(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.CidService', Service);

    Service.$inject = ['$injector'];



    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('pessoa', 'cid')
            .build();

        return selfService;
    }
})();

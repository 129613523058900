(function () {

    'use strict';

    angular.module('educacao.common')
        .filter('appLeadingZero', filter);

    function filter() {

        return formatNumber;

        function formatNumber(input) {

            if (_.isNull(input) || _.isUndefined(input)) {
                return input;
            }

            var formattedNumber = input;

            if (input <= 9) {
                formattedNumber = '0' + input;
            }
            return formattedNumber;
        }

    }

})();


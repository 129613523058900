(function() {
    'use strict';

    angular.module('educacao.common').directive('appCardInformacaoAluno', Directive);

    Directive.$inject = [];

    function Directive() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'common/directives/aluno-card-informacao-diretive/informacoes-aluno.html';
        config.scope = {
            aluno: '=',
            situacao: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;
    }

    function Controller() {
        var vm = this;
        vm.getDeficiencias = getDeficiencias;

        function getDeficiencias(data) {
            return data.join(', ');
        }
    }

})();


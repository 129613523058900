(function () {
    'use strict';

    angular.module('educacao.calendario')
        .constant('REGISTRO_VINCULADO', {
            VINCULO_CONTEUDO_MINISTRADO: 'VINCULO_CONTEUDO_MINISTRADO',
            VINCULO_CONTEUDO_PLANEJADO: 'VINCULO_CONTEUDO_PLANEJADO',
            VINCULO_JUSTIFICATIVA: 'VINCULO_JUSTIFICATIVA',
            VINCULO_ABONO: 'VINCULO_ABONO',
            VINCULO_ALOCACAO: 'VINCULO_ALOCACAO',
            VINCULO_FALTA_DIA: 'VINCULO_FALTA_DIA',
            VINCULO_FALTA_AULA: 'VINCULO_FALTA_AULA'
        });

})();

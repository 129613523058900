(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.CadastroInscricao', Controller);

    Controller.$inject = ['$scope', 'educacao.matricula.InscricaoMatriculaService', 'inscricao'];
    function Controller($scope, InscricaoMatriculaService, inscricao) {
        var vm = this;

        vm.currentStep = 0;
        updateCurrentStep();

        vm.inscricao = inscricao || {};
        if (vm.inscricao.id) {
            vm.inscricao.$$etapaSelecionada =
            vm.inscricao.$$turnosSelecionados = 
            vm.inscricao.$$estabelecimentosSelecionados = 
            vm.inscricao.$$estabelecimentosOrdenados = true;
        }

        vm.onChangingTab = onChangingTab;
        vm.isStepDisabled = isStepDisabled;

        $scope.$watchGroup([
            'vm.inscricao.$$turnosSelecionados',
            'vm.inscricao.$$estabelecimentosSelecionados',
            'vm.inscricao.$$inscricaoSalva',
            'vm.inscricao.$$estabelecimentosOrdenados'
        ], updateCurrentStep);

        $scope.$watch('vm.inscricao.dados', function (newValue) {
            if (!newValue) {
                vm.inscricao.dados = {};
            }
        });

        function onChangingTab(newStep) {
            return newStep >= getCurrentStep();
        }

        function isStepDisabled(step) {
            return step > vm.currentStep;
        }

        function updateCurrentStep() {
            vm.currentStep = getCurrentStep();
        }

        function getCurrentStep() {
            if (!vm.inscricao) {
                return 0;
            } else if (vm.inscricao.$$inscricaoSalva) {
                return 4;
            } else if (vm.inscricao.$$estabelecimentosOrdenados) {
                return 3;
            } else if (vm.inscricao.$$estabelecimentosSelecionados) {
                return 2;
            } else if (vm.inscricao.$$turnosSelecionados) {
                return 1;
            } else {
                return 0;
            }
        }
    }
})();
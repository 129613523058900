(function() {
    'use strict';
    angular.module('educacao.common').directive('appLoadingIcon', function() {
        return {
            restrict: 'E',
            scope: {
                tracker: '='
            },
            template: '<i ng-show="tracker.tracking()" bf-loading="tracker" class="btn"><i></i></i>'
        };
    });
})();

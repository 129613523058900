(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.MotivosDeDispensaService', Service);

    Service.$inject = ['$injector'];



    function Service($injector) {
        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('matricula', 'motivos-dispensa')
            .build();
        return selfService;
    }
})();

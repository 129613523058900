(function () {
    'use strict';

    angular
        .module('educacao.lista-espera-novo.inscricao')
        .controller('educacao.lista-espera-novo.inscricao.EncaminharInscricaoListaEspera', Controller);

    Controller.$inject = [
        '$modalInstance',
        '$q',
        '$enumsCache',
        'promiseTracker',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'bfc.Notification',
        'educacao.context.ContextoAnoLetivo'
    ];

    function Controller($modalInstance, $q, $enumsCache, promiseTracker, InscricaoListaEsperaService, bfcNotification, ContextoAnoLetivo) {
        var vm = this;

        vm.configuracao = $modalInstance.params.configuracao;
        vm.inscricao = $modalInstance.params.inscricao;
        vm.dataInscricao = $modalInstance.params.dataInscricao;
        vm.modalidadeNivelEtapa = $modalInstance.params.modalidadeNivelEtapa;
        vm.onClose = $modalInstance.params.onClose;
        vm.encaminharGeral = !Boolean(vm.modalidadeNivelEtapa);

        vm.getDescricaoModalidade = getDescricaoModalidade;
        vm.searchEstabelecimentos = searchEstabelecimentos;
        vm.formatEstabelecimentos = formatEstabelecimentos;
        vm.searchModalidadeNivelEtapa = searchModalidadeNivelEtapa;
        vm.formatModalidadeNivelEtapa = formatModalidadeNivelEtapa;
        vm.desabilitarSalvarSeEstabelecimentoForVazio = desabilitarSalvarSeEstabelecimentoForVazio;
        vm.searchTurnos = searchTurnos;
        vm.formatTurnos = formatTurnos;
        vm.save = save;
        vm.dataEncaminhamento = moment().format('YYYY-MM-DD HH:mm:ss');
        vm.dataPrazoMatricula = moment().format('YYYY-MM-DD HH:mm:ss');
        vm.calculateDataPrazoMatricula = calculateDataPrazoMatricula;

        vm.promiseTracker = {
            save: promiseTracker()
        };

        vm.selectModalidadeNivelEtapa = [];
        vm.selectEstabelecimentos = [];
        vm.selectTurnos = [];

        $q.all([getNivelEscolarEnum(), getModalidadeEnum()]).then(init);

        function init() {
            _.forEach(vm.inscricao.etapas, function (etapa) {
                if (etapa.situacao.situacao !== 'INSCRITO') {
                    return;
                }

                vm.selectModalidadeNivelEtapa.push(
                    {
                        id: etapa.nivelModalidadeEtapa.id,
                        descricao: getDescricaoModalidadeNivel(etapa.configuracaoNivelModalidade) + ' > ' + etapa.nivelModalidadeEtapa.descricao
                    }
                );
            });

            vm.selectTurnos = [
                {
                    id: 1,
                    descricao: 'MATUTINO'
                },
                {
                    id: 2,
                    descricao: 'VESPERTINO'
                },
                {
                    id: 3,
                    descricao: 'NOTURNO'
                },
                {
                    id: 4,
                    descricao: 'INTEGRAL'
                }
            ];

            if ($modalInstance.params.idEstabelecimentoOrigem) {

                var filter = 'id in (' + $modalInstance.params.idEstabelecimentoOrigem + ') and situacaoFuncionamento = "EM_ATIVIDADE"';
                InscricaoListaEsperaService.getEstabelecimentos(filter)
                    .then(function (estabelecimentos) {
                        vm.estabelecimento = {
                            id: _.get(_.first(estabelecimentos), 'id'),
                            descricao: _.get(_.first(estabelecimentos), 'descricao')
                        };
                        desabilitarSalvarSeEstabelecimentoForVazio();
                    });
            }

            if ($modalInstance.params.turno) {
                vm.turno = {
                    id: 5,
                    descricao: _.get(_.first($modalInstance.params.turno), 'turno')
                };
            }

            InscricaoListaEsperaService.getDataHoraAtual().then(function (data) {
                vm.dataEncaminhamento = moment(data.dataHoraAtual).format('YYYY-MM-DD HH:mm:ss');
                calculateDataPrazoMatricula();
            });
        }

        function desabilitarSalvarSeEstabelecimentoForVazio(){
            if (vm.estabelecimento.id === undefined && vm.estabelecimento.descricao === undefined) {
                vm.estabelecimento = null;
            }
        }

        function calculateDataPrazoMatricula() {
            vm.dataPrazoMatricula = moment(vm.dataEncaminhamento).add(vm.configuracao.qtdDiasEncaminhamento, 'days').format('YYYY-MM-DD');
        }

        function getDescricaoModalidade(nivelModalidade) {
            if (!nivelModalidade) {
                return;
            }

            return nivelModalidade.modalidade ? vm.modalidadeMatricula[nivelModalidade.modalidade].descricao : '';
        }

        function searchTurnos() {
            return $q.when(getTurnos());
        }

        function formatTurnos(data) {
            return data.descricao;
        }

        function getTurnos() {
            return {content: vm.selectTurnos};
        }

        function getNivelEscolarEnum() {
            return $enumsCache.get('NivelEscolar').then(function (enumData) {
                vm.nivelEscolar = enumData;
            });
        }

        function getModalidadeEnum() {
            return $enumsCache.get('ModalidadeMatricula').then(function (enumData) {
                vm.modalidadeMatricula = enumData;
            });
        }

        function getDescricaoModalidadeNivel(nivelModalidade) {
            if (!nivelModalidade) {
                return;
            }

            var nivelEscolar = nivelModalidade.nivelEscolar ? ' > ' + vm.nivelEscolar[nivelModalidade.nivelEscolar].descricao : '';

            return nivelModalidade ? vm.modalidadeMatricula[nivelModalidade.modalidade].descricao + nivelEscolar : '';
        }

        function searchEstabelecimentos(params) {
            var filter = 'nome like "' + params.term + '" and situacaoFuncionamento = "EM_ATIVIDADE"';
            return InscricaoListaEsperaService.getEstabelecimentos(filter)
                .then(function (estabelecimentos) {
                    vm.estabelecimentos = estabelecimentos;
                    return {content: estabelecimentos};
                });
        }

        function formatEstabelecimentos(data) {
            return data.descricao;
        }

        function searchModalidadeNivelEtapa() {
            return $q.when(getModalidadeNivelEtapa());
        }

        function getModalidadeNivelEtapa() {
            return {content: vm.selectModalidadeNivelEtapa};
        }

        function formatModalidadeNivelEtapa(data) {
            return data.descricao;
        }

        function save() {
            var promise = InscricaoListaEsperaService.encaminhar(vm.configuracao.id, vm.inscricao.id, getContent());
            vm.promiseTracker.save.addPromise(promise);
            promise.then(saveSuccess).then(vm.onClose).then(closeModal);
            return promise;
        }

        function getContent() {
            var etapa = _.find(vm.inscricao.etapas, 'nivelModalidadeEtapa.id', vm.modalidadeNivelEtapa.id);

            var filtroEtapa = {
                id: etapa.nivelModalidadeEtapa.id,
                tipo: etapa.nivelModalidadeEtapa.tipo,
                equivalencia: etapa !== 'INFANTIL' ? _.first(etapa.nivelModalidadeEtapa.equivalenciaEtapa) : null
            };

            return {
                inscricao: vm.inscricao.id,
                idEtapa: vm.modalidadeNivelEtapa.id,
                idEstabelecimentoEncaminhado: vm.estabelecimento.id,
                dataEncaminhamento: moment(vm.dataEncaminhamento).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                dataPrazoMatricula: vm.dataPrazoMatricula,
                nivel: 'ETAPA',
                turnos: vm.turno ? [vm.turno.descricao] : vm.selectTurnos.map(function (turno) {
                    return turno.descricao;
                }),
                nome: vm.inscricao.nome,
                etapa: etapa,
                dataNascimento: vm.inscricao.dataNascimento,
                nomeMae: _.get(_.first(_.filter(vm.inscricao.filiacoes, {'tipo': 'MAE'})), 'nome'),
                idConfiguracao: vm.configuracao.id,
                filtroEtapa: filtroEtapa,
                filtroNivel: etapa.configuracaoNivelModalidade.nivelEscolar,
                filtroModalidade: etapa.configuracaoNivelModalidade.modalidade,
                anoLetivoId: ContextoAnoLetivo.getAnoLetivo().id
            };
        }

        function closeModal() {
            return $modalInstance.close();
        }

        function saveSuccess() {
            return bfcNotification.publish('Encaminhamento de ' + vm.inscricao.nome + ' salvo com sucesso.', 'success');
        }
    }
})();

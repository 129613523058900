(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios', [
        'ui.bootstrap',
        'ui-components',
        'ed-components',
        'ngSanitize',
        'educacao.context',
        'educacao.common',
        'ngDraggable'
    ]);
})();

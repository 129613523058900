(function () {
    'use strict';

    angular.module('educacao.common')

    .constant('TURNO', {
        INTEGRAL: 'INTEGRAL',
        MATUTINO: 'MATUTINO',
        VESPERTINO: 'VESPERTINO',
        NOTURNO: 'NOTURNO'
    });
})();
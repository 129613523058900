(function() {
    'use strict';

    angular.module('educacao.context')
        .directive('appSelectAnoLetivo', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ngclass="ngclass" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchAnoLetivo" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                ngChange: '&?',
                ngclass: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.common.AnoLetivoService'];

    function Controller(AnoLetivoService) {

        var vm = this;

        vm.searchAnoLetivo = searchAnoLetivo;
        vm.formatResult = formatResult;
        function formatResult(data){
            return data.ano;
        }

        function searchAnoLetivo(params) {
            return  AnoLetivoService.listAnosLetivos(params)
                .then(function (data) {
                    return {
                    content: data[0].content,
                    hasNext: false
                };
                });

        }

    }
})();


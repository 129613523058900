(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .factory('educacao.enderecos.RegiaoService', Service);

    Service.$inject = [
        'ServiceBuilder',
        'Restangular'
    ];

    function Service(ServiceBuilder, Restangular) {
        var baseUrl = 'regiao-municipal';

        var service = ServiceBuilder.create()
                .path(baseUrl)
                .build();

        service.getList = getList;
        service.getAll = getAll;


        function getList(offset, limit, filter) {
            return Restangular.all('regiao-municipal').get('',{
                offset: offset,
                limit: limit,
                filter: filter
            })
            .then(Restangular.strip);
        }

        function getAll(params) {
            return Restangular.all(baseUrl).get('', params);
        }



        return service;
    }
})();
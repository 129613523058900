(function(){

	'use strict';

	angular.module('educacao.matriz')
		.config(stateConfig);

	stateConfig.$inject = [
		'$stateProvider',
		'STATES_ADICIONAR',
		'STATES_EDITAR'
	];

	function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
		var STATE = 'app.matriz.objetosconhecimentos';

		$stateProvider.state(STATE, {
			url: 'objetos-conhecimentos',
			data: {
				id: 'ObjetosConhecimentosPageMapping'
			},
			views: {
				'@': {
					templateUrl: 'planejamento/matriz/objetos-conhecimentos/listagem-objetos-conhecimentos.html',
					controller: 'educacao.matriz.ObjetosConhecimentosCtrl as vm'
				}
			}
		}).state(STATE + STATES_ADICIONAR.state, {
			url: STATES_ADICIONAR.url,
			onEnter: openModal,
			data: {
				isModalState: true
			}
		}).state(STATE + STATES_EDITAR.state, {
			url: STATES_EDITAR.url,
			onEnter: openModal,
			data: {
				isModalState: true
			}
		});

		openModal.$inject = [
			'$stateParams',
			'educacao.matriz.ObjetoConhecimentoService',
			'$modalStates'
		];

		function openModal($stateParams, ObjetoConhecimentoService, $modalStates) {
			$modalStates.open({
				getMethod: ObjetoConhecimentoService.get,
				templateUrl: 'planejamento/matriz/objetos-conhecimentos/cadastro-objetos-conhecimentos.html',
				controller: 'educacao.matriz.ObjetosConhecimentoCadCtrl as vm',
				propertyName: 'objetoConhecimento',
				size: 'sm',
				stateParams: $stateParams
			});
		}
	}

})();
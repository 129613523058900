(function() {
	'use strict';
	angular.module('educacao.feriado')
		.factory('educacao.feriado.FeriadoService', Service);

	Service.$inject = ['$injector'];

	function Service($injector) {

		var ServiceBuilder = $injector.get('ServiceBuilder');

		var selfService = ServiceBuilder.create()
			.path('commons', 'feriado')
			.build();

		return selfService;
	}
})();


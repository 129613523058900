(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.enderecos.estado';

        $stateProvider.state(STATE, {
            url: 'estados',
            data: {
                id: 'EstadoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'enderecos/estado/estado.html',
                    controller: 'educacao.enderecos.EstadosController as vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.enderecos.EstadoService',
            '$modalStates'
        ];

        function openModal($stateParams, EstadoService, $modalStates) {
            $modalStates.open({
                getMethod: EstadoService.get,
                templateUrl: 'enderecos/estado/cadastro-estado/cadastro-estado.html',
                controller: 'educacao.enderecos.CadastroEstadoController as vm',
                propertyName: 'estado',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }
})();
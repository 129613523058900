(function () {
    'use strict';

    angular
        .module('educacao.pessoas')
        .directive('appFiltroAvancadoEscolaEquipeDiretiva', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'planejamento/escolas/filtro-avancado/escola/equipe-diretiva/equipe-diretiva.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorEquipeDiretiva: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = [
        '$scope', 
        'educacao.funcionario.CargoService',
        'educacao.funcionario.FuncaoGratificadaService'
    ];

    function Controller($scope, CargoService, FuncaoGratificadaService) {
        var vm = this;

        vm.changeContadorEquipeDiretiva = changeContadorEquipeDiretiva;
        vm.searchCargo = searchCargo;
        vm.searchFuncaoGratificada = searchFuncaoGratificada;

        $scope.$watchGroup([
            'vm.advancedFilter.equipeDiretiva.cargo',
            'vm.advancedFilter.equipeDiretiva.cargoComissionado',
            'vm.advancedFilter.equipeDiretiva.funcaoGratificada'
        ], changeContadorEquipeDiretiva);
    
        init();
    
        function init() {
            vm.contadorEquipeDiretiva = 0;

            changeContadorEquipeDiretiva();
        }
    
        function changeContadorEquipeDiretiva() {
            if (vm.advancedFilter && vm.advancedFilter.equipeDiretiva) {
                vm.contadorEquipeDiretiva = getFilterLength();
            }
        }
    
        function getFilterLength() {
            return Object.values(vm.advancedFilter.equipeDiretiva).filter(function (filtroAvancadoSelecionado) {
                return filtroAvancadoSelecionado === false || !!filtroAvancadoSelecionado && filtroAvancadoSelecionado.length !== 0;
            }).length;
        }

        function searchCargo(params) {
            return CargoService.list('(descricao like "' + params.term + '")', params.offset, params.limit);
        }

        function searchFuncaoGratificada(params) {
            return FuncaoGratificadaService.list('(descricao like "' + params.term + '")', params.offset, params.limit);
        }
    }    
})();

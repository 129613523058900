(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaCriterioDesempate', appConfiguracaoListaEsperaCriterioDesempate);

    function appConfiguracaoListaEsperaCriterioDesempate() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-classificacao/app-configuracao-lista-espera-classificacao-utiliza-criterios/app-configuracao-lista-espera-criterio-desempate/app-configuracao-lista-espera-criterio-desempate.directive.html',
            scope: {},
            bindToController: {
                criteriosDesempate: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;
        vm.ordenadorFront = _.map(vm.criteriosDesempate, 'ordenador') || [];
        vm.criteriosDesempate = vm.criteriosDesempate || [];

        $scope.$watch('vm.ordenadorFront', updateCriteriosDesempate);
     
        function updateCriteriosDesempate() {
            addCriteriosDesempate();
            removeCriteriosDesempateNaoSelecionados();
            reordenarList();
        }

        function addCriteriosDesempate() {
            _.forEach(vm.ordenadorFront, insertOrdenadorInlist);
        }

        function insertOrdenadorInlist(ordenador) {
            var listOrdenadorCriteriosDesempate = _.map(vm.criteriosDesempate, 'ordenador');
            if (!findOrdenadorInList(ordenador, listOrdenadorCriteriosDesempate)) {
                vm.criteriosDesempate.push({
                    ordem: vm.criteriosDesempate.length,
                    ordenador: ordenador
                });
            }
        }

        function findOrdenadorInList(ordenador, list) {
            return _.find(list, function(item) {
                return item === ordenador;
            });
        }

        function removeCriteriosDesempateNaoSelecionados() {
            _.remove(vm.criteriosDesempate, function(criterioDesempate) {
                return !findOrdenadorInList(criterioDesempate.ordenador, vm.ordenadorFront);
            });
        }

        function reordenarList() {
            _.forEach(vm.criteriosDesempate, function(item, key) {
                item.ordem = key;
            });
        }

        vm.sortableOptions = {
            stop: reordenarList,
            axis: 'y',
            containment: '#ui-containment'
        };

    }
})();

(function () {
    'use strict';

    var DEFAULT_MESSAGE = 'Este campo está limitado a {0} caracteres';

    angular
        .module('educacao.common')
        .directive('edMaxlength', directive);

    directive.$inject = ['$timeout'];
    function directive($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',

            scope: {
                model: '=ngModel',
                maxLenght: '=edMaxlength',
                message: '@edMaxlengthMessage'
            },

            link: postLink
        };

        function postLink(scope, element, attrs, controller) {
            var timeout;

            controller.$parsers.push(parser);
            controller.$formatters.push(formatter);

            function getCuttedValue(value) {
                if (value && value.length > scope.maxLenght) {
                    value = value.substr(0, scope.maxLenght);
                }
                return value;
            }

            function parser(value) {
                var newValue = getCuttedValue(value);

                if (newValue !== value) {
                    controller.$setViewValue(newValue);
                    controller.$render();
                    notify();
                }
                return newValue;
            }

            function formatter(value) {
                var newValue = getCuttedValue(value);
                if (newValue !== value) {
                    scope.model = newValue;
                    notify();
                }
                return newValue;
            }

            function notify() {
                $timeout.cancel(timeout);

                element.popover({
                    content: getFormatedMessage(),
                    placement: 'top',
                    trigger: 'manual'
                }).popover('show');

                timeout = $timeout(function () {
                    element.popover('hide');
                    timeout = null;
                }, 2000);
            }

            function getFormatedMessage() {
                return (scope.message || DEFAULT_MESSAGE).replace('{0}', scope.maxLenght);
            }
        }
    }
})();

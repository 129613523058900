(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appAccordionGroup', AccordionButtonDirective);

    /*
                <app-accordion-group-panel>
                    <app-accordion-item default-open="true" title="Item 1">
                        <app-accordion-group>
                            <app-accordion-item default-open="true" title="Item 1.1" click-button="vm.onClickMenus('EDUCACAO_BASICA_EDUCACAO_INFANTIL')"></app-accordion-item>
                            <app-accordion-item title="Item 1.2" click-button=""></app-accordion-item>
                            <app-accordion-item title="Item 1.3"></app-accordion-item>
                        </app-accordion-group>
                    </app-accordion-item>
                    <app-accordion-item title="Item 2" active-class='active'></app-accordion-item>
                    <app-accordion-item title="Item 3">
                        <app-accordion-group>
                            <app-accordion-item title="Item 3.1"></app-accordion-item>
                            <app-accordion-item title="Item 3.2"></app-accordion-item>
                            <app-accordion-item title="Item 3.3"></app-accordion-item>
                        </app-accordion-group>
                    </app-accordion-item>
                </app-accordion-group-panel>
        title        -> título do item
        click-button -> Função que será executada após clicar no item
        default-open -> indica se o item clicado será inicializado expandido e clicado(caso não possa expandir)
        active-class -> indica a classe css que o item terá quando o mesmo estiver selecionado
    */

    function AccordionButtonDirective() {
        return {
            restrict: 'E',

            template: '<div class="list-group" collapse="collapsed" ng-transclude></div>',
            replace: true,
            transclude: true
        };
    }
})();


(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaClassificacaoUtilizaCriterios', appConfiguracaoListaEsperaClassificacaoUtilizaCriterios);

    function appConfiguracaoListaEsperaClassificacaoUtilizaCriterios() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-classificacao/app-configuracao-lista-espera-classificacao-utiliza-criterios/app-configuracao-lista-espera-classificacao-utiliza-criterios.directive.html',
            scope: {
                configuracao: '='
            },
            replace: true
        };
    }
})();

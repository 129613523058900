(function() {

	'use strict';

	angular.module('educacao.desempenhoescolar')
		.directive('inputDesempenhoMediaFinalEjaModular', Directive);

	function Directive() {
		return {
			restrict: 'E',
			templateUrl: 'desempenho-escolar/eja-modular/input-media-final/input-media-final-eja-modular.directive.html',
			replace: true,
			scope: {
				save: '=',
				hasPermissionsToEditPeriodoEncerrado: '='
			},
			bindToController: {
				nota: '=',
				configuracao: '='
			},
			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = ['$injector'];

	function Controller($injector) {
		var vm = this;

		var TipoAvaliacaoUtilsService = $injector.get('educacao.matricula.TipoAvaliacaoUtilsService');
		var DesempenhoEscolarUtilsService = $injector.get('educacao.desempenhoescolar.DesempenhoEscolarUtilsService');

		var propriedadesPublicas = {
			isNotaDescritivaOuConceito: _isNotaDescritivaOuConceito
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.popoverMediaFinalPath = 'desempenho-escolar/eja-modular/input-media-final/popover-media-final-eja-modular.html';
			vm.mediaFinalSemConselho = _.first(_.get(vm.nota.nota, 'filhos'));

			if (!vm.mediaFinalSemConselho) {
				return;
			}

			if (DesempenhoEscolarUtilsService.isModoAvaliacaoNumerico(vm.mediaFinalSemConselho.modoAvaliacao)) {
				vm.isMediaFinalSemConselhoNumerico = true;
				vm.decimais = TipoAvaliacaoUtilsService.getQuantidadeCasasDecimais(vm.configuracao);
			}
		}

		function _isNotaDescritivaOuConceito() {
			return Boolean(vm.nota.isModoDescritivo ||
				DesempenhoEscolarUtilsService.isModoAvaliacaoConceito(vm.nota.modoAvaliacao));
		}
	}
})();


(function () {
    'use strict';

    angular.module('educacao.calendario', [
        'bfc',
        'mini-calendar',
        'agenda',
        'educacao.context',
        'generic-service'
    ]);
})();

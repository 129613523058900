(function () {

    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.FormacaoSuperiorService', FormacaoSuperiorService);

    FormacaoSuperiorService.$inject = ['$timeout'];

    function FormacaoSuperiorService($timeout) {
        var self = this;

        self.getAreaFormacaoSuperior = getAreaFormacaoSuperior;
        self.getCursosFormacaoSuperior = getCursosFormacaoSuperior;
        self.getUniversidades = getUniversidades;
        self.getTipoInstituicao = getTipoInstituicao;
        self.getSituacaoCurso = getSituacaoCurso;

        return self;

        function getSituacaoCurso() {
            return $timeout(function () {
                return {
                    'values': [
                        {
                            'key': 'COMPLETO',
                            'value': 'COMPLETO',
                            'description': 'Completo',
                            'id': 'COMPLETO'
                        },
                        {
                            'key': 'INCOMPLETO',
                            'value': 'INCOMPLETO',
                            'description': 'Incompleto',
                            'id': 'INCOMPLETO'
                        }
                    ],
                    'COMPLETO': {
                        'key': 'COMPLETO',
                        'value': 'COMPLETO',
                        'description': 'Completo',
                        'id': 'COMPLETO'
                    },
                    'INCOMPLETO': {
                        'key': 'INCOMPLETO',
                        'value': 'INCOMPLETO',
                        'description': 'Incompleto',
                        'id': 'INCOMPLETO'
                    }
                };
            }, 100);
        }

        function getTipoInstituicao() {
            return $timeout(function () {
                return {
                    'values': [
                        {
                            'key': 'PUBLICA',
                            'value': 'PUBLICA',
                            'description': 'Pública',
                            'id': 'PUBLICA'
                        },
                        {
                            'key': 'PRIVADA',
                            'value': 'PRIVADA',
                            'description': 'Privada',
                            'id': 'PRIVADA'
                        }
                    ],
                    'PUBLICA': {
                        'key': 'PUBLICA',
                        'value': 'PUBLICA',
                        'description': 'Pública',
                        'id': 'PUBLICA'
                    },
                    'PRIVADA': {
                        'key': 'PRIVADA',
                        'value': 'PRIVADA',
                        'description': 'Privada',
                        'id': 'PRIVADA'
                    }
                };
            }, 100);
        }

        function getUniversidades() {
            return $timeout(function () {
                return {
                    content: [
                        {
                            id: 1,
                            descricao: 'Universidade Federal de Mato Grosso'
                        },
                        {
                            id: 2,
                            descricao: 'Universidade de Brasilia'
                        },
                        {
                            id: 3,
                            descricao: 'Universidade Federal de Sergipe'
                        },
                        {
                            id: 5,
                            descricao: 'Universidade Federal do Amazonas'
                        }
                    ]
                };
            }, 200);
        }

        function getAreaFormacaoSuperior() {
            return $timeout(function () {
                return {
                    content: [
                        {
                            id: 1,
                            descricao: 'Educação'
                        },
                        {
                            id: 2,
                            descricao: 'Humanidades e artes'
                        },
                        {
                            id: 3,
                            descricao: 'Ciências, matemática e computação'
                        }
                    ]
                };
            }, 200);
        }

        function getCursosFormacaoSuperior() {
            return $timeout(function () {
                return {
                    content: [
                        {
                            id: 1,
                            descricao: 'Processos escolares'
                        },
                        {
                            id: 2,
                            descricao: 'Pedagogia'
                        },
                        {
                            id: 3,
                            descricao: 'Artes visuais'
                        },
                        {
                            id: 4,
                            descricao: 'Engenharia de materiais'
                        },
                        {
                            id: 5,
                            descricao: 'Biologia'
                        }
                    ]
                };
            }, 200);
        }
    }

})();
(function() {

    'use strict';

    angular.module('educacao.matricula')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.matricula.turma';
        $stateProvider.state(STATE, {
            url: 'turmas',
            data: {
                id: 'TurmaPageMapping'
            },
            views: {
                '': {
                    templateUrl: 'matricula/turma/lista-turma.html',
                    controller: 'educacao.matricula.ListaTurmaController as vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.matricula.TurmaService',
            '$modalStates'
        ];

        function openModal($stateParams, TurmaService, $modalStates) {
            $modalStates.open({
                getMethod: TurmaService.get,
                templateUrl: 'matricula/turma/cadastro/cadastro-turma.html',
				controller: 'educacao.matricula.CadastroTurmaController as vm',
                propertyName: 'turma',
                size: 'xxl',
                stateParams: $stateParams
            });
        }
    }

})();

(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.planejamento');

    moduloCommon.controller('educacao.planejamento.DependenciasCtrl', ['$scope', '$timeout', 'educacao.common.ModalCad', 'bfc.Select2Config',
        function($scope, $timeout, ModalCad) {

            $scope.dependencias = {};

            $scope.openAddDependencia = function(id) {
                ModalCad.open({
                    templateUrl: 'planejamento/dependencias/dependencias.cad.html',
                    controller: 'educacao.planejamento.DependenciasCadCtrl',
                    id: id,
                    readOnly: false
                });
            };

        }
    ]);

    moduloCommon.controller('educacao.planejamento.DependenciasCadCtrl', ['$scope', '$timeout',
        function($scope, $timeout) {
            $scope.dependencias = {};

            /*Campos com selec2 como select*/

            $scope.$watch('$viewContentLoaded', function() {
                $timeout(function() {
                    $('#lbOcupacao').select2({
                        minimumResultsForSearch: -1,
                        data: {
                            results: [{
                                id: 1,
                                text: 'Próprio'
                            }, {
                                id: 2,
                                text: 'Compartilhado'
                            }, {
                                id: 3,
                                text: 'Alugado'
                            }, {
                                id: 3,
                                text: 'Cedido'
                            }]
                        }
                    });
                }, 200);
            });

            $scope.$watch('$viewContentLoaded', function() {
                $timeout(function() {
                    $('#lbStatus').select2({
                        minimumResultsForSearch: -1,
                        data: {
                            results: [{
                                id: 1,
                                text: 'Disponível'
                            }, {
                                id: 2,
                                text: 'Indisponível'
                            }]
                        }
                    });
                }, 200);
            });

        }
    ]);
})();

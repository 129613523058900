(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appSelectDiciplinaEjaModular', SelectDiciplinaEjaModularDirective);

	SelectDiciplinaEjaModularDirective.$inject = [];

	function SelectDiciplinaEjaModularDirective() {
		return {
			restrict: 'E',
			require: 'ngModel',
			template: '<ui-select ng-model="ngModel" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
			scope: {
				ngModel: '=',
				ngRequired: '=?',
				ngDisabled: '=?',
				ngclass: '=',
				turma: '=',
				formatResult: '='
			},
			controller: SelectDiciplinaEjaModularController,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
		};
	}

	SelectDiciplinaEjaModularController.$inject = ['$scope', 'educacao.matricula.TurmaService'];

	function SelectDiciplinaEjaModularController($scope, diciplinaService) {

		var vm = this;

		vm.search = search;
		vm.formatResult = formatResult;

		function search() {
			// var filter = '(descricao like "' + params.term + '")';

			return diciplinaService.getDiciplinas($scope.turma);
		}

		function formatResult(diciplina) {
			return $scope.formatResult ? $scope.formatResult(diciplina) : diciplina.etapaMatrizDisciplina.eixoTematico.descricao;
		}
	}
})();


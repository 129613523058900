(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .service('educacao.quadro-informativo-utils.CardTotalizadoresQuadroInformativoBuilder', CardTotalizadoresQuadroInformativoBuilder);

    CardTotalizadoresQuadroInformativoBuilder.$inject = [];

    function CardTotalizadoresQuadroInformativoBuilder() {
        var service = {
            create: createBuilder,
        };

        return service;

        function createBuilder() {
            var builder = {
                titulo: '',
                saldo: 0,
                tipo: '',
                order: 0,
                hasMoreInformation: false,
                moreInformationRedirect: '',
                permissionRedirect: '',
                hasCollapse: false,
                isCollapsed: false,
                isBigCard: false,
                isEstabelecimentoCard: false,
                hasBadges: false,
                badgeData: {},
                tituloBadge: '',
                isRequired: false,
                requiredTitle: false,
                showMessageEmptySaldo: false,
                textHasMoreInformation: '',

                withTitulo: withTitulo,
                withTituloBadge: withTituloBadge,
                withSaldo: withSaldo,
                withTipo: withTipo,
                withOrder: withOrder,
                withMoreInformation: withMoreInformation,
                withMoreInformationRedirect: withMoreInformationRedirect,
                withPermissionRedirect: withPermissionRedirect,
                withCollapse: withCollapse,
                withIsCollapsed: withIsCollapsed,
                withIsBigCard: withIsBigCard,
                withIsEstabelecimentoCard: withIsEstabelecimentoCard,
                withHasBadge: withHasBadge,
                withBadgeData: withBadgeData,
                withIsRequired: withIsRequired,
                withIsRequiredTitle: withIsRequiredTitle,
                withShowMessageEmptySaldo: withShowMessageEmptySaldo,
                withMessageEmptySaldo: withMessageEmptySaldo,
                withTextHasMoreInformation: withTextHasMoreInformation,

                build: build,
            };

            return builder;

            function withTitulo(titulo) {
                builder.titulo = titulo;
                return builder;
            }

            function withSaldo(saldo) {
                builder.saldo = saldo;
                return builder;
            }

            function withTipo(tipo) {
                builder.tipo = tipo;
                return builder;
            }

            function withOrder(order) {
                builder.order = order;
                return builder;
            }

            function withMoreInformation(hasMoreInformation) {
                builder.hasMoreInformation = hasMoreInformation;
                return builder;
            }

            function withMoreInformationRedirect(moreInformationRedirect) {
                builder.moreInformationRedirect = moreInformationRedirect;
                return builder;
            }

            function withPermissionRedirect(permissionRedirect) {
                builder.permissionRedirect = permissionRedirect;
                return builder;
            }

            function withCollapse(hasCollapse) {
                builder.hasCollapse = hasCollapse;
                return builder;
            }

            function withIsCollapsed(isCollapsed) {
                builder.isCollapsed = isCollapsed;
                return builder;
            }

            function withIsBigCard(isBigCard) {
                builder.isBigCard = isBigCard;
                return builder;
            }

            function withIsEstabelecimentoCard(isEstabelecimentoCard) {
                builder.isEstabelecimentoCard = isEstabelecimentoCard;
                return builder;
            }

            function withHasBadge(hasBadge) {
                builder.hasBadges = hasBadge;
                return builder;
            }

            function withBadgeData(badgeData) {
                builder.badgeData = badgeData;
                return builder;
            }

            function withTituloBadge(tituloBadge) {
                builder.tituloBadge = tituloBadge;
                return builder;
            }

            function withIsRequired(isRequired) {
                builder.isRequired = isRequired;
                return builder;
            }

            function withIsRequiredTitle(isRequiredTitle) {
                builder.isRequiredTitle = isRequiredTitle;
                return builder;
            }

            function withShowMessageEmptySaldo(showMessageEmptySaldo) {
                builder.showMessageEmptySaldo = showMessageEmptySaldo;
                return builder;
            }

            function withMessageEmptySaldo(messageEmptySaldo) {
                builder.messageEmptySaldo = messageEmptySaldo;
                return builder;
            }

            function withTextHasMoreInformation(textHasMoreInformation) {
                builder.textHasMoreInformation = textHasMoreInformation;
                return builder;
            }

            function build() {
                return {
                    titulo: builder.titulo,
                    saldo: builder.saldo,
                    tipo: builder.tipo,
                    order: builder.order,
                    hasMoreInformation: builder.hasMoreInformation,
                    moreInformationRedirect: builder.moreInformationRedirect,
                    hasCollapse: builder.hasCollapse,
                    isCollapsed: builder.isCollapsed,
                    isBigCard: builder.isBigCard,
                    isEstabelecimentoCard: builder.isEstabelecimentoCard,
                    hasBadge: builder.hasBadges,
                    badgeData: builder.badgeData,
                    tituloBadge: builder.tituloBadge,
                    isRequired: builder.isRequired,
                    isRequiredTitle: builder.isRequiredTitle,
                    showMessageEmptySaldo: builder.showMessageEmptySaldo,
                    messageEmptySaldo: builder.messageEmptySaldo,
                    textHasMoreInformation: builder.textHasMoreInformation
                };
            }
        }


    }


})();

(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .directive('appSelectEstabelecimentoSecretariaFuncionarioSubstituto', SelectEstabelecimentoFuncionarioSubstitutoDirective);

    function SelectEstabelecimentoFuncionarioSubstitutoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-change="ngChange" search="vm.search" ng-disabled="ngDisabled" format-result="vm.formatResult(data)" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '=',
                estabelecimentosAtivos: '=',
            },
            controller: SelectEstabelecimentoFuncionarioSubstitutoController,
            controllerAs: 'vm'
        };
    }

    SelectEstabelecimentoFuncionarioSubstitutoController.$inject = [
        '$scope',
        'educacao.pessoas.EstabelecimentoService',
        '$q'
    ];

    function SelectEstabelecimentoFuncionarioSubstitutoController($scope, EstabelecimentoService, $q) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        var SECRETARIA = 'Secretaria';

        var itemSecretaria = {
            id: SECRETARIA,
            descricao: SECRETARIA,
            isSecretaria: true
        };

        function search(params) {

            var filter = '(nome like "' + params.term + '" ';

            if (!_.isEmpty($scope.estabelecimentosAtivos)) {
                var estabelecimentosAtivosSemSecretaria = $scope.estabelecimentosAtivos.filter(function (id) {
                    return id !== undefined && id !== SECRETARIA;
                });

                if(!_.isEmpty(estabelecimentosAtivosSemSecretaria)) {
                    filter += ' and id in(' + estabelecimentosAtivosSemSecretaria.join() + ')';
                } else {
                    return $q.when({
                        content: [itemSecretaria],
                        hasNext: false
                    });
                }
            } else {
                return $q.when({
                    content: [],
                    hasNext: false
                });
            }

            filter += ')';
            
            return EstabelecimentoService
                .getFuncionarios(params.offset, params.limit, filter)
                .then(function (data) {
                    var existeEstabelecimentoAtivoSecretaria = $scope.estabelecimentosAtivos.some(function(id) {
                        return id === undefined || id === SECRETARIA;
                    });

                    var originalTerm = decodeURI(params.term).replace(/%/g, '').trim();
                    var canAddItemSecretaria = _.isEmpty(originalTerm) ? true : SECRETARIA.toLowerCase().includes(originalTerm.toLowerCase());

                    if (existeEstabelecimentoAtivoSecretaria && canAddItemSecretaria) {
                        data.unshift(itemSecretaria);
                    }

                    return {
                        content: data,
                        hasNext: false
                    };
                });
        }

        function formatResult(data) {
            return data.descricao || data.nome || (data.pessoa ? data.pessoa.nome : '');
        }
    }
})();

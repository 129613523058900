(function() {

    'use strict';

    angular.module('configuracao-nota-maxima')
        .service('configuracao-nota-maxima.ConfiguracaoNotaMaximaService', ConfiguracaoNotaMaximaService);

    ConfiguracaoNotaMaximaService.$inject = [
        'Restangular'
    ];

    function ConfiguracaoNotaMaximaService(Restangular) {
        var self = this;

        self.save = save;
        self.getTurmasPeriodosAvaliativos = getTurmasPeriodosAvaliativos;

        function save(configuracaoNotaMaxima) {
            return Restangular.all('matricula/turma/configuracao-nota')
                .customPOST(configuracaoNotaMaxima);
        }

        function getTurmasPeriodosAvaliativos(filter) {
            return Restangular.all('matricula/turma/configuracao-nota')
                .getList({
                    filter: filter
                })
                .then(Restangular.stripRestangular);
        }
    }

})();

(function () {
    'use strict';

    angular
        .module('educacao.quadro-informativo-utils')
        .directive('appSelectEstabelecimentoQuadroInformativo', appSelectEstabelecimentoQuadroInformativo);

    function appSelectEstabelecimentoQuadroInformativo() {

        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search" placeholder="placeholder"/>',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                estabelecimentos: '=',
                anoLetivoSelected: '=',
                placeholder: '=?'
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'educacao.ocupacao-aulas-quadro-informativo.VisaoGeralQuadroInformativoService'];

    function Controller($scope, Service) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        var itemSecretaria = {
            id: 'Secretaria',
            descricao: 'Secretaria',
            isSecretaria: true
        };

        function search(params) {
            var filter = ['(nome like "' + params.term + '")'];

            if ($scope.multiple) {
                var estebelecimentoIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        if (model.id !== itemSecretaria.id) {
                            estebelecimentoIds.push(model.id);
                        }
                    });
                }
                if (estebelecimentoIds.length) {
                    filter.push('id not in(' + estebelecimentoIds.join() + ')');
                }
            }

            if (!_.isEmpty($scope.estabelecimentos)) {
                var ids = _.map($scope.estabelecimentos, 'id');
                filter.push('id in (' + ids.join(',') + ')');
            }

            var reqParams = {
                filter: filter.join(' and '),
                offset: params.offset,
                limit: params.limit,
                anoLetivoId: _.get($scope.anoLetivoSelected,'id')
            };

            return Service
                .getListagemEstabelecimentos(reqParams)
                .then(toPage);
        }

        function toPage(list) {
            return {
                content: list.content,
                hasMore: list.hasNext
            };
        }

        function formatResult(data) {
            return data.descricao;
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.matricula').controller('educacao.matricula.EnturmacaoCtrl', EnturmacaoCtrl);

    EnturmacaoCtrl.$inject = ['educacao.common.$commons', '$modalInstance', 'educacao.matricula.EnturmacaoService'];

    function EnturmacaoCtrl($commons, $modalInstance, EnturmacaoService) {

        var vm = this;

        vm.turmas = $modalInstance.params.turmas;
        vm.Turno = $modalInstance.params.Turno;

        if (!vm.turmas || !vm.turmas.length) {
            throw 'É necessário passar ao menos uma turma';
        }

        vm.tracker = {
            enturmaveis: $commons.$promiseTracker()
        };

        vm.enturmar = enturmar;
        vm.desenturmar = desenturmar;

        activate();

        function activate() {
            var exemploTurma = vm.turmas[0];
            vm.filtro = {
                estabelecimento: exemploTurma.estabelecimento,
                matriz: exemploTurma.matriz,
                etapaMatriz: exemploTurma.etapaMatriz,
                turno: exemploTurma.turno,
                tipoAtendimento: exemploTurma.tipoAtendimento
            };

            vm.tabelaTurmas = _.chunk(vm.turmas, 2);
            getEnturmaveis();
        }

        function enturmar(dragDropObject) {
            var indexAluno = _.findIndex(vm.enturmaveis, 'id', dragDropObject.dragObject.id);
            vm.enturmaveis.splice(indexAluno, 1);

            if(!dragDropObject.multiturno){
                var promise = EnturmacaoService.enturmar(dragDropObject.dragObject, dragDropObject, vm.data).then(function(enturmacao) {

                    $commons.$notification.publish('Aluno enturmado com sucesso!', 'success');
    
                    _.find(vm.turmas, 'id', dragDropObject.id).quantidadeAlunos++;
    
                    if (dragDropObject.enturmacoes) {
                        dragDropObject.enturmacoes.push(enturmacao);
                    }
    
                }, function() {
                    vm.enturmaveis.push(dragDropObject.dragObject);
                });
                vm.tracker.enturmaveis.addPromise(promise);
            }else{
                $commons.$notification.publish('Não é possível realizar enturmações para turmas multiturno', 'error');

                vm.enturmaveis.push(dragDropObject.dragObject);
            }
        }

        function desenturmar(enturmacao, turma) {

            if (turma.enturmacoes) {
                turma.enturmacoes.splice(turma.enturmacoes.indexOf(enturmacao), 1);
            }

            EnturmacaoService.desenturmar(enturmacao).then(function() {
                $commons.$notification.publish('Aluno removido com sucesso!', 'success');

                turma.quantidadeAlunos--;
                vm.enturmaveis.push(enturmacao.matricula);

            }, function() {
                if (turma.enturmacoes) {
                    turma.enturmacoes.push(enturmacao);
                }
            });
        }

        function getEnturmaveis() {
            var promise = EnturmacaoService.getEnturmaveis({
                    estabelecimento: vm.filtro.estabelecimento,
                    etapaMatriz: vm.filtro.etapaMatriz,
                    turno: vm.filtro.turno,
                    tipoAtendimento: vm.filtro.tipoAtendimento
                })
                .then(function(enturmaveis) {
                    vm.enturmaveis = enturmaveis;
                });
            vm.tracker.enturmaveis.addPromise(promise);
        }
    }
})();


 (function() {
     'use strict';
     angular.module('educacao.calendario')
         .factory('educacao.calendario.TiposEventoService', TiposEventoService);

     TiposEventoService.$inject = ['Restangular'];

     function TiposEventoService(Restangular) {
         return {
             getTiposEventos: getTiposEventos
         };

         function getTiposEventos() {
             return Restangular
                 .one('calendario')
                 .all('eventos/tipos-eventos')
                 .getList();
         }
     }

 })();

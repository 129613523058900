(function() {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaTableInfantil', appConfiguracaoListaEsperaTableInfantil);

    function appConfiguracaoListaEsperaTableInfantil() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-table-infantil/app-configuracao-lista-espera-table-infantil.directive.html',
            scope: {},
            bindToController: {
                nivelEscolar: '=',
                tabNivelAtiva: '=',
                tabModalidadeAtiva: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaUtils',
        'educacao.common.CommonSelect',
        'bfc.Notification',
        '$scope',
        'CONFIGURACAO_ETAPAS'
    ];

    function Controller(ConfiguracaoListaEsperaUtils, CommonSelect, bfcNotification, $scope, CONFIGURACAO_ETAPAS) {
        var TIPO_CORTE_DATA_INSCRICAO = 'DATA_INSCRICAO';
        var TIPO_CORTE_DATA_ESPECIFICA = 'DATA_ESPECIFICA';
        var ITERATE_TIPO_OPERACAO_SUM = 'sum';
        var ITERATE_TIPO_OPERACAO_SUB = 'sub';

        var vm = this;

        vm.tabAtiva = 'ensinoInfantil';

        vm.addEtapa = addEtapa;
        vm.deleteEtapa = deleteEtapa;
        vm.enumTurno = ConfiguracaoListaEsperaUtils.enumTurno();
        vm.verificaSelecaoTodos = verificaSelecaoTodos;
        vm.configuracaoEtapas = CONFIGURACAO_ETAPAS;
        vm.changeMesCompletarIdadeAte = changeMesCompletarIdadeAte;
        vm.canDisableCompletarIdadeMinima = canDisableCompletarIdadeMinima;
        vm.iterateDiaCompletarIdadeMinima = iterateDiaCompletarIdadeMinima;
        vm.changeDataEspecifica = changeDataEspecifica;
        vm.operacaoSum = ITERATE_TIPO_OPERACAO_SUM;
        vm.operacaoSub = ITERATE_TIPO_OPERACAO_SUB;
        vm.tipoDataEspecifica = TIPO_CORTE_DATA_ESPECIFICA;

        $scope.$watchGroup(['vm.nivel.confiEtapas', 'vm.tabNivelAtiva', 'vm.tabModalidadeAtiva'], init);
        $scope.$watch('formTable.$invalid', setFormInvalido);
        $scope.$watch('vm.nivel.matriz', watchMatriz);

        vm.sugestaoDiaMes = {
            bebes: {
                mesCompletarIdadeMinima: '2',
                diaCompletarIdadeMinima: '31',
            }
        };
        vm.sugestoes = [{
                descricao: 'Bebês',
                anoIdadeMaxima: '1',
                anoIdadeMinima: '0',
                mesIdadeMaxima: '6',
                mesIdadeMinima: '0',
                turnos: ['TODOS'],
                mesCompletarIdadeMinima: '2',
                diaCompletarIdadeMinima: '31',
                tipoDataCorte: TIPO_CORTE_DATA_ESPECIFICA
                }, {
                descricao: 'Crianças bem pequenas',
                anoIdadeMaxima: '3',
                anoIdadeMinima: '1',
                mesIdadeMaxima: '11',
                mesIdadeMinima: '7',
                turnos: ['TODOS'],
                mesCompletarIdadeMinima: '2',
                diaCompletarIdadeMinima: '31',
                tipoDataCorte: TIPO_CORTE_DATA_ESPECIFICA
                },
            {
                descricao: 'Crianças pequenas',
                anoIdadeMaxima: '5',
                anoIdadeMinima: '4',
                mesIdadeMaxima: '11',
                mesIdadeMinima: '0',
                turnos: ['TODOS'],
                mesCompletarIdadeMinima: '2',
                diaCompletarIdadeMinima: '31',
                tipoDataCorte: TIPO_CORTE_DATA_ESPECIFICA
                }
        ];

        function changeMesCompletarIdadeAte(etapa) {
            etapa.diaCompletarIdadeMinima = (etapa.diaCompletarIdadeMinima > etapa.mesIdadeMinima.max) ? etapa.mesMinima.max : etapa
                .diaCompletarIdadeMinima;
            if (etapa.mesCompletarIdadeMinima) {
                if (parseInt(etapa.diaCompletarIdadeMinima) > etapa.mesCompletarIdadeMinima.max) {
                    etapa.diaCompletarIdadeMinima = etapa.mesCompletarIdadeMinima.max;
                }
            } else {
                setCompletarIdadeMinimaFromSugestao(etapa);
            }

            return;
        }

        function verificaSelecaoTodos(etapa) {
            if (_.includes(etapa.turnos, 'TODOS')) {
                etapa.turnos = ['TODOS'];
            }
        }

        function watchMatriz(matriz) {
            if (!matriz) {
                return;
            }
            if (matriz.etapas) {
                _.forEach(matriz.etapas, setValorParaMatrizSemTipoDataCorte);
                vm.nivel.matriz = matriz;
            }
            init();
        }

        function setValorParaMatrizSemTipoDataCorte(etapa) {
            if (!etapa.tipoDataCorte || etapa.tipoDataCorte === TIPO_CORTE_DATA_ESPECIFICA) {
                if (etapa.dataParaCompletarIdadeMinima) {
                    etapa.tipoDataCorte = TIPO_CORTE_DATA_ESPECIFICA;
                    var diaMes = etapa.dataParaCompletarIdadeMinima.split('-');
                    etapa.mesCompletarIdadeMinima = (diaMes[2] - 1).toString();
                    etapa.diaCompletarIdadeMinima = diaMes[3];
                } else {
                    etapa.tipoDataCorte = TIPO_CORTE_DATA_ESPECIFICA;
                    etapa.mesCompletarIdadeMinima = vm.sugestoes.bebes.mesIdadeMaxima;
                    etapa.diaCompletarIdadeMinima = vm.sugestoes.bebes.diaCompletarIdadeMinima;
                }
            }
        }

        function setFormInvalido(invalido) {
            if (!vm.nivel) {
                return;
            }
            vm.nivel.formularioInvalido = invalido;
        }

        function init() {
            vm.confiEtapa = ConfiguracaoListaEsperaUtils._selectIdades();
            setEtapas();
            setDiaMesCompletarIdadeMin();
        }

        function addEtapa() {
            vm.nivel.etapas.push({
                descricao: '',
                equivalencia: {},
                turnos: []
            });
        }

        function deleteEtapa(etapa) {
            _.remove(vm.nivel.etapas, etapa);
        }

        function setEtapas() {
            vm.nivel = _.find(vm.nivelEscolar, 'nivelEscolar', vm.tabNivelAtiva);

            if (!vm.nivel) {
                return;
            }

            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.CONFORME_MATRIZ_CURRICULAR) {
                if (vm.nivel.matriz) {
                    _.forEach(vm.nivel.matriz.etapas, setValorParaMatrizSemTipoDataCorte);
                }
                vm.nivel.etapas = _.get(vm.nivel.matriz, 'etapas');
                return;
            }

            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.SUGERIDAS_PELO_SISTEMA) {
                if (!_.isEmpty(vm.nivel.etapas) && vm.nivel.matriz) {
                    vm.nivel.etapas = vm.sugestoes;
                    vm.nivel.matriz = '';
                    return;
                }
                if (!_.isEmpty(vm.nivel.etapas)) {
                    return;
                }
                vm.nivel.etapas = vm.sugestoes;
                return;
            }
        }

        function canDisableCompletarIdadeMinima(etapa) {
            return etapa.possuiVinculos || etapa.tipoDataCorte === TIPO_CORTE_DATA_INSCRICAO;
        }

        function iterateDiaCompletarIdadeMinima(etapa, operacao) {
            if (etapa.tipoDataCorte === TIPO_CORTE_DATA_INSCRICAO) {
                return;
            }

            iterateDiaCompletarIdadeMinimaValue(etapa, operacao);
        }

        function iterateDiaCompletarIdadeMinimaValue(etapa, operacao) {
            if (validateOperacao(etapa, operacao)) {
                etapa.diaCompletarIdadeMinima = calcIdade(etapa, operacao);
                return;
            }

            etapa.diaCompletarIdadeMinima = etapa.diaCompletarIdadeMinima ? etapa.diaCompletarIdadeMinima : 1;
        }

        function validateOperacao(etapa, operacao) {
            if (operacao === ITERATE_TIPO_OPERACAO_SUM) {
                return etapa.diaCompletarIdadeMinima < etapa.mesCompletarIdadeMinima.max && !etapa.possuiVinculos;
            }

            return etapa.diaCompletarIdadeMinima > 1 && !etapa.possuiVinculos;
        }

        function calcIdade(etapa, operacao) {
            if (operacao === ITERATE_TIPO_OPERACAO_SUM) {
                return etapa.diaCompletarIdadeMinima * 1 + 1;
            }

            return etapa.diaCompletarIdadeMinima * 1 - 1;
        }

        function changeDataEspecifica(etapa) {
            setCompletarIdadeMinima(etapa, TIPO_CORTE_DATA_ESPECIFICA);
        }

        function setDiaMesCompletarIdadeMin() {
            _.forEach(vm.nivel.etapas, function(etapa) {
                if (etapa.tipoDataCorte === TIPO_CORTE_DATA_INSCRICAO || !etapa.tipoDataCorte) {
                    clearCompletarIdadeMinima(etapa);
                }
            });
        }

        function setCompletarIdadeMinima(etapa, tipoDataCorte) {
            if (etapa.tipoDataCorte === tipoDataCorte || !etapa.tipoDataCorte) {
                clearCompletarIdadeMinima(etapa);
                return;
            }

            setCompletarIdadeMinimaFromSugestao(etapa);
        }

        function clearCompletarIdadeMinima(etapa) {
            etapa.mesCompletarIdadeMinima = '';
            etapa.diaCompletarIdadeMinima = '';
        }

        function setCompletarIdadeMinimaFromSugestao(etapa) {
            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.CONFORME_MATRIZ_CURRICULAR) {
                setValorDiaMesByNivelMatriz(etapa);
            } else {
                if (etapa.tipoDataCorte === TIPO_CORTE_DATA_ESPECIFICA) {
                    etapa.mesCompletarIdadeMinima = vm.sugestaoDiaMes.bebes.mesCompletarIdadeMinima;
                    etapa.diaCompletarIdadeMinima = vm.sugestaoDiaMes.bebes.diaCompletarIdadeMinima;
                }
            }
        }

        function setValorDiaMesByNivelMatriz(etapa) {
            var etapaMatriz = _.find(vm.nivel.matriz.etapas, {
                id: etapa.id
            });
            if (etapaMatriz.dataParaCompletarIdadeMinima) {
                etapa.tipoDataCorte = TIPO_CORTE_DATA_ESPECIFICA;
                var diaMes = etapaMatriz.dataParaCompletarIdadeMinima.split('-');
                etapa.mesCompletarIdadeMinima = (diaMes[2] - 1).toString();
                etapa.diaCompletarIdadeMinima = diaMes[3];
            } else {
                etapa.tipoDataCorte = TIPO_CORTE_DATA_ESPECIFICA;
                etapa.mesCompletarIdadeMinima = vm.sugestaoDiaMes.bebes.mesCompletarIdadeMinima;
                etapa.diaCompletarIdadeMinima = vm.sugestaoDiaMes.bebes.diaCompletarIdadeMinima;
            }

        }

    }

})();

(function () {
    'use strict';

    angular.module('educacao.common')
    .directive('appGraphDonut', appGraphDonutDirective);

    function appGraphDonutDirective() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/app-graph-donut/app-graph-donut.directive.html',
            scope: {},
            bindToController: {
                percent: '=',
                radius: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope'
    ];

    function Controller($scope) {
        var vm = this;

        vm.dashoffset = 0;
        
        $scope.$watch('vm.percent', function() {
            init();
        });

        init();
        
        function init() {
            vm.canShowGraph = Boolean(vm.percent);

            vm.comprimentoCircunferencia = 2 * Math.PI * vm.radius;

            var percentCalc = vm.percent / 100;
            vm.dashoffset = vm.comprimentoCircunferencia * (1 - percentCalc);
        }
    }


})();
(function () {
    'use strict';

    angular.module('educacao.common')

        .constant('ED_CAMPOS_ADICIONAIS', {
            CADASTRO_ALUNO: '58874fa3dceb8c00d7c7d689',
            CADASTRO_ESTABELECIMENTO: '58d19692ffc98c00e134ebae'
        });
})();

(function() {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
            .directive('dropdownFiltroAvancadoAulasGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/filtros-aulas-gestao-pessoas/dropdown-filtro-avancado-aulas-gestao-pessoas/dropdown-filtro-avancado-aulas-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                applySearch: '=',
                filter: '=',
                duracoesSelecionadas: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.limparFiltrosAvancados = limparFiltrosAvancados;
        vm.togglePesquisaAvancada = togglePesquisaAvancada;
        vm.search = search;

        vm.filtroTurno = '';

        function togglePesquisaAvancada() {
            vm.showBottomSheet = !vm.showBottomSheet;
        }
 
        function limparFiltrosAvancados() {
            vm.filtroTurno = null;
            vm.duracoesSelecionadas = null;
            vm.filter = [];
        }

        function search() {
            var duracoes = vm.duracoesSelecionadas.map(function (duracao) {
                return duracao.duracaoAula;
            });

            if (!_.isEmpty(duracoes)) {
                vm.filter.push('duracao in (' + duracoes.join(',') + ')');
            }

            if (!_.isEmpty(vm.filtroTurno)) {
                vm.filter.push('turno in ("' +  vm.filtroTurno.join('","') + '")');
            }

            vm.applySearch();
            vm.filter = [];
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appAccordionEtapaMatriz', appAccordionEtapaMatriz);

    function appAccordionEtapaMatriz() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-accordion-visao-estabelecimento/app-accordion-etapa-matriz/app-accordion-etapa-matriz.directive.html',
            scope: {},
            bindToController: {
                matriz: '=',
                estabelecimentoId: '=',
                estabelecimentoNome: '=',
                turmaFilters: '=',
                turmaSelecionada: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.ocupacao-aulas-quadro-informativo.AccordionVisaoGeralUtilsService'];

    function Controller(AccordionVisaoGeralUtilsService) {
        var vm = this;

        init();

        function init() {
            vm.turmasPorEtapa = _.groupBy(vm.matriz.turmas, 'etapaMatriz.descricao');
            sumSaldoPorEtapa();
        }

        function sumSaldoPorEtapa() {
            vm.saldosPorEtapa = {};

            _.forEach(vm.turmasPorEtapa, function (porEtapa) {
                var etapa = _.first(porEtapa).etapaMatriz.descricao;

                if (!vm.saldosPorEtapa[etapa]) {
                    vm.saldosPorEtapa[etapa] = {
                        saldoInicial: 0,
                        saldoOcupado: 0,
                        saldoAtual: 0
                    };
                }

                AccordionVisaoGeralUtilsService.sumSaldoByTipoSaldo(vm.saldosPorEtapa[etapa], porEtapa);
            });
        }
    }
})();
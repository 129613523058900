(function () {

    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.InstituicaoEnsinoSuperiorInepService', InstituicaoEnsinoSuperiorInepService);


    InstituicaoEnsinoSuperiorInepService.$inject = ['ServiceBuilder'];

    function InstituicaoEnsinoSuperiorInepService(ServiceBuilder) {
        var self = ServiceBuilder.create()
            .path('inep', 'instituicao-ensino-superior-inep')
            .build();

        return self;
    }
})();
(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.ObjetosConhecimentoCadCtrl', ObjetosConhecimentoCadCtrl);

	ObjetosConhecimentoCadCtrl.$inject = ['bfc.Notification', 'educacao.matriz.ObjetoConhecimentoService', '$modalInstance'];

	function ObjetosConhecimentoCadCtrl(Notification, ObjetoConhecimentoService, $modalInstance) {
		var vm = this;

		vm.remove = remove;
		vm.save = save;

		init();

		function init() {
			vm.objetoConhecimento = $modalInstance.params.objetoConhecimento || {};
			vm.editando = _.get(vm.objetoConhecimento, 'id');
			vm.canSaveAdd = $modalInstance.params.canSaveAdd;

			vm.permissions = {
				save: {
					bfPermissions: 'app.matriz.objetosconhecimentos',
					operation: 'criar'
				},
				update: {
					bfPermissions: 'app.matriz.objetosconhecimentos',
					operation: 'editar'
				},
				remove: {
					bfPermissions: 'app.matriz.objetosconhecimentos',
					operation: 'excluir'
				}
			};
		}

		function save(continuar) {
			return ObjetoConhecimentoService.save(vm.objetoConhecimento).then(function(data) {
				Notification.publish('Objeto de conhecimento salva com sucesso', 'success');
				if (continuar) {
					vm.objetoConhecimento = {};
					vm.form.$setUntouched();
				} else {
					$modalInstance.close(data);
				}
			});
		}

		function remove() {
			return ObjetoConhecimentoService.remove(vm.objetoConhecimento).then(function() {
				$modalInstance.close();
			});
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.criterio-lista-espera')
    .factory('educacao.criterio-lista-espera.CriterioListaEsperaService', CriterioListaEsperaService);

    CriterioListaEsperaService.$inject = ['ServiceBuilder'];

    function CriterioListaEsperaService(ServiceBuilder) {
        var service = ServiceBuilder.create()
        .path('lista-espera/criterios')
        .build();

        service.listCriterios = listCriterios;

        return service;

        function listCriterios(params) {
            return service.getEndpoint().getList({
                    limit: params.limit,
                    offset: params.offset,
                    sort: params.sort,
                    filter: '(descricao like "' + params.term + '")'
                });
        }
    }
})();

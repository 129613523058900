(function() {

    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.CadastroMovimentacoesMatriculaFuncionarioCtrl', CadastroMovimentacoesMatriculaFuncionarioCtrl);

    CadastroMovimentacoesMatriculaFuncionarioCtrl.$inject = [
        'promiseTracker',
        'ui.components.Modal',
        '$modalInstance',
        'educacao.funcionario.FuncionarioMatriculaMovimentacoesService',
        '$rootScope'
    ];

    function CadastroMovimentacoesMatriculaFuncionarioCtrl(promiseTracker, $modal, $modalInstance, MovimentacoesService, $rootScope) {
        var vm = this;
        vm.save = save;
        vm.remove = remove;
        vm.tipoMovimentacao = $modalInstance.params.tipoMovimentacao;
        vm.matricula = $modalInstance.params.matricula;
        vm.idMovimentacao = parseInt(_.get(vm.matricula, 'id'));
        vm.editando = $modalInstance.params.editando;

        if (vm.editando) {
            MovimentacoesService.getMovimentacao(vm.idMovimentacao).then(function(matricula) {
                vm.movimentacao = matricula;
            });
        }

        init();
        vm.tracker = {
            save: promiseTracker(),
            remove: promiseTracker()
        };

        function save(movimentacao) {

            if(_.isNull(movimentacao.desenturmar) || _.isUndefined(movimentacao.desenturmar)){
                movimentacao.desenturmar = true;
            }

            var promise;
            if (vm.editando) {
                promise = MovimentacoesService.atualizaMovimentacao(vm.idMovimentacao, vm.movimentacao).then(broadcast);
            } else if(vm.tipoMovimentacao === 'RETORNAR_AFASTAMENTO'){
                movimentacao.funcionario = {
                    id: vm.matricula.id
                };
                movimentacao.tipoMovimentacao = vm.tipoMovimentacao;
                promise = MovimentacoesService.retornaAfastamento(vm.idMovimentacao, vm.movimentacao).then(broadcast);
            }else {
                movimentacao.funcionario = {
                    id: vm.matricula.id
                };
                movimentacao.tipoMovimentacao = vm.tipoMovimentacao;
                promise = MovimentacoesService.saveMovimentacao(movimentacao).then(broadcast);
            }

            vm.tracker.save.addPromise(promise);
        }

        function remove(id) {
            MovimentacoesService.remove(parseInt(id));
        }

        function broadcast() {
            $rootScope.$broadcast('refreshDetalhesFuncionario', {
                atualiza: true
            });
            $modalInstance.close();
        }

        function init() {
            vm.exoneracao = vm.tipoMovimentacao === 'EXONERACAO';
            vm.demissao = vm.tipoMovimentacao === 'DEMISSAO';
            vm.aposentadoria = vm.tipoMovimentacao === 'APOSENTADORIA';
            vm.falecimento = vm.tipoMovimentacao === 'FALECIMENTO';
            vm.remocao = vm.tipoMovimentacao === 'REMOCAO';
            vm.ausencia = vm.tipoMovimentacao === 'AUSENCIA';
            vm.afastamento = vm.tipoMovimentacao === 'AFASTAMENTO';
            vm.retornoAfastamento = vm.tipoMovimentacao === 'RETORNAR_AFASTAMENTO';

        }

    }
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .directive('appDetalheImpeditivo', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                value: '=',
                periodosChanged: '='
            },
            templateUrl: 'calendario/calendario-matriz/registros-vinculados/detalhe-impeditivo/app-detalhe-impeditivo.directive.html',
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;        
       
        vm.hasPeriodoEncerrado = hasPeriodoEncerrado;

        function hasPeriodoEncerrado() {
            var hasPeriodosEncerrados = _.filter(vm.value.periodos, function(periodo) {
                if (!periodo.id) {
                    return false;
                }
                var periodoAlterado = _.find(vm.periodosChanged, { id: periodo.id });
                return periodo.isEncerrado && periodoAlterado;
            });
            return hasPeriodosEncerrados.length > 0;
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matriz')
        .config(stateConfig);

    stateConfig.$inject = [
            '$stateProvider',
            'STATES_ADICIONAR',
            'STATES_EDITAR'
        ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {

        var STATE = 'app.matriz.disciplina';

        $stateProvider.state(STATE, {
            url: 'disciplinas',
            data: {
                id: 'DisciplinaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'planejamento/matriz/disciplina/disciplinas.html',
                    controller: 'educacao.matriz.DisciplinasCtrl as vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });
                
        openModal.$inject = [
            '$stateParams',
            'educacao.matriz.DisciplinaService',
            '$modalStates'
        ];

        function openModal($stateParams, DisciplinaService, $modalStates) {
            $modalStates.open({
                getMethod: DisciplinaService.get,
                templateUrl: 'planejamento/matriz/disciplina/disciplinas.cad.html',
                controller: 'educacao.matriz.DisciplinasCadCtrl as vm',
                propertyName: 'disciplina',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matriz')
        .directive('appSelectMatrizQuadroVagas', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ngclass="ngclass" ng-model="ngModel" ng-required="ngRequired" search="vm.searchMatriz" ng-disabled="ngDisabled" ng-change="ngChange()"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                estabelecimento: '=?',
                ngChange: '&?',
                ngclass: '=',
                anoLetivo: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'educacao.matricula.QuadroVagasService','educacao.rematricula.RematriculaService'];

    function Controller($scope, QuadroVagasService,RematriculaService) {

        var vm = this;

        vm.searchMatriz = searchMatriz;

        function searchMatriz(params) {
            var idEstabelecimento = _.get($scope.estabelecimento, 'id') || -1,
            idAnoLetivo = _.get($scope.anoLetivo,'id');

            var filter = 'estabelecimento = ' + idEstabelecimento;

            if($scope.anoLetivo){
                return RematriculaService.getVagas(idEstabelecimento,idAnoLetivo).then(afterSearch);
            }   
            
            //TODO: REMOVER TRATAMENTO DO FRONT NA PRÓXIMA ETAPA DA REMATRÍCULA
            return QuadroVagasService.getMatrizByEstabelecimento(params, filter).then(afterSearch);


            function afterSearch(data){
                var dados = {};
                var term = decodeURI(params.term);

                var matrizes = _.filter(_.pluck(data, 'matriz'), function(matriz) {
                    return matriz.descricao.toLowerCase()
                        .indexOf(term.replace(/%/g, ' ').trim().toLowerCase()) >= 0;
                });

                matrizes = _.map(matrizes, function(matriz) {
                    return {
                        id: matriz.id,
                        descricao: matriz.descricao
                    };
                });

                dados.content = _.uniq(matrizes, 'id');

                return dados;
            }
        }
    }

})();


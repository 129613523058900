(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('edAlunoVerifyJustificativa', edVerifyJustificativa);

    edVerifyJustificativa.$inject = ['$injector', '$rootScope'];

    function edVerifyJustificativa($injector, $rootScope) {

        function postLink(scope, element, attrs, ngModel) {
            scope.$watch(attrs.ngModel, function(newvalue, oldvalue) {
                var invalidOld = !oldvalue;
                var invalidNew = !newvalue;

                if (invalidNew !== invalidOld || ngModel.$pristine) {
                    $rootScope.$broadcast('refreshDisabledJustificativa', {
                        disabledJustificativa: invalidNew,
                        model: attrs.ngModel
                    });
                }
            });
        }

        return {
            restrict: 'AE',
            require: 'ngModel',
            link: postLink
        };
    }
})();

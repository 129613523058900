/* jshint ignore:start */
(function () {
    'use strict';

    angular.module('fusioncharts-order')
        .value('fusioncharts-order2', 'fusioncharts-order');
})();
/*
 FusionCharts JavaScript Library
 Copyright FusionCharts Technologies LLP
 License Information at <http://www.fusioncharts.com/license>

 @version 3.10.0
 */
FusionCharts.register("module", ["private", "modules.renderer.js-gradientlegend", function () {
    function eb(f, h, ya) {
        var N = f[0], H = f[1];
        f = f[2];
        N += (h[0] - N) * ya;
        H += (h[1] - H) * ya;
        h = f + (h[2] - f) * ya;
        return {hex: (B + (N << 16 | H << 8 | h).toString(16)).slice(-6), rgb: [N, H, h]}
    }

    function zb(f, h) {
        return f.maxvalue - h.maxvalue
    }

    function gb(h) {
        var B, ya, N = h.colorRange || {}, H = h.dataMin, w = h.dataMax, sa = h.sortLegend || !1, C = h.mapByCategory || !1, G = h.defaultColor, pa = h.numberFormatter, O = N.color;
        h = this.colorArr = [];
        var V, ea, qa;
        this.mapByCategory = C;
        "1" === N.mapbypercent && (this.mapbypercent = !0);
        if ("1" === N.gradient && !C) {
            this.gradient = !0;
            ya = Ob(Oa(N.startcolor, N.mincolor, N.code));
            sa = Fb(Ob(Oa(ya, G, "CCCCCC")));
            C = this.scaleMin = Ua(N.startvalue, N.minvalue, this.mapbypercent ? 0 : H);
            h.push({code: ya, maxvalue: C, label: I(N.startlabel), codeRGB: Fb(ya)});
            if (O && (B = O.length))for (H = 0; H < B; H += 1)G = O[H], ya = Ob(Oa(G.color, G.code)), ea = Ua(G.value, G.maxvalue), qa = Ua(G.minvalue), ea > C && h.push({
                code: ya,
                maxvalue: ea,
                userminvalue: qa,
                label: I(Oa(G.label, G.displayvalue)),
                codeRGB: Fb(ya)
            });
            h.sort(zb);
            B = h.length;
            for (H = 1; H < B; H += 1)G = h[H], ya = G.maxvalue - C, 0 < ya ? (G.minvalue = C, G.range = ya, C = G.maxvalue) : (h.splice(H, 1), --H, --B);
            2 <= h.length && (this.scaleMax = C, h[H - 1].label = Oa(N.endlabel, h[H - 1].label, h[H - 1].displayvalue));
            1 === h.length && (ea = Ua(N.maxvalue, this.mapbypercent ? 100 : w), h.push({
                minvalue: C,
                maxvalue: ea,
                range: ea - C,
                label: N.endlabel
            }), this.scaleMax = ea, delete h[0].code);
            N = h[0];
            w = h[h.length - 1];
            N.code && w.code || (ya = f(sa), B = z((ya[2] = 0, ya)), ya = z((ya[2] = 100, ya)), N.code || (N.codeRGB = B, N.code = K(B)), w.code ||
            (w.codeRGB = ya, w.code = K(ya)));
            B = h.length;
            for (H = 1; H < B; H += 1)if (G = h[H], G.code) {
                if (V)for (w = G, qa = N.maxvalue, O = w.maxvalue - qa; V < H; V += 1)sa = h[V], ya = eb(N.codeRGB, w.codeRGB, (sa.maxvalue - qa) / O), sa.code = ya.hex, sa.codeRGB = ya.rgb;
                V = null;
                N = G
            } else V = V || H;
            if (void 0 === this.scaleMin || void 0 === this.scaleMax)this.noValidRange = !0
        } else if (O && (B = O.length)) {
            for (H = 0; H < B; H += 1)G = O[H], ya = Oa(G.color, G.code), ea = Ua(G.maxvalue), qa = Ua(G.minvalue), V = Oa(G.label, G.displayvalue, C ? ja : pa.dataLabels(qa) + " - " + pa.dataLabels(ea)), (ya && ea > qa ||
            C && V) && h.push({code: ya, maxvalue: ea, minvalue: qa, label: I(V), labelId: V.toLowerCase()});
            h.length ? sa && h.sort(zb) : this.noValidRange = !0
        }
    }

    function O(f, h) {
        return h ? oa(100 * f) / 100 + "%" : cb(f, ja).toString()
    }

    var w = this, Da = w.hcLib, Va = w.window, Va = /msie/i.test(Va.navigator.userAgent) && !Va.opera, Ua = Da.pluckNumber, B = Da.COLOR_BLACK, Sb = Da.COLOR_GLASS, db = Da.FC_CONFIG_STRING, h = Da.graphics, z = h.HSBtoRGB, f = h.RGBtoHSB, K = h.RGBtoHex, Fb = h.HEXtoRGB, G = Da.COMMASTRING, ja = Da.BLANKSTRING, I = Da.parseUnsafeString, ua = Da.graphics.convertColor,
        pa = Da.POSITION_TOP, qb = Da.POSITION_MIDDLE, Ta = Da.POSITION_START, Pb = Da.POSITION_END, Tb = Da.graphics.getDarkColor, hb = Da.graphics.getLightColor, Oa = Da.pluck, cb = Da.getValidValue, ib = Da.toRaphaelColor, Ab = Da.hasTouch, oa = Math.round, Pa = Math.max, ea = Math.min, Aa = Math.abs, Ia, Gb, Bb, ac = "rgba(192,192,192," + (Va ? .002 : 1E-6) + ")", Ob = function (f) {
            return f && f.replace(/^#?([a-f0-9]+)/ig, "$1")
        };
    gb.prototype = {
        getColorObj: function (f) {
            var h = this.colorArr, B = this.gradient ? 1 : 0, N = h[B], w;
            if (this.mapByCategory) {
                for (f = I(f).toLowerCase(); N;) {
                    if (N.labelId ===
                        f)return {code: N.code, seriesIndex: B};
                    B += 1;
                    N = h[B]
                }
                return {outOfRange: !0}
            }
            if (this.gradient) {
                if (this.scaleMin <= f && this.scaleMax >= f) {
                    for (; N && N.maxvalue < f;)B += 1, N = h[B];
                    f = (f - N.minvalue) / N.range;
                    return {code: eb(h[B - 1].codeRGB, N.codeRGB, f).hex}
                }
                return {outOfRange: !0}
            }
            for (; N;) {
                if (N.maxvalue > f && N.minvalue <= f)return {code: N.code, seriesIndex: B};
                N.maxvalue === f && (w = B);
                B += 1;
                N = h[B]
            }
            return (N = h[w]) && N.maxvalue === f ? {code: N.code, seriesIndex: w} : {outOfRange: !0}
        }
    };
    gb.prototype.constructor = gb;
    Da.colorRange = gb;
    Ia = Da.configureGradientLegendOptions =
        function (f, h) {
            var w = f.legend, N = h.chart;
            w.legendSliderBorderWidth = Ua(N.legendpointerborderthickness, 1);
            w.legendSliderBorderColor = ua(Oa(N.legendpointerbordercolor, Tb("ABABAB", 70)), Ua(N.legendpointerborderalpha, 100));
            w.legendSliderWidth = Ua(N.legendpointerwidth, N.legendpointerswidth, 12);
            w.legendSliderHeight = Ua(N.legendpointerheight, N.legendpointersheight, 12);
            w.legendColorBoxBorderColor = w.borderColor;
            w.legendColorBoxBorderWidth = w.borderWidth;
            w.legendScaleColor = ua(Oa(N.legendscalelinecolor, B), Ua(N.legendscalelinealpha,
                100));
            w.legendScalePadding = Ua(N.legendscalepadding, 4);
            w.legendScaleLineThickness = Ua(N.legendscalelinethickness, 1);
            w.legendScaleTickDistance = Ua(N.legendscaletickdistance, 6);
            w.itemStyle.cursor = "default";
            w.interActivity = Ua(N.interactivelegend, 1);
            w.legendSliderColor = ua(Oa(N.legendpointercolor, hb("ABABAB", 50)), Ua(N.legendpointeralpha, 100))
        };
    Da.placeGLegendBlockRight = function (f, h, B, w, H) {
        this.configureLegendOptions(f, h.chart, !0, H, B);
        Ia(f, h);
        H = this.snapLiterals || (this.snapLiterals = {});
        var G = f[db], sa = this.smartLabel ||
            G.smartLabel, C = f.legend, pa = f.chart.spacingRight, z, I, V = C.textPadding = 2, oa = 2 * V, qa = C.title.padding, ia = 0, K = 0, Aa = 2 * C.padding;
        h = Ua(h.chart.legendpadding, 7) + C.borderWidth / 2 + 1;
        var R = f.colorRange || {}, Ja = R.colorArr, Da = R.mapbypercent, ua = R.scaleMin, Oa = R.scaleMax - ua, Ta = C.legendSliderWidth, Z = C.legendSliderHeight / 2;
        I = C.legendScalePadding;
        var A = C.legendScaleTickDistance, da = C.itemStyle || {};
        z = Ua(parseInt(da.lineHeight, 10) || 12);
        var Ya = .75 * z, Ka = B - Aa, Ga, xa, Ha = 0, Qa, r, fa, Ba, La, Fa, Sa;
        w -= Aa;
        if (!R.noValidRange && Ja && 1 < (xa =
                Ja.length)) {
            --xa;
            C.title.text !== ja && (sa.setStyle(C.title.style), z = sa.getSmartText(C.title.text, Ka, Pa(z, w / 4)), C.title.text = z.text, ia = z.width + Aa, w -= K = z.height + qa);
            sa.setStyle(da);
            z = sa.lineHeight;
            Ka -= A + I + Ta;
            C.colorBoxX = Ta;
            qa = Pa(z, Ka / 2);
            Ka = ea(Ka - qa - 4, z);
            Qa = Pa(z, w / 2);
            Ga = w / 4;
            A = Ja[0];
            A.scaleLabel = O(A.maxvalue, Da);
            z = sa.getSmartText(A.label, Ga, Ka);
            A.label = z.text;
            da = z.height;
            A.labelY = Ya - z.height / 2;
            I = sa.getSmartText(A.scaleLabel, qa, Qa);
            A.scaleLabel = I.text;
            R = I.height / 2;
            r = I.width;
            A.scaleLabelY = Ya - I.height / 2;
            C.colorBoxY =
                Pa(R, z.width + oa, Z) + K;
            A = Sa = Ja[xa];
            A.scaleLabel = O(A.maxvalue, Da);
            z = sa.getSmartText(A.label, Ga, Ka);
            A.label = z.text;
            da = Pa(da, z.height);
            A.labelY = Ya - z.height / 2;
            I = sa.getSmartText(A.scaleLabel, qa, Qa);
            A.scaleLabel = I.text;
            r = Pa(r, I.width);
            Ga = I.height / 2;
            z = Pa(z.width + oa, Ga, Z);
            A.scaleLabelY = Ya - I.height / 2;
            C.colorBoxHeight = Z = w - C.colorBoxY - z;
            Qa = Z - Ga;
            fa = Z / Oa;
            La = ea(Z - Ha, Qa - R) - 4;
            for (Ga = 1; Ga < xa; Ga += 1)A = Ja[Ga], Ba = (A.maxvalue - ua) * fa, z = sa.getSmartText(A.label, 2 * ea(Ba - Ha, Z - Ba), Ka), A.label = z.text, da = Pa(da, z.height), A.labelY =
                Ya - z.height / 2, z = z.width / 2, A.scaleLabel = O(A.maxvalue, Da), I = sa.getSmartText(A.scaleLabel, qa, 2 * ea(Ba - R, Qa - Ba)), A.scaleLabel = I.text, r = Pa(r, I.width), Fa = I.height / 2, A.scaleLabelY = Ya - I.height / 2, La = ea(La, (Ba - Pa(Fa + R, z + Ha) - 4) * Oa / A.range), Ha = z + Ba, R = Fa + Ba;
            La = Pa(ea(La, (ea(Qa - R, Z - Ha) - 4) * Oa / Sa.range, .3 * w), 0);
            C.colorBoxHeight -= La;
            C.colorBoxWidth = da && da + oa || 15;
            C.height = C.totalHeight = w + K + Aa - La;
            C.width = (r && r + V) + C.colorBoxWidth + Ta + C.legendScaleTickDistance + C.legendScalePadding + Aa;
            C.width < ia && (C.colorBoxX += (ia - C.width) /
                2, C.width = ia);
            C.width > B && (C.width = B);
            H.legendstartx = G.width - pa - C.width;
            H.legendwidth = C.width;
            H.legendendx = H.legendstartx + H.legendwidth;
            H.legendheight = C.height;
            h += C.width;
            f.chart.marginRight += h;
            return h
        }
        C.enabled = !1;
        return 0
    };
    Da.placeGLegendBlockBottom = function (f, h, B, w, H) {
        this.configureLegendOptions(f, h.chart, !1, H, B);
        Ia(f, h);
        H = this.snapLiterals || (this.snapLiterals = {});
        var G = f[db], z = this.smartLabel || G.smartLabel, C = f.legend, I = f.chart, pa = I.spacingBottom, oa = I.spacingLeft, I = I.spacingRight, V, K, qa = C.textPadding =
            2, ia = C.title.padding, Aa = 0, Da = 0, R = 2 * C.padding;
        h = Ua(h.chart.legendpadding, 7) + C.borderWidth / 2 + 1;
        var Ja = f.colorRange || {}, ua = Ja.colorArr, Xa = Ja.mapbypercent, Ta = Ja.scaleMin, Va = Ja.scaleMax - Ta, Z = C.legendSliderWidth, A = C.legendSliderHeight, da = C.legendScalePadding, Ya = C.legendScaleTickDistance, Ka = C.itemStyle || {};
        V = Ua(parseInt(Ka.lineHeight, 10) || 12);
        var Ga = .75 * V, xa = w - R, Ha, Qa, r, fa, Ba = 0, La, Fa, Sa;
        B -= R;
        if (!Ja.noValidRange && ua && 1 < (Qa = ua.length)) {
            --Qa;
            C.title.text !== ja && (z.setStyle(C.title.style), V = z.getSmartText(C.title.text,
                B, xa / 3), C.title.text = V.text, Aa = V.width + R, xa -= Da = V.height + ia);
            z.setStyle(Ka);
            V = z.lineHeight;
            xa -= Ya + da + A;
            ia = Pa(V, xa / 2);
            Ka = ea(xa - ia - 4, V);
            Ha = B / 4;
            fa = 2 * Ha;
            r = ua[0];
            r.scaleLabel = O(r.maxvalue, Xa);
            V = z.getSmartText(r.label, Ha, Ka);
            r.label = V.text;
            xa = V.height;
            r.labelY = Ga - V.height / 2;
            K = z.getSmartText(r.scaleLabel, fa, ia);
            r.scaleLabel = K.text;
            da = K.width / 2;
            Ya = K.height;
            r.code || (r.code = Oa(C.minColor, "CCCCCC"));
            C.colorBoxX = Pa(da, V.width + qa, Z);
            r = Ja = ua[Qa];
            r.scaleLabel = O(r.maxvalue, Xa);
            V = z.getSmartText(r.label, Ha, Ka);
            r.label =
                V.text;
            xa = Pa(xa, V.height);
            r.labelY = Ga - V.height / 2;
            K = z.getSmartText(r.scaleLabel, fa, ia);
            r.scaleLabel = K.text;
            Ya = Pa(Ya, K.height);
            r = K.width / 2;
            V = Pa(V.width + qa, r, Z);
            C.colorBoxWidth = Z = B - C.colorBoxX - V;
            fa = Z - r;
            La = Z / Va;
            Sa = ea(Z - Ba, fa - da) - 4;
            for (Ha = 1; Ha < Qa; Ha += 1)r = ua[Ha], Fa = (r.maxvalue - Ta) * La, V = z.getSmartText(r.label, 2 * ea(Fa - Ba, Z - Fa), Ka), r.label = V.text, xa = Pa(xa, V.height), r.labelY = Ga - V.height / 2, V = V.width / 2, r.scaleLabel = O(r.maxvalue, Xa), K = z.getSmartText(r.scaleLabel, 2 * ea(Fa - da, fa - Fa), ia), r.scaleLabel = K.text, Ya = Pa(Ya,
                K.height), K = K.width / 2, Sa = ea(Sa, (Fa - Pa(K + da, V + Ba) - 4) * Va / r.range), Ba = V + Fa, da = K + Fa;
            Sa = Pa(ea(Sa, (ea(fa - da, Z - Ba) - 4) * Va / Ja.range, .3 * B), 0);
            C.colorBoxWidth -= Sa;
            C.width = B + R - Sa;
            C.width < Aa && (C.colorBoxX += (Aa - C.width) / 2, C.width = Aa);
            C.colorBoxY = Da + A;
            C.colorBoxHeight = xa && xa + 2 * qa || 15;
            C.height = C.totalHeight = (Ya && Ya + qa) + C.colorBoxHeight + Da + A + C.legendScaleTickDistance + C.legendScalePadding + R;
            C.height > w && (C.height = w);
            H.legendstartx = oa + .5 * (G.width - oa - I - C.width) + (C.x || 0);
            H.legendwidth = C.width;
            H.legendendx = H.legendstartx +
                H.legendwidth;
            H.legendstarty = G.height - pa - C.height;
            H.legendheight = C.height;
            H.legendendy = H.legendstarty + H.legendheight;
            h += C.height;
            f.chart.marginBottom += h;
            return h
        }
        C.enabled = !1;
        return 0
    };
    Gb = function () {
        return {point: this}
    };
    Bb = function (f) {
        return oa(100 * f) / 100
    };
    Da.rendererRoot.drawGradientLegendItem = function (f) {
        var h = this, z = h.paper, N = h.options, H = h.canvasLeft, I = h.canvasTop, O = h.canvasWidth, C = h.canvasHeight, ea = N.colorRange, K = N.chart.textDirection, ua, V, Ia, qa, ia = N.legend, Va = Ua(ia.padding, 4), cb = ia.itemStyle,
            N = ia.symbolStyle, R = ia.interActivity, Ja = f.elements;
        f = Ja.elementGroup.trackTooltip(!0);
        var eb = "vertical" === ia.layout, Xa, jb, db, Z, A, da, Ya = 0, Ka = ia.lighting3d, Ga = ia.colorBoxWidth, xa = ia.colorBoxHeight, Ha = Ga, Qa = xa, r = {
            FCcolor: {
                color: ja,
                alpha: ja,
                angle: 0,
                ratio: ja
            }
        }, fa = r.FCcolor, Ba = ia.colorBoxX + Va, La = ia.colorBoxY + Va, Fa, Sa, hb = ia.legendColorBoxBorderColor, Ca = ia.legendColorBoxBorderWidth, gb = ["M"], zb = ia.legendScaleColor;
        A = ia.legendScalePadding;
        var Fb = ia.legendScaleLineThickness, Cb = Fb % 2 / 2;
        V = ia.legendScaleTickDistance;
        var sb = ia.legendSliderWidth, tb = ia.legendSliderHeight;
        Z = Qa / 2;
        qa = Ha / 2;
        da = sb / 2;
        var Za = tb / 2, Ma, Db, ub, lb, mb;
        Sa = 0;
        var Va = ia.legendSliderColor, Hb = ia.legendSliderBorderColor, ia = ia.legendSliderBorderWidth;
        Ma = ia % 2 / 2;
        var nb, ra = {isFirst: !0}, la = {}, ob, b, d, a, c = {}, e = !1;
        if (ea && (ua = ea.colorArr) && 1 < (Ia = ua.length)) {
            ra.toolText = ob = jb = ea.scaleMin;
            la.toolText = b = ea = ea.scaleMax;
            db = ea - jb;
            ra.snapPX = la.snapPX = 0;
            ra.tooltipConstraint = la.tooltipConstraint = "chart";
            ra.getLabelConfig = la.getLabelConfig = Gb;
            ra.tooltipPos = [0, 0];
            la.tooltipPos =
                [0, 0];
            la.tooltipOffsetReference = ra.tooltipOffsetReference = {};
            la.tooltipOffsetReference.left = ra.tooltipOffsetReference.left += H - 20;
            la.tooltipOffsetReference.top = ra.tooltipOffsetReference.top += I;
            d = Ja.colorBox = z.group("colorBox", f);
            if (eb) {
                ra.tooltipPos[0] = la.tooltipPos[0] = O + H;
                Sa = 270;
                fa.angle = 90;
                H = Ba - sb;
                O = Ba + Ha;
                I = La - Za;
                Db = La + Za;
                H = oa(Ba - sb) + Ma;
                O = oa(Ba) + Ma;
                I = oa(La - Za) + Ma;
                Db = oa(La + Za) + Ma;
                C = oa(Ba + Ha) + Ma;
                ub = oa(La - 2) + Ma;
                lb = oa(La + 2) + Ma;
                mb = oa(La) + Ma;
                Xa = Ba - da / 2;
                Fa = oa(Xa - Za) + Ma;
                Xa = oa(Xa) + Ma;
                Z = La - Za / 2;
                da = oa(Z + Za) +
                    Ma;
                Z = oa(Z) + Ma;
                Ga /= 2;
                Ma = ["M", H, I, "L", O, I, O, ub, C, mb, O, lb, O, Db, H, Db, "Z", "M", Fa, Z, "L", Xa, Z, "M", Fa, mb, "L", Xa, mb, "M", Fa, da, "L", Xa, da];
                Z = Ba + Ha + A;
                C = oa(Z + V) + Cb;
                Z = oa(Z) + Cb;
                Fa = Ba + qa;
                O = Ia - 1;
                for (V = 0; V < Ia; V += 1)qa = ua[V], da = (qa.maxvalue - jb) / db, Xa = Qa * da + La, A = oa(Xa) + Cb, V ? (fa.ratio += G, fa.color += G, fa.alpha += G, gb.push("L", Z, A, C, A, "M", Z, A), V === O ? (Za = Pb, A = Xa + 2) : (Za = qb, A = Xa)) : (gb.push(Z, A, "L", C, A, "M", Z, A), Za = Ta, A = Xa - 2), fa.ratio += 100 * (da - Ya), fa.color += Oa(qa.code, B), fa.alpha += Oa(qa.alpha, 100), Ya = da, qa.legendItem = z.text(f).attr({
                    text: qa.label,
                    x: Fa, y: A, "text-anchor": Za, direction: K, "vertical-align": qb
                }).rotate(Sa, Fa, A).css(cb), qa.legendSymbol = z.text(f).attr({
                    text: qa.scaleLabel,
                    x: C,
                    y: Xa,
                    "text-anchor": Ta,
                    direction: K,
                    "vertical-align": qb
                }).css(cb);
                ra.xMin = la.xMin = 0;
                ra.xMax = la.xMax = 0;
                la.yIni = ra.yMin = la.yMin = 0;
                ra.yIni = ra.yMax = la.yMax = Qa;
                ra.x = la.x = 0;
                ra._oriY = ra.y = 0;
                la._oriY = la.y = Qa;
                K = tb + Ha;
                ua = sb
            } else {
                ra.tooltipPos[1] = la.tooltipPos[1] = C + I;
                H = oa(Ba - da) + Ma;
                O = oa(Ba + da) + Ma;
                I = oa(La - tb) + Ma;
                Db = oa(La + Qa) + Ma;
                C = oa(Ba - 2) + Ma;
                Xa = oa(Ba + 2) + Ma;
                Fa = oa(Ba) + Ma;
                ub = oa(La) +
                    Ma;
                lb = La - Za / 2;
                mb = oa(lb - Za) + Ma;
                lb = oa(lb) + Ma;
                Sa = Ba - da / 2;
                qa = oa(Sa + da) + Ma;
                Sa = oa(Sa) + Ma;
                xa /= 2;
                Ma = ["M", H, I, "L", O, I, O, ub, Xa, ub, Fa, Db, C, ub, H, ub, "Z", "M", Sa, mb, "L", Sa, lb, "M", Fa, mb, "L", Fa, lb, "M", qa, mb, "L", qa, lb];
                A = La + Qa + A;
                Sa = oa(A + V) + Cb;
                A = oa(A) + Cb;
                Xa = La + Z;
                O = Ia - 1;
                for (V = 0; V < Ia; V += 1)qa = ua[V], da = (qa.maxvalue - jb) / db, C = Ha * da + Ba, Z = oa(C) + Cb, V ? (fa.ratio += G, fa.color += G, fa.alpha += G, gb.push("L", Z, A, Z, Sa, "M", Z, A), V === O ? (Za = Ta, Z = C + 2) : (Za = qb, Z = C)) : (gb.push(Z, A, "L", Z, Sa, "M", Z, A), Za = Pb, Z = C - 2), fa.ratio += 100 * (da - Ya), fa.color +=
                    Oa(qa.code, B), fa.alpha += Oa(qa.alpha, 100), Ya = da, qa.legendItem = z.text(f).attr({
                    text: qa.label,
                    x: Z,
                    y: Xa,
                    "text-anchor": Za,
                    direction: K,
                    "vertical-align": qb
                }).css(cb), qa.legendSymbol = z.text(f).attr({
                    text: qa.scaleLabel,
                    x: C,
                    y: Sa,
                    "text-anchor": qb,
                    direction: K,
                    "vertical-align": pa
                }).css(cb);
                la.xIni = ra.xMin = la.xMin = 0;
                ra.xIni = ra.xMax = la.xMax = Ha;
                ra.yMin = la.yMin = 0;
                ra.yMax = la.yMax = 0;
                ra.y = la.y = 0;
                ra._oriX = ra.x = 0;
                la._oriX = la.x = Ha;
                K = sb;
                ua = tb + Qa
            }
            Da.rendererRoot.resetLegendSlider = function () {
                var a = c[1], b = c[0];
                a && (nb(la.xMin,
                    la.yMin, la.xMin - a.xChange, la.yMin - a.yChange, !0), e = !0);
                b && (nb(ra.xMax, ra.yMax, ra.xMax - b.xChange, ra.yMax - b.yChange), e = !0)
            };
            Ja.colorBox = z.rect(d).attr({
                x: Ba,
                y: La,
                width: Ha,
                height: Qa,
                fill: ib(r),
                stroke: hb,
                strokeWidth: Ca
            });
            Ka && (Ja.colorBoxEffect = z.rect(d).attr({
                x: Ba,
                y: La,
                width: Ga,
                height: xa,
                fill: Sb,
                "stroke-width": 0
            }));
            Ja.scale = z.path(f).attr({path: gb, stroke: zb, "stroke-width": Fb});
            nb = function (d, e, m, f, l) {
                var p;
                p = c[+!!l] = c[+!!l] || {};
                p.xChange = d;
                p.yChange = e;
                eb ? (p = e * db / Qa + jb, f = 0 < e ? f : f + e + .01) : (p = d * db / Ha + jb, m = 0 <
                d ? m : m + d + .01);
                d = Bb(p);
                l ? (Ja.slider1.translate(m, f), Ja.slider1Tracker.toFront().translate(m, f).tooltip(d, null, null, !0), ob = p) : (Ja.slider2.translate(m, f), Ja.slider2Tracker.toFront().translate(m, f).tooltip(d, null, null, !0), b = p);
                R && (a = clearTimeout(a), a = setTimeout(function () {
                    h.setScaleRange && h.setScaleRange(ob, b)
                }, 100))
            };
            Ia = function (a, b) {
                var c = 0, d = c, e, f = this.isFirst, k = f ? la : ra;
                if (eb) {
                    d = this._startY + b;
                    0 >= d && (d = 0);
                    d > Qa && (d = Qa);
                    if (f ? d > k.y : d < k.y)d = k.y;
                    Aa(d - this.y) >= (this.snapPX || 0) && (e = !0)
                } else {
                    c = this._startX +
                        a;
                    0 >= c && (c = 0);
                    c > Ha && (c = Ha);
                    if (f ? c > k.x : c < k.x)c = k.x;
                    Aa(c - this.x) >= (this.snapPX || 0) && (e = !0)
                }
                e && (nb(c, d, c - this.x, d - this.y, f), this.x = c, this.y = d)
            };
            Ka = function () {
                var a = this.isFirst, c = a ? la : ra;
                e && (e = !1, this.x = c.xIni, this.y = c.yIni, c.x = c._oriX, c.y = c._oriY);
                this._startX = this.x;
                this._startY = this.y;
                this._scaleStart = ob;
                this._scaleEnd = b;
                w.raiseEvent("LegendPointerDragStart", {
                    pointerIndex: a ? 0 : 1,
                    pointers: [{value: ob}, {value: b}],
                    legendPointerHeight: tb,
                    legendPointerWidth: sb
                }, h.logic.chartInstance)
            };
            Ga = function () {
                var a =
                    this._scaleStart, c = this._scaleEnd;
                w.raiseEvent("LegendPointerDragStop", {
                    pointerIndex: this.isFirst ? 0 : 1,
                    pointers: [{value: ob}, {value: b}],
                    legendPointerHeight: tb,
                    legendPointerWidth: sb
                }, h.logic.chartInstance);
                a === ob && c === b || w.raiseEvent("LegendRangeUpdated", {
                    previousMinValue: a,
                    previousMaxValue: c,
                    minValue: ob,
                    maxValue: b
                }, h.logic.chartInstance);
                delete this._scaleStart;
                delete this._scaleEnd
            };
            xa = Bb(jb);
            Ja.slider1 = z.path(f).attr({path: Ma, fill: Va, "stroke-width": ia, stroke: Hb});
            Ab && (H -= .5 * (Pa(30, K) - K), I -= .5 * (Pa(40,
                    ua) - ua), K = Pa(30, K), ua = Pa(40, ua));
            Ja.slider1Tracker = z.rect(f).attr({
                ishot: !0,
                width: K,
                height: ua,
                x: H,
                y: I,
                fill: ac,
                stroke: "none"
            }).drag(Ia, Ka, Ga, ra, ra, ra).tooltip(xa, null, null, !0).css(N);
            xa = Bb(ea);
            Ja.slider2 = z.path(f).attr({path: Ma, fill: Va, "stroke-width": ia, stroke: Hb}).translate(la.x, la.y);
            Ja.slider2Tracker = z.rect(f).attr({
                ishot: !0,
                width: K,
                height: ua,
                x: H,
                y: I,
                fill: ac,
                stroke: "none"
            }).translate(la.x, la.y).css(N).drag(Ia, Ka, Ga, la, la, la).tooltip(xa, null, null, !0)
        }
    }
}]);
FusionCharts.register("module", ["private", "modules.renderer.js-powercharts", function () {
    function eb(b, d, a) {
        var c = b.fcObj;
        O.hcLib.createChart(c, b.container, b.type, void 0, void 0, !1, !0);
        w.raiseEvent("chartUpdated", I({sourceEvent: a}, d), c, [c.id])
    }

    function zb(b, d, a, c, e, g) {
        var q = Ra.atan((d - c) / (b - a)), m = [];
        0 > q && (q = 2 * Ra.PI + q);
        if (c > d) {
            if (a >= b && q > Ra.PI || a < b && q > Ra.PI)q -= Ra.PI
        } else if (a >= b && q < Ra.PI && 0 !== q || a < b && q < Ra.PI)q += Ra.PI;
        "undefined" == typeof g ? (a = b + e * ya(q), e = d + e * bb(q)) : (e = sa(e) / 2, g = sa(g) / 2, a = b + (e = b < a ? e : -e),
            e = d + e * Ra.tan(q), sa(d - e) > sa(g) && (e = d + (g = d < c ? g : -g), a = b + g / Ra.tan(q)));
        m.push("L", a, e, a + 10 * ya(q + .79), e + 10 * bb(q + .79), "M", a + 10 * ya(q - .79), e + 10 * bb(q - .79), "L", a, e);
        return m
    }

    function gb(b, d) {
        var a;
        d._origAttr || (d._origAttr = {});
        for (a in b)ac.test(a) || (d._origAttr[a] = b[a]);
        return d._origAttr
    }

    var O = this, w = O.hcLib, Da = w.Raphael, Va = O.window, Ua = Va.document, B = w.BLANKSTRING, Sb = w.createTrendLine, db = w.parseTooltext, h = w.pluck, z = w.getValidValue, f = w.pluckNumber, K = w.getFirstValue, Fb = w.getDefinedColor, G = w.parseUnsafeString,
        ja = w.FC_CONFIG_STRING, I = w.extend2, ua = w.getDashStyle, pa = w.toRaphaelColor, qb = w.toPrecision, Ta = w.hasSVG, Pb = w.createContextMenu, Tb = w.isIE, hb = w.regex.dropHash, Oa = w.HASHSTRING, cb = function (b, d) {
            var a;
            b || (b = {});
            for (a in d)b[a] = d[a];
            return b
        }, ib = w.each, Ab = w.addEvent, oa = w.removeEvent, Pa = w.getTouchEvent, ea = function (b) {
            return void 0 !== b && null !== b
        }, Aa = "rgba(192,192,192," + (Tb ? .002 : 1E-6) + ")", Ia = w.TOUCH_THRESHOLD_PIXELS, Gb = w.CLICK_THRESHOLD_PIXELS, Bb = 8 === Va.document.documentMode ? "visible" : "", ac = /^_/, Ob = w.BGRATIOSTRING,
        Ra = Math, bb = Ra.sin, ya = Ra.cos, N = Ra.round, H = Ra.min, ab = Ra.max, sa = Ra.abs, C = Ra.PI, tc = Ra.ceil, $b = Ra.floor, fc = Ra.sqrt, V = Ra.pow, gc = C / 180, qa = 2 * C, ia = w.hasTouch, hc = ia ? Ia : Gb, bc = w.graphics.getColumnColor, R = w.getFirstColor, Ja = w.setLineHeight, Ac = w.pluckFontSize, Xa = w.pluckColor, jb = w.getFirstAlpha, uc = w.graphics.getDarkColor, Z = w.graphics.getLightColor, A = w.graphics.convertColor, da = w.COLOR_TRANSPARENT, Ya = w.POSITION_CENTER, Ka = w.POSITION_TOP, Ga = w.POSITION_BOTTOM, xa = w.POSITION_RIGHT, Ha = w.POSITION_LEFT, Qa = w.bindSelectionEvent,
        r = w.chartAPI, fa = w.graphics.mapSymbolName, Ia = r.singleseries, Ba = w.COMMASTRING, La = w.ZEROSTRING, Fa = w.HUNDREDSTRING, Sa = w.COMMASPACE, Mb = w.getMouseCoordinate, Ca = w.plotEventHandler, Bc = O.xssEncode, ic = w.SHAPE_RECT, jc = w.deltend, Gb = w.graphics, Cb = Gb.parseColor, sb = Gb.getValidColor, tb = w.placeHorizontalAxis, Za = w.placeVerticalAxis, Ma = w.stepYAxisValues, Db = w.adjustHorizontalCanvasMargin, ub = w.adjustVerticalCanvasMargin, lb = w.getDataParser, mb = {
            pageX: 0,
            pageY: 0
        }, Hb, nb, ra, la = function () {
            this.data("move", !1);
            clearTimeout(this._longpressactive);
            delete this._longpressactive
        }, ob = w.createElement;
    w.eventList.chartupdated = "FC_ChartUpdated";
    w.eventList.dataposted = "FC_DataPosted";
    w.eventList.dataposterror = "FC_DataPostError";
    w.eventList.datarestored = "FC_DataRestored";
    O.addEventListener("rendered", function (b) {
        b = b.sender;
        var d = b.__state, a = b.jsVars && b.jsVars.instanceAPI;
        !d.listenersAdded && a && "function" === typeof a.getCollatedData && (b.addEventListener(["chartupdated", "dataupdated", "rendered"], function (a) {
            delete a.sender.__state.hasStaleData
        }), d.listenersAdded = !0)
    });
    r("spline", {
        friendlyName: "Spline Chart",
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "spline",
        rendererId: "spline"
    }, r.linebase);
    r("splinearea", {
        friendlyName: "Spline Area Chart",
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "areaspline",
        anchorAlpha: "100",
        rendererId: "spline"
    }, r.area2dbase);
    r("msspline", {
        friendlyName: "Multi-series Spline Chart",
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "spline",
        rendererId: "spline"
    }, r.mslinebase);
    r("mssplinedy", {
        friendlyName: "Multi-series Dual Y-Axis Spline Chart",
        standaloneInit: !0,
        creditLabel: !1,
        isDual: !0,
        series: r.mscombibase.series,
        secondarySeriesType: "spline",
        secondarySeriesFilter: {spline: !0},
        defaultSeriesFilter: {spline: !0}
    }, r.msspline);
    r("mssplinearea", {
        friendlyName: "Multi-series Spline Area Chart",
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "areaspline",
        rendererId: "spline"
    }, r.msareabase);
    r("msstepline", {
        friendlyName: "Multi-series Step Line Chart",
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "line",
        rendererId: "cartesian",
        stepLine: !0
    }, r.mslinebase);
    r("inversemsline", {
        friendlyName: "Inverted Y-Axis Multi-series Line Chart",
        standaloneInit: !0,
        creditLabel: !1,
        inversed: !0,
        rendererId: "cartesian"
    }, r.mslinebase);
    r("inversemsarea", {
        friendlyName: "Inverted Y-Axis Multi-series Area Chart",
        standaloneInit: !0,
        creditLabel: !1,
        inversed: !0,
        rendererId: "cartesian"
    }, r.msareabase);
    r("inversemscolumn2d", {
        friendlyName: "Inverted Y-Axis Multi-series Column Chart",
        standaloneInit: !0,
        creditLabel: !1,
        inversed: !0,
        rendererId: "cartesian"
    }, r.mscolumn2dbase);
    r("logmsline", {
        friendlyName: "Multi-series Log Line Chart",
        standaloneInit: !0,
        isValueAbs: !0,
        isLog: !0,
        configureAxis: r.logbase.configureAxis,
        pointValueWatcher: r.logbase.pointValueWatcher,
        getLogAxisLimits: r.logbase.getLogAxisLimits,
        creditLabel: !1,
        rendererId: "cartesian"
    }, r.mslinebase);
    r("logmscolumn2d", {
        friendlyName: "Multi-series Log Column Chart",
        standaloneInit: !0,
        isLog: !0,
        isValueAbs: !0,
        configureAxis: r.logbase.configureAxis,
        pointValueWatcher: r.logbase.pointValueWatcher,
        getLogAxisLimits: r.logbase.getLogAxisLimits,
        creditLabel: !1,
        rendererId: "cartesian"
    }, r.mscolumn2dbase);
    r("logstackedcolumn2d", {
        friendlyName: "Stacked Log Column Chart",
        standaloneInit: !0,
        creditLabel: !1,
        isStacked: !0
    }, r.logmscolumn2d);
    r("errorbar2d", {
        friendlyName: "Error Bar Chart",
        standaloneInit: !0,
        creditLabel: !1,
        showValues: 0,
        rendererId: "cartesian",
        isErrorChart: !0,
        fireGroupEvent: !0,
        chart: function () {
            var b = this.base.chart.apply(this, arguments), d = this.drawErrorValue;
            b.callbacks || (b.callbacks = []);
            b.callbacks.push(function () {
                for (var a = this.elements.plots, b = this.dataset || this.options.series, e = a && a.length; e--;)b[e] &&
                d.call(this, a[e], b[e])
            });
            return b
        },
        point: function (b, d, a, c, e, g, q, m, v) {
            b = f(c.ignoreemptydatasets, 0);
            var l = !1, p = !f(c.halferrorbar, 1), k = e[ja], n = h(this.isValueAbs, k.isValueAbs, !1), t = f(a.showvalues, k.showValues), u = f(d.yAxis, 0), E = f(c.use3dlighting, 1), D = e[ja].numberFormatter, x = this.colorManager, y = f(c.useplotgradientcolor, 1) ? Fb(c.plotgradientcolor, x.getColor("plotGradientColor")) : B, M = h(a.alpha, c.plotfillalpha, "100"), ga = jb(h(a.errorbaralpha, c.errorbaralpha, M)), wa = f(a.dashed, c.plotborderdashed, 0), Ea = f(a.dashlen,
                c.plotborderdashlen, 5), F = f(a.dashgap, c.plotborderdashgap, 4), P = h(d.type, this.defaultSeriesType), Q = e.plotOptions[P] && e.plotOptions[P].stacking, P = x.getPlotColor(), Wa, na, Na, r, X, w, aa, L, J, S, za, U, T, ba, ca, Y;
            this.errorBarShadow = f(c.errorbarshadow);
            d.errorBar2D = !0;
            d.name = z(a.seriesname);
            Q || (d.columnPosition = f(v, m, q));
            if (0 === f(a.includeinlegend) || 0 === M || void 0 === d.name)d.showInLegend = !1;
            d.errorBarWidthPercent = f(a.errorbarwidthpercent, c.errorbarwidthpercent, 70);
            d.errorBarColor = A(R(h(a.errorbarcolor, c.errorbarcolor,
                "AAAAAA")), ga);
            d.errorBarThickness = f(a.errorbarthickness, c.errorbarthickness, 1);
            d.color = h(a.color, P).split(",")[0].replace(/^#?/g, "#");
            if (q = a.data)for (U = h(c.plotborderthickness, "1"), Q = e.chart.useRoundEdges, v = this.isBar, m = /3d$/.test(e.chart.defaultSeriesType), ba = h(c.plotbordercolor, x.getColor("plotBorderColor")).split(",")[0], ca = "0" == c.showplotborder ? "0" : h(c.plotborderalpha, "100"), ca = m ? c.showplotborder ? ca : "0" : ca, ba = m ? h(c.plotbordercolor, "#FFFFFF") : ba, x = 0; x < g; x += 1)(na = q[x]) ? (L = D.getCleanValue(na.value,
                n), J = D.getCleanValue(na.errorvalue, n), null === L ? d.data.push({y: null}) : (l = !0, aa = k.oriCatTmp[x], r = h(na.color, a.color, P), X = jb(h(na.alpha, M)) + B, Wa = h(na.ratio, a.ratio, c.plotfillratio), Na = h(360 - c.plotfillangle, 90), 0 > L && (Na = 360 - Na), S = {opacity: X / 100}, T = H(X, jb(ca)) + B, w = bc(r + "," + y, X, Wa, Na, Q, ba, T, v, m), za = {opacity: ga / 250}, Y = this.getPointStub(na, L, aa, e, a, t, u, J), aa = [], aa.push({
                errorValue: J,
                toolText: Y._errortoolText,
                shadow: za
            }), p && aa.push({errorValue: -J, toolText: Y._errortoolText, shadow: za}), Wa = this.pointHoverOptions(na,
                d, {
                    plotType: "column",
                    is3d: m,
                    isBar: v,
                    use3DLighting: E,
                    isRoundEdged: Q,
                    color: r,
                    gradientColor: y,
                    alpha: X,
                    ratio: Wa,
                    angle: Na,
                    borderWidth: U,
                    borderColor: ba,
                    borderAlpha: T,
                    borderDashed: wa,
                    borderDashGap: F,
                    borderDashLen: Ea,
                    shadow: S
                }), d.data.push(I(Y, {
                y: L,
                shadow: S,
                errorValue: aa,
                color: w[0],
                borderColor: w[1],
                borderWidth: U,
                use3DLighting: E,
                dashStyle: f(na.dashed, wa) ? ua(h(na.dashlen, Ea), h(na.dashgap, F), U) : "none",
                hoverEffects: Wa.enabled && Wa.options,
                rolloverProperties: Wa.enabled && Wa.rolloverOptions
            })), this.pointValueWatcher(e,
                L, J))) : d.data.push({y: null});
            b && !l && (d.showInLegend = !1);
            return d
        },
        pointValueWatcher: function (b, d, a) {
            var c = b[ja];
            null !== d && (a ? (b = d + a, d -= a) : b = d, c[0] || (c[0] = {}), a = c[0], a.max = a.max > b ? a.max : b, a.min = a.min < b ? a.min : b, a.max = a.max > d ? a.max : d, a.min = a.min < d ? a.min : d)
        },
        drawErrorValue: function (b, d) {
            var a = this, c = a.options, e = c.plotOptions.series, g = c[ja], g = a.smartLabel || g.smartLabel, q = a.paper, m = a.layers, v = a.xAxis[0], l = a.yAxis[0], p = isNaN(+e.animation) && e.animation.duration || 1E3 * e.animation, k = m.dataset = m.dataset || q.group("dataset-orphan"),
                n = b.errorGroup = q.group("errorBar").insertAfter(b.lineLayer || k.column || k), h = m.errorTracker || (m.errorTracker = q.group("hot-error", m.tracker || k).toBack()), u = k.errorValueGroup || (k.errorValueGroup = q.group("errorValues")), E = d.errorBar2D, D = d.data || [], x = D.length, y = b.items, M = !1 !== (c.tooltip || {}).enabled, ga, wa, Ea, F, P = b.graphics = b.graphics || [], Q = !1 === d.visible ? "hidden" : "visible", Wa = c.chart, na = Wa.textDirection, Na = Wa.valuePadding || 0, Wa = 1 == Wa.rotateValues ? 270 : void 0, r = d.columnPosition || 0, X = a.definition.chart, w =
                    v.getAxisPosition(0), aa = v.getAxisPosition(1) - w, L = e.groupPadding, J = e.maxColWidth, w = d.numColumns || 1, aa = (1 - .01 * (X && X.plotspacepercent)) * aa || H(aa * (1 - 2 * L), J * w), X = aa / w, r = r * X - aa / 2, w = a.logic, aa = !w.avoidCrispError, L = a.canvasHeight + a.canvasTop, S = m.shadows || (m.shadows = q.group("shadows", k).toBack()), m = {}, J = c.plotOptions.series.dataLabels.style, A = a.chartWidth, U = a.chartHeight, T = {
                    fontFamily: J.fontFamily,
                    fontSize: J.fontSize,
                    lineHeight: J.lineHeight,
                    fontWeight: J.fontWeight,
                    fontStyle: J.fontStyle
                }, ba, ca, Y, C, z, G, ha, I,
                O, ka, ma, V, va, K, pa, R, fa, Z, ta, pb, ia = function (b) {
                    Ca.call(this, a, b)
                }, ua = function (b) {
                    Ca.call(this, a, b, "DataPlotRollOver")
                }, la = function (b) {
                    Ca.call(this, a, b, "DataPlotRollOut")
                }, da = function (b) {
                    return function () {
                        void 0 !== b && a.linkClickFN.call({link: b}, a)
                    }
                }, qa = function () {
                    n.show();
                    u.attr({transform: "...t" + -A + "," + -U});
                    S.show()
                };
            if (0 < x) {
                for (; x--;)if (ga = D[x], c = f(ga.errorStartValue, ga.y), V = ga.errorValue, k = ga.link, void 0 !== c && V && (pa = V.length)) {
                    ga = f(ga.x, x);
                    F = l.getAxisPosition(c);
                    Ea = v.getAxisPosition(ga);
                    E && (r && (Ea +=
                        r), X && (Ea += X / 2));
                    ka = y[x] || (y[x] = {});
                    ka.errorBars = ka.errorBars || [];
                    ka.errorValues = ka.errorValues || [];
                    ka.trackerBars = ka.trackerBars || [];
                    for (ca = (ba = ka.tracker || ka.graphic) && ba.data("groupId"); pa--;)ta = R = pb = null, va = V[pa], O = va.errorStartValue, wa = va.tooltext || va.toolText, z = isNaN(O) ? F : l.getAxisPosition(O), Y = va.displayValue, K = va.errorValue, va && ea(K) && (C = f(va.isHorizontal, 0), Z = f(va.errorBarThickness, d.errorBarThickness, 1), R = f(X * d.errorBarWidthPercent / 100, va.errorWidth, C ? d.hErrorBarWidth : d.vErrorBarWidth, d.errorBarWidth),
                        fa = R / 2, R = va.errorBarColor || d.errorBarColor, ea(Y) && Y !== B && (pb = q.text(u).attr({
                        text: Y,
                        fill: J.color,
                        direction: na,
                        "text-bound": [J.backgroundColor, J.borderColor, J.borderThickness, J.borderPadding, J.borderRadius, J.borderDash]
                    }).css(T), g.setStyle(T), m = g.getOriSize(Y)), C ? (Y = ma = O = v.getAxisPosition(ga + K), C = Ea, aa && (Y = N(z) + Z % 2 / 2, C = N(ma) + Z % 2 / 2), z = ["M", Ea, Y, "H", C, "M", C, Y - fa, "V", Y + fa]) : (Y = ma = O = l.getAxisPosition((ea(O) ? O : c) + K), C = Ea, aa && (Y = N(ma) + Z % 2 / 2, C = N(Ea) + Z % 2 / 2), I = .5 * (Wa ? m.width : m.height), G = ma + .5 * Z + Na + I, ha = ma -
                        .5 * Z - Na - I, z > ma ? (O = ha, ha - a.canvasTop < I && (O = G)) : (O = G, L - G < I && (O = ha)), z = ["M", C, z, "V", Y, "M", C - fa, Y, "H", C + fa]), R = q.path(z, n).attr({
                        stroke: R,
                        ishot: !M,
                        "stroke-width": Z,
                        cursor: k ? "pointer" : "",
                        "stroke-linecap": "round",
                        visibility: Q
                    }).shadow(f(w.errorBarShadow, e.shadow) && 0 < Z && va.shadow, S), (k || M) && Z < hc && (ta = q.path(z, h).attr({
                        stroke: Aa,
                        "stroke-width": hc,
                        cursor: k ? "pointer" : "",
                        ishot: !!k,
                        visibility: Q
                    })), ta = ta || R, ta.data("eventArgs", ba && ba.data("eventArgs") || {
                            link: k,
                            toolText: wa,
                            displayValue: va.displayValue,
                            value: K
                        }),
                        ta.click(ia).data("groupId", ca).hover(ua, la).tooltip(wa), (k || M) && ta.click(da(k)), pb && (pb.attr({
                        x: Ea,
                        y: O,
                        title: va.originalText || "",
                        visibility: Q
                    }).css(T), Wa && pb.attr("transform", "T0,0,R" + Wa)), R && (P.push(R), ka.errorBars.push(R)), pb && (P.push(pb), ka.errorValues.push(pb)), ta && ta !== R && (P.push(ta), ka.trackerBars.push(ta)));
                    p && (n.hide(), u.attr({transform: "...t" + A + "," + U}), S.hide(), setTimeout(qa, p))
                }
                b.visible = !1 !== d.visible
            }
        }
    }, r.mscolumn2dbase);
    r("errorline", {
        friendlyName: "Error Line Chart",
        standaloneInit: !0,
        creditLabel: !1,
        chart: r.errorbar2d.chart,
        drawErrorValue: r.errorbar2d.drawErrorValue,
        useErrorGroup: !0,
        rendererId: "cartesian",
        isErrorChart: !0,
        fireGroupEvent: !0,
        canvasPaddingModifiers: ["anchor", "errorbar"],
        point: function (b, d, a, c, e, g) {
            b = f(c.ignoreemptydatasets, 0);
            var q = !1, m = !f(c.halferrorbar, 1), v = e[ja], l = h(this.isValueAbs, v.isValueAbs, !1), p = f(a.showvalues, v.showValues), k = f(d.yAxis, 0), n = this.numberFormatter, t = this.colorManager, u = R(h(a.color, c.linecolor, t.getPlotColor())), E = e.chart, D = f(a.alpha, c.linealpha,
                "100"), x = f(a.errorbaralpha, c.errorbaralpha, D), y = f(a.linethickness, c.linethickness, 2), M = Boolean(f(a.dashed, c.linedashed, 0)), ga = f(a.linedashlen, c.linedashlen, 5), wa = f(a.linedashgap, c.linedashgap, 4), Ea, F, P, Q, Wa, na, Na, w, X, C, aa, L, J, S, za, U, T, ba, ca, Y, G, N, O, ha, V, K, ka, ma, ea, va;
            this.errorBarShadow = f(c.errorbarshadow);
            d.name = z(a.seriesname);
            d.color = {FCcolor: {color: u, alpha: D}};
            d.lineWidth = y;
            Na = f(a.drawanchors, a.showanchors, c.drawanchors, c.showanchors);
            ca = f(a.anchorsides, c.anchorsides, 0);
            Y = f(a.anchorradius, c.anchorradius,
                3);
            G = R(h(a.anchorbordercolor, c.anchorbordercolor, u));
            N = f(a.anchorborderthickness, c.anchorborderthickness, 1);
            O = R(h(a.anchorbgcolor, c.anchorbgcolor, t.getColor("anchorBgColor")));
            ha = h(a.anchoralpha, c.anchoralpha, "100");
            V = h(a.anchorbgalpha, c.anchorbgalpha, ha);
            K = f(a.anchorstartangle, c.anchorstartangle, 90);
            t = d.anchorShadow = f(c.anchorshadow, 0);
            d.errorBarWidth = f(c.errorbarwidth, a.errorbarwidth, 5);
            d.errorBarColor = A(R(h(a.errorbarcolor, c.errorbarcolor, "AAAAAA")), x);
            d.errorBarThickness = H(y, f(a.errorbarthickness,
                c.errorbarthickness, 1));
            if (0 === f(a.includeinlegend) || void 0 === d.name || 0 === D && 1 !== Na)d.showInLegend = !1;
            d.marker = {
                fillColor: {FCcolor: {color: O, alpha: V * ha / 100 + B}},
                lineColor: {FCcolor: {color: G, alpha: ha + B}},
                lineWidth: N,
                radius: Y,
                symbol: fa(ca),
                startAngle: K
            };
            if (c = a.data)for (Q = 0; Q < g; Q += 1)(za = c[Q]) ? (F = n.getCleanValue(za.value, l), P = n.getCleanValue(za.errorvalue, l), null === F ? d.data.push({y: null}) : (q = !0, S = f(za.anchorsides, ca), J = f(za.anchorradius, Y), aa = R(h(za.anchorbordercolor, G)), L = f(za.anchorborderthickness, N),
                C = R(h(za.anchorbgcolor, O)), w = h(za.anchoralpha, ha), X = h(za.anchorbgalpha, V), Wa = R(h(za.color, u)), na = h(za.alpha, D), ma = f(za.dashed, M) ? ua(ga, wa, y) : "none", U = {opacity: na / 100}, ka = void 0 === Na ? 0 !== na : !!Na, Ea = v.oriCatTmp[Q], va = this.getPointStub(za, F, Ea, e, a, p, k, P), ea = [], ea.push({
                errorValue: P,
                toolText: va._errortoolText,
                shadow: {opacity: x / 250}
            }), m && ea.push({
                errorValue: null === P ? null : -P,
                toolText: va._errortoolText,
                shadow: {opacity: x / 250}
            }), T = h(za.anchorstartangle, K), ba = Boolean(f(za.anchorshadow, t, 0)), Ea = this.pointHoverOptions(za,
                d, {
                    plotType: "anchor",
                    anchorBgColor: C,
                    anchorAlpha: w,
                    anchorBgAlpha: X,
                    anchorAngle: T,
                    anchorBorderThickness: L,
                    anchorBorderColor: aa,
                    anchorBorderAlpha: w,
                    anchorSides: S,
                    anchorRadius: J,
                    shadow: U
                }), d.data.push(I(va, {
                y: F,
                shadow: U,
                dashStyle: ma,
                errorValue: ea,
                valuePosition: h(za.valueposition, E.valuePosition),
                color: {FCcolor: {color: Wa, alpha: na}},
                marker: {
                    enabled: ka,
                    shadow: ba && {opacity: w / 100},
                    fillColor: {FCcolor: {color: C, alpha: X * w / 100 + B}},
                    lineColor: {FCcolor: {color: aa, alpha: w}},
                    lineWidth: L,
                    radius: J,
                    symbol: fa(S),
                    startAngle: T
                },
                hoverEffects: Ea.enabled && Ea.options,
                rolloverProperties: Ea.enabled && Ea.rolloverOptions
            })), r.errorbar2d.pointValueWatcher(e, F, P))) : d.data.push({y: null});
            b && !q && (d.showInLegend = !1);
            return d
        }
    }, r.mslinebase);
    r("errorscatter", {
        friendlyName: "Error Scatter Chart",
        isXY: !0,
        standaloneInit: !0,
        creditLabel: !1,
        chart: r.errorbar2d.chart,
        drawErrorValue: r.errorbar2d.drawErrorValue,
        defaultZeroPlaneHighlighted: !1,
        useErrorGroup: !0,
        rendererId: "cartesian",
        isErrorChart: !0,
        fireGroupEvent: !0,
        point: function (b, d, a, c, e, g, q) {
            b =
                f(c.ignoreemptydatasets, 0);
            g = !1;
            var m = f(a.drawline, 0), v = f(a.drawprogressioncurve, 0), l, p, k = f(a.showvalues, e[ja].showValues), n = this.numberFormatter, t = f(a.showregressionline, c.showregressionline, 0), u = h(c.errorbarcolor, "AAAAAA"), E = h(c.errorbaralpha, "100"), D = f(c.errorbarthickness, 1);
            p = f(c.errorbarwidth, 5);
            var x = f(c.halfverticalerrorbar, 1), y = f(a.verticalerrorbaralpha, a.errorbaralpha, c.verticalerrorbaralpha, E), M = A(h(a.verticalerrorbarcolor, a.errorbarcolor, c.verticalerrorbarcolor, u), y), ga = f(a.verticalerrorbarthickness,
                a.errorbarthickness, c.verticalerrorbarthickness, D), wa = f(c.halfhorizontalerrorbar, 1), E = h(a.horizontalerrorbaralpha, a.errorbaralpha, c.horizontalerrorbaralpha, E), u = A(h(a.horizontalerrorbarcolor, a.errorbarcolor, c.horizontalerrorbarcolor, u), E), D = f(a.horizontalerrorbarthickness, a.errorbarthickness, c.horizontalerrorbarthickness, D), Ea = f(a.usehorizontalerrorbar, c.usehorizontalerrorbar, 0), F = f(a.useverticalerrorbar, c.useverticalerrorbar, 1), P = {
                sumX: 0,
                sumY: 0,
                sumXY: 0,
                sumXsqure: 0,
                sumYsqure: 0,
                xValues: [],
                yValues: []
            };
            l = this.colorManager;
            var Q = l.getPlotColor(), Wa, na, Na, r, X, w, aa, L, J, S, C, U, T, ba, ca, Y, G, H, N, ha, I, O, ka, ma;
            this.errorBarShadow = f(c.errorbarshadow);
            d.zIndex = 1;
            d.name = z(a.seriesname);
            if (0 === f(a.includeinlegend) || void 0 === d.name)d.showInLegend = !1;
            d.vErrorBarWidth = f(a.verticalerrorbarwidth, a.errorbarwidth, c.verticalerrorbarwidth, p);
            d.hErrorBarWidth = f(a.horizontalerrorbarwidth, a.errorbarwidth, c.horizontalerrorbarwidth, p);
            if (m || v)v && (d.type = "spline"), na = R(h(a.color, Q)), m = h(a.alpha, Fa), v = f(a.linethickness, c.linethickness,
                2), p = Boolean(f(a.linedashed, a.dashed, c.linedashed, 0)), Na = f(a.linedashlen, c.linedashlen, 5), r = f(a.linedashgap, c.linedashgap, 4), d.color = A(h(a.linecolor, c.linecolor, na), f(a.linealpha, c.linealpha, m)), d.lineWidth = v, d.dashStyle = p ? ua(Na, r, v) : "none";
            m = Boolean(f(a.drawanchors, a.showanchors, c.drawanchors, c.showanchors, 1));
            q = f(a.anchorsides, c.anchorsides, q + 3);
            v = f(a.anchorradius, c.anchorradius, 3);
            na = R(h(a.anchorbordercolor, a.color, c.anchorbordercolor, na, Q));
            Q = f(a.anchorborderthickness, c.anchorborderthickness,
                1);
            r = R(h(a.anchorbgcolor, c.anchorbgcolor, l.getColor("anchorBgColor")));
            w = h(a.anchoralpha, a.alpha, c.anchoralpha, "100");
            aa = h(a.anchorbgalpha, c.anchorbgalpha, w);
            Na = h(a.anchorstartangle, c.anchorstartangle);
            d.anchorShadow = f(c.anchorshadow, 0);
            d.marker = {
                fillColor: this.getPointColor(r, "100"),
                lineColor: {FCcolor: {color: na, alpha: w + B}},
                lineWidth: Q,
                radius: v,
                symbol: fa(q)
            };
            if (l = a.data) {
                p = l.length;
                t && (d.events = {
                    hide: this.hideRLine,
                    show: this.showRLine
                }, I = f(a.showyonx, c.showyonx, 1), O = R(h(a.regressionlinecolor, c.regressionlinecolor,
                    na)), ka = f(a.regressionlinethickness, c.regressionlinethickness, Q), c = jb(f(a.regressionlinealpha, c.regressionlinealpha, w)), O = A(O, c));
                for (Wa = 0; Wa < p; Wa += 1)(X = l[Wa]) ? (c = n.getCleanValue(X.y), ca = n.getCleanValue(X.x), n.getCleanValue(X.errorvalue), Y = n.getCleanValue(h(X.horizontalerrorvalue, X.errorvalue)), G = n.getCleanValue(h(X.verticalerrorvalue, X.errorvalue)), null === c ? d.data.push({
                    y: null,
                    x: ca
                }) : (g = !0, H = this.getPointStub(X, c, n.xAxis(ca), e, a, k, void 0, G, Y, ca), L = f(X.anchorsides, q), J = f(X.anchorradius, v), S = R(h(X.anchorbordercolor,
                    na)), C = f(X.anchorborderthickness, Q), U = R(h(X.anchorbgcolor, r)), T = h(X.anchoralpha, X.alpha, w), ba = h(X.anchorbgalpha, aa), N = Boolean(f(X.usehorizontalerrorbar, Ea)), ha = Boolean(f(X.useverticalerrorbar, F)), ma = [], N && (N = H._hErrortoolText, ma.push({
                    errorValue: Y,
                    toolText: N,
                    errorBarColor: u,
                    isHorizontal: 1,
                    errorBarThickness: D,
                    shadow: {opacity: E / 250}
                }), wa || ma.push({
                    errorValue: -Y,
                    toolText: N,
                    errorBarColor: u,
                    isHorizontal: 1,
                    errorBarThickness: D,
                    shadow: {opacity: E / 250}
                })), ha && (ha = H._errortoolText, ma.push({
                    errorValue: G, toolText: ha,
                    errorBarColor: M, errorBarThickness: ga, shadow: {opacity: y / 250}
                }), x || ma.push({
                    errorValue: -G,
                    toolText: ha,
                    errorBarColor: M,
                    errorBarThickness: ga,
                    shadow: {opacity: y / 250}
                })), ha = this.pointHoverOptions(X, d, {
                    plotType: "anchor",
                    anchorBgColor: U,
                    anchorAlpha: T,
                    anchorBgAlpha: ba,
                    anchorAngle: Na,
                    anchorBorderThickness: C,
                    anchorBorderColor: S,
                    anchorBorderAlpha: T,
                    anchorSides: L,
                    anchorRadius: J
                }), d.data.push({
                    y: c,
                    x: ca,
                    errorValue: ma,
                    displayValue: H.displayValue,
                    displayValueArgs: H.displayValueArgs,
                    toolText: H.toolText,
                    link: H.link,
                    marker: {
                        enabled: m,
                        shadow: void 0,
                        fillColor: {FCcolor: {color: U, alpha: ba * T / 100 + B}},
                        lineColor: {FCcolor: {color: S, alpha: T}},
                        lineWidth: C,
                        radius: J,
                        symbol: fa(L),
                        startAngle: h(X.anchorstartangle, Na)
                    },
                    hoverEffects: ha.enabled && ha.options,
                    rolloverProperties: ha.enabled && ha.rolloverOptions
                }), this.pointValueWatcher(e, x ? c : c - G, wa ? ca : ca - Y, t && P), this.pointValueWatcher(e, c + G, ca + Y, t && P))) : d.data.push({y: null});
                t && (a = this.getRegressionLineSeries(P, I, p), this.pointValueWatcher(e, a[0].y, a[0].x), this.pointValueWatcher(e, a[1].y,
                    a[1].x), e = {
                    type: "line",
                    color: O,
                    showInLegend: !1,
                    lineWidth: ka,
                    enableMouseTracking: !1,
                    marker: {enabled: !1},
                    data: a,
                    zIndex: 0
                }, d = [d, e])
            }
            b && !g && (d.showInLegend = !1);
            return d
        }
    }, r.scatterbase);
    r("waterfall2d", {
        friendlyName: "Waterfall Chart",
        standaloneInit: !0,
        isWaterfall: !0,
        creditLabel: !1,
        point: function (b, d, a, c, e) {
            var g, q, m, v, l, p, k, n, t, u, E, D, x, y, M;
            b = h(c.connectorthickness, 1);
            var ga = {
                    step: !0,
                    type: "line",
                    enableMouseTracking: !1,
                    data: [],
                    dataLabels: {enabled: !1},
                    marker: {enabled: !1},
                    dashStyle: "1" === c.connectordashed ?
                        ua(f(c.connectordashlen, 2), f(c.connectordashgap, 2), b) : "none",
                    drawVerticalJoins: !1,
                    useForwardSteps: !0,
                    color: A(h(c.connectorcolor, "000000"), h(c.connectoralpha, 100)),
                    lineWidth: b
                }, wa = this.colorManager, Ea = a.length, F = e[ja], P = F.axisGridManager, Q = e.xAxis, Wa = F.x, r = /3d$/.test(e.chart.defaultSeriesType), Na = this.isBar, w = "1" === h(c.showplotborder, r ? "0" : "1") ? r ? 1 : f(c.plotborderthickness, 1) : 0, X = e.chart.useRoundEdges, C = f(c.plotborderalpha, c.plotfillalpha, 100) + B, aa = h(c.plotbordercolor, wa.getColor("plotBorderColor").split(",")[0]),
                L = f(c.useplotgradientcolor, 1) ? Fb(c.plotgradientcolor, wa.getColor("plotGradientColor")) : B, J = f(c.plotborderdashed, 0), S = f(c.plotborderdashlen, 6), za = f(c.plotborderdashgap, 3), U = 0, T = Boolean(f(c.use3dlighting, 1)), ba = 0, ca = 0, Y = e[ja].numberFormatter, H, N = 0, I, ha = f(c.showsumatend, 1);
            for (g = 0; g < Ea; g += 1)n = a[g], b = Y.getCleanValue(n.value), m = f(n.issum, 0), n.vline || m || (N += b, n._value = b);
            H = Y.dataLabels(N);
            ha && (ha = !0, Ea += 1, I = {label: K(c.sumlabel, "Total"), _value: N, value: N, issum: 1, cumulative: 1});
            for (q = g = 0; g < Ea; g += 1)n = a[g],
            !n && ha && (n = I), n.vline ? P.addVline(Q, n, U, e) : (b = n._value, delete n._value, m = f(n.issum, 0), k = f(n.cumulative, 1), m ? (b = k ? ba : ba === ca ? ba : ba - ca, ca = ba, ga.data.push({
                y: null,
                x: q - .5
            })) : ba += b, m = f(n.showlabel, c.showlabels, 1), m = G(m ? K(n.label, n.name) : B), P.addXaxisCat(Q, U, U, m, n, {}, c), U += 1, 0 < b ? (v = h(n.color, c.positivecolor, wa.getPlotColor()), d.hoverEffects && (d.hoverEffects.color = h(n.positivehovercolor, c.positivehovercolor, c.plotfillhovercolor))) : (v = h(n.color, c.negativecolor, wa.getPlotColor()), d.hoverEffects && (d.hoverEffects.color =
                h(n.negativehovercolor, c.negativehovercolor, c.plotfillhovercolor))), l = h(n.alpha, c.plotfillalpha, "100"), p = h(n.ratio, c.plotfillratio), t = h(360 - c.plotfillangle, 90), 0 > b && (t = 360 - t), D = h(n.alpha, C), x = f(n.dashed, J), y = h(n.dashgap, za), M = h(n.dashlen, S), u = {
                opacity: l / 100,
                inverted: Na
            }, k = bc(v + Ba + L.replace(/,+?$/, ""), l, p, t, X, aa, h(n.alpha, C), Na, r), E = x ? ua(M, y, w) : "none", v = this.pointHoverOptions(n, d, {
                plotType: "column",
                is3d: r,
                isBar: Na,
                use3DLighting: T,
                isRoundEdged: X,
                color: v,
                gradientColor: L,
                alpha: l,
                ratio: p,
                angle: t,
                borderWidth: w,
                borderColor: aa,
                borderAlpha: D,
                borderDashed: x,
                borderDashGap: y,
                borderDashLen: M,
                shadow: u
            }), p = z(G(n.displayvalue)), t = null === b ? b : Y.dataLabels(b), l = z(G(h(n.tooltext, F.tooltext))), D = F.showTooltip ? void 0 !== l ? db(l, [1, 2, 3, 5, 6, 7, 20, 21, 24, 25], {
                formattedValue: t,
                label: m,
                yaxisName: G(c.yaxisname),
                xaxisName: G(c.xaxisname),
                cumulativeValue: ba,
                cumulativeDataValue: Y.dataLabels(ba),
                sum: H,
                unformattedSum: N
            }, n, c) : null === t ? !1 : m !== B ? m + F.tooltipSepChar + t : t : B, l = f(n.showvalue, F.showValues) ? void 0 !== p ? p : t : B, p = h(p, t, B), d.data.push({
                y: b,
                _FCY: 0 > b ? ba - b : ba,
                previousY: 0 > b ? ba : 0 === ba - b ? void 0 : ba - b,
                shadow: u,
                color: k[0],
                borderColor: k[1],
                borderWidth: w,
                dashStyle: E,
                use3DLighting: T,
                hoverEffects: v.enabled && v.options,
                rolloverProperties: v.enabled && v.rolloverOptions,
                displayValue: l,
                displayValueArgs: p,
                categoryLabel: m,
                toolText: D,
                link: h(n.link)
            }), ga.data.push({y: b && ba, x: q}), this.pointValueWatcher(e, ba), q += 1);
            Wa.catCount = U;
            "0" != c.showconnectors && (d = [ga, d]);
            return d
        },
        defaultSeriesType: "column",
        rendererId: "cartesian"
    }, Ia);
    r("multilevelpie", {
        friendlyName: "Multi-level Pie Chart",
        standaloneInit: !0,
        defaultSeriesType: "multilevelpie",
        rendererId: "multiLevelPie",
        defaultPlotShadow: 0,
        series: function () {
            var b = this.dataObj, d = this.hcJSON, a = b.chart, c = d.series, e = {}, g = Boolean(f(a.usehovercolor, 1)), q = A(h(a.hoverfillcolor, "FF5904"), f(a.hoverfillalpha, 100)), m = parseInt(a.pieradius, 10), v = 0, l = !0, v = d.xAxis.labels.style, l = (l = K(a.valuebordercolor, v.borderColor, B)) ? A(l, f(a.valueborderalpha, a.valuebgalpha, a.valuealpha, 100)) : B;
            d.chart.plotBorderColor = 0;
            d.chart.plotBackgroundColor = null;
            d.plotOptions.series.dataLabels.style =
            {
                fontFamily: h(a.valuefont, v.fontFamily),
                fontSize: f(a.valuefontsize, parseInt(v.fontSize, 10)) + "px",
                color: A(h(a.valuefontcolor, v.color), f(a.valuefontalpha, a.valuealpha, 100)),
                fontWeight: f(a.valuefontbold) ? "bold" : "normal",
                fontStyle: f(a.valuefontitalic) ? "italic" : "normal",
                backgroundColor: a.valuebgcolor ? A(a.valuebgcolor, f(a.valuebgalpha, a.valuealpha, 100)) : B,
                border: l || a.valuebgcolor ? f(a.valueborderthickness, 1) + "px solid" : B,
                borderPadding: f(a.valueborderpadding, 2),
                borderThickness: f(a.valueborderthickness, v.borderThickness,
                    1),
                borderRadius: f(a.valueborderradius, v.borderRadius, 0),
                borderColor: l,
                borderDash: f(a.valueborderdashed, 0) ? ua(f(a.valueborderdashlen, 4), f(a.valueborderdashgap, 2), f(a.valueborderthickness, 1)) : "none"
            };
            d.legend.enabled = !1;
            d.plotOptions.pie.allowPointSelect = !1;
            d.plotOptions.series.borderColor = A(h(a.plotbordercolor, a.piebordercolor, "FFFFFF"), "0" != a.showplotborder ? h(a.plotborderalpha, a.pieborderalpha, 100) : 0);
            d.plotOptions.series.borderWidth = f(a.pieborderthickness, a.plotborderthickness, 1);
            d.plotOptions.pie.startingAngle =
                0;
            d.plotOptions.pie.size = "100%";
            e.showLabels = f(a.showlabels, 1);
            e.showValues = f(a.showvalues, 0);
            e.showValuesInTooltip = f(a.showvaluesintooltip, a.showvalues, 0);
            e.showPercentValues = f(a.showpercentvalues, a.showpercentagevalues, 0);
            e.showPercentInTooltip = f(a.showpercentintooltip, 0);
            e.toolTipSepChar = h(a.tooltipsepchar, a.hovercapsepchar, Sa);
            e.labelSepChar = h(a.labelsepchar, e.toolTipSepChar);
            e.tooltext = a.plottooltext;
            g && (d.plotOptions.series.point.events = {
                mouseOver: function () {
                    for (var a = this, b = a.chart.plots, c,
                             d; a;)a.graphic.attr({fill: q}), d = a.prevPointIndex, a = a.prevSeriesIndex, a = (c = b[a]) && c.items && c.items[d]
                }, mouseOut: function () {
                    for (var a = this, b = a.chart.plots, c, d; a;)a.graphic.attr({fill: a.color}), d = a.prevPointIndex, a = a.prevSeriesIndex, a = (c = b[a]) && c.items && c.items[d]
                }
            });
            d.chart.plotBorderWidth = 0;
            b.category && this.addMSPieCat(b.category, 0, 0, 100, h(a.plotfillalpha, a.piefillalpha, 100), e, null);
            m = parseInt(a.pieradius, 10);
            v = 0;
            l = !0;
            m ? (b = 2 * m / c.length, l = !1) : b = parseInt(100 / c.length, 10);
            d.plotOptions.series.dataLabels.distance =
                0;
            d.plotOptions.series.dataLabels.placeLabelsInside = !0;
            for (d = 0; d < c.length; d += 1)c[d].innerSize = v + (l ? "%" : ""), c[d].size = (v += b) + (l ? "%" : ""), 0 === c[d].data[c[d].data.length - 1].y && c[d].data.pop()
        },
        spaceManager: function (b, d, a, c) {
            var e = b[ja];
            this.titleSpaceManager(b, d, a - (e.marginLeftExtraSpace + e.marginRightExtraSpace + b.chart.marginRight + b.chart.marginLeft), .4 * (c - (e.marginBottomExtraSpace + e.marginTopExtraSpace + b.chart.marginBottom + b.chart.marginTop)))
        },
        addMSPieCat: function (b, d, a, c, e, g, q) {
            var m = this.numberFormatter,
                v = this.colorManager, l, p, k = 0, n = b.length - 1, t, u, E;
            l = this.hcJSON.series;
            var D = g.labelSepChar, x, y, M, ga, wa, Ea;
            void 0 === this.colorCount && (this.colorCount = 0);
            0 === d && (this.colorCount = 0);
            l[d] || (l[d] = {
                data: [{
                    toolText: !1,
                    doNotSlice: !0,
                    y: 100,
                    visible: !1,
                    color: "rgba(255,255,255,0)"
                }]
            });
            l = l[d];
            (p = a - 100 + l.data[l.data.length - 1].y) && l.data.splice(l.data.length - 1, 0, {
                toolText: !1,
                doNotSlice: !0,
                y: p,
                visible: !1,
                color: "rgba(255,255,255,0)"
            });
            l.data[l.data.length - 1].y = 100 - c;
            for (u = 0; u <= n; u += 1)t = b[u], t._userValue = m.getCleanValue(t.value,
                this.isValueAbs), t._value = f(t._userValue, 1), k += t._value;
            k = k || 1;
            p = (c - a) / k;
            for (u = n; 0 <= u; --u)t = b[u], n = p * t._value, E = G(h(t.label, t.name)), M = null !== t._userValue ? m.dataLabels(t._userValue) : B, ga = m.percentValue(t._value / k * 100), x = l.data.length - 1, y = f(t.alpha, e), Ea = g.showLabels ? E : B, g.showValues && (g.showPercentValues ? Ea += Ea !== B ? D + ga : ga : void 0 !== M && M !== B && (Ea += Ea !== B ? D + M : M)), wa = G(h(t.tooltext, t.hovertext, g.tooltext)), wa === B ? (wa = E, g.showValuesInTooltip && (g.showPercentInTooltip ? wa += wa !== B ? D + ga : ga : void 0 !== M && M !==
            B && (wa += wa !== B ? D + M : M))) : wa = db(wa, [1, 2, 3, 14], {
                percentValue: ga,
                label: E,
                formattedValue: M
            }, t), l.data.splice(x, 0, {
                prevPointIndex: q,
                prevSeriesIndex: d - 1,
                displayValue: Ea,
                toolText: wa,
                y: n,
                link: z(t.link),
                doNotSlice: !0,
                color: A(t.color || v.getPlotColor(), y),
                shadow: {opacity: .01 * N(50 < y ? y * y * y * 1E-4 : y * y * .01)}
            }), this.colorCount += 1, t.category && this.addMSPieCat(t.category, d + 1, a, 0 === u ? c : a + n, e, g, x), a += n
        },
        isValueAbs: !0,
        creditLabel: !1
    }, Ia);
    r("radar", {
        friendlyName: "Radar Chart", standaloneInit: !0, creditLabel: !1, defaultSeriesType: "radar",
        areaAlpha: 50, spaceManager: function (b, d, a, c) {
            b.chart.plotBorderWidth = 0;
            b.chart.plotBackgroundColor = null;
            var e = b[ja], g = e.x, q = b.xAxis, m = b.yAxis[0], v = d.chart, m = f(v.labelpadding, v.labelxpadding, parseInt(m && m.labels && m.labels.style && m.labels.style.fontSize || 10, 10));
            a -= e.marginLeftExtraSpace + e.marginRightExtraSpace + b.chart.marginRight + b.chart.marginLeft;
            c -= e.marginBottomExtraSpace + e.marginTopExtraSpace + b.chart.marginBottom + b.chart.marginTop;
            e = this.colorManager;
            c -= this.titleSpaceManager(b, d, a, .4 * c);
            q.min =
                f(g.min, 0);
            q.max = f(g.max, g.catCount - 1);
            q.gridLineColor = A(h(v.radarspikecolor, e.getColor("divLineColor")), f(v.radarspikealpha, v.radarinlinealpha, e.getColor("divLineAlpha")));
            q.gridLineWidth = f(v.radarspikethickness, 1);
            q.showRadarBorder = f(v.showradarborder, 1);
            q.radarBorderThickness = f(v.radarborderthickness, 2);
            q.radarBorderColor = A(h(v.radarbordercolor, e.getColor("divLineColor")), f(v.radarborderalpha, 100));
            q.radarFillColor = A(h(v.radarfillcolor, e.getColor("altHGridColor")), f(v.radarfillalpha, e.getColor("altHGridAlpha")));
            b.legend.enabled && (h(v.legendposition, Ga).toLowerCase() != xa ? c -= this.placeLegendBlockBottom(b, d, a, c / 2) : a -= this.placeLegendBlockRight(b, d, a / 3, c));
            d = f(v.radarradius);
            g = 2 * f(parseInt(q.labels.style.lineHeight, 10), 12);
            v = 2 * m;
            g = H(a - (100 + v), c - (g + v));
            d = d || .5 * g;
            a = H(.3 * a, .3 * c);
            d < a && (d = a);
            b.chart.axisRadius = d;
            q.labels.labelPadding = m
        }, anchorAlpha: "100", showValues: 0, isRadar: !0, rendererId: "radar"
    }, r.msareabase);
    Ia = {
        dragExtended: !0, defaultRestoreButtonVisible: 1, spaceManager: function (b, d, a, c) {
            var e = b[ja], g = b.chart,
                q = d.chart, m = e.outCanvasStyle, v = c - .3 * (e.marginBottomExtraSpace + g.marginBottom + g.marginTop), l = 0, p = 0, e = this.smartLabel || e.smartLabel, k, n;
            g.formAction = z(q.formaction);
            g.formDataFormat = h(q.formdataformat, O.dataFormats.XML);
            g.formTarget = h(q.formtarget, "_self");
            g.formMethod = h(q.formmethod, "POST");
            g.submitFormAsAjax = f(q.submitformusingajax, 1);
            g.showFormBtn = f(q.showformbtn, 1) && g.formAction;
            g.formBtnTitle = h(q.formbtntitle, "Submit");
            g.formBtnBorderColor = h(q.formbtnbordercolor, "CBCBCB");
            g.formBtnBgColor = h(q.formbtnbgcolor,
                "FFFFFF");
            g.btnPadding = f(q.btnpadding, 7);
            g.btnSpacing = f(q.btnspacing, 5);
            g.formBtnStyle = {fontSize: m.fontSize, fontFamily: m.fontFamily, fontWeight: "bold"};
            g.formBtnLabelFill = m.color;
            q.btntextcolor && (g.formBtnLabelFill = q.btntextcolor.replace(hb, Oa));
            0 <= (m = f(q.btnfontsize)) && (g.formBtnStyle.fontSize = m + "px");
            Ja(g.formBtnStyle);
            g.showRestoreBtn = f(q.showrestorebtn, this.defaultRestoreButtonVisible, 1);
            g.showRestoreBtn && (g.restoreBtnTitle = h(q.restorebtntitle, "Restore"), g.restoreBtnBorderColor = h(q.restorebtnbordercolor,
                g.formBtnBorderColor), g.restoreBtnBgColor = h(q.restorebtnbgcolor, g.formBtnBgColor), g.restoreBtnStyle = {
                fontSize: g.formBtnStyle.fontSize,
                fontFamily: g.formBtnStyle.fontFamily,
                fontWeight: "bold"
            }, g.restoreBtnLabelFill = g.formBtnLabelFill, q.restorebtntextcolor && (g.restoreBtnLabelFill = q.restorebtntextcolor.replace(hb, Oa)), 0 <= (m = f(q.restorebtnfontsize)) && (g.restoreBtnStyle.fontSize = m + "px"), Ja(g.restoreBtnStyle));
            g.showLimitUpdateMenu = f(q.showlimitupdatemenu, 1);
            g.showFormBtn && (e.setStyle(g.formBtnStyle), k = e.getOriSize(g.formBtnTitle),
                l = k.height || 0);
            g.showRestoreBtn && (e.setStyle(g.restoreBtnStyle), n = e.getOriSize(g.restoreBtnTitle), l = ab(n.height, l) || 0);
            0 < l && (l += g.btnPadding + 4, l > v && (g.btnPadding = ab(g.btnPadding - l + v, 0) / 2, l = v));
            g.btnHeight = l;
            g.showFormBtn && (p = k.width + l, g.formBtnWidth = f(q.formbtnwidth, p), g.formBtnWidth < k.width && (g.formBtnWidth = p));
            g.showRestoreBtn && (p = n.width + l, g.restoreBtnWidth = f(q.restorebtnwidth, p), g.restoreBtnWidth < n.width && (g.restoreBtnWidth = p));
            g.marginBottom += l + g.btnPadding;
            g.spacingBottom += l + g.btnPadding;
            (b.callbacks ||
            (b.callbacks = [])).push(this.drawButtons);
            return this.placeVerticalXYSpaceManager.apply(this, arguments)
        }, drawButtons: function () {
            var b = this.logic, d = this.paper, a = this.options.chart, c = a.btnSpacing, e = this.chartHeight - a.spacingBottom + a.btnPadding, g = this.chartWidth - a.spacingRight, q = this.layers.layerAboveDataset, m = 0;
            a.showFormBtn && (this.submitBtn = d.button(g - a.formBtnWidth, e, a.formBtnTitle, void 0, {
                width: a.formBtnWidth,
                height: a.btnHeight,
                verticalPadding: 1,
                horizontalPadding: 15
            }, q).labelcss(a.formBtnStyle).attr({
                fill: [R(a.formBtnBgColor),
                    a.formBtnLabelFill], stroke: R(a.formBtnBorderColor)
            }).buttonclick(function () {
                b.chartInstance.submitData()
            }), m = a.formBtnWidth + c);
            a.showRestoreBtn && (this.restoreBtn = d.button(g - a.restoreBtnWidth - m, e, a.restoreBtnTitle, void 0, {
                width: a.restoreBtnWidth,
                height: a.btnHeight,
                verticalPadding: 1,
                horizontalPadding: 15
            }, q).labelcss(a.restoreBtnStyle).attr({
                fill: [R(a.restoreBtnBgColor), a.restoreBtnLabelFill],
                stroke: R(a.restoreBtnBorderColor)
            }).buttonclick(function () {
                b.chartInstance.restoreData()
            }))
        }, drawAxisUpdateUI: function () {
            var b =
                this, d = b.logic, a = b.elements, c = b.options, e = c.chart, g = c[ja], q = d.chartInstance, d = d.renderer, m = b.yAxis[0], f = m.axisData, l = m.poi, p = f.plotLines, k = b.container, n = c.chart.showRangeError, h = g.inCanvasStyle, c = b.toolbar || (b.toolbar = []), m = b.menus || (b.menus = []), u = cb({
                outline: "none",
                "-webkit-appearance": "none",
                filter: "alpha(opacity=0)",
                position: "absolute",
                background: "transparent",
                border: "1px solid #cccccc",
                textAlign: "right",
                top: 0,
                left: 0,
                width: 50,
                zIndex: 20,
                opacity: 0,
                borderRadius: 0
            }, h), E, D;
            d && !d.forExport && (D = function (a,
                                                c, d) {
                if (a === c + "")return null;
                c = d ? q.setUpperLimit(a, !0) : q.setLowerLimit(a, !0);
                !c && n && b.showMessage("Sorry! Not enough range gap to modify axis limit to " + (Number(a) || "0") + ".<br />Please modify the data values to be within range.<br />&nbsp;<br />(click anywhere on the chart to close this message)", !0);
                return c
            }, ib(["max", "min"], function (a) {
                var c = l[a], d = c.label, g = p[c.index], c = d && d.getBBox(), q, m, f, n, v, E, B;
                if (c && d) {
                    m = c.x + c.width - e.spacingLeft;
                    f = e.marginLeft - m - (Ta ? 4 : 5);
                    q = ob("input", {
                        type: "text", value: g.value,
                        name: a || ""
                    }, k, !0);
                    cb(u, {top: c.y + (Ta ? -1 : 0) + "px", left: f + "px", width: m + "px"});
                    for (n in u)q.style[n] = u[n];
                    w.dem.listen(q, ["focus", "mouseup", "blur", "keyup"], [function () {
                        var a = {opacity: 1, filter: "alpha(opacity=100)", color: h.color}, b;
                        this.value = g.value;
                        for (b in a)this.style[b] = a[b];
                        v = B = !0;
                        d.hide()
                    }, function () {
                        var a = this;
                        B && (B = !1, ia || setTimeout(function () {
                            a.select()
                        }, 0))
                    }, function () {
                        !0 !== D(this.value, g.value, g.isMaxLabel) && (this.style.opacity = 0, this.style.filter = "alpha(opacity=0)", d.show());
                        Tb && Ua.getElementsByTagName("body")[0].focus &&
                        Ua.getElementsByTagName("body")[0].focus();
                        v = B = !1
                    }, function (a) {
                        var b = a.originalEvent.keyCode, c = this.value;
                        13 === b ? (a = D(c, g.value, g.isMaxLabel), !1 === a && (this.style.color = "#dd0000")) : 27 === b && (this.value = g.value, w.dem.fire(this, "blur", a))
                    }]);
                    q.setAttribute("isOverlay", "true");
                    Ta ? (Ab(b.container, "defaultprevented", E = function (a) {
                        q.parentNode && w.dem.fire(q, "blur", a)
                    }), Ab(b.container, "destroy", function () {
                        oa(b, "defaultprevented", E);
                        q.parentNode.removeChild(q)
                    })) : (Ab(b.container, "mousedown", E = function (a) {
                        a.srcElement !==
                        q && v && w.dem.fire(q, "blur", a)
                    }), Ab(b.container, "destroy", function () {
                        oa(b.container, "mousedown", E);
                        q.parentNode.removeChild(q)
                    }))
                }
            }), e.showLimitUpdateMenu && (m.push(E = Pb({
                chart: b,
                basicStyle: g.outCanvasStyle,
                items: [{
                    text: "Increase Upper Limit", onclick: function () {
                        q.setUpperLimit(f.max + f.tickInterval, !0)
                    }
                }, {
                    text: "Increase Lower Limit", onclick: function () {
                        q.setLowerLimit(f.min + f.tickInterval, !0)
                    }
                }, {
                    text: "Decrease Upper Limit", onclick: function () {
                        q.setUpperLimit(f.max - f.tickInterval, !0)
                    }
                }, {
                    text: "Decrease Lower Limit",
                    onclick: function () {
                        q.setLowerLimit(f.min - f.tickInterval, !0)
                    }
                }],
                position: {
                    x: e.spacingLeft,
                    y: q.height - e.spacingBottom + (e.showFormBtn || e.showRestoreBtn ? 10 : -15)
                }
            })), a.configureButton = c.add("configureIcon", function (a, b) {
                return function () {
                    E.visible ? E.hide() : E.show({x: a, y: b + 1})
                }
            }(), {
                x: e.spacingLeft,
                y: q.height - e.spacingBottom + (e.showFormBtn || e.showRestoreBtn ? 10 : -15),
                tooltip: "Change Y-Axis Limits"
            })))
        }, getCollatedData: function () {
            var b = this.chartInstance, d = b.__state, a = b.jsVars, b = this.updatedDataObj || I({}, b.getChartData(O.dataFormats.JSON)),
                c = a._reflowData, a = b.dataset, e = (c = c && c.hcJSON && c.hcJSON.series) && c.length, g, q, m, f;
            if (void 0 !== d.hasStaleData && !d.hasStaleData && this.updatedDataObj)return this.updatedDataObj;
            if (a && c)for (; e--;)if (q = a[e] && a[e].data, (g = (m = c[e] && c[e].data) && m.length) && q)for (; g--;)if (f = m[g])q[g].value = f.y;
            d.hasStaleData = !1;
            return this.updatedDataObj = b
        }, eiMethods: {
            restoreData: function () {
                var b = this.jsVars, d = b.fcObj;
                b._reflowData = {};
                delete b._reflowClean;
                O.hcLib.createChart(d, b.container, b.type, void 0, void 0, !1, !0);
                w.raiseEvent("dataRestored",
                    {}, d, [d.id]);
                return !0
            }, submitData: function () {
                var b = this.jsVars, d = b.fcObj, a = d.__state, c = a._submitAjaxObj || (a._submitAjaxObj = new O.ajax), a = O.dataFormats.JSON, e = O.dataFormats.CSV, g = O.dataFormats.XML, b = b.instanceAPI, q = b.hcJSON.chart, m = q.formAction, f = q.submitFormAsAjax, l, p, k, n, h;
                q.formDataFormat === a ? (l = a, p = JSON.stringify(b.getCollatedData())) : q.formDataFormat === e ? (l = e, p = b.getCSVString && b.getCSVString(), void 0 === p && (p = O.core.transcodeData(b.getCollatedData(), a, e))) : (l = g, p = O.core.transcodeData(b.getCollatedData(),
                    a, g));
                O.raiseEvent("beforeDataSubmit", {data: p}, d, void 0, function () {
                    f ? (c.onError = function (a, b, c, e) {
                        w.raiseEvent("dataSubmitError", {
                            xhrObject: b.xhr,
                            url: e,
                            statusText: a,
                            httpStatus: b.xhr && b.xhr.status ? b.xhr.status : -1,
                            data: p
                        }, d, [d.id, a, b.xhr && b.xhr.status])
                    }, c.onSuccess = function (a, b, e, g) {
                        w.raiseEvent("dataSubmitted", {xhrObject: c, response: a, url: g, data: p}, d, [d.id, a])
                    }, k = {}, k["str" + l.toUpperCase()] = p, c.open && c.abort(), c.post(m, k)) : (n = Va.document.createElement("span"), n.innerHTML = '<form style="display:none" action="' +
                        m + '" method="' + q.formMethod + '" target="' + q.formTarget + '"> <input type="hidden" name="strXML" value="' + Bc(p) + '"><input type="hidden" name="dataFormat" value="' + l.toUpperCase() + '" /></form>', h = n.removeChild(n.firstChild), Va.document.body.appendChild(h), h.submit && h.submit(), h.parentNode.removeChild(h), n = h = null)
                }, function () {
                    O.raiseEvent("dataSubmitCancelled", {data: p}, d)
                })
            }, getDataWithId: function () {
                for (var b = this.jsVars.instanceAPI.getCollatedData(), d = [[B]], a = b.dataset, b = b.categories && b.categories[0] &&
                    b.categories[0].category, c = a && a.length || 0, e = 0, g, q, m, f, l, p; c--;)if (q = a[c])for (d[0][c + 1] = q.id || q.seriesname, f = q.id || c + 1, p = (q = q.data) && q.length || 0, l = 0; l < p; l += 1) {
                    m = l + 1;
                    if (!d[m]) {
                        for (g = b && b[l + e] || {}; g.vline;)e += 1, g = b[l + e] || {};
                        g = g.label || g.name || B;
                        d[m] = [g]
                    }
                    g = d[m];
                    m = q[l].id || m + "_" + f;
                    g[c + 1] = [m, Number(q[l].value)]
                }
                return d
            }, getData: function (b) {
                var d = this.jsVars.instanceAPI.getCollatedData(), a = [[B]], c = d.dataset, e = d.categories && d.categories[0] && d.categories[0].category, g = c && c.length || 0, q = 0, m, f, l;
                if (b)a = /^json$/ig.test(b) ?
                    d : O.core.transcodeData(d, "json", b); else for (; g--;)if (b = c[g])for (a[0][g + 1] = c[g].seriesname, d = (b = c[g] && c[g].data) && b.length || 0, l = 0; l < d; l += 1) {
                    f = l + 1;
                    if (!a[f]) {
                        for (m = e && e[l + q] || {}; m.vline;)q += 1, m = e[l + q] || {};
                        m = m.label || m.name || B;
                        a[f] = [m]
                    }
                    f = a[f];
                    f[g + 1] = Number(b[l].value)
                }
                return a
            }, setYAxisLimits: function (b, d) {
                var a = this.jsVars.instanceAPI, c = a.hcJSON, e = a.dataObj, g = e && e.chart || {}, c = c && c.yAxis && c.yAxis[0] || !1, q = !1;
                g.animation = !1;
                if (!c)return !1;
                void 0 !== b && b > a.highValue && b !== c.max ? (g.yaxismaxvalue = b, q = !0) : (b =
                    a.highValue > c.max ? a.highValue : c.max, g.yaxismaxvalue = b);
                void 0 !== d && d < a.lowValue && d !== c.min ? (g.yaxisminvalue = d, q = !0) : (d = a.lowValue < c.min ? a.lowValue : c.min, g.yaxisminvalue = d);
                q && a.updateChartWithData(e);
                return q
            }, getUpperLimit: function () {
                var b = this.jsVars.instanceAPI.hcJSON;
                return (b = b.yAxis && b.yAxis[0]) ? b.max : void 0
            }, setUpperLimit: function (b) {
                return this.jsVars.fcObj.setYAxisLimits(b, void 0)
            }, getLowerLimit: function () {
                var b = this.jsVars.instanceAPI.hcJSON;
                return (b = b.yAxis && b.yAxis[0]) ? b.min : void 0
            }, setLowerLimit: function (b) {
                return this.jsVars.fcObj.setYAxisLimits(void 0,
                    b)
            }
        }, updateChartWithData: function (b) {
            var d = this.chartInstance, a = d.jsVars, c = b && b.chart;
            b = a._reflowData || (a._reflowData = {});
            c = {
                dataObj: {
                    chart: {
                        yaxisminvalue: f(c.yaxisminvalue),
                        yaxismaxvalue: f(c.yaxismaxvalue),
                        animation: c.animation
                    }
                }
            };
            I(b, c, !0);
            O.hcLib.createChart(d, a.container, a.type)
        }, preSeriesAddition: function () {
            var b = this, d = b.hcJSON, a = b.dataObj.chart, c = d.chart;
            b.tooltipSepChar = d[ja].tooltipSepChar;
            c.allowAxisChange = f(a.allowaxischange, 1);
            c.changeDivWithAxis = 1;
            c.snapToDivOnly = f(a.snaptodivonly, 0);
            c.snapToDiv = c.snapToDivOnly ? 1 : f(a.snaptodiv, 1);
            c.snapToDivRelaxation = f(a.snaptodivrelaxation, 10);
            c.doNotSnap = f(a.donotsnap, 0);
            c.doNotSnap && (c.snapToDiv = c.snapToDivOnly = 0);
            c.showRangeError = f(a.showrangeerror, 0);
            f(a.allowaxischange, 1) && (d.callbacks || (d.callbacks = [])).push(function (a) {
                var c = this, d = arguments, m;
                Ab(a.renderer.container, "destroy", function () {
                    m && (m = clearTimeout(m))
                });
                m = setTimeout(function () {
                    b.drawAxisUpdateUI.apply(c, d);
                    m = null
                }, 1)
            })
        }, getTooltextCreator: function () {
            var b = arguments;
            return function () {
                var d =
                    arguments, a = d.length, c, e, g;
                for (g = 0; g < a; g += 1)void 0 !== (e = d[g]) && void 0 !== (c = b[g]) && (b[g] = "object" === typeof c ? I(c, e) : e);
                return db.apply(this, b)
            }
        }, getPointStub: function (b, d, a, c, e, g, q) {
            var m = this.isDual, v = this.dataObj.chart;
            c = c[ja];
            var l = null === d ? d : c.numberFormatter.dataLabels(d, 1 === q ? !0 : !1), p = z(G(h(b.tooltext, e.plottooltext, c.tooltext))), k = c.tooltipSepChar, n = e._sourceDataset;
            d = f(b.allowdrag, n.allowdrag, 1);
            var n = f(b.allownegativedrag, n.allownegativedrag, e.allownegativedrag, 1), t, u, E, D, x = 0, y = 0, M, ga;
            c.showTooltip ?
                void 0 !== p ? (ga = this.getTooltextCreator(p, [1, 2, 3, 4, 5, 6, 7], {
                    yaxisName: G(m ? q ? v.syaxisname : v.pyaxisname : v.yaxisname),
                    xaxisName: G(v.xaxisname),
                    formattedValue: l,
                    label: a
                }, b, v, e), e = ga(), e === p && (ga = void 0, x = 1)) : null === l ? e = !1 : (c.seriesNameInToolTip && (D = K(e && e.seriesname)), e = D ? D + k : B, M = e += a ? a + k : B, c.showPercentInToolTip ? t = !0 : e += l) : e = !1;
            f(b.showvalue, g) ? void 0 !== z(b.displayvalue) ? (E = G(b.displayvalue), y = 1) : c.showPercentValues ? u = !0 : E = l : E = B;
            g = h(G(b.displayvalue), l, B);
            b = h(b.link);
            return {
                displayValue: E,
                displayValueArgs: g,
                categoryLabel: a,
                toolText: e,
                link: b,
                showPercentValues: u,
                showPercentInToolTip: t,
                allowDrag: d,
                allowNegDrag: n,
                _toolTextStr: M,
                _isUserValue: y,
                _isUserTooltip: x,
                _getTooltext: ga
            }
        }
    };
    r("dragnode", {
        friendlyName: "Dragable Node Chart",
        standaloneInit: !0,
        decimals: 2,
        numdivlines: 0,
        numVDivLines: 0,
        defaultZeroPlaneHighlighted: !1,
        defaultZeroPlaneHidden: !0,
        spaceManager: Ia.spaceManager,
        drawButtons: Ia.drawButtons,
        updateChartWithData: Ia.updateChartWithData,
        creditLabel: !1,
        canvasPaddingModifiers: null,
        defaultSeriesType: "dragnode",
        rendererId: "dragnode",
        tooltipsepchar: " - ",
        showAxisLimitGridLines: 0,
        cleanedData: function (b, d) {
            var a = b && b.hcJSON, c = d && d.hcJSON, e, g, q, m, f, l, p, k, n;
            if (a && c) {
                if (a.series && c.series && (f = c.series.length))for (k = 0; k < f; k += 1)if (g = c.series[k], e = a.series[k], g.data && (l = g.data.length))for (n = 0; n < l; n += 1)!0 === g.data[n] && e && e.data && e.data[n] && (delete e.data[n], e.data[n] = {y: null});
                if (a.connectors && c.connectors && (q = c.connectors.length))for (k = 0; k < q; k += 1)if (g = c.connectors[k], e = a.connectors[k], g.connector && (p = g.connector.length))for (n =
                                                                                                                                                                                                       0; n < p; n += 1)!0 === g.connector[n] && e && e.connector && e.connector[n] && (delete e.connector[n], e.connector[n] = {});
                if (a.dragableLabels && c.dragableLabels && (m = c.dragableLabels.length))for (k = 0; k < m; k += 1)!0 === c.dragableLabels[k] && a.dragableLabels[k] && (delete a.dragableLabels[k], a.dragableLabels[k] = {})
            }
        },
        eiMethods: I(cb(r.scatterbase.eiMethods, Ia.eiMethods), {
            addNode: function (b) {
                var d = this.jsVars, a = d.instanceAPI, c = d._reflowData || (d._reflowData = {}), e = a.hcJSON, g = a.numberFormatter, a = h(b.datasetId), q = g.getCleanValue(b.y),
                    g = g.getCleanValue(b.x), m = !1, f = e.series, l = f.length, p = e.xAxis.min, k = e.xAxis.max, n = e.yAxis[0].min, t = e.yAxis[0].max, e = {hcJSON: {series: []}}, u = e.hcJSON.series, E;
                if (void 0 !== a && null !== q && q >= n && q <= t && null !== g && g >= p && g <= k) {
                    for (p = 0; p < l && !m; p += 1)a == f[p].id && (u[p] = {data: []}, m = !0, E = f[p], n = E.data, k = n.length, n.push(n = E._dataParser(b, k, g, q)), u[p].data[k] = n, I(c, e, !0), E = {
                        index: k,
                        dataIndex: k,
                        link: b.link,
                        y: b.y,
                        x: b.x,
                        shape: b.shape,
                        width: b.width,
                        height: b.height,
                        radius: b.radius,
                        sides: b.sides,
                        label: b.name,
                        toolText: b.tooltext,
                        id: b.id,
                        datasetIndex: p,
                        datasetName: E.name,
                        sourceType: "dataplot"
                    });
                    if (m)return eb(d, E, "nodeadded"), O.raiseEvent("nodeadded", E, d.fcObj), !0
                }
                return !1
            }, getNodeAttribute: function (b) {
                var d = this.jsVars, a = d.instanceAPI, d = d._reflowData || (d._reflowData = {}), d = d.hcJSON && d.hcJSON.series || [], a = a.hcJSON.series, c = a.length, e, g, q, m;
                if (void 0 !== b)for (e = 0; e < c; e += 1)for (g = a[e], m = g.data, q = m.length, g = 0; g < q; g += 1)if (m[g].id === b)return d[e] && d[e].data && d[e].data[g] ? I(m[g]._options, d[e].data[g]._options, !0) : m[g]._options;
                return !1
            },
            setNodeAttribute: function (b, d, a) {
                var c = this.jsVars, e = c.instanceAPI, g = c._reflowData || (c._reflowData = {}), q = e.hcJSON, m = e.numberFormatter, f = q.series, l = f.length, p = q.xAxis.min, k = q.xAxis.max, n = q.yAxis[0].min, h = q.yAxis[0].max, q = {hcJSON: {series: []}}, e = q.hcJSON.series, u = g.hcJSON && g.hcJSON.series || [], E, D, x, y;
                "object" === typeof d && void 0 === a ? y = d : (y = {}, y[d] = a);
                if (void 0 !== b)for (d = 0; d < l; d += 1)for (E = f[d], D = E.data, x = D.length, a = 0; a < x; a += 1)if (b === D[a].id)return b = D[a], delete y.id, u[d] && u[d].data && u[d].data[a] && u[d].data[a]._options &&
                (y = I(u[d].data[a]._options, y, !0)), y = I(b._options, y, !0), b = m.getCleanValue(y.y), m = m.getCleanValue(y.x), null !== b && b >= n && b <= h && null !== m && m >= p && m <= k ? (e[d] = {data: []}, p = E._dataParser(y, a, m, b), k = {
                    index: a,
                    dataIndex: a,
                    link: y.link,
                    y: y.y,
                    x: y.x,
                    shape: y.shape,
                    width: y.width,
                    height: y.height,
                    radius: y.radius,
                    sides: y.sides,
                    label: y.name,
                    toolText: y.tooltext,
                    id: y.id,
                    datasetIndex: d,
                    datasetName: E.name,
                    sourceType: "dataplot"
                }, e[d].data[a] = p, I(g, q, !0), eb(c, k, "nodeupdated"), O.raiseEvent("nodeupdated", k, c.fcObj), !0) : !1;
                return !1
            },
            deleteNode: function (b) {
                if (void 0 !== b) {
                    var d = this.jsVars, a = d.instanceAPI, c = d._reflowClean || (d._reflowClean = {}), e = a.hcJSON.series, g = {hcJSON: {series: []}}, q, m, f, l, p;
                    if (e && (f = e.length))for (l = 0; l < f; l += 1)if ((a = e[l]) && (m = a.data) && (q = m.length))for (p = 0; p < q; p += 1)if (b === m[p].id)return g.hcJSON.series[l] = {data: []}, g.hcJSON.series[l].data[p] = !0, I(c, g, !0), b = m[p], b = {
                        index: p,
                        dataIndex: p,
                        link: b.link,
                        y: b.y,
                        x: b.x,
                        shape: b._options.shape,
                        width: b._options.width,
                        height: b._options.height,
                        radius: b._options.radius,
                        sides: b._options.sides,
                        label: b.displayValue,
                        toolText: b.toolText,
                        id: b.id,
                        datasetIndex: l,
                        datasetName: a.name,
                        sourceType: "dataplot"
                    }, eb(d, b, "nodedeleted"), O.raiseEvent("nodedeleted", b, d.fcObj), !0
                }
                return !1
            }, addConnector: function (b) {
                if ("object" === typeof b) {
                    var d = this.jsVars, a = d.instanceAPI, c = d._reflowData || (d._reflowData = {}), a = a.hcJSON, e = a.connectors && a.connectors[0] || {connector: []}, a = e.connector.length, g = {hcJSON: {connectors: [{connector: []}]}};
                    b = e._connectorParser && e._connectorParser(b, a);
                    e = {
                        arrowAtEnd: b.arrowAtEnd,
                        arrowAtStart: b.arrowAtStart,
                        fromNodeId: b.from,
                        id: b.id,
                        label: b.label,
                        link: b.connectorLink,
                        sourceType: "connector",
                        toNodeId: b.to
                    };
                    g.hcJSON.connectors[0].connector[a] = b;
                    I(c, g, !0);
                    eb(d, e, "connectoradded");
                    O.raiseEvent("connectoradded", e, d.fcObj);
                    return !0
                }
                return !1
            }, editConnector: function (b, d, a) {
                var c = this.jsVars, e = c.instanceAPI, g = c._reflowData || (c._reflowData = {}), e = e.hcJSON, q = e.connectors || (e.connectors = []), m = q.length, e = {hcJSON: {connectors: []}}, f = e.hcJSON.connectors, l, p, k, n;
                "object" === typeof d && void 0 === a ? n = d : (n = {}, n[d] = a);
                if (void 0 !==
                    b)for (d = 0; d < m; d += 1)if ((p = q[d]) && (l = p.connector))for (k = l.length, a = 0; a < k; a += 1)if (b === l[a].id)return l = l[a], delete n.id, g.hcJSON && g.hcJSON.connectors && g.hcJSON.connectors[d] && g.hcJSON.connectors[d].connector && g.hcJSON.connectors[d].connector[a] && g.hcJSON.connectors[d].connector[a]._options && (n = I(g.hcJSON.connectors[d].connector[a]._options, n, !0)), n = I(l._options, n, !0), b = {
                    arrowAtEnd: Boolean(n.arrowatend),
                    arrowAtStart: Boolean(n.arrowatstart),
                    fromNodeId: n.from,
                    id: b,
                    label: n.label,
                    link: n.link,
                    sourceType: "connector",
                    toNodeId: n.to
                }, f[d] = {connector: []}, l = p._connectorParser(n, a), f[d].connector[a] = l, I(g, e, !0), eb(c, b, "connectorupdated"), O.raiseEvent("connectorupdated", b, c.fcObj), !0;
                return !1
            }, deleteConnector: function (b) {
                if (void 0 !== b) {
                    var d = this.jsVars, a = d.instanceAPI, c = d._reflowClean || (d._reflowClean = {}), e = a.hcJSON.connectors, a = {hcJSON: {connectors: []}}, g, q, m, f, l, p = {};
                    if (e && (f = e.length))for (l = 0; l < f; l += 1)if ((g = e[l]) && (m = g.connector) && (q = m.length))for (g = 0; g < q; g += 1)if (b === m[g].id)return b = m[g], p = {
                        arrowAtEnd: b.arrowAtEnd,
                        arrowAtStart: b.arrowAtStart,
                        fromNodeId: b.from,
                        id: b.id,
                        label: b.label,
                        link: b.connectorLink,
                        sourceType: "connector",
                        toNodeId: b.to
                    }, a.hcJSON.connectors[l] = {connector: []}, a.hcJSON.connectors[l].connector[g] = !0, I(c, a, !0), eb(d, p, "connectordeleted"), O.raiseEvent("connectordeleted", p, d.fcObj), !0
                }
                return !1
            }, addLabel: function (b) {
                if (b) {
                    var d = this.jsVars, a = d.instanceAPI, c = d._reflowData || (d._reflowData = {}), e = {hcJSON: {dragableLabels: []}};
                    e.hcJSON.dragableLabels[(a.hcJSON.dragableLabels || []).length] = b;
                    I(c, e, !0);
                    b =
                    {text: b.text, x: b.x, y: b.y, allowdrag: b.allowdrag, sourceType: "labelnode", link: b.link};
                    eb(d, b, "labeladded");
                    O.raiseEvent("labeladded", b, d.fcObj);
                    return !0
                }
                return !1
            }, deleteLabel: function (b, d) {
                var a = this.jsVars, c = a.instanceAPI, e = a._reflowClean || (a._reflowClean = {}), g = {hcJSON: {dragableLabels: []}};
                return b < (c.hcJSON.dragableLabels || []).length ? (g.hcJSON.dragableLabels[b] = !0, I(e, g, !0), eb(a, d, "labeldeleted"), O.raiseEvent("labeldeleted", d, a.fcObj), !0) : !1
            }, setThreshold: function (b) {
                var d = this.jsVars.hcObj.connectorsStore ||
                    [], a = d.length, c, e;
                for (e = 0; e < a; e += 1)(c = d[e]) && c.options && (c.options.conStrength < b ? (c.graphic && c.graphic.hide(), c.text && (c.text.hide(), c.text.textBoundWrapper && c.text.textBoundWrapper.hide())) : (c.graphic && c.graphic.show(), c.text && (c.text.show(), c.text.textBoundWrapper && c.text.textBoundWrapper.show())))
            }
        }),
        getCollatedData: function () {
            var b = this.chartInstance, d = b.__state, a = b.jsVars, b = this.updatedDataObj || I({}, b.getChartData(O.dataFormats.JSON)), c = a._reflowData, e = a._reflowClean, a = (b.labels || (b.labels = {label: []}),
            b.labels.label || (b.labels.label = [])), g = c && c.hcJSON && c.hcJSON.dragableLabels, q = e && e.hcJSON && e.hcJSON.dragableLabels, m = b.connectors, f = c && c.hcJSON && c.hcJSON.connectors, l = e && e.hcJSON && e.hcJSON.connectors, p = b.dataset, k = c && c.hcJSON && c.hcJSON.series, c = e && e.hcJSON && e.hcJSON.series, e = k && k.length, n, h, u, E;
            if (void 0 !== d.hasStaleData && !d.hasStaleData && this.updatedDataObj)return this.updatedDataObj;
            if (p && k)for (; e--;)if (h = p[e] && p[e].data, (n = (u = k[e] && k[e].data) && u.length) && h)for (; n--;)if (E = u[n])h[n] ? I(h[n], E._options) :
                h[n] = E._options;
            if (e = f && f.length)for (b.connectors || (m = b.connectors = [{connector: []}]); e--;)if (k = m[e] && m[e].connector, (n = (h = f[e] && f[e].connector) && h.length) && k)for (; n--;)if (u = h[n])k[n] ? I(k[n], u._options) : k[n] = u._options;
            if ((e = g && g.length) && g)for (; e--;)g[e] && (a[e] = g[e]);
            jc(p, c);
            jc(m, l);
            jc(a, q);
            d.hasStaleData = !1;
            return this.updatedDataObj = b
        },
        createHtmlDialog: function (b, d, a, c, e, g) {
            var q = b.paper, m = this.hcJSON[ja].inCanvasStyle, f = b.chartWidth, l = b.chartHeight, h = {
                color: m.color,
                textAlign: "center",
                paddingTop: "1px",
                border: "1px solid #cccccc",
                borderRadius: "4px",
                cursor: "pointer",
                _cursor: "hand",
                backgroundColor: "#ffffff",
                zIndex: 21,
                "-webkit-border-radius": "4px"
            }, k;
            k = q.html("div", {fill: "transparent", width: f, height: l}, {
                fontSize: "10px",
                lineHeight: "15px",
                fontFamily: m.fontFamily
            }, b.container);
            k.veil = q.html("div", {fill: "000000", width: f, height: l, opacity: .3}, void 0, k);
            k.dialog = q.html("div", {
                x: (f - d) / 2,
                y: (l - a) / 2,
                fill: "efefef",
                strokeWidth: 1,
                stroke: "000000",
                width: d,
                height: a
            }, {
                borderRadius: "5px",
                boxShadow: "1px 1px 3px #000000",
                "-webkit-border-radius": "5px",
                "-webkit-box-shadow": "1px 1px 3px #000000",
                filter: 'progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color="#000000")'
            }, k);
            k.ok = q.html("div", {
                x: d - 70 - 5,
                y: a - 23 - 5,
                width: 65,
                height: 17,
                text: "Submit",
                tabIndex: 1
            }, h, k.dialog).on("click", c);
            k.cancel = q.html("div", {
                x: d - 140 - 5,
                y: a - 23 - 5,
                width: 65,
                height: 17,
                text: "Cancel",
                tabIndex: 2
            }, h, k.dialog).on("click", e);
            k.remove = q.html("div", {
                    x: d - 210 - 5,
                    y: a - 23 - 5,
                    width: 65,
                    height: 17,
                    text: "Delete",
                    tabIndex: 3,
                    visibility: "hidden"
                }, h,
                k.dialog).on("click", g);
            k.handleKeyPress = function (a) {
                13 === a.keyCode ? k.ok.trigger(ia ? "touchStart" : "click", a) : 27 === a.keyCode && k.cancel.trigger(ia ? "touchStart" : "click", a)
            };
            k.hide();
            return k
        },
        nodeUpdateUIDefinition: [{key: "id", text: "Id", inputWidth: 60, x: 10, y: 15}, {
            key: "dataset",
            text: "Dataset",
            inputType: "select",
            inputWidth: 110,
            innerHTML: void 0,
            x: 170,
            y: 15
        }, {key: "x", text: "Value", x: 10, y: 40, inputWidth: 21}, {
            key: "y",
            text: ",",
            x: 88,
            y: 40,
            inputWidth: 21,
            labelWidth: 5
        }, {text: "(x, y)", x: 125, y: 40, labelWidth: 33, noInput: !0},
            {key: "tooltip", text: "Tooltip", inputWidth: 105, x: 170, y: 40}, {
                key: "label",
                text: "Label",
                inputWidth: 92,
                x: 10,
                y: 65
            }, {
                key: "labelalign",
                text: "Align",
                labelWidth: 70,
                inputWidth: 110,
                inputType: "select",
                innerHTML: '<option></option><option value="top">Top</option><option value="middle">Middle</option><option value="bottom">Bottom</option>',
                x: 145,
                y: 63
            }, {key: "color", text: "Color", x: 10, y: 90, inputWidth: 60}, {
                key: "colorOut",
                innerHTML: "&nbsp;",
                x: 85,
                y: 90,
                inputWidth: 15,
                inputType: "span"
            }, {
                key: "alpha", text: "Alpha", x: 170, y: 90,
                inputWidth: 20
            }, {
                key: "draggable",
                text: "Allow Drag",
                value: !0,
                inputWidth: 20,
                x: 250,
                y: 90,
                labelWidth: 58,
                inputPaddingTop: 3,
                type: "checkbox"
            }, {
                key: "shape",
                text: "Shape",
                inputType: "select",
                inputWidth: 97,
                innerHTML: '<option value="rect">Rectangle</option><option value="circ">Circle</option><option value="poly">Polygon</option>',
                x: 10,
                y: 115
            }, {key: "rectHeight", text: "Height", x: 170, y: 115, inputWidth: 20}, {
                key: "rectWidth",
                text: "Width",
                x: 255,
                y: 115,
                inputWidth: 20
            }, {key: "circPolyRadius", text: "Radius", x: 170, y: 115, inputWidth: 20},
            {key: "polySides", text: "Sides", x: 255, y: 115, inputWidth: 20}, {
                key: "link",
                text: "Link",
                x: 10,
                y: 140,
                inputWidth: 92
            }, {
                key: "image",
                text: "Image",
                type: "checkbox",
                inputPaddingTop: 4,
                inputWidth: 20,
                x: 10,
                y: 170
            }, {key: "imgUrl", text: "URL", inputWidth: 105, x: 170, y: 170}, {
                key: "imgWidth",
                text: "Width",
                inputWidth: 20,
                x: 10,
                y: 195
            }, {key: "imgHeight", text: "Height", inputWidth: 20, x: 82, y: 195}, {
                key: "imgAlign",
                text: "Align",
                inputType: "select",
                inputWidth: 75,
                innerHTML: '<option value="top">Top</option><option value="middle">Middle</option><option value="bottom">Bottom</option>',
                x: 170,
                y: 195
            }],
        showNodeUpdateUI: function () {
            var b = function (a) {
                a = a.cacheUpdateUI;
                for (var b = a.fields.shape, d = ["rectWidth", "rectHeight", "circPolyRadius", "polySides"], q = d.length, m; q--;)m = d[q], /rect|poly|circ/ig.test(m) && (a.labels[m].hide(), a.fields[m].hide()), (new RegExp(h(b.val(), "rect"), "ig")).test(m) && (a.labels[m].show(), a.fields[m].show())
            }, d = function (a) {
                a = a.cacheUpdateUI.fields;
                var b = sb(a.color.val());
                b && a.colorOut.css({background: Cb(b)})
            }, a = function (a, b) {
                var d = a.cacheUpdateUI, q = a.chartHeight, m = d.fields.image.val(),
                    f = b ? 300 : 0, l = ["imgWidth", "imgHeight", "imgAlign", "imgUrl"], h, k, n;
                h = m ? 250 : 215;
                d.ok.hide();
                d.cancel.hide();
                d.remove.hide();
                d.error.hide();
                for (k = l.length; !m && k--;)n = l[k], d.labels[n].hide(), d.fields[n].hide();
                w.danimate.animate(d.dialog.element, {top: (q - h) / 2, height: h}, f, "linear", function () {
                    for (k = l.length; k-- && m;)n = l[k], d.labels[n].show(), d.fields[n].show();
                    d.ok.attr({y: h - 23 - 5}).show();
                    d.cancel.attr({y: h - 23 - 5}).show();
                    d.remove.attr({y: h - 23 - 5});
                    d.error.attr({y: h - 23 - 5 + 4}).show();
                    d.edit ? d.remove.show() : d.remove.hide()
                })
            };
            return function (c, e, g) {
                var q = this, m = c.cacheUpdateUI, f = c.paper, l = {
                    width: "80px",
                    border: "1px solid #cccccc",
                    fontSize: "10px",
                    lineHeight: "15px",
                    padding: "2px",
                    fontFamily: q.hcJSON[ja].inCanvasStyle.fontFamily
                }, h = 0, k = {textAlign: "right"}, n = m && m.fields, t = m && m.labels, u;
                m || (m = c.cacheUpdateUI = q.createHtmlDialog(c, 350, 215, function () {
                    var a = m && m.fields, b = m.edit, c = q.chartInstance, d = q.hcJSON, e, g, k, f, l, n, v;
                    if (!d)return !1;
                    e = d.xAxis.min;
                    g = d.yAxis[0].min;
                    d = d.series;
                    k = d.length;
                    if (a) {
                        switch (a.shape.val()) {
                            case "circ":
                                l =
                                    "circle";
                                break;
                            case "poly":
                                l = "polygon";
                                break;
                            default:
                                l = "rectangle"
                        }
                        v = {
                            x: K(a.x.val(), e),
                            y: K(a.y.val(), g),
                            id: e = a.id.val(),
                            datasetId: a.dataset.val(),
                            name: a.label.val(),
                            tooltext: a.tooltip.val(),
                            color: a.color.val(),
                            alpha: a.alpha.val(),
                            labelalign: a.labelalign.val(),
                            allowdrag: a.draggable.val(),
                            shape: l,
                            width: a.rectWidth.val(),
                            height: a.rectHeight.val(),
                            radius: a.circPolyRadius.val(),
                            numsides: a.polySides.val(),
                            imagenode: a.image.val(),
                            imagewidth: a.imgWidth.val(),
                            imageheight: a.imgHeight.val(),
                            imagealign: a.imgAlign.val(),
                            imageurl: a.imgUrl.val(),
                            link: a.link.val()
                        };
                        if (void 0 !== e && !b)for (h = 0; h < k && !f; h += 1)for (l = d[h].data, n = l.length, g = 0; g < n; g += 1)e === l[g].id && (f = !0);
                        if (f)m.error.attr({text: "ID already exist."}), a.label.focus(); else {
                            b ? c && c.setNodeAttribute && c.setNodeAttribute(v.id, v) : c && c.addNode && c.addNode(v);
                            return
                        }
                    }
                    m.enableFields()
                }, function () {
                    m.hide();
                    m.enableFields();
                    m.error.attr({text: B})
                }, function () {
                    q.chartInstance.deleteNode && q.chartInstance.deleteNode(m.fields.id.val())
                }), u = m.dialog, t = m.labels = {}, n = m.fields = {});
                m.config = e;
                m.edit = g;
                m.error || (m.error = f.html("span", {color: "ff0000", x: 30, y: 228}, void 0, u));
                m.enableFields || (m.enableFields = function () {
                    for (var a in e)e[a] && e[a].disabled && n[a] && n[a].element.removeAttribute("disabled")
                });
                ib(this.nodeUpdateUIDefinition, function (g) {
                    var q, h = g.key, p = {}, M = e[h] || {}, ga, wa;
                    !t[h] && (t[h] = f.html("label", {x: g.x, y: g.y, width: g.labelWidth || 45, text: g.text}, k, u));
                    if (!g.noInput) {
                        q = n[h];
                        if (!q) {
                            l.border = "checkbox" == g.type ? B : "1px solid #cccccc";
                            q = n[h] = f.html(g.inputType || "input", {
                                x: g.labelWidth &&
                                g.labelWidth + 5 || 50,
                                y: -2 + (g.inputPaddingTop || 0),
                                width: g.inputWidth || 50,
                                name: h || ""
                            }, l);
                            if ("select" !== g.inputType)q.attr({type: g.type || "text"}).on("keyup", m.handleKeyPress);
                            q.add(t[h])
                        }
                        ea(ga = K(M.innerHTML, g.innerHTML)) && (p.innerHTML = ga);
                        M.disabled && (p.disabled = "disabled");
                        q.attr(p);
                        ea(wa = K(M.value, g.value)) && q.val(wa);
                        "shape" == h && q.on("change", function () {
                            b(c)
                        });
                        "image" == h && q.on("click", function () {
                            a(c, !0)
                        });
                        "color" == h && q.on("keyup", function () {
                            d(c)
                        })
                    }
                });
                d(c);
                a(c);
                b(c);
                c.options.chart.animation ? m.fadeIn("fast") :
                    m.show();
                m.fields[g ? "label" : "id"].focus()
            }
        }(),
        labelUpdateUIDefinition: [{key: "label", text: "Label*", x: 10, y: 15, inputWidth: 235}, {
            key: "size",
            text: "Size",
            x: 10,
            y: 40
        }, {key: "padding", text: "Padding", x: 10, y: 65}, {
            key: "x",
            text: "Position",
            x: 120,
            y: 65,
            labelWidth: 70,
            inputWidth: 25
        }, {key: "y", text: ",", x: 225, y: 65, labelWidth: 10, inputWidth: 25}, {
            key: "xy",
            text: "(x, y)",
            x: 260,
            y: 65,
            noInput: !0
        }, {
            key: "allowdrag",
            text: "Allow Drag",
            x: 120,
            y: 40,
            inputType: "checkbox",
            inputPaddingTop: 3,
            inputWidth: 15,
            labelWidth: 70,
            val: 1
        }, {
            key: "color",
            text: "Color", x: 10, y: 90
        }, {key: "alpha", text: "Alpha", x: 145, y: 90, inputWidth: 30, val: "100"}, {
            key: "bordercolor",
            text: "Border Color",
            x: 10,
            y: 125,
            labelWidth: 100
        }, {key: "bgcolor", text: "Background Color", x: 10, y: 150, labelWidth: 100}],
        showLabelUpdateUI: function (b, d) {
            var a = this, c = b.paper, e = b.cacheLabelUpdateUI, g = {
                border: "1px solid #cccccc",
                fontSize: "10px",
                lineHeight: "15px",
                fontFamily: a.hcJSON[ja].inCanvasStyle.fontFamily,
                padding: "2px"
            }, q = {textAlign: "right"}, f = e && e.fields, v = e && e.labels, l, p, k;
            e || (e = b.cacheLabelUpdateUI =
                a.createHtmlDialog(b, 315, 205, function () {
                    var b = e && e.fields, c;
                    b && (c = {
                        text: b.label.val(),
                        x: b.x.val(),
                        y: b.y.val(),
                        color: b.color.val(),
                        alpha: b.alpha.val(),
                        bgcolor: b.bgcolor.val(),
                        bordercolor: b.bordercolor.val(),
                        fontsize: b.size.val(),
                        allowdrag: b.allowdrag.val(),
                        padding: b.padding.val()
                    }, c.text ? a.chartInstance && a.chartInstance.addLabel && a.chartInstance.addLabel(c) : (e.error.attr({text: "Label cannot be blank."}), b.label.focus()))
                }, function () {
                    e.error.attr({text: ""});
                    e.hide()
                }), k = e.dialog, v = e.labels = {}, f = e.fields =
            {});
            ib(a.labelUpdateUIDefinition, function (a) {
                var b = a.key;
                v[b] || (v[b] = c.html("label", {x: a.x, y: a.y, width: a.labelWidth || 45, text: a.text}, q, k));
                a.noInput || ((l = f[b]) || (l = f[b] = c.html("input", {
                    y: -2 + (a.inputPaddingTop || 0),
                    x: a.labelWidth && a.labelWidth + 5 || 50,
                    width: a.inputWidth || 50,
                    type: a.inputType || "text",
                    name: b || ""
                }, g, v[b]).on("keyup", e.handleKeyPress)), void 0 !== (p = h(d[b], a.val)) && l.val(p))
            });
            e.error || (e.error = c.html("span", {color: "ff0000", x: 10, y: 180}, void 0, k));
            b.animation ? e.fadeIn("fast") : e.show();
            e.fields.label.focus()
        },
        showLabelDeleteUI: function (b, d) {
            var a = this, c = b.paper, e = b["cache-label-delete-ui"], g = d.data && d.data("data") || {}, q = d.data && d.data("eventArgs"), g = g && g.labelNode;
            e || (e = b["cache-label-delete-ui"] = a.createHtmlDialog(b, 250, 100, void 0, function () {
                e.hide()
            }, function () {
                a.chartInstance.deleteLabel(g.index, q)
            }), e.message = c.html("span", {
                x: 10,
                y: 10,
                width: 230,
                height: 80
            }).add(e.dialog), e.ok.hide(), e.remove.translate(175).show());
            e.message.attr({text: 'Would you really like to delete the label: "' + g.text + '"?'});
            b.animation ?
                e.fadeIn("fast") : e.show()
        },
        connectorUpdateUIDefinition: [{
            key: "fromid",
            text: "Connect From",
            inputType: "select",
            x: 10,
            y: 15,
            labelWidth: 80,
            inputWidth: 100
        }, {
            key: "toid",
            text: "Connect To",
            inputType: "select",
            x: 10,
            y: 40,
            labelWidth: 80,
            inputWidth: 100
        }, {
            key: "arratstart",
            text: "Arrow At Start",
            x: 200,
            y: 15,
            type: "checkbox",
            inputPaddingTop: 3,
            labelWidth: 80,
            inputWidth: 15
        }, {
            key: "arratend",
            text: "Arrow At End",
            x: 200,
            y: 40,
            type: "checkbox",
            inputPaddingTop: 3,
            labelWidth: 80,
            inputWidth: 15
        }, {
            key: "label", text: "Label", x: 10, y: 75, labelWidth: 40,
            inputWidth: 120
        }, {key: "id", text: "Node ID", x: 190, y: 75, inputWidth: 55}, {
            key: "color",
            text: "Color",
            x: 10,
            y: 100,
            labelWidth: 40,
            inputWidth: 35
        }, {key: "alpha", text: "Alpha", x: 110, y: 100, inputWidth: 25, labelWidth: 35}, {
            key: "strength",
            text: "Strength",
            x: 190,
            y: 100,
            inputWidth: 55,
            val: "0.1"
        }, {key: "url", text: "Link", x: 10, y: 125, labelWidth: 40, inputWidth: 120}, {
            key: "tooltext",
            text: "Tooltip",
            x: 190,
            y: 125,
            labelWidth: 40,
            inputWidth: 60
        }, {
            key: "dashed",
            text: "Dashed",
            x: 10,
            y: 150,
            type: "checkbox",
            inputPaddingTop: 3,
            inputWidth: 15,
            labelWidth: 40
        },
            {key: "dashgap", text: "Dash Gap", x: 85, y: 150, labelWidth: 60, inputWidth: 25}, {
                key: "dashlen",
                text: "Dash Length",
                x: 190,
                y: 150,
                labelWidth: 70,
                inputWidth: 30
            }],
        showConnectorUpdateUI: function (b, d, a) {
            var c = this.chartInstance, e = b.paper, g = b.cacheConnectorUpdateUI, q = {
                border: "1px solid #cccccc",
                fontSize: "10px",
                lineHeight: "15px",
                fontFamily: this.hcJSON[ja].inCanvasStyle.fontFamily,
                padding: "2px"
            }, f = {textAlign: "right"}, v = g && g.fields, l = g && g.labels, p, k, n, t;
            g || (g = b.cacheConnectorUpdateUI = this.createHtmlDialog(b, 315, 215, function () {
                var b =
                    g && g.fields, d;
                b && (d = {
                    from: b.fromid.val(),
                    to: b.toid.val(),
                    id: b.id.val(),
                    label: b.label.val(),
                    color: b.color.val(),
                    alpha: b.alpha.val(),
                    link: b.url.val(),
                    tooltext: b.tooltext.val(),
                    strength: b.strength.val(),
                    arrowatstart: b.arratstart.val(),
                    arrowatend: b.arratend.val(),
                    dashed: b.dashed.val(),
                    dashlen: b.dashlen.val(),
                    dashgap: b.dashgap.val()
                }, d.from ? d.to ? d.from != d.to ? (a ? c.editConnector(d.id, d) : c.addConnector(d), g.enableFields()) : (g.error.attr({text: "Connector cannot start and end at the same node!"}), b.fromid.focus()) :
                    (g.error.attr({text: "Please select a valid connector end."}), b.toid.focus()) : (g.error.attr({text: "Please select a valid connector start."}), b.fromid.focus()))
            }, function () {
                g.error.attr({text: ""});
                g.enableFields();
                g.hide()
            }, function () {
                c.deleteConnector(g.fields.id.val())
            }), t = g.dialog, l = g.labels = {}, v = g.fields = {});
            g.config = d;
            g.enableFields = function () {
                for (var a in d)d[a] && d[a].disabled && v[a] && v[a].element.removeAttribute("disabled")
            };
            ib(this.connectorUpdateUIDefinition, function (a) {
                var b = a.key, c = d[b] ||
                    {};
                l[b] || (l[b] = e.html("label", {x: a.x, y: a.y, width: a.labelWidth || 45, text: a.text}, f, t));
                if (!a.noInput) {
                    if (!(k = v[b])) {
                        k = v[b] = e.html(a.inputType || "input", {
                            y: -2 + (a.inputPaddingTop || 0),
                            x: a.labelWidth && a.labelWidth + 5 || 50,
                            width: a.inputWidth || 50,
                            name: b || ""
                        }, q);
                        if ("select" !== a.inputType)k.attr({type: a.type || "text"}).on("keyup", g.handleKeyPress);
                        k.add(l[b])
                    }
                    (p = h(c.innerHTML, a.innerHTML)) && k.attr({innerHTML: p});
                    void 0 !== (n = h(c.val, a.val)) && k.val(n);
                    c.disabled && k.attr({disabled: "disabled"})
                }
            });
            g.checkDash = function () {
                var a =
                    v.dashed && v.dashed.val() ? "show" : "hide";
                l.dashgap && l.dashgap[a]();
                v.dashgap && v.dashgap[a]();
                l.dashlen && l.dashlen[a]();
                v.dashlen && v.dashlen[a]()
            };
            g.checkDash();
            v.dashed.on("click", g.checkDash);
            g.error || (g.error = e.html("span", {color: "ff0000", x: 10, y: 170}, void 0, t));
            g.remove[a ? "show" : "hide"]();
            b.animation ? g.fadeIn("fast") : g.show()
        },
        drawNodeUpdateButtons: function () {
            var b = this, d = b.logic, a = b.options, c = a.chart, e = a.pointStore || {}, g = a.series, a = (a = a[ja]) && a.outCanvasStyle || b.outCanvasStyle || {}, q = b.menu || (b.menu =
                    []), f = b.toolbar, h = g.length, l = "", p = "", k, n;
            for (n in e)l += '<option value="' + n + '">' + n + "</option>";
            for (n = 0; n < h; n += 1)e = g[n], p += '<option value="' + e.id + '">' + (e.name !== B && void 0 !== e.name && e.name + Ba + " " || B) + e.id + "</option>";
            q.push(k = Pb({
                chart: b, basicStyle: a, items: [{
                    text: "Add a Node", onclick: function () {
                        d.showNodeUpdateUI(b, {dataset: {innerHTML: p}})
                    }
                }, {
                    text: "Add a Label", onclick: function () {
                        d.showLabelUpdateUI(b, {})
                    }
                }, {
                    text: "Add a Connector", onclick: function () {
                        d.showConnectorUpdateUI(b, {fromid: {innerHTML: l}, toid: {innerHTML: l}})
                    }
                }],
                position: {
                    x: c.spacingLeft,
                    y: b.chartHeight - c.spacingBottom + (c.showFormBtn || c.showRestoreBtn ? 10 : -15)
                }
            }));
            b.elements.configureButton = f.add("configureIcon", function (a, b) {
                return function () {
                    k.visible ? k.hide() : k.show({x: a, y: b + 1})
                }
            }(), {
                x: c.spacingLeft,
                y: b.chartHeight - c.spacingBottom + (c.showFormBtn || c.showRestoreBtn ? 10 : -15),
                tooltip: "Add or edit items"
            })
        },
        postSeriesAddition: function () {
            var b = this.hcJSON, d = this.dataObj.chart, a = this.base.postSeriesAddition && this.base.postSeriesAddition.apply(this, arguments);
            b.legend.enabled =
                "1" == d.showlegend ? !0 : !1;
            (b.chart.viewMode = f(d.viewmode, 0)) || (b.callbacks || (b.callbacks = [])).push(this.drawNodeUpdateButtons);
            return a
        },
        pointHoverOptions: function (b, d, a, c) {
            var e = f(b.showhovereffect, d.showhovereffect, a.plothovereffect, a.showhovereffect), g = {}, q = !!h(b.hovercolor, d.hovercolor, a.plotfillhovercolor, b.hoveralpha, d.hoveralpha, a.plotfillhoveralpha, b.borderhovercolor, d.borderhovercolor, a.plotborderhovercolor, b.borderhoveralpha, d.borderhoveralpha, a.plotborderhoveralpha, b.borderhoverthickness,
                d.borderhoverthickness, a.plotborderhoverthickness, b.hoverheight, d.hoverheight, a.plothoverheight, b.hoverwidth, d.hoverwidth, a.plothoverwidth, b.hoverradius, d.hoverradius, a.plothoverradius, e), m = !1;
            if (void 0 === e && q || e)m = !0, e = h(b.hovercolor, d.hovercolor, a.plotfillhovercolor, Z(c.color, 70)), q = h(b.hoveralpha, d.hoveralpha, a.plotfillhoveralpha, c.alpha), g = {
                stroke: A(h(b.borderhovercolor, d.borderhovercolor, a.plotborderhovercolor, c.borderColor), f(b.borderhoveralpha, d.borderhoveralpha, a.plotborderhoveralpha, q, c.borderAlpha)),
                "stroke-width": f(b.borderhoverthickness, d.borderhoverthickness, a.plotborderhoverthickness, c.borderThickness),
                height: f(b.hoverheight, d.hoverheight, a.plothoverheight, c.height),
                width: f(b.hoverwidth, d.hoverwidth, a.plothoverwidth, c.width),
                r: f(b.hoverradius, d.hoverradius, a.plothoverradius, c.radius)
            }, b = c.use3D ? this.getPointColor(R(h(b.hovercolor, d.hovercolor, a.plotfillhovercolor, Z(c.color, 70))), h(b.hoveralpha, d.hoveralpha, a.plotfillhoveralpha, c.alpha), c.shapeType) : A(e, q), g.fill = pa(b);
            return {enabled: m, rolloverProperties: g}
        },
        point: function (b, d, a, c, e, g, q) {
            var m = this;
            b = f(c.ignoreemptydatasets, 0);
            var v = m.numberFormatter, l = (g = a.data) && g.length, p = f(a.showvalues, e[ja].showValues), k = f(c.useroundedges), n = !1, t = m.colorManager, u, E, D, x, y, M, ga, wa, r, F, P, Q, w, na;
            d.zIndex = 1;
            d.name = z(a.seriesname);
            M = d.id = h(a.id, q);
            if (b && !a.data)return d.showInLegend = !1, d;
            if (0 === f(a.includeinlegend) || void 0 === d.name)d.showInLegend = !1;
            E = h(c.plotfillalpha, "100");
            D = f(c.showplotborder, 1);
            q = R(h(c.plotbordercolor, "666666"));
            u = f(c.plotborderthickness, k ? 2 : 1);
            x =
                h(c.plotborderalpha, c.plotfillalpha, k ? "35" : "95");
            y = Boolean(f(c.use3dlighting, c.is3d, k ? 1 : 0));
            ga = R(h(a.color, t.getPlotColor()));
            wa = h(a.plotfillalpha, a.nodeFillAlpha, a.alpha, E);
            k = Boolean(f(a.showplotborder, D));
            r = R(h(a.plotbordercolor, a.nodebordercolor, q));
            F = f(a.plotborderthickness, a.nodeborderthickness, u);
            P = k ? h(a.plotborderalpha, a.nodeborderalpha, a.alpha, x) : "0";
            Q = Boolean(f(a.allowdrag, 1));
            d.marker = {
                enabled: !0,
                fillColor: A(ga, wa),
                lineColor: {FCcolor: {color: r, alpha: P}},
                lineWidth: F,
                symbol: "poly_4"
            };
            x = d._dataParser =
                function (b, d, g, q) {
                    d = h(b.id, M + "_" + d);
                    var k = Boolean(f(b.allowdrag, Q)), l = z(b.shape, "rectangle").toLowerCase(), n = z(b.height, 10), t = z(b.width, 10), u = z(b.radius, 10), E = z(b.numsides, 4), x = R(h(b.color, ga)), D = h(b.alpha, wa), C = z(b.imageurl), Y = Boolean(f(b.imagenode));
                    switch (l) {
                        case "circle":
                            na = 0;
                            break;
                        case "polygon":
                            na = 2;
                            l = fa(E);
                            break;
                        default:
                            na = 1
                    }
                    w = y ? m.getPointColor(x, D, na) : A(x, D);
                    E = m.pointHoverOptions(b, a, c, {
                        plotType: "funnel",
                        shapeType: na,
                        use3D: y,
                        height: n,
                        width: t,
                        radius: u,
                        color: x,
                        alpha: D,
                        borderColor: r,
                        borderAlpha: P,
                        borderThickness: F
                    });
                    return I(m.getPointStub(b, q, v.xAxis(g), e, a, p), {
                        hoverEffects: E,
                        _options: b,
                        y: q,
                        x: g,
                        id: d,
                        imageNode: Y,
                        imageURL: C,
                        imageAlign: z(b.imagealign, B).toLowerCase(),
                        imageWidth: z(b.imagewidth),
                        imageHeight: z(b.imageheight),
                        labelAlign: h(b.labelalign, Y && ea(C) ? Ka : "middle"),
                        allowDrag: k,
                        marker: {
                            enabled: !0,
                            fillColor: w,
                            lineColor: {FCcolor: {color: r, alpha: P}},
                            lineWidth: F,
                            radius: u,
                            height: n,
                            width: t,
                            symbol: l
                        },
                        tooltipConstraint: m.tooltipConstraint
                    })
                };
            for (q = 0; q < l; q += 1)if (u = g[q])k = v.getCleanValue(u.y), t = v.getCleanValue(u.x),
                null === k ? d.data.push({
                    _options: u,
                    y: null
                }) : (n = !0, d.data.push(x(u, q, t, k)), this.pointValueWatcher(e, k, t));
            b && !n && (d.showInLegend = !1);
            return d
        },
        getPointColor: function (b, d, a) {
            var c;
            b = R(b);
            d = jb(d);
            c = Z(b, 80);
            b = uc(b, 65);
            d = {FCcolor: {gradientUnits: "objectBoundingBox", color: c + "," + b, alpha: d + "," + d, ratio: Ob}};
            a ? d.FCcolor.angle = 1 === a ? 0 : 180 : (d.FCcolor.cx = .4, d.FCcolor.cy = .4, d.FCcolor.r = "50%", d.FCcolor.radialGradient = !0);
            return d
        },
        getPointStub: function (b, d, a, c, e) {
            var g = this.dataObj.chart, q = c[ja], f = null === d ? d : q.numberFormatter.dataLabels(d),
                v = z(G(h(b.tooltext, e.plottooltext, q.tooltext))), l = this.tooltipSepChar = q.tooltipSepChar, p = h(b.label, b.name);
            d = G(p);
            var k;
            c = B;
            var n = !1;
            q.showTooltip ? void 0 !== v ? (n = !0, e = db(v, [3, 4, 5, 6, 8, 9, 10, 11], {
                yaxisName: G(g.yaxisname),
                xaxisName: G(g.xaxisname),
                yDataValue: f,
                xDataValue: a,
                label: d
            }, b, g, e)) : void 0 !== p ? (e = d, n = !0) : null === f ? e = !1 : (q.seriesNameInToolTip && (k = K(e && e.seriesname)), e = c = k ? k + l : B, e += a ? a + l : B, e += f) : e = !1;
            b = h(b.link);
            return {displayValue: d, toolText: e, link: b, _toolTextStr: c, _isUserTooltip: n}
        },
        connector: function (b,
                             d, a, c, e) {
            var g = e[ja], q = g.smartLabel;
            e = (b = a.connector) && b.length;
            var m, v, l, p, k, n, t, u, E, D, x, y = z(G(h(a.connectortooltext, c.connectortooltext))), M = "$fromLabel" + g.tooltipSepChar + "$toLabel";
            m = f(a.stdthickness, 1);
            v = R(h(a.color, "FF5904"));
            l = h(a.alpha, "100");
            p = f(a.dashgap, 5);
            k = f(a.dashlen, 5);
            n = Boolean(f(a.dashed, 0));
            t = Boolean(f(a.arrowatstart, 1));
            u = Boolean(f(a.arrowatend, 1));
            E = f(a.strength, 1);
            c = d.connector;
            D = d._connectorParser = function (a, b) {
                var c = G(h(a.label, a.name)), d = h(a.alpha, l), d = {
                    FCcolor: {
                        color: R(h(a.color,
                            v)), alpha: d
                    }
                }, e = q.getOriSize(c), D = z(G(h(a.tooltext, y)));
                x = g.showTooltip ? h(D, c ? "$label" : M) : !1;
                return {
                    _options: a,
                    id: h(a.id, b).toString(),
                    from: h(a.from, B),
                    to: h(a.to, B),
                    label: c,
                    toolText: x,
                    customToolText: D,
                    color: d,
                    dashStyle: Boolean(f(a.dashed, n)) ? ua(f(a.dashlen, k), f(a.dashgap, p), m) : "none",
                    arrowAtStart: Boolean(f(a.arrowatstart, t)),
                    arrowAtEnd: Boolean(f(a.arrowatend, u)),
                    conStrength: f(a.strength, E),
                    connectorLink: z(a.link),
                    stdThickness: m,
                    labelWidth: e.widht,
                    labelHeight: e.height
                }
            };
            for (a = 0; a < e; a += 1)c.push(D(b[a],
                a));
            return d
        },
        series: function (b, d, a) {
            var c = d[ja], e = [], g, q, m, v;
            d.legend.enabled = Boolean(f(b.chart.showlegend, 1));
            if (b.dataset && 0 < (q = b.dataset.length)) {
                this.categoryAdder(b, d);
                c.x.requiredAutoNumericLabels = !1;
                if (b.connectors && (g = b.connectors.length))for (v = 0, m = g; v < m; v += 1)g = {connector: []}, e.push(this.connector(a, g, b.connectors[v], b.chart, d, c.oriCatTmp.length, v)); else g = {connector: []}, e.push(this.connector(a, g, {}, b.chart, d, c.oriCatTmp.length, v));
                for (v = 0; v < q; v += 1)g = {
                    hoverEffects: this.parseSeriesHoverOptions(b,
                        d, b.dataset[v], a), data: []
                }, g = this.point(a, g, b.dataset[v], b.chart, d, c.oriCatTmp.length, v), g instanceof Array ? d.series = d.series.concat(g) : d.series.push(g);
                d.connectors = e;
                b.labels && b.labels.label && 0 < b.labels.label.length && (d.dragableLabels = b.labels.label);
                b.chart.showyaxisvalue = h(b.chart.showyaxisvalue, 0);
                this.configureAxis(d, b);
                b.trendlines && Sb(b.trendlines, d.yAxis, c, !1, this.isBar)
            }
        }
    }, r.scatterbase);
    nb = function (b, d, a, c, e, g) {
        var q = g.logic, m = g.options.chart, v, l, p = d[b.from], k = d[b.to], n = {sourceType: "connector"},
            t = b && b._options, u = q.numberFormatter, E, D, x, y, M;
        this.renderer = c;
        this.connectorsGroup = e;
        this.pointStore = d;
        this.options = b;
        this.style = a || {};
        p && k && (this.fromPointObj = p, this.toPointObj = k, this.fromX = E = p._xPos, this.fromY = D = p._yPos, this.toX = x = k._xPos, this.toY = y = k._yPos, this.arrowAtStart = n.arrowAtStart = b.arrowAtStart, this.arrowAtEnd = n.arrowAtEnd = b.arrowAtEnd, this.strokeWidth = d = b.conStrength * b.stdThickness, this.textBgColor = l = (this.color = v = b.color) && v.FCcolor && v.FCcolor.color, this.label = n.label = M = b.label, u = db(b.toolText,
            [3, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92], {
                label: b.label,
                fromXValue: u.dataLabels(p.x),
                fromYValue: u.dataLabels(p.y),
                fromXDataValue: p.x,
                fromYDataValue: p.y,
                fromLabel: h(p.displayValue, p.id),
                toXValue: u.dataLabels(k.x),
                toYValue: u.dataLabels(k.y),
                toXDataValue: k.x,
                toYDataValue: k.y,
                toLabel: h(k.displayValue, k.id)
            }), this.link = n.link = t && t.link, n.id = b.id, n.fromNodeId = p.id, n.toNodeId = k.id, p._config && p._config.startConnectors && p._config.startConnectors.push(this), k._config && k._config.endConnectors && k._config.endConnectors.push(this),
            p = function () {
                var a = this, c = b._options || {};
                a._longpressactive = clearTimeout(a._longpressactive);
                a.data("fire_click_event", 1);
                a._longpressactive = setTimeout(function () {
                    a.data("fire_click_event", 0);
                    a.data("viewMode") || q.showConnectorUpdateUI(g, {
                        fromid: {val: c.from, innerHTML: "<option>" + c.from + "</option>", disabled: !0},
                        toid: {val: c.to, innerHTML: "<option>" + c.to + "</option>", disabled: !0},
                        arratstart: {val: Boolean(f(c.arrowatstart, 1))},
                        arratend: {val: Boolean(f(c.arrowatend, 1))},
                        dashed: {val: f(c.dashed)},
                        dashgap: {val: c.dashgap},
                        dashlen: {val: c.dashlen},
                        label: {val: c.label},
                        tooltext: {val: c.tooltext},
                        id: {val: b.id, disabled: !0},
                        strength: {val: c.strength},
                        alpha: {val: c.alpha},
                        color: {val: c.color}
                    }, !0)
                }, 1E3)
            }, this.graphic = c.path(this.getlinePath(), e).attr({
            "stroke-width": d,
            ishot: !0,
            "stroke-dasharray": b.dashStyle,
            cursor: this.link ? "pointer" : "",
            stroke: pa(v)
        }).mousedown(p).mousemove(function () {
            this.data("fire_click_event", 0);
            la.call(this)
        }).mouseup(function (a) {
            la.call(this);
            Ca.call(this, g, a, "ConnectorClick")
        }).hover(function (a) {
            Ca.call(this,
                g, a, "ConnectorRollover")
        }, function (a) {
            Ca.call(this, g, a, "ConnectorRollout")
        }).tooltip(u).data("eventArgs", n).data("viewMode", m.viewMode), M && (this.text = c.text(), e.appendChild(this.text), this.text.css(a.style).attr({
            text: M,
            x: (E + x) / 2,
            y: (D + y) / 2,
            fill: a.color,
            ishot: !0,
            direction: m.textDirection,
            cursor: this.link ? "pointer" : "",
            "text-bound": [h(a.backgroundColor, l), h(a.borderColor, l), 1, "2"]
        }).tooltip(u).mousedown(p).mousemove(function () {
            this.data("fire_click_event", 0);
            la.call(this)
        }).hover(function (a) {
            Ca.call(this,
                g, a, "ConnectorRollover")
        }, function (a) {
            Ca.call(this, g, a, "ConnectorRollout")
        }).mouseup(function (a) {
            la.call(this);
            Ca.call(this, g, a, "ConnectorClick")
        }).tooltip(u).data("eventArgs", n).data("viewMode", g.options.chart.viewMode)))
    };
    nb.prototype = {
        updateFromPos: function (b, d) {
            this.fromX = b;
            this.fromY = d;
            this.graphic && this.graphic.animate({path: this.getlinePath()});
            this.text && this.text.animate({x: (this.fromX + this.toX) / 2, y: (this.fromY + this.toY) / 2})
        }, updateToPos: function (b, d) {
            this.toX = b;
            this.toY = d;
            this.graphic &&
            this.graphic.animate({path: this.getlinePath()});
            this.text && this.text.animate({x: (this.fromX + this.toX) / 2, y: (this.fromY + this.toY) / 2})
        }, getlinePath: function () {
            var b = this.fromPointObj, d = this.toPointObj, a = this.fromX, c = this.fromY, e = this.toX, g = this.toY, q = ["M", a, c];
            this.arrowAtStart && (b = b._config, q = b.shapeType === ic ? q.concat(zb(a, c, e, g, b.shapeArg.width, b.shapeArg.height)) : q.concat(zb(a, c, e, g, b.shapeArg.radius)));
            this.arrowAtEnd && (b = d._config, q = b.shapeType === ic ? q.concat(zb(e, g, a, c, b.shapeArg.width, b.shapeArg.height)) :
                q.concat(zb(e, g, a, c, b.shapeArg.radius)));
            q.push("L", e, g);
            return q
        }
    };
    nb.prototype.constructor = nb;
    ra = {
        mouseDown: function (b) {
            delete b.data.point.dragActive
        }, click: function (b) {
            return !b.data.point.dragActive
        }, dragHandler: function (b) {
            var d = b.data, a = b.type, c = d.point, e = d.series, g = e.chart || e, q = g.tooltip, f = ia && Pa(b) || mb, g = g.options.instanceAPI;
            switch (a) {
                case "dragstart":
                    q.block(!0);
                    d.dragStartY = b.pageY || f.pageY || 0;
                    d.dragStartX = b.pageX || f.pageX || 0;
                    d.startValue = c.y;
                    d.startXValue = c.x;
                    c.dragActive = !0;
                    e.dragStartHandler &&
                    e.dragStartHandler(d);
                    break;
                case "dragend":
                    q.block(!1);
                    e.repositionItems(d, d.changeX ? (b.pageX || f.pageX || 0) - d.dragStartX : 0, d.changeY ? (b.pageY || f.pageY || 0) - d.dragStartY : 0, !0);
                    a = {
                        dataIndex: c.index + 1,
                        datasetIndex: e.index + 1,
                        startValue: d.startValue,
                        endValue: c.y,
                        datasetName: e.name
                    };
                    b = [g.chartInstance.id, a.dataIndex, a.datasetIndex, a.datasetName, a.startValue, a.endValue];
                    d.changeX && (a.startYValue = d.startValue, a.endYValue = c.y, a.startXValue = d.startXValue, a.endXValue = c.x, b.push(d.startXValue, c.x), delete a.startValue,
                        delete a.endValue);
                    w.raiseEvent("chartupdated", a, g.chartInstance, b);
                    delete d.dragStartY;
                    delete d.dragStartX;
                    delete d.startValue;
                    delete d.startXValue;
                    break;
                default:
                    e.repositionItems(d, d.changeX ? (b.pageX || f.pageX || 0) - d.dragStartX : 0, d.changeY ? (b.pageY || f.pageY || 0) - d.dragStartY : 0)
            }
        }, dragLabelHandler: function (b) {
            var d = b.data, a = b.type, c = d.element, e = d.tracker, g = d.toolTip, q = ia && Pa(b) || mb, f = d.series, h, l, p;
            "dragstart" === a ? (g.block(!0), d.dragStartY = b.pageY || q.pageY || 0, d.dragStartX = b.pageX || q.pageX || 0) : (h = d.x +
                (b.pageX || q.pageX || 0) - d.dragStartX, p = h - d.leftDistance, p + d.width > d.plotWidth && (p = d.plotWidth - d.width), 0 > p && (p = 0), h = p + d.leftDistance, l = d.y + (b.pageY || q.pageY || 0) - d.dragStartY, b = l - d.topDistance, b + d.height > d.plotHeight && (b = d.plotHeight - d.height), 0 > b && (b = 0), l = b + d.topDistance, "dragend" === a ? (g.block(!1), d.x = h, d.y = l, delete d.dragStartY, delete d.dragStartX) : (c.attr({
                x: h,
                y: l
            }).textBound(), e.attr({x: p, y: b})));
            "dragend" == a && (a = {hcJSON: {dragableLabels: []}}, a.hcJSON.dragableLabels[d.index] = {
                y: f.yAxis.translate(f.chart.plotHeight -
                    l + d.yAdjustment, 1), x: f.xAxis.translate(h, 1)
            }, I(f.chart.options.instanceAPI.chartInstance.jsVars._reflowData, a, !0))
        }, pointUpdate: function (b, d, a) {
            b._isUserTooltip || b.toolText === B || b._getTooltext ? b._getTooltext && (b.toolText = b._getTooltext(void 0, void 0, {formattedValue: d}, {value: a})) : b.toolText = b._toolTextStr + d;
            b._isUserValue || b.displayValue === B || (b.displayValue = d)
        }, snapPoint: function (b, d, a) {
            var c = b.options.chart, e = c.snapToDiv, g = c.snapToDivOnly;
            b = b._yAxisPlotLines;
            for (var q = sa(b[1] - b[0]), c = g ? .5 * q : c.snapToDivRelaxation,
                     q = d.lastSnap, f = 1, h = b.length, l; h--;)if (l = sa(b[h] - a), e && l < c) {
                q !== h && (d.lastSnap = g ? void 0 : h, a = b[h]);
                f = 0;
                break
            }
            f && (d.lastSnap = void 0);
            return a
        }, setMinMaxValue: function (b) {
            var d = b.options.series;
            b = b.logic;
            var a = 0, c = Infinity, e = -Infinity, g = b.chartInstance.jsVars._reflowData, q, f, h, l, p;
            p = 0;
            for (q = d.length; p < q; p += 1)for (h = d[p] && d[p].data, a = 0, l = h.length; a < l; a += 1)f = h[a].y, null !== f && (e = e > f ? e : f, c = c < f ? c : f);
            b.highValue = e;
            b.lowValue = c;
            g.postHCJSONCreation = function () {
                var a = this.hcJSON[ja][0];
                a.min = c;
                a.max = e
            }
        }, setSelectBoxValues: function (b,
                                         d) {
            var a = d.xAxis[0], c = d.yAxis[0], e = d.plotHeight;
            b.startX = a.translate(b.left, 1);
            b.endX = a.translate(b.left + b.width, 1);
            b.startY = c.translate(e - b.top, 1);
            b.endY = c.translate(e - (b.top + b.height), 1)
        }
    };
    r("dragarea", cb({
        friendlyName: "Dragable Area Chart",
        standaloneInit: !0,
        creditLabel: !1,
        rendererId: "dragarea",
        defaultSeriesType: "area",
        decimals: 2,
        anchorAlpha: "100",
        eiMethods: r.msareabase.eiMethods
    }, Ia), r.msareabase);
    r("dragline", cb({
        friendlyName: "Dragable Line Chart",
        standaloneInit: !0,
        creditLabel: !1,
        decimals: 2,
        defaultSeriesType: "line",
        rendererId: "dragline",
        eiMethods: r.mslinebase.eiMethods
    }, Ia), r.mslinebase);
    r("dragcolumn2d", cb({
        friendlyName: "Dragable Column Chart",
        standaloneInit: !0,
        creditLabel: !1,
        decimals: 2,
        defaultSeriesType: "column",
        rendererId: "dragcolumn2d",
        eiMethods: r.mscolumn2dbase.eiMethods
    }, Ia), r.mscolumn2dbase);
    r("selectscatter", {
        friendlyName: "Dragable Scatter Chart",
        isXY: !0,
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "scatter",
        defaultZeroPlaneHighlighted: !1,
        spaceManager: Ia.spaceManager,
        drawButtons: Ia.drawButtons,
        updateChartWithData: Ia.updateChartWithData,
        eiMethods: cb(cb(cb({}, r.scatterbase.eiMethods), Ia.eiMethods), {
            getData: function (b) {
                var d = this.jsVars.instanceAPI, a = d.getCollatedData(), c = [], e = a.dataset, g = e && e.length || 0, f = 0, m = 0;
                if (b)c = /^json$/ig.test(b) ? a : /^csv$/ig.test(b) ? d.getCSVString() : O.core.transcodeData(a, "json", b); else for (; f < g; f += 1)if (d = e[f]) {
                    for ((a = b = (d = e[f] && e[f].data) && d.length || 0) && (c[m] || (c[m] = [z(e[f].id, "null")])); a--;)c[m][a + 1] = z(d[a].id, "null");
                    b && (m += 1)
                }
                return c
            }
        }),
        getCSVString: function () {
            for (var b =
                this.chartInstance.getData(), d = b.length; d--;)b[d] = b[d].join(",");
            return b.join("|")
        },
        getCollatedData: function () {
            for (var b = this.chartInstance, d = b.jsVars.hcObj._selectEleArr, a = d && d.length, b = I({}, b.getChartData(O.dataFormats.JSON)), c = b.dataset, e, g, f, m, h, l, p, k, n, t = []; a--;)if (e = d[a])for (l = e.startX, p = e.endX, k = e.startY, n = e.endY, m = c.length; m--;)for (t[m] || (t[m] = {data: []}), h = (f = c[m].data) && f.length; h--;)g = f[h], e = g.x, g = g.y, e > l && e < p && g < k && g > n && (t[m].data[h] = !0);
            for (m = c.length; m--;)for (h = (f = c[m].data) && f.length; h--;)t[m] &&
            t[m].data[h] || f.splice(h, 1);
            return this.updatedDataObj = b
        },
        createSelectionBox: function (b) {
            var d = b.chart, a = d.paper, c = d.options.chart, e = d.yAxis && d.yAxis[0], g = d.xAxis && d.xAxis[0], f = b.selectionLeft, m = b.selectionTop, h = b.selectionWidth;
            b = b.selectionHeight;
            var l = f + h, p = m + b, k = 15 < h && 15 < b, n = {
                resizeEleRadius: 15,
                canvasTop: d.canvasTop,
                canvasRight: d.canvasLeft + d.canvasWidth,
                canvasLeft: d.canvasLeft,
                canvasBottom: d.canvasTop + d.canvasHeight
            }, t = d.layers.tracker, u = d._selectEleArr || (d._selectEleArr = []);
            n.index = u.length;
            n.id = "SELECT_" + n.index;
            n.selectBoxG = t = a.group("selection-box", t).toFront();
            n.selectBoxTracker = a.rect(f, m, h, b, t).attr({
                "stroke-width": 1,
                stroke: pa(c.selectBorderColor),
                ishot: !0,
                fill: c.selectFillColor
            }).css({cursor: "move"});
            n.selectBoxTracker.data("config", {position: 6, selectEleObj: n, xChange: !0, yChange: !0});
            n.topTracker = a.rect(f, m - 6, h, 12, t).attr({
                "stroke-width": 0,
                ishot: !0,
                fill: Aa
            }).css("cursor", Ta && "ns-resize" || "n-resize");
            n.topTracker.data("config", {position: 1, selectEleObj: n, yChange: !0});
            n.rightTracker =
                a.rect(f + h - 6, m, 12, b, t).attr({
                    "stroke-width": 0,
                    ishot: !0,
                    fill: Aa
                }).css("cursor", Ta && "ew-resize" || "w-resize");
            n.rightTracker.data("config", {position: 2, selectEleObj: n, xChange: !0});
            n.bottomTracker = a.rect(f, m + b - 6, h, 12, t).attr({
                "stroke-width": 0,
                ishot: !0,
                fill: Aa
            }).css("cursor", Ta && "ns-resize" || "n-resize");
            n.bottomTracker.data("config", {position: 3, selectEleObj: n, yChange: !0});
            n.leftTracker = a.rect(f - 6, m, 12, b, t).attr({
                "stroke-width": 0,
                ishot: !0,
                fill: Aa
            }).css("cursor", Ta && "ew-resize" || "e-resize");
            n.leftTracker.data("config",
                {position: 4, selectEleObj: n, xChange: !0});
            n.cornerInnerSymbol = a.symbol("resizeIcon", 0, 0, 15, t).attr({
                transform: "t" + l + "," + p,
                "stroke-width": 1,
                visibility: k ? Bb : "hidden",
                ishot: !0,
                stroke: "#999999"
            });
            n.cornerOuterSymbol = a.symbol("resizeIcon", 0, 0, -12, t).attr({
                transform: "t" + l + "," + p,
                strokeWidth: 1,
                visibility: k ? "hidden" : Bb,
                ishot: !0,
                stroke: "#777777"
            });
            n.resizeTracker = a.circle(l, p, 12, t).attr({
                "stroke-width": 1,
                stroke: Aa,
                ishot: !0,
                fill: Aa
            }).css("cursor", Ta && "nwse-resize" || "nw-resize");
            n.resizeTracker.data("config", {
                position: 5,
                selectEleObj: n, yChange: !0, xChange: !0
            });
            n.closeButton = a.symbol("closeIcon", 0, 0, 6, t).attr({
                transform: "t" + l + "," + m,
                "stroke-width": 2,
                stroke: c.selectionCancelButtonBorderColor,
                fill: c.selectionCancelButtonFillColor,
                "stroke-linecap": "round",
                ishot: !0,
                "stroke-linejoin": "round"
            }).css({cursor: "pointer", _cursor: "hand"}).click(function () {
                d.logic.deleteSelection(this, d)
            });
            n.closeButton.data("config", {index: n.index});
            n.chart = d;
            n.startX = g.getAxisPosition(f, 1);
            n.startY = e.getAxisPosition(m, 1);
            n.endX = g.getAxisPosition(l,
                1);
            n.endY = e.getAxisPosition(p, 1);
            n.isVisible = !0;
            u.push(n);
            d.logic.bindDragEvent(n)
        },
        deleteSelection: function (b, d) {
            var a = b.data("config").index, c = d._selectEleArr, e = c[a], g, f, m;
            g = e.selectBoxTracker.getBBox();
            m = {
                selectionLeft: g.x,
                selectionTop: g.y,
                selectionWidth: g.width,
                selectionHeight: g.height,
                startXValue: d.xAxis[0].getAxisPosition(g.x, 1),
                startYValue: d.yAxis[0].getAxisPosition(g.y, 1),
                endXValue: d.xAxis[0].getAxisPosition(g.x + g.width, 1),
                endYValue: d.yAxis[0].getAxisPosition(g.y + g.height, 1),
                data: d.logic.getCollatedData(),
                id: e.id
            };
            for (f in e)g = e[f], g.remove && g.remove(), delete e[f];
            delete c[a];
            O.raiseEvent("selectionRemoved", m, d.logic.chartInstance)
        },
        bindDragEvent: function (b) {
            for (var d in b)/Tracker/.test(d) && b[d].drag(this.move, this.start, this.up)
        },
        start: function () {
            var b = this.data("config").selectEleObj, d = b.topTracker, a = b.rightTracker, c = b.bottomTracker, e = b.leftTracker, g = b.resizeTracker, f = d.data("config"), m = a.data("config"), h = c.data("config"), l = e.data("config"), p = g.data("config"), k = b.selectBoxTracker.data("config"),
                n = b.selectBoxTracker.getBBox();
            f.ox = n.x;
            f.oy = n.y;
            m.ox = n.x2;
            m.oy = n.y;
            h.ox = n.x;
            h.oy = n.y2;
            l.ox = n.x;
            l.oy = n.y;
            f.ox = n.x;
            f.oy = n.y;
            p.ox = n.x2;
            p.oy = n.y2;
            k.ox = n.x;
            k.oy = n.y;
            k.ow = n.width;
            k.oh = n.height;
            k.ox2 = n.x2;
            k.oy2 = n.y2;
            b.selectBoxG.toFront();
            d.hide();
            a.hide();
            c.hide();
            e.hide();
            g.hide();
            this.show()
        },
        move: function (b, d) {
            var a = this.data("config"), c = a.selectEleObj, e = c.chart, g = c.topTracker, f = c.rightTracker, m = c.bottomTracker, h = c.leftTracker, l = c.resizeTracker, p = c.selectBoxTracker, k = c.canvasLeft, n = c.canvasRight, t =
                c.canvasTop, u = c.canvasBottom, E = p.data("config"), D = {}, x, y;
            b = a.xChange ? b : 0;
            d = a.yChange ? d : 0;
            x = b + a.ox;
            y = d + a.oy;
            x = H(n - (a.ow || 0), ab(x, k));
            y = H(u - (a.oh || 0), ab(y, t));
            switch (a.position) {
                case 1:
                    D.y = H(E.oy2, y);
                    D.height = sa(E.oy2 - y) || 1;
                    g.attr({y: y + -6});
                    break;
                case 2:
                    D.x = H(E.ox, x);
                    D.width = sa(E.ox - x) || 1;
                    f.attr({x: x + -6});
                    break;
                case 3:
                    D.y = H(E.oy, y);
                    D.height = sa(E.oy - y) || 1;
                    m.attr({y: y + -6});
                    break;
                case 4:
                    D.x = H(E.ox2, x);
                    D.width = sa(E.ox2 - x) || 1;
                    h.attr({x: x + -6});
                    break;
                case 5:
                    D.x = H(E.ox, x);
                    D.width = sa(E.ox - x) || 1;
                    D.y = H(E.oy, y);
                    D.height = sa(E.oy - y) || 1;
                    l.attr({cx: x, cy: y});
                    break;
                default:
                    D.x = x, D.y = y
            }
            this.data("dragStarted") || (a = p.getBBox(), a = {
                selectionLeft: a.x,
                selectionTop: a.y,
                selectionWidth: a.width,
                selectionHeight: a.height,
                startXValue: e.xAxis[0].getAxisPosition(a.x, 1),
                startYValue: e.yAxis[0].getAxisPosition(a.y, 1),
                endXValue: e.xAxis[0].getAxisPosition(a.x + a.width, 1),
                endYValue: e.yAxis[0].getAxisPosition(a.y + a.height, 1),
                id: c.id
            }, O.raiseEvent("BeforeSelectionUpdate", a, e.logic.chartInstance), this.data("dragStarted", 1));
            p.animate(D);
            c.isVisible && (c.closeButton.hide(), c.cornerInnerSymbol.hide(), c.cornerOuterSymbol.hide(), c.isVisible = !1)
        },
        up: function () {
            var b = this, d = b.data("config").selectEleObj, a = d.chart, c = a.xAxis && a.xAxis[0], e = a.yAxis && a.yAxis[0], g = d.topTracker, f = d.rightTracker, m = d.bottomTracker, h = d.leftTracker, l = d.resizeTracker, p = d.selectBoxTracker, k, n;
            setTimeout(function () {
                k = p.getBBox();
                d.startX = c.getAxisPosition(k.x, 1);
                d.startY = e.getAxisPosition(k.y, 1);
                d.endX = c.getAxisPosition(k.x2, 1);
                d.endY = e.getAxisPosition(k.y2, 1);
                g.attr({
                    x: k.x,
                    y: k.y + -6, width: k.width
                });
                f.attr({x: k.x2 + -6, y: k.y, height: k.height});
                m.attr({x: k.x, y: k.y2 + -6, width: k.width});
                h.attr({x: k.x + -6, y: k.y, height: k.height});
                l.attr({cx: k.x2, cy: k.y2});
                d.closeButton.transform("t" + k.x2 + "," + k.y);
                d.cornerInnerSymbol.transform("t" + k.x2 + "," + k.y2);
                d.cornerOuterSymbol.transform("t" + k.x2 + "," + k.y2);
                d.closeButton.show();
                15 > k.width || 15 > k.height ? (d.cornerInnerSymbol.hide(), d.cornerOuterSymbol.show()) : (d.cornerInnerSymbol.show(), d.cornerOuterSymbol.hide());
                d.isVisible = !0;
                g.show();
                f.show();
                m.show();
                h.show();
                l.show();
                b.data("dragStarted") && (n = {
                    selectionLeft: k.x,
                    selectionTop: k.y,
                    selectionWidth: k.width,
                    selectionHeight: k.height,
                    startXValue: a.xAxis[0].getAxisPosition(k.x, 1),
                    startYValue: a.yAxis[0].getAxisPosition(k.y, 1),
                    endXValue: a.xAxis[0].getAxisPosition(k.x + k.width, 1),
                    endYValue: a.yAxis[0].getAxisPosition(k.y + k.height, 1),
                    data: a.logic.getCollatedData(),
                    id: d.id
                }, O.raiseEvent("SelectionUpdated", n, a.logic.chartInstance), b.data("dragStarted", 0))
            }, 100)
        },
        postSeriesAddition: function (b, d) {
            var a =
                r.scatter && r.scatter.postSeriesAddition && r.scatter.postSeriesAddition.apply(this, arguments), c = b.chart, e = d.chart, g = this.colorManager, q = h(e.selectbordercolor, g.getColor("canvasBorderColor")), m = f(e.selectborderalpha, g.getColor("canvasBorderAlpha"));
            c.selectBorderColor = {FCcolor: {color: q, alpha: m}};
            c.selectFillColor = A(h(e.selectfillcolor, g.getColor("altHGridColor")), f(e.selectfillalpha, g.getColor("altHGridAlpha")));
            c.selectionCancelButtonBorderColor = A(h(e.selectioncancelbuttonbordercolor, q), f(e.selectioncancelbuttonborderalpha,
                m));
            c.selectionCancelButtonFillColor = A(h(e.selectioncancelbuttonfillcolor, "FFFFFF"), f(e.selectioncancelbuttonfillalpha, 100));
            b.chart.nativeZoom = !1;
            c.formAction = z(e.formaction);
            "0" !== e.submitdataasxml || e.formdataformat || (e.formdataformat = O.dataFormats.CSV);
            c.formDataFormat = h(e.formdataformat, O.dataFormats.XML);
            c.formTarget = h(e.formtarget, "_self");
            c.formMethod = h(e.formmethod, "POST");
            c.submitFormAsAjax = f(e.submitformusingajax, 1);
            (b.callbacks || (b.callbacks = [])).push(function () {
                var a = this.logic;
                Qa(this,
                    {
                        selectionStart: function (a) {
                            var b = Mb(a.chart.container, a.originalEvent), b = I({
                                selectionLeft: a.selectionLeft,
                                selectionTop: a.selectionTop,
                                selectionWidth: a.selectionWidth,
                                selectionHeight: a.selectionHeight,
                                startXValue: a.chart.xAxis[0].getAxisPosition(a.selectionLeft, 1),
                                startYValue: a.chart.yAxis[0].getAxisPosition(a.selectionTop, 1)
                            }, b);
                            O.raiseEvent("selectionStart", b, a.chart.logic.chartInstance)
                        }, selectionEnd: function (b) {
                        var c = Mb(b.chart.container, b.originalEvent), d = b.chart.xAxis[0], e = b.chart.yAxis[0],
                            c = I({
                                selectionLeft: b.selectionLeft,
                                selectionTop: b.selectionTop,
                                selectionWidth: b.selectionWidth,
                                selectionHeight: b.selectionHeight,
                                startXValue: d.getAxisPosition(b.selectionLeft, 1),
                                startYValue: e.getAxisPosition(b.selectionTop, 1),
                                endXValue: d.getAxisPosition(b.selectionLeft + b.selectionWidth, 1),
                                endYValue: e.getAxisPosition(b.selectionTop + b.selectionHeight, 1)
                            }, c);
                        O.raiseEvent("selectionEnd", c, b.chart.logic.chartInstance);
                        a.createSelectionBox(b)
                    }
                    })
            });
            b.chart.zoomType = "xy";
            return a
        }
    }, r.scatterbase);
    r("multiaxisline",
        {
            friendlyName: "Multi-axis Line Chart",
            standaloneInit: !0,
            creditLabel: !1,
            defaultSeriesType: "line",
            rendererId: "multiaxisline",
            isMLAxis: !0,
            canvasPaddingModifiers: ["anchor", "anchorlabel"],
            drawAxisTrackerAndCheckBox: function () {
                for (var b = this, d = b.canvasLeft, a = b.canvasTop, c = b.canvasWidth, e = b.canvasHeight, g = b.paper, q = b.yAxis, m = q.length, h = b.logic, l = 0, p = 0, k = {
                        cursor: "col-resize",
                        _cursor: "e-resize",
                        "*cursor": "e-resize"
                    }, n = h.chartInstance, t = n.jsVars, u = h.dataObj, E = t._reflowData, D = E.hcJSON || {}, x = u.axis, y = u.chart,
                         u = f(y.allowaxisshift, 1), M = (y = f(y.allowselection, 1)) && g.html("div", {
                            fill: "transparent",
                            width: b.chartWidth
                        }, {
                            top: "",
                            left: "",
                            fontSize: "10px",
                            lineHeight: "15px",
                            marginTop: -b.chartHeight + "px"
                        }, b.container), D = D.yAxis || (D.yAxis = []), ga, wa, B, F, P, r, w = function (a) {
                        b.series && b.series[a] && b.series[a].setVisible(!1, !1)
                    }, na = function (a) {
                        var c = a.data;
                        a = c.axis[c.index].axisData;
                        var d = a._relatedSeries, e = !c.checkBox.checked(), c = x[a._axisposition];
                        d && ib(d, function (a) {
                            b.options.series[a].legendClick(e, !0)
                        });
                        c.hidedataplots = !e;
                        I(E, {
                            preReflowAdjustments: function () {
                                this.dataObj.axis = x
                            }
                        });
                        O.raiseEvent("AxisSelected", {
                            selected: e,
                            AxisId: c._index,
                            AxisConfiguration: a._origAttr || gb(c, a)
                        }, b.logic.chartInstance)
                    }, C = function (a) {
                        var c = a.data;
                        a = c.axis;
                        var c = a[c.index].axisData, d = c.opposite, e = c._axisposition, g = x.length, k, q, m, l = {}, p = x[e], u = {};
                        for (k = 0; k < g; k += 1)q = x[k], q = !f(q.axisonleft, 1), q === d && (m = k, d && (k = g));
                        m !== e && (l = a[m], u = x[m], a = x.splice(m, 1, x[e]), x.splice(e, 1, a[0]));
                        if (m !== e || d !== h.dataObj.chart._lastClickedOpp)I(E, {
                            preReflowAdjustments: function () {
                                this.dataObj.chart._lastClickedOpp =
                                    d;
                                this.dataObj.axis = x
                            }
                        }), O.raiseEvent("AxisShifted", {
                            previousDefaultAxisId: u._index,
                            newDefaultAxisId: p._index,
                            previousDefaultAxisConfiguration: l._origAttr || gb(u, l),
                            newDefaultAxisConfiguration: c._origAttr || gb(p, c)
                        }, b.logic.chartInstance), O.hcLib.createChart(n, t.container, t.type, void 0, void 0, !1, !0)
                    }; m--;)ga = q[m], wa = ga.axisData, B = wa._axisWidth, (F = wa.opposite) || (l += B), D[m] || (D[m] = {}), y && wa.showAxis && (P = d + (F ? c + p + f(wa.title.margin, B - 10) + 5 : -l), r = a + e + 10, ga.checkBox = g.html("input", {}, {
                    left: P + "px",
                    top: r + "px"
                }).attr({
                    type: "checkbox",
                    name: "axis[]", value: wa.title.text || ""
                }).add(M), ga.checkBox.val(wa.hidedataplots), wa.hidedataplots || wa._relatedSeries && ib(wa._relatedSeries, w), Ab(ga.checkBox.element, ia ? "touchstart" : "mousedown", na, {
                    axis: q,
                    index: m,
                    checkBox: ga.checkBox
                })), u && (ga.tracker = g.rect(d + (F ? c + p : -l), a, B, e, 0).attr({
                    "stroke-width": 0,
                    fill: Aa,
                    isTracker: +new Date,
                    zIndex: 7
                }).css(k), F && (p += B), Ab(ga.tracker[0], ia ? "touchstart" : "mousedown", C, {axis: q, index: m}))
            },
            series: function (b) {
                var d = this, a = d.numberFormatter, c = d.name, e = d.dataObj, g = e.chart,
                    q = e.axis, m = d.hcJSON, v = m[ja], l = m.yAxis[0], p = f(e.chart.allowselection, 1), k = [], n = f(g.showaxisnamesinlegend, 0), t = f(g.yaxisvaluesstep, g.yaxisvaluestep, 1), u = this.colorManager, E, D, x, y, M, ga, wa, r, F, P, Q, w, na, C, z;
                m.callbacks || (m.callbacks = []);
                m.callbacks.push(function () {
                    d.drawAxisTrackerAndCheckBox.call(this)
                });
                m.legend.enabled = Boolean(f(e.chart.showlegend, 1));
                if (q && 0 < q.length) {
                    this.categoryAdder(e, m);
                    m.yAxis.splice(0, 2);
                    ga = v.noHiddenAxis = 0;
                    for (r = q.length; ga < r; ga += 1)F = q[ga], void 0 === F._index && (F._index = ga),
                        F._axisposition = ga, (y = !f(F.axisonleft, 1)) ? (F._isSY = !0, k.unshift(F)) : (F._isSY = !1, k.push(F));
                    ga = 0;
                    for (r = k.length; ga < r; ga += 1)if (F = k[ga], M = f(F.showaxis, 1), q = F._index || 0, a.parseMLAxisConf(F, q), E = u.getPlotColor(q), F.id = q, na = h(F.color, g.axiscolor, E), P = A(na, 100), y = !f(F.axisonleft, 1), Q = f(F.divlinethickness, g.divlinethickness, 1), D = M ? f(F.tickwidth, g.axistickwidth, 2) : 0, x = M ? f(F.axislinethickness, g.axislinethickness, 2) : 0, w = v[ga] = {}, w.showAxis = M, v.noHiddenAxis += 1 - M, M && (y ? z = ga : C = ga), wa = [], m.yAxis.push({
                            startOnTick: !1,
                            endOnTick: !1,
                            _axisposition: F._axisposition,
                            _isSY: F._isSY,
                            _index: q,
                            hidedataplots: !f(F.hidedataplots, 0),
                            title: {
                                enabled: M,
                                style: l.title.style,
                                text: M ? G(F.title) : B,
                                align: p ? "low" : "middle",
                                textAlign: p && y ? "right" : void 0
                            },
                            labels: {x: 0, style: l.labels.style},
                            plotBands: [],
                            plotLines: [],
                            gridLineColor: A(h(F.divlinecolor, na), f(F.divlinealpha, g.divlinealpha, u.getColor("divLineAlpha"), 100)),
                            gridLineWidth: Q,
                            gridLineDashStyle: f(F.divlinedashed, F.divlineisdashed, g.divlinedashed, g.divlineisdashed, 0) ? ua(f(F.divlinedashlen,
                                g.divlinedashlen, 4), f(F.divlinedashgap, g.divlinedashgap, 2), Q) : "none",
                            alternateGridColor: da,
                            lineColor: P,
                            lineWidth: x,
                            tickLength: D,
                            tickColor: P,
                            tickWidth: x,
                            opposite: y,
                            _relatedSeries: wa,
                            showAxis: M
                        }), w.yAxisValuesStep = f(F.yaxisvaluesstep, F.yaxisvaluestep, t), w.maxValue = F.maxvalue, w.tickWidth = D, w.minValue = F.minvalue, w.setadaptiveymin = f(F.setadaptiveymin, g.setadaptiveymin), w.numDivLines = f(F.numdivlines, g.numdivlines, 4), w.adjustdiv = f(F.adjustdiv, g.adjustdiv), w.showYAxisValues = M ? f(F.showyaxisvalues, F.showyaxisvalue,
                            g.showyaxisvalues, g.showyaxisvalue, 1) : 0, w.showLimits = M ? f(F.showlimits, g.showyaxislimits, g.showlimits, w.showYAxisValues) : 0, w.showDivLineValues = M ? f(F.showdivlinevalue, g.showdivlinevalues, F.showdivlinevalues, w.showYAxisValues) : 0, w.showzeroplane = F.showzeroplane, w.showzeroplanevalue = f(F.showzeroplanevalue), w.zeroplanecolor = F.zeroplanecolor, w.zeroplanethickness = F.zeroplanethickness, w.zeroplanealpha = F.zeroplanealpha, w.linecolor = h(F.linecolor, g.linecolor || F.color, E), w.linealpha = F.linealpha, w.linedashed = F.linedashed,
                            w.linethickness = F.linethickness, w.linedashlen = F.linedashlen, w.linedashgap = F.linedashgap, w.anchorShadow = F.anchorshadow, w.plottooltext = F.plottooltext, F.dataset && 0 < F.dataset.length) {
                        Q = F.dataset.length;
                        E = f(F.includeinlegend, 1);
                        y = !1;
                        M = {
                            data: [],
                            relatedSeries: wa,
                            name: G(F.title),
                            type: "line",
                            marker: {symbol: "axisIcon", fillColor: Aa, lineColor: uc(na, 80).replace(hb, Oa)},
                            lineWidth: 0,
                            legendFillColor: 0 !== n ? A(na, 25) : void 0,
                            legendFillOpacity: 0,
                            legendIndex: F._index,
                            showInLegend: Boolean(f(n, E))
                        };
                        m.series.push(M);
                        for (P =
                                 0; P < Q; P += 1) {
                            x = F.dataset[P];
                            x._yAxisName = F.title;
                            void 0 === x.color && (x.color = h(w.linecolor, na));
                            D = {
                                visible: !f(x.initiallyhidden, 0),
                                yAxis: ga,
                                data: [],
                                hoverEffects: this.parseSeriesHoverOptions(b, m, x, c)
                            };
                            D = this.point(c, D, x, e.chart, m, v.oriCatTmp.length, ga, q);
                            D.legendFillColor = M.legendFillColor;
                            D.legendIndex = F._index;
                            if (void 0 === D.showInLegend || D.showInLegend)y = !0;
                            !1 !== D.showInLegend && (D.showInLegend = Boolean(E));
                            wa.push(m.series.length);
                            m.series.push(D)
                        }
                        0 !== wa.length && y || (M.showInLegend = !1)
                    }
                    b = g._lastClickedOpp ?
                        f(z, C) : f(C, z);
                    ga = 0;
                    for (r = m.yAxis.length; ga < r; ga += 1)ga != b && (m.yAxis[ga].gridLineWidth = 0, v[ga].zeroplanethickness = 0);
                    this.configureAxis(m, e)
                }
            },
            point: function (b, d, a, c, e, g, q, m) {
                b = !1;
                q = f(c.ignoreemptydatasets, 0);
                var v;
                v = e.chart;
                var l = a.data || [], p = e[ja], k = p[d.yAxis || 0], n = h(d.type, this.defaultSeriesType), t = e.plotOptions[n] && e.plotOptions[n].stacking, u = h(this.isValueAbs, p.isValueAbs, !1), E = f(d.yAxis, 0), D = this.numberFormatter, x = this.colorManager, y = R(h(a.color, k.linecolor, c.linecolor, x.getPlotColor())), M = f(a.alpha,
                    k.linealpha, c.linealpha, Fa), ga = f(c.showshadow, this.defaultPlotShadow, 1), wa = f(a.drawanchors, a.showanchors, c.drawanchors, c.showanchors), w = f(a.anchorsides, c.anchorsides, 0), F = f(a.anchorstartangle, c.anchorstartangle, 90), r = f(a.anchorradius, c.anchorradius, 3), Q = R(h(a.anchorbordercolor, c.anchorbordercolor, y)), C = f(a.anchorborderthickness, c.anchorborderthickness, 1), x = R(h(a.anchorbgcolor, c.anchorbgcolor, x.getColor("anchorBgColor"))), na = h(a.anchoralpha, c.anchoralpha, Fa), A = h(a.anchorbgalpha, c.anchorbgalpha, na);
                d.anchorShadow = na && h(a.anchorshadow, k.anchorShadow, c.anchorshadow, 0);
                d.name = z(a.seriesname);
                if (0 === f(a.includeinlegend) || void 0 === d.name || 0 === M && 1 !== wa)d.showInLegend = !1;
                d.marker = {
                    fillColor: {FCcolor: {color: x, alpha: A * na / 100 + B}},
                    lineColor: {FCcolor: {color: Q, alpha: na + B}},
                    lineWidth: C,
                    radius: r,
                    symbol: fa(w),
                    startAngle: F
                };
                d.color = {FCcolor: {color: y, alpha: M}};
                d.shadow = ga ? {opacity: ga ? M / 100 : 0} : !1;
                d.step = this.stepLine;
                d.drawVerticalJoins = Boolean(f(c.drawverticaljoins, 1));
                d.useForwardSteps = Boolean(f(c.useforwardsteps,
                    1));
                d.lineWidth = f(a.linethickness, k.linethickness, c.linethickness, 2);
                c = d._dataParser = lb.line(e, {
                    plottooltext: h(a.plottooltext, k.plottooltext),
                    seriesname: d.name,
                    lineAlpha: M,
                    anchorAlpha: na,
                    showValues: f(a.showvalues, p.showValues),
                    yAxis: m,
                    lineDashed: Boolean(f(a.dashed, k.linedashed, c.linedashed, 0)),
                    lineDashLen: f(a.linedashlen, k.linedashlen, c.linedashlen, 5),
                    lineDashGap: f(a.linedashgap, k.linedashgap, c.linedashgap, 4),
                    lineThickness: d.lineWidth,
                    lineColor: y,
                    valuePosition: h(a.valueposition, v.valuePosition),
                    drawAnchors: wa,
                    anchorShadow: d.anchorShadow,
                    anchorBgColor: x,
                    anchorBgAlpha: A,
                    anchorBorderColor: Q,
                    anchorBorderThickness: C,
                    anchorRadius: r,
                    anchorSides: w,
                    anchorAngle: F,
                    _sourceDataset: a,
                    _yAxisName: a._yAxisName,
                    hoverEffects: d.hoverEffects
                }, this);
                delete a._yAxisName;
                for (m = 0; m < g; m += 1)(v = l[m]) ? (a = D.getCleanValue(v.value, u), null === a ? d.data.push({y: null}) : (b = !0, d.data.push(c(v, m, a)), this.pointValueWatcher(e, a, E, t, m, 0, n))) : d.data.push({y: null});
                !q || b || this.realtimeEnabled || (d.showInLegend = !1);
                return d
            },
            configureAxis: function (b,
                                     d) {
                var a = b[ja], c = d.chart, e, g, q, m, h, l, p, k, n, t, u, E, D;
                b.xAxis.title.text = G(c.xaxisname);
                D = 0;
                for (g = b.yAxis.length; D < g; D += 1)e = b.yAxis[D], q = a[D], E = f(q.yAxisValuesStep, 1), E = 1 > E ? 1 : E, m = q.maxValue, h = q.minValue, l = f(q.setadaptiveymin, 0), p = l = !l, k = q.numDivLines, n = 0 !== q.adjustdiv, t = q.showLimits, u = q.showDivLineValues, this.axisMinMaxSetter(e, q, m, h, l, p, k, n), this.configurePlotLines(c, b, e, q, t, u, E, this.numberFormatter, e._isSY, void 0, e._index), e.reversed && 0 <= e.min && (b.plotOptions.series.threshold = e.max)
            },
            spaceManager: function (b,
                                    d, a, c) {
                var e = b[ja], g, q, m = d.chart, v, l, p, k, n, t, u, E, D, x, y, M, ga, w;
                w = b.chart.marginLeft;
                var r = b.chart.marginRight, F = e.marginLeftExtraSpace, B = e.marginTopExtraSpace, Q = e.marginBottomExtraSpace, C = e.marginRightExtraSpace;
                n = a - (F + C + b.chart.marginRight + b.chart.marginLeft);
                var na = c - (Q + b.chart.marginBottom + b.chart.marginTop), A = .3 * n;
                c = .3 * na;
                var z = n - A, X = na - c, G = h(m.legendposition, Ga).toLowerCase();
                b.legend.enabled && G === xa && (z -= this.placeLegendBlockRight(b, d, z / 2, na));
                q = b.yAxis;
                k = q.length;
                g = k - e.noHiddenAxis;
                u = 0;
                if (g)for (x =
                               E = 0, y = 10, ga = z / g, t = k - 1; 0 <= t; --t)D = q[t], D.showAxis && (g = e[t], p = D.opposite, M = (p ? x : E) + y, v = g.tickWidth, l = h(m.rotateyaxisname, p ? "cw" : "ccw"), g.verticalAxisNamePadding = 4, g.fixedValuesPadding = v, g.verticalAxisValuesPadding = v, g.rotateVerticalAxisName = p && "ccw" !== l ? "cw" : l, g.verticalAxisNameWidth = 50, D.offset = M, u = ga + u - y, g = Za(D, g, b, d, na, u, p, 0, 0), g += y, p ? (x += g, b.chart.marginRight += y) : (E += g, b.chart.marginLeft += y), u -= g, z -= g, z < y && (y = 0), D._axisWidth = g);
                z -= Db(b, d, z);
                q = z + A;
                b.legend.enabled && G !== xa && (X -= this.placeLegendBlockBottom(b,
                    d, n, X / 2), b.legend.width > q && (b.legend.x = 0));
                X -= this.titleSpaceManager(b, d, q, X / 2);
                g = e.x;
                g.horizontalAxisNamePadding = f(m.xaxisnamepadding, 5);
                g.horizontalLabelPadding = f(m.labelpadding, 2);
                g.labelDisplay = "1" == m.rotatelabels ? "rotate" : h(m.labeldisplay, "auto").toLowerCase();
                g.staggerLines = f(m.staggerlines, 2);
                g.slantLabels = f(m.slantlabels, m.slantlabel, 0);
                n = {left: 0, right: 0};
                n = b.chart.managePlotOverflow && this.canvasPaddingModifiers && this.calculateCanvasOverflow(b, !0) || n;
                t = n.left + n.right;
                u = .6 * q;
                t > u && (E = n.left /
                    t, n.left -= E * (t - u), n.right -= (1 - E) * (t - u));
                this.xAxisMinMaxSetter(b, d, q, n.left, n.right);
                X -= tb(b.xAxis, g, b, d, q, X, A);
                X -= ub(b, d, X, b.xAxis);
                d = c + X;
                for (t = 0; t < k; t += 1)Ma(d, b, m, b.yAxis[t], e[t].lYLblIdx);
                b.legend.enabled && G === xa && (e = b.legend, m = c + X, e.height > m && (e.height = m, e.scroll.enabled = !0, u = (e.scroll.scrollBarWidth = 10) + (e.scroll.scrollBarPadding = 2), e.width += u, b.chart.marginRight += u), e.y = 20);
                m = (e = b.title.alignWithCanvas) ? b.chart.marginLeft + q / 2 : a / 2;
                w = e ? b.chart.marginLeft : w;
                a = e ? a - b.chart.marginRight : a - r;
                switch (b.title.align) {
                    case Ha:
                        b.title.x =
                            w;
                        b.title.align = "start";
                        break;
                    case xa:
                        b.title.x = a;
                        b.title.align = "end";
                        break;
                    default:
                        b.title.x = m, b.title.align = "middle"
                }
                switch (b.subtitle.align) {
                    case Ha:
                        b.subtitle.x = w;
                        break;
                    case xa:
                        b.subtitle.x = a;
                        break;
                    default:
                        b.subtitle.x = m
                }
                b.chart.marginLeft += F;
                b.chart.marginTop += B;
                b.chart.marginBottom += Q;
                b.chart.marginRight += C
            }
        }, r.mslinebase);
    r("candlestick", {
        friendlyName: "Candlestick Chart",
        standaloneInit: !0,
        creditLabel: !1,
        paletteIndex: 3,
        defaultSeriesType: "candlestick",
        canvasborderthickness: 1,
        rendererId: "candlestick",
        chart: r.errorbar2d.chart,
        drawErrorValue: r.errorbar2d.drawErrorValue,
        series: function (b, d, a) {
            var c, e, g = d[ja], q, m, v, l, p, k;
            c = b.chart;
            q = d.chart;
            var n = f(c.showvolumechart, 1);
            m = this.colorManager;
            var t;
            d.legend.enabled = Boolean(f(c.showlegend, 1));
            q.rollOverBandColor = A(h(c.rolloverbandcolor, m.getColor("altHGridColor")), h(c.rolloverbandalpha, m.getColor("altHGridAlpha")));
            if (b.dataset && 0 < b.dataset.length) {
                this.categoryAdder(b, d);
                d.yAxis[0].opposite = !0;
                g.numdivlines = z(b.chart.numpdivlines);
                n && (t = d._FCconf.numberFormatter,
                    q = d.labels, d._FCconf.numberFormatter = {}, d._FCconf.smartLabel && (e = d._FCconf.smartLabel, d._FCconf.smartLabel = void 0), d.labels = {}, v = I({}, d), d._FCconf.numberFormatter = t, d._FCconf.smartLabel = e, d.labels = q, e && (v._FCconf.smartLabel = e), v._FCconf.numberFormatter = new w.NumberFormatter(I(I({}, c), {
                    forcedecimals: K(c.forcevdecimals, c.forcedecimals),
                    forceyaxisvaluedecimals: K(c.forcevyaxisvaluedecimals, c.forceyaxisvaluedecimals),
                    yaxisvaluedecimals: K(c.vyaxisvaluedecimals, c.yaxisvaluedecimals),
                    formatnumber: K(c.vformatnumber,
                        c.formatnumber),
                    formatnumberscale: K(c.vformatnumberscale, c.formatnumberscale),
                    defaultnumberscale: K(c.vdefaultnumberscale, c.defaultnumberscale),
                    numberscaleunit: K(c.vnumberscaleunit, c.numberscaleunit),
                    vnumberscalevalue: K(c.vnumberscalevalue, c.numberscalevalue),
                    scalerecursively: K(c.vscalerecursively, c.scalerecursively),
                    maxscalerecursion: K(c.vmaxscalerecursion, c.maxscalerecursion),
                    scaleseparator: K(c.vscaleseparator, c.scaleseparator),
                    numberprefix: K(c.vnumberprefix, c.numberprefix),
                    numbersuffix: K(c.vnumbersuffix,
                        c.numbersuffix),
                    decimals: K(c.vdecimals, c.decimals)
                }), this), I(v, {
                    chart: {
                        backgroundColor: "rgba(255,255,255,0)",
                        borderColor: "rgba(255,255,255,0)",
                        animation: !1
                    },
                    title: {text: null},
                    subtitle: {text: null},
                    legend: {enabled: !1},
                    credits: {enabled: !1},
                    xAxis: {opposite: !0, labels: {enabled: !1}},
                    yAxis: [{opposite: !0, title: {}, plotBands: [], plotLines: []}, {
                        opposite: !1,
                        title: {text: b.chart.vyaxisname}
                    }]
                }), t = d.subCharts = [v]);
                c = 0;
                for (e = b.dataset.length; c < e; c += 1)q = {
                    numColumns: e,
                    data: []
                }, m = b.dataset[c], q = this.point(a, q, m, b.chart,
                    d, g.oriCatTmp.length, c), q instanceof Array ? (n && (v.series.push({
                    type: "column",
                    data: q[1]
                }), v.showVolume = !0, m = f(b.chart.volumeheightpercent, 40), m = 20 > m ? 20 : 80 < m ? 80 : m, l = g.height - (d.chart.marginBottom + d.chart.marginTop), p = l * m / 100, k = d.chart.marginBottom + p, v[ja].marginTop = k + 40, v.yAxis[0].plotBands = [], v.yAxis[0].plotLines = [], v.exporting.enabled = !1, v.yAxis[0].title.text = G(z(b.chart.vyaxisname)), v.yAxis[0].title.align = "low", v.chart.height = p + 20, v.chart.width = g.width, v.chart.top = l - p, v.chart.left = 0, v.chart.volumeHeightPercent =
                    m), d.series.push(q[0])) : (d.series.push(q), t = d.subCharts = void 0);
                if (b.trendset && 0 < b.trendset.length)for (c = 0, e = b.trendset.length; c < e; c += 1)q = {
                    type: "line",
                    marker: {enabled: !1},
                    connectNullData: 1,
                    data: []
                }, v = b.trendset[c], v.data && 0 < v.data.length && (q = this.getTrendsetPoint(a, q, v, b.chart, d, g.oriCatTmp.length, c), d.series.push(q));
                b.chart.showdivlinesecondaryvalue = 0;
                b.chart.showsecondarylimits = 0;
                this.configureAxis(d, b);
                d.yAxis[1].opposite = !1;
                d.yAxis[1].min = d.yAxis[0].min;
                d.yAxis[1].max = d.yAxis[0].max;
                d.yAxis[1].title.text =
                    d.yAxis[0].title.text;
                d.yAxis[0].title.text = B;
                n && t && (t = t[0], a = t[ja], a.numdivlines = z(b.chart.numvdivlines), a[0].min = g.volume && g.volume.min, a[0].max = g.volume && g.volume.max, t.series && t.series[0] && (t.series[0].showInLegend = !1), this.configureAxis(t, b), t.yAxis[0].title.text = G(z(b.chart.vyaxisname)), t.yAxis[1].min = t.yAxis[0].min, t.yAxis[1].max = t.yAxis[0].max, t.yAxis[1].title.text = t.yAxis[0].title.text, t.yAxis[0].title.text = B);
                if ((a = b.trendlines && b.trendlines[0] && b.trendlines[0].line) && a.length) {
                    for (n = 0; n <
                    a.length; n += 1)a[n].parentyaxis = "s", a[n].valueonleft = "1";
                    Sb(b.trendlines, d.yAxis, g, !0, this.isBar)
                }
            }
        },
        getTrendsetPoint: function (b, d, a, c, e) {
            if (a.data) {
                b = a.data;
                var g = b.length, q = 0, m, v, l, p, k, n = e[ja], t = this.numberFormatter, u = f(d.yAxis, 0), n = n.toolTextStore, g = R(h(a.color, c.trendsetcolor, "666666")), q = h(a.alpha, c.trendsetalpha, "100");
                m = f(a.thickness, c.trendsetthickness, 2);
                v = Boolean(f(a.dashed, c.trendsetdashed, 0));
                l = f(a.dashlen, c.trendsetdashlen, 4);
                p = f(a.dashgap, c.trendsetdashgap, 4);
                k = h(a.includeinlegend, 1);
                d.color = A(g, q);
                d.lineWidth = m;
                d.dashStyle = v ? ua(l, p) : "none";
                d.includeInLegend = k;
                d.name = z(a.name);
                d.doNotUseBand = !0;
                if (0 === f(a.includeinlegend) || void 0 === d.name)d.showInLegend = !1;
                d.tooltip = {enabled: !1};
                q = c.interactivelegend = 0;
                for (g = b.length; q < g; q += 1)(c = b[q]) && !c.vline && (a = t.getCleanValue(c.value), c = t.getCleanValue(c.x), c = null !== c ? c : q + 1, m = n && n[c], d.data.push({
                    x: c,
                    y: a,
                    toolText: m
                }), this.pointValueWatchers(e, null, a, a, null, u))
            }
            return d
        },
        point: function (b, d, a, c, e) {
            if (a.data) {
                b = r[b];
                var g = e[ja], q = z(c.plotpriceas,
                    B).toLowerCase(), m = a.data, v = m && m.length, l = this.numberFormatter, p = [], k = [], n = {}, t, u, E, D = !1, x = f(d.yAxis, 0), y = R(h(c.bearbordercolor, "B90000")), M = R(h(c.bearfillcolor, "B90000")), ga = this.colorManager, w = R(h(c.bullbordercolor, ga.getColor("canvasBorderColor"))), C = R(h(c.bullfillcolor, "FFFFFF")), F = d.lineWidth = f(c.plotlinethickness, "line" == q || "bar" == q ? 2 : 1), P = h(c.plotlinealpha, "100"), Q = f(c.plotlinedashlen, 5), Wa = f(c.plotlinedashgap, 4), na = f(c.vplotborderthickness, 1), Na = !!f(c.drawanchors, 1), N = f(c.anchorsides, 0), X =
                    f(c.anchorstartangle, 90), I = f(c.anchorradius, this.anchorRadius, 3), aa = R(h(c.anchorbordercolor, w)), L = f(c.anchorborderthickness, this.anchorBorderThickness, 1), ga = R(h(c.anchorbgcolor, ga.getColor("anchorBgColor"))), J = h(c.anchoralpha, "0"), S = h(c.anchorbgalpha, J), za, U, T, ba, ca, Y, O, V, K, ha, ea, pa, ka, ma, Z, va = !1;
                d.name = z(a.seriesname);
                d.showInLegend = !1;
                d.marker = {};
                switch (q) {
                    case "line":
                        d.plotType = "line";
                        break;
                    case "bar":
                        d.plotType = "candlestickbar";
                        break;
                    default:
                        d.plotType = "column", d.errorBarWidthPercent = 0, va = !0
                }
                for (U =
                         0; U < v; U += 1)(T = m[U]) && !T.vline && (O = l.getCleanValue(T.open), V = l.getCleanValue(T.close), K = l.getCleanValue(T.high), ha = l.getCleanValue(T.low), ea = l.getCleanValue(T.volume, !0), ma = l.getCleanValue(T.x), va && sa(V - O), H(O, V), ab(O, V), null !== ea && (D = !0), pa = H(O, V, K, ha), ka = ab(O, V, K, ha), G(z(T.valuetext, B)), t = R(h(T.bordercolor, V < O ? y : w)), u = h(T.alpha, "100"), q = A(R(h(T.color, V < O ? M : C)), u), E = Boolean(f(T.dashed)) ? ua(Q, Wa) : "none", za = {opacity: u / 100}, ca = g.oriCatTmp[U], Z = A(t, P), ba = b.getPointStub(e, c, T, O, V, K, ha, ea, Z, F, d.plotType,
                    ca), ma = ma ? ma : U + 1, n[ma] = ba.toolText, Y = G(h(T.displayvalue, T.valuetext, B)), d.data.push({
                    high: ab(O, V, K, ha),
                    low: H(O, V, K, ha),
                    color: va ? q : {FCcolor: {color: t, alpha: u}},
                    displayValue: Y,
                    displayValueArgs: Y,
                    borderColor: Z,
                    shadow: za,
                    dashStyle: E,
                    borderWidth: F,
                    x: ma,
                    y: ba.y,
                    categoryLabel: ca,
                    errorValue: ba.errorValue,
                    previousY: ba.previousY,
                    toolText: ba.toolText,
                    link: ba.link,
                    marker: {
                        enabled: Na,
                        fillColor: {FCcolor: {color: ga, alpha: S * J / 100 + B}},
                        lineColor: {FCcolor: {color: aa, alpha: J}},
                        lineWidth: L,
                        radius: I,
                        startAngle: X,
                        symbol: fa(N)
                    }
                }),
                    Y = z(G(h(T.volumetooltext, a.volumetooltext, c.volumetooltext))), Y = void 0 !== Y ? b.getPointStub(e, c, T, O, V, K, ha, ea, Z, F, d.plotType, ca, Y).toolText : ba.toolText, k.push({
                    y: ea,
                    categoryLabel: ca,
                    color: A(q, u),
                    toolText: Y,
                    borderWidth: na,
                    borderColor: A(t, h(c.plotlinealpha, T.alpha)),
                    dashStyle: E,
                    shadow: za,
                    x: ma,
                    link: T.link
                }), this.pointValueWatchers(e, ma, pa, ka, ea, x));
                g.toolTextStore = n;
                (d.drawVolume = D) ? p.push(d, k) : p = d;
                return p
            }
            return []
        },
        getPointStub: function (b, d, a, c, e, g, f, m, v, l, p, k, n) {
            var t = B, t = b[ja], u = t.numberFormatter,
                E = "line" === p, D = H(c, e), x = ab(c, e), y = {};
            b = b.subCharts && b.subCharts[0] && b.subCharts[0][ja].numberFormatter || u;
            switch (p) {
                case "line":
                    y.y = e;
                    y.link = h(a.link);
                    break;
                case "column":
                    y.y = sa(e - c);
                    y.previousY = D;
                    y.link = h(a.link);
                    y.errorValue = [];
                    0 < g - x && y.errorValue.push({
                        errorValue: g - x,
                        errorStartValue: x,
                        errorBarColor: v,
                        errorBarThickness: l,
                        opacity: 1
                    });
                    0 > f - D && y.errorValue.push({
                        errorValue: f - D,
                        errorStartValue: D,
                        errorBarColor: v,
                        errorBarThickness: l,
                        opacity: 1
                    });
                    break;
                default:
                    y.y = c, y.previousY = e, y.link = h(a.link)
            }
            t.showTooltip ?
                (t = z(G(h(n, a.tooltext, t.tooltext))), void 0 !== t ? t = db(t, [3, 5, 6, 10, 54, 55, 56, 57, 58, 59, 60, 61, 81, 82], {
                    label: k,
                    yaxisName: G(d.yaxisname),
                    xaxisName: G(d.xaxisname),
                    openValue: a.open,
                    openDataValue: u.dataLabels(c),
                    closeValue: a.close,
                    closeDataValue: u.dataLabels(e),
                    highValue: a.high,
                    highDataValue: u.dataLabels(g),
                    lowValue: a.low,
                    lowDataValue: u.dataLabels(f),
                    volumeValue: a.volume,
                    volumeDataValue: u.dataLabels(m)
                }, a, d) : (t = null === c || E ? B : "<b>Open:</b> " + u.dataLabels(c) + "<br/>", t += null !== e ? "<b>Close:</b> " + u.dataLabels(e) +
                "<br/>" : B, t += null === g || E ? B : "<b>High:</b> " + u.dataLabels(g) + "<br/>", t += null === f || E ? B : "<b>Low:</b> " + u.dataLabels(f) + "<br/>", t += null !== m ? "<b>Volume:</b> " + b.dataLabels(m) : B)) : t = B;
            y.toolText = t;
            return y
        },
        pointValueWatchers: function (b, d, a, c, e, g) {
            var q = b[ja];
            g = f(g, 0);
            null !== e && (b = q.volume, b || (b = q.volume = {}), b.max = b.max > e ? b.max : e, b.min = b.min < e ? b.min : e);
            null !== a && (b = q[g], !b.max && 0 !== b.max && (b.max = a), !b.min && 0 !== b.min && (b.min = a), b.max = ab(b.max, a), b.min = H(b.min, a));
            null !== c && (b = q[g], !b.max && 0 !== b.max && (b.max =
                c), !b.min && 0 !== b.min && (b.min = c), b.max = ab(b.max, c), b.min = H(b.min, c));
            null !== d && (a = q.x, a.max = a.max > d ? a.max : d, a.min = a.min < d ? a.min : d)
        },
        spaceManager: function (b, d, a, c) {
            var e = b[ja], g, q = d.chart, m = b.chart, v, l, p = this.smartLabel || e.smartLabel, k = e.x.min, n = e.x.max, t, u, E = c - (e.marginBottomExtraSpace + 0 + m.marginTop), D = b.yAxis, x;
            l = D.length;
            var y, M, ga = 0, w = 0, r = 8, F, P = ab(f(m.plotBorderWidth, 1), 0), Q;
            this.base.spaceManager.apply(this, arguments);
            b.xAxis.min = k - .5;
            b.xAxis.max = n + .5;
            b.yAxis[0].title.centerYAxis = b.yAxis[1].title.centerYAxis = !0;
            if (b.subCharts) {
                k = b.subCharts[0];
                ga = b.xAxis.showLine ? b.xAxis.lineThickness : P;
                Q = c - (m.marginTop + m.marginBottom + ga + P);
                w = k.chart.volumeHeightPercent;
                n = (e.horizontalAxisHeight || 15) + P;
                Q = Q * w / 100;
                m.marginBottom += Q + ga + P;
                l = I({}, b.xAxis);
                w = 0;
                for (r = b.xAxis.plotBands.length; w < r; w += 1)(g = b.xAxis.plotBands[w]) && g.label && g.label.text && (g.label.text = " "), (g = l.plotBands[w]) && g.label && g.label.y && (g.label.y = Ac(q.basefontsize, 10) + 4 + ga);
                w = 0;
                for (r = l.plotLines.length; w < r; w += 1)(g = l.plotLines[w]) && g.label && g.label.text &&
                (g.label.text = B);
                k.yAxis && k.yAxis[0] && k.yAxis[0].title && k.yAxis[0].title.text && (k.yAxis[0].title.text = B);
                k.xAxis = l;
                l = h(d.chart.rotateyaxisname, "ccw");
                l = l === La ? "none" : l;
                p = D[1].title.rotation ? p.getSmartText(k.yAxis[1].title.text, "none" === l ? m.marginLeft - 10 : Q, void 0, !0).text : p.getSmartText(k.yAxis[1].title.text, p.getOriSize(D[1].title.text).width, void 0, !0).text;
                D = k.yAxis;
                l = D.length;
                r = w = ga = 0;
                for (y = l - 1; 0 <= y; --y)M = D[y], g = e[y], x = M.opposite, F = (x ? w : ga) + r, l = h(d.chart.rotateyaxisname, x ? "cw" : "ccw"), l = l === La ?
                    "none" : l, v = f(q.yaxisvaluespadding, q.labelypadding, 4), v < P && (v = P), g.verticalAxisNamePadding = 10, g.verticalAxisValuesPadding = v + (M.showLine ? M.lineThickness : 0), g.rotateVerticalAxisName = l, M.offset = F, x ? t = Za(M, g, k, d, E, m.marginRight, !!x, 0, 0, w) : u = Za(M, g, k, d, E, m.marginLeft, !!x, 0, 0, ga);
                D = b.yAxis;
                k.yAxis[1].title = I({}, b.yAxis[1].title);
                k.yAxis[1].title.style = b.orphanStyles.vyaxisname.style;
                k.yAxis[1].title.text = p;
                k.chart.left = 0;
                k.chart.width = a;
                k.chart.top = c - m.marginBottom + n;
                k.chart.height = m.marginBottom - n;
                t = Math.max(m.marginRight,
                    t + m.spacingRight);
                u = Math.max(m.marginLeft, u + m.spacingLeft);
                k.chart.marginLeft = m.marginLeft = u;
                k.chart.marginRight = m.marginRight = t;
                k.chart.marginTop = 5;
                k.chart.marginBottom = m.marginBottom - (n + Q);
                b.yAxis.push(k.yAxis[0], k.yAxis[1]);
                k.xAxis.startY = D[2].startY = D[3].startY = k.chart.top + k.chart.marginTop;
                k.xAxis.endY = D[2].endY = D[3].endY = k.yAxis[0].startY + k.chart.height - k.chart.marginBottom;
                k.series[0] && (k.series[0].yAxis = 3, b.series.push(k.series[0]));
                b.xAxis = [b.xAxis, k.xAxis];
                b.yAxis[2].title.centerYAxis = b.yAxis[3].title.centerYAxis = !0
            }
        },
        isDual: !0,
        numVDivLines: 0,
        defSetAdaptiveYMin: !0,
        divLineIsDashed: 1,
        isCandleStick: !0,
        defaultPlotShadow: 1,
        requiredAutoNumericLabels: 1
    }, r.scatterbase);
    r("kagi", {
        friendlyName: "Kagi Chart",
        standaloneInit: !0,
        stepLine: !0,
        creditLabel: !1,
        defaultSeriesType: "kagi",
        defaultZeroPlaneHighlighted: !1,
        setAdaptiveYMin: 1,
        canvasPadding: 15,
        isKagi: 1,
        rendererId: "kagi",
        pointValueWatcher: function (b, d, a) {
            null !== d && (b = b[ja], a = f(a, 0), b[a] || (b[a] = {}), a = b[a], this.maxValue = a.max = a.max > d ? a.max : d, this.minValue = a.min = a.min < d ? a.min :
                d)
        },
        point: function (b, d, a, c, e) {
            b = e.chart;
            var g = a.length, q = 0, m = e[ja].x, v = e[ja].numberFormatter, l = this.colorManager, p, k, n, t, u, E, D, x, y, M, w, r, C, F, P, Q, A, na, z, N, X, H, aa, L, J, S;
            x = R(h(c.linecolor, c.palettecolors, l.getColor("plotFillColor")));
            y = f(c.linealpha, 100);
            p = f(c.linethickness, 2);
            d.color = {FCcolor: {color: x, alpha: y}};
            z = d.anchorShadow = f(c.anchorshadow, 0);
            d.lineWidth = p;
            d.step = this.stepLine;
            d.drawVerticalJoins = Boolean(f(c.drawverticaljoins, 1));
            D = f(c.drawanchors, c.showanchors);
            for (k = 0; k < g; k += 1)t = a[k], t.vline ||
            (p = v.getCleanValue(t.value), null != p && (n = f(t.showlabel, c.showlabels, 1), n = G(n ? K(t.label, t.name) : B), q += 1, E = f(t.linealpha, y), u = {opacity: E / 100}, M = f(t.anchorsides, c.anchorsides, 0), na = f(t.anchorstartangle, c.anchorstartangle, 90), C = f(t.anchorradius, c.anchorradius, this.anchorRadius, 3), r = R(h(t.anchorbordercolor, c.anchorbordercolor, x)), w = f(t.anchorborderthickness, c.anchorborderthickness, this.anchorBorderThickness, 1), F = R(h(t.anchorbgcolor, c.anchorbgcolor, l.getColor("anchorBgColor"))), P = h(t.anchoralpha, c.anchoralpha,
                "100"), Q = h(t.anchorbgalpha, c.anchorbgalpha, P), E = void 0 === D ? 0 !== E : !!D, N = Boolean(f(t.anchorshadow, z, 0)), X = h(t.anchorimageurl, c.anchorimageurl), H = h(t.anchorimagescale, c.anchorimagescale, 100), aa = h(t.anchorimagealpha, c.anchorimagealpha, 100), L = h(t.anchorimagepadding, c.anchorimagepadding, 1), J = f(t.anchorradius, c.anchorradius), S = f(t.hoverradius, c.hoverradius, t.anchorhoverradius, c.anchorhoverradius), A = this.pointHoverOptions(t, d, {
                plotType: "anchor",
                anchorBgColor: F,
                anchorAlpha: P,
                anchorBgAlpha: Q,
                anchorAngle: na,
                anchorBorderThickness: w,
                anchorBorderColor: r,
                anchorBorderAlpha: P,
                anchorSides: M,
                anchorRadius: C,
                imageUrl: X,
                imageScale: H,
                imageAlpha: aa,
                imagePadding: L,
                shadow: u
            }), d.data.push(I(this.getPointStub(t, p, n, e), {
                y: p,
                color: x,
                shadow: u,
                dashStyle: t.dashed,
                valuePosition: h(t.valueposition, b.valuePosition),
                isDefined: !0,
                marker: {
                    enabled: !!E,
                    shadow: N && {opacity: P / 100},
                    fillColor: {FCcolor: {color: F, alpha: Q * P / 100 + B}},
                    lineColor: {FCcolor: {color: r, alpha: P}},
                    lineWidth: w,
                    radius: C,
                    startAngle: na,
                    symbol: fa(M),
                    imageUrl: X,
                    imageScale: H,
                    imageAlpha: aa,
                    imagePadding: L,
                    isAnchorRadius: J,
                    isAnchorHoverRadius: S
                },
                hoverEffects: A.enabled && A.options,
                rolloverProperties: A.enabled && A.rolloverOptions
            })), this.pointValueWatcher(e, p)));
            m.catCount = q;
            return d
        },
        postSeriesAddition: function (b, d) {
            var a = b.series[0], c = d.chart, e = d.data, g = a && a.data, q = g && g.length, m = b[ja], v = m.x, m = m.axisGridManager, l = b.xAxis, p = !1, k = 0, n = .5, t = f(c.reversalvalue, -1), q = f(c.reversalpercentage, 5), u = this.maxValue, E = this.minValue, D, x, y, M, w, r, C, F, P, Q, A, na, z, H, X, N, aa = {};
            if (g && g.length) {
                a.rallyColor =
                    h(c.rallycolor, "FF0000");
                a.rallyAlpha = f(c.rallyalpha, c.linealpha, 100);
                a.declineColor = h(c.declinecolor, "0000FF");
                a.declineAlpha = f(c.declinealpha, c.linealpha, 100);
                a.rallyThickness = f(c.rallythickness, c.linethickness, 2);
                w = f(c.rallydashlen, c.linedashlen, 5);
                na = f(c.rallydashgap, c.linedashgap, 4);
                a.declineThickness = f(c.declinethickness, c.linethickness, 2);
                z = f(c.declinedashlen, c.linedashlen, 5);
                H = f(c.declinedashgap, c.linedashgap, 4);
                a.lineDashed = {
                    "true": f(c.rallydashed, c.linedashed, 0), "false": f(c.declinedashed,
                        c.linedashed, 0)
                };
                a.rallyDashed = f(c.rallydashed, c.linedashed, 0) ? ua(w, na, a.rallyThickness) : "none";
                a.declineDashed = f(c.declinedashed, c.linedashed, 0) ? ua(z, H, a.declineThickness) : "none";
                a.canvasPadding = f(c.canvaspadding, this.canvasPadding, 15);
                t = 0 < t ? t : q * (u - E) / 100;
                u = g[0].y;
                E = function (a, b) {
                    for (var c, d = 1, e = g[0].y; d < a;)c = g[d].y, b ? c <= e && (g[d].isDefined = !1) : c >= e && (g[d].isDefined = !1), d += 1;
                    g[0].vAlign = b ? Ga : Ka;
                    g[0].align = "center"
                };
                q = e && e.length;
                for (H = z = 0; H < q; H += 1)if ((na = e[H]) && na.vline)z && m.addVline(l, na, n, b); else {
                    aa =
                        e[H];
                    N && (N = !1, n += .5);
                    if (z && (P = g[z])) {
                        Q = g[z - 1];
                        P.vAlign = "middle";
                        P.align = xa;
                        P.showLabel = !1;
                        w = null;
                        M = P.y;
                        y = g[z + 1] && g[z + 1].y;
                        A = sa(u - M);
                        p ? M < r && D ? D = !1 : M > C && !D && (D = !0) : (M > u && A > t ? (D = !0, r = u, C = null, p = x = !0, E(z, D)) : M < u && A > t ? (D = !1, r = null, C = u, x = !1, p = !0, E(z, D)) : (x = D = null, p = !1), ea(Q) && (Q.isRally = D), null != D && (g[0].isRally = D));
                        P.isRally = D;
                        if (x && M < u || !x && M > u)w = u;
                        X = w ? w : M;
                        A = sa(X - y);
                        y = null == x ? null : x ? X > y && A >= t : X < y && A >= t;
                        if (Q && Q.isShift)for (x ? (r = u, F = Ga) : x || (C = u, F = Ka), Q = z; 1 < Q; --Q)if (g[Q].y == u) {
                            g[Q].vAlign = F;
                            g[Q].align = "center";
                            g[Q].showLabel = !0;
                            break
                        }
                        y ? (k += 1, n += .5, N = !0, x = !x, P.isShift = !0, u = X, M = f(na.showlabel, c.showlabels, 1), M = G(M ? K(na.label, na.name) : B), m.addXaxisCat(l, k - 1, k - 1, M, na, {}, c)) : x && M > u || !x && M < u ? u = M : w = u;
                        P.plotValue = w;
                        P.objParams = {isRally: D, lastHigh: C, lastLow: r, isRallyInitialised: p}
                    }
                    z += 1
                }
                M = f(aa.showlabel, c.showlabels, 1);
                M = G(M ? K(aa.label, aa.name) : B);
                m.addXaxisCat(l, k, k, M, aa, {}, c);
                a.shiftCount = v.catCount = k + 1
            }
        },
        xAxisMinMaxSetter: function (b, d, a) {
            var c = b[ja].x, e = d.chart;
            d = c.min = f(c.min, 0);
            var c = c.max = f(c.max, c.catCount -
                1), g = b.xAxis, q = H(f(e.canvaspadding, 0), a / 2 - 10), m = q, h = f(e.maxhshiftpercent, 10), l = b.series[0];
            b = l && l.shiftCount;
            var e = f(e.canvaspadding, this.canvasPadding, 15), p = a - 2 * e;
            l && (m = l.xShiftLength = H(p / b, (0 >= h ? 10 : h) * p / 100), q = e + m / 2, m = a - (m * ab(b - 1, 1) + q), c = ab(c, 1));
            g.labels.enabled = !1;
            g.gridLineWidth = 0;
            g.alternateGridColor = da;
            a = (a - (q + m)) / (c - d + 0);
            g.min = d - (0 + q / a);
            g.max = c + (0 + m / a)
        }
    }, r.linebase);
    Hb = function (b, d, a) {
        this.nf = d;
        this.dataSeparator = a;
        this.method = (b || B).toLowerCase().replace(/\s/g, "")
    };
    Hb.prototype = {
        setArray: function (b) {
            var d =
                this.nf, a = this.dataSeparator, c = 0;
            !b && (b = B);
            for (b = this.dataLength = (a = b.replace(/\s/g, B).split(a)) && a.length; b--;)c += a[b] = d.getCleanValue(a[b]);
            a && a.sort(function (a, b) {
                return a - b
            });
            this.values = a;
            this.mean = c / this.dataLength;
            this.getFrequencies()
        }, getQuartiles: function () {
            var b = this.values, d = this.dataLength, a = d % 2, c, e;
            switch (this.method) {
                case "tukey":
                    a ? (a = (d + 3) / 4, d = (3 * d + 1) / 4) : (a = (d + 2) / 4, d = (3 * d + 2) / 4);
                    break;
                case "mooremccabe":
                    a ? (a = (d + 1) / 4, d = 3 * a) : (a = (d + 2) / 4, d = (3 * d + 2) / 4);
                    break;
                case "freundperles":
                    a = (d + 3) / 4;
                    d = (3 * d + 1) / 4;
                    break;
                case "mendenhallsincich":
                    a = N((d + 1) / 4);
                    d = N(3 * a);
                    break;
                default:
                    a = (d + 1) / 4, d = 3 * a
            }
            --a;
            --d;
            c = $b(a);
            e = $b(d);
            a = a - c ? b[c] + (b[tc(a)] - b[c]) * (a - c) : b[a];
            b = d - e ? b[e] + (b[tc(d)] - b[e]) * (d - e) : b[d];
            return this.quartiles = {q1: a, q3: b}
        }, getMinMax: function () {
            var b = this.values;
            return {min: b[0], max: b[this.dataLength - 1]}
        }, getMean: function () {
            return this.mean
        }, getMD: function () {
            for (var b = this.mean, d = this.frequencies, a = d.length, c, e = 0; a--;)c = d[a], e += c.frequency * sa(c.value - b);
            return e / this.dataLength
        }, getSD: function () {
            for (var b =
                this.mean, d = this.values, a = this.dataLength, c = a, e = 0; a--;)e += V(d[a] - b, 2);
            return fc(e) / c
        }, getQD: function () {
            return .5 * (this.quartiles.q3 - this.quartiles.q1)
        }, getFrequencies: function () {
            var b = [], d = this.dataLength, a = this.values, c = 0, e, g, f;
            for (f = 0; f < d; f += 1)c += e = a[f], ea(b[f]) ? b[f].frequency += 1 : (g = {}, g.value = e, g.frequency = 1, b[f] = g);
            this.sum = c;
            this.frequencies = b
        }, getMedian: function () {
            var b = this.dataLength, d = .5 * b, a = this.values;
            return 0 === b % 2 ? (a[d] + a[d - 1]) / 2 : a[$b(d)]
        }
    };
    Hb.prototype.constructor = Hb;
    r("boxandwhisker2d",
        {
            friendlyName: "Box and Whisker Chart",
            standaloneInit: !0,
            creditLabel: !1,
            defaultSeriesType: "boxandwhisker2d",
            chart: r.errorbar2d.chart,
            drawErrorValue: r.errorbar2d.drawErrorValue,
            decimals: 2,
            maxColWidth: 9E3,
            useErrorAnimation: 1,
            avoidCrispError: 0,
            tooltipsepchar: ": ",
            rendererId: "boxandwhisker",
            fireGroupEvent: !0,
            point: function (b, d, a, c, e, g, q, m, v) {
                var l = e[ja], p = f(c.ignoreemptydatasets, 0), k = l.numberFormatter, n = e.chart.useRoundEdges, t = f(c.showshadow, 1), u = this.colorManager, E = "," + (f(c.useplotgradientcolor, 0) ? Fb(c.plotgradientcolor,
                        u.getColor("plotGradientColor")) : B), D = 2 * q, x = f(c.plotborderthickness, 1), y = h(c.plotbordercolor, u.getColor("plotBorderColor")).split(",")[0], M = h(c.plotborderalpha, "100"), w = "0" == c.showplotborder ? "0" : M, r = f(a.dashed, c.plotborderdashed, 0), C = f(a.dashlen, c.plotborderdashlen, 5), F = f(a.dashgap, c.plotborderdashgap, 4), P = h(a.upperboxcolor, c.upperboxcolor, u.getPlotColor(D)), Q = h(a.lowerboxcolor, c.lowerboxcolor, u.getPlotColor(D + 1)), N = f(a.upperboxalpha, c.upperboxalpha), na = f(a.lowerboxalpha, c.lowerboxalpha), O = h(a.upperwhiskercolor,
                    c.upperwhiskercolor, y), V = h(a.lowerwhiskercolor, c.lowerwhiskercolor, y), X = f(a.upperwhiskeralpha, c.upperwhiskeralpha, c.plotborderalpha, "100"), K = f(a.lowerwhiskeralpha, c.lowerwhiskeralpha, c.plotborderalpha, "100"), aa = f(a.upperwhiskerthickness, c.upperwhiskerthickness, x), L = f(a.lowerwhiskerthickness, c.lowerwhiskerthickness, x), J = h(a.upperwhiskerdashed, c.upperwhiskerdashed, 0), S = h(a.lowerwhiskerdashed, c.lowerwhiskerdashed, 0), za = h(a.upperwhiskerdashlen, c.upperwhiskerdashlen, 5), U = h(a.lowerwhiskerdashlen, c.lowerwhiskerdashlen,
                    5), T = h(a.upperwhiskerdashgap, c.upperwhiskerdashgap, 4), ba = h(a.lowerwhiskerdashgap, c.lowerwhiskerdashgap, 4), ca = h(a.upperquartilecolor, c.upperquartilecolor, y), Y = h(a.lowerquartilecolor, c.lowerquartilecolor, y), R = h(a.upperboxbordercolor, c.upperboxbordercolor, y), Z = h(a.lowerboxbordercolor, c.lowerboxbordercolor, y), ia = h(a.mediancolor, c.mediancolor, y), ha = h(a.upperquartilealpha, c.upperquartilealpha, n ? 0 : M), la = h(a.lowerquartilealpha, c.lowerquartilealpha, n ? 0 : M), qa = h(a.upperboxborderalpha, c.upperboxborderalpha, n ?
                    0 : w), ka = h(a.lowerboxborderalpha, c.lowerboxborderalpha, n ? 0 : w), ma = h(a.medianalpha, c.medianalpha, M), ra = h(a.upperquartilethickness, c.upperquartilethickness, x), va = h(a.lowerquartilethickness, c.lowerquartilethickness, x), sa = h(a.upperboxborderthickness, c.upperboxborderthickness, x), Nc = h(a.lowerboxborderthickness, c.lowerboxborderthickness, x), Cc = h(a.medianthickness, c.medianthickness, x), oa = h(a.upperquartiledashed, c.upperquartiledashed, r), Aa = h(a.lowerquartiledashed, c.lowerquartiledashed, r), ta = h(a.upperboxborderdashed,
                    c.upperboxborderdashed, r), pb = h(a.lowerboxborderdashed, c.lowerboxborderdashed, r), Eb = h(a.mediandashed, c.mediandashed, r), ya = h(a.upperquartiledashlen, c.upperquartiledashlen, C), xa = h(a.lowerquartiledashlen, c.lowerquartiledashlen, C), Ba = h(a.upperboxborderdashlen, c.upperboxborderdashlen, C), Ca = h(a.lowerboxborderdashlen, c.lowerboxborderdashlen, C), Da = h(a.mediandashlen, c.mediandashlen, C), Ga = h(a.upperquartiledashgap, c.upperquartiledashgap, F), Ha = h(a.lowerquartiledashgap, c.lowerquartiledashgap, F), Ja = h(a.upperboxborderdashgap,
                    c.upperboxborderdashgap, F), La = h(a.lowerboxborderdashgap, c.lowerboxborderdashgap, F), Ma = h(a.mediandashgap, c.mediandashgap, F), Ia = {}, Ka = {}, Oa = {}, Pa = {}, Fa = {}, Qa = [], Ra = [], Sa = [], Ua = [], Va = [], Ta = {
                    polygon: "polygon",
                    spoke: "spoke"
                }, Xa = Ta[h(a.meaniconshape, c.meaniconshape, "polygon").toLowerCase()] || "polygon", Ya = f(a.meaniconradius, c.meaniconradius, 5), Za = f(a.meaniconsides, c.meaniconsides, 3), bb = h(a.meaniconcolor, c.meaniconcolor, "000000"), cb = h(a.meaniconbordercolor, c.meaniconbordercolor, "000000"), db = f(a.meaniconalpha,
                    c.meaniconalpha, 100), eb = Ta[h(a.sdiconshape, c.sdiconshape, "polygon").toLowerCase()] || "polygon", gb = f(a.sdiconradius, c.sdiconradius, 5), jb = f(a.sdiconsides, c.sdiconsides, 3), hb = h(a.sdiconcolor, c.sdiconcolor, "000000"), ib = h(a.sdiconbordercolor, c.sdiconbordercolor, "000000"), mb = f(a.sdiconalpha, c.sdiconalpha, 100), lb = Ta[h(a.mdiconshape, c.mdiconshape, "polygon").toLowerCase()] || "polygon", nb = f(a.mdiconradius, c.mdiconradius, 5), qb = f(a.mdiconsides, c.mdiconsides, 3), ob = h(a.mdiconcolor, c.mdiconcolor, "000000"), zb = h(a.mdiconbordercolor,
                    c.mdiconbordercolor, "000000"), Ab = f(a.mdiconalpha, c.mdiconalpha, 100), sb = Ta[h(a.qdiconshape, c.qdiconshape, "polygon").toLowerCase()] || "polygon", ub = f(a.qdiconradius, c.qdiconradius, 5), Bb = f(a.qdiconsides, c.qdiconsides, 3), tb = h(a.qdiconcolor, c.qdiconcolor, "000000"), Db = h(a.qdiconbordercolor, c.qdiconbordercolor, "000000"), Hb = f(a.qdiconalpha, c.qdiconalpha, 100), Gb = Ta[h(a.outliericonshape, c.outliericonshape, "polygon").toLowerCase()] || "polygon", Ob = f(a.outliericonradius, c.outliericonradius, 5), Pb = f(a.outliericonsides,
                    c.outliericonsides, 3), Sb = h(a.outliericoncolor, c.outliericoncolor, "000000"), Tb = h(a.outliericonbordercolor, c.outliericonbordercolor, "000000"), ac = f(a.outliericonalpha, c.outliericonalpha, 100), Mb = (1 - 2 * l.plotSpacePercent) / 2 * (-.5 + q), $b = f(c.reverselegend, 0), kc = $b ? -1 : 1, lc = d.legendIndex = 6 * q + ($b ? 5 : 0), hc = f(a.showmean, c.showmean, 0), ic = f(a.showmd, c.showmd, 0), jc = f(a.showsd, c.showsd, 0), tc = f(a.showqd, c.showqd, 0), fc = f(a.showalloutliers, c.showalloutliers, 0), vc = f(c.outliersupperrangeratio, 0), wc = f(c.outlierslowerrangeratio,
                    0), gc = !1, mc = Boolean(f(c.showdetailedlegend, 1)), nc = l.tooltipSepChar, Ub = !0, uc = l.dataSeparator, Qb = l.bwCalc, Oc = h(d.type, this.defaultSeriesType), Ac = e.plotOptions[Oc] && e.plotOptions[Oc].stacking, rb, Vb, Dc, Ec, Pc, Qc, Rc, Sc, Tc, Uc, Vc, kb, Ib, Jb, Kb, Lb, xc, Fc, Nb, cc, oc, Rb, dc, Wb, Xb, Yb, Gc, Hc, pc, Ic, W, qc, Zb, rc, ec, Wc, Jc, fb, $a, Xc, sc, Kc, vb, wb, yc, Lc, xb, yb, Mc, Bc = function (a, b) {
                    return a - b
                }, zc, Yc, Zc, $c, ad, bd;
                d.errorBarWidthPercent = f(a.whiskerslimitswidthratio, c.whiskerslimitswidthratio, 40);
                Kc = a.data;
                d.name = z(a.seriesname);
                Ac ||
                (d.columnPosition = f(v, m, q));
                d.errorBar2D = !0;
                if (0 === f(a.includeinlegend) || void 0 === d.name)Ub = d.showInLegend = !1;
                3 > Za && (Za = 3);
                xc = Cb(P.split(",")[0]);
                Fc = Cb(Q.split(",")[0]);
                d.color = {
                    FCcolor: {
                        color: xc + "," + xc + "," + Fc + "," + Fc,
                        alpha: "100,100,100,100",
                        angle: 90,
                        ratio: "0,50,0,50"
                    }
                };
                rc = this.isBar;
                w = (ec = /3d$/.test(e.chart.defaultSeriesType)) ? c.showplotborder ? w : "0" : w;
                y = ec ? h(c.plotbordercolor, "#FFFFFF") : y;
                vc = 0 > vc ? 0 : vc;
                wc = 0 > wc ? 0 : wc;
                for (Yb = 0; Yb < g; Yb += 1) {
                    if (W = Kc && Kc[Yb])W.value ? (Qb.setArray(W.value), Lc = Qb.getQuartiles(),
                        xb = Lc.q1, yb = Lc.q3, Mc = Qb.getMinMax(), wb = Xb = Mc.min, vb = Mc.max, yc = Qb.getMedian(), Ib = Qb.getMean(), Kb = Qb.getMD(), Jb = Qb.getSD(), Lb = Qb.getQD(), Wb = dc = vb) : (xb = k.getCleanValue(W.q1), yb = k.getCleanValue(W.q3), wb = Xb = k.getCleanValue(W.min), vb = k.getCleanValue(W.max), yc = k.getCleanValue(W.median), Wb = dc = vb, Ib = Ec = k.getCleanValue(W.mean), Kb = k.getCleanValue(W.md), Jb = k.getCleanValue(W.sd), Lb = k.getCleanValue(W.qd));
                    if (W && null != xb && null != yb && null !== dc) {
                        gc = !0;
                        Ec = f(W.showmean, hc);
                        Pc = f(W.showmd, ic);
                        Qc = f(W.showsd, jc);
                        Rc = f(W.showqd,
                            tc);
                        Vb = l.oriCatTmp[Yb];
                        fb = this.getPointStub(e, c, a, W, vb, yb, yc, xb, wb, Ib, Kb, Jb, Lb, Vb);
                        $a = fb.toolText;
                        Ec ? (Sc = 1, kb = f(W.meaniconalpha, db), $a = z(G(h(W.meantooltext, a.meantooltext, c.meantooltext))), $a = void 0 !== $a ? this.getTooltext($a, e, c, a, W, vb, wb, xb, yb, Fa, Jb, Lb, Kb, Ib, Vb) : "<b>Mean" + nc + "</b>" + k.dataLabels(Ib), Qa.push({
                            y: Ib, toolText: $a, link: fb.link, marker: {
                                enabled: !0,
                                fillColor: A(h(W.meaniconcolor, bb), kb),
                                lineColor: A(h(W.meaniconbordercolor, cb), kb),
                                radius: f(W.meaniconradius, Ya),
                                symbol: fa(f(W.meaniconsides, Za),
                                    "spoke" == h(W.meaniconshape, Xa))
                            }
                        })) : Qa.push({y: null});
                        Pc ? (Tc = 1, kb = f(W.mdiconalpha, Ab), $a = z(G(h(W.mdtooltext, a.mdtooltext, c.mdtooltext))), $a = void 0 !== $a ? this.getTooltext($a, e, c, a, W, vb, wb, xb, yb, Fa, Jb, Lb, Kb, Ib, Vb) : "<b>MD" + nc + "</b>" + k.dataLabels(Kb), Ra.push({
                            y: Kb,
                            toolText: $a,
                            link: fb.link,
                            marker: {
                                enabled: !0,
                                fillColor: A(h(W.mdiconcolor, ob), kb),
                                lineColor: A(h(W.mdiconbordercolor, ib), kb),
                                radius: f(W.mdiconradius, nb),
                                symbol: fa(f(W.mdiconsides, qb), "spoke" == h(W.mdiconshape, lb))
                            }
                        })) : Ra.push({y: null});
                        Qc ? (Uc = 1,
                            kb = f(W.sdiconalpha, mb), $a = z(G(h(W.sdtooltext, a.sdtooltext, c.sdtooltext))), $a = void 0 !== $a ? this.getTooltext($a, e, c, a, W, vb, wb, xb, yb, Fa, Jb, Lb, Kb, Ib, Vb) : "<b>SD" + nc + "</b>" + k.dataLabels(Jb), Sa.push({
                            y: Jb,
                            toolText: $a,
                            link: fb.link,
                            marker: {
                                enabled: !0,
                                fillColor: A(h(W.sdiconcolor, hb), kb),
                                lineColor: A(h(W.sdiconbordercolor, ib), kb),
                                radius: f(W.sdiconradius, gb),
                                symbol: fa(f(W.sdiconsides, jb), "spoke" == h(W.sdiconshape, eb))
                            }
                        })) : Sa.push({y: null});
                        Rc ? (Vc = 1, kb = f(W.qdiconalpha, Hb), $a = z(G(h(W.qdtooltext, a.qdtooltext, c.qdtooltext))),
                            $a = void 0 !== $a ? this.getTooltext($a, e, c, a, W, vb, wb, xb, yb, Fa, Jb, Lb, Kb, Ib, Vb) : "<b>QD" + nc + "</b>" + k.dataLabels(Lb), Ua.push({
                            y: Lb,
                            toolText: $a,
                            link: fb.link,
                            marker: {
                                enabled: !0,
                                fillColor: A(h(W.qdiconcolor, tb), kb),
                                lineColor: A(h(W.qdiconbordercolor, Db), kb),
                                radius: f(W.qdiconradius, ub),
                                symbol: fa(f(W.qdiconsides, Bb), "spoke" == h(W.qdiconshape, sb))
                            }
                        })) : Ua.push({y: null});
                        if (Nb = W.outliers) {
                            Nb = Nb.replace(/\s/g, B).split(uc);
                            for (cc = Nb.length; cc--;)Nb[cc] = k.getCleanValue(Nb[cc]);
                            Nb.sort(Bc);
                            cc = Nb.length;
                            for (oc = 0; oc < cc; oc +=
                                1)if (Rb = Nb[oc], fc && (Wb = ab(dc, Rb), Xb = H(wb, Rb)), kb = f(W.outliericonalpha, ac), Rb > dc || Rb < wb)$a = z(G(h(W.outlierstooltext, a.outlierstooltext, c.outlierstooltext))), $a = void 0 !== $a ? this.getTooltext($a, e, c, a, W, vb, wb, xb, yb, Fa, Jb, Lb, Kb, Ib, Vb, Rb) : "<b>Outlier" + nc + "</b>" + k.dataLabels(Rb), Va.push({
                                y: Rb, toolText: $a, x: Yb, link: fb.link, marker: {
                                    enabled: !0,
                                    fillColor: A(h(W.outliericoncolor, Sb), kb),
                                    lineColor: A(h(W.outliericonbordercolor, Tb), kb),
                                    radius: f(W.outliericonradius, Ob),
                                    symbol: fa(f(W.outliericonsides, Pb), "spoke" ==
                                        h(W.outliericonshape, Gb))
                                }
                            })
                        }
                        fc || (Dc = Wb - Xb, Wb += Dc * vc, Xb -= Dc * wc);
                        Gc = h(W.upperboxcolor, P) + E;
                        Hc = h(W.lowerboxcolor, Q) + E;
                        pc = h(W.upperboxalpha, N, c.upperboxalpha, c.plotfillalpha, "100") + B;
                        Ic = h(W.lowerboxalpha, na, c.lowerboxalpha, c.plotfillalpha, "100") + B;
                        qc = h(W.ratio, a.ratio, c.plotfillratio);
                        Zb = h(360 - c.plotfillangle, 90);
                        0 > dc && (Zb = 360 - Zb);
                        Xc = {opacity: pc / 100};
                        sc = H(pc, w) + B;
                        Wc = bc(Gc, pc, qc, Zb, n, y, sc, rc, ec);
                        Jc = bc(Hc, Ic, qc, Zb, n, y, sc, rc, ec);
                        Ia = {
                            value: yb,
                            color: A(h(W.upperquartilecolor, ca), f(W.upperquartilealpha, ha)),
                            borderWidth: f(W.upperquartilethickness, ra),
                            dashStyle: f(W.upperquartiledashed, oa) ? ua(h(W.upperquartiledashlen, ya), h(W.upperquartiledashgap, Ga), f(W.upperquartilethickness, ra)) : "none",
                            displayValue: fb.displayValueQ3
                        };
                        Ka = {
                            value: xb,
                            color: A(h(W.lowerquartilecolor, Y), f(W.lowerquartilealpha, la)),
                            borderWidth: f(W.lowerquartilethickness, va),
                            dashStyle: f(W.lowerquartiledashed, Aa) ? ua(h(W.lowerquartiledashlen, xa), h(W.lowerquartiledashgap, Ha), f(W.lowerquartilethickness, va)) : "none",
                            displayValue: fb.displayValueQ1
                        };
                        Oa = {
                            color: A(h(W.upperboxbordercolor, R), f(W.upperboxborderalpha, qa)),
                            borderWidth: f(W.upperboxborderthickness, sa),
                            dashStyle: f(W.upperboxborderdashed, ta) ? ua(h(W.upperboxborderdashlen, Ba), h(W.upperboxborderdashgap, Ja), f(W.upperboxborderthickness, sa)) : "none"
                        };
                        Pa = {
                            color: A(h(W.lowerboxbordercolor, Z), f(W.lowerboxborderalpha, ka)),
                            borderWidth: f(W.lowerboxborderthickness, Nc),
                            dashStyle: f(W.lowerboxborderdashed, pb) ? ua(h(W.lowerboxborderdashlen, Ca), h(W.lowerboxborderdashgap, La), f(W.lowerboxborderthickness, Nc)) :
                                "none"
                        };
                        Fa = {
                            value: yc,
                            color: A(h(W.mediancolor, ia), f(W.medianalpha, ma)),
                            borderWidth: f(W.medianthickness, Cc),
                            dashStyle: f(W.mediandashed, Eb) ? ua(h(W.mediandashlen, Da), h(W.mediandashgap, Ma), f(W.medianthickness, Cc)) : "none",
                            displayValue: fb.displayValueMid
                        };
                        zc = [];
                        ea(vb) && zc.push({
                            errorValue: vb - yb,
                            toolText: fb.toolText,
                            link: fb.link,
                            errorBarColor: A(h(W.upperwhiskercolor, O), f(W.upperwhiskeralpha, X)),
                            errorBarThickness: f(W.upperwhiskerthickness, aa),
                            dashStyle: f(W.upperwhiskerdashed, J) ? ua(h(W.upperwhiskerdashlen,
                                za), h(W.upperwhiskerdashgap, T), f(W.upperwhiskerthickness, aa)) : "none",
                            displayValue: fb.displayValueMax,
                            shadow: {opacity: t ? f(W.upperwhiskeralpha, X) / 250 : 0}
                        });
                        ea(wb) && zc.push({
                            errorValue: -(xb - wb),
                            errorStartValue: xb,
                            toolText: fb.toolText,
                            link: fb.link,
                            errorBarColor: A(h(W.lowerwhiskercolor, V), f(W.lowerwhiskeralpha, K)),
                            errorBarThickness: f(W.lowerwhiskerthickness, L),
                            dashStyle: f(W.lowerwhiskerdashed, S) ? ua(h(W.lowerwhiskerdashlen, U), h(W.lowerwhiskerdashgap, ba), f(W.lowerwhiskerthickness, L)) : "none",
                            displayValue: fb.displayValueMin,
                            shadow: {opacity: t ? f(W.lowerwhiskeralpha, K) / 250 : 0}
                        });
                        rb = this.pointHoverOptions(W, a, c, {
                            upperBoxColor: Gc,
                            upperBoxAlpha: pc,
                            upperBoxBorderColor: h(W.upperboxbordercolor, R),
                            upperBoxBorderAlpha: f(W.upperboxborderalpha, qa),
                            upperBoxBorderThickness: Oa.borderWidth,
                            lowerBoxColor: Hc,
                            lowerBoxAlpha: Ic,
                            lowerBoxBorderColor: h(W.lowerboxbordercolor, Z),
                            lowerBoxBorderAlpha: f(W.lowerboxborderalpha, ka),
                            lowerBoxBorderThickness: Pa.borderWidth,
                            upperQuartileColor: h(W.upperquartilecolor, ca),
                            upperQuartileAlpha: f(W.upperquartilealpha,
                                ha),
                            upperQuartileThickness: Ia.borderWidth,
                            lowerQuartileColor: h(W.lowerquartilecolor, Y),
                            lowerQuartileAlpha: f(W.lowerquartilealpha, la),
                            lowerQuartileThickness: Ka.borderWidth,
                            upperWhiskerColor: h(W.upperwhiskercolor, O),
                            upperWhiskerThickness: f(W.upperwhiskerthickness, aa),
                            upperWhiskerAlpha: f(W.upperwhiskeralpha, X),
                            lowerWhiskerColor: h(W.lowerwhiskercolor, V),
                            lowerWhiskerAlpha: f(W.lowerwhiskeralpha, K),
                            lowerWhiskerThickness: f(W.lowerwhiskerthickness, L),
                            medianColor: h(W.mediancolor, ia),
                            medianAlpha: f(W.medianalpha,
                                ma),
                            medianThickness: f(W.medianthickness, Cc)
                        });
                        rb.enabled && (rb.upperBox.fill = pa(bc(rb.upperBox.color, rb.upperBox.alpha, qc, Zb, n, y, sc, rc, ec)[0].FCcolor), delete rb.upperBox.color, delete rb.upperBox.alpha, rb.lowerBox.fill = pa(bc(rb.lowerBox.color, rb.lowerBox.alpha, qc, Zb, n, y, sc, rc, ec)[0].FCcolor), delete rb.lowerBox.color, delete rb.lowerBox.alpha);
                        d.data.push(I(fb, {
                            y: yb,
                            errorValue: zc,
                            shadow: Xc,
                            color: Wc[0],
                            toolText: fb.toolText,
                            lowerboxColor: Jc[0],
                            lowerboxBorderColor: Jc[1],
                            borderWidth: 0,
                            upperQuartile: Ia,
                            lowerQuartile: Ka,
                            upperBoxBorder: Oa,
                            lowerBoxBorder: Pa,
                            median: Fa,
                            hoverEffects: rb
                        }));
                        d.isRoundEdges = n;
                        this.pointValueWatcher(e, Wb);
                        this.pointValueWatcher(e, Xb)
                    } else d.data.push({y: null}), Ra.push({y: null}), Sa.push({y: null}), Ua.push({y: null}), Qa.push({y: null})
                }
                d.showInLegend = Ub && (gc || !p);
                d.legendFillColor = A(xc, 20);
                Yc = {
                    type: "line",
                    name: "Mean",
                    relatedSeries: "boxandwhisker",
                    data: Qa,
                    legendIndex: lc + kc,
                    showInLegend: !!Sc && Ub && mc,
                    marker: {fillColor: A(bb, 100), lineColor: A(cb, 100), radius: Ya, symbol: fa(Za, "spoke" == Xa)},
                    color: f(c.drawmeanconnector,
                        a.drawmeanconnector, 0) ? A(h(a.meanconnectorcolor, c.meanconnectorcolor, bb), f(a.meanconnectoralpha, c.meanconnectoralpha, 100)) : da,
                    lineWidth: f(c.drawmeanconnector, a.drawmeanconnector, 0) ? f(a.meanconnectorthickness, c.meanconnectorthickness, 1) : 0,
                    shadow: 0,
                    legendFillColor: d.legendFillColor
                };
                Zc = {
                    type: "line",
                    name: "SD",
                    relatedSeries: "boxandwhisker",
                    data: Sa,
                    legendIndex: lc + 2 * kc,
                    showInLegend: !!Uc && Ub && mc,
                    marker: {fillColor: A(hb, 100), lineColor: A(ib, 100), radius: gb, symbol: fa(jb, "spoke" == eb)},
                    color: f(c.drawsdconnector,
                        a.drawsdconnector, 0) ? A(h(a.sdconnectorcolor, c.sdconnectorcolor, hb), f(a.sdconnectoralpha, c.sdconnectoralpha, 100)) : da,
                    lineWidth: f(c.drawsdconnector, a.drawsdconnector, 0) ? f(a.sdconnectorthickness, c.sdconnectorthickness, 1) : 0,
                    shadow: 0,
                    pointStart: Mb,
                    legendFillColor: d.legendFillColor
                };
                $c = {
                    type: "line",
                    name: "MD",
                    relatedSeries: "boxandwhisker",
                    data: Ra,
                    legendIndex: lc + 3 * kc,
                    showInLegend: !!Tc && Ub && mc,
                    marker: {fillColor: A(ob, 100), lineColor: A(zb, 100), radius: nb, symbol: fa(qb, "spoke" == lb)},
                    color: f(c.drawmdconnector, a.drawmdconnector,
                        0) ? A(h(a.mdconnectorcolor, c.mdconnectorcolor, ob), f(a.mdconnectoralpha, c.mdconnectoralpha, 100)) : da,
                    lineWidth: f(c.drawmdconnector, a.drawmdconnector, 0) ? f(a.mdconnectorthickness, c.mdconnectorthickness, 1) : 0,
                    shadow: 0,
                    pointStart: Mb,
                    legendFillColor: d.legendFillColor
                };
                ad = {
                    type: "line",
                    name: "QD",
                    relatedSeries: "boxandwhisker",
                    data: Ua,
                    legendIndex: lc + 4 * kc,
                    showInLegend: !!Vc && Ub && mc,
                    marker: {fillColor: A(tb, 100), lineColor: A(Db, 100), radius: ub, symbol: fa(Bb, "spoke" == sb)},
                    color: f(c.drawqdconnector, a.drawqdconnector, 0) ?
                        A(h(a.qdconnectorcolor, c.qdconnectorcolor, tb), f(a.qdconnectoralpha, c.qdconnectoralpha, 100)) : da,
                    lineWidth: f(c.drawqdconnector, a.drawqdconnector, 0) ? f(a.qdconnectorthickness, c.qdconnectorthickness, 1) : 0,
                    shadow: 0,
                    pointStart: Mb,
                    legendFillColor: d.legendFillColor
                };
                bd = {
                    type: "line",
                    name: "Outlier",
                    relatedSeries: "boxandwhisker",
                    showInLegend: !(!Va || !Va.length) && Ub && mc,
                    data: Va,
                    legendIndex: lc + 5 * kc,
                    marker: {fillColor: A(Sb, 100), lineColor: A(Tb, 100), radius: Ob, symbol: fa(Pb, "spoke" == Gb)},
                    color: da,
                    lineWidth: 0,
                    shadow: 0,
                    pointStart: Mb,
                    legendFillColor: d.legendFillColor
                };
                e._meanDataArr.push(Yc);
                e._sdDataArr.push(Zc);
                e._mdDataArr.push($c);
                e._qdDataArr.push(ad);
                e._outliers.push(bd);
                return d
            },
            series: function (b, d, a) {
                var c = d.series, e = d._meanDataArr = [], g = d._sdDataArr = [], f = d._mdDataArr = [], m = d._qdDataArr = [], v = d._outliers = [], l = d[ja], p = d.yAxis[0], k = 2 * l.plotSpacePercent, n, t, u, E, D;
                l.dataSeparator = h(d.chart.dataseparator, Ba);
                l.bwCalc = new Hb(b.chart.calculationmethod, l.numberFormatter, l.dataSeparator);
                r.multiseries.series.call(this,
                    b, d, a);
                a = c && c.length;
                b = ab(e.length, g.length, f.length, m.length, v.length, a);
                k = (1 - k) / a;
                l = p.min;
                D = p.max;
                d.series = c.concat(e, g, f, m, v);
                for (p = 0; p < a; p += 1)for (t = c[p], n = p, !t.relatedSeries && (t.relatedSeries = []), u = 0; 5 > u; u += 1)n += a, t.relatedSeries.push(n);
                for (u = p = 0; u < b; u += 1, p += 1)if (c = (-.5 * (a - 1) + p) * k, e[p] && (e[p].pointStart = c), g[p] && (g[p].pointStart = c), m[p] && (m[p].pointStart = c), f[p] && (f[p].pointStart = c), v[p] && (v[p].pointStart = c), n = (c = v[p]) && c.data)for (c = 0; c < n.length; c += 1)t = n[c], E = t.y, t.y = E > D || E < l ? null : E;
                delete d._meanDataArr;
                delete d._sdDataArr;
                delete d._mdDataArr;
                delete d._qdDataArr;
                delete d._outliers
            },
            getTooltext: function (b, d, a, c, e, g, f, m, h, l, p, k, n, t, u, E) {
                d = this.numberFormatter;
                return db(b, [1, 2, 3, 4, 5, 6, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80], {
                    maxValue: g,
                    maxDataValue: d.dataLabels(g),
                    minValue: f,
                    minDataValue: d.dataLabels(f),
                    Q1: d.dataLabels(m),
                    unformattedQ1: m,
                    Q3: d.dataLabels(h),
                    unformattedQ3: h,
                    median: d.dataLabels(l),
                    unformattedMedian: l,
                    SD: d.dataLabels(p),
                    unformattedSD: p,
                    QD: d.dataLabels(k),
                    unformattedQD: k,
                    MD: d.dataLabels(n),
                    unformattedMD: n,
                    mean: d.dataLabels(t),
                    unformattedMean: t,
                    label: G(u),
                    yaxisName: G(a.yaxisname),
                    xaxisName: G(a.xaxisname),
                    formattedValue: d.dataLabels(E),
                    value: E
                }, {value: E}, a, c)
            },
            pointHoverOptions: function (b, d, a, c) {
                var e = f(b.showhovereffect, d.showhovereffect, a.plothovereffect, a.showhovereffect), g = f(b.highlightonhover, d.highlightonhover, d.highlightplotonhover, a.highlightonhover, a.highlightplotonhover, e), q = {}, m = {}, v = {}, l = {}, p = {}, k = {}, n = {}, t = {}, u = {}, E;
                q.color = h(b.upperboxhovercolor, d.upperboxhovercolor,
                    a.plotfillhovercolor, a.upperboxhovercolor);
                q.alpha = h(b.upperboxhoveralpha, d.upperboxhoveralpha, a.upperboxhoveralpha);
                n.color = h(b.upperboxborderhovercolor, d.upperboxborderhovercolor, a.upperboxborderhovercolor);
                n.alpha = h(b.upperboxborderhoveralpha, d.upperboxborderhoveralpha, a.upperboxborderhoveralpha);
                n.thickness = f(b.upperboxborderhoverthickness, d.upperboxborderhoverthickness, a.upperboxborderhoverthickness);
                m.color = h(b.lowerboxhovercolor, d.lowerboxhovercolor, a.plotfillhovercolor, a.lowerboxhovercolor);
                m.alpha = h(b.lowerboxhoveralpha, d.lowerboxhoveralpha, a.lowerboxhoveralpha);
                t.color = h(b.lowerboxborderhovercolor, d.lowerboxborderhovercolor, a.lowerboxborderhovercolor);
                t.alpha = h(b.lowerboxborderhoveralpha, d.lowerboxborderhoveralpha, a.lowerboxborderhoveralpha);
                t.thickness = f(b.lowerboxborderhoverthickness, d.lowerboxborderhoverthickness, a.lowerboxborderhoverthickness);
                p.color = h(b.upperwhiskerhovercolor, d.upperwhiskerhovercolor, a.upperwhiskerhovercolor);
                p.alpha = h(b.upperwhiskerhoveralpha, d.upperwhiskerhoveralpha,
                    a.upperwhiskerhoveralpha);
                p.thickness = h(b.upperwhiskerhoverthickness, d.upperwhiskerhoverthickness, a.upperwhiskerhoverthickness);
                k.color = h(b.lowerwhiskerhovercolor, d.lowerwhiskerhovercolor, a.lowerwhiskerhovercolor);
                k.alpha = h(b.lowerwhiskerhoveralpha, d.lowerwhiskerhoveralpha, a.lowerwhiskerhoveralpha);
                k.thickness = h(b.lowerwhiskerhoverthickness, d.lowerwhiskerhoverthickness, a.lowerwhiskerhoverthickness);
                v.color = h(b.upperquartilehovercolor, d.upperquartilehovercolor, a.upperquartilehovercolor);
                v.alpha = h(b.upperquartilehoveralpha,
                    d.upperquartilehoveralpha, a.upperquartilehoveralpha);
                v.thickness = h(b.upperquartilehoverthickness, d.upperquartilehoverthickness, a.upperquartilehoverthickness);
                l.color = h(b.lowerquartilehovercolor, d.lowerquartilehovercolor, a.lowerquartilehovercolor);
                l.alpha = h(b.lowerquartilehoveralpha, d.lowerquartilehoveralpha, a.lowerquartilehoveralpha);
                l.thickness = h(b.lowerquartilehoverthickness, d.lowerquartilehoverthickness, a.lowerquartilehoverthickness);
                u.color = h(b.medianhovercolor, d.medianhovercolor, a.medianhovercolor);
                u.alpha = h(b.medianhoveralpha, d.medianhoveralpha, a.medianhoveralpha);
                u.thickness = h(b.medianhoverthickness, d.medianhoverthickness, a.medianhoverthickness);
                b = !!h(q.color, q.alpha, n.color, n.alpha, n.thickness, m.color, m.alpha, t.color, t.thickness, t.alpha, p.color, p.alpha, p.thickness, k.color, k.alpha, k.thickness, v.color, v.alpha, v.thickness, l.color, l.alpha, l.thickness, u.color, u.alpha, u.thickness, g);
                void 0 === e && void 0 === g && b && (g = 0);
                if (void 0 === e && b || e)E = !0, q.color = h(q.color, g ? Z(c.upperBoxColor, 70) : c.upperBoxColor),
                    q.alpha = h(q.alpha, c.upperBoxAlpha), m.color = h(m.color, g ? Z(c.lowerBoxColor, 70) : c.lowerBoxColor), m.alpha = h(m.alpha, c.lowerBoxAlpha), n.color = h(n.color, c.upperBoxBorderColor), n.alpha = f(n.alpha, c.upperBoxBorderAlpha), n.stroke = A(n.color, n.alpha), n["stroke-width"] = f(n.thickness, c.upperBoxBorderThickness), delete n.color, delete n.alpha, delete n.thickness, t.color = h(t.color, c.lowerBoxBorderColor), t.alpha = h(t.alpha, c.lowerBoxBorderAlpha), t.stroke = A(t.color, t.alpha), t["stroke-width"] = f(t.thickness, c.lowerBoxBorderThickness),
                    delete t.color, delete t.alpha, delete t.thickness, p.color = h(p.color, c.upperWhiskerColor, 70), p.alpha = h(p.alpha, c.upperWhiskerAlpha), p.stroke = A(p.color, p.alpha), p["stroke-width"] = h(p.thickness, c.upperWhiskerThickness), delete p.color, delete p.alpha, delete p.thickness, k.color = h(k.color, c.lowerWhiskerColor, 70), k.alpha = h(k.alpha, c.lowerWhiskerAlpha), k.stroke = A(k.color, k.alpha), k["stroke-width"] = h(k.thickness, c.lowerWhiskerThickness), delete k.color, delete k.alpha, delete k.thickness, v.color = h(v.color, c.upperQuartileColor,
                    70), v.alpha = h(v.alpha, c.upperQuartileAlpha), v.stroke = A(v.color, v.alpha), v["stroke-width"] = h(v.thickness, c.upperQuartileThickness), delete v.color, delete v.alpha, delete v.thickness, l.color = h(l.color, c.lowerQuartileColor, 70), l.alpha = h(l.alpha, c.lowerQuartileAlpha), l.stroke = A(l.color, l.alpha), l["stroke-width"] = h(l.thickness, c.lowerQuartileThickness), delete l.color, delete l.alpha, delete l.thickness, u.color = h(u.color, c.medianColor, 70), u.alpha = h(u.alpha, c.medianAlpha), u.stroke = A(u.color, u.alpha), u["stroke-width"] =
                    h(u.thickness, c.medianThickness), delete u.color, delete u.alpha, delete u.thickness;
                return {
                    enabled: E,
                    upperBox: q,
                    upperBoxBorder: n,
                    lowerBox: m,
                    lowerBoxBorder: t,
                    upperQuartile: v,
                    lowerQuartile: l,
                    upperWhisker: p,
                    lowerWhisker: k,
                    median: u
                }
            },
            getPointStub: function (b, d, a, c, e, g, q, m, v, l, p, k, n, t) {
                var u = B, u = b[ja], E = u.tooltipSepChar, D = this.numberFormatter, x = f(c.showvalue, a.showvalues, d.showvalues, 1), y = {
                    "true": D.dataLabels(e),
                    "false": B
                }, M = {"true": D.dataLabels(g), "false": B}, w = {"true": D.dataLabels(q), "false": B}, r = {
                    "true": D.dataLabels(m),
                    "false": B
                }, D = {"true": D.dataLabels(v), "false": B};
                u.showTooltip ? (u = z(G(h(c.tooltext, a.plottooltext, u.tooltext))), u = void 0 !== u ? this.getTooltext(u, b, d, a, c, e, v, m, g, q, k, n, p, l, t) : "<b>Maximum" + E + "</b>" + y[!0] + "<br/><b>Q3" + E + "</b>" + M[!0] + "<br/><b>Median" + E + "</b>" + w[!0] + "<br/><b>Q1" + E + "</b>" + r[!0] + "<br/><b>Minimum" + E + "</b>" + D[!0]) : u = B;
                return {
                    toolText: u,
                    link: h(c.link),
                    categoryLabel: t,
                    displayValueMax: y[!(!x || !f(c.showmaxvalue, a.showmaxvalues, d.showmaxvalues, 1))],
                    displayValueMid: w[!(!x || !f(c.showmedianvalue, a.showmedianvalues,
                        d.showmedianvalues, 1))],
                    displayValueMin: D[!(!x || !f(c.showminvalue, a.showminvalues, d.showminvalues, 1))],
                    displayValueQ3: M[!(!x || !f(c.showq3value, a.showq3values, d.showq3values, 0))],
                    displayValueQ1: r[!(!x || !f(c.showq1value, a.showq1values, d.showq1values, 0))]
                }
            }
        }, r.multiseries);
    r("heatmap", {
        friendlyName: "Heatmap Chart",
        standaloneInit: !0,
        creditLabel: !1,
        defaultSeriesType: "heatmap",
        tooltipsepchar: ": ",
        tooltipConstraint: "chart",
        rendererId: "heatmap",
        series: function (b, d, a) {
            var c = b.chart, e = d.chart, g = d[ja], q = this.colorManager,
                m = d.series, v = this.numberFormatter, l = b.rows && b.rows.row, p = l && l.length, k = b.columns && b.columns.column, n = k && k.length, t = b.dataset, u = t && t.data, E = b.colorrange || {}, D = g.mapByPercent = f(E.mapbypercent, 0), x = g.mapByCategory = f(c.mapbycategory, 0), E = !x && f(E.gradient, 0), y = h(c.plotfillalpha, 100), M = f(c.showlabels, c.showlabel, 1), r = f(c.showplotborder, 1), C = r ? f(c.plotborderthickness, 1) : 0, q = h(c.plotbordercolor, q.getColor("plotBorderColor")), r = h(c.plotborderalpha, r ? 95 : 0).toString(), q = A(q, r), r = f(c.plotborderdashed, 0), H = f(c.plotborderdashlen,
                5), u = f(c.plotborderdashgap, 4), H = r ? ua(H, u, C) : "none", F = w.colorRange, P = 0, Q = 0, O = 0, na = 0, Na = g.rowIdObj = {}, V = g.columnIdObj = {}, X = [], r = [], K = 0, aa = [], L, J, S, za, U, T, ba, ca, Y;
            e.showHoverEffect = f(c.showhovereffect, 1);
            E && (d.legend.type = "gradient");
            d.legend.enabled = Boolean(f(c.showlegend, 1));
            for (e = 0; e < p; e += 1)J = l[e], L = J.id, ea(L) && L !== B && (P += 1, Na[L.toLowerCase()] = {
                index: P,
                label: f(J.showlabel, c.showyaxislabels, c.showyaxisnames, M) ? h(J.label, J.name, L) : B
            });
            for (e = 0; e < n; e += 1)S = k[e], l = S.id, ea(l) && l !== B && (V[l.toLowerCase()] =
            {
                index: Q,
                label: f(S.showlabel, c.showxaxislabels, c.showxaxisnames, M) ? h(S.label, S.name, l) : B
            }, Q += 1);
            ba = 0;
            for (ca = t && t.length; ba < ca; ba += 1)for (u = t[ba] && t[ba].data, e = 0, Y = u && u.length; e < Y; e += 1)if (k = u[e], T = v.getCleanValue(k.value), null !== T || x)L = z(k.rowid, k.rowids), J = z(L, B).toLowerCase(), l = z(k.columnid, k.columnids), S = z(l, B).toLowerCase(), X.push(T), ea(U) || ea(za) || !ea(T) || (za = U = T), U > T && (U = T), za < T && (za = T), !ea(J) || ea(Na[J]) || p || (O += 1, Na[J] = {
                index: O,
                label: L
            }), !ea(S) || ea(V[S]) || n || (V[S] = {index: na, label: l}, na += 1), J =
                Na[J], S = V[S], J && S && (ea(aa[J.index]) || (aa[J.index] = []), aa[J.index][S.index] ? r[aa[J.index][S.index] - 1] = {
                rowId: L,
                columnId: l,
                categoryId: h(k.colorrangelabel, k.categoryid, k.categoryname, k.category),
                tlLabel: G(h(k.tllabel, k.ltlabel)),
                trLabel: G(h(k.trlabel, k.rtlabel)),
                blLabel: G(h(k.bllabel, k.lblabel)),
                brLabel: G(h(k.brlabel, k.rblabel)),
                rowLabel: J.label,
                columnLabel: S.label,
                setColor: k.color,
                setAlpha: h(k.alpha, y),
                setShowLabel: f(k.showlabel, k.showname, M),
                colorRangeLabel: k.colorrangelabel,
                displayValue: k.displayvalue,
                tooltext: k.tooltext,
                showvalue: k.showvalue,
                link: k.link,
                hoverColor: h(k.hovercolor, c.hovercolor, c.plotfillhovercolor),
                hoverAlpha: f(k.hoveralpha, c.hoveralpha, c.plotfillhoveralpha),
                index: K,
                value: T,
                y: J.index,
                x: S.index,
                _value: k.value,
                _cleanValue: T
            } : (K += 1, r.push({
                rowId: L,
                columnId: l,
                categoryId: h(k.colorrangelabel, k.categoryid, k.categoryname, k.category),
                tlLabel: G(h(k.tllabel, k.ltlabel)),
                trLabel: G(h(k.trlabel, k.rtlabel)),
                blLabel: G(h(k.bllabel, k.lblabel)),
                brLabel: G(h(k.brlabel, k.rblabel)),
                rowLabel: J.label,
                columnLabel: S.label,
                setColor: k.color && k.color.replace(hb, Oa),
                setAlpha: h(k.alpha, y),
                setShowLabel: f(k.showlabel, k.showname, M),
                colorRangeLabel: k.colorrangelabel,
                displayValue: k.displayvalue,
                tooltext: k.tooltext,
                showvalue: k.showvalue,
                link: k.link,
                hoverColor: h(k.hovercolor, c.hovercolor, c.plotfillhovercolor),
                hoverAlpha: f(k.hoveralpha, c.hoveralpha, c.plotfillhoveralpha),
                index: K,
                value: T,
                y: J.index,
                x: S.index,
                _value: k.value,
                _cleanValue: T
            }), aa[J.index][S.index] = K));
            if (r.length) {
                g.rowCount = P = ab(P, O);
                g.columnCount = ab(Q, na);
                for (e in Na)Na[e].index =
                    P - Na[e].index + 1;
                g.minHeatValue = U;
                g.maxHeatValue = za;
                p = za - U;
                D = D && !x;
                d.colorRange = new F({
                    colorRange: b.colorrange,
                    dataMin: U,
                    dataMax: za,
                    sortLegend: f(c.autoorderlegendicon, c.autoorderlegendicon, 0),
                    mapByCategory: x,
                    defaultColor: "cccccc",
                    numberFormatter: v
                });
                if (E)m.push({
                    data: [],
                    hoverEffects: this.parseSeriesHoverOptions(b, d, t, a),
                    borderWidth: C,
                    borderColor: q,
                    dashStyle: H
                }); else for (v = (c = d.colorRange.colorArr) && c.length, e = 0; e < v; e += 1)za = c[e], ea(za.code) && m.push({
                    data: [],
                    hoverEffects: this.parseSeriesHoverOptions(b,
                        d, t, a),
                    name: h(za.label, za.name),
                    borderWidth: C,
                    borderColor: q,
                    color: Cb(za.code),
                    dashStyle: H
                });
                m.length || m.push({data: [], showInLegend: !1});
                for (e = 0; e < r.length; e += 1)k = r[e], D && (k.value = N((k.value - U) / p * 1E4) / 100), a = d.colorRange.getColorObj(x ? k.categoryId : k.value), a.outOfRange || (k.y = g.rowCount - k.y + 1, k.color = A(h(k.setColor, a.code), h(k.setAlpha, y)), k.hoverColor = A(h(k.hoverColor, k.setColor, a.code), f(k.hoverAlpha, 25)), k = I(k, this.getPointStub(k, k.value, B, d, b)), E ? m[0].data.push(k) : m[a.seriesIndex] && m[a.seriesIndex].data.push(k))
            } else d.series =
                [];
            this.configureAxis(d, b)
        },
        getPointStub: function (b, d, a, c, e) {
            a = c[ja];
            var g = e.chart, q = a.tooltipSepChar, m = a.mapByCategory;
            e = a.mapByPercent && !m;
            var v = this.numberFormatter, l = b._cleanValue;
            c = v.percentValue(d);
            var p = null === l ? d : v.dataLabels(l), k = z(G(h(b.tooltext, a.tooltext))), n = z(G(b.displayValue)), t = m ? n : h(n, p), u = f(b.showvalue, a.showValues), E = z(g.tltype, B), D = z(g.trtype, B), x = z(g.bltype, B), y = z(g.brtype, B);
            d = b.tlLabel;
            var m = b.trLabel, v = b.blLabel, l = b.brLabel, M;
            E !== B && (E = "<b>" + E + q + "</b>");
            D !== B && (D = "<b>" + D + q +
                "</b>");
            x !== B && (x = "<b>" + x + q + "</b>");
            y !== B && (y = "<b>" + y + q + "</b>");
            a = a.showTooltip ? void 0 !== k ? db(k, [1, 2, 5, 6, 7, 14, 93, 94, 95, 96, 97, 98, 112, 113, 114, 115, 116, 117], {
                formattedValue: p,
                percentValue: e ? c : B,
                yaxisName: G(g.yaxisname),
                xaxisName: G(g.xaxisname)
            }, {
                value: b._value,
                displayvalue: b.displayValue
            }, g, b) : t === B ? !1 : (e ? "<b>Value" + q + "</b>" + p + "<br/><b>Percentage" + q + "</b>" + c : t) + (b.tlLabel !== B ? "<br/>" + (E + b.tlLabel) : B) + (b.trLabel !== B ? "<br/>" + D + b.trLabel : B) + (b.blLabel !== B ? "<br/>" + x + b.blLabel : B) + (b.brLabel !== B ? "<br/>" + y +
            b.brLabel : B) : B;
            u ? M = void 0 !== n ? n : e ? c : p : d = m = v = l = M = B;
            e = h(n, e ? c : p, B);
            b = h(b.link);
            return {
                displayValue: M,
                displayValueArgs: e,
                toolText: a,
                link: b,
                tlLabel: d,
                trLabel: m,
                blLabel: v,
                brLabel: l
            }
        },
        configureAxis: function (b, d) {
            var a = b[ja], c = d.chart, e = b.yAxis[0], g = b.xAxis, q = a.rowCount, m = a.columnCount, v = a.axisGridManager, l = a.rowIdObj, p = a.columnIdObj, k = this.colorManager, n = A(h(c.vdivlinecolor, c.divlinecolor, k.getColor("divLineColor")), f(c.vdivlinealpha, c.divlinealpha, k.getColor("divLineAlpha"))), t = f(c.vdivlinethickness, c.divlinethickness,
                1), u = f(c.vdivlinedashed, c.vdivlineisdashed, c.divlinedashed, c.divlineisdashed, 0) ? ua(f(c.vdivlinedashlen, c.divlinedashlen, 4), f(c.vdivlinedashgap, c.divlinedashgap, 2), t) : "none", E = A(h(c.hdivlinecolor, c.divlinecolor, k.getColor("divLineColor")), f(c.hdivlinealpha, c.divlinealpha, k.getColor("divLineAlpha"))), D = f(c.hdivlinethickness, c.divlinethickness, 1), x = f(c.hdivlinedashed, c.hdivlineisdashed, c.divlinedashed, c.divlineisdashed, 0) ? ua(f(c.hdivlinedashlen, c.divlinedashlen, 4), f(c.hdivlinedashgap, c.divlinedashgap,
                2), t) : "none", y, M;
            e.min = 0;
            e.max = q;
            for (M in l)y = l[M], k = y.index, y = y.label, v.addAxisGridLine(e, k + -.5, y, .1, void 0, da, 1), k < q && e.plotBands.push({
                isTrend: !0,
                color: E,
                value: k,
                width: D,
                dashStyle: x,
                zIndex: 3
            });
            e.labels.enabled = !1;
            e.gridLineWidth = 0;
            e.alternateGridColor = da;
            e.title.text = G(c.yaxisname);
            g.min = -.5;
            g.max = e = m + -.5;
            g.opposite = f(c.placexaxislabelsontop, 0);
            a.x.catCount = m;
            for (M in p)a = p[M], k = a.index, y = a.label, v.addXaxisCat(g, k, 1, y, a, {}, c), k -= -.5, k < e && g.plotBands.push({
                isTrend: !0, color: n, value: k, width: t, dashStyle: u,
                zIndex: 3
            });
            g.labels.enabled = !1;
            g.gridLineWidth = 0;
            g.alternateGridColor = da;
            g.title.text = G(c.xaxisname)
        },
        xAxisMinMaxSetter: function () {
        },
        placeLegendBlockRight: function () {
            return "gradient" === arguments[0].legend.type ? w.placeGLegendBlockRight ? w.placeGLegendBlockRight.apply(this, arguments) : 0 : w.placeLegendBlockRight.apply(this, arguments)
        },
        placeLegendBlockBottom: function () {
            return "gradient" === arguments[0].legend.type ? w.placeGLegendBlockBottom ? w.placeGLegendBlockBottom.apply(this, arguments) : 0 : w.placeLegendBlockBottom.apply(this,
                arguments)
        }
    }, r.column2dbase);
    r("renderer.multiaxisline", {
        legendClick: function (b, d, a) {
            var c = this.options.series, e = this.yAxis[c[b.index].yAxis], g = e.axisData._relatedSeries, f = g.length, m = !1;
            r["renderer.cartesian"].legendClick.call(this, b, d, a);
            if (!a) {
                for (; f-- && !(m = c[g[f]].visible););
                e.checkBox.element.checked = m
            }
        }
    }, r["renderer.cartesian"]);
    r("renderer.candlestick", {
        drawPlotCandlestickbar: function (b, d) {
            var a = b.data, c = a.length, e = b.items, g = b.graphics = [], q = this.paper, m = this.layers, h = this.definition.chart, l =
                this.options.plotOptions.series, p = this.xAxis[d.xAxis || 0], k = this.yAxis[d.yAxis || 0], n = d.numColumns || 1, t = d.columnPosition || 0, u = !1 === d.visible ? "hidden" : "visible", E = p.getAxisPosition(0), E = p.getAxisPosition(1) - E, D = l.groupPadding, x = l.maxColWidth, h = (1 - .01 * (h && h.plotspacepercent)) * E || H(E * (1 - 2 * D), x * n), n = h / n * t - h / 2, y, M, w, r, B, D = m.dataset = m.dataset || q.group("dataset-orphan");
            D.column = D.column || q.group("columns", D);
            for (m = 0; m < c; m += 1) {
                t = a[m];
                E = t.y;
                y = null;
                if (null === E) {
                    if (x = e[m])y = x.graphic, y.attr({height: 0})
                } else x =
                    f(t.x, m), h = t.link, x = p.getAxisPosition(x), y = t.previousY, M = k.getAxisPosition(y), y = k.getAxisPosition(E), w = k.getAxisPosition(t.high), r = k.getAxisPosition(t.low), sa(y - M), B = n, M = ["M", x, r, "L", x, w, "M", x, y, "L", x + B, y, "M", x, M, "L", x - B, M], (x = e[m]) || (x = e[m] = {
                    index: m,
                    value: E,
                    graphic: q.path(M, D),
                    dataLabel: null,
                    tracker: null
                }), y = x.graphic, y.attr({
                    path: M,
                    fill: pa(t.color),
                    stroke: pa(t.borderColor),
                    "stroke-width": t.borderWidth,
                    "stroke-dasharray": t.dashStyle,
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "shape-rendering": "crisp",
                    cursor: h ? "pointer" : "",
                    visibility: u
                }).shadow(l.shadow || t.shadow), this.drawTracker && this.drawTracker.call(this, b, d, m);
                y && g.push(y);
                this.drawTracker && this.drawTracker.call(this, b, d, m)
            }
            b.visible = !1 !== d.visible;
            return b
        }, drawCanvas: function () {
            r["renderer.cartesian"].drawCanvas.call(this, arguments);
            if (this.options.subCharts && this.options.subCharts[0]) {
                var b = this.options, b = (b.subCharts && b.subCharts[0]).chart || {}, d = this.paper, a = this.elements, c = a.volumeCanvas, e = b.marginTop + b.top, g = b.left = b.marginLeft, q = b.width -
                    b.marginLeft - b.marginRight, m = b.height - b.marginBottom, h = f(b.plotBorderRadius, 0), l = b.plotBorderWidth, p = b.plotBackgroundColor, k = .5 * l, n = b.plotBorderColor, t = this.layers.canvas;
                c || (a.volumeCanvas = d.rect(g - k, e - k - 1, q + l, m + l, h, t).attr({
                    fill: pa(p),
                    "stroke-width": l,
                    stroke: n,
                    "stroke-linejoin": 2 < l ? "round" : "miter",
                    "shape-rendering": "crisp"
                }).shadow(b.plotShadow).crisp())
            }
        }, drawTracker: function (b, d, a) {
            var c = this, e = c.paper, g = c.xAxis[0], q = b.data[a], m = c.yAxis[0].getAxisPosition(q.y), h = g.getAxisPosition(f(q.x, a));
            b =
                b.items[a];
            a = ia ? 40 : 20;
            var l = c.layers.tracker, p = c.definition.chart, k = c.options.plotOptions.series, n = g.getAxisPosition(0), g = g.getAxisPosition(1) - n, n = k.groupPadding, k = k.maxColWidth, t = ((1 - .01 * (p && p.plotspacepercent)) * g || H(g * (1 - 2 * n), 1 * k)) / 1, u = .5 * -t, p = c.elements, g = p.canvas.getBBox(), k = p.volumeCanvas && p.volumeCanvas.getBBox(), n = p.rollOverBand, E = b && b.tracker, t = {
                "stroke-width": t,
                ishot: !0,
                stroke: pa(c.options.chart.rollOverBandColor),
                fill: pa(c.options.chart.rollOverBandColor),
                visibility: "hidden"
            };
            k && E && !d.doNotUseBand &&
            (E || (E = b.tracker = e.circle(h, m, a, l).attr({
                "stroke-width": 0,
                fill: Aa
            })), E.data("x", h), q.toolText && E.tooltip(q.toolText), n || (n = p.rollOverBand = e.path(["M", 0, g.y, "L", 0, g.y2, "M", 0, k.y, "L", 0, k.y2]).attr(t), c.layers.dataset.appendChild(n), n.toBack()), E.mouseover(function () {
                c.rollOver(c, this, u)
            }).mouseout(function () {
                c.rollOut(c)
            }))
        }, rollOver: function (b, d) {
            b.elements.rollOverBand.transform("t" + d.data("x") + ",0").show()
        }, rollOut: function (b) {
            b.elements.rollOverBand.hide()
        }
    }, r["renderer.cartesian"]);
    r("renderer.spline",
        {
            getSplinePath: function (b, d, a) {
                var c = function (a, b, c, d) {
                    b = (d - b) / fc((c - a) * (c - a) + (d - b) * (d - b));
                    b = sa(.5 * b);
                    return a * b + c * (1 - b)
                }, e = function (a, b, c, d) {
                    var e = a.length, g = a[e - 1], f = g.length, k = g[0], g = g[f - 2];
                    3 > f || ("R" !== k && "C" !== k || 3 !== f || (a[e - 1][0] = "L"), b && a.push(["L", g, d, c, d, "Z"]))
                }, g = /area/ig.test(this.logic.defaultSeriesType), f = this.options.chart.minimizeTendency, m = [null], h = [], l = [], p = d.max, k = d.min;
                d = d.getAxisPosition(0 < p && 0 < k ? k : 0 > p && 0 > k ? p : 0);
                var n, t, u, E, D, x, y, M, w, r, B, F, P, Q, C;
                Q = 0;
                for (C = b.length; Q < C; Q += 1)if (M =
                        b[Q], u = b[Q - 1] || {}, E = b[Q + 1] || {}, k = M.x, p = M.y, n = u.x, u = u.y, D = E.x, E = E.y, x = M.lastYPos, y = M.lastXPos, r = h.length, M = l.length, f)if (null !== x)if (t = F, Q === b.length - 1) {
                    x = m[Q - B - 1];
                    M = D = (k + n) / 2;
                    r = (M - n) * x + u;
                    if (u > p && r < p || u < p && r > p)r = p, M = (r - u) / x + n;
                    F.push(M, r, D, (p + u) / 2, k, p);
                    h.push(F);
                    l.push(F);
                    g && e(h, !0, P, d);
                    g && e(l, !1)
                } else {
                    x = m[Q - B - 1];
                    if (u > p && E >= p || u < p && E <= p)if (w = 0, D = c(n, u, k, p), E = p, 1 !== Q - B) {
                        M = D;
                        r = (M - n) * x + u;
                        if (u > p && r < p || u < p && r > p)r = p, M = (r - u) / x + n;
                        F.push(M, r, D, E, k, p)
                    } else F.push((k + n) / 2, (p + u) / 2, D, E, k, p); else if (u === p)w = 0, F.push(n,
                        u, k, p, k, p); else if (u > p && p > E || u < p && p < E) {
                        w = (E - u) / (D - n);
                        D = c(n, u, k, p);
                        E = (D - k) * w + p;
                        if (u > p && E > u || u < p && E < u)E = u, D = (E - p) / w + k;
                        if (1 !== Q - B) {
                            M = D;
                            r = (M - n) * x + u;
                            if (u > p && r < p || u < p && r > p)r = p, M = (r - u) / x + n;
                            F.push(M, r, D, E, k, p)
                        } else F.push((k + n) / 2, (p + u) / 2, D, E, k, p)
                    }
                    m.push(w)
                } else null === x && 0 !== Q ? (t || (t = []), "C" === t[0] && (h.push(F), l.push(F), g && e(h, !0, P, d), g && e(l, !1)), h.push(["M", k, p]), l.push(["M", k, p]), P = k, F = ["C"], B = Q, m = [null]) : (h.push(["M", k, p]), l.push(["M", k, p]), P = k, F = ["C"], B = Q); else null !== x ? 2 <= r ? ("M" === h[r - 1][0] && h.push(["R"]),
                "M" === l[M - 1][0] && l.push(["R"]), r = h.length, M = l.length, t = h[r - 1], n = t.length, h[r - 1].push(k), h[r - 1].push(p), l[M - 1].push(k), l[M - 1].push(p), Q === a - 1 && "R" === t[0] && (e(h, !0, P, d), e(l, !1))) : (h.push(["M", y, x]), h.push(["R", k, p]), l.push(["M", y, x]), l.push(["R", k, p]), P = y) : null === x && 2 <= r && (t = h[r - 1], "R" === t[0] && (e(h, !0, P, d), e(l, !1)), h.push(["M", k, p]), l.push(["M", k, p]), P = k);
                t = h[h.length - 1];
                g && t && (n = t.length, "Z" === t[n - 1] || "R" !== t[0] && "C" !== t[0] || (e(h, !0, P, d), e(l, !1)));
                g || (h = f ? h : l, 2 <= h.length && e(h, !1));
                return {
                    closedPath: h,
                    openPath: l
                }
            }, drawPlotSpline: function (b, d) {
            var a = this, c = [], c = [], e = a.paper, g = a.elements, h = a.options, m = h.chart, v = h.plotOptions.series, l = v.dataLabels && v.dataLabels.style || {}, p = {
                    fontFamily: l.fontFamily,
                    fontSize: l.fontSize,
                    lineHeight: l.lineHeight,
                    fontWeight: l.fontWeight,
                    fontStyle: l.fontStyle
                }, k = b.items, n = b.graphics = b.graphics || [], t = a.xAxis[d.xAxis || 0], u = a.yAxis[d.yAxis || 0], E = b.data, l = [], D = [], x = !1 === d.visible, y = x ? "hidden" : "visible", w = isNaN(+v.animation) && v.animation.duration || 1E3 * v.animation, r = !1 !== (h.tooltip ||
                    {}).enabled, B = a.chartWidth, C = a.chartHeight, h = function () {
                    R.attr({"clip-rect": null});
                    ka.show();
                    ha.show();
                    ea.show();
                    K.attr({transform: "...t" + -B + "," + -C})
                }, F, P, Q = v.connectNullData, A, z, H, N, X, G, aa = null, L, J = d.lineWidth, S, O, U, T, ba, ca, Y, I = a.layers, V = I.dataset = I.dataset || e.group("dataset-orphan"), K = I.datalabels = I.datalabels || e.group("datalables"), I = I.tracker, m = m.anchorTrackingRadius, ha, ea, R, ka, ma, Z, va, fa = function (c, e, g, f, k, h, m) {
                    return function () {
                        (ma = g.dataLabel = a.drawPlotLineLabel(b, d, m, c, e)) && n.push(ma)
                    }
                },
                ja = function (b) {
                    Ca.call(this, a, b)
                }, ia = function (b, c) {
                    return function (d) {
                        a.hoverPlotAnchor(this, d, c, b, a)
                    }
                };
            a.addCSSDefinition(".fusioncharts-datalabels .fusioncharts-label", p);
            K.insertAfter(V);
            K.attr({"class": "fusioncharts-datalabels", transform: "...t" + B + "," + C});
            w && a.animationCompleteQueue.push({fn: h, scope: a});
            p = V.line || (V.line = e.group("line-connector", V));
            ha = e.group("connector-shadow", p);
            ea = e.group("anchor-shadow", p);
            R = e.group("connector", p);
            ka = e.group("anchors", p);
            ka.hide();
            ha.hide();
            ea.hide();
            F = 0;
            for (P = E.length; F < P; F += 1)if (A = E[F], H = A.y, T = Z = ma = null, null === H)0 === Q && (aa = null); else {
                p = k[F] = {chart: a, index: F, value: H};
                z = f(A.x, F);
                N = A.link;
                X = A.tooltext || A.toolText;
                L = u.getAxisPosition(H);
                z = t.getAxisPosition(z);
                c.push({x: z, y: L, lastXPos: G, lastYPos: aa});
                if ((S = A.marker) && S.enabled)if (O = S.radius, ca = S.shadow, U = S.symbol.split("_"), aa = {
                        index: F,
                        link: N,
                        value: H,
                        displayValue: A.displayValueArgs,
                        categoryLabel: A.categoryLabel,
                        toolText: A.toolText,
                        id: b.userID,
                        datasetIndex: b.index,
                        datasetName: b.name,
                        visible: b.visible
                    },
                        H = Y = {}, G = A.rolloverProperties, S.imageUrl)Z = new Va.Image, N = {
                    isTooltip: r,
                    setLink: N,
                    hotLayer: I
                }, Z.onload = a.onAnchorImageLoad(this, b, d, z, L, S, p, aa, X, G, F, ka, N), Z.onerror = fa(z, L, p, F), Z.src = S.imageUrl; else {
                    G && (H = {
                        polypath: [U[1] || 2, z, L, S.radius, S.startAngle, 0],
                        fill: pa(S.fillColor),
                        "stroke-width": S.lineWidth,
                        stroke: pa(S.lineColor)
                    }, Y = {
                        polypath: [G.sides || 2, z, L, G.radius, G.startAngle, G.dip],
                        fill: pa(G.fillColor),
                        "stroke-width": G.lineWidth,
                        stroke: pa(G.lineColor)
                    });
                    T = p.graphic = e.polypath(U[1] || 2, z, L, S.radius, S.startAngle,
                        0, ka).attr({
                        fill: pa(S.fillColor),
                        "stroke-width": S.lineWidth,
                        stroke: pa(S.lineColor),
                        cursor: N ? "pointer" : "",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        ishot: !0,
                        visibility: 0 === O ? "hidden" : y
                    }).data("alwaysInvisible", 0 === O).data("setRolloverProperties", G).data("setRolloverAttr", Y).data("setRolloutAttr", H).data("anchorRadius", O).data("anchorHoverRadius", G && G.radius).shadow(ca || !1, ea);
                    if (N || r || G)O = ab(O, G && G.radius || 0, m), Z = e.polypath(U[1] || 2, z, L, O, S.startAngle, 0, I).attr({
                        cursor: N ? "pointer" : "", stroke: Aa,
                        "stroke-width": 0, ishot: !0, fill: Aa, visibility: y
                    });
                    va = Z || T;
                    va.click(ja);
                    (Z || T).data("eventArgs", aa).hover(ia(p, "DataPlotRollOver"), ia(p, "DataPlotRollOut")).tooltip(X)
                }
                T && n.push(T);
                va && n.push(va);
                G = z;
                aa = L;
                X = A.color;
                N = A.dashStyle;
                D.push(T);
                p.dataLabel = ma;
                p.tracker = va;
                S && S.imageUrl || (ma = a.drawPlotLineLabel(b, d, F, z, L));
                ma && n.push(ma);
                a.drawTracker && a.drawTracker.call(a, b, d, F)
            }
            c = a.getSplinePath(c, u).closedPath;
            2 <= c.length && (ba = b.graphic = e.path(c, R).attr({
                "stroke-dasharray": N, "stroke-width": J, stroke: pa(X),
                "stroke-linecap": "round", "stroke-linejoin": "round", visibility: y
            }).shadow(v.shadow && A.shadow, ha), l.push(ba), V.shadow(v.shadow || A.shadow));
            w ? R.attr({"clip-rect": g["clip-canvas-init"]}).animate({"clip-rect": g["clip-canvas"]}, w, "normal", a.getAnimationCompleteFn()) : (h && h(), h = void 0);
            ba && n.push(ba);
            b.visible = !x;
            return b
        }, drawPlotAreaspline: function (b, d) {
            var a = this, c = [], c = [], e = a.paper, g = a.layers, h = a.options, m = h.chart, v = a.elements, l = h.plotOptions.series, p = l.dataLabels && l.dataLabels.style || {}, k = {
                    fontFamily: p.fontFamily,
                    fontSize: p.fontSize, lineHeight: p.lineHeight, fontWeight: p.fontWeight, fontStyle: p.fontStyle
                }, n = a.xAxis[d.xAxis || 0], t = a.yAxis[d.yAxis || 0], u = b.data, E = (p = !1 === d.visible) ? "hidden" : "visible", D = isNaN(+l.animation) && l.animation.duration || 1E3 * l.animation, x = "0" === a.definition.chart.drawfullareaborder, h = !1 !== (h.tooltip || {}).enabled, y, w, r, B, C = b.items, F = b.graphics = b.graphics || [], P = null, Q, A = g.tracker, z = g.dataset = g.dataset || e.group("dataset-orphan"), H = g.datalabels = g.datalabels || e.group("datalabels").insertAfter(z),
                G = m.anchorTrackingRadius, X = a.chartWidth, N = a.chartHeight, g = function () {
                    S.attr({"clip-rect": null});
                    J.show();
                    L.show();
                    H.attr({transform: "...t" + -X + "," + -N})
                }, aa, L, J, S, O, U, T = [], ba, ca, Y, I, V, K, ha, ea, R, ka, T = function (c, e, g, f) {
                    return function () {
                        (I = g.dataLabel = a.drawPlotLineLabel(b, d, f, c, e)) && F.push(I)
                    }
                }, Z = function (b) {
                    Ca.call(this, a, b)
                }, fa = function (b, c) {
                    return function (d) {
                        a.hoverPlotAnchor(this, d, c, b, a)
                    }
                };
            S = z.area = z.area || e.group("area", z);
            aa = z.line || (z.line = e.group("line-connector", z));
            e.group("connector-shadow",
                aa);
            L = e.group("anchor-shadow", aa);
            m = e.group("area-connector", aa);
            J = e.group("area-anchors", aa);
            J.hide();
            L.hide();
            a.addCSSDefinition(".fusioncharts-datalabels .fusioncharts-label", k);
            H.insertAfter(z);
            H.attr({"class": "fusioncharts-datalabels", transform: "...t" + X + "," + N});
            D && a.animationCompleteQueue.push({fn: g, scope: a});
            z = 0;
            for (aa = u.length; z < aa; z += 1)if (y = u[z], r = y.y, ca = Y = I = null, null === r)0 === l.connectNullData && (P = null); else {
                R = C[z] = {chart: a, index: z, value: r};
                w = f(y.x, z);
                B = y.link;
                k = y.tooltext || y.toolText;
                w = n.getAxisPosition(w);
                r = t.getAxisPosition(r);
                c.push({x: w, y: r, lastXPos: Q, lastYPos: P});
                if ((Q = y.marker) && Q.enabled)if (ka = {
                        index: z,
                        link: B,
                        value: y.y,
                        displayValue: y.displayValueArgs,
                        categoryLabel: y.categoryLabel,
                        toolText: k,
                        id: b.userID,
                        datasetIndex: b.index,
                        datasetName: b.name,
                        visible: b.visible
                    }, U = Q.radius, ea = Q.shadow, ba = Q.symbol.split("_"), K = ha = {}, P = y.rolloverProperties, Q.imageUrl)Y = new Va.Image, B = {
                    isTooltip: h,
                    setLink: B,
                    hotLayer: A
                }, Y.onload = a.onAnchorImageLoad(this, b, d, w, r, Q, R, ka, k, P, z, J, B), Y.onerror = T(w, r, R, z), Y.src = Q.imageUrl;
                else {
                    if (P = y.rolloverProperties)K = {
                        polypath: [ba[1] || 2, w, r, U, Q.startAngle, 0],
                        fill: pa(Q.fillColor),
                        "stroke-width": Q.lineWidth,
                        stroke: pa(Q.lineColor)
                    }, ha = {
                        polypath: [P.sides || 2, w, r, P.radius, P.startAngle, P.dip],
                        fill: pa(P.fillColor),
                        "stroke-width": P.lineWidth,
                        stroke: pa(P.lineColor)
                    };
                    ca = R.graphic = e.polypath(ba[1] || 2, w, r, U, Q.startAngle, 0, J).attr({
                        fill: pa(Q.fillColor),
                        "stroke-width": Q.lineWidth,
                        stroke: pa(Q.lineColor),
                        "stroke-linecap": "round",
                        cursor: B ? "pointer" : "",
                        ishot: !0,
                        visibility: 0 === U ? "hidden" : E
                    }).data("alwaysInvisible",
                        0 === U).data("setRolloverProperties", P).data("setRolloverAttr", ha).data("setRolloutAttr", K).data("anchorRadius", U).data("anchorHoverRadius", P && P.radius).shadow(ea || !1, L);
                    if (B || h || P)U = ab(U, P && P.radius || 0, G), Y = e.polypath(ba[1] || 2, w, r, U, Q.startAngle, 0, A).attr({
                        cursor: B ? "pointer" : "",
                        stroke: Aa,
                        "stroke-width": 0,
                        ishot: !0,
                        fill: Aa,
                        visibility: E
                    });
                    (Y || ca).data("eventArgs", ka).click(Z).hover(fa(R, "DataPlotRollOver"), fa(R, "DataPlotRollOut")).tooltip(k)
                }
                ca && F.push(ca);
                a.drawTracker && a.drawTracker.call(a, b, d, z);
                R.graphic = ca;
                R.dataLabel = I;
                R.tracker = void 0;
                Q && Q.imageUrl || (I = a.drawPlotLineLabel(b, d, z, w, r));
                I && F.push(I);
                a.drawTracker && a.drawTracker.call(a, b, d, z);
                Q = w;
                P = r
            }
            n = a.getSplinePath(c, t, aa);
            c = n.closedPath;
            T = n.openPath;
            2 <= c.length && (c = e.path(c, S).attr({
                fill: pa(d.fillColor),
                "stroke-dasharray": d.dashStyle,
                "stroke-width": x ? 0 : d.lineWidth,
                stroke: pa(d.lineColor),
                "stroke-linecap": "round",
                visibility: E
            }).shadow(l.shadow && y.shadow), b.graphic = c, F.push(c));
            D ? V = S.attr({"clip-rect": v["clip-canvas-init"]}).animate({"clip-rect": v["clip-canvas"]},
                D, "normal", a.getAnimationCompleteFn()) : (g && g(), g = void 0);
            x && (2 <= T.length && (O = e.path(T, m).attr({
                stroke: pa(d.lineColor),
                "stroke-width": d.lineWidth,
                "stroke-dasharray": y.dashStyle || d.dashStyle,
                "stroke-linecap": "round",
                visibility: E
            }).shadow(l.shadow || y.shadow)), F.push(O), D && m.attr({"clip-rect": v["clip-canvas-init"]}).animateWith(S, V, {"clip-rect": v["clip-canvas"]}, D, "normal"));
            b.visible = !p;
            return b
        }
        }, r["renderer.cartesian"]);
    r("renderer.kagi", {
        drawPlotKagi: function (b, d) {
            var a = this, c = a.paper, e = a.options,
                g = a.elements, f = b.data, m = e.plotOptions.series, v = a.xAxis[d.xAxis || 0], l = a.yAxis[d.yAxis || 0], p = d.canvasPadding, k = d.xShiftLength, n = b.items, t = a.logic, u = !1 === d.visible ? "hidden" : "visible", E = !1 !== (e.tooltip || {}).enabled, D = {
                    stroke: pa({
                        color: d.rallyColor,
                        alpha: d.rallyAlpha
                    }),
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": d.rallyThickness || d.lineWidth,
                    "stroke-dasharray": d.rallyDashed
                }, x = {
                    stroke: pa({color: d.declineColor, alpha: d.declineAlpha}),
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": d.declineThickness || d.lineWidth,
                    "stroke-dasharray": d.declineDashed
                }, y = {
                    "true": D["stroke-width"],
                    "false": x["stroke-width"]
                }, w = a.layers, r = w.dataset = w.dataset || c.group("dataset-orphan"), B = w.datalabels = w.datalabels || c.group("datalabels").insertAfter(r), z = w.tracker, w = isNaN(+m.animation) && m.animation.duration || 1E3 * m.animation, F = g["clip-canvas-init"].slice(0), g = g["clip-canvas"].slice(0), C = 0, A = v.getAxisPosition(C), H = a.chartWidth, G = a.chartHeight, O = function () {
                    la.attr({"clip-rect": null});
                    va.show();
                    qa.show();
                    B.attr({transform: "...t" + -H + "," + -G})
                }, I = [], X = [], V, aa, L, J, S, K, U, T, ba, ca, Y, R, ea, Z, ha, fa, ja, ka, ma = e.chart.anchorTrackingRadius, ia, va, la, qa, ua, da, ra, ta, pb, Eb, sa, oa;
            if (f.length) {
                e = r.line || (r.line = c.group("line-connector", r));
                c.group("connector-shadow", e);
                va = c.group("anchor-shadow", e);
                la = c.group("connector", e);
                qa = c.group("anchors", e);
                qa.hide();
                va.hide();
                B.attr({transform: "...t" + H + "," + G});
                w && a.animationCompleteQueue.push({fn: O, scope: this});
                U = !!f[0].isRally;
                e = 0;
                for (r = f.length; e < r; e += 1)n[e] = {
                    chart: a,
                    index: e, graphic: null, line: [], dataLabel: null, tracker: null
                }, T = f[e], ba = T.y, T.isDefined || (ba = T.plotValue), ba = h(T.plotValue, ba), T.plotY = qb(l.getAxisPosition(T.y), 2), T.graphY = qb(l.getAxisPosition(ba), 2), T.plotX = A, T.isShift && (C += 1, A = v.getAxisPosition(C)), e && (ba = f[e - 1], U = T && T.objParams && T.objParams.isRally, ea = T && T.objParams && T.objParams.lastHigh, Z = T && T.objParams && T.objParams.lastLow, ha = T && T.objParams && T.objParams.isRallyInitialised, ba && ha && ba.isRally !== T.isRally ? (T.isChanged = !0, T.ty = qb(l.getAxisPosition(U ?
                    ea : Z), 2)) : T.isChanged = !1);
                v = a.canvasLeft + p;
                A = v + k / 2;
                ca = f[0].plotY;
                U = !!f[0].isRally;
                l = N(ca) + y[U] % 2 / 2;
                U ? I.push("M", v, l, "H", A) : X.push("M", v, l, "H", A);
                ib(f, function (e, g) {
                    if (fa = f[g + 1])ka = ["M", A, ca], U = e.isRally, e.isShift && (A += k, ca = e.graphY, ka.push("H", A), ka[2] = N(ka[2]) + y[U] % 2 / 2, ka = ka.toString(), U ? I.push(ka) : X.push(ka), ka = ["M", A, ca]), fa.isChanged && (ca = fa.ty, ka.push("V", ca), ka[1] = N(ka[1]) + y[U] % 2 / 2, ka = ka.toString(), U ? I.push(ka) : X.push(ka), ka = ["M", A, ca]), ja = fa.isRally, fa.graphY !== ka[2] && (ka.push("V", fa.graphY),
                        ka[1] = N(ka[1]) + y[ja] % 2 / 2, ka = ka.toString(), ja ? I.push(ka) : X.push(ka)), ca = fa.graphY;
                    Y = e.plotX;
                    R = e.plotY;
                    J = e.marker;
                    V = e && e.link;
                    aa = e && e.toolText;
                    if (void 0 !== R && !isNaN(R) && e.isDefined)if (S = J.symbol.split("_"), pb = "spoke" === S[0] ? 1 : 0, Eb = J.radius, ua = J.shadow, ia = {
                            index: g,
                            link: V,
                            value: e.y,
                            displayValue: e.displayValueArgs,
                            categoryLabel: e.categoryLabel,
                            toolText: aa,
                            id: b.userID,
                            datasetIndex: b.index,
                            datasetName: b.name,
                            visible: b.visible
                        }, da = ra = {}, ta = e.rolloverProperties, J.imageUrl)sa = new Va.Image, sa = new Va.Image, oa =
                    {
                        isTooltip: E,
                        setLink: V,
                        hotLayer: z
                    }, sa.onload = a.onAnchorImageLoad(a, b, d, Y, R, J, n[g], ia, aa, ta, g, qa, oa), sa.onerror = function (c, e, g, f, k, h, m, q) {
                        return function () {
                            f.dataLabel = a.drawPlotKagiLabel(b, d, q, c, e)
                        }
                    }(Y, R, J, n[g], ia, aa, ta, g), sa.src = J.imageUrl; else {
                        !t.multisetRealtime && ta && (da = {
                            polypath: [S[1] || 2, Y, R, Eb, J.startAngle, pb],
                            fill: pa(J.fillColor),
                            "stroke-width": J.lineWidth,
                            stroke: pa(J.lineColor)
                        }, ra = {
                            polypath: [ta.sides || 2, Y, R, ta.radius, ta.startAngle, ta.dip],
                            fill: pa(ta.fillColor),
                            "stroke-width": ta.lineWidth,
                            stroke: pa(ta.lineColor)
                        });
                        K = n[g].graphic = c.polypath(S[1] || 2, Y, R, Eb, J.startAngle, pb, qa).attr({
                            fill: pa(J.fillColor),
                            "stroke-width": J.lineWidth,
                            stroke: pa(J.lineColor),
                            "stroke-linecap": "round",
                            cursor: V ? "pointer" : "",
                            ishot: !0,
                            visibility: 0 === Eb ? "hidden" : u
                        }).data("alwaysInvisible", 0 === Eb).data("setRolloverProperties", ta).data("setRolloverAttr", ra).data("setRolloutAttr", da).data("anchorRadius", Eb).data("anchorHoverRadius", ta && ta.radius).shadow(ua || !1, va);
                        if (V || E)Eb = ab(Eb, ta && ta.radius || 0, ma), L = c.circle(Y,
                            R, Eb, z).attr({
                            cursor: V ? "pointer" : "",
                            stroke: Aa,
                            ishot: !0,
                            fill: Aa,
                            "stroke-width": J.lineWidth,
                            visibility: u
                        }).data("eventArgs", ia).click(function (b) {
                            Ca.call(this, a, b)
                        }).hover(function (b) {
                            return function (c) {
                                a.hoverPlotAnchor(this, c, "DataPlotRollOver", b, a)
                            }
                        }(n[g]), function (b) {
                            return function (c) {
                                a.hoverPlotAnchor(this, c, "DataPlotRollOut", b, a)
                            }
                        }(n[g])).tooltip(aa);
                        n[g].tracker = L || K;
                        J && J.imageUrl || (n[g].dataLabel = a.drawPlotKagiLabel(b, d, g, Y, R))
                    }
                });
                D = c.path(I, la).attr(D).shadow(m.shadow);
                n[0].line.push(D);
                D =
                    c.path(X, la).attr(x).shadow(m.shadow);
                n[0].line.push(D);
                w ? la.attr({"clip-rect": F}).animate({"clip-rect": g}, w, "normal", a.getAnimationCompleteFn()) : (O && O(), O = void 0)
            }
        }, drawPlotKagiLabel: function (b, d, a, c, e, g) {
            var f = this.options, h = f.chart, v = this.paper, l = this.layers, p = f.plotOptions.series.dataLabels.style, f = 1 === h.rotateValues ? 270 : 0, k = this.canvasHeight, n = this.canvasTop, t = this.canvasLeft, u = b.data[a], E = b.items[a];
            b = (b = E.graphic) && "image" == b.type && .5 * b.attr("height") || u.marker && u.marker.radius - 3;
            b = h.valuePadding +
                2 + b;
            d = !1 === d.visible ? "hidden" : "visible";
            a = E.dataLabel;
            var w = {
                fontFamily: p.fontFamily,
                fontSize: p.fontSize,
                lineHeight: p.lineHeight,
                fontWeight: p.fontWeight,
                fontStyle: p.fontStyle
            }, x, y, r;
            g = g || l.datalabels;
            l = u.displayValue;
            ea(l) && l !== B ? (a ? f && a.rotate(360 - f) : a = E.dataLabel = v.text(g).attr({
                text: l,
                fill: p.color,
                direction: h.textDirection,
                "text-bound": [p.backgroundColor, p.borderColor, p.borderThickness, p.borderPadding, p.borderRadius, p.borderDash]
            }).css(w), a.attr({fill: p.color}).tooltip(u.originalText), v = a.getBBox(),
                g = l = f ? v.width : v.height, h = e, g = g + b + 4, l = .5 * l + b, f ? (t = !0, u.vAlign === Ka ? (h -= l, t = e - n < g) : u.vAlign === Ga && (h += l - 2, x = 1, t = e + g > n + k), t && (y = 1, c -= b + 3 + .5 * v.height, h = e)) : u.vAlign === Ka ? h -= l : u.vAlign === Ga ? (h += l, x = 1) : v.width > c - t ? h -= l : (y = 1, c -= b + 3, r = "end"), a.attr({
                x: c,
                y: h,
                "text-anchor": r,
                visibility: d
            }).data("isBelow", x).data("isMiddle", y), f && a.attr("transform", "T0,0,R" + f)) : a && a.attr({text: B});
            return a
        }
    }, r["renderer.cartesian"]);
    r("renderer.boxandwhisker", {
        drawPlotBoxandwhisker2d: function (b, d) {
            var a = this, c = a.paper, e = a.options,
                g = e.plotOptions.series, h = a.xAxis[d.xAxis || 0], m = a.yAxis[d.xAxis || 0], v = isNaN(+g.animation) && g.animation.duration || 1E3 * g.animation, l = a.layers, p = l.dataset = l.dataset || c.group("dataset-orphan"), k = l.datalabels = l.datalabels || c.group("datalabels"), n = d.data, t = b.items || (b.items = []), u = !1 === d.visible ? "hidden" : "visible", E = !1 !== (e.tooltip || {}).enabled, w = d.columnPosition || 0, x = a.definition.chart, y = h.getAxisPosition(0), r = h.getAxisPosition(1) - y, z = g.groupPadding, A = g.maxColWidth, y = d.numColumns || 1, r = (1 - .01 * (x && x.plotspacepercent)) *
                    r || H(r * (1 - 2 * z), A * y), x = r / y, w = w * x - r / 2, y = e.chart, r = 1 === y.rotateValues ? 270 : void 0, z = f(y.valuePadding, 0), A = p.upperBoxGroup = p.upperBoxGroup || c.group("upperBox", p), C = p.lowerBoxGroup = p.lowerBoxGroup || c.group("lowerBox", p), F = p.medianGroup = p.medianGroup || c.group("median", p), P = b.graphics = b.graphics || [], Q = t.displayValues = {}, G = Q.upperQuartileValues = [], O = Q.lowerQuartileValues = [], Q = Q.medianValues = [], I = function (b) {
                    Ca.call(this, a, b)
                }, l = l.shadows || (l.shadows = c.group("shadows", p).toBack()), e = e.plotOptions.series.dataLabels.style,
                V = {
                    fontFamily: e.fontFamily,
                    fontSize: e.fontSize,
                    lineHeight: e.lineHeight,
                    fontWeight: e.fontWeight,
                    fontStyle: e.fontStyle
                }, X = function (b, c) {
                    return function (d) {
                        b.upperBox.attr(c.upperBox);
                        b.lowerBox.attr(c.lowerBox);
                        b.upperBoxBorder.attr(c.upperBoxBorder);
                        b.lowerBoxBorder.attr(c.lowerBoxBorder);
                        b.upperQuartile.attr(c.upperQuartile);
                        b.lowerQuartile.attr(c.lowerQuartile);
                        b.medianBorder.attr(c.median);
                        Ca.call(this, a, d, "DataPlotRollOver")
                    }
                }, K = function (b, c) {
                    return function (d) {
                        b.upperBox.attr(c.upperBox);
                        b.lowerBox.attr(c.lowerBox);
                        b.upperBoxBorder.attr(c.upperBoxBorder);
                        b.lowerBoxBorder.attr(c.lowerBoxBorder);
                        b.upperQuartile.attr(c.upperQuartile);
                        b.lowerQuartile.attr(c.lowerQuartile);
                        b.medianBorder.attr(c.median);
                        Ca.call(this, a, d, "DataPlotRollOut")
                    }
                }, aa, L, J, S, R, U, T, ba, ca, Y, Z, fa, ia, ha, ja, la, ka, ma, qa, va, ua, da, ra, sa, oa, ta;
            ma = 0;
            for (qa = n.length; ma < qa; ma += 1)L = n[ma], J = L.y, R = L.link, U = L.tooltext || L.toolText, (aa = t[ma]) || (aa = t[ma] = {
                index: ma,
                value: J,
                upperBox: null,
                lowerBox: null,
                upperBoxBorder: null,
                lowerBoxBorder: null,
                upperQuartileBorder: null,
                lowerQuartileBorder: null,
                medianBorder: null,
                upperQuartileValues: null,
                lowerQuartileValues: null,
                medianValues: null,
                tracker: null,
                hot: null
            }), null !== J && (m.getAxisPosition(J), J = f(L.x, ma), J = h.getAxisPosition(J), w && (J += w), S = g.borderRadius || 0, ba = ((ba = (ia = L.upperQuartile || {}, ia.value)) || 0 === ba) && m.getAxisPosition(ba), T = ((T = (ha = L.lowerQuartile || {}, ha.value)) || 0 === T) && m.getAxisPosition(T), Y = ((ca = (ka = L.median) && ka.value) || 0 === ca) && m.getAxisPosition(ca), Z = Y - ba, fa = T - Y, ja = L.upperBoxBorder || {}, la = L.lowerBoxBorder ||
                {}, ca = {
                index: ma,
                link: R,
                maximum: L.displayValueMax,
                minimum: L.displayValueMin,
                median: ca,
                q3: ia.value,
                q1: ha.value,
                maxDisplayValue: L.displayValueMax,
                minDisplayValue: L.displayValueMin,
                medianDisplayValue: L.displayValueMid,
                q1DisplayValue: L.displayValueQ1,
                q3DisplayValue: L.displayValueQ3,
                categoryLabel: L.categoryLabel,
                toolText: L.toolText,
                id: b.userID,
                datasetIndex: b.index,
                datasetName: b.name,
                visible: b.visible
            }, va = N(J) + ja.borderWidth % 2 * .5, ua = N(J + x) + ja.borderWidth % 2 * .5, da = N(ba) + ia.borderWidth % 2 * .5, x = ua - va, ta = L.hoverEffects.rollOut =
            {
                upperBox: {
                    fill: pa(L.color.FCcolor),
                    "stroke-width": 0,
                    "stroke-dasharray": ja.dashStyle,
                    cursor: R ? "pointer" : "",
                    ishot: !0,
                    visibility: u
                },
                lowerBox: {
                    fill: pa(L.lowerboxColor.FCcolor),
                    "stroke-width": 0,
                    "stroke-dasharray": la.dashStyle,
                    cursor: R ? "pointer" : B,
                    ishot: !0,
                    visibility: u
                },
                upperBoxBorder: {
                    stroke: ja.color,
                    "stroke-width": ja.borderWidth,
                    "stroke-linecap": "round",
                    dashstyle: ja.dashStyle,
                    ishot: !0,
                    visibility: u
                },
                lowerBoxBorder: {
                    stroke: la.color,
                    "stroke-width": la.borderWidth,
                    dashstyle: la.dashStyle,
                    "stroke-linecap": "round",
                    ishot: !0,
                    visibility: u
                },
                upperQuartile: {
                    stroke: pa(ia.color),
                    "stroke-width": ia.borderWidth,
                    "stroke-dasharray": ia.dashSyle,
                    "stroke-linecap": "round",
                    cursor: R ? "pointer" : B,
                    ishot: !0,
                    visibility: u
                },
                lowerQuartile: {
                    stroke: pa(ha.color),
                    "stroke-width": ha.borderWidth,
                    "stroke-dasharray": ha.dashSyle,
                    cursor: R ? "pointer" : "",
                    "stroke-linecap": "round",
                    ishot: !0,
                    visibility: u
                },
                median: {
                    stroke: pa(ka.color),
                    "stroke-width": ka.borderWidth,
                    "stroke-dasharray": ka.dashSyle,
                    cursor: R ? "pointer" : "",
                    "stroke-linecap": "round",
                    ishot: !0,
                    visibility: u
                }
            },
                R = aa.graphic = aa.upperBox = c.rect(va, da, x, Z, S, A).attr(ta.upperBox).shadow(g.shadow && L.shadow, l), Z = aa.upperBoxBorder = c.path(["M", va, da, "V", da + Z, "M", ua, da, "V", da + Z], A).attr(ta.upperBoxBorder).shadow(g.shadow && ja.shadow, l), ja = aa.upperQuartile = c.path(["M", va, da, "H", va + x], F).attr(ta.upperQuartile).shadow(g.shadow && ia.shadow, l), va = N(J) + la.borderWidth % 2 * .5, ua = N(J + x) + la.borderWidth % 2 * .5, da = N(Y + fa) + ha.borderWidth % 2 * .5, S = aa.lowerBox = c.rect(va, Y, x, da - Y, S, C).attr(ta.lowerBox).shadow(g.shadow && L.shadow, l), la = aa.lowerBoxBorder =
                c.path(["M", va, Y, "V", Y + fa, "M", ua, Y, "V", Y + fa], C).attr(ta.lowerBoxBorder).shadow(g.shadow && la.shadow, l), da = N(Y + fa) + ha.borderWidth % 2 * .5, fa = aa.lowerQuartile = c.path(["M", va, da, "H", va + x], F).attr(ta.lowerQuartile).shadow(g.shadow && ia.shadow, l), da = N(Y) + ka.borderWidth % 2 * .5, va = aa.medianBorder = c.path(["M", va, da, "H", va + x], F).attr(ta.median), da = b.index + "_" + ma, R.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs", ca), S.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs",
                ca), Z.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs", ca), la.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs", ca), ja.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs", ca), fa.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs", ca), va.click(I).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).data("eventArgs", ca), ca = r ? Ha : Ya, ea(ia.displayValue) && ia.displayValue !== B &&
            (ra = G[ma] = c.text(k).attr({
                text: ia.displayValue,
                x: J + x / 2,
                title: ia.originalText || "",
                y: ba - z,
                "text-anchor": r ? "start" : ca,
                "vertical-align": r ? "middle" : "bottom",
                visibility: u,
                direction: y.textDirection,
                fill: e.color,
                "text-bound": [e.backgroundColor, e.borderColor, e.borderThickness, e.borderPadding, e.borderRadius, e.borderDash]
            }).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).css(V), r && ra.rotate(r, J + x / 2, ba - z)), ea(ka.displayValue) && ka.displayValue !== B && (oa = Q[ma] = c.text(k).attr({
                text: ka.displayValue,
                x: J + x /
                2,
                y: Y - z,
                title: ka.originalText || "",
                "text-anchor": r ? "start" : ca,
                "vertical-align": r ? "middle" : "bottom",
                visibility: u,
                direction: y.textDirection,
                fill: e.color,
                "text-bound": [e.backgroundColor, e.borderColor, e.borderThickness, e.borderPadding, e.borderRadius, e.borderDash]
            }).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).css(V), r && oa.rotate(r, J + x / 2, Y - z)), ea(ha.displayValue) && ha.displayValue !== B && (sa = O[ma] = c.text(k).attr({
                text: ha.displayValue,
                x: J + x / 2,
                y: T + z,
                title: ha.originalText || "",
                "text-anchor": r ? "start" :
                    ca,
                "vertical-align": r ? "middle" : "top",
                visibility: u,
                direction: y.textDirection,
                fill: e.color,
                "text-bound": [e.backgroundColor, e.borderColor, e.borderThickness, e.borderPadding, e.borderRadius, e.borderDash]
            }).hover(X(aa, L.hoverEffects), K(aa, ta)).data("groupId", da).css(V), r && sa.rotate(r, J + x / 2, T + z)), E && (R.tooltip(U), S.tooltip(U), Z.tooltip(U), la.tooltip(U), ja.tooltip(U), fa.tooltip(U), va.tooltip(U), ra && ra.tooltip(U), oa && oa.tooltip(U), sa && sa.tooltip(U)), R && P.push(R), S && P.push(S), va && P.push(va), Z && P.push(Z), la &&
            P.push(la), ja && P.push(ja), fa && P.push(fa), ra && P.push(ra), oa && P.push(oa), sa && P.push(sa));
            p.attr({"clip-rect": [a.canvasLeft, a.canvasTop, v ? 0 : a.canvasWidth, a.canvasHeight]});
            v && p.animate({"clip-rect": [a.canvasLeft, a.canvasTop, a.canvasWidth, a.canvasHeight]}, v, "normal");
            b.visible = !1 !== d.visible
        }
    }, r["renderer.cartesian"]);
    r("renderer.dragnode", {
        drawPlotDragnode: function (b, d) {
            var a = this, c = b.graphics = [], e = {}, g = a.options, q = g.tooltip, m = g._FCconf.inCanvasStyle, v = a.paper, l = a.layers, p = b.items, k = l.dataset, n = l.connector,
                t = e.xAxis = a.xAxis[d.xAxis || 0], u = e.yAxis = a.yAxis[d.yAxis || 0], r = d.data, D = e.elements = {data: []}, x = a.smartLabel, y = g.plotOptions.series.dataLabels.style, M = g.orphanStyles.connectorlabels.style, z = g.connectors, A = g.connectorsStore, C = g.pointStore || (g.pointStore = []), F = g.invalConnectStore, P = {
                    fontFamily: y.fontFamily,
                    fontSize: y.fontSize,
                    lineHeight: y.lineHeight,
                    fontWeight: y.fontWeight,
                    fontStyle: y.fontStyle
                }, Q = function (b) {
                    C[b.from] && C[b.to] ? A.push(new nb(b, C, M, v, n, a)) : F.push(b)
                }, H = function () {
                    var b = this;
                    b.data("fire_click_event",
                        1);
                    clearTimeout(b._longpressactive);
                    b._longpressactive = setTimeout(function () {
                        b.data("fire_click_event", 0);
                        b.data("viewMode") || a.logic.showLabelDeleteUI(a, b)
                    }, 1E3)
                }, O = function () {
                    this.data("fire_click_event") && (this.data("fire_click_event", 0), la.call(this))
                }, N = function (b) {
                    var c = this.data("fire_click_event");
                    la.call(this);
                    c && Ca.call(this, a, b, "LabelClick")
                }, R = function (b) {
                    Ca.call(this, a, b, "LabelRollover")
                }, X = function (b) {
                    Ca.call(this, a, b, "LabelRollout")
                }, V = function (b, c, d, e, g) {
                    d = this.data("data");
                    e = d.bBox;
                    var f = a.canvasTop + a.canvasHeight, h = a.canvasLeft + a.canvasWidth;
                    this.data("fire_dragend") || (Ca.call(this, a, g, "LabelDragStart"), this.data("fire_dragend", 1));
                    e.x + b < a.canvasLeft && (b = a.canvasLeft - e.x);
                    e.x2 + b > h && (b = h - e.x2);
                    e.y + c < a.canvasTop && (c = a.canvasTop - e.y);
                    e.y2 + c > f && (c = f - e.y2);
                    this.attr({x: e.x + b, y: e.y + c});
                    d.label.attr({x: d.ox + b, y: d.oy + c})
                }, aa = function () {
                    var a = this.data("data"), b = this.getBBox();
                    a.ox = a.label.attr("x");
                    a.oy = a.label.attr("y");
                    a.bBox = b;
                    this.data("fire_dragend", 0)
                }, L = function (b) {
                    var c = this.data("data"),
                        d = c.label, e = {hcJSON: {dragableLabels: []}}, g = this.data("eventArgs"), f = g.x = a.xAxis[0].getAxisPosition(d.attr("x"), 1), d = g.y = a.yAxis[0].getAxisPosition(d.attr("y"), 1);
                    e.hcJSON.dragableLabels[c.labelNode.index] = {y: d, x: f};
                    I(a.logic.chartInstance.jsVars._reflowData, e, !0);
                    this.data("fire_dragend") && (c = Mb(a.container, b), c.sourceEvent = "labeldragend", w.raiseEvent("chartupdated", I(c, g), a.logic.chartInstance), Ca.call(this, a, b, "labeldragend"))
                }, J, S, K, U, T, ba, ca, Y, Z, fa, da, ha, ia, ja, ka, ma, qa, va, ra, sa, oa, ya, xa, ta;
            n ||
            (n = l.connector = v.group("connectors").insertBefore(k));
            q && !1 !== q.enabled && n.trackTooltip(!0);
            l = D.group = v.group(k);
            q = D.dragLabelGroup = v.group(k);
            x.setStyle(y);
            k = 0;
            for (D = r.length; k < D; k += 1) {
                J = r[k];
                U = J.marker;
                J._yPos = K = u.getAxisPosition(J.y);
                J._xPos = S = t.getAxisPosition(J.x);
                ba = p[k] || (p[k] = {});
                ta = J.hoverEffects && J.hoverEffects.rolloverProperties;
                U = ba.graphic;
                ca = ba.image;
                Y = ba.label;
                U = J.marker;
                if (void 0 !== K && !isNaN(K) && U) {
                    da = J._config = J._config || {shapeArg: {}, startConnectors: [], endConnectors: []};
                    ha = da.shapeArg;
                    ia = f(U && U.height);
                    ja = f(U && U.width);
                    ka = f(U && U.radius);
                    T = h(U && U.symbol);
                    ma = "rectangle" === T;
                    Z = J.id;
                    qa = J.imageNode;
                    va = J.imageURL;
                    ra = J.imageAlign;
                    sa = J.labelAlign;
                    oa = ma ? ja : 1.4 * ka;
                    ya = f(J.imageWidth, oa);
                    ma = ma ? ia : 1.4 * ka;
                    xa = f(J.imageHeight, ma);
                    ka = {fill: pa(U.fillColor), "stroke-width": U.lineWidth, r: U.radius, stroke: pa(U.lineColor)};
                    T = ha.symbol = h(U && U.symbol, e.symbol);
                    T = T.split("_");
                    ha.x = S;
                    ha.y = K;
                    ha.radius = U.radius;
                    ha.width = ja;
                    ha.height = ia;
                    ha.sides = T[1];
                    "poly" === T[0] || "circle" === T[0] ? U = v.polypath(T[1], S, K, U.radius,
                        U.startAngle, 0, l).attr(ka) : (da.shapeType = ic, ha.x = S - ja / 2, ha.y = K - ia / 2, ha.r = 0, ka.width = ja, ka.height = ia, ka.x = S - ja / 2, ka.y = K - ia / 2, ta && J.hoverEffects.enabled && (ta.x = S - ta.width / 2, ta.y = K - ta.height / 2, delete ta.r), delete ka.r, U = v.rect(ha.x, ha.y, ja, ia, 0, l).attr(ka));
                    if (qa && va) {
                        xa > ma && (xa = ma);
                        ya > oa && (ya = oa);
                        switch (ra) {
                            case "middle":
                                ta = K - xa / 2;
                                break;
                            case "bottom":
                                ta = ma > xa ? K + ma / 2 - xa : K - xa / 2;
                                break;
                            default:
                                ta = ma > xa ? K - .5 * ma : K - xa / 2
                        }
                        da.imageX = S - ya / 2;
                        da.imageY = ta;
                        ca || (ca = v.image(l));
                        ca.attr({
                            src: va, x: da.imageX, y: ta, width: ya,
                            height: xa
                        })
                    }
                    da = J.displayValue;
                    if (ea(da) || da !== B) {
                        oa = x.getSmartText(da, oa, ma);
                        da = .5 * ma - .5 * oa.height;
                        switch (sa) {
                            case "top":
                                da = -da;
                                break;
                            case "bottom":
                                break;
                            default:
                                da = 0
                        }
                        J._yAdjustment = sa = da;
                        K += sa;
                        Y ? Y.attr({
                            text: oa.text,
                            title: oa.tooltext || "",
                            fill: y.color,
                            x: S,
                            y: K
                        }) : (Y = v.text(l), Y.attr({
                            text: oa.text,
                            fill: y.color,
                            x: S,
                            y: K,
                            direction: g.chart.textDirection,
                            "text-bound": [y.backgroundColor, y.borderColor, y.borderThickness, y.borderPadding, y.borderRadius, y.borderDash]
                        }).css(P))
                    }
                    C[Z] = J;
                    ba.index = k;
                    ba.graphic = U;
                    ba.label =
                        Y;
                    ba.image = ca;
                    Z = a.drawTracker && a.drawTracker.call(a, b, d, k, ka);
                    U && c.push(U);
                    Y && c.push(Y);
                    ca && c.push(ca);
                    Z && c.push(Z)
                }
                ba.index = k;
                ba.tracker = Z
            }
            if (A)for (k = F.length - 1; 0 <= k; --k)c = F[k], C[c.from] && C[c.to] && (F.splice(k, 1), A.push(new nb(c, C, M, v, n, a))); else for (A = g.connectorsStore = [], F = g.invalConnectStore = [], k = 0; k < z.length; k += 1)ib(z[k].connector, Q);
            if (!a.dragLabelsDrawn && (fa = g.dragableLabels) && 0 < (D = fa.length)) {
                oa = a.plotSizeX;
                ma = a.plotSizeY;
                c = parseInt(m.fontSize, 10);
                p = m.backgroundColor;
                r = m.borderColor;
                for (k =
                         0; k < D; k += 1)if (x = fa[k], x.index = k, P = G(h(x.text, x.label)))P = G(P), Q = t.getAxisPosition(x.x || 0), K = u.getAxisPosition(x.y || 0, 0, 1, 0, 1), J = f(x.fontsize, c), S = Xa(h(x.color, m.color)), l = f(x.alpha, 100) / 100, y = f(x.allowdrag, 1), sa = .8 * J, z = f(x.padding, 5), ca = {
                    fontSize: J + "px",
                    fontFamily: m.fontFamily,
                    fill: S,
                    color: S,
                    opacity: l
                }, Ja(ca), S = h(x.bgcolor, p), ba = h(x.bordercolor, r), J = {
                    link: x.link,
                    text: P,
                    x: Q,
                    y: K,
                    allowdrag: y,
                    sourceType: "labelnode"
                }, S && (ca.backgroundColor = S.replace(hb, Oa), ca.backgroundOpacity = l), ba && (ca.borderColor = ba.replace(hb,
                    Oa), ca.borderOpacity = l), P = v.text(q).css(ca).attr({
                    text: P,
                    x: Q,
                    y: K,
                    align: Ya,
                    direction: g.chart.textDirection,
                    "text-bound": [(x.bgcolor || "").replace(hb, Oa), (x.bordercolor || "").replace(hb, Oa), f(x.borderthickness, 1), z, f(x.radius, 0), f(x.dashed, 0) ? ua(f(x.dashlen, 5), f(x.dashgap, 4), f(x.borderthickness, 1)) : "none"]
                }), Q = P.getBBox(), z = v.rect(Q.x - z, Q.y - z, Q.width + 2 * z, Q.height + 2 * z, 0).attr({
                    fill: Aa,
                    ishot: !0,
                    "stroke-width": 0
                }).css({cursor: y ? "move" : ""}).mousedown(H).mousemove(O).mouseup(N).data("viewMode", g.chart.viewMode).hover(R,
                    X), q.appendChild(z), z.data("data", {
                    label: P,
                    labelNode: x,
                    chart: a
                }).data("eventArgs", J).data("link", x.link), y && z.drag(V, aa, L);
                a.dragLabelsDrawn = !0
            }
            return e
        }, drawTracker: function (b, d, a, c) {
            var e = this, g = e.paper, f = b.data[a], m = b.items[a], v = f._config, l = e.layers.tracker, p = cb({}, v.pointAttr), k = v.shapeArg, n = k.x, t = k.y, u = k.width, r = k.height, w = k.radius, x = e.dragStart, y = e.dragUp, z = e.dragMove, B = f.link ? "pointer" : f.allowDrag ? "move" : "", A = m.tracker;
            p.fill = Aa;
            p.stroke = Aa;
            p.cursor = B;
            p.ishot = !0;
            A = "rect" === v.shapeType ? g.rect(n,
                t, u, r, 0).attr(p) : g.polypath(k.sides, n, t, w, k.startAngle).attr(p);
            g = {
                index: a,
                link: f.link,
                y: f.y,
                x: f.x,
                shape: h(f._options.shape, "rect"),
                width: u,
                height: r,
                radius: w,
                sides: k.sides,
                label: f.displayValue,
                toolText: f.toolText,
                id: f.id,
                datasetIndex: b.index,
                datasetName: b.name,
                sourceType: "dataplot"
            };
            m.tracker = A.hover(function (a, b) {
                return function (c) {
                    a.graphic.attr(b);
                    Ca.call(this, e, c, "DataPlotRollOver")
                }
            }(m, f.hoverEffects.rolloverProperties), function (a, b) {
                return function (c) {
                    a.graphic.attr(b);
                    Ca.call(this, e, c, "DataPlotRollOut")
                }
            }(m,
                c)).data("eventArgs", g).data("drag-options", {
                plotItems: m,
                dataObj: f,
                endConnectors: v.endConnectors,
                startConnectors: v.startConnectors,
                boundaryTop: e.canvasTop,
                boundaryBottom: e.canvasTop + e.canvasHeight,
                boundaryLeft: e.canvasLeft,
                boundaryRight: e.canvasLeft + e.canvasWidth,
                cloneGroup: e.layers.dataset,
                datasetIndex: b.index,
                pointIndex: a,
                dataOptions: d,
                cursor: B,
                chart: e,
                link: f.link
            }).tooltip(f.toolText);
            l.appendChild(A);
            A.drag(function (a, b, c, d, g) {
                z.call(this, a, b, c, d, g, e)
            }, function (a, b, c) {
                x.call(this, a, b, c, e)
            }, function (a) {
                y.call(this,
                    a, e)
            });
            return A
        }, dragStart: function (b, d, a, c) {
            var e = this;
            b = e.paper;
            d = e.data("drag-options") || {};
            var g = d.dataObj, f = d.plotItems, h = f.cloneGroup, v = f.cloneGraphic, l = f.cloneImage, p = f.cloneLabel, k = e.getBBox(), n = ia && Pa(a) || mb, t = a.layerX || n.layerX, u = a.layerY || n.layerY, r = w.getPosition(c.container), D = c.elements, x = D.waitElement, y = d.dataOptions, z = c.layers.tracker, A = {opacity: .3};
            e.data("fire_click_event", 1);
            e.data("mousedown", 1);
            void 0 === t && (t = (a.pageX || n.pageX) - r.left, u = (a.pageY || n.pageY) - r.top);
            clearTimeout(e._longpressactive);
            e.data("move", !0);
            c.options.chart.viewMode || (x || (x = D.waitElement = b.ringpath(t, u, 8, 11, 0, 0, z).attr({
                fill: pa({
                    alpha: "100,100",
                    angle: 120,
                    color: "CCCCCC,FFFFFF",
                    ratio: "30,50"
                }), "stroke-width": 0
            })), t += 11, u -= 21, x.attr({ringpath: [t, u, 8, 11, 0, 0]}).show().animate({ringpath: [t, u, 8, 11, 0, 6.28]}, 1E3), e._longpressactive = setTimeout(function () {
                var a = y.name !== B && void 0 !== y.name ? y.name + Ba + " " : B, b = y.id, d = g._options, f = {
                    circle: "circ",
                    polygon: "poly",
                    undefined: "rect"
                }[d.shape];
                D.waitElement && D.waitElement.hide();
                e.data("fire_click_event",
                    0);
                c.logic.showNodeUpdateUI(c, {
                    x: {value: g.x},
                    y: {value: g.y},
                    draggable: {value: K(d.allowdrag, 1)},
                    color: {value: d.color},
                    alpha: {value: d.alpha},
                    label: {value: K(d.label, d.name)},
                    tooltip: {value: d.tooltext},
                    shape: {value: f},
                    rectWidth: {value: d.width},
                    rectHeight: {value: d.height},
                    circPolyRadius: {value: d.radius},
                    polySides: {value: d.numsides},
                    image: {value: d.imagenode},
                    imgWidth: {value: d.imagewidth},
                    imgHeight: {value: d.imageheight},
                    imgAlign: {value: d.imagealign},
                    imgUrl: {value: d.imageurl},
                    id: {value: g.id, disabled: !0},
                    link: {value: d.link},
                    dataset: {innerHTML: '<option value="' + b + '">' + a + b + "</option>", disabled: !0}
                }, !0)
            }, 1E3));
            d.bBoxX = k.x;
            d.bBoxX2 = k.x2 || k.x + k.width;
            d.bBoxY = k.y;
            d.bBoxY2 = k.y2 || k.y + k.height;
            d.origX = d.lastX || (d.lastX = 0);
            d.origY = d.lastY || (d.lastY = 0);
            d.draged = !1;
            d.startYValue = g.y;
            d.startXValue = g.x;
            h || (h = f.cloneGroup = b.group(d.cloneGroup).attr(A));
            f.graphic && !v && (v = f.cloneGraphic = f.graphic.clone(), h.appendChild(v), v.attr(A));
            f.image && !l && (l = f.cloneImage = f.image.clone(), h.appendChild(l).attr(A));
            f.label && !p && (p = f.cloneLabel = f.label.clone(),
                h.appendChild(p).attr(A));
            h.show()
        }, dragMove: function (b, d, a, c, e, g) {
            a = this.data("drag-options");
            c = a.plotItems;
            var f = a.bBoxX2 + b, h = a.bBoxY + d, v = a.bBoxY2 + d, l = g.elements;
            a.bBoxX + b < a.boundaryLeft && (b = a.boundaryLeft - a.bBoxX);
            f > a.boundaryRight && (b = a.boundaryRight - a.bBoxX2);
            h < a.boundaryTop && (d = a.boundaryTop - a.bBoxY);
            v > a.boundaryBottom && (d = a.boundaryBottom - a.bBoxY2);
            if (b || d)l.waitElement && l.waitElement.hide(), this.data("fire_click_event", 0), la.call(this);
            a.dataObj.allowDrag && (f = a._transformObj = {
                transform: "t" +
                (a.origX + b) + "," + (a.origY + d)
            }, this.attr(f), c.cloneGraphic && c.cloneGraphic.attr(f), c.cloneImage && c.cloneImage.attr(f), c.cloneLabel && c.cloneLabel.attr(f), a.draged || Ca.call(this, g, e, "DataplotDragStart"), a.draged = !0, a.lastX = b, a.lastY = d)
        }, dragUp: function (b) {
            var d = this.data("drag-options"), a = d.plotItems, c = d.chart, e = c.xAxis[0], g = c.yAxis[0], f = c.logic, h = f.tooltipSepChar, v = f.numberFormatter, l = d.dataObj, p = c.elements, k = this.data("fire_click_event"), n, t, u;
            p.waitElement && p.waitElement.hide();
            la.call(this);
            this.data("mousedown",
                0);
            k && Ca.call(this, c, b);
            if (d.draged) {
                d.lastX += d.origX;
                d.lastY += d.origY;
                k = l._xPos + d.lastX;
                p = l._yPos + d.lastY;
                n = d.startConnectors;
                t = n.length;
                for (u = 0; u < t; u += 1)n[u].updateFromPos(k, p);
                n = d.endConnectors;
                t = n.length;
                for (u = 0; u < t; u += 1)n[u].updateToPos(k, p);
                a.label && a.label.attr(d._transformObj);
                a.image && a.image.attr(d._transformObj);
                a.graphic && a.graphic.attr(d._transformObj);
                e = e.getAxisPosition(k, 1);
                g = g.getAxisPosition(p, 1);
                l._isUserTooltip || l.toolText === B || (l.toolText = l._toolTextStr + v.dataLabels(e) + h + v.dataLabels(g));
                v = this.data("eventArgs");
                l.x = v.x = e;
                l.y = v.y = g;
                h = Mb(c.container, b);
                h.sourceEvent = "dataplotdragend";
                w.raiseEvent("chartupdated", I(h, v), c.logic.chartInstance);
                Ca.call(this, c, b, "dataplotdragend");
                b = {hcJSON: {series: []}};
                b.hcJSON.series[d.datasetIndex] = {data: []};
                b.hcJSON.series[d.datasetIndex].data[d.pointIndex] = {
                    _options: {x: e, y: g},
                    x: e,
                    y: g,
                    toolText: l.toolText,
                    displayValue: l.displayValue
                };
                I(f.chartInstance.jsVars._reflowData, b, !0)
            }
            a.cloneGroup && a.cloneGroup.hide()
        }
    }, r["renderer.cartesian"]);
    r("renderer.dragcolumn2d",
        {
            drawTracker: function (b, d, a) {
                var c = this.paper, e = this.yAxis[0], g = b.data[a], f = e.getAxisPosition(g.y), h = b.items[a], v = this.layers.tracker, l = h && h.dragTracker || null, p = this.dragStart, k = this.dragUp, n = this.dragMove, t = {
                    stroke: Aa,
                    "stroke-width": ia ? 40 : 10,
                    ishot: !0,
                    cursor: Ta && "ns-resize" || "n-resize"
                }, u = e && e.axisData && e.axisData.plotLines, r = this._yAxisPlotLines || (this._yAxisPlotLines = []), w = 0, x, y;
                if (!r.length)for (x = u.length; w < x; w += 1)y = u[w], y.isGrid && r.push(e.getAxisPosition(y.value));
                null !== g.y && g.allowDrag && (e =
                    h.graphic.getBBox(), e = ["M", e.x, f, "L", e.x + e.width, f, "Z"], l ? l.animate({d: e}).attr(t) : l = h.dragTracker = c.path(e, v).attr(t), l.drag(n, p, k).data("drag-options", {
                    items: h,
                    yPos: f,
                    chart: this,
                    datasetIndex: b.index,
                    pointIndex: a,
                    dataOptions: d,
                    dataObj: g
                }), h.dragTracker = l)
            }, dragStart: function () {
            var b = this.data("drag-options"), d = b.chart, a = d.yAxis[0], c = a.max, a = a.min, e = this.getBBox();
            b.barH = b.items.graphic.getBBox().height;
            b.isAllPositive = 0 < c && 0 < a;
            b.isAllPositiveZero = 0 < c && 0 <= a;
            b.isAllNegative = 0 > c && 0 > a;
            b.isAllNegativeZero =
                0 >= c && 0 > a;
            b.isPositiveNegative = 0 < c && 0 > a;
            b.boundaryTop = d.canvasTop;
            b.boundaryBottom = d.canvasTop + d.canvasHeight;
            b.bBoxY = e.y;
            b.bBoxY2 = e.y2 || e.y + e.height;
            b.startValue = b.dataObj.y;
            b.origX = b.lastX || (b.lastX = 0);
            b.origY = b.lastY || (b.lastY = 0);
            b.draged = !1
        }, dragMove: function (b, d) {
            var a = this.data("drag-options"), c = a.items, e = a.dataObj, g = a.chart, f = g.options.chart, h = g.yAxis[0], v = g.logic.numberFormatter, l = h.yBasePos, p = c.dataLabel, k = {}, n = a.bBoxY2 + d, t = a.bBoxY + d, u = g.canvasBottom, r = e.allowNegDrag ? u : l, w = g.canvasTop, x =
                parseFloat(e.borderWidth) || 0, f = f.isCanvasBorder, y = a.isAllNegativeZero, z = a.isPositiveNegative, B = a.dataOptions;
            t < a.boundaryTop && (d = a.boundaryTop - a.bBoxY);
            n > r && (d = r - a.bBoxY2);
            t = a._transformObj = {transform: "t0," + (a.origY + d)};
            a.draged || (n = {
                dataIndex: a.pointIndex + 1,
                datasetIndex: B.__i + 1,
                startValue: a.startValue,
                datasetName: B.name
            }, O.raiseEvent("dataplotDragStart", n, g.logic.chartInstance));
            n = a.yPos + d;
            n <= l ? (k.y = n, k.height = l - n) : (k.y = l, k.height = n - l);
            f && !z && (y ? k.y -= k.y - (w - x / 2) : k.height = u - k.y + x / 2);
            this.attr(t);
            c.graphic.animate(k);
            a.shapeAttr = k;
            c = a.value = N(1E8 * h.getAxisPosition(n, 1)) / 1E8;
            v = v.dataLabels(c);
            ra.pointUpdate(e, v, c);
            p && g.drawPlotColumnLabel(g.plots[a.datasetIndex], a.dataOptions, a.pointIndex, void 0, n).attr("text", a.dataObj.displayValue);
            a.draged = !0;
            a.lastX = b;
            a.lastY = d
        }, dragUp: function () {
            var b = this.data("drag-options"), d = b.chart, a = d.logic, c = !d.options.chart.doNotSnap, e = b.dataObj, g = b.dataOptions, f, h;
            b.draged && (f = b.yPos + b.lastY, c && (h = ra.snapPoint(d, e, f), h - f && d.dragMove.call(this, 0, h - b.yPos)), b.yPos =
                h, b.lastX += b.origX, b.lastY += b.origY, c = {
                dataIndex: b.pointIndex + 1,
                datasetIndex: g.__i + 1,
                startValue: b.startValue,
                endValue: b.dataObj.y = b.value,
                datasetName: g.name
            }, g = [d.logic.chartInstance.id, c.dataIndex, c.datasetIndex, c.datsetName, c.startValue, c.endValue], O.raiseEvent("dataplotDragEnd", c, d.logic.chartInstance), w.raiseEvent("chartupdated", c, d.logic.chartInstance, g), c = {hcJSON: {series: []}}, c.hcJSON.series[b.datasetIndex] = {data: []}, b.items.tracker.attr(b.shapeAttr).tooltip(e.toolText), c.hcJSON.series[b.datasetIndex].data[b.pointIndex] =
            {
                y: b.value,
                toolText: e.toolText,
                displayValue: e.displayValue
            }, ra.setMinMaxValue(d), I(a.chartInstance.jsVars._reflowData, c, !0))
        }
        }, r["renderer.cartesian"]);
    r("renderer.dragline", {
        drawTracker: function (b, d, a) {
            var c = this.paper, e = this.yAxis[0], g = this.xAxis[0], f = b.data[a], h = b.items[a], v = ia ? 20 : ab(f.marker && f.marker.radius || 0, 5), l = this.layers.tracker, p = h.tracker || null, k = this.dragStart, n = this.dragUp, t = this.dragMove, u = {
                    fill: Aa,
                    "stroke-width": 0,
                    cursor: Ta && "ns-resize" || "n-resize"
                }, r = e && e.axisData && e.axisData.plotLines,
                w = this._yAxisPlotLines || (this._yAxisPlotLines = []), x = 0, y, z;
            if (!w.length)for (y = r.length; x < y; x += 1)z = r[x], z.isGrid && w.push(e.getAxisPosition(z.value));
            null !== f.y && f.allowDrag && (g = g.getAxisPosition(a), e = e.getAxisPosition(f.y), p || (p = h.tracker = c.circle(g, e, v, l).attr(u)), p.attr({
                cursor: Ta && "ns-resize" || "n-resize",
                ishot: !0
            }).drag(t, k, n).data("drag-options", {
                items: b.items,
                yPos: e,
                chart: this,
                datasetIndex: b.index,
                pointIndex: a,
                dataOptions: d,
                dataObj: f
            }))
        }, dragStart: function () {
            var b = this.data("drag-options"), d = b.items,
                a = b.pointIndex, c = d[a + 1], d = d[a], c = b.nextGraph = c && c.connector, d = b.currGraph = d && d.connector, a = b.chart;
            b._origY = b._lastY || (b._lastY = 0);
            b.boundaryTop = a.canvasTop;
            b.boundaryBottom = a.canvasTop + a.canvasHeight;
            b.currPath = d && d.attr("path");
            b.nextPath = c && c.attr("path");
            b.startValue = b.dataObj.y;
            b.origY = this.attr("cy");
            b.origX = this.attr("cx");
            b.draged = !1
        }, dragMove: function (b, d) {
            var a = this.data("drag-options"), c = a.items[a.pointIndex], e = a.nextPath, g = a.currPath, f = a.dataObj, h = a.chart, v = h.elements.plots[a.datasetIndex],
                l = h.yAxis[0], p = h.logic.numberFormatter, k = l.yBasePos, n = c.dataLabel, t = f.allowNegDrag ? a.boundaryBottom : k, u = a.dataOptions, k = a.origY + d;
            a.draged || (u = {
                dataIndex: a.pointIndex + 1,
                datasetIndex: u.__i + 1,
                startValue: a.startValue,
                datasetName: u.name
            }, O.raiseEvent("dataplotDragStart", u, h.logic.chartInstance));
            k < a.boundaryTop && (d = a.boundaryTop - a.origY);
            k > t && (d = t - a.origY);
            k = a.origY + d;
            this.animate({cy: k});
            c.graphic && c.graphic.attr("transform", "t0," + (a._origY + d));
            c.graphicImage && c.graphicImage.attr("transform", "t0," + (a._origY +
                d));
            e && e[0] && a.nextGraph && (Ta ? e[0][2] = k : e[2] = k, a.nextGraph.animate({path: e}));
            g && g[1] && a.currGraph && (Ta ? g[1][2] = k : g[5] = k, a.currGraph.animate({path: g}));
            c = f.y = a.value = N(1E8 * l.getAxisPosition(k, 1)) / 1E8;
            p = p.dataLabels(c);
            ra.pointUpdate(f, p, c);
            n && h.drawPlotLineLabel(h.plots[a.datasetIndex], a.dataOptions, a.pointIndex, a.origX, k).attr("text", f.displayValue);
            a.draged = !0;
            a.lastY = d;
            h.getAreaPath && v.graphic && v.graphic.attr({path: h.getAreaPath(v.data)})
        }, dragUp: function () {
            var b = this.data("drag-options"), d = b.chart,
                a = d.logic, c = !d.options.chart.doNotSnap, e = b.dataObj, g = b.dataOptions, f, h;
            b.draged && (f = b.yPos + b.lastY, c && (h = ra.snapPoint(d, e, f), h - f && d.dragMove.call(this, 0, h - b.yPos)), b.yPos = h, b._lastY = b.lastY + b._origY, b.lastY += b.origY, g = {
                dataIndex: b.pointIndex + 1,
                datasetIndex: g.__i + 1,
                startValue: b.startValue,
                endValue: b.dataObj.y = b.value,
                datasetName: g.name
            }, c = [d.logic.chartInstance.id, g.dataIndex, g.datasetIndex, g.datasetName, g.startValue, g.endValue], O.raiseEvent("dataplotDragEnd", g, d.logic.chartInstance), w.raiseEvent("chartupdated",
                g, d.logic.chartInstance, c), c = {hcJSON: {series: []}}, c.hcJSON.series[b.datasetIndex] = {data: []}, c.hcJSON.series[b.datasetIndex].data[b.pointIndex] = {
                y: b.value,
                toolText: e.toolText,
                displayValue: e.displayValue
            }, b.items[b.pointIndex].tracker.tooltip(e.toolText), ra.setMinMaxValue(d), I(a.chartInstance.jsVars._reflowData, c, !0))
        }
    }, r["renderer.cartesian"]);
    r("renderer.dragarea", {
        getAreaPath: function (b) {
            for (var d = this.xAxis[0], a = this.yAxis[0], c = a.yBasePos, e = b.length, g = 0, f = [], h = [], v = [], l = !0, p, k, n; g < e; g += 1)k = b[g],
                v[g] = d.getAxisPosition(g), h[g] = null, null !== k.y && (h[g] = a.getAxisPosition(k.y), n = b[g - 1] ? b[g - 1].y : null, k = b[g + 1] ? b[g + 1].y : null, null !== n ? (l ? (f.push("M", v[g - 1], c, "L", v[g - 1], h[g - 1], "L", v[g], h[g]), p = g - 1) : f.push("L", v[g], h[g]), null === k && f.push("L", v[g], c, "L", v[p], c), l = !1) : l = !0);
            return f
        }
    }, r["renderer.dragline"]);
    r("renderer.heatmap", {
        drawPlotHeatmap: function (b, d) {
            var a = this, c = b.data, e = b.items, g = b.graphics = b.graphics || [], h = a.paper, m = a.layers, v = a.options, l = v.chart, p = l.showHoverEffect, k = !1 !== (v.tooltip || {}).enabled,
                n = v.plotOptions.series, v = a.xAxis[d.xAxis || 0], t = a.yAxis[d.yAxis || 0], n = isNaN(+n.animation) && n.animation.duration || 1E3 * n.animation, u = !1 === d.visible ? "hidden" : "visible", r, w, x = v.getAxisPosition(0), y = v.getAxisPosition(1), z = t.getAxisPosition(0), B = t.getAxisPosition(1), x = y - x, z = z - B, l = f(l.useRoundEdges, 0), B = d.borderColor, y = d.borderWidth, A = d.dashStyle, C = x / 2, F = z / 2, P = m.dataset = m.dataset || h.group("dataset-orphan"), Q = m.datalabels = m.datalabels || h.group("datalables").insertAfter(P), m = m.tracker, H = a.chartWidth, G = a.chartHeight,
                O = function (b) {
                    Ca.call(this, a, b)
                }, N = function (b) {
                    Ca.call(this, a, b, "DataPlotRollOver")
                }, X = function (b) {
                    Ca.call(this, a, b, "DataPlotRollOut")
                }, K = function (a, b) {
                    return function () {
                        a.attr({fill: pa(b)})
                    }
                }, I, L, J, S, R, U, T, V, ca;
            n && (Q.attr({transform: "t" + H + "," + G}), a.animationCompleteQueue.push({
                fn: function () {
                    Q.attr({transform: "t0,0"})
                }, scope: this
            }));
            H = 0;
            for (G = c.length; H < G; H++) {
                S = c[H];
                L = S.y;
                I = null;
                if (null !== L) {
                    R = S.link;
                    U = S.toolText || S.tooltext;
                    I = pa(S.setColor || S.color);
                    w = (r = S.visible) && !1 === r ? "hiddden" : u;
                    T = f(S.x, H);
                    T = v.getAxisPosition(T) - C;
                    ca = t.getAxisPosition(L);
                    V = ca + F;
                    L = {
                        link: R,
                        value: S.value,
                        columnId: S.columnId,
                        rowId: S.rowId,
                        displayValue: S.displayValueArgs,
                        tlLabel: S.tlLabel,
                        trLabel: S.trLabel,
                        blLabel: S.blLabel,
                        brLabel: S.brLabel,
                        toolText: U,
                        id: b.userID,
                        datasetIndex: b.index,
                        datasetName: b.name,
                        visible: b.visible
                    };
                    I = h.rect(T, ca, x, z, l, P).attr({
                        fill: I,
                        stroke: B,
                        "stroke-width": y,
                        "stroke-dasharray": A,
                        "stroke-linejoin": "miter",
                        "shape-rendering": 0 === l ? "crisp" : "",
                        cursor: R ? "pointer" : "",
                        opacity: n ? 0 : S.setAlpha && +S.setAlpha /
                        100 || 1
                    }).crisp().attr({visibility: w});
                    n && I.animate({opacity: S.setAlpha && +S.setAlpha / 100 || 1}, n, "normal", a.getAnimationCompleteFn());
                    if (p || k || R)J = h.rect(T, ca, x, z, l, m).attr({
                        cursor: R ? "pointer" : "",
                        stroke: Aa,
                        "stroke-width": y,
                        fill: Aa,
                        ishot: !0
                    }).data("eventArgs", L);
                    (J || I).click(O).hover(N, X).tooltip(U);
                    1 === p && I && J && J.hover(K(I, S.hoverColor), K(I, S.setColor || S.color));
                    e[H] = {
                        index: H,
                        value: S.value,
                        graphic: I,
                        tracker: J,
                        dataLabel: null,
                        dataLabels: [],
                        visible: r || "hidden" !== w
                    };
                    r = a.drawLabelHeatmap.call(a, b, d, H);
                    I &&
                    g.push(I);
                    J && g.push(J);
                    w = 0;
                    for (S = r.length; w < S; w++)!e[H].dataLabels && (e[H].dataLabels = []), r[w] && g.push(r[w]), e[H].dataLabels.push(r[w])
                }
                a.drawTracker && a.drawTracker.call(a, b, H, T, V)
            }
            b.visible = !1 !== d.visible;
            return b
        }, drawLabelHeatmap: function (b, d, a) {
            var c = b.items[a], e = b.data[a], g = this.options;
            b = this.paper;
            a = this.layers.datalabels;
            var f = g.plotOptions.series.dataLabels, h = f.style;
            d = !1 === d.visible ? "hidden" : Bb;
            var v = e.displayValue, g = g.chart.textDirection, l = e.tlLabel, p = e.trLabel, k = e.blLabel, e = e.brLabel, n =
                f.tlLabelStyle, t = f.trLabelStyle, u = f.blLabelStyle, f = f.brLabelStyle, r = {
                fontFamily: n.fontFamily,
                fontSize: n.fontSize,
                lineHeight: n.lineHeight,
                fontWeight: n.fontWeight,
                fontStyle: n.fontStyle
            }, w = {
                fontFamily: t.fontFamily,
                fontSize: t.fontSize,
                lineHeight: t.lineHeight,
                fontWeight: t.fontWeight,
                fontStyle: t.fontStyle
            }, x = {
                fontFamily: u.fontFamily,
                fontSize: u.fontSize,
                lineHeight: u.lineHeight,
                fontWeight: u.fontWeight,
                fontStyle: u.fontStyle
            }, y = {
                fontFamily: f.fontFamily, fontSize: f.fontSize, lineHeight: f.lineHeight, fontWeight: f.fontWeight,
                fontStyle: f.fontStyle
            }, z = c.tlLabel, A = c.trLabel, C = c.blLabel, H = c.brLabel, F = this.smartLabel, P = c.dataLabel, Q = [], G = {
                fontFamily: h.fontFamily,
                fontSize: h.fontSize,
                lineHeight: h.lineHeight,
                fontWeight: h.fontWeight,
                fontStyle: h.fontStyle
            }, I, O, N, X, K, R, L, J;
            X = c.graphic.getBBox();
            I = X.width;
            O = X.height;
            N = X.x;
            X = X.y;
            F.setStyle(h);
            ea(v) && v !== B && (L = F.getSmartText(v, I, O, !1), v = L.text, P || (P = c.dataLabel = b.text(a)), P.attr({
                text: v,
                title: L.tooltext || "",
                visibility: d,
                fill: h.color,
                direction: g,
                x: N + .5 * I,
                y: X + .5 * O,
                "text-bound": [h.backgroundColor,
                    h.borderColor, h.borderThickness, h.borderPadding, h.borderRadius, h.borderDash]
            }).css(G), Q.push(P));
            v = ea(l) && l !== B;
            G = ea(p) && p !== B;
            K = ea(k) && k !== B;
            R = ea(e) && e !== B;
            h = I * (v && G ? .5 : .9);
            P = .5 * (O - (L && L.height || 0));
            J = X + 4;
            v && (F.setStyle(n), L = F.getSmartText(l, h, P, !1), v = L.text, l = N, z || (z = c.tlLabel = b.text(a)), z.attr({
                text: v,
                title: L.tooltext || "",
                visibility: d,
                fill: n.color,
                "text-anchor": "start",
                "vertical-align": Ka,
                direction: g,
                x: l + 4,
                y: J,
                "text-bound": [n.backgroundColor, n.borderColor, n.borderThickness, n.borderPadding, n.borderRadius,
                    n.borderDash]
            }).css(r), a.appendChild(z), Q.push(z));
            G && (F.setStyle(t), L = F.getSmartText(p, h, P, !1), v = L.text, l = N + I, A || (A = c.trLabel = b.text(a)), A.attr({
                text: v,
                title: L.tooltext || "",
                visibility: d,
                fill: t.color,
                "text-anchor": "end",
                "vertical-align": Ka,
                direction: g,
                x: l - 4,
                y: J,
                "text-bound": [t.backgroundColor, t.borderColor, t.borderThickness, t.borderPadding, t.borderRadius, t.borderDash]
            }).css(w), a.appendChild(A), Q.push(A));
            J = X + O - 4;
            K && (F.setStyle(u), L = F.getSmartText(k, h, P, !1), v = L.text, l = N, C || (C = c.blLabel = b.text(a)),
                C.attr({
                    text: v,
                    title: L.tooltext || "",
                    visibility: d,
                    fill: u.color,
                    "text-anchor": "start",
                    "vertical-align": Ga,
                    direction: g,
                    x: l + 4,
                    y: J,
                    "text-bound": [u.backgroundColor, u.borderColor, u.borderThickness, u.borderPadding, u.borderRadius, u.borderDash]
                }).css(x), Q.push(C));
            R && (F.setStyle(u), L = F.getSmartText(e, h, P, !1), v = L.text, l = N + I - 4, H || (H = c.brLabel = b.text(a)), H.attr({
                text: v,
                title: L.tooltext || "",
                visibility: d,
                fill: f.color,
                "text-anchor": "end",
                "vertical-align": Ga,
                direction: g,
                x: l,
                y: J,
                "text-bound": [f.backgroundColor, f.borderColor,
                    f.borderThickness, f.borderPadding, f.borderRadius, f.borderDash]
            }).css(y), a.appendChild(H), Q.push(H));
            return Q
        }, setScaleRange: function (b, d) {
            var a = this.logic, c = this.plots[0], e = {visibility: "visible"}, f = {visibility: "hidden"}, h = {hcJSON: {series: [{}]}}, m = h.hcJSON.series[0], v = m.data || (m.data = []), l = a.chartInstance.jsVars._reflowData, p = c.items, k, n, t, u, r, w, x, y = function (a) {
                a.attr(w)
            };
            setTimeout(function () {
                var a, c;
                a = 0;
                for (c = p.length; a < c; a++)k = p[a], n = k.value, t = k.graphic, r = v[a] || (v[a] = {}), u = k.dataLabels, w = (x = n >=
                    b && n <= d) ? e : f, t.attr(w), ib(u, y), r.visible = x;
                I(l, h, !0)
            }, 100)
        }
    }, r["renderer.cartesian"]);
    r("renderer.radar", {
        createRadarAxis: function () {
            var b = this.options, d = this.canvasLeft + this.canvasWidth / 2, a = this.canvasTop + this.canvasHeight / 2, c = b.xAxis, e = b.yAxis instanceof Array ? b.yAxis[0] : b.yAxis, f = c.max - c.min + 1, h = sa(e.max - e.min), b = ea(b.chart.axisRadius) ? b.chart.axisRadius : H(d, a), m, v = Ra.PI / 2, l = {};
            0 > b && (b = H(d, a));
            m = 2 * Ra.PI / f;
            l.yTrans = b / h;
            l.xTrans = m;
            l.yRange = h;
            l.startAngle = v;
            l.yMin = e.min;
            l.centerX = d;
            l.centerY = a;
            l.radius =
                b;
            l.categories = [];
            l.catLength = f;
            l.yAxis = e;
            l.xAxis = c;
            return this.radarAxis = l
        }, drawRadarAxis: function () {
            var b = this.radarAxis, d = b.catLength, a = b.xAxis, c = b.yAxis, e = c.min, g = c.plotLines, q = g.length, m = a.plotLines, v = b.xTrans, l = b.yTrans, p = b.radius, k = b.startAngle, n = this.canvasLeft + this.canvasWidth / 2, t = this.canvasTop + this.canvasHeight / 2, u = this.paper, r = this.layers, w = r.dataset = r.dataset || u.group("orphan-dataset").trackTooltip(!0), x = r.layerBelowDataset = r.layerBelowDataset || u.group("axisbottom").trackTooltip(!0),
                y = r.layerAboveDataset = r.layerAboveDataset || u.group("axistop").trackTooltip(!0), z = r.axisLines = r.axisLines || u.group("axis-lines", x), B = r.axisLabels = r.axisLabels || u.group("axis-labels", x), A = c.labels, c = 2 * Ra.PI, C = Ra.PI / 2, F = Ra.PI + C, H = !1 !== (this.options.tooltip || {}).enabled, Q = ["right", "center", "left"], I = a.labels, G = f(.9 * parseInt(I.style && I.style.fontSize, 10), 9) / 2, I = p + I.labelPadding, N = [], O = ["M"], K = [], R = this.logic.smartLabel, V = this.options.chart.textDirection, L, J, S, Z, U, T, ba, ca, Y, da, ea, fa = function (a) {
                    if (1 <= a)ea =
                        .5; else return ea = a || .5, a = (J - L) * ea + (L - S) * ea, Z > a && fa.call(this, ea + .1), ea
                };
            x.insertBefore(w);
            y.insertAfter(w);
            b.divline = [];
            for (Y = 0; Y < q; Y += 1) {
                K[Y] = ["M"];
                x = !0;
                w = d;
                U = g[Y];
                da = U.tooltext;
                for (T = U.value; w--;)A = sa(T - e) * l, ba = n + A * ya(-(k + w * v)), ca = t + A * bb(-(k + w * v)), K[Y].splice(K[Y].length, 0, ba, ca), x && (K[Y].push("L"), x = !1), 0 === w && U.label && (A = U.label, ((y = A.text) || 0 === y) && u.text(B).attr({
                    text: y,
                    x: ba,
                    y: ca,
                    "text-anchor": "right" === A.textAlign ? "end" : "left" === A.textAlign ? "start" : "middle",
                    "vertical-align": A.verticalAlign,
                    direction: V,
                    rotation: A.rotation
                }).css(A.style));
                K[Y].push("Z");
                b.divline[Y] = u.path(K[Y], z).attr({stroke: U.color, "stroke-width": U.width});
                H && da && u.path({
                    stroke: Aa,
                    "stroke-width": ab(U.width, hc),
                    ishot: !0,
                    path: K[Y]
                }, r.tracker).toBack().tooltip(da)
            }
            x = !0;
            for (w = m.length; w--;)if (U = m[w], T = U.value, r = k + T * v, d = r % c, ba = n + p * ya(-r), ca = t + p * bb(-r), N.splice(N.length, 0, "M", n, t, "L", ba, ca), O.splice(O.length, 0, ba, ca), x && (O.push("L"), x = !1), U.label && (A = U.label, (y = A.text) || 0 === y)) {
                g = d > C && d < F ? 0 : d == C || d == F ? 1 : 2;
                d = A.style;
                e = {
                    fontFamily: d.fontFamily,
                    fontSize: d.fontSize,
                    lineHeight: d.lineHeight,
                    fontWeight: d.fontWeight,
                    fontStyle: d.fontStyle,
                    color: d.color
                };
                R.setStyle(e);
                g = "right" === Q[g] ? "end" : "left" === Q[g] ? "start" : "middle";
                q = n + I * ya(-r);
                L = t + I * bb(-r);
                Z = parseInt(e.lineHeight, 10);
                K = A.verticalAlign;
                switch (g) {
                    case "start":
                        l = this.canvasLeft + this.canvasWidth - q;
                        r = m[w - 1];
                        r = k + r.value * v;
                        J = t + I * bb(-r) + G * bb(-r) + G;
                        r = m[w + 1 === m.length ? 0 : w + 1];
                        r = k + r.value * v;
                        S = t + I * bb(-r) + G * bb(-r) + G;
                        r = fa();
                        H = (J - L) * r + (L - S) * r;
                        K = "middle";
                        break;
                    case "end":
                        l = q - this.canvasLeft;
                        r = m[w + 1];
                        r = k +
                            r.value * v;
                        J = t + I * bb(-r) + G * bb(-r) + G;
                        r = m[w - 1];
                        r = k + r.value * v;
                        S = t + I * bb(-r) + G * bb(-r) + G;
                        r = fa();
                        H = (J - L) * r + (L - S) * r;
                        K = "middle";
                        break;
                    default:
                        l = this.canvasWidth, H = Z, L += G * bb(-r) + G
                }
                y = R.getSmartText(y, l, H);
                u.text(B).attr({
                    text: y.text,
                    x: q,
                    y: L,
                    "text-anchor": g,
                    "vertical-align": K,
                    rotation: A.rotation,
                    direction: V,
                    "text-bound": [d.backgroundColor, d.borderColor, d.borderThickness, d.borderPadding, d.borderRadius, d.borderDash]
                }).css(e).tooltip(y.tooltext)
            }
            O.push("Z");
            b.spikeGraph = u.path(N, z).attr({
                stroke: a.gridLineColor, "stroke-width": h(a.gridLineWidth,
                    1)
            });
            a.showRadarBorder && (b.borderGraph = u.path(O, z).toBack().attr({
                stroke: a.radarBorderColor,
                "stroke-width": h(a.radarBorderThickness, 2),
                fill: a.radarFillColor
            }))
        }, drawPlotRadar: function (b, d) {
            var a = this, c = a.paper, e = a.layers, g = e.dataset = e.dataset || c.group("orphan-dataset"), h = e.datalabels = e.datalabels || c.group("datalabels").insertAfter(g), m = e.tracker = e.tracker || c.group("hot").insertAfter(g), v = a.options, l = v.chart.anchorTrackingRadius, p = v.plotOptions.series, k = [], n = b.items || {}, t = b.graphics = b.graphics || [],
                e = a.radarAxis, u = d.data || [], w = u.length, z, x, y = !1 === d.visible, A = y ? "hidden" : "visible", p = isNaN(+p.animation) && p.animation.duration || 1E3 * p.animation, B, C, H = !1 !== (v.tooltip || {}).enabled, F, I, G, K, O = g.radarGroup = g.radarGroup || c.group("connectors", g), N = g.marker = g.marker || c.group("anchors", g), R = m.trackers = m.trackers || c.group("trackers", m), X = a.chartWidth, V = a.chartHeight, g = [], aa, L, J, S, Z, U, T, ba, ca, Y, da, ea, fa, ha, ia, ja, ka = (v = v.cursor) && {cursor: v}, ma = r["renderer.cartesian"], la, oa, qa, ra;
            void 0 === a.radarAxis && (e = a.radarAxis =
                a.createRadarAxis(d), a.drawRadarAxis(d));
            B = e.yTrans;
            F = e.yMin;
            I = e.startAngle;
            C = e.xTrans;
            z = e.centerX;
            x = e.centerY;
            1 <= w && (U = [], ib(u, function (e, g) {
                ca = null;
                g ? 2 > g && U.push("L") : U.push("M");
                n[g] = la = k[g] = {chart: a, index: g, value: e.y};
                if (null === e.y)U.push(z, x); else {
                    T = Y = null;
                    da = e.link;
                    ea = e.tooltext || e.toolText;
                    G = z + B * sa(e.y - F) * ya(-(I + g * C));
                    K = x + B * sa(e.y - F) * bb(-(I + g * C));
                    if (T = e.anchorElem)Z = f(T.attr("r"), L.radius), T.attr({
                        x: G,
                        y: K,
                        r: Z
                    }); else if (L = e.marker, qa = {
                            index: g,
                            link: da,
                            value: e.y,
                            displayValue: e.displayValueArgs,
                            categoryLabel: e.categoryLabel,
                            toolText: ea,
                            id: b.userID,
                            datasetIndex: b.index,
                            datasetName: b.name,
                            visible: b.visible
                        }, L && L.enabled)if (J = L.radius, fa = L.shadow, aa = L.symbol.split("_"), S = "spoke" === aa[0] ? 1 : 0, ia = ja = {}, ha = e.rolloverProperties, L.imageUrl)oa = new Va.Image, ra = {
                        isTooltip: H,
                        setLink: da,
                        hotLayer: m,
                        cartesianRenderer: ma,
                        clickFunc: function (b) {
                            Ca.call(this, a, b)
                        }
                    }, oa.onload = ma.onAnchorImageLoad(a, b, d, G, K, L, la, qa, ea, ha, g, N, ra), oa.onerror = function (c, e, g, f, h, k, l, m) {
                        return function () {
                            (ca = f.dataLabel = ma.drawPlotLineLabel.call(a,
                                b, d, m, c, e)) && t.push(ca)
                        }
                    }(G, K, L, la, qa, ea, ha, g), oa.src = L.imageUrl; else {
                        ha && (ia = {
                            polypath: [aa[1] || 2, G, K, J, L.startAngle, S],
                            fill: pa(L.fillColor),
                            "stroke-width": L.lineWidth,
                            stroke: pa(L.lineColor)
                        }, ja = {
                            polypath: [ha.sides || 2, G, K, ha.radius, ha.startAngle, ha.dip],
                            fill: pa(ha.fillColor),
                            "stroke-width": ha.lineWidth,
                            stroke: pa(ha.lineColor)
                        });
                        T = la.graphic = c.polypath(aa[1] || 2, G, K, J, L.startAngle, null, N).attr({
                            fill: pa(L.fillColor),
                            "stroke-width": L.lineWidth,
                            stroke: pa(L.lineColor),
                            cursor: da ? "pointer" : "",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            ishot: !0,
                            visibility: 0 === J ? "hidden" : A
                        }).data("alwaysInvisible", 0 === J).data("setRolloverProperties", ha).data("setRolloverAttr", ja).data("setRolloutAttr", ia).data("anchorRadius", J).data("anchorHoverRadius", ha && ha.radius).shadow(fa);
                        e.anchorElem = T;
                        if (da || H || ha)(Y = e.trackerElem) ? (Z = f(Y.attr("r"), L.radius + 1), Y.attr({
                            x: G,
                            y: K,
                            r: Z
                        })) : (aa || (aa = L.symbol.split("_")), J = ab(J, l, ha && ha.radius || 0), Y = c.circle(G, K, J, R).attr({
                            cursor: e.link ? "pointer" : "", stroke: Aa, "stroke-width": 1, fill: Aa, ishot: !0,
                            visibility: A
                        }).css(ka)), e.trackerElem = Y;
                        (Y = Y || T) && Y.data("eventArgs", qa).click(function (b) {
                            Ca.call(this, a, b)
                        }).hover(function (b) {
                            return function (c) {
                                ma.hoverPlotAnchor(this, c, "DataPlotRollOver", b, a)
                            }
                        }(la), function (b) {
                            return function (c) {
                                ma.hoverPlotAnchor(this, c, "DataPlotRollOut", b, a)
                            }
                        }(la)).tooltip(ea)
                    }
                    U.push(G, K);
                    la.dataLabel = ca;
                    la.tracker = Y;
                    L && L.imageUrl || (ca = ma.drawPlotLineLabel.call(a, b, d, g, G, K));
                    T && t.push(T);
                    ca && t.push(ca);
                    Y && t.push(Y)
                }
            }), U.push("Z"), g = g.concat(U));
            g && 0 < g.length && (ba = b.graphic =
                c.path(g, O).attr({
                    stroke: pa(d.lineColor.FCcolor),
                    fill: pa(d.fillColor.FCcolor),
                    "stroke-width": d.lineWidth,
                    visibility: A
                }));
            p && (a.animationCompleteQueue.push({
                fn: function () {
                    N.show();
                    h.attr({transform: "...t" + -X + "," + -V})
                }, scope: a
            }), N.hide(), h.attr({transform: "...t" + X + "," + V}), O.scale(.01, .01, z, x).animate({transform: "s1,1"}, p, "normal", a.getAnimationCompleteFn()));
            ba && t.push(ba);
            b.visible = !y
        }, legendClick: function (b) {
            r["renderer.cartesian"].legendClick.call(this, b)
        }, getEventArgs: function (b) {
            return r["renderer.cartesian"].getEventArgs.call(this,
                b)
        }
    }, r["renderer.root"]);
    r("renderer.multiLevelPie", {
        drawPlotMultilevelpie: function (b, d) {
            var a = this, c = b.items, e = b.data, g = a.options, h = g.plotOptions.series, m = a.layers, r = h.animation, l = h.dataLabels.style, p = h.shadow, k = f(b.moveDuration, r.duration, 0), n = h.borderWidth, t = h.borderColor, u = a.paper, w = g.chart.textDirection, g = (g = g.tooltip || {}, !1 !== g.enabled), z = (d.startAngle || 0) % qa, x = qa / (d.valueTotal || 100), y = a.canvasLeft + .5 * a.canvasWidth, A = a.canvasTop + .5 * a.canvasHeight, B, C, G, F, I, K, N, O, R, V, X;
            C = H(a.canvasWidth, a.canvasHeight);
            var Z, aa = m.dataset, L = r.mainItem, J = r.animObj, S = function (b) {
                Ca.call(this.graphic, a, b, "DataPlotRollOver");
                h.point.events.mouseOver.call(this)
            }, da = function (b) {
                Ca.call(this.graphic, a, b, "DataPlotRollOut");
                h.point.events.mouseOut.call(this)
            }, U = function (b) {
                Ca.call(this.graphic, a, b, "DataPlotRollOver");
                h.point.events.mouseOver.call(this)
            }, T = function (b) {
                Ca.call(this.graphic, a, b, "DataPlotRollOut");
                h.point.events.mouseOut.call(this)
            }, ba = function () {
                a.placeDataLabels(!1, c, b, d)
            };
            B = .5 * (/%$/.test(d.size) ? C * parseInt(d.size,
                    10) / 100 : d.size);
            C = .5 * (/%$/.test(d.innerSize) ? C * parseInt(d.innerSize, 10) / 100 : d.innerSize);
            d.metrics = [y, A, 2 * B, 2 * C];
            e && e.length || (e = []);
            Z = m.datalabels || (m.datalabels = u.group("datalabels").insertAfter(aa));
            V = R = z;
            for (X = e.length; X--;)F = e[X], I = F.y, K = F.displayValue, m = F.toolText, N = !!F.link, null !== I && void 0 !== I && (V = R, R -= I * x, O = .5 * (R + V), (G = c[X]) || (G = c[X] = {
                chart: a,
                link: F.link,
                value: I,
                angle: O,
                color: F.color,
                prevPointIndex: F.prevPointIndex,
                prevSeriesIndex: F.prevSeriesIndex,
                labelText: K,
                graphic: u.ringpath(y, A, B, C,
                    z, z, aa).attr({
                    "stroke-width": F.borderWidth || n,
                    stroke: F.borderColor || t,
                    fill: pa(F.color),
                    "stroke-dasharray": F.dashStyle,
                    ishot: !g,
                    cursor: N ? "pointer" : ""
                }).shadow(p && !!F.shadow)
            }, F = {
                link: F.link,
                label: F.displayValue,
                toolText: F.toolText
            }, G.graphic.mouseover(S, G), G.graphic.mouseout(da, G), G.graphic.mouseup(a.plotMouseUp), G.graphic.data("plotItem", G), G.graphic.data("eventArgs", F), g && G.graphic.tooltip(m), void 0 !== K && (G.dataLabel = u.text(Z).css(l).attr({
                text: K, fill: l.color || "#000000", visibility: "hidden", direction: w,
                ishot: N, cursor: N ? "pointer" : ""
            }).mouseover(U, G).mouseout(T, G).mouseup(a.plotMouseUp).data("plotItem", G).data("eventArgs", F).attr({"text-bound": [l.backgroundColor, l.borderColor, l.borderThickness, l.borderPadding, l.borderRadius, l.borderDash]}), g && G.dataLabel.tooltip(m))), k ? L ? G.graphic.animateWith(L, J, {ringpath: [y, A, B, C, R, V]}, k, "easeIn", !X && ba) : (J = r.animObj = Da.animation({ringpath: [y, A, B, C, R, V]}, k, "easeIn", !X && ba), L = r.mainItem = G.graphic.animate(J)) : (G.graphic.attr({ringpath: [y, A, B, C, R, V]}), !X && ba && ba()))
        },
        plotMouseUp: function (b) {
            var d = this.data("plotItem");
            Ca.call(this, d.chart, b)
        }
    }, r["renderer.piebase"]);
    Da.addSymbol({
        resizeIcon: function (b, d, a) {
            var c = f(a, 15) / 3, e = [];
            0 > c && (c = -c, a = -a, b += a - c / 2, d += a - c / 2);
            for (a = 3; 0 < a; --a)e.push("M", b - c * a, d - 3, "L", b - 3, d - c * a);
            return e
        }, closeIcon: function (b, d, a) {
            var c = 1.3 * a, e = 43 * gc, f = 48 * gc, h = b + c * ya(e), e = d + c * bb(e), m = b + c * ya(f), r = d + c * bb(f), f = .71 * (a - 2);
            a = .71 * (a - 2);
            c = ["A", c, c, 0, 1, 0, m, r];
            h = ["M", h, e];
            h = h.concat(c);
            return h = h.concat(["M", b + f, d - a, "L", b - f, d + a, "M", b - f, d - a, "L", b + f, d + a])
        },
        configureIcon: function (b, d, a) {
            --a;
            var c = .71 * a, e = .71 * (a + 2), f = b - a, h = d - a, m = b + a;
            a = d + a;
            var r = b + .5, l = d + .5, p = b - .5, k = d - .5, n = f - 2, t = h - 2, u = m + 2, w = a + 2, z = b + c, x = d + c, y = b - c, c = d - c, A = b + e, B = d + e;
            b -= e;
            d -= e;
            return ["M", f, l, "L", n, l, n, k, f, k, y - .25, c + .25, b - .25, d + .25, b + .25, d - .25, y + .25, c - .25, p, h, p, t, r, t, r, h, z - .25, c - .25, A - .25, d - .25, A + .25, d + .25, z + .25, c + .25, m, k, u, k, u, l, m, l, z + .25, x - .25, A + .25, B - .25, A - .25, B + .25, z - .25, x + .25, r, a, r, w, p, w, p, a, y + .25, x + .25, b + .25, B + .25, b - .25, B - .25, y - .25, x - .25, "Z"]
        }, axisIcon: function (b, d, a) {
            --a;
            var c = .33 * a,
                e = a / 2, f = b - a, h = d - a, m = b + e;
            a = d + a;
            b -= e;
            e = d + c;
            d -= c;
            return ["M", f, h, "L", m, h, m, a, f, a, "M", b, e, "L", m, e, "M", b, d, "L", m, d]
        }, loggerIcon: function (b, d, a) {
            --a;
            b -= a;
            d -= a;
            var c = b + 2 * a, e = b + 2, f = c - 2, h = d + 2;
            a = h + a;
            var m = a + 2;
            return ["M", b, d, "L", c, d, c, h, f, h, f, a, c, a, c, m, b, m, b, a, e, a, e, h, b, h, b, d]
        }
    })
}, [3, 2, 1, "release"]]);

/* jshint ignore:end */
(function() {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.CursoSelect', CursoSelect);

    CursoSelect.$inject = ['ui.components.Modal',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.CursoService',
        'bfc.Select2Config',
        'bfc.$$PermissionsService'
    ];

    function CursoSelect($modal,
        ObjectUtilsService,
        CursoService,
        Select2Config,
        PermissionsService
    ) {
        var onAdd = PermissionsService.isRevokedOperation('CursoPageMapping', 'criar') ? undefined : add;

        return {
            select2Config: function(options) {
                return Select2Config.create(angular.extend({
                    onAdd: onAdd,
                    result: function(params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);
                        
                        var result,
                            filter = '(descricao like "' + term + '")';


                        result = CursoService.getList(params.offset, params.limit, filter, undefined)
                            .then(function(data) {
                                callback({
                                    results: data,
                                    more: false
                                });
                            });

                        return result;
                    },
                    formatValue: function(data) {
                        return data;
                    },
                    formatResult: function(data) {
                        return data.text ?
                            data.text :
                            data.descricao;
                    },
                    formatSelection: function(data) {
                        return data.descricao;
                    },
                    initSelection: function(element, callback) {
                        var id = $(element).val();
                        if (id) {
                            CursoService.get(id).then(callback, function() {
                                callback({
                                    id: null
                                });
                            });
                        }
                    }
                }, options));
            }
        };

        function add(val) {
             $modal.open({
                templateUrl: 'planejamento/matriz/curso/cursos.cad.html',
                controller: 'educacao.matriz.CursoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    curso: {
                        descricao: val
                    },
                    canSaveAndAdd: false
                }
            });
            return false;
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.BoletimService', service);

    service.$inject = ['ServiceBuilder'];

    function service(ServiceBuilder) {

        var created = ServiceBuilder
            .create()
            .path('matricula', 'boletim')
            .enum('ModeloBoletim', 'BoletimPeriodoAvaliativo', 'BoletimOrdenacao')
            .build();

        created.gerarPdf = gerarPdf;
        created.enviarGerenciador = enviarGerenciador;

        return created;

        function gerarPdf(parametros) {
            return created.getEndpoint().all('pdf')
                .withHttpConfig({
                    responseType: 'arraybuffer'
                })
                .post(parametros);
        }

        function enviarGerenciador(relatorio, parametros){
            return created.getEndpoint()
                .one('gerenciador', relatorio.id)
                .customPOST({ parametros: parametros });
        }

    }
})();


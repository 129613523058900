(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('classificacaoLista', ClassificacaoLista);

    function ClassificacaoLista() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/classificacao-inscritos/directives/classificacao-lista.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                lista: '=',
                configuracao: '=',
                group: '=',
                processo: '=',
                chamada: '=',
                edit: '=',
                indeferir: '=',
                desfazerIndeferimento: '=',
                showUtilizaEncaminhamento: '=',
                matricular: '=',
                nivelEscolar: '=',
                listaEspera: '=',
                encaminhar: '=',
                visualizarInscricao: '=',
                inscritosEncaminhamentoLote: '=',
                utilizaZoneamento: '='
            }
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.hasTerceiroGrupo = hasTerceiroGrupo;

        $scope.$watch('lista', changeLista);
        $scope.$watch('group', changeGroup);

        $scope.$watch('vm.inscritosEncaminhamentoLote', function (newValue) {
            if (!newValue) {
                return;
            }

            if (_.isEmpty(newValue)) {
                $scope.inscritosEncaminhamentoLote = [];
                return;
            }

            var inscritos = _.map(newValue, function (inscrito) {
                return {
                    classificacaoInscricao: mapClassificacaoInscricao(inscrito),
                    candidato: mapInscritoCandidato(inscrito.resultadoClassificacao.dados),
                    id: inscrito.resultadoClassificacao.id
                };
            });

            $scope.inscritosEncaminhamentoLote = inscritos;
		});

		vm.inscritosEncaminhamentoLote = [];

        function mapInscritoCandidato(data) {
            return {
                nome: data.nome,
                id: data.id,
                sexo: data.sexo,
                dataNascimento: data.dataNascimento
            };
        }

        function mapClassificacaoInscricao(data) {
            var classificacaoInscricao = data.resultadoClassificacao.classificacaoInscricao;
            return {
                configuracaoInscricao: classificacaoInscricao.configuracaoInscricao,
                estabelecimento: classificacaoInscricao.estabelecimento,
                etapa: classificacaoInscricao.etapa,
                id: classificacaoInscricao.id,
                turno: classificacaoInscricao.turno,
                resultado: { 
                    id: data.resultadoClassificacao.id,
                    posicao: data.resultadoClassificacao.posicao
                },
                resultadoChamada: { status: data.statusResultadoClassificacao }
            };
        }

        function isEducacaoInfantil() {
            return $scope.processo.nivelEscolar === 'EDUCACAO_INFANTIL';
        }

        function hasTerceiroGrupo() {
            return !isEducacaoInfantil() || ($scope.processo.status !== 'AGUARDANDO' && $scope.processo.status !== 'PERIODO_ENCERRADO' && $scope.processo.status !== 'ABERTO');
        }

        function changeGroup(group) {
            if (group === '0') {
                vm.lista = angular.copy(_.uniq($scope.lista, 'id'));
            } else {
                vm.lista = $scope.lista;
            }
        }

        function changeLista(lista) {
            if ($scope.group === '0') {
                vm.lista = _.uniq(lista, 'id');
            } else {
                vm.lista = lista;
            }
        }

    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.common.$commonsProvider', 'educacao.historico.HistoricoServiceProvider'];

    function HistoricoConfig($commonsProvider, HistoricoServiceProvider) {

        function openEdicaoRemenejamentoModal(matriculaId) {
            $commonsProvider.$get().$modal.open({
                templateUrl: 'matricula/enturmacao/remanejamento-edicao.html',
                controller: 'educacao.matricula.RemanejamentoEdicaoCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    matriculaId: matriculaId
                }
            });
        }

        HistoricoServiceProvider.addTipoHandler('REMANEJAMENTO', {
            badge: {
                icon: 'fa-exchange',
                tooltip: 'Remanejamento'
            },
            template: '<small>Remanejado da turma <strong>{{turma.valor}}</strong> para: <strong>{{turmaDestino.valor}}</strong><small><br>',
            campos: {
                dataRemanejamento: {
                    template: '<small>Data do remanejamento: <strong>{{valor|bfDate}}</strong></small><br>'
                },
                motivoRemanejamento: {
                    template: '<small>Motivo: <strong>{{valor}}</strong></small><br>'
                },
                observacaoRemanejamento: {
                    template: '<small>Observação: <strong>{{valor}}</strong></small><br>'
                },
                matriculaDestino: {
                    template: '<small>Nova matrícula: <strong>{{valor}}</strong></small><br>'
                }
            },
            openEdicaoRemenejamentoModal: openEdicaoRemenejamentoModal,
            actionTemplates:{
                actionTemplate: [ '<button bf-permissions="app.matricula.remanejamentointerno" operation="editar" class="btn btn-xs btn-default" title="Editar remanejamento" ' +
                'ng-click="handler.openEdicaoRemenejamentoModal(matricula.valor)"><i class="fa fa-pencil"></i></button>']}
        });

        HistoricoServiceProvider.addTipoHandler('REMANEJAMENTO_ALTERACAO', {
            badge: {
                icon: 'fa-pencil',
                tooltip: 'Edição do Remanejamento'
            },
            template: '<small>Editado o remanejamento da turma <strong>{{turma.valor}}</strong> para: <strong>{{turmaDestino.valor}}</strong><small><br>',
            campos: {
                motivoRemanejamento: {
                    alteracao: {
                        template: '<small>Motivo alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    }
                },
                observacaoRemanejamento: {
                    alteracao: {
                        template: '<small>Observação alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    }
                }
            }
        });
    }
})();


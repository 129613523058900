/*(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.planejamento');

	moduloCommon.controller('educacao.planejamento.AvaliacoesCtrl', ['$scope', '$timeout','educacao.common.ModalCad','bfc.Select2Config',
		function($scope, $timeout, ModalCad, Select2Config
		) {

			$scope.avaliacao = {};


		$scope.openAddAvaliacao = function(id) {
			ModalCad.open({
				templateUrl: 'planejamento/avaliacoes/avaliacoes.cad.html',
				controller: 'educacao.planejamento.AvaliacoesCadCtrl',
				id: id,
				readOnly: false
			});
		};

		

	}]);

	moduloCommon.controller('educacao.planejamento.AvaliacoesCadCtrl', ['$scope', '$timeout','educacao.common.ModalCad', 'bfc.Select2Config',
		function($scope, $timeout, ModalCad,Select2Config
		) {
			$scope.avaliacao = {};

			 Campos com selec2 como select
	   $scope.$watch('$viewContentLoaded', function(){
		   	minimumResultsForSearch: -1,
		    setTimeout(function(){
		        $("#lbAvaliador").select2({
		            data: {
		                results: [
		                    {id: 1, text: 'Governo Federal'},
		                    {id: 2, text: 'Governo Estadual'},
		                    {id: 3, text: 'Governo Municipal'}, 
		                    {id: 3, text: 'Privado'}
		                ]
		            }
		        });
		    }, 200);
		});

	}]);

	

	


})();	*/
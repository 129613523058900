(function() {
    'use strict';
    angular.module('educacao.pessoas').directive('configuracaoMatricula', function() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'matricula/configuracao-matricula/configuracao-matricula-template.diretive.html';
        config.scope = {
            tipoConfiguracao: '=ngModel',
            nivelEscolar: '=',
            nivelModalidade: '=',
            enumEtapas: '=',
            exibeColunaEtapas: '=',
            restringeQtdEtapa: '=',
            documentos: '=',
            isEstabelecimento: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';


        Controller.$inject = ['TIPOS_MATRICULAS'];

        function Controller(TIPOS_MATRICULAS) {
            var vm = this;
            vm.tiposMatriculas = TIPOS_MATRICULAS;
        }
        return config;
    });
})();

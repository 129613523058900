(function () {
    'use strict';
    angular.module('educacao.pessoas').directive('appPessoaDocumentos', function () {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'planejamento/pessoa/app-pessoa-documentos.directive.html';
        config.scope = {
            aluno: '=ngModel',
            service: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;

    });
    Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.common.ModalCad'];

    function Controller($scope, $modal, ModalCad) {
        var vm = this;
        var RETURN_MODAL_COMP = 'returnModalComp:Aluno';

        vm.enumNacionalidade = $scope.service.getNacionalidade();
        vm.enumCertidaoCivil = $scope.service.getCertidaoCivil();
        vm.enumTipoVistoEstrangeiro = $scope.service.getTipoVistoEstrangeiro();
        vm.enumJustificativaFaltaDocumentacao = $scope.service.getJustificativaFaltaDocumentacao();
        vm.enumModeloCertidao = $scope.service.getModeloCertidao();
        vm.enumUf = $scope.service.getUF();
        vm.titulo = $scope.titulo;
        vm.tracker = $scope.tracker;
        vm.openDadosCTPS = openDadosCTPS;
        vm.filterCertidaoCivil = filterCertidaoCivil;
        vm.formatResultEnum = formatResultEnum;
        vm.formatResultOrgaoEmissor = formatResultOrgaoEmissor;
        $scope.$watch('aluno.modeloCertidao', changeModeloCertidao);

        vm.isBrasileiro = isPaisOrigemBrasil(_.get($scope.aluno.pessoa, 'complemento.paisNacionalidade'));

        function changeModeloCertidao(newValue, oldValue) {
            if (angular.equals(oldValue, newValue)) {
                return;
            }
            if (newValue === 'NOVO') {
                delete $scope.aluno.numeroTermo;
                delete $scope.aluno.dataEmissaoCertidao;
                delete $scope.aluno.municipioCertidao;
                delete $scope.aluno.emissaoCertidao;
                delete $scope.aluno.livroCertidao;
                delete $scope.aluno.folhaCertidao;
                delete $scope.aluno.cartorioCertidao;
                return;
            }

            delete $scope.aluno.numeroCertidao;
        }

        function filterCertidaoCivil(term, value) {
            if (!_.get($scope.aluno.pessoa, 'complemento.naturalizado')) {
                if (value.key === 'NASCIMENTO' && !vm.isBrasileiro) {
                    return;
                }
            }
            return value.key.indexOf(term.toUpperCase()) !== -1;
        }

        $scope.$watch('aluno.pessoa.complemento.paisNacionalidade', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            verificaPaisOrigem(newValue);
        });

        $scope.$watch('aluno.pessoa.complemento.naturalizado', function (newValue, oldValue) {
            if (oldValue === newValue || newValue) {
                return;
            }

            delete $scope.aluno.certidaoCivil;
        });

        $scope.$watchGroup(['vm.isBrasileiro', 'aluno.pessoa.complemento.naturalizado'], function () {
            vm.naturalizado = vm.isBrasileiro || _.get($scope.aluno.pessoa, 'complemento.naturalizado');
            if (!vm.naturalizado) {
                _.chain($scope.aluno)
                    .set('pessoa.ric', null)
                    .set('pessoa.orgaoEmissorRic', null)
                    .set('pessoa.ufEmissaoRic', null)
                    .set('pessoa.dataEmissaoRic', null)
                    .set('pessoa.rg', null)
                    .set('orgaoEmissorRG', null)
                    .set('pessoa.ufEmissaoRg', null)
                    .set('pessoa.dataEmissaoRg', null)
                    .set('pessoa.complemento.numeroReservista', null)
                    .value();
            }
        });

        function limpaDadosEstrangeiro() {
            _.chain($scope.aluno)
                .set('pessoa.estrangeiro', null)
                .value();
        }

        function formatResultEnum(data) {
            return data.value;
        }

        function formatResultOrgaoEmissor(value) {
            return value.nome || value.descricao;
        }

        function openDadosCTPS() {
            ModalCad.open({
                templateUrl: 'planejamento/alunos/popups/aluno.cad.ctps.html',
                controller: 'educacao.pessoas.ControllerGenerico as vm',
                resolve: {
                    ModalCalCompTransfer: {
                        brodcastReturn: RETURN_MODAL_COMP,
                        valueData: $scope.aluno,
                        select2: {
                            UF: vm.enumUf
                        }
                    }
                },
                readOnly: false
            });
        }

        function verificaPaisOrigem(pais) {

            if (isPaisOrigemBrasil(pais)) {
                vm.isBrasileiro = true;
                limpaDadosEstrangeiro();
            } else {
                vm.isBrasileiro = false;
                delete $scope.aluno.certidaoCivil;
            }

        }

        function isPaisOrigemBrasil(pais){

            var paisOrigem;
            if (_.get(pais, 'descricao') || _.get(pais, 'nome')) {
                paisOrigem = pais.descricao || pais.nome;
            }

            return !paisOrigem ||  (paisOrigem.toUpperCase() === 'BRASIL');
        }
    }

})();

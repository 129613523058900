(function() {

    'use strict';

    angular.module('educacao.historico-escolar')
        .factory('educacao.historicoEscolar.ImpressaoHistoricoEscolarService', ImpressaoHistoricoEscolarService);

    ImpressaoHistoricoEscolarService.$inject = ['ServiceBuilder'];

    function ImpressaoHistoricoEscolarService(ServiceBuilder) {

        var self = ServiceBuilder
            .create()
            .path('impressao-historico-escolar')
            .enum('ModeloHistorico')
            .build();

        self.imprimirHistorico = imprimirHistorico;

        return self;

        function imprimirHistorico(parametros) {
            return self.getEndpoint()
                .all('print')
                .withHttpConfig({
                    responseType: 'arraybuffer'
                })
                .post(parametros);
        }

    }

})();


(function () {

    'use strict';

    var TIPO_DOCUMENTO = {
        MATRICULA: 'MATRICULA',
        HISTORICO_ESCOLAR: 'HISTORICO_ESCOLAR'
    };

    angular.module('educacao.historico-escolar')
        .controller('educacao.historicoEscolar.ImpressaoHistoricoEscolarCtrl', ImpressaoHistoricoEscolarCtrl);

    ImpressaoHistoricoEscolarCtrl.$inject = [
        'ed-components.ed-emissao-relatorio.service',
        'educacao.context.ContextoEstabelecimento',
        '$modalInstance',
        'promiseTracker',
        'bfc.Notification'
    ];

    function ImpressaoHistoricoEscolarCtrl(
        RelatorioService,
        ContextoEstabelecimento,
        $modalInstance,
        promiseTracker,
        Notification) {

        var vm = this;

        vm.imprimirHistorico = imprimirHistorico;
        vm.onChangeEmitirCertificado = onChangeEmitirCertificado;

        vm.aluno = $modalInstance.params.aluno || null;
        vm.historicos = $modalInstance.params.historicosSelecionados || [];

        vm.etapas = _.pluck(vm.historicos, 'etapa');
        vm.buscar = buscar;
        vm.formatResult = formatResult;

        vm.parametros = {
            historicos: getHistoricoObjects(vm.historicos),
            agrupamento: _.first(vm.historicos).agrupamento,
            modalidade: _.first(vm.historicos).modalidade.description,
            nivel: _.first(vm.historicos).nivel ? _.first(vm.historicos).nivel.description : '',
            emitirEquivalenciaEtapas: false,
            emitirCertificado: false,
            emitirObservacoesMatricula: false,
            emitirObservacoesHistorico: false,
            emitirClassificacaoEtapas: false
        };

        vm.tracker = {
            imprimir: promiseTracker()
        };

        function buscar(params) {
            params.filter = getFilter(params.term);
            return RelatorioService.buscar(params);

            function getFilter(term) {
                var filter = 'natureza="IMPRESSAO_HISTORICO_ESCOLAR"';
                return !term ? filter : (filter += ' and titulo like "' + term + '"');
            }
        }

        function formatResult(data) {
            return data.titulo;
        }

        function imprimirHistorico() {
            var parametros = {
                modalidade: vm.parametros.modalidade,
                nivel: vm.parametros.nivel,
                emitirEquivalenciaEtapas: vm.parametros.emitirEquivalenciaEtapas + '',
                emitirCertificado: vm.parametros.emitirCertificado + '',
                emitirObservacoesMatricula: vm.parametros.emitirObservacoesMatricula + '',
                emitirObservacoesHistorico: vm.parametros.emitirObservacoesHistorico + '',
                emitirClassificacaoEtapas: vm.parametros.emitirClassificacaoEtapas + '',
                dataEmissao: vm.parametros.dataEmissao,
                legislacoes: _.pluck(_.map(vm.legislacoes, legislacaoMap), 'legislacao').join(', '),
                convencoes: _.pluck(vm.convencoes, 'descricao').join(', '),
                etapa: _.get(vm.etapa, 'description'),
                matriculas: _.map(_.filter(vm.parametros.historicos,
                    {tipoItemDocumentoHistorico: TIPO_DOCUMENTO.MATRICULA}), 'id'),
                historicos: _.map(_.filter(vm.parametros.historicos,
                    {tipoItemDocumentoHistorico: TIPO_DOCUMENTO.HISTORICO_ESCOLAR}), 'id'),
            };

            if (!ContextoEstabelecimento.isSecretaria()) {
                parametros.estabelecimento = ContextoEstabelecimento.getEstabelecimento().id + '';
            }

            var promise = RelatorioService.emitir(vm.relatorio, parametros)
                .then(notificar)
                .then($modalInstance.close);

            vm.tracker.imprimir.addPromise(promise);
        }

        function notificar() {
            Notification.publish('Impressão do relatório agendada com sucesso', 'info');
        }

        function legislacaoMap(item) {
            return {
                legislacao: item.descricao + ' - Data: ' + moment(item.dataLegislacao).format('DD/MM/YYYY')
            };
        }

        function getHistoricoObjects(historicos) {
            var historicosObjects = [];
            angular.forEach(historicos, function (item) {
                var historico = {
                    id: item.id,
                    tipoItemDocumentoHistorico: item.isMatricula ? TIPO_DOCUMENTO.MATRICULA : TIPO_DOCUMENTO.HISTORICO_ESCOLAR
                };
                historicosObjects.push(historico);
            });
            return historicosObjects;
        }

        function onChangeEmitirCertificado() {
            delete vm.etapa;
        }
    }

})();


(function() {
    'use strict';

    angular.module('educacao.legislacao')
        .controller('educacao.legislacao.LegislacaoCadCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        '$scope',
        'educacao.legislacao.LegislacaoService'
    ];

    function Controller(
        promiseTracker,
        $modalInstance,
        focus,
        $scope,
        LegislacaoService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.legislacao = {};
        vm.editando = _.get($modalInstance.params.legislacao, 'id');
        vm.legislacao = $modalInstance.params.legislacao || {};
        vm.save = save;
        vm.cancel = fechaModal;
        vm.remove = remove;
        vm.windowTitle = vm.editando ? 'Editando legislação' : 'Adicionando legislação';
        verificaFormulario();
        focus('lbDescricaoLegislacao');


        function save(continuar) {
            vm.tracker.loading.addPromise(
                LegislacaoService.save(vm.legislacao).then(function(data) {
                    vm.legislacao = data;
                    onSuccess(continuar);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                LegislacaoService.remove(vm.legislacao).then($modalInstance.close)
            );
        }


        function onSuccess(continuar) {
            if (continuar) {
                verificaFormulario();
                vm.legislacao = {};
                focus('lbDescricaoLegislacao');
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.legislacao);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();

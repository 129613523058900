(function () {
    'use strict';

    angular.module('educacao.criterio-lista-espera')
    .controller('educacao.criterio-lista-espera.CriterioListaEsperaCtrl', CriterioListaEsperaCtrl);

    CriterioListaEsperaCtrl.$inject = [
        'promiseTracker',
        'educacao.criterio-lista-espera.CriterioListaEsperaService',
        'ui.components.Modal',
        'bfc.Notification',
    ];

    function CriterioListaEsperaCtrl(promiseTracker, CriterioListaEsperaService, uiModal, bfcNotification) {
        var vm = this;

        vm.tracker = {};
        vm.tracker.list = promiseTracker();

        vm.criterioListaEsperaService = CriterioListaEsperaService;

        vm.abreCadastro = abreCadastro;
        vm.remove = remove;

        function abreCadastro(criterio) {
            uiModal.open({
                templateUrl: 'lista-espera-new/configuracao/criterio-lista-espera/criterio-lista-espera.cad.html',
                controller: 'educacao.criterio-lista-espera.CriterioListaEsperaCadCtrl as vm',
                params: {
                    criterio: criterio
                },
                readOnly: false
            });
        }

        function remove(criterio) {
            return CriterioListaEsperaService.remove(criterio).then(function(){
                bfcNotification.publish('Critério removido com sucesso.', 'success');
            });
        }
    }
})();
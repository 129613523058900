(function() {
    'use strict';

    angular.module('educacao.matricula')

    .factory('educacao.matricula.ConfiguracaoService', ['Restangular', function(Restangular) {
        return {
            getTiposAtendimento: function() {
                return Restangular.one('matricula').all('configuracoes/tipos-atendimentos').getList();
            },

            getModalidades: function() {
                return Restangular.one('matricula').all('configuracoes/modalidades').getList();
            }
        };
    }])

    .factory('educacao.matricula.TipoAtendimentoSelect', [
        'educacao.matricula.ConfiguracaoService',
        'bfc.Select2Config',
        'educacao.common.ObjectUtilsService',
        function(ConfiguracaoService, Select2Config, ObjectUtilsService) {

            var items = {};

            ConfiguracaoService.getTiposAtendimento().then(function(data) {
                items = data;
            });

            return {
                select2Config: function(options) {
                    return Select2Config.create(angular.extend({

                        result: function(params, callback) {
                            callback({
                                results: ObjectUtilsService.filterByFieldWithSort(items, 'descricao', params.term),
                                more: false
                            });
                        },
                        formatValue: function(data) {
                            return data.id;
                        },
                        formatResult: function(data) {
                            return data.text ?
                                data.text : data.descricao;
                        },
                        formatSelection: function(data) {
                            return data.descricao;
                        },
                        initSelection: function(element, callback) {
                            var id = $(element).val();

                            if (id) {
                                var item = _.find(items, {
                                    'id': Number(id)
                                });
                                if (item) {
                                    callback(item);
                                } else {
                                    callback({
                                        id: null
                                    });
                                }
                            }
                        }
                    }, options));
                }
            };
        }
    ])

    .factory('educacao.matricula.ModalidadeSelect', [
        'educacao.matricula.ConfiguracaoService',
        'bfc.Select2Config',
        'educacao.common.ObjectUtilsService',
        function(ConfiguracaoService, Select2Config, ObjectUtilsService) {

            var items = {};

            ConfiguracaoService.getModalidades().then(function(data) {
                items = data;
            });

            return {
                select2Config: function(options) {
                    return Select2Config.create(angular.extend({

                        result: function(params, callback) {
                            callback({
                                results: ObjectUtilsService.filterByFieldWithSort(items, 'descricao', params.term),
                                more: false
                            });
                        },
                        formatValue: function(data) {
                            return data.id;
                        },
                        formatResult: function(data) {
                            return data.text ?
                                data.text : data.descricao;
                        },
                        formatSelection: function(data) {
                            return data.descricao;
                        },
                        initSelection: function(element, callback) {
                            var id = $(element).val();

                            if (id) {
                                var item = _.find(items, {
                                    'id': Number(id)
                                });
                                if (item) {
                                    callback(item);
                                } else {
                                    callback({
                                        id: null
                                    });
                                }
                            }
                        }
                    }, options));
                }
            };
        }
    ]);
})();

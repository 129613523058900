(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appSelectEtapaEnsinoInep', SelectEtapaEnsinoInepDirective);

    SelectEtapaEnsinoInepDirective.$inject = [];

    function SelectEtapaEnsinoInepDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search"  format-result="vm.formatResult(data)" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?'
            },
            controller: SelectEtapaEnsinoInepDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }

        };
    }

    SelectEtapaEnsinoInepDirectiveController.$inject = ['$scope', 'educacao.common.EtapaInepService'];

    function SelectEtapaEnsinoInepDirectiveController($scope, service) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function formatResult(data) {
            return data.codigoInep + ' - ' + data.nome;
        }

        function search(params) {

            var filter = '( nome like "' + params.term + '"';

            if ($scope.multiple) {
                var etapaIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(etapa) {
                        etapaIds.push(etapa.id);
                    });
                }
                if (etapaIds.length) {
                    filter += ' and id not in(' + etapaIds.join() + ')';
                }
            }
            if(parseInt(decodeURI(params.term).replace(/%/g, ' ').trim()) > 0 && decodeURI(params.term).replace(/%/g, ' ').trim().length){
                filter += ' or codigoInep = ' + parseInt(decodeURI(params.term).replace(/%/g, ' ').trim()) + '';
            }

            filter += ' )';

            return service.getList(params.offset, params.limit, filter).then(toPage);
        }

    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('matriculaVagaEtapa', MatriculaVagaEtapa);

    function MatriculaVagaEtapa() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/vaga-etapa/matricula-vaga-etapa.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                matricula: '=',
                formatResultTurma: '=',
                formatResultTurno: '=',
                hasEncerramentos: '='
            }
        };
    }

    Controller.$inject = ['$scope', '$injector', 'promiseTracker', 'educacao.context.ContextoEstabelecimento', 'TIPOS_MATRICULAS', 'ETAPAS_INEP_TURMAS_MULT', 'NIVEL_ESCOLAR'];

    function Controller($scope, $injector, promiseTracker, ContextoEstabelecimento, TIPOS_MATRICULAS, ETAPAS_INEP_TURMAS_MULT, NIVEL_ESCOLAR) {

        var MatriculaVagaService = $injector.get('educacao.matricula.MatriculaVagaService'),
            MatriculaDisciplinaService = $injector.get('educacao.matricula.MatriculaDisciplinaService'),
            EtapaDisciplinaService = $injector.get('educacao.matriz.EtapaDisciplinaService'),
            ConfiguracoesMatriculaService = $injector.get('educacao.matricula.ConfiguracoesMatriculaService');

        var vm = this;

        vm.isTurmaMultisseriada = isTurmaMultisseriada;

        vm.isTurnoIntegral = isTurnoIntegral;

        vm.tracker = {
            loading: promiseTracker(),
            loadingDisciplinas: promiseTracker(),
            loadingConfigMultiturno: promiseTracker()
        };

        vm.checked = false;

        vm.checkAll = checkAll;
        vm.checkDisciplinasEixos = checkDisciplinasEixos;
        vm.isProgressaoParcial = isProgressaoParcial;
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.showMultiturno = showMultiturno;
        vm.isTurmaAreaConhecimento = isTurmaAreaConhecimento;
        vm.isTurmaObrigatorio = isTurmaObrigatorio;
        vm.isNivelEscolarCompativel = isNivelEscolarCompativel;
        vm.permiteMultiturno = false;

        vm.edit = Boolean($scope.matricula.id);
        vm.tiposMatriculas = TIPOS_MATRICULAS;
        vm.nivelEscolar = NIVEL_ESCOLAR;

        $scope.matricula.$$listaFilhos = [];

        vm.isMultiturno = (vm.edit && $scope.matricula.turnoFrequenta);

        if (vm.edit) {
            listaFilhos();
            showMultiturno();
        }

        vm.tracker.loading.addPromise(
            vm.getTransferenciaOrigem = MatriculaVagaService.getTransferenciaOrigem()
        );

        vm.tracker.loading.addPromise(
            vm.getOrigemMatricula = MatriculaVagaService.getOrigemMatricula()
        );

        vm.tracker.loading.addPromise(
            vm.getTipoClassificacaoMatricula = MatriculaVagaService.getTipoClassificacaoMatricula()
        );

        vm.tracker.loading.addPromise(
            vm.getFormaIngresso = MatriculaVagaService.getFormaIngresso()
        );

        vm.tracker.loading.addPromise(
            vm.getSituacaoUltimaMatricula = MatriculaVagaService.getSituacaoUltimaMatricula()
        );

        vm.tracker.loading.addPromise(
            vm.getTipoReclassificacaoMatricula = MatriculaVagaService.getTipoReclassificacaoMatricula()
        );

        vm.tracker.loading.addPromise(
            vm.getTipoTurmaUnificada = MatriculaVagaService.getTipoTurmaUnificada()
        );

        vm.tracker.loading.addPromise(
            MatriculaDisciplinaService.getDefaultRepresentation().then(function(data) {
                $scope.matricula.$$filhosOptions = data;
            })
        );

        if (!vm.edit) {
            vm.tracker.loading.addPromise(
                MatriculaVagaService.getDefaultRepresentation().then(setDadosMatricula)
            );
        }

        $scope.$watch('matricula.tipoMatricula', watchTipoMatricula);
        $scope.$watch('matricula.matriz', watchMatriz);
        $scope.$watch('matricula.etapaMatriz', watchEtapaMatriz);
        $scope.$watch('matricula.vaga', watchVaga);
        $scope.$watch('matricula.turma', watchTurma);

        function isTurnoIntegral() {
            return _.get(_.get($scope.matricula, 'vaga'), 'turno') === 'INTEGRAL';
        }

        function checkAll() {
            _.forEach($scope.matricula.$$listaFilhos, function(item) {
                if (vm.isTurmaAreaConhecimento()){
                    item.eixoTematico.$$checked = vm.checked;
                }else{
                    item.disciplina.$$checked = vm.checked;
                }
            });
            verificaListaDisciplinasEixos();
        }

        function watchTipoMatricula(newValue, oldValue) {
            if (_.isEqual(oldValue, newValue)) {
                return;
            }
            limpaMatriz();
        }

        function watchMatriz(newValue, oldValue) {
            if (_.isEqual(oldValue, newValue)) {
                return;
            }

            removeEtapaMatriz();
            removeVaga();
            removeTurma();
        }

        function watchEtapaMatriz(newValue, oldValue) {
            if (_.isEqual(oldValue, newValue)) {
                return;
            }

            removeVaga();
            if (!newValue) {
                return;
            }
            if (isProgressaoParcial()) {
                loadingFilhos();
            }
        }

        function watchVaga(newValue, oldValue) {
            if (_.isEqual(oldValue, newValue)) {
                return;
            }

            removeTurma();
        }

        function watchTurma(newValue, oldValue){
            if (_.isEqual(oldValue, newValue) || !$scope.matricula.matriz || !$scope.matricula.etapaMatriz) {
                return;
            }

            loadingFilhos();
        }

        function checkDisciplinasEixos() {
            vm.checked = _.every($scope.matricula.$$listaFilhos, vm.isTurmaAreaConhecimento() ? 'eixoTematico.$$checked' : 'disciplina.$$checked', true);
            verificaListaDisciplinasEixos();
        }

        function verificaListaDisciplinasEixos() {
            vm.checkedAny = _.find(_.pluck($scope.matricula.$$listaFilhos, vm.isTurmaAreaConhecimento() ? 'eixoTematico' : 'disciplina'), '$$checked', true);
        }

        function listaFilhos() {
            if (!vm.isTurmaAreaConhecimento()){
                $scope.matricula.$$listaFilhos = _.map($scope.matricula.filhos, 'disciplina');

                return;
            }

            $scope.matricula.$$listaFilhos = [];
            _.chain(_.map($scope.matricula.filhos, 'disciplina'))
                .sortBy(function(n) {
                    return n.eixoTematico.descricao;
                })
                .groupBy(function(n) {
                    return n.eixoTematico.descricao;
                })
                .forEach(function(item) {
                    $scope.matricula.$$listaFilhos.push({
                        id: _.get(_.first(item), 'pai.id') || undefined,
                        filhos: item,
                        eixoTematico: _.first(item).eixoTematico,
                        tipo: 'AREA_CONHECIMENTO'
                    });
                })
                .value();
        }

        function setDadosMatricula(data) {
            data.estabelecimento = $scope.matricula.estabelecimento;
            data.aluno = $scope.matricula.aluno;
            data.tipoMatricula = $scope.matricula.tipoMatricula;

            $scope.matricula = angular.extend($scope.matricula, data);
        }

        function loadingFilhos() {
            vm.tracker.loadingDisciplinas.addPromise(
                EtapaDisciplinaService.getList(
                    $scope.matricula.matriz.id, $scope.matricula.etapaMatriz.id)
                .then(atualizaFilhos)
            );
        }

        function atualizaFilhos(data) {
            resetChecked();

            if (!vm.isTurmaAreaConhecimento()){
                $scope.matricula.$$listaFilhos = data;

                return;
            }

            $scope.matricula.$$listaFilhos = [];
            _.chain(data)
                .sortBy(function(n) {
                    return n.eixoTematico.descricao;
                })
                .groupBy(function(n) {
                    return n.eixoTematico.descricao;
                })
                .forEach(function(item) {
                    $scope.matricula.$$listaFilhos.push({
                        id: _.get(_.first(item), 'pai.id') || undefined,
                        filhos: item,
                        eixoTematico: _.first(item).eixoTematico,
                        tipo: 'AREA_CONHECIMENTO'
                    });
                })
                .value();
        }

        function limpaMatriz() {
            removeMatriz();
            removeEtapaMatriz();
            removeVaga();
            removeTurma();
        }

        function isProgressaoParcial() {
            return $scope.matricula.tipoMatricula === 'PROGRESSAO_PARCIAL';
        }

        function isTurmaAreaConhecimento(){
            return $scope.matricula.turma && $scope.matricula.turma.tipoResultado === 'AREA_CONHECIMENTO';
        }

        function removeMatriz() {
            delete $scope.matricula.matriz;
        }

        function removeTurma() {
            delete $scope.matricula.turma;
            removeTurnoFrequenta();
        }

        function removeTurnoFrequenta() {
            delete $scope.matricula.turnoFrequenta;
        }

        function removeEtapaMatriz() {
            delete $scope.matricula.etapaMatriz;
        }

        function removeVaga() {
            delete $scope.matricula.vaga;
        }

        function resetChecked() {
            vm.checkedAny = false;
            vm.checked = false;
        }

        function isTurmaObrigatorio(situacao){
            return (((vm.permiteMultiturno || vm.isMultiturno) && vm.isTurnoIntegral()) || (vm.isProgressaoParcial())) &&
                !(vm.edit && situacao !== 'MATRICULADO');
        }

        function showMultiturno() {
            if (!_.get($scope.matricula, 'matriz.id')) {
                return;
            }
            var params = {
                idEstabelecimento: $scope.matricula.estabelecimento.id,
                idMatriz: $scope.matricula.matriz.id
            };

            vm.tracker.loadingConfigMultiturno.addPromise(
                ConfiguracoesMatriculaService.permiteMultiturno(params)
                .then(permiteMultiturno)
            );

            function permiteMultiturno(data) {
                vm.permiteMultiturno = data.permite;
            }

        }

        function isTurmaMultisseriada(turma){
            return ETAPAS_INEP_TURMAS_MULT.CODIGO_INEP.indexOf(_.get(turma, 'etapaEnsinoInep.id')) !== -1;
        }

        function isNivelEscolarCompativel(nivelEscolar) {
            if (!$scope.matricula.matriz) {
                return false;
            }

            if (!vm.edit) {
                clearCamposItinerariosFormativos(nivelEscolar);
                return $scope.matricula.matriz.curso.nivelEscolar === nivelEscolar;   
            }

            return $scope.matricula.vaga.matriz.curso.nivelEscolar === nivelEscolar;
        }

        function clearCamposItinerariosFormativos(nivelEscolar) {
            if ($scope.matricula.matriz.curso.nivelEscolar === nivelEscolar) {
                return;   
            }

            delete $scope.matricula.utilizaConcomitanteItinerarioFormativo;
            delete $scope.matricula.composicoesItinerariosFormativos;
            delete $scope.matricula.tipoCursoItinerarioFormativo;
            delete $scope.matricula.areasItinerariosFormativos;
        }
    }
})();

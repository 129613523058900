(function() {

	'use strict';

	angular.module('configuracao-avaliacao-turma')
		.controller('configuracao-avaliacao-turma.ConfirmacaoConfiguracaoAvaliacaoController', Controller);

	Controller.$inject = ['$modalInstance', 'bfc.Notification', 'educacao.context.ContextoAnoLetivo'];

	function Controller($modalInstance, Notification, ContextoAnoLetivo) {
		var vm = this;

		vm.confirmarAlteracao = confirmarAlteracao;
		vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;

		vm.turmas = _.cloneDeep($modalInstance.params.turmas) || {};

		function confirmarAlteracao(isConfirmedByUser) {
			if (isConfirmedByUser && vm.confirmacao !== vm.anoLetivo) {
				Notification.publish('O campo de confirmação foi preenchido incorretamente', 'error');
				return;
			}

			$modalInstance.close(isConfirmedByUser);
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.ItemEducacionalService', Service);

    Service.$inject = [];

    function Service() {

        var tipoAtendimentoAtividade = ['ATIVIDADE_COMPLEMENTAR', 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO'];

        return {
            getLabel: getLabel,
            isAtividade: isAtividade,
            isEjaModular: isEjaModular,
            isCamposExperiencia: isCamposExperiencia
        };

        function getLabel(tipoAtendimento) {
            return tipoAtendimentoAtividade.indexOf(tipoAtendimento) > -1 ? 'Atividade(s)' : 'Componente curricular';
        }

        function isAtividade(tipoAtendimento) {
            return tipoAtendimentoAtividade.indexOf(tipoAtendimento) > -1;
        }

        function isEjaModular(turma){
            return !!_.find(turma,'modular', true) && !!_.find(turma, 'curso.nivelModalidade', 'EJA');
        }

        function isCamposExperiencia(turma){
            return turma.matriz && turma.matriz.tipoOrganizacao === 'CAMPOS_EXPERIENCIA';
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.FuncionariosCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.funcionario.FuncionarioService',
        'bfc.Notification',
        'educacao.context.ContextoEstabelecimento'
    ];

    function Controller(promiseTracker,
        $modalStates,
        FuncionarioService,
        Notification,
        ContextoEstabelecimento
    ) {

        var vm = this;
        var momentToday = moment();

        vm.tracker = {};
        vm.filtroSituacao = false;
        vm.tracker.loading = promiseTracker();
        vm.openCad = openCad;
        vm.remove = remove;
        vm.situacoesFuncionario = FuncionarioService.getSituacaoFuncionario();
        vm.FuncionarioService = FuncionarioService;
        vm.isAniversariante = isAniversariante;
        vm.filtraAniversariante = filtraAniversariante;
        vm.filtraSituacaoFuncionario = filtraSituacaoFuncionario;
        vm.limparFiltro = limparFiltro;
        vm.listagemControls = {};
        vm.isEstabelecimento = isEstabelecimento;
        vm.isSecretaria = false;

        vm.cpfCustomBuilder = cpfCustomBuilder;

        vm.filtroCargos = [];
        vm.filtroEstabelecimentos = [];
        vm.filtroCodigoMatricula = [];

        function remove(data) {
            FuncionarioService.remove(data).then(notificarExclusao, search);
        }

        function limparFiltro() {
            vm.filtroSituacao = false;
            vm.filtroDataNascimento = false;
            vm.filtrarDataAdmissaoInicial = null;
            vm.filtrarDataAdmissaoFinal = null;
            vm.filtrarCargos = [];
            vm.filtrarEstabelecimentos = [];
            vm.filtroCodigoMatricula = [];
            vm.isSecretaria = false;
        }

        function notificarExclusao() {
            Notification.publish('Funcionário(a) excluído(a) com sucesso', 'success');
        }

        vm.expressionCustomBuilders = {
            onBeforeBuild: function() {
                var filtros = [];
                if (vm.filtroDataNascimento) {
                    var dia = moment(vm.filtroDataNascimento, 'MM/DD').format('DD');
                    var mes = moment(vm.filtroDataNascimento, 'MM/DD').format('MM');

                    filtros.push('mesNascimento = "' + mes + '" and diaNascimento = "' + dia + '"');
                }

                if (!_.isEmpty(vm.filtroSituacao)) {
                    filtros.push('situacao in ("' + vm.filtroSituacao.join('","') + '")');
                }

                if (vm.filtrarDataAdmissaoInicial) {
                    filtros.push('dataAdmissao >= ' + vm.filtrarDataAdmissaoInicial);
                }

                if (vm.filtrarDataAdmissaoFinal) {
                    filtros.push('dataAdmissao <= ' + vm.filtrarDataAdmissaoFinal);
                }

                if (!_.isEmpty(vm.filtrarCargos)) {
                    var cargoId = _.map(vm.filtrarCargos, 'id').join(',');
                    filtros.push('cargo.id in (' + cargoId + ')');
                }

                if (!_.isEmpty(vm.filtrarEstabelecimentos)) {
                    var estabelecimentoIds = _.chain(vm.filtrarEstabelecimentos)
                        .filter(function(estabelecimento) {
                            return estabelecimento.id !== 'Secretaria';
                        })
                        .map('id')
                        .value();
                
                    var estabelecimentoIsNull = _.some(vm.filtrarEstabelecimentos, function(estabelecimento) {
                        return estabelecimento.id === 'Secretaria';
                    });

                    filtros.push(buildFiltroEstabelecimento(estabelecimentoIsNull, estabelecimentoIds));
                }
                

                if (!_.isEmpty(vm.filtroCodigoMatricula)) {
                    filtros.push('matricula = "' + vm.filtroCodigoMatricula + '"');
                }

                return filtros.join(' and ');
            }
        };

        function cpfCustomBuilder(filters) {
            return 'cpf elike \'' + filters.replace(/\.|-/g, '') + '\'';
        }

        function openCad(funcionario) {
            $modalStates.goModalState(funcionario);
        }

        function isAniversariante(aluno) {
            var dataNascimento = moment(aluno.pessoa.dataNascimento);
            return dataNascimento.date() === momentToday.date() &&
                dataNascimento.month() === momentToday.month();
        }

        function filtraAniversariante() {
            limparFiltro();
            var dia = moment(new Date(), 'MM/DD').format('DD');
            var mes = moment(new Date(), 'MM/DD').format('MM');
            vm.filtroDataNascimento = mes + '-' + dia;
            search();
        }

        function search() {
            vm.listagemControls.search();
        }

        function filtraSituacaoFuncionario(situacao) {
            limparFiltro();
            vm.filtroSituacao = [situacao];
            search();
        }

        function isEstabelecimento() {
            return !ContextoEstabelecimento.isSecretaria();
        }

        function buildFiltroEstabelecimento(estabelecimentoIsNull, estabelecimentoIds) {
            var filtro = '(';
            if (estabelecimentoIsNull) {
                filtro += 'estabelecimento is null';
                if (estabelecimentoIds.length) {
                  filtro += ' or ';
                }
              }
              if (estabelecimentoIds.length) {
                filtro += 'estabelecimento in (' + estabelecimentoIds.join(',') + ')';
              }
              filtro += ')';
              return filtro;
        }

    }
})();

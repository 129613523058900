(function () {
    'use strict';
    angular.module('educacao.common')
        .service('educacao.common.MetadataServiceHelper', MetadataServiceHelper);

    MetadataServiceHelper.$inject = ['$q'];

    function MetadataServiceHelper($q) {
        return {
            create: create
        };

        function create(endpoint) {
            return new MetadataHelper(endpoint);
        }

        function MetadataHelper(endpoint) {
            var deferred = $q.defer();
            var metadataPromise = deferred.promise;

            this.getDefaultRepresentation = getDefaultRepresentation;
            this.enumValues = enumValues;
            this.getEnumValues = getEnumValues;
            this.enumObject = enumObject;
            this.getEnum = getEnum;

            endpoint.options().then(function (metadata) {
                deferred.resolve(metadata.plain());
            });

            function getDefaultRepresentation() {
                return metadataPromise.then(function (metadata) {
                    return angular.copy(metadata.representation.default);
                });
            }

            /**
             retorna uma função que recupera um enum através de uma promise.
             usado para remover funções de recuperação de enum no service
             */
            function enumValues(enumName) {
                return function () {
                    return getEnumValues(enumName);
                };
            }

            function getEnumValues(enumName) {
                return metadataPromise.then(function (metadata) {
                    return angular.copy(metadata.enums[enumName].values);
                });
            }

            function enumObject(enumName) {
                return function () {
                    return getEnum(enumName);
                };
            }

            function getEnum(enumName) {
                return getEnumValues(enumName).then(createEnumFromValues);
            }

            function createEnumFromValues(values) {
                var en = {};
                values.forEach(function (entry) {
                    entry.id = entry.id || entry.key;
                    en[entry.key] = entry;
                });
                en.values = values;
                return en;
            }
        }
    }
})();

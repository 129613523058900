(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .directive('appFiltroAvancadoAlunoDocumentos', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'matricula/matriculas/filtro-avancado/aluno/documentos/documentos.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorDocumentos: '=',
            },
            bindToController: true
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.pessoas.AlunoService'
    ];

    function Controller(
        $scope,
        AlunoService
    ) {
        var vm = this;

        vm.formatResultOrgaoEmissor = formatResultOrgaoEmissor;
        vm.formatResultEnum = formatResultEnum;
        vm.changeContadorDocumentos = changeContadorDocumentos;
        vm.ENUM_MODELO_CERTIDAO_NOVO = 'NOVO';

        vm.expressionCustomBuildersDocumentos = {
            rg: function (filters) {
                if (filters.rg) {
                    return 'rg = "' + filters.rg + '"'; 
                }
            },
            RIC: function (filters) {
                if (filters.RIC) {
                    return 'RIC = "' + filters.RIC + '"';
                }
            },
            naturalidade: function (filters) {
                if (filters.naturalidade) {
                    return 'municipioNascimento = ' + filters.naturalidade.id;
                }
            },
            orgaoEmissorRG: function (filters) {
                if (filters.orgaoEmissorRG) {
                    return 'orgaoEmissorRG = ' + filters.orgaoEmissorRG.id;
                }
            },
            municipioDocumento: function (filters) {
                if (filters.municipioDocumento) {
                    return 'municipioDocumento = ' + filters.municipioDocumento.id;
                }
            },
            numeroCertidao: function (filters) {
                if (filters.numeroCertidao) {
                    return 'numeroCertidao = "' + filters.numeroCertidao + '" or numeroTermo = "' + filters.numeroCertidao + '"';
                }
            },
            dataEmissaoRG: function (filters) {
                if(filters.dataEmissaoRG){
                    return 'dataEmissaoRG = ' + filters.dataEmissaoRG;
                }
            },
            dataEmissaoCNS: function (filters) {
                if(filters.dataEmissaoCNS){
                    return 'dataEmissaoCNS = ' + filters.dataEmissaoCNS;
                }
            },
            dataEmissaoRIC: function (filters) {
                if(filters.dataEmissaoRIC){
                    return 'dataEmissaoRIC = ' + filters.dataEmissaoRIC;
                }
            },
            dataEmissaoCertidaoCivil: function (filters) {
                if(filters.dataEmissaoCertidaoCivil){
                    return 'dataEmissaoCertidaoCivil = ' + filters.dataEmissaoCertidaoCivil;
                }
            },
            dataEmissaoCTPS: function (filters) {
                if(filters.dataEmissaoCTPS){
                    return 'dataEmissaoCTPS = ' + filters.dataEmissaoCTPS;
                }
            },
            dataEmissaoPIS: function (filters) {
                if(filters.dataEmissaoPIS){
                    return 'dataEmissaoPIS = ' + filters.dataEmissaoPIS;
                }
            }
        };

        $scope.$watchGroup([
            'vm.advancedFilter.documentos.naturalidade',
            'vm.advancedFilter.documentos.orgaoEmissorRG',
            'vm.advancedFilter.documentos.cartorioCasamento'
        ], changeContadorDocumentos);

        init();

        function init() {
            vm.expressionCustomBuilders = Object.assign(vm.expressionCustomBuilders, vm.expressionCustomBuildersDocumentos);
            vm.contadorDocumentos = 0;
            vm.EnumUf = AlunoService.getUF();
            vm.EnumCertidaoCivil = AlunoService.getCertidaoCivil();
            vm.EnumModeloCertidaoCivil = AlunoService.getModeloCertidao();
            vm.EnumJustificativaFaltaDocumentacao = AlunoService.getJustificativaFaltaDocumentacao();
            changeContadorDocumentos();
        }
    
        function formatResultOrgaoEmissor(value) {
            return value.nome || value.descricao;
        }
    
        function formatResultEnum(data) {
            return data.value;
        }

        function changeContadorDocumentos() {
            if (vm.advancedFilter && vm.advancedFilter.documentos) {
                vm.contadorDocumentos = getFilterLength();
            }

            verificaModeloCertidao();
        }

        function verificaModeloCertidao(){
            if(vm.advancedFilter.documentos.modeloCertidao === vm.ENUM_MODELO_CERTIDAO_NOVO){
                delete vm.advancedFilter.documentos.municipioDocumento;
            }
        }

        function getFilterLength(){
            return Object.values(vm.advancedFilter.documentos).filter(function (filter) { return filter; }).length;
        }
    }
})();

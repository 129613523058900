(function() {

    'use strict';

    angular.module('educacao.configuracao-evasao-reprovacao')
        .service('educacao.configuracao-evasao-reprovacao.ConfiguracaoEvasaoReprovacaoService', ConfiguracaoEvasaoReprovacaoService);

    ConfiguracaoEvasaoReprovacaoService.$inject = [
        'Restangular'
    ];

    function ConfiguracaoEvasaoReprovacaoService(Restangular) {

        var self = this;

        self.getLoteTreinamento = getLoteTreinamento;
        self.verificarRecurso = verificarRecurso;
        self.criarLote = criarLote;
        self.getResultados = getResultados;

        function getBasePath() {
            return Restangular.all('machine-learning').all('risco-evasao-reprovacao');
        }

        function getLoteTreinamento() {
            return getBasePath().all('lote-treinamento').get('');
        }

        function verificarRecurso() {
            return getBasePath()
                .all('verificar-recurso')
                .get('');
        }

        function criarLote() {
            return getBasePath().all('lote-treinamento').customPOST({});
        }

        function getResultados() {
            return getBasePath().all('resultado-treinamento').get('');
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosOcupacaoAulasService', QuadroFuncionariosOcupacaoAulasService);

    QuadroFuncionariosOcupacaoAulasService.$inject = [
        'ServiceBuilder',
        'Restangular',
    ];

    function QuadroFuncionariosOcupacaoAulasService(ServiceBuilder, Restangular) {
        var baseUrl = 'ocupacao-aulas-quadro-informativo';

        var service = ServiceBuilder.create()
            .path(baseUrl)
            .build();

        service.listSaldosVisaoGeral = listSaldosVisaoGeral;
        service.getSaldosTotais = getSaldosTotais;
        service.getOne = getOne;

        function listSaldosVisaoGeral(params) {
            return Restangular.all(baseUrl).get('', params);
        }

        function getSaldosTotais(params) {
            return Restangular.all(baseUrl).one('saldos-totais').get(params).then(strip);
        }

        function getOne(id, params) {
            return Restangular.one(baseUrl, id).get(params).then(strip);
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }

        return service;
    }

})();
(function () {
    'use strict';

    angular.module('educacao.movimentoEscolar')
    .controller('educacao.movimentoEscolar.MovimentoEscolarMaisFiltrosCtrl', Controller);

    Controller.$inject = ['$scope', 
    '$modalInstance',
	'educacao.matricula.TurmaService',
    'educacao.movimentoEscolar.MovimentoEscolarService'
    ];

 
    function Controller($scope, $uiModal,  TurmaService, MovimentoEscolarService){
    	
    	var vm = this;

    	vm.cancel = cancel;
    	vm.continuar = continuar;
    	vm.filtrosModal = $uiModal.params.filtrosSelecionados || {};
        
        vm.filtrosAnteriores = _.clone(vm.filtrosModal);

    	vm.listTurno = TurmaService.getTurno();
        vm.listEtapas = MovimentoEscolarService.getEquivalenciaEtapa();

    	function continuar(){
    		$uiModal.close(vm.filtrosModal);
    	}

    	function cancel() {
            $uiModal.close(vm.filtrosAnteriores);
        } 
    }
})();
(function () {

    'use strict';

    angular.module('educacao.geracaoHorarios').directive('appControlDiasSemana', ControlDiasSemanaDirective);

    ControlDiasSemanaDirective.$inject = [];
    function ControlDiasSemanaDirective() {
        return {
            restrict: 'E',

            templateUrl: 'geracao-horarios/aulas/app-control-dias-semana.html',
            scope: {
                columns: '=ngModel',
                weekDays: '='
            },
            controller: ControlDiasSemanaDirectiveController,
            controllerAs: 'vm'
        };
    }

    ControlDiasSemanaDirectiveController.$inject = ['$scope'];
    function ControlDiasSemanaDirectiveController($scope) {
        var vm = this;
        vm.weekDays = [];
        vm.columns = $scope.columns;
        vm.changeVisibility = changeVisibility;

        $scope.$watch('weekDays', function (value) {
            vm.weekDays = value;
            vm.columns.numVisibles = 0;
            _.forEach(value, function (day) {
                var col = vm.columns[day.key] = vm.columns[day.key] || {
                        key: day.key,
                        visible: true
                    };

                if (col.visible) {
                    vm.columns.numVisibles++;
                }
            });
        });

        function changeVisibility(key) {
            var col = vm.columns[key];
            if (vm.columns.numVisibles === 1 && col.visible) {
                return;
            }
            col.visible = !col.visible;
            if (col.visible) {
                vm.columns.numVisibles++;
            } else {
                vm.columns.numVisibles--;
            }
        }
    }
})();

(function(){

	'use strict';

	angular.module('educacao.matriz')
		.config(stateConfig);

	stateConfig.$inject = [
		'$stateProvider',
		'STATES_ADICIONAR',
		'STATES_EDITAR'
	];

	function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
		var STATE = 'app.matriz.unidadestematicas';

		$stateProvider.state(STATE, {
			url: 'unidades-tematicas',
			data: {
				id: 'UnidadesTematicasPageMapping'
			},
			views: {
				'@': {
					templateUrl: 'planejamento/matriz/unidades-tematicas/listagem-unidades-tematicas.html',
					controller: 'educacao.matriz.UnidadesTematicasCtrl as vm'
				}
			}
		}).state(STATE + STATES_ADICIONAR.state, {
			url: STATES_ADICIONAR.url,
			onEnter: openModal,
			data: {
				isModalState: true
			}
		}).state(STATE + STATES_EDITAR.state, {
			url: STATES_EDITAR.url,
			onEnter: openModal,
			data: {
				isModalState: true
			}
		});

		openModal.$inject = [
			'$stateParams',
			'educacao.matriz.UnidadeTematicaService',
			'$modalStates'
		];

		function openModal($stateParams, UnidadeTematicaService, $modalStates) {
			$modalStates.open({
				getMethod: UnidadeTematicaService.get,
				templateUrl: 'planejamento/matriz/unidades-tematicas/cadastro-unidades-tematicas.html',
				controller: 'educacao.matriz.UnidadesTematicaCadCtrl as vm',
				propertyName: 'unidadeTematica',
				size: 'sm',
				stateParams: $stateParams
			});
		}
	}

})();
(function() {

    'use strict';

    angular.module('educacao.rematricula', [
    	'ui-components',
    	'bfc',
    	'educacao.common'
    	]);

})();


(function () {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.FuncionarioMatriculaHistoricoPopoverController', FuncionarioMatriculaHistoricoPopoverController);

    FuncionarioMatriculaHistoricoPopoverController.$inject = ['educacao.common.$commons', '$popoverInstance',
        'educacao.funcionario.FuncionarioMatriculaService'];
    function FuncionarioMatriculaHistoricoPopoverController($commons, $popoverInstance, MatriculaService) {

        var vm = this;

        vm.matricula = $popoverInstance.params.matricula;

        vm.openHistoricoModal = openHistoricoModal;

        init();

        function init() {
            var promise = MatriculaService.getHistoricos(vm.matricula.id, {
                offset: 0,
                limit: 3
            }).then(function (page) {
                vm.historicos = page.content;

                $commons.$timeout(function () {
                    $popoverInstance.position();
                });
            });

            $popoverInstance.tracker.addPromise(promise);
        }

        function openHistoricoModal() {
            $commons.$modal.open({
                templateUrl: 'historico/historico-modal.html',
                controller: 'educacao.historico.HistoricoModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    title: 'Histórico da matrícula',
                    url: 'funcionario/matriculas/' + vm.matricula.id + '/historicos'
                }
            });
        }

    }
})();
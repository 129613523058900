(function () {
    'use strict';
    angular.module('educacao.abonojustificativa')
        .controller('educacao.abonojustificativa.AbonoJustificativaCadCtrl', Controller);

    Controller.$inject = ['$modalInstance', '$q', 'educacao.abonojustificativa.AbonoJustificativaService', 'promiseTracker', '$scope',
        '$rootScope',
        'educacao.common.ItemEducacionalService',
        'educacao.frequencia.FrequenciaService',
        'bfc.Notification'
    ];

    function Controller($modalInstance, $q, AbonoJustificativaService,
        promiseTracker, $scope, $rootScope, ItemEducacionalService, frequenciaService, bfcNotification) {

        var vm = this;
        var DATE_FORMAT = 'DD/MM/YYYY';

        vm.loadingTracker = promiseTracker();

        vm.configuracoes = _.cloneDeep($modalInstance.params.configuracoes) || {};
        vm.abono = _.cloneDeep($modalInstance.params.registro) || {};
        vm.abonoJustificativas = _.cloneDeep($modalInstance.params.abonoJustificativas) || [];

        vm.listItensEducacionais = listItensEducacionais;
        vm.listPeriodosAvaliativos = listPeriodosAvaliativos;
        vm.save = save;
        vm.setMotivo = setMotivo;

        var service = AbonoJustificativaService.buildService(vm.configuracoes.turma.id);

        vm.TipoAbono = service.getTipoAbono();
        vm.ModoDispensa = service.getTipoDispensaAbono();
        vm.isFaltasPorDia = vm.configuracoes.configuracao.tipoRegistroFrequencia === 'TOTAL_FALTAS_DIA';
        vm.isFaltasPorAula = vm.configuracoes.configuracao.tipoRegistroFrequencia === 'TOTAL_FALTAS_AULAS';
        vm.labelItemEducacional = ItemEducacionalService.getLabel(vm.configuracoes.turma.tipoAtendimento);
        vm.isAtividade = ItemEducacionalService.isAtividade(vm.configuracoes.turma.tipoAtendimento);
        vm.ultimoTamanhoArrayItensEducacionais = 0;
        vm.ultimoFormatoArray = [];
        vm.abono.$$itensEducacionais = [];
        vm.isModular = false;
        vm.filterPeriodo = null;

        vm.criaRegistroPraAbonarFaltas = criaRegistroPraAbonarFaltas;

        vm.loadingTracker.addPromise(service.motivosRecentes().then(function (dados) {
            vm.motivosRecentes = dados;
        }));

        vm.formatSelectionPeriodoAvaliativo = formatSelectionPeriodoAvaliativo;
        vm.configuracoes = $modalInstance.params.configuracoes;

        vm.modalTitle = vm.abono.id ? 'Editando abono ou justificativa' : 'Adicionando abono ou justificativa';

        vm.setHeaderQuantidadeFaltas = setHeaderQuantidadeFaltas;
        vm.setHeaderFaltasNaoJustificadas = setHeaderFaltasNaoJustificadas;

        init();

        function init() {
            if (vm.configuracoes.turma.etapaMatriz !== null) {
                if (vm.configuracoes.turma.etapaMatriz.modular === true) {
                    vm.abono.$$itensEducacionais.push(_.find(vm.configuracoes.itensEducacionais, {
                        'id': vm.configuracoes.aluno.registroFaltas.itemEducacional.id
                    }));
                    vm.isModular = true;
                }
            }

            if (!vm.isModular) {
                createWatches();
            }

            if (vm.abono.id) {
                getPeriodoAvaliativoSalvo();
                getAbonos();
                return;
            }

            setValuesAbono();
        }

        function createWatches() {
            $scope.$watch('vm.abono.periodoAvaliativo', criaRegistroPraAbonarFaltas);

            $scope.$watchCollection('vm.abono.$$itensEducacionais', function () {

                $modalInstance.tracker.addPromise(
                    $q.when(
                        criaRegistroPraAbonarFaltas()
                    )
                );
            });
        }

        function getPeriodoAvaliativoSalvo() {
            if (vm.configuracoes.periodoAtual) {
                vm.abono.periodoAvaliativo = vm.configuracoes.periodoAtual;
            }

            if (vm.isFaltasPorDia && !vm.isModular) {
                var periodoAvaliativoAtual = _.first(vm.configuracoes.itensEducacionais.filter(function (item) {
                    return item.id === vm.abono.periodoAvaliativo.id;
                }));

                vm.abono.periodoAvaliativo = periodoAvaliativoAtual;
            }
        }

        function getAbonos() {
            var filter = '';
            var append = '';

            if (vm.configuracoes.periodoAtual) {
                filter += 'periodoAvaliativo = ' + _.get(vm.configuracoes.periodoAtual, 'id') || -1;
                append = ' and ';
            }

            if (vm.configuracoes.aluno.enturmacao.id) {
                filter += append + 'enturmacao = ' + _.get(vm.configuracoes.aluno.enturmacao, 'id') || -1;
            }

            var paramsRequisicao = {
                filter: filter
            };

            service.completos(paramsRequisicao).then(function (dados) {
                vm.abonosJustificativas = _.first(dados).content;
                vm.abono.$$itensEducacionaisFinal = [];
                vm.abono.$$itensEducacionaisFinal.push(_.find(vm.abonosJustificativas, {
                    'id': vm.abono.id
                }));
                vm.quantidadeAbono = vm.abono.$$itensEducacionaisFinal.quantidadeAbono;

                if (vm.abono.motivoAbonoJustificativa) {
                    vm.motivoAbonoJustificativa = {
                        id: vm.abono.motivoAbonoJustificativa.id,
                        tipo: vm.abono.tipo, 
                        descricao: vm.abono.motivoAbonoJustificativa.descricao
                    };
                }

                setValuesAbono();
            });
        }

        function setValuesAbono() {
            if (vm.configuracoes.periodoAtual) {
                vm.abono.periodoAvaliativo = vm.configuracoes.periodoAtual;
            }

            if (vm.abono.id || vm.isFaltasPorDia) {
                vm.abono.itemEducacional = vm.abono.itemEducacional || {};
                vm.abono.itemEducacional.quantidadeAbono = vm.abono.quantidadeAbono;
                vm.abono.$$itensEducacionais = [];
                vm.abono.$$itensEducacionais.push(vm.abono.itemEducacional);
            }

            if (vm.isModular) {
                criaRegistroPraAbonarFaltas();
            }
        }

        function getRegistrosAbono() {
            service.registroAbono(vm.configuracoes.aluno.enturmacao.id, vm.ultimoItemEducacional.id,
                vm.filterPeriodo).then(setDadosAbono);
        }

        function setDadosAbono(dados) {
            if (!vm.periodosFalta) {
                vm.periodosFalta = dados.plain();
                vm.abono.$$itensEducacionaisFinal = vm.periodosFalta;
                vm.abono.$$itensEducacionaisFinal[0].quantidadeAbono = vm.abono.quantidadeAbono;

                if (vm.abono.$$itensEducacionaisFinal[0].faltasNaoJustificadas === null) {
                    vm.abono.$$itensEducacionaisFinal[0].faltasNaoJustificadas = 0;
                }

                return;
            }

            vm.periodosFalta.push(dados[0]);
            vm.abono.$$itensEducacionaisFinal = vm.periodosFalta;
            vm.abono.$$itensEducacionaisFinal[vm.abono.$$itensEducacionaisFinal.length - 1].quantidadeAbono = vm.abono.quantidadeAbono;
        }

        function criaRegistroPorAula() {
            if (vm.ultimoTamanhoArrayItensEducacionais < vm.abono.$$itensEducacionais.length) {
                vm.ultimoTamanhoArrayItensEducacionais = vm.abono.$$itensEducacionais.length;

                if (vm.configuracoes.turma.etapaMatriz.modular !== true) {
                    vm.itemEducacionalSelecionado = _.find(vm.configuracoes.aluno.registroFaltas, {
                        'itemEducacional': {
                            'id': vm.ultimoItemEducacional.id
                        },
                        'periodoAvaliativo': {
                            'id': vm.abono.periodoAvaliativo.id
                        }
                    });
                } else {
                    var temp = [];
                    temp.push(vm.configuracoes.aluno.registroFaltas);
                    vm.itemEducacionalSelecionado = _.find(temp, {
                        'itemEducacional': {
                            'id': vm.ultimoItemEducacional.id
                        }
                    });
                }

                if (vm.abono.periodoAvaliativo) {
                    vm.filterPeriodo = vm.abono.periodoAvaliativo.id;
                }

                getRegistrosAbono();

                vm.ultimoFormatoArray = vm.abono.$$itensEducacionais;

                return;
            }

            var itensEducacionaisAtual = vm.abono.$$itensEducacionais;
            var arrayTemp = [];
            var arrayTempFinal = [];

            for (var index = 0; index < itensEducacionaisAtual.length; index++) {
                var indexf = _.findIndex(vm.ultimoFormatoArray, {
                    'id': itensEducacionaisAtual[index].id
                });
                arrayTemp.push(indexf);
            }

            for (var i = 0; i < vm.ultimoFormatoArray.length; i++) {
                var posicao = _.findIndex(vm.ultimoFormatoArray, {
                    'id': vm.ultimoFormatoArray[i].id
                });
                arrayTempFinal.push(posicao);
            }

            var indexFinal = _.difference(arrayTempFinal, arrayTemp);
            _.pull(vm.ultimoFormatoArray, itensEducacionaisAtual);

            if (vm.periodosFalta) {
                vm.periodosFalta.splice(indexFinal, 1);
            }

            vm.abono.$$itensEducacionaisFinal = vm.periodosFalta;
            if (vm.abono.$$itensEducacionaisFinal) {
                vm.ultimoTamanhoArrayItensEducacionais = vm.abono.$$itensEducacionaisFinal.length;
            }

            vm.ultimoFormatoArray = vm.abono.$$itensEducacionais;
            if (!vm.itemEducacionalSelecionado) {
                vm.itemEducacionalSelecionado = null;
            }
        }

        function criaRegistroPraAbonarFaltas() {
            vm.buttonDisabled = false;

            if ((!vm.abono.periodoAvaliativo && !vm.isAtividade && vm.configuracoes.turma.etapaMatriz.modular !== true)) {
                return;
            }

            vm.ultimoItemEducacional = _.last(vm.abono.$$itensEducacionais);
            if (vm.isFaltasPorAula && vm.abono.$$itensEducacionais && !vm.isAtividade) {
                vm.filterPeriodo = null;
                criaRegistroPorAula();

                return;
            }

            if (!vm.isAtividade && !vm.isFaltasPorDia) {
                return;
            }

            if (vm.isAtividade && vm.ultimoItemEducacional) {
                vm.itemEducacionalSelecionado = _.find(vm.configuracoes.aluno.registroFaltas, {
                    'itemEducacional': {
                        'id': vm.ultimoItemEducacional.id
                    }
                });
            } else if (vm.isFaltasPorDia) {
                vm.itemEducacionalSelecionado = _.find(vm.configuracoes.aluno.registroFaltas, {
                    'periodoAvaliativo': {
                        'id': vm.abono.periodoAvaliativo.id
                    }
                });

                if (vm.abono.periodoAvaliativo) {
                    vm.filterPeriodo = vm.abono.periodoAvaliativo.id;
                }

                if (!_.get(vm.itemEducacionalSelecionado, 'id')) {
                    delete vm.abono.$$itensEducacionaisFinal;
                    bfcNotification.publish(
                        'Nenhuma falta lançada nesse Período.',
                        'error');

                    vm.buttonDisabled = true;

                    return;
                }
            }

            if (vm.itemEducacionalSelecionado !== undefined && vm.itemEducacionalSelecionado !== null) {
                var idsItensEducacionais = [];
                if (vm.abono.$$itensEducacionais && vm.isAtividade) {
                    idsItensEducacionais = _.pluck(vm.abono.$$itensEducacionais, 'id');
                } else {
                    idsItensEducacionais.push(vm.itemEducacionalSelecionado.itemEducacional.id);
                }

                if (!idsItensEducacionais.length && vm.isAtividade) {
                    vm.abono.$$itensEducacionaisFinal = '';
                    return;
                }

                service.registroAbono(vm.configuracoes.aluno.enturmacao.id, idsItensEducacionais,
                    vm.filterPeriodo).then(function (dados) {
                        var itensEducacionaisFinalOld = vm.abono.$$itensEducacionaisFinal;

                        vm.abono.$$itensEducacionaisFinal = dados.plain();

                        if (itensEducacionaisFinalOld && vm.abono.$$itensEducacionaisFinal) {
                            _.forEach(vm.abono.$$itensEducacionaisFinal, function (item) {
                                var itemOld = _.find(itensEducacionaisFinalOld, 'itemEducacional.id', item.itemEducacional.id);
                                item.quantidadeAbono = itemOld ? itemOld.quantidadeAbono : vm.abono.quantidadeAbono;
                            });
                        }
                    }, function () {
                        vm.abono.$$itensEducacionaisFinal = '';
                        bfcNotification.publish(
                            'Nenhuma falta lançada nesse Período.',
                            'error');
                    });
            }
        }

        function listItensEducacionais() {
            return frequenciaService.getItensEducacionais(vm.configuracoes.turma.id).then(function (data) {
                return {
                    content: data,
                    hasNext: false
                };
            });
        }

        function listPeriodosAvaliativos() {
            return frequenciaService.getPeriodosTurma(vm.configuracoes.turma.id).then(function (data) {
                return {
                    content: data,
                    hasNext: false
                };
            });
        }

        function formatSelectionPeriodoAvaliativo(periodoAvaliativo) {
            if (!periodoAvaliativo) {
                return;
            }
            return periodoAvaliativo.descricao + ' (' + moment(periodoAvaliativo.dataInicial).format(DATE_FORMAT) + ' até ' + moment(periodoAvaliativo.dataFinal).format(DATE_FORMAT) + ')';
        }

        function setMotivo(motivo) {
            vm.abono.motivo = motivo;
        }

        function setValuesListagem(itemEducacionalFinal, abonos, enturmacoesToRefresh) {
            if (itemEducacionalFinal.registroFaltas !== null && itemEducacionalFinal.registroFaltas.quantidadeFaltas >= itemEducacionalFinal.quantidadeAbono) {
                var itemEducacionalToSave = null;
                if (vm.isFaltasPorDia) {
                    itemEducacionalToSave = vm.abono.itemEducacional.id ? vm.abono.itemEducacional : vm.abono.periodoAvaliativo;
                } else {
                    itemEducacionalToSave = {
                        publicacao: null,
                        situacao: null,
                        sigla: '',
                        id: itemEducacionalFinal.itemEducacional.id,
                        descricao: itemEducacionalFinal.itemEducacional.descricao,
                        quantidadeFaltas: itemEducacionalFinal.registroFaltas.quantidadeFaltas,
                        enturmacao: {
                            id: itemEducacionalFinal.registroFaltas.id
                        }
                    };
                }

                var abono = {
                    id: vm.abono.id,
                    itemEducacional: itemEducacionalToSave,
                    quantidadeAbono: itemEducacionalFinal.quantidadeAbono,
                    enturmacao: vm.itemEducacionalSelecionado.enturmacao,
                    tipo: vm.motivoAbonoJustificativa.tipo,
                    dispensa: vm.abono.dispensa,
                    motivo: vm.abono.motivo,
                    periodoAvaliativo: vm.abono.periodoAvaliativo,
                    motivoAbonoJustificativa: vm.motivoAbonoJustificativa
                };

                var found = _.indexOf(enturmacoesToRefresh, vm.quantidadeAbono);

                if (found < 0) {
                    enturmacoesToRefresh.push(vm.quantidadeAbono);
                }
                abonos.push(abono);

                return;
            }

            return bfcNotification.publish(
                itemEducacionalFinal.itemEducacional.descricao +
                ' - As faltas justificadas/abonadas não podem ser maiores que o número de faltas não justificadas/abonadas.',
                'error');
        }

        function save() {
            var abonos = [];
            var enturmacoesToRefresh = [];

            vm.abono.$$itensEducacionaisFinal.forEach(function (itemEducacionalFinal) {
                setValuesListagem(itemEducacionalFinal, abonos, enturmacoesToRefresh);
            });

            if (vm.abono.$$itensEducacionaisFinal.length === abonos.length) {
                if (vm.configuracoes.permissionsPeriodoEncerrado) {
                    vm.loadingTracker.addPromise(service.savePeriodoEncerrado(abonos)
                        .then(saveResult));
                    return;
                }

                vm.loadingTracker.addPromise(service.save(abonos)
                    .then(saveResult));
            }

            function saveResult() {
                enturmacoesToRefresh.forEach(function (idEnturmacao) {
                    $rootScope.$broadcast('diarioClasse::refreshFaltas', {
                        idEnturmacao: idEnturmacao,
                        idTurma: vm.configuracoes.turma.id,
                        aluno: vm.configuracoes.aluno
                    });
                });

                $modalInstance.close(vm.abono);
            }
        }

        function setHeaderQuantidadeFaltas(tipo) {
            var tipoAbono = vm.abono.id ? vm.abono.tipo : tipo; 
            return tipoAbono === 'ABONO' ? 'Faltas não abonadas' : 'Faltas não justificadas';
        }

        function setHeaderFaltasNaoJustificadas(tipo) {
            var tipoAbono = vm.abono.id ? vm.abono.tipo : tipo; 
            return tipoAbono === 'ABONO' ? 'Faltas abonadas' : 'Faltas justificadas';
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('edSelectEnturmacaoTurma', EdSelectEnturmacaoTurma);

    EdSelectEnturmacaoTurma.$inject = [];

    function EdSelectEnturmacaoTurma() {
        return {
            template: '<ui-select ng-model="ngModel" search="vm.search" ng-required="!!notNull" format-result="vm.formatResult(data)"></ui-select>',
            restrict: 'E',
            require: 'ngModel',
            controller: EdSelectEnturmacaoTurmaCtrl,
            controllerAs: 'vm',
            scope: {
                notNull: '=',
                ngModel: '=',
                enturmacoes: '=',
                idsSelecionados: '=',
                ngChange: '='
            },
            compile: function($element, $attrs) {
				var multiple = angular.isDefined($attrs.multiple);
				if (multiple) {
					$element.children('ui-select').attr('multiple', true);

					if (angular.isDefined($attrs.ngRequired)) {
						$element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function($scope) {
						$scope.multiple = multiple;
					}
				};
			}
        };
    }

    EdSelectEnturmacaoTurmaCtrl.$inject = ['$scope', '$q'];

    function EdSelectEnturmacaoTurmaCtrl($scope, $q) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        $scope.$watch('ngModel', function(newValue, oldValue){
            if(newValue === oldValue){
                return;
            }

            if(_.isFunction($scope.ngChange)){
                $scope.ngChange(newValue);
            }
        });

        function search(params) {
            var termo = decodeURI(params.term);
            termo = termo.replace(/%/g, '');
            
            return $q.when({
                content: _.filter($scope.enturmacoes, function(enturmacao) {
                    var filtro = !enturmacao.dataFim && (!$scope.idsSelecionados || !$scope.idsSelecionados.includes(enturmacao.id));
                    if(termo){
                        filtro = filtro && enturmacao.matricula.nome.toLowerCase().includes(termo.toLowerCase());
                    }
                    return filtro;
                }),
                hasNext: false
            });
        }

        function formatResult(value) {
            return value.matricula.nome;
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoSaldosParciaisUtils', SaldosParciaisUtils);

    SaldosParciaisUtils.$inject = [
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService'
    ];

    function SaldosParciaisUtils(VisaoEstabelecimentoService) {
        var vm = this;

        vm.search = search;
        vm.getSaldosParciaisParams = getSaldosParciaisParams;

        var FILTERS = {
            'Modalidade': 'modalidade',
            'Matriz curricular': 'matriz',
            'Turno': 'turno',
            'Componente curricular': 'idComponenteCurricular',
        };

        vm.FILTER_OPTIONS = {
            Modalidade: 'Modalidade',
            MatrizCurricular: 'Matriz curricular',
            Turno: 'Turno',
            ComponenteCurricular: 'Componente curricular',
        };

        function search(params, saldosParciaisTypeFilter, estabelecimentoId) {
            if (!saldosParciaisTypeFilter) {
                return;
            }

            if (saldosParciaisTypeFilter === vm.FILTER_OPTIONS.MatrizCurricular) {
                return searchMatriz(params, estabelecimentoId);
            }

            if (saldosParciaisTypeFilter === vm.FILTER_OPTIONS.ComponenteCurricular) {
                return searchComponentesCurriculares(params, estabelecimentoId);
            }
        }

        function getSaldosParciaisParams(saldosParciaisValueFilter, saldosParciaisEtapaFilter, saldosParciaisTypeFilter, saldosParciaisNivelEscolarFilter, isEnumFilter, matrizesIds, turmaFilter) {
            var filter = [];

            if(!_.isEmpty(matrizesIds)) {
                filter.push('matriz in (' + matrizesIds.join() + ')');
            }

            if(!_.isEmpty(turmaFilter)) {
                filter.push('turma in (' + _.map(turmaFilter, 'id').join() + ')');
            }

            if(saldosParciaisTypeFilter && saldosParciaisValueFilter) {
                filter.push(FILTERS[saldosParciaisTypeFilter] + ' = ' + getFilterValue(isEnumFilter, saldosParciaisValueFilter));

                if(saldosParciaisTypeFilter === vm.FILTER_OPTIONS.MatrizCurricular && saldosParciaisEtapaFilter) {
                    filter.push('etapa = ' + saldosParciaisEtapaFilter.id);
                }

                if(saldosParciaisTypeFilter === vm.FILTER_OPTIONS.Modalidade && saldosParciaisNivelEscolarFilter) {
                    filter.push('nivelEscolar = "' + saldosParciaisNivelEscolarFilter + '"');
                }
            }

            return { 
                filter: filter.join(' and '), 
                turmasIds: turmaFilter,
                componenteCurricular: getComponenteCurricularFilter(saldosParciaisValueFilter, saldosParciaisTypeFilter)
            };
        }

        function getFilterValue(isEnumFilter, saldosParciaisValueFilter) {
            if (isEnumFilter) {
                return '"' + saldosParciaisValueFilter + '"';
            }

            return saldosParciaisValueFilter.id;
        }

        function getComponenteCurricularFilter(saldosParciaisValueFilter, saldosParciaisTypeFilter) {
            if (saldosParciaisTypeFilter === vm.FILTER_OPTIONS.ComponenteCurricular && saldosParciaisValueFilter) {
                return saldosParciaisValueFilter.id;
            }

            return null;
        }

        function searchMatriz(params,estabelecimentoId) {
            var paramsReq = {
                filter: '(descricao like "' + params.term + '")',
            };

            return VisaoEstabelecimentoService
                    .getMatrizes(estabelecimentoId,paramsReq)
                    .then(toPageContent);
        }

        function searchComponentesCurriculares(params,estabelecimentoId) {
            var filter = [];

            if(estabelecimentoId) {
                filter.push('estabelecimento = ' + estabelecimentoId);
            }

            if(params.term) {
                filter.push('descricao like "' + params.term + '"');
            }

            var paramsReq = {
                filter: filter.join(' and ')
            };

            return VisaoEstabelecimentoService
                .getComponentesCurriculares(paramsReq)
                .then(toPage);
        }

        function toPageContent(list) {
            return {
                content: list.content,
                hasMore: false
            };
        }

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .factory('educacao.enderecos.PaisService', Service);

    Service.$inject = [
        'ServiceBuilder'
    ];

    function Service(ServiceBuilder) {
        var baseUrl = 'pais';

        return ServiceBuilder.create()
                .path(baseUrl)
                .build();
    }
})();
(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .factory('educacao.enderecos.EstadoService', Service);

    Service.$inject = [
        'ServiceBuilder',
        'Restangular',
        'ui.components.Modal',
    ];

    function Service(ServiceBuilder, Restangular,$modal) {
        var baseUrl = 'estado';

        var service = ServiceBuilder.create()
                .path(baseUrl)
                .build();

        service.getList = getList;
        service.getAll = getAll;
        service.getAllDefault = getAllDefault;
        service.getAllUser = getAllUser;
        service.openModal = openModal;

        function openModal(nome) {
           return  $modal.open({
                templateUrl: 'enderecos/estado/cadastro-estado/cadastro-estado.html',
                controller: 'educacao.enderecos.CadastroEstadoController',
                propertyName: 'estado',
                controllerAs: 'vm',
                params: {
                    estado: {
                        nome: nome
                    },
                    canSaveAdd: false
                },
                size: 'lg'
            }).result;
        }
        function getList(offset, limit, filter) {
            return Restangular.all('estado').get('',{
                offset: offset,
                limit: limit,
                filter: filter
            })
            .then(Restangular.strip);
        }

        function getAll(params) {
            return Restangular.all(baseUrl).get('', params);
        }

        function getAllDefault(params) {
            params.isDefault = true;
            return getAll(params);
        }

        function getAllUser(params) {
            params.isDefault = false;
            return getAll(params);
        }

        return service;
    }
})();
(function() {
    'use strict';

    angular.module('educacao.formulaCalculo')
        .controller('educacao.formulaCalculo.FormulaCalculoCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        'ui.components.Modal',
        'API_URL',
        'bfc.ScriptEditor',
        'educacao.formulaCalculo.FormulaCalculoService',
        '$scope'
    ];

    function Controller(
        promiseTracker,
        $modal,
        API_URL,
        ScriptEditor,
        FormulaCalculoService,
        $scope
        
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.FormulaCalculoService = FormulaCalculoService;
        vm.TipoFormulaCalculo = [];
        vm.enumTipoFormulaCalculoPromisse = FormulaCalculoService.getTipoFormulaCalculo();
        vm.editarScript = editarScript;

        

        $scope.$on('formulacalculo::refreshlist', refresh);

        function refresh() {
            if ($scope.$$childTail) {
                $scope.$$childTail.$$search();
            }
        }

        init();

        function init() {
            FormulaCalculoService.getTipoFormulaCalculo().then(function(data) {
                vm.TipoFormulaCalculo = data;
            });
        }

        function editarScript(formulaToEdit) {
            ScriptEditor.modal({
                resource: API_URL.concat('/formula-calculo/' + formulaToEdit.id + '/script'),
                title: 'Editando script de fórmula de cálculo: ' + vm.TipoFormulaCalculo[formulaToEdit.tipoFormulaCalculo].description,
                afterSave: refresh
            });
        }

        function openCad(formulaCalculo) {
            $modal.open({
                templateUrl: 'formula-calculo/formula-calculo.cad.html',
                controller: 'educacao.formulaCalculo.FormulaCalculoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    formulaCalculo: angular.copy(formulaCalculo)
                }
            });
        }

        function remove(data) {
            FormulaCalculoService.remove(data);
        }
    }
})();


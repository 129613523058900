(function () {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioEventoValidatorService', CalendarioEventoValidatorService);

    CalendarioEventoValidatorService.$inject = ['educacao.context.ContextoAnoLetivo'];

    function CalendarioEventoValidatorService(ContextoAnoLetivo) {

        return {
            validate: validate,
            eventEquals: eventEquals
        };

        function validate(calendario, evento) {
            var validationMessages = [];

            validateTrue(validationMessages, 'Data inicial deve ser preenchida', evento.dataInicial);
            validateTrue(validationMessages, 'Data final deve ser preenchida', evento.dataFinal);
            validateTrue(validationMessages, 'Evento deve ser preenchido', evento.evento);

            if (!validationMessages.length) {
                validateSameEventSameDate(validationMessages, evento, calendario.eventos);
                validateDataInicialBeforeDataFinal(validationMessages, evento);
            }

            validateEventoPeriodoLetivo(validationMessages, calendario, evento);
            validateEventoPeriodoAnos(validationMessages, evento);

            return validationMessages;
        }

        function validateTrue(validationMessages, message, value) {
            if (!value) {
                validationMessages.push(message);
            }
        }

        function validateSameEventSameDate(validationMessages, evento, eventos) {
            (eventos || [])
                .forEach(function (registered) {
                    if (!eventEquals(evento, registered) &&
                        isSameEvent(evento, registered) &&
                        isConflictingDate(evento, registered) &&
                        (!evento.estabelecimentos.length || !registered.estabelecimentos.length || !angular.equals(evento.estabelecimentos, registered.estabelecimentos))) {
                        validationMessages.push('O evento ' + evento.evento.descricao + ' já foi associado a estas datas');
                        return false;
                    }
                });
        }

        function isSameEvent(evento, registered) {
            return evento.evento.id === registered.evento.id;
        }

        function eventEquals(evento1, evento2) {
            if (evento1.id || evento2.id) {
                return evento1.id === evento2.id;
            }
            return evento1.$$generatedId === evento2.$$generatedId;
        }

        function isConflictingDate(evento1, evento2) {
            var initialDate = moment(evento1.dataInicial);
            var finalDate = moment(evento1.dataFinal);

            var otherInitidalDate = moment(evento2.dataInicial);
            var otherFinalDate = moment(evento2.dataFinal);

            return isBetween(initialDate, otherInitidalDate, otherFinalDate) ||
                isBetween(finalDate, otherInitidalDate, otherFinalDate) ||
                isBetween(otherInitidalDate, initialDate, finalDate) ||
                isBetween(otherFinalDate, initialDate, finalDate);
        }

        function isBetween(date, initialDate, finalDate) {
            return !date.isBefore(initialDate) && !date.isAfter(finalDate);
        }

        function validateDataInicialBeforeDataFinal(validationMessages, evento) {
            var momentEventoInicial = moment(evento.dataInicial);
            var momentEventoFinal = moment(evento.dataFinal);

            validateTrue(validationMessages, 'A data inicial deve ser menor ou igual a data final', !momentEventoInicial.isAfter(momentEventoFinal, 'days'));
            validateTrue(validationMessages, 'A hora/minuto inicial deve ser menor ou igual a hora/minuto final', !momentEventoInicial.isAfter(momentEventoFinal, 'minutes'));

        }

        function validateEventoPeriodoLetivo(validationMessages, calendario, evento) {
            if (!evento.letivo) {
                return;
            }
            var momentCalendarioInicial = moment(calendario.dataInicial);
            var momentCalendarioFinal = moment(calendario.dataFinal);

            var momentEventoInicial = moment(evento.dataInicial);
            var momentEventoFinal = moment(evento.dataFinal);

            if (momentEventoInicial.isBefore(momentCalendarioInicial, 'days')) {
                validationMessages.push('A data inicial deve estar no período letivo');
            }

            if (momentEventoFinal.isAfter(momentCalendarioFinal, 'days')) {
                validationMessages.push('A data final deve estar no período letivo');
            }
        }

        function validateEventoPeriodoAnos(validationMessages, evento) {
            if (evento.letivo) {
                return;
            }

            var anoContext = ContextoAnoLetivo.getValorContextoAnoLetivo();

            var yearEventoInicial = moment(evento.dataInicial).year();
            var yearEventoFinal = moment(evento.dataFinal).year();

            if (Math.abs(anoContext - yearEventoInicial) > 1 ||
                Math.abs(anoContext - yearEventoFinal) > 1) {
                validationMessages.push('A data inicial e/ou final devem estar no mínimo no ano anterior e no máximo o ano posterior a partir do ano letivo selecionado');
            }
        }
    }
})();

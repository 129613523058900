(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.ObjetivosAprendizagemCtrl', Controller);

	Controller.$inject = [
		'promiseTracker',
		'educacao.matriz.ObjetivoAprendizagemService',
		'$modalStates'
	];

	function Controller(promiseTracker, ObjetivoAprendizagemService, $modalStates) {
		var vm = this;

		vm.remove = remove;
		vm.open = open;

		init();

		function init() {
			vm.objetivoAprendizagemService = ObjetivoAprendizagemService;

			vm.tracker = {
				loading: promiseTracker()
			};
		}

		function open(item) {
			$modalStates.goModalState(item);
		}

		function remove(objetivoAprendizagem) {
			return ObjetivoAprendizagemService.remove(objetivoAprendizagem);
		}
	}
})();

(function() {
	'use strict';

	var CLASS_TEXT_GRAY_8 = 'tx__gray--d10';
	var CLASS_TEXT_BLUE_A6 = 'tx__aqua--l10';

	angular.module('educacao.desempenhoescolar')
		.directive('appInputParecer', directive);

	function directive() {
		return {
			restrict: 'E',

			replace: true,
			templateUrl: 'desempenho-escolar/input-parecer/input-parecer.directive.html',

			scope: {},
			bindToController: {
				nota: '=',
				isEjaModular: '=',
				onChange: '&'
			},

			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = ['$scope', '$parecerDesempenhoModalService'];

	function Controller($scope, $parecerDesempenhoModalService) {
		var vm = this;

		vm.hasParecer = false;
		vm.classParecer = CLASS_TEXT_GRAY_8;

		vm.abreModalParecer = abreModalParecer;

		$scope.$watch('vm.nota.parecer', function(value) {
			vm.hasParecer = Boolean(value);
			vm.classParecer = vm.hasParecer ? CLASS_TEXT_BLUE_A6 : CLASS_TEXT_GRAY_8;
		});

		function abreModalParecer() {
			var params = {
				parecer: vm.nota.parecer,
				enturmacao: vm.nota.enturmacao
			};

			$parecerDesempenhoModalService.open(params).then(function(valor) {
				vm.nota.parecer = valor;
			}).then(vm.onChange);
		}
	}
})();


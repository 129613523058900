(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('appSelectTipoMatriculaConvenio', SelectTipoMatriculaConvenioDirective);

    SelectTipoMatriculaConvenioDirective.$inject = [];

    function SelectTipoMatriculaConvenioDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?'
            },
            controller: SelectTipoMatriculaConvenioDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectTipoMatriculaConvenioDirectiveController.$inject = ['$scope', 'educacao.pessoas.EstabelecimentoService'];

    function SelectTipoMatriculaConvenioDirectiveController($scope, EstabelecimentoService) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {

            var filter = '( tipo like "' + params.term + '"';

            if ($scope.multiple) {
                var tipoMatriculaConvenioIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (tipoMatriculaConvenio) {
                        tipoMatriculaConvenioIds.push(tipoMatriculaConvenio.id);
                    });
                }

                if (tipoMatriculaConvenioIds.length) {
                    filter += ' and id not in(' + tipoMatriculaConvenioIds.join() + ')';
                }
            }

            filter += ' )';

            return EstabelecimentoService.getTiposMatriculasConvenios(params.offset, params.limit, filter, params.sort).then(toPage);
        }

        function toPage(list) {
            return {
                content: list.content,
                hasMore: false
            };
        }

        function formatResult(data) {
            return data.tipo;
        }
    }
})();
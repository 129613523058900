(function() {
    'use strict';

    angular.module('educacao.matriz')
        .controller('educacao.matriz.ChaCtrl', Controller);

    Controller.$inject = ['educacao.matriz.chaService','$modalStates'];

    function Controller(chaService, $modalStates) {

        var vm = this;
        vm.chaService = chaService;
        vm.remove = remove;
        vm.openModalCrudCha = openModalCrudCha;
        vm.enumChaPromisse = vm.chaService.getTipoCompetenciaCHA();
        vm.chaService.getTipoCompetenciaCHA().then(
            function(enumCha) {
                vm.enumTipoCHA = enumCha;
            }

        );

        function remove(item){
            chaService.remove(item);
        }

        function openModalCrudCha(item) {
            $modalStates.goModalState(item);
        }


    }
})();


(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.RemoveMatriculaCascadeController', Controller);

    Controller.$inject = ['$modalInstance', 'educacao.matricula.RemoveMatriculaCascadeService', 'promiseTracker', 'bfc.Notification'];

    function Controller($modalInstance, RemoveMatriculaCascadeService, promiseTracker, Notification) {
        var vm = this;
        vm.remove = remove;
        vm.tracker = promiseTracker();
        vm.matricula = _.cloneDeep($modalInstance.params.matricula) || {};

        function remove(id) {
            var promise = RemoveMatriculaCascadeService
                .remove(id)
                .then(notificar)
                .then($modalInstance.close);
            vm.tracker.addPromise(promise);
        }

        function notificar() {
            Notification.publish('Matrícula de ' + vm.matricula.aluno.nome +' enviada para exclusão com sucesso. Você será notificado assim que a exclusão for concluída', 'success');
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .directive('appCardPizza', appCardPizzaDirective);

    function appCardPizzaDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/card-graph-pizza/app-card-pizza.directive.html',
            scope: {},
            bindToController: {
                data: '=',
                emptyStateTitle: '=',
                isTemplateCard: '=',
                redirect: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$state', '$rootScope'];

    function Controller($state, $rootScope) {
        var vm = this;
        vm.isEmpty = isEmpty;
        vm.goToPageWithParamns = goToPageWithParamns;
        vm.chart = null;

        vm.template = {
            'chart': {
                'theme': 'fusion',
                'bgColor': '#f7f7f7',
                'bgAlpha': '100',
                'baseFontColor': '#333333',
                'captionFontColor': '#666666',
                'showShadow': '0',
                'showBorder': '0',
                'borderColor': '#ffffff',
                'showLegend': '1',
                'legendPosition': 'bottom',
                'legendBgColor': '#f7f7f7',
                'legendBorderAlpha': '0',
                'legendShadow': '0',
                'showPercentValues': '1',
                'decimals': '2',
                'smartLineDistance': '0',
                'pieRadius': 95,
                'placevaluesInside': '0'
            },
            'data': [],
        };
        
        vm.groupByThree = [];
        mountGrafico();

        function goToPageWithParamns(link, params) {
            $rootScope.paramsQuadroInformativo = params;
            $state.go(link);
        }

        function isEmpty() {
            return _.isEmpty(vm.data.data) || isEmptyTotalizadores();
        }

        function isEmptyTotalizadores() {
            var totalizadores = _.get(vm.data, 'totalizadores');
            var total = totalizadores.reduce(function (acc, obj) {
                return acc + obj.value;
            }, 0);
            return total === 0;
        }

        function mountGrafico() {
            if (_.get(vm.data, 'data')) {
                vm.template.data = _.get(vm.data, 'data');
                brokenLegendasWithThreeColumns();
            }
        }

        function brokenLegendasWithThreeColumns() {
            resolveRedirects();
            return spliceIntoChunks(_.cloneDeep(_.get(vm.data, 'legendas')), 3);
        }

        function resolveRedirects() {
            if (_.isEmpty(vm.redirect)) {
                return _.get(vm.data, 'legendas');
            }
            _.forEach(vm.redirect, addPropertieRedirect);
        }

        function addPropertieRedirect(value) {
            var position = _.get(value, 'position');
            var linkRedirect = _.get(value, 'linkRedirect');
            var params = _.get(value, 'params');
            _.get(vm.data, 'legendas')[position].link = linkRedirect;
            _.get(vm.data, 'legendas')[position].params = params;
        }

        function spliceIntoChunks(arr, chunkSize) {
            while (arr.length > 0) {
                var chunk = arr.splice(0, chunkSize);
                vm.groupByThree.push(chunk);
            }
            return vm.groupByThree;
        }

    }

})();

(function() {
    'use strict';



    angular.module('educacao.matricula')
        .controller('educacao.matricula.CriterioClassificacaoCadCtrl', CriterioClassificacaoCadCtrl);

    CriterioClassificacaoCadCtrl.$inject = [
        'bfc.Notification',
        'educacao.matricula.CriterioClassificacaoService',
        '$modalTracker',
        '$q',
        'id',
        'readOnly',
        'promiseTracker',
        'fnCad',
        '$rootScope',
        '$modalInstance',
        'bfc.Focus',
        'optionsCad'
    ];

    function CriterioClassificacaoCadCtrl(Notification,CriterioClassificacaoService, $modalTracker, $q, id, readOnly,
        promiseTracker, fnCad, $rootScope, $modalInstance, focus, optionsCad) {

        var REFRESH_CRITERIOS = 'refresh::CriterioClassificacao';

        var vm = this;

        vm.tracker = {
            saveContinue: promiseTracker(),
            save: promiseTracker(),
            remove: promiseTracker()
        };
        vm.cancel = _cancel;
        vm.save = _save;
        vm.remove = _remove;

        vm.modo = {
            adicionando: !id && !readOnly,
            adicionandoNovaOpcao: Boolean(fnCad),
            editando: id && !readOnly,
            visualizando: id && readOnly
        };

        init();


        function init() {

            $modalTracker.addPromise(
                $q.all([
                    CriterioClassificacaoService.getDefaultRepresentation().then(function(data) {
                        vm.criterioProperties = data.CriterioClassificacaoDto;
                    }),
                    refresh()
                ])
            );
        }



        function refresh() {
            if (vm.form) {
                vm.form.$setPristine();
            }

            return Boolean(id) ?
                CriterioClassificacaoService.get(id).then(function(data) {
                    vm.criterio = data;

                }) :
                CriterioClassificacaoService.getDefaultRepresentation().then(function(data) {
                    vm.criterio = angular.extend(data, optionsCad);
                });

        }

        function onSuccess(continuar, tracker) {
            if (continuar) {
                id = null;
                tracker.addPromise(refresh());
                focus('lbDescricaoCriterio');
            } else {
                vm.cancel();
            }
        }

        function _cancel() {
            $modalInstance.close();
        }

        function _remove() {
            vm.tracker.remove.addPromise(
                CriterioClassificacaoService.remove(vm.criterio).then(function() {
                    $rootScope.$broadcast(REFRESH_CRITERIOS, {
                        data: vm.criterio,
                        remove: true
                    });

                    onSuccess();
                })
            );
        }

        function _save(continuar) {
            var tracker = continuar ? vm.tracker.saveContinue : vm.tracker.save;
            
            tracker.addPromise(CriterioClassificacaoService.save(vm.criterio).then(function(data) {
                showSucessMessage(data.descricao);
                if (angular.isFunction(fnCad)) {
                    fnCad(data);
                }
                $rootScope.$broadcast(REFRESH_CRITERIOS, {
                    data: data,
                    add: !id,
                    edit: Boolean(id)
                });

                onSuccess(continuar, tracker);
            }));

        }

        function showSucessMessage(descricao) {
            return Notification.publish('Critério de classificação ' + descricao + ' salvo com sucesso.', 'success');
        }

        vm.readOnly = function() {
            return readOnly;
        };

    }

})();

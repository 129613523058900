(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.common');

    moduloCommon.factory('educacao.common.EtapaInepService', [
        'Restangular',
        function(Restangular) {
            return {
                get: function(id) {
                    return Restangular.one('inep').one('etapas-inep', id).get();
                },
                getList: function(offset, limit, filter, sort) {
                    return Restangular.one('inep').all('etapas-inep').getList({
                        offset: offset,
                        limit: limit,
                        filter: filter,
                        sort: sort
                    });
                }
            };
        }
    ]);
})();

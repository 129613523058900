(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.TipoCargoService', Service);

    Service.$inject = ['$injector'];


    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');


        var selfService = ServiceBuilder.create()
            .path('funcionario', 'tipo-cargo')
            .enum('ClassificacaoTipoCargo')
            .build();

        return selfService;
    }
})();

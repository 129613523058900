(function () {

    'use strict';

    var KEY_MODALIDADE_COMPLEMENTAR = 'COMPLEMENTAR';
    var KEY_MODALIDADE_EJA = 'EJA';
    var KEY_MODALIDADE_ACELERACAO = 'ACELERACAO';
    var KEY_NIVEL_EDUCACAO_INFANTIL = 'EDUCACAO_INFANTIL';
    var KEY_MODALIDADE_EDUCACAO_BASICA = 'EDUCACAO_BASICA';
    var KEY_NIVEL_ENSINO_MEDIO = 'ENSINO_MEDIO';
    var KEYS_ENSINO_MEDIO = ['ETAPA_1', 'ETAPA_2', 'ETAPA_3', 'ETAPA_4'];
    var KEY_SITUACAO_DISCIPLINA_OUTRA = 'OUTRA';
    var IS_MUNICIPAL = 'REDE_MUNICIPAL';
    var IS_OUTRA_REDE = 'OUTRA_REDE';
    var SITUACAO_FINAL_ALUNO_OUTRA = 'OUTRA';
    var TIPO_ORGANIZACAO_CURRICULAR_DISCIPLINA = 'DISCIPLINA';
    var TIPO_ORGANIZACAO_CURRICULAR_EIXO = 'EIXO_TEMATICO';
    var TIPO_ORGANIZACAO_CURRICULAR_CAMPOS_EXPERIENCIA = 'CAMPOS_EXPERIENCIA';
    var TIPO_ORGANIZACAO_CURRICULAR_AMBOS = 'AMBOS';

    angular.module('educacao.historico-escolar')
        .controller('educacao.historicoEscolar.HistoricoEscolarCadCtrl', HistoricoEscolarCadController);

    HistoricoEscolarCadController.$inject = [
        '$scope',
        '$modalInstance',
        'promiseTracker',
        'educacao.pessoas.EstabelecimentoService',
        'educacao.historicoEscolar.HistoricoEscolarService',
        'bfc.Notification',
        '$q',
        'educacao.matriz.MatrizService',
        'educacao.matriz.EtapaService',
        'ui.components.Modal'

    ];

    function HistoricoEscolarCadController(
        $scope,
        $modalInstance,
        promiseTracker,
        EstabelecimentoService,
        HistoricoEscolarService,
        Notification,
        $q,
        MatrizService,
        EtapaMatrizService,
        uiModal) {

        var vm = this;
        var enabledChange = false;
        var disciplinasEtapa = [];
        vm.mostrarCampo = true;
        vm.possuiOutroTipoDeFrequencia = false;

        vm.save = save;
        vm.remove = remove;
        vm.saveAndAdd = saveAndAdd;
        vm.onClickCancel = onClickCancel;
        vm.fieldEtapa = fieldEtapa;

        vm.onChangeSituacaoFinalModular = onChangeSituacaoFinalModular;

        vm.isMunicipal = IS_MUNICIPAL;
        vm.isOutraRede = IS_OUTRA_REDE;

        vm.onSelectCurso = onSelectCurso;
        vm.adicionarComponente = adicionarComponente;
        vm.removerComponente = removerComponente;
        vm.calcularCargaHorariaComponentes = calcularCargaHorariaComponentes;
        vm.calcularFaltasComponentes = calcularFaltasComponentes;
        vm.onChangeTipoAvaliacao = onChangeTipoAvaliacao;
        vm.onChangeTipoComponente = onChangeTipoComponente;
        vm.setEnableChange = setEnableChange;
        vm.getCursosRecentes = getCursosRecentes;
        vm.registrarEixosExistentes = registrarEixosExistentes;
        vm.registrarDisciplinasExistentes = registrarDisciplinasExistentes;
        vm.registrarCamposExperienciaExistentes = registrarCamposExperienciaExistentes;

        vm.isEducacaoInfantil = isEducacaoInfantil;
        vm.isModalidadeComplementar = isModalidadeComplementar;
        vm.isModalidadeEjaOuAceleracao = isModalidadeEjaOuAceleracao;
        vm.isFaltasComponenteDifereHistorico = isFaltasComponenteDifereHistorico;
        vm.isCargaHorariaComponenteDifereHistorico = isCargaHorariaComponenteDifereHistorico;
        vm.isSituacaoFinalOutra = isSituacaoFinalOutra;
        vm.isSituacaoDisciplinaOutra = isSituacaoDisciplinaOutra;
        vm.isModalidadeEja = isModalidadeEja;
        vm.isModular = isModular;
        vm.isTipoAmbos = isTipoAmbos;
        vm.getMatrizCurricular = getMatrizCurricular;
        vm.watchMatriz = watchMatriz;
        vm.watchEtapa = watchEtapa;
        vm.listEtapaMatriz = listEtapaMatriz;
        vm.equalsTipoItemOrganizacaoCurricular = equalsTipoItemOrganizacaoCurricular;

        vm.onkeydown = onkeydown;

        vm.filterResultEnumNivelEscolar = filterResultEnumNivelEscolar;
        vm.filterResultEnumEtapa = filterResultEnumEtapa;
        vm.nivelModalidade = HistoricoEscolarService.getNivelModalidade();
        vm.nivelEscolar = HistoricoEscolarService.getNivelEscolar();
        vm.etapaEquivalencia = HistoricoEscolarService.getEquivalenciaEtapa();
        vm.orientacaoCurricular = HistoricoEscolarService.getOrientacaoCurricular();
        vm.situacaoFinalAluno = HistoricoEscolarService.getSituacaoHistorico();

        vm.conclusaoOrigem = HistoricoEscolarService.getConclusaoOrigem();

        vm.tipoItemOrganizacaoCurricular = HistoricoEscolarService.getTipoItemOrganizacaoCurricularHistorico();
        vm.tipoAvaliacaoHistorico = HistoricoEscolarService.getTipoAvaliacaoHistorico();

        var templateBuscaMatrizCurricular = _.template('descricao like "<%= term %>"');

        vm.regexAnoConclusao = '\^\\d{4}\/\\d{4}$|\^\\d{4}|\^\\d{8}$';

        vm.defaultHistorico = {
            descricaoCurso: '',
            aluno: $modalInstance.params.aluno || {}
        };

        vm.tracker = {
            save: promiseTracker(),
            remove: promiseTracker(),
            getCursosRecentes: promiseTracker(),
            getCidade: promiseTracker(),
            camposAdicionais: promiseTracker()
        };

        vm.placeHolderCargaHoraria = {
            horas: 'horas',
            minutos: 'min'
        };

        vm.vizualizacaoComponentes = {};

        initForm();

        $scope.$watch('vm.historico.nivelModalidade', watchNivelModalidade);
        $scope.$watch('vm.historico.nivelEscolar', watchNivelEscolar);
        $scope.$watch('vm.historico.situacao', watchSituacaoFinal);
        $scope.$watch('vm.historico.tipoItemOrganizacaoCurricular', watchComponenteCurricular);
        $scope.$watch('vm.historico.conclusaoOrigem', watchConclusaoOrigem);
        $scope.$watch('vm.historico.estabelecimento', watchEstabelecimento);
        $scope.$watch('vm.historico.modular', watchModular);
        $scope.$watch('vm.historico.matrizCurricular', watchMatriz);
        $scope.$watch('vm.historico.etapaMatriz', watchEtapa);
        $scope.$watch('vm.historico.outroTipoDeFalta', watchOutroTipoDeFalta);
        $scope.$watch('vm.possuiOutroTipoDeFrequencia', watchPossuiOutroTipoDeFrequencia);
        $scope.$watch('vm.historico.componentes', watchComponentes);

        function initForm() {
            var historicoEdit = $modalInstance.params.historico;
            vm.edit = Boolean(historicoEdit);

            if (vm.edit) {
                vm.defaultHistorico.aluno = historicoEdit.aluno;
                vm.historico = historicoEdit;                
                initCamposAdicionais(vm.historico.id);

                registrarComponentes(vm.historico.tipoOrganizacao, vm.historico.componentes);
                calcularCargaHorariaComponentes(vm.historico.componentes);
                calcularFaltasComponentes(vm.historico.componentes);

                if (vm.historico.matrizCurricular) {
                    MatrizService
                        .disciplina(vm.historico.etapaMatriz.id, vm.historico.matrizCurricular.id)
                        .then(function (disciplinas) {
                            disciplinasEtapa = disciplinas;
                        });
                }

            } else {
                vm.historico = angular.copy(vm.defaultHistorico);
                initCamposAdicionais();
            }

            getCursosRecentes();
        }

        function initCamposAdicionais(historicoId) {            
			var promise = HistoricoEscolarService.camposAdicionais(historicoId)
				.then(function (data) {
					vm.campoAdicional = data;
                    if (!historicoId) {
                        vm.defaultCampoAdicional = _.cloneDeep(vm.campoAdicional);
                    }
				});
			vm.tracker.camposAdicionais.addPromise(promise);
		}

        function formatAnoConclusao() {
            if (vm.historico.anoConclusao.lastIndexOf('/') === 4 && vm.historico.anoConclusao.length === 9) {
                return;
            }

            if (vm.historico.anoConclusao.length === 8) {
                var startYear = vm.historico.anoConclusao.substring(0, 4);
                var endYear = vm.historico.anoConclusao.substring(4, 8);
                vm.historico.anoConclusao = startYear + '/' + endYear;
            }
        }

        function save(historico) {
            clearHistoricoComponentes();
            formatAnoConclusao();
            historico.campoAdicional = vm.campoAdicional;
            var promise = HistoricoEscolarService.saveHistorico(historico)
                .then(function () {
                    vm.hasNewHistoricos = true;
                    $modalInstance.close(vm.hasNewHistoricos);                    
                    Notification.publish('Histórico escolar salvo com sucesso', 'success');
                });

            vm.tracker.save.addPromise(promise);
        }

        function remove(historico) {
            uiModal.open({
                templateUrl: 'historico-escolar/remove-historico-escolar/remove-historico-escolar.html',
                controller: 'educacao.historicoEscolar.RemoveHistoricoEscolarCrlt',
                controllerAs: 'vm',
                params: {
                    historico: $modalInstance.params.dadosExclusao,
                    aluno: {
                        pessoa: vm.defaultHistorico.aluno
                    }
                },
                size: 'lg'
            }).result.then(function (confirm) {
                if (confirm) {
                    var deferred = $q.defer();
                    var promise = deferred.promise;
                    HistoricoEscolarService.removeHistorico(historico).then(function () {
                        vm.hasNewHistoricos = true;
                        $modalInstance.close(vm.hasNewHistoricos);
                        notificarExclusao();
                        deferred.resolve();
                    }, function () {
                        deferred.resolve();
                    });

                    vm.tracker.remove.addPromise(promise);
                }
            });

        }

        function notificarExclusao() {
            Notification.publish('Histórico escolar excluído com sucesso.', 'success');

        }

        function saveAndAdd(historico) {
            formatAnoConclusao();
            historico.campoAdicional = vm.campoAdicional;
            var promise = HistoricoEscolarService.saveHistorico(historico)
                .then(function () {
                    vm.historico = angular.copy(vm.defaultHistorico);
                    vm.campoAdicional = _.cloneDeep(vm.defaultCampoAdicional);
                    vm.formCadHistorico.$setUntouched();
                    vm.hasNewHistoricos = true;
                    vm.disciplinasExistentes = [];
                    Notification.publish('Histórico escolar salvo com sucesso', 'success');
                });
            vm.tracker.save.addPromise(promise);
        }

        function onClickCancel() {
            $modalInstance.close(vm.hasNewHistoricos);
        }

        function onChangeSituacaoFinalModular(componente) {
            componente.situacaoEspecifica = null;
        }

        function getCursosRecentes() {
            var promise = HistoricoEscolarService.getCursosRecentes()
                .then(function (dados) {
                    vm.cursosRecentes = dados;
                });

            vm.tracker.getCursosRecentes.addPromise(promise);
        }

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function plain(list) {
            return list.map(function (item) {
                return item.plain();
            });
        }

        function onSelectCurso(curso) {
            vm.historico.descricaoCurso = curso;
        }

        function watchEstabelecimento(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (!newValue) {
                cleanFieldsDependentsEstabelecimento();
                return;
            }

            if (vm.historico.estabelecimento && vm.historico.estabelecimento !== '') {
                getCidadeEstabelecimentoEnsino(vm.historico.estabelecimento.id);
            }
        }

        function getCidadeEstabelecimentoEnsino(idEstabelecimento) {
            vm.historico.municipio = null;
            var promiseGetCidade = EstabelecimentoService.get(idEstabelecimento).then(function (estabelecimento) {
                if (vm.historico.estabelecimento) {
                    if (!_.isEmpty(estabelecimento.enderecos)) {
                        vm.historico.municipio = _.first(estabelecimento.enderecos).municipio;
                    }
                }
            });

            vm.tracker.getCidade.addPromise(promiseGetCidade);
        }

        function isEducacaoInfantil() {
            return vm.historico.nivelEscolar === KEY_NIVEL_EDUCACAO_INFANTIL;
        }

        function isModalidadeComplementar() {
            return vm.historico.nivelModalidade === KEY_MODALIDADE_COMPLEMENTAR;
        }

        function isModalidadeEjaOuAceleracao() {
            return (vm.historico.nivelModalidade === KEY_MODALIDADE_EJA || vm.historico.nivelModalidade === KEY_MODALIDADE_ACELERACAO);
        }

        function isFaltasComponenteDifereHistorico(faltasComponentes, faltasHistorico) {

            if (!validaValorNumerico(faltasComponentes) || !validaValorNumerico(faltasHistorico)) {
                return false;
            }

            faltasComponentes = parseInt(faltasComponentes);
            faltasHistorico = parseInt(faltasHistorico);

            return (faltasComponentes !== faltasHistorico);
        }

        function isCargaHorariaComponenteDifereHistorico(cargaHorariaTotalComponentesHoras,
                                                         cargaHorariaTotalComponentesMinutos,
                                                         cargaHorariaHistoricoHoras,
                                                         cargaHorariaHistoricoMinutos) {

            var horaComponente;
            var horaHistorico;
            var minutosComponente;
            var minutosHistorico;

            if (!validaValorNumerico(cargaHorariaHistoricoHoras) && !validaValorNumerico(cargaHorariaHistoricoMinutos)) {
                return false;
            }
            if (!validaValorNumerico(cargaHorariaTotalComponentesHoras) &&
                !validaValorNumerico(cargaHorariaTotalComponentesMinutos)) {
                return false;
            }

            horaComponente = converterParaValorValido(cargaHorariaTotalComponentesHoras);
            horaHistorico = converterParaValorValido(cargaHorariaHistoricoHoras);
            minutosComponente = converterParaValorValido(cargaHorariaTotalComponentesMinutos);
            minutosHistorico = converterParaValorValido(cargaHorariaHistoricoMinutos);

            return (horaComponente !== horaHistorico || minutosComponente !== minutosHistorico);
        }

        function validaValorNumerico(valor) {

            var parseComponente = parseInt(valor);

            return parseComponente >= 0;
        }

        function converterParaValorValido(valor) {
            if (!valor) {
                valor = 0;
            }
            return parseInt(valor);
        }

        function isSituacaoFinalOutra(situacaoFinal) {
            return situacaoFinal === SITUACAO_FINAL_ALUNO_OUTRA;
        }

        function isModalidadeEja() {
            return vm.historico.nivelModalidade === KEY_MODALIDADE_EJA;
        }

        function isModular() {
            return Boolean(vm.historico && vm.historico.modular);
        }

        function isSituacaoDisciplinaOutra(componente) {
            if (!componente) {
                var result = false;
                _.forEach(vm.historico.componentes, function (data) {
                    if (isSituacaoDisciplinaOutra(data)) {
                        result = true;
                    }
                });
                return result;
            }
            return componente.situacao === KEY_SITUACAO_DISCIPLINA_OUTRA;
        }

        function filterResultEnumNivelEscolar(term, value) {
            if (vm.historico.nivelModalidade !== KEY_MODALIDADE_EDUCACAO_BASICA) {
                if (value.id !== KEY_NIVEL_EDUCACAO_INFANTIL) {
                    return filter(term, value);
                }
            } else {
                return filter(term, value);
            }
        }

        function filterResultEnumEtapa(term, value) {
            if (vm.historico.nivelModalidade === KEY_MODALIDADE_EDUCACAO_BASICA) {
                if (vm.historico.equivalencias && vm.historico.equivalencias.length > 0) {
                    return;
                }
            }

            if (vm.historico.nivelEscolar === KEY_NIVEL_ENSINO_MEDIO) {
                if (_.indexOf(KEYS_ENSINO_MEDIO, value.id) >= 0) {
                    return filter(term, value);
                }
            } else {
                return filter(term, value);
            }
        }

        function filter(term, value) {
            var valueMaisculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();
            if (valueMaisculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }

        function watchConclusaoOrigem(newValue, oldValue) {
            if (!_.isEqual(newValue, oldValue)) {
                vm.historico.estabelecimento = null;
                vm.historico.nomeEstabelecimento = '';
                vm.historico.municipio = null;
            }
        }

        function watchNivelModalidade(newValue, oldValue) {
            if (!enabledChange) {
                return;
            }
            if (!_.isEqual(newValue, oldValue)) {
                delete vm.historico.modular;
                delete vm.historico.nivelEscolar;
                delete vm.historico.equivalencias;
            }
        }

        function watchNivelEscolar(newValue, oldValue) {
            if (!enabledChange) {
                return;
            }
            if (!_.isEqual(newValue, oldValue)) {
                delete vm.historico.equivalencias;
                if (vm.historico.nivelEscolar === KEY_NIVEL_EDUCACAO_INFANTIL) {
                    angular.forEach(vm.historico.componentes, function (item) {
                        item.orientacaoCurricular = null;
                    });
                }
            }
        }

        function watchSituacaoFinal(newValue, oldValue) {
            if (!_.isEqual(newValue, oldValue)) {
                delete vm.historico.situacaoEspecifica;
            }
        }

        function watchModular(newValue, oldValue) {
            if (!_.isEqual(newValue, oldValue)) {
                delete vm.historico.situacaoEspecifica;
                delete vm.historico.situacao;

                _.forEach(vm.historico.componentes, function (componente) {
                    delete componente.situacao;
                    delete componente.situacaoEspecifica;
                });
            }
        }

        function watchMatriz(newValue, oldValue) {
            fieldEtapa();
            enabledChange = false;

            if (newValue === oldValue) {
                return;
            }

            cleanFieldsDependentsMatriz();

            if (!newValue) {
                return;
            }

            MatrizService
                .get(vm.historico.matrizCurricular.id)
                .then(setCamposHistorico);
        }

        function setCamposHistorico(data) {
            vm.historico.descricaoCurso = data.curso.descricao;
            vm.historico.nivelModalidade = data.curso.nivelModalidade;
            vm.historico.nivelEscolar = data.curso.nivelEscolar;
            vm.historico.tipoItemOrganizacaoCurricular = data.tipoOrganizacao || TIPO_ORGANIZACAO_CURRICULAR_DISCIPLINA;
            vm.historico.modular = data.modular;
        }

        function watchEtapa(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            cleanFieldsDependentsEtapa();

            if (!newValue) {
                return;
            }

            if (!_.isEmpty(newValue.equivalenciasEnum)) {
                vm.historico.equivalencias = [_.first(newValue.equivalenciasEnum)];
            }

            MatrizService
                .disciplina(newValue.id, vm.historico.matrizCurricular.id)
                .then(function (disciplinas) {
                    disciplinasEtapa = disciplinas;
                    setDisciplinas();
                });
        }

        function watchOutroTipoDeFalta(newValue) {
            if (newValue !== null) {
                vm.possuiOutroTipoDeFrequencia = true;
            }
        }

        function watchPossuiOutroTipoDeFrequencia(newValue,oldValue) {
            if (!newValue && oldValue) {
                vm.historico.outroTipoDeFalta = null;
                removeOutroTipoFaltaDosComponentes();
            }
        }

        function watchComponentes() {
            if (checkPossuiOutroTipoFaltaComponentes()) {
                vm.possuiOutroTipoDeFrequencia = true;
            }
        }

        function checkPossuiOutroTipoFaltaComponentes() {
            return _.some(vm.historico.componentes, function (item) {
                return item.outroTipoDeFalta !== null;
            });
        }

        function removeOutroTipoFaltaDosComponentes() {
            _.map(vm.historico.componentes, function (item) {
                delete item.outroTipoDeFalta;
            });
        }

        function setDisciplinas() {
            vm.historico.cargaHorariaHoras = 0;
            vm.historico.cargaHorariaMinutos = 0;
            vm.historico.componentes = [];

            var property = getProperty();

            disciplinasEtapa.forEach(eachDisciplina);

            calcularCargaHorariaComponentes(vm.historico.componentes);

            function eachDisciplina(item) {
                var componente = {
                    itemCurricular: _.get(item, property),
                    eixoTematico: property !== 'eixoTematico' ? _.get(item, 'eixoTematico') : null,
                    orientacaoCurricular: null,
                    cargaHorariaHoras: item.cargaHorariaHoras,
                    cargaHorariaMinutos: item.cargaHorariaMinutos
                };

                if (item.orientacaoCurricular) {
                    var orientacaoCurricular = item.orientacaoCurricular.descricao.replace(/\s/g, '_').toUpperCase();
                    componente.orientacaoCurricular = orientacaoCurricular === 'PARTE_DIVERSIFICADA' ? 'BASE_NACIONAL_DIVERSIFICADA' :
                        orientacaoCurricular;
                }

                vm.historico.componentes.push(componente);
                vm.historico.cargaHorariaHoras += item.cargaHorariaHoras;
                vm.historico.cargaHorariaMinutos += item.cargaHorariaMinutos;
            }
        }

        function getProperty() {
            switch (vm.historico.tipoItemOrganizacaoCurricular) {
                case TIPO_ORGANIZACAO_CURRICULAR_DISCIPLINA:
                    return 'disciplina';

                case TIPO_ORGANIZACAO_CURRICULAR_CAMPOS_EXPERIENCIA:
                    return 'campoExperiencia';

                case TIPO_ORGANIZACAO_CURRICULAR_EIXO:
                    return 'eixoTematico';
            }
        }

        function cleanFieldsDependentsMatriz() {
            delete vm.historico.tipoItemOrganizacaoCurricular;
            delete vm.cargaHorariaTotalComponentesMinutos;
            delete vm.cargaHorariaTotalComponentesHoras;
            delete vm.historico.nivelModalidade;
            delete vm.historico.nivelEscolar;
            delete vm.historico.etapaMatriz;
            delete vm.historico.etapa;
        }

        function cleanFieldsDependentsEtapa() {
            disciplinasEtapa = [];
            vm.historico.cargaHorariaMinutos = 0;
            vm.historico.cargaHorariaHoras = 0;
            vm.historico.equivalencias = [];
            vm.historico.componentes = [];
        }

        function cleanFieldsDependentsEstabelecimento() {
            delete vm.historico.matrizCurricular;
        }

        function fieldEtapa() {
            vm.mostrarCampo = vm.historico.matrizCurricular === null || vm.historico.matrizCurricular === undefined;
        }

        function listEtapaMatriz() {
            return EtapaMatrizService
                .getList(vm.historico.matrizCurricular.id)
                .then(plain)
                .then(toPage);
        }

        function getMatrizCurricular(params) {
            return MatrizService
                .getList(params.offset, params.limit, templateBuscaMatrizCurricular(params))
                .then(plain)
                .then(toPage);
        }

        function watchComponenteCurricular(tipoOrganizacao, oldValue) {
            if (!_.isEqual(tipoOrganizacao, oldValue)) {
                vm.faltasComponentes = null;
                vm.cargaHorariaTotalComponentesHoras = null;
                vm.cargaHorariaTotalComponentesMinutos = null;

                setDisciplinas();
            }

            switch (tipoOrganizacao) {
                case TIPO_ORGANIZACAO_CURRICULAR_DISCIPLINA:
                    vm.vizualizacaoComponentes = {
                        title: 'Disciplinas',
                        columnName: 'Disciplina',
                        alertInfoId: 'das disciplinas',
                        emptyInfo: 'Ainda não há disciplinas por aqui'
                    };
                    break;

                case TIPO_ORGANIZACAO_CURRICULAR_CAMPOS_EXPERIENCIA:
                    vm.vizualizacaoComponentes = {
                        title: 'Campos de experiência',
                        columnName: 'Campo de experiência',
                        alertInfoId: 'dos campos de experiência',
                        emptyInfo: 'Ainda não há campos de experiência por aqui'
                    };
                    break;

                case TIPO_ORGANIZACAO_CURRICULAR_EIXO:
                    vm.vizualizacaoComponentes = {
                        title: 'Eixos temáticos',
                        columnName: 'Eixo temático',
                        alertInfoId: 'dos eixos temáticos',
                        emptyInfo: 'Ainda não há eixos temáticos por aqui'
                    };
                    break;

                default:
                    vm.vizualizacaoComponentes = {
                        title: 'Componentes curriculares',
                        columnName: 'Componente curricular',
                        alertInfoId: 'dos componentes curriculares',
                        emptyInfo: 'Ainda não há componentes curriculares por aqui'
                    };
                    break;
            }
        }

        function adicionarComponente() {
            vm.historico.componentes.push({});
        }

        function removerComponente(componentes, componente) {
            var indexComponent = _.indexOf(componentes, componente);
            if (indexComponent >= 0) {
                componentes.splice(indexComponent, 1);
            }

            var tipoOrganizacao = _.get(vm.historico, 'tipoItemOrganizacaoCurricular');
            if (tipoOrganizacao === TIPO_ORGANIZACAO_CURRICULAR_AMBOS) {
                tipoOrganizacao = _.get(componente, 'tipoItemOrganizacaoCurricular');
            }

            registrarComponentes(tipoOrganizacao, componentes);

            calcularCargaHorariaComponentes(componentes);
            calcularFaltasComponentes(componentes);
        }

        function calcularCargaHorariaComponentes(componentes) {
            vm.cargaHorariaTotalComponentesHoras = null;
            vm.cargaHorariaTotalComponentesMinutos = null;

            angular.forEach(componentes, function (item) {
                if (validaValorNumerico(item.cargaHorariaHoras)) {
                    item.cargaHorariaHoras = parseInt(item.cargaHorariaHoras);
                    vm.cargaHorariaTotalComponentesHoras += item.cargaHorariaHoras;

                }

                if (validaValorNumerico(item.cargaHorariaMinutos)) {
                    item.cargaHorariaMinutos = parseInt(item.cargaHorariaMinutos);

                    vm.cargaHorariaTotalComponentesMinutos += item.cargaHorariaMinutos;
                }
            });

            if (vm.cargaHorariaTotalComponentesMinutos > 0) {
                var horasAdicionais = Math.floor(vm.cargaHorariaTotalComponentesMinutos / 60);
                vm.cargaHorariaTotalComponentesMinutos = (vm.cargaHorariaTotalComponentesMinutos % 60);

                vm.cargaHorariaTotalComponentesHoras += horasAdicionais;

                vm.cargaHorariaTotalComponentesMinutos = formatarNumero(vm.cargaHorariaTotalComponentesMinutos, 2);
            }
        }

        function calcularFaltasComponentes(componentes) {
            vm.faltasComponentes = null;

            angular.forEach(componentes, function (item) {
                if (validaValorNumerico(item.totalFaltas)) {
                    item.totalFaltas = parseInt(item.totalFaltas);
                    vm.faltasComponentes += item.totalFaltas;
                }
            });
        }

        function formatarNumero(numero, digitosEsquerda) {
            var num = '' + numero;

            if (num.length >= digitosEsquerda) {
                return num;
            }

            while (num.length < digitosEsquerda) {
                num = '0' + num;
            }

            return num;
        }

        function onChangeTipoAvaliacao(componente) {
            componente.notaDescritiva = null;
            componente.notaConceito = null;
            componente.notaNumerica = null;
        }

        function registrarDisciplinasExistentes(componentes) {

            vm.disciplinasExistentes = [];

            angular.forEach(componentes, function (item) {
                if (item.itemCurricular) {
                    var componente = {
                        disciplina: item.itemCurricular
                    };
                    vm.disciplinasExistentes.push(componente);
                }
            });

            $scope.$applyAsync();
        }

        function registrarCamposExperienciaExistentes(componentes) {
            vm.camposExperienciaExistentes = [];

            angular.forEach(componentes, function (item) {
                if (item.itemCurricular) {
                    vm.camposExperienciaExistentes.push(item.itemCurricular);
                }
            });

            $scope.$applyAsync();
        }

        function registrarEixosExistentes(componentes) {
            vm.eixosExistentes = [];

            angular.forEach(componentes, function (item) {
                var componente = {
                    eixoTematico: item.itemCurricular
                };
                vm.eixosExistentes.push(componente);
            });

            $scope.$applyAsync();
        }

        function isTipoAmbos() {
            return vm.historico.tipoItemOrganizacaoCurricular === TIPO_ORGANIZACAO_CURRICULAR_AMBOS;
        }

        function onChangeTipoComponente(componente) {
            componente.itemCurricular = null;
            componente.eixoTematico = null;
        }

        function setEnableChange() {
            enabledChange = true;
        }

        function onkeydown(e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code !== 40 && code !== 38) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
        }

        function equalsTipoItemOrganizacaoCurricular(componente, tipo) {
            return (
                vm.historico.tipoItemOrganizacaoCurricular === tipo ||
                componente.tipoItemOrganizacaoCurricular === tipo
            );
        }

        function registrarComponentes(tipoOrganizacao, componentes) {
            switch (tipoOrganizacao) {
                case TIPO_ORGANIZACAO_CURRICULAR_DISCIPLINA:
                    registrarDisciplinasExistentes(componentes);
                    break;

                case TIPO_ORGANIZACAO_CURRICULAR_CAMPOS_EXPERIENCIA:
                    registrarCamposExperienciaExistentes(componentes);
                    break;

                case TIPO_ORGANIZACAO_CURRICULAR_EIXO:
                    registrarEixosExistentes(componentes);
                    break;
            }
        }

        function clearHistoricoComponentes() {
            if (!vm.historico.tipoItemOrganizacaoCurricular) {
                vm.historico.componentes = [];
            }
        }
    }

})();

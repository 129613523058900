(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edSelectCartorio', EdSelectCartorio);

    EdSelectCartorio.$inject = [];

    function EdSelectCartorio() {
        return {
            template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" search="vm.search" minimum-input-length="3" ng-required="!!notNull" format-result="vm.formatResult(data)"></ui-select>',
            restrict: 'E',
            require: 'ngModel',
            controller: EdSelectCartorioCtrl,
            controllerAs: 'vm',
            scope: {
                notNull: '=',
                ngModel: '=',
                municipio: '=',
                ngDisabled: '=?'
            }
        };
    }

    EdSelectCartorioCtrl.$inject = ['$scope',
            'educacao.common.CartorioInepService',
            'educacao.common.ObjectUtilsService'];

    function EdSelectCartorioCtrl($scope, CartorioInepService, ObjectUtilsService) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var term = ObjectUtilsService.normalizeFilter(params.term);
            var filter = '( nome like "' + term + '"';

            if($scope.municipio) {
                filter += ' and municipio.codigoInep = ' + $scope.municipio.codigoIBGE;
            }
            filter += ')';
                

            return CartorioInepService.getList(params.offset, params.limit, filter).then(
                function(data) {
                    return _.first(data);
                }
            );
        }

        function formatResult(value) {
            return value.descricao;
        }
    }
})();


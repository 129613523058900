(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.FuncaoGratificadaCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        'educacao.funcionario.FuncaoGratificadaService'
    ];

    function Controller(
        $scope,
        promiseTracker,
        $modalInstance,
        focus,
        FuncaoGratificadaService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.funcaoGratificada = $modalInstance.params.funcaoGratificada || {};

        var modo = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.funcaoGratificada.id
        };
        vm.save = save;
        vm.cancel = fechaModal;
        vm.modo = modo;
        vm.remove = remove;
        vm.filterResult = filterResult;

        init();

        function init() {
            verificaFormulario();
            vm.enumTipoFuncao = FuncaoGratificadaService.getTipoFuncao();
            vm.windowTitle = modo.adicionando ? 'Adicionando função gratificada' : 'Editando função gratificada';
            focus('lbDescricaoFuncaoGratificada');
        }

        function save(continuar) {
            vm.tracker.loading.addPromise(
                FuncaoGratificadaService.save(vm.funcaoGratificada).then(
                    function(dataRetorno){
                        onSuccess(dataRetorno,continuar);
                    }
                )
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                FuncaoGratificadaService.remove(vm.funcaoGratificada).then(function() {
                    fechaModal();
                })
            );
        }

        function onSuccess(dataRetorno,continuar) {
            if (continuar) {
                vm.funcaoGratificada = {};
                focus('lbDescricaoFuncaoGratificada');
            } else {
                $modalInstance.close(dataRetorno);

            }
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function filterResult(term, value) {
            if (value.key.toUpperCase().contains(term.toUpperCase().trim())) {
                return value;
            }
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();

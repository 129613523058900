(function() {

    'use strict';

    angular.module('educacao.pessoas')
        .directive('appDetalhesResponsavel', Directve);

    function Directve() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/responsaveis/app-detalhes-responsavel.html',
            scope: {},
            bindToController: {
                responsavel: '=',
                method: '=',
                diasSemana: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'promiseTracker'
    ];

    function Controller(promiseTracker) {
        var vm = this;
        vm.tracker = promiseTracker();
        vm.detalhesResponsavel = [];
        vm.getDescricaoRetirarAluno = getDescricaoRetirarAluno;

        init();

        function init() {
            if (!vm.responsavel) {
                return;
            }
            var filter = '( responsavel.id =' + vm.responsavel + ')';

            var params = {
                filter: filter
            };
            var promise = vm.method(params)
                .then(function(data) {
                    if (_.isEmpty(data)) {
                        vm.detalhesResponsavel = [];
                    }
                    vm.detalhesResponsavel = data;
                });
            vm.tracker.addPromise(promise);
        }

        function getDescricaoRetirarAluno(permiteRetiradaAluno) {
            if (_.isNull(permiteRetiradaAluno) || _.isUndefined(permiteRetiradaAluno)) {
                return '';
            }
            return permiteRetiradaAluno ? 'Sim:' : 'Não';
        }
    }
})();
(function() {

	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.CadastroTurmaConfiguracaoFrequenciaController', Controller);

	Controller.$inject = ['$scope', 'promiseTracker', '$modalInstance', '$injector'];

	function Controller($scope, promiseTracker, $modalInstance, $injector) {
		var vm = this;

		var REGISTRO_FREQUENCIA_POR_AULAS = 'REGISTRO_FREQUENCIA_POR_AULAS';
		var REGISTRO_TOTAL_FALTAS_EM_DIAS = 'REGISTRO_TOTAL_FALTAS_EM_DIAS';

		var $commons = $injector.get('educacao.common.$commons');
		var TurmaService = $injector.get('educacao.matricula.TurmaService');
		var tipoRegistroFrequenciaDefault;

		vm.filterResultTipoRegistoFrequencia = filterResultTipoRegistoFrequencia;
		vm.openConfirmaAlteracao = openConfirmaAlteracao;
		vm.closeModal = closeModal;

		init();

		function init() {
			vm.idsTurmas = _.pluck($modalInstance.params.turmas, 'id');
			tipoRegistroFrequenciaDefault = _.get(_.first($modalInstance.params.turmas), 'tipoRegistroFrequencia');
			vm.loadingTracker = promiseTracker();
		}

		function closeModal() {
			$modalInstance.close();
		}

		function filterResultTipoRegistoFrequencia(term, value) {
			if (value.id === REGISTRO_FREQUENCIA_POR_AULAS ||
				value.id === tipoRegistroFrequenciaDefault ||
				value.id === REGISTRO_TOTAL_FALTAS_EM_DIAS && (_.get(vm.turma, 'tipoConfiguracaoFrequencia') === 'EJA_MODULAR_ENSINO_FUNDAMENTAL' ||
					_.get(vm.turma, 'tipoConfiguracaoFrequencia') === 'EJA_MODULAR_ENSINO_MEDIO')) {
				return;
			}
			var valueMaiusculo = value.description.toUpperCase();
			var pesquisa = term.toUpperCase();

			if (valueMaiusculo.indexOf(pesquisa.trim()) === 0) {
				return value;
			}
		}

		function openConfirmaAlteracao() {
			$commons.$modal.open({
				templateUrl: 'matricula/turma/cadastro-configuracao-frequencia/confirmacao-configuracao-frequencia.html',
				controller: 'educacao.matricula.ConfirmacaoConfiguracaoFrequenciaController as vm'
			}).result.then(resultConfirmacao);

			function resultConfirmacao(value) {
				if (!value) {
					return;
				}

				vm.loadingTracker.addPromise(
					TurmaService.alteraTipoRegistroFrequencia(vm.tipoRegistroFrequencia, vm.idsTurmas).then(closeModal)
				);
			}
		}
	}
})();

(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appAccordionCha', AccordionChaDirective);

    function AccordionChaDirective() {
        return {
            restrict: 'E',
            template: '<div class="panel-group"></div>',
            transclude: true,
            scope: {},
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', '$element', '$transclude'];

    function Controller($scope, $element, $transclude) {

        var vm = this;

        vm.accordions = [];

        $transclude(function(clone, innerScope) {
            innerScope.registerAccordion = registerAccordion;
            innerScope.closeAll = closeAll;
            innerScope.openItem = _.throttle(openItem, 500);
            $element.append(clone);
        });

        function openItem(accordion) {
            var state = accordion.collapse;

            closeAll();

            accordion.collapse = !state;
        }

        function registerAccordion(accordion) {
            vm.accordions.push(accordion);
        }

        function closeAll() {
            vm.accordions.forEach(function(item) {
                item.collapse = true;
            });
        }

    }

})();


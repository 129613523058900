(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.pessoas.ClassificacaoInscritosCadCtrl', ClassificacaoInscritosCadCtrl);

    ClassificacaoInscritosCadCtrl.$inject = ['$scope'];

    function ClassificacaoInscritosCadCtrl() {
        var vm = this;

        vm.localTrabalho = {};
    }


})();


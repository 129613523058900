(function () {
	'use strict';

	angular.module('educacao.matricula').controller('educacao.matricula.EditEnturmacaoProfessorCtrl',
		EditEnturmacaoProfessorCtrl);

	EditEnturmacaoProfessorCtrl.$inject = ['$scope', '$modalInstance', '$injector', 'promiseTracker'];

	function EditEnturmacaoProfessorCtrl($scope, $modalInstance, $injector, promiseTracker) {
		var vm = this;

		var EnturmacaoProfessorService = $injector.get('educacao.matricula.EnturmacaoProfessorService');

		vm.save = save;
		vm.formatResult = formatResult;
		vm.isTipoRegente = isTipoRegente;

		init();

		function init() {
			vm.historico = $modalInstance.params.historico;

			vm.enturmar = {
				id: _.get(vm.historico, 'id'),
				data: _.get(vm.historico, 'dataEnturmacao'),
				tipoEnturmacao: _.get(vm.historico, 'tipo'),
				turmas: [{
					turma: _.get(vm.historico, 'turma'),
					componente: _.get(vm.historico, 'itemEducacional')
				}],
				version: _.get(vm.historico, 'version'),
			};

			initTracker();
		}

		function initTracker() {
			vm.tracker = {
				save: promiseTracker()
			};
		}

		function save() {
			vm.tracker.save.addPromise(
				saveEditEnturmar()
			);

			function saveEditEnturmar() {
				var params = {
					id: _.get(vm.enturmar, 'id'),
					dataEnturmacao: _.get(vm.enturmar, 'data'),
					version: _.get(vm.enturmar, 'version')
				};

				return EnturmacaoProfessorService.setEnturmacao(params, 'ALTERACAO_ENTURMACAO_FUNCIONARIO').then(function (data) {
					vm.enturmar = data;
					$modalInstance.close(vm.enturmar);
				});
			}
		}

		function isTipoRegente() {
			return Boolean(_.get(vm.enturmar, 'tipoEnturmacao') === 'REGENTE');
		}

		function formatResult(data) {
			var descricao = _.get(data, 'descricao');
			if (_.get(data, 'tipo') === 'ATIVIDADE') {
				return descricao;
			}

			var result = [];
			var descricaoPai = _.get(data, 'descricaoPai');
			if (descricaoPai) {
				result.push(descricaoPai);
			}
			if (descricao) {
				result.push(descricao);
			}
			
			return result.join(' - ');
		}
	}
})();

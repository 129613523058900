(function() {

	'use strict';

	angular.module('educacao.matricula').directive('appSelectComponenteTurmaProfessor', SelectComponenteTurmaDirective);

	SelectComponenteTurmaDirective.$inject = [];

	function SelectComponenteTurmaDirective() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)" search="vm.search" />',
			scope: {
				ngModel: '=',
				ngRequired: '=',
				ngDisabled: '=',
				matriz: '=?',
				etapas: '=?',
				isAtividade: '=',
				isEixoTematico: '=',
				isCampoExperiencia: '=',
				byFuncionario: '=',
				formatResult: '=',
				turmas: '=',
				turma: '=',
				tipo: '=',
				professor: '='
			},
			controller: SelectComponenteTurmaDirectiveController,
			controllerAs: 'vm',
			compile: function($element, $attrs) {
				var multiple = angular.isDefined($attrs.multiple);
				if (multiple) {
					$element.children('ui-select').attr('multiple', true);

					if (angular.isDefined($attrs.ngRequired)) {
						$element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function($scope) {
						$scope.multiple = multiple;
					}
				};
			}
		};
	}

	SelectComponenteTurmaDirectiveController.$inject = ['$injector', '$scope'];

	function SelectComponenteTurmaDirectiveController($injector, $scope) {
		var vm = this;

		var EnturmacaoProfessorService = $injector.get('educacao.matricula.EnturmacaoProfessorService');

		vm.search = search;
		vm.formatResult = formatResult;

		function search(params) {
			var paramsList = {
				filter: null,
				offset: params.offset,
				limit: params.limit
			};

			if ($scope.byFuncionario) {
				paramsList.filter = ' turma = ' + _.get($scope.turma, 'id') +
					' and professor.id = ' + $scope.professor +
					' and tipo = "' + $scope.tipo + '" and dataEncerramento is null';
				return EnturmacaoProfessorService.getComponentes(paramsList);
			}

			if ($scope.isAtividade) {
				paramsList.filter = '(descricaoAtividade like "' + params.term + '") and idTurma in (' + _.pluck($scope.turmas, 'id').join() + ')';
				return EnturmacaoProfessorService.getAtividade(paramsList).then(returnContent);
			}

			paramsList.filter = '(' + getDisciplinaExpression() + ' like "' + params.term + '")';
			paramsList.matriz = $scope.matriz;
			paramsList.etapas = $scope.etapas;
			return EnturmacaoProfessorService.getDisciplina(paramsList).then(returnContent);

			function returnContent(data){
				return {
					content: data,
					hasNext: false
				};
			}
		}

		function getDisciplinaExpression() {
			return $scope.isEixoTematico ? 'descricaoEixoTematico' : ($scope.isCampoExperiencia ? 'descricaoCampoExperiencia' : 'descricaoDisciplina');
		}

		function formatResult(data) {
			return $scope.formatResult ? $scope.formatResult(data) : formatItem(data);
		}

		function formatItem(item) {
			if ($scope.isAtividade) {
				return _.get(item, 'atividade.descricao');
			}

			return _.get(item, 'disciplina.descricao') || _.get(item, 'campoExperiencia.descricao') || _.get(item, 'eixoTematico.descricao') ;
		}
	}

})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.ConfiguracaoInscricaoService', ConfiguracaoInscricaoService);

    ConfiguracaoInscricaoService.$inject = ['ServiceBuilder'];

    function ConfiguracaoInscricaoService(ServiceBuilder) {
        var selfService = ServiceBuilder
            .create()
            .path('matricula', 'configuracao-inscricao')
            .enum('NivelEscolar', 'CriterioClassificacao', 'Turno')
            .build();

        selfService.getPage = getPage;
        selfService.getCriterios = getCriterios;
        selfService.hasInscritosWithOrdemPreferencia = hasInscritosWithOrdemPreferencia;

        function getPage(filter, offset, limit, sort) {
            return selfService.getEndpoint().customGET('', {
                filter: filter,
                offset: offset,
                limit: limit,
                sort: sort
            });
        }

        function getCriterios() {
            return selfService.getEndpoint().all('criterios-classificacao').getList();
        }

        function hasInscritosWithOrdemPreferencia(configId) {
            return selfService.getEndpoint().one('possui-ordem-preferencia', configId).get();
        }

        return selfService;
    }
})();
(function () {
    'use strict';

    var tipoMatriculas = [
        {
            key: 'EDUCACAO_BASICA',
            value: 0,
            description: 'Educação básica'
        },
        {
            key: 'COMPLEMENTAR',
            value: 1,
            description: 'Complementar'
        },
        {
            key: 'AEE',
            value: 2,
            description: 'AEE'
        },
        {
            key: 'ATIVIDADE_COMPLEMENTAR',
            value: 3,
            description: 'Atividade complementar'
        },
        {
            key: 'PROGRESSAO_PARCIAL',
            value: 4,
            description: 'Progressão parcial'
        },
        {
            key: 'ACELERACAO',
            value: 5,
            description: 'Aceleração'
        },
        {
            key: 'EJA',
            value: 6,
            description: 'EJA'
        }];

    angular
        .module('educacao.matricula')
        .directive('appFiltroAvancadoMatriculaFiltrosPadroes', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'matricula/matriculas/filtro-avancado/matricula/filtros-padroes/filtros-padroes.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorFiltroPadraoMatricula: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = [
        '$scope',
        '$q',
        'educacao.matricula.MatriculaService',
        'educacao.matricula.MatriculaVagaService'
    ];

    function Controller(
        $scope,
        $q,
        MatriculaService,
        MatriculaVagaService
    ) {
        var vm = this;
        vm.filterResultEnuns = filterResultEnuns;
        vm.contadorMatriculaDadosPadroes = contadorMatriculaDadosPadroes;
        vm.EnumTipoMatricula = createPromise(tipoMatriculas);
        vm.EnumResponsavelTransporteEscolar = MatriculaVagaService.getResponsavelTransporteEscolar().then(function(dados){
            vm.ENUM_RESPONSAVEL_TRANSPORTE_ESCOLAR = dados;
            return dados;
        });
        vm.EnumFormaIngresso = MatriculaVagaService.getFormaIngresso();
        vm.EnumSituacaoUltimaMatricula = MatriculaVagaService.getSituacaoUltimaMatricula();
        vm.EnumOrigemMatricula = MatriculaVagaService.getOrigemMatricula();
        vm.formatTipoMatricula = formatTipoMatricula;
        vm.formatTurno = formatTurno;
        vm.formatSituacaoMatricula = formatSituacaoMatricula;
        vm.formatResponsavelTransporte = formatResponsavelTransporte;
        vm.formatEscolarizacaoEspacoDiferenciado = formatEscolarizacaoEspacoDiferenciado;
        vm.EnumEspacoDiferenciado = MatriculaVagaService.getEspacoDiferenciado().then(function (dados){
            vm.ENUM_ESPACO_DIFERENCIADO = dados;
            return dados;
        });

        vm.expressionCustomBuildersDadosPadroes = {
            utilizarTransporteEscolar: function (filters) {
                if (filters.utilizarTransporteEscolar || filters.utilizarTransporteEscolar === false) {
                    return 'utilizarTransporteEscolar = ' + !filters.utilizarTransporteEscolar;
                }
            },
            etapaEnsinoInep: function(filters){
                if (filters.etapaEnsinoInep) {
                    return 'etapaEnsinoInep = ' + filters.etapaEnsinoInep.id;
                }
            }
        };


        $scope.$watchGroup([
            'vm.advancedFilter.filtroPadraoMatricula.tipoMatricula',
            'vm.advancedFilter.filtroPadraoMatricula.avaliacaoDescritiva',
            'vm.advancedFilter.filtroPadraoMatricula.utilizarTransporteEscolar',
            'vm.advancedFilter.filtroPadraoMatricula.responsavelTransporteEscolar',
            'vm.advancedFilter.filtroPadraoMatricula.espacoDiferenciado',
            'vm.advancedFilter.filtroPadraoMatricula.origemMatricula',
            'vm.advancedFilter.filtroPadraoMatricula.situacaoUltimaMatricula',
            'vm.advancedFilter.filtroPadraoMatricula.formaIngresso'
        ],contadorMatriculaDadosPadroes);

        init();

        function init() {
            vm.expressionCustomBuilders = Object.assign(vm.expressionCustomBuilders, vm.expressionCustomBuildersDadosPadroes);
            vm.contadorFiltroPadraoMatricula = 0;

            vm.EnumTurno = MatriculaService.getEnum('Turno').then(function (Turno) {
                vm.Turno = Turno;
                return Turno;
            });

            vm.EnumSituacaoMatricula = MatriculaService.getEnum('SituacaoMatricula').then(function (SituacaoMatricula) {
                vm.SituacaoMatricula = SituacaoMatricula;
                return SituacaoMatricula;
            });

            contadorMatriculaDadosPadroes();
        }

        function contadorMatriculaDadosPadroes() {
            if (vm.advancedFilter && vm.advancedFilter.filtroPadraoMatricula) {
                vm.contadorFiltroPadraoMatricula = 0;
                vm.contadorFiltroPadraoMatricula = getFilterLength();
            }
        }

        function getFilterLength() {
            return Object.values(vm.advancedFilter.filtroPadraoMatricula).filter(function (n) { 
                return n !== undefined && n !== null && n !== 0 && n !== '' && n.length !== 0;
            }).length;
        }

        function filterResultEnuns(term, value) {
            if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                return value;
            }
        }

        function createPromise(values) {
            var createdEnum = {
                values: values
            };

            _.forEach(values, function (value) {
                value.id = value.key;
                createdEnum[value.key] = value;
            });

            return $q.when(createdEnum);
        }

        function formatTipoMatricula(tipoMatricula) {
            return _.find(tipoMatriculas,'key',tipoMatricula).description;
        }

        function formatTurno(turno) {
            return vm.Turno[turno].description;
        }

        function formatSituacaoMatricula(situacao) {
            return vm.SituacaoMatricula[situacao].description;
        }

        function formatResponsavelTransporte(responsavel){
            return vm.ENUM_RESPONSAVEL_TRANSPORTE_ESCOLAR[responsavel].description;
        }

        function formatEscolarizacaoEspacoDiferenciado(espaco){
            return vm.ENUM_ESPACO_DIFERENCIADO[espaco].description;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edRequiredEducacenso', ['$rootScope', function($rootScope) {
            function postLink(scope, element, attrs, ngModel) {

                //constants
                var PREFIXSELECT2 = 's2id_',
                    // CLASS_ERROR = 'field-error',
                    // CLASS_MULTI = '.select2-choices',
                    // CLASS_ONE = '.select2-choice',
                    isInit = true;

                var idElemento, condicaoDependencia, dependenciaOK, valueOK, status, isSelect2;

                condicaoDependencia = String(attrs.edRequiredEducacenso);
                dependenciaOK = condicaoDependencia === 'true' || condicaoDependencia.length === 0;

                var init = function() {
                    if (dependenciaOK) {
                        scope.statisticEducacenso.numberValidations++;
                    }

                    valueOK = false;
                    status = false;

                    if (element.attr('data-ui-select2')) {
                        isSelect2 = true;
                    }
                    if (_.isNull(ngModel.$modelValue) || _.isNaN(ngModel.$modelValue)) {
                        scope.statisticEducacenso.elements.push(isSelect2 ? PREFIXSELECT2 + element.attr('id') : element.attr('id'));
                    }
                };

                init();

                var alteraStatus = function() {

                    idElemento = isSelect2 ? PREFIXSELECT2 + element.attr('id') : element.attr('id');

                    status = valueOK && dependenciaOK;

                    if (!status) {
                        scope.statisticEducacenso.elements.push(idElemento);
                    } else {
                        _.remove(scope.statisticEducacenso.elements, function(id) {
                            return id === idElemento;
                        });

                        if (scope.statisticEducacenso.numberCorrects === scope.statisticEducacenso.numberValidations) {
                            this.visibleErrors = false;
                        }
                    }

                    if (dependenciaOK) {
                        $rootScope.$broadcast('statusEducacenso', {
                            status: status,
                            id: idElemento,
                            select2: isSelect2
                        });
                    }
                };

                scope.$watch(attrs.ngModel, function(newvalue, oldvalue) {

                    if (!_.isUndefined(newvalue)) {
                        if (newvalue !== oldvalue || isInit) {
                            /*if(_.isString(newvalue)){
                               newvalue = newvalue.trim().length===0?null:newvalue;             
                            }*/

                            var validate = _.isBoolean(newvalue) ? !_.isNull(newvalue) : !_.isEmpty(newvalue);

                            if (validate !== valueOK) {

                                valueOK = validate;
                                alteraStatus();
                            }
                        } else {
                            isInit = false;
                        }
                    }
                });

                attrs.$observe('edRequiredEducacenso', function(val) {
                    var booleanVar = (val === 'true' || val.length === 0);

                    if (dependenciaOK !== booleanVar) {
                        dependenciaOK = booleanVar;

                        if (dependenciaOK) {
                            scope.statisticEducacenso.numberValidations++;

                            if (valueOK) {
                                scope.statisticEducacenso.numberCorrects++;
                            }
                        } else {
                            if (scope.statisticEducacenso.numberValidations > 0) {
                                scope.statisticEducacenso.numberValidations--;
                            }

                            if (scope.statisticEducacenso.numberCorrects > 0 && valueOK) {
                                scope.statisticEducacenso.numberCorrects--;
                            }
                        }
                    }
                });

            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: postLink
            };
        }])
        .directive('edBtnEducacenso', [function() {
            return {
                restrict: 'E',
                templateUrl: 'common/directives/ed-btn-educacenso.directive.html'
            };
        }]);
})();

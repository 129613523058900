(function () {
    'use strict';

    angular.module('educacao.funcionario')
        .directive('appFuncionarioSelect', directive);

    function directive() {
        return {
            template: '<ui-select search="vm.listFuncionarios" ng-required="ngRequired" format-result="vm.getNomeFuncioanrio(data)" ng-model="ngModel" ng-disabled="ngDisabled" dropdown-css-class="dropdownCssClass"></ui-select>',

            scope: {
                ngModel: '=',
                ngDisabled: '=',
                ngRequired: '=',

                dropdownCssClass: '@',

                estabelecimento: '=',

                leciona: '='
            },

            compile: setMultiple,

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    function setMultiple(element, attr) {
        if (attr.hasOwnProperty('multiple')) {
            element.find('ui-select').attr('multiple', '');
        }
    }

    Controller.$inject = ['$scope', 'educacao.funcionario.FuncionarioMatriculaService'];
    function Controller($scope, FuncionarioMatriculaService) {

        var vm = this;

        vm.listFuncionarios = listProfessores;
        vm.getNomeFuncioanrio = getNomeFuncioanrio;

        function listProfessores(params) {
            params.leciona = $scope.leciona;

            var filter = '';

            filter += '( nome like "' +  params.term + '"';

            if($scope.leciona){
                filter += ' and leciona=' + $scope.leciona;
            }
            filter += ')';

            if($scope.estabelecimento){
                return FuncionarioMatriculaService.getMatriculasByEstablecimento(filter, $scope.estabelecimento.id, params.offset, params.limit);
            }
            return FuncionarioMatriculaService.list(filter, params.offset, params.limit);
        }

        function getNomeFuncioanrio(professor) {
            if (professor.matricula) {
                return professor.matricula + ' - ' + (professor.descricao || professor.servidor.pessoa.nome) + ' - ' + (professor.descricaoCargo || professor.cargo.descricao);
            } else {
                return (professor.descricao || professor.servidor.pessoa.nome) + ' - ' + (professor.descricaoCargo || professor.cargo.descricao);
            }
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ConfirmacaoConfiguracaoInscricaoCadCtrl', ConfirmacaoConfiguracaoInscricaoCadCtrl);

    ConfirmacaoConfiguracaoInscricaoCadCtrl.$inject = [
        '$rootScope',
        '$modalInstance',
        'id',
        'promiseTracker',
        'educacao.matricula.ConfiguracaoProcessoInscricaoService'
    ];

    function ConfirmacaoConfiguracaoInscricaoCadCtrl(
        $rootScope,
        $modalInstance,
        id,
        promiseTracker,
        ConfiguracaoProcessoInscricaoService
    ) {
    	var vm = this,
            REFRESH_CONFIGURACOES = 'refresh::configuracoesInscricao';

        vm.tracker = {
            removeConfiguracao: promiseTracker()
        };

        vm.removeConfiguracao = removeConfiguracao;

        function fechaModal() {
            $modalInstance.close();
        }

		function removeConfiguracao() {
            vm.tracker.removeConfiguracao.addPromise(
                ConfiguracaoProcessoInscricaoService.remove(id).then(function () {
                        $rootScope.$broadcast(REFRESH_CONFIGURACOES, {
                            remove: true
                        });

                        fechaModal();
                })
            );
        }

    }

})();
(function () {
    'use strict';
    angular.module('educacao.abonojustificativa')
        .controller('educacao.abonojustificativa.AbonoJustificativaListCtrl', Controller);

    Controller.$inject = ['$modalInstance', 'educacao.abonojustificativa.AbonoJustificativaService', 'ui.components.Modal', 
        'promiseTracker', '$rootScope', 'educacao.common.ItemEducacionalService', '$enumsCache', 
        'educacao.abonojustificativa.AbonoJustificativaFaltaService'
    ];

    function Controller($modalInstance, AbonoJustificativaService, $modal, promiseTracker, $rootScope, 
        ItemEducacionalService, $enumsCache, AbonoJustificativaFaltaService) {

        var vm = this;

        vm.loadingTracker = promiseTracker();
        vm.loadingList = promiseTracker();

        vm.listControls = {};

        vm.configuracoes = $modalInstance.params.configuracoes;
        vm.openModal = openModal;
        vm.remove = remove;
        var service = AbonoJustificativaService.buildService(vm.configuracoes.turma.id);
        var abonoJustificativaFaltaService = AbonoJustificativaFaltaService.buildService(vm.configuracoes.turma.id);
        $enumsCache.get('TipoAbono').then(function (enumData) {
            vm.TipoAbono = enumData;
            return enumData;
        });

        $enumsCache.get('TipoDispensaAbono').then(function (enumData) {
            vm.ModoDispensa = enumData;
            return enumData;
        });
        vm.isFaltasPorDia = vm.configuracoes.configuracao.tipoRegistroFrequencia === 'TOTAL_FALTAS_DIA';
        vm.labelItemEducacional = ItemEducacionalService.getLabel(vm.configuracoes.turma.tipoAtendimento);
        vm.isAtividade = ItemEducacionalService.isAtividade(vm.configuracoes.turma.tipoAtendimento);
        vm.turma = $modalInstance.params.configuracoes.turma;
        vm.showOrigemSistema = showOrigemSistema;
        vm.origemSistema = {
            EDUCACAO: 'Educação', 
            PROFESSORES: 'Professores'
        };
        vm.exibeAbonoJustificativaFalta = exibeAbonoJustificativaFalta;
        vm.isEjaModular = isEjaModular;

        vm.list = list;


        function list(params) {
            var filter = '';
            var append = '';

            if (vm.configuracoes.periodoAtual) {
                filter += 'periodoAvaliativo = ' + _.get(vm.configuracoes.periodoAtual, 'id') || -1;
                append = ' and ';
            }
            if (vm.configuracoes.aluno.enturmacao.id) {
                filter += append + 'enturmacao = ' + _.get(vm.configuracoes.aluno.enturmacao, 'id') || -1;
            }

            var paramsRequisicao = {
                filter: filter,
                offset: params ? params.offset : 0,
                limit: params ? params.limit : 20
            };

            return service.completos(paramsRequisicao)
                .then(function (dados) {
                    vm.abonosJustificativas = _.first(dados).content;
                    if (!_.isEmpty(vm.abonosJustificativas)) {
                        getAbonosJustificativasFaltas();
                    }
                    return dados;
                }).then(function (dados) {
                    return _.first(dados);
                });
        }

        function getAbonosJustificativasFaltas() {
            var filter = '';

            if (_.get(vm.configuracoes, 'aluno.enturmacao.id')) {
                filter += 'enturmacao.id = ' + vm.configuracoes.aluno.enturmacao.id;
            }

            if (vm.isFaltasPorDia) {
                abonoJustificativaFaltaService.getAbonosJustificativasFaltasDias(filter)
                    .then(function (dados) {
                        vm.abonoJustificativaFalta = _.first(dados).content;
                    });
               return; 
            }

            if (_.get(vm.configuracoes, 'periodoAtual.id')) {
                filter += 
                    ' and (abono.periodoAvaliativo.id = ' +
                    vm.configuracoes.periodoAtual.id +
                    ' or justificativa.periodoAvaliativo.id = ' +
                    vm.configuracoes.periodoAtual.id +
                    ')';
            }

            abonoJustificativaFaltaService.getAbonosJustificativasFaltasAulas(filter)
                .then(function (dados) {
                    vm.abonoJustificativaFalta = _.first(dados).content;
                });
        }

        function openModal(registro) {
            $modal.open({
                templateUrl: 'abono-justificativa/abono-justificativa-cad.html',
                controller: 'educacao.abonojustificativa.AbonoJustificativaCadCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    configuracoes: vm.configuracoes,
                    registro: registro,
                    abonoJustificativas: vm.abonosJustificativas
                }
            }).result.then(vm.listControls.search);
        }

        // $rootScope.$on('diarioClasse::refreshFaltas', list);

        function remove(item) {
            if (vm.configuracoes.permissionsPeriodoEncerrado) {
                var promiseRemoverPeriodoEncerrado =  service.removeAbonoPeriodoEncerrado(item)
                        .then(diarioClasse(item))
                        .then(vm.listControls.search);
                vm.loadingList.addPromise(promiseRemoverPeriodoEncerrado);
                return promiseRemoverPeriodoEncerrado;
            }

            var promiseRemover = service.remove(item)
                            .then(diarioClasse(item))
                            .then(vm.listControls.search);
            vm.loadingList.addPromise(promiseRemover);
            return promiseRemover;
        }

        function diarioClasse(item) {
                    $rootScope.$broadcast('diarioClasse::refreshFaltas', {
                        idEnturmacao: item.enturmacao.id,
                        idTurma: vm.configuracoes.turma.id,
                        aluno: vm.configuracoes.aluno
                    });
        }

        function showOrigemSistema(item) {
            if (item.abonoFalta || item.justificativaFalta) {
                return vm.origemSistema.PROFESSORES;
            }
            return vm.origemSistema.EDUCACAO;
        }

        function exibeAbonoJustificativaFalta(id, idItemEducacional) {
            if(_.isEmpty(vm.abonoJustificativaFalta)) {
                return;
            }

            var faltasporRegistro = _.first(vm.abonoJustificativaFalta.filter(function (item) {
                return item.abonosJustificativasId === id;
            }));

            var faltasOrdenadas = vm.isFaltasPorDia ? sortFaltasDias(faltasporRegistro.faltas): sortFaltaAulas(faltasporRegistro.faltas, idItemEducacional);

            return faltasOrdenadas.map(function(falta) {
                return vm.isFaltasPorDia ? getDescricacaoFaltasDias(falta.data) : getDescricaoFaltasAulas(falta);
            }); 
        }

        function getDescricacaoFaltasDias(data) {
            return moment(data).format('DD/MM/YYYY');
        }

        function getDescricaoFaltasAulas(falta) {
            var descricao = falta.itemEducacional.descricao;

            if (isEjaModular(vm.turma)) {
                descricao = falta.itemEducacional.pai.descricao;
            }

            return moment(falta.alocacaoAula.data).format('DD/MM') + 
                ' - Aula ' +
                falta.alocacaoAula.numero +
                ' - ' + 
                descricao;
        }

        function sortFaltasDias(faltas) {
            return _.sortByOrder(faltas, ['data'], ['asc']);
        }

        function sortFaltaAulas(faltas, idItemEducacional) {
            var faltasSeparadas = faltas;

            if (!isEjaModular(vm.turma)) {
                faltasSeparadas = separaFaltasPorComponenteCurricular(faltas, idItemEducacional);
            }

            return _.sortByOrder(
                faltasSeparadas,
                ['alocacaoAula.data', 'alocacaoAula.numero', 'itemEducacional.descricao'],
                ['asc', 'asc', 'asc']
            );
        }

        function separaFaltasPorComponenteCurricular(faltas, idItemEducacional) {
            return faltas.filter(function (item) {
                return item.itemEducacional.id === idItemEducacional;
            });
        }

        function isEjaModular(turma) {
            return Boolean(_.get(turma, 'etapaMatriz.modular'));
        }

    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('selecaoTurnos', directive);

    function directive() {
        return {
            restrict: 'E',

            scope: {inscricao: '='},

            templateUrl: 'matricula/inscricao/step-localizando/selecao-turnos/selecao-turnos.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    var buttons = [{
        selected: false,
        boxClass: ['box-turnoM', 'boxshadowM'],
        iconClass: 'fa-sun-o',
        label: 'MATUTINO',
        value: 'MATUTINO'
    }, {
        selected: false,
        boxClass: ['box-turnoV', 'boxshadowV'],
        iconClass: 'fa-sun-o',
        label: 'VESPERTINO',
        value: 'VESPERTINO'
    }, {
        selected: false,
        boxClass: ['box-turnoN', 'boxshadowN'],
        iconClass: 'fa-moon-o',
        label: 'NOTURNO',
        value: 'NOTURNO'

    }, {
        selected: false,
        boxClass: ['box-turnoI', 'boxshadowI'],
        iconClass: 'fa-clock-o',
        label: 'INTEGRAL',
        value: 'INTEGRAL'
    }];

    Controller.$inject = ['$scope', 'promiseTracker', 'InscricaoMatriculaRegularService'];
    function Controller($scope, promiseTracker, InscricaoMatriculaRegularService) {
        var vm = this;
        var turnosSelecionados = [];

        vm.loading = promiseTracker();
        vm.buttons = [];

        vm.getClass = getClass;
        vm.onTurnoClicked = onTurnoClicked;

        vm.isValid = isValid;
        vm.back = back;
        vm.next = next;

        $scope.$watch('inscricao.turnos', function (turnos) {
            turnosSelecionados = turnos || [];
        });
        $scope.$watchGroup(['inscricao.configuracao.id', 'inscricao.equivalencia', 'inscricao.dataNascimento'], loadTurnos);

        init();

        function init() {
            vm.loading.addPromise(
                InscricaoMatriculaRegularService.getTurno().then(function (Turno) {
                    vm.Turno = Turno;
                })
            );
        }

        function loadTurnos() {
            var config = $scope.inscricao.configuracao;
            if (!config) {
                return;
            }

            var idConfiguracao = config.id;
            var equivalencia = $scope.inscricao.equivalencia;
            var dataNascimento = $scope.inscricao.dataNascimento;

            if (!idConfiguracao && !equivalencia && !dataNascimento) {
                return;
            }

            var promise;
            if (equivalencia) {
                promise = InscricaoMatriculaRegularService.listTurnosEquivalencia(idConfiguracao, equivalencia);
            } else {
                promise = InscricaoMatriculaRegularService.listTurnosInfantil(idConfiguracao, dataNascimento);
            }

            vm.loading.addPromise(
                promise.then(configuraBotoes)
            );

            return promise;
        }

        function configuraBotoes(turnos) {

            vm.buttons = buttons.filter(function (button) {
                button.selected = turnosSelecionados.indexOf(button.value) !== -1;
                return turnos.indexOf(button.value) !== -1;
            });

            var diff = buttons.length - turnos.length;
            vm.buttonClass = diff ? 'col-md-4' : 'col-md-3';
            vm.offsetClass = diff > 1 ? 'col-md-' + ((diff - 1 ) * 2) : '';
        }

        function getClass(btn) {
            if (btn.selected) {
                return btn.boxClass;
            }
            return btn.boxClass[0];
        }

        function onTurnoClicked(button) {
            button.selected = !button.selected;
            if (button.selected) {
                turnosSelecionados.push(button.value);
            } else {
                turnosSelecionados = _.without(turnosSelecionados, button.value);
            }
        }

        function isValid() {
            return !_.isEmpty(turnosSelecionados);
        }

        function back() {
            $scope.inscricao.$$etapaSelecionada = false;
        }

        function next() {
            $scope.inscricao.turnos = turnosSelecionados;
            $scope.inscricao.$$turnosSelecionados = true;
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('dropdownFiltroAvancadoProfessoresGestaoPessoas', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-professores-gestao-pessoas/dropdown-pesquisa-avancada-professores/dropdown-pesquisa-avancada-professores.html',
            scope: {},
            bindToController: {
                mountFilter: '&',
                filter: '=',
                isSubstituidos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {

        var vm = this;
        
        vm.limparFiltrosAvancados = limparFiltrosAvancados;
        vm.togglePesquisaAvancada = togglePesquisaAvancada;

        vm.MODALIDADE_ESCOLAR = [
            {key: 'ACELERACAO', descricao: 'Aceleração (Educação básica)'},
            {key: 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO', descricao: 'Atividades AEE'},
            {key: 'ATIVIDADE_COMPLEMENTAR', descricao: 'Atividades complementares'},
            {key: 'COMPLEMENTAR', descricao: 'Complementar'},
            {key: 'EDUCACAO_BASICA', descricao: 'Educação Básica'},
            {key: 'EJA', descricao: 'EJA'}

        ];

        function togglePesquisaAvancada() {
            vm.showBottomSheet = !vm.showBottomSheet;
        }

        function limparFiltrosAvancados($event) {
            $event.stopPropagation();
            vm.filter.filtroComponenteCurricular = null;
            vm.filter.filtroTurno = null;
            vm.filter.filtroModalidadeEscolar = null;
            vm.filter.filtroNivelEscolar = null;
            vm.filter.filtroTipoVinculo = null;
            vm.filter.filtroEstabelecimento = null;
            vm.filter.filtroSubstituto = null;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('educacao.avaliacao-externa')
        .directive('appAvaliacaoExterna', appAvaliacaoExterna);

    appAvaliacaoExterna.$inject = [];
    function appAvaliacaoExterna() {

        return {
            templateUrl : 'avaliacao-externa/avaliacao-tab.html',
            bindToController: true,
            controller: Controller,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                avaliacoes:'='
            }
        };

    }

    Controller.$inject = ['ui.components.Modal'];
    function Controller (uiModal) {
        
        var vm = this;
        
        vm.openModalAvaliacao = openModalAvaliacao;
        vm.removeAvaliacao = removeAvaliacao;
        
        function openModalAvaliacao(avaliacao, $index) {
            
            var modalAvaliacaoConfig = {
                templateUrl: 'avaliacao-externa/avaliacao-externa-modal.cad.html',
                controller: 'educacao.avaliacao-externa.AvaliacaoExternaController as vm',
                params: {
                    avaliacao: avaliacao
                },
                size: 'lg'
            };
            
            uiModal.open(modalAvaliacaoConfig)
                .result
                .then(onModalClose);
            
            function onModalClose(avaliacaoExterna) {
                if(_.isUndefined($index)||_.isNull($index)){
                    adicionaAvaliacao(avaliacaoExterna.avaliacao);
                } else {
                    atualizaAvaliacao($index, avaliacaoExterna);
                    if(avaliacaoExterna.remove){
                        removeAvaliacao($index);
                    }
                }
            }
        }
        
        function adicionaAvaliacao(avaliacao){
            vm.avaliacoes.push(avaliacao);
        }

        function atualizaAvaliacao(indice, avaliacaoExterna) {
            vm.avaliacoes[indice] = avaliacaoExterna.avaliacao;
        }

        function removeAvaliacao(indice){
            vm.avaliacoes.splice(indice, 1);
        }
    }
})();
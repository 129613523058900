(function () {
    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.GeralCtrl', GeralCtrl);

    GeralCtrl.$inject = [
        '$scope',
        'bfc.authentication.AuthenticationContext',
        'educacao.context.ContextoEntidade',
        'educacao.lista-espera-novo.encaminhado.EncaminhadoService',
        '$state',
        'EDUCACENSO_ETAPAS'
    ];

    function GeralCtrl($scope, AuthenticationContext, ContextoEntidade, EncaminhadoService, $state, EDUCACENSO_ETAPAS) {
        var vm = this;

        $scope.user = AuthenticationContext.getUserInfo();
        $scope.entidade = ContextoEntidade.getEntidade();
        $scope.goToEtapaCenso = goToEtapaCenso;
        $scope.EDUCACENSO_ETAPAS = EDUCACENSO_ETAPAS;

        EncaminhadoService.getTotal().then(function (total) {
            vm.totalEncaminhados = total;
        });

        function goToEtapaCenso(etapa) {
            $state.go('app.educacenso', {etapa: etapa}).then(function () {
                $scope.$emit('educacenso:etapa', {etapa: etapa});

            });
        }
    }
})();

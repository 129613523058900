(function() {
    'use strict';
    angular.module('educacao.common')
        .directive('edRestrictTextEducacenso', [function() {

            function postLink(scope, element, attrs, ngModel) {
                function setViewValue(value) {
                    ngModel.$setViewValue(value);
                    ngModel.$render();
                }

                var transformedInput;

                ngModel.$parsers.push(function(inputValue) {
                    transformedInput = inputValue.replace(/[^a-zA-Z0-9\-\ª\º\s]/, '');

                    if (transformedInput !== inputValue) {
                        setViewValue(transformedInput);
                    }

                    return transformedInput;
                });

                element.on('keyup.edFlyRestrictTextEducacenso', function() {
                    if (transformedInput !== element.val()) {
                        setViewValue(transformedInput);

                        return transformedInput;
                    }
                });
            }

            return {
                restrict: 'A',
                require: 'ngModel',
                link: postLink
            };
        }]);
})();

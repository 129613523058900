(function() {
    'use strict';
    angular.module('educacao.funcionario').directive('funcionarioNiveisEtapas', function() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'planejamento/funcionarios/funcionario/niveis-etapas-habilitacoes-professor.html';
        config.scope = {};
        config.scope.ngModel = '=';
        config.scope.tracker = '=';
        config.scope.titulo = '=';
        config.scope.maxNivelEtapa = '=';
        config.scope.enumEquivalenciaEtapa = '=';
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;

    });
    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;
        vm.titulo = $scope.titulo;
        vm.adiciona = adiciona;
        vm.removeItem = removeItem;
        vm.tracker = $scope.tracker;
        init();

        function removeItem(item) {
            var index = $scope.ngModel.indexOf(item);
            $scope.ngModel.splice(index, 1);
            if ($scope.ngModel.length === 0) {
                $scope.ngModel.push({});
            }
        }

        function init() {
            if (_.isEmpty($scope.ngModel)) {
                $scope.ngModel = [];
                $scope.ngModel.push({});
            }
        }

        function adiciona() {
            if (_.isEmpty($scope.ngModel)) {
                $scope.ngModel = [];
            }
            $scope.ngModel.push({});
        }

        vm.filterResult = filterResult;

        function filterResult(term, value) {
            if ($scope.maxNivelEtapa && value.value > $scope.maxNivelEtapa) {
                return;
            }
            var filtra = _.find($scope.ngModel, function(etapa) {
                return value.id === etapa.equivalencia;
            });
            var valueMaisculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (filtra) {
                return;
            }

            if (valueMaisculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }
    }

})();

(function() {

	'use strict';

	var moduloCommon = angular.module('educacao.common');

	moduloCommon.factory('educacao.common.BaseCadastralService', [
		function() {

			function _situacoes(data) {

				data.isSituacaoAprovada = function() {
					return data.situacao === 'APROVADA';
				};
				data.isSituacaoElaboracao = function() {
					return data.situacao === 'EM_ELABORACAO';
				};
				data.isSituacaoExclusao = function() {
					return data.situacao === 'EM_EXCLUSAO';
				};
				data.isSituacaoModificada = function() {
					return data.situacao === 'MODIFICADA';
				};
				data.isSituacaoNova = function() {
					return data.situacao === 'NOVA';
				};

				return data;
			}

			function _tramitacaoSituacoes(data) {

				data.isAlteracao = function() {
					return data && data.situacao === 'EM_ALTERACAO';
				};
				data.isAprovado = function() {
					return data && data.situacao === 'APROVADO';
				};
				data.isElaboracao = function() {
					return data && data.situacao === 'EM_ELABORACAO';
				};
				data.isEnviadoLegislativo = function() {
					return data && data.situacao === 'ENVIADO_LEGISLATIVO';
				};

				return data;
			}

			function _sortBySituacao(obj) {
				/**
				 * Ordenação do objeto.
				 * @param  {Object} first Primeiro objeto da interação.
				 * @param  {Object} last  Último objeto da interação.
				 * @return {Object}		  Objeto ordenado por meio da situação da base cadastral.
				 */
				return obj.stableSort(function(first, last) {

					// Otimização
					if (first.isSituacaoAprovada() && last.isSituacaoAprovada()) {
						return 0;
					} else if (first.isSituacaoAprovada()) {
						return 1;
					} else if (last.isSituacaoAprovada()) {
						return -1;
					}

					/**
					 * Comparação de objetos para definir sua posição.
					 * @param  {[type]} fM	Primeiro objeto com situação MODERAÇÃO
					 * @param  {[type]} fN	Primeiro objeto com situação NOVA
					 * @param  {[type]} fE	Primeiro objeto com situação EM_EXCLUSAO
					 * @param  {[type]} lM	Último objeto com situação MODERAÇÃO
					 * @param  {[type]} lN	Último objeto com situação NOVA
					 * @param  {[type]} lE	Último objeto com situação EM_EXCLUSAO
					 * @return {Number}		Posição da ordenação: acima (-1), mesma (0), abaixo (1)
					 */

					function compare(fM, fN, fE, lM, lN, lE) {
						return (fM && lN || fM && lE || fN && lE) ? -1 :
							(fN && lM || fE && lM || fE && lN) ? 1 : 0;
					}

					return compare(
						first.isSituacaoModificada(),
						first.isSituacaoNova(),
						first.isSituacaoExclusao(),
						last.isSituacaoModificada(),
						last.isSituacaoNova(),
						last.isSituacaoExclusao()
					);
				});
			}

			return {
				// Retorna as funções para verificação da situação do PPA e LDO
				aplicarTransformacaoSituacao: function(data) {
					if (data) {
						if (angular.isArray(data)) {
							angular.forEach(data, function(obj) {
								_situacoes(obj);
							});
						} else {
							_situacoes(data);
						}
					}
					return data;
				},
				// Retorna as funções para verificação da situação da tramitação do PPA e LDO
				tramitacaoAplicarTransformacaoSituacao: function(data) {
					return data ? _tramitacaoSituacoes(data) : undefined;
				},
				sortBySituacao: _sortBySituacao
			};
		}
	]);

})();

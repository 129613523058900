(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.common.$commonsProvider', 'educacao.historico.HistoricoServiceProvider'];

    function HistoricoConfig($commonsProvider, HistoricoServiceProvider) {

        HistoricoServiceProvider.addTipoHandler('MOVIMENTACAO', {
            badge: {
                icon: 'fa-exchange',
                tooltip: 'Movimentado(a)'
            },
            template: '<small>{{descricaoTipo.valor}}: Movimentação realizada em <strong>{{dataMovimentacao.valor}}</strong><small>',
            campos: {
                observacao: {
                    insercao: {
                        template: '<br><small>Observação: <strong>{{valor}}</strong></small><br>'
                    }
                }
            },
            openEdicaoMovimentacao: openEdicaoMovimentacao,
            actionTemplates:{
                actionTemplate: [ '<button class="btn btn-xs btn-default" ' +
                 'bf-permissions operation="movimentar"' +
                'title="Editar movimentação" ' +
                'ng-if="tipo.valor!=\'READMISSAO\'" ' +
                'ng-click="handler.openEdicaoMovimentacao(matricula.valor,tipo.valor,idMovimentacao.valor)">' +
                '<i class="fa fa-pencil"></i></button>']
            }
        });

        function openEdicaoMovimentacao(matriculaId, tipo, idMovimentacao) {
            $commonsProvider.$get().$modal.open({
                templateUrl: 'matricula/matriculas/movimentacao/movimentacao-generica.modal.html',
                controller: 'educacao.matricula.MovimentacaoGenericaModalCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    tipoMovimentacao: tipo,
                    idMovimentacao: idMovimentacao,
                    matriculaId: matriculaId
                }
                
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.FiliacaoService', FiliacaoService);

    FiliacaoService.$inject = ['ServiceBuilder','Restangular'];

    function FiliacaoService(ServiceBuilder, Restangular) {

        var self = ServiceBuilder
            .create()
            .path('pessoa', 'filiacao')
            .enum('GrauInstrucao',
            'EstadoCivil',
            'Parentesco',
            'SituacaoTrabalho',
            'TipoFiliacao',
            'NaturezaFiliacao',
            'Sexo',
            'DiaDaSemanaDto')
            .build();

        self.getDetalhesFiliacao = getDetalhesFiliacao;
        self.getPessoas = getPessoas;
        self.getFiliacaoById = getFiliacaoById;
        self.camposAdicionais = camposAdicionais;

        return self;

        function getDetalhesFiliacao(filter){
            return Restangular.one('pessoa/aluno-filiacao').get(filter);
        }

        function getPessoas(filter, offset, limit) {
            return self.getEndpoint()
                .one('vinculo-alunos')
                .get({
                    filter: filter,
                    offset: offset,
                    limit: limit
                });
        }

        function getFiliacaoById(filterId) {
            return Restangular.all('pessoa/filiacao/vinculo-alunos')
                .withHttpConfig({ cache: true })
                .get('', {
                    filter: 'cpf =\'' + filterId + '\''
                })
                .then(Restangular.strip);
        }

        function camposAdicionais(filiacaoId) {
            return Restangular.one('pessoa/filiacao/campos-adicionais', filiacaoId)
                .get()
                .then(Restangular.stripRestangular);
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.LiberacaoVagasEstabelecimentoService', LiberacaoVagasEstabelecimentoService);

    LiberacaoVagasEstabelecimentoService.$inject = ['$injector'];

    function LiberacaoVagasEstabelecimentoService($injector) {
        var GenericService = $injector.get('GenericService');
        
        var service = GenericService
            .createWithCustomMethods({
                liberados: liberados,
                bloquearTodos: bloquearTodos
            })
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'liberacao-vaga'
            });

        function liberados() {
            service.validateObject();

            return service.modules().all(service._service.uri + '/liberados').getList();
        }
        
        function bloquearTodos(){
            service.validateObject();
            
            return service.modules().one(service._service.uri).one('bloquear-todos').put();
        }
        
        return service;
    }
})();

(function () {
    'use strict';
    var moduloCommon = angular.module('educacao.matricula');
    moduloCommon.controller('educacao.matricula.QuadroVagasCadCtrl', [
        '$q', '$injector', '$modalInstance', '$modalTracker', '$rootScope',
        function QuadroVagasCadCtrl($q, $injector, $modalInstance,
                                    $modalTracker, $rootScope) {

            var QuadroVagasService = $injector.get('educacao.matricula.QuadroVagasService');
            var EtapaService = $injector.get('educacao.matriz.EtapaService');
            var promiseTracker = $injector.get('promiseTracker');
            var EstabelecimentoService = $injector.get('educacao.pessoas.EstabelecimentoServiceDeprecated');
            var ContextoEstabelecimento = $injector.get('educacao.context.ContextoEstabelecimento');

            var id = $modalInstance.params.id;
            var readOnly = $modalInstance.params.readOnly;
            var tipoEdit = $modalInstance.params.tipoEdit;

            var vm = this;
            var _select2 = {};
            vm.tracker = {};
            vm.tracker.load = promiseTracker();
            vm.tracker.saveContinuar = promiseTracker();
            vm.tracker.save = promiseTracker();
            vm.tracker.remove = promiseTracker();
            vm.save = _save;
            vm.remove = _remove;
            vm.removerMatriz = _removerMatriz;
            vm.vagas = {};
            vm.vagas.estabelecimentos = null;
            vm.addMatriz = _addMatriz;
            vm.carregarEtapasMatriz = _carregarEtapasMatriz;
            vm.adicionarTurno = _adicionarTurno;
            vm.listTurnos = [];
            vm.estabelecimentos = [];
            vm.listMatrizes = [];
            vm.matrizesEstabelecimentos = _matrizesEstabelecimentos;
            vm.removerTurno = _removerTurno;
            vm.exibirMatriz = _exibirMatriz;
            vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
            vm.exibirBtnAddTurnos = _exibirBtnAddTurnos;
            vm.changeTurno = _changeTurno;
            vm.vagasValid = _vagasValid;
            vm.filtrarTurnos = _filtrarTurnos;
            vm.matrizSearch = _matrizSearch;
            vm.estabelecimentosSearch = _estabelecimentosSearch;

            var modo = {
                adicionando: !id && !readOnly,
                editando: id && !readOnly,
                tipoEdit: tipoEdit
            };

            init();

            function init() {
                var propriedadesPublicas = {
                    select2: _select2,
                    cancel: _fechaModal,
                    mode: modo
                };
                angular.extend(vm, propriedadesPublicas);
                vm.tracker.load.addPromise(
                    $q.all([
                        setPristine(),
                        carregarEstabelecimentos(),
                        QuadroVagasService.getEnums().then(function (enums) {
                            editTurnosEnums(enums);
                            tipoSaldoEnum(enums);
                        })
                    ]).then(
                        function postInit() {
                            vm.windowTitle = modo.adicionando ? 'Adicionando quadro de vagas' : 'Editando quadro de vagas';
                        })
                        .then(function () {
                            normalizeToEdit();
                        })
                );
            }

            function setPristine() {
                if (vm.form) {
                    vm.form.$setPristine();
                    vm.form.$setUntouched();
                }
            }

            function carregarEstabelecimentos() {
                EstabelecimentoService.getList().then(function (data) {
                    angular.forEach(data, function (estabelecimento) {
                        vm.estabelecimentos.push({
                            'descricao': estabelecimento.descricao,
                            'id': estabelecimento.id
                        });
                    });
                });
            }

            function tipoSaldoEnum(data) {
                vm.tipoSaldo = data.TipoSaldo;
            }

            function normalizeToEdit() {
                if (Boolean(id)) {
                    normalizarEstabelecimentoEdit(id);
                } else {
                    carregarEstabelecimentoContexto();
                }
            }

            function editTurnosEnums(enums) {
                vm.listTurnos = enums.Turno;
                vm.turnosEnum = {
                    values: []
                };
                angular.forEach(vm.listTurnos, function (turno) {
                    var turnoEnum = {
                        'id': turno.key,
                        'key': turno.key,
                        'description': turno.description
                    };

                    vm.turnosEnum[turno.key] = turnoEnum;
                    vm.turnosEnum.values.push(turnoEnum);
                });
                vm.turnosEnum = $q.when(vm.turnosEnum);
            }

            function carregarEstabelecimentoContexto() {
                if (vm.hasEstabelecimento) {
                    vm.vagas.estabelecimentos = [{
                        'id': ContextoEstabelecimento.getEstabelecimento().id,
                        'descricao': ContextoEstabelecimento.getEstabelecimento().pessoa.nome,
                        'matrizes': [{}]
                    }];
                }
            }

            function _save(continuar) {
                var vagas = normalizarVagas();

                if (vm.mode.editando) {
                    atualizarListaVagas(vagas, continuar);
                } else {
                    salvarVagas(vagas, continuar);
                }

            }

            function salvarVagas(vagas, continuar) {
                vm.tracker.save.addPromise(
                    QuadroVagasService.save(vagas).then(function (result) {
                        $rootScope.$broadcast('refresh::vagas', {
                            data: result,
                            add: !id,
                            edit: Boolean(id)
                        });
                        _onSuccess(continuar);

                    }));
            }

            function _remove() {
                var vagas = normalizarVagas(true);
                atualizarListaVagas(vagas);
            }

            function atualizarListaVagas(vagas) {
                var vagasRemovidas = obterVagasRemovidas(vagas);
                vm.tracker.save.addPromise(
                    QuadroVagasService.atualizarVagas(vagas).then(function (result) {
                        $rootScope.$broadcast('refresh::vagas', {
                            data: result,
                            vagasRemovidas: vagasRemovidas
                        });
                        _fechaModal();
                    })
                );
            }

            function obterVagasRemovidas(vagas) {
                return vagas.filter(function (vaga) {
                    return vaga.excluirVaga;
                });
            }

            function _onSuccess(continuar) {
                if (continuar) {
                    id = null;
                    vm.form.$setPristine();
                    vm.form.$setUntouched();
                    vm.vagas = {};
                } else {
                    _fechaModal();
                }
            }

            function _fechaModal() {
                $modalInstance.close();
            }

            function persistSaldos(tipo, vaga, value) {

                var existe = _.some(vaga.saldos, function (data) {
                    return data.tipo === tipo;
                });

                if (!_.isNumber(value)) {
                    if (existe) {
                        _.remove(vaga.saldos, function (data) {
                            return data.tipo === tipo;
                        });
                    }
                    return;
                }

                if (existe) {
                    _.forEach(vaga.saldos, function (data) {
                        if (data.tipo === tipo) {
                            data.qtdVagas = value;
                        }
                    });
                } else {
                    vaga.saldos.push({
                        tipo: tipo,
                        qtdVagas: value
                    });
                }
            }

            function normalizarVagas(excluirTodasVagas) {

                var vagas = [];

                angular.forEach(vm.vagas.estabelecimentos, function (estabelecimento) {
                    angular.forEach(estabelecimento.matrizes, function (matriz) {
                        if (_.has(matriz.matriz, 'etapas')) {
                            angular.forEach(matriz.matriz.etapas, function (etapa) {
                                angular.forEach(etapa.turnos, function (turno) {

                                    if (!turno.saldos) {
                                        turno.saldos = [];
                                    }

                                    persistSaldos(vm.tipoSaldo[0].key, turno, turno.qtdVagasRematricula);
                                    persistSaldos(vm.tipoSaldo[1].key, turno, turno.qtdVagasInscricao);

                                    vagas.push({
                                        'id': turno.idVaga,
                                        'estabelecimento': {
                                            'id': estabelecimento.id,
                                            'descricao': estabelecimento.descricao
                                        },
                                        'matriz': {
                                            'id': matriz.matriz.id,
                                            'descricao': matriz.matriz.descricao
                                        },
                                        'etapaMatriz': {
                                            'id': etapa.id,
                                            'descricao': etapa.descricao
                                        },
                                        'turno': turno.turno,
                                        'qtdVagas': turno.qtdVagas,
                                        'excluirVaga': excluirTodasVagas || turno.excluirVaga,
                                        'saldos': turno.saldos
                                    });
                                });
                            });
                        }
                    });

                });

                return vagas;
            }

            function normalizarEstabelecimentoEdit(vagas) {
                angular.forEach(vagas, function (vaga) {
                    var estabelecimento = _.find(vm.vagas.estabelecimentos, {
                        'id': vaga.estabelecimento.id
                    });
                    if (!estabelecimento) {
                        estabelecimento = {
                            'id': vaga.estabelecimento.id,
                            'descricao': vaga.estabelecimento.descricao
                        };
                        estabelecimento.matrizes = [];
                        if(!vm.vagas.estabelecimentos){
                            vm.vagas.estabelecimentos = [];
                        }
                        vm.vagas.estabelecimentos.push(estabelecimento);
                    }

                    var matrizId = angular.toJson(vaga.matriz, false);
                    var matriz = _.find(estabelecimento.matrizes, {
                        'id': matrizId
                    });
                    if (!matriz) {
                        matriz = {
                            'id': matrizId,
                            'matriz': {
                                'id': vaga.matriz.id,
                                'descricao': vaga.matriz.descricao,
                                'etapas': []
                            },
                            'edit': true
                        };
                        estabelecimento.matrizes.push(matriz);
                        //vm.listMatrizes.push(vaga.matriz);
                        if (modo.editando && modo.tipoEdit !== 'etapa') {
                            carregarEtapas(matriz.matriz, vagas);
                        } else {
                            carregarEtapaMatrizToEdit(matriz.matriz, vaga);
                        }

                    }
                });
            }

            function carregarEtapas(matriz, vagas) {
                if (matriz.id) {
                    EtapaService.getList(matriz.id).then(function (result) {
                        matriz.etapas = result;
                        angular.forEach(matriz.etapas, function (etapa) {
                            if (vagas) {
                                angular.forEach(vagas, function (vaga) {
                                    if (vaga.etapaMatriz.id === etapa.id) {
                                        if (!etapa.turnos) {
                                            etapa.turnos = [];
                                        }

                                        var qtdVagasInscricao = 0,
                                            qtdVagasRematricula = 0;

                                        qtdVagasInscricao = vaga.qtdVagasInscricao || _.result(_.find(vaga.saldos, function (data) {
                                                return data.tipo === 'INSCRICAO';
                                            }), 'qtdVagas');

                                        qtdVagasRematricula = vaga.qtdVagasRematricula || _.result(_.find(vaga.saldos, function (data) {
                                                return data.tipo === 'REMATRICULA';
                                            }), 'qtdVagas');

                                        var turno = {
                                            'turno': vaga.turno,
                                            'qtdVagas': vaga.qtdVagas,
                                            'qtdVagasInscricao': qtdVagasInscricao,
                                            'qtdVagasRematricula': qtdVagasRematricula,
                                            'idVaga': vaga.id,
                                            'saldos': vaga.saldos
                                        };
                                        etapa.turnos.push(turno);
                                    }
                                });
                            }
                        });
                    });
                }
            }

            function carregarEtapaMatrizToEdit(matriz, vaga) {
                var qtdVagasInscricao = 0,
                    qtdVagasRematricula = 0;

                qtdVagasInscricao = vaga.qtdVagasInscricao || _.result(_.find(vaga.saldos, function (data) {
                        return data.tipo === 'INSCRICAO';
                    }), 'qtdVagas');

                qtdVagasRematricula = vaga.qtdVagasRematricula || _.result(_.find(vaga.saldos, function (data) {
                        return data.tipo === 'REMATRICULA';
                    }), 'qtdVagas');

                var turnos = [{
                    'turno': vaga.turno,
                    'qtdVagas': vaga.qtdVagas,
                    'qtdVagasInscricao': qtdVagasInscricao,
                    'qtdVagasRematricula': qtdVagasRematricula,
                    'idVaga': vaga.id,
                    'saldos': vaga.saldos
                }];
                matriz.etapas.push({
                    'id': vaga.etapaMatriz.id,
                    'descricao': vaga.etapaMatriz.descricao,
                    'turnos': turnos
                });
            }

            function _matrizesEstabelecimentos() {
                angular.forEach(vm.vagas.estabelecimentos, function (estabelecimento) {
                    if (!estabelecimento.matrizes) {
                        estabelecimento.matrizes = [{}];
                    }
                });
            }

            function _addMatriz(estabelecimento) {
                if (!estabelecimento.matrizes) {
                    estabelecimento.matrizes = [{}];
                } else {
                    estabelecimento.matrizes.push({});
                }
            }

            function _carregarEtapasMatriz(estabelecimento, matriz) {
                //estabelecimento.matrizes[index].excluirMatriz = true;
                if(!matriz.matriz) {
                    return;
                }
                carregarEtapas(matriz.matriz);
            }

            function _removerMatriz(estabelecimento, matriz, index) {
                if (angular.isDefined(matriz)) {
                    if (vm.mode.editando && vm.mode.tipoEdit !== 'etapa') {
                        matriz.excluirMatriz = true;
                        if (_.has(matriz.matriz, 'etapas')) {
                            angular.forEach(matriz.matriz.etapas, function (etapa) {
                                if (etapa.turnos) {
                                    for (var indexTurno = 0; indexTurno < etapa.turnos.length; indexTurno++) {
                                        vm.removerTurno(etapa, indexTurno);
                                    }
                                }
                            });
                        }
                    } else {
                        estabelecimento.matrizes.splice(index, 1);
                    }
                }
            }

            function _adicionarTurno(etapa) {
                var object = {
                    qtdVagas: null,
                    qtdVagasInscricao: null,
                    qtdVagasRematricula: null
                };
                if (angular.isUndefined(etapa.turnos)) {
                    etapa.turnos = [object];
                } else {
                    etapa.turnos.push(object);
                }
            }

            function _removerTurno(etapa, index) {
                var turnoRemovido = etapa.turnos[index];
                if (angular.isDefined(turnoRemovido) && angular.isDefined(turnoRemovido.idVaga)) {
                    turnoRemovido.excluirVaga = true;
                } else {
                    etapa.turnos.splice(index, 1);
                }
            }

            //Verifica se existe ao menos alguma vaga informada
            function _vagasValid() {
                var estabelecimentoValid = _.every(vm.vagas.estabelecimentos, function (estabelecimento) {
                    return _.has(estabelecimento, 'matrizes') && _.every(estabelecimento.matrizes, function (matriz) {
                            return matriz.excluirMatriz || (_.has(matriz.matriz, 'etapas') && _.some(matriz.matriz.etapas, function (etapa) {
                                    return _.has(etapa, 'turnos') && _.some(etapa.turnos, function (turno) {
                                            return !turno.excluirVaga && turno.qtdVagas > 0;
                                        });
                                }));
                        });
                });
                return estabelecimentoValid;
            }

            function _exibirMatriz(m, matrizes) {
                return !matrizes.some(function (matriz) {
                    if (!matriz) {
                        return false;
                    } else {
                        return m.descricao === matriz.descricao && !matriz.excluirMatriz;
                    }
                });
            }

            function _exibirBtnAddTurnos(etapa) {
                var sizeTurnosEtapa = 0;
                angular.forEach(etapa.turnos, function (turno) {
                    if (!turno.excluirVaga) {
                        sizeTurnosEtapa++;
                    }
                });
                return !etapa.turnos || sizeTurnosEtapa < vm.listTurnos.length;
            }

            function _changeTurno(etapa, turnoEdit) {
                etapa.turnos = _.reject(etapa.turnos, function (turnoEtapa) {
                    if (turnoEtapa.excluirVaga && turnoEtapa.turno === turnoEdit.turno) {
                        turnoEdit.idVaga = turnoEtapa.idVaga;
                        return true;
                    }
                });
            }

            function _filtrarTurnos(value, turnos, turnoInformado) {
                if (!angular.isUndefined(turnoInformado) && value.id === turnoInformado) {
                    return true;
                }
                return !turnos.some(function (turno) {
                    return value.id === turno.turno || (value.id === turno.turno && turno.excluirVaga);
                });
            }

            function _matrizSearch(params) {
                var term = decodeURI(params.term);
                term = term.replace(/%/g, ' ').trim();
                var matrizFilter = _.filter(_.uniq(vm.listMatrizes, 'id'), function (matriz) {
                    return normalize(matriz.descricao.toUpperCase()).indexOf(normalize(term.toUpperCase())) > -1;
                });
                var page = {
                    content: angular.copy(matrizFilter),
                    hasNext: false
                };
                return $q.when(page);
            }

            function normalize(value) {
                return value.toLowerCase()
                    .replace(/ã/g, 'a')
                    .replace(/á/g, 'a')
                    .replace(/â/g, 'a')
                    .replace(/é/g, 'e')
                    .replace(/è/g, 'e')
                    .replace(/ê/g, 'e')
                    .replace(/í/g, 'i')
                    .replace(/ï/g, 'i')
                    .replace(/ì/g, 'i')
                    .replace(/ó/g, 'o')
                    .replace(/ô/g, 'o')
                    .replace(/ú/g, 'u')
                    .replace(/ü/g, 'u')
                    .replace(/ç/g, 'c')
                    .replace(/ß/g, 's')
                    .toUpperCase()
                    .trim();
            }

            function _estabelecimentosSearch(params) {

                var estabelecimentoFilter = vm.estabelecimentos;
                var term = decodeURI(params.term);
                term = term.replace(/%/g, ' ').trim();
                
                estabelecimentoFilter = _.filter(estabelecimentoFilter, function (estabelecimento) {
                    return normalize(estabelecimento.descricao.toUpperCase()).indexOf(normalize(term.toUpperCase())) > -1;
                });

                var page = {
                    content: _.cloneDeep(estabelecimentoFilter),
                    hasNext: false
                };
                return $q.when(page);
            }
        }
    ]);
})();

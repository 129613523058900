(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('subHeaderQuadroInformativo', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/sub-header-quadro-informativo/subheader-quadro-informativo.directive.html',
            scope: {},
            bindToController: {
                anoLetivoSelected: '=',
                subheaderTitle: '=',
                hasReturnIcon: '=',
                returnState: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {}
})();

(function() {
	'use strict';

	angular.module('educacao.common')
		.filter('appPeso', filter);

	function filter() {
		return function(input, decimals) {
			if (_.isNull(input) || _.isUndefined(input) || !_.isFinite(Number(input))) {
				return '0 kg 0g';
			}

			var numDecimals = decimals || 2;

			var DOT_SEPARATOR = '.';

			var pesoSplit = String(input).split(DOT_SEPARATOR);

			var kg = _.first(pesoSplit);
			var decimalValue = _.first(_.rest(pesoSplit)) || '0';

			if (decimalValue.length < numDecimals) {
				var diff = numDecimals - decimalValue.length;
				var diffCharacters = _.repeat('0', diff);
				decimalValue = decimalValue + diffCharacters;
			}

			return kg + ' kg ' + decimalValue + ' g';
		};
	}
})();

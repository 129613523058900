(function() {
    'use strict';

    angular.module('educacao.quadro-funcionarios-configuracao').directive('appTableEtapaEducacaoBasica', AppEtapasDirective);

    function AppEtapasDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/configuracao/app-table-etapas/app-table-etapas-educacao-basica.directive.html',
            scope: {},
            bindToController: {
                configQuadroFuncionario: '=',
                isEditando: '=',
                tracker: '=',
                nivelModalidade: '=',
                nivelEscolar: '='
            },
            controller: Controller,
            controllerAs: 'vm',
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.getEtapaBorder = getEtapaBorder;

        $scope.$watch('vm.configQuadroFuncionario.matrizCurricular.id', init);

        init();

        function init() {
            vm.matrizCurricularId = vm.configQuadroFuncionario.matrizCurricular.id;
            vm.tipoOrganizacao = vm.configQuadroFuncionario.matrizCurricular.tipoOrganizacao;
        }

        function getEtapaBorder(etapa) {
            if(!etapa.pendencias) {
                return '';
            }

            if(etapa.pendencias.pendenciasForm || etapa.pendencias.pendenciasComponenteCurricular) {
                return '2px solid #f9e2e1';
            }

            return '';
        }
    }
})();
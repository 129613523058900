(function() {

    'use strict';

    angular.module('educacao.educacenso')
        .directive('appHeaderPrimeiraEtapaEducacenso', HeaderPrimeiraEtapaEducacenso);

    function HeaderPrimeiraEtapaEducacenso() {
        return {
            restrict: 'E',
            templateUrl: 'educacenso/app-header-primeira-etapa-educacenso/app-header-primeira-etapa-educacenso.html',
            scope: {
                ngModel: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'EDUCACENSO_LAYOUT_ANO_LETIVO',
        'EDUCACENSO_LAYOUT_DATA_DE_REFERENCIA'
    ];

    function Controller($scope, EDUCACENSO_LAYOUT_ANO_LETIVO, EDUCACENSO_LAYOUT_DATA_DE_REFERENCIA) {
        var vm = this;

        vm.informacoesGerais = [
            {
                descricao: 'Ano letivo',
                icone: 'fa-calendar',
                conteudo: EDUCACENSO_LAYOUT_ANO_LETIVO
            },
            {
                descricao: 'Data de referência',
                icone: 'fa-clock-o',
                conteudo: EDUCACENSO_LAYOUT_DATA_DE_REFERENCIA
            },
            {
                descricao: 'Leiaute vigente',
                icone: 'fa-file-text-o',
                conteudo: 'Educacenso ' + EDUCACENSO_LAYOUT_ANO_LETIVO
            }
        ];
    }

})();


(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .controller('educacao.turma-alunos-quadro-informativo.TurmaAlunosQuadroInformativoController', Controller);

    Controller.$inject = [
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        'promiseTracker',
        '$scope',
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.quadro-informativo-utils.CardTotalizadoresQuadroInformativoBuilder',
        '$q',
        '$stateParams',
        '$rootScope'
    ];

    function Controller(QuadroFuncionariosTurmaAlunosService, promiseTracker, $scope, ContextoAnoLetivo, ContextoEstabelecimento, CardTotalizadoresQuadroInformativoBuilder, $q, $stateParams,$rootScope) {
        var vm = this;

        vm.loadingTracker = {
            visaogeral: promiseTracker(),
            totalizadores: promiseTracker(),
            filtroSearch: promiseTracker(),
            estabelecimentoScroll: promiseTracker(),
        };

        vm.SCREEN_NAME = 'Turmas e Alunos';

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.nomeEstabelecimento = ContextoEstabelecimento.getEstabelecimento().descricao;
        
        vm.estabelecimentoSelected = $stateParams.estabelecimentoSelected ? $stateParams.estabelecimentoSelected : null;
        vm.anoLetivoSelected = $stateParams.anoLetivoSelected ? $stateParams.anoLetivoSelected : ContextoAnoLetivo.getAnoLetivo();
        vm.modalidades = null;

        vm.tabs = [];
        vm.cardsEstabelecimento = [];
        vm.cardsSecretariaFirtsRow = [];
        vm.cardsSecretariaSecondRow = [];

        vm.tiposDeResumo = {
            TURMA: 'TURMA',
            ALUNO: 'ALUNO',
            VAGAS: 'VAGAS',
            SALA: 'SALA',
            GERAL: 'GERAL'
        };

        vm.tipoSelecionado = vm.tiposDeResumo.GERAL;


        loadCards();

        function loadCards() {
            clearCards();
            loadCardsTotalizadores().then(function () {
                loadTabs();
            });
            getModalidadesOferecidas();
            addResumoGeral();
        }

        function loadTabs() {
            _.forEach(vm.cardsEstabelecimento, function (card) {
                var existingTab = _.find(vm.tabs, {index: card.order});
                if (!existingTab) {
                    vm.tabs.push({
                        label: card.titulo,
                        badge: card.saldo,
                        tipo: card.tipo,
                        index: card.order
                    });
                }

            });
        }

        function addResumoGeral() {
            var existingTab = _.find(vm.tabs, {index: 1});
            if (!existingTab) {
                vm.tabs.push({
                    label: 'Resumo Geral',
                    tipo: vm.tiposDeResumo.GERAL,
                    index: 1,
                    hiddenBadge: true
                });
            }
        }

        function clearCards() {
            vm.cardsSecretariaFirtsRow = [];
            vm.cardsSecretariaSecondRow = [];
            vm.cardsEstabelecimento = [];
            vm.tabs = [];
        }

        function loadCardsTotalizadores() {
            var promise = $q.all([
                getTotalAlunos(vm.cardsEstabelecimento, false, false, 5),
                getTotalTurmas(vm.cardsEstabelecimento, false, false, 3),
                getTotalSalas(vm.cardsEstabelecimento, false, false, 2),
                getTotalVagasDisponiveis(vm.cardsEstabelecimento, false, false, 4)
            ]);
            return promise;
        }



        $scope.$watch('vm.estabelecimentoSelected', onChangeContextEstabelecimento);
        $scope.$watch('vm.anoLetivoSelected', onChangeContextAnoLetivo);
        $rootScope.$on('app-context:anoletivo:new', clearAnoLetivo);

        function clearAnoLetivo() {
            vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            $stateParams.anoLetivoSelected =ContextoAnoLetivo.getAnoLetivo();
        }


        function onChangeContextEstabelecimento(newValue, oldValue) {
            if (newValue !== oldValue) {
                loadCards();
            }
        }

        function getModalidadesOferecidas() {
            QuadroFuncionariosTurmaAlunosService.getModalidadesOferecidas(resolveParamns()).then(function (data) {
                vm.modalidades = data;
            });

        }

        function onChangeContextAnoLetivo(newValue) {
            if (newValue !== null) {
                loadCards();
            }
            if(newValue === null){
                vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            }
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoLetivoSelected, 'id');
            paramns.estabelecimentoId = _.get(vm.estabelecimentoSelected, 'id');
            return paramns;
        }

        function getTotalAlunos(cards, isBigCard, hasCollapse, order) {
            var promise = QuadroFuncionariosTurmaAlunosService.getTotalAlunos(resolveParamns())
                .then(function (data) {
                    vm.totalAlunos = data.totalAlunos;
                    cards.push(
                        CardTotalizadoresQuadroInformativoBuilder.create()
                            .withTitulo('Alunos')
                            .withSaldo(data.totalAlunos)
                            .withTipo(vm.tiposDeResumo.ALUNO)
                            .withOrder(order)
                            .withCollapse(hasCollapse)
                            .withIsBigCard(isBigCard)
                            .build());
                });
            vm.loadingTracker.totalizadores.addPromise(promise);
            return promise;
        }

        function getTotalSalas(cards, isBigCard, hasCollapse, order) {
            var promise = QuadroFuncionariosTurmaAlunosService.getTotalSalas(resolveParamns())
                .then(function (data) {
                    cards.push(
                        CardTotalizadoresQuadroInformativoBuilder.create()
                            .withTitulo('Salas de aula')
                            .withSaldo(data.totalSalas)
                            .withTipo(vm.tiposDeResumo.SALA)
                            .withOrder(order)
                            .withCollapse(hasCollapse)
                            .withIsBigCard(isBigCard)
                            .build());
                });

            vm.loadingTracker.totalizadores.addPromise(promise);
            return promise;
        }

        function getTotalVagasDisponiveis(cards, isBigCard, hasCollapse, order) {
            var promise = QuadroFuncionariosTurmaAlunosService.getTotalVagasDisponiveis(resolveParamns())
                .then(function (data) {
                    cards.push(
                        CardTotalizadoresQuadroInformativoBuilder.create()
                            .withTitulo('Vagas')
                            .withSaldo(data.totalVagas)
                            .withTipo(vm.tiposDeResumo.VAGAS)
                            .withOrder(order)
                            .withCollapse(hasCollapse)
                            .withIsBigCard(isBigCard)
                            .build());
                });
            vm.loadingTracker.totalizadores.addPromise(promise);
            return promise;
        }

        function getTotalTurmas(cards, isBigCard, hasCollapse, order) {
            var promise = QuadroFuncionariosTurmaAlunosService.getTotalTurmas(resolveParamns())
                .then(function (data) {
                    cards.push(
                        CardTotalizadoresQuadroInformativoBuilder.create()
                            .withTitulo('Turmas')
                            .withSaldo(data.totalTurmas)
                            .withTipo(vm.tiposDeResumo.TURMA)
                            .withOrder(order)
                            .withCollapse(hasCollapse)
                            .withIsBigCard(isBigCard)
                            .build());
                });
            vm.loadingTracker.totalizadores.addPromise(promise);
            return promise;
        }

    }

})();

(function() {
    'use strict';

    angular.module('educacao.context')
        .controller('educacao.common.SelecaoAnoLetivoController', SelecaoAnoLetivoController);

    SelecaoAnoLetivoController.$inject = [
        '$scope',
        'promiseTracker',
        '$state',
        'educacao.context.ContextoAnoLetivo',
        'educacao.common.AnoLetivoService',
        'ui.components.Modal'];

    function SelecaoAnoLetivoController($scope, promiseTracker, $state, ContextoAnoLetivo, AnoLetivoService, uiModal) {
        var vm = this;
        vm.abrirAnoLetivo = abrirAnoLetivo;

        vm.tracker = {
            anosLetivos: promiseTracker()
        };

        vm.atualizarAnoLetivoAtual = atualizarAnoLetivoAtual;

        AnoLetivoService.on('dirty', listaAnosLetivos);

        $scope.$on('$destroy', function() {
            AnoLetivoService.off(listaAnosLetivos);
        });

        listaAnosLetivos();

        function listaAnosLetivos() {
            vm.tracker.anosLetivos.addPromise(
                ContextoAnoLetivo
                .listaAnosLetivos()
                .then(function(data) {
                    vm.anosLetivos = data;
                })
            );
        }

        function atualizarAnoLetivoAtual(anoLetivo) {
            ContextoAnoLetivo.setAnoLetivo(anoLetivo);
            $state.go('selecao');
        }

        function abrirAnoLetivo() {
            uiModal.open({
                templateUrl: 'planejamento/ano-letivo/ano-letivo.cad.html',
                controller: 'educacao.planejamento.AnoLetivoCadCtrl as vm'
            });
        }
    }
})();

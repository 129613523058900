
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.CriterioClassificacaoService', CriterioClassificacaoService);

    CriterioClassificacaoService.$inject = ['ServiceBuilder'];

    function CriterioClassificacaoService(ServiceBuilder) {
        var selfService = ServiceBuilder
            .create()
            .path('matricula', 'criterio-classificacao')
            .build();

        selfService.getCompletos = _getCompletos;

        function _getCompletos(filter, offset, limit, sort){
            return selfService.getEndpoint().all('completos').getList({
                filter: filter,
                offset: offset,
                limit: limit,
                sort: sort
            });
        }

        return selfService;
    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appAccordionMatrizComplementar', appAccordionMatrizComplementar);

    function appAccordionMatrizComplementar() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-accordion-visao-estabelecimento/app-accordion-matriz/app-accordion-matriz-complementar/app-accordion-matriz-complementar.directive.html',
            scope: {},
            bindToController: {
                matrizes: '=',
                estabelecimentoId: '=',
                anoLetivoId: '=',
                estabelecimentoNome: '=',
                turmaFilters: '=',
                turmaSelecionada: '=',
                componenteCurricularFilter: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.ocupacao-aulas-quadro-informativo.AccordionMatrizUtils'
    ];

    function Controller(AccordionMatrizUtils) {
        var vm = this;

        vm.onClickMatriz = onClickMatriz;

        AccordionMatrizUtils.init();

        function onClickMatriz(matriz) {
            AccordionMatrizUtils.onClickMatriz(matriz, vm.estabelecimentoId, vm.turmaFilters, vm.anoLetivoId);
        }
    }
})();
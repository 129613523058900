(function () {
    'use strict';
    angular.module('app')
        .config(edAnexoServiceConfig);
    
    edAnexoServiceConfig.$inject = ['AnexoServiceProvider', 'APP_API_URL'];

    function edAnexoServiceConfig(AnexoServiceProvider, APP_API_URL) {
        AnexoServiceProvider.setUrlBase(APP_API_URL + '/commons/arquivos');
    }
})();

(function() {
    'use strict';

    angular.module('educacao.motivosabonosjustificativas')
    .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider'
    ];

    function stateConfig($stateProvider) {
        var STATE = 'app.motivosabonosjustificativas';
        $stateProvider.state(STATE, {
            url: 'motivo-abono-justificativa',
            data: {
                id: 'MotivoAbonoJustificativaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'motivos-abonos-justificativas/motivos-abonos-justificativas.html',
                    controller: 'educacao.motivosabonosjustificativas.MotivosAbonosJustificativasCtrl as vm'
                }
            }
        });
    }
})();

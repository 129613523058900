(function() {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appSelectTurmasVisaoEstabelecimento', SelectTurmasVisaoEstabelecimento);

    function SelectTurmasVisaoEstabelecimento() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                ngclass: '=',
                estabelecimentoId: '=',
                anoLetivoId: '='
            },
            controller: SelectTurmasVisaoEstabelecimentoController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectTurmasVisaoEstabelecimentoController.$inject = [
        '$scope',
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService'
    ];

    function SelectTurmasVisaoEstabelecimentoController($scope, service) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = [];

            if ($scope.multiple) {
                var turmasId = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        turmasId.push(model.id);
                    });
                }

                if(!_.isEmpty(turmasId)) {
                    filter.push('turma not in(' + turmasId.join() + ')');
                }
            }

            filter.push('tipoAtendimento != "ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO"');

            if(params.term) {
                filter.push('descricaoTurma like "' + params.term + '"');
            }

            var paramsReq = {
                filter: filter.join(' and '),
                offset: params.offset,
                limit: params.limit,
                anoLetivoId: $scope.anoLetivoId
            };

            return service.getTurmasPorEstabelecimento($scope.estabelecimentoId, paramsReq).then(toPage);
        }

        function formatResult(data) {
            return data.descricao;
        }

        function toPage(list) {
            return {
                content: list.content,
                hasMore: list.hasNext
            };
        }
    }
})();
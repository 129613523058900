(function() {
    'use strict';

    angular.module('educacao.matriz')
        .controller('educacao.matriz.EixoTematicoCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        '$rootScope',
        '$q',
        '$modalInstance',
        '$modalTracker',
        'educacao.matriz.EixoTematicoService',
        'bfc.Focus',
        'promiseTracker',
        'bfc.Notification'
    ];

    function Controller(
        $scope,
        $rootScope,
        $q,
        $modalInstance,
        $modalTracker,
        EixoTematicoService,
        focus,
        promiseTracker,
        Notification
    ) {
        var vm = this;
        vm.tracker = {};
        vm.tracker.save = promiseTracker();
        vm.tracker.remove = promiseTracker();
        vm.closeModal = closeModal;
        vm.remove = remove;
        vm.refresh = refresh;
        vm.save = save;

        vm.eixoTematico = $modalInstance.params.eixoTematico;

        vm.editing = Boolean(_.get(vm.eixoTematico, 'id'));
        vm.canSaveAdd = Boolean($modalInstance.params.canSaveAdd);

        init();

        function init() {

            $modalTracker.addPromise(
                $q.all([
                    EixoTematicoService.getTypes().then(function(data) {
                        vm.eixoProperties = data.EixoTematicoDto;
                    }),
                    refresh()
                ])
            );
        }

        function refresh() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }

        function onSuccess(continuar) {
            if (continuar) {
                vm.eixoTematico = {};
                refresh();
                focus('lbDescricao');
            } else {
                closeModal();
            }
        }

        function closeModal() {
            $modalInstance.close(vm.eixoTematico);
        }

        function remove() {
            vm.tracker.remove.addPromise(
                EixoTematicoService.remove(vm.eixoTematico.id).then(function() {
                    $rootScope.$broadcast('refreshEixosTematicos', {
                        data: vm.eixoTematico,
                        remove: true
                    });

                    onSuccess();
                })
            );
        }

        function save(continuar) {

            vm.tracker.save.addPromise(EixoTematicoService.save(vm.eixoTematico)
                .then(function(data) {
                    $rootScope.$broadcast('refreshEixosTematicos', {
                        data: data,
                        add: !vm.editing,
                        edit: vm.editing
                    });
                    vm.eixoTematico = data;
                    onSuccess(continuar);
                    showSucessMessage(data.descricao);
                }));

        }

        function showSucessMessage(descricao) {
            return Notification.publish('Eixo temático ' + descricao + ' salvo com sucesso.', 'success');
        }
    }

})();

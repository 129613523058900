(function () {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.DisciplinaSelect', DisciplinaSelect);
    
    DisciplinaSelect.$inject = ['$q','educacao.common.ModalCad',
            'educacao.common.ObjectUtilsService',
            'educacao.matriz.DisciplinaService',
            'bfc.Select2Config'
        ];

	function  DisciplinaSelect ( $q, ModalCad,
            ObjectUtilsService,
            DisciplinaService,
            Select2Config
		) {

            return {
                select2Config: function (options) {
                    return Select2Config.create(angular.extend({
                        onAdd: function (val, target) {
                            ModalCad.open({
                                templateUrl: 'planejamento/matriz/disciplina/disciplinas.cad.html',
                                controller: 'educacao.matriz.DisciplinasCadCtrl',
                                controllerAs: 'vm',
                                optionsCad: {
                                    'descricao': val
                                },
                                selectElem: target
                            });
                            return false;
                        },
                        result: function (params, callback) {
                            var term = ObjectUtilsService.normalizeFilter(params.term);
                            term = encodeURI(term);
                            
                            var result,
                                filter = '(sigla like "' + term + '" or descricao like "' + term + '")';

                            result = DisciplinaService.getList(params.offset, params.limit, filter, undefined);
                            result.then(function (data) {
                                callback({
                                    results: data,
                                    more: data.hasNext
                                });
                            });

                            return result;
                        },
                        formatValue: function (data) {
                            return data;
                        },
                        formatResult: function (data) {
                            return data.text ?
                                data.text :
                                data.sigla + ' - ' + data.descricao;
                        },
                        formatSelection: function (data) {
                            return data.sigla + ' - ' + data.descricao;
                        },
                        initSelection: function (element, callback) {
                            var id = $(element).val();

                            if (id) {
                                DisciplinaService.get(id).then(callback, function () {
                                    callback({
                                        id: null
                                    });
                                });
                            }
                            
                        }
                    }, options));
                }
            };
		}
})();
(function() {
    'use strict';
    angular.module('educacao.pessoas').directive('appPessoaObservacoes', Directive);

    function Directive() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'planejamento/pessoa/app-pessoa-observacoes/app-pessoa-observacoes.directive.html';
		config.scope = {
			aluno: '=ngModel'
		};
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;
    }
    
    Controller.$inject = ['ui.components.Modal', '$scope'];

    function Controller(uiModal, $scope) {
        $scope.aluno.alunoObservacoes = $scope.aluno.alunoObservacoes || [];
        $scope.openCadatroObs = openCadatroObs;
        $scope.removeObs = removeObs;

        function removeObs(observacao) {
            _.remove($scope.aluno.alunoObservacoes, observacao);
        }

        function openCadatroObs(observacao) {
            uiModal.open({
                templateUrl: 'planejamento/pessoa/app-pessoa-observacoes/cadastro-pessoa-observacoes/cadastro-pessoa-observacoes.html',
                controller: 'educacao.pessoas.CadastroPessoaObservacoesController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    aluno: $scope.aluno,
                    observacao: observacao
                }
            });
        }


    }

})();

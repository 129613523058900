(function() {
    'use strict';

    angular.module('educacao.common')
        .filter('truncateString', filter);

    function filter() {
        return function(input, length, omission) {

            if (!omission) {
                omission = '...';
            }

            var lengthTotal = length + omission.length;

            return _.trunc(input, {
                length: lengthTotal,
                omission: omission
            });
        };
    }

})();


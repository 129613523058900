(function() {
	'use strict';

	angular.module('educacao.pessoas')
		.constant('TIPO_DEPENDENCIA_FISICA', {
			'SALA_AULA': {id: 25, descricao:'Sala de aula',isPadrao:true}
		})
		.controller('educacao.pessoas.DependFisicasCadCtrl', DependFisicasCadCtrl);

	DependFisicasCadCtrl.$inject = ['$injector', 
									'$scope',
									'$rootScope',
									'$modalInstance', 
									'$modalTracker',
									'id', 
									'$q', 
									'readOnly', 
									'optionsCad',
									'estabelecimento',
									'dependencia',
									'TIPO_DEPENDENCIA_FISICA',
									'bfc.Focus'];

	function DependFisicasCadCtrl($injector,
								  $scope,
								  $rootScope, 
								  $modalInstance, 
								  $modalTracker, 
								  id, 
								  $q, 
								  readOnly, 
								  optionsCad, 
								  estabelecimento,
								  dependencia,
								  TIPO_DEPENDENCIA_FISICA,
								  focus) {
		
		var promiseTracker = $injector.get('promiseTracker');
		var CommonSelectEnum = $injector.get('educacao.common.CommonSelectEnum'),
			ParametroService = $injector.get('educacao.common.ParametroService'),
			DependFisicaService = $injector.get('educacao.pessoas.DependFisicaService'),
			TipoDependFisicaSelect = $injector.get('educacao.pessoas.TipoDependenciaSelect'),
			 Notification = $injector.get('bfc.Notification');
		
		var vm = this, _select2 = {}, _pessoasM2;

		vm.tracker = {};
		vm.tracker.save = promiseTracker();
		vm.tracker.saveContinue = promiseTracker();
		vm.tracker.remove = promiseTracker();
		vm.nameEstabelecimento = estabelecimento;
		vm.changeOcupacao = _changeOcupacao;
		vm.changeTipo = _changeTipo;
		vm.tipoDependenciaFisica = TIPO_DEPENDENCIA_FISICA;		
 
		var _modo = {
			 adicionando: !_.isNumber(id) && !readOnly,
			 editando: _.isNumber(id) && !readOnly,
			 visualizando: _.isNumber(id) && readOnly
	   };

		
		var propriedadesPublicas = {
			cancel: _cancel,
			modo: _modo,
			save: _save,
			remove: _remove,
			select2: _select2,
			calculoCapacidadeFisica: _calculoCapacidadeFisica
		};

		init();


		
		
		function init() {
			 $modalTracker.addPromise(
				  $q.all([
						refresh(),
					  	DependFisicaService.getEnums().then(
							function(enums){
								_select2.Ocupacao = CommonSelectEnum.select2Config({values: enums.Ocupacao, description: 'description'});
							}
						),
					  	(function(){
							_select2.TipoDependFisica = TipoDependFisicaSelect.select2Config();
						})(),
					  	ParametroService.get().then(
							function(data){
								_pessoasM2 = angular.isDefined(data) ? data.nroPessoasM2 : null;
							}
						)
				  ]).then(
					function postInit(){
				  		angular.extend(vm, propriedadesPublicas);
						vm.calculoCapacidadeFisica();
					}
				  )
			 );
 	    }

	    function onSuccess(continuar, tracker) {
			 if (continuar) {
				 dependencia = null;
				 tracker.addPromise(refresh());
				 focus('dependencia-descricao');
			 } else {
				_cancel();
			 }
	    } 

		function refresh() {
			
			if (vm.form) {
                vm.form.$setPristine();
            }
            
			var deferred = $q.defer();
			var _data = function(){ 
				deferred.resolve(dependencia);
				
				return deferred.promise;
			};
			
			return !dependencia? 
                DependFisicaService.getDefault().then(function(data) {
					vm.dependenciaFisica = angular.extend(data, optionsCad);
                }):
				_data().then(function(data){
					vm.dependenciaFisica = data;
				});
			
		}

		
		function _calculoCapacidadeFisica(){
			if(!_pessoasM2 || _pessoasM2===0){ 
				vm.capacidadeFisica = null;
				return;
			}
			
			vm.capacidadeFisica = !vm.dependenciaFisica.areaUtil || !_pessoasM2? 0 : Math.trunc(vm.dependenciaFisica.areaUtil/_pessoasM2);
		}
		
		function _save(continuar) {
			
			var errorValidation = false;
			
			if(Array.isArray($scope.validations)){
                _.values($scope.validations).some(
                    function(validation){
                        errorValidation = !validation.isValid;
             
                        if(errorValidation){
							Notification.publish(validation.message, validation.typeMessage);
                        }
                        
                        return errorValidation;
                    }
                );
            }
			
			if(errorValidation){ 
				return;
			}
			
			var tracker = continuar ? vm.tracker.saveContinue : vm.tracker.save;
			tracker.addPromise(
				$q.all([
					$rootScope.$broadcast('refresh::DependenciaFisica', {
						data: vm.dependenciaFisica,
						add: !_.isNumber(id),
						edit: _.isNumber(id),
						id: id
					})
				]).then(onSuccess(continuar, tracker))
			);
			
		}

		function _remove(){
			vm.tracker.remove.addPromise(
				$q.all([
					$rootScope.$broadcast('refresh::DependenciaFisica', {
						data: vm.dependenciaFisica,
						remove: true,
						id: id
					})
				]).then(onSuccess())
			); 
		}
		
		function _cancel() {
			$modalInstance.close();
		}

		function _changeOcupacao() {
			delete(vm.dependenciaFisica.codigoInstituicao);
			delete(vm.dependenciaFisica.descricaoOcupacao);
			delete(vm.dependenciaFisica.nomeInstituicao);
		}

		function _changeTipo() {
			delete(vm.dependenciaFisica.salaUtilizada);
			delete(vm.dependenciaFisica.salaClimatizada);
			delete(vm.dependenciaFisica.nomeInstituicao);
		}
	}
})();
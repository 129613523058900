(function() {
    'use strict';

    angular.module('educacao.matricula')
        .service('educacao.matricula.MatriculaVagaService', service);

    service.$inject = ['ServiceBuilder'];

    function service(ServiceBuilder) {
        var created = ServiceBuilder
            .create()
            .path('matricula', 'matriculas', 'vagas')
            .enum('EspacoDiferenciado', 'OrigemMatricula', 'TipoClassificacaoMatricula', 'TipoReclassificacaoMatricula', 'FormaIngresso', 'SituacaoUltimaMatricula', 'TransferenciaOrigem', 'ResponsavelTransporteEscolar',
                'TipoMatricula', 'VeiculoTransporteEscolar', 'TipoTurmaUnificada')
            .build();

        return created;
    }
})();

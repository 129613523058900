(function() {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.HorasAulasSelect', HorasAulasSelect);

    HorasAulasSelect.$inject = ['$q',
        'educacao.common.ModalCad',
        'educacao.common.ObjectUtilsService',
        'educacao.matricula.HorasAulaService',
        'bfc.Select2Config',
        'bfc.$$PermissionsService'
    ];

    function HorasAulasSelect(
        $q,
        ModalCad,
        ObjectUtilsService,
        HorasAulaService,
        Select2Config,
        PermissionsService
    ) {
        var onAdd = PermissionsService.isRevokedOperation('ConfiguracaoHorasAulasPageMapping', 'criar') ? undefined : add;
        return {
            select2Config: function(options) {
                return Select2Config.create(angular.extend({
                    onAdd: onAdd,
                    result: function(params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result,
                            filter = '(descricao like "' + term + '")';


                        result = HorasAulaService.list(filter, params.offset, params.limit, null)
                            .then(function(data) {
                                callback({
                                    results: data.content,
                                    more: data.hasNext
                                });
                            });

                        return result;
                    },
                    formatValue: function(data) {

                        return data;
                    },
                    formatResult: function(data) {
                        return data.text ?
                            data.text :
                            data.descricao + ' (Carga horária semanal: ' + data.cargaHorariaSemanal +')';
                    },
                    formatSelection: function(data) {
                        return data.descricao + ' (Carga horária semanal: ' + data.cargaHorariaSemanal + ')';
                    },
                    initSelection: function(element, callback) {
                        var id = $(element).val();
                        if (id) {
                            HorasAulaService.get(id).then(callback, function() {
                                callback({
                                    id: null
                                });
                            });
                        }
                    }
                }, options));
            }
        };

        function add(val, target) {
            ModalCad.open({
                templateUrl: 'matricula/horas-aula/horas-aulas.cad.html',
                controller: 'educacao.matricula.HorasAulasCadCtrl as vm',
                optionsCad: {
                    'descricao': val
                },
                selectElem: target
            });
            return false;
        }
    }
})();
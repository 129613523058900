(function() {
    'use strict';

    angular.module('educacao.desempenhoescolar', [
        'ui-components',
        'bfc',
        'educacao.common',
        'parecer-desempenho',
        'cha-desempenho',
        'objetivo-aprendizagem-desempenho'
    ]);
})();


(function() {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.CalendarioSecretariaFeriadoModalController', FeriadoModalController);

    FeriadoModalController.$inject = [
        '$scope',
        'educacao.calendario.CalendarioSecretariaFeriadoService',
        'feriado',
        'fnCad',
        'educacao.calendario.RegistroVinculadoService',
        'educacao.context.ContextoAnoLetivo'
    ];

    function FeriadoModalController($scope, calendarioSecretariaFeriadoService, feriado, fnCad, RegistroVinculadoService, ContextoAnoLetivo) {
        var vm = this;

        vm.feriado = feriado && angular.copy(feriado);

        vm.save = save;
        vm.saveAndCreateNew = saveAndCreateNew;
        vm.remove = remove;
        vm.openModalRegistroVinculado = openModalRegistroVinculado;
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;

        init();

        $scope.$watch('vm.feriado.feriado', function() {
            if(vm.feriado && vm.feriado.feriado) {
                vm.anoFeriadoSelecionado = moment(vm.feriado.feriado.dataFeriado).format('YYYY');
                vm.anoFeriadoSelecionadoMesmoAnoCalendario = vm.anoFeriadoSelecionado === vm.anoLetivo;
            }
        });

        function init() {
            if (!feriado) {
                fillDefaultRepresentation();
            }

            $scope.$watch('vm.feriado.letivo', function(newValue) {
                if (newValue) {
                    vm.feriado.trabalhado = true;
                }
            });
        }

        function fillDefaultRepresentation() {
            calendarioSecretariaFeriadoService
                .getDefaultRepresentation()
                .then(function(defaultRepresentation) {
                    vm.feriado = defaultRepresentation;
                });
        }

        function saveAndCreateNew() {
            fnCad(vm.feriado, true)
                .then(fillDefaultRepresentation)
                .then($scope.form.$setPristine);
        }

        function save() {
            fnCad(vm.feriado, true).then($scope.$modalInstance.close);
        }

        function remove() {
            fnCad(vm.feriado, false).then($scope.$modalInstance.close);
        }

        function openModalRegistroVinculado() {
            RegistroVinculadoService.openModal();
        }
    }
})();


(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('menuQuadroInformativo', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/menu-quadro-informativo/menu-quadro-informativo.directive.html',
            scope: {},
            bindToController: {
                menu: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
                estabelecimentos: '='
            },
            controller: Controller,
            controllerAs: 'vm',
        };
    }

    Controller.$inject = ['educacao.context.ContextoEstabelecimento', '$scope', 'bfc.$$PermissionsService'];

    function Controller(ContextoEstabelecimento, $scope, PermissionsService) {
        var vm = this;
        vm.openNav = openNav;
        vm.closeNav = closeNav;
        vm.selectMenu = selectMenu;

        vm.estabelecimentoFilter = null;

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.hasPermissionVisaoEstabelecimento = hasPermissionVisaoEstabelecimento;

        vm.OCUPACAO_AULAS = 'Ocupação das aulas';

        vm.menuOptions = [
            { 
                label: 'Visão geral', 
                state: 'app.planejamento.visao-geral-quadro-informativo' + getSufixContext(), 
                permissionState: 'app.planejamento.visao-geral-quadro-informativo' , 
                permissionOperation: 'visaoGeral'
            },
            // {
            //     label: vm.OCUPACAO_AULAS,
            //     state: getOcupacaoAulasState(),
            //     permissionState: getOcupacaoAulasPermissionState(),
            //     permissionOperation: getOcupacaoAulasPermissionOperation()
            // },
            {
                label: 'Turmas e Alunos', 
                state: 'app.planejamento.turma-alunos-quadro-informativo' + getSufixContext(),
                permissionState: 'app.planejamento.turma-alunos-quadro-informativo' , 
                permissionOperation: 'turmasAlunos'
            },
            {
                label: 'Gestão de pessoas', 
                state: 'app.planejamento.gestao-pessoas-quadro-informativo' + getSufixContext(),
                permissionState: 'app.planejamento.gestao-pessoas-quadro-informativo' , 
                permissionOperation: 'gestaoPessoas'
            },
        ];

        $scope.$watch('vm.estabelecimentoFilter', onChangeFilter);

        function selectMenu(option) {
            vm.menu = option.label;
        }

        function onChangeFilter(filter) {
            if (!filter) {
                return;
            }

            vm.estabelecimentoSelected = {
                id: filter.id,
                descricao: filter.descricao,
                brasao: filter.brasao
            };

            vm.estabelecimentoFilter = null;
        }

        // function getOcupacaoAulasState() {
        //     if (vm.isSecretaria) {
        //         return 'app.planejamento.ocupacao-aulas-quadro-informativo' + getSufixContext();
        //     }
        //
        //     return 'app.planejamento.ocupacao-aulas-quadro-informativo-visao-estabelecimento({ estabelecimentoId: vm.estabelecimento.id, estabelecimentoSelected: vm.estabelecimentoSelected, anoLetivoSelected: vm.anoLetivoSelected  })';
        // }
        //
        // function getOcupacaoAulasPermissionState() {
        //     if (vm.isSecretaria) {
        //         return 'app.planejamento.ocupacao-aulas-quadro-informativo';
        //     }
        //
        //     return 'app.planejamento.ocupacao-aulas-quadro-informativo-visao-estabelecimento';
        // }
        //
        // function getOcupacaoAulasPermissionOperation() {
        //     if (vm.isSecretaria) {
        //         return 'ocupacaoAulas';
        //     }
        //
        //     return 'visaoEstabelecimento';
        // }

        function getSufixContext() {
            return '({ estabelecimentoSelected: vm.estabelecimentoSelected, anoLetivoSelected: vm.anoLetivoSelected })';
        }

        function openNav() {
            document.getElementById('sidenav').style.width = '250px';
            document.getElementById('main').style.marginLeft = '250px';
        }
    
        function closeNav() {
            document.getElementById('sidenav').style.width = '0';
            document.getElementById('main').style.marginLeft = '0';
        }

        function hasPermissionVisaoEstabelecimento() {
            return !PermissionsService.isRevokedOperation('QuadroInformativoPageMapping', 'visaoEstabelecimento');
        }
    }
})();

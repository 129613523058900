 (function() {
     'use strict';

     angular.module('app')
         .run(createFullCalendarPtBrLocale);

     //Customização dos textos usados pelo fullcalendar.js
     function createFullCalendarPtBrLocale() {
         $.fullCalendar.lang('pt-br', {
             buttonText: {
                 month: 'Mês',
                 week: 'Semana',
                 day: 'Dia',
                 list: 'Compromissos'
             },
             allDayText: 'Dia inteiro',
             eventLimitText: function(e) {
                 return 'mais +' + e;
             }
         });
     }
 })();

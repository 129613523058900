(function () {
    'use strict';

    var GENERATED_ID = '$$generatedId';

    angular.module('educacao.calendario')
        .service('IdGenerator', IdGenerator);

    IdGenerator.$inject = [];

    function IdGenerator() {

        return {
            hasGeneratedId: hasGeneratedId,
            getGenerateId: getGenerateId,
            generateId: generateId,
            removeGeneratedId: removeGeneratedId,
            removeId: removeId,
            generateAllId: generateAllId, //TODO: Rever nome

            equals: equals,
            getIndex: getIndex,
            get: get,
            addOrUpdate: addOrUpdate,
            remove: remove
        };

        function hasGeneratedId(obj) {
            return Boolean(obj[GENERATED_ID]);
        }

        function getGenerateId(obj) {
            return generateId(obj)[GENERATED_ID];
        }

        function generateId(obj) {
            _.set(obj, GENERATED_ID, obj[GENERATED_ID] || obj.id || _.uniqueId('gen_'));
            return obj;
        }

        function removeGeneratedId(obj) {
            delete obj[GENERATED_ID];
            return obj;
        }

        function removeId(obj) {
            delete obj.id;
            return removeGeneratedId(obj);
        }

        function generateAllId(obj) {
            if (obj === null) {
                return null;
            }
            if (!angular.isObject(obj)) {
                return obj;
            }

            angular.forEach(obj, function (value) {
                if (angular.isArray(value)) {
                    value.forEach(generateAllId);
                } else if (angular.isObject(value)) {
                    generateAllId(value);
                }
            });

            return generateId(obj);
        }

        function equals(obj1, obj2) {
            generateId(obj1);
            generateId(obj2);

            return obj1[GENERATED_ID] === obj2[GENERATED_ID];
        }

        function getIndex(obj, list) {
            generateId(obj);
            if (!list) {
                return 0;
            }

            var indexFound = list.length;

            list.forEach(function (value, index) {
                if (equals(value, obj)) {
                    indexFound = index;

                    return false;
                }
            });
            return indexFound;
        }

        function get(obj, list) {
            return list[getIndex(obj, list)];
        }

        function addOrUpdate(obj, list) {
            var index = getIndex(obj, list);

            list[index] = obj;

            return index;
        }

        function remove(obj, list) {
            list.splice(getIndex(obj, list), 1);
        }
    }
})();

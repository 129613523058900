(function() {
    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.ControllerTemplate', ControllerTemplate);

    ControllerTemplate.$inject = ['mensagem'];

    function ControllerTemplate(mensagem) {
        var vm = this;

        vm.mensagem = mensagem;
    }
})();

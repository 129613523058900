(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.TableBuilder', TableBuilder);

    TableBuilder.$inject = ['$log'];

    function TableBuilder($log) {

        var instanceTableBuilder = this;

        instanceTableBuilder.create = create;

        instanceTableBuilder.header = header;

        instanceTableBuilder.values = values;

        instanceTableBuilder.body = body;

        instanceTableBuilder.build = build;

        function create() {
            $log.warn('educacao.common.TableBuilder está depreciado e sera removido em breve, por favor use o ui-list-grid.');
            return instanceTableBuilder;
        }

        function header(headerValue) {
            instanceTableBuilder.attributeHeader = headerValue;
            return instanceTableBuilder;
        }

        function values(list) {
            instanceTableBuilder.list = list;
            return instanceTableBuilder;
        }

        function body(attributeBody) {
            instanceTableBuilder.attributeBody = attributeBody;
            return instanceTableBuilder;
        }

        function buildHeader() {
            return _.chain(instanceTableBuilder.list)
                .map(function(item) {
                    return item[instanceTableBuilder.attributeHeader];
                })
                .unique()
                .value();
        }

        function buildBody() {
            var bodyValue = _.groupBy(instanceTableBuilder.list, instanceTableBuilder.attributeBody),
                sizeHeaderList = instanceTableBuilder.headerList.length;

            angular.forEach(bodyValue, function(row, key) {
                if (row.length <= sizeHeaderList) {
                    var newList = _.range(sizeHeaderList);

                    bodyValue[key] = newList.map(function(i) {

                        var itemExistente = _.find(row, function(item) {
                            return item[instanceTableBuilder.attributeHeader] === instanceTableBuilder.headerList[i];
                        });

                        var result;

                        if (!itemExistente) {

                            result = {};
                            result.isNull = true;
                            result[instanceTableBuilder.attributeHeader] = instanceTableBuilder.headerList[i];

                            return result;

                        }

                        return itemExistente;
                    });

                }
            });

            return bodyValue;
        }

        function isValidToExecuteBuild() {

            if (!instanceTableBuilder.attributeHeader) {
                throw new Error('header undefined!');
            }
            if (!instanceTableBuilder.attributeBody) {
                throw new Error('body undefined!');
            }
            if (!instanceTableBuilder.list) {
                throw new Error('values undefined!');
            }

        }

        function build() {

            isValidToExecuteBuild();

            instanceTableBuilder.headerList = buildHeader();
            instanceTableBuilder.bodyList = buildBody();

            return {
                header: instanceTableBuilder.headerList,
                body: instanceTableBuilder.bodyList
            };
        }

        return instanceTableBuilder;
    }
})();

(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.UnidadesTematicaCadCtrl', UnidadesTematicaCadCtrl);

	UnidadesTematicaCadCtrl.$inject = ['bfc.Notification', 'educacao.matriz.UnidadeTematicaService', '$modalInstance'];

	function UnidadesTematicaCadCtrl(Notification, UnidadeTematicaService, $modalInstance) {
		var vm = this;

		vm.remove = remove;
		vm.save = save;

		init();

		function init() {
			vm.unidadeTematica = $modalInstance.params.unidadeTematica || {};
			vm.editando = _.get(vm.unidadeTematica, 'id');
			vm.canSaveAdd = $modalInstance.params.canSaveAdd;

			vm.permissions = {
				save: {
					bfPermissions: 'app.matriz.unidadestematicas',
					operation: 'criar'
				},
				update: {
					bfPermissions: 'app.matriz.unidadestematicas',
					operation: 'editar'
				},
				remove: {
					bfPermissions: 'app.matriz.unidadestematicas',
					operation: 'excluir'
				}
			};
		}

		function save(continuar) {
			return UnidadeTematicaService.save(vm.unidadeTematica).then(function(data) {
				Notification.publish('Unidade temática salva com sucesso', 'success');
				if (continuar) {
					vm.unidadeTematica = {};
					vm.form.$setUntouched();
				} else {
					$modalInstance.close(data);
				}
			});
		}

		function remove() {
			return UnidadeTematicaService.remove(vm.unidadeTematica).then(function() {
				$modalInstance.close();
			});
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.CalendarioEventoModalController', CalendarioEventoModalController);

    CalendarioEventoModalController.$inject = [
        '$scope',
        '$q',
        'promiseTracker',
        'joinTime',
        'educacao.context.ContextoEstabelecimento',
        'bfc.dialog.Dialog',
        'educacao.calendario.EventoService',
        'educacao.calendario.CalendarioEventoService',
        'educacao.pessoas.EstabelecimentoServiceDeprecated',
        'educacao.matriz.MatrizService',
        'educacao.calendario.RegistroVinculadoService',
        '$modalInstance'
    ];

    function CalendarioEventoModalController(
        $scope,
        $q,
        promiseTracker,
        joinTime,
        ContextoEstabelecimento,
        bfcDialog,
        EventoService,
        calendarioEventoService,
        estabelecimentoService,
        matrizService,
        RegistroVinculadoService,
        $modalInstance
    ) {

        var vm = this;

        vm.evento = $modalInstance.params.resolve.evento && _.cloneDeep($modalInstance.params.resolve.evento);
        vm.coresEvento = [];

        vm.trackers = {
            evento: promiseTracker(),
            audience: promiseTracker(),
            colors: promiseTracker()
        };

        vm.onDiaInteiroChanged = onDiaInteiroChanged;
        vm.searchEvento = searchEvento;
        vm.addEvento = addEvento;

        vm.isPublicoAlvoChecked = isPublicoAlvoChecked;
        vm.onPublicoAlvoChanged = onPublicoAlvoChanged;

        vm.isEstabelecimento = function(){return !ContextoEstabelecimento.isSecretaria();};
        vm.searchEstabelecimento = searchEstabelecimento;
        vm.searchMatrizCurricular = searchMatrizCurricular;

        vm.isEditing = isEditing;

        vm.save = save;
        vm.remove = remove;
        vm.saveAndCreateNew = saveAndCreateNew;
        vm.openModalRegistroVinculado = openModalRegistroVinculado;

        
        vm.CadEventoModal = $modalInstance.params.fn.CadEventoModal;

        init();

        function init() {
            if (!vm.evento) {
                fillDefaultRepresentation();
            }

            vm.trackers.audience.addPromise(
                calendarioEventoService.getPublicoAlvo()
                    .then(function (publicoAlvo) {
                        vm.publicoAlvo = publicoAlvo;
                    })
            );

            vm.trackers.colors.addPromise(
                calendarioEventoService.getCoresEvento()
                    .then(function (coresEvento) {
                        vm.coresEvento = coresEvento;
                    })
            );

            joinTime
                .create()
                .scope($scope)
                .expr1('dataInicialEvento')
                .format1('YYYY-MM-DD')
                .expr2('horaInicialEvento')
                .format2('HH:mm')
                .destExpr('vm.evento.dataInicial')
                .destFormat('YYYY-MM-DDTHH:mm:ss.SSS')
                .build();

            joinTime
                .create()
                .scope($scope)
                .expr1('dataFinalEvento')
                .format1('YYYY-MM-DD')
                .expr2('horaFinalEvento')
                .format2('HH:mm')
                .destExpr('vm.evento.dataFinal')
                .destFormat('YYYY-MM-DDTHH:mm:ss.SSS')
                .build();

            $scope.$watch('vm.evento.letivo', function (value) {
                if (value) {
                    vm.evento.trabalhado = true;
                }
            });

            $scope.$watch('vm.evento.obrigatorio', function (value) {
                if (vm.evento && !value) {
                    vm.evento.obrigatorioNestaData = false;
                }
            });

            $scope.$watch('vm.evento.obrigatorioNestaData', function (value) {
                if (value) {
                    vm.evento.obrigatorio = true;
                }
            });
        }

        function fillDefaultRepresentation() {
            return calendarioEventoService
                .getDefaultRepresentation()
                .then(function (defaultRepresentation) {
                    vm.evento = defaultRepresentation;
                });
        }

        function onDiaInteiroChanged() {
            var newValue = vm.evento.diaInteiro ? '00:00' : '';

            $scope.horaInicialEvento = newValue;
            $scope.horaFinalEvento = newValue;
        }

        function addEvento(val, target) {
            var defer = $q.defer();

            bfcDialog.open({
                templateUrl: 'calendario/evento/evento.cad.html',
                controller: 'educacao.calendario.EventoCadCtrl',
                resolve: {
                    fnCad: $q.when(defer.resolve),
                    id: null,
                    readOnly: false,
                    optionsCad: {
                        'descricao': val
                    },
                    selectElem: target
                }
            });
            return defer.promise;
        }

        function searchEvento(params) {
            return EventoService.getList(params.offset, params.limit, '(descricao like "' + params.term + '")')
                .then(function (data) {
                    return {
                        content: data,
                        hasMore: false
                    };
                });
        }

        function isPublicoAlvoChecked(publicoAlvo) {
            if (!vm.evento.publicoAlvo) {
                return false;
            }
            return vm.evento.publicoAlvo.indexOf(publicoAlvo.key) !== -1;
        }

        function onPublicoAlvoChanged(publicoAlvo) {
            if (!vm.evento.publicoAlvo) {
                vm.evento.publicoAlvo = [];
            }

            var publicoAlvoKey = publicoAlvo.key;

            var index = vm.evento.publicoAlvo.indexOf(publicoAlvoKey);

            if (index === -1) {
                vm.evento.publicoAlvo.push(publicoAlvoKey);
            } else {
                vm.evento.publicoAlvo.splice(index, 1);
            }
        }

        function searchEstabelecimento(offset, term) {
            return estabelecimentoService.getList(offset, undefined, '(nome like "' + term + '")');
        }

        function searchMatrizCurricular(offset, term) {
            var filter = '(descricao like "' + term + '"' + 
            ' and ' + matrizService.getFilterModular() + ')';

            return matrizService.findTodos(offset, undefined, filter);
        }

        function isEditing() {
            return vm.evento && (vm.evento.id || vm.evento.$$generatedId);
        }

        function remove() {
            vm.CadEventoModal(vm.evento, false)
                .then(function () {
                    $scope.$modalInstance.close();
                });
        }

        function saveAndCreateNew() {
            var promise = vm.CadEventoModal(vm.evento, true)
            .then(function(){
                fillDefaultRepresentation().then(function(){
                    $scope.form.$setPristine();
                    $scope.form.$setUntouched();

                    $('#lbEvento input').focus();
                });
            });

            vm.trackers.evento.addPromise(promise);

            return promise;
        }

        function save() {
            saveAndCreateNew()
                .then(function () {
                    $scope.$modalInstance.close();
                });
        }

        function openModalRegistroVinculado() {
            RegistroVinculadoService.openModal();
        }
    }
})();

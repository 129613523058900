(function() {

    'use strict';

    var NAO_OFERECE = 'NAO_OFERECE';
    var DESCRITIVO = 'DESCRITIVO';

    angular.module('configuracao-avaliacao-turma')
        .directive('appSelectModoAvaliacao', appSelectModoAvaliacao);

    function appSelectModoAvaliacao() {
        return {
            restrict: 'E',
            template: ' <app-select-enum ng-model="ngModel" enum-name="ModoAvaliacao" ng-disabled="ngDisabled" filter-result="vm.filterResult(term, value, column)" dropdown-css-class="dropdownCssClass"></app-select-enum>',
            scope: {
                configuracaoAvaliacao: '=',
                itemAvaliavel: '=',
                ngModel: '=',
                dropdownCssClass: '=?',
                ngDisabled: '=?'
            },
            require: 'ngModel',
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'ITEM_AVALIAVEL_TIPO_PERIODO'
    ];

    function Controller($scope, ITEM_AVALIAVEL_TIPO_PERIODO) {

        var vm = this;

        vm.filterResult = filterResult;

        function filterResult(term, value) {
            if (value.key === NAO_OFERECE && !hasNaoOferece($scope.itemAvaliavel)) {
                return false;
            }

            if (value.description.toLowerCase().contains(term.toLowerCase().trim())) {
                return value.key === DESCRITIVO || value.key === NAO_OFERECE ||
                    _.contains($scope.configuracaoAvaliacao.tiposAvaliacoes, value.key);
            }
        }

        function hasNaoOferece(item) {
            return item && item.tipoPeriodo !== ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO &&
                item.tipoPeriodo !== ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL;
        }
    }

})();

(function() {

	'use strict';

	angular.module('educacao.matricula').factory('educacao.matricula.EnturmacaoProfessorService', EnturmacaoProfessorService);

	EnturmacaoProfessorService.$inject = ['Restangular'];

	function EnturmacaoProfessorService(Restangular) {

		var enturmacaoEndPoint = Restangular.all('enturmacao-funcionario');

		return {
			listByParams: listByParams,
			desenturmar: desenturmar,
			remover: remover,
			desenturmarByFuncionarios: desenturmarByFuncionarios,
			enturmar: enturmar,
			getAtividade: getAtividade,
			getComponentes: getComponentes,
			getDisciplina: getDisciplina,
			getDivisao: getDivisao,
			getDivisaoCampo: getDivisaoCampo,
			getDivisaoEixo: getDivisaoEixo,
			getEnturmacao: getEnturmacao,
			getListaEixoTematico: getListaEixoTematico,
			getListaCampoExperiencia: getListaCampoExperiencia,
			getListaEnturmacao: getListaEnturmacao,
			getListaEnturmacaoAtividades: getListaEnturmacaoAtividades,
			getListaEnturmacaoPorTurmas: getListaEnturmacaoPorTurmas,
			getTurmas: getTurmas,
			removeProfessoGradeHorario: removeProfessoGradeHorario,
			setEnturmacao: setEnturmacao,
			setRegentePrincipal: setRegentePrincipal
		};

		function listByParams(params) {
            return enturmacaoEndPoint.get('', params);
		}

		function removeProfessoGradeHorario(enturmacao) {
			return Restangular
				.all('quadros-horarios')
				.one('remover-quadro-enturmacao', enturmacao.id).customDELETE();
		}

		function getTurmas(params) {
			return enturmacaoEndPoint.all('turmas-enturmacoes-funcionario').get('', params);
		}

		function getComponentes(params) {
			return enturmacaoEndPoint.all('itens-turma-enturmacoes-funcionario').get('', params);
		}

		function desenturmarByFuncionarios(enturmacao, param) {
			return enturmacaoEndPoint.one('desenturmar-multiplos', enturmacao.id).customPUT(param, '');
		}

		function enturmar(param) {
			return enturmacaoEndPoint.customPOST(param);
		}

		function desenturmar(enturmacao, param) {
			return enturmacaoEndPoint.all(enturmacao.id + '/desenturmar').customPUT(param, '');
		}

		function remover(enturmacao) {
			return enturmacaoEndPoint.customDELETE(enturmacao.id);
		}

		function getEnturmacao(id) {
			return enturmacaoEndPoint.one(id).get();
		}

		function setEnturmacao(param, tipoHistorico) {
			return enturmacaoEndPoint.all(param.id).one(tipoHistorico).customPUT(param, '');
		}

		function setRegentePrincipal(regente) {
			return enturmacaoEndPoint.one('regente-principal', regente.id).customPUT(null, '');
		}

		function getDivisao(idTurma, idDisciplina) {
			return Restangular.all('matricula/turma/divisoes-disciplinas')
				.get('', {
					idTurma: idTurma,
					idDisciplina: idDisciplina
				});
		}

		function getDivisaoEixo(idTurma, idEixoTematico) {
			return Restangular.all('matricula/turma/divisoes-eixos-tematicos')
				.get('', {
					idTurma: idTurma,
					idEixoTematico: idEixoTematico
				});
		}

		function getDivisaoCampo(idTurma, idCampoExperiencia) {
			return Restangular.all('matricula/turma/divisoes-campos-experiencias')
				.get('', {
					idTurma: idTurma,
					idCampoExperiencia: idCampoExperiencia
				});
		}

		function getDisciplina(params) {
			return Restangular.all('matriz')
				.one('matriz-curricular', params.matriz)
				.one('etapas', params.etapas)
				.all('disciplinas')
				.get('', params);
		}

		function getAtividade(params) {
			return Restangular.all('matricula/turma/atividades')
				.get('', params);
		}

		function getListaEnturmacao(params) {
			return enturmacaoEndPoint.all('enturmaveis-disciplinas').get('', params);
		}

		function getListaEixoTematico(params) {
			return enturmacaoEndPoint.all('enturmaveis-eixos-tematicos').get('', params);
		}
		
		function getListaCampoExperiencia(params) {
			return enturmacaoEndPoint.all('enturmaveis-campo-experiencia').get('', params);
		}

		function getListaEnturmacaoAtividades(params) {
			return enturmacaoEndPoint.all('enturmaveis-atividades').get('', params);
		}

		function getListaEnturmacaoPorTurmas(params) {
			return enturmacaoEndPoint.all('enturmaveis-por-turmas').get('', params);
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.historico').directive('appHistoricoBadge', HistoricoBadge);

    HistoricoBadge.$inject = ['educacao.historico.HistoricoService'];
    function HistoricoBadge(HistoricoService) {
        return {
            restrict: 'E',
            templateUrl: 'historico/app-historico-badge.directive.html',
            scope: {
                historico: '=ngModel'
            },
            link: function ($scope) {
                $scope.$watch('historico', function (historico) {

                    var handler = HistoricoService.getTipoHandler(historico.tipo);

                    var badge = handler.badge || {};

                    $scope.icon = badge.icon || 'fa-user';
                    $scope.tooltip = badge.tooltip || '';
                });
            }
        };
    }
})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('edEtapasSelect', edEtapasSelect);

    edEtapasSelect.$inject = [];

    function edEtapasSelect() {

        var config = {};
        // filter-result="vm.filterResult(term, value)"
        config.template = '<ui-select ng-model="ngModel" not-empty="ngRequired" search="vm.search" ng-required="ngRequired" multiple ></ui-select>';
        config.restrict = 'E';
        config.require = 'ngModel';

        config.scope = {
            ngModel: '=ngModel',
            enum: '=',
            ngRequired: '=ngRequired',
            maxNivelEtapa: '='
        };

        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;
    }

    Controller.$inject = ['$q', '$scope', 'educacao.common.FilterEncodedUtilsService'];

    function Controller($q, $scope, FilterEncodedUtilsService) {
        var vm = this;
        vm.search = search;

        function search(params) {
            var listaEtapas = $scope.ngModel,
                todas = false,
                retirarEnumTodas = false,
                term, page, enumEtapaFilter = [];
            _.forEach(listaEtapas, function(etapa) {
                if (etapa.id === 'TODOS') {
                    todas = true;
                    return;
                }
                retirarEnumTodas = true;
                return;
            });

            if (todas) {
                page = {
                    content: [],
                    hasNext: false
                };
                return $q.when(page);
            }

            angular.copy(enumFormatado, enumEtapaFilter);

            if (retirarEnumTodas) {
                enumEtapaFilter = _.filter(enumEtapaFilter, function(etapa) {
                    return etapa.id !== 'TODOS';
                });
            }

            term = FilterEncodedUtilsService.getDecodedTerm(params.term);
            
            enumEtapaFilter = _.filter(enumEtapaFilter, function(etapa) {
                return etapa.descricao.toUpperCase().indexOf(term.toUpperCase()) > -1;
            });

            if ($scope.maxNivelEtapa) {
                enumEtapaFilter = _.filter(enumEtapaFilter, function(etapa) {
                    return etapa.value <= $scope.maxNivelEtapa;
                });
            }

            page = {
                content: enumEtapaFilter,
                hasNext: false
            };
            return $q.when(page);
        }



        var enumFormatado = [{
            id: 'TODOS',
            descricao: 'Aplicar todas',
            value: 0
        }, {
            id: 'ETAPA_1',
            descricao: '1° ano',
            value: 1
        }, {
            id: 'ETAPA_2',
            descricao: '2° ano',
            value: 2
        }, {
            id: 'ETAPA_3',
            descricao: '3° ano',
            value: 3
        }, {
            id: 'ETAPA_4',
            descricao: '4° ano',
            value: 4
        }, {
            id: 'ETAPA_5',
            descricao: '5° ano',
            value: 5
        }, {
            id: 'ETAPA_6',
            descricao: '6° ano',
            value: 6
        }, {
            id: 'ETAPA_7',
            descricao: '7° ano',
            value: 7
        }, {
            id: 'ETAPA_8',
            descricao: '8° ano',
            value: 8
        }, {
            id: 'ETAPA_9',
            descricao: '9° ano',
            value: 9
        }];
    }
})();

(function () {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.FuncionarioCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        '$q',
        'promiseTracker',
        '$modalInstance',
        'educacao.funcionario.FuncionarioService',
        'bfc.Notification',
        'educacao.pessoas.PessoaService',
        'educacao.matricula.EnturmacaoProfessorService',
        'educacao.common.ModalConfirm',
        'AuthenticationContext',
        'UserAccessContext',
        'bfcApplicationContext',
        '$window'
    ];

    function Controller(
        $scope,
        $q,
        promiseTracker,
        $modalInstance,
        FuncionarioService,
        Notification,
        PessoaService,
        EnturmacaoProfessorService,
        modalConfirm,
        AuthenticationContext,
        UserAccessContext,
        ApplicationContext,
        $window
    ) {

        var vm = this;
        vm.funcionario = $modalInstance.params.funcionario || {};
        vm.disableExcluir = $modalInstance.params.disableExcluir || false;
        vm.listaDesenturmar = [];
        vm.save = save;
        vm.remove = remove;

        vm.url = 'user_access=' + UserAccessContext.getUserAccess().licenseId +
            '&app_context=' + toBase64(ApplicationContext.getList()) +
            '&access_token=' + AuthenticationContext.getAccessToken();

        vm.tracker = {
            loading: promiseTracker(),
            save: promiseTracker(),
            saveAndContinue: promiseTracker(),
            remove: promiseTracker(),
            camposAdicionais: promiseTracker()
        };

        vm.searchMethodPessoa = FuncionarioService.getPessoas;
        vm.onSelectPessoa = onSelectPessoa;

        vm.enumEstadoCivil = FuncionarioService.getEstadoCivil();
        vm.enumSexo = FuncionarioService.getSexo();

        vm.filterResult = filterResult;

        vm.modo = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.funcionario.id
        };

        $scope.$watchCollection('vm.funcionario.enderecos', removeCepInvalidChars);

        init();
        initCamposAdicionais();

        function init() {
            $modalInstance.tracker.addPromise(
                $q.when(
                    build()
                ).then(
                    vm.windowTitle = vm.modo.adicionando ? 'Adicionando funcionário' : 'Editando funcionário'
                )
            );
        }

        function toBase64(context) {
            return $window.btoa(JSON.stringify(context));
        }

        function initCamposAdicionais() {
            var funcionarioId = _.get(vm.funcionario, 'id');

            var promise = FuncionarioService.camposAdicionais(funcionarioId).then(function (data) {
                vm.campoAdicional = data;
            });

            vm.tracker.camposAdicionais.addPromise(promise);
        }

        function build() {
            verificaFormulario();
            if (!vm.funcionario) {
                FuncionarioService.getDefaultRepresentation().then(function (data) {
                    vm.funcionario = data;
                });
            }
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }

        function save(continuar) {
            vm.trackerEmCurso = continuar ? vm.tracker.saveAndContinue : vm.tracker.save;

            vm.funcionario.campoAdicional = vm.campoAdicional;
            colocaSecretariaNull();

            var listaDesenturmar = [];
            if (vm.listaDesenturmar.length) {
                modalConfirm({
                    title: 'Confirmar desenturmação',
                    message: 'Deseja realmente desenturmar o funcionário dos locais de trabalho selecionados?'
                }).then(function () {
                    listaDesenturmar = vm.listaDesenturmar;
                    saveFuncionario(continuar, listaDesenturmar);
                });
            } else {
                saveFuncionario(continuar);
            }
        }

        function saveFuncionario(continuar, listaDesenturmar) {
            if (listaDesenturmar) {
                desenturmarLocais(listaDesenturmar);
            }

            var promise = FuncionarioService.save(vm.funcionario).then(
                function (data) {
                    vm.funcionario = data;
                    onSuccess(continuar);
                    mensageSuccess('salvo');
                }, function () {
                    colocaDescricaoSecretaria();
                });

            vm.trackerEmCurso.addPromise(promise);
        }

        function colocaSecretariaNull() {
            _.each(vm.funcionario.matriculas, function (matricula) {
                _.each(matricula.locaisTrabalho, function (localTrabalho) {
                    if (localTrabalho.estabelecimento !== null && localTrabalho.estabelecimento.isSecretaria === true) {
                        localTrabalho.estabelecimento = null;
                    }
                });
            });
        }

        function colocaDescricaoSecretaria() {
            _.each(vm.funcionario.matriculas, function(matricula) {
                _.each(matricula.locaisTrabalho, function(localTrabalho) {
                    if (!localTrabalho.estabelecimento) {
                        _.set(localTrabalho, 'estabelecimento', {
                            'descricao': 'Secretaria',
                            'isSecretaria': true
                        });
                    }
                });
            });
        }

        function onSuccess(continuar) {
            if (continuar) {
                novoFuncionario();
                refresh();
            } else {
                $modalInstance.close(vm.funcionario);
            }
        }

        function refresh() {
            return FuncionarioService.getDefaultRepresentation();
        }

        function remove() {
            vm.trackerEmCurso = vm.tracker.remove;
            vm.tracker.remove.addPromise(
                FuncionarioService.remove(vm.funcionario)
                    .then(function (data) {
                        $modalInstance.close(data);
                        mensageSuccess('removido');
                    })
            );
        }

        function onSelectPessoa(pessoa) {
            if (!pessoa) {
                return;
            }

            if (pessoa._id) {
                return vm.tracker.loading.addPromise(PessoaService.getPessoas(pessoa._id)
                    .then(_.bind(onLoadPessoa, null, pessoa._id)));
            }
        }

        function onLoadPessoa(idTemplate, pessoa) {
            novoFuncionario();

            vm.funcionario.pessoa = pessoa;
            vm.funcionario.template = idTemplate;
        }

        function novoFuncionario() {
            verificaFormulario();
            vm.funcionario = {
                enderecos: [],
                pessoa: {
                    telefones: [],
                    emails: []
                }
            };
        }

        function filterResult(term, value) {
            if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                return value;
            }
        }

        function removeCepInvalidChars(addresses) {
            _.forEach(addresses, function (address) {
                address.cep = _.padRight(address.cep.split('-').join(''), 8, '0');
            });
        }

        function mensageSuccess(mensagem) {
            Notification.publish('Funcionário ' + mensagem + ' com sucesso.', 'success');
        }

        function desenturmarLocais(listaDesenturmar) {
            var listItensDesenturmar = [];
            _.forEach(listaDesenturmar, function (itemDesenturmar) {
                var itemListDesenturmar = {
                    idFuncionario: itemDesenturmar.matricula,
                    data: itemDesenturmar.dataFinal,
                    idEstabelecimento: itemDesenturmar.estabelecimento,
                    dataInicialLocalTrabalho: itemDesenturmar.dataInicial
                };

                listItensDesenturmar.push(itemListDesenturmar);
            });

            vm.funcionario.enturmacoesFuncionarioDesenturmar = listItensDesenturmar;
        }
    }
})();

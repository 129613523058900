(function () {
    'use strict';
    var moduloCommon = angular.module('educacao.agendaprofessor');
    moduloCommon.controller('educacao.agendaprofessor.DisponiblidadeCadCtrl', DisponiblidadeCadCtrl);

    DisponiblidadeCadCtrl.$inject =[
        'promiseTracker',  
        '$q', 
        '$rootScope',  
        'bfc.Focus', 
        '$modalInstance', 
        'bfc.Notification', 
        'educacao.context.ContextoEstabelecimento',
        'educacao.agendaprofessor.DisponibilidadeService'
        ];

    function DisponiblidadeCadCtrl(
        promiseTracker,  
        $q, 
        $rootScope, 
        focus, 
        $modalInstance, 
        Notification, 
        ContextoEstabelecimento,
        DisponibilidadeService
        ) {


        var DiaSemana = {
            MONDAY: {
                'name': 'MONDAY',
                'descricao': 'segunda-feira'
            },
            TUESDAY: {
                'name': 'TUESDAY',
                'descricao': 'terça-feira'
            },
            WEDNESDAY: {
                'name': 'WEDNESDAY',
                'descricao': 'quarta-feira'
            },
            THURSDAY: {
                'name': 'THURSDAY',
                'descricao': 'quinta-feira'
            },
            FRIDAY: {
                'name': 'FRIDAY',
                'descricao': 'sexta-feira'
            },
            SATURDAY: {
                'name': 'SATURDAY',
                'descricao': 'sábado'
            },
            SUNDAY: {
                'name': 'SUNDAY',
                'descricao': 'domingo'
            }
        };

        var vm = this;
        var _select2 = {};

        
        vm.tracker = {
            save: promiseTracker(),
            saveAndContinue: promiseTracker(),
            remove: promiseTracker(),
            turma: promiseTracker()
        };

        vm.msg = '';
        vm.horariosValid = false;
        vm.disponibilidade = {};
        vm.todosHorariosValidos = false;
        vm.valuesNotUndefinedOrNull = valuesNotUndefinedOrNull;
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.onChangeEstabelecimento = onChangeEstabelecimento;
        var disponibilidade = $modalInstance.params.disponibilidade;
        vm.isHorarioConflitante = isHorarioConflitante;
        var modo = {
            adicionando: !disponibilidade ,
            editando: disponibilidade 
        };

        vm.horarios = [{}];

        init();
        vm.validarDisponibilidade = _validarDisponibilidade;

        vm.copiaHorarios = copiaHorarios;

        function init() {
            var propriedadesPublicas = {
                select2: _select2,
                save: _save,
                remove: _remove,
                clearLine: _clearLine,
                mode: modo
            };

            $modalInstance.tracker.addPromise(
                $q.when(refresh()).then(
                    function postInit() {
                        _validarDisponibilidade();
                        angular.extend(vm, propriedadesPublicas);
                        vm.windowTitle = modo.adicionando ? 'Adicionando disponibilidade' : 'Editando disponibilidade';
                    })
            );
        }

        function parseDisponibilidade() {
            return {
                id: disponibilidade.id,
                professores: [{
                    id: disponibilidade.professor.id,
                    descricao: disponibilidade.professor.pessoa.nome
                }],
                estabelecimento: {
                    'id': disponibilidade.estabelecimento.id,
                    'descricao': disponibilidade.estabelecimento.nome
                }
            };
        }

        function parseHorario() {
            if (disponibilidade.diaSemana === DiaSemana.MONDAY.name) {
                return {
                    horaInicioSegunda: disponibilidade.horaInicio,
                    horaFimSegunda: disponibilidade.horaFim
                };
            }
            if (disponibilidade.diaSemana === DiaSemana.TUESDAY.name) {
                return {
                    horaInicioTerca: disponibilidade.horaInicio,
                    horaFimTerca: disponibilidade.horaFim
                };
            }
            if (disponibilidade.diaSemana === DiaSemana.WEDNESDAY.name) {
                return {
                    horaInicioQuarta: disponibilidade.horaInicio,
                    horaFimQuarta: disponibilidade.horaFim
                };
            }
            if (disponibilidade.diaSemana === DiaSemana.THURSDAY.name) {
                return {
                    horaInicioQuinta: disponibilidade.horaInicio,
                    horaFimQuinta: disponibilidade.horaFim
                };
            }
            if (disponibilidade.diaSemana === DiaSemana.FRIDAY.name) {
                return {
                    horaInicioSexta: disponibilidade.horaInicio,
                    horaFimSexta: disponibilidade.horaFim
                };
            }
            if (disponibilidade.diaSemana === DiaSemana.SATURDAY.name) {
                return {
                    horaInicioSabado: disponibilidade.horaInicio,
                    horaFimSabado: disponibilidade.horaFim
                };
            }
            if (disponibilidade.diaSemana === DiaSemana.SUNDAY.name) {
                return {
                    horaInicioDomingo: disponibilidade.horaInicio,
                    horaFimDomingo: disponibilidade.horaFim
                };
            }
        }

        function parseDisponibilidadeProfessor() {
            vm.disponibilidade = parseDisponibilidade(disponibilidade);
            vm.horarios[0] = parseHorario(disponibilidade);
            vm.diaSemanaEdit = disponibilidade.diaSemana;
        }

        function refresh() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
            return Boolean(disponibilidade) ? parseDisponibilidadeProfessor(disponibilidade) :
                DisponibilidadeService.getDefault().then(function (data) {
                    vm.disponibilidade = angular.copy(data);
                    if (vm.hasEstabelecimento) {
                        vm.disponibilidade.estabelecimento = {
                            'id': ContextoEstabelecimento.getEstabelecimento().id,
                            'descricao': ContextoEstabelecimento.getEstabelecimento().descricao
                        };
                    }
                });

        }

        vm.contemHorarioInformado = function () {
            return vm.horarioInformado(_.last(vm.horarios)).length > 0;
        };

        vm.addHorario = function () {
            if (vm.contemHorarioInformado()) {
                vm.horarios.push({});
            }
        };

        function _validarDisponibilidade() {
            if (_horarios().length === 0) {
                vm.todosHorariosValidos = false;
            } else {
                if (existeConflitoHorarios()) {
                    vm.todosHorariosValidos = false;
                } else {
                    vm.todosHorariosValidos = true;
                }
            }
        }

        function valuesNotUndefinedOrNull(value1, value2) {

            value1 = value1 === null ? '' : value1;
            value2 = value2 === null ? '' : value2;

            return (angular.isUndefined(value1) && !angular.isUndefined(value2)) ||
                (!angular.isUndefined(value1) && angular.isUndefined(value2)) || (value1 === '' && value2 !== '') || (value1 !== '' && value2 === '') || (!angular.isUndefined(value1) && !angular.isUndefined(value2) && (value1 !== '' && value2 !== ''));
        }
        

        function isHorarioInvalido(horaInicio, horaFim, diaSemana) {
            var invalid = valuesNotUndefinedOrNull(horaInicio, horaFim) && horaInicio !== null && horaInicio >= horaFim;
            if (invalid) {
                exibirMsgErroHorariosEntradaSaida(diaSemana);
            }
            return invalid;
        }

        function horaInformada(horarioInicio, horarioFim){
            return isNotUndefinedOrNull(horarioInicio) && isNotUndefinedOrNull(horarioFim);
        }
        function isNotUndefinedOrNull(value){
            return !angular.isUndefined(value) && value !== null;
        }
        var exibirMsgErroConflitoHorarios = function (diaSemana) {
            Notification.publish('Existem conflitos nos horários de ' + diaSemana + '!', 'error');
        };

        var exibirMsgErroHorariosEntradaSaida = function (diaSemana) {
            Notification.publish('Os horários de ' + diaSemana + ' estão inválidos. O horário de saída deve ser posterior ao horário de entrada!');
        };

        var existeConflitoHorarios = function () {
            for (var idx1 = 0; idx1 < vm.horarios.length; idx1++) {
                var horario1 = vm.horarios[idx1];
                if (isHorarioInvalido(horario1.horaInicioSegunda, horario1.horaFimSegunda, DiaSemana.MONDAY.descricao) || 
                    isHorarioInvalido(horario1.horaInicioTerca, horario1.horaFimTerca, DiaSemana.TUESDAY.descricao) ||
                    isHorarioInvalido(horario1.horaInicioQuarta, horario1.horaFimQuarta, DiaSemana.WEDNESDAY.descricao) ||
                    isHorarioInvalido(horario1.horaInicioQuinta, horario1.horaFimQuinta, DiaSemana.THURSDAY.descricao) ||
                    isHorarioInvalido(horario1.horaInicioSexta, horario1.horaFimSexta, DiaSemana.FRIDAY.descricao) ||
                    isHorarioInvalido(horario1.horaInicioSabado, horario1.horaFimSabado, DiaSemana.SATURDAY.descricao) ||
                    isHorarioInvalido(horario1.horaInicioDomingo, horario1.horaFimDomingo, DiaSemana.SUNDAY.descricao)
                    ) {
                    return true;
                }
                for (var idx2 = idx1 + 1; idx2 < vm.horarios.length; idx2++) {
                    var horario2 = vm.horarios[idx2];
                    
                    isHorarioConflitante(horario1.horaInicioSegunda, horario1.horaFimSegunda, horario2.horaInicioSegunda, horario2.horaFimSegunda,DiaSemana.MONDAY.descricao);
                    isHorarioConflitante(horario1.horaInicioTerca, horario1.horaFimTerca, horario2.horaInicioTerca, horario2.horaFimTerca,DiaSemana.TUESDAY.descricao);
                    isHorarioConflitante(horario1.horaInicioQuarta, horario1.horaFimQuarta, horario2.horaInicioQuarta, horario2.horaFimQuarta,DiaSemana.WEDNESDAY.descricao);
                    isHorarioConflitante(horario1.horaInicioQuinta, horario1.horaFimQuinta, horario2.horaInicioQuinta, horario2.horaFimQuinta,DiaSemana.THURSDAY.descricao);
                    isHorarioConflitante(horario1.horaInicioSexta, horario1.horaFimSexta, horario2.horaInicioSexta, horario2.horaFimSexta,DiaSemana.FRIDAY.descricao);
                    isHorarioConflitante(horario1.horaInicioSabado, horario1.horaFimSabado, horario2.horaInicioSabado, horario2.horaFimSabado,DiaSemana.SATURDAY.descricao);
                    isHorarioConflitante(horario1.horaInicioDomingo, horario1.horaFimDomingo, horario2.horaInicioDomingo, horario2.horaFimDomingo,DiaSemana.SUNDAY.descricao);
                    
                }
            }
            return false;
        };

        
        function isHorarioConflitante(horario1Inicio, horario1Fim, horario2Inicio, horario2Fim, diaSemana) {

            if (horario1Inicio === null || horario1Fim === null || horario2Inicio === null || horario2Fim === null) {
                return false;
            }
            if (horario1Inicio <= horario2Inicio && horario1Fim >= horario2Fim) {
                exibirMsgErroConflitoHorarios(diaSemana);
                return true;
            }
            if (horario1Inicio >= horario2Inicio && horario1Inicio <= horario2Fim) {
                exibirMsgErroConflitoHorarios(diaSemana);
                return true;
            }
            if (horario1Fim >= horario2Inicio && horario1Fim <= horario2Fim) {
                exibirMsgErroConflitoHorarios(diaSemana);
                return true;
            }

            return false;
        }

        var _horarios = function () {

            var horariosArray = [];

            vm.horarios.filter(function (horario) {
                var horariosInformados = vm.horarioInformado(horario);
                if (!_.isEmpty(horariosInformados)) {
                    horariosArray = horariosArray.concat(horariosInformados);
                }

            });

            return horariosArray;

        };

        vm.horarioInformado = function (horario) {
            var horariosArray = [];
            if (horaInformada(horario.horaInicioSegunda,horario.horaFimSegunda)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioSegunda,
                    horaFim: horario.horaFimSegunda,
                    diaSemana: DiaSemana.MONDAY.name
                });
            }

            if (horaInformada(horario.horaInicioTerca,horario.horaFimTerca)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioTerca,
                    horaFim: horario.horaFimTerca,
                    diaSemana: DiaSemana.TUESDAY.name
                });
            }

            if (horaInformada(horario.horaInicioQuarta,horario.horaFimQuarta)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioQuarta,
                    horaFim: horario.horaFimQuarta,
                    diaSemana: DiaSemana.WEDNESDAY.name
                });
            }

            if (horaInformada(horario.horaInicioQuinta,horario.horaFimQuinta)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioQuinta,
                    horaFim: horario.horaFimQuinta,
                    diaSemana: DiaSemana.THURSDAY.name
                });
            }

            if (horaInformada(horario.horaInicioSexta,horario.horaFimSexta)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioSexta,
                    horaFim: horario.horaFimSexta,
                    diaSemana: DiaSemana.FRIDAY.name
                });
            }

            if (horaInformada(horario.horaInicioSabado,horario.horaFimSabado)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioSabado,
                    horaFim: horario.horaFimSabado,
                    diaSemana: DiaSemana.SATURDAY.name
                });
            }

            if (horaInformada(horario.horaInicioDomingo,horario.horaFimDomingo)) {
                horariosArray.push({
                    horaInicio: horario.horaInicioDomingo,
                    horaFim: horario.horaFimDomingo,
                    diaSemana: DiaSemana.SUNDAY.name
                });
            }
            return horariosArray;
        };

        function _save(continuar) {
            vm.trackerEmCurso = continuar ? vm.tracker.saveAndContinue : vm.tracker.save;
            vm.disponibilidade.horarios = _horarios();

            vm.trackerEmCurso.addPromise(
                DisponibilidadeService.save(vm.disponibilidade).then(function (data) {
                    $rootScope.$broadcast('refreshDisponibilidades', {
                        data: data,
                        add: !disponibilidade,
                        edit: Boolean(disponibilidade)
                    });
                    onSuccess(continuar);
                })
            );

        }

        function _remove() {
            vm.trackerEmCurso = vm.tracker.remove;
            vm.tracker.remove.addPromise(
                DisponibilidadeService.remove(vm.disponibilidade.id).then(function () {
                    $rootScope.$broadcast('refreshDisponibilidades', {
                        data: vm.disponibilidade,
                        remove: true
                    });
                    fecharModal();
                })
            );
        }

        function onSuccess(continuar) {
            if (continuar) {
                disponibilidade = null;
                vm.horarios = [{}];
                vm.tracker.botoes.addPromise(refresh());
                focus('lbProfessor');

            } else {
                fecharModal();
            }
        }

      

        function fecharModal() {
            $modalInstance.close();
        }

        function copiaHorarios(indexLinha, indexInicio, indexFinal) {
            if (vm.mode.editando) {
                return;
            }

            if (indexFinal === indexInicio) {
                return;
            }
            indexInicio--;

            var horario = vm.horarios[indexLinha];
            var sufixo = getSufixo(indexInicio);

            var valoresCopiar = getValores(horario, sufixo);

            do {
                sufixo = getSufixo(indexInicio);

                horario['horaInicio' + sufixo] = valoresCopiar.inicio;
                horario['horaFim' + sufixo] = valoresCopiar.fim;

            } while ((indexInicio += 2) < indexFinal);
        }

        function getSufixo(index) {
            switch (index) {
                case 0:
                    return 'Domingo';
                case 2:
                    return 'Segunda';
                case 4:
                    return 'Terca';
                case 6:
                    return 'Quarta';
                case 8:
                    return 'Quinta';
                case 10:
                    return 'Sexta';
                default:
                    return 'Sabado';
            }
        }

        function getValores(horario, dia) {
            return {
                inicio: horario['horaInicio' + dia],
                fim: horario['horaFim' + dia]
            };
        }

        function onChangeEstabelecimento(){
            delete vm.disponibilidade.professores;
        }

        function _clearLine(index) {
            vm.horarios[index] = {};
            vm.validarDisponibilidade();
        }
    }

})();

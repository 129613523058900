(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.PaisSelect', PaisSelect);

    PaisSelect.$inject = ['$injector'];

    function PaisSelect($injector) {

        var paisService = $injector.get('educacao.common.PaisService');
        var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');

        return {
            select2Config: function() {
                return CommonSelectWithService.select2Config(paisService, 'nome', {
                    minimumInputLength: 3
                });
            }
        };

    }
})();

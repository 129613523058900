(function () {

    'use strict';

    angular.module('educacao.matriz') //verificar modulo
        .constant('NIVEL_ESCOLAR', {
            EDUCACAO_INFANTIL: 'EDUCACAO_INFANTIL',
            ENSINO_FUNDAMENTAL: 'ENSINO_FUNDAMENTAL',
            ENSINO_MEDIO: 'ENSINO_MEDIO'
        });

})();
(function() {
    'use strict';

    angular.module('educacao.quadro-funcionarios-configuracao').directive('appSelectConfiguracaoQuadroFuncionariosDisciplinas', SelectConfigQuadroFuncionarioDisciplinaDirective);

    function SelectConfigQuadroFuncionarioDisciplinaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search" ngclass="vm.styleEdit()"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                ngclass: '=',
                addOther: '=',
                matrizId: '=',
                etapaId: '=',
                disciplinasEtapas: '=',
                isEditando: '=',
                etapaMatrizDisciplinasOnEdit: '=',
                tipoOrganizacao: '=',
                componentesCurricularesOnEdit: '=',
                hasDiffComponentes: '='
            },
            controller: SelectConfigQuadroFuncionarioDisciplinaDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectConfigQuadroFuncionarioDisciplinaDirectiveController.$inject = [
        '$scope', 
        '$q',
        'educacao.matriz.EtapaDisciplinaService',
        'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosUtils'
    ];

    function SelectConfigQuadroFuncionarioDisciplinaDirectiveController($scope, $q, service, QuadroFuncionariosUtils) {
        var vm = this;

        var TIPOS_ORGANIZACAO = {
            CAMPOS_EXPERIENCIA: 'CAMPOS_EXPERIENCIA',
            DISCIPLINA: 'DISCIPLINA',
            EIXO_TEMATICO: 'EIXO_TEMATICO'
        };

        vm.search = search;
        vm.formatResult = formatResult;
        vm.styleEdit = styleEdit;

        function search(params) {
            var filter = '';


            if($scope.isEditando && $scope.hasDiffComponentes) {
                return $q.when({
                    content: $scope.componentesCurricularesOnEdit,
                    hasMore: false
                });
            }

            if ($scope.multiple) {
                var disciplinasId = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        disciplinasId.push(model.id);
                    });
                }

                if ($scope.disciplinasEtapas && $scope.disciplinasEtapas.length) {
                    disciplinasId = disciplinasId.concat($scope.disciplinasEtapas);
                }

                if (disciplinasId.length) {
                    if($scope.isEditando) {
                        var itensToFilter = _.filter($scope.etapaMatrizDisciplinasOnEdit, function(data) {
                            return !_.includes(disciplinasId, data.id);
                        });

                        var filteredIds = _.map(itensToFilter, 'id');

                        if(filteredIds.length) {
                            filter += 'id in(' + filteredIds.join() + ')';
                        } else {
                            return $q.when({
                                content: [],
                                hasMore: false
                            });
                        }

                    } else {
                        filter += 'id not in(' + disciplinasId.join() + ')';
                    }
                } else {
                    filter += 'id in(' + _.map($scope.etapaMatrizDisciplinasOnEdit, 'id').join() + ')';
                }
            }

            if(params.term) {
                filter += getDescricaoFilter(params.term, filter);
            }

            return service
                .getList($scope.matrizId, $scope.etapaId, filter)
                .then(toPage);
        }

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function formatResult(data) {
            return QuadroFuncionariosUtils.getDescricaoItemCurricular(data);
        }

        function styleEdit() {
            return $scope.ngModel && $scope.ngModel.length === 0 ? 'validacaoCampoComponenteStyle' : '';
        }

        function getDescricaoFilter(nomeComponenteCurricular, filter) {
            var hasFilter = filter === '' ? '' : ' and ';

            if(TIPOS_ORGANIZACAO.CAMPOS_EXPERIENCIA === $scope.tipoOrganizacao) {
                return hasFilter + 'descricaoCampoExperiencia like "' + nomeComponenteCurricular + '"';
            }

            if(TIPOS_ORGANIZACAO.EIXO_TEMATICO === $scope.tipoOrganizacao) {
                return hasFilter + 'descricaoEixoTematico like "' + nomeComponenteCurricular + '"';
            }

            return hasFilter +  'descricaoDisciplina like "' + nomeComponenteCurricular + '"';
        }
    }
})();
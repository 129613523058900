(function() {

    'use strict';

    var CLASS_TEXT_GRAY_8 = 'tx__gray--d10';
    var CLASS_TEXT_BLUE_A6 = 'tx__aqua--l10';

    var TIPO_AVALIACAO = {
        NUMERICO_INTEIRO: 'NUMERICO_INTEIRO',
        NUMERICO_UMA_CASA: 'NUMERICO_UMA_CASA_DECIMAL',
        NUMERICO_DUAS_CASAS: 'NUMERICO_DUAS_CASAS_DECIMAIS',
        CONCEITUAL: 'CONCEITUAL',
        DESCRITIVA: 'DESCRITIVA'

    };

    angular.module('educacao.historico-escolar')
        .directive('inputMediaFinal', InputMediaFinal);

    function InputMediaFinal() {
        return {
            restrict: 'E',
            templateUrl: 'historico-escolar/input-media-final/input-media-final.directive.html',
            scope: {
                notaConceito: '=',
                notaDescritiva: '=',
                notaNumerica: '=',
                nomeAluno: '@',
                tipoAvaliacao: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'ui.components.Modal'
        ];

    function Controller($scope, uiModal) {

        var vm = this;

        vm.modoAvaliacaoNumerico = false;
        vm.modoAvaliacaoConceitual = false;
        vm.modoAvaliacaoDescritiva = false;

        vm.abrirModalNotaDescritiva = abrirModalNotaDescritiva;

        vm.classNotaDescritiva = CLASS_TEXT_GRAY_8;

        vm.configuracao = {};

        $scope.$watch('notaAlunoInteiro', atribuirScopeNumerico);
        $scope.$watch('notaAlunoDecimal', atribuirScopeNumerico);

        $scope.$watch('notaDescritiva', function(nota) {
            if (nota) {
                vm.classNotaDescritiva = CLASS_TEXT_BLUE_A6;
            } else {
                vm.classNotaDescritiva = CLASS_TEXT_GRAY_8;
            }
        });

        $scope.$watch('tipoAvaliacao', function(tipoAvaliacao) {

            vm.modoAvaliacaoNumerico = false;
            vm.modoAvaliacaoConceitual = false;
            vm.modoAvaliacaoDescritiva = false;

            switch (tipoAvaliacao) {
                case TIPO_AVALIACAO.NUMERICO_INTEIRO:
                    vm.modoAvaliacaoNumerico = true;

                    $scope.notaConceito = null;
                    $scope.notaDescritiva = null;

                    $scope.notaAlunoInteiro = $scope.notaNumerica;

                    vm.configuracao = {
                        tipo: 'NUMERICO',
                        inteiros: 4,
                        decimais: 0
                    };
                    break;
                case TIPO_AVALIACAO.NUMERICO_UMA_CASA:

                    vm.modoAvaliacaoNumerico = true;

                    $scope.notaAlunoDecimal = $scope.notaNumerica;

                    $scope.notaConceito = null;
                    $scope.notaDescritiva = null;

                    vm.configuracao = {
                        tipo: 'NUMERICO',
                        inteiros: 4,
                        decimais: 1
                    };
                    break;
                case TIPO_AVALIACAO.NUMERICO_DUAS_CASAS:
                    vm.modoAvaliacaoNumerico = true;

                    $scope.notaConceito = null;
                    $scope.notaDescritiva = null;

                    $scope.notaAlunoDecimal = $scope.notaNumerica;

                    vm.configuracao = {
                        tipo: 'NUMERICO',
                        inteiros: 4,
                        decimais: 2
                    };
                    break;
                case TIPO_AVALIACAO.CONCEITUAL:
                    vm.modoAvaliacaoConceitual = true;

                    $scope.notaNumerica = null;
                    $scope.notaDescritiva = null;

                    vm.configuracao = {
                        tipo: 'CONCEITUAL'
                    };
                    break;
                case TIPO_AVALIACAO.DESCRITIVA:
                    vm.modoAvaliacaoDescritiva = true;

                    $scope.notaNumerica = null;
                    $scope.notaConceito = null;

                    vm.configuracao = {
                        tipo: 'DESCRITIVA'
                    };
                    break;
                default:
                    vm.configuracao = {};
                    break;
            }
        });

        function atribuirScopeNumerico(value, oldValue) {
            if (_.isEqual(value, oldValue)) {
                return;
            }
            $scope.notaNumerica = value;
        }

        function abrirModalNotaDescritiva() {
            uiModal.open({
                templateUrl: 'historico-escolar/input-media-final/nota-descritiva.cad.html',
                controller: 'educacao.historicoEscolar.NotaDescritivaCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    notaDescritiva: $scope.notaDescritiva,
                    nomeAluno: $scope.nomeAluno
                }
            }).result.then(function(dados) {
                $scope.notaDescritiva = dados;
            });
        }
    }
})();


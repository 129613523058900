(function() {
    'use strict';

    var URL_FORMULA_SECRETARIA = 'formulas-secretaria';
    var URL_FORMULA_ESTABELECIMENTO = 'formulas-estabelecimento';

    angular.module('educacao.formulaCalculo')
        .factory('educacao.formulaCalculo.FormulaCalculoService', Service);

    Service.$inject = ['$injector', 'educacao.context.ContextoEstabelecimento'];

    function Service($injector, ContextoEstabelecimento) {

        var ServiceBuilder = $injector.get('ServiceBuilder');


        var selfService = ServiceBuilder.create()
            .path('formula-calculo')
            .enum('TipoFormulaCalculo')
            .build();

        selfService.getFormula = getFormula;

        return selfService;

        function getFormula() {
            if (!ContextoEstabelecimento.isSecretaria()) {
                return selfService.getEndpoint().get(URL_FORMULA_ESTABELECIMENTO);
            }

            return selfService.getEndpoint().get(URL_FORMULA_SECRETARIA);
        }        
    }
})();


(function () {

    'use strict';

    angular.module('educacao.frequencia')
        .directive('tabelaFrequencia', Directive);

    function Directive() {
        return {
            templateUrl: 'frequencia/tabela-frequencia.directive.html',
            restrict: 'E',
            scope: {},
            bindToController: {
                ngModel: '=',
                ngChange: '&?',
                configuracaoFrequencia: '=',
                filtered: '=',
                itensEducacionais: '=',
                turma: '=',
                searchFilter: '=',
                searchRegistros: '=',
                headerFilterOptions: '=',
                models: '=',
                registros: '=',
                openImpressaoDiario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$rootScope',
        '$filter',
        'educacao.matricula.TurmaService',
        'ui.components.Modal',
        'bfc.Notification',
        'educacao.entidade.EntidadeService',
        '$scope',
        'promiseTracker',
        'bfc.PromiseTracker',
        'educacao.frequencia.FrequenciaService',
        'educacao.abonojustificativa.AbonoJustificativaService',
        'educacao.common.ObjectUtilsService',
        '$searchExpressionBuilder',
        'educacao.encerramento.EncerramentoService',
        'bfc.$$PermissionsService',
        'educacao.encerramento.EncerramentoUtilsService',
        'HeaderFilterBuilderService',
        'EdEnumsCacheService'
    ];

    function Controller(
        $rootScope,
        $filter,
        TurmaService,
        $modal,
        bfcNotification,
        EntidadeService,
        $scope,
        promiseTracker,
        PromiseTracker,
        frequenciaService,
        AbonoJustificativaService,
        ObjectUtilsService,
        $searchExpressionBuilder,
        EncerramentoService,
        $$PermissionsService,
        EncerramentoUtilsService,
        HeaderFilterBuilderService,
        EdEnumsCacheService
    ) {
        var vm = this,
            diarioOld;

        vm.columns = [
            {
                id: 'nomeAluno',
                label: 'Aluno(a)',
                model: 'nome',
                filterable: true
            }
        ];

        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaLoadings();
            EdEnumsCacheService.get('SituacaoMatricula').then(function(result) {
                vm.enumSituacaoMatricula = result;
            });
            _.set(vm.searchFilter, 'others.situacaoMatricula', ['CURSANDO']);
        }

        function inicializaPropriedades() {
            vm.frequencia = {};
            vm.filtered = [];

            vm.indexTurma = 0;
            vm.predicate = 'nome';
            vm.reverse = false;
            vm.openOneTime = false;
            vm.trocandoPeriodo = false;
            vm.atividade = false;
            vm.getConfiguracaoFrequencia = getConfiguracaoFrequencia;
            vm.setFaltaAluno = setFaltaAluno;
            vm.searchRegistros = searchRegistros;
            vm.order = order;
            vm.truncar = truncar;
            vm.navegaEntrePeriodo = navegaEntrePeriodo;
            vm.openModalAbonoJustificativa = openModalAbonoJustificativa;
            vm.addAcompanhamento = addAcompanhamento;
            vm.openImpressaoDiario = openImpressaoDiario;
            vm.getPeriodoAvaliativo = getPeriodoAvaliativo;
            vm.publicar = publicar;
            vm.itensEducacionais = {};
            vm.diario = {};
            vm.orderBy = orderBy;
            vm.atualizaTotaisEnturmacao = atualizaTotaisEnturmacao;
            vm.tracker = promiseTracker();
            vm.hasPermissionFrequenciaPeriodoEncerrado = hasPermissionFrequenciaPeriodoEncerrado;
            vm.hasPermissionAbonoPeriodoEncerrado = hasPermissionAbonoPeriodoEncerrado;
            vm.verificaEncerramento = verificaEncerramento;
            vm.permissions = !$$PermissionsService.isRevokedOperation('RegistroFrequenciaPageMapping', 'alterarFrequenciaPeriodoAvaliativoEncerrado');
            vm.permissionsAbono = !$$PermissionsService.isRevokedOperation('AbonoFaltaPageMapping', 'informarAbonoPeriodoEncerrado');

            vm.topFloatHeader = $rootScope.$$pinned ? '78' : '44';
        }

        var tipoAtendimentoAtividade = ['ATIVIDADE_COMPLEMENTAR', 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO'];

        function isAtividade(tipoAtendimento) {
            return tipoAtendimentoAtividade.indexOf(tipoAtendimento) > -1;
        }

        // verifica se houve alteração na fixação do contexto
        $scope.$watch('$root.$$pinned', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            vm.topFloatHeader = newValue ? '78' : '44';
        });

        $scope.$watch('vm.turma', function () {
            vm.atividade = false;
            if (!_.get(vm.turma, 'id')) {
                return;
            }
            getEncerramentosTurma();
            vm.serviceAbono = AbonoJustificativaService.buildService(vm.turma.id);
            if (!isAtividade(vm.turma.tipoAtendimento)) {
                if (vm.turma.etapaMatriz !== null) {
                    if (vm.turma && !vm.turma.etapaMatriz.modular) {
                        getConfiguracaoFrequencia();
                    }
                }
            } else {
                vm.atividade = true;
                getConfiguracaoFrequencia();
            }
            loadPublicados();
        });

        function changeFilterPeriodo(value) {
            if (_.isEqual(vm.periodoAtual, value)) {
                return;
            }
            vm.periodoAtual = value;
            searchRegistros();
        }

        function inicializaLoadings() {
            vm.loadings = PromiseTracker.create(['frequenciaPeriodos', 'salvar']);
        }

        function getConfiguracaoFrequencia() {
            TurmaService.getAlunosTurma(vm.turma.id).then(function (data) {
                vm.diario = data;
            });

            vm.tempIdTurma = vm.turma.id;
            vm.turmaClone = _.clone(vm.turma);
            vm.estabelecimento = vm.turma.estabelecimento;
            getPeriodosTurma(vm.turma.id).then(searchInicial);

            $('table.ed-float-thead').floatThead('reflow');
        }

        function getPeriodosTurma(idTurma) {
            var promise = frequenciaService.getPeriodosTurma(idTurma);
            vm.tracker.addPromise(promise);
            return promise.then(function (data) {
                vm.periodosTurma = 0;
                if (vm.configuracaoFrequencia.tipoRegistroFrequencia !== 'TOTAL_FALTAS_DIA') {
                    vm.periodosTurma = data;
                }
                vm.itensEducacionais = data;
                vm.headerFilterOptions.clearOthers();

                createFilterPeriodo(vm.periodosTurma);
                createFilterSituacao();

                vm.periodoAtual = getPeriodoAtual();

                if (vm.periodoAtual) {
                    verificaEncerramento(vm.periodoAtual);
                }
                if (isAtividade(vm.turma.tipoAtendimento)) {
                    vm.disableInput = vm.disableInputAbono = false;
                    if (vm.encerramentosTurma) {
                        vm.disableInput = !vm.permissions;
                        vm.disableInputAbono = !vm.permissionsAbono;
                    }
                }
                return data;

            }).catch(
                function () {
                    vm.turma = vm.turmaClone;
                }
            );
        }

        function getItensEducacionais(idTurma) {
            var promise = frequenciaService.getItensEducacionais(idTurma);
            promise.then(function (data) {
                vm.itensEducacionais = data;
                _.forEach(vm.registros, function (valueRegistro, key) {
                    _.forEach(vm.itensEducacionais, function (valueItemEducacional) {
                        if (vm.periodosTurma) {
                            _.forEach(vm.periodosTurma, function (valuePeriodoTurma) {
                                if (valueRegistro.registroFaltas) {
                                    if (_.find(valueRegistro.registroFaltas, {
                                        'enturmacao': {
                                            'id': valueRegistro.enturmacao.id
                                        },
                                        'itemEducacional': {
                                            'id': valueItemEducacional.id
                                        },
                                        'periodoAvaliativo': {
                                            'id': valuePeriodoTurma.id
                                        }
                                    }) === undefined) {
                                        if (!vm.registros[key].resultadoFrequencia) {
                                            addResultadoFrequencia(key);
                                        }
                                        addRegistroFaltaPeriodoAvaliativo(valueRegistro,
                                            valueItemEducacional, valuePeriodoTurma, key);
                                    }
                                }
                                $('table.ed-float-thead').floatThead('reflow');
                            });
                        } else {
                            if (valueRegistro.registroFaltas) {
                                if (_.find(valueRegistro.registroFaltas, {
                                    'enturmacao': {
                                        'id': valueRegistro.enturmacao.id
                                    },
                                    'itemEducacional': {
                                        'id': valueItemEducacional.id
                                    }
                                }) === undefined) {
                                    if (!vm.registros[key].resultadoFrequencia) {
                                        addResultadoFrequencia(key);
                                    }
                                    addRegistroFalta(valueRegistro, valueItemEducacional, key);
                                }
                            }
                            $('table.ed-float-thead').floatThead('reflow');
                        }
                    });
                });
            });
            vm.tracker.addPromise(promise);

        }

        vm.sortBy = {
            expression: 'enturmacao.numeroChamada',
            reverse: false
        };

        function addResultadoFrequencia(key) {
            vm.registros[key].resultadoFrequencia = {
                id: null,
                percentual: 100,
                faltas: null
            };
        }

        function addRegistroFalta(registro, itemEducacional, key) {
            vm.registros[key].registroFaltas.push({
                id: null,
                quantidadeFaltas: null,
                enturmacao: {
                    id: registro.enturmacao.id
                },
                itemEducacional: {
                    id: itemEducacional.id
                }
            });
        }

        function addRegistroFaltaPeriodoAvaliativo(registro, itemEducacional, periodoAvaliativo, key) {
            vm.registros[key].registroFaltas.push({
                id: null,
                quantidadeFaltas: null,
                enturmacao: {
                    id: registro.enturmacao.id
                },
                periodoAvaliativo: {
                    'id': periodoAvaliativo.id
                },
                itemEducacional: {
                    id: itemEducacional.id
                }
            });
        }

        function getPeriodoAvaliativo() {
            if (vm.periodosTurma.length <= 0) {
                return;
            }
            var periodo = vm.periodoAtual;
            var format = 'dd/MM/yyyy';
            if (!periodo) {
                return;
            }
            return periodo.descricao + ' (' + $filter('date')(periodo.dataInicial, format) + ' até ' + $filter('date')(periodo.dataFinal,
                format) + ')';

        }

        function list(periodoAvaliativo, enturmacao) {

            var filter = '';
            var append = '';

            if (periodoAvaliativo) {
                filter += 'periodoAvaliativo = ' + periodoAvaliativo || -1;
                append = ' and ';
            }

            if (enturmacao) {
                filter += append + 'enturmacao = ' + enturmacao || -1;
            }

            return vm.serviceAbono.completos({ filter: filter }).then(function (dados) {
                vm.abonosJustificativas = _.first(dados).content;
                return vm.abonosJustificativas;
            });

        }

        function salvaUltimoValorFalta(falta, index) {
            var diarioExist = _.find(diarioOld, function (obj) {
                return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
            });
            vm.valorAntigoFalta = diarioExist.registroFaltas[index];
        }

        function cadastraFalta(falta, index) {

            salvaUltimoValorFalta(falta, index);
            var idTurma = vm.turma.id;
            if ((falta.id === null || falta.id === '') && (falta.quantidadeFaltas === '' || parseInt(falta.quantidadeFaltas) === 0)) {
                cadastraUnicaFaltaAula(idTurma, falta, index);
            } else if ((falta.id === null || falta.id === '') && (falta.quantidadeFaltas !== '' || parseInt(falta.quantidadeFaltas) !== 0)) {
                cadastraUnicaFaltaAula(idTurma, falta, index);
            } else if ((falta.id !== null || falta.id !== '') && (falta.quantidadeFaltas === '' || parseInt(falta.quantidadeFaltas) === 0)) {
                falta.quantidadeFaltas = null;
                var promise;
                if (vm.permissions) {
                    promise = frequenciaService.removeFrequenciaPeriodoEncerrado(falta.id);
                } else {
                    promise = frequenciaService.removeFrequencia(falta.id);
                }
                promise.then(function (data) {
                    vm.openOneTime = true;
                    data.registroFaltas.id = null;
                    data.registroFaltas.quantidadeFaltas = null;
                    setRetornoAlunos(falta, data, index);
                });
                vm.loadings.salvar.addPromise(promise);
            } else {
                vm.openOneTime = true;
                if (falta.quantidadeFaltas === '' || falta.quantidadeFaltas === 0) {
                    falta.quantidadeFaltas = null;
                    var promisef;
                    if (vm.permissions) {
                        promisef = frequenciaService.removeFrequenciaPeriodoEncerrado(falta.id);
                    } else {
                        promisef = frequenciaService.removeFrequencia(falta.id);
                    }
                    promisef.then(function (data) {
                        data.registroFaltas.id = null;
                        data.registroFaltas.quantidadeFaltas = null;

                        setRetornoAlunos(falta, data, index);
                    });
                } else {
                    atualizaFaltaAula(falta.id, idTurma, falta, index);
                }
            }
        }

        function setFaltaAluno(falta, index) {
            var promiseAbonos = '';
            if (falta.periodoAvaliativo !== null && falta.periodoAvaliativo !== undefined) {
                promiseAbonos = list(falta.periodoAvaliativo.id, falta.enturmacao.id);
            } else {
                promiseAbonos = list(null, falta.enturmacao.id);
            }
            promiseAbonos.then(function (dados) {
                vm.existeAbonoNaFrequencia = _.filter(dados, function (obj) {
                    return falta.itemEducacional.id === obj.itemEducacional.id;
                });

                if (vm.existeAbonoNaFrequencia !== undefined) {

                    salvaUltimoValorFalta(falta, index);
                    var soma = 0;
                    for (var i = 0; i < vm.existeAbonoNaFrequencia.length; i++) {
                        soma = soma + vm.existeAbonoNaFrequencia[i].quantidadeAbono;
                    }
                    if (falta.quantidadeFaltas < soma) {
                        falta.quantidadeFaltas = vm.valorAntigoFalta.quantidadeFaltas;
                        bfcNotification.publish(
                            'O número de faltas precisa ser maior ou igual a quantidade de Justificativa/Abono',
                            'error');
                    } else {
                        cadastraFalta(falta, index);
                    }
                } else {

                    cadastraFalta(falta, index);
                }
            });

        }

        function atualizaFaltaAula(faltaId, idTurma, falta, index) {
            var promise;
            if (vm.permissions) {
                promise = frequenciaService.atualizaFrequenciaEncerramento(faltaId, idTurma, falta);
            } else {
                promise = frequenciaService.atualizaFrequencia(faltaId, idTurma, falta);
            }
            promise.then(function (data) {
                setRetornoAlunos(falta, data, index);
            }, function () {

                falta.quantidadeFaltas = vm.valorAntigoFalta.quantidadeFaltas;
                vm.openOneTime = false;

            });
            vm.loadings.salvar.addPromise(promise);
        }

        function cadastraUnicaFaltaAula(idTurma, falta, index) {
            if (falta.quantidadeFaltas === '') {
                falta.quantidadeFaltas = null;
            }
            vm.openOneTime = true;
            var promise;
            if (vm.permissions) {
                promise = frequenciaService.saveFrequenciaEncerramento(idTurma, falta);
            } else {
                promise = frequenciaService.saveFrequencia(idTurma, falta);
            }
            promise.then(function (data) {
                setRetornoAlunos(falta, data, index);
            }, function () {
                if (vm.valorAntigoFalta !== undefined) {
                    falta.quantidadeFaltas = vm.valorAntigoFalta.quantidadeFaltas;
                } else {
                    falta.quantidadeFaltas = null;
                }
                vm.openOneTime = false;
            });
            vm.loadings.salvar.addPromise(promise);
        }

        function setRetornoAlunos(falta, retorno, index) {
            var diarioExist = _.find(vm.registros, function (obj) {
                return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
            });
            var diarioOldExist = _.find(diarioOld, function (obj) {
                return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
            });
            diarioOldExist.registroFaltas[index] = retorno.registroFaltas;
            var teste = diarioOldExist.registroFaltas[index];
            if (angular.isDefined(diarioExist) && teste) {
                angular.copy(retorno.registroFaltas, diarioExist.registroFaltas[index]);
                angular.copy(retorno.resultadoFrequencia, diarioExist.resultadoFrequencia);
            }
            vm.openOneTime = true;
        }

        function searchInicial(periodo) {
            if (!periodo && !vm.atividade) {
                return;
            }
            searchRegistros();
        }

        function searchRegistros() {
            if (!_.get(vm.turma, 'id')) {
                return;
            }
            if (!_.get(vm.turma, 'etapaMatriz.modular') && _.get(vm.searchFilter, 'others.situacaoMatriculaPai')) {
                vm.searchFilter.others.situacaoMatriculaPai = null;
                return;
            }
            var filter = $searchExpressionBuilder.build(vm.searchFilter);
            var promise = frequenciaService.getDadosFrequencia(vm.turma.id, filter, 0, 999);
            promise.then(function (data) {
                vm.registros = data.plain();
                diarioOld = angular.copy(data);
                if (vm.configuracaoFrequencia.tipoRegistroFrequencia !== 'TOTAL_FALTAS_DIA') {
                    getItensEducacionais(vm.turma.id);
                } else {
                    _.forEach(vm.registros, function (valueRegistro, key) {
                        _.forEach(vm.itensEducacionais, function (valueItemEducacional) {
                            if (valueRegistro.registroFaltas) {
                                if (_.find(valueRegistro.registroFaltas, {
                                    'enturmacao': {
                                        'id': valueRegistro.enturmacao.id
                                    },
                                    'periodoAvaliativo': {
                                        'id': valueItemEducacional.id
                                    }
                                }) === undefined) {
                                    if (!vm.registros[key].resultadoFrequencia) {
                                        addResultadoFrequencia(key);
                                    }

                                    addRegistroFaltaPeriodoAvaliativo(valueRegistro, valueItemEducacional,
                                        valueItemEducacional, key);
                                }
                            }
                        });
                    });
                }
            });
            vm.tracker.addPromise(promise);
        }

        function truncar(numero, casasDecimais) {
            return numero < 100 ? $filter('number')(new BigNumber(numero).toFixed(casasDecimais, 1), casasDecimais) : numero;
        }

        function navegaEntrePeriodo(sentido) {
            var periodo = _.findIndex(vm.periodosTurma, {
                'id': vm.periodoAtual.id
            }),
                idPeriodo,
                periodoSelecionado,
                indexPeriodo;

            if (sentido === 'proximo') {
                indexPeriodo = periodo + 1;
                if (indexPeriodo === vm.periodosTurma.length) {
                    indexPeriodo = 0;
                }
            } else if (sentido === 'anterior') {
                if (periodo === 0) {
                    indexPeriodo = vm.periodosTurma.length - 1;
                } else {
                    indexPeriodo = periodo - 1;
                }
            }
            idPeriodo = vm.periodosTurma[indexPeriodo].id;
            periodoSelecionado = vm.periodosTurma[indexPeriodo];

            var promise = frequenciaService.getPeriodosTurma(vm.turma.id);
            promise.then(function () {
                vm.periodoAtual = periodoSelecionado;
            });

            vm.tracker.addPromise(promise);
        }

        function order(predicate) {
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.predicate = predicate;
        }

        function atualizaTotaisEnturmacao(params) {
            var promise = frequenciaService.getTotaisEnturmacao(params.idTurma, params.idEnturmacao);
            promise.then(function (faltas) {
                angular.extend(params.aluno.frequencia, faltas[0]);
            });
        }

        function loadPublicados() {
            frequenciaService.getPublicados(vm.turma.id).then(function (data) {
                vm.publicados = data;
            });
        }

        function publicar(itemEducacional, periodoAvaliativo) {
            var registro = {
                periodoAvaliativo: {
                    id: periodoAvaliativo
                },
                itemEducacional: {
                    id: itemEducacional
                },
                publicavel: {
                    publicado: true
                }
            };
            var promise = frequenciaService.publicar(vm.turma.id, registro);
            promise.then(function (data) {
                vm.publicaveis = data;
            });
        }

        function openModalAbonoJustificativa(aluno) {
            $modal.open({
                templateUrl: 'abono-justificativa/abono-justificativa-list.html',
                controller: 'educacao.abonojustificativa.AbonoJustificativaListCtrl',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    configuracoes: {
                        aluno: aluno,
                        itensEducacionais: vm.itensEducacionais,
                        configuracao: vm.configuracaoFrequencia,
                        turma: vm.turmaClone,
                        periodosAvaliativos: vm.periodosTurma,
                        periodoAtual: vm.periodoAtual,
                        callbackTotais: atualizaTotaisEnturmacao,
                        permissionsPeriodoEncerrado: vm.permissions
                    }
                }
            });
        }

        function addAcompanhamento(aluno) {
            var acompanhamento = {
                estabelecimento: vm.estabelecimento,
                enturmacao: {
                    id: aluno.enturmacao.id,
                    nomeAluno: aluno.enturmacao.matricula.nome
                },
                matricula: {
                    id: aluno.enturmacao.matricula.id
                },
                turma: vm.turmaClone
            };
            $modal.open({
                templateUrl: 'acompanhamento-pedagogico-frequencia.html',
                controller: 'acompanhamentoPedagogico.AcompanhamentoPedagogicoFreqCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    acompanhamento: angular.copy(acompanhamento)
                }
            });
        }

        function openImpressaoDiario() {
            if (!vm.periodoAtual && vm.diario.tipoItemEducacional !== 'ATIVIDADE' && vm.diario.tipoItemEducacional !== 'PERIODO_AVALIATIVO') {
                bfcNotification.publish(
                    'Não foi possível determinar o período para impressão da ficha de frequência. Verifique o calendário escolar da matriz.',
                    'error');
                return;
            }

            $modal.open({
                templateUrl: 'diario-classe/diario-classe-impressao.html',
                controller: 'educacao.diarioclasse.DiarioClasseImpressaoCtrl',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    diario: vm.diario,
                    alunosFiltrados: vm.registros,
                    estabelecimento: vm.estabelecimento,
                    turma: vm.turmaClone,
                    predicate: vm.sortBy.expression,
                    reverse: vm.sortBy.reverse,
                    tituloImpressao: 'Frequência',
                    tituloFicha: 'frequência',
                    tituloColuna: 'Total de faltas',
                    periodoAtual: vm.periodoAtual
                }
            });
        }

        function orderBy(item) {
            if (!_.get(vm.sortBy, 'expression')) {
                vm.sortBy.expression = 'enturmacao.numeroChamada';
            }
            if (vm.sortBy.expression === 'enturmacao.matricula.nome') {
                return ObjectUtilsService.normalize(_.get(item, vm.sortBy.expression));
            }
            return _.get(item, vm.sortBy.expression);
        }

        function getEncerramentosTurma() {
            EncerramentoService.getEncerramentosByTurma(vm.turma)
                .then(function (dado) {
                    vm.encerramentosTurma = dado;
                });
        }

        function hasPermissionFrequenciaPeriodoEncerrado(item) {
            var idPeriodo = _.get(item, 'id');

            if (idPeriodo && !_.get(item,'sigla')) {
                var periodoAvaliativo = _.first(_.filter(vm.encerramentosTurma, 'periodoAvaliativo.id', idPeriodo));
                if (periodoAvaliativo && EncerramentoUtilsService.isStatusEncerrado(periodoAvaliativo)) {
                    return vm.permissions;
                }
            }
            return true;
        }

        function hasPermissionAbonoPeriodoEncerrado() {
            if(_.get(_.first(vm.itensEducacionais), 'sigla')){
                return true;
            }
            if(isAtividade(_.get(vm.turma, 'tipoAtendimento'))){
                return true;
            }
            if (vm.itensEducacionais.length !== vm.encerramentosTurma.length) {
                return true;
            }
            return vm.permissionsAbono;
        }

        function verificaEncerramento(periodoAtual) {
            var idPeriodo = _.get(periodoAtual, 'id');
            if (idPeriodo) {
                var periodoAvaliativo = _.first(_.filter(vm.encerramentosTurma, 'periodoAvaliativo.id', idPeriodo));
                if (periodoAvaliativo && EncerramentoUtilsService.isStatusEncerrado(periodoAvaliativo)) {
                    vm.disableInput = !vm.permissions;
                    vm.disableInputAbono = !vm.permissionsAbono;
                    return;
                }
            }
            vm.disableInput = vm.disableInputAbono = false;
        }

        function createFilterSituacao() {
            function onClick(value) {
                _.set(vm.searchFilter, 'others.situacaoMatricula', value);
            }
            var options = {
                id: 'situacao',
                defaultValues: ['CURSANDO'],
                pathOrder: 'description',
                filtrable: true,
                label: 'Situação',
                clickMethod: onClick,
                pathDescription: 'description',
                pathValue: 'id',
                selectAll: true
            };
            HeaderFilterBuilderService.createCheckbox(vm.enumSituacaoMatricula.values, vm.models, vm.headerFilterOptions, options);
        }

        function createFilterPeriodo(data) {
            var options = {
                id: 'periodos',
                description: 'Períodos',
                filtrable: false,
                formatDescription: formatResultPeriodo,
                defaultValue: getPeriodoAtual(),
                onClick: changeFilterPeriodo,
                hideClear: true
            };
            HeaderFilterBuilderService.createRadiobox(data, vm.headerFilterOptions, vm.models, options);
        }

        function formatResultPeriodo(value) {
            if (!value) {
                return null;
            }

            if (!value.dataInicial || !value.dataFinal) {
                return value.descricao;
            }

            var dateFormat = 'dd/MM/yyyy';
            return value.descricao + ' (' + $filter('date')(value.dataInicial, dateFormat) +
            ' até ' + $filter('date')(value.dataFinal, dateFormat) + ')';
        }

        function getPeriodoAtual() {
            return _.find(vm.periodosTurma, function (obj, index) {
                return (moment().isAfter(obj.dataInicial) &&
                        moment().isBefore(obj.dataFinal)) ||
                        (vm.periodosTurma.length === (index + 1));
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.AlunoService', Service);

    Service.$inject = ['ServiceBuilder', 'Restangular'];

    function Service(ServiceBuilder, Restangular) {
        var self = ServiceBuilder
            .create()
            .path('pessoas', 'aluno')
            .enum('CertidaoCivil',
                'CorOlhos',
                'DiaDaSemanaDto',
                'EstadoCivil',
                'GrauInstrucao',
                'TipoSanguineo',
                'HabilidadeMotora',
                'JustificativaFaltaDocumentacao',
                'ModeloCertidao',
                'Nacionalidade',
                'NaturezaFiliacao',
                'OrientacaoSexual',
                'Parentesco',
                'Raca',
                'RecursoAvaliacaoInep',
                'Sexo',
                'SituacaoTrabalho',
                'TipoFiliacao',
                'TipoVistoEstrangeiro',
                'UF')
            .build();

        self.getMatriculas = getMatriculas;
        self.getHistoricos = getHistoricos;
        self.getEstatisticas = getEstatisticas;
        self.getHistoricosEscolares = getHistoricosEscolares;
        self.getMatriculasHistoricosEscolares = getMatriculasHistoricosEscolares;
        self.getPessoas = getPessoas;
        self.camposAdicionais =camposAdicionais;
        self.getBuscaAlunos = getBuscaAlunos;
        self.getPesquisaMatriculas = getPesquisaMatriculas;
        self.getAlunoToListaEspera = getAlunoToListaEspera;

        return self;

        /**
        *   Consulta COM contexto de aplicação
        */
        function getMatriculas(alunoId, params) {
            return Restangular.all('pessoa')
                .one('aluno', alunoId)
                .all('matriculas').get('', params || {});
        }

        function getAlunoToListaEspera(idAluno){
            return Restangular.all('pessoas')
                .one('aluno', idAluno)
                .one('inscricao-lista-espera-matricula')
                .get('')
                .then(Restangular.stripRestangular);
        }

        /**
        *   Consulta SEM contexto de aplicação
        */
        function getPesquisaMatriculas(alunoId, params) {
            return Restangular.all('pessoa')
                .one('aluno', alunoId)
                .all('pesquisa-matriculas').get('', params || {});
        }

        function getMatriculasHistoricosEscolares(alunoId, params) {
            return Restangular.all('pessoa')
                .one('aluno', alunoId)
                .all('historico-escolar/matriculas')
                .get('', params || {
                    limit: 50
                });
        }

        function getHistoricosEscolares(alunoId, params) {
            return Restangular.all('pessoa')
                .one('aluno', alunoId)
                .all('historico-escolar/historicos')
                .get('', params || {
                    limit: 50
                });
        }

        function getHistoricos(alunoId, params) {
            return Restangular.all('pessoa')
                .one('aluno', alunoId)
                .all('historicos').get('', params || {});
        }

        function getEstatisticas() {
            return self.getEndpoint().one('estatisticas')
                .get();
        }

        function getBuscaAlunos(filter, offset, limit){
            return self.getEndpoint()
                .one('aluno-matricula')
                .get({
                    filter: filter,
                    offset: offset,
                    limit: limit
                });
        }

        function getPessoas(pessoa) {
            pessoa = pessoa.replace(/\.|-/g, '');
            return Restangular.one('pessoa/pessoas/template/fisica')
                .get({
                    query: pessoa
                });
        }

        function camposAdicionais(alunoId){
            return Restangular.one('pessoas/aluno/campos-adicionais', alunoId)
                .get()
                .then(Restangular.stripRestangular);
        }
    }
})();


(function () {
    'use strict';

    var suiteEnviropment = window.___bth.envs.suite;

    angular.module('app')
        .constant('APP_URL_BASE','https://educacao.test.betha.cloud/')
        .constant('API_URL', 'https://api.educacao.test.betha.cloud/educacao/api')
        .constant('URL_BASE_GERENCIADOR_RELATORIOS_API', 'https://plataforma-relatorios.test.betha.cloud')
        .constant('URL_BASE_GERENCIADOR_SCRIPTS_API', 'https://plataforma-scripts.test.betha.cloud')
        .constant('URL_API', 'https://api.educacao.test.betha.cloud/educacao/api')
        .constant('URL_APP_COMPROVANTE', 'https://lista-espera-online.test.betha.cloud')
        .constant('APP_API_URL', 'https://api.educacao.test.betha.cloud/educacao/api')
        .constant('URL_RELATORIO', 'https://api.educacao.test.betha.cloud/educacao/relatorio')
        .constant('SYSTEM_ID', 63 +0)
        .constant('URL_MY_PREFERENCES_API', 'https://api.educacao.test.betha.cloud/my-preferences/api')
        .constant('URL_LIBERACAO_ENTIDADE', 'https://suite.test.plataforma.betha.cloud/#/liberacoes/entidade')
        .constant('URL_CENTRAL_USUARIO', 'https://cdn.test.betha.cloud/centraldousuario/editarusuario.faces')
        .constant('URL_SUITE_BETHA', 'https://suite.test.plataforma.betha.cloud')
        .constant('USER_ACCOUNTS_URL', suiteEnviropment['autorizacoes-ui'].stable.host)
        .constant('ANALYTICS_ID', '')
        .constant('URL_FEEDBACK_API', 'https://feedback.test.betha.cloud/feedback/api')
        .constant('URL_VERIFY', 'https://login-session-verifier.test.betha.cloud/api/verify')
        .constant('REGEX_PATH_ATENDIMENTO', 'servicos/atendimento')
        .constant('REGEX_PATH_EXECUTAR_RELATORIO', '/educacao/api/commons/integracao/relatorio/[^/]+/executar')
        .constant('OCULTAR_EVASAO', '3304,3306,3307,3309')
        .constant('SHOW_BANNERS', 'false')
        .constant('APP_STAGE', 'test')
        .constant('ENTIDADES_CONCEITOS_POR_PERIODO', '10683,1')
        .constant('URL_SUITE_LICENSE',  suiteEnviropment.licenses.v1.host)

        .constant('LOADING_DELAY', 50)
        .constant('LOADING_TIMEOUT', 60000)
        .constant('NOTIFICATION_TIMEOUT', 5000)
        .constant('UNDO_TIMEOUT', 8000)
        
        // Censo
        .constant('EDUCACENSO_LAYOUT_ANO_LETIVO', '2024')
        .constant('EDUCACENSO_LAYOUT_DATA_DE_REFERENCIA', '29/05/2024')
        .constant('EDUCACENSO_ETAPAS', {PRIMEIRA_ETAPA: 'PRIMEIRA', SEGUNDA_ETAPA: 'SEGUNDA'})
        .constant('EDUCACENSO_URL_DOWNLOAD_RESULTADO', suiteEnviropment['plataforma-execucoes'].v1.host);

})();

(function() {

    'use strict';

    angular.module('educacao.entidade')
        .controller('educacao.entidade.EntidadeCtrl', EntidadeController);

    EntidadeController.$inject = [
        '$scope',
        'ui.components.Modal',
        'promiseTracker',
        'bfc.Notification',
        '$modalInstance',
        'educacao.entidade.EntidadeService'
    ];

    function EntidadeController($scope, uiModal, promiseTracker, Notification, $modalInstance, EntidadeService) {

        var vm = this;

        vm.openModalEndereco = openModalEndereco;
        vm.save = save;

        vm.tracker = {
            getEntidade: promiseTracker(),
            save: promiseTracker(),
            loading: promiseTracker(),
            camposAdicionais: promiseTracker()
        };

        init();
        
        function initCamposAdicionais() {
			var entidadeId = _.get(vm.entidade, 'id');
			var promise = EntidadeService.camposAdicionais(entidadeId)
				.then(function (data) {
					vm.campoAdicional = data;
                });			
            vm.tracker.loading.addPromise(promise);
			vm.tracker.camposAdicionais.addPromise(promise);
		}

        function init() {

            var promise = EntidadeService.get('')
                .then(function(dados) {
                    return EntidadeService.getEntidadeSuite().then(function(entidadeSuite) {
                        entidadeSuite = entidadeSuite.data;

                        vm.entidade = dados;
                        vm.entidade.nome = entidadeSuite.nome;
                        vm.entidade.cnpj = entidadeSuite.cnpj;
                        vm.entidade.esfera = _.get(entidadeSuite, 'esfera.description');
                        vm.entidade.sigla = entidadeSuite.sigla || vm.entidade.sigla;
                        vm.entidade.municipio = entidadeSuite.municipio;

                        initCamposAdicionais();                        
                    });
                });

            vm.tracker.getEntidade.addPromise(promise);
        }

        

        function openModalEndereco() {
            uiModal.open({
                    templateUrl: 'entidade/endereco/modal-endereco-entidade.html',
                    controller: 'educacao.entidade.ModalEndereco as vm',
                    params: {
                        entidade: vm.entidade
                    },
                    size: 'lg'
                })
                .result.then(function(dadosEntidade) {
                    vm.entidade = dadosEntidade;
                });
        }

        function save(entidade) {
            vm.entidade.campoAdicional = vm.campoAdicional;
            var promise = EntidadeService.saveEntidade(entidade).then(function() {
                if (!vm.file) {
                    onSaveEntidade();
                    return;
                }
                return EntidadeService.setBrasoOfEntidadeSuite(vm.file)
                .then(onSaveEntidade);
            });

            vm.tracker.save.addPromise(promise);
        }

        function onSaveEntidade() {
            Notification.publish('Entidade salva com sucesso', 'success');
            $modalInstance.close();
        }
    }

})();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('appSelectCargosNaoLecionaGestaoPesssoas', SelectCargoNaoLecionaDirective);

    function SelectCargoNaoLecionaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select placeholder="placeholder"  ngclass="ngclass" ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" ng-change="ngChange()" search="vm.search"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                placeholder: '=',
                ngclass: '='
            },
            controller: SelectCargoNaoLecionaDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectCargoNaoLecionaDirectiveController.$inject = ['$scope', 'educacao.funcionario.CargoService'];

    function SelectCargoNaoLecionaDirectiveController($scope, service) {

        var vm = this;

        vm.search = search;

        function search(params) {

            return service.list('(descricao like "' + params.term + '")', params.offset, params.limit);

        }
    }
})();


(function () {
    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.ConfiguracoesCtrl', ConfiguracoesCtrl);

    ConfiguracoesCtrl.$inject = [
        '$q',
        '$modalTracker',
        'educacao.context.ContextoEstabelecimento',
        '$modalInstance',
        'promiseTracker',
        'educacao.matriz.HorasAulasSelect',
        'educacao.matricula.ConfiguracaoTurmasService',
        'ui.components.Modal',
        'educacao.matricula.HorasAulaService',
        '$enumsCache'
    ];

    function ConfiguracoesCtrl($q,
                               $modalTracker,
                               ContextoEstabelecimento,
                               $modalInstance,
                               promiseTracker,
                               HorasAulasSelect,
                               ConfiguracaoTurmasService,
                               $modal,
                               HorasAulaService,
                               $enumsCache) {
                
        var vm = this,
            faixaEtaria = [],
            ensinoFundamental = [],
            ensinoMedio = [],
            turno = [],
            removeConfiguracao = false,
            idFromRemoverConfiguracaoEstabelecimento,
            tipos = {
                'infantil': copiaValoresCelulaEducacaoInfantil,
                'fundamental': copiaValoresCelulaEnsinoFundamental,
                'medio': copiaValoresCelulaEnsinoMedio
            },
            ENUM_ORDENACAO_ALUNOS_DEFAULT = 'NUMERO_CHAMADA';

        vm.openTableHorasAulas = openTableHorasAulas;

        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.save = save;
        vm.tracker = {
            loading: promiseTracker(),
            loadingBottons: promiseTracker()
        };
        vm.limpaCamposEnsinoMedio = limpaCamposEnsinoMedio;
        vm.limpaCamposEnsinoInfantil = limpaCamposEnsinoInfantil;
        vm.limpaCamposEnsinoFundamental = limpaCamposEnsinoFundamental;
        vm.resetConfiguracao = resetConfiguracao;
        vm.copiaValoresCelulaEducacaoInfantil = copiaValoresCelulaEducacaoInfantil;
        vm.copiaValoresCelulaEnsinoFundamental = copiaValoresCelulaEnsinoFundamental;
        vm.copiaValoresCelulaEnsinoMedio = copiaValoresCelulaEnsinoMedio;
        vm.copiaValores = copiaValores;
        init();

        function init() {
            $modalTracker.addPromise(
                $q.all([
                    ConfiguracaoTurmasService.getConfiguracao(vm.hasEstabelecimento)
                        .then(setConfiguracao),
                    ConfiguracaoTurmasService.getEnums().then(function (enums) {
                        faixaEtaria = enums.FaixaEtaria;
                        ensinoFundamental = enums.EquivalenciaEtapa;
                        ensinoMedio = enums.EquivalenciaEtapa;
                        turno = enums.Turno;
                    })
                ]).then(postInit)
            );

            $enumsCache.get('ConfiguracaoOrdenacaoAlunos').then(function (enumData) {
                vm.configuracaoOrdenacaoAlunos = enumData;
                return enumData;
            });
        }

        function postInit() {
            vm.horasAulas = HorasAulasSelect.select2Config();
            if(!vm.configuracao){
                return; 
            }
            createListEnsinoInfantil(faixaEtaria);
            createListEnsinoFundamental(ensinoFundamental);
            createListEnsinoMedio(ensinoMedio);
            createListTurno(turno);
            verificaEditacaoQuantidadeMaxAlunos();
            setOrdenacaoAlunosDefault();
        }

        function resetConfiguracao() {
            removeConfiguracao = true;
            idFromRemoverConfiguracaoEstabelecimento = vm.configuracao.id;
            vm.tracker.loading.addPromise(
                $q.all([
                    ConfiguracaoTurmasService.getConfiguracaoSecretaria().then(setConfiguracao),
                    ConfiguracaoTurmasService.getEnums().then(function (enums) {
                        faixaEtaria = enums.FaixaEtaria;
                        ensinoFundamental = enums.EquivalenciaEtapa;
                        ensinoMedio = enums.EquivalenciaEtapa;
                        turno = enums.Turno;
                    })
                ]).then(postInit)
            );
        }

        function setConfiguracao(data) {
            if (data) {
                vm.configuracao = data;
                if (vm.hasEstabelecimento && vm.configuracao.estabelecimento === null) {
                    vm.configuracao.id = null;
                }
            } else {
                ConfiguracaoTurmasService.getDefault().then(
                    function (conf) {
                        vm.configuracao = conf;
                        vm.configuracao.impedeAlterarQuantidadeMaximaAlunos = false;
                        vm.configuracao.impedeNovasTurmasSeExistirVagas = false;
                        postInit();

                    }
                );
            }
        }

        function verificaEditacaoQuantidadeMaxAlunos() {
            vm.bloqueiaEdicaoQtdMaxAlunos = vm.configuracao.impedeAlterarQuantidadeMaximaAlunos && vm.hasEstabelecimento;
        }

        function setOrdenacaoAlunosDefault(){
            if(!vm.configuracao.configuracaoOrdenacaoAlunos){
                vm.configuracao.configuracaoOrdenacaoAlunos = ENUM_ORDENACAO_ALUNOS_DEFAULT;
            }
            return;
        }

        function copiaValores(tipo) {
            return tipos[tipo];
        }

        function copiaValoresCelulaEducacaoInfantil(indexInicio, indexFinal) {
            var valor = vm.listEnsinoInfantil[indexInicio].vinculado.qtdMaximaAlunos;
            var limit;
            if (indexFinal > vm.listEnsinoInfantil.length) {
                limit = vm.listEnsinoInfantil.length;
            } else {
                limit = ++indexFinal;
            }
            for (var i = indexInicio; i < limit; i++) {
                vm.listEnsinoInfantil[i].vinculado = {
                    qtdMaximaAlunos: valor
                };
            }
        }

        function copiaValoresCelulaEnsinoFundamental(indexInicio, indexFinal) {
            var valor = vm.listEnsinoFundamental[indexInicio].vinculado.qtdMaximaAlunos;
            var limit;
            if (indexFinal > vm.listEnsinoFundamental.length) {
                limit = vm.listEnsinoFundamental.length;
            } else {
                limit = ++indexFinal;
            }
            for (var i = indexInicio; i < limit; i++) {
                vm.listEnsinoFundamental[i].vinculado = {
                    qtdMaximaAlunos: valor
                };
            }
        }

        function copiaValoresCelulaEnsinoMedio(indexInicio, indexFinal) {
            var valor = vm.listEnsinoMedio[indexInicio].vinculado.qtdMaximaAlunos;
            var limit;
            if (indexFinal > vm.listEnsinoMedio.length) {
                limit = vm.listEnsinoMedio.length;
            } else {
                limit = ++indexFinal;
            }
            for (var i = indexInicio; i < limit; i++) {
                vm.listEnsinoMedio[i].vinculado = {
                    qtdMaximaAlunos: valor
                };
            }
        }

        function createListTurno(enumValue) {
            vm.listTurno = [];
            angular.forEach(enumValue, function (item) {
                vm.listTurno.push({
                    id: null,
                    configuracaoHorasAulas: null,
                    turno: item
                });
            });
            updateListTurno();
        }

        function updateListTurno() {
            if (_.get(vm.configuracao,'configuracaoHorarios')) {
                angular.forEach(vm.configuracao.configuracaoHorarios, function (repositorio) {

                    angular.forEach(vm.listTurno, function (item) {
                        if (repositorio.turno === item.turno.key) {
                            item.id = repositorio.id;
                            item.configuracaoHorasAulas = repositorio.configuracaoHorasAulas;
                        }
                    });

                });
            }
        }

        function createListEnsinoInfantil(faixa) {
            vm.listEnsinoInfantil = [];
            angular.forEach(faixa, function (item) {
                vm.listEnsinoInfantil.push({
                    id: null,
                    faixaEtaria: item,
                    vinculado: {
                        qtdMaximaAlunos: ''
                    }
                });
            });
            updateListEnsinoInfantil();
        }

        function updateListEnsinoInfantil() {
            if (_.get(vm.configuracao,'configuracaoEducacaoInfantil')) {
                angular.forEach(vm.configuracao.configuracaoEducacaoInfantil, function (repositorio) {

                    angular.forEach(vm.listEnsinoInfantil, function (item) {
                        if (repositorio.faixaEtaria === item.faixaEtaria.key) {
                            item.id = repositorio.id;
                            item.vinculado.qtdMaximaAlunos = repositorio.vinculado.qtdMaximaAlunos;
                        }
                    });

                });
            }
        }

        function limpaCamposEnsinoInfantil() {
            if (vm.bloqueiaEdicaoQtdMaxAlunos) {
                return;
            }
            angular.forEach(vm.listEnsinoInfantil, function (item) {
                item.vinculado = {
                    qtdMaximaAlunos: ''
                };

            });
        }

        function createListEnsinoFundamental(enumValue) {
            vm.listEnsinoFundamental = [];
            angular.forEach(enumValue, function (item) {
                vm.listEnsinoFundamental.push({
                    id: null,
                    equivalenciaEtapa: item,
                    vinculado: {
                        qtdMaximaAlunos: ''
                    }
                });
            });
            updateListEnsinoFundamental();
        }

        function updateListEnsinoFundamental() {
            if (_.get(vm.configuracao,'configuracaoEnsinoFundamental')) {
                angular.forEach(vm.configuracao.configuracaoEnsinoFundamental, function (repositorio) {

                    angular.forEach(vm.listEnsinoFundamental, function (item) {
                        if (repositorio.equivalenciaEtapa === item.equivalenciaEtapa.key) {
                            item.id = repositorio.id;
                            item.vinculado.qtdMaximaAlunos = repositorio.vinculado.qtdMaximaAlunos;
                        }
                    });

                });
            }
        }

        function limpaCamposEnsinoFundamental() {
            if (vm.bloqueiaEdicaoQtdMaxAlunos) {
                return;
            }
            angular.forEach(vm.listEnsinoFundamental, function (item) {
                item.vinculado = {
                    qtdMaximaAlunos: ''
                };
            });
        }

        function createListEnsinoMedio(enumValue) {
            vm.listEnsinoMedio = [];
            var limitador = 0;
            angular.forEach(enumValue, function (item) {
                if (limitador < 4) {
                    vm.listEnsinoMedio.push({
                        id: null,
                        equivalenciaEtapa: item,
                        vinculado: {
                            qtdMaximaAlunos: ''
                        }
                    });
                    limitador++;
                }
            });
            updateListEnsinoMedio();
        }

        function updateListEnsinoMedio() {
            if (_.get(vm.configuracao,'configuracaoEnsinoMedio')) {
                angular.forEach(vm.configuracao.configuracaoEnsinoMedio, function (repositorio) {

                    angular.forEach(vm.listEnsinoMedio, function (item) {
                        if (repositorio.equivalenciaEtapa === item.equivalenciaEtapa.key) {
                            item.id = repositorio.id;
                            item.vinculado.qtdMaximaAlunos = repositorio.vinculado.qtdMaximaAlunos;
                        }
                    });

                });
            }
        }

        function limpaCamposEnsinoMedio() {
            if (vm.bloqueiaEdicaoQtdMaxAlunos) {
                return;
            }
            angular.forEach(vm.listEnsinoMedio, function (item) {
                item.vinculado = {
                    qtdMaximaAlunos: ''
                };
            });
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function editToSave() {
            var removeId = false;

            //TODO: Remover quando for removido o campo separacaoAlunosEmGrupos da tabela na base
            if (vm.configuracao.separacaoAlunosEmGrupos === null) {
                vm.configuracao.separacaoAlunosEmGrupos = false;
            }

            if (vm.hasEstabelecimento) {
                if (vm.configuracao.estabelecimento === null) {
                    vm.configuracao.id = null;
                    removeId = true;
                }

                vm.configuracao.estabelecimento = {
                    id: vm.estabelecimento.id,
                    description: vm.estabelecimento.pessoa.nome
                };

            }

            editToSaveListEnsinoFundamental(removeId);
            editToSaveListEnsinoMedio(removeId);
            editToSaveListEnsinoInfantil(removeId);
            editToSaveListTurno(removeId);
        }

        function editToSaveListTurno(removeId) {
            vm.configuracao.configuracaoHorarios = [];
            angular.forEach(vm.listTurno, function (item) {
                if (item.configuracaoHorasAulas !== null && !angular.isUndefined(item.configuracaoHorasAulas.id)) {
                    if (removeId) {
                        vm.configuracao.configuracaoHorarios.push({
                            id: null,
                            turno: item.turno.key,
                            configuracaoHorasAulas: {
                                id: item.configuracaoHorasAulas.id,
                                description: item.configuracaoHorasAulas.descricao
                            }
                        });
                    } else {
                        vm.configuracao.configuracaoHorarios.push({
                            id: item.id,
                            turno: item.turno.key,
                            configuracaoHorasAulas: {
                                id: item.configuracaoHorasAulas.id,
                                description: item.configuracaoHorasAulas.descricao
                            }
                        });
                    }
                }
            });
        }

        function editToSaveListEnsinoFundamental(removeId) {
            vm.configuracao.configuracaoEnsinoFundamental = [];
            angular.forEach(vm.listEnsinoFundamental, function (item) {
                if (angular.isNumber(item.vinculado.qtdMaximaAlunos)) {
                    if (removeId) {
                        vm.configuracao.configuracaoEnsinoFundamental.push({
                            id: null,
                            equivalenciaEtapa: item.equivalenciaEtapa.key,
                            vinculado: {
                                qtdMaximaAlunos: item.vinculado.qtdMaximaAlunos
                            }
                        });
                    } else {
                        vm.configuracao.configuracaoEnsinoFundamental.push({
                            id: item.id,
                            equivalenciaEtapa: item.equivalenciaEtapa.key,
                            vinculado: {
                                qtdMaximaAlunos: item.vinculado.qtdMaximaAlunos
                            }
                        });
                    }
                }
            });
        }

        function editToSaveListEnsinoMedio(removeId) {
            vm.configuracao.configuracaoEnsinoMedio = [];
            angular.forEach(vm.listEnsinoMedio, function (item) {
                if (angular.isNumber(item.vinculado.qtdMaximaAlunos)) {
                    if (removeId) {
                        vm.configuracao.configuracaoEnsinoMedio.push({
                            id: null,
                            equivalenciaEtapa: item.equivalenciaEtapa.key,
                            vinculado: {
                                qtdMaximaAlunos: item.vinculado.qtdMaximaAlunos
                            }
                        });
                    } else {
                        vm.configuracao.configuracaoEnsinoMedio.push({
                            id: item.id,
                            equivalenciaEtapa: item.equivalenciaEtapa.key,
                            vinculado: {
                                qtdMaximaAlunos: item.vinculado.qtdMaximaAlunos
                            }
                        });

                    }
                }
            });
        }

        function editToSaveListEnsinoInfantil(removeId) {
            vm.configuracao.configuracaoEducacaoInfantil = [];
            angular.forEach(vm.listEnsinoInfantil, function (item) {
                if (angular.isNumber(item.vinculado.qtdMaximaAlunos)) {
                    if (removeId) {
                        vm.configuracao.configuracaoEducacaoInfantil.push({
                            id: null,
                            faixaEtaria: item.faixaEtaria.key,
                            vinculado: {
                                qtdMaximaAlunos: item.vinculado.qtdMaximaAlunos
                            }
                        });
                    } else {
                        vm.configuracao.configuracaoEducacaoInfantil.push({
                            id: item.id,
                            faixaEtaria: item.faixaEtaria.key,
                            vinculado: {
                                qtdMaximaAlunos: item.vinculado.qtdMaximaAlunos
                            }
                        });

                    }
                }
            });
        }

        function save() {
            editToSave();
            if (removeConfiguracao && idFromRemoverConfiguracaoEstabelecimento) {
                vm.configuracao.id = idFromRemoverConfiguracaoEstabelecimento;
                vm.tracker.loadingBottons.addPromise(
                    ConfiguracaoTurmasService.save(vm.configuracao).then(function () {
                        fechaModal();
                    })
                );
            } else {
                vm.tracker.loadingBottons.addPromise(
                    ConfiguracaoTurmasService.save(vm.configuracao).then(function () {
                        fechaModal();
                    })
                );
            }
        }

        function openTableHorasAulas(id) {
            return $modal.open({
                templateUrl: 'common/parametros/configuracao-turmas/configuracao.turmas.modal.template.html',
                controller: 'educacao.common.ConfiguracoesModalVisualizacaoHorasAulasCtrl as vm',
                size: 'lg',
                params: {
                    configuracaoHorasAulas: HorasAulaService.get(id),
                    exibeEdicao: true
                }
            }).result;
        }
    }
})();

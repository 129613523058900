(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaMensagem', appConfiguracaoListaEsperaMensagem);

    function appConfiguracaoListaEsperaMensagem() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-mensagem/app-configuracao-lista-espera-mensagem.directive.html',
            scope: {},
            bindToController: {
                configuracao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'ui.components.Modal'
    ];

    function Controller(uiModal) {
        var vm = this;

        vm.opemViewMensagem = opemViewMensagem;

        function opemViewMensagem() {
            uiModal.open({
                templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-mensagem/app-configuracao-lista-espera-visualizar-mensagem/app-configuracao-lista-espera-visualizar-mensagem.html',
                controller: 'educacao.lista-espera-novo.configuracao.appConfiguracaoListaEsperaVisualizarMensagem',
                size:'lg',
                params: {
                    mensagem: vm.configuracao.mensagem,
                    ocultarPosicao: vm.configuracao.ocultarPosicao
                }
            });
        }


    }
})();

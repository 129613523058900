(function () {
    'use strict';

    var STATUS_INSCRITO = 'INSCRITO';

    angular
        .module('educacao.lista-espera')
        .controller('educacao.lista-espera.ConfirmacaoInscricaoController', Controller);

    Controller.$inject = [
        'PROPRIEDADE_MODALIDADE',
        'ui.components.PrintService',
        'educacao.lista-espera.InscricaoListaEsperaService',
        '$modalTracker',
        'configuracao',
        'id'
    ];
    function Controller(PROPRIEDADE_MODALIDADE, PrintService, InscricaoListaEsperaService, $modalTracker, configuracao, idInscricao) {
        var enumTurnos;
        var enumNivelEscolar;
        var enumEquivalenciaEtapa;

        var vm = this;

        vm.configuracao = configuracao;
        vm.print = print;

        $modalTracker.addPromise(
            InscricaoListaEsperaService.getTurno().then(function (data) {
                enumTurnos = data;
            })
        );
        $modalTracker.addPromise(
            InscricaoListaEsperaService.getNivelEscolar().then(function (data) {
                enumNivelEscolar = data;
            })
        );
        $modalTracker.addPromise(
            InscricaoListaEsperaService.getEquivalenciaEtapa().then(function (data) {
                enumEquivalenciaEtapa = data;
            })
        );
        $modalTracker.addPromise(
            InscricaoListaEsperaService
                .get(idInscricao)
                .then(function (data) {
                    vm.inscricao = data;
                    return data;
                })
                .then(criaModalidadeEscolaTurno)
        );

        function criaModalidadeEscolaTurno(inscricao) {
            var MOSTRAR_TELA = {};

            _.forEach(inscricao.modalidades, function (modalidades, keyModalidades) {
                _.forEach(modalidades, function (inscricaoModalidade) {
                    if (inscricaoModalidade.status !== STATUS_INSCRITO) {
                        return;
                    }
                    _.forEach(inscricaoModalidade.estabelecimentos, function (estabelecimentoTurno) {
                        if (estabelecimentoTurno.status !== STATUS_INSCRITO) {
                            return;
                        }
                        var modalidade = MOSTRAR_TELA[keyModalidades] = MOSTRAR_TELA[keyModalidades] || {
                                label: PROPRIEDADE_MODALIDADE[keyModalidades],
                                inscricoesModalidades: {}
                            };

                        var keyModalidadeEstabelecimento = inscricaoModalidade.id + '#' + estabelecimentoTurno.estabelecimento.id;

                        var inscricaoModalidadeEstabelecimento = modalidade.inscricoesModalidades[keyModalidadeEstabelecimento] = modalidade.inscricoesModalidades[keyModalidadeEstabelecimento] || ({
                                nivelEscolar: getNivelEscolar(inscricaoModalidade),
                                equivalencia: getEquivalencia(inscricaoModalidade),
                                curso: getCurso(inscricaoModalidade),
                                atividade: getAtividade(inscricaoModalidade),
                                estabelecimento: estabelecimentoTurno.estabelecimento.descricao,
                                turnos: []
                            });

                        inscricaoModalidadeEstabelecimento.turnos.push(enumTurnos[estabelecimentoTurno.turno].description);
                    });
                });
            });
            vm.MOSTRAR_TELA = MOSTRAR_TELA;
        }

        function getNivelEscolar(inscricaoModalidade) {
            return inscricaoModalidade.nivelEscolar && enumNivelEscolar[inscricaoModalidade.nivelEscolar].description;
        }

        function getEquivalencia(inscricaoModalidade) {
            return inscricaoModalidade.equivalencia && enumEquivalenciaEtapa[inscricaoModalidade.equivalencia].description;
        }

        function getCurso(inscricaoModalidade) {
            return (inscricaoModalidade.curso || {}).descricao;
        }

        function getAtividade(inscricaoModalidade) {
            return (inscricaoModalidade.atividade || {}).descricao;
        }

        function print() {
            PrintService.print('#comprovanteImpressao');
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.TurmaMultisseriadaService', Service);

    Service.$inject = ['Restangular'];


    function Service(Restangular) {
        var endpoint = Restangular.all('matricula/turma-multisseriada');

        return {
            listByEstabelecimento: listByEstabelecimento,
            save: save,
            remove: remove
        };

        function listByEstabelecimento(idEstabelecimento) {
            return endpoint.getList({
                    idEstabelecimento: idEstabelecimento
                })
                .then(Restangular.stripRestangular);
        }

        function save(turmas) {
            return endpoint.customPUT(turmas);
        }
        
        function remove(turmaMultisseriada) {
            return endpoint.one(String(turmaMultisseriada.id)).remove();
        }
    }
})();
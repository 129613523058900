(function () {
    'use strict';

    angular
        .module('educacao.inep')
        .directive('appSelectEducacaoProfissionalInep', Directive);



    function Directive() {

        var directive = {
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-change="ngChange()" ng-required="ngRequired" format-result="vm.formatResult(data)" format-selection="vm.formatSelection(data)" search="vm.search" />',
            restrict: 'E',
            scope: {
                ngModel: '=',
                ngDisabled: '=',
                dropdownCssClass: '=',
                ngChange: '&?',
                formatResult: '&?',
                formatSelection: '&?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };

        return directive;

    }

    Controller.$inject = ['$scope', 'Restangular'];
    function Controller($scope, Restangular) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;

        function search(params) {
            var filter = '(descricao like "' + params.term + '")';
            return Restangular.one('inep', 'curso-educacao-profissional')
                .get({
                    filter: filter,
                    offset: params.offset,
                    limit: params.limit
                });
        }

        function formatResult(data) {
            if (_.isFunction($scope.formatResult)) {
                return $scope.formatResult({
                    data: data
                });
            }

            return data.eixo + ' - '+ data.idCurso +' - ' + data.curso;
        }

        function formatSelection(data) {
            if (_.isFunction($scope.formatSelection)) {
                return $scope.formatSelection({
                    data: data
                });
            }

            return data.eixo + ' - '+ data.idCurso +' - ' + data.curso;
        }

    }


})();
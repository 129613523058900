(function () {
  'use strict';

  angular
    .module('educacao.educacenso')
    .factory(
      'educacao.educacenso.EducacensoExecucaoInconsistenciaService',
      EducacensoExecucaoInconsistenciaService
    );

  EducacensoExecucaoInconsistenciaService.$inject = ['ServiceBuilder'];

  function EducacensoExecucaoInconsistenciaService(ServiceBuilder) {
    var service = ServiceBuilder.create()
      .path('commons')
      .path('educacenso-execucao-inconsistencia')
      .build();

    service.startProcessoValidacaoMultiplos = startProcessoValidacaoMultiplos;

    function startProcessoValidacaoMultiplos(
      estabelecimentosSelecionados,
      dividirTurmasMultiturno,
      etapaAtual
    ) {
      return service
        .getEndpoint()
        .one('multiplos')
        .customPOST({
          estabelecimentos: estabelecimentosSelecionados,
          dividirTurmasMultiturno: dividirTurmasMultiturno,
          etapaCenso: etapaAtual
        });
    }

    return service;
  }
})();

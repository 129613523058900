(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.SelectDisciplinaInepService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {

        var selfService = ServiceBuilder.create()
            .path('inep', 'disciplinas-inep')
            .build();

        return selfService;
    }
})();

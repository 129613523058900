(function() {
	'use strict';

	angular.module('educacao.matriz')
		.factory('educacao.matriz.ObjetivoAprendizagemService', Service);

	Service.$inject = ['$injector'];

	function Service($injector) {

		var ServiceBuilder = $injector.get('ServiceBuilder');

		var service = ServiceBuilder.create()
			.path('objetivo-aprendizagem')
			.build();

		service.getDescricoesRecentes = getDescricoesRecentes;
		service.getObjetivosAprendizagemMatriz = getObjetivosAprendizagemMatriz;

		return service;

		function getDescricoesRecentes() {
			return service.getEndpoint().get('descricoes-recentes');
		}

		function getObjetivosAprendizagemMatriz(etapaMatrizDisciplinaId) {
            return service.getEndpoint().one('objetivos-aprendizagem-matriz').get({
                etapaMatrizDisciplinaId: etapaMatrizDisciplinaId
            });
        }
	}
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .run(['Upload', function(Upload) {
            Upload.setDefaults({
                ngfMaxSize: '10MB'
            });
        }]);
})();

(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.EstabelecimentosCtrl', EstabelecimentosCtrl);

    EstabelecimentosCtrl.$inject = [
        'educacao.common.ModalCad',
        'promiseTracker',
        'educacao.pessoas.EstabelecimentoService',
        'bfc.Notification',
        '$scope',
        'educacao.context.ContextoEstabelecimento'
    ];

    function EstabelecimentosCtrl(ModalCad, promiseTracker, EstabelecimentoService, Notification, $scope, ContextoEstabelecimento) {
        var viewModel = this;
        var vm = this;

        var _estabelecimentos = [];

        var _avisos = [];

        viewModel.tracker = {};
        viewModel.tracker.list = promiseTracker();
        viewModel.cnpjCustomBuilder = cnpjCustomBuilder;

        viewModel.service = EstabelecimentoService;
        viewModel._agruparAvisos = _agruparAvisos;

        viewModel.parametrosGerenciadorRelatorios = {};

        vm.isEstabelecimento = isEstabelecimento;        

        $scope.$watchCollection('vm.selectedItens', atualizarEstabelecimentosGerenciador);

        var propriedadesPublicas = {
            search: {
                value: '',
                isSearching: function () {
                    return Boolean(viewModel.search && (viewModel.search.filter.$ || viewModel.search.filter.avisos));
                }
            },
            remove: _remove,
            estabelecimentos: _estabelecimentos,
            avisos: _avisos,
            openCadEscola: _openCadEscola
        };

        viewModel.advancedFilter = {
            informacoesGerais: {},
            areaDeAtuacao: {},
            infraestrutura: {},
            dependenciasFisicas: {},
            equipeDiretiva: {}
        };

        viewModel.expressionCustomBuilders = {
            dataInicial: function (filters) {
                if (filters.dataInicial) {
                    return 'dataRegistro >= ' + filters.dataInicial;
                }
            },
            dataFinal: function (filters) {
                if (filters.dataFinal) {
                    return 'dataRegistro <= ' + filters.dataFinal;
                }
            }
        };

        _.extend(viewModel, propriedadesPublicas);

        function _agruparAvisos(estabelecimentos) {

            viewModel.avisos = [];

            angular.forEach(estabelecimentos, function (data) {
                var alerts = errosFrom(data);
                if (alerts.length > 0) {
                    viewModel.avisos.push(alerts);

                }
            });

        }

        function errosFrom(entity) {
            if (entity) {
                return _.flatten(_.values(entity.avisos), true);
            }
            return [];
        }

        function _remove(estabelecimento) {
            EstabelecimentoService.remove(estabelecimento).then(notificarExclusao);
        }

        function notificarExclusao() {
            Notification.publish('Estabelecimento excluído com sucesso', 'success');
        }

        function cnpjCustomBuilder(filters) {
            return '(cnpj elike \'' + filters.replace(/[^\d]+/g,'') + '\')';
        }

        function _openCadEscola(id) {
            ModalCad.open({
                templateUrl: 'planejamento/escolas/escolas.cad.html',
                controller: 'educacao.pessoas.EstabelecimentoCadCtrl as vm',
                id: id,
                readOnly: false
            });
        }

        function atualizarEstabelecimentosGerenciador() {
            viewModel.parametrosGerenciadorRelatorios = {};
            if (vm.selectedItens) {
                var ids = _.map(vm.selectedItens, 'id');
                viewModel.parametrosGerenciadorRelatorios = { estabelecimentos: ids };
            }
        }

        function isEstabelecimento() {
            return !ContextoEstabelecimento.isSecretaria();
        }
    }
})();

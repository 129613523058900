(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.calendario');

    moduloCommon.controller('educacao.calendario.EventoCtrl', ['$scope',
        '$rootScope',
        'educacao.common.ModalCad',
        'educacao.common.ObjectUtilsService',
        'educacao.calendario.EventoService',
        'promiseTracker',
        function ($scope,
            $rootScope,
            ModalCad,
            ObjectUtilsService,
            EventoService,
            promiseTracker
        ) {

            $scope.eventos = [];
            $scope.tracker = {};
            $scope.tracker.list = promiseTracker();

            //constant
            $scope.OUTROS = 7;

            $scope.open = function (id) {
                ModalCad.open({
                    templateUrl: 'calendario/evento/evento.cad.html',
                    controller: 'educacao.calendario.EventoCadCtrl',
                    id: id,
                    readOnly: false
                });
            };

            var refresh = function () {
                $scope.tracker.list.addPromise(
                    EventoService.getList(0, 999).then(function (data) {
                        $scope.eventos = data;
                    })
                );
            };

            var init = function () {
                $scope.search = '';

                refresh();
            };

            init();

            $scope.isSearching = function () {
                return Boolean($scope.search);
            };

            $scope.$on('refreshEventos', function (event, args) {
                if (args.data) {
                    if (args.remove) {
                        $scope.eventos = _.reject($scope.eventos, {
                            'id': args.data.id
                        });

                    } else if (args.add) {
                        $scope.eventos.push(args.data);

                    } else if (args.undo || args.edit) {
                        var evento = _.find($scope.eventos, {
                            'id': args.data.id
                        });

                        if (evento) {
                            ObjectUtilsService.copyProperties(evento, args.data);
                        } else {
                            throw new Error('Evento não encontrado! ID: ' + args.data.id);
                        }
                    }
                }
            });

            $scope.remove = function (evento) {
                EventoService.remove(evento.id).then(
                    $rootScope.$broadcast('refreshEventos', {
                        data: evento,
                        remove: true
                    })
                );
            };

            /**
             * Class dos ícones da ordenação
             */
            $scope.getIconSortClass = function (sortBy) {
                return {
                    'icon-caret-down': $scope.predicate === sortBy && $scope.reverse,
                    'icon-caret-up': $scope.predicate === sortBy && !$scope.reverse,
                    'icon-sort': $scope.predicate !== sortBy
                };
            };

            $scope.getDescricaoTipoEvento = function (evento) {
                return evento.tipo.id === $scope.OUTROS ? evento.descricaoOutros : evento.tipo.descricao;
            };

            $scope.$on('refreshContextEntidade', function () {
                init();
            });
        }
    ]);

})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaTable', appConfiguracaoListaEsperaTable);

    function appConfiguracaoListaEsperaTable() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-table/app-configuracao-lista-espera-table.directive.html',
            scope: {},
            bindToController: {
                nivelEscolar: '=',
                tabNivelAtiva: '=',
                tabModalidadeAtiva: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaUtils',
        'bfc.Notification',
        '$scope',
        'CONFIGURACAO_ETAPAS'
    ];

    function Controller(ConfiguracaoListaEsperaUtils, bfcNotification, $scope, CONFIGURACAO_ETAPAS) {
        var vm = this;

        vm.addEtapa = addEtapa;
        vm.deleteEtapa = deleteEtapa;
        vm.verificaSelecaoTodos = verificaSelecaoTodos;
        vm.enumTurno = ConfiguracaoListaEsperaUtils.enumTurno();
        vm.enumEquivalencia = ConfiguracaoListaEsperaUtils.enumEquivalencia();
        vm.configuracaoEtapas = CONFIGURACAO_ETAPAS;

        $scope.$watchGroup(['vm.nivel.confiEtapas', 'vm.tabNivelAtiva', 'vm.tabModalidadeAtiva'], init);
        $scope.$watch('vm.nivel.matriz', watchMatriz);
        $scope.$watch('formTable.$invalid', setFormInvalido);

        function verificaSelecaoTodos(etapa){
            if(_.includes(etapa.turnos, 'TODOS')){
                etapa.turnos = ['TODOS'];
            }
        }

        function watchMatriz(matriz) {
            if (!matriz) {
                return;
            }
            init();
        }

        function init() {
            vm.equivalencias = ConfiguracaoListaEsperaUtils.equivalencias();
            setEtapas();
        }

        function setSugestoes() {
            var numeroEtapas = 10;
            if (vm.tabNivelAtiva === 'ENSINO_MEDIO') {
                numeroEtapas = 5;
            }

            var etapas = _.map(_.range(1, numeroEtapas), createObjEtapa);
            
            return etapas;
        }

        function createObjEtapa(etapa) {
            return {
                descricao: etapa + 'º ano',
                equivalenciaEtapa: ['ETAPA_' + etapa],
                turnos: ['TODOS']
            };
            
        }

        function setFormInvalido(invalido) {
            if (!vm.nivel) {
                return;
            }

            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.CONFORME_MATRIZ_CURRICULAR && !vm.nivel.matriz) {
                vm.nivel.formularioInvalido = true;
                return;
            }

            vm.nivel.formularioInvalido = invalido;
        }

        function addEtapa() {
            vm.nivel.etapas.push({
                descricao: '',
                equivalenciaEtapa: '',
                turnos: []
            });
        }

        function deleteEtapa(etapa) {
            _.remove(vm.nivel.etapas, etapa);
        }

        function setEtapas() {
            vm.nivel = _.find(vm.nivelEscolar, 'nivelEscolar', vm.tabNivelAtiva);

            if (!vm.nivel) {
                return;
            }

            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.CONFORME_MATRIZ_CURRICULAR) {
                vm.nivel.etapas = _.get(vm.nivel, 'matriz.etapas');
                _.forEach(vm.nivel.etapas, function(etapa){
                    etapa.equivalenciaEtapa = etapa.equivalencias;
                    delete etapa.id;
                });
                return;
            }

            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.SUGERIDAS_PELO_SISTEMA) {
                if (!_.isEmpty(vm.nivel.etapas) && vm.nivel.matriz) {
                    vm.nivel.etapas = setSugestoes();
                    vm.nivel.matriz = '';
                    return;
                }
                if (!_.isEmpty(vm.nivel.etapas)) {
                    return;
                }
                vm.nivel.etapas = setSugestoes();
                return;
            }
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edValidateUrl', ['$parse', '$timeout', 'bfc.MessagesField',
            function($parse, $timeout, MessagesField) {
                return {
                    require: '?ngModel',
                    link: postLink
                };

                function postLink(scope, tElement, attrs, ngModel) {

                    if (!ngModel) {
                        throw ('O elemento necessida da utilização do ngModel.');
                    }

                    function checkOnBlur(element, errors) {
                        if (element.data('alreadyAdd')) {
                            return;
                        }

                        element.on('focusin', function() {
                            if (element.val() === '') {
                                element.val('http://');
                            }
                        });

                        element
                            .data('alreadyAdd', true)
                            .on('blur', function() {

                                if (element.val() === 'http://') {
                                    element.val('');
                                }

                                MessagesField.removeMessage(element);

                                for (var type in errors) {
                                    if (!errors.hasOwnProperty(type)) {
                                        continue;
                                    }

                                    var hasError = errors[type];
                                    if (hasError) {
                                        MessagesField.setMessage(element, type, 'Ops, url informada é inválida.');
                                    }

                                }
                            });
                    }

                    checkOnBlur(tElement, ngModel.$error);
                }
            }
        ]);
})();

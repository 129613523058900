(function() {

    'use strict';

    var REQUEST_ERRORS = [503, 502];
    var MESSAGE_ERROR = 'Erro ao realizar a requisição, verifique a sua conexão e recarregue a página.\n';

    angular.module('educacao.common')
        .factory('educacao.common.HttpRequestErrorInterceptor', HttpRequestInterceptor);

    HttpRequestInterceptor.$inject = ['$q', 'bfc.Notification'];

    function HttpRequestInterceptor($q, bfcNotification) {
        return {
            responseError: function(request) {
                function shouldNotificationError(response) {
                    return !isSkipErrorHandling(response);
                }

                function isSkipErrorHandling(response) {
                    return response.config &&
                        response.config.meta &&
                        response.config.meta.skipErrorHandling &&
                        typeof response.config.meta.skipErrorHandling === 'function' && response.config.meta.skipErrorHandling();
                }
                if (!request) {
                    $q.reject(request);
                }

                if (shouldNotificationError(request) && REQUEST_ERRORS.indexOf(request.status) >= 0) {
                    bfcNotification.publish(MESSAGE_ERROR + _.get(request, 'config.url'));
                }
                return $q.reject(request);
            },
        };
    }

})();

(function () {

    /**
     * Diretiva para encapsular conteúdo da aba de habilidades do cadastro de matricula no funcionário
     * Parametros: 
     * matricula -> matricula que está sendo adicionado ou editado 
     */

    'use strict';

    var PREFIX_LIST_NIVEL = '$$list';

    angular.module('educacao.funcionario')
        .directive('appTabFuncionarioMatriculaHabilidades', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-matricula/app-tab-funcionario-matricula-habilidades.directive.html',
            scope: {},
            bindToController: {
                matricula: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.funcionario.FuncionarioService',
        'educacao.matriz.NivelEscolarUtilsService',
        'NIVEL_ESCOLAR'
    ];

    function Controller($scope, FuncionarioService, NivelEscolarUtilsService, NIVEL_ESCOLAR) {

        var vm = this;

        vm.enumNivelEscolar = FuncionarioService.getNivelEscolar();
        vm.enumEquivalenciaEtapa = FuncionarioService.getEquivalenciaEtapa();

        vm.adicionaItem = adicionaItem;
        vm.removeItem = removeItem;

        $scope.$watch('vm.matricula.$$niveisEscolares', watchNiveisEscolares);

        vm.habilitacaoEducaoInfantil = {
            nivelEscolar: NIVEL_ESCOLAR.EDUCACAO_INFANTIL,
            habilitacao: false
        };

        vm.keyEnsinoMedio = PREFIX_LIST_NIVEL + NIVEL_ESCOLAR.ENSINO_MEDIO;
        vm.keyEnsinoFundamental = PREFIX_LIST_NIVEL + NIVEL_ESCOLAR.ENSINO_FUNDAMENTAL;
        vm.keyEducacaoInfantil = PREFIX_LIST_NIVEL + NIVEL_ESCOLAR.EDUCACAO_INFANTIL;

        initController();

        function initController() {
            setListEtapas();

            if(!vm.matricula.funcionarioDisciplinas){
                vm.matricula.funcionarioDisciplinas = [];
            }
        }

        function watchNiveisEscolares(newValue, oldValue) {

            if (newValue === oldValue) {
                return;
            }

            vm.hasEducacaoInfantil = !_.isEmpty(_.find(vm.matricula.$$niveisEscolares, function(item){
               return item === NIVEL_ESCOLAR.EDUCACAO_INFANTIL; 
            }));
            if(vm.hasEducacaoInfantil){
                vm.matricula[vm.keyEducacaoInfantil] = vm.habilitacaoEducaoInfantil;
            }
            
            vm.hasEnsinoFundamental = !_.isEmpty(_.find(vm.matricula.$$niveisEscolares, function(item){
               return item === NIVEL_ESCOLAR.ENSINO_FUNDAMENTAL; 
            }));

            vm.hasEnsinoMedio = !_.isEmpty(_.find(vm.matricula.$$niveisEscolares, function(item){
               return item === NIVEL_ESCOLAR.ENSINO_MEDIO; 
            }));
        }

        function setListEtapas() {

            vm.matricula.$$niveisEscolares = _.chain(vm.matricula.funcionarioEtapasNiveis)
                .map(_.property('nivelEscolar'))
                .unique()
                .value();

            var groupList = _.chain(vm.matricula.funcionarioEtapasNiveis)
                .groupBy(_.property('nivelEscolar'))
                .value();

            vm.matricula[vm.keyEnsinoMedio] = groupList[NIVEL_ESCOLAR.ENSINO_MEDIO] || [];
            vm.hasEnsinoMedio = vm.matricula[vm.keyEnsinoMedio].length;

            vm.matricula[vm.keyEnsinoFundamental] = groupList[NIVEL_ESCOLAR.ENSINO_FUNDAMENTAL] || [];
            vm.hasEnsinoFundamental = vm.matricula[vm.keyEnsinoFundamental].length;

            vm.matricula[vm.keyEducacaoInfantil] = _.first(groupList[NIVEL_ESCOLAR.EDUCACAO_INFANTIL]) || null;
            vm.hasEducacaoInfantil = vm.matricula[vm.keyEducacaoInfantil];
        }

        function adicionaItem(lista) {
            lista.push({});
        }

        function removeItem(lista, index) {
            lista.splice(index, 1);
        }
    }

})();
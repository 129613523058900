(function() {
    'use strict';

    var TIPO_EIXO_TEMATICO = 'EIXO_TEMATICO';
    var TIPO_DISCIPLINA = 'DISCIPLINA';
    var TIPO_DIVISAO_DISCIPLINA = 'DIVISAO_DISCIPLINA';
    var TIPO_CAMPOS_EXPERIENCIA = 'CAMPOS_EXPERIENCIA';
    var MODALIDADE_EDUCACAO_BASICA = 'EDUCACAO_BASICA';
    var AREA_CONHECIMENTO = 'AREA_CONHECIMENTO';

    angular.module('educacao.matricula')
        .directive('appTabTurmaDisciplina', directive);

    function directive() {
        return {
            templateUrl: 'matricula/turma/cadastro/tab-turma-disciplina.directive.html',

            scope: {
                turma: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        '$filter',
        'promiseTracker',
        'ui.components.Modal',
        'educacao.matriz.EtapaDisciplinaService'
    ];

    function Controller($scope, $filter, promiseTracker, uiModal, EtapaDisciplinaService) {
        var orderBy = $filter('orderBy');

        var vm = this;

        vm.loadingTracker = promiseTracker();

        vm.possuiDivisoes = false;
        vm.isTurmaParcial = false;
        vm.isPossuiVariosCalendarios = false;

        vm.isEixoTematico = isEixoTematico;
        vm.isCamposExperiencia = isCamposExperiencia;

        vm.listaDivisaoTurmaDisciplina = [];
        vm.listaAgrupada = [];
        vm.listTurmaFilhos = [];

        vm.abreModalDivisao = abreModalDivisao;
        vm.paddingStyle = paddingStyle;
        vm.selecionaTodos = selecionaTodos;
        vm.atualizaListaFilhos = atualizaListaFilhos;
        vm.atualizaListaFilhosAreaConhecimentoParcial = atualizaListaFilhosAreaConhecimentoParcial;
        vm.isAreaConhecimento = isAreaConhecimento;
        vm.selecionaTodosAreaConhecimento = selecionaTodosAreaConhecimento;
        vm.applyDependenciaFisicaToAll = applyDependenciaFisicaToAll;

        $scope.$watchGroup(['turma.matriz', 'turma.etapaMatriz', 'turma.tipoAtendimento', 'turma.tipoResultado'], setDadosCompletos);
        $scope.$watch('turma.estabelecimento', limpaDependenciasFisicas);

        function setDadosCompletos() {
            vm.listaDivisaoTurmaDisciplina = [];
            vm.listaAgrupada = [];
            vm.listTurmaFilhos = [];

            vm.isTurmaParcial = $scope.turma.tipoAtendimento === 'ATENDIMENTO_PROGRESSAO_PARCIAL';
            vm.isPossuiVariosCalendarios = _.get($scope.turma, 'matriz.curso.nivelModalidade') !== MODALIDADE_EDUCACAO_BASICA;
            loadDisciplinas();
        }

        function loadDisciplinas() {
            vm.loadingTracker.addPromise(
                EtapaDisciplinaService
                .getList($scope.turma.matriz.id, $scope.turma.etapaMatriz.id)
                .then(converteEtapaMatrizDisciplinaTurmaDisciplina)
                .then(planificaDivisoes)
                .then(agrupaDisciplinas)
            );
        }

        /**
         * Realiza a criação/atualização das disciplinas da turma
         * @param {object} listaEtapaMatrizDisciplinas 
         */
        function converteEtapaMatrizDisciplinaTurmaDisciplina(listaEtapaMatrizDisciplinas) {
            var listTurmaDisciplinas = _.cloneDeep($scope.turma.filhos);
            var etapasJaPersistidas = _.mapKeys(listTurmaDisciplinas, 'etapaMatrizDisciplina.id');

            if (isAreaConhecimento()) {
                etapasJaPersistidas = {};
                _.forEach(listTurmaDisciplinas, function(turmaAreaConhecimento) {
                    _.extend(etapasJaPersistidas, _.mapKeys(turmaAreaConhecimento.filhos, 'etapaMatrizDisciplina.id'));
                });
                listTurmaDisciplinas = [];
            }

            vm.isTodosSelecionados = true;

            _.forEach(listaEtapaMatrizDisciplinas, function(etapaMatrizDisciplina) {
                var turmaDisciplina = etapasJaPersistidas[etapaMatrizDisciplina.id];

                if (turmaDisciplina) {
                    turmaDisciplina.$$selected = true;

                    if (isAreaConhecimento()) {
                        listTurmaDisciplinas.push(turmaDisciplina);
                    }

                    return;
                }

                vm.isTodosSelecionados = false;

                turmaDisciplina = {
                    $$selected: !vm.isTurmaParcial,
                    tipo: TIPO_DISCIPLINA,
                    etapaMatrizDisciplina: etapaMatrizDisciplina,
                    filhos: [{
                        tipo: TIPO_DIVISAO_DISCIPLINA
                    }]
                };

                listTurmaDisciplinas.push(turmaDisciplina);
            });

            if (isAreaConhecimento()) {
                agrupaAreasConhecimento(listTurmaDisciplinas);

                return vm.listTurmaFilhos;
            }

            vm.listTurmaFilhos = orderBy(_.filter(listTurmaDisciplinas, 'tipo', 'DISCIPLINA'), [
                isCamposExperiencia() ? 'etapaMatrizDisciplina.campoExperiencia.descricao' : 'etapaMatrizDisciplina.disciplina.descricao',
                'etapaMatrizDisciplina.eixoTematico.descricao']);

            atualizaListaFilhos();
            return vm.listTurmaFilhos;
        }

        function planificaDivisoes() {
            var lista = [];
            vm.possuiDivisoes = false;

            if (isAreaConhecimento()) {
                _.forEach(vm.listTurmaFilhos, function(turmaAreaConhecimento) {
                    _.forEach(turmaAreaConhecimento.filhos, function(turmaDisciplina) {
                        adicionaDivisoes(turmaDisciplina, lista);
                    });
                });
            } else {
                _.forEach(vm.listTurmaFilhos, function(turmaDisciplina) {
                    adicionaDivisoes(turmaDisciplina, lista);
                });
            }

            vm.listaDivisaoTurmaDisciplina = lista;
        }

        function adicionaDivisoes(turmaDisciplina, lista){            
            _.forEach(turmaDisciplina.filhos, function(divisao, index) {
                lista.push(ajustaDivisoes(divisao, index, turmaDisciplina));
            });
        }

        function ajustaDivisoes(divisao, index, turmaDisciplina) {
            divisao.$$labelRowspan = !index ? turmaDisciplina.filhos.length : 0;
            divisao.$$mostraDivisoes = turmaDisciplina.filhos.length > 1;

            vm.possuiDivisoes = vm.possuiDivisoes || divisao.$$mostraDivisoes;

            divisao.$$orientacaoCurricular = turmaDisciplina.etapaMatrizDisciplina.orientacaoCurricular;
            divisao.$$pai = turmaDisciplina;
            divisao.$$label = getDescricaoEtapaMatrizDisciplina(turmaDisciplina.etapaMatrizDisciplina);
            divisao.$$tooltip = getTooltipEtapaMatrizDisciplina(turmaDisciplina.etapaMatrizDisciplina);

            return divisao;
        }

        function getDescricaoEtapaMatrizDisciplina(etapaMatrizDisciplina) {
            return isCamposExperiencia() ? (etapaMatrizDisciplina.campoExperiencia || etapaMatrizDisciplina.eixoTematico).descricao 
                : (etapaMatrizDisciplina.disciplina || etapaMatrizDisciplina.eixoTematico).descricao;
        }

        function getTooltipEtapaMatrizDisciplina(etapaMatrizDisciplina) {
            if (isCamposExperiencia()) {
                return etapaMatrizDisciplina.campoExperiencia &&
                    etapaMatrizDisciplina.eixoTematico &&
                    etapaMatrizDisciplina.eixoTematico.descricao;
            }

            return etapaMatrizDisciplina.disciplina &&
                etapaMatrizDisciplina.eixoTematico &&
                etapaMatrizDisciplina.eixoTematico.descricao;
        }

        /**
         * Agrupa as disciplinas da turma por área de conhecimento (eixo temático)
         * @param {object} listTurmaDisciplinas 
         */
        function agrupaAreasConhecimento(listTurmaDisciplinas) {
            _.chain(listTurmaDisciplinas)
                .filter('tipo', 'DISCIPLINA')
                .sortBy(function(n) {
                    return n.etapaMatrizDisciplina.eixoTematico.descricao;
                })
                .groupBy(function(n) {
                    return n.etapaMatrizDisciplina.eixoTematico.descricao;
                })
                .forEach(function(data) {
                    vm.listTurmaFilhos.push({
                        id: _.get(_.first(data), 'pai.id') || undefined,
                        filhos: data,
                        eixoTematico: _.first(data).etapaMatrizDisciplina.eixoTematico,
                        tipo: AREA_CONHECIMENTO
                    });
                })
                .value();

            if (vm.isTurmaParcial) {
                atualizaListaFilhosAreaConhecimentoParcial();
            } else {
                atualizaListaFilhos();
            }
        }

        /**
         * Preenche a lista agrupada de disciplinas por orientação curricular e/ou áreas de conhecimento (eixo temático)
         */
        function agrupaDisciplinas() {
            vm.listaAgrupada = [];

            vm.listaAgrupada = agrupaPorOrientacaoCurricular(vm.listaDivisaoTurmaDisciplina);

            if (isAreaConhecimento()) {
                _.forEach(vm.listaAgrupada, function(eixos) {
                    eixos.filhos = agrupaPorEixoTematico(eixos.filhos);
                });
            } else {
                var lista = _.clone(vm.listaAgrupada);
                vm.listaAgrupada = [vm.listaAgrupada];
                _.first(vm.listaAgrupada).filhos = lista;
            }

            function agrupaPorOrientacaoCurricular(divisaoDisciplina) {
                var orientacoesId = {};
                _.forEach(divisaoDisciplina, function(divisao) {
                    var orientacao = orientacoesId[_.get(divisao, '$$orientacaoCurricular.id')];
                    if (!orientacao) {
                        orientacao = _.clone(divisao.$$orientacaoCurricular) || {};
                        orientacao.filhos = [];
                        orientacoesId[orientacao.id] = orientacao;
                    }
                    orientacao.filhos.push(divisao);
                });
                return _.values(orientacoesId);
            }

            function agrupaPorEixoTematico(disciplinas) {
                var eixos = [];
                _.chain(disciplinas)
                    .sortBy(function(n) {
                        return n.$$tooltip;
                    })
                    .groupBy(function(n) {
                        return n.$$pai.etapaMatrizDisciplina.eixoTematico.id;
                    })
                    .forEach(function(data) {
                        eixos.push({
                            $$selected: vm.isTurmaParcial ? !_.filter(data, '$$pai.$$selected', false).length : null,
                            $$id: _.first(data).$$pai.etapaMatrizDisciplina.eixoTematico.id,
                            filhos: data,
                            descricao: _.first(data).$$tooltip,
                            id: 'eixo'
                        });
                    })
                    .value();
                return eixos;
            }
        }

        /**
         * Função utilizada para a abertura da tela de seleção de divisões para as disciplinas
         * @param {object} divisao 
         */
        function abreModalDivisao(divisao) {
            var pai = divisao.$$pai;

            uiModal.open({
                    templateUrl: 'matricula/turma/cadastro/divisao-turma-disciplina.html',

                    controller: 'educacao.matricula.DivisaoTurmaDisciplinaController',
                    controllerAs: 'vm',

                    params: {
                        divisoesTurmaDisciplina: _.cloneDeep(pai.filhos),
                        isCamposExperiencia: vm.isCamposExperiencia()
                    }
                }).result
                .then(function(listaDivisoes) {
                    pai.filhos = listaDivisoes;
                })
                .then(isAreaConhecimento() && vm.isTurmaParcial ? atualizaListaFilhosAreaConhecimentoParcial : atualizaListaFilhos)
                .then(planificaDivisoes)
                .then(agrupaDisciplinas);
        }

        function limpaDependenciasFisicas(newValue, oldValue) {
            if (newValue !== oldValue) {
                _.forEach(vm.listaDivisaoTurmaDisciplina, function(divisao) {
                    divisao.dependenciaFisica = null;
                });
            }
        }

        /**
         * Função para seleção de todas as áreas de conhecimento
         * @param {object} turmaAreaConhecimentoSelecionada 
         */
        function selecionaTodosAreaConhecimento(turmaAreaConhecimentoSelecionada) {
            var seleciona = turmaAreaConhecimentoSelecionada.$$selected;

            _.chain(vm.listaAgrupada)
                .map('filhos')
                .flatten()
                .filter('$$id', turmaAreaConhecimentoSelecionada.$$id)
                .map(function(item){
                    item.$$selected = turmaAreaConhecimentoSelecionada.$$selected;
                })
                .value();

            _.chain(vm.listTurmaFilhos)
                .filter('eixoTematico.id', turmaAreaConhecimentoSelecionada.$$id)
                .map('filhos')
                .flatten()
                .map(function(item){
                    item.$$selected = turmaAreaConhecimentoSelecionada.$$selected;
                })
                .value();

            _.forEach(turmaAreaConhecimentoSelecionada.filhos, function(item) {
                item.$$pai.$$selected = seleciona;
            });

            atualizaListaFilhosAreaConhecimentoParcial();
        }

        /**
         * Função para seleção de todos os filhos da turma
         */
        function selecionaTodos() {
            if (isAreaConhecimento()) {
                _.forEach(vm.listTurmaFilhos, function(turmaAreaConhecimento) {
                    _.forEach(turmaAreaConhecimento.filhos, function(turmaDisciplina) {
                        turmaDisciplina.$$selected = vm.isTodosSelecionados;
                    });
                });

                agrupaDisciplinas();

                atualizaListaFilhosAreaConhecimentoParcial();
            } else {
                _.forEach(vm.listTurmaFilhos, function(turmaDisciplina) {
                    turmaDisciplina.$$selected = vm.isTodosSelecionados;
                });

                atualizaListaFilhos();
            }
        }

        /**
         * Atualiza a lista de filhos da turma
         */
        function atualizaListaFilhos() {
            $scope.turma.filhos = isAreaConhecimento() && !vm.isTurmaParcial ? vm.listTurmaFilhos : _.filter(vm.listTurmaFilhos,
                '$$selected', true);
            vm.isTodosSelecionados = $scope.turma.filhos.length === vm.listTurmaFilhos.length;
        }

        /**
         * Atualiza a lista de filhos da turma por área de conhecimento quando progressão parcial.
         * Nesta função é realizado filtro na lista conforme áreas de conhecimento (eixos temáticos) selecionados
         */
        function atualizaListaFilhosAreaConhecimentoParcial() {
            var quantidadeFiltro = 0;
            var quantidade = 0;
            var filhos = _.cloneDeep(vm.listTurmaFilhos);

            _.forEach(vm.listTurmaFilhos, function(filho) {
                quantidade += filho.filhos.length;
            });

            _.forEach(filhos, function(turmaAreaConhecimento) {
                turmaAreaConhecimento.filhos = _.filter(turmaAreaConhecimento.filhos, '$$selected', true);
                quantidadeFiltro += turmaAreaConhecimento.filhos.length;
            });

            $scope.turma.filhos = _.chain(vm.listTurmaFilhos)
                .filter(function(data) {
                    return _.find(data.filhos, '$$selected', true);
                })
                .value();

            vm.isTodosSelecionados = quantidade === quantidadeFiltro;
        }

        /**
         * Verifica se a turma possui matriz com tipo de organização por eixo temático
         */
        function isEixoTematico() {
            return $scope.turma.matriz.tipoOrganizacao === TIPO_EIXO_TEMATICO;
        }

        /**
         * Verifica se a turma possui matriz com tipo de organização por campo de experiência
         */
        function isCamposExperiencia() {
            return $scope.turma.matriz.tipoOrganizacao === TIPO_CAMPOS_EXPERIENCIA;
        }

        /**
         * Verifica se a turma está selecionada como tipo de resultado igual a área de conhecimento
         */
        function isAreaConhecimento() {
            return $scope.turma.tipoResultado === AREA_CONHECIMENTO;
        }

        function paddingStyle(coluna) {
            var eixo = _.first(vm.listaAgrupada).id ? 10 : 0;
            var disciplina = _.first(_.first(vm.listaAgrupada).filhos).id ? 5 : 0;
            return {
                'padding-left': (coluna === 'eixo' ? eixo : eixo + disciplina) + 'px'
            };
        }

        function applyDependenciaFisicaToAll() {
            _.forEach(vm.listaAgrupada, function(orientacao) {
                
                _.forEach(orientacao.filhos, setDependenciaFisica);
            });
        }

        function setDependenciaFisica(eixo) {
            _.forEach(eixo.filhos, function(divisao) {
                divisao.dependenciaFisica = vm.applyAllDependenciaFisica;
            });
        }
    }
})();

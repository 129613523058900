(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.CadastroTurmaMultiSeriada', Controller);

    Controller.$inject = [
        '$q',
        '$scope',
        '$modalInstance',
        'promiseTracker',
        'bfc.Notification',
        'educacao.matricula.TurmaMultisseriadaService',
        'educacao.context.ContextoEstabelecimento',
        'educacao.matriz.HorasAulasSelect',
		'educacao.matricula.TurmaService',
		'educacao.common.EtapaInepService',
        '$enumsCache'
    ];

    function Controller(
        $q, 
        $scope,
        $modalInstance,
        promiseTracker,
        Notification,
        TurmaMultisseriadaService,
        ContextoEstabelecimento,
        HorasAulasSelect,
        TurmaService,
        EtapaInepService,
        $enumsCache
    ) {

        var vm = this;

        vm.editing = false;
        vm.getDescricaoTurmas = getDescricaoTurmas;
        vm.getDescricaoFormasOrganizacoes = getDescricaoFormasOrganizacoes;
        vm.horasAulas = HorasAulasSelect.select2Config();
        vm.addTurmaSeriada = addTurmaSeriada;
        vm.removeRow = removeRow;
        vm.geraListaTurmas = geraListaTurmas;
        vm.save = save;
        vm.editar = editar;
        vm.turmasSelecionadas = _.clone($modalInstance.params.turmasSelecionadas);
        vm.tracker = promiseTracker();
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.listTipoMediacaoDidaticoPedagogica = TurmaService.getTipoMediacaoDidaticoPedagogica();
        vm.listEtapaEnsinoInep = listEtapaEnsinoInep;
        vm.getNomeEtapaEnsinoInep = getNomeEtapaEnsinoInep;

        init();

        var templateBuscaEtapaEnsinoInep = _.template('nome like "<%= term %>"');

        function init() {
            $enumsCache.get('FormaOrganizacao').then(function (data) {
                vm.formasOrganizacoes = data;
            });

            if (!_.isEmpty(vm.turmasSelecionadas)) {
                vm.estabelecimento = _.get(_.first(vm.turmasSelecionadas), 'estabelecimento');

                return;
            }
            
            if (vm.hasEstabelecimento) {
                vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
            }
        }

        $scope.$watch('vm.estabelecimento', changeEstabelecimento);

        function listEtapaEnsinoInep(params) {
			return EtapaInepService
				.getList(params.offset, params.limit, templateBuscaEtapaEnsinoInep(params))
				.then(toPage);
        }
        
        function getNomeEtapaEnsinoInep(etapa) {
			return etapa.codigoInep + ' - ' + etapa.nome;
        }
        
        function toPage(list) {
			return {
				content: list,
				hasMore: false
			};
        }
        
        function addTurmaSeriada() {
            if (vm.editing) {
                vm.turmas[vm.index] = vm.turma;
                vm.editing = false;
            } else {
                vm.turmas.push(angular.copy(vm.turma));
            }

            delete vm.turma;  
            vm.form.$setUntouched();
     
		}

        function changeEstabelecimento(newValue, oldValue) {
            if (!_.isEqual(oldValue, newValue)) {
                vm.turmas = [];
                vm.turmasSelecionadas = [];
            }

            if (_.isEmpty(newValue) || !_.get(vm.estabelecimento, 'id')) {
                return;
            }

            buscaTurmasMultiSeriadas();
        }

        function buscaTurmasMultiSeriadas() {
            $modalInstance.tracker.addPromise(
                TurmaMultisseriadaService.listByEstabelecimento(vm.estabelecimento.id).then(thenListEstabelecimento)
            );

            function thenListEstabelecimento(data) {
                vm.turmas = data;
                $q.all([
                    vm.turmas = setTurmasSelecionadas(vm.turmas)
                ]);
            }
        }

        function setTurmasSelecionadas(turmas) {
            var turmasJaCadastradas = [];

            if (_.isEmpty(turmas)) {
                turmas = [];
                
                if (vm.turmasSelecionadas.length) {
                    turmas.push({
                        turmas: angular.copy(vm.turmasSelecionadas)
                    });
                }

            } else {
                _.forEach(turmas, function (turma) {
                    if (!_.isEmpty(turma.turmas)) {
                        _.forEach(turma.turmas, function (turmaCadastrada) {
                            _.forEach(vm.turmasSelecionadas, function (turmaSelecionada) {
                                if (turmaSelecionada.id === turmaCadastrada.id) {
                                    turmasJaCadastradas.push(turmaSelecionada);
                                }
                            });
                        });
                    }
                });
            
                _.forEach(turmasJaCadastradas, function (turma) {
                    var index = vm.turmasSelecionadas.indexOf(turma);
                    vm.turmasSelecionadas.splice(index, 1);
                });

                if (vm.turmasSelecionadas.length) {
                    turmas.push({
                        turmas: angular.copy(vm.turmasSelecionadas)
                    });
                }
            }

            return turmas;
        }


        function geraListaTurmas() {
            var turmas = [];

            _.forEach(vm.turmas, function (turmaSeriada) {
                _.forEach(turmaSeriada.turmas, function (turma) {
                    turmas.push(turma);
                });
            });

            return turmas;
        }

        function fecharModal() {
            $modalInstance.close();
        }

        function save() {
            var turmasFiltradas = [];

            _.forEach(vm.turmas, eachTurmas);

            if (_.isEmpty(turmasFiltradas)) {
                return;
            }

            vm.tracker.addPromise(
                TurmaMultisseriadaService.save(turmasFiltradas).then(fecharModal)
            );

            function eachTurmas(turma) {
                turma.estabelecimento = vm.estabelecimento;

                if (turma.descricao && turma.turmas) {
                    turmasFiltradas.push(turma);
                }
            }
        }

        function editar(turmaSeriada, index){
            vm.editing = true;
            vm.index = index;
            vm.turma = turmaSeriada;
        }

        function removeRow(index, item) {
            if (item.id) {
                vm.tracker.addPromise(TurmaMultisseriadaService.remove(item).then(removeFromList));
            } else {
                removeFromList();
            }

            function removeFromList() {
                vm.turmas.splice(index, 1);
                vm.form.$setUntouched();
                Notification.publish('Turma multisseriada excluída com sucesso.', 'success');
            }
        }

        function getDescricaoTurmas(turmas) {
            if (turmas.length){
                var descricoesTurmas = [];

                _.forEach(turmas, function (turma) {
                    descricoesTurmas.push(turma.descricao);
                });

                return descricoesTurmas.join(', ');
            }
            
        }

        function getDescricaoFormasOrganizacoes(formasOrganizacoes) {
            if (!formasOrganizacoes || !formasOrganizacoes.length){
                return;
            }
            return vm.formasOrganizacoes[formasOrganizacoes[0]].descricao + '...';
        }
    }
})();

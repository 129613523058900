(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.ResponsavelService', ResponsavelService);

    ResponsavelService.$inject = ['ServiceBuilder','Restangular'];

    function ResponsavelService(ServiceBuilder, Restangular) {

        var self = ServiceBuilder
            .create()
            .path('pessoa', 'responsavel')
            .enum('GrauInstrucao',
            'EstadoCivil',
            'Parentesco',
            'SituacaoTrabalho',
            'TipoFiliacao',
            'Sexo',
            'DiaDaSemanaDto')
            .build();

            
        self.getDetalhesResponsavel = getDetalhesResponsavel;
        self.getPessoas = getPessoas;
        self.camposAdicionais = camposAdicionais;

        return self;
        
        function getDetalhesResponsavel(filter){
            return Restangular.one('pessoa/aluno-responsavel').get(filter);
        }

        function getPessoas(filter, offset, limit) {
            return self.getEndpoint()
                .one('vinculo-alunos')
                .get({
                    filter: filter,
                    offset: offset,
                    limit: limit
                });
        }

        function camposAdicionais(responsavelId) {
            return Restangular.one('pessoa/responsavel/campos-adicionais', responsavelId)
                .get()
                .then(Restangular.stripRestangular);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('megaMenu', directive);

    function directive() {
        return {
            restrict: 'E',

            templateUrl: 'common/layout/header/mega-menu.directive.html',

            scope: false,

            replace: true,
            transclude: true

        };
    }

})();
(function () {
    'use strict';
    angular.module('educacao.desempenhoescolar')
        .controller('educacao.desempenhoescolar.RegistroAvaliacoesController', Controller);

    var TEMPLATE_BUSCA_ABONO = _.template(
        'matricula in (<%= matricula %>) and ' +
        'tipo="ABONO" and ' +
        'dispensa in ("AMBOS","AVALIACAO")'
    );

    var TEMPLATE_PERIODO = _.template(
        '<%= descricao %> (<%= moment(dataInicial).format("DD/MM/YYYY") %> até <%= moment(dataFinal).format("DD/MM/YYYY") %>)'
    );

    var TEMPLATE_SITUACAO_PERIODO = _.template(
        'Periodo avaliativo <%= situacao %> ( <%= moment(dataInicial).format("DD/MM/YYYY") %> a <%= moment(dataFinal).format("DD/MM/YYYY") %>)'
    );

    var OPC_TODOS = {
        descricao: 'Todos',
        id: 0
    };

    var SITUACOES_ALTERACAO = ['APROVADO_CONSELHO', 'APROVADO', 'REPROVADO'];

    Controller.$inject = [
        '$injector', '$rootScope', '$scope', '$state', '$filter', 'promiseTracker', 'educacao.common.ObjectUtilsService',
        '$enumsCache', '$interval', 'Restangular', 'educacao.desempenhoescolar.DesempenhoEscolarUtilsService',
        'educacao.encerramento.EncerramentoUtilsService', 'HeaderFilterBuilderService', 'EdEnumsCacheService'
    ];

    function Controller($injector, $rootScope, $scope, $state, $filter, promiseTracker, ObjectUtilsService,
                        $enumsCache, $interval, Restangular, DesempenhoEscolarUtilsService,
                        EncerramentoUtilsService, HeaderFilterBuilderService, EdEnumsCacheService) {

        var vm = this;

        var $q = $injector.get('$q');
        var bfcNotification = $injector.get('bfc.Notification');
        var $searchExpressionBuilder = $injector.get('$searchExpressionBuilder');
        var PermissionsService = $injector.get('bfc.$$PermissionsService');
        vm.isRevokeAlterarSituacao = PermissionsService.isRevokedOperation('RegistroAvaliacaoPageMapping', 'alterarSituacao');
        var TurmaService = $injector.get('educacao.matricula.TurmaService');
        var AbonoJustificativaServiceFactory = $injector.get('educacao.abonojustificativa.AbonoJustificativaService');
        var $modal = $injector.get('ui.components.Modal');
        var EntidadeService = $injector.get('educacao.entidade.EntidadeService');
        var RegistroAvaliacoesService = $injector.get('educacao.desempenhoescolar.RegistroAvaliacoesService');
        var ItemEducacionalService = $injector.get('educacao.common.ItemEducacionalService');
        var EncerramentoService = $injector.get('educacao.encerramento.EncerramentoService');
        var DesempenhoEscolarPeriodoViewService = $injector.get('educacao.desempenhoescolar.DesempenhoEscolarPeriodoViewService');
        var DesempenhoEscolarYearViewService = $injector.get('educacao.desempenhoescolar.DesempenhoEscolarYearViewService');
        var DesempenhoEscolarItemViewService = $injector.get('educacao.desempenhoescolar.DesempenhoEscolarItemViewService');
        var ITEM_AVALIAVEL_TIPO_PERIODO = $injector.get('ITEM_AVALIAVEL_TIPO_PERIODO');
        var ITEM_AVALIAVEL_MODO_AVALIACAO = $injector.get('ITEM_AVALIAVEL_MODO_AVALIACAO');

        var LIST_ITENS_AVALIAVEIS_TIPO_PERIODO_FILTER = 'tipoPeriodo not in ("' + ITEM_AVALIAVEL_TIPO_PERIODO.CALCULO_INSTRUMENTOS + '", "' + ITEM_AVALIAVEL_TIPO_PERIODO.INSTRUMENTO + '", "' + ITEM_AVALIAVEL_TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO + '", "' + ITEM_AVALIAVEL_TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL + '")';

        var enumTipoPeriodo;
        var AbonoJustificativaService;
        vm.changeSituacao = changeSituacao;

        var propriedadesPublicas = {
            openImpressaoDiario: openImpressaoDiario,
            isTurmaEjaModular: isTurmaEjaModular,
            isTurmaAreaConhecimento: isTurmaAreaConhecimento,
            isTurmaAtividade: isTurmaAtividade,
            getIndexDisciplinaSelecionada: getIndexDisciplinaSelecionada,
            hasDisciplina: hasDisciplina,
            hasPeriodo: hasPeriodo,
            hasItensList: hasItensList,
            hasItensAvaliaveisEja: hasItensAvaliaveisEja,
            hasItensAvaliaveisAreaConhecimento: hasItensAvaliaveisAreaConhecimento,
            exibeSituacaoPeriodo: exibeSituacaoPeriodo,
            getTitlePeriodo: getTitlePeriodo,
            focusSelectDisciplinaEixo: focusSelectDisciplinaEixo,
            focusSelectTurma: focusSelectTurma,
            focusSelectPeriodo: focusSelectPeriodo,
            formatResultItemEducacional: formatResultItemEducacional,
            listaEnturmacoes: listaEnturmacoes,
            listItensEducacionais: listItensEducacionais,
            prevItensEducacionais: prevItensEducacionais,
            nextItensEducacionais: nextItensEducacionais,
            listPeriodos: listPeriodos,
            listFormulasCalculoAreasConhecimento: listFormulasCalculoAreasConhecimento,
            formatPeriodos: formatPeriodos,
            save: save,
            filtraEnturmacoes: filtraEnturmacoes,
            formatResultFormulaCalculo: formatResultFormulaCalculo,
            calcularFormula: calcularFormula,
            desabilitaRefresh: desabilitaRefresh,
            exibeAvisoTipoAvaliacoesIndefinido: exibeAvisoTipoAvaliacoesIndefinido,
            habilitaSelecaoDisciplinasEixos: habilitaSelecaoDisciplinasEixos,
            exibeAvisoSemAlunos: exibeAvisoSemAlunos,
            openPorPeriodo: openPorPeriodo,
            openTodosOsPeriodos: openTodosOsPeriodos,
            refresh: refresh,
            orderBy: orderBy,
            showModalChaObjetivo: showModalChaObjetivo
        };

        _.extend(vm, propriedadesPublicas);

        init();

        vm.headerFilterOptions = {radiobox: [], checkbox: []};
        vm.models = {radiobox: [], checkbox: []};

        function init() {
            initVariaveis();
            initTracker();
            initEnums();
            EdEnumsCacheService.get('SituacaoMatricula').then(function (result) {
                vm.enumSituacaoMatricula = result;
            });
        }

        function initVariaveis() {
            vm.turma = null; //FIXME: Debug, passar pra null
            vm.successfulSave = false;
            vm.labelItensEducacionais = '';
            vm.periodo = null;
            vm.itensEducacionais = [];
            vm.colunas = [];
            vm.linhas = [];
            vm.itensAvaliaveis = undefined;
            vm.itensEducacionaisPage = undefined;
            vm.periodosPage = undefined;
            vm.periodos = [];
            vm.formulaCalculo = null;
            vm.formulasCalculo = [];
            vm.porPeriodo = true;
            vm.filterResultEnunsEspecificos = filterResultEnunsEspecificos;
            vm.itensOflistItensEducacionais = undefined;
            vm.desabilitaCampoSituacao = desabilitaCampoSituacao;
            vm.hasPermissionPeriodoEncerrado = hasPermissionPeriodoEncerrado;
            vm.permissions = !PermissionsService.isRevokedOperation('RegistroAvaliacaoPageMapping', 'alterarDesempenhoPeriodoAvaliativoEncerrado');

            vm.searchColumns = [{
                id: 'nome',
                label: 'Nome',
                model: 'nome',
                filterable: true
            }];

            vm.sortBy = {
                expression: 'enturmacao.numeroChamada',
                reverse: false
            };

            vm.listTurmas = TurmaService.getListFilter;
            vm.hasPermissionsToEditPeriodoEncerrado = !PermissionsService.isRevokedOperation('RegistroAvaliacaoPageMapping', 'criar');
            vm.topFloatHeader = $rootScope.$$pinned ? '78' : '44';
        }

        function initTracker() {
            vm.saveTracker = promiseTracker();
            vm.loadingTracker = promiseTracker();
            vm.refreshTracker = promiseTracker();
            vm.calculoTracker = promiseTracker();
        }

        // verifica se houve alteração na fixação do contexto
        $scope.$watch('$root.$$pinned', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            vm.topFloatHeader = newValue ? '78' : '44';
        });


        function filterResultEnunsEspecificos(term, value) {
            if (_.contains(SITUACOES_ALTERACAO, value.key)) {
                if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                    return value;
                }
            }
        }

        function desabilitaCampoSituacao(situacao) {
            return _.isEmpty(situacao) || !_.contains(SITUACOES_ALTERACAO, situacao);
        }

        function initEnums() {
            vm.loadingTracker.addPromise(
                $q.all([
                    RegistroAvaliacoesService.getEnum('TipoPeriodo').then(function (data) {
                        enumTipoPeriodo = data;
                    }),
                    vm.SituacaoMatriculaPromisse = RegistroAvaliacoesService.getEnum('SituacaoMatricula').then(function (data) {
                        vm.enumSituacaoMatricula = data;
                        return data;
                    }),
                    vm.SituacaoDisciplinaPromisse = RegistroAvaliacoesService.getEnum('SituacaoItemEducacional').then(function (data) {

                        vm.enumSituacaoDisciplina = _.cloneDeep(data);

                        return Object.assign(_.cloneDeep(vm.enumSituacaoMatricula), data);
                    })
                ])
            );

            $enumsCache.get('TipoFormulaCalculo')
                .then(function (enumData) {
                    vm.enumTipoFormulaCalculo = enumData;
                    return enumData;
                });
        }

        function refresh() {
            var promisses = [];

            promisses.push(EncerramentoService.getEncerramentosByTurma(vm.turma)
                .then(setEncerramentosBuscaEnturmacoes));

            promisses.push(RegistroAvaliacoesService.getConfiguracaoAvaliacao(vm.turma.id)
                .then(setConfiguracao));

            //Faz a requisição para buscar novamente os itens avaliáveis para atualização dos globos de publicação
            if (!isTurmaEjaModular() && !isTurmaAreaConhecimento()) {
                promisses.push(RegistroAvaliacoesService.listItensAvaliaveis(vm.turma.id, LIST_ITENS_AVALIAVEIS_TIPO_PERIODO_FILTER)
                    .then(setItensAvaliaveis));
            }

            var promisse = $q.all([promisses]);

            vm.refreshTracker.addPromise(promisse);
        }

        function setEncerramentosBuscaEnturmacoes(encerramento) {
            setEncerramentosTurma(encerramento);
            DesempenhoEscolarPeriodoViewService.configureService(enumTipoPeriodo, vm.encerramentosTurma);

            if (isTurmaEjaModular()) {
                setEncerramentoItensAvaliaveisInEjaAreaConhecimento(vm.listItensAvaliaveis);
                setEncerramentoItensAvaliaveisInEjaAreaConhecimento(vm.listItensAvaliaveisModulo);
            }

            if (isTurmaAreaConhecimento()) {
                setEncerramentoItensAvaliaveisInEjaAreaConhecimento(vm.listItensAvaliaveis);
            }

            filtraEnturmacoes();
        }

        function isTurmaAreaConhecimento() {
            return Boolean(vm.turma && vm.turma.tipoResultado === 'AREA_CONHECIMENTO');
        }

        function isTurmaAtividade() {
            return Boolean(vm.turma &&
                (vm.turma.tipoAtendimento === 'ATIVIDADE_COMPLEMENTAR' || vm.turma.tipoAtendimento === 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO'));
        }

        /*Inicio novas funções para adaptar eja modular e área de conhecimento*/
        function isTurmaEjaModular() {
            return Boolean(vm.turma && vm.turma.matriz && vm.turma.matriz.modular);
        }

        function hasDisciplina() {
            return Boolean(vm.itensEducacionais && vm.itensEducacionais.id);
        }

        function hasItensAvaliaveisEja() {
            return Boolean(vm.listItensAvaliaveis && vm.listItensAvaliaveis.length && vm.listItensAvaliaveisModulo && vm.listItensAvaliaveisModulo
                .length);
        }

        function getIndexDisciplinaSelecionada() {
            return (isTurmaEjaModular() || isTurmaAreaConhecimento()) ? (hasDisciplina() ? 1 : 0) : vm.itensEducacionais.length;
        }

        function hasPeriodo() {
            return Boolean(vm.periodo && vm.periodo.id);
        }

        function hasItensAvaliaveisAreaConhecimento() {
            return Boolean(vm.listItensAvaliaveis && vm.listItensAvaliaveis.length);
        }

        function hasItensList() {
            if (isTurmaAreaConhecimento()) {
                return Boolean(hasDisciplina() && hasItensAvaliaveisAreaConhecimento() && (hasPeriodo() || !vm.porPeriodo) &&
                    vm.enturmacoes && vm.enturmacoes.length);
            }

            return Boolean(isTurmaEjaModular() ? hasDisciplina() &&
                hasItensAvaliaveisEja() &&
                vm.listaEnturmacoesEjaModular &&
                vm.listaEnturmacoesEjaModular.length : listaEnturmacoes().length);
        }

        function getItensEducacionais() {
            return vm.loadingTracker.addPromise(
                $q.all([
                    RegistroAvaliacoesService.getConfiguracaoAvaliacao(vm.turma.id).then(setConfiguracao),
                    (isTurmaAreaConhecimento() ? TurmaService.getItensEducacionaisAreaConhecimento(vm.turma.id) :
                        TurmaService.getItensEducacionais(vm.turma.id)).then(function (data) {
                        setPeriodosItensEducacionais({
                            content: data
                        });
                    }),
                    EncerramentoService.getEncerramentosByTurma(vm.turma).then(setEncerramentosTurma)
                ])
            );
        }

        function openImpressaoDiarioEjaModularAreaConhecimento() {
            var diario = {
                    itensEducacionais: [],
                    registros: []
                },
                matricula,
                listaOrdenada,
                itenEducacional = _.cloneDeep(vm.itensEducacionais);

            diario.itensEducacionais.push(itenEducacional);

            if (isTurmaEjaModular()) {
                diario.tipoItemEducacional = 'AVALIACAO';
            }

            var listaEnturmacoesDiario = isTurmaAreaConhecimento() ? vm.enturmacoes : vm.listaEnturmacoesEjaModular;

            if (vm.sortBy.expression === 'matricula.nome') {
                listaOrdenada = _.sortByOrder(listaEnturmacoesDiario, function (item) {
                    return item.matricula.nome.toLowerCase();
                }, vm.sortBy.reverse ? 'desc' : 'asc');
            } else {
                listaOrdenada = _.sortByOrder(listaEnturmacoesDiario, vm.sortBy.expression, vm.sortBy.reverse ? 'desc' : 'asc');
            }

            if (isTurmaAreaConhecimento()) {
                diario.configuracao = {
                    periodoAvaliativoCorrente: vm.periodo
                };
            }

            _.forEach(listaOrdenada, function (item) {
                matricula = _.cloneDeep(item.matricula);
                matricula.numeroChamada = _.cloneDeep(item.numeroChamada);
                matricula.situacao = _.cloneDeep(vm.enumSituacaoMatricula[item.matricula.situacao].description);
                diario.registros.push(matricula);
            });

            if (vm.periodo && diario.configuracao && isTurmaAreaConhecimento()) {
                diario.configuracao.periodoAvaliativoCorrente = {
                    descricao: vm.periodo.descricao
                };
            }

            openImpressao(diario);
        }

        function setEncerramentoItensAvaliaveisInEjaAreaConhecimento(itensAvaliaveis) {
            _.forEach(itensAvaliaveis, function (itemAvaliavel) {
                var item = _.find(vm.encerramentosTurma, function (find) {
                    if (itemAvaliavel.identificadorExameFinal) {
                        return itemAvaliavel.identificadorExameFinal === find.identificadorExameFinal && itemAvaliavel.tipoPeriodo ===
                            find.tipoPeriodo &&
                            find.status === 'ENCERRADO';
                    }
                    if (itemAvaliavel.tipoPeriodo === 'MODULO' && find.status === 'ENCERRADO') {
                        return true;
                    }
                    return (isTurmaAreaConhecimento() ? (find.periodoAvaliativo && itemAvaliavel.periodoAvaliativo &&
                            itemAvaliavel.periodoAvaliativo.id === find.periodoAvaliativo.id) :
                        (itemAvaliavel.tipoPeriodo === find.tipoPeriodo)) && find.status === 'ENCERRADO';
                });

                itemAvaliavel.hasEncerramento = item ? true : false;
            });
        }

        /*Fim novas funções eja modular e área de conhecimento*/

        function focusSelectTurma($event) {
            $event.stopPropagation();

            var parent = $('ed-select-navigation[model="vm.turma"]');
            parent.find('button').trigger('click');
            parent.find('.select2-focusser').focus();
        }

        function focusSelectDisciplinaEixo($event) {
            $event.stopPropagation();

            var parent = $('button[id="btn-desempenho-escolar-dropdown-itens-educacionais"]');
            parent.trigger('click');

            var select = $('ui-select[ng-model="vm.itensEducacionais"]');
            select.find('.select2-input').focus();
        }

        function focusSelectPeriodo($event) {
            $event.stopPropagation();

            var parent = $('ed-select-navigation[model="vm.periodo"]');
            parent.find('button').trigger('click');
            parent.find('.select2-focusser').focus();
        }

        /**
         * Monta combo para seleção dos itens educacionais (Disciplina ou eixo)
         */
        function listItensEducacionais(params) {
            if (!vm.turma) {
                return $q.reject();
            }

            return $q.when(filterResultListItensEducacionais(vm.itensEducacionaisPage, params));
        }

        /**
         * Filtra os itens educacionais conforme o que foi digitado no selec2
         */
        function filterResultListItensEducacionais(itensEducacionais, params) {

            var term = decodeURI(params.term).replace(/%/g, ' ').trim().toLowerCase();
            vm.itensOflistItensEducacionais = _.filter(itensEducacionais.content, function (item) {
                return item.descricao.toLowerCase().contains(term);
            });
            if (!isTurmaEjaModular() &&
                !isTurmaAreaConhecimento() &&
                vm.itensOflistItensEducacionais.length &&
                _.get(vm.itensEducacionais, 'length') !== vm.itensOflistItensEducacionais.length) {
                vm.itensOflistItensEducacionais.push(OPC_TODOS);
            }
            return {
                content: vm.itensOflistItensEducacionais
            };
        }

        function prevItensEducacionais() {
            var index = _.findIndex(vm.itensEducacionaisPage.content, 'id', _.first(isTurmaEjaModular() || isTurmaAreaConhecimento() ? [
                vm.itensEducacionais] : vm.itensEducacionais)
                .id) - 1;
            if (index < 0) {
                return;
            }
            vm.itensEducacionais = isTurmaEjaModular() || isTurmaAreaConhecimento() ? vm.itensEducacionaisPage.content[index] : [vm.itensEducacionaisPage
                .content[index]];
        }

        function nextItensEducacionais() {
            var index = _.findIndex(vm.itensEducacionaisPage.content, 'id', _.first(isTurmaEjaModular() || isTurmaAreaConhecimento() ? [
                vm.itensEducacionais] : vm.itensEducacionais)
                .id) + 1;
            if (index >= vm.itensEducacionaisPage.content.length) {
                return;
            }
            vm.itensEducacionais = isTurmaEjaModular() || isTurmaAreaConhecimento() ? vm.itensEducacionaisPage.content[index] : [vm.itensEducacionaisPage
                .content[index]];
        }

        function formatResultItemEducacional(itemEducacional) {
            if (!itemEducacional) {
                return;
            }

            if (_.get(itemEducacional, 'dataInicio') && (_.get(itemEducacional, 'dataFinal') || _.get(itemEducacional, 'dataFim'))) {
                return TEMPLATE_PERIODO({
                    descricao: itemEducacional.descricao,
                    dataInicial: itemEducacional.dataInicio,
                    dataFinal: itemEducacional.dataFinal || itemEducacional.dataFim
                });
            }
            return itemEducacional.descricao;
        }

        function listFormulasCalculoAreasConhecimento() {
            var filter = 'situacaoCadastral = "ATIVO"';

            return RegistroAvaliacoesService.getFormulasCalculoAreasConhecimento(filter, 0, 999)
                .then(atualizaFormulasCalculo);
        }

        function atualizaFormulasCalculo(data) {
            vm.formulasCalculo = data.content;

            return {
                content: data.content
            };
        }

        function formatResultFormulaCalculo(formulaCalculo) {
            if (!formulaCalculo) {
                return;
            }

            formulaCalculo.tipoFormulaCalculoDescription = _.get(vm.enumTipoFormulaCalculo[formulaCalculo.tipoFormulaCalculo],
                'description');

            var descricao = formulaCalculo.tipoFormulaCalculoDescription + ' - ' + (!formulaCalculo.estabelecimento ? 'Secretaria' :
                'Estabelecimento de ensino');

            formulaCalculo.descricao = descricao;

            return descricao;
        }

        function calcularFormula() {
            if (!vm.hasDisciplina() || !vm.turma || !vm.formulaCalculo || !vm.periodo) {
                return;
            }

            vm.calculoTracker.addPromise(RegistroAvaliacoesService.calcularFormula(vm.itensEducacionais.id, vm.turma.id, vm.periodo.id,
                vm.formulaCalculo.id).then(monitorarRetornoCalculo));
        }

        function monitorarRetornoCalculo() {
            RegistroAvaliacoesService.statusCalculo(vm.turma.id, vm.periodo.id, vm.itensEducacionais.id).then(function (calculo) {
                vm.calculoFormula = calculo;
            });

            vm.promiseStatusCalculo = $interval(function () {
                if (vm.turma.id && vm.periodo.id && vm.itensEducacionais.id) {
                    RegistroAvaliacoesService.statusCalculo(vm.turma.id, vm.periodo.id, vm.itensEducacionais.id).then(
                        atualizaStatusCalculo);
                }
            }, 2000);
        }

        function atualizaStatusCalculo(data) {
            if (data.status !== 'PROCESSANDO') {
                var filterListAvaliacoes = 'itemAvaliacaoTurma.itemEducacional in (' + vm.itensEducacionais.id +
                    ') or itemAvaliacaoTurma.itemEducacionalPai in (' +
                    vm.itensEducacionais.id + ')';

                vm.calculoFormula = data;
                vm.formulaCalculo = data.formulaCalculo;

                RegistroAvaliacoesService.listAvaliacoes(vm.turma.id, filterListAvaliacoes).then(
                    atualizaMediaCalculada);

                $interval.cancel(vm.promiseStatusCalculo);
            }
        }

        /**
         * Função que retorna promise para select2 de períodos
         */
        function listPeriodos() {
            return $q.when(vm.periodosPage);
        }

        /**
         * Formata os períodos para exibição no select2
         */
        function formatPeriodos(periodo) {
            return isTurmaAreaConhecimento() ? _.get(periodo, 'dataInicial') && TEMPLATE_PERIODO(periodo) :
                _.get(periodo, 'rawValue.dataInicial') && TEMPLATE_PERIODO(periodo.rawValue);
        }

        function setLabelItensEducacionais() {
            vm.labelItensEducacionais = 'Componente curricular';
        }

        /**
         * Função que define se deve aparacer as informações do período no título da coluna
         */
        function exibeSituacaoPeriodo(coluna) {
            //Exibe a situação do período para períodos avaliativos, exames finais e médias de períodos (para atividade).
            return coluna.rawValue && (coluna.rawValue.dataInicial || coluna.rawValue === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL ||
                coluna.rawValue === ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO);
        }

        /**
         * Função que define o que aparece no header das colunas
         */
        function getTitlePeriodo(coluna) {
            if (coluna.rawValue.dataInicial) {
                return TEMPLATE_SITUACAO_PERIODO(_.extend(coluna.rawValue, {
                    situacao: coluna.encerrado ? 'encerrado' : 'em aberto'
                }));
            } else {
                return coluna.descricao + (coluna.encerrado ? ' encerrado' : ' em aberto');
            }
        }

        /**
         * Função chamada ao selecionar uma turma
         */
        function loadConfigurations(turma) {
            vm.periodo = null;
            vm.itensEducacionais = [];
            vm.listaEnturmacoesEjaModular = [];
            vm.calculoFormula = null;
            if (_.get(vm.searchFilter, 'others')) {
                delete vm.searchFilter.others.situacaoMatricula;
                delete vm.searchFilter.others.situacaoMatriculaPai;
            }

            vm.colunas = [];
            vm.linhas = [];

            if (turma) {
                if (isTurmaEjaModular()) {
                    getItensEducacionais();
                    return loadEnturmacoesModular();
                }

                if (isTurmaAreaConhecimento()) {
                    getItensEducacionais();
                    return loadEnturmacoes();
                }

                AbonoJustificativaService = AbonoJustificativaServiceFactory.buildService(turma.id);

                vm.loadingTracker.addPromise($q.all([
                    loadEnturmacoes(),
                    RegistroAvaliacoesService.getConfiguracaoAvaliacao(vm.turma.id).then(setConfiguracao),
                    loadItensEducacionaisComEncerramento().then(setAutoComponentes)
                ]));
            }
        }

        function setAutoComponentes() {
            listItensEducacionais({
                term: ''
            }).then(function () {
                vm.itensEducacionais = [OPC_TODOS];
            });
        }

        /**
         * Carrega os itens educacionais e encerramentos
         */
        function loadItensEducacionaisComEncerramento() {
            return $q.all({
                itensEducacionais: RegistroAvaliacoesService.listItensAvaliaveis(vm.turma.id, LIST_ITENS_AVALIAVEIS_TIPO_PERIODO_FILTER),
                encerramentos: EncerramentoService.getEncerramentosByTurma(vm.turma)
            }).then(function (data) {
                setEncerramentosTurma(data.encerramentos);
                setPeriodosItensEducacionais(data.itensEducacionais);
            });
        }

        /**
         * Atribui retorno para a lista de encerramentos
         */
        function setEncerramentosTurma(data) {
            vm.encerramentosTurma = data;
        }

        /**
         * Função que carrega as enturmações da turma selecionada
         */
        function loadEnturmacoes() {
            var searchFilter = _.cloneDeep(vm.searchFilter);
            if (searchFilter && searchFilter.others) {
                if (searchFilter.others.situacaoMatriculaPai) {
                    delete searchFilter.others.situacaoMatriculaPai;
                }
                if (searchFilter.others.situacaoDisciplina) {
                    delete searchFilter.others.situacaoDisciplina;
                }
            }
            if (_.isEmpty(vm.searchFilter.others)) {
                vm.searchFilter.others = {
                    situacaoMatricula: [
                        'CURSANDO'
                    ]
                };
            }

            if (!isTurmaAreaConhecimento()) {
                return TurmaService.getEnturmacoesHistoricas(vm.turma.id, $searchExpressionBuilder.build(vm.searchFilter), 0, 999)
                    .then(setEnturmacoes);
            }

            if (!hasDisciplina()) {
                return;
            }

            vm.loadingTracker.addPromise(
                TurmaService.getEnturmacoesHistoricas(vm.turma.id, $searchExpressionBuilder.build(vm.searchFilter), 0, 999)
                    .then(setEnturmacoes)
                    .then(refreshItensEducacionaisAreaConhecimento(vm.itensEducacionais))
            );
        }

        function loadEnturmacoesModular() {
            if (!hasDisciplina()) {
                return;
            }

            var filter = [];
            if (_.isEmpty(vm.searchFilter.others)) {
                vm.searchFilter.others = {
                    situacaoMatricula: [
                        'CURSANDO'
                    ],
                    situacaoMatriculaPai: [
                        'CURSANDO'
                    ]
                };
            }

            filter.push('etapaMatrizDisciplina=' + vm.itensEducacionais.etapaMatrizDisciplina.id);
            if ($searchExpressionBuilder.build(vm.searchFilter)) {
                filter.push($searchExpressionBuilder.build(vm.searchFilter));
            }

            var params = {
                idTurma: vm.turma.id,
                filter: filter.join(' and '),
                offset: 0,
                limit: 999
            };

            vm.loadingTracker.addPromise(
                TurmaService.getEnturmacoesHistoricas(params.idTurma,
                    params.filter, params.offset, params.limit).then(function (data) {
                    vm.listaEnturmacoesEjaModular = data;
                })
                    .then(refreshItensEducacionais(vm.itensEducacionais))
            );
        }

        function setEnturmacoes(enturmacoes) {
            vm.enturmacoes = enturmacoes;
        }

        function setConfiguracao(configuracaoAvaliacao) {
            vm.configuracaoAvaliacao = configuracaoAvaliacao;
        }

        /**
         * Função que configura os períodos educacionais e recupera os itens avaliáveis válidos
         */
        function setPeriodosItensEducacionais(page) {
            //Recupera itens avaliáveis válidos
            vm.itensAvaliaveis = _.chain(page.content)
                .reject('modoAvaliacao', null)
                .reject('modoAvaliacao', ITEM_AVALIAVEL_MODO_AVALIACAO.NAO_OFERECE)
                .reject('tipoPeriodo', ITEM_AVALIAVEL_TIPO_PERIODO.INSTRUMENTO)
                .value();

            if (isTurmaEjaModular() || isTurmaAreaConhecimento()) {
                vm.itensEducacionaisPage = {
                    content: _.chain(vm.itensAvaliaveis)
                        .unique(_.property('id'))
                        .sortBy('descricao')
                        .value()
                };
                if (isTurmaAreaConhecimento()) {
                    atualizaPeriodos();
                }
                if (isTurmaEjaModular()) {
                    createRadioFilterDisciplina(vm.itensEducacionaisPage.content);
                }
                createFilterSituacao(!isTurmaAreaConhecimento());

                return;
            }

            vm.itensEducacionaisPage = {
                content: _.chain(vm.itensAvaliaveis)
                    .map(_.property('itemEducacional'))
                    .unique(_.property('id'))
                    .sortBy('descricao')
                    .value()
            };
            createFilterDisciplina();
            createFilterSituacao();
            if (_.isEmpty(vm.itensAvaliaveis)) {
                return;
            }

            DesempenhoEscolarPeriodoViewService.configureService(enumTipoPeriodo, vm.encerramentosTurma);

            atualizaPeriodos();
        }

        function atualizaPeriodos() {
            if (isTurmaAreaConhecimento()) {
                vm.loadingTracker.addPromise(TurmaService.getPeriodosAvaliativos(vm.turma.id).then(function (data) {
                    vm.periodos = data;
                    vm.periodosPage = {
                        content: data
                    };

                    if (!vm.porPeriodo) {
                        loadConfigurations();
                    } else {
                        createRadioFilterPeriodo();
                    }
                    createRadioFilterDisciplina(vm.itensEducacionaisPage.content);
                }));
            } else {
                var periodos = DesempenhoEscolarPeriodoViewService.getPeriodosFromItensAvaliaveis(vm.itensAvaliaveis);
                vm.periodos = periodos.periodos;
                vm.periodosPage = periodos.periodosPage;
                createRadioFilterPeriodo(vm.itensEducacionaisPage.content.length <= 1);
            }

        }

        /**
         * Função para setar o período atual entre os períodos possíveis para seleção
         */
        function setCurrentPeriodoAvaliativo() {
            var today = moment();
            var dataUltimoPeriodo = _.chain(vm.periodos)
                .findLast('rawValue.dataFinal')
                .get('rawValue.dataFinal')
                .value();

            if (today.isAfter(dataUltimoPeriodo)) {
                vm.periodo = getLastPeriodo();
                return;
            }

            vm.periodo = _.chain(_.get(vm.periodosPage, 'content'))
                .filter('rawValue.dataFinal')
                .find(function (periodoWrapper) {
                    return !today.isAfter(periodoWrapper.rawValue.dataFinal);
                })
                .value();
        }

        function getLastPeriodo() {
            var lastPeriodo = _.findLast(_.get(vm.periodosPage, 'content'), function (periodo) {
                var idExame = periodo.identificadorExameFinal;
                return Boolean(idExame);
            });
            if (lastPeriodo) {
                return lastPeriodo;
            }
            return _.last(_.get(vm.periodosPage, 'content'));
        }

        /**
         * Função que executa pesquisa das enturmações com os filtros escolhidos
         */
        function filtraEnturmacoes() {
            if (!vm.turma) {
                return;
            }

            if (isTurmaEjaModular()) {
                return loadEnturmacoesModular();
            }

            if (isTurmaAreaConhecimento()) {
                return loadEnturmacoes();
            }

            vm.loadingTracker.addPromise(loadEnturmacoes().then(loadRegistrosAvaliacoes));
        }

        /**
         * Carrega os registros de avaliação conforme seleções e filtros
         */
        function loadRegistrosAvaliacoes(newValues, oldValues) {
            if (isTurmaEjaModular()) {
                return loadEnturmacoesModular();
            }

            if (isTurmaAreaConhecimento()) {
                return loadEnturmacoes();
            }

            var hasAll = _.filter(vm.itensEducacionais, function (data) {
                return !data.id;
            });

            if (hasAll.length) {
                vm.itensOflistItensEducacionais.splice(vm.itensOflistItensEducacionais.length - 1, 1);
                vm.itensEducacionais = vm.itensOflistItensEducacionais;
                if (_.find(vm.headerFilterOptions.checkbox, {id: 'disciplina'})) {
                    var index = _.findIndex(vm.headerFilterOptions.checkbox, {id: 'disciplina'});
                    _.set(vm.models.checkbox, [index], _.pluck(vm.headerFilterOptions.checkbox[index].options, 'value'));
                }
                return;
            }
            vm.configuracao = null;
            vm.colunas = [];
            vm.linhas = [];
            vm.calculoFormula = null;

            if (!vm.turma || _.isEmpty(vm.itensEducacionais) || _.isEmpty(vm.enturmacoes)) {
                return;
            }

            var createView;

            var itensEducacionaisIds = _.pluck(vm.itensEducacionais, 'id');

            var filter = '(';
            filter += 'itemAvaliacaoTurma.itemEducacional in (' + itensEducacionaisIds.join(',') + ')';

            if (vm.itensEducacionais.length > 1 && vm.periodo) {
                if (vm.periodo.rawValue === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL) {
                    filter += ' and itemAvaliacaoTurma.tipoPeriodo = "' + ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL + '" and ' +
                        'itemAvaliacaoTurma.identificador = ' + vm.periodo.identificadorExameFinal;
                } else if (_.get(vm.periodo, 'rawValue.id')) {
                    filter += ' and itemAvaliacaoTurma.periodoAvaliativo = ' + vm.periodo.rawValue.id;
                } else {
                    filter += ' and itemAvaliacaoTurma.tipoPeriodo = "' + vm.periodo.id + '"';
                }
            }

            filter += ')';

            if (vm.itensEducacionais.length === 1) {
                createView = createYearView;
            } else if (vm.periodo) {
                createView = createItemView;
            }

            if (createView) {
                var newPeriodo = _.get(newValues, '1');
                var oldPeriodo = _.get(oldValues, '1');

                var promisses = {
                    avaliacoes: RegistroAvaliacoesService.listAvaliacoes(vm.turma.id, filter).then(_.property('content')),
                    abonos: AbonoJustificativaService.completos(getFiltroAbonos()).then(_.first).then(_.property('content'))
                };

                //Faz a requisição para buscar novamente os itens avaliáveis para atualização dos globos de publicação quando navega pelos períodos
                if (newPeriodo !== oldPeriodo) {
                    promisses.itensAvaliaveis = RegistroAvaliacoesService.listItensAvaliaveis(vm.turma.id, LIST_ITENS_AVALIAVEIS_TIPO_PERIODO_FILTER).then(setItensAvaliaveis);
                }

                vm.loadingTracker.addPromise($q.all(promisses).then(createView));
            }
        }

        function setItensAvaliaveis(page) {
            vm.itensAvaliaveis = _.chain(page.content)
                .reject('modoAvaliacao', null)
                .reject('modoAvaliacao', ITEM_AVALIAVEL_MODO_AVALIACAO.NAO_OFERECE)
                .reject('tipoPeriodo', ITEM_AVALIAVEL_TIPO_PERIODO.INSTRUMENTO)
                .value();
        }

        /**
         * Retorna as enturmações válidas para seleção. Função criada para filtrar as enturmações de uma única atividade
         */
        function listaEnturmacoes() {
            if (vm.labelItensEducacionais !== 'atividade') {
                adicionaDescricaoSituacao(vm.linhas);
                return vm.linhas;
            }
            var itensEducacionais = _.chain(vm.itensEducacionais)
                .map(_.property('id'))
                .value();

            var lista = _.filter(vm.linhas, function (dado) {
                return _.contains(itensEducacionais, dado.enturmacao.itensEducacionais[0].id);
            });

            adicionaDescricaoSituacao(lista);
            return lista;
        }

        function adicionaDescricaoSituacao(lista) {
            _.forEach(lista, function (linha) {
                linha.enturmacao.situacaoDescription = _.get(vm.enumSituacaoMatricula[linha.enturmacao.situacao], 'description') ||
                    _.get(vm.enumSituacaoDisciplina[
                        linha.enturmacao.situacao], 'description');
            });
        }

        function getFiltroAbonos() {
            var periodos = vm.itensEducacionais.length === 1 ? vm.periodos : [vm.periodo];
            var idPeriodoAvaliativo = _.chain(periodos).map(_.property('rawValue.id')).compact().value().join(',');
            var filter = TEMPLATE_BUSCA_ABONO({
                matricula: vm.enturmacoes.map(_.property('matricula.id')).join(',')
            });

            if (!_.isEmpty(idPeriodoAvaliativo)) {
                filter += ' and periodoAvaliativo in (' + idPeriodoAvaliativo + ')';
            }
            return {filter: filter};
        }

        /**
         * Cria visão do período letivo todo, com apenas um item educacional selecionado (Disciplina)
         */
        function createYearView(registros) {
            var itemEducacional = isTurmaAreaConhecimento() ? vm.itensEducacionais : _.first(vm.itensEducacionais);

            var yearView = DesempenhoEscolarYearViewService.createYearView(registros, itemEducacional, vm.itensAvaliaveis,
                vm.periodos, vm.enturmacoes);

            vm.linhas = yearView.linhas;
            vm.colunas = yearView.colunas;
            vm.colunaMediaFinal = yearView.colunaMediaFinal;
        }

        /**
         * Cria visão para vários itens educacionais (disciplinas) selecionados, filtrando por um período
         */
        function createItemView(registros) {
            var itemView = DesempenhoEscolarItemViewService.createItemView(registros, vm.itensEducacionais, vm.itensAvaliaveis,
                vm.periodo, vm.enturmacoes);

            vm.colunas = itemView.colunas;
            vm.linhas = itemView.linhas;
        }

        function clearFields(nota) {
            var avalDescritiva = _.get(nota.enturmacao, 'matricula.avaliacaoDescritiva');
            var tipoAvaliacao = avalDescritiva ? 'DESCRITIVO' : _.get(nota.avaliavel, 'modoAvaliacao');

            var MODO_AVALIACAO = {
                DESCRITIVO: ['notaNumerica', 'notaConceito'],
                NUMERICO: ['notaDescritiva', 'notaConceito'],
                CONCEITO_NUMERICO: ['notaNumerica', 'notaDescritiva'],
                CONCEITO: ['notaNumerica', 'notaDescritiva']
            };

            (MODO_AVALIACAO[tipoAvaliacao] || []).forEach(function (tipo) {
                nota[tipo] = null;
            });
        }

        function save(nota) {
            vm.successfulSave = false;
            clearFields(nota);
            nota.$$isSaving = true;
            var promise;
            if (vm.permissions) {
                promise = RegistroAvaliacoesService.salvarPeriodoEncerrado(vm.turma.id, nota);
            } else {
                promise = RegistroAvaliacoesService.salvar(vm.turma.id, nota);
            }
            promise.then(function (novaNota) {
                vm.successfulSave = true;
                nota.$$isSaving = false;
                nota.id = _.get(novaNota, 'id');
                nota.updateFields();

                if (_.get(nota.enturmacao, 'matricula.avaliacaoDescritiva')) {
                    nota.$$editavel = DesempenhoEscolarUtilsService.isItemEditavel(_.get(nota.avaliavel, 'tipoPeriodo'), 'DESCRITIVO');
                }

                if (vm.calculoFormula) {
                    if (nota.avaliavel.itemEducacional.tipo !== 'AREA_CONHECIMENTO') {
                        vm.calculoFormula.alterado = true;
                    } else if (vm.porPeriodo) {
                        var indiceMediaArea = _.findIndex(vm.listAvaliacoes, function (avaliacao) {
                            return (isMediaAreaConhecimento(avaliacao) &&
                                (avaliacao.enturmacao.id === novaNota.enturmacao.id));
                        });

                        vm.listAvaliacoes[indiceMediaArea] = Restangular.stripRestangular(novaNota);
                    }
                }
            })
                .catch(function () {
                    nota.$$isSaving = false;
                });

            vm.saveTracker.addPromise(promise);
            nota.$$tracker.addPromise(promise);
        }

        function openImpressaoDiario() {
            var registros = [],
                diario = {},
                tipoAtendimento,
                matricula,
                listaOrdenada;

            if (isTurmaEjaModular() || isTurmaAreaConhecimento()) {
                return openImpressaoDiarioEjaModularAreaConhecimento();
            }

            if (vm.sortBy.expression === 'enturmacao.matricula.nome') {
                listaOrdenada = _.sortByOrder(listaEnturmacoes(), function (item) {
                    return item.enturmacao.matricula.nome.toLowerCase();
                }, vm.sortBy.reverse ? 'desc' : 'asc');
            } else {
                listaOrdenada = _.sortByOrder(listaEnturmacoes(), vm.sortBy.expression, vm.sortBy.reverse ? 'desc' : 'asc');
            }

            _.forEach(listaOrdenada, function (item) {
                tipoAtendimento = _.cloneDeep(item.enturmacao.turma.tipoAtendimento);
                matricula = _.cloneDeep(item.enturmacao.matricula);
                matricula.numeroChamada = _.cloneDeep(item.enturmacao.numeroChamada);
                matricula.situacao = _.cloneDeep(vm.enumSituacaoMatricula[item.enturmacao.matricula.situacao].description);
                registros.push(matricula);
            });

            diario.registros = registros;

            if (vm.itensEducacionais.length > 1 || vm.itensEducacionaisPage.content.length === 1) {
                if (ItemEducacionalService.isAtividade(tipoAtendimento)) {
                    diario.tipoItemEducacional = 'ATIVIDADE';
                } else {
                    diario.configuracao = {
                        periodoAvaliativoCorrente: vm.periodo.rawValue
                    };
                }
                diario.itensEducacionais = vm.itensEducacionais;

            } else {
                diario.itensEducacionais = [];
                var itenEducacional = _.cloneDeep(vm.itensEducacionais[0]);

                _.forEach(vm.periodosPage.content, function (periodo) {
                    if (_.isNumber(periodo.id)) {
                        var item = _.cloneDeep(itenEducacional);

                        item.dataFinal = periodo.rawValue.dataFinal;
                        item.dataInicial = periodo.rawValue.dataInicial;
                        item.descricaoPeriodo = periodo.rawValue.descricao;
                        diario.itensEducacionais.push(item);
                    }
                });

                if (diario.itensEducacionais.length === 0) {
                    diario.itensEducacionais.push(itenEducacional);
                    diario.itensEducacionais.push(itenEducacional);
                }

                diario.tipoItemEducacional = 'AVALIACAO';
            }

            if (vm.periodo && diario.configuracao) {
                diario.configuracao.periodoAvaliativoCorrente = {
                    descricao: vm.periodo.descricao
                };
            }

            openImpressao(diario);
        }

        function openImpressao(diario) {
            return $modal.open({
                templateUrl: 'impressao-diario-classe-desempenho/impressao-diario-classe-desempenho.html',
                controller: 'educacao.diarioclasse.ImpressaoDiario',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    diario: diario,
                    turma: vm.turma,
                    diarioAvaliacao: true,
                    entidade: EntidadeService.get(''),
                    tituloImpressao: 'Avaliação',
                    tituloFicha: 'avaliação',
                    tituloColuna: 'Média'
                }
            }).result;
        }

        function orderBy(item) {
            if (!_.get(vm.sortBy, 'expression')) {
                vm.sortBy.expression = 'enturmacao.numeroChamada';
            }
            if (vm.sortBy.expression === 'enturmacao.matricula.nome') {
                return ObjectUtilsService.normalize(_.get(item, vm.sortBy.expression));
            }
            return _.get(item, vm.sortBy.expression);
        }

        function atualizaAvaliacoes(data) {
            vm.listAvaliacoes = data.content;
        }

        function atualizaMediaCalculada(data) {
            if (vm.listAvaliacoes.length) {
                _.forEach(data.content, function (avaliacaoAtualizada) {
                    if (isMediaAreaConhecimento(avaliacaoAtualizada)) {
                        var indiceMediaArea = _.findIndex(vm.listAvaliacoes, function (avaliacao) {
                            return (isMediaAreaConhecimento(avaliacao) &&
                                (avaliacao.enturmacao.id === avaliacaoAtualizada.enturmacao.id));
                        });

                        if (indiceMediaArea > 0) {
                            vm.listAvaliacoes[indiceMediaArea] = avaliacaoAtualizada;
                        } else {
                            vm.listAvaliacoes[vm.listAvaliacoes.length] = avaliacaoAtualizada;
                        }
                    }
                });

                return;
            }

            atualizaAvaliacoes(data);
        }

        function isMediaAreaConhecimento(avaliacao) {
            return (avaliacao.avaliavel.itemEducacional.tipo === 'AREA_CONHECIMENTO') &&
                (avaliacao.avaliavel.periodoAvaliativo && avaliacao.avaliavel.periodoAvaliativo.id === vm.periodo.id) &&
                (avaliacao.avaliavel.itemEducacional.id === vm.itensEducacionais.id);
        }

        function atualizaItensAvaliaveisAreaConhecimento(data) {
            vm.listItensAvaliaveis = _.chain(data.content)
                .reject('modoAvaliacao', null)
                .reject('modoAvaliacao', ITEM_AVALIAVEL_MODO_AVALIACAO.NAO_OFERECE)
                .reject('tipoPeriodo', ITEM_AVALIAVEL_TIPO_PERIODO.INSTRUMENTO)
                .value();

            setEncerramentoItensAvaliaveisInEjaAreaConhecimento(vm.listItensAvaliaveis);
        }

        function openTodosOsPeriodos() {
            vm.porPeriodo = false;
            vm.periodo = null;
            vm.calculoFormula = null;

            if (vm.turma && !vm.periodos) {
                atualizaPeriodos();
            }
        }

        function openPorPeriodo() {
            vm.porPeriodo = true;
            vm.periodo = null;
        }

        function atualizaCalculoFormula(data) {
            vm.calculoFormula = data;

            if (data) {
                vm.formulaCalculo = data.formulaCalculo;
            }
        }

        function desabilitaRefresh() {
            return !vm.turma || !((vm.itensEducacionais && vm.itensEducacionais.length) || vm.hasDisciplina());
        }

        function exibeAvisoTipoAvaliacoesIndefinido() {
            return vm.turma && ((vm.itensEducacionaisPage && !vm.itensEducacionaisPage.content.length) ||
                (!vm.hasItensAvaliaveisEja() && vm.hasDisciplina() && !vm.isTurmaAreaConhecimento()) ||
                (vm.isTurmaAreaConhecimento() && !vm.hasItensAvaliaveisAreaConhecimento() && vm.hasDisciplina() &&
                    (vm.hasPeriodo() || !vm.porPeriodo)));
        }

        function habilitaSelecaoDisciplinasEixos() {
            return vm.turma && (vm.isTurmaEjaModular() ? !vm.hasDisciplina() :
                (vm.isTurmaAreaConhecimento() ? !vm.hasDisciplina() && (vm.hasPeriodo() || !vm.porPeriodo) :
                    !vm.itensEducacionais.length));
        }

        function exibeAvisoSemAlunos() {
            return vm.turma && !vm.hasItensList() && (vm.isTurmaEjaModular() ? (vm.hasDisciplina() && vm.hasItensAvaliaveisEja()) :
                (vm.isTurmaAreaConhecimento() ? (vm.hasDisciplina() && vm.hasItensAvaliaveisAreaConhecimento()) :
                    vm.itensEducacionais.length));
        }

        function refreshItensEducacionais(itemEducacional) {
            if (!itemEducacional || !itemEducacional.etapaMatrizDisciplina || isTurmaAreaConhecimento()) {
                vm.calculoFormula = null;
                return;
            }

            var filterListAvaliaveis = 'itemEducacional=' + itemEducacional.id + ' and ' + LIST_ITENS_AVALIAVEIS_TIPO_PERIODO_FILTER;
            var filterListAvaliacoes = 'itemAvaliacaoTurma.itemEducacional in (' + itemEducacional.id +
                ') or itemAvaliacaoTurma.itemEducacionalPai in (' +
                itemEducacional.id + ')';

            vm.loadingTracker.addPromise(
                $q.all([
                    RegistroAvaliacoesService.listAvaliacoes(vm.turma.id, filterListAvaliacoes).then(atualizaAvaliacoes),
                    RegistroAvaliacoesService.listItensAvaliaveis(vm.turma.id, filterListAvaliaveis).then(function (data) {
                        vm.listItensAvaliaveis = data.content;
                        setEncerramentoItensAvaliaveisInEjaAreaConhecimento(vm.listItensAvaliaveis);

                    }),
                    RegistroAvaliacoesService.listItensAvaliaveisModulo(vm.turma.id, itemEducacional.etapaMatrizDisciplina.id).then(function (
                        data) {
                        vm.listItensAvaliaveisModulo = data.content;
                        setEncerramentoItensAvaliaveisInEjaAreaConhecimento(vm.listItensAvaliaveisModulo);
                    })
                ])
            );
        }

        function refreshItensEducacionaisAreaConhecimento(itemEducacional) {
            if (!itemEducacional || !itemEducacional.id || ((!vm.periodo || !vm.periodo.id) && vm.porPeriodo) || !
                isTurmaAreaConhecimento()) {
                vm.calculoFormula = null;
                return;
            }

            var filterListAvaliaveis = _.cloneDeep(LIST_ITENS_AVALIAVEIS_TIPO_PERIODO_FILTER);

            filterListAvaliaveis += vm.periodo ? ' and periodoAvaliativo=' + vm.periodo.id : '';

            var filterListAvaliacoes = 'itemAvaliacaoTurma.itemEducacional in (' + itemEducacional.id +
                ') or itemAvaliacaoTurma.itemEducacionalPai in (' +
                itemEducacional.id + ')';

            vm.loadingTracker.addPromise(
                $q.all([
                    RegistroAvaliacoesService.listAvaliacoes(vm.turma.id, filterListAvaliacoes).then(atualizaAvaliacoes),
                    RegistroAvaliacoesService.listItensAvaliaveisAreaConhecimento(vm.turma.id, itemEducacional.id, filterListAvaliaveis).then(
                        atualizaItensAvaliaveisAreaConhecimento),
                    vm.periodo ? RegistroAvaliacoesService.statusCalculo(vm.turma.id, vm.periodo.id, itemEducacional.id).then(
                        atualizaCalculoFormula) : null
                ])
            );
        }

        function changeSituacao(enturmacao) {
            var lista = _.filter(enturmacao.situacaoItensEducacionais, function (dado) {
                return dado.itemEducacional.id === vm.itensEducacionais[0].id;
            });

            if (!_.get(enturmacao, 'situacao')) {
                bfcNotification.publish(
                    'A situação precisa ser informada',
                    'error');
                return;
            }
            if (!_.get(lista, 'length')) {
                bfcNotification.publish(
                    'Não é permitido a alteração para uma atividade que o aluno não esteja matriculado',
                    'error');
                return;
            }

            var promise = RegistroAvaliacoesService.alterarEnturmacaoSituacao(lista[0].id, enturmacao.situacao)
                .then(function () {
                    vm.successfulSave = true;
                });
            vm.saveTracker.addPromise(promise);
        }

        function hasPermissionPeriodoEncerrado(nota) {
            if (_.get(nota, 'avaliavel.tipoPeriodo') === 'MEDIA_PERIODO') {
                if (ItemEducacionalService.isAtividade(_.get(vm.turma, 'tipoAtendimento'))) {
                    if (vm.encerramentosTurma) {
                        return vm.permissions;
                    }
                }

                var idPeriodo = _.get(nota, 'avaliavel.periodoAvaliativo.id');
                if (!!idPeriodo) {
                    var periodoAvaliativo = _.find(vm.encerramentosTurma, 'periodoAvaliativo.id', idPeriodo);
                    if (periodoAvaliativo && EncerramentoUtilsService.isStatusEncerrado(periodoAvaliativo)) {
                        return vm.permissions;
                    }
                }
            }
            return true;
        }

        $scope.$watch('vm.turma', loadConfigurations);
        $scope.$watch('vm.turma.etapaMatriz', setLabelItensEducacionais);
        $scope.$watchGroup(['vm.itensEducacionais', 'vm.periodo'], loadRegistrosAvaliacoes);
        $scope.$watchCollection('vm.periodos', setCurrentPeriodoAvaliativo);
        $scope.$watch('vm.porPeriodo', showFilterPeriodo);

        function setItensEducacionais(value) {
            vm.itensEducacionais = _.cloneDeep(value);
            if (_.get(vm.itensEducacionais, 'length')) {
                var indexRadio = _.findIndex(vm.headerFilterOptions.radiobox, {id: 'periodos'});
                _.set(vm.headerFilterOptions, ['radiobox', indexRadio, 'hide'], vm.itensEducacionais.length === 1);
            }
        }

        function setPeriodo(value) {
            vm.periodo = _.cloneDeep(value);
        }

        function showFilterPeriodo(value) {
            var radioboxIndex = _.findIndex(vm.headerFilterOptions.radiobox, {id: 'periodos'});
            if (radioboxIndex >= 0) {
                _.set(vm.headerFilterOptions, ['radiobox', radioboxIndex, 'hide'], !Boolean(value));
                _.set(vm.headerFilterOptions, ['radiobox', radioboxIndex, 'subDescription'], null);
                _.set(vm.models, ['radiobox', radioboxIndex], null);
                return;
            }
            if (value) {
                createRadioFilterPeriodo();
            }
        }

        function createFilterDisciplina() {
            var optionsDisciplina = {
                id: 'disciplina',
                pathOrder: 'descricao',
                filtrable: true,
                label: vm.labelItensEducacionais,
                clickMethod: setItensEducacionais,
                formatDescription: formatResultItemEducacional,
                selectAll: true,
                filterAllOnClean: true
            };
            HeaderFilterBuilderService.createCheckbox(vm.itensEducacionaisPage.content, vm.models, vm.headerFilterOptions, optionsDisciplina);
        }

        function createFilterSituacao(createPai) {
            function onClick(value) {
                _.set(vm.searchFilter, 'others.situacaoMatricula', value);
            }

            var options = {
                id: 'situacao',
                defaultValues: ['CURSANDO'],
                pathOrder: 'description',
                filtrable: true,
                label: createPai ? 'Situação na disciplina' : 'Situação',
                clickMethod: onClick,
                pathDescription: 'description',
                pathValue: 'id',
                selectAll: true
            };

            function onClickPai(value) {
                _.set(vm.searchFilter, 'others.situacaoMatriculaPai', value);
            }

            if (createPai) {
                var optionsPai = _.cloneDeep(options);
                _.set(optionsPai, 'id', 'situacaoPai');
                _.set(optionsPai, 'clickMethod', onClickPai);
                _.set(optionsPai, 'label', 'Situação');

                HeaderFilterBuilderService.createCheckbox(vm.enumSituacaoMatricula.values, vm.models, vm.headerFilterOptions, optionsPai);
            }
            HeaderFilterBuilderService.createCheckbox(vm.enumSituacaoMatricula.values, vm.models, vm.headerFilterOptions, options);
        }

        function createRadioFilterDisciplina(data) {
            var options = {
                id: 'itensEducacionais',
                description: vm.labelItensEducacionais,
                filtrable: true,
                formatDescription: formatResultItemEducacional,
                onClick: setItensEducacionais,
                hideClear: true
            };
            HeaderFilterBuilderService.createRadiobox(data, vm.headerFilterOptions, vm.models, options);
        }

        function createRadioFilterPeriodo(hide) {
            if (!_.get(vm.periodosPage, 'content')) {
                return;
            }
            var hasDate = Boolean(_.get(vm.periodosPage.content, [0, 'rawValue', 'dataInicial']));

            if (hasDate) {
                setCurrentPeriodoAvaliativo();
            }

            var options = {
                id: 'periodos',
                description: 'Períodos',
                filtrable: false,
                formatDescription: formatResultPeriodo,
                defaultValue: hasDate ? vm.periodo : undefined,
                onClick: setPeriodo,
                hide: hide,
                hideClear: true
            };
            HeaderFilterBuilderService.createRadiobox(vm.periodosPage.content, vm.headerFilterOptions, vm.models, options);
        }

        function formatResultPeriodo(item) {
            var value = item.rawValue || item;

            if (!value) {
                return null;
            }

            if (!value.dataInicial || !value.dataFinal) {
                return item.descricao;
            }

            var dateFormat = 'dd/MM/yyyy';
            return value.descricao + ' (' + $filter('date')(value.dataInicial, dateFormat) +
                ' até ' + $filter('date')(value.dataFinal, dateFormat) + ')';
        }

        function showModalChaObjetivo(registro) {
            var tipoPeriodo = _.get(registro, 'avaliavel.tipoPeriodo');

            if (!tipoPeriodo) {
                return false;
            }

            return (
                tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL ||
                tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO
            );
        }
    }
})();

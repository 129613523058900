(function () {

    'use strict';

    angular.module('educacao.diarioclasse')
        .directive('appSelectPeriodoDiarioClasse', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" allow-clear="false" format-selection="vm.formatSelection(data)" format-result="vm.formatResult(data)" search="vm.search" />',
            scope: {
                ngModel: '=',
                diario: '=',
                ngChange: '&?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', '$q', '$filter'];

    function Controller($scope, $q, $filter) {
        var vm = this;

        var dateFormat = 'dd/MM/yyyy';

        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;

        $scope.$watch('ngModel', function (newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function search(params) {
            var deferred = $q.defer();

            var term = decodeURI(params.term);
            term = term ? term.replace(/%/g, '') : term;
       
            var filtrados = _.filter($scope.diario, function (item) {
                return item.descricao.toLowerCase().contains(term.toLowerCase());
            });
            deferred.resolve(filtrados);

            return deferred.promise.then(function (data) {
                return {
                    content: data,
                    hasNext: false
                };
            });
        }

        function formatResult(data) {
            return data.text ?
                data.text :
                data.descricao + ' (' + $filter('date')(data.dataInicial, dateFormat) + ' até ' +
                    $filter('date')(data.dataFinal, dateFormat) + ')';
        }

        function formatSelection(data) {
            return data.descricao + ' (' + $filter('date')(data.dataInicial, dateFormat) + ' até ' +
                $filter('date')(data.dataFinal, dateFormat) + ')';
        }
    }
})();
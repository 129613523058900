(function () {
    'use strict';

    angular.module('educacao.matricula').controller('educacao.matricula.EnturmacaoProfessoresCtrl', EnturmacaoProfessoresCtrl);

	EnturmacaoProfessoresCtrl.$inject = ['$scope', '$injector', '$modalInstance', '$q', '$enumsCache','$state'];

	function EnturmacaoProfessoresCtrl($scope, $injector, $modalInstance, $q, $enumsCache,$state) {
		var vm = this;

        var $commons = $injector.get('educacao.common.$commons');
        var EnturmacaoProfessorService = $injector.get('educacao.matricula.EnturmacaoProfessorService');
        var FilterGeneric = $injector.get('educacao.common.FilterGeneric');
        var HistoricoService = $injector.get('ed-historico.HistoricoService');

        $enumsCache.get('TipoEnturmacaoFuncionario').then(function (data) {
            vm.TipoEnturmacaoFuncionario = data;
        });

		vm.turmas = $modalInstance.params.turmas;
		vm.Turno = $modalInstance.params.Turno;
		vm.urlCentralAjuda = 'https://educacao.ajuda.betha.cloud/educacao/ajuda/Administrando?_highlight=habilita%C3%A7%C3%B5es#adicionando-funcion%C3%A1rio';

        if (!vm.turmas || !vm.turmas.length) {
            throw 'É necessário passar ao menos uma turma';
        }

        vm.tracker = {
            enturmaveis: $commons.$promiseTracker(),
            enturmaveisMore: $commons.$promiseTracker(),
            loadingProfessores: {
                button: $commons.$promiseTracker()
            }
        };

        _.forEach(vm.turmas, function (turma) {
            vm.tracker.loadingProfessores[turma.id] = $commons.$promiseTracker();
        });

		vm.desenturmar = desenturmar;
		vm.remover = remover;
		vm.getEnturmaveis = getEnturmaveis;
		vm.hasMoreItens = hasMoreItens;
		vm.formValid = formValid;
		vm.isTipoRegente = isTipoRegente;
		vm.isAtividade = isAtividade;
		vm.isEixoTematico = isEixoTematico;
		vm.isCampoExperiencia = isCampoExperiencia;
		vm.isEnturmacaoPorTurma = isEnturmacaoPorTurma;
		vm.dragHelper = dragHelper;
		vm.addEnturmavelInList = addEnturmavelInList;
		vm.addAllEnturmavelInList = addAllEnturmavelInList;
		vm.enturmaveisList = [];
		vm.enturmarFromDrag = enturmarFromDrag;
		vm.enturmarFromButton = enturmarFromButton;
		vm.goToFuncionarios = goToFuncionarios;

		function addEnturmavelInList(enturmavel) {
			if (enturmavel.$checked) {
				vm.enturmaveisList.push(enturmavel);
			} else {
				_.remove(vm.enturmaveisList, function(item) {
					return enturmavel.disciplina.id === item.disciplina.id &&
						enturmavel.funcionario.id === item.funcionario.id;
				});
			}
			vm.checkedAll = (vm.enturmaveisList.length === vm.enturmaveis.length);
		}

        function addAllEnturmavelInList() {
            if (vm.checkedAll) {
                vm.enturmaveisList = _.cloneDeep(vm.enturmaveis);
                _.forEach(vm.enturmaveis, function (enturmavel) {
                    enturmavel.$checked = true;
                });
            } else {
                vm.enturmaveisList = [];
                _.forEach(vm.enturmaveis, function (enturmavel) {
                    enturmavel.$checked = false;
                });
            }
        }

        activate();

        $scope.$watch('vm.filtro.tipoEnturmacao', resetaEnturmaveis);
        $scope.$watchCollection('vm.filtro.componente', resetaEnturmaveis);

        function resetaEnturmaveis(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            vm.filtroEnturmaveis = null;
            vm.pesquisado = false;
            vm.enturmaveis = [];
            vm.enturmaveisList = [];
            vm.checkedAll = false;
        }

        function activate() {
            vm.turma = _.first(vm.turmas);
            vm.filtro = {
                etapaMatriz: _.get(vm.turma, 'etapaMatriz.id'),
                nivelEscolar: _.get(vm.turma, 'matriz.curso.nivelEscolar'),
                estabelecimento: _.get(vm.turma, 'estabelecimento.id')
            };

            vm.tabelaTurmas = _.chunk(vm.turmas, 2);
            setTemplateFilterHistorico();
            setTemplateHistorico();
        }

        function setTemplateFilterHistorico() {
            FilterGeneric.setFilter('appDataHistorico', appDataHistorico);
            FilterGeneric.setFilter('appHorarioHistorico', appHorarioHistorico);

            function appDataHistorico(input) {
                var dia = moment(new Date()).diff(moment(input).format('YYYY-MM-DD'), 'days');
                if (dia <= 0) {
                    return 'Hoje';
                }
            }

            function appHorarioHistorico(input) {
                return moment(input).format('HH:mm:ss - DD/MM/YYYY');
            }
        }

        function setTemplateHistorico() {
            HistoricoService.setTipoHandler('ENTURMACAO_FUNCIONARIO', {
                templateUrl: 'matricula/turma/app-card-turma/professores/historico-enturmacao-professores-template.html',
                openEditEnturmar: openEditEnturmar
            });
            HistoricoService.setTipoHandler('ALTERACAO_ENTURMACAO_FUNCIONARIO', {
                templateUrl: 'matricula/turma/app-card-turma/professores/historico-alteracao-enturmacao-professores-template.html',
                openEditEnturmar: openEditEnturmar,
                hasValorAnterior: hasValorAnterior
            });
            HistoricoService.setTipoHandler('DESENTURMACAO_FUNCIONARIO', {
                templateUrl: 'matricula/turma/app-card-turma/professores/historico-desenturmacao-professores-template.html',
                openEditDesenturmar: openEditDesenturmar
            });
            HistoricoService.setTipoHandler('ALTERACAO_DESENTURMACAO_FUNCIONARIO', {
                templateUrl: 'matricula/turma/app-card-turma/professores/historico-alteracao-desenturmacao-professores-template.html',
                openEditDesenturmar: openEditDesenturmar,
                hasValorAnterior: hasValorAnterior
            });

            function openEditDesenturmar(historico) {
                $commons.$modal.open({
                    templateUrl: 'matricula/enturmacao-professores/desenturmacao/desenturmacao-professor.html',
                    controller: 'educacao.matricula.DesenturmacaoProfessorCtrl as vm',
                    size: 'sm',
                    params: {
                        historico: getEnturmacao(historico),
                        isOnlyEditByHistorico: true
                    }
                });
            }

            function openEditEnturmar(historico) {
                $commons.$modal.open({
                    templateUrl: 'matricula/enturmacao-professores/enturmacao/edit-enturmacao-professor.html',
                    controller: 'educacao.matricula.EditEnturmacaoProfessorCtrl as vm',
                    params: {
                        historico: getEnturmacao(historico)
                    }
                });
            }

            function getEnturmacao(historico) {
                return EnturmacaoProfessorService.getEnturmacao(_.get(historico, 'campo.idEnturmacao.valor'));
            }

            function hasValorAnterior(item) {
                return Boolean(_.get(item, 'valorAnterior'));
            }
        }

		function dragHelper() {
			var msg = 'Enturmar ' + (vm.enturmaveisList.length || 1) + ' professor(es)';
			return '<div class="bth-card bg__blue" style="width: 240px; z-index: 2">' +
				'<div class="bth-card__body tx__gray--l40">' +
				'<i class="fa fa-users mRig5"></i> <strong>' + msg + '</strong>' +
				'</div>' +
				'</div>';
		}

		function enturmarFromDrag(dragDropObject) {
			if (!Array.isArray(dragDropObject.dragObject)) {
				dragDropObject.dragObject = [dragDropObject.dragObject];
			}

			var enturmacoesProfessoresTurma = [{
				turma: dragDropObject,
				professores: dragDropObject.dragObject
			}];

			enturmarList(enturmacoesProfessoresTurma);
		}

		function enturmarFromButton() {
			var enturmacoesProfessoresTurma = [];

			_.forEach(vm.turmasEnturmar, function(turma) {
				enturmacoesProfessoresTurma.push({
					turma: turma,
					professores: _.cloneDeep(vm.enturmaveisList)
				});
			});

			enturmarList(enturmacoesProfessoresTurma);
		}

		function enturmarList(professoresTurmaList) {
			if (!isAtividade() && !isEnturmacaoPorTurma()) {
				vm.divisoes = [];
				var promise = getDivisoes(0, 0);
				vm.tracker.loadingProfessores.button.addPromise(promise);
				vm.tracker.enturmaveis.addPromise(promise);
				_.forEach(professoresTurmaList, function(professoresTurma) {
					vm.tracker.loadingProfessores[professoresTurma.turma.id].addPromise(promise);
				});
			} else {
				enturmar(professoresTurmaList);
			}

			function getDivisoes(posicaoTurma, posicaoEnturmacao) {
				var turma = _.get(professoresTurmaList, posicaoTurma);
				if (posicaoEnturmacao >= turma.professores.length) {
					++posicaoTurma;
					turma = _.get(professoresTurmaList, posicaoTurma);
					posicaoEnturmacao = 0;
					if (posicaoTurma >= professoresTurmaList.length) {
						if (!_.isEmpty(vm.divisoes)) {
							openSelectDivisao(professoresTurmaList);
							return;
						}
						return enturmar(professoresTurmaList);
					}
				}
				var enturmacao = _.get(turma.professores, posicaoEnturmacao);
				var componente = getComponente(enturmacao);

				var service = isEixoTematico() ? 'getDivisaoEixo' : (isCampoExperiencia() ? 'getDivisaoCampo' : 'getDivisao');

				return EnturmacaoProfessorService[service](turma.turma.id, componente).then(nextDivisaoEnturmacao);

				function nextDivisaoEnturmacao(data) {
					if (data.length > 1) {
						var result = {
							turma: turma.turma.id,
							componente: componente,
							data: data
						};
						vm.divisoes.push(result);
					} else {
						enturmacao.divisao = _.first(data);
					}
					return getDivisoes(posicaoTurma, ++posicaoEnturmacao);
				}
			}

			function openSelectDivisao(enturmacoes) {
				$commons.$modal.open({
					templateUrl: 'matricula/enturmacao-professores/selecao-divisao/selecao-divisao-list.html',
					controller: 'educacao.matricula.SelecaoDivisaoEnturmacaoProfessorListCtrl as vm',
					params: {
						enturmacoes: enturmacoes,
						divisoes: vm.divisoes
					}
				}).result.then(enturmar);
			}

			function getComponente(enturmacao) {
				return _.get(enturmacao, 'eixoTematico.id') || _.get(enturmacao, 'disciplina.id') || _.get(
					enturmacao, 'servidor.id');
			}



		}

		function enturmar(turmasEnturmacoes) {
			var promisses = [];
			_.forEach(turmasEnturmacoes, function(turmaEnturmacao) {
				promisses.push(realizarEnturmacao(turmaEnturmacao.professores, turmaEnturmacao.turma.id, turmaEnturmacao.turma.enturmacoes));
			});

			vm.tracker.loadingProfessores.button.addPromise($q.all(promisses));
			_.forEach(turmasEnturmacoes, function(turmaEnturmacao) {
				vm.tracker.loadingProfessores[turmaEnturmacao.turma.id].addPromise($q.all(promisses));
			});

		}

		function realizarEnturmacao(enturmacoes, turmaId, enturmacoesTurma) {

			var enturmacoesRealizadas = [];
			return enturma(0);

			function enturma(enturmarPos) {
				var turma = _.find(vm.turmas, 'id', turmaId);
				if (enturmarPos >= enturmacoes.length) {
					turma.collapse = true;
					if (enturmacoesTurma) {
						enturmacoesTurma.concat(enturmacoesRealizadas);
					}
					return;
				}
				var enturmacaoObj = _.get(enturmacoes, enturmarPos);
				var json = {
					'professor': _.pick(enturmacaoObj.funcionario ? enturmacaoObj.funcionario : enturmacaoObj, 'id'),
					'turma': { id: turmaId },
					'itemEducacional': enturmacaoObj.divisao ? _.pick(enturmacaoObj.divisao, 'id') : undefined,
					'tipo': _.get(vm.filterModel, 'tipoEnturmacao'),
					'dataEnturmacao': vm.data
				};

				if (isAtividade() && !isTipoRegente()) {
					json = _.extend(json, {
						atividade: _.get(enturmacaoObj, 'atividade.id') ? _.pick(enturmacaoObj.atividade, 'id') : null
					});
				}

				if (isModular()) {
					json = _.extend(json, {
						disciplina: _.get(enturmacaoObj, 'disciplina.id') ? _.pick(enturmacaoObj.disciplina, 'id') : null
					});
				}

				return EnturmacaoProfessorService.enturmar(json).then(function(data) {
					enturmaProfessor(data);
					return enturma(++enturmarPos);
				}).catch(function() {
					turma.collapse = true;
					return enturma(++enturmarPos);
				});

				function enturmaProfessor(enturmacao) {
					$commons.$notification.publish(enturmacao.professor.nome +' enturmado(a) com sucesso!', 'success');
					if (enturmacao.tipo === 'PROFESSOR') {
						turma.quantidadeProfessores++;
					}
					enturmacoesRealizadas.push(enturmacao);
				}
			}
		}

		function desenturmar(enturmacao, turma) {
			if (!_.get(enturmacao, 'id')) {
				return;
			}

			removeEnturmacoes(enturmacao, turma);

			return EnturmacaoProfessorService.desenturmar(enturmacao, {data: vm.data})
				.then(desenturmaProfessor)
				.catch(handleError);

			function desenturmaProfessor(ret) {
				$commons.$notification.publish('Professor desenturmado com sucesso!', 'success');
				removeQuadroHorarioEnturmacao(ret, turma);
			}

			function handleError() {
				if (turma.enturmacoes) {
					turma.enturmacoes.push(enturmacao);
				}
			}
		}

		function remover(enturmacao, turma) {
			if (!_.get(enturmacao, 'id')) {
				return;
			}

			removeQuadroHorarioEnturmacao(enturmacao, turma).then(removeProfessor);

			function removeProfessor() {
				removeEnturmacoes(enturmacao, turma);

				return EnturmacaoProfessorService.remover(enturmacao)
					.then(thenRemoveProfessor)
					.catch(handleError);
			}

			function thenRemoveProfessor(ret) {
				if (ret) {
					return;
				}

				$commons.$notification.publish('Professor removido com sucesso!', 'success');
			}

			function handleError() {
				if (turma.enturmacoes) {
					turma.enturmacoes.push(enturmacao);
				}
			}
		}

		function removeQuadroHorarioEnturmacao(enturmacao, turma) {
			if (enturmacao.tipo === 'PROFESSOR') {
				turma.quantidadeProfessores--;
				return desenturmaQuadroHorario(enturmacao);
			}

			return $q.when();
		}

		function removeEnturmacoes(enturmacao, turma) {
			if (turma.enturmacoes) {
				var obj = _.find(turma.enturmacoes, findEnturmacao);

				turma.enturmacoes.splice(turma.enturmacoes.indexOf(obj), 1);
			}

			function findEnturmacao(data) {
				return data.id === enturmacao.id;
			}
		}

		function desenturmaQuadroHorario(enturmacao) {
			return $commons.$modal.open({
				templateUrl: 'matricula/enturmacao-professores/aviso/desenturmacao-aviso.html',
				controller: 'educacao.matricula.EnturmacaoCadAvisosCtrl as vm',
				params: {
					enturmacao: enturmacao
				}
			}).result;
		}

		function getEnturmaveis(more) {
			vm.pesquisadoWithFilter = Boolean(vm.filtroEnturmaveis);
			vm.pesquisado = true;
			var filtro = [];
			var filtroMore = [];
			var tracker = 'enturmaveisMore';

			if (!more) {
				vm.enturmaveis = [];
				tracker = 'enturmaveis';
			}

			if (isEixoTematico()) {
				setFilter(filtro, _.get(vm.filtro, 'componente.length'), 'eixoTematico' + ' in (' + _.pluck(vm.filtro.componente, getComponenteExpression() +
						'.id').join(
						',') +
					')');
			}

			if (!(isEixoTematico() || isCampoExperiencia() && isTipoRegente())) {
				var componente = isAtividade() ? 'atividade' : 'disciplina';
				setFilter(filtro, _.get(vm.filtro, 'componente.length'), componente + ' in (' + _.pluck(vm.filtro.componente, getComponenteExpression() +
						'.id').join(
						',') +
					')');
			}
			if (!isEnturmacaoPorTurma()) {
				setFilter(filtro, vm.filtro.nivelEscolar, 'nivelEscolar="' + vm.filtro.nivelEscolar + '"');
			}
			setFilter(filtro, vm.filtro.estabelecimento, 'localTrabalho=' + vm.filtro.estabelecimento);
			setFilter(filtro, vm.data, 'localTrabalho.dataFinal is null');
			setFilter(filtroMore, vm.filtroEnturmaveis, 'nome like "' + encodeURI('%' + vm.filtroEnturmaveis + '%') + '"');

			vm.filterIn = filtro;
			vm.filterModel = _.cloneDeep(vm.filtro);

			var serviceGetEnturmaveis = getServiceGetEnturmaveis();

			var params = {
				filter: _.union(vm.filterIn, filtroMore).join(' and '),
				etapaMatriz: vm.filterModel.etapaMatriz,
				offset: more ? _.get(vm.page, 'offset') + 25 : 0
			};

			var promise = EnturmacaoProfessorService[serviceGetEnturmaveis](params).then(function (data) {
				vm.page = data;
				vm.enturmaveis = !more ? data.content : _.union(vm.enturmaveis, data.content);
				vm.more = more;
			});

			vm.tracker[tracker].addPromise(promise);
		}

		function getServiceGetEnturmaveis() {
			if (isAtividade() && !isEnturmacaoPorTurma()) {
				return 'getListaEnturmacaoAtividades';
			}
			if (isEnturmacaoPorTurma()) {
				return 'getListaEnturmacaoPorTurmas';
			}
			if (isEixoTematico()) {
				return 'getListaEixoTematico';
			}
			if (isCampoExperiencia()) {
				return 'getListaCampoExperiencia';
			}
			return 'getListaEnturmacao';
		}

		function setFilter(filter, value, condicao) {
			if (value) {
				filter.push(condicao);
			}
		}

		function getComponenteExpression() {
			if (isAtividade()) {
				return 'atividade';
			}
			if (isEixoTematico()) {
				return 'eixoTematico';
			}
			if (isCampoExperiencia()) {
				return 'campoExperiencia';
			}
			return 'disciplina';
		}

		function formValid() {
			return Boolean(_.get(vm.form, '$valid') && (isEnturmacaoPorTurma() || !isEnturmacaoPorTurma() && _.get(vm.filtro, 'componente.length')));
		}

		function isTipoRegente() {
			return Boolean(_.get(vm.filtro, 'tipoEnturmacao') === 'REGENTE');
		}

		function isModular() {
			return Boolean(_.get(vm.turma, 'matriz.modular'));
		}

		function isAtividade() {
			var tipoAtendimento = _.get(vm.turma, 'tipoAtendimento');
			return Boolean(tipoAtendimento === 'ATIVIDADE_COMPLEMENTAR' || tipoAtendimento === 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO');
		}

		function hasMoreItens() {
			return Boolean(_.get(vm.page, 'hasNext'));
		}

		function isEixoTematico() {
			return Boolean(_.get(vm.turma, 'matriz.tipoOrganizacao') === 'EIXO_TEMATICO');
		}

		function isCampoExperiencia() {
			return Boolean(_.get(vm.turma, 'matriz.tipoOrganizacao') === 'CAMPOS_EXPERIENCIA');
		}

		function isEnturmacaoPorTurma() {
			if (_.get(vm.filtro, 'tipoEnturmacao')) {
				return vm.TipoEnturmacaoFuncionario[vm.filtro.tipoEnturmacao].enturmacaoPorTurma;
			}
		}

		function goToFuncionarios() {
			$modalInstance.close();
			$state.go('app.funcionario.funcionario');
		}
	}
})();

(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizAtualizarSituacaoTurmaCtrl', Controller);
		
    Controller.$inject = [
        '$scope', 
        '$modalInstance', 
        'matriz', 
        'educacao.context.ContextoAnoLetivo', 
        'educacao.matriz.MatrizAtualizarSituacaoTurmaService',
        'bfc.Notification'
    ];
    
    function Controller ($scope, $modalInstance, matriz, ContextoAnoLetivo, MatrizAtualizarSituacaoTurmaService, Notification) {

        $scope.matriz = matriz;
        $scope.fecharModal = fecharModal;
        $scope.save = save;
        $scope.searchAnosLetivos = searchAnosLetivos;
        $scope.listAnosLetivos = [];
        $scope.isContinuar = false;

        function fecharModal() {
            $modalInstance.close();
        }

        function save() {
            if (!$scope.listAnosLetivos.length) {
                return;
            }

            var data = {
                matrizId: $scope.matriz.id,
                anosLetivos: $scope.listAnosLetivos.map(function(anoLetivo) { return parseInt(anoLetivo.ano); })
            };

            MatrizAtualizarSituacaoTurmaService.save(data);

            message();
            fecharModal();
        }

        function searchAnosLetivos() {
            return ContextoAnoLetivo.listaAnosLetivos().then(function(response) {
                response.forEach(function(data) {
                    data.descricao = data.ano;
                });

                return {
                    content: response,
                    hasNext: false
                };
            });
        }

        function message() {
            Notification.publish('Processo de atualização da carga horária da matriz curricular em andamento.', 'info');
        }
    }
})();

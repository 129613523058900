(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.ReligiaoSelect', ReligiaoSelect);

    ReligiaoSelect.$inject = ['$injector',
        'educacao.common.ModalCad',
        'bfc.Select2Config',
        'educacao.pessoas.ReligiaoService',
        'educacao.common.CommonSelectWithService'
    ];


    function ReligiaoSelect($injector,
        ModalCad,
        Select2Config,
        ReligiaoService,
        CommonSelectWithService
    ) {

        var PermissionsService = $injector.get('bfc.$$PermissionsService');
        var onAdd = PermissionsService.isRevokedOperation('ReligiaoPageMapping', 'criar') ? undefined : add;

        return {
            select2Config: function() {
                return CommonSelectWithService.select2Config(ReligiaoService, 'descricao', {
                    onAdd: onAdd
                });
            }
        };

        function add(val, target) {
            ModalCad.open({
                templateUrl: 'planejamento/religioes/religioes.cad.html',
                controller: 'educacao.pessoas.ReligioesCadCtrl as ReligioesCadCtrl',
                optionsCad: {
                    'descricao': val
                },
                selectElem: target
            });
            return false;
        }
    }
})();
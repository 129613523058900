(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.OrgaoEmissorInepSelect', OrgaoEmissorInepSelect);

    OrgaoEmissorInepSelect.$inject = ['$injector'];

    function OrgaoEmissorInepSelect($injector) {
       var OrgaoEmissorInepService = $injector.get('educacao.common.OrgaoEmissorInepService');
       var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');
        
       return {select2Config: function(){ 
                    return CommonSelectWithService.select2Config(OrgaoEmissorInepService,'nome', 
                             {delay: 250}); 
               }
              };

    }   
    
})();
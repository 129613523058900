(function() {
    'use strict';

    angular.module('educacao.planejamento').controller('educacao.pessoas.EstabelecimentoEquipeCadCtrl', EstabelecimentoEquipeCadCtrl);

    EstabelecimentoEquipeCadCtrl.$inject = [
        'promiseTracker',
        '$modalTracker',
        '$q',
        'optionsCad',
        '$rootScope',
        '$modalInstance',
        '$injector',
        '$scope',
        'readOnly',
        'bfc.Notification',
        'fnCad',
        'id',
        '$filter'
    ];

    function EstabelecimentoEquipeCadCtrl(
        promiseTracker,
        $modalTracker,
        $q,
        optionsCad,
        $rootScope,
        $modalInstance,
        $injector,
        $scope,
        readOnly,
        Notification,
        fnCad,
        id,
        $filter) {

        var vm = this;
        var _select2 = {};
        var _modo = {
            adicionando: !readOnly,
        };
        vm.tracker = {};
        vm.tracker.saveContinue = promiseTracker();
        vm.tracker.save = promiseTracker();
        vm.tracker.remove = promiseTracker();
        vm.select2 = _select2;
        vm.salvar = _save;
        vm.modo = _modo;
        vm.equipeDiretiva =  null;
        vm.estabelicimento = {'id':id || -1} ;
        vm.formatterTelefones = formatterTelefones;

        _init();

        
       

        function _init() {
            if (_modo.editando) {
                vm.windowTitle = 'Editando vínculo';
            } else {
                vm.windowTitle = 'Adicionando vínculo';
            }
            $modalTracker.addPromise(
                $q.all([
                    (function() {
                        if (vm.form) {
                            vm.form.$setPristine();
                        }
                    })(),
                ]).then(
                    function postInit() {}
                )
            );

        }

        $scope.$watch('vm.equipeDiretiva', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.equipeDiretiva = newValue;
            }
        });

        function _save(continuar) {
            var tracker = continuar ? vm.tracker.saveContinue : vm.tracker.save;
            $rootScope.$broadcast('refresh::EquipeDiretiva', {
                data: vm.equipeDiretiva,
                add: true,
                edit: false
            });
            onSuccess(continuar, tracker);
        }


        function onSuccess(continuar, tracker) {
            if (continuar) {
                vm.equipeDiretiva = null;
                tracker.addPromise();
            } else {
                _cancel();
            }
        }

        function _cancel() {
            $modalInstance.close();
        }

        
        function formatterTelefones(telefone){
                var bfMaskPhone = $filter('bfMaskPhone');
                return bfMaskPhone(telefone.telefone) + (_.isEmpty(telefone.descricao) ? '' : ' (' + telefone.descricao + ')');
        }

    }


})();

(function () {
    'use strict';
    angular.module('educacao.funcionario').directive('appTabFuncionarioFormacaoContinua', Directive);

    function Directive() {

        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-formacao/formacao-continua/app-funcionario-formacao-continua.directive.html',
            scope: {},
            bindToController: {
                funcionario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {

        var vm = this;

        vm.removerLinha = removerLinha;
        vm.adicionarLinha = adicionarLinha;

        initController();

        function initController() {
            if(!vm.funcionario.cursosAperfeicoamento) {
                vm.funcionario.cursosAperfeicoamento = [];
            }
        }

        function removerLinha(list, index) {
            list.splice(index, 1);
        }

        function adicionarLinha(list) {
            list.push({});
        }
    }
})();
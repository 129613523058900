(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.AlunoImportacaoCadCtrl', AlunoImportacaoCadCtrl);

    AlunoImportacaoCadCtrl.$inject = [
        '$modalInstance',
        'promiseTracker',
        'bfc.Focus',
        'educacao.pessoas.AlunoImportacaoService',
        'bfc.Notification'
    ];

    function AlunoImportacaoCadCtrl(
        $modalInstance,
        promiseTracker,
        focus,
        AlunoImportacaoService,
        Notification) {

        var vm = this;

        vm.save = save;

        focus('chave');
        vm.tracker = {
            loading: promiseTracker()
        };

        function save() {
            var tokenAcesso = {
                CHAVE_ACESSO: vm.token
            };
            vm.tracker.loading.addPromise(
                AlunoImportacaoService.save(tokenAcesso)
                    .then(onSuccess)
                    .then(notificar)
            );

        }

        function notificar() {
            Notification.publish('Aluno enviado para importação', 'success');
        }

        function onSuccess() {
            $modalInstance.close();
        }
    }
})();

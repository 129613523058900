(function() {

    'use strict';

    angular.module('educacao.itemAvaliavel')
        .constant('ITEM_AVALIAVEL_TIPO_PERIODO', {
            INSTRUMENTO: 'INSTRUMENTO',
            TIPO_AVALIACAO: 'TIPO_AVALIACAO',
            MEDIA_PERIODO: 'MEDIA_PERIODO',
            EXAME_FINAL: 'EXAME_FINAL',
            MEDIA_FINAL: 'MEDIA_FINAL',
            CALCULO_MEDIA: 'CALCULO_MEDIA',
            NOTA_PARA_EXAME: 'NOTA_PARA_EXAME',
            CONSELHO_CLASSE: 'CONSELHO_CLASSE',
            CALCULO_INSTRUMENTOS: 'CALCULO_INSTRUMENTOS',
            MODULO: 'MODULO',
            CHA_OBJETIVO_MEDIA_PERIODO: 'CHA_OBJETIVO_MEDIA_PERIODO',
            CHA_OBJETIVO_EXAME_FINAL: 'CHA_OBJETIVO_EXAME_FINAL'
        });

})();


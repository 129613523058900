(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MatriculaListController', MatriculaListController);

    var SITUACOES_MOVIMENTAR = ['CURSANDO', 'MATRICULADO'];
    var SITUACOES_REMANEJAR = ['CURSANDO'];
    var SITUACOES_READMINITIR = ['CANCELAMENTO', 'TRANSFERIDO', 'DEIXOU_FREQUENTAR'];

    var SITUACOES_RECLASSIFICAR = ['CURSANDO', 'MATRICULADO'];
    var TIPOS_MATRICULAS_RECLASSIFICAR = ['EDUCACAO_BASICA', 'EJA'];
    var SITUACAOES_ALTERA_SITUACAO = ['APROVADO', 'APROVADO_DEPENDENCIA', 'REPROVADO', 'REPROVADO_FREQUENCIA', 'APROVADO_CONSELHO'];

    var SITUACOES_DESFAZER_EJA_MODULAR = ['CANCELAMENTO', 'TRANSFERIDO', 'DEIXOU_FREQUENTAR'];

    MatriculaListController.$inject = [
        '$q',
        'educacao.common.$commons',
        '$modalStates',
        'educacao.context.ContextoEstabelecimento',
        'educacao.matricula.MatriculaService',
        'educacao.matriz.MatrizService',
        'educacao.matricula.BoletimService',
        'educacao.common.ModalConfirm',
        'bfc.Notification',
        'bfc.$$PermissionsService',
        '$rootScope',
        '$scope',
        'educacao.common.ModalAlunoService',
        'ui.components.Modal',
        'educacao.pessoas.AlunoService',
        'educacao.matriz.EtapaService'
    ];

    function MatriculaListController(
        $q,
        $commons,
        $modalStates,
        ContextoEstabelecimento,
        MatriculaService,
        MatrizService,
        BoletimService,
        modalConfirmService,
        bfcNotification,
        PermissionsService,
        $rootScope,
        $scope,
        ModalAlunoService,
        $modal,
        AlunoService,
        EtapaService
    ) {
        var vm = this;

        vm.isRevokeAlterarSituacao = PermissionsService.isRevokedOperation('MatriculaPageMapping', 'alterarSituacao');
        vm.hasPermissionToCriarInscricaoListaEspera = !PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'criar');
        vm.hasPermissionToListarListaEspera = !PermissionsService.isRevokedOperation('ConfiguracaoParaListaEsperaPageMapping', 'listar');

        vm.MatriculaService = MatriculaService;
        vm.tracker = {
            list: $commons.$promiseTracker(),
            alterando: $commons.$promiseTracker()
        };

        vm.advancedFilter = {
            filtroPadraoMatricula: {},
            filtroPadraoAluno: {},
            dadosPessoais: {},
            documentos: {},
            caracteristicas: {}
        };

        vm.expressionCustomBuilders = {
            dataInicial: function (filters) {
                if (filters.dataInicial) {
                    return 'data >= ' + filters.dataInicial;
                }
            },
            dataFinal: function (filters) {
                if (filters.dataFinal) {
                    return 'data <= ' + filters.dataFinal;
                }
            }
        };

        vm.successfulSave = false;
        vm.changeSituacao = changeSituacao;
        vm.desabilitaCampoSituacao = desabilitaCampoSituacao;
        vm.adicionaFlagsMatricula = adicionaFlagsMatricula;
        vm.ishabilitaPadrao = ishabilitaPadrao;
        vm.hasEstabelecimento = hasEstabelecimento;
        vm.openMatriculaModal = openMatriculaModal;
        vm.filterResultEnunsEspecificos = filterResultEnunsEspecificos;
        vm.openMovimentacaoCancelamentoModal = openMovimentacaoCancelamentoModal;
        vm.desfaz = desfaz;
        vm.exclui = exclui;
        vm.refreshHistorico = refreshHistorico;
        vm.openMovimentacaoDeixouFrequentarModal = openMovimentacaoDeixouFrequentarModal;
        vm.openMovimentacaoTransferenciaModal = openMovimentacaoTransferenciaModal;
        vm.openMovimentacaoFalecimentoModal = openMovimentacaoFalecimentoModal;
        vm.openMovimentacaoReadmissao = openMovimentacaoReadmissao;
        vm.openReclassificacaoModal = openReclassificacaoModal;
        vm.openModalAluno = openModalAluno;
        vm.openModalHistoricosEscolares = openModalHistoricosEscolares;
        vm.filterResultEnuns = filterResultEnuns;
        vm.getDescriptionEnumSituacaoMatricula = getDescriptionEnumSituacaoMatricula;
        vm.parametrosGerenciadorRelatorios = {};

        vm.hasPermissionToEditAluno = hasPermissionToEditAluno;
        vm.openRemanejamentoInterno = openRemanejamentoInterno;

        init();

        vm.getParametros = getParametros;
        vm.parametrosMatriculasSelecionadas = parametrosMatriculasSelecionadas;
        vm.emitirRelatorio = emitirRelatorio;

        $scope.$watchCollection('vm.selectedItens', atualizarMatriculasGerenciador);

        function hasPermissionToEditAluno() {
            return !PermissionsService.isRevokedOperation('AlunoPageMapping', 'editar');
        }

        function openModalAluno(aluno) {
            if (!hasPermissionToEditAluno()) {
                return;
            }

            ModalAlunoService.openByIdAluno(aluno.id, true).result.then(setAlunoFromData);

            function setAlunoFromData(data) {
                if (data) {
                    aluno.foto = null;
                    aluno.nome = _.get(data, 'pessoa.nome');
                    aluno.alunoDeficiencias = data.alunoDeficiencias;
                    aluno.possuiSuperdotacao = data.possuiSuperdotacao;
                    aluno.foto = data.foto;
                    aluno.necessitaAEE = data.necessitaAEE;
                    aluno.transtornosGlobais = data.transtornosGlobais;
                }
            }
        }

        function openModalHistoricosEscolares(aluno) {
            $modal.open({
                templateUrl: 'historico-escolar/historico-escolar.html',
                controller: 'educacao.historicoEscolar.HistoricoEscolarCtrl',
                controllerAs: 'vm',
                params: {
                    aluno: AlunoService.get(aluno.id)
                },
                size: 'xxl'
            });
        }

        function parametrosMatriculasSelecionadas() {
            if (!vm.selectedItens) {
                return {};
            }
            var ids = _.map(vm.selectedItens, 'id');
            return { matriculas: ids };
        }

        function emitirRelatorio(relatorio, parametros) {
            return BoletimService.enviarGerenciador(relatorio, parametros);
        }

        function getParametros() {
            var matriculas = getSelectItens();
            var parametros = [];
            if (_.get(matriculas, 'length')) {
                _.forEach(matriculas, function (data) {
                    parametros.push({
                        'idMatricula': data.id,
                        'idAluno': data.aluno.id,
                        'idMatriz': _.get(data.matriz, 'id'),
                        'idTurma': _.get(data.turma, 'id'),
                        'situacao': data.situacao,
                        'idEstabelecimento': _.get(data.estabelecimento, 'id'),
                        'turno': data.turno,

                    });
                });
            }
            return {
                'idMatricula': _.pluck(parametros, 'idMatricula'),
                'idAluno': _.pluck(parametros, 'idAluno'),
                'idMatriz': _.pluck(parametros, 'idMatriz'),
                'idTurma': _.pluck(parametros, 'idTurma'),
                'situacao': _.pluck(parametros, 'situacao'),
                'idEstabelecimento': _.pluck(parametros, 'idEstabelecimento'),
                'turno': _.pluck(parametros, 'turno')
            };
        }



        function atualizarMatriculasGerenciador() {
            vm.parametrosGerenciadorRelatorios = {};
            if (vm.selectedItens) {
                var ids = _.map(vm.selectedItens, 'id');
                vm.parametrosGerenciadorRelatorios = { matriculas: ids };
            }

        }

        function filterResultEnuns(term, value) {
            if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                return value;
            }
        }

        function filterResultEnunsEspecificos(term, value) {

            if (_.includes(SITUACAOES_ALTERA_SITUACAO, value.key)) {
                if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                    return value;
                }
            }
        }

        function getDescriptionEnumSituacaoMatricula(situacao) {
            return vm.SituacaoMatricula[situacao].description;
        }

        function init() {
            vm.TurnoPromise = MatriculaService.getEnum('Turno').then(function (Turno) {
                vm.Turno = Turno;
                return Turno;
            });

            vm.TipoMatriculaPromise = MatriculaService.getEnum('TipoMatricula').then(function (TipoMatricula) {
                vm.TipoMatricula = TipoMatricula;
                return TipoMatricula;
            });

            vm.SituacaoMatriculaPromise = MatriculaService.getEnum('SituacaoMatricula').then(function (SituacaoMatricula) {
                vm.SituacaoMatricula = SituacaoMatricula;
                return SituacaoMatricula;
            });

            MatrizService.getEnums().then(function (enums) {
                vm.NivelEscolar = _.indexBy(enums.NivelEscolar, 'key');
            });
        }


        function adicionaFlagsMatricula(matricula) {
            var situacao = matricula.situacao;
            var tipoMatricula = matricula.tipo;
            var isModular = (matricula.matriz) ? !matricula.matriz.modular : true;

            matricula.$$podeMovimentar = SITUACOES_MOVIMENTAR.indexOf(situacao) !== -1;
            matricula.$$podeReadmitir = SITUACOES_READMINITIR.indexOf(situacao) !== -1;
            matricula.$$podeRemanejar = SITUACOES_REMANEJAR.indexOf(situacao) !== -1 && isModular;
            matricula.$$podeReclassificar = SITUACOES_RECLASSIFICAR.indexOf(situacao) !== -1 &&
                TIPOS_MATRICULAS_RECLASSIFICAR.indexOf(tipoMatricula) !== -1 && isModular;

            switch (situacao) {
                case 'TRANSFERIDO':
                    matricula.$$acaoDesfazer = 'Transferência';
                    break;
                case 'DEIXOU_FREQUENTAR':
                    matricula.$$acaoDesfazer = 'Deixou de frequentar';
                    break;
                case 'FALECIDO':
                    matricula.$$acaoDesfazer = 'Falecimento';
                    break;
                case 'CANCELAMENTO':
                    matricula.$$acaoDesfazer = 'Cancelamento';
                    break;
                case 'RECLASSIFICADO_AVANCO':
                    matricula.$$acaoDesfazer = 'Reclassificação - Avanço';
                    break;
                case 'RECLASSIFICADO_ACELERACAO':
                    matricula.$$acaoDesfazer = 'Reclassificação - Aceleração';
                    break;
                default:
                    matricula.$$acaoDesfazer = null;
            }
            matricula.$$podeDesfazer = Boolean(matricula.$$acaoDesfazer);
        }

        function hasEstabelecimento() {
            return !ContextoEstabelecimento.isSecretaria();
        }

        function openMatriculaModal(matricula) {
            $modalStates.goModalState(matricula);
        }

        function openMovimentacaoCancelamentoModal(matriculaId) {
            openGenericMovimentacaoModal(matriculaId, 'CANCELAMENTO');
        }

        function desfaz(matricula) {
            var promise;

            if (isEjaModular(matricula) && _.includes(SITUACOES_DESFAZER_EJA_MODULAR, matricula.situacao)) {
                promise = MatriculaService.desfazerMovimentacaoEjaModular(matricula.id);
            } else {
                promise = MatriculaService.desfazerMovimentacao(matricula.id);
            }

            vm.tracker.list.addPromise(promise);
        }

        function openMovimentacaoDeixouFrequentarModal(matriculaId) {
            openGenericMovimentacaoModal(matriculaId, 'DEIXOU_DE_FREQUENTAR');
        }

        function openMovimentacaoTransferenciaModal(matriculaId) {
            openGenericMovimentacaoModal(matriculaId, 'TRANSFERENCIA');
        }

        function openMovimentacaoFalecimentoModal(matriculaId) {
            openGenericMovimentacaoModal(matriculaId, 'FALECIMENTO');
        }

        function openMovimentacaoReadmissao(matricula) {
            if (!matricula || !matricula.id) {
                return;
            }
            $commons.$modal.open({
                templateUrl: 'matricula/matriculas/movimentacao/movimentacao-readmissao.modal.html',
                controller: 'educacao.matricula.MovimentacaoReadmissaoModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    matricula: MatriculaService.get(matricula.id)
                }
            });
        }

        function openGenericMovimentacaoModal(matriculaId, tipo) {
            if (!matriculaId) {
                return;
            }
            return $commons.$modal.open({
                templateUrl: 'matricula/matriculas/movimentacao/movimentacao-generica.modal.html',
                controller: 'educacao.matricula.MovimentacaoGenericaModalCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    tipoMovimentacao: tipo,
                    matricula: MatriculaService.get(matriculaId)
                }
            });
        }

        function openReclassificacaoModal(matricula) {
            return $commons.$modal.open({
                templateUrl: 'matricula/matriculas/reclassificacao/reclassificacao-modal.html',
                controller: 'educacao.matricula.ReclassificacaoModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    matricula: matricula
                }
            }).result.then(function () {
                MatriculaService.emit('dirty');
            });
        }

        function openRemanejamentoInterno(matricula) {

            return $commons.$modal.open({
                templateUrl: 'matricula/enturmacao/remanejamento.cad.html',
                controller: 'educacao.matricula.RemanejamentoCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    enturmacao: MatriculaService.getEnturmacaoAtiva(matricula.id),
                    etapaMatriz: _.get(matricula, 'etapaMatriz.id') ? EtapaService.get(matricula.matriz.id, matricula.etapaMatriz.id) : null
                },
            });

        }

        function getSelectItens() {
            return vm.selectedItens;
        }

        function ishabilitaPadrao() {
            return !_.get(vm.selectedItens, 'length');
        }

        function changeSituacao(matricula) {
            if (!_.get(matricula, 'situacao')) {
                bfcNotification.publish(
                    'A situação da matrícula precisa ser informada',
                    'error');
                return;
            }

            vm.tracker.alterando.addPromise(
                MatriculaService.trocaStatus(matricula.id, matricula.situacao)
            ).then(vm.successfulSave = true);

        }

        function desabilitaCampoSituacao(value) {
            return !(_.includes(SITUACAOES_ALTERA_SITUACAO, value.situacao) || _.isEmpty(value.situacao));
        }

        function refreshHistorico(matricula) {
            $rootScope.$broadcast('refresh-historico-matricula-' + _.get(matricula, 'id'));
        }

        function isEjaModular(matricula) {
            return matricula.tipo === 'EJA' && _.get(matricula, 'matriz.modular');
        }

        function exclui(matricula) {
            var hasPermissionToRemoveCascade = !PermissionsService.isRevokedOperation('MatriculaPageMapping', 'excluirVinculos');
            if (hasPermissionToRemoveCascade) {
                openForceRemoveMatricula(matricula);
                return $q.reject();
            }

            if (matricula.origemMatricula === 'RECLASSIFICACAO') {
                modalConfirmService({
                    message: 'Ao excluir este registro, a matrícula de origem voltará para a situação Cursando. Deseja continuar?'
                }).then(function () {
                    return MatriculaService.remove(matricula);
                });
            } else {
                return MatriculaService.remove(matricula);
            }
        }

        function openForceRemoveMatricula(matricula) {
            $commons.$modal.open({
                templateUrl: 'matricula/matriculas/remove-matricula-cascade/remove-matricula-cascade.html',
                controller: 'educacao.matricula.RemoveMatriculaCascadeController as vm',
                size: 'sm',
                params: {
                    matricula: matricula
                }
            });
        }
    }
})();

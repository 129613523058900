(function () {

    'use strict';

 angular.module('educacao.preparacaoAnoLetivo')
        .controller('educacao.preparacaoAnoLetivo.PreparacaoAnoLetivoController', PreparacaoAnoLetivoController);

    PreparacaoAnoLetivoController.$inject = [
        '$scope',
        'bfc.Notification',
        'promiseTracker',
        '$modalInstance',
        'educacao.context.ContextoEstabelecimento',
        'educacao.preparacaoAnoLetivo.PreparacaoAnoLetivoService'
    ];

    function PreparacaoAnoLetivoController($scope, 
        bfcNotification, 
        promiseTracker, 
        $modalInstance,
        ContextoEstabelecimento,
        PreparacaoAnoLetivoService) {
        var vm = this;

        vm.copiar = copiar;
        vm.onChangeEstabelecimentoOrigem = onChangeEstabelecimentoOrigem; 

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();

        vm.tracker = {
            copia: promiseTracker()
        };

        init();

        function init() {
            vm.copia = {};

            if(!vm.isSecretaria) {
                vm.copia.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
                vm.copia.estabelecimentoDestino = ContextoEstabelecimento.getEstabelecimento();
            }
        }

        function copiar() {
            var dadosCopia = angular.copy(vm.copia);
            
            if (vm.copia.estabelecimento.isSecretaria) {
                _.extend(dadosCopia, {
                    estabelecimento: null,
                    estabelecimentoDestino: null
                });
            }

            var promise = PreparacaoAnoLetivoService.copiar(dadosCopia)
                .then(function() {
                    bfcNotification.publish('Processo da cópia está em andamento. Ao término do processamento, você será notificado.', 'info');
                    $modalInstance.close();
                });
            vm.tracker.copia.addPromise(promise);
        }   

        function onChangeEstabelecimentoOrigem() {
            vm.copia.estabelecimentoDestino = angular.copy(vm.copia.estabelecimento);
            $scope.$applyAsync();
        }
    }

})();
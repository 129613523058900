(function () {
    'use strict';

    angular.module('educacao.matriz').directive('appSelectCurso', SelectCurso);

    function SelectCurso() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add" />',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngChange: '=',
                multiple: '@'
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }
                if (angular.isDefined(tAttrs.ngRequired)) {
                    element.children('ui-select').attr('not-empty', 'ngRequired');
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.matriz.CursoService', '$scope'];

    function Controller($modal, PermissionsService, service, $scope) {

        var vm = this;

        vm.search = search;
        vm.add = !PermissionsService.isRevokedOperation('CursoPageMapping', 'criar') && add;
       
        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function search(params) {
            params.filter = '(descricao like "' + params.term + '")';
            return service.getListByParams(params);
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'planejamento/matriz/curso/cursos.cad.html',
                controller: 'educacao.matriz.CursoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    curso: {
                        descricao: descricao
                    },
                    canSaveAdd: false,
                    situacaoAtivo: true
                }
            }).result;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matriz').directive('appSelectMatriculaEtapaMatriz', SelectMatriculaEtapaMatrizDirective);

    function SelectMatriculaEtapaMatrizDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ngclass="ngclass" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchEtapa" ng-disabled="ngDisabled"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                matriz: '=?',
                ngclass: '=',
                ngChange: '&?',
                estabelecimento: '=?',
                multiturno: '='
            },
            controller: SelectMatriculaEtapaMatrizDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectMatriculaEtapaMatrizDirectiveController.$inject = ['$scope', 'educacao.matricula.QuadroVagasService'];

    function SelectMatriculaEtapaMatrizDirectiveController($scope, service) {

        var vm = this;

        var templateBuscaEtapaMatriz = _.template('matriz =<%= matriz %> and estabelecimento=<%= estabelecimento %>');

        vm.searchEtapa = searchEtapa;

        function searchEtapa(params) {

            var etapas = [];

            var idMatriz = _.get($scope.matriz, 'id') || -1;
            var idEstabelecimento = _.get($scope.estabelecimento, 'id') || -1;

            if ($scope.multiple) {
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(etapa) {
                        etapas.push(etapa.id);
                    });
                }
            }

            var filter = templateBuscaEtapaMatriz({
                matriz: idMatriz,
                estabelecimento: idEstabelecimento
            });

            if (etapas.length) {
                filter += ' and id not in(' + etapas.join() + ')';
            }


            return service.getQuadroDeVagasComVagaTodos(filter, $scope.multiturno)
                .then(function(list) {

                    var term = decodeURI(params.term).replace(/%/g, ' ').trim();

                    var filtrados = _.filter(list, function(item) {
                        return item.descricao.toLowerCase().contains(term.toLowerCase());
                    });

                    return {
                        content: filtrados,
                        hasNext: false
                    };
                });
        }
    }
})();


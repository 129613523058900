(function() {

    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.MatrizSelect', ['$q',
            'educacao.common.ModalCad',
            'educacao.common.ObjectUtilsService',
            'educacao.matriz.MatrizService',
            'bfc.Select2Config',
            'bfc.$$PermissionsService',
            function($q,
                ModalCad,
                ObjectUtilsService,
                MatrizService,
                Select2Config,
                PermissionsService
            ) {

                var onAdd = PermissionsService.isRevokedOperation('MatrizCurricularPageMapping', 'criar') ? undefined : add;

                return {
                    select2Config: function(options) {
                        return Select2Config.create(angular.extend({
                            onAdd: onAdd,
                            result: function(params, callback) {
                                var term = ObjectUtilsService.normalizeFilter(params.term);
                                term = encodeURI(term);
                                
                                var result,
                                    filter = '(descricao like "' + term + '")';

                                result = MatrizService.getList(params.offset, params.limit, filter, undefined);
                                result.then(function(data) {
                                    callback({
                                        results: data,
                                        more: false
                                    });
                                });

                                return result;
                            },
                            formatValue: function(data) {
                                return data;
                            },
                            formatResult: function(data) {
                                return data.text ?
                                    data.text :
                                    data.descricao;
                            },
                            formatSelection: function(data) {
                                return data.descricao;
                            },
                            initSelection: function(element, callback) {
                                var id = $(element).val();
                                if (id) {
                                    MatrizService.get(id).then(callback, function() {
                                        callback({
                                            id: null
                                        });
                                    });
                                }
                            }
                        }, options));
                    }
                };

                function add(val, target) {
                    ModalCad.open({
                        templateUrl: 'planejamento/matriz/matriz/matriz.cad.html',
                        controller: 'educacao.matriz.MatrizCadCtrl',
                        optionsCad: {
                            'descricao': val
                        },
                        selectElem: target
                    });
                    return false;
                }
            }
        ])

    .factory('educacao.matriz.EtapaSelect', ['$q',
        'educacao.common.ModalCad',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.MatrizService',
        'educacao.matriz.EtapaService',
        'bfc.Select2Config',
        function($q,
            ModalCad,
            ObjectUtilsService,
            MatrizService,
            EtapaService,
            Select2Config
        ) {
            return {
                select2Config: function(options) {
                    return Select2Config.create(angular.extend({
                        matriz: {},
                        result: function(params, callback) {
                            var matriz = this.matriz;
                            return EtapaService.getList(this.matriz.id).then(function(data) {

                                var listaEtapas = _.map(data, function(etapa) {
                                    return _.extend(etapa, {
                                        descricao: MatrizService.getDescricaoEtapa(matriz, etapa)
                                    });
                                });

                                callback({
                                    results: _.filter(listaEtapas, function(objeto) {
                                        return _.property('descricao')(objeto).search(params.term.slice(1, -1).replace('%', ' ')) > -1;
                                    }),
                                    more: false
                                });
                            });
                        },
                        formatValue: function(data) {
                            return data;
                        },
                        formatResult: function(data) {
                            return data.text ?
                                data.text :
                                data.descricao;
                        },
                        formatSelection: function(data) {
                            return data.descricao;
                        },
                        initSelection: function(element, callback) {
                            var id = $(element).val();
                            if (id) {
                                EtapaService.get(id).then(callback, function() {
                                    callback({
                                        id: null
                                    });
                                });
                            }
                        }
                    }, options));
                }

            };
        }
    ]);
})();
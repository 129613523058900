(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('classificacaoGrupo', classificacaoGrupo);

    function classificacaoGrupo() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/classificacao-inscritos/directives/classificacao-grupos.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                group: '=',
                lista: '=',
                configuracao: '=',
                processo: '=',
                chamada: '=',
                hasTerceiroGrupo: '=',
                edit: '=',
                indeferir: '=',
                desfazerIndeferimento: '=',
                showUtilizaEncaminhamento: '=',
                encaminhar: '=',
                matricular: '=',
                nivelEscolar: '=',
                listaEspera: '=',
                visualizarInscricao: '=',
                resultadoClassificacaoEncaminhamento: '=',
                utilizaZoneamento: '='
            }
        };
    }

    Controller.$inject = [
        '$scope', 
        'promiseTracker', 
        'bfc.$$PermissionsService',
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
		'Restangular',
        'educacao.matricula.InscricaoMatriculaService',
		'ui.components.Modal',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
		'bfc.Notification',
        '$rootScope'
    ];

    function Controller($scope, promiseTracker, PermissionsService, ConfigListaEsperaService, Restangular, InscricaoMatriculaService, uiModal, InscricaoListaEsperaService, bfcNotification, $rootScope) {

        var vm = this;
        vm.CLASSIFICADO_COM_VAGA = 'CLASSIFICADO_COM_VAGA';
        vm.CLASSIFICADO_SEM_VAGA = 'CLASSIFICADO_SEM_VAGA';
        vm.INDEFERIDO = 'Indeferidos';

        vm.total = total;
        vm.edit = $scope.edit;
        vm.indeferir = $scope.indeferir;
        vm.matricular = $scope.matricular;
        vm.desfazerIndeferimento = $scope.desfazerIndeferimento;
        vm.listaEspera = $scope.listaEspera;
        vm.visualizarInscricao = $scope.visualizarInscricao;

        vm.hasPermissionToCriarInscricaoListaEspera = !PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'criar');
        vm.hasPermissionToListarListaEspera = !PermissionsService.isRevokedOperation('ConfiguracaoParaListaEsperaPageMapping', 'listar');

        vm.trackerLoading = promiseTracker();
        vm.trackerInscritosPorGrupo = {};
        vm.hasVaga = hasVaga;
        vm.getListaEsperaConfigs = getListaEsperaConfigs;
        vm.abreCadastro = abreCadastro; 
        vm.onCheckAluno = onCheckAluno;
        vm.onCheckAll = onCheckAll;
        vm.countCandidatosSelected = countCandidatosSelected;
        vm.encaminhamentoUnico = encaminhamentoUnico;
        vm.setZoneamentoAndPreferenciaInscritos = setZoneamentoAndPreferenciaInscritos;

        vm.isEncaminhamentoListaEspera = true;
        vm.canSearchInscricoesEstabelecimentos = false;
        vm.countCandidatosSelected = {};
        vm.estabelecimentosInscricao = [];

        watchFields();
        $rootScope.$on('encaminhamentoLoteItemDeleted', uncheckCheckboxOnDeleteEvent);

        function watchFields() {

            $scope.$watch('group', onGroup);
            $scope.$watch('lista', onList);

            function onGroup(group) {
                vm.lista = create($scope.lista, agrupa(group));
                createCheckAllObject();
                createPromiseObject();
            }

            function onList(lista) {
                vm.lista = create(lista, agrupa());
                createCheckAllObject();
                createPromiseObject();
            }

        }

        function agrupa(group) {
            if (!group) {
                group = $scope.group;
            }
            return $scope.configuracao.groups[group - 1];
        }

        function create(lista, group) {
            return listaAgrupada(lista, group);
        }

        function listaAgrupada(lista, grupos) {
            return gruposAninhados(_.sortByAll(lista, grupos), grupos);
        }

        function gruposAninhados(seq, keys) {
            if (!keys.length) {
                return seq;
            }
            var first = keys[0];
            var rest = keys.slice(1);

            return _.mapValues(_.groupBy(seq, first), function(value) {
                return gruposAninhados(value, rest);
            });
        }

        function createPromiseObject() {
            vm.trackerInscritosPorGrupo = {};
            trackGroupPromisesRecursively(vm.lista, vm.trackerInscritosPorGrupo);
        }

        function trackGroupPromisesRecursively(group, tracker) {
            _.forEach(group, function(subGroup, index) {
                tracker[index] = !_.isArray(subGroup) ? {} : promiseTracker();
                if (!_.isArray(subGroup)) {
                    trackGroupPromisesRecursively(subGroup, tracker[index]);
                }
            });
        }

        function createCheckAllObject() {
            vm.checkAll = {};
                _.forEach(vm.lista, function(primeiroAgrupador, primeiroAgrupadorIndex) {
                    vm.checkAll[primeiroAgrupadorIndex] = {};
                    _.forEach(primeiroAgrupador, function(segundoAgrupador, segundoAgrupadorIndex) {
                        vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex] = {};
                        _.forEach(segundoAgrupador, function(terceiroAgrupador, terceiroAgrupadorIndex) {
                            vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex][terceiroAgrupadorIndex] = {};
                            vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex][terceiroAgrupadorIndex].checked = false;
                            vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex][terceiroAgrupadorIndex].disabled = false;
                        });
                    });
                });
        }
        
        function total(itens, level) {
            var count = 0;
            _.forEach(itens, function(data) {
                if (level === 1) {
                    _.forEach(data, function(item) {
                        count += item.length;
                    });
                } else if (level === 2) {
                    count += _.map(data).length;
                } else {
                    count += data.length;
                }
            });
            return {
                total: count,
                descricao: count > 1 ? $scope.configuracao.nameTotalVarios : $scope.configuracao.nameTotalUm
            };
        }

        function hasVaga(item) {
			return Boolean(_.get(item, 'statusResultadoClassificacao') === vm.CLASSIFICADO_COM_VAGA);
		}

        function getListaEsperaConfigs(item) {
            vm.inscricaoId = item.resultadoClassificacao.inscricao.id;
			vm.trackerLoading.addPromise(
				ConfigListaEsperaService.listConfiguracaoByNivelEscolar($scope.processo.nivelEscolar).then(
					function (listas) {
						vm.listasEspera = listas;
					}
				)
			);
		}

        function encaminhamentoUnico(item) {
            $scope.encaminhar(item.resultadoClassificacao);
        }

        function abreCadastro(configuracaoId, configuracaoDescricao) {
			vm.trackerLoading.addPromise(InscricaoMatriculaService.get(vm.inscricaoId)
				.then(function (inscritoData) {
					getInscritos(configuracaoId)
						.then(function () {
							if (validateInscricaoDuplicada(inscritoData.dados)) {
								bfcNotification.publish('Já existe uma inscrição na lista de espera para o(a) candidato(a) ' + inscritoData.dados.nome + ' na configuração "' + configuracaoDescricao +'" com o mesmo nome, data de nascimento, sexo e filiação.', 'danger');
								return;
							}
				
							openUiModal(inscritoData, configuracaoId);
						});
				}
			));
        }

		function getInscritos(configuracaoId) {
            return InscricaoListaEsperaService.getInscritosListaEspera(configuracaoId)
                .then(function (data) {
                    vm.inscritosConfig = data.content;
                });
        }

		function openUiModal(inscritoData, configuracaoId) {
			var data = _.cloneDeep(inscritoData);
			uiModal.open({
				templateUrl: 'lista-espera-new/inscricao/cadastro-inscricao-lista-espera.html',
				controller: 'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaCadCtrl',
				controllerAs: 'vm',
				size: 'xxl',
				params: {
					configuracao: configuracaoId,
					inscricaoListaEspera: data.dados,
					observacao: data.observacao,
					isCopiaAlunoMatriculado: true,
					isAlunoInscricaoMatricula: true
				}
			});
		}

		function validateInscricaoDuplicada(inscricao) {
            var inscrito = _.find(vm.inscritosConfig, { 
                nome: inscricao.nome,
                sexo: inscricao.sexo,
                dataNascimento: inscricao.dataNascimento,
            });

            if (!inscrito) {
                return false;
            }

            var inscritoFiliacoesNames = _.map(inscrito.filiacoes, 'nome');
            return inscricao.filiacoes.some(function (filiacao) { 
                return inscritoFiliacoesNames.includes(filiacao.nome); 
            });
        }

        function onCheckAll(itensAgrupador, firstGroupIndex, secondGroupIndex, thirdGroupIndex, groupItems) {
            var checkAll = vm.checkAll[firstGroupIndex][secondGroupIndex][thirdGroupIndex].checked;

            _.forEach(itensAgrupador, function (item) {
                if (item.statusResultadoClassificacao !== vm.CLASSIFICADO_SEM_VAGA && !item.resultadoClassificacao.encaminhado) {
                    item.checked = checkAll;
                }
            });

            onCheck(firstGroupIndex, secondGroupIndex, thirdGroupIndex, groupItems, checkAll);
        }

        function onCheckAluno(firstGroupIndex, secondGroupIndex, thirdGroupIndex, groupItems, checked) {
            onCheck(firstGroupIndex, secondGroupIndex, thirdGroupIndex, groupItems, checked);
        }

        function onCheck(firstGroupIndex, secondGroupIndex, thirdGroupIndex, groupItems, checkSelected) {
            disableOtherGroupsCheckbox(firstGroupIndex, secondGroupIndex, thirdGroupIndex, checkSelected);
            setEncaminhamentoMultiplo(groupItems);
            countCandidatosSelected();
        }

        function disableOtherGroupsCheckbox(firstGroupIndex, secondGroupIndex, thirdGroupIndex, checkSelected) {
            iterateOverGroups(disableClassificacao, firstGroupIndex, secondGroupIndex, thirdGroupIndex, checkSelected);
            iterateOverGroups(disableCheckAll, firstGroupIndex, secondGroupIndex, thirdGroupIndex, checkSelected);
        }

        function countCandidatosSelected() {
            iterateOverGroupsWithCount(countClassificacoesChecked);
        }

        function uncheckCheckboxOnDeleteEvent(event, args) {
            var isEmptyArgs = _.isEmpty(args);
            var resultadoIds = !isEmptyArgs ? _.map(args, 'id') : [];

            createPromiseObject();
            iterateOverGroupsWithCount(uncheckClassificacaoOnDeleteEvent, isEmptyArgs, resultadoIds);
        }

        function iterateOverGroups(applyFunction, firstGroupIndex, secondGroupIndex, thirdGroupIndex, checkSelected) {
            _.forEach(vm.lista, function(primeiroAgrupador, primeiroAgrupadorIndex) {
                _.forEach(primeiroAgrupador, function(segundoAgrupador, segundoAgrupadorIndex) {
                    _.forEach(segundoAgrupador, function(terceiroAgrupador, terceiroAgrupadorIndex) {
                        _.forEach(terceiroAgrupador, function (classificacao) {
                            applyFunction(
                                classificacao,
                                firstGroupIndex, 
                                secondGroupIndex, 
                                thirdGroupIndex, 
                                primeiroAgrupadorIndex, 
                                segundoAgrupadorIndex, 
                                terceiroAgrupadorIndex,
                                checkSelected
                            );
                        });
                    });
                });
            });
        }

        function disableClassificacao(classificacao, firstGroupIndex, secondGroupIndex, thirdGroupIndex, primeiroAgrupadorIndex, segundoAgrupadorIndex, terceiroAgrupadorIndex, checkSelected) {
            if (primeiroAgrupadorIndex !== firstGroupIndex || segundoAgrupadorIndex !== secondGroupIndex || terceiroAgrupadorIndex !== thirdGroupIndex) {
                classificacao.disabled = checkSelected || vm.checkAll[firstGroupIndex][secondGroupIndex][thirdGroupIndex].checked;
                classificacao.checked = false;
            }
        }

        function disableCheckAll(_, firstGroupIndex, secondGroupIndex, thirdGroupIndex, primeiroAgrupadorIndex, segundoAgrupadorIndex, terceiroAgrupadorIndex, checkSelected) {
            if (primeiroAgrupadorIndex !== firstGroupIndex || segundoAgrupadorIndex !== secondGroupIndex || terceiroAgrupadorIndex !== thirdGroupIndex) {
                vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex][terceiroAgrupadorIndex].disabled = checkSelected || vm.checkAll[firstGroupIndex][secondGroupIndex][thirdGroupIndex].checked;
                vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex][terceiroAgrupadorIndex].checked = false;
            }
        }

        function iterateOverGroupsWithCount(applyFunction, isEmptyArgs, resultadoIds) {
            _.forEach(vm.lista, function(primeiroAgrupador, primeiroAgrupadorIndex) {
                var count = 0;
                _.forEach(primeiroAgrupador, function(segundoAgrupador, segundoAgrupadorIndex) {
                    _.forEach(segundoAgrupador, function(terceiroAgrupador, terceiroAgrupadorIndex) {
                        var countTerceiroAgrupador = 0;
                        _.forEach(terceiroAgrupador, function (classificacao) {
                            var result = applyFunction(classificacao, count, isEmptyArgs, resultadoIds);
                            countTerceiroAgrupador += result;
                            count += result;

                            vm.checkAll[primeiroAgrupadorIndex][segundoAgrupadorIndex][terceiroAgrupadorIndex].checked = !!countTerceiroAgrupador;
                        });
                    });
                });
                vm.countCandidatosSelected[primeiroAgrupadorIndex] = count;
            });

            canEnableEveryCheckbox();
        }

        function countClassificacoesChecked(classificacao) {
            return classificacao.checked ? 1 : 0;
        }

        function uncheckClassificacaoOnDeleteEvent(classificacao, count, isEmptyArgs, resultadoIds) {
            if (isEmptyArgs || !resultadoIds.includes(classificacao.resultadoClassificacao.id)) {
                classificacao.checked = false;
                if (isEmptyArgs) {
                    classificacao.disabled = false;
                    createCheckAllObject();
                }
            }

            return countClassificacoesChecked(classificacao);
        }

        function setEncaminhamentoMultiplo(groupItems) {
            $scope.resultadoClassificacaoEncaminhamento = _.filter(groupItems, 
                function (itemGroup) { 
                    return itemGroup.checked; 
                }
            );
        }

        function canEnableEveryCheckbox() {
            var isAllGroupsUnchecked = _.every(vm.countCandidatosSelected, function (item) {
                return !item;
            });          

            if (isAllGroupsUnchecked) {
                iterateOverGroups(enabledCheckbox);
                createCheckAllObject();
            }
        }

        function enabledCheckbox(classificacao) {
            classificacao.disabled = false;
            classificacao.checked = false;
        }

        function setZoneamentoAndPreferenciaInscritos(inscritos, primeiroAgrupador, segundoAgrupador, terceiroAgrupador) {
            var notCachedIds = getNotCachedIds(inscritos);

            if (!_.isEmpty(notCachedIds)) {
                return getInsritosEstabelecimentos(notCachedIds, inscritos, vm.trackerInscritosPorGrupo[primeiroAgrupador][segundoAgrupador][terceiroAgrupador]);   
            }

            setZoneamentoAndOrdemPreferencia(inscritos);
        }

        function getNotCachedIds(inscritos) {
            var inscricoesIds = _.map(inscritos, function (item) { 
                return item.resultadoClassificacao.inscricao.id; 
            });

            if (!_.isEmpty(vm.estabelecimentosInscricao)) {
                var cacheIds = _.map(vm.estabelecimentosInscricao, 'id');
                return _.difference(inscricoesIds, cacheIds);
            }

            return inscricoesIds;
        }

        function getInsritosEstabelecimentos(inscricoesIds, inscritos, tracker) {
            var filter = 'id in (' + inscricoesIds.join(',') + ')';

            return tracker.addPromise(
                InscricaoMatriculaService.listByParams({ filter: filter })
                    .then(function (data) {
                        vm.estabelecimentosInscricao = vm.estabelecimentosInscricao.concat(_.get(data, 'content'));
                        
                        setZoneamentoAndOrdemPreferencia(inscritos);
                    })
            );
        }

        function setZoneamentoAndOrdemPreferencia(inscritos) {
			_.forEach(inscritos, function (item) {
                var inscricao = _.find(vm.estabelecimentosInscricao, { id: item.resultadoClassificacao.inscricao.id });
                var estabelecimentosInscrito = inscricao.estabelecimentos;
                var classificacaoInscricao = item.resultadoClassificacao.classificacaoInscricao;

                if (_.isEmpty(estabelecimentosInscrito)) {
                    classificacaoInscricao.estabelecimento.ordemPreferencia = null;
                    setZoneamento(item.resultadoClassificacao);
                    return;
                }
                
				setOrdemPreferencia(classificacaoInscricao, estabelecimentosInscrito);
			});
		}

        function setOrdemPreferencia(classificacao, estabelecimentosInscrito) {
			var estabelecimentoInscricao = _.find(estabelecimentosInscrito, { 
				'estabelecimento': {
					'id': classificacao.estabelecimento.id 
				},
				'turno': classificacao.turno
			}).estabelecimento;

			if (!estabelecimentoInscricao) {
				classificacao.estabelecimento.ordemPreferencia = null;
				return;
			}

            classificacao.estabelecimento.mesmoZoneamento = estabelecimentoInscricao.mesmoZoneamento;
			classificacao.estabelecimento.ordemPreferencia = estabelecimentoInscricao.ordemPreferencia;
		}

        function setZoneamento(resultadoClassificacao) {
            var idRegiaoEstab = resultadoClassificacao.dados.enderecoResidencia.regiao;
			var enderecoEstab = _.find(resultadoClassificacao.classificacaoInscricao.estabelecimento.enderecos, 'principal');

            if (!enderecoEstab || !idRegiaoEstab) {
				resultadoClassificacao.classificacaoInscricao.estabelecimento.mesmoZoneamento = false;
				return;
			}
		
			var zoneamentoInscricao = enderecoEstab.bairroEndereco.regiao;

			if (!zoneamentoInscricao) {
				resultadoClassificacao.classificacaoInscricao.estabelecimento.mesmoZoneamento = false;
				return;
			}

			resultadoClassificacao.classificacaoInscricao.estabelecimento.mesmoZoneamento = idRegiaoEstab === zoneamentoInscricao.id;
		}
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('tipoDocumentoConfiguracaoMatriculaSelect', SelectTipoDocumento);

    function SelectTipoDocumento() {
        return {
            restrict: 'E',
            template: '<ui-select dropdown-css-class="\'select2-spreadsheet\'" ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=ngRequired',
                lista: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.matricula.TipoDocumentoService'];

    function Controller($scope, $modal, service) {

        var vm = this;

        vm.search = search;
        vm.add = add;

        function search(params) {
            var filter = '(descricao like "' + params.term + '"';
            var tiposDocumentos = [];
            if ($scope.lista) {
                tiposDocumentos = [];
                if ($scope.lista.length) {
                    $scope.lista.forEach(function(documento) {
                        if (documento.tipoDocumento) {
                            tiposDocumentos.push(documento.tipoDocumento.id);
                        }
                    });
                }
                if (tiposDocumentos.length) {
                    filter += ' and id not in(' + tiposDocumentos.join() + ')';
                }
            }
            filter += ')';
            return service.list(filter, params.offset, params.limit);
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'matricula/tipo-documento/tipo-documento-cad.html',
                controller: 'educacao.matricula.TipoDocumentoCadCtrl',
                controllerAs: 'vm',
                size: '',
                params: {
                    tipoDocumento: {
                        descricao: descricao
                    }
                }
            }).result;
        }
    }
})();

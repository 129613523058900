(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.prototipo');

    moduloCommon.controller('educacao.prototipo.HorasAulasCtrl', ['$scope', '$timeout', 'educacao.common.ModalCad',
        function ($scope, $timeout, ModalCad) {

            $scope.HorasAulas = {};

            $scope.open = function (id) {
                ModalCad.open({
                    templateUrl: 'prototipo/horasaulas/horas.aulas.cad.html',
                    controller: 'educacao.prototipo.HorasAulasCadCtrl',
                    id: id,
                    readOnly: false
                });
            };

        }]);

    moduloCommon.controller('educacao.prototipo.HorasAulasCadCtrl', ['$scope',
        function ($scope) {
            $scope.HorasAulas = {};

        }]);

})();	
(function () {
    'use strict';

    angular.module('educacao.common')
        .factory('HeaderFilterBuilderService', HeaderFilterBuilderService);

        HeaderFilterBuilderService.$inject = ['EventEmitter', '$filter'];

    function HeaderFilterBuilderService(EventEmitter, $filter) {
        var self = new EventEmitter();  
        self.createRadiobox = createRadiobox;
        self.createCheckbox = createCheckbox;

        return self;

        function createRadiobox(listValues, headerFilterOptions, models, options) {
            _.remove(headerFilterOptions.radiobox, { id: options.id});
            if (!listValues) {
                return;
            }
            if (options.pathOrder) {
                var orderBy = $filter('orderBy');
                listValues = orderBy(listValues, options.pathOrder);
            }
            var position = _.get(headerFilterOptions, 'radiobox.length') || 0;
            headerFilterOptions.radiobox.push({
                id: options.id,
                description: options.description,
                filtrable: options.filtrable,
                onClick: onClickFilter,
                options: [],
                hide: Boolean(options.hide),
                hideClear: Boolean(options.hideClear),
                loadMore: options.loadMore,
                bfLoading: options.bfLoading
            });

            _.forEach(listValues, builderFilter);
            if (options.defaultValue) {
                models.radiobox.push(options.defaultValue);
                _.set(headerFilterOptions, ['radiobox', position, 'subDescription'], 
                    options.formatDescription ? options.formatDescription(options.defaultValue) : _.get(options.defaultValue, options.pathDescription));
                onClickFilter(models.radiobox);
            }
            

            function builderFilter(value) {
                var radioOptions = _.get(headerFilterOptions, ['radiobox', position, 'options']);
                var valor = options.pathValue ? _.get(value, options.pathValue) : value;
                radioOptions.push({
                    description: options.formatDescription ? options.formatDescription(valor) : valor,
                    value: valor
                });
            }

            function onClickFilter(value) {
                var filter = _.get(value, position);
                _.set(headerFilterOptions, ['radiobox', position, 'subDescription'], 
                    options.formatDescription ? options.formatDescription(filter) : _.get(filter, options.pathDescription));
                if (options.onClick) {
                    options.onClick(filter);
                }
            }
        }

        function createCheckbox(listValues, models, headerFilterOptions, options) {
            _.remove(headerFilterOptions.checkbox, { id: options.id });
            if (!listValues) {
                return;
            }
            models.checkbox.push(options.defaultValues || []);
            if (options.pathOrder) {
                var orderBy = $filter('orderBy');
                listValues = orderBy(listValues, options.pathOrder);
            }
            headerFilterOptions.checkbox.push(
                {
                    id: options.id,
                    filtrable: options.filtrable,
                    description: options.label,
                    onClick: onClick,
                    options: [],
                    selectAll: options.selectAll,
                    hideClear: Boolean(options.hideClear)
                }
            );

            _.forEach(listValues, builderFilter);

            function builderFilter(value) {
                _.find(headerFilterOptions.checkbox, { id: options.id }).options.push({
                    description: options.formatDescription ? options.formatDescription(value) : _.get(value, options.pathDescription),
                    value: options.pathValue ? _.get(value, options.pathValue) : value
                });
            }

            function onClick(value) {
                if (_.isEmpty(value) && options.filterAllOnClean) {
                    if (options.pathValue) {
                        value = _.pluck(listValues, options.pathValue);
                    } else {
                        value = listValues;
                    }
                }
                options.clickMethod(value);
            }
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .service('educacao.rematricula.RematriculaUtilsService', RematriculaUtilsService);

    RematriculaUtilsService.$inject = ['SITUACAO_REMATRICULA'];

    function RematriculaUtilsService(SITUACAO_REMATRICULA) {

        var self = this;

        self.isRematriculado = isRematriculado;

        function isRematriculado(situacao) {
            return situacao === SITUACAO_REMATRICULA.REMATRICULADO;
        }
    }

})();


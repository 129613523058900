(function() {
    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .controller('educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaCadCtrl', ConfiguracaoListaEsperaCadCtrl);

    ConfiguracaoListaEsperaCadCtrl.$inject = [
        '$scope',
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        'bfc.Notification',
        '$modalInstance',
        'promiseTracker',
        'educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaUtils',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils',
        'CONFIGURACAO_PASSOS'
    ];

    function ConfiguracaoListaEsperaCadCtrl($scope, ConfiguracaoListaEsperaService, bfcNotification, $modalInstance, promiseTracker,
        ConfiguracaoListaEsperaUtils, InscricaoListaEsperaService, InscricaoListaEsperaUtils, CONFIGURACAO_PASSOS) {
        var vm = this;

        vm.configuracaoPassos = CONFIGURACAO_PASSOS;
        vm.passo = vm.configuracaoPassos.PASSO_NIVEL_MODALIDADE;
        vm.niveisModalidades = setNivelModalidadePadrao();
        vm.tabNivelAtiva = '';
        vm.tabModalidadeAtiva = '';
        vm.configuracao = ConfiguracaoListaEsperaUtils.montaConfiguracaoEditando($modalInstance.params.configuracaoListaEspera, vm
            .niveisModalidades) || {};
        vm.editando = Boolean(vm.configuracao.id);
        vm.verificaFormulario = verificaFormulario;
        vm.formatEstabelecimento = formatEstabelecimento;
        vm.searchEstabelecimentos = searchEstabelecimentos;
        vm.proximaAba = proximaAba;
        vm.voltarAba = voltarAba;
        vm.setPasso = setPasso;

        vm.saveBase = saveBase;

        vm.remove = remove;

        vm.tracker = {};
        vm.tracker.loadingButtonContinuar = promiseTracker();
        vm.permissions = {
            save: {
                bfPermissions: 'app.lista-espera-novo.configuracao',
                operation: 'criar'
            },
            update: {
                bfPermissions: 'app.lista-espera-novo.configuracao',
                operation: 'editar'
            },
            remove: {
                bfPermissions: 'app.lista-espera-novo.configuracao',
                operation: 'excluir'
            }
        };

        init();

        function init() {
            setEstabelecimento();
        }

        function proximaAba() {
            vm.passo += 1;
        }

        function voltarAba() {
            vm.passo -= 1;
        }

        function setPasso(numeroStep) {
            vm.passo = numeroStep;
        }

        function verificaFormulario(form) {
            var modalidadeAtiva = _.find(vm.niveisModalidades, 'ativo');
            var criterios = vm.configuracao.utilizaCriterios ? validaCriterios(vm.configuracao.criterios) : true;
            form.$invalid = !(
                form.$valid &&
                modalidadeAtiva &&
                criterios &&
                _.find(modalidadeAtiva.listNivelEscolar, 'ativo')
            );
            return form;
        }

        function validaCriterios(criterios) {
            return !(criterios.find(function(criterio) {
                return (!criterio.somenteAdministrador && !criterio.usuarioExterno);
            }));
        }

        function setNivelModalidadePadrao() {
            var modalidade = [
                {
                    modalidade: 'BASICA',
                    descricao: 'EDUCAÇÃO BÁSICA'
                }, {
                    descricao: 'ACELERAÇÃO',
                    modalidade: 'ACELERACAO'
                }, {
                    descricao: 'EJA',
                    modalidade: 'EJA'
                }];

            var nivelEscolar = [
                {
                    descricao: 'Ensino infantil',
                    nivelEscolar: 'EDUCACAO_INFANTIL'
                },
                {
                    descricao: 'Ensino Fundamental',
                    nivelEscolar: 'ENSINO_FUNDAMENTAL'
                },
                {
                    descricao: 'Ensino Médio',
                    nivelEscolar: 'ENSINO_MEDIO'
                }];

            return _.chain(_.cloneDeep(modalidade))
                .map(_.bind(setModalidadePadrao, null, nivelEscolar))
                .value();
        }

        function setModalidadePadrao(nivelEscolar, modalidade) {
            modalidade.ativo = false;
            modalidade.listNivelEscolar = _.chain(_.cloneDeep(nivelEscolar))
                .map(_.bind(setNivelPadrao, null, modalidade))
                .remove(undefined)
                .value();
            return modalidade;
        }

        function setNivelPadrao(modalidade, nivelEscolar) {
            if (modalidade.modalidade !== 'BASICA' && nivelEscolar.nivelEscolar === 'EDUCACAO_INFANTIL') {
                return;
            }
            nivelEscolar.confiEtapas = '1';
            nivelEscolar.matriz = null;
            nivelEscolar.formularioInvalido = false;
            nivelEscolar.ativo = false;
            return nivelEscolar;
        }

        function setEstabelecimento() {
            if (!vm.configuracao.estabelecimentos) {
                vm.estabelecimentos = '';
                return;
            }

            if (!vm.configuracao.estabelecimentos.length && vm.configuracao.aplicarParaTodosEstabelecimentos) {
                vm.estabelecimentos = [{
                    id: 0,
                    descricao: 'Todos'
                }];
                return;
            }

            ConfiguracaoListaEsperaUtils.montaListaEstabelecimento(vm.configuracao.estabelecimentos)
                .then(setVmEstabelecimento);
        }

        function setVmEstabelecimento(dado) {
            vm.estabelecimentos = dado;
        }

        function saveBase(continuar) {
            vm.configuracao.niveisModalidades = ajusteNiveisModalidades();
            var estabelecimento = vm.estabelecimentos;
            if (_.get(_.first(vm.estabelecimentos), 'descricao') === 'Todos') {
                estabelecimento = vm.listEstabelecimentos;
            }

            var configuracao = ConfiguracaoListaEsperaUtils.ajusteEstabelecimento(vm.configuracao, estabelecimento);
            configuracao = removeCriterios(configuracao);
            vm.tracker.loadingButtonContinuar.addPromise(
                ConfiguracaoListaEsperaService.save(configuracao)
                .then(novaConfiguracao)
                .then(notifySuccessSave)
                .then(continuar ? _.noop : $modalInstance.close));

        }

        function removeCriterios(configuracao) {
            if (!configuracao.utilizaCriterios) {
                configuracao.criterios = [];
            }
            return configuracao;
        }

        function searchEstabelecimentos(params) {
            var filter = 'nome like "' + params.term + '"';
            return InscricaoListaEsperaService.getEstabelecimentos(filter)
                .then(setEstabelecimentoCadastro);
        }

        function setEstabelecimentoCadastro(dado) {
            vm.listEstabelecimentos = _.cloneDeep(dado);
            return {
                content: verificaShowAll(dado)
            };

        }

        function formatEstabelecimento(data) {
            return data.descricao;
        }

        function verificaShowAll(data) {
            var verficaTodosPreenchido = _.get(_.first(vm.estabelecimentos), 'id') === 0;
            if (!_.size(data) || (_.size(vm.estabelecimentos) && !verficaTodosPreenchido)) {
                return data;
            }
            if (verficaTodosPreenchido) {
                data = [];
                return data;
            }
            data.unshift({
                id: 0,
                descricao: 'Todos'
            });
            return data;
        }

        function ajusteNiveisModalidades() {
            return _.chain(_.cloneDeep(vm.niveisModalidades))
                .filter('ativo')
                .map(ajustarModalidade)
                .map('listNivelEscolar')
                .flatten()
                .filter('ativo')
                .map(deleteItens)
                .value();
        }

        function deleteItens(nivel) {
            delete nivel.ativo;
            delete nivel.confiEtapas;
            delete nivel.matriz;
            delete nivel.descricao;
            delete nivel.formularioInvalido;

            return nivel;
        }

        function ajustarModalidade(modalidade) {
            modalidade.listNivelEscolar = _.chain(modalidade.listNivelEscolar)
                .flatten()
                .filter('ativo')
                .map(_.bind(ajusteNivelEscolar, null, modalidade))
                .value();
            return modalidade;
        }

        function ajusteNivelEscolar(modalidade, nivelEscolar) {
            nivelEscolar.modalidade = modalidade.modalidade;
            nivelEscolar.etapas = getEtapas(nivelEscolar);
            return nivelEscolar;
        }

        function getEtapas(nivelEscolar) {
            if (nivelEscolar.nivelEscolar === 'EDUCACAO_INFANTIL') {
                return _.chain(nivelEscolar.etapas)
                    .map(ajusteEtapaInfantil)
                    .value();
            }
            return _.chain(nivelEscolar.etapas)
                .map(ajusteEtapa)
                .value();
        }

        function ajusteEtapaInfantil(etapa) {
            removeIdEtapa(etapa);
            etapa.tipo = 'INFANTIL';
            etapa.anoIdadeMaxima = etapa.anoIdadeMaxima.id !== undefined ? etapa.anoIdadeMaxima.id : etapa.anoIdadeMaxima;
            etapa.anoIdadeMinima = etapa.anoIdadeMinima.id !== undefined ? etapa.anoIdadeMinima.id : etapa.anoIdadeMinima;
            etapa.mesIdadeMaxima = etapa.mesIdadeMaxima.id !== undefined ? etapa.mesIdadeMaxima.id : etapa.mesIdadeMaxima;
            etapa.mesIdadeMinima = etapa.mesIdadeMinima.id !== undefined ? etapa.mesIdadeMinima.id : etapa.mesIdadeMinima;
            etapa.turnos = ajusteTurnos(etapa.turnos);

            etapa.diaMesParaCompletarIdadeMinima = moment('--' + (etapa.mesCompletarIdadeMinima.id || Number(etapa
                .mesCompletarIdadeMinima) + 1) + '-' + etapa.diaCompletarIdadeMinima, '--MM-DD').format('--MM-DD');

            delete etapa.equivalencia;
            delete etapa.mesCompletarIdadeMinima;
            delete etapa.diaCompletarIdadeMinima;
            delete etapa.dataParaCompletarIdadeMinima;

            return etapa;
        }

        function removeIdEtapa(etapa) {
            if (!vm.editando) {
                delete etapa.id;
            }
        }

        function ajusteEtapa(etapa) {
            removeIdEtapa(etapa);
            if (!etapa.equivalenciaEtapa) {
                etapa.equivalenciaEtapa = setEquivalenciaEtapa(etapa.equivalencia);
            }

            if (!_.isArray(etapa.equivalenciaEtapa)) {
                etapa.equivalenciaEtapa = [etapa.equivalenciaEtapa];
            }

            etapa.turnos = ajusteTurnos(etapa.turnos);

            etapa.tipo = 'EQUIVALENCIA';

            delete etapa.equivalencia;
            delete etapa.equivalencias;
            delete etapa.anoIdadeMaxima;
            delete etapa.anoIdadeMinima;
            delete etapa.mesIdadeMaxima;
            delete etapa.mesIdadeMinima;
            delete etapa.mesCompletarIdadeMinima;
            delete etapa.diaCompletarIdadeMinima;
            delete etapa.diaMesParaCompletarIdadeMinima;
            delete etapa.dataParaCompletarIdadeMinima;

            return etapa;
        }

        function setEquivalenciaEtapa(equivalencia) {
            if (equivalencia.id) {
                return 'ETAPA_' + equivalencia.id;
            }
            return 'ETAPA_' + (parseInt(equivalencia) + 1);
        }

        function ajusteTurnos(turnos) {
            if (_.first(turnos) === 'TODOS') {
                return ['MATUTINO', 'VESPERTINO', 'NOTURNO', 'INTEGRAL'];
            }
            return turnos;
        }

        function novaConfiguracao(data) {
            $('#lbDescricaoConfiguracao').focus();
            $scope.form.$setPristine();
            $scope.form.$setUntouched();
            vm.configuracao = {};
            vm.estabelecimentos = '';
            vm.tabNivelAtiva = '';
            vm.tabModalidadeAtiva = '';
            vm.niveisModalidades = setNivelModalidadePadrao();

            InscricaoListaEsperaUtils.clearCacheConfig(data.id);

            return data;
        }

        function notifySuccessSave(data) {
            bfcNotification.publish('Configuração salva com sucesso', 'success');
            return data;
        }

        function remove() {
            return ConfiguracaoListaEsperaService.remove(vm.configuracao).then(showMessageDelete);
        }

        function showMessageDelete() {
            bfcNotification.publish('Configuração removida com sucesso.', 'success');
            $modalInstance.close();
        }
    }
})();

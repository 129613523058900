(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.CursoProfisInepService', ['GenericService',
            function(GenericService) {
                return GenericService.create()
                    .module({
                        uri: 'inep'
                    })
                    .service({
                        uri: 'curso-educacao-profissional'
                    });

            }
        ])

    .factory('educacao.common.CursoProfisInepSelect', ['educacao.common.CursoProfisInepService',
        'educacao.common.CommonSelect',
        'educacao.common.ObjectUtilsService',
        function(
            CursoProfisInepService,
            CommonSelect, ObjectUtilsService
        ) {
            return {
                select2Config: function() {
                    return CommonSelect.select2Config([],
                        angular.extend(_.pick(CursoProfisInepService, '_modules', '_service', 'validateObject', 'modules', 'getList'), {
                            result: function(params, callback) {

                                var term = ObjectUtilsService.normalizeFilter(params.term);
                                term = encodeURI(term);

                                var filter = '(descricao like "' + term + '")';

                                this.getList(null, null, filter, null)
                                    .then(function(data) {
                                        callback({
                                            results: data,
                                            more: false
                                        });
                                    });
                            }
                        }));
                }
            };
        }
    ]);
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('ctbInputPropriedade', [
            function() {
                return {
                    require: '?ngModel',
                    restrict: 'A',
                    scope: {
                        ctbInputPropriedade: '='
                    },
                    link: function(scope, element, attr) {
                        var stopWatch = scope.$watch('ctbInputPropriedade', function() {
                            var options = scope.ctbInputPropriedade;

                            for (var prop in options) {
                                if (prop === 'required') {
                                    prop = 'bfRequired';
                                }

                                attr.$set(prop, options[prop]);
                            }

                            if (angular.isDefined(attr.ctbOneBind)) {
                                stopWatch();
                            }
                        });
                    }
                };
            }
        ]);
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .controller('educacao.ocupacao-aulas-quadro-informativo.OcupacaoAulasVisaoEstabelecimentoController', Controller);

    Controller.$inject = [
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService',
        '$scope',
        '$window',
        '$stateParams',
        'promiseTracker',
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosOcupacaoAulasService',
        'educacao.ocupacao-aulas-quadro-informativo.OcupacaoAulaUtilsService',
        'EdEnumsCacheService',
        'educacao.ocupacao-aulas-quadro-informativo.VisaoEstabelecimentoUtilsService',
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.ocupacao-aulas-quadro-informativo.VisaoGeralQuadroInformativoService'
    ];

    function Controller(
        VisaoEstabelecimentoService, 
        $scope, 
        $window, 
        $stateParams, 
        promiseTracker, 
        OcupacaoAulaService, 
        OcupacaoAulaUtilsService, 
        EdEnumsCacheService, 
        VisaoEstabelecimentoUtilsService, 
        ContextoAnoLetivo, 
        ContextoEstabelecimento, 
        VisaoGeralQuadroInformativoService
    ) {
        var vm = this;

        vm.loadingTracker = {
            saldosTurmas: promiseTracker(),
            matrizesCurriculares: promiseTracker(),
            saldosEstabelecimentos: promiseTracker(),
            saldosDisciplinas: promiseTracker(),
            saldosParciais: promiseTracker(),
        };

        vm.SCREEN_NAME = 'Ocupação das aulas';
        
        vm.matrizes = [];
        vm.saldosDisciplinas = [];
        vm.cards = [];
        vm.isCollapsed = [];
        
        vm.turmaFilter = '';
        vm.turmaFilters = null;
        vm.turmaSelecionada = null;
        vm.estabelecimento = null;
        vm.isEnumFilter = false;
        
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimentoContexto = ContextoEstabelecimento.getEstabelecimento();
        vm.estabelecimentoSelected = $stateParams.estabelecimentoSelected ? $stateParams.estabelecimentoSelected : null;
        vm.anoLetivoSelected = $stateParams.anoLetivoSelected ? $stateParams.anoLetivoSelected : ContextoAnoLetivo.getAnoLetivo();

        vm.handleCollapseToggle = handleCollapseToggle;
        vm.getComponenteCurricularSubTitle = VisaoEstabelecimentoUtilsService.getComponenteCurricularSubTitle;
        
        vm.estabelecimentoId = getEstabelecimentoId();

        function handleCollapseToggle(index, isCollapsed) {
            if (isCollapsed) {
                vm.isCollapsed.fill(false);
                vm.isCollapsed[index] = true;
                vm.turmaSelecionada = null;
            }
        }

        $scope.$watch('vm.matrizes', function () {
            vm.turmaSelecionada = null;
        });

        $scope.$watch('vm.estabelecimentoSelected', function (newValue, oldValue) {
            if(newValue !== oldValue) {
                init();
            }
        });

        $scope.$watch('vm.anoLetivoSelected', function (newValue, oldValue) {
            if(newValue !== oldValue) {
                init();
            }
        });

        init();

        function init() {
            handleContext();

            var reqParams = {
                filter: 'id = ' + vm.estabelecimentoId,
                anoLetivoId: vm.anoLetivoId
            };

            var promise = VisaoGeralQuadroInformativoService
                .getListagemEstabelecimentos(reqParams)
                .then(function (data) {
                    if(_.isEmpty(data.content)) {
                        vm.matrizes = [];
                        vm.cards = [
                            {titulo: 'Saldo inicial', saldo: 0},
                            {titulo: 'Saldo ocupado', saldo: 0},
                            {titulo: 'Saldo atual', saldo: 0}
                        ];
                    } else {
                        getSaldoEstabelecimento();
                        getMatrizes();
                    }
                    
                    getEstabelecimento();
                    getEnums();
                });

            vm.loadingTracker.saldosEstabelecimentos.addPromise(promise);
        }

        function getEstabelecimento() {
            var promise = VisaoEstabelecimentoService.getEstabelecimentoById(vm.estabelecimentoId)
                            .then(function (data) {
                                vm.estabelecimentoNome = data.pessoa.nome;
                            });

            vm.loadingTracker.saldosEstabelecimentos.addPromise(promise);
        }

        function getMatrizes() {
            var params = {
                anoLetivoId: vm.anoLetivoId
            };

            var promise = VisaoEstabelecimentoService.getMatrizesSaldosParciaisByEstabelecimentoId(vm.estabelecimentoId,params)
                .then(function (data) {
                    vm.matrizes = _.sortBy(data, ['nivelModalidade']);
                });

            vm.loadingTracker.matrizesCurriculares.addPromise(promise);
        }
        
        function getSaldoEstabelecimento() {
            var params = {
                anoLetivoId: vm.anoLetivoId
            };

            var promise = OcupacaoAulaService.getOne(vm.estabelecimentoId, params).then(function (data) {
                vm.cards = [
                    {titulo: 'Saldo inicial', saldo: data.saldoInicial},
                    {titulo: 'Saldo ocupado', saldo: data.saldoOcupado},
                    {titulo: 'Saldo atual', saldo: data.saldoFinal}
                ];
            });

            vm.loadingTracker.saldosEstabelecimentos.addPromise(promise);
        }

        function getEnums() {
            EdEnumsCacheService.get('NivelModalidade').then(function(result) {
                vm.NIVEL_MODALIDADE = result;
            });
            EdEnumsCacheService.get('NivelEscolar').then(function(result) {
                vm.NIVEL_ESCOLAR = result;
            });
        }

        function handleContext() {
            vm.estabelecimentoId = vm.estabelecimentoSelected ? vm.estabelecimentoSelected.id : getEstabelecimentoId();
            vm.anoLetivoId = vm.anoLetivoSelected ? vm.anoLetivoSelected.id : null;
        }

        function getEstabelecimentoId() {
            if (!vm.isSecretaria && vm.estabelecimentoContexto.id !== $stateParams.estabelecimentoId) {
                return vm.estabelecimentoContexto.id;
            }

            return $stateParams.estabelecimentoId;
        }
    }

})();
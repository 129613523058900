(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .service('educacao.funcionario.FuncionarioUtilsService', FuncionarioUtilsService);

    FuncionarioUtilsService.$inject = [
        'SITUACAO_FUNCIONARIO'
    ];

    function FuncionarioUtilsService(SITUACAO_FUNCIONARIO) {

        var self = this;

        self.isSituacaoTrabalhando = isSituacaoTrabalhando;
        self.isSituacaoExonerado = isSituacaoExonerado;
        self.isSituacaoDemitido = isSituacaoDemitido;
        self.isSituacaoAposentado = isSituacaoAposentado;
        self.isSituacaoFalecido = isSituacaoFalecido;
        self.isSituacaoRemovido = isSituacaoRemovido;

        function isSituacaoTrabalhando(situacao) {
            return situacao === SITUACAO_FUNCIONARIO.TRABALHANDO;
        }

        function isSituacaoExonerado(situacao) {
            return situacao === SITUACAO_FUNCIONARIO.EXONERADO;
        }

        function isSituacaoDemitido(situacao) {
            return situacao === SITUACAO_FUNCIONARIO.DEMITIDO;
        }

        function isSituacaoAposentado(situacao) {
            return situacao === SITUACAO_FUNCIONARIO.APOSENTADO;
        }

        function isSituacaoFalecido(situacao) {
            return situacao === SITUACAO_FUNCIONARIO.FALECIDO;
        }

        function isSituacaoRemovido(situacao) {
            return situacao === SITUACAO_FUNCIONARIO.REMOVIDO;
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.motivosabonosjustificativas')
        .factory('educacao.motivosabonosjustificativas.MotivosAbonosJustificativasService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {
        return ServiceBuilder.create()
            .path('commons', 'motivo-abono-justificativa')
            .enum('TipoAbono')
            .build();
    }
})();

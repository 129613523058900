(function() {

    'use strict';

    angular.module('educacao.itemAvaliavel')
        .constant('ITEM_AVALIAVEL_MODO_AVALIACAO', {
            NUMERICO: 'NUMERICO',
            CONCEITO: 'CONCEITO',
            CONCEITO_NUMERICO: 'CONCEITO_NUMERICO',
            DESCRITIVO: 'DESCRITIVO',
            NAO_OFERECE: 'NAO_OFERECE'
        });

})();


(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .service('ModalResponsaveisService', ModalResponsaveisService);

    ModalResponsaveisService.$inject = ['$q', 'ui.components.Modal'];

    function ModalResponsaveisService($q, $modal) {
        var self = this;

        self.open = open;

        function open(responsaveis) {
            var vinculo = vinculo || [];
            return $modal.open({
                templateUrl: 'planejamento/responsaveis/input-responsaveis/modal/responsaveis-modal.html',
                controller: 'educacao.pessoas.ModalResponsaveisCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    vinculo: {
                        permiteRetirarAluno: vinculo ? angular.copy(vinculo.permiteRetirarAluno) : null,
                        diasDaSemana: vinculo.diaSemana || [],
                        parentesco: vinculo.parentesco,
                        outroParentesco: vinculo.outroParentesco
                    },
                    responsaveis: responsaveis
                }
                
            });
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .directive('appCardTurmaQuadroFuncionarios', appCardTurmaQuadroFuncionarios);

    function appCardTurmaQuadroFuncionarios() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/app-card-turma-quadro-funcionarios/app-card-turma-quadro-funcionarios.directive.html',
            scope: {},
            bindToController: {
                turma: '=',
                turmaSelecionada: '=',
                matrizSelecionada: '=',
                isSelected: '=',
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;
        vm.attr = '';

    }

})();
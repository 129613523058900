(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.factory('educacao.matriz.CompetenciaService', ['Restangular', '$q',
        function (Restangular, $q) {
            var meta;

            function getMetadata() {
                var deferred = $q.defer();
                if (!meta) {
                    Restangular.one('matriz').all('cha').options().then(function (data) {
                        meta = data;
                        deferred.resolve(meta);
                    }, function (data) {
                        deferred.reject(data);
                    });
                } else {
                    deferred.resolve(meta);
                }
                return deferred.promise;
            }

            return {
                get: function (id) {
                    return Restangular.one('matriz').one('cha', id).get();
                },
                getList: function (filter) {
                    filter += ' and tipo="COMPETENCIA"';
                    return Restangular.one('matriz').all('cha/filtroChasSemPaginacao').getList({
                        filter: filter
                    });
                },
                getDefault: function () {
                    return getMetadata().then(function (data) {
                        return angular.copy(data.representation.default);
                    });
                },
                getTypes: function () {
                    return getMetadata().then(function (data) {
                        return angular.copy(data.types);
                    });
                },
                save: function (data) {
                    data.tipo = 'COMPETENCIA';
                    var rest = Restangular.one('matriz').all('cha');

                    return !data.id ?
                        rest.post(data) :
                        rest.customPUT(data, data.id);
                },
                remove: function (id) {
                    return Restangular.one('matriz').one('cha', id).remove();
                }
            };
        }
    ]);

    moduloCommon.factory('educacao.matriz.CompetenciaSelect', [
        'educacao.common.ModalCad',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.CompetenciaService',
        'bfc.Select2Config',
        'bfc.$$PermissionsService',
        function (ModalCad, ObjectUtilsService, CompetenciaService, Select2Config, PermissionsService) {

            var onAdd = PermissionsService.isRevokedOperation('ChaPageMapping', 'criar') ? undefined : add;

            return {
                select2Config: function (options) {
                    return Select2Config.create(angular.extend({
                        onAdd: onAdd,
                        result: function (params, callback) {
                            var term = ObjectUtilsService.normalizeFilter(params.term);
                            term = encodeURI(term);
                            
                            var result,
                                filter = '(descricao like "' + term + '")';

                            result = CompetenciaService.getList(filter);

                            result.then(function (data) {
                                callback({
                                    results: data,
                                    more: false
                                });
                            });

                            return result;
                        },
                        formatValue: function (data) {
                            return data;
                        },
                        formatResult: function (data) {
                            var text = data.text ?
                                data.text :
                                data.descricao;

                            if (text.length > 150) {
                                return '<span title="' + text + '">' + text.substring(0, 150) + '...</span>';
                            } else {
                                return text;
                            }

                        },
                        formatSelection: function (data) {
                            return '<span title="' + data.descricao + '">' + data.descricao + '</span>';
                        },
                        initSelection: function (element, callback) {
                            var id = $(element).val();

                            if (id) {
                                CompetenciaService.get(id).then(callback, function () {
                                    callback({
                                        id: null
                                    });
                                });
                            }
                        }
                    }, options));
                }
            };

            function add(val, target) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/competencia/competencia.cad.html',
                    controller: 'educacao.matriz.CompetenciaCadCtrl',
                    optionsCad: {
                        'descricao': val
                    },
                    selectElem: target
                });
                return false;
            }
    }]);

    moduloCommon.controller('educacao.matriz.CompetenciaCadCtrl', ['$injector', '$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
        'educacao.matriz.CompetenciaService',
        'fnCad',
        'id',
        'optionsCad',
        'promiseTracker',
        'readOnly',
        'bfc.Focus',
        function ($injector, $scope, $rootScope, $q, $modalInstance, $modalTracker,
            CompetenciaService,
            fnCad,
            id,
            optionsCad,
            promiseTracker,
            readOnly,
            focus
        ) {

            /**
             * Tracker for promise
             */
            $scope.tracker = {};
            $scope.tracker.botoes = promiseTracker();

            /**
             * Options for ctCadTitle
             */
            $scope.modo = {
                adicionando: !id && !readOnly,
                adicionandoNovaOpcao: Boolean(fnCad),
                editando: id && !readOnly,
                visualizando: id && readOnly
            };

            init();

            function init() {

                $modalTracker.addPromise(
                    $q.all([
                        CompetenciaService.getTypes().then(function (data) {
                            $scope.competenciaProperties = data.CompetenciaDto;
                        }),
                        refresh()
                    ])
                );

            }

            function refresh() {
                if ($scope.form) {
                    $scope.form.$setPristine();
                    $scope.form.$setUntouched();
                }

                return Boolean(id) ?
                    CompetenciaService.get(id).then(function (data) {
                        $scope.competencia = data;

                    }) :
                    CompetenciaService.getDefault().then(function (data) {
                        $scope.competencia = angular.extend(data, optionsCad);
                    });
            }



            function onSuccess(continuar, tracker) {
                if (continuar) {
                    id = null;
                    optionsCad = null;
                    tracker.addPromise(refresh());
                    focus('lbDescricao');
                } else {
                    $scope.cancel();
                }
            }

            $scope.cancel = function () {
                $modalInstance.close();
            };

            $scope.remove = function () {
                $scope.tracker.botoes.addPromise(
                    CompetenciaService.remove($scope.competencia.id).then(function () {
                        $rootScope.$broadcast('refreshCompetencias', {
                            data: $scope.competencia,
                            remove: true
                        });

                        onSuccess();
                    })
                );
            };

            $scope.copy = function () {
                var competencia = angular.copy($scope.competencia);
                competencia.id = null;
                save(false, competencia, null);
            };

            $scope.save = function (continuar) {
                save(continuar, $scope.competencia, id);
            };

            function save(continuar, competencia, competenciaId) {
                $scope.tracker.botoes.addPromise(CompetenciaService.save(competencia).then(function (data) {
                    if (angular.isFunction(fnCad)) {
                        fnCad(data);
                    }

                    $rootScope.$broadcast('refreshCompetencias', {
                        data: data,
                        add: !competenciaId,
                        edit: Boolean(competenciaId)
                    });

                    onSuccess(continuar, $scope.tracker.botoes);
                }));

            }

            $scope.readOnly = function () {
                return readOnly;
            };

    }]);

})();

(function () {

    'use strict';

    angular.module('educacao.geracaoHorarios').factory('educacao.geracaoHorarios.LocalSearchService', LocalSearchService);

    LocalSearchService.$inject = [];
    function LocalSearchService() {

        return {
            search: search,
            getColumns: getColumns
        };

        function search(filter, aulasPossiveis) {
            var filterables = _.filter(filter.columns, function (column) {
                return column.filterable;
            });

            if (!filterables.length) {
                return aulasPossiveis;
            }

            var runCollection = searchSome;
            switch (filter.criterion) {
                case 'all':
                    runCollection = searchAll;
                    break;
                case 'none':
                    runCollection = searchNone;
                    break;
            }

            return _.filter(aulasPossiveis, function (aulaPossivel) {
                if (filter.keywords.length && hasFiltroDependenciasFisicas(filter)) {
                    return runCollection(aulaPossivel.aula, filter, filterables) && searchOthers(aulaPossivel.aula, filter);
                }

                if (filter.keywords.length) {
                    return runCollection(aulaPossivel.aula, filter, filterables);
                }

                if (hasFiltroDependenciasFisicas(filter)) {
                    return searchOthers(aulaPossivel.aula, filter);
                }

                return true;
            });
        }

        function hasFiltroDependenciasFisicas(filter) {
            return filter.others && filter.others.dependenciaFisica && filter.others.dependenciaFisica.length;
        }

        function searchOthers(aula, filter) {
            if (!filter.others || !filter.others.dependenciaFisica || !filter.others.dependenciaFisica.length) {
                return true;
            }
            return _.any(filter.others.dependenciaFisica, function (dependenciaFisica) {
                return aula.dependenciaFisica.id === dependenciaFisica.id;
            });
        }

        function searchSome(aula, filter, filterables) {
            return _.any(filter.keywords, function (keyword) {
                return find(keyword, filterables, aula);
            });
        }

        function searchAll(aula, filter, filterables) {
            return _.every(filter.keywords, function (keyword) {
                return find(keyword, filterables, aula);
            });
        }

        function searchNone(aula, filter, filterables) {
            return !_.any(filter.keywords, function (keyword) {
                return find(keyword, filterables, aula);
            });
        }

        function find(keyword, filterables, aula) {
            var regex = new RegExp(_.deburr(keyword), 'i');

            return _.any(filterables, function (filterable) {
                var property = _.property(filterable.expression);
                return regex.test(_.deburr(property(aula)));
            });
        }

        function getColumns() {
            return [
                {
                    id: 'turma',
                    label: 'Turma',
                    model: 'turma',
                    expression: 'turma.descricao',
                    filterable: true
                },
                {
                    id: 'origem',
                    label: 'Componente Curricular',
                    model: 'origem',
                    expression: 'origem.descricao',
                    filterable: true
                },
                {
                    id: 'professor',
                    label: 'Professor',
                    model: 'professor',
                    expression: 'professor.nome',
                    filterable: true
                }
            ];
        }

    }
})();


(function() {

    'use strict';

    angular.module('educacao.funcionario')
        .constant('SITUACAO_FUNCIONARIO', {
            TRABALHANDO: 'Trabalhando',
            EXONERADO: 'Exonerado',
            DEMITIDO: 'Demitido',
            APOSENTADO: 'Aposentado',
            FALECIDO: 'Falecido',
            REMOVIDO: 'Removido'
        });
})();


(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosAulasPorEstabelecimentoGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/componente-curricular-info-modal/table-dados-aulas-por-estabelecimento-gestao-pessoas/table-dados-aulas-por-estabelecimento-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                estabelecimentoId: '=',
                anoLetivoId: '=',
                idComponenteCurricular: '=',
                escolaSelecionadas: '=',
                duracoesSelecionadas: '=',
                turnosSelecionados:'=',
                search: '='

            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        'promiseTracker',
        '$scope'
    ];

    function Controller(Service, promiseTracker, $scope) {
        var vm = this;

        vm.tracker = {
            loading: promiseTracker()
        };

        $scope.$watch('vm.search', onClickSearch);

        init();

        function init() {
            var params = {
                estabelecimentoId: vm.estabelecimentoId,
                anoLetivoId: vm.anoLetivoId,
                idComponenteCurricular: vm.idComponenteCurricular,
                filter: mountFilter()
            };

            var promise = Service.getSaldoAulasPorComponenteCurricularGroupEstabelecimento(params).then(function (response) {
                vm.saldosEstabelecimentos = getSaldoPorEstabelecimento(response);
            });

            vm.tracker.loading.addPromise(promise);
        }

        function mountFilter() {
            var filters = [];

            if (!_.isEmpty(vm.duracoesSelecionadas)) {
                var duracoes = vm.duracoesSelecionadas.map(function (duracao) {
                    return duracao.duracaoAula;
                });
    
                filters.push('duracao in (' + duracoes.join(',') + ')');
            }

            if (!_.isEmpty(vm.escolaSelecionadas)) {
                filters.push('idEstabelecimento in (' + _.map(vm.escolaSelecionadas, 'id').join(',') + ')');
            }

            if (!_.isEmpty(vm.turnosSelecionados)) {
                filters.push('turno in ("' +  vm.turnosSelecionados.join('","') + '")');
            }

            return filters.join(' and ');
        }

        function onClickSearch() {
            init();
        }

        function getSaldoPorEstabelecimento(content) {
            var result = content.reduce(function (acc, curr) {
                var idEstab = curr.idEstabelecimento;

                if (!acc[idEstab]) {
                    acc[idEstab] = createDefaultSaldoPorEstabelecimento(curr);
                }

                sumSaldosPorEstabelecimento(acc[idEstab], curr);

                return acc;
            }, {});

            return Object.values(result);
        }

        function createDefaultSaldoPorEstabelecimento(saldoEstabelecimento) {
            return {
                descricao: saldoEstabelecimento.nomeEstabelecimento,
                totalAulas: 0,
                totalAulasVagas: 0,
                aulasPorTurno: []
            };
        }

        function sumSaldosPorEstabelecimento(saldo, saldoEstabelecimento) {
            saldo.totalAulas += saldoEstabelecimento.qtdTotalAulas;
            saldo.totalAulasVagas += saldoEstabelecimento.qtdTotalAulasVagas;
            saldo.aulasPorTurno.push({
                tempo: saldoEstabelecimento.duracaoAula,
                turno: saldoEstabelecimento.turno,
                totalAulas: saldoEstabelecimento.qtdTotalAulas,
                totalAulasVagas: saldoEstabelecimento.qtdTotalAulasVagas
            });
        }
    }
})();
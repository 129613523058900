(function () {
    'use strict';
    angular.module('educacao.matricula')
        .config(['telefoneServiceProvider', function (telefoneServiceProvider) {
            telefoneServiceProvider.path('matricula/inscricao');
        }])
        .config(['deficienciaServiceProvider', function (deficienciaServiceProvider) {
            deficienciaServiceProvider.path('matricula/inscricao');
        }])
        .config(['inscricao-matricula.MunicipioServiceProvider', function (MunicipioServiceProvider) {
            MunicipioServiceProvider.setBaseUrl('pessoa/configuracoes/municipio');
        }])
        .run(['EnumService', 'InscricaoMatriculaRegularService', function (EnumService, InscricaoService) {
            EnumService.setService(InscricaoService);
        }]);
})();

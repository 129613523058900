(function () {
    'use strict';

    var EVENT_EDIT = 'view-event.edit';
    var EVENT_DELETE = 'view-event.delete';

    angular.module('educacao.agendaprofessor')
        .constant('EVENT_VIEW_EDIT_EVENT', EVENT_EDIT)
        .constant('EVENT_VIEW_DELETE_EVENT', EVENT_DELETE)
        .directive('eventsProfessor', EventsProfessor);

    function EventsProfessor() {
        return {
            restrict: 'E',
            templateUrl: 'agenda-professor/evento/events-professor.directive.html',
            scope: {
                eventos: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        '$q',
        '$filter',
        'promiseTracker',
        'educacao.calendario.CalendarioEventoService',
        'educacao.context.ContextoEstabelecimento'
    ];

    function Controller($scope, $q, $filter, promiseTracker, calendarioEventoService, ContextoEstabelecimento) {
        var KEY_GROUP_ALL = '0_all';

        var vm = this;
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.desabilitaMsg = false;
        vm.trackers = {
            publicoAlvo: promiseTracker(),
            grupo: promiseTracker()
        };

        vm.groups = {};
        vm.getPublicoAlvo = getPublicoAlvo;
        init();

        var publicoAlvoEnum = {};
        var orderBy = $filter('orderBy');

        $scope.$watch('eventos', groupBy);

        function init() {

            vm.trackers.publicoAlvo.addPromise(calendarioEventoService.getPublicoAlvo()
                .then(function (publicoAlvo) {
                    publicoAlvo.forEach(function (publico) {
                        publicoAlvoEnum[publico.key] = publico;
                    });
                }));
            groupBy($scope.eventos);
        }

        function groupBy(eventos) {
            vm.groups = {};
            vm.groups[KEY_GROUP_ALL] = {
                title: 'Do calendário da Secretaria de Educação',
                list: []
            };

            (eventos || []).forEach(function (evento) {
                setEventOnGroups(evento);
            });
        }

        function setEventOnGroups(evento) {
            if (!_.isEmpty(evento.estabelecimentos)) {
                evento.estabelecimentos.forEach(function (escola) {
                    addToGroup('est_' + escola.id, escola.descricao, evento);
                    /*if (!vm.estabelecimento || vm.estabelecimento.id === escola.id) {}
                     */
                });
                vm.desabilitaMsg = true;
            } else {
                vm.desabilitaMsg = true;
                vm.groups[KEY_GROUP_ALL].list.push(evento);
            }
        }

        function addToGroup(id, descricao, evento) {
            if (!vm.groups[id]) {
                if (vm.hasEstabelecimento) {
                    vm.groups[id] = {
                        title: 'Do calendário da Escola',
                        list: []
                    };
                } else {
                    vm.groups[id] = {
                        title: descricao,
                        list: []
                    };
                }
            }
            vm.groups[id].list.push(evento);
        }

        function getPublicoAlvo(evento) {
            if (!evento.publicoAlvo || !evento.publicoAlvo.length) {
                return '';
            }

            var alvo = '';

            orderBy(evento.publicoAlvo).forEach(function (publicoAlvo, index) {
                if (index > 0) {
                    alvo += ', ';
                }
                alvo += publicoAlvoEnum[publicoAlvo].description;
            });

            return alvo;
        }
    }

})();

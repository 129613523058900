(function () {
    'use strict';

    angular.module('educacao.matricula')
        .service('educacao.lista-espera.InscricaoListaEsperaService', service);

    service.$inject = ['ServiceBuilder'];

    function service(ServiceBuilder) {
        var created = ServiceBuilder
            .create()
            .path('matricula', 'inscricao-lista-espera')
            .enum('NivelModalidade', 'NivelEscolar', 'EquivalenciaEtapa', 'Turno')
            .build();

        created.completeList = completeList;
        created.cancelar = cancelar;
        created.removerCandidato = removerCandidato;
        created.removerModalidade = removerModalidade;
        created.removerCandidatoModalidade = removerCandidatoModalidade;
        created.desfazerCancelamento = desfazerCancelamento;

        return created;

        function completeList() {
            var params = {
                offset: 0,
                limit: 999
            };
            return created.getEndpoint()
                .get('completos', params);
        }

        function cancelar(idInscricao, idModalidade, idEstabelecimento, cancelamento) {
            var endpoint = created.getEndpoint()
                .one(idInscricao.toString(), 'cancelar');

            if (idModalidade) {
                endpoint = endpoint.one('modalidade', idModalidade);
            }
            if (idEstabelecimento) {
                endpoint = endpoint.one('estabelecimento', idEstabelecimento);
            }

            return endpoint.customPUT(cancelamento);
        }

        function removerCandidato(idInscricao) {
            var endpoint = created.getEndpoint().customDELETE(idInscricao);

            return endpoint;
        }

        function removerModalidade(ids) {
            var endpoint = created.getEndpoint().customDELETE('turno-estabelecimento', {
                inscritosIds: _.pluck(ids, 'id')
            });
            return endpoint;
        }

        function removerCandidatoModalidade(id) {
            var endpoint = created.getEndpoint()
                .one(id.toString(), 'modalidade').customDELETE();
            return endpoint;
        }

        function desfazerCancelamento(idInscricao, idModalidade, idEstabelecimento, turnos) {
            var endpoint = created.getEndpoint()
                .one(idInscricao.toString(), 'cancelar');

            if (idModalidade) {
                endpoint = endpoint.one('modalidade', idModalidade);
            }
            if (idEstabelecimento) {
                endpoint = endpoint.one('estabelecimento', idEstabelecimento);
            }

            return endpoint.all('desfazer').customPUT({
                turnos: turnos
            });
        }
    }
})();

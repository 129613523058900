(function () {
    'use strict';
    angular.module('educacao.funcionario').directive('funcionarioDocumentos', FuncionarioDocumentos);

    function FuncionarioDocumentos() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/app-funcionario-documentos.directive.html',
            scope: {},
            bindToController: {
                funcionario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'educacao.funcionario.FuncionarioService'];

    function Controller($scope, FuncionarioService) {
        var vm = this;

        vm.enumCertidaoCivil = FuncionarioService.getCertidaoCivil();
        vm.enumModeloCertidao = FuncionarioService.getModeloCertidao();
        vm.enumTipoVistoEstrangeiro = FuncionarioService.getTipoVistoEstrangeiro();
        vm.enumUf = FuncionarioService.getUF();

        vm.formatResultEnum = formatResultEnum;
        vm.filterCertidaoCivil = filterCertidaoCivil;
        vm.changeModeloCertidao = changeModeloCertidao;

        vm.isBrasileiro = isPaisOrigemBrasil(_.get(vm.funcionario, 'paisOrigem'));

        function formatResultEnum(data) {
            return data.value;
        }

        function filterCertidaoCivil(term, value) {
            if (!_.get(vm.funcionario, 'naturalizado')) {
                if (value.key === 'NASCIMENTO' && !vm.isBrasileiro) {
                    return;
                }
            }
            return value.key.indexOf(term.toUpperCase()) !== -1;
        }

        function changeModeloCertidao(modeloCertidao, oldValue) {
            if (angular.equals(oldValue, modeloCertidao)) {
                return;
            }
            if (modeloCertidao === 'NOVO') {
                delete vm.funcionario.numeroTermo;
                delete vm.funcionario.folhaCertidao;
                delete vm.funcionario.livroCertidao;
                delete vm.funcionario.emissaoCertidao;
                delete vm.funcionario.municipioCertidao;
                delete vm.funcionario.cartorioCertidao;
            } else {
                delete vm.funcionario.numeroCertidao;
            }
        }

        function changePaisOrigem(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            var paisOrigem;
            if (_.get(newValue, 'descricao') || _.get(newValue, 'nome')) {
                paisOrigem = newValue.descricao || newValue.nome;
            }
            if (newValue === null) {
                vm.isBrasileiro = true;
            }

            if (paisOrigem) {
                if (paisOrigem.toUpperCase() === 'BRASIL') {
                    vm.isBrasileiro = true;
                    limpaDadosEstrangeiro();
                } else {
                    vm.isBrasileiro = false;
                    if (vm.funcionario.certidaoCivil !== 'CASAMENTO') {
                        delete vm.funcionario.certidaoCivil;
                    }
                    delete vm.funcionario.pessoa.ufEmissaoCarteira;
                }
            } else if (angular.isUndefined(vm.isBrasileiro)) {
                vm.isBrasileiro = true;
            }
        }

        function limpaDadosBrasileiro() {
            vm.naturalizado = vm.isBrasileiro || vm.funcionario.naturalizado;
            if (!vm.naturalizado) {
                _.chain(vm.funcionario)
                    .set('pessoa.rg', null)
                    .set('orgaoEmissorRG', null)
                    .set('pessoa.ufEmissaoRg', null)
                    .set('pessoa.dataEmissaoRg', null)
                    .set('pessoa.ufEmissaoCarteira', null)
                    .value();

                if (vm.funcionario.certidaoCivil !== 'CASAMENTO'){
                    delete vm.funcionario.certidaoCivil;
                }
            }
        }

        function limpaDadosEstrangeiro() {
            _.chain(vm.funcionario)
                .set('naturalizado', null)
                .set('pessoa.estrangeiro', null)
                .value();
        }

        function isPaisOrigemBrasil(pais) {

            var paisOrigem;
            if (_.get(pais, 'descricao') || _.get(pais, 'nome')) {
                paisOrigem = pais.descricao || pais.nome;
            }

            return !paisOrigem || (paisOrigem.toUpperCase() === 'BRASIL');
        }

        $scope.$watch('vm.funcionario.paisOrigem', changePaisOrigem);
        $scope.$watchGroup(['vm.isBrasileiro', 'vm.funcionario.naturalizado'], limpaDadosBrasileiro);
    }
})();

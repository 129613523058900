(function() {
    'use strict';

    angular.module('educacao.configuracaoPlanejamentoAula')
        .constant('TIPOS_CONFIGURACOES_PLANEJAMENTO_AULA', {
            'EDUCACAO_BASICA_EDUCACAO_INFANTIL': {
                value: 'EDUCACAO_BASICA_EDUCACAO_INFANTIL'
            },
            'EDUCACAO_BASICA_ENSINO_FUNDAMENTAL': {
                value: 'EDUCACAO_BASICA_ENSINO_FUNDAMENTAL'
            },
            'EDUCACAO_BASICA_ENSINO_MEDIO': {
                value: 'EDUCACAO_BASICA_ENSINO_MEDIO'
            },
            'ACELERACAO_ENSINO_FUNDAMENTAL': {
                value: 'ACELERACAO_ENSINO_FUNDAMENTAL'
            },
            'ACELERACAO_ENSINO_MEDIO': {
                value: 'ACELERACAO_ENSINO_MEDIO'
            },
            'EJA_ENSINO_FUNDAMENTAL': {
                value: 'EJA_ENSINO_FUNDAMENTAL'
            },
            'EJA_ENSINO_MEDIO': {
                value: 'EJA_ENSINO_MEDIO'
            },
            'ATIVIDADES_AEE': {
                value: 'AEE'
            },
            'ATIVIDADES_COMPLEMENTARES': {
                value: 'ATIVIDADE_COMPLEMENTAR'
            },
            'COMPLEMENTAR': {
                value: 'COMPLEMENTAR'
            }
        });
})();


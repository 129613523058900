(function () {
    'use strict';
    angular
        .module('educacao.lista-espera')
        .controller('educacao.matricula.ConfirmacaoExclusaoEncaminhadoLoteCtrl', Controller);

    Controller.$inject = [
        '$rootScope',
        '$modalInstance',
        '$enumsCache'
    ];

    function Controller($rootScope, $modalInstance, $enumsCache) {
        var vm = this;
        
        vm.classificacoes = $modalInstance.params.classificacoes;
        vm.indexToExclude = $modalInstance.params.indexToExclude;

        vm.deleteItem = deleteItem;
        vm.classificacaoToExclude = vm.classificacoes[vm.indexToExclude];

        init();

        function init() {
            $enumsCache.get('Turno').then(function (data) {
				vm.turnos = data;
			});
        }

        function deleteItem() {
			vm.classificacoes.splice(vm.indexToExclude, 1);
			$rootScope.$broadcast('encaminhamentoLoteItemDeleted', vm.classificacoes);
            $modalInstance.close({ classificacoes: vm.classificacoes });
		}
    }
})();

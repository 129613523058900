(function() {
	'use strict';

	angular.module('educacao.enderecos')
		.directive('appSelectEstado', SelectEstados);

	function SelectEstados() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" required data-bf-required search="vm.searchEstado" add-value="vm.add" format-result="vm.formatResult(data)" ></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngRequired: '=?',
                estadosExistentes: '=',
                ngChange: '&?',
				dropdownCssClass: '=?'
			},
			controller: Controller,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
		};
	}

	Controller.$inject = [
        'ui.components.Modal',
        'educacao.enderecos.EstadoService',
        '$q',
        'educacao.common.ObjectUtilsService',
        '$scope',
        'bfc.$$PermissionsService'
    ];

	function Controller($modal, service, $q, ObjectUtilsService, $scope, PermissionsService) {

		var vm = this;

		vm.searchEstado = searchEstado;
        vm.formatResult = formatResult;
        vm.add = PermissionsService.isRevokedOperation('EstadoPageMapping', 'criar') ? undefined : add;

        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function searchEstado(params) {
            
            var term = ObjectUtilsService.normalizeFilter(params.term);
            var estados = [];
            var filter = '(uf like "' + term + '" or nome like "' + term + '")';

            if (angular.isArray($scope.estadosExistentes) && $scope.estadosExistentes.length > 0) {
                _.forEach($scope.estadosExistentes, function(value) {
                    if (value.estado && value.estado.id) {
                        estados.push(value.estado.id);
                    }
                });
                if (estados.length > 0) {
                    filter += ' and (id not in (' + estados + '))';
                }
            }

            return service.getList(params.offset, params.limit, filter);
        }

        function formatResult(data) {
            if(data.pais) {
                return data.nome + ' - ' + data.uf + ' - ' + data.pais.nome;
            }

            return data.nome;
        }

        function add(nome) {
            return $modal.open({
                templateUrl: 'enderecos/estado/cadastro-estado/cadastro-estado.html',
                controller: 'educacao.enderecos.CadastroEstadoController',
                controllerAs: 'vm',
                params: {
                    estado: {
                        nome: nome
                    },
                    canSaveAdd: false
                },
                size: 'lg'
            }).result;

        }
	}
})();

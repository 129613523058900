(function() {
    'use strict';

    var module = angular.module('educacao.common');

    module.factory('educacao.common.DisciplinaInepService', ['Restangular',
        function(Restangular) {
            return {
                get: function(id) {
                    return Restangular.one('inep').one('disciplinas-inep', id).get();
                },
                getList: function(filter, sort) {
                    return Restangular.one('inep').all('disciplinas-inep').getList({
                        filter: filter,
                        sort: sort
                    });
                }
            };
        }
    ]);

    module.factory('educacao.common.DisciplinaInepSelect', [
        'educacao.common.DisciplinaInepService',
        'educacao.common.ObjectUtilsService',
        'bfc.Select2Config',
        function(
            DisciplinaInepService,
            ObjectUtilsService,
            Select2Config
        ) {
            return {
                select2Config: function(options) {
                    return Select2Config.create(angular.extend({

                        result: function(params, callback) {
                            var term = ObjectUtilsService.normalizeFilter(params.term);
                            var filter = 'descricao like "' + term + '"';
                            var termClear = term.replace(/%/g, '');
                            var isNumber = !isNaN(termClear);

                            if (isNumber && termClear) {
                                var codigo = parseInt(termClear);
                                filter += ' or codigoInep = ' + codigo;
                            }

                            var result = DisciplinaInepService.getList(filter, undefined);

                            result.then(function(data) {
                                callback({
                                    results: data,
                                    more: false
                                });
                            });

                            return result;
                        },
                        formatValue: function(data) {
                            return data.id;
                        },
                        formatResult: function(data) {
                            return data.text ?
                                data.text : data.codigoInep + ' - ' + data.descricao;
                        },
                        formatSelection: function(data) {
                            return data.codigoInep + ' - ' + data.descricao;
                        },
                        initSelection: function(element, callback) {
                            var id = $(element).val();

                            if (id) {
                                DisciplinaInepService.get(id).then(callback, function() {
                                    callback({
                                        id: null
                                    });
                                });
                            }
                        }
                    }, options));
                }
            };
        }
    ]);

})();

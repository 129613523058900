(function () {
  'use strict';

  angular
    .module('educacao.matricula')
    .directive('appFiltroAvancado', Directive);

  function Directive() {
    return {
      restrict: 'E',
      templateUrl:
        'matricula/matriculas/filtro-avancado/filtro-avancado.directive.html',
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        expressionCustomBuilders: '=',
        advancedFilter: '=',
        filterCounter: '=',
      },
      bindToController: true
    };
  }

  Controller.$inject = ['$scope'];

  function Controller($scope) {
    var vm = this;

    vm.limparFiltroMatriculas = limparFiltroMatriculas;
    vm.limparFiltroAluno = limparFiltroAluno;

    function limparFiltroMatriculas(){
      vm.advancedFilter.filtroPadraoMatricula = {};

      vm.contadorFiltroPadraoMatricula = 0;
      
      contador();
    }

    function limparFiltroAluno(){
      vm.advancedFilter.filtroPadraoAluno = {};
      vm.advancedFilter.dadosPessoais = {};
      vm.advancedFilter.documentos = {};
      vm.advancedFilter.caracteristicas = {};

      vm.contadorFiltroPadraoAluno = 0;
      vm.contadorDocumentos = 0;
      vm.contadorDadosPessoais = 0;
      vm.contadorCaracteristicasFisicas = 0;

      contador();
    }

    $scope.$watchGroup([
      'vm.contadorFiltroPadraoMatricula',
      'vm.contadorFiltroPadraoAluno',
      'vm.contadorDocumentos',
      'vm.contadorDadosPessoais',
      'vm.contadorCaracteristicasFisicas'
    ], contador);

    function contador() {
      vm.somadorContadorAlunos = 0;
      vm.somadorContadorMatricula = 0;
      if(vm.contadorFiltroPadraoMatricula){
        vm.somadorContadorMatricula += vm.contadorFiltroPadraoMatricula;
      }
      if (vm.contadorFiltroPadraoAluno) {
        vm.somadorContadorAlunos += vm.contadorFiltroPadraoAluno;
      }
      if (vm.contadorDocumentos) {
        vm.somadorContadorAlunos += vm.contadorDocumentos;
      }
      if (vm.contadorDadosPessoais) {
        vm.somadorContadorAlunos += vm.contadorDadosPessoais;
      }
      if (vm.contadorCaracteristicasFisicas) {
        vm.somadorContadorAlunos += vm.contadorCaracteristicasFisicas;
      }

      vm.filterCounter = vm.somadorContadorAlunos + vm.somadorContadorMatricula;
    }
  }
})();

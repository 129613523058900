(function () {

    'use strict';

    var moduloCommon = angular.module('educacao.common');

    moduloCommon.factory('educacao.common.CommonSelect', ['$q',
        'bfc.Select2Config',
        'educacao.common.ObjectUtilsService',
        function ($q,
                  Select2Config,
                  ObjectUtilsService) {
            return {
                select2Config: function (lista, especializacao) {

                    var isObjeto = _.isObject(lista) && !_.isArray(lista),
                        attrDescricao;
                    // validaEnum = _.has(lista, 'enum')

                    if (isObjeto) {
                        attrDescricao = _.has(lista, 'description') ? lista.description : 'descricao';
                        lista = _.has(lista, 'values') ? lista.values : null;
                    } else {
                        attrDescricao = 'descricao';
                    }

                    return Select2Config.create(
                        angular.extend({
                            result: function (params, callback) {
                                var deferred = $q.defer();

                                function _data() {

                                    var slice = ObjectUtilsService.filterByField(lista, attrDescricao, params.term);

                                    deferred.resolve(slice);

                                    return deferred.promise;
                                }

                                return _data().then(function (data) {
                                    callback({
                                        results: data,
                                        more: false
                                    });
                                });
                            },
                            formatValue: function (data) {
                                return data;
                            },
                            formatResult: function (data) {
                                return _.property(attrDescricao)(data);
                            },
                            formatSelection: function (data) {
                                return _.property(attrDescricao)(data);
                            },
                            initSelection: function (element, callback) {
                                var index = $(element).val();

                                if (_.size(lista)) {
                                    callback(lista[index]);
                                } else {
                                    callback({
                                        id: null
                                    });
                                }
                            }
                        }, especializacao));
                }

            };

        }

    ])
        .factory('educacao.common.CommonSelectEnum', ['$q',
            'bfc.Select2Config',
            'educacao.common.ObjectUtilsService',

            /*

             @example

             var GenericService = $injector.get('GenericService');

             GenericService
             .create()
             .module({uri: 'pessoa'})
             .service({uri: 'aluno'})
             .getEnums().then(function(enums){

             viewModel.select2Teste = CommonSelectEnum.select2Config({values: enums.OrientacaoSexualDto, description: 'description'});

             });

             Este serviço recebe a lista de opções do getEnum e devolve a key para o servidor

             */

            function ($q,
                      Select2Config,
                      ObjectUtilsService) {
                return {
                    select2Config: function (lista, especializacao) {

                        lista.values = _.map(lista.values, function (row, key) {
                            return _.extend(row, {
                                'id': key
                            });
                        });

                        return Select2Config.create(
                            angular.extend({
                                result: function (params, callback) {

                                    var deferred = $q.defer();

                                    function _data() {

                                        var slice = ObjectUtilsService.filterByField(lista.values, lista.description, params.term);

                                        deferred.resolve(slice);

                                        return deferred.promise;
                                    }

                                    return _data().then(function (data) {
                                        callback({
                                            results: data,
                                            more: false
                                        });
                                    });
                                },
                                formatValue: function (data) {
                                    return this.multiple ?
                                        data :
                                        data.key;
                                },
                                formatResult: function (data) {
                                    return _.property(lista.description)(data);
                                },
                                formatSelection: function (data) {
                                    return this.multiple ?
                                        _.property(lista.description)(
                                            _.find(lista.values,
                                                function (item) {
                                                    return item.key === (typeof data === 'object' ? data.key : data);
                                                }
                                            )
                                        ) : _.property(lista.description)(data);
                                },
                                initSelection: function (element, callback) {
                                    var index = $(element).val();

                                    if (!_.isNumber(index)) {
                                        callback(
                                            _.find(lista.values, function (row) {
                                                return row.key === index || row.id === index;
                                            })
                                        );
                                    } else {
                                        callback({
                                            id: null
                                        });
                                    }
                                },
                                multiple: false,
                                minimumResultsForSearch: lista.values.length < 6 ? -1 : lista.values.length
                            }, especializacao));
                    }

                };

            }

        ])
        .factory('educacao.common.CommonSelectWithService', ['$injector',
            'bfc.Select2Config',
            'educacao.common.ObjectUtilsService',
            'educacao.common.ModalCad',

            function ($injector,
                      Select2Config,
                      ObjectUtilsService) {

                return {
                    select2Config: function (service, description, especializacao) {
                        return Select2Config.create(angular.extend({
                            _service: service,
                            result: function (params, callback) {
                                var term = ObjectUtilsService.normalizeFilter(params.term);
                                term = encodeURI(term);
                                
                                var result,
                                    filter = '(' + description + ' like "' + term + '")';

                                result = this._service.getList(params.offset, params.limit, filter, undefined)
                                    .then(function (data) {
                                        var res = _.first(data) || {};
                                        callback({
                                            results: res.content || data,
                                            more: false
                                        });
                                    });

                                return result;
                            },
                            formatValue: function (data) {
                                return data;
                            },
                            formatResult: function (data) {
                                return data.text ?
                                    data.text : _.property(description)(data);
                            },
                            formatSelection: function (data) {
                                return _.property(description)(data);
                            },
                            initSelection: function (element, callback) {
                                var id = $(element).val();

                                if (id) {
                                    service.get(id).then(callback, function () {
                                        callback({
                                            id: null
                                        });
                                    });
                                }
                            }
                        }, especializacao));
                    }

                };

            }

        ]);

})();

(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncionarioMatriculaService', FuncionarioMatriculaService);

    FuncionarioMatriculaService.$inject = ['ServiceBuilder', 'Restangular'];

    function FuncionarioMatriculaService(ServiceBuilder, Restangular) {
        var self = ServiceBuilder.create()
            .path('funcionario', 'matriculas')
            .build();
        self.getServidores = getServidores;
        self.getProfessores = getProfessores;
        self.getHistoricos = getHistoricos;
        self.getUnicoServidor = getUnicoServidor;
        self.getMatriculasByEstablecimento = getMatriculasByEstablecimento;
        self.getFuncionariosSubstitutos = getFuncionariosSubstitutos;
        return self;

        function getProfessores(filter, offset, limit){
            return Restangular.all('funcionario/servidores/professores').getList({
                filter: filter,
                offset: offset,
                limit: limit
            });
        }

        function getMatriculasByEstablecimento(filter, estabelecimento, offset, limit){
            return Restangular.all('funcionario/matriculas/por-estabelecimento').get('', {
                filter: filter,
                estabelecimento: estabelecimento,
                offset: offset,
                limit: limit
            });
        }

        function getFuncionariosSubstitutos(params) {
            return Restangular.all('funcionario/matriculas/substitutos-por-estabelecimento').getList(params);
        }

        function getHistoricos(matriculaId, params) {
            return Restangular.all('funcionario')
                .one('matriculas', matriculaId)
                .all('historicos').get('', params || {});
        }

        function getServidores(){
            return Restangular.one('funcionario/servidores').all('professores').customGET();
        }

        function getUnicoServidor(id){
            return self.getEndpoint().get(id);

        }

    }

})();

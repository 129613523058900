(function() {
    'use strict';

    angular.module('educacao.convencao').config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.convencao';
        $stateProvider.state(STATE, {
            url: 'convencao',
            data: {
                id: 'ConvencaoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'convencao/convencao.html'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.convencao.ConvencaoService',
            '$modalStates'
        ];

        function openModal($stateParams, ConvencaoService, $modalStates) {
            $modalStates.open({
                getMethod: ConvencaoService.get,
                templateUrl: 'convencao/convencao.cad.html',
                controller: 'educacao.convencao.ConvencaoCadCtrl as vm',
                propertyName: 'convencao',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }
})();
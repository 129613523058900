(function() {
    'use strict';

    angular.module('educacao.matricula', [
        'ui.bootstrap',
        'ui-components',
        'ed-components',
        'generic-service',
        'ngSanitize',
        'mini-calendar',
        'inscricao-matricula',
        'educacao.context',
        'educacao.common',
        'ngDraggable',
        'ed-accordion-cha',
        'educacao.common',
        'educacao.historico',
        'configuracao-avaliacao-turma',
        'configuracao-nota-maxima'
    ]);
})();

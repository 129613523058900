(function() {
    'use strict';

    angular.module('educacao.programasocial')
        .controller('educacao.programasocial.ProgramaSocialCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.programasocial.ProgramaSocialService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        ProgramaSocialService) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.ProgramaSocialService = ProgramaSocialService;

        function openCad(programaSocial) {
            $modalStates.goModalState(programaSocial);
        }

        function remove(data) {
            return ProgramaSocialService.remove(data);
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('indicadoresClasificacao', indicadores);



    function indicadores() {

        var config = {};

        config.templateUrl = 'matricula/classificacao-inscritos/directives/indicadores-classificacao.directive.html';
        config.restrict = 'E';
        config.scope = {
            processo: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;
    }
    Controller.$inject = [
        '$scope',
        'educacao.matricula.ClassificacaoIndicadoresService',
        'promiseTracker',
        '$rootScope'
    ];


    function Controller(
        $scope,
        ClassificacaoIndicadoresService,
        promiseTracker,
        $rootScope
    ) {

        var vm = this;
        vm.tracker = {
            loading: promiseTracker()
        };


        init();

        $scope.$watch('processo', init);

        $rootScope.$on('refresh::indicadores', init);

        function init() {
            vm.tracker.loading.addPromise(
                ClassificacaoIndicadoresService.getIndicadores($scope.processo.id).then(function(data){
                    vm.totais = data[0];
                })
            );
        }
    }

})();

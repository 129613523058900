(function() {
    'use strict';

    angular.module('educacao.matriz').factory('educacao.matriz.DisciplinaService', DisciplinaService);

    DisciplinaService.$inject = ['Restangular', '$q', 'ui.components.EventEmitter'];

    function DisciplinaService(Restangular, $q, EventEmitter) {
        var meta;
        var emiter = new EventEmitter();

        function getMetadata() {

            var deferred = $q.defer();
            if (!meta) {
                Restangular.one('matriz').all('disciplinas').options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function emitDirty(data) {
            emiter.emit('dirty');
            return data;
        }

        var service = {
            get: function(id) {
                return Restangular.one('matriz').one('disciplinas', id).get();
            },
            getList: function(offset, limit, filter, sort) {
                return Restangular.one('matriz').all('disciplinas').getList({
                    offset: offset,
                    limit: limit,
                    filter: filter,
                    sort: sort
                });
            },
            getListByParams: function(params) {
                return Restangular.one('matriz').all('disciplinas').get('list', {
                    offset: params.offset,
                    limit: params.limit,
                    filter: params.filter,
                    sort: params.sort
                });
            },
            getDefault: function() {
                return getMetadata().then(function(data) {
                    return angular.copy(data.representation.default);
                });
            },
            getTypes: function() {
                return getMetadata().then(function(data) {
                    return angular.copy(data.types);
                });
            },
            save: function(data) {
                var rest = Restangular.one('matriz').all('disciplinas');

                var saveFunction = !data.id ?
                    rest.post(data) :
                    rest.customPUT(data, data.id);

                return saveFunction.then(emitDirty);
            },
            remove: function(id) {
                return Restangular.one('matriz').one('disciplinas', id).remove()
                    .then(emitDirty);
            },
            readOnly: function() {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            },
            camposAdicionais: function(disciplinaId) {
                return Restangular.one('matriz/disciplinas/campos-adicionais', disciplinaId)
                .get()
                .then(Restangular.stripRestangular);
            },
            emitDirty: emitDirty
        };

        _.extend(service, emiter);

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .service('WorkdayHelper', Builder);

    Builder.$inject = ['DATE_FORMAT', 'DayOfWeek'];

    function Builder(DATE_FORMAT, DayOfWeek) {
        return {
            build: build,
            isBetween: isBetween
        };

        function build() {
            return new WorkdayHelper();
        }

        function WorkdayHelper() {
            var self = this;

            this.dataInicial = undefined; //data inicial a ser considerada como letivo
            this.dataFinal = undefined; //data final a ser considerada como letivo
            this.periodosLetivos = [];
            this.diasLetivosSemana = [1, 2, 3, 4, 5]; // dias da semana considerados como letivo, ISO weekday
            this.feriados = {}; //Feriados a serem considerados no calculo, organizados por data
            this.excecoes = {}; //Excecoes a serem considerados no calculo, organizados por data
            this.eventos = {}; //Eventos a serem considerados no calculo, organizados por data

            //BuildFunctions
            this.resetDefaultValues = resetDefaultValues;

            this.withDataInicial = withDataInicial;
            this.withDataFinal = withDataFinal;
            this.withPeriodosLetivos = withPeriodosLetivos;
            this.withDiasLetivosSemana = withDiasLetivosSemana;
            this.withFeriados = withFeriados;
            this.withExcecoes = withExcecoes;
            this.withEventos = withEventos;
            //--

            this.isDiaLetivo = isDiaLetivo;
            this.getProximaDataLetiva = getProximaDataLetiva;
            this.getProximaDataLetivaAsMoment = getProximaDataLetivaAsMoment;
            this.contaDiasLetivos = contaDiasLetivos;
            this.calculaDataFinalPeriodo = calculaDataFinalPeriodo;
            this.isBetween = isBetween;

            function resetDefaultValues() {
                self.dataInicial = undefined;
                self.dataFinal = undefined;
                self.periodosLetivos = [];
                self.diasLetivosSemana = [1, 2, 3, 4, 5];
                self.feriados = {};
                self.excecoes = {};
                self.eventos = {};
                return self;
            }

            function withDataInicial(dataInicial) {
                self.dataInicial = dataInicial;
                return self;
            }

            function withDataFinal(dataFinal) {
                self.dataFinal = dataFinal;
                return self;
            }

            function withPeriodosLetivos(periodosLetivos) {
                self.periodosLetivos = periodosLetivos || [];
                return self;
            }

            function withDiasLetivosSemana(diasLetivosSemana) {
                if (!_.isEmpty(diasLetivosSemana)) {
                    self.diasLetivosSemana = diasLetivosSemana.map(getNumeroDiaEnum);
                }
                return self;
            }

            function getNumeroDiaEnum(enumKey) {
                return DayOfWeek[enumKey].value;
            }

            function withFeriados(feriados, resetValues) {
                if (resetValues) {
                    angular.copy({}, self.feriados);
                }
                if (_.isEmpty(feriados)) {
                    return self;
                }

                feriados.forEach(function (feriado) {
                    self.feriados[feriado.feriado.dataFeriado] = feriado;
                });
                return self;
            }

            function withExcecoes(excecoes, resetValues) {
                if (resetValues) {
                    angular.copy({}, self.excecoes);
                }
                if (_.isEmpty(excecoes)) {
                    return self;
                }

                excecoes.forEach(function (excecao) {
                    self.excecoes[excecao.data] = excecao;
                });
                return self;
            }

            function withEventos(eventos, resetValues) {
                if (resetValues) {
                    angular.copy({}, self.eventos);
                }
                if (_.isEmpty(eventos)) {
                    return self;
                }

                var dataControleInicial;
                var dataControleFinal;
                eventos.forEach(function (evento) {
                    dataControleInicial = moment(evento.dataInicial);
                    dataControleFinal = moment(evento.dataFinal);

                    while (!dataControleInicial.isAfter(dataControleFinal, 'days')) {
                        addEventoData(dataControleInicial.format(DATE_FORMAT), evento);
                        dataControleInicial.add(1, 'days');
                    }
                });
                return self;
            }

            function addEventoData(data, evento) {
                self.eventos[data] = self.eventos[data] || [];
                self.eventos[data].push(evento);
            }

            function isDiaLetivo(date) {
                date = moment.isMoment(date) ? date : moment(date);
                date.utc();
                var formattedDate = date.format(DATE_FORMAT);

                if (!isDentroPeriodoLetivo(date)) {
                    return false;
                }

                var eventosNestaData = self.eventos[formattedDate];

                if (!_.isEmpty(eventosNestaData)) {
                    if (_.find(eventosNestaData, 'letivo', true)) {
                        return true;
                    }
                    if (_.find(eventosNestaData, {'letivo': false, 'diaInteiro': true})) {
                        return false;
                    }
                }

                var feriado = self.feriados[formattedDate];
                if (feriado) {
                    return feriado.letivo;
                }

                var excecao = self.excecoes[formattedDate];
                if (excecao) {
                    return excecao.letivo;
                }

                return isDiaSemanaLetivo(date);
            }

            function isDentroPeriodoLetivo(date) {
                if (_.isEmpty(self.periodosLetivos)) {
                    return isBetween(date, self.dataInicial, self.dataFinal);
                }
                return _.some(self.periodosLetivos, function (periodo) {
                    return isBetween(date, periodo.dataInicial, periodo.dataFinal);
                });
            }

            function isDiaSemanaLetivo(date) {
                return self.diasLetivosSemana.indexOf(date.isoWeekday()) !== -1;
            }

            function getProximaDataLetivaAsMoment(data) {
                data = moment(data || self.dataInicial);

                data.utc();

                while (isBetween(data, self.dataInicial, self.dataFinal)) {
                    if (self.isDiaLetivo(data)) {
                        return data;
                    }
                    data.add(1, 'days');
                }
                return undefined;
            }

            function getProximaDataLetiva(data) {
                var proxima = getProximaDataLetivaAsMoment(data);

                return proxima ? proxima.format(DATE_FORMAT) : proxima;
            }

            function calculaDataFinalPeriodo(periodo) {
                var wantedDate = moment(periodo.dataInicial).utc();
                var workdaysWanted = periodo.$$diasLetivos;

                while (workdaysWanted && isBetween(wantedDate, self.dataInicial, self.dataFinal)) {
                    if (self.isDiaLetivo(wantedDate)) {
                        workdaysWanted--;
                    }
                    wantedDate.add(1, 'days');
                }
                if (workdaysWanted !== periodo.$$diasLetivos) {
                    wantedDate.subtract(1, 'days');
                }
                periodo.$$diasLetivos -= workdaysWanted;
                periodo.dataFinal = wantedDate.format(DATE_FORMAT);
            }

            function contaDiasLetivos(dataInicial, dataFinal) {
                dataInicial = moment(dataInicial || self.dataInicial);
                dataFinal = moment(dataFinal || self.dataFinal);

                dataInicial.utc();
                dataFinal.utc();

                var count = 0;

                while (!dataInicial.isAfter(dataFinal, 'days')) {
                    if (self.isDiaLetivo(dataInicial)) {
                        count++;
                    }
                    dataInicial.add(1, 'days');
                }
                return count;
            }
        }
    }

    function isBetween(date, initialDate, finalDate, unit) {
        unit = unit || 'days';
        return !date.isBefore(initialDate, unit) && !date.isAfter(finalDate, unit);
    }
})();

(function() {
	/*
	    <app-colunas-a-exibir>
	        <column label="Programa"
	                model="hideColPrograma">
	        </column>

	        <column label="Público-alvo"
	                model="hideColPublico">
	        </column>

	        <column label="Objetivos"
	                model="hideColObjetivos">
	        </column>
	    </app-colunas-a-exibir>
	*/
	'use strict';

	angular.module('educacao.common')
		.directive('appColunasAExibir', appColunasAExibir);

	function appColunasAExibir() {
		var diretiva = {
			restrict: 'E',
			transclude: true,
			templateUrl: 'common/directives/app-colunas-a-exibir/app-colunas-a-exibir.directive.html',
			controllerAs: 'AppColunasAExibirController',
			controller: AppColunasAExibirController
		};

		AppColunasAExibirController.$inject = ['$scope', '$transclude', '$element', '$compile'];

		function AppColunasAExibirController($scope, $transclude, $element, $compile) {
			var viewModel = this;

			var propriedadesPublicas = {
				setDisabledColumn: _setDisabledColumn
			};

			_.extend(viewModel, propriedadesPublicas);

			init();

			function init() {
				viewModel.columns = [];
				createItemDescription();
			}

			function _setDisabledColumn(column) {
				viewModel.columns[_.findIndex(viewModel.columns, {
					name: column.name
				})] = {
					name: column.name,
					checked: column.checked
				};
				return _.filter(viewModel.columns, {
					checked: true
				}).length === 1 && column.checked === true;
			}

			function createItemDescription() {
				$transclude($scope, function(elements) {
					elements.each(function(index, element) {
						if (element.nodeName === 'COLUMN') {
							if (element.attributes && element.attributes.model && element.attributes.model.value && element.attributes.label.value) {
								viewModel.columns.push({
									name: element.attributes.model.value,
									checked: true
								});
								var newElement = $('<li>');
								newElement.append(
									$('<div>').attr({
										'class': 'ui-list-checkbox-container'
									}).append(
										$('<div>').attr({
											'class': 'bth-checkbox',
											'data-ng-init': element.attributes.model.value + '=true'
										}).append([
											$('<input>').attr({
												'id': 'Col-' + index,
												'type': 'checkbox',
												'data-ng-model': element.attributes.model.value,
												'data-ng-disabled': 'AppColunasAExibirController.setDisabledColumn({name: \'' + element.attributes.model.value +
													'\', checked :' + element.attributes.model.value + '})'
											}),
											$('<label>').attr({
												'for': 'Col-' + index
											}).append(element.attributes.label.value)
										])
									)
								);
								$element.find('ul').append($compile(newElement)($scope));
							}
						}
					});
				});
			}
		}

		return diretiva;
	}

})();

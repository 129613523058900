(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MotivoDispensaCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.matricula.MotivosDeDispensaService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        MotivosDeDispensaService
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.MotivosDeDispensaService = MotivosDeDispensaService;

        function openCad(motivo) {
            $modalStates.goModalState(motivo);
        }

        function remove(data) {
            MotivosDeDispensaService.remove(data);
        }

    }
})();


(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .controller('educacao.rematricula.ListagemRematriculaCtrl', ListagemRematriculaController);

    ListagemRematriculaController.$inject = [
        '$scope',
        'ui.components.Modal',
        'educacao.rematricula.RematriculaService',
        'educacao.context.ContextoEstabelecimento',
        'promiseTracker',
        'SITUACAO_MATRICULA_REMATRICULA'
    ];

    function ListagemRematriculaController($scope, uiModal, RematriculaService, ContextoEstabelecimento, promiseTracker,
        SITUACAO_MATRICULA_REMATRICULA) {

        var vm = this;

        vm.filterResultSituacaoMatricula = filterResultSituacaoMatricula;
        vm.openModalRematricula = openModalRematricula;
        vm.rematriculaService = RematriculaService;
        vm.isEstabelecimentoOrigemDiferente = isEstabelecimentoOrigemDiferente;
        vm.remover = remover;

        vm.situacaoRematriculaPromise = RematriculaService.getSituacaoRematricula();
        vm.situacaoMatricula = RematriculaService.getSituacaoMatricula();

        vm.equivalenciaPromise = RematriculaService.getEquivalenciaEtapa();
        vm.turnoPromise = RematriculaService.getTurno();

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();

        vm.trackerLoading = promiseTracker();

        vm.pluck = pluck;

        init();

        function init() {
            vm.situacaoRematriculaPromise.then(function(data) {
                vm.situacoesRematricula = data;
            });
        }

        function openModalRematricula() {
            uiModal.open({
                templateUrl: 'rematricula/rematricula.html',
                controller: 'educacao.rematricula.RematriculaCtrl as vm',
                params: {},
                size: 'xl'
            });
        }

        function remover(item) {
            vm.trackerLoading.addPromise(
                RematriculaService.remove(item)
            );
        }

        function filterResultSituacaoMatricula(term, value) {
            if (!value.description.toLowerCase().contains(term.toLowerCase())) {
                return;
            }

            if (SITUACAO_MATRICULA_REMATRICULA.VALUES.indexOf(value.id) >= 0) {
                return value;
            }
        }

        function pluck(messages) {
            return _.pluck(messages, 'alert');
        }

        function isEstabelecimentoOrigemDiferente(item) {
            var estabelecimentoDestino = _.get(item, 'matriculaDestino.estabelecimento');
            return estabelecimentoDestino && _.get(item, 'matriculaOrigem.estabelecimento') !== estabelecimentoDestino;
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.RelatorioService', Service);

    Service.$inject = ['Restangular', 'ui.components.PrintService'];

    function Service(Restangular, PrintService) {

        var factory = {};

        factory.formatRowTelefoneEmail = formatRowTelefoneEmail;
        factory.print = print;
        factory.getDataAtual = getDataAtual;
        factory.getEstabelecimentoRelatorio = getEstabelecimentoRelatorio;
        return factory;

        function getEstabelecimentoRelatorio(id) {
            return Restangular.one('pessoa/estabelecimento')
                .one('estabelecimentoRelatorio')
                .get({ id: id });
        }

        function getDataAtual() {
            var dia, anoLetivo, dataFormatada;
            dia = moment().date();
            anoLetivo = moment().year();
            dataFormatada = dia + ' de ' + moment(moment().month() + 1, 'MM').format('MMMM') + ' de ' + anoLetivo + '.';
            return dataFormatada;
        }

        function formatRowTelefoneEmail(telefone, email) {
            var rowTelefoneEmail = '';
            if (telefone) {
                rowTelefoneEmail = 'Fone: ' + mascaraTelefone(telefone);
            }
            if (rowTelefoneEmail) {
                if (email) {
                    rowTelefoneEmail += ' - ' + formataEmail(email);
                }
            } else {
                rowTelefoneEmail = formataEmail(email);
            }
            return rowTelefoneEmail;
        }

        function mascaraTelefone(numero) {
            //Remove tudo o que não é dígito
            numero = numero.replace(/\D/g, '');
            //Coloca parênteses em volta dos dois primeiros dígitos
            numero = numero.replace(/^(\d{2})(\d)/g, '($1) $2');
            //Coloca hífen entre o quarto e o quinto dígitos
            numero = numero.replace(/(\d)(\d{4})$/, '$1-$2');
            return numero;
        }

        function formataEmail(email) {
            if (email) {
                return 'Email: ' + email;
            }
            return email;
        }

        function print(idFormulario) {
            PrintService.print(idFormulario);
        }
    }
})();

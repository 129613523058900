(function() {
    'use strict';

    angular.module('educacao.matriz').controller('educacao.matriz.DisciplinasCadCtrl', DisciplinasCadCtrl);

    DisciplinasCadCtrl.$inject = ['$injector', '$scope', '$rootScope', '$q',
        '$modalInstance', '$modalTracker',
		'educacao.matriz.DisciplinaService',
		'educacao.common.DisciplinaInepSelect',
		'promiseTracker',
        'bfc.Notification'
    ];

    function DisciplinasCadCtrl($injector, $scope, $rootScope, $q,
        $modalInstance, $modalTracker,
        DisciplinaService,
        DisciplinaInepSelect,
        promiseTracker,
        Notification
    ) {
        var vm = this;

        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.tracker.camposAdicionais = promiseTracker();
        vm.refresh = verificaFormulario;
        vm.save = save;
        vm.remove = remove;
        vm.cancel = fechaModal;
        vm.disciplina = {};
        vm.editando = _.get($modalInstance.params.disciplina, 'id');
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd === undefined ? true : $modalInstance.params.canSaveAdd;
        vm.windowTitle = vm.editando ? 'Editando disciplina' : 'Adicionando disciplina';
        vm.disciplina = $modalInstance.params.disciplina || {};
        vm.disciplinasInepOptions = DisciplinaInepSelect.select2Config();

        $scope.$watch('vm.disciplina.consideraUnidadeEnsinoMedio', limpaClassificacaoInep);

        initCamposAdicionais();

        function save(continuar) {

            vm.disciplina.campoAdicional = angular.copy(vm.campoAdicional);
            
            return DisciplinaService.save(vm.disciplina).then(function(data) {
                vm.disciplina = data;
                showSucessMessage(data.descricao);
                onSuccess(continuar);
            });
        }

        function showSucessMessage(descricao) {
            return Notification.publish('Disciplina ' + descricao + ' salva com sucesso.', 'success');
        }

        function remove() {
            return DisciplinaService.remove(vm.disciplina.id).then(fechaModal);
        }

        function onSuccess(continuar) {
            if (continuar) {
                verificaFormulario();
                vm.disciplina = {};
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.disciplina);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }

        function initCamposAdicionais() {

            var disciplinaId = _.get(vm.disciplina, 'id');

            var promise = DisciplinaService.camposAdicionais(disciplinaId)
                .then(function(data) {
                    vm.campoAdicional = data;
                });

            vm.tracker.loading.addPromise(promise);

            vm.tracker.camposAdicionais.addPromise(promise);
        }

        function limpaClassificacaoInep(consideraUnidadeEnsinoMedio) {
            if (consideraUnidadeEnsinoMedio) {
                return;
            }

            vm.disciplina.classificacaoUnidadeInep = undefined;
        }
    }

})();

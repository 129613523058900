(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizClassificarCtrl', ['$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
        'educacao.matriz.EtapaService',
        'educacao.matriz.MatrizService',
        'educacao.common.ObjectUtilsService',
        'educacao.common.ModalCad',
        'bfc.Notification',
        'fnCad',
        'bfc.Focus',
        'id',
        'optionsCad',
        'promiseTracker',
        'readOnly',
        'bfc.Select2Config',
        function($scope, $rootScope, $q, $modalInstance, $modalTracker,
            EtapaService,
            MatrizService,
            ObjectUtilsService,
            ModalCad,
            Notification,
            fnCad,
            focus,
            id,
            optionsCad,
            promiseTracker,
            readOnly,
            Select2Config
        ) {

            var cores = [];

            var coresDisponiveis = ['red', 'blueB', 'orange', 'greenA', 'purpleB', 'blueA', 'violetA', 'greenB', 'blueC',
                'crimson'];

            $scope.tracker = {};
            $scope.tracker.list = promiseTracker();
            $scope.editando = false;

            init();

            function init() {
                if ($scope.form) {
                    $scope.form.$setPristine();
                }

                $modalTracker.addPromise(
                    MatrizService.get(id).then(function(data) {
                        $scope.matriz = data;

                        $scope.etapas = data.etapas;

                        agrupar();

                        preencherCores();

                        getEtapasDisponiveis();

                        $scope.classificacao = {
                            cor: gerarCor()
                        };
                    })
                );

                $scope.etapasSelect2 = Select2Config.create(angular.extend({
                    result: function(params, callback) {
                        callback({
                            results: ObjectUtilsService.filterByFieldWithSort(getEtapasDisponiveis(),
                                'identificacao', params.term),
                            more: false
                        });
                    },
                    formatValue: function(data) {
                        return data;
                    },
                    formatResult: function(data) {
                        return $scope.descricaoEtapa(data);
                    },
                    formatSelection: function(data) {
                        return $scope.descricaoEtapa(data);
                    },
                    multiple: true
                }));

            }

            $scope.selecionarEtapa = function() {
                if (!angular.isArray($scope.classificacao.matriz) || $scope.classificacao.matriz === 0) {
                    $scope.classificacao.matriz = null;
                }
            };

            function getEtapasDisponiveis() {
                return _.reject($scope.etapas, function(etapa) {
                    var grupo = findGrupo(etapa);
                    return grupo !== null;
                });
            }

            $scope.isSemEtapasDisponiveis = function() {
                return getEtapasDisponiveis().length === 0;
            };

            function findGrupo(etapa) {

                var grupoRetorno = null;
                _.each($scope.classificacoes, function(grupo) {

                    if (_.where(grupo.matriz, {
                            id: etapa.id
                        }).length > 0) {
                        grupoRetorno = grupo;
                    }
                });
                return grupoRetorno;
            }

            function preencherCores() {
                _.each([5, 3, 7, 8, 9, 6, 4, 2, 10, 1], function(i) {
                    _.each(coresDisponiveis, function(cor) {
                        cores.push(cor + i);
                    });
                });
            }

            function agrupar() {
                var classificados = _.filter($scope.etapas, function(etapa) {
                    return etapa.corClassificacao;
                });

                var grupos = _.values(_.groupBy(classificados, function(etapa) {
                    return 'cor:' + etapa.corClassificacao + ',descricao:' + etapa.classificacao;
                }));

                if (grupos.length > 0) {
                    for (var i = 0; i < grupos.length; i++) {
                        var temp = {
                            cor: grupos[i][0].corClassificacao,
                            descricao: grupos[i][0].classificacao,
                            id: i,
                            matriz: grupos[i]
                        };

                        grupos[i] = null;
                        grupos[i] = temp;
                    }
                }

                $scope.classificacoes = grupos;
            }

            $scope.$on('refreshEtapasMatriz', function(event, args) {
                if (args.edit) {
                    var etapa = _.find($scope.etapas, {
                        'id': args.data.id
                    });

                    if (etapa) {
                        ObjectUtilsService.copyProperties(etapa, args.data);
                    } else {
                        throw new Error('Etapa não encontrado! ID: ' + args.data.id);
                    }

                }
            });

            $scope.$on('refreshMatrizCurricular', function() {
                init();
            });

            function gerarCor() {
                var temp = _.reject(cores, function(cor) {
                    var etapasWhere = _.where($scope.etapas, {
                        corClassificacao: cor
                    });
                    var classificacoesWhere = _.where($scope.classificacoes, {
                        cor: cor
                    });
                    return !_.isEmpty(etapasWhere) || !_.isEmpty(classificacoesWhere);

                });
                return temp[0];
            }

            $scope.openCadClassificacao = function(grupo) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.classificar.cad.html',
                    controller: 'educacao.matriz.MatrizClassificarCadCtrl',
                    id: id,
                    resolve: {
                        'classificarCadCtrlArgs': {
                            'etapas': $scope.etapas,
                            'classificacoes': $scope.classificacoes,
                            'grupo': !grupo ? undefined : {
                                id: grupo.id,
                                cor: grupo.cor,
                                descricao: grupo.descricao,
                                matriz: grupo.matriz
                            },
                            'matriz': $scope.matriz,
                            'cores': cores
                        }
                    },
                    readOnly: false
                });
            };

            $scope.editar = function(grupo) {
                $scope.editando = true;
                $scope.classificacao = {
                    id: grupo.id,
                    cor: grupo.cor,
                    descricao: grupo.descricao,
                    matriz: grupo.matriz
                };
            };

            $scope.remover = function(idMatriz, grupo) {
                _.each(grupo.matriz, function(etapa) {
                    etapa.corClassificacao = null;
                    etapa.classificacao = null;
                });

                EtapaService.updateAll(idMatriz, grupo.matriz).then(function(data) {
                    if (id === idMatriz) {
                        $rootScope.$broadcast('refreshClassificacao', {
                            data: data,
                            grupo: grupo,
                            remove: true
                        });
                    }

                });

            };

            function atualizarLista(grupo) {
                $scope.classificacoes = _.reject($scope.classificacoes, {
                    id: grupo.id
                });
            }

            $scope.cancel = function() {
                $modalInstance.close();
            };

            $scope.descricaoEtapa = function(etapa) {
                return MatrizService.getDescricaoEtapa($scope.matriz, etapa);
            };

            function refreshEtapasMatriz(etapas) {
                if (_.isArray(etapas)) {
                    _.each(etapas, function(etapa) {
                        $rootScope.$broadcast('refreshEtapasMatriz', {
                            data: etapa,
                            edit: true
                        });
                    });
                } else {
                    $rootScope.$broadcast('refreshEtapasMatriz', {
                        data: etapas,
                        edit: true
                    });
                }
            }

            $scope.$on('refreshClassificacao', function(event, args) {

                if (args.data) {
                    if (args.remove) {
                        refreshEtapasMatriz(args.data);
                        atualizarLista(args.grupo);
                    } else if (args.add) {

                        $scope.classificacoes.push(args.data);

                    } else if (args.undo || args.edit) {
                        angular.forEach($scope.classificacoes, function(classificacao, key) {
                            if (classificacao.id === args.data.id) {
                                $scope.classificacoes[key] = args.data;
                            }
                        });
                    }
                    ///$scope.isSemEtapasDisponiveis();
                }
            });
        }])

    .controller('educacao.matriz.MatrizClassificarCadCtrl', ['$scope',
                 '$injector',
                 '$rootScope',
                 '$modalTracker',
                 'promiseTracker',
                 'fnCad',
                 'id',
                 'bfc.Select2Config',
                 'educacao.common.ModalCad',
                 '$q',
                 '$modalInstance',
                 'classificarCadCtrlArgs',
                 function($scope,
            $injector,
            $rootScope,
            $modalTracker,
            promiseTracker,
            fnCad,
            id,
            Select2Config,
            ModalCad,
            $q,
            $modalInstance,
            classificarCadCtrlArgs) {

            var EtapaService = $injector.get('educacao.matriz.EtapaService'),
                ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService'),
                MatrizService = $injector.get('educacao.matriz.MatrizService'),
                FilterEncodedUtilsService = $injector.get('educacao.common.FilterEncodedUtilsService');

            $scope.tracker = {};
            $scope.tracker.botoes = promiseTracker();

            var cores = [];

            $scope.modo = {
                adicionando: !classificarCadCtrlArgs.grupo,
                editando: classificarCadCtrlArgs.grupo
            };

            var init = function() {

                return $q.all(
                    cores = classificarCadCtrlArgs.cores,
                    $scope.etapas = classificarCadCtrlArgs.etapas,
                    $scope.classificacoes = classificarCadCtrlArgs.classificacoes,
                    $scope.classificacao = !classificarCadCtrlArgs.grupo ? {} : classificarCadCtrlArgs.grupo,
                    $scope.matriz = classificarCadCtrlArgs.matriz,
                    $scope.classificacao.cor = gerarCor(),

                    $scope.etapasSelect2 = Select2Config.create(angular.extend({
                        result: function(params, callback) {
                            callback({
                                results: ObjectUtilsService.filterByFieldWithSort(
                                    getEtapasDisponiveis(), 'identificacao', params.term),
                                more: false
                            });
                        },
                        formatValue: function(data) {
                            return data;
                        },
                        formatResult: function(data) {
                            return descricaoEtapa(data);
                        },
                        formatSelection: function(data) {
                            return descricaoEtapa(data);
                        },
                        multiple: true
                    }))
                );
            };

            $modalTracker.addPromise(
                init()
            );

            $scope.searchClassificacao = function(params) {
                var classificacaoFilter = getEtapasDisponiveis();
                
                var term = FilterEncodedUtilsService.getDecodedTerm(params.term);

                classificacaoFilter = _.filter(classificacaoFilter, function(classificacao) {
                    return classificacao.identificacao.toUpperCase().indexOf(term.toUpperCase()) > -1;
                });

                var page = {
                    content: classificacaoFilter,
                    hasNext: false
                };
                return $q.when(page);

            };

            $scope.save = function(continuar) {
                var persistence,
                    etapasSelecionadas = [];

                var isValid = true;

                _.forEach($scope.classificacoes, function(classificacao) {
                    if (!classificacao.descricao) {
                        isValid = false;
                    }
                });

                if (!isValid || !_.get($scope.classificacao, 'descricao')) {
                    return;
                }

                if ($scope.modo.editando) {
                    _.forEach($scope.classificacoes, function(classificacao) {

                        _.forEach(classificacao.matriz, function(etapa) {
                            etapa.corClassificacao = classificacao.cor;
                            etapa.classificacao = classificacao.descricao;
                        });

                        etapasSelecionadas = _.union(etapasSelecionadas, classificacao.matriz);
                    });

                    _.forEach($scope.etapas, function(etapa) {
                        var itemSelecionado = _.find(etapasSelecionadas, function(selecionada) {
                            return selecionada.id === etapa.id;
                        });

                        etapa.corClassificacao = !itemSelecionado ? null : itemSelecionado.corClassificacao;
                        etapa.classificacao = !itemSelecionado ? null : itemSelecionado.classificacao;
                    });

                    persistence = $scope.etapas;
                } else {
                    if ($scope.classificacao.id || $scope.classificacao.id === 0) {
                        $scope.classificacao.id++;
                    } else {
                        $scope.classificacao.id = $scope.classificacoes.length;
                    }
                    persistence = $scope.classificacao.matriz;
                    _.forEach(
                        $scope.classificacao.matriz,
                        function(etapa) {
                            etapa.corClassificacao = $scope.classificacao.cor;
                            etapa.classificacao = $scope.classificacao.descricao;
                        }
                    );

                }

                $scope.tracker.botoes.addPromise(
                    EtapaService.updateAll(id, persistence).then(function(data) {

                        if (angular.isFunction(fnCad)) {
                            fnCad(data);
                        }

                        $rootScope.$broadcast('refreshClassificacao', {
                            data: angular.copy($scope.classificacao),
                            add: $scope.modo.adicionando,
                            edit: $scope.modo.editando
                        });

                        refreshEtapasMatriz($scope.etapas);

                        if (!continuar) {
                            $scope.cancel();
                        } else {
                            postSaveContinue();
                        }
                    })
                );
            };

            function refreshEtapasMatriz(etapas) {
                if (_.isArray(etapas)) {
                    _.each(etapas, function(etapa) {
                        $rootScope.$broadcast('refreshEtapasMatriz', {
                            data: etapa,
                            edit: true
                        });
                    });
                } else {
                    $rootScope.$broadcast('refreshEtapasMatriz', {
                        data: etapas,
                        edit: true
                    });
                }
            }

            function getEtapasDisponiveis() {

                _.forEach($scope.etapas, function(data) {
                    data.descricao = MatrizService.getDescricaoEtapa($scope.matriz, data);
                });

                return _.reject($scope.etapas, function(etapa) {
                    return etapa.corClassificacao !== null;
                });
            }

            function descricaoEtapa(etapa) {
                return MatrizService.getDescricaoEtapa($scope.matriz, etapa);
            }

            function postSaveContinue() {
                if ($scope.form) {
                    $scope.form.$setPristine();
                    $scope.form.$setUntouched();
                    $('#lbDescricaoClassificacao').focus();
                }

                $scope.classificacao.descricao = null;
                $scope.classificacao.matriz = null;
                classificarCadCtrlArgs.grupo = null;
                $scope.classificacao.cor = gerarCor();
            }

            $scope.cancel = function() {
                $modalInstance.close();
            };

            function gerarCor() {
                var temp = _.reject(cores, function(cor) {
                    var etapasWhere = _.where($scope.etapas, {
                        corClassificacao: cor
                    });
                    var classificacoesWhere = _.where($scope.classificacoes, {
                        cor: cor
                    });
                    return !_.isEmpty(etapasWhere) || !_.isEmpty(classificacoesWhere);

                });
                return !classificarCadCtrlArgs.grupo ? temp[0] : classificarCadCtrlArgs.grupo.cor;
            }

            $scope.selecionarEtapa = function(data) {
                if (!angular.isArray($scope.classificacao.matriz) || $scope.classificacao.matriz.length === 0) {
                    $scope.classificacao.matriz = null;
                }

                if ($scope.classificacoes[$scope.classificacao.id]) {
                    $scope.classificacoes[$scope.classificacao.id].matriz = data;
                }

            };

            $scope.changeDescricao = function(data) {
                if ($scope.classificacoes[$scope.classificacao.id]) {
                    $scope.classificacoes[$scope.classificacao.id].descricao = data;
                }
            };

            $scope.remover = function() {
                var grupo = $scope.classificacao;

                _.each(grupo.matriz, function(etapa) {
                    etapa.corClassificacao = null;
                    etapa.classificacao = null;
                });

                $scope.tracker.botoes.addPromise(
                    EtapaService.updateAll(id, grupo.matriz).then(function(data) {

                        if (angular.isFunction(fnCad)) {
                            fnCad(data);
                        }

                        $rootScope.$broadcast('refreshClassificacao', {
                            data: data,
                            grupo: grupo,
                            remove: true
                        });

                        $scope.cancel();
                    })
                );

            };

    }]);

})();


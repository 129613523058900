(function() {
    'use strict';

    angular.module('educacao.motivosabonosjustificativas')
        .controller('educacao.motivosabonosjustificativas.MotivosAbonosJustificativasCadCtrl', Controller);

    Controller.$inject = [
        '$modalInstance', 
        'educacao.motivosabonosjustificativas.MotivosAbonosJustificativasService',
        '$scope',
        'bfc.Notification',
        'promiseTracker'
    ];

    function Controller($modalInstance, MotivosAbonosJustificativasService, $scope, bfcNotification, promiseTracker) {
        var vm = this;
        var REQ_TYPE_SAVE = 'save';
        var REQ_TYPE_REMOVE = 'remove';

        vm.motivoAbonoJustificativa = $modalInstance.params.motivoAbonoJustificativa || {};
        vm.save = save;
        vm.remove = remove;
        vm.windowTitle = '';
        vm.hasReqError = true;

        vm.tracker = {};
        vm.tracker.loading = promiseTracker();

        init();

        function init() {
            setWindowTitle();
        }

        function save(continuar) {
            vm.tracker.loading.addPromise(MotivosAbonosJustificativasService
                .save(vm.motivoAbonoJustificativa)
                .then(function(data) {
                    vm.motivoAbonoJustificativa = data;
                    handleReqSuccess(continuar, REQ_TYPE_SAVE, false);
                }));
        }

        function remove() {
            vm.tracker.loading.addPromise(MotivosAbonosJustificativasService
                .remove(vm.motivoAbonoJustificativa)
                .then(function() {
                    vm.hasReqError = false;
                })
                .catch(function() {
                    vm.hasReqError = true;
                })
                .finally(function() {
                    handleReqSuccess(false, REQ_TYPE_REMOVE, vm.hasReqError);
                }
            ));
        }

        function handleReqSuccess(continuar, reqType, hasReqError) {
            if(hasReqError) {
                return;
            }

            publishNotification(reqType);
            
            if(continuar) {
                return refresh();
            }
            
            closeModal();
        }

        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            vm.motivoAbonoJustificativa = {};
        }

        function closeModal() {
            $modalInstance.close(vm.motivoAbonoJustificativa);
        }

        function publishNotification(reqType) {
            if(reqType === REQ_TYPE_SAVE) {
                bfcNotification.publish('Motivo (' + vm.motivoAbonoJustificativa.descricao +  ' - Tipo: ' + vm.motivoAbonoJustificativa.tipo + ') salvo com sucesso.', 'success');
                return;
            }

            bfcNotification.publish('Motivo (' + vm.motivoAbonoJustificativa.descricao +  ' - Tipo: ' + vm.motivoAbonoJustificativa.tipo + ') excluído com sucesso.', 'success');
        }

        function setWindowTitle() {
            if(vm.motivoAbonoJustificativa && vm.motivoAbonoJustificativa.id) {
                vm.windowTitle = 'Editando motivo de abono ou justificativa';
                return;
            }
            
            vm.windowTitle = 'Adicionando motivo de abono ou justificativa';
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.configuracao-indicadores')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.configuracao-evasao-reprovacao.indicadores', {
            url: '/indicadores',
            data: {
                adminOnly: true
            },
            views: {
                '@': {
                    templateUrl: 'configuracao-evasao-reprovacao/configuracao-indicadores/configuracao-indicadores.html',
                    controller: 'educacao.configuracao-indicadores.ConfiguracaoIndicadoresController as vm'
                }
            }
        });
    }

})();


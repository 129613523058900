(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('educacaoInputDeficiencia', InputDeficiencia);

    InputDeficiencia.$inject = [];

    function InputDeficiencia() {

        var config = {};

        config.templateUrl = 'planejamento/deficiencia/ed-deficiencia.directive.html';
        config.restrict = 'E';
        config.require = 'ngModel';
        config.scope = {};
        config.scope.ngModel = '=';
        config.scope.required = '=';

        config.controller = InputDeficienciaCtrl;
        config.controllerAs = 'vm';

        return config;
    }

    InputDeficienciaCtrl.$inject = ['$injector', '$scope', 'educacao.pessoas.edModalDeficienciaService', '$element'];

    function InputDeficienciaCtrl($injector, $scope, edModalDeficienciaService, $element) {
        var vm = this;



        vm.openModal = openModal;
        vm.formatterTag = formatterTag;
        vm.show = true;

        if (!$scope.ngModel) {
            $scope.ngModel = [];
        }



        function openModal() {
            edModalDeficienciaService.open($scope.ngModel)
                .onClose(onClose);
        }

        function formatterTag(pessoaDeficiencia) {
            return pessoaDeficiencia.deficiencia ? pessoaDeficiencia.deficiencia.descricao : '';
        }

        function onClose() {
            $element.find('input').focus();
        }

    }
})();

(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .controller('educacao.matricula.turma.configuracao-avaliacao.ModalChaController', Controller);

    var TIPO_ITEM_CHA = 'CHA';
    var TIPO_PERIODO = {
        MEDIA_PERIODO: 'MEDIA_PERIODO',
        EXAME_FINAL: 'EXAME_FINAL',
        TODOS: 'TODOS'
    };

    Controller.$inject = [
        '$scope',
        '$modalInstance',
        'promiseTracker',
        'educacao.matriz.chaService'
    ];

    function Controller($scope, $modalInstance, promiseTracker, chaService) {
        var vm = this;

        vm.componente = $modalInstance.params.componente || {};
        vm.periodos = $modalInstance.params.periodos || {};
        vm.isAtividade = $modalInstance.params.isAtividade;
        vm.hasExameFinal = $modalInstance.params.hasExameFinal;
        vm.itensAvaliaveisCha = $modalInstance.params.itensAvaliaveisCha;
        vm.onClickAplicar = onClickAplicar;

        vm.onClickContinue = onClickContinue;

        init();

        function init() {
            vm.tracker = {
                getItensCha: promiseTracker()
            };

            buscaItensAvaliaveisCha(_.get(vm.componente, 'etapaMatrizDisciplina.id'));
        }

        vm.configuracoesExibicao = {
            conhecimentos: {
                btnTitle: 'Conhecimento/Conteúdo',
                emptyTitle: 'conhecimentos/conteúdos'
            },
            competencias: {
                btnTitle: 'Competência',
                emptyTitle: 'competências'
            },
            atitudes: {
                btnTitle: 'Atitude',
                emptyTitle: 'atitudes'
            },
            habilidades: {
                btnTitle: 'Habilidade/Capacidade',
                emptyTitle: 'habilidades/capacidades'
            }
        };

        /**
         * Verifica se existem itens CHA no componente, 
         * senão, busca os itens padrões da matriz
         * Agrupa os itens pelo tipo da competencia do CHA
         * @param {*Number} idEtapaMatrizDisc 
         */
        function buscaItensAvaliaveisCha(idEtapaMatrizDisc) {
            if (!_.isEmpty(vm.itensAvaliaveisCha) || vm.isAtividade) {
                vm.itensCha = {
                    atitudes: configuraChas(vm.itensAvaliaveisCha, 'ATITUDE'),
                    conhecimentos: configuraChas(vm.itensAvaliaveisCha, 'CONHECIMENTO'),
                    competencias: configuraChas(vm.itensAvaliaveisCha, 'COMPETENCIA'),
                    habilidades: configuraChas(vm.itensAvaliaveisCha, 'HABILIDADE'),
                };
            } else {
                var promise = chaService.getTodosChaDisciplina(idEtapaMatrizDisc).then(function(i) {
                    vm.itensCha = {
                        atitudes: configuraChasMatriz(i, 'ATITUDE'),
                        conhecimentos: configuraChasMatriz(i, 'CONHECIMENTO'),
                        competencias: configuraChasMatriz(i, 'COMPETENCIA'),
                        habilidades: configuraChasMatriz(i, 'HABILIDADE'),
                    };
                });
                vm.tracker.getItensCha.addPromise(promise);
            }

            function configuraChasMatriz(lista, tipo) {
                return _.chain(lista)
                    .filter(function(i) {
                        return i.tipo === tipo;
                    })
                    .map(function(c) {
                        return {
                            competencia: c,
                            itensAvaliaveis: []
                        };
                    })
                    .value();
            }

            function configuraChas(lista, tipo) {
                var retorno = [];
                var itensCha = _.filter(lista, function(i) {
                    return _.get(i.competenciaCHA, 'tipo') === tipo;
                });

                _.forEach(_.groupBy(itensCha, 'competenciaCHA.id'), function(itemListCha) {
                    retorno.push({
                        itensAvaliaveis: itemListCha,
                        competencia: _.first(itemListCha).competenciaCHA || _.first(itemListCha)
                    });
                });

                return retorno;
            }
        }

        /**
         * Adiciona os elementos CHA na propriedade 
         * $$itensAvaliaveisCha do componente
         */
        function onClickContinue() {
            vm.componente.$$itensAvaliaveisCha = ajustaPeriodosCha();
            $modalInstance.close();
        }

        /**
         * Percorre os períodos dos chás e troca o período 
         * do tipo TODOS por todos os períodos 
         * possíveis do componente para enviar ao backend
         */
        function ajustaPeriodosCha() {
            var itensAvaliaveisCha = [];

            angular.forEach(vm.itensCha, function(itemCha) {
                angular.forEach(itemCha, function(cha) {
                    if (_.find(cha.$$periodos, 'id', TIPO_PERIODO.TODOS)) {

                        if (vm.isAtividade) {
                            itensAvaliaveisCha.push(getItemWithIdIfExists({
                                tipo: TIPO_ITEM_CHA,
                                competenciaCHA: cha.competencia,
                                tipoPeriodo: TIPO_PERIODO.MEDIA_PERIODO,
                                periodoAvaliativo: null,
                                permiteAvaliacao: cha.permiteAvaliacao
                            }));
                        } else {
                            _.forEach(vm.periodos, function(periodo) {
                                itensAvaliaveisCha.push(getItemWithIdIfExists({
                                    tipo: TIPO_ITEM_CHA,
                                    competenciaCHA: cha.competencia,
                                    tipoPeriodo: TIPO_PERIODO.MEDIA_PERIODO,
                                    periodoAvaliativo: periodo,
                                    permiteAvaliacao: cha.permiteAvaliacao
                                }));
                            });
                        }

                        if (vm.hasExameFinal) {
                            itensAvaliaveisCha.push(getItemWithIdIfExists({
                                tipo: TIPO_ITEM_CHA,
                                competenciaCHA: cha.competencia,
                                tipoPeriodo: TIPO_PERIODO.EXAME_FINAL,
                                periodoAvaliativo: null,
                                permiteAvaliacao: cha.permiteAvaliacao
                            }));
                        }
                    } else {
                        _.forEach(cha.$$periodos, function(periodo) {
                            if (!periodo) {
                                return;
                            }
                            itensAvaliaveisCha.push(getItemWithIdIfExists({
                                tipo: TIPO_ITEM_CHA,
                                competenciaCHA: cha.competencia,
                                tipoPeriodo: periodo.tipoPeriodo,
                                periodoAvaliativo: periodo.periodoAvaliativo,
                                permiteAvaliacao: cha.permiteAvaliacao
                            }));
                        });
                    }
                });
            });

            return itensAvaliaveisCha;

            /**
             * Verifica se o cha já existe e insere seu id 
             * para evitar que o backend duplique o objeto
             * @param {Object} objCha - cha
             */
            function getItemWithIdIfExists(objCha) {
                var chaExistente = _.find(vm.componente.itensAvaliaveis, function(itemAvaliavel) {
                    return _.get(objCha.periodoAvaliativo, 'id') === _.get(itemAvaliavel.periodoAvaliativo, 'id') &&
                        _.get(objCha.competenciaCHA, 'id') === _.get(itemAvaliavel.competenciaCHA, 'id') &&
                        objCha.tipoPeriodo === itemAvaliavel.tipoPeriodo;
                });

                if (chaExistente) {
                    objCha.id = chaExistente.id;
                }

                return objCha;
            }
        }

        function onClickAplicar() {
            _.forEach(vm.itensCha, getItensCha);
        }

        function getItensCha(cha) {
            _.forEach(cha, setPeriodo);
        }

        function setPeriodo(item) {
            item.itensAvaliaveis = _.map(vm.aplicarTodosPeriodo, _.bind(createItensAvaliaveis, null, item));
            item.$$periodos = vm.aplicarTodosPeriodo;
        }

        function createItensAvaliaveis(item, periodo) {
            return {
                competenciaCHA: item.competencia,
                periodoAvaliativo: periodo.periodoAvaliativo,
                tipo: 'CHA',
                tipoPeriodo: 'MEDIA_PERIODO',
                permiteAvaliacao: item.permiteAvaliacao
            };
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios').controller('educacao.geracaoHorarios.GeracaoHorariosCadController',
        GeracaoHorariosCadController);

    GeracaoHorariosCadController.$inject = [
        '$modalInstance',
        'bfc.Notification',
        'educacao.context.ContextoEstabelecimento',
        'educacao.geracaoHorarios.GeracaoHorariosService'
    ];

    function GeracaoHorariosCadController($modalInstance, $notification, ContextoEstabelecimento, GeracaoHorariosService) {

        var vm = this;

        vm.remove = remove;
        vm.save = save.bind(null, closeModal);
        vm.saveAdd = save.bind(null, refresh);

        vm.hasContextoEstabelecimento = !ContextoEstabelecimento.isSecretaria();

        vm.disableFilter = false;

        activate();

        function activate() {
            vm.quadroHorarios = $modalInstance.params.quadroHorarios || {};
            vm.editing = Boolean(_.get(vm.quadroHorarios, 'id'));
            vm.buildingQuadro = Boolean($modalInstance.params.buildingQuadro);

            vm.windowTitle = vm.editing ? 'Editando quadro de horários' : 'Adicionando quadro de horários';

            if (vm.hasContextoEstabelecimento) {
                vm.quadroHorarios.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
            }
        }

        function save(callback) {
            vm.quadroHorarios.vigenciaFim = vm.quadroHorarios.vigenciaFim || null;
            return GeracaoHorariosService.save(vm.quadroHorarios)
                .then(callback)
                .then(notificar);
        }

        function notificar() {
            $notification.publish('Quadro de horários salvo com sucesso', 'success');
        }

        function notificarExclusao() {
            $notification.publish('Quadro de horários removido com sucesso', 'success');
        }

        function refresh() {
            vm.quadroHorarios = {};
            vm.quadroHorariosForm.$setPristine();
            vm.quadroHorariosForm.$setUntouched();
        }

        function closeModal() {
            $modalInstance.close();
        }

        function remove() {
            return GeracaoHorariosService.remove(vm.quadroHorarios)
                .then(notificarExclusao)
                .then(closeModal);
        }
    }
})();

(function() {
    'use strict';

    var NIVEL_ESCOLAR = {
        EDUCACAO_INFANTIL: 'Educação Infantil',
        ENSINO_FUNDAMENTAL: 'Ensino Fundamental',
        ENSINO_MEDIO: 'Ensino Médio'
    };

    var NIVEL_MODALIDADE = {
        EDUCACAO_BASICA: 'Educação Básica'
    };

    angular.module('educacao.quadro-funcionarios-configuracao')
        .controller('educacao.quadro-funcionarios-configuracao.ConfigQuadroFuncionariosModalController', ConfigQuadroFuncionariosModalController);

    ConfigQuadroFuncionariosModalController.$inject = [
        '$scope',
        'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosService',
        'educacao.common.$commons',
        '$modalInstance',
        'bfc.Notification',
        'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosUtils',
        'bfc.Focus'
      ];
    
    function ConfigQuadroFuncionariosModalController($scope, QuadroFuncionariosService, $commons, $modalInstance, bfcNotification, QuadroFuncionariosUtils, focus) {
        var NOTIFICATIONS_TYPE = {
            SUCCESS: 'SUCCESS',
            COMPONENTES_CURRICULARES_EMPTY: 'COMPONENTES_CURRICULARES_EMPTY'
        };

        var vm = this;
        
        vm.nivelEscolar = $modalInstance.params.nivelEscolar;
        vm.nivelModalidade = $modalInstance.params.nivelModalidade;
        vm.configQuadroFuncionario = $modalInstance.params.configQuadroFuncionario || {};
        vm.editando = _.get($modalInstance.params.configQuadroFuncionario, 'id');
        vm.windowTitle = '';
        vm.sidebarPath = getNivelModalidadeTitle(vm.nivelModalidade) + ' | ' + getNivelEscolarTitle(vm.nivelEscolar);
        
        vm.save = save;
        vm.remove = remove;
        vm.searchMatriz = searchMatriz;
        vm.setConfigAtivoOuInativo = setConfigAtivoOuInativo;
        vm.hasEtapaComPendencias = hasEtapaComPendencias;
        
        vm.closeModal = closeModal;
        
        vm.tracker = {};
        vm.tracker.loading = $commons.$promiseTracker();
        vm.tracker.isDisabled = false;
        vm.tracker.save = $commons.$promiseTracker();

        $scope.$watch('vm.configQuadroFuncionario.matrizCurricular', setDefaultConfigEtapas);

        init();

        function init() {
            setWindowTitle();
            if(!vm.editando) {
                vm.configQuadroFuncionario.ativo = 'ATIVO';
                vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas = [];
            } else {
                vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas = QuadroFuncionariosUtils.sortEtapas(vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas);
            }
        }
        
        function getNivelEscolarTitle(nivelEscolar) {
            return NIVEL_ESCOLAR[nivelEscolar] || '';
        }

        function getNivelModalidadeTitle(nivelModalidade) {
            return NIVEL_MODALIDADE[nivelModalidade] || '';
        }

        function searchMatriz(params) {
            vm.tracker.isDisabled = true;
            var filter = '(descricao like "' + params.term + '" and modalidade = "' + vm.nivelModalidade + '" and nivelEscolar = "' + vm.nivelEscolar + '")';

            return QuadroFuncionariosService
                .getMatrizEtapa(params.offset, params.limit, filter)
                .then(toPage);
        }

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function setDefaultConfigEtapas() {
            if(vm.configQuadroFuncionario.matrizCurricular && !vm.editando) {
                var etapasMatriz = vm.configQuadroFuncionario.matrizCurricular.etapas;
                vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas = [];

                _.forEach(etapasMatriz, function(etapa) {
                    QuadroFuncionariosUtils.getComponentesCurriculares(etapa.id, vm.configQuadroFuncionario.matrizCurricular.id)
                    .then(function(componentesCurriculares) {
                            return QuadroFuncionariosUtils.setDefaultConfigGroups(componentesCurriculares, vm.nivelModalidade, vm.nivelEscolar, etapa.equivalencias);
                        }
                    )
                    .then(function(grupos) {
                            createDefaultEtapa(grupos, etapa);
                            vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas = QuadroFuncionariosUtils.sortEtapas(vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas);
                        }
                    );
                });
            }
        }

        function createDefaultEtapa(gruposEtapas, etapa) {
            var quadroFuncEtapa = {
                etapaMatriz: etapa,
                configuracaoQuadroFuncionariosGrupos: gruposEtapas,
                utilizaFormatacaoGrupo: true,
                pendencias: {
                    pendenciasComponenteCurricular: 0,
                    pendenciasForm: 0
                }
            };

            vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas.push(quadroFuncEtapa);
        }

        function save(continuar) {
            beforeSave();

            var promise = QuadroFuncionariosService.save(vm.configQuadroFuncionario)
                .then(function(data) {
                    vm.configQuadroFuncionario = data;
                    handleReqSuccess(continuar);
                })
                .catch(function() {
                    _.forEach(vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas, function(etapa) {
                        etapa.hasReqError = true;
                    });
                });

                vm.tracker.save.addPromise(promise);
        }

        function beforeSave() {
            vm.configQuadroFuncionario.nivelModalidade = vm.nivelModalidade;
            vm.configQuadroFuncionario.nivelEscolar = vm.nivelEscolar;

            var etapas = vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas;

            _.forEach(etapas, function(etapa) {
                delete etapa.etapaMatriz.equivalencias;

                if(!etapa.utilizaFormatacaoGrupo) {
                    etapa.configuracaoQuadroFuncionariosGrupos = etapa.gruposNaoFormatados;
                }
            });
        }

        function handleReqSuccess(continuar) {
            publishNotification(NOTIFICATIONS_TYPE.SUCCESS);
            
            if(continuar) {
                refresh();
            } else {
                closeModal();
            }
        }

        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            vm.configQuadroFuncionario = {};
            vm.configQuadroFuncionario.ativo = 'ATIVO';
            vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas = [];
            focus('SelectMatriz');
        }
        
        function remove() {
            var promise = QuadroFuncionariosUtils.remove(vm.configQuadroFuncionario)
                            .then(function() {
                                closeModal();
                            });

            vm.tracker.save.addPromise(promise);
        }

        function hasEtapaComPendencias() {
            var etapas = vm.configQuadroFuncionario.configuracaoQuadroFuncionariosEtapas;

            return _.some(etapas, function(etapa) {
                if(etapa.pendencias) {
                    return etapa.pendencias.pendenciasForm || etapa.pendencias.pendenciasComponenteCurricular;
                }
                
                return false;
            });
        }

        function publishNotification(notificationType) {
            switch (notificationType) {
                case NOTIFICATIONS_TYPE.COMPONENTES_CURRICULARES_EMPTY: return bfcNotification.publish('Existem componentes curriculares não selecionados.', 'error');

                default: return bfcNotification.publish('Configuração salva com sucesso.', 'success');
            }
        }

        function setConfigAtivoOuInativo() {
            if (vm.configQuadroFuncionario.ativo === 'ATIVO') {
                vm.configQuadroFuncionario.ativo = 'INATIVO';
                return;
            }

            vm.configQuadroFuncionario.ativo = 'ATIVO';
        }

        function setWindowTitle() {
            if(vm.editando) {
                vm.windowTitle = 'Editando configuração do quadro de vagas de funcionários';
                return;
            }
            
            vm.windowTitle = 'Adicionando configuração ao quadro de vagas de funcionários';
        }

        function closeModal() {
            $modalInstance.close(vm.configQuadroFuncionario);
        }
    }
})();
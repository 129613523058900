(function () {
    'use strict';

    angular.module('educacao.common')
        .filter('joinArray', filter);

    function filter() {
        return join;
    }

    function join(arr, delimiter) {
        return arr.join(delimiter);
    }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('appAlunosIndicadores', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/alunos/alunos-indicadores.directive.html',
            scope: {},
            bindToController: {
                filtraAniversariante: '&'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'educacao.pessoas.AlunoService'];

    function Controller($scope, promiseTracker, AlunoService) {
        var vm = this;

        vm.estatisticas = {};

        vm.loadingTracker = promiseTracker();
        vm.filtraAniversairo = filtrafiltraAniversairo;

        load();

        function filtrafiltraAniversairo() {
            vm.filtraAniversariante();
        }

        AlunoService.on('dirty', load);

        $scope.$on('$destroy', function() {
            AlunoService.off(load);
        });

        function load() {
            vm.loadingTracker.addPromise(
                AlunoService.getEstatisticas().then(function(estatisticas) {
                    vm.estatisticas = estatisticas;
                })
            );
        }
    }
})();

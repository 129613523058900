(function () {

    'use strict';

    angular.module('educacao.encerramento')
        .directive('appEncerramentoFlagHeader', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'encerramento/encerramento-flag-header-directive/encerramento-flag-header.directive.html',
            scope: {},
            bindToController: {
                item: '=',
                column: '=',
                onClick: '&?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.encerramento.EncerramentoUtilsService'
    ];

    function Controller($scope, EncerramentoUtilsService) {

        var vm = this;

        vm.getCellClass = getCellClass;
        vm.mouseEnterLeave = mouseEnterLeave;
        vm.getStatusPeriodo = getStatusPeriodo;
        $scope.$watch('vm.item', getStatusPeriodo);

        function getStatusPeriodo(){

            if(vm.item[vm.column.id]){
                vm.item[vm.column.id].$$aberto = EncerramentoUtilsService.isStatusEmAberto(vm.item[vm.column.id]);
                vm.item[vm.column.id].$$abertoVm = vm.item[vm.column.id].$$aberto;    
            }
        }
        
        function getCellClass(item, coluna) {
            return {
                'fa-fw fa fa-unlock': item[coluna.id].$$abertoVm,
                'fa-fw fa fa-lock': !item[coluna.id].$$abertoVm
            };
        }

        function mouseEnterLeave(item, coluna, isEnter) {
            if (isEnter) {
                item[coluna.id].$$abertoVm = !item[coluna.id].$$aberto;
            } else {
                item[coluna.id].$$abertoVm = item[coluna.id].$$aberto;
            }
        }
    }
})();
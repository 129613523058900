(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .directive('situacaoRematricula', Directive);

    function Directive() {

        return {
            restrict: 'E',
            template: '<span class="label {{vm.class}} center-block">{{vm.descricaoSituacao}}</span>',
            scope: {},
            bindToController: {
                situacao: '=',
                situacoesRematricula: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.rematricula.RematriculaUtilsService'];

    function Controller(RematriculaUtilsService) {
        var vm = this;

        init();

        function init() {
            vm.descricaoSituacao = _.find(vm.situacoesRematricula, 'id', vm.situacao === null ? vm.situacoesRematricula.NAO_REMATRICULADO.key : vm.situacao).description;

            vm.class = RematriculaUtilsService.isRematriculado(vm.situacao) ? 'bg__green' : 'bg__red--l10';
        }
    }

})();


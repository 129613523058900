(function () {
    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.ConfiguracoesListaEsperaCtrl', Controller);

    Controller.$inject = [
        '$q',
        '$modalInstance',
        '$modalTracker',
        '$rootScope',
        'promiseTracker',
        'educacao.context.ContextoAnoLetivo',
        'educacao.matricula.CamposInscricaoService',
        'educacao.lista-espera.ConfiguracaoListaEsperaService',
        'bfc.dialog.Dialog'

    ];

    function Controller($q,
                        $modalInstance,
                        $modalTracker,
                        $rootScope,
                        promiseTracker,
                        ContextoAnoLetivo,
                        CamposInscricaoService,
                        ConfiguracaoListaEsperaService,
                        bfcDialog) {

        var vm = this;
        vm.options = {
            camposInscricao: undefined
        };

        vm.tracker = promiseTracker();
        vm.tracker.save = promiseTracker();
        vm.save = save;
        vm.configuracaoListaEspera = {};
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.openViewForm = openViewForm;
        vm.opemViewMensagem = opemViewMensagem;

        vm.configuracao = undefined;
        vm.configuracaoDefault = undefined;

        $modalTracker.addPromise(
            $q.all([
                    CamposInscricaoService.getList().then(function (data) {
                        vm.options.camposInscricao = data;
                    }),
                    ConfiguracaoListaEsperaService.get().then(function (data) {
                        vm.configuracao = data;
                    }),

                    ConfiguracaoListaEsperaService.getDefault().then(function (data) {
                        vm.configuracaoDefault = data;

                    })
                ])
                .then(init)
        );

        function init() {
            vm.configuracaoDefault.restringeAreaAbrangencia = false;
            vm.configuracaoDefault.permiteOutroEstabelecimento = false;

            vm.camposFixos = _.clone(vm.configuracaoDefault.campos);
            vm.configuracaoListaEspera = vm.configuracao ? vm.configuracao : vm.configuracaoDefault;
        }

        function fechaModal(reload) {
            $modalInstance.close({ reload: reload });
        }

        function save() {
            vm.tracker.save.addPromise(
                ConfiguracaoListaEsperaService.save(vm.configuracaoListaEspera).then(function () {
                    $rootScope.$broadcast('configuracoes::$viewContentLoaded');
                    fechaModal(true);
                })
            );
        }

        function openViewForm() {
            bfcDialog.open({
                templateUrl: 'matricula/configuracao-inscricao/campos/view-form.modal.html',
                controller: 'inscricao.ViewFormCtrl',
                controllerAs: 'vm',
                resolve: {
                    configuracao: vm.configuracaoListaEspera
                }
            });
        }

        function opemViewMensagem() {
            bfcDialog.open({
                templateUrl: 'common/parametros/parametrizacao.matriculas.mensagem.fim.lista.espera.html',
                controller: 'educacao.common.ControllerTemplate as vm',
                resolve: {
                    mensagem: vm.configuracaoListaEspera.msgFinalizacao
                }
            });
        }
    }
})();

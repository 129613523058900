(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosProfessoresGestaoPessoas', tableDadosProfessoresGestaoPessoas);

    function tableDadosProfessoresGestaoPessoas() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-professores-gestao-pessoas/table-dados-professores-gestao-pessoas/table-dados-professores-gestao-pessoas.html',
            scope: {},
            bindToController: {
                filter: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
                refresh: '=',
                tracker: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        'promiseTracker',
        'educacao.funcionario.FuncionarioService',
        'educacao.context.ContextoEstabelecimento'
    ];

    function Controller($scope, QuadroFuncionariosGestaoPessoasService, promiseTracker, FuncionarioService, ContextoEstabelecimento) {

        var vm = this;

        vm.COMPONENTE_CURRICULAR_UNICO = 1;
        vm.QTD_MAXIMA_COMPONENTES_CURRICULARES = 2;

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.service = QuadroFuncionariosGestaoPessoasService;
        vm.professores = [];
        vm.loadListProfessores = loadListProfessores;
        vm.currentPage = 1;
        vm.itemsPerPage = 20;
        vm.formatCargaHoraria = formatCargaHoraria;
        vm.formatComponentes = formatComponentes;
        vm.formatAtribuicoesAulas = formatAtribuicoesAulas;
        vm.getDescriptionTipoVinculo = getDescriptionTipoVinculo;
        vm.getDadosComponentesCurriculares = getDadosComponentesCurriculares;

        vm.tracker = {
            loading: promiseTracker(),
            init: promiseTracker()
        };

        $scope.$watch('vm.filter', watcherFilter);
        $scope.$watch('vm.anoLetivoSelected', onChangeContextEstabelecimento);
        $scope.$watch('vm.estabelecimentoSelected', onChangeContextEstabelecimento);
        $scope.$watch('vm.refresh', onRefresh);

        FuncionarioService.getEnum('TipoVinculo').then(function (TipoVinculo) {
            vm.TipoVinculo = TipoVinculo;
        });

        init();

        function init() {
            loadListProfessores();
        }

        function loadListProfessores() {
            var params = {
                filter: vm.filter,
                offset: (vm.currentPage - 1) > 0 ? (vm.currentPage - 1) * vm.itemsPerPage : 0,
                limit: vm.itemsPerPage,
                sort: null,
                estabelecimentoId: vm.isSecretaria ? _.get(vm.estabelecimentoSelected, 'id') : _.get(ContextoEstabelecimento.getEstabelecimento(), 'id'),
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id'),
            };
            var promise = vm.service.listProfessores(params).then(onLoadItens);
            vm.tracker.loading.addPromise(promise);
        }

        function onLoadItens(itens) {
            vm.professores = itens.content;
            vm.currentPage = (itens.pageable.offset / itens.pageable.limit) + 1;
            vm.totalItems = itens.total;
            vm.hasNext = itens.hasNext;
            vm.totalContent = itens.content.length;
        }

        function watcherFilter(newValue) {
            if (!_.isEmpty(newValue)) {
                loadListProfessores();
            }
        }

        function getDadosComponentesCurriculares(componentes) {
            return vm.formatComponentes(componentes).slice(vm.QTD_MAXIMA_COMPONENTES_CURRICULARES);

        }

        function onChangeContextEstabelecimento(newValue, oldValue) {
            if (newValue !== oldValue) {
                loadListProfessores();
            }
        }

        function onRefresh(oldvalue, newValue) {
            if (newValue !== oldvalue) {
                loadListProfessores();
            }
        }

        function formatCargaHoraria(locaisTrabalho) {
            if (_.isEmpty(locaisTrabalho)) {
                return '-';
            }

            var duration = _.chain(locaisTrabalho)
                .reduce(sumCargaHorariaFuncionario, moment.duration(0))
                .value();

            var hours = Math.floor(duration.asHours());
            var minutes = duration.minutes();
            return hours + 'h ' + minutes + 'm';
        }

        function sumCargaHorariaFuncionario(acumulador, localTrabalho) {
            return acumulador.add(localTrabalho.cargaHorariaSemanal);
        }

        function formatAtribuicoesAulas(locaisTrabalho) {
            var atribuicaoAulas = 0;
            var quantidadeTotalAulas = 0;

            _.forEach(locaisTrabalho, function (localTrabalho) {
                atribuicaoAulas = atribuicaoAulas + localTrabalho.atribuicoesAulas.atribuicoesAulas;
            });

            if (vm.isSecretaria) {
                quantidadeTotalAulas = _.sum(locaisTrabalho, 'quantidadeAulas');
            } else {
                quantidadeTotalAulas = _.get(_.first(locaisTrabalho), 'quantidadeAulas') || 0;
            }

            return atribuicaoAulas + ' de ' + quantidadeTotalAulas + ' aulas';
        }

        function formatComponentes(componentes) {
            return _.uniq(componentes, 'componenteCurriculaId') && _.uniq(componentes,'descricaoComponente');
        }

        function getDescriptionTipoVinculo(tipo) {
            if (tipo) {
                return _.get(_.find(vm.TipoVinculo, {key: tipo}), 'description', null);
            }
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edValidation', edValidation)
        .directive('edValidate', edValidate);

    edValidation.$inject = ['$injector', '$rootScope'];
    edValidate.$inject = ['$injector'];

    function edValidation($injector, $rootScope) {
        function _link(scope, element, attrs, ngModel) {
            var id = attrs.ngModel;

            var idParent = scope.$parent.$id;

            scope.$watch('specification', function(newValue, oldValue) {

                if (newValue !== oldValue || ngModel.$pristine) {

                    $rootScope.$broadcast('edValidate' + idParent, {
                        data: {
                            id: id,
                            isValid: scope.specification,
                            message: scope.message,
                            typeMessage: scope.typeMessage
                        }
                    });
                }
            });

        }

        return {
            restrict: 'E',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                typeMessage: '@',
                message: '@',
                specification: '='
            },
            link: _link
        };
    }

    function edValidate() {

        function _link(scope) {
            var validations = [];

            var idParent = scope.$parent.$id;

            scope.$on('edValidate' + idParent, function(events, args) {
                validations[args.data.id] = _.pick(args.data, 'isValid', 'message', 'typeMessage');
                scope.$parent.validations = validations;
            });
        }

        return {
            restrict: 'A',
            scope: {},
            link: _link
        };
    }
})();

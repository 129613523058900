(function () {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.historico.HistoricoServiceProvider'];
    function HistoricoConfig(HistoricoServiceProvider) {

        HistoricoServiceProvider.addTipoHandler('DISPENSA', {
            template: '<small>Dispensa</small>',
            campos: {
                itemOrganizacaoCurricular: {
                    insercao: {
                        template: '<br><small>Componente curricular: <strong>{{valor}}</strong></small>'
                    },
                    alteracao: {
                        template: '<br><small>Componente curricular alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small>'
                    }
                },
                motivo: {
                    insercao: {
                        template: '<br><small>Motivo: <strong>{{valor}}</strong></small>'
                    },
                    alteracao: {
                        template: '<br><small>Motivo alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small>'
                    }
                }
            }
        });
    }
})();
(function () {

    'use strict';

    angular.module('educacao.preparacaoAnoLetivo')
        .service('educacao.preparacaoAnoLetivo.PreparacaoAnoLetivoService', PreparacaoAnoLetivoService);

    PreparacaoAnoLetivoService.$inject = ['Restangular'];

    function PreparacaoAnoLetivoService(Restangular) {
        var self = this;

        self.copiar = copiar;

        function copiar(dadosCopia) {
            return Restangular.all('copia-ano-letivo')
                .post(dadosCopia);
        }
    }

})();
(function() {
    'use strict';

    angular.module('educacao.matriz')
        .directive('appSelectEixoTematico', SelectEixoTematicoDirective);

    function SelectEixoTematicoDirective() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/matriz/eixo-tematico/app-template.html',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                eixosExistentes: '=?',
                ngDisabled: '=',
                ngChange: '&?',
            },
            bindToController: {
                formulario: '=',
            },
            controller: SelectEixoTematicoDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }
                if (angular.isDefined(tAttrs.ngRequired)) {
                    element.children('ui-select').attr('not-empty', 'ngRequired');
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectEixoTematicoDirectiveController.$inject = [
        '$scope', 
        'ui.components.Modal',
        'bfc.$$PermissionsService',
        'educacao.matriz.EixoTematicoService'
    ];

    function SelectEixoTematicoDirectiveController($scope, $modal, PermissionsService, service) {

        var vm = this;

        vm.searchEixoTematico = searchEixoTematico;
        vm.add = !PermissionsService.isRevokedOperation('EixoTematicoPageMapping', 'criar') && add;
        vm.getClass = getClass;

        function searchEixoTematico(params) {
            var filter = '(descricao like "' + params.term + '"';
            var eixosTematicos = [];

            if ($scope.multiple) {
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(eixoTematico) {
                        eixosTematicos.push(eixoTematico.id);
                    });
                }
            }

            if (angular.isArray($scope.eixosExistentes) && $scope.eixosExistentes.length > 0) {
                _.forEach($scope.eixosExistentes, function(value) {
                    if (value.eixoTematico && value.eixoTematico.id) {
                        eixosTematicos.push(value.eixoTematico.id);
                    }
                });
            }

            if (eixosTematicos.length) {
                filter += ' and id not in(' + eixosTematicos.join() + ')';
            }

            filter += ')';

            return service.getList(params.offset, params.limit, filter).then(function(data) {
                return {
                    content: data,
                    hasNext: false
                };
            });
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'planejamento/matriz/eixo-tematico/eixo-tematico.cad.html',
                controller: 'educacao.matriz.EixoTematicoCadCtrl as vm',
                params: {
                    eixoTematico: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }

        function getClass(){

            return (vm.formulario) ? '' : 'select2-spreadsheet';

        }
    }

})();


(function () {
    'use strict';

    angular.module('educacao.agendaprofessor')
        .directive('calendarioProfessor', CalendarioProfessor);

    function CalendarioProfessor() {
        return {
            restrict: 'E',
            templateUrl: 'agenda-professor/calendario-professor.directive.html',

            scope: {
                evento: '=',
                feriado: '=',
                tracker: '=',
                calendarioDisplay: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$q',
        '$scope',
        '$rootScope',
        'ViewProfessorController',
        'AGENDA_TITLE_CHANGED_EVENT',
        'AGENDA_PREVIOUS_PAGE_EVENT',
        'AGENDA_NEXT_PAGE_EVENT',
        'AGENDA_TO_DAY_EVENT',
        'CalendarioDisplay',
        'educacao.agendaprofessor.CalendarioProfessorService',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'promiseTracker'
    ];

    function Controller($q,
                        $scope,
                        $rootScope,
                        ViewProfessorController,
                        AGENDA_TITLE_CHANGED_EVENT,
                        AGENDA_PREVIOUS_PAGE_EVENT,
                        AGENDA_NEXT_PAGE_EVENT,
                        AGENDA_TO_DAY_EVENT,
                        CalendarioDisplay,
                        CalendarioProfessorService,
                        ContextoEstabelecimento,
                        ContextoAnoLetivo,
                        promiseTracker) {

        var vm = this;

        vm.calendarView = new ViewProfessorController();

        vm.isAgendaView = isAgendaView;
        vm.calendarioDisplay = $scope.calendarioDisplay;
        vm.previousPage = previousPage;
        vm.nextPage = nextPage;
        vm.today = toDay;
        vm.goToEvento = goToEvento;
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.trackers = [];
        vm.trackers.calendario = promiseTracker();
        vm.trackers.eventos = promiseTracker();
        vm.habilitaPainelEvento = false;
        init();
        vm.alteraStatusPanelEventos = alteraStatusPanelEventos;

        function init() {
            $scope.$on(AGENDA_TITLE_CHANGED_EVENT, onAgendaTitleChanged);
        }

        $scope.$watch('evento', searchEventos, true);
        $scope.$watch('feriado', searchFeriados, true);

        function searchFeriados() {
            if ($scope.feriado) {
                vm.trackers.calendario.addPromise(
                    CalendarioProfessorService.getFeriados().then(function (data) {
                        vm.calendarioDisplay.feriados = data;
                    })
                );
            } else {
                vm.calendarioDisplay.feriados = [];
            }
        }

        function alteraStatusPanelEventos(status) {
            vm.habilitaPainelEvento = status;
        }

        function goToEvento() {
            vm.trackers.eventos.addPromise(
                CalendarioProfessorService.getEventos().then(function (data) {
                    vm.eventos = filtraEventos(data);
                })
            );
            alteraStatusPanelEventos(true);
        }

        function searchEventos() {
            if ($scope.evento) {
                vm.trackers.calendario.addPromise(
                    CalendarioProfessorService.getEventos().then(function (data) {
                        vm.calendarioDisplay.eventos = filtraEventos(data);
                    })
                );
            } else {
                vm.calendarioDisplay.eventos = [];
            }

        }

        function filtraEventos(eventos) {
            var eventosFiltro = [];

            eventos.forEach(function (evento) {

                if (_.isEmpty(evento.estabelecimentos) && evento.aceite !== null) {
                    return;
                }
                eventosFiltro.push(evento);
            });

            return eventosFiltro;

        }

        function onAgendaTitleChanged(event, title) {
            vm.agendaTitle = title;
        }

        function previousPage() {
            $scope.$broadcast(AGENDA_PREVIOUS_PAGE_EVENT);
        }

        function nextPage() {
            $scope.$broadcast(AGENDA_NEXT_PAGE_EVENT);
        }

        function toDay() {
            $scope.$broadcast(AGENDA_TO_DAY_EVENT);
        }

        function isAgendaView() {
            return vm.calendarView.isWeekView() || vm.calendarView.isMonthView() || vm.calendarView.isDayView();
        }
    }
})();

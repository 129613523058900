(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.CalendarioMatrizCurricularModalController', Controller);

    Controller.$inject = ['OBSERVACAO_POPOVER_EDIT_EVENT',
        'DATE_FORMAT',
        'DayOfWeek',

        '$q',
        '$scope',
        '$timeout',
        '$filter',
        'promiseTracker',
        'bfc.dialog.Dialog',
        'bfc.Notification',

        'educacao.matriz.MatrizService',
        'educacao.calendario.CalendarioMatrizCurricularService',
        'educacao.matricula.TurmaService',

        'idCalendarioEstabelecimento',
        'calendarioDisplay',
        'calendarioMatriz',

        'fnCad',
        'ui.components.Modal'
    ];

    function Controller(OBSERVACAO_POPOVER_EDIT_EVENT,
        DATE_FORMAT,
        DayOfWeek,
        $q,
        $scope,
        $timeout,
        $filter,
        promiseTracker,
        bfcDialog,
        Notification,
        matrizService,
        calendarioMatrizCurricularService,
        TurmaService,
        idCalendarioEstabelecimento,
        calendarioDisplay,
        calendarioMatriz,
        fnCad,
        $modal) {

        var vm = this;
        var orderBy = $filter('orderBy');
        var baseCalendarioDisplay = calendarioDisplay.clone();

        var enumDivisaoPeriodoAvaliativo = {};

        vm.calendarioMatriz = calendarioMatriz;
        vm.calendario = undefined;
        vm.totalDias = 0;
        vm.isQuantidadeDiasLetivosValido = true;

        resetCalendarioDisplay();

        vm.saveTracker = promiseTracker();

        vm.divisaoPeriodoAvaliativoEnumPromise = calendarioMatrizCurricularService.getEnumDivisaoPeriodoAvaliativo().then(extendEnumDivisaoPeriodoAvaliativo);
        vm.dayOfWeekEnumPromise = $q.when(DayOfWeek);
        vm.formatDayOfWeek = formatDayOfWeek;
        vm.searchMatrizesCurriculares = searchMatrizesCurriculares;
        vm.formatTipoPeriodoAvaliativo = formatTipoPeriodoAvaliativo;
        vm.setDescricaoPeriodoAvaliativo = setDescricaoPeriodoAvaliativo;

        vm.recalculaDiasLetivos = recalculaDiasLetivos;

        vm.openPeriodoAvaliativoModal = openPeriodoAvaliativoModal;

        vm.save = save;
        vm.saveAndNew = saveAndNew;

        init();

        function init() {
            if (vm.calendarioMatriz) {
                setCalendarioVars();
                vm.calendarioMatriz.periodosAvaliativos.forEach(recalculaDiasLetivos);
            } else {
                loadDefaultRepresentation();
            }

            $scope.$on(OBSERVACAO_POPOVER_EDIT_EVENT, onObservacaoEdited);

            $scope.$watch('vm.calendarioMatriz.matrizCurricular', onMatrizChanged);
            $scope.$watch('vm.calendarioMatriz.tipoPeriodoAvaliativo', onTipoPeriodoAvaliativoChanged);
            $scope.$watchCollection('vm.calendario.diasLetivosSemana', recalculaDiasLetivosTodosPeriodos);

            $scope.$watch('vm.totalDias', validaQuantidadeDias);

            if (vm.calendarioMatriz) {
                vm.periodosOld = _.cloneDeep(vm.calendarioMatriz.periodosAvaliativos);
            }
            vm.periodosAlterados = [];

        }

        function loadDefaultRepresentation() {
            calendarioMatrizCurricularService.getDefaultRepresentation().then(function (data) {
                vm.calendarioMatriz = data;
                setCalendarioVars();
            });
        }

        function setDescricaoPeriodoAvaliativo(index, periodo) {
            periodo.descricao = index + 'º ' + vm.calendarioMatriz.descricaoPeriodoAvaliativo;
            return periodo.descricao;
        }

        function setCalendarioVars() {
            vm.calendarioMatriz.periodosAvaliativos = orderBy(vm.calendarioMatriz.periodosAvaliativos, 'dataInicial');

            vm.calendario = vm.calendarioMatriz.calendario;

            vm.calendario.dataInicial = vm.calendario.dataInicial || calendarioDisplay.dataInicial;
            vm.calendario.dataFinal = vm.calendario.dataFinal || calendarioDisplay.dataFinal;

            vm.calendarioDisplay
                .setBaseCalendar(vm.calendario)
                .addCalendario(vm.calendario, 'matriz')
                .process();

            setDiasLetivosSemana();
        }

        function setDiasLetivosSemana() {
            if (_.isEmpty(vm.calendario.diasLetivosSemana)) {
                vm.calendario.diasLetivosSemana = DayOfWeek.values.filter(function (entry) {
                    return entry.value < 6;
                }).map(getKey);
            }
        }

        function getKey(entry) {
            return entry.key || entry;
        }

        function formatDayOfWeek(data) {
            return moment.weekdays(DayOfWeek[data.key].value);
        }

        function filtraEventosMatriz(evento) {
            if (!vm.calendarioMatriz.matrizCurricular) {
                return false;
            }
            return _.isEmpty(evento.matrizesCurriculares) || _.find(evento.matrizesCurriculares, {
                id: vm.calendarioMatriz.matrizCurricular.id
            });
        }

        function onObservacaoEdited(event, observacao) {
            vm.calendario.observacao = observacao;
        }

        function searchMatrizesCurriculares(params) {
            var filter = '(descricao like "' + params.term + '"' +
                ' and ' + matrizService.getFilterModular() + ')';

            return matrizService.findTodos(params.offset, params.limit, filter)
                .then(function (data) {
                    return {
                        content: data,
                        hasMore: false
                    };
                });
        }

        function formatTipoPeriodoAvaliativo(data) {
            if (isMatrizEja()) {
                var quantidade = data.quantidadePeriodos;
                return quantidade + ' período' + (quantidade > 1 ? 's' : '');
            }
            return data.description;
        }

        function isMatrizEja() {
            var matriz = vm.calendarioMatriz.matrizCurricular;
            return matriz && (matriz.curso.nivelModalidade === 'EJA' || matriz.curso.nivelModalidade === 'ACELERACAO');
        }

        function onMatrizChanged(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            refreshTipoPeriodoAvaliativo();

            createPeriodosAvaliativos();
            vm.calendarioDisplay.process();
        }

        function refreshTipoPeriodoAvaliativo() {
            var tipo = vm.calendarioMatriz.tipoPeriodoAvaliativo;
            delete vm.calendarioMatriz.tipoPeriodoAvaliativo;
            $timeout(function () {
                vm.calendarioMatriz.tipoPeriodoAvaliativo = tipo;
            }, 1);
        }

        function onTipoPeriodoAvaliativoChanged(newValue, oldValue) {
            if (newValue === oldValue || !newValue) {
                return;
            }
            vm.calendarioMatriz.descricaoPeriodoAvaliativo = isMatrizEja() ? 'período' : enumDivisaoPeriodoAvaliativo[newValue].labelPeriodo;
            createPeriodosAvaliativos();
        }

        function validaQuantidadeDias() {
            vm.isQuantidadeDiasLetivosValido = vm.calendarioMatriz.matrizCurricular && vm.totalDias >= vm.calendarioMatriz.matrizCurricular.diasLetivos;
        }

        function createPeriodosAvaliativos() {
            if (!vm.calendarioMatriz || !vm.calendarioMatriz.matrizCurricular || !vm.calendarioMatriz.tipoPeriodoAvaliativo || !vm.calendario || !vm.calendario.diasLetivosSemana || !vm.calendario.diasLetivosSemana.length) {
                return;
            }
            vm.calendarioDisplay.process();

            var divisaoPeriodoAvaliativo = vm.calendarioMatriz.tipoPeriodoAvaliativo;

            var periodos = [];
            var quantidadePeriodos = enumDivisaoPeriodoAvaliativo[divisaoPeriodoAvaliativo].quantidadePeriodos;

            var totalDiasLetivos = vm.calendarioMatriz.matrizCurricular.diasLetivos;

            var divisaoDiasLetivos = totalDiasLetivos / quantidadePeriodos;
            var diasLetivos = Math.floor(divisaoDiasLetivos);

            _.times(quantidadePeriodos, function () {
                periodos.push({
                    $$diasLetivos: diasLetivos
                });
            });

            if (quantidadePeriodos && divisaoDiasLetivos !== diasLetivos) {
                var quantidadeRedistribuir = totalDiasLetivos - (diasLetivos * quantidadePeriodos);

                _.times(quantidadeRedistribuir, function (i) {
                    periodos[i % quantidadePeriodos].$$diasLetivos++;
                });
            }

            calculaDatasPeriodosLetivos(periodos);

            vm.calendarioMatriz.periodosAvaliativos = periodos;
        }

        function extendEnumDivisaoPeriodoAvaliativo(enumObject) {

            angular.forEach(enumObject, function (value, key) {
                switch (key) {
                    case 'ANUAL':
                        value.quantidadePeriodos = 1;
                        value.labelPeriodo = 'ano';
                        break;
                    case 'SEMESTRAL':
                        value.quantidadePeriodos = 2;
                        value.labelPeriodo = 'semestre';
                        break;
                    case 'TRIMESTRAL':
                        value.quantidadePeriodos = 3;
                        value.labelPeriodo = 'trimestre';
                        break;
                    case 'BIMESTRAL':
                        value.quantidadePeriodos = 4;
                        value.labelPeriodo = 'bimestre';
                        break;
                }
            });

            enumDivisaoPeriodoAvaliativo = enumObject;

            return enumObject;
        }

        function calculaDatasPeriodosLetivos(periodos) {
            if (!periodos.length) {
                return;
            }

            var initialDate = vm.calendarioDisplay.getProximaDataLetivaAsMoment(vm.calendario.dataInicial);

            var totalDias = 0;

            _.forEach(periodos, function (periodo) {
                if (!initialDate) {
                    periodo.$$diasLetivos = 0;
                    return;
                }

                periodo.dataInicial = initialDate.format(DATE_FORMAT);
                vm.calendarioDisplay.calculaDataFinalPeriodo(periodo);

                totalDias += periodo.$$diasLetivos;

                initialDate = vm.calendarioDisplay.getProximaDataLetivaAsMoment(moment(periodo.dataFinal).add(1, 'days'));
            });

            vm.calendario.dataInicial = _.first(periodos).dataInicial;
            vm.calendario.dataFinal = _.last(periodos).dataFinal;

            vm.totalDias = totalDias;
        }

        function recalculaDiasLetivos(periodo) {

            verifyPeriodoAlterado(periodo);

            updateDatasCalendarioMatriz();

            var oldDiasLetivos = periodo.$$diasLetivos || 0;
            var newDiasLetivos = vm.calendarioDisplay.contaDiasLetivos(periodo.dataInicial, periodo.dataFinal);

            periodo.$$diasLetivos = newDiasLetivos;

            vm.totalDias = Math.max(0, vm.totalDias + newDiasLetivos - oldDiasLetivos);
        }

        function recalculaDiasLetivosTodosPeriodos() {
            angular.forEach(vm.calendarioMatriz.periodosAvaliativos, recalculaDiasLetivos);
        }

        function updateDatasCalendarioMatriz() {
            var periodosAvaliativos = vm.calendarioMatriz.periodosAvaliativos;

            var dataInicial = moment(_.first(periodosAvaliativos).dataInicial);
            var dataFinal = moment(_.last(periodosAvaliativos).dataFinal);

            angular.forEach(periodosAvaliativos, function (periodo) {
                if (dataInicial.isAfter(periodo.dataInicial)) {
                    dataInicial = moment(periodo.dataInicial);
                }
                if (dataFinal.isBefore(periodo.dataFinal)) {
                    dataFinal = moment(periodo.dataFinal);
                }
            });

            vm.calendario.dataInicial = dataInicial.format(DATE_FORMAT);
            vm.calendario.dataFinal = dataFinal.format(DATE_FORMAT);
            vm.calendarioDisplay.process();
        }

        function openPeriodoAvaliativoModal() {
            bfcDialog.open({
                templateUrl: 'calendario/calendario-matriz/periodo-avaliativo-modal.controller.html',
                controller: 'educacao.calendario.PeriodoAvaliativoModal as vm',
                resolve: {
                    calendarioDisplay: vm.calendarioDisplay.clone(),
                    periodosAvaliativos: $q.when(_.cloneDeep(vm.calendarioMatriz.periodosAvaliativos)),
                    descricaoPeriodoAvaliativo: vm.calendarioMatriz.descricaoPeriodoAvaliativo,
                    saveCallback: $q.when(onPeriodosSaved)
                }
            });
        }

        function onPeriodosSaved(calendario) {
            vm.calendarioDisplay
                .setBaseCalendar(calendario)
                .addCalendario(calendario);

            vm.calendarioMatriz.calendario = calendario;
            vm.calendario = calendario;

            vm.calendarioDisplay.process();
            vm.calendarioMatriz.periodosAvaliativos.forEach(recalculaDiasLetivos);
        }

        function saveAndNew() {
            vm.saveTracker.addPromise(
                calendarioMatrizCurricularService
                    .save(idCalendarioEstabelecimento, vm.calendarioMatriz)
                    .then(fnCad)
                    .then(notifySuccessulSave)
                    .then(resetCalendarioDisplay)
                    .then(loadDefaultRepresentation)
            );
        }

        function save() {
            if (!periodoIsValid()) {
                Notification.publish('Os períodos estão incorretos.', 'error');
                return;
            }

            if (vm.calendarioMatriz.id && !_.isEmpty(vm.periodosAlterados)) {
                showRegistrosVinculados();
                return;
            }

            vm.saveTracker.addPromise(saveCalendario());
        }

        function saveCalendario() {
            return calendarioMatrizCurricularService
                .save(idCalendarioEstabelecimento, vm.calendarioMatriz)
                .then(fnCad)
                .then(notifySuccessulSave)
                .then($scope.$modalInstance.close);
        }

        function notifySuccessulSave() {
            Notification.publish('Calendário da matriz salvo com sucesso.', 'success');
            $scope.form.$setPristine();
            $scope.form.$setUntouched();
        }

        function showRegistrosVinculados() {
            var filter = 'matriz = ' + vm.calendarioMatriz.matrizCurricular.id;
            filter += ' and (calendarioMatrizCurricular = ' + vm.calendarioMatriz.id + ' or calendarioMatrizCurricular is null)';
            var promise = TurmaService.getList({
                filter: filter,
                limit: 99
            }).then(openModalRegistroVinculados);

            vm.saveTracker.addPromise(promise);
        }

        function openModalRegistroVinculados(turmas) {
            if (_.isEmpty(turmas.content)) {
                vm.saveTracker.addPromise(saveCalendario());
                return;
            }

            $modal.open({
                templateUrl: 'calendario/calendario-matriz/registros-vinculados/registros-vinculados-modal.html',
                controller: 'educacao.calendario.RegistrosVinculadosModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    calendarioMatriz: vm.calendarioMatriz,
                    periodosChanged: vm.periodosAlterados,
                    turmas: turmas
                }
            }).result.then(notifyAndClose);
        }

        function notifyAndClose() {
            Notification.publish('Calendário da matriz enviado para ajustes e salvamento. Ao final do processo você será notificado', 'success');
            $scope.form.$setPristine();
            $scope.form.$setUntouched();
            $scope.$modalInstance.close();
        }

        function resetCalendarioDisplay() {
            vm.totalDias = 0;
            $scope.calendarioDisplay = baseCalendarioDisplay.clone();
            vm.calendarioDisplay = $scope.calendarioDisplay;
            vm.calendarioDisplay.addFiltroEventos(filtraEventosMatriz);
        }

        function verifyPeriodoAlterado(periodo) {

            if (!vm.periodosOld || !periodo.id) {
                return;
            }

            var periodoOld = _.find(vm.periodosOld, { id: periodo.id });
            _.remove(vm.periodosAlterados, { id: periodo.id });

            if (periodoOld.dataInicial === periodo.dataInicial &&
                periodoOld.dataFinal === periodo.dataFinal &&
                periodoOld.descricao === periodo.descricao) {
                return;
            }

            var dataInicial = moment(periodo.dataInicial);
            var dataInicialOld = moment(periodoOld.dataInicial);

            var dataFinal = moment(periodo.dataFinal);
            var dataFinalOld = moment(periodoOld.dataFinal);

            // Ao extender o período inicial ou final, não é necessário realocar períodos
            if ((periodo.id === _.first(vm.periodosOld).id &&
                dataInicial.isBefore(dataInicialOld) && periodoOld.dataFinal === periodo.dataFinal) ||
                (periodo.id === _.last(vm.periodosOld).id &&
                    dataFinal.isAfter(dataFinalOld) && periodoOld.dataInicial === periodo.dataInicial)) {
                return;
            }

            vm.periodosAlterados.push(periodo);
        }

        function periodoIsValid() {
            var finalPeriodoAnterior = 0;
            var result = true;
            _.forEach(vm.calendarioMatriz.periodosAvaliativos, function (periodo) {
                if (periodo.dataInicial <= finalPeriodoAnterior || periodo.dataInicial >= periodo.dataFinal) {
                    result = false;
                }
                finalPeriodoAnterior = periodo.dataFinal;
            });
            return result;
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.pessoas')
		.controller('educacao.pessoas.DeficienciasCadCtrl', DeficienciasCadCtrl);

	DeficienciasCadCtrl.$inject = ['$injector', '$scope', 'promiseTracker', '$modalInstance'];

	function DeficienciasCadCtrl($injector, $scope, promiseTracker, $modalInstance) {
		var Notification = $injector.get('bfc.Notification');
		var DeficienciaService = $injector.get('educacao.pessoas.DeficienciaService');

		var vm = this;

		var propriedadesPublicas = {
			remove: _remove,
			save: _save
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.deficiencia = $modalInstance.params.deficiencia || {};

			vm.tracker = promiseTracker();

			vm.mode = {
				adicionando: $modalInstance.params.canSaveAdd,
				editando: vm.deficiencia.id
			};
		}

		function _save(continuar) {
			var promise = DeficienciaService.save(vm.deficiencia).then(function(data) {
				Notification.publish('Deficiência salva com sucesso', 'success');
				if (continuar) {
					vm.deficiencia = {};
					vm.form.$setUntouched();
				} else {
					$modalInstance.close(data);
				}
			});
			vm.tracker.addPromise(promise);
		}

		function _remove() {
			var promise = DeficienciaService.remove(vm.deficiencia).then(function() {
				$modalInstance.close();
			});

			vm.tracker.addPromise(promise);
		}
	}
})();


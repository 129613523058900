(function() {
	'use strict';

	angular.module('educacao.feriado')
		.controller('educacao.feriado.FeriadosCtrl', Controller);

	Controller.$inject = ['$injector', '$scope', 'promiseTracker'];

	function Controller($injector, $scope, promiseTracker) {
		var vm = this;

		var FeriadoService = $injector.get('educacao.feriado.FeriadoService');
		var $modalStates = $injector.get('$modalStates');
		var $enumsCache = $injector.get('$enumsCache');

		var propriedadesPublicas = {
			openModal: _openModal,
			remove: _remove
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.feriadoService = FeriadoService;
			vm.customBuilder = {
				dataFeriado: function(filters) {
					if (filters.dataFeriado) {
						return 'dataFeriado = ' + filters.dataFeriado;
					}
				}
			};

			vm.tracker = {
				list: promiseTracker()
			};

			initEnums();
		}

		function initEnums() {
			vm.abrangencia = $enumsCache.get('AbrangenciaFeriado')
				.then(function(enumData) {
					vm.enumAbrangencia = enumData;
					return enumData;
				});

			vm.tipo = $enumsCache.get('TipoFeriado')
				.then(function(enumData) {
					vm.enumTipo = enumData;
					return enumData;
				});

			vm.tracker.list.addPromise(vm.abrangencia);
			vm.tracker.list.addPromise(vm.tipo);
		}

		function _openModal(feriado) {
			$modalStates.goModalState(feriado);
		}

		function _remove(data) {
			var promise = FeriadoService.remove(data);

			return promise.then(function error() {
				return promise;
			});
		}

		$scope.$on('refreshFeriado:remove', function(eventos, params) {
			angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
		});
	}
})();


(function() {

    'use strict';

    angular.module('educacao.common')
        .filter('formataObito', filter);

    filter.$inject = ['$filter'];

    function filter($filter) {
        return formatarObito;

        function formatarObito(obito) {

            var obitoFormatado;

            if (obito.dataObito) {
                obitoFormatado = $filter('date')(obito.dataObito, 'dd/MM/yyyy');
            }
            if (obito.causaMortis) {
                obitoFormatado += ' (' + obito.causaMortis + ') ';
            }

            return obitoFormatado;
        }
    }
})();


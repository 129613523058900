(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('selecaoEtapa', directive);

    function directive() {
        return {
            restrict: 'E',

            scope: {inscricao: '='},

            templateUrl: 'matricula/inscricao/step-localizando/selecao-etapa/selecao-etapa.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'InscricaoMatriculaRegularService'];
    function Controller($scope, promiseTracker, InscricaoMatriculaRegularService) {
        var vm = this;

        vm.nivelEscolar = undefined;
        vm.dataNascimento = undefined;
        vm.equivalencia = undefined;
        vm.idConfiguracao = undefined;

        vm.dataNascimento = null;
        vm.loadingEtapas = promiseTracker();
        vm.loadingConfig = promiseTracker();

        vm.buttons = {
            EDUCACAO_INFANTIL: {
                outerSelected: false,
                selected: false
            },
            ENSINO_FUNDAMENTAL: {
                outerSelected: false,
                selected: false
            },
            ENSINO_MEDIO: {
                outerSelected: false,
                selected: false
            }
        };

        vm.onButtonClicked = onButtonClicked;
        vm.onDataNascimentoChanged = onDataNascimentoChanged;
        vm.onEquivalenciaSelected = onEquivalenciaSelected;

        vm.isValid = isValid;
        vm.next = next;

        load();

        function load() {
            vm.loadingEtapas.addPromise(
                InscricaoMatriculaRegularService.getEquivalenciaEtapa().then(function (EquivalenciaEtapa) {
                    vm.EquivalenciaEtapa = EquivalenciaEtapa;
                })
            );

            vm.loadingEtapas.addPromise(
                InscricaoMatriculaRegularService.listEtapas().then(function (data) {
                    _.forEach(vm.buttons, function (btn) {
                        btn.visible = false;
                    });

                    vm.visibleButtons = data.length;

                    vm.offsetClass = 'col-md-' + ((_.size(vm.buttons) - data.length) * 2);

                    _.forEach(data, function (etapaInscricao) {
                        var button = vm.buttons[etapaInscricao.nivelEscolar];
                        button.visible = true;
                        button.equivalencias = etapaInscricao.equivalencias;
                        button.idConfiguracao = etapaInscricao.id;
                    });
                }).then(watchValues)
            );
        }

        function watchValues() {
            $scope.$watch('inscricao.configuracao.id', function (newValue) {
                vm.idConfiguracao = newValue;
            });
            $scope.$watch('inscricao.configuracao.nivelEscolar', function (newValue) {
                vm.nivelEscolar = newValue;
                if (newValue) {
                    onButtonClicked(vm.buttons[vm.nivelEscolar]);
                } else {
                    unselectButtons();
                }
            });
            $scope.$watch('inscricao.dataNascimento', function (newValue) {
                vm.dataNascimento = newValue;
            });
            $scope.$watch('inscricao.equivalencia', function (newValue) {
                vm.equivalencia = newValue;
            });
        }

        function unselectButtons() {
            _.forEach(vm.buttons, function (btn) {
                btn.outerSelected = btn.selected = false;
            });
        }

        function onButtonClicked(button) {
            vm.nivelEscolar = null;
            vm.dataNascimento = null;
            vm.equivalencia = null;
            _.forEach(vm.buttons, function (btn, key) {
                btn.selected = btn === button;
                btn.outerSelected = !btn.selected;

                if (btn.selected) {
                    vm.nivelEscolar = key;
                    vm.idConfiguracao = btn.idConfiguracao;
                }
            });
        }

        function onDataNascimentoChanged() {
            vm.dataNascimento = vm.dataNascimento;
            vm.equivalencia = null;
        }

        function onEquivalenciaSelected(value) {
            vm.dataNascimento = null;
            vm.equivalencia = value;
        }

        function isValid() {
            return vm.nivelEscolar && (vm.dataNascimento || vm.equivalencia);
        }

        function next() {
            if (!vm.idConfiguracao) {
                return;
            }

            vm.loadingConfig.addPromise(
                InscricaoMatriculaRegularService
                    .loadConfiguracao(vm.idConfiguracao)
                    .then(setConfiguracao)
            );
        }

        function setConfiguracao(configuracao) {
            $scope.inscricao.configuracao = configuracao;
            $scope.inscricao.dataNascimento = $scope.inscricao.dados.dataNascimento = vm.dataNascimento;
            $scope.inscricao.equivalencia = vm.equivalencia;
            $scope.inscricao.$$etapaSelecionada = true;
        }
    }
})();
(function () {
	'use strict';

	angular.module('educacao.matricula').controller('educacao.matricula.DesenturmacaoProfessorCtrl',
		DesenturmacaoProfessorCtrl);

	DesenturmacaoProfessorCtrl.$inject = ['$scope', '$modalInstance', '$injector', 'promiseTracker', '$enumsCache'];

	function DesenturmacaoProfessorCtrl($scope, $modalInstance, $injector, promiseTracker, $enumsCache) {
		var vm = this;

		var EnturmacaoProfessorService = $injector.get('educacao.matricula.EnturmacaoProfessorService');
		var $commons = $injector.get('educacao.common.$commons');

		var turmaVerificacao = [];

		vm.adiciona = adiciona;
		vm.atualizaDisciplina = atualizaDisciplina;
		vm.formatResult = formatResult;
		vm.getListaIdTurmasSelected = getListaIdTurmasSelected;
		vm.hasAnyTurmaAndByFuncionario = hasAnyTurmaAndByFuncionario;
		vm.hasDesenturmarTodasTurmas = hasDesenturmarTodasTurmas;
		vm.hasFilterComplete = hasFilterComplete;
		vm.isDesenturmarTodasTurmas = isDesenturmarTodasTurmas;
		vm.isTipoRegente = isTipoRegente;
		vm.isEnturmacaoPorTurma = isEnturmacaoPorTurma;
		vm.remove = remove;
		vm.save = save;

		init();

		function atualizaDisciplina(index) {
			if (!turmaVerificacao[index] ||
				turmaVerificacao[index] !== _.get(vm.desenturmar.turmas[index], 'turma.id')) {
				turmaVerificacao[index] = _.get(vm.desenturmar.turmas[index], 'turma.id');
				vm.desenturmar.turmas[index].componente = null;
			}
		}

		$scope.$watch('vm.desenturmar.desenturmarTodas', atualizaGruposEnturmacao);

		function atualizaGruposEnturmacao(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			vm.desenturmar.turmas = newValue ? [] : [{}];
		}

		function init() {

			$enumsCache.get('TipoEnturmacaoFuncionario').then(function (data) {
				vm.TipoEnturmacaoFuncionario = data;
			});

			vm.historico = $modalInstance.params.historico;
			vm.matricula = $modalInstance.params.matricula;
			vm.isOnlyEditByHistorico = $modalInstance.params.isOnlyEditByHistorico;

			vm.desenturmar = vm.isOnlyEditByHistorico ? {
				id: _.get(vm.historico, 'id'),
				data: _.get(vm.historico, 'dataEncerramento'),
				tipoEnturmacao: _.get(vm.historico, 'tipo'),
				turmas: [{
					turma: _.get(vm.historico, 'turma'),
					componente: [_.get(vm.historico, 'itemEducacional')]
				}],
				motivo: _.get(vm.historico, 'motivoEncerramento'),
				version: _.get(vm.historico, 'version'),
				desenturmarTodas: false
			} : {
					turmas: [{}]
				};

			vm.title = !_.get(vm.desenturmar, 'id') ? 'Adicionando' : 'Editando';

			initTracker();
		}

		function initTracker() {
			vm.tracker = {
				save: promiseTracker()
			};
		}

		function save() {
			vm.tracker.save.addPromise(
				saveEditDesenturmar()
			);

			function saveEditDesenturmar() {
				if (vm.isOnlyEditByHistorico) {
					return saveEditDesenturmarHistorico();
				}

				return saveEditDesenturmarByFuncionario();
			}

			function saveEditDesenturmarHistorico() {
				var params = {
					id: _.get(vm.desenturmar, 'id'),
					dataEncerramento: _.get(vm.desenturmar, 'data'),
					motivoEncerramento: _.get(vm.desenturmar, 'motivo'),
					version: _.get(vm.desenturmar, 'version')
				};

				return EnturmacaoProfessorService.setEnturmacao(params,'ALTERACAO_DESENTURMACAO_FUNCIONARIO').then(function (data) {
					vm.desenturmar = data;
					$modalInstance.close(vm.desenturmar);
				});
			}

			function saveEditDesenturmarByFuncionario() {
				var params = {
					data: _.get(vm.desenturmar, 'data'),
					motivo: _.get(vm.desenturmar, 'motivo'),
					tipo: _.get(vm.desenturmar, 'tipoEnturmacao'),
					desenturmarTodas: _.get(vm.desenturmar, 'desenturmarTodas')
				};

				if (!isEnturmacaoPorTurma()) {
					var turmas = _.get(vm.desenturmar, 'turmas');

					var componentes = [];
					_.forEach(turmas, function (data) {
						componentes = _.union(componentes, _.get(data, 'componente'));
					});
					params.enturmacoes = _.pluck(componentes, 'id');
				} else {
					params.turmas = _.pluck(_.get(vm.desenturmar, 'turmas'), 'turma.id');
				}

				return EnturmacaoProfessorService.desenturmarByFuncionarios(vm.matricula, params).then(function (data) {
					if(!data.length){
						$commons.$notification.publish('Não existe(m) turma(s) que o funcionário possa ser desenturmado', 'warn');
					}
					else {
						$commons.$notification.publish('Professor desenturmado com sucesso!', 'success');
					}
					vm.desenturmar = data;
					$modalInstance.close(vm.desenturmar);
				});
			}
		}

		function adiciona() {
			vm.desenturmar.turmas.push({});
		}

		function remove(index) {
			vm.desenturmar.turmas.splice(index, 1);
			if (!_.get(vm.desenturmar, 'turmas.length')) {
				vm.desenturmar.turmas = [{}];
			}
		}

		function isDesenturmarTodasTurmas() {
			return Boolean(_.get(vm.desenturmar, 'desenturmarTodas'));
		}

		function hasDesenturmarTodasTurmas() {
			return Boolean(!_.isUndefined(_.get(vm.desenturmar, 'desenturmarTodas')) && !_.isNull(_.get(vm.desenturmar, 'desenturmarTodas')));
		}

		function hasFilterComplete() {
			return Boolean(_.get(vm.desenturmar, 'tipoEnturmacao') && _.get(vm.desenturmar, 'data'));
		}

		function hasAnyTurmaAndByFuncionario() {
			return Boolean(!vm.isOnlyEditByHistorico && _.get(_.first(_.get(vm.desenturmar, 'turmas')), 'turma.id'));
		}

		function isTipoRegente() {
			return Boolean(_.get(vm.desenturmar, 'tipoEnturmacao') === 'REGENTE');
		}

		function isEnturmacaoPorTurma() {
			if (_.get(vm.desenturmar, 'tipoEnturmacao')) {
				return vm.TipoEnturmacaoFuncionario[vm.desenturmar.tipoEnturmacao].enturmacaoPorTurma;
			}
		}

		function formatResult(data) {
			var inItem = !vm.isOnlyEditByHistorico ? 'itemEducacional.' : '';
			var descricao = _.get(data, inItem + 'descricao');
			if (_.get(data, inItem + 'tipo') === 'ATIVIDADE') {
				return descricao;
			}

			var result = [];
			var descricaoPai = _.get(data, inItem + 'descricaoPai');
			if ((descricaoPai)&& _.get(data, inItem + 'tipo')!== 'DISCIPLINA_EJA_MODULAR') {
				result.push(descricaoPai);
			}
			if (descricao) {
				result.push(descricao);
			}
			
			return result.join(' - ');
		}

		function getListaIdTurmasSelected() {
			return _.pluck(vm.desenturmar.turmas, 'turma');
		}
	}
})();

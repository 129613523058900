(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MatriculaCadAvisosCtrl', MatriculaCadAvisosCtrl);

    MatriculaCadAvisosCtrl.$inject = ['$modalInstance', '$rootScope'];

    function MatriculaCadAvisosCtrl($modalInstance, $rootScope) {

        var vm = this;

        vm.save = save;
        vm.avisos = $modalInstance.params.error.data;

        function save() {
            $rootScope.$broadcast('matricula::salvar', {
                force: true
            });
            $modalInstance.close();
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('educacao.avaliacao-externa')
        .controller('educacao.avaliacao-externa.AvaliacaoExternaController', AvaliacaoExternaController);

    AvaliacaoExternaController.$inject = [
        '$modalInstance'
    ];
    function AvaliacaoExternaController($modalInstance) {
        
        var vm = this;
        
        vm.save = save;
        vm.remove = remove;
        vm.cancel = cancel;

        vm.editando = Boolean($modalInstance.params.avaliacao);
        vm.windowTitle = (!vm.editando) ? 'Adicionando avaliação externa' : 'Editando avaliação externa';        
        vm.avaliacao = (vm.editando)? angular.copy($modalInstance.params.avaliacao) : {};
        
        function save() {
            vm.avaliacao.ano =  Number(vm.avaliacao.ano);
            $modalInstance.close({avaliacao:vm.avaliacao});
        }

        function remove() {
            $modalInstance.close({avaliacao:vm.avaliacao, remove: true});
        }
        
        function cancel() {
            $modalInstance.close();
        }

    }

})();
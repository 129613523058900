(function () {
    'use strict';
    angular
        .module('educacao.lista-espera')
        .controller('educacao.lista-espera.RemoverInscricaoController', Controller);

    Controller.$inject = [
        'promiseTracker',
        'educacao.lista-espera.InscricaoListaEsperaService',
        '$modalInstance',
        '$q'
    ];
    function Controller(promiseTracker, InscricaoListaEsperaService, $modalInstance, $q) {
        var vm = this;

        vm.loadingTracker = promiseTracker();


        vm.searchTurnos = searchTurnos;
        vm.formatTurno = formatTurno;
        vm.removerInscricao = removerInscricao;
        vm.estabelecimento = $modalInstance.params.estabelecimentoInscricao;
        vm.modalidade = $modalInstance.params.modalidade;
        vm.turno = angular.copy(vm.estabelecimento.turno);

        vm.promiseTurnos = InscricaoListaEsperaService.getTurno();

        function searchTurnos() {
            return $q.when(getTurnos());
        }

        function getTurnos() {
            return { content: vm.estabelecimento.turno };
        }

        function formatTurno(data) {
            return data.turnos;
        }

        function removerInscricao(turno) {
            var excluirModalidade = turno.length === vm.estabelecimento.turno.length;
            if (excluirModalidade) {
                vm.loadingTracker.addPromise(
                    InscricaoListaEsperaService
                        .removerCandidatoModalidade(vm.modalidade.id)
                        .then($modalInstance.close));
            } else {
                vm.loadingTracker.addPromise(
                    InscricaoListaEsperaService
                        .removerModalidade(turno)
                        .then($modalInstance.close));
            }
        }
    }
})();
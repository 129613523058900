(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.RemoveMatriculaCascadeService', Service);

    Service.$inject = ['Restangular'];

    function Service(Restangular) {
        var endpoint = Restangular.all('matricula/matriculas/vinculos');

        return {
            remove: remove
        };
        
        function remove(id) {
            return endpoint.customDELETE(id);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.AlunosController', AlunosCtrl);

    AlunosCtrl.$inject = [
        '$state',
        '$scope',
        'promiseTracker',
        'ui.components.Modal',
        'educacao.pessoas.AlunoService',
        '$modalStates',
        'educacao.context.ContextoEntidade',
        'SHOW_BANNERS',
        'educacao.pessoas.PessoaService',
        'bfc.Notification',
        'bfc.$$PermissionsService',
        '$rootScope'
    ];

    function AlunosCtrl($state, $scope, promiseTracker, $modal, AlunoService, $modalStates, ContextoEntidade, SHOW_BANNERS,
                        PessoaService, Notification, PermissionsService, $rootScope) {
        var momentToday = moment();

        var vm = this;
        vm.filterDeficienciasRedirectQuadroInformativo = null;

        init();
        $scope.$watch('$rootScope.paramsQuadroInformativo', onInitComFiltroDeficiencias);

        function onInitComFiltroDeficiencias() {
            if (_.get($rootScope.paramsQuadroInformativo, 'filterDefiencias')) {
                vm.autoSearch = true;
                var idsSearch = _.get($rootScope.paramsQuadroInformativo, 'ids');
                vm.filterDeficienciasRedirectQuadroInformativo = 'id in (' + idsSearch + ')';
            }
        }


        vm.loadingTracker = promiseTracker();
        vm.alunoService = AlunoService;

        vm.isAniversariante = isAniversariante;
        vm.getIdade = getIdade;

        vm.openModalAluno = openModalAluno;
        vm.openModalHistoricosEscolares = openModalHistoricosEscolares;
        vm.openModalImportar = openModalImportar;
        vm.showImportacao = showImportacao;
        vm.remove = remove;
        vm.iniciarCargaTemplates = iniciarCargaTemplates;
        vm.SexoPromise = AlunoService.getSexo();
        vm.listControl = {};
        vm.filtraAniversariante = filtraAniversariante;
        vm.dia = moment().format('DD');
        vm.mes = moment().format('MM');

        vm.sourceCustomParams = {
            total: true
        };
        vm.getParametros = getParametros;
        vm.getSelectItens = getSelectItens;

        vm.entidade = ContextoEntidade.getEntidade();

        function init() {
            var notHasPermissionToList = PermissionsService.isRevokedOperation('AlunoPageMapping', 'listar');
            if (notHasPermissionToList) {
                return $state.go('app');
            }
        }

        function getSelectItens() {
            return vm.selectedItens;
        }

        function getParametros() {
            var alunos = getSelectItens();
            var parametros = [];
            if (_.get(alunos, 'length')) {
                _.forEach(alunos, function (data) {
                    parametros.push({
                        'idAluno': data.id,
                        'idTurma': _.get(data.turma, 'id'),
                        'situacao': data.situacao,
                        'idEstabelecimento': _.get(data.estabelecimento, 'id'),
                        'turno': data.turno,

                    });
                });
            }
            return {
                'idMatricula': _.pluck(parametros, 'idMatricula'),
                'idAluno': _.pluck(parametros, 'idAluno'),
                'idMatriz': _.pluck(parametros, 'idMatriz'),
                'idTurma': _.pluck(parametros, 'idTurma'),
                'situacao': _.pluck(parametros, 'situacao'),
                'idEstabelecimento': _.pluck(parametros, 'idEstabelecimento'),
                'turno': _.pluck(parametros, 'turno')
            };
        }

        vm.expressionCustomBuilders = {
            transtornosGlobais: function (filtro) {
                if (filtro.transtornosGlobais && filtro.transtornosGlobais.length > 0) {
                    var values = [];
                    angular.forEach(filtro.transtornosGlobais, function (transtornos) {
                        values.push(transtornos.id);
                    });
                    return 'transtornosGlobais in (' + '\'' + values.join(',') + '\')';
                }
            },
            dataInicial: function (filtro) {
                if (filtro.dataInicial) {
                    return 'pessoa.dataNascimento >= ' + filtro.dataInicial + '';
                }
            },
            dataFinal: function (filtro) {
                if (filtro.dataFinal) {
                    return 'pessoa.dataNascimento <= ' + filtro.dataFinal + '';
                }
            },
            dataNascimento: function (filtro) {
                if (filtro.dataNascimento) {
                    var dia = moment(filtro.dataNascimento, 'MM/DD').format('DD');
                    var mes = moment(filtro.dataNascimento, 'MM/DD').format('MM');

                    return 'mesNascimento = \'' + mes + '\' and diaNascimento = \'' + dia + '\'';
                }
            },
            onBeforeBuild: function () {
                if (vm.temAniversariante) {
                    vm.temAniversariante = false;
                    return 'mesNascimento = \'' + vm.mes + '\' and diaNascimento = \'' + vm.dia + '\'';
                }
                if (_.get($rootScope.paramsQuadroInformativo, 'filterDefiencias')) {
                    delete $rootScope.paramsQuadroInformativo;
                    return vm.filterDeficienciasRedirectQuadroInformativo;
                }
            }
        };

        function isAniversariante(aluno) {
            var dataNascimento = moment(aluno.pessoa.dataNascimento);
            return dataNascimento.date() === momentToday.date() &&
                dataNascimento.month() === momentToday.month();
        }

        function search() {
            vm.listControl.search();
        }

        function filtraAniversariante() {
            vm.temAniversariante = true;
            search();
        }

        function getIdade(aluno) {
            return momentToday.diff(aluno.pessoa.dataNascimento, 'years');
        }

        function openModalAluno(aluno) {
            $modalStates.goModalState(aluno);
        }

        function remove(aluno) {
            return AlunoService.remove(aluno).then(function () {
                notificarExclusao(aluno);
            });
        }

        function notificarExclusao(aluno) {
            Notification.publish('Aluno(a) ' + aluno.pessoa.nome + ' excluído(a) com sucesso', 'success');
        }

        function openModalHistoricosEscolares(item) {
            $modal.open({
                templateUrl: 'historico-escolar/historico-escolar.html',
                controller: 'educacao.historicoEscolar.HistoricoEscolarCtrl',
                controllerAs: 'vm',
                params: {
                    aluno: item
                },
                size: 'xxl'
            }).result.then(function () {

            });
        }

        function openModalImportar() {
            $modal.open({
                templateUrl: 'planejamento/alunos/importacao/modal-aluno-importacacao.html',
                controller: 'educacao.pessoas.AlunoImportacaoCadCtrl',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function () {

            });
        }

        function showImportacao() {
            var show = vm.entidade.id === 1 || vm.entidade.id === 5387 || vm.entidade.id === 5437;
            if ('false' === SHOW_BANNERS || !SHOW_BANNERS) {
                show = true;
            }
            return show;
        }

        function iniciarCargaTemplates() {
            PessoaService.cargaTemplate().then(function () {
                Notification.publish(
                    'Iniciado o processo de carga inicial de pessoas para cadastro único. Ao término do processamento, você será notificado.',
                    'info');
                vm.habilitaCargaTemplate = !vm.habilitaCargaTemplate;
            });
        }

        $scope.$on('configuracao::carga-template', function () {
            vm.habilitaCargaTemplate = !vm.habilitaCargaTemplate;
        });

    }
})();

(function() {

	'use strict';

	angular.module('educacao.matricula').directive('appSelectDivisao', SelectDivisaoDirective);

	SelectDivisaoDirective.$inject = [];

	function SelectDivisaoDirective() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" search="vm.search" />',
			scope: {
				ngModel: '=',
				ngRequired: '=',
				ngDisabled: '=',
				divisoes: '=?'
			},
			controller: SelectDivisaoDirectiveController,
			controllerAs: 'vm',
			compile: function($element, $attrs) {
				var multiple = angular.isDefined($attrs.multiple);
				if (multiple) {
					$element.children('ui-select').attr('multiple', true);

					if (angular.isDefined($attrs.ngRequired)) {
						$element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function($scope) {
						$scope.multiple = multiple;
					}
				};
			}
		};
	}

	SelectDivisaoDirectiveController.$inject = ['$injector', '$scope'];

	function SelectDivisaoDirectiveController($injector, $scope) {
		var vm = this;

		var $q = $injector.get('$q');

		vm.search = search;

		function search(params) {
			var term, filter;
			term = decodeURI(params.term);
			term = term.replace(/%/g, ' ').trim();

			filter = _.filter($scope.divisoes, function(item) {
				return item.descricao.toUpperCase().indexOf(term.toUpperCase()) > -1;
			});
			return $q.when({
				content: filter,
				hasNext: false
			});
		}
	}

})();

(function() {

	'use strict';

	angular.module('educacao.matriz')
		.config(stateConfig);

	stateConfig.$inject = [
		'$stateProvider',
		'STATES_ADICIONAR',
		'STATES_EDITAR'
	];

	function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
		var STATE = 'app.matriz.campoexperiencia';
		$stateProvider.state(STATE, {
			url: 'campo-experiencia',
			data: {
				id: 'CampoExperienciaPageMapping'
			},
			views: {
				'@': {
					templateUrl: 'planejamento/matriz/campo-experiencia/listagem-campo-experiencia.html',
					controller: 'educacao.matriz.CampoExperienciasCtrl',
					controllerAs: 'vm'
				}
			}
		}).state(STATE + STATES_ADICIONAR.state, {
			url: STATES_ADICIONAR.url,
			onEnter: openModal,
			data: {
				isModalState: true
			}
		}).state(STATE + STATES_EDITAR.state, {
			url: STATES_EDITAR.url,
			onEnter: openModal,
			data: {
				isModalState: true
			}
		});

		openModal.$inject = [
			'$stateParams',
			'educacao.matriz.CampoExperienciaService',
			'$modalStates'
		];

		function openModal($stateParams, CampoExperienciaService, $modalStates) {
			$modalStates.open({
				getMethod: CampoExperienciaService.get,
				templateUrl: 'planejamento/matriz/campo-experiencia/cadastro-campo-experiencia.html',
				controller: 'educacao.matriz.CampoExperienciasCadCtrl as vm',
				propertyName: 'campoExperiencia',
				stateParams: $stateParams
			});
		}
	}

})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.MunicipioService', MunicipioService);

    MunicipioService.$inject = ['$injector'];

    function MunicipioService($injector) {
        var GenericService = $injector.get('GenericService');

        var service = GenericService
            .createWithCustomMethods({
                getCompletos: completos
            })
            .module({
                uri: 'pessoa'
            })
            .module({
                uri: 'configuracoes'
            })
            .service({
                uri: 'municipio'
            });

        function completos(offset, limit, filter, sort) {
            service.validateObject();

            return service.modules().all(service._service.uri + '/completos').getList({
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            });
        }

        return service;

    }
})();

(function () {
    'use strict';

    angular
        .module('educacao.context')
        .service('educacao.context.ContextoAnoLetivo', service);

    service.$inject = [
        'CONTEXT_ANO_LETIVO',
        'CONTEXT_REGION',
        '$q',
        'Restangular',
        'UserAccessContext',
        'bfcApplicationContext',
        'bfcApplicationContextStorage'
    ];

    function service(
        CONTEXT_ANO_LETIVO,
        CONTEXT_REGION,
        $q,
        restangular,
        UserAccessContext,
        bfcApplicationContext,
        bfcApplicationContextStorage
    ) {

        var selected;

        return {
            listaAnosLetivos: listaAnosLetivos,

            getAnoLetivo: getAnoLetivo,
            getValorContextoAnoLetivo: getValorContextoAnoLetivo,
            setAnoLetivo: setAnoLetivo,
            resolveAnoLetivoFromAno: resolveAnoLetivoFromAno,
            hasAnoLetivo: hasAnoLetivo
        };

        function listaAnosLetivos() {
            if (!UserAccessContext.hasUserAccess()) {
                return $q.reject();
            }
            return restangular.all('context')
                .customGET('ano-letivo')
                .then(function (data) {
                    return data;
                });
        }

        function getAnoLetivo() {
            return selected;
        }

        function getValorContextoAnoLetivo() {
            return _.get(getAnoLetivo(), 'ano');
        }

        function setAnoLetivo(anoLetivo) {
            if (angular.equals(getAnoLetivo(), anoLetivo)) {
                return;
            }
            selected = anoLetivo;

            var bfcApplicationContextOperation = selected ? bfcApplicationContext.put : bfcApplicationContext.remove;
            bfcApplicationContextOperation(CONTEXT_ANO_LETIVO, {
                value: String(_.get(anoLetivo, 'id')),
                insulation: false
            });

            bfcApplicationContextStorage.addValue(CONTEXT_ANO_LETIVO, getValorContextoAnoLetivo(), CONTEXT_REGION);
        }

        function resolveAnoLetivoFromAno(ano) {
            if (!ano) {
                return $q.reject('ano letivo vazio');
            }

            return restangular.all('context/ano-letivo')
                .one('ano', ano)
                .get()
                .then(setAnoLetivo)
                .catch(function () {
                    setAnoLetivo(undefined);
                    return $q.reject('ano letivo não encontrado');
                });
        }

        function hasAnoLetivo() {
            return Boolean(selected);
        }
    }
})();
(function () {
    'use strict';

    angular
        .module('educacao.quadro-informativo-utils')
        .directive('appSelectSubstitutoGestaoPessoas', appSelectSubstitutoGestaoPessoas);

    function appSelectSubstitutoGestaoPessoas() {
        return {
            restrict: 'E',
            template: '<ui-select placeholder="placeholder" ngClass="ngClass" ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" ng-change="ngChange()" format-result="vm.formatResult(data)" search="vm.search"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                placeholder: '=',
                ngClass: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['educacao.funcionario.FuncionarioMatriculaService'];

    function Controller(FuncionarioMatriculaService) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = '';

            filter += '( nome like "' +  params.term + '" )';

            var objParams = {
                filter: filter,
                offset: params.offset,
                limit: params.limit
            };

            return FuncionarioMatriculaService.getFuncionariosSubstitutos(objParams).then(toPage);
        }

        function formatResult(data) {
            if (data.nomeFuncionario) {
                return data.matricula + ' - ' + data.nomeFuncionario;
            }

            return data.matricula + ' - ' + data.servidor.nome;
        }

        function toPage(list) {
            return {
                content: _.first(_.pluck(list, 'content')),
                hasMore: list.hasNext
            };
        }
    }
})();
(function () {
    'use strict';
    var EVENT_SELECT = 'estabelecimento-list.select';

    angular.module('educacao.calendario')
        .constant('ESTABELECIMENTO_LIST_SELECT', EVENT_SELECT)
        .directive('estabelecimentoList', estabelecimentoList);

    function estabelecimentoList() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/secretaria/estabelecimento-list.directive.html',

            scope: {},

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'MATRIZ_LIST_SELECTED_EVENT',

        '$scope',
        'promiseTracker',
        'educacao.calendario.CalendarioMatrizCurricularService',
        'educacao.calendario.CalendarioEstabelecimentoService'
    ];

    function Controller(MATRIZ_LIST_SELECTED_EVENT, $scope, promiseTracker, CalendarioMatrizCurricularService, CalendarioEstabelecimentoService) {
        var vm = this;

        vm.items = [];
        vm.loadMatrizes = loadMatrizes;
        vm.onItemClicked = onItemClicked;
        vm.onItemOver = onItemOver;

        vm.tracker = promiseTracker();

        $scope.$on(MATRIZ_LIST_SELECTED_EVENT, selectEstabelecimentoByMatriz);

        vm.tracker.addPromise(
            CalendarioEstabelecimentoService.listCalendariosAnoLetivo().then(onListChanged)
        );

        function onListChanged(calendarios) {
            vm.items = calendarios ? calendarios.map(createItem) : [];
            vm.items.unshift({
                description: 'Secretaria',
                matrizes: [],
                matrizesLoaded: true,
                selected: true,
                hover: false
            });
        }

        function createItem(calendarioEstabelecimento) {
            return {
                id: calendarioEstabelecimento.id,
                description: calendarioEstabelecimento.descricao,
                matrizes: [],
                matrizesLoaded: false,
                matrizesTracker: promiseTracker(),
                selected: false,
                hover: false
            };
        }

        function loadMatrizes(item) {
            if (!item.matrizesLoaded) {
                item.matrizesTracker.addPromise(
                    CalendarioMatrizCurricularService.list(item.id)
                        .then(function (data) {
                            data.forEach(function (matriz) {
                                matriz.idEstabelecimento = item.id;
                            });
                            return data;
                        })
                        .then(function (data) {
                            item.matrizes = data;
                            item.matrizesLoaded = true;
                        })
                );
            }
        }

        function onItemClicked($event, item) {
            $event.stopPropagation();

            vm.items.forEach(function (i) {
                i.selected = i === item;
            });
            $scope.$emit(EVENT_SELECT, item.id);
            $scope.$broadcast(EVENT_SELECT, item.id);
        }

        function onItemOver(item) {
            vm.items.forEach(function (i) {
                i.hover = item === i;
            });
        }

        function selectEstabelecimentoByMatriz(event, idMatriz) {
            var found = false;
            vm.items.forEach(function (estabelecimento) {
                if (found || !estabelecimento.matrizesLoaded) {
                    estabelecimento.selected = false;
                    return;
                }
                found = estabelecimento.selected = Boolean(_.find(estabelecimento.matrizes, 'id', idMatriz));
            });
        }
    }
})();

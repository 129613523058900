(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appSelectAreaFormacaoSuperior', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" search="vm.search" />',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                ngChange: '&?',
                dropdownCssClass: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.common.CursoFormacaoSuperiorService'
    ];

    function Controller($scope, CursoFormacaoSuperiorService){
        var vm = this;

        $scope.$watch('ngModel', function(newValue, oldValue){
            if(newValue === oldValue){
                return;
            }

            if(_.isFunction($scope.ngChange)){
                $scope.ngChange();
            }
        });

        vm.search = search;

        function search(params){
            var filter = '(area like "' + params.term + '")';

            return CursoFormacaoSuperiorService.getAreasFormacaoSuperior(filter).then(function(data){
                return {
                    content: data,
                    hasNext: false
                };
            });
        }
    }

})();
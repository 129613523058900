(function() {
	'use strict';

	angular.module('educacao.matricula')
		.factory('educacao.matricula.NivelEscolarService', NivelEscolarService);

	NivelEscolarService.$inject = ['$injector'];

	function NivelEscolarService($injector) {
		var GenericService = $injector.get('GenericService');


		return GenericService.ofReadOnly('matricula', 'vaga', 'nivel-escolar');
	}
})();
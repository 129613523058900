(function () {

    'use strict';

    angular.module('educacao.common')
        .filter('appGetProperty', filter);

    function filter() {

        return getProperty;

        function getProperty(obj, path) { 
            
            return _.get(obj, path);
        }
    }

})();
(function(){

    'use strict';

    angular.module('educacao.encerramento')
        .constant('STATUS_ENCERRAMENTO', {
            NAO_INICIADO: 'NAO_INICIADO',
            EM_ABERTO: 'EM_ABERTO',
            PROCESSANDO: 'PROCESSANDO',
            PROCESSANDO_REABERTURA: 'PROCESSANDO_REABERTURA',
            ENCERRADO: 'ENCERRADO'
        });
})();
(function() {
    'use strict';

    angular.module('educacao.movimentoEscolar')
        .controller('educacao.movimentoEscolar.MovimentoEscolarCtrl', Controller);

    Controller.$inject = ['$scope',
    '$filter',
    'ui.components.Modal',
    'educacao.movimentoEscolar.MovimentoEscolarService',
    'educacao.context.ContextoEntidade',
    'educacao.context.ContextoAnoLetivo',
    'educacao.context.ContextoEstabelecimento',
    'promiseTracker'
    ];

    var idsColunasMatriculasInativas = ['TRANSFERIDO', 'DEIXOU_FREQUENTAR', 'FALECIDO', 'CANCELAMENTO'];

    function Controller($scope, $filter, uiModal,
        MovimentoEscolarService, ContextoEntidade, ContextoAnoLetivo, ContextoEstabelecimento, promiseTracker) {

        var vm = this;

        vm.entidade = ContextoEntidade.getEntidade();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.hasContextoEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        var estabelecimentoContexto = ContextoEstabelecimento.getEstabelecimento();

        var maskDateFilter = $filter('bfMaskDate');

        vm.titleTotalMotivos = '';
        vm.isFalecimento = false;
        vm.isFiltroAplicado = false;
        vm.loading = false;

        vm.lineHeight = 1;
        vm.filtros = {};
        vm.filtros.estabelecimentos = vm.hasContextoEstabelecimento ? [estabelecimentoContexto] : null;

        vm.detalhesMatriculas = null;

        vm.setLineHeight = setLineHeight;
        vm.aplicarFiltro = aplicarFiltro;
        vm.openFiltros = openFiltros;
        vm.openOutrosFiltros = openOutrosFiltros;
        vm.onChangeVisibleColumns = onChangeVisibleColumns;
        vm.onClickInfo = onClickInfo;
        vm.onClickCheckColumnVisible = onClickCheckColumnVisible;
        vm.fixOnClickData = fixOnClickData;
        vm.atualizar = atualizar;
        vm.pesquisar = pesquisar;

        var anoLetivo = Number(vm.anoLetivo.ano);
        vm.yearRange = (anoLetivo) + ':' + (anoLetivo);

        vm.dateConfigInicial = {
            yearRange: vm.yearRange
        };

        vm.dateConfigFinal = {
            yearRange: vm.yearRange
        };

        vm.tracker = {
            getDadosDetalhesMatriculas: promiseTracker(),
            getDadosGraficoGeral: promiseTracker()
        };

        function setLineHeight(lineHeight) {
            vm.lineHeight = lineHeight;
        }

        function aplicarFiltro(filtros) {

            vm.loading = true;

            var promise = MovimentoEscolarService.getSituacaoMatricula().then(function(enumValues) {
                vm.dataInicialPesquisada = maskDateFilter(filtros.dataInicial);
                vm.dataFinalPesquisada = maskDateFilter(filtros.dataFinal);

                vm.isFiltroAplicado = true;

                MovimentoEscolarService.getDadosGridMatriculas(filtros)
                    .then(function(data) {
                        successData(data, enumValues);
                    }, function() {
                        vm.isFiltroAplicado = false;
                        vm.colunasGrid = [];
                        loadChartGeral(vm.colunasGrid);
                    });
            });

            vm.tracker.getDadosGraficoGeral.addPromise(promise);
        }

        function successData(data, enumValues) {

            vm.loading = false;

            vm.colunasGrid = [];

            var colunaSaldoInicial = {
                id: 'saldoInicial',
                descricao: 'Saldo Inicial (Até ' + vm.dataInicialPesquisada + ')',
                controlaVisibilidade: false,
                exibeGrafico: false,
                largura: '17%',
                exibePopover: false,
                linkPopover: '',
                isVisible: true,
                valorColuna: data.saldoAnterior || 0
            };

            vm.colunasGrid.push(colunaSaldoInicial);

            var colunaMatricula = {
                id: 'matriculas',
                descricao: 'Matrículas',
                controlaVisibilidade: true,
                value: 'matricula',
                exibeGrafico: true,
                exibePopover: true,
                linkPopover: 'movimento-escolar/popover-matriculas-novas.html',
                isVisible: true,
                valorColuna: data.matriculas || 0
            };

            vm.colunasGrid.push(colunaMatricula);

            angular.forEach(enumValues, function(item) {

                if (idsColunasMatriculasInativas.indexOf(item.id) >= 0) {
                    var coluna = {
                        id: item.id,
                        descricao: item.description,
                        value: item.value,
                        controlaVisibilidade: true,
                        exibeGrafico: true,
                        exibePopover: true,
                        linkPopover: 'movimento-escolar/popover-motivos.html',
                        isVisible: true,
                        valorColuna: 0
                    };

                    angular.forEach(data.inativas, function(value, key) {
                        if (key.toUpperCase() === coluna.value.toUpperCase()) {
                            coluna.valorColuna = value;
                        }
                    });

                    vm.colunasGrid.push(coluna);
                }
            });

            var saldoFinal = {
                id: 'saldoFinal',
                descricao: 'Saldo Final (' + vm.dataFinalPesquisada + ')',
                controlaVisibilidade: false,
                exibeGrafico: false,
                exibePopover: false,
                linkPopover: '',
                isVisible: true,
                largura: '15%',
                valorColuna: data.saldo || 0,
                hasInfo: true,
                infoMessage: 'Saldo final = Saldo inicial + Matrículas novas + Matrículas readmitidas - Transferência - Deixaram de frequentar - Falecimento - Cancelamento'
            };

            vm.colunasGrid.push(saldoFinal);

            loadChartGeral(vm.colunasGrid);
        }

        function openFiltros($event) {
            $('#btn-movimento-escolar-filtros-header-pesquisa-avancada')
                .dropdown('toggle')
                .focus();
            $('#inputFiltros input').first().focus();
            fixOnClickData();
            $event.stopPropagation();
        }

        function openOutrosFiltros() {
            vm.resultModal = uiModal.open({
                templateUrl: 'movimento-escolar/movimento-escolar-mais-filtros.html',
                controller: 'educacao.movimentoEscolar.MovimentoEscolarMaisFiltrosCtrl as vm',
                params: {
                    filtrosSelecionados: vm.filtrosModal
                },
                size: 'lg'
            }).result.then(setFiltrosModal);
        }

        function setFiltrosModal(filtros) {
            vm.filtrosModal = filtros;

            if (filtros) {
                angular.extend(vm.filtros, filtros);
            }
        }

        function onClickInfo(value) {

            var valorColuna = value.toUpperCase();

            if (valorColuna === 'MATRICULA') {
                getDadosDetalhesMatriculas(vm.filtros, '');
            } else if (valorColuna === 'TRANSFERIDO') {
                vm.isFalecimento = false;
                vm.titleTotalMotivos = 'transferida(s)';
                getDadosDetalhesMatriculas(vm.filtros, '/' + value);
            } else if (valorColuna === 'DEIXOU_FREQUENTAR') {
                vm.isFalecimento = false;
                vm.titleTotalMotivos = 'deixaram de frequentar';
                getDadosDetalhesMatriculas(vm.filtros, '/' + value);

            } else if (valorColuna === 'FALECIDO') {
                vm.isFalecimento = true;
                vm.titleTotalMotivos = 'com falecimento';
                getDadosDetalhesMatriculas(vm.filtros, '/' + value);
            } else if (valorColuna === 'CANCELAMENTO') {
                vm.isFalecimento = false;
                vm.titleTotalMotivos = 'cancelada(s)';
                getDadosDetalhesMatriculas(vm.filtros, '/' + value);
            }
        }

        function getDadosDetalhesMatriculas(filtros, tipoMatricula) {
            var promiseDetalhes = MovimentoEscolarService.getDadosDetalhesMatriculas(filtros, tipoMatricula)
                .then(function(dados) {

                    vm.detalhesMatriculas = dados;
                });

            vm.tracker.getDadosDetalhesMatriculas.addPromise(promiseDetalhes);
        }

        function onChangeVisibleColumns() {
            loadChartGeral(vm.colunasGrid);
        }

        function onClickCheckColumnVisible($event, item) {
            $event.stopPropagation();
            item.isVisible = !item.isVisible;
            vm.onChangeVisibleColumns();
        }

        function pesquisar() {
            vm.filtrosPesquisados = _.clone(vm.filtros);
            aplicarFiltro(vm.filtros);
        }

        function atualizar() {
            aplicarFiltro(vm.filtrosPesquisados);
        }

        function loadChartGeral(colunasGrid) {
            var dataChart = [];

            var maiorValor = 0;

            colunasGrid.forEach(function(item) {
                var data = {};
                if (item.isVisible && item.exibeGrafico) {
                    data.label = item.descricao;
                    data.value = item.valorColuna;

                    if (item.valorColuna > maiorValor) {
                        maiorValor = item.valorColuna;
                    }

                    dataChart.push(data);
                }
            });

            var dataSourceJson = {
                chart: {
                    caption: vm.entidade.name,
                    subCaption: 'Período: ' + vm.dataInicialPesquisada + ' a ' + vm.dataFinalPesquisada,
                    theme: 'ocean',
                    'formatNumberScale': '0',
                    'placeValuesInside': '0',
                    'rotateValues': '0',
                    'valueFontColor': '#000000',
                    'yaxismaxvalue': maiorValor <= 1 ? '1' : '',
                    'decimals': '0',
                    'forceDecimals': '0',
                    'numdivlines': maiorValor <= 1 ? '0' : '',
                },
                data: dataChart
            };

            vm.graficoGeral = new FusionCharts({
                type: 'column2d',
                renderAt: 'idGraficoGeral',
                width: '100%',
                height: '400',
                dataEmptyMessage: 'Não há dados para exibir',
                dataInvalidMessage: 'Dados inválidos',
                baseChartMessageFontSize: '12',
                dataFormat: 'json',
                dataSource: dataSourceJson
            });

            vm.graficoGeral.render();
        }

        function fixOnClickData() {
            $('.dropdown-menu a#aFiltrosDatas').on('click', function(event) {

                $(event.target).focus();

                return false;

            });
        }
    }
})();


(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .controller('educacao.ocupacao-aulas-quadro-informativo.OcupacaoAulasQuadroInformativoController', Controller);

    Controller.$inject = [
        '$state',
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosOcupacaoAulasService',
        'promiseTracker',
        '$scope',
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        '$stateParams',
        'bfc.$$PermissionsService'
    ];

    function Controller($state, OcupacaoAulasService, promiseTracker, $scope, ContextoAnoLetivo, ContextoEstabelecimento, $stateParams, PermissionsService) {
        var vm = this;

        var DEFAULT_LIMIT = 15;
        var DEFAULT_OFFSET = 0;

        vm.loadingTracker = {
            visaogeral: promiseTracker(),
            estabelecimentosSearch: promiseTracker(),
            filtroSearch: promiseTracker()
        };

        vm.selectMenu = selectMenu;
        vm.search = search;
        vm.loadEstabelecimentos = loadEstabelecimentos;
        vm.getSort = getSort;
        vm.getFilter = getFilter;

        vm.filterBy = '';
        vm.orderBy = '';

        vm.totalItens = DEFAULT_LIMIT;
        vm.hasNext = true;
        vm.isSearching = false;

        vm.estabelecimentoSelected = $stateParams.estabelecimentoSelected ? $stateParams.estabelecimentoSelected : null;
        vm.anoLetivoSelected = $stateParams.anoLetivoSelected ? $stateParams.anoLetivoSelected : ContextoAnoLetivo.getAnoLetivo();

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        
        vm.canShowDonutGraph = true;
        vm.cards = [];
        vm.saldosEstabelecimentos = [];
        vm.itens = [];

        vm.select2Option = {
            allowClear: true
        };

        vm.SCREEN_NAME = 'Ocupação das aulas';

        $scope.$watch('vm.filterBy', function () {
            atualizarDepoisDeLimparFiltros(vm.filterBy);
        });

        $scope.$watch('vm.estabelecimentos', function () {
            atualizarDepoisDeLimparFiltros(vm.estabelecimentos);
        });

        $scope.$watch('vm.orderBy', function () {
            atualizarDepoisDeLimparFiltros(vm.orderBy);
        });

        $scope.$watch('vm.estabelecimentoSelected', redirectToVisaoEstabelecimento);

        $scope.$watch('vm.anoLetivoSelected', function () {
            init();
            search();
        });

        vm.selectFilterByOption = {
            VAGA_PREENCHIDA: 'Todas preenchidas',
            VAGA_EM_ABERTO: 'Em aberto',
            TODOS: 'Todas'
        };

        init();

        vm.selectOrderByOption = {
            MAIOR_QNTD_VAGAS: 'Maior quantidade de vagas em aberto',
            MENOR_QNTD_VAGAS: 'Menor quantidade de vagas em aberto',
            ORDEM_ALFABETICA: 'Ordem alfabética'
        };
        
        function init(){
            vm.ano = _.isEmpty(vm.anoLetivoSelected) ? vm.anoLetivo.ano : vm.anoLetivoSelected.ano;
            loadSaldosTotaisEstabelecimento();
        }

        function selectMenu(option) {
            vm.menu = option;
        }

        function atualizarDepoisDeLimparFiltros(filtro) {
            if (_.isEmpty(filtro)) {
                search();
            }
        }

        function redirectToVisaoEstabelecimento() {
            if(_.isEmpty(vm.estabelecimentoSelected) || hasRevokedPermissionVisaoEstabelecimento()) {
                return;
            }

            var estabelecimento = _.clone(vm.estabelecimentoSelected);
            vm.estabelecimentoSelected = null;

            $state.go('app.planejamento.ocupacao-aulas-quadro-informativo-visao-estabelecimento', {
                estabelecimentoId: estabelecimento.id
            });     
        }

        function hasRevokedPermissionVisaoEstabelecimento() {
            return PermissionsService.isRevokedOperation('QuadroInformativoPageMapping', 'visaoEstabelecimento');
        }

        function loadSaldosTotaisEstabelecimento() {
            var params = {
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id')
            };

            var promise = OcupacaoAulasService.getSaldosTotais(params)
                .then(function (data) {
                    setCardAndGraphValues(data);
                    loadEstabelecimentos();
                });

            vm.loadingTracker.visaogeral.addPromise(promise);
        }

        function loadEstabelecimentos(limit, hasNext) {
            vm.limit = limit;
            vm.isSearching = true;

            if (hasNext) {
                var params = resolveParameters(getFilter(), getSort(), limit);

                var promise = OcupacaoAulasService.listSaldosVisaoGeral(params)
                    .then(function (data) {
                        vm.hasNext = data.hasNext;
                        vm.saldosEstabelecimentos = data.content;
                    }).finally(function () {
                        vm.isSearching = false;
                    });

                vm.loadingTracker.estabelecimentosSearch.addPromise(promise);
            }
        }

        function search() {
            var params = resolveParameters(getFilter(), getSort(), DEFAULT_LIMIT, DEFAULT_OFFSET);
            var promise = OcupacaoAulasService.listSaldosVisaoGeral(params)
                .then(function (data) {
                    vm.hasNext = data.hasNext;
                    vm.saldosEstabelecimentos = data.content;
                }).finally(function () {
                    vm.isSearching = false;
                });

            vm.loadingTracker.filtroSearch.addPromise(promise);
        }


        function getFilter() {
            var filter = [];

            if(!vm.estabelecimento.isSecretaria) {
                filter.push('estabelecimentoId = ' + vm.estabelecimento.id);
            }

            if (!_.isEmpty(vm.estabelecimentos) && vm.estabelecimento.isSecretaria) {
                var estabelecimentosIds = _.map(vm.estabelecimentos, 'id');
                filter.push('estabelecimentoId in (' + estabelecimentosIds.join(',') + ')');
            }

            if (!_.isEmpty(vm.filterBy) && vm.filterBy !== vm.selectFilterByOption.TODOS) {
                filter.push(getFilterBy());
            }

            return filter;
        }

        function getSort() {
            var sort = '';
            if (!_.isEmpty(vm.orderBy)) {
                sort = getSortBy();
            }
            return sort;
        }

        function resolveParameters(filter, sort, limit) {
            return {
                filter: filter.join(' and '),
                limit: limit,
                sort: sort,
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id')
            };
        }


        function setCardAndGraphValues(data) {
            vm.cards = [
                {titulo: 'Saldo inicial', saldo: data.saldoInicial},
                {titulo: 'Saldo ocupado', saldo: data.saldoOcupado},
                {titulo: 'Saldo atual', saldo: data.saldoFinal}
            ];
            
            vm.calcPercentGraph = _.round(data.saldoOcupado * 100 / data.saldoInicial, 2);
            vm.radiusGraph = 65;
        }

        function getFilterBy() {
            if (vm.filterBy === vm.selectFilterByOption.VAGA_PREENCHIDA) {
                return 'saldoFinal = 0 and saldoInicial > 0';
            }

            return 'saldoFinal > 0';
        }

        function getSortBy() {
            if (vm.orderBy === vm.selectOrderByOption.ORDEM_ALFABETICA) {
                return 'nomeEstabelecimento asc';
            }

            if (vm.orderBy === vm.selectOrderByOption.MAIOR_QNTD_VAGAS) {
                return 'saldoFinal desc';
            }

            return 'saldoFinal asc';
        }
    }

})();
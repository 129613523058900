(function () {

    'use strict';

    var PROGRESSAO_PARCIAL = 'PROGRESSAO_PARCIAL';
    var INSCRITO = 'INSCRITO';
    var MATRICULADO = 'MATRICULADO';
    var CANCELADO = 'CANCELADO';

    //necessário para criar a matrícula a partir da inscrição
    var INSCRICAO_TO_MATRICULA = {
        BASICA: 'EDUCACAO_BASICA',
        COMPLEMENTAR: 'COMPLEMENTAR',
        EJA: 'EJA',
        ACELERACAO: 'ACELERACAO',
        ATIVIDADES_COMPLEMENTARES: 'ATIVIDADE_COMPLEMENTAR',
        ATIVDADES_AEE: 'AEE'
    };

    angular.module('educacao.lista-espera')
        .controller('educacao.lista-espera.ListaEsperaController', ListaEsperaController);

    ListaEsperaController.$inject = [
        '$q',
        'promiseTracker',
        'bfc.dialog.Dialog',
        'ui.components.Modal',
        'educacao.context.ContextoEstabelecimento',
        '$searchExpressionBuilder',
        'educacao.lista-espera.ConfiguracaoListaEsperaService',
        'educacao.lista-espera.ListaEsperaService',
        'educacao.lista-espera.InscricaoListaEsperaService',
        'educacao.pessoas.AlunoService'
    ];

    function ListaEsperaController(
        $q,
        promiseTracker,
        bfcDialog,
        $modal,
        ContextoEstabelecimento,
        $searchExpressionBuilder,
        ConfiguracaoListaEsperaService,
        ListaEsperaService,
        InscricaoListaEsperaService,
        AlunoService) {
        var vm = this;

        vm.desfazerCancelamento = desfazerCancelamento;
        vm.removerCandidato = removerCandidato;
        vm.removerModalidade = removerModalidade;
        vm.filterModalidades = filterModalidades;
        vm.listInscritos = listInscritos;
        vm.openModalConfiguracao = openModalConfiguracao;
        vm.openModalCancelamento = openModalCancelamento;
        vm.openModalInscricao = openModalInscricao;
        vm.openModalMatricular = openModalMatricular;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.openConfirmaRemocaoConfiguracao = openConfirmaRemocaoConfiguracao;

        vm.tracker = {
            page: promiseTracker(),
            list: promiseTracker()
        };

        init();
        initFilterOptions();
        createExpressionCustomBuilders();

        function init() {
            vm.tracker.page.addPromise(
                ConfiguracaoListaEsperaService.get().then(setConfiguracao)
            );

            initPaginationProperties();
        }

        function initPaginationProperties() {
            vm.currentPage = 1;
            vm.itemsPerPage = 20;
        }

        function initFilterOptions() {
            vm.headerFilterOptions = [
                {
                    description: 'Inscritos',
                    onClick: onClickFilter.bind(null, INSCRITO)
                },
                {
                    description: 'Matriculados',
                    onClick: onClickFilter.bind(null, MATRICULADO)
                },
                {
                    description: 'Cancelados',
                    onClick: onClickFilter.bind(null, CANCELADO)
                }
            ];

            vm.searchColumns = [{
                id: 'nomeInscrito',
                label: 'Candidato',
                model: 'nome',
                filterable: true
            }];
        }

        function createExpressionCustomBuilders() {
            vm.expressionCustomBuilders = {
                dataNascimentoInicial: function (filter) {
                    if (filter.dataNascimentoInicial) {
                        return 'dataNascimento >= ' + filter.dataNascimentoInicial;
                    }
                },
                dataNascimentoFinal: function (filter) {
                    if (filter.dataNascimentoFinal) {
                        return 'dataNascimento <= ' + filter.dataNascimentoFinal;
                    }
                },
                dataInscricaoInicial: function (filter) {
                    if (filter.dataInscricaoInicial) {
                        return 'dataInscricao >= ' + filter.dataInscricaoInicial + 'T00:00:00';
                    }
                },
                dataInscricaoFinal: function (filter) {
                    if (filter.dataInscricaoFinal) {
                        return 'dataInscricao <= ' + filter.dataInscricaoFinal + 'T23:59:59';
                    }
                }
            };
        }

        function listInscritos(ordenacao) {
            if(!_.isString(ordenacao)){
                ordenacao = undefined;
            }
            if (!vm.tipoItem) {
                return;
            }

            var filter = getStatusFilter();
            var parsedFilter = $searchExpressionBuilder.build(vm.searchFilter, vm.expressionCustomBuilders) || '';

            if (parsedFilter) {
                filter += ' and (' + parsedFilter + ')';
            }

            vm.listFilter = parsedFilter;

            var params = {
                filter: filter,
                offset: vm.currentPage - 1,
                limit: vm.itemsPerPage,
                sort: ordenacao
            };

            params.offset = params.offset > 0 ? params.offset * params.limit : 0;

            var promise = ListaEsperaService.list(params)
                .then(onLoadItens);

            vm.tracker.list.addPromise(promise);
        }

        function onLoadItens(itens) {
            vm.itens = itens.content;
            vm.currentPage = (itens.offset / itens.limit) + 1;
            vm.totalItems = itens.total;
            vm.hasNext = itens.hasNext;
            vm.totalContent = itens.content.length;
            vm.quantidadeInscritos = itens.total;
        }

        function getStatusFilter() {
            return '(statusEstabelecimento = \'' + vm.tipoItem + '\')';
        }

        function setConfiguracao(configuracao) {
            vm.configuracao = configuracao;
        }

        function openModalConfiguracao() {
            $modal.open({
                templateUrl: 'lista-espera/configuracao-lista-espera/configuracao.lista.espera.html',
                controller: 'educacao.common.ConfiguracoesListaEsperaCtrl',
                controllerAs: 'vm',
                size: 'xl'
            })
                .result.then(onCloseModalConfiguracao);
        }

        function onCloseModalConfiguracao(configuracao) {
            if (_.get(configuracao, 'reload')) {
                init();
                listInscritos();
            }
        }

        function openConfirmaRemocaoConfiguracao() {
            $modal.open({
                templateUrl: 'lista-espera/configuracao-lista-espera-confirmacao-excluir.html',
                controller: 'educacao.lista-espera.ConfirmacaoRemocaoConfiguracaoListaEspera',
                controllerAs: 'vm'
            })
                .result.then(onCloseModalConfiguracao);
        }

        function openModalInscricao(id) {
            //Mantido o serviço de modal antigo, pois neste momento não temos
            // tempo disponível para refatorar as modais
            bfcDialog.open({
                templateUrl: 'lista-espera/inscricao/inscricao-lista-espera.html',
                controller: 'educacao.lista-espera.InscricaoListaEsperaController',
                controllerAs: 'vm',
                resolve: {
                    id: id,
                    onClose: $q.when(listInscritos)
                }
            });
        }

        //função herdada do serviço antigo
        function openModalMatricular(itemInscricao, modalidade, estabelecimentoInscricao) {
            var matricula = {
                tipoMatricula: INSCRICAO_TO_MATRICULA[modalidade.modalidade],
                estabelecimento: estabelecimentoInscricao.estabelecimento,
                idInscrito: itemInscricao.listaEspera.id,
                idModalidadeListaEspera: modalidade.id,
                origemInscrito: 'listaEspera'
            };

            var filterAluno = '(nome like "' + itemInscricao.dados.nome +
                '" and sexo = "' + itemInscricao.dados.sexo +
                '" and dataNascimento = "' + itemInscricao.dados.dataNascimento + '")';

            $modal.open({
                templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
                controller: 'educacao.matricula.MatriculaCadCtrl',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    $$origemInscrito: 'listaEspera',
                    matricula: {
                        $$matriculaOrigemInscricao: matricula,
                        $$bloqueioAlteracoes: true
                    },
                    $$alunos: AlunoService.list(filterAluno, 0, 999).then(
                        function (data) {
                            return data.content;
                        }
                    ),
                    $$nivelEscolar: modalidade.nivelEscolar,
                    $$turnos: _.pluck(estabelecimentoInscricao.turno, 'turnos'),
                    alunoInscricao: {
                        pessoa: {
                            nome: itemInscricao.dados.nome,
                            sexo: itemInscricao.dados.sexo,
                            dataNascimento: itemInscricao.dados.dataNascimento
                        }
                    },
                }
            }).result.then(listInscritos);
        }

        function openModalCancelamento(idInscricao, idModalidade, idEstabelecimento, turnos) {
            bfcDialog.open({
                templateUrl: 'lista-espera/cancelar-inscricao/cancelar-inscricao.html',
                controller: 'educacao.lista-espera.CancelaInscricaoController',
                controllerAs: 'vm',

                resolve: {
                    idInscricao: idInscricao,
                    idModalidade: idModalidade,
                    idEstabelecimento: idEstabelecimento,
                    turnosDisponiveis: $q.when(turnos || []),

                    onClose: $q.when(listInscritos)
                }
            });
        }

        function desfazerCancelamento(idInscricao, nivel, estabelecimentoInscricao, turnos) {

            var promise = InscricaoListaEsperaService
                .desfazerCancelamento(idInscricao, nivel, estabelecimentoInscricao, turnos)
                .then(listInscritos);

            vm.tracker.list.addPromise(promise);
        }

        function removerCandidato(idInscricao) {
            InscricaoListaEsperaService.removerCandidato(idInscricao)
                .then(listInscritos);
        }

        function removerModalidade(idInscricao) {
            InscricaoListaEsperaService.removerCandidatoModalidade(idInscricao)
                .then(listInscritos);
        }

        function onClickFilter(tipoItem) {
            vm.tipoItem = tipoItem;
            listInscritos();
        }

        function filterModalidades(term, value) {
            //Comportamento herdado da listagem antiga que não possuia progressão para selecionar.
            if (value.key !== PROGRESSAO_PARCIAL) {
                return value;
            }
        }
    }

})();

(function() {
    'use strict';

    angular.module('agenda')
        .directive('agenda', AgendaViewDirective);

    AgendaViewDirective.$inject = ['$timeout'];

    function AgendaViewDirective($timeout) {
        return {
            restrict: 'E',
            scope: {
                initialDate: '=',
                finalDate: '=',
                view: '=',

                config: '='
            },

            templateUrl: 'gitlab_extract/fullcalendar-agenda/agenda.directive.html',
            transclude: true,
            link: postLink,

            controller: AgendaControler,
            controllerAs: 'vm'
        };

        function postLink($scope, rootElement, attrs, vm) {
            vm.element = $('<div></div>');

            rootElement.append(vm.element);

            vm.element.fullCalendar(angular.extend({
                    header: false,
                    defaultView: 'agendaWeek',
                    editable: true,

                    views: {
                        agendaWeek: {
                            titleFormat: 'DD MMM YYYY',
                            titleRangeSeparator: ' até ',

                            columnFormat: 'ddd, DD',
                            slotLabelFormat: 'HH:mm'
                        },
                        month: {
                            titleFormat: 'MMMM [de] YYYY'
                        }
                    },

                    eventRender: vm.eventRender,
                    eventDrop: vm.eventDrop,
                    eventResize: vm.eventResize,

                    eventDestroy: function(event, element) {
                        var scope = element.data('$scope');
                        if (scope) {
                            element.trigger('$destroy');
                            scope.$destroy();
                        }
                    },

                    dayClick: vm.dayClick
                },

                vm.config
            ));

            $timeout(function() {
                vm.element.fullCalendar('render');
            }, 0);
        }
    }

    AgendaControler.$inject = [
        'AGENDA_WEEK_VIEW',
        'AGENDA_MONTH_VIEW',
        'AGENDA_DAY_VIEW',
        'AGENDA_PREVIOUS_PAGE_EVENT',
        'AGENDA_NEXT_PAGE_EVENT',
        'AGENDA_TO_DAY_EVENT',
        'AGENDA_TITLE_CHANGED_EVENT',
        '$scope', '$timeout'
    ];

    function AgendaControler(WEEK_VIEW,
        MONTH_VIEW,
        DAY_VIEW,
        AGENDA_PREVIOUS_PAGE_EVENT,
        AGENDA_NEXT_PAGE_EVENT,
        AGENDA_TO_DAY_EVENT,
        TITLE_CHANGED_EVENT,
        $scope,
        $timeout) {

        var vm = this;

        var momentInitialDate;
        var momentFinalDate;

        vm.eventRender = eventRender;
        vm.eventDrop = eventDrop;
        vm.eventResize = eventResize;
        vm.dayClick = dayClick;

        vm.config = $scope.config || {};

        $scope.$watch('view', changeView);
        $scope.$watch('initialDate', onInitialDateChanged);
        $scope.$watch('finalDate', onFinalDateChanged);
        $scope.$watch('view', goToDate);

        $scope.$on(AGENDA_PREVIOUS_PAGE_EVENT, prev);
        $scope.$on(AGENDA_NEXT_PAGE_EVENT, next);
        $scope.$on(AGENDA_TO_DAY_EVENT, toDay);

        vm.onSourceChanged = refreshEventSource;

        function changeView(view) {
            if (!isValidView(view)) {
                return;
            }

            vm.element.fullCalendar('changeView', view);
            emitTitleChangedEvent();
        }

        function isValidView(view) {
            switch (view) {
                case DAY_VIEW:
                case WEEK_VIEW:
                case MONTH_VIEW:
                    return true;
                default:
                    return false;
            }
        }

        function onInitialDateChanged(newDate) {
            momentInitialDate = moment(newDate, 'YYYY-MM-DD');
            goToDate();
        }

        function onFinalDateChanged(newDate) {
            momentFinalDate = moment(newDate, 'YYYY-MM-DD');
            goToDate();
        }

        function goToDate() {
            var today = vm.element.fullCalendar('getDate');
            var dateToGo;

            if (today.isBefore(momentInitialDate, 'days')) {
                dateToGo = momentInitialDate;
            } else if (today.isAfter(momentFinalDate, 'days')) {
                dateToGo = momentFinalDate;
            } else {
                dateToGo = today;
            }

            vm.element.fullCalendar('gotoDate', dateToGo);
            emitTitleChangedEvent();
        }

        function getViewTimeUnit() {
            switch ($scope.view) {
                case DAY_VIEW:
                    return 'day';
                case MONTH_VIEW:
                    return 'months';
                default:
                    return 'weeks';
            }
        }

        function isDateInCalendar(date) {
            return !date.isBefore(momentInitialDate) && !date.isAfter(momentFinalDate);
        }

        function canGoToDate(amount) {
            var timeUnit = getViewTimeUnit();

            var currentDate = vm.element.fullCalendar('getDate')
                .clone()
                .startOf(timeUnit)
                .add(amount, timeUnit);

            return isDateInCalendar(currentDate) || isDateInCalendar(currentDate.endOf(timeUnit));
        }

        function prev() {
            if (canGoToDate(-1)) {
                vm.element.fullCalendar('prev');
                emitTitleChangedEvent();
            }
        }

        function next() {
            if (canGoToDate(1)) {
                vm.element.fullCalendar('next');
                emitTitleChangedEvent();
            }
        }

        function toDay() {
            vm.element.fullCalendar('today');
            emitTitleChangedEvent();
        }

        function emitTitleChangedEvent() {
            $scope.$emit(TITLE_CHANGED_EVENT, vm.element.fullCalendar('getView').title);
        }

        function refreshEventSource(events, old) {
            _.forEach(old, function(event) {
                vm.element.fullCalendar('removeEvents', event.id);
            });

            $timeout(function() {
                //feito para executar fora do digest atual
                vm.element.fullCalendar('addEventSource', events);
            }, 10);
        }

        function eventRender(event, element, view) {
            (vm.config.eventRender || angular.noop)(event, element, view);
        }

        function eventDrop(event, delta, revertFunc, jsEvent, ui, view) {
            (vm.config.eventDrop || angular.noop)(event, delta, revertFunc, jsEvent, ui, view);
        }

        function eventResize(event, delta, revertFunc, jsEvent, ui, view) {
            (vm.config.eventResize || angular.noop)(event, delta, revertFunc, jsEvent, ui, view);
        }

        function dayClick(date, jsEvent, view) {
            (vm.config.dayClick || angular.noop)(date, jsEvent, view);
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('configuracaoInscricaoOnline', configuracaoInscricaoOnline);

    configuracaoInscricaoOnline.$inject = [];

    function configuracaoInscricaoOnline() {

        var directiveConfig = {};

        directiveConfig.restrict = 'E';

        directiveConfig.templateUrl = 'matricula/configuracao-inscricao/online/configuracao-inscricao-online.directive.html';

        directiveConfig.controller = ConfiguracaoInscricaoOnlineCtrl;
        directiveConfig.controllerAs = 'vm';

        directiveConfig.scope = {};
        directiveConfig.scope.configuracao = '=';
        directiveConfig.scope.validateTab = '=';
        directiveConfig.scope.tabSelected = '=';

        return directiveConfig;
    }

    ConfiguracaoInscricaoOnlineCtrl.$inject = ['$scope', '$window', 'bfc.dialog.Dialog', '$enumsCache'];

    function ConfiguracaoInscricaoOnlineCtrl($scope, $window, bfcDialog, $enumsCache) {
        var vm = this;
        var clipboard = new $window.Clipboard('.clipboard-button');

        vm.EDUCACAO_INFANTIL = 'EDUCACAO_INFANTIL';
        vm.CAMPO_ENDERECO = 'ENDERECO';

        vm.configuracao = $scope.configuracao;
        vm.validateTab = $scope.validateTab;
        vm.tabSelected = $scope.tabSelected;
        
        vm.configuracao.utilizaZoneamento = !!vm.configuracao.utilizaZoneamento;
        vm.url = '';
        vm.opemViewMensagem = opemViewMensagem;
        vm.showAlertLabelZoneamento = showAlertLabelZoneamento;

        init();

        $scope.$watch('configuracao.url', function (value) {
            vm.url = value || '';
        });

        $scope.$watch('configuracao.utilizaZoneamento', onChangeUtilizaZoneamento);
        $scope.$watch('vm.CampoInscricao', function() {
            setEndereco(vm.configuracao.utilizaZoneamento);
        });
        $scope.$watch('vm.configuracao.qtdEstabelecimentos', updateQtdEstabelecimentoValue);
        $scope.$watch('vm.configuracao.qtdEstabelecimentosZoneamento', updateQtdZoneamentoValue);
        $scope.$watch('vm.configuracao.qtdEstabelecimentosForaZoneamento', updateQtdForaZoneamentoValue);

        $scope.$on('$destroy', function () {
            clipboard.destroy();
        });

        function init() {
            $enumsCache.get('CampoInscricao').then(setCampoInscricao);

            function setCampoInscricao(data) {
                vm.CampoInscricao = data;
            }
        }

        function opemViewMensagem() {
            bfcDialog.open({
                templateUrl: 'common/parametros/parametrizacao.matriculas.mensagem.fim.lista.espera.html',
                controller: 'educacao.common.ControllerTemplate as vm',
                resolve: {
                    mensagem: vm.configuracao.msgFinalizacao
                }
            });
        }

        function onChangeUtilizaZoneamento(newValue) {
            if (newValue) { 
                setEndereco(newValue);
                return;
            }

            vm.configuracao.qtdEstabelecimentosZoneamento = null;
            vm.configuracao.qtdEstabelecimentosForaZoneamento = null;
        }

        function showAlertLabelZoneamento(qtdEstabZoneamento, allowEqualZero) {
            if (_.isNull(vm.configuracao.qtdEstabelecimentos) || _.isUndefined(vm.configuracao.qtdEstabelecimentos) || vm.configuracao.qtdEstabelecimentos === '') {
                return false;
            }

            if (allowEqualZero) {
                return qtdEstabZoneamento >= vm.configuracao.qtdEstabelecimentos;
            }

            return qtdEstabZoneamento > vm.configuracao.qtdEstabelecimentos;
        }

        function updateQtdEstabelecimentoValue(newValue, oldValue) {
            if (typeof newValue === 'string') {
                newValue = newValue.replace(/\D/g, '');
                vm.configuracao.qtdEstabelecimentos = parseInt(newValue);
            }

            if (!oldValue) {
                return;
            }

            if (!newValue || parseInt(newValue) < 1) {
                vm.configuracao.qtdEstabelecimentos = 1;
                return;
            }

            if (newValue > 999) {
                vm.configuracao.qtdEstabelecimentos = 999;
            }
        }

        function updateQtdZoneamentoValue(newValue, oldValue) {
            if (typeof newValue === 'string') {
                newValue = newValue.replace(/\D/g, '');
                vm.configuracao.qtdEstabelecimentosZoneamento = parseInt(newValue);
            }

            if (!oldValue || /\D/.test(oldValue) || !vm.configuracao.utilizaZoneamento) {
                return;
            }

            if (!newValue || parseInt(newValue) < 1) {
                vm.configuracao.qtdEstabelecimentosZoneamento = 1;
                return;
            }

            if (newValue > 999) {
                vm.configuracao.qtdEstabelecimentosZoneamento = 999;
            }
        }

        function updateQtdForaZoneamentoValue(newValue) {
            if (typeof newValue === 'string') {
                newValue = newValue.replace(/\D/g, '');
                vm.configuracao.qtdEstabelecimentosForaZoneamento = parseInt(newValue);
            }

            if (newValue > 999) {
                vm.configuracao.qtdEstabelecimentosForaZoneamento = 999;
            }
        }

        function setEndereco(utilizaZoneamento) {
            var campoEnderecoConfig = _.find(vm.configuracao.campos, { campo: vm.CAMPO_ENDERECO });
            if (utilizaZoneamento && vm.CampoInscricao && !campoEnderecoConfig) {
                var campoEndereco = vm.CampoInscricao[vm.CAMPO_ENDERECO];
                vm.configuracao.campos.push({
                    campo: campoEndereco.id,
                    campoMestre: campoEndereco.campoMestre,
                    $$visible: true,
                    obrigatorio: true,
                    grupo: campoEndereco.grupo
                });
            }
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MotivosDeDispensaCadCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        '$scope',
        'educacao.matricula.MotivosDeDispensaService'
    ];

    function Controller(
        promiseTracker,
        $modalInstance,
        focus,
        $scope,
        MotivosDeDispensaService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.motivoDeDispensa = {};
        vm.editando = _.get($modalInstance.params.motivo, 'id');
        vm.motivoDeDispensa = $modalInstance.params.motivo || {};
        vm.save = save;
        vm.cancel = fechaModal;
        vm.remove = remove;
        vm.windowTitle = vm.editando ? 'Editando motivo de dispensa' : 'Adicionando motivo de dispensa';
        verificaFormulario();
        focus('lbDescricaoMotivoMovimentacao');


        function save(continuar) {
            vm.tracker.loading.addPromise(
                MotivosDeDispensaService.save(vm.motivoDeDispensa).then(function(data) {
                    vm.motivoDeDispensa = data;
                    onSuccess(continuar);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                MotivosDeDispensaService.remove(vm.motivoDeDispensa).then($modalInstance.close)
            );
        }


        function onSuccess(continuar) {
            if (continuar) {
                verificaFormulario();
                vm.motivoDeDispensa = {};
                focus('lbDescricaoMotivoMovimentacao');
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.motivoDeDispensa);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();

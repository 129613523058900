(function() {
    'use strict';

    angular.module('educacao.movimentoEscolar').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.movimentoEscolar', {
            url: 'movimento-escolar',
            data: {
                id: 'EstatisticaMovimentacaoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'movimento-escolar/movimento-escolar.html'
                }
            }
        });
    }
})();


(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.CamposInscricaoService', CamposInscricaoService);

    CamposInscricaoService.$inject = ['$injector'];

    function CamposInscricaoService($injector) {
        var GenericService = $injector.get('GenericService');


        return GenericService.of('matricula', 'configuracao-inscricao', 'campos-inscricao');
    }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .service('ModalFiliacoesService', ModalFiliacoesService);

    ModalFiliacoesService.$inject = ['$q', 'ui.components.Modal'];

    function ModalFiliacoesService($q, $modal) {
        var self = this;

        self.open = open;

        function open(filiacoes) {
            var vinculo = vinculo || [];
            return $modal.open({
                templateUrl: 'planejamento/filiacoes/input-filiacoes/modal/filiacoes-modal.html',
                controller: 'educacao.pessoas.ModalFiliacoesCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    vinculo: {
                        permiteRetirarAluno: vinculo ? angular.copy(vinculo.permiteRetirarAluno) : null,
                        diasDaSemana: vinculo.diaSemana || [],
                        parentesco: vinculo.parentesco,
                        outroParentesco: vinculo.outroParentesco
                    },
                    filiacoes: filiacoes
                }
                
            });
        }
    }

})();
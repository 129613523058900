(function() {

    'use strict';

    angular.module('educacao.configuracao-indicadores')
        .service('educacao.configuracao-indicadores.ConfiguracaoIndicadoresService', ConfiguracaoEvasaoReprovacaoService);

    ConfiguracaoEvasaoReprovacaoService.$inject = [
        'Restangular'
    ];

    function ConfiguracaoEvasaoReprovacaoService(Restangular) {

        var self = this;

        self.get = get;
        self.save = save;

        function getBasePath() {
            return Restangular.all('machine-learning').all('configuracao');
        }

        function get() {
            return getBasePath().get('')
                .then(Restangular.stripRestangular);
        }

        function save(configuracao) {
            return getBasePath().customPOST(configuracao);
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .provider('FiliacoesService', FiliacoesService);

     function FiliacoesService() {
        var self = this;

        self.uri = undefined;
        self.path = path;
        self.$get = ['ServiceBuilder', getTelefoneService];

        function path(uri){
            self.uri = uri;
        }

        function getTelefoneService(ServiceBuilder){
            if(angular.isUndefined(self.uri)){
                throw new Error('configuration to FiliacoesServiceProvider is undefined!');
            }

            return ServiceBuilder.create()
                    .path(self.uri)
                    .enum('TipoTelefone', 'TipoNumero')
                    .build();
        }
    }
})();
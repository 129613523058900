(function() {
	'use strict';

	angular.module('educacao.configuracaoGrupo')
		.controller('educacao.common.GrupoController', GrupoController);

	GrupoController.$inject = [
		'$enumsCache', 
		'bfc.Notification',
		'educacao.common.GrupoEnturmacaoService',
		'educacao.context.ContextoEstabelecimento', 
		'promiseTracker',
		'ui.components.Modal',
        'educacao.common.ModalConfirm'
	];

	function GrupoController($enumsCache, bfcNotification, GrupoEnturmacaoService, ContextoEstabelecimento, promiseTracker, Modal, modalConfirm) {

		 var vm = this;

		vm.tracker = {
			list: promiseTracker(),
			remove: []
		};
		vm.search = search;
		vm.remove = remove;
		vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
		vm.GrupoEnturmacaoService = GrupoEnturmacaoService;
		vm.openCadastroGrupo = openCadastroGrupo;

		vm.popover = 'configuracao-grupos/popover-grupos.html';

		init();

		function init() {

			vm.filter = {};
			vm.columns = [{
				'id': 'descricao',
				'model': 'descricao',
				'filterable': true,
				'label': 'Estabelecimento'
			}];
			getEnums();
		}

		function getEnums() {
			$enumsCache.get('GrupoEnturmacaoCor').then(function (enumData) {
				vm.GrupoEnturmacaoCor = enumData;
				_.forEach(vm.GrupoEnturmacaoCor.values, function(color) {
					color.hex = '#' + color.hex;
				});
			});
		}

		function search() {
			vm.controls.search();
		}

		function remove(id, grupoEnturmacaoItem) {
			var promise = verificarExclusaoVinculo(grupoEnturmacaoItem).then(thenRemove);
			vm.tracker.remove['id_'+id] = promiseTracker();
			vm.tracker.remove['id_'+id].addPromise(promise);
			function thenRemove(vinculo) {
                if (_.get(vinculo, 'hasVinculoFrequenciaRemota')) {
                    return confirmExclusaoVinculos().then(function() {
                        return GrupoEnturmacaoService.removeAndRemoveVinculos(id)
							.then(search)
							.then(notify);
                    });
                }
                return GrupoEnturmacaoService.remove(id)
					.then(search)
					.then(notify);
            }
		}

		function notify() {
			bfcNotification.publish('Grupos do estabelecimento excluído com sucesso', 'success');
		}

		function openCadastroGrupo(id){
			var grupoEnturmacao = null;
			if (!ContextoEstabelecimento.isSecretaria()) {
				grupoEnturmacao = GrupoEnturmacaoService.getGruposEstabelecimento(ContextoEstabelecimento.getEstabelecimento().id);
			}
			Modal.open({
				templateUrl: 'configuracao-grupos/cadastro-grupo/cadastro-grupo.html',
				controller: 'educacao.configuracaoGrupo.GrupoCadastroController',
				controllerAs: 'vm',
                size: 'md',
                params: {
                    grupoEnturmacao: id ? GrupoEnturmacaoService.get(id) : grupoEnturmacao
                }
			}).result.then(search);
		}

		function verificarExclusaoVinculo(grupoEnturmacaoItem) {
            var grupoRemovidosIds = _.pluck(grupoEnturmacaoItem, 'id');
            return GrupoEnturmacaoService.verificarVinculo(grupoRemovidosIds);
        }

		function confirmExclusaoVinculos() {
            var confirmOptions = {
                title: 'Confirmar exclusão',
                message: 'A exclusão do(s) grupo(s) irá excluir também os registros de frequências remotas vinculados. Deseja continuar?'
            };
            return modalConfirm(confirmOptions);
        }
	}
})();

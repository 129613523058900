(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectMatrizEja', SelectMatrizEja);

    function SelectMatrizEja() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchMatriz" ng-disabled="ngDisabled" ng-change="ngChange()"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                estabelecimento: '=?',
                nivelEscolar: '=?',
                ngChange:'&?'
            },
            controller: SelectMatrizEjaController,
            controllerAs: 'vm'
        };
    }

    SelectMatrizEjaController.$inject = ['$scope', 'educacao.matricula.MatriculaService'];

    function SelectMatrizEjaController($scope, service) {

        var vm = this;

        vm.searchMatriz = searchMatriz;

        function searchMatriz(params) {
            var filter = '(descricao like "' + params.term + '")'; 
            if($scope.nivelEscolar) {
                filter += ' and (nivelEscolar ="' + $scope.nivelEscolar + '")';
            }

            var idEstabelecimento = _.get($scope.estabelecimento, 'id') || -1;
            return service
                .getMatrizEja(idEstabelecimento, params, filter)
                .then(_.first);
        }
    }
})();

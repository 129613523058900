(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo').config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider'
    ];

    function stateConfig($stateProvider) {
        var STATE = 'app.planejamento.gestao-pessoas-quadro-informativo';

        $stateProvider.state(STATE, {
            url: 'quadro-informativo-gestao-pessoas',
            // data: {
            //     id: 'QuadroInformativoPageMapping',
            //     mirrorOf: 'app.planejamento.visao-geral-quadro-informativo'
            // },
            params: {
                estabelecimentoSelected: '',
                anoLetivoSelected:''
            },
            views: {
                '@': {
                    templateUrl: 'quadro-informativo/gestao-pessoas/gestao-pessoas.html',
                    controller: 'educacao.gestao-pessoas-quadro-informativo.GestaoPessoasQuadroInformativoController as vm',
                }
            }
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('educacao.context')
        .service('educacao.context.ContextoEntidade', service);

    service.$inject = [
        '$rootScope',
        'UserAccountsService',
        'educacao.context.UserAccessService'
    ];

    function service($rootScope, UserAccountsService, UserAccessService) {
        var promiseListaEntidades;
        var selected;
        return {
            listaEntidades: listaEntidades,

            getEntidade: getEntidade,
            getValorContextoEntidade: getValorContextoEntidade,
            setEntidade: setEntidade,
            resolveEntidadeFromAcesso: resolveEntidadeFromAcesso,
            hasEntidade: hasEntidade
        };

        function listaEntidades() {
            if (promiseListaEntidades) {
                return promiseListaEntidades;
            }

            return UserAccessService.getUserAccess()
                .then(function(data) {
                    return _.unique(
                        _.map(data, _.property('values.entity'))
                    );
                })
                .then(function(ids) {
                    if (!promiseListaEntidades) {
                        promiseListaEntidades = UserAccountsService.entitiesInfo(ids);
                    }
                    return promiseListaEntidades;
                });
        }

        function getEntidade() {
            return selected;
        }

        function getValorContextoEntidade() {
            return _.get(selected, 'id');
        }

        function setEntidade(entidade) {
            if (angular.equals(getEntidade(), entidade)) {
                return;
            }
            var broadcastNew = Boolean(selected);
            selected = entidade;
            if (broadcastNew) {
                $rootScope.$broadcast('app-context:entidade:new', entidade);
            }
        }

        function resolveEntidadeFromAcesso(acesso) {
            return UserAccessService.getUserAccess().then(function(listaAcesso) {
                var found = _.find(listaAcesso, 'context', acesso);
                var id = _.get(found, 'values.entity');
                if (!id) {
                    setEntidade(undefined);
                } else {
                    return listaEntidades().then(function(lista) {
                        setEntidade(_.find(lista, 'id', +id));
                    });
                }
            });
        }

        function hasEntidade() {
            return Boolean(selected);
        }
    }

})();

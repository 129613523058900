(function () {
    'use strict';
    
    angular.module('educacao.matricula')
        .controller('educacao.configuracaoGrupo.GrupoCadastroController', GrupoCadastroController); 
        
    GrupoCadastroController.$inject =  [
        '$q',
        'promiseTracker',
        '$modalInstance',
        '$enumsCache',
        'educacao.context.ContextoEstabelecimento',
        'educacao.common.GrupoEnturmacaoService',
        'bfc.Notification',
        'educacao.common.ModalConfirm'
    ];

    function GrupoCadastroController($q, promiseTracker, $modalInstance, $enumsCache, ContextoEstabelecimento, GrupoEnturmacaoService, bfcNotification, modalConfirm) {
        var vm = this;
        vm.tracker = {
            save: promiseTracker(),
            excluir: promiseTracker()
        };
        vm.estabelecimentos = [];
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.save = save;
        vm.remove = remove;
        vm.addGrupo = addGrupo;
        vm.removeGrupo = removeGrupo;

        vm.grupoEnturmacao = $modalInstance.params.grupoEnturmacao || { grupoEnturmacaoItem: [{}] };
        vm.editing = Boolean(vm.grupoEnturmacao.id);

        init();
        function init(){
            ordernaGrupos();
            if (vm.editing) {
                vm.oldGrupos = _.cloneDeep(vm.grupoEnturmacao.grupoEnturmacaoItem);
            }
        }

        function save(continuar) {
            var promise;
            if (vm.isSecretaria && !vm.editing) {
                var grupoEnturmacaoList = [];
                _.forEach(vm.estabelecimentos, function(estabelecimento) {
                    var grupoEnturmacao = {
                        estabelecimento: estabelecimento,
                        grupoEnturmacaoItem: vm.grupoEnturmacao.grupoEnturmacaoItem
                    };
                    grupoEnturmacaoList.push(grupoEnturmacao);
                });
                promise = GrupoEnturmacaoService.saveList(grupoEnturmacaoList);
            } else {
                promise = verificarExclusaoVinculo().then(thenSave);
            }
            vm.tracker.save.addPromise(promise);
            promise.then(function() {
                bfcNotification.publish('Grupos salvos com sucesso', 'success');
                if (continuar) {
                    vm.grupoEnturmacao = { grupoEnturmacaoItem: [{}] };
                    vm.form.$setUntouched();
                } else {
                    fecharModal();
                }
            });
        }

        function thenSave(vinculo) {
            if (_.get(vinculo, 'hasVinculoFrequenciaRemota')) {
                return confirmExclusaoVinculos().then(function() {
                    return GrupoEnturmacaoService.saveAndRemoveVinculos(vm.grupoEnturmacao);
                });
            }
            return GrupoEnturmacaoService.save(vm.grupoEnturmacao);
        }

        function fecharModal() {
            $modalInstance.close();
        }

        function remove() {
            var promise = verificarExclusaoVinculo(true).then(thenRemove);
            
            function thenRemove(vinculo) {
                if (_.get(vinculo, 'hasVinculoFrequenciaRemota')) {
                    return confirmExclusaoVinculos().then(function() {
                        return GrupoEnturmacaoService.removeAndRemoveVinculos(vm.grupoEnturmacao.id).then(fecharModal);
                    });
                }
                return GrupoEnturmacaoService.remove(vm.grupoEnturmacao.id).then(fecharModal);
            }
            vm.tracker.excluir.addPromise(promise);
        }

        function ordernaGrupos() {
            if (vm.editing) {
                vm.grupoEnturmacao.grupoEnturmacaoItem = _.sortBy(
                    vm.grupoEnturmacao.grupoEnturmacaoItem,function(grupo) {
                        return grupo.descricao.toLowerCase();
                    }
                );
            }
        }

        $enumsCache.get('GrupoEnturmacaoCor').then(function (enumData) {
            vm.colors = enumData;
            _.forEach(vm.colors.values, function(color) {
                color.hex = '#' + color.hex;
            });
        });
        
        function removeGrupo(grupo) {
            var index = vm.grupoEnturmacao.grupoEnturmacaoItem.indexOf(grupo);
            vm.grupoEnturmacao.grupoEnturmacaoItem.splice(index,1);
            if (!_.get(vm.grupoEnturmacao, 'grupoEnturmacaoItem.length')) {
                vm.grupoEnturmacao.grupoEnturmacaoItem = [{}];
            }
        }

        function addGrupo(){
            vm.grupoEnturmacao.grupoEnturmacaoItem.push({
                descricao: null,
                cor: null
            });
        }

        function verificarExclusaoVinculo(isRemovingAll) {
            if (!vm.editing) {
                return $q.all();
            }
            var grupoRemovidosIds = [];
            if (isRemovingAll) {
                grupoRemovidosIds = _.pluck(vm.oldGrupos, 'id');
            } else {
                var gruposRemovidos = _.filter(vm.oldGrupos, notInNewList);
                if (_.isEmpty(gruposRemovidos)) {
                    return $q.all();
                }
                grupoRemovidosIds = _.pluck(gruposRemovidos, 'id');
            }
            return GrupoEnturmacaoService.verificarVinculo(grupoRemovidosIds);
        }

        function notInNewList(oldGrupoItem) {
            return !_.find(vm.grupoEnturmacao.grupoEnturmacaoItem, { id: oldGrupoItem.id });
        }

        function confirmExclusaoVinculos() {
            var confirmOptions = {
                title: 'Confirmar exclusão',
                message: 'A exclusão do(s) grupo(s) irá excluir também os registros de frequências remotas vinculados. Deseja continuar?'
            };
            return modalConfirm(confirmOptions);
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .service('educacao.matricula.MatriculaAtividadeService', service);

    service.$inject = ['ServiceBuilder'];
    function service(ServiceBuilder) {
        var created = ServiceBuilder
            .create()
            .path('matricula', 'matriculas', 'atividades')
            .build();

        return created;
    }
})();
(function () {
    'use strict';

    angular.module('educacao.frequencia')
        .factory('educacao.frequencia.FrequenciaService', FrequenciaService);

    FrequenciaService.$inject = [
        '$q',
        'EventEmitter',
        'Restangular',
        'MetadataCache'
    ];

    function FrequenciaService(
        $q,
        EventEmitter,
        Restangular
    ) {

        var self = new EventEmitter();
        self.getConfiguracaoFrequencia = getConfiguracaoFrequencia;
        self.getPeriodosTurma = getPeriodosTurma;
        self.getItensEducacionais = getItensEducacionais;
        self.getDadosFrequencia = getDadosFrequencia;
        self.atualizaFrequencia = atualizaFrequencia;
        self.atualizaFrequenciaEncerramento = atualizaFrequenciaEncerramento;
        self.atualizaFrequenciaEja = atualizaFrequenciaEja;
        self.atualizaFrequenciaEjaPeriodoEncerrado = atualizaFrequenciaEjaPeriodoEncerrado;
        self.saveFrequencia = saveFrequencia;
        self.saveFrequenciaEncerramento = saveFrequenciaEncerramento;
        self.removeFrequencia = removeFrequencia;
        self.removeFrequenciaPeriodoEncerrado = removeFrequenciaPeriodoEncerrado;
        self.removeFrequenciaEja = removeFrequenciaEja;
        self.getModulosTurma = getModulosTurma;
        self.remove = remove;
        self.getFrequenciaModular = getFrequenciaModular;
        self.saveFrequenciaEja = saveFrequenciaEja;
        self.saveFrequenciaEjaPeriodoEncerrado = saveFrequenciaEjaPeriodoEncerrado;
        self.saveFrequenciaEjaTotal = saveFrequenciaEjaTotal;
        self.saveFrequenciaEjaTotalPeriodoEncerrado = saveFrequenciaEjaTotalPeriodoEncerrado;
        self.atualizaFrequenciaEjaTotal = atualizaFrequenciaEjaTotal;
        self.atualizaFrequenciaEjaTotalPeriodoEncerrado = atualizaFrequenciaEjaTotalPeriodoEncerrado;
        self.removeFrequenciaEjaTotal = removeFrequenciaEjaTotal;
        self.getPublicados = getPublicados;
        return self;

        

        function getConfiguracaoFrequencia(idTurma){
            return Restangular.all('commons/frequencia-aula').one('configuracao-frequencia', idTurma).get();
        }

        function getPeriodosTurma(idTurma){
            return Restangular.all('commons').customGET('periodos-avaliativos', { turma: idTurma});
        }
        function getItensEducacionais(idTurma){
           return Restangular.one('commons/turma', idTurma).getList('itens-educacionais');
        }
        function getDadosFrequencia(idTurma,filter,offset,limit){
             return Restangular.all('commons').customGET('frequencia', { turma: idTurma,
                 filter: filter,
                 offset: offset,
                 limit: limit});
        }
        function remove(id) {
            return Restangular.one('commons').one('frequencia', id).remove();
        }
        function atualizaFrequencia(idFrequencia, idTurma,faltas){
            return Restangular.all('commons/frequencia').customPUT(faltas,idFrequencia, {turma: idTurma});
        }
        function atualizaFrequenciaEncerramento(idFrequencia, idTurma,faltas){
            return Restangular.all('commons/frequencia/periodo-encerrado').customPUT(faltas,idFrequencia, {turma: idTurma});
        }
        function saveFrequencia(idTurma,faltas){
            return Restangular.one('commons/frequencia').customPOST(faltas,'',{turma: idTurma});
        }
        function saveFrequenciaEncerramento(idTurma,faltas){
            return Restangular.one('commons/frequencia/periodo-encerrado').customPOST(faltas,'',{turma: idTurma});
        }
        function removeFrequencia(idFrequencia){
            return Restangular.one('commons/frequencia',idFrequencia).remove();
        }
        function removeFrequenciaPeriodoEncerrado(idFrequencia){
            return Restangular.one('commons/frequencia/periodo-encerrado',idFrequencia).remove();
        }
        function getPublicados(turma){
            return Restangular.one('commons/turma', turma).customGET('frequencias/publicados');
        }

//EJA MODULAR
        function getFrequenciaModular(idTurma,filter,offset,limit){
            return Restangular.all('commons/frequencia-eja').getList({ itemEducacional: idTurma, filter: filter,offset: offset,
                limit: limit});
        }
        function getModulosTurma(idTurma){
            return Restangular.one('commons/turma', idTurma).getList('modulos-eja');
        }   
        function saveFrequenciaEja(idTurma,faltas){
            return Restangular.one('commons/frequencia-eja').customPOST(faltas,'',{turma: idTurma});
        }
        function saveFrequenciaEjaPeriodoEncerrado(idTurma,faltas){
            return Restangular.one('commons/frequencia-eja/periodo-encerrado').customPOST(faltas,'',{turma: idTurma});
        }
        function atualizaFrequenciaEja(idTurma,faltas){
            return Restangular.all('commons/frequencia-eja/'+idTurma).customPUT(faltas,'');
        }
        function atualizaFrequenciaEjaPeriodoEncerrado(idTurma,faltas){
            return Restangular.all('commons/frequencia-eja/periodo-encerrado/'+idTurma).customPUT(faltas,'');
        }
        function removeFrequenciaEja(idFrequencia){
            return Restangular.one('commons/frequencia-eja',idFrequencia).remove();
        }    
        function saveFrequenciaEjaTotal(falta){
            return Restangular.all('commons/registro-faltas').post(falta);
        }
        function saveFrequenciaEjaTotalPeriodoEncerrado(falta){
            return Restangular.all('commons/registro-faltas/periodo-encerrado').post(falta);
        }
        function atualizaFrequenciaEjaTotal(idFrequencia,falta){
             return Restangular.all('commons/registro-faltas').customPUT(falta ,idFrequencia,'');
        }
        function atualizaFrequenciaEjaTotalPeriodoEncerrado(idFrequencia,falta){
             return Restangular.all('commons/registro-faltas').customPUT(falta ,idFrequencia,'');
        }
        function removeFrequenciaEjaTotal(idFrequencia){
            return Restangular.one('commons/registro-faltas',idFrequencia).remove();
        }   


    }
})();

(function () {
    'use strict';
    angular.module('educacao.common')
        .directive('appInscricaoAlunoListaEspera', directive);
    
    var SITUACOES_PERMITE_ENVIAR_MATRICULA_PARA_LISTA_ESPERA = ['CURSANDO', 'MATRICULADO'];

    function directive() {
        return {
            restrict: 'E',
            scope: {
                itensSelecionados: '='
            },
            bindToController: true,
            templateUrl: 'common/directives/app-inscricao-aluno-lista-espera/app-inscricao-aluno-lista-espera.directive.html',
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['Restangular', 'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        'ui.components.Modal', 'educacao.pessoas.AlunoService', 'promiseTracker', 'bfc.$$PermissionsService'];

    function Controller(Restangular, ConfigListaEsperaService, uiModal, AlunoService, promiseTracker, PermissionsService) {

        var vm = this;
        vm.trackerLoading = promiseTracker();
        vm.isMaticulaCursandoOuMatriculado = isMaticulaCursandoOuMatriculado;
        vm.hasPermissionToCriar = !PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'criar');

        vm.abreCadastro = abreCadastro;

        init();

        function init() {
            vm.trackerLoading.addPromise(
                ConfigListaEsperaService.listByParams({filter: 'inativo = false'}).then(
                    function (listas) {
                        vm.listasEspera = Restangular.stripRestangular(listas.content);
                    }
                )
            );
        }

        function abreCadastro(configuracaoId) {
            var alunoSelecionado = _.first(vm.itensSelecionados).aluno.id;
            AlunoService.getAlunoToListaEspera(alunoSelecionado)
                .then(function (alunoData) {
                    uiModal.open({
                        templateUrl: 'lista-espera-new/inscricao/cadastro-inscricao-lista-espera.html',
                        controller: 'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaCadCtrl',
                        controllerAs: 'vm',
                        size: 'xxl',
                        params: {
                            configuracao: configuracaoId,
                            inscricaoListaEspera: alunoData,
                            isCopiaAlunoMatriculado: true
                        }
                    });
                });
        }

        function isMaticulaCursandoOuMatriculado() {
            if(!vm.hasPermissionToCriar){
                return true; 
            }
            if (vm.itensSelecionados.length === 1) {
                return !SITUACOES_PERMITE_ENVIAR_MATRICULA_PARA_LISTA_ESPERA.includes(_.first(vm.itensSelecionados).situacao);
            }
            return true;
        }
    }

})();


(function() {
	'use strict';

	angular.module('educacao.pessoas')
		.factory('educacao.pessoas.MedicaoAntropometricaService', MedicaoAntropometricaService);

	MedicaoAntropometricaService.$inject = ['ui.components.Modal'];

	function MedicaoAntropometricaService($modal) {
		var self = this;

		self.open = open;

		return self;

		function open(medicoes, nascimento) {
			return $modal.open({
				templateUrl: 'planejamento/pessoa/app-medicao-antropometrica/medicao-antropometrica-modal.html',
				controller: 'educacao.pessoas.MedicaoAntropometricaModalController',
				controllerAs: 'vm',
				params: {
					medicoes: medicoes,
					nascimento: nascimento
				},
				size: 'sm'
			});
		}
	}
})();

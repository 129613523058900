(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaNivelEscolar', appConfiguracaoListaEsperaNivelEscolar);

    function appConfiguracaoListaEsperaNivelEscolar() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-nivel-escolar/app-configuracao-lista-espera-nivel-escolar.directive.html',
            scope: {},
            bindToController: {
                niveisModalidades: '=',
                tabModalidadeAtiva: '=',
                tabNivelAtiva: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        '$scope'
    ];

    function Controller($scope) {
        var vm = this;
        $scope.$watch('vm.tabModalidadeAtiva', ajusteModalidade);

        vm.tabActive = tabActive;
        vm.incompleto = incompleto;
        vm.showDiretivaConfiguracaoEtapa = showDiretivaConfiguracaoEtapa;

        function incompleto(nivelEscolar) {
            return nivelEscolar.ativo && nivelEscolar.formularioInvalido;
        }

        function ajusteModalidade(tabModalidadeAtiva) {
            vm.nivelEscolar = _.get(_.find(vm.niveisModalidades, 'modalidade', tabModalidadeAtiva), 'listNivelEscolar');
        }

        function tabActive(nivelEscolar) {
            if (!nivelEscolar.ativo) {
                vm.tabNivelAtiva = '';
                return;
            }
            vm.tabNivelAtiva = nivelEscolar.nivelEscolar;
        }

        function showDiretivaConfiguracaoEtapa() {
            return (vm.tabNivelAtiva === 'EDUCACAO_INFANTIL' ||
                vm.tabNivelAtiva === 'ENSINO_FUNDAMENTAL' ||
                vm.tabNivelAtiva === 'ENSINO_MEDIO') && (_.get(_.find(vm.nivelEscolar, 'nivelEscolar', vm.tabNivelAtiva),'ativo'));
        }
    }

})();

(function() {

    'use strict';

    /** 
		Diretiva utilizada para criar um select a partir de um array de strings.
		
		ngModel -> propriedade a ser vinculada ao ng-model do input
		options -> array com as opções para seleção
		ngDisabled -> expression vinculada com a propriedade ng-disabled do input
		ngChange -> função a ser vinculada no ng-change do input
		ngRequired -> expression que indica se o elemento é obrigatório ou não
		
		É possível passar o atributo 'multiple' para seleção de várias opções

		Exemplos: 
		
		<app-select-string ng-model="vm.parametros.etapa" ng-disabled="!vm.parametros.emitirCertificado" 
		options="vm.etapas"></app-select-string>
		
		<app-select-string ng-model="vm.parametros.etapa" ng-change="vm.changeEtapa()"  
		options="vm.etapas" multiple></app-select-string>

		Onde: 
			options="vm.etapas" -> ['teste', 'segunda etapa', 'quinta etapa']

			Valor retornado ao ng-model:
							ng-model: {id: 1, description: 'teste'}

			No caso de possuir a tag multiple: 
							ng-model: [{"id":1,"description":"teste"},{"id":3,"description":"quinta etapa"}]
    **/

    angular.module('educacao.common')
        .directive('appSelectString', AppSelectStringDirective);

    function AppSelectStringDirective() {
        return {
            restrict: 'E',
            template: '<input ng-model="ngModel" ng-disabled="ngDisabled" ng-required="ngRequired" ng-change="ngChange" data-bf-select2="vm.configSelect2" type="hidden" />',
            scope: {
                ngModel: '=',
                options: '=',
                ngDisabled: '=',
                ngChange: '&?',
                ngRequired: '=?'
            },
            controller: DirectiveController,
            controllerAs: 'vm'
        };
    }

    DirectiveController.$inject = ['$scope', '$q', '$attrs', 'bfc.Select2Config'];

    function DirectiveController($scope, $q, $attrs, Select2Config) {

        var vm = this;

        vm.configSelect2 = configSelect2();
        vm.validValues = [];

        registerValidValues();

        function registerValidValues() {
            var id = 1;
            $scope.options.forEach(function(item) {
                var obj = {
                    id: id++,
                    description: item
                };
                vm.validValues.push(obj);
            });
        }

        function configSelect2() {

            function _formatResult(data) {
                return data.description;
            }

            function _formatSelection(data) {
                return data.description;
            }

            function _formatValue(data) {
                return data;
            }

            function _initSelection() {}

            function _result(params, callback) {
                var deferred = $q.defer();

                function _data() {
                    deferred.resolve(vm.validValues);

                    return deferred.promise;
                }

                return _data().then(function(data) {
                    callback({
                        results: data,
                        more: false
                    });
                });
            }

            return Select2Config.create({
                initSelection: _initSelection,
                formatResult: _formatResult,
                formatSelection: _formatSelection,
                formatValue: _formatValue,
                result: _result,
                multiple: angular.isDefined($attrs.multiple),
                dropdownAutoWidth: false,
            });
        }
    }

})();


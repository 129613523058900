(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ReordenacaoNumerosChamadaController', Controller);

    Controller.$inject = ['$modalInstance', 'educacao.context.ContextoEstabelecimento', 'educacao.context.ContextoAnoLetivo', 'Restangular'];

    function Controller($modalInstance, ContextoEstabelecimento, ContextoAnoLetivo, Restangular) {
        var vm = this;
        
        vm.turmasSelecionadas = _.cloneDeep($modalInstance.params.turmasSelecionadas) || [];

        getParametrosScript();

        function getParametrosScript() {
            var anoLetivoContexto = ContextoAnoLetivo.getAnoLetivo();
            var estabelecimentoContexto = ContextoEstabelecimento.getEstabelecimento();

            var params = {
                parametros: {
                    anoLetivo: Restangular.stripRestangular(anoLetivoContexto),
                    turmas: vm.turmasSelecionadas
                }
            };

            if (!estabelecimentoContexto.isSecretaria) {
                var listEstabelecimento = [];
                listEstabelecimento.push(estabelecimentoContexto);

                params.parametros.estabelecimentos = listEstabelecimento;
            }

            vm.parametrosSript = params;
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .service('educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService', InscricaoListaEsperaService);

    InscricaoListaEsperaService.$inject = ['ServiceBuilder', 'Restangular'];

    function InscricaoListaEsperaService(ServiceBuilder, Restangular) {
        var service = ServiceBuilder.create()
            .path('lista-espera', 'inscricao-lista-espera')
            .build();

        var basePathDadosPadrao = Restangular.all('commons/dados-padrao');

        service.getInscritos = getInscritos;
        service.getInscritosEditar = getInscritosEditar;
        service.saveCandidato = saveCandidato;
        service.getEstabelecimentos = getEstabelecimentos;
        service.getMunicipio = getMunicipio;
        service.getBairro = getBairro;
        service.cancelar = cancelar;
        service.desfazerCancelamento = desfazerCancelamento;
        service.getConfiguracao = getConfiguracao;
        service.getCursos = getCursos;
        service.salveDataInscricao = salveDataInscricao;
        service.situacaoMatriculado = situacaoMatriculado;
        service.getReligiao = getReligiao;
        service.getOrgaoEmissor = getOrgaoEmissor;
        service.getCartorio = getCartorio;
        service.getPais = getPais;
        service.getDeficiencias = getDeficiencias;
        service.remove = remove;
        service.getEstabelecimentoId = getEstabelecimentoId;
        service.getInscritosPosicao = getInscritosPosicao;
        service.getInscritosListaEspera = getInscritosListaEspera;
        service.getPessoas = getPessoas;
        service.encaminhar = encaminhar;
        service.desfazerEncaminhamento = desfazerEncaminhamento;
        service.getDataHoraAtual = getDataHoraAtual;
        service.getChaveAcesso = getChaveAcesso;

        return service;

        function getInscritos(params, configuracao) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', configuracao)
                .all('inscricao')
                .get('', params)
                .then(strip);
        }

        function getInscritosListaEspera(configuracao, params) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', configuracao)
                .all('inscricao/inscritos')
                .get('', params)
                .then(strip);
        }

        function getInscritosPosicao(params, configuracaoId) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', configuracaoId)
                .all('inscricao/posicao')
                .get('', params)
                .then(strip);
        }

        function getInscritosEditar(configuracaoId, idInscrito) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', configuracaoId)
                .one('inscricao', idInscrito)
                .get()
                .then(strip);
        }

        function getChaveAcesso(configuracaoId, idInscrito) {
            return Restangular.one('lista-espera')
            .one('configuracao-lista-espera', configuracaoId)
            .one('inscricao', idInscrito)
            .one('chave-acesso')
            .get();
        }

        function remove(idConfig, idInscrito) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfig)
                .all('inscricao')
                .customDELETE(idInscrito);
        }

        function saveCandidato(dado, idConfiguracao) {
            return dado.id ? Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', dado.id)
                .customPUT(dado)
                :
                Restangular.one('lista-espera')
                    .one('configuracao-lista-espera', idConfiguracao)
                    .all('inscricao')
                    .post(dado);
        }

        function getEstabelecimentos(filter) {
            return Restangular.one('commons/estabelecimento/estabelecimentos/lista')
                .getList('',{
                    filter: filter
                })
                .then(strip);
        }

        function getEstabelecimentoId(id) {
            return Restangular.all('commons')
                .one('estabelecimento', id)
                .withHttpConfig({ cache: true })
                .get();
        }

        function getConfiguracao(configuracao) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', configuracao)
                .get()
                .then(strip);
        }

        function getMunicipio(filter) {
            return basePathDadosPadrao.one('municipio')
                .withHttpConfig({ cache: true })
                .get({
                    filter: filter
                })
                .then(strip);
        }

        function getBairro(filter) {
            return basePathDadosPadrao.one('bairro')
                .withHttpConfig({ cache: true })
                .get({
                    filter: filter
                })
                .then(strip);
        }

        function getReligiao(id) {
            return Restangular.one('pessoa')
                .one('religiao', id)
                .withHttpConfig({ cache: true })
                .get()
                .then(strip);
        }

        function getPais(id) {
            return basePathDadosPadrao.one('pais', id)
                .withHttpConfig({ cache: true })
                .get()
                .then(strip);
        }

        function getOrgaoEmissor(filterId) {
            return Restangular.all('commons/inep/orgao-emissor-inep')
                .withHttpConfig({ cache: true })
                .get('', {
                    filter: filterId
                })
                .then(strip);
        }

        function getCartorio(id) {
            return Restangular.all('commons/inep')
                .one('cartorio-inep', id)
                .withHttpConfig({ cache: true })
                .get()
                .then(strip);
        }

        function getDeficiencias(filter){
            return Restangular.all('commons/deficiencia')
                .withHttpConfig({ cache: true })
                .get('', {
                    filter: filter
                })
                .then(strip);
        }

        function getCursos(filterId) {
            return Restangular.all('matriz/cursos')
                .withHttpConfig({ cache: true })
                .get('', {
                    filter: filterId
                })
                .then(strip);
        }

        function strip(data) {
            return Restangular.stripRestangular(data);
        }

        function cancelar(idConfiguracao, itemId, dado) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', itemId)
                .one('cancelar')
                .customPUT(dado);
        }


        function desfazerCancelamento(idConfiguracao, itemId, dado) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', itemId)
                .one('cancelar/desfazer')
                .customPUT(dado);
        }

        function situacaoMatriculado(idConfiguracao, itemId, dado) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', itemId)
                .one('matricular')
                .customPUT(dado);
        }

        function salveDataInscricao(idConfiguracao, inscricaoId, estabId, data, tipoDataAlteracao, turno) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', inscricaoId)
                .one('alterar-data')
                .customPUT({ 
                    data: data,
                    estabId: estabId,
                    tipoDataAlteracao: tipoDataAlteracao,
                    turno: turno
                });
        }

        function getPessoas(filter) {
            return Restangular.one('pessoas')
                .one('aluno')
                .one('pessoas')
                .get({
                    filter: filter
                });
        }

        function encaminhar(idConfiguracao, idInscricao, dados) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', idInscricao)
                .one('encaminhar')
                .customPUT(dados);
        }

        function desfazerEncaminhamento(idInscricao, idConfiguracao, dados) {
            return Restangular.one('lista-espera')
                .one('configuracao-lista-espera', idConfiguracao)
                .one('inscricao', idInscricao)
                .one('encaminhar/desfazer')
                .customPUT(dados);
        }

        function getDataHoraAtual() {
            return Restangular.one('data-hora-atual')
                .get();
        }
    }
})();

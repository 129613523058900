(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.lista-espera-novo.configuracao.VisualizaFormularioController', ViewFormCtrl);

    ViewFormCtrl.$inject = [
        '$modalInstance', 
        '$modalTracker', 
        '$q',
        'educacao.enderecos.BairroService',
        'educacao.enderecos.MunicipioService',
        'educacao.enderecos.EstadoService'
    ];

    function ViewFormCtrl($modalInstance, $modalTracker, $q, BairroService, MunicipioService,EstadoService) {
        var vm = this;

        vm.BairroService = BairroService;
        vm.MunicipioService = MunicipioService;
        vm.EstadoService = EstadoService;

        var propriedadesPublicas = {
            cancel: _cancel
        };

        function refresh() {

            if (vm.form) {
                vm.form.$setPristine();
            }

            vm.inscricao = {
                dados: {}
            };
            vm.configuracao = $modalInstance.params.configuracao;
            vm.show = true;

        }

        $modalTracker.addPromise(
            $q.when(
                refresh()
            ).then(
                angular.extend(vm, propriedadesPublicas)
            )
        );

        function _cancel() {
            $modalInstance.close();
        }
    }
})();

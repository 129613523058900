(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.TipoCargoCtrl', Controller);

    Controller.$inject = [
        '$modalStates',
        'educacao.funcionario.TipoCargoService'
    ];

    function Controller($modalStates, TipoCargoService) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;

        vm.TipoCargoService = TipoCargoService;
        vm.ClassificacaoCargo = [];
        vm.enumClassificacaoCargoPromisse = TipoCargoService.getClassificacaoTipoCargo();

        init();

        function init() {
            TipoCargoService.getClassificacaoTipoCargo().then(function(data) {
                vm.ClassificacaoCargo = data;
            });
        }

        function openCad(tipoCargo) {
            $modalStates.goModalState(tipoCargo);
        }

        function remove(data) {
            return TipoCargoService.remove(data);
        }
    }
})();


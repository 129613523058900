(function() {

    'use strict';

    angular.module('educacao.educacenso')
        .directive('appMenuLateralEducacenso', SelectMenuLateralEducacenso);

    function SelectMenuLateralEducacenso() {
        return {
            restrict: 'E',
            templateUrl: 'educacenso/app-menu-lateral-educacenso/app-menu-lateral-educacenso.html',
            scope: {
                ngModel: '='
            },
            controller: appMenuLateralEducacensoController,
            controllerAs: 'vm'
        };
    }

    appMenuLateralEducacensoController.$inject = [];

    function appMenuLateralEducacensoController() {}

})();


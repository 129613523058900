(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appEnumValue', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<span title="{{vm.showTitle ? vm.enumDescription : \'\'}}">{{vm.enumDescription}}</span>',
            scope: {},
            bindToController: {
                enumName: '@',
                descriptionField: '@',
                enumValue: '=',
                showTitle: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        '$enumsCache',
        '$scope'
    ];

    function Controller($enumsCache,$scope) {
        var vm = this;


        $scope.$watch('vm.enumValue', function(newValue, oldValue) {
        if(newValue === oldValue){
        return;
        }

        getEnumValue();
        });

        getEnumValue();

        function getEnumValue() {
            var field = vm.descriptionField || 'description';
            $enumsCache.get(vm.enumName)
                .then(function(enumData) {
                    vm.enumDescription = _.get(enumData[vm.enumValue], field);
                });
        }
    }

})();
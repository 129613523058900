(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.CadastroEstadoController', CadastroEstadoController);

    CadastroEstadoController.$inject = [
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'educacao.enderecos.EstadoService',
        'bfc.Focus'
    ];

    function CadastroEstadoController($scope, $modalInstance, bfcNotification, EstadoService, focus) {
        var vm = this;
        var REQ_TYPE_SAVE = 'save';
        var REQ_TYPE_REMOVE = 'remove';

        vm.estado = $modalInstance.params.estado || {};
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd ===  undefined ? true : $modalInstance.params.canSaveAdd;
        vm.editando = _.get($modalInstance.params.estado, 'id');
        vm.save = save;
        vm.remove = remove;
        vm.windowTitle = '';
        vm.hasReqError = true;

        vm.permissions = {
            save: {
                bfPermissions: 'app.enderecos.estado',
                operation: 'criar'
            },
            update: {
                bfPermissions: 'app.enderecos.estado',
                operation: 'editar'
            },
            remove: {
                bfPermissions: 'app.enderecos.estado',
                operation: 'excluir'
            }
        };

        setWindowTitle();

        function save(continuar) {
            return EstadoService
                .save(vm.estado)
                .then(function(data) {
                    vm.estado = data;
                    handleReqSuccess(continuar,REQ_TYPE_SAVE,false);
                });
        }

        function remove() {
            return EstadoService
                .remove(vm.estado)
                .then(function() {
                        vm.hasReqError = false;
                    }
                )
                .catch(function() {
                    vm.hasReqError = true;
                })
                .finally(function() {
                        handleReqSuccess(false,REQ_TYPE_REMOVE,vm.hasReqError);
                    }
                );
        }

        function handleReqSuccess(continuar, reqType, hasReqError) {
            if(hasReqError) {
                return;
            }

            publishNotification(reqType);
            
            if(continuar) {
                refresh();
            } else {
                closeModal();
            }
        }

        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            vm.estado = {};
            focus('DescricaoEstado');
        }

        function closeModal() {
            $modalInstance.close(vm.estado);
        }

        function publishNotification(reqType) {
            if(reqType === REQ_TYPE_SAVE) {
                return bfcNotification.publish('Estado ' + vm.estado.nome + ' - ' + vm.estado.siglaUf + ' salvo com sucesso.', 'success');
            }

            return bfcNotification.publish('Estado ' + vm.estado.nome + ' - ' + vm.estado.siglaUf + ' excluído com sucesso.', 'success');
        }

        function setWindowTitle() {
            if(vm.estado && vm.estado.id) {
                vm.windowTitle = 'Editando Estado';
                return;
            }
            
            vm.windowTitle = 'Adicionando Estado';
        }
    }
})();
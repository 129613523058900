(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appProfileImage', ProfileImageDirective)
        .constant('URL_DEFAULT_PHOTO', {
            USER: '/images/avatar2.png',
            ENTITY: '/images/entidade.png'
        });

    function ProfileImageDirective() {
        return {
            retrict: 'E',
            required: 'ngModel',
            templateUrl: 'common/directives/app-profile-image/app-profile-image.directive.html',
            scope: {},
            bindToController: {
                file: '=',
                ngModel: '=',
                saveAgain: '=',
                classDefault: '=',
                hiddenRemove: '=',
                uploadType: '=',
                nomePessoa: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'Restangular', 'ed-photo-profile.EdUploadPhotoProfileService', 'URL_DEFAULT_PHOTO'];

    function Controller($scope, promiseTracker, Restangular, EdUploadPhotoProfileService, URL_DEFAULT_PHOTO) {

        var vm = this;

        vm.openProfile = openProfile;

        vm.remove = remove;

        vm.tracker = {
            loading: promiseTracker()
        };

        $scope.$watch('vm.ngModel', function() {
            if (!vm.ngModel) {
                return remove();
            }

            if (vm.ngModel.id && !vm.content) {
                var promise = Restangular.one('arquivo', vm.ngModel.id)
                    .all('dataurl').get('')
                    .then(function(data) {
                        setImage(data);
                        if (vm.saveAgain) {
                            vm.file = getBlob(data);
                        }
                    });

                vm.tracker.loading.addPromise(promise);
            }
        });

        function remove() {
            setImage();
            vm.ngModel = null;
        }

        function getBlob(base64) {
            var auxByte;
            if (base64.split(',')[0].indexOf('base64') >= 0) {
                auxByte = atob(base64.split(',')[1]);
            } else {
                auxByte = unescape(base64.split(',')[1]);
            }
            var tipo = base64.split(',')[0].split(':')[1].split(';')[0];
            var img = new Uint8Array(auxByte.length);
            for (var i = 0; i < auxByte.length; i++) {
                img[i] = auxByte.charCodeAt(i);
            }

            return new Blob([img], {
                type: tipo
            });
        }

        function setImage(img) {
            if (!img) {
                vm.photoContainerStyle = null;
                vm.imageUrl = null;
                return;
            }

            vm.imageUrl = img;
            vm.photoContainerStyle = {
                'background-image': 'url("' + img + '")'
            };
        }

        function openProfile() {
            var options = {
                maxSize: '5MB',
                pattern: 'image/*',
                areaType: 'square',
                areaMinSize: 80,
                resultImageSize: 600, //Resolução máxima para equipamento de reconhecimento facial 1200x600
                resultImageQuality: 0.9, //Para reconhecimento facial a imagem deve ter no max 100k, esta quality colabora para isso
                resultImageFormat: 'image/jpeg'
            };

            var urlPhoto = URL_DEFAULT_PHOTO.ENTITY;
            if (vm.uploadType === 'user') {
                urlPhoto = URL_DEFAULT_PHOTO.USER;
                options.areaType = 'circle';
            }

            var profile = {
                name: vm.nomePessoa,
                imgSrc: vm.imageUrl || urlPhoto
            };

            EdUploadPhotoProfileService.openProfile(profile, options).then(function(data) {
                if (!_.get(data, 'image')) {
                    return;
                }

                setImage('data:image/jpeg;base64,' + data.image);

                if (vm.saveAgain) {
                    vm.file = getBlob(vm.imageUrl);
                }
                vm.ngModel = vm.ngModel || {};
                vm.ngModel.nome = 'upload.jpg';
                vm.ngModel.tipo = 'image/jpeg';
                vm.ngModel.conteudo = data.image;
                vm.ngModel.tamanho = data.size;
            });
        }
    }
})();

(function() {
    'use strict';
    angular.module('educacao.common')
        .directive('appTextareaContaCaracteres', directive);

    function directive() {
        return {
            restrict: 'E',

            templateUrl: 'common/directives/textarea-contagem/textarea-conta-caracteres.directive.html',

            scope: {
                maxlength: '=',
                ngModel: '=',
                rows: '='
            },

            link: postLink
        };
    }

    function postLink($scope) {
        onChange();
        $scope.onChange = onChange;

        function onChange() {
            $scope.count = $scope.maxlength - ($scope.ngModel.length + $scope.ngModel.split('\n').length - 1);
        }
    }
})();


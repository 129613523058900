(function () {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.BairroController', BairroController);

    BairroController.$inject = [
        '$modalStates',
        'bfc.Notification',
        'educacao.enderecos.BairroService',
        'educacao.common.$commons'
    ];

    function BairroController($modalStates, bfcNotification, BairroService, $commons) {
        var vm = this;

        vm.BairroService = BairroService;
        vm.openCad = openCad;
        vm.remove = remove;
        vm.search = search;

        vm.filter = {};
        vm.tracker = {};
        vm.listControls = {};

        vm.tracker.loading = $commons.$promiseTracker();

        vm.columns = [
            {
                id: 'nome',
                model: 'nome',
                label: 'Nome',
                filterable: true
            }
        ];

        function search() {
            vm.listControls.search();
        }

        function remove(bairro) {
            return BairroService
                .remove(bairro)
                .then(function () {
                    publishNotificationRemove(bairro);
                });
        }

        function openCad(bairro) {
            $modalStates.goModalState(bairro);
        }

        function publishNotificationRemove(bairro) {
            bfcNotification.publish('Bairro ' + bairro.nome + ' - ' + bairro.municipio.nome + ' excluído com sucesso.', 'success');
        }
    }
})();
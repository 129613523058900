(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .service('educacao.matricula.MatriculaService', service);

    var TIPO_MATRICULAS_ATIVIDADES = ['ATIVIDADE_COMPLEMENTAR', 'AEE'];

    service.$inject = ['ServiceBuilder', 'Restangular'];

    function service(ServiceBuilder, Restangular) {
        var created = ServiceBuilder.create()
            .path('matricula', 'matriculas')
            .build();

        created.getCompletosByTurmaSituacao = getCompletosByTurmaSituacao;
        created.getMatriz = getMatriz;
        created.getMatrizEja = getMatrizEja;
        created.getHistoricos = getHistoricos;
        created.getUltimoRemanejamento = getUltimoRemanejamento;
        created.saveEncaminhamento = saveEncaminhamento;
        created.save = save;
        created.getMovimento = getMovimento;
        created.movimentar = movimentar;
        created.movimentarEjaModular = movimentarEjaModular;
        created.desfazerMovimentacaoEjaModular = desfazerMovimentacaoEjaModular;
        created.desfazerMovimentacao = desfazerMovimentacao;
        created.isAtividade = isAtividade;
        created.gerarBoletim = gerarBoletim;
        created.getEnturmacaoAtiva = getEnturmacaoAtiva;
        created.trocaStatus = trocaStatus;
        created.getEnturmacoesMatricula = getEnturmacoesMatricula;
        created.getEnturmacoesTurma = getEnturmacoesTurma;
        created.getEnturmacoes = getEnturmacoes;
        created.listCompletev2ByParams = listCompletev2ByParams;
        created.getPeriodosAvaliativosByMatricula = getPeriodosAvaliativosByMatricula;

        return created;

        function getMatriz(tipoMatricula, params, filter) {
            return created
                .getEndpoint()
                .all('matriz')
                .getList({
                    filter: filter,
                    tipoMatricula: tipoMatricula,
                    offset: params.offset,
                    limit: params.limit
                });
        }

        function getMatrizEja(idEstabelecimento, params, filter) {
            return created
                .getEndpoint()
                .all('matriz-eja')
                .getList({
                    filter: filter,
                    idEstabelecimento: idEstabelecimento,
                    offset: params.offset,
                    limit: params.limit
                });
        }

        function trocaStatus(id, situacao) {
            return created
                .getEndpoint()
                .all('alterar-status/' + id + '/' + situacao)
                .post({
                    id: id,
                    situacao: situacao
                });
        }

        function getHistoricos(matriculaId, params) {
            return Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .all('historicos')
                .get('', params || {});
        }

        function getUltimoRemanejamento(matriculaId) {
            return Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .one('ultimoRemanejamento')
                .get();
        }

        function save(entity, isVagaEtapa, isEja) {
            var endpoint = created.getEndpoint();
            var id = entity.id;

            if (isVagaEtapa) {
                endpoint = endpoint.all('vagas');
            } else if (isEja) {
                endpoint = endpoint.all('modular');
            } else {
                endpoint = endpoint.all('padrao');
            }

            return id ? endpoint.customPUT(entity, id).then(emitDirty) : endpoint.post(entity).then(emitDirty);
        }

        function listCompletev2ByParams(params) {
            var endpoint = created.getEndpoint();
            return endpoint.get('v2/completos', params);
        }

        function saveEncaminhamento(entity) {
            var endpoint = created.getEndpoint();
            var id = entity.id;
            endpoint = endpoint.one('vagas').all('encaminhamento');
            return id ? endpoint.customPUT(entity, id).then(emitDirty) : endpoint.post(entity).then(emitDirty);
        }

        function getMovimento(matriculaId, movimentacaoId) {
            return Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .one('movimentacoes', movimentacaoId)
                .get({});
        }

        function movimentar(matriculaId, movimentacao) {
            var endpoint = Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .one('movimentacoes');

            if (_.get(movimentacao, 'id')) {
                return endpoint.customPUT(movimentacao, movimentacao.id).then(emitDirty);
            }

            return endpoint.post('movimentar', movimentacao).then(emitDirty);
        }

        function movimentarEjaModular(matriculaId, matriculasId, movimentacao) {
            return Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .one('movimentacoes-eja-modular')
                .customPOST(movimentacao, 'movimentar', {matriculasId: matriculasId})
                .then(emitDirty);
        }

        function desfazerMovimentacaoEjaModular(matriculaId) {
            return Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .all('movimentacoes-eja-modular')
                .one('desfazer')
                .put()
                .then(emitDirty);
        }

        function desfazerMovimentacao(matriculaId) {
            return Restangular.all('matricula')
                .one('matriculas', matriculaId)
                .all('movimentacoes')
                .one('desfazer')
                .put()
                .then(emitDirty);
        }

        function emitDirty(data) {
            created.emit('dirty');
            return data;
        }

        function isAtividade(tipoMatricula) {
            return TIPO_MATRICULAS_ATIVIDADES.indexOf(tipoMatricula) > -1;
        }

        function gerarBoletim(parametros) {
            return Restangular.all('matricula')
                .all('boletim')
                .all('pdf')
                .withHttpConfig({
                    responseType: 'arraybuffer'
                })
                .post(parametros);
        }

        function getCompletosByTurmaSituacao(turma, situacoes) {
            var filter =
                '(' +
                'turma = ' +
                turma.id +
                ' and situacao in (' +
                situacoes.join('', '') +
                ')' +
                ')';
            return created.listCompleteByParams({
                filter: filter
            });
        }

        function getEnturmacaoAtiva(idMatricula) {
            return Restangular.one('matricula')
                .one('matriculas', idMatricula)
                .one('enturmacaoAtiva')
                .get('', {});
        }

        function getEnturmacoesMatricula(idMatricula) {
            return Restangular.all('matricula')
                .all('enturmacao')
                .one('matricula', idMatricula)
                .get('', {})
                .then(Restangular.stripRestangular);
        }

        function getEnturmacoesTurma(idTurma, params) {
            return Restangular.all('matricula')
                .one('enturmacao', idTurma)
                .all('enturmacoes')
                .get('', params)
                .then(Restangular.stripRestangular);
        }

        function getEnturmacoes(params) {
            return Restangular.all('matricula')
                .all('enturmacao/enturmacoes')
                .get('', params)
                .then(Restangular.stripRestangular);
        }

        function getPeriodosAvaliativosByMatricula(idMatricula) {
            return Restangular.all('commons')
                .one('periodos-avaliativos', '')
                .one('matricula', idMatricula)
                .get()
                .then(Restangular.stripRestangular);
        }
    }
})();

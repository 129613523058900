(function() {

    'use strict';

    angular.module('educacao.matricula').directive('appCardTurmaEnturmacoesAgrupadas', CardTurmaEnturmacoesAgrupadas);

    function CardTurmaEnturmacoesAgrupadas() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/app-card-turma/app-card-turma-enturmacoes-agrupadas/app-card-turma-enturmacoes-agrupadas.directive.html',
            scope: {},
            bindToController: {
                enturmacoes: '=',
                grupos: '=',
                turma: '=',
                exibeNumeroChamada: '=',
                desenturmarAction: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$enumsCache'];

    function Controller($enumsCache) {
        var vm = this;
        vm.totalEnturmacoes = [];
        vm.openCollapse = [];
        vm.getLength = getLength;

        getEnums();

        function getEnums() {
			$enumsCache.get('GrupoEnturmacaoCor').then(function (enumData) {
				vm.GrupoEnturmacaoCor = enumData;
				_.forEach(vm.GrupoEnturmacaoCor.values, function(color) {
					color.hex = '#' + color.hex;
				});
			});
		}

        function getLength(grupo) {
            if (!vm.enturmacoes) {
                return;
            }
            var enturmacoesGrupo = vm.enturmacoes[grupo];
            var total = 0;
            _.forEach(enturmacoesGrupo, function(enturmacaoGrupo) {
                total += enturmacaoGrupo.length;
            });
            return total;
        }
    }
})();
(function() {
	'use strict';

	angular.module('educacao.common')
		.factory('educacao.common.StringBuilder', StringBuilder);

	StringBuilder.$inject = ['$injector'];

	function StringBuilder() {
		var data = [];
        var counter = 0;
		
		return {
			init: function(){
				data=[];
				counter=0;
				return this;
			},
			validate: function(){
				if(!angular.isDefined(data) || !angular.isDefined(counter)){ 
					throw new Error('Stringbuilder not initialized');
				}
			},
			//Adiciona a string 's' ao builder
			append: function(s){
				this.validate();

				data[counter++]=s; 
				return this;
			},
			//remove 'j' elementos começando na posição 'i', ou 1 caso a posicao i nao seja informada
			remove: function(i,j){
				this.validate();
				data.splice(i,j||1); 
				return this;
			},
			//Inseri uma string na posicao 'i'
			insert: function(i, s){
				this.validate();
				data.splice(i,0,s); return this;
			},
			//retorna a String construida
			toString: function(s){
				return data.join(s||'');
			}
		};
	}
})();
(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appSelectFunc', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<input data-ng-model="ngModel.nome" data-bf-select2="vm.select2Config" type="hidden"/>',
            scope: {
                ngModel: '=',
                ngChange: '&?'
            },
            compile: function () {
                return {
                    post: function ($scope, $element, $attrs, $ctrl) {

                        var input = $element.children('input');
                        /*debugger;
                        $('.select2-selection').on('click', function () {
                            $(this).blur();
                        });*/

                        input
                            /*.on('select2-blur change', function () {
                                debugger;
                                $scope.$apply($ctrl.$setTouched());
                            })*/
                            .on('change', function () {
                                $scope.$apply($ctrl.$setDirty());
                            });

                        $scope.$watch('ngModel', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                ($scope.ngChange || angular.noop)();
                            }
                        });
                    }
                };
            },
            controller: Controller,
            controllerAs: 'vm',
            require: 'ngModel'
        };
    }

    Controller.$inject = [
        '$q',
        'bfc.Select2Config',
        'educacao.pessoas.AlunoService',
        '$scope'
    ];

    function Controller($q, Select2Config, AlunoService, $scope) {

        function _select2Config(/*options*/) {
            // O que aparece na pesquisa.
            function _formatResult(data) {

                if (!_.isNumber(data.id)) {
                    return data.nome;
                }

                var retorno = data.nome +
                    '<br><small>CPF:' +
                    data.cpf + ' | DATA DE NASCIMENTO: ' + data.dataNascimento + ' | RG: ' + data.rg +
                    '</small>';
                return retorno;
            }

            // O que aparece quando é selecionado um item.
            function _formatSelection(data) {
                return data.nome;
            }

            // O que é enviado para o server.
            function _formatValue(data) {
                if (_.isNumber(data.id)) {
                    return data;
                }
                return data.nome;
            }

            // Inicia o Select2 com um valor setado.
            function _initSelection(element, callback) {

                var nome = $scope.ngModel.nome;
                if (nome) {
                    callback({ id: nome, nome: nome });
                }
            }


            // Lista que aparecerá no select2
            function _result(params, callback) {

                return AlunoService.getPessoas(params.term).then(function (data) {
                    callback({
                        results: data.content,
                        more: false
                    });
                });
            }

            function createSearchChoice(term) {
                return {
                    id: term, nome: term
                };
            }

            return Select2Config.create({
                initSelection: _initSelection,
                formatResult: _formatResult,
                formatSelection: _formatSelection,
                formatValue: _formatValue,
                createSearchChoice: createSearchChoice,
                selectOnBlur: true,
                closeOnSelect: true,
                result: _result
            });
        }

        this.select2Config = _select2Config();
    }
})();
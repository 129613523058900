(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.encaminhado')
        .service('educacao.lista-espera-novo.encaminhado.EncaminhadoService', EncaminhadoService);

    EncaminhadoService.$inject = [
        'ServiceBuilder', 
        'Restangular', 
        '$rootScope'
    ];

    function EncaminhadoService(ServiceBuilder, Restangular, $rootScope) {
        var service = ServiceBuilder.create()
            .path('lista-espera', 'configuracao-lista-espera', 'encaminhamentos')
            .build();

        service.getAll = getAll;
        service.getEstabelecimentos = getEstabelecimentos;
        service.getEtapaInfantil = getEtapaInfantil;
        service.getTotal = getTotal;
        service.getUsers = getUsers;
        service.setTotal = setTotal;

        return service;

        function getAll(params) {
            return Restangular.all('lista-espera/configuracao-lista-espera/encaminhamentos')
                .get('', params);
        }

        function getEstabelecimentos() {
            return Restangular.all('lista-espera/configuracao-lista-espera/encaminhamentos')
                .one('estabelecimento')
                .get();
        }

        function getEtapaInfantil() {
            return Restangular.all('lista-espera/configuracao-lista-espera/encaminhamentos')
                .one('etapa-infantil')
                .get();
        }

        function getTotal() {
            return Restangular.all('lista-espera/configuracao-lista-espera/encaminhamentos')
                .one('total')
                .get();
        }

        function setTotal() {
            getTotal().then(function(total) {
                $rootScope.$broadcast('totalEncaminhados', total);
            });
        }

        function getUsers(user) {
            return Restangular.all('commons/')
                .one('usuario', user)
                .get();
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.convencao')
        .controller('educacao.convencao.ConvencaoCadCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        '$scope',
        'educacao.convencao.ConvencaoService'
    ];

    function Controller(
        promiseTracker,
        $modalInstance,
        focus,
        $scope,
        ConvencaoService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.convencao = {};
        vm.editando = _.get($modalInstance.params.convencao, 'id');
        vm.convencao = $modalInstance.params.convencao || {};
        vm.save = save;
        vm.cancel = fechaModal;
        vm.remove = remove;
        vm.windowTitle = vm.editando ? 'Editando convenção' : 'Adicionando convenção';
        verificaFormulario();
        focus('lbDescricaoConvencao');


        function save(continuar) {
            vm.tracker.loading.addPromise(
                ConvencaoService.save(vm.convencao).then(function(data) {
                    vm.convencao = data;
                    onSuccess(continuar);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                ConvencaoService.remove(vm.convencao).then($modalInstance.close)
            );
        }


        function onSuccess(continuar) {
            if (continuar) {
                verificaFormulario();
                vm.convencao = {};
                focus('lbDescricaoConvencao');
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.convencao);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.context')
        .controller('educacao.context.SelecaoEstabelecimentoController', SelecaoEstabelecimentoController);

    SelecaoEstabelecimentoController.$inject = ['CONTEXTO_ESTABELECIMENTO_SECRETARIA', '$state', 'promiseTracker', 'educacao.context.ContextoEstabelecimento'];

    function SelecaoEstabelecimentoController(CONTEXTO_ESTABELECIMENTO_SECRETARIA, $state, promiseTracker, ContextoEstabelecimento) {
        var vm = this;

        vm.loadingTracker = promiseTracker();
        vm.alteraEstabelecimento = alteraEstabelecimento;

        vm.loadingTracker.addPromise(
            ContextoEstabelecimento.listaEstabelecimentos()
                .then(function (data) {
                    if (_.isEmpty(data)) {
                        alteraEstabelecimento(CONTEXTO_ESTABELECIMENTO_SECRETARIA);
                    } else if (data.length === 1) {
                        alteraEstabelecimento(_.first(data));
                    } else {
                        vm.estabelecimentos = data;
                    }
                }));

        function alteraEstabelecimento(estabelecimento) {
            ContextoEstabelecimento.setEstabelecimento(estabelecimento);
            $state.go('selecao');
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.common.$commonsProvider',
    'educacao.historico.HistoricoServiceProvider',
    'educacao.funcionario.$funcionarioProvider'
    ];

    function HistoricoConfig($commonsProvider, HistoricoServiceProvider, $funcionarioProvider) {

        HistoricoServiceProvider.addTipoHandler('MOVIMENTACAO_FUNCIONARIO', {
            badge: {
                icon: 'fa-exchange',
                tooltip: 'Movimentado(a)'
            },
            campos: {
                tipoDescricao: {
                    insercao: {
                        template: '<small class="{{desfeito.valor == \'true\' ? \'mov-cancelada\' : \'nao-cancelada\'}}">Movimentação: <strong>{{valor}}</strong></small><br>'
                    }
                },
                observacao: {
                    insercao: {
                        template: '<small class="{{desfeito.valor == \'true\' ? \'mov-cancelada\' : \'nao-cancelada\'}}">Observação: <strong>{{valor}}</strong></small><br>'
                    }
                },
                data: {
                    insercao: {
                        template: '<small class="{{desfeito.valor == \'true\' ? \'mov-cancelada\' : \'nao-cancelada\'}}">Data acontecimento: <strong>{{valor}}</strong></small><br>'
                    }
                },
                dataFalecimento: {
                    insercao: {
                        template: '<small class="{{desfeito.valor == \'true\' ? \'mov-cancelada\' : \'nao-cancelada\'}}">Data Falecimento: <strong>{{valor}}</strong></small><br>'
                    }
                },
                dataInicio: {
                    insercao: {
                        template: '<small class="{{desfeito.valor == \'true\' ? \'mov-cancelada\' : \'nao-cancelada\'}}">Data início: <strong>{{valor}}</strong></small><br>'
                    }
                },
                dataFim: {
                    insercao: {
                        template: '<small class="{{desfeito.valor == \'true\' ? \'mov-cancelada\' : \'nao-cancelada\'}}">Data final: <strong>{{valor}}</strong></small><br>'
                    }
                },
                desfeito: {
                    insercao: {
                        template: '<small ng-show="{{desfeito.valor}}"><strong class="{{valor == \'true\' ? \'mov-sim-cancelada\' : \'nao-cancelada\'}}">{{valor == "true" ? "Desfeita" : "Não"}}</strong></small><br <small ng-show="{{desfeito.valor}}" />'
                    }
                }
            },
            openEdicaoMovimentacao: openEdicaoMovimentacao,
            retornaMovimentacao: retornaMovimentacao,
            excluiMovimentacao: excluiMovimentacao,
            actionTemplates: {
                actionTemplate: ['<button class="btn btn-xs btn-default" ' +
                 'bf-permissions="{{handler.retornaMovimentacao(tipoMovimentacao.valor)}}" operation="editar"' +
                'title="Editar movimentação" ' +
                'ng-if="desfeito.valor == \'false\' " ' +
                'ng-click="handler.openEdicaoMovimentacao(idMovimentacao.valor,tipoMovimentacao.valor)">' +
                '<i class="fa  fa-pencil "></i></button>',
                '<button class="btn btn-xs btn-default" ' +
                'bf-permissions="{{handler.retornaMovimentacao(tipoMovimentacao.valor)}}" operation="excluir"' +
                'title="Excluir movimentação" ' +
                'ng-if="(tipoMovimentacao.valor==\'AUSENCIA\') && desfeito.valor === \'false\'   " ' +
                'ng-click="handler.excluiMovimentacao(idMovimentacao.valor)">' +
                '<i class="fa fa-trash-o"></i></button>']
            }
        });

        function retornaMovimentacao(tipoMovimentacao) {
            if (tipoMovimentacao === 'AFASTAMENTO' || tipoMovimentacao === 'RETORNAR_AFASTAMENTO') {
                return 'app.funcionario.afastado';
            }
            return 'app.funcionario.' + tipoMovimentacao.toLowerCase();
        }

        function openEdicaoMovimentacao(matricula, tipo) {
            $commonsProvider.$get().$modal.open({
                templateUrl: 'planejamento/funcionarios/funcionario/movimentacoes-matricula/cadastro-movimentacoes-funcionario.html',
                controller: 'educacao.funcionario.CadastroMovimentacoesMatriculaFuncionarioCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    matricula: {
                        id: matricula
                    },
                    tipoMovimentacao: tipo,
                    editando: true
                }
            });
        }

        function excluiMovimentacao(idMovimentacao) {
            $funcionarioProvider.$get().$movimentacoesFuncionario.remove(parseInt(idMovimentacao));
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('classificacaoListaEncaminhados', ClassificacaoListaEncaminhados);

	function ClassificacaoListaEncaminhados() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/classificacao-inscritos/directives/classificacao-lista-encaminhados.directive.html',
			controller: Controller,
			controllerAs: 'vm',
			scope: {
				nivelEscolar: '=',
				matricular: '=',
				visualizarInscricao: '=',
				processo: '=',
				searchFilter: '=',
				listControls: '='
			}
		};
	}

	Controller.$inject = ['$scope', '$rootScope', 'educacao.matricula.ClassificacaoEncaminhadosService', 'promiseTracker'];

	function Controller($scope, $rootScope, ClassificacaoEncaminhadosService, promiseTracker) {
		var vm = this;

		vm.matricular = matricular;
		vm.listByParams = listByParams;
		vm.desfazerEncaminhado = desfazerEncaminhado;
		vm.tracker = {
			loading: promiseTracker()
		};

		function matricular(resultadoClassificacao) {
			$scope.matricular(resultadoClassificacao, $scope.nivelEscolar, true).then($scope.listControls.search);
		}

		function listByParams(params) {
			return ClassificacaoEncaminhadosService.listaEncaminhados(_.get($scope.processo, 'id'), params, $scope.nivelEscolar);
		}

		function desfazerEncaminhado(resultadoClassificacao) {
			var configuracaoInscricaoId = _.get(resultadoClassificacao, 'classificacaoInscricao.configuracaoInscricao.id');
			var classificacaoStatusId = _.get(resultadoClassificacao, 'status.id');
			if (!configuracaoInscricaoId || !classificacaoStatusId) {
				return;
			}
			vm.tracker.loading.addPromise(
				ClassificacaoEncaminhadosService.desfazerEncaminhado(configuracaoInscricaoId,
					classificacaoStatusId).then($scope.listControls.search)
			);
		}
	}

})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('ctShowHide', function() {
            return {
                restrict: 'A',
                scope: {
                    ctShowHideRender: '=?'
                },
                link: function(scope, elem, attrs) {
                    var icon = elem.find('.icon-caret-down');

                    function iconCaretDown() {
                        icon
                            .removeClass('icon-caret-right')
                            .addClass('icon-caret-down');
                    }

                    function iconCaretRight() {
                        icon
                            .removeClass('icon-caret-down')
                            .addClass('icon-caret-right');
                    }

                    /**
                     * Resgata o Objeto dos elementos que serão controlados pelo slide.
                     * @returns {*|jQuery|HTMLElement}
                     */
                    function getSlide() {
                        return $(bfc.cleanId(attrs.ctShowHide || attrs.ctShowHideId));
                    }

                    /**
                     * Função que irá abrir o accordion e vai atualizar a variável
                     * do accordion (ctShowHideRender) que irá definir que precisa renderizar o seu conteúdo.
                     * @return {void} Será aberto o accordion
                     */
                    function show() {
                        var slide = getSlide();

                        if (slide.is(':visible')) {
                            return;
                        }

                        scope.ctShowHideRender = true;

                        scope.$parent.$digest();

                        slide
                        // é necessário remover a classe hide de depois esconder com o JS pois o Bootstrap3 colocou um !important no display:none da classe hide
                            .removeClass('hide')
                            .hide()
                            .slideDown('fast');

                        elem.addClass('active');

                        if (icon.length > 0) {
                            iconCaretDown();
                        }
                    }

                    /**
                     * Função que irá fechar o accordion e vai atualizar a variável
                     * do accordion (ctShowHideRender) que irá definir para remover do DOM o seu conteúdo.
                     * @return {void} Será fechado o accordion
                     */
                    function hide() {
                        var slide = getSlide();

                        if (!slide.is(':visible')) {
                            return;
                        }

                        elem.removeClass('active');

                        if (icon.length > 0) {
                            iconCaretRight();
                        }

                        slide.slideUp('fast', function() {
                            scope.ctShowHideRender = false;

                            scope.$parent.$digest();
                        });

                    }

                    /**
                     * Função que irá abrir ou fechar o accordion dependendo do seu status atual
                     * @return {void} Será aberto ou fechado o accordion
                     */
                    function toggle() {
                        var slide = getSlide();

                        if (slide.is(':visible')) {
                            hide();
                        } else {
                            show();
                        }
                    }

                    /**
                     * Evento click que irá realizar o toggle do accordion
                     * @return {void} Será aberto ou fechado o accordion
                     */
                    elem.on('click', function() {
                        toggle();
                    });

                    /**
                     * Evento que será utilizado no serviço ShowHide que irá atualizar
                     * o accordion conforme método passado por parâmetro e irá atualizar
                     * a variável do accordion (ctShowHideRender) que irá definir que precisa renderizar
                     * o seu conteúdo.
                     */
                    scope.$on('showHide', function(event, args) {
                        var slide = getSlide();

                        if (args) {
                            if (args.name === (attrs.ctShowHide || attrs.ctShowHideId)) {
                                if (args.show) {
                                    show();
                                } else if (args.hide) {
                                    hide();
                                } else {
                                    toggle();
                                }
                            } else if (args.render !== undefined) {
                                scope.ctShowHideRender = !!args.render;

                                if (scope.ctShowHideRender === false) {
                                    slide.slideUp('fast');
                                    elem.removeClass('active');
                                }

                                scope.$parent.$digest();
                            }
                        }
                    });
                }
            };
        })
        .factory('ShowHide', ['$rootScope', '$timeout', '$document',
            function($rootScope, $timeout, $document) {
                function getParentByChild(element, referenceClass) {
                    var elementParent = element.parentNode;

                    if (element.classList.contains(referenceClass)) {
                        return element;
                    }

                    if (elementParent) {
                        return getParentByChild(elementParent, referenceClass);
                    } else {
                        return undefined;
                    }
                }

                return {
                    show: function(name) {
                        $timeout(function() {
                            $rootScope.$broadcast('showHide', {
                                name: name,
                                show: true
                            });
                        });
                    },
                    hide: function(name) {
                        $timeout(function() {
                            $rootScope.$broadcast('showHide', {
                                name: name,
                                hide: true
                            });
                        });
                    },
                    toggle: function(name) {
                        $timeout(function() {
                            $rootScope.$broadcast('showHide', {
                                name: name
                            });
                        });
                    },
                    toggleAll: function(groupClass) {
                        var elements = $document.getElementsByClassName(groupClass),
                            display = elements[0].style.display;

                        $rootScope.$broadcast('showHide', {
                            render: true
                        });

                        angular.forEach(elements, function(value) {
                            value.style.display = display === 'block' ? 'none' : 'block';
                        });

                    },
                    showAll: function(groupClass) {
                        var elements = $document.getElementsByClassName(groupClass);

                        $rootScope.$broadcast('showHide', {
                            render: true
                        });

                        angular.forEach(elements, function(value) {
                            value.style.display = 'block';
                        });
                    },
                    hideAll: function(groupClass) {
                        var elements = $document.getElementsByClassName(groupClass);

                        angular.forEach(elements, function(value) {
                            value.style.display = 'none';
                        });
                    },
                    showParentByChildElementId: function(elementChildId, referenceClass) {
                        var elementChild = $document.getElementById(elementChildId);

                        if (!elementChild) {
                            $rootScope.$broadcast('showHide', {
                                render: true
                            });
                            elementChild = $document.getElementById(elementChildId);
                        }

                        $timeout(function() {
                            var element = getParentByChild(elementChild, referenceClass);

                            if (element) {
                                element.style.display = 'block';
                                return element;
                            }
                        });
                    }
                };
            }
        ]);
}());

(function(){
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.CadastroMunicipioController', CadastroMunicipioController);

    CadastroMunicipioController.$inject = [
        '$scope',
        '$modalInstance',
        'ui.components.Modal',
        'bfc.Notification',
        'educacao.enderecos.MunicipioService',
        'bfc.Focus',
    ];

    function CadastroMunicipioController($scope, $modalInstance, $modal, bfcNotification, MunicipioService, focus) {
        var vm = this;
        var REQ_TYPE_SAVE = 'save';
        var REQ_TYPE_REMOVE = 'remove';

        vm.municipio = $modalInstance.params.municipio || {};
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd ===  undefined ? true : $modalInstance.params.canSaveAdd;
        vm.editando = _.get($modalInstance.params.municipio, 'id');
        vm.save = save;
        vm.remove = remove;
        vm.windowTitle = '';

        vm.permissions = {
            save: {
                bfPermissions: 'app.enderecos.municipio',
                operation: 'criar'
            },
            update: {
                bfPermissions: 'app.enderecos.municipio',
                operation: 'editar'
            },
            remove: {
                bfPermissions: 'app.enderecos.municipio',
                operation: 'excluir'
            }
        };

        setWindowTitle();

        function save(continuar) {
            vm.municipio.uf = vm.municipio.estado.uf;
            return MunicipioService
                .save(vm.municipio)
                .then(function(data) {
                    vm.municipio = data;
                    handleReqSuccess(continuar,REQ_TYPE_SAVE);
                });
        }

        function remove() {
            return MunicipioService
                .remove(vm.municipio)
                .then(handleReqSuccess(false,REQ_TYPE_REMOVE));
        }

        function handleReqSuccess(continuar, reqType) {
            publishNotification(reqType);
            
            if(continuar) {
                refresh();
            } else {
                closeModal();
            }
        }

        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            vm.municipio = {};
            focus('DescricaoMunicipio');
        }

        function closeModal() {
            $modalInstance.close(vm.municipio);
        }

        function publishNotification(reqType) {
            if(reqType === REQ_TYPE_SAVE) {
                return bfcNotification.publish('Município ' + vm.municipio.nome + ' salvo com sucesso.', 'success');
            }

            return bfcNotification.publish('Município ' + vm.municipio.nome + ' excluído com sucesso.', 'success');
        }

        function setWindowTitle() {
            if(vm.municipio && vm.municipio.id) {
                vm.windowTitle = 'Editando Município';
                return;
            }
            
            vm.windowTitle = 'Adicionando Município';
        }
    }        
})();
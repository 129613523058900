(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .factory('educacao.desempenhoescolar.DesempenhoEscolarNota', DesempenhoEscolarNota);

    DesempenhoEscolarNota.$inject = [
        'educacao.desempenhoescolar.DesempenhoEscolarUtilsService',
        'ITEM_AVALIAVEL_MODO_AVALIACAO',
        'promiseTracker'
    ];

    function DesempenhoEscolarNota(DesempenhoEscolarUtilsService, ITEM_AVALIAVEL_MODO_AVALIACAO, promiseTracker) {

        Nota.from = from;

        return Nota;

        function Nota(enturmacao, itemEducacional, periodoAvaliativo, itensAvaliaveis) {
            var self = this;

            this.$$nocache = _.uniqueId();
            this.$$tracker = promiseTracker();
            this.$$abonado = false;
            this.$$semNota = false;
            this.$$editavel = true;
            this.$$permiteCha = true;
            this.$$permiteParecer = true;

            this.enturmacao = enturmacao;
            this.filhos = [];

            this.$$nota = null; //sort
            this.notaNumerica = null;
            this.notaConceito = null;
            this.notaDescritiva = null;

            this.updateFields = updateFields;

            if (itemEducacional && periodoAvaliativo) {
                this.avaliavel = _.find(itensAvaliaveis, function (itemAvaliavel) {
                    //Busca item avaliável correto para o item educacional e período passado
                    return (itemAvaliavel.itemEducacional ? itemAvaliavel.itemEducacional.id : itemAvaliavel.id) === itemEducacional.id && (
                        _.get(itemAvaliavel, 'idPeriodo') === periodoAvaliativo.id
                    );
                });
            }

            updateFields();

            //Atualiza alguns dados do registro de avaliação
            function updateFields() {

                var modoAvaliacao = _.get(enturmacao, 'matricula.avaliacaoDescritiva') ? ITEM_AVALIAVEL_MODO_AVALIACAO.DESCRITIVO :
                    (self.avaliavel ? _.get(self.avaliavel, 'modoAvaliacao') : '');

                self.$$nota = (_.isNumber(self.notaNumerica) ? self.notaNumerica : (self.notaConceito || self.notaDescritiva));
                var dataFinal = _.get(self, 'avaliavel.periodoAvaliativo.dataFinal');
                self.$$semNota = (_.isNull(self.$$nota) || _.isUndefined(self.$$nota)) && dataFinal && moment().isAfter(dataFinal, 'day');
                self.$$editavel = DesempenhoEscolarUtilsService.isItemEditavel(_.get(self.avaliavel, 'tipoPeriodo'), modoAvaliacao);
                self.$$permiteCha = DesempenhoEscolarUtilsService.isPermiteCha(_.get(self.avaliavel, 'tipoPeriodo'));
                self.$$permiteParecer = modoAvaliacao && DesempenhoEscolarUtilsService.isPermiteParecer(_.get(self.avaliavel, 'tipoPeriodo'));
            }
        }

        function from(base, enturmacoes, itensAvaliaveis) {
            var nota = new Nota();

            nota.avaliavel = _.find(itensAvaliaveis, 'id', base.avaliavel.id);
            nota.enturmacao = _.find(enturmacoes, 'id', base.enturmacao.id);

            nota.id = base.id;
            nota.notaNumerica = base.notaNumerica;
            nota.notaConceito = base.notaConceito;
            nota.notaDescritiva = base.notaDescritiva;
            nota.parecer = base.parecer;
            nota.hasNotaFilhosPreenchido = base.hasNotaFilhosPreenchido;
            nota.filhos = base.filhos;

            nota.updateFields();

            return nota;
        }
    }
})();
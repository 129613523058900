(function () {
    'use strict';

    var templateNome = 'nome like "<%= term %>"';
    var templateEstabelecimento = ' and estabelecimento=<%= estabelecimento%>';
    var templateDisponivel = ' and disponivel=<%= disponivel%>';

    angular.module('educacao.funcionario')
        .directive('appSelectDependenciaFisica', directive);

    function directive() {
        return {
            template: '<ui-select search="vm.listaDependenciaFisica" ng-model="ngModel" ng-disabled="ngDisabled" dropdown-css-class="dropdownCssClass"></ui-select>',

            scope: {
                ngModel: '=',
                ngDisabled: '=',

                dropdownCssClass: '@',

                estabelecimento: '=',
                disponivel: '='
            },

            compile: setMultiple,

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    function setMultiple(element, attr) {
        if (attr.hasOwnProperty('multiple')) {
            var select = element.find('ui-select').attr('multiple', '');
            if (attr.hasOwnProperty('ngRequired')) {
                select.attr('not-empty', 'ngRequired');
            }
        }
    }

    Controller.$inject = ['$scope', 'educacao.pessoas.DependFisicaService'];
    function Controller($scope, DependenciaFisicaService) {
        var templateBusca;
        var vm = this;

        vm.listaDependenciaFisica = listaDependenciaFisica;

        $scope.$watchGroup(['estabelecimento', 'disponivel'], function () {
            var query = templateNome;

            if (!_.isUndefined($scope.estabelecimento)) {
                query += templateEstabelecimento;
            }
            if (!_.isUndefined($scope.disponivel)) {
                query += templateDisponivel;
            }

            templateBusca = _.template(query);
        });

        function listaDependenciaFisica(params) {
            params.estabelecimento = $scope.estabelecimento;
            params.disponivel = $scope.disponivel;
            return DependenciaFisicaService.getList(params.offset, params.limit, templateBusca(params)).then(_.first);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.agendaprofessor')
        .config(AgendaStates);

    AgendaStates.$inject = ['$stateProvider'];

    function AgendaStates($stateProvider) {
        $stateProvider.state('app.agendaprofessor', {
            url: 'agenda',
            data: {
                id: 'AgendaProfessorPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'agenda-professor/agenda-professor.html',
                    controller: 'educacao.agendaprofessor.AgendaProfessorCtrl',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
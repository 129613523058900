(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .service('educacao.desempenhoescolar.DesempenhoEscolarItemViewService', DesempenhoEscolarItemViewService);

    DesempenhoEscolarItemViewService.$inject = [
        'educacao.desempenhoescolar.DesempenhoEscolarNota',
        'ITEM_AVALIAVEL_TIPO_PERIODO',
        'educacao.desempenhoescolar.DesempenhoEscolarUtilsService'
    ];

    function DesempenhoEscolarItemViewService(
        Nota,
        ITEM_AVALIAVEL_TIPO_PERIODO,
        DesempenhoEscolarUtilsService
    ) {

        var TIPO_ITEM_PERIODO_AVALIATIVO = 'PERIODO_AVALIATIVO';

        var self = this;

        self.createItemView = createItemView;

        function createItemView(registros, itensEducacionais, itensAvaliaveis, periodo, enturmacoes) {

            var avaliacoes = registros.avaliacoes;
            var abonos = registros.abonos;

            var linhas, colunas;

            avaliacoes = _.filter(avaliacoes, function (nota) {
                return _.get(nota, 'avaliavel.idPeriodo') === periodo.id && _.get(nota, 'avaliavel.tipoPeriodo') !== 'INSTRUMENTO' && _.get(nota, 'avaliavel.tipoPeriodo') !==  'CALCULO_INSTRUMENTOS';
            });

            var mapAvaliacoesEnturmacaoItemEducacional = {};
            _.forEach(avaliacoes, function (avaliacao) {
                mapAvaliacoesEnturmacaoItemEducacional[getAvaliacaoKey(avaliacao)] = Nota.from(avaliacao, enturmacoes, itensAvaliaveis);
            });

            var mapAbonoPeriodo = {};
            var mapAbonoItemEducacional = {};
            _.forEach(abonos, function (abono) {
                mapAbonoPeriodo[abono.enturmacao.id] = mapAbonoPeriodo[abono.enturmacao.id] ||
                    abono.itemEducacional.tipo === TIPO_ITEM_PERIODO_AVALIATIVO;

                mapAbonoItemEducacional[getKeyAbono(abono)] = true;
            });

            colunas = itensEducacionais;
            linhas = [];

            _.forEach(enturmacoes, function (aluno) {
                var linha = {
                    enturmacao: aluno,
                    notas: []
                };
                linhas.push(linha);

                //Cria célula para cada item educacional e enturmação
                _.forEach(itensEducacionais, function (itemEducacional, index) {
                    var key = String(aluno.id) + itemEducacional.id;
                    var nota = mapAvaliacoesEnturmacaoItemEducacional[key] || new Nota(aluno, itemEducacional, periodo, itensAvaliaveis);

                    if(_.get(aluno, 'matricula.avaliacaoDescritiva')){
                        nota.$$editavel = DesempenhoEscolarUtilsService.isItemEditavel(_.get(nota.avaliavel, 'tipoPeriodo'), 'DESCRITIVO');
                    }

                    nota.$$abonado = mapAbonoPeriodo[aluno.id] || mapAbonoItemEducacional[key + periodo.id];

                    linha.notas[index] = nota;
                });
            });

            return {
                colunas: colunas,
                linhas: linhas
            };

            function getAvaliacaoKey(avaliacao) {
                return String(avaliacao.enturmacao.id) + avaliacao.avaliavel.itemEducacional.id;
            }

            function getKeyAbono(abono) {
                return String(abono.enturmacao.id) + abono.itemEducacional.id +
                    (_.get(abono.periodoAvaliativo, 'id') || ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO);
            }
        }
    }

})();
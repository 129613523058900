(function () {

    'use strict';

    angular.module('educacao.common')
        .filter('appNumber', filter);

    function filter() {

        return formatNumber;

        function formatNumber(input, decimalSeparator, fractionSize) {

            if (_.isNull(input) || _.isUndefined(input)) {
                return input;
            }

            var formattedNumber = input + '';

            if (decimalSeparator) {
                formattedNumber = formattedNumber.replace(/\./g, decimalSeparator);
            } else {
                decimalSeparator = '.';
            }

            if (fractionSize) {

                var splitNumber = formattedNumber.split(decimalSeparator);

                if (splitNumber.length > 1) {

                    var decimals = splitNumber[1];

                    if (fractionSize < decimals.length) {
                        decimals = decimals.splice(0, fractionSize);
                    } else {
                        var difSize = fractionSize - decimals.length;
                        decimals += _.repeat('0', difSize);
                    }

                    formattedNumber = splitNumber[0] + (decimals.length ? decimalSeparator : '') + decimals;
                } else if(fractionSize > 0){
                    formattedNumber = formattedNumber + decimalSeparator + _.repeat('0', fractionSize);
                }
            }
            return formattedNumber;
        }

    }

})();


(function () {
    'use strict';

    angular.module('educacao.calendario')
        .directive('appCardPowernumber', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                value: '=',
                description: '=',
                iconClass: '='
            },
            templateUrl: 'calendario/calendario-matriz/registros-vinculados/detalhe-registro-vinculado/app-card-powernumber.directive.html',
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {}
})();

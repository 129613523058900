(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('appSelectTabQuadroInformativo', directive);

    directive.$inject = [];


    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/app-select-tab/app-select-tab-quadro-informativo.directive.html',
            scope: {},
            bindToController: {
                tabs: '=',
                tipoSelecionado: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;
        vm.toggle = toggle;
        vm.scrollNext = scrollNext;
        vm.scrollBack = scrollBack;
        vm.toleranciaScroll = 0.5;
        vm.currentTab = 1;
        $scope.$watch('vm.tipoSelecionado', onChangeTab);

        function getTipoSelecionadoPeloIndex() {
            var tab = _.find(vm.tabs, {index: vm.currentTab});
            return tab ? tab.tipo : null;
        }

        function onChangeTab(newValue) {
            if (_.get(newValue, 'index')) {
                vm.currentTab = _.get(newValue, 'index');
            }
        }

        function toggle(tipo) {
            vm.tipoSelecionado = tipo;
        }

        function scrollNext() {
            if (!hasAtingidoLimiteMaximo()) {
                vm.currentTab++;
                vm.tipoSelecionado = getTipoSelecionadoPeloIndex();
                scrollTabs('right');
            }
        }

        function scrollBack() {
            if (!hasAtingidoLimiteMinimo()) {
                vm.currentTab--;
                vm.tipoSelecionado = getTipoSelecionadoPeloIndex();
                scrollTabs('left');
            }
        }

        function hasAtingidoLimiteMaximo() {
            return _.max(vm.tabs, 'index').index === vm.currentTab;
        }

        function hasAtingidoLimiteMinimo() {
            return vm.currentTab === 1;
        }

        function scrollTabs(direction) {
            var primeiroItemTab = $('.nav-tabs li:first');
            var containerTab = $('.nav-tabs ');
            var larguraItem = primeiroItemTab.outerWidth(true);
            var larguraContainer = containerTab.outerWidth(true);

            var numItensVisiveis = Math.floor(larguraContainer / larguraItem);
            var distanciaDoScroll = (larguraItem * numItensVisiveis * vm.toleranciaScroll);

            if (direction === 'left') {
                containerTab.animate({scrollLeft: '-=' + distanciaDoScroll}, 100);
            } else if (direction === 'right') {
                containerTab.animate({scrollLeft: '+=' + distanciaDoScroll}, 100);
            }
        }
    }
})();

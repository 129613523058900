(function () {
    'use strict';

    var TIPO_DISCIPLINA_EJA = 'DISCIPLINA_EJA_MODULAR';
    var TIPO_MODULO = 'DISCIPLINA_EJA_MODULAR_MODULO';

    angular.module('educacao.matricula')
        .directive('appTabTurmaDisciplinaEjaModular', directive);

    function directive() {
        return {
            templateUrl: 'matricula/turma/cadastro/tab-turma-disciplina-eja-modular.directive.html',
            scope: {turma: '='},
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        '$filter',
        'promiseTracker',
        'ui.components.Modal',
        'educacao.matriz.EtapaDisciplinaService'
    ];

    function Controller($scope, $filter, promiseTracker, uiModal, EtapaDisciplinaService) {
        var orderBy = $filter('orderBy');

        var vm = this;

        vm.loadingTracker = promiseTracker();

        vm.listaTurmaDisciplinasEja = [];
        vm.listaPorOrientacao = [];

        vm.selecionaTodos = selecionaTodos;
        vm.atualizaListaFilhos = atualizaListaFilhos;
        vm.abreModuloAulas = abreModuloAulas;
        vm.applyDependenciaFisicaToAll = applyDependenciaFisicaToAll;
        
        $scope.$watchGroup(['turma.matriz', 'turma.etapaMatriz'], loadDisciplinas);
        $scope.$watch('turma.estabelecimento', limpaDependenciasFisicas);
        
        function loadDisciplinas() {
            vm.loadingTracker.addPromise(
                EtapaDisciplinaService
                    .getList($scope.turma.matriz.id, $scope.turma.etapaMatriz.id)
                    .then(converteEtapaMatrizDisciplinaTurmaDisciplina)
                    .then(agrupaPorOrientacaoCurricular)
            );
        }

        function converteEtapaMatrizDisciplinaTurmaDisciplina(listaEtapaMatrizDisciplinas) {
            var listaTurmaDisciplinasEja = _.cloneDeep($scope.turma.filhos);
            var etapaJaPersistidas = _.mapKeys(listaTurmaDisciplinasEja, 'etapaMatrizDisciplina.id');

            vm.isTodosSelecionados = true;

            _.forEach(listaEtapaMatrizDisciplinas, function (etapaMatrizDisciplina) {
                var turmaDisciplinaEja = etapaJaPersistidas[etapaMatrizDisciplina.id];

                if (turmaDisciplinaEja) {
                    turmaDisciplinaEja.$$selected = true;
                } else {
                    vm.isTodosSelecionados = false;
                    turmaDisciplinaEja = {
                        $$selected: false,
                        tipo: TIPO_DISCIPLINA_EJA,
                        etapaMatrizDisciplina: etapaMatrizDisciplina
                    };
                    listaTurmaDisciplinasEja.push(turmaDisciplinaEja);
                }

                turmaDisciplinaEja.$$label = getDescricaoEtapaMatrizDisciplina(etapaMatrizDisciplina);
                turmaDisciplinaEja.$$tooltip = getTooltipEtapaMatrizDisciplina(etapaMatrizDisciplina);
                turmaDisciplinaEja.$$orientacaoCurricular = etapaMatrizDisciplina.orientacaoCurricular;
                
                if( !_.get(turmaDisciplinaEja, 'filhos', false)){ 
                    turmaDisciplinaEja.filhos = [];
                    _.times(etapaMatrizDisciplina.quantidadeModulos, function (index) {
                        turmaDisciplinaEja.filhos.push({
                            numeroModulo: index + 1,
                            tipo: TIPO_MODULO, 
                            quantidadeAulas: undefined
                        });
                    });
                }
            });
            
            vm.listaTurmaDisciplinasEja = $scope.turma.filhos = orderBy(listaTurmaDisciplinasEja, 
                    ['etapaMatrizDisciplina.disciplina.descricao', 'etapaMatrizDisciplina.eixoTematico.descricao']);
            atualizaListaFilhos();
            
            return vm.listTurmaDisciplinas;
        }

        function getDescricaoEtapaMatrizDisciplina(etapaMatrizDisciplina) {
            return (etapaMatrizDisciplina.disciplina || etapaMatrizDisciplina.eixoTematico).descricao;
        }

        function getTooltipEtapaMatrizDisciplina(etapaMatrizDisciplina) {
            return etapaMatrizDisciplina.disciplina &&
                etapaMatrizDisciplina.eixoTematico &&
                etapaMatrizDisciplina.eixoTematico.descricao;
        }

        function agrupaPorOrientacaoCurricular() {
            var orientacoesId = {};

            _.forEach(vm.listaTurmaDisciplinasEja, function (divisao) {
                var orientacao = orientacoesId[divisao.$$orientacaoCurricular.id];
                if (_.isUndefined(orientacao) || !orientacao) {
                    orientacao = _.clone(divisao.$$orientacaoCurricular);
                    orientacao.filhos = [];
                    orientacoesId[orientacao.id] = orientacao;
                }
                orientacao.filhos.push(divisao);
            });
            vm.listaPorOrientacao = _.values(orientacoesId);
            return _.values(orientacoesId);
        }

        function limpaDependenciasFisicas(newValue, oldValue) {
            if (newValue !== oldValue) {
                _.forEach($scope.turma.filhos, function (atividade) {
                    delete atividade.dependenciaFisica;
                });
            }
        }

        function selecionaTodos() {
            _.forEach(vm.listaTurmaDisciplinasEja, function (turmaDisciplina) {
                turmaDisciplina.$$selected = vm.isTodosSelecionados;
            });
            atualizaListaFilhos();
        }

        function atualizaListaFilhos() {
            $scope.turma.filhos = _.filter(vm.listaTurmaDisciplinasEja, '$$selected', true);
            vm.isTodosSelecionados = $scope.turma.filhos.length === vm.listaTurmaDisciplinasEja.length;
        }

        function abreModuloAulas(disciplina) {
            uiModal.open({
                templateUrl: 'matricula/turma/cadastro/modulos-turma-eja.html',
                size: 'xs',

                controller: 'educacao.matricula.ModulosTurmaEjaController',
                controllerAs: 'vm',

                params: {
                    moduloQuantidadeAulas: angular.copy(disciplina.filhos) || disciplina.moduloQuantidadeAulas
                }
            }).result.then(function (moduloQuantidadeAulas) {
                disciplina.filhos = moduloQuantidadeAulas;
                atualizaListaFilhos();
            });
        }

        function applyDependenciaFisicaToAll() {
            _.forEach(vm.listaPorOrientacao, function(orientacao) {
                _.forEach(orientacao.filhos, setDependenciaFisica);
            });
        }

        function setDependenciaFisica(disciplina) {
            if(disciplina.$$selected) {
                disciplina.dependenciaFisica = vm.applyAllDependenciaFisica;
            }
        }
    }
})();

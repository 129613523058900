(function () {
    'use strict';

    angular
    .module('educacao.pessoas')
    .directive('appFiltroAvancadoEscola', Directive);

    function Directive() {
      return {
        restrict: 'E',
        templateUrl:
         'planejamento/escolas/filtro-avancado/filtro-avancado-escola.directive.html',
        controller: Controller,
        controllerAs: 'vm',
        scope: {
          expressionCustomBuilders: '=',
          advancedFilter: '=',
          filterCounter: '=',
        },
        bindToController: true
      };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
      var vm = this;

      vm.limparFiltroEscolas = limparFiltroEscolas;

      function limparFiltroEscolas(){
        vm.advancedFilter.informacoesGerais = {};
        vm.advancedFilter.areaAtuacao = {};
        vm.advancedFilter.infraestrutura = {};
        vm.advancedFilter.dependenciasFisicas = {};
        vm.advancedFilter.equipeDiretiva = {};

        vm.contadorInformacoesGerais = 0;
        vm.contadorAreaAtuacao = 0;
        vm.contadorInfraestrutura = 0;
        vm.contadorDependencias = 0;
        vm.contadorEquipeDiretiva = 0;

        contador();
      }

      $scope.$watchGroup([
        'vm.contadorInformacoesGerais',
        'vm.contadorAreaAtuacao',
        'vm.contadorInfraestrutura',
        'vm.contadorDependencias',
        'vm.contadorEquipeDiretiva'
      ], contador);

      function contador() {
        vm.somadorContadorEscola = 0;

        if (vm.contadorInformacoesGerais) {
          vm.somadorContadorEscola += vm.contadorInformacoesGerais;
        }

        if (vm.contadorAreaAtuacao) {
          vm.somadorContadorEscola += vm.contadorAreaAtuacao;
        }

        if (vm.contadorInfraestrutura) {
          vm.somadorContadorEscola += vm.contadorInfraestrutura;
        }

        if (vm.contadorDependencias) {
          vm.somadorContadorEscola += vm.contadorDependencias;
        }

        if (vm.contadorEquipeDiretiva) {
          vm.somadorContadorEscola += vm.contadorEquipeDiretiva;
        }

        vm.filterCounter = vm.somadorContadorEscola;
      }
    }
})();
(function () {

    'use strict';

    angular.module('educacao.common').directive('appSelectClassificacaoTipoCargoInep', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.common.ClassificacaoTipoCargoInep'];

    function Controller(ClassificacaoTipoCargoInep) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = 'descricao like "' + params.term + '"';

            return ClassificacaoTipoCargoInep.getList(params.offset, params.limit, filter);
        }

        function formatResult(data) {
            return data.descricao;
        }
    }

})();
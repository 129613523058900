(function() {
	'use strict';
	angular.module('educacao.matriz')
		.factory('educacao.matriz.ConfiguracoesRepositorio',['Restangular', 'educacao.common.ObjectUtilsService', function(Restangular, ObjectUtilsService) {

			return {

				getOrientacaoCurricular: function(){
					return Restangular.one('matriz').all('configuracoes/orientacao-curricular').getList();
				},

				getNivelModalidade: function(){
					return Restangular.one('matriz').all('configuracoes/nivel-modalidade').getList();
				},

				getNivelEscolar: function(){
					return Restangular.one('matriz').all('configuracoes/nivel-escolar').getList();
				},

				getOrganizacaoNivel: function(){
					return Restangular.one('matriz').all('configuracoes/organizacao-nivel').getList();
				},
				
				getAbrangenciaFeriados: function(){
					return Restangular.one('matriz').all('configuracoes/abrangencia-feriados').getList();
				},
				
				filtraByDescricao: function(lista, descricao) {
					return ObjectUtilsService.filterByFieldWithSort(lista, 'descricao', descricao);
				}
			};


		}])
		
		.factory('educacao.matriz.OrientacaoCurricularSelect',[
		      'educacao.matriz.ConfiguracoesRepositorio',
		      'bfc.Select2Config',
		      function(ConfiguracoesRepositorio, Select2Config){
		    	  
		    	  var items = {};
		    	  
		    	  ConfiguracoesRepositorio.getOrientacaoCurricular().then(function(data){
		    		  items = data;  
		    	  });
			
		    	  return {
		    		  select2Config: function(options){
		    			  return Select2Config.create(angular.extend({
								
								result: function(params, callback) {
									callback({
										results: ConfiguracoesRepositorio.filtraByDescricao(items, params.term),
										more: false
									});
								},
								formatValue: function(data) {
									return data.id;
								},
								formatResult: function(data) {
									return data.text ?
										data.text : data.descricao;
								},
								formatSelection: function(data) {
									return  data.descricao;
								},
								initSelection: function(element, callback) {
									var id = $(element).val();

									if (id) {
										var item = _.find(items, {'id': Number(id)});
										if(item){
											callback(item);
										}else{
											callback({
												id: null
											});
										}
									}
								}
							}, options));
		    		  }
		    	  };
		}])

		
	      
	      .factory('educacao.matriz.OrganizacaoNivelSelect',[
	     'educacao.matriz.ConfiguracoesRepositorio',
	     'bfc.Select2Config',
	     function(ConfiguracoesRepositorio, Select2Config){
	    	 
	    	 return {
	    		 select2Config: function(options){
	    			 var organizacaoItems = {};
	    			 
	    			 ConfiguracoesRepositorio.getOrganizacaoNivel().then(function(data){
	    				 organizacaoItems = data;
	    			 });
	    			 
	    			 return Select2Config.create(angular.extend({
	    				 
	    				 
	    				 result: function(params, callback) {
							 callback({
								 results: ConfiguracoesRepositorio.filtraByDescricao(organizacaoItems, params.term),
								 more: false
							 });
	    				 },
	    				 formatValue: function(data) {
	    					 return data;
	    				 },
	    				 formatResult: function(data) {
	    					 return data.text ?
	    							 data.text : data.descricao;
	    				 },
	    				 formatSelection: function(data) {
	    					 return  data.descricao;
	    				 },
	    				 initSelection: function() { }
	    			 }, options));
	    		 }
	    	 };
	     }]);
})();
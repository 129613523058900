(function(){

    'use strict';

    angular.module('educacao.matriz')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.matriz.curso';

        $stateProvider.state(STATE, {
            url: 'cursos',
            data: {
                id: 'CursoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'planejamento/matriz/curso/cursos.html',
                    controller: 'educacao.matriz.CursosCtrl as vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });
        
        openModal.$inject = [
            '$stateParams',
            'educacao.matriz.CursoService',
            '$modalStates'
        ];

        function openModal($stateParams, CursoService, $modalStates) {
            $modalStates.open({
                getMethod: CursoService.getById,
                templateUrl: 'planejamento/matriz/curso/cursos.cad.html',
                controller: 'educacao.matriz.CursoCadCtrl as vm',
                propertyName: 'curso',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }

})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ConfiguracoesMatriculaCadCtrl', Controller);

    Controller.$inject = [
        '$q',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        '$modalInstance',
        'promiseTracker',
        'educacao.matricula.ConfiguracoesMatriculaService'
    ];

    function Controller($q,
        ContextoEstabelecimento,
        ContextoAnoLetivo,
        $modalInstance,
        promiseTracker,
        ConfiguracoesMatriculaService) {
        var vm = this,
            removeConfiguracao = false;
        ativaTabsIniciais();
        inicializaVariaveis();
        vm.save = save;
        vm.tabsConfiguracaoMatricula = tabsConfiguracaoMatricula();
        vm.tracker = {
            loadingBottons: promiseTracker(),
            loadingTab: promiseTracker()
        };
        vm.loadingTela = promiseTracker();
        vm.fechaModal = fechaModal;
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.resetConfiguracao = resetConfiguracao;
        vm.buscaConfiguracaoTabSelecionada = buscaConfiguracaoTabSelecionada;
        var enumListTiposMatriculas = {
            'EDUCACAO_BASICA': vm.listaEducacaoBasica = [],
            'EJA': vm.listaEJA = [],
            'ACELERACAO': vm.listaAceleracao = [],
            'COMPLEMENTAR': vm.listaComplementar = []
        };
        var enumfuncoesSetConfiguracao = {
            'EDUCACAO_BASICA': setConfiguracaoEducaoBasica,
            'EJA': setConfiguracaoEJA,
            'ACELERACAO': setConfiguracaoAceleracao,
            'COMPLEMENTAR': setConfiguracaoComplementar
        };
        ConfiguracoesMatriculaService.getEquivalenciaEtapa().then(function(value) {
            vm.enumEtapas = value;
        });

        init();

        function init() {
            $modalInstance.tracker.addPromise(
                $q.when(
                    ConfiguracoesMatriculaService.getConfiguracao(vm.hasEstabelecimento)
                    .then(function(data) {
                        $q.when(
                            setConfiguracao(data)
                        );
                    })
                )
            );
        }

        function geraDefaultPraSalvar(nivelModalidade, nivelEscolar) {
            var objetoDefault = {
                documentos: [],
                nivelEscolar: nivelEscolar,
                nivelModalidade: nivelModalidade,
                permiteMatriculaProgressaoParcial: false,
                permiteDivisaoTurnoIntegral: false,
                criarNovaMatriculaRemanejamento: false,
                removerListaEspera: false,
                respeitarIdadeMinima: false,
                restringeAreaAbrangencia: false,
                permiteMultiplasMatriculas: false
            };
            objetoDefault.documentos.push({
                nivelModalidade: nivelModalidade,
                nivelEscolar: nivelEscolar,
                equivalencias: [],
                obrigatorio: false
            });
            return objetoDefault;
        }

        function setConfiguracao(data, nivelModalidade) {
            nivelModalidade = nivelModalidade || 'EDUCACAO_BASICA';
            vm.tipoMatricula = nivelModalidade;
            if (data) {
                angular.copy(data, enumListTiposMatriculas[nivelModalidade]);
            }
            enumfuncoesSetConfiguracao[nivelModalidade](nivelModalidade);
        }

        function setConfiguracaoEducaoBasica(nivelModalidade) {
            vm.basicaFoiAoServidor = true;
            angular.forEach(vm.listaEducacaoBasica, function(configuracao) {
                if (vm.hasEstabelecimento && configuracao.estabelecimento === null) {
                    configuracao.id = null;
                    configuracao.permiteMultiplasMatriculas = false;
                }
                if (isEducacaoInfantil(configuracao)) {
                    vm.educacaoBasicaInfantil = configuracao;
                    vm.documentosEdBasicaEnsinoInfantil = _.clone(configuracao.documentos);
                    formatEtapasEquivalenciaPraEditar(vm.documentosEdBasicaEnsinoInfantil);
                }
                if (isEnsinoMedio(configuracao)) {
                    vm.educacaoBasicaMedio = configuracao;
                    vm.documentosEdBasicaEducacaoMedio = _.clone(configuracao.documentos);
                    formatEtapasEquivalenciaPraEditar(vm.documentosEdBasicaEducacaoMedio);
                }
                if (isEnsinoFundamental(configuracao)) {
                    vm.educacaoBasicaFundamental = configuracao;
                    vm.documentosEdBasicaEducacaoFundamental = _.clone(configuracao.documentos);
                    formatEtapasEquivalenciaPraEditar(vm.documentosEdBasicaEducacaoFundamental);
                }
            });
            if (!_.get(vm.educacaoBasicaFundamental, 'documentos')) {
                vm.educacaoBasicaFundamental = geraDefaultPraSalvar(nivelModalidade, 'ENSINO_FUNDAMENTAL');
            }
            if (!_.get(vm.educacaoBasicaMedio, 'documentos')) {
                vm.educacaoBasicaMedio = geraDefaultPraSalvar(nivelModalidade, 'ENSINO_MEDIO');
            }
            if (!_.get(vm.educacaoBasicaInfantil, 'documentos')) {
                vm.educacaoBasicaInfantil = geraDefaultPraSalvar(nivelModalidade, 'EDUCACAO_INFANTIL');
            }

        }

        function setConfiguracaoAceleracao(nivelModalidade) {
            vm.aceleracaoFoiAoServidor = true;
            angular.forEach(vm.listaAceleracao, function(configuracao) {
                if (vm.hasEstabelecimento && configuracao.estabelecimento === null) {
                    configuracao.id = null;
                }
                if (isEnsinoMedio(configuracao)) {
                    vm.aceleracaoEnsinoMedio = configuracao;
                    vm.documentosAceleracaoEnsinoMedio = configuracao.documentos;
                    formatEtapasEquivalenciaPraEditar(vm.documentosAceleracaoEnsinoMedio);
                }
                if (isEnsinoFundamental(configuracao)) {
                    vm.aceleracaoEnsinoFundamental = configuracao;
                    vm.documentosAceleracaoEnsinoFundamental = configuracao.documentos;
                    formatEtapasEquivalenciaPraEditar(vm.documentosAceleracaoEnsinoFundamental);
                }
            });
            if (!_.get(vm.aceleracaoEnsinoMedio, 'documentos')) {
                vm.aceleracaoEnsinoMedio = geraDefaultPraSalvar(nivelModalidade, 'ENSINO_MEDIO');
            }
            if (!_.get(vm.aceleracaoEnsinoFundamental, 'documentos')) {
                vm.aceleracaoEnsinoFundamental = geraDefaultPraSalvar(nivelModalidade, 'ENSINO_FUNDAMENTAL');
            }
        }

        function setConfiguracaoEJA(nivelModalidade) {
            vm.ejaFoiAoServidor = true;
            angular.forEach(vm.listaEJA, function(configuracao) {
                if (vm.hasEstabelecimento && configuracao.estabelecimento === null) {
                    configuracao.id = null;
                }
                if (isEnsinoMedio(configuracao)) {
                    vm.ejaEnsinoMedio = configuracao;
                    vm.documentosEjaEnsinoMedio = configuracao.documentos;
                    formatEtapasEquivalenciaPraEditar(vm.documentosEjaEnsinoMedio);
                }
                if (isEnsinoFundamental(configuracao)) {
                    vm.ejaEnsinoFundamental = configuracao;
                    vm.documentosEjaEnsinoFundamental = configuracao.documentos;
                    formatEtapasEquivalenciaPraEditar(vm.documentosEjaEnsinoFundamental);

                }
            });
            if (!_.get(vm.ejaEnsinoMedio, 'documentos')) {
                vm.ejaEnsinoMedio = geraDefaultPraSalvar(nivelModalidade, 'ENSINO_MEDIO');
            }
            if (!_.get(vm.ejaEnsinoFundamental, 'documentos')) {
                vm.ejaEnsinoFundamental = geraDefaultPraSalvar(nivelModalidade, 'ENSINO_FUNDAMENTAL');
            }
        }

        function setConfiguracaoComplementar(nivelModalidade) {
            vm.complementarFoiAoServidor = true;
            angular.forEach(vm.listaComplementar, function(configuracao) {
                if (vm.hasEstabelecimento && configuracao.estabelecimento === null) {
                    configuracao.id = null;
                }
                vm.configuracaoComplementar = configuracao;
                vm.documentosConfiguracaoComplementar = configuracao.documentos;
                formatEtapasEquivalenciaPraEditar(vm.documentosConfiguracaoComplementar);
            });
            if (!vm.configuracaoComplementar) {
                vm.configuracaoComplementar = geraDefaultPraSalvar(nivelModalidade, null);
            }
        }

        function buscaConfiguracaoTabSelecionada(modalidade) {
            vm.tipoMatricula = modalidade;
            if (verificaSeJaFoiFeitaABusca(modalidade)) {
                return;
            }
            if (removeConfiguracao) {
                vm.tracker.loadingTab.addPromise(
                    $q.when(ConfiguracoesMatriculaService.getConfiguracaoSecretaria(modalidade).then(
                        function(data) {
                            setConfiguracao(data, modalidade);
                        }
                    ))
                );
            } else {
                vm.tracker.loadingTab.addPromise(
                    $q.when(ConfiguracoesMatriculaService.getConfiguracao(vm.hasEstabelecimento, modalidade).then(
                        function(data) {
                            setConfiguracao(data, modalidade);
                        }
                    ))
                );
            }
        }

        function verificaSeJaFoiFeitaABusca(modalidade) {
            if (isModalidadeEja(modalidade)) {
                return vm.ejaFoiAoServidor;
            }
            if (isModalidadeComplementar(modalidade)) {
                return vm.complementarFoiAoServidor;
            }
            if (isModalidadeAceleracao(modalidade)) {
                return vm.aceleracaoFoiAoServidor;
            }
            if (isModalidadeEducacaoBasica(modalidade)) {
                return vm.basicaFoiAoServidor;
            }
        }

        function isModalidadeEja(modalidade) {
            return modalidade === 'EJA';
        }

        function isModalidadeComplementar(modalidade) {
            return modalidade === 'COMPLEMENTAR';
        }

        function isModalidadeAceleracao(modalidade) {
            return modalidade === 'ACELERACAO';
        }

        function isModalidadeEducacaoBasica(modalidade) {
            return modalidade === 'EDUCACAO_BASICA';
        }

        function resetConfiguracao() {
            removeConfiguracao = true;
            inicializaVariaveis();
            vm.loadingTela.addPromise(
                $q.when(
                    ConfiguracoesMatriculaService.getConfiguracaoSecretaria(vm.tipoMatricula)
                    .then(function(data) {
                        setConfiguracao(data, vm.tipoMatricula);
                    })
                )
            );
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function save() {
            var lista = montaListaPraSalvar();
            if (removeConfiguracao) {
                vm.tracker.loadingBottons.addPromise(
                    ConfiguracoesMatriculaService.removeConfiguracaoEstabelecimento(vm.estabelecimento.id).then(function() {
                        ConfiguracoesMatriculaService.atualizarMatriculas(lista).then(fechaModal);
                    })
                );
            } else {
                vm.tracker.loadingBottons.addPromise(
                    ConfiguracoesMatriculaService.atualizarMatriculas(lista).then(fechaModal)
                );
            }
        }

        function verificaListaEnsinoInfantil(documentosAFiltrar, objeto) {
            var listaFiltrada = [];
            _.forEach(documentosAFiltrar, function(documento) {
                if (!_.isEmpty(documento.tipoDocumento)) {
                    listaFiltrada.push(documento);
                }
            });
            angular.copy(listaFiltrada, objeto.documentos);
        }

        function preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(documentos, objeto) {
            var listaFiltrada = [];
            var copyDocumentos = [];

            angular.copy(documentos, copyDocumentos);
            _.forEach(copyDocumentos, function(documento) {
                if (!_.isEmpty(documento.tipoDocumento)) {
                    if (!_.isEmpty(documento.equivalencias)) {
                        var idsEtapas = editEtapasEquivalenciasToSave(documento);
                        angular.copy(idsEtapas, documento.equivalencias);
                    }

                    listaFiltrada.push(documento);
                }
            });

            objeto.documentos = [];
            angular.copy(listaFiltrada, objeto.documentos);
        }

        function preparaDocumentosComplementarPraSalvar(documentos, objeto) {
            var listaFiltrada = [];
            var copyDocumentos = [];
            angular.copy(documentos, copyDocumentos);
            _.forEach(copyDocumentos, function(documento) {
                if (!_.isEmpty(documento.tipoDocumento)) {
                    var idsEtapas = editEtapasEquivalenciasToSave(documento);
                    angular.copy(idsEtapas, documento.equivalencias);
                    listaFiltrada.push(documento);
                }
            });
            objeto.documentos = [];
            angular.copy(listaFiltrada, objeto.documentos);
        }

        function montaListaPraSalvar() {
            var listaPraSalvar = [];

            if (vm.educacaoBasicaMedio) {
                preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(vm.documentosEdBasicaEducacaoMedio, vm.educacaoBasicaMedio);
                listaPraSalvar.push(vm.educacaoBasicaMedio);
            }
            if (vm.educacaoBasicaFundamental) {
                preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(vm.documentosEdBasicaEducacaoFundamental, vm.educacaoBasicaFundamental);
                listaPraSalvar.push(vm.educacaoBasicaFundamental);
            }
            if (vm.educacaoBasicaInfantil) {
                verificaListaEnsinoInfantil(vm.documentosEdBasicaEnsinoInfantil, vm.educacaoBasicaInfantil);
                listaPraSalvar.push(vm.educacaoBasicaInfantil);
            }
            if (vm.configuracaoComplementar) {
                preparaDocumentosComplementarPraSalvar(vm.documentosConfiguracaoComplementar, vm.configuracaoComplementar);
                listaPraSalvar.push(vm.configuracaoComplementar);
            }
            if (vm.ejaEnsinoMedio) {
                preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(vm.documentosEjaEnsinoMedio, vm.ejaEnsinoMedio);
                listaPraSalvar.push(vm.ejaEnsinoMedio);
            }

            if (vm.ejaEnsinoFundamental) {
                preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(vm.documentosEjaEnsinoFundamental, vm.ejaEnsinoFundamental);
                listaPraSalvar.push(vm.ejaEnsinoFundamental);
            }
            if (vm.aceleracaoEnsinoFundamental) {
                preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(vm.documentosAceleracaoEnsinoFundamental, vm.aceleracaoEnsinoFundamental);
                listaPraSalvar.push(vm.aceleracaoEnsinoFundamental);
            }
            if (vm.aceleracaoEnsinoMedio) {
                preparaDocumentosEducaoFundamentalEDoMedioPraSalvar(vm.documentosAceleracaoEnsinoMedio, vm.aceleracaoEnsinoMedio);
                listaPraSalvar.push(vm.aceleracaoEnsinoMedio);
            }

            return listaPraSalvar;
        }

        function editEtapasEquivalenciasToSave(documento) {
            var keysEtapasEquivalencias = [];
            var maxEtapaEquivalencia = isEnsinoMedio(documento) ? 4 : 9;
            _.forEach(documento.equivalencias, function(etapa) {
                if (etapa.id === 'TODOS') {
                    inserirTodasEquivalencias(keysEtapasEquivalencias, maxEtapaEquivalencia);
                } else {
                    keysEtapasEquivalencias.push(etapa.id);
                }
            });

            return keysEtapasEquivalencias;
        }

        function inserirTodasEquivalencias(lista, maxEtapaEquivalencia) {
            _.forEach(vm.enumEtapas.values, function(etapa) {
                if (etapa.value <= maxEtapaEquivalencia) {
                    lista.push(etapa.key);
                }
            });
        }

        function isEnsinoFundamental(configuracao) {
            return configuracao.nivelEscolar === 'ENSINO_FUNDAMENTAL';
        }

        function isEducacaoInfantil(configuracao) {
            return configuracao.nivelEscolar === 'EDUCACAO_INFANTIL';
        }

        function isEnsinoMedio(configuracao) {
            return configuracao.nivelEscolar === 'ENSINO_MEDIO';
        }

        function formatEtapasEquivalenciaPraEditar(listaDocumentos) {
            var listaEquivalenciaAjustada = [];
            _.forEach(listaDocumentos, function(documento) {
                if (documento.equivalencias.length === 9 || (isEnsinoMedio(documento) && documento.equivalencias.length === 4)) {
                    listaEquivalenciaAjustada.push({
                        descricao: 'Aplicar todas',
                        id: 'TODOS'
                    });
                } else {
                    _.forEach(documento.equivalencias, function(etapa) {
                        _.forEach(vm.enumEtapas, function(value) {
                            if (value.key === etapa) {
                                listaEquivalenciaAjustada.push({
                                    id: value.key,
                                    descricao: value.description
                                });
                            }
                        });
                    });
                }
                documento.equivalencias = [];
                angular.copy(listaEquivalenciaAjustada, documento.equivalencias);
                listaEquivalenciaAjustada = [];
            });
        }

        function inicializaVariaveis() {
            vm.ejaFoiAoServidor = false;
            vm.complementarFoiAoServidor = false;
            vm.aceleracaoFoiAoServidor = false;
            vm.basicaFoiAoServidor = false;
        }

        function ativaTabsIniciais() {
            vm.currentTabEducaoBasica = '4';
            vm.ativaTabCurrent = '1';
        }

        function tabsConfiguracaoMatricula() {
            return [
            {
                id: '4',
                title: 'Educação básica',
                content: 'confsEduBasica',
                tipoMatricula: 'EDUCACAO_BASICA',
                template: 'matricula/configuracao-matricula/tabs/tab-educacao-basica.html'
            },
            {
                id: '3',
                title: 'Complementar',
                content: 'confsComplementar',
                tipoMatricula: 'COMPLEMENTAR',
                template: 'matricula/configuracao-matricula/tabs/tab-complementar.html'
            },
            {
                id: '2',
                title: 'EJA',
                content: 'confsEja',
                tipoMatricula: 'EJA',
                template: 'matricula/configuracao-matricula/tabs/tab-eja.html'
            },
            {
                id: '1',
                title: 'Aceleração',
                content: 'confsAceleracao',
                tipoMatricula: 'ACELERACAO',
                template: 'matricula/configuracao-matricula/tabs/tab-aceleracao.html'
            }
            ];
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.lista-espera')
        .directive('appListTurnos', appListTurnos);

    function appListTurnos() {
        return {
            restrict: 'E',
            template: '<span>{{vm.turnosDescriptions}}</span>',
            scope: {},
            bindToController: {
                turnos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$enumsCache'
    ];

    function Controller($enumsCache) {
        var vm = this;

        init();

        function init() {
            var listTurnos = _.pluck(vm.turnos, 'turno');
            $enumsCache.get('Turno')
                .then(function(enumTurnos) {
                    var turnos = _.chain(listTurnos)
                        .map(function(turno) {
                            return enumTurnos[turno].description;
                        })
                        .value();
                    vm.turnosDescriptions = turnos.join(', ');
                });
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .factory('educacao.enderecos.MunicipioService', Service);

    Service.$inject = [
        'ServiceBuilder',
        'Restangular',
        'ui.components.Modal'
    ];

    function Service(ServiceBuilder, Restangular,$modal) {
        var baseUrl = 'municipio';

        var service = ServiceBuilder.create()
                .path(baseUrl)
                .build();

        service.getAllDefault = getAllDefault;
        service.getAllUser = getAllUser;
        service.getAll = getAll;
        service.openModal = openModal;
        function openModal(nome) {
            return $modal.open({
                templateUrl: 'enderecos/municipio/cadastro-municipio/cadastro-municipio.html',
                controller: 'educacao.enderecos.CadastroMunicipioController',
                propertyName: 'municipio',
                controllerAs: 'vm',
                params: {
                    municipio: {
                        nome: nome
                    },
                    canSaveAdd: false
                },
                size: 'lg'
            }).result;
        }

        function getAll(params) {
            return Restangular.all(baseUrl).get('', params);
        }

        function getAllDefault(params) {
            params.isDefault = true;
            return getAll(params);
        }

        function getAllUser(params) {
            params.isDefault = false;
            return getAll(params);
        }

        return service;
    }
})();
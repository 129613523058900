(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appIdentificadorMatricula', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/movimentacao/identificador-matricula.directive.html',
            scope: {
                matricula: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.matricula.MatriculaService'];

    function Controller(MatriculaService) {
        var vm = this;

        vm.formatTurno = formatTurno;

        function formatTurno(turnoFrequenta) {
            return '(' + _.capitalize((turnoFrequenta || '').toLowerCase()) + ')';
        }

        MatriculaService.getEnum('TipoMatricula').then(function(data) {
            vm.enumTipoMatricula = data;
        });
        MatriculaService.getEnum('Turno').then(function(data) {
            vm.enumTurnos = data;
        });
    }

})();

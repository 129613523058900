(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectAtividade', SelectAtividadeDirective);

    function SelectAtividadeDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" />',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                tipoAtendimento: '=?',
                estabelecimento: '=?'
            },
            controller: SelectAtividadeDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectAtividadeDirectiveController.$inject = ['$scope', 'educacao.matricula.AtividadeService'];
    function SelectAtividadeDirectiveController($scope, service) {

        var vm = this;

        vm.search = search;

        function search(params) {
            var filter = '(descricao like "' + params.term + '")';
            return service.getPossuamTurmas($scope.estabelecimento.id, $scope.tipoAtendimento, params.offset, params.limit, filter);
        }

    }
})();

(function() {

    'use strict';

    angular.module('educacao.entidade')
        .factory('educacao.entidade.EntidadeService', EntidadeService);

    EntidadeService.$inject = [
        'ServiceBuilder',
        'URL_SUITE_LICENSE',
        '$http',
        'URL_API',
        'AuthenticationContext'
    ];

    function EntidadeService(ServiceBuilder, URL_SUITE_LICENSE, $http, URL_API, AuthenticationContext) {

        var self = ServiceBuilder
            .create()
            .path('commons', 'entidade')
            .build();

        self.saveEntidade = saveEntidade;
        self.getEntidadeSuite = getEntidadeSuite;
        self.setBrasoOfEntidadeSuite = setBrasoOfEntidadeSuite;
        self.camposAdicionais = camposAdicionais;
        self.getUrlBrasao = getUrlBrasao;

        return self;

        function saveEntidade(entidade) {
            return self.getEndpoint().customPUT(entidade, '');
        }

        function getUrlBrasao(idEntity) {
            return URL_SUITE_LICENSE + '/api-suite/entidade/' + idEntity + '/brasao?access_token=' + AuthenticationContext.getAccessToken();
        }

        function getEntidadeSuite() {
            return $http.get(URL_SUITE_LICENSE + '/api/entidades/atual');
        }

        function setBrasoOfEntidadeSuite(file) {
            var formData = new FormData();
            formData.append('file', file);

            return $http.post(URL_API + '/commons/entidade/atual/brasao', formData, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            });
        }

        function camposAdicionais(entidadeId) {
            return self.getEndpoint().one('campos-adicionais', entidadeId)
                .get()
                .then(function(response) {
                    return response;
                });
        }
    }

})();

(function () {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.AnoLetivoService', AnoLetivoService);

    AnoLetivoService.$inject = ['ServiceBuilder'];

    function AnoLetivoService(ServiceBuilder) {
        var service = ServiceBuilder.create()
            .path('common/ano-letivo')
            .build();

        service.getByAno = getByAno;
        service.listContextos = listContextos;
        service.listAnosLetivos = listAnosLetivos;

        return service;

        function getByAno(ano) {
            if (!ano) {
                throw Error('deve haver um valor para ano');
            }
            return service.getEndpoint()
                .one('ano', ano)
                .get();
        }

        function listContextos() {
            return service.getEndpoint().customGET('contextos');
        }

        function listAnosLetivos(params) {
            return service.getEndpoint().all('contextos').getList({
                    limit: params.limit,
                    offset: params.offset,
                    sort: params.sort,
                    filter: '(ano like "' + params.term + '")'
                });
        }
    }
})();

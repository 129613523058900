(function () {

    'use strict';

    angular.module('educacao.configuracao-evasao-reprovacao')
        .directive('appEtapaVerificacao', appConfiguracaoListaEsperaMensagem);

    function appConfiguracaoListaEsperaMensagem() {
        return {
            restrict: 'E',
            templateUrl: 'configuracao-evasao-reprovacao/app-etapa-verificacao/app-etapa-verificacao.directive.html',
            scope: {},
            bindToController: {
                dadosVerificacao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'promiseTracker',
        'educacao.configuracao-evasao-reprovacao.ConfiguracaoEvasaoReprovacaoService'
    ];

    function Controller(promiseTracker, ConfiguracaoEvasaoReprovacaoService) {
        var vm = this;

        vm.tracker = {
            verificacao: promiseTracker()
        };

        init();

        function init() {
            var promise = ConfiguracaoEvasaoReprovacaoService.verificarRecurso()
                .then(function(data) {
                    vm.dadosVerificacao = data;
                });
            vm.tracker.verificacao.addPromise(promise);
        }
    }
})();

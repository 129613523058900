(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appSelectTurnoFrequenta', SelectTurnoFrequenta);

	function SelectTurnoFrequenta() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ngclass="ngclass" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchSaldo" format-result="vm.formatResult(data)" ng-disabled="ngDisabled"/>',
			scope: {
				ngModel: '=ngModel',
				ngRequired: '=?',
				ngDisabled: '=?',
				ngChange: '&?',
				formatResult: '=',
				ngclass: '=',
				saldos: '=?'
			},
			controller: SelectTurnoFrequentaDirectiveController,
			controllerAs: 'vm'
		};
	}

	SelectTurnoFrequentaDirectiveController.$inject = ['$scope', '$q'];

	function SelectTurnoFrequentaDirectiveController($scope, $q) {

		var vm = this;

		vm.searchSaldo = searchSaldo;
		vm.formatResult = formatResult;

		init();

		function init() {
			if (!_.get($scope.ngModel, 'turno')) {
				$scope.ngModel = _.get($scope.saldos, 'length') ?
					_.find($scope.saldos, 'turno', $scope.ngModel) : {
						turno: $scope.ngModel
					};
			}
		}

		function formatResult(saldo) {
			if ($scope.formatResult) {
				return $scope.formatResult(saldo);
			}
			return format();

			function format() {
				var descricaoTurno = _.capitalize((saldo.turno || '').toLowerCase());
				if (_.isUndefined(saldo.saldo) ||
					_.isUndefined(saldo.quantidadeMaximaAlunos)) {
					return descricaoTurno;
				}
				return descricaoTurno + ' (' + saldo.saldo + '/' + saldo.quantidadeMaximaAlunos +
					' alunos)';
			}
		}

		function searchSaldo() {
			return $q.when({
				content: $scope.saldos
			});
		}

	}
})();

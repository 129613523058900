(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('inscricaoOnline', inscricaoOnline);

    inscricaoOnline.$inject = [];

    function inscricaoOnline() {

        var directiveConfig = {};

        directiveConfig.templateUrl = 'matricula/inscricao-online/inscricao-online.directive.html';
        directiveConfig.restrict = 'E';
        directiveConfig.require = '^ngModel';
        directiveConfig.controller = 'educacao.pessoas.InscricaoOnlineCtrl';
        directiveConfig.controllerAs = 'vm';

        return directiveConfig;
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.CriterioClassificacaoCtrl', CriterioClassificacaoCtrl);

    CriterioClassificacaoCtrl.$inject = ['$injector', '$scope', '$rootScope', 'API_URL', '$filter'];

    function CriterioClassificacaoCtrl($injector, $scope, $rootScope, API_URL, $filter) {
        var REFRESH_CRITERIOS = 'refresh::CriterioClassificacao';

        var promiseTracker = $injector.get('promiseTracker');
        var ModalCad = $injector.get('educacao.common.ModalCad'),
            CriterioClassificacaoService = $injector.get('educacao.matricula.CriterioClassificacaoService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService'),
            ScriptEditor = $injector.get('bfc.ScriptEditor');

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.getIconSortClass = getIconSortClass;
        vm.orderBy = orderBy;
        vm.editarScript = _editarScript;
        vm.possuiErros = _possuiErros;

        vm.tracker = {
            list: promiseTracker()
        };

        vm.criterios = [];

        init();

        function init() {
            $scope.search = {};
            refresh();
        }

        function refresh() {
            vm.tracker.list.addPromise(
                CriterioClassificacaoService.getCompletos('', 0, 999).then(function (data) {
                    var listaCriterios = _.first(data).content;
                    vm.criterios = $filter('filter')(listaCriterios, {
                        padrao: false
                    });
                    vm.criteriosPadroes = $filter('filter')(listaCriterios, {
                        padrao: true
                    });
                })
            );
        }

        function _possuiErros(criterioAvaliado) {
            return _.isEmpty(criterioAvaliado.script);
        }

        function _editarScript(criterioToEdit) {
            ScriptEditor.modal({
                resource: API_URL.concat('/matricula/criterio-classificacao/' + criterioToEdit.id + '/script'),
                title: 'Editando script: ' + criterioToEdit.descricao
            });
        }

        function openCad(criterioId) {
            ModalCad.open({
                templateUrl: 'matricula/criterio-classificacao/criterio-classificacao.cad.html',
                controller: 'educacao.matricula.CriterioClassificacaoCadCtrl as vm',
                id: criterioId,
                readOnly: false
            });
        }

        function remove(criterioRemove) {
            CriterioClassificacaoService.remove(criterioRemove).then(function () {
                $rootScope.$broadcast(REFRESH_CRITERIOS, {
                    data: criterioRemove,
                    remove: true
                });
            });
        }

        $scope.isSearching = function () {
            return Boolean($scope.search && ($scope.search.filter || $scope.search.value));
        };

        function getIconSortClass(sortBy, atividade) {
            return {
                'icon-caret-down': atividade === 'U' ? vm.predicate === sortBy && vm.reverse : vm.predicatePadrao === sortBy && vm.predicatePadrao,
                'icon-caret-up': atividade === 'U' ? vm.predicate === sortBy && !vm.reverse : vm.predicatePadrao === sortBy && !vm.reversePadrao,
                'icon-sort': atividade === 'U' ? vm.predicate !== sortBy : vm.predicatePadrao !== sortBy
            };
        }

        function orderBy(item) {
            if (typeof vm.predicate === 'undefined') {
                vm.predicate = 'descricao';
            }
            return ObjectUtilsService.normalizeFilter(item[vm.predicate]);
        }

        $scope.$on(REFRESH_CRITERIOS, function (event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.criterios = _.reject(vm.criterios, {
                        'id': args.data.id
                    });
                } else if (args.add) {
                    vm.criterios.push(args.data);
                } else if (args.undo || args.edit) {
                    var criterioEditado = _.find(vm.criterios, {
                        'id': args.data.id
                    });

                    if (criterioEditado) {
                        ObjectUtilsService.copyProperties(criterioEditado, args.data);
                    } else {
                        throw new Error('Critério  não encontrado! ID: ' + args.data.id);
                    }
                }
            }
        });
    }

})();

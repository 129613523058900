(function () {
    'use strict';

    angular.module('educacao.formulaCalculoInstrumento')
        .controller('educacao.formulaCalculoInstrumento.FormulaCalculoInstrumentoCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        'ui.components.Modal',
        'API_URL',
        'bfc.ScriptEditor',
        'educacao.context.ContextoEstabelecimento',
        'educacao.formulaCalculoInstrumento.FormulaCalculoInstrumentoService',
        '$scope'
    ];

    function Controller(
        promiseTracker,
        $modal,
        API_URL,
        ScriptEditor,
        ContextoEstabelecimento,
        FormulaCalculoInstrumentoService,
        $scope
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.FormulaCalculoInstrumentoService = FormulaCalculoInstrumentoService;
        vm.editarScript = editarScript;

        $scope.$on('formulacalculoinstrumento::refreshlist', refresh);

        function refresh() {
            if ($scope.$$childTail) {
                $scope.$$childTail.$$search();
            }
        }

        init();

        function init() {
            FormulaCalculoInstrumentoService.getSituacaoCadastral().then(function (data) {
                vm.enumSituacaoCadastral = data;
            });
        }

        function editarScript(formulaToEdit) {
            ScriptEditor.modal({
                resource: API_URL.concat('/formula-calculo/' + formulaToEdit.id + '/script'),
                title: 'Editando script de fórmula de cálculo: ' + formulaToEdit.descricao,
                afterSave: refresh
            });
        }

        function openCad(formulaCalculoInstrumento) {
            $modal.open({
                templateUrl: 'formula-calculo-instrumento/formula-calculo-instrumento.cad.html',
                controller: 'educacao.formulaCalculoInstrumento.FormulaCalculoInstrumentoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    formulaCalculoInstrumento: angular.copy(formulaCalculoInstrumento)
                }
            });
        }

        function remove(data) {
            FormulaCalculoInstrumentoService.remove(data);
        }
    }
})();


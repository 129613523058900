(function () {
    'use strict';

    angular.module('educacao.visao-geral-quadro-informativo')
        .controller('educacao.visao-geral-quadro-informativo.VisaoGeralQuadroInformativoController', Controller);

    Controller.$inject = [
        'educacao.ocupacao-aulas-quadro-informativo.VisaoGeralQuadroInformativoService',
        '$scope',
        '$q',
        'promiseTracker',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'educacao.quadro-informativo-utils.CardTotalizadoresQuadroInformativoBuilder',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        '$stateParams',
        '$rootScope'
    ];

    function Controller(Service, $scope, $q, promiseTracker, ContextoEstabelecimento, ContextoAnoLetivo, CardTotalizadoresQuadroInformativoBuilder, QuadroFuncionariosTurmaAlunosService, $stateParams, $rootScope) {
        var vm = this;

        vm.CARDS_NAMES = {
            ESTABELECIMENTO: 'Estabelecimento de ensino',
            FUNCIONARIOS: 'Funcionários',
            TURMAS: 'Turmas',
            SALAS_DE_AULA: 'Salas de aula',
            PROFESSORES: 'Professores',
            VAGAS_DISPONIVEIS: 'Vagas Disponíveis',
            EM_ESPERA: 'Candidatos na lista de espera',
            ALUNOS: 'Alunos',
            ALUNOS_DEFICIENCIA: 'Alunos com deficiência'
        };

        vm.SCREEN_NAME = 'Visão geral';

        vm.loadingTracker = {
            firstRow: promiseTracker(),
            secondRow: promiseTracker(),
            thirdRow: promiseTracker(),
            graficoAlunos: promiseTracker(),
            graficoListaEspera: promiseTracker()
        };

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();

        vm.anoLetivoSelected = $stateParams.anoLetivoSelected ? $stateParams.anoLetivoSelected : ContextoAnoLetivo.getAnoLetivo();
        vm.estabelecimentoSelected = $stateParams.estabelecimentoSelected ? $stateParams.estabelecimentoSelected : null;

        vm.firstRow = [];
        vm.secondRow = [];
        vm.thirdRow = [];
        vm.cardLista = null;

        vm.estabelecimentoFilter = null;

        vm.toggleCard = toggleCard;
        vm.getCardLista = getCardLista;

        $scope.$watch('vm.estabelecimentoSelected', onChangeContext);
        $scope.$watch('vm.anoLetivoSelected', onChangeContext);
        $scope.$watch('vm.anosLetivos', loadInscritosListaEsperaPorAno);
        $rootScope.$on('app-context:anoletivo:new', clearAnoLetivo);
        $scope.$on('vm.inscricoesListaEsperaPorAnoGraphData', getCardLista);

        function clearAnoLetivo() {
            $stateParams.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
        }

        init();
        loadAnosLetivos();

        function init() {
            clearCardsRows();
            loadGraficos();
            loadSaldos();

        }

        function loadAnosLetivos() {
            Service.getAnosLetivos().then(function (anos) {
                vm.anosLetivos = anos;
            });
        }

        function getCardLista() {
           vm.cardLista = CardTotalizadoresQuadroInformativoBuilder.create()
                .withTitulo(vm.CARDS_NAMES.EM_ESPERA)
                .withSaldo(getTotalInscritosNoAno())
                .withOrder(3)
                .withMoreInformation(!_.isEmpty(false))
                .withMoreInformationRedirect(false)
                .withCollapse(false)
                .withIsBigCard(false)
                .withIsEstabelecimentoCard(false)
                .build();

        }

        function loadSaldos() {
            var contextParams = resolveParams();
            loadFirstRowSaldos(contextParams);
            loadSecondRowSaldos(contextParams);
            loadThirdRowSaldos(contextParams);
        }

        function loadFirstRowSaldos(contextParams) {
            var saldoEstabelecimentos = null;
            var saldoSalas = null;
            var saldoTurmas = null;

            var firstRowPromise = $q.all([
                Service.getEstabelecimentos(contextParams).then(function (data) {
                    saldoEstabelecimentos = data;
                }),
                QuadroFuncionariosTurmaAlunosService.getTotalSalas(contextParams).then(function (data) {
                    saldoSalas = data;
                }),
                Service.getTurmas(contextParams).then(function (data) {
                    saldoTurmas = data;
                }),
            ]).then(function () {
                vm.firstRow = [];
                addCards(vm.firstRow, vm.CARDS_NAMES.ESTABELECIMENTO, saldoEstabelecimentos.qntdEstabelecimentos, 'app.planejamento.escolas', false, false, 1, true,'Ir para cadastro de estabelecimentos');
                addCards(vm.firstRow, vm.CARDS_NAMES.SALAS_DE_AULA, saldoSalas.totalSalas, null, false, true, 2);
                addCards(vm.firstRow, vm.CARDS_NAMES.TURMAS, saldoTurmas.qntdTurmas, null, false, true, 3);
            });

            vm.loadingTracker.firstRow.addPromise(firstRowPromise);
        }

        function loadSecondRowSaldos(contextParams) {
            var saldoFuncionarios = null;
            var saldoProfessores = null;
            var saldoAlunos = null;

            var secondRowPromise = $q.all([
                Service.getFuncionarios(contextParams).then(function (data) {
                    saldoFuncionarios = data;
                }),
                Service.getProfessores(contextParams).then(function (data) {
                    saldoProfessores = data;
                }),
                Service.getAlunos(contextParams).then(function (data) {
                    saldoAlunos = data;
                })
            ]).then(function () {
                vm.secondRow = [];
                addCards(vm.secondRow, vm.CARDS_NAMES.FUNCIONARIOS, saldoFuncionarios.qntdFuncionarios, null, false, false, 1);
                addCards(vm.secondRow, vm.CARDS_NAMES.PROFESSORES, saldoProfessores.qntdProfessores, null, false, false, 2);
                addCards(vm.secondRow, vm.CARDS_NAMES.ALUNOS, saldoAlunos.qntdAlunos, null, false, false, 3);
            });

            vm.loadingTracker.secondRow.addPromise(secondRowPromise);
        }

        function loadThirdRowSaldos(contextParams) {
            var saldoVagas = null;
            var saldoAlunosComDeficiencia = null;

            var thirdRowPromise = $q.all([
                Service.getVagasDisponiveis(contextParams).then(function (data) {
                    saldoVagas = data;
                }),
                QuadroFuncionariosTurmaAlunosService.getTotalAlunosComDeficiencia(contextParams).then(function (data) {
                    saldoAlunosComDeficiencia = data;
                }),

            ]).then(function () {
                vm.thirdRow = [];
                addCards(vm.thirdRow, vm.CARDS_NAMES.ALUNOS_DEFICIENCIA, saldoAlunosComDeficiencia.totalAlunos, null, false, false, 1);
                addCards(vm.thirdRow, vm.CARDS_NAMES.VAGAS_DISPONIVEIS, saldoVagas.qntdVagasDisponiveis, null, false, false, 2);
            });

            vm.loadingTracker.thirdRow.addPromise(thirdRowPromise);
        }

        function loadGraficos() {
            var contextParams = resolveParams();

            var graficoAlunoPorAnoPromise = Service.getAlunosPorAno(contextParams).then(function (data) {
                vm.alunosPorAnoGraphData = _.sortBy(data, 'ano');
            });

            if (vm.anosLetivos) {
                loadInscritosListaEsperaPorAno();
            }

            vm.loadingTracker.graficoAlunos.addPromise(graficoAlunoPorAnoPromise);
        }

        function loadInscritosListaEsperaPorAno() {
            var contextParams = resolveParams();
            var promiseListEspera = Service.getInscritosListaEsperaPorAno(contextParams).then(function (inscricoes) {
                var dataInicial = vm.anoLetivoSelected !== null ? vm.anoLetivoSelected.ano : vm.anoLetivo.ano;
                var saldosListaDeEspera = sortSaldosPorAnoListaEspera(inscricoes);
                var saldosAjustados = filtrarPorData(saldosListaDeEspera, dataInicial);
                vm.inscricoesListaEsperaPorAnoGraphData = saldosAjustados;
                getCardLista();
            });

            vm.loadingTracker.graficoListaEspera.addPromise(promiseListEspera);
        }

        function getTotalInscritosNoAno() {
            var inscricao = _.find(vm.inscricoesListaEsperaPorAnoGraphData, function (item) {
                return item.ano.toString() === vm.anoLetivoSelected.ano.toString();
            });
            if (_.get(inscricao, 'inscricoes')) {
                return _.get(inscricao, 'inscricoes');
            }
            return 0;
        }

        function filtrarPorData(list, dataInicial) {
            var MAX_ANOS_TO_SHOW = 4;

            var anosParaVisualizacao = retrocederAnos(dataInicial, MAX_ANOS_TO_SHOW);

            return _.filter(list, function (item) {
                return anosParaVisualizacao.includes(item.ano);
            });
        }

        function retrocederAnos(data, valor) {
            var anosRetrocedidos = [];
            for (var i = 0; i < valor; i++) {
                anosRetrocedidos.push(data - i);
            }

            return anosRetrocedidos;
        }

        function addCards(cardArray, descricao, valor, redirect, hasCollapse, isBigCard, order, isEstabelecimentoCard,textMoreInformation) {
            cardArray.push(
                CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(descricao)
                    .withSaldo(valor)
                    .withOrder(order)
                    .withMoreInformation(!_.isEmpty(redirect))
                    .withMoreInformationRedirect(redirect)
                    .withPermissionRedirect(redirect)
                    .withCollapse(hasCollapse)
                    .withIsBigCard(isBigCard)
                    .withIsEstabelecimentoCard(isEstabelecimentoCard)
                    .withTextHasMoreInformation(textMoreInformation)
                    .build());
        }

        function sortSaldosPorAnoListaEspera(inscricoes) {
            return _.chain(inscricoes)
                .map(mapGraficoListaEsperaValues)
                .filter(filterInscritosPorAno)
                .sortBy('ano')
                .value();
        }

        function filterInscritosPorAno(porAno) {
            return !_.isEmpty(porAno);
        }

        function mapGraficoListaEsperaValues(porAno) {
            var anoLetivo = _.find(vm.anosLetivos, 'id', porAno.id);

            if (!anoLetivo) {
                return {};
            }

            return {
                id: porAno.id,
                ano: anoLetivo.ano,
                inscricoes: porAno.inscricoes
            };
        }

        function toggleCard(card) {
            card.isCollapsed = !card.isCollapsed;
        }

        function onChangeContext(newValue) {
            if (newValue !== null || (newValue === null && !_.isEmpty(vm.firstRow))) {
                if (newValue === null) {
                    vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
                }
                init();
            }

        }

        function resolveParams() {
            return {
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id'),
                estabelecimentoId: vm.isSecretaria ? _.get(vm.estabelecimentoSelected, 'id') : _.get(ContextoEstabelecimento.getEstabelecimento(), 'id')
            };
        }

        function clearCardsRows() {
            vm.firstRow = [];
            vm.secondRow = [];
            vm.thirdRow = [];
        }
    }
})();

(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appSelectContextoEntidade', directive);

    directive.$inject = [];
    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/layout/header/contexts-systems/select-contexto-entidade.directive.html',
            scope: {},
            controller: Contoller,
            controllerAs: 'vm'
        };
    }

    Contoller.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.context.ContextoEntidade'
    ];
    function Contoller($scope, promiseTracker, ContextoEntidade) {
        var vm = this;

        vm.tracker = promiseTracker();

        vm.entidade = ContextoEntidade.getEntidade();
        vm.getEntidadeName = getEntidadeName;

        vm.alterarEntidadeAtual = alterarEntidadeAtual;

        $scope.$on('app-context:entidade:new', function () {
            vm.entidade = ContextoEntidade.getEntidade();
        });

        loadEntidades();

        function loadEntidades() {
            vm.tracker.addPromise(
                ContextoEntidade
                    .listaEntidades()
                    .then(function (data) {
                        vm.entidades = data;
                    })
            );
        }

        function alterarEntidadeAtual(entidade) {
            ContextoEntidade.setEntidade(entidade);
        }

        function getEntidadeName() {
            var entity = ContextoEntidade.getEntidade();
            if(entity){
                return entity.name;
            }
        }
    }
})();
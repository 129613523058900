(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.TurmaService', TurmaService);

	TurmaService.$inject = ['ServiceBuilder', 'Restangular', 'educacao.matricula.ConfiguracaoTurmasService', '$enumsCache'];

	function TurmaService(ServiceBuilder, Restangular, ConfiguracaoTurmasService, $enumsCache) {
		var self = ServiceBuilder
			.create()
			.path('matricula', 'turma')
			.enum('TipoAtendimento', 'Turno', 'TipoMediacaoDidaticoPedagogica', 'ModoAvaliacao', 'EquivalenciaEtapa')
			.build();

		self.getAlunosTurma = getAlunosTurma;
        self.getDiciplinas = getDiciplinas;
        self.getFilhos = getFilhos;
        self.getEnturmacoes = getEnturmacoes;
        self.getEnturmacoesMultiturno = getEnturmacoesMultiturno;
        self.getEnturmacoesFuncionarios = getEnturmacoesFuncionarios;
        self.getEnturmacoesHistoricas = getEnturmacoesHistoricas;
        self.getItensEducacionais = getItensEducacionais;
        self.getItensTurma = getItensTurma;
        self.getItensTurmaDetalhes = getItensTurmaDetalhes;
        self.getItensTurmaEjaModular = getItensTurmaEjaModular;
        self.getList = getList;
        self.getListFilter = getListFilter;
        self.getListTurmaCommons = getListTurmaCommons;
        self.getTurmaMultisseriada = getTurmaMultisseriada;
        self.getTurmasRematricula = getTurmasRematricula;
        self.alteraTipoRegistroFrequencia = alteraTipoRegistroFrequencia;
        self.getPeriodosAvaliativos = getPeriodosAvaliativos;
        self.getConfiguracaoAvaliacao = getConfiguracaoAvaliacao;
        self.getItensEducacionaisAreaConhecimento = getItensEducacionaisAreaConhecimento;
	    self.camposAdicionais = camposAdicionais;
        self.getPeriodosAvaliativosTurma = getPeriodosAvaliativosTurma;
        self.getQuantidadeMaximaAlunosVagaEtapa = getQuantidadeMaximaAlunosVagaEtapa;

        var configuracaoTurma = {};

        return self;

        function getItensTurmaDetalhes(idTurma) {
            return Restangular.all('matricula')
                .one('turma', idTurma)
                .all('atividades')
                .getList();
        }

        function getItensTurmaEjaModular(idTurma) {
            return Restangular.all('matricula')
                .one('turma', idTurma)
                .all('disciplinas-eja-modular')
                .getList();
        }

        /**
         * @deprecated utilizar getFilhos
         * @param {number} idTurma
         * @param {object} params
         */
        function getDiciplinas(idTurma, params) {
            params = params || {};

            return Restangular.all('matricula')
                .one('turma', idTurma)
                .all('filhos')
                .getList(params);
        }

        function getFilhos(idTurma) {
            return Restangular.all('matricula')
                .one('turma', idTurma)
                .all('filhos')
                .getList()
                .then(Restangular.stripRestangular);
        }

        function getItensEducacionais(idTurma) {
            return Restangular.all('commons')
                .one('turma', idTurma)
                .all('itens-educacionais')
                .getList();
        }

        function getItensEducacionaisAreaConhecimento(idTurma) {
            return Restangular.all('commons')
                .one('turma', idTurma)
                .all('area-conhecimento')
                .getList();
        }

        function getEnturmacoes(idTurma, params) {
            var ORDENACAO_DEFAULT_ENTURMACAO = 'numeroChamada';
            params = params || {};
            params.limit = params.limit || 1000;

            return $enumsCache.get('ConfiguracaoOrdenacaoAlunos').then(function (configuracaoOrdenacaoEnum) {
				configuracaoTurma.ConfiguracaoOrdenacao = configuracaoOrdenacaoEnum;
			})
            .then(function(){
                return ConfiguracaoTurmasService.getConfiguracaoOrdenacaoTurma().then(function (configuracaoOrdenacao){
                    configuracaoTurma.configuracaoOrdenacao = configuracaoOrdenacao;
                });
            })
            .then(function(){
                params.sort = configuracaoTurma.ConfiguracaoOrdenacao[configuracaoTurma.configuracaoOrdenacao] ? configuracaoTurma.ConfiguracaoOrdenacao[configuracaoTurma.configuracaoOrdenacao].ordenacao : ORDENACAO_DEFAULT_ENTURMACAO;
            })
            .then(function (){
                return Restangular.all('matricula')
                .one('enturmacao', idTurma)
                .all('enturmacoes')
                .get('', params);
            });
        }

        function getEnturmacoesMultiturno(idTurma, params) {
            params = params || {};

            return Restangular.all('matricula')
                .one('enturmacao', idTurma)
                .all('enturmacoes-multiturno')
                .get('', params);
        }

        function getEnturmacoesFuncionarios(params) {
            params = params || {};
            params.limit = params.limit || 1000;

            return Restangular.all('enturmacao-funcionario')
                .get('', params);
        }

        /*utilizado em algumas diretiva passando o term direto por parametro*/
        function getListFilter(filter, offset, limit, sort) {
            var params = {
                filter: filter,
                offset: offset,
                limit: limit,
                sort: sort
            };

            return getList(params);
        }

        function getList(params) {
            return Restangular.all('matricula/turma')
                .get('', params);
        }

        function getListTurmaCommons(params) {
            return Restangular.all('commons/turma')
                .get('', params);
        }

        function getTurmaMultisseriada(id) {
            return Restangular.one('matricula/turma', id)
                .one('multisseriadas')
                .get();
        }

        function getAlunosTurma(id, pa) {
            return Restangular.one('matricula/turma', id).one('frequencias/registros-faltas').get({
                pa: pa
            });
        }

        function getItensTurma(id) {
            return Restangular.one('matricula/turma', id)
                .one('filhos')
                .get();
        }

        function getEnturmacoesHistoricas(idTurma, filter, offset, limit) {
            return Restangular.all('commons')
                .one('turma', idTurma)
                .one('enturmacoes-historicas', '').get({
                    filter: filter,
                    offset: offset,
                    limit: limit
                });
        }

        function getTurmasRematricula(filters) {

            return Restangular.one('matricula/turma/rematricula/turmas')
                .get({

                    estabelecimentoId: _.get(filters.estabelecimento, 'id'),
                    matrizId: _.get(filters.matriz, 'id'),
                    etapasIds: _.pluck(filters.etapas, 'id'),
                    turmasIds: _.pluck(filters.turmas, 'id'),
                    situacoes: filters.situacoes,
                    solicitacaoRematricula: _.get(filters, 'solicitacaoRematricula')
                });
        }

		function alteraTipoRegistroFrequencia(tipoRegistroFrequencia, idsTurmas) {
			return Restangular.all('matricula/turma/alterar-tipo-frequencia').customPOST(null, tipoRegistroFrequencia, {
				idsTurmas: idsTurmas
			});
		}

		function camposAdicionais(turmaId) {
            return Restangular.one('matricula/turma/campos-adicionais', turmaId)
                .get()
                .then(Restangular.stripRestangular);
        }

        function getPeriodosAvaliativos(idTurma) {
            return Restangular.one('matricula/turma', idTurma).one('periodos-avaliativos')
                .getList()
                .then(Restangular.stripRestangular);
        }

        function getPeriodosAvaliativosTurma(idTurma) {
            return Restangular.all('commons')
                .one('periodos-avaliativos', '').get({
                    turma: idTurma
                });
        }

        function getConfiguracaoAvaliacao(idTurma) {
            return Restangular.one('matricula/turma', idTurma).one('configuracao-avaliacao')
                .get()
                .then(Restangular.stripRestangular);
        }

        function getQuantidadeMaximaAlunosVagaEtapa(idVagaEtapa) {
            return Restangular.one('matricula/turma',idVagaEtapa).one('quantidade-maxima-aluno').get();
        }
    }
})();

(function() {
    'use strict';

    var CREATE_EVENT = 'agenda-view.create';

    angular.module('educacao.calendario')
        .constant('AGENDA_VIEW_CREATE_EVENT', CREATE_EVENT)
        .directive('calendarioAgendaProfessor', CalendarioAgendaDirective);

    function CalendarioAgendaDirective() {
        return {
            restrict: 'E',
            scope: {
                calendarioDisplay: '=',
                view: '='
            },

            templateUrl: 'calendario/view-agenda/calendario-agenda.directive.html',

            controller: CalendarioAgendaController,
            controllerAs: 'vm'
        };
    }

    CalendarioAgendaController.$inject = [
        'DATE_TIME_FORMAT',
        'COLOR_CLASS_HOLYDAY',
        'COLOR_CLASS_WORKDAY_HOLYDAY',
        'EVENT_POPOVER_EVENTO_TEMPLATE',
        'EVENT_DEFAULT_TIMED_EVENT_DURATION',

        '$scope',

        'InstallDirective',
        'ColorHelper'
    ];

    function CalendarioAgendaController(DATE_TIME_FORMAT,
        COLOR_CLASS_HOLYDAY,
        COLOR_CLASS_WORKDAY_HOLYDAY,
        EVENT_POPOVER_EVENTO_TEMPLATE,
        EVENT_DEFAULT_TIMED_EVENT_DURATION,
        $scope,
        InstallDirective,
        colorHelper) {

        var vm = this;

        vm.agendaConfig = {
            defaultTimedEventDuration: EVENT_DEFAULT_TIMED_EVENT_DURATION,

            selectable: false,
            select: onSelection,
            eventRender: eventRender
        };

        $scope.$watch('calendarioDisplay.eventos', updateCalendarEvents, true);
        $scope.$watch('calendarioDisplay.feriados', updateCalendarHolydays, true);

        function updateCalendarEvents(eventos) {
            if (_.isArray(eventos)) {
                vm.eventos = eventos ? eventos.map(convertEventoToFullCalendarEvent) : [];
            } else {
                vm.eventos = [];
            }
        }

        function convertEventoToFullCalendarEvent(evento) {
            return {
                title: evento.evento.descricao,
                start: getDataHoraInicio(evento),
                end: getDataHoraFim(evento),
                className: getEventClass(evento.cor),

                allDay: evento.diaInteiro,
                editable: false,
                originalEvent: evento
            };
        }

        function getDataHoraInicio(evento) {
            return moment(evento.dataInicial);
        }

        function getDataHoraFim(evento) {
            if (!evento.dataFinal) {
                return;
            }

            var dataInicial = moment(evento.dataInicial);
            var dataFinal = moment(evento.dataFinal);
            if (evento.diaInteiro && dataFinal.isAfter(dataInicial, 'days')) {
                dataFinal.add(1, 'days');
            }
            return dataFinal;
        }

        function getEventClass(color) {
            return colorHelper.getTextColorClass(color, 10) + ' ' + colorHelper.getColorClass(color);
        }

        function updateCalendarHolydays(feriados) {
            if (_.isArray(feriados)) {
                vm.feriados = feriados ? feriados.map(convertFeriadoToFullCalendarEvent) : [];
            } else {
                vm.feriados = [];
            }

        }

        function convertFeriadoToFullCalendarEvent(feriado) {
            return {
                title: feriado.feriado.descricao,
                start: feriado.feriado.data,
                allDay: true,
                editable: false,

                className: getEventClass(feriado.letivo ? COLOR_CLASS_WORKDAY_HOLYDAY : COLOR_CLASS_HOLYDAY),
                originalEvent: feriado
            };
        }

        function eventRender(event, element) {
            if (!event.originalEvent) {
                return;
            }

            var directiveAttrs = {
                'data-bf-popover': EVENT_POPOVER_EVENTO_TEMPLATE,
                'data-bf-popover-create-body': true
            };

            var newScopeValues = {
                events: [event.originalEvent]
            };

            InstallDirective.addDirective(element, directiveAttrs, $scope, newScopeValues);
        }

        function onSelection(start, end) {
            var scaffold = {
                dataInicial: start.format(DATE_TIME_FORMAT)
            };

            if (end) {
                if (!end.hasTime()) {
                    end.subtract(1, 'days');
                }
                scaffold.dataFinal = end.format(DATE_TIME_FORMAT);
            }

            scaffold.diaInteiro = !start.hasTime();

            $scope.$emit(CREATE_EVENT, scaffold);
        }
    }
})();

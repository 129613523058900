(function() {

    'use strict';

    /**
    	Filtro para devolver um endereço formatado no seguinte formato, caso haja informações: 
	
		Logradouro, número, Município-UF, CEP
	**/

    angular.module('educacao.common')
        .filter('formatarEndereco', filterFormatarEndereco);

    function filterFormatarEndereco() {
        return formatarEndereco;

        function formatarEndereco(endereco) {

            if (!endereco) {
                return '';
            }

            var uf, municipio, logradouro, numero, cep, enderecoFormatado;

            if (endereco.logradouro) {
                logradouro = endereco.logradouro.nome || endereco.logradouro.descricao || endereco.logradouro;
            }

            if (endereco.municipio) {
                uf = endereco.municipio.uf;
                if (!uf && endereco.municipio.estado) {
                    uf = endereco.municipio.estado.sigla || endereco.municipio.estado.numero;
                }

                municipio = endereco.municipio.nome;
            }

            if (endereco.numero) {
                numero = endereco.numero;
            }

            if (endereco.cep) {
                cep = endereco.cep;
            }

            enderecoFormatado = logradouro || '';

            if (numero) {
                enderecoFormatado += enderecoFormatado ? ', ' + numero : numero;
            }
            if (municipio) {
                enderecoFormatado += enderecoFormatado ? ', ' + municipio : municipio;
            }
            if (uf) {
                enderecoFormatado += enderecoFormatado ? ' - ' + uf : uf;
            }
            if (cep) {
                enderecoFormatado += enderecoFormatado ? ', ' + cep : cep;
            }

            return enderecoFormatado;
        }
    }

})();


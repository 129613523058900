(function() {

    'use strict';

    angular.module('educacao.educacenso')
        .directive('appEducacensoResultadosInconsistencias', SelectEducacensoResultadosInconsistencias);

    function SelectEducacensoResultadosInconsistencias() {
        return {
            restrict: 'E',
            templateUrl: 'educacenso/resultado-inconsistencias/resultado-inconsistencias.html',
            bindToController: true,
            scope:{
                progressoValidacao: '=',
                estabelecimento: '=',
                etapa: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;
        var STYLE_BADGE_ERROR_RED = 'bg__red--l30 tx__red--d20';

        vm.getStyleBadge = getStyleBadge;
        vm.getTotalErrosFuncionarios = getTotalErrosFuncionarios;
        vm.getStyleBadgeFuncionarios = getStyleBadgeFuncionarios;

        function getStyleBadge(tipo){
            if(vm.estabelecimento[tipo] > 0) {
                return STYLE_BADGE_ERROR_RED;
            }
        }

        function getTotalErrosFuncionarios(){
            return vm.estabelecimento.totalInconsistenciasProfessores + vm.estabelecimento.totalInconsistenciasGestores + vm.estabelecimento.totalInconsistenciasFuncionarios;
        }

        function getStyleBadgeFuncionarios(){
            if(getTotalErrosFuncionarios() > 0) {
                return STYLE_BADGE_ERROR_RED;
            }
        }

    }

})();

(function () {
    'use strict';

    var EDIT_EVENT = 'observacao-popover.edit';

    angular.module('educacao.calendario')
        .constant('OBSERVACAO_POPOVER_EDIT_EVENT', EDIT_EVENT)
        .controller('educacao.calendario.ObservacaoPopoverController', ObservacaoPopoverController);

    ObservacaoPopoverController.$inject = [
        '$scope'
    ];

    function ObservacaoPopoverController($scope) {
        var vm = this;

        vm.editing = false;

        vm.startEdit = startEdit;
        vm.clear = clear;
        vm.save = save;

        function startEdit(observacao) {
            vm.editing = true;
            vm.temp = observacao.observacao;
        }

        function clear() {
            vm.temp = '';
        }

        function save(observacao) {
            vm.editing = false;
            observacao.observacao = vm.temp;

            $scope.$emit(EDIT_EVENT, vm.temp);
            $scope.$popoverInstance.hide();
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.AtivComplCadCtrl', AtivComplCadCtrl);

    AtivComplCadCtrl.$inject = [
        '$scope',
        'educacao.matricula.AtividadeComplementarService',
        'promiseTracker',
        'bfc.Notification',
        '$modalInstance'
    ];

    function AtivComplCadCtrl(
        $scope,
        AtividadeComplementarService,
        promiseTracker,
        Notification,
        $modalInstance
    ) {

        var vm = this;

        vm.remove = remove;
        vm.save = save;

        vm.subAreas = AtividadeComplementarService.getSubAreaAtividadeCompl();

        vm.atividade = angular.copy($modalInstance.params.atividade) || {};

        vm.tracker = promiseTracker();
        vm.loadingAtividade = promiseTracker();

        vm.mode = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.atividade.id
        };

        init();

        function init() {
            if (_.get(vm.atividade, 'codigoInep')) {
                var promise = AtividadeComplementarService.getDefaultByCodInep(vm.atividade.codigoInep).then(setAtividade);
                vm.loadingAtividade.addPromise(promise);
            }
            vm.areas = AtividadeComplementarService.getAreaAtividadeCompl()
                .then(setAreaAtividade);
        }

        function setAtividade(atividade) {
            vm.atividadePadraoInep = atividade;
        }

        function setAreaAtividade(data) {
            vm.enumAreas = data;
            
            if (vm.atividade.area) {
                vm.atividade.area = {
                    id: vm.atividade.area,
                    descricao: vm.enumAreas[vm.atividade.area].description,
                    subArea: vm.atividade.subArea
                };
            }

            return data;
        }

        $scope.$watch('vm.atividade.area', watch);

        function watch(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (!newValue) {
                delete vm.atividade.subArea;
                return;
            }

            vm.atividade.subArea = newValue.subArea;
        }

        function save(continuar) {
            var atividadeSave = angular.copy(vm.atividade);

            if (vm.atividade.area) {
                atividadeSave.area = atividadeSave.area.id;
            }

            atividadeSave.codigoInep = _.get(vm.atividadePadraoInep, 'codigoInep');

            var promise = AtividadeComplementarService.save(atividadeSave).then(function(data) {
                Notification.publish('Atividade complementar salva com sucesso', 'success');
                if (continuar) {
                    vm.atividade = {};
                    delete vm.atividadePadraoInep;
                    vm.formCadastro.$setUntouched();
                } else {
                    $modalInstance.close(data);
                }
            });
            vm.tracker.addPromise(promise);
        }

        function remove() {
            var promise = AtividadeComplementarService.remove(vm.atividade).then(function() {
                $modalInstance.close();
            });

            vm.tracker.addPromise(promise);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('educacao.lista-espera')

        .constant('PROPRIEDADE_MODALIDADE', {
            'educacaoBasica': 'Educação básica',
            'aceleracao': 'Aceleração',
            'eja': 'Educação de Jovens e Adultos (EJA)',
            'complementares': 'Complementar',
            'atividadesAee': 'Atendimento Educacional Especializado (AEE)',
            'atividadesComplementares': 'Atividade complementar'
        });
})();

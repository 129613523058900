(function() {
    'use strict';

    angular.module('educacao.matricula').directive('appCardTurmaItensProfessores', appCardTurmaProfessores);

    function appCardTurmaProfessores() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/app-card-turma/professores/app-card-turma-itens-professores.directive.html',
            scope: {
                enturmacoes: '=',
                onDesenturmar: '&',
                onRemover: '&',
                turma: '='
            },
            bindToController: true,
            controller: CardTurmaDirectiveController,
            controllerAs: 'vm'
        };
    }

    CardTurmaDirectiveController.$inject = ['$scope', '$injector', 'promiseTracker'];

    function CardTurmaDirectiveController($scope, $injector, $promiseTracker) {
        var vm = this;

        var EnturmacaoProfessorService = $injector.get('educacao.matricula.EnturmacaoProfessorService');

        vm.isRegentePrincipal = isRegentePrincipal;
        vm.setRegente = setRegente;
        vm.getTitle = getTitle;
        vm.isRegente = isRegente;
        vm.hasLugarDisponivel = hasLugarDisponivel;

        init();

        $scope.$watchCollection('vm.enturmacoes', atualizaGruposEnturmacao);

        function init() {
            vm.grupos = [];
            vm.types = [
                {
                    title: 'Professores',
                    tipo: 'PROFESSOR',
                    acessaProfessores: true
				},
                {
                    title: 'Professores Auxiliares',
                    tipo: 'AUXILIAR',
                    acessaProfessores: true
				},
                {
                    title: 'Regente',
                    tipo: 'REGENTE',
                    acessaProfessores: false
				},
                {
                    title: 'Docentes titulares/coordenadores de tutoria EAD',
                    tipo: 'DOCENTE_TITULAR',
                    acessaProfessores: true
				},
                {
                    title: 'Docentes tutores/auxiliares EAD',
                    tipo: 'DOCENTE_TUTOR',
                    acessaProfessores: true
				},
                {
                    title: 'Tradutor e Intérprete de Libras',
                    tipo: 'TRADUTOR_INT_LIBRAS',
                    acessaProfessores: false
				},
                {
                    title: 'Guia-Intérprete',
                    tipo: 'GUIA_INTERPRETE',
                    acessaProfessores: false
				},
                {
                    title: 'Apoio escolar para aluno(a)s com deficiência',
                    tipo: 'PROF_APOIO_ALUNO_DEF',
                    acessaProfessores: false
                },
                {
                    title: 'Auxiliar de Sala',
                    tipo: 'AUXILIAR_SALA',
                    acessaProfessores: true
				},
                {
                    title: 'Instrutor da Educação Profissional',
                    tipo: 'INSTRUTOR_EDUCA_PROF',
                    acessaProfessores: true
				}
			];
            vm.types = _.sortBy(vm.types, 'title');
            initTracker();
        }

        function initTracker() {
            vm.tracker = {
                regente: $promiseTracker()
            };
        }

        function atualizaGruposEnturmacao(newValeu, oldValue) {
            if (newValeu === oldValue) {
                return;
            }

            getGruposEnturmacao();
        }

        function getGruposEnturmacao() {
            vm.grupos = _.groupBy(vm.enturmacoes, 'tipo');
        }

        function getTitle(item, grupo) {
            if (isRegente(item)) {
                return grupo.professor.nome;
            } else {
                var texto = [];
                texto.push(grupo.itemEducacional.descricaoPai);
                texto.push(grupo.itemEducacional.descricao);
                texto = texto.filter(function(n) {
                    return n; //remove empty values
                });
                return grupo.professor.nome + ' (' + texto.join(' - ') + ')';
            }
        }

        function hasLugarDisponivel() {
            return !_.size(_.get(vm.grupos, 'PROFESSOR')) || _.size(_.get(vm.grupos, 'PROFESSOR')) < vm.turma.quantidadeMaximaProfessores;
        }

        function isRegente(item) {
            return item.tipo === 'REGENTE';
        }

        function isRegentePrincipal(grupo) {
            return Boolean(_.get(grupo, 'tipoRegente') === 'PRINCIPAL');
        }

        function setRegente(grupo) {
            var promise = EnturmacaoProfessorService.setRegentePrincipal(grupo).then(refreshRegente);
            vm.tracker.regente.addPromise(promise);

            function refreshRegente() {
                var otherGrups = _.filter(vm.grupos.REGENTE, function(data) {
                    return data.id !== grupo.id;
                });

                _.map(otherGrups, function(data) {
                    data.tipoRegente = null;
                });

                grupo.tipoRegente = isRegentePrincipal(grupo) ? null : 'PRINCIPAL';
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .directive('appFiltroAvancadoAlunoCaracteristicasFisicas', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'matricula/matriculas/filtro-avancado/aluno/caracteristicas-fisicas/caracteristicas-fisicas.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorCaracteristicasFisicas: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.pessoas.AlunoService'
    ];

    function Controller(
        $scope,
        AlunoService
    ) {
        var vm = this;
        vm.changeContadorCaracteristicas = changeContadorCaracteristicas;
        vm.formatTipoSanguineo = formatTipoSanguineo;

        vm.expressionCustomBuildersCaracteristicasFisicas = {};

        $scope.$watchGroup([
            'vm.advancedFilter.caracteristicas.fuma',
            'vm.advancedFilter.caracteristicas.possuiPlanoDeSaude',
            'vm.advancedFilter.caracteristicas.fazUsoDeMedicacao',
            'vm.advancedFilter.caracteristicas.habilidadesSuperdotacao',
            'vm.advancedFilter.caracteristicas.precisaAEE'
        ], changeContadorCaracteristicas);

        init();

        function init() {
            vm.expressionCustomBuilders = Object.assign(vm.expressionCustomBuilders, vm.expressionCustomBuildersCaracteristicasFisicas);
            vm.contadorCaracteristicasFisicas = 0;
            vm.EnumTipoSanguineo = AlunoService.getTipoSanguineo().then(function(dados) {
                vm.ENUM_TIPO_SANGUINEO = dados;
                return dados;
            });
            changeContadorCaracteristicas();
        }

        function changeContadorCaracteristicas() {
            if (vm.advancedFilter && vm.advancedFilter.caracteristicas) {
                vm.contadorCaracteristicasFisicas = getFilterLength();
            }
        }

        function getFilterLength() {
            return Object.values(vm.advancedFilter.caracteristicas).filter(function (n) { 
                return n !== undefined && n !== null && n !== 0 && n !== '';
            }).length;
        }

        function formatTipoSanguineo(tipoSanguineo){
            return vm.ENUM_TIPO_SANGUINEO[tipoSanguineo].description;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.prototipo')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider.state('app.prototipo', {
                'abstract': true,
                url: 'prototipo'
            })
            .state('app.prototipo.matriculas', stateUrlView('/matriculas', '@', 'prototipo/matriculas/matricula.html'))
            .state('app.prototipo.enturmacao', stateUrlView('/enturmacao', '@', 'prototipo/enturmacao/enturmacao.html'))
            .state('app.prototipo.registro-desempenho-ano', stateUrlView('/registro-desempenho-ano', '@', 'prototipo/desempenho/registro-desempenho-ano.html'))
            .state('app.prototipo.registro-desempenho-disc', stateUrlView('/registro-desempenho-disc', '@', 'prototipo/desempenho/registro-desempenho-disc.html'))
            .state('app.prototipo.registro-desempenho-aee', stateUrlView('/registro-desempenho-aee', '@', 'prototipo/desempenho/registro-desempenho-aee.html'))
            ;
    }

    function stateUrlView(urlData, viewNameData, templateData, controllerData) {
        var obj = {};

        obj.views = {};
        obj.views[viewNameData] = {};

        obj.url = urlData;
        obj.views[viewNameData].templateUrl = templateData;

        if (controllerData) {
            obj.views[viewNameData].controller = controllerData;
        }

        return obj;
    }
})();

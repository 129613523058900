(function() {
    'use strict';

    angular.module('educacao.matricula')
        .service('educacao.matricula.ReclassificacaoService', service);

    service.$inject = [
        'Restangular'
    ];

    function service(restangular) {
        var tipoReclassificacao = {
            'AVANCO': 'Avanço',
            'ACELERACAO': 'Aceleração'

        };
        return {
            recuperaDto: recuperaDto,
            reclassificar: reclassificar,
            getTipoReclassificacao: getTipoReclassificacao
        };

        function getReclassificacao(matricula, idReclassificacao) {
            return restangular.all('matricula')
                .one('matriculas', idReclassificacao)
                .all('reclassificar')
                .customGET(idReclassificacao, matricula.id);

        }

        function getTipoReclassificacao(tipo){

            return tipoReclassificacao[tipo];
        }

        function reclassificar(reclassificacao) {
            if (reclassificacao.id) {
                return update(reclassificacao);
            } else {
                return insert(reclassificacao);
            }

        }

        function update(reclassificacao) {
            return restangular.all('matricula')
                .one('matriculas', reclassificacao.matriculaOrigem.id)
                .all('reclassificar',reclassificacao.id,reclassificacao)
                .customPUT(reclassificacao,reclassificacao.id);
        }

        function insert(reclassificacao) {
            return restangular.all('matricula')
                .one('matriculas', reclassificacao.matriculaOrigem.id)
                .all('reclassificar')
                .customPOST(reclassificacao);
        }

        function recuperaDto(matriculaOrigem, idReclassificacao) {
            return idReclassificacao ? getReclassificacao(matriculaOrigem, idReclassificacao) : novaReclassificacao(matriculaOrigem);
        }

        function novaReclassificacao(matriculaOrigem) {
            return restangular.all('matricula')
                .one('matriculas', matriculaOrigem.id)
                .customGET('reclassificar');
        }
    }
})();


(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaTableInscritos', appConfiguracaoListaEsperaTableInscritos);
    
    function appConfiguracaoListaEsperaTableInscritos() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/configuracao-lista-espera-buscar-candidato/app-configuracao-lista-espera-table-inscritos/app-configuracao-lista-espera-table-inscritos.directive.html',
            scope: {},
            bindToController: {
                inscrito: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService'
    ];

    function Controller(InscricaoListaEsperaService) {
        var vm = this;
        vm.showMore = false;
        vm.openDetails = openDetails;
        vm.findDescricaoEstabelecimento = findDescricaoEstabelecimento;
        vm.encaminhamentos = [];

        init();

        function init() {

            InscricaoListaEsperaService.getEstabelecimentos()
                .then(function (estabelecimentos) {
                    vm.estabelecimentos = estabelecimentos;
                    getEncaminhados();
                });
        }

        function getEncaminhados() {
            if (!vm.inscrito.encaminhamentos) {
                return;  
            }

            vm.inscrito.encaminhamentos.forEach(function(encaminhado) {
                vm.encaminhamentos[encaminhado.idEtapa] = {
                    turnos: encaminhado.turnos,
                    estabelecimento: findDescricaoEstabelecimento(encaminhado.idEstabelecimentoEncaminhado)
                };
            });
        }

        function findDescricaoEstabelecimento(id) {
            return _.get(_.find(vm.estabelecimentos, 'id', id), 'descricao');
        }

        function openDetails() {
            vm.showMore = !vm.showMore;
        }
    }
})();

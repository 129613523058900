(function(){
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.EnderecoController', EnderecoController);

    EnderecoController.$inject = [
        '$scope',
        'educacao.common.ModalCad'
    ];

    function EnderecoController($scope, ModalCad) {
        $scope.addEstado = function() {
            ModalCad.open({
                templateUrl: 'enderecos/estado/cadastro-estado/cadastro-estado.html',
                controller: 'educacao.enderecos.CadastroEstadoController'
            });
        };

        $scope.addMunicipio = function() {
            ModalCad.open({
                templateUrl: 'enderecos/estado/cadastro-municipio/cadastro-municipio.html',
                controller: 'educacao.enderecos.CadastroMunicipioController'
            });
        };

        $scope.addRegiao = function() {
            ModalCad.open({
                templateUrl: 'enderecos/regiao/cadastro-regiao/cadastro-regiao.html',
                controller: 'educacao.enderecos.CadastroRegiaoController'
            });
        };
    }
})();
(function () {

    'use strict';

    angular.module('educacao.encerramento')
        .factory('educacao.encerramento.EncerramentoService', EncerramentoService);

    EncerramentoService.$inject = [
        'ServiceBuilder'
    ];

    function EncerramentoService(ServiceBuilder) {
        var self = ServiceBuilder
            .create()
            .path('encerramento')
            .enum('Turno')
            .enum('EncerramentoTipoPeriodo')
            .enum('StatusEncerramento')
            .enum('TipoPeriodo')
            .enum('EquivalenciaEtapa')
            .enum('TipoAtendimento')
            .build();

        self.getEncerramentosByTurma = getEncerramentosByTurma;
        self.getIsTodosPeriodosAvaliativosEncerradosByTurma = getIsTodosPeriodosAvaliativosEncerradosByTurma;
        self.listar = listar;
        self.encerrar = encerrar;
        self.reabrir = reabrir;
        self.getIndicadores = getIndicadores;

        return self;


        function encerrar(itens) {
            return self.getEndpoint().all('periodos').post(itens);
        }

        function reabrir(itens) {
            return self.getEndpoint().all('reabertura-periodos').post(itens);
        }

        function listar(params) {
            return self.getEndpoint().get('list', params);
        }

        function getIndicadores() {
            return self.getEndpoint().get('indicadores-encerramento');
        }

        function getEncerramentosByTurma(turma) {
            return self.getEndpoint().one('encerramentos-turma', turma.id).getList();
        }

        function getIsTodosPeriodosAvaliativosEncerradosByTurma(turma) {
            return self.getEndpoint().one('encerramentos-turma', turma.id).one('todos').get();
        }
    }
})();


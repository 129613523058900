(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .directive('appCha', appCha);

    function appCha() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/configuracao-avaliacao/app-table-configuracao-avaliacao/app-cha/app-cha.directive.html',
            scope: {},
            bindToController: {
                componente: '=',
                isAtividade: '=',
                isModular: '=',
                periodos: '=',
                hasExameFinal: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'promiseTracker',
        'ui.components.Modal'
    ];

    function Controller(promiseTracker, uiModal) {
        var vm = this;

        vm.abrirModal = abrirModal;

        function abrirModal() {
            var itensAvaliaveisCha = vm.componente.$$itensAvaliaveisCha ?
                vm.componente.$$itensAvaliaveisCha : _.filter(vm.componente.itensAvaliaveis, 'tipo', 'CHA');

            uiModal.open({
                templateUrl: 'matricula/turma/configuracao-avaliacao/app-table-configuracao-avaliacao/app-cha/modal-cha.html',
                controller: 'educacao.matricula.turma.configuracao-avaliacao.ModalChaController',
                controllerAs: 'vm',
                params: {
                    componente: vm.componente,
                    isAtividade: vm.isAtividade,
                    itensAvaliaveisCha: itensAvaliaveisCha,
                    periodos: vm.periodos,
                    hasExameFinal: vm.hasExameFinal
                },
                size: 'xxl'
            }).result.then(function() {
                vm.componente.itensAvaliaveisCha = vm.componente.$$itensAvaliaveisCha;
            });
        }
    }

})();

(function () {
	'use strict';

	angular
		.module('educacao.lista-espera-novo.encaminhado')
		.controller('educacao.lista-espera-novo.encaminhado-lista-espera.EncaminhadoCtrl', Controller);

	Controller.$inject = [
		'$q',
		'bfc.Notification',
		'educacao.context.ContextoEstabelecimento',
		'educacao.lista-espera-novo.encaminhado.EncaminhadoService',
		'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
		'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils',
		'ui.components.Modal',
		'INSCRICAO_TO_MATRICULA',
		'educacao.pessoas.FiliacaoService',
		'educacao.pessoas.AlunoService',
		'bfc.$$PermissionsService',
		'promiseTracker'
	];

	function Controller($q, bfcNotification, ContextoEstabelecimento, EncaminhadoService, InscricaoListaEsperaService, InscricaoListaEsperaUtils, uiModal, INSCRICAO_TO_MATRICULA, FiliacaoService, AlunoService, PermissionsService, promiseTracker) {
		var vm = this;
		var users = [];

		vm.getNomeEstabelecimento = getNomeEstabelecimento;
		vm.getDescricaoModalidadeNivel = getDescricaoModalidadeNivel;
		vm.limparFiltro = limparFiltro;
		vm.changeModalidade = changeModalidade;
		vm.changeNivel = changeNivel;
		vm.dateFormat = dateFormat;
		vm.getChaveAcesso = getChaveAcesso;
		vm.desfazerEncaminhamento = desfazerEncaminhamento;
		vm.findDescricaoEstabelecimento = findDescricaoEstabelecimento;
		vm.findDescricaoModalidade = findDescricaoModalidade;
		vm.findDescricaoNivel= findDescricaoNivel;
		vm.openModalMatricular = openModalMatricular;
		vm.openModalCancelamento = openModalCancelamento;
		vm.getNameByUser = getNameByUser;
		vm.prazoMatricula = prazoMatricula;
		vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
		vm.service = EncaminhadoService;
		vm.hasPermissionDesfazerEncaminhamento = PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'desfazerEncaminhamento');
		vm.configuracao = {};
		vm.tracker = {
			loading: promiseTracker(),
			init: promiseTracker()
		};
		vm.columnsSearch = [
            {
                id: 'nome',
                model: 'nome',
                label: 'Nome',
                filterable: true
            }
        ];

		vm.expressionCustomBuilder = {
			onBeforeBuild: function() {
				vm.filterParams = '';

				if (vm.estabelecimento) {
					vm.filterParams = '(idEstabelecimentoEncaminhado=' + vm.estabelecimento.id + ')';
				}

				if (vm.dataEncaminhamentoDe || vm.dataEncaminhamentoAte) {
					var filterDataEncaminhamento = '';

					if (vm.dataEncaminhamentoDe && vm.dataEncaminhamentoAte) {
						filterDataEncaminhamento = '(dataEncaminhamento >= "' + vm.dataEncaminhamentoDe + 'T00:00:00" and dataEncaminhamento <= "' + vm.dataEncaminhamentoAte + 'T23:59:59")';
					} else if (vm.dataEncaminhamentoDe) {
						filterDataEncaminhamento = '(dataEncaminhamento >= "' + vm.dataEncaminhamentoDe + 'T00:00:00" and dataEncaminhamento <= "' + vm.dataEncaminhamentoDe + 'T23:59:59")';
					} else {
						filterDataEncaminhamento = '(dataEncaminhamento <= "' + vm.dataEncaminhamentoAte + 'T23:59:59")';
					}

					vm.filterParams +=  concatenaFiltro(vm.filterParams, filterDataEncaminhamento);
				}

				if (vm.dataPrazoDe || vm.dataPrazoAte) {
					var filterDataPrazo = '';
					
					if (vm.dataPrazoDe && vm.dataPrazoAte) {
						filterDataPrazo = '(dataPrazoMatricula >= ' + vm.dataPrazoDe + ' and dataPrazoMatricula <= ' + vm.dataPrazoAte + ')';
					} else if (vm.dataPrazoDe) {
						filterDataPrazo = '(dataPrazoMatricula = ' + vm.dataPrazoDe + ')';
					} else {
						filterDataPrazo = '(dataPrazoMatricula <= ' + vm.dataPrazoAte + ')';
					}

					vm.filterParams += concatenaFiltro(vm.filterParams, filterDataPrazo);
				}

				if (vm.modalidade) {
					vm.filterParams += concatenaFiltro(vm.filterParams, '(modalidade="' + vm.modalidade.modalidade + '")');
				}

				if (vm.nivel) {
					vm.filterParams += concatenaFiltro(vm.filterParams, '(nivel="' + vm.nivel.nivel + '")');
				}

				if (vm.etapa) {
					var filterEtapa = _.get(vm.nivel, 'nivel') === 'EDUCACAO_INFANTIL' ? '(etapaInfantil="' + vm.etapa.id + '")': '(etapaEquivalencia="' + vm.etapa.id + '")';
					vm.filterParams += concatenaFiltro(vm.filterParams, filterEtapa);
				}

				if (vm.turno) {
					vm.filterParams += concatenaFiltro(vm.filterParams, '(turno="' + vm.turno.turno + '")');
				}

				return vm.filterParams;
			}
		};

		init();

		function init() {
			if (!vm.isSecretaria) {
				vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
			}

			var promise = EncaminhadoService.getEtapaInfantil()
				.then(function (etapas) {
					vm.etapaInfantil = etapas;
					setModalidadeNivelEtapa();
			});

			vm.tracker.loading.addPromise(promise);

			vm.turnos = [
				{
					descricao: 'Matutino',
					turno: 'MATUTINO'
				},
				{
					descricao: 'Vespertivo',
					turno: 'VESPERTINO'
				},
				{
					descricao: 'Noturno',
					turno: 'NOTURNO'
				},
				{
					descricao: 'Integral',
					turno: 'INTEGRAL'
				}
			];

			getEstabelecimentos();
		}

		function getEstabelecimentos() {
			var promise = EncaminhadoService.getEstabelecimentos()
                .then(function (estabelecimentos) {
					
					if (vm.isSecretaria) {
						vm.listaEstabelecimento = estabelecimentos;
					} else {
						vm.listaEstabelecimento = estabelecimentos.filter(function (e) { return e.id === _.get(ContextoEstabelecimento.getEstabelecimento(), 'id'); });
					}

					vm.estabelecimentos = estabelecimentos;
                });

			vm.tracker.loading.addPromise(promise);
		}

		function concatenaFiltro(oldFilter, newFilter){
			if(!oldFilter) {
				return newFilter;
			}
			return ' and ' + newFilter;
		}

		function findDescricaoEstabelecimento(id) {
			return _.get(_.find(vm.estabelecimentos, 'id', id), 'descricao');
		}

		function findDescricaoModalidade(modalidade) {
			return _.get(_.find(vm.modalidadesPadrao, 'modalidade', modalidade), 'descricao');
		}

		function findDescricaoNivel(nivel) {
			return _.get(_.find(vm.niveisPadrao, 'nivel', nivel), 'descricao');
		}

		function getNomeEstabelecimento(descricao) {
			if (!descricao) {
				return;
			}
			return descricao.substring(0, 23);
		}

		function getDescricaoModalidadeNivel(nivelModalidade) {
			if (!nivelModalidade) {
				return;
			}

			var nivelEscolar = nivelModalidade.nivelEscolar ? ' > ' + vm.NivelEscolar[nivelModalidade.nivelEscolar].descricao : '';

			return nivelModalidade ? vm.ModalidadeMatricula[nivelModalidade.modalidade].descricao + nivelEscolar : '';
		}

		function limparFiltro() {
			delete vm.estabelecimento;
			delete vm.dataEncaminhamentoDe;
			delete vm.dataEncaminhamentoAte;
			delete vm.dataPrazoDe;
			delete vm.dataPrazoAte;
			delete vm.modalidade;
			delete vm.nivel;
			delete vm.niveis;
			delete vm.etapa;
			delete vm.etapas;
			delete vm.turno;
			delete vm.pesquisaEstabelecimento;
			delete vm.pesquisaModalidade;
			delete vm.pesquisaNivel;
			delete vm.pesquisaEtapa;
			delete vm.pesquisaTurno;
		}

		function setModalidadeNivelEtapa() {
			var etapas = [];

            vm.modalidadesPadrao = [
                {
                    descricao: 'Educação Básica',
                    modalidade: 'BASICA'
                }, {
                    descricao: 'Aceleração',
                    modalidade: 'ACELERACAO'
                }, {
                    descricao: 'EJA',
                    modalidade: 'EJA'
                }
			];

            vm.niveisPadrao = [
				{
					descricao: 'Ensino infantil',
					nivel: 'EDUCACAO_INFANTIL'
				},
				{
					descricao: 'Ensino Fundamental',
					nivel: 'ENSINO_FUNDAMENTAL'
				},
				{
					descricao: 'Ensino Médio',
					nivel: 'ENSINO_MEDIO'
				}
			];

			for (var i = 1; i <= 9; i++) {
				etapas.push(
					{
						id: 'ETAPA_' + i,
						descricao: i + '° ano',
						ano: i
					}
				);
			}

            vm.modalidadesPadrao.forEach( function (modalidade) {
				modalidade.niveis = [];

				vm.niveisPadrao.forEach(function (nivel) {
					nivel.etapas = [];

					if (modalidade.modalidade !== 'BASICA' && nivel.nivel === 'EDUCACAO_INFANTIL') {
						nivel.etapas = vm.etapaInfantil;
						return;
					}

					etapas.forEach(function (etapa) {
						if (nivel.nivel === 'ENSINO_MEDIO' && etapa.ano > 4) {
							return;
						}

						nivel.etapas.push(etapa);	 	
					});

					modalidade.niveis.push(nivel);
				});
			});

			vm.modalidades = vm.modalidadesPadrao;
        }

		function changeModalidade() {
			vm.niveis = vm.modalidade.niveis; 
			delete vm.nivel;
			delete vm.etapa;
		}

		function changeNivel() {
			vm.etapas = vm.nivel.etapas; 
			delete vm.etapa;
		}

		function dateFormat(date) {
			return moment(date).format('DD/MM/YYYY');
		}

		function openModalCancelamento(nomeCandidato, idInscricao, idConfiguracao, etapa, idEstabelecimento) {
			uiModal.open({
				templateUrl: 'lista-espera-new/inscricao/cancelar-inscricao-lista-espera/cancelar-inscricao-lista-espera.html',
				controller: 'educacao.lista-espera-novo.inscricao.CancelaInscricaoListaEspera',
				controllerAs: 'vm',
				params: {
					nomeCandidato: nomeCandidato,
					idEstabelecimento: idEstabelecimento,
					etapaId: _.get(etapa, 'nivelModalidadeEtapa.id'),
					inscricaoId: idInscricao,
					configuracaoId: idConfiguracao,
					onClose: $q.when(vm.listControls.search())
				}
			}).result.then(updatePage);
		}

		function desfazerEncaminhamento(idInscricao, idConfiguracao, idEtapa, nomeCandidato) {
			var dados = {
				idEtapa: idEtapa,
				nivel: 'ETAPA'
			};

			return InscricaoListaEsperaService.desfazerEncaminhamento(idInscricao, idConfiguracao, dados).then(function () {
				bfcNotification.publish('Encaminhamento de '+ nomeCandidato +' desfeito com sucesso.', 'success');
			}).then(updatePage);
		}

		function openModalMatricular(idInscricao, idConfiguracao, etapa, idEstabelecimento, turnos) {
			vm.configuracao.id = idConfiguracao;
			InscricaoListaEsperaService.getInscritosEditar(idConfiguracao, idInscricao)
				.then(_.bind(getParamsModalMatricular, null, etapa, idEstabelecimento, turnos));
		}

		function getParamsModalMatricular(etapa, idEstabelecimento, turnos, itemInscricao) {
			$q.all([getMunicipios(itemInscricao),
					getReligiao(itemInscricao),
					getPais(itemInscricao),
					getOrgaoEmissor(itemInscricao),
					getCartorio(itemInscricao),
					createFiliacao(itemInscricao),
					getDeficiencias(itemInscricao),
					getPessoas(itemInscricao)])
				.then(_.bind(createAlunoInscricao, null, itemInscricao))
				.then(_.bind(setParamsModal, null, etapa, idEstabelecimento, turnos, itemInscricao));
		}

		function getChaveAcesso(itemInscricao) {
			itemInscricao.$$loadingChave = promiseTracker();
			itemInscricao.$$loadingChave.addPromise(InscricaoListaEsperaService.getChaveAcesso(itemInscricao.idConfiguracao, itemInscricao.inscricao)
				.then(function (chaveAcesso) {
					itemInscricao.$$chave = chaveAcesso;
			}));			
		}

		function getMunicipios(itemInscricao){
			return InscricaoListaEsperaUtils.getMunicipios(itemInscricao)
				 .then(function (dado) {
					 vm.municipios = _.get(dado, 'content');
				 });
		}

		function getReligiao(itemInscricao) {
			if (!itemInscricao.religiao) {
				return;
			}

			return InscricaoListaEsperaService.getReligiao(itemInscricao.religiao)
				.then(function(dado){
					vm.religiao = dado;
				});
		}

		function getPais(itemInscricao) {
			if (!itemInscricao.paisNacionalidade) {
				return;
			}

			return InscricaoListaEsperaService.getPais(itemInscricao.paisNacionalidade)
				.then(function(dado){
					vm.pais = dado;
				});
		}

		function getOrgaoEmissor(itemInscricao) {
			if (!itemInscricao.orgaoEmissorRg) {
				return;
			}

			var filter = '(id in (' + itemInscricao.orgaoEmissorRg + '))';

			return InscricaoListaEsperaService.getOrgaoEmissor(filter)
				.then(function(dado){
					vm.orgaoEmissor = dado.content;
				});
		}

		function getCartorio(itemInscricao) {
			if (!itemInscricao.cartorioCertidao) {
				return;
			}

			return InscricaoListaEsperaService.getCartorio(itemInscricao.cartorioCertidao)
				.then(function(dado){
					vm.cartorioCertidao = dado;
				});
		}

		function createFiliacao(itemInscricao) {
			vm.filiacao = [];

			if (_.isEmpty(itemInscricao.filiacoes)) {
				return;
			}

			_.forEach(itemInscricao.filiacoes, function(item){
				if (item.cpf) {
					FiliacaoService.getFiliacaoById(item.cpf)
						.then(function(dado){
						    var content = _.first(dado.content);
                             if(content) {
                                buildFiliacao(content, item);
                                return;
                             }
							criaFiliacaoCasoNaoExista(item);
						});

					return;
				}

				criaFiliacaoCasoNaoExista(item);

                function criaFiliacaoCasoNaoExista(filiacao){
                    var filiacaoCreate = {pessoa:{}, vinculado:{}};

                    filiacaoCreate.pessoa ={
                        celular: filiacao.cpf,
                        cpf: filiacao.cpf,
                        email: filiacao.email,
                        nome: filiacao.nome,
                        rg: filiacao.rg,
                        sexo: filiacao.sexo,
                        telefone: filiacao.telefone
                    };
                    filiacaoCreate.vinculado = {
                        localTrabalho: filiacao.localTrabalhoDosPais,
                        permiteEnvioEmail: filiacao.permiteEmail,
                        permiteEnvioSms: filiacao.permiteSms,
                        situacaoTrabalho: filiacao.empregado ? 'EMPREGADO' : null,
                        telefoneComercial: null
                    };
					
                    FiliacaoService.save(filiacaoCreate)
                        .then(function(dado) {
                            buildFiliacao(dado, filiacao);
                        });
                }
			});
		}

		function buildFiliacao(dado, filiacao){
			delete dado.vinculado;
			vm.filiacao.push({
				diaSemana: [],
				filiacao: dado,
				tipo: filiacao.tipo
			});
		}

		function getDeficiencias(itemInscricao) {
			if (_.isEmpty(itemInscricao.deficiencias)) {
				return;
			}
			vm.alunoDeficiencias = [];

			var filter = '(id in (' + _.compact(_.pluck(itemInscricao.deficiencias, 'id')).join() + '))';

			return InscricaoListaEsperaService.getDeficiencias(filter)
				.then(function(dado){
					_.forEach(dado.content, function(deficiencia){
						vm.alunoDeficiencias.push({
							deficiencia: deficiencia,
							observacao: _.find(itemInscricao.deficiencias, 'id', deficiencia.id).observacao
						});
					});
				});
		}

		function getPessoas(itemInscricao) {
			var filter = 'cpf = "' + itemInscricao.cpf + '"';

			return InscricaoListaEsperaService.getPessoas(filter)
				.then(function (pessoa) {
					vm.pessoa = _.first(pessoa.content);
				});
		}

		function createAlunoInscricao(itemInscricao) {
			vm.aluno = {
				filiacoes: vm.filiacao,
				enderecos: createAlunoEndereco(itemInscricao.enderecoResidencia),
				pessoa: {
					telefones: itemInscricao.telefones,
					nomeFantasia: itemInscricao.nomeSocial,
					emails: itemInscricao.emails,
					nome: itemInscricao.nome,
					dataNascimento: itemInscricao.dataNascimento,
					sexo: itemInscricao.sexo,
					estadoCivil: itemInscricao.estadoCivil,
					orientacaoSexual: itemInscricao.orientacaoSexual,
					grauInstrucao: itemInscricao.grauInstrucao,
					municipioNascimento: _.find(vm.municipios, 'id', itemInscricao.municipioNascimento),
					estrangeiro: itemInscricao.estrangeiro,
					rg: itemInscricao.rg,
					ufEmissaoRg: itemInscricao.ufEmissaoRg,
					dataEmissaoRg: itemInscricao.dataEmissaoRg,
					pis: itemInscricao.pis,
					dataPis: itemInscricao.dataEmissaoPis,
					cpf: itemInscricao.cpf,
					complemento: {
						tituloEleitor: itemInscricao.tituloEleitor,
						zonaEleitoral: itemInscricao.zonaEleitoral,
						secaoEleitoral: itemInscricao.secaoEleitoral,
						numeroReservista: itemInscricao.numeroReservista,
						raca: itemInscricao.raca,
						corDosOlhos: itemInscricao.corDosOlhos,
						doadorDeOrgaos: itemInscricao.doador,
						habilidadeMotora: itemInscricao.habilidadeMotora,
					}
				},
				alunoDeficiencias: vm.alunoDeficiencias,
				permiteEnvioSms: itemInscricao.permiteEnvioSms,
				permiteEnvioEmai: itemInscricao.permiteEnvioEmail,
				religiao: vm.religiao,
				paisOrigem: vm.pais,
				naturalizado: itemInscricao.naturalizado,
				orgaoEmissorRG: _.first(vm.orgaoEmissor),
				cns: itemInscricao.cns,
				dataEmissaoCns: itemInscricao.dataEmissaoCns,
				ric: itemInscricao.ric,
				orgaoEmissorRic: itemInscricao.orgaoEmissorRic,
				ufEmissaoRic: itemInscricao.ufEmissaoRic,
				dataEmissaoRic: itemInscricao.dataEmissaoRic,
				certidaoCivil: itemInscricao.certidaoCivil,
				modeloCertidao: itemInscricao.modeloCertidao,
				numeroCertidao: itemInscricao.numeroCertidao,
				cartorioCertidao: vm.cartorioCertidao? {
					id: vm.cartorioCertidao.id,
					descricao: vm.cartorioCertidao.nome
				}: null,
				tipoSanguineo: itemInscricao.tipoSanguineo,
				fuma: itemInscricao.fuma,
				planoSaude: itemInscricao.planoSaude,
				possuiPlanoSaude: itemInscricao.possuiPlanoSaude,
				utilizaMedicacao: itemInscricao.utilizaMedicacao,
				medicacoes: itemInscricao.medicacoes,
				alergias: itemInscricao.alergias,
				numeroTermo: itemInscricao.numeroTermo,
				folhaCertidao: itemInscricao.numeroFolhaCertidao,
				livroCertidao: itemInscricao.numeroLivroCertidao,
				emissaoCertidao: itemInscricao.dataEmissaoCertidao,
				municipioCertidao: _.find(vm.municipios,'id',itemInscricao.municipioCertidao)
			};

			if (vm.pessoa) {
				vm.aluno.pessoa.id = vm.pessoa.pessoa.id;
			}
		}

		function setParamsModal(etapa, idEstabelecimento, turnos, itemInscricao) {
			var matricula = {
				tipoMatricula: INSCRICAO_TO_MATRICULA[etapa.configuracaoNivelModalidade.modalidade],
				estabelecimento: _.find(vm.estabelecimentos, 'id', idEstabelecimento),
				origemInscrito: 'listaEspera'
			};

			var filterAluno = '(pessoa.nome elike "' + encodeURI(itemInscricao.nome) +
				'" and sexo = "' + itemInscricao.sexo +
				'" and pessoa.dataNascimento = ' + itemInscricao.dataNascimento + ')';

			uiModal.open({
				templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
				controller: 'educacao.matricula.MatriculaCadCtrl',
				controllerAs: 'vm',
				size: 'xxl',
				params: {
					$$origemInscrito: 'listaEspera',
					matricula: {
						$$matriculaOrigemInscricao: matricula,
						$$bloqueioAlteracoes: true
					},
					$$alunos: AlunoService.list(filterAluno, 0, 999).then(
						function (data) {
							return data.content;
						}
					),
					$$nivelEscolar: etapa.configuracaoNivelModalidade.nivelEscolar,
					$$turnos: turnos,
					alunoInscricao: vm.aluno
				}
			})
				.result
				.then(_.bind(atualizaInscritoMatriculado, null, itemInscricao.id, etapa.nivelModalidadeEtapa.id));
		}

		function createAlunoEndereco(enderecoResidencia) {
			if (!enderecoResidencia) {
				return;
			}

			return [
				{
				  logradouro: enderecoResidencia.nomeLogradouro,
				  numero: enderecoResidencia.numero,
				  bairro: enderecoResidencia.nomeBairro,
				  municipio: _.find(vm.municipios, 'id', enderecoResidencia.municipio),
				  principal: true,
				  cep: enderecoResidencia.cep,
				  complemento: enderecoResidencia.complemento
				}
			  ];
		}

		function atualizaInscritoMatriculado(idInscrito, etapaId) {
			var dado = {
				nivel: 'ETAPA',
				idEtapa: etapaId
			};
			return InscricaoListaEsperaService.situacaoMatriculado(vm.configuracao.id, idInscrito, dado)
				.then(thenMessage)
				.then(delay);

			function delay(){
				_.delay(function(){
					updatePage();
				}, 1000);
			}
			
			function thenMessage() {
				setMessage('Matrícula de '+ vm.aluno.pessoa.nome +' realizada com sucesso.');
			}
		}

		function setMessage(message) {
			bfcNotification.publish(message, 'success');
		}

		function getNameByUser(user) {
			var userFilter = users.filter(function(data){ return data.id === user; });

			if (userFilter.length === 1) {
				vm.userName = _.get(_.first(userFilter), 'name');
				return;
			}

			EncaminhadoService.getUsers(user).then(function(data) {
				vm.userName = data.name;
				users.push({
					id: data.id,
					name: data.name
				});
			});
		}

		function prazoMatricula(data) {
			var titulo = '';
			var prazoIgual = false;
			var prazoProximo = false;
			var prazoExcedido = false;
			var dataPrazo = moment(data).format('YYYY-MM-DD');
			var dataProxima = moment(data).subtract(3, 'days').format('YYYY-MM-DD');
			var dataAtual = moment();

			if (dataAtual.isSame(dataPrazo, 'day')) {
				titulo = 'O prazo para matrícula termina hoje';
				prazoIgual = true;
			} else if (dataAtual.isAfter(dataPrazo, 'day')) {
				titulo = 'O prazo para matrícula excedeu';
				prazoExcedido = true;
			} else if (dataAtual.isAfter(dataProxima, 'day')) {
				titulo = 'O prazo para matrícula está próximo';
				prazoProximo = true;
			}

			return {
				titulo: titulo,
				prazoIgual: prazoIgual,
				prazoProximo: prazoProximo,
				prazoExcedido: prazoExcedido,
				mostrar: prazoIgual || prazoProximo || prazoExcedido
			};
		}

		function updatePage() {
			getEstabelecimentos();
			EncaminhadoService.setTotal();
			vm.listControls.search();
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoInscritosMatricularCtrl', ClassificacaoInscritosMatricularCtrl);

    ClassificacaoInscritosMatricularCtrl.$inject = ['classificacao','promiseTracker','educacao.matricula.MatricularClassificacaoService','$rootScope','$modalInstance'];

    function ClassificacaoInscritosMatricularCtrl(classificacao,promiseTracker,MatricularClassificacaoService,$rootScope,$modalInstance) {
        
        var vm = this;
        vm.tracker = {};
        vm.tracker.botoes = promiseTracker();
        vm.tracker.loading = promiseTracker();
        vm.save = save;

        vm.classificacao = classificacao;

        init();

        function init() {
            vm.tracker.loading.addPromise(
              MatricularClassificacaoService.getDefault(classificacao.classificacaoInscricao.configuracaoInscricao.id).then(function(data) {
                 vm.matriculado = angular.extend(data,  { resultado: { id: classificacao.id } });  
              })
            );                      
        }

        function save() {
            vm.tracker.botoes.addPromise(
            	MatricularClassificacaoService.matricular(classificacao.classificacaoInscricao.configuracaoInscricao.id,vm.matriculado).then(function(data){
                    $rootScope.$broadcast('refresh::classificacoes', {
                        data: data,
                        matriculado: true
                    });
                    close();
                }) 
            );            
        }

        function close() {
            $modalInstance.close();
        }

    }


})();

(function() {
    'use strict';

    angular
        .module('educacao.matricula')
        .controller('educacao.lista-espera.InscricaoListaEsperaController', Controller);

    Controller.$inject = [
        'PROPRIEDADE_MODALIDADE',

        '$scope',
        'bfc.dialog.Dialog',
        'educacao.context.ContextoEstabelecimento',
        'educacao.lista-espera.ConfiguracaoListaEsperaService',
        'educacao.lista-espera.InscricaoListaEsperaService',
        'educacao.matriz.CursoService',
        'educacao.matricula.AtividadeAEEService',
        'educacao.pessoas.EstabelecimentoServiceDeprecated',
        '$modalInstance',
        '$modalTracker',
        'id',
        'onClose',
        'educacao.enderecos.BairroService',
        'educacao.enderecos.MunicipioService',
        'educacao.enderecos.EstadoService'];

    function Controller(PROPRIEDADE_MODALIDADE,
        $scope,
        bfcDialog,
        ContextoEstabelecimento,
        ConfiguracaoListaEsperaService,
        InscricaoListaEsperaService,
        CursoService,
        AtividadeAEEService,
        EstabelecimentoService,
        $modalInstance,
        $modalTracker,
        id,
        onClose,
        BairroService,
        MunicipioService,
        EstadoService) {

        var vm = this;
        var defaultRepresentation;

        vm.editing = Boolean(id);
        vm.modalTracker = $modalTracker;

        vm.BairroService = BairroService;
        vm.MunicipioService = MunicipioService;
        vm.EstadoService = EstadoService;

        vm.propriedadeModalidade = PROPRIEDADE_MODALIDADE;

        vm.showForm = true;
        vm.inscricao = {
            dados: {},
            modalidades: []
        };

        vm.select2Options = {
            allowClear: true,
            formatSelection: formatModalidadeSelection
        };
        vm.splitModalidadeNivel = splitModalidadeNivel;
        vm.hasValue = hasValue;
        vm.isTurnoSalvo = isTurnoSalvo;
        vm.alreadySelected = alreadySelected;

        vm.hasNivelEscolar = hasNivelEscolar;
        vm.isComplementares = isComplementares;
        vm.isAee = isAee;
        vm.isAtividadesComplementares = isAtividadesComplementares;

        vm.getEquivalenciaEtapa = InscricaoListaEsperaService.getEquivalenciaEtapa();
        vm.listComplementares = listComplementares;
        vm.listAee = wrapCall(AtividadeAEEService, 'getList');

        vm.filterNivelEscolar = filterNivelEscolar;

        vm.listEstabelecimentos = listEstabelecimentos;
        vm.getNomeEstabelecimento = getNomeEstabelecimento;
        vm.addEstabecimento = addEstabecimento;
        vm.removeEstabelecimento = removeEstabelecimento;

        vm.hasTurno = hasTurno;
        vm.toggleTodosTurnos = toggleTodosTurnos;
        vm.toggleTurno = toggleTurno;

        vm.adicionaModalidade = salvaModalidade;

        vm.edit = edit;
        vm.editingKey = undefined;

        vm.remove = remove;

        vm.salvaInscricao = salvaInscricao;

        novaModalidade();

        $modalTracker.addPromise(
            ConfiguracaoListaEsperaService.get().then(function(data) {
                vm.configuracao = data;
            })
        );

        $modalTracker.addPromise(
            InscricaoListaEsperaService.getTurno().then(function(data) {
                vm.turnos = data;
            })
        );

        $modalTracker.addPromise(
            InscricaoListaEsperaService.getNivelEscolar().then(function(data) {
                vm.nivelEscolar = data;
            })
        );
        $modalTracker.addPromise(
            InscricaoListaEsperaService.getEquivalenciaEtapa().then(function(data) {
                vm.equivalenciaEtapa = data;
            })
        );

        $modalTracker.addPromise(
            InscricaoListaEsperaService.getDefaultRepresentation().then(function(data) {
                data.dados = data.dados || {};
                defaultRepresentation = data;
                vm.inscricao = _.cloneDeep(defaultRepresentation);
            })
        );

        if (id) {
            $modalTracker.addPromise(
                InscricaoListaEsperaService
                .get(id)
                .then(function(data) {
                    vm.inscricao = data;
                })
            );
        }

        $scope.$watch('vm.inscricao.modalidades', contaModalidades, true);
        $scope.$watch('vm.configuracao.permiteOutroEstabelecimento', setPermiteSelecaoEstabelecimento);

        function wrapCall(service, methodName) {
            return function() {
                return service[methodName].apply(service, arguments).then(createPage);
            };
        }

        function createPage(list) {
            return {
                content: list,
                hasNext: false
            };
        }

        function contaModalidades(modalidades) {
            vm.quantidadeModalidades = 0;

            _.forEach(modalidades, function(modalidade) {
                vm.quantidadeModalidades += modalidade.length;
            });

        }

        function setPermiteSelecaoEstabelecimento(permiteOutroEstabelecimento){
            vm.permiteOutroEstabelecimento = Boolean(permiteOutroEstabelecimento) || ContextoEstabelecimento.isSecretaria();
        }

        function novaModalidade() {
            vm.editingKey = null;
            vm.nomeModalidade = null;
            vm.modalidade = {
                status: 'INSCRITO',
                $$estabelecimentos: []
            };
            addEstabecimento();
        }

        function salvaModalidade() {
            var estabelecimentos = vm.modalidade.estabelecimentos || [];

            if (!_.isEmpty(estabelecimentos)) {
                estabelecimentos = estabelecimentos.filter(function(estabelecimentoTurno) {
                    return estabelecimentoTurno.id && _.find(vm.modalidade.$$estabelecimentos, 'estabelecimento.id',
                        estabelecimentoTurno.estabelecimento.id);
                });
            }

            vm.modalidade.$$estabelecimentos.forEach(function(estabelecimentoTurnos) {
                _.difference(estabelecimentoTurnos.$$turnos, estabelecimentoTurnos.$$turnosSalvos).forEach(function(turno) {
                    estabelecimentos.push({
                        estabelecimento: estabelecimentoTurnos.estabelecimento,
                        turno: turno
                    });
                });
            });

            vm.modalidade.estabelecimentos = estabelecimentos;

            var modalidades = vm.inscricao.modalidades[vm.nomeModalidade];

            if (vm.modalidade.$$id) {
                var index = _.findIndex(modalidades, '$$id', vm.modalidade.$$id);
                modalidades[index] = vm.modalidade;
            } else {
                modalidades.push(vm.modalidade);
            }

            novaModalidade();
        }

        function addEstabecimento() {
            vm.modalidade.$$estabelecimentos.push({
                $$turnos: [],
                $$turnosSalvos: [],
                estabelecimento: vm.permiteOutroEstabelecimento || ContextoEstabelecimento.isSecretaria() ? null : ContextoEstabelecimento.getEstabelecimento()
            });
        }

        function removeEstabelecimento(index) {
            vm.modalidade.$$estabelecimentos.splice(index, 1);
        }

        function formatModalidadeSelection(item) {
            var group = $(item.element).parent('optgroup');
            return (group.length ? '<strong>' + group.attr('label') + '</strong> > ' : '') + item.text;
        }

        function hasValue(nomeModalidade) {
            return Boolean(_.filter(vm.inscricao.modalidades[nomeModalidade], {
                status: 'INSCRITO'
            }).length);
        }

        function alreadySelected(nomeModalidade) {
            return vm.editingKey !== nomeModalidade && hasValue(nomeModalidade);
        }

        function hasNivelEscolar() {
            return Boolean(vm.modalidade.nivelEscolar) && vm.modalidade.nivelEscolar !== 'EDUCACAO_INFANTIL';
        }

        function isComplementares() {
            return vm.nomeModalidade === 'complementares';
        }

        function isAee() {
            return vm.nomeModalidade === 'atividadesAee';
        }

        function isAtividadesComplementares() {
            return vm.nomeModalidade === 'atividadesComplementares';
        }

        function splitModalidadeNivel() {
            var newValue = vm.modalidade.$$modalidadeNivelEscolar;
            if (newValue && newValue.indexOf('#') !== -1) {
                var splited = newValue.split('#');
                vm.modalidade.nivelEscolar = splited.pop();
                vm.nomeModalidade = splited.pop();
            } else {
                delete vm.modalidade.nivelEscolar;
                vm.nomeModalidade = newValue;
            }

            delete vm.modalidade.equivalencia;
            delete vm.modalidade.etapaEnsino;
            delete vm.modalidade.curso;
            delete vm.modalidade.atividade;
        }

        function listComplementares(params) {
            return CursoService.getList(params.offset, params.limit, 'descricao like "' + params.term + '" and nivelModalidade=2').then(
                createPage);
        }

        function filterNivelEscolar(value) {
            return _.last(value.id) <= (vm.modalidade.nivelEscolar === 'ENSINO_MEDIO' ? 4 : 9);
        }

        function listEstabelecimentos(params) {
            var search = 'nome like "' + params.term + '"';

            var idsEstabelecimento = [];

            vm.modalidade.$$estabelecimentos.forEach(function(est) {
                if (est.estabelecimento && est.estabelecimento.id) {
                    idsEstabelecimento.push(est.estabelecimento.id);
                }
            });

            if (!_.isEmpty(idsEstabelecimento)) {
                search += ' and id not in(' + idsEstabelecimento.join(',') + ')';
            }

            return EstabelecimentoService.getList(params.offset, params.limit, search).then(createPage);
        }

        function getNomeEstabelecimento(estabelecimento) {
            return estabelecimento.descricao || estabelecimento.pessoa.nome;
        }

        function hasTurno(inscricaoEstabelecimentos, turno) {
            return inscricaoEstabelecimentos.$$turnos.indexOf(turno.key) !== -1;
        }

        function isTurnoSalvo(inscricaoEstabelecimentos, turno) {
            return inscricaoEstabelecimentos.$$turnosSalvos.indexOf(turno.key) !== -1;
        }

        function toggleTodosTurnos(inscricaoEstabelecimentos) {
            if (inscricaoEstabelecimentos.todos) {
                inscricaoEstabelecimentos.$$turnos = vm.turnos.values.map(function(turno) {
                    return turno.key;
                });
            } else {
                inscricaoEstabelecimentos.$$turnos = [];
            }
        }

        function toggleTurno(inscricaoEstabelecimentos, turno) {
            if (hasTurno(inscricaoEstabelecimentos, turno)) {
                inscricaoEstabelecimentos.$$turnos = _.without(inscricaoEstabelecimentos.$$turnos, turno.key);
            } else {
                inscricaoEstabelecimentos.$$turnos.push(turno.key);
            }

            inscricaoEstabelecimentos.todos = _.pluck(vm.turnos.values, 'key').every(function(itm) {
                return _.includes(inscricaoEstabelecimentos.$$turnos, itm);
            });
        }

        function edit(key, modalidade) {
            vm.nomeModalidade = vm.editingKey = key;
            modalidade.$$id = modalidade.$$id || _.uniqueId();

            vm.modalidade = _.cloneDeep(modalidade);

            vm.modalidade.$$modalidadeNivelEscolar = key;
            if (vm.modalidade.nivelEscolar) {
                vm.modalidade.$$modalidadeNivelEscolar += '#' + vm.modalidade.nivelEscolar;
            }

            var estabelecimentos = {};

            _.forEach(modalidade.estabelecimentos, function(estabelecimento) {
                var estabelecimentoTurnos = estabelecimentos[estabelecimento.estabelecimento.id];

                if (!estabelecimentoTurnos) {
                    estabelecimentoTurnos = estabelecimentos[estabelecimento.estabelecimento.id] = {
                        estabelecimento: estabelecimento.estabelecimento,
                        $$turnos: [],
                        $$turnosSalvos: []
                    };
                }

                estabelecimentoTurnos.estabelecimentoSalvo = estabelecimentoTurnos.estabelecimentoSalvo || Boolean(
                    estabelecimento.id);
                estabelecimentoTurnos.$$turnos.push(estabelecimento.turno);

                if (estabelecimento.id) {
                    estabelecimentoTurnos.$$turnosSalvos.push(estabelecimento.turno);
                }
            });

            vm.modalidade.$$estabelecimentos = _.values(estabelecimentos);
        }

        function remove(key, modalidade) {
            if (modalidade.id) {
                return;
            }

            var modalidades = vm.inscricao.modalidades[key];
            if (modalidade !== modalidades) {
                vm.inscricao.modalidades[key] = _.without(modalidades, modalidade);
            } else {
                vm.inscricao.modalidades[key] = _.filter(modalidades, 'id');
            }
        }

        function salvaInscricao(addAnother) {
            $modalTracker.addPromise(
                InscricaoListaEsperaService
                .save(vm.inscricao)
                .then(abreConfirmacao)
                .then(onClose)
                .then(function() {
                    if (addAnother) {
                        novaInscricao();
                    } else {
                        $modalInstance.close();
                    }
                })
            );
        }

        function abreConfirmacao(inscricao) {
            bfcDialog.open({
                templateUrl: 'lista-espera/confirmacao/confirmacao-inscricao.html',
                controller: 'educacao.lista-espera.ConfirmacaoInscricaoController',
                controllerAs: 'vm',
                resolve: {
                    configuracao: vm.configuracao,
                    id: inscricao.id
                }
            });
        }

        function novaInscricao() {
            novaModalidade();
            vm.inscricao = _.cloneDeep(defaultRepresentation);

            $scope.form.$setPristine();
            $scope.form.$setUntouched();

            $scope.formModalidade.$setPristine();
            $scope.formModalidade.$setUntouched();
        }
    }
})();

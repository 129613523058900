(function () {
    'use strict';

    angular
        .module('educacao.quadro-informativo-utils')
        .directive('appSelectAnoLetivoQuadroInformativo', appSelectAnoLetivoQuadroInformativo);

    function appSelectAnoLetivoQuadroInformativo() {

        return {
            restrict: 'E',
            template: '<ui-select class="ano-letivo-select-quadro-informativo" ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
            ' format-result="vm.formatResult(data)" search="vm.search"/>',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?'
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['educacao.common.AnoLetivoService'];

    function Controller(Service) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            return Service
                .listAnosLetivos(params)
                .then(toPage);
        }

        function toPage(list) {
            return {
                content: _.first(list).content,
                hasMore: list.hasNext
            };
        }

        function formatResult(data) {
            return data.ano;
        }
    }
})();
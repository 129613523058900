(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('inputObito', InputObitoDirective);

    function InputObitoDirective() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/obito-directive/input-obito-directive/input-obito.directive.html',
            scope: {
                obito: '=',
                label: '@',
                ngDisabled: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        '$attrs',
        'ui.components.Modal'
    ];

    function Controller($scope, $attrs, uiModal) {

        var vm = this;

        vm.abreModalObito = abreModalObito;
        vm.focusInput = focusInput;
        vm.removeObito = removeObito;

        function abreModalObito() {
            uiModal.open({
                templateUrl: 'common/directives/obito-directive/cadastro-obito.html',
                controller: 'educacao.common.CadastroObitoController',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    dataValue: angular.copy($scope.obito)
                }
            }).result.then(function(obito) {
                $scope.obito = obito;
            });
        }

        function focusInput() {
            if ((!$scope.obito || !$scope.obito.dataObito) && !$scope.disabled) {
                abreModalObito();
            }
        }

        function removeObito() {
            delete $scope.obito.dataObito;
            delete $scope.obito.certidao;
            delete $scope.obito.dataEmissaoCertidao;
            delete $scope.obito.livro;
            delete $scope.obito.pagina;
            delete $scope.obito.causaMortis;
        }
    }

})();


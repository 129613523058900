(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appSelectLinguaIndigenaInep', SelectLinguaIndigenaInepDirective);

    SelectLinguaIndigenaInepDirective.$inject = [];

    function SelectLinguaIndigenaInepDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?'
            },
            controller: SelectLinguaIndigenaInepDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectLinguaIndigenaInepDirectiveController.$inject = ['$scope', 'educacao.common.LinguaIndigenaService'];

    function SelectLinguaIndigenaInepDirectiveController($scope, LinguaIndigenaService) {

        var vm = this;

        vm.search = search;

        function search(params) {

            var filter = '( descricao like "' + params.term + '"';

            if ($scope.multiple) {
                var linguaIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(lingua) {
                        linguaIds.push(lingua.id);
                    });
                }

                if (linguaIds.length) {
                    filter += ' and id not in(' + linguaIds.join() + ')';
                }
            }
            
            filter += ' )';

            return LinguaIndigenaService.getList(params.offset, params.limit, filter).then(toPage);
        }

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }
    }
})();

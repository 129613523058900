(function() {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.CalendarioSecretariaController', Controller);

    Controller.$inject = [
        'OBSERVACAO_POPOVER_EDIT_EVENT',
        'ESTABELECIMENTO_LIST_SELECT',
        'MATRIZ_LIST_SELECTED_EVENT',
        'VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW',

        '$scope',
        '$q',
        '$timeout',
        'promiseTracker',
        'bfc.Notification',
        'bfc.dialog.Dialog',

        'educacao.context.ContextoAnoLetivo',
        'IdGenerator',
        'CalendarioDisplay',

        'educacao.calendario.CalendarioSecretariaService',
        'educacao.calendario.CalendarioEstabelecimentoService',
        'educacao.calendario.CalendarioMatrizCurricularService',

        'educacao.calendario.RegistroVinculadoService'
    ];

    function Controller(
        OBSERVACAO_POPOVER_EDIT_EVENT,
        ESTABELECIMENTO_LIST_SELECT,
        MATRIZ_LIST_SELECTED_EVENT,
        VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW,
        $scope,
        $q,
        $timeout,
        promiseTracker,
        Notification,
        BfcDialog,
        ContextoAnoLetivo,
        IdGenerator,
        CalendarioDisplay,
        calendarioSecretariaService,
        calendarioEstabelecimentoService,
        calendarioMatrizCurricularService,
        RegistroVinculadoService
    ) {
        var vm = this;
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.calendarioDisplay = CalendarioDisplay.create();

        vm.trackers = {
            calendario: promiseTracker()
        };

        vm.openCalendarioModal = openCalendarioModal;
        vm.bloqueiaCalendarioSecretaria = bloqueiaCalendarioSecretaria;
        vm.compartilhaCalendarioSecretaria = compartilhaCalendarioSecretaria;
        vm.openModalRegistroVinculado = openModalRegistroVinculado;

        vm.goToYearView = goToYearView;

        init();

        function init() {
            $scope.$on(OBSERVACAO_POPOVER_EDIT_EVENT, onObservacaoEdited);
            $scope.$on(ESTABELECIMENTO_LIST_SELECT, addCalendarioEscola);
            $scope.$on(MATRIZ_LIST_SELECTED_EVENT, addCalendarioMatriz);

            loadCalendario();
        }

        function loadCalendario() {
            vm.trackers.calendario.addPromise(
                calendarioSecretariaService.getCalendarioAnoLetivo()
                .then(IdGenerator.generateAllId)
                .then(setCalendario)
                .then(loadAvisos)
            );
        }

        function setCalendario(calendarioSecretaria) {
            vm.isCalendarioStarted = Boolean(calendarioSecretaria);
            if (!vm.isCalendarioStarted) {
                return;
            }

            vm.isCalendarioBlocked = calendarioSecretaria.blocked;
            vm.compartilhadoEm = moment(calendarioSecretaria.compartilhadoEm).format('DD/MM/YYYY');

            vm.calendarioSecretaria = calendarioSecretaria;
            vm.calendario = calendarioSecretaria.calendario;

             $timeout(function () {
                vm.calendarioDisplay
                    .reset()
                    .setBaseCalendar(vm.calendario)
                    .addCalendario(vm.calendario, 'rede municipal')
                    .process();
            }, 10);

        }

        function loadAvisos() {
            if(!vm.calendarioSecretaria){
                return;
            }
            return calendarioSecretariaService.listAvisos(vm.calendarioSecretaria).then(function(data) {
                vm.avisos = data;
            });
        }

        function addCalendarioEscola(event, id) {
            bfc.Popover.closeAll();
            if (!id) {
                vm.calendarioDisplay
                    .reset()
                    .addCalendario(vm.calendario, 'rede municipal')
                    .process();
                return;
            }

            vm.trackers.calendario.addPromise(
                calendarioEstabelecimentoService
                .get(id)
                .then(function(calendarioEscola) {
                    vm.calendarioDisplay
                        .reset()
                        .addObservacao('rede municipal', vm.calendario.observacao, false)
                        .addFeriados(vm.calendario.feriados)
                        .addExcecoes(vm.calendario.excecoes)
                        .addCalendario(calendarioEscola.calendario, calendarioEscola.estabelecimento.descricao);
                })
                .then(vm.calendarioDisplay.process)
            );
        }

        function addCalendarioMatriz(event, id, idEstabelecimento) {
            var loadEscolaPromise = calendarioEstabelecimentoService
                .get(idEstabelecimento)
                .then(function(calendarioEscola) {
                    vm.calendarioDisplay
                        .reset()
                        .addObservacao('rede municipal', vm.calendario.observacao, false)
                        .addFeriados(vm.calendario.feriados)
                        .addExcecoes(vm.calendario.excecoes)

                    .addObservacao(calendarioEscola.estabelecimento.descricao, calendarioEscola.calendario.observacao)
                        .addEventos(calendarioEscola.calendario.eventos)
                        .addExcecoes(calendarioEscola.calendario.excecoes);
                });

            var loadMatrizPromise = calendarioMatrizCurricularService.get(idEstabelecimento, id);

            var all = $q
                .all([loadEscolaPromise, loadMatrizPromise])
                .then(function(values) {
                    var calendarioMatriz = values[1];
                    vm.calendarioDisplay
                        .addPeriodosLetivos(calendarioMatriz.periodosAvaliativos)
                        .addCalendario(calendarioMatriz.calendario, calendarioMatriz.descricao)
                        .addFiltroEventos(criaFiltroEventoMatriz(calendarioMatriz.matrizCurricular.id));
                })
                .then(vm.calendarioDisplay.process);

            vm.trackers.calendario.addPromise(all);
        }

        function criaFiltroEventoMatriz(idMatriz) {
            return function(evento) {
                return !evento.matrizesCurriculares || !evento.matrizesCurriculares.length || _.find(evento.matrizesCurriculares,
                    'id', idMatriz);
            };
        }

        function onObservacaoEdited(event, observacao) {
            var calendarioSecretaria = _.cloneDeep(vm.calendarioSecretaria);
            calendarioSecretaria.calendario.observacao = observacao;

            vm.trackers.calendario.addPromise(
                calendarioSecretariaService.save(calendarioSecretaria)
                .then(IdGenerator.generateAllId)
                .then(setCalendario)
                .then(notifyCalendarioSaved)
            );
        }

        function notifyCalendarioSaved(data) {
            Notification.publish('Calendário salvo com sucesso.', 'success');
            return data;
        }

        function openCalendarioModal() {
            var calendario = vm.isCalendarioStarted ? _.cloneDeep(vm.calendario) : {
                excecoes: [],
                feriados: [],
                eventos: []
            };

            var toEdit = CalendarioDisplay.create().setBaseCalendar(calendario)
                .addCalendario(calendario, 'rede municipal');

            BfcDialog.open({
                templateUrl: 'calendario/cadastro/calendario-modal.html',
                controller: 'educacao.calendario.CalendarioModalController as vm',
                resolve: {
                    fnCad: $q.when(onCalendarioSaved),
                    calendarioDisplay: toEdit
                }
            });
        }

        function onCalendarioSaved(calendario, blocked) {            
            return saveCalendario(calendario, blocked).then(notifyCalendarioSaved);
        }

        function saveCalendario(calendario, blocked) {
            var calendarioSecretaria = _.clone(vm.calendarioSecretaria || {});
            calendarioSecretaria.calendario = calendario;
            calendarioSecretaria.blocked = blocked;

            var savePromise = calendarioSecretariaService.save(calendarioSecretaria)
                .then(IdGenerator.generateAllId)
                .then(setCalendario);

            vm.trackers.calendario.addPromise(savePromise);

            return savePromise;
        }

        function bloqueiaCalendarioSecretaria() {
            saveCalendario(vm.calendario, true)
                .then(notificaCalendarioBloqueadoSucesso);
        }

        function notificaCalendarioBloqueadoSucesso(data) {
            Notification.publish('Calendário bloqueado com sucesso.', 'success');
            return data;
        }

        function compartilhaCalendarioSecretaria() {
            saveCalendario(vm.calendario, false)
                .then(notificaCalendarioCompartilhadoSucesso);
        }

        function notificaCalendarioCompartilhadoSucesso(data) {
            Notification.publish('Calendário compartilhado com sucesso.', 'success');
            return data;
        }

        function goToYearView() {
            $scope.$broadcast(VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW);
        }

        function openModalRegistroVinculado() {
            RegistroVinculadoService.openModal();
        }
    }
})();


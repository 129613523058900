(function() {

    'use strict';

    angular.module('educacao.entidade').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.cadastroEntidade', {
            abstract: true,
            data: {
                id: 'EducacaoEntidadePageMapping',
                mirrorOf: 'app.planejamento.anosletivos'
            }
        });
    }
})();


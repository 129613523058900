(function() {
	'use strict';

	angular.module('educacao.feriado').config(stateConfig);

	stateConfig.$inject = [
		'$stateProvider',
		'STATES_ADICIONAR',
        'STATES_EDITAR'
	];

	function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
		var STATE = 'app.feriado';
		$stateProvider.state(STATE, {
			url: 'feriados',
			data: {
				id: 'CadastroFeriadoPageMapping'
			},
			views: {
				'@': {
					templateUrl: 'feriados/feriados.html',
					controller: 'educacao.feriado.FeriadosCtrl',
					controllerAs: 'vm'
				}
			}
		}).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.feriado.FeriadoService',
            '$modalStates'
        ];

        function openModal($stateParams, FeriadoService, $modalStates) {
            $modalStates.open({
                getMethod: FeriadoService.get,
                templateUrl: 'feriados/feriados.cad.html',
				controller: 'educacao.feriado.FeriadosCadCtrl as vm',
                propertyName: 'feriado',
                stateParams: $stateParams
            });
        }
	}
})();


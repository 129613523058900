(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.AlunoCadCtrl', AlunoCadCtrl);

    AlunoCadCtrl.$inject = [
        '$scope',
        '$modalInstance',
        'promiseTracker',
        'bfc.Focus',
        'educacao.pessoas.AlunoService',
        'educacao.pessoas.PessoaService',
        'bfc.Notification'
    ];

    function AlunoCadCtrl(
        $scope,
        $modalInstance,
        promiseTracker,
        focus,
        AlunoService,
        PessoaService,
        Notification) {

        var vm = this,
            RETURN_MODAL_COMP = 'returnModalComp:Aluno';

        vm.aluno = $modalInstance.params.aluno || $modalInstance.params.alunoInscricao || {};
        vm.disableExcluir = $modalInstance.params.disableExcluir;
        vm.bloqueioAlteracoes = $modalInstance.params.bloqueioAlteracoes;
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd;
        if (!_.get(vm.aluno, 'responsaveis')) {
            vm.aluno.responsaveis = [];
        }
        if (!_.get(vm.aluno, 'filiacoes')) {
            vm.aluno.filiacoes = [];
        }

        vm.windowTitle = !vm.aluno.id ? 'Adicionando aluno(a)' : 'Editando aluno(a)';
        vm.tracker = {
            loading: promiseTracker(),
            save: promiseTracker(),
            saveContinue: promiseTracker(),
            remove: promiseTracker(),
            camposAdicionais: promiseTracker()
        };
        vm.AlunoService = AlunoService;
        vm.searchMethodPessoa = AlunoService.getPessoas;
        vm.cancel = cancel;
        vm.save = save;
        vm.remove = remove;
        vm.enumSexo = AlunoService.getSexo();
        vm.focar = focar;
        vm.pessoaSelecionada = pessoaSelecionada;

        focus('nomeAluno');
        initCamposAdicionais();
        initRestricoes();

        $scope.$watchCollection('vm.aluno.enderecos', removeCepInvalidChars);

        function initRestricoes() {
            vm.aluno.restricoesIngredientes = [];
            vm.aluno.restricoesNutrientes = [];

            if (_.get(vm.aluno, 'restricoes.length')) {
                var nutriente = _.filter(vm.aluno.restricoes, function(obj) {
                    return obj.tipo === 'NUTRIENTE';
                });
                var ingrediente = _.filter(vm.aluno.restricoes, function(obj) {
                    return obj.tipo === 'INGREDIENTE';
                });
                _.forEach(ingrediente, function(value) {
                    vm.aluno.restricoesIngredientes.push(value);
                });
                _.forEach(nutriente, function(value) {
                    value.ingrediente = value.nutriente;
                    vm.aluno.restricoesNutrientes.push(value);
                });
            }
        }

        function removeCepInvalidChars(addresses) {
            _.forEach(addresses, function(address) {
                if (address.cep !== null) {
                    address.cep = _.padRight(address.cep.split('-').join(''), 8, '0');
                }
            });
        }

        function focar(id, tab) {
            vm.currentTab = tab;
            focus(id);
        }

        function initCamposAdicionais() {
            var alunoId = _.get(vm.aluno, 'id');

            var promise = AlunoService.camposAdicionais(alunoId)
                .then(function(data) {
                    vm.campoAdicional = data;
                });
            vm.tracker.loading.addPromise(promise);
            vm.tracker.camposAdicionais.addPromise(promise);
        }

        function novoUsuario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            initCamposAdicionais();

            vm.aluno = {
                responsaveis: [],
                filiacoes: [],
                enderecos: [],
                pessoa: {
                    telefones: [],
                    emails: []
                },
                alunoDeficiencias: [],
                alunoObservacoes: [],
                alunoProgramasSociais: [],
                restricoesIngredientes: [],
                restricoesNutrientes: []
            };
            focus('nomeAluno');
        }

        function onSuccess(continuar) {
            if (continuar) {
                novoUsuario();
                focus('nomeAluno');
                return;
            }
            $modalInstance.close(vm.alunoSalvo);
        }

        function save(continuar) {
            vm.tracker.loading = continuar ? vm.tracker.saveContinue : vm.tracker.save;
            vm.aluno.campoAdicional = angular.copy(vm.campoAdicional);
            vm.aluno.restricoes = [];

            _.forEach(vm.aluno.restricoesIngredientes, function(value) {
                value.tipo = 'INGREDIENTE';
                vm.aluno.restricoes.push(value);
            });
            _.forEach(vm.aluno.restricoesNutrientes, function(value) {
                value.tipo = 'NUTRIENTE';
                vm.aluno.restricoes.push(value);
            });

            vm.tracker.loading.addPromise(
                AlunoService.save(vm.aluno)
                    .then(function(data) {
                        vm.alunoSalvo = data;
                        onSuccess(continuar);
                    })
                    .then(notificar)
            );
        }

        function notificar() {
            Notification.publish('Aluno salvo com sucesso', 'success');
        }

        function remove() {
            vm.tracker.loading = vm.tracker.remove;
            vm.tracker.loading.addPromise(
                AlunoService.remove(vm.aluno).then(function() {
                    onSuccess();
                })
            );
        }

        function cancel() {
            $modalInstance.close();
        }

        function pessoaSelecionada(aluno) {
            if (!aluno) {
                return;
            }
            if (aluno._id) {
                var load = PessoaService.getPessoas(aluno._id)
                    .then(_.bind(onLoadPessoa, null, aluno._id));
                return vm.tracker.loading.addPromise(load);
            }
        }

        function onLoadPessoa(idTemplate, pessoa) {
            novoUsuario();
            vm.aluno.pessoa = pessoa;
            vm.aluno.filiacoes = pessoa.filiacoes;
            delete vm.aluno.pessoa.filiacoes;
        }


        $scope.$on(
            RETURN_MODAL_COMP,
            function(events, args) {
                vm.aluno = args.data;
            }
        );

    }
})();

(function() {

    'use strict';

    angular.module('educacao.diarioclasse')
        .service('educacao.diarioClasse.ImpressaoDiarioClasseService', ImpressaoDiarioClasseService);

    ImpressaoDiarioClasseService.$inject = ['$timeout', '$window'];

    function ImpressaoDiarioClasseService($timeout, $window) {
        var self = this;
        
        self.imprimir = imprimir;
        
        function imprimir(elementId) {
            return $timeout(function() {
                var contents = $(elementId).html();
                var frame1 = $('<iframe />');
                frame1[0].name = 'frame1';
                frame1.css({
                    'position': 'absolute',
                    'top': '-1000000px'
                });
                $('body').append(frame1);
                var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[
                    0].contentDocument.document : frame1[0].contentDocument;
                frameDoc.document.open();
                //Create a new HTML document.
                frameDoc.document.write('<html><head><title>Educação</title>');
                frameDoc.document.write('</head><body>');
                //Append the external CSS file.

                frameDoc.document.write('<link href="/bth-design/dist/bth-design.css" rel="stylesheet" type="text/css" />');
                frameDoc.document.write(
                    '<link href="/mini-calendar/dist/mini-calendar.css" rel="stylesheet" type="text/css" />');
                frameDoc.document.write('<link href="/styles/sys.css" rel="stylesheet" type="text/css" />');
                frameDoc.document.write('<link href="/styles/print.css" rel="stylesheet" type="text/css" />');

                frameDoc.document.write('<link href="css/vendor.css" rel="stylesheet" type="text/css" />');
                frameDoc.document.write('<link href="css/app.css" rel="stylesheet" type="text/css" />');

                //Append the DIV contents.
                frameDoc.document.write(contents);
                frameDoc.document.write('</body></html>');
                frameDoc.document.close();
                return $timeout(function() {
                    $window.frames.frame1.focus();
                    $window.frames.frame1.print();
                    frame1.remove();
                }, 500);
            }, 800);
        }
    }

})();
(function() {
    'use strict';

    angular.module('educacao.diarioclasse')
        .directive('cabecalhoDiarioClasseDetails', CabecalhoDetails);

    function CabecalhoDetails() {

        return {
            restrict: 'E',
            templateUrl: 'impressao-diario-classe-desempenho/cabecalho-template-diario-classe.html',
            scope: {
                turma: '=?',
                estabelecimento: '=?',
                diario: '=?',
                item: '=',
                titulo: '=',
                anoLetivo: '=',
                entidade: '='
            },
            controller: Controller

        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        $scope.getInformacaoPeriodo = informacaoPeriodo;
        $scope.getPeriodoAvaliativo = getPeriodoAvaliativo;

        function informacaoPeriodo(item) {
            var dataInicial = getDataInicialPeriodo(item);
            var dataFinal = getDataFinalPeriodo(item),
                infoDatePeriodo;

            if (dataInicial) {
                infoDatePeriodo = moment(dataInicial).format('DD/MM/YYYY');
            }

            if (dataFinal) {
                if (infoDatePeriodo) {
                    infoDatePeriodo += ' a ' + moment(dataFinal).format('DD/MM/YYYY');
                }
                if (!infoDatePeriodo) {
                    infoDatePeriodo = moment(dataInicial).format('DD/MM/YYYY');
                }
            }
            return infoDatePeriodo;
        }

        function getPeriodoAvaliativo(item) {
            if ($scope.diario.tipoItemEducacional === 'AVALIACAO') {
                return item.descricaoPeriodo ?  'Período avaliativo: ' + item.descricaoPeriodo : 'Período: ' + $scope.anoLetivo;
            }

            if ($scope.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO') {
                return 'Período avaliativo: ' + item.descricao + ' - ' + $scope.anoLetivo;
            } else if ($scope.diario.tipoItemEducacional === 'ATIVIDADE') {
                return 'Período: ' + $scope.anoLetivo;
            } else {
                return $scope.diario.configuracao.periodoAvaliativoCorrente ? 'Período avaliativo: ' + $scope.diario.configuracao.periodoAvaliativoCorrente
                    .descricao : '';
            }
        }

        function getDataInicialPeriodo(item) {

            if ($scope.diario.tipoItemEducacional === 'AVALIACAO' || $scope.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO' || $scope.diario.tipoItemEducacional === 'ATIVIDADE') {
                return item.dataInicio;
            } else {
                return $scope.diario.configuracao.periodoAvaliativoCorrente ? $scope.diario.configuracao.periodoAvaliativoCorrente.dataInicial :
                    null;
            }
        }

        function getDataFinalPeriodo(item) {
            if ($scope.diario.tipoItemEducacional === 'AVALIACAO' || $scope.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO' || $scope.diario.tipoItemEducacional === 'ATIVIDADE') {
                return item.dataFinal;
            } else {
                return $scope.diario.configuracao.periodoAvaliativoCorrente ? $scope.diario.configuracao.periodoAvaliativoCorrente.dataFinal :
                    null;
            }
        }
    }
})();


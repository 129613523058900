(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .controller('educacao.lista-espera-novo.configuracao.appConfiguracaoListaEsperaVisualizarMensagem', appConfiguracaoListaEsperaVisualizarMensagem);

    appConfiguracaoListaEsperaVisualizarMensagem.$inject = [
        '$modalInstance',
        '$scope'
    ];

    function appConfiguracaoListaEsperaVisualizarMensagem($modalInstance, $scope) {
        $scope.mensagem = $modalInstance.params.mensagem;
    }

})();

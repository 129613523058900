(function() {

    'use strict';

    var moduloCommon = angular.module('educacao.common');

    moduloCommon.factory('educacao.common.ObjectUtilsService', function() {

        /**
         * [_copyProperties copiar todas as propriedades mantendo a referência]
         * @param target [referência de um objeto que terá seus atributos e métodos copiados do source.]
         * @param source [contém os atributos e métodos que serão copiados no target]
         */

        function _copyProperties(target, source) {

            if (target && typeof target !== typeof source) {
                throw new Error('A origem e o destino devem ser do mesmo tipo de dado.');
            }

            angular.forEach(target, function(value, key) {
                delete target[key];
            });

            _.merge(target, source);
        }

        
		function getFilterOptions(from, field, uniqueField){
			
			var listOptions = [], last;
			
			from.map(function(item){
				return item[field];
			})
			.sort(function(itemA,itemB){
				
				if(itemA[uniqueField]>itemB[uniqueField]){
					return 1;
				}	
				
				if(itemA[uniqueField]<itemB[uniqueField]){
					return -1;
				}
				
				return 0;
			})
			.forEach(function(item){
				if(!last){
					last = item;
					listOptions.push(item);
				}else{
					
					if(item[uniqueField]!==last[uniqueField]){
						listOptions.push(item);
					}
					last = item;
				}
			});
			
			return listOptions;
			
		}
		
		function _filterByField(lista, field, param) {

            var specialChars = '!@#$^&*()+=-[]\/{}|:<>?,.';

            for (var i = 0; i < specialChars.length; i++) {
                param = param.replace(new RegExp('\\' + specialChars[i], 'g'), '\\' + specialChars[i]);
            }

            //como o component passa o critério com os caracteres '%', é necessário remove-los
            var regex = new RegExp(normalize(param).replace(new RegExp('\\%', 'gm'), ' ').trim());
            var filtradas = [];
            _.forEach(lista, function(el) {
                if (regex.test(normalize(el[field]))) {
                    filtradas.push(el);
                }
            });

            return filtradas;
        }

        function normalize(value) {
            return value.toLowerCase()
                .replace(/ã/g, 'a')
                .replace(/á/g, 'a')
                .replace(/â/g, 'a')
                .replace(/é/g, 'e')
                .replace(/è/g, 'e')
                .replace(/ê/g, 'e')
                .replace(/í/g, 'i')
                .replace(/ï/g, 'i')
                .replace(/ì/g, 'i')
                .replace(/ó/g, 'o')
                .replace(/ô/g, 'o')
                .replace(/ú/g, 'u')
                .replace(/ü/g, 'u')
                .replace(/ç/g, 'c')
                .replace(/ß/g, 's')
                .toUpperCase()
                .trim();
        }

        function _filterByFieldWithSort(lista, field, param) {

            return _.sortBy(_filterByField(lista, field, param), function(obj) {
                return obj[field].toUpperCase();
            });
        }

        /**
         * [_replaceProperties substituir as propriedades, definidas, do source para o target]
         * @param  {[type]} target     [referência de um objeto que terá suas propriedades substituidas.]
         * @param  {[type]} source     [contém as propriedades que serão substituidas no target]
         * @param  {[type]} properties [propriedades que serão substituidas no target]
         */

        function _replaceProperties(target, source, properties) {
            if (target && typeof target !== typeof source) {
                throw new Error('A origem e o destino devem ser do mesmo tipo de dado.');
            }

            if (!properties) {
                throw new Error('O atributo \'properties\' deve ser informado!');
            }

            if (!Array.isArray(properties)) {
                throw new Error('O atributo \'properties\' deve ser um array!');
            }

            if (properties.length <= 0 || typeof properties[0] !== 'string') {
                throw new Error('O array deve ter um conteúdo e deve ser um array de string!');
            }

            angular.forEach(source, function(value, key) {
                for (var j = 0; j < properties.length; j++) {
                    if (properties[j] === key) {
                        target[properties[j]] = value;
                    }
                }
            });
        }

        function _getStringBuilder() {
            var data = [];
            var counter = 0;
            return {
                //Adiciona a string 's' ao builder
                append: function(s) {
                    data[counter++] = s;
                    return this;
                },
                //remove 'j' elementos começando na posição 'i', ou 1 caso a posicao i nao seja informada
                remove: function(i, j) {
                    data.splice(i, j || 1);
                    return this;
                },
                //Inseri uma string na posicao 'i'
                insert: function(i, s) {
                    data.splice(i, 0, s);
                    return this;
                },
                //retorna a String construida
                toString: function(s) {
                    return data.join(s || '');
                }
            };

        }

        var _getMascaraTelefone = function(value) {
            if (!!value) {
                return value.length <= 10 ? '(00) 0000-0000' : '(00) 00000-0000';
            }
            return '(00) 0000-0000';
        };

        return {
            copyProperties: _copyProperties,
            replaceProperties: _replaceProperties,
            filterByField: _filterByField,
            filterByFieldWithSort: _filterByFieldWithSort,
            getStringBuilder: _getStringBuilder,
            normalize: normalize,
            normalizeFilter: function(filter) {

                //TODO banco de dados não suporta a consulta com mais de 4000 caracteres
                if (filter.length >= 3990) {
                    filter = filter.substr(0, 3990);
                }

                return filter.replace(/'/gi, '_');
            },
            getMascaraTelefone: _getMascaraTelefone,
			getFilterOptions: getFilterOptions
        };

    });

})();

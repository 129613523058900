(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .directive('appResumoAlunosQuadroTurmaAlunos', appResumoAlunosQuadroTurmaAlunosDirective);

    function appResumoAlunosQuadroTurmaAlunosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/turma-alunos/resumo-alunos/app-resumo-alunos-quadro-turma-alunos.directive.html',
            scope: {},
            bindToController: {
                anoletivoselecionado: '=',
                estabelecimentoselecionado: '=',
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        'educacao.turma-alunos-quadro-informativo.GraficoTurmaAlunosService',
        'promiseTracker',
        '$scope'
    ];

    function Controller(ContextoAnoLetivo, ContextoEstabelecimento, QuadroFuncionariosTurmaAlunosService, GraficoTurmaAlunosService, promiseTracker, $scope) {
        var vm = this;
        vm.graficosAlunos = {};
        vm.loadingTracker = {
            graficoAlunosPorModalidade: promiseTracker(),
            graficoAlunosEnsinoMedioEja: promiseTracker(),
            graficoAlunosEnsinoMedio: promiseTracker(),
            graficoAlunosEnsinoFundamental: promiseTracker(),
            graficoAlunosEnsinoFundamentalEja: promiseTracker(),
            graficoAlunosEnsinoInfantil: promiseTracker(),
            graficoAlunosComDeficiencia: promiseTracker(),
            graficoAlunosEnsinoMedioAceleracao: promiseTracker(),
            graficoAlunosEnsinoFundamentalAceleracao: promiseTracker(),
            graficoAlunosEducacaoBasica: promiseTracker(),
            graficoAlunosEja: promiseTracker(),
            graficoAlunosAceleracao: promiseTracker(),
        };


        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.paths = [
            'modalidade',
            'etapaEnsinoMedio',
            'etapaEnsinoMedioEja',
            'etapaEnsinoMedioAceleracao',
            'etapaEnsinoFundamental',
            'etapaEnsinoFundamentalEja',
            'etapaEnsinoFundamentalAceleracao',
            'etapaEnsinoInfantil',
            'deficiencia',
            'educacaoBasica',
            'eja',
            'aceleracao',
        ];

        $scope.$watch('vm.anoletivoselecionado', onChangeContextAnoLetivo);
        $scope.$watch('vm.estabelecimentoselecionado', onChangeContextEstabelecimento);
        init();
        vm.idsAlunosComDeficiencia = null;
        vm.redirectAlunos = [];


        function onChangeContextAnoLetivo(oldValue, newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }

        function onChangeContextEstabelecimento(oldValue, newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }


        function reloadValues() {
            GraficoTurmaAlunosService.clearValues();
            _.forEach(vm.paths, clearValue);
            init();
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoletivoselecionado, 'id');
            paramns.estabelecimentoId = _.get(vm.estabelecimentoselecionado, 'id');
            return paramns;
        }

        function clearValue(path) {
            vm.graficosAlunos[path] = null;
        }

        function addRedirectAlunosComDeficiencia() {
            var promise = QuadroFuncionariosTurmaAlunosService.getIdsAlunosComDeficiencia(resolveParamns()).then(function (data) {
                if (!_.isEmpty(data)) {
                    vm.redirectAlunos = [{
                        'position': 1,
                        linkRedirect: 'app.planejamento.alunos',
                        params: {
                            'filterDefiencias': true,
                            'ids': _.map(data, 'id').join(',')
                        }
                    }];
                }
            });
            vm.loadingTracker.graficoAlunosComDeficiencia.addPromise(promise);
        }

        function init() {
            addRedirectAlunosComDeficiencia();
            getGraficoAlunos('modalidade', 'graficoAlunosPorModalidade', 'getGraficoAlunosPorModalidade');
            getGraficoAlunos('etapaEnsinoMedio', 'graficoAlunosEnsinoMedio', 'getGraficoAlunosEnsinoMedio');
            getGraficoAlunos('etapaEnsinoMedioEja', 'graficoAlunosEnsinoMedioEja', 'getGraficoAlunosEnsinoMedioEja');
            getGraficoAlunos('etapaEnsinoMedioAceleracao', 'graficoAlunosEnsinoMedioAceleracao', 'getGraficoAlunosEnsinoMedioAceleracao');
            getGraficoAlunos('etapaEnsinoFundamental', 'graficoAlunosEnsinoFundamental', 'getGraficoAlunosEnsinoFundamental');
            getGraficoAlunos('etapaEnsinoFundamentalEja', 'graficoAlunosEnsinoFundamentalEja', 'getGraficoAlunosEnsinoFundamentalEja');
            getGraficoAlunos('etapaEnsinoFundamentalAceleracao', 'graficoAlunosEnsinoFundamentalAceleracao', 'getGraficoAlunosEnsinoFundamentalAceleracao');
            getGraficoAlunos('etapaEnsinoInfantil', 'graficoAlunosEnsinoInfantil', 'getGraficoAlunosEnsinoInfantil');
            getGraficoAlunos('deficiencia', 'graficoAlunosComDeficiencia', 'getGraficosAlunosComDeficiencia');
            getGraficoAlunos('educacaoBasica', 'graficoAlunosEducacaoBasica', 'getGraficoTotalAlunosEducacaoBasicaPorNivelEscolar');
            getGraficoAlunos('eja', 'graficoAlunosEja', 'getGraficoTotalAlunosEjaPorNivelEscolar');
            getGraficoAlunos('aceleracao', 'graficoAlunosAceleracao', 'getGraficoTotalAlunosAceleracaoPorNivelEscolar');

        }


        function getGraficoAlunos(path, trackerName, serviceName) {
            var promise = GraficoTurmaAlunosService.getGraficoAlunos(path, serviceName, resolveParamns())
                .then(function (data) {
                    vm.graficosAlunos[path] = data;
                });
            vm.loadingTracker[trackerName].addPromise(promise);
        }

    }

})();
(function() {
    'use strict';

    angular.module('educacao.legislacao')
        .directive('appSelectLegislacao', SelectLegislacaoDirective);

    function SelectLegislacaoDirective() {
        return {
            restrict: 'E',
            templateUrl: 'legislacao/app-select-legislacao/app-select-legislacao-template.html',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                legislacoesExistentes: '=?',
                ngChange: '&?'
            },
            controller: SelectLegislacaoDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }
                if (angular.isDefined(tAttrs.ngRequired)) {
                    element.children('ui-select').attr('not-empty', 'ngRequired');
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectLegislacaoDirectiveController.$inject = [
    '$scope',
    'ui.components.Modal',
    'bfc.$$PermissionsService',
    'educacao.legislacao.LegislacaoService'];

    function SelectLegislacaoDirectiveController($scope, uiModal, PermissionsService, LegislacaoService) {

        var vm = this;

        vm.searchLegislacao = searchLegislacao;
        vm.add = !PermissionsService.isRevokedOperation('LegislacaoPageMapping', 'criar') && add;

        function searchLegislacao(params) {
            var filter = '(descricao like "' + params.term + '"';
            var legislacoes = [];

            if ($scope.multiple) {
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(legislacao) {
                        legislacoes.push(legislacao.id);
                    });
                }
            }

            if (angular.isArray($scope.legislacoesExistentes) && $scope.legislacoesExistentes.length > 0) {
                _.forEach($scope.legislacoesExistentes, function(value) {
                    if (value.legislacao && value.legislacao.id) {
                        legislacoes.push(value.legislacao.id);
                    }
                });
            }

            if (legislacoes.length) {
                filter += ' and id not in(' + legislacoes.join() + ')';
            }
            filter += ')';

            return LegislacaoService.list(filter, params.offset, params.limit).then(function(data) {
                return {
                    content: data.content,
                    hasNext: data.hasNext
                };
            });

        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'legislacao/legislacao.cad.html',
                controller: 'educacao.legislacao.LegislacaoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    legislacao: {
                        descricao: descricao
                    }
                }
            }).result;
        }
    }

})();


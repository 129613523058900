(function() {
	'use strict';
	angular.module('educacao.pessoas').directive('appMedicaoAntropometrica', inputMedicaoAntropometrica);

	function inputMedicaoAntropometrica() {
		return {
			restrict: 'E',
			template: '<ed-input-tags data-ng-model="ngModel" formatter="vm.formatterTag" data-ng-click="vm.click()" not-empty="ngRequired" />',
			require: 'ngModel',
			scope: {
				ngModel: '=?',
				ngRequired: '=',
				nascimento: '='
			},
			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = ['$scope', '$element', 'educacao.pessoas.MedicaoAntropometricaService', '$filter'];

	function Controller($scope, $element, MedicaoAntropometricaService, $filter) {
		var vm = this;

		vm.click = click;
		vm.formatterTag = formatterTag;

		if (!$scope.ngModel) {
			$scope.ngModel = [];
		}

		function click() {
			MedicaoAntropometricaService.open($scope.ngModel, $scope.nascimento)
				.result
				.then(onClose);
		}

		function formatterTag(medicoes) {
			return $filter('appEstatura')(medicoes.estatura) + ' - ' + $filter('appPeso')(medicoes.peso) ;
		}

		function onClose(medicoes) {
			$element.find('input').focus();
			$scope.ngModel = medicoes;
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('avisosClassificacao', avisos);



    function avisos() {

        var config = {};

        config.templateUrl = 'matricula/classificacao-inscritos/directives/avisos-classificacao.directive.html';
        config.restrict = 'E';
        config.scope = {
            avisos: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;
    }

    Controller.$inject = ['$scope', 'promiseTracker'];


    function Controller($scope, promiseTracker) {

        var vm = this;

        vm.tracker = {
            loading: promiseTracker()
        };

        init();

        function init() {
            vm.avisos = $scope.avisos;
        }
    }

})();

(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .service('educacao.desempenhoescolar.DesempenhoEscolarYearViewService', DesempenhoEscolarYearViewService);

    DesempenhoEscolarYearViewService.$inject = [
        'educacao.desempenhoescolar.DesempenhoEscolarNota',
        'educacao.desempenhoescolar.DesempenhoEscolarUtilsService',
        'educacao.itemAvaliavel.ItemAvaliavelUtilsService',
        'ITEM_AVALIAVEL_TIPO_PERIODO'
    ];

    function DesempenhoEscolarYearViewService(
        Nota,
        DesempenhoEscolarUtilsService,
        ItemUtilsService,
        ITEM_AVALIAVEL_TIPO_PERIODO) {

        var self = this;

        self.createYearView = createYearView;

        function createYearView(registros, itemEducacional, itensAvaliaveis, periodos, enturmacoes) {

            var avaliacoes = registros.avaliacoes;
            var abonos = registros.abonos;
            var linhas, colunas, colunaMediaFinal;

            avaliacoes = _.filter(registros.avaliacoes, function(avaliacao){
                return _.get(avaliacao, 'avaliavel.itemEducacional.id') === itemEducacional.id && _.get(avaliacao, 'avaliavel.tipoPeriodo') !== 'INSTRUMENTO' && _.get(avaliacao, 'avaliavel.tipoPeriodo') !==  'CALCULO_INSTRUMENTOS';
            });

            var mapAvaliacoesEnturmacaoPeriodo = {};
            _.forEach(avaliacoes, function (avaliacao) {
                mapAvaliacoesEnturmacaoPeriodo[getKeyAvaliacao(avaliacao)] = Nota.from(avaliacao, enturmacoes, itensAvaliaveis);
            });

            var mapAbonoPeriodo = {};
            _.forEach(abonos, function (abono) {
                mapAbonoPeriodo[getKeyAbono(abono)] = true;
            });

            colunas = _.filter(periodos, filterColunasValidas);

            //Armazena coluna de média final para exibila fora do scroll
            colunaMediaFinal = _.find(periodos, 'id', ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL);

            linhas = [];

            _.forEach(enturmacoes, function (enturmacao) {

                var enturmacaoSituacaoItem = _.find(enturmacao.situacaoItensEducacionais, function (item) {
                    return _.get(item.itemEducacional, 'id') === itemEducacional.id;
                });

                enturmacao.situacao = _.get(enturmacaoSituacaoItem, 'situacao') || enturmacao.matricula.situacao;

                var linha = {
                    enturmacao: enturmacao,
                    notas: []
                };
                linhas.push(linha);

                //Cria as células de cada enturmação para cada período do scroll
                _.forEach(colunas, function (periodo, index) {
                    linha.notas[index] = getNota(enturmacao, periodo);
                });

                //Cria célula de exame final
                linha.notaMediaFinal = getNota(enturmacao, colunaMediaFinal);
            });

            return {
                linhas: linhas,
                colunas: colunas,
                colunaMediaFinal: colunaMediaFinal
            };

            /**
             * Retorna objeto de registro de avaliação criado ou recuperado. 
             */
            function getNota(enturmacao, periodo) {
                var key = String(enturmacao.id) + periodo.id;
                var nota = mapAvaliacoesEnturmacaoPeriodo[key] || new Nota(enturmacao, itemEducacional, periodo, itensAvaliaveis);

                if(_.get(enturmacao, 'matricula.avaliacaoDescritiva')){
                    nota.$$editavel = DesempenhoEscolarUtilsService.isItemEditavel(_.get(nota.avaliavel, 'tipoPeriodo'), 'DESCRITIVO');
                }

                nota.$$abonado = Boolean(mapAbonoPeriodo[key]);

                return nota;
            }

            function getKeyAvaliacao(avaliacao) {
                return String(avaliacao.enturmacao.id) +
                    (_.get(avaliacao, 'avaliavel.periodoAvaliativo.id') || (_.get(avaliacao, 'avaliavel.idPeriodo')) || 
                    avaliacao.avaliavel.tipoPeriodo);
            }

            function getKeyAbono(abono) {
                return String(abono.enturmacao.id) + (_.get(abono.periodoAvaliativo, 'id') || ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO);
            }

            //Filtra as colunas que devem aparecer no scroll
            function filterColunasValidas(periodo) {
                //Busca item configurado na turma
                var itemAvaliavel = _.find(itensAvaliaveis, function (item) {
                    return _.get(item, 'idPeriodo') === (periodo.id) &&
                        _.get(item, 'itemEducacional.id') === itemEducacional.id;
                });

                //Verifica se o modo de avaliação é descritivo ou conceitual, para ocutar a coluna de cálculo e nota para exame
                if ((ItemUtilsService.isTipoPeriodoCalculo(itemAvaliavel) ||
                    ItemUtilsService.isTipoPeriodoNotaParaExame(itemAvaliavel)) &&
                    DesempenhoEscolarUtilsService.isModoAvaliacaoDescritivoConceito(itemAvaliavel.modoAvaliacao)) {
                    return false;
                }

                return periodo.id !== ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL;
            }
        }
    }
})();
/* jshint ignore:start */
(function(){
    'use strict';

    angular.module('fusioncharts-order')
        .value('fusioncharts-order','fusioncharts-order');
})();
/*
 FusionCharts JavaScript Library
 Copyright FusionCharts Technologies LLP
 License Information at <http://www.fusioncharts.com/license>
 FusionCharts JavaScript Library
 Copyright FusionCharts Technologies LLP
 License Information at <http://www.fusioncharts.com/license>

 @version 3.9.0
*/

/* jshint ignore:end */
(function() {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.RegistroVinculadoService', RegistroVinculadoService);

    RegistroVinculadoService.$inject = ['$q', 'Restangular', 'bfc.dialog.Dialog'];

    function RegistroVinculadoService($q, Restangular, bfcDialog) {
        var basePath = Restangular.all('calendario/registro-vinculado');

        return {
            openModal: openModal,
            getInstrumentosAvaliacao: getInstrumentosAvaliacao,
            getTotalizadores: getTotalizadores,
            delRegistrosVinculados: delRegistrosVinculados
        };

        function openModal() {
            return bfcDialog.open({
                templateUrl: 'calendario/registro-vinculado/registro-vinculado-modal.html',
                controller: 'educacao.calendario.RegistroVinculadoModalController',
                controllerAs: 'vm',
                size: 'xxl'
            });            
        }

        function getInstrumentosAvaliacao(params) {
            return basePath.one('instrumento-avaliacao').get(params);
        }

        function getTotalizadores(dataInicio, dataFim, estabelecimento, matriz) {
            var params = {
                dataInicio: dataInicio,
                dataFim: dataFim,
                estabelecimento: estabelecimento,
                matriz: matriz
            };

            var totalizador = {
                totalFaltaDia: 0,
                totalFaltaAula: 0,
                totalAlocacaoAula: 0,
                totalConteudoPlanejado: 0,
                totalConteudoMinistrado: 0,
                totalJustificativaFalta: 0,
                totalAbonoFalta: 0,
                totalInstrumentoAvaliacao: 0
            };

            var promise = $q.all([
                getTotal('falta-dia', params).then(thenFaltaDia),
                getTotal('falta-aula', params).then(thenFaltaAula),
                getTotal('alocacao-aula', params).then(thenAlocacaoAula),
                getTotal('conteudo-planejado', params).then(thenConteudoPlanejado),
                getTotal('conteudo-ministrado', params).then(thenConteudoMinistrado),
                getTotal('justificativa-falta', params).then(thenJustificativaFalta),
                getTotal('abono-falta', params).then(thenAbonoFalta),
                getTotal('instrumento-avaliacao', params).then(thenInstrumentoAvaliacao),
            ]);

            return promise.then(thenTotalizadores);

            function thenTotalizadores() {
                return totalizador;
            }

            function thenFaltaDia(totalFaltaDia) {
                totalizador.totalFaltaDia = totalFaltaDia.total;
            }

            function thenFaltaAula(totalFaltaAula) {
                totalizador.totalFaltaAula = totalFaltaAula.total;
            }

            function thenAlocacaoAula(totalAlocacaoAula) {
                totalizador.totalAlocacaoAula = totalAlocacaoAula.total;
            }

            function thenConteudoPlanejado(totalConteudoPlanejado) {
                totalizador.totalConteudoPlanejado = totalConteudoPlanejado.total;
            }

            function thenConteudoMinistrado(totalConteudoMinistrado) {
                totalizador.totalConteudoMinistrado = totalConteudoMinistrado.total;
            }

            function thenJustificativaFalta(totalJustificativaFalta) {
                totalizador.totalJustificativaFalta = totalJustificativaFalta.total;
            }

            function thenAbonoFalta(totalAbonoFalta) {
                totalizador.totalAbonoFalta = totalAbonoFalta.total;
            }

            function thenInstrumentoAvaliacao(totalInstrumentoAvaliacao) {
                totalizador.totalInstrumentoAvaliacao = totalInstrumentoAvaliacao.total;
            }
        }

        function delRegistrosVinculados(registrosVinculados) {
            return basePath.post(registrosVinculados);
        }

        function getTotal(totalizador, params) {
            return basePath.one('totalizador-' + totalizador).get(params);
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.EstabelecimentoServiceDeprecated', EstabelecimentoService);

    EstabelecimentoService.$inject = ['$log', '$injector'];

    function EstabelecimentoService($log, $injector) {
        var GenericService = $injector.get('GenericService');
        $log.warn('[DEPRECATED] use educacao.pessoas.EstabelecimentoService com generic builder');
        var service = GenericService
            .createWithCustomMethods({
                suggest: _suggest
            })
            .module({
                uri: 'pessoa'
            })
            .service({
                uri: 'estabelecimento'
            });

        function _suggest(cnpj) {
            service.validateObject();

            return service.modules().one(service._service.uri + '/suggest').get({
                cnpj: cnpj
            });
        }

        return service;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('orquestrador', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'common/layout/header/orquestrador/orquestrador.directive.html',
            replace: true
        };
    }
})();

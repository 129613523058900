(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectInepAtividadeComplementar', SelectInepAtividadeComplementarDirective);

    function SelectInepAtividadeComplementarDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.matricula.AtividadeComplementarService'];

    function Controller(service) {

        var vm = this;

        vm.formatResult = formatResult;
        vm.search = search;

        function search(params) {
            var filter = '(descricao like "' + params.term + '" and dataBase is null and codigoInep is not null)';

            params.sort = 'codigoInep';

            return service.list(filter, params.offset, params.limit, params.sort);
        }

        function formatResult(data) {
            return data.codigoInep + ' - ' + data.descricao;
        }
    }
})();

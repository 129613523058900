(function () {

    'use strict';

    angular.module('educacao.common')
        .constant('SITUACAO_FORMACAO', {
            EM_ANDAMENTO: 'EM_ANDAMENTO',
            CONCLUIDO: 'CONCLUIDO'
        });

})();
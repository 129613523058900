(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.AeeCtrl', AeeCtrl);

    AeeCtrl.$inject = [
        '$rootScope',
        '$scope',
        'promiseTracker',
        '$q',
        '$filter',
        'educacao.common.ObjectUtilsService',
        '$modalStates',
        'educacao.matricula.AtividadeAEEService'
    ];

    function AeeCtrl(
        $rootScope,
        $scope,
        promiseTracker,
        $q,
        $filter,
        ObjectUtilsService,
        $modalStates,
        AtividadeAEEService) {

        var vm = this;
        vm.openCad = openCad;
        vm.getIconSortClass = getIconSortClass;
        vm.remove = _remove;
        vm.tracker = {
            loading: promiseTracker(),
            remove: promiseTracker()
        };
        vm.orderBy = orderBy;

        init();

        $scope.isSearching = function() {
            return Boolean($scope.search && ($scope.search.filter || $scope.search.value));
        };

        function openCad(aaee) {
            $modalStates.goModalState(aaee);
        }

        function getIconSortClass(sortBy, atividade) {
            return {
                'icon-caret-down': atividade === 'U' ? vm.predicate === sortBy && vm.reverse : vm.predicatePadrao === sortBy && vm.predicatePadrao,
                'icon-caret-up': atividade === 'U' ? vm.predicate === sortBy && !vm.reverse : vm.predicatePadrao === sortBy && !vm.reversePadrao,
                'icon-sort': atividade === 'U' ? vm.predicate !== sortBy : vm.predicatePadrao !== sortBy
            };
        }

        function _remove(data) {
            AtividadeAEEService.remove(data.id).then(function() {
                $rootScope.$broadcast('refresh::atividades', {
                    data: data,
                    remove: true
                });
            });
        }

        $scope.$on('refresh::atividades', function(event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.atividades = _.reject(vm.atividades, function(atividadeAee) {
                        return args.data.id === atividadeAee.id;
                    });

                } else if (args.add) {
                    vm.atividades.push(args.data);
                } else if (args.undo || args.edit) {
                    var atividade = _.find(vm.atividades, function(atividadeAee) {
                        return args.data.id === atividadeAee.id;
                    });

                    if (atividade) {
                        ObjectUtilsService.copyProperties(atividade, args.data);
                    } else {
                        throw new Error('Atividade não encontrada! ID: ' + args.data.atividade.id);
                    }
                }
            }
        });

        function orderBy(item) {
            if (typeof vm.predicate === 'undefined') {
                vm.predicate = 'descricao';
            }
            return ObjectUtilsService.normalizeFilter(item[vm.predicate]);
        }

        function init() {
            vm.tracker.loading.addPromise(
                $q.when(
                    AtividadeAEEService.getList().then(
                        function(data) {
                            vm.predicatePadrao = 'id';
                            vm.atividades = $filter('filter')(data, {
                                padrao: false
                            });
                            vm.atividadesPadroes = $filter('filter')(data, {
                                padrao: true
                            });
                        }
                    )
                )
            );
        }
    }

})();

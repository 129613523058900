(function () {

    'use strict';

    angular.module('educacao.encerramento')
        .directive('appEncerramentoFlag', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'encerramento/encerramento-flag-directive/encerramento-flag.directive.html',
            scope: {},
            bindToController: {
                item: '=',
                column: '=',
                onClick: '&?',
                hasPermission: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.encerramento.EncerramentoUtilsService'
    ];

    function Controller($scope, EncerramentoUtilsService) {

        var vm = this;

        vm.getCellClass = getCellClass;
        vm.mouseEnterLeave = mouseEnterLeave;

        vm.item[vm.column.id].$$aberto = EncerramentoUtilsService.isStatusEmAberto(vm.item[vm.column.id]);
        vm.item[vm.column.id].$$processando = EncerramentoUtilsService.isStatusProcessando(vm.item[vm.column.id]);

        vm.item[vm.column.id].$$abertoVm = vm.item[vm.column.id].$$aberto;

        vm.item[vm.column.id].$$isPeriodoLetivo = EncerramentoUtilsService
            .isPeriodoLetivo(vm.column.id);


        function getCellClass(item, coluna) {
            return {
                'tx__green--d10': item[coluna.id].$$isPeriodoLetivo && !item[coluna.id].$$aberto,
                'tx__red': item[coluna.id].$$isPeriodoLetivo && item[coluna.id].$$aberto,
                'fa-fw fa fa-unlock': item[coluna.id].$$abertoVm,
                'fa-fw fa fa-lock': !item[coluna.id].$$abertoVm
            };
        }

        function mouseEnterLeave(item, coluna, isEnter) {

            if(!vm.hasPermission){
                return;
            }

            if (isEnter) {
                item[coluna.id].$$abertoVm = !item[coluna.id].$$aberto;
            } else {
                item[coluna.id].$$abertoVm = item[coluna.id].$$aberto;
            }
        }
    }
})();
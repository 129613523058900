(function() {
  'use strict';

    angular.module('educacao.matricula')
        .directive('appDetailMatricula', AppDetailMatricula);

    var SITUACOES_MOVIMENTAR = ['CURSANDO', 'MATRICULADO', 'EM_EXAME'];
    var SITUACOES_DESFAZER = ['CANCELAMENTO', 'TRANSFERIDO', 'DEIXOU_FREQUENTAR'];

    function AppDetailMatricula() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/app-detail-matricula.template.html',
            scope: {
                item: '=',
                nivelEscolar: '=',
                enumSituacao: '='
            },
            bindToController: true,
            controllerAs: 'vm',
            controller: Controller
        };
    }

    Controller.$inject = [
        'educacao.common.$commons',
        'educacao.matricula.MatriculaService'
    ];

    function Controller($commons, MatriculaService) {
        var vm = this;

        vm.enturmacoes = [];
        vm.editarData = [];
        vm.tracker = {
            init: $commons.$promiseTracker(),
            save: $commons.$promiseTracker()
        };

        vm.colunasAdicionais = colunasAdicionais;
        vm.adicionaFlagsMatricula = adicionaFlagsMatricula;
        vm.desfazerMovimentacao = desfazerMovimentacao;
        vm.openMovimentacaoCancelamentoModal = openMovimentacaoCancelamentoModal;
        vm.openMovimentacaoDeixouFrequentarModal = openMovimentacaoDeixouFrequentarModal;
        vm.openMovimentacaoTransferenciaModal = openMovimentacaoTransferenciaModal;
        vm.showColOptions = showColOptions;
        vm.showOptions = showOptions;
        vm.isEjaModular = isEjaModular;

        init();

        function init() {
            var promise = MatriculaService.getEnturmacoesMatricula(vm.item.id).then(
                onGetEnturmacoes
            );

            vm.tracker.init.addPromise(promise);

            function onGetEnturmacoes(list) {
                vm.enturmacoes = list;
                adicionaFlagsMatricula(vm.enturmacoes);
            }
        }

        function colunasAdicionais() {
            return (
                vm.item.tipo === 'EJA' ||
                vm.item.tipo === 'ATIVIDADE_COMPLEMENTAR' ||
                vm.item.tipo === 'AEE'
            );
        }

        function adicionaFlagsMatricula(enturmacoes) {
            _.forEach(enturmacoes, function(enturmacao) {
                var situacao = enturmacao.matricula.situacao;

                enturmacao.matricula.$$podeMovimentar = SITUACOES_MOVIMENTAR.indexOf(situacao) !== -1;

                switch (situacao) {
                    case 'TRANSFERIDO':
                        enturmacao.matricula.$$acaoDesfazer = 'Transferência';
                        break;
                    case 'DEIXOU_FREQUENTAR':
                        enturmacao.matricula.$$acaoDesfazer = 'Deixou de frequentar';
                        break;
                    case 'CANCELAMENTO':
                        enturmacao.matricula.$$acaoDesfazer = 'Cancelamento';
                        break;
                    default:
                        enturmacao.matricula.$$acaoDesfazer = null;
                }

                enturmacao.matricula.$$podeDesfazer = Boolean(enturmacao.matricula.$$acaoDesfazer);
            });
        }

        function desfazerMovimentacao(matricula) {
            if (isEjaModular()) {
                return MatriculaService.desfazerMovimentacaoEjaModular(matricula.id);
            }
        }

        function openMovimentacaoCancelamentoModal(matricula) {
            openGenericMovimentacaoModal(matricula, 'CANCELAMENTO');
        }

        function openMovimentacaoDeixouFrequentarModal(matricula) {
            openGenericMovimentacaoModal(matricula, 'DEIXOU_DE_FREQUENTAR');
        }

        function openMovimentacaoTransferenciaModal(matricula) {
            openGenericMovimentacaoModal(matricula, 'TRANSFERENCIA');
        }

        function openGenericMovimentacaoModal(matricula, tipo) {
            if (!_.get(matricula, 'id')) {
                return;
            }

            return $commons.$modal.open({
                templateUrl: 'matricula/matriculas/movimentacao/movimentacao-generica.modal.html',
                controller: 'educacao.matricula.MovimentacaoGenericaModalCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    tipoMovimentacao: tipo,
                    matriculaId: matricula.id,
                    matriculaPai: vm.item,
                }
            });
        }

        function isEjaModular() {
            return _.get(vm.item, 'tipoMatricula') === 'EJA' && _.get(vm.item, 'matriz.modular');
        }

        function showColOptions() {
            return (
                isEjaModular() &&
                canDesfazerOrMovimentar(vm.item.situacao)
            );
        }

        function showOptions(enturmacao) {
            if (_.get(enturmacao, 'dataFim') && _.includes(SITUACOES_MOVIMENTAR, enturmacao.matricula.situacao)) {
                return false;
            }

            return canDesfazerOrMovimentar(enturmacao.matricula.situacao);
        }

        function canDesfazerOrMovimentar(situacao) {
            return (
                _.includes(SITUACOES_MOVIMENTAR, situacao) ||
                _.includes(SITUACOES_DESFAZER, situacao)
            );
        }
    }
})();

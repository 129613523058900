(function () {
    'use strict';

    angular.module('educacao.abonojustificativa')
        .factory('educacao.abonojustificativa.AbonoJustificativaService', AbonoJustificativaService);

    AbonoJustificativaService.$inject = ['ServiceBuilder', '$enumsCache'];

    function AbonoJustificativaService(ServiceBuilder, $enumsCache) {

        return {
            buildService: buildService
        };

        function buildService(idTurma) {

            var service = ServiceBuilder
                .create()
                .path('commons', 'turma/' + idTurma, 'frequencias', 'registros-abonos')
                .build();

            service.completos = completos;
            service.removeAbonoPeriodoEncerrado = removeAbonoPeriodoEncerrado;
            service.motivosRecentes = motivosRecentes;
            service.registroAbono = registroAbono;
            service.getTipoDispensaAbono = getTipoDispensaAbono;
            service.getTipoAbono = getTipoAbono;
            service.savePeriodoEncerrado = savePeriodoEncerrado;

            function completos(params) {
                return service.getEndpoint().all('completos').getList(params);
            }

            function motivosRecentes(limit) {
                return service.getEndpoint().all('motivos-recentes').getList({
                    limit: limit
                });
            }

            function registroAbono(enturmacao, itemEducacional, periodoAvaliativo) {
                var params = {
                    enturmacao: enturmacao,
                    itensEducacionais: itemEducacional
                };

                if (periodoAvaliativo) {
                    params.periodoAvaliativo = periodoAvaliativo;
                }

                return service.getEndpoint()
                    .get('nao-justificadas', params);
            }

            function removeAbonoPeriodoEncerrado(item) {
                return service.getEndpoint()
                    .all('periodo-encerrado').customDELETE(item.id.toString());
            }

            function getTipoDispensaAbono() {
                return $enumsCache.get('TipoDispensaAbono');
            }

            function getTipoAbono() {
                return $enumsCache.get('TipoAbono');
            }

            function savePeriodoEncerrado(abonosjustificativas) {
                return service.getEndpoint()
                    .all('periodo-encerrado').post(abonosjustificativas);
            }

            return service;

        }

    }
})();

(function() {
	'use strict';

	angular
		.module('educacao.lista-espera-novo.configuracao')
		.directive('appConfiguracaoListaEsperaDetailList', appConfiguracaoListaEsperaDetailList);

	function appConfiguracaoListaEsperaDetailList() {
		return {
			restrict: 'E',
			templateUrl:
				'lista-espera-new/configuracao/app-configuracao-lista-espera-detail-list/app-configuracao-lista-espera-detail-list.directive.html',
			scope: {},
			bindToController: {
				configuracao: '='
			},
			controller: Controller,
			controllerAs: 'vm',
			replace: true
		};
	}

	Controller.$inject = [
		'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils',
		'promiseTracker'
	];

	function Controller(InscricaoListaEsperaUtils, promiseTracker) {
		var vm = this;

		vm.loadingTracker = promiseTracker();

		init();

		function init() {
			var promise = InscricaoListaEsperaUtils.getEstabelecimentos(
					vm.configuracao.id,
					vm.configuracao.permiteRegistroOutroEstabelecimento
				).then(createStringEstabelecimento);

			vm.loadingTracker.addPromise(promise);
		}

		function createStringEstabelecimento(dado) {
			var estabelecimentos = _.chain(vm.configuracao.estabelecimentos)
			.map(function(estabelecimento){
				return _.find(dado.estabelecimentos, 'id', estabelecimento.estabelecimento).descricao;
			})
			.value();

			vm.stringEstabelecimento = estabelecimentos.join(', ');
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .directive('appResumoSaldoDeVagasQuadroTurmaAlunos', appResumoSaldoDeVagasQuadroTurmaAlunosDirective);

    function appResumoSaldoDeVagasQuadroTurmaAlunosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/turma-alunos/resumo-saldo-de-vagas/app-resumo-saldo-vagas-quadro-turma-alunos.directive.html',
            scope: {},
            bindToController: {
                anoletivoselecionado: '=',
                estabelecimentoselecionado: '=',
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        'promiseTracker',
        'educacao.turma-alunos-quadro-informativo.GraficoTurmaAlunosService',
        '$scope'
    ];

    function Controller(ContextoAnoLetivo, ContextoEstabelecimento, QuadroFuncionariosTurmaAlunosService, promiseTracker, GraficoTurmaAlunosService, $scope) {
        var vm = this;
        vm.graficosSaldoDeVagas = {};
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.loadingTracker = {
            graficoDisponibilidadeSaldoDeVagasPorTurnoMatutino: promiseTracker(),
            graficoDisponibilidadeSaldoDeVagasPorTurnoVespertino: promiseTracker(),
            graficoDisponibilidadeSaldoDeVagasPorTurnoNoturno: promiseTracker(),
            graficoDisponibilidadeSaldoDeVagasPorTurnoIntegral: promiseTracker(),
            graficoSaldoDeVagasPorModalidade: promiseTracker(),
            graficoSaldoDeVagasEducacaoBasica: promiseTracker(),
            graficoSaldoDeVagasEja: promiseTracker(),
            graficoSaldoDeVagasAceleracao: promiseTracker(),
            graficoSaldoDeVagasPorTurno: promiseTracker(),
            modalidadesOferecidas: promiseTracker(),

        };

        vm.modalidadesOferecidas = null;

        vm.paths = [
            'vagasPorModalidade',
            'disponibilidadePorTurnoMatutino',
            'disponibilidadePorTurnoVespertino',
            'disponibilidadePorTurnoNoturno',
            'disponibilidadePorTurnoIntegral',
            'vagasEducacaoBasica',
            'vagasEja',
            'vagasAceleracao',
            'vagasPorTurno',
        ];


        $scope.$watch('vm.anoletivoselecionado', onChangeContextAnoLetivo);
        $scope.$watch('vm.estabelecimentoselecionado', onChangeContextEstabelecimento);
        init();

        function onChangeContextAnoLetivo(oldValue,newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }

        function onChangeContextEstabelecimento(oldValue,newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }


        function reloadValues() {
            GraficoTurmaAlunosService.clearValues();
            _.forEach(vm.paths, clearValue);
            init();
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoletivoselecionado, 'id');
            paramns.estabelecimentoId = _.get(vm.estabelecimentoselecionado, 'id');
            return paramns;
        }

        function clearValue(path) {
            vm.graficosSaldoDeVagas[path] = null;
        }


        function init() {
            getModalidadesOferecidas();
            getGraficoSaldoDeVagas('disponibilidadePorTurnoMatutino', 'graficoDisponibilidadeSaldoDeVagasPorTurnoMatutino', 'getGraficoDisponibilidadeSaldoDeVagasPorTurnoMatutino');
            getGraficoSaldoDeVagas('disponibilidadePorTurnoVespertino', 'graficoDisponibilidadeSaldoDeVagasPorTurnoVespertino', 'getGraficoDisponibilidadeSaldoDeVagasPorTurnoVespertino');
            getGraficoSaldoDeVagas('disponibilidadePorTurnoNoturno', 'graficoDisponibilidadeSaldoDeVagasPorTurnoNoturno', 'getGraficoDisponibilidadeSaldoDeVagasPorTurnoNoturno');
            getGraficoSaldoDeVagas('disponibilidadePorTurnoIntegral', 'graficoDisponibilidadeSaldoDeVagasPorTurnoIntegral', 'getGraficoDisponibilidadeSaldoDeVagasPorTurnoIntegral');
            getGraficoSaldoDeVagas('vagasPorModalidade', 'graficoSaldoDeVagasPorModalidade', 'getGraficoSaldoDeVagasPorModalidade');
            getGraficoSaldoDeVagas('vagasEducacaoBasica', 'graficoSaldoDeVagasEducacaoBasica', 'getGraficoSaldoDeVagasEducacaoBasica');
            getGraficoSaldoDeVagas('vagasEja', 'graficoSaldoDeVagasEja', 'getGraficoSaldoDeVagasEja');
            getGraficoSaldoDeVagas('vagasAceleracao', 'graficoSaldoDeVagasAceleracao', 'getGraficoSaldoDeVagasAceleracao');
            getGraficoSaldoDeVagas('vagasPorTurno', 'graficoSaldoDeVagasPorTurno', 'getGraficoSaldoDeVagasPorTurno');
        }

        function getGraficoSaldoDeVagas(path, trackerName, serviceName) {
            var promise = GraficoTurmaAlunosService.getGraficoSaldoDeVagas(path, serviceName, resolveParamns())
                .then(function (data) {
                    vm.graficosSaldoDeVagas[path] = data;
                });
            vm.loadingTracker[trackerName].addPromise(promise);
        }

        function getModalidadesOferecidas() {
            var promise = QuadroFuncionariosTurmaAlunosService.getModalidadesOferecidas(resolveParamns()).then(function (data) {
                vm.modalidadesOferecidas = data;
            });
            vm.loadingTracker.modalidadesOferecidas.addPromise(promise);

        }
    }

})();

(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.common');

	moduloCommon.factory('educacao.common.RateioSelect', ['bfc.Select2Config',
		function(Select2Config) {
			return {
				select2Config: function(options) {
					return Select2Config.create(angular.extend({
						query: function(query) {
							query.callback({
								results: [{
									id: 'sim',
									text: 'Sim'
								}, {
									id: 'nao',
									text: 'Não'
								}]
							});
						}
					}, options));
				}
			};
		}
	]);
})();

(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appSelectCampoExperiencia', SelectCampoExperiencia);

	function SelectCampoExperiencia() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" not-empty="ngRequired || false" search="vm.search" minimum-results-for-search="0" add-value="vm.add"></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngRequired: '=?',
				camposExperienciaExistentes: '=',
                ngChange: '&?',
                dropdownCssClass: '=?'
			},
			controller: Controller,
			controllerAs: 'vm',
			compile: function(element, attrs) {
				var multiple = angular.isDefined(attrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
		};
	}

	Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.matriz.CampoExperienciaService'];

	function Controller($scope, $modal, service) {

		var vm = this;

		vm.search = search;
		vm.add = add;

		$scope.$watch('ngModel', watch);

		function watch(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			if (_.isFunction($scope.ngChange)) {
				$scope.ngChange();
			}
		}
		
		function search(params) {
			var filter = '(descricao like "' + params.term + '"';

			if ($scope.multiple) {
				filter += ignoreId($scope.ngModel);
			}
			if ($scope.camposExperienciaExistentes) {
				filter += ignoreId($scope.camposExperienciaExistentes);
			}

			filter += ')';
			return service.list(filter, params.offset, params.limit);
		}

		function add(descricao) {
			return $modal.open({
				templateUrl: 'planejamento/matriz/campo-experiencia/cadastro-campo-experiencia.html',
				controller: 'educacao.matriz.CampoExperienciasCadCtrl',
				controllerAs: 'vm',
				size: '',
				params: {
					campoExperiencia: {
						descricao: descricao
					}
				}
			}).result;
		}

		function ignoreId(list) {
			var filter = '';
			var ids = [];
			if (list) {
				list.forEach(function(campoExperiencia) {
					if (_.get(campoExperiencia, 'id')) {
						ids.push(campoExperiencia.id);
					}
				});
			}
			if (ids.length) {
				filter += ' and id not in(' + ids.join() + ')';
			}
			return filter;
		}
	}
})();

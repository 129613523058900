(function () {
    'use strict';

    angular
        .module('educacao.pessoas')
        .directive('appFiltroAvancadoEscolaDependencias', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'planejamento/escolas/filtro-avancado/escola/dependencias/dependencias.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorDependencias: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.changeContadorDependencias = changeContadorDependencias;

        $scope.$watchCollection(function() { 
            if(vm.advancedFilter.dependenciasFisicas) {
                return Object.values(vm.advancedFilter.dependenciasFisicas);
            }
        }, changeContadorDependencias);

        init();
    
        function init() {
            vm.contadorDependencias = 0;

            changeContadorDependencias();
        }
    
        function changeContadorDependencias() {
            if (vm.advancedFilter && vm.advancedFilter.dependenciasFisicas) {
                vm.contadorDependencias = getFilterLength();
            }
        }
    
        function getFilterLength() {
            return Object.values(vm.advancedFilter.dependenciasFisicas).filter(function (filtroAvancadoSelecionado) {
                return filtroAvancadoSelecionado === false || !!filtroAvancadoSelecionado && filtroAvancadoSelecionado.length !== 0;
            }).length;
        }
    }    
})();

(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appCardTurmaAluno', CardTurmaAlunoDirective);

	function CardTurmaAlunoDirective() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/turma/app-card-turma/app-card-turma-aluno.directive.html',
			scope: {
				tabelaEnturmacoes: '=',
				canExibNumeroChamada: '=',
				desenturmarAction: '&onDesenturmar'
			},
			link: function($scope, $element, $attrs) {
				if (!$attrs.onDesenturmar) {
					delete $scope.desenturmarAction;
				}
			},
			controller: CardTurmaAlunoDirectiveController,
			controllerAs: 'vm'
		};
	}

	CardTurmaAlunoDirectiveController.$inject = ['$scope',
		'educacao.matricula.EnturmacaoService',
		'educacao.common.ItemEducacionalService',
		'bfc.Focus'];

	function CardTurmaAlunoDirectiveController($scope, EnturmacaoService, ItemEducacionalService, foco) {

		var vm = this;

		var propriedadesPublicas = {
			editarNumeroChamada: _editarNumeroChamada
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.tabelaEnturmacoes = $scope.tabelaEnturmacoes || [];
		}

		function _editarNumeroChamada(enturmacao) {
			EnturmacaoService.editarNumeroChamada(enturmacao)
			.then(function(persisted){
				var index = _.findIndex(vm.copyEnturmacoes, function(e){ return e.id === persisted.id;});
				vm.copyEnturmacoes[index].numeroChamada = persisted.numeroChamada;
			})
			.catch(
				function() {
					vm.copyEnturmacoes.forEach(function(copy) {
						if (angular.equals(copy.id, enturmacao.id)) {
							enturmacao.numeroChamada = copy.numeroChamada;
							return;
						}
					});
					foco('numero-de-chama-' + enturmacao.id);
				}
			);
		}

		$scope.$watch('tabelaEnturmacoes', function(value) {
			vm.tabelaEnturmacoes = value || {};
			vm.copyEnturmacoes = angular.copy(_.flatten(vm.tabelaEnturmacoes));
		});
	}
})();


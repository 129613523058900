(function () {
    'use strict';

    angular
        .module('educacao.lista-espera-novo.inscricao')
        .controller('educacao.lista-espera-novo.inscricao.InscricaoCtrl', InscricaoCtrl);

    InscricaoCtrl.$inject = [
        'promiseTracker',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'bfc.Notification',
        '$modalStates',
        '$enumsCache',
        '$q',
        'ui.components.Modal',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils',
        'educacao.pessoas.AlunoService',
        'educacao.pessoas.FiliacaoService',
        'educacao.lista-espera-novo.inscricao.ClassificacaoLoteService',
        'INSCRICAO_TO_MATRICULA',
        '$filter',
        'bfc.$$PermissionsService',
        '$tourGuiadoService',
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        'educacao.lista-espera-novo.encaminhado.EncaminhadoService',
        '$stateParams',
        '$state',
        '$scope',
        'educacao.lista-espera-novo.anotacoes.AnotacaoInscricaoService'
    ];

    function InscricaoCtrl(
        promiseTracker,
        InscricaoListaEsperaService,
        bfcNotification,
        $modalStates,
        $enumsCache,
        $q,
        uiModal,
        InscricaoListaEsperaUtils,
        AlunoService,
        FiliacaoService,
        ClassificacaoLoteService,
        INSCRICAO_TO_MATRICULA,
        $filter,
        PermissionsService,
        $tourGuiadoService,
        ConfigListaEsperaService,
        EncaminhadoService,
        $stateParams,
        $state,
        $scope,
        AnotacaoInscricaoService
    ) {
        var vm = this;

        vm.filter = {};
        vm.carregaUiListGrid = false;
        vm.totalAnotacoes = null;
        vm.columnsSearch = [
            {
                id: 'nome',
                model: 'nome',
                label: 'Nome',
                filterable: true
            }
        ];
        vm.getInscritos = getInscritos;
        vm.inscricaoListaEsperaService = InscricaoListaEsperaService;
        vm.findDescricaoEstabelecimento = findDescricaoEstabelecimento;
        vm.getNomeEstabelecimento = getNomeEstabelecimento;

        vm.abreCadastro = abreCadastro;
        vm.remove = remove;
        vm.cancelar = cancelar;
        vm.desfazerCancelamento = desfazerCancelamento;
        vm.openModalAnotacoes = openModalAnotacoes;
        vm.getSituacoes = getSituacoesEnum;
        vm.search = search;
        vm.searchEstabelecimentos = searchEstabelecimentos;
        vm.formatEstabelecimento = formatEstabelecimento;
        vm.searchNiveisModalidades = searchNiveisModalidades;
        vm.formatNiveisModalidades = formatNiveisModalidades;
        vm.searchEtapas = searchEtapas;
        vm.formatEtapas = formatEtapas;
        vm.searchTurnos = searchTurnos;
        vm.formatTurnos = formatTurnos;
        vm.buscarEstabelecimento = buscarEstabelecimento;
        vm.openModalEncaminhamento = openModalEncaminhamento;
        vm.buttonDisabled = buttonDisabled;
        vm.getClassificacaoLotes = getClassificacaoLotes;
        vm.cadastroConfiguracao = cadastroConfiguracao;

        vm.classificarPor = classificarPor;
        vm.isAplicavel = isAplicavel;
        vm.aplicarFiltro = aplicarFiltro;
        vm.getDescricaoModalidadeNivel = getDescricaoModalidadeNivel;

        vm.limparFiltro = limparFiltro;
        vm.openModalMatricular = openModalMatricular;
        vm.openModalCancelamento = openModalCancelamento;
        vm.toggleDetails = toggleDetails;
        vm.classificacaoLoteService = ClassificacaoLoteService;
        vm.verificaStatusUltimoLote = verificaStatusUltimoLote;

        vm.parametrosEmissaoRelatorio = {};
        vm.changeConfiguracao = changeConfiguracao;
        vm.changeEstabelecimento = changeEstabelecimento;
        vm.changeNivelModalidade = changeNivelModalidade;
        vm.changeEtapa = changeEtapa;
        vm.changeTurno = changeTurno;
        vm.changeSituacao = changeSituacao;

        vm.getNomeMae = getNomeMae;
        vm.getTelefones = getTelefones;
        vm.getClassWidgetCriterios = getClassWidgetCriterios;
        vm.openWidgetCriterios = openWidgetCriterios;
        vm.getTotalAnotacoesInscricao = getTotalAnotacoesInscricao;

        vm.permiteAletarDtInsc = permiteAletarDtInsc;
        vm.permiteAletarDtInscEstab = permiteAletarDtInscEstab;
        vm.permiteAletarDtInscTurno = permiteAletarDtInscTurno;

        vm.hasPermission = !PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'alterarDataInscricao');
        vm.hasPermissionAnotacoesInscricao = !PermissionsService.isRevokedOperation('AnotacaoInscricaoPageMapping', 'inserirRemover');
        vm.tracker = {
            list: promiseTracker(),
            init: promiseTracker(),
            changeConfiguracao: promiseTracker(),
            indicadores: promiseTracker()
        };

        $q.all([getSituacoesEnum(), getNivelEscolarEnum(), getModalidadeEnum(), getTurnoEnum(), getDeficienciaEnum()]).then(init);

        $scope.$watch('vm.parametrosEmissaoRelatorio', changeLiberaListagem(false));

        function init() {
            vm.carregaUiListGrid = true;
            vm.possuiFiltro = false;
            vm.filterClassificacao = '';

            vm.etapas = vm.etapas || [];

            vm.modalidadesNiveis = vm.modalidadesNiveis || [];
            vm.situacao = 'INSCRITO';
            vm.situacoes = vm.situacoes || [];
            vm.niveisModalidades = [];

            vm.classificarPorEstabelecimento = false;
            vm.classificarPorModalidade = false;
            vm.classificarPorEtapa = false;
            vm.classificarPorTurno = false;
            vm.situacoes = vm.getSituacoes();
            vm.parametrosEmissaoRelatorio = {};

            getTurnoEnum();

            vm.expressionCustomBuilders = {
                dataInscricaoInicial: function (filters) {
                    if (filters.dataInscricaoInicial) {
                        return 'data >= "' + filters.dataInscricaoInicial + 'T00:00:00"';
                    }
                },
                dataInscricaoFinal: function (filters) {
                    if (filters.dataInscricaoFinal) {
                        return 'data <= "' + filters.dataInscricaoFinal + 'T00:00:00"';
                    }
                },
                dataNascimentoInicial: function (filters) {
                    if (filters.dataNascimentoInicial) {
                        return 'dataNascimento >= ' + filters.dataNascimentoInicial;
                    }
                },
                dataNascimentoFinal: function (filters) {
                    if (filters.dataNascimentoFinal) {
                        return 'dataNascimento <= ' + filters.dataNascimentoFinal;
                    }
                }
            };

            var promise = ConfigListaEsperaService.listConfiguracaoInscricao()
                .then(function (configuracao) {
                    vm.configuracoes = configuracao;
                    setConfiguracao();
                });

            vm.tracker.init.addPromise(promise);
        }

        function setConfiguracao() {
            if (!$stateParams.configuracao) {
                return;
            }

            vm.configuracaoSelected = _.first(vm.configuracoes.filter(function (configuracao) {
                return configuracao.id === $stateParams.configuracao;
            }));

            changeConfiguracao();
        }

        function getTotalAnotacoesInscricao(idInscricao) {
            if (!vm.hasPermissionAnotacoesInscricao) {
                return;
            }
            AnotacaoInscricaoService.countAnotacoes(idInscricao).then(function (data) {
                vm.totalAnotacoes = _.get(data, 'total');
            });
        }

        function changeLiberaListagem(value) {
            vm.liberaListagem = value;
        }

        function changeEstabelecimento() {
            changeLiberaListagem(false);
            vm.parametrosEmissaoRelatorio.idEstabelecimento = vm.estabelecimentoFiltro.idMongo;
        }

        function buscarEstabelecimento() {
            vm.listaSelecaoEstabelecimentos = _.filter(vm.estabelecimentos, filterEstabelecimento);
        }

        function filterEstabelecimento(estabelecimento) {
            var descricaoEstabelecimento = estabelecimento.descricao.toLowerCase();
            var pesquisaRealizada = vm.conteudoPesquisaEstabelecimento.toLowerCase();
            return _.includes(descricaoEstabelecimento, pesquisaRealizada);
        }

        function loadTourCriterioDesempate() {
            $tourGuiadoService.startTour('TOUR_GUIADO_INSCRICAO_LISTA_ESPERA_CRITERIO_DESEMPATE', false);
        }

        function getClassificacaoLotes() {
            var promise = $q.all([ultimaComSucesso(), ultima()]);
            promise.then(loadTourCriterioDesempate);

            vm.tracker.indicadores.addPromise(promise);

            function ultimaComSucesso() {
                return ClassificacaoLoteService.ultimaComSucesso(vm.configuracao.id)
                    .then(function (lote) {
                        vm.ultimoLoteSucesso = lote;
                    });
            }

            function ultima() {
                return ClassificacaoLoteService.ultima(vm.configuracao.id)
                    .then(function (lote) {
                        vm.ultimoLote = lote;
                    });
            }
        }

        function getNomeEstabelecimento(descricao) {
            if (!descricao) {
                return;
            }
            return descricao.substring(0, 23);
        }

        function search() {
            updateConfig();
            vm.listControls.search();
            changeLiberaListagem(true);
            getClassificacaoLotes();
        }

        function findDescricaoEstabelecimento(id) {
            return _.get(_.find(vm.estabelecimentos, 'idMongo', id), 'descricao');
        }

        function toggleDetails(modalidade) {
            vm.modalidade = modalidade;
            modalidade.$$showDetails = !modalidade.$$showDetails;
        }

        function updateConfig() {
            InscricaoListaEsperaUtils.clearCacheConfig(vm.configuracaoSelected.id);
            getConfiguracao();
        }

        function getConfiguracao() {
            InscricaoListaEsperaUtils.getConfiguracao(vm.configuracao.id);
        }

        function remove(inscricao) {
            return InscricaoListaEsperaService.remove(vm.configuracao.id, inscricao.id)
                .then(thenMessage)
                .then(aplicarFiltro)
                .then(EncaminhadoService.setTotal);

            function thenMessage() {
                setMessage('Inscrição excluída com sucesso');
            }
        }

        function cancelar(item) {
            return InscricaoListaEsperaService.cancelar(item, vm.configuracao.id).then(function () {
                bfcNotification.publish('A inscrição foi cancelada', 'success');
            });
        }

        function createObjeto(nivel, etapa) {
            return {
                nivel: nivel,
                idEtapa: etapa ? etapa.nivelModalidadeEtapa.id : undefined,
            };
        }

        function desfazerCancelamento(inscrito, nivel, etapa) {
            var dado = _.omit(createObjeto(nivel, etapa), _.isUndefined);
            return InscricaoListaEsperaService.desfazerCancelamento(vm.configuracao.id, inscrito.id, dado)
                .then(thenMessage)
                .then(aplicarFiltro);

            function thenMessage() {
                setMessage('Cancelamento da inscrição de ' + inscrito.nome + ' desfeito com sucesso.');
            }
        }

        function setMessage(message) {
            bfcNotification.publish(message, 'success');
        }

        function classificarPor(classificacao) {
            return vm.configuracao.classficacoes.find(function (c) {
                return c === classificacao;
            });
        }

        function initEstabelecimentos() {
            InscricaoListaEsperaUtils.getEstabelecimentos(vm.configuracao.id, vm.configuracao.permiteRegistroOutroEstabelecimento)
                .then(createlistaEstabelecimento)
                .then(function (dado) {
                    if (vm.configuracao.aplicarParaTodosEstabelecimentos) {
                        vm.estabelecimentos = dado;
                        vm.listaSelecaoEstabelecimentos = vm.estabelecimentos;
                        return;
                    }
                    vm.estabelecimentos = _.filter(dado, 'idMongo');
                    vm.listaSelecaoEstabelecimentos = vm.estabelecimentos;
                });

        }

        function createlistaEstabelecimento(estabelecimento) {
            return _.chain(estabelecimento.estabelecimentos)
                .map(_.bind(setIdMongo, null, vm.configuracao.estabelecimentos))
                .value();
        }

        function setIdMongo(listEstabelecimento, estab) {
            vm.estabContexExist = _.find(listEstabelecimento, 'estabelecimento', estab.id);
            estab.idMongo = _.get(vm.estabContexExist, 'id');
            return estab;
        }

        function changeNivelModalidade() {
            changeLiberaListagem(false);
            vm.etapas = vm.nivelModalidade.etapas;
            vm.parametrosEmissaoRelatorio.idModalidadeNivelEscolar = vm.nivelModalidade.id;
        }

        function getDescricaoModalidadeNivel(nivelModalidade) {
            if (!nivelModalidade) {
                return;
            }

            var nivelEscolar = nivelModalidade.nivelEscolar ? ' > ' + vm.NivelEscolar[nivelModalidade.nivelEscolar].descricao : '';

            return nivelModalidade ? vm.ModalidadeMatricula[nivelModalidade.modalidade].descricao + nivelEscolar : '';
        }

        function changeEtapa() {
            changeLiberaListagem(false);
            vm.turnos = vm.etapaFiltro.turnos;
            vm.parametrosEmissaoRelatorio.idEtapa = vm.etapaFiltro.id;
        }

        function changeTurno() {
            changeLiberaListagem(false);
            vm.parametrosEmissaoRelatorio.turno = vm.turno;
        }

        function changeSituacao() {
            changeLiberaListagem(false);
            vm.parametrosEmissaoRelatorio.situacao = vm.situacao;
        }

        function getSituacoesEnum() {
            return $enumsCache.get('SituacaoInscricao').then(function (enumData) {
                vm.SituacaoInscricao = enumData;
            });
        }

        function getNivelEscolarEnum() {
            return $enumsCache.get('NivelEscolar').then(function (enumData) {
                vm.NivelEscolar = enumData;
            });
        }

        function getModalidadeEnum() {
            return $enumsCache.get('ModalidadeMatricula').then(function (enumData) {
                vm.ModalidadeMatricula = enumData;
            });
        }

        function getTurnoEnum() {
            return $enumsCache.get('Turno').then(function (enumData) {
                vm.TurnoEnum = enumData;
                vm.turnos = [];
                vm.TurnoEnum.values.forEach(function (i) {
                    vm.turnos.push(i.key);
                });
            });
        }

        function getDeficienciaEnum() {
            return $enumsCache.get('TipoDeficiencia').then(function (enumData) {
                vm.Deficiencias = enumData;
            });
        }

        function isAplicavel() {
            if (vm.classificarPorEstabelecimento && !vm.estabelecimentoFiltro) {
                return false;
            }

            if (vm.classificarPorModalidade && !vm.nivelModalidade) {
                return false;
            }

            if (vm.classificarPorEtapa && (!vm.etapaFiltro || !vm.nivelModalidade)) {
                return false;
            }

            if (vm.classificarPorTurno && !vm.turno) {
                return false;
            }

            return true;
        }

        function searchEstabelecimentos() {
            return $q.when(getEstabelecimento());
        }

        function getEstabelecimento() {
            var estabelecimentos = _.chain(_.cloneDeep(vm.estabelecimentos))
                .map(function (estabelecimento) {
                    return {
                        id: estabelecimento.idMongo,
                        descricao: estabelecimento.descricao,
                        idRelacional: estabelecimento.id
                    };
                })
                .value();
            return {
                content: estabelecimentos
            };
        }

        function formatEstabelecimento(data) {
            return data.descricao;
        }

        function searchNiveisModalidades() {
            return $q.when(getNiveisModalidades());
        }

        function getNiveisModalidades() {
            return {
                content: vm.niveisModalidades
            };
        }

        function formatNiveisModalidades(data) {
            return getDescricaoModalidadeNivel(data);
        }

        function searchEtapas() {
            return $q.when(getEtapas());
        }

        function getEtapas() {
            return {
                content: _.get(vm.filtro, 'niveisModalidades.etapas')
            };
        }

        function formatEtapas(data) {
            return data.descricao;
        }

        function searchTurnos() {
            return $q.when(getTurnos());
        }

        function getTurnos() {
            return {
                content: vm.filtro.nivelModalidadeEtapa.turnos
            };
        }

        function formatTurnos(data) {
            return data;
        }

        function getNomeMae(filiacoes) {
            return _.get(_.find(filiacoes, 'tipo', 'MAE'), 'nome');
        }

        function getTelefones(telefones) {
            var telefonesNumeros = _.pluck(telefones, 'telefone');

            var bfMaskPhone = $filter('bfMaskPhone');

            return _.map(telefonesNumeros, function (data) {
                return bfMaskPhone(data);
            }).join(', ');
        }

        function getInscritos(params) {
            //todo: isolar chamada pelo ui-list-grid
            if ((vm.classificarPorEstabelecimento || vm.classificarPorModalidade || vm.classificarPorEtapa || vm.classificarPorTurno) &&
                !vm.filterClassificacao && !vm.configuracao) {
                return $q.when();
            }

            if (params.filter) {
                params.filter = '(' + params.filter + ')';
            }

            if (vm.filterClassificacao) {
                params.filter += (params.filter ? ' and ' : '') + vm.filterClassificacao;
            }

            var inscricao = InscricaoListaEsperaService.getInscritosPosicao(params, vm.configuracao.id);
            inscricao.then(setTotal);
            return inscricao;

        }

        function setTotal(dado) {
            vm.indicadoresTotal = dado.total;
        }

        function aplicarFiltro(newSituacao) {
            if (!vm.configuracao) {
                return;
            }

            vm.filterClassificacao = '';
            if (vm.classificarPorEstabelecimento && _.get(vm.estabelecimentoFiltro, 'idMongo')) {
                vm.filterClassificacao = '(estabelecimento.id="' + vm.estabelecimentoFiltro.idMongo + '")';
            }
            if (vm.classificarPorModalidade && _.get(vm.nivelModalidade, 'id')) {
                vm.filterClassificacao += concatenaFiltro(vm.filterClassificacao, '(nivelModalidade.id="' + vm.nivelModalidade.id +
                    '")');
            }
            if (vm.classificarPorEtapa && _.get(vm.etapaFiltro, 'id')) {
                vm.filterClassificacao += concatenaFiltro(vm.filterClassificacao, '(etapa.id="' + vm.etapaFiltro.id + '")');
            }
            if (vm.classificarPorTurno && vm.turno) {
                vm.filterClassificacao += concatenaFiltro(vm.filterClassificacao, '(turno="' + vm.turno + '")');
            }
            if (vm.situacao) {
                vm.situacaoFiltrada = _.cloneDeep(vm.situacao);
                vm.filterClassificacao += concatenaFiltro(vm.filterClassificacao, 'situacao = "' + vm.situacao + '"');
            }

            if (verificaFiltroPreenchido() && !newSituacao) {
                return;
            }

            if (vm.filterClassificacao) {
                vm.filterClassificacao = '(' + vm.filterClassificacao + ')';
            }

            vm.possuiFiltro = !!vm.filterClassificacao;
            search();

            function concatenaFiltro(filterClassificacao, filter) {
                if (!filterClassificacao) {
                    return filter;
                }
                return ' and ' + filter;
            }

        }

        function verificaFiltroPreenchido() {
            return ((vm.classificarPorEstabelecimento && !vm.estabelecimentoFiltro) ||
                (vm.classificarPorModalidade && !vm.nivelModalidade) ||
                (vm.classificarPorEtapa && !vm.etapaFiltro) ||
                (vm.classificarPorTurno && !vm.turno));
        }

        function limparFiltro() {
            vm.estabelecimentoFiltro = undefined;
            vm.nivelModalidade = undefined;
            vm.etapaFiltro = undefined;
            vm.turno = undefined;
        }

        function openModalMatricular(itemId, etapa, estabelecimentoInscricao) {
            InscricaoListaEsperaService.getInscritosEditar(vm.configuracao.id, itemId)
                .then(_.bind(getParamsModalMatricular, null, etapa, estabelecimentoInscricao));
        }

        function getParamsModalMatricular(etapa, estabelecimentoInscricao, itemInscricao) {
            $q.all([getMunicipios(itemInscricao),
                getBairros(itemInscricao),
                getReligiao(itemInscricao),
                getPais(itemInscricao),
                getOrgaoEmissor(itemInscricao),
                getCartorio(itemInscricao),
                createFiliacao(itemInscricao),
                getDeficiencias(itemInscricao),
                getPessoas(itemInscricao)])
                .then(_.bind(createAlunoInscricao, null, itemInscricao))
                .then(_.bind(setParamsModal, null, etapa, estabelecimentoInscricao, itemInscricao));
        }

        function setParamsModal(etapa, estabelecimentoInscricao, itemInscricao) {
            var matricula = {
                tipoMatricula: INSCRICAO_TO_MATRICULA[etapa.configuracaoNivelModalidade.modalidade],
                estabelecimento: _.find(vm.estabelecimentos, 'idMongo', estabelecimentoInscricao.estabelecimento.id),
                origemInscrito: 'listaEspera'
            };

            var filterAluno = '(pessoa.nome elike "' + encodeURI(itemInscricao.nome) +
                '" and sexo = "' + itemInscricao.sexo +
                '" and pessoa.dataNascimento = ' + itemInscricao.dataNascimento + ')';

            uiModal.open({
                templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
                controller: 'educacao.matricula.MatriculaCadCtrl',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    $$origemInscrito: 'listaEspera',
                    matricula: {
                        $$matriculaOrigemInscricao: matricula,
                        $$bloqueioAlteracoes: true
                    },
                    $$alunos: AlunoService.list(filterAluno, 0, 999).then(
                        function (data) {
                            return data.content;
                        }
                    ),
                    $$nivelEscolar: etapa.configuracaoNivelModalidade.nivelEscolar,
                    $$turnos: _.pluck(estabelecimentoInscricao.turnos, 'turno'),
                    alunoInscricao: vm.aluno
                }
            })
                .result
                .then(_.bind(atualizaInscritoMatriculado, null, itemInscricao.id, etapa.nivelModalidadeEtapa.id));
        }

        function createAlunoInscricao(itemInscricao) {
            vm.aluno = {
                filiacoes: vm.filiacao,
                enderecos: createAlunoEndereco(itemInscricao.enderecoResidencia),
                pessoa: {
                    telefones: itemInscricao.telefones,
                    nomeFantasia: itemInscricao.nomeSocial,
                    emails: itemInscricao.emails,
                    nome: itemInscricao.nome,
                    dataNascimento: itemInscricao.dataNascimento,
                    sexo: itemInscricao.sexo,
                    estadoCivil: itemInscricao.estadoCivil,
                    orientacaoSexual: itemInscricao.orientacaoSexual,
                    grauInstrucao: itemInscricao.grauInstrucao,
                    municipioNascimento: _.find(vm.municipios, 'id', itemInscricao.municipioNascimento),
                    estrangeiro: itemInscricao.estrangeiro,
                    rg: itemInscricao.rg,
                    ufEmissaoRg: itemInscricao.ufEmissaoRg,
                    dataEmissaoRg: itemInscricao.dataEmissaoRg,
                    pis: itemInscricao.pis,
                    dataPis: itemInscricao.dataEmissaoPis,
                    cpf: itemInscricao.cpf,
                    complemento: {
                        tituloEleitor: itemInscricao.tituloEleitor,
                        zonaEleitoral: itemInscricao.zonaEleitoral,
                        secaoEleitoral: itemInscricao.secaoEleitoral,
                        numeroReservista: itemInscricao.numeroReservista,
                        raca: itemInscricao.raca,
                        corDosOlhos: itemInscricao.corDosOlhos,
                        doadorDeOrgaos: itemInscricao.doador,
                        habilidadeMotora: itemInscricao.habilidadeMotora,
                        paisNacionalidade: vm.pais
                    }
                },
                alunoDeficiencias: vm.alunoDeficiencias,
                permiteEnvioSms: itemInscricao.permiteEnvioSms,
                permiteEnvioEmai: itemInscricao.permiteEnvioEmail,
                religiao: vm.religiao,
                paisOrigem: vm.pais,
                naturalizado: itemInscricao.naturalizado,
                orgaoEmissorRG: _.first(vm.orgaoEmissor),
                cns: itemInscricao.cns,
                dataEmissaoCns: itemInscricao.dataEmissaoCns,
                ric: itemInscricao.ric,
                orgaoEmissorRic: itemInscricao.orgaoEmissorRic,
                ufEmissaoRic: itemInscricao.ufEmissaoRic,
                dataEmissaoRic: itemInscricao.dataEmissaoRic,
                certidaoCivil: itemInscricao.certidaoCivil,
                modeloCertidao: itemInscricao.modeloCertidao,
                numeroCertidao: itemInscricao.numeroCertidao,
                cartorioCertidao: vm.cartorioCertidao ? {
                    id: vm.cartorioCertidao.id,
                    descricao: vm.cartorioCertidao.nome
                } : null,
                tipoSanguineo: itemInscricao.tipoSanguineo,
                fuma: itemInscricao.fuma,
                planoSaude: itemInscricao.planoSaude,
                possuiPlanoSaude: itemInscricao.possuiPlanoSaude,
                utilizaMedicacao: itemInscricao.utilizaMedicacao,
                medicacoes: itemInscricao.medicacoes,
                alergias: itemInscricao.alergias,
                numeroTermo: itemInscricao.numeroTermo,
                folhaCertidao: itemInscricao.numeroFolhaCertidao,
                livroCertidao: itemInscricao.numeroLivroCertidao,
                emissaoCertidao: itemInscricao.dataEmissaoCertidao,
                municipioCertidao: _.find(vm.municipios, 'id', itemInscricao.municipioCertidao)
            };

            if (vm.pessoa) {
                vm.aluno.pessoa.id = vm.pessoa.pessoa.id;
            }
        }

        function getPessoas(itemInscricao) {
            var filter = 'cpf = "' + itemInscricao.cpf + '"';

            return InscricaoListaEsperaService.getPessoas(filter)
                .then(function (pessoa) {
                    vm.pessoa = _.first(pessoa.content);
                });
        }

        function createFiliacao(itemInscricao) {
            vm.filiacao = [];

            if (_.isEmpty(itemInscricao.filiacoes)) {
                return;
            }

            _.forEach(itemInscricao.filiacoes, function (item) {
                if (item.cpf) {
                    FiliacaoService.getFiliacaoById(item.cpf)
                        .then(function (dado) {
                            var content = _.first(dado.content);
                            if (content) {
                                buildFiliacao(content, item);
                                return;
                            }
                            criaFiliacaoCasoNaoExista(item);
                        });

                    return;
                }

                criaFiliacaoCasoNaoExista(item);

                function criaFiliacaoCasoNaoExista(filiacao) {
                    var filiacaoCreate = {
                        pessoa: {},
                        vinculado: {}
                    };

                    filiacaoCreate.pessoa = {
                        celular: filiacao.cpf,
                        cpf: filiacao.cpf,
                        email: filiacao.email,
                        nome: filiacao.nome,
                        rg: filiacao.rg,
                        sexo: filiacao.sexo,
                        telefone: filiacao.telefone
                    };
                    filiacaoCreate.vinculado = {
                        localTrabalho: filiacao.localTrabalhoDosPais,
                        permiteEnvioEmail: filiacao.permiteEmail,
                        permiteEnvioSms: filiacao.permiteSms,
                        situacaoTrabalho: filiacao.empregado ? 'EMPREGADO' : null,
                        telefoneComercial: null
                    };

                    FiliacaoService.save(filiacaoCreate)
                        .then(function (dado) {
                            buildFiliacao(dado, filiacao);
                        });
                }
            });
        }

        function buildFiliacao(dado, filiacao) {
            delete dado.vinculado;
            vm.filiacao.push({
                diaSemana: [],
                filiacao: dado,
                tipo: filiacao.tipo
            });
        }

        function getMunicipios(itemInscricao) {
            return InscricaoListaEsperaUtils.getMunicipios(itemInscricao)
                .then(function (dado) {
                    vm.municipios = _.get(dado, 'content');
                });
        }

        function getBairros(itemInscricao) {
            return InscricaoListaEsperaUtils.getBairros(itemInscricao)
                .then(function (dado) {
                    vm.bairros = _.get(dado, 'content');
                });
        }


        function getReligiao(itemInscricao) {
            delete vm.religiao;
            if (!itemInscricao.religiao) {
                return;
            }

            return InscricaoListaEsperaService.getReligiao(itemInscricao.religiao)
                .then(function (dado) {
                    vm.religiao = dado;
                });
        }

        function getCartorio(itemInscricao) {
            delete vm.cartorioCertidao;
            if (!itemInscricao.cartorioCertidao) {
                return;
            }

            return InscricaoListaEsperaService.getCartorio(itemInscricao.cartorioCertidao)
                .then(function (dado) {
                    vm.cartorioCertidao = dado;
                });
        }

        function getDeficiencias(itemInscricao) {
            vm.alunoDeficiencias = [];
            if (_.isEmpty(itemInscricao.deficiencias)) {
                return;
            }

            var filter = '(id in (' + _.compact(_.pluck(itemInscricao.deficiencias, 'id')).join() + '))';

            return InscricaoListaEsperaService.getDeficiencias(filter)
                .then(function (dado) {
                    _.forEach(dado.content, function (deficiencia) {
                        vm.alunoDeficiencias.push({
                            deficiencia: deficiencia,
                            observacao: _.find(itemInscricao.deficiencias, 'id', deficiencia.id).observacao
                        });
                    });
                });
        }

        function getOrgaoEmissor(itemInscricao) {
            delete vm.orgaoEmissor;
            if (!itemInscricao.orgaoEmissorRg) {
                return;
            }

            var filter = '(id in (' + itemInscricao.orgaoEmissorRg + '))';

            return InscricaoListaEsperaService.getOrgaoEmissor(filter)
                .then(function (dado) {
                    vm.orgaoEmissor = dado.content;
                });
        }

        function getPais(itemInscricao) {
            delete vm.pais;
            if (!itemInscricao.paisNacionalidade) {
                return;
            }

            return InscricaoListaEsperaService.getPais(itemInscricao.paisNacionalidade)
                .then(function (dado) {
                    vm.pais = dado;
                });
        }

        function createAlunoEndereco(enderecoResidencia) {
            if (!enderecoResidencia) {
                return;
            }

            return [
                {
                    logradouro: enderecoResidencia.nomeLogradouro,
                    numero: enderecoResidencia.numero,
                    bairro: enderecoResidencia.nomeBairro,
                    bairroEndereco: _.find(vm.bairros, 'id', enderecoResidencia.bairroEndereco),
                    municipio: _.find(vm.municipios, 'id', enderecoResidencia.municipio),
                    estados: _.find(vm.estados, 'id', enderecoResidencia.municipio.estado),
                    principal: true,
                    cep: enderecoResidencia.cep,
                    complemento: enderecoResidencia.complemento
                }
            ];
        }

        function atualizaInscritoMatriculado(idInscrito, etapaId) {
            var dado = {
                nivel: 'ETAPA',
                idEtapa: etapaId
            };
            return InscricaoListaEsperaService.situacaoMatriculado(vm.configuracao.id, idInscrito, dado)
                .then(thenMessage)
                .then(aplicarFiltro);

            function thenMessage() {
                setMessage('Inscrito matriculado com sucesso.');
            }
        }

        function openModalCancelamento(inscricao, etapa, estabelecimentoInscricao) {
            uiModal.open({
                templateUrl: 'lista-espera-new/inscricao/cancelar-inscricao-lista-espera/cancelar-inscricao-lista-espera.html',
                controller: 'educacao.lista-espera-novo.inscricao.CancelaInscricaoListaEspera',
                controllerAs: 'vm',
                params: {
                    estabelecimentoInscricao: estabelecimentoInscricao,
                    etapaId: _.get(etapa, 'nivelModalidadeEtapa.id'),
                    inscricaoId: _.get(inscricao, 'id'),
                    configuracaoId: vm.configuracao.id,
                    nomeCandidato: _.get(inscricao, 'nome'),
                    onClose: $q.when(aplicarFiltro)
                }
            });
        }

        function verificaStatusUltimoLote(status) {
            return vm.ultimoLote && vm.ultimoLote.status === status;
        }

        function getClassWidgetCriterios(opened) {
            return opened ? 'bth-card__header--opened' : 'bth-card__header--closed';
        }

        function openWidgetCriterios() {
            vm.widgetsCriterios = !vm.widgetsCriterios;
        }

        function permiteAletarDtInsc() {
            return vm.hasPermission &&
                vm.configuracao.permiteAlterarDataHora &&
                vm.configuracao.tipoAlteracaoDataHoraList.includes('DATA_INSC');
        }

        function permiteAletarDtInscEstab() {
            return vm.hasPermission &&
                vm.configuracao.permiteAlterarDataHora &&
                vm.configuracao.tipoAlteracaoDataHoraList.includes('DATA_INSC_ESTAB');
        }

        function permiteAletarDtInscTurno() {
            return vm.hasPermission &&
                vm.configuracao.permiteAlterarDataHora &&
                vm.configuracao.tipoAlteracaoDataHoraList.includes('DATA_INSC_TURNO');
        }

        function openModalEncaminhamento(inscricao, data, etapa, idEstabelecimentoOrigem, turnos) {
            var modalidadeNivelEtapa = {
                id: _.get(etapa, 'nivelModalidadeEtapa.id'),
                descricao: getDescricaoModalidadeNivel(_.get(etapa, 'configuracaoNivelModalidade')) + ' > ' + _.get(etapa,
                    'nivelModalidadeEtapa.descricao')
            };

            uiModal.open({
                templateUrl: 'lista-espera-new/inscricao/encaminhar-inscricao-lista-espera/encaminhar-inscricao-lista-espera.html',
                controller: 'educacao.lista-espera-novo.inscricao.EncaminharInscricaoListaEspera',
                controllerAs: 'vm',
                params: {
                    configuracao: vm.configuracao,
                    inscricao: inscricao,
                    dataInscricao: data || inscricao.data,
                    modalidadeNivelEtapa: etapa ? modalidadeNivelEtapa : null,
                    idEstabelecimentoOrigem: idEstabelecimentoOrigem,
                    turno: turnos ? turnos.length === 1 ? turnos : null : null,
                    onClose: $q.when(aplicarFiltro)
                }
            }).result.then(EncaminhadoService.setTotal);
        }

        function buttonDisabled(turnos) {
            return !Boolean(_.find(turnos, 'situacao.situacao', vm.situacaoFiltrada));
        }

        function changeConfiguracao() {
            changeLiberaListagem(false);

            var promise = ConfigListaEsperaService.get(vm.configuracaoSelected.id)
                .then(function (data) {
                    vm.configuracao = data;
                    getConfiguracaoDados();
                });

            vm.tracker.changeConfiguracao.addPromise(promise);
        }

        function abreCadastro(inscricaoId) {
            uiModal.open({
                templateUrl: 'lista-espera-new/inscricao/cadastro-inscricao-lista-espera.html',
                controller: 'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaCadCtrl',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    configuracao: vm.configuracao.id,
                    inscricaoListaEspera: inscricaoId ? InscricaoListaEsperaService.getInscritosEditar(vm.configuracao.id,
                        inscricaoId) : {},
                    onClose: $q.when(aplicarFiltro)
                }
            });
        }

        function openModalAnotacoes(inscricaoId) {
            uiModal.open({
                templateUrl: 'lista-espera-new/inscricao/anotacoes/modal-anotacoes-inscrito/modal-anotacoes-inscrito.html',
                controller: 'educacao.lista-espera-novo.anotacoes.ModalAnotacoesInscritos',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    inscricaoListaEspera: inscricaoId,
                    onClose: $q.when(aplicarFiltro)
                }
            });
        }

        function cadastroConfiguracao() {
            $state.go('app.lista-espera-novo.configuracao', {
                abrirCadastro: true
            });
        }

        function setVisiblePontuacao() {
            var column = _.find(vm.columns, 'id', 'pontuacao');
            column.visible = vm.configuracao.utilizaCriterios;
        }

        function getConfiguracaoDados() {
            getConfiguracao();
            setVisiblePontuacao();
            initEstabelecimentos();
            vm.estabelecimentoFiltro = undefined;
            vm.nivelModalidade = undefined;
            vm.etapaFiltro = undefined;
            vm.turno = undefined;
            vm.niveisModalidades = vm.configuracao.niveisModalidades;
            vm.parametrosEmissaoRelatorio = {
                idConfiguracao: vm.configuracao.id,
                situacao: vm.situacao
            };
            vm.classificarPorEstabelecimento = classificarPor('ESTABELECIMENTO');
            vm.classificarPorModalidade = classificarPor('MODALIDADE_NIVEL');
            vm.classificarPorEtapa = classificarPor('ETAPA');
            vm.classificarPorTurno = classificarPor('TURNO');
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ConfiguracaoInscricaoCadCtrl', Controller);

    Controller.$inject = ['$injector',
        'promiseTracker',
        '$scope',
        '$rootScope',
        '$modalInstance',
        '$modalTracker',
        'id',
        'configuracao',
        'nivelEscolar',
        '$q',
        'readOnly'
    ];

    function Controller($injector,
        promiseTracker,
        $scope,
        $rootScope,
        $modalInstance,
        $modalTracker,
        id,
        configuracao,
        nivelEscolar,
        $q,
        readOnly) {
        var TAB_ACTIVATED = 'label badge-inverse',
            TAB_DESACTIVATED = 'label',
            REFRESH_CONFIGS = 'refresh::configuracoesInscricao';

        var vm = this,
            QuadroVagasService = $injector.get('educacao.matricula.QuadroVagasService'),
            ConfiguracaoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoInscricaoService'),
            NivelEscolarService = $injector.get('educacao.matricula.NivelEscolarService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService'),
            LiberacaoVagaService = $injector.get('educacao.matricula.LiberacaoVagasEstabelecimentoService'),
            FilterEncodedUtilsService = $injector.get('educacao.common.FilterEncodedUtilsService'),
            options = {};

        vm.tracker = {
            save: promiseTracker(),
            searchingVagas: promiseTracker(),
            filterVagas: promiseTracker(),
            bloqueandoQuadroVagas: promiseTracker()
        };

        vm.liberadaConfiguracao = true;
        vm.loading = promiseTracker();
        vm.changeTabs = changeTabs;
        vm.tabsBuilt = false;
        vm.configuracao = _.cloneDeep(configuracao);
        vm.configuracaoDefault = {};
        vm.options = {};

        vm.search = {
            matriz: {},
            etapaMatriz: {},
            turno: {
                options: []
            },
            text: {
                value: ''
            },
            isSearching: false
        };

        vm.qtdVagasSelecionadas = 0;

        vm.bloquearQuadroVagas = bloquearQuadroVagas;
        vm.corNumberTab = corNumberTab;
        vm.dataHoraProcesso = {};
        vm.cancelarEvento = cancelarEvento;
        vm.hasParametroResidenciaAndUtilizaZoneamento = hasParametroResidenciaAndUtilizaZoneamento;

        vm.nivelEscolarPromise = ConfiguracaoInscricaoService.getNivelEscolar();

        vm.ORDEM_INSCRICAO = 'ORDEM_INSCRICAO';

        var metodosPublicas = {
            cancel: _cancel,
            save: _save,
            searchVagas: searchVagas,
            checkGrupoVagas: checkGrupoVagas,
            transferVaga: transferVaga,
            validateTab: validateTab,
            doSearchVagas: doSearchVagas,
            isSearching: isSearching,
            initFilters: initFilters,
            modalTabsStep: modalTabsStep,
            clearTextFilter: clearTextFilter
        };

        function bloquearQuadroVagas() {
            vm.tracker.bloqueandoQuadroVagas.addPromise(
                LiberacaoVagaService.bloquearTodos().then(function() {
                    vm.liberadaConfiguracao = true;
                    initConfiguracao();
                })
            );
        }

        function corNumberTab(tab) {
            return tab.active ? 'label badge-inverse' : 'label';
        }

        function modalTabsStep() {
            $scope.$applyAsync(function() {
                if (!vm.tabsBuilt) {
                    vm.tabsBuilt = true;
                }
            });

        }

        function cancelarEvento(evento) {
            evento.stopPropagation();
        }

        vm.tabs = [{
            id: 1,
            name: 'estabelecimentos',
            descricao: 'Estabelecimentos',
            visible: true,
            html: 'matricula/configuracao-inscricao/configuracao-inscricao-estabelecimento.tab.html',
            cor: TAB_ACTIVATED,
            active: true
        }, {
            id: 2,
            name: 'parametroscomuns',
            descricao: 'Parâmetros',
            visible: false,
            cor: TAB_DESACTIVATED,
            active: false
        }, {
            id: 3,
            name: 'inscricaoOnline',
            descricao: 'Inscrição on-line',
            visible: false,
            cor: TAB_DESACTIVATED,
            active: false
        }, {
            id: 4,
            name: 'criteriosClassificacao',
            descricao: 'Critérios',
            visible: false,
            cor: TAB_DESACTIVATED,
            active: false
        }];

        vm.TABS_NAMES = {
            ESTABELECIMENTOS: 0,
            PARAMETROS: 1,
            INSCRICAO_ONLINE: 2,
            CRITERIOS_CLASSIFICACAO: 3 
        };

        vm.tabSelected = vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS];

        vm.modo = {
            adicionando: !id && !readOnly,
            editando: id && !readOnly,
            visualizando: id && readOnly
        };

        function initFilters() {

            vm.search.matriz.value = null;
            vm.search.etapaMatriz.value = null;
            vm.search.turno.value = null;

            formatTableVagasToView(agrupVagas(options.listaVagasOptionsToSearch));

        }

        function getFilterString(filter, model, descricao) {
            if (model && !model.id) {
                filter[descricao] = model;
                return filter;
            }
            filter[descricao] = model ? model.id : model;

            return filter;
        }

        function doSearchVagas() {

            var filter = {},
                listaVagasOptionsToSearch;

            vm.search.text.value = vm.search.text.value.toLowerCase();

            if (!!vm.tracker.filterVagas.active()) {
                vm.tracker.filterVagas.cancel();
            }

            vm.tracker.filterVagas.addPromise(
                $q.when((function() {
                    if (_.isString(vm.search.text.value) && vm.search.text.value.length > 0) {
                        listaVagasOptionsToSearch = _.filter(options.listaVagasOptionsToSearch, function(vaga) {
                            return vaga.estabelecimento.descricao.toLowerCase().search(vm.search.text.value) >= 0 ||
                                vaga.etapaMatriz.descricao.toLowerCase().search(vm.search.text.value) >= 0 ||
                                vaga.matriz.descricao.toLowerCase().search(vm.search.text.value) >= 0 ||
                                vaga.turno.toLowerCase().search(vm.search.text.value) >= 0;
                        });
                    } else {
                        listaVagasOptionsToSearch = options.listaVagasOptionsToSearch;
                    }

                    filter = getFilterString(filter, vm.search.matriz.value, 'matriz');
                    filter = getFilterString(filter, vm.search.etapaMatriz.value, 'etapaMatriz');
                    filter = getFilterString(filter, vm.search.turno.value, 'turno');

                    if (!!filter.matriz || !!filter.etapaMatriz || !!filter.turno) {

                        vm.search.isSearching = true;
                        listaVagasOptionsToSearch = listaVagasOptionsToSearch.filter(function(quadroVagas) {
                            if ((!filter.matriz || filter.matriz === quadroVagas.matriz.id) &&
                                (!filter.etapaMatriz || filter.etapaMatriz === quadroVagas.etapaMatriz.id) &&
                                (!filter.turno || filter.turno === quadroVagas.turno)) {
                                return quadroVagas;
                            }
                        });
                    }

                    vm.search.isSearching = isSearching();

                    formatTableVagasToView(agrupVagas(listaVagasOptionsToSearch));
                })())
            );

        }

        function clearTextFilter() {
            vm.search.text.value = '';
            doSearchVagas();
        }

        function isSearching() {
            return Boolean(!!vm.search.matriz.value || !!vm.search.etapaMatriz.value || !!vm.search.turno.value || vm.search.text.value);
        }

        function agrupVagas(data) {

            return _.chain(data)
                .groupBy(function(item) {
                    return item.estabelecimento.descricao;
                })
                .map(function(item, key) {
                    var result = {};

                    result[key] = _.groupBy(item, function(subitem) {
                        return subitem.matriz.descricao;
                    });

                    return result;
                })
                .value();

        }

        function searchVagas(limpaListaVagas) {
            if (!vm.configuracao.nivelEscolar) {
                vm.options.groupListVagas = [];
                return;
            }
            if (limpaListaVagas && !vm.editando) {
                vm.configuracao.vagas = [];
            } else {
                vm.editando = false;
            }

            if (!vm.tracker.searchingVagas.active()) {
                vm.tracker.searchingVagas.addPromise(
                    NivelEscolarService
                    .get(vm.options.NivelEscolar[vm.configuracao.nivelEscolar].value)
                    .then(function(data) {
                        options.listaVagasOptionsToSearch = angular.copy(data);
                        vm.quadroVagas = angular.copy(data);
                        vm.qtdVagasSelecionadas = 0;

                        formatTableVagasToView(agrupVagas(data));
                    })
                );
            }

        }

        function transferVaga(vagaView) {
            var subGroup = vagaView.parent,
                group = subGroup.parent;

            var saldoInscricao = _.get(_.find(vagaView.saldos, {
                tipo: 'INSCRICAO'
            }), 'qtdVagas');

            if (vagaView.checked) {

                if (!vm.configuracao.vagas) {
                    vm.configuracao.vagas = [];
                }

                vm.configuracao.vagas.push(_.omit(vagaView, 'checked', 'parent'));

                vm.qtdVagasSelecionadas += saldoInscricao || 0;
            } else {

                vm.configuracao.vagas = vm.configuracao.vagas.filter(function(vaga) {
                    if (vaga.id !== vagaView.id) {
                        return vaga;
                    }
                });
                vm.qtdVagasSelecionadas -= saldoInscricao || 0;
            }

            subGroup.checked = !subGroup.children.some(function(item) {
                return !item.checked;
            });

            group.checked = !group.children.some(function(sub) {
                return !sub.checked;
            });
            var idProximaTab = vm.tabSelected.id;
            vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].active = !_.isArray(vm.configuracao.vagas) ? false : vm.configuracao.vagas.length > 0;
            vm.tabs[idProximaTab].cor = vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].visible ? TAB_ACTIVATED : TAB_DESACTIVATED;
            vm.tabs[idProximaTab].active = vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].active;
            // validateTab(vm.tabSelected);
        }

        function checkGrupoVagas(node) {

            var checked = node.checked;
            vm.checado = node.checked;
            node.children.forEach(function(child) {

                if (!!child.children) {
                    child.checked = checked;
                    checkGrupoVagas(child);
                } else {
                    if (child.checked !== checked) {
                        child.checked = checked;
                        transferVaga(child, node);
                    }
                }
            });
        }

        initConfiguracao();

        function validateTab(tabSelected) {
            var maxTabs = vm.tabs.length,
                result;

            var checkTab = function(idTab, isValid) {
                if (idTab <= maxTabs) {
                    vm.tabs[idTab].cor = vm.tabs[idTab].visible ? TAB_ACTIVATED : TAB_DESACTIVATED;
                    vm.tabs[idTab].active = !!isValid;
                }

                return isValid;
            };

            var validateStepCriterio = function() {
                return _.isBoolean(vm.configuracao.permiteEscolhaEstabelecimento) && 
                    validateEscolasZoneamento() &&
                    ((vm.configuracao.online === true && _.isBoolean(vm.configuracao.divulgarQtdVagas)) || vm.configuracao.online ===
                        false) &&
                    vm.tabs[vm.TABS_NAMES.PARAMETROS].active;

            };

            function validateEscolasZoneamento() {
                if (_.isNull(vm.configuracao.utilizaZoneamento) || _.isUndefined(vm.configuracao.utilizaZoneamento)) {
                    return false;
                }

                if (vm.configuracao.utilizaZoneamento) {
                    return vm.configuracao.qtdEstabelecimentos > 0 && 
                        validateCampoEnderecoResidencia() &&
                        validateQtdEstabelecimentosZoneamento(vm.configuracao.qtdEstabelecimentosZoneamento, false) &&
                        validateQtdEstabelecimentosZoneamento(vm.configuracao.qtdEstabelecimentosForaZoneamento, true);
                }

                return vm.configuracao.qtdEstabelecimentos > 0;
            }

            function validateCampoEnderecoResidencia() {
                return _.find(vm.configuracao.campos, { campo: 'ENDERECO' });
            }

            function validateQtdEstabelecimentosZoneamento(qtdEstabelecimentosZoneamento, allowEqualZero) {
                if (allowEqualZero) {
                    return !_.isNull(qtdEstabelecimentosZoneamento) && !_.isUndefined(qtdEstabelecimentosZoneamento) && qtdEstabelecimentosZoneamento < vm.configuracao.qtdEstabelecimentos;
                }
                
                return !!qtdEstabelecimentosZoneamento && qtdEstabelecimentosZoneamento <= vm.configuracao.qtdEstabelecimentos;
            }

            switch (tabSelected.id) {
                case 1:
                    vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].active = !_.isArray(vm.configuracao.vagas) ? false : vm.configuracao.vagas.length > 0;
                    result = checkTab(tabSelected.id, vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].active);
                    break;
                case 2:
                    var dontHaveDateTime = _.some(vm.dataHoraProcesso, function(attribute) {
                        return !attribute;
                    });

                    result = checkTab(tabSelected.id, !dontHaveDateTime && vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].active);
                    break;
                case 3:
                    result = checkTab(tabSelected.id, validateStepCriterio());
                    break;
                case 4:
                    result = checkTab(tabSelected.id - 1, validateStepCriterio());
                    break;
            }

            if (tabSelected.id < maxTabs) {
                validateTab(vm.tabs[tabSelected.id]);
            }

            return result;
        }

        function changeTabs(tabSelected, initializing) {
            if (!tabSelected.active) {
                return;
            }
            if (!validateTab(vm.tabSelected) && !initializing && tabSelected.id > vm.tabSelected.id) {
                return;
            }

            vm.tabSelected = tabSelected;

            if (vm.tabSelected.id === 3) {
                validateTab(vm.tabSelected);
            }

            vm.tabs.forEach(function(tab) {
                tab.visible = (tab.id === tabSelected.id);
            });

            modalTabsStep(tabSelected.id);
        }

        // function init() {
        //     vm.loading.addPromise(
        //         $q.when(
        //             LiberacaoVagaService.liberados()
        //             .then(function(data) {
        //                 vm.liberadaConfiguracao = data.length === 0;
        //             })
        //         )
        //     );

        // }

        function initConfiguracao() {
            var promises = [ConfiguracaoInscricaoService.getDefaultRepresentation()
                .then(function(data) {
                    vm.configuracaoDefault = data;
                    vm.configuracaoDefault.nivelEscolar = nivelEscolar;
                }),
                    modalTabsStep(vm.tabs[vm.TABS_NAMES.ESTABELECIMENTOS].id),
                    ConfiguracaoInscricaoService.getNivelEscolar().then(function(data) {
                    vm.options.NivelEscolar = data;
                }),
                    QuadroVagasService.getEnums().then(function(data) {
                    vm.options.Turno = _.indexBy(data.Turno, 'key');
                    editTurnosEnums(data.Turno);
                }),
                ConfiguracaoInscricaoService.get(nivelEscolar).then(function(data) {
                    vm.configuracao = data;
                })
            ];

            vm.loading.addPromise(
                $q.all(promises).then(function() {
                    angular.extend(vm, metodosPublicas);
                    (function() {
                        getConfiguracaoValida();
                        if (!!vm.configuracao.nivelEscolar && !vm.options.groupListVagas) {
                            searchVagas();
                        }
                    })();
                })
            );
        }

        function editTurnosEnums(enums) {
            vm.listTurnos = enums;
            vm.turnosEnum = {
                values: []
            };
            angular.forEach(vm.listTurnos, function(turno) {
                var turnoEnum = {
                    'id': turno.key,
                    'key': turno.key,
                    'description': turno.description
                };

                vm.turnosEnum[turno.key] = turnoEnum;
                vm.turnosEnum.values.push(turnoEnum);
            });
            vm.turnosEnum = $q.when(vm.turnosEnum);
        }

        function hasParametroResidenciaAndUtilizaZoneamento() {
            if (!vm.configuracao.utilizaZoneamento) {
                return true;
            }

            return _.find(vm.configuracao.campos, { campo: 'ENDERECO' });
        }

        function formatTableVagasToView(listGrouped) {
            var listFormatted = [];

            var vagasFromModel = _.indexBy(vm.configuracao.vagas, 'id');
            vm.qtdVagasSelecionadas = 0;

            var mapItems = function(subGrupo) {
                return function(vaga) {
                    vaga.checked = !!vagasFromModel[vaga.id];
                    vaga.parent = subGrupo;
                    var saldoInscricao = _.get(_.find(vaga.saldos, {
                        tipo: 'INSCRICAO'
                    }), 'qtdVagas');

                    if (!!vaga.checked) {
                        vm.qtdVagasSelecionadas += saldoInscricao || 0;
                    }
                    return vaga;
                };
            };

            if (!!options.listaVagasOptionsToSearch) {
                vm.search.matriz.options = searchOptionsMatriz;
                vm.search.etapaMatriz.options = searchOptionsEtapaMatriz;
            }

            listGrouped.forEach(function(item) {
                var grupoFormatted = {};

                grupoFormatted.name = _.keys(item)[0];
                grupoFormatted.checked = false;
                grupoFormatted.isCollapsed = true;

                var grupo = item[grupoFormatted.name];

                grupoFormatted.children = [];

                for (var keySubGrupo in grupo) {
                    var subGrupo = {};

                    subGrupo.name = keySubGrupo;
                    subGrupo.checked = false;
                    subGrupo.children = grupo[keySubGrupo];

                    subGrupo.isCollapsed = true;

                    subGrupo.children = subGrupo.children.map(mapItems(subGrupo));

                    subGrupo.parent = grupoFormatted;
                    grupoFormatted.children.push(subGrupo);

                    subGrupo.checked = !subGrupo.children.some(function(son) {
                        return !son.checked;
                    });
                }

                grupoFormatted.checked = !grupoFormatted.children.some(function(sub) {
                    return !sub.checked;
                });

                listFormatted.push(grupoFormatted);

            });

            vm.options.groupListVagas = listFormatted;
            validateTab(vm.tabSelected);

        }

        function searchOptionsMatriz(params) {
            var listaMatriz = ObjectUtilsService.getFilterOptions(angular.copy(options.listaVagasOptionsToSearch), 'matriz',
                'descricao');

            var term = FilterEncodedUtilsService.getDecodedTerm(params.term);

            listaMatriz = _.filter(_.uniq(listaMatriz, 'id'), function(matriz) {
                return ObjectUtilsService.normalizeFilter(matriz.descricao.toUpperCase()).indexOf(ObjectUtilsService.normalizeFilter(
                    term.toUpperCase())) > -1;
            });

            return $q.when(listaMatriz).then(toPage);
        }

        function searchOptionsEtapaMatriz(params) {
            var listaEtapaMatriz = ObjectUtilsService.getFilterOptions(angular.copy(options.listaVagasOptionsToSearch), 'etapaMatriz',
                'descricao');

            var term = FilterEncodedUtilsService.getDecodedTerm(params.term);

            listaEtapaMatriz = _.filter(_.uniq(listaEtapaMatriz, 'id'), function(matriz) {
                return ObjectUtilsService.normalizeFilter(matriz.descricao.toUpperCase()).indexOf(ObjectUtilsService.normalizeFilter(
                    term.toUpperCase())) > -1;
            });

            return $q.when(listaEtapaMatriz).then(toPage);
        }

        function toPage(list) {
            list.forEach(setId);
            return {
                content: list,
                hasNext: false
            };
        }

        function setId(obj) {
            obj.id = obj.id || obj.key;
        }

        function getConfiguracaoValida() {
            if (vm.configuracao) {
                vm.tabs.forEach(function(tab) {
                    validateTab(tab);
                });
                vm.modo.editando = true;

            } else {
                vm.modo.editando = false;
                vm.configuracao = {};
                vm.configuracao = _.clone(vm.configuracaoDefault);
            }
        }

        function _save() {
            vm.configuracao.criterios.forEach(
                function(criterio, index) {
                    criterio.ordem = index + 1;
                }
            );

            vm.tracker.save.addPromise(
                ConfiguracaoInscricaoService.save(vm.configuracao).then(function(data) {
                    vm.configuracao.id = data.id;

                    $rootScope.$broadcast(REFRESH_CONFIGS, {
                        data: vm.configuracao,
                        add: !id,
                        edit: Boolean(id)
                    });

                    _cancel();
                })
            );
        }

        function _cancel() {
            $modalInstance.close();
        }

        $scope.$watch('vm.search.matriz.value', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.doSearchVagas();
            }
        });

        $scope.$watch('vm.search.etapaMatriz.value', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.doSearchVagas();
            }
        });

        $scope.$watch('vm.search.turno.value', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.doSearchVagas();
            }
        });

        $scope.$watch('vm.configuracao.campos', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                validateTab(vm.tabs[vm.TABS_NAMES.PARAMETROS]);
            }
        }, true);

        $scope.$watchGroup([
            'vm.configuracao.qtdEstabelecimentos', 
            'vm.configuracao.qtdEstabelecimentosZoneamento', 
            'vm.configuracao.qtdEstabelecimentosForaZoneamento', 
            'vm.configuracao.utilizaZoneamento'
        ], function(newValue, oldValue) {
            if (newValue !== oldValue) {
                validateTab(vm.tabs[vm.TABS_NAMES.INSCRICAO_ONLINE]);
            }
        });

    }
})();

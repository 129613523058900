(function() {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioSecretariaFeriadoService', CalendarioSecretariaFeriadoService);

    CalendarioSecretariaFeriadoService.$inject = ['educacao.common.MetadataServiceHelper', 'Restangular'];

    function CalendarioSecretariaFeriadoService(MetadataServiceHelper, restangular) {
        var metadataHelper = MetadataServiceHelper.create(getBasePoint());

        return {
            getDefaultRepresentation: metadataHelper.getDefaultRepresentation
        };

        function getBasePoint(calendario) {
            var id = calendario ? calendario.id.toString() : '0';

            return restangular
                .one('calendario', id)
                .all('feriados');
        }
    }

})();

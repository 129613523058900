(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .directive('appResumoSalasQuadroTurmaAlunos', appResumoSalasQuadroTurmaAlunosDirective);

    function appResumoSalasQuadroTurmaAlunosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/turma-alunos/resumo-salas/app-resumo-salas-quadro-turma-alunos.directive.html',
            scope: {},
            bindToController: {
                anoletivoselecionado: '=',
                estabelecimentoselecionado: '=',
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        'promiseTracker',
        'educacao.turma-alunos-quadro-informativo.GraficoTurmaAlunosService',
        '$scope'
    ];

    function Controller(ContextoAnoLetivo, ContextoEstabelecimento, QuadroFuncionariosTurmaAlunosService, promiseTracker, GraficoTurmaAlunosService, $scope) {
        var vm = this;
        vm.graficosSalas = {};
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.loadingTracker = {
            graficoDisponibilidadeSalas: promiseTracker(),
            graficoDisponibilidadeSalasPorTurnoMatutino: promiseTracker(),
            graficoDisponibilidadeSalasPorTurnoVespertino: promiseTracker(),
            graficoDisponibilidadeSalasPorTurnoNoturno: promiseTracker(),
            graficoDisponibilidadeSalasPorTurnoIntegral: promiseTracker(),

        };

        vm.paths = [
            'disponibilidade',
            'disponibilidadePorTurnoMatutino',
            'disponibilidadePorTurnoVespertino',
            'disponibilidadePorTurnoNoturno',
            'disponibilidadePorTurnoIntegral'
        ];


        $scope.$watch('vm.anoletivoselecionado', onChangeContextAnoLetivo);
        $scope.$watch('vm.estabelecimentoselecionado', onChangeContextEstabelecimento);
        init();

        function onChangeContextAnoLetivo(oldValue,newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }

        function onChangeContextEstabelecimento(oldValue,newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }


        function reloadValues() {
            GraficoTurmaAlunosService.clearValues();
            _.forEach(vm.paths, clearValue);
            init();
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoletivoselecionado, 'id');
            paramns.estabelecimentoId = _.get(vm.estabelecimentoselecionado, 'id');
            return paramns;
        }

        function clearValue(path) {
            vm.graficosSalas[path] = null;
        }


        function init() {
            getGraficoSalas('disponibilidade', 'graficoDisponibilidadeSalas', 'getGraficoDisponibilidadeSalas');
            getGraficoSalas('disponibilidadePorTurnoMatutino', 'graficoDisponibilidadeSalasPorTurnoMatutino', 'getGraficoDisponibilidadeSalasPorTurnoMatutino');
            getGraficoSalas('disponibilidadePorTurnoVespertino', 'graficoDisponibilidadeSalasPorTurnoVespertino', 'getGraficoDisponibilidadeSalasPorTurnoVespertino');
            getGraficoSalas('disponibilidadePorTurnoNoturno', 'graficoDisponibilidadeSalasPorTurnoNoturno', 'getGraficoDisponibilidadeSalasPorTurnoNoturno');
            getGraficoSalas('disponibilidadePorTurnoIntegral', 'graficoDisponibilidadeSalasPorTurnoIntegral', 'getGraficoDisponibilidadeSalasPorTurnoIntegral');
        }

        function getGraficoSalas(path, trackerName, serviceName) {
            var promise = GraficoTurmaAlunosService.getGraficoSalas(path, serviceName, resolveParamns())
                .then(function (data) {
                    vm.graficosSalas[path] = data;
                });
            vm.loadingTracker[trackerName].addPromise(promise);
        }
    }

})();
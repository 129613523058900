(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.TipoAvaliacaoService', TipoAvaliacaoService);

    TipoAvaliacaoService.$inject = ['ServiceBuilder'];

    function TipoAvaliacaoService(ServiceBuilder) {

        var service = ServiceBuilder
            .create()
            .enum('TipoDecimais', 'QuantidadeDecimais', 'TipoAvaliacao', 'SituacaoCadastral')
            .path('matricula', 'configuracao-avaliacao')
            .build();

        return service;
    }
})();

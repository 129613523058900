(function () {

    'use strict';

    angular.module('educacao.rematricula')
        .constant('SITUACAO_MATRICULA_REMATRICULA', {
            CURSANDO: 'CURSANDO',
            EM_EXAME: 'EM_EXAME',
            APROVADO: 'APROVADO',
            APROVADO_DEPENDENCIA: 'APROVADO_DEPENDENCIA',
            REPROVADO: ':REPROVADO',
            REPROVADO_FREQUENCIA: 'REPROVADO_FREQUENCIA',
            APROVADO_CONSELHO: 'APROVADO_CONSELHO',
            VALUES: ['CURSANDO', 'EM_EXAME', 'APROVADO', 'APROVADO_DEPENDENCIA', 'REPROVADO', 'REPROVADO_FREQUENCIA', 'APROVADO_CONSELHO']
        });
})();


(function () {
    'use strict';

    angular
        .module('educacao.pessoas')
        .directive('appFiltroAvancadoEscolaGeral', Directive);
    
    function Directive() {
        return {
        restrict: 'E',
        templateUrl:
            'planejamento/escolas/filtro-avancado/escola/escola-geral.directive.html',
        controller: Controller,
        controllerAs: 'vm',
        scope: {
            expressionCustomBuilders: '=',
            advancedFilter: '=',
            contadorInformacoesGerais: '=',
            contadorAreaAtuacao: '=',
            contadorInfraestrutura: '=',
            contadorDependencias: '=',
            contadorEquipeDiretiva: '='
        },
        bindToController: true
        };
    }

    Controller.$inject = [];

    function Controller() {}
})();
(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edSelectPais', EdSelectPais);

    EdSelectPais.$inject = [];

    function EdSelectPais() {

        var directiveConfig = {};

        directiveConfig.template = '<ui-select ng-model="ngModel" search="vm.search" minimum-input-length="3" format-result="vm.formatResult(data)" ng-required="!!notNull"></ui-select>';
        directiveConfig.restrict = 'E';
        directiveConfig.require = 'ngModel';

        directiveConfig.scope = {};
        directiveConfig.scope.ngModel = '=';
        directiveConfig.scope.notNull = '=';
        directiveConfig.bfRequired = '=';

        directiveConfig.controller = EdSelectPaisCtrl;
        directiveConfig.controllerAs = 'vm';

        directiveConfig.compile = function(element, tAttrs) {
            if (tAttrs.hasOwnProperty('bf-required') || tAttrs.hasOwnProperty('data-bf-required')) {
                element.children('ui-select').attr('bf-required', '{{bfRequired}}');
            }
        };

        return directiveConfig;
    }

    EdSelectPaisCtrl.$inject = ['$injector'];

    function EdSelectPaisCtrl($injector) {
        var PaisService = $injector.get('educacao.common.PaisService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService'),
            vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var term = ObjectUtilsService.normalizeFilter(params.term);
            var filter = '( nome like "' + term + '")';

            return PaisService.getList(params.offset, params.limit, filter).then(function(data) {
                return _.first(data);
            });
        }

        function formatResult(data) {
            return data.nome;
        }

    }
})();

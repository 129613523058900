(function () {

    'use strict';
    angular.module('educacao.matricula').directive('appSelectMotivoMovimentacao', SelectMotivoMovimentacaoDirective);

    SelectMotivoMovimentacaoDirective.$inject = [];
    function SelectMotivoMovimentacaoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.addValue"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                tipo: '=?'
            },
            controller: SelectMotivoMovimentacaoDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectMotivoMovimentacaoDirectiveController.$inject = ['$scope', 'ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.matricula.MotivoMovimentacaoService'];
    function SelectMotivoMovimentacaoDirectiveController($scope, uiModal, PermissionsService, MotivoMovimentacaoService) {

        var vm = this;

        vm.search = search;
        vm.addValue = !PermissionsService.isRevokedOperation('MotivoMovimentacaoPageMapping', 'criar') && addValue;

        function search(params) {
            var filter = '(descricao like "' + params.term + '"';
            if ($scope.tipo) {
                filter += ' and tipo = "' + $scope.tipo + '"';
            }
            filter += ')';

            return MotivoMovimentacaoService.list(filter, params.offset, params.limit);
        }

        function addValue(descricao) {
            return uiModal.open({
                templateUrl: 'matricula/motivos-movimentacoes/motivos-movimentacoes.cad.html',
                controller: 'educacao.matricula.MotivosDeMovimentacaoCadCtrl as vm',
                size: 'sm',
                params: {
                    motivoMovimentacao: {tipo: $scope.tipo, descricao: descricao}
                }
            }).result.then(returnValidValue);
        }

        function returnValidValue(motivoMovimentacao) {
            if (!$scope.tipo || motivoMovimentacao.tipo === $scope.tipo) {
                return motivoMovimentacao;
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.diarioclasse')
        .factory('educacao.diarioClasse.DiarioService', DiarioService);

    DiarioService.$inject = [
        '$q',
        'EventEmitter',
        'Restangular',
        'MetadataCache'
    ];

    function DiarioService(
        $q,
        EventEmitter,
        Restangular,
        MetadataCache
    ) {

        var self = new EventEmitter();
        self.getPeriodos = getPeriodos;
        self.saveFaltas = saveFaltas;
        self.remove = remove;
        self.getTotaisEnturmacao = getTotaisEnturmacao;
        self.metadata = MetadataCache.create(getEndpoint);
        self.getSituacaoMatricula = self.metadata.enumProxyFunction('SituacaoMatricula');
        
        return self;

        function getEndpoint(idTurma){
            return Restangular.allUrl('matricula/turma/' + (idTurma || '*') + '/frequencias/registros-faltas');
        }

        function getPeriodos(diario) {
            var deferred = $q.defer();
            deferred.resolve(diario.configuracao.periodosAvaliativos);
            return deferred.promise;
        }

        function saveFaltas(idTurma, falta) {
            delete falta.somenteLeitura;
            return !falta.id ?
                Restangular.one('matricula/turma', idTurma).all('frequencias/registros-faltas').post(falta) :
                Restangular.one('matricula/turma', idTurma).one('frequencias/registros-faltas').customPUT(falta, falta.id);
        }

        function remove(idTurma, id) {
            return Restangular.one('matricula/turma', idTurma).one('frequencias/registros-faltas', id).remove();
        }

        function getTotaisEnturmacao(idTurma, idEnturmacao) {
            return Restangular.one('matricula/turma', idTurma).one('frequencias/registros-faltas/totais-enturmacao', idEnturmacao).getList();
        }

    }
})();

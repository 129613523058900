(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appHeaderFilter', appHeaderFilter);

    function appHeaderFilter() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/app-header-filter/app-header-filter.directive.html',
            scope: {},
            bindToController: {
                options: '=',
                ngDisabled: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.onClickOption = onClickOption;

        init();

        function init() {
            if (_.isEmpty(vm.options)) {
                return;
            }

            // inicializa com a primeira opção
            onClickOption(_.first(vm.options));
        }

        function onClickOption(option) {
            if (option.$$active || vm.ngDisabled) {
                return;
            }

            disableAllOptions();
            option.$$active = true;

            if (_.isFunction(option.onClick)) {
                option.onClick();
            }
        }

        function disableAllOptions() {
            _.forEach(vm.options, disableOption);
        }

        function disableOption(option) {
            option.$$active = false;
        }
    }

})();

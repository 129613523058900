(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.VisaoEstabelecimentoUtilsService', VisaoEstabelecimentoUtilsService);

    VisaoEstabelecimentoUtilsService.$inject = [
        'EdEnumsCacheService'
    ];

    function VisaoEstabelecimentoUtilsService(EdEnumsCacheService) {
        var vm = this;

        vm.getComponenteCurricularSubTitle = getComponenteCurricularSubTitle;

        init();

        function init() {
            EdEnumsCacheService.get('NivelModalidade').then(function(result) {
                vm.NIVEL_MODALIDADE = result;
            });
            EdEnumsCacheService.get('NivelEscolar').then(function(result) {
                vm.NIVEL_ESCOLAR = result;
            });
        }

        function getComponenteCurricularSubTitle(matriz) {
            if(!matriz) {
                return;
            }

            if(matriz.nivelModalidade === vm.NIVEL_MODALIDADE.COMPLEMENTAR.key) {
                return vm.NIVEL_MODALIDADE[matriz.nivelModalidade].descricao + ' | ' + matriz.descricao;
            }

            return vm.NIVEL_MODALIDADE[matriz.nivelModalidade].descricao + ' | ' + vm.NIVEL_ESCOLAR[matriz.nivelEscolar].descricao + ' | ' + matriz.descricao;
        }
    }

})();


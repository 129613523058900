(function() {

    'use strict';

    angular.module('educacao.matricula').controller('educacao.matricula.RemanejamentoCtrl', RemanejamentoCtrl);

    RemanejamentoCtrl.$inject = ['$injector', '$scope', '$modalInstance', '$q', 'educacao.matricula.ConfiguracoesMatriculaService'];

    function RemanejamentoCtrl($injector, $scope, $modalInstance, $q, ConfiguracoesMatriculaService) {

        var vm = this;

        var $commons = $injector.get('educacao.common.$commons');
        var ContextoEstabelecimento = $injector.get('educacao.context.ContextoEstabelecimento');
        var TurmaService = $injector.get('educacao.matricula.TurmaService');
        var EnturmacaoService = $injector.get('educacao.matricula.EnturmacaoService');
        var MatriculaService = $injector.get('educacao.matricula.MatriculaService');

        var templateVagas = _.template(
            '<%= descricao%> (<%= quantidadeAlunos %><%= quantidadeMaximaAlunos?"/":"" %><%= quantidadeMaximaAlunos %> alunos)');

        vm.hasContextoEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.tiposAtendimento = TurmaService.getTipoAtendimento();
        vm.turnos = TurmaService.getTurno();

        vm.filtro = (!$modalInstance.params.enturmacao) ? {} : getFiltroFromMatricula($modalInstance.params.enturmacao);
        vm.remanejamento = (!$modalInstance.params.enturmacao) ? {} : {
            enturmacoes: [$modalInstance.params.enturmacao]
        };
        vm.isComplementar = vm.filtro.tipoAtendimento === 'ATIVIDADE_COMPLEMENTAR';
        vm.isAee = vm.filtro.tipoAtendimento === 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO';

        vm.tipo = 'REMANEJAMENTO_INTERNO';
        vm.tracker = {
            modal: $commons.$promiseTracker(),
            disciplina: $commons.$promiseTracker(),
            loadingConfigMultiturno : $commons.$promiseTracker(),
            loadingConfig : $commons.$promiseTracker()
        };
        vm.descricaoTurma = descricaoTurma;
        vm.descricaoTurmaWithMatriz = descricaoTurmaWithMatriz;
        vm.isMatrizEjaModular = isMatrizEjaModular;

        vm.remanejar = remanejar;

        vm.permiteMultiturno = false;
        vm.showMultiturno = showMultiturno;
        vm.useConfigNewMatricula = false;

        activate();

        $scope.$watchGroup(['vm.filtro.estabelecimento', 'vm.filtro.tipoAtendimento'], function(newValue, oldValue) {
            if (_.isEqual(_.cloneDeep(newValue).sort(), _.cloneDeep(oldValue).sort())) {
                return;
            }
            delete vm.filtro.matrizCurricular;
            delete vm.filtro.atividade;
            delete vm.filtro.turno;

            delete vm.remanejamento.turmaDestino;
            delete vm.remanejamento.enturmacoes;

            vm.isComplementar = newValue[1] === 'ATIVIDADE_COMPLEMENTAR';
            vm.isAee = newValue[1] === 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO';
        });

        $scope.$watch('vm.filtro.matrizCurricular', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            showMultiturno();
            setUseConfigNewMatricula();
            delete vm.filtro.etapaMatriz;
        });

        $scope.$watch('vm.filtro.turma', watchTurma);

        function watchTurma(newValue, oldValue) {
            if (angular.equals(newValue, oldValue) || _.isEmpty(newValue)) {
                return;
            }
            delete vm.remanejamento.turmaDestino;
            delete vm.remanejamento.enturmacoes;
            delete vm.filtro.disciplina;
            searchFilhosTurma(newValue);
        }

        function searchFilhosTurma(turma) {
            var turmaId = turma ? turma.id : -1;
            vm.tracker.disciplina.addPromise(
                TurmaService.getDiciplinas(turmaId).then(function(data) {
                    vm.filhosTurma = data;
                    return data;
                })
            );
        }

        $scope.$watch('vm.filtro.disciplina', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            delete vm.remanejamento.enturmacoes;
            delete vm.remanejamento.turmaDestino;
        });

        $scope.$watch('vm.remanejamento.turmaDestino', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            delete vm.remanejamento.turnoFrequenta;

        });

        $scope.$watchGroup(['vm.filtro.etapaMatriz', 'vm.filtro.turno', 'vm.filtro.atividade'], function(newValue, oldValue) {
            if (_.isEqual(newValue.sort(), oldValue.sort())) {
                return;
            }
            delete vm.filtro.turma;

            if (vm.isComplementar || vm.isAee) {
                vm.isTurmaDisabled = !newValue || !oldValue;
            } else {
                vm.isTurmaDisabled = !newValue || !oldValue;
            }
        });

        function activate() {
            if (vm.hasContextoEstabelecimento) {
                vm.filtro.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
            }
            setUseConfigNewMatricula();
			showMultiturno();
        }

        function remanejar(addNew) {

            vm.remanejamento.turnoFrequenta = _.get(vm.remanejamento.turnoFrequenta, 'turno', vm.remanejamento.turnoFrequenta);

            var promise = EnturmacaoService.remanejar(vm.remanejamento)
                .then(emitDirty)
                .then(function() {
                    $commons.$notification.publish('Remanejamento efetuado com sucesso!', 'success');
                    if (addNew) {
                        vm.remanejamento = {};
                        $scope.form.$setPristine();
                        $scope.form.$setUntouched();
                    } else {
                        $modalInstance.close();
                    }
                });
            vm.tracker.modal.addPromise(promise);
        }

        function emitDirty() {
            TurmaService.emit('dirty');
            MatriculaService.emit('dirty');
        }

        function descricaoTurmaWithMatriz(data) {
            return descricaoTurma(data);
        }

        function descricaoTurma(data) {
            return isMatrizEjaModular() || _.get(data, 'multiturno') ? data.descricao : getDescricaoTurma(data);
        }

        function getDescricaoTurma(data) {
            return templateVagas(data);
        }

        function isMatrizEjaModular() {
            return Boolean(vm.filtro && vm.filtro.matrizCurricular && vm.filtro.matrizCurricular.modular);
        }

        function getFiltroFromMatricula(enturmacao) {

            return {
                estabelecimento: enturmacao.turma.estabelecimento,
                tipoAtendimento: enturmacao.turma.tipoAtendimento,
                matrizCurricular: enturmacao.turma.matriz,
                atividade: enturmacao.matricula.atividade,
                etapaMatriz: $modalInstance.params.etapaMatriz,
                turno: enturmacao.turma.turno,
                turma: enturmacao.turma
            };

        }

        function showMultiturno() {
            if (!_.get(vm.filtro.matrizCurricular, 'id')) {
                return;
            }
            var params = {
                idEstabelecimento: vm.filtro.estabelecimento.id,
                idMatriz: vm.filtro.matrizCurricular.id
            };

            vm.tracker.loadingConfigMultiturno.addPromise(
                ConfiguracoesMatriculaService.permiteMultiturno(params)
                .then(permiteMultiturno)
            );

            function permiteMultiturno(data) {
                vm.permiteMultiturno = data.permite;
            }

        }

        function setUseConfigNewMatricula() {
            var curso = _.get(vm.filtro, 'matrizCurricular.curso');
            if (!curso) {
                vm.useConfigNewMatricula = false;
                return;
            }
            var params = {
                idEstabelecimento: vm.filtro.estabelecimento.id,
                idMatriz: vm.filtro.matrizCurricular.id
            };
            var promise = ConfiguracoesMatriculaService.remanejamento(params).then(function(data) {
                    vm.useConfigNewMatricula = data.criarNovaMatriculaRemanejamento;
                });
            vm.tracker.loadingConfig.addPromise(promise);
        }

    }
})();

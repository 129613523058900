(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appCargaTemplate', appCargaTemplate);

    function appCargaTemplate() {
        var diretiva = {
            restrict: 'E',
            scope: {},
            templateUrl: 'common/directives/carga-template/app-carga-template.directive.html',
            controllerAs: 'vm',
            controller: AppCargaTemplate,
            bindToController: {
                agregate: '=',
                label: '='
            }
        };

        AppCargaTemplate.$inject = [
            '$scope',
            'promiseTracker',
            'educacao.common.TemplateService',
            'bfc.Notification'
        ];

        function AppCargaTemplate($scope, promiseTracker, TemplateService, Notification) {
            var vm = this;
            vm.habilitaCargaTemplate = false;
            vm.iniciarCargaTemplates = iniciarCargaTemplates;
            vm.disabled = false;

            function iniciarCargaTemplates() {
                vm.disabled = true;
                TemplateService.cargaTemplate(vm.agregate).then(onCompleted);
            }

            function onCompleted() {
                Notification.publish('Processo de carga inicial realizada com sucesso.', 'info');
                vm.habilitaCargaTemplate = !vm.habilitaCargaTemplate;
                vm.disabled = true;
            }

            $scope.$on('configuracao::carga-template', function() {
                vm.habilitaCargaTemplate = !vm.habilitaCargaTemplate;
            });
        }

        return diretiva;
    }

})();

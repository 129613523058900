(function () {
    'use strict';

    angular.module('educacao.planejamento')
        .controller('educacao.planejamento.AnoLetivoCtrl', AnoLetivoCtrl);

    AnoLetivoCtrl.$inject = [
        'promiseTracker',
        'educacao.common.AnoLetivoService',
        'ui.components.Modal',
        'bfc.Notification',
    ];

    function AnoLetivoCtrl(promiseTracker, AnoLetivoService, uiModal, bfcNotification) {
        var vm = this;

        vm.tracker = {};
        vm.tracker.list = promiseTracker();

        vm.anoLetivoService = AnoLetivoService;

        vm.abreCadastro = abreCadastro;
        vm.remove = remove;

        function abreCadastro(anoLetivo) {
            uiModal.open({
                templateUrl: 'planejamento/ano-letivo/ano-letivo.cad.html',
                controller: 'educacao.planejamento.AnoLetivoCadCtrl as vm',
                params: {
                    anoLetivo: anoLetivo && AnoLetivoService.get(anoLetivo.id)
                }
            });
        }

        function remove(anoLetivo) {
            return AnoLetivoService.remove(anoLetivo).then(function(){
                bfcNotification.publish('Ano letivo removido com sucesso.', 'success');
            });
        }
    }
})();

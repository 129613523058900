(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .service('educacao.pessoas.edModalEquipamentosService', ModalEquipamentoService);

    ModalEquipamentoService.$inject = ['$q', 'ui.components.Modal'];

    function ModalEquipamentoService($q, $modal) {
        return {
            open: openCad
        };

        function openCad(estabelecimentoEquipamentos) {
            var eventEmitter = new EventEmitter();

            $modal.open({
                templateUrl: 'planejamento/escolas/equipamentos-quantidades/ed-equipamento-quantidade-modal.html',
                controller: 'educacao.pessoas.EquipamentoCadCtrl',
                controllerAs: 'vm',

                params: {
                    eventEmitter: eventEmitter,
                    estabelecimentoEquipamentos: $q.when(estabelecimentoEquipamentos)
                }
            });
            return eventEmitter;
        }
    }

    function EventEmitter() {
        var self = this;
        self.handlers = {};

        self.on = on;
        self.emit = emit;

        //shorthands
        self.onSave = onSave;
        self.onRemove = onRemove;
        self.onClose = onClose;

        function on(eventName, handler) {
            if (!self.handlers[eventName]) {
                self.handlers[eventName] = [];
            }
            self.handlers[eventName].push(handler);
            return self;
        }

        function emit() {
            var eventName = arguments[0];
            var params = _.rest(arguments);

            var eventHandlers = self.handlers[eventName] || [];

            eventHandlers.forEach(function(handler) {
                handler.apply(this, params);
            });
            return self;
        }

        //shorthands
        function onSave(handler) {
            return self.on('save', handler);
        }

        function onRemove(handler) {
            return self.on('remove', handler);
        }

        function onClose(handler) {
            return self.on('close', handler);
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.HorasAulasCtrl', HorasAulasCtrl);

	HorasAulasCtrl.$inject = [
		'educacao.matricula.HorasAulaService',
		'educacao.common.ModalCad',
		'ui.components.Modal'

	];

	function HorasAulasCtrl(HorasAulaService, ModalCad, $modal) {
		var vm = this;

		vm.openCad = openCad;
		vm.remove = remove;
		vm.openTableHorasAulas = openTableHorasAulas;
		vm.horasAulaService = HorasAulaService;

		function openCad(id) {
			ModalCad.open({
				templateUrl: 'matricula/horas-aula/horas-aulas.cad.html',
				controller: 'educacao.matricula.HorasAulasCadCtrl as vm',
				id: id,
				readOnly: false
			});
		}

		function openTableHorasAulas(id) {
			$modal.open({
				templateUrl: 'common/parametros/configuracao-turmas/configuracao.turmas.modal.template.html',
				controller: 'educacao.common.ConfiguracoesModalVisualizacaoHorasAulasCtrl as vm',
				size: 'lg',
				params: {
					configuracaoHorasAulas: HorasAulaService.get(id),
					exibeEdicao: false
				}
			});
		}

		function remove(data) {
			HorasAulaService.remove(data);
		}
	}

})();

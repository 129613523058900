(function() {

    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectTurmaAtividade', SelectTurmaAtividadeDirective);

    function SelectTurmaAtividadeDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" format-result="formatResult(data)" bf-permissions="bfPermissions" ng-required="ngRequired" ng-disabled="ngDisabled" search="vm.search"></ui-select>',
            scope: {
                ngModel: '=',
                turmaId: '=',
                bfPermissions: '@?',
                ngRequired: '=?',
                ngDisabled: '=?',
                formatResult: '=?',
                createObject: '=?'
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.matricula.TurmaService'
        ];

    function Controller($scope, TurmaService) {
        var vm = this;

        vm.search = search;

        function search(params) {

            var idTurma = $scope.turmaId || -1;
            var term = decodeURI(params.term).replace(/%/g, ' ').trim();

            return TurmaService.get(idTurma).then(function(data) {
                var atividades = _.map(data.filhos, _.isFunction($scope.createObject) ? $scope.createObject : 'atividade');
                var filtrados = _.filter(atividades, function(item) {
                    return item.descricao.toLowerCase().contains(term.toLowerCase());
                });
                return filtrados;
            }).then(createPage);
        }

        function createPage(list) {
            return {
                content: list,
                hasNext: false
            };
        }
    }

})();


(function () {
	'use strict';

	var CLASSIFICADO_COM_VAGA = 'CLASSIFICADO_COM_VAGA';

	angular.module('educacao.matricula')
		.directive('appClassificacaoListaClassificadosDetails', ClassificacaoListaClassificadosDetails);

	function ClassificacaoListaClassificadosDetails() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/classificacao-inscritos/directives/classificacao-lista-classificados-details.directive.html',
			controller: Controller,
			controllerAs: 'vm',
			scope: {
				idInscricao: '=',
				indeferir: '=',
				processo: '=',
				listaEspera: '=',
				matricular: '=',
				encaminhar: '=',
				visualizarInscricao: '=',
				showUtilizaEncaminhamento: '=',
				chamada: '=',
				candidato: '=',
				inscricao: '=',
				resultadoClassificacaoEncaminhamento: '=',
				inscritosEncaminhamento: '=',
				configuracao: '='
			}
		};
	}

	Controller.$inject = [
		'$scope', 
		'promiseTracker', 
		'educacao.matricula.ClassificacaoService', 
		'bfc.$$PermissionsService', 
		'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
		'Restangular',
		'educacao.matricula.InscricaoMatriculaService',
		'ui.components.Modal',
		'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
		'bfc.Notification',
		'$enumsCache',
		'$rootScope'
	];

	function Controller($scope, promiseTracker, ClassificacaoService, PermissionsService, ConfigListaEsperaService, Restangular, InscricaoMatriculaService, uiModal, InscricaoListaEsperaService, bfcNotification, $enumsCache, $rootScope) {
		var vm = this;
		vm.tracker = promiseTracker();
		vm.trackerLoading = promiseTracker();
		vm.detalhes = [];

		vm.hasVaga = hasVaga;
		vm.encaminhar = encaminhar;
		vm.indeferir = indeferir;
		vm.listaEspera = listaEspera;
		vm.matricular = matricular;
		vm.abreCadastro = abreCadastro; 
		vm.getListaEsperaConfigs = getListaEsperaConfigs;
		vm.onCheckInscrito = onCheckInscrito;

		vm.isEncaminhamentoListaEspera = true;

		vm.hasPermissionToCriarInscricaoListaEspera = !PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'criar');
        vm.hasPermissionToListarListaEspera = !PermissionsService.isRevokedOperation('ConfiguracaoParaListaEsperaPageMapping', 'listar');

		$scope.$watch('inscritosEncaminhamento', disableCheckboxsWithDifferentEstabelecimentoEtapaTurno, true);
		$rootScope.$on('encaminhamentoLoteItemDeleted', uncheckCheckboxOnDeleteEvent);

		init();

		function init() {
			var idProcesso = _.get($scope.processo, 'id');
			if (!$scope.idInscricao || !idProcesso) {
				return;
			}

			var filter = '';
			if ($scope.processo.nivelEscolar === 'EDUCACAO_INFANTIL') {
				filter = 'inscricao=' + $scope.idInscricao;

				return vm.tracker.addPromise(
					ClassificacaoService.getListaResultadoInscritoVaga(idProcesso, _.get($scope.chamada, 'id'), filter)
						.then(onInit)
				);
			}

			filter = 'inscricao=' + $scope.idInscricao;

			return vm.tracker.addPromise(
				ClassificacaoService.getListaResultadoInscritoEquivalencia(idProcesso, _.get($scope.chamada, 'id'), filter)
					.then(onInit)
			);
		}

		function onInit(data) {
			vm.tracker.addPromise(
				InscricaoMatriculaService.get($scope.idInscricao).then(function (inscricao) {
					vm.detalhes = _.get(data, 'content');

					vm.estabelecimentosInscricao = inscricao.estabelecimentos;
					vm.enderecoInscricao = inscricao.dados.enderecoResidencia;

					getTurnoEnum();
					setZoneamentoAndOrdemPreferencia();
					disableCheckboxsWithDifferentEstabelecimentoEtapaTurno($scope.inscritosEncaminhamento);
					checkClassificacaoOnInit();
				})
			);
		}

		function getTurnoEnum() {
			$enumsCache.get('Turno').then(function (data) {
				vm.turnos = data;
			});
		}

		function hasVaga(item) {
			return Boolean(_.get(item, 'resultadoChamada.status') === CLASSIFICADO_COM_VAGA);
		}

		function encaminhar(item) {
			$scope.encaminhar(getObject(item));
		}

		function indeferir(item) {
			$scope.indeferir(getObject(item));
		}

		function listaEspera(item) {
			$scope.listaEspera(getObject(item));
		}

		function matricular(item) {
			$scope.matricular(getObject(item));
		}

		function getObject(item) {
			return {
				id: _.get(item.resultado, 'id'),
				candidato: $scope.candidato,
				classificacaoInscricao: {
					id: $scope.idInscricao,
					candidato: $scope.candidato,
					configuracaoInscricao: _.pick($scope.processo, 'id'),
					estabelecimento: _.get(item, 'classificacao.estabelecimento'),
					etapa: _.get(item, 'classificacao.etapa'),
					turno: _.get(item, 'classificacao.turno'),
					chamada: _.pick($scope.chamada, 'id'),
					resultado: _.get(item, 'resultado'),
					resultadoChamada: _.get(item, 'resultadoChamada')
				}
			};
		}

		function getListaEsperaConfigs() {
			vm.trackerLoading.addPromise(
				ConfigListaEsperaService.listConfiguracaoByNivelEscolar($scope.processo.nivelEscolar).then(
					function (listas) {
						vm.listasEspera = listas;
					}
				)
			);
		}

		function abreCadastro(configuracaoId, configuracaoDescricao) {
			vm.trackerLoading.addPromise(InscricaoMatriculaService.get($scope.inscricao.id)
				.then(function (inscritoData) {
					getInscritos(configuracaoId)
						.then(function () {
							if (validateInscricaoDuplicada(inscritoData.dados)) {
								bfcNotification.publish('Já existe uma inscrição na lista de espera para o(a) candidato(a) ' + inscritoData.dados.nome + ' na configuração "' + configuracaoDescricao +'" com o mesmo nome, data de nascimento, sexo e filiação.', 'danger');
								return;
							}
				
							openUiModal(inscritoData, configuracaoId);
						});
				}
			));
        }

		function getInscritos(configuracaoId) {
            return InscricaoListaEsperaService.getInscritosListaEspera(configuracaoId)
                .then(function (data) {
                    vm.inscritosConfig = data.content;
                });
        }

		function openUiModal(inscritoData, configuracaoId) {
			var data = _.cloneDeep(inscritoData);
			uiModal.open({
				templateUrl: 'lista-espera-new/inscricao/cadastro-inscricao-lista-espera.html',
				controller: 'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaCadCtrl',
				controllerAs: 'vm',
				size: 'xxl',
				params: {
					configuracao: configuracaoId,
					inscricaoListaEspera: data.dados,
					observacao: data.observacao,
					isCopiaAlunoMatriculado: true,
					isAlunoInscricaoMatricula: true
				}
			});
		}

		function validateInscricaoDuplicada(inscricao) {
            var inscrito = _.find(vm.inscritosConfig, { 
                nome: inscricao.nome,
                sexo: inscricao.sexo,
                dataNascimento: inscricao.dataNascimento,
            });

            if (!inscrito) {
                return false;
            }

            var inscritoFiliacoesNames = _.map(inscrito.filiacoes, 'nome');
            return inscricao.filiacoes.some(function (filiacao) { 
                return inscritoFiliacoesNames.includes(filiacao.nome); 
            });
        }

		function onCheckInscrito(item) {
			item.disabled = false;
			disableCheckboxsWithDifferentClassificacao(item);
			$scope.resultadoClassificacaoEncaminhamento = _.map(getInscritosSelected(), getObject);
		}

		function getInscritosSelected() {
			return _.filter(vm.detalhes, function (item) {
				return item.checked && !item.disabled;
			});
		}

		function disableCheckboxsWithDifferentClassificacao(item) {
            _.forEach(vm.detalhes, function (classificacaoInscricao) {
                if (classificacaoInscricao.classificacao.id !== item.classificacao.id) {
                    classificacaoInscricao.checked = false;
                    classificacaoInscricao.disabled = item.checked;
                }
            });
        }

		function disableCheckboxsWithDifferentEstabelecimentoEtapaTurno(inscritosEncaminhamento) {
			if (!inscritosEncaminhamento || _.isEmpty(vm.detalhes)) {
				return;
			}
		
			var inscritos = getInscritosFromEncaminhamento(inscritosEncaminhamento);
		
			if (_.isEmpty(inscritos)) {
				_.forEach(vm.detalhes, function (classificacaoInscricao) {
					classificacaoInscricao.checked = false;
					classificacaoInscricao.disabled = false;
				});
				return;
			}
		
			setDisableCheckbox(inscritos);
		}

		function setDisableCheckbox(inscritos) {
			var firstInscrito = _.first(inscritos);
			var selectedEtapa = firstInscrito.classificacaoInscricao.etapa;
			var selectedTurno = firstInscrito.classificacaoInscricao.turno;
			var selectedEstabelecimento = firstInscrito.classificacaoInscricao.estabelecimento.id;
		
			_.forEach(vm.detalhes, function(classificacaoInscricao) {
				var classificacao = classificacaoInscricao.classificacao;
				if (
					classificacao.turno !== selectedTurno || 
					classificacao.etapa !== selectedEtapa || 
					classificacao.estabelecimento.id !== selectedEstabelecimento
				) {
					classificacaoInscricao.checked = false;
					classificacaoInscricao.disabled = true;
					return;
				}

				classificacaoInscricao.disabled = false;
			});
		}

		function uncheckCheckboxOnDeleteEvent(event, args) {
			var candidatosEncaminhadosIds = _.map(args, function (data) {
				return data.candidato.id;
			});

			if (!candidatosEncaminhadosIds.includes($scope.candidato.id)) {
				_.forEach(vm.detalhes, function (resultadoClassificacao) {
					resultadoClassificacao.checked = false;
					resultadoClassificacao.disabled = false;
				});
			}
		}

		function checkClassificacaoOnInit() {
			var classificacaoInscritosIds = _.map(getInscritosFromEncaminhamento($scope.inscritosEncaminhamento), 'id');

			_.forEach(vm.detalhes, function(classificacaoInscrito) {
				if (classificacaoInscritosIds.includes(classificacaoInscrito.resultado.id)) {
					classificacaoInscrito.checked = true;
				}
			});
		}

		function getInscritosFromEncaminhamento(inscritosEncaminhamento) {
			var inscritos = [];

			_.forEach(inscritosEncaminhamento, function(inscrito) {
				if (!_.isEmpty(inscrito)) {
					inscritos = inscritos.concat(inscrito);
				}
			});

			return inscritos;
		}

		function setZoneamentoAndOrdemPreferencia() {
			_.forEach(vm.detalhes, function (item) {
				setOrdemPreferencia(item.classificacao);
			});

			orderByPreferencia();
		} 

		function setOrdemPreferencia(classificacao) {
			var estabelecimentoInscricao = _.find(vm.estabelecimentosInscricao, { 
				'estabelecimento': {
					'id': classificacao.estabelecimento.id 
				},
				'turno': classificacao.turno
			});

			if (!estabelecimentoInscricao) {
				classificacao.estabelecimento.ordemPreferencia = null;
				setZoneamento(classificacao);
				return;
			}

			classificacao.estabelecimento.mesmoZoneamento = estabelecimentoInscricao.mesmoZoneamento;
			classificacao.estabelecimento.ordemPreferencia = estabelecimentoInscricao.ordemPreferencia;
		}

        function orderByPreferencia() {
            if (!vm.detalhes || _.isEmpty(vm.detalhes)) {
                return [];
            }

            if (!$scope.configuracao.utilizaZoneamento) {
                return vm.detalhes.sort(sortByOrdemPreferencia);
            }

            vm.detalhes.sort(function (a, b) {
                if (a.classificacao.estabelecimento.mesmoZoneamento === b.classificacao.estabelecimento.mesmoZoneamento) {
                    return sortByOrdemPreferencia(a, b);
                }

                return a.classificacao.estabelecimento.mesmoZoneamento ? -1 : 1;
            });
        }

		function sortByOrdemPreferencia(a, b) {
            if (a.classificacao.estabelecimento.ordemPreferencia === null) { return 1; }
            if (b.classificacao.estabelecimento.ordemPreferencia === null) { return -1; }
            return a.classificacao.estabelecimento.ordemPreferencia - b.classificacao.estabelecimento.ordemPreferencia;
        }

		function setZoneamento(classificacao) {
			var enderecoEstab = classificacao.estabelecimento.endereco;
		
			if (!enderecoEstab || !vm.enderecoInscricao) {
				classificacao.estabelecimento.mesmoZoneamento = false;
				return;
			}
		
			var zoneamentoInscricao = vm.enderecoInscricao.bairroEndereco.regiao;
			var idRegiaoEstab = enderecoEstab.regiao;

			if (!idRegiaoEstab || !zoneamentoInscricao) {
				classificacao.estabelecimento.mesmoZoneamento = false;
				return;
			}

			classificacao.estabelecimento.mesmoZoneamento = idRegiaoEstab === zoneamentoInscricao.id;
		}
	}

})();

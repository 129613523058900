(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService', QuadroFuncionariosVisaoEstabelecimentoService);

    QuadroFuncionariosVisaoEstabelecimentoService.$inject = [
        'ServiceBuilder',
        'Restangular',
        '$q'
    ];

    function QuadroFuncionariosVisaoEstabelecimentoService(ServiceBuilder, Restangular) {
        var baseUrl = 'visao-estabelecimento-quadro-informativo';
        var matrizesUrl = 'matriz/matriz-curricular';
        var estabelecimentoUrl = 'pessoa/estabelecimento';

        var service = ServiceBuilder.create()
                        .path(baseUrl)
                        .build();
                        
        service.getSaldosDisciplinasByTurma = getSaldosDisciplinasByTurma;                
        service.getMatrizesSaldosParciaisByEstabelecimentoId = getMatrizesSaldosParciaisByEstabelecimentoId;
        service.getSaldosTurmasByMatrizCurricular = getSaldosTurmasByMatrizCurricular;
        service.getComponentesCurriculares = getComponentesCurriculares;
        service.getComponentesCurricularesPorNome = getComponentesCurricularesPorNome;
        service.getSaldosParciaisPorComponenteCurricular = getSaldosParciaisPorComponenteCurricular;
        service.getMatrizes = getMatrizes;
        service.getMatrizesByTurma = getMatrizesByTurma;
        service.getTurmasPorEstabelecimento = getTurmasPorEstabelecimento;
        service.getOne = getOne;
        service.getMatrizes = getMatrizes;
        service.getComponentesCurriculares = getComponentesCurriculares;
        service.getDisciplinasPorConfigQuadroFuncGrupo = getDisciplinasPorConfigQuadroFuncGrupo;
        service.isEtapaUsingFormatacaoGrupo = isEtapaUsingFormatacaoGrupo;
        service.getEstabelecimentoById = getEstabelecimentoById;

        function getSaldosTurmasByMatrizCurricular(params) {
            return Restangular
                .all(baseUrl)
                .one('matriz')
                .get(params)
                .then(strip);
        }
        
        function getSaldosDisciplinasByTurma(turmaId, params) {
            return Restangular
                .all(baseUrl)
                .one('turma', turmaId)
                .get(params)
                .then(strip);
        }

        function getMatrizes(estabelecimentoId, params) {
            return Restangular
                .all(matrizesUrl)
                .one('matrizes-visao-estabelecimento', estabelecimentoId)
                .get(params)
                .then(strip);
        }

        function getOne(id) {
            return Restangular
                .one(baseUrl, id)
                .get()
                .then(strip);
        }

        function getMatrizesByTurma(estabelecimentoId, params) {
            return Restangular
                .all(baseUrl)
                .one('matrizes-por-turma', estabelecimentoId)
                .get(params)
                .then(strip);
        }

        function getTurmasPorEstabelecimento(estabelecimentoId, params) {
            return Restangular
                .all(baseUrl)
                .one('turmas-estabelecimento', estabelecimentoId)
                .get(params)
                .then(strip);
        }

        function getMatrizesSaldosParciaisByEstabelecimentoId(estabelecimentoId, params) {
            return Restangular
                .all(baseUrl)
                .one('saldos-parciais', estabelecimentoId)
                .get(params)
                .then(strip);
        }

        function getComponentesCurricularesPorNome(params, nomeComponente) {
            return Restangular
                .all(baseUrl)
                .one('componentes-curriculares-saldos-parciais', nomeComponente)
                .get(params)
                .then(strip);
        }
        
        function getComponentesCurriculares(params) {
            return Restangular
                .all(baseUrl)
                .one('componentes-curriculares-saldos-parciais')
                .get(params)
                .then(strip);
        }
        
        function getSaldosParciaisPorComponenteCurricular(idComponente) {
            return Restangular
                .all(baseUrl)
                .one('saldos-parciais-por-componente-curricular', idComponente)
                .get()
                .then(strip);
        }

        function getDisciplinasPorConfigQuadroFuncGrupo(idTurma) {
            return Restangular
                .all(baseUrl)
                .one('disciplinas-por-config-quadro-func-grupo', idTurma)
                .get()
                .then(strip);
        }

        function isEtapaUsingFormatacaoGrupo(idEtapaMatriz, params) {
            return Restangular
                .all(baseUrl)
                .one('etapa-utiliza-formatacao-grupo', idEtapaMatriz)
                .get(params)
                .then(strip);
        }

        function getEstabelecimentoById(idEstabelecimento) {
            return Restangular
                .one(estabelecimentoUrl, idEstabelecimento)
                .get()
                .then(strip);
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }

        return service;
    }

})();
(function () {
    'use strict';
    angular.module('app')
        .config(edErrorInterceptorConfig);

    edErrorInterceptorConfig.$inject = ['$edErrorInterceptorProvider', 'REGEX_PATH_ATENDIMENTO','REGEX_PATH_EXECUTAR_RELATORIO'];

    function edErrorInterceptorConfig($edErrorInterceptorProvider,REGEX_PATH_ATENDIMENTO, REGEX_PATH_EXECUTAR_RELATORIO) {
        var regexAtendimento = new RegExp(REGEX_PATH_ATENDIMENTO, 'g');
        var regexExecutarRelatorio = new RegExp(REGEX_PATH_EXECUTAR_RELATORIO, 'g');
        $edErrorInterceptorProvider.ignoreUrlPatterns([regexAtendimento,regexExecutarRelatorio]);
    }
})();

(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.PaisController', PaisController);

    PaisController.$inject = [
        '$scope',
        'educacao.common.$commons',
        'educacao.enderecos.PaisService',
    ];

    function PaisController($scope, $commons, PaisService) {
        var vm = this;

        vm.PaisService = PaisService;
        vm.search = search;
        vm.columns = getColumns();

        vm.filter = {};
        vm.tracker = {};
        vm.listPadraoControls = {};

        vm.tracker.loading = $commons.$promiseTracker();

        vm.listPadraoCustomBuilder = {
            onBeforeBuild: function () {
                return 'nome is not null';
            }
        };

        function search() {
            vm.listPadraoControls.search();
        }

        function getColumns() {
            return [
                {
                    id: 'nome',
                    model: 'nome',
                    label: 'Descrição',
                    filterable: true
                }
            ];
        }
    }
})();
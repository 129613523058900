(function () {
    'use strict';

    angular
        .module('educacao.quadro-informativo-utils')
        .directive('appSelectDisponibilidadeGestaoPessoas', appSelectDisponibilidadeGestaoPessoas);

    function appSelectDisponibilidadeGestaoPessoas() {

        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" placeholder="placeholder" ngclass="ngclass" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search"/>',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                ngclass: '=',
                placeholder: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '='
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService', '$q'];

    function Controller($scope, Service, $q) {
        var vm = this;
        vm.search = search;
        vm.formatResult = formatResult;
        vm.DISPONIBILIDADES_AULAS = [
            {id: 1, key: 'TODAS', descricao: 'Todas', filter: '(aulasVagas >= 0 and aulas >= 0)'},
            {id: 2, key: 'VAGAS', descricao: 'Vagas', filter: '(aulasVagas > 0 and aulas > 0)'},
            {id: 3, key: 'OCUPADAS', descricao: 'Ocupadas', filter: '(aulasVagas = 0 and aulas > 0)'}
        ];

        function search() {
            var defer = $q.defer();
            var data = {
                content: vm.DISPONIBILIDADES_AULAS
            };
            defer.resolve(data);
            return defer.promise;
        }

        function formatResult(data) {
            return data.descricao;
        }
    }
})();
(function () {
    'use strict';


    /**
    * @name ngAria
    * @description
    * @param matriz
    * @param etapa
    * @param item 
    * @param ngModel 
    * @param ngRequired 
    * @param ngDisabled
    * @param dropdownCssClass
    * o item é usado quando dentro de uma lista precisa que uma ação seja feita no change
    
	 */

    angular.module('educacao.matriz').directive('appSelectEtapaMatrizDisciplina', SelectEtapaMatrizDisciplinaDirective);

    function SelectEtapaMatrizDisciplinaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" search="vm.searchDisciplinas" format-result="vm.getDescricao(data)" ng-disabled="ngDisabled"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                matriz: '=?',
                etapa: '=?',
                ngChange: '=?',
                item: '=',
                dropdownCssClass: '=?'
            },
            controller: SelectEtapaMatrizDisciplinaDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectEtapaMatrizDisciplinaDirectiveController.$inject = ['$scope', 'educacao.matriz.EtapaDisciplinaService'];

    function SelectEtapaMatrizDisciplinaDirectiveController($scope, service) {

        var vm = this;

        vm.searchDisciplinas = searchDisciplinas;
        vm.getDescricao = getDescricao;

        $scope.$watch('ngModel', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange(newValue, oldValue, $scope.item);
            }
        });

        function toPage(list) {
            return { content: list, hasNext: false };
        }

        function getDescricao(etapaMatrizDisciplina) {
            return etapaMatrizDisciplina.disciplina.descricao;
        }

        function searchDisciplinas(params) {
            var filter = 'descricaoDisciplina like "' + params.term + '"';
            var idMatriz = _.get($scope.matriz, 'id') || -1;
            var idEtapa = _.get($scope.etapa, 'id') || -1;

            return service.getList(idMatriz, idEtapa,filter)
                .then(toPage);
        }
    }
})();

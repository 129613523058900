(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncaoGratificadaSelect', TipoCargoSelect);

    TipoCargoSelect.$inject = ['educacao.common.ModalCad',
        'educacao.common.ObjectUtilsService',
        'educacao.funcionario.FuncaoGratificadaService',
        'bfc.Select2Config'
    ];

    function TipoCargoSelect(ModalCad,
        ObjectUtilsService,
        FuncaoGratificadaService,
        Select2Config
    ) {
        return {
            select2Config: function(options) {
                return Select2Config.create(angular.extend({
                    result: function(params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);
                        
                        var result, filter = '(descricao like "' + term + '")';

                        result = FuncaoGratificadaService.list(filter, params.offset, params.limit, '')
                            .then(function(data) {
                                callback({
                                    results: data.content,
                                    more: false
                                });
                            });

                        return result;
                    },
                    formatValue: function(data) {
                        return data;
                    },
                    formatResult: function(data) {
                        return data.descricao;
                    },
                    formatSelection: function(data) {
                        return data.descricao;
                    },
                    initSelection: function(element, callback) {
                        var id = $(element).val();
                        if (id) {
                            FuncaoGratificadaService.get(id).then(callback, function() {
                                callback({
                                    id: null
                                });
                            });
                        }
                    }
                }, options));
            }
        };
    }
})();

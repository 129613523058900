(function() {
    'use strict';

    angular.module('educacao.legislacao')
        .factory('educacao.legislacao.LegislacaoService', Service);

    Service.$inject = ['$injector'];


    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('legislacao')
            .build();

        return selfService;
    }
})();

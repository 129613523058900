(function () {
    'use strict';

    angular.module('educacao.matriz').controller('educacao.matriz.CursosCtrl', CursosCtrl);

    CursosCtrl.$inject = ['educacao.matriz.CursoService', 'promiseTracker', '$scope', '$modalStates'];

    function CursosCtrl(CursoService, promiseTracker, $scope, $modalStates) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.parseNivelModalide = parseNivelModalide;
        vm.parseNivelEscolar = parseNivelEscolar;
        vm.getIconSortClass = getIconSortClass;
        vm.cursoService = CursoService;
        vm.tracker = {};
        vm.tracker.list = promiseTracker();
        vm.listControls = null;
        vm.cursos = [];
        vm.getEnun = getEnun;

        var _select2 = {};

        init();

        function init() {
            vm.getEnunsPromise = CursoService.getEnums().then(function(nivelModalidade) {
                vm.enuns = nivelModalidade;
                return nivelModalidade;
            });

            //vm.listControls.search();
        }
        
        function getEnun (prop,value){
            if ( prop && value ){
                var array = _.get(vm.enuns,prop);
                var result = _.find(array,{'key':value},array);
                return result.description;
            }
        }

        function openCad (curso) {
            $modalStates.goModalState(curso);
        }

        function remove(cursoRemove) {
            CursoService.remove(cursoRemove.id).then(function () {
                vm.listControls.search();
            });
        }

        function parseNivelModalide(key) {
            if (typeof _select2.NivelModalidade !== 'undefined') {
                if (typeof _select2.NivelModalidade[key] !== 'undefined') {
                    return _select2.NivelModalidade[key].description;
                }
            }
        }

        function parseNivelEscolar(key) {
            if (typeof _select2.NivelEscolar !== 'undefined') {
                if (typeof _select2.NivelEscolar[key] !== 'undefined') {
                    return _select2.NivelEscolar[key].description;
                }
            }
        }

        function getIconSortClass(sortBy) {
            return {
                'icon-caret-down': $scope.predicate === sortBy && $scope.reverse,
                'icon-caret-up': $scope.predicate === sortBy && !$scope.reverse,
                'icon-sort': $scope.predicate !== sortBy
            };
        }

    }


})();

(function() {
    'use strict';

    angular.module('educacao.matriz').directive('appSelectDisciplina', SelectDisciplinaDirective);

    function SelectDisciplinaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" not-empty="ngRequired || false" search="vm.searchDisciplina" minimum-results-for-search="0" add-value="vm.add" />',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                disciplinasExistentes: '=',
                ngChange: '&?',
                dropdownCssClass: '=?'
            },
            controller: SelectDisciplinaDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectDisciplinaDirectiveController.$inject = [
        'ui.components.Modal',
        'educacao.matriz.DisciplinaService',
        '$q',
        'educacao.common.ObjectUtilsService',
        '$scope',
        'bfc.$$PermissionsService'
    ];

    function SelectDisciplinaDirectiveController($modal, service, $q, ObjectUtilsService, $scope, PermissionsService) {

        var vm = this;

        vm.searchDisciplina = searchDisciplina;
        vm.add = PermissionsService.isRevokedOperation('DisciplinaPageMapping', 'criar') ? undefined : add;

        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function searchDisciplina(params) {

            var term = ObjectUtilsService.normalizeFilter(params.term),
                disciplinas = [];

            var filter = '(sigla like "' + term + '" or descricao like "' + term + '")';
            if (angular.isArray($scope.disciplinasExistentes) && $scope.disciplinasExistentes.length > 0) {
                _.forEach($scope.disciplinasExistentes, function(value) {
                    if (value.disciplina && value.disciplina.id) {
                        disciplinas.push(value.disciplina.id);
                    }
                });
                if (disciplinas.length > 0) {
                    filter += ' and (id not in (' + disciplinas + '))';
                }
            }
            return service.getList(params.offset, params.limit, filter, undefined)
                .then(function(data) {
                    return {
                        content: data,
                        hasNext: false
                    };

                });
        }

        function add(descricao) {
            
            return $modal.open({
                templateUrl: 'planejamento/matriz/disciplina/disciplinas.cad.html',
                controller: 'educacao.matriz.DisciplinasCadCtrl',
                controllerAs: 'vm',
                params: {
                    disciplina: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                },
                size: 'lg'
            }).result;

        }
    }
})();


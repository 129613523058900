(function() {
    'use strict';



    angular.module('educacao.matricula')
        .controller('educacao.matricula.AeeCadCtrl', AeeCadCtrl);

    AeeCadCtrl.$inject = ['educacao.matricula.AtividadeAEEService',
        'promiseTracker',
        '$rootScope',
        '$modalInstance',
        'bfc.Focus'
    ];

    function AeeCadCtrl(
        AtividadeAEEService,
        promiseTracker,
        $rootScope,
        $modalInstance,
        focus) {

        var vm = this;
        vm.tracker = {
            loading: promiseTracker(),
            loadingAtividade: promiseTracker()
        };
        vm.atividade = $modalInstance.params.atividadeAAEE || {};

        var modo = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.atividade.id
        };

        var properties = {
            save: save,
            cancel: fechaModal,
            mode: modo,
            remove: remove
        };

        init();

        function init() {
            if (_.get(vm.atividade, 'codigoInep')) {
                var promise = AtividadeAEEService.getDefaultByCodInep(vm.atividade.codigoInep).then(setAtividade);
                vm.tracker.loadingAtividade.addPromise(promise);
            }
            verificaFormulario();
            angular.extend(vm, properties);
            vm.windowTitle = modo.adicionando ? 'Adicionando atividade de atendimento educacional especializado (AEE)' : 'Editando atividade de atendimento educacional especializado (AEE)';
            focus('lbDescricaoAAee');
        }

        function setAtividade(atividade) {
            vm.atividadePadraoInep = atividade;
        }

        function save(continuar) {
            vm.atividade.codigoInep = _.get(vm.atividadePadraoInep, 'codigoInep');

            vm.tracker.loading.addPromise(
                AtividadeAEEService.save(vm.atividade).then(function(data) {
                    $rootScope.$broadcast('refresh::atividades', {
                        data: data,
                        add: !vm.atividade.id,
                        edit: Boolean(vm.atividade.id)
                    });
                    onSuccess(continuar,data);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                AtividadeAEEService.remove(vm.atividade.id).then(function() {
                    $rootScope.$broadcast('refresh::atividades', {
                        data: vm.atividade,
                        remove: true
                    });
                    fechaModal();
                })
            );
        }


        function onSuccess(continuar,data) {
            if (continuar) {
                vm.atividade = {};
                delete vm.atividadePadraoInep;
                focus('lbDescricaoAAee');
            } else {
                $modalInstance.close(data);
            }
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function verificaFormulario() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('inputFiliacoes', inputFiliacoes);

    inputFiliacoes.$inject = [];

    function inputFiliacoes() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/filiacoes/input-filiacoes/input/input-filiacoes.directive.html',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                required: '='
            },
            controller: InputFiliacoesCtrl,
            controllerAs: 'vm'
        };
    }

    InputFiliacoesCtrl.$inject = ['$scope', 'ModalFiliacoesService', '$element'];

    function InputFiliacoesCtrl($scope, ModalFiliacoesService, $element) {
        var vm = this;

        vm.click = click;
        vm.formatterTag = formatterTag;

        if (!$scope.ngModel) {
            $scope.ngModel = [];
        }

        function click() {
            ModalFiliacoesService.open($scope.ngModel).result.then(onClose);
        }

        function onClose() {
            $element.find('input').focus();
        }

        function formatterTag(filiacao) {
            return filiacao.filiacao.pessoa.nome;
        }
    }

})();
(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo', [
        'educacao.lista-espera-novo.encaminhado',
        'educacao.lista-espera-novo.configuracao',
        'educacao.lista-espera-novo.inscricao',
        'educacao.lista-espera-novo.anotacoes',
    ]);
})();

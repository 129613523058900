(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('appSelectObjetivosAprendizagem', SelectObjetivosAprendizagem);

	function SelectObjetivosAprendizagem() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add" ng-change="ngChange()"></ui-select>',
			scope: {
				ngModel: '=ngModel',
                ngRequired: '=?',
				ngChange: '&?',
                campoExperiencia: '=',
				lista: '='
			},
			controller: Controller,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
		};
	}

	Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.matriz.ObjetivoAprendizagemService'];

	function Controller($scope, $modal, service) {

		var vm = this;

		vm.search = search;
		vm.add = add;

		$scope.$watch('ngModel', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            if (_.isFunction($scope.ngChange)) {
				$scope.ngChange();
            }
        });

		function search(params) {
			var filter = '(descricao like "' + params.term + '"';

			if ($scope.multiple) {
				filter += ignoreId($scope.ngModel);
			}
			if ($scope.lista) {
				filter += ignoreId($scope.lista);
            }
            if($scope.campoExperiencia){
                filter += ' and campoExperiencia = ' + $scope.campoExperiencia.id;
            }

			filter += ')';
			return service.list(filter, params.offset, params.limit);
		}

		function ignoreId(list) {
			var filter = '';
			var tiposDocumentos = [];
			if (list) {
				list.forEach(function(documento) {
					if (_.get(documento, 'id')) {
						tiposDocumentos.push(documento.id);
					}
				});
			}
			if (tiposDocumentos.length) {
				filter += ' and id not in(' + tiposDocumentos.join() + ')';
			}
			return filter;
		}

		function add(descricao) {
			return $modal.open({
				templateUrl: 'planejamento/matriz/objetivos-aprendizagem/cadastro-objetivos-aprendizagem.html',
				controller: 'educacao.matriz.ObjetivosAprendizagemCadCtrl',
				controllerAs: 'vm',
				size: 'lg',
				params: {
					objetivoAprendizagem: {
						descricao: descricao,
						campoExperiencia: $scope.campoExperiencia
					}
				}
			}).result;
		}
	}
})();

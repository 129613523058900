(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.planejamento');

    moduloCommon.controller('educacao.planejamento.AnoLetivoCadCtrl', AnoLetivoCadCtrl);

    AnoLetivoCadCtrl.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.common.AnoLetivoService',
        'bfc.Notification',
        '$modalInstance'
    ];

    function AnoLetivoCadCtrl($scope, promiseTracker, anoLetivoService, bfcNotification, $modalInstance) {
        var vm = this;

        vm.anoLetivo = $modalInstance.params.anoLetivo || {};

        vm.editando = Boolean(vm.anoLetivo.id);

        vm.trackerLoading = promiseTracker();

        vm.save = save;
        vm.remove = remove;

        function save(continuar) {

            if (vm.anoLetivo.ano < 1900) {
                return bfcNotification.publish('O ano letivo deve ser igual ou superior a 1900.', 'error');
            }
            vm.trackerLoading.addPromise(
                anoLetivoService.save(vm.anoLetivo)
                    .then(notifySuccessSave)
                    .then(continuar ? _.noop : $modalInstance.close)
                    .then(novoAnoLetivo)
            );
        }

        function notifySuccessSave(data) {


            bfcNotification.publish('Ano letivo salvo com sucesso', 'success');
            return data;
        }

        function novoAnoLetivo(data) {


            $('#lbAnoLetivo').focus();
            $scope.form.$setPristine();
            $scope.form.$setUntouched();
            vm.anoLetivo = {};
            return data;

        }

        function remove() {
            return anoLetivoService.remove(vm.anoLetivo).then(
                function () {
                    bfcNotification.publish('Ano letivo removido com sucesso.', 'success');
                    $modalInstance.close();
                }
            );
           
        }
    }
})();

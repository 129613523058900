(function () {

    'use strict';

    angular.module('educacao.matricula').controller('educacao.matricula.RemanejamentoEdicaoCtrl', RemanejamentoEdicaoCtrl);

    RemanejamentoEdicaoCtrl.$inject = ['educacao.common.$commons', '$modalInstance', 'educacao.matricula.MatriculaService', 'educacao.matricula.EnturmacaoService'];
    function RemanejamentoEdicaoCtrl($commons, $modalInstance, MatriculaService, EnturmacaoService) {

        var vm = this;

        vm.tracker = {modal: $commons.$promiseTracker()};
        vm.remanejamento = {};
        vm.tipo = 'REMANEJAMENTO_INTERNO';

        vm.editar = editar;

        activate();

        function activate() {
            var promise = MatriculaService.getUltimoRemanejamento($modalInstance.params.matriculaId).then(function (remanejamento) {
                vm.remanejamento = remanejamento.plain();
            });
            vm.tracker.modal.addPromise(promise);
        }

        function editar() {
            var edicaoRemanejamento = createEdicaoRemanejamento();
            vm.tracker.modal.addPromise(EnturmacaoService.editarRemanejamento(edicaoRemanejamento).then(function () {
                $commons.$notification.publish('Remanejamento editado com sucesso!', 'success');
                $modalInstance.close();
            }));
        }

        function createEdicaoRemanejamento() {
            return {
                enturmacao: vm.remanejamento.enturmacao,
                data: vm.remanejamento.data,
                motivo: vm.remanejamento.motivo,
                observacao: vm.remanejamento.observacao
            };
        }

    }

})();

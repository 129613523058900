(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEspera', appConfiguracaoListaEspera);

    function appConfiguracaoListaEspera() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/configuracao-lista-espera.html',
            scope: {},
            bindToController: {},
            controller: 'educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaCtrl',
            controllerAs: 'vm'
        };
    }
})();

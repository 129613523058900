(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.ModalFiliacoesCtrl', ModalFiliacoesCtrl);

    ModalFiliacoesCtrl.$inject = ['$q', '$modalInstance', 'educacao.pessoas.AlunoService', 'promiseTracker',
        'bfc.$$PermissionsService', 'ui.components.Modal', '$enumsCache', 'educacao.pessoas.FiliacaoService', '$scope'];

    function ModalFiliacoesCtrl($q, $modalInstance, AlunoService, promiseTracker, PermissionsService, $modal, $enumsCache,
        FiliacaoService, $scope) {
        var vm = this;

        vm.close = close;
        vm.edit = edit;
        vm.save = save;
        vm.remove = remove;
        vm.filterResult = filterResult;
        vm.filiacoes = $modalInstance.params.filiacoes || [];
        vm.vinculo = $modalInstance.params.vinculo;
        vm.allDays = allDays;
        vm.refreshListDays = refreshListDays;
        vm.openModalFiliacao = openModalFiliacao;
        vm.todos = true;

        vm.tracker = {
            loading: promiseTracker(),
            save: promiseTracker(),
            saveContinue: promiseTracker(),
            remove: promiseTracker(),
            camposAdicionais: promiseTracker()
        };

        vm.add = !PermissionsService.isRevokedOperation('ResponsaveisPage', 'insert') && add;

        function add(text) {
            return $modal.open({
                templateUrl: 'planejamento/alunos/responsaveis/cadastro-responsaveis.html',
                controller: 'educacao.pessoas.ResponsavelCadCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    detalhes: text
                }
            });
        }

        function openModalFiliacao(vinculo, index) {
            return $modal.open({
                templateUrl: 'planejamento/filiacoes/cadastro-filiacao.html',
                controller: 'educacao.pessoas.FiliacaoCadCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    filiacao: _.get(vinculo, 'filiacao') ? FiliacaoService.get(vinculo.filiacao.id) : {},
                    vinculo: {
                        permiteRetirarAluno: vinculo ? angular.copy(vinculo.permiteRetirarAluno) : null,
                        diasDaSemana: vinculo.diaSemana || [],
                        ehResponsavel: vinculo.ehResponsavel,
                        tipo: vinculo.tipo,
                        natureza: vinculo.natureza
                    }
                }
            }).result.then(function(result) {
                if (result.remove) {
                    remove(index);
                }
            });
        }

        vm.tipo = $modalInstance.params.tipo;

        init();

        function inicializaEnums() {
            vm.options = {
                TipoFiliacao: FiliacaoService.getTipoFiliacao(),
                NaturezaFiliacao: FiliacaoService.getNaturezaFiliacao(),
            };
            vm.filiacao = {
                diaSemana: []
            };
            $enumsCache.get('DiaDaSemanaDto').then(function(diaSemana) {
                angular.forEach(diaSemana.values, function(dia) {
                    dia.checked = false;
                });
                vm.options.DiasDaSemana = diaSemana.values;
                parseDiasSemana();
            });
        }

        $scope.$watch('vm.filiacao.permiteRetirarAluno', function(value) {
            if (!value) {
                angular.forEach(vm.options.DiasDaSemana, function(dia) {
                    dia.checked = false;
                });
                vm.filiacao.diaSemana = [];
            }
        });

        function allDays(value) {
            if (vm.todos) {
                vm.todos = false;
            } else {
                vm.todos = true;
            }
            angular.forEach(vm.options.DiasDaSemana, function(dia) {
                dia.checked = value;
                if (vm.filiacao.diaSemana.indexOf(dia.key) === -1) {
                    vm.filiacao.diaSemana.push(dia.key);
                }
            });
        }

        function refreshListDays(dia) {
            if (vm.filiacao.diaSemana) {
                var existe = vm.filiacao.diaSemana.some(function(diaValue) {
                    return dia.key === diaValue;
                });
                if (existe) {
                    _.remove(vm.filiacao.diaSemana, function(diaSemana) {
                        return dia.key === diaSemana;
                    });
                } else {
                    vm.filiacao.diaSemana.push(dia.key);
                }
            } else {
                vm.filiacao.diaSemana = [dia.key];
            }
        }

        function init() {
            $modalInstance.tracker.addPromise(
                $q.all([
                    inicializaEnums(),
                    refresh()
                ])
            );
        }

        function limpaForm() {
            vm.form.$setPristine();
            vm.form.$setUntouched();
        }

        function refresh() {
            if (vm.form) {
                limpaForm();
            }
            if (!vm.filiacao.id) {
                vm.vinculo.diasDaSemana = [];
                return;
            }
            if (!vm.vinculo.diasDaSemana) {
                vm.vinculo.diasDaSemana = [];
            }
        }

        function parseDiasSemana() {
            var contDias = 0;
            angular.forEach(vm.vinculo.diasDaSemana, function(dia) {
                var option = _.find(vm.options.DiasDaSemana, function(op) {
                    return op.key === dia;
                });
                option.checked = true;
                contDias++;
            });
        }

        function filterResult(term, value) {
            var valueMaiusculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (valueMaiusculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }

        function close() {
            $modalInstance.close();
        }

        function edit($index) {
            vm.filiacao = angular.copy(vm.filiacoes[$index]);
            vm.indexToEdit = $index;
            angular.forEach(vm.options.DiasDaSemana, function(dia) {
                angular.forEach(vm.filiacao.diaSemana, function(diaSalvo) {
                    if (dia.key === diaSalvo) {
                        dia.checked = true;
                    }
                });
            });

            vm.editando = true;
        }

        function save() {
            if (vm.editando) {
                vm.filiacoes.splice(vm.indexToEdit, 1);
            }
            vm.filiacoes.push(vm.filiacao);

            clear();
        }

        function remove($index) {
            vm.filiacoes.splice($index, 1);
        }

        function clear() {
            vm.filiacao = {
                diaSemana: []
            };
            vm.indexToEdit = null;
            vm.editando = false;
            angular.forEach(vm.options.DiasDaSemana, function(dia) {
                dia.checked = false;
            });

            vm.form.$setPristine();
            vm.form.$setUntouched();
        }
    }
})();

(function() {
    'use strict';

    var ATIVO = 'ATIVO',
        INATIVO = 'INATIVO';

    angular.module('educacao.formulaCalculo')
        .controller('educacao.formulaCalculo.FormulaCalculoCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$modalInstance',
        'API_URL',
        'bfc.Focus',
        'educacao.context.ContextoEstabelecimento',
        'bfc.ScriptEditor',
        'educacao.formulaCalculo.FormulaCalculoService',
        '$rootScope'

    ];

    function Controller(
        $scope,
        promiseTracker,
        $modalInstance,
        API_URL,
        focus,
        ContextoEstabelecimento,
        ScriptEditor,
        FormulaCalculoService,
        $rootScope
    ) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.formulaCalculo = $modalInstance.params.formulaCalculo;
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.editarScript = editarScript;
        vm.isPersonalizado = isPersonalizado;

        var modo = {
            adicionando: !vm.formulaCalculo,
            editando: vm.formulaCalculo
        };

        vm.save = save;
        vm.cancel = fechaModal;
        vm.modo = modo;
        vm.remove = remove;
        vm.scriptPadrao = scriptPadrao;
        vm.editarScriptPadrao = editarScriptPadrao;
        vm.filterResult = filterResult;

        init();

        function isPersonalizado(){
            return !vm.modo.adicionando && !!vm.formulaCalculo.estabelecimento;
        }

        function refresh() {
            $rootScope.$broadcast('formulacalculo::refreshlist', {});
            fechaModal();
        }

        function init() {
            if (modo.adicionando) {
                vm.formulaCalculo = {
                    situacao: ATIVO
                };
            }

            vm.situacao = vm.formulaCalculo.situacao === 'INATIVO';
            

            vm.windowTitle = modo.adicionando ? 'Adicionando fórmula' : 'Editando fórmula';
            verificaFormulario();

            vm.enumTipoFormulaCalculoPromisse = FormulaCalculoService.getTipoFormulaCalculo();

            FormulaCalculoService.getTipoFormulaCalculo().then(function(data) {
                vm.TipoFormulaCalculo = data;
            });
        }

        function filterResult(term, value) {
            if (value.id !== 'RESULTADO_INSTRUMENTOS_AVALIACAO' ) {
                return value;
            }
  
        }


        function save(openScript) {

            vm.formulaCalculo.situacao = vm.situacao ? INATIVO : ATIVO;

            vm.tracker.loading.addPromise(
                FormulaCalculoService.save(vm.formulaCalculo).then(function(data) {
                    vm.formulaCalculo = data;
                    onSuccess(openScript);
                }).catch(function() {
                    vm.formulaCalculo.situacao = vm.situacao;
                })
            );

        }

        function scriptPadrao(openScript) {

            vm.formulaCalculo.situacao = vm.situacao ? INATIVO : ATIVO;

            vm.tracker.loading.addPromise(
                FormulaCalculoService.save(vm.formulaCalculo).then(function(data) {
                    vm.formulaCalculo = data;
                    onSuccessPadrao(openScript);
                }).catch(function() {
                    vm.formulaCalculo.situacao = vm.situacao;
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                FormulaCalculoService.remove(vm.formulaCalculo).then(function(data) {
                    $modalInstance.close(data);
                })
            );
        }

        function editarScript(formulaToEdit) {
            ScriptEditor.modal({
                resource: API_URL.concat('/formula-calculo/' + formulaToEdit.id + '/script'),
                title: 'Editando script de fórmula de cálculo: ' + vm.TipoFormulaCalculo[formulaToEdit.tipoFormulaCalculo].description,
                afterSave: refresh
            });
        }

        function editarScriptPadrao(formulaToEdit) {
            ScriptEditor.modal({
                resource: API_URL.concat('/formula-calculo/script-padrao/'+ formulaToEdit.id +'/tipo/' + formulaToEdit.tipoFormulaCalculo),
                title: 'Editando script de fórmula de cálculo: ' + vm.TipoFormulaCalculo[formulaToEdit.tipoFormulaCalculo].description,
                afterSave: refresh
            });
        }

        function onSuccess(openScript) {
            if (openScript) {
                editarScript(vm.formulaCalculo);
                fechaModal();
            } else {
                fechaModal();
            }
        }

        function onSuccessPadrao(openScript) {
            if (openScript) {
                editarScriptPadrao(vm.formulaCalculo);
                fechaModal();
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.formulaCalculo);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }

    }
})();


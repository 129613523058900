(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.enderecos.municipio';

        $stateProvider.state(STATE, {
            url: 'municipios',
            data: {
                id: 'MunicipioPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'enderecos/municipio/municipio.html',
                    controller: 'educacao.enderecos.MunicipiosController as vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.enderecos.MunicipioService',
            '$modalStates'
        ];

        function openModal($stateParams, MunicipioService, $modalStates) {
            $modalStates.open({
                getMethod: MunicipioService.get,
                templateUrl: 'enderecos/municipio/cadastro-municipio/cadastro-municipio.html',
                controller: 'educacao.enderecos.CadastroMunicipioController as vm',
                propertyName: 'municipio',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }
})();
(function () {

    'use strict';

    angular.module('educacao.common')
        .filter('uppercaseFirst', filter);

    function filter() {
        return uppercaseFirst;
        function uppercaseFirst(input) {
            return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
        }

    }

})();


(function() {
    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao', [])
        .constant('CONFIGURACAO_PASSOS', {
            PASSO_NIVEL_MODALIDADE: 1,
            PASSO_INSCRICAO: 2,
            PASSO_CLASSIFICACAO: 3,
            PASSO_FORMULARIO: 4,
            PASSO_MENSAGEM: 5,
            PASSO_PUBLICA: 6
        })
        .constant('CONFIGURACAO_ETAPAS', {
            SUGERIDAS_PELO_SISTEMA: '1',
            CONFORME_MATRIZ_CURRICULAR: '2'
        });
})();

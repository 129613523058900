(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .directive('appFiltroAvancadoAlunoDadosPessoais', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'matricula/matriculas/filtro-avancado/aluno/dados-pessoais/dados-pessoais.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorDadosPessoais: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.funcionario.FuncionarioService',
        'educacao.matricula.InscricaoMatriculaService'
    ];

    function Controller(
        $scope,
        FuncionarioService,
        InscricaoMatriculaService
    ) {
        var vm = this;
        vm.changeContadorDadosPessoais = changeContadorDadosPessoais;
        vm.formatEstadoCivil = formatEstadoCivil;
        vm.formatOrientacaoSexual = formatOrientacaoSexual;
        vm.formatGrauInstrucao = formatGrauInstrucao;

        vm.expressionCustomBuildersDadosPessoais = {
            religiao: function (filters) {
                if (filters.religiao) {
                    return 'religiao.id = ' + filters.religiao.id;
                }
            }
        };

        $scope.$watchGroup([
            'vm.advancedFilter.dadosPessoais.permiteEnvioSms',
            'vm.advancedFilter.dadosPessoais.permiteEnvioEmail',
            'vm.advancedFilter.dadosPessoais.religiao',
        ], changeContadorDadosPessoais);

        init();

        function init() {
            vm.expressionCustomBuilders = Object.assign(vm.expressionCustomBuilders, vm.expressionCustomBuildersDadosPessoais);
            vm.contadorDadosPessoais = 0;
            vm.EnumEstadoCivil = FuncionarioService.getEstadoCivil().then(function(dados) {
                vm.ENUM_ESTADO_CIVIL = dados;
                return dados;
            });
            vm.EnumOrientacaoSexual = InscricaoMatriculaService.getOrientacaoSexual().then(function(dados){
                vm.ENUM_ORIENTACAO_SEXUAL = dados;
                return dados;
            });
            vm.EnumGrauInstrucao = InscricaoMatriculaService.getGrauInstrucao().then(function(dados){
                vm.ENUM_GRAU_INSTRUCAO  = dados;
                return dados;
            });
            changeContadorDadosPessoais();
        }

        function changeContadorDadosPessoais() {
            if (vm.advancedFilter && vm.advancedFilter.dadosPessoais) {
                vm.contadorDadosPessoais = getFilterLength();
            }
        }

        function getFilterLength() {
            return Object.values(vm.advancedFilter.dadosPessoais).filter(function (n) { 
                return n !== undefined && n !== null && n !== 0 && n !== ''; 
            }).length;
        }

        function formatEstadoCivil(estadoCivil) {
            return vm.ENUM_ESTADO_CIVIL[estadoCivil].description;
        }

        function formatOrientacaoSexual(orientacaoSexual) {
            return vm.ENUM_ORIENTACAO_SEXUAL[orientacaoSexual].description;
        }

        function formatGrauInstrucao(grauInstrucao) {
            return vm.ENUM_GRAU_INSTRUCAO[grauInstrucao].description;
        }
    }
})();
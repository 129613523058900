(function() {

    'use strict';

    var ATIVIDADES = ['ATIVIDADES_COMPLEMENTARES', 'ATIVDADES_AEE'];
    var COMPLEMENTAR = 'COMPLEMENTAR';

    angular.module('educacao.lista-espera')
        .directive('appListaEsperaItemDetails', appListaEsperaItemDetails);

    function appListaEsperaItemDetails() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera/app-lista-espera-item/app-lista-espera-item-details/app-lista-espera-item-details.directive.html',
            scope: {},
            bindToController: {
                item: '=',
                details: '=',
                tipoItem: '=',
                filter: '=',
                tracker: '=',
                cancelFunction: '=',
                removeFunction: '=',
                removeModalidadeFunction: '=',
                refresh: '=',
                undoFunction: '=',
                matricularFunction: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        '$listaEsperaItemService'
    ];

    function Controller($listaEsperaItemService) {
        var vm = this;

        vm.getColspan = getColspan;
        vm.getColumnDescription = getColumnDescription;
        vm.getDescricaoNivel = getDescricaoNivel;
        vm.showEtapa = showEtapa;
        vm.isCancelado = $listaEsperaItemService.isCancelado;
        vm.isInscrito = $listaEsperaItemService.isInscrito;
        vm.onClickCancelEtapa = onClickCancelEtapa;
        vm.onClickExcluir = onClickExcluir;
        vm.onClickDesfazerCancelamento = onClickDesfazerCancelamento;
        vm.toggleDetails = toggleDetails;
        vm.toggleItem = toggleItem;
        
        function toggleItem(detail) {
            detail.$$opened = !detail.$$opened;
        }

        function toggleDetails(modalidade) {
            vm.modalidade = modalidade;
            modalidade.$$showDetails = !modalidade.$$showDetails;
        }

        function onClickExcluir(nivel) {
            if (_.isFunction(vm.removeModalidadeFunction)) {
                vm.removeModalidadeFunction(nivel.id);
            }
        }

        function onClickCancelEtapa(nivel) {
            var idInscricao = vm.item.listaEspera.id;
            if (_.isFunction(vm.cancelFunction)) {
                vm.cancelFunction(idInscricao, nivel.id);
            }
        }

        function onClickDesfazerCancelamento(nivel) {
            var idInscricao = vm.item.listaEspera.id;
            if (_.isFunction(vm.undoFunction)) {
                vm.undoFunction(idInscricao, nivel.id);
            }
        }

        function hasEtapa(modalidade) {
            return modalidade.equivalenciaEtapa;
        }

        function showEtapa(detail) {
            return _.chain(detail).any('equivalenciaEtapa').value();
        }

        function getColumnDescription(detail) {
            if (_.includes(ATIVIDADES, detail)) {
                return 'Atividade';
            }
            if (COMPLEMENTAR === detail) {
                return 'Curso';
            }
            return 'Nível escolar';
        }

        function getDescricaoNivel(modalidade) {
            return _.get(modalidade, 'atividade.descricao') ||
                _.get(modalidade, 'curso.descricao');
        }

        function getColspan(modalidade) {
            if (vm.isCancelado(vm.tipoItem)) {
                return hasEtapa(modalidade) ? 4 : 3;
            }
            if (!hasEtapa(modalidade)) {
                return 2;
            }
            return 3;
        }
    }

})();

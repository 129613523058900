(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .controller('educacao.rematricula.RematriculaCtrl', RematriculaController);

    RematriculaController.$inject = ['bfc.$$PermissionsService', 'promiseTracker', 'educacao.rematricula.RematriculaService',
        '$modalInstance', 'bfc.Notification'];

    function RematriculaController(PermissionsService, promiseTracker, RematriculaService, $modalInstance, Notification) {

        var vm = this;

        vm.hasPermissionRematricularEstabelecimentoDiferente = hasPermissionRematricularEstabelecimentoDiferente;
        vm.hasAlunosSelecionados = hasAlunosSelecionados;
        vm.openStepRematricular = openStepRematricular;
        vm.onStepSelecionarAlunos = onStepSelecionarAlunos;
        vm.rematricular = rematricular;

        vm.tracker = promiseTracker();

        vm.stepConfiguration = {
            selecionarAlunos: 'SELECIONAR_ALUNOS',
            rematricular: 'REMATRICULAR'
        };

        vm.turmas = [];
        vm.permiteMultiturno = false;

        function onStepSelecionarAlunos() {
            vm.stepModal = vm.stepConfiguration.selecionarAlunos;
        }

        function openStepRematricular() {

            if (vm.stepModal === vm.stepConfiguration.rematricular) {
                return;
            }

            var turmasRematricular = [];

            _.forEach(vm.turmas, function(turma) {
                if (_.find(turma.matriculas, 'checked', true)) {

                    var alunosCheked = _.filter(turma.matriculas, {
                        checked: true
                    });

                    var indexEtapaMatriz = _.findIndex(turmasRematricular, 'id', turma.etapaMatriz.id);

                    if (indexEtapaMatriz >= 0) {
                        turmasRematricular[indexEtapaMatriz].matriculas =
                            turmasRematricular[indexEtapaMatriz].matriculas.concat(alunosCheked);
                    } else {

                        turmasRematricular.push(
                            _.extend(
                                turma.etapaMatriz, {
                                    matriculas: alunosCheked,
                                    destino: {
                                        estabelecimento: vm.filtroPesquisado.estabelecimento
                                    }
                                })
                        );
                    }
                }
            });

            vm.etapasRematricular = angular.copy(turmasRematricular);

            vm.stepModal = vm.stepConfiguration.rematricular;
        }

        function hasAlunosSelecionados() {
            if (!vm.turmas) {
                return false;
            }

            return _.find(vm.turmas, function(turma) {
                return turma.$$matriculasSelecionadas > 0;
            });
        }

        function hasPermissionRematricularEstabelecimentoDiferente() {
            return Boolean(!PermissionsService.isRevokedOperation('RematriculaPageMapping',
                'rematricularEntreEstabelecimentosDiferentes'));
        }

        function rematricular(rematriculas) {

            var rematriculaDestino = [];
            _.forEach(rematriculas, function(item) {
                rematriculaDestino.push({
                    anoLetivo: item.destino.anoLetivo,
                    vaga: item.destino.turno,
                    turma: item.destino.turma,
                    data: item.destino.dataRematricula,
                    turnoFrequenta: _.get(item.destino.turnoFrequenta, 'turno', null),
                    itens: formatItens(item.matriculas, item.destino.anoLetivo, item.destino.turma)
                });
            });

            var resourceRematricular = hasPermissionRematricularEstabelecimentoDiferente() ? 'rematriculaLotesEstabelecimentoDiferente' :
                'rematricular';

            vm.tracker.addPromise(RematriculaService[resourceRematricular](rematriculaDestino)
                .then(
                    function() {
                        $modalInstance.close();
                        var message =
                            'Processo de rematrícula está em andamento. Ao término do processamento, você será notificado.';
                        Notification.publish(message, 'info');
                    }
                )
            );
        }

        function formatItens(itens, anoLetivo, turma) {
            var rematriculaItem = [];
            _.forEach(itens, function(item) {
                rematriculaItem.push({
                    anoLetivo: anoLetivo,
                    matricula: item,
                    turma: turma
                });
            });
            return rematriculaItem;
        }

    }

})();

/* jshint ignore:start */
(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.prototipo');

	moduloCommon.controller('educacao.prototipo.MovimentoRendimentoEscolarCtrl', [
		'$scope', 
		'$timeout',
		'educacao.common.ModalCad',
		'bfc.Select2Config', 
		'$rootScope',
		'bfc.Popover',
		function(
			$scope, 
			$timeout, 
			ModalCad, 
			Select2Config, 
			$rootScope
		) {

		$scope.popValue = function(v){
			$scope.pop = v;
			$scope.acao=function(){
				ModalCad.open({
					templateUrl: 'prototipo/movimento-e-rendimento-escolar/motivos-movimentacao.html',
					controller: 'educacao.prototipo.MotivosMovimentacaoCadCtrl',
					resolve:{
						value: function(){
							return $scope.pop;
						}
					}
				});
			}
			$scope.reclassificadas=$scope.pop==5?true:false;
			console.log("pop:"+$scope.pop);
			$scope.titleTotalMotivos=$scope.pop==1?"tranferidas":($scope.pop==2?"deixaram de frequentar":($scope.pop==3?"com falecimento":"canceladas"));
		}

		$scope.popValueMatriculasNovas=function(v){
			$scope.popMatriculasNovas = v;
			ModalCad.open({
				templateUrl: 'prototipo/movimento-e-rendimento-escolar/matriculas-novas.html',
				controller: 'educacao.prototipo.MatriculasEditCtrl',
				resolve:{
					value: function(){
						return $scope.popMatriculasNovas;
					}
				}
			});
		}	


		
		$scope.compararPeriodos=function(g){
			$scope.groupC = g;
		}
		
		$scope.compararPeriodos=function(g){
			$scope.groupC = g;
		}
		
		$scope.sairModoComparacao=function(g){
			$scope.groupC = g;
			$scope.filtro=true;
		}

		$scope.widgetGraficoo = true;
		$scope.widgetListt = true;
		$scope.widgetTodosSelected = true;
		$scope.widgetList = function(){
			$scope.widgetListSelected = true;
			$scope.widgetGraficoSelected = false;
			$scope.widgetTodosSelected = false;
			$scope.widgetListt = true;
			$scope.widgetGraficoo = false;
		}
		$scope.widgetGrafico = function(){
			$scope.widgetListSelected = false;
			$scope.widgetGraficoSelected = true;
			$scope.widgetTodosSelected = false;
			$scope.widgetListt = false;
			$scope.widgetGraficoo = true;
		}
		$scope.widgetTodos = function(){
			$scope.widgetListSelected = false;
			$scope.widgetGraficoSelected = false;
			$scope.widgetTodosSelected = true;
			$scope.widgetListt = true;
			$scope.widgetGraficoo = true;
		}
		/*Escolher o tipo de grafico*/
		$scope.graficoColuna = true;
		$scope.selectGraficoPizza = function(){
			$scope.graficoPizza = true;
			$scope.graficoColuna = false;
			$scope.graficoLinha = false;
		}
		$scope.selectGraficoLinha = function(){
			$scope.graficoLinha = true;
			$scope.graficoColuna = false;
			$scope.graficoPizza = false;
		}
		$scope.selectGraficoColuna = function(){
			$scope.graficoColuna = true;
			$scope.graficoLinha = false;
			$scope.graficoPizza = false;
		}
		/*Fim escolher o tipo de grafico*/

		/*Escolher agrupador*/
		$scope.groupM = 1;
		$scope.agrupadorM = function(gpM){
			$scope.groupM = gpM;
		}

		$scope.ampliarGrafico = function(id){
			ModalCad.open({
				templateUrl: 'prototipo/movimento-e-rendimento-escolar/ampliar-grafico.html',
				controller: 'educacao.prototipo.AmpliarGraficoCadCtrl',
				id: id,
				readOnly: false
			});
		};

		$scope.openAddDependencia = function(id) {
			ModalCad.open({
				templateUrl: 'prototipo/dependencias/dependencias.cad.html',
				controller: 'educacao.prototipo.DependenciasCadCtrl',
				id: id,
				readOnly: false
			});
		};

		$scope.openOutrosFiltros = function(id) {
			ModalCad.open({
				templateUrl: 'prototipo/movimento-e-rendimento-escolar/outros-filtros.html',
				controller: function($scope, $timeout){

				}
			});
		};

		$scope.selectedValue = "nothing";
		$rootScope.events = {
		    dataplotclick: function(ev, props) {
		        $scope.$apply(function() {

		            if(props.displayValue == 100){
		            	return $scope.openTransferencias();
		            }
		            if(props.displayValue == 12){
		            	return $scope.openDdf();
		            }
		            if(props.displayValue == 1){
		            	return $scope.openFalecimento();
		            }
		            if(props.displayValue == 5){
		            	return $scope.openCanceladas();
		            }
		            if(props.displayValue == 3){
		            	return $scope.openReclassificadas();
		            }

		            $rootScope.selectedValue = props.displayValue;

		        });
		    }
		}	

		$scope.graficoComparativo = {
		    "chart": {
		        "caption": "Rede XPTO",
		        "yAxisName": "Comparação entre períodos",
		        "plotFillAlpha": "80",
		        "paletteColors": "#0075c2,#1aaf5d",
		        "baseFontColor": "#333333",
		        "baseFont": "Helvetica Neue,Arial",
		        "captionFontSize": "14",
		        "subcaptionFontSize": "14",
		        "subcaptionFontBold": "0",
		        "showBorder": "0",
		        "bgColor": "#ffffff",
		        "showShadow": "0",
		        "canvasBgColor": "#ffffff",
		        "canvasBorderAlpha": "0",
		        "divlineAlpha": "100",
		        "divlineColor": "#999999",
		        "divlineThickness": "1",
		        "divLineIsDashed": "1",
		        "divLineDashLen": "1",
		        "divLineGapLen": "1",
		        "usePlotGradientColor": "0",
		        "showplotborder": "0",
		        "valueFontColor": "#ffffff",
		        "placeValuesInside": "1",
		        "showHoverEffect": "1",
		        "rotateValues": "1",
		        "showXAxisLine": "1",
		        "xAxisLineThickness": "1",
		        "xAxisLineColor": "#999999",
		        "showAlternateHGridColor": "0",
		        "legendBgAlpha": "0",
		        "legendBorderAlpha": "0",
		        "legendShadow": "0",
		        "legendItemFontSize": "10",
		        "legendItemFontColor": "#666666",
		        "formatNumberScale": "0",
		        "decimals": "0"
		    },
		    "geral":[
		    	{"periodo":[
		    			{matricula:"500",matricula2:"1500",
		    			 transferencia:"100",transferencia2:"80",
		    			 ddf:"12",ddf2:"8",
		    			 falecimento:"0",falecimento2:"0",
		    			 canceladas:"5",canceladas2:"10",
		    			 // reclassificadas:"2",reclassificadas2:"2",
		    			}
		    		]
		    	}
		    ],
		    "categories": [
		        {
		            "category": [
		                {
		                    "label": "Matrículas"
		                },
		                // {
		                //     "label": "Novas matrículas"
		                // },
		                {
		                    "label": "Transferências"
		                },
		                {
		                    "label": "Deixaram de frequentar"
		                },
		                {
		                    "label": "Falecimento"
		                },
		                {
		                    "label": "Canceladas"
		                },
		                // {
		                //     "label": "Reclassificadas"
		                // }
		            ]
		        }
		    ],
		    "dataset": [
		        {
		            "seriesname": "De 01/01/2014 á 31/12/2014",
		            "data": [
		                {
		                    "value": "1000"
		                },
		                {
		                    "value": "2"
		                },
		                {
		                    "value": "200"
		                },
		                {
		                    "value": "12"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "5"
		                }
		            ]
		        },
		        {
		            "seriesname": "De 01/01/2015 á 31/12/2015",
		            "data": [
		                {
		                    "value": "1500"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "80"
		                },
		                {
		                    "value": "8"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "10"
		                }
		                
		            ]
		        }
		    ]
		};	

		$scope.graficoComparativo2 = {
		    "chart": {
		        "caption": "E.E.B. Filho do Mineiro",
		        "yAxisName": "Comparação entre períodos",
		        "plotFillAlpha": "80",
		        "paletteColors": "#0075c2,#1aaf5d",
		        "baseFontColor": "#333333",
		        "baseFont": "Helvetica Neue,Arial",
		        "captionFontSize": "14",
		        "subcaptionFontSize": "14",
		        "subcaptionFontBold": "0",
		        "showBorder": "0",
		        "bgColor": "#ffffff",
		        "showShadow": "0",
		        "canvasBgColor": "#ffffff",
		        "canvasBorderAlpha": "0",
		        "divlineAlpha": "100",
		        "divlineColor": "#999999",
		        "divlineThickness": "1",
		        "divLineIsDashed": "1",
		        "divLineDashLen": "1",
		        "divLineGapLen": "1",
		        "usePlotGradientColor": "0",
		        "showplotborder": "0",
		        "valueFontColor": "#ffffff",
		        "placeValuesInside": "1",
		        "showHoverEffect": "1",
		        "rotateValues": "1",
		        "showXAxisLine": "1",
		        "xAxisLineThickness": "1",
		        "xAxisLineColor": "#999999",
		        "showAlternateHGridColor": "0",
		        "legendBgAlpha": "0",
		        "legendBorderAlpha": "0",
		        "legendShadow": "0",
		        "legendItemFontSize": "10",
		        "legendItemFontColor": "#666666",
		        "formatNumberScale": "0",
		        "decimals": "0"
		    },
		    "geral":[
		    	{"periodo":[
		    			{matricula:"500",matricula2:"1500",
		    			 transferencia:"100",transferencia2:"80",
		    			 ddf:"12",ddf2:"8",
		    			 falecimento:"0",falecimento2:"0",
		    			 canceladas:"5",canceladas2:"10",
		    			 // reclassificadas:"2",reclassificadas2:"2",
		    			}
		    		]
		    	}
		    ],
		    "categories": [
		        {
		            "category": [
		                {
		                    "label": "Matrículas"
		                },
		                // {
		                //     "label": "Novas matrículas"
		                // },
		                {
		                    "label": "Transferências"
		                },
		                {
		                    "label": "Deixaram de frequentar"
		                },
		                {
		                    "label": "Falecimento"
		                },
		                {
		                    "label": "Canceladas"
		                },
		                // {
		                //     "label": "Reclassificadas"
		                // }
		            ]
		        }
		    ],
		    "dataset": [
		        {
		            "seriesname": "De 01/01/2014 á 31/12/2014",
		            "data": [
		                {
		                    "value": "1000"
		                },
		                {
		                	"value": "2"
		                },
		                {
		                    "value": "200"
		                },
		                {
		                    "value": "12"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "5"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        },
		        {
		            "seriesname": "De 01/01/2015 á 31/12/2015",
		            "data": [
		                {
		                    "value": "1500"
		                },
		                {
		                	"value": "10"
		                },
		                {
		                    "value": "80"
		                },
		                {
		                    "value": "8"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        }
		    ]
		};	

		$scope.graficoComparativo3 = {
		    "chart": {
		        "caption": "E.E.B. Padre Miguel Giacca",
		        "yAxisName": "Comparação entre períodos",
		        "plotFillAlpha": "80",
		        "paletteColors": "#0075c2,#1aaf5d",
		        "baseFontColor": "#333333",
		        "baseFont": "Helvetica Neue,Arial",
		        "captionFontSize": "14",
		        "subcaptionFontSize": "14",
		        "subcaptionFontBold": "0",
		        "showBorder": "0",
		        "bgColor": "#ffffff",
		        "showShadow": "0",
		        "canvasBgColor": "#ffffff",
		        "canvasBorderAlpha": "0",
		        "divlineAlpha": "100",
		        "divlineColor": "#999999",
		        "divlineThickness": "1",
		        "divLineIsDashed": "1",
		        "divLineDashLen": "1",
		        "divLineGapLen": "1",
		        "usePlotGradientColor": "0",
		        "showplotborder": "0",
		        "valueFontColor": "#ffffff",
		        "placeValuesInside": "1",
		        "showHoverEffect": "1",
		        "rotateValues": "1",
		        "showXAxisLine": "1",
		        "xAxisLineThickness": "1",
		        "xAxisLineColor": "#999999",
		        "showAlternateHGridColor": "0",
		        "legendBgAlpha": "0",
		        "legendBorderAlpha": "0",
		        "legendShadow": "0",
		        "legendItemFontSize": "10",
		        "legendItemFontColor": "#666666",
		        "formatNumberScale": "0",
		        "decimals": "0"
		    },
		    "geral":[
		    	{"periodo":[
		    			{matricula:"500",matricula2:"1500",
		    			 transferencia:"100",transferencia2:"80",
		    			 ddf:"12",ddf2:"8",
		    			 falecimento:"0",falecimento2:"0",
		    			 canceladas:"5",canceladas2:"10",
		    			 // reclassificadas:"2",reclassificadas2:"2",
		    			}
		    		]
		    	}
		    ],
		    "categories": [
		        {
		            "category": [
		                {
		                    "label": "Matrículas"
		                },
		                // {
		                //     "label": "Novas matrículas"
		                // },
		                {
		                    "label": "Transferências"
		                },
		                {
		                    "label": "Deixaram de frequentar"
		                },
		                {
		                    "label": "Falecimento"
		                },
		                {
		                    "label": "Canceladas"
		                },
		                // {
		                //     "label": "Reclassificadas"
		                // }
		            ]
		        }
		    ],
		    "dataset": [
		        {
		            "seriesname": "De 01/01/2014 á 31/12/2014",
		            "data": [
		                {
		                    "value": "1000"
		                },
		                {
		                	"value": "2"
		                },
		                {
		                    "value": "200"
		                },
		                {
		                    "value": "12"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "5"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        },
		        {
		            "seriesname": "De 01/01/2015 á 31/12/2015",
		            "data": [
		                {
		                    "value": "1500"
		                },
		                {
		                	"value": "10"
		                },
		                {
		                    "value": "80"
		                },
		                {
		                    "value": "8"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        }
		    ]
		};	

		$scope.graficoComparativo4 = {
		    "chart": {
		        "caption": "Matriz - Educação infantil",
		        // "subCaption": "Período: 01/02/2015 á 01/03/2015",
		        "yAxisName": "Comparação entre períodos",
		        "plotFillAlpha": "80",
		        "paletteColors": "#0075c2,#1aaf5d",
		        "baseFontColor": "#333333",
		        "baseFont": "Helvetica Neue,Arial",
		        "captionFontSize": "14",
		        "subcaptionFontSize": "14",
		        "subcaptionFontBold": "0",
		        "showBorder": "0",
		        "bgColor": "#ffffff",
		        "showShadow": "0",
		        "canvasBgColor": "#ffffff",
		        "canvasBorderAlpha": "0",
		        "divlineAlpha": "100",
		        "divlineColor": "#999999",
		        "divlineThickness": "1",
		        "divLineIsDashed": "1",
		        "divLineDashLen": "1",
		        "divLineGapLen": "1",
		        "usePlotGradientColor": "0",
		        "showplotborder": "0",
		        "valueFontColor": "#ffffff",
		        "placeValuesInside": "1",
		        "showHoverEffect": "1",
		        "rotateValues": "1",
		        "showXAxisLine": "1",
		        "xAxisLineThickness": "1",
		        "xAxisLineColor": "#999999",
		        "showAlternateHGridColor": "0",
		        "legendBgAlpha": "0",
		        "legendBorderAlpha": "0",
		        "legendShadow": "0",
		        "legendItemFontSize": "10",
		        "legendItemFontColor": "#666666",
		        "formatNumberScale": "0",
		        "decimals": "0"
		    },
		    "geral":[
		    	{"periodo":[
		    			{matricula:"500",matricula2:"1500",
		    			 transferencia:"100",transferencia2:"80",
		    			 ddf:"12",ddf2:"8",
		    			 falecimento:"0",falecimento2:"0",
		    			 canceladas:"5",canceladas2:"10",
		    			 // reclassificadas:"2",reclassificadas2:"2",
		    			}
		    		]
		    	}
		    ],
		    "categories": [
		        {
		            "category": [
		                {
		                    "label": "Matrículas"
		                },
		                // {
		                //     "label": "Novas matrículas"
		                // },
		                {
		                    "label": "Transferências"
		                },
		                {
		                    "label": "Deixaram de frequentar"
		                },
		                {
		                    "label": "Falecimento"
		                },
		                {
		                    "label": "Canceladas"
		                },
		                // {
		                //     "label": "Reclassificadas"
		                // }
		            ]
		        }
		    ],
		    "dataset": [
		        {
		            "seriesname": "De 01/01/2014 á 31/12/2014",
		            "data": [
		                {
		                    "value": "1000"
		                },
		                {
		                    "value": "2"
		                },
		                {
		                    "value": "200"
		                },
		                {
		                    "value": "12"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "5"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        },
		        {
		            "seriesname": "De 01/01/2015 á 31/12/2015",
		            "data": [
		                {
		                    "value": "1500"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "80"
		                },
		                {
		                    "value": "8"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        }
		    ]
		};

		$scope.graficoComparativo5 = {
		    "chart": {
		        "caption": "Matriz - Educação fundamental",
		        // "subCaption": "Período: 01/02/2015 á 01/03/2015",
		        "yAxisName": "Comparação entre períodos",
		        "plotFillAlpha": "80",
		        "paletteColors": "#0075c2,#1aaf5d",
		        "baseFontColor": "#333333",
		        "baseFont": "Helvetica Neue,Arial",
		        "captionFontSize": "14",
		        "subcaptionFontSize": "14",
		        "subcaptionFontBold": "0",
		        "showBorder": "0",
		        "bgColor": "#ffffff",
		        "showShadow": "0",
		        "canvasBgColor": "#ffffff",
		        "canvasBorderAlpha": "0",
		        "divlineAlpha": "100",
		        "divlineColor": "#999999",
		        "divlineThickness": "1",
		        "divLineIsDashed": "1",
		        "divLineDashLen": "1",
		        "divLineGapLen": "1",
		        "usePlotGradientColor": "0",
		        "showplotborder": "0",
		        "valueFontColor": "#ffffff",
		        "placeValuesInside": "1",
		        "showHoverEffect": "1",
		        "rotateValues": "1",
		        "showXAxisLine": "1",
		        "xAxisLineThickness": "1",
		        "xAxisLineColor": "#999999",
		        "showAlternateHGridColor": "0",
		        "legendBgAlpha": "0",
		        "legendBorderAlpha": "0",
		        "legendShadow": "0",
		        "legendItemFontSize": "10",
		        "legendItemFontColor": "#666666",
		        "formatNumberScale": "0",
		        "decimals": "0"
		    },
		    "geral":[
		    	{"periodo":[
		    			{matricula:"500",matricula2:"1500",
		    			 transferencia:"100",transferencia2:"80",
		    			 ddf:"12",ddf2:"8",
		    			 falecimento:"0",falecimento2:"0",
		    			 canceladas:"5",canceladas2:"10",
		    			 // reclassificadas:"2",reclassificadas2:"2",
		    			}
		    		]
		    	}
		    ],
		    "categories": [
		        {
		            "category": [
		                {
		                    "label": "Matrículas"
		                },
		                // {
		                //     "label": "Novas matrículas"
		                // },
		                {
		                    "label": "Transferências"
		                },
		                {
		                    "label": "Deixaram de frequentar"
		                },
		                {
		                    "label": "Falecimento"
		                },
		                {
		                    "label": "Canceladas"
		                },
		                // {
		                //     "label": "Reclassificadas"
		                // }
		            ]
		        }
		    ],
		    "dataset": [
		        {
		            "seriesname": "De 01/01/2014 á 31/12/2014",
		            "data": [
		                {
		                    "value": "1000"
		                },
		                {
		                    "value": "2"
		                },
		                {
		                    "value": "200"
		                },
		                {
		                    "value": "12"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "5"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        },
		        {
		            "seriesname": "De 01/01/2015 á 31/12/2015",
		            "data": [
		                {
		                    "value": "1500"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "80"
		                },
		                {
		                    "value": "8"
		                },
		                {
		                    "value": "0"
		                },
		                {
		                    "value": "10"
		                },
		                {
		                    "value": "2"
		                }
		            ]
		        }
		    ]
		};

		FusionCharts.ready(function () {
		    var hourlySalesChart = new FusionCharts({
		        type: 'msline',
		        renderAt: 'chart-container-linha',
		        width: '100%',
		        height: '350',
		        dataFormat: 'json',
		        dataSource: {
		        "chart": {
				        "caption": "Rede municipal XPTO",
				        "subcaption": "Período: 01/02/2015 a 01/03/2015",
				        "linethickness": "1",
				        "showvalues": "0",
				        "formatnumberscale": "0",
				        "anchorradius": "2",
				        "divlinecolor": "666666",
				        "divlinealpha": "30",
				        "divlineisdashed": "1",
				        "bgcolor": "FFFFFF",
				        "showalternatehgridcolor": "0",
				        "labelpadding": "10",
				        "canvasborderthickness": "1",
				        "legendiconscale": "1.5",
				        "legendshadow": "0",
				        "legendborderalpha": "0",
				        "legendposition": "right",
				        "canvasborderalpha": "50",
				        "numvdivlines": "5",
				        "vdivlinealpha": "20",
				        "showborder": "0"
				    },
				    "categories": [
				        {
				            "category": [
				                {
				                    "label": "31/01/2015"
				                },
				                {
				                    "label": "10/02/2015"
				                },
				                {
				                    "label": "20/02/2015"
				                },
				                {
				                    "label": "01/03/2015"
				                }
				            ]
				        }
				    ],
				    "dataset": [
				        {
				            "seriesname": "Matrículas",
				            "color": "1D8BD1",
				            "anchorbordercolor": "1D8BD1",
				            "anchorbgcolor": "1D8BD1",
				            "data": [
				                {
				                    "value": "50"
				                },
				                {
				                    "value": "20"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "50"
				                }
				            ]
				        },
				        {
				            "seriesname": "Transferências",
				            "color": "F1683C",
				            "anchorbordercolor": "F1683C",
				            "anchorbgcolor": "F1683C",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "90"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "100"
				                }
				            ]
				        },
				        {
				            "seriesname": "Deixaram de frequentar",
				            "color": "2AD62A",
				            "anchorbordercolor": "2AD62A",
				            "anchorbgcolor": "2AD62A",
				            "data": [
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "12"
				                }
				            ]
				        },
				        {
				            "seriesname": "Falecimento",
				            "color": "DBDC25",
				            "anchorbordercolor": "DBDC25",
				            "anchorbgcolor": "DBDC25",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "1"
				                }
				            ]
				        },
				        {
				            "seriesname": "Canceladas",
				            "color": "6d25bd",
				            "anchorbordercolor": "6d25bd",
				            "anchorbgcolor": "6d25bd",
				            "data": [
				                {
				                    "value": "2"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "10"
				                },
				                {
				                    "value": "5"
				                }
				            ]
				        }
				    ]
		        }
		    }).render();
		    var hourlySalesChart2 = new FusionCharts({
		        type: 'msline',
		        renderAt: 'chart-container-linha2',
		        width: '100%',
		        height: '350',
		        dataFormat: 'json',
		        dataSource: {
		        "chart": {
				        "caption": "E.E.B. Padre Miguel Giacca",
				        "subcaption": "Período: 01/02/2015 a 01/03/2015",
				        "linethickness": "1",
				        "showvalues": "0",
				        "formatnumberscale": "0",
				        "anchorradius": "2",
				        "divlinecolor": "666666",
				        "divlinealpha": "30",
				        "divlineisdashed": "1",
				        "bgcolor": "FFFFFF",
				        "showalternatehgridcolor": "0",
				        "labelpadding": "10",
				        "canvasborderthickness": "1",
				        "legendiconscale": "1.5",
				        "legendshadow": "0",
				        "legendborderalpha": "0",
				        "legendposition": "right",
				        "canvasborderalpha": "50",
				        "numvdivlines": "5",
				        "vdivlinealpha": "20",
				        "showborder": "0"
				    },
				    "categories": [
				        {
				            "category": [
				                {
				                    "label": "31/01/2015"
				                },
				                {
				                    "label": "10/02/2015"
				                },
				                {
				                    "label": "20/02/2015"
				                },
				                {
				                    "label": "01/03/2015"
				                }
				            ]
				        }
				    ],
				    "dataset": [
				        {
				            "seriesname": "Matrículas",
				            "color": "1D8BD1",
				            "anchorbordercolor": "1D8BD1",
				            "anchorbgcolor": "1D8BD1",
				            "data": [
				                {
				                    "value": "50"
				                },
				                {
				                    "value": "20"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "50"
				                }
				            ]
				        },
				        {
				            "seriesname": "Transferências",
				            "color": "F1683C",
				            "anchorbordercolor": "F1683C",
				            "anchorbgcolor": "F1683C",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "90"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "100"
				                }
				            ]
				        },
				        {
				            "seriesname": "Deixaram de frequentar",
				            "color": "2AD62A",
				            "anchorbordercolor": "2AD62A",
				            "anchorbgcolor": "2AD62A",
				            "data": [
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "12"
				                }
				            ]
				        },
				        {
				            "seriesname": "Falecimento",
				            "color": "DBDC25",
				            "anchorbordercolor": "DBDC25",
				            "anchorbgcolor": "DBDC25",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "1"
				                }
				            ]
				        },
				        {
				            "seriesname": "Canceladas",
				            "color": "6d25bd",
				            "anchorbordercolor": "6d25bd",
				            "anchorbgcolor": "6d25bd",
				            "data": [
				                {
				                    "value": "2"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "10"
				                },
				                {
				                    "value": "5"
				                }
				            ]
				        }
				    ]
		        }
		    }).render();
		    var hourlySalesChart3 = new FusionCharts({
		        type: 'msline',
		        renderAt: 'chart-container-linha3',
		        width: '100%',
		        height: '350',
		        dataFormat: 'json',
		        dataSource: {
		        "chart": {
				        "caption": "E.E.B. Filho do Mineiro",
				        "subcaption": "Período: 01/02/2015 a 01/03/2015",
				        "linethickness": "1",
				        "showvalues": "0",
				        "formatnumberscale": "0",
				        "anchorradius": "2",
				        "divlinecolor": "666666",
				        "divlinealpha": "30",
				        "divlineisdashed": "1",
				        "bgcolor": "FFFFFF",
				        "showalternatehgridcolor": "0",
				        "labelpadding": "10",
				        "canvasborderthickness": "1",
				        "legendiconscale": "1.5",
				        "legendshadow": "0",
				        "legendborderalpha": "0",
				        "legendposition": "right",
				        "canvasborderalpha": "50",
				        "numvdivlines": "5",
				        "vdivlinealpha": "20",
				        "showborder": "0"
				    },
				    "categories": [
				        {
				            "category": [
				                {
				                    "label": "31/01/2015"
				                },
				                {
				                    "label": "10/02/2015"
				                },
				                {
				                    "label": "20/02/2015"
				                },
				                {
				                    "label": "01/03/2015"
				                }
				            ]
				        }
				    ],
				    "dataset": [
				        {
				            "seriesname": "Matrículas",
				            "color": "1D8BD1",
				            "anchorbordercolor": "1D8BD1",
				            "anchorbgcolor": "1D8BD1",
				            "data": [
				                {
				                    "value": "50"
				                },
				                {
				                    "value": "20"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "50"
				                }
				            ]
				        },
				        {
				            "seriesname": "Transferências",
				            "color": "F1683C",
				            "anchorbordercolor": "F1683C",
				            "anchorbgcolor": "F1683C",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "90"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "100"
				                }
				            ]
				        },
				        {
				            "seriesname": "Deixaram de frequentar",
				            "color": "2AD62A",
				            "anchorbordercolor": "2AD62A",
				            "anchorbgcolor": "2AD62A",
				            "data": [
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "12"
				                }
				            ]
				        },
				        {
				            "seriesname": "Falecimento",
				            "color": "DBDC25",
				            "anchorbordercolor": "DBDC25",
				            "anchorbgcolor": "DBDC25",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "1"
				                }
				            ]
				        },
				        {
				            "seriesname": "Canceladas",
				            "color": "6d25bd",
				            "anchorbordercolor": "6d25bd",
				            "anchorbgcolor": "6d25bd",
				            "data": [
				                {
				                    "value": "2"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "10"
				                },
				                {
				                    "value": "5"
				                }
				            ]
				        }
				    ]
		        }
		    }).render();
		    var hourlySalesChart4 = new FusionCharts({
		        type: 'msline',
		        renderAt: 'chart-container-linha4',
		        width: '100%',
		        height: '350',
		        dataFormat: 'json',
		        dataSource: {
		        "chart": {
				        "caption": "Matriz - Educação infantil",
				        "subcaption": "Período: 01/02/2015 a 01/03/2015",
				        "linethickness": "1",
				        "showvalues": "0",
				        "formatnumberscale": "0",
				        "anchorradius": "2",
				        "divlinecolor": "666666",
				        "divlinealpha": "30",
				        "divlineisdashed": "1",
				        "bgcolor": "FFFFFF",
				        "showalternatehgridcolor": "0",
				        "labelpadding": "10",
				        "canvasborderthickness": "1",
				        "legendiconscale": "1.5",
				        "legendshadow": "0",
				        "legendborderalpha": "0",
				        "legendposition": "right",
				        "canvasborderalpha": "50",
				        "numvdivlines": "5",
				        "vdivlinealpha": "20",
				        "showborder": "0"
				    },
				    "categories": [
				        {
				            "category": [
				                {
				                    "label": "31/01/2015"
				                },
				                {
				                    "label": "10/02/2015"
				                },
				                {
				                    "label": "20/02/2015"
				                },
				                {
				                    "label": "01/03/2015"
				                }
				            ]
				        }
				    ],
				    "dataset": [
				        {
				            "seriesname": "Matrículas",
				            "color": "1D8BD1",
				            "anchorbordercolor": "1D8BD1",
				            "anchorbgcolor": "1D8BD1",
				            "data": [
				                {
				                    "value": "50"
				                },
				                {
				                    "value": "20"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "50"
				                }
				            ]
				        },
				        {
				            "seriesname": "Transferências",
				            "color": "F1683C",
				            "anchorbordercolor": "F1683C",
				            "anchorbgcolor": "F1683C",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "90"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "100"
				                }
				            ]
				        },
				        {
				            "seriesname": "Deixaram de frequentar",
				            "color": "2AD62A",
				            "anchorbordercolor": "2AD62A",
				            "anchorbgcolor": "2AD62A",
				            "data": [
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "12"
				                }
				            ]
				        },
				        {
				            "seriesname": "Falecimento",
				            "color": "DBDC25",
				            "anchorbordercolor": "DBDC25",
				            "anchorbgcolor": "DBDC25",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "1"
				                }
				            ]
				        },
				        {
				            "seriesname": "Canceladas",
				            "color": "6d25bd",
				            "anchorbordercolor": "6d25bd",
				            "anchorbgcolor": "6d25bd",
				            "data": [
				                {
				                    "value": "2"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "10"
				                },
				                {
				                    "value": "5"
				                }
				            ]
				        }
				    ]
		        }
		    }).render();
		    var hourlySalesChart5 = new FusionCharts({
		        type: 'msline',
		        renderAt: 'chart-container-linha5',
		        width: '100%',
		        height: '350',
		        dataFormat: 'json',
		        dataSource: {
		        "chart": {
				        "caption": "Matriz - Educação fundamental",
				        "subcaption": "Período: 01/02/2015 a 01/03/2015",
				        "linethickness": "1",
				        "showvalues": "0",
				        "formatnumberscale": "0",
				        "anchorradius": "2",
				        "divlinecolor": "666666",
				        "divlinealpha": "30",
				        "divlineisdashed": "1",
				        "bgcolor": "FFFFFF",
				        "showalternatehgridcolor": "0",
				        "labelpadding": "10",
				        "canvasborderthickness": "1",
				        "legendiconscale": "1.5",
				        "legendshadow": "0",
				        "legendborderalpha": "0",
				        "legendposition": "right",
				        "canvasborderalpha": "50",
				        "numvdivlines": "5",
				        "vdivlinealpha": "20",
				        "showborder": "0"
				    },
				    "categories": [
				        {
				            "category": [
				                {
				                    "label": "31/01/2015"
				                },
				                {
				                    "label": "10/02/2015"
				                },
				                {
				                    "label": "20/02/2015"
				                },
				                {
				                    "label": "01/03/2015"
				                }
				            ]
				        }
				    ],
				    "dataset": [
				        {
				            "seriesname": "Matrículas",
				            "color": "1D8BD1",
				            "anchorbordercolor": "1D8BD1",
				            "anchorbgcolor": "1D8BD1",
				            "data": [
				                {
				                    "value": "50"
				                },
				                {
				                    "value": "20"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "50"
				                }
				            ]
				        },
				        {
				            "seriesname": "Transferências",
				            "color": "F1683C",
				            "anchorbordercolor": "F1683C",
				            "anchorbgcolor": "F1683C",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "90"
				                },
				                {
				                    "value": "80"
				                },
				                {
				                    "value": "100"
				                }
				            ]
				        },
				        {
				            "seriesname": "Deixaram de frequentar",
				            "color": "2AD62A",
				            "anchorbordercolor": "2AD62A",
				            "anchorbgcolor": "2AD62A",
				            "data": [
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "4"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "12"
				                }
				            ]
				        },
				        {
				            "seriesname": "Falecimento",
				            "color": "DBDC25",
				            "anchorbordercolor": "DBDC25",
				            "anchorbgcolor": "DBDC25",
				            "data": [
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "0"
				                },
				                {
				                    "value": "1"
				                }
				            ]
				        },
				        {
				            "seriesname": "Canceladas",
				            "color": "6d25bd",
				            "anchorbordercolor": "6d25bd",
				            "anchorbgcolor": "6d25bd",
				            "data": [
				                {
				                    "value": "2"
				                },
				                {
				                    "value": "3"
				                },
				                {
				                    "value": "10"
				                },
				                {
				                    "value": "5"
				                }
				            ]
				        }
				    ]
		        }
		    }).render();
		    
		});

		FusionCharts.ready(function () {
		    var topProductsChart = new FusionCharts({
		        type: 'multilevelpie',
		        renderAt: 'chart-container-pizza',
		        id : "myChart",
		        width: '500',
		        height: '500',
		        dataFormat: 'json',
		        dataSource: {
		            "chart": {
		                "caption": "Movimentação Mensal",
		                "subCaption": "Período: 01/02/2015 a 31/03/2015",
		                "captionFontSize": "14",
		                "subcaptionFontSize": "14",
		                "baseFontColor" : "#333333",
		                "baseFont" : "Helvetica Neue,Arial",   
		                "basefontsize": "9",
		                "subcaptionFontBold": "0",
		                "bgColor" : "#ffffff",
		                "canvasBgColor" : "#ffffff",
		                "showBorder" : "0",
		                "showShadow" : "0",
		                "showCanvasBorder": "0",
		                "pieFillAlpha": "60",
		                "pieBorderThickness": "2",
		                "hoverFillColor": "#cccccc",
		                "pieBorderColor": "#ffffff",
		                "useHoverColor": "1",
		                "showValuesInTooltip": "1",
		                "showPercentInTooltip": "0",
		                "numberPrefix": "$",
		                "plotTooltext": "$label, $$valueK, $percentValue"
		            },
		            "category": [
		                {
		                    "label": "Movimentação por mês",
		                    "color": "#ffffff",
		                    "value": "150",
		                    "category": [
		                        {
		                            "label": "Matrículas",
		                            "color": "#f8bd19",
		                            "value": "55.5",
		                            "category": [
		                                {
		                                    "label": "Readimissões",
		                                    "color": "#f8bd19",
		                                    "value": "11.1"
		                                },
		                                {
		                                    "label": "Remanejadas",
		                                    "color": "#f8bd19",
		                                    "value": "27.75"
		                                },
		                                {
		                                    "label": "Reclassificações",
		                                    "color": "#f8bd19",
		                                    "value": "9.99"
		                                },
		                                {
		                                    "label": "Novas matrículas",
		                                    "color": "#f8bd19",
		                                    "value": "6.66"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Transferências",
		                            "color": "#e44a00",
		                            "value": "42",
		                            "category": [
		                                {
		                                    "label": "Sem adaptação",
		                                    "color": "#e44a00",
		                                    "value": "10.08"
		                                },
		                                {
		                                    "label": "Deslocamento ruim",
		                                    "color": "#e44a00",
		                                    "value": "18.9"
		                                },
		                                {
		                                    "label": "Não informado",
		                                    "color": "#e44a00",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Troca de endereço",
		                                    "color": "#e44a00",
		                                    "value": "6.72"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Deixaram de frequentar",
		                            "color": "#008ee4",
		                            "value": "22.5",
		                            "category": [
		                                {
		                                    "label": "Gravidez",
		                                    "color": "#008ee4",
		                                    "value": "9.45"
		                                },
		                                {
		                                    "label": "Doença",
		                                    "color": "#008ee4",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Drogas",
		                                    "color": "#008ee4",
		                                    "value": "6.75"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Cancelamento",
		                            "color": "#33bdda",
		                            "value": "30",
		                            "category": [
		                                {
		                                    "label": "Cansado",
		                                    "color": "#33bdda",
		                                    "value": "8.1"
		                                },
		                                {
		                                    "label": "Trabalho",
		                                    "color": "#33bdda",
		                                    "value": "10.5"
		                                },
		                                {
		                                    "label": "Internado",
		                                    "color": "#33bdda",
		                                    "value": "11.4"
		                                }
		                            ]
		                        }
		                    ]
		                }
		            ]
		        }
		    });
		    var topProductsChart2 = new FusionCharts({
		        type: 'multilevelpie',
		        renderAt: 'chart-container-pizza-estabelecimento1',
		        id : "myChart2",
		        width: '500',
		        height: '500',
		        dataFormat: 'json',
		        dataSource: {
		            "chart": {
		                "caption": "Movimentação Mensal",
		                "subCaption": "Período: 01/02/2015 a 31/03/2015",
		                "captionFontSize": "14",
		                "subcaptionFontSize": "14",
		                "baseFontColor" : "#333333",
		                "baseFont" : "Helvetica Neue,Arial",   
		                "basefontsize": "9",
		                "subcaptionFontBold": "0",
		                "bgColor" : "#ffffff",
		                "canvasBgColor" : "#ffffff",
		                "showBorder" : "0",
		                "showShadow" : "0",
		                "showCanvasBorder": "0",
		                "pieFillAlpha": "60",
		                "pieBorderThickness": "2",
		                "hoverFillColor": "#cccccc",
		                "pieBorderColor": "#ffffff",
		                "useHoverColor": "1",
		                "showValuesInTooltip": "1",
		                "showPercentInTooltip": "0",
		                "numberPrefix": "$",
		                "plotTooltext": "$label, $$valueK, $percentValue"
		            },
		            "category": [
		                {
		                    "label": "Movimentação por mês",
		                    "color": "#ffffff",
		                    "value": "150",
		                    "category": [
		                        {
		                            "label": "Matrículas",
		                            "color": "#f8bd19",
		                            "value": "55.5",
		                            "category": [
		                                {
		                                    "label": "Readimissões",
		                                    "color": "#f8bd19",
		                                    "value": "11.1"
		                                },
		                                {
		                                    "label": "Remanejadas",
		                                    "color": "#f8bd19",
		                                    "value": "27.75"
		                                },
		                                {
		                                    "label": "Reclassificações",
		                                    "color": "#f8bd19",
		                                    "value": "9.99"
		                                },
		                                {
		                                    "label": "Novas matrículas",
		                                    "color": "#f8bd19",
		                                    "value": "6.66"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Transferências",
		                            "color": "#e44a00",
		                            "value": "42",
		                            "category": [
		                                {
		                                    "label": "Sem adaptação",
		                                    "color": "#e44a00",
		                                    "value": "10.08"
		                                },
		                                {
		                                    "label": "Deslocamento ruim",
		                                    "color": "#e44a00",
		                                    "value": "18.9"
		                                },
		                                {
		                                    "label": "Não informado",
		                                    "color": "#e44a00",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Troca de endereço",
		                                    "color": "#e44a00",
		                                    "value": "6.72"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Deixaram de frequentar",
		                            "color": "#008ee4",
		                            "value": "22.5",
		                            "category": [
		                                {
		                                    "label": "Gravidez",
		                                    "color": "#008ee4",
		                                    "value": "9.45"
		                                },
		                                {
		                                    "label": "Doença",
		                                    "color": "#008ee4",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Drogas",
		                                    "color": "#008ee4",
		                                    "value": "6.75"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Cancelamento",
		                            "color": "#33bdda",
		                            "value": "30",
		                            "category": [
		                                {
		                                    "label": "Cansado",
		                                    "color": "#33bdda",
		                                    "value": "8.1"
		                                },
		                                {
		                                    "label": "Trabalho",
		                                    "color": "#33bdda",
		                                    "value": "10.5"
		                                },
		                                {
		                                    "label": "Internado",
		                                    "color": "#33bdda",
		                                    "value": "11.4"
		                                }
		                            ]
		                        }
		                    ]
		                }
		            ]
		        }
		    });
		    var topProductsChart3 = new FusionCharts({
		        type: 'multilevelpie',
		        renderAt: 'chart-container-pizza-estabelecimento2',
		        id : "myChart3",
		        width: '500',
		        height: '500',
		        dataFormat: 'json',
		        dataSource: {
		            "chart": {
		                "caption": "Movimentação Mensal",
		                "subCaption": "Período: 01/02/2015 a 31/03/2015",
		                "captionFontSize": "14",
		                "subcaptionFontSize": "14",
		                "baseFontColor" : "#333333",
		                "baseFont" : "Helvetica Neue,Arial",   
		                "basefontsize": "9",
		                "subcaptionFontBold": "0",
		                "bgColor" : "#ffffff",
		                "canvasBgColor" : "#ffffff",
		                "showBorder" : "0",
		                "showShadow" : "0",
		                "showCanvasBorder": "0",
		                "pieFillAlpha": "60",
		                "pieBorderThickness": "2",
		                "hoverFillColor": "#cccccc",
		                "pieBorderColor": "#ffffff",
		                "useHoverColor": "1",
		                "showValuesInTooltip": "1",
		                "showPercentInTooltip": "0",
		                "numberPrefix": "$",
		                "plotTooltext": "$label, $$valueK, $percentValue"
		            },
		            "category": [
		                {
		                    "label": "Movimentação por mês",
		                    "color": "#ffffff",
		                    "value": "150",
		                    "category": [
		                        {
		                            "label": "Matrículas",
		                            "color": "#f8bd19",
		                            "value": "55.5",
		                            "category": [
		                                {
		                                    "label": "Readimissões",
		                                    "color": "#f8bd19",
		                                    "value": "11.1"
		                                },
		                                {
		                                    "label": "Remanejadas",
		                                    "color": "#f8bd19",
		                                    "value": "27.75"
		                                },
		                                {
		                                    "label": "Reclassificações",
		                                    "color": "#f8bd19",
		                                    "value": "9.99"
		                                },
		                                {
		                                    "label": "Novas matrículas",
		                                    "color": "#f8bd19",
		                                    "value": "6.66"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Transferências",
		                            "color": "#e44a00",
		                            "value": "42",
		                            "category": [
		                                {
		                                    "label": "Sem adaptação",
		                                    "color": "#e44a00",
		                                    "value": "10.08"
		                                },
		                                {
		                                    "label": "Deslocamento ruim",
		                                    "color": "#e44a00",
		                                    "value": "18.9"
		                                },
		                                {
		                                    "label": "Não informado",
		                                    "color": "#e44a00",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Troca de endereço",
		                                    "color": "#e44a00",
		                                    "value": "6.72"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Deixaram de frequentar",
		                            "color": "#008ee4",
		                            "value": "22.5",
		                            "category": [
		                                {
		                                    "label": "Gravidez",
		                                    "color": "#008ee4",
		                                    "value": "9.45"
		                                },
		                                {
		                                    "label": "Doença",
		                                    "color": "#008ee4",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Drogas",
		                                    "color": "#008ee4",
		                                    "value": "6.75"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Cancelamento",
		                            "color": "#33bdda",
		                            "value": "30",
		                            "category": [
		                                {
		                                    "label": "Cansado",
		                                    "color": "#33bdda",
		                                    "value": "8.1"
		                                },
		                                {
		                                    "label": "Trabalho",
		                                    "color": "#33bdda",
		                                    "value": "10.5"
		                                },
		                                {
		                                    "label": "Internado",
		                                    "color": "#33bdda",
		                                    "value": "11.4"
		                                }
		                            ]
		                        }
		                    ]
		                }
		            ]
		        }
		    });
		    var topProductsChart4 = new FusionCharts({
		        type: 'multilevelpie',
		        renderAt: 'chart-container-pizza-matriz1',
		        id : "myChart4",
		        width: '500',
		        height: '500',
		        dataFormat: 'json',
		        dataSource: {
		            "chart": {
		                "caption": "Movimentação Mensal",
		                "subCaption": "Período: 01/02/2015 a 31/03/2015",
		                "captionFontSize": "14",
		                "subcaptionFontSize": "14",
		                "baseFontColor" : "#333333",
		                "baseFont" : "Helvetica Neue,Arial",   
		                "basefontsize": "9",
		                "subcaptionFontBold": "0",
		                "bgColor" : "#ffffff",
		                "canvasBgColor" : "#ffffff",
		                "showBorder" : "0",
		                "showShadow" : "0",
		                "showCanvasBorder": "0",
		                "pieFillAlpha": "60",
		                "pieBorderThickness": "2",
		                "hoverFillColor": "#cccccc",
		                "pieBorderColor": "#ffffff",
		                "useHoverColor": "1",
		                "showValuesInTooltip": "1",
		                "showPercentInTooltip": "0",
		                "numberPrefix": "$",
		                "plotTooltext": "$label, $$valueK, $percentValue"
		            },
		            "category": [
		                {
		                    "label": "Movimentação por mês",
		                    "color": "#ffffff",
		                    "value": "150",
		                    "category": [
		                        {
		                            "label": "Matrículas",
		                            "color": "#f8bd19",
		                            "value": "55.5",
		                            "category": [
		                                {
		                                    "label": "Readimissões",
		                                    "color": "#f8bd19",
		                                    "value": "11.1"
		                                },
		                                {
		                                    "label": "Remanejadas",
		                                    "color": "#f8bd19",
		                                    "value": "27.75"
		                                },
		                                {
		                                    "label": "Reclassificações",
		                                    "color": "#f8bd19",
		                                    "value": "9.99"
		                                },
		                                {
		                                    "label": "Novas matrículas",
		                                    "color": "#f8bd19",
		                                    "value": "6.66"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Transferências",
		                            "color": "#e44a00",
		                            "value": "42",
		                            "category": [
		                                {
		                                    "label": "Sem adaptação",
		                                    "color": "#e44a00",
		                                    "value": "10.08"
		                                },
		                                {
		                                    "label": "Deslocamento ruim",
		                                    "color": "#e44a00",
		                                    "value": "18.9"
		                                },
		                                {
		                                    "label": "Não informado",
		                                    "color": "#e44a00",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Troca de endereço",
		                                    "color": "#e44a00",
		                                    "value": "6.72"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Deixaram de frequentar",
		                            "color": "#008ee4",
		                            "value": "22.5",
		                            "category": [
		                                {
		                                    "label": "Gravidez",
		                                    "color": "#008ee4",
		                                    "value": "9.45"
		                                },
		                                {
		                                    "label": "Doença",
		                                    "color": "#008ee4",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Drogas",
		                                    "color": "#008ee4",
		                                    "value": "6.75"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Cancelamento",
		                            "color": "#33bdda",
		                            "value": "30",
		                            "category": [
		                                {
		                                    "label": "Cansado",
		                                    "color": "#33bdda",
		                                    "value": "8.1"
		                                },
		                                {
		                                    "label": "Trabalho",
		                                    "color": "#33bdda",
		                                    "value": "10.5"
		                                },
		                                {
		                                    "label": "Internado",
		                                    "color": "#33bdda",
		                                    "value": "11.4"
		                                }
		                            ]
		                        }
		                    ]
		                }
		            ]
		        }
		    });
		    var topProductsChart5 = new FusionCharts({
		        type: 'multilevelpie',
		        renderAt: 'chart-container-pizza-matriz2',
		        id : "myChart5",
		        width: '500',
		        height: '500',
		        dataFormat: 'json',
		        dataSource: {
		            "chart": {
		                "caption": "Movimentação Mensal",
		                "subCaption": "Período: 01/02/2015 a 31/03/2015",
		                "captionFontSize": "14",
		                "subcaptionFontSize": "14",
		                "baseFontColor" : "#333333",
		                "baseFont" : "Helvetica Neue,Arial",   
		                "basefontsize": "9",
		                "subcaptionFontBold": "0",
		                "bgColor" : "#ffffff",
		                "canvasBgColor" : "#ffffff",
		                "showBorder" : "0",
		                "showShadow" : "0",
		                "showCanvasBorder": "0",
		                "pieFillAlpha": "60",
		                "pieBorderThickness": "2",
		                "hoverFillColor": "#cccccc",
		                "pieBorderColor": "#ffffff",
		                "useHoverColor": "1",
		                "showValuesInTooltip": "1",
		                "showPercentInTooltip": "0",
		                "numberPrefix": "$",
		                "plotTooltext": "$label, $$valueK, $percentValue"
		            },
		            "category": [
		                {
		                    "label": "Movimentação por mês",
		                    "color": "#ffffff",
		                    "value": "150",
		                    "category": [
		                        {
		                            "label": "Matrículas",
		                            "color": "#f8bd19",
		                            "value": "55.5",
		                            "category": [
		                                {
		                                    "label": "Readimissões",
		                                    "color": "#f8bd19",
		                                    "value": "11.1"
		                                },
		                                {
		                                    "label": "Remanejadas",
		                                    "color": "#f8bd19",
		                                    "value": "27.75"
		                                },
		                                {
		                                    "label": "Reclassificações",
		                                    "color": "#f8bd19",
		                                    "value": "9.99"
		                                },
		                                {
		                                    "label": "Novas matrículas",
		                                    "color": "#f8bd19",
		                                    "value": "6.66"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Transferências",
		                            "color": "#e44a00",
		                            "value": "42",
		                            "category": [
		                                {
		                                    "label": "Sem adaptação",
		                                    "color": "#e44a00",
		                                    "value": "10.08"
		                                },
		                                {
		                                    "label": "Deslocamento ruim",
		                                    "color": "#e44a00",
		                                    "value": "18.9"
		                                },
		                                {
		                                    "label": "Não informado",
		                                    "color": "#e44a00",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Troca de endereço",
		                                    "color": "#e44a00",
		                                    "value": "6.72"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Deixaram de frequentar",
		                            "color": "#008ee4",
		                            "value": "22.5",
		                            "category": [
		                                {
		                                    "label": "Gravidez",
		                                    "color": "#008ee4",
		                                    "value": "9.45"
		                                },
		                                {
		                                    "label": "Doença",
		                                    "color": "#008ee4",
		                                    "value": "6.3"
		                                },
		                                {
		                                    "label": "Drogas",
		                                    "color": "#008ee4",
		                                    "value": "6.75"
		                                }
		                            ]
		                        },
		                        {
		                            "label": "Cancelamento",
		                            "color": "#33bdda",
		                            "value": "30",
		                            "category": [
		                                {
		                                    "label": "Cansado",
		                                    "color": "#33bdda",
		                                    "value": "8.1"
		                                },
		                                {
		                                    "label": "Trabalho",
		                                    "color": "#33bdda",
		                                    "value": "10.5"
		                                },
		                                {
		                                    "label": "Internado",
		                                    "color": "#33bdda",
		                                    "value": "11.4"
		                                }
		                            ]
		                        }
		                    ]
		                }
		            ]
		        }
		    });
		    
		    topProductsChart.render();
		    topProductsChart2.render();
		    topProductsChart3.render();
		    topProductsChart4.render();
		    topProductsChart5.render();
		});

		$scope.graficoPadrao = {
			geral:[
				{
					matricula: 30,
					// matriculaNova: 2,
					matriculaPorcentagem: '91,17%',
					transferencia: 100,
					transferenciaPorcentagem: '7,29%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '0,88%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '0,07%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '0,36%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '0,22%'
				}
			],
		    chart: {
		        caption: "Rede municipal XPTO",
		        subCaption: "Período: 01/02/2015 a 01/03/2015",
		        theme: "ocean",
		        "formatNumberScale": "0",
		        "placeValuesInside": "0",
		        "rotateValues": "0",
		        "valueFontColor": "#000000",
		        "decimals": "0",
		        "forceDecimals": "1",
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30,
		        percentage: 10
		    },
		    // {
		    //     label: "Novas matrículas",
		    //     value: 2,
		    //     percentage: 10
		    // },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // }
		    ]
		};

		$rootScope.graficoEstabelecimentoEnsino1 = {
			geral:[
				{
					nome: "E.E.B. Padre Miguel Giacca",
					matricula: 30,
					// matriculaNova: 2,
					matriculaPorcentagem: '91,17%',
					transferencia: 100,
					transferenciaPorcentagem: '7,29%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '0,88%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '0,07%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '0,36%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '0,22%'
				}
			],
		    chart: {
		        caption: "E.E.B. Padre Miguel Giacca",
		        subCaption: "Período: 01/02/2015 a 01/03/2015",
		        theme: "ocean",
		        "formatNumberScale": "0",
		        "placeValuesInside": "0",
		        "rotateValues": "0",
		        "valueFontColor": "#000000",
		        "decimals": "0",
		        "forceDecimals": "1",
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    // {
		    //     label: "Novas matrículas",
		    //     value: 2
		    // },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};

		$rootScope.graficoEstabelecimentoEnsino2 = {
			geral:[
				{
					nome: "E.E.B. Filho do Mineiro",
					matricula: 30,
					// matriculaNova: 2,
					matriculaPorcentagem: '91,17%',
					transferencia: 100,
					transferenciaPorcentagem: '7,29%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '0,88%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '0,07%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '0,36%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '0,22%'
				}
			],
		    chart: {
		        caption: "E.E.B. Filho do Mineiro",
		        subCaption: "Período: 01/02/2015 a 01/03/2015",
		        theme: "ocean",
		        "formatNumberScale": "0",
		        "placeValuesInside": "0",
		        "rotateValues": "0",
		        "valueFontColor": "#000000",
		        "decimals": "0",
		        "forceDecimals": "1",
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    // {
		    //     label: "Novas matrículas",
		    //     value: 2
		    // },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};

		$rootScope.graficoMatriz1 = {
			geral:[
				{
					nome: "Matriz - Educação infantil",
					matricula: 30,
					// matriculaNova: 2,
					matriculaPorcentagem: '91,17%',
					transferencia: 100,
					transferenciaPorcentagem: '7,29%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '0,88%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '0,07%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '0,36%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '0,22%'
				}
			],
		    chart: {
		        caption: "Matriz - Educação infantil",
		        subCaption: "Período: 01/02/2015 a 01/03/2015",
		        theme: "ocean",
		        "formatNumberScale": "0",
		        "placeValuesInside": "0",
		        "rotateValues": "0",
		        "valueFontColor": "#000000",
		        "decimals": "0",
		        "forceDecimals": "1",
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    // {
		    //     label: "Novas matrículas",
		    //     value: 2
		    // },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};

		$rootScope.graficoMatriz2 = {
			geral:[
				{
					nome: "Matriz - Educação fundamental",
					matricula: 30,
					// matriculaNova: 2,
					matriculaPorcentagem: '91,17%',
					transferencia: 100,
					transferenciaPorcentagem: '7,29%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '0,88%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '0,07%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '0,36%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '0,22%'
				}
			],
		    chart: {
		        caption: "Matriz - Educação fundamental",
		        subCaption: "Período: 01/02/2015 a 01/03/2015",
		        theme: "ocean",
		        "formatNumberScale": "0",
		        "placeValuesInside": "0",
		        "rotateValues": "0",
		        "valueFontColor": "#000000",
		        "decimals": "0",
		        "forceDecimals": "1",
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    // {
		    //     label: "Novas matrículas",
		    //     value: 2
		    // },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};

		$scope.filtro = false;
		$scope.filtroOpen = false;
		$scope.filtroF = function(){
			return $scope.filtro = true;
		}
		$scope.filtroOpenF = function(){
			return $scope.filtroOpen = true;
		}

		$scope.ativarPorcentagem = function(){
			$scope.activePorcentagem = $scope.activePorcentagem == true ? false : true;
			$scope.colMatriculaPorcentagemIsVisible = $scope.colMatriculaPorcentagemIsVisible == true ? false : true;
			$scope.colTransferenciasPorcentagemIsVisible = $scope.colTransferenciasPorcentagemIsVisible == true ? false : true;
			$scope.colMqddFrequentarPorcentagemIsVisible = $scope.colMqddFrequentarPorcentagemIsVisible == true ? false : true;
		}

		$scope.showMore = function(i) {
			i.showMore = !i.showMore;
		};

		$scope.numberOfResults = 10;

		$scope.allChecked = false;

		$scope.lineHeight = "1";
		$scope.colMatriculaFilter = true;
		$scope.colMatrizFilter = true;
		$scope.colEstabelecimentoEnsinoFilter = true;
		$scope.colTransferenciasFilter = true;
		$scope.colMqddFrequentarFilter = true;
		$scope.colMatriculaIsVisible = true;
		$scope.colMatrizIsVisible = true;
		$scope.colEstabelecimentoEnsinoIsVisible = true;
		$scope.colTransferenciasIsVisible = true;
		$scope.colMqddFrequentarIsVisible = true;
		$scope.colMatriculasFalecimentoIsVisible = true;
		$scope.colMatriculasCanceladasIsVisible = true;
		$scope.colMatriculasReclassificadasIsVisible = true;
		$scope.colMatriculasReadmitidasIsVisible = true;
		$scope.colMatriculasRemanejadasIsVisible = true;
		$scope.isSelected = false;
		$scope.tipoPesquisa = '1';
		$scope.agrupamento = '1';

		$scope.checkAll = function(){
			$scope.allChecked = !$scope.allChecked;
			for (var i = 0; i < $scope.graficoPadrao.length; i++) {
				$scope.graficoPadrao[i].checked = $scope.allChecked;
			};
			$scope.isAnySelected();
		}

		$scope.isAnySelected = function(){
			for (var i = 0; i < $scope.graficoPadrao.length; i++) {
				if($scope.graficoPadrao[i].checked){
					$scope.isSelected = true;
					return;
				}
				$scope.isSelected = false;
			};
		};

		$scope.setLineHeight = function(h){
			$scope.lineHeight = h;
		}

		$scope.setAgrupamento = function(a){
			$scope.agrupamento = a;
		}


		$scope.openAddDependencia = function(id) {
			ModalCad.open({
				templateUrl: 'prototipo/dependencias/dependencias.cad.html',
				controller: 'educacao.prototipo.DependenciasCadCtrl',
				id: id,
				readOnly: false
			});
		};

		$scope.salvarPesquisa = function() {
			ModalCad.open({
				templateUrl: 'common/genericos/salvar-pesquisa.html?uniq' + new Date(),
				controller: 'SalvarPesquisaCtrl'
			});
		};

		$scope.termosPesquisados = [];

		$scope.removerTermo = function(t, e){
			var index = $scope.termosPesquisados.indexOf(t);
			
			$('li').removeClass("animated-panel");
			$scope.termosPesquisados.splice(index, 1);
		}

		$scope.limparPesquisa = function(){
			$scope.termosPesquisados = [];
		};

		$scope.$watch("viewContentLoaded", function(){
			setTimeout(function(){

				var options = [];
				$( '.dropdown-menu .bth-radio' ).on( 'click', function(e) {
					e.stopPropagation();
				});

				$( '.dropdown-menu a' ).on( 'click', function( event ) {

				   var $target = $( event.currentTarget ),
				       val = $target.attr( 'data-value' ),
				       $inp = $target.find( 'input' ),
				       idx;

				   if ( ( idx = options.indexOf( val ) ) > -1 ) {
				      options.splice( idx, 1 );
				      setTimeout( function() { $inp.prop( 'checked', true ) }, 0);
				   } else {
				      options.push( val );
				      setTimeout( function() { $inp.prop( 'checked', false ) }, 0);
				   }

				   $( event.target ).blur();
				      
				   return false;
				});


				$("#filtroColunas").select2('val', ['1', '2', '3']);

				$('table').dragtable();


				$("#tagsSelect").select2('val', ['1', '2', '3']);

				// passing a fixedOffset param will cause the table header to stick to the bottom of this element
				$("table").stickyTableHeaders({ fixedOffset: $('.megamenu-container') });
				$('.destroy').on('click', function(e){
					$(".first table").stickyTableHeaders('destroy');
				});
				$('.apply').on('click', function(e){
					$('.first table').stickyTableHeaders({fixedOffset: $('.header')});
				});

				$("#searchInput").on("keypress", function(e){
					if(e.keyCode == 13) {
						if($(e.target).val().length > 0) {
							$scope.termosPesquisados.push($(e.target).val());
							$(e.target).val("");
							$scope.$apply();
						}
					}
				})
			}, 200);
		});


	}]);

	moduloCommon.controller('educacao.prototipo.AmpliarGraficoCadCtrl', ['$scope', '$timeout','educacao.common.ModalCad', 'bfc.Select2Config', 'id', '$rootScope',
		function($scope, $timeout, ModalCad,Select2Config, id, $rootScope
		) {
			$scope.dependencias = {};

			 /*Campos com selec2 como select*/


		$scope.ag = id;

		$scope.graficoEstabelecimentoEnsino1 = {
			geral:[
				{
					nome: "E.E.B. Padre Miguel Giacca",
					matricula: 30,
					matriculaPorcentagem: '100%',
					transferencia: 100,
					transferenciaPorcentagem: '9%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '1%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '1%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '1%',
					matriculasReclassificadas: 3,
					matriculasReclassificadasPorcentagem: '1%',
					// matriculasReadmitidas: 4,
					// matriculasReadmitidasPorcentagem: '1%',
					// matriculasRemanejadas: 7,
					// matriculasRemanejadasPorcentagem: '1%',
				}
			],
		    chart: {
		        caption: "E.E.B. Padre Miguel Giacca",
		        // subCaption: "Top 5 stores in last month by revenue",
		        theme: "ocean"
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};
		$scope.graficoEstabelecimentoEnsino2 = {
			geral:[
				{
					nome: "E.E.B. Filho do Mineiro",
					matricula: 30,
					matriculaPorcentagem: '100%',
					transferencia: 100,
					transferenciaPorcentagem: '9%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '1%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '1%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '1%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '1%',
					// matriculasReadmitidas: 4,
					// matriculasReadmitidasPorcentagem: '1%',
					// matriculasRemanejadas: 7,
					// matriculasRemanejadasPorcentagem: '1%',
				}
			],
		    chart: {
		        caption: "E.E.B. Filho do Mineiro",
		        // subCaption: "Top 5 stores in last month by revenue",
		        theme: "ocean"
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};

		$scope.graficoMatriz1 = {
			geral:[
				{
					nome: "Matriz 1",
					matricula: 30,
					matriculaPorcentagem: '100%',
					transferencia: 100,
					transferenciaPorcentagem: '9%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '1%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '1%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '1%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '1%',
					// matriculasReadmitidas: 4,
					// matriculasReadmitidasPorcentagem: '1%',
					// matriculasRemanejadas: 7,
					// matriculasRemanejadasPorcentagem: '1%',
				}
			],
		    chart: {
		        caption: "Matriz 1",
		        // subCaption: "Top 5 stores in last month by revenue",
		        theme: "ocean"
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};
		$scope.graficoMatriz2 = {
			geral:[
				{
					nome: "Matriz 2",
					matricula: 30,
					matriculaPorcentagem: '100%',
					transferencia: 100,
					transferenciaPorcentagem: '9%',
					mqddFrequentar: 12,
					mqddFrequentarPorcentagem: '1%',
					matriculasFalecimento: 1,
					matriculasFalecimentoPorcentagem: '1%',
					matriculasCanceladas: 5,
					matriculasCanceladasPorcentagem: '1%',
					// matriculasReclassificadas: 3,
					// matriculasReclassificadasPorcentagem: '1%',
					// matriculasReadmitidas: 4,
					// matriculasReadmitidasPorcentagem: '1%',
					// matriculasRemanejadas: 7,
					// matriculasRemanejadasPorcentagem: '1%',
				}
			],
		    chart: {
		        caption: "Matriz 2",
		        // subCaption: "Top 5 stores in last month by revenue",
		        theme: "ocean"
		    },
		    data:[{
		        label: "Matrículas",
		        value: 30
		    },
		    {
		        label: "Transferências",
		        value: 50
		    },
		    {
		        label: "Deixaram de frequentar",
		        value: 12
		    },
		    {
		        label: "Falecimento",
		        value: 1
		    },
		    {
		        label: "Canceladas",
		        value: 5
		    },
		    // {
		    //     label: "Reclassificadas",
		    //     value: 3
		    // },
		    // {
		    //     label: "Readmitidas",
		    //     value: 4
		    // },
		    // {
		    //     label: "Remanejadas",
		    //     value: 7
		    // }
		    ]
		};

		
			 
	    $scope.$watch('$viewContentLoaded', function(){
		   	minimumResultsForSearch: -1,
		    setTimeout(function(){
		        $("#lbOcupacao").select2({
		            data: {
		                results: [
		                    {id: 1, text: 'Próprio'},
		                    {id: 2, text: 'Compartilhado'},
		                    {id: 3, text: 'Alugado'}, 
		                    {id: 3, text: 'Cedido'}
		                ]
		            }
		        });
		    }, 200);
		});

	   $scope.$watch('$viewContentLoaded', function(){
		   	minimumResultsForSearch: -1,
		    setTimeout(function(){
		        $("#lbStatus").select2({
		            data: {
		                results: [
		                    {id: 1, text: 'Disponível'},
		                    {id: 2, text: 'Indisponível'}
		                ]
		            }
		        });
		    }, 200);
		});

	}]);

	moduloCommon.controller('educacao.prototipo.DependenciasCadCtrl', ['$scope', '$timeout','educacao.common.ModalCad', 'bfc.Select2Config',
		function($scope, $timeout, ModalCad,Select2Config
		) {
			$scope.dependencias = {};

			$scope.adf = $rootScope.graficoMatriz1;

			console.log($scope.adf);

			 /*Campos com selec2 como select*/
			 
	   $scope.$watch('$viewContentLoaded', function(){
		   	minimumResultsForSearch: -1,
		    setTimeout(function(){
		        $("#lbOcupacao").select2({
		            data: {
		                results: [
		                    {id: 1, text: 'Próprio'},
		                    {id: 2, text: 'Compartilhado'},
		                    {id: 3, text: 'Alugado'}, 
		                    {id: 3, text: 'Cedido'}
		                ]
		            }
		        });
		    }, 200);
		});

	   $scope.$watch('$viewContentLoaded', function(){
		   	minimumResultsForSearch: -1,
		    setTimeout(function(){
		        $("#lbStatus").select2({
		            data: {
		                results: [
		                    {id: 1, text: 'Disponível'},
		                    {id: 2, text: 'Indisponível'}
		                ]
		            }
		        });
		    }, 200);
		});

	}]);

	moduloCommon.controller('educacao.prototipo.MotivosMovimentacaoCadCtrl', ['$scope', '$timeout','educacao.common.ModalCad', 'bfc.Select2Config','value',
		function($scope, $timeout, ModalCad,Select2Config, value
		) {
			$scope.title = value==1?"Transferências":(value==2?"Deixaram de frequentar":(value==3?"Falecimento":(value==4?"Canceladas":"Reclassificadas")));
			$scope.reclassificadas=value==5?true:false;

	        $scope.openMotivoMovimentacao = function(id) {
	            ModalCad.open({
	                templateUrl: 'matricula/matriculas/movimentacoes/movimentacao.cancelamento.cad.html',
	                controller: function($scope, $timeout){
	                    $scope.matriculaAlunoCadAluno = true;
	                    $scope.btnConcluir = true;
	                    $scope.editando = true;
	                    $scope.title = value==1?"Transferência":(value==2?"Deixou de frequentar":(value==3?"Falecimento":(value==4?"Cancelamento":"Reclassificar")));
	                }
	            });
	        };
	}]);

	moduloCommon.controller('educacao.prototipo.MatriculasEditCtrl', ['$scope', '$timeout','educacao.common.ModalCad', 'bfc.Select2Config','value',
		function($scope, $timeout, ModalCad,Select2Config, value
		) {
		
		$scope.title = value==1?"reclassificadas":(value==2?"readmitidas":(value==3?"novas":""));

		$scope.openMatriculasMovimentacao=function(){
			if(value==1){
				return ModalCad.open({
					templateUrl: 'matricula/matriculas/movimentacoes/movimentacao.reclassificacao.cad.html',
					controller: function($scope, $timeout){

					}
				});
			}if(value==2){
				return ModalCad.open({
					templateUrl: 'matricula/matriculas/movimentacoes/movimentacao.readmitir.cad.html',
					controller: function($scope, $timeout){

					}
				});
			}else{
				return ModalCad.open({
	                templateUrl: 'matricula/matriculas/matricula.cad.html',
	                controller: function($scope, $timeout){
	                    $scope.matriculaAlunoCadAluno = true;
	                    $scope.btnConcluir = true;
	                    $scope.editando = true;
	                    console.log($scope.editando);
	                }
	            });
			}
		}

	}]);

	moduloCommon.controller('educacao.prototipo.MatriculasNovasCadCtrl', ['$scope', '$timeout','educacao.common.ModalCad', 'bfc.Select2Config','value',
		function($scope, $timeout, ModalCad,Select2Config, value
		) {
			$scope.title = value==1?"Transferências":(value==2?"Deixaram de frequentar":(value==3?"Falecimento":(value==4?"Canceladas":"Reclassificadas")));
			$scope.reclassificadas=value==5?true:false;

	        $scope.openMotivoMovimentacao = function(id) {
	            ModalCad.open({
	                templateUrl: 'matricula/matriculas/movimentacoes/movimentacao.cancelamento.cad.html',
	                controller: function($scope, $timeout){
	                    $scope.matriculaAlunoCadAluno = true;
	                    $scope.btnConcluir = true;
	                    $scope.editando = true;
	                    $scope.title = value==1?"Transferência":(value==2?"Deixou de frequentar":(value==3?"Falecimento":(value==4?"Cancelamento":"Reclassificar")));
	                }
	            });
	        };
	}]);
})();	
/* jshint ignore:end */
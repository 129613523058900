(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosOutrosFuncionariosGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-outros-funcionarios-gestao-pessoas/table-dados-outros-funcionarios-gestao-pessoas/table-dados-outros-funcionarios-gestao-pessoas.html',
            scope: {},
            bindToController: {
                filter: '=',
                estabelecimentoSelected: '=',
                refresh: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        'promiseTracker',
        'educacao.context.ContextoEstabelecimento',
        'educacao.funcionario.FuncionarioService'


    ];

    function Controller($scope, QuadroFuncionariosGestaoPessoasService, promiseTracker, ContextoEstabelecimento, FuncionarioService) {
        var vm = this;

        FuncionarioService.getEnum('TipoVinculo').then(function (TipoVinculo) {
            vm.TipoVinculo = TipoVinculo;
        });
        vm.service = QuadroFuncionariosGestaoPessoasService;
        vm.listControls = {};
        vm.funcionarios = [];
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.loadListOutrosFuncionarios = loadListOutrosFuncionarios;
        vm.currentPage = 1;
        vm.itemsPerPage = 20;
        vm.optionsItemsPerPage = [20, 50, 100];
        vm.filtrarSubstitutosPorLocal = filtrarSubstitutosPorLocal;
        $scope.$watch('vm.filter', watcherFilter);
        $scope.$watch('vm.estabelecimentoSelected', onChangeContextEstabelecimento);
        $scope.$watch('vm.refresh', onRefhesh);
        vm.getDescriptionTipoVinculo = getDescriptionTipoVinculo;
        vm.getFieldLocalTrabalho = getFieldLocalTrabalho;
        vm.removerBorderTable = removerBorderTable;

        vm.tracker = {
            loading: promiseTracker(),
            init: promiseTracker()
        };

        init();

        function removerBorderTable($last,$first,$index, substituicoes) {
           if($index === 0 && _.size(substituicoes) === 1){
               return false;
           }
            return !$last || $first;
        }

        function init() {
            loadListOutrosFuncionarios();

        }

        function getDescriptionTipoVinculo(tipo) {
            if (!_.isNull(tipo)) {
                return _.get(_.find(vm.TipoVinculo, {key: tipo}), 'description', null);
            }
        }

        function onChangeContextEstabelecimento(newValue, oldValue) {
            if (newValue !== oldValue) {
                loadListOutrosFuncionarios();
            }
        }

        function filtrarSubstitutosPorLocal(localTrabalho, funcionario) {
            if (_.isNull(localTrabalho.estabelecimento)) {
                return filtrarPorEstabelecimentoNulo(funcionario);
            }
            var estabelecimentoId = obterEstabelecimentoId(localTrabalho);
            return filtrarPorEstabelecimentoIdAndLocalTrabalho(funcionario, estabelecimentoId, localTrabalho);
        }

        function filtrarPorEstabelecimentoNulo(funcionarios) {
            return _.filter(funcionarios, function (substituto) {
                return substituto.estabelecimento === null  && validateVigenciaDataFinalSubstituto(substituto);
            });
        }

        function obterEstabelecimentoId(localTrabalho) {
            if (vm.isSecretaria) {
                return vm.estabelecimentoSelected ? vm.estabelecimentoSelected.id : localTrabalho.estabelecimento.id;
            } else {
                return ContextoEstabelecimento.getEstabelecimento().id;
            }
        }

        function filtrarPorEstabelecimentoIdAndLocalTrabalho(funcionarios, estabelecimentoId) {
            return _.filter(funcionarios, function (substituto) {
                return substituto.estabelecimento !== null && substituto.estabelecimento.id === estabelecimentoId &&  validateVigenciaDataFinalSubstituto(substituto);
            });
        }

        function getFieldLocalTrabalho(localTrabalho) {
            if (_.isNull(localTrabalho.estabelecimento)) {
                return 'Secretaria';
            }
            return localTrabalho.estabelecimento.descricao;
        }

        function validateVigenciaDataFinalSubstituto(substituto) {
            if (substituto.dataFinal === null) {
                return true;
            }
            if (substituto.dataFinal !== null) {
                return isDataFinalIgualOuPosteriorAAtual(substituto);
            }
            return false;

        }

        function isDataFinalIgualOuPosteriorAAtual(substituto) {
            var dataFinal = moment(substituto.dataFinal, 'YYYY-MM-DD').startOf('day');
            var dataAtual = moment().startOf('day');
            return _.gte(dataFinal, dataAtual);
        }

        function watcherFilter() {
            loadListOutrosFuncionarios();
        }

        function onRefhesh() {
            loadListOutrosFuncionarios();
        }


        function loadListOutrosFuncionarios() {
            var params = {
                filter: vm.filter,
                offset: vm.currentPage - 1,
                limit: vm.itemsPerPage,
                sort: null,
                estabelecimentoId: _.get(vm.estabelecimentoSelected, 'id')
            };

            params.offset = params.offset > 0 ? params.offset * params.limit : 0;

            var promise = vm.service.listOutrosFuncionarios(params).then(onLoadItens);
            vm.tracker.loading.addPromise(promise);
        }

        function onLoadItens(itens) {
            vm.funcionarios = itens.content;
            vm.currentPage = (itens.offset / itens.limit) + 1;
            vm.totalItems = itens.total;
            vm.hasNext = itens.hasNext;
            vm.totalContent = itens.content.length;
        }

    }
})();
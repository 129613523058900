(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaTableAlunos', appConfiguracaoListaEsperaTableAlunos);

    function appConfiguracaoListaEsperaTableAlunos() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/configuracao-lista-espera-buscar-candidato/app-configuracao-lista-espera-table-alunos/app-configuracao-lista-espera-table-alunos.directive.html',
            scope: {
                listMatricula: '='
            },
            replace: true
        };
    }
})();

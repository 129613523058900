(function () {
    'use strict';

    angular.module('educacao.matricula').factory('educacao.matricula.LocalizacaoInscricaoService', LocalizacaoInscricaoService);

    LocalizacaoInscricaoService.$inject = ['$injector'];

    function LocalizacaoInscricaoService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .create()
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'localizacao-configuracao-inscricao'
            });

    }

})();

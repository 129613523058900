(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoInscritosEncerrarCtrl', ClassificacaoInscritosEncerrarCtrl);

    ClassificacaoInscritosEncerrarCtrl.$inject = ['processo','promiseTracker','educacao.matricula.ClassificacaoService','$rootScope','$modalInstance'];

    function ClassificacaoInscritosEncerrarCtrl(processo,promiseTracker,ClassificacaoService,$rootScope,$modalInstance) {
        
        var vm = this;
        vm.tracker = {};
        vm.tracker.botoes = promiseTracker();
        vm.save = save;

        function save() {
            vm.tracker.botoes.addPromise(
            	ClassificacaoService.encerrar(processo.id).then(function(data){
                    $rootScope.$broadcast('refresh::classificacoes', {
                        data: data,
                        encerrar: true,
                        periodoEncerrado: isPeriodoEncerrado()
                    });
                    close();
                }) 
            );            
        }

        function isPeriodoEncerrado() {
            return processo.status === 'PERIODO_ENCERRADO';
        }

        function close() {
            $modalInstance.close();
        }

    }


})();

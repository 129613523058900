(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.RemoveTurmaCascadeController', Controller);

    Controller.$inject = ['$modalInstance', 'educacao.matricula.RemoveTurmaCascadeService', 'promiseTracker', 'bfc.Notification'];

    function Controller($modalInstance, RemoveTurmaCascadeService, promiseTracker, Notification) {
        var vm = this;
        vm.remove = remove;
        vm.tracker = promiseTracker();
        vm.turma = _.cloneDeep($modalInstance.params.turma) || {};

        function remove(id) {
            var promise = RemoveTurmaCascadeService
                .remove(id)
                .then(notificar)
                .then($modalInstance.close);
            vm.tracker.addPromise(promise);
        }

        function notificar() {
            Notification.publish('Turma ' + vm.turma.descricao +' enviada para exclusão com sucesso. Você será notificado assim que a exclusão for concluída', 'success');
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.historico')
        .provider('educacao.historico.HistoricoService', HistoricoServiceProvider);

    function HistoricoServiceProvider() {

        var handlers = {
            DEFAULT: {
                campos: {
                    DEFAULT: {
                        insercao: {
                            template: '<small>{{nome}} foi adicionado: <strong>{{valor}}</strong></small><br>'
                        },
                        alteracao: {
                            template: '<small>{{nome}} foi alterado de <strong>{{valorAnterior}}</strong>: <strong>{{valor}}</strong></small><br>'
                        },
                        remocao: {
                            template: '<small>{{nome}} foi removido: <strong>{{valorAnterior}}</strong></small><br>'
                        }
                    }
                }
            }
        };

        this.addTipoHandler = function(tipo, handler) {
            //TODO: validar o handler
            if (handlers[tipo]) {
                throw Error('Já existem um handler registrado para o tipo ' + tipo + ', era a sua intensão substituí-lo?');
            }

            handlers[tipo] = handler;
        };

        this.$get = ['Restangular', function(Restangular) {
            return new HistoricoService(Restangular, handlers);
        }];
    }

    function HistoricoService(Restangular, handlers) {

        return {
            list: list,
            getTipoHandler: getTipoHandler
        };

        function getTipoHandler(tipo) {
            return handlers[tipo] || handlers.DEFAULT;
        }

        function list() {
            return Restangular.one('historico').get();
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appDropdownContextoAnoLetivo', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/layout/header/context-bar/dropdown-contexto-ano-letivo.directive.html',
            replace: true,

            scope: {},

            controller: Contoller,
            controllerAs: 'vm'
        };
    }

    Contoller.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.context.ContextoAnoLetivo',
        'educacao.common.AnoLetivoService'
    ];

    function Contoller($scope, promiseTracker, ContextoAnoLetivo, AnoLetivoService) {
        var vm = this;

        vm.tracker = promiseTracker();

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.anosLetivos = [];

        vm.alterarAnoAtual = alterarAnoAtual;

        $scope.$on('app-context:anoletivo:new', function() {
            vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        });
        $scope.$on('app-context:anoletivo:remove', function() {
            delete vm.anoLetivo;
        });
        $scope.$on('$destroy', function() {
            AnoLetivoService.off(loadAnosLetivos);
        });
        $scope.$on('app-context:estabelecimento:new', function() {
            vm.anosLetivos = [];
            loadAnosLetivos();
        });

        AnoLetivoService.on('dirty', loadAnosLetivos);

        loadAnosLetivos();

        function loadAnosLetivos() {
            vm.tracker.addPromise(
                ContextoAnoLetivo
                .listaAnosLetivos()
                .then(function(data) {
                    vm.anosLetivos = data;
                })
            );
        }

        function alterarAnoAtual(anoLetivo) {
            ContextoAnoLetivo.setAnoLetivo(anoLetivo);
        }
    }
})();


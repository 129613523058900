(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('stepValidando', stepValidando);

    stepValidando.$inject = [];
    function stepValidando() {
        return {
            restrict: 'E',

            scope: {
                inscricao: '='
            },

            templateUrl: 'matricula/inscricao/step-validando/step-validando.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'InscricaoMatriculaRegularService'];
    function Controller($scope, promiseTracker, InscricaoMatriculaRegularService) {
        var vm = this;

        vm.tracker = promiseTracker();

        vm.next = next;
        vm.returnStep = returnStep;

        function next() {
            mapInscricaoProgramasSociais();
            vm.tracker.addPromise(
                InscricaoMatriculaRegularService
                    .save($scope.inscricao)
                    .then(function (data) {
                        data.configuracao = $scope.inscricao.configuracao;
                        $scope.inscricao = data;
                        $scope.inscricao.$$inscricaoSalva = true;
                    })
            );
        }

        function returnStep() {
            $scope.inscricao.$$estabelecimentosOrdenados = false;
        }

        function mapInscricaoProgramasSociais() {
            var programasSociais = $scope.inscricao.dados.programasSociais;
            $scope.inscricao.dados.programasSociais = _.map(programasSociais, function(programaSocial) {
                if (programaSocial.programaSocial) {
                    return programaSocial;
                }

                return {
                    programaSocial: programaSocial
                };
            });
        }
    }

})();
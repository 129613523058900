(function() {
    'use strict';

    angular.module('educacao.funcionario').directive('appSelectFuncaoGratificada', SelectFuncaoGratificada);

    function SelectFuncaoGratificada() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchFuncaoGratificada" add-value="vm.addFuncaoGratificada"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            controller: SelectFuncaoGratificadaDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectFuncaoGratificadaDirectiveController.$inject = ['ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.funcionario.FuncaoGratificadaService'];

    function SelectFuncaoGratificadaDirectiveController($modal, PermissionsService, service) {

        var vm = this;

        vm.searchFuncaoGratificada = searchFuncaoGratificada;
        vm.addFuncaoGratificada = !PermissionsService.isRevokedOperation('FuncaoGratificadaPageMapping', 'criar') && addFuncaoGratificada;

        function searchFuncaoGratificada(params) {
            return service.list('(descricao like "' + params.term + '")', params.offset, params.limit);

        }

        function addFuncaoGratificada(descricao) {
            return $modal.open({
                templateUrl: 'planejamento/funcionarios/funcao-gratificada/funcao-gratificada.cad.html',
                controller: 'educacao.funcionario.FuncaoGratificadaCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    funcaoGratificada: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }
    }
})();

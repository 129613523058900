(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appAccordionItem', AccordionButtonItemDirective);

    AccordionButtonItemDirective.$inject = ['$compile'];

    function AccordionButtonItemDirective() {
        return {
            restrict: 'E',

            templateUrl: 'common/directives/accordion-button-directive/accordion-button-item.html',
            replace: true,
            transclude: true,

            scope: {
                title: '@',
                clickButton: '&?',
                activeClass: '@?',
                defaultOpen: '@?'
            },

            link: postLink,
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    function postLink(scope, element, attrs, ctrl, transcludeFn) {

        if (scope.$parent.registerAccordion) {
            scope.$parent.registerAccordion(scope);
        }
        if (scope.$parent.closeAll) {
            ctrl.closeAll = scope.$parent.closeAll;
        }
        if (scope.$parent.getRecordedAccordions) {
            ctrl.accordionsBrothers = scope.$parent.getRecordedAccordions();
        }

        transcludeFn(function(clone, newScope) {

            if (!clone.is('.list-group')) {
                if (scope.defaultOpen) {
                    ctrl.click();
                }

                return;
            }

            newScope.registerAccordion = scope.registerAccordion;
            newScope.closeAll = scope.closeAll;
            newScope.getRecordedAccordions = scope.getRecordedAccordions;

            element.after(clone.addClass('bottom'));

            if (scope.defaultOpen) {
                ctrl.click();
                newScope.collapsed = false;
                scope.collapsed = false;
            } else {
                newScope.collapsed = true;
                scope.collapsed = true;
            }

            scope.hasChildren = true;

            scope.$watch('collapsed', function(collapsed) {
                newScope.collapsed = collapsed;
            });
        });
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        $scope.accordions = [];

        vm.click = click;

        $scope.closeAll = closeAll;
        $scope.registerAccordion = registerAccordion;
        $scope.getRecordedAccordions = getRecordedAccordions;

        function closeAll(accordionsList) {

            angular.forEach(accordionsList, function(accordion) {

                var accordionsDosFilhos = accordion.getRecordedAccordions();

                angular.forEach(accordionsDosFilhos, function(filho) {
                    filho.active = false;
                    filho.collapsed = !filho.active;
                });

                accordion.active = false;
                accordion.collapsed = !accordion.active;
            });
        }

        function registerAccordion(accordion) {
            $scope.accordions.push(accordion);
        }

        function getRecordedAccordions() {
            return $scope.accordions;
        }

        function click() {

            if ($scope.active) {
                $scope.collapsed = !$scope.collapsed;
            } else {
                vm.closeAll(vm.accordionsBrothers);
                $scope.active = true;
                $scope.collapsed = !$scope.active;
            }

            if ($scope.clickButton) {
                $scope.clickButton();
            }
        }
    }

})();


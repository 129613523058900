(function(){
    'use strict';

    angular.module('app')
        .config(EdEnumsCacheService);

        EdEnumsCacheService.$inject = ['EdEnumsCacheServiceProvider'];

    function EdEnumsCacheService(EdEnumsCacheServiceProvider) {
        EdEnumsCacheServiceProvider.setPath('enums');
        EdEnumsCacheServiceProvider.setCacheable(true);
    }
})();

(function() {

    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.RegistrosVinculadosService', RegistrosVinculadosService);

    RegistrosVinculadosService.$inject = ['$q', 'Restangular'];

    function RegistrosVinculadosService($q, Restangular) {
        var self = this;

        self.getTotalizadoresTurma = getTotalizadoresTurma;
        self.saveRealocacaoPeriodos = saveRealocacaoPeriodos;
        self.getImpeditivosTurma = getImpeditivosTurma;

        function getImpeditivosTurma(idTurma, calendarioMatriz) {
            return Restangular.one('calendario/registro-vinculado-matriz/impeditivos-turma', idTurma).customPOST(calendarioMatriz);
        }

        function getTotalizadoresTurma(calendarioMatriz, idTurma) {
            return Restangular.one('calendario/registro-vinculado-matriz/impactos-turma', idTurma).customPOST(calendarioMatriz);
        }

        function saveRealocacaoPeriodos(calendarioMatriz) {
            return Restangular
                .all('calendario/realocacao-periodos/' + calendarioMatriz.id)
                .customPUT(calendarioMatriz, '');
        }
    }
})();

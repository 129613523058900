(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.anotacoes')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.lista-espera-novo.anotacao', {
            abstract: true,
            data: {
                id: 'AnotacaoInscricaoPageMapping',
                mirrorOf: 'app.lista-espera-novo.anotacoes'
            }
        });


    }


})();
(function() {

	'use strict';

	angular.module('educacao.common').factory('educacao.common.FilterGeneric', FilterGeneric);

	function FilterGeneric() {

		var filter = {};

		return {
			setFilter: setFilter,
			getFilter: getFilter
		};

		function setFilter(name, func) {
			filter[name] = func;
		}

		function getFilter(input, name) {
			return filter[name] && filter[name](input);
		}

	}
})();

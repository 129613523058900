(function () {
    'use strict';

    var EVENT_EDIT = 'event-list-popover.edit';
    var EVENT_REMOVE = 'event-list-popover.remove';

    angular.module('educacao.calendario')
        .constant('EVENT_LIST_POPOVER_EDIT_EVENT', EVENT_EDIT)
        .constant('EVENT_LIST_POPOVER_REMOVE_EVENT', EVENT_REMOVE)
        .controller('educacao.calendario.CalendarioEventoListPopover', CalendarioEventoListPopover);

    CalendarioEventoListPopover.$inject = [
        '$scope',
        'educacao.context.ContextoEstabelecimento'
    ];

    function CalendarioEventoListPopover($scope, ContextoEstabelecimento) {
        var vm = this;

        vm.isEstabelecimento = !ContextoEstabelecimento.isSecretaria();

        vm.edit = edit;
        vm.remove = remove;

        function edit(evento) {
            $scope.$emit(EVENT_EDIT, evento);
        }

        function remove(evento) {
            $scope.$emit(EVENT_REMOVE, evento);
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.common')
		.factory('educacao.common.ParametroService', ParametroService);

	ParametroService.$inject = ['$injector'];

	function ParametroService($injector) {
		var GenericService = $injector.get('GenericService');
  
   
		return GenericService
					.create()
					.module({uri: 'common'})
					.service({uri: 'parametro'});
	}
})();
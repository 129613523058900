(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.DocumentacaoCtrl', DocumentacaoCtrl);

    DocumentacaoCtrl.$inject = ['$injector',
	'educacao.common.$commons',
	'$scope',
	'$window',
	'$rootScope',
	'$modalInstance',
	'promiseTracker',
	'id',
    'readOnly',
    '$modalTracker',
    '$q',
    'itemDocumento',
    'bfc.Notification',
    'bfc.Focus',
    'index'];

    function DocumentacaoCtrl($injector,
        $commons,
        $scope,
        $window,
        $rootScope,
        $modalInstance,
        promiseTracker,
        id,
        readOnly,
        $modalTracker,
        $q,
        itemDocumento,
        Notification,
        focus,
        index) {
        var vm = this;
        vm.salvar = salvar;
        vm.tracker = {};
        vm.tracker = {
            saveContinue: promiseTracker(),
            save: promiseTracker()
        };
        vm.idEstabelecimento = id;
        vm.edit = false;
        vm.add = true;
        vm.procuraArquivo = procuraArquivo;
        vm.onChangeFile = onChangeFile;
        
        var DATA_URL_REGEX = /data:(\S+);base64,(\S+)/;

        var _modo = {
            adicionando: !_.isNumber(id) && !readOnly,
            editando: !_.isEmpty(itemDocumento) && !readOnly,
            visualizando: _.isNumber(id) && readOnly
        };
        _init();

        function procuraArquivo() {
            $('input[name=file]').click();
        }

        function _init() {
            if (_modo.editando) {
                vm.windowTitle = 'Editando documento';
                vm.documento = itemDocumento;
                vm.edit = true;
                vm.add = false;
                focus('lbDescricao');
            } else {
                vm.windowTitle = 'Adicionando documento';
                vm.documento = {
                    arquivo: null
                };
                focus('lbDescricao');
            }
        }

        function onChangeFile($files, $file) {
            if ($file) {
                loadContent($file, vm.documento.arquivo);
            }
        }

        function loadContent(newFile, oldFile) {
            var reader = new $window.FileReader();
            reader.readAsDataURL(newFile);
            reader.onloadend = function() {
                var match = DATA_URL_REGEX.exec(reader.result);
                if (match === null) {
                    Notification.publish('Formato de arquivo inválido.', 'error');
                }
                $scope.$apply(function() {
                    if (match === null) {
                        vm.documento.arquivo = oldFile;
                    } else {
                        var file = {
                            nome: newFile.name,
                            tipo: match[1],
                            conteudo: match[2],
                            tamanho: newFile.size
                        };
                        if (!vm.documento.arquivo) {
                            vm.documento.arquivo = {};
                        }
                        _.extend(vm.documento.arquivo, file);
                    }
                });
            };
        }

        function salvar(continuar) {
            vm.documento.estabelecimento = {
                id: vm.idEstabelecimento
            };
            
            if (vm.documento.arquivo) {
                ajusteArquivo();
            }

            $rootScope.$broadcast('refresh::Documento', {
                id: index,
                data: vm.documento,
                add: vm.add,
                edit: vm.edit
            });
            onSuccess(continuar);
        }

        function ajusteArquivo(){
            delete vm.documento.arquivo.$error;
            delete vm.documento.arquivo.$errorParam;
        }

        function onSuccess(continuar) {
            if (continuar) {
                vm.documento = null;
                vm.form.$setPristine();
                vm.form.$setUntouched();
                focus('lbDescricao');
            } else {
                _cancel();
            }
        }

        function _cancel() {
            $modalInstance.close();
        }

    }
})();

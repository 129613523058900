(function() {

    /**
     * Diretiva para encapsular conteúdo da aba de dados gerais do cadastro de matricula no funcionário
     * Parâmetros: 
     * matricula -> matricula que está sendo adicionado ou editado
     */

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appTabFuncionarioMatriculaDadosGerais', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-matricula/app-tab-funcionario-matricula-dados-gerais.directive.html',
            scope: {},
            bindToController: {
                matricula: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'educacao.pessoas.EstabelecimentoService'];

    function Controller($scope, EstabelecimentoService) {

        var vm = this;

        vm.adicionaCargoComissionado = adicionaCargoComissionado;
        vm.removeCargoComissionado = removeCargoComissionado;
        vm.onChangeCriterioAcessoCargo = onChangeCriterioAcessoCargo;
        vm.search = search;
        vm.formatResult = formatResult;
        vm.createItemSecretaria = createItemSecretaria();


        $scope.$watch('vm.matricula.funcaoGratificada', watchFuncaoGratificada);
        $scope.$watch('vm.matricula.dataInicialFuncao', watchDataInicialFuncao);

        initController();

        function initController() {
            if (vm.matricula.cargoComissionado) {
                vm.cargoComissionado = true;
            }
            vm.matricula.estabelecimento = vm.matricula.secretaria ? createItemSecretaria() : vm.matricula.estabelecimento;
        }

        function adicionaCargoComissionado() {
            vm.cargoComissionado = true;
        }

        function removeCargoComissionado() {
            delete vm.matricula.cargoComissionado;
            vm.cargoComissionado = false;
        }

        function watchFuncaoGratificada(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (!newValue) {
                delete vm.matricula.dataInicialFuncao;
                delete vm.matricula.dataFinalFuncao;
            }
        }

        function watchDataInicialFuncao(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (!newValue) {
                delete vm.matricula.dataFinalFuncao;
            }
        }

        function search(params) {
            return EstabelecimentoService.list('(nome like "' + params.term + '")', params.offset, params.limit)
                .then(
                    function(data) {
                        var term = params.term.substring(1, params.term.length - 1);
                        term = term.replace('%', ' ');
                        if (vm.createItemSecretaria.descricao.toUpperCase().indexOf(term.toUpperCase()) !== -1) {
                            data.unshift(vm.createItemSecretaria);
                        }
                        return {
                            content: data,
                            hasMore: data.hasNext
                        };
                    }
                );
        }

        function createItemSecretaria() {
            var item = {};
            item.id = '-1';
            item.descricao = 'Secretaria de Educação';
            return item;
        }

        function formatResult(data) {
            return data.descricao || data.nome || (data.pessoa ? data.pessoa.nome : '');
        }

        function onChangeCriterioAcessoCargo(){
            vm.matricula.descricaoCriterioAcessoCargo = null;
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.diarioclasse')
        .controller('educacao.diarioclasse.ImpressaoDiario', Controller);

    Controller.$inject = [
        'educacao.common.$commons',
        '$modalInstance',
        'ui.components.PrintService',
        'educacao.entidade.EntidadeService',
        'educacao.context.ContextoAnoLetivo',
        'educacao.diarioClasse.ImpressaoDiarioClasseService'
    ];

    function Controller($commons, $modalInstance, PrintService, EntidadeService, ContextoAnoLetivo, ImpressaoDiarioClasseService) {
        var vm = this;

        vm.diario = $modalInstance.params.diario;
        vm.tituloImpressao = $modalInstance.params.tituloImpressao;
        vm.tituloFicha = $modalInstance.params.tituloFicha;
        vm.estabelecimento = $modalInstance.params.estabelecimento;
        vm.entidade = $modalInstance.params.entidade;
        vm.turma = $modalInstance.params.turma;
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.dataEmissao = moment().local().format('DD/MM/YYYY');
        vm.getPeriodoAvaliativo = getPeriodoAvaliativo;
        vm.getDataInicialPeriodo = getDataInicialPeriodo;
        vm.getDataFinalPeriodo = getDataFinalPeriodo;
        vm.predicate = $modalInstance.params.predicate;
        vm.reverse = $modalInstance.params.reverse;
        vm.tituloColuna = $modalInstance.params.tituloColuna;
        vm.print = print;

        geradorDeColunasDias();

        function geradorDeColunasDias() {
            vm.columnDias = [];
            for (var i = 0; i < 30; i++) {
                vm.columnDias.push({
                    id: i
                });
            }
        }

        function getPeriodoAvaliativo(item) {
            if (vm.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO') {
                return 'Período avaliativo: ' + item.descricao + ' - ' + vm.anoLetivo;
            } else if (vm.diario.tipoItemEducacional === 'ATIVIDADE') {
                return 'Período: ' + vm.anoLetivo;
            } else {
                return vm.diario.configuracao.periodoAvaliativoCorrente ? 'Período avaliativo: ' + vm.diario.configuracao.periodoAvaliativoCorrente
                    .descricao : '';
            }
        }

        function getDataInicialPeriodo(item) {
            if (vm.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO' || vm.diario.tipoItemEducacional === 'ATIVIDADE') {
                return item.dataInicial;
            } else {
                return vm.diario.configuracao.periodoAvaliativoCorrente ? vm.diario.configuracao.periodoAvaliativoCorrente.dataInicial :
                    '';
            }
        }

        function getDataFinalPeriodo(item) {
            if (vm.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO' || vm.diario.tipoItemEducacional === 'ATIVIDADE') {
                return item.dataFinal;
            } else {
                return vm.diario.configuracao.periodoAvaliativoCorrente ? vm.diario.configuracao.periodoAvaliativoCorrente.dataFinal :
                    '';
            }
        }

        function print() {
            ImpressaoDiarioClasseService.imprimir('#formularioDiarioClasseImpressao')
                .then(function () {
                    $modalInstance.close();
                });
        }
    }
})();


(function(){
    'use strict';

    angular.module('app')
    .config(myPreferencesConfig);

    myPreferencesConfig.$inject = [
    '$myPreferencesApiProvider',
    '$myPreferencesProvider',
    'SYSTEM_ID',
    'URL_MY_PREFERENCES_API'
    ];
    
    function myPreferencesConfig(
    $myPreferencesApiProvider,
    $myPreferencesProvider,
    SYSTEM_ID,
    URL_MY_PREFERENCES_API
    ) {
    $myPreferencesApiProvider.setBasePath(URL_MY_PREFERENCES_API);
    $myPreferencesProvider.setSystemId(SYSTEM_ID);
    }
})();

(function () {

    'use strict';

    angular.module('educacao.encerramento')
        .service('educacao.encerramento.EncerramentoUtilsService', EncerramentoUtilsService);

    EncerramentoUtilsService.$inject = [
        'PERIODOS_ENCERRAMENTO',
        'STATUS_ENCERRAMENTO'
    ];

    function EncerramentoUtilsService(PERIODOS_ENCERRAMENTO, STATUS_ENCERRAMENTO) {

        var self = this;

        self.isPeriodoLetivo = isPeriodoLetivo;
        self.isStatusEmAberto = isStatusEmAberto;
        self.isStatusEncerrado = isStatusEncerrado;
        self.isStatusProcessando = isStatusProcessando;
        self.searchColumnPeriodoLetivo = searchColumnPeriodoLetivo;
        self.searchColumnsExcetoPeriodoLetivo = searchColumnsExcetoPeriodoLetivo;

        /**
         * Verifica se o período passado é um período letivo
         */
        function isPeriodoLetivo(periodo) {
            return periodo === PERIODOS_ENCERRAMENTO.PERIODO_LETIVO;
        }

        /**
         * Retorna o valor do enum com o id PERIODO_LETIVO
         */
        function searchColumnPeriodoLetivo(enumColumns) {
            return _.find(enumColumns, 'id', PERIODOS_ENCERRAMENTO.PERIODO_LETIVO);
        }

        /**
         * Retorna valores de enums onde o id é DIFERENTE  de PERIODO_LETIVO e CONSELHO_CLASSE
         */
        function searchColumnsExcetoPeriodoLetivo(enumColumns) {
            return _.filter(enumColumns, function (coluna) {
                return coluna.id !== PERIODOS_ENCERRAMENTO.PERIODO_LETIVO && coluna.id !== PERIODOS_ENCERRAMENTO.CONSELHO_CLASSE;
            });
        }

        /**
         * Verifica se o status de encerramento é encerrado
         */
        function isStatusEncerrado(encerramento) {
            return _.get(encerramento, 'status') === STATUS_ENCERRAMENTO.ENCERRADO;
        }

        /**
         * Verifica se o status de encerramento é Em aberto
         */
        function isStatusEmAberto(encerramento) {
            return _.get(encerramento, 'status') === STATUS_ENCERRAMENTO.EM_ABERTO;
        }

        /**
         * Verifica se o status de encerramento é Processando
         */
        function isStatusProcessando(encerramento){
            return _.get(encerramento, 'status') === STATUS_ENCERRAMENTO.PROCESSANDO || _.get(encerramento, 'status') === STATUS_ENCERRAMENTO.PROCESSANDO_REABERTURA;
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.calendario')
        .service('InstallDirective', InstallDirective);

    InstallDirective.$inject = ['$rootScope', '$compile'];

    function InstallDirective($rootScope, $compile) {
        return {
            addDirective: addDirective
        };

        function addDirective(element, attrs, $parentScope, newScopeValues) {
            var scopeToDestroy = angular.element(element).scope();
            if (scopeToDestroy) {
                scopeToDestroy.$destroy();
            }

            for (var attr in attrs) {
                element.attr(attr, attrs[attr]);
            }

            var $scope = ($parentScope || $rootScope).$new();

            for (var key in (newScopeValues || {})) {
                $scope[key] = newScopeValues[key];
            }

            $compile(element)($scope);
        }
    }
})();

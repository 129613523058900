(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .factory('educacao.rematricula.RematriculaService', RematriculaService);

    RematriculaService.$inject = ['ServiceBuilder'];

    function RematriculaService(ServiceBuilder) {

        var self = ServiceBuilder.create()
            .path('matricula', 'rematricula')
            .enum('SituacaoRematricula')
            .enum('EquivalenciaEtapa')
            .enum('Turno')
            .enum('SituacaoMatricula')
            .build();

        self.getVagas = getVagas;
        self.getCalendarioMatriz = getCalendarioMatriz;
        self.getListTurma = getListTurma;
        self.rematricular = rematricular;
        self.rematriculaLotesEstabelecimentoDiferente = rematriculaLotesEstabelecimentoDiferente;
        self.getRematriculasItens = getRematriculasItens;
        self.getAlunosRematriculas = getAlunosRematriculas;

        return self;

        function getVagas(idEstabelecimento, idAnoLetivo) {
            return self.getEndpoint().all('vaga').getList({
                idEstabelecimento: idEstabelecimento,
                idAnoLetivo: idAnoLetivo
            });
        }

        function getCalendarioMatriz(idEstabelecimento, idAnoLetivo, idMatriz) {
            return self.getEndpoint().all('calendario-matriz').getList({
                idEstabelecimento: idEstabelecimento,
                idAnoLetivo: idAnoLetivo,
                idMatriz: idMatriz
            });
        }

        function rematricular(rematricula){
            return self.getEndpoint().one('rematriculaLotes').customPUT(rematricula);
        }

        function rematriculaLotesEstabelecimentoDiferente(rematricula){
            return self.getEndpoint().one('rematriculaLotesEstabelecimentoDiferente').customPUT(rematricula);
        }

        function getListTurma(filter, offset, limit, sort) {

            var params = {
                filter: filter,
                limit: limit,
                offset: offset,
                sort: sort
            };
            return self.getEndpoint().all('turma').get('', params);
        }

        function getRematriculasItens(params){
            return self.getEndpoint().all('itens-rematriculas').get('',params);
        }

        function getAlunosRematriculas(turma,situacoes,estabelecimento,solicitacaoRematricula){
            var filter = '(' + 'turma = ' + turma.id + ' and situacao in ("' + situacoes.join('","') + '")';
            if (solicitacaoRematricula) {
                filter = filter  + ' and solicitacaoRematricula = "' + solicitacaoRematricula + '"';
            }
            filter = filter + ')';

            var params = {
                filter: filter,
                limit: 999,
                estabelecimentoId: estabelecimento.id,
            };

            return self.getEndpoint().all('matriculas-nao-rematriculadas').get('',params);
        }

    }

})();


(function () {

    'use strict';
    angular.module('educacao.geracaoHorarios').controller('educacao.geracaoHorarios.GeracaoHorariosConflitoModalController', GeracaoHorariosConflitoModalController);

    GeracaoHorariosConflitoModalController.$inject = ['$modalInstance'];
    function GeracaoHorariosConflitoModalController($modalInstance) {

        var vm = this;

        vm.mensagem = $modalInstance.params.resultado.mensagem;

        vm.removerAulasConflitantes = removerAulasConflitantes;
        vm.ignorarConflitos = ignorarConflitos;

        function ignorarConflitos() {
            var aulaParams = $modalInstance.params.aulaParams;
            aulaParams.aula.resolucaoConflito = 'IGNORAR';
            $modalInstance.close(aulaParams);
        }

        function removerAulasConflitantes() {
            var aulaParams = $modalInstance.params.aulaParams;
            aulaParams.aula.resolucaoConflito = 'SUBSTITUIR';
            $modalInstance.close(aulaParams);
        }

    }

})();

(function () {
    'use strict';

    angular
        .module('educacao.quadro-informativo-utils')
        .directive('appSelectComponentesCurricularesProfessores', appSelectComponentesCurricularesProfessores);

    function appSelectComponentesCurricularesProfessores() {

        return {
            restrict: 'E',
            template: '<ui-select placeholder="placeholder" ngclass="ngclass"  ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search"/>',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
                placeholder: '=',
                ngclass: '=',
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService'];

    function Controller($scope, Service) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = ['(descricao like "' + params.term + '")'];

            if ($scope.multiple) {
                var componentesCurricularesIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        componentesCurricularesIds.push(model.id);
                    });
                }

                if (componentesCurricularesIds.length) {
                    filter.push('componenteCurricularId not in(' + componentesCurricularesIds.join() + ')');
                }
            }

            var reqParams = {
                filter: filter.join(' and '),
                offset: params.offset,
                limit: params.limit
            };

            return Service
                .listComponenteCurricularProfessores(reqParams)
                .then(toPage);
        }

        function toPage(list) {
            return {
                content: list.content,
                hasNext: list.hasNext
            };
        }

        function formatResult(data) {
            return data.descricao;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('educacao.context')
        .service('ApplicationContexts', applicationContextsService);

    function applicationContextsService() {
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .service('joinTime', JoinTimeBuilder);

    JoinTimeBuilder.$inject = ['$parse'];

    /**
    
    Expr1 - Format1 --+
                      |-- DestExpr - Format1 + Format2
    Expr2 - Format2 --+

    */

    function JoinTimeBuilder($parse) {
        return {
            create: createBuilder
        };

        function createBuilder() {
            var joinTime = new JoinTime();

            var builder = {
                scope: setScope,

                expr1: setExpr1,
                format1: setFormat1,

                expr2: setExpr2,
                format2: setFormat2,

                destExpr: setDestExpr,
                destFormat: setDestFormat,

                build: build
            };

            return builder;

            function setScope(scope) {
                joinTime.scope = scope;
                return builder;
            }

            function setExpr1(expr1) {
                joinTime.expr1 = expr1;
                return builder;
            }

            function setFormat1(format1) {
                joinTime.format1 = format1;
                return builder;
            }

            function setExpr2(expr2) {
                joinTime.expr2 = expr2;
                return builder;
            }

            function setFormat2(format2) {
                joinTime.format2 = format2;
                return builder;
            }

            function setDestExpr(destExpr) {
                joinTime.destExpr = destExpr;
                return builder;
            }

            function setDestFormat(destFormat) {
                joinTime.destFormat = destFormat;
                return builder;
            }

            function build() {
                return joinTime
                    .validate()
                    .createWatches();
            }
        }

        function JoinTime() {
            var self = this;

            this.scope = undefined;

            this.expr1 = undefined;
            this.format1 = undefined;

            this.expr2 = undefined;
            this.format2 = undefined;

            this.separator = 'T';

            this.destExpr = undefined;

            this.validate = validate;
            this.createWatches = createWatches;

            function validate() {
                if (!self.scope) {
                    throw Error('scope não pode ser nulo.');
                }
                if (!self.expr1) {
                    throw Error('expr1 não pode ser nulo.');
                }
                if (!self.format1) {
                    throw Error('format1 não pode ser nulo.');
                }
                if (!self.expr2) {
                    throw Error('expr2 não pode ser nulo.');
                }
                if (!self.format2) {
                    throw Error('format2 não pode ser nulo.');
                }
                if (!self.destExpr) {
                    throw Error('destExpr não pode ser nulo.');
                }
                if (!self.destFormat) {
                    throw Error('destFormat não pode ser nulo.');
                }
                return self;
            }

            function createWatches() {
                self.scope.$watch(self.expr1, watchExpr1);
                self.scope.$watch(self.expr2, watchExpr2);
                self.scope.$watch(self.destExpr, watchDestExpr);

                return self;
            }

            function watchExpr1(newValue) {
                if (newValue === self.expr1Value) {
                    return;
                }
                self.expr1Value = newValue;

                updateDestValue();

            }

            function watchExpr2(newValue) {
                if (newValue === self.expr2Value) {
                    return;
                }
                self.expr2Value = newValue;

                updateDestValue();
            }

            function updateDestValue() {
                self.destExprValue = self.expr1Value + self.separator + self.expr2Value;

                $parse(self.destExpr)
                    .assign(self.scope, self.destExprValue);
            }

            function watchDestExpr(newValue) {
                if (newValue === self.destExprValue) {
                    return;
                }
                self.destExprValue = newValue;

                var momentNewValue = moment(newValue, self.destFormat);

                if (momentNewValue.isValid()) {
                    self.expr1Value = momentNewValue.format(self.format1);
                    self.expr2Value = momentNewValue.format(self.format2);
                } else {
                    self.expr1Value = '';
                    self.expr2Value = '';
                }

                $parse(self.expr1)
                    .assign(self.scope, self.expr1Value);

                $parse(self.expr2)
                    .assign(self.scope, self.expr2Value);

            }

        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .controller('educacao.geracaoHorarios.GeracaoHorariosCopiaCadController', Controller);

    Controller.$inject = [
        '$modalInstance', 
        'promiseTracker', 
        'bfc.Notification', 
        'educacao.geracaoHorarios.GeracaoHorariosService',
        'educacao.context.ContextoAnoLetivo'    
    ];

    function Controller($modalInstance, promiseTracker, bfcNotification, GeracaoHorariosService, ContextoAnoLetivo) {
        var vm = this;

        vm.save = save;

        vm.promiseTracker = promiseTracker();
        vm.anoLetivo = _.get(ContextoAnoLetivo.getAnoLetivo(), 'ano');
        vm.permissionEdit = _.get($modalInstance, 'params.permissionEdit');
        vm.permission = true;

        init();

        function init() {
            var quadroHorarios = _.get($modalInstance, 'params.quadroHorarios');
            if (!quadroHorarios) {
                return;
            }

            if (!vm.permissionEdit && !quadroHorarios.vigenciaFim) {
                vm.permission = false;
                vm.quadroHorarios = quadroHorarios;
                return;
            }

            vm.quadroHorariosNovo = _.clone(quadroHorarios);
            vm.quadroHorariosNovo.id = null;
            vm.quadroHorariosNovo.vigenciaFim = null;
            vm.quadroHorariosNovo.vigenciaInicio = null;

            var nowDate = moment();

            if (vm.anoLetivo === nowDate.format('YYYY')) {
                var vigenciaFim = _.get(quadroHorarios, 'vigenciaFim');
                if (!vigenciaFim) {
                    vigenciaFim = nowDate.format('YYYY-MM-DD');
                    quadroHorarios.vigenciaFim = vigenciaFim;
                }

                vm.quadroHorariosNovo.vigenciaInicio = moment(vigenciaFim, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
            }

            vm.quadroHorarios = quadroHorarios;
        }

        function save() {
            var promise;

            if (!vm.permissionEdit) {
                promise = thenPut();
            } else {
                promise = GeracaoHorariosService.postOrPut(vm.quadroHorarios).then(thenPut);
            }

            vm.promiseTracker.addPromise(promise);


            function thenPut() {
                return GeracaoHorariosService.postOrPut(vm.quadroHorariosNovo).then(thenPost);
            }
            
            function thenPost(quadroHorariosNovo) {
                if (!quadroHorariosNovo.id) {
                    return;
                }

                return GeracaoHorariosService.copy(quadroHorariosNovo.id, vm.quadroHorarios.id).then(thenCopy);
            }

            function thenCopy() {
                bfcNotification.publish('Processo de cópia das aulas do quadro de horários em andamento. Ao término do processamento, você será notificado.', 'info');
                $modalInstance.close();
            }
        }
    }
})();

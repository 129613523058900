(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.OrgaoEmissorInepService', OrgaoEmissorInepService);

    OrgaoEmissorInepService.$inject = ['$injector'];

    function OrgaoEmissorInepService($injector) {
        var GenericService = $injector.get('GenericService');
  
   
        return GenericService
                    .createReadOnly()
                    .module({uri: 'inep'})
                    .service({uri: 'orgao-emissor-inep'});
    }
})();
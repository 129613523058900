(function() {

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appDetalhesMatriculasFuncionario', Directve);

    function Directve() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/movimentacoes-matricula/app-detalhes-matriculas-funcionario.html',
            scope: {},
            bindToController: {
                funcionario: '=',
                method: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
		'promiseTracker',
		'ui.components.Modal',
		'educacao.funcionario.FuncionarioMatriculaMovimentacoesService',
		'$scope',
		'$rootScope'
	];

    function Controller(promiseTracker, $modal, MovimentacoesService, $scope, $rootScope) {
        var vm = this;
        vm.openMovimentacao = openMovimentacao;
        vm.tracker = promiseTracker();
        vm.detalhesMatriculas = [];
        vm.openDesenturmacao = openDesenturmacao;
        vm.changeShowDetalheMatriculaDiretiva = changeShowDetalheMatriculaDiretiva;

        init();

        function init() {
            if (!vm.funcionario) {
                return;
            }
            var promise = vm.method(vm.funcionario)
                .then(function(data) {
                    if (_.isEmpty(data)) {
                        vm.detalhesMatriculas = [];
                    }
                    vm.detalhesMatriculas = data;
                });

            vm.tracker.addPromise(promise);
        }

        $scope.$on('refreshDetalhesFuncionario', function(event, args) {
            if (args.atualiza) {
                init();
            }
        });

        function openMovimentacao(tipoMovimentacao, matricula) {
            if (matricula.situacao === 'TRABALHANDO' || isMovimentacaoFalecimento(tipoMovimentacao, matricula) || tipoMovimentacao ===
                'RETORNAR_AFASTAMENTO') {
                return $modal.open({
                    templateUrl: 'planejamento/funcionarios/funcionario/movimentacoes-matricula/cadastro-movimentacoes-funcionario.html',
                    controller: 'educacao.funcionario.CadastroMovimentacoesMatriculaFuncionarioCtrl',
                    controllerAs: 'vm',
                    size: 'sm',
                    params: {
                        tipoMovimentacao: tipoMovimentacao,
                        matricula: matricula,
                        detalhesMatriculas: vm.detalhesMatriculas,
                        retornoAfastamento: matricula.situacao === 'RETORNAR_AFASTAMENTO',
                        editando: false
                    }
                }).result.then(function() {
                    $rootScope.$broadcast('refreshDetalhesFuncionario', {
                        atualiza: true
                    });
                });
            } else {
                var promiseMovimentacao = MovimentacoesService.desfazMovimentacao(matricula.id);
                promiseMovimentacao.then(function() {
                    init();
                    $rootScope.$broadcast('refreshDetalhesFuncionario', {
                        atualiza: true
                    });
                });
                vm.tracker.addPromise(promiseMovimentacao);
            }
        }

        function openDesenturmacao(matricula) {
            $modal.open({
                templateUrl: 'matricula/enturmacao-professores/desenturmacao/desenturmacao-professor.html',
                controller: 'educacao.matricula.DesenturmacaoProfessorCtrl as vm',
                size: 'sm',
                params: {
                    matricula: matricula
                }
            });
        }

        function isMovimentacaoFalecimento(tipoMovimentacao, matricula) {
            return matricula.situacao === 'AFASTADO' && tipoMovimentacao === 'FALECIMENTO';
        }

        function changeShowDetalheMatriculaDiretiva(matricula) {
            matricula.$$open = !matricula.$$open;
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.pessoas')
		.factory('educacao.pessoas.DependFisicaService', DependFisicaService);

	DependFisicaService.$inject = ['$injector'];

	function DependFisicaService($injector) {
		var GenericService = $injector.get('GenericService');
  
   
		return GenericService.of('pessoa', 'dependencia-fisica');
	}
})();
(function() {
    'use strict';

    angular.module('educacao.lista-espera')
        .factory('educacao.lista-espera.ConfiguracaoListaEsperaService', Service);

    Service.$inject = ['$injector'];

    function Service($injector) {
        var GenericService = $injector.get('GenericService');

        var service = GenericService
            .create()
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'configuracao-lista-espera'
            });
        return service;
    }
})();

(function () {
    'use strict';

    angular.module('educacao.visao-geral-quadro-informativo').config(stateConfig);

    stateConfig.$inject = [
      '$stateProvider'
    ];

    function stateConfig($stateProvider) {
      var STATE = 'app.planejamento.visao-geral-quadro-informativo';

      $stateProvider.state(STATE, {
        url: 'quadro-informativo-visao-geral',
        params: {
          estabelecimentoSelected: '',
          anoLetivoSelected:''
        },
        // data: {
        //   id: 'QuadroInformativoPageMapping'
        // },
        views: {
          '@': {
            templateUrl: 'quadro-informativo/visao-geral/visao-geral-quadro-informativo.html',
            controller: 'educacao.visao-geral-quadro-informativo.VisaoGeralQuadroInformativoController as vm',
          }
        }
      });
    }
  })();
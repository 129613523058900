(function() {

    'use strict';

    var EVENTS_TO_DISABLE = ['click', 'dblclick', 'mousedown', 'mouseup'].join(' ');
    var MENSAGEM = 'Você não tem permissão para acessar este recurso.';

    angular.module('educacao.common')
        .directive('appHasPermission', appHasPermission);

    appHasPermission.$inject = ['$timeout'];

    function appHasPermission($timeout) {
        return {
            restrict: 'A',
            scope: {
                appHasPermission: '='
            },
            link: postLink
        };

        function postLink(scope, element) {
            if (!scope.appHasPermission) {
                $timeout(disableElement);
            }

            function disableElement() {
                element
                    .off(EVENTS_TO_DISABLE)
                    .bind(EVENTS_TO_DISABLE, function(e) {
                        e.preventDefault();
                    })
                    .addClass('disabled permissions-disabled')
                    .attr({
                        disabled: 'disabled',
                        'ng-disabled': 'true',
                        title: MENSAGEM
                    });
            }
        }
    }

})();

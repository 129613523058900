(function() {

    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.TurmaSelect', TurmaSelect);

    TurmaSelect.$inject = ['$injector'];

    function TurmaSelect($injector){

       var TurmaService = $injector.get('educacao.matricula.TurmaService');
       var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');

       return {select2Config: function(){
                    return CommonSelectWithService.select2Config(TurmaService,'descricao');
               }
              };
    }
})();
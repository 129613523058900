(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.ClassificacaoInscritosListaEncaminhadoCtrl', ClassificacaoInscritosListaEncaminhadoCtrl);

	ClassificacaoInscritosListaEncaminhadoCtrl.$inject = [
		'promiseTracker',
		'educacao.matricula.ClassificacaoEncaminhadosService',
		'$rootScope',
		'$modalInstance',
		'$q',
		'$enumsCache'
	];

	function ClassificacaoInscritosListaEncaminhadoCtrl(
		promiseTracker,
		ClassificacaoEncaminhadosService,
		$rootScope,
		$modalInstance,
		$q,
		$enumsCache
	) {

		var vm = this;
		vm.tracker = {
			loading: promiseTracker(),
			botoes: promiseTracker()
		};

		vm.save = save;

		vm.classificacao = $modalInstance.params.classificacao;
		vm.nivelEscolarSelecionado = $modalInstance.params.nivelEscolarSelecionado;
		vm.nomeCandidato = getNomeCandidato();

		init();

		function init() {
			var configuracaoId = getConfiguracaoId();

			if (!configuracaoId) {
				return;
			}

			vm.tracker.loading.addPromise(
				ClassificacaoEncaminhadosService.getDefault(configuracaoId).then(function(data) {					
					vm.estabelecimento = _.get(vm.classificacao, 'classificacaoInscricao.estabelecimento');
					vm.listaEncaminhado = angular.extend(data, {
						resultado: {
							id: vm.classificacao.id
						},
						etapa: _.get(vm.classificacao, 'classificacaoInscricao.etapa'),
						turno: _.get(vm.classificacao, 'classificacaoInscricao.turno'),
						chamada: _.get(vm.classificacao, 'classificacaoInscricao.chamada')
					});
				}).then(getEnums)
			);
		}

		function getEnums() {
			$enumsCache.get('Turno').then(function (data) {
				vm.turnos = data;
			});

			$enumsCache.get('NivelEscolar').then(function (data) {
				vm.nivelEscolar = data;
			});
		}

		function getConfiguracaoId() {
			return _.get(vm.classificacao, 'classificacaoInscricao.configuracaoInscricao.id');
		}

		function save() {
			var configuracaoId = getConfiguracaoId();
			if (!configuracaoId) {
				return;
			}

			var listaEncaminhado = _.cloneDeep(vm.listaEncaminhado);
			listaEncaminhado.estabelecimento = _.pick(vm.estabelecimento, 'id');
			
			vm.tracker.botoes.addPromise(
				ClassificacaoEncaminhadosService.encaminhar(configuracaoId, listaEncaminhado)
				.then(function(data) {
					$rootScope.$broadcast('refresh::classificacoes', {
						data: data,
						listaEncaminhado: true
					});
					close();
				})
			);
		}

		function getNomeCandidato() {
			if (vm.classificacao.candidato) {
				return vm.classificacao.candidato.nome;
			}

			return vm.classificacao.dados.nome;
		}

		function close() {
			$modalInstance.close();
		}
	}

})();

(function () {
    'use strict';

    angular
        .module('educacao.lista-espera')
        .directive('appLinhaMotivoCancelamento', directive);

    function directive() {
        return {
            restrict: 'E',

            replace: true,
            templateUrl: 'lista-espera/componentes/app-linha-motivo-cancelamento.directive.html',

            scope: {
                mensagem: '='
            }
        };
    }
})();
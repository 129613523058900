(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.ConfiguracoesMatriculaService', Service);

    Service.$inject = ['$injector'];

    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('matricula', 'configuracao-matricula')
            .enum('EquivalenciaEtapa')
            .build();

        selfService.getConfiguracao = configuracao;
        selfService.getConfiguracaoSecretaria = configuracaoSecretaria;
        selfService.atualizarMatriculas = atualizarMatriculas;
        selfService.getDocumentos = getDocumentos;
        selfService.removeConfiguracaoEstabelecimento = removeConfiguracaoEstabelecimento;
        selfService.permiteMultiturno = permiteMultiturno;
        selfService.remanejamento = remanejamento;

        function configuracaoValida(modalidade) {
            var nivelModalidade = modalidade || 'EDUCACAO_BASICA';
            return selfService.getEndpoint()
                .all('configuracao-valida')
                .one('nivel-modalidade', nivelModalidade)
                .get();
        }

        function configuracaoSecretaria(modalidade) {
            var nivelModalidade = modalidade || 'EDUCACAO_BASICA';

            return selfService.getEndpoint()
                .all('configuracao-secretaria')
                .one('nivel-modalidade', nivelModalidade)
                .get();
        }

        function configuracao(estabelecimento, nivelModalidade) {
            if (estabelecimento) {
                return configuracaoValida(nivelModalidade);
            } else {
                return configuracaoSecretaria(nivelModalidade);
            }
        }

        function atualizarMatriculas(matriculas) {
            return selfService.getEndpoint()
                .one('atualizarMatriculas')
                .customPUT(matriculas);
        }

        function getDocumentos(idEstabelecimento, filter) {
            return selfService.getEndpoint()
                .one('documentos')
                .get({
                    filter: filter,
                    estabelecimento: idEstabelecimento
                });
        }

        function removeConfiguracaoEstabelecimento(id) {
            return selfService.getEndpoint()
                .all('remove-configuracoes-estabelecimento')
                .one('id', id)
                .remove();
        }

        function permiteMultiturno(params) {
            return selfService.getEndpoint()
                .one('permite-multiturno')
                .get(params);
        }

        function remanejamento(params) {
            return selfService.getEndpoint()
                .one('remanejamento')
                .get(params);
        }

        return selfService;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edRadioButton', edRadioButton);

    edRadioButton.$inject = [];

    function edRadioButton() {

        function _link(scope, element, attrs, ngModel) {
            var ON = 'icon-circle',
                OFF = 'icon-circle-o';

            function _validate(value) {
                if (!scope.enum) {
                    scope.classOn = _.isUndefined(value) || _.isNull(value) ? OFF :
                        !value ? OFF : ON;
                    scope.classOff = _.isUndefined(value) || _.isNull(value) ? OFF :
                        !value ? ON : OFF;

                } else {
                    scope.classOn = _.isUndefined(value) || _.isNull(value) ? OFF :
                        value === scope.keys[0] ? ON : OFF;
                    scope.classOff = _.isUndefined(value) || _.isNull(value) ? OFF :
                        value === scope.keys[0] ? OFF : ON;
                }
            }

            scope.$watch('enum', function() {
                scope.keys = _.keys(scope.enum);
            });

            scope.$watch('ngModel', function(newValue, oldValue) {
                if (newValue !== oldValue || ngModel.$pristine) {
                    _validate(newValue);
                }
            });
        }

        return {
            templateUrl: 'common/directives/templates/ed-radio-button.template.html',
            restrict: 'E',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                enum: '=',
                label: '@',
                required: '@',
                disabled: '=',
                labelTrue: '@',
                labelFalse: '@'
            },
            link: _link
        };
    }
})();

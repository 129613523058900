(function () {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.CadastroBairroController', CadastroBairroController);

    CadastroBairroController.$inject = [
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'educacao.enderecos.BairroService',
        'bfc.Focus'
    ];

    function CadastroBairroController($scope, $modalInstance, bfcNotification, BairroService, focus) {
        var vm = this;
        var REQ_TYPE_SAVE = 'save';
        var REQ_TYPE_REMOVE = 'remove';

        vm.bairro = $modalInstance.params.bairro || {};
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd !== false;
        vm.editando = _.get($modalInstance.params.bairro, 'id');
        vm.save = save;
        vm.remove = remove;
        vm.getModalTitle = getModalTitle;

        vm.permissions = {
            save: {
                bfPermissions: 'app.enderecos.bairro',
                operation: 'criar'
            },
            update: {
                bfPermissions: 'app.enderecos.bairro',
                operation: 'editar'
            },
            remove: {
                bfPermissions: 'app.enderecos.bairro',
                operation: 'excluir'
            }
        };

        function save(continuar) {
            return BairroService
                .save(vm.bairro)
                .then(function (data) {
                    vm.bairro = data;
                    handleSuccessNotification(continuar, REQ_TYPE_SAVE);
                });
        }

        function remove() {
            return BairroService
                .remove(vm.bairro)
                .then(function () {
                    handleSuccessNotification(false, REQ_TYPE_REMOVE);
                });
        }

        function handleSuccessNotification(continuar, reqType) {
            publishNotification(reqType);

            if (continuar) {
                return refresh();
            }

            closeModal();
        }

        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            vm.bairro = {};
            focus('descricaoBairro');
        }

        function closeModal() {
            $modalInstance.close(vm.bairro);
        }

        function publishNotification(reqType) {
            if (reqType === REQ_TYPE_SAVE) {
                return bfcNotification.publish('Bairro ' + vm.bairro.nome + ' - ' + vm.bairro.municipio.nome + ' salvo com sucesso.', 'success');
            }

            return bfcNotification.publish('Bairro ' + vm.bairro.nome + ' - ' + vm.bairro.municipio.nome + ' excluído com sucesso.', 'success');
        }

        function getModalTitle() {
            if (vm.bairro && vm.bairro.id) {
                return 'Editando bairro';
            }

            return 'Adicionando bairro';
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.LinguaIndigenaService', LinguaIndigenaService)
        .factory('educacao.common.LinguaIndigenaSelect', LinguaIndigenaSelect);

    LinguaIndigenaService.$inject = ['$injector'];
    LinguaIndigenaSelect.$inject = ['$injector'];

    function LinguaIndigenaService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .createReadOnly()
            .module({
                uri: 'inep'
            })
            .service({
                uri: 'lingua-indigena-inep'
            });
    }

    function LinguaIndigenaSelect($injector) {

        var service = $injector.get('educacao.common.LinguaIndigenaService');
        var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');

        return {
            select2Config: function() {
                return CommonSelectWithService.select2Config(service, 'descricao');
            }
        };

    }
})();

(function () {

    /**
     * Diretiva de select2 para lotações físicas 
     * 
     * Parâmetros: 
     * ngModel -> model para vínculo
     * ngRequired -> objeto para indicar se o campo é obrigatorio ou não
     * ngChange -> função para ser executada após o valor do objeto for modificado 
     * lotacoesExistentes -> lista com lotações físicas que devem ser removidas da pesquisa
     * dropdownCssClass -> string da class para de dropdown para ser passada ao ui-select  
     */

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appSelectLotacaoFisica', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchLotacaoFisica" add-value="vm.add"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                ngChange: '&?',
                lotacoesExistentes: '=',
                dropdownCssClass: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }
                if (angular.isDefined(tAttrs.ngRequired)) {
                    element.children('ui-select').attr('not-empty', 'ngRequired');
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = [
        '$scope',
        'ui.components.Modal',
        'bfc.$$PermissionsService',
        'educacao.funcionario.LotacaoFisicaService'
    ];

    function Controller($scope, uiModal, PermissionsService, LotacaoFisicaService) {
        var vm = this;

        vm.add = !PermissionsService.isRevokedOperation('LotacaoFisicaPageMapping', 'criar') && add;

        vm.searchLotacaoFisica = searchLotacaoFisica;

        function searchLotacaoFisica(params) {

            var filter = '(descricao like "' + params.term + '"';

            var lotacoes = [];

            if ($scope.multiple) {
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (lotacao) {
                        lotacoes.push(lotacao.id);
                    });
                }
            }

            if (angular.isArray($scope.lotacoesExistentes) && $scope.lotacoesExistentes.length > 0) {
                _.forEach($scope.lotacoesExistentes, function (value) {
                    if (value.lotacao && value.lotacao.id) {
                        lotacoes.push(value.lotacao.id);
                    }
                });
            }

            if (lotacoes.length) {
                filter += ' and id not in(' + lotacoes.join() + ')';
            }
            filter += ')';

            return LotacaoFisicaService.list(filter, params.offset, params.limit).then(function (data) {
                return {
                    
                    content: data.content,
                    hasNext: false
                };
            });

        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'planejamento/funcionarios/lotacao-fisica/lotacao-fisica.cad.html',
                controller: 'educacao.funcionario.LotacaoFisicaCadCtrl',
                controllerAs: 'vm',
                params: {
                    lotacao: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }
    }

})();
(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .service('educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService', QuadroFuncionariosGestaoPessoasService);

    QuadroFuncionariosGestaoPessoasService.$inject = [
        'ServiceBuilder',
        'Restangular'
    ];

    function QuadroFuncionariosGestaoPessoasService(ServiceBuilder, Restangular) {
        var baseUrl = 'gestao-pessoas-quadro-informativo';
        var baseUrlVisaoGeral = 'gestao-pessoas-quadro-informativo/visao-geral';
        var baseUrlVisaoQuadro = 'visao-geral-quadro-informativo';
        var baseUrlProfessores = 'gestao-pessoas-quadro-informativo/professores';

        var service = ServiceBuilder.create()
            .path(baseUrl)
            .build();

        service.listOutrosFuncionarios = listOutrosFuncionarios;
        service.listFuncionariosSubstitutos = listFuncionariosSubstitutos;
        service.getMotivosSubstituicaoTitulares = getMotivosSubstituicaoTitulares;
        service.getLocaisTrabalhoProfessoresSubstitutos = getLocaisTrabalhoProfessoresSubstitutos;
        service.listCargosComissionados = listCargosComissionados;
        service.getDadosProfessores = getDadosProfessores;
        service.getDadosProfessoresEnturmados = getDadosProfessoresEnturmados;
        service.getDadosOutrosFuncionarios = getDadosOutrosFuncionarios;
        service.getTotalAulasSemanais = getTotalAulasSemanais;
        service.getTotalAulasOcupadasSemanais = getTotalAulasOcupadasSemanais;
        service.getSaldoAulasPorComponenteCurricular = getSaldoAulasPorComponenteCurricular;
        service.getSaldoAulasPorComponenteCurricularGroupEstabelecimento = getSaldoAulasPorComponenteCurricularGroupEstabelecimento;
        service.listAulas = listAulas;
        service.listEstabelecimentos = listEstabelecimentos;
        service.listComponenteCurricular = listComponenteCurricular;
        service.listDuracao = listDuracao;
        service.getAnosLetivos = getAnosLetivos;
        service.listComponenteCurricularProfessores = listComponenteCurricularProfessores;
        service.listProfessores = listProfessores;
        service.listLocaisTrabalhoByProfessor = listLocaisTrabalhoByProfessor;
        service.listCargaHoraria = listCargaHoraria;
        service.listAtribuicaoAulasLocalTrabalho = listAtribuicaoAulasLocalTrabalho;
        service.listTurmasLocalTrabalho = listTurmasLocalTrabalho;
        service.checkPossuiLicencaQuadroInformativo = checkPossuiLicencaQuadroInformativo;

        function listOutrosFuncionarios(params) {
            return Restangular.all(baseUrl + '/outros-funcionarios').get('', params);
        }

        function listFuncionariosSubstitutos(params) {
            return Restangular.all(baseUrl + '/funcionarios-substitutos').get('', params).then(strip);
        }
        function getLocaisTrabalhoProfessoresSubstitutos(params) {
            return Restangular.all(baseUrl + '/funcionarios-substitutos/locais-trabalho').get('', params).then(strip);
        }
        function getMotivosSubstituicaoTitulares(params) {
            return Restangular.all(baseUrl + '/funcionarios-substitutos/professores-titulares').get('', params).then(strip);
        }


        function listCargosComissionados(params) {
            return Restangular.all(baseUrl + '/outros-funcionarios/cargos-comissionados').get('', params);
        }

        function getDadosProfessores(params) {
            return Restangular.all(baseUrlVisaoGeral + '/professores').get('', params).then(strip);
        }

        function checkPossuiLicencaQuadroInformativo(params) {
            return Restangular.all(baseUrlVisaoGeral + '/possui-licenca').get('', params).then(strip);
        }

        function getDadosProfessoresEnturmados(params) {
            return Restangular.all(baseUrlVisaoGeral + '/professores-enturmados').get('', params).then(strip);
        }

        function getDadosOutrosFuncionarios(params) {
            return Restangular.all(baseUrlVisaoGeral + '/outros-funcionarios').get('', params).then(strip);
        }

        function getTotalAulasSemanais(params) {
            return Restangular.all(baseUrl + '/aulas/total-aulas-semanais').get('', params);
        }

        function getTotalAulasOcupadasSemanais(params) {
            return Restangular.all(baseUrl + '/aulas/total-aulas-ocupadas-semanais').get('', params);
        }

        function getSaldoAulasPorComponenteCurricular(params) {
            return Restangular.all(baseUrl + '/aulas/por-componente-curricular').get('', params).then(strip);
        }

        function getSaldoAulasPorComponenteCurricularGroupEstabelecimento(params) {
            return Restangular.all(baseUrl + '/aulas/saldos-por-estabelecimento').get('', params).then(strip);
        }

        function listAulas(params) {
            return Restangular.all(baseUrl + '/aulas').get('', params).then(strip);
        }

        function listEstabelecimentos(params) {
            return Restangular.all(baseUrl + '/aulas/estabelecimentos-aulas-gestao-pessoas').get('', params).then(strip);
        }

        function listComponenteCurricular(params) {
            return Restangular.all(baseUrl + '/aulas/componentes-curriculares-aulas-gestao-pessoas').get('', params).then(strip);
        }

        function listDuracao(params) {
            return Restangular.all(baseUrl + '/aulas/duracao-aulas-gestao-pessoas').get('', params).then(strip);
        }

        function getAnosLetivos(params) {
            return Restangular.all(baseUrlVisaoQuadro + '/anos-letivos-lista-espera').get('', params);
        }

        function listComponenteCurricularProfessores(params) {
            return Restangular.all(baseUrlProfessores + '/componentes-curriculares').get('', params).then(strip);
        }

        function listProfessores(params) {
            return Restangular.all(baseUrlProfessores).get('', params).then(strip);
        }

        function listLocaisTrabalhoByProfessor(funcionarioId, params) {
            return Restangular
                .all(baseUrlProfessores)
                .one('locais-trabalhos-funcionario', funcionarioId)
                .get(params)
                .then(strip);
        }

        function listCargaHoraria(estabelecimentoId) {
            return Restangular
                .all(baseUrlProfessores)
                .one('aulas-carga-horaria-por-duracao', estabelecimentoId)
                .get()
                .then(strip);
        }

        function listAtribuicaoAulasLocalTrabalho(funcionarioId, params) {
            return Restangular
                .all(baseUrlProfessores)
                .one('aulas-por-duracao', funcionarioId)
                .get(params)
                .then(strip);
        }

        function listTurmasLocalTrabalho(funcionarioId, estabelecimentoId, params) {
            return Restangular
                .all(baseUrl)
                .one('professores/' + funcionarioId)
                .one('turmas-local-trabalho', estabelecimentoId)
                .get(params)
                .then(strip);
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }
        
        return service;
    }
})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .directive('inputAlterarDataInscricao', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/inscricao/input-alterar-data-inscricao/input-alterar-data-inscricao.directive.html',
            scope: {},
            bindToController: {
                inscricaoPosicao: '=',
                configuracao: '=',
                tipoDataAlteracao: '=',
                estabId: '=',
                turno: '=',
                labelData: '=',
                labelHora: '=',
                refresh: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'promiseTracker',
        'bfc.Notification'
    ];

    function Controller(InscricaoListaEsperaService, promiseTracker, bfcNotification) {
        var vm = this;

        vm.saveDataInscricao = saveDataInscricao;

        vm.tracker = promiseTracker();

        var templateData = _.template('<%= dataInscricao %>T<%= horaInscricao %>');

        vm.tipoDataAlteracao = !vm.tipoDataAlteracao ? 'DATA_INSC' : vm.tipoDataAlteracao;

        function saveDataInscricao() {
            var data = templateData({
                dataInscricao: vm.dataInscricao,
                horaInscricao: vm.horaInscricao
            });
            vm.tracker.addPromise(
                InscricaoListaEsperaService.salveDataInscricao(vm.configuracao.id, vm.inscricaoPosicao.inscricao.id,
                    vm.estabId, data, vm.tipoDataAlteracao, vm.turno)
                    .then(function () {
                        vm.refresh();
                        bfcNotification.publish('Data de inscrição do candidato alterada', 'success');
                        bfcNotification.publish('Esta ação iniciará um novo processo de classificação, em instantes as posições serão atualizadas', 'info');
                    })
            );
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.pessoas').directive('appSelectEstabelecimentoEnsino', SelectEstabelecimentoEnsinoDirective);

    function SelectEstabelecimentoEnsinoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ngclass="ngclass"  placeholder="placeholder" ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                addOther: '=',
                placeholder:'=',
                ngclass: '='
            },
            controller: SelectEstabelecimentoEnsinoDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectEstabelecimentoEnsinoDirectiveController.$inject = ['$scope', 'educacao.pessoas.EstabelecimentoServiceDeprecated', '$q'];

    function SelectEstabelecimentoEnsinoDirectiveController($scope, service) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function search(params) {

            var filter = '(nome like "' + params.term + '" ';
            if ($scope.multiple) {
                var estebelecimentoIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        estebelecimentoIds.push(model.id);
                    });
                }
                if (estebelecimentoIds.length) {
                    filter += ' and id not in(' + estebelecimentoIds.join() + ')';
                }
            }
            filter += ')';

            return service
                .getList(params.offset, params.limit, filter)
                .then(verificaAddOther)
                .then(toPage);
        }

        function verificaAddOther(data) {
            if (!$scope.addOther){
                return data;
            }
            data.unshift({ id: 0, descricao: 'Outro' });
            return data;            
        }

        function formatResult(data) {
            return data.descricao || data.nome || (data.pessoa ? data.pessoa.nome : '');
        }
    }
})();
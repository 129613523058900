(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('scrollUpButton', scrollUpButton);

    function scrollUpButton() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/scroll-up-button/scroll-up-button.directive.html',
            scope: {},
            bindToController: {},
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$window',
        '$scope'
    ];

    function Controller($window) {
        var vm = this;
        
        vm.onClickButton = onClickButton;
        vm.canShowButton = false;

        function onClickButton() {
            $window.scrollTo(0, 0);
        }
    }

})();
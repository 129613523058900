(function() {
    'use strict';

    angular.module('educacao.common')
        .filter('capitalize', filter);

    function filter() {
        return _.capitalize;
    }
})();

(function () {

    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectAreasAtividadeComplementar', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchAreas"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngChange: '&?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.matricula.AtividadeComplementarService'];

    function Controller(AtividadeComplementarService){

        var vm = this;

        vm.searchAreas = searchAreas;

        function searchAreas(params) {
            
            AtividadeComplementarService.getAreas();

            return AtividadeComplementarService.getAreas().then(function(data) {

                var term = decodeURI(params.term).replace(/%/g, '');

                var content = _.filter(data, function(item) {
                    return item.descricao.toLowerCase().contains(term.toLowerCase());
                }); 

                return {
                    content: content,
                    hasNext: false
                };
            });
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.configuracaoConteudoMinistrado')
        .factory('educacao.configuracaoConteudoMinistrado.ConfiguracaoConteudoMinistradoService',
        ConfiguracaoConteudoMinistradoService);

    ConfiguracaoConteudoMinistradoService.$inject = ['ServiceBuilder'];

    function ConfiguracaoConteudoMinistradoService(ServiceBuilder) {

        var self = ServiceBuilder
            .create()
            .path('conteudo-ministrado', 'configuracao-conteudo-ministrado')
            .enum('ItemConteudoMinistrado')
            .build();

        self.getConfiguracao = getConfiguracao;
        self.restaurarConfiguracao = restaurarConfiguracao;

        function getConfiguracao(tipoEstabSecretaria, tipoConfiguracao) {
            return self.getEndpoint()
                .get(tipoEstabSecretaria + '/' + tipoConfiguracao);
        }

        function restaurarConfiguracao(configuracaoId) {
            return self.getEndpoint()
                .one(String(configuracaoId))
                .remove();
        }

        return self;
    }
})();

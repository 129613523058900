(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.encaminhado')
        .directive('appEncaminhadoListaEspera', appEncaminhadoListaEspera);

    function appEncaminhadoListaEspera() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/encaminhado/candidatos-encaminhados.html',
            scope: {},
            bindToController: {},
            controller: 'educacao.lista-espera-novo.encaminhado-lista-espera.EncaminhadoCtrl',
            controllerAs: 'vm'
        };
    }
})();

(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .controller('educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaCtrl', ConfiguracaoListaEsperaCtrl);

    ConfiguracaoListaEsperaCtrl.$inject = [
        'promiseTracker',
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        'bfc.Notification',
        'ui.components.Modal',
        '$stateParams'
    ];

    function ConfiguracaoListaEsperaCtrl(promiseTracker, ConfiguracaoListaEsperaService, bfcNotification, uiModal, $stateParams) {
        var vm = this;

        vm.tracker = {
            list: promiseTracker()
        };

        vm.listControls = {};
        vm.abreCadastro = abreCadastro;
        vm.remove = remove;
        vm.getConfiguracoes = getConfiguracoes;

        init();

        function init() {
            if ($stateParams.abrirCadastro) {
                abreCadastro();
            }
        }

        function getConfiguracoes(params) {
            var listConfiguracoes = ConfiguracaoListaEsperaService.listByParams(params);
            return listConfiguracoes;
        }

        function abreCadastro(id) {
            uiModal.open({
                templateUrl: 'lista-espera-new/configuracao/cadastro-configuracao-lista-espera.html',
                controller: 'educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaCadCtrl as vm',
                size: 'xl',
                propertyName: 'configuracaoListaEspera',
                params: {
                    configuracaoListaEspera: id ? ConfiguracaoListaEsperaService.get(id) : {},
                }
            }).result
            .then(search);
        }

        function remove(configuracao) {
            ConfiguracaoListaEsperaService.remove(configuracao)
                .then(showMessageDeleted)
                .finally(search);
        }

        function showMessageDeleted() {
            bfcNotification.publish('Configuração removida com sucesso.', 'success');
        }

        function search() {
            if (!_.isFunction(vm.listControls.search)) {
                return;
            }
            vm.listControls.search();
        }
    }
})();

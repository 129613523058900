(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.ControllerGenerico', Controller);

    Controller.$inject = ['$injector', '$rootScope', '$scope', '$modalInstance', '$modalTracker', '$q', 'ModalCalCompTransfer'];

    /*
     * @author marlon.souza
     * este controller destina se a moda que não salvam informaçoes na base diretamente
     * 
     * @param ModalCalCompTransfer passado pelo open do modalcad
     * @return data via brodcast de acordo com o nome do evento informado em ModalCalCompTransfer.brodcastReturn
     */

    function Controller($injector, $rootScope, $scope, $modalInstance, $modalTracker, $q, ModalCalCompTransfer) {
        var promiseTracker = $injector.get('promiseTracker');
        var Notification = $injector.get('bfc.Notification');

        if (!_.has(ModalCalCompTransfer, 'brodcastReturn')) {
            throw new Error('Deve ser informado o nome do brodcast que será retornado (ModalCalCompTransfer.brodcastReturn)');
        }
        if (!_.has(ModalCalCompTransfer, 'valueData')) {
            throw new Error('Deve ser informado o objeto de dados no (ModalCalCompTransfer.valueData)');
        }

        var vm = this;

        vm.tracker = {};
        vm.tracker.salvar = promiseTracker();

        var propriedadesPublicas = {
            cancel: _cancel,
            form: undefined,
            ok: _ok,
            data: ModalCalCompTransfer.valueData,
            select2: _.has(ModalCalCompTransfer, 'select2') ? ModalCalCompTransfer.select2 : undefined,
            anoAtual: new Date().getFullYear(),
            formatResult: formatResultEnum
        };

        $modalTracker.addPromise(init());

        function init() {

            return $q.all([angular.extend(vm, propriedadesPublicas)]);
        }

        function formatResultEnum(data) {
            return data.value;
        }

        function _ok() {
            var errorValidation = false;

            if (Array.isArray($scope.validations)) {
                _.values($scope.validations).some(
                    function(validation) {
                        errorValidation = !validation.isValid;

                        if (errorValidation) {
                            Notification.publish(validation.message, validation.typeMessage);
                        }

                        return errorValidation;
                    }
                );
            }

            if (errorValidation) {
                return;
            }

            $rootScope.$broadcast(
                ModalCalCompTransfer.brodcastReturn, {
                    data: vm.data
                }
            );

            $modalInstance.close();
        }

        function _cancel() {
            $modalInstance.close();
        }

        // function _notify(message, type) {
        //     Notification.publish(message, type);
        // }
    }
})();

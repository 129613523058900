(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaInscricao', appConfiguracaoListaEsperaInscricao);

    function appConfiguracaoListaEsperaInscricao() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-inscricao/app-configuracao-lista-espera-inscricao.directive.html',
            scope: {},
            bindToController: {
                configuracao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        '$window',
        '$scope'
    ];

    function Controller(ConfigListaEsperaService, $window, $scope) {
        var vm = this;

        vm.verificaQtdTurnoDown = verificaQtdTurnoDown;
        vm.verificaQtdTurnoUp = verificaQtdTurnoUp;
        vm.verificaQtdEstabelecimentoDown = verificaQtdEstabelecimentoDown;
        vm.verificaQtdEstabelecimentoUp = verificaQtdEstabelecimentoUp;
        vm.verificaQtdDiasEncaminhamentoDown = verificaQtdDiasEncaminhamentoDown;
        vm.verificaQtdDiasEncaminhamentoUp = verificaQtdDiasEncaminhamentoUp;
        vm.verificaEncaminhamento = verificaEncaminhamento;
        vm.changePermiteAlterarDh = changePermiteAlterarDh;
        vm.formatResultEnumListaEspera = formatResultEnumListaEspera;

        var clipboard = new $window.Clipboard('.clipboard-button');

        vm.configuracao.permiteAlterarDataHora = vm.configuracao.permiteAlterarDataHora ? vm.configuracao.permiteAlterarDataHora : false;

        $scope.$on('$destroy', function () {
            clipboard.destroy();
        });

        ConfigListaEsperaService.getUrlInscricao()
            .then(function (dado) {
                vm.configuracao.urlInscricao = dado;
            });
        
        function verificaQtdEstabelecimentoUp(){
            if(vm.configuracao.qtdEstabelecimentos < 999){
                vm.configuracao.qtdEstabelecimentos++;
                return;
            }
            vm.configuracao.qtdEstabelecimentos = vm.configuracao.qtdEstabelecimentos || 1;
        }

       function formatResultEnumListaEspera(data){
           return data.descricacao;
       }
        
        function verificaQtdEstabelecimentoDown(){
            if(vm.configuracao.qtdEstabelecimentos > 1){
                vm.configuracao.qtdEstabelecimentos--;
                return;
            }
            vm.configuracao.qtdEstabelecimentos = vm.configuracao.qtdEstabelecimentos || 1;
        }

        function verificaQtdTurnoUp(){
            if(vm.configuracao.qtdTurnos < 4){
                vm.configuracao.qtdTurnos++;
                return;
            }
            vm.configuracao.qtdTurnos = vm.configuracao.qtdTurnos || 1;
        }
        
        function verificaQtdTurnoDown(){
            if(vm.configuracao.qtdTurnos > 1){
                vm.configuracao.qtdTurnos--;
                return;
            }
            vm.configuracao.qtdTurnos = vm.configuracao.qtdTurnos || 1;
        }

        function changePermiteAlterarDh() {
            if(!vm.configuracao.permiteAlterarDataHora) {
                vm.configuracao.tipoAlteracaoDataHoraList = [];
            }
        }

        function verificaQtdDiasEncaminhamentoUp(){
            if (!vm.configuracao.encaminhamento) {
                return;
            }

            if(vm.configuracao.qtdDiasEncaminhamento < 999){
                vm.configuracao.qtdDiasEncaminhamento++;
                return;
            }

            vm.configuracao.qtdDiasEncaminhamento = vm.configuracao.qtdDiasEncaminhamento || 1;
        }
        
        function verificaQtdDiasEncaminhamentoDown(){
            if (!vm.configuracao.encaminhamento) {
                return;
            }
            
            if(vm.configuracao.qtdDiasEncaminhamento > 1){
                vm.configuracao.qtdDiasEncaminhamento--;
                return;
            }

            vm.configuracao.qtdDiasEncaminhamento = vm.configuracao.qtdDiasEncaminhamento || 1;
        }

        function verificaEncaminhamento(){
            if (vm.configuracao.encaminhamento) {
                return;
            }
            vm.configuracao.qtdDiasEncaminhamento = null;
        }
    }
})();

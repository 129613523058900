(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .service('educacao.desempenhoescolar.DesempenhoEscolarUtilsService', DesempenhoEscolarUtilsService);

    DesempenhoEscolarUtilsService.$inject = [
        'ITEM_AVALIAVEL_MODO_AVALIACAO',
        'ITEM_AVALIAVEL_TIPO_PERIODO'
    ];

    function DesempenhoEscolarUtilsService(ITEM_AVALIAVEL_MODO_AVALIACAO, ITEM_AVALIAVEL_TIPO_PERIODO) {

        var self = this;

        self.isModoAvaliacaoNumerico = isModoAvaliacaoNumerico;
        self.isModoAvaliacaoDescritivo = isModoAvaliacaoDescritivo;
        self.isModoAvaliacaoConceito = isModoAvaliacaoConceito;
        self.isModoAvaliacaoConceitoNumerico = isModoAvaliacaoConceitoNumerico;
        self.hasAnyNotaValida = hasAnyNotaValida;
        self.hasAnyNotaNumericaValida = hasAnyNotaNumericaValida;
        self.isItemEditavel = isItemEditavel;
        self.isPermiteCha = isPermiteCha;
        self.isPermiteParecer = isPermiteParecer;
        self.isModoAvaliacaoDescritivoConceito = isModoAvaliacaoDescritivoConceito;

        function isModoAvaliacaoNumerico(modoAvaliacao) {
            return (modoAvaliacao === ITEM_AVALIAVEL_MODO_AVALIACAO.NUMERICO);
        }

        function isModoAvaliacaoDescritivo(modoAvaliacao) {
            return (modoAvaliacao === ITEM_AVALIAVEL_MODO_AVALIACAO.DESCRITIVO);
        }

        function isModoAvaliacaoConceito(modoAvaliacao) {
            return (modoAvaliacao === ITEM_AVALIAVEL_MODO_AVALIACAO.CONCEITO);
        }

        function isModoAvaliacaoConceitoNumerico(modoAvaliacao) {
            return (modoAvaliacao === ITEM_AVALIAVEL_MODO_AVALIACAO.CONCEITO_NUMERICO);
        }

        function isModoAvaliacaoDescritivoConceito(modoAvaliacao) {
            return (isModoAvaliacaoConceito(modoAvaliacao) || isModoAvaliacaoDescritivo(modoAvaliacao));
        }

        function hasAnyNotaValida(registrosAvaliacoes) {

            if (!_.isArray(registrosAvaliacoes)) {
                return isValid(registrosAvaliacoes);
            }

            var notaValida = _.find(registrosAvaliacoes, isValid);

            return notaValida ? true : false;

            function isValid(registro) {
                return ((!_.isUndefined(registro.notaNumerica) && !_.isNull(registro.notaNumerica)) ||
                    registro.notaConceito || registro.notaDescritiva) ? true : false;
            }
        }

        function hasAnyNotaNumericaValida(registrosAvaliacoes) {

            if (!_.isArray(registrosAvaliacoes)) {
                return isValid(registrosAvaliacoes);
            }

            return _.find(registrosAvaliacoes, isValid) ? true : false;

            function isValid(registro) {
                return (!_.isUndefined(registro.notaNumerica) && !_.isNull(registro.notaNumerica));
            }
        }

        function isItemEditavel(tipoPeriodo, modoAvaliacao) {
            return !(tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.CALCULO_MEDIA ||
                tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME ||
                (tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL) &&
                (isModoAvaliacaoConceitoNumerico(modoAvaliacao) || isModoAvaliacaoNumerico(modoAvaliacao))
            );
        }

        function isPermiteCha(tipoPeriodo) {
            return (tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO ||
                tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL);
        }

        function isPermiteParecer(tipoPeriodo) {
            return (tipoPeriodo !== ITEM_AVALIAVEL_TIPO_PERIODO.CALCULO_MEDIA ||
                tipoPeriodo !== ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME);
        }
    }

})();


(function () {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioSecretariaService', CalendarioService);

    CalendarioService.$inject = ['Restangular'];

    function CalendarioService(Restangular) {
        var base = Restangular.one('calendario/secretaria');

        return {
            getCalendarioAnoLetivo: getCalendarioAnoLetivo,
            listAvisos: listAvisos,
            save: save
        };

        function getCalendarioAnoLetivo() {
            return base.get();
        }

        function listAvisos(calendarioSecretaria) {
            return base.one(calendarioSecretaria.id.toString()).all('avisos').getList();
        }

        function save(calendario) {
            calendario = plain(calendario);

            if (calendario.id) {
                return base.customPUT(calendario, calendario.id);
            } else {
                return base.post('', calendario);
            }
        }

        function plain(obj) {
            if (obj === null) {
                return null;
            }
            if (!angular.isObject(obj)) {
                return obj;
            }

            angular.forEach(obj, function (value, key) {
                if (angular.isArray(value)) {
                    plainArray(value);
                } else if (angular.isObject(value)) {
                    obj[key] = plain(value);
                }
            });

            return Restangular.stripRestangular(obj);
        }

        function plainArray(objectArray) {
            if (!objectArray) {
                return;
            }
            angular.forEach(objectArray, function (value, index) {
                objectArray[index] = plain(value);
            });
        }
    }
})();

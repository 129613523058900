(function () {
    'use strict';
    angular.module('educacao.pessoas').directive('appPessoaFiliacaoResponsaveis', function () {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'planejamento/pessoa/app-pessoa-filiacao-responsaveis.directive.html';
        config.scope = {};
        config.bindToController = {
            aluno: '=ngModel',
            service: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;

    });
    Controller.$inject = [
        '$scope',
        '$q',
        'ui.components.Modal',
        'educacao.pessoas.ResponsavelService',
        'educacao.pessoas.FiliacaoService',
        'promiseTracker',
        '$enumsCache'
    ];

    function Controller($scope, $q, $modal, ResponsavelService, FiliacaoService, promiseTracker,$enumsCache) {
        var vm = this;

        vm.reloadList = promiseTracker();
        vm.openModalResponsavel = openModalResponsavel;
        vm.openModalFiliacao = openModalFiliacao;
        vm.listarResponsaveis = listarResponsaveis;
        inicializaEnuns();
        listarResponsaveis();
        vm.removeFiliacao = removeFiliacao;
        vm.removeResponsavel = removeResponsavel;
        vm.isPossuiValue = isPossuiValue;


        function inicializaEnuns() {
            FiliacaoService.getTipoFiliacao().then(
                function (data) {
                    vm.enumTipoFiliacao = data;
                }
            );
            $enumsCache.get('Parentesco').then(
                function (data) {
                    vm.enumParentesco = data;
                }
            );
            $enumsCache.get('DiaDaSemanaDto').then(
                function (data) {
                    vm.enumDiasDaSemana = data;
                }
            );
        }

        function openModalResponsavel(vinculo) {
            vinculo = vinculo || {};
            return $modal.open({
                templateUrl: 'planejamento/alunos/responsaveis/cadastro-responsaveis.html',
                controller: 'educacao.pessoas.ResponsavelCadCtrl as vm',
                size: 'lg',

                params: {
                    responsavel: _.get(vinculo, 'responsavel') ? ResponsavelService.get(vinculo.responsavel.id) : {},
                    vinculo: {
                        permiteRetirarAluno: vinculo ? angular.copy(vinculo.permiteRetirarAluno) : null,
                        diasDaSemana: vinculo.diaSemana || [],
                        parentesco: vinculo.parentesco,
                        outroParentesco: vinculo.outroParentesco,
                        aluno: angular.copy(vm.aluno)
                    },

                }
            }).result.then(afterSaveResponsavel);

        }

        function openModalFiliacao(vinculo) {
            vinculo = vinculo || {};
            return $modal.open({
                templateUrl: 'planejamento/alunos/filiacao/cadastro-filiacao.html',
                controller: 'educacao.pessoas.FiliacaoCadCtrl as vm',
                size: 'xl',
                params: {
                    filiacao: _.get(vinculo, 'filiacao') ? FiliacaoService.get(vinculo.filiacao.id) : {},
                    vinculo: {
                        permiteRetirarAluno: vinculo ? angular.copy(vinculo.permiteRetirarAluno) : null,
                        diasDaSemana: vinculo.diaSemana || [],
                        ehResponsavel: vinculo.ehResponsavel,
                        tipo: vinculo.tipo,
                        natureza: vinculo.natureza
                    },
                    aluno: angular.copy(vm.aluno)
                }
            }).result.then(afterSaveFiliacao);
        }

        function listarResponsaveis() {
            var filiacoesResposanveisToo = vm.aluno.filiacoes.filter(
                function (filiacao) {
                    if (!!filiacao.ehResponsavel) {
                        return filiacao;
                    }
                }
            );

            vm.listResponsaveis = [];

            vm.listResponsaveis =
                vm.listResponsaveis
                    .concat(filiacoesResposanveisToo.map(
                        function (filiacao) {
                            return angular.extend(filiacao, {
                                typeResponsavel: false,
                                typeFiliacao: true
                            });
                        }
                    ))
                    .concat(vm.aluno.responsaveis.map(
                        function (responsavel) {
                            return angular.extend(responsavel, {
                                typeResponsavel: true,
                                typeFiliacao: false
                            });
                        }
                    ))
                    .sort(
                    function (objetoUm, objetoDois) {
                        //feito isso para manter a lógica já existente 
                        var nomeUm, nomeDois;
                        if (_.get(objetoUm, 'pessoa')) {
                            nomeUm = objetoUm.pessoa.nome.toLowerCase();
                        } else {
                            var objeto = objetoUm.responsavel || objetoUm.filiacao;
                            nomeUm = objeto.nome.toLowerCase();
                        }
                        if (_.get(objetoDois, 'pessoa')) {
                            nomeDois = objeto.nome.toLowerCase();
                        } else {
                            var objeto2 = objetoUm.responsavel || objetoUm.filiacao;
                            nomeDois = objeto2.nome.toLowerCase();
                        }

                        if (nomeUm < nomeDois) {
                            return -1;
                        }
                        if (nomeUm > nomeDois) {
                            return 1;
                        }
                        return 0;
                    });
        }

        function afterSaveResponsavel(objetoRetorno) {
            if (!objetoRetorno) {
                return;
            }
            if (objetoRetorno.remove) {
                removeResponsavelList(objetoRetorno.vinculo);
                listarResponsaveis();
                return;
            }
            if (_.get(objetoRetorno.vinculos, 'length')) {
                angular.forEach(objetoRetorno.vinculos, function (value) {
                    vm.aluno.responsaveis.push(value);
                });
                listarResponsaveis();
                return;
            }
            if (objetoRetorno.edit) {
                atualizaResponsavel(objetoRetorno);
            } else {
                vm.aluno.responsaveis.push(objetoRetorno.vinculo);
            }
            listarResponsaveis();
        }

        function removeResponsavelList(vinculo) {
            var indexOrdem = _.findIndex(vm.aluno.responsaveis, function (data) {
                return data.responsavel.id === vinculo.responsavel.id;
            });
            if(indexOrdem < 0){
                return;
            }
            vm.aluno.responsaveis.splice(indexOrdem, 1);
        }
        function removeFiliacaoList(vinculo) {
            var indexOrdem = _.findIndex(vm.aluno.filiacoes, function (data) {
                return data.filiacao.id === vinculo.filiacao.id;
            });
            if(indexOrdem < 0){
                return;
            }
            vm.aluno.filiacoes.splice(indexOrdem, 1);
        }
        function atualizaResponsavel(objeto) {
            removeResponsavelList(objeto.vinculo);
            var responsavelAtualizado = angular.copy(objeto.vinculo);
            vm.aluno.responsaveis.push(responsavelAtualizado);
            listarResponsaveis();
        }
        function atualizaFiliacao(objeto) {
            removeFiliacaoList(objeto.vinculo);
            var filiacaoAtualizada = angular.copy(objeto.vinculo);
            vm.aluno.filiacoes.push(filiacaoAtualizada);
            listarResponsaveis();
        }

        function afterSaveFiliacao(objetoRetorno) {

            if (!objetoRetorno) {
                return;
            }
            if (objetoRetorno.remove) {
                removeFiliacaoList(objetoRetorno.vinculo);
                listarResponsaveis();
                return;
            }
            if (_.get(objetoRetorno.vinculos, 'length')) {
                angular.forEach(objetoRetorno.vinculos, function (value) {
                    vm.aluno.filiacoes.push(value);
                });
                listarResponsaveis();
                return;
            }
            if (objetoRetorno.edit) {
                atualizaFiliacao(objetoRetorno);
            } else {
                vm.aluno.filiacoes.push(objetoRetorno.vinculo);
            }
            listarResponsaveis();
        }

        function removeFiliacao(vinculo) {
            return $q.when(removeFiliacaoList(vinculo)).then(listarResponsaveis());
        }

        function removeResponsavel(vinculo) {
            return $q.when(removeResponsavelList(vinculo)).then(listarResponsaveis());
        }

        function isPossuiValue(value) {
            return angular.isDefined(value);
        }
    }

})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.ClassificacaoInscritosListaEncaminhadoLoteCtrl', ClassificacaoInscritosListaEncaminhadoLoteCtrl);

	ClassificacaoInscritosListaEncaminhadoLoteCtrl.$inject = [
		'promiseTracker',
		'educacao.matricula.ClassificacaoEncaminhadosService',
		'$rootScope',
		'$modalInstance',
		'$q',
		'$enumsCache',
		'ui.components.Modal'
	];

	function ClassificacaoInscritosListaEncaminhadoLoteCtrl(
		promiseTracker,
		ClassificacaoEncaminhadosService,
		$rootScope,
		$modalInstance,
		$q,
		$enumsCache,
		uiModal
	) {

		var vm = this;

		var CLASSIFICADO_COM_VAGA = 'CLASSIFICADO_COM_VAGA';

		vm.tracker = {
			loading: promiseTracker(),
			botoes: promiseTracker()
		};

		vm.save = save;
		vm.hasVaga = hasVaga;
		vm.deleteItem = deleteItem;

		vm.classificacoes = _.sortBy($modalInstance.params.classificacoes, 'classificacaoInscricao.resultado.posicao');
		vm.nivelEscolarSelecionado = $modalInstance.params.nivelEscolarSelecionado;

		init();

		function init() {
			var configuracaoId = getConfiguracaoId();
			
			if (!configuracaoId) {
				return;
			}

			vm.tracker.loading.addPromise(
				ClassificacaoEncaminhadosService.getDefault(configuracaoId).then(function(data) {					
					vm.estabelecimento = _.get(_.first(vm.classificacoes), 'classificacaoInscricao.estabelecimento');
					vm.defaultConfig = data;
					vm.listaEncaminhado = getListaEncaminhamento(data);
				}).then(getEnums)
			);
		}

		function getEnums() {
			$enumsCache.get('Turno').then(function (data) {
				vm.turnos = data;
			});

			$enumsCache.get('NivelEscolar').then(function (data) {
				vm.nivelEscolar = data;
			});
		}

		function getConfiguracaoId() {
			return _.get(_.first(vm.classificacoes), 'classificacaoInscricao.configuracaoInscricao.id');
		}

		function getListaEncaminhamento(data) {
			return _.map(vm.classificacoes, function(resultadoClassificacao) {
				return {
					resultado: {
						id: resultadoClassificacao.id
					},
					etapa: _.get(resultadoClassificacao, 'classificacaoInscricao.etapa'),
					turno: _.get(resultadoClassificacao, 'classificacaoInscricao.turno'),
					chamada: _.get(resultadoClassificacao, 'classificacaoInscricao.chamada'),
					type: data.type,
					estabelecimento: data.estabelecimento,
					descricao: data.descricao
				};
			});
		}

		function save() {
			var configuracaoId = getConfiguracaoId();
			if (!configuracaoId) {
				return;
			}

			var listaEncaminhado = setListaEncaminhamentoEstabelecimento();
			
			vm.tracker.botoes.addPromise(
				$q.all(getPromise(configuracaoId, listaEncaminhado))
				.then(close)
				.finally(emitClearEncaminhamentoLote)
			);
		}

		function getPromise(configuracaoId, listaEncaminhado) {
			return listaEncaminhado.map(function (encaminhado) {
				return saveEncaminhamento(configuracaoId, encaminhado);
			});
		}

		function saveEncaminhamento(configuracaoId, listaEncaminhado) {
			ClassificacaoEncaminhadosService.encaminhar(configuracaoId, listaEncaminhado)
				.then(function(data) {
					$rootScope.$broadcast('refresh::classificacoes', {
						data: data,
						listaEncaminhado: true
					});
				});
		}

		function setListaEncaminhamentoEstabelecimento() {
			var listaEncaminhado = _.cloneDeep(vm.listaEncaminhado);

			_.forEach(listaEncaminhado, function (encaminhado) {
				encaminhado.estabelecimento = _.pick(vm.estabelecimento, 'id');
			});

			return listaEncaminhado;
		}

		function close() {
			$modalInstance.close();
		}

		function hasVaga(classificacao) {
			return Boolean(_.get(classificacao, 'classificacaoInscricao.resultadoChamada.status') === CLASSIFICADO_COM_VAGA);
		}

		function openModalConfirmacaoExclusao(index) {
			uiModal.open({
				templateUrl: 'matricula/classificacao-inscritos/confirmacao-exclusao-encaminhamento-lote.html',
				controller: 'educacao.matricula.ConfirmacaoExclusaoEncaminhadoLoteCtrl',
				controllerAs: 'vm',
				size: 'lg',
				params: {
					classificacoes: vm.classificacoes,
					indexToExclude: index
				}
			})
			.result
			.then(onCloseExclusaoModal);
		}

		function onCloseExclusaoModal(data) {
			vm.classificacoes = _.get(data, 'classificacoes');
			vm.listaEncaminhado = getListaEncaminhamento(vm.defaultConfig);
		}

		function deleteItem(index) {
			openModalConfirmacaoExclusao(index);
		}

		function emitClearEncaminhamentoLote() {
			$rootScope.$broadcast('encaminhamentoLoteItemDeleted', []);
		}
	}

})();

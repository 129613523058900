(function(){

    'use strict';

    angular.module('educacao.itemAvaliavel')
        .constant('ITEM_AVALIAVEL_TIPO', {
            CONFIGURACAO_TURMA: 'CONFIGURACAO_TURMA',
            CHA: 'CHA'
        });

})();
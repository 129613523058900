(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('dadosOutrosFuncionariosGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-outros-funcionarios-gestao-pessoas/dados-outros-funcionarios-gestao-pessoas.html',
            scope: {},
            bindToController: {
                anoLetivoSelected: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope'
    ];

    function Controller($scope) {
        var vm = this;

        vm.firstRow = [];
        vm.secondRow = [];
        vm.nomeFuncionarioFilter = null;
        vm.filtrarCargos = null;
        vm.filtrarSituacao = null;
        vm.filtrarTipoVinculo = null;
        vm.advancedFilter = {};
        vm.mountFilter = mountFilter;
        vm.filter = null;
        vm.isRefresh = false;
        vm.refresh = refresh;
        vm.limparFiltros = limparFiltros;
        $scope.$watch('vm.nomeFuncionarioFilter', reloadClearFilter);
        $scope.$watch('vm.filtrarCargos', reloadClearFilter);
        $scope.$watch('vm.filtrarSituacao', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtrarTipoVinculo', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroFuncao', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroCargoComissionado', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroEstabelecimento', reloadClearFilter);


        function limparFiltros(){
            vm.nomeFuncionarioFilter = null;
            vm.filtrarCargos = null;
            vm.filtrarSituacao = null;
        }

        function reloadClearFilter(newValue) {
            if (newValue === null || _.isEmpty(newValue)) {
                mountFilter();
            }
        }

        function refresh() {
            vm.isRefresh = !vm.isRefresh;
        }

        function mountFilter() {
            var filters = [];

            if (!_.isEmpty(vm.nomeFuncionarioFilter)) {
                filters.push('(servidor.pessoa.nome like "' + encodeURI('%' + vm.nomeFuncionarioFilter + '%') + '")');
            }

            if (!_.isEmpty(vm.filtrarCargos)) {
                filters.push('cargo.id in (' + _.map(vm.filtrarCargos, 'id').join(',') + ')');

            }
            if (!_.isEmpty(vm.filtrarSituacao)) {
                filters.push('situacao in ("' + vm.filtrarSituacao.map(function (item) {
                    return item;
                }).join('","') + '")');
            }

            if (!_.isEmpty(vm.advancedFilter.filtrarTipoVinculo)) {
                filters.push('tipoVinculo in ("' + vm.advancedFilter.filtrarTipoVinculo.map(function (item) {
                    return item;
                }).join('","') + '")');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroFuncao)) {
                filters.push('funcaoGratificada.id in (' + _.map(vm.advancedFilter.filtroFuncao, 'id').join(',') + ')');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroCargoComissionado)) {
                filters.push('cargoComissionado.id in (' + _.map(vm.advancedFilter.filtroCargoComissionado, 'id').join(',') + ')');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroEstabelecimento)) {
                filters.push('locaisTrabalho.estabelecimento.id in (' + _.map(vm.advancedFilter.filtroEstabelecimento, 'id').join(',') + ')');
            }

            var filter = filters.join(' and ');

            vm.filter = filter;

        }

    }
})();
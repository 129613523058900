(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appSelectContextoEstabelecimento', directive);

    directive.$inject = [];
    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/layout/header/contexts-systems/select-contexto-estabelecimento.directive.html',
            scope: {},
            controller: Contoller,
            controllerAs: 'vm'
        };
    }

    Contoller.$inject = [
        'CONTEXTO_ESTABELECIMENTO_SECRETARIA',
        '$scope',
        'promiseTracker',
        'educacao.context.ContextoEstabelecimento'
    ];
    function Contoller(CONTEXTO_ESTABELECIMENTO_SECRETARIA, $scope, promiseTracker, ContextoEstabelecimento) {
        var vm = this;

        vm.tracker = promiseTracker();

        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.estabelecimentos = [];

        vm.setEstabelecimento = setEstabelecimento;

        $scope.$on('app-context:estabelecimento:new', function () {
            vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        }); 

        loadEstabelecimentos();

        function loadEstabelecimentos() {
            vm.tracker.addPromise(
                ContextoEstabelecimento.listaEstabelecimentos()
                    .then(function (data) {
                        vm.estabelecimentos = data;
                    })
            );
        }

        function setEstabelecimento(estabelecimento) {
            ContextoEstabelecimento.setEstabelecimento(estabelecimento);
        }
    }
})();
(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncionarioHistoricoService', FuncionarioHistoricoService);

    FuncionarioHistoricoService.$inject = ['ServiceBuilder', 'Restangular'];

    function FuncionarioHistoricoService(ServiceBuilder, restangular) {
        var self = ServiceBuilder.create()
            .path('funcionario', 'matriculas')
            .build();
        self.getHistorico = getHistorico;
        return self;

        function getHistorico(id,filter, offset, limit){
            return restangular.all('funcionario/matriculas'+id+'/historico').getList({
                filter: filter, 
                offset: offset,
                limit: limit
            });
        }

    }

})();
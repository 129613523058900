(function() {
    'use strict';

    angular.module('educacao.historico')
        .controller('educacao.historico.HistoricoModalController', HistoricoModalController);

    HistoricoModalController.$inject = ['$scope', 'promiseTracker', '$modalInstance', '$searchExpressionBuilder', 'Restangular'];

    function HistoricoModalController($scope, promiseTracker, $modalInstance, $searchExpressionBuilder, Restangular) {

        var vm = this;

        vm.tracker = {
            list: promiseTracker()
        };

        vm.title = $modalInstance.params.title;

        vm.itemsPerPage = 20;
        vm.currentPage = 1;

        vm.searchFilter = {};

        vm.colunms = [
            {
                id: 'usuario.nome',
                label: 'Nome do usuário',
                model: 'usuario.nome',
                filterable: true
            },
            {
                id: 'campo.valorAnterior',
                label: 'Valor',
                model: 'campo.valorAnterior',
                filterable: true
            },
            {
                id: 'campo.valor',
                label: 'Valor',
                model: 'campo.valor',
                filterable: true
            }
        ];

        vm.search = search;

        $scope.$watchGroup(['vm.itemsPerPage', 'vm.currentPage'], search);

        function search() {

            vm.filterExpression = $searchExpressionBuilder.build(vm.searchFilter);

            var currentPage = vm.currentPage - 1;
            var promise = Restangular.all($modalInstance.params.url).get('', {
                offset: currentPage > 0 ? currentPage * vm.itemsPerPage : 0,
                limit: vm.itemsPerPage,
                filter: vm.filterExpression
            }).then(function(page) {
                vm.page = page;
            });

            vm.tracker.list.addPromise(promise);
        }

    }
})();

(function () {
	'use strict';
	angular.module('educacao.lista-espera-novo.inscricao')
		.controller('educacao.lista-espera-novo.inscricao.ModalComprovanteListaEspera', Controller);

    Controller.$inject = ['educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService', 'educacao.pessoas.EstabelecimentoService', '$modalInstance'];

	function Controller(InscricaoListaEsperaService, EstabelecimentoService, $modalInstance) {
		var vm = this;

		vm.getterMethod = EstabelecimentoService.get;
		vm.methodGetConfig = InscricaoListaEsperaService.getConfiguracao;

		vm.inscricao = $modalInstance.params.inscricao;
	}
})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.factory('educacao.matricula.HorasAulaService', HorasAulaService);

	HorasAulaService.$inject = ['$injector'];

	function HorasAulaService($injector) {
		var ServiceBuilder = $injector.get('ServiceBuilder');

		var selfService = ServiceBuilder.create()
			.path('matricula', 'horas-aula')
			.enum('SituacaoCadastral', 'DiaDaSemanaDto')
			.build();

		selfService.getTodos = getTodos;

		function getTodos(params) {
			return selfService.getEndpoint().get('todos', params).then(function(data) {
				return data;
			});
		}

		return selfService;
	}
})();

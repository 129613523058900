(function() {
    'use strict';

    angular.module('educacao.planejamento').controller('educacao.planejamento.TiposDependenciasCtrl', TiposDependenciasCtrl);

    TiposDependenciasCtrl.$inject = ['educacao.planejamento.TiposDependenciasService', 'promiseTracker', 'educacao.common.ModalCad', '$scope', '$rootScope', 'educacao.common.ObjectUtilsService', '$q',
        '$filter'
    ];

    function TiposDependenciasCtrl(TiposDependenciasService, promiseTracker, ModalCad, $scope, $rootScope, ObjectUtilsService, $q, $filter) {

        var vm = this;
        vm.openCad = openCad;
        vm.tracker = {};
        vm.tracker.list = promiseTracker();
        vm.getIconSortClass = getIconSortClass;
        vm.remove = remove;
        vm.orderBy = orderBy;

        init();

        function init() {

            refresh();
        }

        $scope.isSearching = function() {
            return Boolean($scope.search && ($scope.search.filter || $scope.search.value));
        };

        function refresh() {

            vm.tracker.list.addPromise(
                TiposDependenciasService.getList(0, 999).then(function(data) {

                    vm.predicatePadrao = 'id';
                    vm.tipos = $filter('filter')(data, {
                        isPadrao: false
                    });
                    vm.tiposPadroes = $filter('filter')(data, {
                        isPadrao: true
                    });
                })
            );
        }

        function openCad(id) {
            ModalCad.open({
                templateUrl: 'planejamento/tipo-dependencia/tipo-dependencia.cad.html',
                controller: 'educacao.planejamento.TiposDependenciasCadCtrl as TipoCadCtrl',
                id: id,
                readOnly: false
            });
        }

        function getIconSortClass(sortBy, tipo) {
            return {
                'icon-caret-down': tipo === 'U' ? vm.predicate === sortBy && vm.reverse : vm.predicatePadrao === sortBy && vm.predicatePadrao,
                'icon-caret-up': tipo === 'U' ? vm.predicate === sortBy && !vm.reverse : vm.predicatePadrao === sortBy && !vm.reversePadrao,
                'icon-sort': tipo === 'U' ? vm.predicate !== sortBy : vm.predicatePadrao !== sortBy
            };
        }

        function orderBy(item) {
            if (typeof vm.predicate === 'undefined') {
                vm.predicate = 'descricao';
            }
            return normalize(item[vm.predicate]);
        }

        function normalize(value) {
            return value.toLowerCase()
                .replace(/ã/g, 'a')
                .replace(/á/g, 'a')
                .replace(/â/g, 'a')
                .replace(/é/g, 'e')
                .replace(/è/g, 'e')
                .replace(/ê/g, 'e')
                .replace(/í/g, 'i')
                .replace(/ï/g, 'i')
                .replace(/ì/g, 'i')
                .replace(/ó/g, 'o')
                .replace(/ô/g, 'o')
                .replace(/ú/g, 'u')
                .replace(/ü/g, 'u')
                .replace(/ç/g, 'c')
                .replace(/ß/g, 's')
                .toUpperCase()
                .trim();
        }

        function remove(data) {
            TiposDependenciasService.remove(data.id).then(function() {
                $rootScope.$broadcast('refreshTiposDependencias', {
                    data: data,
                    remove: true
                });
            });
        }

        $scope.$on('refreshTiposDependencias', function(event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.tipos = _.reject(vm.tipos, function(tipo) {
                        return args.data.id === tipo.id;
                    });

                } else if (args.add) {
                    vm.tipos.push(args.data);

                } else if (args.undo || args.edit) {
                    var tipo = _.find(vm.tipos, function(value) {
                        return args.data.id === value.id;
                    });

                    if (tipo) {
                        ObjectUtilsService.copyProperties(tipo, args.data);
                    } else {
                        throw new Error('Tipo de dependência não encontrado! ID: ' + args.data.tipo.id);
                    }
                }
            }
        });

    }

})();

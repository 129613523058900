(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('appSelectFiliacoes', appSelectFiliacoes);

    appSelectFiliacoes.$inject = [];

    function appSelectFiliacoes() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" search="vm.search"  format-result="vm.formatResult(data)" format-selection="vm.formatResultSelect(data)" ng-required="!!notNull" add-value="vm.add"></ui-select>',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                required: '='
            },
            controller: ControllerAppSelectFiliacoes,
            controllerAs: 'vm'
        };
    }

    ControllerAppSelectFiliacoes.$inject = ['$scope', 'bfc.$$PermissionsService', 'ui.components.Modal',
        'educacao.pessoas.FiliacaoService','$filter'];

    function ControllerAppSelectFiliacoes($scope, PermissionsService, $modal, FiliacaoService, $filter) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatResultSelect = formatResultSelect;
        vm.add = !PermissionsService.isRevokedOperation('FiliacaoPageMapping', 'criar') && add;

        var templateFiliacao = _.template('<%= nome %> <br><small> CPF: <%= cpf %> | DATA DE NASCIMENTO: <%= dataNascimento %> | RG: <%= rg %> </small>');

        function add(nome) {
            return $modal.open({
                templateUrl: 'planejamento/filiacoes/cadastro-filiacao.html',
                controller: 'educacao.pessoas.FiliacaoCadCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    filiacao: {
                        pessoa: {
                            nome: nome
                        }
                    }
                }
             }).result.then(afterSaveFiliacao);
             
        }

        function afterSaveFiliacao(objetoRetorno) {
            $scope.ngModel= {
                    id: objetoRetorno.vinculo.filiacao.id,
                    nome: objetoRetorno.vinculo.filiacao.nome,
                    pessoa: {
                        nome: objetoRetorno.vinculo.filiacao.nome
                    },
                    quantidadeAlunos: 0
            };
        }

        function formatResult(data) {
            if(!data.pessoa){
                return undefined;
            }

            return templateFiliacao({
                nome: data.pessoa.nome || '',
                cpf: $filter('bfMaskCPF')(data.pessoa.cpf),
                rg: data.pessoa.rg,
                dataNascimento: $filter('bfDate')(data.pessoa.dataNascimento)
            });
        }

        function formatResultSelect(data){
            return data.pessoa ? data.pessoa.nome : undefined;
        }

        function search(params) {
            var filter = '(nome like "' + params.term + '"';

            filter += ')';
            return FiliacaoService.getPessoas(filter, params.offset, params.limit).then(function(data) {
                return data;
            });
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appOutrosSistemas', directive);

    function directive() {
        return {
            restrict: 'E',

            templateUrl: 'common/layout/header/contexts-systems/outros-sistemas.directive.html',
            replace: true,

            scope: {
                headerOptions: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'SYSTEM_ID',
        '$scope',
        'promiseTracker',
        '$http', 
        '$q',
        'UserAccessContext'
    ];

    function Controller(SYSTEM_ID,
        $scope,
        promiseTracker,
        $http, 
        $q,
        UserAccessContext) {

        //template para buscar sistemas, considerando apenas contexto de database e entidade 
        // não considera o contexto de estabelecimento
        var hashTemplate = _.template('database:<%= database %>,entity:<%= entity %>');

        var vm = this;

        vm.tracker = promiseTracker();

        loadSystems();

        function loadSystems() {
            vm.tracker.addPromise(
                getSystems().then(function(data) {
                    vm.systems = data;
                    $scope.headerOptions.hasOtherSystems = !_.isEmpty(data);
                })
            );
        }

        //TODO: Ver onde buscar os sistemas, o app SUITE busca nas urls abaixos, pego do mesmo lugar
        function getSystems() {
            var context = _.get(UserAccessContext.getUserAccess(), 'values');

            if (!context) {
                return $q.reject('hash vazio');
            }
            //monta string do contexto
            var hash = hashTemplate(context);

            //transforma contexto para base64
            var hash64 = window.btoa(hash);

            return get(hash64).then(function(systems) {
                return _.chain(systems)
                    .reject('id', SYSTEM_ID)
                    .sortBy('name')
                    .value();
            });
        }

        function get(hash) {
            return $http.get(___bth.envs.suite['user-accounts'].v1.host +
                    '/api/suite/users/@me/access/' +
                    hash +
                    '/systems')
                .then(function(response) {
                    return response.data;
                });
        }
    }
})();

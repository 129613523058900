(function() {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.CursoService', CursoService);

    CursoService.$inject = ['$injector', 'Restangular', 'ui.components.EventEmitter'];

    function CursoService($injector, Restangular, EventEmitter) {
        var GenericService = $injector.get('GenericService');

        var emiter = new EventEmitter();

        var service = GenericService
            .createWithCustomMethods({
                getTodos: _todos,
                getListByParams: getListByParams,
                getById: getById,
                emitDirty: emitDirty,
                camposAdicionais: camposAdicionais
            })
            .module({
                uri: 'matriz'
            })
            .service({
                uri: 'cursos'
            });

        function _todos(offset, limit, filter, sort) {
            service.validateObject();

            return service.modules().all(service._service.uri + '/todos').getList({
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            });
        }

        function getById(id) {
            return Restangular.one('matriz').one('cursos', id);
        }

        function getListByParams(params){
            return Restangular.one('matriz').all('cursos').get('list',{
                    offset: params.offset,
                    limit: params.limit,
                    filter: params.filter,
                    sort: params.sort
                });
        }

        function emitDirty(data) {
            emiter.emit('dirty');
            return data;
        }

        function camposAdicionais(cursoId) {
            return Restangular.one('matriz/cursos/campos-adicionais', cursoId)
                .get()
                .then(Restangular.stripRestangular);
        }

        _.extend(service, emiter);

        return service;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.planejamento')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider.state('app.planejamento', {
            abstract: true
        })

        .state('app.planejamento.anosletivos', {
                url: 'anos-letivos',
                data: {
                    id: 'AnoLetivoPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'planejamento/ano-letivo/ano-letivo.html'
                    }
                }
            })
            .state('app.planejamento.configuracaoinscricao', {
                abstract: true,
                data: {
                    id: 'ConfiguracaoInscricaoMatriculaPageMapping',
                    mirrorOf: 'app.planejamento.anosletivos'
                }
            })
            .state('app.planejamento.configuracaolistaespera', {
                abstract: true,
                data: {
                    id: 'ConfiguracaoListaEsperaPageMapping',
                    mirrorOf: 'app.planejamento.anosletivos'
                }
            })
            .state('app.planejamento.configuracaomatricula', {
                abstract: true,
                data: {
                    id: 'ConfiguracaoMatriculaPageMapping',
                    mirrorOf: 'app.planejamento.anosletivos'
                }
            })
            .state('app.planejamento.configuracaoturma', {
                abstract: true,
                data: {
                    id: 'ConfiguracaoTurmaPageMapping',
                    mirrorOf: 'app.planejamento.anosletivos'
                }
            })
            .state('app.planejamento.configuracaodependenciafisica', {
                abstract: true,
                data: {
                    id: 'ParametroPageMapping',
                    mirrorOf: 'app.planejamento.anosletivos'
                }
            })
            .state('app.planejamento.escolas', {
                url: 'escolas',
                data: {
                    id: 'EstabelecimentoPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'planejamento/escolas/escolas.html'
                    }
                }
            })
            .state('app.planejamento.avaliacoes', {
                url: 'avaliacoes-externas',
                data: {
                    id: 'AvaliacaoExternaPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'planejamento/avaliacoes/avaliacoes.html'
                    }
                }
            })

        //prototipos
        .state('app.planejamento.inscricaoOnline', stateUrlView('inscricao-online', '@', 'planejamento/inscricao-online/inscricao.online.localizar.html'))
            .state('app.planejamento.inscricaoonlinelocalizar', stateUrlView('inscricao-online-localizar', '@', 'planejamento/inscricao-online/inscricao.online.localizar.html'))
            .state('app.planejamento.inscricaoonlineinscrevendo', stateUrlView('inscricao-online-inscrevendo', '@', 'planejamento/inscricao-online/inscricao.online.inscrevendo.html'))
            .state('app.planejamento.inscricaoonlineinscrevendovalidacao', stateUrlView('inscricao-online-inscrevendo-validacao', '@', 'planejamento/inscricao-online/inscricao.online.inscrevendo.validacao.html'))
            .state('app.planejamento.inscricaoonlinefim', stateUrlView('inscricao-online-fim', '@', 'planejamento/inscricao-online/inscricao.online.fim.html'))
            .state('app.planejamento.inscricaoonlineresultados', stateUrlView('inscricao-online-resultados', '@', 'planejamento/inscricao-online/inscricao.online.resultados.html'))
            .state('app.planejamento.inscricaoonlinedocumentosobrigatorio', stateUrlView('inscricao-online-documentos-obrigatorio', '@', 'planejamento/inscricao-online/inscricao.online.documentos.obrigatorios.html'))

        .state('app.planejamento.dependencias', stateUrlView('dependencias', '@', 'planejamento/dependencias/dependencias.html'))
            .state('app.planejamento.tipodependencias', stateUrlView('tipodependencias', '@', 'planejamento/tipo-dependencia/tipo-dependencia.html'));

    }

    function stateUrlView(urlData, viewNameData, templateData, controllerData) {
        var obj = {};

        obj.views = {};
        obj.views[viewNameData] = {};

        obj.url = urlData;
        obj.views[viewNameData].templateUrl = templateData;

        if (controllerData) {
            obj.views[viewNameData].controller = controllerData;
        }

        return obj;
    }
})();
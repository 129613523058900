(function() {
    'use strict';

    var TIPO_ACELARACAO = 'ACELERACAO';
    var MESMO_ESTABELECIMENTO = 'MESMO_ESTABELECIMENTO';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ReclassificacaoModalController', Controller);

    Controller.$inject = [
        '$scope',
        'educacao.matricula.MatriculaVagaService',
        'educacao.matricula.ReclassificacaoService',
        '$modalInstance',
        'educacao.matricula.ConfiguracoesMatriculaService',
        'TURNO'
    ];

    function Controller($scope, MatriculaVagaService, ReclassificacaoService, $modalInstance, ConfiguracoesMatriculaService, TURNO) {
        var isUltimaEtapa = false;
        var matrizPadrao;
        var etapaMatrizPadrao;
        var templateVagas = _.template('<%= descricao%> (<%= quantidadeAlunos %>/<%= quantidadeMaximaAlunos %> alunos)');

        var vm = this;
        vm.matriculaOrigem = $modalInstance.params.matricula;
        vm.idReclassificacao = $modalInstance.params.idReclassificacao;
        vm.data = $modalInstance.params.data;

        vm.reclassificacao = {};
        vm.getTipoReclassificacao = ReclassificacaoService.getTipoReclassificacao;

        vm.listTipoReclassificacaoMatricula = MatriculaVagaService.getEnum('TipoReclassificacaoMatricula');

        vm.podeAlterarMatriz = podeAlterarMatriz;
        vm.podeAlterarEtapaMatriz = podeAlterarEtapaMatriz;
        vm.reclassificar = reclassificar;
        vm.descricaoTurma = descricaoTurma;

        vm.permiteMultiturno = false;
        vm.showMultiturno = showMultiturno;
        vm.onChangeDestinoReclassificacao = onChangeDestinoReclassificacao;
        
        showMultiturno();

        if(vm.data){
            setValues(vm.data);
        } else {
            $modalInstance.tracker.addPromise(
                ReclassificacaoService.recuperaDto(vm.matriculaOrigem, vm.idReclassificacao)
                .then(function(data) {
                    setValues(data);
                })
            );
        }

        $scope.$watch('vm.reclassificacao.matriz', onChangeMatrizCurricular);
        $scope.$watch('vm.reclassificacao.tipo', resetMatrizEtapa);
        $scope.$watch('vm.reclassificacao.etapaMatriz', resetVagaEtapa);
        $scope.$watch('vm.reclassificacao.vagaEtapa', resetTurma);
        $scope.$watch('vm.reclassificacao.destinoReclassificacao', onChangeEstabelecimentoDestino);

        function setValues(data){
            matrizPadrao = data.matriz;
            etapaMatrizPadrao = data.etapaMatriz;
            isUltimaEtapa = etapaMatrizPadrao === null;

            vm.reclassificacao = data;
            adequaEnumTurnoPraVisualizacao();
            vm.reclassificacao.matriculaOrigem = vm.matriculaOrigem;
        }

        function podeAlterarMatriz() {
            return isUltimaEtapa;
        }

        function podeAlterarEtapaMatriz() {
            return (isUltimaEtapa || vm.reclassificacao.tipo === TIPO_ACELARACAO) && vm.reclassificacao.matriz;
        }

        function resetMatrizEtapa(novoValor) {
            if (vm.idReclassificacao) {
                return;
            }
            vm.reclassificacao.matriz = matrizPadrao;
            if (novoValor !== TIPO_ACELARACAO) {
                vm.reclassificacao.etapaMatriz = etapaMatrizPadrao;
            }
        }

        function adequaEnumTurnoPraVisualizacao() {
            if (vm.idReclassificacao && vm.reclassificacao.turmaDestino && vm.reclassificacao.turmaDestino.turno) {
                vm.reclassificacao.turmaDestino.turno = _.capitalize(vm.reclassificacao.turmaDestino.turno.toLowerCase());
            }
        }

        function resetVagaEtapa() {
            if (vm.idReclassificacao) {
                return;
            }
            delete vm.reclassificacao.vagaEtapa;
        }

        function resetTurma() {
            if (vm.idReclassificacao) {
                return;
            }
            delete vm.reclassificacao.turmaDestino;
        }

        function reclassificar() {

            vm.reclassificacao.turnoFrequenta = vm.reclassificacao.turmaDestino && vm.reclassificacao.turmaDestino.turno === TURNO.INTEGRAL ?
                _.get(vm.reclassificacao.turnoFrequenta, 'turno', vm.reclassificacao.turnoFrequenta) : null;

            $modalInstance.tracker.addPromise(
                ReclassificacaoService.reclassificar(vm.reclassificacao)
                .then($modalInstance.close)
            );
        }

        function descricaoTurma(data) {
            if (vm.idReclassificacao) {
                vm.turno = data.turno;
                return;
            }
            return templateVagas(data);
        }

        function showMultiturno() {
            if (!_.get(vm.matriculaOrigem.matriz, 'id')) {
                return;
            }
            var params = {
                idEstabelecimento: vm.matriculaOrigem.estabelecimento.id,
                idMatriz: vm.matriculaOrigem.matriz.id
            };

            $modalInstance.tracker.addPromise(
                ConfiguracoesMatriculaService.permiteMultiturno(params)
                .then(permiteMultiturno)
            );

            function permiteMultiturno(data) {
                vm.permiteMultiturno = data.permite;
            }
        }

        function onChangeMatrizCurricular(oldValue, newValue) {
            if(oldValue === newValue) {
                return;
            }
            delete vm.reclassificacao.etapaMatriz;
            delete vm.reclassificacao.vagaEtapa;
            delete vm.reclassificacao.turmaDestino;
            delete vm.reclassificacao.turnoFrequenta;
        }

        function onChangeDestinoReclassificacao() {
            delete vm.reclassificacao.nomeEstabelecimentoDestino;
            delete vm.reclassificacao.estabelecimentoDestino;
            delete vm.reclassificacao.etapaMatriz;
            delete vm.reclassificacao.vagaEtapa;
            delete vm.reclassificacao.turmaDestino;
            delete vm.reclassificacao.turnoFrequenta;
        }

        function onChangeEstabelecimentoDestino(oldValue, newValue) {
            if(oldValue === newValue) {
                return;
            }
            if(vm.reclassificacao.destinoReclassificacao !== MESMO_ESTABELECIMENTO) {
                delete vm.reclassificacao.matriz;
            }
        }
    }
})();


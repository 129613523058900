(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('headerQuadroInformativo', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/header-quadro-informativo/header-quadro-informativo.directive.html',
            scope: {},
            bindToController: {
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
                isSaldoDisciplinaMobile: '=',
                titleSaldoDisciplinaMobile: '=',
                idEstabelecimentoSaldoDisciplinaMobile: '=',
                estabelecimentos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.context.ContextoEstabelecimento', '$scope', '$timeout', 'educacao.context.ContextoEntidade', 'educacao.ocupacao-aulas-quadro-informativo.VisaoGeralQuadroInformativoService'];

    function Controller(ContextoEstabelecimento, $scope, $timeout, ContextoEntidade, VisaoGeralQuadroInformativoService) {

        var vm = this;
        vm.openMenu = openMenu;
        vm.closeMenu = closeMenu;
        vm.selectMenu = selectMenu;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.nomeEstabelecimento = ContextoEstabelecimento.getEstabelecimento().pessoa.nome;
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.entidade = ContextoEntidade.getEntidade();
        vm.estabelecimentoFilter = null;

        vm.resetEstabelecimentoSelected = resetEstabelecimentoSelected;

        $scope.$watch('vm.estabelecimentoFilter', onChangeFilter);

        vm.menuOptions = [
            {label: 'Visão geral', state: 'app.planejamento.visao-geral-quadro-informativo'},
            {label: 'Turmas e Alunos', state: 'app.planejamento.turma-alunos-quadro-informativo'},
            {label: 'Gestão de pessoas', state: 'app.planejamento.gestao-pessoas-quadro-informativo'},
        ];

        init();

        function init() {
            if (!ContextoEstabelecimento.isSecretaria()) {
                getEstabelecimento();
            }
        }

        function getEstabelecimento() {
            var params = {};

            if (vm.estabelecimento) {
                params.filter = 'id = ' + vm.estabelecimento.id;
            }

            VisaoGeralQuadroInformativoService.getListagemEstabelecimentos(params).then(function (data) {
                var estabelecimento = data.content[0];
                if (_.get(estabelecimento, 'brasao')) {
                    vm.estabelecimento.brasao = _.get(estabelecimento, 'brasao');
                }
            });

        }

        function selectMenu(option) {
            vm.menu = option.label;
        }

        function openMenu() {
            $timeout(function () {
                var menu = document.querySelector('.menu-expanded-quadroinformativo');
                menu.style.width = '250px';
            }, 300);

        }

        function closeMenu() {
            vm.menuOpen = !vm.menuOpen;
            var menu = document.querySelector('.menu-expanded-quadroinformativo');
            menu.style.width = '0';
        }

        function onChangeFilter(filter) {
            if (!filter) {
                return;
            }

            vm.estabelecimentoSelected = {
                id: filter.id,
                descricao: filter.descricao,
                brasao: filter.brasao
            };

            vm.estabelecimentoFilter = null;
        }

        function resetEstabelecimentoSelected() {
            vm.estabelecimentoSelected = null;
        }
    }
})();
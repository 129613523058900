(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .service('configuracao-avaliacao-turma.BuildAvaliaveisService', BuildAvaliaveisService);

    BuildAvaliaveisService.$inject = [
        'ITEM_AVALIAVEL_TIPO_PERIODO'
    ];

    /**
     * Service utilizaza para montar a estrutura do itens avaliáveis necessária para
     * inserção nas turmas
     */
    function BuildAvaliaveisService(ITEM_AVALIAVEL_TIPO_PERIODO) {
        var self = this;

        self.buildItensConfiguracao = buildItensConfiguracao;

        function buildItensConfiguracao(componentes, isEdicao) {
            if (_.isEmpty(componentes)) {
                return componentes;
            }

            var mapComponenteFunction = mapComponente.bind(null, isEdicao);

            return _.chain(componentes)
                .map(mapComponenteFunction)
                .value();
        }

        function mapComponente(isEdicao, componente) {
            var itemConfiguracao = {
                itensAvaliaveis: angular.copy(componente.$$itensAvaliaveis)
            };

            var itemConselho = createItemConselhoClasse(isEdicao, componente.$$itensAvaliaveis, componente.itensAvaliaveis);
            if (itemConselho) {
                itemConfiguracao.itensAvaliaveis.push(itemConselho);    
            }

            var propertyFilho = getFilhoProperty(componente, isEdicao);
            var propertyName = getFilhoPropertyName(isEdicao, componente.tipo);

            itemConfiguracao[propertyName] = propertyFilho;
            itemConfiguracao.itensAvaliaveisCha = componente.itensAvaliaveisCha;
            itemConfiguracao.itensAvaliaveisObjetivoAprendizagem = componente.itensAvaliaveisObjetivoAprendizagem;

            return itemConfiguracao;
        }

        /**
         * Retorna a propriedade correta do objeto para ser enviado
         * @param {*} isEdicao - True se está em modo adição
         * @param {*} componente - componente que possui a propriedade
         */
        function getFilhoProperty(componente, isEdicao) {
            //se for edição retorna o próprio componente
            if (isEdicao) {
                return {
                    id: componente.id
                };
            }

            //Caso não seja edição, retorna a disciplina da matriz, para localizar o item educacional no backend
            return componente.tipo === 'AREA_CONHECIMENTO' ? componente.eixoTematico : componente.etapaMatrizDisciplina;
        }

        /**
         * Retorna a propriedade que deve ser enviada. Quando está em modo edição, o item
         * educacional pode ser enviado, pois só há uma turma selecionada. Quando não estiver em
         * modo edição, a etapaMatriz será enviada. Caso futuramente possa ser editado outros tipos
         * de turmas em lote (como EjaModular ou Atividade), essa função deve ser revista
         * @param {boolean} isEdicao - True se está em modo adição
         */
        function getFilhoPropertyName(isEdicao, tipo) {
            if (isEdicao) {
                return 'itemEducacional';
            }
            return tipo === 'AREA_CONHECIMENTO' ? 'eixoTematico' : 'etapaMatrizDisciplina';
        }

        /**
         * * Cria um item de conselho de classe com base no item de média final
         
         * @param {*} isEdicao - modo de edição ou não
         * @param {Object[]} itensAvaliaveis - itens avaliáveis de um componente
         * @param {*} itensAvaliaveisOriginal - itens avaliáveis originais de um componente
         */
        function createItemConselhoClasse(isEdicao, itensAvaliaveis, itensAvaliaveisOriginal) {
            var itemMediaFinal = _.find(itensAvaliaveis, 'tipoPeriodo', ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL);

            if (!itemMediaFinal) {
                return;
            }

            var itemConselhoOriginal = _.find(itensAvaliaveisOriginal, 'tipoPeriodo', ITEM_AVALIAVEL_TIPO_PERIODO.CONSELHO_CLASSE);

            var itemConselho = {
                modoAvaliacao: itemMediaFinal.modoAvaliacao,
                periodoAvaliativo: null,
                tipo: itemMediaFinal.tipo,
                tipoPeriodo: ITEM_AVALIAVEL_TIPO_PERIODO.CONSELHO_CLASSE
            };

            if (_.get(itemConselhoOriginal, 'id')) {
                itemConselho.id = _.get(itemConselhoOriginal, 'id');
            }

            return itemConselho;
        }
    }

})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('stepInscrito', stepInscrito);

    stepInscrito.$inject = [];

    function stepInscrito() {
        return {
            restrict: 'E',

            scope: {
                inscricao: '='
            },

            templateUrl: 'matricula/inscricao/step-inscrito/step-inscrito.directive.html',

            controller: StepInscritoCtrl,
            controllerAs: 'vm'
        };
    }

    StepInscritoCtrl.$inject = ['$scope', 'ui.components.PrintService'];

    function StepInscritoCtrl($scope, PrintService) {
        var vm = this;

        vm.print = print;

        vm.novaInscricao = novaInscricao;

        function print() {
            PrintService.print('#infoConfirmacaoInscricao');
        }

        function novaInscricao() {
            angular.copy({}, $scope.inscricao);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appAccordionSaldos', appAccordionSaldos);

    function appAccordionSaldos() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-accordion-visao-estabelecimento/app-accordion-saldos/app-accordion-saldos.directive.html',
            scope: {},
            bindToController: {
                saldoInicial: '=',
                saldoOcupado: '=',
                saldoAtual: '=',
                isCollapsed: '=',
                isCollapseArrowHorizontal: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.saldos = [
            { label: 'Saldo Inicial', saldo: vm.saldoInicial },
            { label: 'Saldo Ocupado', saldo: vm.saldoOcupado },
            { label: 'Saldo Atual', saldo: vm.saldoAtual }
        ];
    }
})();
(function () {
    'use strict';

    angular.module('educacao.common').directive('appImageBrasaoEntidade', Directive);

    Directive.$inject = [];

    function Directive() {
        return {
            restrict: 'A',
            scope: {},
            bindToController: {
                appImageBrasaoEntidade: '=',
                width: '=',
                height: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'Restangular', '$element', 'educacao.entidade.EntidadeService'];

    function Controller($scope, $promiseTracker, Restangular, $element, EntidadeService) {
        var vm = this;

        vm.tacker = $promiseTracker();

        $scope.$watch('vm.appImageBrasaoEntidade', setImage);

        function setImage() {
            var width = vm.width || 50;
            var height = vm.height || 50;
            $element.attr('src', EntidadeService.getUrlBrasao(vm.appImageBrasaoEntidade.id) || 'images/4848.png');
            $element.attr('style', 'border-radius:50%; width: ' + width + 'px; height: ' + height + 'px;');
        }
    }
})
();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .controller('educacao.gestao-pessoas-quadro-informativo.PopoverAtribuicaoAulasController', Controller);

    Controller.$inject = ['$rootScope', 'educacao.common.$commons', '$popoverInstance', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService'];

    function Controller($rootScope, $commons, $popoverInstance, Service) {

        var vm = this;
        vm.localTrabalho = $popoverInstance.params.localTrabalho;
        vm.professor = $popoverInstance.params.professor;
        vm.anoLetivoSelected = $popoverInstance.params.anoLetivoSelected;

        init();

        function init() {
            if (! vm.localTrabalho.estabelecimentoId) {
                return;
            }
            var professorId = _.get(vm.professor, 'id');
            var params = {
                funcionarioId: professorId,
                estabelecimentoId: vm.localTrabalho.estabelecimentoId,
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id')
            };
            var promise = Service.listAtribuicaoAulasLocalTrabalho(professorId, params).then(onLoadAtribuicaoAulas);
            $popoverInstance.tracker.addPromise(promise);
        }

        function onLoadAtribuicaoAulas(data) {
            if (_.isEmpty(data)) {
                ajustaPosicaoPopOver();
                return [];
            }

            vm.aulasPorDuracao = _.groupBy(data, 'turno');
            ajustaPosicaoPopOver();
            return vm.aulasPorDuracao;
        }

        function ajustaPosicaoPopOver() {
            $commons.$timeout(function () {
                $popoverInstance.position();
            });
        }
    }
})();

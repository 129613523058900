(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.pessoas');

    moduloCommon.controller('educacao.pessoas.ReligioesCtrl', ReligioesCtrl);
    moduloCommon.controller('educacao.pessoas.ReligioesCadCtrl', ReligioesCadCtrl);

    ReligioesCtrl.$inject = ['$injector', '$scope', '$rootScope', '$timeout', 'promiseTracker', '$q'];

    function ReligioesCtrl($injector, $scope, $rootScope, $timeout, promiseTracker, $q) {

        var viewModel = this;
        var $modalStates = $injector.get('$modalStates'),
            ReligiaoService = $injector.get('educacao.pessoas.ReligiaoService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService');

        viewModel.tracker = {};
        viewModel.tracker.loading = promiseTracker();

        var propriedadesPublicas = {
            search: {
                value: '',
                isSearching: function() {
                    return Boolean(viewModel.search.value);
                }
            },
            remove: _remove,
            openReligiaoCad: _openReligiaoCad,
        };

        function _openReligiaoCad(religiao) {
            $modalStates.goModalState(religiao);
        }

        function init() {
            viewModel.tracker.loading.addPromise(
                $q.all([
                    ReligiaoService.getList(0, 999).then(function(data) {
                        viewModel.religioes = _.first(data).content;
                    })
                ]).then(function() {
                    angular.extend(viewModel, propriedadesPublicas);
                })
            );
        }

        init();

        function _remove(data) {
            ReligiaoService.remove(data.id).then(
                function() {
                    $rootScope.$broadcast(
                        'refresh::Religioes', {
                            data: data,
                            remove: true
                        }
                    );
                }
            );
        }

        $scope.$on('refresh::Religioes', function(event, args) {
            if (args.data) {
                if (args.remove) {
                    viewModel.religioes = _.reject(viewModel.religioes, function(religiao) {
                        return args.data.id === religiao.id;
                    });

                } else if (args.add) {
                    viewModel.religioes.push(args.data);

                } else if (args.undo || args.edit) {

                    var religiao = _.find(viewModel.religioes, function(rel) {
                        return args.data.id === rel.id;
                    });

                    if (religiao) {
                        ObjectUtilsService.copyProperties(religiao, args.data);
                    } else {
                        throw new Error('Religião não encontrada! ID: ' + args.data.id);
                    }
                }
            }
        });

        $scope.$watch('ReligioesCtrl.search.value', function(data) {
            if (!!viewModel.search) {
                if (data) {
                    viewModel.search.filter = {
                        '$': data
                    };
                } else {
                    viewModel.search.filter = {
                        '$': ''
                    };
                }
            }
        });
    }

    ReligioesCadCtrl.$inject = ['$injector', '$scope', '$rootScope', 'promiseTracker', '$modalInstance', '$modalTracker', '$q',
         'bfc.Focus'];

    function ReligioesCadCtrl($injector, $scope, $rootScope, promiseTracker, $modalInstance, $modalTracker, $q,
        focus) {
        var viewModel = this;
        var ReligiaoService = $injector.get('educacao.pessoas.ReligiaoService');

        viewModel.tracker = {
            save: promiseTracker(),
            remove: promiseTracker()
        };

        var religiao = _.get($modalInstance.params, 'religiao') || {};
        var id = religiao.id;

        var propriedadesPublicas = {

            save: _save,
            cancel: _cancel,
            modo: {
                editando: Boolean(id),
                adicionando: !id,
            },
            remove: _remove,
            religiao: religiao,
            hideSaveAndAdd: _.get($modalInstance.params, 'hideSaveAndAdd') || false

        };

        function _save(continuar) {

            viewModel.tracker.save.addPromise(
                ReligiaoService.save(viewModel.religiao).then(
                    function(data) {
                        $rootScope.$broadcast('refresh::Religioes', {
                            data: data,
                            add: !id,
                            edit: Boolean(id)
                        });

                        onSuccess(continuar, data);
                    }
                )
            );
        }

        function _remove() {
            viewModel.tracker.remove.addPromise(
                ReligiaoService.remove(viewModel.religiao.id).then(
                    function() {
                        $rootScope.$broadcast(
                            'refresh::Religioes', {
                                data: viewModel.religiao,
                                remove: true
                            }
                        );
                        _cancel();
                    }
                ));
        }

        function refresh() {

            if (viewModel.form) {
                viewModel.form.$setPristine();
            }

            return !id ? ReligiaoService.getDefault().then(
                    function(data) {
                        viewModel.religiao = angular.extend(data, religiao);
                    }) :
                ReligiaoService.get(id).then(
                    function(data) {
                        viewModel.religiao = data;
                    }
                );

        }

        $modalTracker.addPromise(
            $q.all([refresh()]).then(
                angular.extend(viewModel, propriedadesPublicas)
            )
        );

        function onSuccess(continuar, religiaoSaved) {
            if (continuar) {
                id = null;
                viewModel.tracker.save.addPromise(refresh());
                focus('lbDescricaoReligiao');
            } else {
                _cancel(religiaoSaved);
            }
        }

        function _cancel(religiaoSaved) {
            $modalInstance.close(religiaoSaved);
        }
    }

})();

(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.CadastroPessoaObservacoesController', CadastroPessoaObservacoesController);

    CadastroPessoaObservacoesController.$inject = ['$modalInstance', 'AuthenticationContext', 'UserAccessContext', 'bfcApplicationContext', '$window'];

    function CadastroPessoaObservacoesController($modalInstance, AuthenticationContext, UserAccessContext, ApplicationContext, $window) {
        var vm = this;
        vm.aluno = $modalInstance.params.aluno;
        vm.observacao = _.cloneDeep($modalInstance.params.observacao) || {};
        vm.adicionarObservacao = adicionarObservacao;
        vm.url = 'user_access=' + UserAccessContext.getUserAccess().licenseId +
            '&app_context=' + toBase64(ApplicationContext.getList()) +
            '&access_token=' + AuthenticationContext.getAccessToken();

        function toBase64(context) {
            return $window.btoa(JSON.stringify(context));
        }
        function adicionarObservacao() {
            if ($modalInstance.params.observacao) {
                _.remove(vm.aluno.alunoObservacoes, $modalInstance.params.observacao);
            }
            vm.aluno.alunoObservacoes.push(vm.observacao);
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appAnalyticsPage', appAnalyticsPage);

    function appAnalyticsPage() {
        return {
            restrict: 'E',
            template: '',
            scope: {},
            bindToController: {
                pageLimit: '=',
                pageOffset: '=',
                pageNumber: '=',
                eventLabel: '@'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', '$state', '$window', '$element', 'ANALYTICS_ID'];

    function Controller($scope, $state, $window, $element, ANALYTICS_ID) {
        var vm = this;

        if (!ANALYTICS_ID) {
            return;
        }

        var isPageNumber = $element.attr('page-number');

        if (isPageNumber) {
            $scope.$watch('vm.pageNumber', watchPageNumber);
            return;
        }

        $scope.$watchGroup(['vm.pageLimit', 'vm.pageOffset'], watchLimitOffset);

        function watchPageNumber(newValue) {
            if (!newValue) {
                return;
            }

            sendEvent(newValue);
        }

        function watchLimitOffset(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isEmpty(newValue)) {
                return;
            }
            var pageNumber = (_.last(newValue) / _.first(newValue)) + 1;

            sendEvent(pageNumber);
        }

        function sendEvent(pageNumber) {
            $window.ga('send', 'event', {
                eventCategory: $state.$current.url.sourcePath,
                eventAction: 'list#' + pageNumber,
                eventLabel: vm.eventLabel
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')

        .constant('DATE_FORMAT', 'YYYY-MM-DD')
        .constant('TIME_FORMAT', 'HH:mm')
        .constant('DATE_TIME_FORMAT', 'YYYY-MM-DD[T]HH:mm')

        .constant('CLASS_DAY', 'dia')
        .constant('CLASS_WORKDAY', 'dia-letivo')
        .constant('CLASS_EVENT', 'evento')
        .constant('COLOR_CLASS_VARIOUS_EVENTS', 'bg__yellow--l10')

        .constant('CLASS_HOLYDAY', 'feriado')
        .constant('COLOR_CLASS_HOLYDAY', 'GRAY6')
        .constant('COLOR_CLASS_WORKDAY_HOLYDAY', 'bg__green--l10')

        //FERIADOS
        .constant('HOLYDAY_DATE_FORMAT', 'ddd DD/MM/YYYY')
        .constant('HOLYDAY_POPOVER_LIST_TEMPLATE', '\'calendario/calendario-feriado/calendario-feriado-list-popover.html\'')

        //EVENTOS
        .constant('EVENT_DATE_FORMAT_LEGEND', 'DD/MM')
        .constant('EVENT_DATE_FORMAT_INTERVAL_DAY', 'ddd DD MMM')
        .constant('EVENT_DATE_FORMAT_INTERVAL_DAY_SEPARATOR', ' até ')
        .constant('EVENT_DATE_FORMAT_INTERVAL_HOUR', 'HH:mm')
        .constant('EVENT_DATE_FORMAT_INTERVAL_HOUR_SEPARATOR', ' - ')
        .constant('EVENT_DATE_FORMAT_SHORT_INTERVAL_DAY', 'DD/MM')
        .constant('EVENT_DEFAULT_TIMED_EVENT_DURATION', '00:30:00')
        .constant('EVENT_POPOVER_LIST_TEMPLATE', '\'calendario/calendario-evento/calendario-event-list-popover.html\'')

        //ENUM
        .constant('DayOfWeek', createEnum());

    function createEnum() {
        var enumObject = {
            values: []
        };

        var keys = [
            'MONDAY',
            'TUESDAY',
            'WEDNESDAY',
            'THURSDAY',
            'FRIDAY',
            'SATURDAY',
            'SUNDAY'
        ];

        var entry;
        angular.forEach(keys, function (key, index) {
            entry = {
                id: key,
                key: key,
                value: index + 1
            };
            enumObject[key] = entry;
            enumObject.values.push(entry);
        });

        return enumObject;
    }
})();

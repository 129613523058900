(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('inscricaoOnlineSteps',InscricaoOnlineSteps);

	InscricaoOnlineSteps.$inject = [];

	function InscricaoOnlineSteps() {
		
		var directiveConfig = {};
		
		directiveConfig.templateUrl = 'matricula/inscricao-online/steps/inscricao-online-steps.directive.html';
		
		return directiveConfig;
	}
})();
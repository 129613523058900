(function() {
	'use strict';

	angular.module('educacao.pessoas')
		.controller('educacao.pessoas.DeficienciasCtrl', DeficienciasController);

	DeficienciasController.$inject = [
		'promiseTracker', 
		'educacao.pessoas.DeficienciaService', 
		'$modalStates'
	];

	function DeficienciasController(promiseTracker, DeficienciaService, $modalStates) {
		var vm = this;

		var propriedadesPublicas = {
			remove: _remove,
			openModalCadastroDeficiencia: _openModalCadastroDeficiencia,
			search: _search
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.deficienciaService = DeficienciaService;
			vm.filter = {};
			vm.userListControls = {};
			vm.defaultListControls = {};

			vm.tracker = {
				loading: promiseTracker()
			};

			vm.defaultListCustomBuilder = {
				onBeforeBuild: function() {
					return 'database is null';
				}
			};

			vm.userListCustomBuilder = {
				onBeforeBuild: function() {
					return 'database is not null';
				}
			};

			vm.columnsSearch = [
				{
					id: 'descricao',
					model: 'descricao',
					label: 'Descrição',
					filterable: true
				}
			];
		}

		function _search() {
			vm.defaultListControls.search();
			vm.userListControls.search();
		}

		function _openModalCadastroDeficiencia(itemDeficiencia) {
			$modalStates.goModalState(itemDeficiencia);
		}

		function _remove(deficiencia) {
			return DeficienciaService.remove(deficiencia);
		}
	}
})();


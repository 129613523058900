(function() {
    
    'use strict';

    angular.module('educacao.programasocial')
        .factory('educacao.programasocial.ProgramaSocialService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {

        var self = ServiceBuilder.create()
            .path('pessoa/programa-social')
            .build();

        return self;

    }

})();
(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('turnos', turnos);

	turnos.$inject = ['$state'];

	function turnos() {
		
		var directiveConfig = {};
		
		directiveConfig.templateUrl = 'matricula/inscricao-online/localizando/turnos.directive.html';
		
		directiveConfig.restrict = 'E';
		directiveConfig.scope = {};
		directiveConfig.scope.code = '=';
		directiveConfig.scope.disable = '=';
		directiveConfig.scope.state = '@';
		
		return directiveConfig;
	}
	
})();
(function() {
    'use strict';

    angular.module('educacao.cursoaperfeicoamento')
        .directive('appSelectCursoAperfeicoamento', SelectCursoAperfeicoamento);

    function SelectCursoAperfeicoamento() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-change="ngChange()" ng-required="ngRequired" search="vm.search" add-value="vm.add" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                dropdownCssClass: '=?',
                ngChange: '&?'
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = [
        '$scope',
        'ui.components.Modal',
        'bfc.$$PermissionsService',
        'educacao.cursoaperfeicoamento.CursoAperfeicoamentoService'
    ];

    function Controller($scope, uiModal, PermissionsService, CursoAperfeicoamentoService) {

        var vm = this;

        vm.search = search;
        vm.add = !PermissionsService.isRevokedOperation('CursoAperfeicoamentoPageMapping', 'criar') && add;

        function search(params) {
            var filter = '(descricao like "' + params.term + '")';

            return CursoAperfeicoamentoService.list(filter, params.offset, params.limit);
        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'curso-aperfeicoamento/cadastro-curso-aperfeicoamento.html',
                controller: 'educacao.CursoAperfeicoamentoCadastroCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    curso: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }
    }

})();

(function() {

    'use strict';

    angular.module('educacao.matricula')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.matricula.dispensa';
        $stateProvider.state(STATE, {
            url: 'registros-dispensas',
            data: {
                id: 'RegistroDispensaPageMapping'
            },
            views: {
                '': {
                    templateUrl: 'matricula/dispensa/registro-dispensa.html',
                    controller: 'educacao.matricula.RegistroDispensaCtrl',
                    controllerAs: 'vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.matricula.RegistroDispensaService',
            '$modalStates'
        ];

        function openModal($stateParams, RegistroDispensaService, $modalStates) {
            $modalStates.open({
                getMethod: RegistroDispensaService.get,
                templateUrl: 'matricula/dispensa/cadastro-registro-dispensa.modal.html',
                controller: 'educacao.matricula.CadastroRegistroDispensaModalController as vm',
                propertyName: 'registroDispensa',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.ResponsavelCadCtrl', ResponsavelCadCtrl);

    ResponsavelCadCtrl.$inject = [
        '$q',
        'bfc.Focus',
        '$modalInstance',
        'promiseTracker',
        'ui.components.Modal',
        'educacao.pessoas.ResponsavelService',
        '$enumsCache',
        'educacao.pessoas.PessoaService',
        '$scope',
        'educacao.pessoas.AlunoService',
    ];

    function ResponsavelCadCtrl(
        $q,
        focus,
        $modalInstance,
        promiseTracker,
        $modal,
        ResponsavelService,
        $enumsCache,
        PessoaService,
        $scope,
        AlunoService
    ) {

        var vinculoDefault = {
            permiteRetirarAluno: null,
            diasDaSemana: []
        };

        var vm = this,
            vinculos = [];
        var aluno = $modalInstance.params.aluno || {};

        vm.responsavel = $modalInstance.params.responsavel;
        vm.vinculo = $modalInstance.params.vinculo || angular.copy(vinculoDefault);
        vm.editando = _.get(vm.responsavel, 'id');
        vm.searchMethod = AlunoService.getPessoas;
        vm.reponsavelService = ResponsavelService;
        vm.cancelar = cancelar;

        vm.tracker = {
            loading: promiseTracker(),
            save: promiseTracker(),
            saveContinue: promiseTracker(),
            remove: promiseTracker()
        };
        vm.save = save;
        vm.remove = remove;
        vm.pessoaSelecionada = pessoaSelecionada;
        vm.filterResult = filterResult;
        vm.openObito = openObito;
        vm.windowTitle = vm.editando ? 'Editando responsável' : 'Adicionando responsável';

        init();
        initCamposAdicionais();


        function inicializaEnums() {
            vm.options = {
                GrauInstrucao: ResponsavelService.getGrauInstrucao(),
                EstadoCivil: ResponsavelService.getEstadoCivil(),
                Parentesco: $enumsCache.get('Parentesco'),
                SituacaoTrabalho: ResponsavelService.getSituacaoTrabalho()
            };
            ResponsavelService.getSexo().then(function(sexo) {
                vm.options.Sexo = sexo;
            });
        }

        function init() {
            $modalInstance.tracker.addPromise(
                $q.all([
                    inicializaEnums()
                ])
            );
        }

        function initCamposAdicionais() {
            var responsavelId = _.get(vm.responsavel, 'id');

            var promise = ResponsavelService.camposAdicionais(responsavelId).then(function(data) {
                vm.campoAdicional = data;
            });

            vm.tracker.loading.addPromise(promise);
        }

        function limpaForm() {
            vm.form.$setPristine();
            vm.form.$setUntouched();
            initCamposAdicionais();
        }

        function onSuccess(continuar, vinculo) {
            if (continuar) {
                focus('lbNomeResponsavel');
                vinculos.push(vinculo);
                vm.responsavel = {
                    pessoa: {
                        telefones: [],
                        emails: []
                    }
                };
                vm.vinculo = {};
                limpaForm();
                return;
            }
            if (vinculos.length > 0) {
                vinculos.push(vinculo);
            }
            var objetoRetorno = {
                vinculo: vinculos.length === 0 ? vinculo : null,
                vinculos: vinculos,
                edit: _.get(vm.responsavel, 'id') ? true : false
            };
            $modalInstance.close(objetoRetorno);
        }

        function save(continuar) {
            vm.tracker.loading = continuar ? vm.tracker.saveContinue : vm.tracker.save;
            vm.responsavel.campoAdicional = vm.campoAdicional;
            vm.tracker.loading.addPromise(
                ResponsavelService.save(vm.responsavel).then(function(data) {
                    var vinculo = montaVinculoResponsavel(data);
                    onSuccess(continuar, vinculo);
                })
            );
        }

        function montaObjetoResponsavel(data) {
            return {
                id: data.id,
                nome: data.pessoa.nome,
                pessoa: {
                    telefone: data.pessoa.telefone,
                    celular: data.pessoa.celular
                }
            };
        }

        function montaVinculoResponsavel(data) {
            return {
                aluno: {
                    id: aluno.id
                },
                responsavel: montaObjetoResponsavel(data),
            };
        }

        function filterResult(term, value) {

            if (value.id === 'PAI' || value.id === 'MAE' || value.id === 'PADRASTRO' || value.id === 'MADRASTA') {
                return;
            }
            var valueMaiusculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (valueMaiusculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }

        function remove() {
            vm.tracker.loading = vm.tracker.remove;
            var objetoRetorno = {
                vinculo: {
                    responsavel: vm.responsavel
                },
                remove: true,
            };
            vm.tracker.loading.addPromise(
                ResponsavelService.remove(vm.responsavel)
                    .then(
                        function() {
                            $modalInstance.close(objetoRetorno);
                        })
            );
        }

        function pessoaSelecionada(responsavel) {
            if (responsavel !== null) {
                vm.responsavel = responsavel;
            }

            if (responsavel._id) {
                var load =  PessoaService.getPessoas(responsavel._id)
                    .then(_.bind(onLoadPessoa, null, responsavel._id));
                return vm.tracker.loading.addPromise(load);
            }

            vm.editando = _.get(responsavel, 'id');
            var indexOrdem = _.findIndex(vinculos, function(data) {
                return data.responsavel.id === responsavel.id;
            });
            if (indexOrdem < 0) {
                return;
            }
            vinculos.splice(indexOrdem, 1);
        }

        function onLoadPessoa(idTemplate, responsavel) {
            novoResponsavel();
            vm.responsavel.pessoa = responsavel;
        }

        function novoResponsavel() {
            verificaFormulario();
            vm.responsavel = {
                enderecos: [],
                pessoa: {
                    telefones: [],
                    emails: []
                }
            };
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }

        function cancelar() {
            var objetoRetorno;
            if (vinculos.length) {
                objetoRetorno = {};
                objetoRetorno.vinculos = vinculos;
            }
            $modalInstance.close(objetoRetorno);
        }

        function openObito() {
            $modal.open({
                templateUrl: 'planejamento/alunos/popups/cadastro-obito.html',
                controller: 'educacao.common.CadastroObitoController as vm',
                params: {
                    dataValue: angular.copy(vm.responsavel),
                    canSaveAndAdd: !aluno
                }
            }).result.then(setResponsavel);
        }

        function setResponsavel(data) {
            vm.responsavel = data;
        }

    }
})();

(function() {
	'use strict';

	angular.module('educacao.feriado')
		.controller('educacao.feriado.FeriadosCadCtrl', Controller);

	Controller.$inject = ['$injector', '$rootScope', '$modalInstance'];

	function Controller($injector, $rootScope, $modalInstance) {
		var vm = this;

		var FeriadoService = $injector.get('educacao.feriado.FeriadoService');
		var focus = $injector.get('bfc.Focus');
		var promiseTracker = $injector.get('promiseTracker');
		var $enumsCache = $injector.get('$enumsCache');

		var propriedadesPublicas = {
			cancel: _cancel,
			isFormValid: _isFormValid,
			isLoadingActive: _isLoadingActive,
			remove: _remove,
			save: _save
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.feriado = $modalInstance.params.feriado || {};
			vm.editando = _.get($modalInstance.params.feriado, 'id');

			initTracker();
			initEnums();
		}

		function initTracker() {
			vm.tracker = {
				save: promiseTracker(),
				saveContinue: promiseTracker()
			};
		}

		function initEnums() {
			vm.abrangencia = $enumsCache.get('AbrangenciaFeriado');
			vm.tipo = $enumsCache.get('TipoFeriado');
		}

		function _cancel() {
			$modalInstance.close(vm.feriado);
		}

		function _isFormValid() {
			return Boolean(vm.form && vm.form.$valid);
		}

		function _isLoadingActive() {
			return (vm.tracker.save.active() || vm.tracker.saveContinue.active());
		}

		function _save(continuar) {
			vm.tracker[continuar ? 'saveContinue' : 'save'].addPromise(
				FeriadoService.save(vm.feriado).then(function(data) {
					vm.feriado = data;
					onSuccess(continuar);
				})
			);
		}

		function _remove() {
			$rootScope.$broadcast('refreshFeriado:remove', {
				id: vm.feriado.id
			});
			_cancel();
		}

		function onSuccess(continuar) {
			if (continuar) {
				verificaFormulario();
				vm.feriado = {};
				focus('lbFeriadoCadCtrlDescricao');
			} else {
				_cancel();
			}
		}

		function verificaFormulario() {
			if (vm.form) {
				vm.form.$setPristine();
				vm.form.$setUntouched();
			}
		}
	}
})();


(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.EtapaService', EtapaService);

    EtapaService.$inject = ['$injector'];

    function EtapaService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .create()
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'etapas'
            });
    }
})();

(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appSelectServidor', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ui-not-empty="ngRequired" format-result="vm.formatResult(data)" dropdown-css-class="dropdownCssClass" ng-disabled="ngDisabled" ng-change="ngChange()" search="vm.search"/>',
            scope: {
                ngModel: '=',
                leciona: '=?',
                estabelecimento: '=?',
                ngRequired: '=?',
                ngDisabled: '=?',
                dropdownCssClass: '=?',
                ngChange: '&?'
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'educacao.funcionario.FuncionarioMatriculaService'];

    function Controller($scope, FuncionarioMatriculaService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function formatResult(data) {
            if (_.isFunction($scope.formatResult)) {
                return $scope.formatResult(data);
            }
            if (data.servidor === undefined) {
                return data.nome;
            }
            return data.servidor.pessoa.nome;

        }

        function search(params) {

            var filter = '(nome like "' + params.term + '"';
            var servidoresSelecionados = [];

            if ($scope.multiple && $scope.ngModel) {
                $scope.ngModel.forEach(function (servidor) {
                    servidoresSelecionados.push(servidor.id);
                });
            }

            if (!_.isEmpty(servidoresSelecionados)) {
                filter += ' and idServidor not in ( ' + servidoresSelecionados.join() + ' )';
            }

            if ($scope.leciona) {
                filter += ' and leciona = true';
            }
            if ($scope.estabelecimento) {
                filter += ' and estabelecimento = ' + $scope.estabelecimento.id;
            }

            filter += ')';

            return FuncionarioMatriculaService.getProfessores(filter, params.offset, params.limit)
                .then(function (data) {
                    return {
                        content: data,
                        hasNext: false
                    };
                });
        }
    }
})();
(function () {
    'use strict';
    angular
        .module('educacao.configuracaoFrequencia')
        .controller('educacao.common.ConfiguracaoFrequenciaController', ConfiguracaoFrequenciaController);


    var REGISTRO_FREQUENCIA_POR_AULAS = 'REGISTRO_FREQUENCIA_POR_AULAS';
    var REGISTRO_TOTAL_FALTAS_EM_DIAS = 'REGISTRO_TOTAL_FALTAS_EM_DIAS';

    var tipoConfiguracaoFrequencia = {
        EDUCACAO_BASICA_ENSINO_FUNDAMENTAL: 'EDUCACAO_BASICA_ENSINO_FUNDAMENTAL',
        EDUCACAO_BASICA_ENSINO_MEDIO: 'EDUCACAO_BASICA_ENSINO_MEDIO',
        AEE: 'AEE',
        ATIVIDADE_COMPLEMENTAR: 'ATIVIDADE_COMPLEMENTAR',
        EJA_MODULAR_ENSINO_FUNDAMENTAL: 'EJA_MODULAR_ENSINO_FUNDAMENTAL',
        EJA_MODULAR_ENSINO_MEDIO: 'EJA_MODULAR_ENSINO_MEDIO'
    };

    ConfiguracaoFrequenciaController.$inject = [
        'bfc.PromiseTracker',
        'educacao.common.ConfiguracaoFrequenciaService',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'bfc.Notification',
        '$enumsCache'
    ];

    function ConfiguracaoFrequenciaController(
        PromiseTracker,
        ConfiguracaoFrequenciaService,
        ContextoEstabelecimento,
        ContextoAnoLetivo,
        Notification,
        $enumsCache) {

        var vm = this;

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.desabilitaRestauraPadrao = desabilitaRestauraPadrao;
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.filterResultTipoRegistoFrequencia = filterResultTipoRegistoFrequencia;
        vm.getEquivalencia = getEquivalencia;
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.isAee = isAee;
        vm.isEducacaoBasicaFundamental = isEducacaoBasicaFundamental;
        vm.isEducacaoBasicaMedio = isEducacaoBasicaMedio;
        vm.isRegistroPorAula = isRegistroPorAula;
        vm.showConfiguracaoIdentificadorFrequencia = showConfiguracaoIdentificadorFrequencia;
        vm.openCategoria = openCategoria;
        vm.restauraPadrao = restauraPadrao;
        vm.salvar = salvar;

        vm.configuracao = {};
        vm.configuracao.id = undefined;

        vm.loadings = PromiseTracker.create(['salvar', 'categoria', 'restauraPadrao']);

        $enumsCache.get('IdentificadorFrequenciaCor').then(function (enumData) {
            vm.colors = enumData;
            _.forEach(vm.colors.values, function (color) {
                color.hex = '#' + color.hex;
            });
        });


        function getEnum(categoria) {
            var params = {
                'tipoCategoria': categoria,
                'estabelecimento': vm.hasEstabelecimento
            };

            ConfiguracaoFrequenciaService.getEnum(params, 'EquivalenciaEtapa').then(function (data) {
                vm.enumEquivalencia = data;
            });

            vm.optionsTipoRegistroFrenquencia = ConfiguracaoFrequenciaService.getEnum(params, 'TipoRegistroFrequencia');
        }

        function openCategoria(categoria) {

            vm.configuracao.tipoConfiguracaoFrequencia = categoria;

            getEnum(categoria);

            var params = {
                'tipoCategoria': categoria,
                'estabelecimento': vm.hasEstabelecimento
            };

            var promise;
            promise = ConfiguracaoFrequenciaService.getDefault(params);
            promise.then(function (data) {
                vm.configuracao = data.plain();

                setIdentificadorFrequenciaPadrao(categoria);
            });

            vm.loadings.categoria.addPromise(promise);
        }

        function salvar(categoria) {
            var params = {
                'tipoCategoria': categoria,
                'configuracao': vm.configuracao,
                'estabelecimento': vm.hasEstabelecimento
            };

            var promise = ConfiguracaoFrequenciaService.save(params)
                .then(function (data) {
                    vm.configuracao = data.plain();
                    Notification.publish('Configuração de frequências salva com sucesso', 'success');
                });

            vm.loadings.salvar.addPromise(promise);
        }

        function getEquivalencia(chave) {
            if (vm.enumEquivalencia) {
                var equivalencia = _.find(vm.enumEquivalencia.values, {
                    'key': chave
                });

                if (equivalencia) {
                    return equivalencia.description;
                }
            }
        }

        function desabilitaRestauraPadrao() {
            return !isRestauraPadrao();
        }

        function isRegistroPorAula() {
            //Registro por aula foi retirado através da solicitação MEDUC-1120
            //Mas podera vir ter novamente em outro momento
            return false;
            /*if (tiposRegistro) {
                var registro = _.find(tiposRegistro, 'tipoRegistroFrequencia', REGISTRO_FREQUENCIA_POR_AULAS);
                if (registro) {
                    return true;
                }
            }
            return false;*/
        }

        function isAee(key) {
            return (key === tipoConfiguracaoFrequencia.AEE);
        }

        function isEducacaoBasicaFundamental(key) {
            return (key === tipoConfiguracaoFrequencia.EDUCACAO_BASICA_ENSINO_FUNDAMENTAL);
        }

        function isEducacaoBasicaMedio(key) {
            return (key === tipoConfiguracaoFrequencia.EDUCACAO_BASICA_ENSINO_MEDIO);
        }

        function isAtividadeComplementar(key) {
            return (key === tipoConfiguracaoFrequencia.ATIVIDADE_COMPLEMENTAR);
        }

        function isEjaModularEnsinoFundamental(key) {
            return (key === tipoConfiguracaoFrequencia.EJA_MODULAR_ENSINO_FUNDAMENTAL);
        }

        function isEjaModularEnsinoMedio(key) {
            return (key === tipoConfiguracaoFrequencia.EJA_MODULAR_ENSINO_MEDIO);
        }

        function showConfiguracaoIdentificadorFrequencia(key) {
            return !(isAee(key) || isAtividadeComplementar(key) || isEjaModularEnsinoFundamental(key) || isEjaModularEnsinoMedio(key));
        }

        function isRestauraPadrao() {
            return vm.hasEstabelecimento;
        }

        function restauraPadrao(categoria) {
            if (isRestauraPadrao()) {
                var params = {
                    'tipoCategoria': categoria,
                    'configuracao': vm.configuracao,
                    'estabelecimento': !vm.hasEstabelecimento
                }, promise;

                promise = ConfiguracaoFrequenciaService.getDefault(params);
                promise.then(function (data) {
                    setFieldRegistroFrequencia(data);
                });

                vm.loadings.restauraPadrao.addPromise(promise);
            } else {
                return;
            }
        }

        function setFieldRegistroFrequencia(registros) {
            registros.id = angular.copy(vm.configuracao.id);

            if (registros.identificadorFrequencia) {
                registros.identificadorFrequencia.id = angular.copy(vm.configuracao.identificadorFrequencia.id);
            } else if (vm.configuracao.identificadorFrequencia) {
                registros.identificadorFrequencia = {
                    id: angular.copy(vm.configuracao.identificadorFrequencia.id),
                    letra: 'P',
                    legenda: 'Presença remota',
                    cor: 'AQUA'
                };
            }

            _.forEach(registros.tiposRegistroFrequencia, function (value, key) {
                value.id = vm.configuracao.tiposRegistroFrequencia[key].id;
            });

            _.merge(vm.configuracao, registros.plain());

            setIdentificadorFrequenciaPadrao(vm.configuracao.tipoConfiguracaoFrequencia);
        }

        function setIdentificadorFrequenciaPadrao(categoria) {
            if (!vm.configuracao.identificadorFrequencia && showConfiguracaoIdentificadorFrequencia(categoria)) {
                vm.configuracao.identificadorFrequencia = {};
                vm.configuracao.identificadorFrequencia.letra = 'P';
                vm.configuracao.identificadorFrequencia.legenda = 'Presença remota';
            }
        }

        function filterResultTipoRegistoFrequencia(term, value) {
            if (value.id === REGISTRO_FREQUENCIA_POR_AULAS) {
                return;
            }

            if (value.id === REGISTRO_TOTAL_FALTAS_EM_DIAS &&
                (vm.configuracao.tipoConfiguracaoFrequencia === 'EJA_MODULAR_ENSINO_FUNDAMENTAL' ||
                    vm.configuracao.tipoConfiguracaoFrequencia === 'EJA_MODULAR_ENSINO_MEDIO' ||
                    vm.configuracao.tipoConfiguracaoFrequencia === 'ATIVIDADE_COMPLEMENTAR' ||
                    vm.configuracao.tipoConfiguracaoFrequencia === 'AEE')) {
                return;
            }

            var valueMaisculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (valueMaisculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.formulaCalculoInstrumento')
        .factory('educacao.formulaCalculoInstrumento.FormulaCalculoInstrumentoService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {

        var selfService = ServiceBuilder.create()
            .path('commons/formula-calculo')
            .enum('SituacaoCadastral')
            .build();

        selfService.getFormula = getFormula;

        return selfService;

        function getFormula() {
            return selfService.getEndpoint().get('formulas-instrumentos');
        }        
    }
})();


(function () {
    'use strict';

    angular.module('educacao.calendario')
        .directive('appApplicarTodosSelect2', appEstabelecimentoSelect2);

    appEstabelecimentoSelect2.$inject = [];

    var allItem = createAllItem();
    var blockItem = createBlockItem();

    function appEstabelecimentoSelect2() {

        return {
            restrict: 'E',
            template: '<input type="hidden">',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                search: '=',
                applyAll: '=',
                ngRequired: '='
            },

            controller: Controller,
            controllerAs: 'vm',

            link: postLink
        };

        function postLink(scope, element, attrs, controller) {
            var vm = scope.vm || {};
            var select2Element = element.find('input');
            select2Element
                .select2({
                    multiple: true,
                    allowClear: true,

                    minimumInputLength: 0,

                    initSelection: vm.initSelection,
                    query: vm.search,
                    formatResult: vm.formatResult,
                    formatSelection: vm.formatSelection
                })
                .on('select2-blur', function () {
                    scope.$apply(controller.$setTouched());
                }).on('select2-selecting select2-clearing select2-removing', function () {
                    scope.$apply(controller.$setDirty());
                }).on('select2-blur', function () {
                    element.blur();
                });

            controller.$validators.required = isValid;

            function isValid() {
                if (controller.$pristine && scope.applyAll) {
                    return true;
                }
                var elementData = select2Element.select2('data');
                return Boolean(elementData && elementData.length);
            }
        }

    }

    Controller.$inject = ['$scope', '$element', 'educacao.common.ObjectUtilsService'];

    function Controller($scope, $element, ObjectUtilsService) {
        var vm = this;

        var select2Element = $element.find('input');

        vm.isApplyToAll = false;
        vm.hasValues = false;

        vm.initSelection = initSelection;
        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;

        $scope.$watch('ngModel', setElementValue);
        $scope.$watch('applyAll', applyAll);

        $element.on('change', onChange)
            .on('select2-selecting', onSelecting);

        var currentValue;

        function updateControlFlags(data) {
            vm.isApplyToAll = data.length === 1 && data[0].id === allItem.id;
            vm.hasValues = !vm.isApplyToAll && data.length;
        }

        function setElementValue(newValue) {
            if (currentValue === newValue || (_.isUndefined(currentValue) && !newValue)) {
                return;
            }

            newValue = newValue || [];
            updateControlFlags(newValue);   
            select2Element.select2('data', newValue);

            currentValue = newValue;
            
            select2Element.trigger('change');  
        }

        function applyAll(newValue) {
            if (newValue && (!currentValue || !currentValue.length)) {
                setElementValue([allItem]);
            }
        }

        function onChange() {
            var value = select2Element.select2('data');
            updateControlFlags(value);

            currentValue = vm.isApplyToAll ? [] : value;

            if($scope.ngModel !== currentValue){
                $scope.ngModel = currentValue;
            }
        }

        function onSelecting(event) {
            var selectedObject = event.object;

            if (blockItem.id === selectedObject.id) {
                event.preventDefault();
            }
        }

        function initSelection(element, callback) {
            var id = $(element).val();
            if (!id || id === allItem.id) {
                callback(allItem);
            } else {
                callback();
            }
        }

        function formatResult(data) {
            return data.text ? data.text : (data.descricao || data.pessoa.nome);
        }

        function formatSelection(data) {
            return data && (data.descricao || data.pessoa.nome);
        }

        function search(options) {
            if (vm.isApplyToAll) {
                options.callback({
                    results: [blockItem],
                    more: false
                });
                return;
            }

            var term = ObjectUtilsService.normalizeFilter(options.term);
            var encoded = encodeURI('%' + term + '%');

            var result = $scope.search(options.page - 1, encoded);

            result.then(function (data) {
                if (!vm.hasValues && (!term || allItem.text.indexOf(term) !== -1)) {
                    data.unshift(allItem);
                }
                options.callback({
                    results: data,
                    more: data.hasNext
                });
            });
            return result;
        }
    }

    function createAllItem() {
        var item = {};
        item.id = '-1';
        item.descricao = 'Aplicar para todos';
        item.text = item.descricao;

        return item;
    }

    function createBlockItem() {
        var item = {};
        item.id = '-2';

        item.descricao = 'Você selecionou a opção "' +
            createAllItem().descricao +
            '". Para selecionar outras opções exclua esta';

        item.text = item.descricao;
        return item;
    }
})();

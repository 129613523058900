(function() {
    'use strict';

    angular.module('educacao.context')
        .run(watchContexts);

    watchContexts.$inject = ['$rootScope', '$state', 'educacao.context.UserAccessService', 'educacao.context.ContextoEntidade',
        'educacao.context.ContextoEstabelecimento', 'educacao.context.ContextoAnoLetivo', 'bfc.$$PermissionsService', '$cookies'];

    function watchContexts($rootScope, $state, UserAccessService, ContextoEntidade, ContextoEstabelecimento, ContextoAnoLetivo,
        PermissionsService, $cookies) {

        var debouncedReload = _.debounce(reload, 100);

        $rootScope.$on('app-context:entidade:new', debouncedReload);
        $rootScope.$on('app-context:anoletivo:new', debouncedReload);
        $rootScope.$on('app-context:estabelecimento:new', debouncedReload);

        $rootScope.$on('$stateChangeStart', route);

        function reload() {
            setCookieContext();

            if ($state.includes('app')) {
                $state.go($state.current, getContextStateParams());
            }
        }

        function route(event, toState) {
            //FIXME: P&D adicionou uma condição que mata quem usa ui-router (state.js:StateChangePermissionsCheck:21)
            if (PermissionsService.$$isPermissionsLoaded() && !PermissionsService.checkPermissionForState(toState)) {
                event.preventDefault();
                $state.go(PermissionsService.$$stateWhenFail());
                return;
            }

            if (toState.name.indexOf('selecao') === -1) {
                return;
            }
            var destination = getDestination();
            if (!_.isEqual(destination, toState.name)) {
                event.preventDefault();
                $state.go(destination, getContextStateParams());
            }
        }

        function getContextStateParams() {
            return {
                acesso: UserAccessService.getHashAcesso(),
                anoletivo: ContextoAnoLetivo.getValorContextoAnoLetivo()
            };
        }

        function getDestination() {
            if (!ContextoEntidade.hasEntidade()) {
                return 'selecao.entidade';
            } else if (!ContextoEstabelecimento.hasEstabelecimento()) {
                return 'selecao.estabelecimento';
            } else if (!ContextoAnoLetivo.hasAnoLetivo()) {
                return 'selecao.anoletivo';
            } else {
                return 'app';
            }
        }

        function setCookieContext() {
            var context = buildContextForCookie();
            var contextBase64 = encodeContextToBase64(context);
            $cookies.put('contextoAtual', contextBase64, { domain: 'betha.cloud' });
        }

        function encodeContextToBase64(context) {
            return btoa(angular.toJson(context)).replace(/=+$/, '');
        }

        function buildContextForCookie() {
            var context = {
                entidade: getEntidade(),
                anoletivo: getAnoLetivo()
            };

            var estabelecimento = getEstabelecimento();
            if (estabelecimento) {
                context.estabelecimento = estabelecimento;
            }
            return context;
        }

        function getEntidade() {
            var entidade = ContextoEntidade.getEntidade();
            return {
                id: _.get(entidade, 'id'),
                nome: _.get(entidade, 'name')
            };
        }

        function getEstabelecimento() {
            var estabelecimento = ContextoEstabelecimento.getEstabelecimento();

            if (_.get(estabelecimento, 'isSecretaria')) {
                return null;
            }

            return {
                id: _.get(estabelecimento, 'id'),
                descricao: _.get(estabelecimento, 'descricao')
            };
        }

        function getAnoLetivo() {
            var anoLetivo = ContextoAnoLetivo.getAnoLetivo();
            return {
                id: _.get(anoLetivo, 'id'),
                ano: _.get(anoLetivo, 'ano')
            };
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosSubstitutosProfessoresGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-professores-gestao-pessoas/table-dados-professores-gestao-pessoas/table-dados-locais-trabalhos-professores-gestao-pessoas/table-dados-substitutos-professores-gestao-pessoas/table-dados-substitutos-professores-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                substitutos: '=',
                isProfessoresSubstituidos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.formatCargaHoraria = formatCargaHoraria;



        function formatCargaHoraria(cargaHorariaFuncionario) {
            if (!cargaHorariaFuncionario) {
                return '-';
            }

            var duration = moment.duration(cargaHorariaFuncionario);
            var hours = Math.floor(duration.asHours());
            var minutes = duration.minutes();
            return hours + 'h ' + minutes + 'm';
        }
    }
})();
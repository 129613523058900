(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('stepLocalizando', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: {
                inscricao: '='
            },

            templateUrl: 'matricula/inscricao/step-localizando/step-localizando.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];
    function Controller($scope) {
        var vm = this;

        vm.currentTab = 0;

        $scope.$watch('inscricao.$$etapaSelecionada', onConfiguracaoUpdated);

        function onConfiguracaoUpdated(etapaSelecionada) {
            vm.currentTab = etapaSelecionada ? 1 : 0;
        }
    }
})();
(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appAccordionChaItem', AccordionChaItemDirective);

    function AccordionChaItemDirective() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/accordion-cha/accordion-cha-item.html',
            transclude: true,
            replace: true,
            require: '^appAccordionCha',
            scope: {},
            bindToController: {
                title: '@',
                chaClass: '@',
                classCounter: '@',
                quantity: '=',
                titleDetail: '@',
                textDetail: '='
            },
            link: postLink,
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    function postLink(scope) {
        if (scope.$parent.registerAccordion) {
            scope.$parent.registerAccordion(scope);
        }
        if (scope.$parent.openItem) {
            scope.openItem = scope.$parent.openItem;
        }
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {

        var vm = this;

        $scope.collapse = true;

        vm.onClickGroup = onClickGroup;

        function onClickGroup() {

            if ($scope.openItem) {
                $scope.openItem($scope);
            }

            $scope.$applyAsync();
        }
    }

})();


(function() {

    'use strict';

    angular.module('educacao.configuracao-evasao-reprovacao')
        .controller('educacao.configuracao-evasao-reprovacao.ConfiguracaoEvasaoReprovacaoController', ConfiguracaoEvasaoReprovacaoController);


    ConfiguracaoEvasaoReprovacaoController.$inject = [
        '$state',
        'promiseTracker',
        'bfc.$$PermissionsService',
        '$configuracaoEvasaoReprovacaoUtilsService',
        'educacao.configuracao-evasao-reprovacao.ConfiguracaoEvasaoReprovacaoService'
    ];

    function ConfiguracaoEvasaoReprovacaoController($state, promiseTracker, $$PermissionsService, $configuracaoEvasaoReprovacaoUtilsService, ConfiguracaoEvasaoReprovacaoService) {

        var vm = this;

        vm.onClickConfigurarIndicadores = onClickConfigurarIndicadores;
        vm.onClickStart = onClickStart;

        vm.started = false;
        vm.isAdmin = $$PermissionsService.isAdmin();

        vm.tracker = {
            lote: promiseTracker()
        };

        init();

        function init() {
            if (!vm.isAdmin) {
                return $state.go('app');
            }

            vm.verificacao = null;

            getLoteTreinamento();
        }

        function getLoteTreinamento() {
            var promise = ConfiguracaoEvasaoReprovacaoService.getLoteTreinamento()
                .then(onGetLoteTreinamento);
            vm.tracker.lote.addPromise(promise);
            return promise;
        }

        function getResultados() {
            var promise = ConfiguracaoEvasaoReprovacaoService.getResultados()
                .then(function (data) {
                    vm.resultados = data;
                });
            vm.tracker.lote.addPromise(promise);
            return promise;
        }

        function onGetLoteTreinamento(lote) {
            vm.loteTreinamento = lote;
            vm.isLoteProcessado = $configuracaoEvasaoReprovacaoUtilsService.isProcessed(lote);

            if (vm.isLoteProcessado) {
                return getResultados()
                    .then(function(){
                        vm.started = lote && Boolean(lote.id);
                    });
            }

            vm.started = lote && Boolean(lote.id);
        }

        function onClickStart() {
            vm.started = true;
        }

        function onClickConfigurarIndicadores() {
            $state.go('app.configuracao-evasao-reprovacao.indicadores');
        }
    }

})();

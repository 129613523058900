(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {
        $stateProvider.state('app.geracaohorarios', {
            url: 'geracao-horarios',
            data: {id:'GradeHorariosPageMapping'},
            views: {
                '@': {
                    templateUrl: 'geracao-horarios/geracao-horarios.html',
                    controller: 'educacao.geracaoHorarios.GeracaoHorariosController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();

(function() {
	'use strict';

	var TipoEnturmacaoFuncionario = {
		'PROFESSOR': 'Professor',
		'AUXILIAR': 'Professor auxiliar',
		'REGENTE': 'Regente',
		'DOCENTE_TITULAR': 'Docentes titulares/coordenadores de tutoria EAD',
		'DOCENTE_TUTOR': 'Docentes tutores/auxiliares EAD',
		'TRADUTOR_INT_LIBRAS': 'Tradutor e Intérprete de Libras',
		'GUIA_INTERPRETE': 'Guia-Intérprete',
		'PROF_APOIO_ALUNO_DEF': 'Profissional de apoio escolar para aluno(a)s com deficiência',
		'AUXILIAR_SALA': 'Auxiliar de Sala',
		'INSTRUTOR_EDUCA_PROF': 'Instrutor da Educação Profissional'
	};

	angular.module('educacao.matricula').controller('educacao.matricula.EnturmacoesInativasController', Controller);

	Controller.$inject = ['$modalInstance', 'promiseTracker', 'bfc.Notification', 'educacao.matricula.EnturmacaoProfessorService'];

	function Controller($modalInstance, promiseTracker, Notification, EnturmacaoProfessorService) {
		var vm = this;

		var turmaId = _.get($modalInstance, 'params.turmaId');

		vm.enturmacaoProfessorService = EnturmacaoProfessorService;
		vm.tipoEnturmacaoFuncionario = TipoEnturmacaoFuncionario;

		vm.promiseTracker = promiseTracker();

		vm.filter = {};
		vm.controls = {};
		vm.columns = [
			{
				id: 'nome',
				label: 'Professor',
				model: 'nome',
				filterable: true
			}
		];
		vm.expression = {
			onBeforeBuild: function() {
                return 'turma = ' + turmaId + ' and dataEncerramento is not null';
            }
		};

		vm.search = search;
		vm.remove = remove;


		function search() {
			vm.controls.search();
		}
		
		function remove(enturmacao) {
			return EnturmacaoProfessorService.remover(enturmacao).then(success);
				
			function success() {
				vm.controls.search();
				Notification.publish('Enturmação removida com sucesso!', 'success');
			}
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.motivosabonosjustificativas')
        .controller('educacao.motivosabonosjustificativas.MotivosAbonosJustificativasCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        'ui.components.Modal',
        'educacao.motivosabonosjustificativas.MotivosAbonosJustificativasService',
        'bfc.Notification',
        '$enumsCache'
    ];

    function Controller(
        promiseTracker,
        uiModal,
        MotivosAbonosJustificativasService,
        bfcNotification,
        $enumsCache         
    ) {
        var vm = this;

        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        $enumsCache.get('TipoAbono')
                .then(function (enumTipos) {
                    vm.enumTipoAbono = enumTipos;
                });

        vm.filter = {};
        vm.columns = [
            {
                id: 'descricao',
                model: 'descricao',
                label: 'Motivo',
                filterable: true
            }
        ];

        vm.listAbonosJustificativasControls = {};
        
        vm.MotivosAbonosJustificativasService = MotivosAbonosJustificativasService;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.search = search;

        function search() {
            vm.listAbonosJustificativasControls.search();
        }

        function openCad(motivoAbonoJustificativa) {
            return uiModal.open({
                templateUrl: 'motivos-abonos-justificativas/cadastro/motivos-abonos-justificativas.cad.html',
                controller: 'educacao.motivosabonosjustificativas.MotivosAbonosJustificativasCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    motivoAbonoJustificativa: motivoAbonoJustificativa ? MotivosAbonosJustificativasService.get(motivoAbonoJustificativa.id) : {},
                }
              }).result;
        }
    
        function remove(motivoAbonoJustificativa) {
            return MotivosAbonosJustificativasService.remove(motivoAbonoJustificativa)
                    .then(function() {
                        bfcNotification.publish('Motivo (' + motivoAbonoJustificativa.descricao +  ' - Tipo: ' + motivoAbonoJustificativa.tipo + ') excluído com sucesso.', 'success');
                    });
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appTableMatricula', AppDocumento);

    function AppDocumento() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/comprovante/app-table-matricula/app-table-matricula.directive.html',
            scope: {
                matricula: '=',
                isVaga: '=',
                isEjaModular: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        var matricula = $scope.matricula;
        var isVaga = $scope.isVaga;
        var isEjaModular = $scope.isEjaModular;

        vm.headersTable = {
            showTurno: true
        };

        init();

        function init() {
            formatMatricula();
        }

        function formatMatricula() {
            var objMatricula = {
				estabelecimento: { descricao: matricula.estabelecimento.descricao },
				situacao: matricula.situacao
            };

            if (isVaga) {
                vm.matricula = formatToVaga(objMatricula);
                return;
            }

            if (isEjaModular) {
                vm.matricula = formatToEjaModular(objMatricula);
                return;
            }

            vm.matricula = formatToAtividade(objMatricula);
        }
        
        function formatToVaga(objMatricula) {
            vm.headersTable.details = matricula.turma ? 'Turma/Etapa' : 'Etapa';
            
            objMatricula.turno = matricula.turno;
            objMatricula.details = getDetails(matricula);
            
            
            return objMatricula;
        }

        function formatToEjaModular(objMatricula) {
            vm.headersTable.details = 'Turma(s)';
            vm.headersTable.showTurno = false;

            objMatricula.details = matricula.filhos.map(getDisciplina).join(', ');

            return objMatricula;
        }

        function formatToAtividade(objMatricula) {
            vm.headersTable.details = _.first(matricula.filhos).turma ? 'Turma/Atividade' : 'Atividade';

            objMatricula.turno = matricula.turno;
            objMatricula.details = getDatailTurmaAtividade(_.first(matricula.filhos));

			return objMatricula;

        }
        
        function getDetails(item) {
            if (item.turma) {
                return item.turma.descricao + '/' + item.etapaMatriz.descricao;
            }
            return item.etapaMatriz.descricao;
        }

        function getDatailTurmaAtividade(item) {
            if (item.turma){
                return item.turma.descricao + '/' + item.atividade.descricao;
            }
            return item.atividade.descricao;
        }

		function getDisciplina(item) {
            if (item.turma) {
                return item.turma.descricao + ' - ' + item.disciplina.disciplina.descricao;
            }
			return item.disciplina.disciplina.descricao;
		}
    }
})();

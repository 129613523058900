(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appDropdownContextoEntidade', directive);

    directive.$inject = [];
    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/layout/header/context-bar/dropdown-contexto-entidade.directive.html',
            replace: true,

            scope: {},

            controller: Contoller,
            controllerAs: 'vm'
        };
    }

    Contoller.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.context.ContextoEntidade'
    ];
    function Contoller($scope, promiseTracker, ContextoEntidade) {
        var vm = this;

        vm.tracker = promiseTracker();

        vm.entidade = ContextoEntidade.getEntidade();

        vm.setEntidade = setEntidade;
        vm.getEntidadeName = getEntidadeName;

        $scope.$on('app-context:entidade:new', function () {
            vm.entidade = ContextoEntidade.getEntidade();
        });

        loadEntidades();

        function loadEntidades() {
            vm.tracker.addPromise(
                ContextoEntidade
                    .listaEntidades()
                    .then(function (data) {
                        vm.entidades = data;
                    })
            );
        }

        function setEntidade(entidade) {
            ContextoEntidade.setEntidade(entidade);
        }

        function getEntidadeName() {
            if(ContextoEntidade.getEntidade()){
                return ContextoEntidade.getEntidade().name;
            }
        }
    }
})();
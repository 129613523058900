(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appAccordionGroupPanel', AccordionButtonPanelDirective);

    function AccordionButtonPanelDirective() {
        return {
            restrict: 'E',

            template: '<div class="panel panel-default"><div class="list-group" ></div></div>',
            replace: true,
            transclude: true,
            scope: {},
            controller: Controller,
            controllerAs: 'vm'
        };
    }
    Controller.$inject = ['$scope', '$element', '$transclude'];

    function Controller($scope, $element, $transclude) {

        var vm = this;

        vm.accordions = [];

        $transclude(function(clone, innerScope) {
            innerScope.registerAccordion = registerAccordion;
            innerScope.closeAll = closeAll;
            $element.children('.list-group').append(clone);
        });

        function registerAccordion(accordion) {
            vm.accordions.push(accordion);
        }

        function closeAll() {

            angular.forEach(vm.accordions, function(accordion) {

                var accordionsDosFilhos = accordion.getRecordedAccordions();

                angular.forEach(accordionsDosFilhos, function(filho) {
                    filho.active = false;
                    filho.collapsed = !filho.active;
                });

                accordion.active = false;
                accordion.collapsed = !accordion.active;
            });
        }
    }

})();


(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .controller('educacao.geracaoHorarios.GeracaoHorariosController', GeracaoHorariosController);

    GeracaoHorariosController.$inject = [
        'ui.components.Modal', 
        'promiseTracker',
        'educacao.geracaoHorarios.GeracaoHorariosService',
        'bfc.$$PermissionsService'
    ];

    function GeracaoHorariosController($modal, $promiseTracker, GeracaoHorariosService, PermissionsService) {
        var vm = this;

        vm.tracker = {
            list: $promiseTracker()
        };
        vm.permissionEdit = !PermissionsService.isRevokedOperation('GradeHorariosPageMapping', 'editar');        

        vm.openModalCadastro = openModalCadastro;
        vm.openEditarQuadro = openEditarQuadro;
        vm.openCopiaQuadro = openCopiaQuadro;
        vm.GeracaoHorariosService = GeracaoHorariosService;
        vm.remove = remove;

        function openModalCadastro(gradeHorarios) {
            var params = {
                quadroHorarios: gradeHorarios ? GeracaoHorariosService.get(gradeHorarios.id) : null
            };

            openModal(params);
        }

        function openEditarQuadro(gradeHorarios) {
            var params = {
                quadroHorarios: GeracaoHorariosService.get(gradeHorarios.id),
                buildingQuadro: true
            };

            openModal(params);
        }

        function openCopiaQuadro(gradeHorarios) {
            var params = {
                permissionEdit: vm.permissionEdit,
                quadroHorarios: GeracaoHorariosService.get(gradeHorarios.id)
            };

            $modal.open({
                templateUrl: 'geracao-horarios/geracao-horarios-copia.cad.html',
                controller: 'educacao.geracaoHorarios.GeracaoHorariosCopiaCadController',
                controllerAs: 'vm',
                size: 'md',
                params: params
            });
        }

        function openModal(params) {
            $modal.open({
                templateUrl: 'geracao-horarios/geracao-horarios.cad.html',
                controller: 'educacao.geracaoHorarios.GeracaoHorariosCadController',
                controllerAs: 'vm',
                size: 'xxl',
                params: params
            });
        }

        function remove(entity) {
            return GeracaoHorariosService.remove(entity);
        }
    }
})();

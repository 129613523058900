(function () {
    'use strict';

    angular.module('educacao.historico').directive('appHistoricoDetails', HistoricoDetails);

    HistoricoDetails.$inject = ['$compile', 'educacao.historico.HistoricoService'];
    function HistoricoDetails($compile, HistoricoService) {
        return {
            restrict: 'E',
            templateUrl: 'historico/app-historico-details.directive.html',
            scope: {
                historico: '=ngModel'
            },
            link: function HistoricoDetailsLink($scope, $element) {

                var context = {
                    scope: $scope.$new(),
                    detailsElement: $element.find('.historico-details'),
                    actionsElement: $element.find('.historico-actions'),
                    $compile: $compile
                };

                $scope.$watch('historico', function (historico) {

                    context.historico = historico;
                    context.handler = HistoricoService.getTipoHandler(historico.tipo);
                    fillScope(context);

                    handlerActions(context);
                    handlerGlobalDetails(context);
                    handlerHistoricoCampos(context);
                });
            }
        };
    }

    function handlerHistoricoCampos(context) {

        if (!context.handler.campos) {
            return;
        }

        _.each(context.historico.campos, function (campo) {
            var campoHandler = context.handler.campos[campo.nome] || context.handler.campos.DEFAULT;
            if (!campoHandler) {
                return;
            }

            var acaoHandler = getAcaoHandler(campo, campoHandler);
            if (!acaoHandler) {
                return;
            }

            if (acaoHandler.template) {

                var campoScope = context.scope.$new();
                campoScope.nome = campo.nome;
                campoScope.valor = campo.valor;
                campoScope.valorAnterior = campo.valorAnterior;

                context.detailsElement.append(context.$compile(acaoHandler.template)(campoScope));
            }

            if (angular.isFunction(acaoHandler.templateFunction)) {
                acaoHandler.templateFunction(context.historico, campo, context.detailsElement);
            }
        });
    }


    function handlerActions(context) {
        if (context.handler.actionTemplates) {
            for (var index = 0; index < context.handler.actionTemplates.actionTemplate.length; index++) {
                var content = context.$compile(context.handler.actionTemplates.actionTemplate[index])(context.scope);
                context.actionsElement.append(content);
            }
        }
    }

    function handlerGlobalDetails(context) {
        if (context.handler.template) {
            var content = context.$compile(context.handler.template)(context.scope);
            context.detailsElement.append(content);
        }
        if (angular.isFunction(context.handler.templateFunction)) {
            context.handler.templateFunction(context.historico, context.detailsElement);
        }
    }

    function fillScope(context) {
        context.scope.handler = context.handler;
        _.each(context.historico.campos, function (campo) {
            context.scope[campo.nome] = campo;
        });
    }

    function getAcaoHandler(campo, campoHandler) {

        //Verifica se o handler não é para todos os tipos de operações
        if (campoHandler.template || campoHandler.templateFunction) {
            return campoHandler;
        }

        if (isInsercao(campo)) {
            return campoHandler.insercao || campoHandler.DEFAULT;
        }

        if (isAlteracao(campo)) {
            return campoHandler.alteracao || campoHandler.DEFAULT;
        }

        if (isRemocao(campo)) {
            return campoHandler.remocao || campoHandler.DEFAULT;
        }

        return campoHandler.DEFAULT;
    }

    function isInsercao(campo) {
        return !campo.valorAnterior && campo.valor;
    }

    function isAlteracao(campo) {
        return campo.valorAnterior && campo.valor;
    }

    function isRemocao(campo) {
        return campo.valorAnterior && !campo.valor;
    }
})();
(function() {
    'use strict';

    angular.module('educacao.cursoaperfeicoamento')
        .factory('educacao.cursoaperfeicoamento.CursoAperfeicoamentoService', Service);

    Service.$inject = ['$injector'];


    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('funcionario', 'curso-aperfeicoamento')
            .build();

        return selfService;
    }
})();

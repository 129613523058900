(function () {
    'use strict';

    angular.module('educacao.calendario')
        .directive('appAvisos', widgetAvisos);

    function widgetAvisos() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/avisos/avisos.directive.html',
            scope: {
                avisos: '=',
                onClick: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;
        vm.isAvisosBoxOpen = true;

        vm.onClick = onClick;

        function onClick(aviso) {
            ($scope.onClick || angular.noop)(aviso);
        }
    }

})();

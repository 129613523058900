(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .service('educacao.lista-espera-novo.inscricao.ClassificacaoLoteService', ClassificacaoLoteService);

    ClassificacaoLoteService.$inject = ['Restangular'];

    function ClassificacaoLoteService(Restangular) {

        return {
            ultima: ultima,
            ultimaComSucesso: ultimaComSucesso
        };

        function ultima(idConfiguracao) {
            return Restangular.one('lista-espera')
                .one('classificacao/configuracao', idConfiguracao)
                .all('ultima')
                .get('');
        }


        function ultimaComSucesso(idConfiguracao) {
            return Restangular.one('lista-espera')
                .one('classificacao/configuracao', idConfiguracao)
                .all('ultima-com-sucesso')
                .get('');
        }

    }

})();
(function () {
    'use strict';

    var templateDescricao = 'descricao like "<%= term %>"';
    var templateEstabelecimento = ' and estabelecimento=<%= estabelecimento%>';
    var templateMatriz = ' and matriz=<%= matriz%>';

    angular.module('educacao.funcionario')
        .directive('appSelectCalendarioMatriz', directive);

    function directive() {
        return {
            template: '<ui-select search="vm.listaCalendarioMatriz" ng-model="ngModel" ng-disabled="ngDisabled" dropdown-css-class="dropdownCssClass"></ui-select>',

            scope: {
                ngModel: '=',
                ngDisabled: '=',

                dropdownCssClass: '@',

                descricao: '=',
                estabelecimento: '=',
                matriz: '='
            },

            compile: setMultiple,

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    function setMultiple(element, attr) {
        if (attr.hasOwnProperty('multiple')) {
            element.find('ui-select').attr('multiple', '');
        }
    }

    Controller.$inject = ['$scope', 'educacao.calendario.CalendarioMatrizCurricularBuscaService'];
    function Controller($scope, CalendarioMatrizCurricularService) {
        var templateBusca;
        var vm = this;

        vm.listaCalendarioMatriz = listaCalendarioMatriz;

        $scope.$watchGroup(['estabelecimento', 'matriz'], function () {
            var query = templateDescricao;

            if ($scope.estabelecimento) {
                query += templateEstabelecimento;
            }
            if ($scope.matriz) {
                query += templateMatriz;
            }
            templateBusca = _.template(query);
        });

        function listaCalendarioMatriz(params) {
            params.estabelecimento = $scope.estabelecimento;
            params.matriz = $scope.matriz;
            return CalendarioMatrizCurricularService.list(templateBusca(params), params.offset, params.limit);
        }
    }
})();
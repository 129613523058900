(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('appSelectResponsaveis', appSelectResponsaveis);

    appSelectResponsaveis.$inject = [];

    function appSelectResponsaveis() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" search="vm.search"  format-result="vm.formatResult(data)" format-selection="vm.formatResultSelect(data)" ng-required="!!notNull" add-value="vm.add"></ui-select>',
            require: 'ngModel',
            scope: {
                required: '=',
                ngModel: '='
            },
            controller: ControllerAppSelectResponsaveis,
            controllerAs: 'vm'
        };
    }

    ControllerAppSelectResponsaveis.$inject = ['$scope', 'bfc.$$PermissionsService', 'ui.components.Modal',
        'educacao.pessoas.ResponsavelService', '$filter'];

    function ControllerAppSelectResponsaveis($scope, PermissionsService, $modal, ResponsavelService, $filter) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatResultSelect = formatResultSelect;
        vm.add = !PermissionsService.isRevokedOperation('ResponsavelPageMapping', 'criar') && add;

        var templateResponsavel = _.template('<%= nome %> <br><small> CPF: <%= cpf %> | DATA DE NASCIMENTO: <%= dataNascimento %> | RG: <%= rg %> </small>');

        function add(nome) {
            var vinculo = {};
            return $modal.open({
                templateUrl: 'planejamento/responsaveis/cadastro-responsaveis.html',
                controller: 'educacao.pessoas.ResponsavelCadCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    responsavel: {
                        pessoa: {
                            nome: nome
                        }
                    },
                    vinculo: {
                        permiteRetirarAluno: vinculo ? angular.copy(vinculo.permiteRetirarAluno) : null,
                        diasDaSemana: vinculo.diaSemana || [],
                        parentesco: vinculo.parentesco,
                        outroParentesco: vinculo.outroParentesco
                    },
                }
             }).result.then(afterSaveResponsavel);
             
        }

        function afterSaveResponsavel(objetoRetorno) {
            $scope.ngModel= {
                    id: objetoRetorno.vinculo.responsavel.id,
                    nome: objetoRetorno.vinculo.responsavel.nome,
                    pessoa: {
                        nome: objetoRetorno.vinculo.responsavel.nome
                    },
                    quantidadeAlunos: 0
            };
        }

        function formatResult(data) {
            if(!data.pessoa){
                return undefined;
            }

            return templateResponsavel({
                nome: data.pessoa.nome || '',
                cpf: $filter('bfMaskCPF')(data.pessoa.cpf),
                rg: data.pessoa.rg,
                dataNascimento: $filter('bfDate')(data.pessoa.dataNascimento)
            });
        }

        function formatResultSelect(data){
            return data.pessoa ? data.pessoa.nome : undefined;
        }

        function search(params) {
            var filter = '(nome like "' + params.term + '"';

            filter += ')';
            return ResponsavelService.getPessoas(filter, params.offset, params.limit).then(function(data) {
                return data;

            });
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.agendaprofessor').factory('educacao.agendaprofessor.CalendarioProfessorService', CalendarioProfessorService);



    CalendarioProfessorService.$inject = ['Restangular'];

    function CalendarioProfessorService(Restangular) {

        var base = 'calendario/agenda-professor';

        return {
            getEventos: getEventos,
            getFeriados: getFeriados
        };

        function getEventos() {
            return Restangular.one(base+'/eventos').get();
        }

        function getFeriados() {
            return Restangular.one(base+'/feriados').get();
        }


    }
})();

(function () {
    'use strict';

    angular
        .module('educacao.common')
        .directive('appSelectEstabelecimentoSecretaria', appSelectEstabelecimentoSecretaria);

    function appSelectEstabelecimentoSecretaria() {

        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
            ' format-result="vm.formatResult(data)" search="vm.search"/>',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?'
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'educacao.pessoas.EstabelecimentoService'];

    function Controller($scope, EstabelecimentoService) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        var itemSecretaria = {
            id: 'Secretaria',
            descricao: 'Secretaria',
            isSecretaria: true
        };

        function search(params) {
            var filter = '(nome like "' + params.term + '" ';

            if ($scope.multiple) {
                var estebelecimentoIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        if(model.id !== itemSecretaria.id) {
                            estebelecimentoIds.push(model.id);
                        }
                    });
                }
                if (estebelecimentoIds.length) {
                    filter += ' and id not in(' + estebelecimentoIds.join() + ')';
                }
            }
            filter += ')';

            return EstabelecimentoService
                .list(filter, params.offset, params.limit)
                .then(function (data) {
                    var term = decodeURI(params.term);

                    if (itemSecretaria.descricao.toLowerCase()
                        .indexOf(term.replace(/%/g, ' ').trim().toLowerCase()) >= 0) {
                        data.unshift(itemSecretaria);
                    }

                    return {
                        content: data,
                        hasNext: false
                    };
                });
        }

        function formatResult(data) {
            return data.descricao || data.nome || (data.pessoa ? data.pessoa.nome : '');
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.cursoaperfeicoamento')
        .controller('educacao.cursoaperfeicoamento.CursoAperfeicoamentoCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.cursoaperfeicoamento.CursoAperfeicoamentoService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        CursoAperfeicoamentoService
    ) {
        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.CursoAperfeicoamentoService = CursoAperfeicoamentoService;

        function openCad(curso) {
            $modalStates.goModalState(curso);
        }

        function remove(data) {
            CursoAperfeicoamentoService.remove(data);
        }
    }
})();
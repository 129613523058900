(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.ConfiguracaoProcessoInscricaoService', ConfiguracaoProcessoInscricaoService);

    ConfiguracaoProcessoInscricaoService.$inject = ['$injector'];

    function ConfiguracaoProcessoInscricaoService($injector) {
        var GenericService = $injector.get('GenericService');


        var service = GenericService
            .createWithCustomMethods({
                getProcessosInscricao: _processos,
                putProcesso: _put
            })
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'configuracao-inscricao'
            });

        function _processos(offset, limit, filter, sort) {
            service.validateObject();

            return service.modules().all(service._service.uri + '/processos').getList({
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            });
        }

        function _put(processo) {
            service.validateObject();
 
            return service.modules().all(service._service.uri + '/processos/'  + processo.id ).customPUT(processo);
        }        

        return service;
    }
})();

(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.common');

    moduloCommon.controller('educacao.common.ParametrosCadCtrl', ParametrosCadCtrl);

    ParametrosCadCtrl.$inject = ['$injector', '$rootScope', '$scope', 'id', 'promiseTracker', '$modalInstance', '$modalTracker', '$q', 'fnCad'];

    function ParametrosCadCtrl($injector, $rootScope, $scope, id, promiseTracker, $modalInstance, $modalTracker, $q, fnCad) {
        var viewModel = this;
        var ParametroService = $injector.get('educacao.common.ParametroService');

        viewModel.tracker = {};
        viewModel.tracker.save = promiseTracker();

        var propriedadesPublicas = {
            save: _save,
            cancel: _cancel,
            modo: {
                editando: Boolean(id),
                adicionando: !id
            },
        };

        init();

        function _save() {
            viewModel.tracker.save.addPromise(
                ParametroService.save(viewModel.parametro).then(
                    function(data) {
                        if (angular.isFunction(fnCad)) {
                            fnCad(data);
                        }
                        _cancel();
                    }
                )
            );

        }

        // function refresh() {

        //     if (viewModel.form) {
        //         viewModel.form.$setPristine();
        //     }

        //     return !id ? ParametroService.getDefault().then(
        //             function(data) {
        //                 viewModel.parametro = data;
        //             }) :
        //         ParametroService.get(id).then(
        //             function(data) {
        //                 viewModel.parametro = data;
        //             }
        //         );

        // }

        function init() {

            $modalTracker.addPromise(
                $q.all([
                    ParametroService.get().then(
                        function(data) {
                            viewModel.parametro = data;
                        },
                        function() {
                            ParametroService.getDefault().then(
                                function(data) {
                                    viewModel.parametro = data;
                                });
                        }
                    )
                ]).then(
                    function postInit() {
                        angular.extend(viewModel, propriedadesPublicas);
                    }
                ));
        }

        function _cancel() {
            $modalInstance.close();
        }

    }

})();

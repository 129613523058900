(function() {
    'use strict';

    angular.module('educacao.planejamento').controller('educacao.planejamento.AvaliacoesExternasCtrl', AvaliacoesExternasCtrl);

    AvaliacoesExternasCtrl.$inject = ['educacao.planejamento.AvaliacoesExternasService', 'promiseTracker', 'educacao.common.ModalCad', '$scope', '$rootScope', 'educacao.common.ObjectUtilsService', '$q',
        'educacao.common.CommonSelectEnum', '$filter', 'educacao.avaliacao-externa.AvaliacaoExternaService'
    ];

    function AvaliacoesExternasCtrl(AvaliacoesExternasService, promiseTracker, ModalCad, $scope, $rootScope, ObjectUtilsService, $q, CommonSelectEnum, $filter, AvaliacaoExternaService) {

        var vm = this;
        vm.openCad = openCad;
        vm.tracker = {};
        vm.tracker.list = promiseTracker();
        vm.getIconSortClass = getIconSortClass;
        vm.remove = remove;
        vm.parseAvaliador = parseAvaliador;
        vm.parseEtapaAvaliada = parseEtapaAvaliada;

        var _select2 = {};

        init();

        function init() {

            refresh();
        }



        $scope.isSearching = function() {

            return Boolean($scope.search && ($scope.search.filter || $scope.search.value));
        };


        function refresh() {
            vm.tracker.list.addPromise(
                AvaliacaoExternaService.getAvaliacoes(0, 999).then(function(data) {
                    vm.avaliacoes = $filter('filter')(data.content, {
                        isPadrao: false
                    });
                    vm.avaliacoesPadroes = $filter('filter')(data.content, {
                        isPadrao: true
                    });
                    AvaliacoesExternasService.getEnums().then(function(enums) {
                        _select2.Avaliador = _.indexBy(enums.AvaliadorDto, 'key');
                        _select2.Etapa = _.indexBy(enums.EtapaEnsinoAvaliadaDto, 'key');
                    });
                })
            );
        }

        function openCad(id) {
            ModalCad.open({
                templateUrl: 'planejamento/avaliacoes/avaliacoes.cad.html',
                controller: 'educacao.planejamento.AvaliacoesExternasCadCtrl as AvalCadCtrl',
                id: id,
                readOnly: false
            });
        }

        function getIconSortClass(sortBy, tipo) {

            return {
                'icon-caret-down': tipo === 'U' ? vm.predicate === sortBy && vm.reverse : vm.predicatePadrao === sortBy && vm.predicatePadrao,
                'icon-caret-up': tipo === 'U' ? vm.predicate === sortBy && !vm.reverse : vm.predicatePadrao === sortBy && !vm.reversePadrao,
                'icon-sort': tipo === 'U' ? vm.predicate !== sortBy : vm.predicatePadrao !== sortBy
            };
        }



        function remove(data) {
            AvaliacoesExternasService.remove(data.id).then(function() {
                $rootScope.$broadcast('refreshAvaliações', {
                    data: data,
                    remove: true
                });
            });
        }

        function parseAvaliador(key) {
            if (typeof _select2.Avaliador !== 'undefined') {
                return _select2.Avaliador[key].description;
            }

        }

        function parseEtapaAvaliada(key) {
            if (typeof _select2.Etapa !== 'undefined') {
                if (typeof _select2.Etapa[key] !== 'undefined') {
                    return _select2.Etapa[key].description;
                }
            }
        }


        $scope.$on('refreshAvaliações', function(event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.avaliacoes = _.reject(vm.avaliacoes, function(avaliacao) {
                        return args.data.id === avaliacao.id;
                    });

                } else if (args.add) {
                    vm.avaliacoes.push(args.data);

                } else if (args.undo || args.edit) {
                    var avaliacao = _.find(vm.avaliacoes, function(av) {
                        return args.data.id === av.id;
                    });

                    if (avaliacao) {
                        ObjectUtilsService.copyProperties(avaliacao, args.data);
                    } else {
                        throw new Error('Avaliação não encontrada! ID: ' + args.data.avaliacao.id);
                    }
                }
            }
        });

    }


})();

(function(){

    'use strict';

    angular.module('educacao.common')
        .constant('STATES_ADICIONAR', {
            state: '.adicionar',
            url: '/adicionar'
        })
        .constant('STATES_EDITAR', {
            state: '.editar',
            url: '/editar/{id}'
        });

})();
(function() {

	'use strict';

	angular.module('educacao.matricula').directive('appSelectDisciplinaTurma', SelectDisciplinaTurmaDirective);

	SelectDisciplinaTurmaDirective.$inject = [];

	function SelectDisciplinaTurmaDirective() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)" search="vm.search" />',
			scope: {
				ngModel: '=',
				ngRequired: '=',
				ngDisabled: '=',
				filhos: '='
			},
			controller: SelectDisciplinaTurmaDirectiveController,
			controllerAs: 'vm'
		};
	}

	SelectDisciplinaTurmaDirectiveController.$inject = ['$injector', '$scope'];

	function SelectDisciplinaTurmaDirectiveController($injector, $scope) {
		var vm = this;

		var $q = $injector.get('$q');

		vm.search = search;
		vm.formatResult = formatResult;

		function search(params) {
			var term = decodeURI(params.term);
			term = term ? term.replace(/%/g, '') : term;

			var content = _.filter($scope.filhos, function(filho) {
				return filho.etapaMatrizDisciplina.disciplina.descricao.toLowerCase().contains(term.toLowerCase());
			});

			return $q.when({
				content: content,
				hasNext: false
			});
		}

		function formatResult(data) {
			return data.etapaMatrizDisciplina.disciplina.descricao;
		}

	}

})();


(function () {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioMatrizCurricularService', CalendarioMatrizCurricularService);

    CalendarioMatrizCurricularService.$inject = [
        'Restangular',
        'educacao.common.MetadataServiceHelper'
    ];

    function CalendarioMatrizCurricularService(restangular, MetadataServiceHelper) {
        var metadataHelper = MetadataServiceHelper.create(getBaseEndpoint());

        return {
            getDefaultRepresentation: metadataHelper.getDefaultRepresentation,
            getEnumDivisaoPeriodoAvaliativo: metadataHelper.enumObject('DivisaoPeriodoAvaliativo'),
            getEnumDayOfWeek: metadataHelper.enumObject('DayOfWeek'),
            list: list,
            listAvisos: listAvisos,
            get: get,
            save: save,
            remove: remove
        };

        function getBaseEndpoint(idCalendarioEstabelecimento) {
            var id = idCalendarioEstabelecimento ? idCalendarioEstabelecimento.toString() : '0';

            return restangular
                .all('calendario')
                .one('estabelecimento', id)
                .all('matrizcurricular');
        }

        function list(idCalendarioEstabelecimento) {
            return getBaseEndpoint(idCalendarioEstabelecimento).getList();
        }

        function get(idCalendarioEstabelecimento, id) {
            return getBaseEndpoint(idCalendarioEstabelecimento).get(id);
        }

        function listAvisos(idCalendarioEstabelecimento, id) {
            return getBaseEndpoint(idCalendarioEstabelecimento).one(id.toString()).one('avisos').getList();
        }

        function save(idCalendarioEstabelecimento, calendarioMatriz) {
            var endpoint = getBaseEndpoint(idCalendarioEstabelecimento);

            if (calendarioMatriz.id) {
                return endpoint.customPUT(calendarioMatriz, calendarioMatriz.id);
            } else {
                return endpoint.post(calendarioMatriz);
            }
        }

        function remove(idCalendarioEstabelecimento, calendarioMatriz) {
            return getBaseEndpoint(idCalendarioEstabelecimento).customDELETE(calendarioMatriz.toString());

        }
    }
})();

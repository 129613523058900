(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.TipoDependenciaSelect', TipoDependenciaSelect);

    TipoDependenciaSelect.$inject = [ '$injector',
       'educacao.common.ModalCad',
		'bfc.Select2Config',
        'educacao.planejamento.TiposDependenciasService',   
        'educacao.common.CommonSelectWithService'];
    
    
    function TipoDependenciaSelect( $injector,
            ModalCad,
			Select2Config,
            TiposDependenciasService,
            CommonSelectWithService    
       ) {
        
        return {
				select2Config: function() {
					return CommonSelectWithService.select2Config(TiposDependenciasService, 'descricao',
                       {
						onAdd: function(val, target) {
							ModalCad.open({
								templateUrl: 'planejamento/tipo-dependencia/tipo-dependencia.cad.html',
                                controller: 'educacao.planejamento.TiposDependenciasCadCtrl as TipoCadCtrl',
								optionsCad: {
									'descricao': val
								},
								selectElem: target
							});
							return false;
						}
					   }
                    );
                }
            };
	}
                                                                
})();
(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('appCheckbox', directive);

    directive.$inject = ['educacao.common.TranscludeAttributes'];
    function directive(TranscludeAttributes) {
        return {
            priority: 10,
            terminal: true,

            restrict: 'E',
            templateUrl: 'common/layout/component/form/app-checkbox.directive.html',
            replace: true,
            transclude: true,

            compile: compile
        };

        function compile(tElement) {
            TranscludeAttributes.transcludeInputAttributes(tElement, tElement.children('input'));
            return {post: postLink};
        }

        function postLink(scope, element, attrs) {
            var checkboxId = attrs.checkboxId || _.uniqueId('appChk');
            element.children('input').attr('id', checkboxId);
            element.children('label').attr('for', checkboxId);
        }
    }
})();

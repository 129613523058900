var TEMPO_MAXIMO = 30;
(function () {

    'use strict';

    angular.module('educacao.encerramento')
        .controller('educacao.encerramento.EncerramentoController', EncerramentoController);

    EncerramentoController.$inject = [
        '$scope',
        '$searchExpressionBuilder',
        'promiseTracker',
        'educacao.encerramento.EncerramentoService',
        'educacao.encerramento.EncerramentoUtilsService',
        '$q',
        'bfc.Notification',
        'bfc.$$PermissionsService',
        'educacao.context.ContextoEstabelecimento'
    ];

    function EncerramentoController(
        $scope,
        $searchExpressionBuilder,
        promiseTracker,
        EncerramentoService,
        EncerramentoUtilsService,
        $q,
        Notification,
        PermissionsService,
        ContextoEstabelecimento
    ) {
        var vm = this;

        vm.movimentarPeriodos = movimentarPeriodos;
        vm.encerrarTodosPeriodos = encerrarTodosPeriodos;
        vm.listaEncerramentos = listaEncerramentos;
        vm.onChangeCheckBox = onChangeCheckBox;
        vm.selecionarTodos = selecionarTodos;
        vm.sortColumn = sortColumn;
        vm.buscarIndicadores = buscarIndicadores;
        vm.getTotalSolicitacoesFormatado = getTotalSolicitacoesFormatado;
        vm.getTempoMedioSolicitacao = getTempoMedioSolicitacao;

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.columnsPeriodoLetivo = null;
        vm.hasItensSelecionados = false;
        vm.orderBy = false;
        vm.firstSearchExecuted = false;
        vm.loading = false;
        vm.filter = '';

        vm.currentPage = 1;
        vm.itemsPerPage = 20;

        vm.totalSolicitacoes = 0;
        vm.quantidadeSolicitacoesAtual = 0;
        vm.tempoMedioSolicitacao = 0;

        vm.hasPermissionEncerrar = !PermissionsService.isRevokedOperation('EncerramentoPeriodoPageMapping', 'Encerrar/Reabrir');

        vm.loadingTracker = promiseTracker();
        vm.trackerIndicadores = promiseTracker();


        vm.sortBy = {
            expression: 'turma',
            reverse: false
        };

        $scope.$watch('vm.sortBy', sort);

        initController();

        function initController() {
            vm.isSecretaria = ContextoEstabelecimento.isSecretaria();

            EncerramentoService.getEncerramentoTipoPeriodo().then(function (periodos) {
                vm.listPeriodos = _.get(periodos, 'values');
            });
        }

        function listaEncerramentos(filter) {

            var orderBy = sortColumn(vm.sortBy.reverse);
            
            var params = {
                filter: filter,
                offset: vm.currentPage - 1,
                limit: vm.itemsPerPage,
                sort: (vm.sortBy.expression ? (vm.sortBy.expression + ' ' + orderBy) : '')
            };

            params.offset = params.offset > 0 ? params.offset * params.limit : 0;

            vm.loading = true;
            vm.headerChecked = false;
            vm.hasItensSelecionados = false;

            var promise = EncerramentoService.listar(params).then(successListEncerramentos);

            vm.firstSearchExecuted = true;
            buscarIndicadores();
            vm.loadingTracker.addPromise(promise);
        }

        function getTotalSolicitacoesFormatado(solicitacoes){
            return solicitacoes.toLocaleString(undefined, {minimumFractionDigits:0});
        }

        function getTempoMedioSolicitacao(){
            if(vm.tempoMedioSolicitacao > TEMPO_MAXIMO){
                return 'Mais de 30 minutos';
            }
            return vm.tempoMedioSolicitacao+' min';
        }

        function buscarIndicadores(){
            vm.trackerIndicadores.addPromise(EncerramentoService.getIndicadores().then(setIndicadores));
        }

        function setIndicadores(indicadores){
            vm.totalSolicitacoes=indicadores.totalConcluidosUltimoDia;
            vm.quantidadeSolicitacoesAtual = indicadores.totalEncerramentosFila;
            vm.tempoMedioSolicitacao = _.round(indicadores.media,0);
        }

        function successListEncerramentos(encerramentos) {

            var itens = [];

            vm.columnsPeriodoLetivo = EncerramentoUtilsService.searchColumnPeriodoLetivo(vm.listPeriodos);
            vm.enumColumns = EncerramentoUtilsService.searchColumnsExcetoPeriodoLetivo(vm.listPeriodos);

            vm.page = encerramentos;
            vm.currentPage = (encerramentos.offset / encerramentos.limit) + 1;
            vm.totalItems = encerramentos.total;
            vm.hasNext = encerramentos.hasNext;
            vm.totalContent = encerramentos.content.length;

            _.forEach(encerramentos.content, function (item) {
                item.$$id = _.uniqueId();
                itens.push(item);
            });

            vm.itens = itens;

            vm.loading = false;
            loadSituacaoCadeado(vm.listPeriodos, vm.itens);
        }

        function loadSituacaoCadeado(colunas) {
            vm.itensHeader = {};

            _.forEach(colunas, function (coluna) {
                var periodo = _.find(vm.itens, function (item) {
                    return (item[coluna.id].status === 'EM_ABERTO' && item[coluna.id].possui && item[coluna.id].iniciado);
                });

                if (periodo) {
                    vm.itensHeader[coluna.id] = angular.copy(periodo);
                } else {
                    var item = {};
                    item[coluna.id] = {
                        status: 'ENCERRADO',
                        possui: true,
                        iniciado: true
                    };
                    vm.itensHeader[coluna.id] = item;
                }
            });
        }

        function sortColumn(orderBy) {
            if (orderBy) {
                return 'desc';
            } else {
                return 'asc';
            }
        }

        function sort(oldValue, newValue) {
            if (oldValue === newValue) {
                return;
            }
            listaEncerramentos(vm.getFilter());
        }

        function selecionarTodos(checkValue) {
            _.forEach(vm.itens, function (item) {
                item.checked = checkValue;
            });
            hasItensSelecionados();
        }

        function onChangeCheckBox() {
            hasItensSelecionados();
        }

        function hasItensSelecionados() {
            vm.hasItensSelecionados = _.findIndex(vm.itens, 'checked', true) >= 0;
        }

        function getItensSelecionados() {
            return _.filter(vm.itens, 'checked', true);
        }

        function encerrarTodosPeriodos() {
            var itensEncerramento = [];
            var itensSelecionados = getItensSelecionados();
            vm.loading = true;

            _.forEach(itensSelecionados, function (item) {
                _.forEach(vm.listPeriodos, function (columnPeriodo) {
                    var itemTurmaPeriodo = item[columnPeriodo.id];
                    if (itemTurmaPeriodo.possui && itemTurmaPeriodo.iniciado && itemTurmaPeriodo.$$aberto &&
                        !itemTurmaPeriodo.$$processando) {

                        itemTurmaPeriodo.$$processando = true;
                        itensEncerramento.push(createItemEncerramento(item, columnPeriodo));
                    }
                });
            });

            if (!_.isEmpty(itensEncerramento)) {
                EncerramentoService.encerrar(itensEncerramento).then(onSuccessEncerramento,
                    function () {
                        onErrorEncerramento(itensSelecionados, vm.listPeriodos);
                    });
            } else {
                Notification.publish('A(s) turma(s) selecionada(s) não possuem períodos abertos, iniciados ou existe um processo em andamento..', 'error');
            }
        }

        function movimentarPeriodos(itens, column) {
            var itensEncerramento = [];

            _.forEach(itens, function (item) {
                if (item[column.id].possui && item[column.id].$$aberto &&
                    item[column.id].iniciado && !item[column.id].$$processando) {

                    item[column.id].$$processando = true;
                    itensEncerramento.push(createItemEncerramento(item, column));
                }
            });

            if (!_.isEmpty(itensEncerramento)) {
                EncerramentoService.encerrar(itensEncerramento).then(onSuccessEncerramento,
                    function () {
                        onErrorEncerramento(itens, [column]);
                    });
            } else {
                _.forEach(itens, function (item) {
                    if (item[column.id].possui && !item[column.id].$$aberto &&
                        item[column.id].iniciado && !item[column.id].$$processando) {

                        item[column.id].$$processando = true;
                        itensEncerramento.push(createItemEncerramento(item, column));
                    }
                });
                if (!_.isEmpty(itensEncerramento)) {
                    EncerramentoService.reabrir(itensEncerramento)
                    .then(onSuccessEncerramento,
                        function () {
                            onErrorEncerramento(itens, [column]);
                        });
                }
            }

            if (_.isEmpty(itensEncerramento)) {
                Notification.publish('A(s) turma(s) não possuem períodos abertos, iniciados ou existe um processo em andamento.', 'error');
            }
        }

        function createItemEncerramento(item, column) {
            return _.extend(item[column.id], {
                turma: item.turma,
                periodoAvaliativo: item[column.id].periodoAvaliativo
            });
        }

        function onSuccessEncerramento() {
            var message = 'Processo de encerramento/reabertura em andamento. Ao término do processamento, você será notificado.';
            Notification.publish(message, 'info');
            vm.loading = false;
            buscarIndicadores();
        }

        function onErrorEncerramento(itens, columns) {
            _.forEach(itens, function (item) {
                _.forEach(columns, function (column) {
                    if (item[column.id].$$processando && !EncerramentoUtilsService.isStatusProcessando(item[column.id])) {
                        item[column.id].$$processando = false;
                    }
                });
            });
            vm.loading = false;
        }
    }
})();


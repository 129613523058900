(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('matriculaEja', matriculaEja);

    function matriculaEja() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/eja/matricula-eja.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                matricula: '=',
                enumTurno: '=',
                turnosPossiveis: '=',
                etapasMatrizFilhos: '=',
                formatResultTurma: '=',
                enumSituacao: '=',
                hasEncerramentos: '='
            }
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'educacao.matricula.MatriculaVagaService', 'educacao.matricula.MatriculaDisciplinaService', 'educacao.matricula.ConfiguracoesMatriculaService', 'TIPOS_MATRICULAS', 'ETAPAS_INEP_TURMAS_MULT', 'NIVEL_ESCOLAR'];

    function Controller($scope, promiseTracker, MatriculaVagaService, MatriculaDisciplinaService, ConfiguracoesMatriculaService, TIPOS_MATRICULAS, ETAPAS_INEP_TURMAS_MULT, NIVEL_ESCOLAR) {

        var vm = this;
        vm.isModular = isModular;
        vm.inserirLinha = inserirLinha;
        vm.removeLinha = removeLinha;
        vm.filhosOptions = {};
        vm.enumTurno = $scope.enumTurno;
        vm.enumSituacao = $scope.enumSituacao;
        vm.changeEtapaMatriz = changeEtapaMatriz;
        vm.changeEtapaMatrizFilhos = changeEtapaMatrizFilhos;
        vm.changeDisciplinaFilhos = changeDisciplinaFilhos;
        vm.changeTurnoFilhos = changeTurnoFilhos;
        vm.filterResultTurnos = filterResultTurnos;
        vm.showMultiturno = showMultiturno;
        vm.permiteMultiturno = false;
        vm.isTurnoIntegral = isTurnoIntegral;
        vm.podeRemoverFilho = podeRemoverFilho;
        vm.tiposMatriculas = TIPOS_MATRICULAS;
        vm.nivelEscolar = NIVEL_ESCOLAR;
        vm.isTurmaMultisseriada = isTurmaMultisseriada;
        vm.isNivelEscolarCompativel = isNivelEscolarCompativel;

        vm.tracker = {
            loading: promiseTracker(),
            loadingConfigMultiturno:  promiseTracker()
        };

        vm.edit = $scope.matricula.id;
        vm.isMultiturno = (vm.edit && $scope.matricula.turnoFrequenta);

        vm.tracker.loading.addPromise(
            MatriculaDisciplinaService.getDefaultRepresentation()
                .then(setOptionsFilhos)
        );

        vm.tracker.loading.addPromise(
            vm.getTipoTurmaUnificada = MatriculaVagaService.getTipoTurmaUnificada()
        );

        if (!vm.edit) {
            vm.tracker.loading.addPromise(
                MatriculaVagaService.getDefaultRepresentation().then(setDadosMatricula)
            );
        }

        function changeEtapaMatrizFilhos(newValue, oldValue, item) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            $scope.etapasMatrizFilhos = [];
            _.forEach($scope.matricula.filhos, function (filho) {
                if (_.get(filho.etapaMatriz, 'equivalenciasEnum')) {
                    $scope.etapasMatrizFilhos.push(filho);
                }
            });

            delete item.disciplina;
            delete item.turma;
            delete item.turno;
        }


        function changeDisciplinaFilhos(newValue, oldValue,item) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            delete item.turma;
            delete item.turno;
        }

        function changeTurnoFilhos(item) {
            delete item.turma;
        }

        function setDadosMatricula(data) {
            data.aluno = $scope.matricula.aluno;
            data.tipoMatricula = $scope.matricula.tipoMatricula;
            data.estabelecimento = $scope.matricula.estabelecimento;
            $scope.matricula = angular.extend($scope.matricula, data);
        }

        $scope.$watch('matricula.matriz', changeMatriz);
        $scope.$watch('matricula.etapaMatriz', changeEtapaMatriz);
        $scope.$watch('matricula.vaga', changeVaga);

        function changeMatriz(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            if (!_.get($scope.matricula.filhos, 'length' && !vm.edit)) {
                $scope.matricula.filhos = [];
            }

            if (isModular()) {
                inserirLinha();
            }

            delete $scope.matricula.turma;
            delete $scope.matricula.etapaMatriz;
            delete $scope.matricula.vaga;
        }


        function changeEtapaMatriz(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            delete $scope.matricula.vaga;
            delete $scope.matricula.turma;
        }

        function changeVaga(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            delete $scope.matricula.turma;
        }

        function setOptionsFilhos(data) {
            vm.filhosOptions = data;
        }

        function isModular() {
            return Boolean(_.get($scope.matricula, 'matriz.modular'));
        }

        function removeLinha($index) {
            if (!_.isNull($index) && !_.isUndefined($index) ){
                $scope.matricula.filhos.splice($index, 1);
            } else {
                $scope.matricula.filhos = [];
            }
        }

        function podeRemoverFilho(situacao) {
            if (!situacao) {
                return true;
            }
            return (situacao !== 'CURSANDO' && situacao !== 'EM_EXAME' && situacao !== 'MATRICULADO');
        }

        function inserirLinha() {
            $scope.matricula.filhos.push(angular.copy(vm.filhosOptions));
        }

        function filterResultTurnos(term, value) {
            if(!$scope.turnosPossiveis) {
                return value;
            }

            if(_.contains($scope.turnosPossiveis, value.key)) {
                return value;
            }
        }

        function showMultiturno() {
            if (!_.get($scope.matricula, 'matriz.id')) {
                return;
            }
            var params = {
                idEstabelecimento: $scope.matricula.estabelecimento.id,
                idMatriz: $scope.matricula.matriz.id
            };

            vm.tracker.loadingConfigMultiturno.addPromise(
                ConfiguracoesMatriculaService.permiteMultiturno(params)
                .then(permiteMultiturno)
            );

            function permiteMultiturno(data) {
                vm.permiteMultiturno = data.permite;
            }

        }

        function isTurnoIntegral() {
            return _.get(_.get($scope.matricula, 'vaga'), 'turno') === 'INTEGRAL';
        }
        
        function isTurmaMultisseriada(turma){
            return ETAPAS_INEP_TURMAS_MULT.CODIGO_INEP.indexOf(_.get(turma, 'etapaEnsinoInep.id')) !== -1;
        }

        function isNivelEscolarCompativel(nivelEscolar) {
            if (!$scope.matricula.matriz) {
                return false;
            }

            if (!vm.edit) {
                clearCamposItinerariosFormativos(nivelEscolar);
                return $scope.matricula.matriz.curso.nivelEscolar === nivelEscolar;   
            }

            if ($scope.matricula.matriz.modular) {
                return $scope.matricula.matriz.curso.nivelEscolar === nivelEscolar;
            }

            return $scope.matricula.vaga.matriz.curso.nivelEscolar === nivelEscolar;
        }

        function clearCamposItinerariosFormativos(nivelEscolar) {
            if ($scope.matricula.matriz.curso.nivelEscolar === nivelEscolar) {
                return;   
            }

            delete $scope.matricula.utilizaConcomitanteItinerarioFormativo;
            delete $scope.matricula.composicoesItinerariosFormativos;
            delete $scope.matricula.tipoCursoItinerarioFormativo;
            delete $scope.matricula.areasItinerariosFormativos;
        }
    }
})();

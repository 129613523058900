(function() {
    'use strict';

    angular.module('educacao.configuracaoFrequencia')
        .factory('educacao.common.ConfiguracaoFrequenciaService', ConfiguracaoFrequenciaService);

    ConfiguracaoFrequenciaService.$inject = [
        '$q',
        'EventEmitter',
        'Restangular',
        'MetadataCache'
    ];

    function ConfiguracaoFrequenciaService(
        $q,
        EventEmitter,
        Restangular,
        MetadataCache) {

        var self = new EventEmitter(),
            uri = [
                'EDUCACAO_BASICA_EDUCACAO_INFANTIL',
                'EDUCACAO_BASICA_ENSINO_FUNDAMENTAL',
                'EDUCACAO_BASICA_ENSINO_MEDIO',
                'AEE',
                'ATIVIDADE_COMPLEMENTAR',
                'ACELERACAO_ENSINO_FUNDAMENTAL',
                'ACELERACAO_ENSINO_MEDIO',
                'EJA_ENSINO_FUNDAMENTAL',
                'EJA_ENSINO_MEDIO',
                'COMPLEMENTAR'
            ],
            meta;

        self.remove = remove;
        self.getCategorias = getCategorias;
        self.save = save;
        self.getDefault = getDefault;
        self.getTypes = getTypes;
        self.getEnums = getEnums;
        self.getEnum = getEnum;

        return self;

        function getCategorias() {
            return uri;
        }

        function configuracao(config) {
            if (config) {
                return 'configuracoes-estabelecimentos';
            } else {
                return 'configuracoes-secretarias';
            }
        }

        function getMetadata(params) {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all('matricula/turma/frequencias/' + configuracao(params.estabelecimento) + '/tipos/' + params.tipoCategoria)
                    .options().then(function(data) {
                        meta = data;
                        deferred.resolve(meta);
                    }, function(data) {
                        deferred.reject(data);
                    });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        //TODO
        function getTypes(params) {
            return getMetadata(params).then(function(data) {
                return angular.copy(data.types);
            });
        }

        function getEnums(params) {
            var configuracaoMetadataCache = MetadataCache.create(getEndPoint(params));

            return configuracaoMetadataCache.getMetadataEnums();
        }

        function getEnum(params, enumName) {

            var configuracaoMetadataCache = MetadataCache.create(getEndPoint(params));

            return configuracaoMetadataCache.getEnum(enumName);
        }

        function getEndPoint(params) {
            return function() {
                return Restangular.all('matricula/turma/frequencias/' + configuracao(params.estabelecimento) +
                    '/tipos/' + params.tipoCategoria);
            };
        }

        function getDefault(params) {
            return Restangular.one('matricula/turma/frequencias/' + configuracao(params.estabelecimento) + '/tipos/' + params.tipoCategoria)
                .get();
        }

        function save(params) {
            return !params.configuracao.id ?
                Restangular.all('matricula/turma/frequencias/' + configuracao(params.estabelecimento) + '/tipos/' + params.tipoCategoria)
                .post(params.configuracao) :
                Restangular.all('matricula/turma/frequencias/' + configuracao(params.estabelecimento) + '/tipos/' + params.tipoCategoria)
                .customPUT(params.configuracao, params.configuracao.id);
        }

        function remove(idTurma, id) {
            return Restangular.one('matricula/turma', idTurma).one('frequencias/registros-faltas', id).remove();
        }
    }
})();


(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appCardTurmaItensEjaModular', appCardTurmaEjaModular);

	function appCardTurmaEjaModular() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/turma/app-card-turma/eja-modular/app-card-turma-itens-eja-modular.directive.html',
			scope: {
				turma: '=',
				listaAluno: '=',
				disciplina: '=',
				somenteAtivos: '='
			},
			controller: CardTurmaDirectiveController,
			controllerAs: 'vm'
		};
	}

	CardTurmaDirectiveController.$inject = ['$scope', 'promiseTracker', 'educacao.matricula.TurmaService',
		'educacao.matricula.EnturmacaoService',
		'educacao.common.ItemEducacionalService'];

	function CardTurmaDirectiveController($scope, $promiseTracker, TurmaService) {
		var NR_ELEMENTOS_POR_LINHA = 4;
		var vm = this;

		var propriedadesPublicas = {};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.disciplina = $scope.disciplina || '';
			vm.turma = $scope.turma || {};
			vm.enturmacoesCarregadas = angular.isArray(vm.turma.enturmacoes);
			vm.tabelaEnturmacoes = [];

			vm.tracker = {
				enturmacoes: $promiseTracker()
			};
		}

		function carregaEnturmacoes(somenteAtivos) {
			if (vm.enturmacoesCarregadas && somenteAtivos === undefined) {
				return;
			}

            var params = {
				filter: 'itemEducacional=' + vm.disciplina.id
			};
			
			params.filter += somenteAtivos === false ? '' : ' and dataFim is null';

			vm.tracker.enturmacoes.addPromise(
				TurmaService.getEnturmacoes(vm.turma.id, params).then(function(result) {
					vm.enturmacoes = result.content;
					setTabelaEnturmacoes(vm.enturmacoes, somenteAtivos);
					vm.enturmacoesCarregadas = true;
					vm.copyEnturmacoes = _.cloneDeep(vm.turma.enturmacoes);
				})
			);
		}

		function setTabelaEnturmacoes(enturmacoes, somenteAtivos) {
			if (!enturmacoes) {
				return;
			}

			vm.tabelaEnturmacoes = [];
			vm.tabelaEnturmacoesInativas = [];
			
			var temp = enturmacoes.concat();

            if (somenteAtivos === false){
                vm.tabelaEnturmacoesInativas = _.filter(enturmacoes, function(i){return i.dataFim;});
                temp = _.difference(temp, vm.tabelaEnturmacoesInativas);
                vm.tabelaEnturmacoesInativas = _.chunk(vm.tabelaEnturmacoesInativas, NR_ELEMENTOS_POR_LINHA);
            }

			if ($scope.canEditEnturmacoes) {
				var diffLength = vm.turma.quantidadeMaximaAlunos - temp.length;
				for (var i = 0; i < diffLength; i++) {
					temp.push({
						id: 'empty-' + i,
						empty: true
					});
				}
			} 

			vm.tabelaEnturmacoes = _.chunk(temp, NR_ELEMENTOS_POR_LINHA);
		}


		$scope.$watch('turma', function(value) {
			vm.turma = value || {};
		});

		$scope.$watch('disciplina', function(newValue, oldValue) {
			if (newValue !== oldValue) {
				vm.disciplina = newValue;
				vm.enturmacoesCarregadas = false;
			}
		});

		$scope.$watchGroup(['listaAluno', 'somenteAtivos'], function(values) {
			if (values[0]) {
				carregaEnturmacoes(values[1]);
			}
		});
	}
})();


(function() {
    'use strict';

    angular.module('app')
        .run(['HttpHeadersStore', function(HttpHeadersStore) {
            // Desabilita os links da API
            HttpHeadersStore.put('App-Links', 'false');
        }])
        .run(['rel.atalhos.execucao.ExecutandoService', '$state', '$rootScope',
            function(executandoService, $state, $rootScope) {

                var codigoF4 = 115;
                var codigoQ = 81;

                $('body').on('keyup', function(e) {
                    if (e.keyCode === codigoF4) {
                        e.preventDefault();
                        e.stopPropagation();
                        executandoService.abrirJanela();
                    }

                    if ((e.ctrlKey || e.metaKey) &&
                        e.keyCode === codigoQ) {
                        if (_.get($state, 'current.name') === 'app.matriz.matriz') {
                            $rootScope.$broadcast('configuracao::show-lote-matriz');
                        }
                        $rootScope.$broadcast('configuracao::carga-template');
                    }
                });
            }])
        .config(['ui.components.HttpErrorNotificationHandlerProvider', function(provider) {
            var ignoreHttpErrorCodes = [401, 403, 404, 503, 502];
            provider.ignoreHttpErrorCode(ignoreHttpErrorCodes);
        }])
        .config(['$httpProvider', 'LOADING_TIMEOUT', function($httpProvider, LOADING_TIMEOUT) {
            //CORS - Cross-Origin Resource Sharing
            $httpProvider.defaults.useXDomain = true;
            delete $httpProvider.defaults.headers.common['X-Requested-With'];

            //Max duration
            $httpProvider.defaults.timeout = LOADING_TIMEOUT;

            //Interceptor
            var bfcErrorNotificationHandler = 'bfc.messages.ErrorNotificationHandler';

            _.remove($httpProvider.interceptors, function(interceptorName) {
                return interceptorName === bfcErrorNotificationHandler;
            });

            $httpProvider.interceptors.unshift(
                //interceptor do user accounts não rejeita a promise quando http403 então... cá estamos...
                ['$q', function($q) {
                    return {
                        response: function(response) {
                            if (!response) {
                                return $q.reject(response);
                            }
                            return response.status === 403 ? $q.reject(response) : response;
                        }
                    };
                }]
            );
            $httpProvider.interceptors.push('$edErrorInterceptor');
            $httpProvider.interceptors.push('educacao.common.HttpRequestInterceptor');
            $httpProvider.interceptors.push('educacao.common.HttpRequestErrorInterceptor');
            $httpProvider.interceptors.push('educacao.common.MessagesInterceptor');
        }])

        .config(['RestangularProvider', 'API_URL', function(RestangularProvider, API_URL) {
            RestangularProvider.setBaseUrl(API_URL);

            RestangularProvider.setResponseExtractor(function(data, operation, what, url, response) {
                if (response.status === 201 && operation === 'post' && !data) {
                    var location = response.headers('Location');
                    if (location) {
                        var fragments = location.split('/');
                        var id = parseInt(_.last(fragments), 10);
                        data = {
                            id: id,
                            location: location
                        };
                    }
                } else if (operation === 'getList') {
                    var dataCopy = angular.copy(data);
                    if (!_.isArray(dataCopy)) {
                        var arrayData = [];
                        arrayData.push(dataCopy);
                        return arrayData;
                    }
                }
                return data;
            });

            RestangularProvider.addRequestInterceptor(function(element, operation) {
                if (operation === 'remove') {
                    return undefined;
                } else if (element) {
                    var elementCopy = angular.copy(element);
                    // Remover o id para não ocasionar Bad Request por meio de POST e PUT
                    delete elementCopy.id;

                    return elementCopy;
                }

                return element;
            });

            RestangularProvider.setRestangularFields({
                selfLink: 'links.self.href'
            });
        }])

        .config(function() {
            // define como padrão não fechar modal ao clicar no backdrop('mascara')
            bfc.Modal.defaults.backdrop = 'static';
        })

        .config(['uiGmapGoogleMapApiProvider', function(uiGmapGoogleMapApiProvider) {
            uiGmapGoogleMapApiProvider.configure({
                v: '3.20',
                key: 'AIzaSyAPNgMP4dDIj_L9u26MDCyy1OlOrFbliYY',
                language: 'pt-BR',
                libraries: 'places'
            });
        }])

        .config(['bfc.AppConfigProvider', function(bfcAppConfig) {
            bfcAppConfig.set({
                initialState: {
                    state: 'app',
                    alias: false
                }
            });
        }])

        .config(['SelectTurmaServiceProvider', function(SelectTurmaServiceProvider) {
            SelectTurmaServiceProvider.setPath('commons/turma');
        }])
        .config([
            'ed-components.ed-emissao-relatorio-config.serviceProvider',
            'APP_API_URL',
            function(serviceProvider, APP_API_URL) {
                serviceProvider.setPath(APP_API_URL + '/commons/integracao/');
            }
        ])
        .config([
            'ed-components.ed-execucao-script-config.serviceProvider',
            'APP_API_URL',
            function(serviceProvider, APP_API_URL) {
                serviceProvider.setPath(APP_API_URL + '/commons/integracao/');
            }
        ])
        .config(['SelectEstabelecimentoServiceProvider', function(SelectEstabelecimentoServiceProvider) {
            SelectEstabelecimentoServiceProvider.setPath('commons/estabelecimento/estabelecimentos');
        }])

        .config(['AcompanhamentoPedagogicoServiceProvider', function(AcompanhamentoPedagogicoServiceProvider) {
            AcompanhamentoPedagogicoServiceProvider.setPath('commons/acompanhamento-pedagogico');
            AcompanhamentoPedagogicoServiceProvider.setPathSalvarAcompanhamento('varios');
            AcompanhamentoPedagogicoServiceProvider.setPathEnturmacoesMatricula('enturmacaoTurma');
            AcompanhamentoPedagogicoServiceProvider.setPathListaEnturmacao('enturmacoes');
            AcompanhamentoPedagogicoServiceProvider.setPathTurma('commons/turma');
            AcompanhamentoPedagogicoServiceProvider.setPathItensTurma('filhos');
        }])
        .config(['bfc.PesquisaProvider', function (PesquisaProvider) {
            PesquisaProvider.setTheme('faces');
        }])
        .run(['$rootScope', '$log', function($rootScope, $log) {
            $rootScope.$on('$stateChangeError', function() {
                $log.error('$stateChangeError', arguments);
            });
            $rootScope.$on('$stateNotFound', function() {
                $log.error('$stateNotFound', arguments);
            });

        }])
    ;

})();

(function () {
	'use strict';
	angular.module('educacao.matricula')
		.controller('educacao.matricula.ModalComprovanteCtrl', Controller);

    Controller.$inject = ['$modalInstance'];

	function Controller($modalInstance) {
		var vm = this;

		vm.matricula = $modalInstance.params.matricula;
		vm.isVaga = $modalInstance.params.isVaga;
		vm.isEjaModular = $modalInstance.params.isEjaModular;
		vm.multiple = $modalInstance.params.multiple;
		
	}
})();

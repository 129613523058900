(function () {
    'use strict';
    angular.module('educacao.funcionario').directive('appTabFuncionarioFormacao', Directive);

    function Directive() {

        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-formacao/app-funcionario-formacao.directive.html',
            scope: {},
            bindToController: {
                funcionario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'ui.components.Modal',
        '$enumsCache'
    ];

    function Controller(uiModal,$enumsCache) {

        var vm = this;

        vm.removerLinha = removerLinha;
        vm.adicionarLinha = adicionarLinha;
        vm.openCadastroFormacao = openCadastroFormacao;     
        vm.SituacaoFormacao = [];
        vm.TipoInstituicaoInep = [];

        initController();

        function initController() {
            getEnums();
            if (!vm.funcionario.formacoes) {
                vm.funcionario.formacoes = [];
            }
            if(!vm.funcionario.cursosAperfeicoamento) {
                vm.funcionario.cursosAperfeicoamento = [];
            }
        }

        function getEnums(){
            $enumsCache.get('SituacaoFormacao').then(function (enumData) {
                vm.SituacaoFormacao = enumData;
                return enumData;
            });
            $enumsCache.get('TipoInstituicaoInep').then(function (enumData) {
                vm.TipoInstituicaoInep = enumData;
                return enumData;
            });

        }

        function removerLinha(list, index) {
            list.splice(index, 1);
        }

        function adicionarLinha(list) {
            list.push({});
        }

        function openCadastroFormacao(formacao, index) {
            vm.funcionario.formacoes = vm.funcionario.formacoes || [];
            uiModal.open({
                templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-formacao/cadastro-funcionario-formacao.html',
                controller: 'educacao.funcionario.CadastroFuncionarioFormacaoCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    formacao: formacao,
                    formacoes: vm.funcionario.formacoes,
                    indexItem: index
                }
            }).result.then(function (formacaoReturn) {
                if (!formacaoReturn) {
                    return;
                }

                adicionarFormacao(formacao, index, formacaoReturn);
            });
        }

        function adicionarFormacao(formacao, index, newFormacao) {

            if (formacao) {
                vm.funcionario.formacoes[index] = newFormacao;
            } else {
                vm.funcionario.formacoes.push(newFormacao);
            }
        }
    }
})();
(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.factory('educacao.matriz.MatrizService', ['Restangular', '$q',
        function(Restangular, $q) {
            var _meta = null;

            var self = this;

            self._meta = _meta;
            self.getMeta = getMeta;
            self.getMetaData = getMetaData;
            self.get = get;
            self.getList = getList;
            self.getDefault = getDefault;
            self.getTypes = getTypes;
            self.save = save;
            self.remove = remove;
            self.sequenciaNumerica = sequenciaNumerica;
            self.visualizacao = visualizacao;
            self.readOnly = readOnly;
            self.getDescricaoEtapa = getDescricaoEtapa;
            self.find = find;
            self.findTodos = findTodos;
            self.getEnums = getEnums;
            self.getPossuamTurmas = getPossuamTurmas;
            self.getFilterModular = getFilterModular;
            self.listByParams = listByParams;
            self.getNotificacoes = getNotificacoes;
            self.disciplina = disciplina;
            self.orquestrador = orquestrador;
            self.cargaTemplate = cargaTemplate;

            return self;

            function getMeta() {
                return self._meta;
            }

            function getMetaData() {
                var deferred = $q.defer();
                var meta = self.getMeta();
                if (!meta) {
                    Restangular.one('matriz').all('matriz-curricular').options().then(function(data) {
                        meta = data;
                        deferred.resolve(meta);
                    }, function(data) {
                        deferred.reject(data);
                    });
                } else {
                    deferred.resolve(meta);
                }
                return deferred.promise;
            }

            function get(id) {
                return Restangular.one('matriz').one('matriz-curricular', id).get();
            }

            function getList(offset, limit, filter, sort) {
                return Restangular.one('matriz').all('matriz-curricular').getList({
                    offset: offset,
                    limit: limit,
                    filter: filter,
                    sort: sort
                });
            }

            function getDefault() {
                return self.getMetaData().then(function(data) {
                    return angular.copy(data.representation.default);
                });
            }

            function getTypes() {
                return self.getMetaData().then(function(data) {
                    return angular.copy(data.types);
                });
            }

            function save(data) {
                var rest = Restangular.one('matriz').all('matriz-curricular');

                return !data.id ?
                    rest.post(data) :
                    rest.customPUT(data, data.id);
            }

            function remove(id) {
                return Restangular.one('matriz').one('matriz-curricular', id).remove();
            }

            function sequenciaNumerica(id) {
                return Restangular.one('matriz').one('matriz-curricular', id).all('identificacao-numerica').customPUT();
            }

            function visualizacao(id) {
                return Restangular.one('matriz').one('matriz-curricular', id).one('visualizacao').get();
            }

            function disciplina(idEtapa, idMatriz) {
                return Restangular.one('matriz').one('matriz-curricular', idMatriz).one('etapas', idEtapa).one('disciplinas').get();
            }

            function readOnly() {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }

            function getDescricaoEtapa(matriz, etapa) {
                var format = matriz.identificacaoEtapa.format;

                var organizacao = matriz.curso.organizacao;

                return format.replace('{0}', etapa.identificacao)
                    .replace('{1}', organizacao.descricao);
            }

            function find(offset, limit, filter, sort) {
                return Restangular.one('matriz').all('matriz-curricular').all('find').getList({
                    offset: offset,
                    limit: limit,
                    filter: filter,
                    sort: sort
                });
            }

            function findTodos(offset, limit, filter, sort) {
                return Restangular.one('matriz').all('matriz-curricular').all('todos').getList({
                    offset: offset,
                    limit: limit,
                    filter: filter,
                    sort: sort
                });
            }

            function getEnums() {
                return self.getMetaData().then(function(data) {
                    angular.forEach(data.enums, function(_enum, key) {
                        data.enums[key] = _enum.values;
                    });
                    return angular.copy(
                        data.enums
                    );
                });
            }

            function getPossuamTurmas(estabelecimentoId, tipoAtendimento, offset, limit, filter) {
                return Restangular.one('matriz').all('matriz-curricular').all('possuam-turmas').get('', {
                    estabelecimento: estabelecimentoId,
                    tipoAtendimento: tipoAtendimento,
                    offset: offset,
                    limit: limit,
                    filter: filter
                });
            }

            function getFilterModular() {
                return '(modular != true or modular is null)';
            }

            function listByParams(params) {
                return Restangular.one('matriz').all('matriz-curricular').get('listagem', params);
            }

            function getNotificacoes() {
                return Restangular.one('matriz').all('matriz-curricular').get('notifications');
            }

            function orquestrador(value) {
                return Restangular.all('orquestrador').post(value);
            }

            function cargaTemplate() {
                return Restangular.one('matriz').all('matriz-curricular').one('carga-template').post();
            }
        }
    ]);
})();

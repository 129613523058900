(function() {
    'use strict';
    /* jshint ignore:start */
    var element = document.getElementById('bodyEducacao');
    var month = new Date().getMonth();

    if (month === 8) {
        element.classList.add('bth-campaign', 'bth-campaign--september');
    }

    if (month === 9) {
        element.classList.add('bth-campaign', 'bth-campaign--october');
    }

    if (month === 10) {
        element.classList.add('bth-campaign', 'bth-campaign--november');
    }
    /* jshint ignore:end */
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.historico.HistoricoServiceProvider'];

    function HistoricoConfig(HistoricoServiceProvider) {
        HistoricoServiceProvider.addTipoHandler('MATRICULA_ALTERACAO', {
            badge: {
                icon: 'fa-pencil',
                tooltip: 'Edição da matrícula'
            },
            campos: {
                tipo: {
                    insercao: {
                        template: '<small>Aluno matriculado em <strong>{{valor}}</strong></small><br>'
                    }
                },
                disciplina: {
                    insercao: {
                        template: '<small>Disciplina: <strong>{{valor}}</strong></small><br>'
                    }
                },
                situacao: {
                    alteracao: {
                        template: '<small>Situação alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Situação foi removida</small><br>'
                    }
                },
                etapaEnsinoInep: {
                    insercao: {
                        template: '<small>Etapa de ensino do Inep foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Etapa de ensino do Inep alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Etapa de ensino do Inep foi removido</small><br>'
                    }
                },
                data: {
                    alteracao: {
                        template: '<small>Data alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Data foi removida</small><br>'
                    }
                },
                espacoDiferenciado: {
                    insercao: {
                        template: '<small>Escolarização em espaço diferenciado foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Escolarização em espaço diferenciado alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Escolarização em espaço diferenciado foi removido</small><br>'
                    }
                },
                utilizarTransporteEscolar: {
                    insercao: {
                        template: '<small>Utiliza transporte escolar foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Utiliza transporte escolar alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Utiliza transporte escolar foi removido</small><br>'
                    }
                },
                responsavelTransporteEscolar: {
                    insercao: {
                        template: '<small>Responsável pelo transporte escolar foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Responsável pelo transporte escolar alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Responsável pelo transporte escolar foi removido</small><br>'
                    }
                },
                avaliacaoDescritiva: {
                    insercao: {
                        template: '<small>A avaliação do aluno deve ser descritiva foi informada: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>A avaliação do aluno deve ser descritiva alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>A avaliação do aluno deve ser descritiva foi removida</small><br>'
                    }
                },
                transferenciaOrigem: {
                    insercao: {
                        template: '<small>Transferência origem foi informada: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Transferência origem alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Transferência origem foi removida</small><br>'
                    }
                },
                estabelecimentoOrigem: {
                    insercao: {
                        template: '<small>Estabelecimento de ensino de origem foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Estabelecimento de ensino de origem alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Estabelecimento de ensino de origem foi removido</small><br>'
                    }
                },
                nomeEstabelecimentoOrigem: {
                    insercao: {
                        template: '<small>Estabelecimento de ensino de origem foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Estabelecimento de ensino de origem alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Estabelecimento de ensino de origem foi removido</small><br>'
                    }
                },
                documentos: {
                    insercao: {
                        template: '<small>Documento(s) para a matrícula foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Documento(s) para a matrícula foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Documento(s) para a matrícula  foi removido</small><br>'
                    }
                },
                veiculos: {
                    insercao: {
                        template: '<small>Veículo(s) utilizado(s) foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Veículo(s) utilizado(s) foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Veículo(s) utilizado(s)  foi removido</small><br>'
                    }
                },
                solicitacaoRematricula: {
                    insercao: {
                        template: '<small>Solicitação de rematrícula foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Solicitação de rematrícula foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Solicitação de rematrícula foi removido</small><br>'
                    }
                },
                turmaUnificada: {
                    insercao: {
                        template: '<small>Turma unificada foi informada: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Turma unificada foi alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Turma unificada foi removida</small><br>'
                    }
                },
                formaIngresso: {
                    insercao: {
                        template: '<small>Forma de ingresso foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Forma de ingresso foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Forma de ingresso foi removido</small><br>'
                    }
                },
                situacaoUltimaMatricula: {
                    insercao: {
                        template: '<small>Situação da última matrícula foi informada: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Situação da última matrícula foi alterada de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Situação da última matrícula foi removida</small><br>'
                    }
                },
                origemMatricula: {
                    insercao: {
                        template: '<small>Origem foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Origem foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Origem foi removido</small><br>'
                    }
                },
                // tipoReclassificacao: {
                //     insercao: {
                //         template: '<small>Tipo foi informado: <strong>{{valor}}</strong></small><br>'
                //     },
                //     alteracao: {
                //         template: '<small>Tipo foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                //     },
                //     remocao: {
                //         template: '<small>Tipo foi removido</small><br>'
                //     }
                // },
                tipoClassificacao: {
                    insercao: {
                        template: '<small>Tipo foi informado: <strong>{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Tipo foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Tipo foi removido</small><br>'
                    }
                },
                observacao: {
                    insercao: {
                        template: '<small>Observação foi informada: <strong title="{{valor}}">{{valor}}</strong></small><br>'
                    },
                    alteracao: {
                        template: '<small>Observação foi alterada para: <strong title="{{valor}}">{{valor}}</strong></small><br>'
                    },
                    remocao: {
                        template: '<small>Observação foi removida</small><br>'
                    }
                },
                turnoFrequenta: {
                    alteracao: {
                        template: '<small>Turno que frequenta foi alterado de <strong>{{valorAnterior}}</strong> para: <strong>{{valor}}</strong></small><br>'
                    }
                }
            }
        });
    }
})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaClassificacaoTableCriterios', appConfiguracaoListaEsperaClassificacaoTableCriterios);

    function appConfiguracaoListaEsperaClassificacaoTableCriterios() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-classificacao/app-configuracao-lista-espera-classificacao-utiliza-criterios/app-configuracao-lista-espera-classificacao-table-criterios/app-configuracao-lista-espera-classificacao-table-criterios.directive.html',
            scope: {},
            bindToController: {
                configuracao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.criterio-lista-espera.CriterioListaEsperaService'
    ];

    function Controller(CriterioListaEsperaService) {
        var vm = this;

        vm.configuracao.criterios = _.get(vm.configuracao, 'criterios') || [];
        vm.addListCriterios = addListCriterios;
        vm.searchCriterios = searchCriterios;
        vm.formatCriterio = formatCriterio;
        vm.remove = remove;
        vm.desmarcaUsuarioExternoSeNecessario = desmarcaUsuarioExternoSeNecessario;

        init();

        function init() {
            if (!_.size(vm.configuracao.criterios)) {
                addListCriterios();
            }
        }

        function searchCriterios(params) {
            return CriterioListaEsperaService.listByParams(params.term);
        }

        function formatCriterio(data) {
            return data.descricao;
        }

        function remove(item) {
            _.remove(vm.configuracao.criterios, item);
        }

        function addListCriterios() {
            vm.configuracao.criterios.push(
                {
                    pontuacao: 0,
                    somenteAdministrador: false,
                    usuarioExterno: false
                }
            );
        }

        function desmarcaUsuarioExternoSeNecessario(item){
            if(item.somenteAdministrador){
                item.usuarioExterno = false;
            }    
        }

    }

})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.EnturmacaoCadAvisosCtrl', EnturmacaoCadAvisosCtrl);

	EnturmacaoCadAvisosCtrl.$inject = ['$modalInstance', '$injector', 'promiseTracker'];

	function EnturmacaoCadAvisosCtrl($modalInstance, $injector, promiseTracker) {
		var vm = this;

		var EnturmacaoProfessorService = $injector.get('educacao.matricula.EnturmacaoProfessorService');

		vm.enturmacao = $modalInstance.params.enturmacao;
		vm.sim = sim;
		vm.close = close;

		initTracker();

		function initTracker() {
			vm.tracker = {
				sim: promiseTracker()
			};
		}

		function sim() {
			var promise = EnturmacaoProfessorService.removeProfessoGradeHorario(vm.enturmacao)
				.then(close);

			vm.tracker.sim.addPromise(promise);
		}

		function close(){
			$modalInstance.close();
		}

	}
})();

(function() {
    'use strict';
    angular.module('educacao.funcionario').directive('funcionarioDadosPessoais', FuncionarioDadosPessoais);

    function FuncionarioDadosPessoais() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/app-funcionario-dados-pessoais.directive.html',
            scope: {},
            bindToController: {
                funcionario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.funcionario.FuncionarioService',
        'educacao.enderecos.BairroService',
        'educacao.enderecos.EstadoService',
        'educacao.enderecos.MunicipioService'
    ];

    function Controller(FuncionarioService, BairroService, EstadoService, MunicipioService) {
        var vm = this;
        vm.enumRaca = FuncionarioService.getRaca();
        vm.enumGrauInstrucao = FuncionarioService.getGrauInstrucao();
        vm.bairroService = BairroService;
        vm.estadoService = EstadoService;
        vm.municipioService = MunicipioService;
    }

})();


(function() {
    'use strict';

    angular.module('educacao.context')
        .controller('educacao.context.SelecaoEntidadeController', SelecaoEntidadeController);

    SelecaoEntidadeController.$inject = ['$state', 'promiseTracker', 'educacao.context.ContextoEntidade', '$window', 'URL_LIBERACAO_ENTIDADE'];

    function SelecaoEntidadeController($state, promiseTracker, ContextoEntidade, $window, URL_LIBERACAO_ENTIDADE) {
        var vm = this;

        vm.loadingTracker = promiseTracker();

        vm.alterarEntidadeAtual = alterarEntidadeAtual;

        vm.loadingTracker.addPromise(
            ContextoEntidade.listaEntidades()
            .then(function(entidades) {
                vm.entidades = entidades;
                if (entidades.length === 1) {
                    alterarEntidadeAtual(_.first(entidades));
                }
                if(entidades.length <= 0){
                    $window.location.replace(URL_LIBERACAO_ENTIDADE);
                }
            })
        );

        function alterarEntidadeAtual(entidade) {
            ContextoEntidade.setEntidade(entidade);
            $state.go('selecao');
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('educacao.diarioclasse')
        .controller('educacao.diarioClasse.DiarioController', DiarioController);

    DiarioController.$inject = [
        '$filter',
        '$document',
        '$q',
        'bfc.PromiseTracker',
        'educacao.diarioClasse.DiarioService',
        'educacao.matricula.TurmaService',
        'bfc.MessagesField',
        'ui.components.Modal',
        '$rootScope',
        'bfc.Notification',
        'educacao.entidade.EntidadeService',
        '$scope',
        'promiseTracker'
    ];

    function DiarioController(
        $filter,
        $document,
        $q,
        PromiseTracker,
        DiarioService,
        TurmaService,
        MessagesField,
        $modal,
        $rootScope,
        bfcNotification,
        EntidadeService,
        $scope,
        promiseTracker
    ) {

        var vm = this,
            offset = 0,
            diarioOld,
            queuePromise;

        vm.columns = [
            {
                id: 'nomeAluno',
                label: 'Aluno(a)',
                model: 'nome',
                filterable: true
            }];
        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaLoadings();
        }

        function inicializaPropriedades() {
            vm.diario = {};
            vm.diarioClasse = {};
            vm.filtered = [];
            vm.indexTurma = 0;
            vm.predicate = 'nomeAluno';
            vm.reverse = false;
            vm.openOneTime = false;
            vm.navegaEntreTurma = navegaEntreTurma;
            vm.getPeriodoAvaliativo = getPeriodoAvaliativo;
            vm.selecionaPeriodo = selecionaPeriodo;
            vm.setFaltaAluno = queueSetFaltaAluno;
            vm.searchRegistros = searchRegistros;
            vm.order = order;
            vm.truncar = truncar;
            vm.navegaEntrePeriodo = navegaEntrePeriodo;
            vm.getDeficiencias = getDeficiencias;
            vm.openModalAbonoJustificativa = openModalAbonoJustificativa;
            vm.addAcompanhamento = addAcompanhamento;
            vm.openImpressaoDiario = openImpressaoDiario;
            vm.searchTurmas = searchTurmas;
            vm.atualizaTotaisEnturmacao = atualizaTotaisEnturmacao;
            vm.tracker = promiseTracker();
            vm.situacaoMatricula = DiarioService.getSituacaoMatricula();
        }

        $scope.$watch('vm.diarioClasse.turma', changeTurma);

        function inicializaLoadings() {
            vm.loadings = PromiseTracker.create(['diarioPeriodos', 'salvar']);
        }

        function searchRegistros() {
            if (_.isEmpty(vm.registros)) {
                vm.filtered = [];
                return;
            }
            var defer = $q.defer();
            vm.tracker.addPromise(defer.promise);

            if (_.isEmpty(_.get(vm.searchFilter, 'keywords')) && _.isEmpty(_.get(vm.searchFilter, 'others.situacoes'))) {
                vm.filtered = vm.registros;
                defer.resolve();
                return;
            }

            vm.filtered = [];
            var keywords = _.get(vm.searchFilter, 'keywords');
            var situacoes = _.get(vm.searchFilter, 'others.situacoes');
            var criterion = _.get(vm.searchFilter, 'criterion');

            _.forEach(vm.registros, function(registro) {
                var existsKeyword;
                var listKeys = [];
                if (criterion === 'none') {
                    existsKeyword = _.find(keywords, function(keyword) {
                        return !(registro.nome.toLowerCase().contains(keyword.toLowerCase()));
                    });
                }
                if (criterion === 'some') {
                    existsKeyword = _.find(keywords, function(keyword) {
                        return (registro.nome.toLowerCase().contains(keyword.toLowerCase()));
                    });
                }
                if (criterion === 'all') {
                    _.forEach(keywords, function(keyword) {
                        if (registro.nome.toLowerCase().contains(keyword.toLowerCase())) {
                            listKeys.push(keyword);
                        }
                    });
                    existsKeyword = listKeys.length === keywords.length ? listKeys.toString() : null;
                }

                var existsSituacao = _.find(situacoes, function(situacao) {
                    return (registro.situacao === situacao);
                });

                if ((!_.isEmpty(keywords) ? existsKeyword : true) && (!_.isEmpty(situacoes) ? existsSituacao : true)) {
                    vm.filtered.push(registro);
                }
            });
            defer.resolve();
        }

        function getPeriodoAvaliativo() {
            if (vm.diario.configuracao.periodosAvaliativos.length <= 0) {
                return;
            }
            var periodo = _.find(vm.diario.configuracao.periodosAvaliativos, {
                'id': vm.diarioClasse.periodo ? vm.diarioClasse.periodo.id : vm.diario.configuracao.periodoAvaliativoCorrente.id
            });
            var format = 'dd/MM/yyyy';
            if (!periodo) {
                return;
            }
            return periodo.descricao + ' (' + $filter('date')(periodo.dataInicial, format) + ' até ' + $filter('date')(periodo.dataFinal,
                format) + ')';
        }

        function getDeficiencias(data) {
            return data.join(', ');
        }

        function selecionaTurma(idPeriodo) {
            var promise;
            if (vm.diarioClasse.turma) {
                //guarda a turma selecionada pra mandar para o acompanhamento
                vm.turma = _.clone(vm.diarioClasse.turma);

                promise = TurmaService.getAlunosTurma(vm.diarioClasse.turma.id, idPeriodo);
                promise.then(function(data) {
                    vm.diario = data;
                    vm.registros = _.get(vm.diario, 'registros');
                    vm.estabelecimento = vm.diarioClasse.turma.estabelecimento;
                    diarioOld = angular.copy(data);
                    vm.columns = _.union(vm.diario.itemEducacional, vm.columns);
                    vm.diarioClasse.periodo = vm.diario.configuracao.periodoAvaliativoCorrente;
                    searchRegistros();
                }, function() {
                    vm.diarioClasse.turma = undefined;
                });
                vm.loadings.diarioPeriodos.addPromise(promise);
            }
            vm.openOneTime = false;
        }

        function changeTurma(oldValue, newValue) {
            if (angular.equals(oldValue, newValue)) {
                return;
            }
            selecionaTurma();
        }

        function selecionaPeriodo() {
            var idPeriodo = vm.diarioClasse.periodo ? vm.diarioClasse.periodo.id : null;
            selecionaTurma(idPeriodo);
        }

        function setRetornoAlunos(falta, retorno, index) {
            var diarioExist = _.find(vm.diario.registros, function(obj) {
                return parseInt(obj.id) === falta.enturmacao.id;
            });

            //Atualiza o diario com a ultima quantidade persistida no banco
            var diarioOldExist = _.find(diarioOld.registros, function(obj) {
                return parseInt(obj.id) === falta.enturmacao.id;
            });

            diarioOldExist.faltas[index] = retorno.faltas;

            if (angular.isDefined(diarioExist)) {
                angular.copy(retorno.frequencia, diarioExist.frequencia);
                angular.copy(retorno.faltas, falta);
            }

            vm.openOneTime = true;
        }

        function returnLastQuantidadeFalta(falta, index) {
            var diarioExist = _.find(diarioOld.registros, function(obj) {
                return parseInt(obj.id) === falta.enturmacao.id;
            });
            return diarioExist.faltas[index].quantidadeFaltas;
        }

        function queueSetFaltaAluno(falta, index, nomeCampo) {
            if (vm.loadings.salvar.tracking()) {
                queuePromise.then(function() {
                    queueSetFaltaAluno(falta, index, nomeCampo);
                });
            } else {
                queuePromise = setFaltaAluno(falta, index, nomeCampo);
                vm.loadings.salvar.addPromise(queuePromise);
            }
        }

        function setFaltaAluno(falta, index, nomeCampo) {
            var promise,
                faltaCopia = angular.copy(falta);
            faltaCopia.quantidadeFaltas = +faltaCopia.quantidadeFaltas;
            if (faltaCopia.quantidadeFaltas === null || !_.isNumber(faltaCopia.quantidadeFaltas) || (angular.equals(parseInt(falta.quantidadeFaltas),
                    returnLastQuantidadeFalta(faltaCopia, index)))) {
                return $q.when();
            }
            if (falta.quantidadeFaltas === '') {
                faltaCopia.quantidadeFaltas = null;
                if (faltaCopia.id === null) {
                    return $q.when();
                }
                promise = DiarioService.remove(vm.diario.id, faltaCopia.id);
                promise.then(function(data) {
                    setRetornoAlunos(falta, data, index);
                    MessagesField.removeMessage($('#' + vm.Diario[nomeCampo].$name).removeClass('field-error'));
                }, function(error) {
                    falta.quantidadeFaltas = returnLastQuantidadeFalta(faltaCopia, index);
                    MessagesField.setMessage($('#' + vm.Diario[nomeCampo].$name).removeClass('field-error'), null, error.data.detail[
                        Object.keys(error.data.detail)[0]]);
                });
            } else {
                promise = DiarioService.saveFaltas(vm.diario.id, faltaCopia);
                promise.then(function(faltas) {
                    setRetornoAlunos(falta, faltas, index);
                    MessagesField.removeMessage($('#' + vm.Diario[nomeCampo].$name).removeClass('field-error'));
                }, function(error) {
                    falta.quantidadeFaltas = returnLastQuantidadeFalta(faltaCopia, index);
                    MessagesField.setMessage($('#' + vm.Diario[nomeCampo].$name).removeClass('field-error'), null, error.data.detail[
                        Object.keys(error.data.detail)[0]]);
                });
            }
            return promise;
        }

        function truncar(numero, casasDecimais) {
            return numero < 100 ? $filter('number')(new BigNumber(numero).toFixed(casasDecimais, 1), casasDecimais) : numero;
        }

        function navegaEntrePeriodo(sentido) {
            var periodo = _.findIndex(vm.diario.configuracao.periodosAvaliativos, {
                    'id': vm.diarioClasse.periodo.id
                }),
                idPeriodo,
                periodoSelecionado,
                indexPeriodo;

            if (sentido === 'proximo') {
                indexPeriodo = periodo + 1;
                if (indexPeriodo === vm.diario.configuracao.periodosAvaliativos.length) {
                    indexPeriodo = 0;
                }
            } else if (sentido === 'anterior') {
                if (periodo === 0) {
                    indexPeriodo = vm.diario.configuracao.periodosAvaliativos.length - 1;
                } else {
                    indexPeriodo = periodo - 1;
                }
            }

            idPeriodo = vm.diario.configuracao.periodosAvaliativos[indexPeriodo].id;
            periodoSelecionado = vm.diario.configuracao.periodosAvaliativos[indexPeriodo];

            selecionaTurma(idPeriodo);
            vm.diarioClasse.periodo = periodoSelecionado;
            vm.openOneTime = false;
        }

        function navegaEntreTurma(sentido) {
            if (sentido === 'proximo') {
                if (vm.hasNext) {
                    offset = !vm.diario ? 0 : offset + 1;
                } else {
                    offset = 0;
                }
            } else if (sentido === 'anterior') {
                offset = !vm.diario ? 0 : offset - 1;
            }

            if (offset < 0) {
                return;
            }

            var promise = TurmaService.getList({
                offset: offset,
                limit: 1
            });
            promise.then(function(data) {
                vm.hasNext = data[0].hasNext;
                vm.diario = {};
                vm.diarioClasse.turma = angular.copy(data[0].content[0]);
            }).then(function() {
                selecionaTurma();
            });
            vm.loadings.diarioPeriodos.addPromise(promise);
        }

        function order(predicate) {
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.predicate = predicate;
        }

        function searchTurmas(params) {
            return TurmaService.getListFilter('descricao like "' + params.term + '"', params.offset, params.limit)
                .then(function(data) {
                    return data;
                });
        }

        function atualizaTotaisEnturmacao(params) {
            var promise = DiarioService.getTotaisEnturmacao(params.idTurma, params.idEnturmacao);
            promise.then(function(faltas) {
                angular.extend(params.aluno.frequencia, faltas[0]);
            });
        }

        function openModalAbonoJustificativa(aluno) {
            $modal.open({
                templateUrl: 'abono-justificativa/abono-justificativa-list.html',
                controller: 'educacao.abonojustificativa.AbonoJustificativaListCtrl',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    configuracoes: {
                        aluno: aluno,
                        itensEducacionais: vm.diario.itensEducacionais,
                        configuracao: vm.diario.configuracao,
                        turma: vm.turma,
                        callbackTotais: atualizaTotaisEnturmacao
                    }
                }
            });
        }

        function addAcompanhamento(aluno) {
            var acompanhamento = {
                estabelecimento: vm.estabelecimento,
                itemEducacional: vm.itemEducacional,
                enturmacao: {
                    id: aluno.idEnturmacao,
                    nomeAluno: aluno.nome
                },
                matricula: {
                    id: aluno.idMatricula
                },
                turma: vm.turma
            };
            $modal.open({
                templateUrl: 'acompanhamento-pedagogico-frequencia.html',
                controller: 'acompanhamentoPedagogico.AcompanhamentoPedagogicoFreqCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    acompanhamento: angular.copy(acompanhamento)
                }
            });
        }

        function openImpressaoDiario() {
            if (!vm.diario.configuracao.periodoAvaliativoCorrente && vm.diario.tipoItemEducacional !== 'ATIVIDADE' && vm.diario.tipoItemEducacional !==
                'PERIODO_AVALIATIVO') {
                bfcNotification.publish(
                    'Não foi possível determinar o período para impressão da ficha de frequência. Verifique o calendário escolar da matriz.',
                    'error');
                return;
            }

            $modal.open({
                templateUrl: 'impressao-diario-classe-desempenho/impressao-diario-classe-desempenho.html',
                controller: 'educacao.diarioclasse.ImpressaoDiario',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    diario: vm.diario,
                    estabelecimento: vm.estabelecimento,
                    turma: vm.turma,
                    predicate: vm.predicate,
                    reverse: vm.reverse,
                    entidade: EntidadeService.get(''),
                    tituloImpressao: 'Frequência',
                    tituloFicha: 'frequência',
                    tituloColuna: 'Total de faltas'
                }
            });
        }
    }
})();


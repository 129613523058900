(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appBooleanValue', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<span>{{vm.booleanDescription}}</span>',
            scope: {},
            bindToController: {
                booleanValue: '=',
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = ['$scope'];

    function Controller() {
        var vm = this;
        if(vm.booleanValue===true){
            vm.booleanDescription = 'Sim';
        }else{
            vm.booleanDescription = 'Não';
        }
    }

})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.TipoDocumentoService', TipoDocumentoService);

    TipoDocumentoService.$inject = ['$injector'];

    function TipoDocumentoService($injector) {
        var ServiceBuilder = $injector.get('ServiceBuilder');


        var selfService = ServiceBuilder.create()
            .path('matricula', 'tipo-documento')
            .build();
        return selfService;
    }
})();

(function() {
    'use strict';
    angular.module('educacao.common').directive('edSituacao', function() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'common/directives/templates/situacao-cadastral.html';
        config.scope = {};
        config.scope.situacao = '=';
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;

    });
    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;
        vm.situacao = $scope.situacao;
    }

})();

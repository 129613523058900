(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncionarioMatriculaMovimentacoesService', FuncionarioMatriculaMovimentacoesService);

    FuncionarioMatriculaMovimentacoesService.$inject = ['ServiceBuilder', 'Restangular','EventEmitter','$rootScope','bfc.Notification'];

    function FuncionarioMatriculaMovimentacoesService(ServiceBuilder, restangular,EventEmitter,$rootScope,Notification) {

        var self = new EventEmitter();
        self.saveMovimentacao = saveMovimentacao;
        self.desfazMovimentacao = desfazMovimentacao;
        self.atualizaMovimentacao = atualizaMovimentacao;
        self.getMovimentacao = getMovimentacao;
        self.retornaAfastamento = retornaAfastamento;
        self.remove = remove;
        return self;

        function saveMovimentacao(movimentacao){
            return restangular.one('funcionario/matriculas/movimentacoes/movimentar').customPOST(movimentacao);
        }

        function desfazMovimentacao(funcionarioId){
            return restangular.one('funcionario/matriculas/movimentacoes/desfazer/'+funcionarioId).customPUT();
        }

        function retornaAfastamento(movimentacaoId,movimentacao){
            return restangular.one('funcionario/matriculas/movimentacoes/retornar/'+movimentacaoId).customPUT(movimentacao);            
        }

        function getMovimentacao(movimentacaoId){
            return restangular.one('funcionario/matriculas/movimentacoes/'+movimentacaoId).get();
        }

        function atualizaMovimentacao(movimentacaoId,movimentacao){
            return restangular.one('funcionario/matriculas/movimentacoes/'+movimentacaoId).customPUT(movimentacao);
        }

        function remove(id) {
            return restangular.one('funcionario/matriculas/movimentacoes/'+id).remove().then(function(){
                $rootScope.$broadcast('refreshDetalhesFuncionario', {
                    atualiza: true
                });
                Notification.publish('Movimentação excluída com sucesso', 'success');
            }, function(){
                Notification.publish('Movimentação já foi excluída', 'error');
            });
        }

    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.AcompanhamentoPedagogicoCtrl', Controller);    

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento'
    ];

    function Controller(
        ContextoEstabelecimento
    ) {
        var vm = this;

        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.RemoveTurmaCascadeService', Service);

    Service.$inject = ['Restangular'];


    function Service(Restangular) {
        var endpoint = Restangular.all('matricula/turma/vinculos');

        return {
            remove: remove
        };
        
        function remove(id) {
            return endpoint.customDELETE(id);
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('configuracaoInscricaoCampos', configuracaoInscricaoCampos);

    configuracaoInscricaoCampos.$inject = [];

    function configuracaoInscricaoCampos() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/configuracao-inscricao/campos/configuracao-inscricao-campos.directive.html',

            scope: {
                configuracao: '=',
                validateTab: '=',
                tabSelected: '=',
                dateTimeProcess: '=',
                isUploading: '='
            },

            controller: ConfiguracaoInscricaoCamposCtrl,
            controllerAs: 'vm'
        };
    }

    ConfiguracaoInscricaoCamposCtrl.$inject = [
        '$scope',
        'joinTime',
        'promiseTracker',
        'bfc.dialog.Dialog',
        'educacao.matricula.ConfiguracaoInscricaoService',
        'educacao.matricula.CamposInscricaoService'
    ];

    function ConfiguracaoInscricaoCamposCtrl($scope, joinTime, promiseTracker, bfcDialog, ConfiguracaoInscricaoService, CamposInscricaoService) {
        var vm = this;
        vm.options = {};

        joinTime
            .create()
            .scope($scope)
            .expr1('dateTimeProcess.dataInicial')
            .format1('YYYY-MM-DD')
            .expr2('dateTimeProcess.horaInicial')
            .format2('HH:mm')
            .destExpr('vm.configuracao.dataHoraInicio')
            .destFormat('YYYY-MM-DDTHH:mm:ss.SSS')
            .build();

        joinTime
            .create()
            .scope($scope)
            .expr1('dateTimeProcess.dataFinal')
            .format1('YYYY-MM-DD')
            .expr2('dateTimeProcess.horaFinal')
            .format2('HH:mm')
            .destExpr('vm.configuracao.dataHoraFim')
            .destFormat('YYYY-MM-DDTHH:mm:ss.SSS')
            .build();

        vm.loading = promiseTracker();
        vm.configuracao = $scope.configuracao;

        vm.validateTab = $scope.validateTab;
        vm.tabSelected = $scope.tabSelected;
        vm.openViewForm = openViewForm;

        init();

        function init() {
            vm.loading.addPromise(
                CamposInscricaoService.getList().then(function(data) {
                    vm.options.camposInscricao = data;
                }),

                ConfiguracaoInscricaoService.getDefaultRepresentation().then(function(data) {
                    if (!vm.configuracao) {
                        vm.configuracao = data;
                    }
                    vm.camposFixos = _.clone(data.campos);
                })
            );
        }

        function openViewForm() {
            bfcDialog.open({
                templateUrl: 'matricula/configuracao-inscricao/campos/view-form.modal.html',
                controller: 'inscricao.ViewFormCtrl',
                controllerAs: 'vm',
                resolve: {
                    configuracao: vm.configuracao
                }
            });
        }

        $scope.$watch('dateTimeProcess', function() {
            vm.validateTab(vm.tabSelected);
        }, true);
    }
})
();

(function() {
    'use strict';

    angular.module('educacao.common')
        .filter('edMaskFone', function() {
            return function(fone) {
                if (angular.isNumber(fone)) {
                    throw new Error('Este filtro aceita apenas STRING.');
                }

                if (!fone) {
                    return null;
                } else if (fone.length === 10) {
                    return fone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
                } else {
                    return fone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
                }

            };
        });

})();

(function() {
    'use strict';

    angular.module('educacao.historico-escolar')
        .controller('educacao.historicoEscolar.NotaDescritivaCtrl', NotaDescritivaController);

    NotaDescritivaController.$inject = [
        '$modalInstance'
        ];

    function NotaDescritivaController($modalInstance) {
        var vm = this;

        vm.notaDescritiva = $modalInstance.params.notaDescritiva || '';
        vm.nomeAluno = $modalInstance.params.nomeAluno || '';

        vm.isEditando = Boolean(vm.notaDescritiva);

        vm.salvar = salvar;

        function salvar() {
            $modalInstance.close(vm.notaDescritiva);
        }

    }
})();


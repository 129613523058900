(function() {
	'use strict';

	var SITUACAO_FINAL_OUTRA = 'OUTRA';

	angular.module('educacao.historico-escolar')
		.directive('appHistoricoEjaModular', HistoricoEjaModular);

	function HistoricoEjaModular() {

		return {
			templateUrl: 'historico-escolar/eja-modular/app-historico-eja-modular.directive.html',
			restrict: 'E',
			bindToController: {
				value: '=?',
				situacaoHistorico: '=?',
				situacaoMatricula: '=?',
				aluno: '=?'
			},
			scope: {
				editItem: '='
			},
			controller: Controller,
			controllerAs: 'vm'
		};

	}

	Controller.$inject = ['$injector'];

	function Controller($injector) {
		var vm = this;

		var HistoricoEscolarService = $injector.get('educacao.historicoEscolar.HistoricoEscolarService');
		var promiseTracker = $injector.get('promiseTracker');
		var $enumsCache = $injector.get('$enumsCache');
		var Notification = $injector.get('bfc.Notification');

		var propriedadesPublicas = {
			saveSituacao: _saveSituacao,
			getDescricaoSituacao: _getDescricaoSituacao,
			getDescricaoEnumSituacao: _getDescricaoEnumSituacao,
			onChangeSituacaoFinalModular: _onChangeSituacaoFinalModular
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.situacaoFinalAluno = HistoricoEscolarService.getSituacaoHistorico();

			vm.situacaoFinalAluno.then(function(data) {
				vm.enumsSituacoes = data;
			});

			iniTracker();
			getEnumsEquivalenciaEtapa().then(getSituacoes);
		}

		function iniTracker() {
			vm.tracker = {
				situacao: promiseTracker(),
				saveSituacao: promiseTracker()
			};
		}

		function getEnumsEquivalenciaEtapa() {
			return $enumsCache.get('EquivalenciaEtapa')
				.then(function(enumData) {
					vm.enumsEquivalenciaEtapa = enumData;
				});
		}

		function getNewListaEjaModular() {
			vm.equivalencias = [];
			_.forEach(vm.value, function(value) {
				_.forEach(value.componentes, function(componente) {
					_.forEach(value.equivalencias, function(eq) {
						var equivalencia = _.find(vm.equivalencias, 'key', eq);

						if (equivalencia) {
							equivalencia.historicos.push({
								value: value,
								componente: componente
							});
						} else {
							var newEquivalencia = {
								historicos: [{
									value: value,
									componente: componente
							}],
								key: eq
							};

							var situacao = _.find(vm.situacoes, {
								equivalencia: getKeyEnumEquivalenciaEtapa(eq),
								nivelEscolar: value.nivel.key
							});
							_.extend(newEquivalencia, situacao);

							vm.equivalencias.push(newEquivalencia);

						}

					});
				});
			});
		}

		function _saveSituacao(equivalencia) {
			var registro = {
				id: equivalencia.id || null,
				aluno: _.pick(vm.aluno, 'id'),
				equivalencia: getKeyEnumEquivalenciaEtapa(equivalencia.key),
				situacao: equivalencia.situacao || null,
				situacaoEspecifica: equivalencia.situacaoEspecifica || null,
				nivelEscolar: equivalencia.historicos[0].value.nivel.key
			};

			vm.tracker.saveSituacao.addPromise(
				HistoricoEscolarService.saveSituacao(registro).then(function(data) {
					Notification.publish('Situação salva com sucesso', 'success');
					equivalencia.id = data.id;
					bfc.Popover.closeAll();
				})
			);
		}

		function _getDescricaoEnumSituacao(equivalencia) {
			if (!equivalencia.situacao) {
				return 'Não informado';
			}
			return _getDescricaoSituacao(equivalencia);
		}

		function getKeyEnumEquivalenciaEtapa(descricao) {
			var objEnum = _.find(vm.enumsEquivalenciaEtapa, 'descricao', descricao);
			return objEnum.key;
		}

		function getSituacoes() {
			vm.tracker.situacao.addPromise(HistoricoEscolarService.getSituacao({
				filter: 'aluno.id=' + vm.aluno.id
			}).then(function(data) {
				vm.situacoes = data;
				getNewListaEjaModular();
			}));
		}

		function _getDescricaoSituacao(componente) {
			var situacao = _.find(vm.situacaoHistorico.values, 'id', componente.situacao);
			if (situacao) {
				if (situacao.id === SITUACAO_FINAL_OUTRA) {
					return componente.situacaoEspecifica;
				} else {
					return situacao.description;
				}
			}

			situacao = _.find(vm.situacaoMatricula.values, 'id', componente.situacao);
			if (situacao) {
				return situacao.description;
			}
		}

		function _onChangeSituacaoFinalModular(equivalencia) {
			equivalencia.situacaoEspecifica = null;
		}
	}
})();


(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appCardTurmaEjaModular', appCardTurmaEjaModular);

	function appCardTurmaEjaModular() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/turma/app-card-turma/eja-modular/app-card-turma-eja-modular.directive.html',
			scope: {
				turma: '=',
				listaAluno: '=',
				disciplina: '=',
				somenteAtivos: '='
			},
			controller: CardTurmaDirectiveController,
			controllerAs: 'vm'
		};
	}

	CardTurmaDirectiveController.$inject = ['$q', '$scope', 'promiseTracker', 'educacao.matricula.TurmaService'];

	function CardTurmaDirectiveController($q, $scope, $promiseTracker, TurmaService) {

		var vm = this;

		var propriedadesPublicas = {
			listaDiciplinas: _listaDiciplinas,
			formatResult: _formatResult
		};

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			vm.turma = $scope.turma || {};
			$scope.somenteAtivos = true;
			iniciaTracker();
			iniciaDiciplinas();

			function iniciaTracker() {
				vm.tracker = {
					diciplica: $promiseTracker()
				};
			}

			function iniciaDiciplinas() {
				vm.tracker.diciplica.addPromise(
					TurmaService.getDiciplinas(vm.turma.id)
					.then(function(dadosDiciplina) {
						vm.diciplinas = dadosDiciplina;
					})
				);
			}
		}

		function _listaDiciplinas() {
			return $q.when({
				hasNext: false,
				content: vm.diciplinas
			});
		}

		function _formatResult(data) {
			if (_.get(data.etapaMatrizDisciplina, 'disciplina')) {
				return data.etapaMatrizDisciplina.disciplina.descricao;
			}
			return data.etapaMatrizDisciplina.eixoTematico.descricao;
		}

		$scope.$watch('turma', function(value) {
			vm.turma = value || {};
		});

		$scope.$watch('vm.disciplina', function(value) {
			$scope.listaAluno = false;
			if (value) {
				$scope.disciplina = value;
			}
		});
	}
})();


(function() {
    'use strict';
    /*
    Directive recebe três parametros:
    ng-model
    ng-required
    tipo-cha - COMPETENCIA, ATITUDE, etc
    list -> lista contendo os chas que não devem ser retornados na lista
    dropdownCssClass -> class aplicada ao input quando a div de seleção está aberta
    ng-change -> função chamada quando o model é alterado
    */
    angular.module('educacao.funcionario').directive('appSelectCha', SelectCHA);

    function SelectCHA() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" search="vm.search" add-value="vm.openAdd" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                tipoCha: '@',
                list: '=',
                ngChange: '&?',
                dropdownCssClass: '=?',
                disableTipo: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.matriz.chaService', '$scope'];

    function Controller($modal, PermissionsService, chaService, $scope) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.openAdd = !PermissionsService.isRevokedOperation('ChaPageMapping', 'criar') && openAdd;

        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.get($scope.ngModel, 'tipo') !== $scope.tipoCha) {
                $scope.ngModel = null;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function search(params) {
            var chasIds = [];
            if ($scope.list) {
                $scope.list.forEach(function(cha) {
                    if (cha) {
                        chasIds.push(cha.id);
                    }
                });
            }

            var filter = '(descricao like "' + params.term + '" or identificador like "' + params.term + '")';

            if ($scope.tipoCha) {
                filter += ' and tipo="' + $scope.tipoCha + '"';
            }

            if (chasIds.length) {
                filter += ' and id not in(' + chasIds.join() + ')';
            }

            return chaService.list(filter, params.offset, params.limit);
        }

        function openAdd(descricao) {
            return $modal.open({
                templateUrl: 'cadastro-cha/cha-cad.html',
                controller: 'educacao.matriz.ChaCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    cha: {
                        descricao: descricao,
                        tipo: $scope.tipoCha
                    },
                    canSaveAdd: false,
                    disableTipo: $scope.disableTipo
                }
            }).result;
        }

        function formatResult(data) {
            if (!data.identificador) {
                return data.descricao;
            }

            return data.identificador + ' - ' + data.descricao;
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.desempenhoescolar')
        .config(states);

    states.$inject = ['$stateProvider'];

    function states($stateProvider) {
        $stateProvider
            .state('app.desempenhoescolar', {
                data: {
                    id: 'RegistroAvaliacaoPageMapping'
                },
                url: 'desempenho-escolar',
                views: {
                    '@': {
                        templateUrl: 'desempenho-escolar/desempenho-escolar.html',
                        controller: 'educacao.desempenhoescolar.RegistroAvaliacoesController as vm'
                    }
                }
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('educacao.pessoas')
        .directive('appFiltroAvancadoEscolaInfraestrutura', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'planejamento/escolas/filtro-avancado/escola/infraestrutura/infraestrutura.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorInfraestrutura: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.changeContadorInfraestrutura = changeContadorInfraestrutura;

        $scope.$watchCollection(function() { 
            if(vm.advancedFilter.infraestrutura) {
                return Object.values(vm.advancedFilter.infraestrutura);
            }
        }, changeContadorInfraestrutura);
    
        init();
    
        function init() {
            vm.contadorInfraestrutura = 0;

            changeContadorInfraestrutura();
        }
    
        function changeContadorInfraestrutura() {
            if (vm.advancedFilter && vm.advancedFilter.infraestrutura) {
                vm.contadorInfraestrutura = getFilterLength();
            }
        }
    
        function getFilterLength() {
            return Object.values(vm.advancedFilter.infraestrutura).filter(function (filtroAvancadoSelecionado) {
                return filtroAvancadoSelecionado === false || !!filtroAvancadoSelecionado && filtroAvancadoSelecionado.length !== 0;
            }).length;
        }
    }    
})();

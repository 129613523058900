(function() {

    'use strict';

    angular.module('educacao.lista-espera')
        .directive('appListaEsperaItem', appListaEsperaItem);

    function appListaEsperaItem() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera/app-lista-espera-item/app-lista-espera-item.directive.html',
            scope: {},
            bindToController: {
                item: '=',
                tipoItem: '=', // Inscritos, matriculados ou cancelados
                cancelFunction: '=',
                undoFunction: '=',
                filter: '=',
                matricularFunction: '=',
                removeFunction: '=',
                removeModalidadeFunction : '=',
                refresh:'=',
                editFunction: '&'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'bfc.dialog.Dialog',
        '$listaEsperaItemService',
        'promiseTracker',
        'educacao.lista-espera.ListaEsperaService',
        'bfc.Notification'
    ];

    function Controller(bfcDialog, $listaEsperaItemService, promiseTracker, ListaEsperaService,bfcNotification) {
        var vm = this;

        vm.onClickCancelar = onClickCancelar;
        vm.onClickDesfazerCancelamento = onClickDesfazerCancelamento;
        vm.onClickEditar = onClickEditar;
        vm.onClickImprimir = onClickImprimir;
        vm.onClickExcluir = onClickExcluir;
        vm.onClickMotivo = onClickMotivo;

        vm.isInscrito = $listaEsperaItemService.isInscrito;
        vm.isCancelado = $listaEsperaItemService.isCancelado;
        vm.editarOrdemClassificacao = editarOrdemClassificacao;
        vm.openAluno = openAluno;
    
        vm.itemTracker = promiseTracker();
        function editarOrdemClassificacao(inscricao){
            if(!_.get(inscricao, 'ordemClassificacao')){
                bfcNotification.publish(
                    'A ordem de classificação precisa ser informada',
                    'error');
                    return;
            }
            ListaEsperaService.editarOrdemClassificacao(inscricao);
        }
        function openAluno() {
            vm.item.$$opened = !vm.item.$$opened;

            if (vm.item.$$detailsLoaded) {
                return;
            }

            var filter = buildFilterPadrao();
            if (vm.filter) {
                filter += ' and (' + vm.filter + ')';
            }

            var promise = ListaEsperaService.getDetails({
                    filter: filter
                })
                .then(onLoadDetails);
            vm.itemTracker.addPromise(promise);

            vm.item.$$detailsLoaded = true;
        }

        function onLoadDetails(data) {
            vm.item.$$details = _.chain(data)
                .groupBy('modalidade')
                .value();
        }

        function onClickImprimir($event) {
            $event.stopPropagation();
            bfcDialog.open({
                templateUrl: 'lista-espera/confirmacao/confirmacao-inscricao.html',
                controller: 'educacao.lista-espera.ConfirmacaoInscricaoController',
                controllerAs: 'vm',
                resolve: {
                    configuracao: vm.configuracao,
                    id: vm.item.listaEspera.id
                }
            });
        }

        function onClickEditar($event) {
            $event.stopPropagation();
            if (_.isFunction(vm.editFunction)) {
                vm.editFunction({
                    itemId: vm.item.listaEspera.id
                });
            }
        }

        function onClickExcluir($event) {
            $event.stopPropagation();
            if (_.isFunction(vm.removeFunction)) {
                vm.removeFunction(vm.item.listaEspera.id);
            }
        }

        function onClickCancelar($event) {
            $event.stopPropagation();
            if (_.isFunction(vm.cancelFunction)) {
                vm.cancelFunction(vm.item.listaEspera.id);
            }
        }

        function onClickDesfazerCancelamento($event) {
            $event.stopPropagation();
            if (_.isFunction(vm.undoFunction)) {
                vm.undoFunction(vm.item.listaEspera.id);
            }
        }

        function onClickMotivo($event) {
            $event.stopPropagation();
        }

        function buildFilterPadrao() {
            return '(inscricao.id = ' + vm.item.listaEspera.id + ' and (statusEstabelecimento = \'' + vm.tipoItem + '\'))';
        }
    }

})();

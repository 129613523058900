(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.CampoExperienciasCadCtrl', CampoExperienciasCadCtrl);

	CampoExperienciasCadCtrl.$inject = ['bfc.Notification', 'educacao.matriz.CampoExperienciaService', '$modalInstance'];

	function CampoExperienciasCadCtrl(Notification, CampoExperienciaService, $modalInstance) {
		var vm = this;

		vm.remove = remove;
		vm.save = save;

		init();

		function init() {
			vm.campoExperiencia = $modalInstance.params.campoExperiencia || {};
			vm.editando = _.get(vm.campoExperiencia, 'id');
			vm.canSaveAdd = $modalInstance.params.canSaveAdd;

			vm.permissions = {
				save: {
					bfPermissions: 'app.matriz.campoexperiencia',
					operation: 'criar'
				},
				update: {
					bfPermissions: 'app.matriz.campoexperiencia',
					operation: 'editar'
				},
				remove: {
					bfPermissions: 'app.matriz.campoexperiencia',
					operation: 'excluir'
				}
			};
		}

		function save(continuar) {
			return CampoExperienciaService.save(vm.campoExperiencia).then(function(data) {
				Notification.publish('Campo de experiência salvo com sucesso', 'success');
				if (continuar) {
					vm.campoExperiencia = {};
					vm.form.$setUntouched();
				} else {
					$modalInstance.close(data);
				}
			});
		}

		function remove() {
			return CampoExperienciaService.remove(vm.campoExperiencia).then(function() {
				$modalInstance.close();
			});
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.configuracaoGrupo').factory('educacao.common.GrupoEnturmacaoService', GrupoEnturmacaoService);



    GrupoEnturmacaoService.$inject = ['Restangular'];

    function GrupoEnturmacaoService(Restangular) {

        var base = 'grupo-enturmacao';

        return {
            getAll: getAll,
            get: get,
            getGruposEstabelecimento: getGruposEstabelecimento,
            remove: remove,
            save: save,
            saveList: saveList,
            verificarVinculo: verificarVinculo,
            saveAndRemoveVinculos: saveAndRemoveVinculos,
            removeAndRemoveVinculos: removeAndRemoveVinculos
        };

        function getAll(params) {
            return Restangular.all(base).get('', params);
        }

        function get(id) {
            return Restangular.one(base, id).get();
        }

        function getGruposEstabelecimento(id) {
            return Restangular.all(base).one('estabelecimento', id).get();
        }

        function remove(id) {
            return Restangular.one(base)
                .customDELETE(id);
        }

        function save(entity) {
            return entity.id ? Restangular.one(base, entity.id).customPUT(entity) :
                Restangular.one(base)
                    .customPOST(entity);
        }
        
        function saveAndRemoveVinculos(entity) {
            return Restangular.one(base, entity.id).one('remove-vinculos').customPUT(entity);
        }

        function saveList(entities) {
            return Restangular.all(base).one('criar-grupos-estabelecimentos').customPOST(entities);
        }

        function verificarVinculo(grupos) {
            var params = {
                grupoId: grupos
            };
            return Restangular.all(base).get('vinculo-frequencia-remota', params);
        }

        function removeAndRemoveVinculos(id) {
            return Restangular.one(base, id).one('remove-vinculos')
                .customDELETE();
        }
    }
})();

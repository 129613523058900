(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoDataChamadaCtrl', ClassificacaoDataChamadaCtrl);

    ClassificacaoDataChamadaCtrl.$inject = ['chamada','processo','$modalInstance','educacao.matricula.ClassificacaoService','promiseTracker','$rootScope', 'onClose'];

    function ClassificacaoDataChamadaCtrl(chamada, processo, $modalInstance, ClassificacaoService, promiseTracker, $rootScope, onClose) {
  
        var vm = this;
        vm.tracker = {};
        vm.tracker.save = promiseTracker();
        vm.chamada = chamada;

        vm.save = save;

        init();

        function init() {
          if (vm.form) {
              vm.form.$setPristine();
          }
        }

        function save() {
            vm.tracker.save.addPromise(
                ClassificacaoService.updateChamada(processo.id,vm.chamada)
                    .then(function(data){
	                    $rootScope.$broadcast('refresh::classificacoes', {
	                        data: data,
	                        updateChamada: true
	                    });
                    })
                    .then(onClose)
                    .then(close)
            );    
        }

        function close() {
            $modalInstance.close();
        }        


    }


})();

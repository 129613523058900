(function() {

    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.FilterEncodedUtilsService', FilterEncodedUtilsService);

    function FilterEncodedUtilsService() {
        var self = this;

        self.getDecodedTerm = getDecodedTerm;

        function getDecodedTerm(term) {
            term = decodeURI(term);
            return term.replace(/%/g, ' ').trim();
        }
    }

})();
(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('appGridVagaMatriz', GridVagaMatriz);

	function GridVagaMatriz() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/quadro-vagas-matriculas/grid/grid-vaga-matriz-template.html',
			scope: {
				estabelecimento: '=',
				estabControls: '='
			},
			bindToController: {
				condensed: '=',
				colunas: '=',
				colunasVisiveis: '='
			},
			controller: GridVagaMatrizController,
			controllerAs: 'vm'
		};

	}

	GridVagaMatrizController.$inject = ['$scope', 'educacao.matricula.QuadroVagasService'];

	function GridVagaMatrizController($scope, QuadroVagasService) {

		var vm = this;

		vm.estabelecimento = $scope.estabelecimento;
		vm.QuadroVagasService = QuadroVagasService;
		vm.openModalEdicaoMatriz = openModalEdicaoMatriz;
		vm.removerVagasMatriz = removerVagasMatriz;

		$scope.$watchCollection('vm.colunasVisiveis', ajusteColunasVisiveis);
		$scope.$watchCollection('vm.matrizControls', watchMatrizControls);

		function watchMatrizControls(value) {
			if (!value) {
				return;
			}
			ajusteColunasVisiveis(vm.colunasVisiveis);
		}

		vm.param = {
			idEstabelecimento: vm.estabelecimento.id
		};

		function openModalEdicaoMatriz(matriz) {

			var param = {
				filter: '(estabelecimento= ' + vm.estabelecimento.id + ' and matriz=' + matriz.id + ')',
				limit: 999
			};

			QuadroVagasService.openModalEdicao(param, 'matriz')
				.result.then(function() {
					vm.matrizControls.search();
				});

		}

		function removerVagasMatriz(matriz) {

			var params = {
				idEstabelecimento: vm.estabelecimento.id,
				idMatriz: matriz.id
			};

			QuadroVagasService.removePorMatriz(params)
				.then(search);

		}

		function search() {
			$scope.estabControls.search();
		}

		function ajusteColunasVisiveis(values) {
			if (!vm.matrizControls) {
				return;
			}
			_.forEach(vm.colunas, function(coluna) {
				vm.matrizControls.setColumnVisible(coluna, _.includes(values, coluna));
			});
		}

	}
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appMultiturnoInfoIcon', MultiturnoInfoIcon);

    function MultiturnoInfoIcon() {
        return {
            restrict: 'E',
            template: '<i class="fa fa-sun-o tx__orange" aria-hidden="true" ng-style="vm.style" bf-tooltip="{{vm.title}}"></i>',
            scope: {
                matricula: '=',
                saldoAtual: '=',
            },
            controller: MultiturnoInfoIconController,
            controllerAs: 'vm'
        };

    }

    MultiturnoInfoIconController.$inject = ['$scope'];

    function MultiturnoInfoIconController($scope) {

        var vm = this;
        vm.title =
            'O valor é maior que o saldo inicial, pois este estabelecimento permite a divisão de vagas de etapa(s) de ensino oferecida(s) no turno Integral.';

        if($scope.saldoAtual){
            vm.title = 'O valor é menor que zero, pois este estabelecimento permite a divisão de vagas de etapa(s) de ensino oferecida(s) no turno Integral.';
        }

        if ($scope.matricula) {
            vm.title = 'Aluno matriculado em turma de turno integral dividido';
        }

    }
})();

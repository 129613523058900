(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectTipoAvaliacao', SelectTipoAvaliacaoDirective);

    function SelectTipoAvaliacaoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-change="ngChange()" search="vm.search"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngChange: '&?'
            },
            controller: SelectTipoAvaliacaoDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectTipoAvaliacaoDirectiveController.$inject = ['$scope', 'educacao.matricula.TipoAvaliacaoService'];
    function SelectTipoAvaliacaoDirectiveController($scope, service) {

        var vm = this;

        vm.search = search;

        function search(params) {
            var filter = '(descricao like "' + params.term + '") and situacaoCadastral = "ATIVO"';
            return service.list(filter);
        }

    }
})();

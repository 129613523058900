(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.CargoCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        'educacao.funcionario.CargoService'
    ];

    function Controller(
        $scope,
        promiseTracker,
        $modalInstance,
        focus,
        CargoService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.cargo = $modalInstance.params.cargo || {};

        var modo = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.cargo.id
        };
        vm.save = save;
        vm.cancel = fechaModal;
        vm.modo = modo;
        vm.remove = remove;
        vm.filterResult = filterResult;

        init();

        function init() {
            verificaFormulario();
            vm.cargo.leciona = modo.editando ? vm.cargo.leciona : null;
            vm.windowTitle = modo.editando ? 'Editando cargo' : 'Adicionando cargo';
            focus('lbDescricaoCargo');
        }

        function save(continuar) {
            vm.tracker.loading.addPromise(
                CargoService.save(vm.cargo).then(
                    function(data) {
                        vm.cargo = data;
                        onSuccess(continuar);
                    }
                )
            );
        }

        function remove() {
            vm.tracker.loading.addPromise(
                CargoService.remove(vm.cargo).then(function() {
                    fechaModal();
                })
            );
        }

        function onSuccess(continuar) {
            if (continuar) {
                vm.cargo = {
                    leciona: null
                };
                focus('lbDescricaoCargo');
            } else {
                $modalInstance.close(vm.cargo);
            }
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function filterResult(term, value) {
            if (value.key.toUpperCase().contains(term.toUpperCase().trim())) {
                return value;
            }
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();


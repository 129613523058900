(function() {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.EixoTematicoSelect', EixoTematicoSelect);

    EixoTematicoSelect.$inject = [
        'educacao.common.ModalCad',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.EixoTematicoService',
        'bfc.Select2Config',
        'bfc.$$PermissionsService'
    ];

    function EixoTematicoSelect(
        ModalCad,
        ObjectUtilsService,
        EixoTematicoService,
        Select2Config,
        PermissionsService
    ) {

        var onAdd = PermissionsService.isRevokedOperation('EixoTematicoPageMapping', 'criar') ? undefined : add;

        return {
            select2Config: function(options) {
                return Select2Config.create(angular.extend({
                    onAdd: onAdd,
                    result: function(params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);
                        
                        var result,
                            filter = '(descricao like "' + term + '")';

                        result = EixoTematicoService.getList(params.offset, params.limit, filter);

                        result.then(function(data) {
                            callback({
                                results: data,
                                more: false
                            });
                        });

                        return result;
                    },
                    formatValue: function(data) {
                        return data;
                    },
                    formatResult: function(data) {
                        return data.text ?
                            data.text :
                            data.descricao;
                    },
                    formatSelection: function(data) {
                        return data.descricao;
                    },
                    initSelection: function(element, callback) {
                        var id = $(element).val();

                        if (id) {
                            EixoTematicoService.get(id).then(callback, function() {
                                callback({
                                    id: null
                                });
                            });
                        }
                    }
                }, options));
            }
        };

        function add(val, target) {
            ModalCad.open({
                templateUrl: 'planejamento/matriz/eixo-tematico/eixo-tematico.cad.html',
                controller: 'educacao.matriz.EixoTematicoCadCtrl as vm',
                optionsCad: {
                    'descricao': val
                },
                selectElem: target
            });
            return false;
        }
    }
})();

(function () {
    'use strict';
    angular.module('educacao.funcionario').directive('appTabFuncionarioFormacaoPosGraduacao', Directive);

    function Directive() {

        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-formacao/pos-graduacao/app-funcionario-formacao-pos-graduacao.directive.html',
            scope: {},
            bindToController: {
                funcionario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'ui.components.Modal',
        '$enumsCache'
    ];

    function Controller(uiModal, $enumsCache) {

        var vm = this;

        vm.SituacaoFormacaoPosGraduacao = [];
        vm.TipoFormacaoPosGraduacao = [];

        vm.openCadastroFormacaoPosGraduacao = openCadastroFormacaoPosGraduacao;
        vm.removerLinha = removerLinha;
        vm.formatTipoFormacaoPosGraduacao = formatTipoFormacaoPosGraduacao;
        vm.formatCargaHoraria = formatCargaHoraria;
        
        initController();

        function initController() {
            getEnums();
            if (!vm.funcionario.formacoesPosGraduacoes) {
                vm.funcionario.formacoesPosGraduacoes = [];
            }
            if (!vm.funcionario.cursosAperfeicoamento) {
                vm.funcionario.cursosAperfeicoamento = [];
            }
        }

        function getEnums() {
            $enumsCache.get('SituacaoFormacaoPosGraduacao').then(function (enumData) { vm.SituacaoFormacaoPosGraduacao = enumData; });
            $enumsCache.get('TipoFormacaoPosGraduacao').then(function (enumData) { vm.TipoFormacaoPosGraduacao = enumData; });

        }

        function openCadastroFormacaoPosGraduacao(formacao, index) {
            vm.funcionario.formacoesPosGraduacoes = vm.funcionario.formacoesPosGraduacoes || [];
            uiModal.open({
                templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-formacao/pos-graduacao/cadastro-pos-graduacao/cadastro-funcionario-formacao-pos-graduacao.html',
                controller: 'educacao.funcionario.CadastroFuncionarioFormacaoPosGraduacaoCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    formacao: formacao,
                    formacoes: vm.funcionario.formacoesPosGraduacoes,
                    indexItem: index
                }
            }).result.then(function (formacaoReturn) {
                if (!formacaoReturn) {
                    return;
                }

                adicionarFormacao(formacao, index, formacaoReturn);
            });
        }

        function adicionarFormacao(formacao, index, newFormacao) {

            if (formacao) {
                vm.funcionario.formacoesPosGraduacoes[index] = newFormacao;
            } else {
                vm.funcionario.formacoesPosGraduacoes.push(newFormacao);
            }
        }

        function removerLinha(list, index) {
            list.splice(index, 1);
        }

        function formatTipoFormacaoPosGraduacao(tipoFormacaoPosGraduacao) {
            return vm.TipoFormacaoPosGraduacao.values.length ? vm.TipoFormacaoPosGraduacao[tipoFormacaoPosGraduacao].description : '';
        }

        function isNotEmpty(n) { 
            return n !== undefined && n !== null && n !== 0 && n !== '' && n !== '0';
        }

        function formatCargaHoraria(formacao){
            var cargaHoraria = '-';
            
            if(isNotEmpty(formacao.cargaHorariaHora)){
                cargaHoraria = formacao.cargaHorariaHora + 'h';
            }

            if(isNotEmpty(formacao.cargaHorariaHora) && isNotEmpty(formacao.cargaHorariaMinuto)){
                cargaHoraria = formacao.cargaHorariaHora + 'h ' + formacao.cargaHorariaMinuto + 'm';
            }

            if(!isNotEmpty(formacao.cargaHorariaHora) && isNotEmpty(formacao.cargaHorariaMinuto)){
                cargaHoraria = formacao.cargaHorariaMinuto + 'm';
            }

            return cargaHoraria;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .controller('educacao.gestao-pessoas-quadro-informativo.GestaoPessoasQuadroInformativoController', Controller);

    Controller.$inject = [
        '$scope',
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        '$stateParams',
        '$rootScope'
    ];

    function Controller(
        $scope,
        ContextoAnoLetivo,
        ContextoEstabelecimento,
        $stateParams,
        $rootScope
    ) {
        var vm = this;

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.nomeEstabelecimento = ContextoEstabelecimento.getEstabelecimento().descricao;

        vm.estabelecimentoSelected = $stateParams.estabelecimentoSelected ? $stateParams.estabelecimentoSelected : null;
        vm.anoLetivoSelected = $stateParams.anoLetivoSelected ? $stateParams.anoLetivoSelected : ContextoAnoLetivo.getAnoLetivo();

        vm.SCREEN_NAME = 'Gestão de pessoas';
        vm.TAB_TYPES = {
            VISAO_GERAL: 'VISAO_GERAL',
            AULAS: 'AULAS',
            PROFESSORES: 'PROFESSORES',
            PROFESSORES_SUBSTITUTOS: 'PROFESSORES_SUBSTITUTOS',
            OUTROS_FUNCIONARIOS: 'OUTROS_FUNCIONARIOS'
        };
        vm.CURRENT_TAB = vm.TAB_TYPES.VISAO_GERAL;

        $scope.$watch('vm.anoLetivoSelected', onChangeContextAnoLetivo);
        $rootScope.$on('app-context:anoletivo:new', clearAnoLetivo);

        vm.tabs = [
            {
                label: 'RESUMO GERAL',
                tipo: vm.TAB_TYPES.VISAO_GERAL,
                index: 1,
                hiddenBadge: true
            },
            {
                label: 'AULAS',
                tipo: vm.TAB_TYPES.AULAS,
                index: 2,
                hiddenBadge: true
            },
            {
                label: 'PROFESSORES',
                tipo: vm.TAB_TYPES.PROFESSORES,
                index: 3,
                hiddenBadge: true
            },
            {
                label: 'PROFESSORES SUBSTITUTOS',
                tipo: vm.TAB_TYPES.PROFESSORES_SUBSTITUTOS,
                index: 4,
                hiddenBadge: true
            },
            {
                label: 'OUTROS FUNCIONÁRIOS',
                tipo: vm.TAB_TYPES.OUTROS_FUNCIONARIOS,
                index: 5,
                hiddenBadge: true
            }
        ];

        function clearAnoLetivo() {
            vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            $stateParams.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
        }

        function onChangeContextAnoLetivo(newValue) {
            if (newValue === null) {
                vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.historico.HistoricoServiceProvider'];

    function HistoricoConfig(HistoricoServiceProvider) {

        HistoricoServiceProvider.addTipoHandler('GRADE_HORARIOS', {
            badge: {
                icon: 'fa-clock-o',
                tooltip: 'Alteração agendada'
            },
            template: '<small>Ativação <strong>{{vigenciaInicio.valor|bfDate}}</strong><small>'
        });
    }
})();
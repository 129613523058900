(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('stepOrdenacao', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: {
                inscricao: '=',
            },

            templateUrl: 'matricula/inscricao/step-ordenacao/step-ordenacao.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', '$enumsCache'];

    function Controller($scope, $enumsCache) {
        var vm = this;

        vm.estabelecimentos = $scope.inscricao.estabelecimentos;
        vm.utilizaZoneamento = $scope.inscricao.configuracao.utilizaZoneamento;
        vm.confirmOrder = confirmOrder;
        vm.stepBack = stepBack;

        vm.sortableOptionsObj = {
            true: {
                stop: reordenarList,
                axis: 'y',
                containment: '#ui-containment-true'
            },
            false: {
                stop: reordenarList,
                axis: 'y',
                containment: '#ui-containment-false'
            }   
        };

        vm.sortableOptionsSemZoneamento = {
            stop: reordenarList,
            axis: 'y',
            containment: '#ui-containment-sem-zoneamento'
        };

        init();

        function init() {
            $enumsCache.get('Turno').then(function (data) {
				vm.turnos = data;
                setEstabelecimentoGroups();
			});
        }

        function confirmOrder() {            
            if(vm.utilizaZoneamento) {
                var estabsZoneamento = _.flatten(_.values(vm.estabelecimentoGroups));
                _.forEach($scope.inscricao.estabelecimentos, function (estab) {
                    var estabEscolhido = _.find(estabsZoneamento, { 
                        'estabelecimento': {
                            'id': estab.estabelecimento.id
                        },
                        'turno': estab.turno
                    });
                    
                    estab.mesmoZoneamento = Boolean(estab.isSameZoneamento);
                    estab.ordemPreferencia = _.get(estabEscolhido, 'ordemPreferencia');
                });
            }
                
            $scope.inscricao.$$estabelecimentosOrdenados = true;
        }

        function setEstabelecimentoGroups() {
            orderByPreferencia();

            if (!_.isEmpty($scope.inscricao.$$estabelecimentosOnEdit)) {
                setZoneamentoOnEdit();
            }

            if (vm.utilizaZoneamento) {
                setZoneamento();
                setEstabelecimentosOrder();
                orderByPreferencia();
            }

            if (!$scope.inscricao.id) {
                setEstabelecimentosOrder();
            } 
        }

        function setZoneamento() {
            setZoneamentoEstabEqualInscricao();
            setZoneamentoGroups();
        }

        function setZoneamentoOnEdit() {
            if (isSameEstabelecimentos()) {
                $scope.inscricao.estabelecimentos = $scope.inscricao.$$estabelecimentosOnEdit;
                return;
            }

            vm.estabelecimentoGroups = {};
            $scope.inscricao.$$estabelecimentosOnEdit = [];
            setZoneamento();
            setEstabelecimentosOrder();
            orderByPreferencia();
        }

        function setZoneamentoEstabEqualInscricao() {
            var zoneamentoInscricao = $scope.inscricao.dados.enderecoResidencia.bairroEndereco.regiao;

            _.forEach($scope.inscricao.estabelecimentos, function (estab) {
                var enderecoEstab = _.find(estab.estabelecimento.enderecos, 'principal');
                var isSameZoneamento = false;

                if (enderecoEstab) {
                    var zoneamentoEstab = enderecoEstab.bairroEndereco.regiao;
                    isSameZoneamento = zoneamentoEstab && zoneamentoInscricao && zoneamentoInscricao.id === zoneamentoEstab.id;
                }

                estab.isSameZoneamento = isSameZoneamento;
            });
        }

        function setZoneamentoGroups() {
            vm.sortedEstabelecimentos = _.sortBy($scope.inscricao.estabelecimentos, function (estab) { return estab.isSameZoneamento ? -1 : 1; });
            vm.estabelecimentoGroups = _.groupBy(vm.sortedEstabelecimentos, 'isSameZoneamento');
        }

        function setEstabelecimentosOrder() {
            if (!vm.utilizaZoneamento) {
                _.forEach(vm.estabelecimentos, function (item, key) {
                    item.ordemPreferencia = key + 1;
                });
                return;
            }

            _.forEach(vm.estabelecimentoGroups, function (group) {
                _.forEach(group, function (item, key) {
                    item.ordemPreferencia = key + 1;
                });
            });
        }

        function reordenarList() {
            setEstabelecimentosOrder();
        }

        function stepBack() {
            $scope.inscricao.$$estabelecimentosSelecionados = false;
        }

        function orderByPreferencia() {
            if (!$scope.inscricao.estabelecimentos || _.isEmpty($scope.inscricao.estabelecimentos)) {
                return [];
            }

            if (!vm.utilizaZoneamento) {
                return $scope.inscricao.estabelecimentos.sort(sortByOrdemPreferencia);
            }

            $scope.inscricao.estabelecimentos.sort(function (a, b) {
                if (a.mesmoZoneamento === b.mesmoZoneamento) {
                    return sortByOrdemPreferencia(a, b);
                }

                return a.mesmoZoneamento ? -1 : 1;
            });
        }

		function sortByOrdemPreferencia(a, b) {
            if (a.ordemPreferencia === null) { return 1; }
            if (b.ordemPreferencia === null) { return -1; }
            return a.ordemPreferencia - b.ordemPreferencia;
        }

        function isSameEstabelecimentos() {
            var idsEstabelecimentosSelected = _.map(vm.estabelecimentos, 'id');
            var idsEstabelecimentosOnEdit = _.map($scope.inscricao.$$estabelecimentosOnEdit, 'id');
            
            return _.isEqual(_.sortBy(idsEstabelecimentosSelected), _.sortBy(idsEstabelecimentosOnEdit));
        }
    }
})();
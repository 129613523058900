(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .directive('appComponenteCurricularTurma', appComponenteCurricularTurmaDirective);

    function appComponenteCurricularTurmaDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-componente-curricular-turma/app-componente-curricular-turma.html',
            scope: {},
            bindToController: {
                saldoTurma: '=',
                anoLetivoId: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService',
        '$scope',
        'promiseTracker',
    ];

    function Controller(VisaoEstabelecimentoService, $scope, promiseTracker) {
        var vm = this;

        vm.EMPTY_STATE_DIVISAO_DISCIPLINA = '-';

        vm.isSaldoPositivo = isSaldoPositivo;

        vm.promiseTracker = {
            load: promiseTracker(),
        };

        vm.componentesCurricular = null;
        vm.saldoInicial = null;
        vm.saldoOcupado = null;
        vm.saldoAtual = null;

        $scope.$watch('vm.saldoTurma', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            
            init();
        });

        init();
        
        function isSaldoPositivo(componente) {
            return componente.saldoAtual === 0;
        }

        function init() {
            vm.etapaMatrizId = vm.saldoTurma.etapaMatriz.id;
            vm.turmaId = vm.saldoTurma.turma.id;

            var promise = VisaoEstabelecimentoService.getSaldosDisciplinasByTurma(vm.turmaId, null)
                .then(function (result) {
                    vm.componentesCurricular = getSaldosDivisaoDisciplinaByComponenteCurricular(result);
                    getGruposDisciplinas();
                });
            vm.promiseTracker.load.addPromise(promise);
        }

        function getSaldosDivisaoDisciplinaByComponenteCurricular(content) {
            var result = content.reduce(function(acc, curr) {
                var descricao = curr.descricaoItemEducacional;
                
                if(!acc[descricao]) {
                    acc[descricao] = createDefaultSaldoComponenteCurricular(curr);
                }
                
                sumSaldosDivisaoComponentesCurriculares(acc[descricao], curr);

                return acc;
            }, {});

            return result;
        }

        function createDefaultSaldoComponenteCurricular(divisaoDisciplina) {
            return {
                descricao: divisaoDisciplina.descricaoItemEducacional,
                saldoInicial: 0,
                saldoAtual: 0,
                saldoOcupado: 0,
                professores: []
            };
        }

        function sumSaldosDivisaoComponentesCurriculares(saldo, divisaoDisciplina) {
            saldo.saldoInicial += divisaoDisciplina.saldoInicial;
            saldo.saldoAtual += divisaoDisciplina.saldoAtual;
            saldo.saldoOcupado += divisaoDisciplina.saldoOcupado;

            if(!_.isEmpty(divisaoDisciplina.funcionario)) {
                var divisaoNome = _.get(divisaoDisciplina, 'divisaoTurmaDisciplina.nome');
                var funcionarioNome = _.get(divisaoDisciplina, 'funcionario.nome');
                saldo.professores.push({
                    nome: funcionarioNome ? funcionarioNome : vm.EMPTY_STATE_DIVISAO_DISCIPLINA,
                    divisao: divisaoNome ? divisaoNome : vm.EMPTY_STATE_DIVISAO_DISCIPLINA
                });
            }
        }

        function getGruposDisciplinas() {
            var params = {
                anoLetivoId: vm.anoLetivoId
            };

            var promise = VisaoEstabelecimentoService.isEtapaUsingFormatacaoGrupo(vm.etapaMatrizId, params).then(function (data) {
                vm.utilizaFormatacaoGrupo = data.utilizaFormatacaoGrupo;

                if (vm.utilizaFormatacaoGrupo) {
                    var promiseGrupos = VisaoEstabelecimentoService.getDisciplinasPorConfigQuadroFuncGrupo(vm.turmaId).then(function (gruposDisciplinas) {
                        vm.componentesCurricular = _.map(gruposDisciplinas, groupDisciplinasWithFormatacaoGrupo);
                    });

                    vm.promiseTracker.load.addPromise(promiseGrupos);
                }
            });

            vm.promiseTracker.load.addPromise(promise);
        }

        function groupDisciplinasWithFormatacaoGrupo(grupo) {
            var novoGrupo = createDefaultSaldoWithFormatacaoGrupo(grupo);
            
            grupo.divisaoTurmaDisciplinas.forEach(function(itemGrupo) {
                var descricao = itemGrupo.divisaoTurmaDisciplinaDescricao;

                if (vm.componentesCurricular[descricao] && !vm.componentesCurricular[descricao].$$visited) {
                    novoGrupo.descricoesDisciplinas.push(descricao);
                    novoGrupo.saldoInicial += vm.componentesCurricular[descricao].saldoInicial;
                    novoGrupo.saldoAtual += vm.componentesCurricular[descricao].saldoAtual;
                    novoGrupo.saldoOcupado += vm.componentesCurricular[descricao].saldoOcupado;

                    if (!_.isEmpty(vm.componentesCurricular[descricao].professores)) {
                        var professores = vm.componentesCurricular[descricao].professores;
                        
                        _.forEach(professores, function(professor) {
                            if(professor.divisao === vm.EMPTY_STATE_DIVISAO_DISCIPLINA) {
                                professor.divisao = descricao;
                            } else {
                                professor.divisao = descricao + ' (' + professor.divisao + ')';
                            }
                        });

                        novoGrupo.professores.push(vm.componentesCurricular[descricao].professores);
                    }
                    
                    vm.componentesCurricular[descricao].$$visited = true;
                }
            });

            novoGrupo.professores = _.flatten(novoGrupo.professores);
            novoGrupo.descricao = novoGrupo.descricao + ' (' + _.trunc(novoGrupo.descricoesDisciplinas.join(', '), { length: 50 }) + ')';
            novoGrupo.descricoesDisciplinas = '(' + novoGrupo.descricoesDisciplinas.join(', ') + ')';
            return novoGrupo;
        }

        function createDefaultSaldoWithFormatacaoGrupo(grupo) {
            return {
                descricao: grupo.descricaoGrupo,
                descricoesDisciplinas: [],
                saldoInicial: 0,
                saldoAtual: 0,
                saldoOcupado: 0,
                professores: []
            };
        }
    }
})(); 
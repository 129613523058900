(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.funcionario.funcionario';
        $stateProvider.state('app.funcionario', {
                'abstract': true,
                views: {
                    '@': {
                        templateUrl: 'planejamento/funcionarios/funcionario.html'
                    }
                }
            })
            .state('app.funcionario.exoneracao', {
                abstract: true,
                data: {
                    id: 'ExoneracaoPageMapping',
                    mirrorOf: STATE
                }
            })
            .state('app.funcionario.afastado', {
                abstract: true,
                data: {
                    id: 'AfastadoPageMapping',
                    mirrorOf: STATE
                }
            })
            .state('app.funcionario.aposentadoria', {
                abstract: true,
                data: {
                    id: 'AposentadoriaPageMapping',
                    mirrorOf: STATE
                }
            })
            .state('app.funcionario.ausencia', {
                abstract: true,
                data: {
                    id: 'AusenciaPageMapping',
                    mirrorOf: STATE
                }
            })
            .state('app.funcionario.demissao', {
                abstract: true,
                data: {
                    id: 'DemissaoPageMapping',
                    mirrorOf: STATE
                }
            })
            .state('app.funcionario.falecimento', {
                abstract: true,
                data: {
                    id: 'FalecimentoPageMapping',
                    mirrorOf: STATE
                }
            })
            .state('app.funcionario.remocao', {
                abstract: true,
                data: {
                    id: 'RemocaoPageMapping',
                    mirrorOf: STATE
                }
            })
            .state(STATE, {
                url: 'funcionarios',
                data: {
                    id: 'FuncionarioPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'planejamento/funcionarios/funcionario/listagem-funcionario.html'
                    }
                }
            })
            .state('app.funcionario.disponibilidade', {
                url: 'disponibilidades',
                views: {
                    '@': {
                        templateUrl: 'planejamento/funcionarios/disponibilidade/disponibilidade.html'
                    }
                }
            })
            .state(STATE + STATES_ADICIONAR.state, {
                url: STATES_ADICIONAR.url,
                onEnter: openModal,
                data: {
                    isModalState: true
                }
            }).state(STATE + STATES_EDITAR.state, {
                url: STATES_EDITAR.url,
                onEnter: openModal,
                data: {
                    isModalState: true
                }
            })
            .state('app.funcionario.areatuacao', stateUrlView('area', '', 'planejamento/funcionarios/areas-atuacao/area.atuacao.html',
                ''));

        openModal.$inject = [
                '$stateParams',
                'educacao.funcionario.FuncionarioService',
                '$modalStates'
            ];

        function openModal($stateParams, FuncionarioService, $modalStates) {
            $modalStates.open({
                getMethod: FuncionarioService.get,
                templateUrl: 'planejamento/funcionarios/funcionario/cadastro-funcionario.html',
                controller: 'educacao.funcionario.FuncionarioCadCtrl as vm',
                propertyName: 'funcionario',
                size: 'xxl',
                stateParams: $stateParams
            });
        }
    }

    function stateUrlView(urlData, viewNameData, templateData, controllerData) {
        var obj = {};

        obj.views = {};
        obj.views[viewNameData] = {};

        obj.url = urlData;
        obj.views[viewNameData].templateUrl = templateData;

        if (controllerData) {
            obj.views[viewNameData].controller = controllerData;
        }

        return obj;
    }
})();

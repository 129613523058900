(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('InscricaoMatriculaRegularService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {
        var createdService = ServiceBuilder.create()
            .path('matricula/inscricao')
            .enum('NivelEscolar', 'EquivalenciaEtapa', 'Turno', 'Raca', 'CorOlhos', 'TipoSanguineo', 'TipoFiliacao', 'HabilidadeMotora', 'OrientacaoSexual', 'EstadoCivil', 'GrauInstrucao', 'Sexo')
            .build();

        createdService.listEtapas = listEtapas;
        createdService.loadConfiguracao = loadConfiguracao;

        createdService.listTurnosInfantil = listTurnosInfantil;
        createdService.listTurnosEquivalencia = listTurnosEquivalencia;

        createdService.listEstabelecimentosInfantil = listEstabelecimentosInfantil;
        createdService.listEstabelecimentosByEquivalencia = listEstabelecimentosByEquivalencia;

        return createdService;

        function listEtapas() {
            return createdService.getEndpoint().all('etapas').get('');
        }

        function loadConfiguracao(idConfiguracao) {
            return createdService.getEndpoint().one('parametros', idConfiguracao).get();
        }

        function listTurnosInfantil(idConfiguracao, dataNascimento) {
            return createdService.getEndpoint().one('turnos', idConfiguracao).one('dataNascimento', dataNascimento).get();
        }

        function listTurnosEquivalencia(idConfiguracao, equivalencia) {
            return createdService.getEndpoint().one('turnos', idConfiguracao).one('equivalencia', equivalencia).get();
        }

        function listEstabelecimentosInfantil(idConfiguracao, dataNascimento, turnos, latitudeResidencia, longitudeResidencia) {
            return createdService.getEndpoint().one('estabelecimentos', idConfiguracao).one('dataNascimento', dataNascimento).get({
                turnos: turnos,
                latitudeResidencia: latitudeResidencia,
                longitudeResidencia: longitudeResidencia
            });
        }

        function listEstabelecimentosByEquivalencia(idConfiguracao, equivalencia, turnos, latitudeResidencia, longitudeResidencia) {
            return createdService.getEndpoint().one('estabelecimentos', idConfiguracao).one('equivalencia', equivalencia).get({
                turnos: turnos,
                latitudeResidencia: latitudeResidencia,
                longitudeResidencia: longitudeResidencia
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('hideEmptyContainer', directive);

    directive.$inject = [];
    function directive() {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
                $scope.$watch(hideIfEmpty);

                function hideIfEmpty() {
                    if ($element.children().length) {
                        $element.removeClass('hide');
                    } else {
                        $element.addClass('hide');
                    }
                }
            }
        };
    }
})();
(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.factory('educacao.matriz.EtapaService', ['Restangular', '$q',
        function (Restangular, $q) {
            var meta;

            function getMetadata(matriz) {
                var deferred = $q.defer();
                if (!meta) {
                    Restangular.one('matriz').one('matriz-curricular', matriz).all('etapas').options().then(function (data) {
                        meta = data;
                        deferred.resolve(meta);
                    }, function (data) {
                        deferred.reject(data);
                    });
                } else {
                    deferred.resolve(meta);
                }
                return deferred.promise;
            }

            return {
                get: function (matriz, id) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .one('etapas', id).get();
                },
                getList: function (matriz, filter, offset, limit, sort) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .all('etapas').getList({
                            offset: offset,
                            limit: limit,
                            filter: filter,
                            sort: sort
                        });
                },
                getDefault: function (matriz) {
                    return getMetadata(matriz).then(function (data) {
                        return angular.copy(data.representation.default);
                    });
                },
                getTypes: function (matriz) {
                    return getMetadata(matriz).then(function (data) {
                        return angular.copy(data.types);
                    });
                },
                save: function (matriz, data) {
                    var rest = Restangular.one('matriz').one('matriz-curricular', matriz)
                        .all('etapas');
                    return !data.id ?
                        rest.post(data) :
                        rest.customPUT(data, data.id);
                },
                updateAll: function (matriz, data) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .all('etapas').customPUT(data);
                },
                remove: function (matriz, id) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .one('etapas', id).remove();
                },
                readOnly: function () {
                    var deferred = $q.defer();
                    deferred.resolve(false);
                    return deferred.promise;
                },
                getPossuamTurmas: function (matrizId, estabelecimentoId, tipoAtendimento, offset, limit, filter) {
                    return Restangular.one('matriz').one('matriz-curricular', matrizId).one('etapas').all('possuam-turmas').get('', {
                        estabelecimento: estabelecimentoId,
                        tipoAtendimento: tipoAtendimento,
                        offset: offset,
                        limit: limit,
                        filter: filter
                    });
                },
                camposAdicionais: function(matrizId, etapaId){                    
                    return Restangular.one('matriz').one('matriz-curricular', matrizId)
                        .one('etapas').one('campos-adicionais', etapaId).get();
                }
            };
        }
    ]);
})();
(function() {
    'use strict';

    var SITUACOES_ALTERACAO = ['APROVADO_CONSELHO', 'APROVADO', 'REPROVADO'];

    angular.module('educacao.desempenhoescolar').directive('appDesempenhoTableAreaConhecimento', appDesempenhoTableAreaConhecimento);

    function appDesempenhoTableAreaConhecimento() {
        return {
            restrict: 'E',
            templateUrl: 'desempenho-escolar/area-conhecimento/app-desempenho-table-area-conhecimento.directive.html',
            scope: {
                save: '='
            },
            bindToController: {
                hasPermissionsToEditPeriodoEncerrado: '=',
                listaEnturmacoes: '=',
                listaAvaliaveis: '=',
                listaAvaliacoes: '=',
                configuracaoAvaliacao: '=',
                itemEducacional: '=',
                turma: '=',
                periodo: '=',
                calculoFormula: '=',
                periodos: '=',
                porPeriodo: '=',
                encerramentos: '=',
                hasPermissionsPeriodoEncerrado: '=',
                sortBy: '=',
                saveTracker: '=',
                showModalChaObjetivo: '='
            },
            controller: DesempenhoTableAreaConhecimento,
            controllerAs: 'vm'
        };
    }

    DesempenhoTableAreaConhecimento.$inject = ['$injector', '$rootScope', '$scope', '$filter', 'promiseTracker', 'educacao.common.ObjectUtilsService'];

    function DesempenhoTableAreaConhecimento($injector, $rootScope, $scope, $filter, promiseTracker, ObjectUtilsService) {

        var vm = this;
        var PermissionsService = $injector.get('bfc.$$PermissionsService');
        vm.verificaPeriodoEncerrado = verificaPeriodoEncerrado;
        vm.isRevokeAlterarSituacao = PermissionsService.isRevokedOperation('RegistroAvaliacaoPageMapping', 'alterarSituacao');
        var TipoAvaliacaoUtilsService = $injector.get('educacao.matricula.TipoAvaliacaoUtilsService');
        var DesempenhoEscolarUtilsService = $injector.get('educacao.desempenhoescolar.DesempenhoEscolarUtilsService');
        var RegistroAvaliacoesService = $injector.get('educacao.desempenhoescolar.RegistroAvaliacoesService');
        var ITEM_AVALIAVEL_TIPO_PERIODO = $injector.get('ITEM_AVALIAVEL_TIPO_PERIODO');
        var ITEM_AVALIAVEL_MODO_AVALIACAO = $injector.get('ITEM_AVALIAVEL_MODO_AVALIACAO');
        var bfcNotification = $injector.get('bfc.Notification');
        var $q = $injector.get('$q');
        
        vm.titleInputNota = titleInputNota;
        vm.changeSituacao = changeSituacao;
        vm.filterResultEnunsEspecificos = filterResultEnunsEspecificos;
        vm.desabilitaCampoSituacao = desabilitaCampoSituacao;
        var TEMPLATE_SITUACAO_PERIODO = _.template(
            'Período avaliativo <%= situacao %> (<%= moment(dataInicial).format("DD/MM/YYYY") %> a <%= moment(dataFinal).format("DD/MM/YYYY") %>)'
        );
        vm.topFloatHeader = $rootScope.$$pinned ? '78' : '44';

        var propriedadesPublicas = {
            isNotaDescritivaOuConceito: _isNotaDescritivaOuConceito,
            getMedia: _getMedia,
            getDescricaoItem: _getDescricaoItem,
            getTitlePeriodo: _getTitlePeriodo,
            getClassStatus: _getClassStatus,
            isSemNota: _isSemNota,
            orderBy: _orderBy
        };

        $scope.$watch('vm.listaAvaliacoes', function(newValue, oldValue) {
            if (newValue === oldValue || !vm.porPeriodo) {
                return;
            }

            atualizaMediaCalculo();
        }, true);

        _.extend(vm, propriedadesPublicas);

        init();

        function init() {
            initTracker();
            initEnum();
            getCols();
            getListaEnturmacoes();
        }

        function initTracker() {
            vm.tracker = {
                enumsSituacao: promiseTracker()
            };
        }

        // verifica se houve alteração na fixação do contexto
        $scope.$watch('$root.$$pinned', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            vm.topFloatHeader = newValue ? '78' : '44';
        });


        function initEnum() {
            vm.tracker.enumsSituacao.addPromise(
                $q.all([
					vm.SituacaoMatriculaPromisse = RegistroAvaliacoesService.getEnum('SituacaoMatricula').then(function(data) {
                        vm.enumSituacaoMatricula = data;
                        return data;
                    }),
					RegistroAvaliacoesService.getEnum('SituacaoItemEducacional').then(function(data) {
                        vm.enumSituacaoDisciplina = data;
                    })
				])
            );
        }

        function desabilitaCampoSituacao(situacao){
            return _.isEmpty(situacao) || !_.contains(SITUACOES_ALTERACAO, situacao);
        }
        function filterResultEnunsEspecificos(term, value) {
            if(_.contains(SITUACOES_ALTERACAO, value.key)){
                if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                    return value;
                }    
            }
        }
        function _getClassStatus(enturmacao) {
            return {
                'text-center': enturmacao.situacao !== 'EM_EXAME',
                'tx__red': enturmacao.situacao === 'EM_EXAME'
            };
        }

        function _isSemNota(nota, dataFinal) {
            return Boolean(!getNota(nota) &&
                ((vm.periodo && vm.periodo.dataFinal && moment().isAfter(vm.periodo.dataFinal, 'day')) ||
                    ((moment().isAfter(dataFinal, 'day')))));
        }

        function _isNotaDescritivaOuConceito(media) {
            if (!media) {
                return;
            }

            return Boolean(media.isModoDescritivo ||
                DesempenhoEscolarUtilsService.isModoAvaliacaoConceito(media.modoAvaliacao));
        }

        function _getMedia(linha) {
            if (!linha) {
                return;
            }

            return vm.porPeriodo ? getMediaAreaConhecimento(linha) : getMediaFinalAreaConhecimento(linha);
        }

        function _getDescricaoItem(item) {
            if (!item) {
                return;
            }

            return vm.porPeriodo ? item.itemEducacional.descricao : item.descricao;
        }

        function getCols() {
            vm.cols = {
                finais: []
            };

            if (vm.porPeriodo) {
                vm.cols.itens = _.sortBy(filterItensAvaliaveis(vm.listaAvaliaveis, [ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO]),
                    function(data) {
                        return Number(data.itemEducacional.descricao);
                    });
            } else {
                vm.cols.itens = _.clone(vm.periodos);
            }

            setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME]);
            setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL], null, 'identificadorExameFinal');
            setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.CONSELHO_CLASSE], 'Conselho de classe');

            if (!vm.porPeriodo) {
                _.forEach(vm.cols.itens, function(item) {
                    var periodoEncerrado = _.find(vm.encerramentos, function(encerramento) {
                        if (item.identificadorExameFinal && encerramento.identificadorExameFinal) {
                            return item.identificadorExameFinal === encerramento.identificadorExameFinal &&
                                item.tipoPeriodo === encerramento.tipoPeriodo && encerramento.status === 'ENCERRADO';
                        }

                        return encerramento.periodoAvaliativo && encerramento.periodoAvaliativo.id === item.id &&
                            encerramento.status === 'ENCERRADO';
                    });

                    item.hasEncerramento = periodoEncerrado ? true : false;
                });
            }
        }

        function setColValue(tipo, descricao, order) {
            var itens = filterItensAvaliaveis(vm.listaAvaliaveis, tipo);

            if (itens.length) {
                _.forEach(order ? _.sortBy(itens, order) : itens, function(data) {
                    data.descricao = descricao || data.descricao;
                    vm.cols.itens.push(data);
                });
            }
        }

        function atualizaMediaCalculo() {
            var avaliavelMedia = _.find(vm.listaAvaliaveis, function(data) {
                return ((data.itemEducacional.tipo === 'AREA_CONHECIMENTO') &&
                    (data.periodoAvaliativo && data.periodoAvaliativo.id === vm.periodo.id) &&
                    (data.itemEducacional.id === vm.itemEducacional.id));
            });

            _.forEach(vm.listaEnturmacoes, function(enturmacao) {
                var newEnturmacao = _.cloneDeep(enturmacao);

                if (enturmacao.coluna) {
                    var colunaMedia = _.filter(enturmacao.coluna, function(coluna) {
                        return ((coluna.nota.avaliavel.itemEducacional.tipo === 'AREA_CONHECIMENTO') &&
                            (coluna.nota.avaliavel.periodoAvaliativo && coluna.nota.avaliavel.periodoAvaliativo.id === vm.periodo.id) &&
                            (coluna.nota.avaliavel.itemEducacional.id === vm.itemEducacional.id));
                    });

                    if (colunaMedia) {
                        atualizaColunaEnturmacao(enturmacao, avaliavelMedia, newEnturmacao);
                    }
                }
            });
        }

        function getListaEnturmacoes() {
            _.forEach(vm.listaEnturmacoes, function(enturmacao) {
                enturmacao.coluna = {};
                getItensAvaliaveis(enturmacao, vm.listaAvaliaveis);
            });
        }

        function getItensAvaliaveis(enturmacao, listaAvaliaveis) {
            var newEnturmacao = _.cloneDeep(enturmacao);

            var avaliaveis = vm.porPeriodo ? vm.listaAvaliaveis : _.filter(listaAvaliaveis, function(data) {
                return (data.itemEducacional.tipo === 'AREA_CONHECIMENTO');
            });

            _.forEach(avaliaveis, function(avaliavel) {
                atualizaColunaEnturmacao(enturmacao, avaliavel, newEnturmacao);
            });
        }

        function atualizaColunaEnturmacao(enturmacao, avaliavel, newEnturmacao) {
            var itemAvaliacao = _.find(vm.listaAvaliacoes, function(data) {
                return data.enturmacao.id === enturmacao.id && avaliavel.id === data.avaliavel.id;
            });

            var modoAvaliacao = _.get(enturmacao, 'matricula.avaliacaoDescritiva') ? ITEM_AVALIAVEL_MODO_AVALIACAO.DESCRITIVO :
                (avaliavel ? _.get(avaliavel, 'modoAvaliacao') : '');

            itemAvaliacao = itemAvaliacao || [];
            var notas = getNota(itemAvaliacao);
            var descricao = notas && notas.sigla ? notas.sigla : notas;
            var periodo = vm.porPeriodo ? avaliavel.itemEducacional.descricao : avaliavel.descricao;
            var isPermiteCha = vm.porPeriodo ? DesempenhoEscolarUtilsService.isPermiteCha(_.get(avaliavel, 'tipoPeriodo')) :
                false;
            var isEditavel = DesempenhoEscolarUtilsService.isItemEditavel(_.get(avaliavel, 'tipoPeriodo'), modoAvaliacao);
            var isPermiteParecer = modoAvaliacao && DesempenhoEscolarUtilsService.isPermiteParecer(_.get(avaliavel,
                'tipoPeriodo'));

            if (DesempenhoEscolarUtilsService.isModoAvaliacaoNumerico(avaliavel.modoAvaliacao) && !newEnturmacao.matricula.avaliacaoDescritiva) {
                var decimais = TipoAvaliacaoUtilsService.getQuantidadeCasasDecimais(vm.configuracaoAvaliacao);
                descricao = $filter('appNumber')(notas, ',', decimais);
            }

            var enturmacaoSituacaoItem = _.find(enturmacao.situacaoItensEducacionais, function(item) {
                return _.get(item.itemEducacional, 'id') === avaliavel.itemEducacional.id;
            });

            enturmacao.situacao = _.get(enturmacaoSituacaoItem, 'situacao') || enturmacao.matricula.situacao;

            var nota = {
                $$tracker: promiseTracker(),
                $$permiteCha: isPermiteCha,
                $$editavel: isEditavel,
                $$permiteParecer: isPermiteParecer,
                hasNotaFilhosPreenchido: itemAvaliacao.hasNotaFilhosPreenchido,
                avaliavel: avaliavel,
                enturmacao: newEnturmacao,
                id: itemAvaliacao.id,
                filhos: itemAvaliacao.filhos,
                parecer: itemAvaliacao.parecer,
                notaNumerica: itemAvaliacao.notaNumerica,
                notaConceito: itemAvaliacao.notaConceito,
                notaDescritiva: itemAvaliacao.notaDescritiva,
                updateFields: function() {}
            };

            enturmacao.coluna[periodo] = {
                notaDescricao: descricao || '-',
                nota: nota,
                modoAvaliacao: avaliavel.modoAvaliacao,
                showEdit: !_.includes(
					[ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME,
					ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL],
                    avaliavel.tipoPeriodo),
                isModoDescritivo: getAvaliacaoDescritiva(nota, avaliavel)
            };
        }

        function getAvaliacaoDescritiva(nota, avaliavel) {
            return Boolean(DesempenhoEscolarUtilsService.isModoAvaliacaoDescritivo(avaliavel.modoAvaliacao) ||
                (nota && nota.enturmacao && nota.enturmacao.matricula && nota.enturmacao.matricula.avaliacaoDescritiva));
        }

        function getNota(avaliacao) {
            if (!avaliacao) {
                return;
            }
            return avaliacao.notaConceito || avaliacao.notaDescritiva || avaliacao.notaNumerica;
        }

        function filterItensAvaliaveis(listaAvaliaveis, filter) {
            return _.filter(listaAvaliaveis, function(data) {
                return _.includes(filter, data.tipoPeriodo) && data.modoAvaliacao !== ITEM_AVALIAVEL_MODO_AVALIACAO.NAO_OFERECE &&
                    ((data.itemEducacional.tipo !== 'AREA_CONHECIMENTO' && vm.porPeriodo) || (data.itemEducacional.tipo ===
                        'AREA_CONHECIMENTO' && !vm.porPeriodo));
            });
        }

        function _getTitlePeriodo(item) {
            if (item.dataInicial) {
                return TEMPLATE_SITUACAO_PERIODO(_.extend(item, {
                    situacao: item.hasEncerramento ? 'encerrado' : 'em aberto'
                }));
            } else {
                return item.descricao + (item.hasEncerramento ? ' encerrado' : ' em aberto');
            }
        }

        function getMediaAreaConhecimento(enturmacao) {
            if (!enturmacao.coluna) {
                return;
            }
            return findMediaAreaConhecimento(enturmacao.coluna) || {};
        }

        function getMediaFinalAreaConhecimento(enturmacao) {
            if (!enturmacao.coluna) {
                return;
            }
            return findMediaFinalAreaConhecimento(enturmacao.coluna) || {};
        }

        function findMediaAreaConhecimento(coluna) {
            return _.find(coluna, function(data) {
                return data.nota.avaliavel.itemEducacional.tipo === 'AREA_CONHECIMENTO';
            });
        }

        function findMediaFinalAreaConhecimento(coluna) {
            return _.find(coluna, function(data) {
                return data.nota.avaliavel.tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL;
            });
        }

        function _orderBy(item) {
            if (!_.get(vm.sortBy, 'expression')) {
                vm.sortBy.expression = 'numeroChamada';
            }
            if (vm.sortBy.expression === 'matricula.nome') {
                return ObjectUtilsService.normalize(_.get(item, vm.sortBy.expression));
            }
            return _.get(item, vm.sortBy.expression);
        }
        function changeSituacao(enturmacao){
            var lista = _.filter(enturmacao.situacaoItensEducacionais, function(dado) {
                return dado.itemEducacional.id === vm.itemEducacional.id;
            });

    
            if(!_.get(enturmacao,'situacao')){
                bfcNotification.publish(
                    'A situação precisa ser informada',
                    'error');
                    return;
            }

            var promise = RegistroAvaliacoesService.alterarEnturmacaoSituacao(lista[0].id,enturmacao.situacao)
                .then(function() {
                    vm.successfulSave = true;
                });

            vm.saveTracker.addPromise(promise);
        }

        function verificaPeriodoEncerrado(linha) {
            var idPeriodo = _.get(linha, 'avaliavel.periodoAvaliativo.id');
            if (!!idPeriodo) {
                var periodoAvaliativo = _.first(_.filter(vm.encerramentos, 'periodoAvaliativo.id', idPeriodo));
                if (_.get(periodoAvaliativo, 'status') === 'ENCERRADO') {
                    vm.title = vm.hasPermissionsPeriodoEncerrado;
                    return vm.hasPermissionsPeriodoEncerrado;
                }
            }
            return true;
        }

        function titleInputNota(linha, item){
            var nota = linha.coluna[vm.getDescricaoItem(item)].nota;
            var dataFinal = item.dataFinal;
            if(!vm.verificaPeriodoEncerrado(nota)){
                return 'Período encerrado.';
            }
            if(vm.isSemNota(nota, dataFinal)) {
                return 'A data atual é maior que a data final do período. É necessário informar o desempenho do aluno.';
            }
            if(!vm.hasPermissionsToEditPeriodoEncerrado){
                return 'Você não tem permissão para acessar este recurso.';
            }
            return '';
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.common')
        .factory('CriaMenusPlanejando', factory);
    factory.$inject = ['mainMenu', 'MegaMenuItem', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService', '$rootScope'];

    function factory(mainMenu, MegaMenuItem, QuadroFuncionariosGestaoPessoasService, $rootScope) {
        return createMenus;

        function createMenus() {
            var configurationMenu = {
                style: {
                    'width': '250px',
                    'left': '-1px'
                },
                classCol: 'col-md-12',
                configurationColumns: [{
                    numberOfChildrens: 1
                }, {
                    numberOfChildrens: 1
                }]
            };

            var planejando = new MegaMenuItem('planejando', 'Planejando')
                .setConfiguration(configurationMenu);

            criaGrupoAnoLetivo(planejando);
            criaGrupoCalendarioEscolar(planejando);
            criaGrupoMatricula(planejando);
            criaGrupoQuadroInformativo(planejando);

            mainMenu.addChildren(planejando);


            function criaGrupoAnoLetivo(root) {
                var grupoAnoLetivo = new MegaMenuItem('anoLetivoPlanejando', 'Ano letivo')
                    .setParent(root);

                new MegaMenuItem('Preparação de ano letivo')
                    .setParent(grupoAnoLetivo)
                    .setPermission('app.preparacao-ano-letivo')
                    .setAction('app.preparacao-ano-letivo')
                    .setAction(abreModalPreparacaoAnoLetivo);
            }

            function criaGrupoCalendarioEscolar(root) {
                var grupoCalendario = new MegaMenuItem('calendarioEscolar', 'Calendário escolar')
                    .setParent(root);

                new MegaMenuItem('Eventos')
                    .setParent(grupoCalendario)
                    .setAction('app.evento');

                new MegaMenuItem('Feriados')
                    .setParent(grupoCalendario)
                    .setAction('app.feriado');

                new MegaMenuItem('Calendários escolares')
                    .setParent(grupoCalendario)
                    .setAction('app.calendario');
            }

            function criaGrupoMatricula(root) {
                var grupoMatricula = new MegaMenuItem('matriculas', 'Matrículas')
                    .setParent(root);

                new MegaMenuItem('Quadros de vagas')
                    .setParent(grupoMatricula)
                    .setAction('app.matricula.vagas');

                new MegaMenuItem('Inscrições para matrícula')
                    .setParent(grupoMatricula)
                    .setAction('app.matricula.classificacaoInscritos');

                new MegaMenuItem('Atestado de vagas')
                    .setParent(grupoMatricula)
                    .setIconClass('fa fa-file-text-o')
                    .setPermission('app.matricula.atestado-de-vaga')
                    .setAction(abreModalAtestadoDeVaga);

            }

            function criaGrupoQuadroInformativo(root) {

                var grupoQuadroInformativo = new MegaMenuItem('gestaoRede', 'Gestão da rede')
                    .setParent(root);

                var menu = new MegaMenuItem('Quadro informativo')
                    .setParent(grupoQuadroInformativo)
                    .setPermission('app.planejamento.visao-geral-quadro-informativo')
                    .setAction('app.planejamento.visao-geral-quadro-informativo');

                $rootScope.$on('bfc:user-access:new', function () {
                    QuadroFuncionariosGestaoPessoasService.checkPossuiLicencaQuadroInformativo().then(function (data) {
                        var possuiLicencaQuadro = _.get(data, 'quadroInformativo');
                        if (!possuiLicencaQuadro) {
                            menu.setVisible(false);
                            grupoQuadroInformativo.label = '';
                        } else {
                            menu.setVisible(true);
                            grupoQuadroInformativo.label = 'Gestão da rede';
                        }
                    });
                });
            }


            abreModalAtestadoDeVaga.$inject = ['ui.components.Modal'];

            function abreModalAtestadoDeVaga($modal) {
                $modal.open({
                    templateUrl: 'matricula/matriculas/relatorios-matricula/atestado-de-vaga/atestado.vaga-informacoes.html',
                    controller: 'educacao.matricula.atestadoDeVaga',
                    controllerAs: 'vm',
                    size: 'xxl'
                });
            }

            abreModalPreparacaoAnoLetivo.$inject = ['ui.components.Modal'];

            function abreModalPreparacaoAnoLetivo($modal) {
                $modal.open({
                    templateUrl: 'preparacao-ano-letivo/preparacao-ano-letivo.html',
                    controller: 'educacao.preparacaoAnoLetivo.PreparacaoAnoLetivoController',
                    controllerAs: 'vm',
                    size: 'xl'
                });
            }
        }
    }
})();


(function () {

    /**
     * Diretiva para encapsular conteúdo da aba locais de trabalho vinculado ao funcionário
     * Parâmetros:
     * matricula -> matricula que está sendo adicionado ou editado
     */

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appTabFuncionarioMatriculaLocaisTrabalho', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-matricula/app-tab-funcionario-matricula-locais-trabalho.directive.html',
            scope: {},
            bindToController: {
                matricula: '=',
                listaDesenturmar: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['orderByFilter'];

    function Controller(orderBy) {

        var vm = this;

        vm.adicionarLinha = adicionarLinha;
        vm.removerLinha = removerLinha;
        vm.watchAlteraHorario = watchAlteraHorario;
        vm.changeDataInicial = changeDataInicial;
        vm.changeLocalTrabalho = changeLocalTrabalho;
        vm.inLocaisEncerrados = inLocaisEncerrados;
        vm.inListaDesenturmar = inListaDesenturmar;
        vm.addListaDesenturmar = addListaDesenturmar;
        vm.desabilitaDesenturmar = desabilitaDesenturmar;
        vm.localDesenturmado = localDesenturmado;
        vm.changeShowAulasPersonalizado = changeShowAulasPersonalizado;
        vm.adicionarLinhaAulasSubstituidas = adicionarLinhaAulasSubstituidas;
        vm.getTitleEstabelecimentoText = getTitleEstabelecimentoText;
        vm.getTitleDataText = getTitleDataText;

        initController();

        vm.locaisEncerrados = angular.copy(_.filter(vm.matricula.locaisTrabalho, function(l){
            return !!l.dataFinal;
        }));

        function initController() {
            vm.matricula.locaisTrabalho = orderBy(vm.matricula.locaisTrabalho, 'dataFinal', true);
            vm.locaisTrabalho = vm.matricula.locaisTrabalho;
            if (!vm.matricula.locaisTrabalho) {
                vm.matricula.locaisTrabalho = [];
            }
            vm.locaisTrabalho.forEach(function (localTrabalho) {
                if (_.isEmpty(localTrabalho.aulasSubstituidas)) {
                    adicionarLinhaAulasSubstituidas(localTrabalho.aulasSubstituidas);
                }
            });
            
            if (vm.matricula.cargoComissionado) {
                vm.cargoComissionado = true;
            }
        }

        function adicionarLinha(list) {
            list.push({
                aulasSubstituidas: [{ duracaoAula: null, quantidadeAulas: null }]
            });
        }

        function adicionarLinhaAulasSubstituidas(list) {
            list.push({duracaoAula: null, quantidadeAulas: null});
        }

        function watchAlteraHorario(matricula) {
            var horas = sumProperty(matricula.locaisTrabalho, 'cargaHorariaSemanalHoras');
            var minutos = sumProperty(matricula.locaisTrabalho, 'cargaHorariaSemanalMinutos');

            if (minutos > 0) {
                var horasAdicionais = Math.floor(minutos / 60);
                minutos = minutos % 60;
                horas = horas + horasAdicionais;
            }

            return horas + 'h ' + minutos + 'm';
        }

        function removerLinha(list, index) {
            list.splice(index, 1);
            removeSeNaoExiste();
        }

        function sumProperty(list, property) {
            list = _.filter(list, function(l){
                return !l.dataFinal;
            });
            return _.chain(list)
                .map(property)
                .sum()
                .value();
        }

        function changeDataInicial(local){
            if (!local.dataInicial){
                local.dataPrevistaTermino = null;
                local.dataFinal = null;
            }
        }

        function changeLocalTrabalho(local){
            if (!inLocaisEncerrados(local) && !inListaDesenturmar(local)) {
                local.desenturmado = false;
                removeSeNaoExiste();
            }
        }

        function inLocaisEncerrados(local){
            return !!(_.find(vm.locaisEncerrados, local));
        }

        function inListaDesenturmar(local){
            var item = criarItemDesenturmar(local);
            return _.find(vm.listaDesenturmar, item);
        }

        function addListaDesenturmar(local) {
            var item = criarItemDesenturmar(local);
            if (local.desenturmado && !inListaDesenturmar(local)) {
                vm.listaDesenturmar.push(item);
            }
            if (!local.desenturmado) {
                removeItemLista(item);
            }
        }

        function removeItemLista(item){
            _.remove(vm.listaDesenturmar, item);
            removeSeNaoExiste();
        }

        function removeSeNaoExiste(){
            _.remove(vm.listaDesenturmar, function(item){
                return !findItemLocalTrabalho(item) && item.matricula && item.matricula === vm.matricula.id;
            });
        }

        function findItemLocalTrabalho(item) {
            return _.find(vm.matricula.locaisTrabalho, function(l) {
                if (!l.estabelecimento) {
                    return false;
                }
                return localTrabalhoEquals(l, item);
            });
        }

        function localTrabalhoEquals(localTrabalhoFromList, localTrabalho){
            return localTrabalhoFromList.estabelecimento.id === localTrabalho.estabelecimento &&
                    localTrabalhoFromList.dataFinal === localTrabalho.dataFinal &&
                    localTrabalhoFromList.dataInicial === localTrabalho.dataInicial &&
                    localTrabalhoFromList.dataPrevistaTermino === localTrabalho.dataPrevistaTermino &&
                    localTrabalhoFromList.id === localTrabalho.local;
        }

        function criarItemDesenturmar(local){
            var matriculaId = vm.matricula ? vm.matricula.id : null;
            var estabelecimentoId = local.estabelecimento ? local.estabelecimento.id : null;
            return {
                local: local.id,
                estabelecimento: estabelecimentoId,
                dataInicial: local.dataInicial,
                dataFinal: local.dataFinal,
                dataPrevistaTermino: local.dataPrevistaTermino,
                matricula: matriculaId
            };
        }

        function desabilitaDesenturmar(localTrabalho) {
            return (localTrabalho.desenturmado && !inListaDesenturmar(localTrabalho)) ||
                    !localTrabalho.dataFinal ||
                    !localTrabalho.dataInicial ||
                    !localTrabalho.estabelecimento ||
                    localTrabalho.estabelecimento.isSecretaria;
        }

        function localDesenturmado(localTrabalho){
            return (localTrabalho.desenturmado && !vm.inListaDesenturmar(localTrabalho));
        }

        function changeShowAulasPersonalizado(localTrabalho) {
            localTrabalho.$$open = !localTrabalho.$$open;
        }

        function getTitleEstabelecimentoText(localTrabalho) {
            if(!localTrabalho || !localTrabalho.estabelecimento) {
                return '';
            }

            return localTrabalho.estabelecimento.descricao || localTrabalho.estabelecimento.nome || (localTrabalho.estabelecimento.pessoa ? localTrabalho.estabelecimento.pessoa.nome : '');
        }

        function getTitleDataText(data) {
            if(!data) {
                return '';
            }

            return moment(data).format('DD-MM-YYYY');
        }
    }
})();


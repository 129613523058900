(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.ModalResponsaveisCtrl', ModalResponsaveisCtrl);

    ModalResponsaveisCtrl.$inject = ['$q', 'bfc.Focus', '$modalInstance', 'promiseTracker',
        'bfc.$$PermissionsService', 'ui.components.Modal', '$enumsCache', '$scope', 'educacao.pessoas.ResponsavelService'];

    function ModalResponsaveisCtrl($q, focus, $modalInstance, promiseTracker, PermissionsService, $modal, $enumsCache, $scope,
        ResponsavelService) {
        var vm = this;

        vm.close = close;
        vm.edit = edit;
        vm.save = save;
        vm.remove = remove;
        vm.filterResult = filterResult;
        vm.responsaveis = $modalInstance.params.responsaveis || [];
        vm.vinculo = $modalInstance.params.vinculo;
        vm.allDays = allDays;
        vm.refreshListDays = refreshListDays;
        vm.openModalResponsavel = openModalResponsavel;

        vm.todos = true;

        vm.tracker = {
            loading: promiseTracker(),
            save: promiseTracker(),
            saveContinue: promiseTracker(),
            remove: promiseTracker(),
            camposAdicionais: promiseTracker()
        };

        vm.tipo = $modalInstance.params.tipo;

        init();

        function inicializaEnums() {
            vm.options = {
                Parentesco: $enumsCache.get('Parentesco'),
            };
            vm.responsavel = {
                diaSemana: []
            };
            $enumsCache.get('DiaDaSemanaDto').then(function(diaSemana) {
                angular.forEach(diaSemana.values, function(dia) {
                    dia.checked = false;
                });
                vm.options.DiasDaSemana = diaSemana.values;
                parseDiasSemana();
            });
        }

        $scope.$watch('vm.responsavel.permiteRetirarAluno', function(value) {
            if (!value) {
                angular.forEach(vm.options.DiasDaSemana, function(dia) {
                    dia.checked = false;
                });
                vm.responsavel.diaSemana = [];
            }
        });

        function allDays(value) {
            if (vm.todos) {
                vm.todos = false;
            } else {
                vm.todos = true;
            }
            angular.forEach(vm.options.DiasDaSemana, function(dia) {
                dia.checked = value;
                if (vm.responsavel.diaSemana.indexOf(dia.key) === -1) {
                    vm.responsavel.diaSemana.push(dia.key);
                }
            });
        }

        function refreshListDays(dia) {
            if (vm.responsavel.diaSemana) {
                var existe = vm.responsavel.diaSemana.some(function(diaValue) {
                    return dia.key === diaValue;
                });
                if (existe) {
                    _.remove(vm.responsavel.diaSemana, function(diaSemana) {
                        return dia.key === diaSemana;
                    });
                } else {
                    vm.responsavel.diaSemana.push(dia.key);
                }
            } else {
                vm.responsavel.diaSemana = [dia.key];
            }
        }

        function init() {
            $modalInstance.tracker.addPromise(
                $q.all([
                    inicializaEnums(),
                    refresh()
                ])
            );
        }

        function limpaForm() {
            vm.form.$setPristine();
            vm.form.$setUntouched();
        }

        function refresh() {
            if (vm.form) {
                limpaForm();
            }
            if (!vm.responsavel.id) {
                vm.responsavel.diaSemana = [];
                return;
            }
            if (!vm.responsavel.diaSemana) {
                vm.responsavel.diaSemana = [];
            }
        }

        function parseDiasSemana() {
            var contDias = 0;
            angular.forEach(vm.responsavel.diaSemana, function(dia) {
                var option = _.find(vm.options.DiasDaSemana, function(op) {
                    return op.key === dia;
                });
                option.checked = true;
                contDias++;
            });
        }

        function filterResult(term, value) {
            var valueMaiusculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (valueMaiusculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }

        function close() {
            $modalInstance.close();
        }

        function edit($index) {
            vm.responsavel = angular.copy(vm.responsaveis[$index]);
            vm.indexToEdit = $index;
            angular.forEach(vm.options.DiasDaSemana, function(dia) {
                angular.forEach(vm.responsavel.diaSemana, function(diaSalvo) {
                    if (dia.key === diaSalvo) {
                        dia.checked = true;
                    }
                });
            });

            vm.editando = true;
        }

        function save() {
            if (vm.editando) {
                vm.responsaveis.splice(vm.indexToEdit, 1);
            }
            vm.responsaveis.push(vm.responsavel);

            clear();
        }

        function remove($index) {
            vm.responsaveis.splice($index, 1);
        }

        function clear() {
            vm.responsavel = {
                diaSemana: []
            };
            vm.indexToEdit = null;
            vm.editando = false;
            angular.forEach(vm.options.DiasDaSemana, function(dia) {
                dia.checked = false;
            });

            vm.form.$setPristine();
            vm.form.$setUntouched();
        }

        function openModalResponsavel(responsavel, index) {
            return $modal.open({
                templateUrl: 'planejamento/responsaveis/cadastro-responsaveis.html',
                controller: 'educacao.pessoas.ResponsavelCadCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    responsavel: ResponsavelService.get(responsavel.id)
                }

            }).result.then(function(result) {
                if (result.remove) {
                    remove(index);
                }
            });

        }
    }
})();

(function() {
    'use strict';
    angular.module('educacao.matricula')
        .controller('educacao.matricula.impressaoDocumenos', Controller);

    Controller.$inject = ['$modalInstance', 'ui.components.PrintService'];

    function Controller($modalInstance, PrintService) {
        var vm = this;
        vm.documentos = $modalInstance.params.documentos;
        vm.turma = $modalInstance.params.turma;
        vm.etapaMatriz = $modalInstance.params.etapaMatriz;
        vm.matricula = $modalInstance.params.matricula;
        vm.nomeEscola = vm.matricula.estabelecimento.descricao || vm.matricula.estabelecimento.pessoa.nome;
        vm.nomeAluno = vm.matricula.aluno.nome || vm.matricula.aluno.pessoa.nome;

        formatDadosTurma();
        vm.print = print;

        function formatDadosTurma() {
            vm.dadosDaTurma = '';
            if (vm.etapaMatriz) {
                vm.dadosDaTurma += vm.etapaMatriz.descricao;
            }
            if (vm.turma) {
                if (vm.dadosDaTurma.length) {
                    vm.dadosDaTurma += ' - ';
                }
                vm.dadosDaTurma += vm.turma.descricao;
            }
        }

        function print() {
            PrintService.print('#formularioDocumentos');
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .directive('appGeracaoHorariosVisaoGeralGrade', GeracaoHorariosVisaoGeralGradeDirective);

    function GeracaoHorariosVisaoGeralGradeDirective() {
        return {
            restrict: 'E',
            templateUrl: 'geracao-horarios/visao-geral/grade.directive.html',
            scope: {
                gradeHorarios: '=',
                readOnly: '@?',
                editAction: '=?',
                hidePagination: '=?'
            },
            controller: GeracaoHorariosVisaoGeralGradeDirectiveController,
            controllerAs: 'vm'
        };
    }

    GeracaoHorariosVisaoGeralGradeDirectiveController.$inject = [
        '$scope',
        'educacao.common.$commons',
        '$element',
        'ui.components.PrintService',
        'educacao.geracaoHorarios.GradeDirectiveService',
        'educacao.geracaoHorarios.GeracaoHorariosService',
        '$searchExpressionBuilder',
        'bfc.$$PermissionsService'
    ];

    function GeracaoHorariosVisaoGeralGradeDirectiveController($scope, $commons, $element, uiPrintService, gradeService,
        geracaoHorariosService, $searchExpressionBuilder, PermissionsService) {

        var vm = this;

        vm.readOnly = $scope.readOnly;
        vm.columns = {
            numVisibles: 0
        };
        vm.tracker = {
            grade: $commons.$promiseTracker()
        };

        vm.editAction = $scope.editAction;
        vm.fetchGrade = fetchGrade;
        vm.print = print;
        vm.adicionarAula = converteAdicionaAula;
        vm.removerAula = removerAula;
        vm.dragHelper = dragHelper;

        vm.currentPage = 1;
        vm.itemsPerPage = 5;
        vm.optionsItemsPerPage = [3, 5, 10];

        vm.searchColumns = [{
            id: 'descricao',
            label: 'Turma',
            model: 'descricao',
            filterable: true
		}];

        $scope.$watch('gradeHorarios', function() {
            activate();
        });

        function activate() {
            if (!$scope.gradeHorarios) {
                return;
            }

            vm.hasCreatePermission = !PermissionsService.isRevokedOperation('GradeHorariosPageMapping', 'criar');
            vm.hasEditPermission = !PermissionsService.isRevokedOperation('GradeHorariosPageMapping', 'editar');
            fetchGrade();
        }

        // workaround para tratar paginação. Atualmente a chamada do ui-pagination é efetuada com $timeout
        // portanto, foi feita esta rotina para efetuar a chamada diretamente da função de listagem
        vm.noop = angular.noop;
        $scope.$watchGroup(['vm.currentPage', 'vm.itemsPerPage'], function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            fetchGrade();
        });

        function fetchGrade() {
            clearTurmas();

            if (!$scope.hidePagination) {
                var filter = $searchExpressionBuilder.build(vm.searchFilter) || '';

                var params = {
                    filter: filter,
                    offset: vm.currentPage - 1,
                    limit: vm.itemsPerPage
                };

                params.offset = params.offset > 0 ? params.offset * params.limit : 0;
            }

            if (vm.readOnly) {
                fetchVigentes(params);
            } else {
                fectchGradeEdicao(params);
            }
        }

        function fetchVigentes(params) {
            vm.tracker.grade.addPromise(geracaoHorariosService
                .getGradeHorariosVigente($scope.gradeHorarios.id, params)
                .then(createGrade));
        }

        function fectchGradeEdicao(params) {
            vm.tracker.grade.addPromise(geracaoHorariosService
                .getGradeHorarios($scope.gradeHorarios.id, params)
                .then(createGrade));

            $scope.$on('drag:start', addHighlights);
            $scope.$on('drag:end', removeHighlights);
        }

        function createGrade(data) {
            geracaoHorariosService.getDiasDaSemana().then(function(weekDays) {
                var result = gradeService.createGrade(_.first(data.content), weekDays);

                vm.turmas = result.turmas;
                vm.diasDaSemana = result.diasDaSemana;
                vm.numeroAulas = result.numeroAulas;
                vm.aulasPossiveis = result.aulasPossiveis;

                buildPaginacao(data);
            });
        }

        function buildPaginacao(data) {
            vm.page = data;
            vm.currentPage = (data.offset / data.limit) + 1;
            vm.totalItems = data.total;
            vm.hasNext = data.hasNext;
            vm.totalContent = _.size(vm.turmas);
        }

        function clearTurmas() {
            if (vm.turmas) {
                vm.turmas = null;
            }
        }

        function print() {
            uiPrintService.print($element.find('.spread-sheet-grade-horarios'));
        }

        function converteAdicionaAula(dropOb) {
            if (vm.readOnly) {
                return;
            }
            var droppedAula = dropOb.dragObject.aula || dropOb.dragObject;
            var slotAula = dropOb.slotAula;

            var aula = angular.copy(droppedAula);
            aula.numero = slotAula.numero;
            aula.diaSemana = slotAula.diaSemana;

            var aulaParams = {
                turma: dropOb.turma,
                index: dropOb.index,
                oldAula: droppedAula,
                aula: aula
            };

            adicionarAula(aulaParams);
        }

        function adicionarAula(aulaParams) {
            blockAction(geracaoHorariosService.adicionarAula(aulaParams.aula))
                .then(function(aula) {
                    var aulaResultante = aula.plain();

                    if (aulaParams.aula.resolucaoConflito === 'SUBSTITUIR') {
                        activate();
                        return;
                    }

                    if (!aulaParams.oldAula.numero) {
                        vm.aulasPossiveis = gradeService.removerAulaPossivel(vm.aulasPossiveis, aulaResultante);
                    } else {
                        gradeService.removerAulaDaLista(aulaParams.turma, aulaParams.oldAula);
                    }

                    vm.turmas[aulaParams.turma.id].aulas[aulaParams.index] = aulaResultante;
                }, function(resultado) {
                    if (resultado.status === 429) {
                        $commons.$modal.open({
                            templateUrl: 'geracao-horarios/geracao-horarios-conflito-modal.html',
                            controller: 'educacao.geracaoHorarios.GeracaoHorariosConflitoModalController',
                            controllerAs: 'vm',
                            params: {
                                resultado: resultado.data,
                                aulaParams: aulaParams
                            }
                        }).result.then(function(aulaParamsResolved) {
                            adicionarAula(aulaParamsResolved);
                        });
                    }
                });
        }

        function removerAula(aulaDropped) {
            if (vm.readOnly) {
                return;
            }
            if (aulaDropped.turma) {
                var turma = vm.turmas[aulaDropped.turma.id];

                blockAction(geracaoHorariosService.removerAula(aulaDropped))
                    .then(function() {
                        gradeService.removerAulaDaLista(turma, aulaDropped);
                        gradeService.adicionarAulaPossivel(vm.aulasPossiveis, aulaDropped);
                    });
            }
        }

        function blockAction(promise) {
            var modal = $commons.$modal.open({
                template: ' ',
                params: {
                    p: $commons.$q.when(promise)
                        .catch(function() {
                            return $commons.$q.when();
                        })
                        .finally(function() {
                            modal.close();
                        })
                }
            });
            return promise;
        }

        function addHighlights(data, args) {
            var aula = args.dragObject.aula || args.dragObject;
            vm.dragTurmaId = aula.turma.id;

            removeHighlights();
            $element.find('#' + vm.dragTurmaId).addClass('highlight-grade-horarios');
            $element.find('.slot-aula').droppable('disable').addClass('noDrop');
            $element.find('.turma-' + vm.dragTurmaId).droppable('enable').removeClass('noDrop');
            $element.find('.canNotDrop').droppable('disable').addClass('noDrop');
        }

        function removeHighlights() {
            $element.find('.highlight-grade-horarios').removeClass('highlight-grade-horarios');
            $element.find('.slot-aula').droppable('enable').removeClass('noDrop');
        }

        function dragHelper(dragObject) {
            return '<div class="ui-widget-header aula-drag-smaller text-center ellipsis" ' +
                'title="{{dragObject.origem.sigla}}" style="background-color: #' + dragObject.cor + '">' +
                '<small>' + dragObject.origem.sigla + '</small></div>';
        }

    }
})();

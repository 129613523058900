(function() {

    'use strict';

    angular.module('educacao.educacenso')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.educacenso', {
            url: 'educacenso',
            data: {
                id: 'EducacensoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'educacenso/educacenso.html',
                    controller: 'educacao.educacenso.EducacensoController as vm'
                }
            }
        });
    }

})();


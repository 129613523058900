(function() {
    'use strict';

    angular.module('educacao.pessoas').directive('appCid', SelectCid);

    function SelectCid() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)" format-selection="vm.formatSelection(data)" multiple/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                sexo: '='
            },
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function($scope) {
                        $scope.multiple = multiple;
                    }
                };
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['ui.components.Modal', 'educacao.pessoas.CidService', '$scope'];

    function Controller($modal, service, $scope) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;

        function search(params) {
            var filter = '( descricao like "' + params.term + '" or id like "' + params.term + '"';

            if ($scope.ngModel && $scope.ngModel.length) {
                var deficiencias = [];
                $scope.ngModel.forEach(function(value) {
                    deficiencias.push(value.id);
                });
                if (deficiencias.length) {
                    filter += ' and id not in("' + deficiencias.join() + '")';
                }
            }
            if ($scope.sexo) {
                filter += ' and (sexoRestricao = "' + $scope.sexo + '" or sexoRestricao = "INDIFERENTE")';
            }
            filter += ')';

            return service.list(filter, params.offset, params.limit);
        }

        function getLabelClassificacao(classificacao) {
            var title = getDescricaoClassificacao(classificacao);
            var color = classificacao === 'CID_10' ? 'bg__yellow--d10' : 'bg__green';
            return '<div class="badge text-right ' + color + '">' + title + '</div>';
        }

        function getDescricaoClassificacao(classificacao) {
            return classificacao === 'CID_10' ? 'CID-10' : 'CID-11';
        }

        function formatResult(data) {
            return (
                '<div class="row bottom" style="margin-right: 2px">' +
                    '<div class="col-xs-12 col-sm-11 col-md-11 hidden-overflow">' +
                        data.id + ' - ' + data.descricao +
                    '</div>' +
                    '<div class="col-sm-1 col-md-1 text-center hidden-xs">' +
                        getLabelClassificacao(data.classificacao) +
                    '</div>' +
                '</div>'
            );
        }

        function formatSelection(data) {
            return (
                '<div class="row bottom" style="margin-right: 2px">' +
                    '<div class="col-xs-12 col-sm-11 col-md-11 hidden-overflow">' +
                        data.id + ' - ' + getDescricaoClassificacao(data.classificacao) + ' - ' +data.descricao +
                    '</div>' +
                '</div>'
            );
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.rematricula')
        .directive('stepRematricular', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'rematricula/step-rematricular.directive.html',
            scope: {
                rematriculas: '=',
                permiteTrocarEstabelecimento: '='
            }
        };
    }

})();


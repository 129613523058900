(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.OcupacaoAulaUtilsService', OcupacaoAulaUtilsService);

    function OcupacaoAulaUtilsService() {
        var vm = this;
        vm.getSumByTipoSaldo = getSumByTipoSaldo;


        function getSumByTipoSaldo(saldos, tipoSaldo) {
            return _.chain(saldos)
                    .map(tipoSaldo)
                    .sum()
                    .value();
        }
    }

})();


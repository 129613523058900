(function() {
    'use strict';

    var cores = {
        RED: 'red',
        ORANGE: 'orange',
        YELLOW: 'yellow',
        GREENA: 'greenA',
        GREENB: 'greenB',
        BLUEA: 'blueA',
        BLUEB: 'blueB',
        BLUEC: 'blueC',
        PURPLEA: 'purpleA',
        PURPLEB: 'purpleB',
        VIOLETA: 'violetA',
        CRIMSON: 'crimson',
        GRAY: 'gray'
    };

    angular.module('educacao.calendario')
        .constant('Cores', cores)
        .service('ColorHelper', ColorHelper);

    function ColorHelper() {
        return {
            getColorClass: getColorClass,
            getTextColorClass: getTextColorClass
        };

        function getColorName(enumCor) {
            return cores[enumCor.substr(0,enumCor.length-1).toUpperCase()];
        }

        function getColorIntensity(enumCor) {
            return /\d+/.exec(enumCor)[0];
        }

        function getColorClass(keyEnumCor, intensity) {
            intensity = intensity || getColorIntensity(keyEnumCor);

            return getColorName(keyEnumCor) + intensity;
        }

        function getTextColorClass(keyEnumCor, intensity) {
            intensity = intensity || getColorIntensity(keyEnumCor);

            return 'text-' + getColorName(keyEnumCor) + intensity;
        }
    }
})();

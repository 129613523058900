(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = [
        'educacao.historico.HistoricoServiceProvider', 
        'educacao.common.$commonsProvider', 
        'educacao.matricula.ReclassificacaoServiceProvider',
        'bfc.NotificationProvider'];

    function HistoricoConfig(HistoricoServiceProvider, $commonsProvider, $reclassificacaoServiceProvider, Notification) {

        HistoricoServiceProvider.addTipoHandler('RECLASSIFICACAO', {
            badge: {
                icon: 'fa-exchange',
                tooltip: 'Reclassificando(a)'
            },
            template: '<small>{{descricaoTipo.valor}} em <strong>{{dataMovimentacao.valor}}</strong><small>',
            campos: {
                observacao: {
                    insercao: {
                        template: '<br><small>Observação: <strong>{{valor}}</strong></small><br>'
                    }
                },
                destinoReclassificacao: {
                    insercao: {
                        template: '<br><small>Destino reclassificação: <strong>{{valor}}</strong></small><br>'
                    }
                }
            },
            openEdicaoReclacificacao: openEdicaoReclassificacao,
            actionTemplates:{
                actionTemplate: [ '<button class="btn btn-xs btn-default" ' +
                'title="Editar reclassificação" ' +
                // 'ng-if="tipo.valor!=\'RECLASSIFICACAO\'" ' +
                'ng-click="handler.openEdicaoReclacificacao(matricula,idReclassificacao.valor)">' +

                '<i class="fa fa-pencil"></i></button>']
            }
        });

        function openEdicaoReclassificacao(matricula, idReclassificacao) {
            $reclassificacaoServiceProvider.$get().recuperaDto(matricula, idReclassificacao)
              .then(function(data){
                $commonsProvider.$get().$modal.open({
                    templateUrl: 'matricula/matriculas/reclassificacao/reclassificacao-modal.html',
                    controller: 'educacao.matricula.ReclassificacaoModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    params: {
                        matricula: matricula,
                        idReclassificacao: idReclassificacao,
                        update: true,
                        data: data 
                    }
                });
            }).catch(function(){
                Notification.$get().publish('Esta movimentação de reclassificação foi desfeita e não está mais disponível para alteração.', 'warn');
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matriz').controller('educacao.matriz.ChaCadCtrl', Controller);

    Controller.$inject = ['educacao.matriz.chaService', 'promiseTracker', 'bfc.Focus', '$modalInstance', 'bfc.Notification', ];

    function Controller(chaService, promiseTracker, focus, $modalInstance, Notification) {
        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.save = save;
        vm.remove = remove;
        vm.cancel = fechaModal;
        vm.cha = $modalInstance.params.cha || {};
        vm.enumTipoCHA = chaService.getTipoCompetenciaCHA();
        vm.isTipoHABILIDADE = isTipoHABILIDADE;
        vm.onSelectIdentificadorRecente = onSelectIdentificadorRecente;

        init();

        function init() {
            verificaFormulario();
            vm.adicionando = Boolean(_.get(vm.cha, 'id'));
            vm.canSaveAdd = $modalInstance.params.canSaveAdd;
            vm.disableTipo = $modalInstance.params.disableTipo;
            vm.windowTitle = vm.adicionando ? 'Adicionando CHA' : 'Editando CHA';
            focus('lbTipoCHA');
            getDescricoesRecentes();
        }

        function getDescricoesRecentes() {
            var promise = chaService.getDescricoesRecentes()
                .then(function(data) {
                    vm.identificacoesRecentes = data;
                });

            vm.tracker.loading.addPromise(promise);
        }

		function onSelectIdentificadorRecente(identificador) {
			vm.cha.identificador = identificador;
		}

        function save(continuar) {
            var cha = _.cloneDeep(vm.cha);
            if (!isTipoHABILIDADE()) {
                delete cha.objetoConhecimento;
            }

            vm.tracker.loading.addPromise(
                chaService.save(cha)
                .then(
                    function(dataRetorno) {
                        onSuccess(continuar, dataRetorno);
                        mensagenSuccess('salvo');
                    }
                )
            );
        }

        function remove() {
            vm.tracker.loading.addPromise(
                chaService.remove(vm.cha)
                .then(
                    function() {
                        fechaModal();
                        mensagenSuccess('removido');
                    }
                )
            );
        }

        function onSuccess(continuar, data) {
            if (continuar) {
                vm.cha = {};
                focus('lbTipoCHA');
            } else {
                $modalInstance.close(data);
            }
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function mensagenSuccess(mensagem) {
            Notification.publish('CHA ' + mensagem + ' com sucesso.', 'success');
        }

        function verificaFormulario() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }

        function isTipoHABILIDADE() {
            return Boolean(_.get(vm.cha, 'tipo') === 'HABILIDADE');
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .directive('appSaldoEscola', appSaldoEscolaDirective);


    function appSaldoEscolaDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/app-saldo-escola/app-saldo-escola.directive.html',
            scope: {},
            bindToController: {
                saldoEstabelecimento: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        
    ];

    function Controller() {
        var vm = this;

        vm.onClickCard = onClickCard;
        vm.getSaldos = getSaldos;

        function onClickCard(estabelecimento) {
            vm.estabelecimentoSelected = {
                id: estabelecimento.id,
                descricao: estabelecimento.descricao,
                brasao: estabelecimento.brasao
            };
        }

        function getSaldos(estabelecimento) {
            if(!estabelecimento) {
                return [];
            }
            
            return [
                { label: 'Saldo Inicial', saldo: estabelecimento.saldoInicial },
                { label: 'Saldo Ocupado', saldo: estabelecimento.saldoOcupado },
                { label: 'Saldo Atual', saldo: estabelecimento.saldoFinal }
            ];
        }
        
    }

})();
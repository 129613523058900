(function () {

    'use strict';

    angular.module('educacao.configuracao-evasao-reprovacao')
        .directive('appEtapaTreinamento', appConfiguracaoListaEsperaMensagem);

    function appConfiguracaoListaEsperaMensagem() {
        return {
            restrict: 'E',
            templateUrl: 'configuracao-evasao-reprovacao/app-etapa-treinamento/app-etapa-treinamento.directive.html',
            scope: {},
            bindToController: {
                dadosVerificacao: '=',
                lote: '=',
                resultados: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$configuracaoEvasaoReprovacaoUtilsService',
        'educacao.configuracao-evasao-reprovacao.ConfiguracaoEvasaoReprovacaoService'
    ];

    function Controller($scope, promiseTracker, $configuracaoEvasaoReprovacaoUtilsService, ConfiguracaoEvasaoReprovacaoService) {
        var vm = this;

        vm.tracker = {
            verificacao: promiseTracker()
        };

        $scope.$watch('vm.dadosVerificacao', onLoadVerificacao);

        function onLoadVerificacao(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (!vm.dadosVerificacao.enableEvasao && !vm.dadosVerificacao.enableReprovacao) {
                vm.disabled = true;
            }

            verificaLote();
        }

        function verificaLote() {
            if (vm.disabled) {
                return;
            }

            if ($configuracaoEvasaoReprovacaoUtilsService.isProcessed(vm.lote)) {
                vm.treinamentoFinalizado = true;
            }

            if ($configuracaoEvasaoReprovacaoUtilsService.isQueue(vm.lote)) {
                vm.treinamentoEmFila = true;
            }

            gerarLote();
            getResultados();
        }

        function gerarLote() {
            if (vm.treinamentoFinalizado || vm.treinamentoEmFila) {
                return;
            }
            return ConfiguracaoEvasaoReprovacaoService.criarLote();
        }

        function getResultados() {
            if (!vm.treinamentoFinalizado || !vm.resultados) {
                return;
            }

            var resultado = $configuracaoEvasaoReprovacaoUtilsService.getPercentualFScore(vm.resultados);
            vm.percentualEvasao = resultado.percentualEvasao;
            vm.percentualReprovacao = resultado.percentualReprovacao;

            vm.isEvasaoHabilitado = $configuracaoEvasaoReprovacaoUtilsService.isResultEnabled(vm.resultados.evasao);
            vm.isReprovacaoHabilitado = $configuracaoEvasaoReprovacaoUtilsService.isResultEnabled(vm.resultados.reprovacao);
        }
    }
})();

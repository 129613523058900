(function() {
	'use strict';

	angular.module('educacao.matricula').controller('educacao.matricula.SelecaoDivisaoEnturmacaoProfessorListCtrl',
		SelecaoDivisaoEnturmacaoProfessorListCtrl);

	SelecaoDivisaoEnturmacaoProfessorListCtrl.$inject = ['$modalInstance'];

	function SelecaoDivisaoEnturmacaoProfessorListCtrl($modalInstance) {
		var vm = this;

		vm.enturmacoes = $modalInstance.params.enturmacoes;
		vm.divisoes = $modalInstance.params.divisoes;

		vm.enturmar = enturmar;
		vm.getComponente = getComponente;
		vm.getComponenteDescricao = getComponenteDescricao;
		vm.getDivisaoByEnturmacao = getDivisaoByEnturmacao;
		vm.hasManyDivisaoByEnturmacao = hasManyDivisaoByEnturmacao;
		vm.hasturmaInDivisoes = hasturmaInDivisoes;

		function getDivisaoByEnturmacao(enturmacao, turmaId) {
			var componente = getComponente(enturmacao);
			var divisao = _.find(vm.divisoes, {turma: turmaId, componente: componente});

			if (divisao) {
				return divisao.data;
			}
			return null;
		}

		function hasManyDivisaoByEnturmacao(enturmacao, turmaId) {
			return !!getDivisaoByEnturmacao(enturmacao, turmaId);
		}
		
		function getComponente(enturmacao) {
			return _.get(enturmacao, 'eixoTematico.id') || _.get(enturmacao, 'disciplina.id') || _.get(
				enturmacao, 'servidor.id');
		}

		function getComponenteDescricao(enturmacao) {
			return _.get(enturmacao, 'eixoTematico.descricao') || _.get(enturmacao, 'disciplina.descricao') || _.get(
				enturmacao, 'servidor.descricao');
		}
		

		function enturmar() {
			$modalInstance.close(vm.enturmacoes);
		}

		function hasturmaInDivisoes(turmaId) {
			return !!_.find(vm.divisoes, { turma: turmaId });
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo')
        .controller('educacao.lista-espera-novo.ListaEsperaCtrl', Controller);

    Controller.$inject = [
        '$stateParams',
        'educacao.lista-espera-novo.encaminhado.EncaminhadoService',
        'bfc.$$PermissionsService',
        'ui.components.Modal',
        '$scope'
    ];

    function Controller($stateParams, EncaminhadoService, PermissionsService, uiModal, $scope) {
        var vm = this;

        vm.tela = $stateParams.tela; 

        $scope.$on('totalEncaminhados', function (event, args) {
            vm.totalEncaminhados = args;
        });

        vm.openModalBuscarCandidato = openModalBuscarCandidato;

        init();

        function init() {  
            EncaminhadoService.setTotal();

            vm.hasPermissions = {
                inscricao: !PermissionsService.isRevokedOperation('InscricaoListaEsperaNovaPageMapping', 'listar'),
                encaminhado: PermissionsService.checkPermissionForPageId('EncaminhamentoListaEsperaNovaPageMapping'),
                configuracao: PermissionsService.checkPermissionForPageId('ConfiguracaoParaListaEsperaPageMapping')
            };
        }

        function openModalBuscarCandidato() {
            uiModal.open({
                templateUrl: 'lista-espera-new/configuracao/configuracao-lista-espera-buscar-candidato/configuracao-lista-espera-buscar-candidato.html',
                controller: 'educacao.lista-espera-novo.inscricao.ConfiguracaoListaEsperaBuscarCandidato',
                controllerAs: 'vm',
                size: 'xxl'
            });
        }
    }
})();

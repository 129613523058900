(function() {
	'use strict';

	angular.module('educacao.common')
		.filter('appFilterGeneric', filter);

	filter.$inject = ['educacao.common.FilterGeneric'];

	function filter(FilterGeneric) {
		return format;

		function format(input, value) {
			if (_.isNull(input) || _.isUndefined(input) || _.isUndefined(value)) {
				return '';
			}

			return FilterGeneric.getFilter(input, value);
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('indeterminate', directive);

    function directive() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: postLink
        };
    }

    function postLink($scope, $element, $attrs, ngModelController) {
        ngModelController.$parsers = [];

        ngModelController.$formatters.push(function (value) {
            $element.prop('indeterminate', value === undefined);
            return value;
        });
    }
})();
(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosLocaisTrabalhosProfessoresGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-professores-gestao-pessoas/table-dados-professores-gestao-pessoas/table-dados-locais-trabalhos-professores-gestao-pessoas/table-dados-locais-trabalhos-professores-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                professor: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
                isProfessoresSubstituidos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        'promiseTracker'
    ];

    function Controller(Service, promiseTracker) {
        var vm = this;
        vm.formatCargaHoraria = formatCargaHoraria;
        vm.formatAtribuicoesAulas = formatAtribuicoesAulas;
        vm.getFieldLocalTrabalho = getFieldLocalTrabalho;

        vm.loading = {
            table: promiseTracker(),
            popoverCargaHoraria: promiseTracker(),
            popoverTurmas: promiseTracker(),
        };

        function formatCargaHoraria(cargaHorariaFuncionario) {
            if (!cargaHorariaFuncionario) {
                return '-';
            }

            var duration = moment.duration(cargaHorariaFuncionario);
            var hours = Math.floor(duration.asHours());
            var minutes = duration.minutes();
            return hours + 'h ' + minutes + 'm';
        }

        function formatAtribuicoesAulas(localTrabalho) {
            return localTrabalho.atribuicoesAulas.atribuicoesAulas + ' de ' + localTrabalho.atribuicoesAulas.quantidadeTotalAulas + ' aulas';
        }

        function getFieldLocalTrabalho(localTrabalho) {
            if (_.isNull(localTrabalho.estabelecimento)) {
                return 'Secretaria';
            }
            return localTrabalho.estabelecimento.nome;
        }
    }

})();

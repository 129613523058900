(function () {

    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ProfessoresAuxiliaresController', Controller);

    Controller.$inject = ['$modalInstance'];

    function Controller($modalInstance) {
        var vm = this;

        vm.professoresAuxiliares = $modalInstance.params.professoresAuxiliares;
        vm.estabelecimento = $modalInstance.params.estabelecimento;

        vm.excluir = excluir;
        vm.salvar = salvar;

        function excluir() {
            vm.professoresAuxiliares = [];
            salvar();
        }

        function salvar() {
            $modalInstance.close(vm.professoresAuxiliares);
        }
    }
})();
(function() {

    'use strict';

    angular.module('educacao.pessoas')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.planejamento.responsaveis';
        $stateProvider.state(STATE, {
            url: 'responsaveis',
            data: {
                id: 'ResponsavelPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'planejamento/responsaveis/listagem-responsaveis.html',
                    controller: 'educacao.pessoas.ResponsaveisController',
                    controllerAs: 'vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });
    }

    openModal.$inject = [
        '$stateParams',
        'educacao.pessoas.ResponsavelService',
        '$modalStates'
    ];

    function openModal($stateParams, ResponsavelService, $modalStates) {
        $modalStates.open({
            getMethod: ResponsavelService.get,
            templateUrl: 'planejamento/responsaveis/cadastro-responsaveis.html',
            controller: 'educacao.pessoas.ResponsavelCadCtrl as vm',
            propertyName: 'responsavel',
            size: 'xl',
            stateParams: $stateParams
        });
    }

})();

(function () {
  'use strict';

  angular
    .module('educacao.matricula')
    .directive('appFiltroAvancadoAluno', Directive);

  function Directive() {
    return {
      restrict: 'E',
      templateUrl:
        'matricula/matriculas/filtro-avancado/aluno/aluno.directive.html',
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        expressionCustomBuilders: '=',
        advancedFilter: '=',
        contadorFiltroPadraoAluno : '=',
        contadorDocumentos: '=',
        contadorDadosPessoais: '=',
        contadorCaracteristicasFisicas: '='
      },
      bindToController: true
    };
  }

  Controller.$inject = [];

  function Controller() {}
})();

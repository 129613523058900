(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.AtividadeAEEService', AtividadeAEEService);

    AtividadeAEEService.$inject = ['GenericService', 'Restangular'];

    function AtividadeAEEService(GenericService, Restangular) {

        function getById(id) {
            return Restangular.one('matricula').one('atividade-aee', id).get()
                .then(function(data) {
                    return data;
                });
        }

        function getDefaultByCodInep(codigoInep) {
            var params = {
                filter: 'databaseId is null and codigoInep = ' + codigoInep,
                sort: 'codigoInep'
            };
            return Restangular.one('matricula').all('atividade-aee').get('', params)
                .then(function(data) {
                    return _.first(data);
                });
        }

        return GenericService
            .createWithCustomMethods({
                getById: getById,
                getDefaultByCodInep: getDefaultByCodInep
            })
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'atividade-aee'
            });
    }
})();

(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizConfigOrgCtrl', ['$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
        'educacao.common.ModalCad',
        'educacao.matriz.CursoService',
        'educacao.matriz.EtapaService',
        'educacao.matriz.MatrizService',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.EtapaDisciplinaService',
        'educacao.matriz.CursoConcomitanteService',
        'educacao.matriz.MatrizPreRequisitoService',
        'bfc.Notification',
        'fnCad',
        'id',
        'optionsCad',
        'promiseTracker',
        'ui.components.Modal',

        function ($scope, $rootScope, $q, $modalInstance, $modalTracker,
            ModalCad,
            CursoService,
            EtapaService,
            MatrizService,
            ObjectUtilsService,
            EtapaDisciplinaService,
            CursoConcomitanteService,
            MatrizPreRequisitoService,
            Notification,
            fnCad,
            id,
            optionsCad,
            promiseTracker,
            $modal
        ) {

            var BASE_NACIONAL_COMUN = 1;


            $scope.tracker = {};
            $scope.tracker.save = promiseTracker();
            $scope.tracker.preRequisito = promiseTracker();
            $scope.tracker.carregarEtapas = promiseTracker();
            $scope.tracker.carregarMatriz = promiseTracker();
            $scope.getMensagemExclusao = getMensagemExclusao;
            $scope.setEnuns = setEnuns;

            init();

            function init() {
                $modalTracker.addPromise(
                    $q.all([

                        MatrizService.get(id).then(function (data) {
                            $scope.matriz = data;

                            atualizarEtapas();

                            CursoConcomitanteService.getList(id)
                                .then(function (result) {
                                    $scope.cursosConcomitantes = result;
                            });
                        })
                    ])
                );
                CursoService.getEnums().then(function (data) {
                    $scope.enuns = data;
                });
                // $scope.cursoSelect = CursoSelect.select2Config({
                //     result: function (params, callback) {

                //         var term = ObjectUtilsService.normalizeFilter(params.term);
                //         term = encodeURI(term);

                //         var result;
                //         params.filter = '(descricao like "' + term + '")';

                //         result = CursoService.getListByParams(params);
                //         result.then(function (data) {
                //             data = _.reject(data, function (curso) {
                //                 return _.find($scope.matriz.cursosRequisito, function (cursoSelecionado) {
                //                     return !cursoSelecionado ? false : cursoSelecionado.id === curso.id;
                //                 }) || $scope.matriz.curso.id === curso.id;
                //             });

                //             callback({
                //                 results: data,
                //                 more: false
                //             });
                //         });

                //         return result;
                //     }
                // });

            }

            function setEnuns(){
                if ( $scope.matriz.cursoPreRequisito ){
                    $scope.matriz.cursoPreRequisito.nivelModalidade = {'descricao' : getEnun('NivelModalidade',$scope.matriz.cursoPreRequisito.nivelModalidade)};
                    $scope.matriz.cursoPreRequisito.nivelEscolar = {'descricao' : getEnun('NivelEscolar', $scope.matriz.cursoPreRequisito.nivelEscolar)};
                }
            }

            function getEnun(prop, value) {
                if (prop && value) {
                    var array = _.get($scope.enuns, prop);
                    var result = _.find(array, { 'key': value }, array);
                    return result.description;
                }
            }

            $scope.hasEtapas = function () {
                return $scope.matriz.etapas.length > 0;
            };

            function atualizarEtapas() {

                if ($scope.hasEtapas()) {
                    var etapaTemp;
                    if ($scope.etapaCurrent) {
                        etapaTemp = _.find($scope.matriz.etapas, {
                            'id': $scope.etapaCurrent.id
                        });
                    }

                    $scope.setEtapa(etapaTemp ? etapaTemp : $scope.matriz.etapas[0]);
                    $scope.lastEtapa = _.last($scope.matriz.etapas);
                } else {
                    $scope.disciplinas = [];
                    $scope.disciplinasAgrupadas = [];
                }
            }

            $scope.setEtapa = function (etapa) {

                if (angular.isUndefined($scope.etapaCurrent) || $scope.etapaCurrent.id !== etapa.id) {

                    $scope.etapaCurrent = etapa;

                    $scope.tracker.carregarEtapas.addPromise(
                        EtapaDisciplinaService.getList($scope.matriz.id, $scope.etapaCurrent.id).then(function (data) {
                            $scope.disciplinas = data;
                            agruparDisciplinas();
                        })
                    );
                } else {
                    $scope.etapaCurrent = etapa;
                }
            };



            function agruparDisciplinas() {

                var total = _.reduce($scope.disciplinas, function (totalTemp, value) {
                    return totalTemp += value.cargaHorariaHoras * 60 + value.cargaHorariaMinutos;
                }, 0);

                if ($scope.isDisciplinas()) {
                    var grupos = _.values(_.groupBy($scope.disciplinas, function (disciplina) {
                        return 'orientacao:' + (disciplina.orientacaoCurricular ? disciplina.orientacaoCurricular.descricao : '');
                    }));
                    for (var i = 0; i < grupos.length; i++) {
                        grupos[i].orientacaoCurricular = (grupos[i][0].orientacaoCurricular ? grupos[i][0].orientacaoCurricular.descricao : '');
                        grupos[i].id = i;
                    }

                } else {
                    var gruposEixo = _.values(_.groupBy($scope.disciplinas, function () {
                        return 'orientacao: eixoTematico';
                    }));

                }


                var totalHoras = Math.floor(total / 60);
                var totalMinutos = Math.floor(total - totalHoras * 60);

                $scope.totalHoras = totalHoras < 10 ? '0' + totalHoras : totalHoras;
                $scope.totalMinutos = totalMinutos < 10 ? '0' + totalMinutos : totalMinutos;

                $scope.cargaHorariaValida = Boolean($scope.matriz.curso.cargaHorariaMinimaHoras) &&
                    (totalHoras < $scope.matriz.curso.cargaHorariaMinimaHoras ||
                        totalHoras === $scope.matriz.curso.cargaHorariaMinimaHoras &&
                        totalMinutos < $scope.matriz.curso.cargaHorariaMinimaMinutos
                    );

                if ($scope.isDisciplinas()) {
                    $scope.disciplinasAgrupadas = grupos;
                } else {
                    $scope.disciplinasAgrupadas = gruposEixo;
                }

            }


            function errosFrom(entity) {
                if (entity) {
                    return _.flatten(_.values(entity.avisos), true);
                }
                return [];
            }

            $scope.possuiErros = function (entity) {
                return errosFrom(entity).length > 0;
            };

            $scope.$on('$destroy', function () {
                recarregaMatriz();
            });

            $scope.isDisciplinas = function () {
                if (angular.isUndefined($scope.matriz)) {
                    return false;
                }
                return !$scope.matriz.tipoOrganizacao || $scope.matriz.tipoOrganizacao === 'DISCIPLINA';
            };

            $scope.isCampoExperiencias = function () {
                if (angular.isUndefined($scope.matriz)) {
                    return false;
                }
                
                return $scope.matriz.tipoOrganizacao === 'CAMPOS_EXPERIENCIA';
            };

            $scope.getErros = function (entity) {
                if (entity && $scope.possuiErros(entity)) {
                    var alerts = _.pluck(errosFrom(entity), 'alert');

                    var message = '';

                    if (alerts.length === 1) {
                        message += alerts[0];
                    } else if (alerts.length > 0) {
                        var org = $scope.matriz.curso.organizacao.id === -1 ? 'etapa' : $scope.matriz.curso.organizacao.descricao;
                        if ($scope.matriz.curso.organizacao.noun.gender === 'MALE') {
                            message += 'Há inconsistências neste ' + org;
                        } else {
                            message += 'Há inconsistências nesta ' + org;
                        }
                    }

                    return message;
                }
            };

            $scope.descricaoEtapa = function (matriz, etapa) {
                if (matriz && etapa) {
                    return MatrizService.getDescricaoEtapa(matriz, etapa);
                }
            };

            $scope.descricaoOrganizacao = function (matriz) {
                return matriz.curso.organizacao.id < 0 ? 'etapa' : matriz.curso.organizacao.descricao.toLowerCase();
            };

            function atualizarMatrizSeNecessario(newValue, oldValue) {

                var recarregar = false;

                var newEquivalencias = newValue.equivalencias;
                var oldEquivalencias = oldValue.equivalencias;
                if (angular.isArray(newEquivalencias) && angular.isArray(oldEquivalencias)) {

                    var eq = _.isEqual(
                        _.sortBy(_.pluck(newEquivalencias, 'id')),
                        _.sortBy(_.pluck(oldEquivalencias, 'id'))
                    );

                    if (eq === false) {
                        recarregar = true;
                    }
                }

                if (newValue.quantidadeDependencias !== oldValue.quantidadeDependencias) {
                    recarregar = true;
                }

                if (!_.isEqual(newValue.etapaInep, oldValue.etapaInep)) {
                    recarregar = true;
                }
                if (recarregar === true) {
                    recarregaMatriz();
                }

            }

            function atualizarDisciplinasSeNecessario(newValue, oldValue) {

                if (newValue !== oldValue) {
                    $scope.tracker.carregarEtapas.addPromise(
                        EtapaDisciplinaService.getList($scope.matriz.id, $scope.etapaCurrent.id).then(function (data) {
                            $scope.disciplinas = data;
                            agruparDisciplinas();
                        })
                    );
                }
            }

            function recarregarHorasEJA() {
                //se a matriz for eja é necessário recarregar as horas
                if ($scope.matriz.curso.nivelModalidade.modular) {
                    recarregaMatriz();
                }

            }

            function recarregaMatriz() {
                $scope.tracker.carregarMatriz.addPromise(
                    MatrizService.get(id).then(function (data) {
                        $rootScope.$broadcast('refreshMatrizCurricular', {
                            data: data,
                            edit: true
                        });
                    }));
            }

            function recarregaEtapaCurrent(etapaId) {
                EtapaService.get(id, etapaId).then(function (data) {
                    $scope.etapaCurrent = data;

                    var etapa = _.find($scope.matriz.etapas, {
                        'id': etapaId
                    });

                    if (etapa) {
                        ObjectUtilsService.copyProperties(etapa, data);
                    } else {
                        throw new Error('Etapa não encontrado! ID: ' + data.id);
                    }

                });
            }

            $scope.$on('refreshEtapasMatriz', function (event, args) {

                if (args.edit || args.undo) {
                    var etapa = _.find($scope.matriz.etapas, {
                        'id': args.data.id
                    });


                    if (etapa) {
                        atualizarMatrizSeNecessario(etapa, args.data);
                        ObjectUtilsService.copyProperties(etapa, args.data);
                        atualizarEtapas();
                    } else {
                        throw new Error('Etapa não encontrado! ID: ' + args.data.id);
                    }

                } else if (args.remove) {

                    $scope.matriz.etapas = _.reject($scope.matriz.etapas, {
                        'id': args.data.id
                    });

                    recarregaMatriz();

                } else if (args.add) {
                    $scope.matriz.etapas.push(args.data);
                    atualizarEtapas();
                    recarregarHorasEJA();
                }

            });

            $scope.$on('refreshMatrizCurricular', function (event, args) {
                if (args.remove) {
                    $scope.cancel();
                } else if (args.undo || args.edit) {
                    atualizarDisciplinasSeNecessario(_.get($scope.matriz,'modular'), args.data.modular);
                    $scope.matriz = args.data;
                    atualizarEtapas();
                }
            });

            $scope.$on('refreshEtapasDisciplinas', refreshEtapasDisciplinas);

            function refreshEtapasDisciplinas(event, args) {
                if (!args) {
                    return;
                }

                if (args.data) {
                    if (args.etapa.id === $scope.etapaCurrent.id) {
                        if (args.remove) {
                            $scope.disciplinas = _.reject($scope.disciplinas, {
                                'id': args.data.id
                            });
                        } else if (args.add) {
                            $scope.disciplinas.push(args.data);
                        } else if (args.undo || args.edit) {
                            var disciplina = _.find($scope.disciplinas, {
                                'id': args.data.id
                            });

                            if (disciplina) {
                                ObjectUtilsService.copyProperties(disciplina, args.data);
                            } else {
                                throw new Error('Disciplina não encontrado! ID: ' + args.data.id);
                            }
                        }
                        recarregaEtapaCurrent(args.etapa.id);
                        agruparDisciplinas();
                    } else if (args.remove) {
                        if (_.findIndex($scope.matriz.etapas, {
                            'id': args.etapa.id
                        }) > 0 && args.data.orientacaoCurricular.id === BASE_NACIONAL_COMUN) {

                            $scope.disciplinas = _.reject($scope.disciplinas, {
                                'disciplina': {
                                    'id': args.data.disciplina.id
                                }
                            });

                            recarregaEtapaCurrent($scope.etapaCurrent.id);
                            agruparDisciplinas();
                        }
                    }
                }
                if (args.remove) {
                    var mensagem = '';

                    if ($scope.isDisciplinas()) {
                        mensagem = 'Componente curricular ' + args.data.disciplina.descricao + ' excluído com sucesso';
                    } else if ($scope.isCampoExperiencias()) {
                        mensagem = 'Campo de experiência ' + args.data.campoExperiencia.descricao + ' excluído com sucesso';
                    } else {
                        mensagem = 'Eixo temático ' + args.data.eixoTematico.descricao + ' excluído com sucesso';
                    }

                    Notification.publish(mensagem, 'success');
                }
                recarregarHorasEJA();
            }

            $scope.$on('refreshCursosConcomitantes', function (event, args) {
                if (args.add) {
                    $scope.cursosConcomitantes.push(args.data);
                } else {
                    var concomitante = _.find($scope.cursosConcomitantes, {
                        'id': args.data.id
                    });

                    if (concomitante) {
                        ObjectUtilsService.copyProperties(concomitante, args.data);
                    } else {
                        throw new Error('Curso concomitante não encontrado! ID:' + args.data.id);
                    }
                }
            });
            $scope.$on('refreshCursosPreRequisito', function (event, args) {
                if (args.add) {
                    $scope.matriz.cursosRequisito.push(args.data);
                } else if (args.remove) {
                    $scope.matriz.cursosRequisito = _.reject($scope.matriz.cursosRequisito, {
                        'id': args.data.id
                    });

                }
            });


            $scope.cancel = function () {
                recarregaMatriz();
                $modalInstance.close();
            };

            $scope.remove = function (matriz, disciplina) {

                EtapaDisciplinaService.remove(matriz, disciplina.etapa.id, disciplina.id).then(function () {
                    $rootScope.$broadcast('refreshEtapasDisciplinas', {
                        data: disciplina,
                        etapa: disciplina.etapa,
                        remove: true
                    });
                });
            };

            $scope.removerUltimaEtapa = function (matriz, etapa) {
                if ($scope.matriz.id === matriz.id) {

                    $scope.tracker.carregarEtapas.addPromise(
                        EtapaService.remove(matriz.id, etapa.id).then(function () {
                            $rootScope.$broadcast('refreshEtapasMatriz', {
                                data: {
                                    id: etapa.id
                                },
                                remove: true
                            });
                        })
                    );

                }
            };

            $scope.adicionarEtapa = function (matriz) {

                if ($scope.matriz.id === matriz.id) {

                    $scope.tracker.carregarEtapas.addPromise(
                        EtapaService.save(matriz.id, {}).then(function (data) {
                            $rootScope.$broadcast('refreshEtapasMatriz', {
                                data: data,
                                add: true
                            });
                        })
                    );

                }
            };


            $scope.sequenciaNumerica = function (matrizId) {
                $scope.tracker.carregarEtapas.addPromise(
                    MatrizService.sequenciaNumerica(matrizId).then(function (data) {
                        $rootScope.$broadcast('refreshMatrizCurricular', {
                            data: data,
                            edit: true
                        });
                    })
                );
            };

            /*    $scope.sequenciaAlfabetica = function (matrizId) {
                    $scope.tracker.carregarEtapas.addPromise(
                        MatrizService.sequenciaAlfabetica(matrizId).then(function (data) {
                            $rootScope.$broadcast('refreshMatrizCurricular', {
                                data: data,
                                edit: true
                            });
                        })
                    );
                };*/

            /*  $scope.sequenciaRomana = function (matrizId) {
                  $scope.tracker.carregarEtapas.addPromise(
                      MatrizService.sequenciaRomana(matrizId).then(function (data) {
                          $rootScope.$broadcast('refreshMatrizCurricular', {
                              data: data,
                              edit: true
                          });
                      })
                  );
              }; */

            $scope.open = function () {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.cad.html',
                    controller: 'educacao.matriz.MatrizCadCtrl as vm',
                    id: $scope.matriz.id,
                    readOnly: false
                });
            };

            $scope.openAdicionarItemOrganizacaoCurricular = function(etapaDisciplina){
                if($scope.isCampoExperiencias()){
                    openAdicionarCampoExperiencia(etapaDisciplina);
                } else {
                    openAdicionarDisciplina(etapaDisciplina);
                }
            };

            function openAdicionarDisciplina(etapaDisciplina) {

                var ignore;
                if ($scope.isDisciplinas()) {
                    ignore = _.pluck(_.pluck($scope.disciplinas, 'disciplina'), 'id');
                } else {
                    ignore = _.pluck(_.pluck($scope.disciplinas, 'eixoTematico'), 'id');
                }

                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.disciplinas.html',
                    controller: 'educacao.matriz.MatrizAddDisciplinasCtrl',
                    id: etapaDisciplina ? etapaDisciplina.id : null,
                    readOnly: false,
                    resolve: {
                        'etapa': $scope.etapaCurrent,
                        'matriz': $scope.matriz,
                        'disciplinasExistentes': function () {
                            return ignore;
                        }
                    }
                });

            }

            function openAdicionarCampoExperiencia(etapaDisciplina) {

                var ignore = _.pluck($scope.disciplinas, 'campoExperiencia');

                $modal.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.campos-experiencia.html',
                    controller: 'educacao.matriz.MatrizAddCamposExperienciaCtrl',
                    controllerAs: 'vm',
                    size: 'lg',
                    params: {
                        etapa: $scope.etapaCurrent,
                        matriz: $scope.matriz,
                        etapaMatrizDisciplina: etapaDisciplina,
                        optionsCad: optionsCad,
                        camposExperienciaExistentes: ignore
                    }
                }).result.then(setEtapasDisciplinasCamposExperiencia);

            }

            function setEtapasDisciplinasCamposExperiencia(args){
                var event;
                
                refreshEtapasDisciplinas(event, args);
            }

            $scope.openModalCompetenciasEixo = function (etapaMatrizDisciplina) {
                $modal.open({
                    templateUrl: 'planejamento/matriz/matriz/input-competencia-eixo/matriz-input-competencia-eixo.html',
                    controller: 'educacao.matriz.MatrizCompetenciasEixoCtrl',
                    controllerAs: 'vm',
                    size: 'sm',
                    params: {
                        etapaMatrizDisciplina: etapaMatrizDisciplina,
                        matrizId: $scope.matriz.id,
                        canSaveAdd: true
                    }
                });

            };

            /*   $scope.openAddOutraSequencia = function (matrizId) {
                   ModalCad.open({
                       templateUrl: 'planejamento/matriz/matriz/matriz.sequencia.html',
                       controller: 'educacao.matriz.MatrizAddOutraSequenciaCtrl',
                       id: matrizId,
                       readOnly: false
                   });

               };*/

            function getMensagemExclusao(item) {
                if ($scope.isDisciplinas()) {
                    return 'Componente curricular ' + item.disciplina.descricao + ' será excluído(a) em instantes';
                }
                if ($scope.isCampoExperiencias()) {
                    return 'Campo de experiência ' + item.campoExperiencia.descricao + ' será excluído(a) em instantes';
                }
                if (item.eixoTematico) {
                    return 'Eixo temático ' + item.eixoTematico.descricao + ' será excluído(a) em instantes';
                }
                return item.eixoTematico.descricao + ' será excluído(a) em instantes';
            }

            $scope.openClassificar = function () {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.classificar.html',
                    controller: 'educacao.matriz.MatrizClassificarCtrl',
                    id: $scope.matriz.id,
                    readOnly: false
                });
            };

            $scope.openConcomitantes = function (cursoConcomitante) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.concomitantes.html',
                    controller: 'educacao.matriz.MatrizConcomitantesCtrl',
                    id: $scope.matriz.id,
                    resolve: {
                        'cursoConcomitante': cursoConcomitante
                    },
                    readOnly: false
                });
            };

            $scope.openParametros = function (etapaId) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.parametros.html',
                    controller: 'educacao.matriz.ParametrosCadCtrl',
                    id: etapaId,
                    resolve: {
                        'matriz': $scope.matriz
                    },
                    readOnly: false
                });
            };

            $scope.openVisualizarMatriz = function () {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/matriz/matriz.visualizar.html',
                    controller: 'educacao.matriz.MatrizVisualizarCtrl',
                    id: id,
                    readOnly: false
                });
            };



            $scope.removePreRequisito = function (matrizId, curso) {
                MatrizPreRequisitoService.remove(matrizId, curso.id).then(function () {
                    $rootScope.$broadcast('refreshCursosPreRequisito', {
                        data: curso,
                        remove: true
                    });
                });
            };

            $scope.removeConcomitante = function (concomitante) {
                CursoConcomitanteService.remove(id, concomitante.id).then(function () {
                    _.remove($scope.cursosConcomitantes, function (el) {
                        return el.id === concomitante.id;
                    });
                });
            };

            $scope.savePreRequisito = function () {
                var curso = $scope.matriz.cursoPreRequisito;

                $scope.tracker.preRequisito.addPromise(
                    MatrizPreRequisitoService.save($scope.matriz.id, curso).then(function () {
                        $rootScope.$broadcast('refreshCursosPreRequisito', {
                            data: curso,
                            add: true
                        });

                        $scope.matriz.cursoPreRequisito = null;

                    }));


            };
        }]);


})();

(function() {
    'use strict';
    angular.module('educacao.pessoas').directive('appPessoaDadosPessoais', function() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'planejamento/pessoa/app-pessoa-dados-pessoais.directive.html';
        config.scope = {
            aluno: '=ngModel',
            service: '='
        };
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;

    });
    Controller.$inject = [
        '$scope', 
        'educacao.common.ModalCad',
        'educacao.enderecos.BairroService',
        'educacao.enderecos.EstadoService',
        'educacao.enderecos.MunicipioService'
    ];

    function Controller($scope, ModalCad, BairroService, EstadoService, MunicipioService) {
        var vm = this,
            RETURN_MODAL_COMP = 'returnModalComp:Aluno';
        vm.enumOrientacaoSexual = $scope.service.getOrientacaoSexual();
        vm.enumEstadoCivil = $scope.service.getEstadoCivil();
        vm.enumGrauInstrucao = $scope.service.getGrauInstrucao();
        vm.openObito = openObito;

        vm.bairroService = BairroService;
        vm.estadoService = EstadoService;
        vm.municipioService = MunicipioService;

        function openObito() {
            ModalCad.open({
                templateUrl: 'planejamento/alunos/popups/aluno.cad.obito.html',
                controller: 'educacao.pessoas.ControllerGenerico as vm',
                resolve: {
                    ModalCalCompTransfer: {
                        brodcastReturn: RETURN_MODAL_COMP,
                        valueData: $scope.aluno
                    }
                },
                readOnly: false
            });
        }
    }

})();

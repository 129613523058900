(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .service('educacao.ocupacao-aulas-quadro-informativo.AccordionMatrizUtils', AccordionMatrizUtils);

    AccordionMatrizUtils.$inject = [
        'promiseTracker',
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService',
        'EdEnumsCacheService',
        'educacao.ocupacao-aulas-quadro-informativo.AccordionVisaoGeralUtilsService'
    ];

    function AccordionMatrizUtils(promiseTracker, VisaoEstabelecimentoService, EdEnumsCacheService, AccordionVisaoGeralUtilsService) {
        var vm = this;

        vm.onClickMatriz = onClickMatriz;
        vm.init = init;

        function init() {
            EdEnumsCacheService.get('Turno').then(function(result) {
                vm.TURNOS = result;
            });
        }

        function onClickMatriz(matriz, estabelecimentoId, turmaFilters, anoLetivoId, componenteCurricularFilter) {
            matriz.loading = promiseTracker();
            matriz.collapsed = !matriz.collapsed;

            if(matriz.collapsed) {
                getSaldosTurmas(matriz, estabelecimentoId, turmaFilters, anoLetivoId, componenteCurricularFilter);
            }
        }

        function getSaldosTurmas(matriz, estabelecimentoId, turmaFilters, anoLetivoId, componenteCurricularFilter) {
            var filter = applyFilters(matriz, estabelecimentoId, turmaFilters, componenteCurricularFilter);
            
            var params = {
                filter: filter,
                anoLetivoId: anoLetivoId
            };
            
            if (!matriz.turmas) {
                matriz.hasSearch = true;
                var promise = VisaoEstabelecimentoService.getSaldosTurmasByMatrizCurricular(params)
                    .then(function (data) {
                        matriz.turmas = groupSaldosByTurma(data);
                        return;
                    });

                matriz.loading.addPromise(promise);
            }
        }

        function applyFilters(matriz, estabelecimentoId, turmaFilters, componenteCurricularId) {
            var filter = [];

            if(!_.isEmpty(estabelecimentoId)) {
                filter.push('estabelecimento = ' + estabelecimentoId);
            }

            if(!_.isEmpty(turmaFilters)) {
                if(_.isEmpty(matriz)) {
                    filter.push('turma in (' + _.map(turmaFilters, 'id').join() + ')');
                } else {
                    var turmasPorMatriz = _.filter(turmaFilters, 'matriz.id', matriz.matrizCurricularId);

                    if (!_.isEmpty(turmasPorMatriz)) {
                        filter.push('turma in (' + _.map(turmasPorMatriz, 'id').join() + ')');
                    }
                }
            }

            if(!_.isEmpty(matriz)) {
                filter.push('matriz = ' + matriz.matrizCurricularId);
            }

            if(componenteCurricularId) {
                filter.push('idComponenteCurricular = ' + componenteCurricularId);
            }

            return filter.join(' and ');
        }

        function groupSaldosByTurma(turmas) {
            var porTurma = _.groupBy(turmas, 'turma.id');
            return sum(porTurma);
        }

        function sum(porTurmas) {
            return _.map(porTurmas, function (porTurma) {
                var porTurmaClone = _.cloneDeep(_.first(porTurma));

                var saldos = {
                    saldoInicial: 0,
                    saldoOcupado: 0,
                    saldoAtual: 0
                };

                AccordionVisaoGeralUtilsService.sumSaldoByTipoSaldo(saldos, porTurma);

                porTurmaClone.saldoInicial = saldos.saldoInicial;
                porTurmaClone.saldoOcupado = saldos.saldoOcupado;
                porTurmaClone.saldoAtual = saldos.saldoAtual;

                return porTurmaClone;
            });
        }
    }
})();
(function() {

    'use strict';
    angular.module('educacao.geracaoHorarios').factory('educacao.geracaoHorarios.GradeDirectiveService', GradeDirectiveService);

    GradeDirectiveService.$inject = [];

    function GradeDirectiveService() {

        return {
            createGrade: createGrade,
            removerAulaDaLista: removerAulaDaLista,
            removerAulaPossivel: removerAulaPossivel,
            adicionarAulaPossivel: adicionarAulaPossivel
        };

        function createGrade(infoGrid, weekDays) {
            var diasDaSemana = extractDayOfWeek(infoGrid.configuracaoHorasAulas.itens, weekDays);
            var turmas = extractTurma(infoGrid, diasDaSemana);

            return _.extend(turmas, {
                diasDaSemana: diasDaSemana
            }, {
                aulasPossiveis: infoGrid.aulasPossiveis
            });
        }

        function extractTurma(infoGrid, diasDaSemana) {
            var numeroAulas = [];
            var turmas = {};
            var aulas = [];

            _.each(diasDaSemana, function(dia) {
                for (var iteratorQtdAulas = 1; iteratorQtdAulas <= dia.qtdAulas; iteratorQtdAulas++) {
                    aulas.push({
                        diaSemana: dia.key,
                        numero: iteratorQtdAulas
                    });

                    numeroAulas.push({
                        diaSemana: dia.key,
                        numero: iteratorQtdAulas
                    });
                }
            });

            _.each(infoGrid.aulas, function(aula) {
                var turma = aula.turma;
                createTurma(turmas, turma, aulas);
                turmas[turma.id].aulas[calcPosition(aula, diasDaSemana)] = aula;
            });

            _.each(infoGrid.aulasPossiveis, function(aulaPossivel) {
                var turma = aulaPossivel.aula.turma;
                createTurma(turmas, turma, aulas);
            });

            return {
                turmas: turmas,
                numeroAulas: numeroAulas
            };
        }

        function extractDayOfWeek(itensConfiguracao, daysOfWeek) {
            var daysConfig = [];

            _.each(itensConfiguracao, function(item) {
                var daysMatched = _.filter(daysOfWeek, function(day) {
                    return _.indexOf(item.diasDaSemana, day.key) > -1;
                });

                daysConfig = _.union(daysConfig, _.each(daysMatched, function(day) {
                    _.extend(day, {
                        qtdAulas: item.qtdAulas
                    });
                }));
            });

            return _.sortByOrder(daysConfig, ['value'], ['asc']);
        }

        function createTurma(turmas, turma, aulas) {
            if (!turmas[turma.id]) {
                turmas[turma.id] = {
                    id: turma.id,
                    descricao: turma.descricao,
                    aulas: angular.copy(aulas)
                };
            }
        }

        function calcPosition(aula, diasDaSemana) {
            var position = 0;

            _.each(diasDaSemana, function(dia) {
                if (dia.key === aula.diaSemana) {
                    return false;
                }
                position += dia.qtdAulas;
            });

            position += aula.numero - 1;
            return position;
        }

        function removerAulaDaLista(turma, aulaDropped) {
            _.each(turma.aulas, function(aula, key) {
                if (aula.id === aulaDropped.id) {
                    turma.aulas[key] = {
                        diaSemana: aula.diaSemana,
                        numero: aula.numero
                    };
                    return false;
                }
            });
        }

        function removerAulaPossivel(aulasPossiveis, aula) {
            _.each(aulasPossiveis, function(aulaPossivel, key) {
                if (equalsAulas(aulaPossivel.aula, aula)) {
                    aulaPossivel.quantidadeAulasDisponiveis--;
                    if (aulaPossivel.quantidadeAulasDisponiveis < 1) {
                        aulasPossiveis[key] = undefined;
                    }
                    return false;
                }
            });

            return _.filter(aulasPossiveis, function(aulaPossivel) {
                return angular.isDefined(aulaPossivel);
            });
        }

        function adicionarAulaPossivel(aulasPossiveis, aula) {
            var founded = _.find(aulasPossiveis, function(aulaPossivel) {
                return equalsAulas(aulaPossivel.aula, aula);
            });

            if (!founded) {
                aula.numero = null;
                aula.id = null;
                aulasPossiveis.push({
                    aula: aula,
                    quantidadeAulasDisponiveis: 1
                });
                return;
            }

            _.each(aulasPossiveis, function(aulaPossivel) {
                if (equalsAulas(aulaPossivel.aula, aula)) {
                    aulaPossivel.quantidadeAulasDisponiveis++;
                    return false;
                }
            });

            return aulasPossiveis;
        }

        function equalsAulas(aulaL, aulaR) {
            return aulaL.turma.id === aulaR.turma.id && aulaL.professor.id === aulaR.professor.id &&
                aulaL.origem.id === aulaR.origem.id;
        }

    }
})();

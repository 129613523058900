(function() {
    'use strict';

    var QUANTIDADE_DECIMAIS = {
        UMA: 'UMA',
        DUAS: 'DUAS'
    };
    var TIPO_DECIMAIS_ZERO_OU_CINCO = 'ZERO_OU_CINCO';

    angular.module('educacao.matricula')
        .service('educacao.matricula.TipoAvaliacaoUtilsService', TipoAvaliacaoUtilsService);

    TipoAvaliacaoUtilsService.$inject = [];

    function TipoAvaliacaoUtilsService() {
        var self = this;

        self.getQuantidadeCasasDecimais = getQuantidadeCasasDecimais;
        self.getDecimaisValidos = getDecimaisValidos;

        function getQuantidadeCasasDecimais(configuracaoAvaliacao) {

            if (!configuracaoAvaliacao) {
                return;
            }

            switch (configuracaoAvaliacao.quantidadeDecimais) {
                case QUANTIDADE_DECIMAIS.UMA:
                    return 1;
                case QUANTIDADE_DECIMAIS.DUAS:
                    return 2;
                default:
                    return 0;
            }
        }

        function getDecimaisValidos(configuracaoAvaliacao) {

            if (!configuracaoAvaliacao) {
                return;
            }

            var decimais = getQuantidadeCasasDecimais(configuracaoAvaliacao);

            if (TIPO_DECIMAIS_ZERO_OU_CINCO === configuracaoAvaliacao.decimaisAvaliacao) {
                return [_.padRight('0', decimais, '0'), _.padRight('5', decimais, '0')];
            }
            return undefined;
        }
    }
})();


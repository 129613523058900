(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.funcionario');

    moduloCommon.controller('educacao.funcionario.DisponibilidadeCtrl', ['$scope', 'educacao.common.ModalCad',
        function($scope, ModalCad) {

            $scope.disponibilidade = {};

            $scope.open = function(id) {
                ModalCad.open({
                    templateUrl: 'planejamento/funcionarios/disponibilidade/disponibilidade.cad.html',
                    controller: 'educacao.funcionario.DisponibilidadeCadCtrl',
                    id: id,
                    readOnly: false
                });
            };

        }
    ]);

    moduloCommon.controller('educacao.funcionario.DisponibilidadeCadCtrl', ['$scope',
        function($scope) {
            $scope.disponibilidade = {};
        }
    ]);

})();

(function() {
	'use strict';

	angular.module('educacao.historico-escolar')
		.factory('educacao.historicoEscolar.HistoricoEscolarService', HistoricoEscolarService);

	HistoricoEscolarService.$inject = ['ServiceBuilder', 'Restangular'];

	function HistoricoEscolarService(ServiceBuilder, Restangular) {
		var self = ServiceBuilder
			.create()
			.path('historico-escolar')
			.enum('ConclusaoOrigem')
			.enum('NivelModalidade')
			.enum('NivelEscolar')
			.enum('SituacaoHistorico')
			.enum('OrientacaoCurricular')
			.enum('TipoAvaliacaoHistorico')
			.enum('TipoItemOrganizacaoCurricular')
			.enum('EquivalenciaEtapa')
			.enum('TipoItemOrganizacaoCurricularHistorico')
			.build();

		self.saveSituacao = saveSituacao;
		self.getSituacao = getSituacao;
		self.saveHistorico = saveHistorico;
		self.removeHistorico = removeHistorico;
		self.getCursosRecentes = getCursosRecentes;
		self.imprimirHistorico = imprimirHistorico;
		self.camposAdicionais = camposAdicionais;

		return self;

		function saveHistorico(historico) {
			return self.save(historico);
		}

		function removeHistorico(historico) {
			return self.remove(historico);
		}

		function getCursosRecentes() {
			return self.getEndpoint()
				.all('descricoes-cursos-recentes')
				.get('');
		}

		function imprimirHistorico(parametros) {
			return self.getEndpoint()
				.all('imprimir')
				.withHttpConfig({
					responseType: 'arraybuffer'
				})
				.post(parametros);
		}

		function camposAdicionais(historicoId) {
			return Restangular.one('historico-escolar/campos-adicionais', historicoId)
                .get(historicoId)
                .then(Restangular.stripRestangular);
        }

		function getEndpointAvaliacao() {
			return Restangular.all('situacao-equivalencia-aluno');
		}

		function saveSituacao(equivalencia) {
			var endpoint = getEndpointAvaliacao();
			return equivalencia.id ? endpoint.customPUT(equivalencia, equivalencia.id) : endpoint.post(equivalencia);
		}

		function getSituacao(params) {
			var endpoint = getEndpointAvaliacao();
			return endpoint.get('', params);
		}
	}
})();


(function() {

    'use strict';

    angular.module('educacao.matricula').directive('appCardTurmaItens', CardTurmaItens);

    function CardTurmaItens() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/app-card-turma/app-card-turma-itens.directive.html',
            scope: {
                itens: '='
            },
            controller: Controller
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        
        $scope.getDescription = function(i){
            return _.get(i,'atividade') || _.get(i, 'disciplina');
        };

        $scope.$watch('itens', inicializaValores);

        function inicializaValores(value, oldValue) {
            if (_.isEqual(value, oldValue)) {
                return;
            }

            angular.forEach($scope.itens, function(item) {
                var valorFinal = item.qtdeAlunos * 100 / item.qtdeMaximaAlunos;

                if (valorFinal <= 80) {
                    item.class = 'tx__green';
                } else if (valorFinal > 80 && valorFinal < 100) {
                    item.class = 'tx__yellow--d10';
                } else {
                    item.class = 'tx__red';
                }
            });
        }
    }
})();


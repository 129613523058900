(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appAccordionChaItemDetail', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<small class="text-muted" title="teste title" ng-if="textDetail">{{textDetail}}</small>',
            scope: {
                titleDetail: '@',
                textDetail: '='
            }
        };
    }

})();


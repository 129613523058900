(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.AtividadeComplementarService', AtividadeComplementarService);

    AtividadeComplementarService.$inject = ['ServiceBuilder'];

    function AtividadeComplementarService(ServiceBuilder) {

        var self = ServiceBuilder
            .create()
            .path('matricula', 'atividade-complementar')
            .enum('AreaAtividadeCompl')
            .enum('SubAreaAtividadeCompl')
            .build();

        self.getAreas = getAreas;
        self.getDefaultByCodInep = getDefaultByCodInep;
        self.listByParamsUser = listByParamsUser;

        return self;

        function listByParamsUser(params) {
            params.filter += (params.filter ? ' and ' : '') + 'dataBase is not null';
            return self.listByParams(params);
        }

        function getAreas() {
            return self.getEndpoint().get('areas');
        }

        function getDefaultByCodInep(codigoInep) {
            var params = {
                filter: 'dataBase is null and codigoInep = ' + codigoInep
            };
            return self.getEndpoint().get('', params)
                .then(function(data) {
                    return _.first(data.content);
                });
        }
    }
})();

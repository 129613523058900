(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('etapas', etapas);

    etapas.$inject = ['$state'];

    function etapas() {

        var directiveConfig = {};

        directiveConfig.templateUrl = 'matricula/inscricao-online/localizando/etapas.directive.html';

        directiveConfig.restrict = 'E';
        directiveConfig.scope = {};
        directiveConfig.scope.code = '=';
        directiveConfig.scope.disable = '=';
        directiveConfig.scope.state = '@';
        directiveConfig.controller = EtapasCtrl;
        directiveConfig.controllerAs = 'vm';

        return directiveConfig;
    }


    function EtapasCtrl() {
        var vm = this;
        
        vm.etapaMatrizEquivalenciaMedio = [{
            etapa: '1º ano'
        }, {
            etapa: '2º ano'
        }, {
            etapa: '3º ano'
        }];


        vm.etapaMatrizEquivalenciaFundamental = [{
            etapa: '1º ano'
        }, {
            etapa: '2º ano'
        }, {
            etapa: '3º ano'
        }, {
            etapa: '4º ano'
        }, {
            etapa: '5º ano'
        }, {
            etapa: '6º ano'
        }, {
            etapa: '7º ano'
        }, {
            etapa: '8º ano'
        }, {
            etapa: '9º ano'
        }];

    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.ModalAlunoService', ModalAlunoService);

    ModalAlunoService.$inject = ['educacao.common.$commons', 'educacao.pessoas.AlunoService'];

    function ModalAlunoService($commons, AlunoService) {
        return {
            openByIdAluno: openByIdAluno
        };

        function openByIdAluno(id, disableExcluir) {
            return $commons.$modal.open({
                templateUrl: 'planejamento/alunos/cadastro-aluno.html',
                controller: 'educacao.pessoas.AlunoCadCtrl as vm',
                size: 'xl',
                params: {
                    aluno: AlunoService.get(id),
                    disableExcluir: disableExcluir,
                    canSaveAndAdd: false
                }
            });
        }
    }

})();
(function () {

    'use strict';

    angular.module('educacao.preparacaoAnoLetivo')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.preparacao-ano-letivo', {
            abstract: true,
            data: {
                id: 'CopiaAnoLetivoPageMapping'
            },
            
        });
    }
})();

(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');


    moduloCommon.controller('educacao.matriz.MatrizConcomitantesCtrl', ['$scope',
                             '$timeout',
                             '$rootScope',
                             'promiseTracker',
                             'educacao.common.ModalCad',
                             'educacao.matriz.CursoSelect',
                             'educacao.matriz.CursoService',
                             'id',
                             'cursoConcomitante',
                             'optionsCad',
                             'fnCad',
                             'educacao.matriz.MatrizService',
                             'educacao.matriz.CursoConcomitanteService',
                             'educacao.common.ObjectUtilsService',
                             '$modalTracker',
                             'bfc.Select2Config',
                             '$modalInstance',
                             'bfc.Focus',

		function ($scope,
            $timeout,
            $rootScope,
            promiseTracker,
            ModalCad,
            CursoSelect,
            CursoService,
            idMatriz,
            cursoConcomitante,
            optionsCad,
            fnCad,
            MatrizService,
            CursoConcomitanteService,
            ObjectUtilsService,
            $modalTracker,
            Select2Config,
            $modalInstance,
            focus
                 )
        {

            $scope.tracker = {};
            $scope.tracker.saveContinue = promiseTracker();
            $scope.tracker.save = promiseTracker();
            $scope.cursoConcomitante = {};
            $scope.matriz = {};

            $scope.modo = {
                adicionando: !cursoConcomitante,
                editando: cursoConcomitante
            };

            (function () {
                $modalTracker.addPromise(
                    MatrizService.get(idMatriz).then(function (data) {
                        $scope.matriz = data;
                        $scope.etapas = data.etapas;

                        $scope.etapasSelect2 = Select2Config.create(angular.extend({
                            result: function (params, callback) {
                                callback({
                                    results: ObjectUtilsService.filterByField($scope.etapas, 'identificacao', params.term),
                                    more: false
                                });
                            },
                            formatValue: function (value) {
                                return value;
                            },
                            formatResult: function (value) {
                                return descricaoEtapa(value);
                            },
                            formatSelection: function (value) {
                                return descricaoEtapa(value);
                            },
                            multiple: true
                        }));
                    })
                );

                $scope.cursoSelect = CursoSelect.select2Config({
                    result: function (params, callback) {

                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result,
                            filter = '(descricao like "' + term + '" and nivelModalidade = "1")';

                        CursoConcomitanteService.getList(idMatriz).then(function (cursosSelecionados) {

                            result = CursoService.getList(params.offset, params.limit, filter, undefined);
                            result.then(function (data) {
                                data = _.reject(data, function (curso) {
                                    return curso.id === $scope.matriz.curso.id ||
                                        _.find(cursosSelecionados, function (el) {
                                            return el.curso.id === curso.id;
                                        });
                                });

                                callback({
                                    results: data,
                                    more: false
                                });
                            });


                        });
                        return result;
                    }
                });

                if (_.has(cursoConcomitante, 'id')) {
                    $scope.cursoConcomitante = cursoConcomitante;
                }
            })();


            var descricaoEtapa = function (etapa) {
                return MatrizService.getDescricaoEtapa($scope.matriz, etapa);
            };

            $scope.limparSeriesControladas = function () {
                _.remove($scope.cursoConcomitante.etapas);
            };

            $scope.cancel = function () {
                $modalInstance.close();
            };




            $scope.save = function (continuar) {
                var tracker = continuar ? $scope.tracker.saveContinue : $scope.tracker.save;
                tracker.addPromise(CursoConcomitanteService.save(idMatriz, $scope.cursoConcomitante)
                    .then(function (data) {
                        if (angular.isFunction(fnCad)) {
                            fnCad(data);
                        }

                        $rootScope.$broadcast('refreshCursosConcomitantes', {
                            data: data,
                            add: !$scope.cursoConcomitante.id,
                            edit: Boolean($scope.cursoConcomitante.id)
                        });

                        onSuccess(continuar, tracker);
                    }));

            };


            function onSuccess(continuar, tracker) {
                if (continuar) {
                    $scope.cursoConcomitante.id = null;
                    tracker.addPromise(refresh());
                    focus('lbConcomitantes');
                } else {
                    $scope.cancel();
                }
            }

            function refresh() {
                if ($scope.form) {
                    $scope.form.$setPristine();
                }
                return Boolean($scope.cursoConcomitante.id) ?
                    CursoConcomitanteService.get(idMatriz, $scope.cursoConcomitante.id).then(function (data) {
                        $scope.cursoConcomitante = data;
                    }) :
                    CursoConcomitanteService.getDefault(idMatriz).then(function (data) {
                        $scope.cursoConcomitante = angular.extend(data);
                    });

            }


		}]);
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.QuadroVagasService', QuadroVagasService);

    QuadroVagasService.$inject = ['$injector', 'Restangular', 'bfc.dialog.Dialog', '$q', 'ui.components.Modal'];

    function QuadroVagasService($injector, Restangular, BfcDialog, $q, $modal) {

        var GenericService = $injector.get('GenericService');
        var service = Restangular.one('matricula').one('vaga');
        var modal = {
            templateUrl: 'matricula/quadro-vagas-matriculas/quadro-vagas-matriculas.cad.html',
            controller: 'educacao.matricula.QuadroVagasCadCtrl as vm',
            size: 'lg'
        };

        return GenericService.createWithCustomMethods({
                listEstabelecimentos: listEstabelecimentos,
                listMatrizes: listMatrizes,
                listVagasEtapas: listVagasEtapas,
                openModalCadastro: openModalCadastro,
                openModalEdicao: openModalEdicao,
                estabelecimentoVagaEtapaList: estabelecimentoVagaEtapaList,
                removePorEstabelecimento: removePorEstabelecimento,
                removePorMatriz: removePorMatriz,
                removePorVaga: removePorVaga,
                getVagas: getVagas,
                atualizarVagas: atualizarVagas,
                getByEstabelecimentoEtapaMatrizTurno: getByEstabelecimentoEtapaMatrizTurno,
                getQuadroDeVagasComVaga: getQuadroDeVagasComVaga,
                getQuadroDeVagasComVagaTodos: getQuadroDeVagasComVagaTodos,
                getQuadroDeVagas: getQuadroDeVagas,
                getMatrizByEstabelecimento: getMatrizByEstabelecimento
            })
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'vaga'
            });

        function openModalCadastro() {

            modal.params = {
                id: undefined,
                readOnly: false,
                tipoEdit: undefined
            };

            return $modal.open(modal);

        }

        function openModalEdicao(param, tipo) {

            var promise = getVagas(param);
            modal.params = {
                id: promise.then(function(data) {
                    return data[0].content;
                }),
                readOnly: false,
                tipoEdit: tipo
            };

            return $modal.open(modal);

        }

        function listEstabelecimentos(param) {

            return service.one('estabelecimentos')
                .getList('', {
                    filter: param.filter,
                    offset: param.offset,
                    limit: param.limit,
                    sort: param.sort
                })
                .then(function(data) {

                    return data[0];

                });

        }

        function estabelecimentoVagaEtapaList(estabelecimento) {

            return service.one('estabelecimento', estabelecimento.id)
                .one('vagas-etapas')
                .getList();

        }

        function listMatrizes(idEstabelecimento) {

            return service.one('matrizes').getList('', idEstabelecimento)
                .then(function(data) {

                    return {
                        content: data
                    };

                });

        }

        function listVagasEtapas(idEstabelecimento, idMatriz) {

            return service.one('vagas-etapas')
                .getList('', idEstabelecimento, idMatriz)
                .then(function(data) {

                    return {
                        content: data
                    };

                });

        }

        function removePorEstabelecimento(id) {

            return service.one('estabelecimento')
                .customDELETE(id);

        }

        function removePorMatriz(params) {

            return service.one('estabelecimento', params.idEstabelecimento)
                .one('matriz', params.idMatriz)
                .customDELETE();

        }

        function removePorVaga(id) {

            return service.one('estabelecimento')
                .customDELETE(id);

        }

        function getVagas(param) {

            return service.one('vagas')
                .getList('', param);

        }

        function atualizarVagas(vagas) {

            return service.one('atualizarVagas').customPUT(vagas);

        }

        function getByEstabelecimentoEtapaMatrizTurno(idEstabelecimento, idEtapaMatriz, turno) {

            return service.one('estabelecimentos', idEstabelecimento)
                .one('etapas-matrizes', idEtapaMatriz)
                .one('turnos', turno)
                .get();

        }

        function getQuadroDeVagasComVaga(params, filter, anoLetivo) {

            return service
                .all('vagas-disponiveis')
                .getList({
                    anoLetivo: anoLetivo,
                    filter: filter,
                    offset: params.offset,
                    limit: params.limit
                });

        }

        function getQuadroDeVagasComVagaTodos(filter, multiturno) {

            return service
                .all('vagas-disponiveis-todas')
                .getList({
                    filter: filter,
                    multiturno: multiturno
                });

        }

        function getQuadroDeVagas(params, filter) {
            return service
                .all('vagas')
                .getList({
                    filter: filter,
                    offset: params.offset,
                    limit: params.limit
                });

        }

        function getMatrizByEstabelecimento(params, filter) {
            return service
                .getList('', {
                    filter: filter,
                    offset: params.offset,
                    limit: params.limit
                });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('matriculaAtividade', MatriculaAtividade);

    function MatriculaAtividade() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/atividade/matricula-atividade.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                matricula: '=',
                formatResultTurma: '=',
                hasEncerramentos: '='
            }
        };
    }

    Controller.$inject = ['promiseTracker', '$injector', '$scope', 'TIPOS_MATRICULAS'];

    function Controller(promiseTracker, $injector, $scope, TIPOS_MATRICULAS) {

        var vm = this;

        var TurmaService = $injector.get('educacao.matricula.TurmaService'),
            MatriculaAtividadeService = $injector.get('educacao.matricula.MatriculaAtividadeService'),
            MatriculaPadraoService = $injector.get('educacao.matricula.MatriculaPadraoService');

        var templateTurmas = _.template('<%= descricao %> - <%= _.capitalize(turno.toLowerCase())  %>');

        vm.createAtividadeObject = createAtividadeObject;
        vm.isEmpty = isEmpty;
        vm.formatDeficiencia = formatDeficiencia;
        vm.formatTurma = formatTurma;
        vm.valueBoolean = valueBoolean;
        vm.formatResultAtividade = formatResultAtividade;

        vm.tracker = {
            loading: promiseTracker(),
            loadingAtividades: promiseTracker()
        };

        vm.isAee = $scope.matricula.tipoMatricula === 'AEE';

        vm.edit = Boolean($scope.matricula.id);
        vm.tiposMatriculas = TIPOS_MATRICULAS;

        vm.tracker.loading.addPromise(
            vm.getOrigemEstabelecimento = MatriculaPadraoService.getTransferenciaOrigem()
        );

        vm.tracker.loading.addPromise(
            MatriculaAtividadeService.getDefaultRepresentation().then(filhosOptions)
        );

        if (!vm.edit) {
            vm.tracker.loading.addPromise(
                MatriculaPadraoService.getDefaultRepresentation().then(setDadosMatricula)
            );
        } else {
            listaFilhos();
        }

        $scope.$watchGroup(['matricula.tipoMatricula', 'matricula.estabelecimento'], limpaItens);
        $scope.$watch('matricula.turma', watchTurmaAtividade);

        function formatDeficiencia(data) {
            return data.deficiencia.descricao;
        }

        function formatTurma(data) {
            return templateTurmas({
                descricao: data.descricao,
                turno: _.capitalize((data.turno || $scope.matricula.turno).toLowerCase())
            });
        }

        function valueBoolean(value) {
            if (_.isBoolean(value)) {
                return value ? 'Sim' : 'Não';
            }
            return '';
        }

        function limpaItens(newValue, oldValue) {

            if (_.isEqual(oldValue, newValue)) {
                return;
            }

            delete $scope.matricula.turma;

            vm.isAee = $scope.matricula.tipoMatricula === 'AEE';
        }

        function watchTurmaAtividade(newValue, oldValue) {
            var idTurma = _.get($scope.matricula, 'turma.id');

            if (vm.edit) {
                vm.tracker.loadingAtividades.addPromise(
                    TurmaService.getFilhos(idTurma)
                    .then(atividadesTurmaEdit)
                );

                return;
            }

            if (_.isEqual(oldValue, newValue)) {
                return;
            }

            delete $scope.matricula.$$listaFilhos;

            if (idTurma) {
                vm.tracker.loadingAtividades.addPromise(
                    TurmaService.getFilhos(idTurma)
                    .then(atividadesTurma)
                );
            }
        }

        function createAtividadeObject(element) {
            return _.extend(element.atividade, {
                quantidadeMaximaAlunos: element.quantidadeMaximaAlunos,
                quantidadeAlunos: element.quantidadeAlunos
            });
        }

        function atividadesTurma(data) {
            $scope.matricula.$$listaFilhos = _.map(data, createAtividadeObject);
            $scope.matricula.$$listaTurmaAtividade = data;
        }

        function atividadesTurmaEdit(data) {
            var atividadesSalvas = _.filter(data, function(item) {
                return _.find($scope.matricula.filhos, 'atividade.id', item.atividade.id);
            });

            var atividadesNaoSalvas = _.filter(data, function(item) {
                return !_.find($scope.matricula.filhos, 'atividade.id', item.atividade.id);
            });

            $scope.matricula.$$listaFilhos = _.map(atividadesSalvas, createAtividadeObject);
            $scope.matricula.$$listaTurmaAtividade = atividadesNaoSalvas;
        }

        function filhosOptions(data) {
            $scope.matricula.$$filhosOptions = data;
        }

        function setDadosMatricula(data) {
            data.aluno = $scope.matricula.aluno;
            data.tipoMatricula = $scope.matricula.tipoMatricula;
            data.estabelecimento = $scope.matricula.estabelecimento;
            $scope.matricula = angular.extend($scope.matricula, data);
        }

        function isEmpty(data) {
            return _.isEmpty(data);
        }

        function listaFilhos() {
            $scope.matricula.$$listaFilhos = $scope.matricula.filhos;
        }

        function formatResultAtividade(data) {
            if (vm.edit && data.atividade) {
                return data.atividade.descricao;
            }

            if (data.quantidadeAlunos) {
                return data.descricao + ' (' + data.quantidadeAlunos + '/' + data.quantidadeMaximaAlunos + ') ';
            } else {
                return data.descricao + ' (' + '0/' + data.quantidadeMaximaAlunos + ') ';
            }
        }
    }
})();


(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');


    moduloCommon.controller('educacao.matriz.MatrizAddDisciplinasCtrl', ['$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
		'educacao.common.ModalCad',
		'educacao.common.ObjectUtilsService',
		'educacao.matriz.MatrizService',
		'educacao.matriz.DisciplinaService',
        'educacao.matriz.EixoTematicoService',
		'educacao.matriz.EixoTematicoSelect',
		'educacao.matriz.AtitudeSelect',
		'educacao.matriz.AtitudeService',
		'educacao.matriz.CompetenciaSelect',
		'educacao.matriz.CompetenciaService',
		'educacao.matriz.ConhecimentoSelect',
		'educacao.matriz.ConhecimentoService',
		'educacao.matriz.HabilidadeSelect',
		'educacao.matriz.HabilidadeService',
		'educacao.matriz.EtapaDisciplinaService',
		'educacao.matriz.OrientacaoCurricularSelect',
		'fnCad',
		'id',
		'optionsCad',
		'promiseTracker',
		'readOnly',
		'etapa',
		'matriz',
		'disciplinasExistentes',
        'bfc.Focus',
		function ($scope, $rootScope, $q, $modalInstance, $modalTracker,
            ModalCad, ObjectUtilsService, MatrizService, DisciplinaService, EixoTematicoService,
            EixoTematicoSelect, AtitudeSelect, AtitudeService,
            CompetenciaSelect, CompetenciaService,
            ConhecimentoSelect, ConhecimentoService,
            HabilidadeSelect, HabilidadeService,
            EtapaDisciplinaService,
            OrientacaoCurricularSelect,
            fnCad,
            id,
            optionsCad,
            promiseTracker,
            readOnly,
            etapa,
            matriz,
            disciplinasExistentes,
            focus
		) {

            $scope.matriz = {};

            $scope.modo = {
                adicionando: !id && !readOnly,
                adicionandoNovaOpcao: Boolean(fnCad),
                editando: id && !readOnly,
                visualizando: id && readOnly
            };


            /**
             * Tracker for promise
             */
            $scope.tracker = {};
            $scope.tracker.botoes = promiseTracker();
            $scope.tracker.camposAdicionais = promiseTracker();

            $scope.BASE_NACIONAL_COMUM = 1;


            initCamposAdicionais();
            init();

            function init() {
                $scope.chas = {};

                $modalTracker.addPromise(
                    $q.all([
						refresh()
					])
                );

                $scope.orientacaoCurricularOptions = OrientacaoCurricularSelect.select2Config();

                $scope.matriz = matriz;
                $scope.etapa = etapa;

                $scope.eixoTematicoOptions = EixoTematicoSelect.select2Config({
                    result: function (params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result;
                        var filter = '(descricao like "' + term + '")';

                        if (angular.isArray(disciplinasExistentes) && disciplinasExistentes.length > 0) {
                            filter += ' and (id not in (' + disciplinasExistentes + '))';
                        }

                        result = EixoTematicoService.getList(params.offset, params.limit, filter, undefined);

                        result.then(function (data) {
                            callback({
                                results: data,
                                more: data.hasNext
                            });
                        });

                        return callback;
                    },
                    formatResult: function(data) {
                        if (!data.identificador) {
                            return data.descricao;
                        }

                        return data.identificador + ' - ' + data.descricao;
                    }
                });
                $scope.atitudeOptions = AtitudeSelect.select2Config({
                    result: function (params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result;
                        var filter = '(descricao like "' + term + '" or identificador like "' + term + '")';

                        if (angular.isArray($scope.etapaDisciplina.atitudes) && $scope.etapaDisciplina.atitudes.length > 0) {
                            filter += ' and (id not in (' + _.pluck($scope.etapaDisciplina.atitudes, 'id') + '))';
                        }

                        result = AtitudeService.getList(filter);

                        result.then(function (data) {
                            callback({
                                results: data,
                                more: false
                            });
                        });

                        return result;
                    },
                    formatResult: function(data) {
                        if (!data.identificador) {
                            return data.descricao;
                        }

                        return data.identificador + ' - ' + data.descricao;
                    }
                });
                $scope.competenciaOptions = CompetenciaSelect.select2Config({
                    result: function (params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result;
                        var filter = '(descricao like "' + term + '" or identificador like "' + term + '")';

                        if (angular.isArray($scope.etapaDisciplina.competencias) && $scope.etapaDisciplina.competencias.length > 0) {
                            filter += ' and (id not in (' + _.pluck($scope.etapaDisciplina.competencias, 'id') + '))';
                        }

                        result = CompetenciaService.getList(filter);

                        result.then(function (data) {
                            callback({
                                results: data,
                                more: false
                            });
                        });

                        return result;
                    },
                    formatResult: function(data) {
                        if (!data.identificador) {
                            return data.descricao;
                        }

                        return data.identificador + ' - ' + data.descricao;
                    }
                });
                $scope.conhecimentoOptions = ConhecimentoSelect.select2Config({
                    result: function (params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result;
                        var filter = '(descricao like "' + term + '" or identificador like "' + term + '")';

                        if (angular.isArray($scope.etapaDisciplina.conhecimentos) && $scope.etapaDisciplina.conhecimentos.length > 0) {
                            filter += ' and (id not in (' + _.pluck($scope.etapaDisciplina.conhecimentos, 'id') + '))';
                        }

                        result = ConhecimentoService.getList(filter);

                        result.then(function (data) {
                            callback({
                                results: data,
                                more: false
                            });
                        });

                        return result;
                    },
                    formatResult: function(data) {
                        if (!data.identificador) {
                            return data.descricao;
                        }

                        return data.identificador + ' - ' + data.descricao;
                    }
                });
                $scope.habilidadeOptions = HabilidadeSelect.select2Config({
                    result: function (params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result;
                        var filter = '(descricao like "' + term + '" or identificador like "' + term + '")';

                        if (angular.isArray($scope.etapaDisciplina.habilidades) && $scope.etapaDisciplina.habilidades.length > 0) {
                            filter += ' and (id not in (' + _.pluck($scope.etapaDisciplina.habilidades, 'id') + '))';
                        }

                        result = HabilidadeService.getList(filter);

                        result.then(function (data) {
                            callback({
                                results: data,
                                more: false
                            });
                        });

                        return result;
                    },
                    formatResult: function(data) {
                        if (!data.identificador) {
                            return data.descricao;
                        }

                        return data.identificador + ' - ' + data.descricao;
                    }
                });

                // $scope.disciplinaOptions = DisciplinaSelect.select2Config({
                //     result: function (params, callback) {
                //         var term = ObjectUtilsService.normalizeFilter(params.term);
                //         term = encodeURI(term);

                //         var result,
                //             filter = '(sigla like "' + term + '" or descricao like "' + term + '")';

                //         if (angular.isArray(disciplinasExistentes) && disciplinasExistentes.length > 0) {
                //             filter += ' and (id not in (' + disciplinasExistentes + '))';
                //         }

                //         result = DisciplinaService.getList(params.offset, params.limit, filter, undefined);

                //         result.then(function (data) {
                //             callback({
                //                 results: data,
                //                 more: data.hasNext
                //             });
                //         });

                //         return callback;
                //     }
                // });


            }

            function refresh() {
                if ($scope.form) {
                    $scope.form.$setPristine();
                    $scope.form.$setUntouched();
                }

                return Boolean(id) ?
                    EtapaDisciplinaService.get(matriz.id, etapa.id, id).then(function (data) {
                        $scope.etapaDisciplina = data;
                    }) :
                    EtapaDisciplinaService.getDefault(matriz.id, etapa.id).then(function (data) {
                        $scope.etapaDisciplina = angular.extend(data, optionsCad);
                    });
            }


            $scope.descricaoEtapa = function (etapaSelecionada) {
                return MatrizService.getDescricaoEtapa($scope.matriz, etapaSelecionada);
            };

            $scope.cancel = function () {
                $modalInstance.close();
            };

            $scope.remove = function () {
                $scope.tracker.botoes.addPromise(
                    EtapaDisciplinaService.remove(matriz.id, etapa.id, $scope.etapaDisciplina.id)
                    .then(function () {
                        $rootScope.$broadcast('refreshEtapasDisciplinas', {
                            data: $scope.etapaDisciplina,
                            etapa: etapa,
                            remove: true
                        });

                        onSuccess();
                    })
                );
            };

            $scope.save = function (continuar) {
                var tracker = $scope.tracker.botoes;
                $scope.etapaDisciplina.campoAdicional = $scope.campoAdicional;
                tracker.addPromise(EtapaDisciplinaService.save(matriz.id, etapa.id, $scope.etapaDisciplina)
                    .then(function (data) {
                        if (angular.isFunction(fnCad)) {
                            fnCad(data);
                        }

                        $rootScope.$broadcast('refreshEtapasDisciplinas', {
                            data: data,
                            etapa: etapa,
                            add: !id,
                            edit: Boolean(id)
                        });

                        onSuccess(continuar, tracker);
                    }));

            };

            function onSuccess(continuar, tracker) {
                if (continuar) {
                    id = null;
                    tracker.addPromise(refresh());
                    focus('lbSelecionarDisciplina');
                } else {
                    $scope.cancel();
                }
            }

            $scope.readOnly = function () {
                return readOnly;
            };

            $scope.isDisciplinas = function () {
                return $scope.matriz.tipoOrganizacao === 'DISCIPLINA' || !$scope.matriz.tipoOrganizacao;
            };

            $scope.$on('refreshEtapasDisciplinas', function (event, args) {
                if (args.add) {
                    if ($scope.isDisciplinas()) {
                        disciplinasExistentes.push(args.data.disciplina.id);
                    } else {
                        disciplinasExistentes.push(args.data.eixoTematico.id);
                    }
                }
            });

            $scope.$on('refreshConhecimentos', function (event, args) {
                $scope.etapaDisciplina.conhecimentos = atualizarLista($scope.etapaDisciplina.conhecimentos, args.data, args);
            });

            $scope.conhecimentoSelectChange = function () {
                var data = $scope.chas.conhecimento;

                atualizarLista($scope.etapaDisciplina.conhecimentos, data, {
                    add: true
                });

                $scope.chas.conhecimento = null;
            };

            $scope.removerConhecimento = function (conhecimentoId) {
                $scope.etapaDisciplina.conhecimentos =
                    atualizarLista($scope.etapaDisciplina.conhecimentos, {
                        id: conhecimentoId
                    }, {
                        remove: true
                    });
            };

            $scope.$on('refreshAtitudes', function (event, args) {
                $scope.etapaDisciplina.atitudes = atualizarLista($scope.etapaDisciplina.atitudes, args.data, args);
            });

            $scope.atitudeSelectChange = function () {
                var data = $scope.chas.atitude;

                atualizarLista($scope.etapaDisciplina.atitudes, data, {
                    add: true
                });

                $scope.chas.atitude = null;
            };

            $scope.removerAtitude = function (atitudeId) {
                $scope.etapaDisciplina.atitudes =
                    atualizarLista($scope.etapaDisciplina.atitudes, {
                        id: atitudeId
                    }, {
                        remove: true
                    });
            };

            $scope.$on('refreshCompetencias', function (event, args) {
                $scope.etapaDisciplina.competencias = atualizarLista($scope.etapaDisciplina.competencias, args.data, args);
            });

            $scope.competenciaSelectChange = function () {

                var data = $scope.chas.competencia;

                atualizarLista($scope.etapaDisciplina.competencias, data, {
                    add: true
                });

                $scope.chas.competencia = null;
            };

            $scope.removerCompetencia = function (competenciaId) {
                $scope.etapaDisciplina.competencias =
                    atualizarLista($scope.etapaDisciplina.competencias, {
                        id: competenciaId
                    }, {
                        remove: true
                    });
            };

            $scope.$on('refreshHabilidades', function (event, args) {
                $scope.etapaDisciplina.habilidades = atualizarLista($scope.etapaDisciplina.habilidades, args.data, args);
            });

            $scope.habilidadeSelectChange = function () {
                var data = $scope.chas.habilidade;

                atualizarLista($scope.etapaDisciplina.habilidades, data, {
                    add: true
                });

                $scope.chas.habilidade = null;
            };

            $scope.removerHabilidade = function (habilidadeId) {
                $scope.etapaDisciplina.habilidades =
                    atualizarLista($scope.etapaDisciplina.habilidades, {
                        id: habilidadeId
                    }, {
                        remove: true
                    });
            };

            //            $scope.$on('refreshEixosTematicos', function (event, args) {
            //                $scope.etapaDisciplina.eixosTematicos = atualizarLista($scope.etapaDisciplina.eixosTematicos, args.data, args);
            //            });

            function atualizarLista(lista, objeto, action) {
                if (objeto) {
                    if (action.remove) {
                        lista = _.reject(lista, {
                            'id': objeto.id
                        });
                    } else if (action.add) {
                        var entidade = _.find(lista, {
                            'id': objeto.id
                        });
                        if (entidade) {
                            ObjectUtilsService.copyProperties(entidade, objeto);
                        } else {
                            lista.push(objeto);
                        }
                    } else if (action.undo || action.edit) {
                        var entity = _.find(lista, {
                            'id': objeto.id
                        });

                        if (entity) {
                            ObjectUtilsService.copyProperties(entity, objeto);
                        } else {
                            throw new Error('Item não encontrado! ID: ' + objeto.id);
                        }
                    }
                    return lista;
                }
            }

            $scope.openCompetencia = function (competenciaId) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/competencia/competencia.cad.html',
                    controller: 'educacao.matriz.CompetenciaCadCtrl',
                    id: competenciaId,
                    readOnly: false
                });
            };

            $scope.openConhecimento = function (conhecimentoId) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/conhecimento/conhecimento.cad.html',
                    controller: 'educacao.matriz.ConhecimentoCadCtrl',
                    id: conhecimentoId,
                    readOnly: false
                });
            };

            $scope.openAtitude = function (atitudeId) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/atitude/atitude.cad.html',
                    controller: 'educacao.matriz.AtitudeCadCtrl',
                    id: atitudeId,
                    readOnly: false
                });
            };

            $scope.openHabilidade = function (habilidadeId) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/habilidade/habilidade.cad.html',
                    controller: 'educacao.matriz.HabilidadeCadCtrl',
                    id: habilidadeId,
                    readOnly: false
                });
            };

            function initCamposAdicionais() {
                var promise = EtapaDisciplinaService.camposAdicionais(matriz.id, etapa.id, id)
                    .then(function(data) {
                        $scope.campoAdicional = data;
                    });
                $scope.tracker.camposAdicionais.addPromise(promise);
            }

		}]);
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.VisaoGeralQuadroInformativoService', VisaoGeralQuadroInformativoService);

    VisaoGeralQuadroInformativoService.$inject = [
        'ServiceBuilder',
        'Restangular',
    ];

    function VisaoGeralQuadroInformativoService(ServiceBuilder, Restangular) {
        var baseUrl = 'visao-geral-quadro-informativo';
        var baseUrlListaEspera = 'lista-espera/quadro-informativo-lista-espera';

        var service = ServiceBuilder.create()
            .path(baseUrl)
            .build();

        service.getProfessores = getProfessores;
        service.getEstabelecimentos = getEstabelecimentos;
        service.getAlunos = getAlunos;
        service.getVagasDisponiveis = getVagasDisponiveis;
        service.getVagasDisponiveisPorAno = getVagasDisponiveisPorAno;
        service.getFuncionarios = getFuncionarios;
        service.getEmEspera = getEmEspera;
        service.getTurmas = getTurmas;
        service.getListaEsperaMatriculados = getListaEsperaMatriculados;
        service.getAlunosPorAno = getAlunosPorAno;
        service.getListagemEstabelecimentos = getListagemEstabelecimentos;
        service.getInscritosListaEsperaPorAno = getInscritosListaEsperaPorAno;
        service.getAnosLetivos = getAnosLetivos;

        function getProfessores(params) {
            return Restangular.all(baseUrl).get('professores', params).then(strip);
        }

        function getEstabelecimentos(params) {
            return Restangular.all(baseUrl).get('estabelecimentos', params).then(strip);
        }

        function getAlunos(params) {
            return Restangular.all(baseUrl).get('alunos', params).then(strip);
        }

        function getVagasDisponiveis(params) {
            return Restangular.all(baseUrl).get('vagas-disponiveis', params).then(strip);
        }

        function getVagasDisponiveisPorAno(params) {
            return Restangular.all(baseUrl).get('vagas-por-ano', params).then(strip);
        }

        function getFuncionarios(params) {
            return Restangular.all(baseUrl).get('funcionarios', params).then(strip);
        }

        function getEmEspera(params) {
            return Restangular
                .all(baseUrlListaEspera)
                .one('saldo-inscricao')
                .get(params)
                .then(strip);
        }

        function getInscritosListaEsperaPorAno(params) {
            return Restangular
                .all(baseUrlListaEspera)
                .one('saldo-inscricao-por-ano')
                .get(params)
                .then(strip);
        }

        function getListaEsperaMatriculados(params) {
            return Restangular.all('lista-espera/quadro-informativo-lista-espera/saldo-inscricao-matriculados').get('', params).then(strip);
        }

        function getTurmas(params) {
            return Restangular.all(baseUrl).get('turmas', params).then(strip);
        }

        function getAlunosPorAno(params) {
            return Restangular.all(baseUrl).get('alunos-por-ano', params).then(strip);
        }

        function getListagemEstabelecimentos(params) {
            return Restangular.all(baseUrl).get('listagem-estabelecimentos', params);
        }

        function getAnosLetivos(params) {
            return Restangular.all(baseUrl).get('anos-letivos-lista-espera', params);
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }

        return service;
    }

})();
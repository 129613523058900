(function() {
    'use strict';

    var TIPO_MOVIMENTACAO = 'READMISSAO';
    var SITUACOES_DESFAZER_EJA_MODULAR = ['CANCELAMENTO', 'TRANSFERIDO', 'DEIXOU_FREQUENTAR'];

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MovimentacaoReadmissaoModalController', Controller);

    Controller.$inject = ['bfc.Notification', 'educacao.matricula.MatriculaService', '$modalInstance'];

    function Controller(bfcNotification, MatriculaService, $modalInstance) {
        var vm = this;

        vm.matricula = _.get($modalInstance, 'params.matricula');
        vm.movimentacao = {
            tipo: TIPO_MOVIMENTACAO
        };

        vm.movimentar = movimentar;

        function movimentar() {
            var promise;

            if (isEjaModular() && _.includes(SITUACOES_DESFAZER_EJA_MODULAR, vm.matricula.situacao)) {
                promise = MatriculaService.movimentarEjaModular(vm.matricula.id, [], vm.movimentacao).then(thenMovimentacao);
            } else {
                promise = MatriculaService.movimentar(vm.matricula.id, vm.movimentacao).then(thenMovimentacao);
            }

            $modalInstance.tracker.addPromise(promise);
        }

        function thenMovimentacao() {
            bfcNotification.publish('Movimentação realizada com sucesso!', 'success');
            $modalInstance.close();
        }

        function isEjaModular() {
            return vm.matricula.tipoMatricula === 'EJA' && _.get(vm.matricula, 'matriz.modular');
        }
    }
})();

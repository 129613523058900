(function () {

    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.CursoFormacaoSuperiorService', FormacaoSuperiorService);

    FormacaoSuperiorService.$inject = ['ServiceBuilder'];

    function FormacaoSuperiorService(ServiceBuilder) {

        var self = ServiceBuilder.create()
            .path('inep', 'curso-formacao-superior-inep')
            .build();

        self.getAreasFormacaoSuperior = getAreasFormacaoSuperior;

        return self;

        function getAreasFormacaoSuperior(filter){
            var params = {
                filter: filter
            };
            return self.getEndpoint().get('areas', params);
        }
    }
})();
(function () {

    'use strict';

    angular.module('educacao.matriz')
        .service('educacao.matriz.NivelEscolarUtilsService', NivelEscolarUtilsService);

    NivelEscolarUtilsService.$inject = ['NIVEL_ESCOLAR'];

    function NivelEscolarUtilsService(NIVEL_ESCOLAR) {

        var self = this;

        self.isEnsinoFundamental = isEnsinoFundamental;
        self.isEducacaoInfantil = isEducacaoInfantil;
        self.isEnsinoMedio = isEnsinoMedio;

        function isEnsinoFundamental(nivelEscolar) {
            return nivelEscolar === NIVEL_ESCOLAR.ENSINO_FUNDAMENTAL;
        }

        function isEducacaoInfantil(nivelEscolar) {
            return nivelEscolar === NIVEL_ESCOLAR.EDUCACAO_INFANTIL;
        }

        function isEnsinoMedio(nivelEscolar) {
            return nivelEscolar === NIVEL_ESCOLAR.ENSINO_MEDIO;
        }

    }
})();
(function() {
	'use strict';

	angular.module('educacao.matriz')
		.factory('educacao.matriz.CampoExperienciaService', Service);

	Service.$inject = ['$injector'];

	function Service($injector) {

		var ServiceBuilder = $injector.get('ServiceBuilder');

		var service = ServiceBuilder.create()
			.path('campo-experiencia')
			.build();

		return service;
	}
})();

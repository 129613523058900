(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaConfiguracaoEtapa', appConfiguracaoListaEsperaConfiguracaoEtapa);

    function appConfiguracaoListaEsperaConfiguracaoEtapa() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-configuracao-etapa/app-configuracao-lista-espera-configuracao-etapa.directive.html',
            scope: {},
            bindToController: {
                nivelEscolar: '=',
                tabNivelAtiva: '=',
                tabModalidadeAtiva: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaUtils',
        '$scope',
        'CONFIGURACAO_ETAPAS'
    ];

    function Controller(ConfiguracaoListaEsperaUtils, $scope, CONFIGURACAO_ETAPAS) {
        var vm = this;

        $scope.$watchGroup(['vm.tabNivelAtiva', 'vm.tabModalidadeAtiva'], setNivel);

        $scope.$watch('vm.form.$invalid', setFormInvalido);

        vm.searchMatriz = searchMatriz;
        vm.ajusteMatriz = ajusteMatriz;
        vm.configuracaoEtapas = CONFIGURACAO_ETAPAS;

        function setFormInvalido(invalido) {
            if (vm.nivel && invalido) {
                vm.nivel.formularioInvalido = invalido;
            }
            if (_.get(vm.nivel, 'confiEtapas') === vm.configuracaoEtapas.SUGERIDAS_PELO_SISTEMA && !invalido) {
                vm.nivel.formularioInvalido = invalido;
            }
        }

        function setNivel() {
            vm.nivel = _.find(vm.nivelEscolar, 'nivelEscolar', vm.tabNivelAtiva);
        }

        function searchMatriz(params) {
            return ConfiguracaoListaEsperaUtils.searchMatriz(params, vm.tabModalidadeAtiva, vm.tabNivelAtiva);
        }

        function ajusteMatriz(matriz) {
            _.forEach(_.get(matriz, 'etapas'), ajusteEtapaInfantil);
        }

        function ajusteEtapaInfantil(etapa) {
            etapa.anoIdadeMaxima = _.get(etapa, 'anoIdadeMaxima.id') !== undefined ? etapa.anoIdadeMaxima.id + '' : etapa.anoIdadeMaxima + '';
            etapa.mesIdadeMaxima = _.get(etapa, 'mesIdadeMaxima.id') !== undefined ? etapa.mesIdadeMaxima.id + '' : etapa.mesIdadeMaxima + '';
            etapa.anoIdadeMinima = _.get(etapa, 'anoIdadeMinima.id') !== undefined ? etapa.anoIdadeMinima.id + '' : etapa.anoIdadeMinima + '';
            etapa.mesIdadeMinima = _.get(etapa, 'mesIdadeMinima.id') !== undefined ? etapa.mesIdadeMinima.id + '' : etapa.mesIdadeMinima + '';
            etapa.turnos = ['TODOS'];
            
            if (!etapa.mesCompletarIdadeMinima && !etapa.dataParaCompletarIdadeMinima) {
                return;
            }

            if (!etapa.tipoDataCorte) {
                etapa.mesCompletarIdadeMinima = etapa.dataParaCompletarIdadeMinima ? moment(etapa.dataParaCompletarIdadeMinima).month() + '' : etapa.mesCompletarIdadeMinima.id - 1 + '';
                etapa.diaCompletarIdadeMinima = etapa.dataParaCompletarIdadeMinima ? moment(etapa.dataParaCompletarIdadeMinima).format('DD') : etapa.diaCompletarIdadeMinima;
            }
    }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.HttpRequestInterceptor', HttpRequestInterceptor);

    HttpRequestInterceptor.$inject = ['API_URL'];

    function HttpRequestInterceptor(API_URL) {
        return {
            request: function(configuration) {
                if (configuration.method === 'GET' &&
                    configuration.url.indexOf(API_URL) >= 0 &&
                    configuration.params && configuration.params.filter) {
                    configuration.headers['Filter-Encoded'] = true;
                }
                return configuration;
            }
        };
    }

})();


(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.common');

	/**
	 * Factory para ser utilizada no filtro do valor e irá definir
	 * corretamente a descrição do valor.
	 */
	moduloCommon.factory('educacao.common.SearchValor', ['$filter', 'bfc.Notification', 'Popover',
		function($filter, Notification, Popover) {

			/**
			 * Função que irá montar a label a ser mostrada após pesquisar
			 * @param {object} search      	objeto que possui as váriaveis valor para validar e montar a label
			 */

			function setDescricaoValorSearch(search) {
				if (search.valorInicial && search.valorFinal) {
					if (search.valorInicial === search.valorFinal) {
						search.valor = 'Igual a ' + $filter('currency')(search.valorFinal, 'R$');
					} else {
						search.valor = 'De ' + $filter('currency')(search.valorInicial, 'R$') + ' até ' + $filter('currency')(search.valorFinal, 'R$');
					}
				} else if (search.valorInicial) {
					search.valor = 'A partir de ' + $filter('currency')(search.valorInicial, 'R$');
				} else if (search.valorFinal) {
					search.valor = 'Até ' + $filter('currency')(search.valorFinal, 'R$');
				} else if (search.valorInicial === search.valorFinal) {
					search.valor = 'Igual a ' + $filter('currency')(search.valorFinal, 'R$');
				}
			}

			function closePopover() {
				Popover.hide();
			}

			return {
				initPreValor: function(search) {
					if (!search.valor) {
						search.preValorInicial = undefined;
						search.preValorFinal = undefined;
					}
				},
				setValorSearch: function(search, doSearch) {
					if (!search.preValorInicial && !search.preValorFinal) {
						search.valorInicial = search.valorFinal = 0;
					} else {
						search.valorInicial = search.preValorInicial || undefined;
						search.valorFinal = search.preValorFinal || undefined;

						if (search.preValorInicial && search.preValorFinal && search.preValorInicial > search.preValorFinal) {
							Notification.publish('Valor m\u00ednimo n\u00e3o pode ser superior ao valor m\u00e1ximo.', 'warn');
							return;
						}
					}
					setDescricaoValorSearch(search);
					closePopover();
					doSearch();
				}
			};
		}
	]);
})();

(function(){
    
    'use strict';

    angular.module('educacao.funcionario')
        .config(stateConfig);

        stateConfig.$inject = [
            '$stateProvider',
            'STATES_ADICIONAR',
            'STATES_EDITAR'
        ];
    

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.funcionario.tipocargo';
        $stateProvider.state(STATE, {
            url: 'tipos-cargo',
            data: { 
                id: 'TipoCargoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'planejamento/funcionarios/tipo-cargo/tipo-cargo.html'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.funcionario.TipoCargoService',
            '$modalStates'
        ];

        function openModal($stateParams, TipoCargoService, $modalStates) {
            $modalStates.open({
                getMethod: TipoCargoService.get,
                templateUrl: 'planejamento/funcionarios/tipo-cargo/tipo-cargo.cad.html',
                controller: 'educacao.funcionario.TipoCargoCadCtrl as vm',
                propertyName: 'tipoCargo',
                stateParams: $stateParams
            });
        }
    }
    
})();
(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .service('configuracao-avaliacao-turma.ConfiguracaoAvaliacaoTurmaService', ConfiguracaoAvaliacaoTurmaService);

    ConfiguracaoAvaliacaoTurmaService.$inject = [
        'Restangular',
        '$q'
    ];

    function ConfiguracaoAvaliacaoTurmaService(Restangular,$q) {
        var self = this;

        self.save = save;
        self.saveConfiguracaoPorPeriodoEspecifico = saveConfiguracaoPorPeriodoEspecifico;

        function save(configuracaoAvaliacaoTurma) {
            return Restangular.all('matricula/turma/configuracao-avaliacoes')
                .customPOST(configuracaoAvaliacaoTurma);
        }

        function saveConfiguracaoPorPeriodoEspecifico(params, isLagoaSanta) {
            if (!isLagoaSanta) {
                return  $q.resolve();
            }
            return Restangular.all('matricula/turma/configuracao-avaliacoes/habilitar-configuracao-periodo')
                .customPOST(params);
        }
    }

})();

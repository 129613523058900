(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectMatriculaEjaModular', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<input ng-model="ngModel" ng-change="ngChange" ng-disabled="ngDisabled" ng-required="ngRequired" data-bf-select2="vm.configSelect2" type="hidden" />',
            scope: {
                ngModel: '=',
                ngChange: '=',
                ngDisabled: '=',
                ngRequired: '='
            },
            bindToController: {
                matriculaId: '=',
                matriculaPaiId: '=',
                turmaId: '=',
                filho: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function(element, attrs) {
                var multiple = angular.isDefined(attrs.multiple);
                if (multiple) {
                    element.children('input').attr('multiple', true);
                }

                return {
                    post: function($scope) {
                        $scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'bfc.Select2Config', 'educacao.matricula.MatriculaService'];

    function Controller($scope, Select2Config, MatriculaService) {
        var vm = this;

        vm.configSelect2 = _configSelect2();

        vm.matriculas = [];

        $scope.$watch('ngModel', watch);
        $scope.$watch('vm.matriculaId', watchMatricula);

        function watch(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            if (_.find($scope.ngModel, 'id', 'TODOS')) {
                $scope.ngModel = vm.matriculas;
            }
        }

        function watchMatricula() {
            if (!vm.matriculaId || !vm.filho) {
                return;
            }

            var filter = 'dataFim is null and matricula.pai.id =  ' + vm.matriculaPaiId;
            if (vm.turmaId) {
                filter += ' and turma = ' + vm.turmaId;
            }

            return MatriculaService.getEnturmacoes({filter: filter}).then(thenResult);

            function thenResult(data) {
                vm.matriculas = _.map(data.content, mapData);

                var value = _.find(vm.matriculas, 'id', vm.matriculaId);
                if (value) {
                    $scope.ngModel = [value];
                }
            }
        }

        function _configSelect2() {
            function _formatResult(data) {
                return data.descricao;
            }

            function _formatSelection(data) {
                return data.descricao;
            }

            function _formatValue(data) {
                return data;
            }

            function _initSelection() {}

            function _result(params, callback) {
                params.filter = 'dataFim is null';

                if (vm.filho) {
                    params.filter += ' and matricula.pai.id =  ' + vm.matriculaPaiId + ' and turma = ' + vm.turmaId;
                } else {
                    params.filter += ' and matricula.pai.id = ' + vm.matriculaId;
                }

                return MatriculaService.getEnturmacoes(params).then(thenResult);

                function thenResult(data) {
                    callback({
                        results: filterResult(data.content),
                        more: false
                    });
                }

                function filterResult(data) {
                    if (_.find($scope.ngModel, 'id', 'TODOS')) {
                        return [];
                    }

                    var dataFormatted = _.map(data, mapData);

                    vm.matriculas = _.clone(dataFormatted);

                    if (_.get(dataFormatted, 'length')) {
                        dataFormatted.push({id: 'TODOS', descricao: 'Todos'});
                    }

                    return dataFormatted;
                }
            }

            return Select2Config.create({
                initSelection: _initSelection,
                formatResult: _formatResult,
                formatSelection: _formatSelection,
                formatValue: _formatValue,
                result: _result
            });
        }

        function mapData(val) {
            return {
                id: val.matricula.id,
                descricao: _.get(_.first(val.itensEducacionais), 'descricao')
            };
        }
    }
})();


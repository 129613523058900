(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.RegistroVinculadoModalController', RegistrosVinculadosController);

    
    RegistrosVinculadosController.$inject = [
        'REGISTRO_VINCULADO',
        'educacao.calendario.RegistroVinculadoService', 
        'educacao.context.ContextoEstabelecimento',
        'educacao.common.ModalConfirm',
        'promiseTracker',
        '$modalInstance', 
        '$q'
    ];

    function RegistrosVinculadosController(
        REGISTRO_VINCULADO,
        RegistroVinculadoService, 
        ContextoEstabelecimento, 
        modalConfirm,
        promiseTracker, 
        $modalInstance, 
        $q
    ) {
        var vm = this;

        vm.searchParams = [];
        vm.resultsFound = [];
        vm.allowPurge = false;
        vm.trackerSearch = promiseTracker();
        vm.trackerPurge = promiseTracker();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.registroVinculadoService = RegistroVinculadoService;

        var confirmOptions = {
            title: 'Confirmar exclusão',
            message: 'Esta ação irá excluir todos os registros vinculados do(s) período(s) pesquisado(s). Deseja continuar?'
        };

        vm.addSearchParam = addSearchParam;
        vm.delSearchParam = delSearchParam;
        vm.changeSearchParam = changeSearchParam;
        vm.disabledSearch = disabledSearch;
        vm.searchResults = searchResults;
        vm.purgeResults = purgeResults;

        init();

        function init() {
            addSearchParam();
        }

        function addSearchParam() {
            var searchParam = {
                dataInicial: null,
                dataFinal: null,
                letivo: null,
                invalidAfterdate: false,
                invalidMaxdate: false,
                estabelecimento: !vm.isSecretaria ? [vm.estabelecimento] : [],
                matriz: []
            };

            vm.searchParams.push(searchParam);
        }

        function delSearchParam(index) {
            vm.searchParams.splice(index, 1);
        }

        function changeSearchParam(searchParam) {
            var dataInicial = searchParam.dataInicial ? moment(searchParam.dataInicial) : null;
            var dataFinal = searchParam.dataFinal ? moment(searchParam.dataFinal) : null;

            if (!dataInicial || !dataFinal) {
                return;
            }

            if (dataInicial.isAfter(dataFinal)) {
                searchParam.invalidAfterdate = true;
            } else {
                searchParam.invalidAfterdate = false;
            }

            if (dataFinal.diff(dataInicial, 'days') > 14) {
                searchParam.invalidMaxdate = true;
            } else {
                searchParam.invalidMaxdate = false;
            }
        }

        function disabledSearch() {
            return _.any(vm.searchParams, any);

            function any(value) {
                return value.invalidAfterdate === true || value.invalidMaxdate === true || !_.isBoolean(value.letivo);
            }
        }
        
        function searchResults() {
            vm.allowPurge = false;
            vm.resultsFound = [];
            var results = [];
            var promises = [];

            vm.searchParams.forEach(eachSearchParams);

            var searchPromise = $q.all(promises).then(thenSearch);
            
            vm.trackerSearch.addPromise(searchPromise);

            function eachSearchParams(searchParam) {
                var dataInicial = _.get(searchParam, 'dataInicial');
                var dataFinal = _.get(searchParam, 'dataFinal');
                var estabelecimento = _.pluck(searchParam.estabelecimento, 'id');
                var matriz = _.pluck(searchParam.matriz, 'id');

                if (!dataInicial || !dataFinal) {
                    return;
                }

                if (searchParam.letivo === true) {
                    results.push({
                        dataInicial: dataInicial,
                        dataFinal: dataFinal,
                        temTotal: false,
                        letivo: true
                    });

                    return;
                }

                var promise = RegistroVinculadoService.getTotalizadores(dataInicial, dataFinal, estabelecimento, matriz);

                promise.then(function(total) {
                    if (
                        !total.totalConteudoPlanejado && 
                        !total.totalConteudoMinistrado &&  
                        !total.totalAlocacaoAula && 
                        !total.totalJustificativaFalta &&
                        !total.totalAbonoFalta &&
                        !total.totalFaltaAula &&
                        !total.totalFaltaDia

                    ) {
                        total.temTotal = false;
                    } else {
                        total.temTotal = true;
                    }

                    total.totalFaltas = total.totalFaltaAula + total.totalFaltaDia;
                    total.dataInicial = dataInicial;
                    total.dataFinal = dataFinal;
                    total.letivo = false;
                    total.estabelecimento = estabelecimento;
                    total.matriz = matriz;
                    total.expression = buildExpression(dataInicial, dataFinal, estabelecimento, matriz);

                    results.push(total);
                });

                promises.push(promise);
            }

            function thenSearch() {
                vm.allowPurge = _.any(results, 'temTotal', true);

                vm.resultsFound = _.sortByAll(results, ['dataInicial', 'dataFinal']);
            }
        }

        function purgeResults() {
            var promise = modalConfirm(confirmOptions)
                .then(remove)
                .then(closeModal);

            vm.trackerPurge.addPromise(promise);
        }

        function remove() {
            var results = _.chain(vm.resultsFound)
                            .filter(filterResults)
                            .map(mapResults)
                            .value();

            if (_.isEmpty(results)) {
                return;
            }

            return RegistroVinculadoService.delRegistrosVinculados(results);
        }

        function closeModal() {
            return $modalInstance.close();
        }

        function filterResults(searchResult) {
            if (searchResult.letivo === true || searchResult.temTotal === false) {
                return;
            }

            return searchResult;
        }

        function mapResults(searchResult) {
            var tiposRegistroVinculado = [];

            if (searchResult.totalConteudoPlanejado) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_CONTEUDO_PLANEJADO);
            }

            if (searchResult.totalConteudoMinistrado) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_CONTEUDO_MINISTRADO);
            }

            if (searchResult.totalAlocacaoAula) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_ALOCACAO);
            }

            if (searchResult.totalJustificativaFalta) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_JUSTIFICATIVA);
            }

            if (searchResult.totalAbonoFalta) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_ABONO);
            }

            if (searchResult.totalFaltaAula) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_FALTA_AULA);
            }

            if (searchResult.totalFaltaDia) {
                tiposRegistroVinculado.push(REGISTRO_VINCULADO.VINCULO_FALTA_DIA);
            }

            return {
                dataInicio: searchResult.dataInicial,
                dataFim: searchResult.dataFinal,
                estabelecimentos: searchResult.estabelecimento,
                matrizes: searchResult.matriz,
                tiposRegistroVinculado: tiposRegistroVinculado
            };
        }

        function buildExpression(dataInicial, dataFinal, estabelecimento, matriz) {
            return {
                onBeforeBuild: function() {
                    var filter = 'data >= ' + dataInicial;
                    filter += ' and data <= ' + dataFinal;

                    if (!_.isEmpty(estabelecimento)) {
                        filter += ' and estabelecimento.id in (' + estabelecimento.join(',') + ')';
                    }

                    if (!_.isEmpty(matriz)) {
                        filter += ' and matriz.id in (' + matriz.join(',') + ')';
                    }

                    return filter;
                }
            };
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('contextBar', directive);

    function directive() {
        return {
            restrict: 'E',

            templateUrl: 'common/layout/header/context-bar/context-bar.directive.html',
            replace: true,

            scope: {
                headerOptions: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.context.ContextoEntidade',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        '$rootScope'
    ];

    function Controller($scope, ContextoEntidade, ContextoEstabelecimento, ContextoAnoLetivo, $rootScope) {

        var vm = this;

        vm.hasEntidade = ContextoEntidade.hasEntidade;
        vm.hasEstabelecimento = ContextoEstabelecimento.hasEstabelecimento;
        vm.hasAnoLetivo = ContextoAnoLetivo.hasAnoLetivo;

        vm.pin = pin;

        function pin() {
            $rootScope.$$pinned = !$rootScope.$$pinned;
            $scope.headerOptions.pinned = !$scope.headerOptions.pinned;
        }
    }
})();


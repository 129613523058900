(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizAddCamposExperienciaCtrl', [
        '$modalInstance',
        'promiseTracker',
        'bfc.Focus',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.EtapaDisciplinaService',
        'educacao.matriz.MatrizService',
        'ui.components.Modal',
        '$scope',
        '$rootScope',

    function Controller($modalInstance, promiseTracker, focus, ObjectUtilsService, EtapaDisciplinaService, MatrizService,  $modal, $scope, $rootScope) {

        var vm = this;

        vm.descricaoEtapa = descricaoEtapa;
        vm.cancel = cancel;
        vm.objetivoSelectChange = objetivoSelectChange;
        vm.removerObjetivo = removerObjetivo;
        vm.openObjetivo = openObjetivo;
        vm.save = save;
        vm.remove = remove;

        vm.tracker = {};
        vm.tracker.botoes = promiseTracker();
        vm.tracker.camposAdicionais = promiseTracker();

        init();

        function init(){
            vm.etapaMatrizDisciplina = $modalInstance.params.etapaMatrizDisciplina;
            vm.etapa = $modalInstance.params.etapa;
            vm.matriz = $modalInstance.params.matriz;
            vm.optionsCad = $modalInstance.params.optionsCad;
            vm.camposExperienciaExistentes = $modalInstance.params.camposExperienciaExistentes;

            vm.adicionando = !vm.etapaMatrizDisciplina;

            initCamposAdicionais();
        }

        function descricaoEtapa(etapaSelecionada) {
            return MatrizService.getDescricaoEtapa(vm.matriz, etapaSelecionada);
        }

        function cancel () {
            $modalInstance.close();
        }

        function objetivoSelectChange () {
            if(!vm.objetivoAprendizagem){
                return;
            }

            vm.etapaMatrizDisciplina.objetivosAprendizagem = vm.etapaMatrizDisciplina.objetivosAprendizagem || [];

            var objetivoAprendizagem = _.find(vm.etapaMatrizDisciplina.objetivosAprendizagem, {
                'id': vm.objetivoAprendizagem.id
            });

            if (objetivoAprendizagem) {
                ObjectUtilsService.copyProperties(objetivoAprendizagem, vm.objetivoAprendizagem);
            } else {
                vm.etapaMatrizDisciplina.objetivosAprendizagem.push(vm.objetivoAprendizagem);
            }

            vm.objetivoAprendizagem = null;
            $scope.$applyAsync('');
        }

        function removerObjetivo (objetivoId) {
            vm.etapaMatrizDisciplina.objetivosAprendizagem = _.reject(vm.etapaMatrizDisciplina.objetivosAprendizagem, {
                'id': objetivoId
            });
        }

        function remove() {
            var tracker = vm.tracker.botoes;
            tracker.addPromise(
                EtapaDisciplinaService.remove(vm.matriz.id, vm.etapa.id, vm.etapaMatrizDisciplina.id)
                .then(function () {
                    var args = {
                        data: vm.etapaMatrizDisciplina,
                        etapa: vm.etapa,
                        remove: true
                    };

                    onSuccess(false, tracker, args);
                })
            );
        }

       function openObjetivo (objetivoAprendizagem) {
            $modal.open({
                templateUrl: 'planejamento/matriz/objetivos-aprendizagem/cadastro-objetivos-aprendizagem.html',
                controller: 'educacao.matriz.ObjetivosAprendizagemCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    objetivoAprendizagem: angular.copy(objetivoAprendizagem),
                    openByMatriz: true,
                    canSaveAdd: true
                }
            }).result.then(setObjetivo);
        }

        function setObjetivo(data){
            vm.objetivoAprendizagem = data;

            objetivoSelectChange();
        }

        function save (continuar) {
            var tracker = vm.tracker.botoes;
            vm.etapaMatrizDisciplina.campoAdicional = vm.campoAdicional;
            tracker.addPromise(EtapaDisciplinaService.save(vm.matriz.id, vm.etapa.id, vm.etapaMatrizDisciplina)
                .then(function (data) {
                    var args = {
                        data: data,
                        etapa: vm.etapa,
                        add: !vm.etapaMatrizDisciplina.id,
                        edit: Boolean(vm.etapaMatrizDisciplina.id)
                    };

                    onSuccess(continuar, tracker, args);
                }));
        }

        function onSuccess(continuar, tracker, args) {
            if (continuar) {
                $rootScope.$broadcast('refreshEtapasDisciplinas', args);

                vm.etapaMatrizDisciplina.id = null;
                tracker.addPromise(refresh());
                focus('lbCampoExperiencia');
            } else {
                $modalInstance.close(args);
            }
        }

        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            return Boolean(vm.etapaMatrizDisciplina.id) ?
                EtapaDisciplinaService.get(vm.matriz.id, vm.etapa.id, vm.etapaMatrizDisciplina.id).then(function (data) {
                    vm.etapaMatrizDisciplina = data;
                }) :
                EtapaDisciplinaService.getDefault(vm.matriz.id, vm.etapa.id).then(function (data) {
                    vm.etapaMatrizDisciplina = angular.extend(data, vm.optionsCad);
                });
        }

        function initCamposAdicionais() {
            var promise = EtapaDisciplinaService.camposAdicionais(vm.matriz.id, vm.etapa.id, _.get(vm.etapaMatrizDisciplina, 'id'))
                .then(function(data) {
                    vm.campoAdicional = data;
                });
            vm.tracker.camposAdicionais.addPromise(promise);
        }

    }]);
})();
(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.FiliacaoCadCtrl', FiliacaoCadCtrl);

    FiliacaoCadCtrl.$inject = [
        '$q',
        'bfc.Focus',
        '$modalInstance',
        'promiseTracker',
        'ui.components.Modal',
        '$enumsCache',
        'educacao.pessoas.FiliacaoService',
        'educacao.pessoas.PessoaService',
        '$scope',
        'educacao.pessoas.AlunoService'
    ];

    function FiliacaoCadCtrl(
        $q,
        focus,
        $modalInstance,
        promiseTracker,
        $modal,
        $enumsCache,
        FiliacaoService,
        PessoaService,
        $scope,
        AlunoService
    ) {
        var vinculoDefault = {
            permiteRetirarAluno: null,
            diasDaSemana: []
        };

        var vm = this,
        vinculos = [];
        var aluno = $modalInstance.params.aluno || {};
        vm.filiacao = $modalInstance.params.filiacao;
        vm.vinculo = $modalInstance.params.vinculo || angular.copy(vinculoDefault);

        vm.editando = _.get(vm.filiacao, 'id');
        vm.searchMethod = AlunoService.getPessoas;
        vm.filiacaoService = FiliacaoService;
        vm.cancelar = cancelar;

        vm.tracker = {
            loading: promiseTracker(),
            save: promiseTracker(),
            saveContinue: promiseTracker(),
            remove: promiseTracker()
        };
        vm.pessoaSelecionada = pessoaSelecionada;
        vm.save = save;
        vm.remove = remove;
        vm.openObito = openObito;
        vm.windowTitle = vm.editando ? 'Editando filiação' : 'Adicionando filiação';


        init();
        initCamposAdicionais();

        function inicializaEnums() {
            vm.options = {
                GrauInstrucao: FiliacaoService.getGrauInstrucao(),
                EstadoCivil: FiliacaoService.getEstadoCivil(),
                Parentesco: $enumsCache.get('Parentesco'),
                SituacaoTrabalho: FiliacaoService.getSituacaoTrabalho(),
                TipoFiliacao: FiliacaoService.getTipoFiliacao(),
            };
            FiliacaoService.getNaturezaFiliacao().then(function (naturezaFiliacao) {
                vm.options.NaturezaFiliacao = naturezaFiliacao;

            });
            FiliacaoService.getSexo().then(function (sexo) {
                vm.options.Sexo = sexo;
            });
        }

        function init() {
            $modalInstance.tracker.addPromise(
                $q.all([
                    inicializaEnums()
                ]).then(
                    function () {
                        vm.windowTitle = vm.editando ? 'Editando Filiação' : 'Adicionando Filiação';
                    })
            );
        }

        function initCamposAdicionais() {
            var filiacaoId = _.get(vm.filiacao, 'id');

            var promise = FiliacaoService.camposAdicionais(filiacaoId).then(function(data) {
                vm.campoAdicional = data;
            });

            vm.tracker.loading.addPromise(promise);
        }

        function pessoaSelecionada(filiacao) {
            if (filiacao !== null) {
                vm.filiacao = filiacao;
            }

            if (filiacao._id) {
                var load = PessoaService.getPessoas(filiacao._id)
                    .then(_.bind(onLoadPessoa, null, filiacao._id));
                return vm.tracker.loading.addPromise(load);
            }

            vm.editando = _.get(filiacao, 'id');
            var indexOrdem = _.findIndex(vinculos, function (data) {
                return data.filiacao.id === filiacao.id;
            });
            if(indexOrdem < 0){
                return;
            }
            vinculos.splice(indexOrdem, 1);
        }

        function onLoadPessoa(idTemplate, filiacao) {
            novaFiliacao();
            vm.filiacao.pessoa = filiacao;
        }

        function novaFiliacao() {
            verificaFormulario();
            vm.filiacao = {
                enderecos: [],
                pessoa: {
                    telefones: [],
                    emails: []
                }
            };
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }

        function limpaForm() {
            vm.form.$setPristine();
            vm.form.$setUntouched();
            initCamposAdicionais();
        }



        function onSuccess(continuar, vinculo) {
            if (continuar) {
                focus('lbNomeFiliacao');
                vinculos.push(vinculo);
                vm.filiacao = {
                    pessoa: {
                        telefones: [],
                        emails: []
                    }
                };
                vm.vinculo = {};
                limpaForm();
                return;
            }
            if (vinculos.length > 0) {
                vinculos.push(vinculo);
            }
            var objetoRetorno = {
                vinculo: vinculos.length === 0 ? vinculo : null,
                vinculos: vinculos,
                edit: _.get(vm.filiacao, 'id') ? true : false
            };
            $modalInstance.close(objetoRetorno);
        }

        function save(continuar) {
            vm.tracker.loading = continuar ? vm.tracker.saveContinue : vm.tracker.save;

            vm.filiacao.campoAdicional = vm.campoAdicional;

            vm.tracker.loading.addPromise(
                FiliacaoService.save(vm.filiacao).then(function (data) {
                    var vinculo = montaVinculoFiliacao(data);
                    onSuccess(continuar, vinculo);
                })
            );

        }

        function montaObjetoFiliacao(data) {
            return {
                id: data.id,
                nome: data.pessoa.nome,
                pessoa: {
                    telefone: data.pessoa.telefone,
                    celular: data.pessoa.celular
                },
                tipo: data.tipo
            };
        }
        function montaVinculoFiliacao(data) {
            return {
                aluno: {
                    id: aluno.id
                },
                filiacao: montaObjetoFiliacao(data)
            };
        }

        function remove() {
            vm.tracker.loading = vm.tracker.remove;
            var objetoRetorno = {
                vinculo: {
                    filiacao: vm.filiacao
                },
                remove: true,
            };
            vm.tracker.loading.addPromise(
                FiliacaoService.remove(vm.filiacao)
                    .then(
                    function () {
                        $modalInstance.close(objetoRetorno);
                    })
            );
        }

        function cancelar() {
            var objetoRetorno;
            if (vinculos.length) {
                objetoRetorno = {};
                objetoRetorno.vinculos = vinculos;
            }
            $modalInstance.close(objetoRetorno);
        }

        function openObito() {
            return $modal.open({
                templateUrl: 'planejamento/alunos/popups/cadastro-obito.html',
                controller: 'educacao.common.CadastroObitoController as vm',
                params: {
                    brodcastReturn: 'returnModalComp:Filiacao',
                    dataValue: angular.copy(vm.filiacao),
                    canSaveAndAdd: !aluno
                }
            }).result.then(setFiliacao);
        }

        function setFiliacao(data) {
            vm.filiacao = data;
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matricula')
        .directive('inputNumeroChamada', inputNumeroChamada);

    function inputNumeroChamada() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/app-card-turma/input-numero-chamada/input-numero-chamada.directive.html',
            scope: {
                enturmacao: '=',
                turma: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'educacao.matricula.EnturmacaoService', 'bfc.Focus','$enumsCache'];

    function Controller($scope, EnturmacaoService, focus, $enumsCache) {
        var vm = this;

        vm.editarNumeroChamada = _editarNumeroChamada;
        vm.turma = $scope.turma;

        init();
        
        function init(){
            vm.copyEnturmacao = angular.copy($scope.enturmacao);
            getEnums();
        }

        function _editarNumeroChamada(enturmacao) {
			EnturmacaoService.editarNumeroChamada(enturmacao)
				.catch(
					function() {
						enturmacao.numeroChamada = vm.copyEnturmacao.numeroChamada;
						focus('numero-de-chamada-' + enturmacao.id);
					}
				);
		}
        function getEnums() {
			$enumsCache.get('GrupoEnturmacaoCor').then(function (enumData) {
				vm.GrupoEnturmacaoCor = enumData;
				_.forEach(vm.GrupoEnturmacaoCor.values, function(color) {
					color.hex = '#' + color.hex;
				});
			});
		}
    }
})();
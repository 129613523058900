(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appSelectCursoFormacaoSuperior', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-disabled="ngDisabled" ng-change="ngChange()" ng-required="ngRequired" search="vm.search" />',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                ngDisabled: '=',
                areaFormacao: '=',
                ngChange: '&?',
                dropdownCssClass: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.common.CursoFormacaoSuperiorService'
    ];

    function Controller($scope, CursoFormacaoSuperiorService) {

        var vm = this;

        vm.search = search;

        function search(params) {

            var filter = '(grau like "' + params.term + '"';

            if ($scope.areaFormacao) {
                filter += ' and codigoArea = ' + $scope.areaFormacao.id;
            }

            filter += ')';

            return CursoFormacaoSuperiorService.list(filter, params.offset, params.limit);
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.quadro-funcionarios-configuracao')
        .factory('educacao.quadro-funcionarios-configuracao.QuadroFuncionariosService', Service);

    Service.$inject = [
        'ServiceBuilder',
        'Restangular'
    ];

    function Service(ServiceBuilder, Restangular) {
        var baseUrl = 'configuracao-quadro-funcionario';
        var matrizEtapaUrl = 'matriz/matriz-curricular';

        var service = ServiceBuilder.create()
                        .path(baseUrl)
                        .build();

        service.getMatrizEtapa = getMatrizEtapa;
        service.getAllDefault = getAllDefault;
        service.getOne = getOne;

        function getMatrizEtapa(offset, limit, filter) {
            return Restangular.one(matrizEtapaUrl).all('matrizes-quadro-funcionarios').getList({
                offset: offset,
                limit: 999,
                filter: filter
            }).then(strip);
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }

        function getAllDefault(params) {
            return Restangular.all(baseUrl + '/list').get('', params);
        }

        function getOne(id) {
            return Restangular.one(baseUrl, id).get().then(strip);
        }

        return service;
    }
})();
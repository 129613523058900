(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('edHorasAulaSelect', edHorasAulaSelect);

    function edHorasAulaSelect() {

        var configDirective = {};

        configDirective.restrict = 'E';
        configDirective.template = '<ui-select not-empty ng-model="ngModel" ng-disabled="ngDisabled" search="vm.search"></ui-select>';
        configDirective.require = 'ngModel';
        configDirective.scope = {
            ngModel: '=',
            bfRequired: '@',
            ngDisabled: '='
        };

        configDirective.controller = EdHorasAulaSelectCtrl;
        configDirective.controllerAs = 'vm';

        configDirective.compile = function(element, tAttrs) {
            if (angular.isDefined(tAttrs.bfRequired)) {
                element.children('ui-select').attr('bf-required', '{{bfRequired}}');
            }
        };

        return configDirective;
    }

    EdHorasAulaSelectCtrl.$inject = ['$injector'];

    function EdHorasAulaSelectCtrl($injector) {
        var vm = this;
        var HorasAulaService = $injector.get('educacao.matricula.HorasAulaService');

        vm.search = search;

        function search(parans) {
            var filter = '(descricao like "' + parans.term + '")';

            return HorasAulaService.list(filter, parans.offset, parans.limit, null).then(function(data) {
                return data;
            });
        }
    }
})();

(function() {
	'use strict';
	angular.module('educacao.pessoas').directive('appPessoaCaracteristicaFisica', function() {
		var config = {};

		config.restrict = 'E';
		config.templateUrl = 'planejamento/pessoa/app-pessoa-caracteristicas-fisicas.directive.html';
		config.scope = {
			aluno: '=ngModel',
			service: '='
		};
		config.controller = Controller;
		config.controllerAs = 'vm';

		return config;

	});
	Controller.$inject = ['$q', '$scope', 'educacao.restricao-alimentar.IngredienteService', 'educacao.restricao-alimentar.NutrienteService'];

	function Controller($q, $scope, IngredienteService, NutrienteService) {
		var vm = this;

		vm.onChangePossuiPlanoSaude = onChangePossuiPlanoSaude;

		vm.serviceIngrediente = IngredienteService;
		vm.serviceNutriente = NutrienteService;
		vm.enumRaca = $scope.service.getRaca();
		vm.enumCorDosOlhos = $scope.service.getCorOlhos();
		vm.enumHabilidadeMotora = $scope.service.getHabilidadeMotora();
		vm.enumRecursoAvaliacaoInep = $scope.service.getRecursoAvaliacaoInep();
		vm.tipo = $scope.service.getTipoSanguineo();
		$scope.service.getTipoSanguineo().then(
			function(data) {
				var enumTipoSanguineo = {};
				enumTipoSanguineo.values = [];
				angular.forEach(data.values, function(value) {
					var grupo = {
						'id': value.key,
						'key': value.key,
						'description': value.value
					};
					enumTipoSanguineo[value.key] = grupo;
					enumTipoSanguineo.values.push(grupo);
				});

				vm.optionsPromisse = $q.when(enumTipoSanguineo);

			}
		);

		function onChangePossuiPlanoSaude() {
			$scope.aluno.planoSaude = null;
		}

		// vm.serviceIngrediente = IngredienteService;
		// vm.serviceNutriente = NutrienteService;

		// vm.addIngrediente = {
		// 	add: addIngrediente
		// };
		// vm.addNutriente = {
		// 	add: addNutriente
		// };

		// function addIngrediente(descricao) {
		// 	return $commons.$modal.open({
		// 		templateUrl: 'a',
		// 		controller: 'a',
		// 		controllerAs: 'vm',
		// 		params: {
		// 			ingrediente: {
		// 				descricao: descricao || ''
		// 			}
		// 		},
		// 		size: 'sm'
		// 	}).result;
		// }

		// function addNutriente(descricao) {
		// 	return $commons.$modal.open({
		// 		templateUrl: 'a',
		// 		controller: 'a',
		// 		controllerAs: 'vm',
		// 		params: {
		// 			nutriente: {
		// 				descricao: descricao || ''
		// 			}
		// 		},
		// 		size: 'sm'
		// 	}).result;
		// }

	}

})();

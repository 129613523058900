(function() {
    'use strict';

    angular.module('educacao.convencao')
        .controller('educacao.convencao.ConvencaoCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.convencao.ConvencaoService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        ConvencaoService
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.ConvencaoService = ConvencaoService;

        function openCad(convencao) {
            $modalStates.goModalState(convencao);
        }

        function remove(data) {
            return ConvencaoService.remove(data);
        }
    }
})();


(function () {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.CadastroRegiaoController', CadastroRegiaoController);

    CadastroRegiaoController.$inject = [
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'educacao.enderecos.RegiaoService',
        'bfc.Focus'
    ];

    function CadastroRegiaoController($scope, $modalInstance, bfcNotification, RegiaoService, focus) {
        var vm = this;
        var REQ_TYPE_SAVE = 'save';
        var REQ_TYPE_REMOVE = 'remove';

        vm.regiao = $modalInstance.params.regiao || {};
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd === undefined ? true : $modalInstance.params.canSaveAdd;
        vm.editando = _.get($modalInstance.params.regiao, 'id');
        vm.save = save;
        vm.remove = remove;
        vm.windowTitle = '';
        vm.hasReqError = true;

        vm.permissions = {
            save: {
                bfPermissions: 'app.enderecos.regiao',
                operation: 'criar'
            },
            update: {
                bfPermissions: 'app.enderecos.regiao',
                operation: 'editar'
            },
            remove: {
                bfPermissions: 'app.enderecos.regiao',
                operation: 'excluir'
            }
        };

        setWindowTitle();

        function save(continuar) {
            return RegiaoService
                .save(vm.regiao)
                .then(function (data) {
                    vm.regiao = data;
                    handleSuccessNotification(continuar, REQ_TYPE_SAVE);
                });
        }

        function remove() {
            return RegiaoService
                .remove(vm.regiao)
                .then(function () {
                    handleSuccessNotification(false, REQ_TYPE_REMOVE);
                });

        }

        function handleSuccessNotification(continuar, reqType) {
            publishNotification(reqType);

            if (continuar) {
                return refresh();
            }

            closeModal();
        }


        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            vm.regiao = {};
            focus('NomeRegiaoMunicipal');
        }

        function closeModal() {
            $modalInstance.close(vm.regiao);
        }

        function publishNotification(reqType) {
            if (reqType === REQ_TYPE_SAVE) {
                return bfcNotification.publish('Região municipal ' + vm.regiao.nome +   ' salva com sucesso.', 'success');
            }

            return bfcNotification.publish('Região municipal ' + vm.regiao.nome +  ' excluída com sucesso.', 'success');
        }

        function setWindowTitle() {
            if (_.get(vm.regiao, 'id')) {
                vm.windowTitle = 'Editando região municipal';
                return;
            }

            vm.windowTitle = 'Adicionando região municipal';
        }
    }
})();
(function() {
    'use strict';

    var tipoMatricula = [{
            key: 'EDUCACAO_BASICA',
            value: 0,
            description: 'Educação básica'
    }, {
            key: 'COMPLEMENTAR',
            value: 1,
            description: 'Complementar'
    }, {
            key: 'AEE',
            value: 2,
            description: 'AEE'
    },
        {
            key: 'ATIVIDADE_COMPLEMENTAR',
            value: 3,
            description: 'Atividade complementar'
    }, {
            key: 'PROGRESSAO_PARCIAL',
            value: 4,
            description: 'Progressão parcial'
    }, {
            key: 'ACELERACAO',
            value: 5,
            description: 'Aceleração'
    },{
          key: 'EJA',
            value: 6,
            description: 'EJA' 
    }];
    var nivelEscolar = [{
            key: 'EDUCACAO_INFANTIL',
            value: 0,
            description: 'Educação infantil'
    }, {
            key: 'ENSINO_FUNDAMENTAL',
            value: 1,
            description: 'Ensino fundamental'
    }, {
            key: 'ENSINO_MEDIO',
            value: 2,
            description: 'Ensino médio'
    }];

    angular.module('educacao.matricula')
        .controller('educacao.matricula.atestadoDeVaga', Controller);

    Controller.$inject = ['TIPOS_MATRICULAS', '$scope', 'educacao.common.$commons', 'educacao.matricula.RelatorioService',
        'educacao.context.ContextoEstabelecimento', '$q','bfc.Notification'];

    function Controller(TIPOS_MATRICULAS, $scope, $commons, RelatorioService, ContextoEstabelecimento, $q,Notification) {
        var vm = this;

        vm.openTemplateAtestadoVagaImpressao = openTemplateAtestadoVagaImpressao;
        vm.atestadoDeVaga = {};

        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.tiposMatriculas = TIPOS_MATRICULAS;
        vm.formatResultTurma = formatResultTurma;
        vm.formatResultTurno = formatResultTurno;
        vm.isEmpty = isEmpty;
        vm.createAtividadeObject = createAtividadeObject;
        vm.formatResultAtividade = formatResultAtividade;
        vm.promisseTipoMatricula = createPromise(tipoMatricula);
        vm.promisseNivelEscolar = createPromise(nivelEscolar);

        $scope.$watch('vm.atestadoDeVaga.tipoMatricula', changeTipoMatricula);
        $scope.$watch('vm.matriz', changeMatriz);
        $scope.$watch('vm.atestadoDeVaga.etapaMatriz', changeEtapaMatriz);
        $scope.$watch('vm.atestadoDeVaga.vaga', changeTurno);
        $scope.$watch('vm.atestadoDeVaga.turma', changeTurma);
        

        function changeTipoMatricula() {
            delete vm.matriz;
            delete vm.atestadoDeVaga.etapaMatriz;
            delete vm.atestadoDeVaga.vaga;
            delete vm.atestadoDeVaga.turma;
        }

        function changeMatriz() {
            delete vm.atestadoDeVaga.etapaMatriz;
            delete vm.atestadoDeVaga.vaga;
            delete vm.atestadoDeVaga.turma;
        }

        function changeEtapaMatriz() {
            delete vm.atestadoDeVaga.vaga;
            delete vm.atestadoDeVaga.turma;
        }

        function changeTurma(newValue) {
            delete vm.atestadoDeVaga.atividade;

            vm.possuiVaga = 1;
            if (!_.isEmpty(newValue) && vm.tiposMatriculas[vm.atestadoDeVaga.tipoMatricula].tipo !== 'atividade') {
                vm.possuiVaga = newValue.quantidadeMaximaAlunos - newValue.quantidadeAlunos;
            }
        }

        function createAtividadeObject(element) {
            return _.extend(element.atividade, {
                quantidadeMaximaAlunos: element.quantidadeMaximaAlunos,
                quantidadeAlunos: element.quantidadeAlunos
            });
        }

        function changeTurno(newValue) {
            if (!_.isEmpty(newValue)) {
                vm.possuiVaga = newValue.saldoVagas;
            }
            delete vm.atestadoDeVaga.turma;
        }

        function isEmpty(data) {
            return _.isEmpty(data);
        }

        function formatResultTurma(data) {
            if ((_.isNull(data.quantidadeAlunos) || _.isUndefined(data.quantidadeAlunos)) || (!_.get(data, 'quantidadeMaximaAlunos'))) {
                return;
            }
            return data.descricao + ' ' + '(' + data.quantidadeAlunos + '/' + data.quantidadeMaximaAlunos + ' alunos)';
        }

        function formatResultTurno(data) {
            return _.capitalize((data.turno || '').toLowerCase()) + ' ' + '(' + data.qtdVagasOcupadas + '/' + data.qtdVagas + ' vagas)';
        }

        function formatResultAtividade(data) {
            if (data.quantidadeAlunos) {
                return data.descricao + ' (' + data.quantidadeAlunos + '/' + data.quantidadeMaximaAlunos + ') ';
            } else {
                return data.descricao + ' (' + '0/' + data.quantidadeMaximaAlunos + ') ';
            }
        }

        function openTemplateAtestadoVagaImpressao() {
            if ($scope.form.$invalid) {
                Notification.publish('Existem campos obrigatórios que não foi preenchidos', 'danger');
                return;
            }
            if(vm.possuiVaga<=0){
                return;
            }

            $commons.$modal.open({
                templateUrl: 'matricula/matriculas/relatorios-matricula/atestado-de-vaga/atestado.vaga.html',
                controller: 'educacao.matricula.relatorioVaga',
                controllerAs: 'vm',
                params: {
                    atestado: vm.atestadoDeVaga,
                    validade: vm.validade,
                    matriz: vm.matriz,
                    estabelecimento: vm.estabelecimento,
                    informaDocumentos: vm.tiposMatriculas[vm.atestadoDeVaga.tipoMatricula].informaDocumentos
                },
                size: 'xxl'
            });
        }

        function createPromise(values) {
            var createdEnum = {
                values: values
            };

            _.forEach(values, function(value) {
                value.id = value.key;
                createdEnum[value.key] = value;
            });

            return $q.when(createdEnum);
        }

    }
})();


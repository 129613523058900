(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appSelectOrgaoRegionalInep', Directive);


    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                estado: '=',


            },
            bindToController: {
                item: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.common.OrgaoRegionalInepService', '$scope'];

    function Controller(OrgaoRegionalInepService, $scope) {

        var vm = this;
        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = '((nome like "' + params.term + '") and (estado = ' + $scope.estado + '))';

            return OrgaoRegionalInepService.getList(params.offset, params.limit, filter)
                .then(_.first);
        }

        function formatResult(data) {
            if (_.isFunction($scope.formatResult)) {
                return $scope.formatResult({
                    data: data
                });
            }

            return data.nome;
        }

    }
})();
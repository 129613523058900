(function () {
    'use strict';
  
    angular
      .module('educacao.matricula')
      .directive('appFiltroAvancadoMatricula', Directive);
  
    function Directive() {
      return {
        restrict: 'E',
        templateUrl:
          'matricula/matriculas/filtro-avancado/matricula/matricula.directive.html',
        controller: Controller,
        controllerAs: 'vm',
        scope: {
          expressionCustomBuilders: '=',
          advancedFilter: '=',
          contadorFiltroPadraoMatricula : '=',
        },
        bindToController: true
      };
    }
  
    Controller.$inject = [];
  
    function Controller() {}
  })();
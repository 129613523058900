(function () {
    'use strict';

    angular.module('app')
        .config(edGoogleOauthConfig);

    edGoogleOauthConfig.$inject = [
        '$edGoogleOauthApiProvider'
    ];

    function edGoogleOauthConfig(
        $edGoogleOauthApiProvider
    ) {
        // $edGoogleOauthApiProvider.setBasePath(URL_GOOGLE_OAUTH_API);
        $edGoogleOauthApiProvider.setCreateNew(false);
    }
})();

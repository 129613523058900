(function() {
    'use strict';

    angular.module('educacao.legislacao')
        .controller('educacao.legislacao.LegislacaoCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.legislacao.LegislacaoService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        LegislacaoService
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.LegislacaoService = LegislacaoService;

        function openCad(legislacao) {
            $modalStates.goModalState(legislacao);
        }

        function remove(data) {
            return LegislacaoService.remove(data);
        }
    }
})();


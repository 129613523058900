(function() {

    'use strict';

    angular.module('educacao.historico-escolar', [
			'educacao.common'
    	]);

})();


(function() {
    'use strict';

    angular.module('educacao.desempenhoescolar')
        .directive('appInputObjetivoAprendizagem', directive);

    function directive() {
        return {
            restrict: 'E',

            replace: true,
            templateUrl: 'desempenho-escolar/input-objetivo-aprendizagem/input-objetivo-aprendizagem.directive.html',

            scope: {},
            bindToController: {
                nota: '=',
                matricula: '=',
                configuracao: '=',
                hasNotaObjetivo: '=',
                hasPermission: '=?',
                isEjaModular: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.desempenhoescolar.DesempenhoEscolarUtilsService',
        '$objetivoAprendizagemDesempenhoModalService'
    ];

    function Controller(DesempenhoEscolarUtilsService, $objetivoAprendizagemDesempenhoModalService) {

        var vm = this;

        vm.abreModalObjetivo = abreModalObjetivo;

        function abreModalObjetivo() {

            var params = {
                matricula: vm.matricula,
                configuracao: vm.configuracao,
                nota: vm.nota,
                hasPermission: vm.hasPermission
            };

            $objetivoAprendizagemDesempenhoModalService.open(params)
                .then(function(registroAvaliacao) {
                    vm.nota.filhos = registroAvaliacao.filhos;

                    if (registroAvaliacao && !_.get(registroAvaliacao.enturmacao, 'matricula.avaliacaoDescritiva') && DesempenhoEscolarUtilsService.isModoAvaliacaoNumerico(
                            vm.nota.avaliavel.modoAvaliacao)) {
                        vm.nota = _.merge(vm.nota, registroAvaliacao);

                        vm.nota.updateFields();
                    } else {
                        vm.nota.id = registroAvaliacao.id;
                    }

                    vm.hasNotaObjetivo = DesempenhoEscolarUtilsService.hasAnyNotaValida(_.get(registroAvaliacao, 'filhos'));
                });
        }
    }
})();


(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.ClassificacaoInscritosService', ClassificacaoInscritosService);

    ClassificacaoInscritosService.$inject = ['$injector'];

    function ClassificacaoInscritosService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .create()
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'inscricao'
            });
    }

})();

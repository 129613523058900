(function() {
    'use strict';

    angular.module('educacao.matricula').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.matricula.acompanhamentoPedagogico', {
            url: 'acompanhamentos-pedagogicos',
            data: {
                id: 'AcompanhamentoPedagogicoPageMapping'
            },
            views: {
                '': {
                    templateUrl: 'matricula/acompanhamento-pedagogico/acompanhamento-pedagogico.html'
                }
            }
        });
    }
})();
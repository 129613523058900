(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.EstadosController', EstadosController);

    EstadosController.$inject = [
        '$scope',
        'educacao.common.$commons', 
        'ui.components.Modal',
        '$modalStates',
        'bfc.Notification',
        'educacao.enderecos.EstadoService',
    ];

    function EstadosController($scope, $commons, Modal, $modalStates, bfcNotification, EstadoService) {
        var vm = this;

        vm.EstadoService = EstadoService;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.search = search;
        vm.columns = getColumns();

        vm.filter = {};
        vm.tracker = {};
        vm.listUsuarioControls = {};
        vm.listPadraoControls = {};
        
        vm.tracker.loading = $commons.$promiseTracker();

        vm.listUsuarioCustomBuilder = {
            onBeforeBuild: function () {
                return 'database is not null';
            }
        };

        vm.listPadraoCustomBuilder = {
            onBeforeBuild: function () {
                return 'database is null';
            }
        };

        function remove(estado) {
            return EstadoService.remove(estado)
                    .then(function() {
                        bfcNotification.publish('Estado ' + estado.nome + ' - ' + estado.siglaUf + ' excluído com sucesso.', 'success');
                    });
        }

        function search() {
            vm.listUsuarioControls.search();
            vm.listPadraoControls.search();
        }

        function getColumns() {
            return [
                {
                    id: 'nome',
                    model: 'nome',
                    label: 'Nome',
                    filterable: true
                },
                {
                    id: 'uf',
                    model: 'uf',
                    label: 'UF',
                    filterable: true
                },
                {
                    id: 'codigoIBGE',
                    model: 'codigoIBGE',
                    label: 'Código IBGE',
                    filterable: false
                }
            ];
        }

        function  openCad(estado) {
            $modalStates.goModalState(estado);
        }
    }
})();
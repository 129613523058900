(function () {

    'use strict';

    var SELECIONADOS_SIMULTANEOS = {
        APROVADOS: ['CURSANDO', 'EM_EXAME', 'APROVADO', 'APROVADO_DEPENDENCIA', 'APROVADO_CONSELHO'],
        REPROVADOS: ['REPROVADO', 'REPROVADO_FREQUENCIA']
    };

    angular.module('educacao.rematricula')
        .directive('stepSelecionarAlunos', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'rematricula/step-selecionar-alunos.directive.html',
            scope: {},
            bindToController: {
                turmas: '=',
                modalTracker: '=',
                filtroPesquisado: '=',
                permiteTrocarEstabelecimento: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    } 

    Controller.$inject = [
        '$scope',
        'educacao.context.ContextoEstabelecimento',
        'educacao.matricula.MatriculaService', //TODO: Buscar da rematricula service
        'educacao.rematricula.RematriculaService',
        'educacao.matricula.TurmaService',
        'promiseTracker',
        'SITUACAO_MATRICULA_REMATRICULA',
        '$enumsCache'
    ];

    function Controller($scope, ContextoEstabelecimento, MatriculaService, RematriculaService, TurmaService, promiseTracker,
        SITUACAO_MATRICULA_REMATRICULA, $enumsCache) {

        var vm = this;

        vm.filterResultSituacaoMatricula = filterResultSituacaoMatricula;
        vm.hasContextoEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.onChangeEstabelecimento = onChangeEstabelecimento;
        vm.onChangeEtapa = onChangeEtapa;
        vm.onChangeMatriz = onChangeMatriz;
        vm.onClickPesquisar = onClickPesquisar;
        vm.situacaoMatricula = MatriculaService.getEnum('SituacaoMatricula').then(function (SituacaoMatricula) {
            vm.situacoesMatricula = SituacaoMatricula;
            return SituacaoMatricula;
        });
        
        vm.solicitacaoRematricula = $enumsCache.get('SolicitacaoRematricula').then(function (solicitacaoRematricula) {
            vm.solicitacoesRematriculas = solicitacaoRematricula;
            return solicitacaoRematricula;
        });

        vm.pesquisa = {
            estabelecimento: vm.hasContextoEstabelecimento ? ContextoEstabelecimento.getEstabelecimento() : null
        };

        vm.tracker = {
            getTurmas: promiseTracker()
        };

        function onChangeEstabelecimento() {
            delete vm.pesquisa.matriz;
        }

        function onChangeEtapa() {
            delete vm.pesquisa.turmas;
        }

        function onChangeMatriz() {
            delete vm.pesquisa.etapas;
            delete vm.pesquisa.turmas;
        }

        function onClickPesquisar(filtrosPesquisados) {

            vm.filtroPesquisado = angular.copy(filtrosPesquisados);

            var promise = TurmaService.getTurmasRematricula(vm.filtroPesquisado)
                .then(function (turmas) {
                    vm.turmas = turmas;
                });

            vm.tracker.getTurmas.addPromise(promise);
            vm.modalTracker.addPromise(promise);
        }

        function filterResultSituacaoMatricula(term, value) {
            if (!value.description.toLowerCase().contains(term.toLowerCase())) {
                return;
            }

            if (SITUACAO_MATRICULA_REMATRICULA.VALUES.indexOf(value.id) >= 0) {

                if (!_.isEmpty(vm.pesquisa.situacoes)) {
                    if (!_.isEmpty(_.intersection(SELECIONADOS_SIMULTANEOS.APROVADOS, vm.pesquisa.situacoes))) {
                        if (SELECIONADOS_SIMULTANEOS.APROVADOS.indexOf(value.id) >= 0) {
                            return value;
                        }
                    } else if (SELECIONADOS_SIMULTANEOS.REPROVADOS.indexOf(value.id) >= 0) {
                        return value;
                    }

                } else {
                    return value;
                }

            }
        }
    }
})();


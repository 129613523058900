(function() {

    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.MessagesInterceptor', MessagesInterceptor);

    MessagesInterceptor.$inject = ['$q', 'bfc.Notification'];

    function MessagesInterceptor($q, Notification) {
        return {
            response: function(response) {
                var messages = getBthHeaderMessages(response);
                sendNotification(messages);
                return response;
            },
            responseError: function(response) {
                var messages = getBthHeaderMessages(response);
                sendNotification(messages);
                return $q.reject(response);
            }
        };

        function sendNotification(messages) {
            if (messages.length > 0) {
                for (var i = 0; i < messages.length; i++) {
                    Notification.publish(messages[i].message, messages[i].type);
                }
            }
        }

        function getBthHeaderMessages(response) {
            var messages = [];
            messages = messages.concat(extractMessages('x-bth-error-messages', 'error', response));
            messages = messages.concat(extractMessages('x-bth-info-messages', 'info', response));
            messages = messages.concat(extractMessages('x-bth-warn-messages', 'warn', response));
            messages = messages.concat(extractMessages('x-bth-success-messages', 'success', response));
            return messages;
        }

        function extractMessages(headerName, messageType, response) {
            var messages = [];
            var header = response.headers(headerName);
            if (header === null || angular.isUndefined(header)) {
                return messages;
            }

            var jsonMessages = [];
            var headerValue;
            try {
                headerValue = Base64.decode(header);
                jsonMessages = JSON.parse(headerValue);
            } catch (e) {
                if (headerValue !== undefined && headerValue !== '') {
                    jsonMessages.push(headerValue);
                }
            }
            if (Array.isArray(jsonMessages) && jsonMessages.length > 0) {
                jsonMessages.forEach(function(text) {
                    if (text === '') {
                        return;
                    }
                    messages.push({
                        type: messageType,
                        message: Base64.decode(text)
                    });
                });
            }
            return messages;
        }

    }

})();

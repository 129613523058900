(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.historico.HistoricoServiceProvider'];

    function HistoricoConfig(HistoricoServiceProvider) {

        HistoricoServiceProvider.addTipoHandler('MOVIMENTACAO_DESFAZER', {
            badge: {
                icon: 'fa-exchange',
                tooltip: 'Movimentação desfeita'
            },
            template: '<small>{{descricaoTipo.valor}} desfeito(a)<small>'
        });
    }
})();

(function () {

    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ImpressaoFichaIndividualController', ImpressaoFichaIndividualCtrl);

    ImpressaoFichaIndividualCtrl.$inject = [
        '$scope',
        '$modalInstance',
        'educacao.matricula.MatriculaService',
        'educacao.matricula.FichaIndividualService',
        'promiseTracker',
        'bfc.Notification'
    ];

    function ImpressaoFichaIndividualCtrl($scope,
        $modalInstance,
        MatriculaService,
        FichaIndividualService,
        promiseTracker,
        Notification
    ) {

        var vm = this;

        vm.imprimirFicahIndividual = imprimirFicahIndividual;

        vm.nivelEscolar = FichaIndividualService.getEnum('NivelEscolar');
        vm.nivelModalidade = FichaIndividualService.getEnum('NivelModalidade');
        vm.tipoMatricula = MatriculaService.getEnum('TipoMatricula');

        vm.periodos = FichaIndividualService.getBoletimPeriodoAvaliativo();
        vm.ordenacao = FichaIndividualService.getBoletimOrdenacao();
        vm.modelos = FichaIndividualService.getModeloFichaIndividual();

        vm.tracker = {
            initialize: promiseTracker(),
            imprimir: promiseTracker()
        };

        vm.configuracaoImpressao = {};

        init($modalInstance.params.matriculas || []);

        function init(matriculas) {

            var promisse = vm.tipoMatricula.then(function (tipos) {

                var tipoMatricula = tipos;

                vm.nivelModalidade.then(function (nivelModalidade) {
                    var niveisModalidades = nivelModalidade;

                    vm.nivelEscolar.then(function (nivelEscolar) {

                        var niveisEscolares = nivelEscolar;

                        vm.configuracaoImpressao.configuracaoMatriculas = getConfiguracoesMatriculasAgrupadas(
                            matriculas,
                            tipoMatricula, niveisModalidades,
                            niveisEscolares);
                    });
                });
            });

            vm.tracker.initialize.addPromise(promisse);
        }

        function getConfiguracoesMatriculasAgrupadas(matriculas, tiposMatricula, niveisModalidades, niveisEscolares) {

            var matriculasAgrupadas = [];

            angular.forEach(matriculas, function (matricula) {

                var keyMatricula, agrupamento;

                if (MatriculaService.isAtividade(matricula.tipo)) {

                    var tipo = _.find(tiposMatricula, 'id', matricula.tipo);
                    if (tipo) {
                        agrupamento = tipo.description;

                        keyMatricula = tipo.id;
                    }

                } else {
                    var modalidade = _.find(niveisModalidades, 'id', matricula.matriz.curso.nivelModalidade);
                    if (modalidade) {
                        agrupamento = modalidade.description;
                        keyMatricula = modalidade.id;
                    }

                    var escolar = _.find(niveisEscolares, 'id', matricula.matriz.curso.nivelEscolar);
                    if (escolar) {
                        agrupamento += ' / ' + escolar.description;
                        keyMatricula += escolar.id;
                    }
                }

                var indexGroup = _.findIndex(matriculasAgrupadas, '$$keyGroup', keyMatricula);
                if (indexGroup >= 0) {
                    matriculasAgrupadas[indexGroup].matriculas.push(matricula.id);
                } else {
                    matriculasAgrupadas.push({
                        $$keyGroup: keyMatricula,
                        $$agrupamento: agrupamento,
                        matriculas: [matricula.id]
                    });
                }
            });

            return matriculasAgrupadas;
        }

        function imprimirFicahIndividual(parametros) {

            parametros.configuracaoMatriculas.forEach(function (item) {
                item.legislacoes = _.pluck(_.map(item.$$legislacoes, legislacaoMap), 'legislacao').join(', ');
                item.convencoes = _.pluck(item.$$convencoes, 'descricao').join(', ');
            });

            var promise = FichaIndividualService.gerarPdf(parametros)
                .then(notificar)
                .then($modalInstance.close);
            vm.tracker.imprimir.addPromise(promise);
        }

        function notificar() {
            Notification.publish('Impressão do relatório agendada com sucesso', 'info');
        }

        function legislacaoMap(item) {
            return {
                legislacao: item.descricao + ' - Data: ' + moment(item.dataLegislacao).format('DD/MM/YYYY')
            };
        }
    }

})();


(function() {

    'use strict';

    angular.module('educacao.funcionario')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.funcionario.lotacaofisica';
        $stateProvider.state(STATE, {
            url: 'lotacoes-fisicas',
            data: {
                id: 'LotacaoFisicaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'planejamento/funcionarios/lotacao-fisica/lotacao-fisica.html'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.funcionario.LotacaoFisicaService',
            '$modalStates'
        ];

        function openModal($stateParams, LotacaoFisicaService, $modalStates) {
            $modalStates.open({
                getMethod: LotacaoFisicaService.get,
                templateUrl: 'planejamento/funcionarios/lotacao-fisica/lotacao-fisica.cad.html',
                controller: 'educacao.funcionario.LotacaoFisicaCadCtrl as vm',
                propertyName: 'lotacao',
                stateParams: $stateParams
            });
        }
    }

})();

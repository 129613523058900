(function() {

    'use strict';

angular.module('educacao.common')
.directive('appSelectEnum', appSelectEnum);

function appSelectEnum() {
    return {
        restrict: 'E',
        templateUrl: 'common/directives/app-select-enum/app-select-enum.directive.html',
        scope: {
            ngModel: '=',
            enumName: '@',
            multiple: '@',
            ngRequired: '=?',
            ngClass: '=',
            placeholder: '=',
            ngDisabled: '=?',
            filterResult: '&?',
            formatResult: '&?',
            containerCssClass: '=?',
            dropdownCssClass: '=?',
            ngChange: '&?',
            order: '@?',
            allowClear: '=',
            maximumSelectionSize: '=?'
        },
        controller: Controller,
        controllerAs: 'vm',
        compile: function(element, tAttrs) {
            var multiple = angular.isDefined(tAttrs.multiple);
            if (multiple) {
                element.children('ui-select-enum').attr('multiple', true);
            }

            if (angular.isDefined(tAttrs.filterResult)) {
                element.children('ui-select-enum').attr('filter-result', 'filterResult({term: term, value: value})');
            }

            if (angular.isDefined(tAttrs.formatResult)) {
                element.children('ui-select-enum').attr('format-result', 'formatResult({data: data})');
            }
            return {
                post: function(scope) {
                    scope.multiple = multiple;
                }
            };
        }
    };
}

Controller.$inject = ['$scope', '$enumsCache'];

function Controller($scope, $enumsCache) {
    var vm = this;
    vm.placeholder =  $scope.placeholder;
    vm.promise = getPromise();

    function getPromise() {
        var order = $scope.order ? $scope.order : 'description';
        return $enumsCache.get($scope.enumName).then(function(data){
            data.values = _.has(_.first(data.values), order) ? _.sortByOrder(data.values, order) : data;
            return data;
        });
        
    }

}

})();
(function() {
    'use strict';
    var moduloCommon = angular.module('educacao.pessoas');

    moduloCommon
        .factory('educacao.pessoas.RecursoInepSelectService', function() {
            return {
                create: function() {

                    return angular.copy({
                        _items: [],
                        updateItems: function(item) {
                            if (typeof item === 'object') {

                                var existe = this._items.some(function(_item) {
                                    return _item.id === item.id;
                                });

                                if (!existe) {
                                    this._items.push(item);
                                }
                            }
                        },
                        getList: function() {
                            return this._items;
                        },
                        newModel: function() {
                            this._items = [];
                        },
                        filterOptions: function(options) {

                            if (!Array.isArray(options)) {
                                throw new Error('Não informada as opções do select2 de Recursos do Inep');
                            }

                            if (this._items.length === 0) {
                                return options;
                            }

                            var isNenhumSelected = this._items.some(function(item) {
                                return item.key === 'NENHUM';
                            });

                            if (isNenhumSelected) {
                                return [];
                            }

                            var consumerProvaAmpliada = function(any) {
                                return any.key === 'PROVA_APLIADA_FONTE_16' || any.key === 'PROVA_APLIADA_FONTE_20' || any.key === 'PROVA_APLIADA_FONTE_24';
                            };

                            var isProvaAmpliadaSelected = this._items.some(function(item) {
                                return consumerProvaAmpliada(item);
                            });

                            var tempOptions = options.filter(function(option) {
                                if (option.key !== 'NENHUM') {
                                    return option;
                                }
                            });

                            if (isProvaAmpliadaSelected) {
                                tempOptions = tempOptions.filter(function(option) {
                                    if (!consumerProvaAmpliada(option) && isProvaAmpliadaSelected) {
                                        return option;
                                    }
                                });
                            }

                            return tempOptions;

                        }
                    });
                }
            };
        })

    .factory('educacao.pessoas.RecursoInepSelect', ['$q',
        'bfc.Select2Config',
        'educacao.common.ObjectUtilsService',
        'educacao.pessoas.RecursoInepSelectService',

        function($q,
            Select2Config,
            ObjectUtilsService,
            RecursoInepSelectService) {

            return angular.copy({
                select2Config: function(lista, especializacao) {

                    var instanceRecursoInepSelectService = RecursoInepSelectService.create();

                    lista.values = _.map(lista.values, function(row, key) {
                        return angular.extend(row, {
                            'id': key
                        });
                    });

                    return Select2Config.create(
                        angular.extend({
                            _value: null,
                            setValueModel: function(value) {
                                this._value = value;
                            },
                            result: function(params, callback) {

                                var deferred = $q.defer();

                                function _data() {

                                    var filteredOptions = instanceRecursoInepSelectService.filterOptions(lista.values);

                                    var slice = ObjectUtilsService.filterByField(filteredOptions, lista.description, params.term);

                                    deferred.resolve(slice);

                                    return deferred.promise;
                                }

                                return _data()
                                    .then(function(data) {
                                        callback({
                                            results: data,
                                            more: false
                                        });
                                    });
                            },
                            formatValue: function(data) {
                                if (instanceRecursoInepSelectService.getList()
                                    .length > data.length) {
                                    instanceRecursoInepSelectService.newModel();
                                    data.forEach(function(model) {
                                        instanceRecursoInepSelectService.updateItems(model);
                                    });
                                }

                                return this.multiple ?
                                    data :
                                    data.key;
                            },
                            formatResult: function(data) {
                                return _.property(lista.description)(data);
                            },
                            formatSelection: function(data) {
                                instanceRecursoInepSelectService.updateItems(data);

                                return this.multiple ?
                                    _.property(lista.description)(
                                        _.find(lista.values,
                                            function(item) {
                                                return item.key === (typeof data === 'object' ? data.key : data);
                                            }
                                        )
                                    ) : _.property(lista.description)(data);
                            },
                            initSelection: function(element, callback) {
                                var index = $(element)
                                    .val();

                                if (!_.isNumber(index)) {
                                    callback(
                                        _.find(lista.values, function(row) {
                                            return row.key === index || row.id === index;
                                        })
                                    );
                                } else {
                                    callback({
                                        id: null
                                    });
                                }
                            },
                            multiple: false,
                            minimumResultsForSearch: lista.values.length < 6 ? -1 : lista.values.length
                        }, especializacao));
                }

            });
        }

    ]);

})();

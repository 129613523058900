(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios').directive('appGeracaoHorariosAulas', GeracaoHorariosAulasDirective);

    GeracaoHorariosAulasDirective.$inject = [];

    function GeracaoHorariosAulasDirective() {
        return {
            restrict: 'E',
            templateUrl: 'geracao-horarios/aulas/aulas.directive.html',
            scope: {
                ngModel: '=',
                estabelecimento: '=',
                hasCreatePermission: '='
            },
            controller: GeracaoHorariosAulasDirectiveController,
            controllerAs: 'vm'
        };
    }

    GeracaoHorariosAulasDirectiveController.$inject = ['$scope', 'educacao.geracaoHorarios.LocalSearchService'];

    function GeracaoHorariosAulasDirectiveController($scope, localSearchService) {

        var vm = this;

        vm.aulas = [];
        vm.filter = {};
        vm.columns = localSearchService.getColumns();

        vm.search = search;
        vm.dragHelper = dragHelper;

        $scope.$watch('estabelecimento', function(value) {
            vm.estabelecimento = value;
        });

        $scope.$watch('ngModel', function(value, oldValue) {
            if (value === oldValue) {
                return;
            }
            vm.aulas = value;
            search();
        }, true);

        function search() {
            if (!vm.filter) {
                return;
            }

            vm.aulas = localSearchService.search(vm.filter, $scope.ngModel);
        }

        function dragHelper(dragObject) {
            return '<div class="ui-widget-header aula-drag-smaller text-center ellipsis" ' +
                'title="' + dragObject.aula.origem.sigla + '" style="background-color: #' + dragObject.aula.cor + '">' +
                '<small>' + dragObject.aula.origem.sigla + '</small></div>';
        }

    }
})();

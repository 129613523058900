(function () {
    'use strict';

    angular.module('educacao.historico').directive('appHistoricoList', HistoricoList);

    function HistoricoList() {
        return {
            restrict: 'E',
            templateUrl: 'historico/app-historico-list.directive.html',
            scope: {},
            bindToController: {
                historicos: '=ngModel',
            },
            controller: HistoricoListController,
            controllerAs: 'vm'
        };
    }

    HistoricoListController.$inject = [];
    function HistoricoListController() {
    }
})();

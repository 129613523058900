(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .directive('appLinhaDetalhesEstabelecimentoTurnos', directive);

    function directive() {
        return {
            restrict: 'E',

            templateUrl: 'lista-espera/inscricao/linha-detalhes-estabelecimento-turnos.directive.html',

            scope: {
                estabelecimentos: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'educacao.lista-espera.InscricaoListaEsperaService'];
    function Controller($scope, InscricaoListaEsperaService) {
        var enumTurno;

        var vm = this;

        vm.pairs = [];

        InscricaoListaEsperaService.getTurno().then(function (data) {
            enumTurno = data;
            $scope.$watch('estabelecimentos', createPairs);
        });

        function createPairs(estabelecimentos) {
            vm.pairs = [];

            var grouped = _.groupBy(estabelecimentos, 'estabelecimento.id');
            _.forEach(grouped, function (group) {
                vm.pairs.push({
                    estabelecimento: getNomeEstabelecimento(group),
                    turnos: getTurnos(group)
                });
            });
        }

        function getNomeEstabelecimento(grupo) {
            var estabelecimento = _.first(grupo);
            return estabelecimento.estabelecimento.descricao || estabelecimento.estabelecimento.pessoa.nome;
        }

        function getTurnos(grupo) {
            return grupo.map(function (estabelecimentoTurno) {
                return enumTurno[estabelecimentoTurno.turno].description;
            }).join(' - ');
        }
    }
})();
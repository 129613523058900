(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .constant('SITUACAO_REMATRICULA', {
            REMATRICULADO: 'REMATRICULADO',
            NAO_REMATRICULADO: 'NAO_REMATRICULADO',
            PROCESSANDO: 'PROCESSANDO',
            VALUES: ['REMATRICULADO', 'NAO_REMATRICULADO','PROCESSANDO']
        });

})();
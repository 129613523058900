(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('headerRelatorios', HeaderRelatoriosDirective);

    function HeaderRelatoriosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/header-relatorios-directive/header-relatorios.directive.html',
            scope: {
                dadosRelatorio: '=',
                telefoneEmail: '=',
                entidade: '='
            },
            controller: ControllerDirective,
            controllerAs: 'vm'
        };
    }

    ControllerDirective.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.entidade.EntidadeService'
    ];

    function ControllerDirective($scope, promiseTracker, EntidadeService) {

        var vm = this;

        vm.tracker = {
            loading: promiseTracker()
        };

        init();

        function init() {

            var promise = EntidadeService.get('')
                .then(function(entidade) {

                    $scope.entidade = entidade;
                });

            vm.tracker.loading.addPromise(promise);
        }
    }

})();


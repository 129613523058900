(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .controller('educacao.ocupacao-aulas-quadro-informativo.SaldoDisciplinaMobileController', Controller);

    Controller.$inject = [
        '$stateParams',
        'educacao.ocupacao-aulas-quadro-informativo.VisaoEstabelecimentoUtilsService'
    ];

    function Controller($stateParams, VisaoEstabelecimentoUtilsService) {
        var vm = this;

        vm.SCREEN_NAME = 'Ocupação das aulas';
        vm.getComponenteCurricularSubTitle = VisaoEstabelecimentoUtilsService.getComponenteCurricularSubTitle;

        vm.turmaSelecionada = $stateParams.turmaSelecionada;
        vm.estabelecimentoId = $stateParams.estabelecimentoId;
        vm.estabelecimentoNome = $stateParams.estabelecimentoNome;
    }
})();
(function() {
	'use strict';

	var SITUACOES_ALTERACAO = ['APROVADO_CONSELHO', 'APROVADO', 'REPROVADO'];

	angular.module('educacao.desempenhoescolar').directive('appDesempenhoTableEjaModular', appDesempenhoTableEjaModular);

	function appDesempenhoTableEjaModular() {
		return {
			restrict: 'E',
			templateUrl: 'desempenho-escolar/eja-modular/app-desempenho-table-eja-modular.directive.html',
			scope: {
				save: '='
			},
			bindToController: {
				listaEnturmacoes: '=',
				listaAvaliaveis: '=',
				listaAvaliaveisModulo: '=',
				listaAvaliacoes: '=',
				configuracaoAvaliacao: '=',
				encerramentosTurma: '=',
				itemEducacional: '=',
				trackerSalvar: '=',
				turma: '=',
				hasPermissionsToEditPeriodoEncerrado: '=',
				sortBy: '='
			},
			controller: DesempenhoTableEjaModular,
			controllerAs: 'vm'
		};
	}

	DesempenhoTableEjaModular.$inject = ['$injector', '$rootScope', '$scope', '$filter', 'promiseTracker', 'educacao.common.ObjectUtilsService'];

	function DesempenhoTableEjaModular($injector, $rootScope, $scope, $filter, promiseTracker, ObjectUtilsService) {

		var vm = this;
		var PermissionsService = $injector.get('bfc.$$PermissionsService');
        vm.isRevokeAlterarSituacao = PermissionsService.isRevokedOperation('RegistroAvaliacaoPageMapping', 'alterarSituacao');
		var TipoAvaliacaoUtilsService = $injector.get('educacao.matricula.TipoAvaliacaoUtilsService');
		var DesempenhoEscolarUtilsService = $injector.get('educacao.desempenhoescolar.DesempenhoEscolarUtilsService');
		var EncerramentoUtilsService = $injector.get('educacao.encerramento.EncerramentoUtilsService');
		var RegistroAvaliacoesService = $injector.get('educacao.desempenhoescolar.RegistroAvaliacoesService');
		var ITEM_AVALIAVEL_TIPO_PERIODO = $injector.get('ITEM_AVALIAVEL_TIPO_PERIODO');
		var ITEM_AVALIAVEL_MODO_AVALIACAO = $injector.get('ITEM_AVALIAVEL_MODO_AVALIACAO');
		var bfcNotification = $injector.get('bfc.Notification');
		vm.filterResultEnunsEspecificos = filterResultEnunsEspecificos;
		vm.desabilitaCampoSituacao =desabilitaCampoSituacao;
		vm.changeSituacao = changeSituacao;
		var propriedadesPublicas = {
			getNotaMediaFinal: _getNotaMediaFinal,
			getClassStatus: _getClassStatus,
			getNameCol: _getNameCol,
			isSemNota: _isSemNota,
			orderBy: _orderBy
		};
		vm.permissionToEditPeriodoEncerrado = !PermissionsService.isRevokedOperation('RegistroAvaliacaoPageMapping', 'alterarDesempenhoPeriodoAvaliativoEncerrado');

		vm.hasPermissionPeriodoEncerrado = hasPermissionPeriodoEncerrado;
		vm.titleInputNota = titleInputNota;
		vm.topFloatHeader = $rootScope.$$pinned ? '78' : '44';

		_.extend(vm, propriedadesPublicas);

		init();

		function init() {
			initTracker();
			initEnum();
			getCols();
			getListaEnturmacoes();
		}

		function initTracker() {
			vm.tracker = {
				enumSituacaoMatricula: promiseTracker()
			};
		}

		// verifica se houve alteração na fixação do contexto
        $scope.$watch('$root.$$pinned', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            vm.topFloatHeader = newValue ? '78' : '44';
        });

        function filterResultEnunsEspecificos(term, value) {
            if(_.contains(SITUACOES_ALTERACAO, value.key)){
                if (value.description.toUpperCase().contains(term.toUpperCase().trim())) {
                    return value;
                }    
            }
		}
		function desabilitaCampoSituacao(situacao){
			return _.isEmpty(situacao) || !_.contains(SITUACOES_ALTERACAO, situacao);
        }
      
		function initEnum() {
			vm.tracker.enumSituacaoMatricula.addPromise(
				vm.SituacaoMatriculaPromisse = RegistroAvaliacoesService.getEnum('SituacaoMatricula').then(function(data) {
					vm.enumSituacaoMatricula = data;
					return data;
				})
			);
		}

		function _getNotaMediaFinal(enturmacao) {
			if (!enturmacao.coluna) {
				return;
			}
			return _.get(enturmacao.coluna, ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL) || {};
		}

		function _getClassStatus(enturmacao) {
			return {
				'text-center': enturmacao.matricula.situacao !== 'EM_EXAME',
				'tx__red': enturmacao.matricula.situacao === 'EM_EXAME'
			};
		}

		function _isSemNota(nota) {
			return Boolean(getNota(nota)===null && vm.itemEducacional && vm.itemEducacional.dataFim && moment().isAfter(vm.itemEducacional.dataFim, 'day'));
		}

		function _getNameCol(avaliavel) {
			switch (avaliavel.tipoPeriodo) {
				case ITEM_AVALIAVEL_TIPO_PERIODO.MODULO:
					return avaliavel.tipoPeriodo + avaliavel.itemEducacional.descricao;
				case ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL:
					return avaliavel.tipoPeriodo + (avaliavel.identificadorExameFinal || '');
				default:
					return avaliavel.tipoPeriodo;
			}
		}

		function getCols() {
			vm.cols = {
				finais: []
			};

			vm.cols.modulos = _.sortBy(filterItensAvaliaveis(vm.listaAvaliaveisModulo, [ITEM_AVALIAVEL_TIPO_PERIODO.MODULO]), function(data) {
				return Number(data.itemEducacional.descricao);
			});

			setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO], 'Cálculo');
			setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME]);
			setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL], null, 'identificadorExameFinal');
			setColValue([ITEM_AVALIAVEL_TIPO_PERIODO.CONSELHO_CLASSE], 'Conselho');
		}

		function setColValue(tipo, descricao, order) {
			var itens = filterItensAvaliaveis(vm.listaAvaliaveis, tipo);

			if (itens.length) {
				_.forEach(order ? _.sortBy(itens, order) : itens, function(data) {
					data.descricao = descricao || data.descricao;
					vm.cols.finais.push(data);
				});
			}
		}

		function getListaEnturmacoes() {
			_.forEach(vm.listaEnturmacoes, function(enturmacao) {
				enturmacao.coluna = {};
				getItensAvaliaveis(enturmacao, vm.listaAvaliaveisModulo);
				getItensAvaliaveis(enturmacao, vm.listaAvaliaveis);
			});
		}

		function getItensAvaliaveis(enturmacao, listaAvaliaveis) {
			var newEnturmacao = _.cloneDeep(enturmacao);
			_.forEach(listaAvaliaveis, function(avaliavel) {
				var itemAvaliacao = _.find(vm.listaAvaliacoes, function(data) {
					return data.enturmacao.id === enturmacao.id && avaliavel.id === data.avaliavel.id;
				});

				itemAvaliacao = itemAvaliacao || [];
				var notas = getNota(itemAvaliacao);
				var descricao = notas && notas.sigla ? notas.sigla : notas;
				var periodo = _getNameCol(avaliavel);

				if (DesempenhoEscolarUtilsService.isModoAvaliacaoNumerico(avaliavel.modoAvaliacao) && !newEnturmacao.matricula.avaliacaoDescritiva) {
					var decimais = TipoAvaliacaoUtilsService.getQuantidadeCasasDecimais(vm.configuracaoAvaliacao);
					descricao = $filter('appNumber')(notas, ',', decimais);
				}

				var nota = {
					$$tracker: promiseTracker(),
					avaliavel: avaliavel,
					enturmacao: newEnturmacao,
					id: itemAvaliacao.id || null,
					filhos: itemAvaliacao.filhos || null,
					parecer: itemAvaliacao.parecer || null,
					notaNumerica: itemAvaliacao.notaNumerica || null,
					notaConceito: itemAvaliacao.notaConceito || null,
					notaDescritiva: itemAvaliacao.notaDescritiva || null,
					updateFields: function() {}
				};

				enturmacao.coluna[periodo] = {
					notaDescricao: descricao || '-',
					nota: nota,
					modoAvaliacao: avaliavel.modoAvaliacao,
					showEdit: !_.includes(
						[ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO,
						ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME,
						ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL],
						avaliavel.tipoPeriodo),
					isModoDescritivo: getAvaliacaoDescritiva(nota, avaliavel)
				};
			});

			function getAvaliacaoDescritiva(nota, avaliavel) {
				return Boolean(DesempenhoEscolarUtilsService.isModoAvaliacaoDescritivo(avaliavel.modoAvaliacao) ||
					(nota && nota.enturmacao && nota.enturmacao.matricula && nota.enturmacao.matricula.avaliacaoDescritiva));
			}
		}

		function getNota(avaliacao) {
			if (!avaliacao) {
				return;
			}
			return avaliacao.notaConceito || avaliacao.notaDescritiva || avaliacao.notaNumerica;
		}

		function filterItensAvaliaveis(listaAvaliaveis, filter) {
			return _.filter(listaAvaliaveis, function(data) {
				return _.includes(filter, data.tipoPeriodo) && data.modoAvaliacao !== ITEM_AVALIAVEL_MODO_AVALIACAO.NAO_OFERECE;
			});
		}

		function _orderBy(item) {
            if (!_.get(vm.sortBy,'expression')) {
                vm.sortBy.expression = 'numeroChamada';
            }
            if (vm.sortBy.expression === 'matricula.nome') {
                return ObjectUtilsService.normalize(_.get(item, vm.sortBy.expression));
            }
            return _.get(item, vm.sortBy.expression);
		}
		
		function changeSituacao(enturmacao){
            var lista = _.filter(enturmacao.situacaoItensEducacionais, function(dado) {
                return dado.itemEducacional.id === vm.itemEducacional.id;
            });
            
            if(!_.get(enturmacao.matricula,'situacao')){
                bfcNotification.publish(
                    'A situação precisa ser informada',
                    'error');
                    return;
            }

            vm.trackerSalvar.addPromise(
                 RegistroAvaliacoesService.alterarEnturmacaoSituacao(lista[0].id,enturmacao.matricula.situacao)
             );
        
		}

		function hasPermissionPeriodoEncerrado(avaliavel) {
		    var tipoPeriodo = _.get(avaliavel, 'tipoPeriodo');
		    var encerramento = null;
		    if(tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.MODULO){
		        encerramento = _.find(vm.encerramentosTurma, {tipoPeriodo: ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO});
		    }else if(tipoPeriodo === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL){
                encerramento = _.find(vm.encerramentosTurma, {tipoPeriodo: ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL, identificadorExameFinal: _.get(avaliavel, 'identificadorExameFinal')});
		    } else {
		        encerramento = _.find(vm.encerramentosTurma, {tipoPeriodo: ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL});
		    }

			if(EncerramentoUtilsService.isStatusEncerrado(encerramento)){
				return vm.permissionToEditPeriodoEncerrado;
			}
            return true;
		}

		function titleInputNota(linha, modulo){
			var nota = linha.coluna[_getNameCol(modulo)].nota;
			if(!vm.hasPermissionPeriodoEncerrado(nota.avaliavel)){
				return 'Período está encerrado';
			}
			if(vm.isSemNota(nota)){
				return 'A data atual é maior que a data final do período. É necessário informar o desempenho do aluno';
			}
			if(!vm.hasPermissionsToEditPeriodoEncerrado){
				return 'Você não tem permissão para acessar este recurso.';
			}
			return '';
		}
	}
})();


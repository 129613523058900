(function () {

    'use strict';

    var CONFIGURACAO_TURMA = 'CONFIGURACAO_TURMA';
    var AREA_CONHECIMENTO = 'AREA_CONHECIMENTO';
    var NAO_OFERECE = 'NAO_OFERECE';
    var CHA_OBJETIVO_PREFIX = 'CHA_OBJETIVO';
    var OBJETIVO_APRENDIZAGEM = 'OBJETIVO_APRENDIZAGEM';
    var CHA = 'CHA';

    var COLUMN_DESCRIPTION = {
        MEDIA_FINAL: 'Média final',
        MEDIA_PERIODO: 'Média do período avaliativo',
        INSTRUMENTO: 'Tipo avaliação - Inst. avaliação',
        EXAME_FINAL: 'Exame Final',
        CHA: 'CHA',
        OBJETIVO_APRENDIZAGEM: 'Obj. Aprendizagem',
    };

    angular.module('configuracao-avaliacao-turma')
        .directive('appTableConfiguracaoAvaliacao', appTableConfiguracaoAvaliacao);

    function appTableConfiguracaoAvaliacao() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/configuracao-avaliacao/app-table-configuracao-avaliacao/app-table-configuracao-avaliacao.directive.html',
            scope: {},
            bindToController: {
                isAtividade: '=',
                isModular: '=',
                isAreaConhecimento: '=',
                configuracaoAvaliacao: '=',
                periodos: '=',
                componentes: '=',
                controls: '=',
                hasExameFinal: '=',
                isEdicao: '=',
                isCamposExperiencia: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'ITEM_AVALIAVEL_TIPO_PERIODO'
    ];

    function Controller(ITEM_AVALIAVEL_TIPO_PERIODO) {
        var TIPO_PERIODO = ITEM_AVALIAVEL_TIPO_PERIODO;

        var SORT_TIPO_PERIODO = {
            INSTRUMENTO: 1,
            CHA_OBJETIVO_MEDIA_PERIODO: 2,
            MEDIA_PERIODO: 3,
            EXAME_FINAL: 4,
            CHA_OBJETIVO_EXAME_FINAL: 5,
            MEDIA_FINAL: 6
        };

        var SORT_TIPO_PERIODO_FINAIS = {
            EXAME_FINAL: 1,
            CHA_OBJETIVO_EXAME_FINAL: 2,
            MEDIA_FINAL: 3
        };

        var TIPO_PERIODOS_OCULTOS = [TIPO_PERIODO.CALCULO_INSTRUMENTOS, TIPO_PERIODO.CONSELHO_CLASSE, TIPO_PERIODO.EXAME_FINAL,
            TIPO_PERIODO.NOTA_PARA_EXAME, TIPO_PERIODO.CALCULO_MEDIA];

        var TIPO_PERIODOS_CHA_OBJ = [TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL, TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO];

        var TIPO_PERIODOS_FINAIS = [TIPO_PERIODO.EXAME_FINAL, TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL, TIPO_PERIODO.MEDIA_FINAL];

        var vm = this;

        vm.controls = {
            applyDefaultMode: applyDefaultMode
        };

        vm.changeModoAvaliacaoColumn = changeModoAvaliacaoColumn;
        vm.disableApplyAllButton = disableApplyAllButton;
        vm.clearModoAvaliacaoColumn = clearModoAvaliacaoColumn;
        
        vm.modoAvaliacaoColumnValue = [];

        vm.toggleAlignedDataClass = function() {
            return (!vm.isAtividade && !vm.isModular) ? 'aligned-data-header' : '';
          };

        init();

        function init() {
            mapItensEducacionais(vm.componentes);
            buildTableHeader(vm.componentes);

            if (vm.isCamposExperiencia) {
                createItensObjetivosAprendizagem(vm.componentes);
            } else {
                createItensCha(vm.componentes);
            }
        }

        /**
         * Cria a lista de CHA's com os dados já existentes
         * @param {*Array} componentes
         */
        function createItensCha(componentes) {
            _.each(componentes, function (componente) {
                componente.itensAvaliaveisCha = _.filter(componente.itensAvaliaveis, function (item) {
                    return item.tipo === 'CHA';
                });
            });
        }

        /**
         * Cria a lista de itens avaliáveis de objetivos de aprendizagem e desenvolvimento com os dados já existentes
         * @param {*Array} componentes
         */
        function createItensObjetivosAprendizagem(componentes) {
            _.each(componentes, function (componente) {
                componente.itensAvaliaveisObjetivoAprendizagem = _.filter(componente.itensAvaliaveis, function (item) {
                    return item.tipo === 'OBJETIVOS_APRENDIZAGEM';
                });
            });
        }

        /**
         * Função responsável por mapear os componentes da turma para a diretiva
         * Cada componente é transformado em um item de visualização. Os mesmos devem ser transformados
         * novamente quando a configuração for salva.
         * @param {Object[]} componentes - Componentes da turma
         */
        function mapItensEducacionais(componentes) {
            if (_.isEmpty(componentes)) {
                return;
            }

            _.forEach(componentes, mapComponente);
        }

        /**
         * Gera as propriedades necessárias para exibir cada componente e seus itens avaliáveis
         * @param {Object} componente - Componente a ser mapeado
         */
        function mapComponente(componente) {
            componente.$$isAreaConhecimento = componente.tipo === AREA_CONHECIMENTO;

            if (_.isEmpty(componente.itensAvaliaveis)) {
                createItensAvaliaveis(componente);
                return;
            }

            if (vm.isAtividade || vm.isModular) {
                componente.itensAvaliaveis = sortByTipoPeriodo(componente.itensAvaliaveis, SORT_TIPO_PERIODO);
            }

            var itensAvaliaveis = _.chain(componente.itensAvaliaveis)
                .filter('tipo', CONFIGURACAO_TURMA)
                .filter(filterTipoPeriodoDiff)
                .value();

            if(vm.isModular) {
                itensAvaliaveis = _.chain(itensAvaliaveis)
                                .filter(filterItensChaObj)
                                .value();
            }

            componente.$$itensAvaliaveis = [];
            var mapPeriodosAvaliativosFunction = mapPeriodosAvaliativos.bind(null, componente, itensAvaliaveis);

            _.forEach(vm.periodos, mapPeriodosAvaliativosFunction);

            componente.$$itensAvaliaveis = componente.$$itensAvaliaveis.concat(mapMediasFinais(itensAvaliaveis));
        
            insertItensForComponenteWithoutPeriodo(componente);

            createItemAvaliavelExame(componente);
        }   

        function mapPeriodosAvaliativos(componente, itensAvaliaveis, periodo){
            var porPeriodo = _.filter(itensAvaliaveis, {'periodoAvaliativo': {'id': periodo.id}});

            if (_.isEmpty(porPeriodo)) {
                createItemAvaliavelForPeriodo(componente, periodo);
            } else {
                porPeriodo = removePeriodoItensDuplicados(porPeriodo);
                porPeriodo = sortByTipoPeriodo(porPeriodo, SORT_TIPO_PERIODO);

                componente.$$itensAvaliaveis = componente.$$itensAvaliaveis.concat(
                    _.map(porPeriodo, generateItemAvaliavelVisualization)
                );

                var hasTipoPeriodoChaObj = hasItemByTipoPeriodo(porPeriodo,TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO);

                if(!hasTipoPeriodoChaObj) {
                    var indexMedia = _.findLastIndex(componente.$$itensAvaliaveis, 'tipoPeriodo', TIPO_PERIODO.MEDIA_PERIODO);
                    componente.$$itensAvaliaveis.splice(indexMedia, 0, createItemAvaliavelCHAOuObjAprendizagem(componente,periodo,false));
                }
            }
        }

        function mapMediasFinais(itensAvaliaveis){
            return _.chain(itensAvaliaveis)
                        .filter(notHasPeriodo)
                        .map(generateItemAvaliavelVisualization)
                        .value();
        }

        function notHasPeriodo(data) {
            return _.isNull(_.get(data, 'periodoAvaliativo'));
        }


        /**
         * Retorna função de ordenação dos itens avaliáveis. Necessário pois os itens
         * não são carregados ordenados quando a turma não possui períodos
         * @param {*} item - item avaliável
         */
        function sortItensAvaliaveis(sortBy, item) {
            return sortBy[item.tipoPeriodo];
        }

        /**
         * Responsável por criar o item de exame na lista de itens de visualização
         * @param {Object} componente - Componente que receberá o item avaliável
         */
        function createItemAvaliavelExame(componente) {
            var itemExameFinal = _.find(componente.itensAvaliaveis, {
                tipoPeriodo: TIPO_PERIODO.EXAME_FINAL,
                tipo: CONFIGURACAO_TURMA
            });

            delete itemExameFinal.id;

            //insere o item de exame após o último item de média período
            var indexExame = _.findLastIndex(componente.$$itensAvaliaveis, 'tipoPeriodo', TIPO_PERIODO.MEDIA_FINAL);
            componente.$$itensAvaliaveis.splice(indexExame, 0, generateItemAvaliavelVisualization(itemExameFinal));

            var hasitemChaObjExameFinal = hasItemByTipoPeriodo(componente.$$itensAvaliaveis, TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL);
            
            if(!vm.isModular && !hasitemChaObjExameFinal) {
                insertItemChaOuObj(componente, indexExame + 1, itemExameFinal.periodoAvaliativo, true);
            }
        
            removeItensDuplicadosFinais(componente.$$itensAvaliaveis);
            componente.$$itensAvaliaveis = sortByTipoPeriodo(componente.$$itensAvaliaveis, SORT_TIPO_PERIODO_FINAIS);
        }

        /**
         * Responsável por gerar uma cópia simplificada do item avaliável
         * @param {Object} itemAvaliavel - Item avaliável a ser copiado
         */
        function generateItemAvaliavelVisualization(itemAvaliavel) {
            var nonEditable = isNonEditable(itemAvaliavel.itemEducacional, itemAvaliavel.tipoPeriodo);

            return {
                id: itemAvaliavel.id || undefined,
                tipoPeriodo: angular.copy(itemAvaliavel.tipoPeriodo),
                modoAvaliacao: nonEditable ? NAO_OFERECE : itemAvaliavel.modoAvaliacao,
                tipo: itemAvaliavel.tipo,
                periodoAvaliativo: itemAvaliavel.periodoAvaliativo,
                $$isNonEditable: nonEditable
            };
        }

        /**
         * Cria os itens avaliáveis para o componente caso ainda não exista. Também será utilizada
         * para quando estiver setando configuração para várias turmas
         * @param {Object} componente - Componente curricular (Disciplina, Atividade, Area de conhecimento, etc.)
         */
        function createItensAvaliaveis(componente) {
            componente.$$itensAvaliaveis = [];

            var createItemAvaliavelFunction = createItemAvaliavelForPeriodo.bind(null, componente);

            //cria os itens para cada período
            _.forEach(vm.periodos, createItemAvaliavelFunction);

            if (vm.isAtividade || vm.isModular) {
                createItensWithoutPeriodos(componente);
            }

            //por fim, cria os itens para exame e média final   
            componente.$$itensAvaliaveis.push(createItemAvaliavel(componente, null, TIPO_PERIODO.EXAME_FINAL, CONFIGURACAO_TURMA));
        
            if (!vm.isModular) {
                componente.$$itensAvaliaveis.push(createItemAvaliavelCHAOuObjAprendizagem(componente,null,true));
            }

            componente.$$itensAvaliaveis.push(createItemAvaliavel(componente, null, TIPO_PERIODO.MEDIA_FINAL, CONFIGURACAO_TURMA));
        }

        /**
         * Gera os itens avaliáveis quando a turma não possui período
         * @param {Object} componente
         */
        function createItensWithoutPeriodos(componente) {
            componente.$$itensAvaliaveis.push(createItemAvaliavel(componente, null, TIPO_PERIODO.INSTRUMENTO, CONFIGURACAO_TURMA));

            if (!vm.isModular) {
                componente.$$itensAvaliaveis.push(createItemAvaliavelCHAOuObjAprendizagem(componente,null,false));
            }

            componente.$$itensAvaliaveis.push(createItemAvaliavel(componente, null, TIPO_PERIODO.MEDIA_PERIODO, CONFIGURACAO_TURMA));
        }

        /**
         * Gera os itens de instrumento e média de período para o componente a período avaliativo
         * @param {Object} componente - Componente curricular
         * @param {Object} periodo - Período avaliativo
         */
        function createItemAvaliavelForPeriodo(componente, periodo) {
            componente.$$itensAvaliaveis.push(createItemAvaliavel(componente, periodo, TIPO_PERIODO.INSTRUMENTO, CONFIGURACAO_TURMA));

            if (!vm.isModular) {
                componente.$$itensAvaliaveis.push(createItemAvaliavelCHAOuObjAprendizagem(componente,periodo,false));
            }

            componente.$$itensAvaliaveis.push(createItemAvaliavel(componente, periodo, TIPO_PERIODO.MEDIA_PERIODO, CONFIGURACAO_TURMA));
        }

        /**
         * Cria um item avaliável de configuração de turma novo com base nos parâmetros passados
         * @param {Object} componente - Componente curricular (Atividade, disciplina, etc)
         * @param {Object} periodo - Período avaliativo
         * @param {string} tipoPeriodo - Tipo de período do item avaliável
         * @param {string} tipo - Tipo do item avaliável
         */
        function createItemAvaliavel(componente, periodo, tipoPeriodo, tipo) {
            var nonEditable = isNonEditable(componente, tipoPeriodo);
            var modoAvaliacao = getModoAvaliacao(componente, periodo, tipoPeriodo, tipo);

            return {
                tipoPeriodo: tipoPeriodo,
                modoAvaliacao: nonEditable ? NAO_OFERECE : modoAvaliacao,
                tipo: tipo,
                periodoAvaliativo: periodo,
                $$isNonEditable: nonEditable
            };
        }

        /**
         * Retorna se o componente pode ser editado ou não, com base no componente e tipo de período
         * @param {Object} componente - componente curricular
         * @param {string} tipoPeriodo - tipo de período
         */
        function isNonEditable(componente, tipoPeriodo) {
            return isNonEditableAreaConhecimento(componente, tipoPeriodo) ||
                   isNonEditableExameFinal(tipoPeriodo) ||
                   isNonEditableModular(tipoPeriodo);
        }

        function isNonEditableAreaConhecimento(componente, tipoPeriodo) {
            return componente.tipo === AREA_CONHECIMENTO && (tipoPeriodo === TIPO_PERIODO.INSTRUMENTO || tipoPeriodo === TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO);
        }

        function isNonEditableExameFinal(tipoPeriodo) {
            return vm.isEdicao && (tipoPeriodo === TIPO_PERIODO.EXAME_FINAL || tipoPeriodo === TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL) && !vm.hasExameFinal;
        }

        function isNonEditableModular(tipoPeriodo) {
            return tipoPeriodo === TIPO_PERIODO.INSTRUMENTO && vm.isModular;
        }

        /**
         * Função para filtrar os itens avaliáveis com tipo perído diferente de conselho de classe
         * @param {Object} itemAvaliavel - Item avaliável
         */
        function filterTipoPeriodoDiff(itemAvaliavel) {
            return !_.contains(TIPO_PERIODOS_OCULTOS, itemAvaliavel.tipoPeriodo);
        }

        /**
         * Monta os períodos para a tabela, com base nos componentes passados
         * @param {Object} componentes - Lista de componentes curriculares
         */
        function buildTableHeader(componentes) {

            //Todos os itens possuem a mesma configuração, logo, pode ser utilizado qualquer item para montar a table
            var componente = _.first(componentes);

            vm.headerColumns = _.chain(componente.$$itensAvaliaveis)
                .map(mapItemAvaliavelToColumn)
                .value();
        }

        /**
         * Retorna o nome da coluna para o item avaliável passado
         * @param {Object} itemAvaliavel - item avaliável
         */
        function mapItemAvaliavelToColumn(itemAvaliavel) {
            return COLUMN_DESCRIPTION[getColumnDescription(itemAvaliavel.tipoPeriodo)];
        }

        /**
         * Aplica um modo de avaliação à todos os itens avaliáveis
         * @param {string} modo - novo modo de avaliação
         */
        function applyDefaultMode(modo) {
            _.chain(vm.componentes)
                .map('$$itensAvaliaveis')
                .flatten()
                .filter(filterItensEditaveis)
                .map(function (itemAvaliavel) {
                    itemAvaliavel.modoAvaliacao = modo;
                })
                .value();
        }

        function filterItensEditaveis(itemAvaliavel) {
            return !itemAvaliavel.$$isNonEditable;
        }

        function filterItensChaObj(itemAvaliavel) {
            return !_.contains(TIPO_PERIODOS_CHA_OBJ, itemAvaliavel.tipoPeriodo);
        }

        function getColumnDescription(tipoPeriodo) {
            if(tipoPeriodo.includes(CHA_OBJETIVO_PREFIX)) {
                return vm.isCamposExperiencia ? OBJETIVO_APRENDIZAGEM : CHA;
            }

            return tipoPeriodo;
        }

        function createItemAvaliavelCHAOuObjAprendizagem(componente, periodo, isTipoPeriodoExameFinal) {
            if (isTipoPeriodoExameFinal) {
                return createItemAvaliavel(componente, periodo, TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL, CONFIGURACAO_TURMA);
            }

            return createItemAvaliavel(componente, periodo, TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO, CONFIGURACAO_TURMA);
        }

        function getModoAvaliacao(componente, periodo, tipoPeriodo, tipo) {
            if (tipoPeriodo !== TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO && tipoPeriodo !== TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL) {
                return null;
            }

            var itemAvaliavel = _.find(componente.itensAvaliaveis, function (item) {
                return item.tipoPeriodo === converTipoPeriodo(tipoPeriodo) && item.tipo === tipo && _.get(item, 'periodoAvaliativo.id') === _.get(periodo, 'id');
            });

            return _.get(itemAvaliavel, 'modoAvaliacao') || null;
        }

        function converTipoPeriodo(tipoPeriodo) {
            if (tipoPeriodo === TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO) {
                return TIPO_PERIODO.MEDIA_PERIODO;
            }

            if (tipoPeriodo === TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL) {
                return TIPO_PERIODO.EXAME_FINAL;
            } 

            return tipoPeriodo;
        }

        function changeModoAvaliacaoColumn(index) {
            _.forEach(vm.componentes, function (item) {
                if(!item.$$itensAvaliaveis[index].$$isNonEditable) {
                    item.$$itensAvaliaveis[index].modoAvaliacao = vm.modoAvaliacaoColumnValue[index];
                }
            });

            clearModoAvaliacaoColumn(index);
        }

        function insertItemChaOuObj(componente, insertIndex, periodoAvaliativo, isExameFinal) {
            componente.$$itensAvaliaveis.splice(insertIndex, 0, createItemAvaliavelCHAOuObjAprendizagem(componente,periodoAvaliativo,isExameFinal));
        }

        function insertChaObjForComponenteWithoutPeriodo(componente) {
            var indexMedia = _.findLastIndex(componente.$$itensAvaliaveis, 'tipoPeriodo', TIPO_PERIODO.MEDIA_PERIODO);
            insertItemChaOuObj(componente, indexMedia, null, false);

            componente.$$itensAvaliaveis = sortByTipoPeriodo(componente.$$itensAvaliaveis, SORT_TIPO_PERIODO);
        }

        function insertMediaPeriodoForComponenteWithoutPeriodo(componente) {
            var insertIndex = _.findLastIndex(componente.$$itensAvaliaveis, 'tipoPeriodo', TIPO_PERIODO.EXAME_FINAL);
            componente.$$itensAvaliaveis.splice(insertIndex, 0, createItemAvaliavel(componente, null, TIPO_PERIODO.MEDIA_PERIODO, CONFIGURACAO_TURMA));
        }

        function insertItensForComponenteWithoutPeriodo(componente) {
            var isMediaPeriodoNeeded = !vm.periodos && !hasItemByTipoPeriodo(componente.$$itensAvaliaveis, TIPO_PERIODO.MEDIA_PERIODO);
            var isChaObjNeeded = !vm.periodos && !hasItemByTipoPeriodo(componente.$$itensAvaliaveis, TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO) && !vm.isModular;

            if (isMediaPeriodoNeeded) {
                insertMediaPeriodoForComponenteWithoutPeriodo(componente);
            }

            if (isChaObjNeeded) {
                insertChaObjForComponenteWithoutPeriodo(componente);
            }
        }

        function disableApplyAllButton(index) {
            var itensNaoEditaveis = _.filter(vm.componentes, function(componente) {
                return componente.$$itensAvaliaveis[index].$$isNonEditable;
            });

            return itensNaoEditaveis.length === vm.componentes.length;
        }

        function hasItemByTipoPeriodo(itensAvaliaveis, tipoPeriodo) {
            return _.find(itensAvaliaveis, 'tipoPeriodo', tipoPeriodo);
        }

        function sortByTipoPeriodo(itemToSort, sortByMethod) {
            if (sortByMethod === SORT_TIPO_PERIODO_FINAIS) {
                return _.sortBy(itemToSort, sortItensExamesFinais);
            }

            var sortFunction = sortItensAvaliaveis.bind(null, sortByMethod);
            return _.sortBy(itemToSort, sortFunction);
        }

        function sortItensExamesFinais(item) {
            if(SORT_TIPO_PERIODO_FINAIS[item.tipoPeriodo]) {
                return sortItensAvaliaveis(SORT_TIPO_PERIODO_FINAIS, item);
            }

            return 0;
        }

        function clearModoAvaliacaoColumn(index) {
            vm.modoAvaliacaoColumnValue[index] = '';
        }

        function removePeriodoItensDuplicados(itensAvaliaveis) {
            var tiposPeriodo = itensAvaliaveis.map(function (item) { 
                return item.tipoPeriodo;
            });

            return itensAvaliaveis.filter( function (item, index) { 
                return !tiposPeriodo.includes(item.tipoPeriodo, index + 1);
            });
        }

        function removeItensDuplicadosFinais(itensAvaliaveis) {
            var indexTiposFinais = getIndexByTipoPeriodoFinal(itensAvaliaveis);

            _.forEach(TIPO_PERIODOS_FINAIS, function(tipoPeriodo) {
                var firstOcurrencyIndex = _.findIndex(indexTiposFinais, { 'tipoPeriodo': tipoPeriodo });
                var lastOcurrencyIndex = _.findLastIndex(indexTiposFinais, { 'tipoPeriodo': tipoPeriodo });

                if(firstOcurrencyIndex !== lastOcurrencyIndex) {
                    var indexToRemove = indexTiposFinais[lastOcurrencyIndex].indexItensAvaliaveis;
                    itensAvaliaveis.splice(indexToRemove, 1);
                }
            });
        }

        function getIndexByTipoPeriodoFinal(itensAvaliaveis) {
            var indexTiposFinais = [];
            
            _.forEach(itensAvaliaveis, function(item, index) {
                if(_.includes(TIPO_PERIODOS_FINAIS, item.tipoPeriodo)) {
                    indexTiposFinais.push({ 'tipoPeriodo': item.tipoPeriodo, 'indexItensAvaliaveis': index });
                }
            });

            return indexTiposFinais;
        }
    }

})();

(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appPanelRealizarPesquisaFiltro', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/panel-realizar-pesquisa-filtro/panel-realizar-pesquisa-filtro.directive.html',
            scope: {},
            bindToController: {
                searchSelector: '=?',
                filterSelector: '=?'
            },
            controller: DirectiveController,
            controllerAs: 'vm'
        };
    }

    DirectiveController.$inject = [];

    function DirectiveController() {

        var vm = this;

        vm.focusOnFilter = focusOnFilter;
        vm.focusOnSearch = focusOnSearch;

        vm.filterSelector = vm.filterSelector || '.ui-search-advanced-dropdown';
        vm.searchSelector = vm.searchSelector || '.search-field';

        function focusOnSearch() {
            $(vm.searchSelector).focus();
        }

        function focusOnFilter($event) {
            $(vm.filterSelector).click();
            $event.stopPropagation();
        }

    }

})();


(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        var STATE = 'app.enderecos';

        $stateProvider.state(STATE, {
            abstract: true,
            views: {
                '@': {
                    templateUrl: 'enderecos/enderecos.html'
                }
            }
        });
    }
})();
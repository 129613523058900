(function() {
    'use strict';

    angular.module('educacao.quadro-funcionarios-configuracao')
        .service('educacao.quadro-funcionarios-configuracao.QuadroFuncionariosUtils', QuadroFuncionariosUtils);

    QuadroFuncionariosUtils.$inject = [
        'educacao.matriz.EtapaDisciplinaService',
        'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosService',
        'bfc.Notification'
    ];

    function QuadroFuncionariosUtils(EtapaDisciplinaService, QuadroFuncionariosService, bfcNotification) {
        var vm = this;

        var EDUCACAO_BASICA = 'EDUCACAO_BASICA';

        var NIVEL_ESCOLAR_GRUPO_UNICO = {
            ENSINO_FUNDAMENTAL: 'ENSINO_FUNDAMENTAL',
            EDUCACAO_INFANTIL: 'EDUCACAO_INFANTIL'
        };

        var EQUIVALENCIAS_GRUPO_UNICO = {
            ETAPA_1: 'ETAPA_1',
            ETAPA_2: 'ETAPA_2',
            ETAPA_3: 'ETAPA_3',
            ETAPA_4: 'ETAPA_4',
            ETAPA_5: 'ETAPA_5'
        };

        vm.getComponentesCurriculares = getComponentesCurriculares;
        vm.remove = remove;
        
        vm.getItemOrganizacaoCurricularByEtapaMatriz = getItemOrganizacaoCurricularByEtapaMatriz;
        vm.getDescricaoItemCurricular = getDescricaoItemCurricular;

        vm.setDefaultConfigGroups = setDefaultConfigGroups;
        vm.sortEtapas = sortEtapas;
        vm.canSetGrupoUnico = canSetGrupoUnico;

        function getComponentesCurriculares(etapaMatrizId, matrizCurricularId, filter) {
            return EtapaDisciplinaService.getList(
                matrizCurricularId,
                etapaMatrizId,
                filter
            );
        }

        function getItemOrganizacaoCurricularByEtapaMatriz(etapaMatriz) {
            if(!etapaMatriz.disciplina && !etapaMatriz.campoExperiencia && !etapaMatriz.eixoTematico) {
                return etapaMatriz;
            }

            return etapaMatriz.disciplina ? etapaMatriz.disciplina : etapaMatriz.campoExperiencia ? etapaMatriz.campoExperiencia : etapaMatriz.eixoTematico;
        }

        function getDescricaoItemCurricular(etapaMatriz) {
            if(!etapaMatriz.disciplina && !etapaMatriz.campoExperiencia && !etapaMatriz.eixoTematico) {
                return etapaMatriz.descricao;
            }
            
            return etapaMatriz.disciplina ? etapaMatriz.disciplina.descricao : etapaMatriz.campoExperiencia ? etapaMatriz.campoExperiencia.descricao : etapaMatriz.eixoTematico.descricao;
        }

        function setDefaultConfigGroups(componentesCurriculares, nivelModalidade, nivelEscolar, equivalencia) {
            var configQuadrogrupos = [];
            var seqNomeGrupo = 1;

            if(nivelModalidade && nivelEscolar && equivalencia) {
                if(canSetGrupoUnico(nivelModalidade, nivelEscolar, equivalencia)) {
                    return setGruposWithEquivalenciaOrInfantil(componentesCurriculares, seqNomeGrupo, configQuadrogrupos);
                }
            }

            return setDefaultGroup(componentesCurriculares, seqNomeGrupo, configQuadrogrupos);
        }

        function canSetGrupoUnico(nivelModalidade, nivelEscolar, equivalencia) {
            if(nivelModalidade === EDUCACAO_BASICA) {
                if(nivelEscolar === NIVEL_ESCOLAR_GRUPO_UNICO.EDUCACAO_INFANTIL) {
                    return true;
                }

                if(nivelEscolar === NIVEL_ESCOLAR_GRUPO_UNICO.ENSINO_FUNDAMENTAL && (equivalencia in EQUIVALENCIAS_GRUPO_UNICO)) {
                    return true;
                }
            }

            return false;
        }

        function setGruposWithEquivalenciaOrInfantil(componentesCurriculares, seqNomeGrupo, configQuadrogrupos) {
            var configComponentes = [];
            var componentesCurricularesFormatados = _.map(componentesCurriculares, getItemOrganizacaoCurricularByEtapaMatriz);

            _.forEach(componentesCurricularesFormatados, function(componenteFormatado) {
                configComponentes.push({
                    itemOrganizacaoCurricular: componenteFormatado
                });
            });

            configQuadrogrupos.push({
                descricao: 'Grupo ' + seqNomeGrupo.toString(),
                configuracaoQuadroFuncionariosComponentes: configComponentes,
                grupoEtapaMatrizDisciplinas: componentesCurriculares,
                quantidadeDocentes: 1
            });

            seqNomeGrupo++;
            return configQuadrogrupos;
        }

        function setDefaultGroup(componentesCurriculares, seqNomeGrupo, configQuadrogrupos) {
            _.forEach(componentesCurriculares, function(componenteCurricular) {
                var configQuadroComponente = {
                    itemOrganizacaoCurricular: getItemOrganizacaoCurricularByEtapaMatriz(componenteCurricular)
                };
                
                configQuadrogrupos.push({
                    descricao: 'Grupo ' + seqNomeGrupo.toString(),
                    configuracaoQuadroFuncionariosComponentes: [configQuadroComponente],
                    grupoEtapaMatrizDisciplinas: [componenteCurricular],
                    quantidadeDocentes: 1
                });

                seqNomeGrupo++;
            });

            return configQuadrogrupos;
        }

        function remove(configQuadroFuncionario) {
            return QuadroFuncionariosService.remove(configQuadroFuncionario)
              .then(function() {
                bfcNotification.publish('Configuração ' + configQuadroFuncionario.matrizCurricular.descricao + ' excluída com sucesso', 'success');
              });
        }

        function sortEtapas(etapas) {
            return etapas.sort(function (a,b) {
                return a.etapaMatriz.descricao.localeCompare(b.etapaMatriz.descricao, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
        }
    }
})();
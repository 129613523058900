(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .directive('appFiltroAvancadoAlunoFiltrosPadroes', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'matricula/matriculas/filtro-avancado/aluno/filtros-padroes/filtros-padroes.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorFiltroPadraoAluno: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = [
        'educacao.funcionario.FuncionarioService'
    ];

    function Controller(
        FuncionarioService
    ) {
        var vm = this;
        vm.contadorDadosPadroes = contadorDadosPadroes;
        vm.formatSexo = formatSexo;

        vm.expressionCustomBuildersDadosPadroes = {
            dataNascimentoInicio: function (filters) {
                if (filters.dataNascimentoInicio) {
                    return 'dataNascimentoInicio >= ' + filters.dataNascimentoInicio;
                }
            },
            dataNascimentoFinal: function (filters) {
                if (filters.dataNascimentoFinal) {
                    return 'dataNascimentoFinal <= ' + filters.dataNascimentoFinal;
                }
            },
            nomeSocial: function (filters) {
                if (filters.nomeSocial) {
                    return 'nomeFantasia elike "' + encodeURI('%' + filters.nomeSocial + '%') + '"';
                }
            },
            codigoInep: function (filters) {
                if (filters.codigoInep) {
                    return 'codigoInep = "' + filters.codigoInep + '"';
                }
            }
        };

        init();

        function init() {
            vm.expressionCustomBuilders = Object.assign(vm.expressionCustomBuilders, vm.expressionCustomBuildersDadosPadroes);
            vm.contadorFiltroPadraoAluno = 0;
            vm.EnumSexo = FuncionarioService.getSexo().then(function(dados){
                vm.ENUM_SEXO = dados;
                return dados;
            });
            contadorDadosPadroes();
        }

        function contadorDadosPadroes() {
            if (vm.advancedFilter && vm.advancedFilter.filtroPadraoAluno) {
                vm.contadorFiltroPadraoAluno = getFilterLength();
            }
        }

        function getFilterLength() {
            return Object.values(vm.advancedFilter.filtroPadraoAluno).filter(function (filter) { return filter; }).length;
        }

        function formatSexo(sexo) {
            return vm.ENUM_SEXO[sexo].description;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('inscricao.ViewFormCtrl', ViewFormCtrl);

    ViewFormCtrl.$inject = ['$modalInstance', '$modalTracker', '$q', 'configuracao','educacao.enderecos.BairroService',
        'educacao.enderecos.MunicipioService','educacao.enderecos.EstadoService','bfc.$$PermissionsService'];

    function ViewFormCtrl($modalInstance, $modalTracker, $q, configuracao,BairroService,MunicipioService,EstadoService,$$PermissionsService) {
        var vm = this;
        vm.MunicipioService = MunicipioService;
        vm.BairroService = BairroService;
        vm.EstadoService = EstadoService;
        vm.PermissaoService = $$PermissionsService;

        var propriedadesPublicas = {
            cancel: _cancel
        };

        function refresh() {

            if (vm.form) {
                vm.form.$setPristine();
            }

            vm.inscricao = {
                dados: {}
            };
            vm.configuracao = configuracao;
            vm.show = true;

        }

        $modalTracker.addPromise(
            $q.when(
                refresh()
            ).then(
                angular.extend(vm, propriedadesPublicas)
            )
        );

        function _cancel() {
            $modalInstance.close();
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .directive('saldosEscolas', saldosEscolas);


    function saldosEscolas() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/app-saldo-escola/saldos-escola/saldos-escola.directive.html',
            scope: {},
            bindToController: {
                estabelecimento: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        
    ];

    function Controller() {
        var vm = this;
        
        vm.saldos = [
            { label: 'Saldo Inicial', saldo: vm.estabelecimento.saldoInicial },
            { label: 'Saldo Ocupado', saldo: vm.estabelecimento.saldoOcupado },
            { label: 'Saldo Atual', saldo: vm.estabelecimento.saldoFinal }
        ];
    }

})();
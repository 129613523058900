(function() {
    'use strict';

    var EVENT_EDIT = 'view-event.edit';
    var EVENT_DELETE = 'view-event.delete';

    angular.module('educacao.calendario')
        .constant('EVENT_VIEW_EDIT_EVENT', EVENT_EDIT)
        .constant('EVENT_VIEW_DELETE_EVENT', EVENT_DELETE)
        .directive('eventsView', EventsView);

    function EventsView() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/view-events/events-view.directive.html',
            scope: {
                calendarioDisplay: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        'IdGenerator',
        'educacao.calendario.CalendarioEventoService'
    ];

    function Controller($scope, promiseTracker, IdGenerator, calendarioEventoService) {
        var KEY_GROUP_ALL = '0_all';

        var vm = this;

        vm.trackers = {
            publicoAlvo: promiseTracker()
        };

        vm.groups = {};
        vm.getPublicoAlvo = getPublicoAlvo;

        vm.isReadonly = isReadonly;
        vm.edit = edit;
        vm.remove = remove;

        var publicoAlvoEnum = {};
        $scope.$watch('calendarioDisplay.processCount', groupBy);

        vm.trackers.publicoAlvo.addPromise(calendarioEventoService.getPublicoAlvo()
            .then(function(publicoAlvo) {
                publicoAlvo.forEach(function(publico) {
                    publicoAlvoEnum[publico.key] = publico;
                });
            }));

        function groupBy() {
            vm.groups = {};
            vm.groups[KEY_GROUP_ALL] = {
                title: 'Todos',
                list: []
            };
            _.forEach($scope.calendarioDisplay.flatten.eventos, setEventOnGroups);
        }

        function setEventOnGroups(evento) {
            if (!_.isEmpty(evento.matrizesCurriculares)) {
                _.forEach(evento.matrizesCurriculares, function(matriz) {
                    addToGroup('mat_' + matriz.id, matriz.descricao, evento);
                });
            } else if (!_.isEmpty(evento.estabelecimentos)) {
                _.forEach(evento.estabelecimentos, function(escola) {
                    addToGroup('est_' + escola.id, escola.descricao, evento);
                });
            } else {
                IdGenerator.addOrUpdate(evento, vm.groups[KEY_GROUP_ALL].list);
            }
        }

        function addToGroup(id, descricao, evento) {
            if (!vm.groups[id]) {
                vm.groups[id] = {
                    title: descricao,
                    list: []
                };
            }
            IdGenerator.addOrUpdate(evento, vm.groups[id].list);
        }

        function getPublicoAlvo(evento) {
            if (_.isEmpty(evento.publicoAlvo)) {
                return '';
            }
            return _.chain(evento.publicoAlvo)
                .sortBy()
                .map(function(publicoAlvo) {
                    return publicoAlvoEnum[publicoAlvo].description;
                })
                .value()
                .join(', ');
        }

        function isReadonly(evento) {
            return evento.$$readonly || (evento.aceite && evento.aceite.readonly);
        }

        function edit(evento) {
            if (isReadonly(evento) || $scope.calendarioDisplay.blocked) {
                return;
            }
            $scope.$emit(EVENT_EDIT, evento);
        }

        function remove(evento) {
            if (isReadonly(evento)) {
                return;
            }
            $scope.$emit(EVENT_DELETE, evento);
        }
    }

})();

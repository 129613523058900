(function() {
    'use strict';

    var TIPOS_CADASTRAVEIS = [1, 2, 6, 7];

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MotivosDeMovimentacaoCadCtrl', Controller);

    Controller.$inject = ['$modalInstance', 'educacao.matricula.MotivoMovimentacaoService'];

    function Controller($modalInstance, MotivosMovimentacaoService) {

        var vm = this;

        vm.motivoMovimentacao = $modalInstance.params.motivoMovimentacao || {};

        vm.save = save;
        vm.remove = remove;

        init();

        function init() {
            vm.enumTipoMotivoMovimentacao = MotivosMovimentacaoService.getTipoMovimentacao().then(function(data) {
                var copy = angular.copy(data);
                copy.values = _.filter(data.values, function(value) {
                    return TIPOS_CADASTRAVEIS.indexOf(value.value) !== -1;
                });
                return copy;
            });
            vm.windowTitle = vm.motivoMovimentacao.id ? 'Editando motivo de movimentação e remanejamento interno' :
                'Adicionando motivo de movimentação e remanejamento interno';
        }

        function save(continuar) {
            var promise = MotivosMovimentacaoService.save(vm.motivoMovimentacao).then(function(data) {
                if (!continuar) {
                    $modalInstance.close(data);
                } else {

                    vm.motivoMovimentacao = {};

                    vm.form.$setPristine();
                    vm.form.$setUntouched();
                }
            });
            $modalInstance.tracker.addPromise(promise);
        }

        function remove() {
            var promise = MotivosMovimentacaoService.remove(vm.motivoMovimentacao).then($modalInstance.close);
            $modalInstance.tracker.addPromise(promise);
        }
    }
})();

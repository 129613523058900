(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appDownloadArquivo', appDownloadArquivoDirective);

    function appDownloadArquivoDirective() {
        return {
            retrict: 'E',
            required: 'ngModel',
            templateUrl: 'common/directives/app-download-arquivo/app-download-arquivo.directive.html',
            bindToController: {
                ngModel: '='
            },
            scope: {
                ngClick: '&'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'Restangular', 'promiseTracker', 'API_URL', 'AuthenticationContext', 'UserAccessContext', 'bfcApplicationContext', '$window'];

    function Controller($scope, Restangular, promiseTracker, API_URL, AuthenticationContext, UserAccessContext, ApplicationContext, $window) {

        var vm = this;
        vm.url = null;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.nomeArquivo = vm.ngModel.nome;
        vm.idArquivo = vm.ngModel.id;
        var toBase64 = function (context) {
            return $window.btoa(JSON.stringify(context));
        };
        vm.url = API_URL + '/arquivo/' + vm.idArquivo + '/download?' +
            '&user_access=' + UserAccessContext.getUserAccess().licenseId +
            '&app_context=' + toBase64(ApplicationContext.getList()) +
            '&access_token=' + AuthenticationContext.getAccessToken();
    }
})();

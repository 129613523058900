(function () {
    'use strict';
    angular.module('educacao.lista-espera-novo.anotacoes')
        .controller('educacao.lista-espera-novo.anotacoes.ModalAnotacoesInscritos', Controller);

    Controller.$inject = ['educacao.lista-espera-novo.anotacoes.AnotacaoInscricaoService',
        'educacao.pessoas.EstabelecimentoService',
        '$modalInstance',
        'modalConfirmService',
        'promiseTracker',
        'bfc.Notification',
        '$scope'];

    function Controller(AnotacaoInscricaoService, EstabelecimentoService, $modalInstance, modalConfirmService, promiseTracker, bfcNotification, $scope) {
        var vm = this;
        vm.anotacoes = [];
        vm.anotacaoToAdd = {
            'data': moment().format('YYYY-MM-DD'),
            'hora': moment().format('HH:mm:ss'),
            'descricao': '',
            'inscricao': {}
        };
        vm.removerLinha = removerLinha;
        vm.editar = editar;
        vm.save = save;
        vm.remove = remove;
        vm.excluirAnotacao = excluirAnotacao;
        vm.adicionarAnotacao = adicionarAnotacao;
        vm.updatedAnotacaoToAdd = updatedAnotacaoToAdd;
        vm.inscricaoListaEspera = _.get($modalInstance, 'params.inscricaoListaEspera');
        vm.isInscrito = _.get(vm.inscricaoListaEspera, 'situacao.inscrito');
        vm.isEditing = false;
        vm.noop = angular.noop;
        vm.promiseTracker = {
            save: promiseTracker(),
            load: promiseTracker(),
            remove: promiseTracker()
        };
        vm.currentPage = 1;
        vm.itemsPerPage = 5;

        $scope.$watch('vm.anotacoes', buildPaginacao);
        $scope.$watch('vm.totalItems', loadFirstPage);
        $scope.$watch('vm.currentPage', watcherCurrentPage);

        init();

        function init() {
            loadAnotacoes();
        }

        function loadFirstPage(totalRegistros) {
            if(totalRegistros === vm.itemsPerPage){
                vm.currentPage = 1;
            }
        }

        function watcherCurrentPage(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            buildPaginacao();
        }

        function loadAnotacoes() {
            var params = resolveParams();
            var promise = AnotacaoInscricaoService.listAnotacoes(params);
            vm.promiseTracker.load.addPromise(promise);
            promise.then(function (data) {
                vm.anotacoes = _.forEach(data.content, ajusteParaView);
                buildPaginacao();
            });
        }

        function buildPaginacao() {
            var startIdx = (vm.currentPage - 1) * vm.itemsPerPage;
            vm.anotacoesPagination = vm.anotacoes.slice(startIdx, startIdx + vm.itemsPerPage);
            vm.totalItems = vm.anotacoes.length;
            vm.hasNext = (startIdx + vm.itemsPerPage) < vm.totalItems;
            vm.totalContent = vm.totalItems;
        }

        function resolveParams() {
            var params = {
                filter: '(inscricao="' + vm.inscricaoListaEspera.id + '")',
                sort : 'id desc'
            };
            return params;
        }

        function ajusteParaView(anotacao) {
            var dataEHora = separarDataEHora(anotacao.dataHora);
            anotacao.hora = dataEHora.hora;
            anotacao.data = dataEHora.data;
            return anotacao;
        }

        function removerLinha(list, index) {
            list.splice(index, 1);
        }

        function editar(list, index) {
            vm.isEditing = true;
            vm.anotacaoToAdd = angular.copy(vm.anotacoesPagination[index]);
        }

        function ajusteParaSalvar(anotacao) {
            anotacao.inscricao = vm.inscricaoListaEspera.id;
            anotacao.dataHora = juntarDataEHora(anotacao.data, anotacao.hora);
            delete anotacao.data;
            delete anotacao.hora;
            return anotacao;
        }

        function remove(list, index) {
            var anotacao = list[index];
            if (!_.get(anotacao, 'id')) {
                deleteItemOriginalArray(anotacao);
                return removerLinha(list, index);
            }
            var promise = AnotacaoInscricaoService.removeAnotacao(_.get(anotacao, 'id'));
            promise.then(removeSuccess).then(function () {
                removerLinha(list, index);
                loadAnotacoes();
            });
            return promise;
        }

        function deleteItemOriginalArray(anotacao) {
            var index = _.findIndex(vm.anotacoes, function (item) {
                return item === anotacao;
            });
            if (index !== -1) {
                vm.anotacoes.splice(index, 1);
            }
        }

        function save() {
            var anotacoes = angular.copy(vm.anotacoes);
            var anotacoesAjustadas = _.map(anotacoes, ajusteParaSalvar);
            var promise = AnotacaoInscricaoService.saveList(anotacoesAjustadas);
            vm.promiseTracker.save.addPromise(promise);
            promise.then(saveSuccess).then(vm.onClose).then(closeModal);
            return promise;
        }

        function saveSuccess() {
            return bfcNotification.publish('Anotações salvas com sucesso.', 'success');
        }

        function removeSuccess() {
            return bfcNotification.publish('Anotação removida com sucesso.', 'success');
        }

        function juntarDataEHora(data, hora) {
            var dataFormatada = moment(data, 'YYYY-MM-DD').format('YYYY-MM-DD');
            var horaFormatada = moment(hora, 'HH:mm:ss').format('HH:mm:ss');
            return dataFormatada + 'T' + horaFormatada + '.000';
        }

        function separarDataEHora(dataEHora) {
            var momento = moment(dataEHora, 'YYYY-MM-DDTHH:mm:ss.SSS');
            return {
                'data': momento.format('YYYY-MM-DD'),
                'hora': momento.format('HH:mm:ss'),
            };
        }

        function adicionarAnotacao() {
            if (!vm.isEditing) {
                adicionarNovaAnotacao();
            } else {
                atualizarAnotacaoExistente();
            }
            buildPaginacao();
            vm.isEditing = false;
        }

        function adicionarNovaAnotacao() {
            var anotacao = angular.copy(vm.anotacaoToAdd);
            vm.anotacoes.unshift(anotacao);
            updatedAnotacaoToAdd();
        }

        function atualizarAnotacaoExistente() {
            var index = vm.anotacoes.findIndex(function (item) {
                return item.id === vm.anotacaoToAdd.id;
            });
            if (index !== -1) {
                vm.anotacoes[index] = angular.copy(vm.anotacaoToAdd);
                updatedAnotacaoToAdd();
            }
        }

        function excluirAnotacao(list, index) {
            modalConfirmService({
                title: 'Excluir anotação',
                message: 'Ao excluir a anotação, está não poderá ser recuperada.',
                confirmButton: 'Excluir',
                cancelButton: 'Cancelar',
                position: 'center'
            }).then(excluir);

            function excluir() {
                remove(list, index);
            }
        }

        function updatedAnotacaoToAdd() {
            if(!vm.isEditing){
                delete vm.anotacaoToAdd.id;
                delete vm.anotacaoToAdd.createdBy;
                delete vm.anotacaoToAdd.updatedBy;
                delete vm.anotacaoToAdd.createdIn;
                delete vm.anotacaoToAdd.updatedIn;
            }
            delete vm.anotacaoToAdd.descricao;
            delete vm.anotacaoToAdd.dataHora;
            vm.anotacaoToAdd.data = moment().format('YYYY-MM-DD');
            vm.anotacaoToAdd.hora = moment().format('HH:mm:ss');
        }

        function closeModal() {
            return $modalInstance.close();
        }

    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .config(HistoricoConfig);

    HistoricoConfig.$inject = ['educacao.historico.HistoricoServiceProvider'];
    function HistoricoConfig(HistoricoServiceProvider) {
        HistoricoServiceProvider.addTipoHandler('ENTURMACAO', {
            badge: {
                icon: 'fa-users',
                tooltip: 'Enturmação'
            },
            campos: {
                turma: {
                    insercao: {
                        template: '<small>Enturmado na turma: <strong>{{turma.valor}}</strong><small><br>'
                    },
                    remocao: {
                        template: '<small>Desenturmado da turma: <strong>{{turma.valorAnterior}}</strong><small><br>'
                    }
                },
                numero: {
                    insercao: {
                        template: '<small>Número da chamada: <strong>{{valor}}</strong><small><br>'
                    }
                },
                disciplina: {
                    insercao: {
                        template: '<small>Disciplina: <strong>{{valor}}</strong><small><br>'
                    }
                }
            }
        });
    }
})();
(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .directive('appCadastroCha', appCadastroCha);

    function appCadastroCha() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/configuracao-avaliacao/app-table-configuracao-avaliacao/app-cha/app-cadastro-cha.directive.html',
            scope: {},
            bindToController: {
                componente: '=',
                chaModel: '=',
                tipoCha: '@',
                configuracaoExibicao: '=',
                periodos: '=',
                hasExameFinal: '=',
                isAtividade: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    var TIPOS_PERIODO = {
        MEDIA_PERIODO: 'MEDIA_PERIODO',
        EXAME_FINAL: 'EXAME_FINAL'
    };

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.onChangeCompetencia = onChangeCompetencia;
        vm.removerItem = removerItem;
        vm.adicionarItem = adicionarItem;
        vm.onClickAplicar = onClickAplicar;
        vm.aplicarParaTodos = aplicarParaTodos;

        configureItens();

        /**
         * Cria os períodos avaliativos que serão 
         * exibidos no cadastro do cha
         */
        function configureItens() {
            if (vm.chaModel) {
                _.forEach(vm.chaModel, function(cha) {
                    cha.$$periodos = [];
                    var quantidadePeriodos = _.get(vm.periodos, 'length') + (vm.hasExameFinal ? 1 : 0);

                    if (vm.isAtividade) {

                        var itemMediaFinal = _.find(cha.itensAvaliaveis, {
                            tipoPeriodo: TIPOS_PERIODO.MEDIA_PERIODO
                        });
                        var itemExameFinal = _.find(cha.itensAvaliaveis, {
                            tipoPeriodo: TIPOS_PERIODO.EXAME_FINAL
                        });

                        cha.permiteAvaliacao = _.get(itemMediaFinal, 'permiteAvaliacao') || _.get(itemExameFinal, 'permiteAvaliacao') || false;

                        if ((itemMediaFinal && itemExameFinal) || (itemMediaFinal && !vm.hasExameFinal)) {
                            cha.$$periodos.push(createItemTodos(itemMediaFinal.permiteAvaliacao));
                        } else if (itemMediaFinal) {
                            cha.$$periodos.push(createItemPeriodoAtividade(itemMediaFinal));
                        } else if (itemExameFinal) {
                            cha.$$periodos.push(createItemPeriodoAtividade(itemExameFinal));
                        }
                    } else {
                        cha.permiteAvaliacao = null;
                        if (cha.itensAvaliaveis.length) {
                            cha.permiteAvaliacao = _.first(cha.itensAvaliaveis).permiteAvaliacao;
                        }

                        if (quantidadePeriodos === _.get(cha.itensAvaliaveis, 'length')) {
                            cha.$$periodos.push(createItemTodos(cha.permiteAvaliacao));
                        } else {
                            _.forEach(cha.itensAvaliaveis, function(item) {
                                cha.$$periodos.push(createItemPeriodo(item, (item.tipoPeriodo === TIPOS_PERIODO.EXAME_FINAL)));
                            });
                        }
                    }

                    if (!_.first(cha.$$periodos)) {
                        cha.$$periodos = '';
                    }
                });

                atualizaChasSelecionados(vm.chaModel);
            }
        }

        function onChangeCompetencia(chaModel, cha, competencia) {
            if (cha.itensAvaliaveis) {
                angular.forEach(cha.itensAvaliaveis, function(item) {
                    item.competenciaCHA = competencia;
                });
            }
            atualizaChasSelecionados(chaModel);
        }

        function atualizaChasSelecionados(chaModel) {
            vm.listExistentes = _.pluck(chaModel, 'competencia');
        }

        function createItemTodos(permiteAvaliacao) {
            return {
                id: 'TODOS',
                descricao: 'Todos',
                periodoAvaliativo: null,
                permiteAvaliacao: permiteAvaliacao
            };
        }

        function createItemPeriodoAtividade(itemPeriodo) {
            return {
                periodoAvaliativo: null,
                tipoPeriodo: itemPeriodo.tipoPeriodo,
                id: itemPeriodo.itemPeriodo || itemPeriodo.tipoPeriodo,
                descricao: (itemPeriodo.tipoPeriodo === TIPOS_PERIODO.EXAME_FINAL) ? 'Exame final' : 'Média',
                permiteAvaliacao: itemPeriodo.permiteAvaliacao
            };
        }

        function createItemPeriodo(itemPeriodo, isExameFinal) {
            if (itemPeriodo.periodoAvaliativo || isExameFinal) {
                return {
                    periodoAvaliativo: itemPeriodo.periodoAvaliativo,
                    tipoPeriodo: itemPeriodo.tipoPeriodo,
                    id: isExameFinal ? TIPOS_PERIODO.EXAME_FINAL : itemPeriodo.periodoAvaliativo.id,
                    descricao: isExameFinal ? 'Exame Final' : itemPeriodo.periodoAvaliativo.descricao,
                    permiteAvaliacao: itemPeriodo.permiteAvaliacao
                };
            }
            return;
        }

        function adicionarItem(chaModel) {
            chaModel.push({
                itensAvaliaveis: []
            });
        }

        function removerItem(chaModel, index) {
            chaModel.splice(index, 1);
            atualizaChasSelecionados(chaModel);
        }

        function onClickAplicar() {
            _.forEach(vm.chaModel, setPeriodo);
        }

        function setPeriodo(item) {
            item.itensAvaliaveis = _.map(vm.aplicarTodosPeriodo, _.bind(createItensAvaliaveis, null, item));
            item.$$periodos = vm.aplicarTodosPeriodo;
        }

        function createItensAvaliaveis(item, periodo) {
            return {
                competenciaCHA: item.competencia,
                periodoAvaliativo: periodo.periodoAvaliativo,
                tipo: 'CHA',
                tipoPeriodo: 'MEDIA_PERIODO',
                permiteAvaliacao: item.permiteAvaliacao
            };
        }

        function aplicarParaTodos() {
            if (!vm.chaModel) {
                return;
            }

            _.forEach(vm.chaModel, function(cha) {
                cha.permiteAvaliacao = vm.aplicarAvaliado;
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.CartorioInepService', CartorioInepService);

    CartorioInepService.$inject = ['$injector'];

    function CartorioInepService($injector) {
        var GenericService = $injector.get('GenericService');
  
   
        return GenericService
                .createReadOnly()
                .module({uri: 'pessoa'})
                .module({uri: 'configuracoes'})
                .service({uri: 'cartorio'});
    }
})();
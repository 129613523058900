(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.CadastroRegistroDispensaModalController', Controller);

    Controller.$inject = [
        '$scope',
        '$rootScope',
        'promiseTracker',
        'bfc.Notification',
        'educacao.matricula.RegistroDispensaService',
        '$modalInstance',
        'educacao.matricula.MatriculaService',
        '$q'
    ];

    function Controller($scope, $rootScope, promiseTracker, bfcNotification, RegistroDispensaService, $modalInstance, MatriculaService, $q) {
        var vm = this;
        var enumTipoMatricula;

        vm.editando = !!$modalInstance.params.registroDispensa;
        vm.registroDispensa = $modalInstance.params.registroDispensa ? $modalInstance.params.registroDispensa.plain() : {};
        vm.registroDispensa.itemCurricular = _.get(vm.registroDispensa, 'atividade') || _.get(vm.registroDispensa, 'itemCurricular');
        vm.modalTitle = !vm.registroDispensa.id ? 'Adicionando dispensa' : 'Editando dispensa';
        vm.periodosAvaliativos = [];
        vm.itensCurriculares = [];
        vm.existsCalendarioMatriz = true;
        vm.contentPeriodosAvaliativos = null;
        vm.tracker = promiseTracker();
        vm.trackers = {
            save: promiseTracker()
        };

        vm.formatResultMatricula = formatResultMatricula;
        vm.searchPeriodos = searchPeriodos;
        vm.disabledInputPeriodoAnual = disabledInputPeriodoAnual;
        vm.remove = remove;
        vm.save = save;
        vm.isSelecionadoTodosNosComponentesAndPeriodos = isSelecionadoTodosNosComponentesAndPeriodos;
        vm.isMatrizCurricularPossuiCalendarioEscolar = isMatrizCurricularPossuiCalendarioEscolar;
        $scope.$watch('vm.registroDispensa.matricula', watchMatricula);
        $scope.$watch('vm.registroDispensa.periodosAvaliativos', watchPeriodos);
        $scope.$watch('vm.contentPeriodosAvaliativos', checkIsPeriodoAvaliativoAnual);
        $scope.$watch('vm.registroDispensa.matricula.itensCurriculares', watchItensCurricular);
        $scope.$watch('vm.registroDispensa.itemCurricular ', watchItemCurricularSelecionado);
        init();

        function init() {
            if (vm.editando && _.isEmpty(vm.registroDispensa.periodosAvaliativos)) {
                vm.registroDispensa.periodosAvaliativos = addTodosInList([]);
            }
        }

        vm.tracker.addPromise(RegistroDispensaService.getTipoMatricula().then(function (data) {
            enumTipoMatricula = data;
        }));

        function isSelecionadoTodosNosComponentesAndPeriodos() {
            if (!_.get(vm.registroDispensa, 'periodosAvaliativos') || !_.get(vm.registroDispensa, 'itemCurricular') || !_.get(vm.registroDispensa, 'matricula')) {
                return;
            }
            if (isEjaModular() || isTurmaAtividade()) {
                return false;
            }
            var selecionadoOpcaoTodosPeriodoAvaliativo = isSelecionadoOpcaoTodos(vm.registroDispensa.periodosAvaliativos);
            var selecionadoOpcaoTodosItemCurricular = isSelecionadoOpcaoTodos(vm.registroDispensa.itemCurricular);

            return (selecionadoOpcaoTodosPeriodoAvaliativo && selecionadoOpcaoTodosItemCurricular) ||
                (vm.registroDispensa.matricula.itensCurriculares.length === vm.registroDispensa.itemCurricular.length &&
                    vm.registroDispensa.periodosAvaliativos.length === vm.periodosAvaliativos.length);
        }


        function watchItensCurricular(newValue) {
            if (newValue === undefined) {
                return;
            }

            if (!vm.editando && !isPeriodoAnual()) {
                addTodosInList(vm.registroDispensa.matricula.itensCurriculares);
            }

        }

        function watchItemCurricularSelecionado(newValue) {
            if (newValue === undefined) {
                return;
            }
            if (_.find(vm.registroDispensa.itemCurricular, 'id', 'TODOS')) {
                removerItensCurricularesQuandoSelecionaOpcaoTodos();
            }

            if (_.isEmpty(newValue)) {
                vm.registroDispensa.matricula.itensCurriculares = vm.itensCurriculares;
            }
        }

        function removerItensCurricularesQuandoSelecionaOpcaoTodos() {
            _.remove(vm.registroDispensa.matricula.itensCurriculares, function (item) {
                return item.id !== 'TODOS';
            });
            _.remove(vm.registroDispensa.itemCurricular, function (item) {
                return item.id !== 'TODOS';
            });
        }

        function watchMatricula() {
            if (!$scope.form.$pristine) {
                delete vm.registroDispensa.itemCurricular;
                vm.registroDispensa.periodosAvaliativos = [];
                vm.periodosAvaliativos = [];
            }
            if (vm.registroDispensa.matricula && isEjaModular()) {
                vm.registroDispensa.matricula.itensCurriculares.forEach(function (item) {
                    item.descricao += ' - ' + item.etapaMatriz.descricao;
                });
                if (_.get(vm.registroDispensa, 'itemCurricular', false)) {
                    vm.registroDispensa.itemCurricular.descricao += ' - ' + vm.registroDispensa.etapaMatriz.descricao;
                }
            }

            if (vm.registroDispensa.matricula) {
                vm.itensCurriculares = _.clone(vm.registroDispensa.matricula.itensCurriculares);
            }
            vm.periodosAvaliativos = [];
            searchPeriodos();
            if(_.get(vm.registroDispensa, 'matricula')) {
                vm.registroDispensa.matricula.itensCurriculares = addTodosInList(vm.registroDispensa.matricula.itensCurriculares);
            }
        }

        function checkIsPeriodoAvaliativoAnual(newValue) {
            if (!newValue) {
                return;
            }
            if (isPeriodoAnual() && _.get(vm.registroDispensa, 'matricula')) {
                vm.registroDispensa.periodosAvaliativos = newValue[0];
            }
        }

        function disabledInputPeriodoAnual() {
            if (isTurmaAtividade() || isEjaModular() || isPeriodoAnual()) {
                return true;
            }
            return false;
        }

        function isPeriodoAnual() {
            var periodos = _.filter(vm.periodosAvaliativos, function (item) {
                return item.id !== 'TODOS';
            });

            if (_.isEmpty(periodos) || periodos.length === 1) {
                return true;
            }
        }

        function isMatrizCurricularPossuiCalendarioEscolar() {
            var matricula = _.get(vm.registroDispensa, 'matricula');
            if (matricula === null || matricula === undefined || vm.editando) {
                return true;
            }
            if (!_.get(matricula, 'existsCalendarioMatriz')) {
                return false;
            }
            return true;
        }

        function watchPeriodos(newValue, oldValue) {

            if (_.isEqual(newValue, oldValue) || !_.get(vm.registroDispensa, 'matricula')) {
                return;
            }

            if (_.find(vm.registroDispensa.periodosAvaliativos, 'id', 'TODOS')) {
                _.remove(vm.registroDispensa.periodosAvaliativos, function (item) {
                    return item.id !== 'TODOS';
                });
                _.remove(vm.registroDispensa.matricula.itensCurriculares, function (item) {
                    return item.id === 'TODOS';
                });
            }
        }


        function isSelecionadoOpcaoTodos(list) {
            return _.some(list, function (item) {
                return item.id === 'TODOS';
            });
        }

        function formatResultMatricula(data) {
            var turma = data.turma ? ' - ' + data.turma : '';
            var turno = data.turno ? ' - ' + _.capitalize(data.turno.toLowerCase()) : '';
            return data.nome + ' - ' + enumTipoMatricula[data.tipoMatricula].description + turma + turno;
        }

        function save(addNew) {
            var promise;
            var registroDispensa = setDispensaToSave(_.clone(vm.registroDispensa));
            if (vm.editando) {
                promise = RegistroDispensaService.save(registroDispensa);
            } else {
                promise = RegistroDispensaService.saveList(registroDispensa);
            }
            promise.then(function (registro) {
                bfcNotification.publish('Registro de dispensa salvo com sucesso.', 'success');
                if (addNew) {
                    vm.registroDispensa = {};

                    $scope.form.$setPristine();
                    $scope.form.$setUntouched();
                } else {
                    $modalInstance.close(registro);
                }
                $rootScope.registroDispensa = registroDispensa;
            });
            vm.trackers.save.addPromise(promise);
        }

        function setDispensaToSave(registroDispensa) {
            if (!_.isEmpty(vm.registroDispensa.itemCurricular.etapaMatriz)) {
                registroDispensa.etapaMatriz = vm.registroDispensa.itemCurricular.etapaMatriz;
            }
            if (_.find(vm.registroDispensa.periodosAvaliativos, 'id', 'TODOS') || isPeriodoAnual()) {
                registroDispensa.periodosAvaliativos = [];
            }
            var registrosDispensa = [];
            var registroDispensaCopy;


            if (vm.editando) {
                return registroDispensa;

            }
            if (isSelecionadoOpcaoTodos(vm.registroDispensa.itemCurricular)) {
                _.forEach(vm.itensCurriculares, function (itemCurricularDispensa) {
                    registroDispensaCopy = _.cloneDeep(registroDispensa);
                    registroDispensaCopy.itemCurricular = itemCurricularDispensa;
                    registroDispensaCopy.matricula.itensCurriculares = [];
                    registrosDispensa.push(registroDispensaCopy);
                });
                return registrosDispensa;
            }

            _.forEach(registroDispensa.itemCurricular, function (itemCurricularDispensa) {
                registroDispensaCopy = _.cloneDeep(registroDispensa);
                registroDispensaCopy.itemCurricular = itemCurricularDispensa;
                registrosDispensa.push(registroDispensaCopy);
            });

            return registrosDispensa;
        }

        function isSelecionadoTodosComponentesCurriculares() {
            return isSelecionadoOpcaoTodos(vm.registroDispensa.itemCurricular);
        }

        function remove() {
            vm.trackers.save.addPromise(
                RegistroDispensaService
                    .remove(vm.registroDispensa)
                    .then($modalInstance.close)
            );
        }

        function isEjaModular() {
            return _.get(vm.registroDispensa, 'matricula.modular');
        }

        function isTurmaAtividade() {
            var tipoMatricula = _.get(vm.registroDispensa, 'matricula.tipoMatricula');
            return tipoMatricula === enumTipoMatricula.ATIVIDADE_COMPLEMENTAR.value || tipoMatricula === enumTipoMatricula.AEE.value;
        }

        function searchPeriodos() {
            if (!_.get(vm.registroDispensa, 'matricula')) {
                return;
            }
            if (!_.isEmpty(vm.periodosAvaliativos)) {
                return getPeriodosPromise();
            }
            if (isTurmaAtividade() || isEjaModular()) {
                vm.periodosAvaliativos = addTodosInList([]);
                vm.registroDispensa.periodosAvaliativos[0] = vm.periodosAvaliativos[0];
                return getPeriodosPromise();
            }
            return MatriculaService.getPeriodosAvaliativosByMatricula(vm.registroDispensa.matricula.id).then(setContent);
        }

        function filterPeriodosToSelect() {
            if (_.some(vm.registroDispensa.periodosAvaliativos, {id: 'TODOS'})) {
                return getObjectToSelect([]);
            }

            if (vm.contentPeriodosAvaliativos.length - 1 === vm.registroDispensa.periodosAvaliativos.length) {
                return getObjectToSelect(addTodosInList([]));
            }

            if (isSelecionadoTodosComponentesCurriculares()) {
                _.remove(vm.periodosAvaliativos, function (item) {
                    return item.id === 'TODOS';
                });
            }
            return getObjectToSelect(addTodosInList(vm.periodosAvaliativos));
        }

        function getPeriodosPromise() {
            return $q(function (resolve) {
                resolve(filterPeriodosToSelect());
            });
        }

        function getObjectToSelect(content) {
            return {
                content: content,
                hasNext: false
            };
        }

        function setContent(content) {
            vm.contentPeriodosAvaliativos = _.cloneDeep(content);
            if (isSelecionadoTodosComponentesCurriculares()) {
                vm.periodosAvaliativos = content;
            } else {
                vm.periodosAvaliativos = addTodosInList(content || []);
            }

            return filterPeriodosToSelect();
        }

        function addTodosInList(list) {
            _.remove(list, function (item) {
                return item.id === 'TODOS';
            });
            list.unshift({id: 'TODOS', descricao: 'Todos'});
            return list;
        }
    }
})();

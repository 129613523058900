(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectTipoDocumento', SelectTipoDocumento);

    function SelectTipoDocumento() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                lista: '=?'
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.matricula.TipoDocumentoService'];

    function Controller($scope, $modal, service) {

        var vm = this;

        vm.search = search;
        vm.add = add;

        function search(params) {
            var filter = '(descricao like "' + params.term + '"';

            if ($scope.multiple) {
                filter += ignoreId($scope.ngModel);
            }
            if ($scope.lista) {
                filter += ignoreId($scope.lista());
            }

            filter += ')';
            return service.list(filter, params.offset, params.limit);
        }

        function ignoreId(list) {
            var filter = '';
            var tiposDocumentos = [];
            if (list) {
                list.forEach(function(documento) {
                    if (_.get(documento, 'id')) {
                        tiposDocumentos.push(documento.id);
                    }
                });
            }
            if (tiposDocumentos.length) {
                filter += ' and id not in(' + tiposDocumentos.join() + ')';
            }
            return filter;
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'matricula/tipo-documento/tipo-documento-cad.html',
                controller: 'educacao.matricula.TipoDocumentoCadCtrl',
                controllerAs: 'vm',
                size: '',
                params: {
                    tipoDocumento: {
                        descricao: descricao
                    }
                }
            }).result;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.inep')
        .directive('appSelectOrgaoEmissorInep', directive);

    directive.$inject = ['Restangular'];

    function directive(Restangular) {
        return {
            template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" search="vm.search" format-result="formatResult(data)"></ui-select>',
            restrict: 'E',
            scope: {
                ngModel: '=',
                ngDisabled: '=',
                formatResult: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };

        function Controller() {
            var vm = this;

            vm.search = search;

            function search(params) {
                var filter = '(nome like "' + params.term + '")';
                return Restangular.one('inep', 'orgao-emissor-inep').get({
                    filter: filter,
                    offset: params.offset,
                    limit: params.limit
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.MotivoMovimentacaoService', Service);

    Service.$inject = ['ServiceBuilder'];
    function Service(ServiceBuilder) {
        return ServiceBuilder.create()
            .path('matricula', 'motivos-movimentacoes')
            .enum('TipoMovimentacao')
            .build();
    }
})();

(function () {
    'use strict';

    angular.module('educacao.context')
        .config(configureSystemRegion)
        .config(configureBfcContextInitializer);

    configureSystemRegion.$inject = ['CONTEXT_REGION', 'bfcApplicationContextConfigurerProvider'];
    function configureSystemRegion(CONTEXT_REGION, bfcApplicationContextConfigurerProvider) {
        bfcApplicationContextConfigurerProvider.setRegion(CONTEXT_REGION);
    }

    configureBfcContextInitializer.$inject = ['CONTEXT_ENTIDADE', 'CONTEXT_ANO_LETIVO', 'CONTEXT_ESTABELECIMENTO', 'bfc.initializerProvider'];
    function configureBfcContextInitializer(CONTEXT_ENTIDADE, CONTEXT_ANO_LETIVO, CONTEXT_ESTABELECIMENTO, bfcInitializerProvider) {

        var hints = [
            {
                value: CONTEXT_ENTIDADE,
                accepts: [{
                    key: CONTEXT_ENTIDADE
                }]
            }, {
                value: CONTEXT_ANO_LETIVO,
                accepts: [{
                    key: CONTEXT_ANO_LETIVO
                }]
            }, {
                value: CONTEXT_ESTABELECIMENTO,
                accepts: [{
                    key: CONTEXT_ESTABELECIMENTO
                }]

            }];

        bfcInitializerProvider.config({
            hints: hints,
            state: {
                value: 'selecao',
                isAlias: false
            }
        });
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matriz')
        .controller('educacao.matriz.CursoCadCtrl', CursoCadCtrl);

    CursoCadCtrl.$inject = [
        'bfc.Notification',
        'educacao.matriz.CursoService',
        'educacao.common.CommonSelectEnum',
        'educacao.matriz.OrganizacaoNivelSelect',
        '$modalTracker',
        '$q',
        'promiseTracker',
        '$rootScope',
        '$modalInstance',
        'bfc.Focus',
        '$scope'

    ];

    function CursoCadCtrl(Notification, CursoService, CommonSelectEnum, OrganizacaoNivelSelect, $modalTracker, $q,
                          promiseTracker, $rootScope, $modalInstance, focus, $scope) {

        var vm = this,
            NIVEL_MODALIDADE_COMPLEMENTAR = 'COMPLEMENTAR';

        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.tracker.camposAdicionais = promiseTracker();

        vm.opcoes = [];
        vm.cancel = fechaModal;
        vm.save = save;
        vm.remove = remove;
        vm.isOrganizacaoEspecifica = _isOrganizacaoEspecifica;
        vm.changeModalidade = _changeModalidade;
        vm.canSaveAndAdd = $modalInstance.params.canSaveAdd === undefined ? true : $modalInstance.params.canSaveAdd;
        vm.curso = $modalInstance.params.curso || {};
        vm.editando = _.get($modalInstance.params.curso, 'id');
        vm.situacaoAtivo = $modalInstance.params.situacaoAtivo;
        vm.windowTitle = vm.editando ? 'Editando Curso' : 'Adicionando Curso';

        init();
        initCamposAdicionais();

        function formatResultNivelEscolar(data) {
            return data.description;
        }

        function formatValueNivelEscolar(data) {
            return data.key;
        }

        function init() {
            var defered = $q.defer();
            vm.optionsPromisse = defered.promise;
            vm.optionsPromisseNivelModalidade = defered.promise;
            vm.optionsPromisseSituacaoCadastral = defered.promise;
            $modalTracker.addPromise(
                $q.all([
                    CursoService.getTypes().then(function (data) {
                        vm.cursoProperties = data.CursoDto;
                    }),
                    CursoService.getEnums().then(function (enums) {

                        vm.nivelEscolarOptions = enums.NivelEscolar;

                        vm.nivelModalidadeOptions = enums.NivelModalidade;
                        
                        vm.situacaoCadastralOptions = enums.SituacaoCadastral;

                        vm.opcoes.NivelModalidadeSelect = CommonSelectEnum.select2Config({
                            values: enums.NivelModalidade,
                            description: 'description'
                        });

                        vm.opcoes.SituacaoCadastralSelect = CommonSelectEnum.select2Config({
                            values: enums.SituacaoCadastral,
                            description: 'description'
                        });

                        defered.resolve(convertEnum(enums.NivelEscolar));

                    }),
                    refresh()
                ]).then(function () {
                    if (vm.editando) {
                        vm.habilitarNivelEscolar = !_isNivelModalidadeComplementar(vm.curso);
                        _isFiltraEnum(vm.curso.nivelModalidade);
                    }
                    filtraEnum();
                    vm.formatResultNivelEscolar = formatResultNivelEscolar;
                    vm.formatValueNivelEscolar = formatValueNivelEscolar;
                })
            );
            vm.opcoes.OrganizacaoNivelSelect = OrganizacaoNivelSelect.select2Config();

            if (vm.situacaoAtivo) {
                vm.curso.situacaoCadastral = 'ATIVO';
            }
        }

        function save(continuar) {
            vm.curso.campoAdicional = vm.campoAdicional;
            return CursoService.save(vm.curso)
                .then(CursoService.emitDirty)
                .then(function (data) {
                    showSucessMessage(data.descricao);
                    vm.curso = data;
                    onSuccess(continuar);

                });
        }

        function showSucessMessage(descricao) {
            return Notification.publish('Curso ' + descricao + ' salvo com sucesso.', 'success');
        }


        function remove() {
            return CursoService.remove(vm.curso.id)
                .then(CursoService.emitDirty)
                .then(fechaModal);
        }

        function onSuccess(continuar) {
            if (continuar) {
                refresh();
                vm.curso = {};
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.curso);
        }


        function refresh() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }

            return Boolean(vm.editando) ?
                CursoService.get(vm.editando).then(function (data) {
                    vm.curso = data;
                }) :
                CursoService.getDefault().then(function () {
                    // vm.curso = angular.extend(data, optionsCad);
                    vm.habilitarNivelEscolar = true;
                });
        }

        function convertEnum(niveis) {
            if (vm.filtraNivelEscolar) {
                niveis = _filtraNivel(niveis);
            }
            var nivelEscolarEnum = {};
            nivelEscolarEnum.values = [];
            angular.forEach(niveis, function (value) {
                var nivelEscolar = {
                    'id': value.key,
                    'key': value.key,
                    'description': value.description
                };
                nivelEscolarEnum[value.key] = nivelEscolar;
                nivelEscolarEnum.values.push(nivelEscolar);
            });
            return nivelEscolarEnum;
        }

        function convertEnumWithoutFiltrar(niveis) {
            var ENUM_VALUES = {};
            ENUM_VALUES.values = [];
            angular.forEach(niveis, function (value) {
                var nivelEscolar = {
                    'id': value.key,
                    'key': value.key,
                    'description': value.description
                };
                ENUM_VALUES[value.key] = nivelEscolar;
                ENUM_VALUES.values.push(nivelEscolar);
            });
            return ENUM_VALUES;
        }

        function filtraEnum() {
            var nivelEscolarEnum = convertEnum(vm.nivelEscolarOptions);
            vm.optionsPromisse = $q.when(nivelEscolarEnum);

            var NivelModalidadeEnum = convertEnumWithoutFiltrar(vm.nivelModalidadeOptions);
            vm.optionsPromisseNivelModalidade = $q.when(NivelModalidadeEnum);

            var SituacaoCadastralEnum = convertEnumWithoutFiltrar(vm.situacaoCadastralOptions);
            vm.optionsPromisseSituacaoCadastral = $q.when(SituacaoCadastralEnum);
        }

        /*Retira da lista o ensino fundamental infantil*/
        function _filtraNivel(nivel) {
            return _.filter(nivel, function (item) {
                return item.value !== 1;
            });

        }

        function _isFiltraEnum(modalidade) {
            vm.filtraNivelEscolar = modalidade === 'EJA' || modalidade === 'ACELERACAO';
        }

        function _changeModalidade(modalidade) {
            vm.filtraNivelEscolar = modalidade === 'EJA' || modalidade === 'ACELERACAO';
            if (vm.filtraNivelEscolar) {
                vm.curso.nivelEscolar = vm.curso.nivelEscolar === 'EDUCACAO_INFANTIL' ? '' : vm.curso.nivelEscolar;
            }

            vm.habilitarNivelEscolar = !_isNivelModalidadeComplementar(vm.curso);
            filtraEnum();
        }

        function _isOrganizacaoEspecifica() {
            var item = $('#organizacao').select2('data');
            return item !== null && item !== undefined && item.nomenclaturaPadrao === false;
        }

        function _isNivelModalidadeComplementar(cursoEntity) {
            return cursoEntity && cursoEntity.nivelModalidade === NIVEL_MODALIDADE_COMPLEMENTAR;
        }

        function initCamposAdicionais() {

            var cursoId = _.get(vm.curso, 'id');

            var promise = CursoService.camposAdicionais(cursoId)
                .then(function (data) {
                    vm.campoAdicional = data;
                });

            vm.tracker.loading.addPromise(promise);

            vm.tracker.camposAdicionais.addPromise(promise);

        }
    }
})();

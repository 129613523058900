(function() {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.chaService', Service);

    Service.$inject = ['$injector'];

    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('matriz', 'cha')
            .enum('TipoCompetenciaCHA')
            .build();

        selfService.getChaAgrupadoDisciplina = getChaAgrupadoDisciplina;
        selfService.getTodosChaDisciplina = getTodosChaDisciplina;
        selfService.getDescricoesRecentes = getDescricoesRecentes;

        return selfService;

        function getChaAgrupadoDisciplina(filter, etapaMatrizDisciplinaId) {
            return selfService.getEndpoint().one('chas-agrupados').get({
                filter: filter,
                etapaMatrizDisciplinaId: etapaMatrizDisciplinaId
            });
        }

        function getTodosChaDisciplina(etapaMatrizDisciplinaId) {
            return selfService.getEndpoint().one('chas-matriz').get({
                etapaMatrizDisciplinaId: etapaMatrizDisciplinaId
            });
        }

        function getDescricoesRecentes() {
            return selfService.getEndpoint().get('descricoes-recentes');
        }
    }
})();


(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.EixoTematico', Controller);

    Controller.$inject = ['$injector',
        '$rootScope',
        '$scope',
        'promiseTracker',
        '$q',
        '$modalStates'
    ];

    function Controller($injector, $rootScope, $scope, promiseTracker, $q, $modalStates) {
        var vm = this;
        var EixoTematicoService = $injector.get('educacao.matriz.EixoTematicoService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService');
        vm.tracker = {};
        vm.openEixoTematico = openEixoTematico;
        vm.tracker.loading = promiseTracker();
        vm.removeEixoTematico = removeEixoTematico;
        vm.isSearching = isSearching;
        vm.orderBy = orderBy;
        vm.getIconSortClass = getIconSortClass;

        function init() {
            vm.tracker.loading.addPromise(
                $q.all([
                    EixoTematicoService.getList().then(
                        function(data) {
                            vm.eixosTematicos = data;
                        }
                    )
                ])
            );
        }

        init();

        function orderBy(item) {
            if (typeof vm.predicate === 'undefined') {
                vm.predicate = 'descricao';
            }

            return ObjectUtilsService.normalizeFilter(item[vm.predicate]);
        }

        function getIconSortClass(sortBy) {
            return {
                'icon-caret-down': vm.reverse,
                'icon-caret-up': !vm.reverse,
                'icon-sort': vm.predicate !== sortBy
            };
        }

        function isSearching() {
            return Boolean(vm.search && (vm.search.filter || vm.search.value));
        }

        $scope.$on('refreshEixosTematicos',
            function(events, args) {
                if (args.data) {
                    if (args.remove) {
                        vm.eixosTematicos = vm.eixosTematicos.filter(function(eixo) {
                            if (eixo.id !== args.data.id) {
                                return eixo;
                            }
                        });

                    } else if (args.add) {
                        vm.eixosTematicos.push(args.data);
                    } else if (args.undo || args.edit) {
                        var eixo = _.find(vm.eixosTematicos, {
                            'id': args.data.id
                        });
                        if (eixo) {
                            ObjectUtilsService.copyProperties(eixo, args.data);
                        } else {
                            throw new Error('Eixo temático não encontrado! ID: ' + args.data.id);
                        }
                    }
                }

            });

        function removeEixoTematico(eixo) {
            EixoTematicoService.remove(eixo.id).then(function() {
                $rootScope.$broadcast('refresh::eixo', {
                    data: eixo,
                    remove: true
                });
                init();
            });
        }

        function openEixoTematico(eixo) {
            $modalStates.goModalState(eixo);
        }
    }
})();

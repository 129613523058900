(function() {
    'use strict';

    angular.module('educacao.cursoaperfeicoamento')
        .config(states);

    states.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function states($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.cursoaperfeicoamento';
        $stateProvider
            .state(STATE, {
                data: {
                    id: 'CursoAperfeicoamentoPageMapping'
                },
                url: 'curso-aperfeicoamento',
                views: {
                    '@': {
                        templateUrl: 'curso-aperfeicoamento/listagem-curso-aperfeicoamento.html',
                        controller: 'educacao.cursoaperfeicoamento.CursoAperfeicoamentoCtrl as vm'
                    }
                }
            }).state(STATE + STATES_ADICIONAR.state, {
                url: STATES_ADICIONAR.url,
                onEnter: openModal,
                data: {
                    isModalState: true
                }
            }).state(STATE + STATES_EDITAR.state, {
                url: STATES_EDITAR.url,
                onEnter: openModal,
                data: {
                    isModalState: true
                }
            });
    
            openModal.$inject = [
                '$stateParams',
                'educacao.cursoaperfeicoamento.CursoAperfeicoamentoService',
                '$modalStates'
            ];
    
            function openModal($stateParams, CursoAperfeicoamentoService, $modalStates) {
                $modalStates.open({
                    getMethod: CursoAperfeicoamentoService.get,
                    templateUrl: 'curso-aperfeicoamento/cadastro-curso-aperfeicoamento.html',
                    controller: 'educacao.CursoAperfeicoamentoCadastroCtrl as vm',
                    propertyName: 'curso',
                    size: 'lg',
                    stateParams: $stateParams
                });
            }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioEventoService', CalendarioEventoService);

    CalendarioEventoService.$inject = ['educacao.common.MetadataServiceHelper', 'Restangular'];

    function CalendarioEventoService(MetadataServiceHelper, restangular) {

        var metadataHelper = MetadataServiceHelper.create(getBasePoint());

        return {
            getDefaultRepresentation: metadataHelper.getDefaultRepresentation,
            getCoresEvento: metadataHelper.enumValues('CorEvento'),
            getPublicoAlvo: metadataHelper.enumValues('PublicoAlvo'),
            listEventosAceite: listEventosAceite,
            save: save,
            remove: remove,
            recusar: recusar,
            saveList: saveList
        };

        function getBasePoint(calendario) {
            var id = calendario ? calendario.id.toString() : '0';

            return restangular
                .one('calendario', id)
                .all('eventos');
        }

        function save(calendario, evento) {
            var endPoint = getBasePoint(calendario);
            return evento.id ? endPoint.customPUT(evento, evento.id) : endPoint.post(evento);
        }

        function saveList(calendario, eventos) {
            return getBasePoint(calendario).all('lote').post({calendarioEventos: eventos});
        }

        function remove(calendario, evento) {
            return getBasePoint(calendario)
                .customDELETE(evento.id);
        }

        function listEventosAceite(calendario) {
            return getBasePoint(calendario).all('aceite').getList();
        }

        function recusar(calendario, evento) {
            return getBasePoint(calendario).all('recusar').one(evento.id.toString()).put();
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .directive('appDetalheRegistrosVinculados', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                value: '=',
                calendario: '='
            },
            templateUrl: 'calendario/calendario-matriz/registros-vinculados/detalhe-registro-vinculado/app-detalhe-registros-vinculados.directive.html',
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.calendario.RegistrosVinculadosService', 'promiseTracker'];

    function Controller(RegistrosVinculadosService, promiseTracker) {
        var vm = this;

        vm.openDetail = openDetail;

        vm.loading = promiseTracker();

        function openDetail(idTurma) {
            vm.open = !vm.open;
            if (vm.open) {
                vm.loading.addPromise(RegistrosVinculadosService.getTotalizadoresTurma(vm.calendario, idTurma).then(function(data) {
                    vm.totalizadores = data;
                }));
            }
        }
    }
})();

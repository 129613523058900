(function() {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectAee', SelectAEEDirective);

    function SelectAEEDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchAaee" add-value="vm.add" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            controller: SelectAAEEDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectAAEEDirectiveController.$inject = ['$scope', 'ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.matricula.AtividadeAEEService'];

    function SelectAAEEDirectiveController($scope, $modal, PermissionsService, service) {

        var vm = this;

        vm.formatResult = formatResult;
        vm.searchAaee = searchAaee;
        vm.add = !PermissionsService.isRevokedOperation('AtividadeAeePageMapping', 'criar') && add;

        function searchAaee(params) {
            var filter = '(descricao like "' + params.term + '"';
            if ($scope.multiple) {
                var atividades = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(atividade) {
                        atividades.push(atividade.id);
                    });
                }
                if (atividades.length) {
                    filter += ' and id not in(' + atividades.join() + ')';
                }
            }
            filter += ')';

            return service.getList(params.offset, params.limit, filter, params.sort).then(function(data) {
                return {
                    content: data,
                    hasNext: false
                };

            });
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'matricula/aaee/aaee.cad.html',
                controller: 'educacao.matricula.AeeCadCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    atividadeAAEE: {
                        descricao: descricao
                    }
                }
            }).result;
        }

        function formatResult(data) {
            return data.descricao;
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.common').service('educacao.common.AreaFormacaoPosGraduacaoInep', Service);

    Service.$inject = ['Restangular'];

    function Service(Restangular) {
        return {
            getList: getList
        };


        function getList(offset, limit, filter, sort) {
            var params = {
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            };

            return Restangular.all('inep/area-formacao-pos-graduacao-inep').get('', params);
        }
    }

})();
(function () {
    'use strict';

    var STATUS_LOTE = {
        PROCESSED: 'PROCESSED',
        ERROR: 'ERROR',
        QUEUE: 'QUEUE'
    };

    var PERCENTUAL_SCORE_MINIMO = 0.65;

    angular.module('educacao.configuracao-evasao-reprovacao')
        .service('$configuracaoEvasaoReprovacaoUtilsService', ConfiguracaoEvasaoReprovacaoUtilsService);

    ConfiguracaoEvasaoReprovacaoUtilsService.$inject = [];

    function ConfiguracaoEvasaoReprovacaoUtilsService() {
        var self = this;

        self.isProcessed = isProcessed;
        self.isQueue = isQueue;
        self.getPercentualFScore = getPercentualFScore;
        self.isResultEnabled = isResultEnabled;

        function isProcessed(lote) {
            return _.get(lote, 'statusTreinamento') === STATUS_LOTE.PROCESSED;
        }

        function isQueue(lote) {
            return _.get(lote, 'statusTreinamento') === STATUS_LOTE.QUEUE;
        }

        function getPercentualFScore(resultados) {
            return {
                percentualEvasao: resultados.evasao.f1Score * 100,
                percentualReprovacao: resultados.reprovacao.f1Score * 100
            };
        }

        function isResultEnabled(resultado) {
            return resultado.f1Score > PERCENTUAL_SCORE_MINIMO;
        }
    }

})();
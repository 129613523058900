(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios').directive('appSelectComponentesCurriculares', SelectComponentesCurricularesDirective);

    SelectComponentesCurricularesDirective.$inject = [];
    function SelectComponentesCurricularesDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" search="vm.search" />',
            scope: {
                ngModel: '=',
                options: '='
            },
            controller: SelectComponentesCurricularesDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectComponentesCurricularesDirectiveController.$inject = ['$scope', '$q'];
    function SelectComponentesCurricularesDirectiveController($scope, $q) {

        var vm = this;

        vm.search = search;

        function search(params) {
            var term = _.trim(params.term, '%');
            return $q.when({
                hasNext: false,
                content: $scope.options.filter(function (item) {
                    return !term.length || item.descricao.toLowerCase().search(term.toLowerCase()) !== -1;
                })
            });
        }
    }
})();

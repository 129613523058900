(function () {
    'use strict';

    angular
        .module('educacao.calendario')
        .directive('periodoAvaliativoRow', directive);

    directive.$inject = [];
    function directive() {
        return {
            restrict: 'E',

            scope: {
                numero: '=',
                periodo: '=',
                descricao: '=',
                calendarioDisplay: '='
            },

            templateUrl: 'calendario/calendario-matriz/periodo-avaliativo-row.directive.html',
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    var defaultRenderConfig = {
        showYearSeparator: false,
        showLegend: false,
        showMonthWorkdayCount: true,
        showTotalWorkdayCount: false,
        showHolydaysControls: false,
        showEventsControls: false,
        monthPerRow: 3
    };

    Controller.$inject = ['DATE_FORMAT', 'MINI_MONTH_DAY_CLICKED_EVENT', '$scope', 'IdGenerator'];

    function Controller(DATE_FORMAT, MINI_MONTH_DAY_CLICKED_EVENT, $scope, IdGenerator) {
        var vm = this;

        var momentDataInicial = moment($scope.periodo.dataInicial);
        var momentDataFinal = moment($scope.periodo.dataFinal);

        vm.calendarioDisplay = $scope.calendarioDisplay
            .clone()
            .setBaseCalendar($scope.calendarioDisplay.baseCalendar)
            .addCalendario($scope.calendarioDisplay.baseCalendar)
            .addPeriodosLetivos([$scope.periodo])
            .addFiltroEventos(filtraEventoPeriodosAvaliativos)
            .process();

        vm.renderConfig = angular.extend({
            restrictInitialDate: $scope.periodo.dataInicial,
            restrictFinalDate: $scope.periodo.dataFinal
        }, defaultRenderConfig);

        $scope.$on(MINI_MONTH_DAY_CLICKED_EVENT, onMiniCalendarDayClicked);

        function onMiniCalendarDayClicked(event, dayInfo) {
            var momentDate = moment(dayInfo.date, DATE_FORMAT);

            if (dayInfo.hasEvents || dayInfo.hasHolyday || !vm.calendarioDisplay.isBetween(momentDate, $scope.periodo.dataInicial, $scope.periodo.dataFinal)) {
                return;
            }

            var excecoes = vm.calendarioDisplay.baseCalendar.excecoes;
            var isLetivo = vm.calendarioDisplay.isDiaLetivo(momentDate);

            var excecao = _.find(excecoes, function (ex) {
                return ex.data === dayInfo.date;
            });

            if (excecao) {
                IdGenerator.remove(excecao, excecoes);
            }

            if (!excecao) {
                excecao = {
                    data: dayInfo.date,
                    letivo: !isLetivo
                };
                IdGenerator.addOrUpdate(excecao, excecoes);
            }
            vm.calendarioDisplay.process();
        }

        function filtraEventoPeriodosAvaliativos(evento) {
            return !momentDataInicial.isAfter(evento.dataInicial, 'days') && !momentDataFinal.isBefore(evento.dataInicial, 'days') ||
                !momentDataInicial.isAfter(evento.dataFinal, 'days') && !momentDataFinal.isBefore(evento.dataFinal, 'days');
        }
    }
})();
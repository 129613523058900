(function () {
    'use strict';


    angular.module('educacao.common')
        .directive('appSelectDisciplinaInep', SelectDisciplinaInep);

    function SelectDisciplinaInep() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function ($scope) {
                        $scope.multiple = multiple;
                    }
                };
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.common.SelectDisciplinaInepService', '$scope'];

    function Controller(service, $scope) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = '( descricao like "' + params.term + '"';

            if (!_.isEmpty($scope.ngModel)) {
                var idsFilter = _.chain($scope.ngModel).map('id').join().value();
                filter += ' and id not in( '+ idsFilter +' )';
            }

            filter += ')';

            return service.list(filter, params.offset, params.limit).then(function(data) {
                return {
                    content: data,
                    hasNext: false
                };

            });

        }

        function formatResult(data) {
            return data.descricao;
        }
    }
})();


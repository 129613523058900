(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .controller('educacao.geracaoHorarios.GeracaoHorariosHistoricoPopoverController', GeracaoHorariosHistoricoPopoverController);

    GeracaoHorariosHistoricoPopoverController.$inject = ['educacao.common.$commons', '$popoverInstance',
        'educacao.geracaoHorarios.GeracaoHorariosService'];
    function GeracaoHorariosHistoricoPopoverController($commons, $popoverInstance, GeracaoHorariosService) {

        var vm = this;

        vm.configuracaoHorasAulas = $popoverInstance.params.configuracaoHorasAulas;

        vm.openHistoricoModal = openHistoricoModal;

        init();

        function init() {
            var promise = GeracaoHorariosService.getHistoricos(vm.configuracaoHorasAulas.id, {
                offset: 0,
                limit: 3
            }).then(function (page) {
                vm.historicos = page.content;

                $commons.$timeout(function () {
                    $popoverInstance.position();
                });
            });

            $popoverInstance.tracker.addPromise(promise);
        }

        function openHistoricoModal() {
            $commons.$modal.open({
                templateUrl: 'historico/historico-modal.html',
                controller: 'educacao.historico.HistoricoModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    title: 'Histórico da geração de horários',
                    url: 'matricula/horas-aula/' + vm.configuracaoHorasAulas.id + '/historicos'
                }
            });
        }

    }
})();
(function () {

    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.RegistroDispensaCtrl', RegistroDispensaCtrl);

    RegistroDispensaCtrl.$inject = [
        '$modalStates',
        'educacao.matricula.RegistroDispensaService',
        '$rootScope'
    ];

    function RegistroDispensaCtrl($modalStates, RegistroDispensaService, $rootScope) {

        var vm = this;

        vm.openModal = openModal;
        vm.remove = remove;
        vm.listControls = {};
        $rootScope.$watch('registroDispensa', watchRegistro);
        vm.RegistroDispensaService = RegistroDispensaService;

        RegistroDispensaService.getTipoMatricula().then(function (data) {
            vm.enumTipoMatricula = data;
        });

        function openModal(registroDispensa) {
            $modalStates.goModalState(registroDispensa);
        }

        function remove(registroDispensa) {
            RegistroDispensaService.remove(registroDispensa);
        }

        function watchRegistro() {
            if (_.get($rootScope.registroDispensa,'[0]')) {
                refresh();
            }
        }

        function refresh() {
            if(_.get(vm.listControls,'search')) {
                return vm.listControls.search();
            }
        }

    }
})();

(function() {
    'use strict';

    angular.module('educacao.agendaprofessor').factory('educacao.agendaprofessor.DisponibilidadeService', DisponibilidadeService);

    DisponibilidadeService.$inject = ['$injector'];

    function DisponibilidadeService($injector) {

        var GenericService = $injector.get('GenericService');

        return GenericService
           .create()
           .module({
               uri: 'calendario'
           })
           .service({
               uri: 'disponibilidade'
           });

    }

})();

(function() {
    'use strict';

    angular.module('educacao.relatorio')
        .factory('educacao.relatorio.RelatorioService', RelatorioService);

    RelatorioService.$inject = ['Restangular', 'ui.components.EventEmitter'];

    function RelatorioService(Restangular, EventEmitter) {

        var basePath = 'relatorio';

        var self = EventEmitter.install({
            list: list,
            reenviar: reenviar
        });

        return self;

        function list(params) {
            return Restangular.one(basePath).get(params).then(function(data) {
                return data.plain();
            });
        }

        function reenviar(relatorio){
            return Restangular.one('matricula/boletim/reenviar', relatorio.id).customPUT();
        }
    }
})();

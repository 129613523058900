(function() {

    'use strict';

    angular.module('educacao.encerramento')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.encerramento', {
            url: 'encerramento',
            data: {
                id: 'EncerramentoPeriodoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'encerramento/listagem-encerramento/listagem-encerramento.html',
                    controller: 'educacao.encerramento.EncerramentoController as vm'
                }
            }
        });
    }

})();


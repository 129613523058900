(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .factory('educacao.geracaoHorarios.GeracaoHorariosService', GeracaoHorariosService);

    GeracaoHorariosService.$inject = ['Restangular', 'MetadataCache', 'ui.components.EventEmitter'];

    function GeracaoHorariosService(Restangular, MetadataCache, EventEmitter) {
        var service = new EventEmitter();

        var PATH = 'quadros-horarios';

        var endPointOne = Restangular.one(PATH);

        service.list = list;
        service.getGradeHorarios = getGradeHorarios;
        service.getGradeHorariosVigente = getGradeHorariosVigente;
        service.getDiasDaSemana = getDiasDaSemana;
        service.adicionarAula = adicionarAula;
        service.removerAula = removerAula;
        service.getHistoricos = getHistoricos;
        service.save = save;
        service.postOrPut = postOrPut;
        service.copy = copy;
        service.remove = remove;
        service.get = get;

        return service;

        function list(params) {
            return endPointOne.get(params);
        }

        function save(entity) {
            return postOrPut(entity).then(emitDirty);
        }

        function postOrPut(entity) {
            var id = entity.id;
            var promise = Restangular.all(PATH);

            if (id) {
                return promise.customPUT(entity, id);
            }

            return promise.post(entity);
        }

        function copy(id, idCopy) {
            return Restangular.one(PATH, id).one('copia', idCopy).post().then(emitDirty);
        }

        function remove(entity) {
            return Restangular.all(PATH).customDELETE(entity.id.toString()).then(emitDirty);
        }

        function get(id) {
            return Restangular.one(PATH, id).get().then(Restangular.stripRestangular);
        }

        function getGradeHorarios(idGrade, params) {
            params = params || {
                limit: 999,
                offset: 0
            };
            return Restangular.one(PATH, idGrade).customGET('recuperar', params);
        }

        function getGradeHorariosVigente(idGrade, params) {
            params = params || {
                limit: 999,
                offset: 0
            };
            return Restangular.one(PATH, idGrade).customGET('vigente', params);
        }

        function adicionarAula(aula) {
            return aula.id ? endPointOne.one('aulas').customPUT(aula, aula.id) :
                endPointOne.one('aulas').customPOST(aula).then(emitDirty);
        }

        function removerAula(aula) {
            return Restangular.all(PATH).all('aulas').customDELETE(aula.id.toString())
                .then(emitDirty);
        }

        function emitDirty(value) {
            service.emit('dirty');
            return value;
        }

        function getDiasDaSemana() {
            return MetadataCache.create(function() {
                return Restangular.all(PATH + '/aulas');
            }).getEnum('DiaDaSemanaDto');
        }

        function getHistoricos(configuracaoHorasAulasId, params) {
            return Restangular.all('matricula')
                .one('horas-aula', configuracaoHorasAulasId)
                .all('historicos').get('', params || {});
        }
    }
})
();

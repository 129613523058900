(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appCardTurma', CardTurmaDirective);

	function CardTurmaDirective() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/turma/app-card-turma/app-card-turma.directive.html',
			scope: {
				ngModel: '=',
				canExibNumeroChamada: '=',
				canEditEnturmacoes: '=',
				selectable: '=',
				index: '=',
				selectAction: '&onSelect',
				editAction: '&onEdit',
				removeAction: '&onRemove',
				desenturmarAction: '&onDesenturmar',
				somenteAtivos: '=',
				initAlunoExpanded: '=',
				naListagem: '='
			},
			link: function($scope, $element, $attrs) {

				//Remove a action caso o usuário não tenha passado nenhuma função
				//https://github.com/angular/angular.js/issues/6404
				if (!$attrs.onEdit) {
					delete $scope.editAction;
				}

				if (!$attrs.onRemove) {
					delete $scope.removeAction;
				}

				if (!$attrs.onDesenturmar) {
					delete $scope.desenturmarAction;
				}
			},
			controller: CardTurmaDirectiveController,
			controllerAs: 'vm'
		};
	}

	CardTurmaDirectiveController.$inject = [
		'$enumsCache',
		'$scope',
		'promiseTracker', 
		'educacao.matricula.TurmaService',
		'educacao.common.ItemEducacionalService',
		'TURNO',
		'ui.components.Modal',
		'educacao.common.GrupoEnturmacaoService',
		'educacao.funcionario.FuncionarioService',
		'bfc.$$PermissionsService'
	];

	function CardTurmaDirectiveController(
		$enumsCache,
		$scope,
		$promiseTracker,
		TurmaService,
		ItemEducacionalService,
		TURNO,
		Modal,
		GrupoEnturmacaoService,
		FuncionarioService,
		PermissionsService
	) {

		var vm = this;
		var NR_ELEMENTOS_POR_LINHA = 4;

		vm.turma = $scope.ngModel || {};
		vm.hasEditPermission = !PermissionsService.isRevokedOperation('FuncionarioPageMapping', 'editar');
		vm.showGroupsCheck = true;

		vm.openModalFuncionario = openModalFuncionario;
		
		vm.naListagem = $scope.naListagem;
		vm.collapseEnturmacoes = Boolean($scope.initAlunoExpanded);
		vm.checkSomenteAtivos = true;
		vm.enturmacoesCarregadas = angular.isArray(vm.enturmacoesTurnos) || angular.isArray(vm.turma.enturmacoes);
		vm.textButton = ItemEducacionalService.getLabel(vm.turma.tipoAtendimento);

		vm.isAtividade = isAtividade;
		vm.isEjaModular = isEjaModular;
		vm.hasGrupos = false;
		vm.carregarItensTurmaDetalhes = carregarItensTurmaDetalhes;

		vm.carregarTurmasMultisseriada = carregarTurmasMultisseriada;

		vm.agruparAlunos = agruparAlunos;
		vm.changeViewListEnturmacoes = changeViewListEnturmacoes;

		vm.tabelaEnturmacoes = [];

		vm.tracker = {
			enturmacoes: $promiseTracker(),
			professores: $promiseTracker(),
			carregarItensTurmaDetalhes: $promiseTracker(),
			carregarTurmasMultisseriada: $promiseTracker()
		};

		getConfiguracao();

		$scope.$watch('ngModel', function(value) {
			vm.turma = value || {};
		});

		$scope.$watchGroup(['vm.collapseEnturmacoes', 'vm.checkSomenteAtivos'], function(newValues) {
			if (newValues[0]) {
				carregaEnturmacoes(newValues[1]);
			}
		});

		$scope.$watch('vm.collapseProfessores', function(value) {
			if (value) {
				carregaEnturmacoesProfessores();
			}
		});

		$scope.$watch('vm.collapseItens', function(value) {
			if (value) {
				if (isEjaModular()) {
					carregarItensTurmaEjaModular();
				} else {
					carregarItensTurmaDetalhes();
				}
			}
		});

		$scope.$watchCollection('vm.turma.enturmacoes', changeEnturmacoes);
		$scope.$watchCollection('vm.enturmacoesTurnos', changeEnturmacoesTurnos);
		
		function changeEnturmacoes() {
			vm.tabelaEnturmacoes = [];
			vm.tabelaEnturmacoesInativas = [];

			if (!vm.turma.enturmacoes) {
				return;
			}

			// Faz uma copia do array
			var enturmacoes = vm.turma.enturmacoes.concat();

			if (vm.checkSomenteAtivos === false) {
				enturmacoes = getEnturmacoesInativas(enturmacoes);
			}

			enturmacoes = addCardsEnturmacoesVagasDisponiveis(enturmacoes);

			vm.tabelaEnturmacoesAgrupadas = separarEnturmacoesEmGrupos(enturmacoes);
			vm.tabelaEnturmacoes = _.chunk(enturmacoes, NR_ELEMENTOS_POR_LINHA);
		}
		
		function separarEnturmacoesEmGrupos(enturmacoes) {
			var enturmacoesAgrupadas = {
				groupNone: []
			};
			_.forEach(enturmacoes, function(enturmacao) {
				if (_.get(enturmacao, 'grupoEnturmacaoItem.id')) {
					if (!enturmacoesAgrupadas['group' + enturmacao.grupoEnturmacaoItem.id]) {
						enturmacoesAgrupadas['group' + enturmacao.grupoEnturmacaoItem.id] = [];
					}
					enturmacoesAgrupadas['group' + enturmacao.grupoEnturmacaoItem.id].push(enturmacao);
				} else {
					if (!enturmacao.empty) {
						enturmacoesAgrupadas.groupNone.push(enturmacao);
					}
				}
			});
			Object.keys(enturmacoesAgrupadas).forEach(function(item) {
				enturmacoesAgrupadas[item] = _.chunk(enturmacoesAgrupadas[item], NR_ELEMENTOS_POR_LINHA);
			});
			return enturmacoesAgrupadas;
		}

		function changeEnturmacoesTurnos() {
			if (!vm.enturmacoesTurnos) {
				return;
			}

			var qtdEnturmacoesIntegral = _.where(_.get(_.find(vm.enturmacoesTurnos, 'turno', TURNO.INTEGRAL), 'enturmacoes', []), {dataFim: null}).length;
			var enturmacoesNaoIntegrais = _.chain(vm.enturmacoesTurnos)
											.reject('turno', TURNO.INTEGRAL)
											.pluck('enturmacoes');

			var qtdMaxEnturmacoes = 0;
			if(enturmacoesNaoIntegrais.value().length){
				qtdMaxEnturmacoes = enturmacoesNaoIntegrais
				                    .map(filterEnturmacoesAtivas)
									.map('length')
									.max()
									.value();
			}

			vm.tabelaEnturmacoesTurnos = [];
			vm.turma.enturmacoes = [];
			_.forEach(vm.enturmacoesTurnos, function(enturmacaoTurno){
				// Faz uma copia do array
				var enturmacoes = enturmacaoTurno.enturmacoes.concat();
				
				vm.tabelaEnturmacoes = [];
				vm.tabelaEnturmacoesInativas = [];

				_.forEach(enturmacaoTurno.enturmacoes, function(enturmacao){
					vm.turma.enturmacoes.push(enturmacao);
				});

				enturmacaoTurno.totalEnturmacoes = _.where(enturmacaoTurno.enturmacoes, {dataFim: null}).length;

				enturmacaoTurno.totalDisponivel = vm.turma.quantidadeMaximaAlunos - (enturmacaoTurno.totalEnturmacoes + 
					(enturmacaoTurno.turno === TURNO.INTEGRAL ? qtdMaxEnturmacoes : qtdEnturmacoesIntegral));

				if (vm.checkSomenteAtivos === false) {
					enturmacoes = getEnturmacoesInativas(enturmacoes);

					enturmacaoTurno.totalEnturmacoes = enturmacoes.length;
				}

				enturmacoes = addCardsEnturmacoesVagasDisponiveis(enturmacoes);

				vm.tabelaEnturmacoes = _.chunk(enturmacoes, NR_ELEMENTOS_POR_LINHA);

				enturmacaoTurno.enturmacoes = vm.tabelaEnturmacoes;
				enturmacaoTurno.enturmacoesAgrupadas = separarEnturmacoesEmGrupos(enturmacoes);
				vm.tabelaEnturmacoesTurnos.push(enturmacaoTurno);
			});
		}

		$scope.$watchCollection('vm.turma.enturmacoesProfessores', function() {
			if (!vm.turma.enturmacoesProfessores) {
				return;
			}
			vm.tabelaEnturmacoesProfessores = [];
			var temp = vm.turma.enturmacoesProfessores.concat();
			vm.tabelaEnturmacoesProfessores = _.chunk(temp, NR_ELEMENTOS_POR_LINHA);
		});

		function filterEnturmacoesAtivas(enturmacao){
		    return  _.filter(enturmacao, {dataFim: null});
		}


		function isAtividade(tipoAtendimento) {
			return ItemEducacionalService.isAtividade(tipoAtendimento);
		}

		function isEjaModular() {
			return ItemEducacionalService.isEjaModular(vm.turma);
		}

		function addCardsEnturmacoesVagasDisponiveis(enturmacoes){
			if ($scope.canEditEnturmacoes) {
				var diffLength = vm.turma.quantidadeMaximaAlunos - enturmacoes.length;
				for (var i = 0; i < diffLength; i++) {
					enturmacoes.push({
						id: 'empty-' + i,
						empty: true
					});
				}
			}

			return enturmacoes;
		}

		function getEnturmacoesInativas(enturmacoes){
			vm.tabelaEnturmacoesInativas = _.filter(vm.turma.enturmacoes, function(i) {
				return i.dataFim;
			});

			enturmacoes = _.difference(enturmacoes, vm.tabelaEnturmacoesInativas);
			vm.tabelaEnturmacoesInativas = _.chunk(vm.tabelaEnturmacoesInativas, NR_ELEMENTOS_POR_LINHA);

			return enturmacoes;
		}

		function carregarItensTurmaDetalhes(somenteAtivos) {
			var promise = TurmaService.getItensTurmaDetalhes(vm.turma.id, somenteAtivos)
				.then(function(dados) {
					vm.listaItensDetalhes = dados;
				});

			vm.tracker.carregarItensTurmaDetalhes.addPromise(promise);
		}

		function carregarTurmasMultisseriada() {
			var promise = TurmaService.getTurmaMultisseriada(vm.turma.id)
				.then(function(dados) {
					vm.turmasMultisseriada = dados;
				});

			vm.tracker.carregarTurmasMultisseriada.addPromise(promise);
		}

		function carregarItensTurmaEjaModular() {
			var promise = TurmaService.getItensTurmaEjaModular(vm.turma.id)
				.then(function(dados) {
					vm.listaItensDetalhes = dados;
				});

			vm.tracker.carregarItensTurmaDetalhes.addPromise(promise);
		}

		function carregaEnturmacoes(checkboxAtivos) {
			if (vm.enturmacoesCarregadas && checkboxAtivos === undefined) {
				return;
			}

			var promiseEnturmacoes;
			var filter = checkboxAtivos === false ? '' : 'dataFim is null';

			if (vm.isAtividade(vm.turma.tipoAtendimento)){				
				promiseEnturmacoes = TurmaService.getEnturmacoes(vm.turma.id, {
					filter: filter
				}).then(setEnturmacoes);

				vm.tracker.enturmacoes.addPromise(promiseEnturmacoes);

				return;
			}
			
			if(vm.turma.multiturno){
				promiseEnturmacoes = TurmaService.getEnturmacoesMultiturno(vm.turma.id, {
					filter: filter
				}).then(setEnturmacoes);
			}else{
				promiseEnturmacoes = TurmaService.getEnturmacoes(vm.turma.id, {
					filter: filter
				}).then(setEnturmacoes);
			}

			vm.tracker.enturmacoes.addPromise(promiseEnturmacoes);
		}

		function setEnturmacoes(page){
			vm.enturmacoesCarregadas = true;

			if(vm.turma.multiturno){
				vm.enturmacoesTurnos = page;	
			}else{
				vm.turma.enturmacoes = page.content;
			}
		}

		function carregaEnturmacoesProfessores() {
			var filter = 'turma = ' + vm.turma.id + ' and tipo = "PROFESSOR" and dataEncerramento is null';
			var sort = 'professor.servidor.pessoa.nome';

			var promise = TurmaService.getEnturmacoesFuncionarios({
				filter: filter,
				sort: sort
			}).then(buscaProfessoresPorTurma);

			vm.tracker.professores.addPromise(promise);

			function buscaProfessoresPorTurma(page) {
				vm.turma.enturmacoesProfessores = page.content;
				vm.turma.enturmacoesProfessores.sort(ordenarEnturmacoesFuncionarios);
			}
		}

		function ordenarEnturmacoesFuncionarios(a, b) {
            return a.professor.nome.localeCompare(b.professor.nome) || compararDescricaoItemEducacional(a, b);
        }

        function compararDescricaoItemEducacional(a, b) {
            if (a.itemEducacional.descricao) {
                return a.itemEducacional.descricao.localeCompare(b.itemEducacional.descricao);
            }
            return a.itemEducacional.descricaoPai.localeCompare(b.itemEducacional.descricaoPai);
        }

		function agruparAlunos() {
            Modal.open({
				params: {
					turma: vm.turma
				},
                templateUrl: 'matricula/turma/app-card-turma/agrupar-alunos-modal/agrupar-alunos-modal.html',
                controller: 'educacao.matricula.AgruparAlunosModalController as vm'
            }).result.then(reloadEnturmacoes);
        }

		function reloadEnturmacoes() {
			vm.enturmacoesCarregadas = null;
			carregaEnturmacoes(vm.checkSomenteAtivos);
		}

		function getConfiguracao() {
			if (vm.turma.separacaoAlunosEmGrupos) {
				return GrupoEnturmacaoService
					.getGruposEstabelecimento(vm.turma.estabelecimento.id)
					.then(function (configuracao) {
						vm.configuracaoTurma = configuracao;
						vm.hasGrupos = Boolean(_.get(configuracao, 'grupoEnturmacaoItem.length'));
						vm.isShowGroups = vm.turma.separacaoAlunosEmGrupos && vm.hasGrupos;
					});
			}
		}

		function openModalFuncionario(professor) {
			Modal.open({
				params: {
					funcionario: FuncionarioService.getMatriculasFuncionario(professor.servidor.id),
					disableExcluir: true
				},
                templateUrl: 'planejamento/funcionarios/funcionario/cadastro-funcionario.html',
                controller: 'educacao.funcionario.FuncionarioCadCtrl as vm',
				size: 'xxl'
            }).result.then(carregaEnturmacoesProfessores);

		}

		function changeViewListEnturmacoes() {
			vm.isShowGroups = vm.showGroupsCheck && vm.turma.separacaoAlunosEmGrupos && vm.hasGrupos;
		}
	}
})();


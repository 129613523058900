(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .service('educacao.lista-espera-novo.configuracao.ConfiguracaoListaEsperaUtils', ConfiguracaoListaEsperaUtils);

    ConfiguracaoListaEsperaUtils.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        'educacao.common.CommonSelect',
        '$enumsCache'
    ];

    function ConfiguracaoListaEsperaUtils(ConfiguracaoListaEsperaService, CommonSelect, $enumsCache) {

        var self = this;

        self.ajusteEstabelecimento = ajusteEstabelecimento;
        self.searchMatriz = searchMatriz;
        self._selectIdades = _selectIdades;
        self.enumTurno = enumTurno;
        self.enumEquivalencia = enumEquivalencia;
        self.equivalencias = equivalencias;
        self.montaListaEstabelecimento = montaListaEstabelecimento;
        self.montaConfiguracaoEditando = montaConfiguracaoEditando;

        function montaListaEstabelecimento(listEstabelecimento) {
            return ConfiguracaoListaEsperaService.getEstabelecimentos(_.pluck(listEstabelecimento, 'estabelecimento'))
                .then(_.bind(createlistaEstabelecimento, null, listEstabelecimento));
        }

        function createlistaEstabelecimento(listEstabelecimento, estabelecimento) {
            return _.chain(estabelecimento)
                .map(_.bind(setIdMongo, null, listEstabelecimento))
                .value();
        }

        function setIdMongo(listEstabelecimento, estabelecimento) {
            estabelecimento.idMongo = _.get(_.find(listEstabelecimento, 'estabelecimento', estabelecimento.id), 'id');
            return estabelecimento;
        }

        function montaConfiguracaoEditando(configuracao, niveisModalidades) {
            if (!configuracao) {
                return;
            }

            configuracao.niveisModalidades = _.chain(niveisModalidades)
                .map(_.bind(verficaModalidade, null, configuracao))
                .value();

            return configuracao;
        }

        function verficaModalidade(configuracao, modalidade) {
            var modalidadeSalva = _.find(configuracao.niveisModalidades, 'modalidade', modalidade.modalidade);
            if (modalidadeSalva) {
                modalidade.ativo = true;
                _.chain(modalidade.listNivelEscolar)
                    .map(_.bind(verificaNivelEscolar, null, modalidade, configuracao))
                    .value();
            }
            return modalidade;
        }

        function verificaNivelEscolar(modalidade, configuracao, nivel) {
            var nivelSalvo = _.find(configuracao.niveisModalidades, { 'nivelEscolar': nivel.nivelEscolar, 'modalidade': modalidade.modalidade });
            if (nivelSalvo) {
                nivel.id = nivelSalvo.id;
                nivel.etapas = ajusteEtapas(nivelSalvo.etapas);
                nivel.confiEtapas = 0;
                nivel.ativo = true;
            }
            return nivel;
        }

        function ajusteEtapas(etapas) {
            return _.chain(etapas)
                .map(deleteEtapa)
                .value();
        }

        function deleteEtapa(etapa) {
            if (etapa.turnos.length === 4) {
                etapa.turnos = ['TODOS'];
            }

            if (etapa.tipo === 'INFANTIL') {
                return ajusteEtapaInfantil(etapa);
            }
            return etapa;
        }

        function ajusteEtapaInfantil(etapa) {
            etapa.diaCompletarIdadeMinima = moment(etapa.diaMesParaCompletarIdadeMinima, '--MM-DD').date() + '';
            etapa.mesCompletarIdadeMinima = moment(etapa.diaMesParaCompletarIdadeMinima, '--MM-DD').month() + '';
            etapa.anoIdadeMaxima += '';
            etapa.anoIdadeMinima += '';
            etapa.mesIdadeMaxima += '';
            etapa.mesIdadeMinima += '';
            delete etapa.equivalenciaEtapa;
            return etapa;
        }

        function addItemEnumTurno(data) {
            var objTodos = {
                description: 'Todos',
                id: 'TODOS',
                key: 'TODOS'
            };

            data.values.unshift(objTodos);
            data.TODOS = objTodos;
            return data;
        }

        function ajusteEstabelecimento(config, estabelecimento) {
            var configuracao = _.cloneDeep(config);
            configuracao.estabelecimentos = _.chain(estabelecimento)
                .map(montaObjEstabelecimento)
                .value();

            return configuracao;
        }

        function searchMatriz(params, tabModalidadeAtiva, tabNivelAtiva) {

            if (tabModalidadeAtiva === 'BASICA') {
                var modalidade = 'EDUCACAO_BASICA';
            }

            var filter = '(descricao like "' + params.term + '" and ' +
                'modalidade = "' + (modalidade || tabModalidadeAtiva) + '" and ' +
                'nivelEscolar = "' + tabNivelAtiva + '"';

            filter += ')';

            return ConfiguracaoListaEsperaService
                .getMatrizEtapa(params.offset, params.limit, filter)
                .then(toPage);
        }

        function toPage(list) {
            return {
                content: _.first(_.pluck(list, 'content')),
                hasMore: false
            };
        }

        function _selectIdades() {
            var opcoesAnos = _.map(_.range(0, 7), montaSelectAnos);

            var opcoesMeses = _.map(_.range(self.nivelEscolar, 12), montaSelectMeses);

            var confEtapa = {
                anoIdadeMinimaSelect: CommonSelect.select2Config(opcoesAnos),
                anoIdadeMaximaSelect: CommonSelect.select2Config(opcoesAnos),
                mesCompletarIdade: CommonSelect.select2Config(opcoesMes()),
                mesIdadeMinimaSelect: CommonSelect.select2Config(opcoesMeses),
                mesIdadeMaximaSelect: CommonSelect.select2Config(opcoesMeses)
            };

            return confEtapa;
        }


        function enumTurno() {
            return $enumsCache.get('Turno').then(addItemEnumTurno);
        }

        function enumEquivalencia() {
            return $enumsCache.get('EquivalenciaEtapa');
        }

        function montaObjEstabelecimento(estabelecimento) {
            return {
                estabelecimento: _.get(estabelecimento, 'id'),
                id: _.get(estabelecimento, 'idMongo')
            };
        }

        function equivalencias() {
            var list = _.map(_.range(1, 10), montaSelectEquival);
            return CommonSelect.select2Config(list);
        }

        function montaSelectEquival(num) {
            return {
                'id': num,
                'descricao': num + 'º ano'
            };
        }

        function montaSelectMeses(num) {
            return {
                'id': num,
                'descricao': num + (num > 1 ? ' meses' : ' mês')
            };
        }

        function montaSelectAnos(num) {
            return {
                'id': num,
                'descricao': num + (num > 1 ? ' anos' : ' ano')
            };
        }

        function opcoesMes() {
            return _.map(_.range(1, 13), montaOpcoesMes);
        }

        function montaOpcoesMes(mes) {
            var momentMes = moment(mes, 'MM');
            return {
                'id': mes,
                'descricao': momentMes.format('MMMM'),
                'max': parseInt(momentMes.endOf('month').format('D'))
            };
        }

    }
})();
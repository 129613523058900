(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appComponenteCurricularSaldos', appComponenteCurricularSaldos);

    function appComponenteCurricularSaldos() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-componente-curricular-turma/app-componente-curricular-saldos/app-componente-curricular-saldos.directive.html',
            scope: {},
            bindToController: {
                componente: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        $scope.$watch('vm.componente', init);

        init();

        function init () {
            vm.isCollapsed = vm.componente.collapsed;
            vm.professores = vm.componente.professores;
    
            vm.saldos = [
                { label: 'Saldo Inicial', saldo: vm.componente.saldoInicial },
                { label: 'Saldo Ocupado', saldo: vm.componente.saldoOcupado },
                { label: 'Saldo Atual', saldo: vm.componente.saldoAtual }
            ];
        }
    }
})();
(function () {
    'use strict';
    angular
        .module('educacao.frequencia')
        .controller('educacao.frequencia.FrequenciaController', FrequenciaController);

   FrequenciaController.$inject = [
        'educacao.frequencia.TurmaFrequenciaService',
        '$scope',
        'promiseTracker',
        'bfc.PromiseTracker',
        'educacao.frequencia.FrequenciaService'];

    function FrequenciaController(
        TurmaFrequenciaService,
        $scope,
        promiseTracker,
        PromiseTracker,
        frequenciaService) {
        var vm = this;

        vm.headerFilterOptions = { radiobox: [], checkbox: [] };
        vm.models = { radiobox: [], checkbox: [] };

        vm.columns = [
            {
                id: 'nomeAluno',
                label: 'Aluno(a)',
                model: 'nome',
                filterable: true
            }];

        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaLoadings();
        }

        function inicializaPropriedades() {
            vm.frequencia = {};
            vm.indexTurma = 0;
            vm.itensEducacionais = {};
            vm.configuracaoFrequencia = null;
            vm.tracker = promiseTracker();
            vm.listTurmas = TurmaFrequenciaService.list;
            vm.searchFilter = {};
        }

        $scope.$watch('vm.frequencia.turma',getConfiguracaoFrequencia);

        function inicializaLoadings() {
            if (_.isEmpty(vm.searchFilter.others)) {
                vm.searchFilter.others = {
                    situacaoMatricula: [
                        'CURSANDO'
                    ]};

            }
            vm.loadings = PromiseTracker.create(['frequenciaPeriodos', 'salvar']);
        }



        function getConfiguracaoFrequencia(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            delete vm.registros;
            if(_.get(vm.frequencia.turma ,'etapaMatriz.modular')){
                vm.searchFilter.others.situacaoMatriculaPai = ['CURSANDO'];
            }
            if(!_.get(vm.frequencia,'turma.id')){
                delete vm.configuracaoFrequencia;
                return;
            }
            if (_.get(vm.frequencia,'turma')) {
                var promise = frequenciaService.getConfiguracaoFrequencia(vm.frequencia.turma.id);
                promise.then(function(data) {
                    vm.turma = _.clone(vm.frequencia.turma);
                    vm.estabelecimento = vm.frequencia.turma.estabelecimento;
                    vm.configuracaoFrequencia = data;
                }, function() {
                    vm.frequencia.turma = null;
                    vm.headerFilterOptions.clear();
                });
            }
        }
    }

})();
(function () {
    'use strict';

    angular
        .module('educacao.matricula')
        .directive('appJoinRowspan', directive);

    function directive() {
        return {
            restrict: 'A',
            link: postLink
        };
    }

    function postLink($scope, $element) {
        _.delay(function () {
            var value = $element.html();
            var index = $element.prevAll().length;

            var tr = $element.parents('tr');

            var first = true;
            var tdSelector = 'td:eq(' + index + ')';

            tr.prevAll().each(function (tdIndex, trS) {
                $(trS).children(tdSelector).each(function (i, e) {
                    first = first && e.innerHTML !== value;
                });
            });
            if (!first) {
                $element.hide();
                return;
            }

            var rowspan = 1;
            tr.nextAll().each(function (tdIndex, trS) {
                $(trS).children(tdSelector).each(function (i, e) {
                    rowspan += e.innerHTML === value ? 1 : 0;
                });
            });
            $element.attr('rowspan', rowspan);
        }, 150);
    }
})();
(function () {
	'use strict';

	angular.module('educacao.common')
		.directive('ctCadTitle', ['$parse',
			function($parse) {
				return {
					restrict: 'A',
					link: function(scope, element) {
						var message = element.html(),
							modo = $parse('modo')(scope);

						if (!modo) {
							return;
						}
						if (modo.adicionando) {
							message = 'Adicionando ' + message;
							if (modo.adicionandoNovaOpcao) {
								element.wrap('<div></div>');
								element.parent().append('<small class="text-warning"><i class="icon-info-sign"></i> Atenção! O dado informado ainda não existe. Preencha os campos necessários para cadastrá-lo.</small>');
							}
						} else if (modo.editando) {
							message = 'Editando ' + message;
						} else if (modo.copiando) {
							message = 'Copiando ' + message;
							element.wrap('<div></div>');
							element.parent().append('<small class="text-warning"><i class="icon-info-sign"></i> Atenção! Você está copiando um(a) ' + element.text() + '.</small>');
						} else if (modo.visualizando) {
							message = 'Visualizando ' + message;
						} else {
							throw new Error('Erro ao executar a diretiva ctCadTitle: A variável "modo" é inválida ou inexistente para o escopo atual');
						}
						element.html(message);
					}
				};
			}
		]);
}());
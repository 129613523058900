(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.LotacaoFisicaCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.funcionario.LotacaoFisicaService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        LotacaoFisicaService
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.LotacaoFisicaService = LotacaoFisicaService;

        function openCad(lotacao) {
            $modalStates.goModalState(lotacao);
        }

        function remove(data) {
            LotacaoFisicaService.remove(data);
        }
    }
})();


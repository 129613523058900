(function() {
    'use strict';

    angular.module('educacao.matricula')
    .controller('educacao.matricula.TiposAvaliacaoCadCtrl', TiposAvaliacaoCadCtrl);


    TiposAvaliacaoCadCtrl.$inject = ['educacao.matricula.TipoAvaliacaoService',

        '$modalTracker',
        '$q',
        'id',
        'readOnly',
        'promiseTracker',
        'fnCad',
        '$rootScope',
        '$modalInstance',
        'bfc.Focus',
        '$scope',
        'bfc.Notification',
        'educacao.context.ContextoEntidade',
        'APP_STAGE',
        'ENTIDADES_CONCEITOS_POR_PERIODO'
    ];

    function TiposAvaliacaoCadCtrl(TipoAvaliacaoService,
        $modalTracker,
        $q,
        id,
        readOnly,
        promiseTracker,
        fnCad,
        $rootScope,
        $modalInstance,
        focus,
        $scope,
        Notification,
        ContextoEntidade,
        APP_STAGE,
        ENTIDADES_CONCEITOS_POR_PERIODO
    ) {

        var ENVS_DEV = ['development'];
        var IDS_ENTIDADES_CONC_PERIODOS = ENTIDADES_CONCEITOS_POR_PERIODO.trim().split(',').map(function(item) {
            return parseInt(item);
        });

        var vm = this,
            _options = {};

        vm.opcoes = _options;

        vm.tracker = promiseTracker();
        vm.tracker.saveContinuar = promiseTracker();
        vm.tracker.save = promiseTracker();
        vm.tracker.remove = promiseTracker();

        vm.isTipoAvaliacaoChecked = isTipoAvaliacaoChecked;
        vm.onTipoAvaliacaoChanged = onTipoAvaliacaoChanged;
        vm.addConceito = addConceito;
        vm.addConceitoNumerico = addConceitoNumerico;
        vm.configuracao = {};
        vm.save = save;
        vm.cancel = fechaModal;
        vm.removeConceito = removeConceito;
        vm.removeConceitoNumerico = removeConceitoNumerico;
        vm.conceitosNumericos = [];
        vm.conceitos = [];
        vm.remove = removeConfiguracao;
        vm.validaCampoConceitos = validaCampoConceitos;
        vm.validaCampoConceitosNumericos = validaCampoConceitosNumericos;
        vm.notaMinimaPrimeiroPeriodo = 0;
        vm.notaMaximaPrimeiroPeriodo = 0;
        vm.notaMinimaSegundoPeriodo = 0;
        vm.notaMaximaSegundoPeriodo = 0;
        vm.notaMinimaTerceiroPeriodo = 0;
        vm.notaMaximaTerceiroPeriodo = 0;
        vm.notaMinimaQuartoPeriodo = 0;
        vm.notaMaximaQuartoPeriodo = 0;
        vm.canShowConfigLagoaSanta = showConfigLagoaSanta();
        vm.hasCopiConceitosEspecificos =  false;

        vm.ORDEM_PERIODOS = {
            PRIMEIRO: 1,
            SEGUNDO: 2,
            TERCEIRO: 3,
            QUARTO: 4,
        };


        $scope.$watch('vm.configuracao.utilizaConceitosEspecificosPorPeriodo', onChangeUtilizaConceitoEspecifico);
        vm.conceitosNumericosEspecificos = [];

        TipoAvaliacaoService.getQuantidadeDecimais().then(function(data) {
            vm.enumQuantidadeDecimais = $q.when(data);
            vm.enumQuantidadeDecimais.values = data;
        });
        TipoAvaliacaoService.getTipoDecimais().then(function(data) {
            vm.enumTipoDecimais = $q.when(data);
            vm.enumTipoDecimais.values = data;
        });
        vm.enumSituacaoCadastral = TipoAvaliacaoService.getSituacaoCadastral();
        TipoAvaliacaoService.getTipoAvaliacao().then(function(data) {
            vm.enumTipoAvaliacao = data;
        });

        vm.isHabilitadoMostrarCheckboxDecimaisLivres = isHabilitadoMostrarCheckboxDecimaisLivres;

        var modo = {
            adicionando: !id && !readOnly,
            editando: id && !readOnly
        };

        init();

        function init() {
            $modalTracker.addPromise(
                $q.all([
                    verificaFormulario(),
                    refresh()
                ]).then(
                    function postInit() {
                        angular.extend(vm, modo);
                        vm.windowTitle = modo.adicionando ? 'Adicionando tipo de avaliação' : 'Editando tipo de avaliação';
                        if (!id) {
                            vm.isConceito = false;
                            vm.isNumerico = false;
                            vm.isConceitoNumerico = false;
                        }
                        preencherNotasDosPeriodos();
                        ordernarConceitosNumericos();
                    }
                )
            );
        }
        function ordernarConceitosNumericos() {
            vm.conceitosNumericos = _.sortBy(vm.conceitosNumericos, 'valorMinimo').reverse();
            vm.conceitosNumericosEspecificos = _.sortBy(vm.conceitosNumericosEspecificos, 'PRIMEIRO_PERIODO.valorMinimo').reverse();
        }

        function onChangeUtilizaConceitoEspecifico() {
            if (vm.configuracao.utilizaConceitosEspecificosPorPeriodo) {
                copyConceitosNumericosPadroes();
            }
        }

        function copyConceitosNumericosPadroes() {
            if(!vm.hasCopiConceitosEspecificos){
                vm.conceitosNumericosEspecificos = atualizaConceitosEsp();
                vm.hasCopiConceitosEspecificos = true;
            }
        }


        function addConceito() {
            vm.conceitos.push({
                sigla: '',
                descricao: ''
            });
            vm.disableAddConceito = true;
        }

        function addConceitoNumerico() {
            vm.conceitosNumericos.push({
                sigla: '',
                descricao: '',
                valorMinimo: '',
                valorMaximo: '',
                valorCalculo: ''
            });

            var last = _.last(vm.conceitosNumericos);
            var index = vm.conceitosNumericos.indexOf(last);
            atualizarOuAdicionarConceito(index, last);
        }


        function validaCampoConceitos(conceito) {
            vm.disableAddConceito = !conceito.sigla || !conceito.descricao;
        }


        function validaCampoConceitosNumericos(conceitoNumerico) {
            vm.disableAddConceitoNumerico = !conceitoNumerico.sigla ||
                !conceitoNumerico.descricao ||
                conceitoNumerico.valorCalculo < '0' ||
                conceitoNumerico.valorMaximo < '0' ||
                conceitoNumerico.valorMinimo < '0';

            if (!vm.disableAddConceitoNumerico) {
                var index = vm.conceitosNumericos.indexOf(conceitoNumerico);
                if (!_.isUndefined(index)) {
                    atualizarOuAdicionarConceito(index, conceitoNumerico);
                }
            }

        }

        function atualizarOuAdicionarConceito(index, conceitoNumerico) {
            var conceitoEspecifico = vm.conceitosNumericosEspecificos[index];

            if (_.isUndefined(conceitoEspecifico)) {
                adicionarNovoConceito(conceitoNumerico);
            } else {
                atualizarSiglaConceito(conceitoEspecifico, conceitoNumerico.sigla);
            }
        }

        function adicionarNovoConceito(conceitoNumerico) {
            var template = criaTemplatePorPeriodo(conceitoNumerico, null, null);
            template.sigla = _.get(conceitoNumerico, 'sigla');
            vm.conceitosNumericosEspecificos.push(template);
        }

        function atualizarSiglaConceito(conceitoEspecifico, sigla) {
            conceitoEspecifico.sigla = sigla;
        }

        function removeConceito(conceito) {
            var index = vm.conceitos.indexOf(conceito);
            vm.conceitos.splice(index, 1);
            for (var i = 0; i < vm.conceitos.length; i++) {
                validaCampoConceitos(vm.conceitos[i]);
                if (vm.disableAddConceito) {
                    return;
                }
            }
            if (vm.conceitos.length === 0) {
                vm.disableAddConceito = false;
            }
        }


        function removeConceitoNumerico(conceitoNumerico) {
            var index = vm.conceitosNumericos.indexOf(conceitoNumerico);
            vm.conceitosNumericos.splice(index, 1);
            removeConceitoEspecifico(index);

            for (var i = 0; i < vm.conceitosNumericos.length; i++) {
                validaCampoConceitosNumericos(vm.conceitosNumericos[i]);
                if (vm.disableAddConceitoNumerico) {
                    return;
                }
            }
            if (vm.conceitosNumericos.length === 0) {
                vm.disableAddConceitoNumerico = false;
            }
        }

        function removeConceitoEspecifico(index) {
            vm.conceitosNumericosEspecificos.splice(index, 1);
        }

        function ajustaListConceitosAvaliacoesToSave() {

            if (vm.isConceito) {
                if (vm.configuracao.avaliacaoConceito !== null) {
                    vm.configuracao.avaliacaoConceito.conceitos = vm.conceitos;
                } else {
                    vm.configuracao.avaliacaoConceito = {
                        conceitos: vm.conceitos
                    };
                }
            } else if (vm.configuracao.avaliacaoConceito !== null) {
                vm.configuracao.avaliacaoConceito = null;
            }

        }

        function ajustaListConceitosNumericosAvaliacoesToSave() {
            if (vm.isConceitoNumerico) {
                if (vm.configuracao.avaliacaoConceitoNumerico !== null) {
                    vm.configuracao.avaliacaoConceitoNumerico.conceitosNumericos = vm.conceitosNumericos;
                } else {
                    vm.configuracao.avaliacaoConceitoNumerico = {
                        conceitosNumericos: vm.conceitosNumericos
                    };
                }

            } else if (vm.configuracao.avaliacaoConceitoNumerico !== null) {
                vm.configuracao.avaliacaoConceitoNumerico = null;
            }
        }

        function ajustaConfiguracaoNumericaToSave() {
            if (vm.isNumerico || vm.isConceitoNumerico) {
                if (vm.configuracao.decimaisAvaliacao === vm.enumTipoDecimais.values.LIVRE.key) {
                    vm.configuracao.utilizaDecimaisLivre = false;
                }
                return;
            }

            vm.configuracao.utilizaDecimaisLivre = false;
            vm.configuracao.notaMinima = null;
            vm.configuracao.notaMaxima = null;
            vm.configuracao.quantidadeDecimais = null;
            vm.configuracao.decimaisAvaliacao = null;
        }

        function ajustaListTiposAvaliacoesToEdit() {
            if (!vm.isConceito) {
                addConceito();
                vm.disableAddConceito = true;
            } else {
                vm.conceitos = vm.configuracao.avaliacaoConceito.conceitos;
            }

            if (!vm.isConceitoNumerico) {
                addConceitoNumerico();
                vm.disableAddConceitoNumerico = true;
            } else {
                vm.conceitosNumericos = vm.configuracao.avaliacaoConceitoNumerico.conceitosNumericos;
            }
        }

        function refresh() {

            verificaFormulario();
            return Boolean(id) ?
                TipoAvaliacaoService.get(id).then(function(data) {
                    vm.configuracao = data;
                    setStatusTiposAvaliacoes(vm.configuracao.tiposAvaliacoes);
                    ajustaListTiposAvaliacoesToEdit();

                }) :

                TipoAvaliacaoService.getDefaultRepresentation().then(function(data) {
                    vm.configuracao = data;
                    vm.configuracao.tiposAvaliacoes = [];
                    addConceitoNumerico();
                    addConceito();
                });
        }


        $scope.$watch('vm.configuracao.quantidadeDecimais', function(value) {
            if (value === 'ZERO') {
                vm.quantidadeCasasDecimais = 0;
                vm.configuracao.utilizaDecimaisLivre = false;
            } else if (value === 'UMA') {
                vm.quantidadeCasasDecimais = 1;
            } else if (value === 'DUAS') {
                vm.quantidadeCasasDecimais = 2;
            }
        });

        function isTipoAvaliacaoChecked(tipoAvaliacao) {
            return !!vm.configuracao.tiposAvaliacoes && vm.configuracao.tiposAvaliacoes.indexOf(tipoAvaliacao.key) !== -1;
        }

        function onTipoAvaliacaoChanged(tipoAvaliacao) {
            if (!vm.configuracao.tiposAvaliacoes) {
                vm.configuracao.tiposAvaliacoes = [];
            }

            var tipoAvaliacaoKey = tipoAvaliacao.key;

            var index = vm.configuracao.tiposAvaliacoes.indexOf(tipoAvaliacaoKey);

            if (index === -1) {
                vm.configuracao.tiposAvaliacoes.push(tipoAvaliacaoKey);
                atualizaStatusTipoAvaliacaoSelecionado(tipoAvaliacaoKey, true);
            } else {
                vm.configuracao.tiposAvaliacoes.splice(index, 1);
                atualizaStatusTipoAvaliacaoSelecionado(tipoAvaliacaoKey, false);
            }
        }

        function preencherNotasDosPeriodos() {
            var periodos = [vm.ORDEM_PERIODOS.PRIMEIRO, vm.ORDEM_PERIODOS.SEGUNDO, vm.ORDEM_PERIODOS.TERCEIRO, vm.ORDEM_PERIODOS.QUARTO];

            periodos.forEach(function(periodo) {
                var conceitoPeriodo = _.find(vm.conceitosNumericos, function(conceito) {
                    return _.some(conceito.conceitosEspecificos, {ordemPeriodo: periodo});
                });

                if (conceitoPeriodo) {
                    var conceito = _.find(conceitoPeriodo.conceitosEspecificos, {ordemPeriodo: periodo});
                    vm['notaMinima' + periodo + 'Periodo'] = conceito ? conceito.notaMinima : null;
                    vm['notaMaxima' + periodo + 'Periodo'] = conceito ? conceito.notaMaxima : null;
                }
            });
        }


        function save(continuar) {
            ajustarConceitosEspecificosToSave();
            var tracker = continuar ? vm.tracker.saveContinuar : vm.tracker.save;

            ajustaListConceitosAvaliacoesToSave();
            ajustaListConceitosNumericosAvaliacoesToSave();
            ajustaConfiguracaoNumericaToSave();

            tracker.addPromise(
                TipoAvaliacaoService.save(vm.configuracao).then(function(data) {
                    showSucessMessage(data.descricao);
                    if (angular.isFunction(fnCad)) {
                        fnCad(data);
                    }
                    $rootScope.$broadcast('refresh::configuracoes', {
                        data: data,
                        add: !id,
                        edit: Boolean(id)
                    });
                    onSuccess(continuar, tracker);
                })
            );

        }

        function getValuesMinimoMaximoPeriodo(conceitoEsp) {
            switch (conceitoEsp.ordemPeriodo) {
                case vm.ORDEM_PERIODOS.PRIMEIRO:
                    vm.notaMinimaPrimeiroPeriodo = conceitoEsp.notaMinima;
                    vm.notaMaximaPrimeiroPeriodo = conceitoEsp.notaMaxima;
                    break;
                case vm.ORDEM_PERIODOS.SEGUNDO:
                    vm.notaMinimaSegundoPeriodo = conceitoEsp.notaMinima;
                    vm.notaMaximaSegundoPeriodo = conceitoEsp.notaMaxima;
                    break;
                case vm.ORDEM_PERIODOS.TERCEIRO:
                    vm.notaMinimaTerceiroPeriodo = conceitoEsp.notaMinima;
                    vm.notaMaximaTerceiroPeriodo = conceitoEsp.notaMaxima;
                    break;
            }
        }

        function atualizaConceitosEsp() {
            var conceitosMap = {};

            vm.conceitosNumericosEspecificos.forEach(function(c) {
                conceitosMap[c.sigla] = c;
            });

            return vm.conceitosNumericos.map(function(conceito) {
                var templatePeriodosEspecificos = conceitosMap.sigla || {
                    sigla: conceito.sigla,
                    PRIMEIRO_PERIODO: {valorMinimo: null, valorMaximo: null, valorCalculo: null},
                    SEGUNDO_PERIODO: {valorMinimo: null, valorMaximo: null, valorCalculo: null},
                    TERCEIRO_PERIODO: {valorMinimo: null, valorMaximo: null, valorCalculo: null}
                };

                if (Array.isArray(conceito.conceitosEspecificos)) {
                    conceito.conceitosEspecificos.forEach(function(conceitoEsp) {
                        switch (conceitoEsp.ordemPeriodo) {
                            case vm.ORDEM_PERIODOS.PRIMEIRO:
                                templatePeriodosEspecificos.PRIMEIRO_PERIODO = criaTemplatePorPeriodo(conceitoEsp, vm.notaMinimaPrimeiroPeriodo, vm.notaMaximaPrimeiroPeriodo);
                                getValuesMinimoMaximoPeriodo(conceitoEsp);
                                break;
                            case vm.ORDEM_PERIODOS.SEGUNDO:
                                templatePeriodosEspecificos.SEGUNDO_PERIODO = criaTemplatePorPeriodo(conceitoEsp, vm.notaMinimaSegundoPeriodo, vm.notaMaximaSegundoPeriodo);
                                getValuesMinimoMaximoPeriodo(conceitoEsp);
                                break;
                            case vm.ORDEM_PERIODOS.TERCEIRO:
                                templatePeriodosEspecificos.TERCEIRO_PERIODO = criaTemplatePorPeriodo(conceitoEsp, vm.notaMinimaTerceiroPeriodo, vm.notaMaximaTerceiroPeriodo);
                                getValuesMinimoMaximoPeriodo(conceitoEsp);
                                break;
                            case vm.ORDEM_PERIODOS.QUARTO:
                                templatePeriodosEspecificos.QUARTO_PERIODO = criaTemplatePorPeriodo(conceitoEsp, vm.notaMinimaQuartoPeriodo, vm.notaMaximaQuartoPeriodo);
                                getValuesMinimoMaximoPeriodo(conceitoEsp);
                                break;
                        }
                    });
                }

                return templatePeriodosEspecificos;
            });
        }

        function criaTemplatePorPeriodo(conceitoEsp, notaMinima, notaMaxima) {
            return {
                valorMinimo: _.get(conceitoEsp, 'valorMinimo'),
                valorMaximo: _.get(conceitoEsp, 'valorMaximo'),
                valorCalculo: _.get(conceitoEsp, 'valorCalculo'),
                notaMinima: notaMinima || 0,
                notaMaxima: notaMaxima || 0
            };
        }


        function ajustarConceitosEspecificosToSave() {
            if (!vm.configuracao.utilizaConceitosEspecificosPorPeriodo) {
                clearConceitosEspecificosIfNotUtilizaConceitosEspecificosPorPeriodo();
                return;
            }

            _.forEach(vm.conceitosNumericos, function(conceitoNumerico) {
                conceitoNumerico.conceitosEspecificos = vm.conceitosNumericosEspecificos
                .filter(function(conceitoEsp) {
                    return conceitoEsp.sigla === conceitoNumerico.sigla;
                })
                .flatMap(mountConceitoEspecificos);
            });
        }

        function clearConceitosEspecificosIfNotUtilizaConceitosEspecificosPorPeriodo() {
            _.forEach(vm.conceitosNumericos, function(conceitoNumerico) {
                conceitoNumerico.conceitosEspecificos = [];
            });
        }

        function mountConceitoEspecificos(conceitoEsp) {
            var periodos = [];

            if (!_.isUndefined(conceitoEsp.PRIMEIRO_PERIODO)) {
                configurarPeriodo(conceitoEsp.PRIMEIRO_PERIODO, vm.ORDEM_PERIODOS.PRIMEIRO, vm.notaMinimaPrimeiroPeriodo, vm.notaMaximaPrimeiroPeriodo, conceitoEsp.sigla);
                periodos.push(conceitoEsp.PRIMEIRO_PERIODO);
            }

            if (!_.isUndefined(conceitoEsp.SEGUNDO_PERIODO)) {
                configurarPeriodo(conceitoEsp.SEGUNDO_PERIODO, vm.ORDEM_PERIODOS.SEGUNDO, vm.notaMinimaSegundoPeriodo, vm.notaMaximaSegundoPeriodo, conceitoEsp.sigla);
                periodos.push(conceitoEsp.SEGUNDO_PERIODO);
            }

            if (!_.isUndefined(conceitoEsp.TERCEIRO_PERIODO)) {
                configurarPeriodo(conceitoEsp.TERCEIRO_PERIODO, vm.ORDEM_PERIODOS.TERCEIRO, vm.notaMinimaTerceiroPeriodo, vm.notaMaximaTerceiroPeriodo, conceitoEsp.sigla);
                periodos.push(conceitoEsp.TERCEIRO_PERIODO);
            }

            if (!_.isUndefined(conceitoEsp.QUARTO_PERIODO)) {
                configurarPeriodo(conceitoEsp.QUARTO_PERIODO, vm.ORDEM_PERIODOS.QUARTO, vm.notaMinimaQuartoPeriodo, vm.notaMaximaQuartoPeriodo, conceitoEsp.sigla);
                periodos.push(conceitoEsp.QUARTO_PERIODO);
            }

            return periodos;
        }


        function configurarPeriodo(periodo, ordem, notaMinima, notaMaxima, sigla) {
            periodo.ordemPeriodo = ordem;
            periodo.notaMinima = notaMinima;
            periodo.notaMaxima = notaMaxima;
            periodo.sigla = sigla;
        }


        function showSucessMessage(descricao) {
            return Notification.publish('Tipo de avaliação ' + descricao + ' salvo com sucesso.', 'success');
        }

        function removeConfiguracao() {
            TipoAvaliacaoService.remove(vm.configuracao).then(function() {
                $rootScope.$broadcast('refresh::configuracoes', {
                    data: vm.configuracao,
                    remove: true
                });
                fechaModal();
            });
        }

        function onSuccess(continuar, tracker) {
            if (continuar) {
                id = null;
                tracker.addPromise(refresh());
                vm.conceitos = [];
                vm.conceitosNumericos = [];
                vm.isConceitoNumerico = false;
                vm.isConceito = false;
                vm.isNumerico = false;
                focus('lbDescricao');
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function setStatusTiposAvaliacoes(tiposAvaliacoes) {
            for (var i = 0; i < tiposAvaliacoes.length; i++) {
                if (tiposAvaliacoes[i] === 'CONCEITO') {
                    vm.isConceito = true;
                } else if (tiposAvaliacoes[i] === 'CONCEITO_NUMERICO') {
                    vm.isConceitoNumerico = true;
                } else if (tiposAvaliacoes[i] === 'NUMERICO') {
                    vm.isNumerico = true;
                }
            }
        }

        function verificaFormulario() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }


        function atualizaStatusTipoAvaliacaoSelecionado(tipoAvaliacaoKey, status) {
            switch (tipoAvaliacaoKey) {
                case 'CONCEITO':
                    vm.isConceito = status;
                    return;
                case 'CONCEITO_NUMERICO':
                    vm.isConceitoNumerico = status;
                    return;
                case 'NUMERICO':
                    vm.isNumerico = status;
                    return;
            }
        }

        function isHabilitadoMostrarCheckboxDecimaisLivres() {
            return vm.enumTipoDecimais.values.ZERO_OU_CINCO.key === vm.configuracao.decimaisAvaliacao && vm.enumQuantidadeDecimais.values.ZERO.key !== vm.configuracao.quantidadeDecimais;
        }

        function showConfigLagoaSanta() {
            return ENVS_DEV.includes(APP_STAGE) || IDS_ENTIDADES_CONC_PERIODOS.includes(ContextoEntidade.getEntidade().id);
        }
    }

})
();

(function() {
    'use strict';

    angular.module('educacao.matriz').factory('educacao.matriz.EixoTematicoService', EixoTematicoService);

    EixoTematicoService.$inject = ['GenericService', 'Restangular'];

    function EixoTematicoService(GenericService, Restangular) {

        function getById(id) {
            return Restangular.one('matriz').one('eixo-tematico', id).get()
                .then(function(data) {
                    return data;
                });
        }

        return GenericService
            .createWithCustomMethods({
                getById: getById,
            })
            .module({
                uri: 'matriz'
            })
            .service({
                uri: 'eixo-tematico'
            });

    }
})();

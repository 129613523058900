(function() {
  'use strict';

  angular
    .module('educacao.matricula')
    .factory('educacao.matricula.EnturmacaoService', EnturmacaoService);

  EnturmacaoService.$inject = ['Restangular'];

  function EnturmacaoService(Restangular) {
    var matriculaEndPoint = Restangular.all('matricula');
    var enturmacaoEndPoint = matriculaEndPoint.all('enturmacao');

    return {
      getEnturmaveis: getEnturmaveis,
      enturmar: enturmar,
      desenturmar: desenturmar,
      remanejar: remanejar,
      editarRemanejamento: editarRemanejamento,
      getListaEnturmacao: getListaEnturmacao,
      editarNumeroChamada: editarNumeroChamada,
      alterarDatasEnturmacao: alterarDatasEnturmacao,
      agruparEnturmacoes: agruparEnturmacoes,
      agruparEnturmacoesAlunos: agruparEnturmacoesAlunos
    };

    function enturmar(matricula, turma, data) {
      return enturmacaoEndPoint.customPOST({
        matricula: matricula,
        turma: turma,
        dataInicio: data
      });
    }

    function desenturmar(enturmacao) {
      return matriculaEndPoint.one('enturmacao', enturmacao.id).remove();
    }

    function remanejar(remanejamento) {
      return enturmacaoEndPoint.all('remanejar').customPOST(remanejamento);
    }

    function getListaEnturmacao(filtro) {
      return enturmacaoEndPoint.all('enturmacaoTurma').getList({
        filter: filtro
      });
    }

    function editarRemanejamento(edicaoRemanejamento) {
      return enturmacaoEndPoint
        .all('editarRemanejamento')
        .customPOST(edicaoRemanejamento);
    }

    function editarNumeroChamada(editaNumeroChamada) {
      var endpoint = Restangular.all('matricula')
        .one('enturmacao', editaNumeroChamada.id)
        .all('numeroChamada');
      return endpoint.customPUT(
        editaNumeroChamada.id,
        editaNumeroChamada.numeroChamada
      );
    }

    function getEnturmaveis(filtro) {
      return matriculaEndPoint.all('enturmacao/enturmaveis').customPOST(filtro);
    }

    function alterarDatasEnturmacao(enturmacao) {

      var copia = angular.copy(enturmacao);

      delete copia.itensEducacionais;

      return Restangular.all('matricula')
        .one('enturmacao', enturmacao.id)
        .one('alterar-data')
        .customPUT(copia);
    }

    function agruparEnturmacoes(enturmacoes) {
      return Restangular.all('matricula')
        .one('enturmacao')
        .one('agrupar')
        .customPUT(enturmacoes);
    }

    function agruparEnturmacoesAlunos(enturmacoes) {
      return Restangular.all('matricula')
        .one('enturmacao')
        .one('agrupar-aluno')
        .customPUT(enturmacoes);
    }
  }
})();

(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .directive('appFuncionariosIndicadores', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/funcionarios-indicadores.directive.html',
            scope: {},
            bindToController: {
                filtraAniversariante: '&',
                filtraSituacaoFuncionario: '&',
                trackerList: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'educacao.funcionario.FuncionarioService'];

    function Controller($scope, promiseTracker, FuncionarioService) {
        var vm = this;

        vm.estatisticas = {};

        vm.loadingTracker = promiseTracker();
        vm.filtraAniversairo = filtrafiltraAniversairo;
        vm.filtraSituacao = filtrarSituacao;

        function filtrafiltraAniversairo() {
            vm.filtraAniversariante();
        }

        $scope.$watch('vm.trackerList.active()', watchTrakerList);

        function watchTrakerList(newValue) {
            if (newValue) {
                load();
            }
        }

        function filtrarSituacao(situacao) {
            var tipoSituacao = {
                situacao: situacao
            };
            vm.filtraSituacaoFuncionario(
                tipoSituacao
            );
        }
        FuncionarioService.on('dirty', load);

        $scope.$on('$destroy', function() {
            
            FuncionarioService.off(load);
        });
        $scope.$on('refreshDetalhesFuncionario', function() {
            load();
        });
        $scope.$on('loadIndicadores', function() {
            load();
        });

        function load() {
            vm.loadingTracker.addPromise(
                FuncionarioService.getEstatisticas().then(function(estatisticas) {
                    vm.estatisticas = estatisticas;
                })
            );
        }
    }
})();

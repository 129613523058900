(function() {
    'use strict';

    angular.module('educacao.matricula')

    .constant('TIPOS_MATRICULAS', {
        'EDUCACAO_BASICA': {
            title: 'Educação Básica',
            modalidade: 'EDUCACAO_BASICA',
            informaFilhos: false,
            informaOrigem: true,
            informaDocumentos: true,
            listaEspera: true,
            restricoes: true,
            progressaoParcial: false,
            informaTurmaUnificada: true,
            tipo: 'vaga'
        },
        'COMPLEMENTAR': {
            title: 'Complementar',
            modalidade: 'COMPLEMENTAR',
            informaFilhos: false,
            informaOrigem: false,
            informaDocumentos: true,
            listaEspera: false,
            restricoes: false,
            progressaoParcial: false,
            informaTurmaUnificada: true,
            tipo: 'vaga'
        },
        'AEE': {
            title: 'AEE',
            enumTurmas: 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO',
            informaFilhos: true,
            informaOrigem: false,
            informaDocumentos: false,
            informaTurmaUnificada: false,
            atividade: true,
            tipo: 'atividade'
        },
        'ATIVIDADE_COMPLEMENTAR': {
            title: 'Atividade Complementar',
            enumTurmas: 'ATIVIDADE_COMPLEMENTAR',
            informaFilhos: true,
            informaOrigem: false,
            informaDocumentos: false,
            informaTurmaUnificada: false,
            atividade: true,
            tipo: 'atividade'
        },
        'PROGRESSAO_PARCIAL': {
            title: 'Progressão Parcial',
            modalidade: 'EDUCACAO_BASICA',
            informaFilhos: true,
            informaOrigem: false,
            informaDocumentos: false,
            informaTurmaUnificada: false,
            tipo: 'vaga'
        },
        'ACELERACAO': {
            title: 'Aceleração',
            modalidade: 'ACELERACAO',
            informaFilhos: false,
            informaOrigem: true,
            informaDocumentos: true,
            informaTurmaUnificada: true,
            listaEspera: false,
            restricoes: false,
            progressaoParcial: true,
            tipo: 'vaga'
        },
        'EJA': {
            title: 'EJA',
            modalidade: 'EJA',
            informaOrigem: false,
            informaFilhos: false,
            informaDocumentos: true,
            informaTurmaUnificada: true,
            listaEspera: false,
            restricoes: false,
            progressaoParcial: true,
            tipo: 'eja'
        }
    });


})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaFormulario', appConfiguracaoListaEsperaFormulario);

    function appConfiguracaoListaEsperaFormulario() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-formulario/app-configuracao-lista-espera-formulario.directive.html',
            scope: {},
            bindToController: {
                configuracao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
        'ui.components.Modal'
    ];

    function Controller(ConfigListaEsperaService, uiModal) {
        var vm = this;

        vm.options = { camposInscricao: undefined };
        vm.openViewForm = openViewForm;

        init();

        function init() {
            setCamposFixos();
            ConfigListaEsperaService.getCamposFormulario().then(function (data) {
                vm.options.camposInscricao = data;
            });
        }

        function openViewForm() {
            uiModal.open({
                templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-formulario/visualiza-formulario.modal.html',
                controller: 'educacao.lista-espera-novo.configuracao.VisualizaFormularioController',
                controllerAs: 'vm',
                size:'xxl',
                params: {
                    configuracao: vm.configuracao
                }
            });
        }


        function setCamposFixos() {
            vm.camposFixos = [
                {
                    'id': null,
                    'campo': 'DATA_NASC',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                },
                {
                    'id': null,
                    'campo': 'NOME',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                },
                {
                    'id': null,
                    'campo': 'SEXO',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                },
                {
                    'id': null,
                    'campo': 'FILIACAO',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                }
            ];
        }
    }

})();

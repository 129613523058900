(function() {
	'use strict';

	angular.module('educacao.pessoas')
		.controller('educacao.pessoas.MedicaoAntropometricaModalController', MedicaoAntropometricaModalController);

	MedicaoAntropometricaModalController.$inject = ['$modalInstance', '$scope'];

	function MedicaoAntropometricaModalController($modalInstance, $scope) {
		var vm = this;

		vm.close = close;
		vm.edit = edit;
		vm.save = save;
		vm.remove = remove;

		vm.medicoes = $modalInstance.params.medicoes || [];
		vm.nascimento = $modalInstance.params.nascimento || [];

		$scope.$watch('vm.medicao.alunoPresente', changeAlunoPresente);

		function changeAlunoPresente(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			if (_.isBoolean(newValue) && !newValue) {
				vm.medicao.peso = null;
				vm.medicao.estatura = null;
			}
		}

		vm.sortableOptions = {
			placeholder: 'ui-card-sortable',
			cursor: 'move',
			axis: 'y',
			containment: '#ui-containment',
			forcePlaceholderSize: true,
			tolerance: 'pointer'
		};

		function close() {
			$modalInstance.close(vm.medicoes);
		}

		function edit($index) {
			vm.medicao = _.cloneDeep(vm.medicoes[$index]);
			vm.indexToEdit = $index;
			vm.editando = true;
		}

		function save() {
			if (vm.editando) {
				vm.medicoes.splice(vm.indexToEdit, 1);
			}
			vm.medicoes.push(vm.medicao);
			vm.medicoes = _.sortBy(vm.medicoes, 'dataMedicao').reverse();
			clear();
		}

		function remove($index) {
			vm.medicoes.splice($index, 1);
		}

		function clear() {
			vm.medicao = null;
			vm.indexToEdit = null;
			vm.editando = false;

			vm.form.$setPristine();
			vm.form.$setUntouched();
		}
	}
})();

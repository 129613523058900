(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .directive('listagemAlunosRematricula', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'rematricula/listagem-alunos-rematricula/listagem-alunos-rematricula.directive.html',
            scope: {
                filtroPesquisado: '=',
                turma: '=',
                modalTracker: '=',
                estabelecimento: '='
            },
            bindToController: {
                situacoesMatricula: '=',
                solicitacaoRematricula: '=',
                filtroSolicitacao: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.rematricula.RematriculaService',
        'promiseTracker'
    ];

    function Controller($scope, RematriculaService, promiseTracker) {

        var vm = this;

        vm.openGroup = openGroup;
        vm.onSelectTurma = onSelectTurma;
        vm.onSelectAluno = onSelectAluno;

        vm.tracker = {
            getMatriculas: promiseTracker()
        };

        function openGroup(turma) {
            $scope.collapse = !$scope.collapse;

            if (!turma.alreadyOpen) {
                turma.alreadyOpen = true;

                vm.tracker.getMatriculas.addPromise(getMatriculas(turma));
            }
        }

        function onSelectTurma(turma, $event) {

            if (!turma.alreadyOpen) {
                var promise = getMatriculas(turma);

                $scope.modalTracker.addPromise(promise);
                vm.tracker.getMatriculas.addPromise(promise);

                turma.alreadyOpen = true;
            } else {
                _.forEach(turma.matriculas, function(matricula) {
                    matricula.checked = turma.checked;
                });

                countAlunosSelecionados(turma);
            }

            $event.stopPropagation();
        }

        function onSelectAluno(turma) {
            countAlunosSelecionados(turma);
        }

        function countAlunosSelecionados(turma) {
            turma.$$matriculasSelecionadas = _.countBy(turma.matriculas, 'checked').true;
        }

        function getMatriculas(turma) {
            return RematriculaService.getAlunosRematriculas(turma, _.get($scope.filtroPesquisado, 'situacoes'),$scope.estabelecimento, vm.filtroSolicitacao)
                .then(function(matriculas) {
                    turma.matriculas = matriculas.content;

                    _.forEach(turma.matriculas, function(matricula) {
                        matricula.checked = turma.checked;
                    });

                    countAlunosSelecionados(turma);
                });
        }
    }

})();


(function () {
	'use strict';

	var moduloCommon = angular.module('educacao.matriz');

	moduloCommon.factory('educacao.matriz.EtapaDisciplinaService', ['Restangular', '$q',
		function (Restangular, $q) {
			var meta;

			function getMetadata(matriz, etapa) {
				var deferred = $q.defer();
				if (!meta) {
					Restangular
						.one('matriz')
						.one('matriz-curricular', matriz)
						.one('etapas', etapa)
						.all('disciplinas').options().then(function (data) {
							meta = data;
							deferred.resolve(meta);
						}, function (data) {
							deferred.reject(data);
						});
				} else {
					deferred.resolve(meta);
				}
				return deferred.promise;
			}

			function list(matriz, etapa) {
				return Restangular.one('matriz')
					.one('matriz-curricular', matriz)
					.one('etapas', etapa)
					.all('disciplinas');

			}

			return {
				get: function (matriz, etapa, id) {
					return Restangular.one('matriz')
						.one('matriz-curricular', matriz)
						.one('etapas', etapa)
						.one('disciplinas', id).get();
				},
				getList: function (matriz, etapa, filter) {
					if (filter) {
						return list(matriz, etapa)
							.getList({
								filter: filter
							});
					}
					return list(matriz, etapa).getList();
				},
				getDefault: function (matriz, etapa) {
					return getMetadata(matriz, etapa).then(function (data) {
						return angular.copy(data.representation.default);
					});
				},
				getTypes: function (matriz, etapa) {
					return getMetadata(matriz, etapa).then(function (data) {
						return angular.copy(data.types);
					});
				},
				save: function (matriz, etapa, data) {
					var rest = Restangular.one('matriz')
						.one('matriz-curricular', matriz)
						.one('etapas', etapa)
						.all('disciplinas');
					return !data.id ?
						rest.post(data) :
						rest.customPUT(data, data.id);
				},
				remove: function (matriz, etapa, id) {
					return Restangular.one('matriz')
						.one('matriz-curricular', matriz)
						.one('etapas', etapa)
						.one('disciplinas', id).remove();
				},
				readOnly: function () {
					var deferred = $q.defer();
					deferred.resolve(false);
					return deferred.promise;
				},
				camposAdicionais: function camposAdicionais(matriz, etapa, id){
						return Restangular.one('matriz/matriz-curricular/' + matriz + '/etapas/' + etapa + '/disciplinas/campos-adicionais', id)
							.get()
							.then(Restangular.stripRestangular);
					}
			};
		}
	]);
})();
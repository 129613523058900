( function() {
    'use strict';

    angular.module('educacao.planejamento').controller('educacao.planejamento.TiposDependenciasCadCtrl', TiposDependenciasCadCtrl);

    TiposDependenciasCadCtrl.$inject = ['educacao.planejamento.TiposDependenciasService',
                                        '$modalTracker',
                                        '$q',
                                        'id',
                                        'optionsCad',
                                        'readOnly',
                                        'promiseTracker',
                                        'fnCad',
                                        '$rootScope',
                                        '$modalInstance',
                                        'bfc.Focus'];

    function TiposDependenciasCadCtrl(TiposDependenciasService,  $modalTracker, $q, id, optionsCad, readOnly, promiseTracker, fnCad, $rootScope, $modalInstance,focus) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.saveContinue = promiseTracker();
        vm.tracker.save = promiseTracker();
        vm.tracker.remove = promiseTracker();

        var modo = {
            adicionando: !id && !readOnly   ,
            adicionandoNovaOpcao: Boolean(fnCad),
            editando: id && !readOnly
        };

        var properties = {
            save: _save,
            cancel: _cancel,
            mode: modo,
            remove: _remove
        };


        init();

        function init() {
            $modalTracker.addPromise(
                   $q.all([
                      (function(){
                          if (vm.form) {
                              vm.form.$setPristine();
                          }
                      })(),

                      refresh()

                ]).then(
                    function postInit(){
                         angular.extend(vm, properties);
                         vm.windowTitle = modo.adicionando ? 'Adicionando tipo de dependência física' : 'Editando tipo de dependência física';
                    }
                )
            );

        }

        function refresh() {

           if (vm.form) {
                vm.form.$setPristine();
            }

            return Boolean(id) ?
                TiposDependenciasService.get(id).then(function(data) {
                    vm.tipo = data;
                    vm.tipo.id = id;
                }):

                TiposDependenciasService.getDefault().then(function(data) {
                    vm.tipo = angular.extend(data, optionsCad);
                });

        }

        function _save(continuar) {
            var tracker = continuar ? vm.tracker.saveContinue : vm.tracker.save;
            tracker.addPromise(
                TiposDependenciasService.save(vm.tipo).then(function(data){
                    if(angular.isFunction(fnCad)){
                        fnCad(data);
                    }

                    $rootScope.$broadcast('refreshTiposDependencias', {
                        data: data,
                        add: !id,
                        edit: Boolean(id)
                    });

                    onSuccess(continuar, tracker);
                })
            );

        }

        function _remove(){
            vm.tracker.remove.addPromise(
                TiposDependenciasService.remove(vm.tipo.id).then(function() {
                    $rootScope.$broadcast('refreshTiposDependencias', {
                        data: vm.tipo,
                        remove: true
                    });

                    onSuccess();
                })
            );
        }


        function onSuccess(continuar, tracker) {
            if (continuar) {
                id = null;
                tracker.addPromise(refresh());
                focus('lbDescricaoTipoDependencia');
            } else {
                _cancel();
            }
        }

        function _cancel() {
            $modalInstance.close();
        }

    }

})();
 (function() {
     'use strict';

     var AGENDA_VIEW_WEEK = 'agendaWeek';
     var AGENDA_VIEW_MONTH = 'month';
     var AGENDA_VIEW_DAY = 'agendaDay';
     var AGENDA_VIEW_EVENTS = 'events';

     angular.module('educacao.agendaprofessor')
         .service('ViewProfessorController', ViewProfessorController);

     function ViewProfessorController() {
         return Contructor;
     }

     function Contructor() {
         var vm = this;

         vm.currentView = AGENDA_VIEW_WEEK;

         vm.isWeekView = isWeekView;
         vm.goToWeekView = goToWeekView;

         vm.isMonthView = isMonthView;
         vm.goToMonthView = goToMonthView;

         vm.isEventsView = isEventsView;
         vm.goToEventsView = goToEventsView;

         vm.isDayView = isDayView;
         vm.goToDay = goToDay;


         function isDayView() {
             return vm.currentView === AGENDA_VIEW_DAY;
         }

         function goToDay() {
             vm.currentView = AGENDA_VIEW_DAY;
         }

         function isWeekView() {
             return vm.currentView === AGENDA_VIEW_WEEK;
         }

         function goToWeekView() {
             vm.currentView = AGENDA_VIEW_WEEK;
         }

         function isMonthView() {
             return vm.currentView === AGENDA_VIEW_MONTH;
         }

         function goToMonthView() {
             vm.currentView = AGENDA_VIEW_MONTH;
         }

         function isEventsView() {
             return vm.currentView === AGENDA_VIEW_EVENTS;
         }

         function goToEventsView() {
             vm.currentView = AGENDA_VIEW_EVENTS;
         }
     }
 })();

(function () {
    'use strict';

    angular.module('educacao.restricao-alimentar')
        .factory('educacao.restricao-alimentar.NutrienteService', NutrienteService);

    NutrienteService.$inject = ['Restangular'];

    function NutrienteService(Restangular) {

        return {
            list: list
        };

        function list(params) {

            return Restangular.all('nutriente').get('', {
                offset: params.offset,
                limit: params.limit,
                filter: params.filter
            });
        
        }
    
    }

})();

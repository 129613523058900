(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .service('educacao.desempenhoescolar.TesteService', TesteService);

    function TesteService() {
        var self = this;

        self.getItensAvaliaveisTurma = getItensAvaliaveisTurma;

        function getItensAvaliaveisTurma() {
            return {
                'content': [
                    {
                        'id': 1332,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 71,
                        'descricao': '1º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 71,
                            'dataInicial': '2016-02-01',
                            'dataFinal': '2016-04-08',
                            'descricao': '1º outra coisa'
                        }
                    },
                    {
                        'id': 1333,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'CONCEITO_NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 71,
                        'descricao': '1º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 71,
                            'dataInicial': '2016-02-01',
                            'dataFinal': '2016-04-08',
                            'descricao': '1º outra coisa'
                        }
                    },
                    {
                        'id': 1334,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 72,
                        'descricao': '2º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 72,
                            'dataInicial': '2016-04-11',
                            'dataFinal': '2016-04-12',
                            'descricao': '2º outra coisa'
                        }
                    },
                    {
                        'id': 1335,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 72,
                        'descricao': '2º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 72,
                            'dataInicial': '2016-04-11',
                            'dataFinal': '2016-04-12',
                            'descricao': '2º outra coisa'
                        }
                    },
                    {
                        'id': 1336,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 73,
                        'descricao': '3º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 73,
                            'dataInicial': '2016-04-13',
                            'dataFinal': '2016-04-20',
                            'descricao': '3º outra coisa'
                        }
                    },
                    {
                        'id': 1337,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 73,
                        'descricao': '3º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 73,
                            'dataInicial': '2016-04-13',
                            'dataFinal': '2016-04-20',
                            'descricao': '3º outra coisa'
                        }
                    },
                    {
                        'id': 1338,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 74,
                        'descricao': '4º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 74,
                            'dataInicial': '2016-05-01',
                            'dataFinal': '2016-06-10',
                            'descricao': '4º outra coisa'
                        }
                    },
                    {
                        'id': 1339,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 74,
                        'descricao': '4º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 74,
                            'dataInicial': '2016-05-01',
                            'dataFinal': '2016-06-10',
                            'descricao': '4º outra coisa'
                        }
                    },
                    {
                        'id': 1341,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'DESCRITIVO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 'CALCULO',
                        'descricao': 'Cálculo',
                        'tipoPeriodo': 'CALCULO',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1342,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 'NOTA_PARA_EXAME',
                        'descricao': 'Nota para exame',
                        'tipoPeriodo': 'NOTA_PARA_EXAME',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1343,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'data':'2016-03-01',
                        'idPeriodo': 'EXAME_FINAL_1',
                        'descricao': 'Exame final 1',
                        'identificadorExameFinal': 1,
                        'tipoPeriodo': 'EXAME_FINAL',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1344,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'data':'2016-03-02',
                        'idPeriodo': 'EXAME_FINAL_2',
                        'descricao': 'Exame final 2',
                        'identificadorExameFinal': 2,
                        'tipoPeriodo': 'EXAME_FINAL',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1344,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'data':'2016-03-03',
                        'idPeriodo': 'NOTA_CONSELHO',
                        'descricao': 'Conselho',
                        'tipoPeriodo': 'NOTA_CONSELHO',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1340,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'pt',
                            'id': 1097,
                            'descricao': 'Portgues'
                        },
                        'idPeriodo': 'MEDIA_FINAL',
                        'descricao': 'Média',
                        'tipoPeriodo': 'MEDIA_FINAL',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1500,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 71,
                        'descricao': '1º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 71,
                            'dataInicial': '2016-02-01',
                            'dataFinal': '2016-04-08',
                            'descricao': '1º outra coisa'
                        }
                    },
                    {
                        'id': 1501,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'CONCEITO_NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 71,
                        'descricao': '1º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 71,
                            'dataInicial': '2016-02-01',
                            'dataFinal': '2016-04-08',
                            'descricao': '1º outra coisa'
                        }
                    },
                    {
                        'id': 1502,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 72,
                        'descricao': '2º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 72,
                            'dataInicial': '2016-04-11',
                            'dataFinal': '2016-04-12',
                            'descricao': '2º outra coisa'
                        }
                    },
                    {
                        'id': 1503,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 72,
                        'descricao': '2º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 72,
                            'dataInicial': '2016-04-11',
                            'dataFinal': '2016-04-12',
                            'descricao': '2º outra coisa'
                        }
                    },
                    {
                        'id': 1504,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 73,
                        'descricao': '3º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 73,
                            'dataInicial': '2016-04-13',
                            'dataFinal': '2016-04-20',
                            'descricao': '3º outra coisa'
                        }
                    },
                    {
                        'id': 1505,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 73,
                        'descricao': '3º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 73,
                            'dataInicial': '2016-04-13',
                            'dataFinal': '2016-04-20',
                            'descricao': '3º outra coisa'
                        }
                    },
                    {
                        'id': 1506,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 74,
                        'descricao': '4º outra coisa',
                        'tipoPeriodo': 'INSTRUMENTO',
                        'periodoAvaliativo': {
                            'id': 74,
                            'dataInicial': '2016-05-01',
                            'dataFinal': '2016-06-10',
                            'descricao': '4º outra coisa'
                        }
                    },
                    {
                        'id': 1507,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 74,
                        'descricao': '4º outra coisa',
                        'tipoPeriodo': 'MEDIA_PERIODO',
                        'periodoAvaliativo': {
                            'id': 74,
                            'dataInicial': '2016-05-01',
                            'dataFinal': '2016-06-10',
                            'descricao': '4º outra coisa'
                        }
                    },
                    {
                        'id': 1508,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'DESCRITIVO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 'CALCULO',
                        'descricao': 'Cálculo',
                        'tipoPeriodo': 'CALCULO',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1509,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'DESCRITIVO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 'NOTA_PARA_EXAME',
                        'descricao': 'Nota para exame',
                        'tipoPeriodo': 'NOTA_PARA_EXAME',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1510,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'data':'2016-02-01',
                        'idPeriodo': 'EXAME_FINAL_1',
                        'descricao': 'Exame final 1',
                        'identificadorExameFinal': 1,
                        'tipoPeriodo': 'EXAME_FINAL',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1511,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'data':'2016-02-02',
                        'idPeriodo': 'EXAME_FINAL_2',
                        'descricao': 'Exame final 2',
                        'identificadorExameFinal': 2,
                        'tipoPeriodo': 'EXAME_FINAL',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1512,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'data':'2016-02-03',
                        'idPeriodo': 'NOTA_CONSELHO',
                        'descricao': 'Conselho',
                        'tipoPeriodo': 'NOTA_CONSELHO',
                        'periodoAvaliativo': null
                    },
                    {
                        'id': 1513,
                        'anoLetivo': null,
                        'estabelecimento': null,
                        'tipo': 'CONFIGURACAO_TURMA',
                        'modoAvaliacao': 'CONCEITO_NUMERICO',
                        'itemEducacional': {
                            'sigla': 'mat',
                            'id': 1098,
                            'descricao': 'Matemática'
                        },
                        'idPeriodo': 'MEDIA_FINAL',
                        'descricao': 'Média',
                        'tipoPeriodo': 'MEDIA_FINAL',
                        'periodoAvaliativo': null
                    }
                ],
                'offset': 0,
                'limit': 999,
                'total': 10,
                'hasNext': false
            };
        }
    }

})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('matriculaTipos', MatriculaTipos);

    function MatriculaTipos() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/matricula-tipo-directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                matricula: '=',
                enumTurno: '=',
                enumSituacao: '=',
                turnosPossiveis: '='
            }
        };
    }

    Controller.$inject = [
        '$injector',
        'promiseTracker',
        '$scope',
        'educacao.context.ContextoEstabelecimento',
        'TIPOS_MATRICULAS'
    ];

    function Controller($injector,
        promiseTracker,
        $scope,
        ContextoEstabelecimento,
        TIPOS_MATRICULAS) {

        var vm = this,
            MatriculaVagaService = $injector.get('educacao.matricula.MatriculaVagaService'),
            EncerramentoService = $injector.get('educacao.encerramento.EncerramentoService');

        vm.editando = $scope.matricula.id;

        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.tiposMatriculas = TIPOS_MATRICULAS;
        vm.exibeImputDocumentos = exibeImputDocumentos;
        vm.tracker = {
            loading: promiseTracker(),
            loadingDocumentos: promiseTracker()
        };
        vm.enumTurno = $scope.enumTurno;
        vm.enumSituacao = $scope.enumSituacao;
        vm.turnosPossiveis = $scope.turnosPossiveis;
        vm.formatResultTurma = formatResultTurma;
        vm.formatResultTurno = formatResultTurno;
        vm.formatResultTurnoFrequenta = formatResultTurnoFrequenta;
        vm.exigeAee = exigeAee;

        getEncerramentosTurma();

        $scope.$watch('matricula.estabelecimento', changeEstabelecimento);
        $scope.$watch('matricula.tipoMatricula', changeTipoMatricula);

        $scope.$watch('matricula.matriz', changeMatriz);
        $scope.$watch('matricula.etapaMatriz', changeEtapaMatriz);

        $scope.$watch('matricula.utilizarTransporteEscolar', changeUtilizaTransporteEscolar);
        $scope.$watch('matricula.origemMatricula', changeOrigemMatricula);
        $scope.$watch('matricula.transferenciaOrigem', changeTransferenciaMatricula);
        $scope.$watch('matricula.turma', changeTurma);

        function exibeImputDocumentos() {
            return isEducacaoInfantil() || isComplementar() || $scope.matricula.etapaMatriz || isModular();
        }

        function isEducacaoInfantil() {
            return _.get($scope.matricula.matriz, 'curso') && $scope.matricula.matriz.curso.nivelEscolar === 'EDUCACAO_INFANTIL';
        }

        function isModular() {
            return Boolean(_.get($scope.matricula, 'matriz.modular'));
        }

        function isComplementar() {
            return _.get($scope.matricula.matriz, 'curso') && $scope.matricula.matriz.curso.nivelModalidade === 'COMPLEMENTAR';
        }

        if (vm.hasEstabelecimento) {
            $scope.matricula.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        }
        vm.tracker.loading.addPromise(
            vm.getTipoMatricula = MatriculaVagaService.getTipoMatricula()
        );

        vm.tracker.loading.addPromise(
            vm.getVeiculoTransporteEscolar = MatriculaVagaService.getVeiculoTransporteEscolar()
        );

        vm.tracker.loading.addPromise(
            vm.getResponsavelTransporteEscolar = MatriculaVagaService.getResponsavelTransporteEscolar()
        );

        vm.tracker.loading.addPromise(
            vm.getEspacoDiferenciado = MatriculaVagaService.getEspacoDiferenciado()
        );

        function formatResultTurma(data) {
            if (vm.editando) {
                return data.descricao;
            }

            if ((_.isNull(data.quantidadeAlunos) || _.isUndefined(data.quantidadeAlunos)) ||
                (!_.get(data, 'quantidadeMaximaAlunos'))) {
                return;
            }

            return data.descricao + ' ' + '(' + data.quantidadeAlunos + '/' + data.quantidadeMaximaAlunos + ' alunos)';
        }

        function formatResultTurno(data) {
            if (vm.editando) {
                return _.capitalize((data.turno || '').toLowerCase());
            }

            return _.capitalize((data.turno || '').toLowerCase()) + ' ' + '(' + data.qtdVagasOcupadas + '/' + data.qtdVagas + ' vagas)';
        }

        function formatResultTurnoFrequenta(data) {
            if (vm.editando) {
                return _.capitalize((data.turno || '').toLowerCase());
            }

            return _.capitalize((data.turno || '').toLowerCase()) + ' ' + '(' + data.qtdVagasOcupadas + '/' + data.qtdVagas + ' vagas)';
        }

        function changeMatriz(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            $scope.matricula.documentos = [];
            $scope.matricula.filhos = [];
        }

        function changeEtapaMatriz(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            if (_.get($scope.matricula.matriz, 'curso') && $scope.matricula.matriz.nivelEscolar !== 'EDUCACAO_INFANTIL') {
                $scope.matricula.documentos = [];
            }
        }

        function changeEstabelecimento(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            limpaMatriz();
        }

        function changeTipoMatricula(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            delete $scope.matricula.$$listaFilhos;
        }

        function changeUtilizaTransporteEscolar(value) {
            if (!value) {
                delete $scope.matricula.responsavelTransporteEscolar;
                delete $scope.matricula.veiculos;
            }
        }

        function changeOrigemMatricula(value) {
            if (!value) {
                delete $scope.matricula.tipoClassificacao;
                delete $scope.matricula.tipoReclassificacao;
            }
        }

        function changeTransferenciaMatricula(newValue, oldValue) {
            if (_.isEqual(oldValue, newValue)) {
                return;
            }
            delete $scope.matricula.nomeEstabelecimentoOrigem;
            delete $scope.matricula.estabelecimentoOrigem;
        }

        function changeTurma(newValue, oldValue) {
            if (_.isEqual(oldValue, newValue)) {
                return;
            }
            getEncerramentosTurma();
        }

        function limpaMatriz() {
            delete $scope.matricula.matriz;
            delete $scope.matricula.etapaMatriz;
            delete $scope.matricula.turma;
            delete $scope.matricula.documentos;
        }

        function exigeAee() {
            var aluno = $scope.matricula.aluno;
            return alunoHasDeficiencias(aluno) || alunoHasTranstornosGlocais(aluno) || aluno.possuiSuperdotacao;
        }

        function alunoHasDeficiencias(aluno) {
            return aluno.hasDeficiencias || _.get(aluno, 'alunoDeficiencias.length');
        }

        function alunoHasTranstornosGlocais(aluno) {
            return aluno.hasTranstornosGlobais || _.get(aluno, 'transtornosGlobais.length');
        }

        function getEncerramentosTurma() {
            if(_.isUndefined($scope.matricula.isHabilitadoCenso) || _.isNull($scope.matricula.isHabilitadoCenso)){
                $scope.matricula.isHabilitadoCenso = true;
            }

            if (!_.get($scope.matricula, 'turma.id')) {
                return;
            }
            vm.tracker.loading.addPromise(
                EncerramentoService.getIsTodosPeriodosAvaliativosEncerradosByTurma($scope.matricula.turma).then(setEncerramentosTurma)
            );
        }

        function setEncerramentosTurma(data) {
            vm.hasEncerramentos = _.get(data, 'isTodosPeriodosAvaliativosEncerrados');
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MovimentacaoGenericaModalCtrl', MovimentacaoGenericaModalCtrl);

    MovimentacaoGenericaModalCtrl.$inject = [
        '$scope', 
        '$modalInstance', 
        'educacao.common.$commons', 
        'educacao.matricula.MotivoMovimentacaoService',
        'educacao.matricula.MatriculaService'
    ];

    function MovimentacaoGenericaModalCtrl($scope, $modalInstance, $commons, MotivoMovimentacaoService, MatriculaService) {

        var vm = this;

        var tipo = _.get($modalInstance, 'params.tipoMovimentacao');
        vm.matricula = _.get($modalInstance, 'params.matricula');
        vm.matriculaId = _.get($modalInstance, 'params.matriculaId');
        vm.matriculaPai = _.get($modalInstance, 'params.matriculaPai');
        vm.movimentacaoId = _.get($modalInstance, 'params.idMovimentacao');
        vm.tiposMovimentacao = _.get($modalInstance, 'params.tiposMovimentacao') || [];
        vm.movimentacao = {
            tipo: tipo
        };

        vm.movimentar = movimentar;
        vm.isMovimentacaoEjaModular = isMovimentacaoEjaModular;
        
        $scope.$watchGroup(['vm.movimentacao.tipo', 'vm.movimentacao.motivo.solicitaEstabelecimento'], onMovimentacaoChange);
        $scope.$watch('vm.movimentacao.motivo', onChangeMotivo);

        activate();

        function onChangeMotivo(newValue, oldValue){
            if(newValue === oldValue || oldValue === undefined){
                return;
            }
            vm.movimentacao.estabelecimentoDestino = undefined;
            vm.movimentacao.nomeEstabelecimentoDestino = undefined;
            vm.movimentacao.municipio = undefined;
            
        }

        function activate() {
            $modalInstance.tracker.addPromise(
                MotivoMovimentacaoService.getTipoMovimentacao().then(thenTipoMovimentacao)
            );

            if (vm.matricula) {
                return;
            }

            if (!vm.matriculaId) {
                return;
            }

            $modalInstance.tracker.addPromise(
                MatriculaService.get(vm.matriculaId).then(thenMatricula)
            );

            if (vm.movimentacaoId) {
                $modalInstance.tracker.addPromise(
                    MatriculaService.getMovimento(vm.matriculaId, vm.movimentacaoId).then(thenMovimento)
                );
            }

            function thenTipoMovimentacao(tipos) {
                vm.windowTitle = tipos[tipo].description;
            }

            function thenMatricula(matricula) {
                vm.matricula = matricula;
            }

            function thenMovimento(movimentacao) {
                vm.movimentacao = movimentacao;
            }
        }

        function onMovimentacaoChange() {
            if (vm.movimentacao.tipo === 'FALECIMENTO') {
                vm.tamanhoColunaMovito = 0;
                return;
            }
            var solicitaEstabelecimento = _.get(vm.movimentacao, 'motivo.solicitaEstabelecimento');
            if (!solicitaEstabelecimento || solicitaEstabelecimento === 'NAO') {
                vm.tamanhoColunaMovito = 10;
            } else {
                vm.tamanhoColunaMovito = 5;
            }
        }

        function movimentar() {
            var matriculaPaiId = _.get(vm.matriculaPai, 'id') || vm.matricula.id;

            if (isMovimentacaoEjaModular()) {
                var matriculasId = _.pluck(vm.matriculas, 'id');

                $modalInstance.tracker.addPromise(
                    MatriculaService.movimentarEjaModular(matriculaPaiId, matriculasId, vm.movimentacao).then(thenMovimentacao)
                );

                return;
            }

            $modalInstance.tracker.addPromise(
                MatriculaService.movimentar(matriculaPaiId, vm.movimentacao).then(thenMovimentacao)
            );

            function thenMovimentacao() {
                $commons.$notification.publish('Movimentação realizada com sucesso!', 'success');
                $modalInstance.close();
            }
        }

        function isMovimentacaoEjaModular() {
            return (
                (_.get(vm.matricula, 'tipoMatricula') === 'EJA' || _.get(vm.matriculaPai, 'tipoMatricula') === 'EJA') && 
                (_.get(vm.matricula, 'matriz.modular') || _.get(vm.matriculaPai, 'matriz.modular')) && 
                ( 
                    vm.movimentacao.tipo === 'CANCELAMENTO' || 
                    vm.movimentacao.tipo === 'TRANSFERENCIA' || 
                    vm.movimentacao.tipo === 'DEIXOU_DE_FREQUENTAR' 
                )
            );
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('userInfo', UserInfo);

    function UserInfo() {
        return {
            restrict: 'E',
            templateUrl: 'common/layout/header/user-info/user-info.directive.html',
            replace: true,

            scope: {},

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'URL_CENTRAL_USUARIO',
        '$window',
        'bfc.authentication.AuthenticationContext',
        'AuthenticationService'
    ];

    function Controller(URL_CENTRAL_USUARIO,
                        $window,
                        AuthenticationContext,
                        AuthenticationService) {

        var vm = this;

        vm.userInfo = AuthenticationContext.getUserInfo();

        vm.sair = sair;
        vm.editaConta = editaConta;

        function sair() {
            AuthenticationService.logout();
        }

        function editaConta() {
            $window.open(URL_CENTRAL_USUARIO);
        }
    }
})();

(function () {
    
        'use strict';
    
        angular.module('educacao.frequencia')
            .directive('tabelaFrequenciaModular', Directive);
    
        function Directive() {
            return {
                templateUrl: 'frequencia/tabela-frequencia-modular.directive.html',
                restrict: 'E',
                scope: {},
                bindToController: {
                    ngModel: '=',
                    diario: '=',
                    ngChange: '&?',
                    turma: '=',
                    configuracao: '=',
                    searchFilter: '=',
                    headerFilterOptions: '=',
                    models: '=',
                    registros: '=',
                    searchRegistros: '='
                },
                controller: Controller,
                controllerAs: 'vm'
            };
        }
    
        Controller.$inject = [
            '$rootScope',
            '$filter',
            'ui.components.Modal',
            'bfc.Notification',
            '$scope',
            'promiseTracker',
            'bfc.PromiseTracker',
            'educacao.frequencia.FrequenciaService',
            'educacao.abonojustificativa.AbonoJustificativaService',
            'educacao.common.ObjectUtilsService',
            '$searchExpressionBuilder',
            'educacao.encerramento.EncerramentoService',
            'bfc.$$PermissionsService',
            'educacao.encerramento.EncerramentoUtilsService',
            'HeaderFilterBuilderService',
            'EdEnumsCacheService'
        ];
    
        function Controller(
            $rootScope,
            $filter,
            $modal,
            bfcNotification,
            $scope,
            promiseTracker,
            PromiseTracker,
            frequenciaService,
            AbonoJustificativaService,
            ObjectUtilsService,
            $searchExpressionBuilder,
            EncerramentoService,
            $$PermissionsService,
            EncerramentoUtilsService,
            HeaderFilterBuilderService,
            EdEnumsCacheService) {
            var vm = this,
                diarioOld;
    
            vm.columns = [
                {
                    id: 'nomeAluno',
                    label: 'Aluno(a)',
                    model: 'nome',
                    filterable: true
                }];
            inicializa();
    
            function inicializa() {
                inicializaPropriedades();
                inicializaLoadings();
                EdEnumsCacheService.get('SituacaoMatricula').then(function(result) {
                    vm.enumSituacaoMatricula = result;
                });
                _.set(vm.searchFilter, 'others.situacaoMatricula', ['CURSANDO']);
                _.set(vm.searchFilter, 'others.situacaoMatriculaPai', ['CURSANDO']);
            }
    
            function inicializaPropriedades() {
                vm.frequencia = {};
                vm.filtered = [];
                vm.tempIdTurma = [];
                vm.indexTurma = 0;
                vm.faltasTotal = 0;
                vm.predicate = 'enturmacao.matricula.nome';
                vm.order = order;
                vm.reverse = false;
                vm.openOneTime = false;
                vm.trocandoPeriodo = false;
                vm.itensEducacionais = {};
                vm.getDescricaoPeriodoAvaliativo = getDescricaoPeriodoAvaliativo;
                vm.getModulosTurma = getModulosTurma;
                vm.navegaEntrePeriodo = navegaEntrePeriodo;
                vm.setFaltaAluno = setFaltaAluno;
                vm.searchRegistros = searchRegistros;
                vm.getConfiguracaoFrequencia = getConfiguracaoFrequencia;
                vm.openModalAbonoJustificativa = openModalAbonoJustificativa;
                vm.getDescricaoPeriodo = getDescricaoPeriodo;
                vm.addAcompanhamento = addAcompanhamento;
                vm.orderBy = orderBy;
                vm.tracker = promiseTracker();
                vm.permissions = !$$PermissionsService.isRevokedOperation('RegistroFrequenciaPageMapping', 'alterarFrequenciaPeriodoAvaliativoEncerrado');
                vm.permissionsAbono = !$$PermissionsService.isRevokedOperation('AbonoFaltaPageMapping', 'informarAbonoPeriodoEncerrado');
                vm.topFloatHeader = $rootScope.$$pinned ? '78' : '44';
            }
    
            $scope.$watch('vm.turma', getItensEducacionais);
    
            function inicializaLoadings() {
                vm.loadings = PromiseTracker.create(['frequenciaPeriodos', 'salvarModular']);
            }

            // verifica se houve alteração na fixação do contexto
            $scope.$watch('$root.$$pinned', function(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                vm.topFloatHeader = newValue ? '78' : '44';
            });
    
            vm.sortBy = {
                expression: 'enturmacao.numeroChamada',
                reverse: false
            };
    
            function getItensEducacionais() {
                if (!_.get(vm.turma, 'id') || !_.get(vm.turma, 'etapaMatriz.modular')) {
                    return;
                }
                vm.serviceAbono = AbonoJustificativaService.buildService(vm.turma.id);
                if (vm.turma !== undefined) {
                    vm.disciplinaAtual = undefined;
                    var promise = frequenciaService.getItensEducacionais(vm.turma.id);
                    promise.then(function (data) {
                        vm.itensEducacionais = data;
                        createFilterDisciplina(data);
                        createFilterSituacao();
                    });
                    vm.tracker.addPromise(promise);
                }
                loadPublicados();
                getEncerramentosTurma(vm.turma);
            }
    
            function getEncerramentosTurma() {
                EncerramentoService.getEncerramentosByTurma(vm.turma)
                    .then(function (dado) {
                        vm.encerramentosTurma = dado;
                        vm.disabled = false;
                        vm.disabledAbono = false;
                        if (EncerramentoUtilsService.isStatusEncerrado(_.first(vm.encerramentosTurma))) {
                            vm.disabled = !vm.permissions;
                            vm.disabledAbono = !vm.permissionsAbono;
                        }
                    });
            }
    
            function getConfiguracaoFrequencia(itemEducacional) {
                vm.disciplinaAtual = itemEducacional;
                if (vm.disciplinaAtual !== undefined) {
                    getModulosTurma(itemEducacional.id);
                }

                $('table.ed-float-thead').floatThead('reflow');
            }
    
            function getDescricaoPeriodo(itemEducacional) {
                var dateFormat = 'dd/MM/yyyy';
                if (itemEducacional.dataInicio && itemEducacional.dataFim) {
                    return itemEducacional.descricao +
                        ' (' + $filter('date')(itemEducacional.dataInicio, dateFormat) + ' até ' + $filter('date')(itemEducacional.dataFim,
                            dateFormat) + ')';
                } else {
                    return itemEducacional.descricao;
                }
            }
    
            function addRegistroFaltaPeriodoAvaliativo(registro, itemEducacional, key) {
                vm.registros[key].registrosFaltasEjaModular.push({
                    id: null,
                    quantidadeFaltas: null,
                    enturmacao: {
                        id: registro.enturmacao.id
                    },
                    itemEducacional: {
                        id: itemEducacional.id
                    }
                });
            }
    
            function addRegistroFaltaTotalModulos(registro, itemEducacional, key) {
                vm.registros[key].registroFaltas = {
                    quantidadeFaltas: null,
                    itemEducacional: {
                        id: itemEducacional
                    },
                    enturmacao: {
                        id: registro.enturmacao.id
                    }
                };
            }
    
            function addResultadoFrequencia(key) {
                vm.registros[key].resultadoFrequencia = {
                    id: null,
                    percentual: 100,
                    faltas: null
                };
            }
    
            function getModulosTurma(idItemEducacional) {
                var promise = frequenciaService.getModulosTurma(idItemEducacional);
                promise.then(function (data) {
                    vm.modulosTurma = data;
                    vm.idItemEducacionalAtual = idItemEducacional;
                    searchRegistros(idItemEducacional);
                });
                vm.tracker.addPromise(promise);
            }
    
            function getDescricaoPeriodoAvaliativo(itemEducacional) {
                if (itemEducacional.length <= 0) {
                    return;
                }
                var periodo = itemEducacional;
                var format = 'dd/MM/yyyy';
                if (!periodo) {
                    return;
                }
                return periodo.descricao + ' (' + $filter('date')(periodo.dataInicial, format) + ' até ' + $filter('date')(periodo.dataFinal,
                    format) + ')';
            }
    
            function salvaUltimoValorFaltaTotal(falta) {
                var faltaAtualTotal = _.find(diarioOld, function (obj) {
                    return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
                });
                vm.valorAntigoFaltaTotal = faltaAtualTotal.resultadoFrequencia.faltas;
            }
    
            function salvaUltimoValorFalta(falta) {
                var faltaAtual = _.find(diarioOld, function (obj) {
                    return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
                });
                var faltaAtualFinal = _.find(faltaAtual.registrosFaltasEjaModular, function (obj) {
                    return parseInt(obj.id) === falta.id;
                });
                if (faltaAtualFinal !== undefined) {
                    vm.valorAntigoFalta = faltaAtualFinal.quantidadeFaltas;
                } else {
                    vm.valorAntigoFalta = '';
                }
            }
    
            function list(enturmacao) {
                var filter = '';
                var append = '';
                if (enturmacao) {
                    filter += append + 'enturmacao = ' + enturmacao || -1;
                }
                return vm.serviceAbono.completos({filter: filter}).then(function (dados) {
                    vm.abonosJustificativas = _.first(dados).content;
                    return vm.abonosJustificativas;
                });
            }
    
            function cadastraTiposDeFalta(falta, index, tipo) {
                if (tipo === undefined) {
                    var idTurma = vm.turma.id;
                    if ((falta.id === null || falta.id === '') && (falta.quantidadeFaltas !== null)) {
                        salvaUltimoValorFalta(falta);
                        cadastraUnicaFaltaAula(idTurma, falta, index);
                    } else if ((falta.id !== null || falta.id !== '') && (falta.quantidadeFaltas === '' || parseInt(falta.quantidadeFaltas) ===
                        0)) {
                        falta.quantidadeFaltas = null;
                        salvaUltimoValorFalta(falta);
                        var promise = frequenciaService.removeFrequenciaEja(falta.id);
                        promise.then(function (data) {
                            vm.openOneTime = true;
                            data.registroFaltasEjaModular.id = null;
                            data.registroFaltasEjaModular.quantidadeFaltas = null;
                            setRetornoAlunos(falta, data, index);
                            diarioOld = angular.copy(vm.registros);
                        }, function () {
                            voltaValorAntigoFaltaModulo(falta);
                        });
                        vm.loadings.salvarModular.addPromise(promise);
                    } else {
                        vm.openOneTime = true;
                        atualizaFaltaAula(falta.id, idTurma, falta, index);
                    }
                } else {
                    if ((parseInt(falta.quantidadeFaltas) === 0 || falta.quantidadeFaltas === null || falta.quantidadeFaltas === '') &&
                        falta.id !==
                        undefined) {
                        salvaUltimoValorFaltaTotal(falta);
                        vm.openOneTime = true;
                        var promiseFrequencia = frequenciaService.removeFrequenciaEjaTotal(falta.id);
                        promiseFrequencia.then(function (data) {
                            setRetornoAlunos(falta, data, index, true);
                        }, function () {
                            falta.quantidadeFaltas = vm.valorAntigoFaltaTotal;
                        });
                        vm.loadings.salvarModular.addPromise(promiseFrequencia);
    
                    } else {
                        cadastraFaltaTotal(falta, index);
                    }
                }
    
            }
    
            function setFaltaAluno(falta, index, tipo) {
                var promiseAbonos = list(falta.enturmacao.id);
                promiseAbonos.then(function (data) {
                    vm.existeAbonoNaFrequencia = _.filter(data, function (obj) {
                        return falta.itemEducacional.id === obj.itemEducacional.id;
                    });
                    if (vm.existeAbonoNaFrequencia !== undefined) {
                        var soma = 0;
                        for (var i = 0; i < vm.existeAbonoNaFrequencia.length; i++) {
                            soma = soma + vm.existeAbonoNaFrequencia[i].quantidadeAbono;
                        }
                        if (falta.quantidadeFaltas < soma) {
                            salvaUltimoValorFalta(falta);
                            salvaUltimoValorFaltaTotal(falta);
                            voltaValorAntigoFaltaTotal(falta);
                            bfcNotification.publish(
                                'O número de faltas precisa ser maior ou igual a quantidade de Justificativa/Abono',
                                'error');
                        } else {
                            cadastraTiposDeFalta(falta, index, tipo);
                        }
                    } else {
                        cadastraTiposDeFalta(falta, index, tipo);
                    }
                });
            }
    
            function voltaValorAntigoFaltaModulo(falta) {
                if (vm.valorAntigoFalta !== undefined && vm.valorAntigoFalta !== '') {
                    var faltaAlteracaoValorRegistros = _.find(vm.registros, {
                        'enturmacao': {
                            'id': falta.enturmacao.id
                        }
                    });
                    var faltaAlteracaoValor = _.find(faltaAlteracaoValorRegistros.registrosFaltasEjaModular, {
                        'id': falta.id
                    });
                    faltaAlteracaoValor.quantidadeFaltas = vm.valorAntigoFalta;
                } else {
                    falta.quantidadeFaltas = null;
                }
    
            }
    
            function voltaValorAntigoFaltaTotal(falta) {
                var faltaAlteracaoValor = _.find(vm.registros, {
                    'registroFaltas': {
                        'id': falta.id
                    }
                });
                faltaAlteracaoValor.registroFaltas.quantidadeFaltas = vm.valorAntigoFaltaTotal;
            }
    
            function cadastraFaltaTotal(falta, index) {
                vm.openOneTime = true;
                salvaUltimoValorFaltaTotal(falta);
                if (falta.id) {
                    var promise;
                    if (vm.permissions) {
                        promise = frequenciaService.atualizaFrequenciaEjaTotalPeriodoEncerrado(falta.id, falta);
                    } else {
                        promise = frequenciaService.atualizaFrequenciaEjaTotal(falta.id, falta);
                    }
                    promise.then(function (data) {
                        vm.faltasTotal = falta;
                        vm.valorAntigoFaltaTotal = falta;
                        setRetornoAlunos(falta, data, index);
                        diarioOld = angular.copy(vm.registros);
                    }, function () {
                        voltaValorAntigoFaltaTotal(falta);
                    });
                    vm.loadings.salvarModular.addPromise(promise);
                } else {
                    var promiseSaveTotal;
                    if (vm.permissions) {
                        promiseSaveTotal = frequenciaService.saveFrequenciaEjaTotalPeriodoEncerrado(falta);
                    } else {
                        promiseSaveTotal = frequenciaService.saveFrequenciaEjaTotalPeriodoEncerrado(falta);
                    }
                    promiseSaveTotal.then(function (data) {
                        vm.faltasTotal = falta;
                        vm.valorAntigoFaltaTotal = falta;
                        setRetornoAlunos(falta, data, index, false, true);
                        diarioOld = angular.copy(vm.registros);
                    }, function () {
                        voltaValorAntigoFaltaTotal(falta);
                    });
                    vm.loadings.salvarModular.addPromise(promise);
                }
            }
    
            function atualizaFaltaAula(faltaId, idTurma, falta, index) {
                salvaUltimoValorFalta(falta);
                vm.openOneTime = true;
                var promise;
                if (vm.permissions) {
                    promise =  frequenciaService.atualizaFrequenciaEjaPeriodoEncerrado(faltaId, falta);
                } else {
                    promise = frequenciaService.atualizaFrequenciaEja(faltaId, falta);
                }
                promise.then(function (data) {
                    vm.valorAntigoFalta = falta.quantidadeFaltas;
                    setRetornoAlunos(falta, data, index);
                    diarioOld = angular.copy(vm.registros);
                }, function () {
                    voltaValorAntigoFaltaModulo(falta);
                    vm.openOneTime = false;
                });
                vm.loadings.salvarModular.addPromise(promise);
            }
    
            function cadastraUnicaFaltaAula(idTurma, falta, index) {
                if (falta.quantidadeFaltas === '') {
                    falta.quantidadeFaltas = null;
                }
                vm.openOneTime = true;
                var promise;
                if (vm.permissions) {
                    promise = frequenciaService.saveFrequenciaEjaPeriodoEncerrado(idTurma, falta);
                } else {
                    promise = frequenciaService.saveFrequenciaEja(idTurma, falta);
                }
    
                promise.then(function (data) {
                    vm.valorAntigoFalta = falta.quantidadeFaltas;
                    setRetornoAlunos(falta, data, index, false, true);
                    diarioOld = angular.copy(vm.registros);
                }, function () {
                    voltaValorAntigoFaltaModulo(falta);
                    vm.openOneTime = false;
                });
                vm.loadings.salvarModular.addPromise(promise);
            }
    
            function setRetornoAlunos(falta, retorno, index, deletando) {
                var diarioExist = _.find(vm.registros.plain(), function (obj) {
                    return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
                });
    
                var diarioOldExist = _.find(diarioOld, function (obj) {
                    return parseInt(obj.enturmacao.id) === falta.enturmacao.id;
                });
                diarioOldExist.registroFaltas = retorno.registroFaltas;
                if (angular.isDefined(diarioExist)) {
                    if (deletando) {
                        retorno.registroFaltas.id = '';
                        retorno.registroFaltas.quantidadeFaltas = '';
                    }
                    angular.copy(retorno.registroFaltas, diarioExist.registroFaltas);
                    angular.copy(retorno.resultadoFrequencia, diarioExist.resultadoFrequencia);
    
                    if (diarioOld[index]) {
                        angular.copy(diarioExist.resultadoFrequencia, diarioOld[index].resultadoFrequencia);
                    }
                    if (retorno.registroFaltasEjaModular) {
                        angular.copy(retorno.registroFaltasEjaModular, diarioExist.registrosFaltasEjaModular[index]);
                    }
                }
                vm.openOneTime = true;
            }
    
            function navegaEntrePeriodo(sentido) {
                var periodo = _.findIndex(vm.itensEducacionais, {
                    'id': vm.disciplinaAtual.id
                }),
                    idPeriodo,
                    periodoSelecionado,
                    indexPeriodo;
                if (sentido === 'proximo') {
                    indexPeriodo = periodo + 1;
                    if (indexPeriodo === vm.itensEducacionais.length) {
                        indexPeriodo = 0;
                    }
                } else if (sentido === 'anterior') {
                    if (periodo === 0) {
                        indexPeriodo = vm.itensEducacionais.length - 1;
                    } else {
                        indexPeriodo = periodo - 1;
                    }
                }
                idPeriodo = vm.itensEducacionais[indexPeriodo].id;
                periodoSelecionado = vm.itensEducacionais[indexPeriodo];
    
                getConfiguracaoFrequencia(periodoSelecionado);
                vm.openOneTime = false;
    
            }
    
            function atualizaTotaisEnturmacao(params) {
                var promise = frequenciaService.getTotaisEnturmacao(params.idTurma, params.idEnturmacao);
                promise.then(function (faltas) {
                    angular.extend(params.aluno.frequencia, faltas[0]);
                });
            }
    
            function loadPublicados() {
                frequenciaService.getPublicados(vm.turma.id).then(function (data) {
                    vm.publicados = data;
                });
            }
    
            function openModalAbonoJustificativa(aluno) {
                $modal.open({
                    templateUrl: 'abono-justificativa/abono-justificativa-list.html',
                    controller: 'educacao.abonojustificativa.AbonoJustificativaListCtrl',
                    controllerAs: 'vm',
                    size: 'xxl',
                    params: {
                        configuracoes: {
                            aluno: aluno,
                            itensEducacionais: vm.itensEducacionais,
                            configuracao: vm.configuracao,
                            turma: vm.turma,
                            periodosAvaliativos: vm.periodosTurma,
                            periodoAtual: vm.periodoAtual,
                            callbackTotais: atualizaTotaisEnturmacao
                        }
                    }
                });
            }
    
            function addAcompanhamento(aluno) {
                var acompanhamento = {
                    estabelecimento: vm.estabelecimento,
                    itemEducacional: vm.disciplinaAtual,
                    enturmacao: {
                        id: aluno.enturmacao.id,
                        nomeAluno: aluno.enturmacao.matricula.nome
                    },
                    matricula: {
                        id: aluno.enturmacao.matricula.id
                    },
                    turma: vm.turmaClone
                };
                $modal.open({
                    templateUrl: 'acompanhamento-pedagogico-frequencia.html',
                    controller: 'acompanhamentoPedagogico.AcompanhamentoPedagogicoFreqCtrl',
                    controllerAs: 'vm',
                    size: 'lg',
                    params: {
                        acompanhamento: angular.copy(acompanhamento)
                    }
                });
            }
    
            function order(predicate) {
                vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
                vm.predicate = predicate;
            }
    
            function orderBy(item) {
                if (!_.get(vm.sortBy, 'expression')) {
                    vm.sortBy.expression = 'enturmacao.numeroChamada';
                }
                if (vm.sortBy.expression === 'enturmacao.matricula.nome') {
                    return ObjectUtilsService.normalize(_.get(item, vm.sortBy.expression));
                }
                return _.get(item, vm.sortBy.expression);
            }
    
            function searchRegistros(idTurma) {
                idTurma = idTurma || _.get(vm.turma, 'id');
                if (!idTurma || !vm.idItemEducacionalAtual) {
                    return;
                }
                var promise = frequenciaService.getFrequenciaModular(vm.idItemEducacionalAtual, $searchExpressionBuilder.build(vm.searchFilter));
                promise.then(function (data) {
                    vm.registros = data;
                    vm.estabelecimento = vm.turma.estabelecimento;
                    vm.turmaClone = _.clone(vm.turma);
                    _.forEach(vm.registros, function (valueRegistro, key) {
                        if (valueRegistro.resultadoFrequencia === null) {
                            addResultadoFrequencia(key);
                        }
                        _.forEach(vm.modulosTurma, function (valueItemEducacional) {
                            if (valueRegistro.registroFaltas === null) {
                                addRegistroFaltaTotalModulos(valueRegistro, idTurma, key);
                            }
                            if (valueRegistro.registrosFaltasEjaModular) {
                                if (_.find(valueRegistro.registrosFaltasEjaModular, {
                                    'enturmacao': {
                                        'id': valueRegistro.enturmacao.id
                                    },
                                    'itemEducacional': {
                                        'id': valueItemEducacional.id
                                    }
                                }) === undefined) {
                                    addRegistroFaltaPeriodoAvaliativo(valueRegistro, valueItemEducacional, key);
                                }
                            }
                        });
                    });
                    diarioOld = angular.copy(vm.registros);
                });
                vm.tracker.addPromise(promise);
            }

            function createFilterSituacao() {
                function onClickPai(value) {
                    _.set(vm.searchFilter, 'others.situacaoMatriculaPai', value);
                }
                var options = {
                    id: 'situacaoPai',
                    defaultValues: ['CURSANDO'],
                    pathOrder: 'description',
                    filtrable: true,
                    label: 'Situação',
                    clickMethod: onClickPai,
                    pathDescription: 'description',
                    pathValue: 'id',
                    selectAll: true
                };
                function onClick(value) {
                    _.set(vm.searchFilter, 'others.situacaoMatricula', value);
                }
                var optionsPai = _.cloneDeep(options);
                _.set(optionsPai, 'id', 'situacao');
                _.set(optionsPai, 'clickMethod', onClick);
                _.set(optionsPai, 'label', 'Situação na disciplina');
                HeaderFilterBuilderService.createCheckbox(vm.enumSituacaoMatricula.values, vm.models, vm.headerFilterOptions, options);
                HeaderFilterBuilderService.createCheckbox(vm.enumSituacaoMatricula.values, vm.models, vm.headerFilterOptions, optionsPai);
            }

            function createFilterDisciplina(data) {
                vm.headerFilterOptions.clearOthers();
            
                var options = {
                    id: 'itensEducacionais',
                    description: 'Componente curricular',
                    filtrable: true,
                    formatDescription: getDescricaoPeriodo,
                    onClick: getConfiguracaoFrequencia,
                    hideClear: true
                };
                HeaderFilterBuilderService.createRadiobox(data, vm.headerFilterOptions, vm.models, options);
            }
    
        }
    })();
    
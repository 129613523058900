(function () {

    'use strict';

    angular.module('educacao.matricula').directive('appSelectEtapaMatrizEja', SelectEtapaMatrizEjaDirective);

    SelectEtapaMatrizEjaDirective.$inject = [];

    function SelectEtapaMatrizEjaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" ng-disabled="ngDisabled" search="vm.search" />',
            scope: {
                ngModel: '=?',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '=',
                matriz: '=',
                item: '=',
                dropdownCssClass: '=?'
            },

            compile: setMultiple,

            controller: SelectEtapaMatrizEjaController,
            controllerAs: 'vm'
        };
    }

    function setMultiple(element, attrs) {
        if (attrs.hasOwnProperty('multiple')) {
            element.find('ui-select').attr('multiple', 'true');
        }
    }

    SelectEtapaMatrizEjaController.$inject = ['$scope', 'educacao.matriz.EtapaService'];

    function SelectEtapaMatrizEjaController($scope, EtapaMatrizService) {

        var vm = this;

        vm.search = search;

        $scope.$watch('ngModel', function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange(newValue, oldValue, $scope.item);
            }
        });

        function search(params) {
            var term = decodeURI(params.term).replace(/%/g, ' ').trim();

            return EtapaMatrizService.getList($scope.matriz && $scope.matriz.id || '0')
                .then(function (list) {
                    var filtrados = _.filter(list, function (item) {
                        return item.descricao.toLowerCase().contains(term.toLowerCase());
                    });
                    
                    return {
                        content: filtrados,
                        hasNext: false
                    };
                });
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appAnexo', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/configuracao-inscricao/anexo-inscricao/app-anexo.directive.html',
            transclude: true,

            scope: {
                ngDisabled: '='
            },
            bindToController: {
                model: '=ngModel',
                uploadUrl: '=',
                formatos: '=',
                isUploading: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'Upload', 'appAnexoErrorMessages', 'appAnexoFileTypeIcons', 'URL_API', 'bfc.Notification',
		'promiseTracker'];

    function Controller($scope, Upload, appAnexoErrorMessages, appAnexoFileTypeIcons, URL_API, Notification, promiseTracker) {
        var vm = this;

        vm.isUploadInProgress = false;
        vm.acceptExpression = null;
        vm.acceptInfo = 'qualquer arquivo';

        vm.maxSizeInfo = Upload.defaults.ngfMaxSize;

        vm.uploadFile = uploadFile;
        vm.removeFile = removeFile;
        vm.formatterTags = formatterTags;
        vm.isUploading = isUploading;

        init();

        function init() {
            addRestrictPattern();
            vm.trackerUpload = promiseTracker();
        }

        $scope.$watch('vm.model', function(newValue) {
            vm.model = newValue;
        });

        $scope.$watchCollection('formatos', refreshFormatos);

        function removeFile() {
            vm.model = null;
        }

        function formatterTags(file) {
            return file.nome;
        }

        function uploadFile(files, invalidFiles) {

            _.forEach(invalidFiles, function(file) {
                extendFile(file).error(appAnexoErrorMessages[file.$error]);
                vm.model = null;
                Notification.publish(file.errorMessage, 'error');
            });

            _.forEach(files, function(file) {
                extendFile(file);
                upload(file);
                vm.model = file;
            });
        }

        function addRestrictPattern() {
            var value = 'PDF,DOC,DOCX,ODT,ODS,TXT,XLS,XLSX,JPG,JPEG,PNG';

            value = value.split(',');
            var formatos = [];
            _.map(value, function(data) {
                formatos.push({
                    pattern: '.' + data.toLowerCase(),
                    description: data.toUpperCase()
                });
            });
            vm.acceptExpression = _.map(formatos, 'pattern').join(',');
            vm.acceptInfo = _.map(formatos, 'description').join(', ');

        }

        function refreshFormatos(value) {
            var formatos = value;
            vm.formatos = [];

            if (_.get(formatos, 'length')) {
                formatos = _.isArray(formatos) ? formatos : formatos.split(',');

                _.map(formatos, function(data) {
                    vm.formatos.push({
                        pattern: '.' + data.toLowerCase(),
                        description: data.toUpperCase()
                    });
                });
            }
        }

        function upload(file) {
            vm.isUploadInProgress = true;

            vm.trackerUpload.addPromise(Upload.upload({
                    url: URL_API + '/commons/arquivos',
                    data: {
                        file: file
                    }
                })
                .then(function(response) {
                    file.responseData = response.data;
                    vm.model = _.get(file, 'responseData');
                }, function(response) {
                    file.responseData = response.data;
                    file.error(_.get(response, 'data.message'));
                }, function onResponseProgress(evt) {
                    file.progress = Math.min(90, parseInt(100.0 * evt.loaded / evt.total));
                })
                .finally(function() {
                    vm.isUploadInProgress = Upload.isUploadInProgress();
                })
            );
        }

        function extendFile(file) {
            if (!file.id) {
                file.newFile = true;
            }

            file.id = file.id || _.uniqueId('file');

            file.iconStyle = getFileIcon();
            file.formattedSize = formatBytes();
            file.extension = getExtension();

            file.progress = 0;

            file.isImage = _.startsWith(file.type, 'image');

            file.success = success;
            file.error = error;

            return file;

            function success() {
                file.isSuccess = true;
                file.progress = 100;
                return file;
            }

            function error(message) {
                file.isError = true;
                file.errorMessage = message;
                file.progress = 100;
                return file;
            }

            function getFileIcon() {
                var icon = appAnexoFileTypeIcons.general;
                _.forEach(appAnexoFileTypeIcons, function(value, mime) {
                    if (file.type.startsWith(mime)) {
                        icon = value;
                        return false;
                    }
                });
                return icon;
            }

            function getExtension() {
                if (_.include(file.name, '.')) {
                    return _.last(file.name.split('.'));
                }
                return 'Sem extensão';
            }

            function formatBytes() {
                var bytes = file.size;
                if (!bytes) {
                    return '0 Byte';
                }
                var k = 1024;
                var sizes = ['Bytes', 'Kb', 'Mb'];
                var i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
            }
        }

        function isUploading() {
            return _.get(vm.trackerUpload, 'tracking')();
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.abonojustificativa')
        .factory('educacao.abonojustificativa.AbonoJustificativaFaltaService', AbonoJustificativaFaltaService);

    AbonoJustificativaFaltaService.$inject = ['ServiceBuilder'];
    
    function AbonoJustificativaFaltaService(ServiceBuilder) {

        return {
            buildService: buildService
        };

        function buildService(idTurma) {

            var service = ServiceBuilder
                .create()
                .path('commons', 'turma/' + idTurma, 'frequencias', 'abono-justificativa')
                .build();

            service.getAbonosJustificativasFaltasDias = getAbonosJustificativasFaltasDias;
            service.getAbonosJustificativasFaltasAulas = getAbonosJustificativasFaltasAulas;

            function getAbonosJustificativasFaltasDias(filter) {
                return service.getEndpoint().all('falta-dia').getList({
                    filter: filter
                });
            }

            function getAbonosJustificativasFaltasAulas(filter) {
                return service.getEndpoint().all('falta-aula').getList({
                    filter: filter
                });
            }

            return service;
        }

    }
})();

(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.factory('educacao.matriz.CursoConcomitanteService', ['Restangular', '$q',
                          function (Restangular, $q) {
            var meta;

            function getMetadata(matriz) {
                var deferred = $q.defer();
                if (!meta) {
                    Restangular.one('matriz').one('matriz-curricular', matriz).options().then(function (data) {
                        meta = data;
                        deferred.resolve(meta);
                    }, function (data) {
                        deferred.reject(data);
                    });
                } else {
                    deferred.resolve(meta);
                }
                return deferred.promise;
            }

            return {
                get: function (matriz, id) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .get(id);
                },
                getList: function (matriz) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .getList();
                },
                getDefault: function (matriz) {
                    return getMetadata(matriz).then(function (data) {
                        return angular.copy(data.representation.default);
                    });
                },
                getTypes: function (matriz) {
                    return getMetadata(matriz).then(function (data) {
                        return angular.copy(data.types);
                    });
                },
                save: function (matriz, data) {
                    var rest = Restangular.one('matriz').one('matriz-curricular', matriz);
                    return !data.id ?
                        rest.post(data) :
                        rest.customPUT(data, data.id);
                },
                updateAll: function (matriz, data) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .all('concomitancia').customPUT(data);
                },
                remove: function (matriz, id) {
                    return Restangular.one('matriz').one('matriz-curricular', matriz)
                        .one('concomitancia', id).remove();
                },
                readOnly: function () {
                    var deferred = $q.defer();
                    deferred.resolve(false);
                    return deferred.promise;
                }
            };

    }]);

})();

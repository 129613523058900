(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.TipoCargoCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        'educacao.funcionario.TipoCargoService'
    ];

    function Controller(
        $scope,
        promiseTracker,
        $modalInstance,
        focus,
        TipoCargoService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.tipoCargo = $modalInstance.params.tipoCargo;
        var modo = {
            adicionando: !vm.tipoCargo,
            editando: vm.tipoCargo
        };

        vm.save = save;
        vm.cancel = fechaModal;
        vm.modo = modo;
        vm.remove = remove;


        init();


        function init() {
            vm.windowTitle = modo.adicionando ? 'Adicionando tipo de cargo' : 'Editando tipo de cargo';
            verificaFormulario();
            vm.enumClassificacaoCargo = TipoCargoService.getClassificacaoTipoCargo();
            focus('lbDescricaoTipoCargo');
        }

        function refresh() {
            verificaFormulario();
            vm.tipoCargo = {};
        }

        function save(continuar) {
            vm.tracker.loading.addPromise(
                TipoCargoService.save(vm.tipoCargo).then(function(data) {
                    vm.tipoCargo = data;
                    onSuccess(continuar);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                TipoCargoService.remove(vm.tipoCargo).then(function(data) {
                    $modalInstance.close(data);
                })
            );
        }


        function onSuccess(continuar) {
            if (continuar) {
                refresh();
                focus('lbDescricaoTipoCargo');
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.tipoCargo);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();

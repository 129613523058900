(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectMatriculaMatriz', SelectMatriculaMatrizDirective);

    function SelectMatriculaMatrizDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchMatriz" ng-disabled="ngDisabled" ng-change="ngChange()"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                tipoMatricula: '=?',
                estabelecimento: '=?',
                nivelEscolar: '=?',
                ngChange: '&?'
            },
            controller: SelectMatriculaMatrizDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectMatriculaMatrizDirectiveController.$inject = ['$scope', 'educacao.matricula.MatriculaService'];

    function SelectMatriculaMatrizDirectiveController($scope, service) {

        var vm = this;

        var templateBuscaMatriz = _.template('matriz_descricao like "<%= term %>" and estabelecimento=<%= estabelecimento %>');


        vm.searchMatriz = searchMatriz;

        function searchMatriz(params) {

            var idEstabelecimento = _.get($scope.estabelecimento, 'id') || -1;
            var query = templateBuscaMatriz({
                term: params.term,
                estabelecimento: idEstabelecimento
            });
            if (_.get($scope.nivelEscolar, 'length')) {
                query += ' and nivelEscolar ="' + $scope.nivelEscolar + '"';
            }
            return service
                .getMatriz($scope.tipoMatricula, params, query)
                .then(_.first);
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.CargoCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.funcionario.CargoService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        CargoService
    ) {

        var vm = this; 

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.CargoService = CargoService;

        function openCad(cargo) {
            $modalStates.goModalState(cargo);
        }

        function remove(data) {
            CargoService.remove(data);
        }
    }
})();


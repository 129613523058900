(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('edSelectReligiao', EdSelectReligiao);

    EdSelectReligiao.$inject = [];

    function EdSelectReligiao() {

        return {
            restrict: 'E',
            require: 'ngModel',
            template: '<ui-select ng-model="ngModel" search="vm.search" minimum-input-length="3" format-result="vm.formatResult(data)" ng-required="!!notNull" add-value="vm.add"></ui-select>',
            scope: {
                ngModel: '=',
                notNull: '='
            },
            controller: EdSelectReligiaoCtrl,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                if (tAttrs.hasOwnProperty('bf-required') || tAttrs.hasOwnProperty('data-bf-required')) {
                    element.children('ui-select').attr('bf-required', '{{bfRequired}}');
                }
            }
        };
    }

    EdSelectReligiaoCtrl.$inject = [
        'ui.components.Modal',
        'bfc.$$PermissionsService',
        'educacao.pessoas.ReligiaoService',
        'educacao.common.ObjectUtilsService'

    ];

    function EdSelectReligiaoCtrl(uiModal, PermissionsService, ReligiaoService, ObjectUtilsService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.add = !PermissionsService.isRevokedOperation('LegislacaoPageMapping', 'criar') && add;

        function search(params) {
            var term = ObjectUtilsService.normalizeFilter(params.term);
            var filter = '( descricao like "' + term + '")';

            return ReligiaoService.getList(params.offset, params.limit, filter)
                .then(function(data) {
                    return _.first(data);
                });
        }

        function formatResult(data) {
            return data.descricao;
        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'planejamento/religioes/religioes.cad.html',
                controller: 'educacao.pessoas.ReligioesCadCtrl',
                controllerAs: 'ReligioesCadCtrl',
                params: {
                    religiao: {
                        descricao: descricao
                    },
                    hideSaveAndAdd: true
                }
            }).result;
        }

    }
})();


(function() {
    'use strict';

    angular.module('educacao.calendario')
        .filter('formatEventDayInterval', formatEventDayInterval)
        .filter('formatEventShortDayInterval', formatEventShortDayInterval)
        .filter('formatEventHourInterval', formatEventHourInterval);

    formatEventDayInterval.$inject = ['DATE_TIME_FORMAT','EVENT_DATE_FORMAT_INTERVAL_DAY', 'EVENT_DATE_FORMAT_INTERVAL_DAY_SEPARATOR'];

    function formatEventDayInterval(dateTimeFormat, formatPattern, separator) {
        return format;

        function format(evento) {
            var dataInicial = moment(evento.dataInicial, dateTimeFormat);
            var dataFinal = moment(evento.dataFinal, dateTimeFormat);

            return $.fullCalendar.formatRange(dataInicial, dataFinal, formatPattern, separator);
        }
    }

    formatEventShortDayInterval.$inject = ['DATE_TIME_FORMAT', 'EVENT_DATE_FORMAT_SHORT_INTERVAL_DAY', 'EVENT_DATE_FORMAT_INTERVAL_DAY_SEPARATOR'];

    function formatEventShortDayInterval(dateTimeFormat, formatPattern, separator) {
        return format;

        function format(evento) {
            var dataInicial = moment(evento.dataInicial, dateTimeFormat);
            var dataFinal = moment(evento.dataFinal, dateTimeFormat);

            return $.fullCalendar.formatRange(dataInicial, dataFinal, formatPattern, separator);
        }
    }

    formatEventHourInterval.$inject = ['DATE_TIME_FORMAT', 'EVENT_DATE_FORMAT_INTERVAL_HOUR', 'EVENT_DATE_FORMAT_INTERVAL_HOUR_SEPARATOR'];

    function formatEventHourInterval(dateTimeFormat, formatPattern, separator) {
        return format;

        function format(evento) {
            if (evento.diaInteiro) {
                return 'Dia inteiro';
            }

            var dataInicial = moment(evento.dataInicial, dateTimeFormat);
            var dataFinal = moment(evento.dataFinal, dateTimeFormat);

            return $.fullCalendar.formatRange(dataInicial, dataFinal, formatPattern, separator);
        }
    }
})();

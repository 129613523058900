(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('inscricaoOnlineInscrevendo', inscricaoOnlineInscrevendo);

    function inscricaoOnlineInscrevendo() {

        var directiveConfig = {};

        directiveConfig.restrict = 'E';
        directiveConfig.templateUrl = 'matricula/inscricao-online/inscrevendo/inscricao-online-inscrevendo.directive.html';

        return directiveConfig;
    }
})();
(function() {

    'use strict';

    angular.module('educacao.matricula').directive('appCardTurmaEnturmacoes', CardTurmaEnturmacoes);

    function CardTurmaEnturmacoes() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/app-card-turma/app-card-turma-enturmacoes.directive.html',
            scope: {
                enturmacoes: '=',
                turma: '=',
                exibeNumeroChamada: '=',
                desenturmarAction: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['bfc.$$PermissionsService', 'educacao.common.ModalAlunoService','$enumsCache'];

    function Controller(PermissionsService, ModalAlunoService, $enumsCache) {

        var vm = this;
        vm.hasPermissionToEditAluno = hasPermissionToEditAluno;
        vm.openModalAluno = openModalAluno;

        function hasPermissionToEditAluno() {
            return !PermissionsService.isRevokedOperation('AlunoPageMapping', 'editar');
        }
    
        function openModalAluno(matricula) {
            if (!hasPermissionToEditAluno()) {
                return;
            }

            ModalAlunoService.openByIdAluno(matricula.idAluno, true).result.then(setMatriculaFromData);

            function setMatriculaFromData(data) {
                if (data) {
                    matricula.foto = null;
                    matricula.nome = _.get(data, 'pessoa.nome');
                    matricula.foto = data.foto;
                }
            }
        }
        
        getEnums();

        function getEnums() {
			$enumsCache.get('GrupoEnturmacaoCor').then(function (enumData) {
				vm.GrupoEnturmacaoCor = enumData;
				_.forEach(vm.GrupoEnturmacaoCor.values, function(color) {
					color.hex = '#' + color.hex;
				});
			});
		}
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appTabTurmaAtividade', directive);

    function directive() {
        return {
            templateUrl: 'matricula/turma/cadastro/tab-turma-atividade.directive.html',

            scope: {
                turma: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.matricula.AtividadeAEEService'
    ];

    function Controller($scope, AtividadeAEEService) {
        
        var templateAee = _.template('descricao like "<%= term %>"');

        var vm = this;

        vm.isLimiteAtividadesAtingido = isLimiteAtividadesAtingido;
        vm.novaAtividade = novaAtividade;
        vm.removeLinha = removeLinha;
        vm.applyDependenciaFisicaToAll = applyDependenciaFisicaToAll;

        vm.listAee = listAee;

        $scope.$watch('turma.estabelecimento', limpaDependenciasFisicas);
        $scope.$watch('turma.filhos', function (filhos) {
            if (_.isEmpty(filhos)) {
                limpaTabela();
            }
        });
        $scope.$watch('turma.tipoAtendimento', function (tipoAtendimento, oldValue) {
            vm.isAee = tipoAtendimento === 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO';
            if (tipoAtendimento !== oldValue) {
                limpaTabela();
            }
        });

        function listAee(params) {
            return AtividadeAEEService.getList(params.offset, params.limit, templateAee(params)).then(toPage);
        }

        function toPage(list) {
            return {
                content: list,
                hasNext: false
            };
        }

        function limpaDependenciasFisicas(newValue, oldValue) {
            if (newValue !== oldValue) {
                _.forEach($scope.turma.filhos, function (atividade) {
                    delete atividade.dependenciaFisica;
                });
            }
        }

        function limpaTabela() {
            $scope.turma.filhos = [];
            novaAtividade();
        }

        function removeLinha($index) {
            $scope.turma.filhos.splice($index, 1);
        }

        function novaAtividade() {
            $scope.turma.filhos.push({
                tipo: 'ATIVIDADE'
            });
        }

        function isLimiteAtividadesAtingido() {
            return !vm.isAee && $scope.turma.filhos.length >= 6;
        }

        function applyDependenciaFisicaToAll() {
            _.forEach($scope.turma.filhos, setDependenciaFisica);
        }

        function setDependenciaFisica(atividade) {
            atividade.dependenciaFisica = vm.applyAllDependenciaFisica;
        }
    }
})();


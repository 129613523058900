(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('appGridVagaEtapa', GridVagaEtapa);

	function GridVagaEtapa() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/quadro-vagas-matriculas/grid/vaga-etapa-grid-template.html',
			scope: {
				estabelecimento: '=',
				matriz: '=',
				estabControls: '=',
			},
			bindToController: {
				condensed: '=',
				colunas: '=',
				colunasVisiveis: '='
			},
			controller: GridVagaEtapaController,
			controllerAs: 'vm'
		};

	}

	GridVagaEtapaController.$inject = ['$scope', 'educacao.matricula.QuadroVagasService',
		'educacao.matricula.ConfiguracoesMatriculaService'];

	function GridVagaEtapaController($scope, QuadroVagasService, ConfiguracoesMatriculaService) {

		var vm = this;

		vm.estabelecimento = $scope.estabelecimento;
		vm.matriz = $scope.matriz;
		vm.QuadroVagasService = QuadroVagasService;
		vm.openModalEdicaoVaga = openModalEdicaoVaga;
		vm.removerVaga = removerVaga;
		vm.permiteMultiturno = false;
		vm.params = {
			idEstabelecimento: vm.estabelecimento.id,
			idMatriz: vm.matriz.id
		};

		$scope.$watchCollection('vm.colunasVisiveis', ajusteColunasVisiveis);
		$scope.$watchCollection('vm.vagaControls', watchEstabControls);

		function watchEstabControls(value) {
			if (!value) {
				return;
			}
			ajusteColunasVisiveis(vm.colunasVisiveis);
		}

		init();

		function init() {

			verificaPermiteMultiturno();

		}

		function openModalEdicaoVaga(etapaMatriz) {

			var param = {
				filter: 'id=' + etapaMatriz.id,
				limit: 999
			};

			QuadroVagasService.openModalEdicao(param, 'etapa')
				.result.then(function() {
					vm.vagaControls.search();
				});

		}

		function removerVaga(etapaMatriz) {

			QuadroVagasService.remove(etapaMatriz.id)
				.then(search)
				.catch(search);

		}

		function search() {
			$scope.estabControls.search();
		}

		function verificaPermiteMultiturno() {
			ConfiguracoesMatriculaService.permiteMultiturno(vm.params)
				.then(function(data) {
					vm.permiteMultiturno = data.permite;
				});
		}

		function ajusteColunasVisiveis(values) {
			if (!vm.vagaControls) {
				return;
			}
			_.forEach(vm.colunas, function(coluna) {
				var has = _.includes(values, coluna);
				vm.vagaControls.setColumnVisible(coluna, has);
				if (coluna === 'descricao') {
					vm.vagaControls.setColumnVisible('turno', has);
				}
			});
		}

	}
})();

(function () {
    'use strict';

    angular
        .module('educacao.pessoas')
        .directive('appFiltroAvancadoEscolaInformacoesGerais', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'planejamento/escolas/filtro-avancado/escola/informacoes-gerais/informacoes-gerais.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorInformacoesGerais: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.changeContadorInformacoesGerais = changeContadorInformacoesGerais;

        $scope.$watchCollection('vm.advancedFilter.informacoesGerais.orgaosPublicosEscola', changeContadorInformacoesGerais);
    
        init();
    
        function init() {
            vm.contadorInformacoesGerais = 0;

            changeContadorInformacoesGerais();
        }
    
        function changeContadorInformacoesGerais() {
            if (vm.advancedFilter && vm.advancedFilter.informacoesGerais) {
                vm.contadorInformacoesGerais = getFilterLength();
            }
        }
    
        function getFilterLength() {
            return Object.values(vm.advancedFilter.informacoesGerais).filter(function (filtroAvancadoSelecionado) {
                return filtroAvancadoSelecionado === false || !!filtroAvancadoSelecionado && filtroAvancadoSelecionado.length !== 0;
            }).length;
        }
    }    
})();

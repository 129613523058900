(function() {
    'use strict';

    angular.module('educacao.lista-espera-novo.anotacoes')
        .controller('educacao.lista-espera-novo.anotacoes.HistoricoAnotacoesPopoverController', HistoricoAnotacoesPopoverController);

    HistoricoAnotacoesPopoverController.$inject = ['$rootScope', 'educacao.common.$commons', '$popoverInstance'];

    function HistoricoAnotacoesPopoverController($rootScope, $commons, $popoverInstance) {

        var vm = this;
        vm.anotacao = $popoverInstance.params.anotacao;
    }
})();

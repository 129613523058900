(function() {

    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.CadastroObitoController', CadastroObitoCtrl);

    CadastroObitoCtrl.$inject = ['$modalInstance'];

    function CadastroObitoCtrl($modalInstance) {

        var vm = this;

        vm.continuar = continuar;

        vm.dataValue = $modalInstance.params.dataValue || {};

        function continuar() {
            $modalInstance.close(vm.dataValue);
        }
    }

})();


(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .controller('educacao.gestao-pessoas-quadro-informativo.ComponenteCurricularInfoModalController', Controller);

    Controller.$inject = [
        '$modalInstance',
        '$scope'
    ];

    function Controller($modalInstance, $scope) {
        var vm = this;

        vm.isSearch = false;

        vm.duracoesSelecionadas = $modalInstance.params.duracoesSelecionadas;
        vm.idComponenteCurricular = $modalInstance.params.idComponenteCurricular;

        vm.estabelecimentoId = $modalInstance.params.estabelecimentoId;
        vm.anoLetivoId = $modalInstance.params.anoLetivoId;
        vm.data = $modalInstance.params.data;
        vm.filtroTurno = null;
        $scope.$watch('vm.escolaSelecionadas' , onChangeFilter);
        $scope.$watch('vm.duracoesSelecionadas' , onChangeFilter);
        $scope.$watch('vm.filtroTurno' , onChangeFilter);
        vm.search = search;
        vm.init = init;
        init();

        function search() {
            vm.isSearch = !vm.isSearch;
        }

        function onChangeFilter(newValue) {
            if (_.isEmpty(newValue)) {
                search();
            }
        }



        function init() {
            if (!_.isEmpty($modalInstance.params.escolaSelecionadas)) {
                vm.escolaSelecionadas = $modalInstance.params.escolaSelecionadas;
            }
        }
    }
})();
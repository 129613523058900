(function () {
    'use strict';

    angular.module('educacao.calendario')
        .directive('currentMiniMonth', directiveFactorFunction);

    function directiveFactorFunction() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/current-mini-month/current-mini-month.directive.html',

            scope: {
                calendarioDisplay: '='
            },

            controller: CalendarControler,
            controllerAs: 'vm'
        };
    }

    CalendarControler.$inject = ['DATE_FORMAT', '$scope'];

    function CalendarControler(DATE_FORMAT, $scope) {
        var vm = this;

        vm.currentDate = moment();
        vm.formatedMonth = '';

        vm.initialDate = $scope.calendarioDisplay.initialDate;
        vm.finalDate = $scope.calendarioDisplay.finalDate;

        vm.prev = prev;
        vm.next = next;

        $scope.$watch('calendarioDisplay.processCount', updateEventDate);

        $scope.$watch('vm.currentDate', function () {
            vm.formatedMonth = vm.currentDate.format('MMMM YYYY');
        });

        function prev() {
            vm.currentDate = vm.currentDate.clone().subtract(1, 'month');
        }

        function next() {
            vm.currentDate = vm.currentDate.clone().add(1, 'month');
        }

        function updateEventDate() {
            var prefix = vm.currentDate.format('YYYY-MM-');

            _.times(vm.currentDate.daysInMonth(), function(index){
                reprocessDay(prefix + _.padLeft(index + 1, 2, '0'));
            });
        }

        function reprocessDay(date) {
            $scope.$broadcast('reprocess-day', date);
        }
    }
})();

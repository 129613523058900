(function () {
    'use strict';

    var EVENT_EDIT = 'holyday-list-popover.edit';
    var EVENT_REMOVE = 'holyday-list-popover.remove';

    angular.module('educacao.calendario')
        .constant('HOLYDAY_LIST_POPOVER_EDIT_EVENT', EVENT_EDIT)
        .constant('HOLYDAY_LIST_POPOVER_REMOVE_EVENT', EVENT_REMOVE)
        .controller('educacao.calendario.CalendarioFeriadoListPopover', CalendarioFeriadoListPopover);

    CalendarioFeriadoListPopover.$inject = [
        '$scope',
        'educacao.context.ContextoEstabelecimento'
    ];

    function CalendarioFeriadoListPopover($scope, ContextoEstabelecimento) {
        var vm = this;

        vm.readonly = !ContextoEstabelecimento.isSecretaria() || !$scope.showHolydaysControls;
        vm.edit = edit;
        vm.remove = remove;

        function edit(holyday) {
            $scope.$emit(EVENT_EDIT, holyday);
        }

        function remove(holyday) {
            $scope.$emit(EVENT_REMOVE, holyday);
        }
    }
})();

(function() {
    'use strict';

    angular.module('app', [
        'bfc',
        'bfc.oauth2',
        'bfc.state',
        'bfc.user-access',
        'bfc.messages',
        'user-accounts-ui',
        'bfc.script',
        'bfc.notifications',
        'bfc.pesquisa',

        'relatorios',
        'scripts',
        'assinador-components',

        'ed-publicacao-frequencia',

        'ui.bootstrap',
        'ui.sortable',
        'angular.filter',
        'ngFileUpload',

        'ui-components',
        'ui-components.ui-commons',
        'ui-components.ui-select',
        'ui-components.ui-modal',
        'ui-components.ui-number',
        'ui-components.ui-pagination',
        'ui-components.ui-search',
        'ui-components.ui-list-grid',
        'ui-components.ui-spinner',
        'ui-components.ui-popover',
        'ui-components.ui-validation',
        'ui-components.ui-number',
        'ui-components.ui-banner',

        'ed-components',
        'ed-components.ed-footer-buttons',
        'ed-components.ed-navigable-table',
        'ed-components.ed-input-tags',
        'ed-components.edFooterHelp',
        'ed-components.ed-restricao-alimentar',
        'ed-components.ed-select-turma',
        'ed-components.ed-emissao-relatorio',
        'ed-components.ed-execucao-script',

        'ui-fone',
        'generic-service',
        // 'e-mail',
        'telefone',
        // 'ed-connection-status',
        'cha-desempenho',

        'educacao.context',

        'cpa-components',
        'gridster',

        'ed-filter-decode',
        'ed-error-interceptor',
        'ed-modal-confirm',
        'ed-endereco',
        'ed-historico',
        'ed-enums-cache',
        'ed-enum-value',
        'ed-api-value',
        'ed-inscricao-comprovante',
        'educacao.common',
        'ed-header-filter',
        'ed-float-thead',



        // 'educacao.prototipo',
        'educacao.matriz',
        'educacao.calendario',
        'educacao.funcionario',
        'educacao.preparacaoAnoLetivo',
        'educacao.lista-espera',
        'educacao.lista-espera-novo',
        'educacao.matricula',
        'educacao.pessoas',
        'educacao.planejamento',
        'educacao.agendaprofessor',
        'educacao.diarioclasse',
        'educacao.frequencia',
        'educacao.geracaoHorarios',
        'educacao.historico',
        'educacao.desempenhoescolar',
        'educacao.movimentoEscolar',
        'educacao.configuracaoInstrumentosAvaliacao',
        'educacao.configuracaoPlanejamentoAula',
        'educacao.configuracaoConteudoMinistrado',
        'educacao.legislacao',
        'educacao.convencao',
        'educacao.historico-escolar',
        'educacao.entidade',
        'educacao.configuracaoFrequencia',
        'educacao.configuracaoGrupo',
        'educacao.formulaCalculo',
        'educacao.rematricula',
        'educacao.itemAvaliavel',
        'educacao.encerramento',
        'educacao.enderecos',
        'educacao.motivosabonosjustificativas',
        'educacao.programasocial',
        'acompanhamento-pedagogico',
        'educacao.relatorio',
        'educacao.formulaCalculoInstrumento',
        'educacao.cursoaperfeicoamento',
        'educacao.feriado',
        'educacao.avaliacao-externa',
        'educacao.restricao-alimentar',
        'educacao.criterio-lista-espera',
        'educacao.configuracao-evasao-reprovacao',
        'educacao.ocupacao-aulas-quadro-informativo',
        'educacao.turma-alunos-quadro-informativo',
        'educacao.quadro-funcionarios-configuracao',
        'educacao.visao-geral-quadro-informativo',
        'educacao.quadro-informativo-utils',
        'educacao.gestao-pessoas-quadro-informativo',
        'educacao.educacenso',
        'inscricao-lista-espera-formulario',
        'inscricao-criterio',
        'ed-select-enum',
        'suite.atendimento',
        'suite.avisos',
        'ed-anexo',
        'ed-preferences',
        'ed-google-oauth',
        'ed-tour-guiado',
        'ed-photo-profile',
        'plataforma-extensoes',
        'gerenciador-extensoes'
    ]).run(['$state', '$rootScope', '$log', function($state, $rootScope, $log) {
            $rootScope.$state = $state;

            $rootScope.$on('$stateChangeError', function() {
                $log.error(arguments);
            });

            $rootScope.$on('$stateNotFound', function() {
                $log.error(arguments);
            });
        }])
        .run(InitializeGoogleAnalytics).run([
            'bfc.authentication.AuthenticationContext',
            function(authContext) {
                var userInfo = authContext.getUserInfo();
                window.postMessage(JSON.stringify({
                    event: 'BLIP_WEBCHAT',
                    userInfo: userInfo
                }), '*');
            }
        ]);

    InitializeGoogleAnalytics.$inject = ['$window', '$rootScope', '$state', 'ANALYTICS_ID'];

    function InitializeGoogleAnalytics($window, $rootScope, $state, ANALYTICS_ID) {
        if (!ANALYTICS_ID) {
            return;
        }

        $window.ga('create', ANALYTICS_ID, 'auto');

        $rootScope.$on('$stateChangeSuccess', function() {
            var location = $state.href($state.current).replace($state.href('app'), '/');
            $window.ga('send', 'pageview', location);
        });
    }
})();

(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.calendario');

    moduloCommon.controller('educacao.calendario.EventoCadCtrl', ['$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
		'educacao.calendario.EventoService',
		'educacao.common.ObjectUtilsService',
		'educacao.calendario.TipoEventoSelect',
		'fnCad',
		'id',
		'optionsCad',
		'promiseTracker',
		'readOnly',
		'bfc.Focus',
		function($scope, $rootScope, $q, $modalInstance, $modalTracker, EventoService,
			ObjectUtilsService,
            TipoEventoSelect, 
			fnCad,
			id,
			optionsCad,
			promiseTracker,
			readOnly,
			focus
		) {
			
			$scope.evento = {};
            /**
			 * Tracker for promise
			 */
			$scope.tracker = {};
			$scope.tracker.saveContinue = promiseTracker();
			$scope.tracker.save = promiseTracker();
			$scope.tracker.remove = promiseTracker();

			$scope.modo = {
				adicionando: !id && !readOnly,
				adicionandoNovaOpcao: Boolean(fnCad),
				editando: id && !readOnly,
				visualizando: id && readOnly
			};
			
			
            
            //Constante
            $scope.OUTROS= {id: 7, descricao: 'Outros'};
            
          	init();

			function init(){
				$modalTracker.addPromise(
					$q.all([
						EventoService.getTypes().then(function(data) {
							$scope.eventoProperties = data.EventoDto;
						}),
						refresh()
					])
				);
                
                $scope.tipoEventoSelect = TipoEventoSelect.select2Config();
            }

			

			function refresh() {
				if ($scope.form) {
					$scope.form.$setPristine();
				}

				return Boolean(id) ?
					EventoService.get(id).then(function(data) {
						$scope.evento = data;
					}) :
					EventoService.getDefault().then(function(data) {
						$scope.evento = angular.extend(data, optionsCad);
					});
			}

			function onSuccess(continuar, tracker) {
				if (continuar) {
					id = null;
					tracker.addPromise(refresh());
                    focus('lbDescricao');
				} else {
					$scope.cancel();
				}
			}

			$scope.cancel = function() {
				$modalInstance.close();
			};

			$scope.save = function(continuar) {
               var tracker = continuar ? $scope.tracker.saveContinue : $scope.tracker.save;
                
               tracker.addPromise(EventoService.save($scope.evento).then(function(data) {
					if (angular.isFunction(fnCad)) {
						fnCad(data);
					}

					$rootScope.$broadcast('refreshEventos', {
						data: data,
						editarConfiguracoes: !continuar,
						add: !id,
						edit: Boolean(id)
					});

					onSuccess(continuar, tracker);
				}));

			};

			$scope.readOnly = function() {
				return readOnly;
			};
            
            $scope.remove = function() {
				$scope.tracker.remove.addPromise(
						EventoService.remove($scope.evento.id).then(function() {
						$rootScope.$broadcast('refreshEventos', {
							data: $scope.evento,
							remove: true
						});

						onSuccess();
					})
				);
			};
            
           $scope.isEspecifico = function(){
                var item = $('#lbTipoEvento').select2('data');
				return item !== null && item !== undefined && item.id === $scope.OUTROS.id && item.descricao === $scope.OUTROS.descricao;
           };
           
        }]);

})();
(function () {
    'use strict';

    angular.module('educacao.funcionario').directive('appSelectCargo', SelectCargoDirective);

    function SelectCargoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchCargo" format-result="vm.formatResult(data.nome)" add-value="vm.addCargo"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            controller: SelectCargoDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectCargoDirectiveController.$inject = ['ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.funcionario.CargoService'];

    function SelectCargoDirectiveController($modal, PermissionsService, service) {

        var vm = this;

        vm.searchCargo = searchCargo;
        vm.addCargo = !PermissionsService.isRevokedOperation('CargoPageMapping', 'criar') && addCargo;
        vm.formatResult = formatResult;

        function searchCargo(params) {
            return service.list('(descricao like "' + params.term + '")', params.offset, params.limit);

        }

        function formatResult(data) {
            return data;
        }

        function addCargo(descricao) {
            return $modal.open({
                templateUrl: 'planejamento/funcionarios/cargo/cargo.cad.html',
                controller: 'educacao.funcionario.CargoCadCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    cargo: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }
    }
})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .directive('appInscricaoListaEsperaModalidades', appInscricaoListaEsperaModalidades);

    function appInscricaoListaEsperaModalidades() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/inscricao/app-inscricao-lista-espera-modalidades/app-inscricao-lista-espera-modalidades.directive.html',
            scope: {},
            bindToController: {
                inscricao: '=',
                configuracao: '=',
                etapasListagem: '=',
                estabelecimentosEditando: '=',
                editandoEtapa: '=',
                formModalidade: '=',
                quadroVagas: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento',
        'bfc.Notification',
        '$enumsCache',
        '$q',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils'
    ];

    function Controller(ContextoEstabelecimento, bfcNotification, $enumsCache, $q, InscricaoListaEsperaService, InscricaoListaEsperaUtils) {
        var vm = this;

        init();

        vm.edit = edit;
        vm.isAee = isAee;
        vm.editando = false;
        vm.hasTurno = hasTurno;
        vm.hasValue = hasValue;
        vm.toggleTurno = toggleTurno;
        vm.removeEtapa = removeEtapa;
        vm.formatEtapa = formatEtapa;
        vm.newTurnos = { values: {} };
        vm.searchEtapas = searchEtapas;
        vm.searchCursos = searchCursos;
        vm.isTurnoSalvo = isTurnoSalvo;
        vm.descricaoEstabelecimento = '';
        vm.exibeEtapaEnsino = exibeEtapaEnsino;
        vm.salvaModalidade = salvaModalidade;
        vm.alreadySelected = alreadySelected;
        vm.addEstabecimento = addEstabecimento;
        vm.isComplementares = isComplementares;
        vm.selectModalidade = selectModalidade;
        vm.getDescricaoTurno = getDescricaoTurno;
        vm.splitModalidadeNivel = splitModalidadeNivel;
        vm.formatEstabelecimento = formatEstabelecimento;
        vm.removeEstabelecimento = removeEstabelecimento;
        vm.searchEstabelecimentos = searchEstabelecimentos;
        vm.editandoInscricaoEtapa = editandoInscricaoEtapa;
        vm.isAtividadesComplementares = isAtividadesComplementares;
        vm.turnosConformeConfiguracao = turnosConformeConfiguracao;
        vm.getModalidade = getModalidade;
        vm.select2Options = {
            allowClear: true,
            formatSelection: formatModalidadeSelection
        };

        function init() {
            vm.estabelecimentos = [];

            if (vm.inscricao.etapas && _.isEmpty(vm.etapasListagem)) {
                createEtapaListagem();
            }

            vm.inscricao.etapas = [];
            vm.isMatriculado =  false;
            vm.quadroVagas = vm.quadroVagas || [];
            if (vm.configuracao.limitarEstabelecimentosQuadroVaga) {
                vm.configuracao.niveisModalidades = _.filter(
                    vm.configuracao.niveisModalidades,
                    hasQuadroVaga
                );
            }
            
            isMatriculado();
            setEstabelecimento();
            enumTurno();
            enumModalidade();
            enumNivelEscolar();
            setPermiteSelecaoEstabelecimento();
            novaModalidade();
            addEstabecimento();
        }

        function hasQuadroVaga(nivelModalidade) {
            var modalidade = nivelModalidade.modalidade === 'BASICA' ? 'EDUCACAO_BASICA' : nivelModalidade.modalidade;
            var estabelecimentosConfig = _.pluck(vm.configuracao.estabelecimentos, 'estabelecimento');
            return _.find(vm.quadroVagas, function(vaga) {
                var curso = _.get(vaga, 'matriz.curso');
                return (
                    curso.nivelEscolar === nivelModalidade.nivelEscolar &&
                    curso.nivelModalidade === modalidade &&
                    estabelecimentosConfig.includes(vaga.estabelecimento.id)
                );
            });
        }

        function editandoInscricaoEtapa() {
            return _.first(_.filter(vm.etapa.estabelecimentos, 'salvo')) || vm.editandoEtapa;
        }

        function createEtapaListagem() {
            _.forEach(vm.inscricao.etapas, function (etapa) {
                vm.estabelecimentosLista = [];

                _.forEach(etapa.estabelecimentos, setEtapasListagemEstabelecimento);

                findNivelEscolar(etapa);

                vm.etapasListagem.push({
                    id: etapa.id,
                    configuracaoNivelModalidade: {
                        id: _.get(etapa, 'configuracaoNivelModalidade.id'),
                        modalidade: _.get(etapa, 'configuracaoNivelModalidade.modalidade'),
                        nivelEscolar: _.get(etapa, 'configuracaoNivelModalidade.nivelEscolar')
                    },
                    modalidadeNivelEscolar: vm.modalidadeNivelEscolar,
                    nivelEscolar: vm.nivelEscolar,
                    nivelModalidadeEtapa: etapa.nivelModalidadeEtapa,
                    estabelecimentos: vm.estabelecimentosLista,
                    situacao: etapa.situacao
                });
            });
        }

        function setEtapasListagemEstabelecimento(estabelecimento) {
            var estab = {estabelecimento: _.find(vm.estabelecimentosEditando, 'id', estabelecimento.estabelecimento.estabelecimento)};
                estab.estabelecimento.idMongo = estabelecimento.id;
                estab.turnos = estabelecimento.turnos;
                estab.turnosSalvos = _.cloneDeep(estabelecimento.turnos);
                estab.dataInscEstab = estabelecimento.dataInscEstab;
                estab.salvo = true;
                vm.estabelecimentosLista.push(estab);
        }

        function findNivelEscolar(etapa) {
            var niveisModalidades = _.find(vm.configuracao.niveisModalidades, 'id', etapa.configuracaoNivelModalidade.id);
            if (niveisModalidades) {
                vm.modalidade = niveisModalidades.modalidade;
                vm.nivelEscolar = niveisModalidades.nivelEscolar;

                createModalidadeNivelEscolar();

                return;
            }

            if (_.find(_.flatten(_.pluck(vm.configuracao.niveisModalidades, 'etapas')), 'id', etapa.nivelModalidadeEtapa.id)) {
                vm.modalidade = 'EDUCACAO_BASICA';
                vm.nivelEscolar = 'EDUCACAO_INFANTIL';
                vm.modalidadeNivelEscolar = 'basica#EDUCACAO_INFANTIL';
            }
        }

        function createModalidadeNivelEscolar() {
            var firstWord = vm.modalidade.toLowerCase();
            vm.modalidadeNivelEscolar = vm.nivelEscolar ? firstWord + '#' + vm.nivelEscolar : firstWord;
        }

        function setEstabelecimento(modalidadeNivel) {
            vm.estabelecimentos = [];
            if (!vm.configuracao.estabelecimentos) {
                vm.estabelecimentos = '';
                return;
            }

            if (!vm.configuracao.estabelecimentos.length && vm.configuracao.aplicarParaTodosEstabelecimentos) {
                vm.estabelecimentos = vm.estabelecimentosEditando;
                return;
            }

            _.forEach(vm.configuracao.estabelecimentos, function(estabelecimentos){
                findEstabelecimentos(estabelecimentos, modalidadeNivel);
            });
        }

        function findEstabelecimentos(estabelecimentos, modalidadeNivel) {
            var estabelecimento = _.find(vm.estabelecimentosEditando, 'id', estabelecimentos.estabelecimento);
            if (!estabelecimento) {
                return;
            }

            if (vm.configuracao.limitarEstabelecimentosQuadroVaga && !hasModalidadeInEstabelecimento(modalidadeNivel, estabelecimento)) {
                return;
            }
            estabelecimento.idMongo = estabelecimentos.id;
            vm.estabelecimentos.push(estabelecimento);
        }

        function hasModalidadeInEstabelecimento(modalidadeNivel, estabelecimento) {
            if (!_.get(modalidadeNivel, 'nivel') || !_.get(modalidadeNivel, 'modalidade')) {
                return true;
            }
            return vm.quadroVagas.some(function(vaga) {
                var estabelecimentoEquals = vaga.estabelecimento.id === estabelecimento.id;
                var nivelEscolarEquals = vaga.matriz.curso.nivelEscolar === modalidadeNivel.nivel;
                var modalidadeEquals = vaga.matriz.curso.nivelModalidade === modalidadeNivel.modalidade;
                return estabelecimentoEquals && nivelEscolarEquals && modalidadeEquals;
            });
        }

        function edit(etapa, estabelecimento) {
            vm.editandoEtapa = true;
            vm.editando = true;

            var etapaEdicao = _.cloneDeep(_.find(vm.etapasListagem, etapa));
            vm.etapa.modalidadeNivelEscolar = etapaEdicao.modalidadeNivelEscolar;
            vm.etapa.nivelModalidadeEtapa = etapaEdicao.nivelEscolar === 'EDUCACAO_INFANTIL' ? undefined : etapaEdicao.nivelModalidadeEtapa;
            vm.etapa.estabelecimentos = [_.find(etapaEdicao.estabelecimentos, 'estabelecimento', estabelecimento.estabelecimento)];
            vm.etapa.turnos = estabelecimento.turnos;
            vm.etapa.id = etapaEdicao.id;

            turnosConformeConfiguracao(vm.etapa.nivelModalidadeEtapa);
            splitModalidadeNivel();
            removeEtapa(etapa, estabelecimento);
        }

        function removeEtapa(etapa, estabelecimento) {
            var etapaEdicao = _.find(vm.etapasListagem, etapa);
            if (_.size(etapaEdicao.estabelecimentos) === 1) {
                _.remove(vm.etapasListagem, etapa);
                verificaFormInvalido();
                return;
            }

            _.remove(etapaEdicao.estabelecimentos, estabelecimento);
            verificaFormInvalido();
        }

        function addEstabecimento() {
            if (_.size(vm.etapa.estabelecimentos) >= vm.configuracao.qtdEstabelecimentos) {
                bfcNotification.publish('A configuração possui uma quantidade máxima de ' + vm.configuracao.qtdEstabelecimentos + ' estabelecimento(s)', 'danger');
                return;
            }

            vm.etapa.estabelecimentos.push({
                turnos: [],
                turnosSalvos: [],
                estabelecimento: vm.permiteOutroEstabelecimento || ContextoEstabelecimento.isSecretaria() ? null : ContextoEstabelecimento.getEstabelecimento()
            });
        }

        function formatModalidadeSelection(item) {
            var group = $(item.element).parent('optgroup');

            return (group.length ? '<strong>' + group.attr('label') + '</strong> > ' : '') + item.text;
        }

        function novaModalidade() {
            vm.editingKey = null;
            vm.nomeModalidade = null;
            vm.etapa = {
                status: 'INSCRITO',
                estabelecimentos: []
            };
        }

        function splitModalidadeNivel() {
            clearFields();

            var newValue = vm.etapa.modalidadeNivelEscolar;
            if (newValue && newValue.indexOf('#') !== -1) {
                var splited = newValue.split('#');
                vm.etapa.nivelEscolar = splited.pop();
                vm.nomeModalidade = splited.pop();
            } else {
                delete vm.etapa.nivelEscolar;
                vm.nomeModalidade = newValue;
            }
            setEstabelecimento(getModalidadeNivel());
            setEtapaEducacao();

            delete vm.etapa.equivalencia;
            delete vm.etapa.etapaEnsino;
            delete vm.etapa.curso;
            delete vm.etapa.atividade;
        }

        function getModalidadeNivel() {
            return {
                modalidade: getNivelModalidade(),
                nivel: vm.etapa.nivelEscolar
            };
        }

        function getNivelModalidade() {
            var mapModalidades = {
                aceleracao: 'ACELERACAO',
                eja: 'EJA',
                basica: 'EDUCACAO_BASICA'
            };
            return mapModalidades[vm.nomeModalidade];
        }

        function clearFields() {
            if (vm.editando) {
                return;
            }

            vm.etapa.nivelModalidadeEtapa = undefined;

            vm.turnos = [{
                cancelamento: undefined,
                turno: ''
            }];

            vm.etapa.estabelecimentos = [{
                turnos: [],
                turnosSalvos: [],
                estabelecimento: undefined
            }];
        }

        function turnosConformeConfiguracao(nivelModalidadeEtapa) {
            if (!nivelModalidadeEtapa) {
                return;
            }

            vm.newTurnos = { values: {} };
            _.forEach(nivelModalidadeEtapa.turnos, function (turno) {
                vm.newTurnos[turno] = vm.todosTurnos[turno];
                vm.newTurnos.values[turno] = vm.todosTurnos[turno];
            });

            vm.turnos = { turno: vm.newTurnos };
        }

        function setEtapaEducacao() {
            _.forEach(vm.configuracao.niveisModalidades, function (nivelModalidade) {
                if (vm.nomeModalidade.toUpperCase() === nivelModalidade.modalidade && (!vm.etapa.nivelEscolar || vm.etapa.nivelEscolar === nivelModalidade.nivelEscolar)) {
                    if (nivelModalidade.modalidade === 'BASICA' && nivelModalidade.nivelEscolar === 'EDUCACAO_INFANTIL') {
                        var isNovaInscricao = !_.get(vm.inscricao, 'id');
                        vm.nivelModalidadeEtapa = InscricaoListaEsperaUtils.checkIdadeComEtapa(nivelModalidade.etapas, vm.inscricao.dataNascimento, isNovaInscricao);

                        if (!vm.nivelModalidadeEtapa) {
                            bfcNotification.publish('O inscrito possui uma idade que não se encaixa em nenhuma etapa do nível escolar educação infantil.', 'danger');
                            return;
                        }

                        turnosConformeConfiguracao(vm.nivelModalidadeEtapa);
                    }
                    vm.listEtapas = nivelModalidade.etapas;
                }
            });
        }

        function salvaModalidade() {
            vm.editando = false;

            var estabelecimentosTurnos = _.cloneDeep(vm.etapa.estabelecimentos);
            var filtro = vm.etapa.nivelEscolar === 'EDUCACAO_INFANTIL' ? { 'nivelEscolar': 'EDUCACAO_INFANTIL' } : { 'nivelEscolar': vm.etapa.nivelEscolar, 'nivelModalidadeEtapa': vm.etapa.nivelModalidadeEtapa };

            var etapaListagemAdd = _.find(vm.etapasListagem, filtro);
            if (etapaListagemAdd) {
                verificaEstabelecimentoAdicionado(etapaListagemAdd.estabelecimentos);

                var hasEtapaEstabelecimentoAdd = _.find(vm.etapa.estabelecimentos, 'estabelecimento.descricao', vm.descricaoEstabelecimento);

                if (vm.descricaoEstabelecimento && hasEtapaEstabelecimentoAdd) {
                    bfcNotification.publish('O estabelecimento ' + vm.descricaoEstabelecimento + ' já foi adicionado.', 'danger');
                    return;
                }

                etapaListagemAdd.estabelecimentos.push(estabelecimentosTurnos);
                etapaListagemAdd.estabelecimentos = _.flatten(etapaListagemAdd.estabelecimentos);

                verificaFormInvalido();
                novaModalidade();
                addEstabecimento();

                vm.editandoEtapa = false;
                return;
            }

            var configuracaoNivelModalidade = _.find(vm.configuracao.niveisModalidades, { modalidade: vm.nomeModalidade.toUpperCase(), nivelEscolar: filtro.nivelEscolar ? filtro.nivelEscolar : null });

            vm.etapasListagem.push({
                nivelEscolar: vm.etapa.nivelEscolar,
                configuracaoNivelModalidade: { id: configuracaoNivelModalidade.id },
                modalidadeNivelEscolar: vm.etapa.modalidadeNivelEscolar,
                nivelModalidadeEtapa: vm.etapa.nivelModalidadeEtapa || vm.nivelModalidadeEtapa,
                estabelecimentos: estabelecimentosTurnos,
            });

            novaModalidade();
            addEstabecimento();
            verificaFormInvalido();
            vm.editandoEtapa = false;
        }

        function verificaFormInvalido() {
            if (!_.size(vm.etapasListagem)) {
                vm.formModalidade.$invalid = true;
                return;
            }

            vm.formModalidade.$invalid = false;
        }

        function verificaEstabelecimentoAdicionado(listaEtabAdd) {
            _.forEach(listaEtabAdd, function (estabelecimento) {
                var estabelecimentoAdd = _.find(vm.etapa.estabelecimentos, 'estabelecimento.idMongo', estabelecimento.estabelecimento.idMongo);
                if (estabelecimentoAdd) {
                    vm.descricaoEstabelecimento = _.get(estabelecimentoAdd, 'estabelecimento.descricao');
                }
            });
        }

        function getDescricaoTurno(turno) {
            var minusculo = turno.turno.toLowerCase();
            return minusculo.charAt(0).toUpperCase() + minusculo.slice(1);
        }

        function toggleTurno(inscricaoEstabelecimentos, turno) {
            if (hasTurno(inscricaoEstabelecimentos, turno)) {
                _.remove(inscricaoEstabelecimentos.turnos, 'turno', turno.key);
            } else {
                inscricaoEstabelecimentos.turnos.push({ turno: turno.key, cancelamento: undefined });
            }

            inscricaoEstabelecimentos.todos = _.pluck(vm.turnos.turno.values, 'key').every(function (itm) {
                return _.includes(inscricaoEstabelecimentos.turnos, itm);
            });
        }

        function hasValue(nomeModalidade) {
            return Boolean(_.filter(vm.etapasListagem[nomeModalidade], {
                status: 'INSCRITO'
            }).length);
        }

        function selectModalidade(modalidade, nivelEscolar) {
            var filtro = nivelEscolar ? { modalidade: modalidade, nivelEscolar: nivelEscolar } : { modalidade: modalidade };
            return Boolean(_.find(vm.configuracao.niveisModalidades, filtro));
        }

        function enumModalidade() {
            $enumsCache.get('ModalidadeMatricula').then(function (data) {
                vm.ModalidadeEnum = data;
            });
        }

        function enumNivelEscolar() {
            $enumsCache.get('NivelEscolar').then(function (data) {
                vm.NivelEscolarEnum = data;
            });
        }

        function enumTurno() {
            $enumsCache.get('Turno').then(setTurnos);
        }

        function setTurnos(data) {
            vm.todosTurnos = data;
        }

        function setPermiteSelecaoEstabelecimento() {
            vm.permiteOutroEstabelecimento = true;
        }

        function removeEstabelecimento(index) {
            vm.etapa.estabelecimentos.splice(index, 1);
        }

        function searchEtapas() {
            return $q.when(getEtapas());
        }

        function getEtapas() {
            return { content: vm.listEtapas };
        }

        function formatEtapa(data) {
            return data.descricao;
        }

        function searchCursos() {
            return getCursos();
        }

        function getCursos() {
            vm.newListEtapas = [];
            var idCursos = _.pluck(vm.listEtapas, 'curso');

            return InscricaoListaEsperaService.getCursos(idCursos)
                .then(function (values) {
                    _.forEach(values.cursos, function (curso) {
                        var findCurso = _.find(vm.listEtapas, 'curso', curso.id);
                        if (findCurso) {
                            findCurso.descricao = curso.descricao;
                            vm.newListEtapas.push(findCurso);
                        }
                    });
                    return { content: vm.newListEtapas };
                });
        }

        function searchEstabelecimentos() {
            return $q.when(getEstabelecimento());
        }

        function getEstabelecimento() {
            var estabelecimentos = _.cloneDeep(vm.estabelecimentos);
            _.forEach(vm.etapa.estabelecimentos, function (estabelecimento) {
                if (!_.get(estabelecimento, 'estabelecimento.id')) {
                    return;
                }

                _.remove(estabelecimentos, 'id', estabelecimento.estabelecimento.id);
            });

            return { content: estabelecimentos };
        }

        function formatEstabelecimento(data) {
            return data.descricao;
        }

        function hasTurno(inscricaoEstabelecimentos, turno) {
            return _.find(inscricaoEstabelecimentos.turnos, 'turno', turno.key);
        }

        function isTurnoSalvo(inscricaoEstabelecimentos, turno) {
            if (!inscricaoEstabelecimentos.turnosSalvos) {
                return;
            }
            
            return _.find(inscricaoEstabelecimentos.turnosSalvos, 'turno', turno.key);
        }

        function exibeEtapaEnsino() {
            return Boolean(_.size(vm.listEtapas) && vm.etapa.nivelEscolar && vm.etapa.nivelEscolar !== 'EDUCACAO_INFANTIL');
        }

        function isComplementares() {
            return vm.nomeModalidade === 'complementar';
        }

        function isAee() {
            return vm.nomeModalidade === 'atividadesAee';
        }

        function isAtividadesComplementares() {
            return vm.nomeModalidade === 'atividadesComplementares';
        }

        function alreadySelected(nomeModalidade) {
            return vm.editingKey !== nomeModalidade && hasValue(nomeModalidade);
        }

        function getModalidade(modalidade) {
            return modalidade.substr(0, modalidade.indexOf('#')).toUpperCase();
        }

        function isMatriculado() {
            vm.isMatriculado = !!_.find(vm.etapasListagem, 'situacao.matriculado');
        }
    }

})();

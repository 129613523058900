(function() {
    'use strict';
    angular.module('educacao.matricula').directive('edDocumentos', function() {
        var config = {};

        config.restrict = 'E';
        config.templateUrl = 'matricula/configuracao-matricula/documentos-obrigatorios.html';
        config.scope = {};
        config.scope.ngModel = '=';
        config.scope.nivelModalidade = '=';
        config.scope.nivelEscolar = '=';
        config.scope.enumEtapas = '=';
        config.scope.exibeColunaEtapas = '=';
        config.scope.restringeQtdEtapa = '=';
        config.controller = Controller;
        config.controllerAs = 'vm';

        return config;

    });
    Controller.$inject = ['$scope', 'educacao.matricula.TipoDocumentoSelect', '$q', 'promiseTracker'];

    function Controller($scope, TipoDocumentoSelect, $q, promiseTracker) {
        var vm = this;
        vm.select2 = {
            TipoDocumento: TipoDocumentoSelect.select2Config()
        };
        vm.adicionaDocumento = adicionaDocumento;
        vm.removeDocumento = removeDocumento;
        vm.tracker = promiseTracker();


        init();

        // $scope.$watch('$scope.ngModel', formatEtapasEquivalenciaPraEditar);

        function init() {
            if (_.isEmpty($scope.ngModel)) {
                $scope.ngModel = [];
                $scope.ngModel.push({
                    nivelModalidade: $scope.nivelModalidade,
                    nivelEscolar: $scope.nivelEscolar,
                    equivalencias: [],
                    obrigatorio: false
                });
            }
        }

        function removeDocumento(item) {
            var index = $scope.ngModel.indexOf(item);
            $scope.ngModel.splice(index, 1);
        }

        function adicionaDocumento() {
            if (_.isEmpty($scope.ngModel)) {
                $scope.ngModel = [];
            }
            $scope.ngModel.push({
                nivelModalidade: $scope.nivelModalidade,
                nivelEscolar: $scope.nivelEscolar,
                equivalencias: [],
                obrigatorio: false
            });

        }
    }


})();

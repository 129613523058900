(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoInscritosComunicadoCtrl', ClassificacaoInscritosComunicadoCtrl);

    ClassificacaoInscritosComunicadoCtrl.$inject = ['processo', '$injector', 'promiseTracker', '$rootScope', '$modalInstance', '$q', 'bfc.dialog.Dialog', 'educacao.matricula.ConfiguracaoInscricaoService'];

    function ClassificacaoInscritosComunicadoCtrl(processo, $injector, promiseTracker, $rootScope, $modalInstance, $q, bfcDialog, ConfiguracaoInscricaoService) {

        var ConfiguracaoProcessoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoProcessoInscricaoService'),
            TipoDocumentoSelect = $injector.get('educacao.matricula.TipoDocumentoSelect');

        var vm = this,
            select2 = {};
        vm.tracker = {};
        vm.tracker.save = promiseTracker();
        vm.tracker.loading = promiseTracker();

        vm.save = save;
        vm.adicionarDocumento = adicionarDocumento;
        vm.removeItem = removeItem;
        vm.opemViewMensagem = opemViewMensagem;
        vm.getListDocumentos = getListDocumentos;

        vm.select2 = select2;

        vm.TIPO_RESULTADO = {
            EXIBIR_TODAS_ESCOLAS: 'EXIBIR_TODAS_ESCOLAS',
            EXIBIR_ESCOLAS_CLASSIFICADAS: 'EXIBIR_ESCOLAS_CLASSIFICADAS',
        };

        init();

        function init() {
            vm.tracker.loading.addPromise(
                ConfiguracaoProcessoInscricaoService.getProcessosInscricao().then(function(dados) {
                    _.forEach(dados, function(item) {
                        if (item.id === processo.id) {
                            vm.processo = item;

                            if (!vm.processo.documentos.length) {
                                vm.processo.documentos = [];
                                adicionarDocumento();
                            }

                            vm.tipoResultado = vm.processo.tipoExibicaoCandidato === vm.TIPO_RESULTADO.EXIBIR_TODAS_ESCOLAS;
                            select2.TipoDocumento = TipoDocumentoSelect.select2Config(vm.processo.documentos);
                        }
                    });
                }).then(function() {
                    ConfiguracaoInscricaoService.hasInscritosWithOrdemPreferencia(vm.processo.id).then(function (data) {
                        vm.hasInscritosWithPreferencia = data;
                    });
                })
            );
        }

        function adicionarDocumento() {
            vm.processo.documentos.push({ tipoDocumento: null });
        }

        function removeItem(item) {
            var index = vm.processo.documentos.indexOf(item);
            vm.processo.documentos.splice(index, 1);
            if (!vm.processo.documentos.length) {
                adicionarDocumento();
            }
        }



        function ajustaDocumentos() {
            vm.processo.documentos = _.reject(vm.processo.documentos, function(data) {
                return !data.tipoDocumento;
            });
        }

        function getListDocumentos() {
            var result = [];
            vm.processo.documentos.forEach(function(data) {
                if (_.get(data, 'tipoDocumento') && data.tipoDocumento.id) {
                    result.push(data.tipoDocumento);
                }
            });
            return result;
        }

        function opemViewMensagem(vaga) {
            bfcDialog.open({
                templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-comunicado-mensagem.html',
                controller: 'educacao.matricula.ClassificacaoInscritosComunicadoMensagemCtrl as vm',
                resolve: {
                    processo: vm.processo,
                    vaga: vaga
                }
            });
        }

        function save() {
            vm.processo.tipoExibicaoCandidato = vm.tipoResultado ? vm.TIPO_RESULTADO.EXIBIR_TODAS_ESCOLAS : vm.TIPO_RESULTADO.EXIBIR_ESCOLAS_CLASSIFICADAS;
            ajustaDocumentos();

            vm.tracker.save.addPromise(
                ConfiguracaoProcessoInscricaoService.putProcesso(vm.processo).then(function(data) {
                    $rootScope.$broadcast('refresh::classificacoes', {
                        data: data,
                        comunicado: true
                    });
                    close();
                })
            );
        }

        function close() {
            $modalInstance.close();
        }
    }
})
();

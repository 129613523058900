(function() {
    'use strict';

    var IMAGE_TAG = 'IMG';

    angular.module('educacao.common').directive('appImage', Directive);

    Directive.$inject = [];

    function Directive() {
        return {
            restrict: 'A',
            scope: {},
            bindToController: {
                appImage: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'Restangular', '$element'];

    function Controller($scope, $promiseTracker, Restangular, $element) {
        var vm = this;
        var isImgTag = $element[0].nodeName === IMAGE_TAG;

        vm.tacker = $promiseTracker();

        $scope.$watch('vm.appImage', function() {
            if (vm.appImage && vm.appImage.id) {
                var promise = Restangular.one('arquivo', vm.appImage.id)
                    .all('dataurl')
                    .withHttpConfig({ cache: true})
                    .get('')                    
                    .then(setImage);

                vm.tacker.addPromise(promise);
            } else {
                setImage();
            }
        });

        function setImage(img) {
            if (isImgTag) {
                $element.attr('src', img || '');
            } else {
                $element.css('background-image', img ? 'url("' + img + '")' : '');
            }
        }
    }
})
();

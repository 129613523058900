(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('tabelaCamposInscricao', directive);

    var abbrs = {
        'RG': 'Registro Geral',
        'CPF': 'Cadastro de Pessoas Físicas',
        'UF': 'Unidade da Federação',
        'RIC': 'Registro de Identidade Civil',
        'PIS/NIS': 'Programa de Integração Social/Número de Inscrição Social'
    };

    function directive() {
        return {
            restrict: 'E',

            scope: {
                listaCampos: '=',
                camposFixos: '=',
                camposInscricao: '=',
                listaEspera: '=',
                listaEsperaPublica: '=',
                configuracaoListaEsperaPublica: '=',
                nivelEscolar: '='
            },

            templateUrl: 'matricula/configuracao-inscricao/directives/tabela-campos-inscricao.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', '$filter', 'promiseTracker', 'educacao.matricula.CamposInscricaoService', '$enumsCache'];

    function Controller($scope, $filter, promiseTracker, CamposInscricaoService, $enumsCache) {
        var vm = this;
        var filter = $filter('filter');

        vm.EDUCACAO_INFANTIL_FIELDS = ['PROGRAMAS_SOCIAIS','POSSUI_IRMAO_ESTABELECIMENTO'];
        vm.EDUCACAO_INFANTIL = 'EDUCACAO_INFANTIL';

        vm.emptySearch = false;
        vm.groups = [];

        vm.setGroupVisibility = setGroupVisibility;
        vm.setGroupRequired = setGroupRequired;
        vm.setGroupPublico = setGroupPublico;

        vm.updateField = updateField;

        vm.tracker = promiseTracker();

        $scope.$watch('vm.search', filterFields);

        init();

        function init() {
            var promise = $enumsCache.get('GrupoCadastro').then(function (data) {
                vm.GrupoCadastro = data;

                if ($scope.listaEspera) {
                    $enumsCache.get('CampoListaEspera').then(setCampoInscricao);
                } else {
                    $enumsCache.get('CampoInscricao').then(setCampoInscricao);
                }

            }).then(function(){
                $scope.$watchGroup(['listaCampos', 'camposFixos', 'camposInscricao'], createGroups);
            });

            vm.tracker.addPromise(promise);

            function setCampoInscricao(data) {
                vm.CampoInscricao = data;
            }
        }

        function createGroups() {
            var listaCampos = $scope.listaCampos;
            vm.groups = [];
            var groupsMap = {};

            _.forEach(listaCampos, function (campo) {
                if ($scope.nivelEscolar !== vm.EDUCACAO_INFANTIL && vm.EDUCACAO_INFANTIL_FIELDS.includes(campo.campo)) {
                    return;
                }

                var key = campo.grupo;
                var group = groupsMap[key];

                if (!group) {
                    group = {
                        key: key,
                        $$label: vm.GrupoCadastro[key].descricao,
                        collapsed: true,
                        fields: [],
                        filtered: []
                    };

                    vm.groups.push(group);
                    groupsMap[key] = group;
                }
                var field = createField(campo, group);
                group.fields.push(field);
                group.filtered.push(field);
            });

            updateValue();
        }

        function createField(campo, group) {
            var found = _.find($scope.camposInscricao, 'campo', campo.campo);
            var fixed = isFixed(campo);

            if (found) {
                found.$$visible = true;
            } else {
                found = {
                    campo: campo.campo,
                    campoMestre: campo.campoMestre,
                    $$visible: fixed,
                    obrigatorio: fixed,
                    grupo: campo.grupo
                };
            }

            found.$$label = getDescriptionField(campo.campo);
            found.$$html = wrapAbbreviations(found.$$label);
            found.$$group = group;
            found.$$fixed = fixed;


            return found;
        }

        function getDescriptionField(campo) {
            if (campo === 'CNS') {
                return 'Cartão Nacional de Saúde (CNS)';
            }

            if (campo === 'PIS' || campo === 'NRO_PIS') {
                return 'Nº do PIS/NIS';
            }

            if (campo === 'DT_EMISSAO_PIS' || campo === 'DT_PIS') {
                return 'Data de emissão do PIS/NIS';
            }

            return vm.CampoInscricao[campo].descricao;
        }

        function isFixed(campo) {
            return _.any($scope.camposFixos, 'campo', campo.campo);
        }

        function filterFields() {
            if (_.isEmpty(vm.groups)) {
                return;
            }

            var emptyGroups = 0;

            _.forEach(vm.groups, function (group) {
                group.filtered = filter(group.fields, {
                    $$label: vm.search
                });

                if (!group.filtered.length) {
                    emptyGroups++;
                }
                group.collapsed = !vm.search;
            });

            vm.emptySearch = vm.groups.length === emptyGroups;
        }

        function setGroupVisibility(group) {
            var fieldsVisible = 0;

            _.forEach(group.filtered, function (field) {
                if (!field.$$fixed) {
                    field.$$visible = group.visible || field.obrigatorio;
                }
                fieldsVisible += field.$$visible ? 1 : 0;
            });

            if (!fieldsVisible) {
                group.visible = false;
            } else if (fieldsVisible === group.filtered.length) {
                group.visible = true;
            } else {
                group.visible = undefined;
            }

            updateValue();
        }

        function setGroupRequired(group) {
            var fieldsRequired = 0;

            _.forEach(group.filtered, function (field) {
                if (!field.$$fixed) {
                    field.$$visible = group.required || field.$$visible;
                    field.obrigatorio = group.required;
                }
                fieldsRequired += field.obrigatorio ? 1 : 0;
            });

            if (!fieldsRequired) {
                group.required = false;
            } else if (fieldsRequired === group.filtered.length) {
                group.visible = group.required = true;
            } else {
                group.visible = undefined;
            }

            updateValue();
        }

        function setGroupPublico(group) {
            _.forEach(group.filtered, function (field) {
                field.publico = group.publico;
            });
            updateValue();
        }

        function updateField(field) {
            field.$$visible = field.obrigatorio || field.$$visible;

            updateParent(field);
            updateChildren(field);

            updateValue();
        }

        function updateParent(field) {
            var campoMestre = field.campoMestre;
            if (!campoMestre) {
                return;
            }
            var parentField = _.find(field.$$group.fields, 'campo', campoMestre);
            parentField.$$visible = parentField.$$visible || field.$$visible;
            parentField.obrigatorio = parentField.obrigatorio || field.obrigatorio;

            updateParent(parentField);
        }

        function updateChildren(field) {
            var childrenField = _.filter(field.$$group.fields, 'campoMestre', field.campo);

            _.forEach(childrenField, function (child) {
                if (!field.$$visible) {
                    child.$$visible = false;
                }
                if (!field.obrigatorio) {
                    child.obrigatorio = false;
                }
                updateChildren(child);
            });
        }

        function updateValue() {
            if (_.isEmpty(vm.groups)) {
                return;
            }

            $scope.camposInscricao = $scope.camposInscricao || [];
            $scope.camposInscricao.splice(0, $scope.camposInscricao.length);

            _.forEach(vm.groups, function (group) {
                var requiredFields = 0;
                var visibleFields = 0;
                _.forEach(group.fields, function (field) {
                    if (field.$$visible) {
                        $scope.camposInscricao.push(field);
                    }
                    visibleFields += field.$$visible ? 1 : 0;
                    requiredFields += field.obrigatorio ? 1 : 0;

                    if (!visibleFields) {
                        group.visible = false;
                    } else if (visibleFields === group.fields.length) {
                        group.visible = true;
                    } else {
                        group.visible = undefined;
                    }

                    if (!requiredFields) {
                        group.required = false;
                    } else if (requiredFields === group.fields.length) {
                        group.required = true;
                    } else {
                        group.required = undefined;
                    }
                });
            });
        }

        function wrapAbbreviations(text) {
            var replaced = null;
            var index = 0;
            _.forEach(abbrs, function (meaning, key) {
                if (text.indexOf(key) !== -1 && index === 0) {
                    replaced = text.replace(key, '<abbr title="' + meaning + '">' + key + '</abbr>');
                    index++;
                } else if (replaced !== null && replaced.indexOf(key) !== -1 && index !== 0) {
                    replaced = replaced.replace(key, '<abbr title="' + meaning + '">' + key + '</abbr>');
                    return false;
                }
            });

            return replaced || text;
        }
    }
})();

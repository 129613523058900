(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('appSelectFuncaoGestaoPesssoas', SelectFuncaoDirective);

    function SelectFuncaoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select  placeholder="placeholder" ngclass="ngclass"  ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" ng-change="ngChange()" search="vm.search"/>',
            scope: {
                ngModel: '=ngModel',
                ngChange: '&?',
                placeholder: '=',
                ngclass: '=',

            },
            controller: SelectFuncaoDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            },

        };
    }

    SelectFuncaoDirectiveController.$inject = ['$scope', 'educacao.funcionario.FuncaoGratificadaService', '$document'];

    function SelectFuncaoDirectiveController($scope, service) {
        var vm = this;
        vm.search = search;

        function search(params) {
            return service.list('(descricao like "' + params.term + '")', params.offset, params.limit);

        }
    }
})();


(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar').
        service('educacao.desempenhoescolar.DesempenhoEscolarPeriodoViewService', DesempenhoEscolarPeriodoViewService);

    DesempenhoEscolarPeriodoViewService.$inject = [
        'educacao.itemAvaliavel.ItemAvaliavelUtilsService',
        'STATUS_ENCERRAMENTO',
        'ITEM_AVALIAVEL_TIPO_PERIODO'
    ];

    function DesempenhoEscolarPeriodoViewService(ItemUtilsService, STATUS_ENCERRAMENTO, ITEM_AVALIAVEL_TIPO_PERIODO) {

        var enumTipoPeriodo, encerramentosTurma;

        var self = this;

        self.getPeriodosFromItensAvaliaveis = getPeriodosFromItensAvaliaveis;
        self.configureService = configureService;

        function configureService(enumPeriodos, encerramentos) {
            enumTipoPeriodo = enumPeriodos;
            encerramentosTurma = encerramentos;
        }

        function getPeriodosFromItensAvaliaveis(itensAvaliaveis) {
            //Recupera períodos avaliativos dos itens avaliaveis
            var periodosAvaliativos = _.chain(itensAvaliaveis)
                .map(_.property('periodoAvaliativo'))
                .unique(_.property('id'))
                .compact()
                .sortBy('dataInicial')
                .map(function (periodo) {
                    return new PeriodoWrapper(periodo, {
                        id: periodo.id.toString(),
                        encerrado: getIsPeriodoEncerrado(periodo)
                    });
                })
                .value();

            //Caso não haja períodos avaliativos nos itens, busca a média do período para atividade
            if (_.isEmpty(periodosAvaliativos)) {
                //Busca item atividade com média período
                var itemAtividade = _.find(itensAvaliaveis, 'tipoPeriodo', ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_PERIODO);

                //Cria período de média período para atividade. 
                //Todas as atividades possuem o período de média período igual
                periodosAvaliativos.push(new PeriodoWrapper(itemAtividade.tipoPeriodo, {
                    id: itemAtividade.idPeriodo,
                    descricao: itemAtividade.descricao,
                    encerrado: getIsPeriodoEncerrado(itemAtividade.tipoPeriodo)
                }));
            }

            //Cálculo
            var calculo = _.find(itensAvaliaveis, function (item) {
                return (ItemUtilsService.isTipoPeriodoCalculo(item));
            });
            if (calculo) {
                periodosAvaliativos.push(
                    createPeriodoEnum(calculo)
                );
            }

            //Nota para exame
            var notaParaExame = _.find(itensAvaliaveis, function (item) {
                return (ItemUtilsService.isTipoPeriodoNotaParaExame(item));
            });
            if (notaParaExame) {
                periodosAvaliativos.push(
                    createPeriodoEnum(notaParaExame)
                );
            }

            //Recupera periodos de exames dos itens avaliáveis
            var examesFinais = _.chain(itensAvaliaveis)
                .filter({
                    tipoPeriodo: ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL
                })
                .unique(_.property('identificadorExameFinal'))
                .sortBy(_.property('identificadorExameFinal'))
                .map(function (exameFinal) {
                    return new PeriodoWrapper(exameFinal.tipoPeriodo, {
                        id: exameFinal.idPeriodo,
                        identificadorExameFinal: exameFinal.identificadorExameFinal,
                        descricao: exameFinal.descricao,
                        data: exameFinal.data,
                        encerrado: getIsPeriodoEncerrado({
                            id: exameFinal.tipoPeriodo,
                            identificadorExameFinal: exameFinal.identificadorExameFinal
                        })
                    });
                })
                .value();

            periodosAvaliativos = periodosAvaliativos.concat(examesFinais);

            var enumsMedia = [];

            //Recupera demais períodos baseados nos tipos de períodos dos itens avaliáveis
            enumsMedia = _.filter(itensAvaliaveis, function (item) {
                return (ItemUtilsService.isTipoPeriodoConselhoClasse(item) || ItemUtilsService.isTipoPeriodoMediaFinal(item));
            });
            enumsMedia = _.unique(enumsMedia, 'tipoPeriodo');

            var periodosRestantes = enumsMedia
                .map(function (enumItemMedia) {
                    return createPeriodoEnum(enumItemMedia);
                });

            var periodos = periodosAvaliativos.concat(periodosRestantes);

            return {
                periodos: periodos,
                periodosPage: {
                    content: _.filter(periodos, filterListPeriodos)
                }
            };
        }

        function createPeriodoEnum(enumItemMedia) {
            return new PeriodoWrapper(enumItemMedia.tipoPeriodo, {
                id: enumItemMedia.idPeriodo,
                descricao: enumItemMedia.descricao,
                data: enumItemMedia.data || null
            });
        }

        /**
         * Função que filtra os períodos que poderão ser selecionados no select2 de períodos
         */
        function filterListPeriodos(item) {
            return (item.id !== ITEM_AVALIAVEL_TIPO_PERIODO.MEDIA_FINAL && item.id !== ITEM_AVALIAVEL_TIPO_PERIODO.CALCULO_MEDIA &&
                item.id !== ITEM_AVALIAVEL_TIPO_PERIODO.NOTA_PARA_EXAME);
        }

        /**
         * Define se o período está encerrado ou não, através da lista de encerramentos
        */
        function getIsPeriodoEncerrado(periodo) {
            var idPeriodo = _.get(periodo, 'id') || periodo;

            return _.find(encerramentosTurma, function (encerramento) {
                if (_.get(encerramento, 'tipoPeriodo') === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL) {
                    return (_.get(encerramento, 'identificadorExameFinal') === _.get(periodo, 'identificadorExameFinal') && 
                        _.get(encerramento, 'status') === STATUS_ENCERRAMENTO.ENCERRADO);
                }

                return (_.get(encerramento, 'periodoAvaliativo.id') === idPeriodo ||
                    (_.get(encerramento, 'tipoPeriodo') === idPeriodo)) &&
                    _.get(encerramento, 'status') === STATUS_ENCERRAMENTO.ENCERRADO;
            });
        }

        function PeriodoWrapper(periodo, extendObj) {
            var selfPeriodo = this;

            selfPeriodo.id = periodo.id || periodo;
            selfPeriodo.rawValue = periodo;

            if (_.isString(periodo)) {
                selfPeriodo.descricao = _.get(enumTipoPeriodo[periodo], 'description') || periodo;
            } else {
                selfPeriodo.descricao = periodo.descricao;
            }
            _.extend(selfPeriodo, extendObj);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.avaliacao-externa')
        .factory('educacao.avaliacao-externa.AvaliacaoExternaService', AvaliacaoExternaService);

    AvaliacaoExternaService.$inject = ['Restangular'];

    function AvaliacaoExternaService(Restangular) {

        return {
            getAvaliacoes: getAvaliacoes
        };

        function getAvaliacoes(offset, limit, filter) {

            return Restangular.one('pessoa').all('avaliacao-externa').get('', {
                offset: offset,
                limit: limit,
                filter: filter
            });
        
        }
    
    }

})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .service('educacao.matricula.RegistroDispensaService', RegistroDispensaService);

    RegistroDispensaService.$inject = ['ServiceBuilder', 'Restangular'];
    function RegistroDispensaService(ServiceBuilder, Restangular) {
        var service = ServiceBuilder.create()
            .path('matricula', 'registros-dispensas')
            .enum('TipoMatricula')
            .build();

        service.saveList = saveList;

        function saveList(registros){
            return Restangular.one('matricula/registros-dispensas/lista-registros').customPOST(registros);
        }
        return service;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoInscritosIndeferirCtrl', ClassificacaoInscritosIndeferirCtrl);

    ClassificacaoInscritosIndeferirCtrl.$inject = ['classificacao','promiseTracker','educacao.matricula.IndeferirClassificacaoService','$rootScope','$q','$modalInstance', 'onClose'];

    function ClassificacaoInscritosIndeferirCtrl(classificacao,promiseTracker,IndeferirClassificacaoService,$rootScope,$q,$modalInstance, onClose) {
        
        var vm = this;
        vm.tracker = {};
        vm.tracker.botoes = promiseTracker();
        vm.tracker.loading = promiseTracker();
        vm.save = save;

        init();

        function init() {
          vm.tracker.loading.addPromise(
              IndeferirClassificacaoService.getDefault(classificacao.classificacaoInscricao.configuracaoInscricao.id).then(function(data) {
                 vm.indeferido = angular.extend(data,  { resultado: { id: classificacao.id } });  
              })
            );
        }

        function save() {
            vm.tracker.botoes.addPromise(
            	IndeferirClassificacaoService.indeferir(classificacao.classificacaoInscricao.configuracaoInscricao.id,vm.indeferido).then(function(data){
                    $rootScope.$broadcast('refresh::classificacoes', {
                        data: data,
                        indeferir: true
                    });
                })
                .then(onClose)
                .then(close)
            );            
        }

        function close() {
            $modalInstance.close();
        }

    }


})();

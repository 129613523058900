(function () {
    'use strict';

    angular
        .module('educacao.pessoas')
        .directive('appFiltroAvancadoEscolaAreaAtuacao', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl:
                'planejamento/escolas/filtro-avancado/escola/area-atuacao/area-atuacao.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                expressionCustomBuilders: '=',
                advancedFilter: '=',
                contadorAreaAtuacao: '='
            },
            bindToController: true
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.changeContadorAreaAtuacao = changeContadorAreaAtuacao;

        vm.expressionCustomBuildersAreaAtuacao = {
            linguasIndigenas: function(filters) {
                if (filters.linguasIndigenas) {
                    return 'linguasIndigenas = ' + filters.linguasIndigenas.id;
                }
            }
        };

        $scope.$watchCollection(function() { 
            if(vm.advancedFilter.areaAtuacao) {
                return Object.values(vm.advancedFilter.areaAtuacao);
            }
        }, changeContadorAreaAtuacao);

        init();

        function init() {
            vm.contadorAreaAtuacao = 0;

            changeContadorAreaAtuacao();
        }

        function changeContadorAreaAtuacao() {
            if (vm.advancedFilter && vm.advancedFilter.areaAtuacao) {
                vm.contadorAreaAtuacao = getFilterLength();
            }
        }

        function getFilterLength() {
            return Object.values(vm.advancedFilter.areaAtuacao).filter(function (filtroAvancadoSelecionado) {
                return filtroAvancadoSelecionado === false || !!filtroAvancadoSelecionado && filtroAvancadoSelecionado.length !== 0;
            }).length;
        }
    }
})();

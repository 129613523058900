(function() {
    'use strict';

    angular.module('educacao.planejamento').controller('educacao.pessoas.EstabelecimentoAvaliacoesCadCtrl', EstabelecimentoAvaliacoesCadCtrl);

    EstabelecimentoAvaliacoesCadCtrl.$inject = [
        'promiseTracker',
        '$modalTracker',
        '$q',
        'optionsCad',
        '$rootScope',
        '$modalInstance',
        '$injector',
        '$scope',
        'readOnly',
        'bfc.Notification',
        'fnCad',
        'avaliacao',
        'id'
    ];

    function EstabelecimentoAvaliacoesCadCtrl(
        promiseTracker,
        $modalTracker,
        $q,
        optionsCad,
        $rootScope,
        $modalInstance,
        $injector,
        $scope,
        readOnly,
        Notification,
        fnCad,
        avaliacao,
        id) {

        var GenericService = $injector.get('GenericService'),
            focus = $injector.get('bfc.Focus'),
            CommonSelectEnum = $injector.get('educacao.common.CommonSelectEnum'),
            AvaliacoesExternasService = $injector.get('educacao.planejamento.AvaliacoesExternasService'),
            AvaliacaoExternaSelect = $injector.get('educacao.planejamento.AvaliacaoExternaSelect');


        var EstabAvalExternaService = GenericService.of('pessoa', 'estabelecimento-avaliacao-externa');

        var vm = this;
        var _select2 = {};

        var _modo = {
            adicionando: !avaliacao && !readOnly,
            editando: avaliacao && !readOnly
        };

        vm.tracker = {};
        vm.tracker.saveContinue = promiseTracker();
        vm.tracker.save = promiseTracker();
        vm.tracker.remove = promiseTracker();
        vm.select2 = _select2;
        vm.save = _saveAndValidate;
        vm.modo = _modo;
        vm.remove = _remove;

        _init();

        function _init() {
            if (_modo.editando) {
                vm.windowTitle = 'Editando avaliação externa';
            } else {
                vm.windowTitle = 'Adicionando avaliação externa';
            }
            $modalTracker.addPromise(
                $q.all([
                    (function() {
                        if (vm.form) {
                            vm.form.$setPristine();
                        }
                    })(),
                    _refresh(),
                    _getEnums(),
                    _refreshAvaliacoes()
                ]).then(
                    function postInit() {}
                )
            );

        }

        function _refreshAvaliacoes() {
            _select2.AvaliacaoExterna = AvaliacaoExternaSelect.select2Config();
        }

        function _getEnums() {
            AvaliacoesExternasService.getEnums().then(function(enums) {
                _select2.EtapaEnsinoAvaliada = CommonSelectEnum.select2Config({
                    values: enums.EtapaEnsinoAvaliada,
                    description: 'description'
                });
            });
        }

        function _refresh() {

            if (vm.form) {
                vm.form.$setPristine();
            }
            return Boolean(avaliacao) ?

                _getAvalicaoModel(avaliacao) :

                EstabAvalExternaService.getDefault().then(function(data) {
                    vm.avaliacao = angular.extend(data, optionsCad);

                });
        }

        function _getAvalicaoModel(data) {
            vm.avaliacao = data;
            vm.id = id;
            vm.avaliacao.etapaEnsinoAvaliada = _refreshAvaliacaoExterna();
        }

        $scope.$watch('EstabAvalCadCtrl.avaliacao.avaliacaoExterna', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.etapaEnsinoAvaliada = newValue;
            }
        });

        function _refreshAvaliacaoExterna() {
            return AvaliacoesExternasService.get(vm.avaliacao.avaliacaoExterna.id).then(function(data) {
                vm.etapaEnsinoAvaliada = data;
            });
        }

        function _validate() {
            return fnCad(vm.avaliacao);
        }

        function _saveAndValidate(continuar) {
            _validate(continuar).then(function() {
                _save(continuar);
            }, function(msg) {
                Notification.publish(msg, 'error');
            });
        }

        function _save(continuar) {
            var tracker = continuar ? vm.tracker.saveContinue : vm.tracker.save;
            $rootScope.$broadcast('refresh::AvaliacaoExterna', {
                data: vm.avaliacao,
                add: !avaliacao,
                edit: Boolean(avaliacao)
            });
            onSuccess(continuar, tracker);
        }

        function _remove() {
            $rootScope.$broadcast('refresh::AvaliacaoExterna', {
                data: vm.avaliacao,
                id: vm.avaliacao.avaliacaoExterna.id,
                remove: true
            });

            onSuccess();
        }

        function onSuccess(continuar, tracker) {
            if (continuar) {
                avaliacao = null;
                vm.avaliacao = [];
                tracker.addPromise(_refresh());
                focus('lbAvaliacao');
            } else {
                _cancel();
            }
        }

        function _cancel() {
            $modalInstance.close();
        }

    }


})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('appClassificacaoListaClassificados', ClassificacaoListaClassificados);

	function ClassificacaoListaClassificados() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/classificacao-inscritos/directives/classificacao-lista-classificados.directive.html',
			controller: Controller,
			controllerAs: 'vm',
			scope: {
				showUtilizaEncaminhamento: '=',
				visualizarInscricao: '=',
				listControls: '=',
				searchFilter: '=',
				listaEspera: '=',
				matricular: '=',
				encaminhar: '=',
				indeferir: '=',
				processo: '=',
				chamada: '=',
				lista: '=',
				inscritosEncaminhamentoLote: '=',
				configuracao: '=',
			}
		};
	}

	Controller.$inject = ['$q', '$scope', 'educacao.matricula.ClassificacaoService', '$rootScope'];

	function Controller($q, $scope, ClassificacaoService, $rootScope) {
		var vm = this;

		vm.listByParams = listByParams;

		$rootScope.$on('encaminhamentoLoteItemDeleted', uncheckCheckboxOnDeleteEvent);

		$scope.$watch('vm.inscritosEncaminhamentoLote', function (newValue) {
			if (!newValue) {
				return;
			}

			if (_.isEmpty(newValue)) {
                $scope.inscritosEncaminhamentoLote = [];
                return;
            }

			var inscritos = [];

			_.forEach(newValue, function (inscrito) {
				if (!_.isEmpty(inscrito)) {
					inscritos = inscritos.concat(inscrito);
				}
			});

			$scope.inscritosEncaminhamentoLote = inscritos;
		}, true);

		vm.inscritosEncaminhamentoLote = {};

		function listByParams(params) {
			var idProcesso = _.get($scope.processo, 'id');
			var idChamada = _.get($scope.chamada, 'id');
			if (!idProcesso || !idChamada) {
				return $q.when();
			}
			return ClassificacaoService.getListaClassificados(idProcesso, idChamada, params).then(function(data) {
				$scope.lista = _.cloneDeep(data.content);
				initInscritosEncaminhamentoLote(data.content);
				return data;
			});
		}

		function initInscritosEncaminhamentoLote(listaClassificacao) {
			_.forEach(listaClassificacao, function (classificacao) {
				vm.inscritosEncaminhamentoLote[classificacao.candidato.id] = [];
			});
		}

		function uncheckCheckboxOnDeleteEvent(event, args) {
			if (_.isEmpty(args)) {
				vm.inscritosEncaminhamentoLote = {};
				return;
			}

			var candidatosEncaminhadosIds = _.map(args, function (data) {
				return data.candidato.id;
			});

			_.forEach(vm.inscritosEncaminhamentoLote, function (data, key) {
				if (!candidatosEncaminhadosIds.includes(parseInt(key))) {
					vm.inscritosEncaminhamentoLote[key] = [];
				}
			});
		}
	}

})();

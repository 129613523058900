(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.AlunoUtilsService', Service);

    Service.$inject = [];

    function Service() {
     
        var self = {};
        self.getPessoas =getPessoas; 
        self.diasSemana = diasSemana;
        return self;

        function getPessoas(pessoa,endPoint) {

            var pessoaTermSemCaracterEspecial = encodeURI('%' + removeCaracteresEspeciais(pessoa) + '%');
            var pessoaTerm = encodeURI('%' + pessoa + '%');

            var filter = '(' +
                '(nome like "' + pessoaTerm + '")' +
                ' or (cpf like "' + pessoaTermSemCaracterEspecial + '")' +
                ' or (carteiraProf like "' + pessoaTerm + '")' +
                ' or (cns like "' + pessoaTerm + '")' +
                ' or (pis like "' + pessoaTermSemCaracterEspecial + '")' +
                ' or (rg like "' + pessoaTerm + '")' +
                ' or (ric like "' + pessoaTerm + '"))';

            return endPoint()
                .one('pessoas')
                .get({
                    filter: filter
                });
        }

        function removeCaracteresEspeciais(texto) {
            var er = /\^|~|\?|,|\*|\.|\-/g;
            return texto.replace(er, '');
        }
        
      function diasSemana(pessoa,enumDiasSemana) {
          if(!enumDiasSemana){
              return;
          }
            var stringBuilder = '';
            var dias = pessoa.diaSemana;

            if (dias.length === 7) {
                return stringBuilder += 'Todos os dias da semana';
            }

            dias.sort(
                function (diaA, diaB) {
                    var valueA = enumDiasSemana[diaA].value,
                        valueB = enumDiasSemana[diaB].value;
                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                    return 0;
                })
                .forEach(function (dia, key) {
                    if (key > 0) {
                        stringBuilder += ', ';
                    }
                    stringBuilder += enumDiasSemana[dia].description.split('-')[0];
                });

            return stringBuilder;
        }             
    }
})();
(function() {
    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioEstabelecimentoService', CalendarioEstabelecimentoService);

    CalendarioEstabelecimentoService.$inject = ['Restangular'];

    function CalendarioEstabelecimentoService(Restangular) {
        var base = Restangular.one('calendario/estabelecimento');

        return {
            getCalendarioAnoLetivo: getCalendarioAnoLetivo,
            listCalendariosAnoLetivo: listCalendariosAnoLetivo,
            get: get,
            listAvisos: listAvisos,
            save: save,
            iniciarCalendario: iniciarCalendario,
            excluirCalendario: excluirCalendario,
            isIniciado: isIniciado,
        };

        function iniciarCalendario() {
            return base.post();
        }

        function excluirCalendario(id){
            return base.one(id.toString()).remove();
        }

        function getCalendarioAnoLetivo() {
            return base.get();
        }

        function listCalendariosAnoLetivo() {
            return base.all('lista').getList();
        }

        function get(id) {
            return base.one(id.toString()).get();
        }

        function listAvisos(id) {
            return base.one(id.toString()).one('avisos').getList();
        }

        function save(calendario) {
            calendario = plain(calendario);

            if (calendario.id) {
                return base.customPUT(calendario, calendario.id);
            } else {
                return base.post(calendario);
            }
        }

        function isIniciado(){
            
            return base.one('iniciado').get();
        
        }

        function plain(obj) {
            if (obj === null) {
                return null;
            }
            if (!angular.isObject(obj)) {
                return obj;
            }

            angular.forEach(obj, function(value, key) {
                if (angular.isArray(value)) {
                    plainArray(value);
                } else if (angular.isObject(value)) {
                    obj[key] = plain(value);
                }
            });

            return Restangular.stripRestangular(obj);
        }

        function plainArray(objectArray) {
            if (!objectArray) {
                return;
            }
            angular.forEach(objectArray, function(value, index) {
                objectArray[index] = plain(value);
            });
        }
    }
})();

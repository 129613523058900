(function () {
    'use strict';

    angular.module('educacao.calendario')
        .filter('formatHolydayDate', formatHolydayDate);

    formatHolydayDate.$inject = ['HOLYDAY_DATE_FORMAT'];

    function formatHolydayDate(formatPattern) {
        return format;

        function format(holyday) {
            return moment(holyday.feriado.dataFeriado).format(formatPattern);
        }
    }
})();

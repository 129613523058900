(function () {
    'use strict';

    angular.module('educacao.context')

        .constant('CONTEXT_REGION', 'educacao')

        .constant('CONTEXT_ACESSO', 'acesso')
        .constant('CONTEXT_ENTIDADE', 'entidade')
        .constant('CONTEXT_ANO_LETIVO', 'anoletivo')
        .constant('CONTEXT_ESTABELECIMENTO', 'estabelecimento')

        .constant('CONTEXTO_ESTABELECIMENTO_SECRETARIA', createSecretaria());

    function createSecretaria() {
        var secretaria = {
            id: 'secretaria',
            isSecretaria: true,
            descricao: 'Secretaria',
            pessoa: {
                nome: 'Secretaria'
            }
        };
        return secretaria;
    }
})();

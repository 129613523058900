(function () {
    'use strict';

    angular.module('educacao.matricula').factory('educacao.matricula.ClassificacaoInscricaoService', ClassificacaoInscricaoService);

    ClassificacaoInscricaoService.$inject = ['$injector'];

    function ClassificacaoInscricaoService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .create()
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'inscricao'
            });

    }

})();

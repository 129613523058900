(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.pessoas');

    moduloCommon.controller('educacao.pessoas.InscricaoOnlineFiliacaoCtrl', ['$scope',
        function($scope) {

            $scope.filiacao = [{
                nome: 'Ricardo Casagrande Farias',
                tipo: 'Pai'
            }, {
                nome: 'Vanessa Réus Müller',
                tipo: 'Mãe'
            }];

        }
    ]);
})();

(function () {
    'use strict';

    angular.module('educacao.frequencia')
        .config(FrequenciaStates);

    FrequenciaStates.$inject = ['$stateProvider'];

    function FrequenciaStates($stateProvider) {
        $stateProvider
            .state('app.frequencia', {
                url: 'frequencia',
                data: {
                    id: 'RegistroFrequenciaPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'frequencia/frequencia.html' ,
                        controller: 'educacao.frequencia.FrequenciaController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.TiposAvaliacaoCtrl', TiposAvaliacaoCtrl);

    TiposAvaliacaoCtrl.$inject = [
        '$rootScope',
        '$scope',
        'promiseTracker',
        'educacao.common.ObjectUtilsService',
        '$q',
        'educacao.matricula.TipoAvaliacaoService',
        'educacao.common.ModalCad'
    ];



    function TiposAvaliacaoCtrl(
        $rootScope,
        $scope,
        promiseTracker,
        ObjectUtilsService,
        $q,
        TipoAvaliacaoService,
        ModalCad) {

        var vm = this;

        vm.openCad = openCad;
        vm.tracker = promiseTracker();
        vm.tracker.loading = promiseTracker();
        vm.remove = removeConfiguracao;
        vm.isSearching = isSearching;
        vm.getIconSortClass = getIconSortClass;
        vm.orderBy = orderBy;
        vm.tipoAvaliacaoService = TipoAvaliacaoService;

        init();

        function init() {
            vm.tracker.loading.addPromise(
                $q.when(TipoAvaliacaoService.list().then(
                    function(data) {
                        vm.configuracoes = data.content;
                    }
                ))
            );
        }

        function getIconSortClass(sortBy) {
            return {
                'icon-caret-down': vm.predicate === sortBy && vm.reverse,
                'icon-caret-up': vm.predicate === sortBy && !vm.reverse,
                'icon-sort': vm.predicate !== sortBy
            };
        }

        function orderBy(item) {
            if (typeof vm.predicate === 'undefined') {
                vm.predicate = 'descricao';
            }
            return ObjectUtilsService.normalizeFilter(item[vm.predicate]);
        }

        function isSearching() {
            return Boolean($scope.search && ($scope.search.filter || $scope.search.value));
        }

        function removeConfiguracao(data) {

            TipoAvaliacaoService.remove(data).then(function() {
                $rootScope.$broadcast('refresh::configuracoes', {
                    data: data,
                    remove: true
                });
            });
        }

        $scope.$on('refresh::configuracoes', function(event, args) {

            if (args.data) {
                if (args.remove) {
                    vm.configuracoes = _.reject(vm.configuracoes, function(configuracao) {
                        return args.data.id === configuracao.id;
                    });

                } else if (args.add) {
                    vm.configuracoes.push(args.data);
                } else if (args.undo || args.edit) {
                    var configuracaoAvaliacao = _.find(vm.configuracoes, function(configuracao) {
                        return args.data.id === configuracao.id;
                    });

                    if (configuracaoAvaliacao) {
                        ObjectUtilsService.copyProperties(configuracaoAvaliacao, args.data);
                    } else {
                        throw new Error('configuracao avaliação não encontrada! ID: ' + args.data.configuracaoAvaliacao.id);
                    }
                }
            }
        });

        function openCad(id) {
            ModalCad.open({
                templateUrl: 'matricula/tipos-avaliacao/tipos.avaliacao.cad.html',
                controller: 'educacao.matricula.TiposAvaliacaoCadCtrl as vm',
                id: id,
                readOnly: false
            });
        }
    }
})();

(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizCompetenciasEixoCtrl', [
        '$modalInstance',
        'promiseTracker',
        'educacao.common.ObjectUtilsService',
        'educacao.matriz.EtapaDisciplinaService',
        'educacao.common.ModalCad',

    function Controller($modalInstance, promiseTracker, ObjectUtilsService, EtapaDisciplinaService, ModalCad) {

            var vm = this;

            vm.tracker = {};
            vm.tracker.botoes = promiseTracker();

            vm.etapaMatrizDisciplina = $modalInstance.params.etapaMatrizDisciplina;

            vm.competenciasEixo = vm.etapaMatrizDisciplina.competenciasEixo || {};
            vm.eixoTematico = vm.etapaMatrizDisciplina.eixoTematico;

            vm.matrizId = $modalInstance.params.matrizId;

            vm.editando = vm.competenciasEixo.length > 0;
            vm.adicionando = !vm.editando;

            vm.onChangeCompetencia = onChangeCompetencia;
            vm.openCompetenciaModal = openCompetenciaModal;
            vm.removerCompetencia = removerCompetencia;
            vm.cancel = cancel;
            vm.save = save;

            function onChangeCompetencia(competencia) {
                if(!competencia){
                    return;
                }

                var competenciaSelecionada = _.find(vm.competenciasEixo, {
                    'id': competencia.id
                });

                if (competenciaSelecionada) {
                    ObjectUtilsService.copyProperties(competenciaSelecionada, competencia);
                } else {
                    vm.competenciasEixo.push(competencia);
                }

                vm.competencia = null;
            }

            function openCompetenciaModal(competenciaId) {
                ModalCad.open({
                    templateUrl: 'planejamento/matriz/competencia/competencia.cad.html',
                    controller: 'educacao.matriz.CompetenciaCadCtrl',
                    id: competenciaId,
                    readOnly: false
                });
            }

            function removerCompetencia(competenciaId){
                vm.competenciasEixo = _.reject(vm.competenciasEixo, {
                    'id': competenciaId
                });
            }

            function cancel() {
                $modalInstance.close();
            }

            function save() {
                var tracker = vm.tracker.botoes;

                vm.etapaMatrizDisciplina.competenciasEixo = vm.competenciasEixo;

                tracker.addPromise(EtapaDisciplinaService.save(vm.matrizId, vm.etapaMatrizDisciplina.etapa.id, vm.etapaMatrizDisciplina)
                    .then(cancel()));
            }
    }]);
})();

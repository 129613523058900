(function () {
    'use strict';
    angular
        .module('educacao.lista-espera')
        .controller('educacao.lista-espera.CancelaInscricaoController', Controller);

    Controller.$inject = [
        'promiseTracker',

        'educacao.lista-espera.InscricaoListaEsperaService',

        '$modalInstance',
        'idInscricao',
        'idModalidade',
        'idEstabelecimento',
        'turnosDisponiveis',
        'onClose'
    ];
    function Controller(promiseTracker, InscricaoListaEsperaService, $modalInstance, idInscricao, idModalidade, idEstabelecimento, turnosDisponiveis, onClose) {
        var vm = this;

        vm.cancelamento = {turnos: _.cloneDeep(turnosDisponiveis)};

        vm.loadingTracker = promiseTracker();
        vm.mostraSelecaoTurnos = Boolean(idEstabelecimento);

        vm.promiseTurnos = InscricaoListaEsperaService.getTurno();

        vm.filtraTurnos = filtraTurnos;
        vm.cancela = cancela;

        function filtraTurnos(turno, term) {
            return turnosDisponiveis.indexOf(turno.key) !== -1 &&
                (_.isEmpty(term) ||
                _.deburr(turno.description).toLocaleLowerCase().indexOf(_.deburr(term).toLocaleLowerCase()) !== -1);
        }

        function cancela() {
            vm.loadingTracker.addPromise(
                InscricaoListaEsperaService
                    .cancelar(idInscricao, idModalidade, idEstabelecimento, vm.cancelamento)
                    .then(onClose)
                    .then($modalInstance.close)
            );
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('columnGraphQuadroInformativo', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/column-graph/column-graph.directive.html',
            scope: {},
            bindToController: {
                data: '=',
                label: '=',
                value: '=',
                yAxisName: '=',
                title: '=',
                columnColor: '=',
                emptyStateTitle: '=',
                anoLetivoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        $scope.$watch('vm.data', init);

        vm.isAnoMaxEMinEqual = isAnoMaxEMinEqual;

        init();

        function init() {
            createGraphDatasource();

            if(!_.isEmpty(vm.data)) {
                vm.anosMaxEMin = getAnosMaxEMin(vm.data);
            }
        }

        function createGraphDatasource() {
            vm.datasource = {
                chart: {
                    yAxisName: _.isEmpty(vm.yAxisName) ? '' : vm.yAxisName,
                    theme: 'ocean',
                    formatNumberScale: '0',
                    placeValuesInside: '1',
                    bgAlpha: '100',
                    rotateValues: '0',

                    valueFontColor: '#222222',
                    decimals: '0',
                    forceDecimals: '1',
                    bgColor: '#F5F7FA',
                    canvasBgColor:'#F5F7FA',
                    paletteColors: _.isEmpty(vm.columnColor) ? '#B98CC6' : vm.columnColor,
                    plotSpacePercent: '60'
                },
                data: getData()
            };
        }

        function getData() {
            if(!vm.data) {
                return [];
            }

            return vm.data.map(function (item) {
                return { label: item[vm.label], value: item[vm.value] };
            });
        }

        function getAnosMaxEMin(data) {
            var anos = data.map(function (obj) {
                return obj.ano;
            });

            return {
                max: Math.max.apply(Math, anos),
                min: Math.min.apply(Math, anos)
            };
        }

        function isAnoMaxEMinEqual(anos) {
            if (!anos) {
                return false;
            }

            return anos.max === anos.min;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MotivosDeMovimentacaoCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'educacao.common.$commons', 
        '$modalStates',
        'educacao.matricula.MotivoMovimentacaoService'
    ];

    function Controller($scope, $commons, $modalStates, MotivosMovimentacaoService) {

        var vm = this,
         TIPOS_CADASTRAVEIS = [1, 2, 6, 7];

        vm.tracker = {};
        vm.tracker.loading = $commons.$promiseTracker();
        vm.enumTipo = MotivosMovimentacaoService.getTipoMovimentacao().then(function(data) {
                var copy = angular.copy(data);
                copy.values = _.filter(data.values, function(value) {
                    return TIPOS_CADASTRAVEIS.indexOf(value.value) !== -1;
                });
                return copy;
            });

        vm.filter = {};
        vm.columns = [
            {
                id: 'descricao',
                model: 'descricao',
                label: 'Motivo',
                filterable: true
            }
        ];

        vm.listUsuarioCustomBuilder = {
            onBeforeBuild: function () {
                return 'entidade is not null';
            }
        };

        vm.listPadraoCustomBuilder = {
            onBeforeBuild: function () {
                return 'entidade is null';
            }
        };

        vm.listUsuarioControls = {};
        vm.listPadraoControls = {};

        vm.MotivosMovimentacaoService = MotivosMovimentacaoService;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.search = search;

        activate();

        function activate() {
            MotivosMovimentacaoService.getTipoMovimentacao()
                .then(function (enumTipos) {
                    vm.enumTipoMotivoMovimentacao = enumTipos;
                });
        }

        function search() {
            vm.listUsuarioControls.search();
            vm.listPadraoControls.search();
        }

        function openCad(motivoMovimentacao) {
            $modalStates.goModalState(motivoMovimentacao);
        }

        function remove(motivoMovimentacao) {
            return MotivosMovimentacaoService.remove(motivoMovimentacao);
        }
    }
})();

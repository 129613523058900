(function () {
    'use strict';
    angular
        .module('educacao.lista-espera-novo.inscricao')
        .controller('educacao.lista-espera-novo.inscricao.CancelaInscricaoListaEspera', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$q',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        '$modalInstance',
        'bfc.Notification'
    ];
    function Controller(promiseTracker, $q, InscricaoListaEsperaService, $modalInstance, bfcNotification) {
        var vm = this;

        vm.estabelecimentoInscricao = $modalInstance.params.estabelecimentoInscricao;
        vm.inscricaoId = $modalInstance.params.inscricaoId;
        vm.etapaId = $modalInstance.params.etapaId;
        vm.configuracaoId = $modalInstance.params.configuracaoId;
        vm.onClose = $modalInstance.params.onClose;
        vm.nomeCandidato = $modalInstance.params.nomeCandidato;

        vm.loadingTracker = promiseTracker();
        vm.cancelaOuDescancela = cancelaOuDescancela;
        vm.searchTurnos = searchTurnos;
        vm.formatTurnos = formatTurnos;
        turnosConformeConfiguracao();
        function turnosConformeConfiguracao() {
            if (!_.get(vm.estabelecimentoInscricao, 'turnos')) {
                return;
            }
            vm.newTurnos = [];
            _.forEach(vm.estabelecimentoInscricao.turnos, function (turno) {
                vm.newTurnos.push({ descricao: turno.turno, id: Math.floor((Math.random() * 100) + 1) });
            });

            vm.turnos = vm.newTurnos;
        }

        function searchTurnos() {
            return $q.when(getTurnos());
        }

        function getTurnos() {
            return { content: vm.turnos };
        }

        function formatTurnos(data) {
            return data.descricao;
        }

        function createObjeto() {
            return {
                nivel: _.get(vm.estabelecimentoInscricao, 'turnos') ? 'TURNO' : vm.etapaId ? 'ETAPA' : 'INSCRICAO',
                idEtapa: vm.etapaId,
                idEstabelecimento: _.get(vm.estabelecimentoInscricao, 'estabelecimento.id'),
                motivo: vm.cancelamento.motivo,
                turnos: _.pluck(vm.cancelamento.turnos, 'descricao')
            };
        }

        function cancelaOuDescancela() {
            var dado = _.omit(createObjeto(), _.isEmpty, _.isUndefined);
            if (_.get(vm.estabelecimentoInscricao, 'situacao.cancelado')) {
                delete dado.motivo;
                vm.loadingTracker.addPromise(
                    InscricaoListaEsperaService
                        .desfazerCancelamento(vm.configuracaoId, vm.inscricaoId, dado)
                        .then(function () {
                            setMessage('Cancelamento da inscrição de '+ vm.nomeCandidato +' desfeito com sucesso.');
                        })
                        .then(vm.onClose)
                );
                return;
            }
            vm.loadingTracker.addPromise(
                InscricaoListaEsperaService
                    .cancelar(vm.configuracaoId, vm.inscricaoId, dado)
                    .then($modalInstance.close)
                    .then(function () {
                        setMessage('Inscrição de ' + vm.nomeCandidato + ' cancelado com sucesso.');
                    })
                    .then(vm.onClose)
            );
        }

        function setMessage(message) {
            bfcNotification.publish(message, 'success');
            $modalInstance.close();
        }
    }
})();
(function () {
    'use strict';

    var HOUR_FORMAT = 'HH[h]mm[min]';
    var DATE_HOUR_FORMAT = 'DD/MM/YYYY[ - ]' + HOUR_FORMAT;

    angular.module('educacao.common')
        .filter('dateTime', filter);

    function filter() {
        return format;
    }

    function format(dateTime) {
        var value = moment(dateTime);
        if(!value.isValid()) {
            return null;
        }

        if(moment().isSame(value, 'day')) {
            return value.format('[Hoje - ]' + HOUR_FORMAT);
        } else if(moment().subtract(1, 'day').isSame(value, 'day')) {
            return value.format('[Ontem - ]' + HOUR_FORMAT);
        }

        return value.format(DATE_HOUR_FORMAT);
    }
})();

(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.ObjetivosAprendizagemCadCtrl', ObjetivosAprendizagemCadCtrl);

	ObjetivosAprendizagemCadCtrl.$inject = ['bfc.Notification', 'educacao.matriz.ObjetivoAprendizagemService', '$modalInstance',
		'promiseTracker'];

	function ObjetivosAprendizagemCadCtrl(Notification, ObjetivoAprendizagemService, $modalInstance, promiseTracker) {
		var vm = this;

		vm.remove = remove;
		vm.save = save;
		vm.cancel = cancel;
		vm.copy = copy;
		vm.onSelectIdentificacaoRecente = onSelectIdentificacaoRecente;

		init();

		function init() {
			vm.objetivoAprendizagem = $modalInstance.params.objetivoAprendizagem || {};
			vm.openByMatriz = $modalInstance.params.openByMatriz || false;
			vm.editando = _.get(vm.objetivoAprendizagem, 'id');
			vm.canSaveAdd = $modalInstance.params.canSaveAdd !== false;

			vm.tracker = {
				modal: promiseTracker(),
				botoes: promiseTracker()
			};

			getDescricoesRecentes();
		}

		function getDescricoesRecentes() {
			var promise = ObjetivoAprendizagemService.getDescricoesRecentes()
				.then(function(data) {
					vm.identificacoesRecentes = data;
				});

			vm.tracker.modal.addPromise(promise);
		}

		function onSelectIdentificacaoRecente(identificacao) {
			vm.objetivoAprendizagem.identificacao = identificacao;
		}

		function save(continuar) {
			vm.tracker.botoes.addPromise(ObjetivoAprendizagemService.save(vm.objetivoAprendizagem).then(function(data) {
				Notification.publish('Objetivo de aprendizagem e desenvolvimento salvo com sucesso', 'success');
				if (continuar) {
					vm.objetivoAprendizagem = {};
					vm.form.$setUntouched();
				} else {
					$modalInstance.close(data);
				}
			}));
		}

		function remove() {
			vm.tracker.botoes.addPromise(ObjetivoAprendizagemService.remove(vm.objetivoAprendizagem).then(function() {
				$modalInstance.close();
			}));
		}

		function cancel() {
			$modalInstance.close();
		}

		function copy() {
			vm.objetivoAprendizagem.id = null;
			
			save(false);
		}
	}
})();

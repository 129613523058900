(function() {
	'use strict';


angular.module('educacao.common')
	.directive('edValidateEmail', ['$parse', '$timeout', 'bfc.MessagesField',
		function ($parse, $timeout, MessagesField) {
			return {
	            require: '?ngModel',
				link: postLink
	   		};

	   		function postLink (scope, element, attrs, ngModel) {
				if (!ngModel) {
					throw ('O elemento necessida da utilização do ngModel.');
				}

				MessagesField.checkOnBlur(element, ngModel.$error);
			}


	}]);
})();

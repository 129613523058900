(function() {
    'use strict';

    var SHOW_YEAR_VIEW = 'visualizacao-calendario.show-year-view';

    angular.module('educacao.calendario')
        .constant('VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW', SHOW_YEAR_VIEW)
        .directive('visualizacaoCalendario', visualizacaoCalendario);

    function visualizacaoCalendario() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/visualizacao/visualizacao-calendario.directive.html',
            transclude: true,
            scope: {
                calendarioDisplay: '=',
                tracker: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'EVENT_VIEW_EDIT_EVENT',
        'EVENT_VIEW_DELETE_EVENT',
        'EVENT_LIST_POPOVER_EDIT_EVENT',
        'EVENT_LIST_POPOVER_REMOVE_EVENT',
        'AGENDA_VIEW_UPDATE_EVENT',
        'AGENDA_VIEW_CREATE_EVENT',
        'AGENDA_TITLE_CHANGED_EVENT',
        'AGENDA_PREVIOUS_PAGE_EVENT',
        'AGENDA_NEXT_PAGE_EVENT',
        '$scope',
        '$q',
        '$state',
        'promiseTracker',
        'bfc.$$PermissionsService',
        'bfc.Notification',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'IdGenerator',
        'ViewController',
        'educacao.calendario.CalendarioEventoService',
        'educacao.calendario.CalendarioImpressaoService',
        'ui.components.Modal'
    ];

    function Controller(EVENT_VIEW_EDIT_EVENT,
        EVENT_VIEW_DELETE_EVENT,
        EVENT_LIST_POPOVER_EDIT_EVENT,
        EVENT_LIST_POPOVER_REMOVE_EVENT,
        AGENDA_VIEW_UPDATE_EVENT,
        AGENDA_VIEW_CREATE_EVENT,
        AGENDA_TITLE_CHANGED_EVENT,
        AGENDA_PREVIOUS_PAGE_EVENT,
        AGENDA_NEXT_PAGE_EVENT,
        $scope,
        $q,
        $state,
        promiseTracker,
        PermissionsService,
        Notification,
        ContextoEstabelecimento,
        ContextoAnoLetivo,
        IdGenerator,
        ViewController,
        calendarioEventoService,
        CalendarioImpressaoService,
        uiModal
    ) {

        var vm = this;

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;

        vm.calendarView = new ViewController();

        vm.renderConfig = {
            showHolydaysControls: false
        };

        vm.renderConfigPersonalizado = {
            showHolydaysControls: false,
            restrictInitialDate: null,
            restrictFinalDate: null,
            hideRestrictedDate: true,
        };

        vm.dataConfig = {
            minDate: new Date(moment().year(vm.anoLetivo).startOf('year').format('YYYY-MM-DDTHH:mm:ss')),
            maxDate: new Date(moment().year(vm.anoLetivo).endOf('year').format('YYYY-MM-DDTHH:mm:ss')),
            yearRange: vm.anoLetivo + ':' + vm.anoLetivo
        };

        vm.eventosAceite = [];
        vm.eventosAceiteTracker = promiseTracker();

        vm.isAgendaView = isAgendaView;
        vm.openEventModal = openEventModal;
        vm.openEventosAceiteModal = openEventosAceiteModal;

        vm.previousPage = previousPage;
        vm.nextPage = nextPage;

        vm.aceitarEvento = aceitarEvento;
        vm.aceitarEditarEvento = aceitarEditarEvento;
        vm.recusarEvento = recusarEvento;
        vm.imprimir = CalendarioImpressaoService.imprimir.bind(null, '.content-impressao');

        extendPromiseTracker();
        init();

        function init() {
            $scope.$on(EVENT_VIEW_EDIT_EVENT, onEditEvent);
            $scope.$on(EVENT_LIST_POPOVER_EDIT_EVENT, onEditEvent);

            $scope.$on(EVENT_VIEW_DELETE_EVENT, onRemoveEvent);
            $scope.$on(EVENT_LIST_POPOVER_REMOVE_EVENT, onRemoveEvent);

            $scope.$on(AGENDA_TITLE_CHANGED_EVENT, onAgendaTitleChanged);
            $scope.$on(AGENDA_VIEW_CREATE_EVENT, onCreateScaffoldEvent);
            $scope.$on(AGENDA_VIEW_UPDATE_EVENT, function(event, evento, rollbackFunction) {
                onEventoSaved(evento).catch(rollbackFunction);
            });

            $scope.$on(SHOW_YEAR_VIEW, vm.calendarView.goToYearView);

            loadEventosAceite();
        }

        function extendPromiseTracker() {
            var addPromise = $scope.tracker.addPromise;
            $scope.tracker.addPromise = function() {
                bfc.Popover.closeAll();
                addPromise.apply($scope.tracker, arguments);
            };
        }

        function onAgendaTitleChanged(event, title) {
            vm.agendaTitle = title;
        }

        function previousPage() {
            $scope.$broadcast(AGENDA_PREVIOUS_PAGE_EVENT);
        }

        function nextPage() {
            $scope.$broadcast(AGENDA_NEXT_PAGE_EVENT);
        }

        function onEditEvent(event, evento) {
            bfc.Popover.closeAll();
            openEventModal(evento);
        }

        function onRemoveEvent(event, evento) {
            bfc.Popover.closeAll();
            onEventoDeleted(evento);
        }

        function onCreateScaffoldEvent(event, evento) {
            calendarioEventoService.getDefaultRepresentation().then(function(data) {
                openEventModal(angular.extend(data, evento));
            });
        }

        function loadEventosAceite(data) {
            if (ContextoEstabelecimento.isSecretaria()) {
                return data;
            }

            vm.eventosAceiteTracker.addPromise(
                calendarioEventoService
                .listEventosAceite($scope.calendarioDisplay.baseCalendar)
                .then(orderEventosAceite));

            function orderEventosAceite(aceites) {
                vm.eventosAceite = _.sortBy(aceites, 'dataInicial');
            }

            return data;
        }

        function loadEventosAceiteModal(data) {
            if (ContextoEstabelecimento.isSecretaria()) {
                return data;
            }
            return calendarioEventoService
            .listEventosAceite($scope.calendarioDisplay.baseCalendar);
        }

        function isAgendaView() {
            return vm.calendarView.isWeekView() || vm.calendarView.isMonthView();
        }

        function verifyPermission() {
            if (PermissionsService.isRevokedOperation(PermissionsService.$$readPermissionRequiredForState($state.current), 'editar')) {
                Notification.publish('Você não tem permissão para esta ação', 'warn');
                return $q.reject();
            }
            return $q.when();
        }

        function CadEventoModal(editedEvento, saved) {
            return (saved ? onEventoSaved : onEventoDeleted)(editedEvento);
        }

        function openEventModal(evento) {
            bfc.Popover.closeAll();
            var promise = verifyPermission();
            var promiseModal = promise.then(function() {
                return uiModal.open({
                    templateUrl: 'calendario/calendario-evento/calendario-evento-modal.html',
                    controller: 'educacao.calendario.CalendarioEventoModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    params: {
                        fn: {
                            CadEventoModal: CadEventoModal
                        },
                        resolve: {
                            evento: evento || null
                        }
                    },
                }).result;
            });

            $scope.tracker.addPromise(promise);

            return promiseModal;
        }

        function openEventosAceiteModal() {
            bfc.Popover.closeAll();

            var promise = verifyPermission().then(function() {
                uiModal.open({
                    templateUrl: 'calendario/visualizacao/evento-aceite-modal.html',
                    controller: 'educacao.calendario.CalendarioEventoAceiteModalController',
                    controllerAs: 'vm',
                    size: 'md',
                    params: {
                        eventosAceite: vm.eventosAceite,
                        fn: {
                                aceitarEvento: aceitarEvento,
                                aceitarEditarEvento: aceitarEditarEvento,
                                recusarEvento: vm.recusarEvento,
                                loadEventosAceite: loadEventosAceiteModal,
                                saveLoteEventos: saveLoteEventos
                            }
                    },
                });
            });
            
            $scope.tracker.addPromise(promise);

            return promise;
        }

        function saveEvento(evento) {
            var calendario = $scope.calendarioDisplay.baseCalendar;

            var promise = verifyPermission()
                .then(function() {
                    return calendarioEventoService.save(calendario, evento);
                })
                .then(notifyEventSaved)
                .then(function(saved) {
                    var original = IdGenerator.get(evento, calendario.eventos);
                    if (original) {
                        angular.copy(saved, original);
                    } else {
                        IdGenerator.addOrUpdate(saved, calendario.eventos);
                    }
                })
                .then($scope.calendarioDisplay.process)
                .then(loadEventosAceite);

            $scope.tracker.addPromise(promise);

            return promise;
        }

        function saveLoteEventos(eventos) {
            var calendario = $scope.calendarioDisplay.baseCalendar;

            function mapEventos(evento) {
                return getEventoAceite(evento, 'ACEITO');
            }

            var eventosAceite = _.map(eventos, mapEventos);

            var promise = verifyPermission()
                .then(function() {
                    return calendarioEventoService.saveList(calendario, eventosAceite);
                })
                .then(function(eventosComErros) {
                    notifyEventLoteError(eventosComErros, eventosAceite.length);
                })
                .then(loadEventosAceite);

            $scope.tracker.addPromise(promise);

            return promise;
        }

        function onEventoSaved(evento) {
            return saveEvento(evento);
        }

        function notifyEventSaved(evento) {
            Notification.publish('Evento ' + evento.evento.descricao + ' de ' + moment(evento.dataInicial).format('DD [de] MMMM') + ' salvo com sucesso.', 'success');
            return evento;
        }

        function notifyEventLoteError(eventos, eventosAceiteLength) {
            if (eventos.calendarioEventos.length) {
                _.forEach(eventos.calendarioEventos, function(evento){
                    Notification.publish(evento.msgError, 'error');
                });
                if (eventosAceiteLength > eventos.calendarioEventos.length) {
                    Notification.publish('Eventos obrigatórios salvos com sucesso.', 'success');
                }
            }
            else {
                Notification.publish('Eventos obrigatórios salvos com sucesso.', 'success');
            }
            return eventos;
        }

        function onEventoDeleted(evento) {
            var calendario = $scope.calendarioDisplay.baseCalendar;
            var promise = calendarioEventoService.remove(calendario, evento)
                .then(function() {
                    IdGenerator.remove(evento, calendario.eventos);
                    $scope.calendarioDisplay.process();
                })
                .then(notifyEventRemoved)
                .then(loadEventosAceite);
            $scope.tracker.addPromise(promise);
            return promise;
        }

        function notifyEventRemoved(evento) {
            Notification.publish('Evento removido com sucesso.', 'success');
            return evento;
        }

        function getEventoAceite(evento, tipo) {
            var aceito = angular.copy(evento);
            IdGenerator.removeId(aceito);
            aceito.estabelecimentos = [];
            aceito.aceite = {
                tipo: tipo,
                aceito: {
                    id: evento.id
                }
            };
            return aceito;
        }

        function aceitarEvento(evento) {
            var promise = saveEvento(getEventoAceite(evento, 'ACEITO'));

            vm.eventosAceiteTracker.addPromise(promise);
            $scope.tracker.addPromise(promise);

            return promise;
        }

        function aceitarEditarEvento(evento) {
           return openEventModal(getEventoAceite(evento, 'ACEITO_EDITADO'));
        }

        function recusarEvento(evento) {
            var calendario = $scope.calendarioDisplay.baseCalendar;
            var promise = calendarioEventoService.recusar(calendario, evento)
                            .then(notifyEventRecused)
                            .then(loadEventosAceite);

            $scope.tracker.addPromise(promise);

            return promise;
        }

        function notifyEventRecused(data) {
            Notification.publish('Evento recusado com sucesso.', 'success');
            return data;
        }
    }
})();

// (function () {
//     'use strict';

//     angular.module('educacao.diarioclasse')
//         .config(DiarioClasseStates);

//     DiarioClasseStates.$inject = ['$stateProvider'];

//     function DiarioClasseStates($stateProvider) {
//         $stateProvider
//             .state('app.diarioclasse', {
//                 url: 'diario-classe',
//                 data: {
//                     id: 'FrequenciaPageMapping'
//                 },
//                 views: {
//                     '@': {
//                         templateUrl: 'diario-classe/diario-classe.html',
//                         controller: 'educacao.diarioClasse.DiarioController',
//                         controllerAs: 'vm'
//                     }
//                 }
//             });
//     }
// })();
(function() {
    'use strict';

    angular.module('educacao.matricula')

    /**
     * Constantes para mensagens de erro do ng-file-update.
     * Ver https://github.com/danialfarid/ng-file-upload para quais chaves são preenchidas em $error.
     */
    .constant('appAnexoErrorMessages', {
        maxSize: 'Excedeu tamanho',
        pattern: 'Formato inválido'
    })

    /**
     * Constantes para identificar o icone do tipo.
     * Usa startWith da key para determinar qual usar.
     */
    .constant('appAnexoFileTypeIcons', {
        'general': {
            icon: 'fa-file-o',
            color: 'text-gray5'
        },
        'application/pdf': {
            icon: 'fa-file-pdf-o',
            color: 'text-red9'
        },
        'text/plain': {
            icon: 'fa-file-text-o',
            color: 'text-gray10'
        },
        'text/html': {
            icon: 'fa-file-code-o',
            color: 'text-orange7'
        },
        'image/': {
            icon: 'fa-file-image-o',
            color: 'text-purpleA8'
        }
    });
})();

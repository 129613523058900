(function () {
    'use strict';

    angular
        .module('educacao.quadro-informativo-utils')
        .directive('appSelectEstabelecimentoGestaoPessoas', appSelectEstabelecimentoGestaoPessoas);

    function appSelectEstabelecimentoGestaoPessoas() {

        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" placeholder="placeholder" ngclass="ngclass" ng-change="ngChange()" ng-disabled="ngDisabled" ng-required="ngRequired"' +
                ' format-result="vm.formatResult(data)" search="vm.search"/>',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                ngclass: '=',
                placeholder: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '='
            },
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService'];

    function Controller($scope, Service) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = ['(descricaoEstabelecimento like "' + params.term + '")'];

            if ($scope.multiple) {
                var estebelecimentoIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function (model) {
                        estebelecimentoIds.push(model.id);
                    });
                }

                if (estebelecimentoIds.length) {
                    filter.push('idEstabelecimento not in(' + estebelecimentoIds.join() + ')');
                }
            }

            var reqParams = {
                filter: filter.join(' and '),
                offset: params.offset,
                limit: params.limit,
                estabelecimentoId: _.get($scope.estabelecimentoSelected, 'id'),
                anoLetivoId: _.get($scope.anoLetivoSelected,'id')
            };

            return Service
                .listEstabelecimentos(reqParams);

        }


        function formatResult(data) {
            return data.descricao;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.abonojustificativa')
        .config(AbonoJustificativaStates);

    AbonoJustificativaStates.$inject = ['$stateProvider'];

    function AbonoJustificativaStates($stateProvider) {
        $stateProvider.state('app.abonojustificativa', {
            abstract: true,
            data: {
                id: 'AbonoFaltaPageMapping',
                mirrorOf: 'app.frequencia'
            }
        });
    }
})();
(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .directive('appFiltrosMatrizes', appFiltrosMatrizes);


    function appFiltrosMatrizes() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-filtros-matrizes/app-filtros-matrizes.directive.html',
            scope: {},
            bindToController: {
                estabelecimentoId: '=',
                anoLetivoId: '=',
                turmaFilter: '=',
                turmaFilters: '=',
                matrizes: '=',
                componenteCurricularFilter: '=',
                loadingTracker: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoSaldosParciaisUtils',
        'educacao.ocupacao-aulas-quadro-informativo.QuadroFuncionariosVisaoEstabelecimentoService'
    ];

    function Controller($scope, SaldosParciaisUtils, VisaoEstabelecimentoService) {
        var vm = this;

        vm.saldosParciaisTypeFilter = '';
        vm.saldosParciaisEtapaFilter = '';
        vm.saldosParciaisValueFilter = '';
        vm.saldosParciaisNivelEscolarFilter = '';

        vm.ENUMS_FILTER = {
            'Turno': 'Turno',
            'Modalidade': 'Modalidade'
        };

        vm.MODALIDADE_COMPLEMENTAR = 'COMPLEMENTAR';
        vm.MATRIZ_CURRICULAR_SELECT = 'Matriz curricular';
        vm.COMPONENTE_CURRICULAR_SELECT = 'Componente curricular';

        vm.saldosParciais = [
            {titulo: 'Saldo inicial', saldo: 0},
            {titulo: 'Saldo ocupado', saldo: 0},
            {titulo: 'Saldo atual', saldo: 0}
        ];

        vm.saldosParciaisFilterOptions = {
            'Modalidade': 'Modalidade',
            'Matriz curricular': 'Matriz curricular',
            'Turno': 'Turno',
            'Componente curricular': 'Componente curricular',
        };

        vm.searchFiltros = searchFiltros;
        vm.searchMatrizes = searchMatrizes;

        vm.params = null;

        $scope.$watch('vm.estabelecimentoId', function () {
            clearAllFilters();
            getSaldosParciais();
        });
        
        $scope.$watch('vm.anoLetivoId', function () {
            clearAllFilters();
            getSaldosParciais();
        });

        $scope.$watch('vm.saldosParciaisTypeFilter', function() {
            vm.saldosParciaisValueFilter = '';
            vm.saldosParciaisEtapaFilter = '';
            vm.saldosParciaisNivelEscolarFilter = '';
            vm.isEnumFilter = vm.saldosParciaisTypeFilter in vm.ENUMS_FILTER;
        });

        $scope.$watch('vm.saldosParciaisValueFilter', function() {
            getSaldosParciais();
            vm.saldosParciaisEtapaFilter = '';
            vm.saldosParciaisNivelEscolarFilter = '';
        });

        $scope.$watch('vm.saldosParciaisEtapaFilter', function() {
            getSaldosParciais();
        });

        $scope.$watch('vm.saldosParciaisNivelEscolarFilter', function() {
            getSaldosParciais();
        });

        $scope.$watch('vm.turmaFilter', function() {
            getSaldosParciais();
        });

        init();

        function init() {
            vm.matrizesIds = _.map(vm.matrizes, 'matrizCurricularId');
        }

        function getSaldosParciais() {
            var saldoParams = SaldosParciaisUtils.getSaldosParciaisParams(
                vm.saldosParciaisValueFilter,
                vm.saldosParciaisEtapaFilter,
                vm.saldosParciaisTypeFilter,
                vm.saldosParciaisNivelEscolarFilter,
                vm.isEnumFilter,
                vm.matrizesIds,
                vm.turmaFilter
            );

            vm.params = { filter: saldoParams.filter, anoLetivoId: vm.anoLetivoId };
            vm.turmaFilters = saldoParams.turmasIds;
            vm.componenteCurricularFilter = saldoParams.componenteCurricular;
        }

        function searchMatrizes() {
            var promise = VisaoEstabelecimentoService.getMatrizesSaldosParciaisByEstabelecimentoId(vm.estabelecimentoId, vm.params)
                .then(function (data) {
                    vm.matrizes = data;
                });

            vm.loadingTracker.matrizesCurriculares.addPromise(promise);
        }

        function searchFiltros(params) {
            return SaldosParciaisUtils.search(
                    params, 
                    vm.saldosParciaisTypeFilter,
                    vm.estabelecimentoId
                );
        }

        function clearAllFilters() {
            vm.turmaFilter = '';
            vm.saldosParciaisValueFilter = '';
            vm.saldosParciaisEtapaFilter = '';
            vm.saldosParciaisNivelEscolarFilter = '';
            vm.saldosParciaisTypeFilter = '';
        }
    }

})();
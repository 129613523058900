(function () {
    'use strict';

    var EDIT_EVENT = 'matriz-list.edit';
    var REMOVE_EVENT = 'matriz-list.remove';
    var SELECTED_EVENT = 'matriz-list.selected';

    var colors = [
        'tx__red--l20',
        'tx__orange--l10',
        'tx__yellow',
        'tx__green--l10',
        'tx__aqua--l10',
        'tx__blue--l10'
    ];

    angular.module('educacao.calendario')
        .constant('MATRIZ_LIST_EDIT_EVENT', EDIT_EVENT)
        .constant('MATRIZ_LIST_REMOVE_EVENT', REMOVE_EVENT)
        .constant('MATRIZ_LIST_SELECTED_EVENT', SELECTED_EVENT)
        .directive('matrizList', matrizList);

    function matrizList() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/calendario-matriz/matriz-list.directive.html',

            scope: {
                list: '=',
                blocked: '=?',
                hideDropdownOptions: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['ESTABELECIMENTO_LIST_SELECT', '$scope', 'educacao.context.ContextoEstabelecimento'];

    function Controller(ESTABELECIMENTO_LIST_SELECT, $scope, ContextoEstabelecimento) {
        var vm = this;

        vm.itemEscola = {
            selected: true,
            hover: false
        };

        vm.isEscola = !ContextoEstabelecimento.isSecretaria();
        if (vm.isEscola) {
            vm.itemEscola.label = ContextoEstabelecimento.getEstabelecimento().pessoa.nome;
        }

        vm.items = [];

        vm.onItemClicked = onItemClicked;
        vm.onItemOver = onItemOver;

        vm.edit = edit;
        vm.remove = remove;

        $scope.$watch('list', onListChanged, true);

        $scope.$on(ESTABELECIMENTO_LIST_SELECT, unselectAll);

        function onListChanged(matrizes) {
            vm.items = matrizes ? matrizes.map(createItem) : [];
            vm.itemEscola.selected = !_.some(vm.items, 'selected', true);
        }

        function createItem(matriz, index) {
            var old = _.find(vm.items, {
                id: matriz.id
            });

            var selected = Boolean(old) && old.selected;

            return {
                id: matriz.id,
                label: matriz.descricao,
                idEstabelecimento: matriz.idEstabelecimento,
                colorClass: colors[index % colors.length],
                selected: selected,
                hover: false
            };
        }

        function onItemClicked($event, item) {
            $event.stopPropagation();

            vm.items.forEach(function (i) {
                i.selected = item === i;
            });
            vm.itemEscola.selected = vm.itemEscola === item;

            $scope.$emit(SELECTED_EVENT, item.id, item.idEstabelecimento);
        }

        function onItemOver(item) {
            vm.items.forEach(function (i) {
                i.hover = item === i;
            });

            vm.itemEscola.hover = vm.itemEscola === item;
        }

        function edit(item) {
            if (!$scope.blocked) {
                $scope.$emit(EDIT_EVENT, item.id);
            }
        }

        function remove(item) {
            if (!$scope.blocked) {
                $scope.$emit(REMOVE_EVENT, item.id);
            }
        }

        function unselectAll() {
            vm.items.forEach(function (i) {
                i.selected = false;
            });
        }
    }
})();

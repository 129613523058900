(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matricula');

    moduloCommon.controller('educacao.matricula.ListaTurmaController', Controller);

    Controller.$inject = [
        '$q',
        '$injector',
        '$scope',
        '$timeout',
        '$searchExpressionBuilder',
        'promiseTracker',
        '$edGoogleOauthService',
        'educacao.context.ContextoEntidade',
        'bfc.$$PermissionsService'
    ];

    function Controller($q, $injector, $scope, $timeout, $searchExpressionBuilder, promiseTracker, $edGoogleOauthService, ContextoEntidade, PermissionsService) {

        var $commons = $injector.get('educacao.common.$commons');
        var uiModal = $injector.get('ui.components.Modal');
        var TurmaService = $injector.get('educacao.matricula.TurmaService');
        var MatrizService = $injector.get('educacao.matriz.MatrizService');
        var HorasAulaService = $injector.get('educacao.matricula.HorasAulaService');
        var $modalStates = $injector.get('$modalStates');
        var ConfiguracaoAvaliacaoTurmaModalService = $injector.get('ConfiguracaoAvaliacaoTurmaModalService');
        var ConfiguracaoNotaMaximaModalService = $injector.get('configuracao-nota-maxima.ConfiguracaoNotaMaximaModalService');


        var templateBuscaMatrizCurricular = _.template('descricao like "<%= term %>"');
        var templateBuscaFiltroTurma = _.template('descricao like "<%= term %>"');
        var templateBuscaConfiguracao = _.template('descricao like "<%= term %>"');

        var REGISTRO_FREQUENCIA_POR_AULAS = 'REGISTRO_FREQUENCIA_POR_AULAS';
        var REGISTRO_TOTAL_FALTAS_EM_DIAS = 'REGISTRO_TOTAL_FALTAS_EM_DIAS';

        var ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO = 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO';
        var ATIVIDADE_COMPLEMENTAR = 'ATIVIDADE_COMPLEMENTAR';

        var vm = this;

        vm.loadingTracker = promiseTracker();

        vm.turnoSelecionado = 'MATUTINO'; //

        vm.selecionarTodas = false;

        vm.searchColumns = [{
            id: 'nomeEstabelecimento',
            label: 'Estabelecimento de ensino',
            model: 'nomeEstabelecimento',
            filterable: true
        }, {
            id: 'descricaoMatriz',
            label: 'Matriz curricular',
            model: 'descricaoMatriz',
            filterable: false
        }, {
            id: 'descricao',
            label: 'Turma',
            model: 'descricao',
            filterable: true
        }];

        vm.turmas = [];
        vm.turmasSelecionadas = [];
        vm.turmasLinha = [];

        vm.currentPage = 1;
        vm.itemsPerPage = 18;
        vm.optionsItemsPerPage = [18, 48, 99];

        vm.openModalTurma = openModalTurma;
        vm.getIdsTurmasSelecionadas = getIdsTurmasSelecionadas;
        vm.exclui = exclui;
        vm.mudaTurnoSelecionado = mudaTurnoSelecionado;

        vm.onChangeSelecionarTodas = onChangeSelecionarTodas;
        vm.onSelectTurma = onSelectTurma;
        vm.openEnturmacao = openEnturmacao;
        vm.openEnturmacaoProfessores = openEnturmacaoProfessores;
        vm.openModalConfiguracaoAvaliacao = openModalConfiguracaoAvaliacao;
        vm.openModalConfiguracaoNotaMaxima = openModalConfiguracaoNotaMaxima;
        vm.podeEnturmar = podeEnturmar;
        vm.openMultisseriada = openMultisseriada;
        vm.isAtividade = isAtividade;

        vm.listaTurmas = listaTurmas;
        vm.hasFiltered = hasFiltered;
        vm.onFocusSearch = onFocusSearch;
        vm.onFocusFilter = onFocusFilter;

        vm.listMatriz = listMatrizCurricular;
        vm.listTurno = TurmaService.getTurno();
        vm.equivalenciaPromise = TurmaService.getEquivalenciaEtapa();
        vm.listNivelEscolar = listNivelEscolar();
        vm.listTurma = listTurma;
        vm.listTipoAtendimento = TurmaService.getTipoAtendimento();
        vm.listConfiguracaoHorasAulas = listConfiguracaoHorasAulas;

        vm.openCadConfigFrequencia = openCadConfigFrequencia;

        vm.filterResultTipoRegistoFrequencia = filterResultTipoRegistoFrequencia;

        vm.incompativeisParaTipoAvaliacao = incompativeisParaTipoAvaliacao;
        vm.incompativeisParaConfiguracaoNota = incompativeisParaConfiguracaoNota;

        vm.entidade = ContextoEntidade.getEntidade();
        vm.googleOauth = googleOauth;

        vm.openModalReordenarNumChamada = openModalReordenarNumChamada;

        $scope.$watch('vm.turmas', agrupaTurmas);
        $scope.$watch('vm.turmasSelecionadas', habilitaBotoes, true);

        TurmaService.on('dirty', listaTurmas);

        $scope.$on('$destroy', function () {
            TurmaService.off(listaTurmas);
        });

        function hasFiltered() {
            return Boolean(vm.searchFilter && vm.searchFilter.keywords && vm.searchFilter.keywords.length);
        }

        function onFocusSearch() {
            $timeout(function () {
                $('.search-field').focus();
            });
        }

        function onFocusFilter() {
            $timeout(function () {
                $('.ui-search-advanced-dropdown').click();
            });
        }

        function habilitaBotoes(newValue, oldValue) {
            vm.isRegistroDeFrequenciaIguais = false;
            vm.isEstabelecimentosDiferentes = false;
            vm.isNivelModalidadeDiferentes = false;
            vm.isTurmaIncompativel = false;
            var idEstabelecimento;
            var nivelModalidade;
            if (_.isEqual(oldValue, newValue)) {
                return;
            }
            if (_.isEmpty(newValue)) {
                return;
            }
            newValue.forEach(function (data) {
                if (idEstabelecimento && idEstabelecimento !== data.estabelecimento.id) {
                    vm.isEstabelecimentosDiferentes = true;
                    return;
                }
                idEstabelecimento = data.estabelecimento.id;
            });
            newValue.forEach(function (data) {
                if (nivelModalidade && nivelModalidade !== data.matriz.curso.nivelModalidade) {
                    vm.isNivelModalidadeDiferentes = true;
                    return;
                }
                if (data.matriz.id === null) {
                    vm.isTurmaIncompativel = true;
                    return;
                }
                nivelModalidade = data.matriz.curso.nivelModalidade;
            });

            getSituacaoTipoRegistroFrequencia(newValue);
        }

        function getSituacaoTipoRegistroFrequencia(newValue) {
            var modularOuAtividade = _.filter(newValue, function (dataTurma) {
                return _.get(dataTurma, 'matriz.modular') ||
                    _.get(dataTurma, 'tipoAtendimento') === ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO ||
                    _.get(dataTurma, 'tipoAtendimento') === ATIVIDADE_COMPLEMENTAR;
            });

            if (_.get(modularOuAtividade, 'length')) {
                vm.tituloAlteracaoRegistroFrequencia =
                    'Não é permitido alterar o tipo do registro de frequência, para uma turma sem matriz ou com matriz modular.';
                return;
            }

            vm.tituloAlteracaoRegistroFrequencia =
                'Não é possível alterar as configurações de frequências das turmas selecionadas, pois as frequências precisam ser iguais.';

            var qntTiposRegistroFrequencia = [];
            _.forEach(_.groupBy(newValue, 'tipoRegistroFrequencia'), function (data) {
                qntTiposRegistroFrequencia.push(data);
            });

            if (_.get(qntTiposRegistroFrequencia, 'length') === 1) {
                vm.isRegistroDeFrequenciaIguais = true;
                vm.tituloAlteracaoRegistroFrequencia = 'Alterar configuração de frequência da turma';
            }
        }

        function listaTurmas() {
            var filter = 'turno="' + vm.turnoSelecionado + '"';
            if(!vm.searchFilter.others.separacaoAlunosEmGrupos){
                delete vm.searchFilter.others.separacaoAlunosEmGrupos;
            }
            var expression = $searchExpressionBuilder.build(vm.searchFilter) || '';
            if (!_.isEmpty(expression)) {
                filter += ' and (' + expression + ')';
            }

            var params = {
                filter: filter,
                offset: vm.currentPage - 1,
                limit: vm.itemsPerPage
            };

            params.offset = params.offset > 0 ? params.offset * params.limit : 0;

            vm.loadingTracker.addPromise(
                TurmaService.getList(params).then(listPaginado)
            );
        }

        function listPaginado(itens) {
            vm.firstSearchExecuted = true;
            vm.turmasSelecionadas = [];
            vm.selecionarTodas = false;
            vm.turmas = itens.content;
            vm.page = itens;
            vm.currentPage = (itens.offset / itens.limit) + 1;
            vm.totalItems = itens.total;
            vm.hasNext = itens.hasNext;
            vm.totalContent = itens.content.length;
        }

        function agrupaTurmas(list) {
            vm.turmasLinha = _.chunk(list, 3);
        }

        function openEnturmacao() {
            var template = 'matricula/enturmacao/enturmacao.html';
            var controller = 'educacao.matricula.EnturmacaoCtrl as vm';
            var filter = 'id in(' + _.map(vm.turmasSelecionadas, 'id').join(',') + ')';

            if (isEjaModular(vm.turmasSelecionadas)) {
                template = 'matricula/enturmacao/alunos-eja-modular/enturmacao.alunos-eja-modular.html';
                controller = 'educacao.matricula.EnturmacaoAlunosEjaModularCtrl as vm';
            }

            $commons.$modal.open({
                templateUrl: template,
                controller: controller,
                size: 'xxl',
                params: {
                    turmas: TurmaService.getList({
                        filter: filter
                    })
                        .then(function (data) {
                            return data.content;
                        }),
                    Turno: TurmaService.getTurno()
                }
            }).result.then(listaTurmas);

            function isEjaModular(turmas) {
                var turma = turmas[0];
                return Boolean(turma && turma.matriz && turma.matriz.modular);
            }
        }

        function openEnturmacaoProfessores() {
            var template = 'matricula/enturmacao-professores/enturmacao-professores.html';
            var controller = 'educacao.matricula.EnturmacaoProfessoresCtrl as vm';
            var filter = 'id in(' + _.map(vm.turmasSelecionadas, 'id').join(',') + ')';

            $commons.$modal.open({
                templateUrl: template,
                controller: controller,
                size: 'xxl',
                params: {
                    turmas: TurmaService.getList({
                        filter: filter
                    })
                        .then(function (data) {
                            return data.content;
                        }),
                    Turno: TurmaService.getTurno()
                }
            }).result.then(listaTurmas);
        }

        function openModalConfiguracaoAvaliacao() {
            ConfiguracaoAvaliacaoTurmaModalService.open(vm.turmasSelecionadas)
                .result.then(listaTurmas);
        }

        function openModalConfiguracaoNotaMaxima() {
            ConfiguracaoNotaMaximaModalService.open(vm.turmasSelecionadas);
        }

        function openModalTurma(turma) {
            $modalStates.goModalState(turma);
        }

        function openMultisseriada() {
            uiModal.open({
                templateUrl: 'matricula/turma/cadastro-turma-multiseriada/turma.multisseriada.cad.html',
                size: 'lg',
                controller: 'educacao.matricula.CadastroTurmaMultiSeriada',
                controllerAs: 'vm',
                params: {
                    turmasSelecionadas: angular.copy(vm.turmasSelecionadas)
                }
            });
        }

        function exclui(turma) {
            var isRevokedExcluirTurma = PermissionsService.isRevokedOperation('TurmaPageMapping', 'excluir');
            if (isRevokedExcluirTurma) {
                return;
            }

            var hasPermissionToRemoveCascade = !PermissionsService.isRevokedOperation('TurmaPageMapping', 'excluirVinculos');
            if (hasPermissionToRemoveCascade) {
                openForceRemoveTurma(turma);
                return $q.reject();
            }
            return TurmaService.remove(turma)
                .then(listaTurmas);
        }

        function openForceRemoveTurma(turma) {
            $commons.$modal.open({
                templateUrl: 'matricula/turma/remove-turma-cascade/remove-turma-cascade.html',
                controller: 'educacao.matricula.RemoveTurmaCascadeController as vm',
                size: 'sm',
                params: {
                    turma: turma
                }
            });
        }

        function mudaTurnoSelecionado($event) {
            if ($event.target.nodeName !== 'A') {
                return;
            }

            var $ul = $($event.delegateTarget);
            var $underline = $('.linha-roll');

            var $clicked = $($event.target);

            vm.turnoSelecionado = $clicked.attr('data-value');

            $ul.find('a').removeClass('active');
            $clicked.addClass('active');

            var left = parseInt($ul.parent().css('padding-left'));
            $clicked.parent().prevAll().each(function (index, el) {
                left += $(el).outerWidth() + 2;
            });

            $underline.css({
                width: $clicked.outerWidth(),
                left: left + 'px'
            });

            listaTurmas();
        }

        function onSelectTurma(turma) {
            addTurmaSelecionada(turma);
            verificarTodasSelecionadas();
        }

        function addTurmaSelecionada(turma) {
            var index = vm.turmasSelecionadas.indexOf(turma);
            if (turma.selecionada) {
                if (index === -1) {
                    vm.turmasSelecionadas.push(turma);
                }
            } else {
                if (index !== -1) {
                    vm.turmasSelecionadas.splice(index, 1);
                }
            }
        }

        function verificarTodasSelecionadas() {
            if (vm.turmasSelecionadas.length === vm.turmas.length) {
                vm.selecionarTodas = true;
                return;
            }
            vm.selecionarTodas = false;
        }

        function onChangeSelecionarTodas() {
            vm.turmas.forEach(function (turma) {
                selectTurma(turma, vm.selecionarTodas);
            });
        }

        function selectTurma(turma, value) {
            turma.selecionada = value;
            addTurmaSelecionada(turma);
        }

        function isAtividade() {
            return vm.turmasSelecionadas.length && (vm.turmasSelecionadas[0].tipoAtendimento === 'ATIVIDADE_COMPLEMENTAR' || vm.turmasSelecionadas[
                0].tipoAtendimento === 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO');
        }

        function incompativeisParaTipoAvaliacao() {

            vm.titleTipoAvaliacao = 'Tipos de avaliação';

            if (vm.turmasSelecionadas.length > 1) {

                if (isEstabelecimentoIncompativel() || isTipoAtividade() || isEjaModular() || isEtapaIncompativel()) {

                    vm.titleTipoAvaliacao = 'Turmas incompatíveis';

                    return true;

                }

            }

            function isTipoAtividade() {

                return _.chain(vm.turmasSelecionadas)
                    .map('tipoAtendimento')
                    .difference(['NAO_SE_APLICA', 'CLASSE_HOSPITALAR', 'ESCOLARIZACAO'])
                    .value()
                    .length > 0;

            }

            function isEjaModular() {

                return _.chain(vm.turmasSelecionadas)
                    .map('matriz.modular')
                    .filter(function (i) {
                        return Boolean(i);
                    }).value()
                    .length > 0;

            }

            function isEtapaIncompativel() {
                return _.chain(vm.turmasSelecionadas)
                    .map('etapaMatriz.id')
                    .unique()
                    .value()
                    .length > 1;
            }

            function isEstabelecimentoIncompativel() {
                return _.chain(vm.turmasSelecionadas)
                    .map('estabelecimento.id')
                    .unique()
                    .value()
                    .length > 1;
            }

        }

        function incompativeisParaConfiguracaoNota() {
            vm.titleNotaMaxima = 'Configuração de nota máxima por período';

            if (!vm.turmasSelecionadas.length) {
                return false;
            }

            var calendarios = _.pluck(vm.turmasSelecionadas, 'calendarioMatrizCurricular.id');
            var configuracoesAvaliacoes = _.pluck(vm.turmasSelecionadas, 'configuracaoAvaliacao.id');

            if (!_.every(configuracoesAvaliacoes, verifyArrayValues(configuracoesAvaliacoes))) {
                vm.titleNotaMaxima = 'As configurações de avaliação das turmas selecionadas devem ser preenchidas e iguais';
                return true;
            }

            if (!_.every(calendarios, verifyArrayValues(calendarios))) {
                vm.titleNotaMaxima = 'Os calendários das turmas selecionadas devem ser preenchidos e iguais';
                return true;
            }

            return false;
        }

        function verifyArrayValues(arr) {
            return function (data) {
                return data && data === _.first(arr);
            };
        }

        function podeEnturmar() {
            if (!vm.turmasSelecionadas.length) {
                return false;
            }

            var turmaModelo = vm.turmasSelecionadas[0];
            if (!turmaModelo.etapaMatriz) {
                return false;
            }

            var estabelecimentoId = turmaModelo.estabelecimento.id;
            var etapaId = turmaModelo.etapaMatriz.id;
            var turno = turmaModelo.turno;
            var tipoAtendimento = turmaModelo.tipoAtendimento;

            return _.filter(vm.turmasSelecionadas, function (turma) {
                var turmaEtapaId = turma.etapaMatriz ? turma.etapaMatriz.id : null;
                return turma.estabelecimento.id === estabelecimentoId &&
                    turmaEtapaId === etapaId &&
                    turma.turno === turno &&
                    turma.tipoAtendimento === tipoAtendimento;

            }).length === vm.turmasSelecionadas.length;
        }

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function listMatrizCurricular(params) {
            return MatrizService
                .getList(params.offset, params.limit, templateBuscaMatrizCurricular(params))
                .then(toPage);
        }

        function listNivelEscolar() {
            return MatrizService
                .getEnums()
                .then(_.property('NivelEscolar'))
                .then(function (values) {
                    var e = _.mapKeys(values, 'key');
                    _.forEach(e, function (value, key) {
                        value.id = key;
                    });
                    e.values = values;
                    return e;
                });
        }

        function listTurma(params) {
            return TurmaService.getListFilter(templateBuscaFiltroTurma(params));
        }

        function listConfiguracaoHorasAulas(params) {
            return HorasAulaService
                .list(templateBuscaConfiguracao(params), params.offset, params.limit);
        }

        function openCadConfigFrequencia() {
            $commons.$modal.open({
                templateUrl: 'matricula/turma/cadastro-configuracao-frequencia/cadastro-configuracao-frequencia.html',
                controller: 'educacao.matricula.CadastroTurmaConfiguracaoFrequenciaController as vm',
                size: 'sm',
                params: {
                    turmas: vm.turmasSelecionadas
                }
            });
        }

        function filterResultTipoRegistoFrequencia(term, value) {
            if (value.id === REGISTRO_FREQUENCIA_POR_AULAS ||
                value.id === REGISTRO_TOTAL_FALTAS_EM_DIAS && (_.get(vm.turma, 'tipoConfiguracaoFrequencia') === 'EJA_MODULAR_ENSINO_FUNDAMENTAL' ||
                    _.get(vm.turma, 'tipoConfiguracaoFrequencia') === 'EJA_MODULAR_ENSINO_MEDIO')) {
                return;
            }
            var valueMaiusculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (valueMaiusculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }
        }

        function googleOauth() {
            var turmas = [];
            vm.turmasSelecionadas.forEach(function (data) {
                turmas.push({id: data.id});
            });
            $edGoogleOauthService.sendData('classroom/turma', turmas);
        }

        function getIdsTurmasSelecionadas() {
            return _.map(vm.turmasSelecionadas, 'id');
        }

        function openModalReordenarNumChamada() {
            uiModal.open({
                templateUrl: 'matricula/turma/reordenacao-numeros-chamada/reordenacao-numeros-chamada.html',
                controller: 'educacao.matricula.ReordenacaoNumerosChamadaController as vm',
                size: 'sm',
                params: {
                    turmasSelecionadas: _.map(vm.turmasSelecionadas, _.partialRight(_.pick, ['id']))
                }
            });
        }

    }
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.PeriodoAvaliativoModal', Controller);

    Controller.$inject = [
        '$scope',

        'calendarioDisplay',
        'periodosAvaliativos',
        'descricaoPeriodoAvaliativo',
        'saveCallback'
    ];

    function Controller($scope,
                        calendarioDisplay,
                        periodosAvaliativos,
                        descricaoPeriodoAvaliativo,
                        saveCallback) {

        var vm = this;

        vm.calendarioDisplay = calendarioDisplay;
        vm.calendario = vm.calendarioDisplay.baseCalendar;
        vm.descricaoPeriodoAvaliativo = descricaoPeriodoAvaliativo;

        vm.periodosAvaliativos = periodosAvaliativos;

        vm.save = save;

        function save() {
            $scope.$modalInstance.close();
            saveCallback(vm.calendario);
        }
    }
})();

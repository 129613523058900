(function () {
    'use strict';

    angular.module('educacao.funcionario')
        .directive('appProfessoresAuxiliares', directive);

    function directive() {
        return {
            template: '<a href="" class="profs-auxiliares" ng-click="vm.abreModal()" ng-class="{\'link-disabled\': disabled}"><i class="fa fa-user-plus pull-left"></i><div class="bg__gray--l20 text-center pull-left">{{vm.quantidade}}</div></a>',

            scope: {
                itemEducacional: '=',
                estabelecimento: '=',
                disabled: '='
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'ui.components.Modal'];
    function Controller($scope, uiModal) {
        var vm = this;
        
        vm.abreModal = abreModal;

        $scope.$watch('itemEducacional.professoresAuxiliares', function (professoresAuxiliares) {
            vm.professoresAuxiliares = professoresAuxiliares || [];
            vm.quantidade = vm.professoresAuxiliares.length;
        });

        function abreModal() {
            uiModal.open({
                templateUrl: 'matricula/turma/cadastro/modal-professores-auxiliares.html',

                controller: 'educacao.matricula.ProfessoresAuxiliaresController',
                controllerAs: 'vm',

                params: {
                    professoresAuxiliares: _.cloneDeep(vm.professoresAuxiliares),
                    estabelecimento: $scope.estabelecimento
                }
            }).result.then(setNovosProfessores);
        }

        function setNovosProfessores(novosProfessores) {
            $scope.itemEducacional.professoresAuxiliares = novosProfessores;
        }
    }
})();
(function () {

    'use strict';

    var PREFIX_LIST_NIVEL = '$$list';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.CadastroFuncionarioMatriculaCtrl', CadastroFuncionarioMatriculaCtrl);


    CadastroFuncionarioMatriculaCtrl.$inject = [
        '$modalInstance'
    ];

    function CadastroFuncionarioMatriculaCtrl($modalInstance) {
        var defaultMatricula = {
            locaisTrabalho: [],
            funcionarioSubstituto: []
        };

        var vm = this;

        vm.remove = remove;
        vm.save = save;

        vm.matricula = angular.copy($modalInstance.params.matricula) || angular.copy(defaultMatricula);
        vm.listaDesenturmar = $modalInstance.params.listaDesenturmar;
        vm.funcionario = $modalInstance.params.funcionario;
        
        vm.matriculas = $modalInstance.params.matriculas;
        vm.indexItem = $modalInstance.params.indexItem;
        vm.canSaveSubstituicao = true;

        vm.canSaveSubstituicao = canSaveSubstituicao;
        
        vm.editing = _.get(vm.matricula, 'matricula');

        function verificaCargoLeciona() {
            var lecionaCargo = _.get(vm.matricula, 'cargo.leciona');
            var lecionaCargoComissionado = _.get(vm.matricula, 'cargoComissionado.leciona');

            if (!lecionaCargo && !lecionaCargoComissionado) {
                delete vm.matricula.funcionarioDisciplinas;
                delete vm.matricula.funcionarioEtapasNiveis;
                delete vm.matricula.atividadesAees;
                delete vm.matricula.atividadesComplementares;
                vm.nivelEscolar = [];
            }
        }

        function setEtapasMatricula() {

            vm.matricula.funcionarioEtapasNiveis = [];

            _.forEach(vm.matricula.$$niveisEscolares, function (nivelEscolar) {

                var key = PREFIX_LIST_NIVEL + nivelEscolar;

                if (_.isArray(vm.matricula[key])) {
                    _.forEach(vm.matricula[key], function (item) {
                        item.nivelEscolar = nivelEscolar;
                    });
                } else {
                    vm.matricula[key].nivelEscolar = nivelEscolar;
                }

                vm.matricula.funcionarioEtapasNiveis = vm.matricula.funcionarioEtapasNiveis.concat(vm.matricula[key]);
            });
        }

        function save(saveAndAdd) {
            setEtapasMatricula();
            verificaCargoLeciona();
            ajusteSubstituicoes();

            if (saveAndAdd) {
                vm.matriculas.push(vm.matricula);
                vm.matricula = angular.copy(defaultMatricula);
                vm.formCadastroMatricula.$setUntouched();
            } else {
                $modalInstance.close(vm.matricula);
            }
        }

        function remove() {
            if (vm.indexItem >= 0) {
                vm.matriculas.splice(vm.indexItem, 1);
                $modalInstance.close();
            }
        }

        function ajusteSubstituicoes() {
            verificaEstabelecimentoSecretaria();
            verificaQuantidadeAulas();
            verificarHorasPersonalizadas();
        }

        function verificaEstabelecimentoSecretaria() {
            vm.matricula.funcionarioSubstituto.forEach(function (funcionario) {
                if(funcionario.estabelecimento.isSecretaria) {
                    delete funcionario.estabelecimento;
                }
            });
        }

        function verificaQuantidadeAulas() {
            if (!vm.matricula.funcionarioSubstituto.length) {
                return;
            }

            vm.matricula.funcionarioSubstituto.forEach(function (funcionario) {
                funcionario.aulasSubstituidas.map(function (aulaSubstituida) {
                    if (aulaSubstituida.quantidadeAulas === null) {
                        aulaSubstituida.quantidadeAulas = 0;
                    }
                    return aulaSubstituida;
                });
            });          
        }

        function verificarHorasPersonalizadas() {
            if (!vm.matricula.funcionarioSubstituto.length) {
                return;
            }

            vm.matricula.funcionarioSubstituto.map(function (funcionario) {
                funcionario.utilizaHorasPersonalizadas = funcionario.aulasSubstituidas.length > 1;
                return funcionario;
            });
        }

        function canSaveSubstituicao() {
            return validaCargaHorariaLocaisTrabalho();
        }

        function validaCargaHorariaLocaisTrabalho() {
            if (_.isEmpty(vm.locaisTrabalhoSubstituicao)) {
                return true;
            }

            return _.every(vm.locaisTrabalhoSubstituicao, function (localDeTrabalho) {
                return localDeTrabalho.$$canSave;
            });
        }
        
    }
})();
(function () {
  'use strict';

  angular
    .module('educacao.educacenso')
    .directive(
      'appBadgedCountErrosEducacenso',
      SelectBadgedCountErrosEducacenso
    );

  function SelectBadgedCountErrosEducacenso() {
    return {
      restrict: 'E',
      templateUrl:
        'educacenso/app-badged-count-erros-educacenso/app-badged-count-erros-educacenso.html',
      bindToController: true,
      scope: {
        tiposErros: '@',
        estabelecimento: '=',
      },
      controller: Controller,
      controllerAs: 'vm',
    };
  }

  Controller.$inject = [];

  function Controller() {
    var vm = this;

    vm.isBadgedNaoProcessado = isBadgedNaoProcessado;
    vm.isBadgedProcessando = isBadgedProcessando;
    vm.isBadgedConcluido = isBadgedConcluido;
    vm.isBadgedValidadoComErros = isBadgedValidadoComErros;
    vm.getNumeroErros = getNumeroErros;

    function isBadgedNaoProcessado() {
      return !vm.estabelecimento.educacensoExecucao;
    }

    function isBadgedProcessando() {
      return _.get(vm.estabelecimento, 'educacensoExecucao.execucaoFinalizada') === false;
    }

    function isBadgedConcluido() {
      if (_.get(vm.estabelecimento, 'educacensoExecucao.execucaoFinalizada')) {
        return !existsErros();
      }

      return false;
    }

    function isBadgedValidadoComErros() {
      if (_.get(vm.estabelecimento, 'educacensoExecucao.execucaoFinalizada')) {
        return existsErros();
      }
      return false;
    }

    function getNumeroErros() {
      if (!vm.estabelecimento.educacensoExecucao) {
        return 0;
      }
      return somarValoresPorTipos();
    }

    function existsErros() {
      return somarValoresPorTipos() > 0;
    }

    function somarValoresPorTipos() {
      var tipos = vm.tiposErros.split(',');
      var soma = 0;

      _.forEach(tipos, function (tipo) {
        if (vm.estabelecimento.hasOwnProperty(tipo)) {
          soma += vm.estabelecimento[tipo];
        }
      });

      return soma;
    }
  }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('estabelecimentoEquipamento', InputEquipamentos);

    InputEquipamentos.$inject = [];

    function InputEquipamentos() {

        var config = {};
                             
        config.templateUrl = 'planejamento/escolas/estabelecimentos-equipamentos/app-estabelecimento-equipamento.directive.html';
        config.restrict = 'E';
        config.require = 'ngModel';
        config.scope = {};
        config.scope.enumEquipamento = '=';
        config.scope.ngModel = '=';
        config.scope.required = '=';

        config.controller = InputEquipamentosCtrl;
        config.controllerAs = 'vm';

        return config;
    }

    InputEquipamentosCtrl.$inject = [ '$scope', 'ui.components.Modal'];

    function InputEquipamentosCtrl($scope, $modal) {

        var vm = this;

        vm.formatterTag = formatterTag;
        vm.openCad = openCad;

        if (!$scope.ngModel) {
            $scope.ngModel = [];
        }

        function formatterTag(estabelecimentoEquipamento) {
        
            return $scope.enumEquipamento[estabelecimentoEquipamento.equipamento].description + ' (' + estabelecimentoEquipamento.quantidade + ')';
        }

        function openCad() {
           return $modal.open({
                templateUrl: 'planejamento/escolas/estabelecimentos-equipamentos/app-estabelecimento-equipamento-modal.html',
                controller: 'educacao.pessoas.EquipamentoCadCtrl',
                controllerAs: 'vm',
                params: {
                    estabelecimentosEquipamentos: $scope.ngModel,
                    enumEquipamento: $scope.enumEquipamento
                }
            });
        }

    }
})();

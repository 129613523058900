(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectAtividadeComplementar', SelectAtividadeComplementarDirective);

    function SelectAtividadeComplementarDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" placeholder="placeholder" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" search="vm.search" add-value="vm.add" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                placeholder: '=?',
                dropdownCssClass: '=?'
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    Controller.$inject = ['$scope', 'ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.matricula.AtividadeComplementarService'];

    function Controller($scope, $modal, PermissionsService, service) {

        var vm = this;

        vm.formatResult = formatResult;
        vm.search = search;
        vm.add = !PermissionsService.isRevokedOperation('AtividadeComplementarPageMapping', 'criar') && add;

        function search(params) {
            var filter = '(descricao like "' + params.term + '"';
            if ($scope.multiple) {
                var atividades = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(atividade) {
                        atividades.push(atividade.id);
                    });
                }
                if (atividades.length) {
                    filter += ' and id not in(' + atividades.join() + ')';
                }
            }
            filter += ')';

            return service.list(filter, params.offset, params.limit, params.sort);
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'matricula/atividades-complementares/atividade.complementar.cad.html',
                controller: 'educacao.matricula.AtivComplCadCtrl',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    atividade: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }

        function formatResult(data) {
            return data.descricao;
        }
    }
})();

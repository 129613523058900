(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('classificacaoCandidato', classificacaoCandidato);

    function classificacaoCandidato() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/classificacao-inscritos/directives/classificacao-candidato.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                lista: '=',
                edit: '=',
                processo: '=',
                visualizarInscricao: '='
            }
        };
    }

    Controller.$inject = ['$scope',
        '$filter'
    ];

    function Controller($scope, $filter) {

        var vm = this;

        vm.edit = edit;
        vm.visualizarInscricao = visualizarInscricao;
        vm.agrupaEstabelecimentosETurnos = agrupaEstabelecimentosETurnos;

        watchFields();

        function watchFields() {

            $scope.$watch('lista', onList);

            function onList(lista) {
                vm.lista = lista;
            }
        }

        function agrupaEstabelecimentosETurnos(estabelecimentos) {
            var orderBy = $filter('orderBy');
            estabelecimentos = orderBy(estabelecimentos || [], ['nomeEstabelecimento', 'turno']);
            var estabelecimentosAgrupados = {};
            _.forEach(estabelecimentos, function(estabelecimento) {
                var nomeEstabelecimento = estabelecimento.estabelecimento.nome;
                var turno = _.capitalize(estabelecimento.turno.toLowerCase());

                var turnos = estabelecimentosAgrupados[nomeEstabelecimento];
                if (turnos) {
                    turnos += ' - ' + turno;
                } else {
                    turnos = turno;
                }

                estabelecimentosAgrupados[nomeEstabelecimento] = turnos;
            });
            return estabelecimentosAgrupados;
        }

        function edit(inscricao) {
            $scope.edit(inscricao);
        }

        function visualizarInscricao(inscricao) {
            $scope.visualizarInscricao(inscricao);
        }

    }
})();

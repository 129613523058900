(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('appCardToggle', appCardToggleDirective);

    function appCardToggleDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/app-card-toggle/app-card-toggle.directive.html',
            scope: {},
            bindToController: {
                titulo: '=',
                saldo: '=',
                card: '=',
                height: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento',
        '$timeout'
    ];

    function Controller(ContextoEstabelecimento, $timeout) {
        var vm = this;
        vm.nomeEstabelecimento = vm.estabelecimentoSelected !== null ? _.get(vm.estabelecimentoSelected,'descricao'): ContextoEstabelecimento.getEstabelecimento().pessoa.nome;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.isEmptySaldo = false;

        init();

        function init() {
            checkIsEmptySaldo();
            ajusteMinHeightAppCard();
        }

        function checkIsEmptySaldo() {
            if (vm.card.saldo === 0 || _.isUndefined(vm.card.saldo)) {
                vm.isEmptySaldo = true;
            }
        }

        function ajusteMinHeightAppCard() {
            $timeout(function () {
                if (vm.height) {
                    vm.heightAjustada = vm.height + 'px';
                    return;
                }
                vm.heightAjustada = '11rem';
            });
        }
    }
})();

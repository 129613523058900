(function() {
    'use strict';

    angular.module('educacao.planejamento')
        .factory('educacao.planejamento.AvaliacaoExternaSelect', AvaliacaoExternaSelect);

    AvaliacaoExternaSelect.$inject = ['$injector'];

    function AvaliacaoExternaSelect($injector) {

        var AvaliacaoExternaService = $injector.get('educacao.planejamento.AvaliacoesExternasService');
        var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');
        var ModalCad = $injector.get('educacao.common.ModalCad');

        var PermissionsService = $injector.get('bfc.$$PermissionsService');
        var onAdd = PermissionsService.isRevokedOperation('AvaliacaoExternaPageMapping', 'criar') ? undefined : add;

        return {
            select2Config: function() {
                return CommonSelectWithService.select2Config(AvaliacaoExternaService, 'descricao', {
                    onAdd: onAdd
                });
            }
        };

        function add(val, target) {
            ModalCad.open({
                templateUrl: 'planejamento/avaliacoes/avaliacoes.cad.html',
                controller: 'educacao.planejamento.AvaliacoesExternasCadCtrl as AvalCadCtrl',
                optionsCad: {
                    'descricao': val
                },
                selectElem: target
            });
            return false;
        }
    }
})();
(function() {
    'use strict';

    var SITUACAO_FINAL_OUTRA = 'OUTRA';

    angular.module('educacao.historico-escolar')
        .controller('educacao.historicoEscolar.HistoricoEscolarCtrl', HistoricoEscolarController);

    HistoricoEscolarController.$inject = [
		'ui.components.Modal',
		'$modalInstance',
		'promiseTracker',
		'educacao.historicoEscolar.HistoricoEscolarService',
		'educacao.pessoas.AlunoService',
		'educacao.matricula.MatriculaService',
		'educacao.context.ContextoEstabelecimento',
		'educacao.context.ContextoAnoLetivo',
		'bfc.Notification',
		'$q',
		'bfc.$$PermissionsService'
	];

    function HistoricoEscolarController(
        uiModal,
        $modalInstance,
        promiseTracker,
        HistoricoEscolarService,
        AlunoService,
        MatriculaService,
        ContextoEstabelecimento,
        ContextoAnoLetivo,
        Notification,
        $q,
        PermissionsService
    ) {

        var vm = this;

        vm.openModalCadHistorico = openModalCadHistorico;
        vm.getClassOrderBy = getClassOrderBy;
        vm.alterarCampoOrdenacaoGrid = alterarCampoOrdenacaoGrid;
        vm.getCampoOrdenacao = getCampoOrdenacao;
        vm.editItem = editItem;
        vm.removeItem = removeItem;
        vm.selecionarTodos = selecionarTodos;
        vm.hasHistoricosSelecionados = hasHistoricosSelecionados;
        vm.openModalImprimirHistoricos = openModalImprimirHistoricos;
        vm.openModalImprimirHistoricoIndividual = openModalImprimirHistoricoIndividual;
        vm.listControls = {};

        vm.isEjaModular = isEjaModular;

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.valorEstabelecimentoContexto = ContextoEstabelecimento.getValorContextoEstabelecimento();
        vm.anoLetivoContexto = ContextoAnoLetivo.getAnoLetivo();

        vm.aluno = $modalInstance.params.aluno || {};

        vm.tracker = {
            getHistoricosCadastrados: promiseTracker(),
            getEnums: promiseTracker()
        };

        init();

        function init() {
            var promisseEnum = HistoricoEscolarService.getNivelModalidade().then(function(modalidades) {
                vm.modalidades = modalidades;

                HistoricoEscolarService.getNivelEscolar().then(function(niveisEscolares) {
                    vm.nivelEscolar = niveisEscolares;

                    MatriculaService.getEnum('SituacaoMatricula').then(function(situacaoMatricula) {
                        vm.situacaoMatricula = situacaoMatricula;

                        HistoricoEscolarService.getEquivalenciaEtapa().then(function(equivalencia) {
                            vm.equivalencias = equivalencia;

                            HistoricoEscolarService.getSituacaoHistorico().then(function(
                                situacaoHistorico) {
                                vm.situacaoHistorico = situacaoHistorico;

                                getCadastros(vm.aluno);
                            });
                        });
                    });
                });
            });

            vm.tracker.getEnums.addPromise(promisseEnum);
        }

        function isEjaModular(value) {
            var firstItem = _.first(value);
            return Boolean(firstItem && firstItem.modular);
        }

        function isMatriculaEjaModular(matricula) {
            return Boolean(matricula && matricula.matriz && matricula.matriz.modular);
        }

        function getCadastros(aluno) {

            vm.campoOrdenacao = ['anoConclusao', 'etapa'];
            vm.orderByReverse = false;

            vm.gruposOrdenacao = [];

            vm.matriculasHistoricos = [];

            getMatriculas(aluno);
            getHistoricos(aluno);
        }

        function getMatriculas(aluno) {
            var promise = AlunoService.getMatriculasHistoricosEscolares(aluno.id)
                .then(function(dados) {
                    var matriculas = dados.content;

                    _.forEach(matriculas, function(matricula) {
                        if (isMatriculaEjaModular(matricula)) {
                            _.forEach(matricula.filhos, function(filho) {
                                var cloneFilho = _.cloneDeep(filho);
                                cloneFilho.itemCurricular = {
                                    descricao: filho.disciplina.disciplina.descricao
                                };

                                /*Adaptado parametros para aproveitar da diretiva do historico eja modular*/
                                var params = {
                                    matricula: matricula,
                                    modular: true,
                                    componentes: [cloneFilho],
                                    nivelModalidade: matricula.matriz.curso.nivelModalidade,
                                    nivelEscolar: matricula.matriz.curso.nivelEscolar,
                                    situacao: filho.situacao,
                                    equivalencias: filho.etapaMatriz.equivalencias,
                                    id: matricula.id,
                                    estabelecimento: filho.estabelecimento.descricao,
                                    curso: matricula.matriz.curso.descricao,
                                    etapa: filho.etapaMatriz.descricao,
                                    anoConclusao: matricula.anoLetivo.descricao,
                                    classificacao: filho.etapaMatriz.classificacao
                                };
                                setGridMaticila(params);
                            });
                        } else {
                            var params = {
                                matricula: matricula,
                                nivelModalidade: matricula.vaga.matriz.curso.nivelModalidade,
                                nivelEscolar: matricula.vaga.matriz.curso.nivelEscolar,
                                situacao: matricula.situacao,
                                equivalencias: matricula.vaga.etapaMatriz.equivalencias,
                                id: matricula.id,
                                estabelecimento: matricula.estabelecimento.descricao,
                                curso: matricula.vaga.matriz.curso.descricao,
                                etapa: matricula.etapaMatriz.descricao,
                                anoConclusao: matricula.anoLetivo.descricao,
                                classificacao: matricula.etapaMatriz.classificacao
                            };
                            setGridMaticila(params);
                        }
                    });
                });

            vm.tracker.getHistoricosCadastrados.addPromise(promise);
        }

        function setGridMaticila(params) {
            var itemGrid = {
                agrupamento: '',
                equivalencias: [],
                isMatricula: true,
                componentes: params.componentes || null,
                modular: params.modular || false,
                permissao: 'app.matricula.matriculas'
            };

            itemGrid.permiteEdicao = verificarContextoValidoMatricula(params.matricula);

            var modalidade = _.find(vm.modalidades.values, 'id', params.nivelModalidade);
            if (modalidade) {
                itemGrid.agrupamento = modalidade.description;
                itemGrid.modalidade = modalidade;
            }

            itemGrid.agrupamento += itemGrid.modular ? ' Modular' : '';

            var nivel = _.find(vm.nivelEscolar.values, 'id', params.nivelEscolar);
            if (nivel) {
                if (itemGrid.agrupamento) {
                    itemGrid.agrupamento += ' / ';
                    itemGrid.nivel = nivel;
                }
                itemGrid.agrupamento += nivel.description;
            }

            var situacao = _.find(vm.situacaoMatricula.values, 'id', params.situacao);
            if (situacao) {
                itemGrid.situacaoFinal = situacao.description;
            }

            angular.forEach(params.equivalencias,
                function(equivalencia) {
                    var equivalenciaVisivel = _.find(vm.equivalencias.values, 'id', equivalencia);
                    if (equivalenciaVisivel) {
                        itemGrid.equivalencias.push(equivalenciaVisivel.description);
                    }
                });

            _.extend(itemGrid, {
                id: params.id,
                estabelecimento: params.estabelecimento,
                curso: params.curso,
                etapa: params.etapa,
                anoConclusao: params.anoConclusao,
                classificacao: params.classificacao,
            });

            registraCampoOrdenacao({
                groupKey: itemGrid.agrupamento,
                campoOrdenacao: ['anoConclusao', 'etapa'],
                orderByReverse: false
            });

            vm.matriculasHistoricos.push(
                itemGrid);
        }

        function getHistoricos(aluno) {
            var promiseGetHistoricos = AlunoService.getHistoricosEscolares(aluno.id)
                .then(function(dadosRetorno) {

                    var historicos = dadosRetorno.content;

                    angular.forEach(historicos, function(historico) {

                        var itemGrid = {
                            agrupamento: '',
                            equivalencias: [],
                            componentes: [],
                            isMatricula: false,
                            modular: false,
                            permissao: 'app.historicoEscolar'
                        };

                        var modalidade = _.find(vm.modalidades.values, 'id', historico.nivelModalidade);
                        if (modalidade) {
                            itemGrid.agrupamento = modalidade.description;
                            itemGrid.modalidade = modalidade;
                        }

                        itemGrid.componentes = historico.componentes;
                        itemGrid.modular = historico.modular;
                        itemGrid.agrupamento += itemGrid.modular ? ' Modular' : '';

                        var nivel = _.find(vm.nivelEscolar.values, 'id', historico.nivelEscolar);
                        if (nivel) {
                            if (itemGrid.agrupamento) {
                                itemGrid.agrupamento += ' / ';
                                itemGrid.nivel = nivel;
                            }
                            itemGrid.agrupamento += nivel.description;
                        }

                        var situacao = _.find(vm.situacaoHistorico.values, 'id', historico.situacao);
                        if (situacao) {
                            if (situacao.id === SITUACAO_FINAL_OUTRA) {
                                itemGrid.situacaoFinal = historico.situacaoEspecifica;
                            } else {
                                itemGrid.situacaoFinal = situacao.description;
                            }
                        }

                        angular.forEach(historico.equivalencias,
                            function(equivalencia) {
                                var equivalenciaVisivel = _.find(vm.equivalencias.values, 'id', equivalencia);
                                if (equivalenciaVisivel) {
                                    itemGrid.equivalencias.push(equivalenciaVisivel.description);
                                }
                            });

                        _.extend(itemGrid, {
                            id: historico.id,
                            estabelecimento: historico.nomeEstabelecimento || historico.estabelecimento.descricao,
                            curso: historico.descricaoCurso,
                            etapa: historico.etapa || _.get(historico, 'etapaMatriz.descricao'),
                            anoConclusao: historico.anoConclusao,
                            classificacao: historico.classificacao
                        });

                        registraCampoOrdenacao({
                            groupKey: itemGrid.agrupamento,
                            campoOrdenacao: ['anoConclusao', 'etapa'],
                            orderByReverse: false
                        });

                        vm.matriculasHistoricos.push(
                            itemGrid);
                    });
                });

            vm.tracker.getHistoricosCadastrados.addPromise(promiseGetHistoricos);

        }

        function verificarContextoValidoMatricula(matricula) {
            return ((vm.isSecretaria || vm.valorEstabelecimentoContexto === matricula.estabelecimento.id) && vm.anoLetivoContexto.id ===
                matricula.anoLetivo.id);
        }

        function openModalCadHistorico(aluno) {
            uiModal.open({
                templateUrl: 'historico-escolar/historico-escolar.cad.html',
                controller: 'educacao.historicoEscolar.HistoricoEscolarCadCtrl',
                controllerAs: 'vm',
                params: {
                    aluno: aluno
                },
                size: 'xxl',
            }).result.then(function(hasNewHistoricos) {
                if (hasNewHistoricos) {
                    getCadastros(aluno);
                }
            });
        }

        function editItem(item, aluno) {

            if (item.isMatricula) {
                var matricula;
                if (item) {
                    MatriculaService.get(item.id).then(function(dados) {
                        matricula = dados;

                        if (verificarContextoValidoMatricula(matricula)) {
                            uiModal.open({
                                templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
                                controller: 'educacao.matricula.MatriculaCadCtrl',
                                controllerAs: 'vm',
                                size: 'xxl',
                                params: {
                                    matricula: matricula
                                }
                            });
                        } else {
                            Notification.publish('Não é possível editar a matrícula com o contexto atual', 'error');
                        }
                    });
                }
            } else {
                uiModal.open({
                    templateUrl: 'historico-escolar/historico-escolar.cad.html',
                    controller: 'educacao.historicoEscolar.HistoricoEscolarCadCtrl',
                    controllerAs: 'vm',
                    params: {
                        historico: item ? HistoricoEscolarService.get(item.id) : null,
                        dadosExclusao: item
                    },
                    size: 'xxl',
                }).result.then(function(hasNewHistoricos) {
                    if (hasNewHistoricos) {
                        getCadastros(aluno);
                    }
                });
            }
        }

        function removeItem(item, aluno) {
            var deferred = $q.defer();
            if (item.isMatricula) {
                MatriculaService.get(item.id)
                    .then(function(matricula) {
                        if (verificarContextoValidoMatricula(matricula)) {
                            var hasPermissionToRemoveCascade = !PermissionsService.isRevokedOperation('MatriculaPageMapping',
                                'excluirVinculos');
                            if (hasPermissionToRemoveCascade) {
                                openForceRemoveMatricula(matricula);
                                deferred.resolve();
                                return $q.reject();
                            }
                            MatriculaService.remove(matricula)
                                .then(function() {
                                    Notification.publish('Matrícula excluída com sucesso.', 'success');
                                    getCadastros(aluno);
                                    deferred.resolve();
                                }, function() {
                                    deferred.resolve();
                                });
                        } else {
                            Notification.publish('Não é possível remover a matrícula com o contexto atual', 'error');
                        }
                    });
            } else {
                uiModal.open({
                    templateUrl: 'historico-escolar/remove-historico-escolar/remove-historico-escolar.html',
                    controller: 'educacao.historicoEscolar.RemoveHistoricoEscolarCrlt',
                    controllerAs: 'vm',
                    params: {
                        historico: item,
                        aluno: vm.aluno
                    },
                    size: 'lg'
                }).result.then(function(confirm) {
                    if (confirm) {
                        HistoricoEscolarService.remove(item).then(notificarExclusao).then(recarregarModal);
                    }
                });
            }
        }

        function notificarExclusao() {
            Notification.publish('Histórico escolar excluído com sucesso', 'success');
        }

        function recarregarModal() {
            getCadastros(vm.aluno);
        }

        function openForceRemoveMatricula(matricula) {
            uiModal.open({
                templateUrl: 'matricula/matriculas/remove-matricula-cascade/remove-matricula-cascade.html',
                controller: 'educacao.matricula.RemoveMatriculaCascadeController as vm',
                size: 'sm',
                params: {
                    matricula: matricula
                }
            });
        }

        function registraCampoOrdenacao(ordenacao) {
            var group = _.find(vm.gruposOrdenacao, 'groupKey', ordenacao.groupKey);
            if (!group) {
                vm.gruposOrdenacao.push(ordenacao);
            }
        }

        function getCampoOrdenacao(key) {
            var group = _.find(vm.gruposOrdenacao, 'groupKey', key);
            return group;
        }

        function alterarCampoOrdenacaoGrid(campo, key) {
            var group = _.find(vm.gruposOrdenacao, 'groupKey', key);
            if (!group) {
                return;
            } else {
                if (!_.isEqual(campo, group.campoOrdenacao)) {
                    group.orderByReverse = false;
                } else {
                    group.orderByReverse = !group.orderByReverse;
                }

                group.campoOrdenacao = campo;
            }
        }

        function getClassOrderBy(campoClass, key) {
            var group = _.find(vm.gruposOrdenacao, 'groupKey', key);
            if (!group) {
                return;
            }
            return {
                'fa-sort-down': _.isEqual(group.campoOrdenacao, campoClass) && group.orderByReverse,
                'fa-sort-up': _.isEqual(group.campoOrdenacao, campoClass) && !group.orderByReverse,
                'fa-sort': !_.isEqual(group.campoOrdenacao, campoClass)
            };
        }

        function selecionarTodos(values, groupChecked) {

            if (groupChecked) {
                angular.forEach(values, function(value) {
                    value.checked = true;
                });
            } else {
                angular.forEach(values, function(value) {
                    value.checked = false;
                });
            }
        }

        function hasHistoricosSelecionados(matriculasHistoricos, key) {
            var registros = [];
            matriculasHistoricos.forEach(function(item) {
                if (item.agrupamento === key) {
                    registros.push(item);
                }
            });
            return _.find(registros, 'checked', true);
        }

        function openModalImprimirHistoricos(matriculasHistoricos, keyGroup, $event) {
            vm.historicosSelecionados = [];

            angular.forEach(matriculasHistoricos, function(itemHistorico) {
                if (itemHistorico.checked && itemHistorico.agrupamento === keyGroup) {
                    vm.historicosSelecionados.push(itemHistorico);
                }
            });

            if (vm.historicosSelecionados.length > 0) {
                uiModal.open({
                    templateUrl: 'historico-escolar/impressao-historico-escolar/impressao-historico-escolar.html',
                    controller: 'educacao.historicoEscolar.ImpressaoHistoricoEscolarCtrl',
                    controllerAs: 'vm',
                    params: {
                        historicosSelecionados: vm.historicosSelecionados,
                        aluno: vm.aluno
                    },
                    size: 'xl'
                });
            }

            $event.stopPropagation();
        }

        function openModalImprimirHistoricoIndividual(matriculaHistorico) {
            uiModal.open({
                templateUrl: 'historico-escolar/impressao-historico-escolar/impressao-historico-escolar.html',
                controller: 'educacao.historicoEscolar.ImpressaoHistoricoEscolarCtrl',
                controllerAs: 'vm',
                params: {
                    historicosSelecionados: [matriculaHistorico],
                    aluno: vm.aluno
                },
                size: 'xl'
            });
        }
    }
})();

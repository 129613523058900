(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .directive('inputDesempenhoMediaFinal', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'desempenho-escolar/input-media-final/input-media-final.directive.html',
            replace: true,
            scope: {},
            bindToController: {
                nota: '=',
                configuracao: '=',
                placeholder: '@',
                onChange: '&'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.matricula.TipoAvaliacaoUtilsService',
        'educacao.desempenhoescolar.DesempenhoEscolarUtilsService'
    ];

    function Controller(TipoAvaliacaoUtilsService, DesempenhoEscolarUtilsService) {
        var vm = this;

        vm.popoverMediaFinalPath = 'desempenho-escolar/input-media-final/popover-media-final.html';

        vm.mediaFinalSemConselho = _.first(_.get(vm.nota, 'filhos'));

        initController();

        function initController() {

            if(!vm.mediaFinalSemConselho) {
                return;
            }

            var modoAvaliacao = vm.mediaFinalSemConselho.modoAvaliacao;

            if(DesempenhoEscolarUtilsService.isModoAvaliacaoNumerico(modoAvaliacao)){
                vm.isMediaFinalSemConselhoNumerico = true;
                vm.decimais = TipoAvaliacaoUtilsService.getQuantidadeCasasDecimais(vm.configuracao);
            }
        }
    }
})();
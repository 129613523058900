(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .directive('appCardsSaldos', appCardsSaldosDirective);

    function appCardsSaldosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/app-card-saldo/app-card-saldo.directive.html',
            scope: {},
            bindToController: {
                cards: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [

    ];

    function Controller() {
        var vm = this;

        vm.attr = '';
    }

})();
(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appCardTurmaProfessores', appCardTurmaProfessores);

	function appCardTurmaProfessores() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/turma/app-card-turma/professores/app-card-turma-professores.directive.html',
			scope: {
				ngModel: '=',
				reloadProfessores: '=',
				onDesenturmar: '&',
				onRemover: '&',
				loadingProfessores: '='
			},
			link: function($scope, $element, $attrs) {
				if (!$attrs.onRemover) {
					delete $scope.onRemover;
				}

				if (!$attrs.onDesenturmar) {
					delete $scope.onDesenturmar;
				}
			},

			controller: CardTurmaDirectiveController,
			controllerAs: 'vm'
		};
	}

	CardTurmaDirectiveController.$inject = ['$scope', 'promiseTracker', '$injector'];

	function CardTurmaDirectiveController($scope, $promiseTracker, $injector) {
		var vm = this;

		var TurmaService = $injector.get('educacao.matricula.TurmaService');
		var ItemEducacionalService = $injector.get('educacao.common.ItemEducacionalService');
		var uiComponentsModal = $injector.get('ui.components.Modal');

		vm.turma = $scope.ngModel || {};

		vm.isAtividade = isAtividade;
		vm.isEjaModular = isEjaModular;
		vm.onDesenturmar = onDesenturmar;
		vm.onRemover = onRemover;
		vm.openModalEnturmacoesInativas = openModalEnturmacoesInativas;

		vm.tracker = {
			enturmacoes: $promiseTracker()
		};

		vm.tracker.professores = $scope.loadingProfessores;

		vm.getApiHistorico = getApiHistorico;
		setFilterHistorico();

		function getApiHistorico(item) {
			return 'enturmacao-funcionario/' + item.id + '/historicos-enturmacoes';
		}

		function onDesenturmar(enturmacao) {
			$scope.onDesenturmar({
				enturmacao: enturmacao,
				turma: vm.turma
			});
		}

		function onRemover(enturmacao) {
			$scope.onRemover({
				enturmacao: enturmacao,
				turma: vm.turma
			});
		}

		function openModalEnturmacoesInativas(turmaId) {
			uiComponentsModal.open({
				templateUrl: 'matricula/turma/app-card-turma/professores/enturmacoes-inativas-template.html',
				params: {
					turmaId: turmaId
				},
				controller: 'educacao.matricula.EnturmacoesInativasController',
				controllerAs: 'vm',
				size: 'lg'
			});
		}

		function setFilterHistorico() {
			vm.filterHistorico = [{
				active: true,
				descricao: 'Todos',
				template: '(tipo="ENTURMACAO_FUNCIONARIO" or tipo="DESENTURMACAO_FUNCIONARIO" or tipo="ALTERACAO_ENTURMACAO_FUNCIONARIO" or tipo="ALTERACAO_DESENTURMACAO_FUNCIONARIO")'
			}, {
				descricao: 'Enturmação',
				template: '(tipo="ENTURMACAO_FUNCIONARIO" or tipo="ALTERACAO_ENTURMACAO_FUNCIONARIO")'
			}, {
				descricao: 'Desenturmação',
				template: '(tipo="DESENTURMACAO_FUNCIONARIO" or tipo="ALTERACAO_DESENTURMACAO_FUNCIONARIO")'
			}];
		}

		$scope.$watch('ngModel', function(value) {
			vm.turma = value || {};
		});

		$scope.$watch('reloadProfessores', function(value) {
			if (value) {
				vm.itemCollapse = true;
				$scope.reloadProfessores = false;
				carregaEnturmacoes();
			}
		});

		$scope.$watch('vm.itemCollapse', function(value) {
			if (value) {
				carregaEnturmacoes();
			}
		});

		function isAtividade(tipoAtendimento) {
			return ItemEducacionalService.isAtividade(tipoAtendimento);
		}

		function isEjaModular() {
			return ItemEducacionalService.isEjaModular(vm.turma);
		}

		function carregaEnturmacoes() {
			var filter = 'turma = ' + vm.turma.id + ' and dataEncerramento is null';
			var sort = 'tipo';

			var promise = TurmaService.getEnturmacoesFuncionarios({
				filter: filter,
				sort: sort
			}).then(buscaProfessoresPorTurma);

			vm.tracker.enturmacoes.addPromise(promise);

			function buscaProfessoresPorTurma(page) {
				vm.turma.enturmacoes = page.content;
				vm.turma.enturmacoes.sort(ordenarEnturmacoesFuncionarios);
			}
		}

		function ordenarEnturmacoesFuncionarios(a, b) {
            return a.professor.nome.localeCompare(b.professor.nome) || compararDescricaoItemEducacional(a, b);
        }

        function compararDescricaoItemEducacional(a, b) {
            if (a.itemEducacional.descricao) {
                return a.itemEducacional.descricao.localeCompare(b.itemEducacional.descricao);
            }
            return a.itemEducacional.descricaoPai.localeCompare(b.itemEducacional.descricaoPai);
        }
	}
})();

(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.EstabelecimentoCadCtrl', EstabelecimentoCadCtrl);

    EstabelecimentoCadCtrl.$inject = [
        '$rootScope',
        '$scope',
        '$injector',
        '$modalInstance',
        'promiseTracker',
        '$modalTracker',
        'id',
        '$q',
        'fnCad',
        'readOnly',
        'optionsCad',
        'bfc.Notification',
        '$enumsCache'
    ];

    function EstabelecimentoCadCtrl($rootScope, $scope, $injector, $modalInstance, promiseTracker, $modalTracker, id, $q, fnCad,
                                    readOnly,
                                    optionsCad,
                                    bfcNotification, $enumsCache) {
        var ModalCad = $injector.get('educacao.common.ModalCad'),
            focus = $injector.get('bfc.Focus'),
            EstabelecimentoService = $injector.get('educacao.pessoas.EstabelecimentoService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService'),
            LinguaIndigenaSelect = $injector.get('educacao.common.LinguaIndigenaSelect'),
            AvaliacaoExternaService = $injector.get('educacao.planejamento.AvaliacoesExternasService'),
            PessoaService = $injector.get('educacao.pessoas.PessoaService'),
            ParametroService = $injector.get('educacao.common.ParametroService');

        var vm = this,
            _pessoasM2;

        vm.showTab = showTab;

        var _select2 = {};
        EstabelecimentoService.getEquipamento().then(
            function (enumEquipamento) {
                vm.enumEquipamento = enumEquipamento;
            }
        );
        vm.tracker = promiseTracker();
        vm.trackerCamposAdicionais = promiseTracker();
        vm.isUnidadeInformada = isUnidadeInformada;
        vm.isSelectNenhumaDasOpcoesFormasAmbientais = null;
        vm.filterResultPadrao = filterResultPadrao;
        vm.filterDestinacaoLixo = filterDestinacaoLixo;
        vm.filterResultConvenioPoderPublico = filterResultConvenioPoderPublico;

        vm.bairroService = $injector.get('educacao.enderecos.BairroService');
        vm.estadoService = $injector.get('educacao.enderecos.EstadoService');
        vm.municipioService = $injector.get('educacao.enderecos.MunicipioService');

        vm.windowTitle = 'estabelecimento de ensino';
        vm.layoutTeste = false;
        vm.equipesDiretivasListagem = [];
        vm.searchMethod = EstabelecimentoService.getPessoas;
        vm.pessoaSelecionada = pessoaSelecionada;
        vm.isConvenioPreenchido = isConvenioPreenchido;
        vm.addTipoMatriculaConvenio = addTipoMatriculaConvenio;
        vm.addConvenioPoderPublicoEstabelecimento = addConvenioPoderPublicoEstabelecimento;
        vm.delTipoMatriculaConvenio = delTipoMatriculaConvenio;
        vm.delConvenioPoderPublicoEstabelecimento = delConvenioPoderPublicoEstabelecimento;
        vm.searchTiposMatriculasConvenios = searchTiposMatriculasConvenios;
        vm.formatResultTipoMatriculaConvenio = formatResultTipoMatriculaConvenio;
        vm.verificaQtdTipoMatriculaConvenioUp = verificaQtdTipoMatriculaConvenioUp;
        vm.verificaQtdTipoMatriculaConvenioDown = verificaQtdTipoMatriculaConvenioDown;
        vm.hasConvenioEstabelecimento = hasConvenioEstabelecimento;
        vm.QTD_MAXIMA_CONVENIOS_PODER_PUBLICO = 2;
        vm.NENHUMA_DAS_OPCOES = 'NENHUMA_DAS_OPCOES';
        var OPCAO_OUTRO = 'OUTRO';

        var _modo = {
            adicionando: !id && !readOnly,
            adicionandoNovaOpcao: Boolean(fnCad),
            editando: id && !readOnly,
            visualizando: id && readOnly
        };
        var _getMascaraTelefone = function (name) {
            return ObjectUtilsService.getMascaraTelefone(name);
        };

        $scope.$watchCollection('vm.estabelecimento.enderecos', removeCepInvalidChars);
        $scope.$watch('vm.estabelecimento.unidade', watchUnidade);
        $scope.$watch('vm.estabelecimento.dependenciaAdministrativa', watchDependenciaAdministrativa);
        $scope.$watch('vm.estabelecimento.educacaoAmbiental', watchEducacaoAmbiental);
        $scope.$watch('vm.estabelecimento.formasDesenvolvimentoAmbiental', watchFormaDesenvolvimentoAmbiental);


        $scope.isRequiredConvenioPoderPublico = function (convenioPoderPublico) {
            return !_.isEmpty(convenioPoderPublico);
        };

        function removeCepInvalidChars(addresses) {
            _.forEach(addresses, function (address) {
                if (address.cep !== null) {
                    address.cep = _.padRight(address.cep.split('-').join(''), 8, '0');
                }
            });
        }

        var _escolasTabs = [{
            id: '1',
            title: 'Informações gerais',
            content: 'gerais',
            template: 'planejamento/escolas/tabs/escola-gerais-tab.cad.html'
        }, {
            id: '2',
            title: 'Área de atuação',
            content: 'atuacao',
            template: 'planejamento/escolas/tabs/escola-area-atuacao-tabs.cad.html'
        }, {
            id: '3',
            title: 'Infraestrutura',
            content: 'infra',
            template: 'planejamento/escolas/tabs/escola-infraestrutura-tab.cad.html'
        }, {
            id: '4',
            title: 'Documentação',
            content: 'documentacao',
            template: 'planejamento/escolas/tabs/escola-documentacao-tab.html'
        }, {
            id: '5',
            title: 'Avaliações externas',
            content: 'avaliacao',
            template: 'planejamento/escolas/tabs/escola-avaliacao-tab.html'
        }, {
            id: '6',
            title: 'Dependências',
            content: 'dependencia',
            template: 'planejamento/escolas/tabs/escola-dependencia.tab.cad.html'
        }, {
            id: '7',
            title: 'Equipe diretiva',
            content: 'equipeDiretiva',
            template: 'planejamento/escolas/tabs/escola-equipe-tab.cad.html'
        }, {
            id: '8',
            title: 'Escola Privada',
            content: 'escolaPrivada',
            template: 'planejamento/escolas/tabs/escola-privada-tab.cad.html'
        }, {
            id: '9',
            title: 'Campos adicionais',
            content: 'camposAdicionais',
            template: 'planejamento/escolas/tabs/escola-campo-adicional-tab.cad.html'
        }];

        init();

        function isUnidadeInformada() {
            return _.get(vm.estabelecimento, 'unidade') && vm.estabelecimento.unidade !== 'NAO';
        }

        function init() {

            $enumsCache.get('ConvenioPoderPublico').then(function (enumData) {
                vm.enumConvenioPoderPublico = enumData;
            });

            var propriedadesPublicas = {
                cancel: _cancel,
                modo: _modo,
                save: _save,
                remove: _remove,
                select2: _select2,
                escolasTabs: _escolasTabs,
                currentTab: '1',
                getMascaraTelefone: _getMascaraTelefone,
                suggest: _suggest,
                openAddAvaliacao: abrirPopupCadastroAvaliacoes,
                openAddDocumento: abrirPopupCadastroDocumento,
                openAddVinculo: abrirPopupCadastroVinculo,
                openDependencia: abrirPopupCadastroDependencias,
                parseEtapaAvaliada: _parseEtapaAvaliada,
                removeAvaliacao: _removeAvaliacao,
                removeEquipeDiretiva: _removeEquipeDiretiva,
                removeDocumento: _removeDocumento,
                getIconSortClass: _getIconSortClass,
                mask: {
                    celular: '',
                    fone: ''
                },
                getMessageCapacidade: _getMessageCapacidade,
                removeDependenciaFisica: _removeDependenciaFisica,
                changeEducacaoIndigena: changeEducacaoIndigena,
                changeShowDetalheEquipeDiretiva: changeShowDetalheEquipeDiretiva
            };

            $modalTracker.addPromise(
                $q.all([
                    clearFormulario(),
                    refresh(),
                    initCamposAdicionais(),
                    (function () {
                        _select2.LinguaIndigenaInep = LinguaIndigenaSelect.select2Config();
                        angular.extend(vm, propriedadesPublicas);
                    })(),
                    AvaliacaoExternaService.getEnums().then(function (enums) {
                        _select2.EtapaEnsinoAvaliada = _.indexBy(enums.EtapaEnsinoAvaliadaDto, 'key');
                    }),
                    ParametroService.get().then(
                        function (data) {
                            _pessoasM2 = angular.isDefined(data) ? data.nroPessoasM2 : null;
                        }
                    )
                ]).then(
                    function postInit() {
                        angular.extend(vm, propriedadesPublicas);
                        if (!_.get(vm.estabelecimento, 'estabelecimentoTipoMatriculaConvenios')) {
                            refreshTipoMatriculaConvenio();
                        }
                    })
            );

        }

        function pessoaSelecionada(estabelecimento) {
            if (!estabelecimento) {
                return;
            }
            if (estabelecimento._id) {
                var load = PessoaService.getPessoas(estabelecimento._id)
                    .then(_.bind(onLoadPessoa, null, estabelecimento._id));
                return vm.tracker.addPromise(load);
            }
        }

        function onLoadPessoa(idTemplate, pessoa) {
            vm.estabelecimento.pessoa = pessoa;
        }

        function initCamposAdicionais() {
            vm.trackerCamposAdicionais.addPromise(
                EstabelecimentoService.camposAdicionais(id).then(onInitCamposAdicionais)
            );

            function onInitCamposAdicionais(data) {
                vm.campoAdicional = data;
            }
        }

        function abrirPopupCadastroAvaliacoes(data, index) {

            vm.avaliacaoEdicao = data;

            ModalCad.open({
                templateUrl: 'planejamento/escolas/avaliacoes-externas/escola.avaliacao-tab.cad.html',
                controller: 'educacao.pessoas.EstabelecimentoAvaliacoesCadCtrl as EstabAvalCadCtrl',
                resolve: {
                    avaliacao: (data) ? data : null,
                    id: index
                },
                fnCad: function (avaliacao) {
                    return (onAvaliacaoSaved)(avaliacao);
                },
                readOnly: false
            });
        }

        function onAvaliacaoSaved(avaliacaoChanged) {
            var validateAvaliacao = vm.estabelecimento.avaliacoes.some(function (data) {
                return (data !== vm.avaliacaoEdicao && data.avaliacaoExterna.id === avaliacaoChanged.avaliacaoExterna.id &&
                    data.ano ===
                    avaliacaoChanged.ano);
            });
            if (validateAvaliacao) {
                return $q.reject('Esta avaliação externa já foi informada para este ano!');
            }

            return $q.when(avaliacaoChanged);
        }

        function abrirPopupCadastroDependencias(idDependencia) {
            ModalCad.open({
                templateUrl: 'planejamento/escolas/dependencias-fisicas/escolas.dependencias.cad.html',
                controller: 'educacao.pessoas.DependFisicasCadCtrl as vm',
                id: idDependencia,
                resolve: {
                    estabelecimento: vm.estabelecimento.pessoa.nome || '',
                    dependencia: !_.isNumber(idDependencia) ? null : vm.estabelecimento.dependenciasFisicas[idDependencia]
                }
            });
        }

        function abrirPopupCadastroDocumento(documento, index) {
            ModalCad.open({
                templateUrl: 'planejamento/escolas/documentos/escola-documentacao-cad.html',
                controller: 'educacao.pessoas.DocumentacaoCtrl as vm',
                id: vm.estabelecimento.id,
                resolve: {
                    itemDocumento: documento || null,
                    index: index >= 0 ? index : ''
                },
                readOnly: false
            });
        }

        function clearFormulario() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }

        function refresh() {
            clearFormulario();

            return Boolean(id) ?
                EstabelecimentoService.get(id).then(function (data) {
                    vm.estabelecimento = data;
                    vm.estabelecimento.id = id;
                    if (!_.get(vm.estabelecimento, 'estabelecimentosEquipamentos')) {
                        vm.estabelecimento.estabelecimentosEquipamentos = [];
                    }
                    refreshTipoMatriculaConvenio();
                }) :
                EstabelecimentoService.getDefaultRepresentation().then(function (data) {
                    vm.estabelecimento = angular.extend(data, optionsCad);
                    if (!vm.estabelecimento.dependenciasFisicas) {
                        vm.estabelecimento.dependenciasFisicas = [];
                    }
                    if (!vm.estabelecimento.avaliacoes) {
                        vm.estabelecimento.avaliacoes = [];
                    }
                    if (!vm.estabelecimento.estabelecimentosEquipamentos) {
                        vm.estabelecimento.estabelecimentosEquipamentos = [];
                    }
                    if (!vm.estabelecimento.equipesDiretivas) {
                        vm.estabelecimento.equipesDiretivas = [];
                    }
                    if (!vm.estabelecimento.documentos) {
                        vm.estabelecimento.documentos = [];
                    }
                    if (!vm.estabelecimento.enderecos) {
                        vm.estabelecimento.enderecos = [];
                    }
                    if (!vm.estabelecimento.convenioPoderPublicoEstabelecimento) {
                        vm.estabelecimento.convenioPoderPublicoEstabelecimento = [];
                    }

                    refreshTipoMatriculaConvenio();
                });
        }

        function refreshTipoMatriculaConvenio() {
            if (_.isEmpty(_.head(vm.estabelecimento.estabelecimentoTipoMatriculaConvenios))) {
                vm.estabelecimento.estabelecimentoTipoMatriculaConvenios = [];
                addTipoMatriculaConvenio(vm.estabelecimento.estabelecimentoTipoMatriculaConvenios);
            }
        }

        function _getMessageCapacidade(dependencia) {
            if (!_pessoasM2 || _pessoasM2 === 0) {
                return null;
            }
            var stringBuilder = ObjectUtilsService.getStringBuilder();
            var capacidade = Math.trunc(dependencia.areaUtil / _pessoasM2);

            return !capacidade ? '' :
                stringBuilder
                    .append(capacidade)
                    .append(capacidade > 1 ? ' alunos' : ' aluno')
                    .toString();
        }

        function onSuccess(continuar, tracker) {
            if (continuar) {
                id = null;
                tracker.addPromise(refresh());
                focus('lbNomeEstabelecimento');
            } else {
                _cancel();
            }
        }

        function _suggest(cnpj) {
            if (cnpj && cnpj.length === 14) {
                var promise = EstabelecimentoService.suggest(cnpj).then(function (data) {
                    if (data && data.pessoa) {
                        vm.estabelecimento.pessoa = data.pessoa;
                    } else {
                        vm.estabelecimento.pessoa.id = null;
                    }
                });

                vm.tracker.addPromise(promise);
            }
        }

        function _parseEtapaAvaliada(key) {
            if (typeof _select2.EtapaEnsinoAvaliada !== 'undefined') {
                if (typeof _select2.EtapaEnsinoAvaliada[key] !== 'undefined') {
                    return _select2.EtapaEnsinoAvaliada[key].description;
                }
            }
        }

        function _getIconSortClass(sortBy) {
            return {
                'fa-sort-down': vm.predicateAval === sortBy && vm.reverseAval,
                'fa-sort-up': vm.predicateAval === sortBy && !vm.reverseAval,
                'fa-sort': vm.predicateAval !== sortBy
            };
        }

        $scope.$watchCollection('vm.estabelecimento.locaisFuncionamentos', function (newValue) {
            if (newValue) {
                $scope.existeOutroLocalFuncionamento = newValue.find(function (item) {
                    return item === OPCAO_OUTRO;
                });
                if (!$scope.existeOutroLocalFuncionamento) {
                    vm.estabelecimento.outroLocalFuncionamento = null;
                }
            } else {
                $scope.existeOutroLocalFuncionamento = false;
            }
        });

        $scope.$watchCollection('vm.estabelecimento.destinacoesLixos', function (newValue) {
            if (newValue) {

                $scope.existeOutraDestinacaoLixo = newValue.find(function (item) {
                    return item === OPCAO_OUTRO;
                });

                if (!$scope.existeOutraDestinacaoLixo) {
                    vm.estabelecimento.outraDestinacaoLixo = null;
                }
                return;
            }
            $scope.existeOutraDestinacaoLixo = false;

        });

        $scope.$watch('vm.estabelecimento.educacaoIndigina', function (newValue) {
            if (!newValue && angular.isDefined(newValue)) {
                vm.estabelecimento.linguaEnsinoMinistrado = null;
                vm.estabelecimento.linguaIndigena = null;
                vm.estabelecimento.linguasIndigenas = null;
            }
        });


        function watchFormaDesenvolvimentoAmbiental(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            if (!newValue && angular.isDefined(newValue)) {
                vm.estabelecimento.formasDesenvolvimentoAmbiental = null;
            }

            if (angular.isDefined(newValue) && _.last(newValue) === vm.NENHUMA_DAS_OPCOES) {
                return setOptionNenhumaDasOpcoes();
            }

            if (_.last(newValue) !== vm.NENHUMA_DAS_OPCOES && !_.isEmpty(newValue)) {
                vm.estabelecimento.formasDesenvolvimentoAmbiental =  _.filter(newValue,function (item) {
                    return item !== vm.NENHUMA_DAS_OPCOES;
                });
            }
        }

        function watchEducacaoAmbiental(newValue) {
            if (!newValue && angular.isDefined(newValue)) {
                vm.estabelecimento.formasDesenvolvimentoAmbiental = null;
            }
        }

        function setOptionNenhumaDasOpcoes() {
            vm.estabelecimento.formasDesenvolvimentoAmbiental = [vm.NENHUMA_DAS_OPCOES];
        }

        function watchUnidade(newValue, oldValue) {
            if (angular.equals(newValue, oldValue)) {
                return;
            }

            if (newValue === 'NAO' || !newValue) {
                delete vm.estabelecimento.codigoIES;
                delete vm.estabelecimento.codigoEscolaSede;
            }
            if (newValue === 'UNIDADE_VINCULADA') {
                delete vm.estabelecimento.codigoIES;
            }
            if (newValue === 'UNIDADE_OFERTANTE') {
                delete vm.estabelecimento.codigoEscolaSede;
            }
        }

        function _save(continuar) {
            var tracker = vm.tracker;
            vm.estabelecimento.campoAdicional = angular.copy(vm.campoAdicional);

            if (_.some(vm.estabelecimento.convenioPoderPublicoEstabelecimento, 'convenioPoderPublico', 'NAO_POSSUI_CONVENIO') || _.isEmpty(vm.estabelecimento.convenioPoderPublicoEstabelecimento)) {
                delete vm.estabelecimento.convenioPoderPublicoEstabelecimento.formaContratacao;
                delete vm.estabelecimento.estabelecimentoTipoMatriculaConvenios;
            }

            var promisse = EstabelecimentoService.save(vm.estabelecimento).then(function (data) {
                if (angular.isFunction(fnCad)) {
                    fnCad(data);
                }

                $rootScope.$broadcast('refreshEstabelecimentos', {
                    data: data,
                    add: !id,
                    edit: Boolean(id)
                });

                onSuccess(continuar, tracker);
            });

            tracker.addPromise(promisse);
            return promisse;
        }

        function _remove() {
            var promisse = EstabelecimentoService.remove(vm.estabelecimento).then(function () {
                $rootScope.$broadcast('refreshEstabelecimentos', {
                    data: vm.estabelecimento,
                    remove: true
                });

                onSuccess();
            });

            vm.tracker.addPromise(promisse);
            return promisse;
        }

        function _removeAvaliacao(data) {
            $q.all([
                $rootScope.$broadcast('refresh::AvaliacaoExterna', {
                    id: data.avaliacaoExterna.id,
                    data: data,
                    remove: true
                })
            ]);
        }

        function _removeEquipeDiretiva(data) {
            $q.all([
                $rootScope.$broadcast('refresh::EquipeDiretiva', {
                    id: data.funcionario.id,
                    data: data,
                    remove: true
                })
            ]);
        }

        function _removeDocumento(data, index) {
            $q.all([
                $rootScope.$broadcast('refresh::Documento', {
                    id: index,
                    data: data,
                    remove: true
                })
            ]);
        }

        function _removeDependenciaFisica(data, idDependencia) {
            $q.all([
                $rootScope.$broadcast('refresh::DependenciaFisica', {
                    data: data,
                    remove: true,
                    id: idDependencia
                })
            ]);
        }

        function changeEducacaoIndigena() {
            vm.isBloqueiaEnsinoMinistrado = vm.estabelecimento.educacaoIndigina;
        }

        function _cancel() {
            return $modalInstance.close();
        }

        $scope.$on('refresh::DependenciaFisica', function (event, args) {
            if (args.data) {
                if (args.remove) {
                    var filtroDependencias = function (dependencia, index) {
                        if (index !== args.id) {
                            return dependencia;
                        }
                    };

                    vm.estabelecimento.dependenciasFisicas = vm.estabelecimento.dependenciasFisicas.filter(filtroDependencias);
                } else if (args.add) {
                    vm.estabelecimento.dependenciasFisicas.push(args.data);
                } else if (args.undo || args.edit) {
                    var estabelecimento = _.find(vm.estabelecimento.dependenciasFisicas, {
                        'id': args.data.id
                    });
                    estabelecimento = !_.isNumber(args.id) ? null : vm.estabelecimento.dependenciasFisicas[args.id];
                    if (estabelecimento) {
                        ObjectUtilsService.copyProperties(estabelecimento, args.data);
                    } else {
                        throw new Error('Dependência não encontrada! ID: ' + args.data.id);
                    }
                }
            }
        });
        $scope.$on('refresh::AvaliacaoExterna', function (event, args) {
            if (args.data) {
                delete args.data.etapaEnsinoAvaliada;
                if (args.remove) {
                    vm.estabelecimento.avaliacoes = vm.estabelecimento.avaliacoes.filter(function (avaliacao) {
                        if (avaliacao.ano !== args.data.ano || avaliacao.avaliacaoExterna.id !== args.data.avaliacaoExterna.id) {
                            return avaliacao;
                        }
                    });
                } else if (args.add) {
                    if (args.data.avaliacaoExterna.etapaEnsinoAvaliada) {
                        args.data.etapaAvaliada = args.data.avaliacaoExterna.etapaEnsinoAvaliada;
                    }
                    vm.estabelecimento.avaliacoes.push(args.data);
                } else if (args.undo || args.edit) {
                    if (vm.avaliacaoEdicao) {
                        if (args.edit) {
                            if (args.data.avaliacaoExterna.etapaEnsinoAvaliada) {
                                args.data.etapaAvaliada = args.data.avaliacaoExterna.etapaEnsinoAvaliada;
                            }
                        }
                        ObjectUtilsService.copyProperties(vm.avaliacaoEdicao, args.data);
                    } else {
                        throw new Error('Avaliação não encontrada! ID: ' + args.data.id);
                    }
                }
            }
        });

        $scope.$on('refresh::EquipeDiretiva', function (event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.estabelecimento.equipesDiretivas = vm.estabelecimento.equipesDiretivas.filter(function (equipeDiretiva) {
                        if (equipeDiretiva.funcionario.id !== args.id) {
                            return equipeDiretiva;
                        }
                    });
                } else if (args.add) {
                    var cargoComissionado = (args.data.cargoComissionado === null) ? '' : args.data.cargoComissionado.descricao;
                    var jaExiste = false;
                    vm.estabelecimento.equipesDiretivas.filter(function (equipeDiretiva) {
                        if (equipeDiretiva.funcionario.id === args.data.id) {
                            jaExiste = true;
                        }
                    });
                    if (!jaExiste) {
                        vm.estabelecimento.equipesDiretivas.push({
                            'funcionario': {
                                'id': args.data.id,
                                'nome': args.data.servidor.pessoa.nome,
                                'cargo': args.data.cargo.descricao,
                                'cargoComissionado': cargoComissionado,
                                'dataAdmissao': args.data.dataAdmissao,
                                'dataDemissao': args.data.dataDemissao
                            }
                        });
                    } else {
                        bfcNotification.publish(
                            'Funcionário já inserido.',
                            'error');
                    }
                }
            }
        });

        $scope.$on('refresh::Documento', function (event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.estabelecimento.documentos = vm.estabelecimento.documentos.filter(function (documento, index) {
                        if (index !== args.id) {
                            return documento;
                        }
                    });

                } else if (args.add) {
                    addDocumento(angular.copy(args));
                } else if (args.edit) {
                    vm.estabelecimento.documentos[args.id] = angular.copy(args.data);
                }
            }
        });

        function addDocumento(args) {
            vm.estabelecimento.documentos.push(args.data);
        }

        function filterResultConvenioPoderPublico(term, value) {
            if (value.description.toUpperCase().indexOf(term.toUpperCase().trim()) === -1) {
                return;
            }

            var conveniosPoderPublico = _.map(vm.estabelecimento.convenioPoderPublicoEstabelecimento, 'convenioPoderPublico');
            if (_.includes(conveniosPoderPublico, value.key) || (conveniosPoderPublico.length === vm.QTD_MAXIMA_CONVENIOS_PODER_PUBLICO &&
                value.key === vm.enumConvenioPoderPublico.NAO_POSSUI_CONVENIO.key)) {
                return;
            }

            return value;
        }

        function filterResultPadrao(term, value, lista, onlyValue) {
            if (value.description.toUpperCase().indexOf(term.toUpperCase().trim()) === -1) {
                return;
            }
            if (!_.get(lista, 'length')) {
                return value;
            }
            var retiraOnlyValue = lista.filter(function (item) {
                return item !== onlyValue;
            });

            if (retiraOnlyValue.length && value.key === onlyValue) {
                return;
            }

            var itens = lista.filter(function (item) {
                return item === value.key || item === onlyValue;
            });
            if (!itens.length) {
                return value;
            }
        }

        //Filtro para não mostrar em tela o item Recicla para o campo Destinação do lixo
        function filterDestinacaoLixo(value) {
            return value.id !== 'RECICLA';
        }

        vm.sortBy = {
            expression: ['-funcionario.dataDemissao', 'funcionario.cargo'],
            reverse: false
        };

        function abrirPopupCadastroVinculo() {
            ModalCad.open({
                templateUrl: 'planejamento/escolas/equipe-diretiva/escola-equipe.cad.html',
                controller: 'educacao.pessoas.EstabelecimentoEquipeCadCtrl as vm',
                id: id
            });
        }

        function changeShowDetalheEquipeDiretiva(equipeDiretiva) {
            equipeDiretiva.$$open = !equipeDiretiva.$$open;
        }

        function showTab(tab) {
            if (tab.content === 'escolaPrivada' && _.get(vm.estabelecimento, 'dependenciaAdministrativa') !== 'PRIVADA') {
                return false;
            }

            return true;
        }

        function watchDependenciaAdministrativa(newValue, oldValue) {
            if (!angular.equals(newValue, oldValue) && newValue !== 'PRIVADA') {
                vm.estabelecimento.mantenedorasEscolaPrivada = null;
                vm.estabelecimento.categoriaEscolaPrivada = null;
                vm.estabelecimento.cnpjMantenedoraPrincipal = null;
            }
        }

        function isConvenioPreenchido(conveniosPoderPublico) {
            if (_.includes(conveniosPoderPublico, vm.enumConvenioPoderPublico.NAO_POSSUI_CONVENIO.key)) {
                return false;
            }
            return _.includes(conveniosPoderPublico, vm.enumConvenioPoderPublico.ESTADUAL.key) ||
                _.includes(conveniosPoderPublico, vm.enumConvenioPoderPublico.MUNICIPAL.key);
        }

        function addTipoMatriculaConvenio(tiposMatriculaConvenios) {
            tiposMatriculaConvenios.push({});
        }

        function delTipoMatriculaConvenio(tiposMatriculaConvenios, index) {
            tiposMatriculaConvenios.splice(index, 1);
        }

        function addConvenioPoderPublicoEstabelecimento(conveniosPoderPublicoEstabelecimento) {
            conveniosPoderPublicoEstabelecimento.push({});
        }

        function delConvenioPoderPublicoEstabelecimento(conveniosPoderPublicoEstabelecimento, index) {
            conveniosPoderPublicoEstabelecimento.splice(index, 1);
        }

        function searchTiposMatriculasConvenios(params) {
            var idsTipoMatriculaConvenio = [];
            _.forEach(vm.estabelecimento.estabelecimentoTipoMatriculaConvenios, function (estabTipoMatricula) {
                if (_.get(estabTipoMatricula, 'tipoMatriculaConvenio.id')) {
                    idsTipoMatriculaConvenio.push(estabTipoMatricula.tipoMatriculaConvenio.id);
                }
            });
            params.filter = 'tipo elike "' + params.term + '"';
            if (!_.isEmpty(idsTipoMatriculaConvenio)) {
                params.filter += ' and id not in (' + idsTipoMatriculaConvenio.join(',') + ')';
            }
            return EstabelecimentoService
                .getTiposMatriculasConvenios(params.offset, params.limit, params.filter, params.sort);
        }

        function formatResultTipoMatriculaConvenio(data) {
            return data.tipo;
        }

        function hasConvenioEstabelecimento() {
            if (!vm.estabelecimento.convenioPoderPublicoEstabelecimento) {
                return;
            }

            return _.some(vm.estabelecimento.convenioPoderPublicoEstabelecimento, function (item) {
                return item.convenioPoderPublico !== vm.enumConvenioPoderPublico.NAO_POSSUI_CONVENIO.key &&
                    item.convenioPoderPublico && item.convenioPoderPublico.length;
            });
        }

        $scope.$watch('vm.estabelecimento.convenioPoderPublicoEstabelecimento', convenioPoderPublicoChanged, true);

        function convenioPoderPublicoChanged(convenioPoderPublicoEstabelecimento) {
            if (!convenioPoderPublicoEstabelecimento) {
                return;
            }

            convenioPoderPublicoEstabelecimento.forEach(function (item) {
                if (item.convenioPoderPublico === vm.enumConvenioPoderPublico.NAO_POSSUI_CONVENIO.key || !item.convenioPoderPublico) {
                    item.formaContratacao = [];
                }
            });

        }

        function verificaQtdTipoMatriculaConvenioUp(estabelecimentoTipoMatriculaConvenios) {
            if (estabelecimentoTipoMatriculaConvenios.quantidade < 9999) {
                estabelecimentoTipoMatriculaConvenios.quantidade++;
                return;
            }

            estabelecimentoTipoMatriculaConvenios.quantidade = estabelecimentoTipoMatriculaConvenios.quantidade || 1;
        }

        function verificaQtdTipoMatriculaConvenioDown(estabelecimentoTipoMatriculaConvenios) {
            if (estabelecimentoTipoMatriculaConvenios.quantidade > 1) {
                estabelecimentoTipoMatriculaConvenios.quantidade--;
                return;
            }

            estabelecimentoTipoMatriculaConvenios.quantidade = estabelecimentoTipoMatriculaConvenios.quantidade || 1;
        }

    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
        .service('educacao.ocupacao-aulas-quadro-informativo.AccordionVisaoGeralUtilsService', AccordionVisaoGeralUtilsService);

    function AccordionVisaoGeralUtilsService() {
        var vm = this;
        vm.sumSaldoByTipoSaldo = sumSaldoByTipoSaldo;

        function sumSaldoByTipoSaldo(saldos, item) {
            saldos.saldoInicial += _.sum(item, 'saldoInicial');
            saldos.saldoOcupado += _.sum(item, 'saldoOcupado');
            saldos.saldoAtual += _.sum(item, 'saldoAtual');
        }
    }
})();


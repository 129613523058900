(function () {
    'use strict';

    angular.module('educacao.agendaprofessor')
        .directive('disponibilidadeAgenda', DisponibilidadeDirective);

    function DisponibilidadeDirective() {
        return {
            restrict: 'E',
            scope: {
                minDate: '=',
                maxDate: '=',

                horarios: '=',
                view: '='
            },

            templateUrl: 'agenda-professor/disponibilidade/disponibilidade-agenda.directive.html',

            controller: DisponibilidadeAgendaController,
            controllerAs: 'vm'
        };
    }

    DisponibilidadeAgendaController.$inject = [
        '$scope',
        '$compile',
        'educacao.agendaprofessor.DisponibilidadeConfig',
        'DISPONIBILIDADE_POPOVER_TEMPLATE',
        'LIST_PROFESSOR_COLORS'
    ];

    function DisponibilidadeAgendaController($scope, $compile, disponibilidadeConfig, DISPONIBILIDADE_POPOVER_TEMPLATE, LIST_PROFESSOR_COLORS) {
        var vm = this;

        vm.config = $scope.config || {};

        vm.agendaConfig = {
            eventRender: eventRender,
            eventAfterRender: eventAfterRender,
            eventDrop: onEventUpdated,
            eventResize: onEventUpdated,
            dayClick: onDayClicked,
            allDaySlot: false,
            scrollTime: '06:00:00',
            views: {
                agendaWeek: {
                    columnFormat: 'ddd',
                    slotLabelFormat: 'HH:mm'

                }
            }
        };

        $scope.$watch('horarios', updateCalendarAvailability, true);

        function getEventClass(color) {
            return disponibilidadeConfig.getTextColorClass(color, 10) + ' ' + disponibilidadeConfig.getColorClass(color);
        }

        function updateCalendarAvailability(horarios) {
            if (horarios) {
                vm.horarios = horarios.map(convertHorarioToFullCalendar);
            }
        }

        function convertHorarioToFullCalendar(horario) {
            return {
                start: getDataHora(horario.horaInicio,horario.diaSemana),
                end: getDataHora(horario.horaFim,horario.diaSemana),
                allDay: false,
                editable: false,
                originalHorario: horario,
                className: getEventClass(getColor(horario.indice)),
                gotoDate: moment(),

                columnFormat: {
                    month: 'ddd',
                    week: 'ddd',
                    day: 'dddd d/M'
                }

            };
        }


        function getDataHora(horario, diaSemana) {

            var dataCalendar = moment();
            var hora = horario.split(':')[0];
            var minuto = horario.split(':')[1];

            dataCalendar.day(disponibilidadeConfig.getDayWeekNumber(diaSemana));
            dataCalendar.set('hour', hora);
            dataCalendar.set('minute', minuto);

            return moment(dataCalendar, disponibilidadeConfig.dateTimeFormat).startOf('seconds');

        }

        function onEventUpdated(event, delta, rollbackFunction) {
            if (event.start.hasTime()) {
                rollbackFunction();
                return;
            }

            var originalEvent = angular.copy(event.originalEvent);

            originalEvent.dataInicial = event.start ? event.start.format(disponibilidadeConfig.dateTimeFormat) : null;
            originalEvent.dataFinal = getDataFim(event.end) || originalEvent.dataInicial;

            originalEvent.diaInteiro = event.allDay;

            $scope.$apply(function () {
                $scope.$emit('agenda.event.changed', originalEvent, function () {
                    updateCalendarAvailability($scope.horarios);
                });
            });
        }

        function getDataFim(dataFinal) {
            if (!dataFinal) {
                return null;
            }
            dataFinal = dataFinal.clone();
            dataFinal.subtract(1, 'days');
            return dataFinal.format(disponibilidadeConfig.dateTimeFormat);
        }

        function onDayClicked(date) {

            if (date.hasTime()) {
                return;
            }
            var scaffold = {
                dataInicial: date.format(disponibilidadeConfig.dateTimeFormat)
            };
            $scope.$emit('event.create', scaffold);
        }

        function eventRender(event, element) {

            element
                .attr('data-bf-popover', DISPONIBILIDADE_POPOVER_TEMPLATE);

            var scopeToDestroy = element.data('scopeToDestroy');
            if (scopeToDestroy) {
                scopeToDestroy.$destroy();
            }

            var horarioScope = $scope.$new();
            horarioScope.horario = event.originalHorario;

            $compile(element)(horarioScope);

            element.data('scopeToDestroy', horarioScope);

        }

        function eventAfterRender(event, element) {
            element.removeClass('fc-short');
        }

        function getColor(indice) {
            return LIST_PROFESSOR_COLORS[indice > 9 ? indice % 10 : indice];
        }

    }
})();

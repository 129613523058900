(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaPublica', appConfiguracaoListaEsperaPublica);

    function appConfiguracaoListaEsperaPublica() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-publica/app-configuracao-lista-espera-publica.directive.html',
            scope: {},
            bindToController: {
                configuracao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [
        'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService'
    ];

    function Controller(ConfigListaEsperaService) {
        var vm = this;
        vm.configurado = !!vm.configuracao.configuracaoListaEsperaPublica;

        vm.options = { camposInscricao: undefined };
        vm.configurar = configurar;
        vm.excluir = excluir;
        vm.nomeCompleto = nomeCompleto;
        vm.hasCampoNome = hasCampoNome;

        init();

        function init() {
            setCamposFixos();
            ConfigListaEsperaService.getCamposFormulario().then(function (data) {
                var filtered = data.filter(function (elemet){ 
                    if (isContemConfigCampos(elemet)) {
                        return elemet;
                    }
                });
                vm.options.camposInscricao = filtered;
            });
        }

        function isContemConfigCampos(element){
            return vm.configuracao.campos.filter(function(campo) {
                if(element.campo === campo.campo){
                    return true;
                }
            }).length;
        }

        function nomeCompleto() {
            vm.configuracao.configuracaoListaEsperaPublica.exibeIniciaisNome = false;
            vm.configuracao.configuracaoListaEsperaPublica.exibirApenasPrimeiroNome = false;
        }

        function setCamposFixos() {
            vm.camposFixos = [
                {
                    'id': null,
                    'campo': 'DATA_NASC',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                },
                {
                    'id': null,
                    'campo': 'NOME',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                },
                {
                    'id': null,
                    'campo': 'SEXO',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                },
                {
                    'id': null,
                    'campo': 'FILIACAO',
                    'obrigatorio': true,
                    'grupo': null,
                    'campoMestre': null
                }
            ];
        }

        function geraUrlListaPublica() {
            ConfigListaEsperaService.getUrlInscricao()
                .then(function (dado) {
                    vm.configuracao.configuracaoListaEsperaPublica.urlListaPublica = dado;
                    var identicficador = Base64.encode(new Date().toISOString());
                    var path = '/home/consulta-publica/' + identicficador;
                    vm.configuracao.configuracaoListaEsperaPublica.identificador = identicficador;
                    vm.configuracao.configuracaoListaEsperaPublica.urlListaPublica += path;
                });
        }

        function configurar() {
            vm.configuracao.configuracaoListaEsperaPublica = {};
            vm.configurado = true;
            geraUrlListaPublica();
        }

        function excluir() {
            vm.configuracao.configuracaoListaEsperaPublica = null;
            vm.configurado = false;
        }

        function hasCampoNome() {
            return Boolean(_.find(vm.configuracao.campos, function(campo) {
                return (campo.campo === 'NOME' || campo.campo === 'FILIACAO') && campo.publico;
            }));
        }
    }
})();

(function () {

	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.CadastroTurmaController', Controller);

	Controller.$inject = [
		'$scope',
		'promiseTracker',
		'educacao.context.ContextoEstabelecimento',
		'educacao.matricula.ConfiguracaoTurmasService',
		'educacao.matricula.HorasAulaService',
		'educacao.matricula.TurmaService',
		'educacao.matriz.MatrizService',
		'educacao.matriz.EtapaService',
		'educacao.common.EtapaInepService',
		'educacao.matricula.QuadroVagasService',
		'educacao.common.ItemEducacionalService',
		'ITEM_AVALIAVEL_TIPO_PERIODO',
		'ITEM_AVALIAVEL_TIPO',
		'$modalInstance',
		'$q',
		'educacao.matriz.HorasAulasSelect',
		'educacao.common.GrupoEnturmacaoService',
        'bfc.$$PermissionsService',
		'educacao.common.$commons',
		'UserAccessContext',
        'bfcApplicationContext',
		'AuthenticationContext',
		'$window'
	];

	function Controller($scope,
		promiseTracker,
		ContextoEstabelecimento,
		ConfiguracaoTurmasService,
		HorasAulaService,
		TurmaService,
		MatrizService,
		EtapaMatrizService,
		EtapaInepService,
		QuadroVagasService,
		ItemEducacionalService,
		ITEM_AVALIAVEL_TIPO_PERIODO,
		ITEM_AVALIAVEL_TIPO,
		$modalInstance,
		$q,
		HorasAulasSelect,
		GrupoEnturmacaoService,
		PermissionsService,
		$commons,
		UserAccessContext,
		ApplicationContext,
		AuthenticationContext,
		$window) {
		var naoParticipantesMaisEducacao = ['CLASSE_HOSPITALAR', 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO',
			'ATENDIMENTO_PROGRESSAO_PARCIAL'];
		var tipoAtendimentoAtividades = ['ATIVIDADE_COMPLEMENTAR', 'ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO'];

		// var templateBuscaEstabelecimento = _.template('nome like "<%= term %>"');
		var templateBuscaEtapaEnsinoInep = _.template('nome like "<%= term %>"');
		var templateBuscaMatrizCurricular = _.template('descricao like "<%= term %>"');

		var configuracaoTurma;

		var vm = this;
		vm.horasAulas = HorasAulasSelect.select2Config();
		vm.showAlertAlterQtdExame = false;

		vm.url = 'user_access=' + UserAccessContext.getUserAccess().licenseId +
            '&app_context=' + toBase64(ApplicationContext.getList()) +
            '&access_token=' + AuthenticationContext.getAccessToken();

		vm.permissions = {
			save: {
				bfPermissions: 'app.matricula.turma',
				operation: 'criar'
			},
			update: {
				bfPermissions: 'app.matricula.turma',
				operation: 'editar'
			},
			remove: {
				bfPermissions: 'app.matricula.turma',
				operation: 'excluir'
			}
		};

		vm.loadingTracker = promiseTracker();
		vm.configuracaoesTracker = promiseTracker();
		vm.hasContextoEstabelecimento = !ContextoEstabelecimento.isSecretaria();

		vm.tracker = {
			loading: promiseTracker(),
			camposAdicionais: promiseTracker()
		};

		vm.turma = $modalInstance.params.turma;
		if (!vm.turma) {
			novaTurma();
		}else{
			getConfiguracao().then(function(){
				if (vm.turma.vagaEtapa){
					getQuantidadeMaximaAlunosTurma(vm.turma.vagaEtapa.id);
				}
			});
		}
		vm.qtdExamesSalvo = vm.turma.quantidadeExamesFinais;
		initCamposAdicionais();

		vm.listTipoAtendimento = TurmaService.getTipoAtendimento();
		vm.listTurno = TurmaService.getTurno();
		vm.listTipoMediacaoDidaticoPedagogica = TurmaService.getTipoMediacaoDidaticoPedagogica();

		vm.isParticipaMaisEducacao = isParticipaMaisEducacao;
		vm.isTipoAtendimentoPadrao = isTipoAtendimentoPadrao;
		vm.isTipoAtendimentoAtividade = isTipoAtendimentoAtividade;
		vm.isQuantidadeAlunosPorItem = isQuantidadeAlunosPorItem;

		vm.isDadosMatrizPreenchidos = isDadosMatrizPreenchidos;
		vm.isMatrizPadrao = isMatrizPadrao;
		vm.isMatrizEjaModular = isMatrizEjaModular;
		vm.isPossuiVariosCalendarios = isPossuiVariosCalendarios;

		vm.listEtapaEnsinoInep = listEtapaEnsinoInep;
		vm.getNomeEtapaEnsinoInep = getNomeEtapaEnsinoInep;

		vm.listMatrizCurricular = listMatrizCurricular;

		vm.listEtapaMatriz = listEtapaMatriz;

		vm.possibilitaAreaConhecimento = possibilitaAreaConhecimento;

		vm.salvar = salvar;
		vm.salvarAdicionarNovo = salvarAdicionarNovo;
		vm.excluir = excluir;
		vm.getSizeFieldMatrizCurricular = getSizeFieldMatrizCurricular;

		$modalInstance.tracker.addPromise(loadConfiguracaoTurma());

		$scope.$watchGroup(['vm.turma.turno'], setConfiguracao);
		$scope.$watch(isTipoAtendimentoPadrao, limpaMatriz);
		$scope.$watchGroup(['vm.turma.tipoAtendimento', 'vm.turma.etapaMatriz'], limpaFilhos);

		$scope.$watchGroup(['vm.turma.tipoAtendimento', 'vm.turma.tipoMediacaoDidaticoPedagogica'], onTipoAtendimentoChanged);

		$scope.$watchGroup(['vm.turma.estabelecimento', 'vm.turma.matriz'], limpaCalendario);
		$scope.$watch('vm.turma.matriz', removeEtapaMatriz);
		$scope.$watch('vm.turma.etapaMatriz', preencheEtapaInep);
		$scope.$watchGroup(['vm.turma.estabelecimento', 'vm.turma.tipoAtendimento', 'vm.turma.turno', 'vm.turma.etapaMatriz'],
			buscaVagaEtapa);

		$scope.$watch('vm.turma.estabelecimento', loadConfiguracaoEstabelecimeto);
		$scope.$watch('vm.turma.filhos', verificaMatriz);

		$scope.$watch('vm.turma.quantidadeExamesFinais', function (newValue, oldValue) {
			checkQtdExamesFinais();
			if (angular.equals(newValue, oldValue)) {
				return;
			}

			(vm.turma.filhos || []).forEach(function (filho) {
				if (!newValue) {
					_.remove(filho.itensAvaliaveis, {
						tipo: ITEM_AVALIAVEL_TIPO.CHA,
						tipoPeriodo: ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL
					});
				}
			});
		});

        function toBase64(context) {
            return $window.btoa(JSON.stringify(context));
        }

		function checkQtdExamesFinais(){
			if (!vm.turma.id) {
				return;
			}

			vm.showAlertAlterQtdExame = vm.turma.quantidadeExamesFinais !== vm.qtdExamesSalvo;
		}

		function loadConfiguracaoEstabelecimeto(newValue, oldValue) {
			if (angular.equals(newValue, oldValue)) {
				return;
			}
			if (!_.get(vm.turma.estabelecimento, 'id')) {
				return;
			}
			if (!vm.hasContextoEstabelecimento) {
				return getConfiguracao();
			}
		}

		function getConfiguracao() {
			return ConfiguracaoTurmasService
				.getConfiguracaoEstabelecimento(vm.turma.estabelecimento.id)
				.then(function (configuracao) {
					configuracaoTurma = configuracao;
					vm.configuracaoTurma = configuracaoTurma;
				}).then(loadGrupos);
		}

		function loadConfiguracaoTurma() {
			if (vm.hasContextoEstabelecimento) {
				return ConfiguracaoTurmasService
					.getConfiguracaoValida()
					.then(function (configuracao) {
						configuracaoTurma = configuracao;
						vm.configuracaoTurma = configuracaoTurma;
					}).then(loadGrupos);
			}
			return $q.when();
		}

		function loadGrupos() {
			return GrupoEnturmacaoService
				.getGruposEstabelecimento(vm.turma.estabelecimento.id)
				.then(function(grupos) {
					vm.gruposEstabelecimento = grupos;
					vm.turma.separacaoAlunosEmGrupos = Boolean(vm.turma.separacaoAlunosEmGrupos && 
						_.get(grupos, 'grupoEnturmacaoItem.length'));
				});
		}

		function novaTurma() {
			vm.turma = {
				tipo: 'TURMA'
			};
			if (vm.hasContextoEstabelecimento) {
				vm.turma.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
			}
		}

		function toPage(list) {
			return {
				content: list,
				hasMore: false
			};
		}

		function plain(list) {
			return list.map(function (item) {
				return item.plain();
			});
		}

		function isParticipaMaisEducacao() {
			return vm.turma.tipoMediacaoDidaticoPedagogica === 'PRESENCIAL' &&
				_.get(vm.turma, 'matriz.curso.nivelModalidade') !== 'EJA' &&
				(vm.turma.tipoAtendimento && naoParticipantesMaisEducacao.indexOf(vm.turma.tipoAtendimento) === -1);
		}

		function isTipoAtendimentoPadrao() {
			return !isTipoAtendimentoAtividade();
		}

		function isTipoAtendimentoAtividade() {
			return tipoAtendimentoAtividades.indexOf(vm.turma.tipoAtendimento) !== -1;
		}

		function isQuantidadeAlunosPorItem() {
			return isTipoAtendimentoAtividade() || isMatrizEjaModular();
		}

		function isDadosMatrizPreenchidos() {
			return vm.turma.matriz && vm.turma.etapaMatriz;
		}

		function isMatrizPadrao() {
			return !_.get(vm.turma, 'matriz.modular');
		}

		function isMatrizEjaModular() {
			return _.get(vm.turma, 'matriz.modular');
		}

		function isPossuiVariosCalendarios() {
			return _.get(vm.turma, 'matriz.curso.nivelModalidade') !== 'EDUCACAO_BASICA';
		}

		function listEtapaEnsinoInep(params) {
			return EtapaInepService
				.getList(params.offset, params.limit, templateBuscaEtapaEnsinoInep(params))
				.then(toPage);
		}

		function getNomeEtapaEnsinoInep(etapa) {
			return etapa.codigoInep + ' - ' + etapa.nome;
		}

		function listMatrizCurricular(params) {
			return MatrizService
				.getList(params.offset, params.limit, templateBuscaMatrizCurricular(params))
				.then(plain)
				.then(toPage);
		}

		function listEtapaMatriz() {
			return EtapaMatrizService
				.getList(vm.turma.matriz.id)
				.then(toPage);
		}

		function setConfiguracao(novo, velho) {
			limpaMatriz(novo, velho);
			if (isTipoAtendimentoAtividade() || _.isEqual(novo, velho) || !configuracaoTurma) {
				return;
			}
			var idConfiguracaoHorasAula = _.get(_.find(configuracaoTurma.configuracaoHorarios, 'turno', vm.turma.turno),
				'configuracaoHorasAulas.id');

			if (!idConfiguracaoHorasAula) {
				vm.turma.configuracaoHorasAulas = null;
				return;
			}

			vm.configuracaoesTracker.addPromise(HorasAulaService.get(idConfiguracaoHorasAula).then(function (data) {
				vm.turma.configuracaoHorasAulas = data;
			}));
		}

		function limpaMatriz(newValue, oldValue) {
			if (!_.isEqual(newValue, oldValue)) {
				delete vm.turma.matriz;
				delete vm.turma.etapaMatriz;
				delete vm.turma.etapaEnsinoInep;
				delete vm.turma.vagaEtapa;
			}
		}

		function limpaFilhos(newValue, oldValue) {
			if (!_.isEqual(newValue, oldValue)) {
				var novoTipoAtendimento = _.get(newValue, 0);
				var antigoTipoAtendimento = _.get(oldValue, 0);

				//É permitido a troca de tipo de atendimento "Não se aplica" para "Escolarização" e vice-versa sem alteração de dados vinculados
				if ((novoTipoAtendimento === 'NAO_SE_APLICA' && antigoTipoAtendimento === 'ESCOLARIZACAO') ||
				(novoTipoAtendimento === 'ESCOLARIZACAO' && antigoTipoAtendimento === 'NAO_SE_APLICA')){
					return;
				}
				
				vm.mostraNaoHaVagas = false;
				vm.turma.filhos = [];

				delete vm.turma.vagaEtapa;
			}
		}

		function onTipoAtendimentoChanged() {
			if (!isParticipaMaisEducacao()) {
				delete vm.turma.participaMaisEducacao;
			}
		}

		function limpaCalendario(newValue, oldValue) {
			if (!_.isEqual(newValue, oldValue)) {
				vm.turma.calendarioMatrizCurricular = null;
			}
		}

		function removeEtapaMatriz(newValue, oldValue) {
			if (newValue !== oldValue) {
				delete vm.turma.etapaMatriz;
				delete vm.turma.etapaEnsinoInep;

				vm.turma.tipoResultado = 'COMPONENTE_CURRICULAR';
			}
		}

		function verificaMatriz() {
			if (isTipoAtendimentoAtividade()) {
				vm.disableAreaConhecimento = true;
				vm.turma.tipoResultado = 'COMPONENTE_CURRICULAR';
				return;
			}

			var filhos = _.chain(vm.turma.filhos)
							.filter(filterFilhos)
							.value();

			vm.disableAreaConhecimento = !!_.find(filhos, function (filho) {
				return vm.turma.tipoResultado === 'AREA_CONHECIMENTO' ? !filho.eixoTematico : !filho.etapaMatrizDisciplina.eixoTematico;
			});

			if (vm.disableAreaConhecimento) {
				vm.turma.tipoResultado = 'COMPONENTE_CURRICULAR';
			}

			function filterFilhos(item){
				return item.tipo === 'DISCIPLINA' || item.tipo === 'AREA_CONHECIMENTO';
			}
		}

		function preencheEtapaInep(etapaMatriz, oldValue) {
			if (etapaMatriz && oldValue !== etapaMatriz) {
				vm.turma.etapaEnsinoInep = etapaMatriz.etapaInep;
			}
		}

		function buscaVagaEtapa(newValues, oldValues) {
			if (_.isEqual(newValues, oldValues) || isTipoAtendimentoAtividade()) {
				return;
			}

			var idEstabelecimento = _.get(vm.turma, 'estabelecimento.id');
			var idEtapaMatriz = _.get(vm.turma, 'etapaMatriz.id');
			var turno = vm.turma.turno;

			vm.mostraNaoHaVagas = false;
			if (vm.impedirAlterarQtdMaxima){
				vm.turma.quantidadeMaximaAlunos = null;
			}
			if (idEstabelecimento && idEtapaMatriz && turno) {
				if (isMatrizEjaModular()) {
					vm.turma.vagaEtapa = null;
				} else {
					QuadroVagasService
						.getByEstabelecimentoEtapaMatrizTurno(idEstabelecimento, idEtapaMatriz, turno)
						.then(function (data) {
							vm.turma.vagaEtapa = data;
							vm.mostraNaoHaVagas = !data;
							if (vm.turma.vagaEtapa){
								getQuantidadeMaximaAlunosTurma(vm.turma.vagaEtapa.id);
							}
							
						});
				}
			}

		}

		function possibilitaAreaConhecimento(){
			return !vm.isMatrizEjaModular() && vm.turma.matriz && vm.turma.matriz.tipoOrganizacao !== 'EIXO_TEMATICO' && 
				!vm.isTipoAtendimentoAtividade();
		}

		function excluir() {
            var isRevokedExcluirTurma = PermissionsService.isRevokedOperation('TurmaPageMapping', 'excluir');
            if (isRevokedExcluirTurma) {
                return;
            }

			var hasPermissionToRemoveCascade = !PermissionsService.isRevokedOperation('TurmaPageMapping', 'excluirVinculos');
            if (hasPermissionToRemoveCascade) {
                return openForceRemoveTurma().then($modalInstance.close);
            }
			return TurmaService.remove(vm.turma)
				.then($modalInstance.close);
		}


		function openForceRemoveTurma() {
            return $commons.$modal.open({
                templateUrl: 'matricula/turma/remove-turma-cascade/remove-turma-cascade.html',
                controller: 'educacao.matricula.RemoveTurmaCascadeController as vm',
                size: 'sm',
                params: {
                    turma: vm.turma
                }
            }).result;
        }

		function salvar() {
			vm.turma.campoAdicional = vm.campoAdicional;
			if (isMatrizEjaModular() || isTipoAtendimentoAtividade() ||
				!_.get(vm.gruposEstabelecimento, 'grupoEnturmacaoItem.length')) {
				vm.turma.separacaoAlunosEmGrupos = false;
			}
			return TurmaService.save(vm.turma).then($modalInstance.close);
		}

		function salvarAdicionarNovo(form) {
			vm.turma.campoAdicional = vm.campoAdicional;
			return TurmaService.save(vm.turma)
				.then(novaTurma)
				.then(function () {
					form.$setPristine();
					form.$setUntouched();
					});
		}

		function initCamposAdicionais() {
			var TurmaId = _.get(vm.turma, 'id');
			var promise = TurmaService.camposAdicionais(TurmaId)
				.then(function (data) {
					vm.campoAdicional = data;
				});
			vm.tracker.loading.addPromise(promise);
			
			vm.tracker.camposAdicionais.addPromise(promise);

		}

		function getQuantidadeMaximaAlunosTurma(idVagaEtapa){
			if(vm.configuracaoTurma && vm.configuracaoTurma.impedeAlterarQuantidadeMaximaAlunos){
				TurmaService.getQuantidadeMaximaAlunosVagaEtapa(idVagaEtapa).then(function(data){
					vm.turma.quantidadeMaximaAlunos = data || vm.turma.quantidadeMaximaAlunos;
					vm.impedirAlterarQtdMaxima = !!data;
				});
			}
		}

		function getSizeFieldMatrizCurricular() {
			if (vm.isMatrizEjaModular()) {
				return 'col-md-10';
			}
			if (_.get(vm.turma, 'matriz') && vm.isPossuiVariosCalendarios()) {
				return 'col-md-4';
			}
			if (vm.isQuantidadeAlunosPorItem()) {
				return 'col-md-6';
			}
			return 'col-md-8';
		}
	}
})();

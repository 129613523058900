(function() {

	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.ConfirmacaoConfiguracaoFrequenciaController', Controller);

	Controller.$inject = ['$modalInstance'];

	function Controller($modalInstance) {
		var vm = this;

		vm.result = result;

		function result(value) {
			$modalInstance.close(value);
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.configuracaoFrequencia')
        .config(ConfiguracaoFrequenciaStates);

    ConfiguracaoFrequenciaStates.$inject = ['$stateProvider'];

    function ConfiguracaoFrequenciaStates($stateProvider) {
        $stateProvider.state('app.configuracaofrequencia', {
            url: 'configuracao-frequencia',
            data: {
                id: 'ConfiguracaoFrequenciaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'configuracao-frequencia/configuracao-frequencia.html',
                    controller: 'educacao.common.ConfiguracaoFrequenciaController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();


(function () {
  'use strict';

  angular.module('educacao.quadro-funcionarios-configuracao').config(stateConfig);

  stateConfig.$inject = [
    '$stateProvider'
  ];

  function stateConfig($stateProvider) {
    var STATE = 'app.planejamento.quadro-funcionarios-configuracao';

    $stateProvider.state(STATE, {
      url: 'quadro-funcionario-configuracao',
      // data: {
      //   id: 'ConfiguracaoQuadroFuncionarioPageMapping'
      // },
      views: {
        '@': {
          templateUrl: 'quadro-informativo/configuracao/listagem-configuracao-quadro-funcionario/listagem-quadro-funcionarios.html',
          controller: 'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosController as vm',
        }
      }
    });
  }
})();
(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('columnGraphEmptyState', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/column-graph-empty-state/column-graph-empty-state.directive.html',
            scope: {},
            bindToController: {
                emptyDescription: '=',
                isTemplateCard: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.description = vm.emptyDescription;
    }
})();
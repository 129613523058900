(function() {
    'use strict';

    angular.module('educacao.common')
        .directive('appSystemActions', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'common/layout/header/actions/system-actions.directive.html',
            replace: true,

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', '$injector', '$state', '$element', 'mainMenu'];

    function Controller($scope, $injector, $state, $element, mainMenu) {
        var vm = this;
        vm.menus = [];

        vm.rawSearchText = '';
        vm.searchText = '';

        vm.focusOnInput = focusOnInput;
        vm.executeAction = executeAction;

        $scope.$watch('vm.rawSearchText', normalizeSearchText);
        $element.blur(clearSearchText);
        

        addFunctions(mainMenu);
       
        

        function addFunctions(menu) {
            if (menu.hasAction()) {
                menu.$$label = _.deburr(menu.label).toLowerCase();
                vm.menus.push(menu);
            }
            _.forEach(menu.children, addFunctions);
        }

       

        function normalizeSearchText(newValue) {
            vm.searchText = _.deburr(newValue).toLowerCase();
        }

        function clearSearchText() {
            vm.rawSearchText = '';
        }

        function focusOnInput() {
            $element.find('input').focus();
        }

        function executeAction(menu) {
            if (_.isString(menu.action)) {
                $state.go(menu.action);
            } else if (_.isFunction(menu.action)) {
                $injector.invoke(menu.action);
            }

            $element.blur();
        }
    }

})();


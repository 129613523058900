(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .directive('rematricularEtapa', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'rematricula/rematricular-etapa/rematricular-etapa.directive.html',
            scope: {},
            bindToController: {
                rematricula: '=',
                permiteTrocarEstabelecimento: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'educacao.rematricula.RematriculaService', 'educacao.matricula.ConfiguracoesMatriculaService',
        'promiseTracker'];

    function Controller($scope, RematriculaService, ConfiguracoesMatriculaService, promiseTracker) {

        var vm = this;

        vm.formatResultTurma = formatResultTurma;
        vm.formatResultTurno = formatResultTurno;
        vm.onChangeAnoLetivo = onChangeAnoLetivo;
        vm.onChangeEstabelecimento = onChangeEstabelecimento;
        vm.onChangeEtapaMatriz = onChangeEtapaMatriz;
        vm.onChangeTurno = onChangeTurno;
        vm.configDateRematricula = {
            maxDate: new Date()
        };

        vm.tracker = {};
        vm.tracker.loadingConfigMultiturno = promiseTracker();

            $scope.$watch('vm.rematricula.destino.matriz', watchMatriz);

        function watchMatriz(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            showMultiturno();
            getCalendariosMatriz();

            delete vm.rematricula.destino.etapaMatriz;
        }

        function formatResultTurno(data) {
            return _.capitalize((data.turno || '').toLowerCase()) + ' ' + '(' + data.qtdVagasOcupadas + '/' + data.qtdVagas + ' vagas)';
        }

        function formatResultTurma(data) {
            return data.descricao + ' (' + (data.quantidadeAlunos + '/' + data.quantidadeMaximaAlunos) + ') ';
        }

        function onChangeAnoLetivo() {
            delete vm.rematricula.destino.matriz;
        }

        function onChangeEstabelecimento() {
            delete vm.rematricula.destino.matriz;
        }

        function onChangeEtapaMatriz() {
            delete vm.rematricula.destino.turno;
        }

        function onChangeTurno() {
            delete vm.rematricula.destino.turma;
        }

        function getCalendariosMatriz() {

            if (!vm.rematricula.destino.estabelecimento || !vm.rematricula.destino.anoLetivo || !vm.rematricula.destino.matriz) {
                return;
            }

            RematriculaService.getCalendarioMatriz(vm.rematricula.destino.estabelecimento.id, vm.rematricula.destino.anoLetivo.id,
                    vm.rematricula.destino.matriz.id)
                .then(function(data) {
                    vm.calendarios = data;
                });
        }

        function showMultiturno() {
            if (!_.get(vm.rematricula.destino, 'matriz.id')) {
                return;
            }
            var params = {
                idEstabelecimento: vm.rematricula.destino.estabelecimento.id,
                idMatriz: vm.rematricula.destino.matriz.id,
                idAnoLetivo: vm.rematricula.destino.anoLetivo.id
            };

            vm.tracker.loadingConfigMultiturno.addPromise(
                ConfiguracoesMatriculaService.permiteMultiturno(params)
                .then(permiteMultiturno)
            );

            function permiteMultiturno(data) {
                vm.permiteMultiturno = data.permite;
            }

        }

    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appAccordionTurma', appAccordionTurma);

    function appAccordionTurma() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-accordion-visao-estabelecimento/app-accordion-turma/app-accordion-turma.directive.html',
            scope: {},
            bindToController: {
                turmasEtapa: '=',
                estabelecimentoId: '=',
                estabelecimentoNome: '=',
                turmaFilters: '=',
                turmaSelecionada: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'EdEnumsCacheService',
        'promiseTracker',
        '$scope',
        '$window',
        '$state'
    ];

    function Controller(EdEnumsCacheService, promiseTracker, $scope, $window, $state) {
        var vm = this;

        vm.loading = promiseTracker();
        vm.onClickCard = onClickCard;

        vm.SCREE_WIDTH = $window.innerWidth;
        vm.MOBILE_WIDTH = 991;

        init();

        $scope.$watch('vm.turmaSelecionada', collapseTurmas);

        angular.element($window).on('resize', function () {
            vm.SCREE_WIDTH = $window.innerWidth;
        });

        function init() {
            var promise = EdEnumsCacheService.get('Turno').then(function(result) {
                vm.TURNOS = result;
                vm.turmasPorTurno = groupTurmasByTurno(vm.turmasEtapa);
            });

            vm.loading.addPromise(promise);
        }

        function groupTurmasByTurno(turmas) {
            return _.chain(turmas)
                .sortBy('turma.descricao')
                .sortBy(sortTurnos)
                .groupBy('turma.turno')
                .value();
        }

        function sortTurnos(saldoTurma) {
            return Object.keys(vm.TURNOS).indexOf(saldoTurma.turma.turno);
        }

        function onClickCard(turma) {
            vm.turmaSelecionada = null;
            turma.collapsed = !turma.collapsed;
            vm.turmaSelecionada = turma.collapsed ? turma : null;

            if (vm.SCREE_WIDTH <= vm.MOBILE_WIDTH) {
                $state.go('app.planejamento.ocupacao-aulas-saldo-disciplinas-mobile', {
                    turmaSelecionada: vm.turmaSelecionada,
                    estabelecimentoId: vm.estabelecimentoId,
                    estabelecimentoNome: vm.estabelecimentoNome
                });
            }
        }

        function collapseTurmas() {
            _.forEach(vm.turmasEtapa, function (turma) {
                turma.collapsed = !_.isNull(vm.turmaSelecionada) && turma.id === vm.turmaSelecionada.id;
            });
        }
    }
})();
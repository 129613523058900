(function() {

	'use strict';

	angular.module('educacao.matricula').controller('educacao.matricula.EnturmacaoAlunosEjaModularCtrl', EnturmacaoAlunosEjaModularCtrl);

	EnturmacaoAlunosEjaModularCtrl.$inject = ['educacao.common.$commons', '$modalInstance'];

	function EnturmacaoAlunosEjaModularCtrl($commons, $modalInstance) {
		var vm = this;

		init();

		function init() {
			vm.turmas = $modalInstance.params.turmas;
			vm.Turno = $modalInstance.params.Turno;
			vm.isAlunosEjaModular = true;

			if (!vm.turmas || !vm.turmas.length) {
				throw 'É necessário passar ao menos uma turma';
			}

			vm.tracker = {
				enturmaveis: $commons.$promiseTracker()
			};

			var exemploTurma = vm.turmas[0];

			vm.filtro = {
				estabelecimento: exemploTurma.estabelecimento,
				matriz: exemploTurma.matriz,
				etapaMatriz: exemploTurma.etapaMatriz,
				turno: exemploTurma.turno,
				tipoAtendimento: exemploTurma.tipoAtendimento,
				data: exemploTurma.data
			};

			vm.tabelaTurmas = _.chunk(vm.turmas, 2);
		}
	}
})();


(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .service('educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService', QuadroFuncionariosTurmaAlunosService);

    QuadroFuncionariosTurmaAlunosService.$inject = [
        'ServiceBuilder',
        'Restangular',
    ];

    function QuadroFuncionariosTurmaAlunosService(ServiceBuilder, Restangular) {
        var baseUrl = 'turmas-alunos-quadro-informativo';

        var service = ServiceBuilder.create()
            .path(baseUrl)
            .build();

        service.listSaldosVisaoGeral = listSaldosVisaoGeral;
        service.getOne = getOne;
        service.getTotalTurmas = getTotalTurmas;
        service.getTotalAlunos = getTotalAlunos;
        service.getTotalAlunosComDeficiencia = getTotalAlunosComDeficiencia;
        service.getTotalSalas = getTotalSalas;
        service.getTotalVagasDisponiveis = getTotalVagasDisponiveis;
        service.getTotalEstabelecimentos = getTotalEstabelecimentos;
        service.getSaldoAlunosAnuais = getSaldoAlunosAnuais;
        service.getGraficoTurmasPorTurnoMatutino = getGraficoTurmasPorTurnoMatutino;
        service.getGraficoTurmasPorTurnoVespertino = getGraficoTurmasPorTurnoVespertino;
        service.getGraficoTurmasPorTurnoNoturno = getGraficoTurmasPorTurnoNoturno;
        service.getGraficoTurmasPorTurnoIntegral = getGraficoTurmasPorTurnoIntegral;
        service.getGraficoTurmasPorEtapa = getGraficoTurmasPorEtapa;
        service.getGraficoTurmasPorModalidade = getGraficoTurmasPorModalidade;
        service.getGraficoTurmasPorNivelEscolar = getGraficoTurmasPorNivelEscolar;
        service.getGraficoTurmasEnsinoMedio = getGraficoTurmasEnsinoMedio;
        service.getGraficoTurmasEnsinoFundamental = getGraficoTurmasEnsinoFundamental;
        service.getGraficoTurmasEnsinoFundamentalEja = getGraficoTurmasEnsinoFundamentalEja;
        service.getGraficoTurmasEnsinoFundamentalAceleracao = getGraficoTurmasEnsinoFundamentalAceleracao;
        service.getGraficoTurmasEnsinoInfantil = getGraficoTurmasEnsinoInfantil;
        service.getGraficoAlunosPorModalidade = getGraficoAlunosPorModalidade;
        service.getGraficoAlunosPorTurno = getGraficoAlunosPorTurno;
        service.getGraficoAlunosEnsinoMedio = getGraficoAlunosEnsinoMedio;
        service.getGraficoAlunosEnsinoMedioEja = getGraficoAlunosEnsinoMedioEja;
        service.getGraficoAlunosEnsinoInfantil = getGraficoAlunosEnsinoInfantil;
        service.getGraficoAlunosEnsinoFundamental = getGraficoAlunosEnsinoFundamental;
        service.getGraficoAlunosEnsinoFundamentalEja = getGraficoAlunosEnsinoFundamentalEja;
        service.getGraficosAlunosComDeficiencia = getGraficosAlunosComDeficiencia;
        service.getGraficoDisponibilidadeSalas = getGraficoDisponibilidadeSalas;
        service.getGraficoDisponibilidadeSalasPorTurnoMatutino = getGraficoDisponibilidadeSalasPorTurnoMatutino;
        service.getGraficoDisponibilidadeSalasPorTurnoVespertino = getGraficoDisponibilidadeSalasPorTurnoVespertino;
        service.getGraficoDisponibilidadeSalasPorTurnoNoturno = getGraficoDisponibilidadeSalasPorTurnoNoturno;
        service.getGraficoDisponibilidadeSalasPorTurnoIntegral = getGraficoDisponibilidadeSalasPorTurnoIntegral;
        service.getGraficoTurmasEnsinoMedioEja = getGraficoTurmasEnsinoMedioEja;
        service.getGraficoTurmasEnsinoMedioAceleracao = getGraficoTurmasEnsinoMedioAceleracao;
        service.getGraficoAlunosEnsinoMedioAceleracao = getGraficoAlunosEnsinoMedioAceleracao;
        service.getGraficoAlunosEnsinoFundamentalAceleracao = getGraficoAlunosEnsinoFundamentalAceleracao;
        service.getGraficoTotalTurmasEducacaoBasicaPorNivelEscolar = getGraficoTotalTurmasEducacaoBasicaPorNivelEscolar;
        service.getGraficoTotalTurmasEjaPorNivelEscolar = getGraficoTotalTurmasEjaPorNivelEscolar;
        service.getGraficoTotalTurmasAceleracaoPorNivelEscolar = getGraficoTotalTurmasAceleracaoPorNivelEscolar;
        service.getGraficoTotalAlunosEducacaoBasicaPorNivelEscolar = getGraficoTotalAlunosEducacaoBasicaPorNivelEscolar;
        service.getGraficoTotalAlunosEjaPorNivelEscolar = getGraficoTotalAlunosEjaPorNivelEscolar;
        service.getGraficoTotalAlunosAceleracaoPorNivelEscolar = getGraficoTotalAlunosAceleracaoPorNivelEscolar;
        service.getGraficoDisponibilidadeSaldoDeVagasPorTurnoMatutino = getGraficoDisponibilidadeSaldoDeVagasPorTurnoMatutino;
        service.getGraficoDisponibilidadeSaldoDeVagasPorTurnoVespertino = getGraficoDisponibilidadeSaldoDeVagasPorTurnoVespertino;
        service.getGraficoDisponibilidadeSaldoDeVagasPorTurnoNoturno = getGraficoDisponibilidadeSaldoDeVagasPorTurnoNoturno;
        service.getGraficoDisponibilidadeSaldoDeVagasPorTurnoIntegral = getGraficoDisponibilidadeSaldoDeVagasPorTurnoIntegral;
        service.getGraficoSaldoDeVagasPorModalidade = getGraficoSaldoDeVagasPorModalidade;
        service.getGraficoSaldoDeVagasEducacaoBasica = getGraficoSaldoDeVagasEducacaoBasica;
        service.getGraficoSaldoDeVagasEja = getGraficoSaldoDeVagasEja;
        service.getGraficoSaldoDeVagasAceleracao = getGraficoSaldoDeVagasAceleracao;
        service.getGraficoSaldoDeVagasPorTurno = getGraficoSaldoDeVagasPorTurno;
        service.getModalidadesOferecidas = getModalidadesOferecidas;
        service.getDisponibilidadeTotalVagas = getDisponibilidadeTotalVagas;
        service.getSaldoVagasAnuais = getSaldoVagasAnuais;
        service.getIdsAlunosComDeficiencia = getIdsAlunosComDeficiencia;

        function getIdsAlunosComDeficiencia(params) {
            return Restangular.all(baseUrl + '/alunos-deficiencia-id').get('', params);
        }

        function getModalidadesOferecidas(params) {
            return Restangular.all(baseUrl + '/modalidades-oferecidas').get('', params);
        }

        function getDisponibilidadeTotalVagas(params) {
            return Restangular.all(baseUrl + '/disponibilidade-vagas').get('', params);
        }

        function getGraficoSaldoDeVagasPorTurno(params) {
            return Restangular.all(baseUrl + '/total-vagas-por-turno').get('', params);
        }

        function getGraficoSaldoDeVagasEducacaoBasica(params) {
            return Restangular.all(baseUrl + '/total-vagas-educacao-basica').get('', params);
        }

        function getGraficoSaldoDeVagasEja(params) {
            return Restangular.all(baseUrl + '/total-vagas-eja').get('', params);
        }

        function getGraficoSaldoDeVagasAceleracao(params) {
            return Restangular.all(baseUrl + '/total-vagas-aceleracao').get('', params);
        }

        function getGraficoDisponibilidadeSaldoDeVagasPorTurnoMatutino(params) {
            return Restangular.all(baseUrl + '/total-vagas-matutino').get('', params);
        }

        function getGraficoSaldoDeVagasPorModalidade(params) {
            return Restangular.all(baseUrl + '/total-vagas-por-modalidade').get('', params);
        }

        function getGraficoDisponibilidadeSaldoDeVagasPorTurnoVespertino(params) {
            return Restangular.all(baseUrl + '/total-vagas-vespertino').get('', params);
        }

        function getGraficoDisponibilidadeSaldoDeVagasPorTurnoNoturno(params) {
            return Restangular.all(baseUrl + '/total-vagas-noturno').get('', params);
        }

        function getGraficoDisponibilidadeSaldoDeVagasPorTurnoIntegral(params) {
            return Restangular.all(baseUrl + '/total-vagas-integral').get('', params);
        }

        function listSaldosVisaoGeral(params) {
            return Restangular.all(baseUrl).get('', params);
        }

        function getTotalTurmas(params) {
            return Restangular.all(baseUrl + '/total-turmas').get('', params);
        }

        function getTotalEstabelecimentos(params) {
            return Restangular.all(baseUrl + '/total-estabelecimentos').get('', params);
        }

        function getTotalVagasDisponiveis(params) {
            return Restangular.all(baseUrl + '/total-vagas').get('', params);
        }

        function getGraficosAlunosComDeficiencia(params) {
            return Restangular.all(baseUrl + '/saldo-alunos-com-deficiencia').get('', params);
        }

        function getTotalAlunos(params) {
            return Restangular.all(baseUrl + '/total-alunos').get('', params);
        }

        function getTotalAlunosComDeficiencia(params) {
            return Restangular.all(baseUrl + '/total-alunos-com-deficiencia').get('', params);
        }

        function getTotalSalas(params) {
            return Restangular.all(baseUrl + '/total-salas').get('', params);
        }

        function getGraficoTurmasPorTurnoMatutino(params) {
            return Restangular.all(baseUrl + '/turmas-por-turno-matutino').get('', params);
        }

        function getGraficoTurmasPorTurnoVespertino(params) {
            return Restangular.all(baseUrl + '/turmas-por-turno-vespertino').get('', params);
        }

        function getGraficoTurmasPorTurnoNoturno(params) {
            return Restangular.all(baseUrl + '/turmas-por-turno-noturno').get('', params);
        }

        function getGraficoTurmasPorTurnoIntegral(params) {
            return Restangular.all(baseUrl + '/turmas-por-turno-integral').get('', params);
        }

        function getGraficoAlunosPorTurno(params) {
            return Restangular.all(baseUrl + '/alunos-por-turno').get('', params);
        }

        function getGraficoDisponibilidadeSalasPorTurnoMatutino(params) {
            return Restangular.all(baseUrl + '/salas-por-turno-matutino').get('', params);
        }

        function getGraficoDisponibilidadeSalasPorTurnoVespertino(params) {
            return Restangular.all(baseUrl + '/salas-por-turno-vespertino').get('', params);
        }

        function getGraficoDisponibilidadeSalasPorTurnoNoturno(params) {
            return Restangular.all(baseUrl + '/salas-por-turno-noturno').get('', params);
        }

        function getGraficoDisponibilidadeSalasPorTurnoIntegral(params) {
            return Restangular.all(baseUrl + '/salas-por-turno-integral').get('', params);
        }

        function getGraficoDisponibilidadeSalas(params) {
            return Restangular.all(baseUrl + '/disponibilidade-salas').get('', params);
        }

        function getGraficoTurmasPorEtapa(params) {
            return Restangular.all(baseUrl + '/turmas-por-etapa').get('', params);
        }

        function getGraficoTurmasPorModalidade(params) {
            return Restangular.all(baseUrl + '/turmas-por-modalidade').get('', params);
        }

        function getGraficoAlunosPorModalidade(params) {
            return Restangular.all(baseUrl + '/alunos-por-modalidade').get('', params);
        }

        function getGraficoTurmasPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/turmas-por-nivel-escolar').get('', params);
        }

        function getGraficoTotalTurmasEducacaoBasicaPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/turmas-educacao-basica').get('', params);
        }

        function getGraficoTotalTurmasEjaPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/turmas-eja').get('', params);
        }

        function getGraficoTotalTurmasAceleracaoPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/turmas-aceleracao').get('', params);
        }

        function getGraficoTotalAlunosEducacaoBasicaPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/alunos-educacao-basica').get('', params);
        }

        function getGraficoTotalAlunosEjaPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/alunos-eja').get('', params);
        }

        function getGraficoTotalAlunosAceleracaoPorNivelEscolar(params) {
            return Restangular.all(baseUrl + '/alunos-aceleracao').get('', params);
        }


        function getGraficoTurmasEnsinoMedio(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-medio').get('', params);
        }

        function getGraficoTurmasEnsinoMedioEja(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-medio-eja').get('', params);
        }

        function getGraficoAlunosEnsinoMedio(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-medio').get('', params);
        }

        function getGraficoAlunosEnsinoMedioEja(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-medio-eja').get('', params);
        }

        function getGraficoTurmasEnsinoMedioAceleracao(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-medio-aceleracao').get('', params);
        }

        function getGraficoAlunosEnsinoMedioAceleracao(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-medio-aceleracao').get('', params);
        }

        function getGraficoAlunosEnsinoFundamentalAceleracao(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-fundamental-aceleracao').get('', params);
        }

        function getGraficoTurmasEnsinoInfantil(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-infantil').get('', params);
        }

        function getGraficoAlunosEnsinoInfantil(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-infantil').get('', params);
        }

        function getGraficoTurmasEnsinoFundamental(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-fundamental').get('', params);
        }

        function getGraficoTurmasEnsinoFundamentalEja(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-fundamental-eja').get('', params);
        }

        function getGraficoTurmasEnsinoFundamentalAceleracao(params) {
            return Restangular.all(baseUrl + '/turmas-ensino-fundamental-aceleracao').get('', params);
        }

        function getGraficoAlunosEnsinoFundamental(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-fundamental').get('', params);
        }

        function getGraficoAlunosEnsinoFundamentalEja(params) {
            return Restangular.all(baseUrl + '/alunos-ensino-fundamental-eja').get('', params);
        }

        function getSaldoAlunosAnuais(params) {
            return Restangular.all(baseUrl + '/saldo-alunos-anuais').get('', params);
        }

        function getSaldoVagasAnuais(params) {
            return Restangular.all(baseUrl + '/saldo-vagas-anuais').get('', params);
        }

        function getOne(id) {
            return Restangular.one(baseUrl, id).get().then(strip);
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }


        return service;
    }

})();
(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.FuncaoGratificadaCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$modalStates',
        'educacao.funcionario.FuncaoGratificadaService'
    ];

    function Controller(
        promiseTracker,
        $modalStates,
        FuncaoGratificadaService
    ) {

        var vm = this;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.FuncaoGratificadaService = FuncaoGratificadaService;
        vm.enumTipoFuncao = FuncaoGratificadaService.getTipoFuncao();
        vm.TipoFuncao = [];

        init();

        function init() {
            FuncaoGratificadaService.getTipoFuncao().then(function(data) {
                vm.TipoFuncao = data;
            });
        }

        function openCad(funcaoGratificada) {
            $modalStates.goModalState(funcaoGratificada);
        }

        function remove(data) {
            FuncaoGratificadaService.remove(data);
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.funcionario').directive('appSelectTipoCargo', SelectTipoCargoDirective);

    function SelectTipoCargoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchTipoCargo" add-value="vm.addTipoCargo"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            controller: SelectTipoCargoDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectTipoCargoDirectiveController.$inject = ['ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.funcionario.TipoCargoService'];

    function SelectTipoCargoDirectiveController($modal, PermissionsService, service) {

        var vm = this;

        vm.searchTipoCargo = searchTipoCargo;
        vm.addTipoCargo = !PermissionsService.isRevokedOperation('TipoCargoPageMapping', 'criar') && addTipoCargo;

        function searchTipoCargo(params) {
            return service.list('(descricao like "' + params.term + '")', params.offset, params.limit);

        }

        function addTipoCargo(descricao) {
            return $modal.open({
                templateUrl: 'planejamento/funcionarios/tipo-cargo/tipo-cargo.cad.html',
                controller: 'educacao.funcionario.TipoCargoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    tipoCargo: {
                        descricao: descricao
                    },
                    canSaveAndAdd: false
                }
            }).result;
        }
    }
})();

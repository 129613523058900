(function () {
    'use strict';

    angular.module('educacao.frequencia')
        .factory('educacao.frequencia.TurmaFrequenciaService', TurmaFrequenciaService);

    TurmaFrequenciaService.$inject = [
        'ServiceBuilder'
    ];

    function TurmaFrequenciaService(
        ServiceBuilder
    ) {
        var self = ServiceBuilder
            .create()
            .path('commons', 'turma')
            .enum('TipoAtendimento', 'Turno', 'TipoMediacaoDidaticoPedagogica', 'ModoAvaliacao', 'EquivalenciaEtapa')
            .build();        
        return self;

    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('CriaMenusAdministrando', factory);

    factory.$inject = ['mainMenu', 'MegaMenuItem'];

    function factory(mainMenu, MegaMenuItem) {
        return createMenus;

        function createMenus() {

            var configurationMenu = {
                style: {
                    'width': '750px',
                    'left': '-1px'
                },
                classCol: 'col-md-4',
                configurationColumns: [{
                    numberOfChildrens: 2
                }, {
                    numberOfChildrens: 3
                }, {
                    numberOfChildrens: 3
                }]
            };

            var administrando = new MegaMenuItem('administrando', 'Administrando')
                .setChildrenPerColumn(3)
                .setConfiguration(configurationMenu);

            criaGrupoMatrizCurricular(administrando);
            criaGrupoGestaoPessoas(administrando);
            criaGrupoAlunos(administrando);
            criaGrupoEstabelecimentosEnsino(administrando);
            criaGrupoEnderecos(administrando);
            criaGrupoOutros(administrando);

            mainMenu.addChildren(administrando);

            function criaGrupoMatrizCurricular(root) {
                var grupoMatrizCurricular = new MegaMenuItem('matrizCurricular', 'Matriz curricular')
                    .setParent(root);

                new MegaMenuItem('Cursos')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.curso');

                new MegaMenuItem('Disciplinas')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.disciplina');

                new MegaMenuItem('Eixos temáticos')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.eixoTematico');

                new MegaMenuItem('Matrizes curriculares')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.matriz');

                new MegaMenuItem('Competências, conhecimentos, habilidades e atitudes')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.cha');

                new MegaMenuItem('Campo de experiência')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.campoexperiencia');

                new MegaMenuItem('Unidades temáticas e práticas de linguagem')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.unidadestematicas');

                new MegaMenuItem('Objetos de conhecimentos')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.objetosconhecimentos');

                new MegaMenuItem('Objetivos de aprendizagem e desenvolvimento')
                    .setParent(grupoMatrizCurricular)
                    .setAction('app.matriz.objetivosaprendizagem');

            }

            function criaGrupoGestaoPessoas(root) {
                var grupoGestaoPessoas = new MegaMenuItem('gestaoPessoas', 'Gestão de pessoas')
                    .setParent(root);

                new MegaMenuItem('Tipos de cargos')
                    .setParent(grupoGestaoPessoas)
                    .setAction('app.funcionario.tipocargo');

                new MegaMenuItem('Cargos')
                    .setParent(grupoGestaoPessoas)
                    .setAction('app.funcionario.cargo');

                new MegaMenuItem('Funções gratificadas')
                    .setParent(grupoGestaoPessoas)
                    .setAction('app.funcionario.funcaogratificada');

                new MegaMenuItem('Lotações físicas')
                    .setParent(grupoGestaoPessoas)
                    .setAction('app.funcionario.lotacaofisica');

                new MegaMenuItem('Cursos de aperfeiçoamento')
                    .setParent(grupoGestaoPessoas)
                    .setAction('app.cursoaperfeicoamento');

                new MegaMenuItem('Funcionários')
                    .setParent(grupoGestaoPessoas)
                    .setAction('app.funcionario.funcionario');
            }

            function criaGrupoEstabelecimentosEnsino(root) {
                var grupoEstabelecimentosEnsino = new MegaMenuItem('estabelecimentosEnsino', 'Estabelecimentos de ensino')
                    .setParent(root);

                new MegaMenuItem('Estabelecimentos de ensino')
                    .setParent(grupoEstabelecimentosEnsino)
                    .setAction('app.planejamento.escolas');

                new MegaMenuItem('Avaliações externas')
                    .setParent(grupoEstabelecimentosEnsino)
                    .setAction('app.planejamento.avaliacoes');
            }

            function criaGrupoAlunos(root) {
                var grupo = new MegaMenuItem('grupoAlunos', 'Alunos')
                    .setParent(root);

                new MegaMenuItem('Alunos')
                    .setParent(grupo)
                    .setOperation('listar')
                    .setAction('app.planejamento.alunos');

                new MegaMenuItem('Responsáveis')
                    .setParent(grupo)
                    .setAction('app.planejamento.responsaveis');

                new MegaMenuItem('Filiações')
                    .setParent(grupo)
                    .setAction('app.planejamento.filiacoes');
            }

            function criaGrupoEnderecos(root) {
                var grupoEndereco = new MegaMenuItem('enderecos', 'Endereços')
                    .setParent(root);

                new MegaMenuItem('Bairros')
                    .setParent(grupoEndereco)
                    .setAction('app.enderecos.bairro');

                new MegaMenuItem('Regiões')
                    .setParent(grupoEndereco)
                    .setAction('app.enderecos.regiao');

                new MegaMenuItem('Municípios')
                    .setParent(grupoEndereco)    
                    .setAction('app.enderecos.municipio');

                new MegaMenuItem('Estados')
                    .setParent(grupoEndereco)    
                    .setAction('app.enderecos.estado');

                new MegaMenuItem('Países')
                    .setParent(grupoEndereco)    
                    .setAction('app.enderecos.pais');
            }

            function criaGrupoOutros(root) {
                var grupo = new MegaMenuItem('outros', 'Outros')
                    .setParent(root);

                new MegaMenuItem('Atividades AEE')
                    .setParent(grupo)
                    .setAction('app.matricula.aaee');

                new MegaMenuItem('Atividades complementares')
                    .setParent(grupo)
                    .setAction('app.matricula.atividadecomplementar');

                new MegaMenuItem('Motivos de movimentações e remanejamento interno')
                    .setParent(grupo)
                    .setAction('app.matricula.motivosmovimentacaomatricula');

                new MegaMenuItem('Motivos de dispensa')
                    .setParent(grupo)
                    .setAction('app.matricula.motivosdispensa');

                new MegaMenuItem('Motivos de abonos e justificativas')
                    .setParent(grupo)
                    .setAction('app.motivosabonosjustificativas');

                new MegaMenuItem('Religiões')
                    .setParent(grupo)
                    .setAction('app.planejamento.religioes');

                new MegaMenuItem('Legislações')
                    .setParent(grupo)
                    .setAction('app.legislacao');

                new MegaMenuItem('Convenções')
                    .setParent(grupo)
                    .setAction('app.convencao');

                new MegaMenuItem('Programas Sociais')
                    .setParent(grupo)
                    .setAction('app.programasocial');

                new MegaMenuItem('Deficiências')
                    .setParent(grupo)
                    .setAction('app.planejamento.deficiencia');
            }
        }
    }

    abreModalParametrosGerais.$inject = ['educacao.common.bfcDialog'];

    function abreModalParametrosGerais(ModalCad) {
        ModalCad.open({
            templateUrl: 'common/parametros/parametros.cad.html',
            controller: 'educacao.common.ParametrosCadCtrl as ParametrosCadCtrl',
            readOnly: false
        });
    }

    abreModalParametrosTurmas.$inject = ['educacao.common.ModalCad'];

    function abreModalParametrosTurmas(ModalCad) {
        ModalCad.open({
            templateUrl: 'common/parametros/configuracao-turmas/configuracao.turmas.html',
            controller: 'educacao.common.ConfiguracoesCtrl as vm',
            readOnly: false
        });
    }

    abreModalParametrosMatricula.$inject = ['ui.components.Modal'];

    function abreModalParametrosMatricula($modal) {
        $modal.open({
            templateUrl: 'matricula/configuracao-matricula/configuracao-matricula.html',
            controller: 'educacao.matricula.ConfiguracoesMatriculaCadCtrl',
            controllerAs: 'vm',
            size: 'xl'
        });
    }

})();


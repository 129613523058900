(function () {
    'use strict';

    angular
        .module('educacao.lista-espera')
        .directive('appIndicadoresListaEspera', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera/indicadores-lista-espera.directive.html',

            scope: {quantidadeInscricoes: '='}
        };
    }
})();
// (function() {

//     'use strict';

//     var KEYS = {
//         LEFT: 37,
//         RIGHT: 39,
//         UP: 38,
//         DOWN: 40,
//         ENTER: 13
//     };
//     var itemSelector = '[ed-navigable-item]';
//     var selectorNextTd = 'td:has([ed-navigable-item])';
//     var nextFunction = 'nextAll';
//     var prevFunction = 'prevAll';

//     angular.module('educacao.common')
//         .directive('edNavigableTable', edNavigableTable);

//     function edNavigableTable() {
//         return {
//             retrict: 'A',
//             scope: {
//                 edNavigableTableGroup: '@',
//                 edNavigableTableSequence: '@'
//             },
//             link: function(scope, element) {

//                 element.on('keydown', itemSelector, handleNavigation);

//                 var groupId = scope.edNavigableTableGroup;
//                 var sequenceId = scope.edNavigableTableSequence;

//                 function handleNavigation(event) {
//                     var currentTd = $(event.target).closest('td');
//                     handleKeys(event.keyCode, event.target, currentTd, groupId, sequenceId);
//                 }
//             }
//         };
//     }

//     function handleKeys(keyCode, targetElement, currentTd, groupId, sequenceId) {
//         var nextElement;
//         switch (keyCode) {
//             case KEYS.RIGHT:
//                 nextElement = getNextSideNavigation(targetElement, currentTd, nextFunction, groupId,
//                     sequenceId);
//                 break;
//             case KEYS.LEFT:
//                 nextElement = getNextSideNavigation(targetElement, currentTd, prevFunction, groupId,
//                     sequenceId);
//                 break;
//             case KEYS.UP:
//                 nextElement = topDownNavigation(currentTd, prevFunction);
//                 break;
//             case KEYS.ENTER:
//             case KEYS.DOWN:
//                 nextElement = topDownNavigation(currentTd, nextFunction);
//                 break;
//         }

//         if (nextElement && nextElement.length) {
//             nextElement.find(itemSelector).each(function(i, element) {
//                 var focusSelector = _.get(element.attributes,
//                     '["ed-navigable-table-focus-selector"].value');

//                 var elementToFocus = focusSelector ? $(element).find(focusSelector) :
//                     $(element);

//                 if (elementToFocus.is(':disabled') || !elementToFocus.length) {
//                     handleKeys(keyCode, _.first(elementToFocus), nextElement, groupId, sequenceId);
//                     return;
//                 }

//                 elementToFocus.focus();
//             });
//         }
//     }

//     function topDownNavigation(currentTd, funcNext) {
//         var currentTr = currentTd.closest('tr');
//         var nextTr = currentTr[funcNext]('tr');
//         var nextIndex = currentTd[0].cellIndex;
//         var nextElement;

//         if (nextTr.length) {
//             nextElement = $(nextTr[0].cells[nextIndex]);
//         }
//         return nextElement;
//     }

//     function getNextSideNavigation(element, currentTd, funcNext, groupId, sequenceId) {
//         var nextElement;
//         if ($(element).is('input')) {
//             if (!canMoveToNextElement(element, funcNext)) {
//                 return;    
//             }
//             nextElement = _.first(currentTd[funcNext](selectorNextTd));

//         } else {
//             nextElement = _.first(currentTd[funcNext](selectorNextTd));
//         }

//         nextElement = $(nextElement);

//         if (nextElement && !nextElement.length && groupId) {
//             return getNextTableElement(currentTd, groupId, sequenceId, funcNext);
//         }

//         return nextElement;
//     }

//     function canMoveToNextElement(element, funcNext) {
//         if ($(element).is(':disabled')) {
//             return true;
//         }
//         if (funcNext === nextFunction) {
//             return (element.selectionEnd === element.value.length);
//         }
//         if (funcNext === prevFunction) {
//             return element.selectionStart === 0;
//         }
//     }

//     function getNextTableElement(currentTd, groupId, sequenceId, funcNext) {
//         var nextSequenceId = getNextSequenceId(sequenceId, funcNext);
//         var groupSelector = '[ed-navigable-table-group="' + groupId + '"]';
//         var sequenceSelector = '[ed-navigable-table-sequence="' + nextSequenceId + '"]';
//         var selector = '[ed-navigable-table]' + groupSelector + sequenceSelector;

//         var nextTable = $(selector);

//         if (nextTable && nextTable.length) {
//             var rowIndex = _.get(currentTd.closest('tr')[0], 'rowIndex');
//             var elements = $(nextTable[0].rows[rowIndex]).find('td:has(' + itemSelector + ')');
//             return getNextElementByFunc(elements, funcNext);
//         }
//     }

//     function getNextSequenceId(sequenceId, funcNext) {
//         if (funcNext === nextFunction) {
//             return Number(sequenceId) + 1;
//         } else {
//             return Number(sequenceId) - 1;
//         }
//     }

//     function getNextElementByFunc(elements, funcNext) {
//         if (funcNext === nextFunction) {
//             return elements.first();
//         } else {
//             return elements.last();
//         }
//     }
// })();

(function() {
    'use strict';

    angular
        .module('educacao.calendario')
        .service('CalendarioDisplay', CalendarioDisplay);

    CalendarioDisplay.$inject = ['WorkdayHelper', 'IdGenerator'];

    function CalendarioDisplay(WorkdayHelper, IdGenerator) {
        return {
            create: create
        };

        function create() {
            var self = WorkdayHelper.build();

            self.baseCalendar = null; //Calendario principal
            self.blocked = false;
            self.setBlocked = setBlocked;

            self.sources = [];
            self.reset = reset;

            self.observacoes = [];

            self.setBaseCalendar = setBaseCalendar;

            self.addCalendario = addCalendario;
            self.addPeriodosLetivos = addPeriodosLetivos;
            self.addObservacao = addObservacao;
            self.addEventos = addEventos;
            self.addFeriados = addFeriados;
            self.addExcecoes = addExcecoes;

            self.getEventos = getEventos;
            self.getFeriados = getFeriados;
            self.getExcecoes = getExcecoes;

            self.filtrosEvento = [];
            self.addFiltroEventos = addFiltroEventos;

            self.flatten = {
                eventos: [],
                feriados: [],
                excecoes: []
            };

            self.processCount = 0;
            self.process = process;

            self.clone = clone;

            return self;

            function reset() {
                self.resetDefaultValues();
                self.sources = [];
                self.filtrosEvento = [];
                self.observacoes = [];
                return self;
            }

            function setBaseCalendar(baseCalendar) {
                self.baseCalendar = baseCalendar;
                return self;
            }

            function setBlocked(isBlocked) {
                self.blocked = isBlocked;
                return self;
            }

            function addSource(source) {
                IdGenerator.addOrUpdate(source, self.sources);
                return self;
            }

            function addObservacao(label, observacao, readonly) {
                if (!label) {
                    return self;
                }
                return addSource({
                    label: label,
                    observacao: observacao,
                    readonly: readonly !== false
                });
            }

            function addCalendario(calendario, label) {
                return addSource(calendario)
                    .addObservacao(label, calendario.observacao, calendario !== self.baseCalendar);
            }

            function addPeriodosLetivos(listaPeriodos) {
                return addSource({periodosLetivos: listaPeriodos});
            }

            function addEventos(eventos) {
                return addSource({
                    eventos: eventos
                });
            }

            function addFeriados(feriados) {
                return addSource({
                    feriados: feriados
                });
            }

            function addExcecoes(excecoes) {
                var source = {
                    excecoes: excecoes
                };
                return addSource(source);
            }

            function getEventos(date) {
                return self.eventos[date];
            }

            function getFeriados(date) {
                return self.feriados[date];
            }

            function getExcecoes(date) {
                return self.excecoes[date];
            }

            function addFiltroEventos(filtro) {
                self.filtrosEvento.push(filtro);
                return self;
            }

            function filtraEventos(eventos) {
                var filtrados = eventos;

                self.filtrosEvento.forEach(function(filtro) {
                    filtrados = filtrados.filter(filtro);
                });

                return filtrados;
            }

            function process() {
                self.resetDefaultValues();
                self.observacoes = [];

                self.flatten = {
                    eventos: [],
                    feriados: [],
                    excecoes: []
                };

                var eventos;
                angular.forEach(self.sources, function(source) {
                    if (source.dataInicial) {
                        self.withDataInicial(source.dataInicial);
                    }
                    if (source.dataFinal) {
                        self.withDataFinal(source.dataFinal);
                    }
                    if (source.periodosLetivos) {
                        self.withPeriodosLetivos(source.periodosLetivos);
                    }
                    if (source.diasLetivosSemana) {
                        self.withDiasLetivosSemana(source.diasLetivosSemana);
                    }
                    if (source.label) {
                        self.observacoes.push(source);
                    }

                    if (source.eventos) {
                        eventos = filtraEventos(source.eventos);

                        eventos.forEach(function setReadonly(evento) {
                            evento.$$readonly = source !== self.baseCalendar || (evento.aceite && evento.aceite.aceitoObrigatorioNestaData);
                            evento.$$canDelete = !evento.aceite || !evento.aceite.aceitoObrigatorio;
                        });

                        self.withEventos(eventos);
                        self.flatten.eventos = self.flatten.eventos.concat(eventos);
                    }

                    self.withFeriados(source.feriados);
                    self.flatten.feriados = self.flatten.feriados.concat(source.feriados || []);

                    self.withExcecoes(source.excecoes);
                    self.flatten.excecoes = self.flatten.excecoes.concat(source.excecoes || []);
                });

                self.processCount++;

                return self;
            }

            function clone() {
                var cloned = create();
                cloned.sources = _.cloneDeep(self.sources);
                cloned.baseCalendar = self.baseCalendar && IdGenerator.get(self.baseCalendar, cloned.sources);
                cloned.blocked = self.blocked;
                cloned.filtrosEvento = _.cloneDeep(self.filtrosEvento);
                return cloned.process();
            }
        }
    }
})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .service('educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils', InscricaoListaEsperaUtils);

    InscricaoListaEsperaUtils.$inject = [
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'educacao.context.ContextoEstabelecimento',
        '$q',
        'educacao.context.ContextoAnoLetivo'
    ];

    function InscricaoListaEsperaUtils(InscricaoListaEsperaService, ContextoEstabelecimento, $q, ContextoAnoLetivo) {
        var cacheConfig = [];
        var cacheEstab = [];
        var cacheCursos = [];

        return {
            getConfiguracao: getConfiguracao,
            getEstabelecimentos: getEstabelecimentos,
            getCursos: getCursos,
            clearCacheConfig: clearCacheConfig,
            getMunicipios: getMunicipios,
            checkIdadeComEtapa: checkIdadeComEtapa,
            getBairros: getBairros,
            getBairrosFiliacoes: getBairrosFiliacoes,

        };

        function getCursos(configId, listId) {
            var existeCache = getCache(cacheCursos, configId);

            if (existeCache) {
                return $q.when(existeCache);
            }

            var filter = '(id in (' + _.compact(listId).join() + '))';
            return InscricaoListaEsperaService.getCursos(filter)
                .then(_.bind(setCacheCursos, null, configId));
        }


        function setCacheCursos(configId, values) {
            cacheCursos.push({
                config: configId,
                cursos: values
            });

            return getCache(cacheCursos, configId);
        }

        function getConfiguracao(configuracaoId) {
            var existeCache = getCache(cacheConfig, configuracaoId);

            if (existeCache) {
                return $q.when(existeCache);
            }

            return InscricaoListaEsperaService.getConfiguracao(configuracaoId)
                .then(_.bind(setCacheConfig, null, configuracaoId));

        }

        function setCacheConfig(configuracaoId, values) {
            cacheConfig.push({
                config: configuracaoId,
                configuracao: values
            });

            return getCache(cacheConfig, configuracaoId);
        }

        function clearCacheConfig(configId) {
            _.remove(cacheConfig, 'config', configId);
        }

        function getEstabelecimentos(configuracaoId, permiteRegistroOutroEstabelecimento) {
            if (!ContextoEstabelecimento.isSecretaria() && !permiteRegistroOutroEstabelecimento) {
                cacheEstab = [];
                return $q.when([ContextoEstabelecimento.getEstabelecimento()])
                    .then(_.bind(setCacheEstab, null, configuracaoId));
            }

            var existeCache = getCache(cacheEstab, configuracaoId);
            if (existeCache) {
                return $q.when(existeCache);
            }

            return InscricaoListaEsperaService.getEstabelecimentos()
                .then(_.bind(setCacheEstab, null, configuracaoId));
        }

        function setCacheEstab(configuracaoId, values) {
            cacheEstab.push({
                config: configuracaoId,
                estabelecimentos: values
            });

            return getCache(cacheEstab, configuracaoId);
        }

        function getCache(cache, id) {
            return _.find(cache, 'config', id);
        }

        function getMunicipios(inscricaoListaEspera, isAlunoInscricaoMatricula) {
            var params = [];
            params.push(_.get(inscricaoListaEspera, 'municipioNascimento'));
            params.push(_.get(inscricaoListaEspera, 'enderecoResidencia.municipio'));
            params.push(_.get(inscricaoListaEspera, 'municipioCertidao'));

            _.forEach(inscricaoListaEspera.filiacoes, function (filiacao) {
                params.push(_.get(filiacao, 'enderecoTrabalho.municipio'));
            });

            if (isAlunoInscricaoMatricula) {
                params = params
                .filter(function (data) {
                    return !!data;
                })
                .map(function (param) {
                    return param.id;
                });
            }

            params = _.remove(params, _.isNumber);

            if (_.isEmpty(params)) {
                return $q.when();
            }

            var filter = '(id in (' + _.compact(params).join() + '))';

            return InscricaoListaEsperaService.getMunicipio(filter);
        }

        function getBairros(inscricaoListaEspera, isAlunoInscricaoMatricula) {
            var bairroInscricao = _.get(inscricaoListaEspera, 'enderecoResidencia.bairroEndereco');
            var bairroId = isAlunoInscricaoMatricula ? bairroInscricao.id : bairroInscricao;

            if (!bairroId) {
                return $q.when();
            }

            var filter = '(id = ' + bairroId + ' )';

            return InscricaoListaEsperaService.getBairro(filter);
        }


        function getBairrosFiliacoes(inscricaoListaEspera, isAlunoInscricaoMatricula) {
            var filiacoes = _.get(inscricaoListaEspera, 'filiacoes', []);

            var bairrosIds = _.map(filiacoes, function (filiacao) {
                var bairroFiliacao = _.get(filiacao, 'enderecoTrabalho.bairroEndereco');
                return isAlunoInscricaoMatricula && bairroFiliacao ? bairroFiliacao.id : bairroFiliacao;
            }).filter(function (bairroId) {
                return !!bairroId;
            });

            if (_.isEmpty(bairrosIds)) {
                return $q.when();
            }
            var params = '(id in (' + bairrosIds.join(',') + '))';

            return InscricaoListaEsperaService.getBairro(params);
        }


        function checkIdadeComEtapa(etapas, dataNascimento) {
            var TIPO_CORTE_DATA_INSCRICAO = 'DATA_INSCRICAO';

            var indexPrimeiraFaixaEtaria = getPrimeiraFaixaEtaria(etapas);

            var checkIdadeEtapaFunction = checkIdadeEtapa.bind(null, indexPrimeiraFaixaEtaria);

            return _.chain(etapas)
                .map(checkIdadeEtapaFunction)
                .find()
                .value();

            function checkIdadeEtapa(idxPrimeiraFaixaEtaria, etapa) {
                var dataDeCorte = getDataCorteByTipoDataCorte(etapa);
                var indexEtapa = _.findIndex(etapas, 'id', etapa.id);

                if (verficaEtapa(etapa, dataDeCorte, indexEtapa, idxPrimeiraFaixaEtaria)) {
                    return {id: etapa.id, descricao: etapa.descricao, tipo: etapa.tipo, turnos: etapa.turnos};
                }
            }

            function verficaEtapa(etapa, dataDeCorte, indexEtapa, idxPrimeiraFaixaEtaria) {

                var idadeMaxima = moment(dataDeCorte).subtract(etapa.anoIdadeMaxima, 'years').subtract(etapa.mesIdadeMaxima, 'months');
                var idadeMinima = moment(dataDeCorte).subtract(etapa.anoIdadeMinima, 'years').subtract(etapa.mesIdadeMinima, 'months');

                var mesDaIdadeMaxima = dataDeCorte.diff(idadeMaxima, 'months');
                var mesDaIdadeMinima = dataDeCorte.diff(idadeMinima, 'months');
                var mesDoInscrito = dataDeCorte.diff(dataNascimento, 'months');

                var isPrimeiraFaixa = indexEtapa === idxPrimeiraFaixaEtaria;

                if (moment(dataNascimento).isAfter(dataDeCorte)) {
                    if (isPrimeiraFaixa) {
                        return true;
                    }

                    return false;
                }

                if (mesDoInscrito <= mesDaIdadeMinima && isPrimeiraFaixa) {
                    return true;
                }

                if (mesDoInscrito >= mesDaIdadeMinima && mesDoInscrito <= mesDaIdadeMaxima) {
                    return true;
                }

                return false;
            }

            function getDataCorteByTipoDataCorte(etapa) {
                if (etapa.tipoDataCorte === TIPO_CORTE_DATA_INSCRICAO) {
                    return moment().startOf('day');
                }

                var diaMesCorte = etapa.diaMesParaCompletarIdadeMinima.replace('-', '');
                return moment(ContextoAnoLetivo.getValorContextoAnoLetivo() + diaMesCorte);
            }

            function getPrimeiraFaixaEtaria(etapasModalidade) {
                var idadeMin = Date.now();
                var idxPrimeiraFaixaEtaria = 0;

                for (var i = 0; i < etapasModalidade.length; i++) {
                    var modalidadeInfantil = etapasModalidade[i];

                    var idadeMinModalidade = new Date();
                    idadeMinModalidade.setFullYear(modalidadeInfantil.anoIdadeMinima);
                    idadeMinModalidade.setMonth(modalidadeInfantil.mesIdadeMinima);

                    if (idadeMinModalidade < idadeMin) {
                        idadeMin = idadeMinModalidade;
                        idxPrimeiraFaixaEtaria = i;
                    }
                }

                return idxPrimeiraFaixaEtaria;
            }
        }
    }
})();

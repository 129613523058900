(function () {
  'use strict';

  var DIAS_ARQUIVO_DISPONIVEL = 30;

  angular
    .module('educacao.educacenso')
    .controller('educacao.educacenso.EducacensoController', Controller);

  Controller.$inject = [
    'promiseTracker',
    '$state',
    '$searchExpressionBuilder',
    'bfc.Notification',
    'educacao.educacenso.EducacensoExecucaoInconsistenciaService',
    'educacao.educacenso.EducacensoExecucaoService',
    '$enumsCache',
    'bfc.$$PermissionsService',
    'bfc.authentication.AuthenticationContext',
    'educacao.context.ContextoEntidade',
    'EDUCACENSO_URL_DOWNLOAD_RESULTADO',
    'EDUCACENSO_ETAPAS',
      '$rootScope'
  ];

  function Controller(
    promiseTracker,
    $state,
    $searchExpressionBuilder,
    bfcNotification,
    EducacensoExecucaoInconsistenciaService,
    EducacensoExecucaoService,
    $enumsCache,
    PermissionsService,
    AuthenticationContext,
    ContextoEntidade,
    EDUCACENSO_URL_DOWNLOAD_RESULTADO,
    EDUCACENSO_ETAPAS,
    $rootScope
  ) {
    var vm = this;
    var MINUTOS_PARA_MOSTRAR_AVISO_ERROR = 15;
    vm.ETAPAS = EDUCACENSO_ETAPAS;

    vm.loadingTracker = promiseTracker();
    vm.loadingTracker.envioMultiplo = promiseTracker();
    vm.loadingTracker.loading = promiseTracker();

    vm.listaEstabelecimentos = listaEstabelecimentos;
    vm.startProcessoValidacao = startProcessoValidacao;
    vm.isBadgedValidadoComErros = isBadgedValidadoComErros;
    vm.getTotalError = getTotalError;
    vm.isBadgedValidadoProntoParaEnvioAoEducacenso =
      isBadgedValidadoProntoParaEnvioAoEducacenso;
    vm.habilitaBadgedPlay = habilitaBadgedPlay;
    vm.habilitaBadgedVisualizar = habilitaBadgedVisualizar;
    vm.habilitaBadgedReprocessar = habilitaBadgedReprocessar;
    vm.habilitaBadgedBaixarResultadoExecucao =
      habilitaBadgedBaixarResultadoExecucao;
    vm.habilitaBadgedProcessoNaoIniciado = habilitaBadgedProcessoNaoIniciado;
    vm.habilitaBadgedProcessando = habilitaBadgedProcessando;
    vm.progressoValidacao = false;
    vm.firstSearchExecuted = false;
    vm.allSelected = false;
    vm.estabelecimentoParaBuscarInconsistencias = null;
    vm.selectAll = selectAll;
    vm.startProcessoValidacaoMultiplos = startProcessoValidacaoMultiplos;
    vm.changeSituacaoExecucao = changeSituacaoExecucao;
    vm.showInconsistencias = showInconsistencias;
    vm.disabledEstabelecimentoMarcadoParaExecutarMultiplos =
      disabledEstabelecimentoMarcadoParaExecutarMultiplos;
    vm.isArquivoDisponivel = isArquivoDisponivel;
    vm.alteraEtapa = alteraEtapa;

    $rootScope.$on('educacenso:etapa', function (events, args) {
      alteraEtapa(args.etapa);
    });

    vm.configuracaoTurmasMultiturnoLocalStorage = undefined;
    vm.changeConfiguracaoTurmasMultiturnoLocalStorage = changeConfiguracaoTurmasMultiturnoLocalStorage;

    vm.hasPermissionToAlterarSituacaoEstabelecimento = PermissionsService.isRevokedOperation('EducacensoPageMapping', 'alterarSituacaoEnvioEstabelecimento');

    vm.user = AuthenticationContext.getUserInfo();
    vm.entidade = ContextoEntidade.getEntidade();

    var KEY_LOCAL_STORAGE_CONFIGURACAO_DIVISAO_TURMA_MULTITURNO = btoa('educacao.configuracaoTurmasMultiturno-' + vm.user.id + '-'+ vm.entidade.id);

    vm.habilitaBadgedprocessandoEstaDemorandoMuito = habilitaBadgedprocessandoEstaDemorandoMuito;
    vm.getUrlDownloadResultadoExecucao = getUrlDownloadResultadoExecucao;

    vm.searchColumns = [{
      id: 'nome',
      label: 'Nome do estabelecimento',
      model: 'estabelecimento.nome',
      filterable: true
    }];

    function listaEstabelecimentos() {
      var filter = 'etapa in ( "' + vm.etapaAtual +'")';

      var expression = $searchExpressionBuilder.build(vm.searchFilter) || '';
      if (!_.isEmpty(expression)) {
        filter += ' and (' + expression + ')';
      }

      var params = {
        filter: filter,
        offset: vm.currentPage - 1,
        limit: vm.itemsPerPage,
      };

      params.offset = params.offset > 0 ? params.offset * params.limit : 0;

      vm.loadingTracker.addPromise(
        EducacensoExecucaoInconsistenciaService.listCompleteByParams(
          params
        ).then(listPaginado)
      );

      vm.allSelected = false;
    }

    function initEnums() {
			vm.enumOptionsSituacaoEstabelecimentoEnvioEducacenso = $enumsCache.get('SituacaoEstabelecimentoEnvioEducacenso');
    }

    function init(){
      alteraEtapa(vm.ETAPAS.PRIMEIRA_ETAPA);
      initConfiguracaoTurmasMultiturnoLocalStorage();
      initEnums();
      listaEstabelecimentos();
    }

    init();

    function listPaginado(itens) {
      vm.firstSearchExecuted = true;
      vm.estabelecimentos = itens.content;
      vm.page = itens;
      vm.currentPage = itens.offset / itens.limit + 1;
      vm.totalItems = itens.total;
      vm.hasNext = itens.hasNext;
      vm.totalContent = itens.content.length;
    }

    function startProcessoValidacao(estabelecimento) {
      if(!vm.loadingTracker.loading[estabelecimento.id]){
        vm.loadingTracker.loading[estabelecimento.id] = promiseTracker();
      }

        vm.loadingTracker.loading[estabelecimento.id].addPromise(
          EducacensoExecucaoInconsistenciaService.startProcessoValidacaoMultiplos(
            [estabelecimento],
            vm.configuracaoTurmasMultiturnoLocalStorage,
              vm.etapaAtual
          ).then(listaEstabelecimentos)
        );
    }

    function isArquivoDisponivel(estabelecimento){
      var dataAtual = moment();
      var updatedInData = _.get(estabelecimento,['educacensoExecucao', 'updatedIn']);
      var createdInData = _.get(estabelecimento,['educacensoExecucao', 'createdIn']);
      var ultimaExecucao= updatedInData? updatedInData: createdInData;
      var diasPassados = dataAtual.diff(ultimaExecucao, 'days');
      return (diasPassados < DIAS_ARQUIVO_DISPONIVEL);
    }

    function startProcessoValidacaoMultiplos() {
      var selecionados = _.filter(vm.estabelecimentos, { $$selected: true }).map(function(obj) {
        return _.pick(obj, 'id');
      });

      vm.loadingTracker.envioMultiplo.addPromise(EducacensoExecucaoInconsistenciaService.startProcessoValidacaoMultiplos(
        selecionados,
        vm.configuracaoTurmasMultiturnoLocalStorage,
          vm.etapaAtual
      ).then( bfcNotification.publish(
        'Enviado estabelecimentos! Assim que todos estiverem em análise você será notificado',
        'info'
      )));
    }

    function isBadgedValidadoComErros(estabelecimento) {
      if (!estabelecimento.educacensoExecucao) {
        return false;
      }

      if (estabelecimento.educacensoExecucao.execucaoFinalizada) {
        return existsErros(estabelecimento);
      }

      return false;
    }

    function existsErros(estabelecimento) {
      return (getTotalError(estabelecimento) > 0);
    }

    function getTotalError(estabelecimento) {
      return (
        estabelecimento.totalInconsistenciasEscolas +
        estabelecimento.totalInconsistenciasTurmas +
        estabelecimento.totalInconsistenciasMatriculas +
        estabelecimento.totalInconsistenciasAlunos +
        estabelecimento.totalInconsistenciasGestores +
        estabelecimento.totalInconsistenciasProfessores +
        estabelecimento.totalInconsistenciasFuncionarios +
        estabelecimento.totalInconsistenciasOutros
      );
    }

    function isBadgedValidadoProntoParaEnvioAoEducacenso(estabelecimento) {
      if(_.get(estabelecimento, 'educacensoExecucao.execucaoFinalizada')){
				return !existsErros(estabelecimento);
			}
			return false;
    }

    function habilitaBadgedPlay(estabelecimento) {
      return !_.get(estabelecimento, 'educacensoExecucao');
    }

    function habilitaBadgedReprocessar(estabelecimento) {
      return _.isBoolean(_.get(estabelecimento, 'educacensoExecucao.execucaoFinalizada'));
    }

    function habilitaBadgedBaixarResultadoExecucao(estabelecimento) {
      return (
        habilitaBadgedReprocessar(estabelecimento) &&
        _.get(estabelecimento, 'educacensoExecucao.execucaoFinalizada') === true
      );
    }

    function habilitaBadgedVisualizar(estabelecimento) {
        return _.get(estabelecimento, 'educacensoExecucao.execucaoFinalizada') && existsErros(estabelecimento);
    }

    function habilitaBadgedProcessoNaoIniciado(estabelecimento){
      return !_.isObject(_.get(estabelecimento, 'educacensoExecucao'));
    }

    function habilitaBadgedProcessando(estabelecimento){
      return _.get(estabelecimento, 'educacensoExecucao.execucaoFinalizada') === false;
    }

    function selectAll() {
      vm.allSelected = !vm.allSelected;
      _.forEach(vm.estabelecimentos, function (item) {
        item.$$selected = vm.allSelected;
      });
    }

    function disabledEstabelecimentoMarcadoParaExecutarMultiplos() {
      if (!vm.estabelecimentos) {
        return true;
      }
      return !_.any(vm.estabelecimentos, { $$selected: true });
    }

    function showInconsistencias(estabelecimento) {
      vm.progressoValidacao = true;
      vm.estabelecimentoParaBuscarInconsistencias = estabelecimento;
    }

    function changeSituacaoExecucao(item){
      EducacensoExecucaoService.alterarSituacao(item)
        .then(bfcNotification.publish(
          'Alteração da situação do estabelecimento realizado com sucesso.',
          'success'
        )
      );
    }

    function initConfiguracaoTurmasMultiturnoLocalStorage(){
      atualizadaConfiguracaoTurmasMultiturnoLocalStorage();
    }

    function setConfiguracaoTurmasMultiturno(situacao){
      localStorage.setItem(KEY_LOCAL_STORAGE_CONFIGURACAO_DIVISAO_TURMA_MULTITURNO, situacao);
    }

    function atualizadaConfiguracaoTurmasMultiturnoLocalStorage(){
      vm.configuracaoTurmasMultiturnoLocalStorage = localStorage.getItem(KEY_LOCAL_STORAGE_CONFIGURACAO_DIVISAO_TURMA_MULTITURNO) === 'true';
    }

    function changeConfiguracaoTurmasMultiturnoLocalStorage(){
     setConfiguracaoTurmasMultiturno(vm.configuracaoTurmasMultiturnoLocalStorage);
    }


    function habilitaBadgedprocessandoEstaDemorandoMuito(item) {
      if(_.get(item, 'educacensoExecucao.execucaoFinalizada') || !_.get(item, 'educacensoExecucao.createdIn') || !_.get(item, 'educacensoExecucao.updatedIn')){
        return false;
      }
      return processandoEstaDemorando(item);
    }

    function processandoEstaDemorando(item){
      var createdIn = moment(item.educacensoExecucao.createdIn);
      var updatedIn = moment(item.educacensoExecucao.updatedIn);
      var dataMaisRecente = moment.max(createdIn, updatedIn);
      var minutosDesdeDoInicioDaExecucaoDoScript = moment().diff(dataMaisRecente, 'minutes');
      return minutosDesdeDoInicioDaExecucaoDoScript > MINUTOS_PARA_MOSTRAR_AVISO_ERROR;
    }

    function getUrlDownloadResultadoExecucao(item){
      if(!item.educacensoExecucao){
        return;
      }
      return EDUCACENSO_URL_DOWNLOAD_RESULTADO + '/download/api/execucoes/' + item.educacensoExecucao.protocoloExecucao + '/resultado';
    }

    function alteraEtapa(etapa){
      vm.etapaAtual = etapa;
      vm.progressoValidacao = false;
      listaEstabelecimentos();
    }

  }
})();

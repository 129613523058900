(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.HorasAulasCadCtrl', HorasAulasCadCtrl);

    HorasAulasCadCtrl.$inject = [
        'educacao.matricula.HorasAulaService',
        '$modalTracker',
        '$scope',
        '$q',
        'id',
        'readOnly',
        'promiseTracker',
        'fnCad',
        '$rootScope',
        '$modalInstance',
        'bfc.Focus',
        'educacao.common.CommonSelectEnum',
        'optionsCad'
    ];

    function HorasAulasCadCtrl(
        HorasAulaService,
        $modalTracker,
        $scope,
        $q,
        id,
        readOnly,
        promiseTracker,
        fnCad,
        $rootScope,
        $modalInstance,
        focus,
        CommonSelectEnum,
        optionsCad) {

        var _option = {},
            vm = this;
        vm.dadosDisponiveisPraModal = false;
        vm.tracker = {};
        vm.tracker.save = promiseTracker();
        vm.tracker.saveAndAdd = promiseTracker();
        vm.tracker.remove = promiseTracker();
        vm.tracker.loading = promiseTracker();

        vm.save = save;
        vm.cancel = fechaModal;
        vm.remove = remove;
        vm.addItem = addItem;
        vm.removeItem = removeItem;
        vm.openIntervaloPopover = openIntervaloPopover;
        vm.addIntervalo = addIntervalo;
        vm.fecharPopover = fecharPopover;

        vm.option = _option;
        vm.listenDaysOfWeek = listenDaysOfWeek;
        vm.formatterIntervalosTags = formatterIntervalosTags;
        vm.isItensValidos = isItensValidos;
        vm.formatResult = formatResult;

        vm.openPopoverDuracaoHorasAulas = openPopoverDuracaoHorasAulas;
        vm.saveDuracaoHorasAulas = saveDuracaoHorasAulas;
        vm.changeQuantidadeAulas = changeQuantidadeAulas;
        vm.changeDuracaoAula = changeDuracaoAula;

        var modo = {
            adicionando: !id && !readOnly,
            editando: id && !readOnly,
            editViaConfiguracaoTurmas: Boolean(fnCad) || readOnly,
        };

        vm.listAulasPrecedentes = listAulasPrecedentes;


        init();

        function formatterIntervalosTags(intervalo) {
            return intervalo.nroAulaPrecedente + 'ª aula - ' + intervalo.tempoDuracao + ' min';
        }


        function saveDuracaoHorasAulas() {
            vm.horasAula.itens[vm.rowPopover].itensDuracao = angular.copy(vm.horariosAulas);
            var possuiHorarioDivergente = _.find(vm.horariosAulas, function (itemDuracao) {
                return !angular.equals(itemDuracao.tempoDuracaoAula, vm.itemSelected.tempoDuracaoAulas);
            });
            vm.horasAula.itens[vm.rowPopover].$$disableDuracaoHoraAula = !!possuiHorarioDivergente;
            focus('idTimer' + vm.rowPopover);
            fecharPopover();
        }

        function changeQuantidadeAulas(item, index) {
            vm.horariosAulas = item.itensDuracao;

            if (_.get(vm.horariosAulas, 'length')) {
                vm.horariosAulas = _.sortByOrder(vm.horariosAulas, ['nroAula'], ['asc']);
                if (item.qtdAulas === item.itensDuracao.length) {
                    return;
                }

                if (item.qtdAulas > item.itensDuracao.length) {
                    item.itensDuracao = adicionaItensDuracao(item);
                    return;
                }

                if (item.qtdAulas < item.itensDuracao.length) {
                    item.itensDuracao = removeItensDuracao(item);
                    return;
                }
                return;
            }
            vm.horasAula.itens[index].$$disableDuracaoHoraAula = false;
            montaListaHorarioAulas(item);
        }

        function adicionaItensDuracao(item) {
            var indice = vm.horariosAulas.length;
            for (var i = indice; i < item.qtdAulas; i++) {
                var idHoraAula = i + 1;
                vm.horariosAulas.push({
                    $$indice: idHoraAula,
                    nroAula: idHoraAula,
                    tempoDuracaoAula: item.tempoDuracaoAulas
                });
            }
            return vm.horariosAulas;
        }
        function removeItensDuracao(item) {
            var quantidaRemocao = item.itensDuracao.length - item.qtdAulas;
            var indiceRemocao = item.qtdAulas;
            vm.horariosAulas.splice(indiceRemocao, quantidaRemocao);
            return vm.horariosAulas;
        }

        function changeDuracaoAula(item) {
            vm.horariosAulas = item.itensDuracao;
            if (_.get(vm.horariosAulas, 'length') && item.qtdAulas === item.itensDuracao.length) {
                var naoPossuiHorario = _.find(vm.horariosAulas, function (itemDuracao) {
                    return !itemDuracao.tempoDuracaoAula;
                });
                if (!naoPossuiHorario) {
                    //se possuir só um elemento signnifica que todas as durações são iguais então
                    //serão sobrescritas pelo campo tempo duração quando trocar.
                    var duracaoItensIguais = _.uniq(vm.horariosAulas,'tempoDuracaoAula');
                    if(duracaoItensIguais.length > 1){
                        return;
                    }
                }
            }
            montaListaHorarioAulas(item);
        }
        function openPopoverDuracaoHorasAulas(item, row) {
            vm.itemSelected = item;
            vm.rowPopover = row;
            vm.horariosAulas = angular.copy(item.itensDuracao);
            vm.horariosAulas = _.sortByOrder(vm.horariosAulas, ['nroAula'], ['asc']);
        }

        function montaListaHorarioAulas(item) {
            vm.horariosAulas = [];
            for (var i = 0; i < item.qtdAulas; i++) {
                var aula = + i + 1;
                vm.horariosAulas.push({
                    id: i,
                    nroAula: aula,
                    tempoDuracaoAula: item.tempoDuracaoAulas
                });
            }
            item.itensDuracao = vm.horariosAulas;
        }
        function listenDaysOfWeek() {

            var daysSelected = [];

            angular.forEach(vm.horasAula.itens, function (item) {
                if (Boolean(item.diasDaSemana)) {
                    daysSelected = _.union(daysSelected, item.diasDaSemana);
                }
            });
            vm.daysSelected = daysSelected;
        }

        function isItensValidos() {
            var status;
            if (!vm.horasAula.itens) {
                return true;
            }

            angular.forEach(vm.horasAula.itens, function (item) {
                status = !item.qtdAulas || !item.horaInicio;
            });

            return status;
        }

        function desabilitaItensDivergentes(item, index) {

            var possuiHorarioDivergente = _.find(item.itensDuracao, function (itemDuracao) {
                return !angular.equals(itemDuracao.tempoDuracaoAula, item.tempoDuracaoAulas);
            });
            vm.horasAula.itens[index].$$disableDuracaoHoraAula = !!possuiHorarioDivergente;

        }
        function ajustaDiasDaSemana(data) {
            var copy = angular.copy(data);

            angular.forEach(copy.itens, function (item) {
                if (!item.diasDaSemana) {
                    return;
                }

                item.diasDaSemana = item.diasDaSemana.map(function (dia) {
                    return typeof dia === 'object' ? dia.key : dia;
                });
            });
            return copy;
        }

        function ajustaCamposMultiplos(data) {
            var diasAjustados = ajustaDiasDaSemana(data);
            return diasAjustados;
        }

        function addItem() {
            vm.horasAula.itens.push({
                qtdAulas: null,
                tempoDuracaoAulas: null,
                horaInicio: '',
                diasDaSemana: [],
                intervalos: [],
                itensDuracao: []
            });
        }

        function removeItem(item) {
            var index = vm.horasAula.itens.indexOf(item);
            vm.horasAula.itens.splice(index, 1);
            listenDaysOfWeek();
        }

        function listAulasPrecedentes() {
            return $q.when({
                content: vm.aulasIntervalo,
                hasNext: false
            });
        }

        function openIntervaloPopover(item, row) {
            vm.itemSelected = item;
            vm.aulasIntervalo = [];
            for (var i = 1; i < item.qtdAulas; i++) {
                vm.aulasIntervalo.push({
                    id: i,
                    nroAula: i,
                    descricao: i + 'ª aula'
                });
            }
            vm.rowPopover = row;
            vm.intervaloSelected = {};
        }

        function addIntervalo(intervaloSelected) {
            vm.intervalo = {
                nroAulaPrecedente:  _.first(intervaloSelected.nroAulaPrecedente).nroAula,
                tempoDuracao: intervaloSelected.tempoDuracao
            };
            vm.horasAula.itens[vm.rowPopover].intervalos.push(vm.intervalo);
            focus('btPopoverIntervalo' + vm.rowPopover);
            fecharPopover();
        }

        function fecharPopover() {
            bfc.Popover.closeAll();
        }

        function verificaFormulario() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }

        function refresh() {
            verificaFormulario();
            return Boolean(id) ?
                HorasAulaService.get(id).then(function (data) {
                    vm.horasAula = data;
                }) :

                HorasAulaService.getDefaultRepresentation().then(function (data) {
                    vm.horasAula = angular.extend(data, optionsCad);
                    vm.horasAula.itens = [];
                    addItem();
                });
        }

        function save(continuar) {

            vm.tracker.loading = continuar ? vm.tracker.saveAndAdd : vm.tracker.save;

            var horasAulasAjustado = ajustaCamposMultiplos(vm.horasAula);

            vm.tracker.loading.addPromise(
                HorasAulaService.save(horasAulasAjustado).then(function (data) {
                    if (angular.isFunction(fnCad)) {
                        fnCad(data);
                    }
                    $rootScope.$broadcast('refresh::horasAulas', {
                        data: data,
                        add: !id,
                        edit: Boolean(id)
                    });
                    onSuccess(continuar);
                })
            );

        }

        function remove() {

            vm.tracker.loading = vm.tracker.remove;

            vm.tracker.loading.addPromise(
                HorasAulaService.remove(vm.horasAula).then(function () {
                    $rootScope.$broadcast('refresh::horasAulas', {
                        data: vm.horasAula,
                        remove: true
                    });
                    fechaModal();
                })
            );
        }

        function onSuccess(continuar) {
            if (continuar) {
                id = null;
                vm.tracker.loading.addPromise(refresh());
                vm.aulasIntervalo = [];

                vm.intervaloSelected = [];
                focus('lbDescricaoHorasAulas');
                return;
            }
            fechaModal();
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function formatSelection(data) {
            var descriptionMin = !data.key ? data : data.key;

            return descriptionMin.slice(0, 3).toLowerCase();
        }

        function formatResult(data) {
            return data.description;
        }


        function filterResult(term, value) {
            var filtra = _.find(vm.daysSelected, function (dia) {
                return value.id === dia;
            });
            var valueMaisculo = value.description.toUpperCase();
            var pesquisa = term.toUpperCase();

            if (filtra) {
                return;
            }

            if (valueMaisculo.indexOf(pesquisa.trim()) === 0) {
                return value;
            }

        }

        function init() {
            var defered = $q.defer();
            vm.optionsPromisse = defered.promise;
            $modalTracker.addPromise(
                $q.all([
                    refresh(),
                    HorasAulaService.getMetadataEnums().then(function (enums) {
                        vm.diasDaSemanaOptions = enums.DiaDaSemanaDto;
                        vm.option.SituacaoCadastralSelect = CommonSelectEnum.select2Config({
                            values: enums.SituacaoCadastral.values,
                            description: 'description'
                        });
                    })
                ]).then(
                    function postInit() {
                        vm.daysSelected = [];
                        vm.formatSelection = formatSelection;
                        vm.filterResult = filterResult;

                        vm.optionsPromisse = $q.when(vm.diasDaSemanaOptions).then(
                            function (data) {
                                var index = 0;
                                angular.forEach(vm.horasAula.itens, function (item) {
                                    vm.daysSelected = _.union(vm.daysSelected, item.diasDaSemana);
                                    desabilitaItensDivergentes(item, index);
                                    index++;
                                });

                                return data;
                            }
                        );

                        vm.windowTitle = modo.adicionando ? 'Adicionando horas/aula por dia' : 'Editando horas/aula por dia';
                        angular.extend(vm, modo);
                        vm.dadosDisponiveisPraModal = true;
                    }
                    )
            );

        }
    }

})();

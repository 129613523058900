(function () {

    'use strict';

    angular.module('educacao.common').directive('appSelectAreaFormacaoPosGraduacaoInep', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.common.AreaFormacaoPosGraduacaoInep'];

    function Controller(AreaFormacaoPosGraduacaoInep) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = 'descricao like "' + params.term + '"';

            var originalTerm = decodeURI(params.term).replace(/%/g, '').trim();
            if (originalTerm && Number.isInteger(Number(originalTerm))) {
                filter += ' or codigoInep = ' + originalTerm;
            }
            
            return AreaFormacaoPosGraduacaoInep.getList(params.offset, params.limit, filter);
        }

        function formatResult(data) {
            return data.codigoInep + ' - ' + data.descricao;
        }
    }

})();
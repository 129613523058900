/**
 * Created by marlon.souza on 10/09/2015.
 */
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .service('educacao.matricula.InscricaoService', Service);

    Service.$inject = ['$injector'];
    function Service($injector) {
        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('matricula', 'inscricao', 'online')
            .enum('Raca', 'CorOlhos', 'TipoSanguineo', 'TipoFiliacao', 'HabilidadeMotora', 'OrientacaoSexual', 'EstadoCivil', 'GrauInstrucao', 'Sexo')
            .build();

        selfService.getProcessosVigentes = getProcessosVigentes;
        selfService.getCamposFormulario = getCamposFormulario;
        selfService.getCamposConfiguracao = getCamposConfiguracao;

        function getProcessosVigentes() {
            return selfService.getEndpoint().all('processos').getList();
        }

        function getCamposFormulario() {
            return selfService.getEndpoint().all('campos-formulario').all('todos').getList();
        }

        function getCamposConfiguracao(idConfiguracao) {
            return selfService.getEndpoint().one('campos-formulario', idConfiguracao).getList();
        }

        return selfService;
    }
})();

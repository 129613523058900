(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appDropdownContextoEstabelecimento', directive);

    directive.$inject = [];
    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/layout/header/context-bar/dropdown-contexto-estabelecimento.directive.html',
            replace: true,

            scope: {},

            controller: Contoller,
            controllerAs: 'vm'
        };
    }

    Contoller.$inject = [
        'CONTEXTO_ESTABELECIMENTO_SECRETARIA',
        '$scope',
        'promiseTracker',
        'educacao.context.ContextoEstabelecimento'
    ];
    function Contoller(CONTEXTO_ESTABELECIMENTO_SECRETARIA, $scope, promiseTracker, ContextoEstabelecimento) {
        var vm = this;

        vm.tracker = promiseTracker();

        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.estabelecimentos = [];

        vm.alterarEstabelecimentoAtual = alterarEstabelecimentoAtual;

        $scope.$on('app-context:estabelecimento:new', function () {
            vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento(); //|| CONTEXTO_ESTABELECIMENTO_SECRETARIA;
        });
        $scope.$on('app-context:estabelecimento:remove', function () {
            vm.estabelecimento = CONTEXTO_ESTABELECIMENTO_SECRETARIA;
        });

        loadEstabelecimentos();

        function loadEstabelecimentos() {
            vm.tracker.addPromise(
                ContextoEstabelecimento.listaEstabelecimentos()
                    .then(function (data) {
                        vm.estabelecimentos = data;
                    })
            );
        }

        function alterarEstabelecimentoAtual(estabelecimento) {
            ContextoEstabelecimento.setEstabelecimento(estabelecimento);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.avaliacao-externa').directive('appSelectAvaliacaoExterna', SelectAvaliacaoExterna);

    function SelectAvaliacaoExterna() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" />',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
            },
            controller: SelectAvaliacaoExternaController,
            controllerAs: 'vm'
        };
    }

    SelectAvaliacaoExternaController.$inject = ['educacao.avaliacao-externa.AvaliacaoExternaService'];
    function SelectAvaliacaoExternaController(service) {

        var vm = this;

        vm.search = search;

        function search(params) {
            var filter = '(descricao like "' + params.term + '")';
            return service.getAvaliacoes(params.offset, params.limit, filter);
        }

    }
})();

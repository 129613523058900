(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.ClassificacaoIndicadoresService', ClassificacaoIndicadoresService);

    ClassificacaoIndicadoresService.$inject = ['ServiceBuilder'];

    function ClassificacaoIndicadoresService(ServiceBuilder) {
        var createdService = ServiceBuilder.create()
            .path('matricula/configuracao-inscricao')
            .build();

        createdService.getIndicadores = getIndicadores;

        return createdService;

        function getIndicadores(id)  {
            return createdService.getEndpoint().one('processos', id).all('indicadores').getList();   
        }
                  
    }

})();

(function () {
    'use strict';

    angular.module('educacao.matriz')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider

            .state('app.matriz', {
                abstract: true,
                views: {
                    '@': {
                        templateUrl: 'planejamento/matriz/matriz-curricular.html'
                    }
                }
            })
            .state('app.matriz.matriz', {
                url: 'matrizes-curriculares',
                data: {
                    id: 'MatrizCurricularPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'planejamento/matriz/listagem-matriz-curricular.html',
                        controller: 'educacao.matriz.MatrizCtrl as vm'
                    }
                }
            });
    }

    /*.state('app.matriz.atitude', stateUrlView('atitudes', '', 'planejamento/matriz/atitude/atitudes.html'))
      .state('app.matriz.habilidade', stateUrlView('habilidades', '', 'planejamento/matriz/habilidade/habilidades.html'))
          .state('app.matriz.conhecimento', stateUrlView('conhecimentos', '', 'planejamento/matriz/conhecimento/conhecimentos.html'))
          .state('app.matriz.competencia', stateUrlView('competencias', '', 'planejamento/matriz/competencia/competencias.html'))
      function stateUrlView(urlData, viewNameData, templateData, controllerData) {
          var obj = {};

          obj.views = {};
          obj.views[viewNameData] = {};

          obj.url = urlData;
          obj.views[viewNameData].templateUrl = templateData;

          if (controllerData) {
              obj.views[viewNameData].controller = controllerData;
          }

          return obj;
      }*/
})();
(function () {
    'use strict';

    angular.module('app')
        .config(BannerConfig);

    BannerConfig.$inject = ['SHOW_BANNERS', '$uiBannersProvider', 'SYSTEM_ID'];

    function BannerConfig(SHOW_BANNERS, $uiBannersProvider, SYSTEM_ID) {
        if ('false' === SHOW_BANNERS || !SHOW_BANNERS) {
            return;
        }

        var banners = [
            {
                identificador: 'banner-instabilidade',
                dataInicio: '2023-12-01',
                dataFim: '2023-12-04',
                imageUrl: 'images/banner-instabilidade.png'
            }
        ];

        banners.forEach(addBanner);

        function addBanner(banner) {
            var current = moment();
            var dataInicio = moment(banner.dataInicio);
            var dataFim = moment(banner.dataFim);
            var height = banner.height || 450;
            var width = banner.width || 650;
    
            if (!current.isBefore(dataInicio, 'day') && !current.isAfter(dataFim, 'day')) {
                $uiBannersProvider.setSystemId(SYSTEM_ID);
                $uiBannersProvider.addBanner({
                    id: banner.identificador + '_' + dataInicio.format('YYYYMMDD') + '_' + dataFim.format('YYYYMMDD'),
                    template: getTemplate(),
                    controlUserStorage: true,
                    showOnEvent: 'educacao-context:resolved',
                    buttonsClasses: 'bg__gray--l20 tx__gray--d20',
                    height: height,
                    width: width
                });
            }

            function getTemplate() {
                if (banner.linkUrl) {
                    return (
                        '<a target="_blank" style="outline: none;" href="' +
                        banner.linkUrl +
                        '">' +
                        getTemplateImage() +
                        '</a>'
                    );
                }
                return getTemplateImage();
            }
    
            function getTemplateImage() {
                return (
                    '<img src="' + banner.imageUrl + '" height="' + height + '" width="' + width + '">'
                );
            }
        }
    }

})();

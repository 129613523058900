(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.UnidadesTematicasCtrl', Controller);

	Controller.$inject = [
		'promiseTracker',
		'educacao.matriz.UnidadeTematicaService',
		'$modalStates'
	];

	function Controller(promiseTracker, UnidadeTematicaService, $modalStates) {
		var vm = this;

		vm.remove = remove;
		vm.open = open;

		init();

		function init() {
			vm.unidadeTematicaService = UnidadeTematicaService;

			vm.tracker = {
				loading: promiseTracker()
			};
		}

		function open(item) {
			$modalStates.goModalState(item);
		}

		function remove(unidadeTematica) {
			return UnidadeTematicaService.remove(unidadeTematica);
		}
	}
})();

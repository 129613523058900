(function() {

    'use strict';

    angular.module('educacao.matricula').directive('appSelectMotivoDispensa', SelectMotivoDispensaDirective);
    SelectMotivoDispensaDirective.$inject = [];

    function SelectMotivoDispensaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['educacao.matricula.MotivosDeDispensaService','bfc.$$PermissionsService', 'ui.components.Modal'];

    function Controller(MotivosDispensaService, PermissionsService, $modal) {

        var vm = this;

        vm.search = search;
        vm.add = !PermissionsService.isRevokedOperation('MotivoDispensaPageMapping', 'criar') && add;

        function search(params) {
            return MotivosDispensaService.listByParams({
                filter: '(descricao like "' + params.term + '")',
                offset: params.offset,
                limit: params.limit
            });
        }

        function add(descricao) {
            return $modal.open({
                templateUrl: 'matricula/motivo-dispensa/motivos-dispensa.cad.html',
                controller: 'educacao.matricula.MotivosDeDispensaCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    motivo: {
                        descricao: descricao
                    }
                }
            }).result;
        }

    }
})();

(function () {
	'use strict';

	angular.module('educacao.matricula').controller('educacao.matricula.AgruparAlunosModalController', AgruparAlunosModalController);

	AgruparAlunosModalController.$inject = [
		'$modalInstance',
		'educacao.common.GrupoEnturmacaoService',
		'$enumsCache',
		'educacao.matricula.EnturmacaoService',
		'promiseTracker'
	];

	function AgruparAlunosModalController(
		$modalInstance,
		GrupoEnturmacaoService,
		$enumsCache,
		EnturmacaoService,
		promiseTracker
	) {
		var vm = this;

		vm.turma = $modalInstance.params.turma;	
		vm.enturmacoesAgrupadas = [];
		
		vm.save = save;
		vm.changeGrupoEnturmacao = changeGrupoEnturmacao;

		vm.tracker = {
			save: promiseTracker()
		};
		
		init();

		function init() {
			getConfiguracao();
			getEnums();
		}

		function separarenturmacoesGrupos() {
			_.forEach(vm.configuracaoTurma.grupoEnturmacaoItem, function(grupo) {
				vm.enturmacoesAgrupadas[grupo.id] = _.filter(vm.turma.enturmacoes, function(enturmacao) {
					return _.get(enturmacao, 'grupoEnturmacaoItem.id') === grupo.id;
				});
			});
			changeGrupoEnturmacao();
		}

		function getConfiguracao() {
			return GrupoEnturmacaoService
				.getGruposEstabelecimento(vm.turma.estabelecimento.id)
				.then(function (configuracao) {
					vm.configuracaoTurma = configuracao;
				})
				.then(separarenturmacoesGrupos);
		}

		function getEnums() {
			$enumsCache.get('GrupoEnturmacaoCor').then(function (enumData) {
				vm.GrupoEnturmacaoCor = enumData;
				_.forEach(vm.GrupoEnturmacaoCor.values, function(color) {
					color.hex = '#' + color.hex;
				});
			});
		}

		function save() {
			var enturmacoesAgrupadas = [];
			_.forEach(vm.configuracaoTurma.grupoEnturmacaoItem, function(grupo) {
				var enturmacoesGrupo = vm.enturmacoesAgrupadas[grupo.id];
				if (!_.isEmpty(enturmacoesGrupo)) {
					_.forEach(enturmacoesGrupo, function(enturmacao) {
						enturmacao.grupoEnturmacaoItem = grupo;
						enturmacoesAgrupadas.push(enturmacao);
					});
				}
			});
			var enturmacoesSemGrupo = [];
			_.forEach(vm.turma.enturmacoes, function(enturmacao) {
				if (!_.find(enturmacoesAgrupadas, { id: enturmacao.id })) {
					enturmacao.grupoEnturmacaoItem = null;
					enturmacoesSemGrupo.push(enturmacao);
				}
			});
			var todasEnturmacoes = enturmacoesAgrupadas.concat(enturmacoesSemGrupo);
			var promise = EnturmacaoService.agruparEnturmacoesAlunos(todasEnturmacoes).then($modalInstance.close);
			vm.tracker.save.addPromise(promise);
		}

		function changeGrupoEnturmacao() {
			vm.idsSelecionados = [];
			_.forEach(vm.configuracaoTurma.grupoEnturmacaoItem, function(grupo) {
				var enturmacoesGrupo = vm.enturmacoesAgrupadas[grupo.id];
				if (!_.isEmpty(enturmacoesGrupo)) {
					vm.idsSelecionados = vm.idsSelecionados.concat(_.pluck(enturmacoesGrupo, 'id'));
				}
			});
		}

	}
})();

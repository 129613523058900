(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('appMenuBreadcrumbs', directive);

    function directive() {
        return {
            restrict: 'E',
            template: '<ol class="breadcrumb"><li ng-repeat="crumb in vm.crumbs track by $index">{{crumb}}</li></ol>',
            replace: true,

            scope: {},

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$state', 'mainMenu'];

    function Controller($state, mainMenu) {
        var vm = this;
        vm.crumbs = [];

        addCrumb(getAction($state.$current));

        function getAction(currentState) {
            if (_.get(currentState.data, 'isModalState')) {
                return mainMenu.getMenuByAction(currentState.parent.name);
            }
            return mainMenu.getMenuByAction(currentState.name);
        }

        function addCrumb(menuItem) {
            if (menuItem === mainMenu) {
                return;
            }
            vm.crumbs.unshift(menuItem.label);
            if (menuItem.parent) {
                addCrumb(menuItem.parent);
            }
        }
    }
})();

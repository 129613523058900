(function() {
    'use strict';

    angular.module('educacao.geracaoHorarios').directive('uiDrag', DragDirective);

    DragDirective.$inject = [];

    function DragDirective() {
        return {
            restrict: 'A',
            controller: DragDirectiveController
        };
    }

    DragDirectiveController.$inject = ['$scope', '$element', '$attrs', '$rootScope', '$timeout'];

    function DragDirectiveController($scope, $element, $attrs, $rootScope, $timeout) {
        $timeout(function() {
            $scope.$watch(function() {
                return $scope.$eval($attrs.uiDrag);
            }, function(value) {
                if (value !== false) {
                    run();
                }
            });
        });

        function run() {

            $element.draggable({
                cursor: 'move',
                cursorAt: {
                    top: 0,
                    left: 21
                },
                containment: 'window',
                zIndex: $scope.$eval($attrs.dragZIndex) || 0,
                start: function() {
                    var dragStop = $scope.$eval($attrs.dragStop);
                    var dragObject = $scope.$eval($attrs.dragObject);

                    $rootScope.$broadcast('drag:start', {
                        dragObject: dragObject
                    });

                    return dragStop ? dragStop(dragObject) : angular.noop();
                },
                stop: function() {
                    var dragStop = $scope.$eval($attrs.dragStop);
                    var dragObject = $scope.$eval($attrs.dragObject);

                    $rootScope.$broadcast('drag:end', {
                        dragObject: dragObject
                    });

                    return dragStop ? dragStop(dragObject) : angular.noop();
                },
                helper: !$scope.$eval($attrs.dragHelper) ? 'clone' : helperFunction
            });

            function helperFunction() {
                var dragObject = $scope.$eval($attrs.dragObject);
                var helper = $scope.$eval($attrs.dragHelper);
                return helper ? $(helper(dragObject)) : undefined;
            }
        }

    }
})();


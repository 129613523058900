(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider'
    ];

    function stateConfig($stateProvider) {

        $stateProvider.state('app.lista-espera-novo', {
            url: 'lista-espera-novo'
        })
        .state('app.lista-espera-novo.encaminhado', {
            url: '/encaminhado',
            params: { 
                tela: 'ENCAMINHADO' 
            },
            data: {
                id: 'EncaminhamentoListaEsperaNovaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'lista-espera-new/lista-espera.html',
                    controller: 'educacao.lista-espera-novo.ListaEsperaCtrl',
                    controllerAs: 'vm'
                }
            }
        })
        .state('app.lista-espera-novo.configuracao', {
            url: '/configuracao',
            params: { 
                tela: 'CONFIGURACAO',
                abrirCadastro: false
            },
            data: {
                id: 'ConfiguracaoParaListaEsperaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'lista-espera-new/lista-espera.html',
                    controller: 'educacao.lista-espera-novo.ListaEsperaCtrl',
                    controllerAs: 'vm'
                }
            }
        })
        .state('app.lista-espera-novo.inscricao', {
            url: '/inscricao',
            params: { 
                tela: 'INSCRICAO',
                configuracao: ''
            },
            data: {
                id: 'InscricaoListaEsperaNovaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'lista-espera-new/lista-espera.html',
                    controller: 'educacao.lista-espera-novo.ListaEsperaCtrl',
                    controllerAs: 'vm'
                }
            }
        }).state('app.lista-espera-novo.anotacoes', {
            url: '/anotacoes',
            params: {
                tela: 'ANOTACOES',
                configuracao: ''
            },
            data: {
                id: 'AnotacaoInscricaoPageMapping',
            },
            views: {
                '@': {
                    templateUrl: 'lista-espera-new/inscricao/anotacoes/modal-anotacoes-inscrito/modal-anotacoes-inscrito.html',
                    controller: 'educacao.lista-espera-novo.anotacoes.ModalAnotacoesInscritos',
                    controllerAs: 'vm'
                }
            }
        });
    } 
})();

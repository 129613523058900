(function() {

    'use strict';

    angular.module('configuracao-nota-maxima')
        .service('configuracao-nota-maxima.ConfiguracaoNotaMaximaModalService', ConfiguracaoNotaMaximaModalService);

    ConfiguracaoNotaMaximaModalService.$inject = [
        'ui.components.Modal'
    ];

    function ConfiguracaoNotaMaximaModalService($modal) {
        var self = this;
        self.open = open;

        function open(turmas) {
         
            return $modal.open({
                templateUrl: 'matricula/turma/configuracao-nota-maxima/configuracao-nota-maxima.html',
                controller: 'configuracao-nota-maxima.ConfiguracaoNotaMaximaController',
                controllerAs: 'vm',
                params: {
                    turmas: turmas
                }
            });
        }
    }
})();

(function () {

    'use strict';

    angular.module('educacao.pessoas')
        .directive('appDetalhesFiliacao', Directve);

    function Directve() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/filiacoes/app-detalhes-filiacao.html',
            scope: {},
            bindToController: {
                filiacao: '=',
                method: '=',
                diasSemana: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'promiseTracker'
    ];

    function Controller(promiseTracker) {
        var vm = this;
        vm.tracker = promiseTracker();
        vm.detalhesFiliacao= [];
        vm.getDescricaoRetirarAluno = getDescricaoRetirarAluno;
        init();


        function init() {


            if (!vm.filiacao) {
                return;
            }
       		var filter = '( filiacao.id =' + vm.filiacao + ')';

            var params = {
                filter: filter
            };
            var promise = vm.method(params)
                .then(function (data) {
                    if (_.isEmpty(data)) {
                        vm.detalhesFiliacao = [];
                    }
                    vm.detalhesFiliacao = data;
                });

            vm.tracker.addPromise( promise);
        }

        function getDescricaoRetirarAluno(permiteRetiradaAluno) {
            if (_.isNull(permiteRetiradaAluno) || _.isUndefined(permiteRetiradaAluno)) {
                return '';
            }
            return permiteRetiradaAluno ? 'Sim:' : 'Não';
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .factory('educacao.enderecos.EnderecosService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {
        var self = ServiceBuilder.create();

        return self;
    }
})();
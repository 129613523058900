(function () {
    'use strict';

    var AGENDA_VIEW_WEEK = 'agendaWeek';
    var AGENDA_VIEW_MONTH = 'month';
    var AGENDA_VIEW_YEAR = 'year';
    var AGENDA_VIEW_PERSONALIZED = 'personalized';
    var AGENDA_VIEW_EVENTS = 'events';

    angular.module('educacao.calendario')
        .service('ViewController', ViewController);

    function ViewController() {
        return Constructor;
    }

    function Constructor() {
        var vm = this;

        vm.currentView = AGENDA_VIEW_WEEK;

        vm.isWeekView = isWeekView;
        vm.goToWeekView = goToWeekView;

        vm.isMonthView = isMonthView;
        vm.goToMonthView = goToMonthView;

        vm.isYearView = isYearView;
        vm.goToYearView = goToYearView;

        vm.isPersonalizedView = isPersonalizedView;
        vm.goToPersonalizedView = goToPersonalizedView;

        vm.isEventsView = isEventsView;
        vm.goToEventsView = goToEventsView;

        function isWeekView() {
            return vm.currentView === AGENDA_VIEW_WEEK;
        }

        function goToWeekView() {
            vm.currentView = AGENDA_VIEW_WEEK;
        }

        function isMonthView() {
            return vm.currentView === AGENDA_VIEW_MONTH;
        }

        function goToMonthView() {
            vm.currentView = AGENDA_VIEW_MONTH;
        }

        function isYearView() {
            return vm.currentView === AGENDA_VIEW_YEAR;
        }

        function goToYearView() {
            vm.currentView = AGENDA_VIEW_YEAR;
        }

        function isPersonalizedView() {
            return vm.currentView === AGENDA_VIEW_PERSONALIZED;
        }

        function goToPersonalizedView() {
            vm.currentView = AGENDA_VIEW_PERSONALIZED;
        }

        function isEventsView() {
            return vm.currentView === AGENDA_VIEW_EVENTS;
        }

        function goToEventsView() {
            vm.currentView = AGENDA_VIEW_EVENTS;
        }
    }
})();

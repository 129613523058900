(function() {
    'use strict';

    angular.module('educacao.common')
        .run(createMenus);

    createMenus.$inject = ['CriaMenusConfiguracao', 'CriaMenusAdministrando', 'CriaMenusPlanejando', 'CriaMenusExecutando'];

    function createMenus(criaMenusConfiguracao, criaMenusAdministrando, criaMenusPlanejando, criaMenusExecutando) {
        criaMenusConfiguracao();
        criaMenusAdministrando();
        criaMenusPlanejando();
        criaMenusExecutando();
    }
})();


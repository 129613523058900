(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('configuracaoInscricaoCriterios', configuracaoInscricaoCriterios);

    function configuracaoInscricaoCriterios() {

        var directiveConfig = {};

        directiveConfig.restrict = 'E';
        directiveConfig.require = 'ngModel';
        directiveConfig.templateUrl = 'matricula/configuracao-inscricao/criterios/configuracao-inscricao-criterios.directive.html';

        directiveConfig.scope = {};
        directiveConfig.scope.ngModel = '=';
        directiveConfig.scope.nivelEscolar = '=';

        directiveConfig.controller = ConfiguracaoInscricaoCriteriosCtrl;
        directiveConfig.controllerAs = 'vm';

        return directiveConfig;
    }

    ConfiguracaoInscricaoCriteriosCtrl.$inject = ['$injector', 'promiseTracker', '$q', '$scope', 'bfc.Notification'];

    function ConfiguracaoInscricaoCriteriosCtrl($injector, promiseTracker, $q, $scope, Notification) {

        var ConfiguracaoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoInscricaoService');

        var vm = this,
            defaultRepresentation;

        vm.CRITERIOS_CONFIG_INFANTIL = ['Zoneamento', 'Candidato possui irmão na escola pretendida'];
        vm.EDUCACAO_INFANTIL = 'EDUCACAO_INFANTIL';

        vm.loading = promiseTracker();
        vm.options = {};
        vm.options.CriteriosClassificacao = [];
        vm.criterios = $scope.ngModel;
        vm.transferCriterioClassificacao = transferCriterioClassificacao;
        vm.validateCriteriosEducacaoInfantil = validateCriteriosEducacaoInfantil;

        init();

        function init() {
            vm.loading.addPromise($q.all([
                ConfiguracaoInscricaoService.getCriterios().then(function(data) {
                    vm.options.CriteriosClassificacao = data.map(function(option) {
                        return {
                            id: null,
                            criterio: option,
                            ordem: null
                        };
                    });
                }),
                ConfiguracaoInscricaoService.getDefaultRepresentation().then(function(data) {
                    defaultRepresentation = data;
                })
            ]).then(function() {

                vm.ORDEM_INSCRICAO = _.first(defaultRepresentation.criterios);

                vm.sortableOptions = {
                    placeholder: 'panel-default',
                    connectWith: '.list-preferencias-horario',
                    stop: reviewCriterios
                };

                vm.sortableOptionsModel = angular.extend({
                    update: function(event, uiSortable) {
                        if (!uiSortable.item.sortable.received) {
                            var position = uiSortable.item.sortable.index;
                            if (vm.criterios[position].criterio.id === vm.ORDEM_INSCRICAO.criterio.id) {
                                Notification.publish('Não é possível alterar o critério "' + vm.ORDEM_INSCRICAO.criterio.descricao + '"', 'error');
                                uiSortable.item.sortable.cancel();
                            }
                        }
                    }
                }, vm.sortableOptions);

                formatCriteriosToView();
            }));
        }


        function formatCriteriosToView() {
            vm.options.CriteriosClassificacao = vm.options.CriteriosClassificacao.filter(function(criterioOption) {
                var existModel = vm.criterios.some(function(criterioDto) {
                    return criterioDto.criterio.id === criterioOption.criterio.id;
                });

                if (!existModel) {
                    return criterioOption;
                }
            });
        }

        function transferCriterioClassificacao(listCriterioA, listCriterioB, criterioToTransfer) {
            listCriterioA.forEach(function(criterioObject, index) {
                if (criterioToTransfer.criterio.id === criterioObject.criterio.id) {
                    listCriterioA.splice(index, 1);
                }
            });
            var ordem;
            listCriterioB.forEach(function(criterioObject) {
                if (criterioObject.criterio.id === 1) {
                    ordem = _.clone(criterioObject.ordem);
                    criterioObject.ordem++;
                }
            });
            listCriterioB.push({
                criterio: criterioToTransfer.criterio,
                ordem: ordem
            });
            reviewCriterios();
        }


        function reviewCriterios() {
            var indexOrdemInscricao = _.findIndex(vm.criterios, function(criterioDto) {
                return criterioDto.criterio.id === vm.ORDEM_INSCRICAO.criterio.id;
            });

            var criterioOrdemUsuario = vm.criterios[indexOrdemInscricao];

            vm.criterios.splice(indexOrdemInscricao, 1);
            vm.criterios.push(criterioOrdemUsuario);
        }

        function validateCriteriosEducacaoInfantil(descricaoCriterio) {
            if ($scope.nivelEscolar === vm.EDUCACAO_INFANTIL) {
                return true;
            }

            return !vm.CRITERIOS_CONFIG_INFANTIL.includes(descricaoCriterio);
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.CalendarioModalController', CalendarioModalController);

    CalendarioModalController.$inject = [
        'MINI_MONTH_DAY_CLICKED_EVENT',
        'EVENT_LIST_POPOVER_EDIT_EVENT',
        'EVENT_LIST_POPOVER_REMOVE_EVENT',
        'HOLYDAY_LIST_POPOVER_EDIT_EVENT',
        'HOLYDAY_LIST_POPOVER_REMOVE_EVENT',
        'OBSERVACAO_POPOVER_EDIT_EVENT',

        'DATE_FORMAT',

        '$scope',
        '$q',
        'bfc.Notification',
        'promiseTracker',
        'educacao.common.ModalCad',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'IdGenerator',
        'educacao.calendario.CalendarioEventoValidatorService',
        'calendarioDisplay',
        '$timeout',
        'educacao.calendario.CalendarioImpressaoService',
        'ui.components.Modal',
        'fnCad'
    ];

    function CalendarioModalController(MINI_MONTH_DAY_CLICKED_EVENT,
        EVENT_LIST_POPOVER_EDIT_EVENT,
        EVENT_LIST_POPOVER_REMOVE_EVENT,
        HOLYDAY_LIST_POPOVER_EDIT_EVENT,
        HOLYDAY_LIST_POPOVER_REMOVE_EVENT,
        OBSERVACAO_POPOVER_EDIT_EVENT,
        DATE_FORMAT,
        $scope,
        $q,
        Notification,
        promiseTracker,
        ModalCad,
        ContextoEstabelecimento,
        ContextoAnoLetivo,
        IdGenerator,
        calendarioEventoValidator,
        calendarioDisplay,
        $timeout,
        CalendarioImpressaoService,
        uiModal,
        fnCad
    ) {

        var vm = this;

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.renderConfig = {
            restrictInitialDate: undefined,
            restrictFinalDate: undefined,
            hideRestrictedDate: false
        };
        vm.tracker = promiseTracker();
        vm.trackerLoading = promiseTracker();

        vm.calendarioDisplay = calendarioDisplay;
        $scope.calendarioDisplay = vm.calendarioDisplay;
        vm.calendario = vm.calendarioDisplay.baseCalendar;

        vm.openEventoModal = openEventoModal;
        vm.openFeriadoModal = openFeriadoModal;

        vm.save = save;
        vm.saveAndPublish = saveAndPublish;
        vm.imprimir = CalendarioImpressaoService.imprimir.bind(null, '.modal-content');

        vm.onChangeData = onChangeData;

        bindEvents();

        createDateConfig();

        init();

        function bindEvents() {
            $scope.$on(EVENT_LIST_POPOVER_EDIT_EVENT, onEditEvent);
            $scope.$on(EVENT_LIST_POPOVER_REMOVE_EVENT, onRemoveEvent);

            $scope.$on(HOLYDAY_LIST_POPOVER_EDIT_EVENT, editHolyday);
            $scope.$on(HOLYDAY_LIST_POPOVER_REMOVE_EVENT, removeHolyday);

            $scope.$on(OBSERVACAO_POPOVER_EDIT_EVENT, onObservacaoEdited);

            $scope.$on(MINI_MONTH_DAY_CLICKED_EVENT, onMiniCalendarDayClicked);

            $scope.$watch('vm.calendario.feriados', vm.calendarioDisplay.process, true);
            $scope.$watch('vm.calendario.eventos', vm.calendarioDisplay.process, true);
            $scope.$watch('vm.calendario.excecoes', vm.calendarioDisplay.process, true);
        }

        function init() {
            setOldDates();
        }

        function onChangeData() {
            if(vm.oldDataInicial === vm.calendario.dataInicial &&
                vm.oldDataFinal === vm.calendario.dataFinal) {
                    return;
            }

            var promise = $timeout(function(){
                vm.calendarioDisplay.process();
            });

            vm.trackerLoading.addPromise(promise);

            vm.oldDataFinal = angular.copy(vm.calendario.dataFinal);
            vm.oldDataInicial = angular.copy(vm.calendario.dataInicial);
        }

        function setOldDates() {
            vm.oldDataFinal = angular.copy(vm.calendario.dataFinal);
            vm.oldDataInicial = angular.copy(vm.calendario.dataInicial);
        }

        function onEditEvent(event, evento) {
            bfc.Popover.closeAll();
            openEventoModal(evento);
        }

        function onRemoveEvent(event, evento) {
            bfc.Popover.closeAll();
            removeFromEventoList(evento);
        }

        function editHolyday(event, feriado) {
            bfc.Popover.closeAll();
            openFeriadoModal(feriado);
        }

        function onObservacaoEdited(event, observacao) {
            vm.calendario.observacao = observacao;
        }

        function removeHolyday(event, feriado) {
            bfc.Popover.closeAll();
            removeFromFeriadoList(feriado);
        }

        function createDateConfig() {
            var anoLetivo = Number(vm.anoLetivo);
            var calendarioDateConfig = {
                showDate: true,
                numberOfMonths: 1,
                yearRange: (anoLetivo - 1) + ':' + (anoLetivo + 1)

            };

            vm.dataInicialConfig = angular.extend({
                defaultDate: '01/02/' + vm.anoLetivo
            }, calendarioDateConfig);

            vm.dataFinalConfig = angular.extend({
                defaultDate: '01/12/' + vm.anoLetivo
            }, calendarioDateConfig);
        }

        function openFeriadoModal(feriado) {
            ModalCad.open({
                templateUrl: 'calendario/calendario-feriado/calendario-feriado-modal.html',
                controller: 'educacao.calendario.CalendarioSecretariaFeriadoModalController as vm',
                fnCad: function (changedHolyday, saved) {
                    return (saved ? onFeriadoSaved : removeFromFeriadoList)(changedHolyday);
                },
                resolve: {
                    feriado: feriado || null
                }
            });
        }

        function onFeriadoSaved(savedFeriado) {
            var found = _.find(vm.calendario.feriados, 'feriado.id', savedFeriado.feriado.id);
            if (found && !IdGenerator.equals(found, savedFeriado)) {
                Notification.publish('Este feriado já está associado ao calendário.', 'error');
                return $q.reject('Feriado duplicado');
            }
            IdGenerator.addOrUpdate(savedFeriado, vm.calendario.feriados);
            return $q.when(savedFeriado);
        }

        function removeFromFeriadoList(feriado) {
            IdGenerator.remove(feriado, vm.calendario.feriados);
            return $q.when(feriado);
        }

        function CadEventoModal(changedEvento, saved) {
            return (saved ? onEventoSaved : removeFromEventoList)(changedEvento);
        }

        function openEventoModal(evento) {
            uiModal.open({
                templateUrl: 'calendario/calendario-evento/calendario-evento-modal.html',
                controller: 'educacao.calendario.CalendarioEventoModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    fn: {
                        CadEventoModal: CadEventoModal
                    },
                    resolve: {
                        evento: evento || null
                    }
                },
            });
        }

        function onEventoSaved(changedEvent) {
            IdGenerator.generateId(changedEvent);

            var messages = calendarioEventoValidator.validate(vm.calendario, changedEvent);

            if (messages.length) {
                messages.forEach(function (message) {
                    Notification.publish(message, 'error');
                });
                IdGenerator.removeGeneratedId(changedEvent);
                return $q.reject(messages);
            }

            IdGenerator.addOrUpdate(changedEvent, vm.calendario.eventos);
            return $q.when(changedEvent);
        }

        function removeFromEventoList(eventoToDelete) {
            IdGenerator.remove(eventoToDelete, vm.calendario.eventos);
            return $q.when(eventoToDelete);
        }

        function save() {
            vm.tracker.addPromise(fnCad(vm.calendario, true).then($scope.$modalInstance.close));
        }

        function saveAndPublish() {
            vm.tracker.addPromise(fnCad(vm.calendario, false).then($scope.$modalInstance.close));
        }

        function onMiniCalendarDayClicked(event, dayInfo) {
            if (dayInfo.hasEvents || dayInfo.hasHolyday) {
                return;
            }

            var momentDate = moment(dayInfo.date, DATE_FORMAT);

            var excecao = _.find(vm.calendario.excecoes, function (ex) {
                return ex.data === dayInfo.date;
            });

            var isDiaSemanaLetivo = vm.calendarioDisplay.isDiaLetivo(momentDate);

            if (excecao) {
                IdGenerator.remove(excecao, vm.calendario.excecoes);
            }

            if (isOneDayBefore(dayInfo.date, vm.calendario.dataInicial)) {
                vm.calendario.dataInicial = dayInfo.date;
                onChangeData();
                return;
            }

            if (isOneDayAfter(dayInfo.date, vm.calendario.dataFinal)) {
                vm.calendario.dataFinal = dayInfo.date;
                onChangeData();
                return;
            }

            if (vm.calendario.dataInicial === vm.calendario.dataFinal) {
                return;
            }

            if (dayInfo.date === vm.calendario.dataInicial) {
                vm.calendario.dataInicial = momentDate.add(1, 'days').format(DATE_FORMAT);
                onChangeData();
                return;
            }

            if (dayInfo.date === vm.calendario.dataFinal) {
                vm.calendario.dataFinal = momentDate.subtract(1, 'days').format(DATE_FORMAT);
                onChangeData();
                return;
            }

            if (!vm.calendarioDisplay.isBetween(momentDate, vm.calendario.dataInicial, vm.calendario.dataFinal)) {
                return;
            }

            if (!excecao) {
                excecao = {
                    data: dayInfo.date,
                    letivo: !isDiaSemanaLetivo
                };
                IdGenerator.addOrUpdate(excecao, vm.calendario.excecoes);
            }

            vm.calendarioDisplay.process();
        }

        function isOneDayBefore(date1, date2) {
            return moment(date1, DATE_FORMAT).add(1, 'days').format(DATE_FORMAT) === date2;
        }

        function isOneDayAfter(date1, date2) {
            return moment(date1, DATE_FORMAT).subtract(1, 'days').format(DATE_FORMAT) === date2;
        }
    }
})();


(function() {
    'use strict';

    var UPDATE_EVENT = 'agenda-view.update';
    var CREATE_EVENT = 'agenda-view.create';

    angular.module('educacao.calendario')
        .constant('AGENDA_VIEW_UPDATE_EVENT', UPDATE_EVENT)
        .constant('AGENDA_VIEW_CREATE_EVENT', CREATE_EVENT)
        .directive('calendarioAgenda', CalendarioAgendaDirective);

    function CalendarioAgendaDirective() {
        return {
            restrict: 'E',
            scope: {
                calendarioDisplay: '=',
                view: '='
            },

            templateUrl: 'calendario/view-agenda/calendario-agenda.directive.html',

            controller: CalendarioAgendaController,
            controllerAs: 'vm'
        };
    }

    CalendarioAgendaController.$inject = [
        'DATE_TIME_FORMAT',
        'COLOR_CLASS_HOLYDAY',
        'COLOR_CLASS_WORKDAY_HOLYDAY',
        'EVENT_POPOVER_LIST_TEMPLATE',
        'EVENT_DEFAULT_TIMED_EVENT_DURATION',

        '$scope',

        'InstallDirective',
        'ColorHelper'
    ];

    function CalendarioAgendaController(DATE_TIME_FORMAT,
        COLOR_CLASS_HOLYDAY,
        COLOR_CLASS_WORKDAY_HOLYDAY,
        EVENT_POPOVER_LIST_TEMPLATE,
        EVENT_DEFAULT_TIMED_EVENT_DURATION,
        $scope,
        InstallDirective,
        colorHelper) {

        var vm = this;

        vm.agendaConfig = {
            defaultTimedEventDuration: EVENT_DEFAULT_TIMED_EVENT_DURATION,
            nextDayThreshold: '00:00:00',
            timeFormat: 'HH:mm',

            selectable: true,
            select: onSelection,

            eventDragStart: bfc.Popover.closeAll,

            eventRender: eventRender,
            eventDrop: onEventUpdated,
            eventResize: onEventUpdated
        };

        $scope.$watch('calendarioDisplay.processCount', function() {
            vm.entries = updateCalendarEvents().concat(updateCalendarHolydays());
        });

        function updateCalendarEvents() {
            return _.flatten($scope.calendarioDisplay.flatten.eventos).map(convertEventoToFullCalendarEvent);
        }

        function convertEventoToFullCalendarEvent(evento) {
            return {
                id: _.uniqueId('fce'),

                title: evento.evento.descricao,
                start: getDataHoraInicio(evento),
                end: getDataHoraFim(evento),
                className: getEventClass(evento.cor),
                editable: !evento.$$readonly,

                allDay: evento.diaInteiro,

                originalEvent: evento
            };
        }

        function getDataHoraInicio(evento) {
            return moment(evento.dataInicial, DATE_TIME_FORMAT);
        }

        function getDataHoraFim(evento) {
            if (!evento.dataFinal) {
                return;
            }

            var dataInicial = moment(evento.dataInicial, DATE_TIME_FORMAT);
            var dataFinal = moment(evento.dataFinal, DATE_TIME_FORMAT);
            if (evento.diaInteiro && dataFinal.isAfter(dataInicial, 'days')) {
                dataFinal.add(1, 'days');
            }
            return dataFinal;
        }

        function getEventClass(color) {
            return colorHelper.getTextColorClass(color, 10) + ' ' + colorHelper.getColorClass(color);
        }

        function updateCalendarHolydays() {
            return _.flatten($scope.calendarioDisplay.flatten.feriados).map(convertFeriadoToFullCalendarEvent);
        }

        function convertFeriadoToFullCalendarEvent(feriado) {
            return {
                id: _.uniqueId('fch'),
                title: feriado.feriado.descricao,
                start: feriado.feriado.dataFeriado,
                allDay: true,
                editable: false,

                className: getEventClass(feriado.letivo ? COLOR_CLASS_WORKDAY_HOLYDAY : COLOR_CLASS_HOLYDAY)
            };
        }

        function eventRender(event, element) {
            if (!event.originalEvent) {
                return;
            }

            var directiveAttrs = {
                'data-bf-popover': EVENT_POPOVER_LIST_TEMPLATE,
                'data-bf-popover-create-body': true
            };

            var newScopeValues = {
                events: [event.originalEvent]
            };

            InstallDirective.addDirective(element, directiveAttrs, $scope, newScopeValues);
        }

        function onEventUpdated(event) {
            var originalEvent = _.cloneDeep(event.originalEvent);

            originalEvent.dataInicial = event.start.format(DATE_TIME_FORMAT);
            originalEvent.dataFinal = getDataFim(event.start, event.end).format(DATE_TIME_FORMAT);

            originalEvent.diaInteiro = event.allDay;

            $scope.$apply(function() {
                $scope.$emit(UPDATE_EVENT, originalEvent, function() {
                    //Como é adicionado um tracker, o rollbackFunction não tem mais efeito
                    updateCalendarEvents($scope.calendarioDisplay.flatten.eventos);
                });
            });
        }

        function getDataFim(dataInicial, dataFinal) {
            if (!dataFinal || dataInicial.isSame(dataFinal)) {
                return dataInicial.clone().add(moment.duration(EVENT_DEFAULT_TIMED_EVENT_DURATION));
            }

            dataFinal = dataFinal.clone();
            if (!dataInicial.isSame(dataFinal, 'days') && !dataFinal.hasTime()) {
                dataFinal.subtract(1, 'days');
            }

            return dataFinal;
        }

        function onSelection(start, end) {
            var scaffold = {
                dataInicial: start.format(DATE_TIME_FORMAT)
            };

            if (end) {
                if (!end.hasTime()) {
                    end.subtract(1, 'days');
                }
                scaffold.dataFinal = end.format(DATE_TIME_FORMAT);
            }

            scaffold.diaInteiro = !start.hasTime();

            $scope.$emit(CREATE_EVENT, scaffold);
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.configuracaoGrupo')
        .config(ConfiguracaoGrupoStates);

    ConfiguracaoGrupoStates.$inject = ['$stateProvider'];

    function ConfiguracaoGrupoStates($stateProvider) {
        $stateProvider.state('app.configuracaoGrupo', {
            url: 'grupos',
            data: {
                id: 'GrupoEnturmacaoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'configuracao-grupos/configuracao-grupos.html'
                }
            }
        });
    }
})();
(function() {
    'use strict';

    angular.module('educacao.matricula')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {

        var STATE_MATRICULAS = 'app.matricula.matriculas';

        $stateProvider

            .state('app.matricula', {
                abstract: true,
                views: {
                    '@': {
                        templateUrl: 'matricula/matricula.html'
                    }
                }
            })
            .state('app.matricula.criterioClassificacao', {
                url: 'criterios-classificacao',
                data: {
                    id: 'CriterioClassificacaoPageMapping'
                },
                views: {
                    '': {
                        templateUrl: 'matricula/criterio-classificacao/criterio-classificacao.html'
                    }
                }
            })
            .state('app.matricula.horasaulas', {
                url: 'horas-aulas',
                data: {
                    id: 'ConfiguracaoHorasAulasPageMapping'
                },
                views: {
                    '': {
                        templateUrl: 'matricula/horas-aula/horas-aulas.html'
                    }
                }
            })
            .state('app.matricula.configuracaoAvaliacao', {
                url: 'configuracoes-avaliacoes',
                data: {
                    id: 'ConfiguracaoAvaliacaoPageMapping'
                },
                views: {
                    '': {
                        templateUrl: 'matricula/tipos-avaliacao/tipos.avaliacao.html'
                    }
                }
            })
            .state('app.matricula.cadastrarinscricao', {
                url: 'inscritos/{inscricao}',
                views: {
                    '': {
                        templateUrl: 'matricula/inscricao/cadastro-inscricao.html',
                        controller: 'educacao.matricula.CadastroInscricao as vm'
                    }
                },
                data: {
                    id: 'InscricaoMatriculaPageMapping'
                },
                params: {
                    inscricao: ''
                },
                resolve: {
                    inscricao: resolveInscricao
                }
            })
            .state('app.matricula.classificacaoInscritos', {
                url: 'classificacao-inscritos',
                data: {
                    id: 'InscricaoMatriculaPageMapping'
                },
                views: {
                    '': {
                        templateUrl: 'matricula/classificacao-inscritos/classificacao.inscritos.html'
                    }
                }
            })
            .state('app.matricula.vagas', {
                url: 'quadros-vagas',
                data: {
                    id: 'VagaPageMapping'
                },
                views: {
                    '': {
                        templateUrl: 'matricula/quadro-vagas-matriculas/quadro-vagas-matriculas.html'
                    }
                }
            })
            .state('app.matricula.atestado-de-vaga', {
                abstract: true,
                data: {
                    id: 'AtestadoVagaPageMapping',
                    mirrorOf: 'app.matricula.vagas'
                }
            })
            .state('app.matricula.turmamultisseriada', {
                abstract: true,
                data: {
                    id: 'TurmaMultisseriadaPageMapping',
                    mirrorOf: 'app.matricula.turma'
                }
            })
            .state('app.matricula.remanejamentointerno', {
                abstract: true,
                data: {
                    id: 'RemanejamentoInternoPageMapping',
                    mirrorOf: 'app.matricula.turma'
                }
            })
            .state('app.matricula.enturmacao', {
                abstract: true,
                data: {
                    id: 'EnturmacaoAlunoPageMapping',
                    mirrorOf: 'app.matricula.turma'
                }
            })
            .state('app.matricula.enturmacao-funcionario', {
                abstract: true,
                data: {
                    id: 'EnturmacaoFuncionarioPageMapping',
                    mirrorOf: 'app.matricula.turma'
                }
            })
            // .state('app.matricula.listaEspera', {
            //     url: 'lista-espera',
            //     data: {
            //         id: 'InscricaoListaEsperaPageMapping'
            //     },
            //     views: {
            //         '': {
            //             templateUrl: 'lista-espera/lista-espera.html',
            //             controller: 'educacao.lista-espera.ListaEsperaController',
            //             controllerAs: 'vm'
            //         }
            //     }
            // })
            .state(STATE_MATRICULAS, {
                url: 'matriculas',
                data: {
                    id: 'MatriculaPageMapping'
                },
                views: {
                    '': {
                        templateUrl: 'matricula/matriculas/matricula-list.html',
                        controller: 'educacao.matricula.MatriculaListController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state(STATE_MATRICULAS + STATES_ADICIONAR.state, {
                url: STATES_ADICIONAR.url,
                onEnter: openModalMatricula,
                data: {
                    isModalState: true
                }
            }).state(STATE_MATRICULAS + STATES_EDITAR.state, {
                url: STATES_EDITAR.url,
                onEnter: openModalMatricula,
                data: {
                    isModalState: true
                }
            })

            //TODO: Descomentar este código após realização dos merges referente a alteração da listagem de enturmações na matrícula
            .state(STATE_MATRICULAS + '.editardataenturmacao', {
                data: {
                    id: 'EnturmacaoAlunoPageMapping',
                    mirrorOf: 'app.matricula.matriculas'
                }
            })

            // stateUrlView('lista-espera', '', 'matricula/lista-espera/lista.espera.html')

            //TODO: Prototipos
            //stateUrlView('classificacao-inscritos', '', 'matricula/classificacao-inscritos/classificacao.inscritos.html'))
            .state('app.matricula.listaesperalocalizar', stateUrlView('lista-espera-localizar', '',
                'matricula/lista-espera/lista.espera.localizar.html'))
            .state('app.matricula.listaesperainscrevendo', stateUrlView('lista-espera-inscrevendo', '',
                'matricula/lista-espera/lista.espera.inscrevendo.html'))
            .state('app.matricula.listaesperafim', stateUrlView('lista-espera-fim', '', 'matricula/lista-espera/lista.espera.fim.html'));

        $stateProvider
            .state('app.matricula.parametros', stateUrlView('configuracao-inscricao', '',
                'matricula/configuracao-inscricao/configuracao-inscricao.html'))
            .state('app.matricula.inscricaoonline', stateUrlView('inscricao-online', '',
                'matricula/inscricao-online/inscricao-online.html')); // stateUrlView('inscritos/cadastrar', '', 'matricula/inscricao/cadastro-inscricao.html'));

    }

    function stateUrlView(urlData, viewNameData, templateData, controllerData) {
        var obj = {};

        obj.views = {};
        obj.views[viewNameData] = {};

        obj.url = urlData;
        obj.views[viewNameData].templateUrl = templateData;

        if (controllerData) {
            obj.views[viewNameData].controller = controllerData;
        }

        return obj;
    }

    resolveInscricao.$inject = ['$q', '$state', '$stateParams', 'bfc.$$PermissionsService',
        'educacao.matricula.InscricaoMatriculaService', 'contextos'];

    function resolveInscricao($q, $state, $stateParams, PermissionsService, InscricaoMatriculaService) {
        var inscricao = $stateParams.inscricao;
        var operation = inscricao ? 'editar' : 'criar';
        if (PermissionsService.isRevokedOperation('InscricaoMatriculaPageMapping', operation)) {
            $state.go('app');
            return $q.reject('sem permissão');
        }
        return InscricaoMatriculaService.get(inscricao);
    }

    openModalMatricula.$inject = [
        '$stateParams',
        'educacao.matricula.MatriculaService',
        '$modalStates'
    ];

    function openModalMatricula($stateParams, MatriculaService, $modalStates) {
        $modalStates.open({
            getMethod: MatriculaService.get,
            templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
            controller: 'educacao.matricula.MatriculaCadCtrl as vm',
            propertyName: 'matricula',
            size: 'xxl',
            stateParams: $stateParams
        });
    }

})();

(function () {
    'use strict';

    /**
     * Diretiva para encasular aba de matrículas do cadastro de funcionário
     * Parâmetros:
     * funcionário -> funcionário que está sendo cadastrado ou editado
     */

    angular.module('educacao.funcionario')
        .directive('appFuncionarioMatricula', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-matricula/app-funcionario-matricula.directive.html',
            scope: {},
            bindToController: {
                funcionario: '=',
                listaDesenturmar: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'ui.components.Modal'
    ];

    function Controller(uiModal) {

        var vm = this;

        vm.openCadastroMatricula = openCadastroMatricula;
        vm.removeMatricula = removeMatricula;
        vm.changeShowDetalheMatriculaDiretiva = changeShowDetalheMatriculaDiretiva;
        vm.calculaTotalHorasSemanais = calculaTotalHorasSemanais;

        function openCadastroMatricula(matricula, index) {
            vm.funcionario.matriculas = vm.funcionario.matriculas || [];
            uiModal.open({
                templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-matricula/cadastro-funcionario-matricula.html',
                controller: 'educacao.funcionario.CadastroFuncionarioMatriculaCtrl',
                controllerAs: 'vm',
                size: 'xl',
                params: {
                    funcionario: vm.funcionario,
                    matricula: matricula,
                    matriculas: vm.funcionario.matriculas,
                    listaDesenturmar: vm.listaDesenturmar,
                    indexItem: index
                }
            }).result.then(function (matriculaReturn) {
                if (!matriculaReturn) {
                    return;
                }

                adicionarMatricula(matricula, index, matriculaReturn);
            });

            _.forEach(vm.funcionario.matriculas, function (matriculaFunc) {
                _.forEach(matriculaFunc.locaisTrabalho, function (localTrabalho) {
                    if (!localTrabalho.estabelecimento) {
                        localTrabalho.estabelecimento = {
                            'descricao': 'Secretaria',
                            'isSecretaria': true
                        };
                    }
                });
            });
        }

        function removeMatricula(index) {
            vm.funcionario.matriculas.splice(index, 1);
        }

        function adicionarMatricula(matricula, index, newMatricula) {

            if (matricula) {
                vm.funcionario.matriculas[index] = newMatricula;
            } else {
                if (!vm.funcionario.matriculas) {
                    vm.funcionario.matriculas = [];
                }
                vm.funcionario.matriculas.push(newMatricula);
            }
        }

        function changeShowDetalheMatriculaDiretiva(matricula) {
            matricula.$$open = !matricula.$$open;
        }

        function calculaTotalHorasSemanais(matricula) {
            var horas = 0;
            var minutos = 0;
            var horasAdicionais = 0;
            matricula.cargaHorariaSemanalHoras = null;
            matricula.cargaHorariaSemanalMinutos = null;

            var locaisTrabalhoAberto = _.filter(matricula.locaisTrabalho, function(l){
                return !l.dataFinal;
            });

            _.forEach(locaisTrabalhoAberto, function(localTrabalho) {
                horas += parseInt(localTrabalho.cargaHorariaSemanalHoras);
                minutos += parseInt(localTrabalho.cargaHorariaSemanalMinutos);
            });

            if (minutos > 0) {
                horasAdicionais = Math.floor(minutos / 60);
                minutos = minutos % 60;
                horas = horas + horasAdicionais;
            }
            if (horas || minutos) {
                matricula.cargaHorariaSemanalHoras = horas;
                matricula.cargaHorariaSemanalMinutos = minutos;
            }

            return horas + 'h ' + minutos + 'm';
        }

    }
})();

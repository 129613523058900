(function() {
    'use strict';
    angular.module('educacao.desempenhoescolar')
        .directive('appSortHeaderIcon', directive);

    function directive() {
        return {
            restrict: 'E',

            replace: true,
            template: '<i class="fa pull-right" ng-class="vm.iconClass" ng-click="vm.click()"></i>',

            scope: {},
            bindToController: {
                ngModel: '=',
                sortProp: '@'
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;

        vm.iconClass = 'fa-sort';
        vm.click = click;

        $scope.$watch('vm.ngModel', setIcon, true);

        function setIcon() {
            var sortingByThis = _.get(vm.ngModel, 'expression') === vm.sortProp;
            var isReverse = _.get(vm.ngModel, 'reverse');

            if (!sortingByThis) {
                vm.iconClass = 'fa-sort';
            } else if (isReverse) {
                vm.iconClass = 'fa-sort-down';
            } else {
                vm.iconClass = 'fa-sort-up';
            }
        }

        function click() {
            var sortingByThis = _.get(vm.ngModel, 'expression') === vm.sortProp;
            var isReverse = _.get(vm.ngModel, 'reverse');

            vm.ngModel = {
                expression: vm.sortProp,
                reverse: sortingByThis ? !isReverse : false
            };
        }
    }
})();

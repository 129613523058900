(function () {
	'use strict';

	var moduloCommon = angular.module('educacao.matriz');

	moduloCommon.factory('educacao.matriz.MatrizAtualizarSituacaoTurmaService', ['Restangular',
		function (Restangular) {

            return {
                save: save
            };

			function save(data) {
				return Restangular.all('matriz/matriz-curricular')
					.one('carga-horaria')
					.one('atualizar-situacao-turma')
					.customPOST(data);
			}
		}
	]);
})();

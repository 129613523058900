(function () {
    'use strict';
  
    angular.module('educacao.ocupacao-aulas-quadro-informativo').config(stateConfig);
  
    stateConfig.$inject = [
      '$stateProvider'
    ];
  
    function stateConfig($stateProvider) {
      var STATE = 'app.planejamento.ocupacao-aulas-quadro-informativo';
      var STATE_VISAO_ESTABELECIMENTO = 'app.planejamento.ocupacao-aulas-quadro-informativo-visao-estabelecimento';
      var STATE_SALDO_DISCIPLINA_MOBILE = 'app.planejamento.ocupacao-aulas-saldo-disciplinas-mobile';

      $stateProvider.state(STATE, {
        url: 'quadro-informativo-ocupacao-aula',
        // data: {
        //   id: 'QuadroInformativoPageMapping',
        //   mirrorOf: 'app.planejamento.visao-geral-quadro-informativo'
        // },
        params: {
          estabelecimentoSelected: '',
          anoLetivoSelected:''
        },
        views: {
          '@': {
            templateUrl: 'quadro-informativo/ocupacao-aulas/ocupacao-aulas.html',
            controller: 'educacao.ocupacao-aulas-quadro-informativo.OcupacaoAulasQuadroInformativoController as vm',
          }
        }
      })
      .state(STATE_VISAO_ESTABELECIMENTO, {
          url: 'quadro-informativo-visao-estabelecimento/:estabelecimentoId',
          // data: {
          //   id: 'QuadroInformativoPageMapping',
          //   mirrorOf: 'app.planejamento.visao-geral-quadro-informativo'
          // },
          params: {
            estabelecimentoId: '',
            estabelecimentoSelected: '',
            anoLetivoSelected:''
          },
          views: {
              '@': {
                  templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/visao-estabelecimento.html',
                  controller: 'educacao.ocupacao-aulas-quadro-informativo.OcupacaoAulasVisaoEstabelecimentoController as vm',
              },
          },
      })
      .state(STATE_SALDO_DISCIPLINA_MOBILE, {
        url: 'quadro-informativo-saldo-disciplinas-mobile',
          params: {
            turmaSelecionada: '',
            estabelecimentoId: '',
            estabelecimentoNome: ''
          },
          views: {
              '@': {
                  templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-saldo-disciplina-mobile/app-saldo-disciplina-mobile.directive.html',
                  controller: 'educacao.ocupacao-aulas-quadro-informativo.SaldoDisciplinaMobileController as vm',
              },
          },
      });
    }
  })();
(function() {
    'use strict';

    angular.module('educacao.legislacao').config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.legislacao';
        $stateProvider.state(STATE, {
            url: 'legislacao',
            data: {
                id: 'LegislacaoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'legislacao/legislacao.html'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.legislacao.LegislacaoService',
            '$modalStates'
        ];

        function openModal($stateParams, LegislacaoService, $modalStates) {
            $modalStates.open({
                getMethod: LegislacaoService.get,
                templateUrl: 'legislacao/legislacao.cad.html',
                controller: 'educacao.legislacao.LegislacaoCadCtrl as vm',
                propertyName: 'legislacao',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.quadro-funcionarios-configuracao')
        .directive('appTableGrupoEducacaoBasica', appGruposDirective);

    function appGruposDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/configuracao/app-table-grupos/app-table-grupos-educacao-basica.directive.html',
            scope: {},
            bindToController: {
                etapa: '=',
                matrizCurricularId: '=',
                tipoOrganizacao: '=',
                isEditando: '=',
                tracker: '=',
                nivelModalidade: '=',
                nivelEscolar: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosUtils'
    ];

    function Controller($scope, QuadroFuncionariosUtils) {
        var vm = this;

        vm.getDescricaoItemCurricular = QuadroFuncionariosUtils.getDescricaoItemCurricular;
        vm.setDisciplinasEscolhidasPorEtapa = setDisciplinasEscolhidasPorEtapa;
        vm.addGrupo = addGrupo;
        vm.deleteGrupoEtapa = deleteGrupoEtapa;
        vm.getDescricaoGrupoWithoutFormatacao = getDescricaoGrupoWithoutFormatacao;
        vm.getNextSeqNomeGrupo = getNextSeqNomeGrupo;
        vm.validateQuantidadeDocentes = validateQuantidadeDocentes;
        vm.getPendenciasByEtapa = getPendenciasByEtapa;
        vm.canShowComponentCurricularToolTip = canShowComponentCurricularToolTip;

        vm.etapa.disciplinasEscolhidas = [];
        vm.componentesCurricularesEtapa = [];
        vm.etapasMatrizDisciplinas = [];
        

        init();

        $scope.$watch('vm.etapa.utilizaFormatacaoGrupo', watchFormatacaoGrupo);
        $scope.$watch('vm.matrizCurricularId', init);
        $scope.$watch('vm.etapa.hasReqError', updatePendenciasOnReqError);

        function init() {
            vm.seqNomeGrupo = getNextSeqNomeGrupo();

            if(vm.isEditando) {
                vm.componentesCurricularesOnEdit = getComponentesCurricularesOnEdit();
                var configComponentesCurricularesIds = _.map(vm.componentesCurricularesOnEdit, 'id').join(', ');

                var filter = 'idDisciplina in (' + configComponentesCurricularesIds + 
                            ') or idEixoTematico in (' + configComponentesCurricularesIds + 
                            ') or idCampoExperiencia in (' + configComponentesCurricularesIds + ')';

                var promiseEdit = QuadroFuncionariosUtils.getComponentesCurriculares(vm.etapa.etapaMatriz.id, vm.matrizCurricularId, filter)
                        .then(function(data) {
                            vm.isConfigComponentesDiffMatrizComponentes = isConfigComponentesDiffMatrizComponentes(vm.componentesCurricularesOnEdit, data);
                            vm.etapasMatrizDisciplinas = vm.isConfigComponentesDiffMatrizComponentes ? vm.componentesCurricularesOnEdit : data;
                            vm.componentesCurricularesEtapa = _.map(data, QuadroFuncionariosUtils.getItemOrganizacaoCurricularByEtapaMatriz);
                            
                            setConfigGroupsOnEdit(vm.etapasMatrizDisciplinas);
                            vm.tracker.isDisabled = false;
                        });

                vm.tracker.loading.addPromise(promiseEdit);
                return;
            }

            var promise = QuadroFuncionariosUtils.getComponentesCurriculares(vm.etapa.etapaMatriz.id, vm.matrizCurricularId)
                    .then(function(data) {
                        vm.etapasMatrizDisciplinas = data;
                        vm.componentesCurricularesEtapa = _.map(data, QuadroFuncionariosUtils.getItemOrganizacaoCurricularByEtapaMatriz);
            
                        setDisciplinasEscolhidasPorEtapa();
                        setFormatacaoGrupo();
                        vm.tracker.isDisabled = false;
                    });

            vm.tracker.loading.addPromise(promise);
        }
        
        function isConfigComponentesDiffMatrizComponentes(configComponentes, matrizEtapasDisciplinas) {
            var configComponentesIds = _.map(configComponentes, 'id');

            var matrizEtapasDisciplinasIds = _.map(
                                            _.map(matrizEtapasDisciplinas, QuadroFuncionariosUtils.getItemOrganizacaoCurricularByEtapaMatriz), 
                                            'id');

            return _.difference(configComponentesIds, matrizEtapasDisciplinasIds).length;
        }

        function setConfigGroupsOnEdit(data) {
            _.forEach(vm.etapa.configuracaoQuadroFuncionariosGrupos, function(grupo) {
                setGrupoEtapaMatrizDisciplinasOnEdit(data, grupo);
                setDisciplinasEscolhidasPorEtapa();
                setEtapaUtilizaFormatacaoGrupo();
            });
            
            setFormatacaoGrupo();

            if(!vm.etapa.utilizaFormatacaoGrupo) {
                vm.etapa.gruposNaoFormatados = _.cloneDeep(vm.etapa.configuracaoQuadroFuncionariosGrupos);
            }
        }

        function setGrupoEtapaMatrizDisciplinasOnEdit(etapasMatriz, grupo) {
            grupo.grupoEtapaMatrizDisciplinas = [];
            _.forEach(etapasMatriz, function(etapa) {
                _.forEach(grupo.configuracaoQuadroFuncionariosComponentes, function(componente) {
                    if(componente.itemOrganizacaoCurricular.id === QuadroFuncionariosUtils.getItemOrganizacaoCurricularByEtapaMatriz(etapa).id) {
                        grupo.grupoEtapaMatrizDisciplinas.push(etapa);
                    }
                });

            });
        }
        
        function setFormatacaoGrupo() {
            if(!vm.etapa.utilizaFormatacaoGrupo && _.isEmpty(vm.etapa.gruposNaoFormatados)) {
                vm.etapa.gruposNaoFormatados = QuadroFuncionariosUtils.setDefaultConfigGroups(vm.etapasMatrizDisciplinas);
                validateQuantidadeDocentes(false);
                return;
            }

            setGruposWithFormatacaoGrupo(vm.nivelModalidade,vm.nivelEscolar,vm.etapa.etapaMatriz.equivalenciasEnum);
            validateQuantidadeDocentes(true);
        }

        function watchFormatacaoGrupo() {
            setFormatacaoGrupo();

            if(!vm.tracker.loading.tracking()) {
                getPendenciasByEtapa();
            }
        }

        function deleteGrupoEtapa(indexGrupo) {
            var grupoToRemove = vm.etapa.configuracaoQuadroFuncionariosGrupos[indexGrupo];
            var componentesToRemove = _.map(grupoToRemove.grupoEtapaMatrizDisciplinas, 'id');

            vm.etapa.disciplinasEscolhidas = _.difference(vm.etapa.disciplinasEscolhidas, componentesToRemove);
            vm.etapa.configuracaoQuadroFuncionariosGrupos.splice(indexGrupo, 1);

            getPendenciasByEtapa();
            vm.seqNomeGrupo = getNextSeqNomeGrupo();
        }

        function setDisciplinasEscolhidasPorEtapa() {
            vm.etapa.disciplinasEscolhidas = [];

            _.forEach(vm.etapa.configuracaoQuadroFuncionariosGrupos, function(grupo) {
                var idsDisciplinasEscolhidas = _.map(grupo.grupoEtapaMatrizDisciplinas, 'id');
                vm.etapa.disciplinasEscolhidas = vm.etapa.disciplinasEscolhidas.concat(idsDisciplinasEscolhidas);
            });


            setGruposWithFormatacaoGrupo();
            vm.seqNomeGrupo = getNextSeqNomeGrupo();
        }

        function addGrupo() {
            vm.etapa.configuracaoQuadroFuncionariosGrupos.push({
                descricao: 'Grupo ' + vm.seqNomeGrupo.toString(),
                configuracaoQuadroFuncionariosComponentes: [],
                grupoEtapaMatrizDisciplinas: [],
                quantidadeDocentes: 1
            });

            vm.seqNomeGrupo++;
            validateQuantidadeDocentes(true);
            getPendenciasByEtapa();
        }

        function setGruposWithFormatacaoGrupo(nivelModalidade, nivelEscolar, equivalencia) {
            if(vm.etapa.utilizaFormatacaoGrupo) {
                
                if(
                    QuadroFuncionariosUtils.canSetGrupoUnico(nivelModalidade, nivelEscolar, equivalencia) &&
                    vm.etapa.configuracaoQuadroFuncionariosGrupos.length === vm.componentesCurricularesEtapa.length
                ){
                    setFormatacaoGrupoUnico();
                    return;
                }

                _.forEach(vm.etapa.configuracaoQuadroFuncionariosGrupos, function(grupo) {
                    if(_.isEmpty(grupo.grupoEtapaMatrizDisciplinas)) {
                        return;
                    }

                    grupo.configuracaoQuadroFuncionariosComponentes = [];

                    _.forEach(grupo.grupoEtapaMatrizDisciplinas, function(disciplina) {
                        grupo.configuracaoQuadroFuncionariosComponentes.push({ 
                            itemOrganizacaoCurricular: QuadroFuncionariosUtils.getItemOrganizacaoCurricularByEtapaMatriz(disciplina) 
                        });
                    });
                });

                vm.etapa.configuracaoQuadroFuncionariosGrupos = sortGrupos();
                return;
            }
        }

        function setFormatacaoGrupoUnico() {
            if(vm.isEditando) {
                return;
            }

            var configComponentes = [];
            vm.seqNomeGrupo = 1;
            vm.etapa.configuracaoQuadroFuncionariosGrupos = [];

            _.forEach(vm.componentesCurricularesEtapa, function(componenteFormatado) {
                configComponentes.push({
                    itemOrganizacaoCurricular: componenteFormatado
                });
            });

            vm.etapa.configuracaoQuadroFuncionariosGrupos.push({
                descricao: 'Grupo ' + vm.seqNomeGrupo.toString(),
                configuracaoQuadroFuncionariosComponentes: configComponentes,
                grupoEtapaMatrizDisciplinas: vm.etapasMatrizDisciplinas,
                quantidadeDocentes: 1
            });
            vm.seqNomeGrupo++;
        }

        function getComponentesCurricularesOnEdit(){
            var configComponentesCurricularesIds = [];

            _.forEach(vm.etapa.configuracaoQuadroFuncionariosGrupos, function(grupo) {
                configComponentesCurricularesIds = configComponentesCurricularesIds.concat(_.map(grupo.configuracaoQuadroFuncionariosComponentes, 'itemOrganizacaoCurricular'));
            });

            return configComponentesCurricularesIds;
        }

        function getNextSeqNomeGrupo() {
            var nextSeqValue = _.chain(vm.etapa.configuracaoQuadroFuncionariosGrupos)
                            .map('descricao')
                            .filter(function (descricao) {
                                return descricao.includes('Grupo');
                            })
                            .map(function (descricao) {
                                return _.last(descricao.split(' '));
                            })
                            .filter(function (seqValueNomeGrupo) {
                                return !isNaN(seqValueNomeGrupo);
                            })
                            .sort()
                            .last()
                            .parseInt()
                            .value() + 1;
            
            return isNaN(nextSeqValue) ? 1 : nextSeqValue;
        }

        function updatePendenciasOnReqError() {
            if(vm.etapa.hasReqError) {
                setDisciplinasEscolhidasPorEtapa();
                getPendenciasByEtapa();
            }
        }

        function setEtapaUtilizaFormatacaoGrupo() {
            if(vm.etapa.utilizaFormatacaoGrupo) {
                return;
            }

            vm.etapa.utilizaFormatacaoGrupo = _.some(vm.etapa.configuracaoQuadroFuncionariosGrupos, function(grupo) {
                return grupo.configuracaoQuadroFuncionariosComponentes.length > 1;
            });
        }

        function validateQuantidadeDocentes(utilizaFormatacaoGrupo) {
            var grupos = utilizaFormatacaoGrupo ? vm.etapa.configuracaoQuadroFuncionariosGrupos : vm.etapa.gruposNaoFormatados;

            grupos.forEach(function(grupo) {
                grupo.quantidadeDocentes = parseInt(grupo.quantidadeDocentes);
                grupo.isQuantidadeDocentesValid = grupo.quantidadeDocentes >= 1 && grupo.quantidadeDocentes <= 10;
            });
        }

        function getDescricaoGrupoWithoutFormatacao(grupo) {
            return vm.getDescricaoItemCurricular(grupo.configuracaoQuadroFuncionariosComponentes[0].itemOrganizacaoCurricular);
        }

        function getPendenciasByEtapa() {
            vm.etapa.pendencias = {
                pendenciasComponenteCurricular: 0,
                pendenciasForm: 0
            };
            
            var isEtapaGruposEmpty = _.isEmpty(vm.etapa.configuracaoQuadroFuncionariosGrupos);

            if(isEtapaGruposEmpty && vm.etapa.utilizaFormatacaoGrupo) {
                vm.etapa.pendencias.pendenciasForm++;
                return;
            } 

            var grupos = vm.etapa.utilizaFormatacaoGrupo ? vm.etapa.configuracaoQuadroFuncionariosGrupos : vm.etapa.gruposNaoFormatados;

            if(
                (vm.etapa.disciplinasEscolhidas.length !== vm.etapasMatrizDisciplinas.length && vm.etapa.utilizaFormatacaoGrupo) ||
                _.some(_.map(grupos, 'grupoEtapaMatrizDisciplinas'), _.isEmpty) && vm.etapa.utilizaFormatacaoGrupo
            ) {
                vm.etapa.pendencias.pendenciasComponenteCurricular++;
            }

            if(
                (_.some(_.map(grupos, 'descricao'), _.isEmpty) && vm.etapa.utilizaFormatacaoGrupo) || 
                _.some(grupos, 'isQuantidadeDocentesValid', false)
            ) {
                vm.etapa.pendencias.pendenciasForm++;
            }
        }

        function sortGrupos() {
            return vm.etapa.configuracaoQuadroFuncionariosGrupos.sort(function (a,b) {
                return a.descricao.localeCompare(b.descricao, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
        }

        function canShowComponentCurricularToolTip() {
            if(vm.etapa.pendencias) {
                return vm.etapa.pendencias.pendenciasComponenteCurricular;
            }
            
            return false;
        }
    }

})();
(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.matricula');

	moduloCommon.controller('educacao.matricula.MatriculasCtrl', ['$scope','educacao.common.ModalCad',
		function($scope,ModalCad) {
			$scope.openCadMatricula = function() {
				ModalCad.open({
					templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
					controller: 'educacao.matricula.MatriculaCadCtrl as vm'
				});
			};
		}
	]);

	moduloCommon.controller('educacao.matricula.MatriculasCtrlCadCtrl', [
		function() {}
	]);

})();
(function() {
    'use strict';

    angular.module('educacao.educacenso')
        .factory('educacao.educacenso.EducacensoInconsistenciaService', EducacensoInconsistenciaService);

    EducacensoInconsistenciaService.$inject = ['ServiceBuilder'];

    function EducacensoInconsistenciaService(ServiceBuilder) {
        var service = ServiceBuilder.create()
            .path('commons')
            .path('educacenso-inconsistencia')
            .build();

        service.inverterStatusConclusao = inverterStatusConclusao;
        
        function inverterStatusConclusao(inconsistenciaId){
            return service.getEndpoint()
                .one(inconsistenciaId.toString())
                .one('inverter-status-conclusao')
                .put();
        }
        
        return service;
    }
})();

(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.CampoExperienciasCtrl', CampoExperienciasController);

	CampoExperienciasController.$inject = [
		'promiseTracker',
		'educacao.matriz.CampoExperienciaService',
		'$modalStates'
	];

	function CampoExperienciasController(promiseTracker, CampoExperienciaService, $modalStates) {
		var vm = this;

		vm.remove = remove;
		vm.open = open;
		vm.search = search;

		init();

		function init() {
			vm.campoExperienciaService = CampoExperienciaService;
			vm.filter = {};
			vm.userListControls = {};

			vm.tracker = {
				loading: promiseTracker()
			};

			vm.userListCustomBuilder = {
				onBeforeBuild: function() {
					return 'database is not null';
				}
			};

			vm.columnsSearch = [
				{
					id: 'descricao',
					model: 'descricao',
					label: 'Descrição',
					filterable: true
				}
			];
		}

		function search() {
			vm.userListControls.search();
		}

		function open(item) {
			$modalStates.goModalState(item);
		}

		function remove(campoExperiencia) {
			return CampoExperienciaService.remove(campoExperiencia);
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', '$state', '$rootScope', 'APP_URL_BASE', '$timeout',];

    function HeaderController($scope, $state, $rootScope, APP_URL_BASE, $timeout) {
        var vm = this;
        vm.isPageVisaoGeral = false;
        vm.obj = null;

        vm.url = APP_URL_BASE;

        $rootScope.$$pinned = !!$rootScope.$$pinned;
        vm.urlVisaoGeral = 'common/layout/geral.html';

        vm.headerOptions = {
            hasOtherSystems: true,
            pinned: $rootScope.$$pinned
        };

        vm.isStateSelecao = isStateSelecao;
        vm.init = init;
        vm.init();

        function isStateSelecao() {
            return !$state.includes('app');
        }


        function init() {
            $timeout(function onInitView() {
                processAvisosAndRemoveDuplicates();
            });
        }

        function processAvisosAndRemoveDuplicates() {
            var toastElement = document.querySelector('bth-toast').shadowRoot;
            var toastItems = toastElement.querySelectorAll('bth-toast-item.hydrated');
            closeDuplicateAvisos(toastItems);
        }

        function closeDuplicateAvisos(toastItems) {
            var uniqueAvisos = [];
            toastItems.forEach(function closeDuplicateAviso(toast) {
                if (!uniqueAvisos.includes(toast.textContent)) {
                    uniqueAvisos.push(toast.textContent);
                } else {
                    closeAviso(toast);
                }
            });
        }

        function closeAviso(shadowRoot) {
            var closeButton = shadowRoot.querySelector('.bth-toast__close button');
            if (closeButton) {
                closeButton.click();
            }
        }

    }
})();

(function () {
    'use strict';
    angular.module('educacao.common').directive('tableHorasAulasDia', function () {

        var configuracaoDiretiva = {
            restrict: 'E',
            templateUrl: 'common/directives/templates/tableConfiguracaoHoraAulaTemplate.html',
            scope: {
                horasAulasDia: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            link: postLink
        };

        return configuracaoDiretiva;

    });

    function postLink(scope, element, attrs, controller) {
        scope.$on('refresh::horasAulas', function (event, args) {
            controller.refreshHorariosConfig(args.data);
        });

        scope.$watch('horasAulasDia', controller.refreshHorariosConfig);
    }

    Controller.$inject = ['$scope', '$q', 'educacao.common.TableBuilder', 'promiseTracker', 'educacao.matricula.HorasAulaService'];

    function Controller($scope, $q, TableBuilder, promiseTracker, HorasAulaService) {
        var enumDiaSemana;

        var vm = this;
        vm.tracker = {
            refreshHorariosConfig: promiseTracker()
        };

        vm.refreshHorariosConfig = refreshHorariosConfig;

        function refreshHorariosConfig(configSelected) {
            var listaHorarios = [];

            if (configSelected === null || angular.isUndefined(configSelected.itens) || configSelected.itens.length === 0) {
                vm.tableHorasAulasDia = listaHorarios;
                return;
            }

            vm.tracker.refreshHorariosConfig.addPromise(
                loadEnumDiasSemana().then(function () {
                    configSelected.itens.forEach(function (item) {
                        listaHorarios = _.union(listaHorarios, builderDiaHorario(item));
                    });
                }).then(function () {

                    listaHorarios = _.chain(listaHorarios)
                        .sortByAll(['diaValue', 'nroAula'])
                        .value();

                    vm.tableHorasAulasDia = TableBuilder.create()
                        .values(listaHorarios)
                        .header('descricaoDia')
                        .body('nroAula')
                        .build();
                })
            );
        }

        function loadEnumDiasSemana() {
            return HorasAulaService.getMetadataEnums().then(function (enums) {
                enumDiaSemana = {};
                _.forEach(enums.DiaDaSemanaDto, function (item) {
                    enumDiaSemana[item.key] = item;
                });
            });
        }

        function builderDiaHorario(item) {

            var intervalosOrdenados = item.intervalos.sort(getSortIntervalo());

            var listaAulas = [],
                aula;

            var horarioInicialDia = moment({
                hour: parseInt(item.horaInicio.substring(0, 2)),
                minute: parseInt(item.horaInicio.substring(3, 5))
            });

            var itensDuracaoHorasAula = _.sortByOrder(item.itensDuracao, ['nroAula'], ['asc']);

            var acresMinutesHorario = function (horario, minutos) {
                return moment(horario).add(minutos, 'minutes');
            };

            var criarAula = function (horarioInicio, dia, isIntervalo, nroAula, duracao) {
                var newAula = {};

                newAula.horarioInicio = moment(horarioInicio);
                newAula.horarioFinal = acresMinutesHorario(horarioInicio, duracao);
                newAula.dia = dia;
                newAula.diaValue = enumDiaSemana[dia].value;
                newAula.descricaoDia = enumDiaSemana[dia].description;
                newAula.isIntervalo = isIntervalo;
                newAula.nroAula = nroAula;

                return newAula;
            };

            item.diasDaSemana.forEach(function (diaAula) {

                var horarioInicialAula = moment(horarioInicialDia);

                var indexIntervalo = 0;

                itensDuracaoHorasAula.forEach(function (itemHoraAula) {

                    aula = criarAula(horarioInicialAula, diaAula, false, itemHoraAula.nroAula + indexIntervalo, itemHoraAula.tempoDuracaoAula);
                    listaAulas.push(aula);
                    horarioInicialAula = aula.horarioFinal;

                    if (indexIntervalo < intervalosOrdenados.length) {
                        if (intervalosOrdenados[indexIntervalo].nroAulaPrecedente === itemHoraAula.nroAula) {

                            var duracaoIntervalo = intervalosOrdenados[indexIntervalo].tempoDuracao;

                            indexIntervalo++;

                            aula = criarAula(horarioInicialAula, diaAula, true, itemHoraAula.nroAula + indexIntervalo, duracaoIntervalo);
                            listaAulas.push(aula);
                            horarioInicialAula = aula.horarioFinal;
                        }
                    }

                });

            });

            return listaAulas;

        }

        function getSortIntervalo() {

            return function (intervaloA, intervaloB) {
                if (intervaloA.nroAulaPrecedente > intervaloB.nroAulaPrecedente) {
                    return 1;
                }

                if (intervaloA.nroAulaPrecedente < intervaloB.nroAulaPrecedente) {
                    return -1;
                }

                return 0;
            };
        }
    }

})();

(function () {
    'use strict';

    angular.module('agenda')
        .directive('agendaEventSource', AgendaEventSource);

    function AgendaEventSource() {
        return {
            restrict: 'E',
            require:'^agenda',
            scope: {
                source: '='
            },
            link: postLink
        };
    }

    function postLink($scope,$element,$attrs, agendaController) {
        var debounced = _.debounce(sourceChanged, 100);
        $scope.$watch('source', debounced);

        function sourceChanged(newValue,oldValue){
            agendaController.onSourceChanged(newValue,oldValue);
            $scope.$apply();
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.geracaoHorarios')
        .directive('gradeHorariosGridDetail', gradeHorariosGridDetail);

    function gradeHorariosGridDetail() {
        return {
            restrict: 'E',
            templateUrl: 'geracao-horarios/grade-horarios-grid-detail/grade-horarios-grid-detail.directive.html',
            scope: {},
            bindToController: {
                grade: '='    
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    function Controller() {}
    
})();
(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('contextsSystems', directive);

    function directive() {
        return {
            restrict: 'E',

            templateUrl: 'common/layout/header/contexts-systems/contexts-systems.directive.html',
            replace: true,

            scope: {headerOptions: '='},

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'URL_SUITE_BETHA',
        '$scope',
        '$state',
        '$rootScope',
        'educacao.context.UserAccessService'
    ];

    function Controller(URL_SUITE_BETHA,
                        $scope,
                        $state,
                        $rootScope, 
                        UserAccessService) {

        var vm = this;

        vm.urlSuiteBetha = URL_SUITE_BETHA;

        vm.isMenuDisabled = isMenuDisabled;
        vm.isSelecaoEntidade = isSelecaoEntidade;
        vm.isSelecaoAnoLetivo = isSelecaoAnoLetivo;

        vm.pin = pin;

        init();

        function init(){
            vm.hashEntidade = UserAccessService.getHashAcesso() ? 
                ('/' + UserAccessService.getHashAcesso()) : '';
        }

        function isMenuDisabled(){
            return $scope.headerOptions.pinned &&!$scope.headerOptions.hasOtherSystems;
        }

        function isSelecaoEntidade() {
            return $state.is('selecao.entidade');
        }

        function isSelecaoAnoLetivo() {
            return $state.is('selecao.anoletivo');
        }

        function pin() {
            $rootScope.$$pinned = !$scope.headerOptions.pinned; 
            $scope.headerOptions.pinned = !$scope.headerOptions.pinned;        
        }
    }
})();
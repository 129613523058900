(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.EstabelecimentoService', EstabelecimentoService);

    EstabelecimentoService.$inject = ['ServiceBuilder', 'Restangular'];

    function EstabelecimentoService(ServiceBuilder, Restangular) {
        var service = ServiceBuilder.create()
            .path('pessoa/estabelecimento')
            .enum('Equipamento')
            .enum('MateriasDidaticos')
            .build();

        service.suggest = suggest;
        service.getCompletos = getCompletos;
        service.camposAdicionais = camposAdicionais;
        service.getPessoas = getPessoas;
        service.getFuncionarios = getFuncionarios;
        service.getTiposMatriculasConvenios = getTiposMatriculasConvenios;

        function suggest(cnpj) {
            return service.getEndpoint()
                .one('suggest')
                .get({
                    cnpj: cnpj
                });
        }

        function getCompletos(estabelecimento) {
            var estabelecimentoTerm = encodeURI('%' + estabelecimento + '%');

            var filter = '(' +
            '(nome like "' + estabelecimentoTerm + '")' +
            ' or (dependenciaAdministrativa like "' + estabelecimentoTerm + '")' +
            ' or (cnpj like "' + estabelecimentoTerm + '")' +
            ' or (situacaoFuncionamento like "' + estabelecimentoTerm + '")' +
            ' or (codigoInep like "' + estabelecimentoTerm + '")' +
            ' or (regulamentacaoAutorizacao like "' + estabelecimentoTerm + '"))"';

            return service.getEndpoint()
                .one('estabelecimentos')
                .get({
                    filter: filter
                });
        }

        function camposAdicionais(estabelecimentoId) {
            return Restangular.one('pessoa/estabelecimento/campos-adicionais', estabelecimentoId)
                .get()
                .then(Restangular.stripRestangular);
        }

        function getPessoas(pessoa) {
            pessoa = pessoa.replace(/\.|-|\//g, '');
            return Restangular.one('pessoa/pessoas/template/juridica')
                .get({
                    query: pessoa
                });
        }

        function getFuncionarios(offset, limit, filter) {
            var params = {    
                offset: offset,
                limit: limit,
                filter: filter
            };

            return service.getEndpoint().getList(params);
        }

        function getTiposMatriculasConvenios(offset, limit, filter, sort) {
            var params = {    
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            };
            
            return Restangular.one('pessoa/estabelecimento').customGET('tipo-matricula-convenio', params);
        }

        return service;
    }
})();
(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.prototipo');

    moduloCommon.controller('educacao.prototipo.MatriculasCtrl', ['$scope', '$timeout', 'educacao.common.ModalCad',
        function ($scope, $timeout, ModalCad) {

            $scope.btBoy = [
                {
                    nome: 'João da Silva',
                    df: [
                        {descricao: 'Auditiva'},
                        {descricao: 'Visual'}
                    ],
                    group: [
                        {turma: 'Turma A'}
                    ]
                }
            ];

            $scope.reloadCheck = function (chkg1) {
                $scope.chkg1 = chkg1;
            };

            $scope.reloadCheck2 = function (chkg2) {
                $scope.chkg2 = chkg2;
            };

            $scope.openReclassificacao = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.reclassificacao.cad.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openReadmitir = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.readmitir.cad.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openOutrosFiltros = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/outros-filtros.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openHistoricoCompleto = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/historico-completo.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openTransferencia = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.transferencia.cad.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openFalecimento = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.falecimento.cad.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openDeixouDeFrequentar = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.deixou.de.frequentar.cad.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openCancelamento = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.cancelamento.cad.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openRemanejar = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/remanejar.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.openRemanejamentoInterno = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/remanejamento-interno.html',
                    controller: 'educacao.prototipo.MatriculasCtrl'
                });
            };

            $scope.open = function () {
                ModalCad.open({
                    // templateUrl: 'prototipo/matriculas/matricula.cad.html',
                    templateUrl: 'prototipo/matriculas/aluno.todoscad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

        }]);

    moduloCommon.controller('prototipo.matricula.MatriculasCtrlCadCtrl', ['$scope', '$timeout', 'educacao.common.ModalCad',
        function ($scope, $timeout, ModalCad) {
            $scope.HorasAulas = {};

            $scope.situacaoUltimaMatricula = false;
            $scope.formaIngresso = false;
            $scope.transpEscolar = false;
            $scope.transpEscolar4 = false;
            $scope.etapaInep = false;

            $scope.transpEscolarDefici = true;
            $scope.transpEscolarDefici2 = false;

            $scope.openReclassificacao = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.reclassificacao.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openRemanejamentoInterno = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/remanejamento-interno.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openTransferencia = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.transferencia.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openFalecimento = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.falecimento.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openDeixouDeFrequentar = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.deixou.de.frequentar.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openCancelamento = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.cancelamento.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openNovaMatricula = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/matricula.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openObs = function () {
                ModalCad.open({
                    templateUrl: 'planejamento/alunos/popups/aluno.cad.observacoes.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openReclassificacao = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/movimentacoes/movimentacao.reclassificacao.cad.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openImpressao = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/matricula.cad.regular.impressao.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.openMovimentacoesContrib = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/modal-movimentacoes.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

            $scope.anoLetivoVisible = function (value) {
                if (value === '6') {
                    $scope.situacaoUltimaMatricula = true;
                    $scope.colMaiorSituacaoMatricula = 'col-md-3';
                } else {
                    $scope.situacaoUltimaMatricula = false;
                    $scope.colMaiorSituacaoMatricula = 'col-md-4';
                }
            };

            $scope.formaIngressoVisible = function (value) {
                if (value === '5') {
                    if ($scope.transpEscolarPoder === true) {
                        $scope.transpEscolarVeiculo = false;
                        $scope.transpEscolar4 = true;
                        $scope.formaIngresso = true;
                    } else {
                        $scope.formaIngresso = true;
                    }
                } else {
                    if ($scope.transpEscolarPoder === true) {
                        $scope.transpEscolarVeiculo = true;
                        $scope.transpEscolar4 = false;
                        $scope.formaIngresso = false;
                    } else {
                        $scope.formaIngresso = false;
                    }
                }
            };

            $scope.transpEscolarVisible = function (value) {
                if (value === '2') {
                    if ($scope.formaIngresso === true) {
                        $scope.transpEscolar4 = true;
                        $scope.transpEscolarPoder = false;
                        $scope.transpEscolarVeiculo = false;
                    } else {
                        $scope.transpEscolarPoder = true;
                        $scope.transpEscolarVeiculo = true;

                        $scope.transpEscolarDefici = false;
                        $scope.transpEscolarDefici2 = true;
                    }
                } else {
                    if ($scope.formaIngresso === true) {
                        $scope.transpEscolar4 = false;
                        $scope.transpEscolarPoder = false;
                        $scope.transpEscolarVeiculo = false;
                    } else {
                        $scope.transpEscolarPoder = false;
                        $scope.transpEscolarVeiculo = false;

                        $scope.transpEscolarDefici = true;
                        $scope.transpEscolarDefici2 = false;
                    }
                }
            };

            $scope.etapaEnsinoVisible = function (value) {
                if (value === '1') {
                    $scope.turmaUnificada = true;
                    $scope.colMenor = 'col-md-4';
                } else {
                    $scope.turmaUnificada = false;
                    $scope.colMenor = 'col-md-6';
                }

                if (value === '3') {
                    $scope.etapaInep = true;
                } else {
                    $scope.etapaInep = false;
                }
            };

            $scope.tipoOrigemDesabled = true;
            $scope.tipoOrigem = function () {
                $scope.tipoOrigemDesabled = false;
            };

            $scope.openAlertaPendencias = function () {
                ModalCad.open({
                    templateUrl: 'prototipo/matriculas/matricula.cad.alerta.pendencias.html',
                    controller: 'prototipo.matricula.MatriculasCtrlCadCtrl'
                });
            };

        }]);
})();
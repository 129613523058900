(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios')
        .directive('appGeracaoHorariosVisaoGeralConfiguracao', GeracaoHorariosVisaoGeralConfiguracaoDirective);
        
    function GeracaoHorariosVisaoGeralConfiguracaoDirective() {
        return {
            restrict: 'E',
            templateUrl: 'geracao-horarios/visao-geral/configuracao.directive.html',
            scope: {},
            bindToController: {
                gradeHorarios: '='
            },
            controller: GeracaoHorariosVisaoGeralConfiguracaoDirectiveController,
            controllerAs: 'vm'
        };
    }

    GeracaoHorariosVisaoGeralConfiguracaoDirectiveController.$inject = ['$scope', 'educacao.common.$commons'];
    function GeracaoHorariosVisaoGeralConfiguracaoDirectiveController($scope, $commons) {

        var vm = this;

        vm.editAction = editAction;
        vm.viewGrade = false;

        function editAction() {
            $commons.$modal.open({
                templateUrl: 'geracao-horarios/geracao-horarios.cad.html',
                controller: 'educacao.geracaoHorarios.GeracaoHorariosCadController',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    quadroHorarios: vm.gradeHorarios,
                    buildingQuadro: true
                }
            });
        }
    }

})();

(function () {

    'use strict';

    angular.module('educacao.pessoas')
        .directive('appDiasSemana', Directve);

    function Directve() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/responsaveis/app-dias-semana.html',
            scope: {},
            bindToController: {
                diasEnum: '=',
                diasFormatados: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'promiseTracker',
        '$enumsCache'
    ];

    function Controller(promiseTracker) {
        var vm = this;
        vm.tracker = promiseTracker();
        vm.dias = [];
        vm.todos = '';
        angular.forEach(vm.diasEnum, function(diaEnum) {
            angular.forEach(vm.diasFormatados, function(diaFormatado) {
                if(diaEnum === diaFormatado.key){
                    vm.dias.push(diaFormatado.description);
                }
            });
            if(vm.dias.length === 7){
                vm.todos = 'Todos';
                vm.dias = [];
            }
        });
    }
})();
(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.matriz');




	moduloCommon.controller('educacao.matriz.MatrizAddOutraSequenciaCtrl', ['$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
		'educacao.common.ModalCad',
		'fnCad',
		'educacao.matriz.MatrizService',
		'id',
		'optionsCad',
		'promiseTracker',
		
		function($scope, $rootScope, $q, $modalInstance, $modalTracker, 
			ModalCad,
			fnCad,
			MatrizService,
			id,
			optionsCad,
			promiseTracker
		) {


			$scope.tracker = {};
			$scope.tracker.save = promiseTracker();

			init();

			function init(){
				$modalTracker.addPromise(
					MatrizService.get(id).then(function(data) {
						$scope.matriz = data;

					})
				);
			}

			$scope.$on('refreshEtapasMatriz', function() {
				init();
			});

			$scope.$on('refreshMatrizCurricular', function() {
				init();
			});


			$scope.save = function(){
				$scope.tracker.save.addPromise(
					MatrizService.sequenciaOutra($scope.matriz.id, $scope.matriz.etapas).then(function(data){
						$rootScope.$broadcast('refreshMatrizCurricular', {
							data: data,
							edit: true
						});
						$scope.cancel();
					})
				);

				
			};

			$scope.cancel = function(){
				$modalInstance.close();
			};


		}]);

		
})();	
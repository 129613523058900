(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('observerDropdown', ['$timeout', function ($timeout) {
            return {
                restrict: 'A',
                scope: {
                    dropdownStateCallback: '&'
                },
                link: function (scope, element) {
                    var previousIsHidden = element.hasClass('dropdown-menu') && element.css('display') === 'none';

                    function checkDropdownState() {
                        var isHidden = element.hasClass('dropdown-menu') && element.css('display') === 'none';
                        if (isHidden !== previousIsHidden) {
                            if (isHidden) {
                                scope.dropdownStateCallback({ isOpen: false });
                            } else {
                                scope.dropdownStateCallback({ isOpen: true });
                            }
                            previousIsHidden = isHidden;
                        }
                        $timeout(checkDropdownState);
                    }

                    checkDropdownState();
                }
            };
        }]);
})();

(function() {
    'use strict';

    angular.module('educacao.configuracaoInstrumentosAvaliacao')
        .controller('educacao.configuracaoInstrumentosAvaliacao.ConfiguracaoInstrumentosAvaliacaoCtrl',
            ConfiguracaoInstrumentosAvaliacaoCtrl);

    ConfiguracaoInstrumentosAvaliacaoCtrl.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.configuracaoInstrumentosAvaliacao.ConfiguracaoInstrumentosAvaliacaoService',
        'educacao.context.ContextoEstabelecimento',
        'TIPOS_CONFIGURACOES_INSTRUMENTOS_AVALIACAO',
        'bfc.Notification'
    ];

    var urlConfiguracaoEstabelecimento = 'configuracoes-estabelecimentos';
    var urlConfiguracaoSecretaria = 'configuracoes-secretarias';
    var urlConfiguracao = '';

    function ConfiguracaoInstrumentosAvaliacaoCtrl($scope,
        promiseTracker,
        ConfiguracaoInstrumentosAvaliacaoService,
        ContextoEstabelecimento,
        TIPOS_CONFIGURACOES_INSTRUMENTOS_AVALIACAO,
        Notification) {

        var vm = this;

        vm.onClickMenus = onClickMenus;
        vm.saveConfiguracao = saveConfiguracao;
        vm.restaurarPadroes = restaurarPadroes;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimento = ContextoEstabelecimento.getValorContextoEstabelecimento();

        vm.tracker = {
            getDadosConfig: promiseTracker(),
            salvarRestaurar: promiseTracker()
        };

        vm.tiposConfiguracoes = TIPOS_CONFIGURACOES_INSTRUMENTOS_AVALIACAO;

        inicializarConfiguracao();

        function onClickMenus(keyMenu) {
            vm.tiposConfiguracoes[keyMenu].onClick();
        }

        function inicializarConfiguracao() {
            angular.forEach(vm.tiposConfiguracoes, function(tipoConfig) {
                tipoConfig.onClick = function() {
                    getConfiguracao(tipoConfig.value);
                };
            });

            if (vm.isSecretaria) {
                urlConfiguracao = urlConfiguracaoSecretaria;
            } else {
                urlConfiguracao = urlConfiguracaoEstabelecimento;
            }
        }

        function getConfiguracao(tipoConfiguracao) {

            var promise = ConfiguracaoInstrumentosAvaliacaoService.getConfiguracao(urlConfiguracao, tipoConfiguracao)
                .then(function(dados) {
                    vm.configuracao = dados;

                    validaConfiguracao(vm.configuracao);
                });

            vm.tracker.getDadosConfig.addPromise(promise);
        }

        function saveConfiguracao(configuracao) {
            var promiseSalvar = ConfiguracaoInstrumentosAvaliacaoService.saveConfiguration(configuracao)
                .then(function(dadosPersistidos) {
                    vm.configuracao = dadosPersistidos;
                    Notification.publish('Configuração de instrumentos de avaliação salva com sucesso', 'success');
                }, function() {
                    Notification.publish('Erro ao salvar configuração de instrumentos de avaliação', 'error');
                });

            vm.tracker.salvarRestaurar.addPromise(promiseSalvar);
        }

        function restaurarPadroes() {
            var tipoConfiguracao = vm.configuracao.tipoConfiguracaoInstrumento;
            var promiseRestaurar = ConfiguracaoInstrumentosAvaliacaoService.getConfiguracao(urlConfiguracaoSecretaria, tipoConfiguracao)
                .then(function(dadosDefault) {
                    vm.configuracaoDefault = dadosDefault;
                    validaConfiguracao(vm.configuracaoDefault);
                    vm.configuracaoDefault.id = vm.configuracao.id;
                    vm.configuracao = vm.configuracaoDefault;
                });

            vm.tracker.salvarRestaurar.addPromise(promiseRestaurar);
        }

        function validaConfiguracao(configuracao) {

            if (_.isNull(configuracao.permiteUtilizarCompetencias)) {
                configuracao.permiteUtilizarCompetencias = false;
            }

            if (_.isNull(configuracao.permiteUtilizarConhecimentosConteudos)) {
                configuracao.permiteUtilizarConhecimentosConteudos = false;
            }

            if (_.isNull(configuracao.permiteUtilizarHabilidadesCapacidades)) {
                configuracao.permiteUtilizarHabilidadesCapacidades = false;
            }

            if (_.isNull(configuracao.permiteUtilizarAtitudes)) {
                configuracao.permiteUtilizarAtitudes = false;
            }

            if (_.isNull(configuracao.permiteUtilizarParecer)) {
                configuracao.permiteUtilizarParecer = false;
            }
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.ConfiguracaoTurmasService', ConfiguracaoTurmasService);

    ConfiguracaoTurmasService.$inject = ['$injector','Restangular'];

    function ConfiguracaoTurmasService($injector,Restangular) {

        var GenericService = $injector.get('GenericService');

        var service = GenericService
            .createWithCustomMethods({
                getConfiguracaoValida: configuracaoValida,
                getConfiguracaoSecretaria: configuracaoSecretaria,
                getConfiguracao: configuracao,
                getConfiguracaoEstabelecimento: configuracaoEstabelecimento,
                getConfiguracaoOrdenacaoTurma: getConfiguracaoOrdenacaoTurma
            })
            .module({
                uri: 'matricula'
            })
            .service({
                uri: 'configuracao-turma'
            });

        function configuracaoValida() {
            service.validateObject();

            return service.modules().one(service._service.uri + '/configuracao-valida').get();
        }

        function configuracaoSecretaria() {
            service.validateObject();
            return service.modules().one(service._service.uri + '/configuracao-secretaria').get();
        }

        function configuracao(isEstabelecimento) {
            if (isEstabelecimento) {
                return configuracaoValida();
            } else {
                return configuracaoSecretaria();
            }
        }
        
        function configuracaoEstabelecimento(id) {
          return  Restangular.all('matricula').one('configuracao-turma', id).one('configuracao-estabelecimento').get();
        }

        function getConfiguracaoOrdenacaoTurma() {
            return  Restangular.all('matricula').one('configuracao-turma').one('configuracao-ordenacao-turma').get();
        }

        return service;
    }
})();



(function() {
    'use strict';

    angular.module('educacao.pessoas').directive('appSelectAluno', SelectAlunoDirective);

    function SelectAlunoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" debounce-delay="1000" minimum-input-length="3" ng-disabled="ngDisabled" search="vm.searchAluno" add-value="vm.openCadAluno" format-result="vm.formatResult(data)" format-selection="vm.formatResultSelect(data)"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?'
            },
            controller: Controller,
            controllerAs: 'vm',
        };
    }

    Controller.$inject = ['$scope', 'ui.components.Modal', 'bfc.$$PermissionsService', 'educacao.pessoas.AlunoService', '$filter'];

    function Controller($scope, $modal, PermissionsService, service, $filter) {

        var vm = this;

        vm.searchAluno = searchAluno;
        vm.formatResult = formatResult;
        vm.formatResultSelect = formatResultSelect;
        vm.openCadAluno = !PermissionsService.isRevokedOperation('AlunoPageMapping', 'criar') && openCadAluno;

        var templateAluno = _.template('<%= nome %> <br><small> CPF: <%= cpf %> | DATA DE NASCIMENTO: <%= dataNascimento %> | RG: <%= rg %> | NOME DA MÃE: <%= nomeMae %> </small>');

        function searchAluno(params) {
            var termDecode = decodeURI(params.term );
            termDecode = termDecode.replace(/%/, ' ').trim();
            termDecode = encodeURI(termDecode);

            var filter = '(nome like "' + params.term + '" or rg like "' + termDecode + '" or cpf like "' + termDecode + '"';

            if ($scope.multiple) {
                var alunoIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(aluno) {
                        alunoIds.push(aluno.id);
                    });
                }
                if (alunoIds.length) {
                    filter += ' and id not in(' + alunoIds.join() + ')';
                }
            }
            filter += ')';

            return service.getBuscaAlunos(filter, params.offset, params.limit);
        }


        function formatResult(alunoFiliacao) {
            if (!alunoFiliacao.nome){
                return undefined;
            }

            return templateAluno({
                nome: alunoFiliacao.nome,
                cpf: $filter('bfMaskCPF')(alunoFiliacao.cpf),
                rg: alunoFiliacao.rg,
                dataNascimento: $filter('bfDate')(alunoFiliacao.dataNascimento),
                nomeMae: alunoFiliacao.filiacao
            });
        }

        function formatResultSelect(alunoFiliacao) {
            return alunoFiliacao.descricao || alunoFiliacao.nome;
        }

        function openCadAluno(nome) {
            return $modal.open({
                templateUrl: 'planejamento/alunos/cadastro-aluno.html',
                controller: 'educacao.pessoas.AlunoCadCtrl as vm',
                size: 'xl',
                params: {
                    aluno: {
                        pessoa: {
                            nome: nome
                        }
                    },
                    canSaveAdd: false
                }
            }).result;
        }
    }
})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaModalidadeNiveis', appConfiguracaoListaEsperaModalidadeNiveis);

    function appConfiguracaoListaEsperaModalidadeNiveis() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-modalidade-niveis/app-configuracao-lista-espera-modalidade-niveis.directive.html',
            scope: {},
            bindToController: {
                niveisModalidades: '=',
                tabNivelAtiva: '=',
                tabModalidadeAtiva: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.tabActive = tabActive;
        vm.incompleto = incompleto;
        vm.showDiretivaNivelEscolar = showDiretivaNivelEscolar;

        function incompleto(nivelModalidade) {
            var niveisEscolaresAtivos = _.filter(nivelModalidade.listNivelEscolar, 'ativo');
            return (nivelModalidade.ativo && _.get(_.find(niveisEscolaresAtivos, 'formularioInvalido'), 'formularioInvalido'));
        }

        function tabActive(nivelModalidade) {
            if (!nivelModalidade.ativo) {
                vm.tabModalidadeAtiva = '';
                return;
            }
            vm.tabModalidadeAtiva = nivelModalidade.modalidade;
        }

        function showDiretivaNivelEscolar() {
            return (vm.tabModalidadeAtiva === 'BASICA' ||
                vm.tabModalidadeAtiva === 'ACELERACAO' ||
                vm.tabModalidadeAtiva === 'EJA');
        }
    }

})();

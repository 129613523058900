(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncaoGratificadaService', Service);

    Service.$inject = ['$injector'];


    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');


        var selfService = ServiceBuilder.create()
            .path('funcionario', 'funcao-gratificada')
            .enum('TipoFuncao')
            .build();



        return selfService;
    }
})();

(function() {

    'use strict';

    var NUMERICO = 'NUMERICO';
    var TIPO_DECIMAIS_ZERO_OU_CINCO = 'ZERO_OU_CINCO';

    angular.module('configuracao-nota-maxima')
        .controller('configuracao-nota-maxima.ConfiguracaoNotaMaximaController', ConfiguracaoNotaMaximaController);

    ConfiguracaoNotaMaximaController.$inject = [
        'promiseTracker',
        '$modalInstance',
        'bfc.Notification',
        'educacao.matricula.TurmaService',
        'configuracao-nota-maxima.ConfiguracaoNotaMaximaService'
    ];

    function ConfiguracaoNotaMaximaController(promiseTracker, $modalInstance, bfcNotification, TurmaService,
        ConfiguracaoNotaMaximaService) {
        var vm = this;

        vm.onClickSave = onClickSave;
        vm.isNotaRequired = isNotaRequired;

        vm.tracker = {
            save: promiseTracker(),
            modal: promiseTracker()
        };

        vm.turmas = $modalInstance.params.turmas || [];
        vm.isConfiguracaoIgual = true;
        vm.showWarning = false;
        vm.hasNotaNumerica = true;
        vm.decimaisValidos = [];

        init();

        function init() {
            vm.$$turma = _.first(vm.turmas);
            vm.estabelecimento = vm.$$turma.estabelecimento;

            vm.hasConfiguracaoAvaliacao = Boolean(vm.$$turma.configuracaoAvaliacao);

            loadConfiguracaoAvaliacao();
            loadPeriodosAvaliativos();
        }

        function loadConfiguracaoAvaliacao() {
            if (!vm.hasConfiguracaoAvaliacao) {
                return;
            }

            var promise = TurmaService.getConfiguracaoAvaliacao(vm.$$turma.id)
                .then(setConfiguracaoAvaliacao);
            vm.tracker.modal.addPromise(promise);
        }

        function setConfiguracaoAvaliacao(data) {
            vm.configuracaoAvaliacao = data;

            if (!_.find(vm.configuracaoAvaliacao.tiposAvaliacoes, _.matches(NUMERICO))) {
                vm.warnMessage = 'A configuração de avaliação da(s) turma(s) não permite nota numérica';
                vm.showWarning = true;
                vm.hasNotaNumerica = false;
                return;
            }

            vm.quantidadeInteiros = _.size(String(vm.configuracaoAvaliacao.notaMaxima));
            vm.quantidadeDecimais = getQuantidadeDecimais(vm.configuracaoAvaliacao.quantidadeDecimais);
            vm.decimaisValidos = getDecimaisValidos();
        }

        function loadPeriodosAvaliativos() {
            var promise = TurmaService.getPeriodosAvaliativos(vm.$$turma.id)
                .then(setPeriodosAndLoadNotas);

            vm.tracker.modal.addPromise(promise);
        }

        function setPeriodosAndLoadNotas(data) {
            vm.periodos = data;
            loadTurmasPeriodosAvaliativos();
        }

        function loadTurmasPeriodosAvaliativos() {
            var filter = 'turma in (' + _.pluck(vm.turmas, 'id') + ')';
            var promise = ConfiguracaoNotaMaximaService.getTurmasPeriodosAvaliativos(filter)
                .then(onLoadTurmasPeriodosAvaliativos);
            vm.tracker.modal.addPromise(promise);
        }

        function onLoadTurmasPeriodosAvaliativos(data) {
            if (_.isEmpty(data)) {
                return;
            }

            var turmasPeriodos = _.map(data, mapTurmaPeriodoAvaliativo);

            var notasPorPeriodo = _.chain(turmasPeriodos)
                .groupBy('periodoAvaliativo.id')
                .value();

            vm.isConfiguracaoIgual = _.chain(notasPorPeriodo)
                .map(mapEqualNotas)
                .every(Boolean)
                .value();

            if (vm.isConfiguracaoIgual) {
                _.map(vm.periodos, mapNotaMaximaPeriodo.bind(null, notasPorPeriodo));
            } else {
                vm.warnMessage = 'As configurações das turmas selecionadas estão diferentes.';
                vm.showWarning = true;
            }
        }

        function mapTurmaPeriodoAvaliativo(turmaPeriodo) {
            return {
                notaMaxima: turmaPeriodo.notaMaxima,
                periodoAvaliativo: {
                    id: turmaPeriodo.periodoAvaliativo.id
                }
            };
        }

        function mapNotaMaximaPeriodo(notasPorPeriodo, periodo) {
            var notaPeriodo = _.first(notasPorPeriodo[periodo.id]);
            periodo.$$notaMaxima = notaPeriodo.notaMaxima;
        }

        function mapEqualNotas(notas) {
            return _.every(notas, _.matches(_.first(notas)));
        }

        function getQuantidadeDecimais(qtdDecimais) {
            switch (qtdDecimais) {
                case 'UMA':
                    return 1;
                case 'DUAS':
                    return 2;
                default:
                    return 0;
            }
        }

        function getDecimaisValidos() {
            if (TIPO_DECIMAIS_ZERO_OU_CINCO === vm.configuracaoAvaliacao.decimaisAvaliacao) {
                return [_.padRight('0', vm.decimais, '0'), _.padRight('5', vm.decimais, '0')];
            }
            return undefined;
        }

        function onClickSave() {
            var configuracaoPeriodos = {
                turmas: vm.turmas,
                configuracoes: buildConfiguracoesArray()
            };

            var promise = ConfiguracaoNotaMaximaService.save(configuracaoPeriodos)
                .then(onSaveSuccess);

            vm.tracker.save.addPromise(promise);
        }

        function buildConfiguracoesArray() {
            return _.map(vm.periodos, function(periodo) {
                return {
                    periodoAvaliativo: periodo,
                    notaMaxima: periodo.$$notaMaxima
                };
            });
        }

        function onSaveSuccess() {
            bfcNotification.publish('Configuração de notas criada com sucesso', 'success');
            $modalInstance.close();
        }

        function isNotaRequired() {
            return _.find(vm.periodos, function(periodo) {
                return Boolean(periodo.$$notaMaxima);
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.programasocial')
        .controller('educacao.programasocial.ProgramaSocialCadCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        'bfc.Notification',
        '$modalInstance',
        'bfc.Focus',
        '$scope',
        'educacao.programasocial.ProgramaSocialService'
    ];

    function Controller(
        promiseTracker,
        Notification,
        $modalInstance,
        focus,
        $scope,
        ProgramaSocialService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.save = save;
        vm.remove = remove;
        vm.cancel = fechaModal;
        vm.programaSocial = $modalInstance.params.programaSocial || {};
        init();

        function init() {
            verificaFormulario();
            vm.adicionando = $modalInstance.params.canSaveAdd;
            vm.editando = _.get($modalInstance.params.programaSocial, 'id');
            vm.windowTitle = vm.editando ? 'Editando programa social' : 'Adicionando programa social';
            focus('lbDescricaoProgramaSocial');

        }

        function save(continuar) {
            vm.tracker.loading.addPromise(
                ProgramaSocialService.save(vm.programaSocial).then(function(data) {
                    vm.programaSocial = data;
                    onSuccess(continuar);
                })
            );
        }

        function remove() {
            vm.tracker.loading.addPromise(
                ProgramaSocialService.remove(vm.programaSocial).then($modalInstance.close)
            );
        }

        function onSuccess(continuar) {
            Notification.publish('Programa social salvo com sucesso', 'success');
            if (continuar) {
                verificaFormulario();
                vm.programaSocial = {};
                focus('lbDescricaoProgramaSocial');
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.programaSocial);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.rematricula')
        .directive('resumoRematricula', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'rematricula/rematricular-etapa/resumo-rematricula.directive.html',
            scope: {
                rematricula: '='
            }
        };
    }
})();


(function(){
    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao', [])
        .constant('INSCRICAO_TO_MATRICULA', {
            BASICA: 'EDUCACAO_BASICA',
            COMPLEMENTAR: 'COMPLEMENTAR',
            EJA: 'EJA',
            ACELERACAO: 'ACELERACAO',
            ATIVIDADES_COMPLEMENTARES: 'ATIVIDADE_COMPLEMENTAR',
            ATIVDADES_AEE: 'AEE'
        })
        .constant('PASSOS_CADASTRO_INSCRICAO', {
            FORMULARIO: 1,
            MODALIDADES: 2,
            CRITERIOS: 3
        });

})();
(function() {

    'use strict';

    angular.module('educacao.matricula').directive('appSelectEtapaMatriz', SelectEtapaMatrizDirective);

    SelectEtapaMatrizDirective.$inject = [];

    function SelectEtapaMatrizDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" ng-disabled="ngDisabled" search="vm.search" />',
            scope: {
                ngModel: '=?',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                matriz: '=',
                estabelecimento: '=?',
                tipoAtendimento: '=?',
                apenasPossuamTurmas: '=?',
                dropdownCssClass: '=?'
            },

            compile: setMultiple,

            controller: SelectEtapaMatrizDirectiveController,
            controllerAs: 'vm'
        };
    }

    function setMultiple(element, attrs) {
        if (attrs.hasOwnProperty('multiple')) {
            element.find('ui-select').attr('multiple', 'true');
        }
    }

    SelectEtapaMatrizDirectiveController.$inject = ['$scope', 'educacao.matriz.EtapaService'];

    function SelectEtapaMatrizDirectiveController($scope, EtapaMatrizService) {

        var vm = this;

        vm.search = search;

        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange(newValue, oldValue);
            }
        });

        function search(params) {
            var filter  = '';
            if ($scope.apenasPossuamTurmas) {
                filter = '(identificacao like "' + params.term + '")';
                return EtapaMatrizService.getPossuamTurmas($scope.matriz.id, $scope.estabelecimento.id, $scope.tipoAtendimento,
                    params.offset, params.limit, filter);
            }

            return EtapaMatrizService.getList($scope.matriz && $scope.matriz.id || '0',filter).then(function(list) {
                return {
                    content: list,
                    hasNext: false
                };
            });
        }

    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula').directive('appProgressBar', ProgressBarCtrl);

    function ProgressBarCtrl() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/app-card-turma/app-progress-bar.directive.html',
            scope: {
                filled: '=',
                total: '=',
                ngIf: '=?',
                inverter: '='
            },
            link: function($scope) {
                $scope.$watchGroup(['filled', 'total'], function() {
                    if (!$scope.total) {
                        setToGreen();
                        return;
                    }

                    var total = $scope.total;
                    var filled = $scope.filled || 0;

                    $scope.percentual = filled * 100 / total;
                    if ($scope.percentual === 100) {
                        if ($scope.inverter) {
                            return setToGreen();
                        }
                        setToRed();
                    } else if ($scope.percentual > 79) {
                        setToYellow();
                    } else {
                        if ($scope.inverter) {
                            return setToRed();
                        }
                        setToGreen();
                    }
                });

                function setToGreen() {
                    $scope.textColorClass = 'tx__green';
                    $scope.barColorClass = 'bg__green';
                }

                function setToYellow() {
                    $scope.textColorClass = 'tx__yellow--d10';
                    $scope.barColorClass = 'bg__yellow--d10';
                }

                function setToRed() {
                    $scope.textColorClass = 'tx__red';
                    $scope.barColorClass = 'bg__red';

                }
            }
        };
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matricula')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.matricula.aaee';
        $stateProvider.state(STATE, {
            url: 'atividades-aee',
            data: {
                id: 'AtividadeAeePageMapping'
            },
            views: {
                '': {
                    templateUrl: 'matricula/aaee/aaee.html'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.matricula.AtividadeAEEService',
            '$modalStates'
        ];

        function openModal($stateParams, AtividadeAEEService, $modalStates) {
            $modalStates.open({
                getMethod: AtividadeAEEService.getById,
                templateUrl: 'matricula/aaee/aaee.cad.html',
                controller: 'educacao.matricula.AeeCadCtrl as vm',
                propertyName: 'atividadeAAEE',
                size: 'lg',
                stateParams: $stateParams
            });
        }
    }

})();

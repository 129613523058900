(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizCadCtrl', ['$scope', '$rootScope', '$q', '$modalInstance', '$modalTracker',
		'educacao.matriz.MatrizService',
		'educacao.common.ObjectUtilsService',
		'fnCad',
		'id',
		'optionsCad',
		'promiseTracker',
		'readOnly',
		'bfc.Focus',
        'educacao.common.CommonSelectEnum',
		function ($scope, $rootScope, $q, $modalInstance, $modalTracker, MatrizService,
            ObjectUtilsService,
            fnCad,
            id,
            optionsCad,
            promiseTracker,
            readOnly,
            focus,
            CommonSelectEnum
		) {
            /**
             * Tracker for promise
             */
            var vm = this;
            var _select2 = {};
            var _option = {};

            vm.radioModel = {
                left: false,
                middle: true,
                right: false
            };

            vm.checkModel = {
                a: false,
                b: true
            };

            vm.tracker = {};
            vm.tracker.saveContinue = promiseTracker();
            vm.tracker.save = promiseTracker();
            vm.tracker.remove = promiseTracker();
            vm.option = _option;
            vm.select2 = _select2;

            /**
             */
            vm.modo = {
                adicionando: !id && !readOnly,
                adicionandoNovaOpcao: Boolean(fnCad),
                editando: id && !readOnly,
                visualizando: id && readOnly
            };


            init();

            function init() {
                $modalTracker.addPromise(
                    $q.all([
                        (function () {
                            if (vm.form) {
                                vm.form.$setPristine();
                            }
                        })(),
						MatrizService.getTypes().then(function (data) {
                            $scope.matrizProperties = data.MatrizCurricularDto;
                        }),
						MatrizService.getEnums().then(function (enums) {
                            _select2.NivelEscolar = CommonSelectEnum.select2Config({
                                values: enums.NivelEscolar,
                                description: 'description'
                            });
                            _select2.NivelModalidade = CommonSelectEnum.select2Config({
                                values: enums.NivelModalidade,
                                description: 'description'
                            });
                            _select2.identificacaoEtapa = CommonSelectEnum.select2Config({
                                values: enums.TipoIdentificacaoEtapa,
                                description: 'description'
                            });
                        }),
                        refresh()
					])
                );
            }


            vm.cursoSelectChange = function () {
                var data = vm.matriz.curso;
                if (data && data.nivelEscolar) {
                    vm.matriz.etapaInicial = data.nivelEscolar.etapaInicial;
                    vm.matriz.etapaFinal = data.nivelEscolar.etapaFinal;
                } else {
                    vm.matriz.etapaInicial = undefined;
                    vm.matriz.etapaFinal = undefined;
                }
            };

            function refresh() {
                if ($scope.form) {
                    $scope.form.$setPristine();
                }

                return Boolean(id) ?
                    MatrizService.get(id).then(function (data) {
                        vm.matriz = data;
                    }) :
                    MatrizService.getDefault().then(function (data) {
                        vm.matriz = angular.extend(data, optionsCad);
                    });
            }

            function onSuccess(continuar, tracker) {
                if (continuar) {
                    id = null;
                    tracker.addPromise(refresh());
                    focus('lbDescricaoMatriz');
                } else {
                    $scope.cancel();
                }
            }

            $scope.cancel = function () {
                $modalInstance.close();
            };

            $scope.remove = function () {
                $scope.tracker.remove.addPromise(
                    MatrizService.remove($scope.matriz.id).then(function () {
                        $rootScope.$broadcast('refreshMatrizCurricular', {
                            data: $scope.matriz,
                            remove: true
                        });

                        onSuccess();
                    })
                );
            };

            vm.save = function (continuar) {
                var tracker = continuar ? vm.tracker.saveContinue : vm.tracker.save;

                if (vm.matriz.curso.nivelEscolar !== 'EDUCACAO_INFANTIL') {
                    vm.matriz.tipoOrganizacao = null;
                }

                tracker.addPromise(MatrizService.save(vm.matriz).then(function (data) {
                    if (angular.isFunction(fnCad)) {
                        fnCad(data);
                    }

                    $rootScope.$broadcast('refreshMatrizCurricular', {
                        data: data,
                        editarConfiguracoes: !continuar,
                        add: !id,
                        edit: Boolean(id)
                    });

                    onSuccess(continuar, tracker);
                }));

            };

        $scope.readOnly = function () {
            return readOnly;
        };

    }]);

})();

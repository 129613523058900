(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .directive('appSelectMunicipios', SelectMunicipio);

    function SelectMunicipio() {
        return {
            restrict: 'E',
			template: '<ui-select id="selectMunicipioId" ng-model="ngModelSelectMunicipio" search="vm.search" add-value="vm.add" format-result="vm.formatResult(data)" ng-required="ngRequired"></ui-select>',
			scope: {
				ngModelSelectMunicipio: '=',
				ngRequired: '=?',
                ngChange: '&?',
				dropdownCssClass: '=?',
                canAdd: '='
			},
			controller: Controller,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
        };
    }

    Controller.$inject = [
        'ui.components.Modal',
        'educacao.enderecos.MunicipioService',
        'educacao.common.ObjectUtilsService',
        '$scope',
        'bfc.$$PermissionsService'
    ];

    function Controller($modal, service, ObjectUtilsService, $scope, PermissionsService) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.add = canAddMunicipio();

        $scope.$watch('ngModelSelectMunicipio', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function search(params) {
            var term = ObjectUtilsService.normalizeFilter(params.term);
            var filter = ['(uf like "' + term + '" or nome like "' + term + '")'];

            if ($scope.multiple) {
                var municipiosId = [];
                if ($scope.ngModelSelectMunicipio) {
                    $scope.ngModelSelectMunicipio.forEach(function (model) {
                        municipiosId.push(model.id);
                    });
                }

                if(!_.isEmpty(municipiosId)) {
                    filter.push('municipio not in(' + municipiosId.join() + ')');
                }
            }

            var paramsReq = {
                filter: filter,
                offset: params.offset,
                limit: params.limit
            };

            return service
                .getAll(paramsReq);
        }

        function formatResult(data) {
            if(data.estado) {
                return data.nome + ' - ' + data.estado.uf;
            }

            return data.nome;
        }

        function canAddMunicipio() {
            if(!$scope.canAdd) {
                return undefined;
            }

            return PermissionsService.isRevokedOperation('MunicipioPageMapping', 'criar') ? undefined : add;
        }

        function add(nome) {
            return $modal.open({
                templateUrl: 'enderecos/municipio/cadastro-municipio/cadastro-municipio.html',
                controller: 'educacao.enderecos.CadastroMunicipioController',
                controllerAs: 'vm',
                params: {
                    municipio: {
                        nome: nome
                    },
                    canSaveAdd: false
                },
                size: 'lg'
            }).result;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appDuracaoTurno', DuracaoTurnoDirective);

	function DuracaoTurnoDirective() {
		return {
			restrict: 'E',
			templateUrl: 'matricula/horas-aula/app-duracao-turno/app-duracao-turno.directive.html',
			scope: {
				configuracaoHoraAula: '='
			},
			bindToController: true,
			controller: DuracaoTurnoDirectiveController,
			controllerAs: 'vm'
		};
	}

	DuracaoTurnoDirectiveController.$inject = ['$scope'];

	function DuracaoTurnoDirectiveController($scope) {

		var vm = this;
		var LAST_AULA = 0;
		var INTERVALOS = [];
		var debouncedCalcularHorarioAulas = _.debounce(calcularHorarioAulas, 200);

		vm.aplicarTurnosParaTodos = aplicarTurnosParaTodos;

		vm.configuracaoHoraAula.itensDuracao = _.sortByOrder(vm.configuracaoHoraAula.itensDuracao, 'nroAula', 'asc');

		$scope.$watch('vm.configuracaoHoraAula.horaInicio', debouncedCalcularHorarioAulas);
		$scope.$watch('vm.configuracaoHoraAula.itensDuracao', debouncedCalcularHorarioAulas, true);
		$scope.$watchCollection('vm.configuracaoHoraAula.intervalos', debouncedCalcularHorarioAulas);

		function calcularHorarioAulas() {
			if (!vm.configuracaoHoraAula.horaInicio) {
				_.forEach(vm.configuracaoHoraAula.itensDuracao, removeHoraInicioFimAula);
				return;
			}
			LAST_AULA = vm.configuracaoHoraAula.horaInicio;
			INTERVALOS = vm.configuracaoHoraAula.intervalos || [];
			_.forEach(vm.configuracaoHoraAula.itensDuracao, calcularInicioFimAula);
		}

		function calcularInicioFimAula(aula) {
			if (!aula.tempoDuracaoAula) {
				removeHoraInicioFimAula(aula);
				return;
			}
			aula.horaInicio = moment(LAST_AULA, 'HH:mm').format('HH:mm');
			aula.horaFim = moment(LAST_AULA, 'HH:mm').add(aula.tempoDuracaoAula, 'minutes').format('HH:mm');
			var intervalo = getIntervaloAposAula(aula.nroAula);
			if (intervalo) {
				LAST_AULA = moment(aula.horaFim, 'HH:mm').add(intervalo.tempoDuracao, 'minutes').format('HH:mm');
				return;
			}
			LAST_AULA = aula.horaFim;
		}

		function getIntervaloAposAula(nroAula) {
			return _.find(INTERVALOS, { nroAulaPrecedente: nroAula });
		}

		function removeHoraInicioFimAula(aula) {
			aula.horaInicio = null;
			aula.horaFim = null;
		}

		function aplicarTurnosParaTodos() {
			_.forEach(vm.configuracaoHoraAula.itensDuracao, aplicarTurnosParaAula);
			vm.turnos = null;
		}

		function aplicarTurnosParaAula(aula) {
			aula.turnos = aula.turnos || [];
			aula.turnos = _.union(aula.turnos, vm.turnos);
		}
	}
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoInscritosComunicadoMensagemCtrl', ClassificacaoInscritosComunicadoMensagemCtrl);

    ClassificacaoInscritosComunicadoMensagemCtrl.$inject = ['processo', 'vaga', '$modalInstance', 'educacao.context.ContextoEntidade'];

    function ClassificacaoInscritosComunicadoMensagemCtrl(processo, vaga, $modalInstance, ContextoEntidade) {

        var vm = this;
        vm.close = close;

        vm.processo = processo;
        vm.vaga = vaga;
        vm.nameEntidade = ContextoEntidade.getEntidade().nomeEntidade;

        function close() {
            $modalInstance.close();
        }

    }

})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .directive('appListInscricaoTurnos', appListTurnos);

    function appListTurnos() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/inscricao/app-list-inscricao-turnos/app-list-inscricao-turnos.html',
            scope: {},
            bindToController: {
                estabId: '=',
                turnos: '=',
                configuracao: '=',
                inscricaoPosicao: '=',
                permiteAletarDataInscTurno: '=',
                refresh: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$enumsCache',
    ];

    function Controller($enumsCache) {
        var vm = this;
        vm.enumTurnos = [];
        vm.setValuePopoverMotivoCancelamentoInscricao = setValuePopoverMotivoCancelamentoInscricao;
        vm.motivoCancelamentoInscricao = null;
        vm.changeZindexPopOver = changeZindexPopOver;

        init();

        function init() {
            $enumsCache.get('Turno')
                .then(function (enumTurnos) {
                    vm.enumTurnos = enumTurnos;
                });
        }

        function setValuePopoverMotivoCancelamentoInscricao(data) {
            vm.motivoCancelamentoInscricao = data.situacao.motivo;
        }

        function changeZindexPopOver() {
            var popoverElement = angular.element('.popover');
            popoverElement.css('z-index', 'auto');
        }


    }

})();

(function () {
    'use strict';

    var QTD_MAX_ETAPAS = {
        ENSINO_MEDIO: 4
    };

    angular.module('educacao.configuracao-indicadores')
        .controller('educacao.configuracao-indicadores.ConfiguracaoIndicadoresController', ConfiguracaoIndicadoresController);

    ConfiguracaoIndicadoresController.$inject = [
        '$q',
        'promiseTracker',
        '$enumsCache',
        'bfc.Notification',
        'educacao.configuracao-evasao-reprovacao.ConfiguracaoEvasaoReprovacaoService',
        'educacao.configuracao-indicadores.ConfiguracaoIndicadoresService',
        '$configuracaoEvasaoReprovacaoUtilsService'
    ];

    function ConfiguracaoIndicadoresController($q, promiseTracker, $enumsCache,
                                               bfcNotification,
                                               ConfiguracaoEvasaoReprovacaoService,
                                               ConfiguracaoIndicadoresService,
                                               $configuracaoEvasaoReprovacaoUtilsService) {
        var vm = this;

        vm.onChangeNivelEvasao = onChangeNivelEvasao;
        vm.onChangeNivelReprovacao = onChangeNivelReprovacao;
        vm.onChangeItemEvasao = onChangeItemEvasao;
        vm.onChangeItemReprovacao = onChangeItemReprovacao;
        vm.onClickOpen = onClickOpen;
        vm.onClickSave = onClickSave;

        vm.tracker = {
            init: promiseTracker(),
            save: promiseTracker()
        };

        init();

        function init() {
            setWarningDay();

            getVerificacao()
                .then(getResultados);

            loadEnumTipoConfiguracao()
                .then(getConfiguracao);
        }

        function setWarningDay() {
            var DAY_05 = getNextDay(5);
            var DAY_20 = getNextDay(20);

            if (DAY_05.isBefore(DAY_20)) {
                vm.warningDay = DAY_05.format('DD/MM');
            } else {
                vm.warningDay = DAY_20.format('DD/MM');
            }
        }

        function getNextDay(day) {
            var lastDay = moment().date(day);
            var current = moment();
            if (current.isBefore(lastDay)) {
                return lastDay;
            }
            return lastDay.add(1, 'months');
        }

        function getConfiguracao() {
            loadConfiguracao()
                .then(onLoadConfiguracao);
        }

        function onLoadConfiguracao(configuracao) {
            if (!_.isEmpty(configuracao)) {
                vm.configuracoes = configuracao;
                return;
            }

            loadFromEnums();
        }

        function loadEnumTipoConfiguracao() {
            var promise = $enumsCache.get('TipoConfiguracaoRiscoEvasaoReprovacao')
                .then(function (tiposConfiguracao) {
                    vm.tiposConfiguracaoEnum = tiposConfiguracao;
                });
            vm.tracker.init.addPromise(promise);
            return promise;
        }

        function loadFromEnums() {
            return $q.all([$enumsCache.get('EquivalenciaEtapa'), $enumsCache.get('NivelEscolar')])
                .then(function (niveisEnums) {
                    vm.equivalenciaEtapaEnum = _.first(niveisEnums);
                    vm.nivelEscolarEnum = _.last(niveisEnums);

                    createConfiguracaoFromEnums();
                });
        }

        function createConfiguracaoFromEnums() {
            vm.configuracoes = _.chain(vm.tiposConfiguracaoEnum.values)
                .map(createRegistroConfiguracao)
                .value();
        }

        function createRegistroConfiguracao(tipoEnumValue) {
            var configuracao = {
                tipo: tipoEnumValue.id,
                evasaoHabilitado: false,
                reprovacaoHabilitado: false,
                nivelModalidade: tipoEnumValue.nivelModalidade,
                nivelEscolar: tipoEnumValue.nivelEscolar,
                itens: [],
                $$isOpen: false
            };

            if (!tipoEnumValue.hasEtapas) {
                return configuracao;
            }

            configuracao.itens = createEtapas(tipoEnumValue);

            return configuracao;
        }

        function createEtapas(tipoEnumValue) {
            return _.chain(vm.equivalenciaEtapaEnum.values)
                .filter(function (equivalenciaEtapa) {
                    return !QTD_MAX_ETAPAS[tipoEnumValue.nivelEscolar] || equivalenciaEtapa.codigo <= QTD_MAX_ETAPAS[tipoEnumValue.nivelEscolar];
                })
                .map(createEtapa)
                .value();
        }

        function createEtapa(equivalenciaEtapa) {
            return {
                equivalenciaEtapa: equivalenciaEtapa.id,
                evasaoHabilitado: false,
                reprovacaoHabilitado: false
            };
        }

        function getResultados() {
            var promise = ConfiguracaoEvasaoReprovacaoService.getResultados()
                .then(function (resultados) {
                    vm.resultados = resultados;
                    vm.percentuais = $configuracaoEvasaoReprovacaoUtilsService.getPercentualFScore(vm.resultados);
                }).then(checkRecursosHabilitados);
            vm.tracker.init.addPromise(promise);
        }

        function getVerificacao() {
            var promise = ConfiguracaoEvasaoReprovacaoService.verificarRecurso()
                .then(function (verificacao) {
                    vm.verificacao = verificacao;
                });
            vm.tracker.init.addPromise(promise);
            return promise;
        }

        function checkRecursosHabilitados() {
            vm.isEvasaoHabilitado = vm.verificacao.enableEvasao &&
                $configuracaoEvasaoReprovacaoUtilsService.isResultEnabled(vm.resultados.evasao);
            vm.isReprovacaoHabilitado = vm.verificacao.enableReprovacao &&
                $configuracaoEvasaoReprovacaoUtilsService.isResultEnabled(vm.resultados.reprovacao);
        }

        function loadConfiguracao() {
            var promise = ConfiguracaoIndicadoresService.get();
            vm.tracker.init.addPromise(promise);
            return promise;
        }

        function onClickOpen(configuracao) {
            configuracao.$$isOpen = !configuracao.$$isOpen;
        }

        function onChangeNivelEvasao(configuracao) {
            onChangeNivel(configuracao, 'evasaoHabilitado');
        }

        function onChangeNivelReprovacao(configuracao) {
            onChangeNivel(configuracao, 'reprovacaoHabilitado');
        }

        function onChangeNivel(configuracao, fieldHabilitado) {
            if (!vm.tiposConfiguracaoEnum[configuracao.tipo].hasEtapas) {
                return;
            }

            _.forEach(configuracao.itens, function (item) {
                item[fieldHabilitado] = configuracao[fieldHabilitado];
            });
        }

        function onChangeItemEvasao(configuracao) {
            checkNivel(configuracao, 'evasaoHabilitado');
        }

        function onChangeItemReprovacao(configuracao) {
            checkNivel(configuracao, 'reprovacaoHabilitado');
        }

        function checkNivel(configuracao, fieldHabilitado) {
            configuracao[fieldHabilitado] = _.every(configuracao.itens, function (item) {
                return item[fieldHabilitado];
            });
        }

        function onClickSave() {
            var promise = ConfiguracaoIndicadoresService.save(vm.configuracoes)
                .then(function (configuracao) {
                    vm.configuracoes = configuracao;
                    bfcNotification.publish('Configuração salva com sucesso', 'success');
                });
            vm.tracker.save.addPromise(promise);
        }
    }

})();
(function() {
    'use strict';

    angular.module('educacao.matriz').directive('appSelectQuadroVagasSaldos', SelectQuadroVagasSaldos);

    function SelectQuadroVagasSaldos() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ngclass="ngclass" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchSaldo" format-result="vm.formatResult(data)" ng-disabled="ngDisabled"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                formatResult: '=?',
                ngclass: '=',
                comVagas: '=',
                saldos: '='
            },
            controller: SelectQuadroVagasSaldosDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectQuadroVagasSaldosDirectiveController.$inject = ['$scope', '$q'];

    function SelectQuadroVagasSaldosDirectiveController($scope, $q) {

        var vm = this;

        vm.searchSaldo = searchSaldo;
        vm.formatResult = formatResult;

        function formatResult(saldo) {
            
            return $scope.formatResult ? $scope.formatResult(saldo) : format(saldo);


            function format(s){

                return _.capitalize((s.turno || '').toLowerCase()) + ' ('+ (s.qtdVagas) +'/'+s.qtdVagas+' vagas)';

            }

        }

        function searchSaldo() {

            return $q.when({
                content:  _.filter($scope.saldos, filterSaldoMultiTurno)
            });

            function filterSaldoMultiTurno(s){
                return s.tipo === 'MULTITURNO';
            }

        }

    }
})();

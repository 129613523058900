(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.QuadroVagasMatriculasCtrl', QuadroVagasMatriculasCtrl);

	QuadroVagasMatriculasCtrl.$inject = ['$scope', 'educacao.matricula.QuadroVagasService', 'bfc.dialog.Dialog', '$q',
	'educacao.matricula.LiberacaoVagasEstabelecimentoService', 'educacao.context.ContextoEstabelecimento', 'promiseTracker'];

	function QuadroVagasMatriculasCtrl($scope, QuadroVagasService, BfcDialog, $q, LiberacaoVagasEstabelecimentoService,
		ContextoEstabelecimento,
		promiseTracker) {

		var vm = this;

		vm.tracker = {};
		vm.controls = {};
		vm.tracker.list = promiseTracker();
		vm.search = search;
		vm.openModalLiberacao = openModalLiberacao;
		vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
		vm.openModalCadastro = openModalCadastro;
		vm.QuadroVagasService = QuadroVagasService;
		vm.openModalEdicaoEstabelecimento = openModalEdicaoEstabelecimento;
		vm.removerVagasEstabelecimento = removerVagasEstabelecimento;

		$scope.$watch('vm.colEstabelecimentoIsVisible', watchColumns('descricao'));
		$scope.$watch('vm.colInicialIsVisible', watchColumns('inicial'));
		$scope.$watch('vm.colInscricaoIsVisible', watchColumns('inscricao'));
		$scope.$watch('vm.colMatriculasIsVisible', watchColumns('matriculas'));
		$scope.$watch('vm.colAtualIsVisible', watchColumns('atual'));

		function watchColumns(coluna) {
			return function(value) {
				if (!_.isUndefined(vm.controls.setColumnVisible)) {
					vm.controls.setColumnVisible(coluna, value);
					if (value) {
						return vm.colunasVisiveis.push(coluna);
					}
					return _.pull(vm.colunasVisiveis, coluna);
				}
			};
		}

		init();

		function init() {

			vm.filter = {};
			vm.columns = [{
				'id': 'descricao',
				'model': 'descricao',
				'filterable': true,
				'label': 'Estabelecimento'
			}];

			vm.colunas = ['descricao', 'inicial', 'inscricao', 'matriculas', 'atual'];
			vm.colunasVisiveis = _.cloneDeep(vm.colunas);
			vm.colEstabelecimentoIsVisible = true;
			vm.colInicialIsVisible = true;
			vm.colInscricaoIsVisible = true;
			vm.colMatriculasIsVisible = true;
			vm.colAtualIsVisible = true;

			vm.tracker.list.addPromise(
				$q.all([
					LiberacaoVagasEstabelecimentoService.liberados().then(function(data) {
						if (!vm.hasEstabelecimento) {
							vm.habilitaEdicao = true;
						} else {
							vm.habilitaEdicao = _.some(data, function(liberados) {
								return liberados.estabelecimento.id === ContextoEstabelecimento.getEstabelecimento()
									.id;
							});
						}
					})
				])
			);
		}

		function openModalCadastro() {

			QuadroVagasService.openModalCadastro()
				.result.then(search);

		}

		function openModalLiberacao() {

			BfcDialog.open({
				templateUrl: 'matricula/quadro-vagas-matriculas/liberacao-estabelecimento/liberacao-vagas-estabelecimento.html',
				controller: 'educacao.matricula.LiberacaoVagasEstabelecimentoCtrl as vm'
			});

		}

		function openModalEdicaoEstabelecimento(estabelecimento) {

			var param = {
				filter: 'estabelecimento=' + estabelecimento.id,
				limit: 999
			};

			QuadroVagasService.openModalEdicao(param, 'estabelecimento')
				.result.then(search);

		}

		function removerVagasEstabelecimento(estabelecimento) {

			QuadroVagasService.removePorEstabelecimento(estabelecimento.id)
				.then(search);

		}

		function search() {
			vm.controls.search();
		}
	}
})();

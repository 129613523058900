(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .directive('appSelectRegioes', SelectRegioes);

    function SelectRegioes() {
        return {
            restrict: 'E',
			template: '<ui-select id="selectRegiaoId" ng-model="ngModelSelectRegiao" search="vm.search" add-value="vm.add" format-result="vm.formatResult(data)" ng-required="ngRequired"></ui-select>',
			scope: {
				ngModelSelectRegiao: '=',
				ngRequired: '=?',
                ngChange: '&?',
				dropdownCssClass: '=?',
                canAdd: '='
			},
			controller: Controller,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
        };
    }

    Controller.$inject = [
        'ui.components.Modal',
        'educacao.enderecos.RegiaoService',
        'educacao.common.ObjectUtilsService',
        '$scope',
        'bfc.$$PermissionsService'
    ];

    function Controller($modal, service, ObjectUtilsService, $scope, PermissionsService) {
        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.add = canAddRegiao();

        $scope.$watch('ngModelSelectRegiao', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function search(params) {
            var term = ObjectUtilsService.normalizeFilter(params.term);
            var filter = ['(nome like "' + term + '" or sigla like "' + term + '")'];

            if ($scope.multiple) {
                var regioesId = [];
                if ($scope.ngModelSelectRegiao) {
                    $scope.ngModelSelectRegiao.forEach(function (model) {
                        regioesId.push(model.id);
                    });
                }

                if(!_.isEmpty(regioesId)) {
                    filter.push('id not in(' + regioesId.join() + ')');
                }
            }

            var paramsReq = {
                filter: filter,
                offset: params.offset,
                limit: params.limit
            };

            return service
                .getAll(paramsReq);
        }

        function formatResult(data) {
            if(data.sigla) {
                return data.nome + ' - ' + data.sigla;
            }

            return data.nome;
        }

        function add(nome) {
            return $modal.open({
                templateUrl: 'enderecos/regiao/cadastro-regiao/cadastro-regiao.html',
                controller: 'educacao.enderecos.CadastroRegiaoController',
                controllerAs: 'vm',
                params: {
                    regiao: {
                        nome: nome
                    },
                    canSaveAdd: false
                },
                size: 'lg'
            }).result;
        }

        function canAddRegiao() {
            if(!$scope.canAdd) {
                return undefined;
            }

            return PermissionsService.isRevokedOperation('RegiaoMunicipalPageMapping', 'criar') ? undefined : add;
        }
    }
})();
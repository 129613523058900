(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.AtividadesComplementaresCtrl', AtividadesComplementaresController);

    AtividadesComplementaresController.$inject = [
        'ui.components.Modal',
        'educacao.matricula.AtividadeComplementarService',
        'promiseTracker',
        '$modalStates'
    ];

    function AtividadesComplementaresController(
        uiModal,
        AtividadeComplementarService,
        promiseTracker,
        $modalStates) {

        var vm = this;

        vm.AtividadeComplementarService = AtividadeComplementarService;
        vm.openModalCadastroAtividade = openModalCadastroAtividade;
        vm.remove = remove;
        vm.search = search;

        vm.filter = {};

        vm.userListControls = {};
        vm.defaultListControls = {};

        vm.tracker = {
            loading: promiseTracker()
        };

        vm.defaultListCustomBuilder = {
            onBeforeBuild: function() {
                return 'dataBase is null';
            }
        };

        vm.columnsSearch = [
            {
                id: 'descricao',
                model: 'descricao',
                label: 'Descrição',
                filterable: true
            }
        ];

        initController();

        function initController() {
            vm.areas = AtividadeComplementarService.getAreaAtividadeCompl()
                .then(function(data) {
                    vm.enumAreas = data;
                    return data;
                });

            vm.subAreas = AtividadeComplementarService.getSubAreaAtividadeCompl().then(function(data) {
                vm.enumSubAreas = data;
                return data;
            });

            vm.tracker.loading.addPromise(vm.areas);
            vm.tracker.loading.addPromise(vm.subAreas);
        }

        function search() {
            vm.defaultListControls.search();
            vm.userListControls.search();
        }

        function openModalCadastroAtividade(itemAtividade) {
            $modalStates.goModalState(itemAtividade);
        }

        function remove(atividade) {
            AtividadeComplementarService.remove(atividade);
        }
    }
})();

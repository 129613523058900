(function () {
    'use strict';

    angular
        .module('educacao.context')
        .service('educacao.context.ContextoEstabelecimento', service);

    service.$inject = [
        'CONTEXT_REGION',
        'CONTEXT_ESTABELECIMENTO',
        'CONTEXTO_ESTABELECIMENTO_SECRETARIA',
        '$window',
        '$rootScope',
        '$q',
        'Restangular',
        'educacao.context.UserAccessService',
        'bfcApplicationContext',
        'bfcApplicationContextStorage',
        'educacao.context.ContextoEntidade'
    ];

    function service(CONTEXT_REGION,
        CONTEXT_ESTABELECIMENTO,
        CONTEXTO_ESTABELECIMENTO_SECRETARIA,
        $window,
        $rootScope,
        $q,
        restangular,
        UserAccessService,
        bfcApplicationContext,
        bfcApplicationContextStorage,
        ContextoEntidade) {

        var selected;

        return {
            listaEstabelecimentos: listaEstabelecimentos,

            getEstabelecimento: getEstabelecimento,
            isSecretaria: isSecretaria,
            getValorContextoEstabelecimento: getValorContextoEstabelecimento,
            setEstabelecimento: setEstabelecimento,
            resolveEstabelecimentoFromAcesso: resolveEstabelecimentoFromAcesso,
            hasEstabelecimento: hasEstabelecimento
        };

        function listaEstabelecimentos() {
            return UserAccessService.getUserAccess()
                .then(function (acessos) {
                    acessos = _.chain(acessos)
                        .filter('values.entity', String(ContextoEntidade.getValorContextoEntidade()))
                        .value();

                    var estabelecimentos = _.chain(acessos)
                        .filter('values.estabelecimento')
                        .compact()
                        .groupBy('licenseId')
                        .map(load)
                        .value();

                    if (hasContextoSecretaria(acessos)) {
                        estabelecimentos.unshift(CONTEXTO_ESTABELECIMENTO_SECRETARIA);
                    }
                    
                    return $q.all(estabelecimentos)
                        .then(function (data) {

                            var retorno = [];

                            _.forEach(data, function (item) {
                                if (_.isArray(item)) {
                                    retorno = retorno.concat(_.compact(item));
                                } else {
                                    retorno.push(item);
                                }
                            });
                            return retorno;
                        });
                });
        }

        function load(access) {
            var estabelecimentos = _.isArray(access) ?
                estabelecimentos = _.pluck(access, 'values.estabelecimento') : access.values.estabelecimento;

            return restangular
                .all('context')
                .customGET('estabelecimento', {
                    id: estabelecimentos
                }, {
                    'User-Access': _.get(_.first(access), 'licenseId') || access.licenseId,
                    'App-Context': $window.btoa(JSON.stringify({
                        estabelecimento: {
                            value: String(_.get(access, 'values.estabelecimento') || _.first(estabelecimentos)),
                            insulation: true
                        }
                    }))
                }).then(function (est) {
                    _.forEach(est, function (estabelecimento) {
                        estabelecimento.pessoa = {
                            nome: estabelecimento.descricao
                        };
                    });
                    return est;
                }).catch(function () {
                    return null;
                });
        }

        function getEstabelecimento() {
            return selected;
        }

        function isSecretaria() {
            return Boolean(selected && selected.isSecretaria);
        }

        function getValorContextoEstabelecimento() {
            return selected && selected.id;
        }

        function hasContextoSecretaria(acessos) {
            var acessoSecretaria = _.find(acessos, function(acesso) {
                        return acesso.values.database && acesso.values.entity && 
                            !acesso.values.estabelecimento; 
                    });
            return Boolean(acessoSecretaria);
        }

        function setEstabelecimento(estabelecimento) {
            estabelecimento = _.isArray(estabelecimento) ? _.first(estabelecimento) : estabelecimento;
            if (estabelecimento && getValorContextoEstabelecimento() === estabelecimento.id) {
                return;
            }
            selected = estabelecimento;

            if (isSecretaria()) {
                bfcApplicationContext.remove(CONTEXT_ESTABELECIMENTO);
                bfcApplicationContextStorage.addValue(CONTEXT_ESTABELECIMENTO, null, CONTEXT_REGION);
                $rootScope.$broadcast('app-context:estabelecimento:new');
            } else {
                var bfcApplicationContextOperation = selected ? bfcApplicationContext.put : bfcApplicationContext.remove;
                bfcApplicationContextOperation(CONTEXT_ESTABELECIMENTO, {
                    value: String(getValorContextoEstabelecimento()),
                    insulation: true
                });
                bfcApplicationContextStorage.addValue(CONTEXT_ESTABELECIMENTO, getValorContextoEstabelecimento(), CONTEXT_REGION);
            }
        }

        function resolveEstabelecimentoFromAcesso(context) {
            if (!context) {
                return $q.reject('sem contexto');
            }
            return UserAccessService.getUserAccess()
                .then(function (listaAcesso) {
                    var access = _.find(listaAcesso, 'context', context);
                    var id = _.get(access, 'values.estabelecimento');
                    if (id) {
                        return load(access).then(setEstabelecimento);
                    } else {
                        setEstabelecimento(CONTEXTO_ESTABELECIMENTO_SECRETARIA);
                    }
                });
        }

        function hasEstabelecimento() {
            return Boolean(selected);
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('descricaoVagas', descricaoVagas);

    function descricaoVagas() {

        var directiveConfig = {};

        directiveConfig.restrict = 'E';
        directiveConfig.require = 'ngModel';
        directiveConfig.templateUrl = 'matricula/configuracao-inscricao/directives/descricao-vagas.directive.html';
        directiveConfig.scope = {};
        directiveConfig.scope.ngModel = '=';
        directiveConfig.scope.enumTurno = '=';
        directiveConfig.scope.nivelEscolar = '=';
        directiveConfig.scope.tipoSaldo = '@';
        directiveConfig.controller = DescricaoVagasCtrl;
        directiveConfig.controllerAs = 'vm';

        return directiveConfig;
    }

    DescricaoVagasCtrl.$inject = ['$injector', '$scope'];

    function DescricaoVagasCtrl($injector, $scope) {
        var StringBuilder = $injector.get('educacao.common.StringBuilder');

        var vm = this,
            ENSINO_MEDIO = 'ENSINO_MEDIO';

        vm.getDescriptionItemVagas = getDescriptionItemVagas;

        function getDescriptionItemVagas(item) {
            var sb = StringBuilder.init()
                .append('<strong>')
                .append(item.etapaMatriz.descricao)
                .append(' (')
                .append($scope.enumTurno[item.turno].description)
                .append(')</strong> ');

            if (item.etapaMatriz.descricaoParametrizacao && $scope.nivelEscolar !== ENSINO_MEDIO) {
                sb.append(' - ')
                    .append(item.etapaMatriz.descricaoParametrizacao);
            }

            var qtdVagas = _.result(_.find(item.saldos, function(data) { 
                return data.tipo === $scope.tipoSaldo;
            }),'qtdVagas');

            sb.append(' - ')
                .append(qtdVagas)
                .append(qtdVagas > 1 ? ' vagas' : ' vaga');

            return sb.toString();
        }
    }
})();

(function () {

    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .directive('appConfiguracaoListaEsperaClassificacao', appConfiguracaoListaEsperaClassificacao);

    function appConfiguracaoListaEsperaClassificacao() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/configuracao/app-configuracao-lista-espera-classificacao/app-configuracao-lista-espera-classificacao.directive.html',
            scope: {},
            bindToController: {
                configuracao: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            replace: true
        };
    }

    Controller.$inject = ['$enumsCache'];

    function Controller($enumsCache) {
        var vm = this;
        vm.CampoClassificacaoListaEspera = $enumsCache.get('CampoClassificacaoListaEspera');
        vm.formatResultEnumListaEspera = formatResultEnumListaEspera;

        function formatResultEnumListaEspera(data){
            return data.descricao;
        }
    }
})();

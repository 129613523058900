(function() {

    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.FichaIndividualService', FichaIndividualService);

    FichaIndividualService.$inject = ['ServiceBuilder'];

    function FichaIndividualService(ServiceBuilder) {
        var self = ServiceBuilder
            .create()
            .path('matricula', 'ficha-individual')
            .enum('BoletimPeriodoAvaliativo')
            .enum('BoletimOrdenacao')
            .enum('ModeloFichaIndividual')
            .build();

        self.gerarPdf = gerarPdf;

        return self;

        function gerarPdf(parametros) {

            return self.getEndpoint().all('pdf')
                .withHttpConfig({
                    responseType: 'arraybuffer'
                })
                .post(parametros);
        }
    }

})();


(function () {

    'use strict';

    angular.module('educacao.encerramento')
        .directive('listagemEncerramentoFilter', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'encerramento/listagem-encerramento/listagem-encerramento-filter.directive.html',
            scope: {},
            bindToController: {
                search: '=',
                getFilter: '=?',
                listPeriodos: '=',
                searchFilterKeywords: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.encerramento.EncerramentoService',
        'educacao.context.ContextoEstabelecimento',
        '$searchExpressionBuilder',
        'STATUS_ENCERRAMENTO'
    ];

    function Controller(EncerramentoService, ContextoEstabelecimento, $searchExpressionBuilder, STATUS_ENCERRAMENTO) {

        var PREFIX_ENCERRAMENTO_STATUS_FILTER = 'ENCERRAMENTO_STATUS_';
        var PREFIX_ENCERRAMENTO = 'ENCERRAMENTO_';

        var vm = this;

        vm.filterResultStatusEncerramento = filterResultStatusEncerramento;
        vm.onSearch = onSearch;

        vm.turnos = EncerramentoService.getTurno();
        vm.tiposAtendimentos = EncerramentoService.getTipoAtendimento();
        vm.etapas = EncerramentoService.getEquivalenciaEtapa();
        vm.periodos = EncerramentoService.getEncerramentoTipoPeriodo();
        vm.statusPeriodos = EncerramentoService.getStatusEncerramento();

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimentoContexto = ContextoEstabelecimento.getEstabelecimento();

        vm.searchColumns = [{
            id: 'descricaoTurma',
            label: 'Turma',
            model: 'turma',
            filterable: true
        }];

        vm.expressionCustomBuilders = {
            periodos: filtersBuilderPeriodos,
            statusPeriodos: filtersBuilderStatus
        };

        vm.getFilter = getFilter;

        function filtersBuilderStatus(filters) {
            var filter = '';

            if (!filters) {
                return;
            }

            if (!_.isEmpty(filters.statusPeriodos)) {
                if (!_.isEmpty(filters.periodos)) {
                    if(filter){
                        filter += ' and ';
                    }
                    filter += getFilterStatusPeriodos(filters.periodos, filters.statusPeriodos);
                } else {
                    if(filter){
                        filter += ' and ';
                    }
                    filter += getFilterStatusPeriodos(vm.listPeriodos, filters.statusPeriodos);
                }
            }

            return filter;
        }

        function filtersBuilderPeriodos(filters) {
            var filter = '';

            if (!filters) {
                return;
            }

            if (!_.isEmpty(filters.periodos)) {
                _.forEach(filters.periodos, function (periodo) {
                    if (filter) {
                        filter += ' or ';
                    }
                    filter += periodo + ' is not null ';
                });
            }
            if(filter){
                filter  = '(' + filter + ')';
            }

            return filter;
        }

        function getFilterStatusPeriodos(periodos, situacoes) {
            var filter = '';

            _.forEach(periodos, function (periodo) {
                var periodoProperty = periodo.id || periodo;
                if (filter) {
                    filter += ' or ';
                }
                filter += '(';
                filter += PREFIX_ENCERRAMENTO_STATUS_FILTER + periodoProperty + ' in ("' + situacoes.join('","') + '")';

                if(_.indexOf(situacoes, STATUS_ENCERRAMENTO.EM_ABERTO) >= 0){
                    if(filter){
                        filter += ' or ';
                    }
                    filter += PREFIX_ENCERRAMENTO + periodoProperty + ' is null ' + ' and ' + periodoProperty + ' is not null ' ;
                }
                filter += ')';
            });

            return (' ( ' + filter + ' ) ');
        }

        function getFilter(){

            var filter = '';

            var expression = $searchExpressionBuilder.build(vm.searchFilter, vm.expressionCustomBuilders) || '';
            if (!_.isEmpty(expression)) {
                filter += ' (' + expression + ')';
            }

            if(!vm.isSecretaria){
                if(filter){
                    filter += ' and ';
                }
                filter += ' estabelecimento=' + vm.estabelecimentoContexto.id;
            }

            return filter;
        }

        function onSearch(){
            
            var filter = getFilter();

            vm.searchFilterKeywords = (vm.searchFilter);

            vm.search(filter);
        }

        function filterResultStatusEncerramento(term, value){
            if(value.id !== STATUS_ENCERRAMENTO.NAO_INICIADO){
                return value;
            }
        }
    }
})();
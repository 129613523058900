(function() {
    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.StatisticEducacensoService', StatisticEducacensoService);

    StatisticEducacensoService.$inject = [];

    function StatisticEducacensoService() {
        var CLASS_ERROR = 'field-error',
            PREFIX_SELECT2 = 's2id_';

        var service;

        var propriedadeCampos = {
            numberCorrects: 0,
            numberValidations: 0,
            percentCorrects: null,
            elements: [],
            visibleErrors: false,
            new: function() {
                this.numberCorrects = 0;
                this.numberValidations = 0;
                this.percentCorrects = null;
                this.elements = [];
                this.visibleErrors = false;
            }
        };

        var metodosPublicos = {
            showErrors: _showErrors,
            hideErrors: _hideErrors,
            elementsWithError: _elementsWithError,
            getPercentual: _getPercentual,
            calcPercentual: _calcPercentual
        };

        function _showErrors() {
            return function(element) {
                element.addClass(CLASS_ERROR);
            };
        }

        function _hideErrors() {
            return function(element) {
                element.removeClass(CLASS_ERROR);
            };
        }

        function _elementsWithError() {
            if (service.elements.length > 0) {

                var event = !service.visibleErrors ? service.showErrors() : service.hideErrors();
                _.each(service.elements, function(descElement) {
                    descElement = '#' + descElement;
                    var element = descElement.indexOf(PREFIX_SELECT2) > -1 ?
                        (angular.element(descElement).find('.select2-choice').length > 0 ?
                            angular.element(descElement).find('.select2-choice') : angular.element(descElement).find('.select2-choices')) :
                        angular.element(descElement);
                    event(element);
                });
                service.visibleErrors = !service.visibleErrors;
            }
        }

        function _getPercentual() {
            service.calcPercentual();
            return service.percentCorrects;
        }

        function _calcPercentual() {
            service.percentCorrects = String(Math.round((service.numberCorrects / service.numberValidations) * 100)) + '%';
            return service.percentCorrects;
        }

        service = angular.copy(angular.extend(propriedadeCampos, metodosPublicos));

        return service;
    }
})();

(function() {
	'use strict';

	var BASE_END_POINT = 'configuracao-inscricao';

	angular.module('educacao.matricula')
		.factory('educacao.matricula.ClassificacaoService', ClassificacaoService);

	ClassificacaoService.$inject = ['ServiceBuilder', '$q'];

	function ClassificacaoService(ServiceBuilder, $q) {
		var createdService = ServiceBuilder.create()
			.path('matricula')
			.build();

		var CHAMADA = 'chamada',
			CLASSIFICACAO = 'classificacao';

		createdService.classificar = classificar;
		createdService.desfazerClassificacao = desfazerClassificacao;
		createdService.getDefault = getDefault;
		createdService.encerrarIndeferindo = encerrarIndeferindo;
		createdService.encerrar = encerrar;
		createdService.updateChamada = updateChamada;
		createdService.getChamadoClassificado = getChamadoClassificado;
		createdService.getListaClassificados = getListaClassificados;
		createdService.getOldListaClassificados = getOldListaClassificados;
		createdService.desfazerEncerramento = desfazerEncerramento;
		createdService.statusClassificacao = statusClassificacao;
		createdService.getListaResultadoInscrito = getListaResultadoInscrito;

		createdService.getListaResultadoInscritoVaga = getListaResultadoInscritoVaga;
		createdService.getListaResultadoInscritoEquivalencia = getListaResultadoInscritoEquivalencia;

		return createdService;

		function getBaseEndPoint(id) {
			return createdService.getEndpoint().one(BASE_END_POINT, id);
		}

		function classificar(id) {
			return getBaseEndPoint(id).all(CLASSIFICACAO).post();
		}

		function getChamadoClassificado(idConfiguracao) {
			return getBaseEndPoint(idConfiguracao).one(CHAMADA).one('atual').get();
		}

		function getListaClassificados(idConfiguracao, idChamado, params) {
			return getBaseEndPoint(idConfiguracao).one(CHAMADA).all(idChamado).get('', params);
		}

		function getOldListaClassificados(idConfiguracao, idChamado, params) {
			return getBaseEndPoint(idConfiguracao).one(CHAMADA).all(idChamado).get('old', params);
		}

		function getListaResultadoInscrito(idConfiguracao, idInscrito) {
			return getBaseEndPoint(idConfiguracao).one(CHAMADA).all(idInscrito).get('resultados', {
				limit: 999
			});
		}

		function getListaResultadoInscritoVaga(idConfiguracao, chamada, filter) {
			return getBaseEndPoint(idConfiguracao).one(CHAMADA).all(chamada).get('vaga', {
				filter: filter,
				limit: 999
			});
		}

		function getListaResultadoInscritoEquivalencia(idConfiguracao, chamada, filter) {
			return getBaseEndPoint(idConfiguracao).one(CHAMADA).all(chamada).get('equivalencia', {
				filter: filter,
				limit: 999
			});
		}

		function desfazerClassificacao(id) {
			return getBaseEndPoint(id).all(CLASSIFICACAO).remove();
		}

		function encerrarIndeferindo(id, indeferido) {
			return getBaseEndPoint(id).all(CHAMADA + '/encerrar-indeferindo').customPUT(indeferido);
		}

		function encerrar(id) {
			return createdService.getEndpoint().one('configuracao-inscricao/processos', id).all('encerrar').customPUT();
		}

		function desfazerEncerramento(id) {
			return createdService.getEndpoint().one('configuracao-inscricao/processos', id).all('desfazer-encerramento').customPUT();
		}

		function updateChamada(id, chamada) {
			return getBaseEndPoint(id).one(CHAMADA, chamada.id).customPUT(chamada);
		}

		function statusClassificacao(id) {
			return getBaseEndPoint(id).one(CLASSIFICACAO).one('status').get();
		}

		function getDefault(id) {
			return getMetadata(id, CHAMADA).then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata(id, endPoint) {
			var deferred = $q.defer();
			getBaseEndPoint(id).all(endPoint).options().then(function(data) {
				deferred.resolve(data);
			}, function(data) {
				deferred.reject(data);
			});
			return deferred.promise;
		}

	}

	angular.module('educacao.matricula')
		.factory('educacao.matricula.MatricularClassificacaoService', MatricularClassificacaoService);

	MatricularClassificacaoService.$inject = ['ServiceBuilder', '$q'];

	function MatricularClassificacaoService(ServiceBuilder, $q) {
		var createdService = ServiceBuilder.create()
			.path('matricula')
			.build();

		var END_POINT = 'chamada/resultado/matricular';

		createdService.matricular = matricular;
		createdService.getDefault = getDefault;
		createdService.listMatriculados = listMatriculados;
		createdService.vagas = vagas;

		return createdService;

		function getBaseEndPoint(idConfiguracao) {
			return createdService.getEndpoint().one(BASE_END_POINT, idConfiguracao);
		}

		function matricular(idConfiguracao, matriculado) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT).post(matriculado);
		}

		function vagas(idConfiguracao, idResultado) {
			return getBaseEndPoint(idConfiguracao).one('chamada/resultado', idResultado).all('vagas').getList();
		}

		function listMatriculados(idConfiguracao) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT + '/completos').getList({
				limit: 999
			});
		}

		function getDefault(id) {
			return getMetadata(id).then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata(id) {
			var deferred = $q.defer();
			getBaseEndPoint(id).all(END_POINT).options().then(function(data) {
				deferred.resolve(data);
			}, function(data) {
				deferred.reject(data);
			});
			return deferred.promise;
		}

	}

	angular.module('educacao.matricula')
		.factory('educacao.matricula.IndeferirClassificacaoService', IndeferirClassificacaoService);

	IndeferirClassificacaoService.$inject = ['ServiceBuilder', '$q'];

	function IndeferirClassificacaoService(ServiceBuilder, $q) {
		var createdService = ServiceBuilder.create()
			.path('matricula')
			.build();

		var END_POINT = 'chamada/resultado/indeferir';

		createdService.indeferir = indeferir;
		createdService.getDefault = getDefault;
		createdService.listIndeferidos = listIndeferidos;
		createdService.desfazerIndeferimento = desfazerIndeferimento;

		return createdService;

		function getBaseEndPoint(idConfiguracao) {
			return createdService.getEndpoint().one(BASE_END_POINT, idConfiguracao);
		}

		function indeferir(idConfiguracao, indeferido) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT).post(indeferido);
		}

		function desfazerIndeferimento(idConfiguracao, idResultado) {
			return getBaseEndPoint(idConfiguracao).one(END_POINT, idResultado).remove();
		}

		function listIndeferidos(idConfiguracao) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT + '/completos').getList({
				limit: 999
			});
		}

		function getDefault(id) {
			return getMetadata(id).then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata(id) {
			var deferred = $q.defer();
			getBaseEndPoint(id).all(END_POINT).options().then(function(data) {
				deferred.resolve(data);
			}, function(data) {
				deferred.reject(data);
			});
			return deferred.promise;
		}

	}

	angular.module('educacao.matricula')
		.factory('educacao.matricula.ClassificacaoListaEsperaService', ClassificacaoListaEsperaService);

	ClassificacaoListaEsperaService.$inject = ['ServiceBuilder', '$q'];

	function ClassificacaoListaEsperaService(ServiceBuilder, $q) {
		var createdService = ServiceBuilder.create()
			.path('matricula')
			.build();

		var END_POINT = 'chamada/resultado/lista-espera';

		createdService.enviar = enviar;
		createdService.getDefault = getDefault;
		createdService.listListaEspera = listListaEspera;

		return createdService;

		function getBaseEndPoint(idConfiguracao) {
			return createdService.getEndpoint().one(BASE_END_POINT, idConfiguracao);
		}

		function enviar(idConfiguracao, classificado) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT).post(classificado);
		}

		function getDefault(id) {
			return getMetadata(id).then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function listListaEspera(idConfiguracao) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT).getList();
		}

		function getMetadata(id) {
			var deferred = $q.defer();
			getBaseEndPoint(id).all(END_POINT).options().then(function(data) {
				deferred.resolve(data);
			}, function(data) {
				deferred.reject(data);
			});
			return deferred.promise;
		}

	}

})();

(function() {
    'use strict';

    angular.module('educacao.historico-escolar')
        .controller('educacao.historicoEscolar.RemoveHistoricoEscolarCrlt', Controller);

    Controller.$inject = ['$injector', '$modalInstance'];

    function Controller($injector, $modalInstance) {
        var vm = this;
        vm.confirm = confirm;
        vm.refuse = refuse;
        vm.historico = _.cloneDeep($modalInstance.params.historico) || {};
        vm.aluno = _.cloneDeep($modalInstance.params.aluno) || {};
        vm.retornaNivelSeTiver = retornaNivelSeTiver;
        var StringBuilder = $injector.get('educacao.common.StringBuilder');

        function confirm() {
            return $modalInstance.close(true);
        }

        function refuse() {
            return $modalInstance.close(false);
        }

        function retornaNivelSeTiver() {
            var texto;
            if (vm.historico.nivel) {
                texto = StringBuilder.init()
                    .append('Esta ação irá <strong class="text-danger">excluir</strong> o Histórico Escolar do aluno <strong>')
                    .append(vm.aluno.pessoa.nome)
                    .append('</strong>, curso <strong>')
                    .append(vm.historico.curso)
                    .append('</strong>, modalidade <strong>')
                    .append(vm.historico.modalidade.description)
                    .append('</strong>, nível <strong>')
                    .append(vm.historico.nivel.description)
                    .append('</strong>, etapa <strong>')
                    .append(vm.historico.etapa || vm.historico.etapaMatriz.descricao)
                    .append('</strong>, ano de conclusão <strong>')
                    .append(vm.historico.anoConclusao)
                    .append('</strong>.');
            } else {
                texto = StringBuilder.init()
                    .append('Esta ação irá <strong class="text-danger">excluir</strong> o Histórico Escolar do aluno <strong>')
                    .append(vm.aluno.pessoa.nome)
                    .append('</strong>, curso <strong>')
                    .append(vm.historico.curso)
                    .append('</strong> modalidade <strong>')
                    .append(vm.historico.modalidade.description)
                    .append('</strong>, etapa <strong>')
                    .append(vm.historico.etapa || vm.historico.etapaMatriz.descricao)
                    .append('</strong>, ano de conclusão <strong>')
                    .append(vm.historico.anoConclusao)
                    .append('</strong>.');
            }
            return texto;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectTurmaRematricula', SelectTurmaDirective);

    SelectTurmaDirective.$inject = [];

    function SelectTurmaDirective() {
        return {
            restrict: 'E',
            require: 'ngModel',
            template: '<ui-select ng-model="ngModel" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngclass: '=',
                estabelecimento: '=?', 
                anoLetivo: '=?',  
                etapaMatriz: '=?', 
                turno: '=?', 
                formatResult: '=',
                matriz: '=?',
                multiturno: '=' 
            },
            controller: SelectTurmaDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectTurmaDirectiveController.$inject = ['$scope', 'educacao.rematricula.RematriculaService'];

    function SelectTurmaDirectiveController($scope, RematriculaService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {

            var filter = '(descricao like "' + params.term + '"';

            if ($scope.estabelecimento) {
                filter += ' and estabelecimento = ' + $scope.estabelecimento.id;
            }

            if($scope.anoLetivo){
                 filter += ' and anoLetivo = ' + $scope.anoLetivo.id;   
            }

            if ($scope.etapaMatriz) {
                if (_.isArray($scope.etapaMatriz)) {
                    if (!_.isEmpty($scope.etapaMatriz)) {
                        filter += ' and etapaMatriz in (' + _.pluck($scope.etapaMatriz, 'id').join(',') + ') ';
                    }
                } else {
                    filter += ' and etapaMatriz = ' + $scope.etapaMatriz.id;
                }
            }

            if ($scope.turno) {
                filter += ' and turno = "' + $scope.turno + '"';
            }

            if ($scope.matriz) {
                filter += ' and matriz = ' + $scope.matriz.id;
            }

            filter += ')';
            
            return RematriculaService.getListTurma(filter, params.offset, params.limit, 'descricao');
        }

        function formatResult(turma) {
            if($scope.multiturno){
                return turma.descricao;
            }
            return $scope.formatResult ? $scope.formatResult(turma) : turma.descricao;
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .directive('inputResponsaveis', inputResponsaveis);

    inputResponsaveis.$inject = [];

    function inputResponsaveis() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/responsaveis/input-responsaveis/input/input-responsaveis.directive.html',
            require: 'ngModel',
            scope: {
                ngModel: '=',
                required: '='
            },
            controller: InputResponsaveisCtrl,
            controllerAs: 'vm'
        };
    }

    InputResponsaveisCtrl.$inject = ['$scope', 'ModalResponsaveisService', '$element'];

    function InputResponsaveisCtrl($scope, ModalResponsaveisService, $element) {
        var vm = this;

        vm.click = click;
        vm.formatterTag = formatterTag;

        if (!$scope.ngModel) {
            $scope.ngModel = [];
        }

        function click() {
            ModalResponsaveisService.open($scope.ngModel).result.then(onClose);
        }

        function onClose() {
            $element.find('input').focus();
        }

        function formatterTag(responsavel) {
            if(responsavel.responsavel.pessoa.nome){
                return responsavel.responsavel.pessoa.nome;
            }else{
                return responsavel.pessoa.nome;
            }
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.formulaCalculoInstrumento')
        .controller('educacao.formulaCalculoInstrumento.FormulaCalculoInstrumentoCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$modalInstance',
        'API_URL',
        'bfc.ScriptEditor',
        'educacao.context.ContextoEstabelecimento',
        'educacao.formulaCalculoInstrumento.FormulaCalculoInstrumentoService',
        '$rootScope'

    ];

    function Controller(
        $scope,
        promiseTracker,
        $modalInstance,
        API_URL,
        ScriptEditor,
        ContextoEstabelecimento,
        FormulaCalculoInstrumentoService,
        $rootScope
    ) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.formulaCalculoInstrumento = $modalInstance.params.formulaCalculoInstrumento;
        vm.editarScript = editarScript;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.enumSituacaoCadastral = FormulaCalculoInstrumentoService.getSituacaoCadastral();

        var modo = {
            adicionando: !vm.formulaCalculoInstrumento,
            editando: vm.formulaCalculoInstrumento
        };

        vm.save = save;
        vm.cancel = fechaModal;
        vm.modo = modo;
        vm.remove = remove;

        init();

        function refresh() {
            $rootScope.$broadcast('formulacalculoinstrumento::refreshlist', {});
        }

        function init() {
            vm.windowTitle = modo.adicionando ? 'Adicionando fórmula' : 'Editando fórmula';
            verificaFormulario();

            vm.enumSituacaoCadastral = FormulaCalculoInstrumentoService.getSituacaoCadastral();
        }

        function save(openScript) {
            vm.formulaCalculoInstrumento.tipoFormulaCalculo = 'RESULTADO_INSTRUMENTOS_AVALIACAO';

            vm.tracker.loading.addPromise(
                FormulaCalculoInstrumentoService.save(vm.formulaCalculoInstrumento).then(function (data) {
                    vm.formulaCalculoInstrumento = data;
                    onSuccess(openScript);
                })
            );
        }

        function remove() {
            vm.tracker.loading.addPromise(
                FormulaCalculoInstrumentoService.remove(vm.formulaCalculoInstrumento).then(function (data) {
                    $modalInstance.close(data);
                })
            );
        }

        function editarScript(formulaToEdit) {
            ScriptEditor.modal({
                resource: API_URL.concat('/formula-calculo/' + formulaToEdit.id + '/script'),
                title: 'Editando script de fórmula de cálculo: ' + formulaToEdit.descricao,
                afterSave: refresh
            });
        }

        function onSuccess(openScript) {
            if (openScript) {
                editarScript(vm.formulaCalculoInstrumento);
                fechaModal();
            } else {
                fechaModal();
            }
        }

        function fechaModal() {
            $modalInstance.close(vm.formulaCalculoInstrumento);
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }
    }
})();


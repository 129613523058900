(function() {
    'use strict';

    angular.module('educacao.matriz')
        .directive('appSelectMatriz', SelectMatrizDirective);

    function SelectMatrizDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" ng-change="ngChange()" search="vm.searchMatriz"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                estabelecimento: '=?',
                tipoAtendimento: '=?',
                apenasPossuamTurmas: '=?',
                modular: '=?',
                ngChange: '&?'
            },
            controller: SelectMatrizDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectMatrizDirectiveController.$inject = ['$scope', 'educacao.matriz.MatrizService'];

    function SelectMatrizDirectiveController($scope, service) {

        var vm = this;

        vm.searchMatriz = searchMatriz;

        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }

        function searchMatriz(params) {

            var filter = '(descricao like "' + params.term + '" ';

            if ($scope.multiple) {
                var matrizIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(matriz) {
                        matrizIds.push(matriz.id);
                    });
                }
                if (matrizIds.length) {
                    filter += ' and id not in(' + matrizIds.join() + ')';
                }
            }
            
            if(!$scope.modular) {
                filter += ' and ' + service.getFilterModular();
            }

            filter += ')';

            if ($scope.apenasPossuamTurmas) {
                return service.getPossuamTurmas($scope.estabelecimento.id, $scope.tipoAtendimento, params.offset, params.limit, filter);
            }

            return service
                .getList(params.offset, params.limit, filter)
                .then(toPage);
        }
    }
})();


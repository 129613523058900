(function() {
    'use strict';

    angular.module('educacao.common')
        .controller('educacao.common.ConfiguracoesModalVisualizacaoHorasAulasCtrl', Controller);


    Controller.$inject = [
        '$modalInstance',
        'educacao.common.ModalCad'

    ];

    function Controller(
        $modalInstance,
        ModalCad
    ) {

        var vm = this;
        vm.openHorasAulas = openHorasAulas;

        vm.configuracaoHorasAulas = $modalInstance.params.configuracaoHorasAulas;
        vm.exibeEdicao = $modalInstance.params.exibeEdicao;


        function openHorasAulas(idHorasAulas) {
            ModalCad.open({
                templateUrl: 'matricula/horas-aula/horas-aulas.cad.html',
                controller: 'educacao.matricula.HorasAulasCadCtrl as vm',
                id: idHorasAulas,
                readOnly: true
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosAulasPorTurmaGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/table-dados-aulas-gestao-pessoas/table-dados-aulas-por-tempo-gestao-pessoas/table-dados-aulas-por-turma-gestao-pessoas/table-dados-aulas-por-turma-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                saldos: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento',
        'EdEnumsCacheService'
    ];

    function Controller(ContextoEstabelecimento, EdEnumsCacheService) {
        var vm = this;

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.showTableEstabelecimento = showTableEstabelecimento;
        vm.removerBorderTable = removerBorderTable;

        init();

        function showTableEstabelecimento() {
            return !vm.isSecretaria || _.get(vm.estabelecimentoSelected, 'id');
        }

        function removerBorderTable($last, $first, $index, substituicoes) {
            if ($index === 0 && _.size(substituicoes) === 1) {
                return false;
            }
            return !$last || $first;
        }

        function init() {
            EdEnumsCacheService.get('Turno').then(getEnumTurnos);
        }

        function getEnumTurnos(result) {
            vm.TURNOS = result;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .controller('educacao.gestao-pessoas-quadro-informativo.PopoverTurmasController', Controller);

    Controller.$inject = ['$rootScope', 'educacao.common.$commons', '$popoverInstance', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService'];

    function Controller($rootScope, $commons, $popoverInstance, Service) {

        var vm = this;
        vm.local = $popoverInstance.params.local;
        vm.professor = $popoverInstance.params.professor;
        vm.anoLetivoSelected = $popoverInstance.params.anoLetivo;
        init();

        function init() {
            if (!vm.local.estabelecimentoId) {
                return;
            }
            var professorId = _.get(vm.professor, 'id');
            var promise = Service.listTurmasLocalTrabalho(professorId, vm.local.estabelecimentoId, resolveParamns()).then(onLoadTurmas);
            $popoverInstance.tracker.addPromise(promise);
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoLetivoSelected, 'id');
            return paramns;
        }

        function onLoadTurmas(data) {
            if (_.isEmpty(data)) {
                ajustaPosicaoPopOver();
                return [];
            }

            vm.turmasLocaisTrabalhoPopover = _.groupBy(data, 'turma.turno');
            Object.keys(vm.turmasLocaisTrabalhoPopover).forEach(function (key) {
                vm.turmasLocaisTrabalhoPopover[key] = _.groupBy(vm.turmasLocaisTrabalhoPopover[key], 'turma.id');
            });
            ajustaPosicaoPopOver();
        }

        function ajustaPosicaoPopOver() {
            $commons.$timeout(function () {
                $popoverInstance.position();
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.ResponsaveisController', ResponsaveisCtrl);

    ResponsaveisCtrl.$inject = ['$scope', 'promiseTracker', '$modalStates', 'educacao.pessoas.ResponsavelService',
        'bfc.Notification','$enumsCache'];

    function ResponsaveisCtrl($scope, promiseTracker, $modalStates, ResponsavelService, Notification,$enumsCache) {

        var vm = this;

        vm.loadingTracker = promiseTracker();
        vm.responsavelService = ResponsavelService;

        vm.remove = remove;
        vm.openModal = openModal;
        vm.listagemControls = {};
        vm.expressionCustomBuilders = {};

        $enumsCache.get('DiaDaSemanaDto').then(function(retorno) {
                vm.options = {
                    diasSemana: retorno,
                };
        });

        function remove(responsavel) {
            var promise = ResponsavelService.remove(responsavel).then(function() {
                Notification.publish(' Responsável excluído com sucesso.', 'success');
                vm.listagemControls.search();
            });
            vm.loadingTracker.addPromise(promise);
            return promise;
        }

        function openModal(responsavel) {
            $modalStates.goModalState(responsavel);
        }
    }
})();

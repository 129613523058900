/* jshint ignore:start */
(function () {
    'use strict';

    angular.module('fusioncharts-order')
        .value('fusioncharts-order', 'fusioncharts-order');
})();
/*
 Ocean Theme v0.0.3
 FusionCharts JavaScript Library

 Copyright FusionCharts Technologies LLP
 License Information at <http://www.fusioncharts.com/license>
 */

/* jshint ignore:end */
(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appAccordionNivelEscolar', appAccordionNivelEscolar);

    function appAccordionNivelEscolar() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-accordion-visao-estabelecimento/app-accordion-nivel-escolar/app-accordion-nivel-escolar.directive.html',
            scope: {},
            bindToController: {
                modalidadeGroup: '=',
                estabelecimentoId: '=',
                anoLetivoId: '=',
                estabelecimentoNome: '=',
                turmaFilters: '=',
                turmaSelecionada: '=',
                componenteCurricularFilter: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'EdEnumsCacheService',
        'educacao.ocupacao-aulas-quadro-informativo.AccordionVisaoGeralUtilsService'
    ];

    function Controller(EdEnumsCacheService, AccordionVisaoGeralUtilsService) {
        var vm = this;

        vm.COMPLEMENTAR = 'COMPLEMENTAR';

        vm.saldosPorNivelEscolar = {};

        init();

        function init() {
            sumSaldoPorNivelEscolar();
            EdEnumsCacheService.get('NivelEscolar').then(function(result) {
                vm.NIVEL_ESCOLAR = result;
            });
        }

        function sumSaldoPorNivelEscolar() {
            vm.saldosPorNivelEscolar = {};

            if(vm.modalidadeGroup.nivelModalidade === vm.COMPLEMENTAR) {
                return;
            }

            _.forEach(vm.modalidadeGroup.porNivelEscolar, function (porNivelEscolar) {
                var nivelEscolar = _.first(porNivelEscolar).nivelEscolar;

                if (!vm.saldosPorNivelEscolar[nivelEscolar]) {
                    vm.saldosPorNivelEscolar[nivelEscolar] = {
                        saldoInicial: 0,
                        saldoOcupado: 0,
                        saldoAtual: 0
                    };
                }

                AccordionVisaoGeralUtilsService.sumSaldoByTipoSaldo(vm.saldosPorNivelEscolar[nivelEscolar], porNivelEscolar);
            });
        }
    }
})();
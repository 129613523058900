(function() {
	'use strict';

	angular.module('educacao.desempenhoescolar').directive('appDesempenhoTable', appDesempenhoTable);

	function appDesempenhoTable() {
		return {
			restrict: 'E',
			templateUrl: 'desempenho-escolar/app-desempenho-table.directive.html'
		};
	}
})();


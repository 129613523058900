(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .service('ConfiguracaoAvaliacaoTurmaModalService', ConfiguracaoAvaliacaoTurmaModalService);

    ConfiguracaoAvaliacaoTurmaModalService.$inject = [
        'ui.components.Modal'
    ];

    function ConfiguracaoAvaliacaoTurmaModalService($modal) {

        var self = this;
        self.open = open;

        function open(turmas) {
         
            return $modal.open({
                templateUrl: 'matricula/turma/configuracao-avaliacao/configuracao-avaliacao-turma.html',
                controller: 'configuracao-avaliacao-turma.ConfiguracaoAvaliacaoTurmaController',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    turmas: turmas
                }
            });
        }
    }

})();

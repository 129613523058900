(function() {

    'use strict';

    angular.module('educacao.matricula')
        .directive('appSelectChaGroup', AppSelectChaGroup);

    function AppSelectChaGroup() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" minimum-results-for-search="0" bf-required="ngRequired" search="vm.searchCha" add-value="vm.add" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                chaExistentes: '=?',
                etapaMatrizDisciplina: '=',
                tipoCha: '@',
                ngChange: '&?',
                dropdownCssClass: '=?'
            },
            controller: SelectController,
            controllerAs: 'vm'
        };
    }

    SelectController.$inject = [
        '$scope',
        'ui.components.Modal',
        'bfc.$$PermissionsService',
        'educacao.matriz.chaService'
    ];

    function SelectController($scope, uiModal, PermissionsService, chaService) {

        var vm = this;

        vm.formatResult = formatResult;
        vm.searchCha = searchCha;
        vm.add = !PermissionsService.isRevokedOperation('ChaPageMapping', 'criar') && add;

        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            if (_.get($scope.ngModel, 'tipo') !== $scope.tipoCha) {
                $scope.ngModel = null;
            }

            if (_.isFunction($scope.ngChange)) {
                $scope.ngChange();
            }
        });

        function searchCha(params) {

            var filter = '((descricao like "' + params.term + '" or identificador like "' + params.term + '")';

            var etapaMatrizDisciplina = _.get($scope.etapaMatrizDisciplina, 'id') || -1;

            var competencias = [];

            if (angular.isArray($scope.chaExistentes) && $scope.chaExistentes.length > 0) {
                _.forEach($scope.chaExistentes, function(value) {
                    if (value && value.id) {
                        competencias.push(value.id);
                    }
                });
            }

            if (competencias.length) {
                filter += ' and (id not in(' + competencias.join() + '))';
            }

            if ($scope.tipoCha) {
                filter += ' and tipo = "' + $scope.tipoCha + '"';
            }
            filter += ')';

            return chaService.getChaAgrupadoDisciplina(filter, etapaMatrizDisciplina).then(filterResult);

        }

        function filterResult(data) {
            var result = [];

            if (!_.isEmpty(data.chasByEtapaMatrizDisciplina)) {
                result.push({
                    descricao: 'Registros da matriz curricular',
                    children: data.chasByEtapaMatrizDisciplina
                });
            }

            if (!_.isEmpty(data.chasByCompetenciaCHA)) {
                result.push({
                    descricao: 'Registros do cadastro de competências',
                    children: data.chasByCompetenciaCHA
                });
            }

            return {
                content: result,
                hasNext: false
            };
        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'cadastro-cha/cha-cad.html',
                controller: 'educacao.matriz.ChaCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    cha: {
                        descricao: descricao,
                        tipo: $scope.tipoCha
                    },
                    canSaveAndAdd: false
                }
            }).result;
        }

        function formatResult(data) {
            if (!data.identificador) {
                return data.descricao;
            }

            return data.identificador + ' - ' + data.descricao;
        }
    }

})();

(function() {

    'use strict';

    angular.module('educacao.pessoas')
        .service('educacao.pessoas.PessoaService', PessoaService);

    PessoaService.$inject = ['Restangular'];

    function PessoaService(Restangular) {
        var self = this;

        self.get = get;
        self.getPessoas = getPessoas;
        self.cargaTemplate = cargaTemplate;

        function get(id) {
            return Restangular.all('pessoa').one('pessoas', id).get()
                .then(Restangular.stripRestangular);
        }

        function getPessoas(id) {
            return Restangular.one('pessoa/pessoas')
                .one('template', id)
                .get()
                .then(Restangular.stripRestangular);
        }

        function cargaTemplate() {
            return Restangular.one('pessoa/pessoas')
                .all('template')
                .one('carga')
                .post();
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.LotacaoFisicaCadCtrl', Controller);

    Controller.$inject = [
        '$scope',
        '$modalTracker',
        '$q',
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        'educacao.funcionario.LotacaoFisicaService'
    ];

    function Controller($scope,
        $modalTracker,
        $q,
        promiseTracker,
        $modalInstance,
        focus,
        LotacaoFisicaService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();

        vm.save = save;
        vm.lotacao = $modalInstance.params.lotacao || {};
        vm.remove = remove;

        vm.modo = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.lotacao.id
        };

        init();

        function init() {
            verificaFormulario();
            vm.windowTitle = vm.modo.adicionando ? 'Adicionando lotação física' : 'Editando lotação física';
            focus('lbLotacaoFisica');            
        }

        function save(continuar) {
            vm.tracker.loading.addPromise(
                LotacaoFisicaService.save(vm.lotacao).then(function(data) {
                    onSuccess(continuar,data);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                LotacaoFisicaService.remove(vm.lotacao).then($modalInstance.close)
            );
        }


        function onSuccess(continuar,data) {
            vm.lotacao = {};
            if(continuar){
                vm.lotacao = {};
                verificaFormulario();
            } else {
                $modalInstance.close(data);
            }
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
                focus('lbLotacaoFisica');
            }
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.funcionario')
        .directive('situacaoFuncionario', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/app-label-situacao.directive.html',
            scope: {},
            bindToController: {
                situacao: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.funcionario.FuncionarioUtilsService'
    ];

    function Controller(FuncionarioUtilsService) {

        var vm = this;
        vm.isSituacaoTrabalhando = FuncionarioUtilsService.isSituacaoTrabalhando(vm.situacao);
        vm.isSituacaoExonerado = FuncionarioUtilsService.isSituacaoExonerado(vm.situacao);
        vm.isSituacaoDemitido = FuncionarioUtilsService.isSituacaoDemitido(vm.situacao);
        vm.isSituacaoAposentado = FuncionarioUtilsService.isSituacaoAposentado(vm.situacao);
        vm.isSituacaoFalecido = FuncionarioUtilsService.isSituacaoFalecido(vm.situacao);
        vm.isSituacaoRemovido = FuncionarioUtilsService.isSituacaoRemovido(vm.situacao);
    
    }

})();


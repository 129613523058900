(function() {
	'use strict';

	angular.module('educacao.configuracaoConteudoMinistrado')
		.controller('educacao.configuracaoConteudoMinistrado.ConfiguracaoConteudoMinistradoCtrl',
			ConfiguracaoConteudoMinistradoCtrl);

	ConfiguracaoConteudoMinistradoCtrl.$inject = [
        '$scope',
        'promiseTracker',
        'educacao.configuracaoConteudoMinistrado.ConfiguracaoConteudoMinistradoService',
        'educacao.context.ContextoEstabelecimento',
        'TIPOS_CONFIGURACOES_CONTEUDO_MINISTRADO',
        'bfc.Notification',
        'educacao.context.ContextoAnoLetivo'
    ];

	var labelAlteraveis = [
        'TEMA',
        'OBJETIVOS_GERAIS',
        'OBJETIVOS_ESPECIFICOS',
        'METODOLOGIA',
        'PRATICAS',
        'RECURSOS',
        'REFERENCIAS',
		'PERIODO',
		'COMPONENTE',
		'ANP',
		'GUIA_COMPONENTES_CURRICULARES',
		'GUIA_DETALHAMENTO_CONTEUDO',
		'GUIA_DADOS_ADICIONAIS',
		'GUIA_ANEXO',
		'GUIA_LINK',
		'GUIA_ATIVIDADES',
		'CARGA_HORARIA',
		'COMPETENCIA',
		'CONHECIMENTO',
		'HABILIDADE',
		'ATITUDE',
		'ATIVIDADE',
		'AULA',
		'OBJETIVOS_APRENDIZAGEM',
		'DESCONSIDERAR_ANP',
		'GUIA_TEXTOS_ADICIONAIS'
    ];
	var urlConfiguracaoEstabelecimento = 'configuracoes-estabelecimentos';
	var urlConfiguracaoSecretaria = 'configuracoes-secretarias';
	var urlConfiguracao = '';

	function ConfiguracaoConteudoMinistradoCtrl($scope,
		promiseTracker,
		ConfiguracaoConteudoMinistradoService,
		ContextoEstabelecimento,
		TIPOS_CONFIGURACOES_CONTEUDO_MINISTRADO,
		Notification,
		ContextoAnoLetivo) {

		var vm = this;
		vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
		vm.onClickMenus = onClickMenus;
		vm.saveConfiguracao = saveConfiguracao;
		vm.restaurarConfiguracao = restaurarConfiguracao;
		vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
		vm.estabelecimento = ContextoEstabelecimento.getValorContextoEstabelecimento();
		vm.clickObrigatorio = clickObrigatorio;
		vm.mostraInformacaoConteudoMinistrado = mostraInformacaoConteudoMinistrado;
		vm.isCadastraCampoPersonalizado = isCadastraCampoPersonalizado;
		vm.tracker = {
			getDadosConfig: promiseTracker(),
			restaurar: promiseTracker(),
			save: promiseTracker()
		};

		vm.tiposConfiguracoes = TIPOS_CONFIGURACOES_CONTEUDO_MINISTRADO;
		vm.isGuia = isGuia;
		vm.disableGuia = disableGuia;
		vm.camposVisiveis = camposVisiveis;
		vm.isConfiguracaoPersonalizada = isConfiguracaoPersonalizada;
		vm.getTextoOrigemConfiguracao = getTextoOrigemConfiguracao;

		inicializarConfiguracao();

		function onClickMenus(keyMenu) {
			vm.tiposConfiguracoes[keyMenu].onClick();
		}

		function inicializarConfiguracao() {
			angular.forEach(vm.tiposConfiguracoes, function(tipoConfig) {
				tipoConfig.onClick = function() {
					getConfiguracao(tipoConfig.value);
				};
			});

			if (vm.isSecretaria) {
				urlConfiguracao = urlConfiguracaoSecretaria;
				return;
			}
			urlConfiguracao = urlConfiguracaoEstabelecimento;
		}

		function clickObrigatorio(planoAula) {
			if (planoAula.obrigatorio) {
				planoAula.visivel = true;
			}
		}

		function getConfiguracao(tipoConfiguracao) {
			vm.tracker.getDadosConfig.addPromise(
				ConfiguracaoConteudoMinistradoService.getConfiguracao(urlConfiguracao, tipoConfiguracao)
				.then(function(dados) {
					vm.configuracao = dados;
					parametrizarInformacoesConfiguracaoItens();
				})
			);
		}

		function saveConfiguracao() {
			setDesconsiderarAnp();
			var configuracao = angular.copy(vm.configuracao);
			vm.tracker.save.addPromise(
				ConfiguracaoConteudoMinistradoService.save(fromRepresentationConteudoMinistradoItens(configuracao))
				.then(function(dadosPersistidos) {
					vm.configuracao.id = dadosPersistidos.id;
					vm.configuracao.origemConfiguracao = getOrigemConfiguracaoTela();
					Notification.publish('Configuração de conteúdo ministrado salvo com sucesso', 'success');
				})
			);
		}

		function getOrigemConfiguracaoTela() {
			return vm.isSecretaria ? 'CONTEUDO_MINISTRADO_SECRETARIA' : 'CONTEUDO_MINISTRADO_ESTABELECIMENTO';
		}

		function restaurarConfiguracao() {
			vm.tracker.restaurar.addPromise(
				ConfiguracaoConteudoMinistradoService.restaurarConfiguracao(_.get(vm.configuracao, 'id'))
					.then(function() {
						Notification.publish('Configuração de conteúdo ministrado restaurada com sucesso', 'success');
						getConfiguracao(_.get(vm.configuracao, 'tipoConfiguracao'));
					})
			);
		}

		function setDesconsiderarAnp() {
			_.chain(vm.configuracao.configuracaoConteudoMinistradoItens)
				.filter(getDesconsiderarAnp)
				.map(atualizaDesconsiderarAnp)
				.value();
		}

		function getDesconsiderarAnp(item) {
			return _.get(item, 'itemConteudoMinistrado.key') === 'DESCONSIDERAR_ANP';
		}
		
		function atualizaDesconsiderarAnp(item) {
			item.visivel = vm.desconsiderarAnp.visivel;
		}

		function fromRepresentationConteudoMinistradoItens(configuracao) {
			angular.forEach(configuracao.configuracaoConteudoMinistradoItens, function(item) {
				item.itemConteudoMinistrado = item.itemConteudoMinistrado.value;
			});
			return configuracao;
		}

		function toRepresentationConteudoMinistradoItens(configuracao) {
			angular.forEach(configuracao.configuracaoConteudoMinistradoItens, function(item) {
				var itemConteudoMinistrado = {
					id: vm.enumItemConteudoMinistrado[item.itemConteudoMinistrado].value,
					value: vm.enumItemConteudoMinistrado[item.itemConteudoMinistrado].key,
					key: vm.enumItemConteudoMinistrado[item.itemConteudoMinistrado].key,
					description: getDescription(vm.enumItemConteudoMinistrado[item.itemConteudoMinistrado])
				};
				item.itemConteudoMinistrado = angular.copy(itemConteudoMinistrado);
			});
			vm.desconsiderarAnp = _.find(vm.configuracao.configuracaoConteudoMinistradoItens, 'itemConteudoMinistrado','DESCONSIDERAR_ANP');
			return configuracao;
		}

		function parametrizarInformacoesConfiguracaoItens() {
			var promisse = ConfiguracaoConteudoMinistradoService
				.getItemConteudoMinistrado()
				.then(function(itensEnum) {
					vm.enumItemConteudoMinistrado = itensEnum;
					if (vm.configuracao.id || vm.configuracao.configuracaoConteudoMinistradoItens.length) {
						desabilitaCamposObrigatorios(itensEnum);
						var configuracao = angular.copy(vm.configuracao);
						vm.configuracao = toRepresentationConteudoMinistradoItens(configuracao);
						vm.configuracao.configuracaoConteudoMinistradoItens = getConteudoMinistradoItens(itensEnum, vm.configuracao.configuracaoConteudoMinistradoItens);
						return;
					}

					vm.configuracao.configuracaoConteudoMinistradoItens = getConteudoMinistradoItensDefault(itensEnum);
					vm.desconsiderarAnp = _.find(vm.configuracao.configuracaoConteudoMinistradoItens, 'itemConteudoMinistrado.key','DESCONSIDERAR_ANP');
				});
			vm.tracker.getDadosConfig.addPromise(promisse);
			return promisse;
		}

		function getConteudoMinistradoItensDefault(itensEnum) {
			return getConteudoMinistradoItens(itensEnum, []);
		}

		function getConteudoMinistradoItens(itensEnum, configuracaoConteudoMinistradoItens) {
			var itens = [];
			angular.forEach(itensEnum.values, function(item) {
				var itemConfiguracao = _.find(configuracaoConteudoMinistradoItens, 'itemConteudoMinistrado.key', item.key);
				if (itemConfiguracao) {
					itens.push(itemConfiguracao);
					return;
				}

				if (item.key === 'OBJETIVOS_APRENDIZAGEM' && !isEducacaoBasicaInfantil()) {
					return;
				}

				itemConfiguracao = {
					id: null,
					visivel: !!((item.key === 'OBJETIVOS_APRENDIZAGEM') || disabledItemPlanoAulaComponente(item.key) || disabledItemPlanoAulaAtividade(item.key) || camposVisiveis(item.key)),
					obrigatorio: !!(disabledItemPlanoAulaComponente(item.key) || disabledItemPlanoAulaAtividade(item.key)),
					desabilitado: !!(disabledItemPlanoAulaComponente(item.key) || disabledItemPlanoAulaAtividade(item.key)),
					itemConteudoMinistrado: {
						id: item.value,
						value: item.key,
						key: item.key,
						description: getDescription(item)
					}
				};
				itens.push(itemConfiguracao);
			});
			return itens;
		}

		function getDescription(item) {
			if (isGuia(item.key)) {
				return 'Guia ' + item.description;
			}
			return item.description;
		}

		function desabilitaCamposObrigatorios() {
			angular.forEach(vm.configuracao.configuracaoConteudoMinistradoItens, function(configuracaoConteudoMinistradoItem) {
				configuracaoConteudoMinistradoItem.desabilitado = !!(disabledItemPlanoAulaComponente(configuracaoConteudoMinistradoItem.itemConteudoMinistrado) ||
					disabledItemPlanoAulaAtividade(configuracaoConteudoMinistradoItem.itemConteudoMinistrado));
			});
		}

		function itemPlanoAulaObrigatorio(infoPlanoAula) {
			return infoPlanoAula === 'TURMA' || infoPlanoAula === 'TEMA' || infoPlanoAula === 'PERIODO';
		}

		function disabledItemPlanoAulaComponente(infoPlanoAula) {
			return (itemPlanoAulaObrigatorio(infoPlanoAula) || infoPlanoAula === 'COMPONENTE') && (isEducacaoBasica() || isComplementar() || isEJA() ||
				isAceleracao());
		}

		function disabledItemPlanoAulaAtividade(infoPlanoAula) {
			return (itemPlanoAulaObrigatorio(infoPlanoAula) || infoPlanoAula === 'ATIVIDADE') && (isAEE() || isAtividadeComplementar());
		}

		function isEducacaoBasicaInfantil() {
			return isEducacaoBasica() && vm.configuracao.nivelEscolar === 'EDUCACAO_INFANTIL';
		}

		function isEducacaoBasica() {
			return vm.configuracao.nivelModalidade === 'EDUCACAO_BASICA';
		}

		function isComplementar() {
			return vm.configuracao.nivelModalidade === 'COMPLEMENTAR';
		}

		function isAtividadeComplementar() {
			return vm.configuracao.tipoConfiguracao === 'ATIVIDADE_COMPLEMENTAR';
		}

		function isAceleracao() {
			return vm.configuracao.nivelModalidade === 'ACELERACAO';
		}

		function isEJA() {
			return vm.configuracao.nivelModalidade === 'EJA';
		}

		function isAEE() {
			return vm.configuracao.tipoConfiguracao === 'AEE';
		}

		function isGuia(item) {
			return item === 'GUIA_COMPONENTES_CURRICULARES' || 
				item === 'GUIA_DETALHAMENTO_CONTEUDO' || 
				item === 'GUIA_DADOS_ADICIONAIS' || 
				item === 'GUIA_ANEXO' || 
				item === 'GUIA_LINK' ||
				item === 'GUIA_ATIVIDADES' ||
				item === 'GUIA_TEXTOS_ADICIONAIS';
		}

		function disableGuia(item) {
			if (!isGuia(item)) {
				return false;
			}
			return item !== 'GUIA_LINK';
		}

		function camposVisiveis(item) {
			return item === 'CARGA_HORARIA' ||
				item === 'ANP' ||
				isGuia(item);
		}

		function mostraInformacaoConteudoMinistrado(planoAula) {
			if (_.get(planoAula, 'itemConteudoMinistrado') && (planoAula.itemConteudoMinistrado.key === 'COMPONENTE' || planoAula.itemConteudoMinistrado.key === 'GUIA_COMPONENTES_CURRICULARES')) {
				return !isAEE() && !isAtividadeComplementar();
			}
			if (_.get(planoAula, 'itemConteudoMinistrado') && (planoAula.itemConteudoMinistrado.key === 'ATIVIDADE' || planoAula.itemConteudoMinistrado.key === 'GUIA_ATIVIDADES')) {
				return isAEE() || isAtividadeComplementar();
			}
			return true;
		}

		function isCadastraCampoPersonalizado(item) {
			return _.includes(labelAlteraveis, item);
		}

		function isConfiguracaoPersonalizada(){
			return getOrigemConfiguracaoTela() === _.get(vm.configuracao, 'origemConfiguracao');
		}

		function getTextoOrigemConfiguracao() {
			switch (_.get(vm.configuracao, 'origemConfiguracao')) {
				case 'PLANEJAMENTO_AULA_SECRETARIA':
				case 'DEFAULT':
					return 'Planejamento de Aula - Secretaria';
				case 'PLANEJAMENTO_AULA_ESTABELECIMENTO':
					return 'Planejamento de Aula - Estabelecimento';
				case 'CONTEUDO_MINISTRADO_SECRETARIA':
					return 'Conteúdo Ministrado - Secretaria';
				case 'CONTEUDO_MINISTRADO_ESTABELECIMENTO':
					return 'Conteúdo Ministrado - Estabelecimento';
			}
		}
	}
})();

(function () {
    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.$commons', CommonsService);

    CommonsService.$inject = ['$injector'];
    function CommonsService($injector) {
        return {
            $q: $injector.get('$q'),
            $timeout: $injector.get('$timeout'),
            $filter: $injector.get('$filter'),
            $window: $injector.get('$window'),
            $promiseTracker: $injector.get('promiseTracker'),
            $notification: $injector.get('bfc.Notification'),
            $state: $injector.get('$state'),
            $modal: $injector.get('ui.components.Modal'),
        };
    }
})();
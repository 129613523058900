(function () {
    'use strict';

    angular.module('educacao.quadro-informativo-utils')
        .directive('listEstabelecimentosQuadroInformativo', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/quadro-informativo-utils/list-estabelecimento-quadro-informativo/list-estabelecimentos-quadro-informativo.directive.html',
            scope: {},
            bindToController: {
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'educacao.ocupacao-aulas-quadro-informativo.VisaoGeralQuadroInformativoService',
        'promiseTracker',
        '$scope'
    ];

    function Controller(ContextoEstabelecimento, ContextoAnoLetivo, VisaoGeralQuadroInformativoService, promiseTracker, $scope) {
        var vm = this;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();

        vm.limit = 15;
        vm.hasNext = true;
        vm.isSearching = false;

        vm.loadListagemEstabelecimentoScrollPaginator = loadListagemEstabelecimentoScrollPaginator;
        vm.isEmptyEstabelecimentos = isEmptyEstabelecimentos;
        vm.loadEstabelecimentos = loadEstabelecimentos;

        $scope.$watch('vm.anoLetivoSelected', loadEstabelecimentos);

        vm.loadingTracker = {
            estabelecimento: promiseTracker(),
            estabelecimentoScroll: promiseTracker()
        };

        function loadEstabelecimentos() {
            var params = {};

            if (vm.estabelecimentoFilter) {
                params.filter = 'id = ' + vm.estabelecimentoFilter.id;
            }

            if(_.get(vm.anoLetivoSelected,'id')){
                params.anoLetivoId = _.get(vm.anoLetivoSelected,'id');
            }

            var promise = VisaoGeralQuadroInformativoService.getListagemEstabelecimentos(params).then(function (data) {
                vm.hasNext = data.hasNext;
                vm.estabelecimentos = data.content;
            });

            vm.loadingTracker.estabelecimento.addPromise(promise);
        }

        function isEmptyEstabelecimentos() {
            return _.isEmpty(vm.estabelecimentos);
        }

        function loadListagemEstabelecimentoScrollPaginator(limit, hasNext) {
            vm.limit = limit;
            vm.isSearching = true;

            if (hasNext) {
                var params = {
                    filter: '',
                    limit: vm.limit,
                    sort: '',
                    anoLetivoId: _.get(vm.anoLetivoSelected,'id')
                };

                var promise = VisaoGeralQuadroInformativoService.getListagemEstabelecimentos(params).then(function (data) {
                    vm.hasNext = data.hasNext;
                    vm.estabelecimentos = data.content;
                }).finally(function () {
                    vm.isSearching = false;
                });

                vm.loadingTracker.estabelecimentoScroll.addPromise(promise);
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.geracaoHorarios').directive('uiDrop', DropDirective);

    DropDirective.$inject = [];
    function DropDirective() {
        return {
            restrict: 'A',
            controller: DropDirectiveController
        };
    }

    DropDirectiveController.$inject = ['$scope', '$element', '$attrs'];
    function DropDirectiveController($scope, $element, $attrs) {

        $scope.$watch(function () {
            return $scope.$eval($attrs.uiDrop);
        }, function (value) {
            if (value !== false) {
                run();
            }
        });

        function run() {
            $element.droppable({
                tolerance: 'pointer',
                drop: onDrop
            });
        }

        function onDrop(event, data) {
            var draggable = data.draggable;
            var dragObject = draggable.attr('drag-object');
            var dropObject = $scope.$eval($attrs.dropObject);
            dragObject = $scope.$eval(dragObject);

            var ob = dropObject ? _.extend({dragObject: dragObject}, dropObject) : dragObject;

            var dropSuccess = $scope.$eval($attrs.dropSuccess);
            $scope.$apply((dropSuccess || angular.noop)(ob));
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.ocupacao-aulas-quadro-informativo')
    .directive('appAccordionModalidade', appAccordionModalidade);

    function appAccordionModalidade() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/ocupacao-aulas/visao-estabelecimento/app-accordion-visao-estabelecimento/app-accordion-modalidade/app-accordion-modalidade.directive.html',
            scope: {},
            bindToController: {
                matrizes: '=',
                estabelecimentoId: '=',
                anoLetivoId: '=',
                estabelecimentoNome: '=',
                turmaFilters: '=',
                turmaSelecionada: '=',
                componenteCurricularFilter: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope', 
        'EdEnumsCacheService'
    ];

    function Controller($scope, EdEnumsCacheService) {
        var vm = this;

        vm.COMPLEMENTAR = 'COMPLEMENTAR';

        vm.groupByNivelEscolar = groupByNivelEscolar;
        vm.saldosPorNivelModalidade = {};

        $scope.$watch('vm.matrizes', init);

        init();

        function init() {
            EdEnumsCacheService.get('NivelModalidade').then(function(result) {
                vm.NIVEL_MODALIDADE = result;
            }).then(groupMatrizes);
        }

        function groupMatrizes() {
            if (_.isEmpty(vm.matrizes)) {
                return;
            }

            var porModalidades = _.groupBy(vm.matrizes, 'nivelModalidade');
            vm.groupedMatrizes = _.map(porModalidades, groupByNivelEscolar);
            sumSaldoPorNivelModalidade();
        }

        function groupByNivelEscolar(porModalidade) {
            if (_.isEmpty(porModalidade)) {
                return;
            }

            var nivelModalidade = _.first(porModalidade).nivelModalidade;

            if(vm.COMPLEMENTAR === nivelModalidade) {
                return { 
                    nivelModalidade: nivelModalidade, 
                    porNivelEscolar: porModalidade 
                };
            }

            return { 
                nivelModalidade: nivelModalidade, 
                porNivelEscolar: _.groupBy(porModalidade, 'nivelEscolar') 
            };
        }

        function sumSaldoPorNivelModalidade() {
            vm.saldosPorNivelModalidade = {};

            _.forEach(vm.groupedMatrizes, function (matriz) {
                if (!vm.saldosPorNivelModalidade[matriz.nivelModalidade]) {
                    vm.saldosPorNivelModalidade[matriz.nivelModalidade] = {
                        saldoInicial: 0,
                        saldoOcupado: 0,
                        saldoAtual: 0
                    };
                }

                sumSaldoByTipoSaldo(vm.saldosPorNivelModalidade[matriz.nivelModalidade], matriz);
            });
        }

        function sumSaldoByTipoSaldo(saldo, item) {
            saldo.saldoInicial += reduceSum('saldoInicial', item);
            saldo.saldoOcupado += reduceSum('saldoOcupado', item);
            saldo.saldoAtual += reduceSum('saldoAtual', item);
        }

        function reduceSum(tipoSaldo, item) {
            var sum = 0;

            if(item.nivelModalidade === vm.COMPLEMENTAR) {
                return _.sum(item.porNivelEscolar, tipoSaldo);
            }

            _.forEach(item.porNivelEscolar, function(porNivel) {
                sum += _.sum(porNivel, tipoSaldo);
            });

            return sum;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.common')

        .directive('edScrollPaginator', ['$timeout', function ($timeout) {
            return {
                restrict: 'A',
                scope: {
                    paginationScroll: '&',
                    paginationLimit: '=',
                    hasNext: '=',
                    isSearching: '=',

                },
                link: function (scope, element) {
                    var DEFAULT_LIMIT = 10;

                    scope.limit = scope.paginationLimit || DEFAULT_LIMIT;

                    var hasNext = scope.hasNext || true;


                    loadInitialValues();

                    function loadInitialValues() {
                        $timeout(function () {
                            scope.paginationScroll({
                                limit: scope.limit,
                                hasNext: scope.hasNext,
                            });
                        });
                    }


                    element.on('scroll', function () {
                        checkScrollEnd();
                    });

                    function checkScrollEnd() {
                        var scrollTop = element.scrollTop();
                        var scrollHeight = element.prop('scrollHeight');
                        var clientHeight = element.prop('clientHeight');
                        var atEnd = scrollTop + clientHeight + 1 >= scrollHeight;
                        if (atEnd && !scope.isSearching && hasNext) {
                            updatedPaginator();
                        }
                    }

                    function updatedPaginator() {
                        scope.$apply(function () {
                            scope.paginationScroll({
                                limit: calculateLimit(), // Use 'scope' para acessar a variável 'limit'
                                hasNext: scope.hasNext,
                            });
                        });
                    }

                    function calculateLimit() {
                        scope.limit = scope.limit + scope.limit;
                        return scope.limit;
                    }
                }
            };
        }]);

})();

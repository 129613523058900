(function() {
    'use strict';

    var DAY_CLICKED_EVENT = 'mini-month.day-clicked';

    angular.module('educacao.calendario')
        .constant('MINI_MONTH_DAY_CLICKED_EVENT', DAY_CLICKED_EVENT)
        .directive('miniMonthCalendar', miniMonthCalendar);

    function miniMonthCalendar() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/view-year/mini-month.directive.html',

            scope: {
                month: '=',
                workdays: '=?',

                showWorkdaysCount: '=',
                showHolydaysControls: '=',
                showEventsControls: '=',
                hideTitle: '=',

                calendarioDisplay: '=',

                hideRestrictedDate: '=?',
                restrictInitialDate: '=?',
                restrictFinalDate: '=?'
            },

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'DATE_FORMAT',

        'CLASS_WORKDAY',
        'CLASS_HOLYDAY',
        'CLASS_EVENT',
        'COLOR_CLASS_VARIOUS_EVENTS',

        'HOLYDAY_POPOVER_LIST_TEMPLATE',
        'EVENT_POPOVER_LIST_TEMPLATE',

        '$scope',
        '$element',

        'InstallDirective',
        'ColorHelper'
    ];

    function Controller(DATE_FORMAT,
        CLASS_WORKDAY,
        CLASS_HOLYDAY,
        CLASS_EVENT,
        COLOR_CLASS_VARIOUS_EVENTS,
        HOLYDAY_POPOVER_LIST_TEMPLATE,
        EVENT_POPOVER_LIST_TEMPLATE,
        $scope,
        $element,
        InstallDirective,
        ColorHelper) {

        var vm = this;

        vm.options = {
            dateAttributeFormat: DATE_FORMAT,
            hideTitle: false
        };
        vm.dayRender = dayRender;
        vm.onDayClicked = onDayClicked;

        initObservers();

        function initObservers() {
            vm.restrictInitialDate = $scope.restrictInitialDate ? moment($scope.restrictInitialDate, DATE_FORMAT) : null;
            vm.restrictFinalDate = $scope.restrictFinalDate ? moment($scope.restrictFinalDate, DATE_FORMAT) : null;

            $scope.$watch('month', function(newValue) {
                vm.monthMoment = moment(newValue);
            });

            $scope.$watch('vm.monthMoment', updateMonth);
            $scope.$on('miniCalendar.created', updateWorkdaysCount);

            $scope.$watch('hideTitle', function(newValue) {
                vm.options.hideTitle = newValue;
            });

            $scope.$on('$destroy', function() {
                $element.trigger('$destroy').remove();
            });
        }

        function updateMonth(newValue) {
            if (vm.month === newValue) {
                return;
            }
            vm.month = newValue;
            updateWorkdaysCount();
        }

        function onDayClicked(date, element) {
            $scope.$apply($scope.$emit(DAY_CLICKED_EVENT, {
                date: date,
                hasEvents: element.hasClass(CLASS_HOLYDAY),
                hasHolyday: element.hasClass(CLASS_EVENT),
                isWorkday: element.hasClass(CLASS_WORKDAY)
            }));
        }

        function dayRender(element, date) {
            if (!$scope.calendarioDisplay) {
                return;
            }

            var day = moment(date, DATE_FORMAT);

            if (isRestricted(day)) {
                element.addClass('hide');
                return;
            }

            element.addClass('dia');

            if ($scope.calendarioDisplay.isDiaLetivo(day)) {
                element.addClass('dia-letivo');
            }

            var holyday = $scope.calendarioDisplay.getFeriados(date);
            if (holyday) {
                renderHolyday(element, holyday);
            }

            var events = $scope.calendarioDisplay.getEventos(date);
            if (events && events.length) {
                renderEvent(element, events);
            }
            updateWorkdaysCount();
        }

        function renderHolyday(element, holyday) {
            element.addClass(CLASS_HOLYDAY);

            var newScopeValues = {
                showHolydaysControls: $scope.showHolydaysControls,
                holydays: [holyday]
            };

            InstallDirective.addDirective(element, {
                'data-bf-popover': HOLYDAY_POPOVER_LIST_TEMPLATE,
                'data-bf-popover-create-body': 'true',

                'data-bf-tooltip': holyday.feriado.descricao
            }, $scope, newScopeValues);

        }

        function renderEvent(element, events) {
            if (events.length === 1) {
                element.addClass(ColorHelper.getColorClass(events[0].cor));
            } else {
                element.addClass(COLOR_CLASS_VARIOUS_EVENTS);
            }

            element.addClass(CLASS_EVENT);

            var directiveAttrs = {
                'data-bf-popover': EVENT_POPOVER_LIST_TEMPLATE,
                'data-bf-popover-create-body': 'true'
            };

            var newScopeValues = {
                events: events,
                showEventsControls: $scope.showEventsControls
            };

            InstallDirective.addDirective(element, directiveAttrs, $scope, newScopeValues);
        }

        function updateWorkdaysCount() {
            $scope.workdays = $element.find('.dia-letivo').length;
        }

        function isRestricted(day) {
            if (!$scope.hideRestrictedDate) {
                return false;
            }

            var isValid = true;
            if (vm.restrictInitialDate) {
                isValid = day.isSameOrAfter(vm.restrictInitialDate);
            }
            if (vm.restrictFinalDate) {
                isValid = isValid && day.isSameOrBefore(vm.restrictFinalDate);
            }

            return !isValid;
        }
    }
})();

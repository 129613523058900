(function() {
    'use strict';

    angular.module('educacao.rematricula').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.rematricula', {
            url: 'rematricula',
            data: {
                id: 'RematriculaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'rematricula/listagem-rematricula.html',
                    controller: 'educacao.rematricula.ListagemRematriculaCtrl as vm'
                }
            }
        });
    }
})();


(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider'
    ];

    function stateConfig($stateProvider) {
        var STATE = 'app.enderecos.pais';

        $stateProvider.state(STATE, {
            url: 'paises',
            data: {
                id: 'PaisPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'enderecos/pais/pais.html',
                    controller: 'educacao.enderecos.PaisController as vm'
                }
            }
        });
    }
})();
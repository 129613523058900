(function () {
    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.OrgaoRegionalInepService', Service);

    Service.$inject = ['Restangular'];

    function Service(Restangular) {

        var service = {};
        service.getList = getList;


        function getList(offset, limit, filter, sort) {
            var params = {
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            };


            return Restangular.one('commons/inep').all('orgao-regional-inep').getList(params);
        }

        return service;
    }

})();
(function() {

    'use strict';

    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioImpressaoService', CalendarioImpressaoService);

    CalendarioImpressaoService.$inject = [
        '$timeout',
        '$window',
        '$log'
    ];

    function CalendarioImpressaoService($timeout, $window, $log) {
        var self = this;

        self.imprimir = imprimir;

        function imprimir(contentTag){
            $timeout(function () {
                var contents = $(contentTag).html();
                var frame1 = $('<iframe />');
                frame1[0].name = 'frame1';
                frame1.css({ 'position': 'absolute', 'top': '-1000000px' });
                $('body').append(frame1);
                var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
                frameDoc.document.open();
                //Create a new HTML document.
                frameDoc.document.write('<html><head><title>Calendário escolar</title>');
                frameDoc.document.write('</head><body>');
                //Append the external CSS file.

                frameDoc.document.write('<link href="css/vendor.css" rel="stylesheet" type="text/css" />');
                frameDoc.document.write('<link href="css/app.css" rel="stylesheet" type="text/css" />');

                //Append the DIV contents.
                frameDoc.document.write(contents);
                $log.info(contents);
                frameDoc.document.write('</body></html>');
                frameDoc.document.close();
                $timeout(function () {
                    $window.frames.frame1.focus();
                    $window.frames.frame1.print();
                    frame1.remove();
                }, 500);
            }, 800);
        }
    }

})();

(function() {
    'use strict';

    angular.module('agenda')
        .constant('AGENDA_WEEK_VIEW', 'agendaWeek')
        .constant('AGENDA_MONTH_VIEW', 'month')
        .constant('AGENDA_DAY_VIEW', 'agendaDay')

    .constant('AGENDA_PREVIOUS_PAGE_EVENT', 'agenda.prev')
        .constant('AGENDA_NEXT_PAGE_EVENT', 'agenda.next')
    	.constant('AGENDA_TO_DAY_EVENT', 'agenda.today')
        .constant('AGENDA_TITLE_CHANGED_EVENT', 'agenda.title-changed');

})();

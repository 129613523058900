(function() {
    'use strict';

    angular.module('educacao.planejamento')
        .factory('educacao.planejamento.AvaliacoesExternasService', AvaliacoesExternasService);

    AvaliacoesExternasService.$inject = ['$injector'];

    function AvaliacoesExternasService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .create()
            .module({
                uri: 'pessoa'
            })
            .service({
                uri: 'avaliacao-externa'
            });

    }

})();

(function() {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectTurma', SelectTurmaDirective);

    SelectTurmaDirective.$inject = [];

    function SelectTurmaDirective() {
        return {
            restrict: 'E',
            require: 'ngModel',
            template: '<ui-select ng-model="ngModel" dropdown-css-class="dropdownCssClass" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)" format-selection="vm.formatSelection(data)"></ui-select>',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngclass: '=',
                estabelecimento: '=?',
                anoLetivo: '=?',
                tipoAtendimento: '=?',
                etapaMatriz: '=?',
                turno: '=?',
                dropdownCssClass: '=?',
                turnos: '=?',
                atividade: '=?',
                turmaNotEquals: '=?',
                formatResult: '=',
                formatSelection: '=',
                lista: '=?',
                matrizNull: '=?',
                nivelEscolar: '=?',
                modalidade: '=?',
                matriz: '=?',
                disciplina: '=',
                multiturno: '=',
                useConfigNewMatricula: '=',
                equivalencias: '=',
                showMatriz: '='
            },
            controller: SelectTurmaDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectTurmaDirectiveController.$inject = ['$scope', 'educacao.matricula.TurmaService'];

    function SelectTurmaDirectiveController($scope, turmaService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;

        function search(params) {

            var filter = '(descricao like "' + params.term + '"';

            if ($scope.turmaNotEquals) {
                filter += ' and id != ' + $scope.turmaNotEquals.id;
            }

            if ($scope.nivelEscolar) {
                filter += ' and nivelEscolar ="' + $scope.nivelEscolar + '"';
            }

            if ($scope.modalidade) {
                filter += ' and modalidade ="' + $scope.modalidade + '"';
            }

            if ($scope.estabelecimento) {
                filter += ' and estabelecimento = ' + $scope.estabelecimento.id;
            }

            if ($scope.anoLetivo) {
                filter += ' and anoLetivo = ' + $scope.anoLetivo.id;
            }

            if ($scope.tipoAtendimento) {
                var tiposAtendimentosEscolaricacao = ['NAO_SE_APLICA', 'ESCOLARIZACAO'];
                if ($scope.useConfigNewMatricula && tiposAtendimentosEscolaricacao.includes($scope.tipoAtendimento)) {
                    filter += ' and tipoAtendimento in ("' + tiposAtendimentosEscolaricacao.join('","') + '")';
                } else {
                    filter += ' and tipoAtendimento = "' + $scope.tipoAtendimento + '"';
                }
            }

            if ($scope.disciplina) {
                filter += ' and disciplina = ' + $scope.disciplina.id;
            }

            if ($scope.etapaMatriz && !$scope.useConfigNewMatricula) {
                if (_.isArray($scope.etapaMatriz)) {
                    if (!_.isEmpty($scope.etapaMatriz)) {
                        filter += ' and etapaMatriz in (' + _.pluck($scope.etapaMatriz, 'id').join(',') + ') ';
                    }
                } else {
                    filter += ' and etapaMatriz = ' + $scope.etapaMatriz.id;
                }
            }

            if ($scope.useConfigNewMatricula && !_.isEmpty($scope.equivalencias)) {
                filter += ' and equivalencias in ("' + $scope.equivalencias.join('","') + '") ';
            }

            if ($scope.turno) {
                filter += ' and turno = "' + $scope.turno + '"';
            }

            if ($scope.turnos) {
                filter += ' and turno in ("' + $scope.turnos.join('","') + '")';

            }

            if ($scope.atividade) {
                filter += ' and atividade = ' + $scope.atividade.id;
            }
            if ($scope.matrizNull) {
                filter += ' and matriz is not null ';
            }
            if ($scope.matriz) {
                filter += ' and matriz = ' + $scope.matriz.id;
            }
            if ($scope.lista) {
                filter += ignoreId($scope.lista());
            }

            filter += ')';

            var paramsList = {
                filter: filter,
                offset: params.offset,
                limit: params.limit,
                sort: 'descricao'
            };

            return turmaService.getListTurmaCommons(paramsList);
        }

        function formatResult(turma) {
            var subDescription = '';
            var descricaoMatriz = _.get(turma, 'vagaEtapa.matriz.descricao');
            var retorno = $scope.formatResult ? $scope.formatResult(turma) : turma.descricao;
            if (descricaoMatriz && $scope.showMatriz) {
                subDescription = '<br><span class="small text-muted"><strong>Matriz: </strong>' + descricaoMatriz + '</span>';
            }
            return retorno + subDescription;
        }

        function formatSelection(turma) {
            return $scope.formatSelection ? $scope.formatSelection(turma) : formatResult(turma);
        }

        function ignoreId(list) {
            var filter = '';
            var turmas = [];

            if (list) {
                list.forEach(function(turma) {
                    if (_.get(turma, 'id')) {
                        turmas.push(turma.id);
                    }
                });
            }
            if (turmas.length) {
                filter += ' and id not in(' + turmas.join() + ')';
            }

            return filter;
        }
    }
})();


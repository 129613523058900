(function () {
    'use strict';
    /**
     * Deprecated: Usar select do P&D
     * TODO:Apagar este serviço
     */
    angular.module('educacao.common')
        .factory('educacao.common.MunicipioSelect', MunicipioSelect);

    MunicipioSelect.$inject = ['$injector', '$log'];

    function MunicipioSelect($injector, $log) {
        $log.warn('[educacao.common.MunicipioSelect] Deprecated: Usar select do P&D');

        var MunicipioService = $injector.get('educacao.common.MunicipioService');
        var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');

        return {
            select2Config: function () {
                $log.warn('[educacao.common.MunicipioSelect] Deprecated: Usar select do P&D');

                return CommonSelectWithService.select2Config(MunicipioService, 'nome', {
                    minimumInputLength: 3,
                    formatResult: function (data) {
                        return data.nome + ' - ' + data.uf;
                    }
                });
            }
        };
    }
})();
(function() {

    'use strict';

    angular.module('app')
        .config(stateConfig);

    stateConfig.$inject = [
        'USER_ACCOUNTS_URL',
        '$stateProvider',
        '$urlRouterProvider'
    ];

    function stateConfig(USER_ACCOUNTS_URL, $stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/selecao');

        $stateProvider
            .state('app', {
                url: '/entidades/{acesso}/anosletivos/{anoletivo}/',
                views: {
                    'header': {
                        templateUrl: 'common/layout/header/header.html'
                    },
                    '@': {
                        templateUrl: 'common/layout/geral.html',
                        controller: 'educacao.common.GeralCtrl',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    contextos: resolveContextos
                },
                controller: ['contextos', function() {}]
            })

            .state('app.permissions', {
                adminOnly: true,
                url: 'gerenciador-acessos',
                views: {
                    'content@': {
                        templateUrl: USER_ACCOUNTS_URL + '/access/dashboard.html'
                    }
                }
            });
    }

    resolveContextos.$inject = [
        '$rootScope',
        '$state',
        '$stateParams',
        '$q',
        'educacao.context.UserAccessService',
        'bfc.$$PermissionsService',
        'educacao.context.ContextoEntidade',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo'
    ];

    function resolveContextos(
        $rootScope,
        $state,
        $stateParams,
        $q,
        UserAccessService,
        PermissionsService,
        ContextoEntidade,
        ContextoEstabelecimento,
        ContextoAnoLetivo) {

        return $q.all([
            resolveEntidade(),
            resolveEstabelecimento()
        ])
            .then(resolveAcesso)
            .then(resolveAnoLetivo)
            .then(resolvePermissoes)
            .then(function () {
                $rootScope.$broadcast('educacao-context:resolved');
            })
            .catch(function() {
                $state.go('selecao');
            });

        function resolveEntidade() {
            return ContextoEntidade.resolveEntidadeFromAcesso($stateParams.acesso);
        }

        function resolveEstabelecimento() {
            return ContextoEstabelecimento.resolveEstabelecimentoFromAcesso($stateParams.acesso);
        }

        function resolveAcesso() {
            return UserAccessService.setUserAccess(ContextoEntidade, ContextoEstabelecimento);
        }

        function resolveAnoLetivo() {
            return ContextoAnoLetivo.resolveAnoLetivoFromAno($stateParams.anoletivo);
        }

        function resolvePermissoes() {
            return !PermissionsService.$$isPermissionsLoaded() &&
                PermissionsService.$$checkForUpdatedPermissions({});
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ConfiguracaoInscricaoCtrl', ConfiguracaoInscricaoCtrl);

    ConfiguracaoInscricaoCtrl.$inject = ['$injector', '$scope', '$rootScope', '$q'];

    function ConfiguracaoInscricaoCtrl($injector, $scope, $rootScope, $q) {
        var REFRESH_CONFIGURACOES = 'refresh::configuracoesInscricao';

        var promiseTracker = $injector.get('promiseTracker');
        var ModalCad = $injector.get('educacao.common.ModalCad'),
            ConfiguracaoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoInscricaoService'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService');

        var vm = this;

        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.tracker.remove = promiseTracker();
        vm.openModalCad = openModalCad;
        vm.remove = remove;
        vm.configuracoes = [];
        vm.options = {};

        init();

        function openModalCad(id) {
            ModalCad.open({
                templateUrl: 'matricula/configuracao-inscricao/configuracao-inscricao.cad.html',
                controller: 'educacao.matricula.ConfiguracaoInscricaoCadCtrl as vm',
                id: id,
                readOnly: false
            });
        }

        function init() {
            vm.tracker.loading.addPromise(
                $q.all([
                    refresh(),
                    ConfiguracaoInscricaoService.getNivelEscolar().then(function(data) {
                        vm.options.NivelEscolar = data;
                    })
                ])
            );
        }

        function refresh() {
            vm.tracker.loading.addPromise(
                ConfiguracaoInscricaoService.getPage().then(function(data) {
                    vm.configuracoes = data.content;
                })
            );
        }

        function remove(data) {
            ConfiguracaoInscricaoService.remove(data).then(function() {
                $rootScope.$broadcast(REFRESH_CONFIGURACOES, {
                    data: data,
                    remove: true
                });
            });
        }

        $scope.$on(REFRESH_CONFIGURACOES, function(event, args) {
            if (args.data) {
                if (args.remove) {
                    vm.configuracoes = _.reject(vm.configuracoes, function(configuracao) {
                        return configuracao.id === args.data.id;
                    });

                } else if (args.add) {
                    vm.configuracoes.push(args.data);

                } else if (args.undo || args.edit) {
                    var config = _.find(vm.configuracoes, function(configuracao) {
                        return configuracao.id === args.data.id;
                    });

                    if (config) {
                        ObjectUtilsService.copyProperties(config, args.data);
                    } else {
                        throw new Error('Aluno não encontrado! ID: ' + args.id);
                    }
                }
            }
        });

    }
})();

(function() {
  'use strict';

  angular
    .module('educacao.matricula')
    .directive('appEditarDataEnturmacao', Directive);

  function Directive() {
    return {
      restrict: 'E',
      templateUrl:
        'matricula/matriculas/app-editar-data-enturmacao.directive.html',
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        enturmacao: '=',
        editar: '='        
      },
      bindToController: true
    };
  }

  Controller.$inject = ['educacao.matricula.EnturmacaoService',  'educacao.common.$commons'];

  function Controller(EnturmacaoService, $commons) {
    var vm = this;

    vm.alterarDatasEnturmacao = alterarDatasEnturmacao;
    vm.alterar = angular.copy(vm.enturmacao);
    vm.tracker = $commons.$promiseTracker();

    function alterarDatasEnturmacao() {
      var promise = EnturmacaoService.alterarDatasEnturmacao(vm.alterar).then(
        onAlterarDatas
      );

      vm.tracker.addPromise(promise);

      function onAlterarDatas(ent) {
        vm.enturmacao.dataInicio = ent.dataInicio;
        vm.enturmacao.dataFim = ent.dataFim;
        vm.editar = false;
      }
    }
  }
})();

(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .directive('appResumoGeralTurmaAlunos', appResumoGeralTurmaAlunosDirective);

    function appResumoGeralTurmaAlunosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/turma-alunos/resumo-geral/app-resumo-geral-turma-alunos.directive.html',
            scope: {},
            bindToController: {
                anoletivoselecionado: '=',
                estabelecimentoselecionado: '=',
                estabelecimentoSelected: '=',
                teste: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        'educacao.turma-alunos-quadro-informativo.GraficoTurmaAlunosService',
        'promiseTracker',
        '$rootScope',
        '$scope',
        '$log',
        '$stateParams'

    ];

    function Controller(ContextoAnoLetivo, ContextoEstabelecimento, QuadroFuncionariosTurmaAlunosService, GraficoTurmaAlunosService, promiseTracker, $rootScope, $scope, $log, $stateParams) {
        var vm = this;
        vm.graficosGerais = {};

        vm.loadingTracker = {
            graficoTurmaPorModalidade: promiseTracker(),
            graficoAlunoPorModalidade: promiseTracker(),
            totalAlunosAno: promiseTracker(),
            totalVagasAno: promiseTracker(),
            graficoDisponibilidadeSalas: promiseTracker(),
            graficoDisponibilidadeVagas: promiseTracker(),
            modalidadesOferecidas: promiseTracker(),
        };

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.alunosPorAnoGraphData = null;
        vm.vagasPorAnoGraphData = null;
        vm.modalidadesOferecidas = null;
        $log.log();

        vm.paths = [
            'turmaModalidade',
            'alunoModalidade',
            'disponibilidadeSalas',
            'disponibilidadeVagas',
        ];


        $scope.$watch('vm.estabelecimentoselecionado', onChangeContextEstabelecimento);
        $scope.$watch('vm.anoletivoselecionado', onChangeContextAnoLetivo);
        $rootScope.$on('app-context:anoletivo:new', clearAnoLetivo);
        init();

        function onChangeContextAnoLetivo(newValue) {
            if (newValue === null) {
                return;
            }

            reloadValues();
        }

        function clearAnoLetivo() {
            vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            $stateParams.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
        }

        function onChangeContextEstabelecimento(newValue, oldValue) {
            if (newValue !== oldValue) {
                reloadValues();
            }
        }

        function reloadValues() {
            GraficoTurmaAlunosService.clearValues();
            _.forEach(vm.paths, clearValue);
            vm.alunosPorAnoGraphData = null;
            vm.vagasPorAnoGraphData = null;
            init();
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoletivoselecionado, 'id');
            paramns.estabelecimentoId = _.get(vm.estabelecimentoselecionado, 'id');
            return paramns;
        }

        function clearValue(path) {
            vm.graficosGerais[path] = null;
        }


        function init() {
            getModalidadesOferecidas();
            getGraficoGerais('turmaModalidade', 'graficoTurmaPorModalidade', 'getGraficoTurmasPorModalidade');
            getGraficoGerais('alunoModalidade', 'graficoAlunoPorModalidade', 'getGraficoAlunosPorModalidade');
            getGraficoGerais('disponibilidadeSalas', 'graficoDisponibilidadeSalas', 'getGraficoDisponibilidadeSalas');
            getGraficoGerais('disponibilidadeVagas', 'graficoDisponibilidadeVagas', 'getDisponibilidadeTotalVagas');
            getGraficoTotalAlunosAnuais();
            getVagasDisponiveisPorAno();
        }

        function getGraficoGerais(path, trackerName, serviceName) {
            var promise = GraficoTurmaAlunosService.getGraficoGerais(path, serviceName, resolveParamns())
                .then(function (data) {
                    vm.graficosGerais[path] = data;
                });
            vm.loadingTracker[trackerName].addPromise(promise);
        }

        function getModalidadesOferecidas() {
            var promise = QuadroFuncionariosTurmaAlunosService.getModalidadesOferecidas(resolveParamns()).then(function (data) {
                vm.modalidadesOferecidas = data;
            });
            vm.loadingTracker.modalidadesOferecidas.addPromise(promise);

        }

        function getGraficoTotalAlunosAnuais() {
            var promise = QuadroFuncionariosTurmaAlunosService.getSaldoAlunosAnuais(resolveParamns())
                .then(function (data) {
                    vm.alunosPorAnoGraphData = data;
                });
            vm.loadingTracker.totalAlunosAno.addPromise(promise);
        }

        function getVagasDisponiveisPorAno() {
            var promise = QuadroFuncionariosTurmaAlunosService.getSaldoVagasAnuais(resolveParamns())
                .then(function (data) {
                    vm.vagasPorAnoGraphData = data;
                });
            vm.loadingTracker.totalVagasAno.addPromise(promise);
        }
    }

})();

(function() {

    'use strict';

    angular.module('educacao.lista-espera')
        .service('educacao.lista-espera.ListaEsperaService', ListaEsperaService);

    ListaEsperaService.$inject = [
        'Restangular'
    ];
    
    function ListaEsperaService(Restangular) {
        var self = this;

        self.list = list;
        self.getDetails = getDetails;
        self.getEstabelecimentos = getEstabelecimentos;
        self.editarOrdemClassificacao = editarOrdemClassificacao;
        function getBasePath() {
            return Restangular.all('matricula').all('inscricao-lista-espera');
        }

        function list(params) {
            return getBasePath().all('dados').get('', params);
        }

        function getDetails(params) {
            return getBasePath().all('modalidades').get('', params)
                .then(Restangular.stripRestangular);
        }

        function editarOrdemClassificacao(inscricao) {
            var endpoint = Restangular.all('matricula').one('inscricao-lista-espera', inscricao.id).all('ordem');
            return endpoint.customPUT(inscricao.id, inscricao.ordemClassificacao);
        }

        function getEstabelecimentos(params, idModalidade) {
            return Restangular.all('matricula').one('inscricao-lista-espera', idModalidade)
                .all('estabelecimentos').getList(params)
                .then(Restangular.stripRestangular);
        }
    }

})();

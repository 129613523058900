(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.relatorioVaga', Controller);

    Controller.$inject = ['$q', '$modalInstance', 'educacao.matricula.ConfiguracoesMatriculaService',
        'educacao.matricula.RelatorioService',
        'educacao.entidade.EntidadeService'];

    function Controller($q, $modalInstance, ConfiguracoesMatriculaService, RelatorioService, EntidadeService) {
        var vm = this;

        vm.atestadoDeVaga = $modalInstance.params.atestado;
        vm.estabelecimento = $modalInstance.params.estabelecimento;
        vm.matriz = $modalInstance.params.matriz;
        vm.validade = $modalInstance.params.validade;
        vm.informaDocumentos = $modalInstance.params.informaDocumentos;

        vm.print = print;
        vm.dataFormatada = RelatorioService.getDataAtual();

        EntidadeService.getEntidadeSuite().then(function (entidade) {
            vm.entidadeSuite = entidade.data;
        });

        buscaDocumentos(filterDocumentos()).then(formataInformacoes);

        $modalInstance.tracker.addPromise(RelatorioService
            .getEstabelecimentoRelatorio(vm.estabelecimento.id)
            .then(function (data) {
                vm.rowTelefoneEmail = RelatorioService
                    .formatRowTelefoneEmail(data.telefoneEstabelecimento, data.emailEstabelecimento);

                vm.estabelecimentoRelatorio = data;
            })
        );

        function formataInformacoes() {
            vm.textoCorpo = 'Atestamos para os devidos fins e a pedido da parte interessada, que há vaga';
            if (vm.matriz && vm.matriz.curso) {
                vm.textoCorpo += ' no(a)  ' + vm.matriz.descricao + ', no(a) ' + vm.atestadoDeVaga.etapaMatriz.descricao;
            }
            if (vm.atestadoDeVaga.atividade) {
                vm.textoCorpo += ' na atividade ' + vm.atestadoDeVaga.atividade.descricao;
            }
            if (vm.atestadoDeVaga.turma) {
                vm.textoCorpo += ', na turma ' + vm.atestadoDeVaga.turma.descricao;
            }

            vm.textoCorpo += ', podendo ser aceita a transferência do(a) aluno(a) ' + vm.atestadoDeVaga.aluno;

            var turno = _.get(vm.atestadoDeVaga, 'vaga.turno') || _.get(vm.atestadoDeVaga, 'turma.turno');
            if (turno) {
                vm.textoCorpo += ' no turno ' + _.capitalize((turno || '').toLowerCase());
            }
            if (vm.informaDocumentos && !_.isEmpty(vm.listaDocumentosEncontrados)) {
                vm.textoCorpo += ', mediante a apresentação da documentação exigida:';
            } else {
                vm.textoCorpo += '.';
            }
        }

        function filterDocumentos() {
            if (!vm.informaDocumentos) {
                return;
            }
            var filter;
            if (isEducacaoInfantil()) {
                filter = filtroDocumentosCompleto();
                return filter;
            }
            if (isComplementar()) {
                filter = 'nivelModalidade = "' + vm.matriz.curso.nivelModalidade + '"';
                return filter;
            }
            if (_.isEmpty(vm.atestadoDeVaga.etapaMatriz.equivalencias)) {
                vm.listaDocumentosEncontrados = [];
                return;
            }
            filter = filtroDocumentosCompleto();
            filter += ' and etapa in ("' + vm.atestadoDeVaga.etapaMatriz.equivalenciasEnum.join('","') + '")';

            return filter;
        }

        function isEducacaoInfantil() {
            return vm.matriz.curso.nivelEscolar === 'EDUCACAO_INFANTIL';
        }

        function isComplementar() {
            return vm.matriz.curso.nivelModalidade === 'COMPLEMENTAR';
        }

        function filtroDocumentosCompleto() {
            return 'nivelEscolar = "' + vm.matriz.curso.nivelEscolar + '" and nivelModalidade = "' + vm.matriz.curso.nivelModalidade +
                '"';
        }

        function buscaDocumentos(filter) {
            if (filter) {
                var promise = ConfiguracoesMatriculaService.getDocumentos(vm.estabelecimento.id, filter).then(function (documentos) {
                    vm.listaDocumentosEncontrados = documentos;
                });

                $modalInstance.tracker.addPromise(promise);
                return promise;
            } else {
                return $q.when();
            }
        }

        function print() {
            RelatorioService.print('#formularioAtestadoVaga');
        }

    }
})();


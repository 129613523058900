(function () {

    'use strict';

    angular.module('educacao.lista-espera')
        .directive('appListaEsperaItemEstabelecimentos', appListaEsperaItemEstabelecimentos);

    function appListaEsperaItemEstabelecimentos() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera/app-lista-espera-item/app-lista-espera-item-estabelecimento/app-lista-espera-item-estabelecimento.directive.html',
            scope: {},
            bindToController: {
                item: '=',
                nivel: '=',
                tipoItem: '=',
                filter: '=',
                tracker: '=',
                cancelFunction: '=',
                removeFunction: '=',
                undoFunction: '=',
                modalidade: '=',                
                refresh: '=',
                matricularFunction: '=',
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$listaEsperaItemService',
        'promiseTracker',
        'educacao.lista-espera.ListaEsperaService',
        'ui.components.Modal',
    ];

    function Controller($listaEsperaItemService, promiseTracker, ListaEsperaService, $modal) {
        var vm = this;

        vm.isCancelado = $listaEsperaItemService.isCancelado;
        vm.isInscrito = $listaEsperaItemService.isInscrito;
        vm.onClickCancelEstabelecimento = onClickCancelEstabelecimento;
        vm.onClickDesfazerCancelamento = onClickDesfazerCancelamento;
        vm.onClickMatricular = onClickMatricular;
        vm.onClickRemover = onClickRemover;

        vm.tracker = promiseTracker();

        init();

        function openModalRemover(estabelecimentoInscricao) {
            $modal.open({
                templateUrl: 'lista-espera/remover-inscricao/remover-inscricao.html',
                controller: 'educacao.lista-espera.RemoverInscricaoController',
                controllerAs: 'vm',
                params: {
                    estabelecimentoInscricao: estabelecimentoInscricao,
                    modalidade: vm.modalidade
                },
                size: 'l'
            }).result.then(vm.refresh);
        }

        function init() {
            var filter = buildFilterPadrao();
            if (vm.filter) {
                filter += ' and (' + vm.filter + ')';
            }

            var promise = ListaEsperaService.getEstabelecimentos({
                filter: filter
            }, vm.nivel.id)
                .then(mapEstabelecimentoInscricao);
            vm.tracker.addPromise(promise);
        }

        function mapEstabelecimentoInscricao(estabelecimentos) {
            vm.estabelecimentos = _.chain(estabelecimentos)
                .groupBy('estabelecimento.id')
                .map(createEstabelecimento)
                .flatten()
                .value();
        }

        function createEstabelecimento(estabelecimentosInscricao) {
            if (!vm.isCancelado(vm.tipoItem)) {
                return createEstabelecimentoTurno(estabelecimentosInscricao, null);
            }

            return _.chain(estabelecimentosInscricao)
                .groupBy('motivoCancelamento')
                .map(function (item, motivo) {
                    return createEstabelecimentoTurno(item, motivo);
                })
                .value();
        }

        function createEstabelecimentoTurno(estabelecimentosInscricao, motivoCancelamento) {
            //var turnos = _.pluck(estabelecimentosInscricao, 'turnos');
            var turnos = [];
            _.forEach(estabelecimentosInscricao, function (a) {
                turnos.push({
                    id: a.id,
                    turnos: a.turno
                });
            });
            return {
                estabelecimento: {
                    id: _.first(estabelecimentosInscricao).estabelecimento.id,
                    nome: _.get(_.first(estabelecimentosInscricao), 'estabelecimento.pessoa.nome')
                },
                turno: turnos,
                motivoCancelamento: motivoCancelamento
            };
        }

        function onClickCancelEstabelecimento(nivel, estabelecimentoInscricao) {
            var idInscricao = vm.item.listaEspera.id;
            var turnos = _.pluck(estabelecimentoInscricao.turno, 'turnos');
            if (_.isFunction(vm.cancelFunction)) {
                vm.cancelFunction(idInscricao, nivel.id, estabelecimentoInscricao.estabelecimento.id, turnos);
            }
        }

        function onClickDesfazerCancelamento(nivel, estabelecimentoInscricao) {
            var idInscricao = vm.item.listaEspera.id;
            if (_.isFunction(vm.undoFunction)) {
                vm.undoFunction(idInscricao, nivel.id, estabelecimentoInscricao.estabelecimento.id, estabelecimentoInscricao.turnos);
            }
        }

        function onClickMatricular(nivel, estabelecimentoInscricao) {
            if (_.isFunction(vm.matricularFunction)) {
                vm.matricularFunction(vm.item, nivel, estabelecimentoInscricao);
            }
        }

        function onClickRemover(estabelecimentoInscricao) {
            openModalRemover(estabelecimentoInscricao);
        }

        function buildFilterPadrao() {
            return '(statusEstabelecimento = \'' + vm.tipoItem + '\')';
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.diarioclasse')
        .directive('tableAlunosDetails', AlunosDetails);

    function AlunosDetails() {

        return {
            restrict: 'E',
            templateUrl: 'impressao-diario-classe-desempenho/template-diario-classe-alunos.html',
            scope: {
                registros: '=',
                predicate: '=',
                reverse: '=',
                columnDias: '=',
                tituloColuna: '='
            }     
        };
    }
})();


(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ClassificacaoNovaChamadaCtrl', ClassificacaoNovaChamadaCtrl);

    ClassificacaoNovaChamadaCtrl.$inject = ['chamada','processo','$modalInstance','educacao.matricula.ClassificacaoService','promiseTracker','$rootScope','$q',
    'educacao.matricula.IndeferirClassificacaoService'];

    function ClassificacaoNovaChamadaCtrl(chamada, processo, $modalInstance, ClassificacaoService, promiseTracker, $rootScope, $q, IndeferirClassificacaoService) {
  
        var vm = this;
        vm.tracker = {};
        vm.tracker.save = promiseTracker();

        vm.chamada = chamada;

        vm.save = save;

        init();

        function init() {
            $q.all([
              (function(){
                  if (vm.form) {
                      vm.form.$setPristine();
                  }
              })(),
              IndeferirClassificacaoService.getDefault(processo.id).then(function(data) {
                 vm.statusClassificacao = data;                                     
              })
            ]);                      
        }

        function save() {
            vm.tracker.save.addPromise(
                ClassificacaoService.encerrarIndeferindo(processo.id,vm.statusClassificacao).then(function(data){
	                $rootScope.$broadcast('refresh::classificacoes', {
	                    data: data,
	                    novaChamada: true
	                });
	                close();                    	
                })
            );    
        }

        function close() {
            $modalInstance.close();
        }        


    }


})();

(function() {
    'use strict';

    angular.module('educacao.planejamento')
        .factory('educacao.planejamento.TiposDependenciasService', TiposDependenciasService);

    TiposDependenciasService.$inject = ['$injector'];

    function TiposDependenciasService($injector) {
        var GenericService = $injector.get('GenericService');

        return GenericService
            .create()
            .module({
                uri: 'pessoa'
            })
            .service({
                uri: 'tipo-dependencia-fisica'
            });
    }
})();

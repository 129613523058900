(function() {
    'use strict';

    var $state;

    angular.module('educacao.common')
        .config(config)
        .run(setState);

    config.$inject = ['PermissionConfigProvider', 'mainMenu'];

    function config(PermissionConfig, mainMenu) {
        PermissionConfig.formatter(getFormatted);

        function getFormatted(permissions) {
            return format(permissions, mainMenu);
        }
    }

    setState.$inject = ['$state'];

    function setState(state) {
        $state = state;
    }

    function format(permissions, mainMenu) {
        var rootId = 'root';
        var pageMappings = getPageMappingStateMap();
        var mapMenuIdPage = {};

        _.forEach(permissions, function(perm) {
            var stateName = pageMappings[perm.id];
            if (!stateName) {
                return;
            }

            var parentMenu = getParentMenu(stateName);
            createParents(parentMenu);
            mapMenuIdPage[parentMenu.id].pages.push(perm);
        });

        return sort(mapMenuIdPage[rootId]);

        function getPageMappingStateMap() {
            return _.chain($state.get())
                .filter('data.id')
                .filter(filterModalState)
                .mapKeys('data.id')
                .mapValues('name')
                .value();
        }

        function filterModalState(item) {
            return !_.get(item, 'data.isModalState');
        }

        function getParentMenu(stateName) {
            var state = $state.get(stateName);
            if (state.data.mirrorOf) {
                return getParentMenu(state.data.mirrorOf);
            }
            var menu = mainMenu.getMenuByAction(stateName);
            if (menu) {
                return menu.parent;
            }
        }

        function createParents(menu) {
            if (!menu) {
                return;
            }
            createParents(menu.parent);

            var shouldAdd = !mapMenuIdPage[menu.id];

            mapMenuIdPage[menu.id] = mapMenuIdPage[menu.id] || {
                description: menu.label,
                pages: []
            };

            if (shouldAdd && menu.parent) {
                mapMenuIdPage[menu.parent.id].pages.push(mapMenuIdPage[menu.id]);
            }
        }

        function sort(item) {
            var pages = item.pages;
            _.forEach(pages, function(page) {
                if (page.pages) {
                    page.page = sort(page);
                }
            });
            return _.sortBy(pages, 'description');
        }
    }
})();

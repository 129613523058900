(function() {
    'use strict';

    angular.module('educacao.common').directive('appHasNotification',
        AppHasNotification);

    AppHasNotification.$inject = ['$compile'];

    function AppHasNotification($compile) {
        return {
            restrict: 'A',
            link: postLink
        };

        function postLink(scope, element, attrs) {
            attrs.$observe('appHasNotification', function() {
                _buildAlert(scope, element, attrs);
            });

            if (attrs.appHasNotification) {
                _buildAlert(scope, element, attrs);
            }

        }

        function _buildAlert(scope, element, attrs) {
            var attr = attrs.appHasNotification;

            var notifications = scope.$eval(attr);
            if (_hasNotification(notifications)) {
                var message = 'Há dados incompletos';
                var template = '<div class="list-alert-group"> <span data-bf-tooltip="' +
                    message +
                    '" class="label label-danger list-alert ng-isolate-scope" data-original-title="" title="">!</span> </div>';

                var result = $compile(template)(scope);

                element.append(result);
            } else{
            	element.find('div.list-alert-group').remove();
            }
        }

        function _hasNotification(notifications) {
            return (_.map(_.values(notifications))[0]);
        }
    }

})();

(function() {
	'use strict';

	angular.module('educacao.matricula').controller('educacao.matricula.SelecaoDivisaoEnturmacaoProfessorCtrl',
		SelecaoDivisaoEnturmacaoProfessorCtrl);

	SelecaoDivisaoEnturmacaoProfessorCtrl.$inject = ['$modalInstance'];

	function SelecaoDivisaoEnturmacaoProfessorCtrl($modalInstance) {
		var vm = this;

		vm.divisoes = $modalInstance.params.divisoes;

		vm.enturmar = enturmar;

		function enturmar() {
			$modalInstance.close(vm.divisao);
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .filter('appIdade', filter);

    function filter() {
        return function(data, hideMonths, hideDays) {
            if (!data) {
                return '(0 ano 0 mês)';
            }

            var dataAtual = moment();
            var dataAniversario = moment(data);

            if (dataAniversario > dataAtual) {
                return '(0 ano 0 mês)';
            }
            var PLULARIZE = {
                ANO: {
                    'one': ' ano',
                    'other': ' anos'
                },
                MES: {
                    'one': ' mês',
                    'other': ' meses'
                },
                DIAS: {
                    'one': ' dia',
                    'other': ' dias'
                }
            };

            var year = dataAtual.diff(dataAniversario, 'years');
            var meses = dataAtual.diff(dataAniversario, 'month');
            var mes = meses % 12;
            var days = dataAtual.diff(dataAniversario.add(meses, 'months'), 'days');

            var result = year + ' ' + getPlularize(year, 'ANO');

            if (hideMonths) {
                return result;
            }

            result += ' ' + mes + ' ' + getPlularize(mes, 'MES');

            if (hideDays) {
                return result;
            }

            return result + ' ' + days + ' ' + getPlularize(days, 'DIAS');

            function getPlularize(num, tipo) {
                return num > 1 ? PLULARIZE[tipo].other : PLULARIZE[tipo].one;
            }
        };
    }
})();

(function () {
    'use strict';

    angular.module('educacao.criterio-lista-espera')
    .controller('educacao.criterio-lista-espera.CriterioListaEsperaCadCtrl', CriterioListaEsperaCadCtrl);

    CriterioListaEsperaCadCtrl.$inject = [
        '$scope',
        'educacao.criterio-lista-espera.CriterioListaEsperaService',
        'bfc.Notification',
        '$modalInstance'
    ];

    function CriterioListaEsperaCadCtrl($scope, CriterioListaEsperaService, bfcNotification, $modalInstance) {
        var vm = this;

        vm.criterio = $modalInstance.params.criterio || {};

        vm.editando = Boolean(vm.criterio.id);

        vm.save = save;
        vm.remove = remove;

        function save(continuar) {
            return CriterioListaEsperaService.save(vm.criterio)
                .then(notifySuccessSave)
                .then(continuar ? _.noop : $modalInstance.close)
                .then(novocriterio);
        }

        function notifySuccessSave(data) {
            bfcNotification.publish('Critério salvo com sucesso', 'success');
            return data;
        }

        function novocriterio(data) {
            $('#lbDescricaoCriterio').focus();
            $scope.form.$setPristine();
            $scope.form.$setUntouched();
            vm.criterio = {};
            return data;

        }

        function remove() {
            return CriterioListaEsperaService.remove(vm.criterio).then(
                function () {
                    bfcNotification.publish('Critério removido com sucesso.', 'success');
                    $modalInstance.close();
                }
            );
           
        }
    }
})();

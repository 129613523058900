(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.FiliacoesController', FiliacoesCtrl);

    FiliacoesCtrl.$inject = ['$scope', 'promiseTracker', '$modalStates', 'educacao.pessoas.FiliacaoService', 'bfc.Notification',
        '$enumsCache'];

    function FiliacoesCtrl($scope, promiseTracker, $modalStates, FiliacaoService, Notification, $enumsCache) {

        var vm = this;

        vm.loadingTracker = promiseTracker();
        vm.filiacaoService = FiliacaoService;
        vm.listagemControls = {};

        vm.remove = remove;
        vm.openModal = openModal;
        vm.expressionCustomBuilders = {};

        $enumsCache.get('DiaDaSemanaDto').then(function(retorno) {
            vm.options = {
                diasSemana: retorno,
            };
        });

        function remove(responsavel) {
            var promise = FiliacaoService.remove(responsavel).then(function() {
                Notification.publish('Filiação excluida com sucesso.', 'success');
                vm.listagemControls.search();
            });
            vm.loadingTracker.addPromise(promise);
            return promise;
        }

        function openModal(filiacao) {
            $modalStates.goModalState(filiacao);
        }
    }
})();

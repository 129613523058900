(function() {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.VisualizacaoCalendarioEstabelecimentoController',
            VisualizacaoCalendarioEstabelecimentoController);

    VisualizacaoCalendarioEstabelecimentoController.$inject = [
        'OBSERVACAO_POPOVER_EDIT_EVENT',
        'MATRIZ_LIST_EDIT_EVENT',
        'MATRIZ_LIST_REMOVE_EVENT',
        'MATRIZ_LIST_SELECTED_EVENT',
        'VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW',
        'ESTABELECIMENTO_LIST_SELECT',

        '$scope',
        '$q',
        'promiseTracker',
        'bfc.Notification',
        'bfc.dialog.Dialog',

        'IdGenerator',
        'CalendarioDisplay',

        'educacao.calendario.CalendarioEstabelecimentoService',
        'educacao.calendario.CalendarioMatrizCurricularService',

        'educacao.common.ModalConfirm',
        'educacao.context.ContextoAnoLetivo',

        'educacao.calendario.RegistroVinculadoService'
    ];

    function VisualizacaoCalendarioEstabelecimentoController(
        OBSERVACAO_POPOVER_EDIT_EVENT,
        MATRIZ_LIST_EDIT_EVENT,
        MATRIZ_LIST_REMOVE_EVENT,
        MATRIZ_LIST_SELECTED_EVENT,
        VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW,
        ESTABELECIMENTO_LIST_SELECT,
        $scope,
        $q,
        promiseTracker,
        Notification,
        BfcDialog,
        IdGenerator,
        CalendarioDisplay,
        calendarioEstabelecimentoService,
        calendarioMatrizCurricularService,
        modalConfirmService,
        ContextoAnoLetivo,
        RegistroVinculadoService
    ) {

        var vm = this;
        var lastMatrizLoaded;

        vm.calendarioDisplay = CalendarioDisplay.create();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;

        vm.trackers = {
            calendario: promiseTracker(),
            matriz: promiseTracker()
        };

        vm.isCalendarioStarted = false;
        vm.openCalendarioModal = openCalendarioModal;
        vm.openMatrizModal = openMatrizModal;
        vm.loadCalendarios = loadCalendarios;
        vm.iniciarCalendario = iniciarCalendario;
        vm.excluirCalendario = excluirCalendario;
        vm.goToYearView = goToYearView;
        vm.openModalRegistroVinculado = openModalRegistroVinculado;

        init();

        function init() {
            $scope.$on(OBSERVACAO_POPOVER_EDIT_EVENT, onObservacaoEdited);

            $scope.$on(MATRIZ_LIST_EDIT_EVENT, function(event, calendarioMatriz) {
                openMatrizModal(calendarioMatriz);
            });
            $scope.$on(MATRIZ_LIST_REMOVE_EVENT, removeCalendarioMatriz);

            $scope.$on(MATRIZ_LIST_SELECTED_EVENT, onCalendarioMatrizSelected);

            getCalendarioSecretaria();

        }

        function getCalendarioSecretaria() {

            var promise = calendarioEstabelecimentoService.isIniciado()
                .then(onSuccess)
                .catch(onError);

            vm.trackers.calendario.addPromise(promise);

            function onSuccess() {

                vm.calendarioSecretariaNaoIniciado = false;
                loadCalendarios();

            }

            function onError() {

                vm.calendarioSecretariaNaoIniciado = true;

            }

        }

        function iniciarCalendario() {

            calendarioEstabelecimentoService.iniciarCalendario()
                .then(IdGenerator.generateAllId)
                .then(setCalendario);

        }

        function excluirCalendario() {
            modalConfirmService({
                title: 'Confirmar exclusão',
                message: 'Confirma a exclusão do calendário para este estabelecimento?'
            }).then(excluir);

            function excluir() {
                calendarioEstabelecimentoService.excluirCalendario(vm.calendarioEstabelecimento.id)
                    .then(loadCalendarios);
            }
        }

        function loadCalendarios() {
            vm.trackers.calendario.addPromise(
                calendarioEstabelecimentoService.getCalendarioAnoLetivo()
                .then(IdGenerator.generateAllId)
                .then(setCalendario)
                .then(loadAvisos)
            );
        }

        function setCalendario(calendarioEstabelecimento) {
            if (!calendarioEstabelecimento) {
                vm.isCalendarioStarted = false;
                return;
            }
            vm.isCalendarioStarted = true;

            vm.calendarioEstabelecimento = calendarioEstabelecimento;
            vm.calendarioSecretaria = calendarioEstabelecimento.calendarioSecretaria;
            vm.calendario = calendarioEstabelecimento.calendario;

            vm.isCalendarioBlocked = calendarioEstabelecimento.calendarioSecretaria.blocked;

            var calendarioSecretaria = vm.calendarioSecretaria.calendario;

            vm.calendarioDisplay
                .setBaseCalendar(vm.calendario)
                .setBlocked(vm.isCalendarioBlocked)
                .reset()
                .addObservacao('rede municipal', vm.calendarioSecretaria.calendario.observacao)
                .addExcecoes(calendarioSecretaria.excecoes)
                .addFeriados(calendarioSecretaria.feriados)
                .addCalendario(vm.calendario, 'escola', true)
                .process();

            vm.trackers.matriz.addPromise(
                calendarioMatrizCurricularService.list(vm.calendarioEstabelecimento.id).then(function(data) {
                    vm.calendariosMatriz = data;
                })
            );
        }

        function loadAvisos() {
            if (lastMatrizLoaded) {
                return calendarioMatrizCurricularService
                    .listAvisos(_.get(vm.calendarioEstabelecimento,'id'), lastMatrizLoaded)
                    .then(function(data) {
                        vm.avisos = data;
                    });
            } else {
                return calendarioEstabelecimentoService
                    .listAvisos(_.get(vm.calendarioEstabelecimento,'id'))
                    .then(function(data) {
                        vm.avisos = data;
                    });
            }
        }

        function goToYearView() {
            $scope.$broadcast(VISUALIZACAO_CALENDARIO_SHOW_YEAR_VIEW);
        }

        function onObservacaoEdited(event, observacao) {
            var calendarioEstabelecimento = _.cloneDeep(vm.calendarioEstabelecimento);
            calendarioEstabelecimento.calendario.observacao = observacao;

            vm.trackers.calendario.addPromise(
                calendarioEstabelecimentoService.save(calendarioEstabelecimento)
                .then(IdGenerator.generateAllId)
                .then(setCalendario)
                .then(notifyCalendarioSaved)
                .then(loadAvisos)
            );
        }

        function notifyCalendarioSaved(data) {
            Notification.publish('Calendário salvo com sucesso.', 'success');
            return data;
        }

        function openCalendarioModal() {
            var copy = _.cloneDeep(vm.calendario);

            var toEdit = CalendarioDisplay.create()
                .setBaseCalendar(copy)
                .addObservacao('rede municipal', vm.calendarioSecretaria.calendario.observacao)
                .addFeriados(vm.calendarioSecretaria.calendario.feriados)
                .addExcecoes(vm.calendarioSecretaria.calendario.excecoes)
                .addCalendario(copy, 'escola')
                .process();

            BfcDialog.open({
                templateUrl: 'calendario/cadastro/calendario-modal.html',
                controller: 'educacao.calendario.CalendarioModalController as vm',
                resolve: {
                    fnCad: $q.when(onCalendarioSaved),
                    calendarioDisplay: toEdit
                }
            });
        }

        function onCalendarioSaved(calendario) { 
            var calendarioEstabelecimento = _.clone(vm.calendarioEstabelecimento);
            calendarioEstabelecimento.calendario = calendario;

            var savePromise = calendarioEstabelecimentoService.save(calendarioEstabelecimento)
                .then(IdGenerator.generateAllId)
                .then(setCalendario)
                .then(notifyCalendarioSaved)
                .then(loadAvisos);

            vm.trackers.calendario.addPromise(savePromise);

            return savePromise;
        }

        function openMatrizModal(id) {
            var copy = _.cloneDeep(vm.calendario);

            var toEdit = CalendarioDisplay.create()
                .addObservacao('rede municipal', vm.calendarioSecretaria.calendario.observacao)
                .addFeriados(vm.calendarioSecretaria.calendario.feriados)
                .addExcecoes(vm.calendarioSecretaria.calendario.excecoes)
                .addCalendario(copy, 'escola')
                .process();

            var promise = id ? calendarioMatrizCurricularService.get(vm.calendarioEstabelecimento.id, id) : null;

            BfcDialog.open({
                templateUrl: 'calendario/calendario-matriz/calendario-matriz-modal.html',
                controller: 'educacao.calendario.CalendarioMatrizCurricularModalController as vm',
                resolve: {
                    fnCad: $q.when(onCalendarioMatrizSaved),
                    idCalendarioEstabelecimento: vm.calendarioEstabelecimento.id,
                    calendarioDisplay: toEdit,
                    calendarioMatriz: promise
                }
            });
        }

        function onCalendarioMatrizSaved(calendario, removed) {
            if (removed) {
                removeFromList(calendario.id);
            } else {
                IdGenerator.addOrUpdate(calendario, vm.calendariosMatriz);
            }
            vm.calendarioDisplay.process();
        }

        function onCalendarioMatrizSelected(event, id) {
            lastMatrizLoaded = id;
            if (!id) {
                vm.calendarioDisplay
                    .reset()
                    .addObservacao('rede municipal', vm.calendarioSecretaria.calendario.observacao)
                    .addExcecoes(vm.calendarioSecretaria.calendario.excecoes)
                    .addFeriados(vm.calendarioSecretaria.calendario.feriados)
                    .addCalendario(vm.calendario, 'escola', true)
                    .process();

                loadAvisos();
                return;
            }

            var loadMatrizPromise = calendarioMatrizCurricularService.get(vm.calendarioEstabelecimento.id, id)
                .then(function(calendarioMatriz) {
                    vm.calendarioDisplay
                        .reset()

                        .addObservacao('rede municipal', vm.calendarioSecretaria.calendario.observacao)
                        .addFeriados(vm.calendarioSecretaria.calendario.feriados)
                        .addExcecoes(vm.calendarioSecretaria.calendario.excecoes)

                        .addObservacao('escola', vm.calendario.observacao, false)
                        .addEventos(vm.calendario.eventos)
                        .addExcecoes(vm.calendario.excecoes)

                        .addCalendario(calendarioMatriz.calendario, calendarioMatriz.descricao)
                        .addPeriodosLetivos(calendarioMatriz.periodos)
                        .addFiltroEventos(criaFiltroEventoMatriz(calendarioMatriz.matrizCurricular.id))
                        .process();
                })
                .then(loadAvisos);

            vm.trackers.calendario.addPromise(loadMatrizPromise);
        }

        function criaFiltroEventoMatriz(idMatriz) {
            return function(evento) {
                return !evento.matrizesCurriculares || !evento.matrizesCurriculares.length || _.find(evento.matrizesCurriculares,
                    'id', idMatriz);
            };
        }

        function removeCalendarioMatriz(event, idCalendarioMatriz) {
            vm.trackers.calendario.addPromise(
                calendarioMatrizCurricularService
                .remove(vm.calendarioEstabelecimento.id, idCalendarioMatriz)
                .then(notifyCalendarioMatrizRemoved)
                .then(function() {
                    removeFromList(idCalendarioMatriz);
                })
            );
        }

        function notifyCalendarioMatrizRemoved(data) {
            Notification.publish('Calendário Matriz removido com sucesso', 'success');
            return data;
        }

        function removeFromList(idCalendarioMatriz) {
            if (lastMatrizLoaded === idCalendarioMatriz) {
                lastMatrizLoaded = null;
                onCalendarioMatrizSelected(null, lastMatrizLoaded);
            }
            vm.calendariosMatriz = vm.calendariosMatriz.filter(function(calendarioMatriz) {
                return calendarioMatriz.id !== idCalendarioMatriz;
            });
        }

        function openModalRegistroVinculado() {
            RegistroVinculadoService.openModal();
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.VisualizacaoInscricaoCtrl', VisualizacaoInscricaoCtrl);

    VisualizacaoInscricaoCtrl.$inject = ['inscricao', 'educacao.matricula.InscricaoMatriculaService', 'promiseTracker', 'ui.components.PrintService'];

    function VisualizacaoInscricaoCtrl(inscricao, InscricaoMatriculaService, promiseTracker, PrintService) {

        var vm = this;

        vm.tracker = {
            loading: promiseTracker()
        };

        vm.print = print;

        activate();

        function activate() {
            if (inscricao.id) {
                vm.tracker.loading.addPromise(InscricaoMatriculaService.get(inscricao.id)
                    .then(dadosInscricao));
            }
        }

        function dadosInscricao(data) {
            vm.inscricao = data;
        }

        function print() {
            PrintService.print('#imprimeInscricao');
        }

    }


})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.matricula.ClassificacaoInscritosListaEsperaCtrl', ClassificacaoInscritosListaEsperaCtrl);

	ClassificacaoInscritosListaEsperaCtrl.$inject = ['classificacao', 'promiseTracker',
		'educacao.matricula.ClassificacaoListaEsperaService', '$rootScope', '$q', '$modalInstance'];

	function ClassificacaoInscritosListaEsperaCtrl(classificacao, promiseTracker, ClassificacaoListaEsperaService,
		$rootScope, $q, $modalInstance) {

		var vm = this;
		vm.tracker = {};
		vm.tracker.loading = promiseTracker();
		vm.tracker.botoes = promiseTracker();
		vm.save = save;

		vm.classificacao = classificacao;

		init();

		function init() {
			vm.tracker.loading.addPromise(
				ClassificacaoListaEsperaService.getDefault(classificacao.classificacaoInscricao.configuracaoInscricao.id).then(
					function(data) {
						vm.listaEspera = angular.extend(data, {
							resultado: {
								id: classificacao.id
							},
							listaEspera: {
								id: classificacao.id
							}
						});
					})
			);
		}

		function save() {
			vm.tracker.botoes.addPromise(
				ClassificacaoListaEsperaService.enviar(classificacao.classificacaoInscricao.configuracaoInscricao.id, vm.listaEspera)
				.then(function(data) {
					$rootScope.$broadcast('refresh::classificacoes', {
						data: data,
						listaEspera: true
					});
					close();
				})
			);
		}

		function close() {
			$modalInstance.close();
		}

	}

})();

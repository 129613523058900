(function() {

    'use strict';

    angular.module('educacao.matriz')
        .config(stateConfig);

    stateConfig.$inject = [
            '$stateProvider',
            'STATES_ADICIONAR',
            'STATES_EDITAR'
        ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.matriz.eixoTematico';

        $stateProvider.state(STATE, {
            url: 'eixos-tematicos',
            data: {
                id: 'EixoTematicoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'planejamento/matriz/eixo-tematico/eixo-tematico.html'
                }
            }
        })
        .state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.matriz.EixoTematicoService',
            '$modalStates'
        ];

        function openModal($stateParams, EixoTematicoService, $modalStates) {
            $modalStates.open({
                getMethod: EixoTematicoService.getById,
                templateUrl: 'planejamento/matriz/eixo-tematico/eixo-tematico.cad.html',
                controller: 'educacao.matriz.EixoTematicoCadCtrl as vm',
                propertyName: 'eixoTematico',
                stateParams: $stateParams
            });
        }
    }
})();

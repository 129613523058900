(function() {
    'use strict';

    angular.module('educacao.matricula').directive('appSelectInepAee', SelectInepAeeDirective);

    function SelectInepAeeDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.searchAaee" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?'
            },
            controller: SelectInepAeeDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectInepAeeDirectiveController.$inject = ['educacao.matricula.AtividadeAEEService'];

    function SelectInepAeeDirectiveController(service) {

        var vm = this;

        vm.formatResult = formatResult;
        vm.searchAaee = searchAaee;

        function searchAaee(params) {
            var filter = '(descricao like "' + params.term + '" and databaseId is null and codigoInep is not null)';

            params.sort = 'codigoInep';

            return service.getList(params.offset, params.limit, filter, params.sort).then(function(data) {
                return {
                    content: data,
                    hasNext: false
                };

            });
        }

        function formatResult(data) {
            return data.codigoInep + ' - ' + data.descricao;
        }
    }
})();

(function () {

    'use strict';

    var AREA_CONHECIMENTO = 'AREA_CONHECIMENTO';
    var CONFIGURACAO_TURMA = 'CONFIGURACAO_TURMA';
    var ATIVIDADE = 'ATIVIDADE';

    angular.module('configuracao-avaliacao-turma')
        .controller('configuracao-avaliacao-turma.ConfiguracaoAvaliacaoTurmaController', ConfiguracaoAvaliacaoTurmaController);

    ConfiguracaoAvaliacaoTurmaController.$inject = [
        'promiseTracker',
        '$modalInstance',
        'educacao.matricula.TurmaService',
        'educacao.common.ItemEducacionalService',
        'configuracao-avaliacao-turma.ConfiguracaoAvaliacaoTurmaService',
        'configuracao-avaliacao-turma.BuildAvaliaveisService',
        'educacao.calendario.CalendarioMatrizCurricularBuscaService',
        'bfc.Notification',
        'ITEM_AVALIAVEL_TIPO_PERIODO',
        'educacao.common.$commons',
        'educacao.context.ContextoEntidade',
        'APP_STAGE',
        'ENTIDADES_CONCEITOS_POR_PERIODO'
    ];

    function ConfiguracaoAvaliacaoTurmaController(
        promiseTracker,
        $modalInstance,
        TurmaService,
        ItemEducacionalService,
        ConfiguracaoAvaliacaoTurmaService,
        BuildAvaliaveisService,
        CalendarioMatrizCurricularBuscaService,
        bfcNotification,
        ITEM_AVALIAVEL_TIPO_PERIODO,
        $commons,
        ContextoEntidade,
        APP_STAGE,
        ENTIDADES_CONCEITOS_POR_PERIODO
    ) {

        var ENVS_DEV = ['development'];
        var IDS_ENTIDADES_CONC_PERIODOS = ENTIDADES_CONCEITOS_POR_PERIODO.trim().split(',').map(function (item) { return parseInt(item); });
        var TIPO_PERIODO = ITEM_AVALIAVEL_TIPO_PERIODO;

        var vm = this;

        vm.onChangeTipoAvaliacao = onChangeTipoAvaliacao;
        vm.onClickAplicar = onClickAplicar;
        vm.onClickSave = onClickSave;
        vm.isAllTurmasUtilizandoConfiguracao = isAllTurmasUtilizandoConfiguracao;
        vm.showCheckBoxConceitoEspecifico = showCheckBoxConceitoEspecifico;
        vm.canShowConfigLagoaSanta = showConfigLagoaSanta();

        vm.isTrimestre = false;

        vm.tableControlsConfiguracao = {};

        vm.tracker = {
            modal: promiseTracker(),
            save: promiseTracker()
        };

        vm.turmas = $modalInstance.params.turmas || [];

        //Inicializa turma base para buscar as configurações. Como as turmas selecionadas possuem configurações iguais
        //pode ser utilizada qualquer turma.
        vm.$$turma = _.first(vm.turmas);
        vm.utilizaConceitoEspecificoPorPeriodo = isAllTurmasUtilizandoConfiguracao();

        init();

        function init() {
            vm.isAtividade = ItemEducacionalService.isAtividade(vm.$$turma.tipoAtendimento);
            vm.isModular = ItemEducacionalService.isEjaModular(vm.$$turma);
            vm.isCamposExperiencia = ItemEducacionalService.isCamposExperiencia(vm.$$turma);
            vm.isAreaConhecimento = isAreaConhecimento();
            vm.isEdicao = isEdicao();
            vm.hasExameFinal = findExameFinal();
            vm.hasCalendario = true;

            if (!vm.isModular && !vm.isAtividade) {
                vm.tracker.modal.addPromise(loadPeriodosAvaliativos());
            }

            vm.tracker.modal.addPromise(loadComponentesCurriculares());

            initConfiguracaoAvaliacao();
        }

        function isAllTurmasUtilizandoConfiguracao() {
            return _.every(vm.turmas, function (turma) {
                return _.get(turma, 'utilizaConceitosEspecificosPorPeriodo');
            });
        }


        function initConfiguracaoAvaliacao() {
            if (vm.isEdicao) {
                var promise = TurmaService.getConfiguracaoAvaliacao(vm.$$turma.id)
                    .then(setConfiguracaoAvaliacao);
                vm.tracker.modal.addPromise(promise);
            }
        }

        function setConfiguracaoAvaliacao(data) {
            if (data.id) {
                vm.configuracaoAvaliacao = data;
            }
        }

        function loadComponentesCurriculares() {
            return TurmaService.getFilhos(vm.$$turma.id)
                .then(mapComponentes);
        }

        /**
         * Busca os períodos avaliativos para a turma base
         */
        function loadPeriodosAvaliativos() {
            return TurmaService.getPeriodosAvaliativos(vm.$$turma.id)
                .then(setPeriodosAvaliativos);
        }

        /**
         * Seta os períodos avaliativos e verifica se existe calendário setado nas turmas
         * Se alguma turma não possuir o calendário setado, chama a função 'getCalendar' para buscá-lo
         * @param {*Object} data - períodos
         */
        function setPeriodosAvaliativos(data) {
            vm.periodos = data;
            setIsTrimistre();
            vm.hasCalendario = !vm.isModular && !vm.isAtividade && _.isEmpty((_.filter(vm.turmas, function (t) {
                return !t.calendarioMatrizCurricular.id;
            })));
            if (!vm.hasCalendario && _.get(vm.$$turma, 'matriz.curso.nivelModalidade') === 'EDUCACAO_BASICA') {
                getCalendar(vm.$$turma.estabelecimento.id, vm.$$turma.matriz.id);
            } else {
                vm.calendarioMatrizCurricular = {
                    id: _.get(vm.$$turma, 'calendarioMatrizCurricular.id') || vm.$$turma.calendarioMatrizCurricular
                };
            }
        }


        function setIsTrimistre() {
            vm.isTrimestre = _.size(vm.periodos) === 3;
        }


        /**
         * Busca o calendário da matriz e adiciona no objeto a ser persistido
         * @param {*Integer} estabelecimento
         * @param {*Integer} matriz
         */
        function getCalendar(estabelecimento, matriz) {
            var template = _.template('estabelecimento = <%= estabelecimento %> and matriz = <%= matriz %>');
            CalendarioMatrizCurricularBuscaService.listCompletos(template({
                estabelecimento: estabelecimento || -1,
                matriz: matriz || -1
            })).then(function (calendario) {
                if (!calendario.content.length) {
                    vm.hasCalendario = false;
                    return;
                }
                vm.calendarioMatrizCurricular = _.first(calendario.content);
                vm.hasCalendario = true;
            });
        }

        function isAreaConhecimento() {
            return vm.$$turma.tipoResultado === AREA_CONHECIMENTO;
        }

        /**
         * Retorna true se a modal será exibida em modo edição
         * Caso tenha mais de 1 turma selecionada, a modal é aberta em modo de ADIÇÃO
         */
        function isEdicao() {
            return vm.turmas.length <= 1;
        }

        /**
         * Retorna true se alguma turma possuir quantidade de exames finais maior que 0
         */
        function findExameFinal() {
            return !!_.find(vm.turmas, function (t) {
                return t.quantidadeExamesFinais;
            });
        }

        /**
         * Seta variável de componentes do vm e adiciona descrição
         * @param {*} componentes
         */
        function mapComponentes(componentes) {
            var componentesChain = _.chain(componentes);

            componentesChain.map(function (componente) {
                componente.itensAvaliaveis = _.filter(componente.itensAvaliaveis,
                    function (item) {
                        return !(item.tipoPeriodo === 'INSTRUMENTO' && item.tipo === 'CHA');
                    }
                );
                return componente;
            }).value();

            //Monta areas de conhecimento e disciplinas no mesmo nível
            if (vm.isAreaConhecimento) {
                componentesChain = componentesChain
                    .map(mapAreasConhecimento)
                    .flatten();
            }

            //Adiciona descrição para facilitar processamento
            componentesChain = componentesChain.map(addDescricaoComponente);
            if (vm.isEdicao) {
                vm.componentes = componentesChain.value();
                return;
            }

            //Limpa os itens educacionais caso a tela esteja em modo de Adição
            vm.componentes = componentesChain
                .map(clearItensAvaliaveis)
                .value();
        }

        function mapAreasConhecimento(componente) {
            if (componente.tipo !== AREA_CONHECIMENTO) {
                return componente;
            }
            return [componente].concat(componente.filhos);
        }

        /**
         * Adiciona descrição interna no componente passado
         * @param {object} componente
         */
        function addDescricaoComponente(componente) {
            componente.$$descricao = _.get(componente, vm.isCamposExperiencia ?
                    'etapaMatrizDisciplina.campoExperiencia.descricao' : 'etapaMatrizDisciplina.disciplina.descricao') ||
                _.get(componente, 'etapaMatrizDisciplina.eixoTematico.descricao');

            if (componente.tipo === ATIVIDADE) {
                componente.$$descricao = _.get(componente, 'atividade.descricao');
            }

            if (componente.tipo === AREA_CONHECIMENTO) {
                componente.$$descricao = _.get(componente, 'eixoTematico.descricao');
            }

            return componente;
        }

        /**
         * Limpa os itens avaliáveis de um componente curricular
         * @param {Object} componente - Componente curricular
         */
        function clearItensAvaliaveis(componente) {
            componente.itensAvaliaveis = [];
            return componente;
        }

        function onChangeTipoAvaliacao() {
            vm.defaultModoAvaliacao = null;
        }

        function onClickAplicar() {
            if (vm.hasCalendario) {
                vm.tableControlsConfiguracao.applyDefaultMode(vm.defaultModoAvaliacao);
            }
        }

        function isAlteracaoModoAvaliacao() {
            if (vm.turmas.length > 1) {
                return true;
            }

            var hasAlteracao = _.some(vm.componentes, function (componente) {
                return _.some(componente.itensAvaliaveis, function (itemAvaliavel) {
                    var itemAvaliavelTemp = _.find(componente.$$itensAvaliaveis, {
                        id: itemAvaliavel.id,
                    });

                    return (
                        itemAvaliavelTemp &&
                        !itemAvaliavelTemp.$$isNonEditable &&
                        itemAvaliavel.modoAvaliacao !== itemAvaliavelTemp.modoAvaliacao
                    );
                });
            });

            return hasAlteracao;
        }

        function onClickSave() {
            if (isAlteracaoModoAvaliacao()) {
                $commons.$modal.open({
                    templateUrl: 'matricula/turma/configuracao-avaliacao/confirmacao-configuracao-avaliacao.html',
                    controller: 'configuracao-avaliacao-turma.ConfirmacaoConfiguracaoAvaliacaoController as vm',
                    params: {
                        turmas: vm.turmas
                    }
                }).result.then(resultConfirmacao);
            } else {
                save();
            }
        }

        function resultConfirmacao(value) {
            if (!value) {
                return;
            }

            save();
        }

        function saveAsync() {
            setModoAvaliacaoTipoChaOuObjAprendizagem(vm.componentes);

            var configuracaoAvaliacaoTurma = {
                turmas: vm.turmas,
                configuracaoAvaliacao: vm.configuracaoAvaliacao,
                tipo: getTipoProcessamento(),
                itensConfiguracao: BuildAvaliaveisService.buildItensConfiguracao(vm.componentes, vm.isEdicao),
                calendarioMatrizCurricular: vm.calendarioMatrizCurricular
            };

            var promise = ConfiguracaoAvaliacaoTurmaService.save(configuracaoAvaliacaoTurma)
                .then(onSave);
            vm.tracker.save.addPromise(promise);
        }

        function onSave() {
            bfcNotification.publish(
                'Processo de configuração dos tipos de avaliação da(s) turma(s) em andamento. Ao término do processamento, você será notificado.',
                'info');
            $modalInstance.close();
        }

        function save() {
            var promise = ConfiguracaoAvaliacaoTurmaService.saveConfiguracaoPorPeriodoEspecifico({
                isUtilizaConceitosEspecificosPorPeriodo: vm.utilizaConceitoEspecificoPorPeriodo,
                idsTurma: _.map(vm.turmas, 'id')
            }, vm.canShowConfigLagoaSanta).then(saveAsync);
            vm.tracker.save.addPromise(promise);
        }

        function showCheckBoxConceitoEspecifico() {
            return _.get(vm.configuracaoAvaliacao, 'avaliacaoConceitoNumerico') &&
                vm.hasCalendario &&
                vm.isTrimestre &&
                isTurmasBasicaFundamental();
        }

        function isTurmasBasicaFundamental() {
            return _.every(vm.turmas, function (turma) {
                var curso = _.get(turma, 'matriz.curso');
                return _.get(curso, 'nivelModalidade') === 'EDUCACAO_BASICA' &&
                    _.get(curso, 'nivelEscolar') === 'ENSINO_FUNDAMENTAL';
            });
        }


        /**
         * Retorna o tipo de processamento com base em informações da turma
         */
        function getTipoProcessamento() {
            if (vm.isEdicao) {
                return 'NORMAL';
            }
            return 'MULTIPLO';
        }

        function setModoAvaliacaoTipoChaOuObjAprendizagem(componentes) {
            _.forEach(componentes, setModoAvaliacaoTipoChaOuObjPorPeriodoExameFinal);
        }

        function setModoAvaliacaoTipoChaOuObjPorPeriodoExameFinal(componente) {
            setModoAvaliacaoPorPeriodo(componente);
            setModoAvaliacaoExameFinal(componente);
        }

        function setModoAvaliacaoPorPeriodo(componente) {
            var setModoAvaliacaoPorPeriodoFunction = setItensModoAvaliacaoPorPeriodo.bind(null, componente);
            _.forEach(vm.periodos, setModoAvaliacaoPorPeriodoFunction);
        }

        function setItensModoAvaliacaoPorPeriodo(componente, periodo) {
            var $$itemAvaliavelChaOuObjAprendizagemPorPeriodo = getItemAvaliavelChaOuObjAprendizagemPorPeriodo(componente.$$itensAvaliaveis, periodo);
            setModoAvaliacaoParaItensAvaliaveisChaOuObjAprendizagem(componente.itensAvaliaveis, periodo, $$itemAvaliavelChaOuObjAprendizagemPorPeriodo);
        }

        function getItemAvaliavelChaOuObjAprendizagemPorPeriodo(itensAvaliaveis, periodo) {
            return _.first(_.filter(itensAvaliaveis, getChaObjPorPeriodoFilter(periodo)));
        }

        function getChaObjPorPeriodoFilter(periodo) {
            return {
                'periodoAvaliativo': {'id': periodo.id},
                'tipoPeriodo': TIPO_PERIODO.CHA_OBJETIVO_MEDIA_PERIODO,
                'tipo': CONFIGURACAO_TURMA
            };
        }

        function setModoAvaliacaoParaItensAvaliaveisChaOuObjAprendizagem(itensAvaliaveisChaOuObjAprendizagem, periodo, itemAvaliavelChaOuObjAprendizagemPorPeriodo) {
            _.filter(itensAvaliaveisChaOuObjAprendizagem, {'periodoAvaliativo': {'id': periodo.id}}).forEach(function (item) {
                item.modoAvaliacao = itemAvaliavelChaOuObjAprendizagemPorPeriodo.modoAvaliacao;
            });
        }

        function setModoAvaliacaoExameFinal(componente) {
            var setModoAvaliacaoItensAvaliavesFinaisFunction = setModoAvaliacaoParaItensAvaliaveisChaOuObjAprendizagemFinais.bind(null, getItemAvaliavelExameFinal(componente));
            _.forEach(componente.itensAvaliaveis.filter(isExameFinal), setModoAvaliacaoItensAvaliavesFinaisFunction);
        }

        function setModoAvaliacaoParaItensAvaliaveisChaOuObjAprendizagemFinais($$itensAvaliaveisFinais, itemAvaliavelChaOuObjAprendizagemFinais) {
            var modoAvaliacaoChaObjAprendizagemExameFinal = _.find($$itensAvaliaveisFinais, {'tipoPeriodo': TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL});
            if (itemAvaliavelChaOuObjAprendizagemFinais.tipoPeriodo === TIPO_PERIODO.CHA_OBJETIVO_EXAME_FINAL) {
                itemAvaliavelChaOuObjAprendizagemFinais.modoAvaliacao = modoAvaliacaoChaObjAprendizagemExameFinal.modoAvaliacao;
            }
        }

        function getItemAvaliavelExameFinal(componente) {
            var indexExameFinal = getIndexExameFinal(componente);
            return componente.$$itensAvaliaveis.slice(indexExameFinal, indexExameFinal + 2);
        }

        function getIndexExameFinal(componente) {
            return _.findLastIndex(componente.$$itensAvaliaveis, 'tipoPeriodo', TIPO_PERIODO.EXAME_FINAL);
        }

        function isExameFinal(data) {
            return _.get(data, 'tipoPeriodo') === TIPO_PERIODO.EXAME_FINAL;
        }

        function showConfigLagoaSanta() {
            return ENVS_DEV.includes(APP_STAGE) || IDS_ENTIDADES_CONC_PERIODOS.includes(ContextoEntidade.getEntidade().id);
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.context', [
        'ngLocale',
        'bfc',
        'bfc.oauth2',
        // 'user-accounts-ui',
        'bfc.user-access',
        'bfc.state',
        // 'generic-service',
        'bfc.context-initializer'
        ]);
})();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosAulasGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/table-dados-aulas-gestao-pessoas/table-dados-aulas-gestao-pessoas.html',
            scope: {},
            bindToController: {
                filter: '=',
                search: '=',
                estabelecimentoSelected: '=',
                anoLetivoSelected: '=',
                escolaSelecionadas: '=',
                duracoesSelecionadas: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'ui.components.Modal',
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        'promiseTracker',
        'educacao.context.ContextoEstabelecimento'
    ];

    function Controller($scope, uiModal, QuadroFuncionariosGestaoPessoasService, promiseTracker, ContextoEstabelecimento) {
        var vm = this;

        vm.onClickAction = onClickAction;

        vm.service = QuadroFuncionariosGestaoPessoasService;
        vm.itens = [];
        vm.currentPage = 1;
        vm.itemsPerPage = 20;
        vm.optionsItemsPerPage = [20, 50, 100];

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();

        vm.loadListComponentesCurriculares = loadListComponentesCurriculares;
        vm.showTableEstabelecimento = showTableEstabelecimento;
        vm.listControls = {};
        vm.componentesCurriculares = [];
        $scope.$watch('vm.estabelecimentoSelected', onChangeContextEstabelecimento);
        $scope.$watch('vm.anoLetivoSelected', onChangeContextAnoLetivo);
        $scope.$watch('vm.search', loadListComponentesCurriculares);
        $scope.$watch('vm.currentPage', watcherCurrentPage);
        $scope.$watch('vm.itemsPerPage', watcherCurrentPage);

        vm.tracker = {
            loading: promiseTracker(),
            loadingByComponente: promiseTracker(),
            init: promiseTracker()
        };

        init();


        function init() {
            loadListComponentesCurriculares();
        }

        function onChangeContextEstabelecimento(newValue, oldValue) {
            if (newValue !== oldValue) {
                loadListComponentesCurriculares();
            }
        }

        function onChangeContextAnoLetivo(newValue, oldValue) {
            if (newValue !== oldValue) {
                loadListComponentesCurriculares();
            }
        }

        function loadListComponentesCurriculares() {
            var params = {
                filter: vm.filter,
                offset: vm.currentPage - 1,
                limit: vm.itemsPerPage,
                sort: null,
                estabelecimentoId: _.get(vm.estabelecimentoSelected, 'id'),
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id')
            };

            params.offset = params.offset > 0 ? params.offset * params.limit : 0;
            var promise = vm.service.listAulas(params).then(buildPaginacao);
            vm.tracker.loading.addPromise(promise);


        }

        function watcherCurrentPage(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            loadListComponentesCurriculares();
        }


        function buildPaginacao(itens) {
            vm.itens = getSaldosDivisaoDisciplinaByComponenteCurricular(itens);
            var startIdx = (vm.currentPage - 1) * vm.itemsPerPage;
            var itensPaginacao = vm.itens.slice(startIdx, startIdx + vm.itemsPerPage);
            vm.totalItems = vm.itens.length;
            vm.hasNext = (startIdx + vm.itemsPerPage) < vm.totalItems;
            vm.totalContent = vm.totalItems;
            vm.componentesCurriculares = itensPaginacao;
        }

        function getSaldosDivisaoDisciplinaByComponenteCurricular(content) {
            if (_.isEmpty(content)) {
                return content;
            }
            var result = content.reduce(function (acc, curr) {
                var key = curr.componenteCurricularId;

                if (!acc[key]) {
                    acc[key] = createDefaultSaldoComponenteCurricular(curr);
                }

                sumSaldosDivisaoComponentesCurriculares(acc[key], curr);

                return acc;
            }, {});

            return _.values(result);
        }

        function createDefaultSaldoComponenteCurricular(componenteCurricular) {
            return {
                descricao: componenteCurricular.descricaoComponenteCurricular,
                totalAulas: 0,
                totalAulasVagas: 0,
                idComponenteCurricular: componenteCurricular.componenteCurricularId,
                aulasPorDuracao: [],
                aulasPorTurmas: [],
            };
        }

        function sumSaldosDivisaoComponentesCurriculares(saldo, componenteCurricular) {
            saldo.totalAulas += componenteCurricular.totalAulas;
            saldo.totalAulasVagas += componenteCurricular.totalAulasVagas;
            saldo.aulasPorDuracao.push({
                tempo: componenteCurricular.duracaoAula,
                totalAulas: componenteCurricular.totalAulas,
                totalAulasVagas: componenteCurricular.totalAulasVagas,
            });
            saldo.aulasPorTurmas.push({
                descricao: componenteCurricular.turma.descricao,
                turno: componenteCurricular.turma.turno,
                totalAulas: componenteCurricular.totalAulas,
                totalAulasVagas: componenteCurricular.totalAulasVagas,
                tempo: componenteCurricular.duracaoAula
            });
        }

        function onClickAction(data) {
            uiModal.open({
                templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/componente-curricular-info-modal/componente-curricular-info-modal.html',
                controller: 'educacao.gestao-pessoas-quadro-informativo.ComponenteCurricularInfoModalController',
                controllerAs: 'vm',
                size: 'xxl',
                params: {
                    idComponenteCurricular: data.idComponenteCurricular,
                    escolaSelecionadas: vm.escolaSelecionadas,
                    duracoesSelecionadas: vm.duracoesSelecionadas,
                    estabelecimentoId: _.get(vm.estabelecimentoSelected, 'id'),
                    anoLetivoId: _.get(vm.anoLetivoSelected, 'id'),
                    data: data
                }
            });
        }

        function showTableEstabelecimento() {
            return !vm.isSecretaria || _.get(vm.estabelecimentoSelected, 'id');
        }

    }
})();
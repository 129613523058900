(function() {
  'use strict';

  angular.module('educacao.quadro-funcionarios-configuracao')
  .controller('educacao.quadro-funcionarios-configuracao.QuadroFuncionariosController', Controller);

  Controller.$inject = [
    '$scope',
    'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosService',
    'ui.components.Modal',
    'bfc.Notification',
    'educacao.quadro-funcionarios-configuracao.QuadroFuncionariosUtils',
    '$timeout'
  ];

  function Controller($scope, QuadroFuncionariosService, uiModal, bfcNotification, QuadroFuncionariosUtils, $timeout) {
    var vm = this;

    vm.editarButtonDisabled = false;
    vm.QuadroFuncionariosService = QuadroFuncionariosService;
    vm.nivelEscolar = 'EDUCACAO_INFANTIL';
    vm.nivelModalidade = 'EDUCACAO_BASICA';

    vm.openModal = openModal;
    vm.remove = QuadroFuncionariosUtils.remove;
    vm.search = search;
    vm.columnsSearch = getColumnsSearch();
    vm.setNivelEscolar = setNivelEscolar;
    
    vm.userListControls = {};
    vm.filter = {};
    

    function openModal(item) {
      if (vm.editarButtonDisabled) {
        return;
      }
      vm.editarButtonDisabled = true;
      $timeout(function () {
        vm.editarButtonDisabled = false;
      }, 200);

      return uiModal.open({
        templateUrl: 'quadro-informativo/configuracao/configuracao-quadro-funcionarios-modal/configuracao-quadro-funcionarios-modal.html',
        controller: 'educacao.quadro-funcionarios-configuracao.ConfigQuadroFuncionariosModalController',
        controllerAs: 'vm',
        size: 'xl',
        params: {
          configQuadroFuncionario: item ? QuadroFuncionariosService.getOne(item.id) : {},
          nivelEscolar: vm.nivelEscolar,
          nivelModalidade: vm.nivelModalidade
        }
      }).result;
    }

    function getColumnsSearch() {
      return [
        {
          id: 'matriz',
          model: 'matrizCurricular.descricao',
          label: 'Matriz',
          filterable: true
        },
        {
          id: 'situacao',
          model: 'ativo',
          label: 'Situação',
          filterable: false
        },
      ];
    }

    vm.listCustomBuilder = {
      onBeforeBuild: function () {
        return 'nivelModalidade = "' + vm.nivelModalidade + '" and nivelEscolar = "' + vm.nivelEscolar + '"';
      }
    };

    function search() {
      vm.userListControls.search();
    }

    function setNivelEscolar(nivelEscolar) {
      vm.nivelEscolar = nivelEscolar;
      search();
    }

  }
})();
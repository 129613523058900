(function() {
	'use strict';

	angular.module('educacao.matricula')
		.controller('educacao.pessoas.InscricaoOnlineCtrl', InscricaoOnlineCtrl);
	
	InscricaoOnlineCtrl.$inject = ['$injector', 'promiseTracker', '$q' ];
	
	function InscricaoOnlineCtrl($injector, promiseTracker, $q){
        var vm = this, TESTE=666,
            ConfiguracaoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoInscricaoService');
		
        vm.InscricaoService = $injector.get('educacao.matricula.InscricaoMatriculaService');
        
        vm.tracker = {};
        vm.tracker.loadingInscricao = promiseTracker();
        vm.inscricao = {};
        vm.configuracao = {};
        vm.selectedStep = 1;

        vm.formularioVisible = true;
        
        init();
        
        function init(){
            
            vm.tracker.loadingInscricao.addPromise(
                $q.all([
                    refresh(),
                    ConfiguracaoInscricaoService.get(TESTE).then(function(data){
                        vm.configuracao = data;
                    })
                ]).then(function(){
                    vm.selectedStep = 1;
                })
            );
            
        }
        
        function refresh(){
            return vm.InscricaoService.getDefault().then(function(data){
                vm.inscricao = data;

                if(!vm.inscricao.dados){
                    vm.inscricao.dados = {};
                }
            });
        }
        
	}

})();	
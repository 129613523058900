(function() {

    'use strict';

    var DEFINICAO_SITUACAO_COR = [
        {
            situacao: 'CURSANDO',
            colorClass: 'bg__green--l10'
    		},
        {
            situacao: 'EM_EXAME',
            colorClass: 'bg__orange--l10'
    		},
        {
            situacao: 'APROVADO',
            colorClass: 'bg__green--l10'
    		},
        {
            situacao: 'APROVADO_DEPENDENCIA',
            colorClass: 'bg__green--l10'
    		},
        {
            situacao: 'REPROVADO',
            colorClass: 'bg__red--l20'
    		},
        {
            situacao: 'REPROVADO_FREQUENCIA',
            colorClass: 'bg__red--l20'
    		}
    	];

    angular.module('educacao.rematricula')
        .directive('tagSituacaoAluno', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<span class="label {{::vm.colorClass}} center-block mTop3">{{vm.descricaoSituacao}}</span>',
            scope: {},
            bindToController: {
                situacaoAluno: '=',
                situacoesMatricula: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {

        var vm = this;
        vm.descricaoSituacao = _.find(vm.situacoesMatricula, 'id', vm.situacaoAluno).description;
        vm.colorClass = _.get(_.find(DEFINICAO_SITUACAO_COR, 'situacao', vm.situacaoAluno), 'colorClass');

    }

})();


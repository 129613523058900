(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.ClassificacaoInscritosCtrl', ClassificacaoInscritosCtrl);

    ClassificacaoInscritosCtrl.$inject = [
        '$scope',
        '$timeout',
        'educacao.common.ModalCad',
        'bfc.dialog.Dialog',
        'promiseTracker'
    ];


    function ClassificacaoInscritosCtrl(
        $scope,
        $timeout,
        ModalCad,
        bfcDialog,
        promiseTracker
    ) {
        var vm = this,
            REFRESH_CONFIGURACOES = 'refresh::configuracoesInscricao';
        $scope.localTrabalho = {};
        vm.actionForClickTab = actionForClickTab;

        vm.loadingDados = promiseTracker();
        vm.changeTab = changeTab;

        vm.niveisEscolares = [ {
            tooltipInfantil: '',
        },{
            tooltipFundamental: '',
        },{
            tooltipMedio: '',
        }];


        $scope.open = function(id) {
            ModalCad.open({
                templateUrl: 'matricula/classificacao-inscritos/classificacao.inscritos.cad.html',
                controller: 'educacao.pessoas.ClassificacaoInscritosCadCtrl',
                id: id,
                readOnly: false
            });
        };

        init();


        function init() {
            $scope.altTabs = 1;


        }

        function changeTab(nivel) {
            vm.tabSelecionada = nivel;
        }

        $scope.$on(REFRESH_CONFIGURACOES, function() {
            init();
        });

        function actionForClickTab(tab) {
            if (tab.id === '1') {
                $scope.passo1 = true;
                $scope.passo2 = false;
                $scope.passo3 = false;
                $scope.altTabs = 1;
            }
            if (tab.id === '2') {
                $scope.passo1 = false;
                $scope.passo2 = true;
                $scope.passo3 = false;
                $scope.altTabs = 2;

            }
            if (tab.id === '3') {
                $scope.altTabs = 3;
            }
            if (tab.id === '4') {
                $scope.altTabs = 4;
            }
        }
    }

})();

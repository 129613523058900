(function () {
    'use strict';

    angular.module('educacao.agendaprofessor')
        .directive('professorList', ProfessorList);

    function ProfessorList() {
        return {
            restrict: 'E',
            templateUrl: 'agenda-professor/disponibilidade/professor-list.directive.html',
            scope: {},
            controller: ControllerList,
            controllerAs: 'vm'
        };
    }

    ControllerList.$inject = [
        '$scope',
        '$q',
        'promiseTracker',
        'LIST_PROFESSOR_COLORS',
        'educacao.funcionario.FuncionarioMatriculaService',

    ];

    function ControllerList($scope, $q, promiseTracker, LIST_PROFESSOR_COLORS, FuncionarioMatriculaService
        //, EstabelecimentoService
    ) {

        var vm = this;

        vm.tracker = {
            item: promiseTracker()
        };

        vm.onItemClicked = onItemClicked;

        init();

        function init() {
            FuncionarioMatriculaService.getServidores().then(function (data) {
                var dados = data;

                vm.groupEstabelecimentos = _.chain(dados)
                    .groupBy('estabelecimento.id')
                    .map(mapGroups)
                    .value();
            });
        }

        function mapGroups(dados) {
            return {
                estabelecimento: _.get(_.first(dados), 'estabelecimento'),
                professores: _.chain(dados)
                    .map(function (item, key) {
                        item.class = getColors(getIndex(key));
                        item.index = key;
                        return item;
                    }).value()
            };
        }

        function onItemClicked(item) {

            if (vm.tracker.item.tracking()) {
                return;
            }

            item.selected = !item.selected;

            $scope.$emit('item.clicked', item);
        }

        function getIndex(i) {
            return (i > 9) ? i % 10 : i;
        }

        function getColors(i) {
            return 'text-' + LIST_PROFESSOR_COLORS[i];
        }
    }
})();
(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.calendario');

    moduloCommon.factory('educacao.calendario.EventoService', ['GenericService', function(GenericService) {
        return GenericService
            .create()
            .module({
                uri: 'calendario'
            })
            .service({
                uri: 'eventos'
            });
    }]);
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.EstabelecimentoSelect', EstabelecimentoSelect);

    EstabelecimentoSelect.$inject = ['$injector'];

    function EstabelecimentoSelect($injector) {

        var EstabelecimentoService = $injector.get('educacao.pessoas.EstabelecimentoServiceDeprecated');
        var CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService');

        return {
            select2Config: function(options
) {
                return CommonSelectWithService.select2Config(EstabelecimentoService, 'nome', angular.extend({
                    formatResult: function(data) {
                        return data.pessoa.nome;
                    },
                    formatSelection: function(data) {
                        return data.pessoa.nome;
                    }

                }, options));
            }
        };
    }
})();

(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.configuracao')
        .service('educacao.lista-espera-novo.configuracao.ConfigListaEsperaService', ConfiguracaoListaEsperaService);

    ConfiguracaoListaEsperaService.$inject = ['ServiceBuilder', 'Restangular'];

    function ConfiguracaoListaEsperaService(ServiceBuilder, Restangular) {
        var service = ServiceBuilder.create()
            .path('lista-espera', 'configuracao-lista-espera')
            .build();

        service.listConfiguracao = listConfiguracao;
        service.saveBase = saveBase;
        service.getMatrizEtapa = getMatrizEtapa;
        service.getEstabelecimentos = getEstabelecimentos;
        service.getUrlInscricao = getUrlInscricao;
        service.getCamposFormulario = getCamposFormulario;
        service.getInscritosOrMatriculados = getInscritosOrMatriculados;
        service.getInscricaoId = getInscricaoId;
        service.listConfiguracaoInscricao = listConfiguracaoInscricao;
        service.listConfiguracaoByNivelEscolar = listConfiguracaoByNivelEscolar;

        return service;

        function listConfiguracao(params) {
            return service.getEndpoint().all('contextos').getList({
                limit: params.limit,
                offset: params.offset,
                sort: params.sort,
                filter: '(descricao like "' + (params.term || '') + '")'
            });
        }

        function saveBase(dado) {
            return dado.id ? Restangular.one('lista-espera').one('configuracao-lista-espera', dado.id).customPUT(dado) :
                Restangular.one('lista-espera').all('configuracao-lista-espera').post(dado);
        }

        function getMatrizEtapa(offset, limit, filter, sort) {
            return Restangular.one('matriz/matriz-curricular').all('matrizes-etapas').getList({
                offset: offset,
                limit: limit,
                filter: filter,
                sort: sort
            }).then(strip);
        }

        function getEstabelecimentos(estabelecimentos) {
            return Restangular.one('commons/estabelecimento')
                .getList('context', {
                    id: estabelecimentos
                })
                .then(strip);
        }

        function getUrlInscricao() {
            return service.getEndpoint()
                .one('url-inscricao-online')
                .get();
        }

        function listConfiguracaoByNivelEscolar(nivelEscolar) {
            return service.getEndpoint()
                .one('nivel-escolar', nivelEscolar)
                .get();
        }

        function getCamposFormulario() {
            return service.getEndpoint()
                .one('campos-formulario-inscricao')
                .get();
        }

        function strip(list) {
            return Restangular.stripRestangular(list);
        }

        function getInscritosOrMatriculados(filter) {
            return Restangular.one('lista-espera/pesquisa-inscricao/sugerir')
                .getList('',{
                    filter: filter
                })
                .then(strip);    
        }

        function getInscricaoId(id) {
            return Restangular.one('lista-espera/pesquisa-inscricao', id)
                .get()
                .then(strip);    
        }

        function listConfiguracaoInscricao() {
            return service.getEndpoint()
                .one('inscricao')
                .get();
        }
    }
})();

(function() {
    'use strict';

    var appInjector;

    angular.module('educacao.calendario')
        .config(CalendarioStates)
        .run(['$injector', function($injector) {
            appInjector = $injector;
        }]);

    CalendarioStates.$inject = ['$stateProvider'];

    function CalendarioStates($stateProvider) {
        $stateProvider
            .state('app.evento', {
                url: 'eventos',
                data: {
                    id: 'EventoPageMapping'
                },
                views: {
                    '@': {
                        templateUrl: 'calendario/evento/evento.html'
                    }
                }
            })

        .state('app.calendario', {
            url: 'calendario',
            data: {
                id: 'CalendarioPageMapping'
            },
            views: {
                '@': {
                    templateUrl: function() {
                        return appInjector.invoke(getTemplateUrl);
                    }
                }
            }
        })
        .state('app.calendario.registrosvinculados', {
            abstract: true,
            data: {
                id: 'RegistroVinculadoPageMapping',
                mirrorOf: 'app.calendario'
            }
        });

        getTemplateUrl.$inject = ['educacao.context.ContextoEstabelecimento'];

        function getTemplateUrl(ContextoEstabelecimento) {
            return ContextoEstabelecimento.isSecretaria() ?
                'calendario/secretaria/calendario-secretaria.html' :
                'calendario/estabelecimento/calendario-estabelecimento.html';
        }
    }
})();

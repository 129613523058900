(function() {

    'use strict';

    angular.module('educacao.context')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('selecao', {
                url: '/selecao',
                views: {
                    'header': {
                        templateUrl: 'common/layout/header/header.html'
                    }
                }
            })

        .state('selecao.entidade', {
            url: '/entidades',
            views: {
                '@': {
                    templateUrl: 'context/entidades/selecao-entidade.html'
                }
            }
        })

        .state('selecao.estabelecimento', {
            url: '/estabelecimentos',
            views: {
                '@': {
                    templateUrl: 'context/estabelecimento/selecao-estabelecimento.html'
                }
            },
            resolve: {}
        })

        .state('selecao.anoletivo', {
            url: '/anos-letivos',
            views: {
                '@': {
                    templateUrl: 'context/ano-letivo/selecao-ano-letivo.html'
                }
            }
        });
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MatriculaCadCtrl', Controller);

    Controller.$inject = [
        '$q',
        '$scope',
        'educacao.common.$commons',
        '$modalInstance',
        'TIPOS_MATRICULAS',
        'educacao.matricula.MatriculaService',
        'bfc.$$PermissionsService',
        'ed-components.ed-emissao-relatorio.service',
        'educacao.common.ModalConfirm'
    ];

    function Controller($q, $scope, $commons, $modalInstance, TIPOS_MATRICULAS, MatriculaService, PermissionsService, RelatorioService, modalConfirmService) {

        var vm = this;

        var STATUS_ALERTS = 429;

        vm.labelBotaoSalvar = ' Matricular';
        vm.tracker = {
            save: $commons.$promiseTracker()
        };
        vm.enumTurno = MatriculaService.getEnum('Turno');
        MatriculaService.getEnum('SituacaoMatricula').then(function (SituacaoMatricula) {
            vm.SituacaoMatricula = SituacaoMatricula;
        });

        vm.tabs = [{
            id: 1,
            title: 'Selecionar aluno',
            active: true
        }, {
            id: 2,
            title: 'Matricular',
            active: false
        }];

        var PERMITE_ALTERECAO_APOS_MOVIMENTACAO = [
            'TRANSFERIDO',
            'RECLASSIFICADO_AVANCO',
            'RECLASSIFICADO_ACELERACAO',
            'REMANEJADO'
        ];

        vm.changeTabs = changeTabs;
        vm.habilitaBotaoAvancar = habilitaBotaoAvancar;
        vm.salvaMatricula = salvaMatricula;
        vm.excluirMatricula = excluirMatricula;
        vm.informaFilhos = informaFilhos;
        vm.disableSalvarAtividade = disableSalvarAtividade;
        vm.disableButtonSalvarForm = disableButtonSalvarForm;
        vm.windowTitle = vm.edit ? 'Editando matrícula' : 'Adicionando matrícula';

        activate();

        function activate() {
            var matricula = $modalInstance.params.matricula;
            vm.edit = _.get(matricula, 'id');

            if (vm.edit) {
                vm.matricula = matricula;
                vm.labelBotaoSalvar = ' Salvar';
                vm.windowTitle = 'Editando matrícula';
                return;
            }

            vm.matricula = matricula ? matricula : {
                turma: {}
            };
            if (_.get($modalInstance.params, '$$origemInscrito')) {
                parametrizaInformacoesOrigemInscricao();
            }
        }

        function parametrizaInformacoesOrigemInscricao() {
            vm.alunos = _.get($modalInstance.params, '$$alunos') || [];
            vm.matricula.aluno = vm.alunos.length === 1 ? vm.alunos[0] : '';
            vm.matricula.$$alunos = vm.alunos;
            vm.matricula.$$alunoInscricao = $modalInstance.params.alunoInscricao;
            vm.matricula.$$turnos = _.get($modalInstance.params, '$$turnos') || [];
            vm.matricula.$$nivelEscolar = _.get($modalInstance.params, '$$nivelEscolar') || [];
            if (!vm.alunos.length) {
                openModalAluno($modalInstance.params.alunoInscricao);
            }
        }

        function openModalAluno(aluno) {
            if (!!PermissionsService.isRevokedOperation('AlunoPageMapping', 'criar')) {
                return;
            }
            $commons.$modal.open({
                templateUrl: 'planejamento/alunos/cadastro-aluno.html',
                controller: 'educacao.pessoas.AlunoCadCtrl as vm',
                size: 'xl',
                params: {
                    alunoInscricao: aluno,
                    canSaveAndAdd: false,
                    bloqueioAlteracoes: true
                }
            }).result.then(
                function (retorno) {
                    vm.matricula.aluno = retorno;
                }
            );
        }

        function changeTabs(tabSelected) {
            _.forEach(vm.tabs, function (tab) {
                tab.active = (tab.id === tabSelected);
            });
            if (_.get(vm.matricula, '$$matriculaOrigemInscricao')) {
                setValoresDadosInscricao();
            }
        }

        function setValoresDadosInscricao() {
            var matricula = $modalInstance.params.matricula.$$matriculaOrigemInscricao;
            if (matricula) {
                angular.extend(vm.matricula, matricula);
            }
        }

        function habilitaBotaoAvancar() {
            return _.first(vm.tabs).active && vm.matricula.aluno;
        }

        function mergeFilhos() {
            if (!vm.edit) {
                vm.matricula.filhos = [];
            }

            if (!isVagaEtapa() && vm.edit && !_.isEmpty(vm.matricula.filhos) && _.isArray(vm.matricula.$$listaFilhos)) {
                vm.matricula.filhos = _.filter(vm.matricula.filhos, function (item) {
                    return _.find(vm.matricula.$$listaFilhos, 'id', item.atividade.id);
                });
            }

            if (_.isArray(vm.matricula.$$listaFilhos)) {
                _.forEach(vm.matricula.$$listaFilhos, function (data) {
                    configureFilhos(data);
                });
            } else {
                configureFilhos(vm.matricula.$$listaFilhos);
            }
        }

        function configureFilhos(data) {
            if (isVagaEtapa()) {
                if (vm.matricula.turma.tipoResultado === 'AREA_CONHECIMENTO') {
                    if (data.eixoTematico.$$checked) {
                        _.forEach(data.filhos, function (item) {
                            inserirFilho();
                            _.last(vm.matricula.filhos).disciplina = item;
                            adicionaTipoMatriculaFilho();
                        });
                    }
                } else {
                    if (data.disciplina.$$checked) {
                        inserirFilho();
                        _.last(vm.matricula.filhos).disciplina = data;
                        adicionaTipoMatriculaFilho();
                    }
                }
            } else {
                _.forEach(vm.matricula.$$listaTurmaAtividade, function (item) {
                    if ((_.get(data, 'id') || data) === item.atividade.id) {
                        inserirFilho();
                        _.last(vm.matricula.filhos).atividade = item.atividade;
                        adicionaTipoMatriculaFilho();
                    }
                });

            }
        }

        function inserirFilho() {
            vm.matricula.filhos.push(angular.copy(vm.matricula.$$filhosOptions));
        }

        function adicionaTipoMatriculaFilho() {
            _.last(vm.matricula.filhos).tipoMatricula = vm.matricula.tipoMatricula;
        }

        function salvaMatricula() {
            if (isEjaModular()) {
                vm.matricula.type = 'MATRICULA_MODULAR';
            }

            if ((!vm.edit || _.isArray(vm.matricula.$$listaTurmaAtividade)) && informaFilhos()) {
                mergeFilhos();
            }

            restauraValoresListaEspera();

            vm.matricula.turnoFrequenta = _.get(vm.matricula.turnoFrequenta, 'turno', vm.matricula.turnoFrequenta);

            var serviceSave = _.get($modalInstance, 'params.isEncaminhado') ? 'saveEncaminhamento' : 'save';
            vm.tracker.save.addPromise(
                MatriculaService[serviceSave](vm.matricula, isVagaEtapa() || isEja(), isEjaModular())
                    .then(closeModal)
                    .catch(handleError));
        }

        function excluirMatricula(matricula) {
            var hasPermissionToRemoveCascade = !PermissionsService.isRevokedOperation('MatriculaPageMapping', 'excluirVinculos');
            if (hasPermissionToRemoveCascade) {
                openForceRemoveMatricula(matricula);
                return $q.reject();
            }

            if (matricula.origemMatricula === 'RECLASSIFICACAO') {
                modalConfirmService({
                    message: 'Ao excluir este registro, a matrícula de origem voltará para a situação Cursando. Deseja continuar?'
                }).then(function () {
                    MatriculaService.remove(matricula)
                        .then(closeModal);
                });
            } else {
                MatriculaService.remove(matricula)
                    .then(closeModal);
            }
        }

        function openForceRemoveMatricula(matricula) {
            $commons.$modal.open({
                templateUrl: 'matricula/matriculas/remove-matricula-cascade/remove-matricula-cascade.html',
                controller: 'educacao.matricula.RemoveMatriculaCascadeController as vm',
                size: 'sm',
                params: {
                    matricula: matricula
                }
            }).result.then(closeModal);
        }

        function closeModal(matricula) {
            $modalInstance.close();

            if (!vm.matricula.id) {
                RelatorioService
                    .buscar({ filter: 'natureza="COMPROVANTE_MATRICULA"' })
                    .then(function (data) {
                        openModalComprovante(data, matricula);
                    });
            }
        }

        function openModalComprovante(data, matricula) {
            if (_.isEmpty(data.content)) {
                return;
            }
            matricula.filhos = vm.matricula.filhos;
            matricula.turma = vm.matricula.turma;

            $commons.$modal.open({
                templateUrl: 'matricula/matriculas/cadastro/comprovante/modal-comprovante.html',
                controller: 'educacao.matricula.ModalComprovanteCtrl as vm',
                size: 'sm',
                params: {
                    matricula: matricula,
                    isVaga: isVagaEtapa() || isEja(),
                    isEjaModular: isEjaModular(),
                    multiple: data.content.length > 1
                }
            });
        }

        function restauraValoresListaEspera() {
            if (_.get(vm.matricula, '$$matriculaOrigemInscricao')) {
                vm.matricula.idInscrito = vm.matricula.$$matriculaOrigemInscricao.idInscrito;
                vm.matricula.origemInscrito = vm.matricula.$$matriculaOrigemInscricao.origemInscrito;
                vm.matricula.idModalidadeListaEspera = vm.matricula.$$matriculaOrigemInscricao.idModalidadeListaEspera;
            }
        }

        function handleError(error) {
            if (error.status === STATUS_ALERTS) {
                openModalAvisos(error);
            }
        }

        function isVagaEtapa() {
            return TIPOS_MATRICULAS[vm.matricula.tipoMatricula].tipo === 'vaga';
        }

        function isEja() {
            return TIPOS_MATRICULAS[vm.matricula.tipoMatricula].tipo === 'eja' && !isEjaModular();
        }

        function isEjaModular() {
            return _.get(vm.matricula.matriz, 'modular');
        }

        function informaFilhos() {
            return TIPOS_MATRICULAS[vm.matricula.tipoMatricula].informaFilhos;
        }

        function disableSalvarAtividade() {
            return TIPOS_MATRICULAS[vm.matricula.tipoMatricula] &&
                !isVagaEtapa() && !isEja() && !isEjaModular() && _.isEmpty(vm.matricula.$$listaFilhos);
        }

        function disableButtonSalvarForm() {
            if (PERMITE_ALTERECAO_APOS_MOVIMENTACAO.includes(vm.matricula.situacao)) {
                return false;
            }

            return vm.form.$invalid || vm.disableSalvarAtividade();
        }

        function openModalAvisos(error) {
            $commons.$modal.open({
                templateUrl: 'matricula/matriculas/cadastro/avisos/matricula-avisos.html',
                controller: 'educacao.matricula.MatriculaCadAvisosCtrl as vm',
                params: {
                    error: error
                }
            });
        }

        $scope.$on('matricula::salvar', function (event, args) {
            if (args.force) {
                vm.matricula.force = true;
                salvaMatricula();
            }
        });
    }
})();

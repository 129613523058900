(function () {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .controller('educacao.matricula.turma.configuracao-avaliacao.ModalObjetivosAprendizagemController', Controller);

    Controller.$inject = [
        '$modalInstance',
        'promiseTracker',
        'educacao.matriz.ObjetivoAprendizagemService'
    ];

    function Controller($modalInstance, promiseTracker, ObjetivoAprendizagemService) {

        var vm = this;

        vm.componente = $modalInstance.params.componente || {};
        vm.periodos = $modalInstance.params.periodos || {};
        vm.hasExameFinal = $modalInstance.params.hasExameFinal;
        vm.itensAvaliaveisObjetivoAprendizagem = $modalInstance.params.itensAvaliaveisObjetivoAprendizagem;
        vm.adicionarItem = adicionarItem;
        vm.removerItem = removerItem;
        vm.objetivoSelectChange = objetivoSelectChange;
        vm.onClickContinue = onClickContinue;
        vm.onClickAplicar = onClickAplicar;
        vm.aplicarParaTodos = aplicarParaTodos;

        init();

        function init() {
            vm.tracker = {
                getItensObjetivosAprendizagem: promiseTracker()
            };

            vm.componente.itensAvaliaveis = _.filter(vm.componente.itensAvaliaveis, function(item) {
                return item.tipoPeriodo !== 'INSTRUMENTO';
            });

            buscaItensAvaliaveisObjetivoAprendizagem(_.get(vm.componente, 'etapaMatrizDisciplina.id'));
        }

        function buscaItensAvaliaveisObjetivoAprendizagem(idEtapaMatrizDisc) {
            if (!_.isEmpty(vm.itensAvaliaveisObjetivoAprendizagem)) {
                vm.itensObjetivosAprendizagem = configuraObjetivosAprendizagem(vm.itensAvaliaveisObjetivoAprendizagem);

                configureItensPeriodo();
            } else {
                var promise = ObjetivoAprendizagemService.getObjetivosAprendizagemMatriz(idEtapaMatrizDisc).then(function (data) {
                    vm.itensObjetivosAprendizagem = configuraObjetivosAprendizagemMatriz(data);

                    configureItensPeriodo();
                });

                vm.tracker.getItensObjetivosAprendizagem.addPromise(promise);
            }
        }

        function configureItensPeriodo() {
            if (vm.itensObjetivosAprendizagem) {
                _.forEach(vm.itensObjetivosAprendizagem, function (itemObjetivoAprendizagem) {
                    itemObjetivoAprendizagem.$$periodos = [];
                    var quantidadePeriodos = _.get(vm.periodos, 'length') + (vm.hasExameFinal ? 1 : 0);
                    var qtdItensConfiguracao = getQuantidadeItensConfiguracao(itemObjetivoAprendizagem);
                    var permiteAvaliacao = _.get(_.first(itemObjetivoAprendizagem.itensAvaliaveis), 'permiteAvaliacao'); 

                    if (quantidadePeriodos === qtdItensConfiguracao) {
                        itemObjetivoAprendizagem.$$periodos.push(createItemTodos(permiteAvaliacao));
                    } else {
                        _.forEach(itemObjetivoAprendizagem.itensAvaliaveis, function(item) {
                            itemObjetivoAprendizagem.$$periodos.push(createItemPeriodo(item, (item.tipoPeriodo === 'EXAME_FINAL')));
                        });
                    }

                    if (!_.first(itemObjetivoAprendizagem.$$periodos)) {
                        itemObjetivoAprendizagem.$$periodos = '';
                    }

                    itemObjetivoAprendizagem.permiteAvaliacao = permiteAvaliacao;
                });
                atualizaObjetivosAprendizagemSelecionados(vm.itensObjetivosAprendizagem);
            }
        }

        function getQuantidadeItensConfiguracao(itemObjetivoAprendizagem) {
            return _.chain(itemObjetivoAprendizagem.itensAvaliaveis)
                .filter(function (item) {
                    return item.tipoPeriodo !== 'INSTRUMENTO';
                })
                .size()
                .value();
        }

        function createItemTodos(permiteAvaliacao) {
            return {
                id: 'TODOS',
                descricao: 'Todos',
                periodoAvaliativo: null,
                permiteAvaliacao: permiteAvaliacao
            };
        }

        function createItemPeriodo(itemPeriodo, isExameFinal) {
            if (itemPeriodo.periodoAvaliativo || isExameFinal) {
                return {
                    periodoAvaliativo: itemPeriodo.periodoAvaliativo,
                    tipoPeriodo: itemPeriodo.tipoPeriodo,
                    id: isExameFinal ? 'EXAME_FINAL' : itemPeriodo.periodoAvaliativo.id,
                    descricao: isExameFinal ? 'Exame Final' : itemPeriodo.periodoAvaliativo.descricao,
                    permiteAvaliacao: itemPeriodo.permiteAvaliacao
                };
            }
            return;
        }

        function adicionarItem(objetivoAprendizagem) {
            objetivoAprendizagem.push({
                itensAvaliaveis: []
            });
        }

        function removerItem(itensObjetivosAprendizagem, index) {
            vm.itensObjetivosAprendizagem.splice(index, 1);

            atualizaObjetivosAprendizagemSelecionados(itensObjetivosAprendizagem);
        }

        function objetivoSelectChange(itensObjetivosAprendizagem, itemObjetivoAprendizagem, objetivoAprendizagem) {
            if (!objetivoAprendizagem) {
                return;
            }

            if (itemObjetivoAprendizagem.itensAvaliaveis) {
                angular.forEach(itemObjetivoAprendizagem.itensAvaliaveis, function (item) {
                    item.objetivoAprendizagem = objetivoAprendizagem;
                });
            }
            atualizaObjetivosAprendizagemSelecionados(itensObjetivosAprendizagem);
        }

        function configuraObjetivosAprendizagemMatriz(lista) {
            return _.chain(lista)
                .map(function (objetivoAprendizagem) {
                    return {
                        objetivoAprendizagem: objetivoAprendizagem,
                        itensAvaliaveis: []
                    };
                })
                .value();
        }

        function configuraObjetivosAprendizagem(lista) {
            var retorno = [];
            
            var listaItens = _.filter(lista, function(item) {
                return item.tipoPeriodo !== 'INSTRUMENTO';
            });

            _.forEach(_.groupBy(listaItens, 'objetivoAprendizagem.id'), function (itemListObjetivoAprendizagem) {
                retorno.push({
                    itensAvaliaveis: itemListObjetivoAprendizagem,
                    objetivoAprendizagem: _.first(itemListObjetivoAprendizagem).objetivoAprendizagem || _.first(itemListObjetivoAprendizagem)
                });
            });

            return retorno;
        }

        function atualizaObjetivosAprendizagemSelecionados(itensObjetivosAprendizagem) {
            vm.listObjetivosAprendizagemExistentes = _.pluck(itensObjetivosAprendizagem, 'objetivoAprendizagem');
        }

        function onClickContinue() {
            vm.componente.$$itensAvaliaveisObjetivoAprendizagem = ajustaPeriodosObjetivosAprendizagem();
            $modalInstance.close();
        }

        function ajustaPeriodosObjetivosAprendizagem() {
            var itensAvaliaveisObjetivoAprendizagem = [];

            angular.forEach(vm.itensObjetivosAprendizagem, function (itemAvalObjetivoAprendizagem) {
                if (_.find(itemAvalObjetivoAprendizagem.$$periodos, 'id', 'TODOS')) {
                    _.forEach(vm.periodos, function (periodo) {
                        itensAvaliaveisObjetivoAprendizagem.push(getItemWithIdIfExists({
                            tipo: 'OBJETIVOS_APRENDIZAGEM',
                            objetivoAprendizagem: itemAvalObjetivoAprendizagem.objetivoAprendizagem,
                            tipoPeriodo: 'MEDIA_PERIODO',
                            periodoAvaliativo: periodo,
                            permiteAvaliacao: itemAvalObjetivoAprendizagem.permiteAvaliacao
                        }));
                    });

                    if (vm.hasExameFinal) {
                        itensAvaliaveisObjetivoAprendizagem.push(getItemWithIdIfExists({
                            tipo: 'OBJETIVOS_APRENDIZAGEM',
                            objetivoAprendizagem: itemAvalObjetivoAprendizagem.objetivoAprendizagem,
                            tipoPeriodo: 'EXAME_FINAL',
                            periodoAvaliativo: null,
                            permiteAvaliacao: itemAvalObjetivoAprendizagem.permiteAvaliacao
                        }));
                    }
                } else {
                    _.forEach(itemAvalObjetivoAprendizagem.$$periodos, function (periodo) {
                        if (!periodo && periodo.tipoPeriodo !== 'INSTRUMENTO') {
                            return;
                        }
                        itensAvaliaveisObjetivoAprendizagem.push(getItemWithIdIfExists({
                            tipo: 'OBJETIVOS_APRENDIZAGEM',
                            objetivoAprendizagem: itemAvalObjetivoAprendizagem.objetivoAprendizagem,
                            tipoPeriodo: periodo.tipoPeriodo,
                            periodoAvaliativo: periodo.periodoAvaliativo,
                            permiteAvaliacao: itemAvalObjetivoAprendizagem.permiteAvaliacao
                        }));
                    });
                }
            });

            return itensAvaliaveisObjetivoAprendizagem;
        }

        function getItemWithIdIfExists(objetoItemAvalObjetivo) {
            var objetivoAprendizagemExistente = _.find(vm.componente.itensAvaliaveis, function (itemAvaliavel) {
                return _.get(objetoItemAvalObjetivo.periodoAvaliativo, 'id') === _.get(itemAvaliavel.periodoAvaliativo, 'id') &&
                    _.get(objetoItemAvalObjetivo.objetivoAprendizagem, 'id') === _.get(itemAvaliavel.objetivoAprendizagem, 'id');
            });

            if (objetivoAprendizagemExistente) {
                objetoItemAvalObjetivo.id = objetivoAprendizagemExistente.id;
            }

            return objetoItemAvalObjetivo;
        }

        function onClickAplicar() {
            _.forEach(vm.itensObjetivosAprendizagem, setPeriodo);
        }

        function setPeriodo(item) {
            item.itensAvaliaveis = _.map(vm.aplicarTodosPeriodo, _.bind(createItensAvaliaveis, null, item));
            item.$$periodos = vm.aplicarTodosPeriodo;
        }

        function createItensAvaliaveis(objetivoAprendizagem, periodo) {
            return {
                objetivoAprendizagem: objetivoAprendizagem.objetivoAprendizagem,
                periodoAvaliativo: periodo.periodoAvaliativo,
                tipo: 'OBJETIVOS_APRENDIZAGEM',
                tipoPeriodo: periodo.tipoPeriodo,
                permiteAvaliacao: objetivoAprendizagem.permiteAvaliacao,
            };
        }

        function aplicarParaTodos() {
            if (!vm.itensObjetivosAprendizagem) {
                return;
            }

            _.forEach(vm.itensObjetivosAprendizagem, function(item) {
                item.permiteAvaliacao = vm.aplicarAvaliado;
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.TipoDocumentoSelect', TipoDocumentoSelect);

    TipoDocumentoSelect.$inject = ['$injector'];

    function TipoDocumentoSelect($injector) {

        var Service = $injector.get('educacao.matricula.TipoDocumentoService'),
            CommonSelectWithService = $injector.get('educacao.common.CommonSelectWithService'),
            ModalCad = $injector.get('educacao.common.ModalCad'),
            ObjectUtilsService = $injector.get('educacao.common.ObjectUtilsService');

        return {
            select2Config: function (documentos) {
                return CommonSelectWithService.select2Config(Service, 'descricao', {
                    onAdd: function (val, target) {
                        ModalCad.open({
                            templateUrl: 'matricula/tipo-documento/tipo-documento-cad.html',
                            controller: 'educacao.matricula.TipoDocumentoCadCtrl as vm',
                            optionsCad: {
                                'descricao': val
                            },
                            selectElem: target
                        });
                        return false;
                    },
                    result: function (params, callback) {
                        var term = ObjectUtilsService.normalizeFilter(params.term);
                        term = encodeURI(term);

                        var result,
                            filter = '( descricao like "' + term + '")';

                        if (angular.isArray(documentos) && documentos.length > 0) {
                            _.forEach(documentos, function (data) {
                                if (data.tipoDocumento) {
                                    filter += ' and (id not in (' + data.tipoDocumento.id + '))';
                                }
                            });
                        }

                        result = Service.list(filter, params.offset, params.limit);

                        result.then(function (data) {
                            callback({
                                results: data.content,
                                more: data.hasNext
                            });
                        });

                    }
                });
            }
        };

    }
})();

(function () {
    'use strict';

    angular.module('educacao.matriz').directive('appSelectQuadroVagas', SelectQuadroVagas);

    function SelectQuadroVagas() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ngclass="ngclass" ng-change="ngChange()" ng-required="ngRequired" search="vm.searchVaga" format-result="vm.formatResult(data)" ng-disabled="ngDisabled"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                ngDisabled: '=?',
                ngChange: '&?',
                etapaMatriz: '=?',
                estabelecimento: '=?',
                formatResult: '=?',
                anoLetivo: '=?',
                ngclass: '=',
                filtroTurnos: '=',
                comVagas:'=',
                multiturno: '='
            },
            controller: SelectQuadroVagasDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectQuadroVagasDirectiveController.$inject = ['$scope', 'educacao.matricula.QuadroVagasService'];

    function SelectQuadroVagasDirectiveController($scope, service) {

        var vm = this;

        var templateBuscaVaga = _.template('etapaMatriz =<%= etapaMatriz %> and estabelecimento=<%= estabelecimento %>');

        vm.searchVaga = searchVaga;
        vm.formatResult = formatResult;

    
        function formatResult(vaga) {

            if($scope.multiturno && vaga.turno === 'INTEGRAL'){

                return _.capitalize((vaga.turno || '').toLowerCase());

            }

            return $scope.formatResult ? $scope.formatResult(vaga) : _.capitalize((vaga.turno || '').toLowerCase());
        }

        function searchVaga(params) {

            var idEtapa = _.get($scope.etapaMatriz, 'id') || -1;
            var idEstabelecimento = _.get($scope.estabelecimento, 'id') || -1;
            var filter = templateBuscaVaga({
                etapaMatriz: idEtapa,
                estabelecimento: idEstabelecimento
            });
            if($scope.comVagas){
                return service.getQuadroDeVagas(params, filter).then(setfiltro);
            }else{
                return service.getQuadroDeVagasComVaga(params, filter, $scope.anoLetivo).then(setfiltro);
            }

            function setfiltro(data){
                var dados = _.first(data);
                
                var term = decodeURI(params.term).replace(/%/g, '');
    
                var filtrados = _.filter(dados.content, function (item) {
                    return item.turno.toLowerCase().contains(term.toLowerCase()) && filterTurno(item.turno);
                });
                dados.content = filtrados;
    
                return dados;
            }
        }



        function filterTurno(turno) {
            if (!_.get($scope.filtroTurnos, 'length')) {
                return true;
            }

            var filtro =  $scope.filtroTurnos.filter(function (turnoFilter) {
                return turnoFilter === turno;
            });
            return filtro.length ? true : false; 
        }
    }
})();


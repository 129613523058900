(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.ReligiaoService', ReligiaoService);

    ReligiaoService.$inject = ['GenericService', 'Restangular'];

    function ReligiaoService(GenericService, Restangular) {

        function getById(id) {
            return Restangular.one('pessoa').one('religiao', id);
        }

        return GenericService
            .createWithCustomMethods({
                getById: getById,
            })
            .module({
                uri: 'pessoa'
            })
            .service({
                uri: 'religiao'
            });
    }
})();

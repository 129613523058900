(function () {
    'use strict';

    angular.module('educacao.funcionario')
        .service('educacao.funcionario.$funcionario', MovimentacaoFuncionarioService);

    MovimentacaoFuncionarioService.$inject = ['$injector'];
    function MovimentacaoFuncionarioService($injector) {
        return {
            $movimentacoesFuncionario: $injector.get('educacao.funcionario.FuncionarioMatriculaMovimentacoesService')
        };
    }
})();
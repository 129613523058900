(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('dadosAulasGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/dados-aulas-gestao-pessoas.html',
            scope: {},
            bindToController: {
                anoLetivoSelected: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.quadro-informativo-utils.CardTotalizadoresQuadroInformativoBuilder',
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        '$scope',
        'ui.components.Modal',
        '$q',
        'promiseTracker',
        '$timeout',
        '$stateParams',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        '$rootScope'
    ];

    function Controller(CardTotalizadoresQuadroInformativoBuilder, Service, $scope, uiModal, $q, promiseTracker, $timeout, $stateParams, ContextoEstabelecimento, ContextoAnoLetivo, $rootScope) {
        var vm = this;

        vm.Service = Service;
        vm.firstRow = [];
        vm.saldoAulasSemanais = [];
        vm.duracoesSelecionadas = [];
        vm.advancedFilter = [];
        vm.maiorHeightCards = null;
        vm.filter = null;
        vm.filtrarComponenteCurricular = null;
        vm.filtrarEstabelecimento = null;
        vm.filterDisponibilidadeAula = null;
        vm.search = false;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();

        var saldoAulasSemanais = null;
        var saldoAulasOcupadasSemanais = null;
        var MENSSAGEM_SALDO_VAZIO = 'Não há aulas confirmadas para exibir o tempo.';

        vm.loadingTracker = {
            firstRow: promiseTracker(),
        };

        vm.limparFiltros = limparFiltros;
        vm.isNotEmptyFilter = isNotEmptyFilter;
        vm.mountFilter = mountFilter;
        vm.applySearch = applySearch;
        vm.refresh = refresh;

        vm.CARDS_ORDER = {
            FIRST: 1,
            SECOND: 2,
            THIRD: 3
        };
        vm.BIG_CARD = {
            TRUE: true
        };

        vm.HAS_BADGE_CARD = {
            TRUE: true
        };

        vm.REQUIRED_CARD = {
            TRUE: true
        };

        vm.CARDS_NAMES = {
            AULAS_SEMANAIS: {
                titulo: 'Total de aulas semanais',
                tituloBadge: 'Total de aulas semanais por tempo'
            },
            AULAS_SEMANAIS_OCUPADAS: {
                titulo: 'Aulas semanais ocupadas',
                tituloBadge: 'Total de aulas semanais ocupadas por tempo'
            },
            AULAS_SEMANAIS_VAGAS: {
                titulo: 'Aulas semanais vagas',
                tituloBadge: 'Total de aulas semanais vagas por tempo'
            },

        };
        vm.CARDS_ROW = {
            FIRST_ROW: 1,
            SECOND_ROW: 2
        };

        vm.DISPONIBILIDADES_AULAS = [
            {key: 'TODAS', descricao: 'Todas', filter: '(aulasVagas >= 0 and aulas >= 0)'},
            {key: 'VAGAS', descricao: 'Vagas', filter: '(aulasVagas > 0 and aulas > 0)'},
            {key: 'OCUPADAS', descricao: 'Ocupadas', filter: '(aulasVagas = 0 and aulas > 0)'}
        ];


        $rootScope.$on('app-context:anoletivo:new', clearAnoLetivo);
        $scope.$watchGroup(['vm.estabelecimentoSelected', 'vm.anoLetivoSelected'], onChangeContext);
        $scope.$watchGroup(['vm.filtrarComponenteCurricular', 'vm.filtrarEstabelecimento', 'vm.filterDisponibilidadeAula'], reloadClearFilter);

        init();

        function refresh() {
            init();
            mountFilter();
            vm.search = !vm.search;
        }


        function init() {
            var params = resolveParams();
            loadFirstRow(params);
        }

        function getMaiorAlturaDosCards() {
            var alturasCards = [];
            $timeout(function () {
                _.forEach(vm.firstRow, function (card, index) {
                    var element = document.getElementById('card_' + index);
                    var clientHeight = element.clientHeight;
                    alturasCards.push(clientHeight);
                });
                vm.maiorHeightCards = _.max(alturasCards);
            });
        }

        function onChangeContext(newValue) {
            if (newValue !== null || (!_.isEmpty(vm.firstRow))) {
                if (newValue === null) {
                    vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
                }
                init();
            }
        }

        function isNotEmptyFilter() {
            return !_.isEmpty(vm.filtrarComponenteCurricular) ||
                !_.isEmpty(vm.filtrarEstabelecimento) ||
                !_.isEmpty(vm.filterDisponibilidadeAula) ||
                !_.isEmpty(vm.advancedFilter);


        }


        function limparFiltros() {
            vm.filtrarComponenteCurricular = null;
            vm.filtrarEstabelecimento = null;
            vm.filterDisponibilidadeAula = null;
            vm.advancedFilter = null;
        }


        function clearAnoLetivo() {
            $stateParams.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
        }

        function reloadClearFilter(newValue) {
            if (newValue === null || _.isEmpty(newValue)) {
                applySearch();
            }
        }

        function loadFirstRow(contextParams) {
            var firstRowPromise = $q.all([
                Service.getTotalAulasSemanais(contextParams).then(atualizadSaldoAulasSemanais),
                Service.getTotalAulasOcupadasSemanais(contextParams).then(atualizaSaldoAulasOcupadasSemanais),
            ]).then(builderCards);
            vm.loadingTracker.firstRow.addPromise(firstRowPromise);
        }

        function builderCards() {
            vm.firstRow = [];
            vm.firstRow.push(
                CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.AULAS_SEMANAIS.titulo)
                    .withSaldo(_.sum(saldoAulasSemanais, 'qtdTotalAulas'))
                    .withOrder(vm.CARDS_ORDER.FIRST)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeAulasPorDuracao(saldoAulasSemanais))
                    .withTituloBadge(vm.CARDS_NAMES.AULAS_SEMANAIS.tituloBadge)
                    .withShowMessageEmptySaldo(vm.REQUIRED_CARD.TRUE)
                    .withMessageEmptySaldo(MENSSAGEM_SALDO_VAZIO)
                    .build()
            );
            vm.firstRow.push(
                CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.AULAS_SEMANAIS_OCUPADAS.titulo)
                    .withSaldo(saldoAulasOcupadasSemanais.total)
                    .withOrder(vm.CARDS_ORDER.SECOND)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeAulasPorDuracao(saldoAulasOcupadasSemanais.aulasPorDuracao))
                    .withTituloBadge(vm.CARDS_NAMES.AULAS_SEMANAIS_OCUPADAS.tituloBadge)
                    .withIsRequiredTitle(vm.REQUIRED_CARD.TRUE)
                    .withShowMessageEmptySaldo(vm.REQUIRED_CARD.TRUE)
                    .withShowMessageEmptySaldo(vm.REQUIRED_CARD.TRUE)
                    .withMessageEmptySaldo(MENSSAGEM_SALDO_VAZIO)
                    .build()
            );
            vm.firstRow.push(
                CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.AULAS_SEMANAIS_VAGAS.titulo)
                    .withSaldo(calculaTotalVagasAulasSemanais(saldoAulasSemanais, saldoAulasOcupadasSemanais))
                    .withOrder(vm.CARDS_ORDER.THIRD)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeAulasVagasPorDuracao(saldoAulasSemanais, saldoAulasOcupadasSemanais.aulasPorDuracao))
                    .withTituloBadge(vm.CARDS_NAMES.AULAS_SEMANAIS_VAGAS.tituloBadge)
                    .withIsRequiredTitle(vm.REQUIRED_CARD.TRUE)
                    .withShowMessageEmptySaldo(vm.REQUIRED_CARD.TRUE)
                    .withShowMessageEmptySaldo(vm.REQUIRED_CARD.TRUE)
                    .withMessageEmptySaldo(MENSSAGEM_SALDO_VAZIO)
                    .build()
            );
            getMaiorAlturaDosCards(vm.firstRow, vm.CARDS_ROW.FIRST_ROW);
        }

        function atualizadSaldoAulasSemanais(data) {
            saldoAulasSemanais = data;
        }

        function atualizaSaldoAulasOcupadasSemanais(data) {
            saldoAulasOcupadasSemanais = data;
        }


        function mountBadgeAulasPorDuracao(saldoSemanalAulas) {
            var badgeds = [];
            _.map(saldoSemanalAulas, function (saldoSemanalAula) {
                if (_.get(saldoSemanalAula, 'qtdTotalAulas') !== 0) {
                    badgeds.push({descricao: _.get(saldoSemanalAula, 'qtdTotalAulas') + ' x ' + _.get(saldoSemanalAula, 'tempoDuracaoAulas') + 'min'});
                }
            });
            return badgeds;

        }

        function calculaTotalVagasAulasSemanais(saldoTotalAulasSemanais, saldoTotalAulasOcupadasSemanais) {
            var totalAulasSemanais = _.sum(saldoTotalAulasSemanais, 'qtdTotalAulas');
            var totalAulasOcupadasSemanais = saldoTotalAulasOcupadasSemanais.total;

            var saldo = totalAulasSemanais - totalAulasOcupadasSemanais;

            if (saldo < 0) {
                return 0;
            }

            return saldo;
        }

        function mountBadgeAulasVagasPorDuracao(saldoTotalAulasSemanais, saldoTotalAulasOcupadasSemanais) {
            var badges = [];
            var saldoTotalAulasVagasSemanais = {};

            var saldoTotalAulasVagas = saldoTotalAulasSemanais.concat(saldoTotalAulasOcupadasSemanais);

            saldoTotalAulasVagas.forEach(function (saldo) {
                var quantidade = _.get(saldo, 'qtdTotalAulas');
                var duracao = _.get(saldo, 'tempoDuracaoAulas');

                if (saldoTotalAulasVagasSemanais[duracao]) {
                    saldoTotalAulasVagasSemanais[duracao] -= quantidade;
                } else {
                    saldoTotalAulasVagasSemanais[duracao] = quantidade;
                }
            });

            Object.keys(saldoTotalAulasVagasSemanais).map(function (duracao) {
                if (saldoTotalAulasVagasSemanais[duracao] > 0) {
                    badges.push({descricao: saldoTotalAulasVagasSemanais[duracao] + ' x ' + duracao + 'min'});
                }
            });


            return badges;
        }

        function resolveParams() {
            return {
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id'),
                estabelecimentoId: vm.isSecretaria ? _.get(vm.estabelecimentoSelected, 'id') : _.get(ContextoEstabelecimento.getEstabelecimento(), 'id')
            };
        }

        function mountFilter() {
            var filters = [];

            if (!_.isEmpty(vm.filtrarComponenteCurricular)) {
                filters.push('idComponenteCurricular in (' + _.map(vm.filtrarComponenteCurricular, 'componenteCurricularId').join(',') + ')');
            }

            if (!_.isEmpty(vm.filtrarEstabelecimento)) {
                filters.push('idEstabelecimento in (' + _.map(vm.filtrarEstabelecimento, 'id').join(',') + ')');
            }

            if (vm.filterDisponibilidadeAula) {
                filters.push(applyFiltersDisponibilidade(vm.filterDisponibilidadeAula));
            }

            if (!_.isEmpty(vm.advancedFilter)) {
                filters.push(vm.advancedFilter.join(' and '));
            }

            vm.filter = filters.join(' and ');
        }

        function applyFiltersDisponibilidade(option) {
            var find = _.find(vm.DISPONIBILIDADES_AULAS, {key: option.key});
            return find.filter;
        }

        function applySearch() {
            vm.mountFilter();
            vm.search = !vm.search;
        }
    }
})();
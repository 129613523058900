(function () {

    'use strict';

    angular.module('educacao.matricula').directive('appSelectMatriculaDispensavel', SelectMatriculaDispensavelDirective);
    SelectMatriculaDispensavelDirective.$inject = [];
    function SelectMatriculaDispensavelDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" format-result="vm.formatResult(data)" search="vm.search" ng-disabled="ngDisabled" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                formatResult: '=?',
                ngDisabled: '=?'
            },
            controller: SelectMatriculaDispensavelDirectiveCtrl,
            controllerAs: 'vm'
        };
    }

    SelectMatriculaDispensavelDirectiveCtrl.$inject = ['$scope', 'educacao.matricula.MatriculaVagaService'];
    function SelectMatriculaDispensavelDirectiveCtrl($scope, MatriculaVagaEtapaService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            return MatriculaVagaEtapaService
                .list('(aluno.nome like "' + params.term + '")', params.offset, params.limit)
                .then(function (list) {
                    return {
                        content: list,
                        hasNext: false
                    };
                });
        }

        function formatResult(data) {
            if ($scope.formatResult) {
                return $scope.formatResult(data);
            }
            return data.descricao || data.nome;
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matriz').config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.cha';
        $stateProvider.state(STATE, {
            url: 'cha',
            data: {
                id: 'ChaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'cadastro-cha/cha.html',
                    controller: 'educacao.matriz.ChaCtrl',
                    controllerAs: 'vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.matriz.chaService',
            '$modalStates'
        ];

        function openModal($stateParams, chaService, $modalStates) {
            $modalStates.open({
                getMethod: chaService.get,
                templateUrl: 'cadastro-cha/cha-cad.html',
                controller: 'educacao.matriz.ChaCadCtrl as vm',
                propertyName: 'cha',
                stateParams: $stateParams
            });
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.common')
		.filter('lowercase', filter);

	function filter() {
		return function(input) {
			input = input || '';

			return input.toLowerCase();
		};
	}
})();


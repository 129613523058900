(function () {
    'use strict';

    angular.module('educacao.movimentoEscolar')
    	.factory('educacao.movimentoEscolar.MovimentoEscolarService', MovimentoEscolarService);

    MovimentoEscolarService.$inject = ['ServiceBuilder'];

    function MovimentoEscolarService(ServiceBuilder){

        var self = ServiceBuilder
            .create()
            .path('matricula', 'estatisticas')
            .enum('SituacaoMatricula')
            .enum('EquivalenciaEtapa')
            .build();

        self.getDadosGridMatriculas = getDadosGridMatriculas;
        self.getDadosDetalhesMatriculas = getDadosDetalhesMatriculas;

        return self;

        function getDadosGridMatriculas(filtro){
            return self.getEndpoint().post(filtro);
        }


        function getDadosDetalhesMatriculas(filters, tipo){
            return self.getEndpoint()
                    .all('detalhes' + tipo)
                    .post(filters);
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.pessoas')
        .factory('educacao.pessoas.AlunoImportacaoService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {
        var service = ServiceBuilder.create()
            .path('importacao', 'aluno')
            .build();


        return service;
    }
})();

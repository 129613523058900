(function() {
    'use strict';

    angular.module('educacao.matriz')
        .factory('educacao.matriz.ObjetoConhecimentoService', Service);

    Service.$inject = ['$injector'];

    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var service = ServiceBuilder.create()
            .path('objeto-conhecimento')
            .build();

        return service;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.diarioclasse')
        .controller('educacao.diarioclasse.DiarioClasseImpressaoCtrl', DiarioClasseImpressaoCtrl);

    DiarioClasseImpressaoCtrl.$inject = [
        'educacao.common.$commons',
        '$modalInstance',
        'educacao.entidade.EntidadeService',
        'educacao.context.ContextoAnoLetivo',
        'educacao.diarioClasse.ImpressaoDiarioClasseService'
    ];

    function DiarioClasseImpressaoCtrl($commons, $modalInstance, EntidadeService, ContextoAnoLetivo, ImpressaoDiarioClasseService) {
        var vm = this;

        vm.diario = $modalInstance.params.diario;
        vm.alunosFiltrados = $modalInstance.params.alunosFiltrados;
        vm.estabelecimento = $modalInstance.params.estabelecimento;
        vm.turma = $modalInstance.params.turma;
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.dataEmissao = moment().local().format('DD/MM/YYYY');
        vm.getPeriodoAvaliativo = getPeriodoAvaliativo;
        vm.getDataInicialPeriodo = getDataInicialPeriodo;
        vm.getDataFinalPeriodo = getDataFinalPeriodo;
        vm.predicate = $modalInstance.params.predicate;
        vm.reverse = $modalInstance.params.reverse;
        vm.print = print;
        vm.periodoAvaliativoCorrente = $modalInstance.params.periodoAtual;

        getEntidade();
        geradorDeColunasDias();
        
        function getEntidade() {
            EntidadeService.get('').then(getEntidadeGlb);
        }

        function getEntidadeGlb(dados) {
            vm.entidade = dados;                
            EntidadeService.getEntidadeSuite().then(setMunicipio);
        }
        
        function setMunicipio(entidadeSuite) {
            vm.entidade.municipio = entidadeSuite.data.municipio;
        }

        function geradorDeColunasDias() {
            vm.columnDias = [];
            for (var i = 0; i < 30; i++) {
                vm.columnDias.push({
                    id: i
                });
            }
        }

        function getPeriodoAvaliativo(item) {
            if (vm.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO') {
                return 'Período avaliativo: ' + item.descricao + ' - ' + vm.anoLetivo;
            } else if (vm.diario.tipoItemEducacional === 'ATIVIDADE') {
                return 'Período: ' + vm.anoLetivo;
            } else {
                return vm.periodoAvaliativoCorrente ? 'Período avaliativo: ' + vm.periodoAvaliativoCorrente
                    .descricao : '';
            }
        }

        function getDataInicialPeriodo(item) {
            if (vm.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO' || vm.diario.tipoItemEducacional === 'ATIVIDADE') {
                return item.dataInicial;
            } else {
                return vm.periodoAvaliativoCorrente ? vm.periodoAvaliativoCorrente.dataInicial :
                    '';
            }
        }

        function getDataFinalPeriodo(item) {
            if (vm.diario.tipoItemEducacional === 'PERIODO_AVALIATIVO' || vm.diario.tipoItemEducacional === 'ATIVIDADE') {
                return item.dataFinal;
            } else {
                return vm.periodoAvaliativoCorrente ? vm.periodoAvaliativoCorrente.dataFinal :
                    '';
            }
        }

        function print() {
            ImpressaoDiarioClasseService.imprimir('#formularioDiarioClasseImpressao')
                .then(function () {
                    $modalInstance.close();
                });
        }
    }
})();

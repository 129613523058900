(function() {
    'use strict';

    var DATE_FORMAT_MONTH = 'YYYY-MM';

    var defaultRenderConfig = {
        showYearSeparator: true, //Mostra faixa separadora de anos
        showLegend: true, //Mostra legenda do calendario
        showMonthWorkdayCount: true, //Mostrar o total de dias letivos de um mes
        showTotalWorkdayCount: true, //Mostra o total de dias letivos do calendario mostrado
        showHolydaysControls: true, //Mostra as opções para editar/deletar feriados
        showEventsControls: true, //Mostra as opções para editar/deletar eventos

        monthPerRow: 4,

        restrictInitialDate: undefined, //Restringe a exibição do calendario a essa data inicial
        restrictFinalDate: undefined, //Restringe a exibição do calendario a essa data final
        hideRestrictedDate: false, //Exibe apenas os dias que estão dentro de restrictInitialDate e restrictFinalDate
    };

    angular.module('educacao.calendario')
        .directive('calendarYearView', CalendarGridDirective);

    function CalendarGridDirective() {
        return {
            restrict: 'E',
            templateUrl: 'calendario/view-year/year-view.directive.html',

            scope: {
                calendarioDisplay: '=',
                renderConfig: '=',

                onWorkdaysUpdated: '&'
            },

            link: postLink,

            controller: CalendarControler,
            controllerAs: 'vm'
        };
    }

    function postLink(scope, element, attr, vm) {
        vm.container = element.find('.months-container');
    }

    CalendarControler.$inject = ['$scope', '$compile', '$log', 'ColorHelper'];

    function CalendarControler($scope, $compile, $log, ColorHelper) {
        var vm = this;

        vm.months = {};
        vm.workdayCount = 0;

        vm.statistics = {};

        vm.getTextColorClass = ColorHelper.getTextColorClass;

        var actualState = {};
        var actualConfig = {};
        var currentDates = {};

        $scope.$watch('renderConfig', recreateMonths, true);

        $scope.$watch('calendarioDisplay.processCount', function() {
            recreateMonths();
            updateDates();
        });

        function recreateMonths() {
            if (isSameState() && isSameConfig()) {
                return;
            }

            destroyOldCalendars();

            if (!isValidState()) {
                return;
            }

            vm.config = angular.extend({}, defaultRenderConfig, $scope.renderConfig || {});

            createMonthsInformation();
            renderCalendars();
            updateStatistics();
        }

        function isSameState() {
            var newState = createState();
            if (angular.equals(actualState, newState)) {
                return true;
            }
            actualState = newState;
            return false;
        }

        function isSameConfig() {
            var newState = createConfig();
            if (angular.equals(actualConfig, newState)) {
                return true;
            }
            actualConfig = newState;
            return false;
        }

        function createState() {
            return {
                dataInicial: $scope.calendarioDisplay.dataInicial,
                dataFinal: $scope.calendarioDisplay.dataFinal,
                diasLetivosSemana: $scope.calendarioDisplay.diasLetivosSemana
            };
        }

        function createConfig() {
            return {
                restrictInitialDate: $scope.renderConfig.restrictInitialDate,
                restrictFinalDate: $scope.renderConfig.restrictFinalDate,
                hideRestrictedDate: $scope.renderConfig.hideRestrictedDate,
            };
        }

        function isValidState() {
            return actualState.dataInicial && actualState.dataFinal;
        }

        function destroyOldCalendars() {
            angular.forEach(vm.months, function(month) {
                vm.workdayCount = Math.max(vm.workdayCount - month.workdays, 0);

                month.element.remove();
                month.$scope.$destroy();
            });
            vm.months = {};
        }

        function createMonthsInformation() {
            var limits = getLimitDates();

            var initialDate = limits.dataInicial;
            var finalDate = limits.dataFinal;

            if (!vm.config.restrictInitialDate && !vm.config.restrictFinalDate) {
                initialDate.startOf('year');
                finalDate.endOf('year');
            }

            var diffMonths = getDiffMonths(initialDate, finalDate);

            var key;
            for (var i = 0; i < diffMonths; i++) {
                key = initialDate.format(DATE_FORMAT_MONTH);

                vm.months[key] = createMonthCalendar(key, initialDate.clone());

                initialDate.add(1, 'months');
                initialDate.startOf('month');
            }

            key = initialDate.format(DATE_FORMAT_MONTH);
            vm.months[key] = createMonthCalendar(key, initialDate.clone());
        }

        function getLimitDates() {
            var initialDate = moment(actualState.dataInicial);
            var finalDate = moment(actualState.dataFinal);

            angular.forEach($scope.calendarioDisplay.eventos, function(event, date) {
                if (initialDate.isAfter(date)) {
                    initialDate = moment(date);
                }

                if (finalDate.isBefore(date)) {
                    finalDate = moment(date);
                }
            });
            angular.forEach($scope.calendarioDisplay.feriados, function(feriado, date) {
                if (initialDate.isAfter(date)) {
                    initialDate = moment(date);
                }
                if (finalDate.isBefore(date)) {
                    finalDate = moment(date);
                }
            });

            if (vm.config.restrictInitialDate) {
                initialDate = moment(vm.config.restrictInitialDate);
            }
            if (vm.config.restrictFinalDate) {
                finalDate = moment(vm.config.restrictFinalDate);
            }

            return {dataInicial: initialDate, dataFinal: finalDate};
        }

        function getDiffMonths(dataInicial, dataFinal) {
            dataInicial = dataInicial
                .clone()
                .startOf('month');

            dataFinal = dataFinal
                .clone()
                .startOf('month');

            return dataFinal.diff(dataInicial, 'months');
        }

        function createMonthCalendar(key, date) {
            var month = {
                key: key,
                year: date.year(),

                date: date,

                workdays: 0,

                element: createMiniMonthCalendarElement()
            };

            month.$scope = $scope.$new();
            month.$scope.month = month;
            month.$scope.calendarioDisplay = $scope.calendarioDisplay;

            month.$scope.restrictInitialDate = vm.config.restrictInitialDate;
            month.$scope.restrictFinalDate = vm.config.restrictFinalDate;
            month.$scope.hideRestrictedDate = vm.config.hideRestrictedDate;

            month.$scope.$watch('month.workdays', recountWorkdays);

            return month;
        }

        function createMiniMonthCalendarElement() {
            return $('<mini-month-calendar>')
                .attr('month', 'month.date')
                .attr('workdays', 'month.workdays')
                .attr('calendario-display', 'calendarioDisplay')
                .attr('restrict-initial-date', 'restrictInitialDate')
                .attr('restrict-final-date', 'restrictFinalDate')
                .attr('hide-restricted-date', 'hideRestrictedDate')

            .attr('show-workdays-count', vm.config.showMonthWorkdayCount.toString())
                .attr('show-holydays-controls', vm.config.showHolydaysControls.toString())
                .attr('show-events-controls', vm.config.showEventsControls.toString());
        }

        function renderCalendars() {
            vm.container.empty();

            var count = 0;
            var year;

            var row;
            var column;

            var columnClassName = getMonthColumnClass();

            angular.forEach(vm.months, function(month) {
                if (vm.config.showYearSeparator && year !== month.year) {
                    year = month.year;
                    count = 0;

                    vm.container.append(createYearSeparator(year));
                }

                if (count++ % vm.config.monthPerRow === 0) {
                    row = $('<div class="row">');
                    vm.container.append(row);
                }

                column = $('<div>')
                    .addClass(columnClassName)
                    .append($compile(month.element)(month.$scope));

                row.append(column);
            });
        }

        function getMonthColumnClass() {
            var colSize = Math.floor(Math.max(1, 12 / vm.config.monthPerRow));
            return 'col-md-' + colSize;
        }

        function createYearSeparator(year) {
            var title = $('<h3></h3>')
                .html(year);

            var column = $('<div class="col-md-12 text-center">')
                .append('<hr>')
                .append(title)
                .append('<hr>');

            return $('<div class="row">')
                .append(column);
        }

        function recountWorkdays() {
            vm.workdayCount = _.sum(vm.months, function(object) {
                return object.workdays;
            });

            ($scope.onWorkdaysUpdated || angular.noop)({
                workdays: vm.workdayCount
            });
        }

        function updateDates() {
            _.forEach(currentDates, function(e, date) {
                reprocessDay(date);
                delete currentDates[date];
            });
            _.forEach($scope.calendarioDisplay.eventos, function(e, date) {
                reprocessDay(date);
                currentDates[date] = true;
            });
            _.forEach($scope.calendarioDisplay.excecoes, function(e, date) {
                reprocessDay(date);
                currentDates[date] = true;
            });
            _.forEach($scope.calendarioDisplay.feriados, function(f, date) {
                reprocessDay(date);
                currentDates[date] = true;
            });
            updateStatistics();
        }

        function reprocessDay(date) {
            var month = vm.months[date.substr(0, 7)];
            if (month) {
                month.$scope.$broadcast('reprocess-day', date);
            }
        }

        function updateStatistics() {
            var calendarioDisplay = $scope.calendarioDisplay;
            vm.statistics = {};
            vm.statistics.months = getDiffMonths(moment(calendarioDisplay.dataInicial), moment(calendarioDisplay.dataFinal)) + 1;

            _.forEach(calendarioDisplay.flatten.feriados, function(feriado) {
                vm.statistics.hasHolyday = vm.statistics.hasHolyday || !feriado.letivo;
                vm.statistics.hasWorkdayHolyday = vm.statistics.hasWorkdayHolyday ||
                        (feriado.letivo && calendarioDisplay.isDiaLetivo(feriado.feriado.dataFeriado));
            });

            _.forEach(calendarioDisplay.eventos, function(eventos) {
                vm.statistics.hasEventsSameDay = eventos.length > 1;
                return !vm.statistics.hasEventsSameDay;
            });

            vm.eventos = _.filter(calendarioDisplay.flatten.eventos, showEvent);
        }

        function showEvent(event) {
            if (!vm.config.hideRestrictedDate) {
                return true;
            }

            if (!vm.config.restrictInitialDate && !vm.config.restrictFinalDate) {
                return true;
            }

            var dataInical = moment(event.dataInicial);
            var dataFinal = moment(event.dataFinal);

            if (vm.config.restrictInitialDate && vm.config.restrictFinalDate) {
                var inicio = moment(vm.config.restrictInitialDate);
                var fim = moment(vm.config.restrictFinalDate);

                return dataInical.isBetween(inicio, fim, 'day', '[]') || dataFinal.isBetween(inicio, fim, 'day', '[]') ||
                    (dataInical.isBefore(inicio) && dataFinal.isAfter(fim));
            }

            if (vm.config.restrictInitialDate) {
                var restrictInitial = moment(vm.config.restrictInitialDate);
                return restrictInitial.isBefore(dataInical) || restrictInitial.isBefore(dataFinal);
            }

            if (vm.config.restrictFinalDate) {
                var restrictFinal = moment(vm.config.restrictFinalDate);
                return restrictFinal.isBefore(dataInical) || restrictFinal.isBefore(dataFinal);
            }

            return true;
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.agendaprofessor')
        .constant('CORES', cores)
        .service('educacao.agendaprofessor.DisponibilidadeConfig', DisponibilidadeConfig);

    var cores = {
        RED: 'red',
        ORANGE: 'orange',
        YELLOW: 'yellow',
        GREENA: 'greenA',
        GREENB: 'greenB',
        BLUEA: 'blueA',
        BLUEB: 'blueB',
        BLUEC: 'blueC',
        PURPLEA: 'purpleA',
        PURPLEB: 'purpleB',
        VIOLETA: 'violetA',
        CRIMSON: 'crimson',
        GRAY: 'gray'
    };

    function DisponibilidadeConfig() {
        return {
            dateFormat: 'YYYY-MM-DD',
            timeFormat: 'HH:mm',
            dateTimeFormat: 'YYYY-MM-DD[T]HH:mm',
            titleFormat: 'DD MMM YYYY',
            hollydayClass: 'bg__gray',
            popoverEventListTemplate: '\'calendario/calendario-evento/calendario-event-list.popover.html\'',
            getColorClass: getColorClass,
            getTextColorClass: getTextColorClass,

            formatEventInterval: formatEventInterval,
            getDayWeekNumber: getDayWeekNumber

        };

        function getColorName(enumCor) {
            return cores[enumCor.split(/\d+/)
                .join('')
                .toUpperCase()];
        }

        function getColorIntensity(enumCor) {
            return /\d+/.exec(enumCor)[0];
        }

        function getColorClass(keyEnumCor, intensity) {
            intensity = intensity || getColorIntensity(keyEnumCor);
            return getColorName(keyEnumCor) + intensity;
        }

        function getTextColorClass(keyEnumCor, intensity) {
            intensity = intensity || getColorIntensity(keyEnumCor);
            return 'text-' + getColorName(keyEnumCor) + intensity;
        }

        function formatEventInterval(evento) {
            var format = moment(evento.dataInicial)
                .format('ddd DD');
            if (evento.horaInicial) {
                format += ', ' + evento.horaInicial;
            }

            var separator = ' - ';

            if (evento.dataFinal && evento.dataFinal !== evento.dataInicial) {
                format += ' até ' + moment(evento.dataFinal)
                    .format('ddd DD');
                separator = ', ';
            }

            if (evento.horaFinal) {
                format += separator + evento.horaFinal;
            }

            return format;
        }

        function getDayWeekNumber(diaSemana) {

            var weekday = new Array(7);
            weekday[0] = 'SUNDAY';
            weekday[1] = 'MONDAY';
            weekday[2] = 'TUESDAY';
            weekday[3] = 'WEDNESDAY';
            weekday[4] = 'THURSDAY';
            weekday[5] = 'FRIDAY';
            weekday[6] = 'SATURDAY';
            return weekday.indexOf(diaSemana);
        }
    }

})();

(function() {

    'use strict';

    angular.module('educacao.convencao')
        .directive('appSelectConvencao', SelectConvencaoDirective);

    function SelectConvencaoDirective() {
        return {
            restrict: 'E',
            templateUrl: 'convencao/app-select-convencao/app-select-convencao-template.html',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                convencoesExistentes: '=?',
                ngChange: '&?'
            },
            controller: SelectConvencaoDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }
                if (angular.isDefined(tAttrs.ngRequired)) {
                    element.children('ui-select').attr('not-empty', 'ngRequired');
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectConvencaoDirectiveController.$inject = [
	    '$scope',
	    'ui.components.Modal',
	    'bfc.$$PermissionsService',
	    'educacao.convencao.ConvencaoService'
    ];

    function SelectConvencaoDirectiveController($scope, uiModal, PermissionsService, ConvencaoService) {

        var vm = this;

        vm.searchConvencao = searchConvencao;
        vm.add = !PermissionsService.isRevokedOperation('ConvencaoPageMapping', 'criar') && add;

        function searchConvencao(params) {
            var filter = '(descricao like "' + params.term + '"';
            var convencoes = [];

            if ($scope.multiple) {
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(convencao) {
                        convencoes.push(convencao.id);
                    });
                }
            }

            if (angular.isArray($scope.convencoesExistentes) && $scope.convencoesExistentes.length > 0) {
                _.forEach($scope.convencoesExistentes, function(value) {
                    if (value.convencao && value.convencao.id) {
                        convencoes.push(value.convencao.id);
                    }
                });
            }

            if (convencoes.length) {
                filter += ' and id not in(' + convencoes.join() + ')';
            }
            filter += ')';

            return ConvencaoService.list(filter, params.offset, params.limit).then(function(data) {
                return {
                    content: data.content,
                    hasNext: data.hasNext
                };
            });
        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'convencao/convencao.cad.html',
                controller: 'educacao.convencao.ConvencaoCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    convencao: {
                        descricao: descricao
                    }
                }
            }).result;
        }

    }

})();


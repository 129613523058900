(function() {
    'use strict';

    angular.module('educacao.cursoaperfeicoamento')
        .controller('educacao.CursoAperfeicoamentoCadastroCtrl', Controller);

    Controller.$inject = [
        '$scope',
        'promiseTracker',
        '$modalInstance',
        'bfc.Focus',
        'educacao.cursoaperfeicoamento.CursoAperfeicoamentoService'
    ];

    function Controller(
        $scope,
        promiseTracker,
        $modalInstance,
        focus,
        CursoAperfeicoamentoService) {

        var vm = this;
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.curso = $modalInstance.params.curso || {};

        var modo = {
            adicionando: $modalInstance.params.canSaveAdd,
            editando: vm.curso.id
        };
        vm.save = save;
        vm.cancel = fechaModal;
        vm.modo = modo;
        vm.remove = remove;

        init();

        function init() {
            verificaFormulario();
            vm.windowTitle = modo.editando ? 'Editando curso de aperfeiçoamento' : 'Adicionando curso de aperfeiçoamento';
            focus('lbDescricao');
            if ( modo.editando ) {
                CursoAperfeicoamentoService.get(vm.curso.id).then(function(data){
                    vm.curso = data;
                });
            }
        }

        $scope.$watch('vm.curso.formacaoContinuada', function(newValue, oldValue){
            if ( newValue === oldValue ){
                return;
            }
            if ( !newValue ){
                vm.curso.areaFormacaoContinuada = null;
            }
        });

        function save(continuar) {
            beforeSave();
            vm.tracker.loading.addPromise(
                CursoAperfeicoamentoService.save(vm.curso).then(
                    function(data) {
                        vm.curso = data;
                        onSuccess(continuar);
                    }
                )
            );
        }

        function remove() {
            vm.tracker.loading.addPromise(
                CursoAperfeicoamentoService.remove(vm.curso).then(function() {
                    fechaModal();
                })
            );
        }

        function onSuccess(continuar) {
            if (continuar) {
                vm.curso = {};
                verificaFormulario();
                focus('lbDescricao');
            } else {
                $modalInstance.close(vm.curso);
            }
        }

        function fechaModal() {
            $modalInstance.close();
        }

        function verificaFormulario() {
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        }

        function beforeSave(){
            if ( vm.curso.tipoCursoAperfeicoamento !== 'OUTRO' ){
                vm.curso.tipoCursoOutro = null;
            }
            if ( !vm.curso.formacaoContinuada ){
                vm.curso.areaFormacaoContinuada = null;
                vm.curso.areaFormacaoContinuadaOutro = null;
            } else if ( vm.curso.areaFormacaoContinuada !== 'OUTRO' ) {
                vm.curso.areaFormacaoContinuadaOutro = null;
            }
        }
    }
})();


(function() {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
            .directive('dadosVisaoGeralGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-visao-geral-gestao-pessoas/dados-visao-geral-gestao-pessoas.html',
            scope: {},
            bindToController: {
                anoLetivoSelected: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService',
        'educacao.quadro-informativo-utils.CardTotalizadoresQuadroInformativoBuilder',
        '$scope',
        '$q',
        'promiseTracker',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        '$timeout',
        '$stateParams',
        '$rootScope'
    ];

    function Controller(Service, CardTotalizadoresQuadroInformativoBuilder, $scope, $q, promiseTracker, ContextoEstabelecimento, ContextoAnoLetivo, $timeout, $stateParams, $rootScope) {

        var vm = this;

        vm.CARDS_NAMES = {
            PROFESSORES: {
                titulo: 'Total de professores',
                tituloBadge: 'Total matrículas por tipo de contratação'
            },
            PROFESSORES_ENTURMADOS: {
              titulo: 'Total de professores enturmados',
              tituloBadge: 'Total de matrículas enturmadas por tipo de contratação'  
            },
            OUTROS_FUNCIONARIOS: {
                titulo: 'Total de outros funcionários',
            },
            AULAS_SEMANAIS: {
                titulo: 'Total de aulas semanais',
                tituloBadge: 'Total de aulas por tempo'
            },
            AULAS_SEMANAIS_OCUPADAS: {
                titulo: 'Aulas semanais ocupadas',
                tituloBadge: 'Total de aulas ocupadas por tempo'
            },
            AULAS_SEMANAIS_VAGAS: {
                titulo: 'Aulas semanais vagas',
                tituloBadge: 'Total de aulas vagas por tempo'
            }
        };

        vm.CARDS_ROW = {
            FIRST_ROW: 1,
            SECOND_ROW: 2
        };

        vm.CARDS_ORDER = {
            FIRST: 1,
            SECOND: 2,
            THIRD: 3
        };

        vm.BIG_CARD = {
            TRUE: true
        };

        vm.HAS_BADGE_CARD = {
            TRUE: true
        };

        vm.REQUIRED_CARD = {
            TRUE: true
        };

        vm.loadingTracker = {
            firstRow: promiseTracker(),
            secondRow: promiseTracker()
        };

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.estabelecimento = ContextoEstabelecimento.getEstabelecimento();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();

        vm.estabelecimentoSelected = $stateParams.estabelecimentoSelected ? $stateParams.estabelecimentoSelected : null;
        
        vm.firstRow = [];
        vm.secondRow = [];
        vm.maiorHeightCards = null;

        var saldoProfessores = null;
        var saldoProfessoresEnturmados = null;
        var saldoOutrosFuncionarios = null;
        var saldoAulasSemanais = null;
        var saldoAulasOcupadasSemanais = null;

        $scope.$watch('vm.estabelecimentoSelected', onChangeContext);
        $scope.$watch('vm.anoLetivoSelected', onChangeContext);
        $rootScope.$on('app-context:anoletivo:new', clearAnoLetivo);

        init();
        loadAnosLetivos();

        function init() {
            clearCardsRows();
            loadSaldos();
        }

        function loadAnosLetivos() {
            Service.getAnosLetivos().then(atualizaAnosLetivos);
        }

        function atualizaAnosLetivos(data) {
            vm.anosLetivos = data;
        }

        function loadSaldos() {
            var contextParams = resolveParams();
            loadFirstRowSaldos(contextParams);
            loadSecondRowSaldos(contextParams);
        }

        function loadFirstRowSaldos(contextParams) {
            var firstRowPromise = $q.all([
                Service.getDadosProfessores(contextParams).then(atualizaSaldoProfessores),
                Service.getDadosProfessoresEnturmados(contextParams).then(atualizaSaldoProfessoresEnturmados),
                Service.getDadosOutrosFuncionarios(contextParams).then(atualizaSaldoOutrosFuncionarios)
            ]).then(function () {
                vm.firstRow = [];

                vm.firstRow.push(
                    CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.PROFESSORES.titulo)
                    .withSaldo(saldoProfessores.total)
                    .withOrder(vm.CARDS_ORDER.FIRST)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeProfessores(saldoProfessores.vinculos))
                    .withTituloBadge(vm.CARDS_NAMES.PROFESSORES.tituloBadge)
                    .build()
                );
                vm.firstRow.push(
                    CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.PROFESSORES_ENTURMADOS.titulo)
                    .withSaldo(_.sum(saldoProfessoresEnturmados,'qtdFuncionario'))
                    .withOrder(vm.CARDS_ORDER.SECOND)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeProfessores(saldoProfessoresEnturmados))
                    .withTituloBadge(vm.CARDS_NAMES.PROFESSORES_ENTURMADOS.tituloBadge)
                    .build()
                );
                vm.firstRow.push(
                    CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.OUTROS_FUNCIONARIOS.titulo)
                    .withSaldo(saldoOutrosFuncionarios.qntdOutrosFuncionarios)
                    .withOrder(vm.CARDS_ORDER.THIRD)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .build()
                );

                getMaiorAlturaDosCards(vm.firstRow, vm.CARDS_ROW.FIRST_ROW);
            });

            vm.loadingTracker.firstRow.addPromise(firstRowPromise);
        }

        function atualizaSaldoProfessores(data) {
            saldoProfessores = data;
        }

        function atualizaSaldoProfessoresEnturmados(data) {
            saldoProfessoresEnturmados = data;
        }

        function atualizaSaldoOutrosFuncionarios(data) {
            saldoOutrosFuncionarios = data;
        }

        function mountBadgeProfessores(saldoTotalProfessores) {
            var badges = [];
            var counts = {
                CONCURSADO_EFETIVO_ESTAVEL: { count: 0, label: 'Efetivos' },
                TEMPORARIO: { count: 0, label: 'Temporários' },
                TERCEIRIZADO: { count: 0, label: 'Terceirizados' },
                CLT: { count: 0, label: 'CLT' }           
            };

            saldoTotalProfessores.forEach(function (saldoProfessor) {
                var tipoVinculo = saldoProfessor.tipoVinculo;
                if (counts[tipoVinculo]) {
                    counts[tipoVinculo].count += saldoProfessor.qtdFuncionario;
                }
            });

            Object.keys(counts).forEach(function (keyCount) {
                if (counts[keyCount].count > 0) {
                    badges.push({ descricao: counts[keyCount].count + ' ' + counts[keyCount].label });
                }
            });

            return badges;
        }

        function loadSecondRowSaldos(contextParams) {          
            var firstRowPromise = $q.all([
                Service.getTotalAulasSemanais(contextParams).then(atualizadSaldoAulasSemanais),
                Service.getTotalAulasOcupadasSemanais(contextParams).then(atualizaSaldoAulasOcupadasSemanais),
            ]).then(function () {
                vm.secondRow = [];
                vm.secondRow.push(
                    CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.AULAS_SEMANAIS.titulo)
                    .withSaldo(_.sum(saldoAulasSemanais, 'qtdTotalAulas'))
                    .withOrder(vm.CARDS_ORDER.FIRST)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeAulasPorDuracao(saldoAulasSemanais))
                    .withTituloBadge(vm.CARDS_NAMES.AULAS_SEMANAIS.tituloBadge)
                    .build()
                );
                vm.secondRow.push(
                    CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.AULAS_SEMANAIS_OCUPADAS.titulo)
                    .withSaldo(saldoAulasOcupadasSemanais.total)
                    .withOrder(vm.CARDS_ORDER.SECOND)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeAulasPorDuracao(saldoAulasOcupadasSemanais.aulasPorDuracao))
                    .withTituloBadge(vm.CARDS_NAMES.AULAS_SEMANAIS_OCUPADAS.tituloBadge)
                    .withIsRequired(vm.REQUIRED_CARD.TRUE)
                    .build()
                );
                vm.secondRow.push(
                    CardTotalizadoresQuadroInformativoBuilder.create()
                    .withTitulo(vm.CARDS_NAMES.AULAS_SEMANAIS_VAGAS.titulo)
                    .withSaldo(calculaTotalVagasAulasSemanais(saldoAulasSemanais, saldoAulasOcupadasSemanais))
                    .withOrder(vm.CARDS_ORDER.THIRD)
                    .withIsBigCard(vm.BIG_CARD.TRUE)
                    .withHasBadge(vm.HAS_BADGE_CARD.TRUE)
                    .withBadgeData(mountBadgeAulasVagasPorDuracao(saldoAulasSemanais, saldoAulasOcupadasSemanais.aulasPorDuracao))
                    .withTituloBadge(vm.CARDS_NAMES.AULAS_SEMANAIS_VAGAS.tituloBadge)
                    .withIsRequired(vm.REQUIRED_CARD.TRUE)
                    .build()
                );

                getMaiorAlturaDosCards(vm.secondRow, vm.CARDS_ROW.SECOND_ROW);
            });

            vm.loadingTracker.firstRow.addPromise(firstRowPromise);
        }

        function atualizadSaldoAulasSemanais(data) {
            saldoAulasSemanais = data;
        }

        function atualizaSaldoAulasOcupadasSemanais(data){ 
            saldoAulasOcupadasSemanais = data;
        }

        function calculaTotalVagasAulasSemanais(saldoTotalAulasSemanais, saldoTotalAulasOcupadasSemanais) {
            var totalAulasSemanais = _.sum(saldoTotalAulasSemanais, 'qtdTotalAulas');
            var totalAulasOcupadasSemanais = saldoTotalAulasOcupadasSemanais.total;

            var saldo = totalAulasSemanais - totalAulasOcupadasSemanais;

            if (saldo < 0) {
                return 0;
            }

            return saldo;
        }

        function mountBadgeAulasPorDuracao(saldoTotalAulasSemanais) {
            var badges = [];
            _.map(saldoTotalAulasSemanais, function (saldoAulaSemanal) {
                if (_.get(saldoAulaSemanal, 'qtdTotalAulas') > 0) {
                    badges.push({descricao: _.get(saldoAulaSemanal, 'qtdTotalAulas') + ' x ' + _.get(saldoAulaSemanal, 'tempoDuracaoAulas') + 'min'});
                }
            });
            return badges;
        }

        function mountBadgeAulasVagasPorDuracao(saldoTotalAulasSemanais, saldoTotalAulasOcupadasSemanais) {
            var badges = [];
            var saldoTotalAulasVagasSemanais = {};

            var saldoTotalAulasVagas = saldoTotalAulasSemanais.concat(saldoTotalAulasOcupadasSemanais);

            saldoTotalAulasVagas.forEach(function (saldo) {
                var quantidade = _.get(saldo, 'qtdTotalAulas');
                var duracao = _.get(saldo, 'tempoDuracaoAulas');

                if (saldoTotalAulasVagasSemanais[duracao]) {
                    saldoTotalAulasVagasSemanais[duracao] -= quantidade;
                } else {
                    saldoTotalAulasVagasSemanais[duracao] = quantidade;
                }
            });

            Object.keys(saldoTotalAulasVagasSemanais).map(function (duracao) {
                if (saldoTotalAulasVagasSemanais[duracao] > 0) {
                    badges.push({ descricao: saldoTotalAulasVagasSemanais[duracao] + ' x ' + duracao + 'min' });
                }
            });

            return badges;
        }

        function getMaiorAlturaDosCards(cards, cardsRowNumber) {
            $timeout(function () {
                var alturasCards = getAlturasDosCards(cards, cardsRowNumber);
                vm.maiorHeightCards = getMaiorAltura(alturasCards);
            });        
        }

        function getAlturasDosCards(cards, cardsRowNumber) {
            var alturasCards = [];
            _.forEach(cards, function (index) {
                var cardId = cardsRowNumber === vm.CARDS_ROW.FIRST_ROW ? 'card_' + index : 'card2_' + index;
                var clientHeight = getCardHeight(cardId);
                alturasCards.push(clientHeight);
            });
            return alturasCards;
        }

        function getCardHeight(cardId) {
            var element = document.getElementById(cardId);
            return element ? element.clientHeight : 0;
        }

        function getMaiorAltura(alturasCards) {
            return _.max(alturasCards);
        }

        function onChangeContext(newValue) {
            if (newValue !== null || (newValue === null && !_.isEmpty(vm.firstRow))) {
                if (newValue === null) {
                    vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
                }
                init();
            }
        }

        function clearAnoLetivo() {
            $stateParams.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
            vm.anoLetivoSelected = ContextoAnoLetivo.getAnoLetivo();
        }

        function resolveParams() {
            return {
                anoLetivoId: _.get(vm.anoLetivoSelected, 'id'),
                estabelecimentoId: vm.isSecretaria ? _.get(vm.estabelecimentoSelected, 'id') : _.get(ContextoEstabelecimento.getEstabelecimento(), 'id')
            };
        }

        function clearCardsRows() {
            vm.firstRow = [];
            vm.secondRow = [];
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.common')
        .provider('educacao.common.ModalConfirm', Provider);

    var defaultOptions = {
        title: 'Confirmar',
        message: 'Você realmente deseja fazer esta operação?',
        confirmButton: 'Sim',
        cancelButton: 'Não'
    };

    function Provider() {
        var provider = this;
        _.forEach(defaultOptions, function(v, key) {
            provider['set' + _.capitalize(key)] = function(value) {
                defaultOptions[key] = value;
            };
        });

        this.$get = create;

        create.$inject = ['ui.components.Modal'];

        function create(uiModal) {
            return open;

            function open(options) {
                return uiModal.open({
                    templateUrl: 'common/services/modal-confirm.template.html',
                    size: 'alert',
                    params: _.extend({}, defaultOptions, options)
                }).result;
            }
        }
    }
})();

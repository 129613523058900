(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .directive('appInscricaoListaEspera', appInscricaoListaEspera);

    function appInscricaoListaEspera() {
        return {
            restrict: 'E',
            templateUrl: 'lista-espera-new/inscricao/inscricao-lista-espera.html',
            scope: {},
            bindToController: {},
            controller: 'educacao.lista-espera-novo.inscricao.InscricaoCtrl',
            controllerAs: 'vm'
        };
    }
})();

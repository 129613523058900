(function() {
	'use strict';

	angular.module('educacao.configuracaoInstrumentosAvaliacao')
		.factory('educacao.configuracaoInstrumentosAvaliacao.ConfiguracaoInstrumentosAvaliacaoService',
			ConfiguracaoInstrumentosAvaliacaoService);

	ConfiguracaoInstrumentosAvaliacaoService.$inject = ['ServiceBuilder'];

	function ConfiguracaoInstrumentosAvaliacaoService(ServiceBuilder) {

		var self = ServiceBuilder
			.create()
			.path('configuracao-instrumento-avaliacao')
			.build();

		self.saveConfiguration = saveConfiguration;
		self.getConfiguracao = getConfiguracao;

		return self;

		function getConfiguracao(tipoEstabSecretaria, tipoConfiguracao) {
			return self.getEndpoint()
				.get(tipoEstabSecretaria + '/' + tipoConfiguracao).then(function(data) {
					if (_.isNull(data.permiteUtilizarParecer)) {
						data.permiteUtilizarParecer = true;
					}
					return data;
				});
		}

		function saveConfiguration(configuracao) {
			return self.save(configuracao);
		}
	}
})();

(function() {
    'use strict';

    angular.module('educacao.configuracaoPlanejamentoAula').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.configuracaoPlanejamentoAula', {
            url: 'configuracao-planejamento-aula',
            data: {
                id: 'ConfiguracaoPlanejamentoAulaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'configuracao-planejamento-aula/configuracao-planejamento-aula.html'
                }
            }
        });
    }
})();


(function () {

    'use strict';

    angular.module('educacao.agendaprofessor').controller('educacao.agendaprofessor.AgendaProfessorCtrl', AgendaProfessorCtrl);

    AgendaProfessorCtrl.$inject = [
        '$scope',
        'promiseTracker',
        '$q',
        'educacao.calendario.CalendarioSecretariaService',
        'educacao.calendario.CalendarioEstabelecimentoService',
        'IdGenerator',
        'CalendarioDisplay',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo',
        'ui.components.Modal',
        'educacao.agendaprofessor.DisponibilidadeService'
    ];

    function AgendaProfessorCtrl($scope,
                                 promiseTracker,
                                 $q,
                                 calendarioSecretariaService,
                                 calendarioEstabelecimentoService,
                                 IdGenerator,
                                 CalendarioDisplay,
                                 ContextoEstabelecimento,
                                 ContextoAnoLetivo,
                                 $modal,
                                 DisponibilidadeService) {

        

        var vm = this;
        vm.calendarioDisplay = CalendarioDisplay.create();
        vm.tracker = {};
        vm.tracker.calendario = promiseTracker();
        vm.tracker.list = promiseTracker();
        vm.tracker.listProfessores = promiseTracker();
        vm.tracker.listHorarios = promiseTracker();
        vm.horarios = [];
        vm.estabelecimentosEProfessoresMarcados = [];
        vm.hasEstabelecimento = !ContextoEstabelecimento.isSecretaria();
        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo().ano;
        vm.isCalendarioIndisponivel = false;
        vm.existCalendarioSecretaria = false;
        vm.openAddDisponibilidade  = openAddDisponibilidade;
        init();
        bindEvents();

        function bindEvents() {
            $scope.$on('edit.horario', function (event, disponibilidade) {
                openAddDisponibilidade(disponibilidade);
                bfc.Popover.closeAll();
            });
            $scope.$on('remove.horario', function (event, disponibilidade) {

                vm.remove(disponibilidade);
                bfc.Popover.closeAll();
            });

            $scope.$on('item.clicked', function (event, args) {
                _populateAgenda(args);
            });
        }

        vm.remove = function (disponibilidade) {
            DisponibilidadeService.remove(disponibilidade.id).then(function () {
                vm.horarios = _.reject(vm.horarios, function (data) {
                    return data.id === disponibilidade.id;
                });
            });
        };

        function verificaStatusCalendario() {
            if (vm.hasEstabelecimento) {
                vm.tracker.calendario.addPromise(
                    calendarioEstabelecimentoService.getCalendarioAnoLetivo()
                        .then(IdGenerator.generateAllId)
                        .then(checkCalendarioEstabelecimentoIniciado)
                );
            } else {
                vm.tracker.calendario.addPromise(
                    calendarioSecretariaService.getCalendarioAnoLetivo()
                        .then(IdGenerator.generateAllId)
                        .then(checkCalendarioSecretariaIniciado)
                );
            }
        }

        function init() {
            limitYearForCalendar();
            verificaStatusCalendario();
        }

        function limitYearForCalendar() {
            var anoInicioLimit = vm.anoLetivo - 1;
            var anoFinalLimit = vm.anoLetivo + 1;
            vm.initialDate = moment().year(anoInicioLimit).startOf('year').format('YYYY-MM-DD');
            vm.finalDate = moment().year(anoFinalLimit).endOf('year').format('YYYY-MM-DD');
        }

        function checkCalendarioEstabelecimentoIniciado(valueCalendario) {
            vm.isCalendarioEstabelecimentoIniciado = !!valueCalendario;
            if (vm.isCalendarioEstabelecimentoIniciado) {
                vm.isCalendarioBlocked = valueCalendario.calendarioSecretaria.blocked;
                vm.menssagem = vm.isCalendarioBlocked ? 'O calendário da rede está sendo alterado, aguarde a nova revisão!' : '';
                vm.calendario = valueCalendario.calendario;

                vm.calendarioDisplay
                    .reset()
                    .setBaseCalendar(vm.calendario)
                    .addCalendario(vm.calendario, 'Escola', true)
                    .process();
                vm.calendarioDisplay.dataInicial = vm.initialDate;
                vm.calendarioDisplay.dataFinal = vm.finalDate;

            } else {
                vm.isCalendarioIndisponivel = true;
                vm.menssagem = 'O calendário escolar da rede municipal para o ano letivo ainda não foi disponibilizado.';
            }
        }

        function checkCalendarioSecretariaIniciado(valueCalendario) {

            if (!valueCalendario) {
                vm.isCalendarioIndisponivel = true;
                vm.menssagem = 'O calendário escolar para o ano letivo ' + vm.anoLetivo + ' ainda não foi iniciado!';
            } else {
                vm.calendario = valueCalendario.calendario;

                vm.calendarioDisplay
                    .reset()
                    .setBaseCalendar(vm.calendario)
                    .addCalendario(vm.calendario, 'Rede Municipal')
                    .process();
                vm.calendarioDisplay.dataInicial = vm.initialDate;
                vm.calendarioDisplay.dataFinal = vm.finalDate;

            }

        }

        function _populateAgenda(itemServidor) {
            if (vm.tracker.listHorarios.tracking()) {
                return;
            }
            if (itemServidor.selected) {
                var filter = '(professor = ' + itemServidor.servidor.id + ' and estabelecimento  = ' + itemServidor.estabelecimento.id + ') ';
                vm.filtro = itemServidor.estabelecimento.id;
                vm.tracker.listHorarios.addPromise(
                    DisponibilidadeService.getList(0, 999, filter).then(function (data) {
                            vm.insertHorario(data, itemServidor);
                            vm.estabelecimentosEProfessoresMarcados.push(_parseEstabelecimentosProfessores(itemServidor));
                        },
                        function (error) {
                            throw new Error(error);
                        }));
            } else {
                $q.all([
                    vm.horarios = _.reject(vm.horarios, function (data) {
                        return ((data.professor.id === itemServidor.servidor.id) && (data.estabelecimento.id === itemServidor.estabelecimento.id));
                    }),
                    vm.estabelecimentosEProfessoresMarcados = _.reject(vm.estabelecimentosEProfessoresMarcados, function (data) {
                        return ((data.professor.id === itemServidor.servidor.id) && (data.estabelecimento.id === itemServidor.estabelecimento.id));
                    })
                ]);
                vm.filtro = null;
            }
        }

        function _parseEstabelecimentosProfessores(itemServidor) {
            return {
                professor: itemServidor.servidor,
                estabelecimento: itemServidor.estabelecimento,
                index: itemServidor.index
            };
        }

        vm.insertHorario = function (disponibilidade, itemServidor) {
            disponibilidade.map(function (value) {
                vm.horarios = vm.horarios.concat(
                    _createItemHorario(value, itemServidor)
                );
            });
        };

        function _createItemHorario(item, itemServidor) {
            return {
                id: item.id,
                diaSemana: item.diaSemana,
                horaInicio: item.horaInicio,
                horaFim: item.horaFim,
                indice: itemServidor.index,
                professor: item.professor,
                estabelecimento: itemServidor.estabelecimento
            };
        }

         
        function openAddDisponibilidade(disponibilidade) {
            $modal.open({
                templateUrl: 'agenda-professor/disponibilidade/disponibilidade.cad.html',
                controller: 'educacao.agendaprofessor.DisponiblidadeCadCtrl as vm',
                size: 'lg',
                params: {
                    disponibilidade: angular.copy(disponibilidade),
                }
            });
        }

        function createProfessor(item) {
            return {
                id: item.professor.id,
                nome: item.professor.pessoa.nome,
                index: item.index,
                estabelecimento: item.estabelecimento
            };
        }

        $scope.$on('refreshDisponibilidades', function (event, args) {
            if (args.add) {
                vm.estabelecimentosEProfessoresMarcados.forEach(function (item) {
                    args.data.forEach(function (disponibilidade) {
                        if (disponibilidade.professor.id === item.professor.id && disponibilidade.estabelecimento.id === item.estabelecimento.id) {
                            disponibilidade.horarios.forEach(function (horario) {
                                vm.horarios.push(_createItemHorario(horario, createProfessor(item)));
                            });
                        }
                    });
                });
            } else if (args.edit) {

                var horario = args.data.horarios[0];

                var index = _.findIndex(vm.horarios, {
                    id: horario.id
                });

                vm.horarios[index].horaInicio = horario.horaInicio;
                vm.horarios[index].horaFim = horario.horaFim;

            } else if (args.remove) {
                vm.horarios = _.reject(vm.horarios, function (h) {
                    return h.id === args.data.id;
                });
            }
        });

    }

})();

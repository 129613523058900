(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('inscricaoOnlineLocalizando', inscricaoOnlineLocalizando);

    inscricaoOnlineLocalizando.$inject = [];

    function inscricaoOnlineLocalizando() {
        return {
            restrict: 'E',

            templateUrl: 'matricula/inscricao-online/localizando/localizando.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        // vm.statesLocalizando = {};
        // vm.statesLocalizando.endereco = {id:1};
        // vm.statesLocalizando.etapa = {id:2};
        // vm.statesLocalizando.turno = {id:3};

        // vm.stateSelectedLocalizando = 1;
        vm.stateStepUm = true;
        vm.stateStepDois = false;
        vm.stateStepTres = false;
        vm.checkSelecionado = checkSelecionado;

        function checkSelecionado(step) {
            if (step === '2' && vm.stateStepTres) {
                vm.stateStepDois = true;
                vm.stateStepUm = false;
                vm.stateStepTres = false;
            } else if (step === '1') {
                vm.stateStepUm = true;
                vm.stateStepDois = false;
                vm.stateStepTres = false;
            }
        }
    }
})();

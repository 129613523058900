(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('appSelectObjetoConhecimento', SelectObjetoConhecimento);

	function SelectObjetoConhecimento() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add"></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngRequired: '=?',
				lista: '=?'
			},
			controller: Controller,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
		};
	}

	Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.matriz.ObjetoConhecimentoService'];

	function Controller($scope, $modal, service) {

		var vm = this;

		vm.search = search;
		vm.add = add;

		function search(params) {
			var filter = '(descricao like "' + params.term + '"';

			if ($scope.multiple) {
				filter += ignoreId($scope.ngModel);
			}
			if ($scope.lista) {
				filter += ignoreId($scope.lista());
			}

			filter += ')';
			return service.list(filter, params.offset, params.limit);
		}

		function ignoreId(list) {
			var filter = '';
			var objetos = [];
			if (list) {
				list.forEach(function(dataObj) {
					if (_.get(dataObj, 'id')) {
						objetos.push(dataObj.id);
					}
				});
			}
			if (objetos.length) {
				filter += ' and id not in(' + objetos.join() + ')';
			}
			return filter;
		}

		function add(descricao) {
			return $modal.open({
				templateUrl: 'planejamento/matriz/objetos-conhecimentos/cadastro-objetos-conhecimentos.html',
				controller: 'educacao.matriz.ObjetosConhecimentoCadCtrl',
				controllerAs: 'vm',
				size: '',
				params: {
					objetoConhecimento: {
						descricao: descricao
					}
				}
			}).result;
		}
	}
})();

(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.factory('educacao.matriz.EtapaInepSelect', ['$q',
                          'educacao.common.ObjectUtilsService',
                          'educacao.common.EtapaInepService',
                          'bfc.Select2Config',
          function ($q,
            ObjectUtilsService,
            EtapaInepService,
            Select2Config) {
            return {
                select2Config: function (matriz, options) {
                    return Select2Config.create(
                        angular.extend({
                            result: function (params, callback) {

                                var term = ObjectUtilsService.normalizeFilter(params.term);
                                term = encodeURI(term);

                                var result, filter;

                                /*var classificacaoModalidade = {
                                    '1':{ 'descricao': 'Regular', 'filter':'regular=true'},
                                    '2':{ 'descricao': 'Especial', 'filter':'especial=true'},
                                    '3':{ 'descricao': 'EJA', 'filter':'eja=true'}
                                };*/

                                //nivelModalidadeCurso = matriz.curso.nivelModalidade.id;

                                //var filterEtapasPossíveis = _.result(
                                //                                _.result(classificacaoModalidade, nivelModalidadeCurso),
                                //                            'filter');
                                //removido do filter and '+ filterEtapasPossíveis +'
                                filter = '(nome like "' + term + '")';

                                result = EtapaInepService.getList(params.offset, params.limit, filter, undefined)
                                    .then(function (data) {
                                        callback({
                                            results: data,
                                            more: false
                                        });
                                    });

                                return result;
                            },
                            formatValue: function (data) {
                                return data;
                            },
                            formatResult: function (data) {
                                return data.text ?
                                    data.text :
                                    data.nome;
                            },
                            formatSelection: function (data) {
                                return data.nome;
                            },
                            initSelection: function (element, callback) {
                                var id = $(element).val();

                                if (id) {
                                    EtapaInepService.get(id).then(callback, function () {
                                        callback({
                                            id: null
                                        });
                                    });
                                }
                            }
                        }, options));

                }

            };

          }

     ]);


    moduloCommon.controller('educacao.matriz.ParametrosCadCtrl', ['$scope',
                             '$rootScope',
                             '$timeout',
                             'promiseTracker',
                             '$modalTracker',
		                     'educacao.common.ModalCad',
                             'educacao.matriz.EtapaService',
                             'educacao.common.ObjectUtilsService',
                             'id',
                             'matriz',
                             'fnCad',
                             '$modalInstance',
                             'bfc.Select2Config',
                             'educacao.matriz.EtapaInepSelect',
                             'educacao.common.CommonSelect',
                             'educacao.matriz.MatrizService',
                             '$q',
		function ($scope,
            $rootScope,
            $timeout,
            promiseTracker,
            $modalTracker,
            ModalCad,
            EtapaService,
            ObjectUtilsService,
            id,
            matriz,
            fnCad,
            $modalInstance,
            Select2Config,
            EtapaInepSelect,
            CommonSelect,
            MatrizService,
            $q
		) {

            $scope.tracker = {};
            $scope.tracker.save = promiseTracker();
            $scope.tracker.saveContinue = promiseTracker();
            $scope.tracker.saveContinue = promiseTracker();
            $scope.tracker.camposAdicionais= promiseTracker();
            $scope.etapaMatriz = {};

            var ConfiguracoesNivelEscolar = [
                {
                    'key': 'EDUCACAO_INFANTIL',
                    'minAno': 0,
                    'maxAno': 7,
                    'maxEquivalencias': 10
                        },
                {
                    'key': 'ENSINO_MEDIO',
                    'minAno': 6,
                    'maxAno': 100,
                    'maxEquivalencias': 5
                        },
                {
                    'key': 'ENSINO_FUNDAMENTAL',
                    'minAno': 6,
                    'maxAno': 100,
                    'maxEquivalencias': 10
                        },
                {
                    'key': 'ACELERACAO',
                    'minAno': 14,
                    'maxAno': 100,
                    'maxEquivalencias': 10
                        },
                {
                    'key': 'EJA',
                    'minAno': 14,
                    'maxAno': 100,
                    'maxEquivalencias': 10
                }
            ];

            var ConfiguracoesNivelModalidade = [
                {
                    'key': 'ACELERACAO',
                    'minAno': 14,
                    'maxAno': 100
                },
                {
                    'key': 'EJA',
                    'minAno': 14,
                    'maxAno': 100
                },
                {
                    'key': 'COMPLEMENTAR',
                    'minAno': 0,
                    'maxAno': 100
                }
            ];



            var opcoesMes = [
                {
                    'id': 1,
                    'descricao': 'janeiro',
                    max: 31
                },
                {
                    'id': 2,
                    'descricao': 'fevereiro',
                    max: 29
                },
                {
                    'id': 3,
                    'descricao': 'março',
                    max: 31
                },
                {
                    'id': 4,
                    'descricao': 'abril',
                    max: 30
                },
                {
                    'id': 5,
                    'descricao': 'maio',
                    max: 31
                },
                {
                    'id': 6,
                    'descricao': 'junho',
                    max: 30
                },
                {
                    'id': 7,
                    'descricao': 'julho',
                    max: 31
                },
                {
                    'id': 8,
                    'descricao': 'agosto',
                    max: 31
                },
                {
                    'id': 9,
                    'descricao': 'setembro',
                    max: 30
                },
                {
                    'id': 10,
                    'descricao': 'outubro',
                    max: 31
                },
                {
                    'id': 11,
                    'descricao': 'novembro',
                    max: 30
                },
                {
                    'id': 12,
                    'descricao': 'dezembro',
                    max: 31
                },

            ];

            //


            init();
            
            function init() {
                $modalTracker.addPromise(
                    EtapaService.get(matriz.id, id).then(function (data) {
                        $scope.etapaMatriz = data;
                        convertListaFront();
                        _selectIdades();
                        initCamposAdicionais();
                    })
                );

                $scope.matriz = matriz;

                $scope.etapaInepSelect = EtapaInepSelect.select2Config(matriz);

                $scope.equivalenciaSelect = Select2Config.create(angular.extend({
                    result: function (params, callback) {
                        callback({
                            results: isEquivalenciasArray() || $scope.etapaMatriz.equivalencias.length === 0 ? ObjectUtilsService.filterByField(getEquivalenciasDisponiveis(), 'descricao', params.term) : [],
                            more: false
                        });
                    },
                    formatValue: function (data) {
                        return data;
                    },
                    formatResult: function (data) {
                        return data.descricao;
                    },
                    formatSelection: function (data) {
                        return data.descricao;
                    },
                    multiple: true
                }));

                $scope.equivalencias = _.map(_.range(1, findConfiguracoes('maxEquivalencias')),
                    function (num) {
                        return {
                            'id': num,
                            'descricao': num + 'º ano'
                        };
                    });
            }

            function initCamposAdicionais() {
                var matrizId = _.get($scope.matriz, 'id');
                var etapaId = _.get($scope.etapaMatriz, 'id');
                var promise = EtapaService.camposAdicionais(matrizId, etapaId)
                    .then(function (data) {
                        $scope.campoAdicional = data;
                    });                
                $scope.tracker.camposAdicionais.addPromise(promise);
            }

            $scope.searchEquivalencia = function (params) {
                var term = decodeURI(params.term);
                term = term.replace(/%/g, ' ').trim();

                var equivalenciaFilter = isEquivalenciasArray() || $scope.etapaMatriz.equivalencias.length === 0 ? ObjectUtilsService.filterByField(getEquivalenciasDisponiveis(), 'descricao', term) : [];
                
                equivalenciaFilter = _.filter(equivalenciaFilter, function (equivalencia) {
                    return equivalencia.descricao.toUpperCase().indexOf(term.toUpperCase()) > -1;
                });

                var page = {
                    content: equivalenciaFilter,
                    hasNext: false
                };
                return $q.when(page);

            };

            function findConfiguracoes(attribute) {
                var findConfig = _.find(ConfiguracoesNivelModalidade, function (data) {
                    return data.key === $scope.matriz.curso.nivelModalidade;
                });
                if (!findConfig || attribute === 'maxEquivalencias') {
                    findConfig = _.find(ConfiguracoesNivelEscolar, function (data) {
                        return data.key === $scope.matriz.curso.nivelEscolar;
                    });
                }
                return _.result(findConfig, attribute);
            }

            function _selectIdades() {

                var minAno = findConfiguracoes('minAno');
                var maxAno = findConfiguracoes('maxAno');

                var opcoesAnos = _.map(_.range(minAno, maxAno),
                    function (num) {
                        return {
                            'id': num,
                            'descricao': num + (num > 1 ? ' anos' : ' ano')
                        };
                    });

                var opcoesMeses = _.map(_.range(0, 12),
                    function (num) {
                        return {
                            'id': num,
                            'descricao': num + (num > 1 ? ' meses' : ' mês')
                        };
                    });

                $scope.anoIdadeMinimaSelect = CommonSelect.select2Config(opcoesAnos);
                $scope.anoIdadeMaximaSelect = CommonSelect.select2Config(opcoesAnos);
                $scope.mesCompletarIdade = CommonSelect.select2Config(opcoesMes);
                $scope.mesIdadeMinimaSelect = CommonSelect.select2Config(opcoesMeses);
                $scope.mesIdadeMaximaSelect = CommonSelect.select2Config(opcoesMeses);

            }

            function isEquivalenciasArray() {
                return $scope.matriz.curso.nivelModalidade === 'ACELERACAO' || $scope.matriz.curso.nivelModalidade === 'EJA';
            }

            $scope.descricaoEtapa = function (etapa) {
                var organizacao = MatrizService.getDescricaoEtapa($scope.matriz, etapa);
                if ($scope.matriz.curso.organizacao.id === -1) {
                    organizacao = organizacao.replace($scope.matriz.curso.organizacao.descricao, 'etapa');
                    if (!etapa.identificacao) {  
                        organizacao = 'etapa';
                    }
                    return organizacao;
                } else {
                    organizacao = organizacao.replace($scope.matriz.curso.organizacao.descricao, $scope.matriz.curso.organizacao.descricao.toLowerCase());
                }               
                return etapa.identificacao ? organizacao : $scope.matriz.curso.organizacao.descricao.toLowerCase();
            };

            function getEquivalenciasDisponiveis() {
                return _.reject($scope.equivalencias, function (equivalencia) {
                    return findGrupo(equivalencia);
                });
            }

            function findGrupo(equivalencia) {

                var grupoRetorno = null;
                _.each($scope.equivalencias, function (grupo) {
                    if (_.where(grupo.matriz, {
                            id: equivalencia.id
                        }).length > 0) {
                        grupoRetorno = grupo;
                    }
                });
                return grupoRetorno;
            }


            $scope.$watch('etapaMatriz.mesCompletarIdadeMinima', function (newValue) {

                if (_.isUndefined(newValue) || _.isNull(newValue)) {
                    $scope.etapaMatriz.diaCompletarIdadeMinima = null;
                }

            });


            $scope.save = function () {

                $scope.etapaMatriz.campoAdicional = $scope.campoAdicional;

                convertListaBack();

                $scope.tracker.save.addPromise(EtapaService.save(matriz.id, $scope.etapaMatriz).then(function (data) {
                    if (angular.isFunction(fnCad)) {
                        fnCad(data);
                    }

                    $rootScope.$broadcast('refreshEtapasMatriz', {
                        data: data,
                        add: !id,
                        edit: Boolean(id)
                    });

                    $modalInstance.close();
                }));

                convertListaFront();

            };

            var convertListaFront = function () {
                if (!_.isNull($scope.etapaMatriz.anoIdadeMinima)) {
                    $scope.etapaMatriz.anoIdadeMinima = {
                        'id': $scope.etapaMatriz.anoIdadeMinima,
                        'descricao': $scope.etapaMatriz.anoIdadeMinima + ($scope.etapaMatriz.anoIdadeMinima > 1 ? ' anos' : ' ano')
                    };
                }

                if (!_.isNull($scope.etapaMatriz.mesIdadeMinima)) {
                    $scope.etapaMatriz.mesIdadeMinima = {
                        'id': $scope.etapaMatriz.mesIdadeMinima,
                        'descricao': $scope.etapaMatriz.mesIdadeMinima + ($scope.etapaMatriz.mesIdadeMinima > 1 ? ' meses' : ' mês')
                    };
                }

                if (!_.isNull($scope.etapaMatriz.anoIdadeMaxima)) {
                    $scope.etapaMatriz.anoIdadeMaxima = {
                        'id': $scope.etapaMatriz.anoIdadeMaxima,
                        'descricao': $scope.etapaMatriz.anoIdadeMaxima + ($scope.etapaMatriz.anoIdadeMaxima > 1 ? ' anos' : ' ano')
                    };
                }

                if (!_.isNull($scope.etapaMatriz.mesIdadeMaxima)) {
                    $scope.etapaMatriz.mesIdadeMaxima = {
                        'id': $scope.etapaMatriz.mesIdadeMaxima,
                        'descricao': $scope.etapaMatriz.mesIdadeMaxima + ($scope.etapaMatriz.mesIdadeMaxima > 1 ? ' meses' : ' mês')
                    };
                }

                if (!_.isNull($scope.etapaMatriz.mesCompletarIdadeMinima)) {

                    $scope.etapaMatriz.mesCompletarIdadeMinima = _.find(opcoesMes, {
                        'id': $scope.etapaMatriz.mesCompletarIdadeMinima
                    });
                }
            };

            var convertListaBack = function () {
                if (!_.isNull($scope.etapaMatriz.anoIdadeMinima)) {
                    $scope.etapaMatriz.anoIdadeMinima = $scope.etapaMatriz.anoIdadeMinima.id;
                }

                if (!_.isNull($scope.etapaMatriz.mesIdadeMinima)) {
                    $scope.etapaMatriz.mesIdadeMinima = $scope.etapaMatriz.mesIdadeMinima.id;
                }

                if (!_.isNull($scope.etapaMatriz.anoIdadeMaxima)) {
                    $scope.etapaMatriz.anoIdadeMaxima = $scope.etapaMatriz.anoIdadeMaxima.id;
                }

                if (!_.isNull($scope.etapaMatriz.mesIdadeMaxima)) {
                    $scope.etapaMatriz.mesIdadeMaxima = $scope.etapaMatriz.mesIdadeMaxima.id;
                }

                if (!_.isNull($scope.etapaMatriz.mesCompletarIdadeMinima)) {
                    $scope.etapaMatriz.mesCompletarIdadeMinima = $scope.etapaMatriz.mesCompletarIdadeMinima.id;
                }
            };

            $scope.cancel = function () {
                $modalInstance.close();
            };

        }]);
})();

(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosAulasPorEstabelecimentoTempoGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/componente-curricular-info-modal/table-dados-aulas-por-estabelecimento-gestao-pessoas/table-dados-aulas-por-estabelecimento-tempo-gestao-pessoas/table-dados-aulas-por-estabelecimento-tempo-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                saldos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento',
        'EdEnumsCacheService'
    ];

    function Controller(ContextoEstabelecimento, EdEnumsCacheService) {
        var vm = this;

        init();

        function init() {
            EdEnumsCacheService.get('Turno').then(function(result) {
                vm.TURNOS = result;
            });
        }

        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matricula')
        .factory('educacao.matricula.AtividadeService', AtividadeService);

    AtividadeService.$inject = ['Restangular'];

    function AtividadeService(Restangular) {

        return {
            getPossuamTurmas: getPossuamTurmas
        };

        function getPossuamTurmas(estabelecimentoId, tipoAtendimento, offset, limit, filter) {
            return Restangular.one('matricula').one('atividade').all('possuam-turmas').get('', {
                estabelecimento: estabelecimentoId,
                tipoAtendimento: tipoAtendimento,
                offset: offset,
                limit: limit,
                filter: filter
            });
        }
    }
})();

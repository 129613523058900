(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.RegiaoController', RegiaoController);

    RegiaoController.$inject = [
        '$scope',
        'educacao.common.$commons', 
        'ui.components.Modal',
        '$modalStates',
        'bfc.Notification',
        'educacao.enderecos.RegiaoService',
    ];

    function RegiaoController($scope, $commons, Modal, $modalStates, bfcNotification, RegiaoService) {
        var vm = this;

        vm.RegiaoService = RegiaoService;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.search = search;
        vm.columns = getColumns();

        vm.filter = {};
        vm.tracker = {};
        vm.listUsuarioControls = {};
        vm.listPadraoControls = {};
        
        vm.tracker.loading = $commons.$promiseTracker();



        function remove(regiaoMunicipal) {
            return RegiaoService.remove(regiaoMunicipal)
                    .then(function() {
                        publishNotificationRemove(regiaoMunicipal);
                    });
        }

        function publishNotificationRemove(regiaoMunicipal) {
            bfcNotification.publish('Região municipal ' + regiaoMunicipal.nome + ' excluída com sucesso.', 'success');
        }

        function search() {
            vm.listUsuarioControls.search();
        }

        function getColumns() {
            return [
                {
                    id: 'nome',
                    model: 'nome',
                    label: 'Nome',
                    filterable: true
                },
                {
                    id: 'sigla',
                    model: 'sigla',
                    label: 'Sigla',
                    filterable: true
                },

            ];
        }

        function  openCad(estado) {
            $modalStates.goModalState(estado);
        }
    }
})();
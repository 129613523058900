(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncionarioService', Service);

    Service.$inject = ['ServiceBuilder', 'Restangular'];

    function Service(ServiceBuilder, Restangular) {
        var selfService = ServiceBuilder.create()
            .path('funcionario', 'servidores')
            .enum('CertidaoCivil')
            .enum('EquivalenciaEtapa')
            .enum('EstadoCivil')
            .enum('ModeloCertidao')
            .enum('NivelEscolar')
            .enum('Raca')
            .enum('Sexo')
            .enum('SituacaoFormacao')
            .enum('TipoInstituicaoInep')
            .enum('TipoVistoEstrangeiro')
            .enum('UF')
            .enum('GrauInstrucao')
            .enum('SituacaoFuncionario')
            .enum('SituacaoFormacaoPosGraduacao')
            .enum('TipoFormacaoPosGraduacao')
            .build();

        selfService.suggest = suggest;
        selfService.getFuncionarios = getFuncionarios;
        selfService.getMatriculasFuncionario = getMatriculasFuncionario;
        selfService.camposAdicionais = camposAdicionais;
        selfService.getPessoas = getPessoas;
        selfService.getEstatisticas = getEstatisticas;
        selfService.listagem = listagem;

        function suggest(cpf) {
            return selfService.getEndpoint()
                .one('suggest')
                .get({
                    cpf: cpf
                });
        }

        function getMatriculasFuncionario(id) {
            return Restangular.all('funcionario/servidores').get(id);
        }

        function listagem(params) {
            return Restangular.all('funcionario/servidores/listagem').get('',params);
        }

        function getFuncionarios(pessoa) {
            var pessoaCpf = encodeURI('%' + removeCaracteresEspeciais(pessoa) + '%');
            var pessoaTerm = encodeURI('%' + pessoa + '%');

            var filter = '(' +
                '(pessoa.nome like "' + pessoaTerm + '")' +
                ' or (pessoa.cpf like "' + pessoaCpf + '")' +
                ' or (pessoa.rg like "' + pessoaTerm + '"))';

            return selfService.getEndpoint()
                .one('pessoas')
                .get({
                    filter: filter
                });
        }

        function getPessoas(pessoa) {
            pessoa = pessoa.replace(/\.|-/g, '');
            return Restangular.one('pessoa/pessoas/template/fisica')
                .get({
                    query: pessoa
                });
        }



        function camposAdicionais(funcionarioId) {
            return Restangular.one('funcionario/servidores/campos-adicionais', funcionarioId)
                .get()
                .then(Restangular.stripRestangular);
        }

        function getEstatisticas() {
            return Restangular.one('funcionario/estatisticas')
                .get();
        }

        function removeCaracteresEspeciais(texto) {
            var er = /\^|~|\?|,|\*|\.|\-/g;
            return texto.replace(er, '');
        }

        return selfService;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.MatriculaHistoricoPopoverController', MatriculaHistoricoPopoverController);

    MatriculaHistoricoPopoverController.$inject = ['$rootScope', 'educacao.common.$commons', '$popoverInstance',
        'educacao.matricula.MatriculaService'];

    function MatriculaHistoricoPopoverController($rootScope, $commons, $popoverInstance, MatriculaService) {

        var vm = this;

        var hasBroadcast;

        vm.matricula = $popoverInstance.params.matricula;

        vm.openHistoricoModal = openHistoricoModal;

        init();

        function init() {
            var promise = MatriculaService.getHistoricos(vm.matricula.id, {
                offset: 0,
                limit: 3
            }).then(function(page) {
                vm.historicos = page.content;

                $commons.$timeout(function() {
                    $popoverInstance.position();
                });
                if (!hasBroadcast) {
                    setBroadcast();
                }
            });

            $popoverInstance.tracker.addPromise(promise);
        }

        function openHistoricoModal() {
            $commons.$modal.open({
                templateUrl: 'historico/historico-modal.html',
                controller: 'educacao.historico.HistoricoModalController',
                controllerAs: 'vm',
                size: 'lg',
                params: {
                    title: 'Histórico da matrícula',
                    url: 'matricula/matriculas/' + vm.matricula.id + '/historicos'
                }
            });
        }

        function setBroadcast() {
            hasBroadcast = true;
            $rootScope.$on('refresh-historico-matricula-' + _.get(vm.matricula, 'id'), function() {
                init();
            });
        }

    }
})();

(function() {
    'use strict';

    angular.module('educacao.pessoas').directive('appDeficiencia', SelectDeficiencia);

    function SelectDeficiencia() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add"/>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                lista: '='
            },
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }

                return {
                    post: function($scope) {
                        $scope.multiple = multiple;
                    }
                };
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.pessoas.DeficienciaService', 'bfc.$$PermissionsService'];

    function Controller($scope, uiModal, service, PermissionsService) {

        var vm = this;

        vm.search = search;
        vm.add = !PermissionsService.isRevokedOperation('DeficienciaPageMapping', 'criar') && add;

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'planejamento/deficiencia/deficiencias.cad.html',
                controller: 'educacao.pessoas.DeficienciasCadCtrl',
                controllerAs: 'vm',
                params: {
                    deficiencia: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }

        function search(params) {
            var filter = '(descricao like "' + params.term + '"';
            if (angular.isArray($scope.lista) && $scope.lista.length > 0 && $scope.lista.length) {
                var deficiencias = [];

                deficiencias = consultaRegraDeficiencia(deficiencias);

                if (deficiencias.length) {
                    filter += ' and id not in(' + deficiencias.join() + ')';
                }
            }
            filter += ')';
            return service.list(filter, params.offset, params.limit);
        }

        function consultaRegraDeficiencia() {
            var CEGUEIRA = 1;
            var BAIXA_VISAO = 2;
            var SURDEZ = 3;
            var AUDITIVA = 4;
            var SURDOCEGUEIRA = 5;
            var VISAO_MONOCULAR = 8;
    
            var listaDeRestricoes = [];
    
            $scope.lista.forEach(function(data) {
                //inclui o que ja esta selecionado pra não repetir
                listaDeRestricoes.push(data.deficiencia.id);
    
                //Verifica regra especifica
                //Verifica Cegueira
                if (data.deficiencia.id === CEGUEIRA) {
                    listaDeRestricoes.push(BAIXA_VISAO);
                    listaDeRestricoes.push(SURDEZ);
                    listaDeRestricoes.push(SURDOCEGUEIRA);
                }
                // Verifica Baixa visão
                if (data.deficiencia.id === BAIXA_VISAO) {
                    listaDeRestricoes.push(CEGUEIRA);
                    listaDeRestricoes.push(SURDOCEGUEIRA);
                }
                if (data.deficiencia.id === SURDEZ) {
                    listaDeRestricoes.push(CEGUEIRA);
                    listaDeRestricoes.push(AUDITIVA);
                    listaDeRestricoes.push(SURDOCEGUEIRA);
                }
                if (data.deficiencia.id === AUDITIVA) {
                    listaDeRestricoes.push(SURDEZ);
                    listaDeRestricoes.push(SURDOCEGUEIRA);
                }
                if (data.deficiencia.id === SURDOCEGUEIRA) {
                    listaDeRestricoes.push(CEGUEIRA);
                    listaDeRestricoes.push(BAIXA_VISAO);
                    listaDeRestricoes.push(SURDEZ);
                    listaDeRestricoes.push(AUDITIVA);
                    listaDeRestricoes.push(VISAO_MONOCULAR);
                }
                if (data.deficiencia.id === VISAO_MONOCULAR) {
                    listaDeRestricoes.push(SURDOCEGUEIRA);
                }
            });
            //reduz a lista pra não haver itens repetidos
            return _.uniq(listaDeRestricoes);
        }
    }
})();


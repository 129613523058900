(function() {

    'use strict';

    angular.module('educacao.pessoas')
        .directive('appEquipeDiretivaDetalhe', appEquipeDiretivaDetalhe);


    function appEquipeDiretivaDetalhe() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/escolas/equipe-diretiva/app-equipe-diretiva-detalhe/app-equipe-diretiva-detalhe.directive.html',
            scope: {},
            bindToController: {
                idFuncionario: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.funcionario.FuncionarioMatriculaService',
        'promiseTracker',
    ];

    function Controller(FuncionarioMatriculaService, promiseTracker) {
        var vm = this;
        vm.tracker = promiseTracker();

        init();

        function init() {
            var promise = FuncionarioMatriculaService.getUnicoServidor(vm.idFuncionario);
            promise.then(function(data) {
                vm.detalhesFuncionario = data;
            }, function() {});

            vm.tracker.addPromise(promise);
        }
    }

})();

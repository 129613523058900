(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .directive('appResumoTurmaQuadroTurmaAlunos', appResumoTurmasQuadroTurmaAlunosDirective);

    function appResumoTurmasQuadroTurmaAlunosDirective() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/turma-alunos/resumo-turmas/app-resumo-turmas-quadro-turma-alunos.directive.html',
            scope: {},
            bindToController: {
                anoletivoselecionado: '=',
                estabelecimentoselecionado: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        'promiseTracker',
        'educacao.turma-alunos-quadro-informativo.GraficoTurmaAlunosService',
        '$scope'
    ];

    function Controller(ContextoAnoLetivo, ContextoEstabelecimento, QuadroFuncionariosTurmaAlunosService, promiseTracker, GraficoTurmaAlunosService, $scope) {
        var vm = this;
        vm.graficosTurmas = {};

        vm.loadingTracker = {
            modalidadesOferecidas: promiseTracker(),
            graficoTurmaPorTurnoMatutino: promiseTracker(),
            graficoTurmaPorTurnoVespertino: promiseTracker(),
            graficoTurmaPorTurnoNoturno: promiseTracker(),
            graficoTurmaPorTurnoIntegral: promiseTracker(),
            graficoTurmaPorModalidade: promiseTracker(),
            graficoTurmaPorEtapa: promiseTracker(),
            graficoTurmaEnsinoMedio: promiseTracker(),
            graficoTurmaEnsinoMedioEja: promiseTracker(),
            graficoTurmaEnsinoFundamental: promiseTracker(),
            graficoTurmaEnsinoFundamentalEja: promiseTracker(),
            graficoTurmaEnsinoFundamentalAceleracao: promiseTracker(),
            graficoTurmaEnsinoInfantil: promiseTracker(),
            graficoTurmaEnsinoMedioAceleracao: promiseTracker(),
            graficoTurmaEducacaoBasica: promiseTracker(),
            graficoTurmaEja: promiseTracker(),
            graficoTurmaAceleracao: promiseTracker(),
        };
        vm.paths = [
            'modalidade',
            'turnoMatutino',
            'turnoVespertino',
            'turnoNoturno',
            'turnoIntegral',
            'etapaEnsinoMedio',
            'etapaEnsinoMedioEja',
            'etapaEnsinoMedioAceleracao',
            'etapaEnsinoFundamental',
            'etapaEnsinoFundamentalEja',
            'etapaEnsinoFundamentalAceleracao',
            'etapaEnsinoInfantil',
            'educacaoBasica',
            'eja',
            'aceleracao',
        ];

        vm.anoLetivo = ContextoAnoLetivo.getAnoLetivo();
        vm.modalidadesOferecidas = null;


        $scope.$watch('vm.anoletivoselecionado', onChangeContextAnoLetivo);
        $scope.$watch('vm.estabelecimentoselecionado', onChangeContextEstabelecimento);
        init();

        function onChangeContextAnoLetivo(oldValue, newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }

        function onChangeContextEstabelecimento(oldValue, newValue) {
            if (oldValue === null || (_.get(oldValue, 'id') === _.get(newValue, 'id'))) {
                return;
            }
            reloadValues();
        }

        function reloadValues() {
            GraficoTurmaAlunosService.clearValues();
            _.forEach(vm.paths, clearValue);
            init();
        }

        function resolveParamns() {
            var paramns = {};
            paramns.anoLetivoId = _.get(vm.anoletivoselecionado, 'id');
            paramns.estabelecimentoId = _.get(vm.estabelecimentoselecionado, 'id');
            return paramns;
        }

        function clearValue(path) {
            vm.graficosTurmas[path] = null;
        }


        function init() {
            getModalidadesOferecidas();
            getGraficoTurma('modalidade', 'graficoTurmaPorModalidade', 'getGraficoTurmasPorModalidade');
            getGraficoTurma('turnoMatutino', 'graficoTurmaPorTurnoMatutino', 'getGraficoTurmasPorTurnoMatutino');
            getGraficoTurma('turnoVespertino', 'graficoTurmaPorTurnoVespertino', 'getGraficoTurmasPorTurnoVespertino');
            getGraficoTurma('turnoNoturno', 'graficoTurmaPorTurnoNoturno', 'getGraficoTurmasPorTurnoNoturno');
            getGraficoTurma('turnoIntegral', 'graficoTurmaPorTurnoIntegral', 'getGraficoTurmasPorTurnoIntegral');
            getGraficoTurma('etapaEnsinoMedio', 'graficoTurmaEnsinoMedio', 'getGraficoTurmasEnsinoMedio');
            getGraficoTurma('etapaEnsinoMedioEja', 'graficoTurmaEnsinoMedioEja', 'getGraficoTurmasEnsinoMedioEja');
            getGraficoTurma('etapaEnsinoMedioAceleracao', 'graficoTurmaEnsinoMedioAceleracao', 'getGraficoTurmasEnsinoMedioAceleracao');
            getGraficoTurma('etapaEnsinoFundamental', 'graficoTurmaEnsinoFundamental', 'getGraficoTurmasEnsinoFundamental');
            getGraficoTurma('etapaEnsinoFundamentalEja', 'graficoTurmaEnsinoFundamentalEja', 'getGraficoTurmasEnsinoFundamentalEja');
            getGraficoTurma('etapaEnsinoFundamentalAceleracao', 'graficoTurmaEnsinoFundamentalAceleracao', 'getGraficoTurmasEnsinoFundamentalAceleracao');
            getGraficoTurma('etapaEnsinoInfantil', 'graficoTurmaEnsinoInfantil', 'getGraficoTurmasEnsinoInfantil');
            getGraficoTurma('educacaoBasica', 'graficoTurmaEducacaoBasica', 'getGraficoTotalTurmasEducacaoBasicaPorNivelEscolar');
            getGraficoTurma('eja', 'graficoTurmaEja', 'getGraficoTotalTurmasEjaPorNivelEscolar');
            getGraficoTurma('aceleracao', 'graficoTurmaAceleracao', 'getGraficoTotalTurmasAceleracaoPorNivelEscolar');
        }


        function getGraficoTurma(path, trackerName, serviceName) {

            var promise = GraficoTurmaAlunosService.getGraficoTurma(path, serviceName, resolveParamns())
                .then(function (data) {
                    vm.graficosTurmas[path] = data;
                });
            vm.loadingTracker[trackerName].addPromise(promise);
        }

        function getModalidadesOferecidas() {
            var promise = QuadroFuncionariosTurmaAlunosService.getModalidadesOferecidas(resolveParamns()).then(function (data) {
                vm.modalidadesOferecidas = data;
            });
            vm.loadingTracker.modalidadesOferecidas.addPromise(promise);

        }

    }

})();
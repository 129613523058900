(function() {
    'use strict';

    angular
        .module('educacao.context')
        .service('educacao.context.UserAccessService', service)
        .run(watchContexts);

    service.$inject = ['UserAccountsService', 'UserAccessContext', 'bfc.$$PermissionsService'];

    function service(UserAccountsService, UserAccessContext, PermissionsService) {
        var promiseListUserAccess;

        return {
            removeUserAccess: removeUserAccess,
            getUserAccess: getUserAccess,
            setUserAccess: setUserAccess,
            getHashAcesso: getHashAcesso
        };

        function removeUserAccess() {
            UserAccessContext.remove();
        }

        function getUserAccess() {
            if (!promiseListUserAccess) {
                promiseListUserAccess =
                    UserAccountsService.myAccess()
                    .then(removeAcessosErrados);
            }
            return promiseListUserAccess;
        }

        function removeAcessosErrados(acessos) {
            return acessos.filter(function(acesso) {
                //  acesso.id2 = acesso.licenseId
                return !_.get(acesso, 'values.databaseId') && acesso.licenseId;
            });
        }

        function setUserAccess(contextoEntidade, contextoEstabelecimento) {

            UserAccessContext.remove();

            return getUserAccess()
                .then(function(list) {
                    var entity = String(contextoEntidade.getValorContextoEntidade());
                    var estabelecimento;

                    var idEstabelecimento = contextoEstabelecimento.getValorContextoEstabelecimento();
                    if (idEstabelecimento && !contextoEstabelecimento.isSecretaria()) {
                        estabelecimento = String(idEstabelecimento);
                    }

                    var acesso = _.find(list, function(acs) {
                        return acs.values.entity === entity && (estabelecimento ? acs.values.estabelecimento ===
                            estabelecimento : !acs.values.estabelecimento);
                    });

                    if (acesso) {
                        UserAccessContext.setUserAccess(acesso);
                    } else {
                        UserAccessContext.remove();
                    }
                })
                .then(PermissionsService.$$clearPermissions);
        }

        function getHashAcesso() {
            return _.get(UserAccessContext.getUserAccess(), 'context');
        }
    }

    watchContexts.$inject = [
        '$rootScope',
        'educacao.context.UserAccessService',
        'educacao.context.ContextoEntidade',
        'educacao.context.ContextoEstabelecimento',
        'educacao.context.ContextoAnoLetivo'
    ];

    function watchContexts($rootScope, UserAccessService, ContextoEntidade, ContextoEstabelecimento, ContextoAnoLetivo) {
        $rootScope.$on('app-context:entidade:new', clearAllContexts);
        $rootScope.$on('app-context:estabelecimento:new', novoEstabelecimento);
        $rootScope.$on('app-context:estabelecimento:remove', novoEstabelecimento);

        function clearAllContexts() {
            UserAccessService.removeUserAccess();
            ContextoEstabelecimento.setEstabelecimento(null);
            ContextoAnoLetivo.setAnoLetivo(null);
        }

        function novoEstabelecimento() {
            UserAccessService.setUserAccess(ContextoEntidade, ContextoEstabelecimento);
        }
    }
})();


(function() {
	'use strict';

	angular.module('educacao.feriado')
		.directive('appSelectFeriado', SelectFeriado);

	function SelectFeriado() {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" add-value="vm.add" format-result="vm.formatResultFeriado(data)" format-selection=vm.formatSelectionFeriado(data)></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngRequired: '=?',
				lista: '=?'
			},
			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = ['$scope', 'ui.components.Modal', 'educacao.feriado.FeriadoService', 'bfc.$$PermissionsService'];

	function Controller($scope, $modal, service, PermissionsService) {

		var vm = this;

		vm.search = search;
		vm.formatResultFeriado = formatResultFeriado;
		vm.formatSelectionFeriado = formatSelectionFeriado;
		vm.add = !PermissionsService.isRevokedOperation('CadastroFeriadoPageMapping', 'criar') && add;

		function search(params) {
			var filter = '(descricao like "' + params.term + '")';
			return service.list(filter, params.offset, params.limit);
		}

		function add(descricao) {
			return $modal.open({
				templateUrl: 'feriados/feriados.cad.html',
				controller: 'educacao.feriado.FeriadosCadCtrl',
				controllerAs: 'vm',
				params: {
					feriado: {
						descricao: descricao
					}
				}
			}).result;
		}

		function formatResultFeriado(data) {
			return data.descricao + ' (' + moment(data.dataFeriado).format('DD/MM/YYYY') + ')';
		}

		function formatSelectionFeriado(data) {
			return data.descricao + ' (' + moment(data.dataFeriado).format('DD/MM/YYYY') + ')';
		}
	}
})();


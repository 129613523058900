(function () {

    /**
     * Diretiva para encapsular conteúdo da aba substituições ao funcionário
     * Parâmetros:
     * matricula -> matricula que está sendo adicionada ou editada
     */

    'use strict';

    angular.module('educacao.funcionario')
        .directive('appTabFuncionarioMatriculaSubstituicoes', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/funcionarios/funcionario/tabs/funcionario-matricula/app-tab-funcionario-matricula-substituicoes.directive.html',
            scope: {},
            bindToController: {
                matricula: '=',
                locaisTrabalhoSubstituicao: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['orderByFilter', '$scope'];

    function Controller(orderBy, $scope) {
        var vm = this;

        var ITERATE_TIPO_OPERACAO_SUM = 'sum';
        var ITERATE_TIPO_OPERACAO_SUB = 'sub';
        var SECRETARIA = {
            id: 'Secretaria',
            descricao: 'Secretaria',
            isSecretaria: true
        };
        
        vm.checarLocaisAtivos = checarLocaisAtivos;
        vm.adicionarLinhaRegistroSubsituicao = adicionarLinhaRegistroSubsituicao;
        vm.adicionarLinhaAulasSubstituidas = adicionarLinhaAulasSubstituidas;
        vm.removerLinha = removerLinha;
        vm.calculaCargaHorariaRestante = calculaCargaHorariaRestante;
        vm.changeShowAulasPersonalizado = changeShowAulasPersonalizado;
        vm.validaCargaHorariaFuncionario = validaCargaHorariaFuncionario;
        vm.iterateCampoNumerico = iterateCampoNumerico;
        vm.getTitleFuncionarioText = getTitleFuncionarioText;
        vm.getTitleEstabelecimentoText = getTitleEstabelecimentoText;
        vm.getTitleDataText = getTitleDataText;


        vm.operacaoSum = ITERATE_TIPO_OPERACAO_SUM;
        vm.operacaoSub = ITERATE_TIPO_OPERACAO_SUB;
        vm.CAMPO_QNTD_MIN_SUBSTITUIDOS = 'qntdMinutosSubstituidos';
        vm.CAMPO_QNTD_HORAS_SUBSTITUIDAS = 'qntdHorasSubstituidas';
        vm.CAMPO_DURACAO_AULA = 'duracaoAula';
        vm.CAMPO_QUANTIDADE_AULAS = 'quantidadeAulas';

        $scope.$watch('vm.matricula.locaisTrabalho', checarLocaisAtivos, true);

        init();

        function init() {
            vm.matricula.funcionarioSubstituto = orderBy(vm.matricula.funcionarioSubstituto, 'dataFinal', true);
            vm.funcionarioSubstituto = vm.matricula.funcionarioSubstituto;

            if (!vm.matricula.funcionarioSubstituto) {
                vm.matricula.funcionarioSubstituto = [];
                vm.funcionarioSubstituto = [];
            }
            
            vm.funcionarioSubstituto.map(function (funcionario) {
                if (!funcionario.estabelecimento) {
                    funcionario.estabelecimento = SECRETARIA;
                }
            });
        }

        function adicionarLinhaRegistroSubsituicao(list) {
            list.push({
                aulasSubstituidas: [{ duracaoAula: null, quantidadeAulas: null }],
                dataFinal: null,
                dataInicial: null,
                qntdHorasSubstituidas: null,
                qntdMinutosSubstituidos: null,
                estabelecimento: null,
                funcionario: { id: vm.matricula.id },
                substituto: null,
                motivo: '',
                utilizaHorasPersonalizadas: false,
                $$open: false,
                $$canSave: true
            });
        }

        function adicionarLinhaAulasSubstituidas(list) {
            list.push({duracaoAula: null, quantidadeAulas: null});
        }

        function removerLinha(list, index) {
            list.splice(index, 1);
        }

        function checarLocaisAtivos(locais) {
            vm.locaisDeTrabalhoAtivos = locais.filter(function(localDeTrabalho) {
                return validaCamposLocalDeTrabalhoPreenchidos(localDeTrabalho) && validaDataFinalLocalDeTrabalho(localDeTrabalho.dataFinal);
            });

            vm.locaisTrabalhoSubstituicao = vm.locaisDeTrabalhoAtivos;
            separaIdsEstabelecimentosLocaisAtivos(vm.locaisDeTrabalhoAtivos);
        }

        function separaIdsEstabelecimentosLocaisAtivos(estabelecimentos) {
            vm.idsLocaisDeTrabalhoAtivos = [];
            estabelecimentos.forEach(function(estabelecimento) {
                vm.idsLocaisDeTrabalhoAtivos.push(estabelecimento.estabelecimento.id);
            });
        }

        function validaCamposLocalDeTrabalhoPreenchidos(localDeTrabalho) {
            return localDeTrabalho.estabelecimento !== undefined &&
                localDeTrabalho.cargaHorariaSemanalHoras !== undefined && 
                localDeTrabalho.cargaHorariaSemanalMinutos !== undefined;
        }

        function validaDataFinalLocalDeTrabalho(data) {
            return _.isEmpty(data) || data >= moment(new Date()).format('YYYY-MM-DD');
        }

        function validaCargaHorariaFuncionario(funcionario) {
            if (_.isEmpty(funcionario.estabelecimento)) {
                return;
            }

            funcionario.qntdHorasSubstituidas = parseInt(funcionario.qntdHorasSubstituidas);
            funcionario.qntdMinutosSubstituidos = parseInt(funcionario.qntdMinutosSubstituidos);

            var localDeTrabalho = funcionario.estabelecimento.id === SECRETARIA.id ? 
                _.find(vm.locaisDeTrabalhoAtivos, 'estabelecimento.descricao', SECRETARIA.descricao) 
                : _.find(vm.locaisDeTrabalhoAtivos, 'estabelecimento.id', funcionario.estabelecimento.id);

            if (!localDeTrabalho) {
                return;
            }

            calculaCargaHorariaRestante(localDeTrabalho);
        }

        function calculaCargaHorariaRestante(localDeTrabalho) {
            var estabelecimento = localDeTrabalho.estabelecimento;
            var cargaHorariaHoras = localDeTrabalho.cargaHorariaSemanalHoras;
            var cargaHorariaMinutos = localDeTrabalho.cargaHorariaSemanalMinutos;

            var filterFuncionarioPorEstabelecimentoFunction = filterFuncionarioPorEstabelecimento.bind(null, estabelecimento);

            var cargaHorariaFuncionariosPorEstabelecimentoDuration = _.chain(vm.funcionarioSubstituto)
                                            .filter(filterFuncionarioPorEstabelecimentoFunction)
                                            .map(parseCargaHorariaFuncionario)
                                            .reduce(sumCargaHorariaFuncionario, moment.duration(0))
                                            .value();

            var cargaHorariaEstabelecimento = moment.duration(cargaHorariaHoras, 'hours').add(cargaHorariaMinutos, 'minutes');

            var cargaHorariaRestante = cargaHorariaEstabelecimento.subtract(cargaHorariaFuncionariosPorEstabelecimentoDuration);

            if (cargaHorariaRestante.asMinutes() < 0) {
                localDeTrabalho.$$canSave = false;
                return '-';
            }

            localDeTrabalho.$$canSave = true;
            return formatDuration(cargaHorariaRestante);
        }

        function filterFuncionarioPorEstabelecimento(estabelecimento, funcionario) {
            if (_.isNull(funcionario.estabelecimento) || _.isUndefined(funcionario.estabelecimento)) {
                return;
            }

            if (funcionario.estabelecimento.id === SECRETARIA.id) {
                return estabelecimento.isSecretaria;
            }

            return funcionario.estabelecimento.id === estabelecimento.id;
        }

        function parseCargaHorariaFuncionario(funcionario) {
            if (validaCamposRegistroSubstituicaoNaoPreenchidos(funcionario) || validaDataFinalRegistroSubstituicao(funcionario.dataFinal)) {
                return moment.duration(0);
            }

            var cargaHorariaSubstituidaPorFuncionario = moment.duration(funcionario.qntdHorasSubstituidas, 'hours');
            return cargaHorariaSubstituidaPorFuncionario.add(funcionario.qntdMinutosSubstituidos, 'minutes');
        }

        function sumCargaHorariaFuncionario(acumulador, cargaHorariaDuration) {
            return acumulador.add(cargaHorariaDuration);
        }

        function formatDuration(duration) {
            return _.floor(duration.asHours()) + 'h ' + duration.minutes() + 'm';
        }

        function validaCamposRegistroSubstituicaoNaoPreenchidos(funcionario) {
            return funcionario.qntdHorasSubstituidas === undefined || funcionario.qntdMinutosSubstituidos === undefined;
        }

        function validaDataFinalRegistroSubstituicao(data) {
            return !_.isEmpty(data) && data < moment(new Date()).format('YYYY-MM-DD');
        }

        function changeShowAulasPersonalizado(funcionarioSubstituto) {
            funcionarioSubstituto.$$open = !funcionarioSubstituto.$$open;
        }

        function iterateCampoNumerico(objeto, campo, valorLimiteNumerico, operacao, funcionario) {
            if(naoIteraCampoNumerico(campo, funcionario)){
                return;
            }

            if (operacao === ITERATE_TIPO_OPERACAO_SUM) {
                objeto[campo] = objeto[campo] < valorLimiteNumerico ? objeto[campo] * 1 + 1 : objeto[campo];
                return;
            }
            objeto[campo] = objeto[campo] > valorLimiteNumerico ? objeto[campo] * 1 - 1 : objeto[campo];
        }

        function naoIteraCampoNumerico(campo, funcionario) {
            return  (campo === vm.CAMPO_DURACAO_AULA ||  campo === vm.CAMPO_QUANTIDADE_AULAS) &&
                    !_.isEmpty(funcionario) && 
                    funcionario.aulasSubstituidas.length <= 1 && 
                    funcionario.$$open;
        }

        function getTitleFuncionarioText(funcionario) {
            if(!funcionario || !funcionario.substituto) {
                return '';
            }

            if (funcionario.substituto.nomeFuncionario) {
                return funcionario.substituto.matricula + ' - ' + funcionario.substituto.nomeFuncionario;
            }

            return funcionario.substituto.matricula + ' - ' + funcionario.substituto.servidor.nome;
        }

        function getTitleEstabelecimentoText(funcionario) {
            if(!funcionario || !funcionario.estabelecimento) {
                return '';
            }

            return funcionario.estabelecimento.descricao || funcionario.estabelecimento.nome || (funcionario.estabelecimento.pessoa ? funcionario.estabelecimento.pessoa.nome : '');
        }
    
        function getTitleDataText(data) {
            if(!data) {
                return '';
            }

            return moment(data).format('DD-MM-YYYY');
        }
    }
})();

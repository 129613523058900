/*(function() {

    'use strict';

    angular.module('educacao.diarioclasse')
            .factory('educacao.diarioClasse.DiarioClassePeriodoSelect', [
                'educacao.common.ObjectUtilsService',
                'educacao.diarioClasse.DiarioService',
                'bfc.Select2Config',
                '$filter',
                function(
                        ObjectUtilsService,
                        DiarioService,
                        Select2Config,
                        $filter
                        ) {
                    var format = 'dd/MM/yyyy';
                    return {
                        select2Config: function(options) {
                            return Select2Config.create(angular.extend({
                                result: function(params, callback) {
                                    var term = ObjectUtilsService.normalizeFilter(params.term);
                                    var result,
                                            filter = '(descricao like "' + term + '")';
                                    result = DiarioService.getPeriodos(filter);
                                    result.then(function(data) {
                                        callback({
                                            results: data,
                                            more: false
                                        });
                                    });
                                    return result;
                                },                                
                                formatValue: function(data) {
                                    return data;
                                },
                                formatResult: function(data) {
                                    return data.text ?
                                            data.text :
                                            data.descricao + ' (' + $filter('date')(data.dataInicial, format) + ' até ' + $filter('date')(data.dataFinal, format) + ')';
                                },
                                formatSelection: function(data) {
                                    return data.descricao + ' (' + $filter('date')(data.dataInicial, format) + ' até ' + $filter('date')(data.dataFinal, format) + ')';
                                }
                            }, options));
                        }
                    };
                }
            ]);
})();*/
(function() {
    'use strict';

    angular.module('educacao.funcionario')
        .directive('appSelectFuncionarioSubstituto', SelectFuncionarioSubstitutoDirective);

    function SelectFuncionarioSubstitutoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" ng-disabled="ngDisabled" format-result="vm.formatResult(data)" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?'
            },
            controller: SelectFuncionarioSubstitutoDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectFuncionarioSubstitutoDirectiveController.$inject = [
        'educacao.funcionario.FuncionarioMatriculaService'
    ];

    function SelectFuncionarioSubstitutoDirectiveController(FuncionarioMatriculaService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = '';

            filter += '( nome like "' +  params.term + '" )';

            var objParams = {
                filter: filter,
                offset: params.offset,
                limit: params.limit
            };

            return FuncionarioMatriculaService.getFuncionariosSubstitutos(objParams).then(toPage);
        }

        function formatResult(data) {
            if (data.nomeFuncionario) {
                return data.matricula + ' - ' + data.nomeFuncionario;
            }

            return data.matricula + ' - ' + data.servidor.nome;
        }

        function toPage(list) {
            return {
                content: _.first(_.pluck(list, 'content')),
                hasMore: list.hasNext
            };
        }
    }
})();

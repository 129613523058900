(function () {
    'use strict';

    angular.module('educacao.matricula')

    /* ETAPAS DE ENSINO INEP MULTISSERIADAS OU UNIFICADAS 
    (TABELA PADRÃO INEP (ETAPAS_ENSINOS_INEP))
    3	Educação Infantil - Unificada (0 a 5 anos)
    22	Ensino Fundamental de 9 anos - Multi
    23	Ensino Fundamental de 9 anos - Correção de Fluxo
    56	Educação Infantil e Ensino Fundamental (8 e 9 anos) Multietapa
    64	Curso Técnico Misto
    72	EJA - Ensino Fundamental  - Anos iniciais e Anos finais
    */
    .constant('ETAPAS_INEP_TURMAS_MULT', {
        CODIGO_INEP: [3, 22, 23, 56, 64, 72]
    });
})();
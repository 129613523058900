(function() {
    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.TipoDocumentoCadCtrl', Controller);

    Controller.$inject = [
        'promiseTracker',
        '$q',
        '$modalInstance',
        'educacao.matricula.TipoDocumentoService',
        'bfc.Focus'
    ];

    function Controller(promiseTracker,
        $q,
        $modalInstance,
        TipoDocumentoService,
        focus) {


        var vm = this;

        vm.editando = _.get($modalInstance.params.tipoDocumento, 'id');
        vm.tipoDocumento = $modalInstance.params.tipoDocumento || {};
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();
        vm.save = save;
        vm.remove = remove;
        vm.windowTitle = vm.editando ? 'Editando tipo de documento' : 'Adicionando tipo de documento';


        init();

        function init() {
            $modalInstance.tracker.addPromise(
                $q.when(
                    refresh()
                )
            );
            focus('lbDescricaoDoc');
        }

        function refresh() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
            if (vm.tipoDocumento.id) {
                TipoDocumentoService.getDefault();
            }
        }

        function save(continuar) {
            vm.tracker.loading.addPromise(
                TipoDocumentoService.save(vm.tipoDocumento).then(function(data) {
                    vm.documento = data;
                    onSuccess(continuar, data);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                TipoDocumentoService.remove(vm.tipoDocumento.id).then(function() {
                    $modalInstance.close();
                })
            );
        }


        function onSuccess(continuar, data) {
            if (continuar) {
                vm.tracker.loading.addPromise(refresh());
            } else {
                $modalInstance.close(data);
            }
        }
    }
})();

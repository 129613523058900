(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.anotacoes')
        .service('educacao.lista-espera-novo.anotacoes.AnotacaoInscricaoService', AnotacaoInscricao);

    AnotacaoInscricao.$inject = ['ServiceBuilder', 'Restangular'];

    function AnotacaoInscricao(ServiceBuilder, Restangular) {
        var service = ServiceBuilder.create()
            .path('lista-espera', 'anotacoes')
            .build();

        service.saveList = saveList;
        service.countAnotacoes = countAnotacoes;
        service.removeAnotacao = removeAnotacao;
        service.listAnotacoes = listAnotacoes;

        function saveList(anotacoes) {
            return Restangular.one('lista-espera')
                .one('anotacoes')
                .customPOST(anotacoes);
        }

        function countAnotacoes( inscricaoId) {
            return Restangular.one('lista-espera')
                .one('anotacoes/count', inscricaoId)
                .get();
        }

        function listAnotacoes( params) {
            return Restangular.one('lista-espera')
                .all('anotacoes')
                .get('', params)
                .then(strip);
        }

        function removeAnotacao(anotacaoId) {
            return Restangular.one('lista-espera')
                .all('anotacoes', anotacaoId)
                .customDELETE(anotacaoId);
        }

        function strip(data) {
            return Restangular.stripRestangular(data);
        }

        return service;
    }

})();

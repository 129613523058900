(function() {

    'use strict';

    angular.module('educacao.programasocial')
        .directive('appSelectProgramaSocial', SelectProgramaSocialDirective);

    function SelectProgramaSocialDirective() {
        return {
            restrict: 'E',
            templateUrl: 'programa-social/app-select-programa-social/app-select-programa-social-template.html',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                programasSociaisExistentes: '=?',
                ngChange: '&?'
            },
            controller: SelectProgramaSocialDirectiveController,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }
                if (angular.isDefined(tAttrs.ngRequired)) {
                    element.children('ui-select').attr('not-empty', 'ngRequired');
                }

                return {
                    post: function(scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectProgramaSocialDirectiveController.$inject = [
	    '$scope',
	    'ui.components.Modal',
	    'bfc.$$PermissionsService',
	    'educacao.programasocial.ProgramaSocialService'
    ];

    function SelectProgramaSocialDirectiveController($scope, uiModal, PermissionsService, ProgramaSocialService) {

        var vm = this;

        vm.searchProgramaSocial = searchProgramaSocial;
        vm.add = !PermissionsService.isRevokedOperation('ProgramaSocialPageMapping', 'criar') && add;
        vm.formatResult = formatResult;

        function searchProgramaSocial(params) {
            var filter = '(descricao like "' + params.term + '"';
            var programasSociais = [];

            if ($scope.multiple) {
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(programaSocial) {
                        if (programaSocial.programaSocial) {
                            programasSociais.push(programaSocial.programaSocial.id);
                        } else {
                            programasSociais.push(programaSocial.id);
                        }
                    });
                }
            }

            if (angular.isArray($scope.programasSociaisExistentes) && $scope.programasSociaisExistentes.length > 0) {
                _.forEach($scope.programasSociaisExistentes, function(value) {
                    if (value.programaSocial && value.programaSocial.id) {
                        programasSociais.push(value.programaSocial.id);
                    }
                });
            }

            if (programasSociais.length) {
                filter += ' and id not in(' + programasSociais.join() + ')';
            }
            filter += ')';

            return ProgramaSocialService.list(filter, params.offset, params.limit).then(function(data) {
                return {
                    content: data.content,
                    hasNext: false
                };
            });
        }

        function formatResult(data) {
            if (data.programaSocial) {
                return data.programaSocial.descricao;
            }

            return data.descricao;
        }

        function add(descricao) {
            return uiModal.open({
                templateUrl: 'programa-social/programa-social.cad.html',
                controller: 'educacao.programasocial.ProgramaSocialCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    programaSocial: {
                        descricao: descricao
                    },
                    canSaveAdd: false
                }
            }).result;
        }

    }

})();


(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('dadosProfessoresGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-professores-gestao-pessoas/dados-professores-gestao-pessoas.html',
            scope: {},
            bindToController: {
                anoLetivoSelected: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'promiseTracker'
    ];

    function Controller($scope, promiseTracker) {
        var vm = this;
        vm.MODALIDADE_ATIVIDADE = ['ATENDIMENTO_EDUCACIONAL_ESPECIALIZADO', 'ATIVIDADE_COMPLEMENTAR'];
        vm.SITUACOES = ['AFASTADO','TRABALHANDO'];

        vm.nomeProfessorFilter = null;
        vm.filtrarCargos = null;
        vm.filtrarSituacao = null;
        vm.advancedFilter = {};
        vm.mountFilter = mountFilter;
        vm.filter = null;
        vm.isRefresh = false;
        vm.refresh = refresh;
        vm.limparFiltros = limparFiltros;
        vm.search = search;
        vm.isNotEmptyFilter = isNotEmptyFilter;

        vm.tracker = {
            loading: promiseTracker(),
            init: promiseTracker()
        };

        vm.filterSituacaoFuncionario = filterSituacaoFuncionario;

        $scope.$watch('vm.nomeProfessorFilter', reloadClearFilter);
        $scope.$watch('vm.filtrarCargos', reloadClearFilter);
        $scope.$watch('vm.filtrarSituacao', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtrarTipoVinculo', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroComponenteCurricular', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroTurno', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroModalidadeEscolar', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroNivelEscolar', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroEstabelecimento', reloadClearFilter);
        $scope.$watch('vm.advancedFilter.filtroSubstituto', reloadClearFilter);

        function limparFiltros() {
            vm.nomeProfessorFilter = null;
            vm.filtrarCargos = null;
            vm.filtrarSituacao = null;
            vm.search();
        }


        function isNotEmptyFilter() {
            return !_.isEmpty(vm.nomeProfessorFilter) ||
                !_.isEmpty(vm.filtrarCargos) ||
                !_.isEmpty(vm.filtrarSituacao);
        }

        function reloadClearFilter(newValue) {
            if (newValue === null || _.isEmpty(newValue)) {
                mountFilter();
            }
        }

        function refresh() {
            vm.isRefresh = !vm.isRefresh;
        }

        function mountFilter() {
            var filters = [];

            if (!_.isEmpty(vm.nomeProfessorFilter)) {
                filters.push('(nome like "' + encodeURI('%' + vm.nomeProfessorFilter + '%') + '")');
            }

            if (!_.isEmpty(vm.filtrarCargos)) {
                filters.push('cargo in (' + _.map(vm.filtrarCargos, 'id').join(',') + ')');

            }
            if (!_.isEmpty(vm.filtrarSituacao)) {
                filters.push('situacao in ("' + vm.filtrarSituacao.join('","') + '")');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroComponenteCurricular)) {
                filters.push('componenteCurricularId in (' + _.map(vm.advancedFilter.filtroComponenteCurricular, 'componenteCurricularId').join(',') + ')');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroTurno)) {
                filters.push('turno in ("' + vm.advancedFilter.filtroTurno.join('","') + '")');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroModalidadeEscolar)) {
                var filterModalidade = [];
                var modalidadesNivel = vm.advancedFilter.filtroModalidadeEscolar.filter(function (modalidade) {
                    return !vm.MODALIDADE_ATIVIDADE.includes(modalidade);
                });
                var modalidadeAtividades = vm.advancedFilter.filtroModalidadeEscolar.filter(function (modalidade) {
                    return vm.MODALIDADE_ATIVIDADE.includes(modalidade);
                });

                if (!_.isEmpty(modalidadesNivel)) {
                    filterModalidade.push('nivelModalidade in ("' + modalidadesNivel.join('","') + '")');
                }

                if (!_.isEmpty(modalidadeAtividades)) {
                    filterModalidade.push('tipoAtendimento in ("' + modalidadeAtividades.join('","') + '")');
                }

                if (!_.isEmpty(filterModalidade)) {
                    filters.push('(' + filterModalidade.join(' or ') + ')');
                }
            }

            if (!_.isEmpty(vm.advancedFilter.filtroNivelEscolar)) {
                filters.push('nivelEscolar in ("' + vm.advancedFilter.filtroNivelEscolar.join('","') + '")');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroTipoVinculo)) {
                filters.push('tipoVinculo in ("' + vm.advancedFilter.filtroTipoVinculo.join('","') + '")');
            }

            if (!_.isEmpty(vm.advancedFilter.filtroEstabelecimento)) {
                var filterEstabelecimento = [];
                var idsEstabelecimento = _.map(vm.advancedFilter.filtroEstabelecimento, 'id');

                if (idsEstabelecimento.includes('Secretaria')) {
                    filterEstabelecimento.push('locaisTrabalho is null');
                }

                var idsNotSecretaria = idsEstabelecimento.filter(function (id) { return id !== 'Secretaria'; });

                if (!_.isEmpty(idsNotSecretaria)) {
                    filterEstabelecimento.push('locaisTrabalho in (' + idsNotSecretaria.join(',') + ')');
                }

                if (!_.isEmpty(filterEstabelecimento)) {
                    filters.push('(' + filterEstabelecimento.join(' or ') + ')');
                }
            }

            vm.filter = filters.join(' and ');
        }

        function search() {
            mountFilter();
            refresh();
        }

        function filterSituacaoFuncionario(value) {
            if (vm.SITUACOES.includes(value.id)) {
                return value;
            }
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('tableDadosAulasPorTempoGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-aulas-gestao-pessoas/table-dados-aulas-gestao-pessoas/table-dados-aulas-por-tempo-gestao-pessoas/table-dados-aulas-por-tempo-gestao-pessoas.directive.html',
            scope: {},
            bindToController: {
                saldos: '=',
                estabelecimentoSelected: '=',
                refresh:'='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.context.ContextoEstabelecimento',
        '$scope'
    ];

    function Controller(ContextoEstabelecimento,$scope) {
        var vm = this;

        vm.showTableEstabelecimento = showTableEstabelecimento;
        vm.isSecretaria = ContextoEstabelecimento.isSecretaria();
        vm.removerBorderTable = removerBorderTable;
        $scope.$watch('vm.refresh',init);
        vm.saldosPorDuracao = {};

        init();

        function init() {
            vm.saldosPorDuracao = {};
            vm.saldos.aulasPorDuracao.forEach(function (saldo) {
                if (!vm.saldosPorDuracao[saldo.tempo]) {
                    vm.saldosPorDuracao[saldo.tempo] = {
                        tempo: saldo.tempo,
                        totalAulas: 0,
                        totalAulasVagas: 0,
                        aulasPorTurma: []
                    };
                }

                vm.saldosPorDuracao[saldo.tempo].totalAulas += saldo.totalAulas;
                vm.saldosPorDuracao[saldo.tempo].totalAulasVagas += saldo.totalAulasVagas;
                vm.saldosPorDuracao[saldo.tempo].aulasPorTurma = vm.saldos.aulasPorTurmas.filter(function (aulaPorTurma) {
                    return aulaPorTurma.tempo === saldo.tempo;
                });
            });
        }

        function removerBorderTable($last, $first, $index, data) {
            if ($index === 0 && _.size(data) === 1) {
                return false;
            }
            return !$last || $first;
        }

        function showTableEstabelecimento() {
            return !vm.isSecretaria || _.get(vm.estabelecimentoSelected, 'id');
        }
    }
})();
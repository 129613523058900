(function() {
	'use strict';

	angular.module('educacao.matricula').directive('appSelectTurmaEnturmacao', SelectTurmaDirective);

	SelectTurmaDirective.$inject = [];

	function SelectTurmaDirective() {
		return {
			restrict: 'E',
			require: 'ngModel',
			template: '<ui-select ng-model="ngModel" ng-change="ngChange()" dropdown-css-class="dropdownCssClass" ngclass="ngclass" search="vm.search" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
			scope: {
				ngModel: '=',
				ngRequired: '=?',
				ngChange: '&',
				ngDisabled: '=?',
				professor: '=',
				formatResult: '=',
				tipo: '=',
				data: '=',
				dropdownCssClass: '=?',
				lista: '=?'
			},
			controller: SelectTurmaDirectiveController,
			controllerAs: 'vm',
			compile: function(element, tAttrs) {
				var multiple = angular.isDefined(tAttrs.multiple);
				if (multiple) {
					element.children('ui-select').attr('multiple', true);

					if (angular.isDefined(tAttrs.ngRequired)) {
						element.children('ui-select').attr('not-empty', 'ngRequired');
					}
				}

				return {
					post: function(scope) {
						scope.multiple = multiple;
					}
				};
			}
		};
	}

	SelectTurmaDirectiveController.$inject = ['$scope', 'educacao.matricula.EnturmacaoProfessorService'];

	function SelectTurmaDirectiveController($scope, turmaService) {

		var vm = this;

		vm.search = search;
		vm.formatResult = formatResult;

		function search(params) {

			var filter = '(turma.descricao like "' + params.term + '" and dataEncerramento is null';

			if ($scope.professor) {
				filter += ' and professor.id =' + $scope.professor;
			}

			if ($scope.tipo) {
				filter += ' and tipo = "' + $scope.tipo + '"';
			}

			if ($scope.data) {
				filter += ' and dataEnturmacao <= ' + $scope.data;
			}

			if ($scope.lista) {
				filter += ignoreId($scope.lista());
			}

			filter += ')';

			var paramsList = {
				filter: filter,
				offset: params.offset,
				limit: params.limit
			};

			return turmaService.getTurmas(paramsList);
		}

		function formatResult(turma) {
			return $scope.formatResult ? $scope.formatResult(turma) : turma.descricao;
		}

		function ignoreId(list) {
			var filter = '';
			var turmas = [];
			if (list) {
				list.forEach(function(turma) {
					if (_.get(turma, 'id')) {
						turmas.push(turma.id);
					}
				});
			}
			if (turmas.length) {
				filter += ' and turma not in(' + turmas.join() + ')';
			}
			return filter;
		}
	}
})();

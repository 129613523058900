(function () {
    'use strict';

    /**
     * Diretiva para seleção de pessoas
     * Parâmetros:
     * ngModel -> model que será vinculado à pessoa
     * ngRequired -> indica se o campo do nome da pessoa será obrigatório
     * onSelect -> função executada ao ser selecionada alguma pessoa, a pessoa é passada para a função
     * searchMethod -> método chamado ao executar a pesquisa
     * pessoaPath -> path do objeto de pessoa dentro de um objeto de retorno na lista.
     *      Ex: A pesquisa retorna uma lista de funcionários, logo: funcionario.pessoa.nome
     *              pessoaPath deve ser 'pessoa'.
     *          objDaListaDeRetorno.objIntermediario.pessoa.propriedade -> deve ser passado 'objIntermediario.pessoa'
     * endPoint -> endpoint do servico que vai usa a diretiva
     */

    angular.module('educacao.pessoas').directive('appSelectPessoa', SelectPessoaDirective);

    function SelectPessoaDirective() {
        return {
            restrict: 'E',
            templateUrl: 'planejamento/pessoa/app-select-pessoa/app-select-pessoa.directive.html',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                onSelect: '&',
                placeholder: '@',
                searchMethod: '=',
                pessoaPath: '@',
                endPoint: '='
            },
            controller: SelectPessoaDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectPessoaDirectiveController.$inject = [
        '$scope',
        'educacao.common.$commons',
        '$timeout',
        '$document'
    ];

    function SelectPessoaDirectiveController($scope, $commons, $timeout, $document) {

        var vm = this;
        vm.tracker = $commons.$promiseTracker();

        vm.search = search;
        vm.select = select;
        vm.closeList = closeList;
        vm.openList = openList;

        function search() {
            vm.pessoas = [];

            if ($scope.ngModel.pessoa !== null && _.get($scope.ngModel.pessoa, 'nome.length') >= 3) {
                vm.showAutoComplete = true;
                if(!_.isFunction($scope.searchMethod)){
                    return;
                }
                var promise = $scope.searchMethod($scope.ngModel.pessoa.nome,$scope.endPoint).then(function (pessoas) {
                    vm.pessoas = pessoas;
                    if (!vm.pessoas.length) {
                        vm.showAutoComplete = false;
                    }

                    vm.showRefineSearch = (vm.pessoas && vm.pessoas.length >= 25);
                });
                vm.tracker.addPromise(promise);
            }
            closeList();
        }

        function select(pessoa) {
            if ($scope.onSelect) {
                $scope.onSelect({
                    pessoa: pessoa
                });
            }
            vm.showAutoComplete = false;
        }

        function closeList() {
            $timeout(function () {
                if ($document[0].activeElement.id !== 'input-text-pessoa' && $document[0].activeElement.id !== 'list-pessoas') {
                    $($document[0]).ready(function () {
                        $timeout(function () {
                            $('#list-pessoas').fadeOut(0);
                        }, 100);
                        vm.showAutoComplete = false;
                    });
                }
            }, 200);
        }

        function openList() {
            if ($document[0].activeElement.id !== 'input-text-pessoa' && $document[0].activeElement.id !== 'list-pessoas') {

                $($document[0]).ready(function () {
                    $timeout(function () {
                        $('#list-pessoas').fadeIn(0);
                    }, 100);
                    vm.showAutoComplete = true;
                });
            }
        }
    }
})();

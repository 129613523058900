/* jshint ignore:start */
(function () {

    'use strict';

    angular.module('educacao.common')

        .directive('bfOpenframe', ['$location', 'educacao.context.ContextoEntidade', function($location, ContextoEntidade) {
            return {
              restrict: 'A',
              link: function(scope, element, attrs) {

                if (!window.name && window.parent.name !== 'root') {
                  window.name = 'root';
                }

                if (window.name !== 'root') {
                  document.body.classList.add('frame-main');
                }
                
                element.on('click', function(e) {
                    
                    if (!(e.ctrlKey && e.shiftKey)) {
                      return;
                    }
                    
                    e.preventDefault();
                    e.stopPropagation();
                    
                    var fromMenu = attrs.href.includes('#');
                    var entidade = ContextoEntidade.getEntidade();
                    var frameUrl =  fromMenu ? $location.absUrl().split('#')[0] + attrs.href : attrs.href;

                    console.log(frameUrl);

                    var iframe = document.createElement('iframe');
                    iframe.setAttribute('src', frameUrl);
                    iframe.setAttribute('class', 'frameContent');

                    var header = document.createElement('div');
                    header.setAttribute('class', 'frameHeader');
                    header.innerHTML = (fromMenu ? '<span title="Sistema">' + attrs.bfOpenframe + '</span> / <span title="Entidade">' + entidade.name + '</span>' : '<span title="Sistema">' + attrs.bfOpenframe + '</span>');
                      header.innerHTML += '<a class="frameAction frameClose" href="#"><i class="fa fa-fw fa-times"></i></a>';

                    var div = document.createElement('div');
                    div.setAttribute('class', 'frameBox');
                    
                    div.appendChild(header);
                    div.appendChild(iframe);

                    $(header).on('mousedown', function(event) {

                      if(event.target.parentNode.className.includes('frameClose')){
                        event.preventDefault();
                        $(div).draggable('destroy');
                        div.remove();
                        return;
                      }
                      
                      document.querySelectorAll('.frameBox').forEach(function(item) {
                        item.classList.remove('frametop');
                      });

                      div.classList.add('frametop');
                        
                    });
                    
                    $(div).draggable({
                        handle: 'div.frameHeader',
                        containment: 'document',
                        iframeFix: true,
                        opacity: 0.7
                    });

                    var level = document.querySelectorAll('.frameBox').length * 50;
                    div.style.top = div.style.left = (100 + level)+'px';

                    div.classList.add('frametop');
                    
                    document.body.appendChild(div);
                    
                });
              }
            };
          }]);

})();
/* jshint ignore:end */

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.EquipamentoCadCtrl', EquipamentoCadCtrl);

    EquipamentoCadCtrl.$inject = ['bfc.Focus', '$scope', '$modalInstance'];

    function EquipamentoCadCtrl(focus, $scope, $modalInstance) {
        var vm = this;

        vm.editando = false;
        vm.save = save;
        vm.remove = remove;
        vm.edit = edit;

         
        vm.estabelecimentosEquipamentos = $modalInstance.params.estabelecimentosEquipamentos || [];
        vm.estabelecimentoEquipamento = {};
        vm.enumEquipamento = $modalInstance.params.enumEquipamento;
        vm.editando = false;
        vm.save = save;
        vm.remove = remove;
        vm.edit = edit;
        vm.filterResult = filterResult;

        vm.sortableOptions = {
            placeholder: 'ui-card-sortable',
            cursor: 'move',
            axis: 'y',
            containment: '#ui-containment',
            forcePlaceholderSize: true,
            tolerance: 'pointer'
        };

        
        function filterResult(term, value) {
            
            if (value.description.toUpperCase().indexOf(term.toUpperCase().trim()) === -1) {
                return;
            }
            //var itens = vm.estabelecimentosEquipamentos.filter(item=>item.equipamento === value.key);
            var itens = vm.estabelecimentosEquipamentos.filter(function(item){
                return item.equipamento === value.key;
            });
            if(!itens.length){
                return value;
            }

        }

        function save(continuar) {
            if (vm.editando) {
                vm.estabelecimentosEquipamentos.splice(vm.indexToEdit, 1);
            }
            vm.estabelecimentosEquipamentos.push(angular.copy(vm.estabelecimentoEquipamento));
            if (!!vm.editando) {
                vm.editando = false;
            }
            if(continuar){
                clear();
                return;
            }
            $modalInstance.close();
        }
            
        function clear() {
            vm.estabelecimentoEquipamento.quantidade = null;
            vm.estabelecimentoEquipamento.equipamento = null;
            vm.indexToEdit = null;
            vm.form.$setPristine();
            vm.form.$setUntouched();
            focus('quantidade');
        }

        function remove(index) {
            vm.estabelecimentosEquipamentos.splice(index, 1);
        }

        function edit(index) {
            vm.estabelecimentoEquipamento = angular.copy(vm.estabelecimentosEquipamentos[index]);
            vm.indexToEdit = index;
            vm.editando = true;
        }

    }
})();

( function() {
    
    'use strict';
    
    angular.module('educacao.pessoas').controller('educacao.pessoas.EstabelecimentoAvaliacoesCtrl', EstabelecimentoAvaliacoesCtrl);
    
    EstabelecimentoAvaliacoesCtrl.$inject = ['promiseTracker','$injector'];
    
    function EstabelecimentoAvaliacoesCtrl(promiseTracker,$injector) {
        
        var EstabelecimentoService = $injector.get('educacao.pessoas.EstabelecimentoServiceDeprecated'),
        ModalCad = $injector.get('educacao.common.ModalCad');
           
        var vm = this;
        
        vm.tracker = {};
        vm.tracker.list = promiseTracker();
        vm.openCad = _openCad;
        
        _init();
        
        function _init() {
            
            _refresh();  
            
        }
        
        function _refresh() {
            vm.tracker.list.addPromise(
                EstabelecimentoService.getList(0, 999).then(function(data) {    
                    vm.avaliacoes = data.avaliacoes;
                })
            );            
        }
        
        function _openCad(id) {
			ModalCad.open({
				templateUrl: 'planejamento/escolas/avaliacoes-externas/escola.avaliacao-tab.cad.html',
				controller: 'educacao.pessoas.EstabelecimentoAvaliacoesCadCtrl as EstabAvalCadCtrl',
				id: id,
				readOnly: false
			});            
        }
                
        
    }
    
})();
(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.TipoAtivComplService', ['GenericService',
            function(GenericService) {
                return GenericService
                    .create()
                    .module({
                        uri: 'inep'
                    })
                    .service({
                        uri: 'tipo-atividade-complementar'
                    });
            }
        ])

    .factory('educacao.common.TipoAtivComplSelect', ['educacao.common.TipoAtivComplService',
        'educacao.common.CommonSelect',
        'educacao.common.ObjectUtilsService',
        function(
            TipoAtivComplService,
            CommonSelect,
            ObjectUtilsService
        ) {
            return {
                select2Config: function(options) {
                    return CommonSelect.select2Config([],
                        angular.extend(_.pick(TipoAtivComplService, '_modules', '_service', 'validateObject', 'modules', 'getList'), {
                            result: function(params, callback) {

                                var term = ObjectUtilsService.normalizeFilter(params.term);
                                var filter = '(descricao like "' + term + '")';

                                this.getList(params.offset, params.limit, filter, undefined).then(function(data) {
                                    callback({
                                        results: data,
                                        more: false
                                    });
                                });
                            }
                        }, {
                            multiple: true
                        }, options));
                }
            };
        }
    ]);
})();

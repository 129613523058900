(function() {
	'use strict';

	angular.module('educacao.matricula')
		.factory('educacao.matricula.ClassificacaoEncaminhadosService', ClassificacaoEncaminhadosService);

	ClassificacaoEncaminhadosService.$inject = ['$injector'];

	function ClassificacaoEncaminhadosService($injector) {
		var ServiceBuilder = $injector.get('ServiceBuilder');
		var $q = $injector.get('$q');

		var BASE_END_POINT = 'configuracao-inscricao';
		var END_POINT = 'chamada/resultado/encaminhar';

		var selfService = ServiceBuilder.create()
			.path('matricula')
			.build();

		selfService.encaminhar = encaminhar;
		selfService.listaEncaminhados = listaEncaminhados;
		selfService.desfazerEncaminhado = desfazerEncaminhado;
		selfService.getDefault = getDefault;

		return selfService;

		function getBaseEndPoint(idConfiguracao) {
			return selfService.getEndpoint().one(BASE_END_POINT, idConfiguracao);
		}

		function encaminhar(idConfiguracao, encaminhado) {
			return getBaseEndPoint(idConfiguracao).all(END_POINT).post(encaminhado);
		}

		function listaEncaminhados(idConfiguracao, params, nivel) {
			return getBaseEndPoint(idConfiguracao).one(END_POINT + '/completos').all(nivel).get('', params);
		}

		function desfazerEncaminhado(idConfiguracao, idResultado) {
			return getBaseEndPoint(idConfiguracao).one(END_POINT, idResultado).remove();
		}

		function getDefault(id) {
			return getMetadata(id).then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata(id) {
			var deferred = $q.defer();
			getBaseEndPoint(id).all(END_POINT).options().then(function(data) {
				deferred.resolve(data);
			}, function(data) {
				deferred.reject(data);
			});
			return deferred.promise;
		}

	}

})();

(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.matriz');

	moduloCommon.factory('educacao.matriz.MatrizPreRequisitoService', ['Restangular',
		function(Restangular) {

			return {
				
				save: function(matriz, data) {
					var rest = Restangular.one('matriz')
								.one('matriz-curricular', matriz)
								.all('pre-requisto');
                    return rest.customPUT(data, data.id);
				},
				remove: function(matriz, id) {
					return Restangular.one('matriz')
								.one('matriz-curricular', matriz)
								.one('pre-requisto', id)
								.remove();
				}
			};
		}
	]);
})();



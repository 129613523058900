(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('CriaMenusExecutando', factory);

    factory.$inject = ['mainMenu', 'MegaMenuItem'];

    function factory(mainMenu, MegaMenuItem) {
        return createMenus;

        function createMenus() {

            var configurationMenu = {
                style: {
                    'width': '550px',
                    'left': '-1px'
                },
                classCol: 'col-md-6',
                configurationColumns: [{
                    numberOfChildrens: 3
                }, {
                    numberOfChildrens: 2
                }]
            };

            var grupoExecutando = new MegaMenuItem('executando', 'Executando')
                .setParent(mainMenu)
                .setConfiguration(configurationMenu);

            criaGrupoMatricula();
            criaGrupoListaEspera();
            criaGrupoPraticaDocente();
            criaGrupoEnturmacao();
            criaGrupoApoioEducacional();
            criaGrupoEncerramento();
            criaGrupoArtefatos();
            criaGrupoExigenciaLegal();

            function criaGrupoMatricula() {
                new MegaMenuItem('Matrículas')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Matrículas').setAction('app.matricula.matriculas'))
                    //.addChildren(new MegaMenuItem('Listas de espera').setAction('app.matricula.listaEspera'))
                    .addChildren(new MegaMenuItem('Movimentos escolares').setAction('app.movimentoEscolar'));
            }

            function criaGrupoListaEspera() {
                var children = new MegaMenuItem('Lista de espera')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Configurações').setAction('app.lista-espera-novo.configuracao'))
                    .addChildren(new MegaMenuItem('Inscritos').setOperation('listar').setAction('app.lista-espera-novo.inscricao'))
                    .addChildren(new MegaMenuItem('Encaminhados para matrícula').setAction('app.lista-espera-novo.encaminhado'));


                new MegaMenuItem('Anotacoes')
                    .setParent(children)
                    .setAction('app.lista-espera-novo.anotacoes')
                    .setVisible(false);
            }

            function criaGrupoEncerramento() {
                new MegaMenuItem('Encerramento')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Rematrículas').setAction('app.rematricula'))
                    .addChildren(new MegaMenuItem('Encerramentos').setAction('app.encerramento'));
            }

            function criaGrupoPraticaDocente() {
                new MegaMenuItem('Prática docente')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Agenda dos professores').setAction('app.agendaprofessor'))
                    .addChildren(new MegaMenuItem('Frequências').setAction('app.frequencia'))
                    .addChildren(new MegaMenuItem('Desempenhos escolares').setAction('app.desempenhoescolar'));
            }

            function criaGrupoEnturmacao() {
                new MegaMenuItem('Enturmação e ensalamento')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Turmas').setAction('app.matricula.turma'))
                    .addChildren(new MegaMenuItem('Quadros de horários').setAction('app.geracaohorarios'))
                    .addChildren(new MegaMenuItem('Remanejamento interno')
                        .setPermission('app.matricula.remanejamentointerno')
                        .setAction(abreModalRemanejamento));
            }

            function criaGrupoApoioEducacional() {
                new MegaMenuItem('Apoio educacional')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Acompanhamentos pedagógicos').setAction('app.matricula.acompanhamentoPedagogico'))
                    .addChildren(new MegaMenuItem('Dispensas').setAction('app.matricula.dispensa'));
            }

            function criaGrupoArtefatos() {
                new MegaMenuItem('Artefatos')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Relatórios <kbd title="Pressione F4 para acessar este recurso.">F4</kbd>')
                    .setPermission('main.app.relatorios')
                    .setAction(abreModalRelatorios));
            }            
            
            function criaGrupoExigenciaLegal() {
                new MegaMenuItem('Exigência legal')
                    .setParent(grupoExecutando)
                    .addChildren(new MegaMenuItem('Censo Escolar').setAction('app.educacenso').setNew(true));
            } 
        }
    }

    abreModalRemanejamento.$inject = ['ui.components.Modal'];

    function abreModalRemanejamento(uiModal) {
        uiModal.open({
            templateUrl: 'matricula/enturmacao/remanejamento.cad.html',
            controller: 'educacao.matricula.RemanejamentoCtrl',
            controllerAs: 'vm',
            size: 'xl'
        });
    }

    abreModalRelatorios.$inject = ['rel.atalhos.execucao.ExecutandoService'];
        
    function abreModalRelatorios(executandoService) {
        executandoService.abrirJanela();
    }   

})();


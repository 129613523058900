(function() {

    'use strict';

    var INSCRITO = 'INSCRITO';
    var CANCELADO = 'CANCELADO';

    angular.module('educacao.lista-espera')
        .service('$listaEsperaItemService', ListaEsperaItemService);

    function ListaEsperaItemService() {
        var self = this;

        self.isCancelado = isCancelado;
        self.isInscrito = isInscrito;    
    
        function isInscrito(tipoItem) {
            return tipoItem === INSCRITO;
        }

        function isCancelado(tipoItem) {
            return tipoItem === CANCELADO;
        }
    }

})();

(function() {
	'use strict';

	angular.module('educacao.matricula')
		.factory('educacao.matricula.InscricaoMatriculaService', InscricaoMatriculaService);

	InscricaoMatriculaService.$inject = ['$injector'];

	function InscricaoMatriculaService($injector) {
        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('matricula','inscricao')
            .enum('Raca', 'CorOlhos', 'TipoSanguineo', 'HabilidadeMotora', 'OrientacaoSexual', 'EstadoCivil', 'GrauInstrucao', 'Sexo')
            .build();

        selfService.getProcessosVigentes = getProcessosVigentes;
        selfService.getCamposFormulario = getCamposFormulario;
        selfService.getCamposConfiguracao = getCamposConfiguracao;

        function getProcessosVigentes(){
            return selfService.getEndpoint().all('processos').getList();
        }

        function getCamposFormulario(){
            return selfService.getEndpoint().all('campos-formulario').all('todos').getList();
        }

        function getCamposConfiguracao(idConfiguracao){
            return selfService.getEndpoint().one('campos-formulario',idConfiguracao).getList();
        }

        return selfService;
    }
})();
(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .directive('dropdownFiltroAvancadoOutrosFuncionariosGestaoPessoas', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/gestao-pessoas/dados-outros-funcionarios-gestao-pessoas/dropdown-pesquisa-avancada-outros-funcionarios/dropdown-pesquisa-avancada-outros-funcionarios.html',
            scope: {},
            bindToController: {
                mountFilter: '&',
                filter: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;
        vm.limparFiltrosAvancados = limparFiltrosAvancados;

        vm.togglePesquisaAvancada = togglePesquisaAvancada;

        function togglePesquisaAvancada() {
            vm.showBottomSheet = !vm.showBottomSheet;
        }

        function limparFiltrosAvancados() {
            vm.filter.filtroFuncao = null;
            vm.filter.filtroCargoComissionado = null;
            vm.filter.filtroEstabelecimento = null;
            vm.filter.filtrarTipoVinculo = null;
        }

    }
})();
(function() {

    'use strict';

    angular.module('educacao.common')
        .service('educacao.common.TemplateService', TemplateService);

    TemplateService.$inject = ['Restangular'];

    function TemplateService(Restangular) {
        var self = this;

        self.cargaTemplate = cargaTemplate;

        function cargaTemplate(item) {
            return Restangular.one('template')
                .all('carga')
                .one(item)
                .post();
        }
    }
})();

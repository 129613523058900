(function () {

    'use strict';

    angular.module('educacao.common')
        .directive('appSelectInstituicaoEnsinoInep', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" dropdown-css-class="dropdownCssClass" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)"/>',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                tipoInstituicao: '=',
                ngChange: '&?',
                ngDisabled: '=?',
                dropdownCssClass: '=?'
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'educacao.common.InstituicaoEnsinoSuperiorInepService'
    ];

    function Controller($scope, InstituicaoEnsinoSuperiorInepService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function formatResult(data) {
            var descricao = [data.codigoInep, data.descricao, municipioText].filter(Boolean).join(' - ');
            if (!data.municipio || !data.statusFuncionamento){
                return descricao;
            }
            var municipioText = [data.municipio.nome, data.municipio.uf].filter(Boolean).join('-');
            return descricao +
                   ' (' + municipioText + ')' +
                   ' - ' + data.statusFuncionamento;
        }

        function search(params){

            var filter = '(descricao like "' + params.term + '"';
            
            var originalTerm = decodeURI(params.term).replace(/%/g, '').trim();
            if (originalTerm && Number.isInteger(Number(originalTerm))) {
                filter += ' or codigoInep = ' + originalTerm;
            }
            filter += ')';

            if($scope.tipoInstituicao){
                filter += ' and (tipoInstituicao = "' + $scope.tipoInstituicao + '" or tipoInstituicao is null)';
            }
            
            return InstituicaoEnsinoSuperiorInepService.list(filter, params.offset, params.limit);
        }
    }

})();
(function() {

    'use strict';

    angular.module('educacao.common')
        .directive('appHeaderFilterFrequenciaDesempenho', appHeaderFilterFrequenciaDesempenho);

    function appHeaderFilterFrequenciaDesempenho() {
        return {
            restrict: 'E',
            template: '<ed-header-filter options="vm.headerFilterOptions" models="vm.models" clear="vm.clear"></ed-header-filter>',
            scope: {},
            bindToController: {
                model: '=',
                headerFilterOptions: '=',
                models: '=',
                filter: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.matricula.TurmaService',
        'HeaderFilterBuilderService',
        '$scope',
        'promiseTracker'
    ];

    function Controller(TurmaService, HeaderFilterBuilderService, $scope, promiseTracker) {
        var vm = this;
        vm.clear = clear;
        vm.offset = 0;
        vm.filterTurma = '';
        vm.loadingTracker = promiseTracker();
        init();
        
        $scope.$watch('vm.headerFilterOptions.radiobox[0].filtro', _.debounce(applyFiltro, 500));

        function init() {
            vm.headerFilterOptions.clearOthers = clearOthers;
            vm.headerFilterOptions.clear = clear;
            TurmaService.getListFilter(null, null, null, 'descricao').then(createFilterturma);
        }

        function createFilterturma(data) {
            vm.hasNext = data.hasNext;
            vm.limit = data.limit;
            vm.total = data.total;
            vm.turmas = data.content;
            var options = {
                id: 'turmas',
                description: 'Turma',
                filtrable: true,
                onClick: onClickFilterTurma,
                formatDescription: getDescricaoTurma,
                pathValue: 'id',
                loadMore: loadMore,
                bfLoading: vm.loadingTracker
            };
            HeaderFilterBuilderService.createRadiobox(data.content, vm.headerFilterOptions, vm.models, options);
        }

        function clear() {
            clearOthers();
            _.set(vm, 'models', { checkbox: [], radiobox: [] });
            _.first(vm.headerFilterOptions.radiobox).subDescription = null;
            vm.model = null;
        }

        function clearOthers() {
            delete vm.models.checkbox;
            delete vm.headerFilterOptions.checkbox;
            _.set(vm.models, 'checkbox', []);
            _.set(vm.headerFilterOptions, 'checkbox', []);
            while (_.get(vm, 'models.radiobox.length') > 1) {
                vm.models.radiobox.pop();
            }
            while (_.get(vm, 'headerFilterOptions.radiobox.length') > 1) {
                vm.headerFilterOptions.radiobox.pop();
            }
        }

        function getDescricaoTurma(idTurma) {
            var turma = _.find(vm.turmas, { id: idTurma });
            return _.get(turma, 'descricao') + ' - ' + _.get(turma, 'estabelecimento.descricao') + ' - ' + _.get(turma,'turno').toLowerCase();
        }

        function onClickFilterTurma(value) {
            if (!value) {
                return clear();
            }
            clearOthers();
            vm.model = _.find(vm.turmas, { id: value });
        }

        function loadMore() {
            var radiobox = getRadioFilterTurma();
            if (!vm.hasNext || _.isEmpty(radiobox.options) || vm.loadingTurmas) {
                return;
            }
            vm.offset += vm.limit;
            if (vm.offset > vm.total) {
                return;
            }
            vm.loadingTurmas = true;
            loadTurmas();
        }

        function loadTurmas(filtering) {
            vm.loadingTracker.addPromise(
                TurmaService.getListFilter(vm.filterTurma, vm.offset, vm.limit, 'descricao')
                .then(function(value) {
                    vm.loadingTurmas = false;
                    addRegistrosTurma(value, filtering);
                })
            );
        }

        function addRegistrosTurma(data, filtering) {
            vm.hasNext = data.hasNext;
            vm.total = data.total;
            
            var radiobox = getRadioFilterTurma();
            if (filtering) {
                vm.turmas = [];
                radiobox.options = [];
            }

            vm.turmas = _.union(vm.turmas, data.content);

            _.forEach(data.content, function(value) {
                addValuesFilter(value, radiobox);
            });
            
        }

        function addValuesFilter(value, radiobox) {
            radiobox.options.push({
                description: _.get(value, 'descricao') + ' - ' + _.get(value, 'estabelecimento.descricao') + ' - ' + _.get(value, 'turno').toLowerCase(),
                value: value.id
            });
        }

       function applyFiltro(value) {
           var radiobox = getRadioFilterTurma();
           if (!radiobox) {
               return;
           }
           value = value ? encodeURI('%' + value + '%') : null;
           vm.filterTurma = value ? 'descricao like "' + value + '"' : '';
           vm.offset = 0;
           vm.turmas = [];
           radiobox.options = [];
           loadTurmas(true);
       }

       function getRadioFilterTurma() {
           return _.find(vm.headerFilterOptions.radiobox, { id: 'turmas' });
       }
    }
})();
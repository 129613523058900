(function () {
    'use strict';

    angular.module('educacao.lista-espera-novo.inscricao')
        .controller('educacao.lista-espera-novo.inscricao.InscricaoListaEsperaCadCtrl', InscricaoListaEsperaCadCtrl);

    InscricaoListaEsperaCadCtrl.$inject = [
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaService',
        'bfc.Notification',
        '$modalInstance',
        'promiseTracker',
        '$stateParams',
        'ui.components.Modal',
        'educacao.lista-espera-novo.inscricao.InscricaoListaEsperaUtils',
        'PASSOS_CADASTRO_INSCRICAO',
        'educacao.matricula.QuadroVagasService',
        'educacao.enderecos.BairroService',
        'educacao.enderecos.MunicipioService',
        'educacao.enderecos.EstadoService',
        '$enumsCache'
    ];

    function InscricaoListaEsperaCadCtrl(InscricaoListaEsperaService, bfcNotification, $modalInstance, promiseTracker, $stateParams, uiModal, InscricaoListaEsperaUtils, PASSOS_CADASTRO_INSCRICAO, QuadroVagasService, BairroService, MunicipioService, EstadoService, $enumsCache) {
        var vm = this;

        vm.passosCadastroInscricao = PASSOS_CADASTRO_INSCRICAO;
        vm.tracker = {};
        vm.tracker.vaga = promiseTracker();

        vm.BairroService = BairroService;
        vm.MunicipioService = MunicipioService;
        vm.EstadoService = EstadoService;
        vm.isCopiaAlunoMatriculado = $modalInstance.params.isCopiaAlunoMatriculado;
        vm.isAlunoInscricaoMatricula = $modalInstance.params.isAlunoInscricaoMatricula;
        vm.observacaoInscricao = $modalInstance.params.observacao;
        getConfiguracao()
            .then(getEnumDeficiencia)
            .then(_.bind(ajusteInscricao, null, $modalInstance.params.inscricaoListaEspera))
            .then(getVagasEstabelecimentos)
            .then(init);

        function getVagasEstabelecimentos() {
            var promise = QuadroVagasService.getQuadroDeVagas({limit: 999}).then(function (data) {
                setVmQuadroVagas(data);
            });

            vm.tracker.vaga.addPromise(promise);
            return promise;
        }

        function setVmQuadroVagas(data) {
            var dados = _.first(data);
            vm.quadroVagas = dados.content;
        }

        function init() {
            vm.proximoPasso = proximoPasso;
            vm.voltarPasso = voltarPasso;
            vm.saveCandidato = saveCandidato;
            vm.etapasListagem = [];
            vm.tracker.loadingButtonContinuar = promiseTracker();
            vm.criterios = angular.copy(vm.inscricao.criterios) || [];
            vm.criterios.forEach(function (c) {
                c.checked = true;
            });
            vm.habilitaAvancar = habilitaAvancar;
            vm.habilitaSalvar = habilitaSalvar;
            vm.formModalidade = {$invalid: !vm.editando};
            vm.passo = vm.passosCadastroInscricao.FORMULARIO;
        }

        function getEnumDeficiencia() {
            $enumsCache.get('TipoDeficiencia').then(function (enumData) {
                vm.Deficiencias = enumData;
            });
        }

        function proximoPasso() {
            if (!verificaIdadeComEtapa() && !verificaEtapaInfantilCorrespondente()) {
                bfcNotification.publish('O inscrito possui uma idade que não possui correspondência com nenhuma das etapas disponíveis', 'danger');
            }

            vm.passo += 1;
        }

        function voltarPasso() {
            vm.passo -= 1;
        }

        function habilitaAvancar(campos, inscricao, passo, form, formModalidade) {
            if (isCamposListaInvalidos(campos, inscricao)) {
                return true;
            }

            if (passo === vm.passosCadastroInscricao.MODALIDADES) {
                return formModalidade.$invalid;
            }

            if (passo === vm.passosCadastroInscricao.FORMULARIO) {
                return form.$invalid;
            }

        }

        function habilitaSalvar(campos, inscricao, passo, form, formModalidade) {
            if (isCamposListaInvalidos(campos, inscricao)) {
                return true;
            }

            if (passo === vm.passosCadastroInscricao.MODALIDADES) {
                return formModalidade.$invalid;
            }

            if (passo === vm.passosCadastroInscricao.FORMULARIO) {
                return formModalidade.$invalid || form.$invalid;
            }

        }

        function isCamposListaInvalidos(campos, inscricao) {
            var campoTelefone = getCampo(campos, 'TELEFONES');
            if (isObrigatorio(campoTelefone) && !isPreenchido(inscricao.telefones)) {
                return true;
            }

            var campoEndereco = getCampo(campos, 'ENDERECO');
            if (isObrigatorio(campoEndereco) && !isPreenchido(inscricao.enderecoResidencia)) {
                return true;
            }

            var campoFiliacao = getCampo(campos, 'FILIACAO');
            if (isObrigatorio(campoFiliacao) && !isPreenchido(inscricao.filiacoes)) {
                return true;
            }

            var campoEmail = getCampo(campos, 'EMAILS');
            if (isObrigatorio(campoEmail) && !isPreenchido(inscricao.emails)) {
                return true;
            }

            var campoDeficiencia = getCampo(campos, 'DEFICIENCIAS');
            if (inscricao.possuiDeficiencia && isObrigatorio(campoDeficiencia) && !isPreenchido(inscricao.deficiencias)) {
                return true;
            }

            return false;

            function getCampo(list, enumCampo) {
                return _.find(list, 'campo', enumCampo);
            }

            function isObrigatorio(campo) {
                return !!campo && campo.obrigatorio;
            }

            function isPreenchido(lista) {
                return !!lista && !_.isEmpty(lista);
            }

        }

        function getBairros() {
            return InscricaoListaEsperaUtils.getBairros($modalInstance.params.inscricaoListaEspera, vm.isAlunoInscricaoMatricula)
                .then(function (data) {
                    vm.bairroEndereco = _.get(data, 'content');
                });
        }


        function getBairrosFiliacoes() {
            return InscricaoListaEsperaUtils.getBairrosFiliacoes($modalInstance.params.inscricaoListaEspera, vm.isAlunoInscricaoMatricula)
                .then(function (data) {
                    vm.bairrosFiliacoes = _.get(data, 'content');
                });
        }

        function getMunicipios() {
            return InscricaoListaEsperaUtils.getMunicipios($modalInstance.params.inscricaoListaEspera, vm.isAlunoInscricaoMatricula)
                .then(function (dado) {
                    vm.municipios = _.get(dado, 'content');
                });
        }

        function getConfiguracao() {

            return InscricaoListaEsperaUtils.getConfiguracao($modalInstance.params.configuracao)
                .then(function (value) {
                    vm.configuracao = value.configuracao;
                });
        }

        function setVmEstabelecimento(dado) {
            vm.estabelecimentos = dado.estabelecimentos;
        }

        function saveCandidato() {
            vm.disableButtonSave = true; //TODO: ADICIONADO DISABLE PARA EVITAR INSCRIÇÕES DUPLICADAS (ED-17898)
            if (!verificaIdadeComEtapa() && !verificaEtapaInfantilCorrespondente()) {
                bfcNotification.publish('O inscrito possui uma idade que não possui correspondência com nenhuma das etapas disponíveis', 'danger');
                enableSave();
                return;
            }

            if (validateBairrosEnderecos(vm.inscricao)) {
                enableSave();
                return;
            }

            var inscricao = ajusteSalvar(vm.inscricao);

            vm.tracker.loadingButtonContinuar.addPromise(
                InscricaoListaEsperaService.saveCandidato(inscricao, vm.configuracao.id)
                    .then(novaInscricao)
                    .then(notifySuccessSave)
                    .then(openComprovante)
                    .then($modalInstance.params.onClose)
                    .catch(enableSave));
        }

        function enableSave() {
            vm.disableButtonSave = false;
        }

        function isObrigatorioEnderecoTrabalho(configuracao) {
            var campos = _.get(configuracao, 'campos');

            if (_.isArray(campos)) {
                var campoEnderecoTrabalho = _.find(campos, {'campo': 'ENDERECO_TRABALHO'});

                if (_.isObject(campoEnderecoTrabalho)) {
                    return campoEnderecoTrabalho.obrigatorio === true;
                }
            }

            return false;
        }

        function isObrigatorioEnderecoResidencial(configuracao) {
            var campos = _.get(configuracao, 'campos');

            if (_.isArray(campos)) {
                var campoEnderecoResidencial = _.find(campos, {'campo': 'ENDERECO'});

                if (_.isObject(campoEnderecoResidencial)) {
                    return campoEnderecoResidencial.obrigatorio === true;
                }
            }

            return false;
        }


        function verificaEtapaInfantilCorrespondente() {
            var etapaInfantil = _.find(vm.inscricao.etapas, 'configuracaoNivelModalidade.nivelEscolar', 'EDUCACAO_INFANTIL') || _.find(vm.inscricao.etapas, 'nivelEscolar', 'EDUCACAO_INFANTIL');
            if (etapaInfantil) {
                var modalidade = _.find(vm.configuracao.niveisModalidades, 'id', etapaInfantil.configuracaoNivelModalidade.id);
                var isNovaInscricao = !_.get(vm.inscricao, 'id');
                var etapaSugerida = InscricaoListaEsperaUtils.checkIdadeComEtapa(modalidade.etapas, vm.inscricao.dataNascimento, isNovaInscricao);

                if (!etapaSugerida) {
                    return false;
                }

                etapaInfantil.nivelModalidadeEtapa = etapaSugerida;

                return true;
            }

            return true;
        }

        function verificaIdadeComEtapa() {
            if (_.isEmpty(vm.inscricao.etapas) && !_.isEmpty(vm.etapasListagem)) {
                vm.inscricao.etapas = vm.etapasListagem;
            }

            var idadeCompativelComEtapa = _.chain(vm.inscricao.etapas)
                .map(function (etapa) {
                    var modalidade = _.find(vm.configuracao.niveisModalidades, 'id', etapa.configuracaoNivelModalidade.id);

                    if (modalidade.nivelEscolar !== 'EDUCACAO_INFANTIL') {
                        return true;
                    }
                    var isNovaInscricao = !_.get(vm.inscricao, 'id');
                    var etapaSugerida = InscricaoListaEsperaUtils.checkIdadeComEtapa(modalidade.etapas, vm.inscricao.dataNascimento, isNovaInscricao);

                    return etapaSugerida && etapa.nivelModalidadeEtapa.id === etapaSugerida.id;
                })
                .without(true)
                .value();

            return _.isEmpty(idadeCompativelComEtapa);
        }

        function openComprovante(data) {
            $modalInstance.close();
            uiModal.open({
                templateUrl: 'lista-espera-new/inscricao/modal-comprovante-lista-espera/modal-comprovante-lista-espera.html',
                controller: 'educacao.lista-espera-novo.inscricao.ModalComprovanteListaEspera',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    inscricao: data
                }
            });
        }

        function ajusteSalvar(inscrito) {
            var inscricao = _.cloneDeep(inscrito);
            inscricao.frequentaEstabelecimento = _.get(inscricao, 'frequentaEstabelecimento.id') || null;
            inscricao.documentosCandidatoEstabelecimento = _.get(inscricao, 'documentosCandidatoEstabelecimento.id') || null;
            inscricao.cartorioCertidao = _.get(inscricao, 'cartorioCertidao.id');
            inscricao.municipioCertidao = _.get(inscricao, 'municipioCertidao.id');
            inscricao.orgaoEmissorRg = _.get(inscricao, 'orgaoEmissorRg.id');
            inscricao.paisNacionalidade = _.get(inscricao, 'paisNacionalidade.id');
            inscricao.religiao = _.get(inscricao, 'religiao.id');
            inscricao.municipioNascimento = _.get(inscricao, 'municipioNascimento.id');

            if (_.get(inscricao, 'enderecoResidencia.bairroEndereco.id')) {
                inscricao.enderecoResidencia.bairroEndereco = _.get(inscricao, 'enderecoResidencia.bairroEndereco.id');
            }

            if (_.get(inscricao, 'filiacoes')) {
                inscricao.filiacoes.forEach(function (filiacao) {
                    if (_.get(filiacao, 'enderecoTrabalho.bairroEndereco.id')) {
                        filiacao.enderecoTrabalho.bairroEndereco = _.get(filiacao, 'enderecoTrabalho.bairroEndereco.id');
                    }
                });
            }

            if (_.get(inscricao, 'enderecoResidencia.municipio')) {
                inscricao.enderecoResidencia.municipio = _.get(inscricao, 'enderecoResidencia.municipio.id');
            }


            if (inscricao.deficiencias) {
                inscricao.deficiencias = _.chain(inscricao.deficiencias)
                    .map(createObjDeficiencia)
                    .value();
            }

            if (!_.isEmpty(inscricao.filiacoes)) {
                inscricao.filiacoes.forEach(deleteCoordenadaEnderecoTrabalho);
            }

            inscricao.criterios = _.filter(vm.criterios, 'checked');

            if (!_.isEmpty(vm.etapasListagem)) {
                inscricao.etapas = _.chain(vm.etapasListagem)
                    .map(setEtapa)
                    .value();
            }

            if (inscricao.enderecoResidencia) {
                delete inscricao.enderecoResidencia.coordenada;
            }

            delete inscricao.pontuacao;

            ajustaSituacaoInscricaoNaoEnquadrado(inscricao);
            if (vm.isAlunoInscricaoMatricula) {
                delete inscricao.id;
            }

            return inscricao;
        }

        function validateBairrosEnderecos(inscricao) {
            if (isObrigatorioEnderecoResidencial(vm.configuracao) && !_.get(inscricao, 'enderecoResidencia.bairroEndereco.id')) {
                notificaBairroNaoPrenchido('O campo bairro do endereço residencial é obrigatório!');
                return true;
            }

            if (_.get(inscricao, 'filiacoes')) {
                var invalid = inscricao.filiacoes.some(function (filiacao) {
                    return isObrigatorioEnderecoTrabalho(vm.configuracao) && !_.get(filiacao, 'enderecoTrabalho.bairroEndereco.id') && _.get(filiacao, 'empregado');
                });
                if (invalid) {
                    notificaBairroNaoPrenchido('O campo bairro no endereço de trabalho em filiações é obrigatório!');
                }
                return invalid;
            }
            return false;
        }


        function ajustaSituacaoInscricaoNaoEnquadrado(inscricao) {

            inscricao.situacao = inscritoIfNaoEnquadrado(inscricao.situacao);

            inscricao.etapas.forEach(ajustaEtapa);

            return inscricao;

            function ajustaEtapa(etapa) {
                if (etapa) {
                    etapa.situacao = inscritoIfNaoEnquadrado(etapa.situacao);
                    etapa.estabelecimentos.forEach(ajustaEstabelecimento);
                }
            }

            function ajustaEstabelecimento(estabelecimento) {
                if (estabelecimento) {
                    estabelecimento.situacao = inscritoIfNaoEnquadrado(estabelecimento.situacao);
                    estabelecimento.turnos.forEach(ajustaTurno);
                }
            }

            function ajustaTurno(turno) {
                if (turno) {
                    turno.situacao = inscritoIfNaoEnquadrado(turno.situacao);
                }
            }

            function inscritoIfNaoEnquadrado(situacao) {
                if (situacao && situacao.situacao === 'NAO_ENQUADRADO') {
                    situacao.situacao = 'INSCRITO';
                }

                return situacao;
            }

        }

        function createObjDeficiencia(deficiencia) {
            return {
                id: deficiencia.deficiencia.id,
                observacao: deficiencia.observacao
            };
        }

        function deleteCoordenadaEnderecoTrabalho(filiacao) {
            if (filiacao.enderecoTrabalho) {
                filiacao.enderecoTrabalho.municipio = _.get(filiacao, 'enderecoTrabalho.municipio.id');
                delete filiacao.enderecoTrabalho.coordenada;
            }
        }

        function setEtapa(etapa) {
            return {
                nivelModalidadeEtapa: {id: etapa.nivelModalidadeEtapa.id, tipo: etapa.nivelModalidadeEtapa.tipo},
                configuracaoNivelModalidade: etapa.configuracaoNivelModalidade,
                estabelecimentos: setEstabelecimento(etapa)
            };
        }

        function setEstabelecimento(etapa) {
            return _.chain(etapa.estabelecimentos)
                .map(excluirDescricaoEstabelecimento)
                .value();
        }

        function excluirDescricaoEstabelecimento(estabelecimento) {
            return {
                estabelecimento: {id: estabelecimento.estabelecimento.idMongo || estabelecimento.estabelecimento.id},
                turnos: estabelecimento.turnos,
                dataInscEstab: estabelecimento.dataInscEstab
            };
        }

        function ajusteInscricao(inscricao) {
            InscricaoListaEsperaUtils.getEstabelecimentos($modalInstance.params.configuracao, vm.configuracao.permiteRegistroOutroEstabelecimento)
                .then(setVmEstabelecimento)
                .then(getMunicipios)
                .then(getBairros)
                .then(getBairrosFiliacoes)
                .then(_.bind(getDeficiencias, null, inscricao))
                .then(_.bind(ajusteEstabelecimento, null, inscricao, 'frequentaEstabelecimento'))
                .then(_.bind(ajusteEstabelecimento, null, inscricao, 'documentosCandidatoEstabelecimento'))
                .then(_.bind(ajusteMunicipio, null, inscricao, 'municipioNascimento'))
                .then(_.bind(ajusteMunicipio, null, inscricao.enderecoResidencia, 'municipio'))
                .then(_.bind(ajusteMunicipio, null, inscricao, 'municipioCertidao'))
                .then(_.bind(ajusteBairro, null, inscricao.enderecoResidencia, 'bairroEndereco'))
                .then(_.bind(ajusteEstado, null, inscricao.enderecoResidencia, 'estado'))
                .then(_.bind(ajustaEnderecoFiliacao, null, inscricao))
                .then(_.bind(ajusteOrgaoEmissorRic, null, inscricao))
                .then(_.bind(ajusteFiliacao, null, inscricao))
                .then(_.bind(getReligiao, null, inscricao))
                .then(_.bind(getCartorio, null, inscricao))
                .then(_.bind(getPais, null, inscricao))
                .then(_.bind(getOrgaoEmissor, null, inscricao));

            vm.inscricao = inscricao || {};

            if (vm.isAlunoInscricaoMatricula) {
                vm.inscricao.observacao = vm.observacaoInscricao;
                delete vm.inscricao.id;
            }

            vm.editando = Boolean(vm.inscricao.id);
        }

        function ajustaEnderecoFiliacao(inscricao) {
            if (!_.isEmpty(inscricao.filiacoes)) {
                inscricao.filiacoes.forEach(function (filiacao) {
                    if (filiacao.enderecoTrabalho) {
                        filiacao.enderecoTrabalho.municipio = vm.isAlunoInscricaoMatricula ? _.find(vm.municipios, 'id', filiacao.enderecoTrabalho.municipio.id) : _.find(vm.municipios, 'id', filiacao.enderecoTrabalho.municipio);
                        filiacao.enderecoTrabalho.bairroEndereco = vm.isAlunoInscricaoMatricula ? _.find(vm.bairrosFiliacoes, 'id', filiacao.enderecoTrabalho.bairroEndereco.id) : _.find(vm.bairrosFiliacoes, 'id', filiacao.enderecoTrabalho.bairroEndereco);
                        filiacao.enderecoTrabalho.estado = _.get(filiacao, 'enderecoTrabalho.municipio.estado');
                        filiacao.enderecoTrabalho.estado.uf = _.get(filiacao, 'enderecoTrabalho.municipio.uf');
                    }
                });
            }
        }

        function ajusteFiliacao(inscricao) {
            if (!_.isEmpty(inscricao.filiacoes)) {
                inscricao.filiacoes.forEach(function (filiacao) {
                    filiacao.permiteSms = filiacao.permiteEnvioSms;
                    filiacao.permiteEmail = filiacao.permiteEnvioEmail;
                });
            }
        }

        function ajusteMunicipio(inscricao, campo) {
            if (!inscricao || !vm.municipios) {
                return;
            }

            var dado = _.find(vm.municipios, 'id', inscricao[campo]);

            if (!dado) {
                return;
            }

            setCampo(inscricao, campo, dado);
        }

        function ajusteBairro(inscricao, campo) {
            if (!inscricao || !vm.bairroEndereco) {
                return;
            }

            var dado = vm.isAlunoInscricaoMatricula ? _.find(vm.bairroEndereco, 'id', inscricao[campo].id) : _.find(vm.bairroEndereco, 'id', inscricao[campo]);
            
            setCampo(inscricao, campo, dado);
        }

        function ajusteEstado(inscricao, campo) {
            if (!inscricao) {
                return;
            }

            var estado = _.get(inscricao, 'municipio.estado');
            estado.uf = _.get(inscricao, 'municipio.uf');
            inscricao[campo] = estado;
        }

        function ajusteEstabelecimento(inscricao, campo) {
            if (!inscricao[campo]) {
                if (campo === 'frequentaEstabelecimento' && inscricao.outroEstabelecimento) {
                    inscricao[campo] = {id: 0, descricao: 'Outro'};
                    return;
                }

                if (campo === 'documentosCandidatoEstabelecimento' && inscricao.outroEstabelecimentoDocumentos) {
                    inscricao[campo] = {id: 0, descricao: 'Outro'};
                }

                return;
            }

            var dado = _.find(vm.estabelecimentos, 'id', inscricao[campo]);

            if (!dado) {
                return;
            }

            setCampo(inscricao, campo, dado);
        }

        function setCampo(objeto, campo, dado) {
            objeto[campo] = _.first(dado.content) || dado;
        }

        function novaInscricao(data) {
            $('#lbDescricaoConfiguracao').focus();
            vm.form.$setPristine();
            vm.form.$setUntouched();
            vm.inscricao = {};
            enableSave();
            return data;
        }

        function notificaBairroNaoPrenchido(mensagem) {
            bfcNotification.publish(
                mensagem,
                'error');
        }

        function notifySuccessSave(data) {
            bfcNotification.publish('Inscrição salva com sucesso', 'success');
            return data;
        }

        function getReligiao(itemInscricao) {
            if (!itemInscricao.religiao) {
                return;
            }

            var religiaoId = vm.isAlunoInscricaoMatricula ? itemInscricao.religiao.id : itemInscricao.religiao;
            return InscricaoListaEsperaService.getReligiao(religiaoId)
                .then(function (dado) {
                    itemInscricao.religiao = dado;
                });
        }

        function getCartorio(itemInscricao) {
            if (!itemInscricao.cartorioCertidao) {
                return;
            }

            var cartorioId = vm.isAlunoInscricaoMatricula ? itemInscricao.cartorioCertidao.id : itemInscricao.cartorioCertidao;
            return InscricaoListaEsperaService.getCartorio(cartorioId)
                .then(function (dado) {
                    itemInscricao.cartorioCertidao = {id: dado.id, descricao: dado.nome};
                });
        }

        function getDeficiencias(itemInscricao) {
            if (_.isEmpty(itemInscricao.deficiencias)) {
                return;
            }

            var filter;

            if (vm.isAlunoInscricaoMatricula) {
                var descricoes = itemInscricao.deficiencias.map(function (deficiencia) {
                    return vm.Deficiencias[deficiencia.tipoDeficiencia].description;
                });
                filter = '(descricao in ("' + descricoes.join('","') + '"))';
            } else {
                filter = '(id in (' + _.compact(_.pluck(itemInscricao.deficiencias, 'id')).join() + '))';
            }

            var deficiencias = [];

            return InscricaoListaEsperaService.getDeficiencias(filter)
                .then(function (dado) {
                    _.forEach(dado.content, function (deficiencia) {
                        deficiencias.push({
                            deficiencia: deficiencia,
                            observacao: vm.isAlunoInscricaoMatricula ? '' : _.find(itemInscricao.deficiencias, 'id', deficiencia.id).observacao
                        });
                    });

                    itemInscricao.deficiencias = deficiencias;
                });
        }

        function getOrgaoEmissor(itemInscricao) {
            if (!itemInscricao.orgaoEmissorRg) {
                return;
            }

            var orgaoEmissorRgId = vm.isAlunoInscricaoMatricula ? itemInscricao.orgaoEmissorRg.id : itemInscricao.orgaoEmissorRg;
            var filter = '(id = ' + orgaoEmissorRgId + ')';

            return InscricaoListaEsperaService.getOrgaoEmissor(filter)
                .then(function (dado) {
                    itemInscricao.orgaoEmissorRg = _.first(dado.content);
                });
        }

        function ajusteOrgaoEmissorRic(itemInscricao) {
            if (!itemInscricao.orgaoEmissorRic || !vm.isAlunoInscricaoMatricula) {
                return;
            }

            itemInscricao.orgaoEmissorRic = itemInscricao.orgaoEmissorRic.descricao;
        }

        function getPais(itemInscricao) {
            if (!itemInscricao.paisNacionalidade) {
                return;
            }

            var paisId = vm.isAlunoInscricaoMatricula ? itemInscricao.paisNacionalidade.id : itemInscricao.paisNacionalidade;
            return InscricaoListaEsperaService.getPais(paisId)
                .then(function (dado) {
                    itemInscricao.paisNacionalidade = dado;
                });
        }
    }
})();

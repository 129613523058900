(function () {
    'use strict';
    angular
        .module('educacao.lista-espera')
        .controller('educacao.lista-espera.ConfirmacaoRemocaoConfiguracaoListaEspera', Controller);

    Controller.$inject = [
        '$rootScope',
        'promiseTracker',
        '$modalInstance',
        'educacao.lista-espera.ConfiguracaoListaEsperaService'
    ];

    function Controller($rootScope, promiseTracker, $modalInstance, ConfiguracaoListaEsperaService) {
        var vm = this;

        vm.loadingTracker = promiseTracker();

        vm.removeConfiguracao = removeConfiguracao;

        function removeConfiguracao() {
            vm.loadingTracker.addPromise(
                ConfiguracaoListaEsperaService
                    .remove()
                    .then(function () {
                        $modalInstance.close({ reload: true });
                    })
            );
        }
    }
})();

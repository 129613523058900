(function () {

    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ImpressaoBoletimController', ImpressaoBoletimCtrl);

    ImpressaoBoletimCtrl.$inject = [
        '$scope',
        '$modalInstance',
        'promiseTracker',
        'bfc.Notification',
        'educacao.matricula.BoletimService'
    ];

    function ImpressaoBoletimCtrl($scope,
        $modalInstance,
        promiseTracker,
        Notification,
        BoletimService) {

        var vm = this;

        vm.imprimirBoletim = imprimirBoletim;

        vm.tracker = {
            loading: promiseTracker()
        };

        vm.getModeloBoletim = BoletimService.getModeloBoletim();
        vm.getBoletimOrdenacao = BoletimService.getBoletimOrdenacao();
        vm.getBoletimPeriodoAvaliativo = BoletimService.getBoletimPeriodoAvaliativo();

        vm.configuracaoImpressao = {
            matriculas: getIdsMatriculas($modalInstance.params.matriculas || [])
        };

        function getIdsMatriculas(matriculas) {

            var idsMatriculas = [];

            angular.forEach(matriculas, function (value) {
                idsMatriculas.push(value.id);
            });

            return idsMatriculas;
        }

        function imprimirBoletim(configuracoes) {
            vm.tracker.loading.addPromise(BoletimService.gerarPdf(configuracoes)
                .then(notificar)
                .then($modalInstance.close));
        }

        function notificar() {
            Notification.publish('Impressão do relatório agendada com sucesso', 'info');
        }
    }
})();


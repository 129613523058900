(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .directive('appSelectPeriodoCha', Directive);

    function Directive() {
        return {
            restrict: 'E',
            template: '<input ng-model="ngModel" ng-class="ngclass" ng-disabled="ngDisabled" ng-required="ngRequired" ng-change="ngChange" data-bf-select2="vm.configSelect2" type="hidden" />',
            scope: {
                ngModel: '=',
                ngDisabled: '=',
                ngChange: '&?',
                ngRequired: '=?',
                periodosAvaliativos: '=',
                qtdExamesFinais: '=',
                dropdownCssClass: '=?',
                isAtividade: '='
            },
            controller: Controller,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('input').attr('multiple', true);
                }

                return {
                    post: function($scope, $element /*, $attrs, $ctrl*/ ) {
                        $scope.multiple = multiple;

                        var input = $element.children('input');

                        $scope.$watch('placeholder', function(placeholder) {
                            input.attr('placeholder', placeholder || ' ');
                        });

                        $scope.$watch('ngModel', function(newValue, oldValue) {
                            if (newValue !== oldValue) {
                                ($scope.ngChange || angular.noop)();
                            }
                        });
                    }
                };
            },
        };
    }

    Controller.$inject = ['$scope', '$q', '$attrs', 'bfc.Select2Config'];

    function Controller($scope, $q, $attrs, Select2Config) {
        var vm = this;

        var TIPO_PERIODO = {
            MEDIA_PERIODO: 'MEDIA_PERIODO',
            EXAME_FINAL: 'EXAME_FINAL'
        };

        vm.configSelect2 = configSelect2();
        vm.validValues = [];

        $scope.$watch('ngModel', function(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }

            if (_.find($scope.ngModel, 'id', 'TODOS')) {
                _.remove($scope.ngModel, function(item) {
                    return item.id !== 'TODOS';
                });
            }

            if (_.isEmpty($scope.ngModel)) {
                $scope.ngModel = null;
            }

        });

        registerPeriodos();

        function registerPeriodos() {

            vm.validValues.push({
                periodoAvaliativo: null,
                descricao: 'Todos',
                id: 'TODOS'
            });

            if ($scope.isAtividade) {
                vm.validValues.push({
                    periodoAvaliativo: null,
                    tipoPeriodo: TIPO_PERIODO.MEDIA_PERIODO,
                    descricao: 'Média',
                    id: TIPO_PERIODO.MEDIA_PERIODO
                });
            } else {
                _.forEach($scope.periodosAvaliativos, function(periodo) {
                    var validValue = {
                        periodoAvaliativo: periodo,
                        tipoPeriodo: TIPO_PERIODO.MEDIA_PERIODO,
                        descricao: periodo.descricao,
                        id: _.get(periodo, 'id')
                    };
                    vm.validValues.push(validValue);
                    
                });
            }
            if ($scope.qtdExamesFinais) {
                vm.validValues.push({
                    periodoAvaliativo: null,
                    tipoPeriodo: TIPO_PERIODO.EXAME_FINAL,
                    descricao: 'Exame final',
                    id: TIPO_PERIODO.EXAME_FINAL
                });
            }
        }

        function configSelect2() {
            function filterResult(data) {
                if (_.find($scope.ngModel, 'id', 'TODOS')) {
                    return [];
                }
                return data;
            }

            function _formatResult(data) {
                return data.descricao;
            }

            function _formatSelection(data) {
                return data.descricao;
            }

            function _formatValue(data) {
                return data;
            }

            function _initSelection() {}

            function _result(params, callback) {
                var deferred = $q.defer();

                function _data() {
                    deferred.resolve(vm.validValues);

                    return deferred.promise;
                }

                return _data().then(function(data) {
                    callback({
                        results: filterResult(data),
                        more: false
                    });
                });
            }

            return Select2Config.create({
                initSelection: _initSelection,
                formatResult: $scope.formatResult || _formatResult,
                formatSelection: _formatSelection,
                formatValue: _formatValue,
                result: _result,
                multiple: angular.isDefined($attrs.multiple),
                dropdownCssClass: $scope.dropdownCssClass || null
            });
        }
    }

})();


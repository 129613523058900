(function () {

    'use strict';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.ModulosTurmaEjaController', Controller);

    Controller.$inject = [
        '$modalInstance', '$scope'
    ];

    function Controller($modalInstance, $scope) {
        var vm = this;
        vm.modulosRequired = false;
        vm.validaTodosOuNenhum = validaTodosOuNenhum;
        vm.salvar = salvar;

        vm.moduloQuantidadeAulas = $modalInstance.params.moduloQuantidadeAulas || [];
        vm.btnSalvar = validaTodosOuNenhum();
        
        function validaTodosOuNenhum(){
            var isNull = true;
            var isNotNull = true;
            _.forEach(vm.moduloQuantidadeAulas, function(i){
                if (_.has(i,'quantidadeAulas')){
                    if (i.quantidadeAulas){
                        isNull = false;
                    } else {
                        isNotNull = false;
                    }
                }
            });
            vm.btnSalvar = (isNull === isNotNull);
            vm.modulosRequired = !isNull;
            if ( vm.modulosRequired && $scope.form ){
                _.forEach($scope.form.$error.required, function(field) {
                    field.$setTouched();
                });
            }
        }

        function salvar() {
            $modalInstance.close(vm.moduloQuantidadeAulas);
        }
    }
})();
(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .config(stateConfig);

    stateConfig.$inject = [
        '$stateProvider',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function stateConfig($stateProvider, STATES_ADICIONAR, STATES_EDITAR) {
        var STATE = 'app.enderecos.regiao';

        $stateProvider.state(STATE, {
            url: 'regioes',
            data: {
                id: 'RegiaoMunicipalPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'enderecos/regiao/regiao.html',
                    controller: 'educacao.enderecos.RegiaoController as vm'
                }
            }
        }).state(STATE + STATES_ADICIONAR.state, {
            url: STATES_ADICIONAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        }).state(STATE + STATES_EDITAR.state, {
            url: STATES_EDITAR.url,
            onEnter: openModal,
            data: {
                isModalState: true
            }
        });

        openModal.$inject = [
            '$stateParams',
            'educacao.enderecos.RegiaoService',
            '$modalStates'
        ];

        function openModal($stateParams, RegiaoService, $modalStates) {
            $modalStates.open({
                getMethod: RegiaoService.get,
                templateUrl: 'enderecos/regiao/cadastro-regiao/cadastro-regiao.html',
                controller: 'educacao.enderecos.CadastroRegiaoController as vm',
                propertyName: 'regiao',
                size: 'sm',
                stateParams: $stateParams
            });
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.matriz').controller('educacao.matriz.DisciplinasCtrl', DisciplinasCtrl);
        
    DisciplinasCtrl.$inject = ['$scope', 
        '$timeout',
		'educacao.common.ObjectUtilsService',
		'educacao.matriz.DisciplinaService',
        'promiseTracker',
        '$modalStates'];

    function DisciplinasCtrl ($scope, 
            $timeout,
            ObjectUtilsService,
            DisciplinaService,
            promiseTracker, 
            $modalStates
		) {

            var vm = this;

            vm.openCad = openCad;
            vm.remove = remove;
            vm.getIconSortClass = getIconSortClass;
            vm.tracker = {};
            vm.tracker.list = promiseTracker();
            
            vm.disciplinas = [];
            vm.disciplinaService = DisciplinaService;

            function openCad (disciplina) {
                $modalStates.goModalState(disciplina);
            }

             function remove (disciplina) {
                DisciplinaService.remove(disciplina.id).then(function () {
                    vm.disciplinas = _.reject(vm.disciplinas, {
                        'id': disciplina.id
                    });
                    vm.listControls.search();
                });
            }
            
            function getIconSortClass (sortBy) {
                return {
                    'icon-caret-down': vm.predicate === sortBy && vm.reverse,
                    'icon-caret-up': vm.predicate === sortBy && !vm.reverse,
                    'icon-sort': vm.predicate !== sortBy
                };
            }


		}

})();

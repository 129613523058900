(function () {
    'use strict';

    angular.module('educacao.visao-geral-quadro-informativo')
        .directive('cardListagemEstabelecimentoVisaoGeral', directive);

    directive.$inject = [];

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'quadro-informativo/visao-geral/card-listagem-estabelecimento-visao-geral/card-listagem-estabelecimento-visao-geral.directive.html',
            scope: {},
            bindToController: {
                estabelecimento: '=',
                estabelecimentoSelected: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [];

    function Controller() {
        var vm = this;

        vm.onClickCard = onClickCard;

        function onClickCard() {
            vm.estabelecimentoSelected = {
                id: vm.estabelecimento.id,
                descricao: vm.estabelecimento.descricao,
                brasao: vm.estabelecimento.brasao
            };
        }
    }
})();
/* global Promise */
(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo')
        .service('educacao.turma-alunos-quadro-informativo.GraficoTurmaAlunosService', GraficoTurmaAlunosService);

    GraficoTurmaAlunosService.$inject = [
        'educacao.context.ContextoAnoLetivo',
        'educacao.context.ContextoEstabelecimento',
        'educacao.turma-alunos-quadro-informativo.QuadroFuncionariosTurmaAlunosService',
        '$rootScope'
    ];

    function GraficoTurmaAlunosService(ContextoAnoLetivo, ContextoEstabelecimento, QuadroFuncionariosTurmaAlunosService, $rootScope) {

        $rootScope.$on('app-context:estabelecimento:new', clearValues);
        $rootScope.$on('app-context:anoletivo:new', clearValues);


        var service = {
            graficosTurmas: {},
            graficosGerais: {},
            graficosAlunos: {},
            graficoSalas: {},
            graficoSaldoDeVagas: {},
            getGraficoTurma: getGraficoTurma,
            getGraficoGerais: getGraficoGerais,
            getGraficoAlunos: getGraficoAlunos,
            getGraficoSalas: getGraficoSalas,
            getGraficoSaldoDeVagas: getGraficoSaldoDeVagas,
            clearValues: clearValues
        };

        return service;

        function clearValues() {
            service.graficosTurmas = {};
            service.graficosGerais = {};
            service.graficosAlunos = {};
            service.graficoSalas = {};
            service.graficoSaldoDeVagas = {};
        }

        function getGraficoTurma(path, serviceName, params) {
            if (!service.graficosTurmas[path]) {
                return QuadroFuncionariosTurmaAlunosService[serviceName](params)
                    .then(function (data) {
                        service.graficosTurmas[path] = data;
                        return data;
                    });
            } else {
                return Promise.resolve(service.graficosTurmas[path]);
            }
        }

        function getGraficoSaldoDeVagas(path, serviceName, params) {
            if (!service.graficoSaldoDeVagas[path]) {
                return QuadroFuncionariosTurmaAlunosService[serviceName](params)
                    .then(function (data) {
                        service.graficoSaldoDeVagas[path] = data;
                        return data;
                    });
            } else {
                return Promise.resolve(service.graficoSaldoDeVagas[path]);
            }
        }

        function getGraficoSalas(path, serviceName, params) {
            if (!service.graficoSalas[path]) {
                return QuadroFuncionariosTurmaAlunosService[serviceName](params)
                    .then(function (data) {
                        service.graficoSalas[path] = data;
                        return data;
                    });
            } else {
                return Promise.resolve(service.graficoSalas[path]);
            }
        }

        function getGraficoGerais(path, serviceName, params) {
            if (!service.graficosGerais[path]) {
                return QuadroFuncionariosTurmaAlunosService[serviceName](params)
                    .then(function (data) {
                        service.graficosGerais[path] = data;
                        return data;
                    });
            } else {
                return Promise.resolve(service.graficosGerais[path]);
            }
        }

        function getGraficoAlunos(path, serviceName, params) {
            if (!service.graficosAlunos[path]) {
                return QuadroFuncionariosTurmaAlunosService[serviceName](params)
                    .then(function (data) {
                        service.graficosAlunos[path] = data;
                        return service.graficosAlunos[path];
                    });
            } else {
                return Promise.resolve(service.graficosAlunos[path]);
            }
        }


    }


})();
(function() {
    'use strict';

    var moduloCommon = angular.module('educacao.pessoas');

    moduloCommon.controller('educacao.pessoas.InscricaoOnlineCtrl', ['$scope', '$timeout', 'educacao.common.ModalCad',
        function($scope, $timeout, ModalCad) {

            $scope.localTrabalho = {};

            var watchCheckTurma = true;
            var watchCheckTurma1 = true;
            $scope.checkTurma = function() {

                if (watchCheckTurma1 === false) {
                    $scope.check11 = 'bg__gray--l20';
                    $scope.checkIco11 = 'icon-remove tx__gray--d10';
                    watchCheckTurma1 = true;
                } else if (watchCheckTurma1 === true) {
                    $scope.check11 = 'bg__aqua--l40';
                    $scope.checkIco11 = 'icon-ok tx__aqua';
                    watchCheckTurma1 = false;
                }

                if (watchCheckTurma === false) {
                    $scope.check = 'bg__gray--l20';
                    $scope.checkIco = 'icon-remove tx__gray--d10';
                    watchCheckTurma = true;
                } else if (watchCheckTurma === true) {
                    $scope.check = 'bg__aqua--l40';
                    $scope.checkIco = 'icon-ok tx__aqua';
                    watchCheckTurma = false;
                }
            };
            $scope.TabGreen2 = 'bg__gray';
            $scope.TabGreen3 = 'bg__gray';
            $scope.TabGreen4 = 'bg__gray';
            $scope.trocaTabs = function(tab) {
                if (tab === 'estabelecimentos') {
                    $scope.estabelecimentos = true;
                    $scope.parametroscomuns = false;
                    $scope.ParametrosProcessoInscricaoOnline = false;
                    $scope.CriteriosClassificacaoInscricoes = false;
                }
                if (tab === 'parametroscomuns') {
                    $scope.estabelecimentos = false;
                    $scope.parametroscomuns = true;
                    $scope.ParametrosProcessoInscricaoOnline = false;
                    $scope.CriteriosClassificacaoInscricoes = false;
                    $scope.TabGreen2 = 'bg__green--l20';
                }
                if (tab === 'ParametrosProcessoInscricaoOnline') {
                    $scope.estabelecimentos = false;
                    $scope.parametroscomuns = false;
                    $scope.ParametrosProcessoInscricaoOnline = true;
                    $scope.CriteriosClassificacaoInscricoes = false;
                    $scope.TabGreen3 = 'bg__green--l20';
                }
                if (tab === 'CriteriosClassificacaoInscricoes') {
                    $scope.estabelecimentos = false;
                    $scope.parametroscomuns = false;
                    $scope.ParametrosProcessoInscricaoOnline = false;
                    $scope.CriteriosClassificacaoInscricoes = true;
                    $scope.TabGreen4 = 'bg__green--l20';
                }
            };

            $scope.trocaTabs('estabelecimentos');

            $scope.openFiliacao = function() {
                ModalCad.open({
                    templateUrl: 'planejamento/inscricao-online/inscricao.online.filiacao.html',
                    controller: 'educacao.pessoas.InscricaoOnlineCadCtrl'
                });
            };

            $scope.openDocumentosObrigatorios = function() {
                ModalCad.open({
                    templateUrl: 'planejamento/inscricao-online/inscricao.online.documentos.obrigatorios.html',
                    controller: 'educacao.pessoas.InscricaoOnlineCadCtrl'
                });
            };


        }
    ]);

    moduloCommon.controller('educacao.pessoas.InscricaoOnlineCadCtrl', ['$scope',
        function($scope) {
            $scope.localTrabalho = {};


        }
    ]);

})();

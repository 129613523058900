(function () {
    'use strict';

    angular.module('educacao.turma-alunos-quadro-informativo').config(stateConfig);

    stateConfig.$inject = [
      '$stateProvider'
    ];

    function stateConfig($stateProvider) {
      var STATE = 'app.planejamento.turma-alunos-quadro-informativo';

      $stateProvider.state(STATE, {
        url: 'quadro-informativo-turma-alunos',
        // data: {
        //   id: 'QuadroInformativoPageMapping',
        //   mirrorOf: 'app.planejamento.visao-geral-quadro-informativo'
        // },
        params: {
          estabelecimentoSelected: '',
          anoLetivoSelected:''
        },
        views: {
          '@': {
            templateUrl: 'quadro-informativo/turma-alunos/turma-alunos.html',
            controller: 'educacao.turma-alunos-quadro-informativo.TurmaAlunosQuadroInformativoController as vm',
          }
        }
      });

    }
  })();
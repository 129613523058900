(function () {
    'use strict';

    angular.module('educacao.motivosabonosjustificativas')
        .directive('appSelectMotivoAbonoJustificativa', SelectMotivoAbonoJustificativaDirective);

    SelectMotivoAbonoJustificativaDirective.$inject = [];

    function SelectMotivoAbonoJustificativaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" ng-disabled="ngDisabled" add-value="vm.addValue" format-result="vm.formatResult(data)" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                abonoJustificativa: '=',
                motivoAbonoJustificativa: '='
            },
            controller: SelectMotivoAbonoJustificativaDirectiveController,
            controllerAs: 'vm'
        };
    }

    SelectMotivoAbonoJustificativaDirectiveController.$inject = [
        '$scope',
        'ui.components.Modal',
        'bfc.$$PermissionsService',
        'educacao.motivosabonosjustificativas.MotivosAbonosJustificativasService'
    ];

    function SelectMotivoAbonoJustificativaDirectiveController($scope, uiModal, PermissionsService, MotivosAbonosJustificativasService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;
        vm.addValue = !PermissionsService.isRevokedOperation('MotivoAbonoJustificativaPageMapping', 'criar') && addValue;

        function search(params) {
            var filter = getFilter(params);
            return MotivosAbonosJustificativasService.list(filter, params.offset, params.limit);
        }

        function getFilter(params) {
            var filter = '( descricao like "' + params.term + '")';

            if (isEdit()) {
                filter += getTipoFilter();
            }

            return filter;
        }

        function isEdit() {
            return !!_.get($scope.abonoJustificativa, 'id');
        }

        function getTipoFilter() {
            return ' and tipo = "' + $scope.abonoJustificativa.tipo + '"';
        }

        function addValue(descricao) { 
            return uiModal.open({
                templateUrl: 'motivos-abonos-justificativas/cadastro/motivos-abonos-justificativas.cad.html',
                controller: 'educacao.motivosabonosjustificativas.MotivosAbonosJustificativasCadCtrl',
                controllerAs: 'vm',
                size: 'sm',
                params: {
                    motivoAbonoJustificativa: {tipo: $scope.tipo, descricao: descricao}
                }
            }).result.then(returnValidValue);
        }

        function returnValidValue(motivoAbonoJustificativa) {
            if (!$scope.motivoAbonoJustificativa.tipo || motivoAbonoJustificativa.tipo === $scope.motivoAbonosJustificativa.tipo) {
                return motivoAbonoJustificativa;
            }
        }

        function formatResult(motivoAbonoJustificativa) {
            if (!motivoAbonoJustificativa.descricao || !motivoAbonoJustificativa.tipo) {
                return;
            }
            return motivoAbonoJustificativa.descricao + ' - ' + motivoAbonoJustificativa.tipo;
        }
    }
})();

(function() {
	'use strict';

	angular.module('educacao.common')
		.filter('appIdadeMeses', filter);

	function filter() {
		return function(dataIni, dataFim) {
			if (_.isNull(dataIni) || _.isUndefined(dataIni) || _.isNull(dataFim) || _.isUndefined(dataFim)) {
				return '0 mês (0 ano 0 mês)';
			}

			var PLULARIZE = {
					ANO: {
						'one': ' ano',
						'other': ' anos'
					},
					MES: {
						'one': ' mês',
						'other': ' meses'
					}
				};

			var ano = moment(dataFim).diff(dataIni, 'years');
			var meses = moment(dataFim).diff(dataIni, 'month');
			var mes = meses % 12;

			return meses + getPlularize(meses, 'MES') + ' (' + ano + ' ' + getPlularize(ano, 'ANO') + ' ' + mes + ' ' + getPlularize(
				mes, 'MES') + ')';

			function getPlularize(num, tipo) {
				return num > 1 ? PLULARIZE[tipo].other : PLULARIZE[tipo].one;
			}
		};
	}
})();

(function() {

    'use strict';

    angular.module('educacao.matriz')
        .constant('TIPO_COMPETENCIA_CHA', {
            COMPETENCIA: 'COMPETENCIA',
            CONHECIMENTO: 'CONHECIMENTO',
            HABILIDADE: 'HABILIDADE',
            ATITUDE: 'ATITUDE'
        });

})();


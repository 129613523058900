(function() {
    'use strict';
    var moduloCommon = angular.module('educacao.matricula');
    moduloCommon.controller('educacao.matricula.LiberacaoVagasEstabelecimentoCtrl', ['educacao.matricula.LiberacaoVagasEstabelecimentoService',
        '$q', '$injector', 'promiseTracker', '$modalInstance', '$modalTracker', 'bfc.Notification',
        function QuadroVagasCadCtrl(LiberacaoVagasEstabelecimentoService, $q, $injector, promiseTracker, $modalInstance, $modalTracker, Notification) {
            var vm = this;
            vm.tracker = {};
            vm.tracker.list = promiseTracker();
            vm.tracker.save = promiseTracker();
            vm.tracker.remove = promiseTracker();
            vm.save = _save;
            vm.estabelecimentosLiberados = {};
            vm.allLiberados = false;
            vm.watchIconCheck = _watchIconCheck;
            vm.changeLiberado = _changeLiberado;
            vm.fechaModal = _fechaModal;

            init();

            function init() {

                $modalTracker.addPromise(
                    $q.all([
                        (function() {
                            if (vm.form) {
                                vm.form.$setPristine();
                            }
                        })(),

                        refresh()

                    ])
                );
            }

            function refresh() {
                vm.tracker.list.addPromise(LiberacaoVagasEstabelecimentoService.getList(0, 999)
                    .then(function(data) {
                        vm.estabelecimentosLiberados = data;
                        verifyAllLiberados();
                    }));
            }

            function verifyAllLiberados() {
                vm.allLiberados = !vm.estabelecimentosLiberados.some(function(estabelecimento) {
                    return !estabelecimento.liberado;
                });
            }

            function _save() {
                vm.tracker.save.addPromise(
                    LiberacaoVagasEstabelecimentoService.save(vm.estabelecimentosLiberados)
                    .then(onSuccess)
                    .then(notifyLiberacaoSaved));
            }

            function notifyLiberacaoSaved(data) {
                if (data.some(function(estabelecimento) {
                        return estabelecimento.liberado;
                    })) {
                    Notification.publish('O levantamento do quadro de vagas está liberado para preenchimento.', 'success');
                }
                return data;
            }

            function onSuccess(data) {

                vm.fechaModal();
                return data;
            }

            function _fechaModal() {
                $modalInstance.close();
            }

            function _watchIconCheck() {
                vm.allLiberados = !vm.allLiberados;
                angular.forEach(vm.estabelecimentosLiberados, function(estabelecimento) {
                    estabelecimento.liberado = vm.allLiberados;
                });
            }

            function _changeLiberado(estabelecimento) {
                estabelecimento.liberado = !estabelecimento.liberado;
                var size = vm.estabelecimentosLiberados.filter(function(e) {
                    return e.liberado;
                }).length;
                vm.allLiberados = size === vm.estabelecimentosLiberados.length;
            }
        }
    ]);
})();

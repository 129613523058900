(function () {
    'use strict';

    angular.module('educacao.common', [
        'bfc.oauth2',
        'bfc.user-access',
        'generic-service',
        'educacao-base',
        'ed-numeric',
        'ed-accordion-cha',
        'ed-accordion-objetivo-aprendizagem',
        'pes-components.pes-feedback',
    ]);

})();

(function() {
    'use strict';
    angular.module('educacao.calendario')
        .service('educacao.calendario.CalendarioMatrizCurricularBuscaService', service);

    service.$inject = ['Restangular'];

    function service(restangular) {
        return {
            list: list,
            get: get,
            listCompletos: listCompletos,
            listByAnoLetivo: listByAnoLetivo
        };

        function list(filter, offset, limit, sort) {
            return restangular.all('calendario/matriz').getList({
                filter: filter,
                offset: offset,
                limit: limit,
                sort: sort
            }).then(_.first);
        }

        function get(id) {
            return restangular.one('calendario').one('matriz', id).get();
        }

        function listCompletos(filter, offset, limit, sort) {
            return restangular.all('calendario/matriz/completos').getList({
                filter: filter,
                offset: offset,
                limit: limit,
                sort: sort
            }).then(_.first);
        }

        function listByAnoLetivo(estabelecimento, matriz, anoLetivo) {
            var filter = '(';

            filter += ' estabelecimento=' + estabelecimento.id + ' and matriz=' + matriz.id +
                ' and anoLetivo=' + anoLetivo.id;
            filter += ' ) ';

            return list(filter);
        }
    }
})();


(function () {

    'use strict';

    angular.module('educacao.matricula').directive('appSelectItemCurricularDispensavel', SelectItemCurricularDispensavelDirective);
    SelectItemCurricularDispensavelDirective.$inject = [];
    function SelectItemCurricularDispensavelDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" minimum-input-length="-1" format-result="vm.formatResult(data)" search="vm.search" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?',
                ngDisabled: '=?',
                options: '='
            },
            controller: SelectItemCurricularDispensavelDirectiveCtrl,
            controllerAs: 'vm',
            compile: function(element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);
                }


            }
        };
    }

    SelectItemCurricularDispensavelDirectiveCtrl.$inject = ['$scope', '$q'];
    function SelectItemCurricularDispensavelDirectiveCtrl($scope, $q) {

        var vm = this;

        vm.search = search;

        function search(params) {
            var term,filter;
            term= decodeURI(params.term);
            term = term.replace(/%/g, ' ').trim();

            filter = _.filter($scope.options, function (item) {
                return item.descricao.toUpperCase().indexOf(term.toUpperCase()) > -1;
            });

            if ($scope.ngModel) {
                var semTodos = _.filter(filter, function(item) {
                    return item.id !== 'TODOS';
                });

                if ((semTodos.length - 1) === $scope.ngModel.length) {
                    return $q.when({
                        content: [_.find(filter, { id: 'TODOS' })],
                        hasNext: false
                    });
                }
            }

            return $q.when({
                content: filter,
                hasNext: false
            });
        }
    }
})();

(function() {
    'use strict';

    angular.module('educacao.convencao')
        .factory('educacao.convencao.ConvencaoService', Service);

    Service.$inject = ['$injector'];


    function Service($injector) {

        var ServiceBuilder = $injector.get('ServiceBuilder');

        var selfService = ServiceBuilder.create()
            .path('convencao')
            .build();

        return selfService;
    }
})();

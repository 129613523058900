(function() {
    'use strict';

    var DEFAULT_MASK = 'HH:mm:ss';

    angular.module('educacao.common')
        .directive('appTimepicker', AppTime);

    AppTime.$inject = [];

    function AppTime() {
        return {
            restrict: 'E',
            require: 'ngModel',

            template: '<input type="text">',
            replace: true,

            scope: {
                inputMask: '@',
                modelMask: '@',
                ngModel: '='
            },
            link: postLink
        };

        function postLink(scope, element, attrs, ngModelController) {
            var inputMask = DEFAULT_MASK;
            var modelMask = DEFAULT_MASK;

            ngModelController.$parsers.push(parseValue);
            ngModelController.$formatters.push(formatValue);

            validTime.message = 'Ops, esta não é uma hora válida';
            ngModelController.$validators.time = validTime;

            scope.$watch('inputMask', onInputMaskChanged);
            scope.$watch('modelMask', onModelMaskChanged);

            element.blur(completeValue);

            function parseValue(viewValue) {
                var modelValue = moment(viewValue, inputMask);

                if (modelValue.isValid()) {
                    modelValue = modelValue.format(modelMask);
                } else {
                    modelValue = null;
                }
                return modelValue;
            }

            function formatValue(modelValue) {
                var viewValue = modelValue ? moment(modelValue, modelMask) : null;

                if (viewValue && viewValue.isValid()) {
                    viewValue = viewValue.format(inputMask);
                } else {
                    viewValue = '';
                }

                return viewValue;
            }

            function validTime(modelValue, viewValue) {
                var value;
                var momentValue;

                if (modelValue) {
                    value = modelValue;
                    momentValue = moment(modelValue, modelMask);
                } else {
                    value = viewValue;
                    momentValue = moment(viewValue, inputMask);
                }

                return _.isEmpty(value) || momentValue.isValid();
            }

            function onInputMaskChanged(attrMask) {
                inputMask = attrMask || DEFAULT_MASK;

                element
                    .attr('maxlength', inputMask.length)
                    .mask(inputMask.split(/\w/)
                        .join('0'));
            }

            function onModelMaskChanged(attrMask) {
                modelMask = attrMask || DEFAULT_MASK;
            }

            function completeValue() {
                if (ngModelController.$invalid) {
                    return;
                }
                scope.$apply(function() {
                    var value = formatValue(ngModelController.$viewValue);
                    if (value) {
                        ngModelController.$setViewValue(value);
                        ngModelController.$render();
                    }
                });
            }
        }
    }
})();

(function () {
    'use strict';

    var moduloCommon = angular.module('educacao.matriz');

    moduloCommon.controller('educacao.matriz.MatrizVisualizarCtrl', ['$scope',
                             '$rootScope',
                             'promiseTracker',
                             '$modalInstance',
                             '$modalTracker',
                             'educacao.matriz.MatrizService',
                             'educacao.matriz.EtapaDisciplinaService',
                             'id',
                             'ui.components.PrintService',
		function ($scope,
            $rootScope,
            promiseTracker,
            $modalInstance,
            $modalTracker,
            MatrizService,
            EtapaDisciplinaService,
            idMatriz,
            PrintService
		) {



            $scope.matriz = {};
            $scope.etapas = {};
            $scope.itens = [];

            var etapasDisciplinas = [],
                deveAgrupar,
                totalChItens;

            //constantes
            var EIXO_TEMATICO = 'Eixos temáticos',
                AGRUPADOR = 'Organização curricular',
                ITEM = 'Disciplinas ou Campos de experiência',
                TOTAL_CARGA_HORARIA = 'Total da carga horária';

            var agrupadoPor = {
                eixo: function () {
                    return isEducacaoInfantil();
                },
                organizacao: function () {
                    return !isEducacaoInfantil();
                }
            };

            $scope.tracker = {};
            $scope.tracker.loadingDisciplinas = promiseTracker();
            $scope.tracker.loadingMatriz = promiseTracker();

            $scope.semItens = true;

            $scope.possuiEixos = false;

            inicializar();


            function inicializar() {
                $modalTracker.addPromise(MatrizService.visualizacao(idMatriz).then(function (data) {
                    $scope.init(data);
                }));
            }

            $scope.init = function (data) {

                $scope.matriz = data;
                $scope.etapas = data.etapas;

                deveAgrupar = !isSomenteEixoTematico();
                totalChItens = isSomenteEixoTematico() ? 'Total (Eixos)' : 'Total (Componente curricular)';

                //Busca informações das etapas para o cabeçalho da matriz
                var carregaDescricaoEtapas = function () {
                    return _.map($scope.etapas, function (etapa) {
                        return {
                            'id': etapa.id,
                            'descricao': MatrizService.getDescricaoEtapa($scope.matriz, etapa),
                            'disciplinas': etapa.disciplinas
                        };
                    });
                };

                $scope.colEtapas = carregaDescricaoEtapas();

                $scope.cols = _.union([{
                            'descricao': AGRUPADOR,
                            'width': '15%'
                        },
                        {
                            'descricao': ITEM,
                            'width': '25%'
                        },
                        {
                            'descricao': EIXO_TEMATICO,
                            'width': '20%'
                        }],
                    _.map($scope.colEtapas, function (col) {
                        return {
                            'descricao': col.descricao,
                            'width': ''
                        };
                    }), [{
                        'descricao': totalChItens,
                        'width': '10%'
                    }]);

                _.each($scope.colEtapas, function (etapa) {

                    var etapaDisciplina = _.map(etapa.disciplinas, function (etapaDisc) {
                        if (isDisciplinas()) {
                            etapaDisc.disciplina.orientacaoCurricular = etapaDisc.orientacaoCurricular;
                            etapaDisc.disciplina.eixoTematico = etapaDisc.eixoTematico;
                        } else if (isCampoExperiencia()){
                            etapaDisc.campoExperiencia.orientacaoCurricular = etapaDisc.orientacaoCurricular;
                            etapaDisc.campoExperiencia.eixoTematico = etapaDisc.eixoTematico;
                        } else {
                            etapaDisc.disciplina = etapaDisc.eixoTematico;
                        }

                        return isCampoExperiencia() ? etapaDisc.campoExperiencia : etapaDisc.disciplina;
                    });

                    $scope.itens.push(etapaDisciplina);
                    etapasDisciplinas.push({
                        id: etapa.id,
                        etapasDisciplinas: etapa.disciplinas
                    });

                    if (etapa.id === $scope.colEtapas[$scope.colEtapas.length - 1].id) {
                        listaDisciplinas();
                    }

                });

            };

            $scope.print = function () {
                PrintService.print('#imprimeMatriz');
            };

            var listaDisciplinas = function () {


                $scope.itens = _.reduce($scope.itens, function (last, currrent) {
                    return last.concat(_.reduce(currrent, function (last1, currrent2) {
                        return last1.concat(currrent2);
                    }, []));
                }, []);
                $scope.itens = _.uniq($scope.itens, function (objeto) {
                    return objeto.id + ' - ' + (!isEducacaoInfantil() ? objeto.orientacaoCurricular.id : (objeto.eixoTematico ? objeto.eixoTematico.id : ''));
                });


                $scope.itens = _.sortBy($scope.itens, function (disciplina) {
                    if (!isEducacaoInfantil()) {
                        return disciplina.orientacaoCurricular.descricao + ' - ' + disciplina.descricao;
                    } else {
                        if (!deveAgrupar) {
                            return disciplina.descricao;
                        }
                    }
                    return (disciplina.eixoTematico ? disciplina.eixoTematico.id : '') + ' -' + disciplina.descricao;
                });
                if ($scope.itens.length > 0) {
                    relacionarDados();
                }

            };

            var relacionarDados = function () {

                var dadosDisciplina = [];

                var getDescricaoEixo = function (disciplina) {
                    if (disciplina.eixosTematicos) { //disciplina.eixosTematicos.length > 0
                        $scope.possuiEixos = true;

                        var result = '';

                        for (var i = 0; i < disciplina.eixosTematicos.length; i++) {
                            result += (i === 0 ? '' : ', ') + disciplina.eixosTematicos[i].descricao;
                        }

                        return result;
                    } else {
                        return '';
                    }

                };

                $scope.semItens = false;

                $scope.dados = _.map($scope.itens, function (disciplina) {

                    var indexCol = 0;

                    var descricaoEixo = (disciplina.eixoTematico ? disciplina.eixoTematico.descricao : '');

                    dadosDisciplina = [{
                            value: isEducacaoInfantil() ? isDisciplinas() || isCampoExperiencia() ? descricaoEixo : disciplina.descricao : disciplina.orientacaoCurricular.descricao,
                            descricao: $scope.cols[indexCol++].descricao
                        },
                        {
                            value: disciplina.descricao,
                            descricao: $scope.cols[indexCol++].descricao
                        },
                        {
                            value: getDescricaoEixo(disciplina),
                            descricao: $scope.cols[indexCol++].descricao
                        }];

                    var totalDisciplina = 0,
                        totalMinutos = 0;
                    _.each($scope.colEtapas, function (etapa) {

                        var ch = getCargaHoraria(etapa.id, indexCol++, disciplina);

                        totalMinutos += ch.valueMinutos;

                        totalDisciplina += ch.value;

                        dadosDisciplina.push(ch);

                    });

                    dadosDisciplina.push({
                        value: totalDisciplina,
                        valueMinutos: totalMinutos,
                        descricao: $scope.cols[indexCol++].descricao
                    });

                    return _.extend(dadosDisciplina);
                });

                agrupaMatriz();

            };

            var getCargaHoraria = function (idEtapa, indexCol, disciplina) {
                var ch = 0,
                    chMinutos = 0,
                    listaDisciplinasEtapa, findEtapa;

                findEtapa = _.find(etapasDisciplinas, function (objeto) {
                    return objeto.id === idEtapa;
                });

                if (_.has(findEtapa, 'etapasDisciplinas')) {

                    listaDisciplinasEtapa = findEtapa.etapasDisciplinas;

                    var etapaDisc = _.find(listaDisciplinasEtapa, function (objeto) {
                        if (isCampoExperiencia()) {
                            return objeto.campoExperiencia.id === disciplina.id && (isEducacaoInfantil() && (objeto.campoExperiencia.eixoTematico ? objeto.campoExperiencia.eixoTematico.id : '') === (disciplina.eixoTematico ? disciplina.eixoTematico.id : ''));
                        }

                        return objeto.disciplina.id === disciplina.id && ((!isEducacaoInfantil() && objeto.disciplina.orientacaoCurricular.id === disciplina.orientacaoCurricular.id) || (isEducacaoInfantil() && (objeto.disciplina.eixoTematico ? objeto.disciplina.eixoTematico.id : '') === (disciplina.eixoTematico ? disciplina.eixoTematico.id : '')));
                    });

                    ch = _.has(etapaDisc, 'cargaHorariaHoras') ? (_.isNull(etapaDisc.cargaHorariaHoras) ? 0 : etapaDisc.cargaHorariaHoras) : 0;
                    chMinutos = _.has(etapaDisc, 'cargaHorariaHoras') ? (_.isNull(etapaDisc.cargaHorariaMinutos) ? 0 : etapaDisc.cargaHorariaMinutos) : 0;

                }

                return {
                    idEtapa: idEtapa,
                    value: ch,
                    valueMinutos: chMinutos,
                    descricao: $scope.cols[indexCol].descricao
                };
            };

            var getValue = function (row, field) {
                if (!deveAgrupar) {
                    return;
                }
                return _.find(row, function (coluna) {
                    return coluna.descricao === field;
                }).value;
            };

            var agrupaMatriz = function () {

                var somaCargaHoraria = function (group, final) { // jshint ignore:line
                    var total;

                    var verificaColunasMesclarHorizontal = function (col) {
                        return col.descricao === ITEM || col.descricao === AGRUPADOR && final;
                    };

                    angular.forEach(group, function (row, key) {
                        if (key === 0) {
                            total = angular.copy(row);
                        }

                        var next = group[key + 1];


                        if (Boolean(next)) {
                            angular.forEach(next, function (col, key2) {

                                if (_.has(col, 'idEtapa') || total[key2].descricao === totalChItens) {
                                    if (angular.isNumber(Number(col.value))) {
                                        total[key2].value += Number(col.value);
                                        total[key2].valueMinutos += Number(col.valueMinutos);
                                    } else {
                                        total[key2].value = col.value;
                                    }
                                }


                            });
                        } else if (final) {
                            _.each(row, function (col, key3) {
                                if (group.length > 1 && verificaColunasMesclarHorizontal(col, final)) {
                                    if (_.has(col, 'idEtapa') || total[key3].descricao === totalChItens) {
                                        if (angular.isNumber(Number(col.value))) {
                                            total[key3].value += Number(col.value);
                                            total[key3].valueMinutos += Number(col.valueMinutos);
                                        } else {
                                            total[key3].value = col.value;
                                        }
                                    }


                                    if (col.descricao === AGRUPADOR) {
                                        total[key3].value = TOTAL_CARGA_HORARIA;
                                    }

                                    total[key3].groupFinal = true;
                                    total[key3].subGroup = false;
                                } else if (col.descricao === EIXO_TEMATICO) {
                                    total[key3].eixo = true;
                                }
                            });
                        } else {
                            _.each(row, function (col, key4) {
                                if (group.length > 1) {
                                    if (verificaColunasMesclarHorizontal(col)) {
                                        if (_.has(col, 'idEtapa') || total[key4].descricao === totalChItens) {
                                            if (angular.isNumber(Number(col.value))) {
                                                total[key4].value += 1 + Number(col.value);
                                                total[key4].valueMinutos += Number(col.valueMinutos);
                                            } else {
                                                total[key4].value = 1 + col.value;
                                            }
                                        }
                                    }
                                }

                                if (verificaColunasMesclarHorizontal(col, final)) {
                                    total[key4].subGroup = true;
                                } else if (col.descricao === EIXO_TEMATICO) {
                                    total[key4].eixo = true;
                                }
                            });
                        }
                    });

                    return total;
                };

                var subGroup = [],
                    totalCH = [];

                var inseriAgrupamento = function (resultAgrupamento, action) {
                    action(resultAgrupamento);
                    totalCH.push(angular.copy(resultAgrupamento));
                    subGroup = [];
                };

                var dadosSemAgrupamento = angular.copy($scope.dados);

                _.each(dadosSemAgrupamento, function (current, key) {

                    var next = dadosSemAgrupamento[key + 1];
                    subGroup.push(current);

                    if (!next) {
                        inseriAgrupamento(somaCargaHoraria(subGroup), function (resultAgrupamento) {
                            $scope.dados.push(resultAgrupamento);
                        });
                    } else if (getValue(current, AGRUPADOR) !== getValue(next, AGRUPADOR)) {
                        inseriAgrupamento(somaCargaHoraria(subGroup), function (resultAgrupamento) {
                            $scope.dados.splice(key + 1, 0, resultAgrupamento);
                        });
                    }


                });

                inseriAgrupamento(somaCargaHoraria(totalCH, true),
                    function (resultAgrupamento) {
                        if (totalCH.length === 1) {
                            $scope.dados.pop();
                        }
                        $scope.dados.push(resultAgrupamento);
                    });


                if (isEducacaoInfantil() && deveAgrupar) {
                    $scope.dados = _.sortBy($scope.dados, function (data) {
                        if (!data[0].groupFinal) {
                            return data[0].value + (data[1].subGroup ? data[0].value + '1' : data[0].value);
                        }
                    });
                }

            };

            $scope.verificaColunasMesclarVertical = function (col) {
                return _.has(col, 'descricao') && col.descricao === AGRUPADOR;
            };

            $scope.mesclarGrupo = function (dadoCol, indexRow) {

                var show;

                var isIgualValorAnterior = function () {
                    return dadoCol.value === getValue($scope.dados[indexRow - 1], dadoCol.descricao);
                };

                var exibeColVertical = function () {
                    show = $scope.verificaColunasMesclarVertical(dadoCol) ?
                        (indexRow > 0 ? !isIgualValorAnterior() : true) :
                        true;
                    return {
                        colspan: 1,
                        show: show
                    };
                };

                var mesclaHorizontal = function () {
                    var nroCols = $scope.possuiEixos === true ? 3 : !deveAgrupar ? 1 : 2;

                    return {
                        colspan: dadoCol.groupFinal ? nroCols : dadoCol.subGroup ? nroCols - 1 : 1,
                        show: show,
                        rowspan: 1
                    };
                };

                if (_.has(dadoCol, 'eixo') && dadoCol.eixo) {
                    show = Boolean(dadoCol.idEtapa);
                    return {
                        colspan: 1,
                        show: show,
                        rowspan: 1
                    };
                } else if (_.has(dadoCol, 'groupFinal') && dadoCol.groupFinal) {
                    show = dadoCol.descricao === AGRUPADOR;
                    return mesclaHorizontal();
                } else if (_.has(dadoCol, 'subGroup') && dadoCol.subGroup) {
                    show = $scope.habilitaEixo(dadoCol);
                    return mesclaHorizontal();
                } else {
                    return exibeColVertical();
                }

            };

            function isDisciplinas() {
                return $scope.matriz.tipoOrganizacao === 'DISCIPLINA' || !$scope.matriz.tipoOrganizacao;
            }

            function isSomenteEixoTematico() {
                return $scope.matriz.tipoOrganizacao === 'EIXO_TEMATICO';
            }

            function isCampoExperiencia() {
                return $scope.matriz.tipoOrganizacao === 'CAMPOS_EXPERIENCIA';
            }

            function isEducacaoInfantil() {
                return $scope.matriz.curso.nivelEscolar === 'EDUCACAO_INFANTIL';
            }

            $scope.nroRowsMesclar = function (dadoCol) {
                return _.filter($scope.dados, function (row) {
                    return getValue(row, dadoCol.descricao) === dadoCol.value;
                }).length;
            };

            $scope.colunaNegrito = function (dadoCol) {
                return _.has(dadoCol, 'groupFinal') && dadoCol.groupFinal ||
                    _.has(dadoCol, 'subGroup') && dadoCol.subGroup ||
                    _.has(dadoCol, 'descricao') && dadoCol.descricao === AGRUPADOR;
            };

            $scope.cleanText = function (data, dataLast) {

                if (data.valueMinutos) {
                    if (data.valueMinutos > 59) {
                        data.value += 1;
                        data.valueMinutos = (data.valueMinutos - 60);
                    }
                }

                var valorEmMinutos = data.valueMinutos < 10 ? ':0' + data.valueMinutos : data.valueMinutos ? ':' + data.valueMinutos : '';

                var out = _.has(data, 'value') ? data.value + valorEmMinutos : null;

                if (out === '0:00') {
                    out = '-';
                }

                if (_.has(data, 'descricao') && data.descricao === ITEM && _.has(data, 'subGroup')) {
                    if (Boolean(data.subGroup)) {
                        if (deveAgrupar) {
                            out = 'Total ' + (_.has(dataLast, 'value') ? dataLast.value : null);
                        } else {
                            out = TOTAL_CARGA_HORARIA;
                        }
                    }
                }
                return _.isBoolean(out) ? '' : out + '';
            };

            $scope.cancel = function () {
                $modalInstance.close();
            };

            $scope.habilitaEixo = function (dado) {
                if (dado.descricao === EIXO_TEMATICO) {
                    return $scope.possuiEixos;
                } else {
                    return true;
                }

            };

            $scope.habilitaColunaAgrupadora = function (dado) {
                if (dado.descricao === AGRUPADOR && !deveAgrupar && !_.has(dado, 'groupFinal')) {
                    return false;
                } else {
                    return true;
                }

            };

            $scope.getDescricao = function (dado) {
                if ((dado.descricao === ITEM && isSomenteEixoTematico()) || (dado.descricao === AGRUPADOR && agrupadoPor.eixo())) {
                    return EIXO_TEMATICO;
                }
                return dado.descricao;
            };



            $scope.$on('refreshMatrizCurricular', function (event, args) {

                if (args && args.data.id === idMatriz) {
                    if (args.remove) {
                        $scope.cancel();
                    } else if (args.undo || args.edit) {
                        inicializar();
                    }
                }
            });


        }]);
})();

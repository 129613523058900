(function() {
    'use strict';

    angular.module('educacao.relatorio').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.relatorio', {
            url: 'relatorio',
            views: {
                '@': {
                    templateUrl: 'relatorio/relatorio-list.html'
                }
            }
        });
    }
})();
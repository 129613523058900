(function () {
    'use strict';

    angular.module('educacao.matricula')
        .directive('stepInscrevendo', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: {
                inscricao: '='
            },

            templateUrl: 'matricula/inscricao/step-inscrevendo/step-inscrevendo.directive.html',

            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope', 'promiseTracker', 'InscricaoMatriculaRegularService', 'educacao.enderecos.BairroService',
        'educacao.enderecos.MunicipioService','educacao.enderecos.EstadoService','bfc.$$PermissionsService'];

    function Controller($scope, promiseTracker, InscricaoMatriculaRegularService, BairroService, MunicipioService,EstadoService,$$PermissionsService) {
        var vm = this;

        vm.NUM_MIN_ESTABS_ZONEAMENTO = 1;
        vm.formularioPreenchido = false;
        vm.MunicipioService = MunicipioService;
        vm.BairroService = BairroService;
        vm.EstadoService = EstadoService;
        vm.PermissaoService = $$PermissionsService;
        vm.loading = promiseTracker();

        vm.stepBack = stepBack;
        vm.confirmaPreenchimento = confirmaPreenchimento;
        vm.confirmaSelecaoEstabelecimentos = confirmaSelecaoEstabelecimentos;
        vm.disableConcluirButton = disableConcluirButton;
        vm.validateFiliacao = validateFiliacao;

        $scope.inscricao.$$estabelecimentosOnEdit = $scope.inscricao.id ? _.cloneDeep($scope.inscricao.estabelecimentos) : [];

        function stepBack() {
            $scope.inscricao.$$turnosSelecionados = false;
        }

        function confirmaPreenchimento() {
            var inscricao = $scope.inscricao;

            var idConfiguracao = inscricao.configuracao.id;
            var turnos = inscricao.turnos;

            var coordenadaEndereco = getCoordenadaEnderecoInscrito(inscricao);
            var latitudeResidencia = coordenadaEndereco.latitude;
            var longitudeResidencia = coordenadaEndereco.longitude;

            var equivalencia = inscricao.equivalencia;
            var dataNascimento = inscricao.dados.dataNascimento;

            var value;
            var listEstabelecimentos;

            if (equivalencia) {
                value = equivalencia;
                listEstabelecimentos = InscricaoMatriculaRegularService.listEstabelecimentosByEquivalencia;
            } else {
                value = dataNascimento;
                listEstabelecimentos = InscricaoMatriculaRegularService.listEstabelecimentosInfantil;
            }
            var promise = listEstabelecimentos(idConfiguracao, value, turnos, latitudeResidencia, longitudeResidencia)
                .then(setEstabelecimentos);

            vm.loading.addPromise(promise);
        }

        function setEstabelecimentos(dados) {
            vm.estabelecimentos = dados;
            vm.formularioPreenchido = true;
        }

        function getCoordenadaEnderecoInscrito(inscricao) {
            var endereco = inscricao.dados.enderecoResidencia;
            return endereco && endereco.coordenada || {};
        }

        function confirmaSelecaoEstabelecimentos() {
            vm.formularioPreenchido = false;
            $scope.inscricao.$$estabelecimentosSelecionados = true;
        }

        function disableConcluirButton() {
            var estabelecimentos = $scope.inscricao.estabelecimentos;
            var enderecoInscricao = _.get($scope.inscricao.dados, 'enderecoResidencia');

            if (!estabelecimentos || !estabelecimentos.length) {
                return true;
            }

            return isZoneamentoNotValid(estabelecimentos, enderecoInscricao);
        }

        function isZoneamentoNotValid(estabelecimentos, enderecoInscricao) {
            var utilizaZoneamento = $scope.inscricao.configuracao.utilizaZoneamento;
            var numMaxEstabelecimentos = $scope.inscricao.configuracao.qtdEstabelecimentos;
            var numMaxEstabelecimentosZoneamento = $scope.inscricao.configuracao.qtdEstabelecimentosZoneamento;
            var numMaxEstabelecimentosForaZoneamento = $scope.inscricao.configuracao.qtdEstabelecimentosForaZoneamento;

            vm.escolhidosZoneamento = 0;
            vm.escolhidosForaZoneamento = 0;

            if (!utilizaZoneamento) {
                return false;
            }

            var uniqEstabelecimentos = _.uniq(estabelecimentos, 'estabelecimento.id');
            _.forEach(uniqEstabelecimentos, function (estabelecimento) {
                countEstabelecimentosZoneamento(_.get(estabelecimento, 'estabelecimento'), enderecoInscricao);
            });

            return vm.escolhidosZoneamento + vm.escolhidosForaZoneamento > numMaxEstabelecimentos ||
                vm.escolhidosForaZoneamento > numMaxEstabelecimentosForaZoneamento ||
                vm.escolhidosZoneamento > numMaxEstabelecimentosZoneamento ||
                vm.escolhidosZoneamento < vm.NUM_MIN_ESTABS_ZONEAMENTO;
        }

        function countEstabelecimentosZoneamento(estabelecimento, enderecoInscricao) {
            var enderecoEstab = _.find(estabelecimento.enderecos, 'principal');

            if (!enderecoEstab) {
                vm.escolhidosForaZoneamento = vm.escolhidosForaZoneamento + 1;
                return;
            }

            var zoneamentoInscricao = enderecoInscricao.bairroEndereco.regiao;
            var zoneamentoEstab = enderecoEstab.bairroEndereco.regiao;

            if (!zoneamentoEstab || !zoneamentoInscricao) {
                vm.escolhidosForaZoneamento = vm.escolhidosForaZoneamento + 1;
                return;
            }

            if (zoneamentoInscricao.id === zoneamentoEstab.id) {
                vm.escolhidosZoneamento = vm.escolhidosZoneamento + 1;
            } else {
                vm.escolhidosForaZoneamento = vm.escolhidosForaZoneamento + 1;
            }
        }

        function validateFiliacao() {
            if (!$scope.inscricao || !$scope.inscricao.configuracao) {
                return true;
            }
            
            var campos = $scope.inscricao.configuracao.campos;
            var inscricaoDados = $scope.inscricao.dados;

            var filiacao = _.find(campos, { campo: 'FILIACAO' });

            if (filiacao.obrigatorio) {
                return !inscricaoDados.filiacoes || !_.isEmpty(inscricaoDados.filiacoes);
            }

            return true;
        }
    }
})();
(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('otherSystemActions', directive);

    function directive() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'common/layout/header/other-system-actions/other-systems-actions.directive.html',
            replace: true
        };
    }

})();

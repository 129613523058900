(function() {
	'use strict';

	angular.module('educacao.common')
		.filter('appEstatura', filter);

	function filter() {
		return function(input, decimals) {
			if (_.isNull(input) || _.isUndefined(input) || !_.isFinite(Number(input))) {
				return '0 m 0 cm';
			}

			var numDecimals = decimals || 2;

			var DOT_SEPARATOR = '.';

			var estaturaSplit = String(input).split(DOT_SEPARATOR);

			var metros = _.first(estaturaSplit);
			var decimalValue = _.first(_.rest(estaturaSplit)) || '0';

			if (decimalValue.length < numDecimals) {
				var diff = numDecimals - decimalValue.length;
				var diffCharacters = _.repeat('0', diff);
				decimalValue = decimalValue + diffCharacters;
			}

			return metros + ' m ' + decimalValue + ' cm';
		};
	}
})();

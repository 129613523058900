(function () {
    'use strict';

    var IGNOREDED_INPUT = {
        'ng-repeat': true,
        'data-ng-repeat': true
    };

    angular.module('educacao.common')
        .service('educacao.common.TranscludeAttributes', service);

    function service() {
        return {transcludeInputAttributes: transcludeInputAttributes};

        function transcludeInputAttributes(src, dst) {
            transcludeAttributes(src, dst, IGNOREDED_INPUT);
        }

        function transcludeAttributes(src, dst, ignored) {
            var attrNames = _.map(src[0].attributes, function (attr) {
                return attr.name;
            });

            _.forEach(attrNames, function (attr) {
                if (!ignored[attr] && attr.indexOf('-') !== -1) {
                    dst.attr(attr, src.attr(attr));
                    src.removeAttr(attr);
                }
            });
        }
    }
})();

(function() {
    'use strict';
    angular.module('educacao.desempenhoescolar')
        .service('educacao.desempenhoescolar.RegistroAvaliacoesService', RegistroAvaliacoesService);

    RegistroAvaliacoesService.$inject = ['Restangular', 'ui.components.EventEmitter', 'MetadataCache'];

    function RegistroAvaliacoesService(Restangular, EventEmitter, MetadataCache) {
        var configuracaoMetadataCache = MetadataCache.create(getEndpointConfiguracao);
        var avaliacaoMetadataCache = MetadataCache.create(getEndpointAvaliacao);

        var service = new EventEmitter();

        service.getEnum = function(name) {
            return configuracaoMetadataCache.getEnum(name)
                .then(function(data) {
                    return data || avaliacaoMetadataCache.getEnum(name);
                });
        };

        service.getConfiguracaoAvaliacao = getConfiguracaoAvaliacao; //periodos-avaliativos

        service.listItensAvaliaveis = listItensAvaliaveis; //itens-avaliaveis
        service.listItensAvaliaveisModulo = listItensAvaliaveisModulo; //itens-avaliaveis-modulo
        service.listItensAvaliaveisAreaConhecimento = listItensAvaliaveisAreaConhecimento;
        service.getFormulasCalculoAreasConhecimento = getFormulasCalculoAreasConhecimento;

        service.getData = getData;
        service.saveDate = saveDate;
        service.listAvaliacoes = listAvaliacoes;
        service.salvar = salvar;
        service.salvarPeriodoEncerrado = salvarPeriodoEncerrado;
        service.calcularFormula = calcularFormula;
        service.statusCalculo = statusCalculo;
        service.alterarEnturmacaoSituacao = alterarEnturmacaoSituacao;

        service.listItensAvaliaveisCha = listItensAvaliaveisCha;
        service.listItensAvaliaveisObjetivoAprendizagem = listItensAvaliaveisObjetivoAprendizagem;

        return service;

        function getEndpointConfiguracao(idTurma) {
            return Restangular.all('commons').one('turma', idTurma || 0).one('configuracao-avaliacoes', '');
        }

        function getEndpointAvaliacao(idTurma) {
            return Restangular.all('commons').one('turma', idTurma || 0).all('avaliacoes');
        }
        
        function getConfiguracaoAvaliacao(idTurma) {
            return getEndpointConfiguracao(idTurma).get();
        }

        function listItensAvaliaveis(idTurma, filter) {
            filter = filter || '';

            return getEndpointConfiguracao(idTurma).one('itens-avaliaveis', '').get({
                filter: filter,
                limit: 999
            });
        }

        function alterarEnturmacaoSituacao(id, param){
            return Restangular.all('enturmacao-situacao').one(''+id).customPUT(param, '');
        }
        function listItensAvaliaveisAreaConhecimento(idTurma, idItemEducacional, filter) {
            return Restangular.all('commons').one('turma', idTurma || 0)
                .one('configuracao-avaliacoes', idItemEducacional || 0)
                .all('area-conhecimento-avaliaveis').get('', {
					filter: filter,
                    limit: 999
                });
        }

        function listAvaliacoes(idTurma, filter) {
            return getEndpointAvaliacao(idTurma).one('completos', '').get({
                filter: filter,
                limit: 999
            });
        }

        function listItensAvaliaveisCha(registroAvaliacao) {

            var filter = '(';

            filter += ' tipoPeriodo= "' + registroAvaliacao.avaliavel.tipoPeriodo + '"';

            if (registroAvaliacao.avaliavel.itemEducacional) {
                filter += ' and itemEducacional= ' + registroAvaliacao.avaliavel.itemEducacional.id;
            }

            if (registroAvaliacao.avaliavel.periodoAvaliativo) {
                filter += ' and periodoAvaliativo= ' + registroAvaliacao.avaliavel.periodoAvaliativo.id;
            }

            filter += ')';

            return getEndpointAvaliacao(registroAvaliacao.enturmacao.turma.id).one('avaliacoes-chas', '').get({
                filter: filter,
                registroAvaliacaoId: _.get(registroAvaliacao, 'id')
            });
        }

        function listItensAvaliaveisObjetivoAprendizagem(registroAvaliacao) {

            var filter = '(';

            filter += ' tipoPeriodo= "' + registroAvaliacao.avaliavel.tipoPeriodo + '"';

            if (registroAvaliacao.avaliavel.itemEducacional) {
                filter += ' and itemEducacional= ' + registroAvaliacao.avaliavel.itemEducacional.id;
            }

            if (registroAvaliacao.avaliavel.periodoAvaliativo) {
                filter += ' and periodoAvaliativo= ' + registroAvaliacao.avaliavel.periodoAvaliativo.id;
            }

            filter += ')';

            return getEndpointAvaliacao(registroAvaliacao.enturmacao.turma.id).one('avaliacoes-objetivos-aprendizagem', '').get({
                filter: filter,
                registroAvaliacaoId: _.get(registroAvaliacao, 'id')
            });
        }

        function getData(turma, itemAvaliavel) {
            var params = {
                tipoPeriodo: itemAvaliavel.tipoPeriodo,
                identificadorExameFinal: itemAvaliavel.identificadorExameFinal,
                itemEducacional: _.get(itemAvaliavel, 'itemEducacional.id')
            };

            return getEndpointAvaliacao(turma.id).get('data-exame-conselho', params);
        }

        function listItensAvaliaveisModulo(idTurma, etapaMatrizDisciplina) {
            return Restangular.all('commons')
                .one('avaliacoes', idTurma)
                .one('itens-avaliaveis-modulo')
                .get({
                    etapaMatrizDisciplina: etapaMatrizDisciplina,
                    limit: 999
                });
        }

        function getFormulasCalculoAreasConhecimento(filter, offset, limit) {
            return Restangular.all('commons/formula-calculo')
                .one('formulas-areas-conhecimento', '').get({
                    filter: filter,
                    offset: offset,
                    limit: limit
                });
        }

        function calcularFormula(idItemEducacional, idTurma, idPeriodo, idFormula){
            return getEndpointAvaliacao(idTurma)
            .customPOST('', 'areas-conhecimento/calcular', {
                itemEducacionalId: idItemEducacional,
                periodoAvaliativoId: idPeriodo,
                formulaId: idFormula
            });
        }

        function statusCalculo(idTurma, idPeriodo, idItemEducacional) {
            return getEndpointAvaliacao(idTurma)
                .one('areas-conhecimento/status-calculo', '').get({
                    periodoAvaliativoId: idPeriodo,
                    itemEducacionalId: idItemEducacional
                });
        }

        function saveDate(turma, data) {
            return getEndpointAvaliacao(turma.id).all('data-exame-conselho').post(data);
        }

        function salvar(idTurma, nota) {
            var endpoint = getEndpointAvaliacao(idTurma);
            return nota.id ? endpoint.customPUT(nota, nota.id) : endpoint.post(nota);
        }
        
        function salvarPeriodoEncerrado(idTurma, nota) {
            var endpoint = getEndpointAvaliacao(idTurma);
            return nota.id ? endpoint.all('periodo-encerrado/').customPUT(nota, nota.id) : endpoint.all('periodo-encerrado').post(nota);
        }
    }
})();

(function() {

    'use strict';

    angular.module('configuracao-avaliacao-turma')
        .directive('appObjetivosAprendizagem', appObjetivosAprendizagem);

    function appObjetivosAprendizagem() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/turma/configuracao-avaliacao/app-table-configuracao-avaliacao/app-objetivos-aprendizagem/app-objetivos-aprendizagem.directive.html',
            scope: {},
            bindToController: {
                componente: '=',
                periodos: '=',
                hasExameFinal: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'ui.components.Modal'
    ];

    function Controller(uiModal) {
        var vm = this;

        vm.abrirModal = abrirModal;

        function abrirModal() {
            var itensAvaliaveisObjetivoAprendizagem = vm.componente.$$itensAvaliaveisObjetivoAprendizagem ?
                vm.componente.$$itensAvaliaveisObjetivoAprendizagem : _.filter(vm.componente.itensAvaliaveis, 'tipo', 'OBJETIVOS_APRENDIZAGEM');

            uiModal.open({
                templateUrl: 'matricula/turma/configuracao-avaliacao/app-table-configuracao-avaliacao/app-objetivos-aprendizagem/modal-objetivos-aprendizagem.html',
                controller: 'educacao.matricula.turma.configuracao-avaliacao.ModalObjetivosAprendizagemController',
                controllerAs: 'vm',
                params: {
                    componente: vm.componente,
                    itensAvaliaveisObjetivoAprendizagem: itensAvaliaveisObjetivoAprendizagem,
                    periodos: vm.periodos,
                    hasExameFinal: vm.hasExameFinal
                },
                size: 'sm'
            }).result.then(function() {
                vm.componente.itensAvaliaveisObjetivoAprendizagem = vm.componente.$$itensAvaliaveisObjetivoAprendizagem;
            });
        }
    }

})();

(function () {
    'use strict';

    angular.module('educacao.relatorio')
        .controller('educacao.relatorio.RelatorioListController', RelatorioListController);

    RelatorioListController.$inject = ['educacao.common.$commons', 'URL_RELATORIO', 'educacao.relatorio.RelatorioService'];
    function RelatorioListController($commons, URL_RELATORIO, RelatorioService) {

        var vm = this;

        vm.tracker = {list: $commons.$promiseTracker()};
        vm.RelatorioService = RelatorioService;

        vm.imprimir = imprimir;
        vm.reenviar = reenviar;

        function imprimir(relatorio) {
            return URL_RELATORIO + '?id=' + relatorio.chave;
        }

        function reenviar(relatorio) {
            if (relatorio.tipo === 'BOLETIM') {
                $commons.$notification.publish(
                    'Esta opção não está mais disponível para boletim.',
                    'error');
                return;
            }
            if(moment().diff(moment(relatorio.createdIn), 'minutes') > 5){
                vm.tracker.list.addPromise(
                    RelatorioService.reenviar(relatorio)
                    .then($commons.$notification.publish.bind(null, 'Relatório reenviado para processamento', 'success')));
            }else{
                $commons.$notification.publish('O relatório só pode ser reenviado após 5 minutos', 'warning');
            }
            
        }

    }
})();

(function () {

    'use strict';

    angular.module('educacao.itemAvaliavel')
        .service('educacao.itemAvaliavel.ItemAvaliavelUtilsService', ItemAvaliavelUtilsService);

    ItemAvaliavelUtilsService.$inject = ['ITEM_AVALIAVEL_TIPO_PERIODO'];

    function ItemAvaliavelUtilsService(tipoPeriodoConstants) {

        var self = this;

        self.isTipoPeriodoExameFinal = isTipoPeriodoExameFinal;
        self.isTipoPeriodoMediaPeriodo = isTipoPeriodoMediaPeriodo;
        self.isTipoPeriodoMediaFinal = isTipoPeriodoMediaFinal;
        self.isTipoPeriodoCalculo = isTipoPeriodoCalculo;
        self.isTipoPeriodoNotaParaExame = isTipoPeriodoNotaParaExame;
        self.isTipoPeriodoConselhoClasse = isTipoPeriodoConselhoClasse;

        function isTipoPeriodoExameFinal(itemAvaliavel) {
            return (_.get(itemAvaliavel, 'tipoPeriodo') === tipoPeriodoConstants.EXAME_FINAL);
        }

        function isTipoPeriodoMediaPeriodo(itemAvaliavel) {
            return (_.get(itemAvaliavel, 'tipoPeriodo') === tipoPeriodoConstants.MEDIA_PERIODO);
        }

        function isTipoPeriodoMediaFinal(itemAvaliavel) {
            return (_.get(itemAvaliavel, 'tipoPeriodo') === tipoPeriodoConstants.MEDIA_FINAL);
        }

        function isTipoPeriodoCalculo(itemAvaliavel) {
            return (_.get(itemAvaliavel, 'tipoPeriodo') === tipoPeriodoConstants.CALCULO_MEDIA);
        }

        function isTipoPeriodoNotaParaExame(itemAvaliavel) {
            return (_.get(itemAvaliavel, 'tipoPeriodo') === tipoPeriodoConstants.NOTA_PARA_EXAME);
        }

        function isTipoPeriodoConselhoClasse(itemAvaliavel){
            return (_.get(itemAvaliavel, 'tipoPeriodo') === tipoPeriodoConstants.CONSELHO_CLASSE);
        }
    }
})();


(function() {
	'use strict';

	angular.module('educacao.matricula')
		.directive('listaClassificaoInscricao', listaClassificaoInscricao);

	function listaClassificaoInscricao() {

		var config = {};

		config.templateUrl = 'matricula/classificacao-inscritos/classificacao-inscritos-lista.html';
		config.restrict = 'E';
		config.scope = {};
		config.scope.nivelEscolar = '=';
		config.scope.niveisEscolares = '=';
		config.controller = Controller;
		config.controllerAs = 'vm';

		return config;
	}

	Controller.$inject = ['$injector', '$scope', '$rootScope', '$state', '$tourGuiadoService', 'ui.components.Modal', '$enumsCache'];

	function Controller($injector, $scope, $rootScope, $state, $tourGuiadoService, uiModal, $enumsCache) {

		var $q = $injector.get('$q');
		var bfcDialog = $injector.get('bfc.dialog.Dialog');
		var promiseTracker = $injector.get('promiseTracker');
		var ConfiguracaoProcessoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoProcessoInscricaoService');
		var ConfiguracaoInscricaoService = $injector.get('educacao.matricula.ConfiguracaoInscricaoService');
		var ClassificacaoInscritosService = $injector.get('educacao.matricula.ClassificacaoInscritosService');
		var ClassificacaoService = $injector.get('educacao.matricula.ClassificacaoService');
		var IndeferirClassificacaoService = $injector.get('educacao.matricula.IndeferirClassificacaoService');
		var MatricularClassificacaoService = $injector.get('educacao.matricula.MatricularClassificacaoService');
		var InscricaoMatriculaRegularService = $injector.get('InscricaoMatriculaRegularService');
		var AlunoService = $injector.get('educacao.pessoas.AlunoService');
		var $commons = $injector.get('educacao.common.$commons');
		var $filter = $injector.get('$filter');
		var $interval = $injector.get('$interval');
		var $timeout = $injector.get('$timeout');
		var Notification = $injector.get('bfc.Notification');

		var vm = this,
			REFRESH_CONFIGURACOES = 'refresh::configuracoesInscricao',
			REFRESH_CLASSIFICACOES = 'refresh::classificacoes',
			DATE_FORMAT = 'DD/MM/YYYY',
			DATE_FORMAT_MSG_AVISO = 'DD/MM',
			DATE_FORMAT_DIFF = 'YYYY-MM-DD';

		vm.arrayAvisos = [];
		vm.habilitaChamada = false;
		var firstOpen = true;
		var haveOldList;

		vm.tracker = {};
		vm.tracker.loading = promiseTracker();
		vm.tracker.listaClassificacao = promiseTracker();

		vm.openMensagem = openMensagem;
		vm.openChamada = openChamada;
		vm.openNovaChamada = openNovaChamada;
		vm.openModalCadConfiguracaoInscricao = openModalCadConfiguracaoInscricao;
		vm.openAlerta = openAlerta;
		vm.removeConfiguracao = removeConfiguracao;
		vm.openEncerrar = openEncerrar;
		vm.changeTab = changeTab;
		vm.isClassificacaoIniciada = isClassificacaoIniciada;
		vm.isPermiteOperacoesClassificacao = isPermiteOperacoesClassificacao;
		vm.mensagemEncerramentoProcesso = mensagemEncerramentoProcesso;

		vm.isInscricaoAguardando = isInscricaoAguardando;
		vm.isInscricaoAberta = isInscricaoAberta;
		vm.isPeriodoEncerrado = isPeriodoEncerrado;
		vm.isChamadaIniciada = isChamadaIniciada;
		vm.isChamadaEncerrada = isChamadaEncerrada;
		vm.iniciarClassificacao = iniciarClassificacao;
		vm.desfazerClassificacao = desfazerClassificacao;
		vm.isEncerrado = isEncerrado;
		vm.isEncerradoSemClassificacao = isEncerradoSemClassificacao;
		vm.isClassificada = isClassificada;
		vm.isNovaVisaoClassificado = isNovaVisaoClassificado;

		vm.showUtilizaEncaminhamento = showUtilizaEncaminhamento;
		vm.encaminhar = encaminhar;
		vm.inscritosEncaminhamentoLote = [];

		vm.indeferir = indeferir;
		vm.desfazerIndeferimento = desfazerIndeferimento;
		vm.listaEspera = listaEspera;
		vm.desfazerEncerramento = desfazerEncerramento;
		vm.visualizarInscricao = visualizarInscricao;
		vm.emitClearEncaminhamentoLote = emitClearEncaminhamentoLote;

		vm.mensagemPeriodoChamada = mensagemPeriodoChamada;
		vm.searching = searching;
		vm.modalTabsStep = modalTabsStep;

		vm.currentTab = 0;

		vm.nivelEscolarSelecionado = $scope.nivelEscolar;

		vm.organizador = '1';
		vm.matricular = openMatriculaModal;

		vm.editar = editar;

		$scope.search = {};
		$scope.search.filter = {};

		var gruposClassificados = [
			['classificacaoInscricao.estabelecimento.nome',
				'classificacaoInscricao.turno',
				'classificacaoInscricao.etapa'
			],
			['classificacaoInscricao.turno',
				'classificacaoInscricao.estabelecimento.nome',
				'classificacaoInscricao.etapa'
			],
			['classificacaoInscricao.etapa',
				'classificacaoInscricao.estabelecimento.nome',
				'classificacaoInscricao.turno'
			]
		];
		vm.tabs = [{
			id: 1,
			descricao: 'Inscritos',
			name: 'inscritos',
			nameTotalVarios: 'inscrições',
			nameTotalUm: 'inscrição',
			classe: 'text-center TabGreen1',
			groups: [
				['nomeEstabelecimento', 'turno', 'equivalencia'],
				['turno', 'nomeEstabelecimento', 'equivalencia'],
				['equivalencia', 'nomeEstabelecimento', 'turno']
			]

		}, {
			id: 2,
			descricao: 'Classificados',
			name: 'classificados',
			nameTotalVarios: 'classificações',
			nameTotalUm: 'classificação',
			classe: 'text-center TabGreen2',
			groups: [
				['resultadoClassificacao.classificacaoInscricao.estabelecimento.nome',
					'resultadoClassificacao.classificacaoInscricao.turno',
					'resultadoClassificacao.classificacaoInscricao.etapa'
				],
				['resultadoClassificacao.classificacaoInscricao.turno',
					'resultadoClassificacao.classificacaoInscricao.estabelecimento.nome',
					'resultadoClassificacao.classificacaoInscricao.etapa'
				],
				['resultadoClassificacao.classificacaoInscricao.etapa',
					'resultadoClassificacao.classificacaoInscricao.estabelecimento.nome',
					'resultadoClassificacao.classificacaoInscricao.turno'
				]
			]
		}, {
			id: 3,
			descricao: 'Matriculados',
			name: 'matriculados',
			nameTotalVarios: 'matriculados',
			nameTotalUm: 'matriculado',
			classe: 'text-center TabGreen3',
			groups: gruposClassificados
		}, {
			id: 4,
			descricao: 'Indeferidos',
			name: 'indeferimento',
			nameTotalVarios: 'indeferimentos',
			nameTotalUm: 'indeferimento',
			classe: 'text-center TabGreen4',
			groups: gruposClassificados
		}, {
			id: 3,
			descricao: 'Encaminhados',
			name: 'encaminhado',
			nameTotalVarios: 'encaminhados',
			nameTotalUm: 'encaminhado',
			classe: 'text-center TabGreen5'
		}];

		vm.columns = [
			{
				id: 'candidato',
				label: 'Candidato',
				model: 'nome',
				filterable: true
			}];

		$rootScope.$on('refresh::configuracoesInscricao', init);
		$rootScope.$on('clearEncaminhamentoLote', clearEncaminhamentoLote);

		init();

		function init() {
			vm.processos = [];
			vm.tracker.loading.addPromise(
				$q.all([
					refreshProcessos(),
					ConfiguracaoInscricaoService.getNivelEscolar().then(function(data) {
						vm.NivelEscolarEnum = data;
					}),
					InscricaoMatriculaRegularService.getEquivalenciaEtapa().then(function(EquivalenciaEtapa) {
						vm.EquivalenciaEtapa = EquivalenciaEtapa;
					})
				])
				.then(getTurnoEnum)
				.then(postInit)
			);
		}

		function postInit() {
			carregaMensagensFeedBak();
			if (vm.isPossuiProcesso) {
				refreshListas();
			}

			loadTour();
		}

		function getTurnoEnum() {
			$enumsCache.get('Turno').then(function (data) {
				vm.turnos = data;
			});
		}

		function loadTour() {
			$timeout(function() {
				$tourGuiadoService.startTour('TOUR_GUIADO_INSCRICAO_ONLINE', false);
			}, 300);
		}

		function loadTourInscricao() {
			$timeout(function() {
				$tourGuiadoService.startTour('TOUR_GUIADO_INSCRICAO_ONLINE_PROCESSO', false);
			}, 300);
		}
		

		function refreshProcessos() {
			return ConfiguracaoProcessoInscricaoService.getProcessosInscricao()
				.then(function(data) {
					angular.copy(data, vm.processos);
					verificaProcessoInscricao();
				});
		}

		function tabInit() {
			if ((isClassificacaoIniciada() || isEncerrado()) && vm.currentTab === 0) {
				vm.currentTab = 1;
				modalTabsStep();
			}
		}

		function verificaProcessoInscricao() {
			vm.processo = {};
			vm.nivelEscolarSelecionado = $scope.nivelEscolar;

			angular.forEach(vm.processos, function(value) {
				tooltipNiveisEcolares(value);
				if (value.nivelEscolar === vm.nivelEscolarSelecionado) {
					vm.processo = _.clone(value);
				}
			});
			vm.isPossuiProcesso = !_.isEmpty(vm.processo);
			atualizaMsgDeAviso();
		}

		function carregaMensagensFeedBak() {
			atualizaMsgExibicao();
			atualizaMsgDeAviso();
		}

		function atualizaMsgDeAviso() {
			if (vm.NivelEscolarEnum && vm.isPossuiProcesso) {
				vm.mensagemAviso = 'Período de inscrições: de ' + moment(vm.processo.dataHoraInicio).format(DATE_FORMAT) + ' a ' +
					moment(vm.processo.dataHoraFim)
					.format(DATE_FORMAT);

			}
		}

		function carregaAvisoDaChamada() {
			vm.avisoChamada = '';
			if (vm.chamada && vm.chamada.dataFim && vm.chamada.dataInicio) {
				if (!isEncerrado()) {
					var diasRestantes = moment(vm.chamada.dataFim).diff(moment(new Date()).format(DATE_FORMAT_DIFF), 'days');

					if (diasRestantes >= 0) {
						vm.avisoChamada = 'A ' + vm.chamada.numeroChamada +
							'ª chamada encerra em ' + diasRestantes + ' dia(s) ' +
							moment(vm.chamada.dataFim).format(DATE_FORMAT_MSG_AVISO);
					}
				}
			}
		}

		function tooltipNiveisEcolares(value) {
			var descricao = 'Classificação';
			if (value.status === 'ENCERRADO') {
				descricao += ' encerrada';
			} else if (value.status === 'AGUARDANDO' || value.status === 'ABERTO' || value.status === 'PERIODO_ENCERRADO') {
				descricao += ' não iniciada';
			} else {
				descricao += ' iniciada';
			}

			if (value.nivelEscolar === 'EDUCACAO_INFANTIL') {
				$scope.niveisEscolares.tooltipInfantil = descricao;
			} else if (value.nivelEscolar === 'ENSINO_FUNDAMENTAL') {
				$scope.niveisEscolares.tooltipFundamental = descricao;
			} else if (value.nivelEscolar === 'ENSINO_MEDIO') {
				$scope.niveisEscolares.tooltipMedio = descricao;
			}
		}

		function atualizaMsgExibicao() {
			if (isGenereroNivelEscolarFeminino() && vm.NivelEscolarEnum) {
				vm.mensagemFeedBack = 'Para iniciar a inscrição de alunos na ' + vm.NivelEscolarEnum[vm.nivelEscolarSelecionado].description
					.toLowerCase() +
					' você precisa';
			} else if (vm.NivelEscolarEnum && vm.nivelEscolarSelecionado) {
				vm.mensagemFeedBack = 'Para iniciar a inscrição de alunos no ' + vm.NivelEscolarEnum[vm.nivelEscolarSelecionado].description
					.toLowerCase() +
					' você precisa';
			}
		}

		function isGenereroNivelEscolarFeminino() {
			return vm.nivelEscolarSelecionado === 'EDUCACAO_INFANTIL';
		}

		$scope.$watch('search.value', onSearch);

		function onSearch(value) {
			$scope.search.filter = {
				'$': value
			};
			vm.lista = $filter('filter')(vm.listaOriginal, $scope.search.filter);
			vm.listaFiltrada = vm.lista;
		}

		function searching() {
			return Boolean($scope.search.filter && ($scope.search.filter.$));
		}

		$scope.$watch('nivelEscolar', changeNivelEscolar);
		$scope.$watch('vm.processo', changeLista);

		function changeNivelEscolar() {

			vm.processo = {};
			vm.nivelEscolarSelecionado = $scope.nivelEscolar;
			angular.forEach(vm.processos, function(value) {
				if (value.nivelEscolar === vm.nivelEscolarSelecionado) {
					vm.processo = _.clone(value);
				}
			});

			vm.isPossuiProcesso = !_.isEmpty(vm.processo);

			vm.currentTab = 0;

			carregaMensagensFeedBak();
			if (isClassificacaoIniciada() || isEncerrado()) {
				vm.currentTab = 1;
				vm.tracker.listaClassificacao.addPromise(verificaStatusAssincromo());
			}

			verificaOrganizador();
			modalTabsStep();
			vm.configuracao = undefined;
			if (vm.nivelEscolarSelecionado) {
				getConfiguracaoByNivelEscolar();
			}
		}

		function getConfiguracaoByNivelEscolar() {
			vm.tracker.loading.addPromise(
				ConfiguracaoInscricaoService.get(vm.nivelEscolarSelecionado).then(function(data) {
					vm.configuracao = data;
					modalTabsStep();
				})
			);
		}

		function showUtilizaEncaminhamento() {
			return Boolean(_.get(vm.configuracao, 'utilizaEncaminhamento'));
		}

		function changeLista() {
			if (!vm.isPossuiProcesso) {
				return;
			}

			vm.tracker.loading.addPromise(
				$q.when(
					refreshListas()
				).then(function() {
					vm.listaOriginal = vm.lista;
				}));
		}

		$rootScope.$on(REFRESH_CONFIGURACOES, function(event, args) {
			if (args.data) {
				if (args.remove) {
					init(args.data.id);
				} else if (args.add) {
					vm.processo.dataInicio = args.data.dataHoraInicio;
					vm.processo.dataFim = args.data.dataHoraFim;
					vm.processo.status = args.data.status;
					init();
				}
			}
		});

		$rootScope.$on(REFRESH_CLASSIFICACOES, function(event, args) {
			if (args.data) {
				if (args.updateChamada) {
					vm.chamada = args.data;
					carregaAvisoDaChamada();
					refreshProcessos();
				} else if (args.comunicado) {
					vm.processo = args.data;
				} else if (args.matriculado || args.indeferir || args.listaEspera) {
					if (isNovaVisaoClassificado()){ 
						if(vm.listControls){
							return vm.listControls.search();
						}
					}
					
					vm.lista = _.reject(vm.lista, function(data) {
						return data.resultadoClassificacao.id === args.data.id;
					});
					habilitaDesbilitaProximaChamada();
					vm.listaOriginal = _.reject(vm.listaOriginal, function(data) {
						return data.resultadoClassificacao.id === args.data.id;
					});
					$rootScope.$broadcast('refresh::indicadores');
				} else if (args.desfazerIndeferimento) {
					habilitaDesbilitaProximaChamada();
					vm.lista = _.reject(vm.lista, function(data) {
						return data.id === args.data.id;
					});
					vm.listaOriginal = _.reject(vm.listaOriginal, function(data) {
						return data.id === args.data.id;
					});
					$rootScope.$broadcast('refresh::indicadores');
				} else if (args.novaChamada) {
					ClassificacaoService.getChamadoClassificado(vm.processo.id).then(function(data) {
						return getOldListaClassificados().then(function() {
							vm.chamada = chamada(data);
							carregaAvisoDaChamada();
							openChamada();
						});
					});
				} else {
					init();

					if (args.encerrar && args.periodoEncerrado) {
						vm.currentTab = 0;
					}
				}
			}
		});

		function removeConfiguracao() {
			ConfiguracaoProcessoInscricaoService.remove(vm.processo.id).then(function() {
				init();
			});
		}

		function openModalCadConfiguracaoInscricao(id) {
			bfcDialog.open({
				templateUrl: 'matricula/configuracao-inscricao/configuracao-inscricao.cad.html',
				controller: 'educacao.matricula.ConfiguracaoInscricaoCadCtrl as vm',
				resolve: {
					id: id,
					nivelEscolar: vm.nivelEscolarSelecionado,
					configuracao: vm.configuracao,
					readOnly: false
				},
			});
		}

		function openAlerta() {
			bfcDialog.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao.inscritos.confirmacao.exclusao.html',
				controller: 'educacao.matricula.ConfirmacaoConfiguracaoInscricaoCadCtrl as vm',
				resolve: {
					id: vm.processo.id,
				},
				readOnly: false
			});
		}

		function openChamada() {
			bfcDialog.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-data-chamada.html',
				controller: 'educacao.matricula.ClassificacaoDataChamadaCtrl as vm',
				resolve: {
					chamada: angular.copy(vm.chamada),
					processo: angular.copy(vm.processo),
					readOnly: false,
					onClose: $q.when(emitClearEncaminhamentoLote)
				},
			});
		}

		function openNovaChamada() {
			if (vm.habilitaChamada) {
				bfcDialog.open({
					templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-novas-chamadas.html',
					controller: 'educacao.matricula.ClassificacaoNovaChamadaCtrl as vm',
					resolve: {
						chamada: angular.copy(vm.chamada),
						processo: angular.copy(vm.processo),
						readOnly: false
					},
				});
			}
		}

		function openEncerrar() {
			bfcDialog.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-encerrar.html',
				controller: 'educacao.matricula.ClassificacaoInscritosEncerrarCtrl as vm',
				resolve: {
					processo: angular.copy(vm.processo),
					readOnly: false
				},
			});
		}

		function openMensagem() {
			bfcDialog.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-comunicado.html',
				controller: 'educacao.matricula.ClassificacaoInscritosComunicadoCtrl as vm',
				resolve: {
					processo: angular.copy(vm.processo),
					readOnly: false
				},
			});
		}

		function editar(inscricao) {
			_.forEach(vm.EquivalenciaEtapa, function(value) {
				if (inscricao.equivalencia === value.description) {
					inscricao.equivalencia = value.key;
				}
			});

			$state.go('app.matricula.cadastrarinscricao', {
				inscricao: inscricao.id
			});
		}

		function desfazerEncerramento() {
			vm.tracker.loading.addPromise(
				ClassificacaoService.desfazerEncerramento(vm.processo.id).then(init)
			);

		}

		function indeferir(resultadoClassificacao) {
			bfcDialog.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao.inscritos.indeferir.html',
				controller: 'educacao.matricula.ClassificacaoInscritosIndeferirCtrl as vm',
				resolve: {
					classificacao: resultadoClassificacao,
					onClose: $q.when(emitClearEncaminhamentoLote)
				}
			});
		}

		function desfazerIndeferimento(resultadoClassificacao) {
			vm.tracker.loading.addPromise(
				IndeferirClassificacaoService.desfazerIndeferimento(resultadoClassificacao.classificacaoInscricao.configuracaoInscricao
					.id,
					resultadoClassificacao.status.id).then(function() {
					$rootScope.$broadcast('refresh::classificacoes', {
						data: resultadoClassificacao,
						desfazerIndeferimento: true
					});
				})
			);
		}

		function visualizarInscricao(data) {

			var inscricao = _.get(data, 'inscricao') || data;

			bfcDialog.open({
				templateUrl: 'matricula/inscricao/visualizacao/visualizacao-inscricao.html',
				controller: 'educacao.matricula.VisualizacaoInscricaoCtrl as vm',
				resolve: {
					inscricao: inscricao
				}
			});
		}

		function listaEspera(resultadoClassificacao) {
			bfcDialog.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-lista-espera.html',
				controller: 'educacao.matricula.ClassificacaoInscritosListaEsperaCtrl as vm',
				resolve: {
					classificacao: resultadoClassificacao
				}
			});
		}

		function encaminhar(item, isEncaminhamentoLote) {
			if (isEncaminhamentoLote) {
				return openModalEncaminhamentoLote(item);
			}

			return openModalEncaminhamento(item);
		}

		function openModalEncaminhamento(item) {
			uiModal.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-lista-encaminhado.html',
				controller: 'educacao.matricula.ClassificacaoInscritosListaEncaminhadoCtrl',
				controllerAs: 'vm',
				size: 'lg',
				params: {
					classificacao: item,
					nivelEscolarSelecionado: vm.nivelEscolarSelecionado
				}
			}).result
			.then(emitClearEncaminhamentoLote);
		}

		function openModalEncaminhamentoLote(item) {
			uiModal.open({
				templateUrl: 'matricula/classificacao-inscritos/classificacao-inscritos-lista-encaminhado-lote.html',
				controller: 'educacao.matricula.ClassificacaoInscritosListaEncaminhadoLoteCtrl',
				controllerAs: 'vm',
				size: 'lg',
				params: {
					classificacoes: item,
					nivelEscolarSelecionado: vm.nivelEscolarSelecionado
				}
			}).result
			.then(emitClearEncaminhamentoLote);
		}

		function isInscricaoAguardando() {
			return vm.processo.status === 'AGUARDANDO';
		}

		function isInscricaoAberta() {
			return vm.processo.status === 'ABERTO';
		}

		function isPeriodoEncerrado() {
			return vm.processo.status === 'PERIODO_ENCERRADO';
		}

		function isEncerrado() {
			return (vm.processo.status === 'ENCERRADO');
		}

		function isChamadaIniciada() {
			return vm.processo.status === 'CHAMADA_INICIADA';
		}

		function isChamadaEncerrada() {
			return vm.processo.status === 'CHAMADA_ENCERRADA';
		}

		function isClassificada() {
			return vm.processo.status === 'CLASSIFICADO';
		}

		function isEncerradoSemClassificacao() {
			return vm.processo.status === 'ENCERRADO_SEM_CLASSIFICACAO';
		}

		function isClassificacaoIniciada() {
			if (!vm.processo.status) {
				return false;
			}
			return !isInscricaoAberta() && !isPeriodoEncerrado() && !isInscricaoAguardando() && !isEncerrado() && !
				isEncerradoSemClassificacao();
		}

		function isPermiteOperacoesClassificacao() {
			return isClassificada() || isChamadaIniciada() || isChamadaEncerrada();
		}

		function verificaOrganizador() {
			if (isClassificado()) {
				vm.organizador = '0';
				return;
			}

			if (vm.organizador === '0' && isClassificacaoIniciada()) {
				vm.organizador = '1';
				return;
			}

			if (vm.organizador === '3' && vm.nivelEscolarSelecionado === 'EDUCACAO_INFANTIL') {
				vm.organizador = '1';
			}
		}

		function iniciarClassificacao() {
			var message = 'Processo de classificação está em andamento. Ao término do processamento, você será notificado.';
			Notification.publish(message, 'info');
			vm.tracker.loading.addPromise(
				ClassificacaoService.classificar(vm.processo.id)
				.then(function() {
					init();
					vm.currentTab = 1;
					vm.organizador = '0';
					habilitaDesbilitaProximaChamada();
					monitaraRetornoClassificacao();
				})
			);
		}

		function monitaraRetornoClassificacao() {
			vm.emProcessoClassificacao = true;
			refreshRetornoClassificacao(true);
		}

		function refreshRetornoClassificacao(minRefresh) {
			var timeRefresh = minRefresh ? 100 : 10000;
			var promise = $interval(function() {
				if (!_.get(vm.processo, 'id')) {
					$interval.cancel(promise);
					return;
				}

				ClassificacaoService.statusClassificacao(vm.processo.id).then(function(dados) {
					var status = dados.status;

					if (status !== 'PROCESSANDO') {

						listaClassificados();

						$interval.cancel(promise);
					} else if (minRefresh) {
						refreshRetornoClassificacao();
						$interval.cancel(promise);
					}

				});

			}, timeRefresh);

			vm.tracker.listaClassificacao.addPromise(promise);
		}

		function verificaStatusAssincromo() {
			return ClassificacaoService.statusClassificacao(vm.processo.id)
				.then(function(dados) {
					var status = dados.status;
					if (status !== 'PROCESSANDO') {
						listaClassificados();
					} else {
						monitaraRetornoClassificacao();
					}
				});
		}

		function listaClassificados() {
			return ClassificacaoService.getChamadoClassificado(vm.processo.id).then(function(data) {
				return getOldListaClassificados().then(function() {
					vm.chamada = chamada(data);
					vm.emProcessoClassificacao = false;
					carregaAvisoDaChamada();
					refreshProcessos();
				});
			});
		}

		function desfazerClassificacao() {
			vm.tracker.loading.addPromise(
				ClassificacaoService.desfazerClassificacao(vm.processo.id).then(function() {
					init();
					vm.currentTab = 0;
				})
			);
		}

		function chamada(classificados) {
			return {
				id: classificados.id,
				numeroChamada: classificados.numeroChamada,
				dataInicio: classificados.dataInicio,
				dataFim: classificados.dataFim
			};
		}

		function mensagemPeriodoChamada() {
			if (!vm.chamada || !vm.chamada.dataFim) {
				return ' não configurada ';
			}
			return '. Encerramento dia ' + moment(vm.chamada.dataFim).format(DATE_FORMAT);
		}

		function mensagemEncerramentoProcesso() {
			return 'Esta classificação foi encerrada em ' + moment(vm.processo.dataHoraEncerramentoProcesso).format(DATE_FORMAT);
		}

		function changeTab(tab) {

			if (tab !== 0 && !isClassificacaoIniciada() && !isEncerrado()) {
				return;
			}

			if (tab === 0 && !isInscricaoAberta() && !isPeriodoEncerrado() && !isInscricaoAguardando()) {
				return;
			}
			if (vm.currentTab === tab) {
				return;
			}
			vm.currentTab = tab;
			changeLista();

			if (vm.currentTab !== 1 && vm.organizador === '0' && isClassificacaoIniciada()){
				vm.organizador = '1';
			}

			if (tab === 0 || tab === 1) {
				verificaOrganizador();
			}

			if (tab === 4) {
				modalTabsStep();
			}

			emitClearEncaminhamentoLote();
		}

		function habilitaDesbilitaProximaChamada() {
			vm.habilitaChamada = vm.lista && vm.lista.length > 0;
		}

		function refreshMatriculados() {
			return MatricularClassificacaoService.listMatriculados(vm.processo.id).then(function(data) {
				vm.lista = data[0].content;
			});

		}

		function isClassificado() {
			return vm.tabs[vm.currentTab].name === 'classificados';
		}

		function isInscrito() {
			return vm.tabs[vm.currentTab].name === 'inscritos';
		}

		function isIndeferido() {
			return vm.tabs[vm.currentTab].name === 'indeferimento';
		}

		function isMatriculado() {
			return vm.tabs[vm.currentTab].name === 'matriculados';
		}

		function refreshListaClassificados() {
			return ClassificacaoService.getChamadoClassificado(vm.processo.id).then(function(data) {
				if (isNovaVisaoClassificado()){ 
					if(vm.listControls){
						vm.listControls.search();
					}
				}

				return getOldListaClassificados().then(function() {
					vm.chamada = chamada(data);
					habilitaDesbilitaProximaChamada();
					carregaAvisoDaChamada();
				});
			});
		}

		function refreshListas() {

			tabInit();

			vm.lista = [];
			if (isMatriculado()) {
				return refreshMatriculados();
			}
			if (isClassificado()) {
				return refreshListaClassificados();
			}

			if (isInscrito()) {
				return ClassificacaoInscritosService.getList(0, 999, 'configuracao = ' + vm.processo.id).then(function(data) {
					vm.lista = normalizaParaAgrupar(data[0].content);
				});
			}

			if (isIndeferido()) {
				return IndeferirClassificacaoService.listIndeferidos(vm.processo.id).then(function(data) {
					vm.lista = data[0].content;
				});
			}

			function normalizaParaAgrupar(lista) {
				var listaNormalizada = [];
				var estabelecimentosAgrupados = _.uniq(_.pluck(_.flatten(_.pluck(lista, 'estabelecimentos')), 'estabelecimento'), 'id');
				_.forEach(estabelecimentosAgrupados, function(grupo) {
					_.forEach(lista, function(data) {
						_.forEach(angular.copy(data.estabelecimentos), function(estabelecimento) {
							if (grupo.id === estabelecimento.estabelecimento.id) {
								if ((data.equivalencia) && (vm.EquivalenciaEtapa) && (vm.EquivalenciaEtapa[data
										.equivalencia])) {
									data.equivalencia = vm.EquivalenciaEtapa[data.equivalencia].description;
								}
								listaNormalizada.push(angular.copy(_.merge(data, createEstabelecimento(
									estabelecimento))));
							}
						});
					});
				});
				loadTourInscricao();
				return listaNormalizada;
			}

			function createEstabelecimento(estabelecimento) {
				return {
					idEstabelecimento: estabelecimento.estabelecimento.id,
					nomeEstabelecimento: estabelecimento.estabelecimento.nome,
					turno: estabelecimento.turno
				};
			}

		}

		function modalTabsStep() {

			var step = vm.currentTab === 4 ? 3 : (vm.currentTab + 1 + (showUtilizaEncaminhamento() && vm.currentTab > 1 ? 1 : 0));

			//Remove a classe active
			$('.move-tabs-step.active').removeClass('active');

			//Verifica o tamanho da div onde estão os steps
			var groupWidth = $('.move-tabs').width();

			//Quantidade de passos
			var steps = 5;

			//Define a diferença de espaços entre steps de acordo com o número de steps
			var diffWidth = groupWidth / steps;

			//Define a posição de cada step
			for (var i = 1; i <= steps; i++) {
				$('.move-tabs-step' + i).css('left', ((diffWidth * i) + 90 - diffWidth) + 'px');
			}

			$('.move-tabs-active').css('left', ((diffWidth * step) + 10 - diffWidth) + 'px');

			//Adiciona a classe active ao step clicado
			$('.move-tabs-step' + step).addClass('active');
		}

		function openMatriculaModal(inscrito, nivelEscolar, isEncaminhado) {
			var matricula = {
				tipoMatricula: 'EDUCACAO_BASICA',
				estabelecimento: _.get(inscrito, 'status.estabelecimento') || _.get(inscrito,
					'classificacaoInscricao.estabelecimento'),
				idInscrito: inscrito.id,
				origemInscrito: 'inscricao',
			};
			var nome = _.get(inscrito.candidato, 'nome') || _.get(inscrito.dados, 'nome');
			var sexo = _.get(inscrito.candidato, 'sexo') || _.get(inscrito.dados, 'sexo');
			var nascimento = _.get(inscrito.candidato, 'dataNascimento') || _.get(inscrito.dados, 'dataNascimento');

			var filterAluno = '(nome elike "' + encodeURI(nome) +
				'" and sexo = "' + sexo +
				'" and pessoa.dataNascimento = ' + nascimento + ')';
			return $commons.$modal.open({
				templateUrl: 'matricula/matriculas/cadastro/matricula.cad.html',
				controller: 'educacao.matricula.MatriculaCadCtrl',
				controllerAs: 'vm',
				size: 'xxl',
				params: {
					$$origemInscrito: 'inscricao',
					matricula: {
						$$matriculaOrigemInscricao: matricula,
						$$bloqueioAlteracoes: true
					},
					isEncaminhado: isEncaminhado,
					$$alunos: AlunoService.list(filterAluno, 0, 999).then(
						function(data) {
							return data.content;
						}
					),
					$$nivelEscolar: nivelEscolar,
					$$turnos: [inscrito.classificacaoInscricao.turno],
					alunoInscricao: {
						pessoa: {
							nome: nome,
							sexo: sexo,
							dataNascimento: nascimento
						}
					},
				}
			}).result.then(emiterAlteracaoClassificacao);
		}


		function emiterAlteracaoClassificacao(data) {
			$rootScope.$broadcast(REFRESH_CLASSIFICACOES, {
				data: data,
				matriculado: true
			});
			refreshListaClassificados();
		}

		function isNovaVisaoClassificado() {
			var result = Boolean(vm.organizador === '0' && vm.currentTab === 1);
			if (result) {
				haveOldList = false;
			}
			return result;
		}

		function getOldListaClassificados() {
			var idProcesso = _.get(vm.processo, 'id');
			var idChamada = _.get(vm.chamada, 'id');
			if (!idProcesso || !idChamada || isNovaVisaoClassificado()) {
				return $q.when();
			}
			return ClassificacaoService.getOldListaClassificados(idProcesso, idChamada).then(function(data) {
				vm.lista = data;
				haveOldList = true;
				habilitaDesbilitaProximaChamada();
			});
		}

		$scope.$watch('vm.organizador', function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			if (isClassificado() && !isNovaVisaoClassificado() && !haveOldList) {
				vm.tracker.listaClassificacao.addPromise(
					getOldListaClassificados()
				);
			}
		});

		$scope.$watch('vm.currentTab', function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			vm.lista = null;
			vm.searchFilter = {};
			if (newValue === 1 && firstOpen) {
				firstOpen = false;
				verificaOrganizador();
			}
		});

		function clearEncaminhamentoLote() {
			vm.inscritosEncaminhamentoLote = [];
		}

		function emitClearEncaminhamentoLote() {
			vm.inscritosEncaminhamentoLote = [];
			$rootScope.$broadcast('encaminhamentoLoteItemDeleted', []);
		}
	}
})();

/**
 * Serviço criado para fornecer funções genéricas para navegação via states pelas modais de cadastro
 */
(function() {

    'use strict';

    angular.module('educacao.common')
        .service('$modalStates', ModalStates);

    ModalStates.$inject = [
        'educacao.common.$commons',
        'STATES_ADICIONAR',
        'STATES_EDITAR'
    ];

    function ModalStates($commons, STATES_ADICIONAR, STATES_EDITAR) {

        var self = this;

        self.open = open;
        self.goModalState = goModalState;

        /**
         * Função para abrir a modal de cadastro do sistema
         * 
         * @param {*} config objeto de configuração. O objeto de configuração pode ter as seguintes propriedades:
         *                      size: tamanho da modal
         *                      templateUrl: template da modal
         *                      controller: controller da modal
         *                      propertyName: nome da propriedade que será passada para a modal ao carregar
         *                                      o objeto a partir do ID do state com o método getMethod
         *                      getMethod: função para buscar o objeto de acordo com o ID do stateParams
         *                      stateParams: parametros do state atual, pode ser passado de $stateParams
         *                      extraParams: parametros adicionais que podem ser passados para a modal
         *                      modalCallback: callback que pode ser passado e será executado ao fechar a modal
         */
        function open(config) {
            var params = buildParams(config);

            $commons.$modal.open({
                templateUrl: config.templateUrl,
                controller: config.controller,
                params: params,
                size: config.size,
            }).result.finally(function() {
                $commons.$state.go('^');
            });
        }

        /**
         * Navega para o state de edição ou adição, de acordo com a entidade passada
         * 
         * @param {*} entity entidade. Caso tenha a propriedade 'id' é chamado o state de edição
         *                      caso contrário, é chamado o state para adicionar
         */
        function goModalState(entity) {
            if (_.get(entity, 'id')) {
                return $commons.$state.go(STATES_EDITAR.state, {
                    id: entity.id
                });
            }
            return $commons.$state.go(STATES_ADICIONAR.state);
        }

        function buildParams(config) {
            var params = {
                canSaveAdd: !hasId(config.stateParams)
            };
            params[config.propertyName] = hasId(config.stateParams) &&
                (config.getMethod || angular.noop)(config.stateParams.id);
            return _.extend(params, config.extraParams);
        }

        function hasId(stateParams) {
            return Boolean(_.get(stateParams, 'id'));
        }
    }

})();

(function() {
    'use strict';

    angular.module('educacao.pessoas')
        .controller('educacao.pessoas.DeficienciaCadCtrl', DeficienciaCadCtrl);

    DeficienciaCadCtrl.$inject = ['bfc.Focus', '$scope', '$modalInstance'];

    function DeficienciaCadCtrl(focus, $scope, $modalInstance) {
        var vm = this;
        vm.pessoaDeficiencias = $modalInstance.params.pessoaDeficiencias || [];

        vm.editando = false;
        vm.save = save;
        vm.remove = remove;
        vm.edit = edit;
        vm.cancelar = cancelar;

        vm.sortableOptions = {
            placeholder: 'ui-card-sortable',
            cursor: 'move',
            axis: 'y',
            containment: '#ui-containment',
            forcePlaceholderSize: true,
            tolerance: 'pointer'
        };

        function inseriNaLista() {
            if (vm.pessoaDeficiencia.deficiencia) {
                vm.pessoaDeficiencias.push(angular.copy(vm.pessoaDeficiencia));
            }
        }

        function save(continuar) {
            if (continuar) {
                inseriNaLista();
                clear();
                return;
            }

            if (vm.editando) {
                vm.pessoaDeficiencias.splice(vm.indexToEdit, 1);
                vm.editando = false;
            }
            inseriNaLista();

        }

        function clear() {
            vm.pessoaDeficiencia = null;
            vm.indexToEdit = null;
            vm.editando = false;
            vm.pessoaDeficienciaBackup = null;

            $scope.form.$setPristine();
            $scope.form.$setUntouched();
        }

        function cancelar() {
            if (vm.pessoaDeficienciaBackup) {
                vm.pessoaDeficiencias.push(vm.pessoaDeficienciaBackup);
            }
            $modalInstance.close();
        }

        function remove(index) {
            vm.pessoaDeficiencias.splice(index, 1);
        }

        function edit(index) {
            vm.pessoaDeficiencia = angular.copy(vm.pessoaDeficiencias[index]);
            vm.pessoaDeficienciaBackup = angular.copy(vm.pessoaDeficiencias[index]);
            vm.pessoaDeficiencias.splice(index, 1);
            vm.indexToEdit = index;
            vm.editando = true;
        }

    }
})();

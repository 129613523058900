(function() {
    'use strict';

    angular.module('educacao.enderecos')
        .controller('educacao.enderecos.MunicipiosController', MunicipiosController);

    MunicipiosController.$inject = [
        '$scope',
        'educacao.common.$commons', 
        'ui.components.Modal',
        '$modalStates',
        'bfc.Notification',
        'educacao.enderecos.MunicipioService',
    ];

    function MunicipiosController($scope, $commons, Modal, $modalStates, bfcNotification, MunicipioService) {
        var vm = this;

        vm.MunicipioService = MunicipioService;

        vm.openCad = openCad;
        vm.remove = remove;
        vm.search = search;
        vm.columns = getColumns();

        vm.filter = {};
        vm.tracker = {};
        vm.listUsuarioControls = {};
        vm.listPadraoControls = {};
        
        vm.tracker.loading = $commons.$promiseTracker();

        vm.listUsuarioCustomBuilder = {
            onBeforeBuild: function () {
                return 'database is not null';
            }
        };

        vm.listPadraoCustomBuilder = {
            onBeforeBuild: function () {
                return 'database is null';
            }
        };

        function remove(municipio) {
            return MunicipioService.remove(municipio)
                .then(function() {
                    bfcNotification.publish('Município ' + municipio.nome + ' excluído com sucesso.', 'success');
                });
        }

        function search() {
            vm.listUsuarioControls.search();
            vm.listPadraoControls.search();
        }

        function getColumns() {
            return [
                {
                    id: 'nome',
                    model: 'nome',
                    label: 'Descrição',
                    filterable: true
                },
                {
                    id: 'uf',
                    model: 'uf',
                    label: 'UF',
                    filterable: true
                },
                {
                    id: 'codigoIBGE',
                    model: 'codigoIBGE',
                    label: 'Código IBGE',
                    filterable: false
                }
            ];
        }

        function openCad(municipio) {
            $modalStates.goModalState(municipio);
        }
    }
})();
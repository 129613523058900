(function() {
    'use strict';

    angular.module('educacao.matricula')
        .directive('matriculaSelecionarAluno', MatriculaSelecionarAluno);

    function MatriculaSelecionarAluno() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/selecionar-alunos.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                matricula: '='
            }
        };
    }

    Controller.$inject = ['$scope', '$injector', 'promiseTracker', '$filter', 'TIPOS_MATRICULAS','educacao.matricula.MatriculaService'];

    function Controller($scope, $injector, promiseTracker, $filter, TIPOS_MATRICULAS,MatriculaService) {

        var vm = this;

        vm.tracker = {
            loadingMatriculas: promiseTracker()
        };

        var AlunoService = $injector.get('educacao.pessoas.AlunoService');

        var orderBy = $filter('orderBy'),
            AgrupaPor = 'tipoMatricula';

        vm.capitalize = capitalize;
        vm.formataFilhos = formataFilhos;
        vm.isVagaEtapa = isVagaEtapa;
        vm.getIconSortClass = getIconSortClass;
        vm.getDescricaoSituacaoMatricula = getDescricaoSituacaoMatricula;
        vm.isEjaModular = isEjaModular;
        MatriculaService.getEnum('SituacaoMatricula').then(function (SituacaoMatricula) {
                vm.SituacaoMatricula = SituacaoMatricula;
            });
        vm.pluralizeMatriculas = {
            'one': 'matrícula',
            'other': 'matrículas'
        };

        getMatriculas(_.get($scope.matricula, 'aluno.id'));

        $scope.$watch('matricula.aluno', watchAluno);

        function watchAluno(newValue, oldValue) {
            if (_.isEqual(newValue, oldValue)) {
                return;
            }
            vm.listaMatriculas = [];

            var idAluno = _.get(newValue, 'id');
            getMatriculas(idAluno);
        }

        function getMatriculas(idAluno) {
            if (idAluno) {
                vm.tracker.loadingMatriculas.addPromise(
                    AlunoService.getMatriculas(idAluno)
                    .then(setListaMatriculas)
                );
            }
        }

        function setListaMatriculas(data) {
            vm.listaMatriculas = groupBy(data.content, ['tipoMatricula']);
        }

        function groupBy(toGroup, properties) {
            var grouped = toGroup;

            var property = _.first(properties);
            if (property) {
                grouped = createGroup(_.groupBy(grouped, AgrupaPor), property);
                _.forEach(grouped, function(value, key) {
                    grouped[key].children = groupBy(value.children, _.rest(properties));
                });
            }
            return grouped;
        }

        function createGroup(toGroup, groupOptions) {
            var arr = [];

            _.forEach(toGroup, function(list) {
                var firstRecord = _.first(list);

                var group = angular.extend({
                    id: _.uniqueId('grp'),
                    label: TIPOS_MATRICULAS[firstRecord.tipoMatricula].title,
                    children: list,
                    vagaEtapa: TIPOS_MATRICULAS[firstRecord.tipoMatricula].tipo === 'vaga' || TIPOS_MATRICULAS[firstRecord.tipoMatricula].tipo === 'eja',
                    predicate: undefined,
                    reverse: undefined
                }, groupOptions.extend);

                _.forEach(groupOptions.retain, function(src, dst) {
                    group[dst] = firstRecord[src];
                });

                arr.push(group);
            });

            return orderBy(arr, 'label');
        }

        function getIconSortClass(sortBy, predicate, reverse) {
            return {
                'pull-right icon-caret-down': predicate === sortBy && reverse,
                'pull-right icon-caret-up': predicate === sortBy && !reverse,
                'pull-right icon-sort': predicate !== sortBy
            };
        }

        function capitalize(description) {
            return _.capitalize((description || '').toLowerCase());
        }

        function getDescricaoSituacaoMatricula(description){
            if(description){
                return vm.SituacaoMatricula[description].description;
            }
            return '';
        }

        function formataFilhos(filhos) {
            return (filhos || []).map(function(item) {
                var descricao;

                if (_.has(item, 'atividade')) {
                    descricao = item.atividade.descricao;
                } else {
                    descricao = item.disciplina.disciplina.descricao;
                }
                return descricao;
            }).join(' , ');
        }

        function isVagaEtapa(grupo) {
            return grupo.vagaEtapa;
        }

        function isEjaModular(subGrupo){
            return _.get(subGrupo, 'matriz') && subGrupo.matriz.modular;
        }
    }

})();

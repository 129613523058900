(function () {
    'use strict';

    angular.module('educacao.common')
        .filter('mapByArray', filter);

    function filter() {
        return map;
    }

    function map(arr, prop) {
        return _.map(arr, prop);
    }
})();

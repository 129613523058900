(function () {

    'use strict';

    angular.module('educacao.funcionario')
        .controller('educacao.funcionario.CadastroFuncionarioFormacaoPosGraduacaoCtrl', CadastroFuncionarioFormacaoPosGraduacaoCtrl);


        CadastroFuncionarioFormacaoPosGraduacaoCtrl.$inject = [
        '$modalInstance',
        'SITUACAO_FORMACAO',
        'educacao.funcionario.FuncionarioService'
    ];

    function CadastroFuncionarioFormacaoPosGraduacaoCtrl($modalInstance, SITUACAO_FORMACAO, FuncionarioService) {

        var vm = this;

        vm.remove = remove;
        vm.save = save;

        vm.formacao = angular.copy($modalInstance.params.formacao) || {};
        vm.formacoes = $modalInstance.params.formacoes;
        vm.indexItem = $modalInstance.params.indexItem;
        
        vm.editing = !!$modalInstance.params.formacao;

        vm.onChangeAreaFormacao = onChangeAreaFormacao;
        vm.onChangeSituacaoCurso = onChangeSituacaoCurso;
        vm.onChangeTipoInstituicao = onChangeTipoInstituicao;

        vm.tiposFormacaoPosGraduacao = FuncionarioService.getTipoFormacaoPosGraduacao();
        vm.situacaoFormacaoPosGraduacao = FuncionarioService.getSituacaoFormacaoPosGraduacao();

        function save(saveAndAdd) {
            if (saveAndAdd) {
                vm.formacoes.push(vm.formacao);
                vm.formacao = {};
                vm.formCadastroFormacaoPosGraduacao.$setUntouched();
            } else {
                $modalInstance.close(vm.formacao);
            }
        }

        function remove() {
            if (vm.indexItem >= 0) {
                vm.formacoes.splice(vm.indexItem, 1);
                $modalInstance.close();
            }
        }

        function onChangeAreaFormacao() {
            delete vm.formacao.curso;
        }

        function onChangeSituacaoCurso() {
            if (vm.formacao.situacao === SITUACAO_FORMACAO.EM_ANDAMENTO) {
                delete vm.formacao.anoFim;
            }
        }

        function onChangeTipoInstituicao() {
            delete vm.formacao.instituicao;
        }

    }
})();
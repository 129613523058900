(function () {
    'use strict';

    angular.module('educacao.configuracaoPlanejamentoAula')
        .factory('educacao.configuracaoPlanejamentoAula.ConfiguracaoPlanejamentoAulaService',
        ConfiguracaoPlanejamentoAulaService);

    ConfiguracaoPlanejamentoAulaService.$inject = ['ServiceBuilder'];

    function ConfiguracaoPlanejamentoAulaService(ServiceBuilder) {

        var self = ServiceBuilder
            .create()
            .path('planejamento-aula', 'configuracao-planejamento-aula')
            .enum('ItemPlanejamentoAula')
            .build();

        self.getConfiguracao = getConfiguracao;
        self.restaurarConfiguracao = restaurarConfiguracao;

        function getConfiguracao(tipoEstabSecretaria, tipoConfiguracao) {
            return self.getEndpoint()
                .get(tipoEstabSecretaria + '/' + tipoConfiguracao);
        }

        function restaurarConfiguracao(configuracaoId) {
            return self.getEndpoint()
                .one(String(configuracaoId))
                .remove();
        }

        return self;
    }
})();

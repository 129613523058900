(function() {
	'use strict';

	angular.module('educacao.matriz')
		.controller('educacao.matriz.MatrizCtrl', Controller);

	Controller.$inject = [
		'$scope',
		'promiseTracker',
		'educacao.matriz.MatrizService',
		'educacao.common.ObjectUtilsService',
		'educacao.common.ModalCad',
		'$timeout',
		'bfc.Notification'
	];

	function Controller($scope, promiseTracker, MatrizService, ObjectUtilsService, ModalCad, $timeout, Notification) {
		var vm = this;
		var currentAviso;

		vm.remove = remove;
		vm.open = open;
		vm.search = search;
		vm.getListagem = getListagem;
		vm.getRangeEtapasFormatado = getRangeEtapasFormatado;
		vm.filterByNotificacao = filterByNotificacao;
		vm.visualizar = visualizar;
		vm.atualizarSituacaoTurma = atualizarSituacaoTurma;
		vm.criarPacote = criarPacote;
		vm.iniciarCargaTemplates = iniciarCargaTemplates;

		init();

		function init() {
			vm.filter = {};
			vm.controls = {};

			vm.tracker = {
				loading: promiseTracker()
			};

			vm.columnsSearch = [
				{
					id: 'descricao',
					model: 'descricao',
					label: 'Descrição',
					filterable: true
				}
			];

			vm.customBuilder = {
				id: function(filters) {
					if (filters.id) {
						return 'id in (' + filters.id + ')';
					}
				}
			};

			getNotificacoes();
		}

		function getListagem(params) {
			return MatrizService.listByParams(params).then(function(dataMatriz) {
				_.forEach(dataMatriz.content, function(dataItem) {
					dataItem.aviso = _.find(vm.notificacoes, 'entityId', dataItem.id);
				});
				return dataMatriz;
			});
		}

		function getNotificacoes() {
			var promise = MatrizService.getNotificacoes().then(function(data) {
				vm.notificacoes = _.flatten(_.values(data.plain()));
				vm.notificacoesAlertas = _.groupBy(_.uniq(_.cloneDeep(vm.notificacoes), 'entityId'), 'alert');
			});

			vm.tracker.loading.addPromise(promise);
			return promise;
		}

		function filterByNotificacao(grupo) {
			currentAviso = grupo;
			var ids = _.pluck(vm.notificacoesAlertas[grupo], 'entityId');
			if (_.get(vm.filter, 'others.id')) {
				delete vm.filter.others.id;
			}
			vm.filter.others = {
				id: ids.join()
			};
			vm.filter.listing = {
				id: {
					label: 'ids',
					group: true,
					fnFormatLabel: function() {
						return '(Aviso) ' + grupo;
					},
					fnFormatField: function() {
						return '';
					}
				}
			};
			search();
		}

		function search() {
			vm.controls.search();
		}

		function remove(item) {
			return MatrizService.remove(item.id).then(notificarExclusao).then(search);
		}

		function notificarExclusao(){
			Notification.publish('Matriz curricular excluída com sucesso', 'success');
		}

		function getRangeEtapasFormatado(matriz) {
			if (!matriz.etapaInicial) {
				return 'Sem etapas informadas';
			}

			var stringBuilder = ObjectUtilsService.getStringBuilder();
			var simbolo = _.get(matriz, 'curso.organizacao.simbolo');
			var descricaoEtapa = _.get(matriz, 'curso.organizacao.description');
			var format = _.get(matriz, 'identificacaoEtapa.format');

			var codAscii = simbolo.charCodeAt(0);
			var complementoRange = codAscii === 176 || codAscii === 186 ? ' ao ' : ' a ';

			var inicio = format.replace('{0}', matriz.etapaInicial)
				.replace('{1}', '');

			var fim = format.replace('{0}', matriz.etapaFinal)
				.replace('{1}', '');

			var fases = stringBuilder
				.append(inicio)
				.append(complementoRange)
				.append(fim)
				.append(' ')
				.toString();

			return format.replace('{0}', fases)
				.replace('{1}', descricaoEtapa);

		}

		function open(item) {
			if (_.get(item, 'id')) {

				return ModalCad.open({
					templateUrl: 'planejamento/matriz/matriz/matriz.config.organizacoes.html',
					controller: 'educacao.matriz.MatrizConfigOrgCtrl',
					id: item.id,
					readOnly: false
				});
			}

			ModalCad.open({
				templateUrl: 'planejamento/matriz/matriz/matriz.cad.html',
				controller: 'educacao.matriz.MatrizCadCtrl as vm',
				readOnly: false
			});
		}

		function visualizar(item) {
			ModalCad.open({
				templateUrl: 'planejamento/matriz/matriz/matriz.visualizar.html',
				controller: 'educacao.matriz.MatrizVisualizarCtrl',
				id: item.id,
				readOnly: false
			});
		}

		function atualizarSituacaoTurma(item) {
			ModalCad.open({
				templateUrl: 'planejamento/matriz/matriz/matriz.atualizar-situacao-turma.html',
				controller: 'educacao.matriz.MatrizAtualizarSituacaoTurmaCtrl',
				resolve: {
					matriz: item
				},
				size: 'sm',
				readOnly: false
			});
		}

		function refresh() {
			getNotificacoes().then(function() {
				if (_.get(vm.filter, 'others.id')) {
					filterByNotificacao(currentAviso);
				}
			});
		}

		$scope.$on('refreshContextEntidade', refresh);
		$scope.$on('refreshMatrizCurricular', refresh);

		$scope.$on('configuracao::show-lote-matriz', function() {
			vm.showMultipleSelecion = !vm.showMultipleSelecion;
			setReload(true);
			setReload(false, 200);
		});

		$scope.$on('configuracao::carga-template', function() {
			vm.habilitaCargaTemplate = !vm.habilitaCargaTemplate;
		});

		function iniciarCargaTemplates() {
			MatrizService.cargaTemplate().then(function() {
				Notification.publish('Iniciado o processo de carga inicial para templates!', 'info');
			});
		}

		function setReload(value, time) {
			$timeout(function() {
				vm.reloadPacote = value;
			}, time || 100);
		}

		function criarPacote() {
			var ids = _.pluck(vm.selectedItens, 'id');
			MatrizService.orquestrador({
				tipo: 'MATRIZ_CURRICULAR',
				ids: ids
			}).then(function() {
				Notification.publish('Matrizes curriculares publicadas para flexibilização com sucesso!', 'success');
			});
		}
	}
})();

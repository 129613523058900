(function() {
    'use strict';

    angular.module('educacao.matriz')
        .controller('educacao.matriz.MatrizCurricularCtrl', ['$scope', '$timeout', 'promiseTracker', 'educacao.common.ModalCad',
            function($scope, $timeout, promiseTracker, ModalCad) {

                $scope.addCurso = function() {
                    ModalCad.open({
                        templateUrl: 'planejamento/matriz/curso/cursos.cad.html',
                        controller: 'educacao.matriz.CursosCadCtrl as vm',
                    });
                };

                $scope.addDisciplina = function() {
                    ModalCad.open({
                        templateUrl: 'planejamento/matriz/disciplina/disciplinas.cad.html',
                        controller: 'educacao.matriz.DisciplinasCadCtrl'
                    });
                };

                $scope.addMatriz = function() {
                    ModalCad.open({
                        templateUrl: 'planejamento/matriz/matriz/matriz.cad.html',
                        controller: 'educacao.matriz.MatrizCadCtrl as vm',
                    });
                };

                $scope.addEixoTematico = function() {
                    ModalCad.open({
                        templateUrl: 'planejamento/matriz/eixo-tematico/eixo-tematico.cad.html',
                        controller: 'educacao.matriz.EixoTematicoCadCtrl as vm',
                    });
                };
            }
        ]);
})();

(function () {

    'use strict';

    angular.module('educacao.desempenhoescolar')
        .directive('inputDataAvaliacao', Directive);

    function Directive() {
        return {
            restrict: 'E',
            templateUrl: 'desempenho-escolar/input-data-avaliacao/input-data-avaliacao.directive.html',
            scope: {},
            bindToController: {
                turma: '=',
                periodo: '=',
                itensAvaliaveis: '=',
                itemEducacional: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        'educacao.desempenhoescolar.RegistroAvaliacoesService',
        'ITEM_AVALIAVEL_TIPO_PERIODO',
        'promiseTracker'
    ];

    function Controller(RegistroAvaliacoesService, ITEM_AVALIAVEL_TIPO_PERIODO, promiseTracker) {
        var vm = this;

        vm.getDataAvaliacao = getDataAvaliacao;
        vm.onChangeData = onChangeData;

        vm.exibeData = false;
        vm.isConselhoClasse = vm.periodo.id === ITEM_AVALIAVEL_TIPO_PERIODO.CONSELHO_CLASSE;

        vm.tracker = promiseTracker();

        init();

        function init() {

            var itemData = _.find(vm.itensAvaliaveis, function (itemAvaliavel) {
                return _.get(itemAvaliavel, 'itemEducacional.id') === vm.itemEducacional.id &&
                    itemAvaliavel.idPeriodo === vm.periodo.id;
            });
            vm.exibeData = (vm.periodo.rawValue === ITEM_AVALIAVEL_TIPO_PERIODO.EXAME_FINAL ||
                vm.periodo.rawValue === ITEM_AVALIAVEL_TIPO_PERIODO.CONSELHO_CLASSE) && itemData;



            vm.itemAvaliavel = _.find(vm.itensAvaliaveis, function (itemAvaliavel) {
                return itemAvaliavel.idPeriodo === vm.periodo.id &&
                    itemAvaliavel.itemEducacional.id === vm.itemEducacional.id;
            });
        }

        function getDataAvaliacao() {
            if (vm.tracker.tracking()) {
                return;
            }

            vm.tracker.addPromise(RegistroAvaliacoesService.getData(vm.turma, vm.itemAvaliavel)
                .then(function (data) {
                    vm.dataAvaliacao = data.dataExameConselho;
                }));
        }

        function onChangeData() {
            if (vm.tracker.tracking()) {
                return;
            }

            saveDataAvaliacao();
        }

        function saveDataAvaliacao() {
            var dataObj = {
                data: vm.dataAvaliacao,
                tipoPeriodo: _.get(vm.itemAvaliavel, 'tipoPeriodo'),
                identificadorExameFinal: _.get(vm.itemAvaliavel, 'identificadorExameFinal'),
                itemEducacional: vm.itemEducacional.id
            };

            vm.tracker.addPromise(RegistroAvaliacoesService.saveDate(vm.turma, dataObj));
        }
    }
})();
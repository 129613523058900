(function () {
    'use strict';

    var DEFAULT_COLORS = [
        {hex: '#1abc9c', name: ''},
        {hex: '#16a085', name: ''},
        {hex: '#2ecc71', name: ''},
        {hex: '#27ae60', name: ''},
        {hex: '#3498db', name: ''},
        {hex: '#2980b9', name: ''},
        {hex: '#9b59b6', name: ''},
        {hex: '#8e44ad', name: ''},
        {hex: '#f39c12', name: ''},
        {hex: '#e67e22', name: ''},
        {hex: '#e74c3c', name: ''},
        {hex: '#c0392b', name: ''},
        {hex: '#95a5a6', name: ''},
        {hex: '#7f8c8d', name: ''},
        {hex: '#000000', name: ''}
    ];

    angular.module('app')
        .directive('uiColorSelector', directive);

    function directive() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/ui-color-selector/color-selector.directive.html',
            scope: {},
            bindToController: {
                colors: '=?',
                ngModel: '=?',
                label: '@?',
                hideLabel: '=?',
                square: '=?',
                onChange: '&'
            },
            link: function(scope, elem) {
                $('.ui-color-selector', elem).click(function (event) {
                    event.stopPropagation();

                    $('.dropdown-toggle', elem).dropdown('toggle');
                });
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this;
        var initialColor = _.first(vm.colors);

        $scope.$watch('vm.ngModel', function(newValue, oldValue) {
            if (!newValue) {
                return _changeColor(initialColor);
            }

            if (newValue !== oldValue){
                return _changeColor(_.find(vm.colors, { key: newValue }));
            }
        });

        if (!angular.isDefined(vm.square)) {
            vm.square = false;
        }

        if (!angular.isDefined(vm.hideLabel)) {
            vm.hideLabel = false;
        }

        vm.colorSelected = _.find(vm.colors, { key: vm.ngModel }) || initialColor;
        vm.ngModel = vm.colorSelected.key;
        vm.label = vm.label || 'Cor';
        vm.listColors = vm.colors || DEFAULT_COLORS;

        vm.changeColor = _changeColor;

        function _changeColor(newColor) {
            vm.colorSelected = newColor;
            vm.ngModel = newColor.key;
        }
    }

})();
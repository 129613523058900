(function () {

    'use strict';

    angular.module('educacao.criterio-lista-espera')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.criterio-lista-espera', {
            url: 'criterios-lista-espera',
            data: {
                id: 'CriterioListaEsperaPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'lista-espera-new/configuracao/criterio-lista-espera/criterio-lista-espera.html' ,
                    controller: 'educacao.criterio-lista-espera.CriterioListaEsperaCtrl',
                    controllerAs: 'vm'
                }
            }            
        });
    }
})();

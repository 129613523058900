(function() {
    'use strict';

    angular.module('educacao.planejamento').controller('educacao.planejamento.AvaliacoesExternasCadCtrl', AvaliacoesExternasCadCtrl);

    AvaliacoesExternasCadCtrl.$inject = ['educacao.planejamento.AvaliacoesExternasService',
        'educacao.common.CommonSelectEnum',
        '$modalTracker',
        '$q',
        '$injector',
        'id',
        'optionsCad',
        'readOnly',
        'promiseTracker',
        'fnCad',
        '$rootScope',
        '$modalInstance',
        'bfc.Focus'
    ];

    function AvaliacoesExternasCadCtrl(AvaliacoesExternasService, CommonSelectEnum, $modalTracker, $q, $injector, id, optionsCad, readOnly, promiseTracker, fnCad, $rootScope, $modalInstance, focus) {

        var vm = this,
            select2 = {};
        vm.tracker = {};
        vm.tracker.loading = promiseTracker();

        var modo = {
            adicionando: !id && !readOnly,
            editando: id && !readOnly,
            novaOpcao: Boolean(optionsCad)
        };

        var properties = {
            select2: select2,
            save: save,
            cancel: cancel,
            mode: modo,
            remove: remove
        };

        init();

        function init() {
            $modalTracker.addPromise(
                $q.all([
                    refresh(),
                    AvaliacoesExternasService.getEnums().then(function(enums) {

                        select2.Avaliador = CommonSelectEnum.select2Config({
                            values: enums.Avaliador,
                            description: 'description'
                        });
                        select2.EtapaEnsinoAvaliada = CommonSelectEnum.select2Config({
                            values: enums.EtapaEnsinoAvaliada,
                            description: 'description'
                        });

                    })
                ]).then(
                    function postInit() {
                        angular.extend(vm, properties);
                        vm.windowTitle = modo.adicionando ? 'Adicionando avaliação externa' : 'Editando avaliação externa';
                    }
                )
            );

        }

        function refresh() {

            verificaFormulario();

            return Boolean(id) ?
                AvaliacoesExternasService.get(id).then(function(data) {
                    vm.avaliacao = data;
                    vm.avaliacao.id = id;
                }) :

                AvaliacoesExternasService.getDefault().then(function(data) {
                    vm.avaliacao = data;
                });

        }

        function save(continuar) {

            vm.tracker.loading.addPromise(
                AvaliacoesExternasService.save(vm.avaliacao).then(function(data) {
                    if (angular.isFunction(fnCad)) {
                        fnCad(data);
                    }

                    $rootScope.$broadcast('refreshAvaliações', {
                        data: data,
                        add: !id,
                        edit: Boolean(id)
                    });

                    onSuccess(continuar);
                })
            );

        }

        function remove() {
            vm.tracker.loading.addPromise(
                AvaliacoesExternasService.remove(vm.avaliacao.id).then(function() {
                    $rootScope.$broadcast('refreshAvaliações', {
                        data: vm.avaliacao,
                        remove: true
                    });

                    onSuccess();
                })
            );
        }

        function onSuccess(continuar) {
            if (continuar) {
                id = null;
                vm.tracker.loading.addPromise(refresh());
                focus('lbDescricaoAvaliação');
            } else {
                cancel();
            }
        }

        function cancel() {
            $modalInstance.close();
        }

        function verificaFormulario() {
            if (vm.form) {
                vm.form.$setPristine();
                vm.form.$setUntouched();
            }
        }
    }
})();

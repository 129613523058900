(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.RegistrosVinculadosModalController', Controller);

    Controller.$inject = ['$modalInstance', 'educacao.calendario.RegistrosVinculadosService', 'promiseTracker', '$q', '$filter'];

    function Controller($modalInstance, RegistrosVinculadosService, promiseTracker, $q, $filter) {

        var vm = this;
        var orderBy = $filter('orderBy');

        vm.turmas = $modalInstance.params.turmas.content;
        vm.periodosChanged = $modalInstance.params.periodosChanged;
        vm.calendarioMatriz = $modalInstance.params.calendarioMatriz;
        vm.turmaImpeditivos = [];
        
        vm.hasImpeditivoOnTurma = hasImpeditivoOnTurma;
        vm.hasImpeditivo = hasImpeditivo;
        //vm.hasMediaPeriodo = hasMediaPeriodo;
        vm.save = save;

        vm.tracker = {
            save: promiseTracker(),
            loading: promiseTracker()
        };

        init();

        function init() {
            var promises = vm.turmas.map(getImpeditivos);
            vm.tracker.loading.addPromise($q.all(promises));
        }

        function getImpeditivos(turma) {
            return RegistrosVinculadosService.getImpeditivosTurma(turma.id, vm.calendarioMatriz).then(function(data) {
                setImpeditivosTurma(data, turma);
            });
        }

        function setImpeditivosTurma(impeditivos, turma) {
            impeditivos.turma = turma;
            vm.turmaImpeditivos.push(impeditivos);
            vm.turmaImpeditivos = orderBy(vm.turmaImpeditivos, 'turma.descricao');
        }

        function hasImpeditivoOnTurma(item) {
            if (!item) {
                return false;
            }
            return item.hasInstrumentoWithout || item.hasAulaAlocadaWithout || item.hasFaltaDiaWithout || hasPeriodoEncerrado(item.periodos);
        }

        function hasImpeditivo() {
            return _.filter(vm.turmaImpeditivos, hasImpeditivoOnTurma).length > 0;
        }

        function hasPeriodoEncerrado(periodos) {
            var hasPeriodosEncerrados = _.filter(periodos, function(periodo) {
                if (!periodo.id) {
                    return false;
                }
                var periodoAlterado = _.find(vm.periodosChanged, { id: periodo.id });
                return periodo.isEncerrado && periodoAlterado;
            });
            return hasPeriodosEncerrados.length > 0;
        }

        function save() {
            if (hasImpeditivo()) {
                return;
            }
            
            vm.tracker.save.addPromise(RegistrosVinculadosService
                .saveRealocacaoPeriodos(vm.calendarioMatriz)
                .then($modalInstance.close));
        }
        
        //No momento não será um impeditivo.
        /*function hasMediaPeriodo(periodos) {
            var hasMedia = _.filter(periodos, function(periodo) {
                if (!periodo.id) {
                    return false;
                }
                var periodoAlterado = _.find(vm.periodosChanged, { id: periodo.id });                
                return periodo.hasMedia && periodoAlterado;               
            });

            return hasMedia.length > 0;
        }*/
        
    }
})();

(function () {
    'use strict';

    var TIPO_DIVISAO = 'DIVISAO_DISCIPLINA';

    angular.module('educacao.matricula')
        .controller('educacao.matricula.DivisaoTurmaDisciplinaController', Controller);

    Controller.$inject = ['$modalInstance'];

    function Controller($modalInstance) {
        var vm = this;

        vm.divisoesTurmaDisciplina = $modalInstance.params.divisoesTurmaDisciplina;
        vm.isCamposExperiencia = $modalInstance.params.isCamposExperiencia;

        var modelo = _.first(vm.divisoesTurmaDisciplina);
        var turmaDisciplina = modelo.$$pai;

        vm.descricaoDisciplina = modelo.$$label;

        vm.novaDivisao = novaDivisao;

        vm.limpa = limpa;
        vm.limpaTodos = limpaTodos;

        vm.excluir = excluir;
        vm.salvar = salvar;

        function novaDivisao() {
            vm.divisoesTurmaDisciplina.push({
                $$pai: turmaDisciplina,
                tipo: TIPO_DIVISAO
            });
        }

        function limpa(index) {
            vm.divisoesTurmaDisciplina.splice(index, 1);
        }

        function limpaTodos() {
            vm.divisoesTurmaDisciplina = [];
        }

        function excluir() {
            vm.divisoesTurmaDisciplina = [];
            novaDivisao();
            salvar();
        }

        function salvar() {
            $modalInstance.close(vm.divisoesTurmaDisciplina);
        }
    }
})();
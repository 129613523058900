(function () {
	'use strict';
	angular
		.module('educacao.lista-espera-novo.inscricao')
		.controller('educacao.lista-espera-novo.inscricao.ConfiguracaoListaEsperaBuscarCandidato', ConfiguracaoListaEsperaBuscarCandidato);

	ConfiguracaoListaEsperaBuscarCandidato.$inject = [
		'educacao.lista-espera-novo.configuracao.ConfigListaEsperaService',
		'$filter',
		'$enumsCache',
		'educacao.pessoas.AlunoService',
		'promiseTracker'
	];

	function ConfiguracaoListaEsperaBuscarCandidato(ConfigListaEsperaService, $filter, $enumsCache, AlunoService, promiseTracker) {
		var vm = this;

		vm.searchInscrito = searchInscrito;
		vm.formatResult = formatResult;
		vm.formatResultSelect = formatResultSelect;
		vm.pesquisarSelecao = pesquisarSelecao;
		vm.filterCpf = $filter('bfMaskCPF');
		vm.filterDate = $filter('bfDate');

		vm.tracker = { list: promiseTracker() };

		var template = _.template('<%= nome %> <span class="label pull-right <%= situacaoColor %>" style="margin-left: 10px; width: 165px"><%= situacao %></span> <span class="label pull-right bg__orange" style="width: 60px"><%= tipo %></span> <br><small> CPF: <%= cpf %> | Nascimento: <%= dataNascimento %> | Filiação: <%= filiacao %> </small>');

		init();

		function init() {
			$enumsCache.get('SuggestTipo').then(function (enumData) {
				vm.SuggestTipo = enumData;
			});
		}

		function pesquisarSelecao() {
			if (!vm.selecao) {
				return;
			}

			if (vm.selecao.tipo === 'ALUNO') {
				vm.inscrito = undefined;
				pesquisaAluno();
			}

			if (vm.selecao.tipo === 'INSCRICAO') {
				vm.listMatricula = undefined;
				pesquisaInscrito();
			}
		}

		function pesquisaAluno() {
			vm.tracker.list.addPromise(
				AlunoService.getPesquisaMatriculas(vm.selecao.id)
					.then(function (dado) {
						vm.listMatricula = dado.content;
					}));
		}

		function pesquisaInscrito() {
			vm.tracker.list.addPromise(
				ConfigListaEsperaService.getInscricaoId(vm.selecao.id)
					.then(function (dado) {
						vm.inscrito = dado;
					}));
		}

		function searchInscrito(params) {
			return ConfigListaEsperaService.getInscritosOrMatriculados(createFilter(params))
				.then(function (data) {
					return {
						content: data,
						hasMore: false
					};
				});
		}

		function createFilter(params) {
			var termDecode = decodeURI(params.term);

			var termDecodeNome = encodeURI('%' + termDecode.replace(/[%]+/g, ' ').trim() + '%');
			var termSemCaracterEspecial = encodeURI(removeCaracteresEspeciais(termDecode) + '%');

			var filter = '(nome like "' + termDecodeNome + '" or cpf like "' + termSemCaracterEspecial + '"' + ' or filiacoes.nome like "' + termDecodeNome + '"' + ')';

			return filter;
		}

		function removeCaracteresEspeciais(texto) {
			var er = /\^|~|\?|,|\*|\.|\-/g;
			return texto.replace(er, '');
		}

		function formatResultSelect(inscrito) {
			return inscrito.nome;
		}

		function formatResult(inscrito) {
			if (!inscrito.nome) {
				return undefined;
			}

			var situacaoColor = 'label-danger';
			if (inscrito.situacao === 'Cursando' || inscrito.situacao === 'Matriculado' ||
				inscrito.situacao === 'Em exame' || inscrito.situacao === 'Aprovado' ||
				inscrito.situacao === 'Aprovado(a) parcialmente' || inscrito.situacao === 'Aprovado(a) pelo conselho' ||
				inscrito.situacao === 'Inscrito') {
					situacaoColor = 'label-success';
			}

			return template({
				nome: inscrito.nome,
				cpf: vm.filterCpf(inscrito.cpf),
				dataNascimento: vm.filterDate(inscrito.dataNascimento),
				tipo: vm.SuggestTipo[inscrito.tipo].description,
				filiacao: _.get(_.find(inscrito.filiacoes, 'tipoFiliacao', 'MAE'), 'nome') || _.get(_.first(inscrito.filiacoes), 'nome'),
				situacao: inscrito.situacao,
				situacaoColor: situacaoColor
			});
		}
	}
})();

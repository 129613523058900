(function () {
    'use strict';

    angular.module('educacao.gestao-pessoas-quadro-informativo')
        .controller('educacao.gestao-pessoas-quadro-informativo.PopoverCargaHorariaController', Controller);

    Controller.$inject = ['$rootScope', 'educacao.common.$commons', '$popoverInstance', 'educacao.gestao-pessoas-quadro-informativo.QuadroFuncionariosGestaoPessoasService'];

    function Controller($rootScope, $commons, $popoverInstance, Service) {

        var vm = this;
        vm.localTrabalho = $popoverInstance.params.localTrabalho;
        vm.exibeCargaHorariaAulas = exibeCargaHorariaAulas;
        vm.getDescriptionPopoverCargaHoraria = getDescriptionPopoverCargaHoraria;
        vm.formatCargaHoraria = formatCargaHoraria;

        init();

        function init() {
            if (!vm.localTrabalho.localTrabalhoId) {
                return;
            }

            var promise = Service.listCargaHoraria(vm.localTrabalho.localTrabalhoId).then(function (data) {
                onLoadCargaHoraria(data,  vm.localTrabalho.cargaHorariaSemanal);
            });
            $popoverInstance.tracker.addPromise(promise);
        }

        function onLoadCargaHoraria(data, cargaHorariaLocalTrabalho) {
            if (_.isEmpty(data)) {
                return [];
            }

            vm.cargaHorariaPorDuracao = {
                total: formatCargaHoraria(cargaHorariaLocalTrabalho),
                duracoes: data
            };

            vm.exibicaoDuracaoAulas = exibeCargaHorariaAulas(vm.cargaHorariaPorDuracao);
            ajustaPosicaoPopOver();
            return vm.cargaHorariaPorDuracao;
        }

        function exibeCargaHorariaAulas(data) {
            if (data.duracoes.length === 1 && _.isNull(data.duracoes[0].qntdAulas)) {
                ajustaPosicaoPopOver();
                return false;
            }
            ajustaPosicaoPopOver();
            return true;
        }


        function formatCargaHoraria(cargaHorariaFuncionario) {
            if (!cargaHorariaFuncionario) {
                return '-';
            }

            var duration = moment.duration(cargaHorariaFuncionario);
            var hours = Math.floor(duration.asHours());
            var minutes = duration.minutes();
            return hours + 'h ' + minutes + 'm';
        }

        function getDescriptionPopoverCargaHoraria(duracao) {
            if (duracao.duracaoAula) {
                ajustaPosicaoPopOver();
                return duracao.qntdAulas + ' aulas de ' + duracao.duracaoAula + ' min';
            }
            ajustaPosicaoPopOver();

            return duracao.qntdAulas + ' aulas (tempo não informado)';
        }

        function ajustaPosicaoPopOver() {
            $commons.$timeout(function () {
                $popoverInstance.position();
            });
        }
    }
})();

(function() {

    'use strict';

    angular.module('educacao.matricula').directive('appSelectEnturmacao', SelectEnturmacaoDirective);

    SelectEnturmacaoDirective.$inject = [];

    function SelectEnturmacaoDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" format-result="vm.formatResult(data)" search="vm.search" />',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                ngDisabled: '=',
                turma: '=',
                atividade: '=',
                disciplina: '='
            },
            controller: SelectEnturmacaoDirectiveController,
            controllerAs: 'vm',
            compile: function($element, $attrs) {
                var multiple = angular.isDefined($attrs.multiple);
                if (multiple) {
                    $element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined($attrs.ngRequired)) {
                        $element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function($scope) {
                        $scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectEnturmacaoDirectiveController.$inject = ['$scope', 'educacao.matricula.TurmaService'];

    function SelectEnturmacaoDirectiveController($scope, TurmaService) {

        var vm = this;

        vm.search = search;
        vm.formatResult = formatResult;

        function search(params) {
            var filter = '(nome like "' + params.term + '"';

            if ($scope.atividade && $scope.atividade.id) {
                filter += ' and atividade=' + $scope.atividade.id;
            }

            if (_.get($scope.disciplina, 'id')) {
                filter += ' and itemEducacional=' + $scope.disciplina.id;
            }

            if ($scope.multiple) {
                var enturmacoesIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(enturmacao) {
                        enturmacoesIds.push(enturmacao.id);
                    });
                }
                if (enturmacoesIds.length) {
                    filter += ' and id not in(' + enturmacoesIds.join() + ')';
                }
            }

            filter += ' and dataFim is null)';

            var turmaId = $scope.turma ? $scope.turma.id : -1;
            return TurmaService.getEnturmacoes(turmaId, {
                offset: params.offset,
                limit: params.limit,
                filter: filter
            });
        }

        function formatResult(data) {

            if (!data.numeroChamada && !data.matricula) {

                return data.descricao;

            }

            if (data.matricula.turnoFrequenta) {
             
                return data.numeroChamada + ' - ' + ((!data.matricula.aluno) ? data.matricula.nome : data.matricula.aluno.pessoa.nome) + ' (' +_.capitalize(( data.matricula.turnoFrequenta || '').toLowerCase()) + ')';
            
            }

            return data.numeroChamada + ' - ' + ((!data.matricula.aluno) ? data.matricula.nome : data.matricula.aluno.pessoa.nome);

        }

    }

})();

(function () {
    'use strict';

    angular.module('educacao.planejamento')
        .directive('appSelectTipoDependenciaFisica', SelectTipoDependenciaFisicaDirective);

    SelectTipoDependenciaFisicaDirective.$inject = [];

    function SelectTipoDependenciaFisicaDirective() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" />',
            scope: {
                ngModel: '=',
                ngRequired: '=?'
            },
            controller: SelectTipoDependenciaFisicaDirectiveController,
            controllerAs: 'vm',
            compile: function (element, tAttrs) {
                var multiple = angular.isDefined(tAttrs.multiple);
                if (multiple) {
                    element.children('ui-select').attr('multiple', true);

                    if (angular.isDefined(tAttrs.ngRequired)) {
                        element.children('ui-select').attr('not-empty', 'ngRequired');
                    }
                }

                return {
                    post: function (scope) {
                        scope.multiple = multiple;
                    }
                };
            }
        };
    }

    SelectTipoDependenciaFisicaDirectiveController.$inject = ['$scope', 'educacao.planejamento.TiposDependenciasService'];

    function SelectTipoDependenciaFisicaDirectiveController($scope, TiposDependenciasService) {
        var vm = this;

        vm.search = search;  
        
        function search(params) {

            var filter = '( descricao like "' + params.term + '"';

            if ($scope.multiple) {
                var tipoDependenciaIds = [];
                if ($scope.ngModel) {
                    $scope.ngModel.forEach(function(tipoDependencia) {
                        tipoDependenciaIds.push(tipoDependencia.id);
                    });
                }

                if (tipoDependenciaIds.length) {
                    filter += ' and id not in(' + tipoDependenciaIds.join() + ')';
                }
            }

            filter += ' )';

            return TiposDependenciasService.getList(params.offset, params.limit, filter).then(toPage);
        }
        
        function toPage(list) {
            return {
                content: list,
                hasMore: false
            };
        }
    }
}) ();

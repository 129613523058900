(function() {
    'use strict';

    angular.module('educacao.educacenso')
        .factory('educacao.educacenso.EducacensoExecucaoService', EducacensoExecucaoService);

    EducacensoExecucaoService.$inject = ['ServiceBuilder'];

    function EducacensoExecucaoService(ServiceBuilder) {
        var service = ServiceBuilder.create()
            .path('commons')
            .path('educacenso-execucao')
            .build();

        service.alterarSituacao = alterarSituacao;
        
        function alterarSituacao(item){
            return service.getEndpoint()
                .one(item.educacensoExecucao.id.toString())
                .one('situacao')
                .customPUT(item.educacensoExecucao);
        }
        
        return service;
    }
})();

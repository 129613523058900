(function () {
    'use strict';

    angular.module('educacao.calendario')
        .controller('educacao.calendario.CalendarioEventoAceiteModalController', CalendarioEventoAceiteModalController);

        CalendarioEventoAceiteModalController.$inject = ['$modalInstance', 'promiseTracker'];

    function CalendarioEventoAceiteModalController ($modalInstance, promiseTracker) {

        var vm = this;

        vm.loadingTracker = promiseTracker();   
        vm.loadingTrackerEvent = [];

        vm.eventosAceite = $modalInstance.params.eventosAceite; 
        vm.loadEventosAceite = $modalInstance.params.fn.loadEventosAceite;

        vm.aceitarEvento = $modalInstance.params.fn.aceitarEvento;
        vm.aceitarEditarEvento = $modalInstance.params.fn.aceitarEditarEvento;
        vm.recusarEvento = $modalInstance.params.fn.recusarEvento;

        vm.aceitarEventoModal = aceitarEventoModal;
        vm.aceitarEditarEventoModal = aceitarEditarEventoModal;
        vm.recusarEventoModal = recusarEventoModal;    
        vm.aceitarTodosObrigatorios = aceitarTodosObrigatorios; 
        vm.saveLoteEventos = $modalInstance.params.fn.saveLoteEventos;
                
        vm.hasEventoObrigatorio = hasEventoObrigatorio;

        function loadEventosAceiteModal() {
            vm.loadingTracker.addPromise(
                vm.loadEventosAceite().then(thenLoadEventosAceiteModal)
            );
        }
        
        function thenLoadEventosAceiteModal(aceites) {
            vm.eventosAceite = aceites;

            if (_.isEmpty(vm.eventosAceite)) {
                $modalInstance.close();
            }
        }

        function aceitarEventoModal(evento) {
            addPromiseToTrackerEvent(vm.aceitarEvento(evento)
                .then(loadEventosAceiteModal),
                evento
            );
        }

        function aceitarEditarEventoModal(evento) {
            addPromiseToTrackerEvent(vm.aceitarEditarEvento(evento)
                .then(loadEventosAceiteModal),
                evento
            );
        }

        function recusarEventoModal(evento) {
            addPromiseToTrackerEvent(vm.recusarEvento(evento)
                .then(loadEventosAceiteModal),
                evento
            );
        }

        function aceitarTodosObrigatorios() {            
            var eventosObrigatorios = _.filter(vm.eventosAceite, 'obrigatorio');
            vm.loadingTracker.addPromise(vm.saveLoteEventos(eventosObrigatorios)
                .then(loadEventosAceiteModal)
            );
        }

        function addPromiseToTrackerEvent(promise, evento) {
            vm.loadingTrackerEvent[evento.id] = promiseTracker();
            vm.loadingTrackerEvent[evento.id].addPromise(promise);
            vm.loadingTracker.addPromise(promise);
        }

        function hasEventoObrigatorio() {  
            return _.any(vm.eventosAceite, 'obrigatorio', true);
        }
    }
})();

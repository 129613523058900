(function () {
    'use strict';

    angular.module('educacao.funcionario')
        .factory('educacao.funcionario.FuncionarioTodosService', Service);

    Service.$inject = ['ServiceBuilder'];

    function Service(ServiceBuilder) {
        var selfService = ServiceBuilder.create()
            .path('funcionario', 'servidores','completos')
            .build();
        return selfService;
    }
})();

(function() {
    'use strict';

    angular.module('educacao.formulaCalculoInstrumento').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.formulaCalculoInstrumento', {
            url: 'formula-calculo-instrumento',
            data: {
                id: 'FormulaCalculoInstrumentoPageMapping'
            },
            views: {
                '@': {
                    templateUrl: 'formula-calculo-instrumento/formula-calculo-instrumento.html'
                }
            }
        });
    }
})();
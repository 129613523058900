 (function() {
     'use strict';
     angular.module('educacao.calendario')
         .factory('educacao.calendario.TipoEventoSelect', TipoEventoSelect);

     TipoEventoSelect.$inject = [
         'educacao.calendario.TiposEventoService',
         'educacao.common.ObjectUtilsService',
         'bfc.Select2Config'
     ];

     function TipoEventoSelect(ConfiguracoesRepositorio, ObjectUtilsService, Select2Config) {
         return {
             select2Config: function(options) {
                 var tipoEventoItems = {};

                 ConfiguracoesRepositorio.getTiposEventos().then(function(data) {
                     tipoEventoItems = data;
                 });

                 return Select2Config.create(angular.extend({
                     result: function(params, callback) {

                         callback({
                             results: ObjectUtilsService.filterByFieldWithSort(tipoEventoItems, 'descricao', params.term),
                             more: false
                         });
                     },
                     formatValue: function(data) {
                         return _.pick(data, 'id', 'descricao');
                     },
                     formatResult: function(data) {
                         return data.text ?
                             data.text : data.descricao;
                     },
                     formatSelection: function(data) {
                         return data.descricao;
                     },
                     initSelection: function() {}
                 }, options));
             }
         };
     }

 })();

(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('educacao.common.PaisService', PaisService);

    PaisService.$inject = ['$injector'];

    function PaisService($injector) {
        var GenericService = $injector.get('GenericService');
  
   
        return GenericService.of('pessoa', 'configuracoes', 'pais');
    }
})();
(function() {
	'use strict';

	var moduloCommon = angular.module('educacao.funcionario');

	moduloCommon.controller('educacao.funcionario.AreaAtuacaoCtrl', ['$scope', '$timeout','educacao.common.ModalCad',
		function($scope, $timeout, ModalCad
		) {

			$scope.AreaAtuacap = {};

			$scope.open = function(id) {
				ModalCad.open({
					templateUrl: 'planejamento/funcionarios/areas-atuacao/area.atuacao.cad.html',
					controller: 'educacao.funcionario.AreaAtuacaoCadCtrl',
					id: id,
					readOnly: false
				});
			};

	}]);

	moduloCommon.controller('educacao.funcionario.AreaAtuacaoCadCtrl', ['$scope', '$timeout','educacao.common.ModalCad','bfc.Select2Config',
		function($scope) {
			$scope.AreaAtuacap = {};			
	}]);

})();	
(function() {
    'use strict';

    angular.module('educacao.common')
        .factory('CriaMenusConfiguracao', factory);

    factory.$inject = ['mainMenu', 'MegaMenuItem', 'UserAccessContext', '$rootScope', 'OCULTAR_EVASAO'];

    function factory(mainMenu, MegaMenuItem, UserAccessContext, $rootScope, OCULTAR_EVASAO) {

        return createMenus;

        function createMenus() {

            var configurationMenu = {
                style: {
                    'width': '540px',
                    'left': '-1px'
                },
                classCol: 'col-md-6',
                configurationColumns: [{
                    numberOfChildrens: 1
                }, {
                    numberOfChildrens: 1
                }]
            };

            var configurando = new MegaMenuItem('configurando', 'Configurando')
                .setConfiguration(configurationMenu);

            criaGrupoParametros(configurando);
            criaGrupoAvaliacaoFrequencia(configurando);
            criaGrupoFormulasValidacoes(configurando);

            mainMenu.addChildren(configurando);

            function criaGrupoParametros(root) {
                var grupoParametros = new MegaMenuItem('parametros', 'Parâmetros')
                    .setParent(root);

                new MegaMenuItem('Entidade')
                    .setParent(grupoParametros)
                    .setPermission('app.cadastroEntidade')
                    .setAction(abreModalCadastroEntidade);

                new MegaMenuItem('Anos letivos')
                    .setParent(grupoParametros)
                    .setAction('app.planejamento.anosletivos');

                new MegaMenuItem('Critérios de classificação')
                    .setParent(grupoParametros)
                    .setAction('app.matricula.criterioClassificacao');

                new MegaMenuItem('Critérios da lista de espera')
                    .setParent(grupoParametros)
                    .setAction('app.criterio-lista-espera');

                new MegaMenuItem('Configuração de matrículas')
                    .setParent(grupoParametros)
                    .setPermission('app.planejamento.configuracaomatricula')
                    .setAction(abreModalParametrosMatricula);


                new MegaMenuItem('Configuração de turmas')
                    .setParent(grupoParametros)
                    .setPermission('app.planejamento.configuracaoturma')
                    .setAction(abreModalParametrosTurmas);

                new MegaMenuItem('Dependências físicas')
                    .setParent(grupoParametros)
                    .setIconClass('fa fa-cogs')
                    .setPermission('app.planejamento.configuracaodependenciafisica')
                    .setAction(abreModalParametrosGerais);


                var precicao = new MegaMenuItem('Predição do risco de evasão e reprovação')
                    .setParent(grupoParametros)
                    .setIsAdminOnly(true)
                    .setAction('app.configuracao-evasao-reprovacao')
                    .setNew(true);
                $rootScope.$on('bfc:user-access:new', function() {
                    if (!OCULTAR_EVASAO) {
                        return;
                    }
                    var database = _.get(UserAccessContext.getUserAccess(), 'values.database');
                    var index = _.indexOf(OCULTAR_EVASAO.split(','), database);
                    if (index >= 0) {
                        precicao.setVisible(false);
                    } else {
                        precicao.setVisible(true);
                    }
                });

                new MegaMenuItem('Grupos')
                .setParent(grupoParametros)
                .setAction('app.configuracaoGrupo');
            }

            function criaGrupoAvaliacaoFrequencia(root) {
                var grupo = new MegaMenuItem('avaliacaoFrequencia', 'Avaliação e frequência')
                    .setParent(root);

                new MegaMenuItem('Horas/aulas por dia')
                    .setParent(grupo)
                    .setAction('app.matricula.horasaulas');

                new MegaMenuItem('Configuração de frequências')
                    .setParent(grupo)
                    .setAction('app.configuracaofrequencia');

                new MegaMenuItem('Tipos de avaliações')
                    .setParent(grupo)
                    .setAction('app.matricula.configuracaoAvaliacao');

                new MegaMenuItem('Instrumentos de avaliação')
                    .setParent(grupo)
                    .setAction('app.configuracaoInstrumentosAvaliacao');

                new MegaMenuItem('Planejamento de aula')
                    .setParent(grupo)
                    .setAction('app.configuracaoPlanejamentoAula');

                new MegaMenuItem('Conteúdo ministrado')
                    .setParent(grupo)
                    .setAction('app.configuracaoConteudoMinistrado');
            }

            function criaGrupoFormulasValidacoes(root) {
                var grupo = new MegaMenuItem('formulasValidacoes', 'Fórmulas e validações')
                    .setParent(root);

                new MegaMenuItem('Fórmulas de cálculo de resultados dos períodos')
                    .setParent(grupo)
                    .setAction('app.formulaCalculo');

                new MegaMenuItem('Fórmulas de cálculo dos instrumentos de avaliação')
                    .setParent(grupo)
                    .setAction('app.formulaCalculoInstrumento');
            }
        }
    }

    abreModalParametrosGerais.$inject = ['educacao.common.ModalCad'];

    function abreModalParametrosGerais(ModalCad) {
        ModalCad.open({
            templateUrl: 'common/parametros/parametros.cad.html',
            controller: 'educacao.common.ParametrosCadCtrl as ParametrosCadCtrl',
            readOnly: false
        });
    }

    abreModalParametrosTurmas.$inject = ['educacao.common.ModalCad'];

    function abreModalParametrosTurmas(ModalCad) {
        ModalCad.open({
            templateUrl: 'common/parametros/configuracao-turmas/configuracao.turmas.html',
            controller: 'educacao.common.ConfiguracoesCtrl as vm',
            readOnly: false
        });
    }

    abreModalParametrosMatricula.$inject = ['ui.components.Modal'];

    function abreModalParametrosMatricula($modal) {
        $modal.open({
            templateUrl: 'matricula/configuracao-matricula/configuracao-matricula.html',
            controller: 'educacao.matricula.ConfiguracoesMatriculaCadCtrl',
            controllerAs: 'vm',
            size: 'xl'
        });
    }

    abreModalCadastroEntidade.$inject = ['ui.components.Modal'];

    function abreModalCadastroEntidade($modal) {
        $modal.open({
            templateUrl: 'entidade/cadastro-entidade.html',
            controller: 'educacao.entidade.EntidadeCtrl',
            controllerAs: 'vm',
            size: 'xl'
        });
    }

})();


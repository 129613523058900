(function () {
    'use strict';

    angular.module('educacao.common')
        .directive('cardAluno', MatriculaCardAluno);

    function MatriculaCardAluno() {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/aluno-card-directive/aluno-card.directive.html',
            controller: Controller,
            controllerAs: 'vm',
            scope: {
                aluno: '='
            }
        };
    }

    Controller.$inject = ['$scope'];

    function Controller($scope) {
        var vm = this,
            DATE_FORMAT = 'DD/MM/YYYY',
            DATE_FORMAT_DIFF = 'YYYY-MM-DD',

            PLULARIZE_ANOS = {
                'one': ' ano',
                'other': ' anos'
            };

        vm.formatIdade = formatIdade;
        vm.getNome = getNome;
        vm.hasDeficiencias = hasDeficiencias;
        vm.hasTranstornosGlobais = hasTranstornosGlobais;

        function formatIdade() {
            if (!$scope.aluno) {
                return;
            }
            var idade = moment(new Date()).diff(moment($scope.aluno.dataNascimento || $scope.aluno.pessoa.dataNascimento).format(
                DATE_FORMAT_DIFF), 'years'),
                plularize = idade > 1 ? PLULARIZE_ANOS.other : PLULARIZE_ANOS.one;
            return moment($scope.aluno.dataNascimento || $scope.aluno.pessoa.dataNascimento).format(DATE_FORMAT) + ' (' + idade +
                plularize + ')';
        }

        function getNome() {
            return $scope.aluno.nome || $scope.aluno.pessoa.nome;
        }

        function hasDeficiencias() {

            var aluno = $scope.aluno;

            if (!aluno) {
                return;
            }

            return aluno.hasDeficiencias || _.get(aluno, 'alunoDeficiencias.length');
        }

        function hasTranstornosGlobais() {

            var aluno = $scope.aluno;

            if (!aluno) {
                return;
            }

            return aluno.hasTranstornosGlobais || _.get(aluno, 'transtornosGlobais.length');
        }

    }

})();


(function () {
    'use strict';

    angular.module('educacao.matricula').directive('appDocumentosMatricula', AppDocumento);

    function AppDocumento() {
        return {
            restrict: 'E',
            templateUrl: 'matricula/matriculas/cadastro/app-documentos-matricula.html',
            scope: {},
            bindToController: {
                documentos: '=ngModel',
                estabelecimento: '=',
                matricula: '=',
                etapasMatriz: '=',
                tracker: '=',
                hasEncerramentos: '='
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = ['$scope',
        '$q',
        'educacao.matricula.ConfiguracoesMatriculaService',
        'educacao.common.FilterEncodedUtilsService',
        'ui.components.Modal'
    ];

    function Controller($scope, $q, ConfiguracoesMatriculaService, FilterEncodedUtilsService, $modal) {
        var vm = this;

        vm.documentosSearch = documentosSearch;
        vm.openImpressaoDocumentos = openImpressaoDocumentos;

        $scope.$watchGroup(['vm.matricula.documentos', 'vm.listaDocumentosEncontrados'], changeDocumentos);
        $scope.$watch('vm.matricula.etapaMatriz', watchEtapaMatriz);
        $scope.$watch('vm.matricula.matriz', watchMatriz);
        $scope.$watch('vm.etapasMatriz', watchFilhos);

        function getEquivalenciasProperty(matricula) {
            if (matricula.id) {
                return 'equivalencias';
            }
            return 'equivalenciasEnum';
        }

        function watchMatriz() {
            vm.listaDocumentosEncontrados = [];
            var filter = filterDocumentosComplementarOrInfantil();
            searchDocuments(filter);
        }

        function watchEtapaMatriz() {
            var filter;
            //Verifica se  tem o cuso e se o nivel escolar e diferente de complementar, infantil e se tem equivalencias
            if (!isEducacaoInfantil() && !isComplementar() && !isModular()) {
                var equivalenciasEnum = equivalenciasEnumEtapa();
                if (_.isEmpty(equivalenciasEnum)) {
                    vm.listaDocumentosEncontrados = [];
                    return;
                }
                filter = filtroDocumentosCompleto();
                filter += ' and etapa in ("' + equivalenciasEnum.join('","') + '")';
            }
            searchDocuments(filter);
        }

        function watchFilhos() {
            var filter;

            var equivalenciasEnumLista = _.chain(vm.matricula.filhos)
                .map(function(filho) {
                    var eqProperty = getEquivalenciasProperty(filho);
                    return _.get(filho.etapaMatriz, eqProperty);
                })
                .flatten()
                .without(undefined)
                .value();

            var equivalenciasEnum = equivalenciasEnumLista.join('","');

            if (_.isEmpty(equivalenciasEnum)) {
                vm.listaDocumentosEncontrados = [];
                return;
            }

            filter = filtroDocumentosCompleto();
            filter += ' and etapa in ("' + equivalenciasEnum + '")';
            searchDocuments(filter);
        }

        function searchDocuments(filter) {
            if (!_.get(filter, 'length')) {
                return;
            }
            vm.tracker.addPromise(
                ConfiguracoesMatriculaService.getDocumentos(vm.estabelecimento.id, filter)
                    .then(function (documentos) {
                        vm.listaDocumentosEncontrados = documentos;
                    })
            );
        }

        function documentosSearch(params) {
            var documentoFilter = [];
            var term = FilterEncodedUtilsService.getDecodedTerm(params.term);

            _.filter(vm.listaDocumentosEncontrados, function (documento) {
                if (documento.tipoDocumento.descricao.toUpperCase().indexOf(term.toUpperCase()) > -1) {
                    documentoFilter.push({
                        id: documento.tipoDocumento.id,
                        descricao: documento.tipoDocumento.descricao
                    });
                }
            });

            return $q.when(getPage(documentoFilter, false));
        }

        function getPage(lista, hasNext) {
            return {
                content: lista,
                hasNext: hasNext
            };
        }

        function filterDocumentosComplementarOrInfantil() {
            var filter;
            if (isEducacaoInfantil()) {
                filter = filtroDocumentosCompleto();
            }
            if (isComplementar()) {
                filter = 'nivelModalidade = "' + getNivelModalidadeMatriz() + '"';
            }
            return filter;
        }

        function isEducacaoInfantil() {
            return getNivelEscolarMatriz() === 'EDUCACAO_INFANTIL';
        }

        function isComplementar() {
            return getNivelModalidadeMatriz() === 'COMPLEMENTAR';
        }

        function isModular() {
            return _.get(vm.matricula.matriz, 'modular');
        }

        function filtroDocumentosCompleto() {
            return 'nivelEscolar = "' + getNivelEscolarMatriz() + '" and nivelModalidade = "' + getNivelModalidadeMatriz() + '"';
        }

        function getNivelEscolarMatriz() {
            return _.get(vm.matricula.matriz, 'curso.nivelEscolar') || _.get(vm.matricula.vaga, 'matriz.curso.nivelEscolar');
        }

        function getNivelModalidadeMatriz() {
            return _.get(vm.matricula.matriz, 'curso.nivelModalidade') || _.get(vm.matricula.vaga, 'matriz.curso.nivelModalidade');
        }

        function changeDocumentos() {
            vm.documentosFaltantes = [];
            _.forEach(vm.listaDocumentosEncontrados, function (documentoObrigatorio) {
                if (documentoObrigatorio.obrigatorio && !hasDocumento(documentoObrigatorio)) {
                    vm.documentosFaltantes.push(wrapDocumento(documentoObrigatorio, true));
                }
            });
            vm.documentosImpressao = [];
            _.forEach(vm.listaDocumentosEncontrados, function (documento) {
                var pendente = !hasDocumento(documento);
                vm.documentosImpressao.push(wrapDocumento(documento, pendente));
            });
        }

        function hasDocumento(documentoObrigatorio) {
            return _.find(vm.matricula.documentos, function (documento) {
                return documentoObrigatorio.tipoDocumento.id === documento.id;
            });
        }

        function wrapDocumento(documentoObrigatorio, pendente) {
            return {
                id: documentoObrigatorio.tipoDocumento.id,
                descricao: documentoObrigatorio.tipoDocumento.descricao,
                pendente: pendente
            };
        }

        function equivalenciasEnumEtapa() {
            if (_.get(vm.matricula, 'etapaMatriz.equivalenciasEnum')) {
                return vm.matricula.etapaMatriz.equivalenciasEnum;
            }

            if (_.get(vm.matricula.vaga, 'etapaMatriz.equivalenciasEnum')) {
                return vm.matricula.vaga.etapaMatriz.equivalenciasEnum;
            }

            if (_.get(vm.matricula.vaga, 'etapaMatriz.equivalencias')) {
                return vm.matricula.vaga.etapaMatriz.equivalencias;
            }

            if (_.get(_.pluck(vm.matricula.filhos, 'etapaMatriz.equivalencias'), 'length')) {
                return _.pluck(vm.matricula.filhos, 'etapaMatriz.equivalencias');
            }
        }

        function openImpressaoDocumentos() {
            $modal.open({
                templateUrl: 'matricula/tipo-documento/impressao-documentos.html',
                controller: 'educacao.matricula.impressaoDocumenos',
                controllerAs: 'vm',
                size: '',
                params: {
                    documentos: vm.documentosImpressao,
                    matricula: vm.matricula,
                    turma: vm.matricula.turma,
                    etapaMatriz: vm.matricula.etapaMatriz
                }
            });
        }

    }
})();

(function() {

    'use strict';

    angular.module('educacao.encerramento')
        .constant('PERIODOS_ENCERRAMENTO', {
            PERIODO_1: 'PERIODO_1',
            PERIODO_2: 'PERIODO_2',
            PERIODO_3: 'PERIODO_3',
            PERIODO_4: 'PERIODO_4',
            EXAME_1: 'EXAME_1',
            EXAME_2: 'EXAME_2',
            EXAME_3: 'EXAME_3',
            EXAME_4: 'EXAME_4',
            CONSELHO_CLASSE: 'CONSELHO_CLASSE',
            PERIODO_LETIVO: 'PERIODO_LETIVO'
        });

})();


(function () {
  'use strict';

  angular
    .module('educacao.educacenso')
    .directive('appListagemInconsistencias', SelectListagemInconsistencias);

  function SelectListagemInconsistencias() {
    return {
      restrict: 'E',
      templateUrl:
        'educacenso/app-listagem-inconsistencias/app-listagem-inconsistencias.html',
      bindToController: true,
      scope: {
        funcionalidade: '@',
        estabelecimento: '=',
        etapa: '='
      },
      controller: Controller,
      controllerAs: 'vm',
    };
  }

  Controller.$inject = [
    'educacao.educacenso.EducacensoInconsistenciaService',
    'educacao.common.$commons',
    'bfc.Notification',
    '$searchExpressionBuilder',
    'bfc.$$PermissionsService'
  ];

  function Controller(
    EducacensoInconsistenciaService,
    $commons,
    bfcNotification,
    $searchExpressionBuilder,
    PermissionsService
  ) {
    var vm = this;
    vm.educacensoInconsistenciaService = EducacensoInconsistenciaService;
    vm.hasPermissionToAlterarSituacaoInconcistencia = PermissionsService.isRevokedOperation('EducacensoPageMapping', 'permitirConcluirInconcistencia');

    vm.filter = {};
    vm.tracker = {};
    vm.listControls = {};

    vm.tracker.loading = $commons.$promiseTracker();

    vm.inverterStatusConclusao = inverterStatusConclusao;
    vm.search = search;

    vm.listPadraoCustomBuilder = {
      onBeforeBuild: function () {
        var filter =
            'etapa in ("' + vm.etapa + '") and funcionalidade in (' +
          vm.funcionalidade +
          ') and estabelecimento in (' +
          vm.estabelecimento.id +
          ')';
        if (vm.mostrarSomenteOsNaoResolvidos) {
          filter += ' and erroCorrigido = false ';
        }

        var expression = $searchExpressionBuilder.build(vm.searchFilter) || '';
        if (!_.isEmpty(expression)) {
          filter += ' and (' + expression + ')';
        }

        return filter;
      },
    };

    vm.searchColumns = [
      {
        id: 'descricao',
        label: 'Descrição',
        model: 'descricao',
        filterable: true,
      },
      {
        id: 'inconsistencia',
        label: 'Inconsistência',
        model: 'inconsistencia',
        filterable: true,
      },
      {
        id: 'valorOriginal',
        label: 'Valor original',
        model: 'valorOriginal',
        filterable: true,
      },
    ];

    function inverterStatusConclusao(inconsistencia) {
      if(!vm.tracker.loading[inconsistencia.id]){
        vm.tracker.loading[inconsistencia.id] = $commons.$promiseTracker();
      }

      vm.tracker.loading[inconsistencia.id].addPromise(
        EducacensoInconsistenciaService.inverterStatusConclusao(
          inconsistencia.id
        ).then(
          bfcNotification.publish(
            'Situação da inconsistência alterada',
            'success'
          )
        )
      );
    }

    function search() {
      vm.listControls.search();
    }
  }
})();

(function() {

    'use strict';

    angular.module('educacao.entidade')
        .controller('educacao.entidade.ModalEndereco', ModalEnderecoEntidade);

    ModalEnderecoEntidade.$inject = [
    	'$scope',
    	'$modalInstance'
    ];

    function ModalEnderecoEntidade($scope, $modalInstance) {

        var vm = this;

        vm.continuar = continuar;

        vm.entidade = angular.copy($modalInstance.params.entidade);

        function continuar() {
            $modalInstance.close(vm.entidade);
        }
    }

})();